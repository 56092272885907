export default {
	'ERRORS.ERROR': 'Error',
	'ERRORS.FORBIDDEN_ACCESS': 'Forbidden Access',
	'ERRORS.RETURN_TO_HOME_PAGE': 'RETURN TO HOME PAGE',
	'ERRORS.PAGE_NOT_FOUND':
		'It seems we can’t find the page you are looking for.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Internal Server Error',
	'ERRORS.REQUEST_TIMED_OUT': 'Request timed out',
	'ERRORS.LOGOUT': 'Logout',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION':
		"Uh oh, It seems that you don't have permission to access this application.",
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR':
		'If you believe you should have the access, please contact your company administrator.'
};
