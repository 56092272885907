// import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getUserGroupPermissions = (params) => {
	if (params.system) {
		return ApiService.get(`/users/system-user-groups/${params.id}/permissions`);
	}
	if (params.myProfile && params.currentUser.systemUser) {
		return ApiService.get(`/users/system-user-groups/${params.id}/permissions`);
	}
	if (params.myProfile) {
		return ApiService.get(`/users/user-groups/${params.id}/permissions`);
	}
	return ApiService.get(`/users/user-groups/${params.id}/permissions`);
};

export const editUserGroupPermissions = (params) =>
	ApiService.patch(
		`/users/${params.system ? 'system-' : ''}user-groups/${params.id}`,
		params.data
	);

export const getBlacklistedPermissions = () =>
	ApiService.get('/users/blacklisted-permissions');

export const editBlacklistedPermission = (params) => {
	const { permissionId, blacklist, companyId } = params;
	if (blacklist) {
		return ApiService.post(
			`/users/companies/${companyId}/blacklisted-permissions`,
			{
				permissionId
			}
		);
	}
	return ApiService.post(
		`/users/companies/${companyId}/blacklisted-permissions/remove`,
		{
			permissionId
		}
	);
};

export const requestFeature = (params) =>
	ApiService.post('/users/requested-permissions/request', {
		blacklistedPermissionId: params.permissionId,
		note: params.note
	});

export const cancelRequestedFeature = (params) =>
	ApiService.post(`/users/requested-permissions/${params.id}/cancel`, {
		note: params.note
	});

export const approveRequestedFeature = (params) =>
	ApiService.post(`/users/requested-permissions/${params.id}/approve`, {
		note: params.note
	});

export const approveRequestedFeatureWithTimeLimit = (params) =>
	ApiService.post(
		`/users/requested-permissions/${params.id}/approve-with-time-limit`,
		{
			note: params.note
		}
	);

export const rejectRequestedFeature = (params) =>
	ApiService.post(`/users/requested-permissions/${params.id}/reject`, {
		note: params.note
	});

export const getBlacklistedPermissionsForCompany = (params) =>
	ApiService.get(`/users/companies/${params.id}/blacklisted-permissions`);

export const getUserEffectivePermissions = (params) => {
	if (params.myProfile) {
		return ApiService.get('/users/me/effective-permissions');
	}
	return ApiService.get(
		`/users/${params.system ? 'system-users/' : ''}${
			params.id
		}/effective-permissions`
	);
};
