import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getDataDetails = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/data-details?${qs.stringify(
			params.params
		)}`
	);

export const getDataDetailsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/data-details/filters?${qs.stringify(params.params)}`
	);
