/* eslint-disable import/prefer-default-export */
import globalAction from '../../../../redux/global/globalAction';

import { GET_BATCH_REPORTS } from './constants';

import { getBatchReports as getBatchReportsService } from '../../services/BatchReportsService';

/** ************ get batch reports ************* */
export const getBatchReports = (params) =>
	globalAction(GET_BATCH_REPORTS, getBatchReportsService, params);
