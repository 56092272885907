import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { UserAllowedToAccess } from '../../../../utils/AuthSelector';

import styles from './Recredential.scss';

const Info = ImageSelector(CurrentContext.theme, 'svgs/question-full.svg');
const M2MAccountIDRWROComponent = UserAllowedToAccess([
	'mnc.endpoints.accountid_ro',
	'mnc.endpoints.accountid_rw',
]);

export default function Recredential({ endpoint }) {
	return (
		<div
			data-spec="recredential-wrapper"
			className={styles.recredential_wrapper}
			key={'recredential-data'}
		>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="CARRIER"
						defaultMessage="Carrier"
					/>
				</span>
				<span>{endpoint.networkOperator || '-'}</span>
			</div>
			<M2MAccountIDRWROComponent>
				<div className={styles.data}>
					<span className={styles.tooltip_data}>
						<FormattedMessage
							id="M2M_ACCOUNT_ID"
							defaultMessage="M2M Account ID"
						/>
						<FormattedMessage
							id="DESCRIPTION"
							defaultMessage="Description"
						>
							{(formattedValue) => (
								<Info
									data-tip={formattedValue}
									data-for="m2mAccountIDTooltip"
								/>
							)}
						</FormattedMessage>
						<ReactTooltip
							className={styles.tooltip}
							type="light"
							id="m2mAccountIDTooltip"
						/>
					</span>
					<span>{endpoint.m2mAccountId || '-'}</span>
				</div>
			</M2MAccountIDRWROComponent>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="HOLDING_ACCOUNT_ID"
						defaultMessage="Holding Account ID"
					/>
				</span>
				<span>{endpoint.holdingAccount || '-'}</span>
			</div>
		</div>
	);
}

Recredential.propTypes = {
	endpoint: object,
};
Recredential.defaultProps = {
	endpoint: {},
};
