import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getPrepaidSubscriptions = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/prepaid-subscriptions?${qs.stringify(params.params)}`
	);

export const getPrepaidSubscriptionsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/prepaid-subscriptions/filters?${qs.stringify(params.params)}`
	);
