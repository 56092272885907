import { createAction } from 'redux-actions';
import { getLocale, downloadReportHelper } from '../../../../utils/constants';
import { exportCsv, ticketPollForFile } from '../../services/TicketingService';
import { showNotification } from '../../../Shared/redux/notifications/actions';
import {
	EXPORT_CSV_REQUEST,
	EXPORT_CSV_FAIL,
	EXPORT_CSV_SUCCESS
} from '../constants';

const exportRequest = createAction(EXPORT_CSV_REQUEST);
const exportSuccess = createAction(EXPORT_CSV_SUCCESS);
const exportFail = createAction(EXPORT_CSV_FAIL);

// eslint-disable-next-line import/prefer-default-export
export const exportCsvFile = (data) => async (dispatch) => {
  const locale = getLocale();
	const {
		default: { ACTION_COULD_NOT_BE_COMPLETED }
	} = await import(`../../../../localizations/${locale}`);
	const {
		default: { 'TICKETING.SOMETHING_WENT_WRONG': message }
	} = await import(`../../localization/${locale}`);
	const downloadURL = '';
	dispatch(exportRequest());
	exportCsv(data)
		.then((response) => {
			ticketPollForFile(response.id, 100, 4000)
				.then((ticketsExport) => {
					dispatch(exportSuccess(ticketsExport));
					return downloadReportHelper(ticketsExport.downloadUrl, downloadURL);
				})
				.catch((error) => {
					dispatch(exportFail(error));
					dispatch(
						showNotification({
							id: 'export-ticket-error',
							message: message ? [message] : [ACTION_COULD_NOT_BE_COMPLETED],
							type: 'error',
							notificationType: 'toaster'
						})
					);
				});
		})
		.catch((error) => {
			dispatch(exportFail(error));
		});
};
