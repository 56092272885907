export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': 'Supprimer paramètre de diagramme défini ?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': 'Voulez-vous vraiment supprimer le paramètre {settingName} ?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Graphiques actuels disponibles',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': 'Ce graphique existe déjà dans vos paramètres. Un seul type de graphique permis.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'Aucune description pour ce graphique',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Type graphique',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Exporter données diagramme',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Supprimer graphique',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Paramètres graphique',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Ajouter graphique',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'Ajout du nouveau graphique réussi',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Échec de l’ajout du nouveau graphique',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'Mise à jour réussie du graphique',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Échec de la mise à jour du graphique',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Suppression des paramètres du graphique réussie',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Échec de la suppression des paramètres du graphique'
};
