import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import FlowSteps from '../../../../lib/DigitalComponents/FlowSteps/FlowSteps';
import Button from '../../../../lib/DigitalComponents/Button';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import Loader from '../../../../lib/DigitalComponents/Loader';
import FirstStep from './Steps/StepFirst';
import SecondStep from './Steps/StepSecond';
import ThirdStep from './Steps/StepThird';
import ForthStep from './Steps/StepFourth';
import UsageTrend from '../../../Shared/components/Charts/UsageTrend';
import WrapperGridCard from '../../../Shared/components/WrapperGridCard';
import {
	isCycle,
	isHistoricalCycle
} from '../../../Shared/components/Charts/Helpers/helpers';

import styles from './AnalyticsModal.scss';

const ChartIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/analytic-chart-icon.svg'
);
const Edit = ImageSelector(CurrentContext.theme, 'svgs/pen.svg');
const CloseIcon = ImageSelector(CurrentContext.theme, 'svgs/close.svg');
const CheckIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');

const dateRange = [
	{ id: 0, label: 'TODAY', value: 0 },
	{ id: 6, label: 'PREVIOUS_DAY', value: 6 },
	{ id: 1, label: 'LAST_10_DAYS', value: 1 },
	{ id: 2, label: 'LAST_30_DAYS', value: 2 },
	{ id: 3, label: 'CUSTOM_RANGE', value: 3 }
];
const cycleArray = [
	{ value: 4, label: 'CURRENT_CYCLE_TO_DATE' },
	{ value: 5, label: 'HISTORICAL' }
];
const incrementArray = [
	{
		value: 0,
		label: 'DAY',
		propValue: 'year,month,dayinmonth',
		type: 'day'
	},
	{
		value: 1,
		label: 'WEEK',
		propValue: 'year,month,weekinmonth',
		type: 'week'
	},
	{
		value: 2,
		label: 'MONTH',
		propValue: 'year,month',
		type: 'month'
	}
];
const aggregationArray = [
	{ value: 0, label: 'SUM_TOTALS', propValue: 'SUM' },
	{ value: 1, label: 'AVERAGE_TOTALS', propValue: 'AVG' },
	{ value: 2, label: 'MOVING_AVERAGE', propValue: 'MVA' },
];

class AnalyticsModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
			refresh: false,
			editGraphNameVisible: false,
			editDescriptionVisible: false,
			isEditing: false,
			graphName: '',
			description: '',
			from: new Date(),
			to: new Date(),
			temporarySetting: null,
			changed: false,
			shouldGetFilters: true,
			editedName: '',
			editedDescription: ''
		};
	}

	componentDidMount() {
		const { initialize } = this.props;
		const initialValues = {
			radioCategory: '',
			groupBySelector: '',
			groupByDate: '0',
			groupByIncrement: '0',
			groupByShow: '0',
			filtersForm: {},
			chartForm: '',
			showSummary: true,
		};

		initialize(initialValues);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			getCategories,
			getAttributes,
			getFilters,
			show,
			editItem,
			groupByDate,
			categories,
			attributes,
			filters,
			change,
			formValues,
			initialize,
			chartType,
			dispatch,
			groupBySelector,
			categoryId
		} = this.props;
		const {
			changed,
			refresh,
			temporarySetting,
			shouldGetFilters,
			from,
			to
		} = this.state;

		if (!prevProps.show && show) {
			getCategories();
			getAttributes();
		}

		if (
			shouldGetFilters &&
			attributes &&
			categories &&
			Object.keys(editItem).length
		) {
			const category = categories.find((ctg) =>
				(editItem.radioCategory
					? ctg.name === editItem.radioCategory
					: ctg.id === editItem.categoryId));

			if (category !== undefined) {
				getFilters(attributes, category.id);
			}

			this.setGetFilters();
		}

		if (prevProps.groupByDate === 3 && groupByDate !== 3) {
			this.setDates();
		}

		if (
			Object.keys(prevProps.editItem).length === 0 &&
			Object.keys(editItem).length > 0
		) {
			let tempFrom = new Date();
			let tempTo = new Date();

			if (editItem.dateFrom && editItem.dateTo) {
				tempFrom = this.extractDate(editItem.dateFrom);
				tempTo = this.extractDate(editItem.dateTo);
			}

			this.setEditItem(tempFrom, tempTo, editItem);
		}

		if (categories && attributes && filters && !temporarySetting && show) {
			this.shouldRefresh(
				groupByDate,
				groupBySelector,
				attributes,
				chartType,
				dispatch,
				change,
				from,
				to,
				categoryId,
				categories
			);
		}

		if (!prevProps.filters && filters) {
			const allSelectedFilters = {};
			const editItemCheck =
				!Object.keys(editItem).length ||
				!!(editItem.filters && !Object.keys(editItem.filters).length) ||
				!!(editItem.editFilters && !Object.keys(editItem.editFilters).length) ||
				!!(editItem.filterForm && !Object.keys(editItem.filterForm).length);

			if (editItemCheck) {
				Object.keys(filters).forEach((filter) => {
					if (filters[filter].length) allSelectedFilters[filter] = {};
					filters[filter].forEach((item) => {
						allSelectedFilters[filter][item] = true;
					});
				});

				change('filtersForm', allSelectedFilters);
				initialize({
					...formValues,
					radioCategory: editItem.radioCategory,
					filtersForm: allSelectedFilters,
				});
				change('radioCategory', formValues.radioCategory);
			}
		}

		if (!prevState.changed && changed) {
			this.setChangedState();
			this.shouldRefresh(
				groupByDate,
				groupBySelector,
				attributes,
				chartType,
				dispatch,
				change,
				from,
				to,
				categoryId,
				categories
			);
		}

		if (!prevState.refresh && refresh) {
			this.setRefreshState();
		}
	}

	setGetFilters = () => this.setState({ shouldGetFilters: false });

	setDates = () => this.setState({ from: new Date(), to: new Date() });

	setEditItem = (tempFrom, tempTo, editItem) =>
		this.setState({
			from: tempFrom,
			to: tempTo,
			currentStep: 4,
			isEditing: true,
			graphName: editItem.name,
			description: editItem.description || '',
			editedName: editItem.name,
			editedDescription: editItem.description || ''
		});

	setChangedState = () => this.setState({ changed: false });

	setRefreshState = () => this.setState({ refresh: false });

	extractDate = (date) => {
		const tempString = date
			.split('T')
			.map((item, index) => item.split(index === 0 ? '-' : ':'))
			.flat();

		return new Date(
			tempString[0],
			tempString[1] - 1,
			tempString[2],
			tempString[3],
			tempString[4],
			tempString[5].split('.')[0],
			tempString[5].split('.')[0].split('Z')[0]
		);
	};

	shouldRefresh = (
		groupByDate,
		groupBySelector,
		attributes,
		chartType,
		dispatch,
		change,
		from,
		to,
		categoryId,
		categories
	) => {
		if (
			groupBySelector &&
			chartType &&
			categories.find((cat) => cat.name === categoryId) &&
			(
				attributes.find((att) => att.name === groupBySelector) ||
				groupBySelector
			)
		) {
			if (
				chartType === 'map' &&
				(isCycle(groupByDate) ||
					(attributes.find((attribute) => attribute.name === groupBySelector) &&
						!attributes.find((attribute) => attribute.name === groupBySelector)
							.isgeography))
			) {
				dispatch(change('chartForm', ''));
			} else if (groupByDate === 3) {
				if (from && to) {
					this.generateFinalObject();
				}
			} else {
				this.generateFinalObject();
			}
		}
	};

	hasChanged = () => this.setState({ changed: true });

	handleConfirmDateRange = ({ startDate, endDate }) => {
		const { currentStep } = this.state;

		this.setState({
			from: startDate,
			to: endDate,
		});

		if (currentStep > 3) {
			this.setState({ changed: true });
		}
	}

	editGraphName = () => this.setState({ editGraphNameVisible: true });

	editGraphDescription = () => this.setState({ editDescriptionVisible: true });

	nextStep = (step, currentStep) => {
		if (step > currentStep) {
			this.setState({
				currentStep: step
			});
		}
	};

	resetState = (initialize) => {
		const initialValues = {
			radioCategory: '',
			groupBySelector: '',
			groupByDate: '0',
			groupByIncrement: '0',
			groupByShow: '0',
			filtersForm: {},
			chartForm: '',
			showSummary: true,
		};

		this.setState({
			currentStep: 0,
			from: new Date(),
			to: new Date(),
			temporarySetting: null,
			graphName: '',
			isEditing: false,
			editGraphNameVisible: false,
			editDescriptionVisible: false,
			description: '',
			shouldGetFilters: true,
			editedName: '',
			editedDescription: ''
		});
		initialize(initialValues);
	};

	isPending = () => {
		const {
			isGetChartDataPending,
			isGetDirectionIndexPending,
			isAccountOverviewSuperAdminModal,
			categoriesPending,
			attributesPending,
			filtersPending
		} = this.props;
		const { temporarySetting } = this.state;

		if (!temporarySetting) {
			return false;
    }
    if (categoriesPending || attributesPending || filtersPending) {
			return true;
    }
    if (isAccountOverviewSuperAdminModal) {
			return isGetChartDataPending(temporarySetting.i);
		}

		return Boolean(
			isGetChartDataPending(temporarySetting.i) ||
				isGetChartDataPending(`${temporarySetting.i}_usage_trend`) ||
				(!isHistoricalCycle(temporarySetting.groupByDate) &&
					isGetDirectionIndexPending(temporarySetting.i))
		);
	};

	generateFinalObject = () => {
		const {
			formValues,
			attributes,
			categories,
			isAccountOverviewSuperAdminModal,
			intl,
		} = this.props;
		const { to, from, graphName, description } = this.state;
		const testObject = {};
		const groupByIncrementObject = incrementArray[formValues.groupByIncrement]
			? incrementArray[formValues.groupByIncrement]
			: incrementArray[0];
		const groupByShowObject = aggregationArray[formValues.groupByShow]
			? aggregationArray[formValues.groupByShow]
			: aggregationArray[0];

		if (
			!categories ||
			!attributes ||
			categories.length === 0 ||
			attributes.length === 0
		) {
			return;
		}

		testObject.filters = {};
		testObject.editFilters = {};

		const notAllFiltersSelected = Object.keys(formValues.filtersForm).some(
			(key) =>
				Object.keys(formValues.filtersForm[key]).some(
					(item) => formValues.filtersForm[key][item] === false
				)
		);

		if (notAllFiltersSelected) {
			testObject.editFilters = formValues.filtersForm;
			testObject.filters = formValues.filtersForm;
		}

		testObject.groupByIncrement = groupByIncrementObject.propValue;
		testObject.aggregationFunction = groupByShowObject.propValue;

		if (graphName) {
			testObject.graphName = graphName;
		} else {
			const categoryName = categories.find((category) => (
				`${category.name}` === formValues.radioCategory
			)).name;
			const formattedCategoryName = intl.formatMessage({
				id: categoryName,
        defaultMessage: 'Unlocalized category name'
			});
			const formattedIncrementLabel = intl.formatMessage({
				id: groupByIncrementObject.label,
				defaultMessage: 'Unlocalized Increment By Label'
			});

			testObject.graphName = `${formattedCategoryName} by ${formattedIncrementLabel}`;
		}

		if (isCycle(formValues.groupByDate)) {
			testObject.groupByColumn = 'platform';
			testObject.filters.platform = { [formValues.groupBySelector]: true };
			if (isHistoricalCycle(formValues.groupByDate)) {
				testObject.incrementBy = 'billcycle';
			}
		} else {
			testObject.groupByColumn =
				attributes && attributes.length > 0
					? attributes
						.find(
							(attribute) =>
								`${attribute.name}` === formValues.groupBySelector ||
									`${attribute.id}` === formValues.groupBySelector
						)
						.name.toLowerCase()
					: '';
		}

		const finalObject = {
			type: formValues.chartForm,
			groupByColumn: testObject.groupByColumn,
			groupByColumnId: formValues.groupBySelector,
			groupByDateId: formValues.groupByDate,
			filters: testObject.filters,
			incrementBy: testObject.groupByIncrement,
			incrementById: formValues.groupByIncrement,
			incrementType: groupByIncrementObject.type,
			aggregationFunction: testObject.aggregationFunction,
			aggregationFunctionId: formValues.groupByShow,
			radioCategory: formValues.radioCategory,
			categoryId: categories && categories.length > 0 ? categories.find(
				(category) => `${category.name}` === formValues.radioCategory
			).id : '',
			editFilters: testObject.editFilters,
			groupByDate: formValues.groupByDate,
			name: testObject.graphName,
			units: categories && categories.length > 0
				? categories.find((category) => `${category.name}`
					=== formValues.radioCategory).unit
				: '',
			showSummary: formValues.showSummary,
		};

		finalObject.dateFrom = from.toISOString();
		finalObject.dateTo = to.toISOString();

		if (isAccountOverviewSuperAdminModal) {
			finalObject.description = description ||
				intl.formatMessage({
					id: 'NO_DESCRIPTION_FOR_GRAPH',
					defaultMessage: 'No description for this graph'
				});
		}

		this.setState({
			temporarySetting: {
				...finalObject,
				i: 'modal',
				w: 3,
				name: ''
			},
			refresh: true
		});

		return finalObject;
	};

	finish = () => {
		const {
			updateSettings,
			editItem,
			settingsState,
			dataGridSize,
			getModalChartData,
			isAccountOverviewSuperAdminModal,
			addChart,
			onClose,
			initialize
		} = this.props;
		const { isEditing } = this.state;

		if (isAccountOverviewSuperAdminModal) {
			if (editItem.name) {
				addChart({ ...editItem, ...this.generateFinalObject() });
			} else {
				addChart(this.generateFinalObject());
			}
		} else if (isEditing) {
			const tempLayout = cloneDeep(settingsState);
			const itemToUpdateIndex = settingsState[dataGridSize].findIndex(
				(item) => item.i === editItem.i
			);

			Object.keys(tempLayout).forEach((key) => {
				tempLayout[key][itemToUpdateIndex] = {
					...editItem,
					...this.generateFinalObject()
				};
			});
			updateSettings(tempLayout);
			getModalChartData(tempLayout[dataGridSize][itemToUpdateIndex]);
		} else {
			addChart(this.generateFinalObject());
		}

		onClose();
		this.resetState(initialize);
	};

	shouldBeDisabled = () => {
		const {
			categoryId,
			isAccountOverviewSuperAdminModal,
			editItem,
			groupBySelector,
			chartType,
			isPristine,
			attributes,
			categories
		} = this.props;
		const {
			graphName,
			description,
			currentStep,
			editDescriptionVisible,
			editGraphNameVisible,
			from,
			to
		} = this.state;
		let shouldDisable = false;

		if (editItem) {
			shouldDisable = isPristine && editItem.name === graphName;

			if (editItem.groupByDateId === '3' || editItem.groupByDate === '3') {
				shouldDisable =
					shouldDisable &&
					from.getTime() === this.extractDate(editItem.dateFrom).getTime() &&
					to.getTime() === this.extractDate(editItem.dateTo).getTime();
			}

			if (isAccountOverviewSuperAdminModal) {
				shouldDisable = shouldDisable && editItem.description === description;
			}
		}

		if (
			currentStep < 4 ||
			this.isPending() ||
			!groupBySelector ||
			!categoryId ||
			!chartType ||
			editDescriptionVisible ||
			editGraphNameVisible ||
			!categories ||
			categories.length === 0 ||
			!attributes ||
			attributes.length === 0
		) {
			shouldDisable = true;
		}

		return shouldDisable;
	};

	renderNameDescriptionInputs = (
		currentStateValue,
		editStateValue,
		editStateBool
	) => {
		const {
			[currentStateValue]: currentValue,
			[editStateValue]: editValue,
			editedName: tempName,
			editedDescription: tempDescription
		} = this.state;

		return (
			<div
				data-spec={`analytics-modal-input-${currentStateValue}`}
				className={styles.name_description_input}
			>
				<Input
					dataSpec={`input-graph-${currentStateValue}`}
					input={{
						onChange: (event) =>
							this.setState({ [editStateValue]: event.target.value }),
						value: editStateValue === 'editedName' ? tempName : tempDescription
					}}
					meta={{}}
					className={styles.graph_input}
					maxLength={currentStateValue === 'graphName' ? '50' : '250'}
				/>
				<CheckIcon
					data-spec={`accept-graph-${currentStateValue}-change`}
					onClick={() =>
						this.setState({
							[editStateBool]: false,
							[currentStateValue]: editValue.trim()
						})}
					className={styles.modal_button_check}
				/>
				<CloseIcon
					data-spec={`cancel-graph-${currentStateValue}-change`}
					onClick={() =>
						this.setState({
							[editStateBool]: false,
							[editStateValue]: currentValue
						})}
					className={styles.modal_button_cancel}
				/>
			</div>
		);
	};

	renderNameDescriptionLabel = (
		currentStateValue,
		defaultLabel,
		makeEditingVisibleFunction
	) => {
		const { [currentStateValue]: currentValue } = this.state;

		return (
			<div
				data-spec={`analytics-modal-label-${currentStateValue}`}
				className={styles.name_description_label}
			>
				<span className={styles.modal_note}>
					{currentValue || defaultLabel}
				</span>
				<Edit
					data-spec={`edit-graph-${currentStateValue}`}
					onClick={() => makeEditingVisibleFunction()}
				/>
			</div>
		);
	};

	render() {
		const {
			show,
			categories,
			attributes,
			filters,
			editItem,
			getModalChartData,
			chartData,
			onClose,
			directionIndex,
			groupByDate,
			chartType,
			isAccountOverviewSuperAdminModal,
			dispatch,
			change,
			handleSubmit,
			groupBySelector,
			getFilters,
			filtersPending,
			formValues,
			initialize,
			isGetChartDataPending,
			isGetDirectionIndexPending,
			categoryId,
			intl,
		} = this.props;
		const {
			currentStep,
			temporarySetting,
			refresh,
			from,
			to,
			editGraphNameVisible,
			editDescriptionVisible
		} = this.state;
		const analyticsSteps = [
			{
				label: (
					<FormattedMessage
						id="ANALYTICS.FIRST_STEP_CHART_CATEGORIES"
						defaultMessage="1. Graph Categories"
					/>
				),
				component: (
					<FirstStep
						onNext={() => this.nextStep(1, currentStep)}
						categories={categories}
						currentStep={currentStep}
						shouldRefresh={this.hasChanged}
						getFilters={(id) => getFilters(attributes, id)}
						dispatch={dispatch}
						change={change}
					/>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ANALYTICS.SECOND_STEP_GROUP_DATA_BY"
						defaultMessage="2. Group Data By"
					/>
				),
				component: (
					<SecondStep
						onNext={() => this.nextStep(3, currentStep)}
						from={from}
						to={to}
						attributes={attributes}
						dateRange={dateRange}
						cycleArray={cycleArray}
						incrementArray={incrementArray}
						aggregationArray={aggregationArray}
						editItem={editItem}
						currentStep={currentStep}
						shouldRefresh={this.hasChanged}
						filters={filters}
						groupByDate={groupByDate}
						chartType={chartType}
						dispatch={dispatch}
						change={change}
						isMovingAverage={categoryId === 'ESTIMATED_AVERAGE_USAGE'}
						intl={intl}
						handleConfirm={this.handleConfirmDateRange}
					/>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ANALYTICS.THIRD_STEP_FILTERS"
						defaultMessage="3. Filters"
					/>
				),
				component: (
					<ThirdStep
						onNext={() => this.nextStep(3, currentStep)}
						filters={filters}
						currentStep={currentStep}
						shouldRefresh={this.hasChanged}
						isCycle={isCycle(groupByDate)}
						filtersPending={filtersPending}
						stateFilters={formValues && formValues.filtersForm}
					/>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ANALYTICS.FOURTH_STEP_CHART_OPTIONS"
						defaultMessage="4. Graph Options"
					/>
				),
				component: (
					<ForthStep
						onNext={() => this.nextStep(4, currentStep)}
						currentStep={currentStep}
						shouldRefresh={this.hasChanged}
						renderMapChoice={
							!isCycle(groupByDate) &&
							attributes &&
							attributes.find(
								(attribute) => attribute.name === groupBySelector
							) &&
							attributes.find((attribute) => attribute.name === groupBySelector)
								.isgeography
						}
					/>
				)
			}
		];

		return (
			<Modal
				data-spec="dashboard-notes-modal"
				show={show}
				onClose={() => {
					onClose();
					this.resetState(initialize);
				}}
				className={styles.modal}
				disableContainerClickClose
			>
				<ModalHeader
					title={
						editItem && Object.keys(editItem).length > 0 ? (
							<FormattedMessage
								id="ANALYTICS.EDIT_CHART"
								defaultMessage="Edit Chart"
							/>
						) : (
							<FormattedMessage
								id="ANALYTICS.CREATE_NEW_CHART"
								defaultMessage="Create New Graph"
							/>
						)
					}
				/>
				<form onSubmit={handleSubmit(this.finish)}>
					<ModalBody className={styles.modal_body}>
						<div className={styles.user_actions}>
							{editGraphNameVisible
								? this.renderNameDescriptionInputs(
									'graphName',
									'editedName',
									'editGraphNameVisible'
								  )
								: this.renderNameDescriptionLabel(
									'graphName',
									intl.formatMessage({
										id: 'ANALYTICS.DEFAULT_NAME',
										defaultMessage: 'Graph Name',
									}),
									this.editGraphName
								  )}
							{isAccountOverviewSuperAdminModal &&
								(editDescriptionVisible
									? this.renderNameDescriptionInputs(
										'description',
										'editedDescription',
										'editDescriptionVisible'
									  )
									: this.renderNameDescriptionLabel(
										'description',
										intl.formatMessage({
											id: 'ANALYTICS.DEFAULT_DESCRIPTION',
											defaultMessage: 'This is a description of the graph.'
										}),
										this.editGraphDescription
									  ))}
						</div>
						{currentStep < 4 || !temporarySetting ? (
							<div data-spec="body-note" className={styles.body_note}>
								<ChartIcon />
							</div>
						) : (
							!refresh && (
								<WrapperGridCard
									widthSize={3}
									hasDropdown={false}
									gridItem={temporarySetting}
									getContent={getModalChartData}
									isModal
									pendingData={this.isPending(
										isGetChartDataPending,
										isGetDirectionIndexPending,
										isAccountOverviewSuperAdminModal,
										temporarySetting
									)}
									contentData={chartData}
									isAnalytics
								>
									{!isAccountOverviewSuperAdminModal
									&& temporarySetting.aggregationFunction !== 'MVA'
									&& temporarySetting.showSummary ? (
										<UsageTrend
											data={chartData('modal_usage_trend')}
											isWide
											isModal
											directionIndex={directionIndex(temporarySetting.i)}
											isHistoricalCycle={isHistoricalCycle(
												temporarySetting.groupByDateId
											)}
											isPie={temporarySetting.type === 'pie'}
											unit={temporarySetting.units}
										/>
									) : (
										[]
									)}
								</WrapperGridCard>
							)
						)}
						<div className={styles.modal_disclaimer_wrapper}>
							<span>
								<FormattedMessage
									id="ANALYTICS.DISCLAIMER"
									defaultMessage="Disclaimer: "
								/>
							</span>
							<span>
								<FormattedMessage
									id="ANALYTICS.CREATE_NEW_CHART_DISCLAIMER"
									defaultMessage={
										'Charting data is provided by multiple systems in various time intervals. The data in the graphs will provide sum total that is approximate.\n Data is represented in UTC-0 time. Final data related to billed services will be available on invoice for the billing cycle.'
									}
								/>
							</span>
						</div>
						{!categories || !attributes ? (
							<Loader className={styles.loader} />
						) : (
							<FlowSteps
								steps={analyticsSteps}
								currentStep={currentStep}
								isPending={this.isPending()}
							/>
						)}
					</ModalBody>
					<ModalFooter data-spec="ds-modal-footer">
						<Button
							variant="link"
							onClick={() => {
								onClose();
								this.resetState(initialize);
							}}
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						/>
						<Button
							dataSpec="continue"
							variant="primary"
							type="submit"
							label={
								<FormattedMessage
									id="SAVE_AND_CLOSE"
									defaultMessage="Save and Close"
								/>
							}
							disabled={this.shouldBeDisabled()}
						/>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const {
	func,
	bool,
	object,
	arrayOf,
	string,
	number,
	objectOf,
	array,
	oneOfType,
	symbol,
} = PropTypes;

AnalyticsModal.propTypes = {
	onClose: func,
	show: bool,
	addChart: func,
	categories: arrayOf(object),
	attributes: arrayOf(object),
	filters: objectOf(array),
	getCategories: func,
	getAttributes: func,
	getFilters: func,
	editItem: objectOf(oneOfType([string, number, bool, object])),
	getModalChartData: func,
	isGetChartDataPending: func,
	chartData: func,
	directionIndex: func,
	isGetDirectionIndexPending: func,
	settingsState: objectOf(array),
	dataGridSize: string,
	updateSettings: func,
	groupByDate: number,
	groupBySelector: string,
	formValues: objectOf(oneOfType([string, object, bool])),
	chartType: string,
	isAccountOverviewSuperAdminModal: bool,
	dispatch: func,
	change: func,
	handleSubmit: func,
	categoryId: string,
	filtersPending: bool,
	initialize: func,
	isPristine: bool,
	categoriesPending: bool,
	attributesPending: bool,
	intl: objectOf(oneOfType([object, func, string, symbol])),
};

AnalyticsModal.defaultProps = {
	show: false,
	categories: [],
	attributes: [],
	filters: {},
	editItem: {},
	settingsState: {},
	dataGridSize: '',
	groupByDate: 0,
	groupBySelector: '',
	formValues: {},
	chartType: '',
	isAccountOverviewSuperAdminModal: false,
	categoryId: '',
	filtersPending: false,
	onClose: undefined,
	addChart: undefined,
	getCategories: undefined,
	getAttributes: undefined,
	getFilters: undefined,
	getModalChartData: undefined,
	isGetChartDataPending: undefined,
	chartData: undefined,
	directionIndex: undefined,
	isGetDirectionIndexPending: undefined,
	updateSettings: undefined,
	dispatch: undefined,
	change: undefined,
	handleSubmit: undefined,
	initialize: undefined,
	isPristine: false,
	categoriesPending: false,
	attributesPending: false,
	intl: {},
};

export default injectIntl(AnalyticsModal);
