import { createAction } from 'redux-actions';
import { postm2mAccount } from '../../services/EndpointsService';

import {
	UPDATE_BULK_M2M_ACCOUNT_REQUEST,
	UPDATE_BULK_M2M_ACCOUNT_SUCCESS,
	UPDATE_BULK_M2M_ACCOUNT_FAIL,
	UPDATE_BULK_M2M_ACCOUNT_STARTED,
	UPDATE_BULK_M2M_ACCOUNT_ENDED,
	UPDATE_BULK_M2M_ACCOUNT_ADD_FILTERED_SIM,
	UPDATE_BULK_M2M_ACCOUNT_ERROR_CLEAR
} from './constants';

const updateBulkm2mAccountStatusRequest = createAction(
	UPDATE_BULK_M2M_ACCOUNT_REQUEST
);
const updateBulkm2mAccountStatusSuccess = createAction(
	UPDATE_BULK_M2M_ACCOUNT_SUCCESS
);
const updateBulkm2mAccountStatusFail = createAction(
	UPDATE_BULK_M2M_ACCOUNT_FAIL
);
const updateBulkm2mAccountAddFilteredSims = createAction(
	UPDATE_BULK_M2M_ACCOUNT_ADD_FILTERED_SIM
);
export const updateBulkm2mAccountStatusStarted = createAction(
	UPDATE_BULK_M2M_ACCOUNT_STARTED
);
export const updateBulkm2mAccountStatusEnded = createAction(
	UPDATE_BULK_M2M_ACCOUNT_ENDED
);
export const updateBulkm2mAccountErrorClear = createAction(
	UPDATE_BULK_M2M_ACCOUNT_ERROR_CLEAR
);

export const updateBulkm2mAccount = (data, count = 0) => async (dispatch) => {
	if (count >= data.selectedSims.length) {
		dispatch(updateBulkm2mAccountStatusEnded());
	} else {
		const updateData = {
			...data.newOperator,
			targetICCID: data.selectedSims[count].endpointName
		};
		if (!data.selectedSims[count].euiccid) {
			dispatch(
				updateBulkm2mAccountAddFilteredSims({
					id: data.selectedSims[count].endpointName
				})
			);
			dispatch(updateBulkm2mAccount(data, count + 1));
		} else {
			dispatch(updateBulkm2mAccountStatusRequest(updateData));
			setTimeout(() => {
				postm2mAccount(updateData)
					.then(() => {
						dispatch(updateBulkm2mAccountStatusSuccess(updateData));
						dispatch(updateBulkm2mAccount(data, count + 1));
					})
					.catch(() => {
						dispatch(updateBulkm2mAccountStatusFail(updateData));
						dispatch(updateBulkm2mAccount(data, count + 1));
					});
			}, 1000);
		}
	}
};
