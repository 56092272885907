import { handleActions } from 'redux-actions';

import {
	CANCEL_SCHEDULE_STATUS_MODAL_OPEN,
	CANCEL_SCHEDULE_STATUS_MODAL_CLOSE
} from './constants';

export const initialState = {
	isOpened: false
};

export default handleActions(
	{
		[CANCEL_SCHEDULE_STATUS_MODAL_OPEN]: (state) => ({
				...state,
				isOpened: true
			}),
		[CANCEL_SCHEDULE_STATUS_MODAL_CLOSE]: (state) => ({
				...state,
				isOpened: false
			})
	},
	initialState
);
