import React from 'react';
import {
	string,
	objectOf,
	func,
	arrayOf,
	object,
	bool,
	array,
	number,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import DSDateRangePicker
	from '../../../../../lib/DigitalComponents/DateRangePicker';
import DropdownSelectNew
	from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import Table from '../../../../../lib/DigitalComponents/Table';
import NoData from '../../../../Shared/components/NoData/NoData';
import EditTableModal from '../../EditTableModal';
import hoc from '../Usage';

import styles from './Desktop.scss';

const EditTableIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/guide-setup.svg',
);
const ExportDataIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg',
);

function Desktop({
	messages,
	tableData,
	tableOptions,
	isEditTableModalOpen,
	closeEditTableModal,
	submitColumns,
	settings,
	usageSignature,
	header,
	getEndpointUsageDataRequest,
	type,
	types,
	from,
	to,
	changeDataType,
	handleConfirm,
	openEditTableModal,
	limitByDays,
	dateRangePickerLabel,
	dateRangePickerInputLabel,
	dateRangePickerButtonConfirmLabel,
	dateRangePickerButtonCancelLabel,
	exportUsageData,
	isExportUsageDataPending,
}) {
	const renderTable = () => {
		if (getEndpointUsageDataRequest) {
			return (
				<FormattedMessage
					data-spec="usage-loader"
					id="FETCHING_DATA"
					defaultMessage="Please wait. Fetching data..."
				>
					{(formattedValue) => (
						<Loader msg={formattedValue} />
					)}
				</FormattedMessage>
			);
		}
		if (tableData.length === 0) {
			return 	<NoData
				title={
							<FormattedMessage
								id="SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE"
								defaultMessage="There is no data to show for the selected period"
							/>
						}
				subtitle={
							<FormattedMessage
								id="SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST"
								defaultMessage="If avaliable, try changing the date range to refresh the list"
							/>
						}
				data-spec="no-data-usage-table"
				table
				styles={styles}
			/>;
		}

		return (
			<Table
				data={tableData}
				options={tableOptions(false)}
				messages={messages}
				data-spec="usage-table"
			/>
		);
	};

	return (
		<div data-spec="usage-desktop" className={styles.desktop_wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>
					<span>
						<FormattedMessage
							id="USAGE_HISTORY"
							defaultMessage="Usage History"
						/>
					</span>
				</div>
				<div className={styles.dropdown_wrapper}>
					<span className={styles.usage_text}>
						<FormattedMessage id="USAGE_TYPE" defaultMessage="Usage Type" />
					</span>
					<div className={styles.dropdown}>
						<DropdownSelectNew
							value={type}
							options={!getEndpointUsageDataRequest
								? types
								: []}
							onChange={changeDataType}
						/>
					</div>
				</div>
				<div className={styles.date_icons}>
					<DSDateRangePicker
						handleConfirm={handleConfirm}
						startDate={from}
						endDate={to}
						minDate={new Date(0)}
						maxDate={new Date()}
						disabled={getEndpointUsageDataRequest}
						limitByDays={limitByDays}
						label={dateRangePickerLabel}
						inputLabel={dateRangePickerInputLabel}
						buttonConfirmLabel={dateRangePickerButtonConfirmLabel}
						buttonCancelLabel={dateRangePickerButtonCancelLabel}
					/>
					{tableData && tableData.length > 0 ? (
						<div className={styles.picker_icon}>
							<FormattedMessage
								id="EDIT_TABLE_PARAMETERS"
								defaultMessage="Edit Table Parameters"
							>
								{(formattedValue) => (
									<EditTableIcon
										onClick={openEditTableModal}
										data-tip={formattedValue}
										data-for="edit-table-icon-tooltip"
									/>
								)}
							</FormattedMessage>
							<ReactTooltip
								type="light"
								id="edit-table-icon-tooltip"
							/>
							{isExportUsageDataPending ? (
								<>
									<FormattedMessage
										id="SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE"
										defaultMessage="Please wait while we prepare the file for you. Depending on the number of records, this might take some time to complete."
									>
										{(formattedValue) => (
												<div
													data-for="export-usage-tooltip-message"
													data-tip={formattedValue}
													className={styles.loader_custom_style}
												>
													<Loader />
												</div>
										)}
									</FormattedMessage>
									<ReactTooltip
										type="light"
										id="export-usage-tooltip-message"
									/>
								</>
							) : (
								<FormattedMessage
									id="SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE"
									defaultMessage="Export Usage Data"
								>
									{(formattedValue) => (
											<ExportDataIcon
												onClick={exportUsageData}
												data-tip={formattedValue}
												data-for="export-usage-data-icon-tooltip"
											/>
									)}
								</FormattedMessage>
							)}
							<ReactTooltip
								type="light"
								id="export-usage-data-icon-tooltip"
							/>
						</div>
					) : null}
				</div>
			</div>
			{renderTable()}
			{tableData && tableData.length > 0 ? (
				<EditTableModal
					show={isEditTableModalOpen}
					onClose={closeEditTableModal}
					customFieldLabels={[]}
					messages={messages}
					submitColumns={submitColumns}
					columns={settings}
					signature={usageSignature}
					tableTabs={header}
					excludeColumns={[]}
				/>
			) : null}
		</div>
	);
}

Desktop.propTypes = {
	messages: objectOf(string),
	tableData: arrayOf(object),
	tableOptions: func,
	isEditTableModalOpen: bool,
	closeEditTableModal: func,
	submitColumns: func,
	settings: object,
	usageSignature: string,
	header: array,
	getEndpointUsageDataRequest: bool,
	type: object,
	types: array,
	from: object,
	to: object,
	changeDataType: func,
	handleConfirm: func,
	openEditTableModal: func,
	limitByDays: number,
	dateRangePickerLabel: string,
	dateRangePickerInputLabel: string,
	dateRangePickerButtonConfirmLabel: string,
	dateRangePickerButtonCancelLabel: string,
	exportUsageData: func,
	isExportUsageDataPending: bool,
};

Desktop.defaultProps = {
	messages: {},
	tableData: {},
	tableOptions: undefined,
	changeDataType: undefined,
	type: '',
	isEditTableModalOpen: false,
	closeEditTableModal: undefined,
	submitColumns: undefined,
	settings: {},
	usageSignature: '',
	header: [],
	openEditTableModal: undefined,
	getEndpointUsageDataRequest: false,
	handleConfirm: undefined,
	limitByDays: 0,
	dateRangePickerLabel: '',
	dateRangePickerInputLabel: '',
	dateRangePickerButtonConfirmLabel: '',
	dateRangePickerButtonCancelLabel: '',
	exportUsageData: undefined,
	isExportUsageDataPending: false,
};

export default hoc(Desktop);
