import { handleActions } from 'redux-actions';

import { Status } from 'utils/constants';

import {
	DOWNLOAD_TEMPLATE_FAIL,
	DOWNLOAD_TEMPLATE_REQUEST,
	DOWNLOAD_TEMPLATE_SUCCESS,
	FETCH_ALL_TEMPLATES_REQUEST,
	FETCH_ALL_TEMPLATES_SUCCESS,
	FETCH_ALL_TEMPLATES_FAIL
} from './constants';

export const initialState = {
	templates: [],
	fetchingTemplates: Status.INIT,
	fetchTemplatesError: false,
	downloadingTemplate: Status.INIT,
	downloadTemplateError: false
};

export default handleActions(
	{
		[FETCH_ALL_TEMPLATES_REQUEST]: (state) => ({
				...state,
				fetchingTemplates: Status.PENDING,
				fatchTemplatesError: null
			}),
		[FETCH_ALL_TEMPLATES_SUCCESS]: (state, action) => ({
				...state,
				fetchingTemplates: Status.DONE,
				templates: action.payload
			}),
		[FETCH_ALL_TEMPLATES_FAIL]: (state, action) => ({
				...state,
				fetchingTemplates: Status.FAIL,
				templates: [],
				fetchTemplatesError: action.payload.error
			}),
		[DOWNLOAD_TEMPLATE_REQUEST]: (state) => ({
				...state,
				downloadingTemplate: Status.PENDING,
				downloadTemplateError: null
			}),
		[DOWNLOAD_TEMPLATE_SUCCESS]: (state) => ({
				...state,
				downloadingTemplate: Status.DONE,
				downloadTemplateError: null
			}),
		[DOWNLOAD_TEMPLATE_FAIL]: (state, action) => ({
				...state,
				downloadingTemplate: Status.DONE,
				downloadTemplateError: action.payload.error
			})
	},
	initialState
);
