import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import {
	required,
	onlyNumbers,
	maxLength,
	requiredCheckbox
} from '../../../../utils/validators';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import Loading from '../../../../lib/DigitalComponents/Loader';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import Radio from '../../../../lib/DigitalComponents/Radio';

import PageTitle from '../../../Shared/views/PageTitleView';
import { resetFields } from '../../../../utils/constants';
import { billingPlatform } from '../../utils/constants';
import { localizationHelper } from '../../../../utils/helperFunctions';

import styles from './AddAccount.scss';

const maxLength30 = maxLength(30);

const InfoFull = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');

class AddAccount extends Component {
	componentDidMount() {
		const {
			getPlatformTypes,
			match: {
				params: { companyId }
			}
		} = this.props;

		getPlatformTypes();

		if (companyId) {
			this.getCompany(companyId);
		}
	}

	componentWillUnmount() {
		const { getCompanyInit, validateAccountInit } = this.props;
		getCompanyInit();
		validateAccountInit();
	}

	getCompany = (companyId) => {
		const { getCompany } = this.props;
		getCompany({
			id: companyId
		});
	};

	submitForm = (values) => {
		const {
			addAccount,
			match: {
				params: { companyId }
			},
			validateAccountSuccess
		} = this.props;

		let request = {
			companyId
		};
		request = {
			...request,
			m2mAccountId: values.m2mAccountId.trim(),
			m2mAccountName:
				values.m2mAccountName ||
				(validateAccountSuccess &&
					validateAccountSuccess.accountInformation &&
					validateAccountSuccess.accountInformation.accountName),
			platformTypeCategoryId: values.platformCategoryTypeId.id,
			platformTypeId: values.platformType.id
		};

		if (values.m2mBillingAccountId) {
			request.m2mBillingAccountId = values.m2mBillingAccountId;
		}

		if (values.m2mBillingPlatform) {
			request.m2mBillingPlatform = values.m2mBillingPlatform.value;
		}

		if (values.m2mSimLoader) {
			request.m2mSimLoader = values.m2mSimLoader === 'true';
		}

		addAccount(request);
	};

	getPods = (value) => {
		const { getPlatforms } = this.props;
		getPlatforms({
			searchParams: [
				{ propValue: value, prop: 'platformTypeCategoryId', operator: '=' }
			]
		});
	};

	validateAccountId = () => {
		const { validateAccount, formData } = this.props;
		validateAccount({
			id: formData.platformType.id,
			m2mAccountId: formData.m2mAccountId
		});
	};

	getValidatedPlatfromName = (account) => {
		let name = '';
		if (account && account.isValidAccount) {
			name =
				(account &&
					account.accountInformation &&
					account.accountInformation.accountName) ||
				null;
			return name;
		}
		return null;
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId }
			}
		} = this.props;
		pushBack(`/companies/${companyId}`);
	};

	renderMobileForm = () => {
		const {
			formData,
			platforms,
			validateAccountSuccess,
			getPlatformsRequest,
			intl,
			match: {
				params: { platformId }
			},
			validateAccountRequest
		} = this.props;

		return (
			<div data-spec="form">
				<>
					<FieldWrapper required>
						<FormattedMessage
							id="ONBOARDING.POD_TENANT_INSTANCE_ID"
							defaultMessage="Pod, Tenant, or Instance ID"
						>
							{(formattedValue) => (
								<Field
									component={Select}
									name="platformType"
									label={formattedValue}
									validate={[required]}
									options={
										platforms &&
										platforms.resultList &&
										platforms.resultList.length > 0 &&
										platforms.resultList.map((item) => ({
											value: item.id,
											label: item.description,
											id: item.id
										}))
									}
									loading={getPlatformsRequest}
									isDisabled={getPlatformsRequest}
									cleanValue
								/>
							)}
						</FormattedMessage>
					</FieldWrapper>

					<FieldWrapper required>
						<FormattedMessage
							id="ONBOARDING.M2M_ACCOUNT_ID"
							defaultMessage="M2M Account ID"
						>
							{(formattedValue) => (
								<Field
									component={Input}
									placeholder={formattedValue}
									label={formattedValue}
									name="m2mAccountId"
									dataSpec="m2mAccountId"
									validate={[required]}
									onChange={() =>
										(validateAccountSuccess ? this.validateAccountInit() : null)}
								/>
							)}
						</FormattedMessage>
					</FieldWrapper>
					{formData.platformCategoryTypeId.canBeValidated && (
						<div
							className={cn(
								styles.field_wrapper,
								styles.validate,
								formData.platformType && formData.m2mAccountId
									? styles.active
									: styles.disabled
							)}
						>
							<div
								onClick={
									formData.platformType && formData.m2mAccountId
										? () => this.validateAccountId()
										: null
								}
							>
								<FormattedMessage
									id="ONBOARDING.VALIDATE"
									defaultMessage="Validate"
								/>
							</div>
						</div>
					)}
					<div>
						{validateAccountRequest && <Loading />}
						{validateAccountSuccess &&
							(this.getValidatedPlatfromName(validateAccountSuccess) !==
							null ? (
								<>
									<FieldWrapper>
										<StaticField
											label={intl.formatMessage({
												id: 'ONBOARDING.M2M_ACCOUNT_NAME',
												defaultMessage: 'M2M Account Name'
											})}
											data={this.getValidatedPlatfromName(
												validateAccountSuccess
											)}
											single
											name="m2mAccountName"
										/>
									</FieldWrapper>
									<FieldWrapper>
										<FormattedMessage
											id="ONBOARDING.VALIDATE_ACCOUNT_NAME"
											defaultMessage="Validate Account Name"
										>
											{(formattedValue) => (
												<Field
													name="validate"
													component={Checkbox}
													type="checkbox"
													label={formattedValue}
													validate={[requiredCheckbox]}
												/>
											)}
										</FormattedMessage>
									</FieldWrapper>
								</>
							) : (
								<>
									<FieldWrapper>
										<></>
									</FieldWrapper>
									<FieldWrapper>
										<div className={styles.error_message}>
											<FormattedMessage
												id="ONBOARDING.ACCOUNT_ID_NOT_FOUND"
												defaultMessage="Account ID Not Found"
											/>
										</div>
									</FieldWrapper>
								</>
							))}
						{!formData.platformCategoryTypeId.canBeValidated && (
							<FieldWrapper required>
								<FormattedMessage
									id="ONBOARDING.M2M_ACCOUNT_NAME"
									defaultMessage="M2M Account Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											label={formattedValue}
											placeholder={formattedValue}
											name="m2mAccountName"
											dataSpec="m2mAccountName"
											validate={[required]}
										/>
									)}
								</FormattedMessage>
							</FieldWrapper>
						)}
					</div>
				</>
				{!formData.platformCategoryTypeId.canBeValidated && (
					<>
						<h5 className={styles.section_headline}>
							<FormattedMessage
								id="ONBOARDING.SIM_LOADING"
								defaultMessage="Sim Loading"
							/>
						</h5>

						<div className={styles.fields_wrapper}>
							<div
								className={cn(styles.field_wrapper, styles.field_wrapper_radio)}
							>
								<div className={styles.medium_field_wrapper}>
									<Field
										component={Radio}
										name="m2mSimLoader"
										label={
											<FormattedMessage
												id="ONBOARDING.REPORT"
												defaultMessage="Report"
											/>
										}
										value={`${false}` || false}
										type="radio"
									/>
								</div>

								<div className={styles.medium_field_wrapper}>
									<Field
										component={Radio}
										name="m2mSimLoader"
										label={
											<FormattedMessage
												id="ONBOARDING.GATEWAY"
												defaultMessage="Gateway"
											/>
										}
										value={`${true}` || true}
										type="radio"
									/>
								</div>
							</div>
						</div>
					</>
				)}

				<h5 className={styles.section_headline}>
					<FormattedMessage
						id="ONBOARDING.BILLING_INFORMATION"
						defaultMessage="Billing Information"
					/>
				</h5>
				<FieldWrapper required>
					<FormattedMessage
						id="ONBOARDING.BILLING_PLATFORM"
						defaultMessage="Billing Platform"
					>
						{(formattedValue) => (
							<Field
								component={Select}
								name="m2mBillingPlatform"
								label={formattedValue}
								options={billingPlatform.map((item) => ({
									value: item.name,
									label: (
										<FormattedMessage
											id={`ONBOARDING.${item.name.toUpperCase()}`}
											defaultMessage={item.name}
										/>
									)
								}))}
								isDisabled={platformId && platformId.length > 0}
								validate={[required]}
								cleanValue
							/>
						)}
					</FormattedMessage>
				</FieldWrapper>
				<FieldWrapper required>
					<FormattedMessage
						id="ONBOARDING.INVOICE_ACCOUNT_NUMBER"
						defaultMessage="Invoice Account Number"
					>
						{(formattedValue) => (
							<Field
								component={Input}
								placeholder={formattedValue}
								label={
									<>
										{formattedValue}
										<span className={styles.label_tooltip}>
											<FormattedMessage
												id={
													'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE'
												}
												defaultMessage={
													'This number is required to display invoices in the IoT Console.'
												}
											>
												{(tooltipFormattedMessage) => (
													<>
														<InfoFull
															data-tip={tooltipFormattedMessage}
															data-for={'tooltip'}
														/>
													</>
												)}
											</FormattedMessage>
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id={'tooltip'}
											/>
										</span>
									</>
								}
								name="m2mBillingAccountId"
								dataSpec="m2mBillingAccountId"
								validate={[maxLength30, onlyNumbers, required]}
							/>
						)}
					</FormattedMessage>
				</FieldWrapper>
			</div>
		);
	};

	validateAccountInit = () => {
		const { validateAccountInit } = this.props;
		validateAccountInit();
	};

	render() {
		const {
			handleSubmit,
			invalid,
			messages,
			formData,
			company,
			addAccountRequest,
			platformTypes,
			validateAccountSuccess,
			dispatch
		} = this.props;

		return (
			<div data-spec="add-account">
				<PageTitle
					title={company && company.companyFriendlyName}
					pushBack={
						company && company.companyFriendlyName ? this.pushBack : null
					}
					messages={messages}
				/>

				{!company || addAccountRequest ? (
					<Loading data-spec="loader" />
				) : (
					<form
						onSubmit={handleSubmit(this.submitForm)}
						className={styles.form_wrapper}
					>
						<h2 className={styles.form_headline}>
							<FormattedMessage
								id="ONBOARDING.NEW_ACCOUNT"
								defaultMessage="New Account"
							/>
						</h2>
						<div className={styles.form_subheadline}>
							<FormattedMessage
								id="ONBOARDING.NEW_ACCOUNT_SUBHEADLINE"
								defaultMessage="An account or sub-account must exisit in M2M platform before it can be added to MNC. Follow the steps
								below to create an M2M account to be provisioned in IoT Console. "
							/>
						</div>

						<h5 className={styles.section_headline}>
							<FormattedMessage
								id="ONBOARDING.ADD_M2M_PLATFORM"
								defaultMessage="Add M2M Platform"
							/>
						</h5>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.M2M_PLATFORM"
								defaultMessage="M2M Platform"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name="platformCategoryTypeId"
										label={formattedValue}
										options={
											platformTypes &&
											platformTypes.resultList &&
											platformTypes.resultList.length > 0 &&
											platformTypes.resultList.map((item) => ({
												id: item.id,
												value: item.id,
												label: (
													<FormattedMessage
														id={
															item.code &&
															`ONBOARDING.PLATFORM_${localizationHelper(
																item.code
															).toUpperCase()}`
														}
														defaultMessage={item.name}
													/>
												),
												name: item.name,
												canBeValidated: item.hasAutoValidation
											}))
										}
										validate={[required]}
										onChange={(item) => {
											this.getPods(item.value);
											resetFields(dispatch, 'AddAccountForm', {
												platformType: ''
											});
											this.validateAccountInit();
										}}
										cleanValue
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>

						<div>
							{formData &&
								formData.platformCategoryTypeId &&
								this.renderMobileForm(formData.platformCategoryTypeId)}
						</div>
						<br />
						<>
							<hr className={styles.divider} />
							<div className={styles.section_buttons}>
								<Button
									label={
										<FormattedMessage id="CANCEL" defaultMessage="Cancel" />
									}
									variant="outline-primary"
									type="button"
									dataSpec="close-button"
									onClick={this.pushBack}
								/>
								<Button
									label={
										<FormattedMessage
											id="ONBOARDING.CREATE_ACCOUNT"
											defaultMessage="Create Account"
										/>
									}
									variant="primary"
									type="submit"
									dataSpec="submit-button"
									disabled={
										invalid ||
										(formData &&
											formData.platformCategoryTypeId &&
											formData.platformCategoryTypeId.canBeValidated &&
											!validateAccountSuccess) ||
										(validateAccountSuccess &&
											this.getValidatedPlatfromName(validateAccountSuccess) ===
												null)
									}
								/>
							</div>
						</>
					</form>
				)}
			</div>
		);
	}
}

const { func, bool, object } = PropTypes;

AddAccount.propTypes = {
	handleSubmit: func,
	invalid: bool,
	getPlatforms: func,
	getPlatformsRequest: bool,
	platforms: object,
	messages: object,
	match: object,
	pushBack: func,
	formData: object,
	addAccount: func,
	getCompany: func,
	getCompanyInit: func,
	company: object,
	addAccountRequest: bool,
	validateAccountSuccess: object,
	platformTypes: object,
	validateAccountInit: func,
	getPlatformTypes: func,
	validateAccount: func,
	intl: object,
	dispatch: func,
	validateAccountRequest: bool
};

const AddAccountForm = reduxForm({
	form: 'AddAccountForm'
})(injectIntl(AddAccount));

export default AddAccountForm;
