import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import hoc from '../Actions';

import styles from './Desktop.scss';

import Button from '../../../../../lib/DigitalComponents/Button';

import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';

const RWComponent = UserAllowedToAccess([
	'mnc.automation_rw'
]);

class Desktop extends PureComponent {
	render() {
		const {
			createNewRule,
			isAlertsTab,
			selectedItems,
			rulesRequest,
			alertsRequest,
			openDeleteAlertsModal
		} = this.props;
		const isAnyRequest = rulesRequest || alertsRequest;
		return (
			<div data-spec="automation-actions-desktop" className={styles.container}>
				<RWComponent>
					<div className={styles.buttons_container}>
						{isAlertsTab && (
							<span className={styles.delete_button}>
								<Button
									label={
										<FormattedMessage
											id="AUTOMATION.DELETE"
											defaultMessage="Delete"
										/>
									}
									variant="primary"
									dataSpec="delete-automation-rule"
									disabled={
										isAnyRequest || (selectedItems && isEmpty(selectedItems))
									}
									onClick={() => openDeleteAlertsModal()}
								/>
							</span>
						)}
						<Button
							label={
								<FormattedMessage
									id="AUTOMATION.CREATE_NEW_AUTOMATION_RULE"
									defaultMessage="Create new automation rule"
								/>
							}
							variant="outline-primary"
							dataSpec="create-automation-rule"
							onClick={createNewRule}
							disabled={isAnyRequest}
						/>
					</div>
				</RWComponent>
			</div>
		);
	}
}

const { func, bool, array } = PropTypes;

Desktop.propTypes = {
	createNewRule: func,
	isAlertsTab: bool,
	selectedItems: array,
	rulesRequest: bool,
	alertsRequest: bool,
	openDeleteAlertsModal: func
};

export default hoc()(Desktop);
