/* eslint-disable function-paren-newline */
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';

import PageTitle from '../../../Shared/views/PageTitleView';
import AddInvoiceForm from '../AddInvoiceForm';
import AddInvoiceOverview from '../AddInvoiceOverview';

import styles from './AddInvoice.scss';

import {
	formatBillingCycle,
	formatInvoiceDateForApi,
} from '../../utils/helpers';

const submitForm = (values, _, props) => {
	const { fileSuccess, intl, postInvoiceAttachment } = props;

	const successMessage = intl.formatMessage({
		id: 'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE',
		defaultMessage: 'You have succesfully added new invoice.',
	});

	const errorMessage = intl.formatMessage({
		id: 'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE',
		defaultMessage:
			'An error has occured while adding invoice. Please try again.',
	});

	const file = fileSuccess[0];
	const splitedFiles = file.name.split('.');
	const extension = splitedFiles[splitedFiles.length - 1];

	let data = {
		m2mAccountId: values.m2mAccountId.value,
		invoiceId: values.invoiceId,
		fileLocation: file.location,
		invoiceDate: formatInvoiceDateForApi(values.invoiceDate),
		billingCycle: formatBillingCycle(values.billingCycle),
	};

	const invoiceFileName = values.fileName
		? `${values.fileName}.${extension}`
		: null;

	if (invoiceFileName) {
		data = {
			...data,
			invoiceFileName,
		};
	}

	const notifOptions = {
		id: 'add-new-invoice',
		successMessage,
		errorMessage,
	};

	postInvoiceAttachment(file.id, data, notifOptions);
};

let fileTimeStamp = null;

class AddInvoiceComponent extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isConfirmationScreen: false,
		};
	}

	componentDidMount() {
		const { getM2mAccounts } = this.props;
		getM2mAccounts();
	}

	componentDidUpdate(prevProps) {
		const { fileSuccess, postInvoiceSuccess, goToInvoices } = this.props;
		if (fileSuccess && prevProps.fileSuccess != fileSuccess) {
			fileTimeStamp = new Date();
    }
    if (
      postInvoiceSuccess &&
      prevProps.postInvoiceSuccess != postInvoiceSuccess
    ) {
      goToInvoices();
    }
	}

	componentWillUnmount() {
		const { resetFiles } = this.props;
		resetFiles();
	}

	setConfirmationScreen = (isConfirmationScreen) => {
		this.setState({
			isConfirmationScreen,
		});
	};

	renderButtons = () => {
		const { goToInvoices, valid, fileSuccess } = this.props;
		return (
			<div className={styles.buttons} data-spec="add-invoice-buttons">
				<Button
					className={styles.left_button}
					type="button"
					variant="outline-primary"
					label={
						<FormattedMessage id="INVOICES.CANCEL" defaultMessage="Cancel" />
					}
					onClick={() => goToInvoices()}
				/>
				<Button
					type="button"
					variant="primary"
					label={
						<FormattedMessage
							id="INVOICES.ADD_INVOICE"
							defaultMessage="Add Invoice"
						/>
					}
					onClick={() => this.setConfirmationScreen(true)}
					disabled={!valid || fileSuccess.length === 0}
				/>
			</div>
		);
	};

	renderConfirmationButtons = () => (
		<div
			className={styles.buttons}
			data-spec="add-invoice-confirmation-buttons"
		>
			<Button
				className={styles.left_button}
				type="button"
				variant="outline-primary"
				label={<FormattedMessage id="INVOICES.EDIT" defaultMessage="Edit" />}
				onClick={() => this.setConfirmationScreen(false)}
			/>
			<Button
				variant="primary"
				label={
					<FormattedMessage id="INVOICES.CONFIRM" defaultMessage="Confirm" />
				}
			/>
		</div>
	);

	render() {
		const { isConfirmationScreen } = this.state;
		const { goToInvoices, handleSubmit, m2mAccountsRequest, postInvoiceRequest } = this.props;

		const isAnyRequest = m2mAccountsRequest || postInvoiceRequest;

		return (
			<div data-spec="add-invoice">
				<PageTitle
					title={
						<FormattedMessage
							id={'INVOICES.ADD_NEW_INVOICE'}
							defaultMessage={'Add New Invoice'}
						/>
					}
					pushBack={() => goToInvoices()}
				/>
				{isAnyRequest && <Loader data-spec="loader" />}
				{!isAnyRequest && (
					<div className={styles.add_invoice}>
						<div className={styles.titles}>
							{isConfirmationScreen && (
								<label className={styles.confirmation_text}>
									<FormattedMessage
										id="INVOICES.CONFIRMATION"
										defaultMessage="Confirmation"
									/>
								</label>
							)}
							<label className={styles.subtitle}>
								<FormattedMessage
									id="INVOICES.INVOICE_DETAILS"
									defaultMessage="Invoice Details"
								/>
							</label>
						</div>

						<form
							onSubmit={handleSubmit((values, _, props) =>
								submitForm(values, _, props)
							)}
						>
							{isConfirmationScreen && (
								<div className={styles.add_invoice_body}>
									<AddInvoiceOverview
										{...this.props}
										fileTimeStamp={fileTimeStamp}
									/>
								</div>
							)}
							{isConfirmationScreen && this.renderConfirmationButtons()}

							{!isConfirmationScreen && (
								<div className={styles.add_invoice_body}>
									<AddInvoiceForm {...this.props} />
								</div>
							)}
							{!isConfirmationScreen && this.renderButtons()}
						</form>
					</div>
				)}
			</div>
		);
	}
}

const { func, object, bool, array } = PropTypes;

AddInvoiceComponent.propTypes = {
	goToInvoices: func,
	handleSubmit: func,
	getM2mAccounts: func,
	m2mAccountsRequest: bool,
	m2mAccounts: array,
	valid: bool,
	fileSuccess: array,
  resetFiles: func,
  postInvoiceRequest: bool,
  postInvoiceSuccess: object
};

const AddInvoice = reduxForm({
	form: 'addInvoice',
	enableReinitialize: true,
})(AddInvoiceComponent);

export default injectIntl(AddInvoice);
