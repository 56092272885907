import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import IFrame from '../../../../lib/DigitalComponents/IFrame';
import { getSwaggerUrl } from '../../../../utils/constants';
import Loader from '../../../../lib/DigitalComponents/Loader';

class ApiSandbox extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isIFrameLoading: true,
			frameHeight: '0px',
			frameWidth: '0px'
		};
	}

	iFrameLoaded = () => {
		this.setState({
			isIFrameLoading: false,
			frameWidth: '100%',
			frameHeight: '4200px'
		});
	};

	iFrameResized = (height) => {
		this.setState({
			frameHeight: `${height.toString()}px`
		});
	};

	render() {
		const { isIFrameLoading, frameHeight, frameWidth } = this.state;

		return (
			<div data-spec="api-sandbox">
				<h3>
					<FormattedMessage id="SWAGGER.API" defaultMessage="API" />
				</h3>
				<p>
					<FormattedMessage
						id="SWAGGER.SANDBOX_SPECIFIES_THE_LIST"
						defaultMessage="The Swagger sandbox below specifies the list of resources that are available in the REST API and the operations that can be called on those resources. The document also specifies the list of parameters to an operation, including the name and type of the parameters, whether the parameters are required or optional, and information about acceptable values for those parameters."
					/>
				</p>
				<p>
					<FormattedMessage
						id="SWAGGER.JSON_SCHEMA_INCLUDES"
						defaultMessage="JSON Schema is included that describes the structure of the request body that is sent to an operation in a REST API, along with the JSON schema that describes the structure of any response bodies that are returned from an operation."
					/>
				</p>
				<div data-spec="swagger">
					{isIFrameLoading ? <Loader data-spec="loader" /> : null}
					<IFrame
						onLoad={this.iFrameLoaded}
						onHeightInterval={this.iFrameResized}
						height={frameHeight}
						width={frameWidth}
						src={getSwaggerUrl()}
					/>
				</div>
			</div>
		);
	}
}
export default ApiSandbox;
