import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import Loading from '../../../../lib/DigitalComponents/Loader';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './DeleteSimSkuModal.scss';

const Alert = ImageSelector(CurrentContext.theme, 'svgs/alert.svg');

class DeleteSimSkuModal extends PureComponent {
	renderButtons() {
		const { simSku, deleteSimSkuRequest, onClose, deleteSimSku } = this.props;
		return (
			<div data-spec="buttons">
				<Button
					type="button"
					variant="link"
					label={<FormattedMessage id="NO" defaultMessage="No" />}
					dataSpec="no-button"
					onClick={onClose}
					disabled={deleteSimSkuRequest}
				/>
				<Button
					type="button"
					variant="primary"
					onClick={() => deleteSimSku(simSku.id)}
					label={<FormattedMessage id="YES" defaultMessage="Yes" />}
					dataSpec="yes-button"
					disabled={deleteSimSkuRequest}
				/>
			</div>
		);
	}

	renderBody = () => (
		<div className={styles.modal_content} data-spec="delete-sim-sku">
			<div className={styles.modal_text}>
				<FormattedMessage
					id="ONBOARDING.DELETE_SIM_SKU_MESSAGE"
					defaultMessage="By deleting this SKU you will delete all SIM Orders tied to this SKU. Are you sure you want to proceed?"
				/>
			</div>
			<div className={styles.icon_wrapper}>
				<Alert />
			</div>
		</div>
	);

	render() {
		const { onClose, deleteSimSkuRequest } = this.props;
		return (
			<Modal
				data-spec="force-reset-password-modal"
				show
				onClose={onClose}
				className={styles.modal}
			>
				<FormattedMessage
					id="ONBOARDING.DELETE_SIM_SKU"
					defaultMessage="Delete SIM SKU"
				>
					{(formattedValue) => (
						<ModalHeader title={formattedValue} closeModal={onClose} />
					)}
				</FormattedMessage>
				<ModalBody className={styles.modal_body}>
					{deleteSimSkuRequest ? (
						<Loading customStyle={{ minHeight: '100px' }} />
					) : (
						this.renderBody()
					)}
				</ModalBody>
				<ModalFooter data-spec="modal-footer">
					{this.renderButtons()}
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, object } = PropTypes;

DeleteSimSkuModal.propTypes = {
	onClose: func.isRequired,
	deleteSimSku: func,
	deleteSimSkuRequest: bool,
	simSku: object
};

export default DeleteSimSkuModal;
