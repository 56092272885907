import React from 'react';
import PropTypes from 'prop-types';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Card from '../Card';
import WrapperGridCard from '../WrapperGridCard';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './GridLayout.scss';

const ResponsiveGridLayout = WidthProvider(Responsive);
const DefaultIcon = ImageSelector(CurrentContext.theme, 'svgs/analytics.svg');

const GridLayout = ({
	settings,
	dataGridSize,
	hasCarousel,
	getContent,
	contentData,
	pendingData,
	dropdownItem,
	children,
	hasRefresh,
	isAnalytics,
	updateSettings,
	propSettings,
	asOfDate,
}) => {
	const tempLayouts = cloneDeep(settings);
	const onDragOrResizeStop = (layout, oldItem, newItem) => {
		const tempSettings = settings;

		if (!isEqual(oldItem, newItem)) {
			tempSettings[dataGridSize] = settings[dataGridSize].map(
				(item, index) => ({ ...item, ...layout[index] })
			);
			updateSettings(tempSettings);
		}
	};

	const onLayoutChange = debounce((layout) => {
		if (!propSettings || layout.length !== propSettings[dataGridSize].length) {
			updateSettings({
				lg: settings.lg.map((item, index) => ({ ...layout[index], ...item })),
				md: settings.md.map((item, index) => ({ ...layout[index], ...item })),
				sm: settings.sm.map((item, index) => ({ ...layout[index], ...item }))
			});
		}
	}, 500);

	return (
		<div data-spec="grid-layout">
			{settings && settings[dataGridSize] && (
				<ResponsiveGridLayout
					className="layout"
					layouts={tempLayouts}
					breakpoints={{ lg: 1101, md: 841, sm: 0 }}
					cols={{ lg: 3, md: 2, sm: 1 }}
					rowHeight={450}
					draggableCancel=".nonDraggable"
					draggableHandle=".draggable"
					onDragStop={onDragOrResizeStop}
					onResizeStop={onDragOrResizeStop}
					onLayoutChange={onLayoutChange}
					isResizable={dataGridSize !== 'sm'}
				>
					{settings[dataGridSize].map((item) => (
						<div
							data-spec="grid-item-card"
							key={item.i}
							className={styles.grid_item}
						>
							<Card data-spec={`grid-card-item-${item.name}`}>
								<WrapperGridCard
									widthSize={item.w}
									hasDropdown={dataGridSize !== 'sm'}
									hasRefresh={hasRefresh}
									gridItem={item}
									hasCarousel={hasCarousel}
									getContent={getContent}
									contentData={contentData}
									pendingData={pendingData(item.i)}
									dropdownItem={dropdownItem}
									isAnalytics={isAnalytics}
									asOfDate={asOfDate}
									dataGridSize={dataGridSize}
								>
									{children ? children(item) : []}
								</WrapperGridCard>
							</Card>
						</div>
					))}
				</ResponsiveGridLayout>
			)}
			{settings[dataGridSize].length === 0 && (
				<div
					data-spec="no-settings-message"
					className={styles.grid_layout_no_settings}
				>
					<div className={styles.no_data_icon}>
						<DefaultIcon />
					</div>
					<div className={styles.no_data_text}>
						<FormattedMessage
							id="NO_GRAPHS"
							defaultMessage="ADDED GRAPHS WILL BE DISPLAYED HERE"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

const { objectOf, string, func, bool, array } = PropTypes;

GridLayout.propTypes = {
	settings: objectOf(array),
	dataGridSize: string,
	hasCarousel: bool,
	getContent: func,
	contentData: func,
	pendingData: func,
	dropdownItem: func,
	children: func,
	hasRefresh: bool,
	isAnalytics: bool,
	updateSettings: func,
	propSettings: objectOf(array),
	asOfDate: func,
};

GridLayout.defaultProps = {
	settings: {},
	dataGridSize: '',
	hasCarousel: false,
	hasRefresh: false,
	isAnalytics: false,
	propSettings: {},
	getContent: undefined,
	contentData: undefined,
	pendingData: undefined,
	dropdownItem: undefined,
	children: undefined,
	updateSettings: undefined,
	asOfDate: undefined,
};

export default GridLayout;
