import React from 'react';
import { object } from 'prop-types';
import { injectIntl } from 'react-intl';
import { localizationHelper } from '../../../../utils/helperFunctions';

import StaticField from '../../../../lib/DigitalComponents/StaticField';

function CompanyAddressOverview({ address, intl }) {
  const renderCountryState = (prefix, value) =>
		intl.formatMessage({
			id: `${prefix}_${localizationHelper(value).toUpperCase()}`,
			defaultMessage: value
		});

	return (
		<>
			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.COUNTRY',
					defaultMessage: 'Country'
				})}
				data={
					(address.country &&
						address.country.name &&
						renderCountryState('COUNTRY', address.country.name)) ||
					(address.countryId &&
						address.countryId.label &&
						renderCountryState('COUNTRY', address.countryId.label))
				}
				single
			/>

			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.ADDRESS_LINE_1',
					defaultMessage: 'Address Line 1'
				})}
				data={address.street1}
			/>

			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.ADDRESS_LINE_2_OPTIONAL',
					defaultMessage: 'Address Line 2 (Optional)'
				})}
				data={address.street2}
			/>

			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.CITY_TOWN',
					defaultMessage: 'City/Town'
				})}
				data={address.city}
			/>

			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.STATE_PROVINCE',
					defaultMessage: 'State/Province'
				})}
				data={
					(address.state &&
						address.state.name &&
						renderCountryState('STATE', address.state && address.state.name)) ||
					(address.stateId &&
						address.stateId.label &&
						renderCountryState('STATE', address.stateId.label))
				}
			/>

			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.ZIP_POSTAL_CODE',
					defaultMessage: 'ZIP/Postal Code'
				})}
				data={address.zipCode}
			/>
		</>
	);
}

CompanyAddressOverview.propTypes = {
	address: object,
	intl: object
};

export default injectIntl(CompanyAddressOverview);
