import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';
import NoDataView from '../../../Shared/views/NoDataView';

import styles from './AccountOverviewModal.scss';

const AccountOverviewModal = ({
	show,
	onClose,
	addNewChart,
	tabs,
	onTabChange,
	existingCharts,
	selectedIdInModal,
	hasChartData,
	isGetDefinedSettingsPending,
}) => (
	<Modal
		data-spec="account-overview-modal"
		show={show}
		onClose={onClose}
		className={styles.modal}
	>
		<ModalHeader
			title={
				<FormattedMessage
					id="ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS"
					defaultMessage="Current Available Graphs"
				/>
			}
		/>
		<ModalBody className={styles.modal_body}>
			{isGetDefinedSettingsPending
			&& <Loader data-spec="account-overview-loader" />}
			{tabs && tabs.length > 0 && (
				<ModalTabs tabs={tabs} onTabChange={onTabChange} />
			)}
			{!isGetDefinedSettingsPending && (!tabs || tabs.length === 0) && (
				<NoDataView />
			)}
		</ModalBody>
		<ModalFooter>
			<Button
				dataSpec="close-account-overview-modal"
				variant="link"
				label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				onClick={onClose}
			/>
			<Button
				dataSpec="apply-account-overview-modal"
				variant="primary"
				label={<FormattedMessage id="APPLY" defaultMessage="Apply" />}
				onClick={() => {
					addNewChart();
					onClose();
				}}
				disabled={
					existingCharts
						.find((item) => item === selectedIdInModal) !==	undefined
					|| !hasChartData
				}
			/>
		</ModalFooter>
	</Modal>
);

const { func, bool, arrayOf, number, object } = PropTypes;

AccountOverviewModal.propTypes = {
	show: bool,
	onClose: func,
	addNewChart: func,
	tabs: arrayOf(object),
	onTabChange: func,
	existingCharts: arrayOf(number),
	selectedIdInModal: number,
	hasChartData: bool,
	isGetDefinedSettingsPending: bool,
};

AccountOverviewModal.defaultProps = {
	show: false,
	tabs: [],
	hasChartData: false,
	onClose: undefined,
	addNewChart: undefined,
	onTabChange: undefined,
	existingCharts: [],
	selectedIdInModal: 0,
	isGetDefinedSettingsPending: false,
};

export default AccountOverviewModal;
