import React, { useState } from 'react';
import { object, bool, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import hoc from '../Permissions';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';

import styles from './Desktop.scss';

const SuccessIcon = ImageSelector(CurrentContext.theme, 'svgs/ok-empty.svg');

function PermissionItem({ permission, read, readWrite, noAccess, indentLevel }) {
	const [isExpanded, setIsExpanded] = useState(true);

	const renderName = (val) => {
		const getRender = Boolean(
			val.childPermission && val.childPermission.length
		);
		const triggerIcon = cn({
			[styles.trigger__icon]: true,
			[styles.no_subpermissions]:
				val.childPermission && val.childPermission.length < 1,
			[styles.open]: isExpanded
		});

		const name = cn({
			[styles.level_one_indent]: indentLevel === 1,
			[styles.level_one_indent_no_children]: indentLevel === 1 && !getRender,
			[styles.level_two_indent]: indentLevel === 2
		});

		return (
			<div data-spec="name" className={styles.name_container}>
				{getRender && (
					<span
						onClick={() => setIsExpanded(!isExpanded)}
						className={triggerIcon}
					/>
				)}
				<div className={name}>{val.name}</div>
			</div>
		);
	};

	const getRowClass = (val) => {
		const getRender = Boolean(
			val.childPermission && val.childPermission.length
		);

		const level = cn({
			[styles.gray_row]: !getRender
		});

		return level;
	};

	return (
		<>
			<tr className={getRowClass(permission)}>
				<td>{renderName(permission)}</td>
				<td>
					{(permission.noAccess ||
						(!permission.readWrite && !permission.read && noAccess)) && (
						<SuccessIcon className={styles.success_icon} />
					)}
				</td>
				<td>
					{(permission.read ||
						(!permission.readWrite && !permission.noAccess && read)) && (
						<SuccessIcon className={styles.success_icon} />
					)}
				</td>
				<td>
					{(permission.readWrite ||
						(!permission.read && !permission.noAccess && readWrite)) && (
						<SuccessIcon className={styles.success_icon} />
					)}
				</td>
			</tr>

			{isExpanded && (
				<>
					{permission.childPermission &&
						permission.childPermission.map((item) => (
							<PermissionItem
								key={item.id}
								permission={item}
								indentLevel={indentLevel + 1}
								read={
									permission.read ||
									(!permission.read &&
										!permission.readWrite &&
										!permission.noAccess &&
										read)
								}
								readWrite={
									permission.readWrite ||
									(!permission.read &&
										!permission.readWrite &&
										!permission.noAccess &&
										readWrite)
								}
								noAccess={
									permission.noAccess ||
									(!permission.read &&
										!permission.readWrite &&
										!permission.noAccess &&
										noAccess)
								}
							/>
						))}
				</>
			)}
		</>
	);
}

function Desktop(props) {
	const { permissions } = props;
	return (
		<table data-spec="permissions-desktop" className={styles.permissions_table}>
			<thead>
				<tr>
					<th>
						<FormattedMessage
							id="ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS"
							defaultMessage="System-Level Entitlements"
						/>
					</th>
					<th>
						<FormattedMessage
							id="ONBOARDING.DENY_ACCESS"
							defaultMessage="Deny Access"
						/>
					</th>
					<th>
						<FormattedMessage
							id="ONBOARDING.ALLOW_READ"
							defaultMessage="Allow Read"
						/>
					</th>
					<th>
						<FormattedMessage
							id="ONBOARDING.ALLOW_WRITE"
							defaultMessage="Allow Write"
						/>
					</th>
				</tr>
			</thead>
			<tbody>
				{permissions.resultList.map((item) => (
					<PermissionItem
						key={item.id}
						permission={item}
						read={item.read}
						readWrite={item.readWrite}
						noAccess={item.noAccess}
						indentLevel={0}
					/>
				))}
			</tbody>
		</table>
	);
}

Desktop.propTypes = {
	permissions: object
};

PermissionItem.propTypes = {
	permission: object,
	readWrite: bool,
	read: bool,
	noAccess: bool,
	indentLevel: number
};

export default hoc(Desktop);
