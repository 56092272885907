import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { getDateFormat } from '../../../../utils/constants';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './Date.scss';

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/downloadable-file-l.svg'
);

class Date extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedId: null
		};
	}

	componentDidMount() {
		const { data } = this.props;
		data && data.details &&
			data.details.length &&
			this.setState({
				selectedId: {
					value: `${data.details[0].id}`,
					label: getDateFormat(data.details[0].report_date)
				}
			});
	}

	options = (data) => data && data.details && data.details.map((item) => {
			const detailsString = item.report_date
				? getDateFormat(item.report_date)
				: 'Invalid date';
			return {
				value: `${item.id}`,
				label: detailsString
			};
		});

	render() {
		const { data, id, downloadReportFile, uniqueId, dataLength } = this.props;
		const { selectedId } = this.state;
		const isDateEmpty = data && data.details && data.details.length === 0;
		const menuPosition = (uniqueId + 1) > (dataLength - 3) ? 'fixed' : 'absolute';
		const tab =
			data &&
			data.report_category &&
			data.report_category.toLowerCase().replace(/_/g, '-');

		return (
			<div data-spec="reports-date" className={styles.reports_date}>
				<Select
					noBorder
					className={styles.reports_select}
					name={`report${id}`}
					value={selectedId}
					options={this.options(data)}
					onChange={(item) => {
						this.setState({
							selectedId: {
								value: `${item.value}`,
								label: item.label
							}
						});
					}}
					menuPosition={menuPosition}
				/>
				{selectedId && !isDateEmpty ? (
					<div
						className={styles.color}
						onClick={() => {
							downloadReportFile({ tab, id: selectedId.value });
						}}
					>
						<DocumentDownloadIcon
							data-spec="download-icon"
							className={styles.icon}
						/>
						<FormattedMessage id="DOWNLOAD" defaultMessage="Download" />
					</div>
				) : (
					<div className={cn(styles.color, styles.disabled)}>
						<DocumentDownloadIcon
							data-spec="download-icon"
							className={cn(styles.icon, styles.disabled)}
						/>
						<FormattedMessage id="DOWNLOAD" defaultMessage="Download" />
					</div>
				)}
			</div>
		);
	}
}

const { func, number, shape } = PropTypes;

Date.propTypes = {
	data: shape(),
	downloadReportFile: func,
	id: number,
	uniqueId: number,
	dataLength: number,
};

export default Date;
