import { connect } from 'react-redux';
import SideBarItem from './SideBarItem';

import { simpleSearchClose } from '../../redux/simpleSearch/actions';
import { getIsSimpleSearchActive } from '../../redux/simpleSearch/selectors';
import { setDataForFiltersInit } from '../../redux/filterModal/actions';
import { setPaginationInit } from '../../redux/pagination/actions';

const mapStateToProps = (state) => ({
	searchActive: getIsSimpleSearchActive(state)
});

const mapDispatchToProps = (dispatch) => ({
	simpleSearchClose: () => dispatch(simpleSearchClose()),
  setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
  setPaginationInit: () => dispatch(setPaginationInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarItem);
