import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './GettingStarted.scss';
import { renderTabs, onNewTabClick } from '../../../../utils/tabHelpers';
import Tabs from '../Tabs';

import {
	getGettingStartedTabs,
	getGettingStartedComponents,
	gettingStartedTabsIds
} from '../../utils/helper';

class GettingStarted extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			tabs: getGettingStartedTabs(styles.image),
			tabComponents: getGettingStartedComponents(),
			activeTab: gettingStartedTabsIds.Node
		};
	}

	getAllTabs = () => {
		const { tabs, activeTab, tabComponents } = this.state;
		return tabs.map((tab) => ({
			name: tab.label,
			isActive: tab.id === activeTab,
			onClick: () => onNewTabClick(tab.id, this)(),
			component: tabComponents[tab.id],
			id: tab.id
		}));
	};

	renderAboveTabsPart = () => (
		<div data-spec="top-part">
			<h3>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_T"
					defaultMessage="Getting started with API"
				/>
			</h3>
			<p>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_P"
					defaultMessage="Before you can make your first API call, your source IP address (server) must be whitelisted. Source IP is typically configured during onboarding process when API access is created. If you receive any errors it is likely related to blocked access to AT&T IoT Gateway."
				/>
			</p>
			<h4>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_UL_T"
					defaultMessage="Please ensure you have taken the folloing steps to get started with development:"
				/>
			</h4>
			<ul>
				<li>
					<FormattedMessage
						id="SWAGGER.GETTING_STARTED_WITH_API_LI1"
						defaultMessage="- You have set up a publicly accessible server and provided us it’s IP address during onboarding."
					/>
				</li>
				<li>
					<FormattedMessage
						id="SWAGGER.GETTING_STARTED_WITH_API_LI2"
						defaultMessage="- Your Source IP is whitelisted to access AT&T IoT Gateway."
					/>
				</li>
				<li>
					<FormattedMessage
						id="SWAGGER.GETTING_STARTED_WITH_API_LI3"
						defaultMessage="- Your company Administrator has given you API username and password that was created when API service was enabled."
					/>
				</li>
				<li>
					<FormattedMessage
						id="SWAGGER.GETTING_STARTED_WITH_API_LI4"
						defaultMessage="- You have access to your resource server to execute curl commands, or use connection apps written in your preferred language below."
					/>
				</li>
			</ul>
			<h3>
				<FormattedMessage
					id="SWAGGER.GET_OAUTH_TOKEN_T"
					defaultMessage="Get OAuth access token"
				/>
			</h3>
			<p>
				<FormattedMessage
					id="SWAGGER.GET_OAUTH_TOKEN_P"
					defaultMessage="Get OAuth token from AT&T IoT Identity using username and password supplied by your administrator using your preferred method below. Token is valid for 7 days."
				/>
			</p>
			<h3>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T"
					defaultMessage="API connectors"
				/>
			</h3>
			<p>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P"
					defaultMessage="Select from the examples below how you wish to connect to our API."
				/>
			</p>
		</div>
	);

	renderBelowTabsPart = () => (
		<div data-spec="bottom-part" className={styles.below_tab}>
			<h3>
				<FormattedMessage
					id="SWAGGER.TEST_CONNECTION_T"
					defaultMessage="Test connection to the IoT Gateway"
				/>
			</h3>
			<p>
				<FormattedMessage
					id="SWAGGER.TEST_CONNECTION_P"
					defaultMessage="Curl example:"
				/>
			</p>
			<code>
				<pre>
					curl https://mciotgw.att.com/test/echo/testing -H &apos;Authorization:
					Bearer cis_token&apos;
				</pre>
			</code>
			<h3>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T"
					defaultMessage="Desired Result"
				/>
			</h3>
			<p>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P"
					defaultMessage="If you get HTTP Response Code 200 OK, you are all set to begin your development."
				/>
			</p>
			<h4>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T"
					defaultMessage="Troubleshooting Connection Errors"
				/>
			</h4>
			<p>
				<FormattedMessage
					id="SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P"
					defaultMessage="If you get any errors, first check to see if your authetication is properly set in the HTTPHeader, and the Access Token has not expired and needs to be refreshed. Also, ping AT&T IoT Gateway to check if your source IP is white-listed. If you get unexpected results you may file a troubleshooting ticket in support section.'"
				/>
			</p>
		</div>
	);

	render() {
		return (
			<div data-spec="getting-started-details">
				{this.renderAboveTabsPart()}
				<div className={styles.tabs_wrapper}>
					<div className={styles.tabs_sidebar}>
						<Tabs tabs={this.getAllTabs()} className={styles.actionBar} />
					</div>
					<div className={styles.tabs_content}>
						<code>{renderTabs(this.getAllTabs())}</code>
					</div>
				</div>
				{this.renderBelowTabsPart()}
			</div>
		);
	}
}

export default GettingStarted;
