import globalAction from '../../../../redux/global/globalAction';

import { GET_TECH_TYPES, GET_TECH_TYPES_FILTERS } from './constants';

import {
	getTechTypes as getTechTypesService,
	getTechTypesFilters as getTechTypesFiltersService
} from '../../services/TechTypesService';

/** ************ get tech types ************* */
export const getTechTypes = (id, params = {}) =>
	globalAction(GET_TECH_TYPES, getTechTypesService, { id, params });

/** ************ get tech types filters ************* */
export const getTechTypesFilters = (id, params = {}) =>
	globalAction(GET_TECH_TYPES_FILTERS, getTechTypesFiltersService, {
		id,
		params
	});
