import { Status } from '../../../utils/constants';

export const cancelRegistrationRequest = (state) =>
	state.singleEndpoint.cancelRegistration.status === Status.PENDING;

export const cancelRegistrationSuccess = (state) =>
	state.singleEndpoint.cancelRegistration.status === Status.DONE;

export const cancelRegistrationFail = (state) =>
	state.singleEndpoint.cancelRegistration.status === Status.FAIL;

export const cancelScheduleStatusSuccess = (state) =>
	state.singleEndpoint.cancelScheduleStatus.status === Status.DONE;

export const changeNetworkEntitlementPending = (state) =>
	state.singleEndpoint.changeNetworkEntitlement.status === Status.PENDING;

export const changeNetworkEntitlementFail = (state) =>
	state.singleEndpoint.changeNetworkEntitlement.status === Status.FAIL;

export const changeNetworkEntitlementSuccess = (state) =>
	state.singleEndpoint.changeNetworkEntitlement.status === Status.DONE;

export const customFieldUpdateSuccess = (state) =>
	state.singleEndpoint.customFieldUpdate.status	=== Status.DONE;

export const customFieldUpdateFail = (state) =>
	state.singleEndpoint.customFieldUpdate.status	=== Status.FAIL;

export const registrationHistorySuccess = (state) =>
	state.singleEndpoint.endpointRegistrationHistory.data;

export const registrationHistoryRequest = (state) =>
	state.singleEndpoint.endpointRegistrationHistory.status === Status.PENDING;

export const getDataHistoryPending = (state) =>
	state.singleEndpoint.getDataHistory.status === Status.PENDING;

export const getDataHistorySuccess = (state) =>
	state.singleEndpoint.getDataHistory.data
	&& state.singleEndpoint.getDataHistory.data.data;

export const getMessageHistoryPending = (state) =>
	state.singleEndpoint.getMessageHistory.status === Status.PENDING;

export const getMessageHistorySuccess = (state) =>
	state.singleEndpoint.getMessageHistory.data;

export const getNetworkEntitlementData = (state) =>
	state.singleEndpoint.getNetworkEntitlement.data
	&& state.singleEndpoint.getNetworkEntitlement.data.networkEntitlements;

export const isNetworkEntitlementPending = (state) =>
	state.singleEndpoint.getNetworkEntitlement.status === Status.PENDING;

export const getCurrentEntitlementM2MaccountId = (state) =>
	state.singleEndpoint.getNetworkEntitlement.currentM2mAccountId;

export const getNetworkEntitlementApnData = (state) =>
	state.singleEndpoint.getNetworkEntitlementApn.entitlementApn;

export const getNetworkEntitlementApnIsFetching = (state) =>
	state.singleEndpoint.getNetworkEntitlementApn.status === Status.PENDING;

export const getNoteSuccess = (state) =>
	state.singleEndpoint.getNote.data;

export const getNoteFail = (state) =>
	state.singleEndpoint.getNote.error;

export const getNotePending = (state) =>
	state.singleEndpoint.getNote.status === Status.PENDING;

export const getHistoryChange = (state) =>
	state.singleEndpoint.getHistoryChange.data
	&& state.singleEndpoint.getHistoryChange.data.txnEntries;

export const isHistoryChangeRequest = (state) =>
	state.singleEndpoint.getHistoryChange.status === Status.PENDING;

export const isHistoryChangeFailed = (state) =>
	state.singleEndpoint.getHistoryChange.status === Status.FAIL;

export const getTotalCount = (state) =>
	state.singleEndpoint.getHistoryChange.totalCount;

export const sendSmsMessageRequest = (state) =>
	state.singleEndpoint.sendSMSMessage.status === Status.PENDING;

export const sendSmsMessageSuccess = (state) =>
	state.singleEndpoint.sendSMSMessage.status === Status.DONE;

export const sendSmsMessageFail = (state) =>
	state.singleEndpoint.sendSMSMessage.status === Status.FAIL;

export const sendDiagnosticSmsMessageRequest = (state) =>
	state.singleEndpoint.sendDiagnosticSMSMessage.status === Status.PENDING;

export const sendDiagnosticSmsMessageSuccess = (state) =>
	state.singleEndpoint.sendDiagnosticSMSMessage.status === Status.DONE;

export const sendDiagnosticSmsMessageFail = (state) =>
	state.singleEndpoint.sendDiagnosticSMSMessage.status === Status.FAIL;

export const updateNoteSuccess = (state) =>
	state.singleEndpoint.updateNote.data;

export const updateNoteFail = (state) =>
	state.singleEndpoint.updateNote.error;

export const updateNotePending = (state) =>
	state.singleEndpoint.updateNote.status === Status.PENDING;

export const ism2mAccountUpdating = (state) =>
	state.singleEndpoint.updateM2MAccount.status === Status.PENDING;

export const m2mAccountUpdateFail = (state) =>
	state.singleEndpoint.updateM2MAccount.status === Status.FAIL;

export const m2mAccountUpdateSuccess = (state) =>
	state.singleEndpoint.updateM2MAccount.status === Status.DONE;

export const areCustomFieldsValuesUpdating = (state) =>
	state.singleEndpoint.updateCustomFieldsValues.status === Status.PENDING;

export const getUpdateCustomFieldsValuesFail = (state) =>
	state.singleEndpoint.updateCustomFieldsValues.status === Status.FAIL;

export const getUpdateCustomFieldsValuesSuccess = (state) =>
	state.singleEndpoint.updateCustomFieldsValues.status === Status.DONE;

export const getEndpointUsageDataSuccess = (state) => (
	state.singleEndpoint.getEndpointUsageData.data
);

export const getEndpointUsageChartDataSuccess = (state) => (
	state.singleEndpoint.getEndpointUsageChartData.data
	&& state.singleEndpoint.getEndpointUsageChartData.data.resultList
);

export const getEndpointUsageDataRequest = (state) => (
	state.singleEndpoint.getEndpointUsageData.status === Status.PENDING
);

export const getEndpointUsageChartDataRequest = (state) => (
	state.singleEndpoint.getEndpointUsageChartData.status === Status.PENDING
);

export const getEndpointUsageChartDataTotalAvgSuccess = (state) => (
	state.singleEndpoint.getEndpointUsageChartDataTotalAvg.data
	&& state.singleEndpoint.getEndpointUsageChartDataTotalAvg.data.resultList
);

export const getEndpointUsageChartDataTotalAvgRequest = (state) => (
	state.singleEndpoint.getEndpointUsageChartDataTotalAvg.status === Status.PENDING
);

export const isRatePlanUpdating = (state) =>
	state.singleEndpoint.updateRatePlan.status === Status.PENDING;

export const ratePlanUpdateFail = (state) =>
	state.singleEndpoint.updateRatePlan.status === Status.FAIL;

export const ratePlanUpdateSuccess = (state) =>
	state.singleEndpoint.updateRatePlan.status === Status.DONE;

export const isRatePlansFetching = (state) =>
	state.singleEndpoint.getRatePlans.status === Status.PENDING;

export const getRatePlansData = (state) =>
	state.singleEndpoint.getRatePlans.data
	&& state.singleEndpoint.getRatePlans.data.rateplans;

export const isSingleEndpointUpdating = (state) =>
	state.singleEndpoint.updateSingleEndpoint.status === Status.PENDING;

export const singleEndpointUpdateFail = (state) =>
	state.singleEndpoint.updateSingleEndpoint.status === Status.FAIL;

export const singleEndpointUpdateSuccess = (state) =>
	state.singleEndpoint.updateSingleEndpoint.status === Status.DONE;

export const isGetBlockedSubscriptionDcpFetching = (state) =>
	state.singleEndpoint.getBlockedSubscriptionDcp.status === Status.PENDING;

export const isGetBlockedSubscriptionDcpEdFail = (state) =>
	state.singleEndpoint.getBlockedSubscriptionDcp.status === Status.FAIL;

export const isGetBlockedSubscriptionDcpSuccess = (state) =>
	state.singleEndpoint.getBlockedSubscriptionDcp.status === Status.DONE;

export const getBlockedSubscriptionDcpData = (state) =>
	state.singleEndpoint.getBlockedSubscriptionDcp.data;

export const isGetTroubleshootDcpFetching = (state) =>
	state.singleEndpoint.getTroubleShootDcp.status === Status.PENDING;

export const isGetTroubleshootDcpFail = (state) =>
	state.singleEndpoint.getTroubleShootDcp.status === Status.FAIL;

export const isGetTroubleshootDcpSuccess = (state) =>
	state.singleEndpoint.getTroubleShootDcp.status === Status.DONE;

export const getTroubleshootDcpData = (state) =>
	state.singleEndpoint.getTroubleShootDcp.data;

export const isGetCcipFetching = (state) =>
	state.singleEndpoint.getCcipEndpointsData.status === Status.PENDING;

export const isGetCcipFail = (state) =>
	state.singleEndpoint.getCcipEndpointsData.status === Status.FAIL;

export const isGetCcipSuccess = (state) =>
	state.singleEndpoint.getCcipEndpointsData.status === Status.DONE;

export const getDiagnosticCcipData = (state) =>
	state.singleEndpoint.getCcipEndpointsData.data;

export const isGetDcpEdFetching = (state) =>
	state.singleEndpoint.getDcpEndpointsData.status === Status.PENDING;

export const isGetDcpEdFail = (state) =>
	state.singleEndpoint.getDcpEndpointsData.status === Status.FAIL;

export const isGetDcpEdSuccess = (state) =>
	state.singleEndpoint.getDcpEndpointsData.status === Status.DONE;

export const getDiagnosticDcpData = (state) =>
	state.singleEndpoint.getDcpEndpointsData.data;

export const isGetGdspPending = (state) =>
	state.singleEndpoint.getGdspEndpointsData.status === Status.PENDING;

export const isGetGdspFail = (state) =>
	state.singleEndpoint.getGdspEndpointsData.status === Status.FAIL;

export const isGetGdspSuccess = (state) =>
	state.singleEndpoint.getGdspEndpointsData.status === Status.DONE;

export const getDiagnosticGdspData = (state) =>
	state.singleEndpoint.getGdspEndpointsData.data;

export const getDiagnosticHlrRequest = (state) =>
	state.singleEndpoint.getDiagnosticHlr.status === Status.PENDING;

export const getDiagnosticHlrDone = (state) =>
	state.singleEndpoint.getDiagnosticHlr.status === Status.DONE;

export const getDiagnosticHlrFail = (state) =>
	state.singleEndpoint.getDiagnosticHlr.status === Status.FAIL;

export const getDiagnosticHlrSuccess = (state) =>
	state.singleEndpoint.getDiagnosticHlr.data
	&& state.singleEndpoint.getDiagnosticHlr.data.data;

export const getEndpointDiagnosticsDetails = (state) =>
	state.singleEndpoint.getDiagnosticsDetails.data || {};

export const isGettingEndpointsDiagnosticsDetails = (state) =>
	state.singleEndpoint.getDiagnosticsDetails.status === Status.PENDING;

export const getEndpointRegistrationDetails = ({ singleEndpoint }) =>
	(
		singleEndpoint.getRegistrationDetails.data
		&& singleEndpoint.getRegistrationDetails.data.deviceRegistrations
		&& singleEndpoint.getRegistrationDetails.data.deviceRegistrations.length > 0
		&& singleEndpoint.getRegistrationDetails.data.deviceRegistrations[0]
	)	|| {};

export const isGettingEndpointsRegistrationDetails = (state) =>
	state.singleEndpoint.getRegistrationDetails.status === Status.PENDING;

export const isSuccessEndpointsRegistrationDetails = (state) =>
	state.singleEndpoint.getRegistrationDetails.status === Status.DONE;

export const getSingleEndpointData = (state) =>
	(state.singleEndpoint.getSingleEndpoint.data
	&& state.singleEndpoint.getSingleEndpoint.data.endpoint) || {};

export const getSingleEndpointTransactionStatus = (state) =>
	(
		state.singleEndpoint.getSingleEndpoint.data
		&& state.singleEndpoint.getSingleEndpoint.data.transactionStatus
	) || {};

export const isSingleEndpointPending = (state) =>
	state.singleEndpoint.getSingleEndpoint.status === Status.PENDING;

export const isSingleEndpointRequestFailed = (state) =>
	state.singleEndpoint.getSingleEndpoint.status === Status.FAIL;

export const getCustomFieldLabels = (state) =>
	state.singleEndpoint.getSingleEndpoint.data
	&& state.singleEndpoint.getSingleEndpoint.data.customLabels;

export const isExportSessionsDataPending = (state) => (
	state.singleEndpoint.exportSessionsData
	&& state.singleEndpoint.exportSessions.status === Status.PENDING
);

export const sessionHistoryData = (state) =>
	state.singleEndpoint.getSessionHistory.data || {};

export const isSessionsPending = (state) =>
	state.singleEndpoint.getSessionHistory.status === Status.PENDING;

export const isGetM2MAccountPending = (state) =>
	state.singleEndpoint.getM2MAccount.status === Status.PENDING;

export const getM2MAccountData = (state) =>
	state.singleEndpoint.getM2MAccount.data
	&& state.singleEndpoint.getM2MAccount.data.m2mAccounts;

export const isExportUsageDataPending = ({ singleEndpoint }) => (
	singleEndpoint.exportUsageData.status === Status.PENDING
);
