import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import SimOrderingDesktop from './Desktop';
import SimOrderingMobile from './Mobile';

import {
	getSimOrdersRequest,
	getSimOrdersSuccess,
	getSimOrdersFail,
	getSimOrdersCount,
	editSimOrderRequest,
	editSimOrderSuccess,
	getSimOrderingFiltersRequest,
	getSimOrderingFiltersSuccess,
	getSimOrderingFiltersFail,
	getSimOrderStatusesRequest,
	getSimOrderStatusesSuccess
} from '../../redux/simOrders/selectors';

import {
	getSimOrders,
	editSimOrder,
	getSimOrderStatuses
} from '../../redux/simOrders/actions';

import {
	simpleSearchClose,
	simpleSearchStart
} from '../../../Shared/redux/simpleSearch/actions';

import { getPlatformsInit } from '../../redux/getPlatforms/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';

import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';
import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import {
	setDataOffset,
	setSelectedPage,
	setPaginationInit
} from '../../../Shared/redux/pagination/actions';
import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

const mapStateToProps = (state) => ({
	orderStatusesRequest: getSimOrderStatusesRequest(state),
	orderStatuses: getSimOrderStatusesSuccess(state),
	simOrdersRequest: getSimOrdersRequest(state),
	simOrdersFail: getSimOrdersFail(state),
	simOrders: getSimOrdersSuccess(state),
	totalCount: getSimOrdersCount(state),
	editSimOrderRequest: editSimOrderRequest(state),
	editSimOrderSuccess: editSimOrderSuccess(state),
	filterModalOpened: isFilterModalOpen(state),
	getSimOrderingFiltersRequest: getSimOrderingFiltersRequest(state),
	getSimOrderingFiltersFail: getSimOrderingFiltersFail(state),
	simOrderingFilters: getSimOrderingFiltersSuccess(state),
	user: getUser(state),
	filterData: filterDataSuccess(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
	searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	getOrderStatuses: (params) => dispatch(getSimOrderStatuses(params)),
	getSimOrders: (params) => dispatch(getSimOrders(params)),
	createSimOrder: (url) => dispatch(localizedRoute(url)),
	goToSimOrder: (url) => dispatch(localizedRoute(url)),
	editSimOrder: (params) => dispatch(editSimOrder(params)),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	simpleSearchStart: (data) => dispatch(simpleSearchStart(data)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	getPlatformsInit: () => dispatch(getPlatformsInit()),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit()),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(SimOrderingDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(SimOrderingMobile);
