import React, { PureComponent } from 'react';
import { func, object } from 'prop-types';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import Button from '../../../../../lib/DigitalComponents/Button';
import {
	getDateFormat,
	sameDateFormatter,
	startOfDayFormatter,
} from '../../../../../utils/constants';

import styles from './Steps.scss';

class StepFirst extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
    const { context } = this.props;

		this.onDefaultDateChange(new Date());

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
    const { context } = this.props;
    const { selectedDay } = this.state;

		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

	updateEndpointStatus = (
		val,
		endpoint,
		startDate,
		singleEndpointUpdate,
		user,
		intl,
	) => {
		if (val.value === endpoint.status) return;

		const updateData = {
			id: endpoint.endpointName,
			updateScreen: 'scheduleChangeModal',
			data: {
				targetState: val.value,
				userNotes: intl.formatMessage(
					{
						id: 'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES',
						defaultMessage: 'User {uid} made change status on {startDate}',
					},
					{
						startDate: format(startDate, 'yyyy-MM-dd'),
						uid: user.uid,
					},
				)
			}
		};

		if (!sameDateFormatter(startDate)) {
			updateData.data.startDate = startOfDayFormatter(startDate);
		}
		singleEndpointUpdate(updateData);
	};

	handleOnClick = () => {
    const {
			newState,
	    endpoint,
	    singleEndpointUpdate,
	    user,
	    onNext,
	    intl,
    } = this.props;
    const { selectedDay } = this.state;

		this.updateEndpointStatus(
			newState,
			endpoint,
			selectedDay,
			singleEndpointUpdate,
			user,
			intl,
		);

		onNext({ scheduleDate: selectedDay });
	};

	setFooter = (context) => {
    const { onCancel } = this.props;
    const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					onClick={onCancel}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					variant="primary"
					onClick={this.handleOnClick}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule Change"
							/>
						) : (
							<FormattedMessage
								id="INITIATE_CHANGE"
								defaultMessage="Initiate Change"
							/>
						)
					}
					dataSpec="schedule-initiate-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	onDefaultDateChange = (date) => this.setState({	selectedDay: date	});

	render() {
    const { selectedDay } = this.state;

		return (
			<div data-spec="first-step">
				<div className={styles.custom}>
					<div className="confirm-content">
						<DSDatePicker
							label={<FormattedMessage
								id="SCHEDULE_DATE"
								defaultMessage="Date"
							/>}
							value={selectedDay}
							onDayChange={this.onDefaultDateChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

StepFirst.propTypes = {
	onCancel: func,
	onNext: func,
	singleEndpointUpdate: func,
	newState: object,
	endpoint: object,
	user: object,
	context: object,
	intl: object,
};

export default injectIntl(StepFirst);
