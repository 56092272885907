import { connect } from 'react-redux';

import {
	anyModalClosed as modalClosed,
	anyModalOpened as modalOpened,
} from '../../../../redux/modals/globalModal/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import {
	changeNetworkEntitlement,
	updateRatePlanInit,
	changeNetworkEntitlementInit,
} from '../../redux/actions';
import {
	changeNetworkEntitlementFail,
	changeNetworkEntitlementPending,
	changeNetworkEntitlementSuccess,
	getNetworkEntitlementData,
	m2mAccountUpdateFail,
	ratePlanUpdateFail,
	singleEndpointUpdateFail,
} from '../../redux/selectors';
import SingleEndpointActionsModal from './SingleEndpointActionsModal';

const mapStateToProps = (state) => (
	{
		singleEndpointUpdateFail: singleEndpointUpdateFail(state),
		user: getUser(state),
		isChanging: changeNetworkEntitlementPending(state),
		changeSuccess: changeNetworkEntitlementSuccess(state),
		changeFail: changeNetworkEntitlementFail(state),
		ratePlanUpdateFail: ratePlanUpdateFail(state),
		entitlements: getNetworkEntitlementData(state),
		m2mAccountUpdateFail: m2mAccountUpdateFail(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		anyModalOpened: () => dispatch(modalOpened()),
		anyModalClosed: () => dispatch(modalClosed()),
		changeNetworkEntitlement: (
			endpointId,
			entitlementId,
			startDate,
		) => dispatch(changeNetworkEntitlement(
			endpointId,
			entitlementId,
			startDate,
		)),
		updatem2mAccountStatusInit: () => dispatch(updateRatePlanInit()),
		changeNetworkEntitlementInit: () => dispatch(changeNetworkEntitlementInit()),
	}
);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SingleEndpointActionsModal);
