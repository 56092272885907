import { Status } from '../../../../utils/constants';

export const getChartDataSuccess = ({ accountOverview }, settingId) =>
	accountOverview.getChartData.data &&
	accountOverview.getChartData.data[settingId];

export const isGetChartDataPending = ({ accountOverview }, settingId) =>
	accountOverview.getChartData.status &&
	(accountOverview.getChartData.status[settingId] === Status.PENDING ||
		accountOverview.getChartData.status[settingId] === Status.INIT);

export const getChartDataAsOf = ({ accountOverview }, settingId) =>
	accountOverview.getChartData.asOfDates &&
	accountOverview.getChartData.asOfDates[settingId];

export const checkedChartSetting = ({ accountOverview }, settingId) =>
	accountOverview.getChartData.checkedCharts &&
	accountOverview.getChartData.checkedCharts[settingId];
