import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getTabs = () =>
	ApiService.get('/customers/security-features/types');

export const getSecurityFeatures = (params) =>
	ApiService.get(`/customers/security-features?${qs.stringify(params)}`);

export const editSecurityFeatures = (data) =>
	ApiService.patch('/customers/security-features', data);

export const editSecurityFeature = (params) =>
	ApiService.patch(`/customers/security-features/${params.id}`, params.params);

export const getSecurityFeaturesStatuses = () =>
	ApiService.get('/customers/security-features/statuses');

export const getSecurityFeaturesFilters = async () => {
	const calls = [
		ApiService.get('/customers/security-features/statuses'),
		ApiService.get('/customers/m2mAccount')
	];

	const response = await Promise.all(calls);
	const data = {
		filter_category_status: response[0].data.resultList,
		filter_category_m2mAccount: response[1].data.m2mAccounts
	};
	return {
		data
	};
};

export const exportToCsv = (params) =>
	ApiService.get(`/customers/security-features/export?${qs.stringify(params)}`);

export const addSecurityFeature = (data) =>
	ApiService.post('/customers/security-features', data);

export const getM2mAccounts = () => ApiService.get('/customers/m2mAccount');

export const getUploadTemplate = (params) =>
	ApiService.get(
		`/customers/security-features/templates?${qs.stringify(params)}`
	);

export const downloadTemplate = (params) =>
	ApiService.get(
		`/customers/security-features/download?${qs.stringify(params)}`
	);
