import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import Select, {
	components,
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import DSDatePicker from '../../../../lib/DigitalComponents/DatePicker';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Dropzone from '../../../Shared/components/UploadFile';

import {
	required,
	uploadFileName,
	// phoneFormat,
	// email,
	// maxLength,
	// onlyLettersAndNumbers,
	// onlyLettersAndSpaces
} from '../../../../utils/validators';

import styles from './AddInvoiceForm.scss';

class AddInvoiceForm extends PureComponent {
	Option = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.Option data-spec="options" {...optionProps}>
				<span data-spec={`${data.value}`}>{children}</span>
			</components.Option>
		);
	};

	SingleValue = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.SingleValue {...optionProps} data-spec="data-spec">
				<span data-spec={`${data.value}`}>{children}</span>
			</components.SingleValue>
		);
	};

	getM2mAccounts = () => {
		const { m2mAccounts } = this.props;
		return m2mAccounts.map((m2mAccount) => ({
			label: m2mAccount.m2mAccountId,
			value: m2mAccount.m2mAccountId,
		}));
	};

	renderInvoiceId = () => (
		<span className={styles.value} data-spec="add-invoice-invoice-id">
			<div className={styles.bolded_text}>
				<FormattedMessage
					id="ADD_INVOICE.INVOICE_ID"
					defaultMessage="Invoice ID"
				/>
				<span className={styles.star}>*</span>
			</div>
			<div className={styles.field}>
				<FormattedMessage
					id="ADD_INVOICE.INVOICE_ID_PLACEHOLDER"
					defaultMessage="12345678910"
				>
					{(formattedValue) => (
						<Field
							name="invoiceId"
							component={Input}
							placeholder={formattedValue}
							validate={[required]}
						/>
					)}
				</FormattedMessage>
			</div>
		</span>
	);

	renderM2mAccountId = () => {
		const { m2mAccounts } = this.props;
		return (
			<span className={styles.value} data-spec="add-invoice-account-id">
				<div className={styles.bolded_text}>
					<FormattedMessage
						id="ADD_INVOICE.M2M_ACCOUNT_ID"
						defaultMessage="M2M Account ID"
					/>
					<span className={styles.star}>*</span>
				</div>
				<div className={styles.field}>
					<FormattedMessage
						id="ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER"
						defaultMessage="2241001"
					>
						{(formattedValue) => (
							<Field
								name="m2mAccountId"
								component={Select}
								options={
									m2mAccounts && m2mAccounts.length > 0
										? this.getM2mAccounts()
										: []
								}
								placeholder={formattedValue}
								validate={[required]}
								components={((this.Option, this.SingleValue))}
							/>
						)}
					</FormattedMessage>
				</div>
			</span>
		);
	};

	renderInvoiceDate = () => (
		<span className={styles.value} data-spec="add-invoice-invoice-date">
			<div className={styles.bolded_text}>
				<FormattedMessage
					id="ADD_INVOICE.INVOICE_DATE"
					defaultMessage="Invoice Date"
				/>
				<span className={styles.star}>*</span>
			</div>
			<div className={styles.field}>
				<FormattedMessage
					id="ADD_INVOICE.DATE_PLACEHOLDER"
					defaultMessage="Select Date"
				>
					{(formattedValue) => (
						<Field
							name="invoiceDate"
							component={DSDatePicker}
							placeholderText={formattedValue}
							canSelectBeforeDate
							validate={[required]}
						/>
					)}
				</FormattedMessage>
			</div>
		</span>
	);

	renderBillingCycle = () => (
		<span className={styles.value} data-spec="add-invoice-billing-cycle">
			<div className={styles.bolded_text}>
				<FormattedMessage
					id="ADD_INVOICE.BILLING_CYCLE"
					defaultMessage="Billing Cycle"
				/>
				<span className={styles.star}>*</span>
			</div>
			<div className={styles.field}>
				<FormattedMessage
					id="ADD_INVOICE.DATE_PLACEHOLDER"
					defaultMessage="Select Date"
				>
					{(formattedValue) => (
						<Field
							name="billingCycle"
							component={DSDatePicker}
							validate={[required]}
							canSelectBeforeDate
							placeholderText={formattedValue}
							dateFormat="MMM yyyy"
							showMonthYearPicker
						/>
					)}
				</FormattedMessage>
			</div>
		</span>
	);

	renderUploadFile = () => (
		<div className={styles.upload} data-spec="add-invoice-upload-file">
			<span className={styles.upload_title}>
				<FormattedMessage
					id="ADD_INVOICE.UPLOAD_FILE"
					defaultMessage="Upload a File"
				/>
				<span className={styles.star}>*</span>
			</span>
			<Dropzone
				singleFileUpload
				accept={['.pdf']}
				maxSize={104857600}
				service="invoice"
				transactionType="basicInvoice"
			/>
			<span className={styles.upload_file_name}>
				<FormattedMessage
					id="ADD_INVOICE.UPLOAD_FILE_NAME"
					defaultMessage="File Name (Optional: If left blank the file name will be used)"
				/>
			</span>
			<FormattedMessage
				id="ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER"
				defaultMessage="File Name (255 characters maximum)"
			>
				{(formattedValue) => (
					<Field
						name="fileName"
						component={Input}
						placeholder={formattedValue}
						validate={[uploadFileName]}
					/>
				)}
			</FormattedMessage>
		</div>
	);

	render() {
		return (
			<div data-spec="add-invoice-form">
				<div className={styles.row}>
					{this.renderInvoiceId()}
					{this.renderM2mAccountId()}
				</div>
				<div className={styles.row}>
					{this.renderInvoiceDate()}
					{this.renderBillingCycle()}
				</div>
				{this.renderUploadFile()}
			</div>
		);
	}
}

const { array } = PropTypes;

AddInvoiceForm.propTypes = {
	m2mAccounts: array,
	fileSuccess: array,
};

export default AddInvoiceForm;
