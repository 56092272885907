import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import styles from './AutomationZonesModal.scss';
import Dropzone from '../../../Shared/components/UploadFile';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';

import { downloadHelper } from '../../../AnalyticsInvoices/redux/invoiceAttachment/actions';

const submitForm = (values, _, props, onFinish) => {
	const { fileSuccess, intl, patchFileUpload } = props;

	const successTitle = intl.formatMessage({
		id: 'AUTOMATION_ZONES.NOTIFICATION_SUCCESS_UPLOAD_TITLE',
		defaultMessage: 'Upload Successful'
	});

	const successMessage = intl.formatMessage({
		id: 'AUTOMATION_ZONES.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE',
		defaultMessage: 'Successfully uploaded file.'
	});

	const errorTitle = intl.formatMessage({
		id: 'AUTOMATION_ZONES.NOTIFICATION_ERROR_UPLOAD_TITLE',
		defaultMessage: 'Error uploading'
	});

	const errorMessage = intl.formatMessage({
		id: 'AUTOMATION_ZONES.NOTIFICATION_ERROR_UPLOAD_MESSAGE',
		defaultMessage: 'There was an error uploading this file to IoT Console.'
	});

	const file = fileSuccess[0];
	const data = {
		fileLocation: file.location,
		fileUploadId: file.id
	};

	const notifOptions = {
		id: 'automation-zones',
		successMessage,
		successTitle,
		errorMessage,
		errorTitle
	};
	patchFileUpload(file.id, data, notifOptions);
	onFinish();
};

const afterSubmit = (result, dispatch) => dispatch(reset('automationZones'));

class AutomationZonesModal extends PureComponent {
	componentDidUpdate(prevProps) {
		const { downloadTemplateSuccess } = this.props;
		if (downloadTemplateSuccess && prevProps.downloadTemplateSuccess !== downloadTemplateSuccess) {
			downloadHelper(false, 'template.csv', downloadTemplateSuccess);
		}
	}

	componentWillUnmount() {
		const { resetFiles } = this.props;
		resetFiles();
	}

	onFinish = () => {
		const { onClose, anyModalClosed } = this.props;
		onClose();
		anyModalClosed();
	};

	prepareErrors = (errors, messages) =>
		errors.map((error) => messages[error.code] || error.message);

	downloadTemplate = () => {
		const { downloadTemplate } = this.props;
		downloadTemplate();
	};

	render() {
		const {
			show,
			notifications,
			errors,
			messages,
			handleSubmit,
			filePending,
			fileSuccess,
			uploadFileRequest,
			downloadTemplateRequest
		} = this.props;

		const request = uploadFileRequest || downloadTemplateRequest;

		return (
			<div data-spec="automation-zones-bulk-upload">
				<Modal show={show} className={styles.modal} onClose={this.onFinish}>
					<ModalHeader
						title={
							<FormattedMessage
								id={'AUTOMATION_ZONES.UPLOAD_FILE'}
								defaultMessage={'Upload File'}
							/>
						}
						notifications={notifications}
						errors={errors ? this.prepareErrors(errors, messages) : []}
					/>
					<form
						onSubmit={handleSubmit((values, _, props) =>
							submitForm(values, _, props, this.onFinish))}
					>
						<ModalBody className={styles.modal_body}>
							{request && <Loader data-spec="loader" />}
							{!request && (
								<div>
									<Dropzone
										messages={messages}
										service="automation"
										transactionType="automationZone"
										validateFileContent
										accept={['.csv']}
										singleFileUpload
									/>
									<div className={styles.download_template_area}>
										<FormattedMessage
											id="AUTOMATION_ZONES.DOWNLOAD_TEMPLATE"
											defaultMessage="Download template:"
										/>
										&nbsp;
										<span
											className={styles.template_url}
											onClick={() => this.downloadTemplate()}
										>
											<FormattedMessage
												id="AUTOMATION_ZONES.TEMPLATE_CSV"
												defaultMessage="Template.csv"
											/>
										</span>
									</div>
								</div>
							)}
						</ModalBody>

						<ModalFooter>
							<Button
								variant="link"
								onClick={this.onFinish}
								label={
									<FormattedMessage
										id="AUTOMATION_ZONES.CLOSE"
										defaultMessage="Close"
									/>
								}
								data-spec="close-button"
							/>
							<Button
								type="submit"
								variant="primary"
								label={
									<FormattedMessage
										id="AUTOMATION_ZONES.UPLOAD_FILE"
										defaultMessage="Upload File"
									/>
								}
								data-spec="submit-button"
								disabled={filePending || fileSuccess.length === 0}
							/>
						</ModalFooter>
					</form>
				</Modal>
			</div>
		);
	}
}

const { bool, func, array, object, string } = PropTypes;

AutomationZonesModal.propTypes = {
	show: bool,
	onClose: func,
	notifications: array,
	errors: array,
	messages: object,
	resetFiles: func,
	handleSubmit: func,
	filePending: bool,
	fileSuccess: array,
	anyModalClosed: func,
	uploadFileRequest: bool,
	downloadTemplate: func,
	downloadTemplateSuccess: string,
	downloadTemplateRequest: bool
};

const AutomationZonesModalForm = reduxForm({
	form: 'automationZones',
	enableReinitialize: true,
	onSubmitSuccess: afterSubmit
})(AutomationZonesModal);

export default injectIntl(AutomationZonesModalForm);
