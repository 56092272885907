import globalAction from '../../../../redux/global/globalAction';

import {
	GET_SECURITY_FEATURES,
	EDIT_SECURITY_FEATURES,
	GET_SECURITY_FEATURES_STATUSES,
	ADD_SECURITY_FEATURE,
	EDIT_SECURITY_FEATURE
} from './constants';

import {
	getSecurityFeatures as getSecurityFeaturesService,
	editSecurityFeatures as editSecurityFeaturesService,
	getSecurityFeaturesStatuses as getSecurityFeaturesStatusesService,
	addSecurityFeature as addSecurityFeatureService,
	editSecurityFeature as editSecurityFeatureService
} from '../../services/SecurityFeaturesService';

export const getSecurityFeatures = (params) =>
	globalAction(GET_SECURITY_FEATURES, getSecurityFeaturesService, params);

export const editSecurityFeatures = (data) =>
	globalAction(EDIT_SECURITY_FEATURES, editSecurityFeaturesService, data, {
		notify: 'edit-security-feature'
	});

export const editSecurityFeature = (id, params = {}) =>
	globalAction(
		EDIT_SECURITY_FEATURE,
		editSecurityFeatureService,
		{
			id,
			params
		},
		{
			notify: 'edit-security-feature'
		}
	);

export const getSecurityFeaturesStatuses = () =>
	globalAction(
		GET_SECURITY_FEATURES_STATUSES,
		getSecurityFeaturesStatusesService
	);

export const addSecurityFeatures = (data) =>
	globalAction(ADD_SECURITY_FEATURE, addSecurityFeatureService, data, {
		notify: 'security-feature-rule'
	});
