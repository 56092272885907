import { connect } from 'react-redux';

import {
	getRatePlans,
	updateRatePlan,
	updateRatePlanInit,
} from '../../redux/actions';
import {
	getRatePlansData,
	isRatePlanUpdating as isRatePlanUpdatingSelector,
	ratePlanUpdateFail as ratePlanUpdateFailSelector,
	ratePlanUpdateSuccess as ratePlanUpdateSuccessSelector,
} from '../../redux/selectors';
import RatePlanChangeStepper from './RatePlanChangeStepper';

const mapStateToProps = (state) => (
	{
		ratePlans: getRatePlansData(state),
		isRatePlanUpdating: isRatePlanUpdatingSelector(state),
		ratePlanUpdateFail: ratePlanUpdateFailSelector(state),
		ratePlanUpdateSuccess: ratePlanUpdateSuccessSelector(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getRatePlans: (m2mAccountId) => dispatch(getRatePlans(m2mAccountId)),
		updateRatePlanStatusInit: () => dispatch(updateRatePlanInit()),
		ratePlanUpdate: (data) => dispatch(updateRatePlan(data)),
	}
);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RatePlanChangeStepper);
