import { createAction } from 'redux-actions';
import {
	SET_DATA_OFFSET_SUCCESS,
	SET_SELECTED_PAGE_SUCCESS,
	SET_PAGINATION_INIT
} from './constants';

const setDataOffsetSuccess = createAction(SET_DATA_OFFSET_SUCCESS);

const setSelectedPageSuccess = createAction(SET_SELECTED_PAGE_SUCCESS);

const setPaginationInitAction = createAction(SET_PAGINATION_INIT);

export const setDataOffset = (dataOffset) => async (dispatch) => {
	dispatch(setDataOffsetSuccess(dataOffset));
};

export const setSelectedPage = (selectedPage) => async (dispatch) => {
	dispatch(setSelectedPageSuccess(selectedPage));
};

export const setPaginationInit = () => async (dispatch) => {
	dispatch(setPaginationInitAction());
};
