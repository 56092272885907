import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SuperUserTopBar from '../SuperUserTopBar';
import Button from '../../../../../lib/DigitalComponents/Button';

import styles from './Mobile.scss';

const Mobile = ({ companyFriendlyName }) => (
	<div
		className={styles.super_user_mode}
		data-spec="above-top-bar-content-mobile"
	>
		<h2 className={styles.super_user_header}>
			<FormattedMessage
				id="SUPER_USER_MODE_MOBILE"
				defaultMessage="{companyFriendlyName}"
				values={{
					companyFriendlyName
				}}
			/>
		</h2>
		<Button
			className={styles.super_user_button}
			type="button"
			label={
				<FormattedMessage
					id={'SHARED.EXIT_THIS_COMPANY'}
					defaultMessage={'Exit'}
				/>
			}
			onClick={() => {
				sessionStorage.removeItem('company');
				sessionStorage.removeItem('company-token');
				window.location.href = `/${sessionStorage.getItem('language') || 'en-us'}`;
			}}
		/>
	</div>
);

const { string } = PropTypes;

Mobile.propTypes = {
	companyFriendlyName: string,
};

Mobile.defaultProps = {
	companyFriendlyName: '',
};

export default SuperUserTopBar()(Mobile);
