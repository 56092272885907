import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_PENDING_REGISTRATIONS,
	CANCEL_USER_PENDING_INVITATION,
	RESEND_USER_INVITATION
} from './constants';

export default combineReducers({
	getRegistrations: globalReducer(GET_PENDING_REGISTRATIONS),
	cancelRegistration: globalReducer(CANCEL_USER_PENDING_INVITATION),
	resendRegistration: globalReducer(RESEND_USER_INVITATION)
});
