import globalAction from '../../../../redux/global/globalAction';

import { GET_ENDPOINTS, GET_ENDPOINTS_FILTERS } from './constants';

import {
	getEndpoints as getEndpointsService,
	getEndpointsFilters as getEndpointsFiltersService
} from '../../services/EndpointsService';

/** ************ get endpoints ************* */
export const getEndpoints = (id, params = {}) =>
	globalAction(GET_ENDPOINTS, getEndpointsService, { id, params });

/** ************ get endpoints filters ************* */
export const getEndpointsFilters = (id, params = {}) =>
	globalAction(GET_ENDPOINTS_FILTERS, getEndpointsFiltersService, {
		id,
		params
	});
