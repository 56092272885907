import React, { PureComponent } from 'react';
import { object, func, bool, array, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
	findEntitlement,
} from '../../../../utils/helperFunctions';

export default function DataOverview(WrapperComponent) {
	class DataOverviewComponent extends PureComponent {
		componentDidMount() {
			const { availableStates, getAvailableStates, getM2MAccount } = this.props;

			if (!availableStates) {
				getAvailableStates();
			}

			getM2MAccount();
		}

		checkPendingStatus = () => {
			const { refreshEndpoint } = this.props;

			window.scrollTo(0, 0);
			refreshEndpoint();
		};

		renderDataSession = ({ dataSession }) => {
			switch (dataSession) {
				case '1':
					return <FormattedMessage
						data-spec="data-session-message"
						id="YES"
						defaultMessage="Yes"
					/>;
				case '0':
					return <FormattedMessage
						id="NO"
						defaultMessage="No"
						data-spec="data-session-message"
					/>;
				default:
					return null;
			}
		};

		render() {
			const {
				endpoint,
				entitlements,
				type,
				endpointName,
				isPendingStatus,
				ratePlans,
				customFieldLabels,
				getM2MAccountData,
			} = this.props;
			const entitlement = findEntitlement(
				entitlements,
				endpoint.networkEntitlementId
			);

			return (
				<div data-spec="data-overview">
					<WrapperComponent
						entitlement={entitlement}
						endpoint={endpoint}
						type={type}
						endpointName={endpointName}
						isPendingStatus={isPendingStatus}
						ratePlans={ratePlans}
						customFieldLabels={customFieldLabels}
						checkPendingStatus={this.checkPendingStatus}
						renderDataSession={this.renderDataSession}
						m2mAccounts={getM2MAccountData}
					/>
				</div>
			);
		}
	}

	DataOverviewComponent.propTypes = {
		endpoint: object,
		customFieldLabels: object,
		isPendingStatus: bool,
		entitlements: array,
		type: string,
		endpointName: string,
		ratePlans: array,
		refreshEndpoint: func,
		availableStates: object,
		getAvailableStates: func,
		getM2MAccountData: array,
		getM2MAccount: func,
	};
	DataOverviewComponent.defaultProps = {
		endpoint: {},
		customFieldLabels: {},
		isPendingStatus: false,
		entitlements: [],
		type: '',
		endpointName: '',
		ratePlans: [],
		refreshEndpoint: undefined,
		availableStates: {},
		getAvailableStates: undefined,
		getM2MAccountData: [],
		getM2MAccount: undefined,
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	DataOverviewComponent.displayName = `DataOverview(${wrappedComponentName})`;

	return DataOverviewComponent;
}
