import { find } from 'lodash';

const TriggerTypes = {
	RegularSingleValue: 1,
	SIMStateMultiValue: 2,
	SIMRatePlanMultiValue: 3,
	NoFrameValues: 4,
	RegularSingleValueWithZoneDropdown: 5,
	SingleValueFromCountryDropdown: 6,
	SingleValueWithSimPoolId: 7,
	ZoneDropdownWithZoneModelDropdown: 8
};

const validate = (values, props) => {
	const { messages } = props;
	const errors = {};

	const allFields = [
		'm2mAccountId',
		'category',
		'trigger',
		'automationTriggerSubCategoryId'
	];

	allFields.forEach((field) => {
		if (!values[field]) {
			errors[field] = messages.REQUIRED || 'Required';
		}
	});

	const values2Config = [
		{
			name: 'automationActionSchedulerId2',
			propName: 'automationSchedulers',
			revertActions: ['NEXT_BILLING_CYCLE'],
			value2Name: 'automationActionSchedulerValue2'
		},
		{
			name: 'automationActionId2',
			propName: 'automationChildActions',
			revertActions: [
				'REVERT_COMM_PLAN',
				'REVERT_SIM_STATE',
				'REVERT_RATE_PLAN'
			],
			value2Name: 'automationActionValue2'
		}
	];

	values2Config.forEach((field) => {
		const { name, propName, revertActions, value2Name } = field;
		if (values[name]) {
			const data = props[propName];
			const activeAction = find(data, {
				id: values[name].value || values[name]
			});
			if (activeAction) {
				const code = activeAction.automationActionType
					? activeAction.automationActionType.code
					: activeAction.code;
				const actionCheck = revertActions.indexOf(code);
				if (actionCheck === -1 && !values[value2Name]) {
					errors[value2Name] = messages.REQUIRED || 'Required';
				}
			}
		}
	});

	if (values.automationActionId2) {
		const actions = props.automationChildActions;
		const activeAction = find(actions, {
			id: values.automationActionId2.value || values.automationActionId2
		});
		if (
			activeAction &&
			activeAction.hasSchedulers &&
			!values.automationActionSchedulerId2
		) {
			errors.automationActionSchedulerId2 = messages.REQUIRED || 'Required';
		}
	}
	/* ---------- field array validation ---------- */
	const automationRuleFrameActionValuesErrors = [];
	let automationRuleFrameActionValueErrors = {};
	values.automationRuleFrameActionValues &&
		values.automationRuleFrameActionValues.forEach((field, index) => {
			automationRuleFrameActionValueErrors = {};
			const id =
				(field.automationActionId && field.automationActionId.value) ||
				field.automationActionId; /* FIX THIS FOR UPDATE */
			const item = find(props.automationActions, { id });
			const itemId = item && item.automationActionTypeId;

			if (
				field.frameValue &&
				Number.isNaN(Number(field.frameValue)) &&
				(values.trigger &&
					[
						TriggerTypes.RegularSingleValue,
						TriggerTypes.RegularSingleValueWithZoneDropdown,
						TriggerTypes.SingleValueWithSimPoolId
					].includes(values.trigger.automationTriggerFrameValueTypeId))
			) {
				automationRuleFrameActionValueErrors.frameValue =
					messages.MUST_BE_A_NUMBER || 'Must be a number';
			}

			if (
				values.category &&
				(values.trigger && values.trigger.value === 45) &&
				field.frameValue &&
				(field.frameValue > 100 || field.frameValue < 1)
			) {
				automationRuleFrameActionValueErrors.frameValue =
					`${messages.MUST_BE_BETWEEN || 'Must be between '}1-100`;
			}

			if (field.automationActionValue) {
				switch (itemId) {
					case 1:
						if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
								field.automationActionValue
							)
						) {
							automationRuleFrameActionValueErrors.automationActionValue =
								messages.INVALID_EMAIL_ADDRESS || 'Invalid email address';
						}
						break;
					case 3:
						if (
							!/^(\+|00)([0-9-]{1,3})([0-9-]{1,16})$/i.test(
								field.automationActionValue
							)
						) {
							automationRuleFrameActionValueErrors.automationActionValue =
								messages.WRONG_PHONE_FORMAT || 'Wrong phone format';
						}
						break;
					case 12:
						if (
							!/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
								field.automationActionValue
							)
						) {
							automationRuleFrameActionValueErrors.automationActionValue =
								messages.INVALID_URL_FORMAT || 'Invalid URL format';
						}
						break;
					// no default
				}
			}

			automationRuleFrameActionValuesErrors[
				index
			] = automationRuleFrameActionValueErrors;
		});

	if (automationRuleFrameActionValuesErrors.length > 0) {
		errors.automationRuleFrameActionValues = automationRuleFrameActionValuesErrors;
	}
	/* field array validation */

	if (!values.name || values.name.trim().length === 0) {
		errors.name = messages.REQUIRED || 'Required';
	}

	if (values.name && values.name.length > 50) {
		errors.name =
			(messages.MUST_BE_LESS_THAN_OR_EQUAL_TO ||
				'Must be less than or equal to ') +
			50 +
			(messages.CHARACTERS || ' characters');
	}

	return errors;
};

export default validate;
