import React, { PureComponent } from 'react';
import {
	func,
	object,
	string,
} from 'prop-types';
import cn from 'classnames';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import Button from '../../../../../lib/DigitalComponents/Button';
import {
	getDateFormat,
	sameDateFormatter,
	startOfDayFormatter,
} from '../../../../../utils/constants';

import styles from '../RatePlanChangeStepper.scss';

export default class StepSecond extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
		const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context } = this.props;
		const { selectedDay } = this.state;

		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

	onNext = () => {
		const { ratePlans, onNext, endpointId, ratePlanUpdate } = this.props;
		const { selectedDay } = this.state;
		const data = {
			data: {
				ratePlanId: `${ratePlans.newRatePlan.ratePlan.id}`,
			},
			id: endpointId
		};

		onNext({ startDate: selectedDay });
		if (!sameDateFormatter(selectedDay)) {
			data.data.startDate = startOfDayFormatter(selectedDay);
		}
		ratePlanUpdate(data);
	};

	onDateChange = (date) => this.setState({ selectedDay: date });

	setFooter = (context) => {
		const { onClose } = this.props;
		const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					variant="primary"
					onClick={this.onNext}
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule Change"
							/>
						) : (
							<FormattedMessage
								id="INITIATE_CHANGE"
								defaultMessage="Initiate Change"
							/>
						)
					}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
					dataSpec="schedule-initiate-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
		const { ratePlans } = this.props;
		const { selectedDay } = this.state;
		const ratePlanName = ratePlans && ratePlans.newRatePlan.ratePlan.name;

		return (
			<div data-spec="second-step">
				<div data-spec="ds-modal-body" className={styles.custom}>
					<div className={styles.select_field_label}>
						<FormattedMessage
							id="CONFIRM_RATE_PLAN_CHANGE"
							defaultMessage="Confirm Rate Plan Change"
						/>
					</div>
					<div className={styles.select_field_label_text}>
						<FormattedMessage
							id="RATE_PLAN_WILL_BE"
							defaultMessage="Once you initiate this change, the selected endpoint’s rate plan will be "
						/>
						<span
							className={cn(styles.select_field_label_text, styles.rate_plan)}
						>
							{ratePlanName}
							.&nbsp;
						</span>
						<FormattedMessage
							id="WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW"
							defaultMessage="Would you like to initiate change now?"
						/>
					</div>
					<div
						className={cn(
							styles.select_field_wrapper,
							styles.rate_plan,
							styles.datepicker
						)}
					>
						<span>
							<FormattedMessage
								id="SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR"
								defaultMessage="Or you can schedule the change for: "
							/>
						</span>
						<DSDatePicker
							value={selectedDay}
							onDayChange={this.onDateChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

StepSecond.propTypes = {
	onClose: func,
	onNext: func,
	ratePlanUpdate: func,
	ratePlans: object,
	endpointId: string,
	context: object
};
