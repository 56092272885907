import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../lib/DigitalComponents/Button';
import BatchFileUpload from '../BatchFileUpload';

class StepOne extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	onNext = () => {
		const { onNext } = this.props;
		onNext({});
	};

	onClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	setFooter = (context) => {
		const footer = (
			<>
				<Button
					variant="link"
					onClick={this.onClose}
					label={
						<FormattedMessage
							id="SECURITY_FEATURES.CANCEL"
							defaultMessage="Cancel"
						/>
					}
					dataSpec="cancel-button"
				/>
				<Button
					variant="primary"
					onClick={this.onNext}
					disabled
					label={
						<FormattedMessage
							id="SECURITY_FEATURES.CONTINUE"
							defaultMessage="Continue"
						/>
					}
					dataSpec="continue-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	handleClick = (value) => {
		const { onNext, clearUploadErrors } = this.props;
		const file = value.files[0];
		const uploadBody = {
			file,
			request: {
				fileName: value.fileName,
				fileType: '.csv'
			}
		};
		onNext(uploadBody);
		clearUploadErrors();
	};

	render() {
    const { id } = this.props;
		return (
			<div data-spec="batch-upload-first-step">
				<BatchFileUpload onClick={(value) => this.handleClick(value)} id={id} />
			</div>
		);
	}
}

const { shape, func, number } = PropTypes;

StepOne.propTypes = {
	context: shape(),
	onClose: func,
	onNext: func,
  clearUploadErrors: func,
  id: number
};

export default StepOne;
