import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Stickyfill from 'stickyfilljs';
import Loader from '../../../../../lib/DigitalComponents/Loader';

import styles from './Desktop.scss';

class Desktop extends React.Component {
	componentDidMount() {
		this.showNotification();
	}

	componentDidUpdate(prevProps) {
		const { faqAvailable } = this.props;
		if (!prevProps.faqAvailable && faqAvailable) {
			Stickyfill.add(this.faqNavRef);
		}
	}

	showNotification = () => {
		const { intl, showNotification } = this.props;
		const message = intl.formatMessage({
			id: 'SUPPORT.TRANSLATION_NOT_AVAILABLE',
			defaultMessage:
				'Document below is shown in U.S. English. Translation into other supported languages will be available in upcoming product releases.'
		});
		showNotification(message);
	};

	getNodes = (str) =>
		new DOMParser().parseFromString(str, 'text/html').body.childNodes;

	render() {
		const { faqAvailable, contentId, fileMarkdown } = this.props;
		return (
			<div data-spec="faq-desktop" className={styles.wrapper}>
				{fileMarkdown && (
					<div
						className={styles.faq_menu_container}
						ref={(menu) => {
							this.faqNavRef = menu;
						}}
					>
						<h2>
							<FormattedMessage id="SUPPORT.TOPICS" defaultMessage="Topics" />
						</h2>
						<div className={styles.faq_menu}>
							{faqAvailable &&
								this.faqRef &&
								(() => {
									const nodes = this.getNodes(fileMarkdown);
									const titles = Object.keys(nodes)
										.filter(
											(key) => nodes[key].id && nodes[key].tagName === 'H2'
										)
										.reduce((obj, key) => {
											obj[key] = nodes[key];
											return obj;
										}, {});
									return (
										<ul data-spec="faq-menu">
											{Object.keys(titles).map((key) => (
												<li
													role="menuitem"
													key={titles[key].id}
													onClick={() => {
														const el = document.getElementById(titles[key].id);
														window.scrollBy({
															top: el.getBoundingClientRect().top - 60,
															left: 0
														});
													}}
												>
													<span>{titles[key].childNodes.item(0).data}</span>
												</li>
											))}
										</ul>
									);
								})()}
						</div>
					</div>
				)}
				<div
					className={styles.main_content}
					ref={(faq) => {
						this.faqRef = faq;
					}}
					id={contentId}
					dangerouslySetInnerHTML={{ __html: fileMarkdown }}
				/>
				{!fileMarkdown && <Loader />}
			</div>
		);
	}
}

const { bool, string, oneOfType, object, func } = PropTypes;

Desktop.propTypes = {
	faqAvailable: bool,
	contentId: string,
	fileMarkdown: oneOfType([string, object]),
  intl: object,
  showNotification: func
};

export default injectIntl(Desktop);
