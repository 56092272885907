import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_M2M_ACCOUNTS
} from './constants';

export default combineReducers({
	getM2mAccounts: globalReducer(GET_M2M_ACCOUNTS)
});
