import React from 'react';
import Stickyfill from 'stickyfilljs';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loader from '../../../../../lib/DigitalComponents/Loader';

import styles from './Desktop.scss';

class Desktop extends React.Component {
  componentDidMount() {
    this.showNotification();
  }

	componentDidUpdate(prevProps) {
		const { userGuideAvailable } = this.props;
		if (!prevProps.userGuideAvailable && userGuideAvailable) {
			Stickyfill.add(this.userGuideNavRef);
    }
	}

	showNotification = () => {
		const { intl, showNotification } = this.props;
		const message = intl.formatMessage({
			id: 'SUPPORT.TRANSLATION_NOT_AVAILABLE',
			defaultMessage:
				'Document below is shown in U.S. English. Translation into other supported languages will be available in upcoming product releases.'
		});
		showNotification(message);
	};

	getNodes = (str) =>
		new DOMParser().parseFromString(str, 'text/html').body.childNodes;

	render() {
		const { userGuideAvailable, contentId, fileMarkdown } = this.props;
		return (
			<div data-spec="user-guide-desktop" className={styles.wrapper}>
				{fileMarkdown && (
					<div
						className={styles.user_guide_menu_container}
						ref={(menu) => {
							this.userGuideNavRef = menu;
						}}
					>
						<h2>
							<FormattedMessage
								id="SUPPORT.TABLE_OF_CONTENTS"
								defaultMessage="Table of Contents"
							/>
						</h2>
						<div className={styles.user_guide_menu}>
							{userGuideAvailable &&
								this.userGuideRef &&
								(() => {
									const nodes = this.getNodes(fileMarkdown);
									const titles = Object.keys(nodes)
										.filter(
											(key) =>
												nodes[key].id &&
												(nodes[key].tagName === 'H2' ||
													nodes[key].tagName === 'H1')
										)
										.reduce((obj, key) => {
											obj[key] = nodes[key];
											return obj;
										}, {});
									return (
										<ul data-spec="user-guide-menu">
											{Object.keys(titles).map((key) => (
												<li
													role="menuitem"
													key={titles[key].id}
													className={
														titles[key].tagName === 'H2' ? styles.indent : ''
													}
													onClick={() => {
														const el = document.getElementById(titles[key].id);
														window.scrollBy({
															top: el.getBoundingClientRect().top - 60,
															left: 0
														});
													}}
												>
													<span>{titles[key].childNodes.item(0).data}</span>
												</li>
											))}
										</ul>
									);
								})()}
						</div>
					</div>
				)}
				<div
					className={styles.main_content}
					ref={(userGuide) => {
						this.userGuideRef = userGuide;
					}}
					id={contentId}
					dangerouslySetInnerHTML={{ __html: fileMarkdown }}
				/>
				{!fileMarkdown && <Loader />}
			</div>
		);
	}
}

const { string, bool, oneOfType, object, func } = PropTypes;

Desktop.propTypes = {
	userGuideAvailable: bool,
	contentId: string,
  fileMarkdown: oneOfType([object, string]),
  showNotification: func,
  intl: object
};

export default injectIntl(Desktop);
