import React from 'react';
import { object, func } from 'prop-types';

import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';
import hoc from '../AutomationZones';

function Mobile(props) {
	const { automationZones, getOptions, messages } = props;
	return (
		<div data-spec="automation-zones-mobile">
			<CardsTable
				data-spec="automation-zones-table-mobile"
				data={automationZones.resultList}
				options={getOptions('mobile')}
				messages={messages}
			/>
		</div>
	);
}

Mobile.propTypes = {
	automationZones: object,
	getOptions: func,
	messages: object
};

export default hoc(Mobile);
