import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getAnalyticsInvoices = (params) =>
	ApiService.get(`${analyticsInvoicesBaseURL}?${qs.stringify(params)}`);

export const getInvoices = (params) =>
	ApiService.get(`/invoices?${qs.stringify(params)}`);

export const getAnalyticsInvoicesFilters = (params) =>
	ApiService.get(`${analyticsInvoicesBaseURL}/filters?${qs.stringify(params)}`);

// export const getInvoiceDetailsOld = (params) =>
// 	ApiService.get(
// 		`${analyticsInvoicesBaseURL}/${params.id}?${qs.stringify(params.params)}`
// 	);

export const getInvoiceDetails = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/m2m-accounts/${
			params.m2mAccountId
		}?${qs.stringify(params.params)}`
	);

export const getFilters = () => ApiService.get('/invoices/filters');

export const exportInvoice = (id) =>
	ApiService.get(`${analyticsInvoicesBaseURL}/${id}/export`);

export const getInvoiceAttachments = (id) =>
	ApiService.get(`invoices/${id}/attachments`);

// DOWNLOAD INVOICE ATTACHMENT
export const downloadInvoiceFile = async (data) => {
	const url = '/batch/file-download';
	try {
		const response = await ApiService.post(url, data);
		return response.data.id;
	} catch (error) {
		throw { error: error.response.data };
	}
};

export const exportInvoiceDetails = (params) =>
	ApiService.get(
		`reports/invoice-details/${params.id}?${qs.stringify(params.params)}`
	);

export const checkInvoiceDownloadStatus = (id) =>
	ApiService.get(`/batch/file-download/${id}`);

export const invoicePollForFile = (data, retries, delay) => {
	// eslint-disable-next-line no-plusplus
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				// eslint-disable-next-line no-unused-expressions
				-checkInvoiceDownloadStatus(data)
					.then((response) => {
						if (response.data.status === 0 || response.data.status === 1) {
							resolve(invoicePollForFile(data, retries, delay));
						} else if (response.data.status === 2) {
							resolve(response.data);
						} else {
							reject({ error: response });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
	return new Promise(function (_resolve, reject) {
		reject({ error: [] });
	});
};

export const initiateInvoiceChange = (id, data) =>
	ApiService.patch(`/batch/file-upload/${id}`, data)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const checkInvoicePoolStatus = (id) =>
	ApiService.get(`/batch/file-upload/${id}`)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const invoiceAttachmentPollForFile = (id, retries, delay) => {
	// eslint-disable-next-line no-plusplus
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				// eslint-disable-next-line no-unused-expressions
				checkInvoicePoolStatus(id)
					.then((response) => {
						if (response.status == 3) {
							resolve(invoiceAttachmentPollForFile(id, retries, delay));
						} else if (response.status == 5) {
							resolve(response);
						} else {
							reject({ error: response });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
	return new Promise(function (_resolve, reject) {
		reject({ error: [] });
	});
};

export const patchInvoice = (params) =>
	ApiService.patch(`invoices/${params.id}`, params.data);

export const getM2mAccounts = () => ApiService.get('/customers/m2mAccount');
