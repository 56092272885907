import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import EditCompany from './EditCompany';

import {
	editCompany,
	getCompany,
	getCompanyInit,
	getCompanyToken,
} from '../../redux/companies/actions';
import {
	getCompanyRequest,
	getCompanyFail,
	getCompanySuccess,
	getCompanyInitTab,
	getCompanyTokenRequest,
	getCompanyTokenSuccess,
	editCompanySuccess,
	editCompanyRequest,
	editCompanyFail,
} from '../../redux/companies/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	getCompanyRequest: getCompanyRequest(state),
	getCompanyFail: getCompanyFail(state),
	company: getCompanySuccess(state),
	user: getUser(state),
	initTab: getCompanyInitTab(state),
	getCompanyTokenRequest: getCompanyTokenRequest(state),
	getCompanyTokenSuccess: getCompanyTokenSuccess(state),
	editCompanySuccess: editCompanySuccess(state),
	editCompanyRequest: editCompanyRequest(state),
	editCompanyFail: editCompanyFail(state),
});

const mapDispatchToProps = (dispatch) => ({
	getCompany: (params) => dispatch(getCompany(params)),
	getCompanyInit: () => dispatch(getCompanyInit()),
	pushBack: () => dispatch(localizedRoute('/companies')),
	getCompanyToken: (params) => dispatch(getCompanyToken(params)),
	editCompany: (params, companyId) => dispatch(editCompany(params, companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
