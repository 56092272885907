import { Status } from '../../../../utils/constants';

export const getPrepaidSubscriptionsRequest = (state) =>
	state.analyticsInvoices.tables.prepaidSubscriptions.getPrepaidSubscriptions
		.status === Status.PENDING;
export const getPrepaidSubscriptionsFail = (state) =>
	state.analyticsInvoices.tables.prepaidSubscriptions.getPrepaidSubscriptions
		.status === Status.FAIL;
export const getPrepaidSubscriptionsSuccess = (state) =>
	state.analyticsInvoices.tables.prepaidSubscriptions.getPrepaidSubscriptions
		.data;
export const getPrepaidSubscriptionsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.prepaidSubscriptions
		.getPrepaidSubscriptionsFilters.status === Status.PENDING;
export const getPrepaidSubscriptionsFiltersFail = (state) =>
	state.analyticsInvoices.tables.prepaidSubscriptions
		.getPrepaidSubscriptionsFilters.status === Status.FAIL;
export const getPrepaidSubscriptionsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.prepaidSubscriptions
		.getPrepaidSubscriptionsFilters.data;
