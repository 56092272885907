import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_RATE_PLAN_ZONES, GET_RATE_PLAN_ZONES_FILTERS } from './constants';

export default combineReducers({
	getRatePlanZones: globalReducer(GET_RATE_PLAN_ZONES),
	getRatePlanZonesFilters: globalReducer(GET_RATE_PLAN_ZONES_FILTERS)
});
