import { handleActions } from 'redux-actions';

import { INTERNAL_SERVER_ERROR, CLEAR_ERROR } from './constants';

export const initialState = {
	statusCode: '',
	statusErrors: [],
	statusCodeErrorPage: '',
	statusErrorPageError: null,
	response: null
};

export default handleActions(
	{
		[INTERNAL_SERVER_ERROR]: (state, action) => {
			let statusCode = [];
			let statusErrors = [];
			let statusCodeErrorPage = null;
			let statusErrorPageError = null;
			const { response } = action.payload;
			if (response) {
				statusCode = response.status;
				statusCodeErrorPage = response.status;
				if (response.data) {
					statusErrors = response.data.resultList || [response.data.message];
					statusErrorPageError = response.data.resultList || [
						response.data.message
					];
				}
			}
			return {
				...state,
				statusCode,
				statusErrors,
				statusCodeErrorPage,
				statusErrorPageError,
				response
			};
		},
		[CLEAR_ERROR]: (state) => ({
				...state,
				statusCode: '',
				statusErrors: [],
				response: null
			})
	},
	initialState
);
