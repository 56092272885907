import { Status } from '../../../../utils/constants';

export const isBulkRatePlanUpdating = (state) =>
	state.Endpoints.updateBulkRatePlan.status === Status.PENDING;
export const bulkRatePlanUpdateFail = (state) =>
	state.Endpoints.updateBulkRatePlan.status === Status.FAIL;
export const bulkRatePlanUpdateSuccess = (state) =>
	state.Endpoints.updateBulkRatePlan.status === Status.DONE;
export const getCurrentSim = (state) =>
	state.Endpoints.updateBulkRatePlan.currentSim;
export const getActionError = (state) =>
	state.Endpoints.updateBulkRatePlan.actionError;
export const getFailSims = (state) =>
	state.Endpoints.updateBulkRatePlan.failSims;
export const getSuccessSims = (state) =>
	state.Endpoints.updateBulkRatePlan.successSims;
export const getIsStarted = (state) =>
	state.Endpoints.updateBulkRatePlan.started;
export const getFilteredSims = (state) =>
	state.Endpoints.updateBulkRatePlan.filteredSims;
