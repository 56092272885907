import { createAction } from 'redux-actions';

import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import {
	ADD_SIM_SKU,
	DELETE_SIM_SKU,
	DELETE_SIM_SKU_MODAL,
	EDIT_SIM_SKU,
	EDIT_SIM_SKU_MODAL,
	GET_PARENT_SIM_SKUS,
	ADD_SIM_SKU_MODAL,
	ADD_SUB_ACCOUNT_SIM_SKU,
	GET_SKUS,
	POST_ACCOUNT_TRANSFER,
	GET_M2M_ACCOUNTS,
	GET_NETWORK_ENTITLEMENTS,
	GET_RATE_PLANS
} from './constants';

import {
	addSimSku as addSimSkuService,
	deleteSimSku as deleteSimSkuService,
	editSimSku as editSimSkuService,
	getSkus as getSimSkuService,
	assignSimSkus as assignSimSkuService,
	postAccountTransfer as updateAccountTransferService,
	getM2MAccounts as getM2MAccountsService,
	getNetworkEntitlements as getNetworkEntitlementsService,
	getRatePlans as getRatePlansService
} from '../../services/SimOrderingService';

export const openEditSimSkuModal = createAction(`OPEN_${EDIT_SIM_SKU_MODAL}`);
export const closeEditSimSkuModal = createAction(`CLOSE_${EDIT_SIM_SKU_MODAL}`);
export const openAddSimSkuModal = createAction(`OPEN_${ADD_SIM_SKU_MODAL}`);
export const closeAddSimSkuModal = createAction(`CLOSE_${ADD_SIM_SKU_MODAL}`);
export const openDeleteSimSkuModal = createAction(
	`OPEN_${DELETE_SIM_SKU_MODAL}`
);
export const closeDeleteSimSkuModal = createAction(
	`CLOSE_${DELETE_SIM_SKU_MODAL}`
);

export const addSimSku = (data) =>
	globalAction(ADD_SIM_SKU, addSimSkuService, data, {
		notify: 'add-sim-skus',
		init: true,
		modals: closeEditSimSkuModal(),
		notifyMessageSuccess: 'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS',
		notifyMessageError: 'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR'
	});

export const deleteSimSku = (id) =>
	globalAction(DELETE_SIM_SKU, deleteSimSkuService, id, {
		notify: 'delete-sim-ordering-sku',
		init: true,
		modals: closeDeleteSimSkuModal()
	});

export const editSimSku = (params) =>
	globalAction(EDIT_SIM_SKU, editSimSkuService, params, {
		notify: 'edit-sim-ordering-skus',
		init: true,
		modals: closeEditSimSkuModal(),
		notifyMessageSuccess: 'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS',
		notifyMessageError: 'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR'
	});

/* ******************** GET SIM SKUS ******************** */

export const getSkus = (params) =>
	globalAction(GET_SKUS, getSimSkuService, params);

/* ******************** GET PARENT SIM SKUS ******************** */
export const getParentSimSkus = (params) =>
	globalAction(GET_PARENT_SIM_SKUS, getSimSkuService, params);

export const getParentSimSkusInit = () => actionInit(GET_PARENT_SIM_SKUS);

/* ******************** ADD SUB SIM SKU ******************** */
export const addSubAccountSimSku = (params) =>
	globalAction(ADD_SUB_ACCOUNT_SIM_SKU, assignSimSkuService, params, {
		modals: closeAddSimSkuModal(),
		notify: 'add-sim-skus'
	});

/* ******************** ADD SIM SKU MODAL ******************** */

export const getM2MAccounts = (params) =>
	globalAction(GET_M2M_ACCOUNTS, getM2MAccountsService, params);

export const getNetworkEntitlements = (params) =>
	globalAction(GET_NETWORK_ENTITLEMENTS, getNetworkEntitlementsService, params);

	export const getNetworkEntitlementsInit = () => actionInit(GET_NETWORK_ENTITLEMENTS);

export const getRatePlans = (params) =>
	globalAction(GET_RATE_PLANS, getRatePlansService, params);

	export const getRatePlansInit = () => actionInit(GET_RATE_PLANS);

export const postAccountTransfer = (params) =>
	globalAction(POST_ACCOUNT_TRANSFER, updateAccountTransferService, params, {
		notify: 'post-account-transfer',
		notifyMessageSuccess: 'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS',
		notifyMessageError: 'ONBOARDING.ACCOUNT_TRANSFER_ERROR'
	});
