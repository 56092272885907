import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import DesktopView from './Desktop';
import MobileView from './Mobile';

import {
	openAddNotificationModal,
	closeAddNotificationModal,
	getNotifications,
	getAllNotifications,
	getNotificationsTypes,
	getNotificationsGroups,
	addNotification,
	editNotification,
	removeNotification,
	getNotification,
	getNotificationInit,
	getActiveNotifications
} from '../../redux/notifications/actions';

import {
	isAddNotificationModalOpen,
	getNotificationsRequest,
	getNotificationsSuccess,
	getNotificationsTypesRequest,
	getNotificationsTypesSuccess,
	getNotificationsGroupsRequest,
	getNotificationsGroupsSuccess,
	addNotificationRequest,
	addNotificationSuccess,
	getNotificationSuccess,
	getNotificationsFail,
	editNotificationRequest,
	editNotificationSuccess,
	removeNotificationRequest,
	removeNotificationSuccess
} from '../../redux/notifications/selectors';

const mapStateToProps = (state) => ({
		notificationsRequest: getNotificationsRequest(state),
		notifications: getNotificationsSuccess(state),
		notificationsTypesRequest: getNotificationsTypesRequest(state),
		notificationsTypes: getNotificationsTypesSuccess(state),
		notificationsGroupsRequest: getNotificationsGroupsRequest(state),
		notificationsGroups: getNotificationsGroupsSuccess(state),
		values: getFormValues('AddNotificationForm')(state),
		addNotificationRequest: addNotificationRequest(state),
		addNotificationSuccess: addNotificationSuccess(state),
		editNotificationRequest: editNotificationRequest(state),
		editNotificationSuccess: editNotificationSuccess(state),
		getNotificationSuccess: getNotificationSuccess(state),
		isGetAllNotificationRequestFailed: getNotificationsFail(state),
		isAddNotificationModalOpen: isAddNotificationModalOpen(state),
		removeNotificationRequest: removeNotificationRequest(state),
		removeNotificationSuccess: removeNotificationSuccess(state)
	});

const mapDispatchToProps = (dispatch) => ({
	getNotifications: (data) => dispatch(getNotifications(data)),
	getAllNotifications: (data) => dispatch(getAllNotifications(data)),
	getNotificationsTypes: () => dispatch(getNotificationsTypes()),
	getNotificationsGroups: () => dispatch(getNotificationsGroups()),
	addNotification: (data, type) => dispatch(addNotification({ data, type })),
	editNotification: (data, id, type) =>
		dispatch(editNotification({ data, id, type })),
	removeNotification: (id, type) => dispatch(removeNotification({ id, type })),
	getNotification: (id, type, additionalParams) =>
		dispatch(getNotification({ id, type, additionalParams })),
	getNotificationInit: () => dispatch(getNotificationInit()),
	openAddNotificationModal: () => dispatch(openAddNotificationModal()),
	closeAddNotificationModal: () => dispatch(closeAddNotificationModal()),
	getActiveNotifications: () => dispatch(getActiveNotifications())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopView);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileView);
