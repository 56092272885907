import qs from 'qs';

import ApiClient from '../utils/ApiClient';

export const get = () => {
	const calls = [
		ApiClient.get('/users/current'),
		ApiClient.get('/settings/user-settings')
	];

	return Promise.all(calls).then((response) => ({
			user: response[0].data,
			settings: response[1].data
		}));
};

export const postUserSettings = (requestObject) => ApiClient.post('/settings/user-settings', requestObject)
		.then((response) => response)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const patchUserSettings = (setting, id) => ApiClient.patch(`/settings/user-settings/${id}`, setting)
		.then((response) => response)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const deleteUserSettings = (id) => ApiClient.delete(`/settings/user-settings/${id}`, { data: { id } })
		.then((response) => response)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const resetUserSettings = (refTypeId) => ApiClient.delete(
		`/settings/user-settings/reset?${qs.stringify({
			searchParams: [
				{
					prop: 'refTypeId',
					propValue: refTypeId,
					operator: '='
				}
			]
		})}`,
		{ data: { refTypeId } }
	)
		.then((response) => response)
		.catch((error) => {
			throw { error: error.response.data };
		});
