import { connect } from 'react-redux';
import StepTwo from './StepTwo';

import { batchFileRequestURL } from '../../../../redux/batchFiles/actions';

const mapDispatchToProps = (dispatch) => ({
	batchFileRequestURL: (data, type) => dispatch(batchFileRequestURL(data, type))
});

export default connect(
	null,
	mapDispatchToProps
)(StepTwo);
