export default {
	'RELEASE_NOTES.RELEASE_NOTES': 'Releaseopmerkingen',
	'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'IoT Console wordt regelmatig geüpdatet.',
	'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': 'IoT Console wordt regelmatig geüpdatet om de meest optimale werking te verzekeren, uitgebreide functies en mogelijkhedenplus verbeterde gebruikerservaring te bieden. Hieronder staan de updates die in deze release zijn geïntroduceerd.',
	'RELEASE_NOTES.RELEASE_VERSION': 'Releaseversie',
	'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'Nieuwe platformfuncties',
	'RELEASE_NOTES.DEPRECATIONS': 'Afschaffingen',
	'RELEASE_NOTES.DEPRECATION': 'Afschaffing',
	'RELEASE_NOTES.KNOWN_ISSUES': 'Bekende problemen',
	'RELEASE_NOTES.NEW_FEATURES': 'Nieuwe functies',
	'RELEASE_NOTES.IMPROVEMENTS': 'Verbeteringen',
	'RELEASE_NOTES.FEATURE': 'Functie',
	'RELEASE_NOTES.IMPROVEMENT': 'Verbetering',
	'RELEASE_NOTES.ISSUE': 'Probleem',

	'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Nog een afschaffing toevoegen',
	'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Nog een platformfuncties toevoegen',
	'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Nog een functie toevoegen',
	'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Nog een bekend probleem toevoegen',
	'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Nog een verbetering toevoegen',

	'RELEASE_NOTES.PUBLISH': 'Versturen',
	'RELEASE_NOTES.SAVE': 'Opslaan',
	'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Releaseopmerkingen publiceren',
	'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': 'U staat op het punt om releaseopmerkingen te publiceren voor {releaseVersion}. Weet u zeker dat u dat wilt doen?',
	'RELEASE_NOTES.CANCEL': 'Annuleren'
};
