import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { union, uniqBy, find } from 'lodash';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Switch from '../../../../../lib/DigitalComponents/Switch';
import { tableHeaderPermissionsCheck } from '../../../../../utils/constants';
import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../../utils/AuthSelector';

import styles from '../EditTableModal.scss';

const SatelliteSpecificColumns = UserAllowedToAccess([
	'mnc.endpoints.satellitecolumns_rw',
	'mnc.endpoints.satellitecolumns_ro',
]);

class EditTableColumns extends Component {
  constructor(props) {
		super(props);

		this.state = {
			satellite: [
				'deviceName',
        'deviceModelNumber',
        'deviceType',
        'deviceId'
			],
			headers: []
		};
  }

  componentDidMount() {
    const {
			user,
			hasOriginal,
			activeTab,
			customLabelsColumns,
			columns,
			allColumns
		} = this.props;
    const { satellite } = this.state;
		const tab = find(columns, { name: activeTab });
		let items = [];
		const allItems = allColumns[0].value.data;
		const customLabels = customLabelsColumns;
		let columnsList = [];
		let allColumnsList = [];
		let customColumns = [];

		if (tab && !columns[0].default) {
			items = JSON.parse(tab.value).data;
		} else {
			items = allItems;
		}

		items.forEach((item) => {
			if (item.checked !== false && item.name) {
				columnsList = [
					...columnsList,
					{
						name: item.name,
						checked: true,
						value: items.value,
						type:
							satellite.indexOf(item.name) !== -1 ? 'satellite' : 'cellular',
						title: item.title
					}
				];
			}
		});
		allItems
			.filter((item) => item.name)
			.forEach((item) => {
				allColumnsList = [
					...allColumnsList,
					{
						name: item.name,
						checked: false,
						type:
							satellite.indexOf(item.name) !== -1 ? 'satellite' : 'cellular',
						title: item.title
					}
				];
			});

		Object.keys(customLabels).forEach((key) => {
			if (key.startsWith('customLabel') && key !== 'messageId') {
				const number = key.substr('customLabel'.length);
				customColumns = [
					...customColumns,
					{
						name: `customFieldValue${number}`,
						checked: false
					}
				];
			}
		});

		const allData = union(columnsList, allColumnsList, customColumns);
		let headers = uniqBy(allData, 'name');
    headers = tableHeaderPermissionsCheck(headers, user);
		columnsList = tableHeaderPermissionsCheck(columnsList, user);

		if (hasOriginal && hasOriginal.length > 0) {
			this.setState({
				headers,
				original: columnsList
			});
		} else {
			this.setState({
				headers,
				original: []
			});
		}
	}

	componentWillUnmount() {
		this.setState({
			headers: [],
			original: [],
			satellite: []
		});
	}

	submitColumns = (newColumns, oldColumns) => {
    const { onSubmitColumns, submitColumnsDisabled } = this.props;
		let newValues = [];
		let oldValues = [];

		newColumns.forEach((item, index) => {
			if (item.checked === true) {
				newValues = [
					...newValues,
					{
						name: newColumns[index].name,
						refTypeId: 1,
						value: newColumns[index].value ? newColumns[index].value : index,
					},
				];
			}
		});

		if (oldColumns.length > 0) {
			oldColumns.forEach((item, index) => {
				if (item.checked === true) {
					oldValues = [
						...oldValues,
						{
							name: oldColumns[index].name,
							refTypeId: 1,
							value: oldColumns[index].value
								? oldColumns[index].value
								: oldColumns[index].name,
						},
					];
				}
			});
		} else {
			oldValues = [];
		}

		onSubmitColumns(newValues, oldValues);
		submitColumnsDisabled(newValues, oldValues);
	};

	changeList = (e, index) => {
    const { headers, satellite } = this.state;
		this.setState(
			{
				headers: [
					...headers.slice(0, index),
					{
						name: headers[index].name,
						title: headers[index].title,
						checked: !headers[index].checked,
						value: headers[index].value ? headers[index].value : index + 1,
						type:
							satellite.indexOf(headers[index].name) !== -1
								? 'satellite'
								: 'cellular'
					},
					...headers.slice(index + 1)
				]
			},
			() => {
				const { headers: newHeaders, original } = this.state;
				this.submitColumns(newHeaders, original);
			}
		);
	};

	renderFilterList = (values, customNames) => {
		let cellular = [];
    let satellites = [];

		values.forEach((item, index) => {
			if (item.type === 'cellular' || !item.type) {
				const temp = { ...item, index };

				cellular = [...cellular, temp];
			} else {
				const temp = { ...item, index };

				satellites = [...satellites, temp];
			}
		});

		return (
			<div data-spec="title">
				<div className={styles.title}>
					<FormattedMessage
						id="ENDPOINTS.CELLULAR_COLUMNS"
						defaultMessage="Cellular Columns"
					/>
				</div>
				{cellular
					.filter((item) => item.name !== 'endpointName')
					.map((item) => (
						<Switch
							data-spec="switch"
							key={item.index}
							checked={item.checked}
							label={
								item.name.startsWith('customFieldValue') ? (
									customNames[item.name.match(/\d+/g) - 1].name
								) : (
									<FormattedMessage
										id={`ENDPOINTS.${item.title}`}
										defaultMessage="{title}"
										values={{ title: item.title }}
									/>
								)
							}
							onChange={(e) => this.changeList(e, item.index)}
							disabled={item.name === 'endpointName'}
						/>
					))}
				<SatelliteSpecificColumns>
					<>
						<div className={cn(styles.title, styles.satellite)}>
							<FormattedMessage
								id="ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS"
								defaultMessage="Satellite Specific Columns"
							/>
						</div>
						{satellites.map((item) => (
							<Switch
								data-spec="switch"
								key={item.index}
								checked={item.checked}
								label={
									item.name.startsWith('customFieldValue') ? (
										customNames[item.name.match(/\d+/g) - 1].name
									) : (
										<FormattedMessage
											id={`ENDPOINTS.${item.title}`}
											defaultMessage="{title}"
											values={{ title: item.title }}
										/>
									)
								}
								onChange={(e) => this.changeList(e, item.index)}
								disabled={item.name === 'endpointName'}
							/>
						))}
					</>
				</SatelliteSpecificColumns>
			</div>
		);
	};

	getNumberOfColumns = (values) => {
    const { allColumns, user } = this.props;
		const numberOfSatelliteColumns = allColumns[0].value.data.filter(
			(item) => item.type === 'satellite'
		).length;

		return isUserAllowedToAccess(
			[
				'mnc.endpoints.satellitecolumns_rw',
				'mnc.endpoints.satellitecolumns_ro',
			],
			user
		)
			? values.length - 1
			: values.length - numberOfSatelliteColumns;
	};

	render() {
    const { customLabelsColumns } = this.props;
		const { headers } = this.state;
    let customNames = [];

		if (customLabelsColumns) {
      Object.keys(customLabelsColumns).forEach((key) => {
				if (key.startsWith('customLabel') && key !== 'messageId') {
					customNames = [...customNames, { name: customLabelsColumns[key] }];
				}
			});
    }

		return (
			<div data-spec="edit-table-columns">
				<div className={styles.title}>
					<FormattedMessage
						id="ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE"
						defaultMessage="Select a minimum of 2 to apply to the table. "
					/>
					<span className={styles.number_of_columns}>
						{headers.filter((item) => item.checked === true).length - 1}
					</span>
					<FormattedMessage id="OF" defaultMessage=" of " />
					<span className={styles.number_of_columns}>
						{this.getNumberOfColumns(headers)}
					</span>
					<FormattedMessage id="SELECTED" defaultMessage=" selected" />
				</div>
				{this.renderFilterList(headers, customNames)}
			</div>
		);
  }
}

const { array, object, func, string } = PropTypes;

EditTableColumns.propTypes = {
	columns: array,
	allColumns: array,
	onSubmitColumns: func,
	hasOriginal: array,
	submitColumnsDisabled: func,
	customLabelsColumns: object,
	activeTab: string,
	user: object,
};

EditTableColumns.defaultProps = {
	columns: [],
	allColumns: [],
	onSubmitColumns: undefined,
	hasOriginal: [],
	submitColumnsDisabled: undefined,
	customLabelsColumns: {},
	activeTab: '',
	user: {},
};

export default EditTableColumns;
