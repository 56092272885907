import { handleActions } from 'redux-actions';

import { Status } from '../../../../utils/constants';
import {
	GET_ACCOUNT_OVERVIEW_CHART_DATA_REQUEST,
	GET_ACCOUNT_OVERVIEW_CHART_DATA_SUCCESS,
	GET_ACCOUNT_OVERVIEW_CHART_DATA_ERROR,
	GET_ACCOUNT_OVERVIEW_CHART_DATA_INIT,
	CHECK_ACCOUNT_OVERVIEW_CHART_SETTINGS,
} from './constants';

const initialState = {
	data: null,
	asOfDates: null,
	checkedCharts: null,
	error: null,
	status: Status.INIT,
	dispatcher: null
};

export default handleActions(
	{
		[GET_ACCOUNT_OVERVIEW_CHART_DATA_REQUEST]: (state, action) => ({
			...state,
			error: null,
			status: {
				...state.status,
				[action.payload.id]: Status.PENDING
			},
			dispatcher: action.payload.id
		}),
		[GET_ACCOUNT_OVERVIEW_CHART_DATA_SUCCESS]: (state, action) => ({
			...state,
			data: {
				...state.data,
				[action.payload.id]: action.payload.data.data.resultList
			},
			asOfDates: {
				...state.asOfDates,
				[action.payload.id]: action.payload.data.data.asOfDate
			},
			error: null,
			status: {
				...state.status,
				[action.payload.id]: Status.DONE
			},
			dispatcher: action.payload.id
		}),
		[GET_ACCOUNT_OVERVIEW_CHART_DATA_ERROR]: (state, action) => ({
			...state,
			data: null,
			asOfDates: null,
			error: action.payload.error,
			status: {
				...state.status,
				[action.payload.id]: Status.FAIL
			},
			dispatcher: action.payload.id
		}),
		[GET_ACCOUNT_OVERVIEW_CHART_DATA_INIT]: (state, action) => ({
			...state,
			data: {
				...state.data,
				[action.payload.id]: null
			},
			asOfDates: {
				...state.asOfDates,
				[action.payload.id]: null
			},
			error: null,
			status: {
				...state.status,
				[action.payload.id]: Status.INIT
			},
			dispatcher: action.payload.id
		}),
		[CHECK_ACCOUNT_OVERVIEW_CHART_SETTINGS]: (state, action) => ({
			...state,
			checkedCharts: {
				...state.checkedCharts,
				[action.payload.id]: state.checkedCharts
				&& state.checkedCharts[action.payload.id]
					? !state.checkedCharts[action.payload.id] : true,
			}
		})
	},
	initialState
);
