import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import CurrentContext from 'utils/currentContext';
import { getTheme, getLocale, LOCALES } from 'utils/constants';
import { loadLanguages } from 'utils/localization';
import { cloneDeep, find } from 'lodash';
import Loader from '../lib/DigitalComponents/Loader';
import MainLayout from '../views/MainLayoutView';
import ErrorPageView from '../components/ErrorPage/views/ErrorPageView';
import onboardingRoutes from '../components/Onboarding/routes';
import simOrderingRoutes from '../components/SimOrdering/routes';
import ticketingRoutes from '../components/Ticketing/routes';
import supportRoutes from '../components/Support/routes';
import analyticsInvoicesRoutes from '../components/AnalyticsInvoices/routes';
import automationRoutes from '../components/Automation/routes';
import accountOverviewRoutes from '../components/AccountOverview/routes';
import reportsRoutes from '../components/Reports/routes';
import failoverRoutes from '../components/Failover/routes';
import analyticsRoutes from '../components/Analytics/routes';
import batchHistoryRoutes from '../components/BatchHistory/routes';
import errorRoutes from '../components/ErrorPage/routes';
import swaggerRoutes from '../components/Swagger/routes';
import securityFeaturesRoutes from '../components/SecurityFeatures/routes';
import releaseNotesRoutes from '../components/ReleaseNotes/routes';
import singleEndpointRoutes from '../components/SingleEndpoint/routes';
import endpointRoutes from '../components/Endpoint/routes';

import AuthSignInCallback from '../components/AuthSignInCallback';

import AccountActivationView from '../components/Onboarding/views/AccountActivationView';
import automationZonesRoutes from '../components/AutomationZones/routes';
/* import RequestAccessView from '../components/Onboarding/views/RequestAccessView'; */

import { getSessionLocale } from '../redux/locale/selectors';
import { setLanguageCode } from '../utils/helperFunctions';

/* eslint-disable react/display-name */
// please be advised to take a caution when ordering routes

const allRoutes = [
	...accountOverviewRoutes,
	...onboardingRoutes,
	...simOrderingRoutes,
	...securityFeaturesRoutes,
	...supportRoutes,
	...ticketingRoutes,
	...analyticsInvoicesRoutes,
	...reportsRoutes,
	...endpointRoutes,
	...failoverRoutes,
	...automationRoutes,
	...analyticsRoutes,
	...batchHistoryRoutes,
	...errorRoutes,
	...swaggerRoutes,
	...releaseNotesRoutes,
	...singleEndpointRoutes,
	...automationZonesRoutes
];

const routes = allRoutes.map((item) => ({
	...item,
	path: `/:language?${item.path}`
}));

class Routes extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			messages: null
		};
	}

	async componentDidMount() {
		this.updateMessages();
	}

	async componentDidUpdate(prevProps) {
		const {
			language,
			history,
			history: { location }
		} = this.props;

		if (
			prevProps.language &&
			prevProps.language.toLowerCase() !== language.toLowerCase()
		) {
			this.updateMessages();
		}

		if (history.action === 'POP') {
			const currentLanguage = location.pathname.split('/')[1];
			const foundLocal = find(LOCALES, {
				label: currentLanguage.toUpperCase()
			});

			if (
				foundLocal &&
				currentLanguage.toLowerCase() !== language.toLowerCase()
			) {
				setLanguageCode(foundLocal.formattedLocal);
			}
		}
	}

	updateMessages = async () => {
		const { language } = this.props;
		const languageCode = find(LOCALES, {
			label: language.toUpperCase()
		});

		CurrentContext.theme = getTheme();
		CurrentContext.locale =
			(languageCode && languageCode.formattedLocal) || getLocale();

		const { messages } = await loadLanguages(
			CurrentContext.theme,
			CurrentContext.locale
		);

		this.setMessages(cloneDeep(messages));
	};

	setMessages = (messages) => this.setState({ messages });

	render() {
		const { language } = this.props;
		const { messages } = this.state;

		return messages ? (
			<IntlProvider
				data-spec="intl-provider"
				locale={CurrentContext.locale}
				key={CurrentContext.locale}
				messages={messages}
				textComponent={React.Fragment}
			>
				<Switch data-spec="routes">
					<Route exact path="/accept-invite">
						<AccountActivationView />
					</Route>
					{/* <Route exact path="/request-access">
						<RequestAccessView />
					</Route> */}
					<Route exact path="/signin-callback">
						<AuthSignInCallback />
					</Route>
					{routes.map(
						({
							path,
							noLayout,
							Component,
							exact,
							customProps,
							withoutSidebar
						}) => (
							<Route
								key={0}
								exact={exact}
								path={path}
								render={({ history, location, match }) =>
									(!noLayout ? (
										<MainLayout
											messages={messages}
											history={history}
											location={location}
											match={match}
											withoutSidebar={withoutSidebar}
											language={language}
										>
											<Component
												messages={messages}
												history={history}
												location={location}
												match={match}
												{...customProps}
											/>
										</MainLayout>
									) : (
										<Component
											history={history}
											location={location}
											match={match}
										/>
									))}
							/>
						)
					)}
					<Redirect
						from="*"
						to={`/${
							language ? language.toLowerCase() : 'en-us'
						}/page-not-found`}
						component={ErrorPageView}
					/>
				</Switch>
			</IntlProvider>
		) : (
			<Loader />
		);
	}
}

const { string, object } = PropTypes;

Routes.propTypes = {
	language: string,
	history: object
};

const mapStateToProps = (state) => ({
  language: getSessionLocale(state)
});

export default withRouter(connect(mapStateToProps)(Routes));
/* eslint-enable react/display-name */
