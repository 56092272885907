import { handleActions } from 'redux-actions';

import { SET_ENDPOINTS_ACTIVE_TAB } from './constants';

export const initialState = {
	activeTab: 'tab'
};

export default handleActions(
	{
		[SET_ENDPOINTS_ACTIVE_TAB]: (state, action) => ({
				...state,
				activeTab: action.payload
			})
	},
	initialState
);
