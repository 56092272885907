import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, groupBy, orderBy, uniqBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { transpose } from '../../../../SingleEndpoint/utils/helpers';
import { getRatePlans } from '../../../redux/actions';
import {
	getRatePlansRequest,
	getRatePlansSuccess
} from '../../../redux/selectors';
import Button from '../../../../../lib/DigitalComponents/Button';
import Loading from '../../../../../lib/DigitalComponents/Loader';
import StepFirstRatePlanTable from '../../../../SingleEndpoint/components/RatePlanChangeStepper/Steps/StepFirstRatePlanTable';
import { allowedServiceProviders } from '../../../../../utils/constants';

import styles from './Steps.scss';

const RatePlanRWComponent = UserAllowedToAccess([
	'mnc.endpoints.rateplans_rw'
]);

class StepFirst extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newRatePlan: {},
			originalRatePlan: {
				ratePlan: {}
			},
			transposed: false,
		};
	}

	componentDidMount() {
		const { ratePlans, fetchRatePlans, selectedSims, context } = this.props;

		if (!ratePlans.length) {
			fetchRatePlans();
		} else {
			this.setRatePlane({ selectedSims, ratePlans });
		}

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context, ratePlans } = this.props;
		const { transposed } = this.state;

		if (
			context &&
			this.checkButton() !== this.checkButton(prevProps, prevState)
		) {
			this.setFooter(context);
		}
		if (ratePlans && !transposed) {
			this.setTransposedData();
		}
	}

	setFooter = (context) => {
		const { onClose } = this.props;
		const footer = (
			<div>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<RatePlanRWComponent>
					<Button
						dataSpec="continue"
						variant="primary"
						onClick={this.onNext}
						disabled={this.checkButton()}
						label={<FormattedMessage id="CONTINUE" defaultMessage="Continue" />}
					/>
				</RatePlanRWComponent>
			</div>
		);

		context.updateContext({ footer });
	};

	setRatePlane = ({ selectedSims, ratePlans }) => {
		if (!selectedSims.length) return;
		const groupedValues = groupBy(selectedSims, 'ratePlanId');
		const plansList = orderBy(
			Object.keys(groupedValues).map((key) => ({
				name: key,
				value: groupedValues[key].length
			})),
			['value'],
			['desc']
		);
		const ratePlan = find(
			ratePlans,
			(o) => o.ratePlan.id === plansList[0].name
		);
		this.checkRatePlans({ selectedSims, ratePlans }, plansList);
		if (ratePlan) {
			this.setState({
				originalRatePlan: ratePlan,
				newRatePlan: ratePlan,
				ratePlansCount: plansList[0].value,
			});
		}
	};

	setTransposedData = () => this.setState({
		transposedData: transpose(this.filterRatePlansBym2mAccountId()),
		transposed: true,
	})

	checkRatePlans = ({ ratePlans, selectedSims }, plansList) => {
		if (ratePlans.length) {
			if (
				ratePlans.length === 1 &&
				plansList[0].value === selectedSims.length &&
				ratePlans[0].ratePlan.name === plansList[0].name
			) {
				this.setState({
					areRatePlansAvailable: false
				});
			} else {
				this.setState({
					areRatePlansAvailable: true
				});
			}
		} else {
			this.setState({
				areRatePlansAvailable: false
			});
		}
	};

	onNext = () => {
		const { newRatePlan } = this.state;
		const { onNext, selectedSims } = this.props;

		onNext({ selectedSims, newRatePlan });
	};

	handleSelectRatePlan = (plan) => {
		const { ratePlans } = this.props;

		this.setState({
			newRatePlan: find(ratePlans, (o) => o.ratePlan.id === plan)
		});
	};

	checkButton = (prevProps, prevState) => {
		const { selectedSims } = prevProps || this.props;
		const {
			areRatePlansAvailable,
			ratePlansCount,
			originalRatePlan,
			newRatePlan
		} = prevState || this.state;

		return (
			!selectedSims.length ||
			!areRatePlansAvailable ||
			(uniqBy(selectedSims, 'serviceProvider').length >= 2 ||
				allowedServiceProviders.indexOf(
					uniqBy(
						selectedSims,
						'serviceProvider'
					)[0].serviceProvider.toLowerCase()
				) === -1) ||
			(ratePlansCount === selectedSims.length &&
				originalRatePlan === newRatePlan) ||
				!Object.keys(newRatePlan).length
		);
	};

	filterRatePlansBym2mAccountId = () => {
		const { ratePlans, selectedSims } = this.props;

		return ratePlans.filter(
			(m) => m.ratePlan.m2mAccountId == selectedSims[0].m2mAccountId
		);
	};

	renderModalBody = (ratePlanId) => {
		const {
			areRatePlansAvailable,
			ratePlansCount,
			originalRatePlan,
			transposedData,
		} = this.state;
		const { selectedSims } = this.props;

		if (selectedSims.length < 2) {
			return (
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className={styles.step_first}>
						<h2 className={styles.touppercase}>
							<FormattedMessage
								id="ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS"
								defaultMessage="Please select a few of SIMS."
							/>
						</h2>
					</div>
				</div>
			);
		}
		if (
			uniqBy(selectedSims, 'serviceProvider').length >= 2 ||
			allowedServiceProviders.indexOf(
				uniqBy(selectedSims, 'serviceProvider')[0].serviceProvider.toLowerCase()
			) === -1
		) {
			return (
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className={styles.step_first}>
						<h2 className={styles.touppercase}>
							<FormattedMessage
								id="ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED"
								defaultMessage="Change rate plan is not allowed"
							/>
						</h2>
					</div>
				</div>
			);
		}
		if (!areRatePlansAvailable) {
			return (
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className={styles.step_first}>
						<h2 className={styles.touppercase}>
							<FormattedMessage
								id="ENDPOINTS.NO_RATE_PLANS_AVAILABLE"
								defaultMessage="No rate plans available"
							/>
						</h2>
					</div>
				</div>
			);
		}
		return (
			<div data-spec="ds-modal-body" className={'first-step-modal-body'}>
				<div
					className={cn(styles.select_field_label, styles.normal_font_weight)}
				>
					<FormattedMessage
						id="ENDPOINTS.CHANGE_RATE_PLAN"
						defaultMessage="Change Rate Plan"
					/>
				</div>
				<div className={styles.select_field_label_text}>
					{`${ratePlansCount || 0} `}
					<FormattedMessage id="OF" defaultMessage=" of " />
					{` ${selectedSims.length} `}
					<FormattedMessage
						id="ENDPOINTS.SIMS_HAVE"
						defaultMessage="SIMs have"
					/>
					<span
						className={cn(styles.select_field_label_text, styles.rate_plan)}
					>
						&nbsp;
						{originalRatePlan.ratePlan.name || (
							<FormattedMessage
								id="ENDPOINTS.NO_RATE_PLAN_SELECTED"
								defaultMessage=" no Rate plan selected"
							/>
						)}
						.&nbsp;
					</span>
					<FormattedMessage
						id="ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS"
						defaultMessage="Select different rate plan to apply change for all selected SIMs"
					/>
				</div>
				{this.filterRatePlansBym2mAccountId().length > 0 && (
					<StepFirstRatePlanTable
						ratePlans={this.filterRatePlansBym2mAccountId()}
						styles={styles}
						onClick={this.handleSelectRatePlan}
						selected={ratePlanId}
						isMultiAction
						currentRatePlan={originalRatePlan}
						transposedData={transposedData}
					/>
				)}
				{this.filterRatePlansBym2mAccountId().length === 0 && (
					<span
						className={cn(styles.select_field_label_text, styles.rate_plan)}
					>
						<FormattedMessage
							id="ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE"
							defaultMessage="There is an error on underlying platform and rate plan change is not available at the moment."
						/>
					</span>
				)}
			</div>
		);
	};

	render() {
		const { newRatePlan } = this.state;
		const { isRatePlansFetching } = this.props;

		if (isRatePlansFetching) {
			return <Loading data-spec="loader" />;
		}

		const ratePlanId = newRatePlan.ratePlan && newRatePlan.ratePlan.id;

		return (
			<div data-spec="first-step" className={styles.first_step}>
				{this.renderModalBody(ratePlanId)}
			</div>
		);
	}
}

StepFirst.propTypes = {
	onClose: PropTypes.func,
	onNext: PropTypes.func,
	fetchRatePlans: PropTypes.func,
	isRatePlansFetching: PropTypes.bool,
	ratePlans: PropTypes.array,
	selectedSims: PropTypes.array,
	context: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	fetchRatePlans: () => dispatch(getRatePlans())
});

const mapStateToProps = (state) => ({
	isRatePlansFetching: getRatePlansRequest(state),
	ratePlans: getRatePlansSuccess(state)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepFirst);
