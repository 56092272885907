import { handleActions } from 'redux-actions';

import { ANY_MODAL_OPEN, ANY_MODAL_CLOSE } from './constants';

export const initialState = {
	anyModalOpened: false
};

export default handleActions(
	{
		[ANY_MODAL_OPEN]: (state) => ({
				...state,
				anyModalOpened: true
			}),
		[ANY_MODAL_CLOSE]: (state) => ({
				...state,
				anyModalOpened: false
			})
	},
	initialState
);
