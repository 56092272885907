import { createAction } from 'redux-actions';

import { showNotification } from '../../../Shared/redux/notifications/actions';
import {
	downloadReportRequestURL as downloadReportRequestURLService,
	reportPollForFile
} from '../../../../services/DownloadService';
import { downloadReportHelper, getLocale } from '../../../../utils/constants';
import {
	DOWNLOAD_INVOICE_INIT,
	DOWNLOAD_INVOICE_REQUEST,
	DOWNLOAD_INVOICE_FAIL,
	DOWNLOAD_INVOICE_SUCCESS
} from './constants';

const downloadInvoiceInit = createAction(DOWNLOAD_INVOICE_INIT);
const downloadInvoiceRequest = createAction(DOWNLOAD_INVOICE_REQUEST);
const downloadInvoiceFail = createAction(DOWNLOAD_INVOICE_FAIL);
const downloadinvoiceSuccess = createAction(DOWNLOAD_INVOICE_SUCCESS);

export const downloadReportRequestURL = (data) => async (
	dispatch
) => {
	dispatch(downloadInvoiceInit());
	const downloadURL = data.reportFileName;
	let downloadFileId = null;
	const locale = getLocale();
	const {
		default: { DOWNLOAD_REPORT_ERROR_MESSAGE }
	} = await import(`../../../../localizations/${locale}`);

	dispatch(downloadInvoiceRequest());

	try {
		const response = await downloadReportRequestURLService(data);

		downloadFileId = response.data.id;

		const pollResponse = await reportPollForFile(
			{ id: downloadFileId },
			100,
			4000
		);
		downloadReportHelper(pollResponse.data.downloadUrl, downloadURL);
		dispatch(downloadinvoiceSuccess());
	} catch (error) {
		dispatch(
			showNotification({
				id: 'download-invoice-fail',
				message: [DOWNLOAD_REPORT_ERROR_MESSAGE],
				type: 'error',
				duration: 5000
			})
		);
		dispatch(downloadInvoiceFail(error));
	}
};

export const downloadReportRequestInit = () => (dispatch) =>
	dispatch(downloadInvoiceInit());
