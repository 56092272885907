import { connect } from 'react-redux';
import RequestedFeatureModal from './RequestedFeatureModal';

import { requestedFeatureData } from '../../redux/blacklistedPermissions/selectors';
import {
	rejectRequestedFeature,
	approveRequestedFeature
} from '../../redux/blacklistedPermissions/actions';

const mapStateToProps = (state) => ({
	permission: requestedFeatureData(state)
});

const mapDispatchToProps = (dispatch) => ({
	approveRequestedFeature: (params) => dispatch(approveRequestedFeature(params)),
	rejectRequestedFeature: (params) => dispatch(rejectRequestedFeature(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RequestedFeatureModal);
