import React from 'react';
import propTypes from 'prop-types';

import './styles.scss';

const styles = {
	wrapper: {
		position: 'absolute',
		width: '100%',
		zIndex: '100',
		top: '50%',
		transform: 'translateY(-50%)',
		textAlign: 'center'
	},
	btn: {
		width: '30px',
		height: '30px',
		cursor: 'pointer',
		userSelect: 'none',
		position: 'absolute',
		bottom: '0',
		font: '16px/30px sans-serif',
		border: 'solid 1px #afafaf',
		borderRadius: '100%'
	},
	left: {
		left: '15px'
	},
	right: {
		right: '15px'
	}
};

export default function Buttons(props) {
	const prevBtnStyle = { ...styles.btn, ...styles.left };
	const nextBtnStyle = { ...styles.btn, ...styles.right };
	const { index, total, loop, prevHandler, nextHandler } = props;

	return (
		<div style={styles.wrapper} data-spec="button-wrapper">
			{(loop || index !== 0) && (
				<div className="arrow" style={prevBtnStyle} onClick={prevHandler}>
					&#10094;
				</div>
			)}
			{(loop || index !== total - 1) && (
				<div className="arrow" style={nextBtnStyle} onClick={nextHandler}>
					&#10095;
				</div>
			)}
		</div>
	);
}

Buttons.propTypes = {
	index: propTypes.number.isRequired,
	total: propTypes.number.isRequired,
	prevHandler: propTypes.func,
	nextHandler: propTypes.func,
	loop: propTypes.bool
};
