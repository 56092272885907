export default {
	'SECURITY_FEATURES.TITLE': 'Recursos de segurança',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'Lista de permissão de IMEI',
	'SECURITY_FEATURES.SMS_WHITELIST': 'Lista de permissão de SMS',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'Lista de permissão de VOZ',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'Lista negra de porta URL, IP',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'Lista de permissão de intervalo de IP',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': 'Não há recursos de segurança disponíveis',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': 'Não há recursos de segurança para exibir nesta tabela no momento',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'NOME DA CONTA DE M2M',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'DATA DE ENVIO',
	'SECURITY_FEATURES.STATUS': 'STATUS',
	'SECURITY_FEATURES.PENDING': 'Pendente',
	'SECURITY_FEATURES.FAILED': 'Falhou',
	'SECURITY_FEATURES.SUCCESSFUL': 'Bem-sucedido',
	'SECURITY_FEATURES.CCIP': 'Control Center Integration Program (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Central de controle da Vivo',
	'SECURITY_FEATURES.DCP': 'Plataforma de conexão de dispositivos (DCP)',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'AT&T Control Center (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SEARCH MENU
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOZ',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, PORTA',
	'SECURITY_FEATURES.MENU_5': 'INTERVALO DE IP',
	'SECURITY_FEATURES.ADD_1': 'Acrescentar novo IMEI à lista de permissão',
	'SECURITY_FEATURES.ADD_2': 'Acrescentar nova SMS à lista de permissão',
	'SECURITY_FEATURES.ADD_3': 'Acrescentar nova VOZ à lista de permissão',
	'SECURITY_FEATURES.ADD_4': 'Acrescentar novo IP, URL, PORTA à lista de bloqueio',
	'SECURITY_FEATURES.ADD_5': 'Acrescentar novo intervalo de IP à lista de permissão',
	'SECURITY_FEATURES.EXPORT': 'Exportar',
	'SECURITY_FEATURES.EDIT': 'Revisar',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'Selecionar plataforma M2M',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Upload em massa',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Carregar arquivo',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Confirmar mudanças',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. Confirmação do sistema',
	'SECURITY_FEATURES.CANCEL': 'Cancelar',
	'SECURITY_FEATURES.CONTINUE': 'Continuar',
	'SECURITY_FEATURES.DONE': 'Concluído',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Carregar arquivo',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': 'Carregar arquivos para a ação de lotes selecionada:',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Arrastar arquivo ou clicar para carregar',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Confirmar carregamento do arquivo de lotes',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Ação agendada em',
	'SECURITY_FEATURES.LOADER_MESSAGE': 'Aguarde. Isso pode levar alguns minutos, dependendo do tamanho do arquivo.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': 'Arquivos do lote recebidos com êxito!',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': 'As mudanças pendentes no sistema concluem as atualizações',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'Erro no sistema de arquivo em lotes!',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': 'Ocorreu um problema no processamento de atualizações. Tente de novo mais tarde.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Baixar modelo',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': 'Os arquivos devem ser enviados com a extensão .csv.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': 'O máximo de recursos de segurança por carregamento é '
};
