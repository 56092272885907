import { connect } from 'react-redux';
import {
	downloadReportFile,
	downloadReportRequestURL
} from '../../redux/downloadReport/actions';

import { sfgRepost, sfgRepostNotification } from '../../redux/reports/actions';

import DateMyExports from './DateOld';
import DateAnalytics from './Date';
import { repostInit, repostSuccess, sfgRepostFail, sfgRepostRequest } from '../../redux/reports/selectors';

const mapDispatchToProps = (dispatch) => ({
		downloadReportFile: (params) => dispatch(downloadReportFile(params)),
		sfgRepostNotification: (notification) => dispatch(sfgRepostNotification(notification)),
		sfgRepost: (
			reportFileName,
			serviceProvider,
			m2mAccountId,
			yearOfGeneration,
			monthOfGeneration,
			dateOfGeneration
		) => dispatch(
			sfgRepost({
				reportFileName,
				serviceProvider,
				m2mAccountId,
				yearOfGeneration,
				monthOfGeneration,
				dateOfGeneration
			})
		),
		downloadReport: (
			fullReportFileName,
			reportFrequency,
			date,
			serviceProvider,
			year,
			month,
			day,
			currentTab,
			m2mAccountId
		) => dispatch(
				downloadReportRequestURL({
					reportFileName: fullReportFileName,
					reportFrequency,
					reportCategory: currentTab,
					serviceProvider,
					yearOfGeneration: year,
					monthOfGeneration: month,
					dateOfGeneration: day,
					m2mAccountId
				})
			)
	});

const mapStateToProps = (state) => ({
	sfgRepostInit: repostInit(state),
	sfgRepostRequest: sfgRepostRequest(state),
	sfgRepostSuccess: repostSuccess(state),
	sfgRepostFail: sfgRepostFail(state)
});

export const DateOld = connect(
	mapStateToProps,
	mapDispatchToProps
)(DateMyExports);
export const Date = connect(
	null,
	mapDispatchToProps
)(DateAnalytics);
