import { connect } from 'react-redux';
import UploadFile from './UploadFile';

import {
	fileRequest,
	resetFileNumbers,
	clearUploadFiles,
	resetErrors
} from '../../redux/uploadFile/actions';

import {
	uploadFileNumber,
	fileUploadRequest,
	fileUploadSuccess
} from '../../redux/uploadFile/selectors';

import {
	showNotification,
	hideNotification
} from '../../redux/notifications/actions';

const mapStateToProps = (state) => ({
	uploadFileNumber: uploadFileNumber(state),
	filePending: fileUploadRequest(state),
	fileSuccess: fileUploadSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	fileRequest: (data) => dispatch(fileRequest(data)),
	resetFileNumbers: () => dispatch(resetFileNumbers()),
	removeFile: (name) => dispatch(clearUploadFiles(name)),
	resetErrors: () => dispatch(resetErrors()),
	showNotification: (notificationData) =>
		dispatch(
			showNotification({
				id: notificationData.id,
				message: [notificationData.message],
				type: notificationData.type,
				title: notificationData.title,
				close: true,
        duration: 5000,
        handleDismiss: () => dispatch(hideNotification(notificationData.id))
			})
		),
	hideNotification: (id) => dispatch(hideNotification(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
