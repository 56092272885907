/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CurrentContext from '../../../../../../utils/currentContext';
import ImageSelector from '../../../../../../utils/imageSelector';
import { getDateFormat, isValidDate } from '../../../../../../utils/constants';
import {
	fifthEntitlementArray,
	firstEntitlementArray,
	fourthEntitlementArray,
	secondEntitlementArray,
	thirdEntitlementArray
} from '../../../../../SingleEndpoint/utils/constants';

import styles from './EntitlementInfo.scss';

const Success = ImageSelector(CurrentContext.theme, 'svgs/ok-full.svg');

function EntitlementInfo({ chosenEntitlement, selectedSims }) {
	const renderInfo = (info) => {
		if (info === true) {
			return (
				<span data-spec="yes-info">
					<FormattedMessage id="YES" defaultMessage="Yes" />
				</span>
			);
		}
		if (info === false) {
			return (
				<span data-spec="no-info">
					<FormattedMessage id="NO" defaultMessage="No" />
				</span>
			);
		}
		return info;
	};

	const entitlementDataItem = (label, info, grayedOutIfMissing) => (
		<div
			className={styles.entitlements_details_item}
			data-spec="entitlement-item"
			key={label.id}
		>
			<div
				className={cn({
					[styles.entitlements_item_title]: true,
					[styles.grayed_out]: grayedOutIfMissing && info === undefined
				})}
			>
				<FormattedMessage id={label.id} defaultMessage={label.defaultMessage} />
			</div>
			<div className={styles.entitlements_item_data}>
				{info || (grayedOutIfMissing ? '' : '-')}
			</div>
		</div>
	);

	const checkAndGetDateFormat = (value) =>
		(isValidDate(value) ? getDateFormat(value) : undefined);

	const checkEntitlementStatus = () =>
		selectedSims &&
		selectedSims[0].networkEntitlementId === chosenEntitlement.id;

	return (
		<>
			<div className={styles.entitlement_info}>
				<h3>
					<FormattedMessage
						id="ENDPOINTS.NETWORK_ENTITLEMENT_DETAILS"
						defaultMessage="Network entitlement details"
					/>
				</h3>
				{chosenEntitlement.name}
				<p className={styles.current_network_entitlement}>
					<Success />
					{checkEntitlementStatus() ? (
						<FormattedMessage
							id="ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS"
							defaultMessage="Currently assigned to selected endpoints"
						/>
					) : (
						<FormattedMessage
							id="ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS"
							defaultMessage="Eligible to use with selected endpoints"
						/>
					)}
				</p>
			</div>
			{firstEntitlementArray(
				chosenEntitlement,
				renderInfo
			).map(({ label, info, grayedOutIfMissing }) =>
				entitlementDataItem(label, info, grayedOutIfMissing))}
			<div
				className={styles.entitlements_details}
				data-spec="entitlement-details"
			>
				<div className={styles.entitlements_details_container}>
					{secondEntitlementArray(
						chosenEntitlement,
						renderInfo
					).map(({ label, info, grayedOutIfMissing }) =>
						entitlementDataItem(label, info, grayedOutIfMissing))}
				</div>
				<div className={styles.entitlements_details_container}>
					{thirdEntitlementArray(
						chosenEntitlement,
						renderInfo
					).map(({ label, info, grayedOutIfMissing }) =>
						entitlementDataItem(label, info, grayedOutIfMissing))}
				</div>
			</div>
			<div
				className={styles.entitlements_details}
				data-spec="entitlement-details"
			>
				<div className={styles.entitlements_details_container}>
					{fourthEntitlementArray(
						chosenEntitlement,
						renderInfo
					).map(({ label, info, grayedOutIfMissing }) =>
						entitlementDataItem(label, info, grayedOutIfMissing))}
				</div>
				<div className={styles.entitlements_details_container}>
					{fifthEntitlementArray(
						chosenEntitlement,
						renderInfo
					).map(({ label, info, grayedOutIfMissing }) =>
						entitlementDataItem(label, info, grayedOutIfMissing))}
				</div>
			</div>
			<div
				className={cn(styles.entitlements_details, styles.apns)}
				data-spec="entitlement-apn"
			>
				{chosenEntitlement &&
          chosenEntitlement.onStateProfile &&
          chosenEntitlement.onStateProfile.apns &&
					chosenEntitlement.onStateProfile.apns.length > 0 &&
					chosenEntitlement.onStateProfile.apns.map((itm) => (
						<div
							key={itm.id}
							className={styles.apns_container}
							data-spec="apn-container"
						>
							{Object.keys(itm).map((key) =>
								entitlementDataItem(
									{
										id: `APNS_${key.toUpperCase()}`,
										defaultMessage: key
									},
									renderInfo(
										key === 'createDate' ||
											key === 'lastUpdateDate' ||
											key === 'lastUpdateTimestamp'
											? checkAndGetDateFormat(itm[key])
											: itm[key]
									),
									false
								))}
						</div>
					))}
			</div>
		</>
	);
}

const { object, array } = PropTypes;

EntitlementInfo.propTypes = {
	chosenEntitlement: object,
	selectedSims: array
};

export default EntitlementInfo;
