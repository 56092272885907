import { connect } from 'react-redux';
import AddUserGroupModal from './AddUserGroupModal';

import { getUserGroups } from '../../../redux/userGroups/actions';

import { getUserGroupPermissions } from '../../../redux/userGroupPermissions/actions';

import {
	getUserGroupPermissionsRequest,
	getUserGroupPermissionsSuccess
} from '../../../redux/userGroupPermissions/selectors';

import {
	getUserGroupsFail,
	getUserGroupsSuccess,
	getUserGroupsRequest
} from '../../../redux/userGroups/selectors';

const mapStateToProps = (state) => ({
	userGroupsRequest: getUserGroupsRequest(state),
	userGroupsFail: getUserGroupsFail(state),
	userGroups: getUserGroupsSuccess(state),
	permissionsRequest: getUserGroupPermissionsRequest(state),
	permissions: getUserGroupPermissionsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getUserGroups: (params) => dispatch(getUserGroups(params)),
	getPermissions: (params) => dispatch(getUserGroupPermissions(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroupModal);
