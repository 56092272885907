import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import Button from '../../../../lib/DigitalComponents/Button';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import styles from './ReactivateUserModal.scss';

const Icon = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

class ReactivateUserModal extends PureComponent {
	reactivateUser = () => {
		const { reactivateUser, data, system, onClose } = this.props;
		const params = {
			data,
			system
		};
		reactivateUser(params);
		onClose();
	};

	render() {
		const { show, onClose } = this.props;
		return (
			<Modal data-spec="confirmation-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.REACTIVATE_USER"
							defaultMessage="Reactivate User"
						/>
					}
					closeModal={onClose}
				/>
				<ModalBody className={styles.modal__body}>
					<div data-spec="body-message" className={styles.body}>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED"
								defaultMessage="Are you sure you want to proceed?"
							/>
						</div>
					</div>
					<div>
						<Icon />
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						dataSpec={'close-button'}
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						onClick={onClose}
						type="button"
					/>
					<Button
						type="submit"
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.SUBMIT"
								defaultMessage="Submit"
							/>
						}
						dataSpec="submit-button"
						onClick={() => this.reactivateUser()}
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, object } = PropTypes;

ReactivateUserModal.propTypes = {
	onClose: func,
	show: bool,
	system: bool,
	reactivateUser: func,
	data: object
};

export default ReactivateUserModal;
