import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';
import globalModalReducer from '../../../../redux/global/globalModalReducer';

import {
	ADD_NETWORK_ENTITLEMENT,
	EDIT_NETWORK_ENTITLEMENT,
	GET_NETWORK_ENTITLEMENT,
	GET_NETWORK_ENTITLEMENTS,
	ADD_NETWORK_ENTITLEMENTS_MODAL,
	GET_PARENT_NETWORK_ENTITLEMENTS,
	ADD_SUB_ACCOUNT_NETWORK_ENTITLEMENTS,
	SHOW_ASSOCIATED_APNS
} from './constants';

export default combineReducers({
	addNetworkEntitlement: globalReducer(ADD_NETWORK_ENTITLEMENT),
	editNetworkEntitlement: globalReducer(EDIT_NETWORK_ENTITLEMENT),
	getNetworkEntitlement: globalReducer(GET_NETWORK_ENTITLEMENT),
	getNetworkEntitlements: globalReducer(GET_NETWORK_ENTITLEMENTS),
	addNetworkEntitlementsModal: globalModalReducer(
		ADD_NETWORK_ENTITLEMENTS_MODAL
	),
	getParentNetworkEntitlements: globalReducer(GET_PARENT_NETWORK_ENTITLEMENTS),
	addSubAccountNetworkEntitlements: globalReducer(ADD_SUB_ACCOUNT_NETWORK_ENTITLEMENTS),
	showAssociatedApns: globalReducer(SHOW_ASSOCIATED_APNS)
});
