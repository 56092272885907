import { Status } from '../../../../utils/constants';

export const isBulkNotesUpdating = (state) =>
	state.Endpoints.updateBulkNotes.status === Status.PENDING;
export const bulkNotesUpdateFail = (state) =>
	state.Endpoints.updateBulkNotes.status === Status.FAIL;
export const bulkNotesUpdateSuccess = (state) =>
	state.Endpoints.updateBulkNotes.status === Status.DONE;
export const getCurrentEndpoint = (state) =>
	state.Endpoints.updateBulkNotes.currentEndpoint;
export const getActionError = (state) =>
	state.Endpoints.updateBulkNotes.actionError;
export const getFailEndpoints = (state) =>
	state.Endpoints.updateBulkNotes.failEndpoints;
export const getSuccessEndpoints = (state) =>
	state.Endpoints.updateBulkNotes.successEndpoints;
export const getIsStarted = (state) => state.Endpoints.updateBulkNotes.started;
export const getFilteredEndpoints = (state) =>
	state.Endpoints.updateBulkNotes.filteredEndpoints;
