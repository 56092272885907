import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { SuperUserTopBarMobile }
	from '../../../components/Shared/components/SuperUserTopBar';
import {
	MobileFooter,
} from '../../../lib/DigitalComponents/Footer';
import TopBar from '../../../lib/DigitalComponents/TopBar';
import CurrentContext from '../../../utils/currentContext';
import ImageSelector from '../../../utils/imageSelector';
import hoc from '../MainLayout';

import styles from './Mobile.scss';

const Logo = ImageSelector(CurrentContext.theme, 'svgs/logo.svg');
const UserPlaceholder = ImageSelector(CurrentContext.theme, 'svgs/user.svg');
const LinkIcon = ImageSelector(CurrentContext.theme, 'svgs/link.svg');

const Mobile = ({
	clientMenu,
	user,
	profileMenu,
	supportedLanguages,
	selectedLanguage,
	children,
	companyAccountId,
	companyFriendlyName,
	showContentAboveTopBar,
	intl,
	links,
	resetFilterPaginationSearchData,
	userInitials,
}) => (
	<div
		data-spec="mobile"
		className={showContentAboveTopBar ? styles.top_bar_wrapper : ''}
	>
		<TopBar
			mainMenu={clientMenu
				.filter((item) => !item.notOnMobile)
				.map((item) => {
					if (item.submenu) {
						item.submenu = item.submenu.filter(
							(subItem) => !subItem.notOnMobile
						);
					}

					return item;
				})}
			logo={
				<NavLink to="/">
					<Logo className={styles.logo} data-spec="homepage-logo" />
				</NavLink>
			}
			appName={<FormattedMessage id="APP_NAME" defaultMessage="IoT Console" />}
			userPlaceholder={
				<div className={styles.profile_avatar}>
					<UserPlaceholder />
				</div>
			}
			userName={userInitials}
			profileMenu={profileMenu}
			supportedLanguages={supportedLanguages}
			selectedLanguage={selectedLanguage}
			showContentAboveTopBar={showContentAboveTopBar}
			contentAboveTopBar={
				<SuperUserTopBarMobile
					companyFriendlyName={companyFriendlyName}
					companyAccountId={companyAccountId}
					user={user}
				/>
			}
			resetFilterPaginationSearchData={resetFilterPaginationSearchData}
		/>
		<div
			className={`${styles.global_container} ${
				showContentAboveTopBar ? styles.additional_offset : ''
			}`}
		>
			<div className={styles.main_right}>{children}</div>
		</div>
		<MobileFooter
			logo={<Logo />}
			externalLink={<LinkIcon className={styles.link_icon} />}
			links={links}
			copyrightLink={intl.formatMessage(
				{
					id: 'COPYRIGHT_ATT_INTELECTUAL_PROPERTY',
					defaultMessage: '© {year} AT&T Intellectual Property. ',
				},
				{
					year: new Date().getFullYear(),
				}
			)}
			copyrightMessage={intl.formatMessage({
				id: 'COPYRIGHT_MESSAGE',
				defaultMessage:
					'All rights reserved. AT&T, the AT&T, Globe logo, Mobilizing Your World and DIRECTV are registered trademarks of AT&T intellectual Property and/or AT&T affiliated companies. All other marks are the property of their respective owners.',
			})}
		/>
	</div>
);

const {
	object,
	arrayOf,
	string,
	array,
	element,
	oneOfType,
  bool,
  func
} = PropTypes;

Mobile.propTypes = {
	clientMenu: arrayOf(object),
	user: oneOfType([array, object, string]),
	profileMenu: arrayOf(object),
	supportedLanguages: arrayOf(object),
	selectedLanguage: object,
	children: element,
	companyAccountId: string,
	companyFriendlyName: string,
	showContentAboveTopBar: bool,
	intl: object,
	links: arrayOf(object),
	resetFilterPaginationSearchData: func,
	userInitials: string,
};

Mobile.defaultProps = {
	clientMenu: [],
	user: {},
	profileMenu: [],
	children: null,
	companyAccountId: '',
	companyFriendlyName: '',
	showContentAboveTopBar: false,
	intl: {},
	links: [],
};

export default hoc()(injectIntl(Mobile));
