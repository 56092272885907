import { createAction } from 'redux-actions';
import { cloneDeep } from 'lodash';
import {
	FILTER_MODAL,
	GET_FILTERS_INIT,
	GET_FILTERS_REQUEST,
	GET_FILTERS_FAIL,
	GET_FILTERS_SUCCESS
} from './constants';
import { filterDataInit, setFilterData } from './filterData/actions';
import {
	originalFilterInit,
	setOriginalFilter
} from './originalFilter/actions';
import { resetFilterInit, setResetFilter } from './resetFilter/actions';
import { setSearchParameterInit } from '../searchBar/actions';

import { setPaginationInit } from '../pagination/actions';

import { simpleSearchClose } from '../simpleSearch/actions';

import { createFilterData } from '../../../../utils/tableHelpers';

export const openFilterModal = createAction(`OPEN_${FILTER_MODAL}`);
export const closeFilterModal = createAction(`CLOSE_${FILTER_MODAL}`);

const filtersInit = createAction(GET_FILTERS_INIT);
const filtersRequest = createAction(GET_FILTERS_REQUEST);
const filtersFail = createAction(GET_FILTERS_FAIL);
const filtersSuccess = createAction(GET_FILTERS_SUCCESS);

/** ************ get filters ************* */
export const getFilters = (params) => async (dispatch) => {
	dispatch(filtersInit());
	try {
		dispatch(filtersRequest());
    const request = await params.service(params.params);

		let filterData = {};
		if (params.createFilterData) {
			if (params.objectsString) {
				filterData = params.createFilterData(
					request.data[params.objectsString]
				);
			} else if (request.data) {
				filterData = params.createFilterData(request.data);
			} else {
				// Endpoints fix
				filterData = params.createFilterData(request);
			}
		} else {
			filterData = createFilterData(request.data);
    }
		dispatch(setFilterData(cloneDeep(filterData)));
		dispatch(setOriginalFilter(cloneDeep(filterData)));
		if (params.hasSavedFilters) {
			let resetFilter = {};
			const filterDataKeys = Object.keys(filterData);
			filterDataKeys.forEach((item) => {
				resetFilter = {
					...resetFilter,
					[item]: filterData[item].map((filterItem) => ({
						value: filterItem.value,
						label: filterItem.label,
						checked: false
					}))
				};
			});
			dispatch(setResetFilter(resetFilter));
		} else {
			dispatch(setResetFilter(cloneDeep(filterData)));
		}
		dispatch(filtersSuccess(request));
	} catch (error) {
		dispatch(filtersFail(error));
	}
};

export const setDataForFiltersInit = () => async (dispatch) => {
	dispatch(filtersInit());
	dispatch(filterDataInit());
	dispatch(originalFilterInit());
	dispatch(resetFilterInit());
	dispatch(setPaginationInit());
	dispatch(setSearchParameterInit());
	dispatch(simpleSearchClose());
};
