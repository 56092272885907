import React from 'react';
import { object } from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import hoc from '../Module';

import styles from '../../DataOverview/DataOverview.scss';
import mobile from './Mobile.scss';

const NetworkTypeRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networktype_rw',
	'mnc.endpoints.networktype_ro'
]);

function Mobile({ endpoint, modules, rateplan, endpointData }) {
	return (
		<div
			data-spec="mobile-modules"
			className={`${styles.data_wrap} ${mobile.block_wrap}`}
		>
			<div className={`${styles.column} ${mobile.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="DEVICE_NAME"
							defaultMessage="Device Name"
						/>
					</span>
					<span>{endpointData.name || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="IMEI"
							defaultMessage="IMEI"
						/>
					</span>
					<span>{endpoint.deviceIMEI || '-'}</span>
				</div>
				<NetworkTypeRWComponent>
					<div className={styles.data}>
						<span>
							<FormattedMessage
								id="NETWORK_TYPE"
								defaultMessage="Network Type"
							/>
						</span>
						<span>{endpoint.networkType || '-'}</span>
					</div>
				</NetworkTypeRWComponent>
				<div className={styles.data}>
					{modules.type === 'Satellite-Narrowband' ? (
						<span>
							<FormattedMessage
								id="MODULE_STATUS"
								defaultMessage="Module Status"
							/>
						</span>
					) : (
						<span>
							<FormattedMessage
								id="SIM_STATUS"
								defaultMessage="SIM status"
							/>
						</span>
					)}
					<div
						className={cn(
							styles.status_col,
							styles[
							endpoint.status &&
							endpoint.status
								.toLowerCase()
								.replace(' ', '_')
								.replace('-', '_')
								],
							mobile.status_col_res
						)}
					>
						{endpoint.status}
					</div>
				</div>
			</div>
			<div className={`${styles.column} ${mobile.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE"
							defaultMessage="Threshold of Notification by Data Usage"
						/>
					</span>
					<span>
						{modules.notificationByteCap != null
							? modules.notificationByteCap
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE"
							defaultMessage="Threshold of Suspension by Data Usage"
						/>
					</span>
					<span>
						{modules.suspensionByteCap != null
							? modules.suspensionByteCap
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NOTIFICATION_INTERVAL"
							defaultMessage="Notification Interval"
						/>
					</span>
					<span>
						{modules.notificationRefresh != null
							? modules.notificationRefresh
							: '-'}
					</span>
				</div>
			</div>
			<div className={`${styles.column} ${mobile.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="DATA_USAGE"
							defaultMessage="Cycle to Date Data Usage"
						/>
					</span>
					<span>{modules.byteUsage != null ? modules.byteUsage : '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="ICCID"
							defaultMessage="ICCID"
						/>
					</span>
					<span>{endpoint.iccid || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="MSISDN"
							defaultMessage="MSISDN"
						/>
					</span>
					<span>{endpoint.msisdn || '-'}</span>
				</div>
			</div>
			<div className={`${styles.column} ${mobile.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="IMSI"
							defaultMessage="IMSI"
						/>
					</span>
					<span>{endpoint.imsi || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="RATE_PLAN_NAME"
							defaultMessage="Rate Plan Name"
						/>
					</span>
					<span>{rateplan.name || '-'}</span>
				</div>
			</div>
		</div>
	);
}

Mobile.propTypes = {
	endpoint: object,
	modules: object,
	rateplan: object,
	endpointData: object
};

export default hoc(Mobile);
