import React from 'react';
import { number, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import hoc from '../SatelliteRatePlan';

import styles from './Mobile.scss';

function Mobile({ endpoint, renderActivationFee }) {
	return (
		<div
			data-spec="mobile-modules"
			className={`${styles.data_wrap} ${styles.block_wrap}`}
		>
			<div className={`${styles.column} ${styles.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage id="RATE_PLAN" defaultMessage="Rate Plan" />
					</span>
					<span>{endpoint.name || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage id="DESCRIPTION" defaultMessage="Description" />
					</span>
					<span>{endpoint.description || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="RENEWAL_PERIOD"
							defaultMessage="Renewal Period"
						/>
					</span>
					<span>{endpoint.renewalPeriod || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage id="CATEGORY" defaultMessage="Category" />
					</span>
					<span>{endpoint.category || '-'}</span>
				</div>
			</div>
			<div className={`${styles.column} ${styles.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="ACTIVATION_FEE"
							defaultMessage="Activation Fee"
						/>
					</span>
					<span>
						{endpoint.activationFee != null
							? renderActivationFee
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.RECURRING_FEE"
							defaultMessage="Recurring Fee"
						/>
					</span>
					<span>
						{endpoint.recurringFee != null ? endpoint.recurringFee : '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="OVERAGE_FEE"
							defaultMessage="Overage Fee"
						/>
					</span>
					<span>
						{endpoint.overageFee != null ? endpoint.overageFee : '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="INCLUDED_BYTES"
							defaultMessage="Included Bytes"
						/>
					</span>
					<span>
						{endpoint.includedBytes != null
							? endpoint.includedBytes
							: '-'}
					</span>
				</div>
			</div>
			<div className={`${styles.column} ${styles.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="MINIMUM_BILLABLE_BYTES"
							defaultMessage="Minimum Billable Bytes"
						/>
					</span>
					<span>
						{endpoint.minimumBillableBytes != null
							? endpoint.minimumBillableBytes
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NETWORK_NAME"
							defaultMessage="Network Name"
						/>
					</span>
					<span>{endpoint.networkName}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NOTIFICATION_CONTACT"
							defaultMessage="Notification Contact"
						/>
					</span>
					<span>{endpoint.notificationContact}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NOTIFICATION_BYTE_CAP"
							defaultMessage="Notification Byte Cap"
						/>
					</span>
					<span>
						{endpoint.notificationByteCap != null
							? endpoint.notificationByteCap
							: '-'}
					</span>
				</div>
			</div>
			<div className={`${styles.column} ${styles.column_block}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NOTIFICATION_REFRESH"
							defaultMessage="Notification Refresh"
						/>
					</span>
					<span>
						{endpoint.notificationRefresh != null
							? endpoint.notificationRefresh
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="OBSOLETE"
							defaultMessage="Obsolete"
						/>
					</span>
					<span>{endpoint.obsolete || '-'}</span>
				</div>
			</div>
		</div>
	);
}

Mobile.propTypes = {
	endpoint: object,
	renderActivationFee: number,
};

export default hoc(Mobile);
