import React from 'react';
import { object, func, string, number, array, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
	Field,
} from 'redux-form';
import cn from 'classnames';

import Button from '../../../../../lib/DigitalComponents/Button';
import Input from '../../../../../lib/DigitalComponents/FieldGroup';
import Table from '../../../../../lib/DigitalComponents/Table';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { noWhiteSpaceOnly } from '../../../../../utils/validators';
import hoc from '../SMSTab';

import styles from './Desktop.scss';

const SMSTabRWComponent = UserAllowedToAccess(['mnc.endpoints.smstab_rw']);

function Desktop({
	maxCharacters,
	sendSms,
	reset,
	messages,
	messageHistoryData,
	options,
	message,
	invalid,
}) {
	return (
		<div data-spec="sms-tab-component">
			<SMSTabRWComponent>
				<div>
					<div className={styles.header}>
						<FormattedMessage
							id="SEND_SMS_TO_THIS_DEVICE"
							defaultMessage="Send an SMS to this device."
						/>
					</div>
					<div className={styles.field_body}>
						<div
							className={cn({
								[styles.field_button]: true,
								[styles.invalid]: invalid
							})}
						>
							<FormattedMessage
								id="TYPE_A_MESSAGE"
								defaultMessage="Type a message you wish to send to this device using short message service. Standard rates may apply."
							>
								{(formattedValue) => (
									<Field
										componentClass="textarea"
										component={Input}
										name="message"
										placeholder={formattedValue}
										dataSpec="sms-message"
										className={styles.field}
										normalize={(value) => (
											value.length > maxCharacters
												? value.substring(0, maxCharacters) : value
										)}
										validate={[noWhiteSpaceOnly]}
									/>
								)}
							</FormattedMessage>
							<Button
								dataSpec="sms-button"
								variant="outline-primary"
								onClick={() => {
									sendSms();
									reset();
								}}
								label={<FormattedMessage id="SEND" defaultMessage="Send" />}
								disabled={message.length === 0 || invalid}
							/>
						</div>
						<span className={styles.remaining}>
							<FormattedMessage
								id="CHARACTERS_REMAINING"
								defaultMessage="{remaining} characters remaining..."
								values={{
									remaining: message
										? maxCharacters - message.length
										: maxCharacters,
								}}
							/>
						</span>
					</div>
				</div>
			</SMSTabRWComponent>
			<div className={styles.table}>
				<div className={styles.table_header}>
					<span>
						<FormattedMessage
							id="SENT_MESSAGES_HISTORY"
							defaultMessage="Sent messages history"
						/>
					</span>
					<span>
						<FormattedMessage
							id="MESSAGES_WILL_APPEAR"
							defaultMessage="Messages will appear as sent and will appear in SMS Usage reports. Rates may apply."
						/>
					</span>
				</div>
				<Table
					data-spec="sms-history-table"
					data={messageHistoryData}
					options={options()}
					messages={messages}
				/>
			</div>
		</div>
	);
}

Desktop.propTypes = {
	messages: object,
	messageHistoryData: array,
	reset: func,
	maxCharacters: number,
	options: func,
	sendSms: func,
	message: string,
	invalid: bool,
};

Desktop.defaultProps = {
	messages: {},
	messageHistoryData: [],
	reset: undefined,
	maxCharacters: 0,
	options: undefined,
	sendSms: undefined,
	message: '',
	invalid: false,
};

export default hoc(Desktop);
