export default {
	'SUPPORT.USER_GUIDE': 'Guia do usuário',
	'SUPPORT.PRINT_PDF': 'Imprimir PDF',
	'SUPPORT.SUPPORT': 'Suporte',
	'SUPPORT.FAQ': 'Perguntas frequentes',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Encontre respostas rápidas para as perguntas frequentes de seus usuários.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Leia instruções detalhadas para as funções do portal. O guia é personalizado para o seu tipo de conta.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Índice',
	'SUPPORT.TICKETING': 'Emissão de tíquetes',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'Caso ocorram problemas de interrupção do sistema ou de serviço com seus endpoints e os testes de diagnósticos não solucionarem o problema, você pode criar um tíquete do problema com nossa equipe de operações. Você pode também fazer perguntas relacionadas a cobranças e serviços.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Notificações recentes',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Criar nova notificação',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'Visualizar todas as notificações',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Perguntas frequentes',
	'SUPPORT.TOPICS': 'Tópicos',
	'SUPPORT.EDIT_NOTIFICATION': 'Revisar notificação',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Notificação global',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': 'Notificação relacionada à empresa',
	'SUPPORT.NOTIFICATION_TYPE': 'Tipo de notificação?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER':
		'Selecionar espaço reservado para notificação',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Selecionar grupos de usuários a serem notificados:',
	'SUPPORT.CONTENT': 'Conteúdo',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Adicionar conteúdo da notificação',
	'SUPPORT.PUBLISH': 'Publicar',
	'SUPPORT.GLOBAL': 'Global',
	'SUPPORT.COMPANY_RELATED': 'Relacionado à empresa',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Confirmação',
	'SUPPORT.NOTIFICATION_INFO': 'Informação',
	'SUPPORT.NOTIFICATION_WARNING': 'Aviso',
	'SUPPORT.NOTIFICATION_ERROR': 'Erro',
	'SUPPORT.SELECT_SECTION': 'Selecionar seção',
	'SUPPORT.ALL': 'Todos',
	'SUPPORT.ADMIN': 'Admin',
	'SUPPORT.USER': 'Usuário',
	'SUPPORT.FINANCE': 'Finanças',
	'SUPPORT.OPERATIONS': 'Operações',
	'SUPPORT.RESTRICTEDACCESS': 'Acesso restrito',
	'SUPPORT.COMPANYVIEW': 'Exibição da empresa',
	'SUPPORT.COMPANYVIEWREADONLY': 'Somente leitura de exibição da empresa',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Adicionar novas notas do lançamento',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'O documento abaixo não está totalmente traduzido para o seu idioma. A versão em inglês é a mostrada. A tradução para o seu idioma estará disponível nos próximos lançamentos do produto.'
};
