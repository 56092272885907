import { createAction } from 'redux-actions';

import {
	downloadInvoiceFile,
	invoicePollForFile
} from '../../services/AnalyticsInvoicesService';

import {
	GET_INVOICE_ATTACHMENT_INIT,
	GET_INVOICE_ATTACHMENT_REQUEST,
	GET_INVOICE_ATTACHMENT_FAIL,
	GET_INVOICE_ATTACHMENT_SUCCESS
} from './constants';

export const downloadInvoiceAttachmentInit = createAction(
	GET_INVOICE_ATTACHMENT_INIT
);

const downloadInvoiceAttachmentRequest = createAction(
	GET_INVOICE_ATTACHMENT_REQUEST
);

const downloadInvoiceAttachmentFail = createAction(GET_INVOICE_ATTACHMENT_FAIL);

const downloadInvoiceAttachmentSuccess = createAction(
	GET_INVOICE_ATTACHMENT_SUCCESS
);

// If you want to create new file to download, like csvDownloadHelper used to do,
// send it as fileStream and in case it is a url send it as urlPath. If you do not
// send mimeType and you want to create a file, it will be csv file
export const downloadHelper = (urlPath, fileName, fileStream, mimeType) => {
	let url;
	const Mime = mimeType || 'application/octet-stream';
	if (fileStream) {
		url = window.URL.createObjectURL(new Blob([fileStream], {
			type: Mime
		}));
	} else url = urlPath;
	const tempLink = document.createElement('a');
	tempLink.href = url;
	tempLink.setAttribute('download', fileName);
	tempLink.click();
};

export const invoiceAttachmentDownload = (data) => async (dispatch) => {
	dispatch(downloadInvoiceAttachmentInit());
	try {
    const downloadURL = '';
    dispatch(downloadInvoiceAttachmentRequest());
    const response = await downloadInvoiceFile(data);
    const pollResponse = await invoicePollForFile(response, 100, 4000);
		dispatch(downloadInvoiceAttachmentSuccess());
		return downloadHelper(pollResponse.downloadUrl, downloadURL);
	} catch (error) {
		dispatch(downloadInvoiceAttachmentFail(error));
	}
};
