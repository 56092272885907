export default {
	'RELEASE_NOTES.RELEASE_NOTES': 'Versionshinweise',
	'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'IoT Console wird häufig aktualisiert.',
	'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': 'IoT Console wird häufig aktualisiert, um einen optimalen Betrieb zu gewährleisten und erweiterte Funktionen sowie Optimierungen der Funktionen und des Benutzererlebnisses zu ermöglichen. Weiter unten sehen Sie die in dieser Version eingeführten Aktualisierungen.',
	'RELEASE_NOTES.RELEASE_VERSION': 'Version',
	'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'Neue Plattformfunktionen',
	'RELEASE_NOTES.DEPRECATIONS': 'Abschreibungen',
	'RELEASE_NOTES.DEPRECATION': 'Abschreibung',
	'RELEASE_NOTES.KNOWN_ISSUES': 'Bekannte Probleme',
	'RELEASE_NOTES.NEW_FEATURES': 'Neue Funktionen',
	'RELEASE_NOTES.IMPROVEMENTS': 'Optimierungen',
	'RELEASE_NOTES.FEATURE': 'Funktion',
	'RELEASE_NOTES.IMPROVEMENT': 'Optimierung',
	'RELEASE_NOTES.ISSUE': 'Problem',

	'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Weitere Abschreibung hinzufügen',
	'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Weitere Plattformfunktion hinzufügen',
	'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Weitere Funktion hinzufügen',
	'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Weiteres bekanntes Problem hinzufügen',
	'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Weitere Optimierung hinzufügen',

	'RELEASE_NOTES.PUBLISH': 'Veröffentlichen',
	'RELEASE_NOTES.SAVE': 'Speichern',
	'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Versionshinweise veröffentlichen',
	'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': 'Sie sind kurz davor, die Versionshinweise {releaseVersion} zu veröffentlichen. Wirklich fortfahren?',
	'RELEASE_NOTES.CANCEL': 'Abbrechen'
};
