export default {
	'RELEASE_NOTES.RELEASE_NOTES': 'Notes de version',
	'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'IoT Console est fréquemment mise à jour.',
	'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': 'IoT Console est fréquemment mise à jour pour assurer un fonctionnement optimal et offrir des caractéristiques et des fonctionnalités étendues ainsi que des améliorations de l’expérience utilisateur. Les mises à jour introduites dans cette version figurent ci-dessous.',
	'RELEASE_NOTES.RELEASE_VERSION': 'Version',
	'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'Nouvelles fonctionnalités de plateforme',
	'RELEASE_NOTES.DEPRECATIONS': 'Dépréciations',
	'RELEASE_NOTES.DEPRECATION': 'Dépréciation',
	'RELEASE_NOTES.KNOWN_ISSUES': 'Problèmes connus',
	'RELEASE_NOTES.NEW_FEATURES': 'Nouvelles fonctionnalités',
	'RELEASE_NOTES.IMPROVEMENTS': 'Améliorations',
	'RELEASE_NOTES.FEATURE': 'Fonctionnalité',
	'RELEASE_NOTES.IMPROVEMENT': 'Amélioration',
	'RELEASE_NOTES.ISSUE': 'Problème',

	'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Ajouter une autre dépréciation',
	'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Ajouter une autre fonctionnalité de plateforme',
	'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Ajouter une autre fonctionnalité',
	'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Ajouter un autre problème connu',
	'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Ajouter une autre amélioration',

	'RELEASE_NOTES.PUBLISH': 'Publier',
	'RELEASE_NOTES.SAVE': 'Enregistrer',
	'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Publier les notes de version',
	'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': 'Vous êtes sur le point de publier les notes de version {releaseVersion}. Voulez-vous vraiment continuer ?',
	'RELEASE_NOTES.CANCEL': 'Annuler'
};
