import React from 'react';
import PropTypes from 'prop-types';
import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

import hoc from '../PageTitle';

import styles from './Mobile.scss';

const Left = ImageSelector(CurrentContext.theme, 'svgs/left.svg');

const Mobile = (props) => {
	const {
		pushBack,
		title,
		renderActions,
		actions,
		renderNotifications,
		showNotifications
	} = props;
	return (
		<div data-spec="page-title-mobile">
			<div className={styles.page_title}>
				<div className={styles.title_container}>
					{pushBack && (
						<Left
							onClick={() => pushBack()}
							className={styles.push_back}
							data-spec="push-back"
						/>
					)}
					<h1 className={styles.title}>{title}</h1>
				</div>
				{renderActions && (
					<div className={styles.actions_container}>{actions}</div>
				)}
			</div>
			<div>{showNotifications && renderNotifications()}</div>
		</div>
	);
};

const { func, bool, shape, oneOfType, string } = PropTypes;

Mobile.propTypes = {
	pushBack: func,
	title: oneOfType([shape(), string]),
	actions: shape(),
	renderNotifications: func,
	renderActions: bool,
	showNotifications: bool
};

Mobile.defaultProps = {
	renderActions: false,
	showNotifications: true
};

export default hoc()(Mobile);
