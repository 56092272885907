import React from 'react';
import PropTypes from 'prop-types';
import Stickyfill from 'stickyfilljs';
import { FormattedMessage, injectIntl } from 'react-intl';

import Select, { components } from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Loader from '../../../../../lib/DigitalComponents/Loader';

import styles from './Mobile.scss';

class Mobile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null
		};
	}

	componentDidMount() {
		this.showNotification();
	}

	componentDidUpdate(prevProps) {
		const { userGuideAvailable } = this.props;
		if (!prevProps.userGuideAvailable && userGuideAvailable) {
			Stickyfill.add(this.userGuideNavRef);
		}
	}

	Option = (optionProps) => {
		const { children, data } = optionProps;
		return (
			<components.Option
				data-spec="option"
				{...optionProps}
				className={data.indent ? styles.indent : ''}
			>
				<span>{children}</span>
			</components.Option>
		);
	};

	showNotification = () => {
		const { intl, showNotification } = this.props;
		const message = intl.formatMessage({
			id: 'SUPPORT.TRANSLATION_NOT_AVAILABLE',
			defaultMessage:
				'Document below is shown in U.S. English. Translation into other supported languages will be available in upcoming product releases.'
		});
		showNotification(message);
	};

	getNodes = (str) =>
		new DOMParser().parseFromString(str, 'text/html').body.childNodes;

	render() {
		const { userGuideAvailable, contentId, fileMarkdown } = this.props;
		const { selected } = this.state;
		return (
			<div data-spec="user-guide-desktop" className={styles.wrapper}>
				{
					<div
						className={styles.user_guide_menu}
						ref={(menu) => {
							this.userGuideNavRef = menu;
						}}
					>
						{userGuideAvailable &&
							fileMarkdown &&
							this.userGuideRef &&
							(() => {
								const nodes = this.getNodes(fileMarkdown);
								const titles = Object.keys(nodes)
									.filter(
										(key) =>
											nodes[key].id &&
											(nodes[key].tagName === 'H2' ||
												nodes[key].tagName === 'H1')
									)
									.reduce((obj, key) => {
										obj[key] = nodes[key];
										return obj;
									}, {});

								const options = Object.keys(titles).map((key) => ({
									value: titles[key].id,
									label: titles[key].childNodes.item(0).data,
									indent: titles[key].tagName === 'H2'
								}));
								return (
									<Select
										data-spec="select"
										name="sim-state"
										options={options}
										onChange={(selectedItem) => {
											this.setState({
												selected: selectedItem
											});
											const el = document.getElementById(selectedItem.value);
											window.scrollBy({
												top: el.getBoundingClientRect().top - 100,
												left: 0
											});
										}}
										value={selected}
										placeholder={
											<FormattedMessage
												id="SUPPORT.SELECT_SECTION"
												defaultMessage="Select Section"
											/>
										}
										components={{ Option: this.Option }}
									/>
								);
							})()}
					</div>
				}
				<div
					className={styles.main_content}
					ref={(userGuide) => {
						this.userGuideRef = userGuide;
					}}
					id={contentId}
					dangerouslySetInnerHTML={{ __html: fileMarkdown }}
				/>
				{!fileMarkdown && <Loader />}
			</div>
		);
	}
}

const { string, bool, oneOfType, object, func } = PropTypes;

Mobile.propTypes = {
	userGuideAvailable: bool,
	contentId: string,
	fileMarkdown: oneOfType([object, string]),
  showNotification: func,
  intl: object
};

export default injectIntl(Mobile);
