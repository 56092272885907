import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { cloneDeep } from 'lodash';
import { subDays, differenceInDays, parseISO } from 'date-fns';
import html2canvas from 'html2canvas';
import download from 'downloadjs';

import Button from '../../../../lib/DigitalComponents/Button';
import GridLayout from '../../../Shared/components/GridLayout/GridLayout';
import { UsageTrend } from '../../../Shared/components/Charts';
import AnalyticsModalForm from '../AnalyticsModal';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import { UserAllowedToAccess } from '../../../../utils/AuthSelector';
import {
	checkEmptyPlace,
	isCycle,
	isHistoricalCycle
} from '../../../Shared/components/Charts/Helpers/helpers';
import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import styles from './Analytics.scss';

const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');
const AnalyticsRWComponent = UserAllowedToAccess([
	'mnc.analytics_rw'
]);
const settingsKeyCheck = [
	'w',
	'h',
	'x',
	'y',
	'i',
	'maxW',
	'maxH',
	'moved',
	'static',
	'type',
	'groupByColumn',
	'groupByColumnId',
	'groupByDate',
	'groupByDateId',
	'filters',
	'incrementBy',
	'incrementById',
	'aggregationFunction',
	'aggregationFunctionId',
	'categoryId',
	'editFilters',
	'name',
	'units'
];
// Analytics
class Analytics extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			openAddChartModal: false,
			settingsState: props.settings[0]
				? JSON.parse(props.settings[0].value)
				: { lg: [], md: [], sm: [] },
			editItem: {},
			checked: false
		};
	}

	componentDidMount() {
		const { settings, createUserSettings, resetUserSettings } = this.props;
		const { settingsState } = this.state;

		this.initialCalls(
			settings,
			createUserSettings,
			resetUserSettings,
			settingsState
		);
	}

	componentDidUpdate() {
		const { dataGridSize, settings } = this.props;
		const { checked } = this.state;

		if (settings[0]) {
			const parsedSettings = JSON.parse(settings[0].value);
			let needToUpdate = false;

			if (
				parsedSettings[dataGridSize] &&
				parsedSettings[dataGridSize].length &&
				!checked
			) {
				const tempSettings = cloneDeep(parsedSettings);

				parsedSettings[dataGridSize].forEach((setting) => {
					if (setting.groupByDateId === 3) {
						settingsKeyCheck.push('dateFrom', 'dateTo');
					}

					settingsKeyCheck.forEach((keyCheck) => {
						if (Object.keys(setting).indexOf(keyCheck) === -1) {
							Object.keys(tempSettings).forEach((key) => {
								tempSettings[key] = tempSettings[key].filter(
									(temp) => setting.i !== temp.i
								);
							});

							if (!needToUpdate) {
								needToUpdate = true;
							}
						}
					});
				});

				if (needToUpdate) {
					this.updateSettings(tempSettings);
					this.updateStateSettings(tempSettings);
				}
			}
		}
	}

	initialCalls = (
		settings,
		createUserSettings,
		resetUserSettings,
		settingsState
	) => {
		if (settings.length === 0) {
			createUserSettings({
				refTypeId: 5,
				name: 'AnalyticsSettings',
				value: JSON.stringify(settingsState)
			});
		} else if (
			settings.length > 1 ||
			settings[0].name !== 'AnalyticsSettings' ||
			(!JSON.parse(settings[0].value).lg &&
				!JSON.parse(settings[0].value).md &&
				!JSON.parse(settings[0].value).sm)
		) {
			resetUserSettings();
			createUserSettings({
				refTypeId: 5,
				name: 'AnalyticsSettings',
				value: JSON.stringify(settingsState)
			});
		}
	};

	updateStateSettings = (tempSettings) =>
		this.setState({
			settingsState: tempSettings,
			checked: true
		});

	openModal = (editItem = {}) =>
		this.setState({
			editItem,
			openAddChartModal: true
		});

	closeModal = () => this.setState({ openAddChartModal: false, editItem: {} });

	updateSettings = (settingsToBeSaved) => {
		const { updateUserSettings, settings } = this.props;
		const tempSettingsToSave = cloneDeep(settingsToBeSaved);

		Object.keys(tempSettingsToSave).forEach((key) => {
			tempSettingsToSave[key].forEach((setting, index) => {
				if (setting.groupByDate !== '3') {
					const tempSetting = cloneDeep(setting);

					delete tempSetting.dateFrom;
					delete tempSetting.dateTo;

					tempSettingsToSave[key][index] = tempSetting;
				}
			});
		});

		updateUserSettings(
			{
				value: JSON.stringify(tempSettingsToSave)
			},
			settings[0].id
		);
		this.setState({ settingsState: tempSettingsToSave });
	};

	createAnalyticsParams = (gridItem) => {
		const searchParams = [];
		const additionalParams = !isHistoricalCycle(gridItem.groupByDateId)
			? {
				columnsToSelect: `${gridItem.groupByColumn},${gridItem.incrementBy}`,
				dataSort: gridItem.incrementBy
					.split(',')
					.map((item) => `${item} asc`)
					.join(',')
			  }
			: {
				columnsToSelect: `${gridItem.groupByColumn},billcycle`,
				dataSort: 'billcycle asc'
			  };
		const aggregationParams = gridItem.aggregationFunction !== 'MVA'
			? [
				{
					function: `${gridItem.aggregationFunction}`,
					column: 'value',
					as: 'value'
				}
			]
			: [
				{
					function: 'SUM',
					column: 'value',
					as: 'value'
				},
				{
					function: 'SUM',
					column: 'value_2',
					as: 'value_2'
				}
			];
		const aggregationParamsUsageTrend = [
			{ function: 'SUM', column: 'value', as: 'total_value' },
			{ function: 'AVG', column: 'value', as: 'average_value' }
		];
		const aggregationParamsDX = [
			{
				function: 'SUM',
				column: 'value',
				as: 'sum'
			}
		];
		const date = new Date();
		const currentDate = new Date(
			Date.UTC(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				date.getHours(),
				date.getMinutes(),
				date.getSeconds(),
				date.getMilliseconds()
			)
		);
		const dateRange = [
			subDays(parseISO(currentDate.toISOString()), 10),
			subDays(parseISO(currentDate.toISOString()), 30)
		];
		let dateFrom = '';
		let dateTo = '';
		let additionalParamsDX = {};

		if (gridItem.filters) {
			Object.keys(gridItem.filters).forEach((column) => {
				const tempPropValueIn = [];

				Object.keys(gridItem.filters[column]).forEach((filter) => {
					if (gridItem.filters[column][filter]) {
						tempPropValueIn.push(
							typeof filter === 'boolean'
								? gridItem.filters[column][filter]
								: filter
						);
					}
				});

				if (
					tempPropValueIn.length !==
					Object.keys(gridItem.filters[column]).length
				) {
					searchParams.push({
						propValue: tempPropValueIn,
						prop: column,
						operator: 'in'
					});
				}
			});
		}

		if (gridItem.groupByDateId === '0') {
			dateFrom = new Date(
				Date.UTC(
					date.getFullYear(),
					date.getMonth(),
					date.getDate(),
					0,
					0,
					0,
					0
				)
			).toISOString();
			dateTo = new Date(
				Date.UTC(
					date.getFullYear(),
					date.getMonth(),
					date.getDate(),
					23,
					59,
					59,
					999
				)
			).toISOString();
		} else if (gridItem.groupByDateId === '6') {
			dateFrom = new Date(
				Date.UTC(
					date.getFullYear(),
					date.getMonth(),
					date.getDate() - 1,
					0,
					0,
					0,
					0
				)
			).toISOString();
			dateTo = new Date(
				Date.UTC(
					date.getFullYear(),
					date.getMonth(),
					date.getDate() - 1,
					23,
					59,
					59,
					999
				)
			).toISOString();
		} else if (
			gridItem.groupByDateId === '1' ||
			gridItem.groupByDateId === '2'
		) {
			dateFrom = dateRange[gridItem.groupByDateId - 1].toISOString();
			dateTo = currentDate.toISOString();
		} else if (gridItem.groupByDateId === '3') {
			const itemFrom = new Date(gridItem.dateFrom);
			const itemTo = new Date(gridItem.dateTo);
			dateFrom = new Date(
				Date.UTC(
					itemFrom.getFullYear(),
					itemFrom.getMonth(),
					itemFrom.getDate(),
					0,
					0,
					0,
					0
				)
			).toISOString();
			dateTo = new Date(
				Date.UTC(
					itemTo.getFullYear(),
					itemTo.getMonth(),
					itemTo.getDate(),
					23,
					59,
					59,
					999
				)
			).toISOString();
		}

		searchParams.push({
			propValue: gridItem.categoryId,
			prop: 'categoryid',
			operator: '='
		});

		const searchParamsDX = cloneDeep(searchParams);

		if (!isCycle(gridItem.groupByDateId)) {
			searchParams.push({
				propValue: dateFrom,
				prop: 'full_date',
				operator: '>='
			});
			searchParams.push({
				propValue: dateTo,
				prop: 'full_date',
				operator: '<='
			});
			additionalParamsDX = {
				dateRanges: [
					{
						startDate: subDays(
							parseISO(dateFrom),
							differenceInDays(parseISO(dateTo), parseISO(dateFrom))
						).toISOString(),
						endDate: subDays(
							parseISO(dateTo),
							differenceInDays(parseISO(dateTo), parseISO(dateFrom))
						).toISOString()
					},
					{
						startDate: dateFrom,
						endDate: dateTo
					}
				]
			};
		} else if (gridItem.groupByDateId === '4') {
			additionalParams.currentCycle = true;
			searchParams.push({
				propValue: [Object.keys(gridItem.filters.platform)[0]],
				prop: 'platform',
				operator: 'in'
			});
			additionalParamsDX = { adxByCycle: true };
		} else if (isHistoricalCycle(gridItem.groupByDateId)) {
			searchParams.push({
				propValue: [Object.keys(gridItem.filters.platform)[0]],
				prop: 'platform',
				operator: 'in'
			});
		}

		return {
			additionalParams,
			additionalParamsDX,
			searchParams,
			searchParamsDX,
			aggregationParams,
			aggregationParamsUsageTrend,
			aggregationParamsDX
		};
	};

	getAnalyticsChartData = (gridItem) => {
		const { getChartData, getDirectionalIndex } = this.props;
		const params = this.createAnalyticsParams(gridItem);

		getChartData(gridItem.i, {
			additionalParams: params.additionalParams,
			aggregationParams: params.aggregationParams,
			searchParams: params.searchParams
		});

		if (gridItem.showSummary) {
			getChartData(`${gridItem.i}_usage_trend`, {
				aggregationParams: params.aggregationParamsUsageTrend,
				searchParams: params.searchParams
			});
		}

		if (!isHistoricalCycle(gridItem.groupByDateId)) {
			getDirectionalIndex(gridItem.i, {
				additionalParams: params.additionalParamsDX,
				aggregationParams: params.aggregationParamsDX,
				searchParams: params.searchParamsDX
			});
		}
	};

	addChart = (settingObject) => {
		const { dataGridSize } = this.props;
		const { settingsState } = this.state;
		const emptyCoordinates = checkEmptyPlace(dataGridSize, settingsState);
		const uuid = new Date().toISOString();
		const itemSettings = {
			i: uuid,
			h: 1,
			maxH: 1,
			...settingObject
		};

		this.setState({
			settingsState: {
				lg: [
					...settingsState.lg,
					{
						x: emptyCoordinates.lg.x,
						y: emptyCoordinates.lg.y,
						w: 3,
						maxW: 3,
						...itemSettings
					}
				],
				md: [
					...settingsState.md,
					{
						x: emptyCoordinates.md.x,
						y: emptyCoordinates.md.y,
						w: 2,
						maxW: 2,
						...itemSettings
					}
				],
				sm: [
					...settingsState.sm,
					{
						x: emptyCoordinates.sm.x,
						y: emptyCoordinates.sm.y,
						w: 1,
						maxW: 1,
						...itemSettings
					}
				]
			}
		});
	};

	removeChart = (itemIndex) => {
		const { settingsState } = this.state;

		this.setState({
			settingsState: {
				lg: settingsState.lg.filter((setting) => setting.i !== itemIndex),
				md: settingsState.md.filter((setting) => setting.i !== itemIndex),
				sm: settingsState.sm.filter((setting) => setting.i !== itemIndex)
			}
		});
	};

	exportChartCSV = (gridItem, exportChartDataCSV) => {
		const params = this.createAnalyticsParams(gridItem);

		exportChartDataCSV(gridItem.i, gridItem.name, {
			additionalParams: params.additionalParams,
			aggregationParams: params.aggregationParams,
			searchParams: params.searchParams
		});
	};

	renderDropdownItem = (gridItem, objectToCanvas, exportChartDataCSV) => (
		<div
			data-spec={`analytics-dropdown-item-${gridItem.i}`}
			className={styles.dropmenu}
		>
			<div
				data-spec={`customize-graph-${gridItem.i}`}
				onClick={() => this.openModal(gridItem)}
				className={styles.menuBottom}
			>
				<FormattedMessage
					id="ANALYTICS.CUSTOMIZE_CHART_DATA"
					defaultMessage="Customize Graph Data"
				/>
			</div>
			<div
				data-spec={`export-graph-${gridItem.i}`}
				onClick={() => this.exportChartCSV(gridItem, exportChartDataCSV)}
				onKeyUp={undefined}
				className={styles.menuBottom}
			>
				<FormattedMessage
					id="ANALYTICS.EXPORT_CHART_DATA"
					defaultMessage="Export Graph Data"
				/>
			</div>
			<div
				data-spec={`download-graph-${gridItem.i}`}
				onClick={() =>
					html2canvas(objectToCanvas, {
						logging: false,
						scrollY: -window.scrollY
					}).then((canvas) => {
						download(canvas.toDataURL(), `${gridItem.name}.png`, 'image/png');
					})}
				onKeyUp={undefined}
				className={styles.menuBottom}
			>
				<FormattedMessage
					id="ANALYTICS.DOWNLOAD_CHART_AS_PNG"
					defaultMessage="Download PNG"
				/>
			</div>
			<div
				data-spec={`delete-graph-${gridItem.i}`}
				onClick={() => this.removeChart(gridItem.i)}
				onKeyUp={undefined}
				className={styles.menuBottom}
			>
				<FormattedMessage
					id="ANALYTICS.REMOVE_CHART"
					defaultMessage="Remove Graph"
				/>
			</div>
		</div>
	);

	renderUsageTrend = (itemSetting, chartData, directionIndex) => (
		itemSetting.aggregationFunction !== 'MVA' && itemSetting.showSummary
			? (
				<UsageTrend
					data-spec="analytics-usage-trend-chart"
					data={chartData(`${itemSetting.i}_usage_trend`)}
					isWide={itemSetting.w > 1}
					directionIndex={directionIndex(itemSetting.i)}
					isHistoricalCycle={isHistoricalCycle(itemSetting.groupByDateId)}
					isPie={itemSetting.type === 'pie'}
					unit={itemSetting.units}
				/>
			)
			: null
	);

	render() {
		const {
			dataGridSize,
			chartData,
			isGetChartDataPending,
			directionIndex,
			isGetDirectionIndexPending,
			settings,
			isExportingChartDataCsv,
			exportChartDataCSV
		} = this.props;
		const { settingsState, openAddChartModal, editItem } = this.state;

		if (!settings[0]) {
			return <Loading data-spec="analytics-loader" />;
		}

		return (
			<div data-spec="analytics-charts">
				<PageTitle
					title={<FormattedMessage id="ANALYTICS" defaultMessage="Analytics" />}
					actions={
						<AnalyticsRWComponent>
							<Button
								label={
									<FormattedMessage
										id="ANALYTICS.ADD_WIDGET_BUTTON"
										defaultMessage="Add Graph"
									/>
								}
								labelIcon={<AddIcon className={`${styles.addIconPlus}`} />}
								variant="primary"
								disabled={settingsState && settingsState.length > 11}
								onClick={() => this.openModal()}
								dataSpec="add_graph_button"
							/>
						</AnalyticsRWComponent>
					}
				/>
				<GridLayout
					settings={settingsState}
					propSettings={JSON.parse(settings[0].value)}
					dataGridSize={dataGridSize}
					updateSettings={this.updateSettings}
					getContent={this.getAnalyticsChartData}
					hasCarousel
					hasRefresh
					isAnalytics
					contentData={chartData}
					pendingData={(gridItemId) =>
						isGetChartDataPending(gridItemId) ||
						isGetChartDataPending(`${gridItemId}_usage_trend`) ||
						isGetDirectionIndexPending(gridItemId) ||
						isExportingChartDataCsv(gridItemId)}
					dropdownItem={(gridItem, objectToCanvas) =>
						this.renderDropdownItem(
							gridItem,
							objectToCanvas,
							exportChartDataCSV
						)}
				>
					{(gridItem) =>
						this.renderUsageTrend(gridItem, chartData, directionIndex)}
				</GridLayout>
				<AnalyticsModalForm
					show={openAddChartModal}
					onClose={this.closeModal}
					addChart={this.addChart}
					editItem={editItem}
					getModalChartData={this.getAnalyticsChartData}
					chartData={chartData}
					isGetChartDataPending={isGetChartDataPending}
					updateSettings={this.updateSettings}
					settingsState={settingsState}
					dataGridSize={dataGridSize}
					directionIndex={directionIndex}
					isGetDirectionIndexPending={isGetDirectionIndexPending}
				/>
			</div>
		);
	}
}

const { func, oneOf, arrayOf, object } = PropTypes;

Analytics.propTypes = {
	settings: arrayOf(object),
	dataGridSize: oneOf(['lg', 'md', 'sm']),
	updateUserSettings: func,
	getChartData: func,
	chartData: func,
	isGetChartDataPending: func,
	directionIndex: func,
	isGetDirectionIndexPending: func,
	getDirectionalIndex: func,
	exportChartDataCSV: func,
	isExportingChartDataCsv: func,
	createUserSettings: func,
	resetUserSettings: func
};

Analytics.defaultProps = {
	settings: {},
	dataGridSize: 'lg',
	updateUserSettings: undefined,
	getChartData: undefined,
	chartData: undefined,
	isGetChartDataPending: undefined,
	directionIndex: undefined,
	isGetDirectionIndexPending: undefined,
	getDirectionalIndex: undefined,
	exportChartDataCSV: undefined,
	isExportingChartDataCsv: undefined,
	createUserSettings: undefined,
	resetUserSettings: undefined
};

export default Analytics;
