export default {
	// filters
	CURRENCY: 'Valuta',
	BILLABLE: 'Factureerbaar',
	TAX_STATUS: 'Btw-status',
	SUBSCRIBER_STATUS: 'Status abonnee',
	MONTHLY_RATE_PLAN: 'Maandelijks tariefplan',
	STANDARD_RATE_PLAN: 'Standaard tariefplan',
	PREPAID_TERMS_CHARGED: 'Berekende prepaid-termijnen',
	PLAN_TYPE: 'Type plan',
	ZONE: 'Zone',
	ROAMING_ZONE: 'Roamingzone',
	GROUP_NAME: 'Groepsnaam',
	THE_PLAN_TYPE: 'Het type plan',
	ACTIVATION_TYPE: 'Type activering',
	CHARGE_TYPE: 'Type kosten',
	TECH_TYPE: 'Tech-type',
	PRIMARY_PLACE: 'Primaire plaats',
	DISTRICT_COUNTY: 'Provincie district',
	DISTRICT_CITY: 'Plaats district',
	PRIMARY_PLACE_OF_USE: 'Primaire gebruiksplaats',
	// meldingen
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': 'Gebruikersinstellingen zijn geüpdatet',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'Kan gebruikersinstellingen niet updaten',
	// facturen
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'Account-id',
	'ANALYTICS_INVOICES.ADD_NOTE': 'Voeg een opmerking toe',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Bijlage toevoegen',
	'ANALYTICS_INVOICES.COUNTRY_NAME': 'Land',
	'ANALYTICS_INVOICES.CUSTOMER_ID': 'Klant-id',
	'ANALYTICS_INVOICES.INVOICE_DATE': 'Factuurdatum',
	'ANALYTICS_INVOICES.DOCUMENT_ID': 'Document-id',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Subaccount',
	'ANALYTICS_INVOICES.NOTES': 'Opmerkingen',
	'ANALYTICS_INVOICES.LINK': 'Facturen',
	'ANALYTICS_INVOICES.BY': 'Op ',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Selecteer de statistieken die u hier wilt weergeven',
	'ANALYTICS_INVOICES.NA': 'N.v.t.',
	'ANALYTICS_INVOICES.YES': 'Ja',
	'ANALYTICS_INVOICES.NO': 'Nee',
	'ANALYTICS_INVOICES.INVOICE_ID': 'Factuur-id',
	'ANALYTICS_INVOICES.BILLING_CYCLE': 'Factureringscyclus',
	'ANALYTICS_INVOICES.ACCOUNT_ID': 'Account-id',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Naam account',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'Account-id operator',
	'ANALYTICS_INVOICES.DEVICES': 'Apparaten',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Totale kosten',
	'ANALYTICS_INVOICES.CURRENCY': 'Valuta',
	'ANALYTICS_INVOICES.DATA_VOLUME': 'Datavolume (MB)',
	'ANALYTICS_INVOICES.SMS_VOLUME': 'Sms-volume',
	'ANALYTICS_INVOICES.BILLABLE': 'Factureerbaar (ja of nee)',
	'ANALYTICS_INVOICES.BILL': 'Factureerbaar',
	'ANALYTICS_INVOICES.DATA_RATES': 'Datatarieven',
	'ANALYTICS_INVOICES.DATE': 'Factuurdatum',
	'ANALYTICS_INVOICES.BILLING_DETAILS': 'Factureringsgegevens',
	'ANALYTICS_INVOICES.DUE_DATE': 'Vervaldatum',
	'ANALYTICS_INVOICES.OPERATOR_ID': 'Operator-id',
	'ANALYTICS_INVOICES.PUBLISHED': 'Gepubliceerd',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Standaard overschrijding',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Standaard roaming',
	'ANALYTICS_INVOICES.TAXABLE': 'Account btw-plichtig',
	'ANALYTICS_INVOICES.TAX_STATUS': 'Btw-status',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Accountsegment',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'Weergeven/printen/opslaan',
	'ANALYTICS_INVOICES.INVOICE_TITLE': 'Factureringsgegevens',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Factuur downloaden',
	'ANALYTICS_INVOICES.ENDPOINTS': 'Eindpunten',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Prepaid abonnementen',
	'ANALYTICS_INVOICES.ADDONS': 'Add-ons',
	'ANALYTICS_INVOICES.RATE_PLANS': 'Tariefplannen',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Zones tariefplan',
	'ANALYTICS_INVOICES.BILLING_GROUPS': 'Factureringsgroepen',
	'ANALYTICS_INVOICES.DOWNLOAD': 'Downloaden',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'PDF downloaden',
	'ANALYTICS_INVOICES.UPLOAD': 'Uploaden',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Factuur uploaden',
	'ANALYTICS_INVOICES.ACTIVATIONS': 'Activeringen',
	'ANALYTICS_INVOICES.DISCOUNTS': 'Kortingen',
	'ANALYTICS_INVOICES.TAX': 'Btw',
	'ANALYTICS_INVOICES.SURCHARGES': 'Toeslagen',
	'ANALYTICS_INVOICES.TECH_TYPES': 'Tech-typen',
	'ANALYTICS_INVOICES.OTHER_CHARGES': 'Overige kosten',
	'ANALYTICS_INVOICES.NO_DATA': 'Geen facturen beschikbaar',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': 'Er zijn momenteel geen beschikbare facturen om te laten zien',
	'ANALYTICS_INVOICES.TITLE': 'Facturen',
	'ANALYTICS_INVOICES.DATA_DETAILS': 'Datagegevens',
	'ANALYTICS_INVOICES.SMS_DETAILS': 'Sms-gegevens',
	'ANALYTICS_INVOICES.TAXES': 'Btw',
	// eindpuntentabel
	'ANALYTICS_INVOICES.ICCID': 'ICCID',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Index maandelijks pro rata',
	'ANALYTICS_INVOICES.DEVICE_ID': 'Apparaat-id',
	'ANALYTICS_INVOICES.CUSTOMER': 'Klant',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Maandelijks tariefplan',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Berekende prepaid-tijdvakken',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Standaard tariefplan',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'Status abonnee',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Abonnementskosten',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Kosten vaste pool',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'SMS MO-volume (berichten)',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'SMS MT-volume (berichten)',
	// tabel prepaid abonnementen
	'ANALYTICS_INVOICES.MSISDN': 'MSISDN',
	'ANALYTICS_INVOICES.RATE_PLAN': 'Tariefplan',
	'ANALYTICS_INVOICES.PLAN_VERSION': 'Versie plan',
	'ANALYTICS_INVOICES.PLAN_TYPE': 'Type plan',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Index pro rata verbruik',
	'ANALYTICS_INVOICES.TERM_START_DATE': 'Begindatum tijdvak',
	'ANALYTICS_INVOICES.TERM_END_DATE': 'Einddatum tijdvak',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'Verbruik bundeldata MB',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'Bundel-SMS MO (berichten)',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'Bundel-SMS MT (berichten)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Bundel-voice (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'Bundel-voice MO (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'Bundel-voice MT (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD': 'Bundel-CSD (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'Bundel-CSD MO (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'Bundel-CSD MT (m:ss)',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': 'Gebruik in periode tot op heden (primaire zone)',
	'ANALYTICS_INVOICES.VOICE_VOLUME': 'Voice-volume (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Voice MO-volume (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Voice MT-volume (m:ss)',
	// Add-ontabel
	'ANALYTICS_INVOICES.ADDON_SIZE': 'Grootte add-on (MB)',
	'ANALYTICS_INVOICES.ZONE': 'Zone',
	'ANALYTICS_INVOICES.ADDON_CHARGES': 'Kosten add-on',
	'ANALYTICS_INVOICES.DATE_PURCHASED': 'Aankoopdatum',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Basisplan toegepast op',
	// Tariefplannentabel
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': 'Abo-commitment activeringsperiode',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Actieve abonnementen',
	'ANALYTICS_INVOICES.ACTIVE_TIER': 'Actief niveau',
	'ANALYTICS_INVOICES.DATA_CHARGE': 'Datakosten',
	'ANALYTICS_INVOICES.INCLUDED_DATA': 'Bundeldata',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Modus databundel',
	'ANALYTICS_INVOICES.INCLUDED_SMS': 'Bundel-sms',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Bundelmodus SMS MO',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Bundelmodus sms',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Bundelmodus SMS MT',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Bundelmodus voice MO',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Bundelmodus voice',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': 'Minimale abo-activeringsperiode',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Datakosten niet-roaming',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Datavolume niet-roaming',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Type betaling',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Prepaid-activeringen',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Datakosten roaming',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Datavolume roaming',
	'ANALYTICS_INVOICES.SMS_CHARGE': 'Sms-kosten',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Abonnementsveld',
	'ANALYTICS_INVOICES.VOICE_CHARGE': 'Kosten voice',
	// Tabel Zones tariefplan
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Actief overschrijdingstarief',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Datalimiet bundel',
	'ANALYTICS_INVOICES.INCLUDED_MODE': 'Bundelmodus',
	'ANALYTICS_INVOICES.PRIORITY': 'Prioriteit',
	'ANALYTICS_INVOICES.ROAMING_ZONE': 'Roamingzone',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Totaal bundelverbruik (MB)',
	//  Tabel Factureringsgroepen
	'ANALYTICS_INVOICES.GROUP_NAME': 'Groepsnaam',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Totaal bundel',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'Kosten SMS MO',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'Bundel SMS MO (berichten)',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': 'Totaal verbruik bundel SMS MO',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': 'Totaal verbruik bundel voice MO',
	// Tabel Datagegevens
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Dagelijkse afrondingsbijstelling',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'Het type plan',
	// tabel sms-tabel
	'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Type bericht',
	// activeringentabel
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Activeringskosten',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Type activering',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Oorspronkelijke activeringsdatum',
	// kortingentabel
	'ANALYTICS_INVOICES.CHARGE_TYPE': 'Type kosten',
	'ANALYTICS_INVOICES.DISCOUNT': 'Korting',
	// btw-tabel
	'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Primaire plaats',
	'ANALYTICS_INVOICES.MRC': 'MRC',
	'ANALYTICS_INVOICES.DATA': 'Data',
	'ANALYTICS_INVOICES.SMS': 'SMS',
	'ANALYTICS_INVOICES.VOICE': 'Voice',
	'ANALYTICS_INVOICES.ACTIVATION': 'Activering',
	'ANALYTICS_INVOICES.VPN': 'VPN',
	'ANALYTICS_INVOICES.SIM': 'SIM',
	'ANALYTICS_INVOICES.MRF': 'MRF',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': 'Type tech',
	// toeslagentabel
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Primaire gebruiksplaats',
	'ANALYTICS_INVOICES.FEDERAL': 'Landelijk',
	'ANALYTICS_INVOICES.STATE': 'Provincie',
	'ANALYTICS_INVOICES.COUNTY': 'Gemeente',
	'ANALYTICS_INVOICES.CITY': 'Plaatsnaam',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'Gemeente district',
	'ANALYTICS_INVOICES.DISTRICT_CITY': 'Plaats district',
	// tech-typetabel
	'ANALYTICS_INVOICES.PLAN_ID': 'Plan-id',
	'ANALYTICS_INVOICES.VERSION_ID': 'Versie-id',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'Aantal 2G-apparaten',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': 'Kosten tech-type 2G',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'Aantal 3G-apparaten',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': 'Kosten tech-type 3G',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'Aantal 4G-apparaten',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': 'Kosten tech-type 4G',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Berekeningsfrequentie tech-type',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Totale kosten tech-type',

	// Abonnementskosten:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Abonnementskosten',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Abonnementskosten',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Datakosten',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'Sms-kosten',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Activeringskosten',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Kosten tech-type',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Vaste korting',
	'ANALYTICS_INVOICES_DETAILS.TAX': 'Btw',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Totale kosten',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Toeslag',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Vervalt op ',

	// Statistieken eindpunt:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Statistieken eindpunt',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'EINDPUNTEN',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'ACTIEVE ABONNEES',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'DATAVOLUME',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'SMS-VOLUME',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'SERVICE-OPBRENGSTEN',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'VARIABLE KORTING',

	// tabel overige kosten
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Bedrag voor toeslagen',
	'ANALYTICS_INVOICES.DESCRIPTION': 'Beschrijving',
	'ANALYTICS_INVOICES.QUANTITY': 'Aantal',
	'ANALYTICS_INVOICES.ORDER_NUMBER': 'Bestelnummer',
	'ANALYTICS_INVOICES.REFERENCE_ID': 'Referentie-id',
	//  tijdelijke aanduidingen
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Noteer hier uw opmerking',
	'ANALYTICS_INVOICES.REMOVE': 'Verwijderen',
	'ANALYTICS_INVOICES.EDIT_NOTE': 'Opmerking bewerken',
	'ANALYTICS_INVOICES.CANCEL': 'Annuleren',
	'ANALYTICS_INVOICES.SUBMIT': 'Verzenden',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Bijlagen toegevoegd',
	//  tabel modaal bewerken
	'ANALYTICS_INVOICES.APPLY_CHANGE': 'Wijziging toepassen',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Standaardinstellingen herstellen',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Tabelparameters bewerken',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': 'Selecteer minimaal 2 om op de tabel toe te passen. ',
	'ANALYTICS_INVOICES.OF': ' van ',
	'ANALYTICS_INVOICES.SELECTED': ' Geselecteerd',
	// factureringsgegevens
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'Data niet beschikbaar',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': 'Er zijn geen data voor deze factuur',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': 'Database met factureringsgegevens is leeg voor deze factuur',
	'ANALYTICS_INVOICES.ADD_FILES': 'Bestanden met factureringsgegevens',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'Weergeven/toevoegen/bewerken',
	//  BIJLAGEN MODAAL
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Bijlagen voor factuur',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Bijlage bewerken',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Upload een bestand',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Bijlage verwijderen',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Sluiten',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Annuleren',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Opslaan',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Verwijderen',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Bestand uploaden',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Bijlage uploaden',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Factuur uploaden',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'Naam bestand',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Beschrijving',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': 'U staat op het punt een bijlage te verwijderen uit MC. Dit bestand zal daardoor permanent uit het systeem worden verwijderd. Weet u zeker dat u deze taak wilt uitvoeren?',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'Te verwijderen bestand:',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'Bijgevoegd bestand',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': 'Bestandsnaam (De standaard bestandsnaam wordt gebruikt als u dit leeg laat)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': 'Beschrijving bestand zichtbaar voor klant (optioneel)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': 'Deze factuur wordt over een paar weken actief.',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': 'Versleep het bestand of klik om te uploaden',
	'INVOICES_NOTES_UPDATE.SUCCESS': 'Factuuropmerking is geüpdatet.',
	//  OPMERKINGEN MODAAL
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'Update geslaagd',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': 'Uw opmerking is toegevoegd.',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Update mislukt',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': 'Uw opmerking is toegevoegd.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'Upload geslaagd',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Bestand is geüpload.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Fout tijdens uploaden',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Er is een fout opgetreden tijdens het uploaden van dit bestand naar MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': 'Bijlage verwijderd',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': 'Bestand is verwijderd uit MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': 'Fout tijdens verwijderen',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': 'Er is een fout opgetreden tijdens het verwijderen van dit bestand uit MC.',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Factuurdatum',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': 'Kan de factureringsgegevens niet weergeven, omdat de factureringscyclus niet geldig is.',
	// NIEUWE FACTUUR TOEVOEGEN
	'INVOICES.ADD_NEW_INVOICE': 'Nieuwe factuur toevoegen',
	'INVOICES.CANCEL': 'Annuleren',
	'INVOICES.ADD_INVOICE': 'Factuur toevoegen',
	'INVOICES.EDIT': 'Bewerken',
	'INVOICES.CONFIRM': 'Bevestigen',
	'INVOICES.CONFIRMATION': 'Bevestiging',
	'INVOICES.INVOICE_DETAILS': 'Factureringsgegevens',
	'ADD_INVOICE.INVOICE_ID': 'Invoice ID',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': 'Id M2M-account',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'Id M2M-account selecteren',
	'ADD_INVOICE.INVOICE_DATE': 'Factuurdatum',
	'ADD_INVOICE.BILLING_CYCLE': 'Billing Cycle',
	'ADD_INVOICE.DATE_PLACEHOLDER': 'Datum selecteren',
	'ADD_INVOICE.UPLOAD_FILE': 'Upload een bestand',
	'ADD_INVOICE.UPLOAD_FILE_NAME': 'Naam bestand (Optioneel: De standaard bestandsnaam wordt gebruikt als u dit leeg laat)',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': 'Naam bestand (maximaal 255 tekens)',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': 'Naam bestand',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'U hebt een nieuwe factuur toegevoegd.',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Er is een fout opgetreden tijdens het toevoegen van een factuur. Probeer het opnieuw.',
	'ADD_INVOICE.TIME_STAMP': 'Bijgevoegd op {date} om {time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION': 'Bestandsnaam is niet geldig (gebruik _ in plaats van een spatie, en de volgende tekens zijn niet toegestaan: ! ? <> % $ () + =',
};
