import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const SuperUserTopBarWrapper = () => (WrappedComponent) => {
	class SuperUserTopBar extends PureComponent {
		getCompanyFriendlyName = (user, companyAccountId) => {
			const companyServiceResult = user.companies.find(
				(e) => e.externalCompanyId === companyAccountId
			);

			return companyServiceResult && companyServiceResult.companyFriendlyName;
		};

		render() {
			const { user, companyAccountId } = this.props;

			return (
				<WrappedComponent
					data-spec="above-top-bar-content-wrapper"
					companyFriendlyName={
						this.getCompanyFriendlyName(user, companyAccountId)
					}
				/>
			);
		}
	}

	const { shape, string } = PropTypes;

	SuperUserTopBar.propTypes = {
		user: shape(),
		companyAccountId: string,
	};

	SuperUserTopBar.defaultProps = {
		user: {},
		companyAccountId: '',
	};

	return SuperUserTopBar;
};

export default SuperUserTopBarWrapper;
