import React, { PureComponent } from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import {} from 'lodash';

import { FormattedMessage } from 'react-intl';
import { array, object, bool, func } from 'prop-types';

import ImageSelector from 'utils/imageSelector';
import CurrentContext from 'utils/currentContext';
import { required, email } from '../../../../utils/validators';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './AddUsersModal.scss';

const PlusIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/pluss-add-collapse.svg'
);

const TrashIcon = ImageSelector(CurrentContext.theme, 'svgs/trash.svg');
const searchParams = [{ propValue: true, prop: 'active', operator: '=' }];
const lower = (value) => value && value.toLowerCase();
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
// const asyncValidate = (values, _, props, fieldName) =>
// 	sleep(1000).then(() => {
// 		console.log('fields: ', fieldName);
// 		const val = values[fieldName];
// 		const index = fieldName.substr(7, 1) >= 0 && fieldName.substr(7, 1);
// 		console.log('index; ', index);
// 		if (fieldName === 'email') {
// 			if (
// 				[
// 					'john@gmail.com',
// 					'paul@gmail.com',
// 					'george@gmail.com',
// 					'ringo@gmail.com'
// 				].includes(`${val}`)
// 			) {
// 				throw { email: 'Email is taken' };
// 			}
// 		}
// 		if (index) {
// 			const valu = values.fields[index].email;
// 			console.log('value: ', valu);
// 			if (
// 				[
// 					'john@gmail.com',
// 					'paul@gmail.com',
// 					'george@gmail.com',
// 					'ringo@gmail.com'
// 				].includes(valu)
// 			) {
// 				throw { : 'Email is taken' };
// 			}
// 		}
// 	});
class AddUsersModal extends PureComponent {
	componentDidMount() {
		const { getUserGroups, system } = this.props;
		getUserGroups({ system, params: { searchParams } });
	}

	getOptions = () => {
		const { userGroups } = this.props;
		const userGroupOptions = userGroups ? userGroups.resultList : [];
		return userGroupOptions.map((item) => ({
			value: item.id,
			label: item.name
		}));
	};

	sendInvite = () => {
		const { formValues, inviteUsers, inviteSystemUsers, onClose, system } = this.props;
		const registrations = [];
		registrations.push({
			firstName: formValues.firstName,
			lastName: formValues.lastName,
			email: formValues.email,
			userGroupId: formValues.userGroup.value
		});
		if (formValues.fields && formValues.fields.length > 0) {
			// eslint-disable-next-line array-callback-return
			formValues.fields.map((item) => {
				registrations.push({
					firstName: item.firstName,
					lastName: item.lastName,
					email: item.email,
					userGroupId: item.userGroup.value
				});
			});
		}
		if (!system) {
			inviteUsers({ registrations });
		} else {
			inviteSystemUsers({ registrations });
		}
		onClose();
	};

	renderFields = ({ fields }) => (
		<div data-spec="invite-user-fields">
			{fields.map((item) => (
				<div className={styles.block} key={item}>
					<div className={styles.column_fields}>
						<FormattedMessage
							id="ONBOARDING.FIRST_NAME"
							defaultMessage="First Name"
						>
							{(formattedValue) => (
								<Field
									validate={[required]}
									component={Input}
									placeholder={formattedValue}
									name={`${item}.firstName`}
									dataSpec="input-first-name"
								/>
							)}
						</FormattedMessage>
					</div>
					<div className={styles.column_fields}>
						<FormattedMessage
							id="ONBOARDING.LAST_NAME"
							defaultMessage="Last Name"
						>
							{(formattedValue) => (
								<Field
									validate={[required]}
									component={Input}
									placeholder={formattedValue}
									name={`${item}.lastName`}
									dataSpec="input-last-name"
								/>
							)}
						</FormattedMessage>
					</div>
					<div className={styles.column_fields}>
						<FormattedMessage
							id="ONBOARDING.EMAIL_ADDRESS"
							defaultMessage="Email Address"
						>
							{(formattedValue) => (
								<Field
									validate={[required, email]}
									component={Input}
									placeholder={formattedValue}
									normalize={lower}
									name={`${item}.email`}
									dataSpec="input-email-address"
								/>
							)}
						</FormattedMessage>
					</div>
					<div className={styles.column_fields}>
						<Field
							validate={[required]}
							component={Select}
							name={`${item}.userGroup`}
							options={this.getOptions()}
						/>
					</div>
					<span
						className={styles.remove_icon_wrapper}
						onClick={() => fields.pop({})}
					>
						<TrashIcon />
						<FormattedMessage id="REMOVE" defaultMessage="Remove" />
					</span>
				</div>
			))}
			<Button
				className={styles.link_button}
				variant="link"
				type="button"
				label={
					<FormattedMessage
						id={'ONBOARDING.ADD_MORE_USERS'}
						defaultMessage="Add More Users"
					/>
				}
				labelIcon={<PlusIcon className={styles.icon} />}
				onClick={() => fields.push({})}
			/>
		</div>
	);

	render() {
		const { show, onClose, handleSubmit, isInvalid, isDirty } = this.props;
		return (
			<Modal data-spec="add-users-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.ADD_USERS"
							defaultMessage="Add Users"
						/>
					}
					closeModal={onClose}
				/>
				<ModalBody className={styles.modal_body}>
					<form className={styles.modal_content} onSubmit={handleSubmit}>
						<p className={styles.instructions}>
							<FormattedMessage
								id="ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS"
								defaultMessage="Enter the email addresses for the users you would like to invite. Users will be able to access the company account as soon as they accept the invite link provided in the email notification."
							/>
						</p>
						<label className={styles.label}>
							<FormattedMessage
								id="ONBOARDING.INVITE_USERS"
								defaultMessage="Invite Users"
							/>
						</label>
						<div className={styles.block}>
							<div className={styles.column}>
								<FormattedMessage
									id="ONBOARDING.FIRST_NAME"
									defaultMessage="First Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name="firstName"
											validate={[required]}
											dataSpec="input-first-name"
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={styles.column}>
								<FormattedMessage
									id="ONBOARDING.LAST_NAME"
									defaultMessage="Last Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											validate={[required]}
											placeholder={formattedValue}
											name="lastName"
											dataSpec="input-last-name"
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={styles.column}>
								<FormattedMessage
									id="ONBOARDING.EMAIL_ADDRESS"
									defaultMessage="Email Address"
								>
									{(formattedValue) => (
										<Field
											validate={[required, email]}
											component={Input}
											placeholder={formattedValue}
											name="email"
											// onBlur={() => this.checkEmail('email')}
											normalize={lower}
											dataSpec="input-email-address"
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={styles.column}>
								<Field
									validate={[required]}
									component={Select}
									name="userGroup"
									options={this.getOptions()}
								/>
							</div>
						</div>
						<FieldArray name={'fields'} component={this.renderFields} />
						{/* <div>
							<Button
								variant="outline-primary"
								type="button"
								label={
									<FormattedMessage
										id="ONBOARDING.BULK_UPLOAD"
										defaultMessage="Bulk Upload"
									/>
								}
							/>
							<Button
								variant="link"
								type="button"
								className={styles.link_button_template}
								label={
									<FormattedMessage
										id="ONBOARDING.DOWNLOAD_CSV_TEMPLATE"
										defaultMessage="Download CSV template"
									/>
								}
							/>
							<FormattedMessage
								id="ONBOARDING.FOR_BULK_UPLOADS"
								defaultMessage="for bulk uploads"
							/>
						</div>
						<hr className={styles.separator} />
						<div className={styles.accounts_message}>
							<FormattedMessage
								id="ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS"
								defaultMessage="Access will be granted to the following M2M Accounts"
							/>
						</div>
						<div className={styles.assigned_account}>
							<div>Acme Co.</div>
							<div>Acme-Danny</div>
							<div>Acme Ag.</div>
							<div>Acme Mike</div>
						</div> */}
					</form>
				</ModalBody>
				<ModalFooter>
					<Button
						data-spec="close-button"
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						onClick={onClose}
						type="button"
					/>
					<Button
						disabled={isInvalid || !isDirty}
						onClick={this.sendInvite}
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.SEND_INVITE"
								defaultMessage="Send Invite"
							/>
						}
						data-spec="send-invite-button"
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

AddUsersModal.propTypes = {
	handleSubmit: func,
	getUserGroups: func,
	onClose: func,
	inviteUsers: func,
	inviteSystemUsers: func,
	show: bool,
	isInvalid: bool,
	isDirty: bool,
	resultList: array,
	userGroups: object,
	formValues: object,
	system: bool
};

AddUsersModal.defaultProps = {
	show: false
};

export default reduxForm({
	form: 'InviteNewUser',
	destroyOnUnmount: true,
	// asyncValidate,
	// asyncBlurFields: ['email', 'fields[].email']
})(AddUsersModal);
