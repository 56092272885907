import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { bool, func, array } from 'prop-types';

import { required, maxLength } from '../../../../utils/validators';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './AddUserGroupModal.scss';

const maxLength30 = maxLength(30);

function AddUserGroupModal({
	show,
	onClose,
	existingUserGroups,
	handleSubmit,
	invalid,
	addUserGroupRequest
}) {
	const submitForm = (values, _, props) => {
		values.name = values.name.trim();

		const userGroup = {
			name: values.name
		};
		if (values.inheritFromUserGroupId) {
			userGroup.inheritFromUserGroupId = values.inheritFromUserGroupId.value;
		}
		props.addUserGroup({ system: props.system, userGroup });
	};
	return (
		<Modal data-spec="add-users-modal" show={show} onClose={onClose}>
			<form onSubmit={handleSubmit(submitForm)}>
				<FormattedMessage
					id="ONBOARDING.CREATE_NEW_USER_GROUP"
					defaultMessage="Create New User Group"
				>
					{(formattedValue) => (
						<ModalHeader title={formattedValue} closeModal={onClose} />
					)}
				</FormattedMessage>
				<ModalBody className={styles.modal_body}>
					<div className={styles.modal_body_content}>
						<h2 className={styles.heading}>
							<FormattedMessage
								id="ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP"
								defaultMessage="Create customized access entitlement groups for your users."
							/>
						</h2>

						<div className={styles.field_wrap}>
							<FormattedMessage
								id="ONBOARDING.GROUP_NAME"
								defaultMessage="Group Name"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										name="name"
										dataSpec="group-name-input"
										label={formattedValue}
										validate={[required, maxLength30]}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage
								id="ONBOARDING.SELECT_EXISTING_USER_GROUP"
								defaultMessage="Select Existing User Group"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name="inheritFromUserGroupId"
										label={formattedValue}
										options={existingUserGroups.map((item) => ({
											value: item.id,
											label: item.name
										}))}
										cleanValue
										menuPosition="fixed"
									/>
								)}
							</FormattedMessage>
						</div>
						<p className={styles.paragraph}>
							<FormattedMessage
								id="ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS"
								defaultMessage="In the next step you will be able to fine-tune new group permissions."
							/>
						</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						dataSpec="close-button"
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						onClick={onClose}
						type="button"
					/>
					<Button
						type="submit"
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.CREATE_GROUP"
								defaultMessage="Create Group"
							/>
						}
						dataSpec="submit-button"
						disabled={invalid || addUserGroupRequest}
					/>
				</ModalFooter>
			</form>
		</Modal>
	);
}

AddUserGroupModal.propTypes = {
	onClose: func,
	show: bool,
	existingUserGroups: array,
	handleSubmit: func,
	invalid: bool,
	addUserGroup: func,
	system: bool,
	addUserGroupRequest: bool
};

export default reduxForm({
	form: 'AddUserGroupModalForm'
})(AddUserGroupModal);
