export const GET_AUTOMATION_ALERTS_FILTERS = 'GET_AUTOMATION_ALERTS_FILTERS';
export const GET_AUTOMATION_RULES_FILTERS = 'GET_AUTOMATION_RULES_FILTERS';
export const GET_AUTOMATION_ALERTS = 'GET_AUTOMATION_ALERTS';
export const REMOVE_AUTOMATION_ALERT = 'REMOVE_AUTOMATION_ALERT';
export const GET_AUTOMATION_RULES = 'GET_AUTOMATION_RULES';
export const GET_AUTOMATION_RULE = 'GET_AUTOMATION_RULE';
export const REMOVE_AUTOMATION_RULE = 'REMOVE_AUTOMATION_RULE';
export const ADD_AUTOMATION_RULE = 'ADD_AUTOMATION_RULE';
export const EDIT_AUTOMATION_RULE = 'EDIT_AUTOMATION_RULE';
export const GET_AUTOMATION_RULES_ACTIONS = 'GET_AUTOMATION_RULES_ACTIONS';
export const GET_AUTOMATION_RULES_CHILD_ACTIONS =
	'GET_AUTOMATION_RULES_CHILD_ACTIONS';
export const GET_AUTOMATION_COUNTRIES = 'GET_AUTOMATION_COUNTRIES';
export const GET_AUTOMATION_OPTIONS = 'GET_AUTOMATION_OPTIONS';
export const GET_AUTOMATION_PLATFORMS = 'GET_AUTOMATION_PLATFORMS';
export const GET_AUTOMATION_CATEGORIES = 'GET_AUTOMATION_CATEGORIES';
export const GET_AUTOMATION_TRIGGERS = 'GET_AUTOMATION_TRIGGERS';
export const GET_AUTOMATION_TRIGGERS_SUBCATEGORIES =
	'GET_AUTOMATION_TRIGGERS_SUBCATEGORIES';
export const GET_AUTOMATION_FRAMES = 'GET_AUTOMATION_FRAMES';
export const GET_AUTOMATION_PLATFORM = 'GET_AUTOMATION_PLATFORM';
export const GET_AUTOMATION_POOL = 'GET_AUTOMATION_POOL';
export const GET_AUTOMATION_ZONE_NAME = 'GET_AUTOMATION_ZONE_NAME';
export const GET_AUTOMATION_ZONE_MODEL = 'GET_AUTOMATION_ZONE_MODEL';
export const GET_AUTOMATION_SCHEDULERS = 'GET_AUTOMATION_SCHEDULERS';
export const GET_AUTOMATION_RATE_PLANS = 'GET_AUTOMATION_RATE_PLANS';
export const GET_AUTOMATION_PREPAID_RATE_PLANS =
	'GET_AUTOMATION_PREPAID_RATE_PLANS';
export const GET_AUTOMATION_NETWORK_ENTITLEMENTS =
	'GET_AUTOMATION_NETWORK_ENTITLEMENTS';
export const GET_AUTOMATION_AVAILABLE_STATES =
	'GET_AUTOMATION_AVAILABLE_STATES';
export const ACTIVATE_RULE = 'ACTIVATE_RULE';
export const SET_RULE_STATE_RECEIVED = 'SET_RULE_STATE_RECEIVE';
export const SET_RULE_STATE_IMPLEMENTED = 'SET_RULE_STATE_IMPLEMENTED';
export const SET_RULE_STATE_CONFLICTED = 'SET_RULE_STATE_CONFLICTED';
export const REQUEST_FOR_DELETION = 'REQUEST_FOR_DELETION';
export const GET_RULE_HISTORY = 'GET_RULE_HISTORY';
export const GET_RULE_NOTES = 'GET_RULE_NOTES';
export const GET_RULE_AUTOMATION_ZONES = 'GET_RULE_AUTOMATION_ZONES';
