export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1.图类别',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2.分组数据依据标准',
	'ANALYTICS.THIRD_STEP_FILTERS': '3.过滤器',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4.图选项',
	'ANALYTICS.EDIT_CHART': '编辑图表',
	'ANALYTICS.CREATE_NEW_CHART': '创建新图',
	'ANALYTICS.DISCLAIMER': '免责声明： ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': '图表数据来自多个系统，提交周期各不相同。图中提供的是近似汇总数据。数据依据的时区为UTC-0。与账单服务相关的最终数据将出现在该账单周期的账单中。',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': '端点总数',
	'ANALYTICS.NO_USAGE_SIMS': '无用量的SIM卡',
	'ANALYTICS.TOTAL_SMS_USAGE': '短信服务（SMS）总用量',
	'ANALYTICS.TOTAL_DATA_USAGE': '数据总用量',
	'ANALYTICS.TOTAL_VOICE_USAGE': '语音总用量',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': '估计的平均用量',
	'ANALYTICS.NO_CATEGORIES': '本账户无可用的类别',
	'ANALYTICS.LINE_GRAPH': '线状图',
	'ANALYTICS.PIE_CHART': '饼图',
	'ANALYTICS.BAR_GRAPH': '条形图',
	'ANALYTICS.WORLD_MAP_GRAPH': '世界地图',
	'ANALYTICS.PLATFORM': '平台',
	'ANALYTICS.ACCESSTECHNOLOGY': '访问技术',
	'ANALYTICS.OPERATOR': '运营者',
	'ANALYTICS.RATEPLAN': '费率计划',
	'ANALYTICS.COUNTRYCODE': '国家',
	'ANALYTICS.SIM_STATE': 'SIM卡状态',
	'ANALYTICS.DATE_RANGE': '日期范围',
	'ANALYTICS.TODAY': '今天',
	'ANALYTICS.PREVIOUS_DAY': '前一天',
	'ANALYTICS.LAST_10_DAYS': '过去10天',
	'ANALYTICS.LAST_30_DAYS': '过去30天',
	'ANALYTICS.CUSTOM_RANGE': '定制范围',
	'ANALYTICS.CYCLE': '周期',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': '当前周期至今',
	'ANALYTICS.HISTORICAL': '历史',
	'ANALYTICS.M2M_PLATFORM_TITLE': '机对机（M2M） 平台',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': '递增依据标准',
	'ANALYTICS.DAY': '日',
	'ANALYTICS.WEEK': '周',
	'ANALYTICS.MONTH': '月',
	'ANALYTICS.SHOW': '显示',
	'ANALYTICS.SUM_TOTALS': '总和',
	'ANALYTICS.AVERAGE_TOTALS': '总平均',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': '第一步，请选择一个类别',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': '此类别无可用的过滤器',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': '定制图数据',
	'ANALYTICS.EXPORT_CHART_DATA': '输出图数据',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': '下载PNG',
	'ANALYTICS.REMOVE_CHART': '移除图',
	'ANALYTICS.ADD_WIDGET_BUTTON': '添加图',
	'ANALYTICS.NO_ATTRIBUTES': '此账户无可用的按属性分组',
	'ANALYTICS.MOVING_AVERAGE': '移动平均数',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': '显示合计总结',
};
