import React from 'react';
import { object } from 'prop-types';
import { injectIntl } from 'react-intl';

import StaticField from '../../../../lib/DigitalComponents/StaticField';

function CompanyContactOverview({ contact, intl }) {
	return (
		<>
			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.FIRST_NAME',
					defaultMessage: 'First Name'
				})}
				data={contact.firstName}
			/>
			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.LAST_NAME',
					defaultMessage: 'Last Name'
				})}
				data={contact.lastName}
			/>
			<StaticField
				single
				label={intl.formatMessage({
					id: 'ONBOARDING.JOB_TITLE',
					defaultMessage: 'Job Title'
				})}
				data={contact.jobTitle}
			/>
			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.PHONE_NUMBER',
					defaultMessage: 'Phone Number'
				})}
				data={contact.mask ?
					<div>
						{contact.mask.value} - {contact.phoneNumber}
					</div> : contact.phoneNumber}
			/>
			<StaticField
				label={intl.formatMessage({
					id: 'ONBOARDING.EMAIL_ADDRESS',
					defaultMessage: 'Email Address'
				})}
				data={contact.email}
			/>
		</>
	);
}

CompanyContactOverview.propTypes = {
	contact: object,
	intl: object
};

export default injectIntl(CompanyContactOverview);
