import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import DSSteps from '../../../../lib/DigitalComponents/Steps';
import StepFirst from './Steps/StepFirst';
import StepSecond from './Steps/StepSecond';
import Notifications from '../../../../lib/DigitalComponents/Notifications';
import {
	ScheduleModalContext,
	ScheduleModalContextProvider,
	ScheduleModalContextConsumer
} from './ScheduleModalContext';
import styles from './ScheduleModal.scss';

class ScheduleModal extends Component {
	constructor(props) {
    super(props);
		this.state = {};
  }

	onFinish = () => {
		const { onCancel } = this.props;
		onCancel();
	};

	mergeValues = (values) => {
		this.setState({
			...values
		});
	};

	renderNotifications = (endpoint) => {
    const { intl } = this.props;
		const item = {
			id: 'endpoint-status-change-error',
			type: 'error',
			title: (
				<FormattedMessage
					id="PROVISIONING_ERROR"
					defaultMessage="Provisioning Error"
				/>
			),
			message: [
				intl.formatMessage(
					{
						id: 'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR',
						defaultMessage: `Endpoind ID ${
							endpoint ? endpoint.iccid : ''
						} failed to provision after IMSI status change. Get support`
					},
					{
						endpointICCID: endpoint ? endpoint.iccid : ''
					}
				)
			]
		};

		return <Notifications data-spec="notification" {...item} />;
	};

	render() {
		const {
			singleEndpointUpdateFail,
			onCancel,
			newState,
			endpoint,
			user,
			fetchSingleEndpoint,
			type,
			endpointName,
			show
    } = this.props;

    const customSteps = [
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP"
						defaultMessage="1. Schedule SIM state change"
					/>
				),
				component: (
					<ScheduleModalContextConsumer>
						<StepFirst
							onCancel={onCancel}
							newState={newState}
							endpoint={endpoint}
							user={user}
						/>
					</ScheduleModalContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SYSTEM_CONFIRMATION_STEP"
						defaultMessage="2. System confirmation"
					/>
				),
				component: (
					<ScheduleModalContextConsumer>
						<StepSecond
							values={this.state}
							onCancel={onCancel}
							fetchSingleEndpoint={fetchSingleEndpoint}
							type={type}
							endpointName={endpointName}
						/>
					</ScheduleModalContextConsumer>
				)
			}
		];

		const tabs = [
			{
				id: 'simStateChange',
				displayName: (
					<FormattedMessage
						id="ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE"
						defaultMessage="Schedule SIM state change"
					/>
				),
				tabContentClassName: styles.modal_tab_content,
				content: () => (
					<div key="simStateChangeSteps">
						<DSSteps
							data-spec="sim-state-change-steps"
							steps={customSteps}
							onNext={this.mergeValues}
							noActions
							onBack={this.mergeValues}
							onFinish={this.onFinish}
							isActionError={singleEndpointUpdateFail}
						/>
					</div>
				)
			}
		];

		return (
			<div data-spec="batch modal">
				<ScheduleModalContextProvider>
					<Modal show={show} onClose={onCancel} className={styles.modal}>
						<ModalHeader
							title={
								<FormattedMessage
									id="ENDPOINTS.SIM_STATE_CHANGE"
									defaultMessage="SIM state change"
								/>
							}
						/>
						<ModalBody className={styles.modal_body_notf}>
							<div className={styles.modal_notification_wrapper}>
								{singleEndpointUpdateFail &&
									this.renderNotifications(endpoint)}
							</div>
							<ModalTabs tabs={tabs} />
						</ModalBody>
						<ModalFooter>
							<ScheduleModalContext.Consumer>
								{(context) => context.state.footer}
							</ScheduleModalContext.Consumer>
						</ModalFooter>
					</Modal>
				</ScheduleModalContextProvider>
			</div>
		);
	}
}

export default injectIntl(ScheduleModal);

ScheduleModal.propTypes = {
	show: PropTypes.bool,
	singleEndpointUpdateFail: PropTypes.bool,
	onCancel: PropTypes.func,
	newState: PropTypes.object,
	endpoint: PropTypes.object,
	user: PropTypes.object,
	fetchSingleEndpoint: PropTypes.func,
	type: PropTypes.string,
	endpointName: PropTypes.string,
	onlyBody: PropTypes.bool,
	isPendingStatus: PropTypes.bool,
	intl: PropTypes.object
};
