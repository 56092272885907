import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import NotSupported from '../NotSupported';

import styles from './Mobile.scss';

const image = ImageSelector(
	CurrentContext.theme,
	'svgs/screen-resolution-illustration.svg'
);

export default NotSupported(styles, image);
