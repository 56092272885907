import {
	userIsAuthenticated,
	userInStandardMode,
	userInSupermode
} from '../../../utils/AuthSelector';
import SimOrderingView from '../views/SimOrderingView';
import SimOrderEditView from '../views/SimOrderEditView';

const simOrderingRoutes = [
	{
		path: '/sim-ordering',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.simordering_rw',
				'mnc.simordering_ro',
			])(SimOrderingView)
		)
	},
	{
		path: '/sim-ordering/order/:id?',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(
				['mnc.simordering_rw', 'mnc.simordering_ro'],
				'/forbidden-access'
			)(SimOrderEditView)
		)
	},
	{
		path: '/company-sim-ordering',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated([
				'system.managesimorders_ro',
				'system.managesimorders_rw'
			])(SimOrderingView)
		),
		customProps: {
			system: true
		}
	},
	{
		path: '/company-sim-ordering/order/:id?',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated([
				'system.managesimorders_ro',
				'system.managesimorders_rw'
			], '/forbidden-access')(SimOrderEditView)
		),
		customProps: {
			system: true
		}
	},
];

export default simOrderingRoutes;
