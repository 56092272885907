import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';

import SelectorMultiple from '../../../../../lib/DigitalComponents/SelectorMultiple';
import Loader from '../../../../../lib/DigitalComponents/Loader';

import styles from './Step.scss';

const StepThird = ({
	filters,
	onNext,
	currentStep,
	shouldRefresh,
	isCycle,
	filtersPending,
	stateFilters
}) => {
	if (!filters && !filtersPending) {
		return (
			<div data-spec="analytics-step-third-category-not-chosen">
				<span>
					<FormattedMessage
						id="ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL"
						defaultMessage="Please select a category in the first step"
					/>
				</span>
			</div>
		);
	}

	if (filtersPending) {
		return <Loader data-spec="analytics-modal-step-third-loader" />;
	}

	return (
		<div data-spec="analytics-step-third" className={styles.steps_container}>
			<FormSection name="filtersForm">
				{filters &&
					Object.keys(filters)
						.filter((item) => (isCycle ? item !== 'platform' : item))
						.map((item) =>
							(filters[item].length > 0 ? (
								<div key={item} data-spec="filter-data">
									<div className={styles.steps_header}>
										<FormattedMessage
											id={`ANALYTICS.${item.toUpperCase()}`}
											defaultMessage={`${item.toUpperCase()}`}
										/>
									</div>
									<div
										className={`${styles.fetch_data_wrapper} ${styles.margin_top_bottom_10}`}
									>
										{filters[item].map((filter) => {
											const isLastActiveFilter = stateFilters[item]
												? Object.keys(stateFilters[item])
													.map((key) => stateFilters[item][key])
													.filter((stateFilter) => stateFilter).length === 1
												: false;

											return (
												<div
													data-spec="radio-button"
													key={filter}
													className={
														isLastActiveFilter &&
														stateFilters[item] &&
														stateFilters[item][filter]
															? styles.lastActiveFilter
															: ''
													}
												>
													<FormSection name={item}>
														<FormattedMessage
															id="ANALYTICS.FILTER_LABEL"
															defaultMessage={filter}
															values={{ filter: filter || ' ' }}
														>
															{(formattedValue) => (
																<Field
																	component={SelectorMultiple}
																	name={filter}
																	label={formattedValue}
																	value={filter}
																	type="checkbox"
																	onChange={(event, newValue, oldValue) => {
																		if (
																			isLastActiveFilter &&
																			oldValue === true &&
																			newValue === false
																		) {
																			event.preventDefault();
																		} else {
																			onNext();
																			if (currentStep > 3) {
																				shouldRefresh();
																			}
																		}
																	}}
																/>
															)}
														</FormattedMessage>
													</FormSection>
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<div data-spec="analytics-step-third-no-filters" key={item}>
									<div className={styles.steps_header}>
										<FormattedMessage
											id={`ANALYTICS.${item.toUpperCase()}`}
											defaultMessage={`${item.toUpperCase()}`}
										/>
									</div>
									<div className={styles.steps_no_data}>
										<FormattedMessage
											id="ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL"
											defaultMessage="No filters available for this category"
										/>
									</div>
								</div>
							)))}
			</FormSection>
		</div>
	);
};

const { func, number, bool, objectOf, array, object } = PropTypes;

StepThird.propTypes = {
	onNext: func,
	filters: objectOf(array),
	currentStep: number,
	shouldRefresh: func,
	isCycle: bool,
	filtersPending: bool,
	stateFilters: objectOf(object)
};

StepThird.defaultProps = {
	filters: {},
	currentStep: 0,
	isCycle: false,
	filtersPending: false,
	stateFilters: {},
	onNext: undefined,
	shouldRefresh: undefined
};

export default StepThird;
