export const GET_ANALYTICS_ATTRIBUTES = 'GET_ANALYTICS_ATTRIBUTES';
export const GET_ANALYTICS_CATEGORIES = 'GET_ANALYTICS_CATEGORIES';
export const GET_ANALYTICS_CHART_DATA_REQUEST =
	'GET_ANALYTICS_CHART_DATA_REQUEST';
export const GET_ANALYTICS_CHART_DATA_SUCCESS =
	'GET_ANALYTICS_CHART_DATA_SUCCESS';
export const GET_ANALYTICS_CHART_DATA_ERROR = 'GET_ANALYTICS_CHART_DATA_ERROR';
export const GET_ANALYTICS_DIRECTIONAL_INDEX_REQUEST =
	'GET_ANALYTICS_DIRECTIONAL_INDEX_REQUEST';
export const GET_ANALYTICS_DIRECTIONAL_INDEX_SUCCESS =
	'GET_ANALYTICS_DIRECTIONAL_INDEX_SUCCESS';
export const GET_ANALYTICS_DIRECTIONAL_INDEX_ERROR =
	'GET_ANALYTICS_DIRECTIONAL_INDEX_ERROR';
export const GET_ANALYTICS_FILTERS = 'GET_ANALYTICS_FILTERS';
