import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../Accounts';

const Desktop = (props) => {
	const { accounts, getOptions, messages, expanded } = props;
	return (
		<div data-spec="accounts-desktop">
			<Table
				data-spec="accounts-table-desktop"
				data={accounts.resultList}
				options={getOptions('desktop')}
				messages={messages}
				expanded={expanded}
			/>
		</div>
	);
};

const { object, func, array } = PropTypes;

Desktop.propTypes = {
	accounts: object,
	getOptions: func,
	messages: object,
	expanded: array
};

export default hoc()(Desktop);
