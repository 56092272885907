export default {
	'REPORTS.REPORT': '报告',
	'REPORTS.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'REPORTS.M2M_PLATFORM': '机对机（M2M）平台',
	'REPORTS.FREQUENCY': '频率',
	'REPORTS.REPORT_DATE': '报告日期',
	'REPORTS_TABS.ENDPOINTS': '端点',
	'REPORTS_TABS.USAGE_SUMMARY': '用量总结',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': '转移活动',
	'REPORTS_TABS.ACCOUNT_SUMMARY': '账户概况',
	'REPORTS_TABS.BILLING_DETAILS': '账单详情',
	'REPORTS_TABS.REFERENCE_REPORTS': '参考报告',
	'REPORTS_TABS.MY_EXPORTS': '我的导出',
	'REPORTS_TABS.USAGE_REPORTS': '使用报告',
	'REPORTS_TABS.REPOSTED_REPORTS': '转发报告',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': '没有可用的报告',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': '本表格目前无可显示的报告',
	'REPORTS.FILE_NAME': '文件名',
	'REPORTS.FILE_DATE': '文件日期',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': '被要求'
};
