export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Acciones de terminales múltiples',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Seleccione dos o más terminales',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': 'Aplicar un filtro o más para habilitar la función de exportación',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': 'Terminales seleccionadas actualmente asignadas',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': 'Elegible para utilizar con terminales seleccionadas',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': 'No se pudo solicitar con éxito el cambio de permisos de red SIM múltiples',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': 'Permiso de red SIM actualizado con éxito',
	'ENDPOINTS.VIEW_NOTE': 'Ver nota',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'No hay terminales disponibles.',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': 'No hay Terminales para esta tabla para mostrarle ahora',
	//  EDITAR MODAL DE TABLA
	'ENDPOINTS.TABLE_NAME': 'Nombre de tabla',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Editar nombre de vista de tabla',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Ingresar nombre de tabla',
	'ENDPOINTS.EDIT_TABLE': 'Editar parámetros de tabla',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Editar etiquetas de campo personalizado',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Editar parámetros de tabla',
	'ENDPOINTS.APPLY_CHANGE': 'Aplicar cambio',
	'ENDPOINTS.DELETE_TAB': 'Borrar pestaña',
	'ENDPOINTS.CUSTOM_LABEL': 'Etiqueta personalizada',
	'ENDPOINTS.CLEAR_ALL': 'Restablecer a predeterminado',
	'ENDPOINTS.DEVICE_TYPE': 'Tipo de equipo',
	'ENDPOINTS.BULK_UPLOAD': 'Subida masiva',

	//  'EDITAR PARÁMETROS DE TABLA',
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': 'Seleccionar un mín. de 2 para aplicar a la tabla. ',
	'ENDPOINTS.CELLULAR_COLUMNS': 'Parámetros específicos de celular',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Parámetros específicos de satélite',

	//  'PARÁMETROS ESPECÍFICOS DE CELULAR',
	'ENDPOINTS.M2MACCOUNTID': 'ID de cuenta M2M',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': 'Estado',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': 'Operador de red',
	'ENDPOINTS.NETWORK_TYPE': 'Tipo de red',
	'ENDPOINTS.IMSI_TRANSFER': 'Transferencia de estado IMSI',
	'ENDPOINTS.RATE_PLAN': 'Plan de tarifas',
	'ENDPOINTS.RECREDENTIAL_STATUS': 'Estado de reacreditación',
	'ENDPOINTS.ICCID': 'ICCID',
	'ENDPOINTS.SERVICE_PROVIDER': 'Plataforma M2M',
	'ENDPOINTS.MSISDN': 'MSISDN',
	'ENDPOINTS.COUNTRY_OPERATOR': 'País de red',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'ID de permiso de red',
	'ENDPOINTS.OEM': 'Cuentas suscritas',
	'ENDPOINTS.SERVICE_STATUS': 'Estado de 360L',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': 'País de roaming',
	'ENDPOINTS.ROAMING_CARRIER': 'Operador de roaming',
	'ENDPOINTS.M2M_DATE_ADDED': 'Fecha agregada a M2M',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'Fecha de activación de M2M',
	'ENDPOINTS.NOTES': 'Notas',
	'ENDPOINTS.CTD_DATA_USAGE': 'CTD de uso de datos',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'CTD de uso de SMS',
	'ENDPOINTS.CTD_VOICE_USAGE': 'CTD de uso de voz',
	'ENDPOINTS.INSESSION': 'En sesión',

	//  'PARÁMETROS ESPECÍFICOS DE SATÉLITE',
	'ENDPOINTS.M2M_ACC_ID': 'ID de cuenta M2M',
	'ENDPOINTS.DEVICE_NAME': 'Nombre de equipo',
	'ENDPOINTS.DEVICE_ID': 'ID de equipo',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': 'Núm. de modelo de equipo',

	//  PLAN DE TARIFAS FIJAR MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': 'Hay un error en la plataforma subyacente y el cambio del plan de tarifas no está disponible en este momento.',
	'ENDPOINTS.RATE_PLAN_CHANGE': 'Cambio en plan de tarifas',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'Eligió cambiar el plan de tarifas a',
	'ENDPOINTS.TAP_INITIATE_CHANGE': 'Toque ’Iniciar cambio’ para realizar acción inmediata.',
	'ENDPOINTS.CHANGE_LATER': 'También puede fijar el cambio para más adelante.',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': 'El cambio fijado sucederá al principio de la fecha que eligió.',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'FIJAR CAMBIO',
	'ENDPOINTS.INITIATE_CHANGE': 'Iniciar cambio',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Cambio de plan de tarifas fijado con éxito',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Cambio de plan de tarifas fijado con éxito para el',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': '¡El cambio de plan de tarifas no se pudo fijar con éxito!',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Fijar cambio de plan de tarifas',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. Confirmación de sistema',
	'ENDPOINTS.NOTIFICATION_ERROR': 'Fallo al suministrar la ID de terminal {endpointICCID} tras el cambio del plan de tarifas. Obtener asistencia',
	'ENDPOINTS.SCHEDULE_DATE': 'Fecha',
	'ENDPOINTS.SIM_STATE_CHANGE': 'Cambio en estado de SIM',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': 'Eligió cambiar estado de SIM a',
	'ENDPOINTS.STATUS_CHANGE_ERROR': 'El usuario {user} hizo un cambio de estado el {changeDate}',
	'ENDPOINTS.SCHEDULING': 'Fijando...',

	//  'ESTADO DE LA SIM',
	'ENDPOINTS.ACTIVE': 'Activado',
	'ENDPOINTS.TERMINATE': 'Cancelado',
	'ENDPOINTS.PROVISIONED': 'Suministrado',
	'ENDPOINTS.DEACTIVE': 'Desactivado',
	'ENDPOINTS.INACTIVE': 'Inactivo',
	'ENDPOINTS.TEST': 'Prueba',
	'ENDPOINTS.SUSPENDED': 'Suspendido',
	'ENDPOINTS.SUSPEND': 'Suspender',
	'ENDPOINTS.INVENTORY': 'Inventario',

	//  SIM STATE SCHEDULE CHANGE
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. Fijar cambio en estado de SIM',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'Fijar cambio en estado de SIM',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': '¡Cambio de estado de SIM fijado con éxito!',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Cambio de estado de SIM fijado con éxito para',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': '¡El cambio de estado de SIM no se pudo fijar con éxito!',
	'ENDPOINTS.PLEASE_TRY_AGAIN': 'Inténtelo nuevamente.',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': 'Fallo al suministrar la ID de terminal {endpointICCID} tras el cambio de estado de IMSI. Obtener asistencia',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE': 'El usuario {user} hizo un cambio de estado el {changeDate}',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS': 'El usuario {user} hizo un cambio de estado el {date}',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': '¡El cambio de estado de SIM múltiples no se pudo fijar con éxito!',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': '¡El cambio de estado de SIM múltiples no se pudo solicitar con éxito!',

	//  GENERAR INFORME MODAL
	'ENDPOINTS.CREATE_REPORT': 'Crear informe',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Crear informe personalizado',
	'ENDPOINTS.ENDPOINTS_ARE': 'terminales seleccionadas para exportar',
	'ENDPOINTS.ENDPOINT_IS': 'terminal seleccionada para exportar',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': 'El informe personalizado aparecerá en la sección de informes',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': 'Puede agregar aquí el nombre personalizado para el informe',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Apéndice de nombre de informe personalizado',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': 'Agregar apéndice de nombre de informe personalizado (solo dígitos y letras)',

	//  MULTI-ACTION MODAL
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. Seleccionar estado de SIM',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Confirmar cambios',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. Confirmación de sistema',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Seleccionar plan de tarifas',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1. Seleccionar permiso de red',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Cambiar notas',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2. Confirmación de sistema',
	'ENDPOINTS.CHANGE_SIM_STATE': 'Cambiar estado de SIM',
	'ENDPOINTS.CHANGE_RATE_PLAN': 'Cambiar plan de tarifas',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Cambiar permiso de red',
	'ENDPOINTS.CHANGE_NOTES': 'Notas',

	//  MULTI-ACTION NOTES MODAL
	'ENDPOINTS.SAVE_NOTE': 'Guardar notas',
	'ENDPOINTS.ADD_NOTE_BELOW': 'Agregar nota abajo: ',
	'ENDPOINTS.ENTER_NOTE_HERE': 'Ingresar nota aquí (máx. 2000 caracteres)',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Límite de caracteres superado',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'No puede tener solo espacios',
	'ENDPOINTS.CHANGING_NOTES': 'Cambiando notas',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': 'Terminales cambiadas con éxito',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': 'No se pudo iniciar con éxito el cambio de notas de varias terminales',
	'ENDPOINTS.SUCCESS_KEY': 'Éxito',
	'ENDPOINTS.ERROR': 'Error',
	'ENDPOINTS.REASON': 'motivo',
	'ENDPOINTS.ENDPOINTS_SELECTED': 'terminales seleccionadas',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'No se permite cambiar notas',

	//  'CAMBIAR PLAN DE TARIFAS',
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Seleccionar algunas SIM.',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'No se permite cambiar plan de tarifas',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'No hay planes de tarifas disponibles',
	'ENDPOINTS.SIMS_HAVE': 'SIM tienen',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': 'Elegir otro plan de tarifas para aplicar cambio a todas las SIM seleccionadas',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': ' no hay plan de tarifas seleccionado',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Confirmar cambio de plan de tarifas',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': 'Eligió cambiar plan de tarifas de las',
	'ENDPOINTS.SELECTED_SIMS_TO': 'SIM seleccionadas a',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': '¿Desea iniciar el cambio ahora?',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': 'O puede fijar el cambio para',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': 'Plan de tarifas de varias SIM fijadas con éxito',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'Cambio de plan de tarifas de SIM para',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': 'SIM fijadas con éxito para',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': 'Planes de tarifas de SIM actualizados con éxito',
	'ENDPOINTS.SIMS_SELECTED': 'SIM seleccionadas',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': 'El cambio de plan de tarifas de SIM múltiples no se pudo fijar con éxito',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': 'El cambio de plan de tarifas de SIM múltiples no se pudo solicitar con éxito',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Mismo plan de tarifas seleccionado',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': 'ID de terminales {endpointID} se suministró con éxito. El plan de tarifas cambió a ',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': 'ID de terminales {endpointID} fallo de suministro tras cambio en plan de tarifas. Obtener asistencia',

	//  'CAMBIAR ESTADO DE SIM',
	'ENDPOINTS.CURRENT': 'ACTUAL',
	'ENDPOINTS.NETWORK_TYPE_CHECK': 'Lamentablemente no se permite el cambio múltiple de estado de terminal para las terminales de tipos de red diferentes o proveedores de servicio diferentes.',
	'ENDPOINTS.SELECT_SIM_STATE': 'Seleccionar estado de SIM',
	'ENDPOINTS.CURRENT_STATES': 'Estados actuales',
	'ENDPOINTS.SELECTED_TIP': 'Consejo: Si seleccionó SIM que están en diferentes estados, solo podrá cambiar a un estado disponible para todas las SIM.',
	'ENDPOINTS.VIEW_RULES': 'Ver reglas de cambio de estado para esta cuenta',
	'ENDPOINTS.STATES_MESSAGE': 'No hay estado disponible',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'Confirmar cambio de estado de SIM',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': 'Al iniciar este cambio, las SIM seleccionadas serán',
	'ENDPOINTS.PROCEED_ACTION': '¿Desea continuar con esta acción?',
	'ENDPOINTS.CAN_SCHEDULE': 'O puede fijar el cambio para',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': 'ID de terminales {endpointID} se suministró con éxito. El estado de IMSI cambió a ',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': 'ID de terminales {endpointID} fallo al suministrar después del cambio de estado IMSI. Obtener asistencia',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': 'El usuario {user} hizo un cambio de estado el {date}',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL': '¡Cambio de estado de SIM múltiples fijado con éxito!',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'Cambio de estado de SIM para',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': '¡Cambio de estado de SIM solicitado con éxito!',

	//  GENERAR INFORME
	'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Informe personalizado creado con éxito',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': 'Error al tratar de descargar su archivo',
	'ENDPOINTS.GO_TO_REPORTS': 'Ir a informes',

	//  UPDATE CUSTOM LABELS
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': 'Una o más etiquetas de campo personalizado se actualizaron con éxito.',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': 'Una o más etiquetas de campo personalizado no se actualizaron en este momento. Inténtelo nuevamente.',

	//  Activation platform
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': 'DCP',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

	TECH_TYPE: 'Tipo de tecnología',
	TECH_TYPE_SUB_TYPE: 'Subcategoría de tipo de tecnología',
	'ENDPOINTS.TECH_TYPE': 'Tipo de tecnología',
	'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Subcategoría de tipo de tecnología',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Tipo de tecnología',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Subcategoría de tipo de tecnología'
};
