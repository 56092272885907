import React, { useState } from 'react';
import { object, number, func, bool, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Switch from '../../../../../lib/DigitalComponents/Switch';
// import Button from '../../../../../lib/DigitalComponents/Button';

import hoc from '../BlacklistedPermissions';

import styles from './Desktop.scss';
import { isUserAllowedToAccess } from '../../../../../utils/AuthSelector';

function PermissionItem({
	permission,
	indentLevel,
	editBlacklistedPermission,
	editBlacklistedPermissionRequest,
	getFieldValue,
	openRequestFeatureModal,
	companyId,
	openRequestedFeatureModal,
	openCancelRequestedFeatureModal,
	user
}) {
	const [isExpanded, setIsExpanded] = useState(
		permission.childPermission &&
			permission.childPermission.length > 0 &&
			!permission.parentPermissionId
	);

	const renderName = (val) => {
		const getRender = Boolean(
			val.childPermission && val.childPermission.length
		);
		const triggerIcon = cn({
			[styles.trigger__icon]: true,
			[styles.no_subpermissions]:
				val.childPermission && val.childPermission.length < 1,
			[styles.open]: isExpanded
		});

		const name = cn({
			[styles.level_one_indent]: indentLevel === 1,
			[styles.level_one_indent_no_children]: indentLevel === 1 && !getRender,
			[styles.level_two_indent]: indentLevel === 2
		});

		return (
			<div data-spec="name" className={styles.name_container}>
				{getRender && (companyId || (!companyId && !val.blacklisted)) && (
					<span
						onClick={() => setIsExpanded(!isExpanded)}
						className={triggerIcon}
					/>
				)}
				<div className={name}>{val.name}</div>
			</div>
		);
	};

	const getRowClass = (val) => {
		const getRender = Boolean(
			val.childPermission && val.childPermission.length
		);

		const level = cn({
			[styles.gray_row]: !getRender
		});

		return level;
	};

	const blacklistPermission = (id, blacklist) => {
		if (blacklist) {
			editBlacklistedPermission({ permissionId: id, blacklist, companyId });
		} else {
			editBlacklistedPermission({ permissionId: id, companyId });
		}
	};

	return (
		<>
			<tr className={getRowClass(permission)}>
				<td>{renderName(permission)}</td>
				<td>
					{companyId && (
						/* (!Object.prototype.hasOwnProperty.call(
							permission,
							'requestedPermission'
						) ||
							permission.requestedPermission.length < 1 ||
							(permission.requestedPermission[0] &&
								permission.requestedPermission[0].requestedPermissionStatus &&
								permission.requestedPermission[0].requestedPermissionStatus.code.toLowerCase() !==
									'requested')) &&  */
						<Field
							component={Switch}
							name={`${permission.id}-${permission.name}`}
							label={
								getFieldValue(`${permission.id}-${permission.name}`) ? (
									<FormattedMessage id="ACTIVE" defaultMessage="Active" />
								) : (
									<FormattedMessage id="INACTIVE" defaultMessage="Inactive" />
								)
							}
							onChange={() =>
								companyId &&
								blacklistPermission(
									permission.id,
									getFieldValue(`${permission.id}-${permission.name}`)
								)}
							disabled={
								!isUserAllowedToAccess(
									['system.accessmanagement.featureaccessmanagement_rw'],
									user
								)
							}
						/>
					)}

					{/* {companyId &&
						permission.requestedPermission &&
						permission.requestedPermission[0] &&
						permission.requestedPermission[0].requestedPermissionStatus &&
						permission.requestedPermission[0].requestedPermissionStatus.code.toLowerCase() ===
							'requested' && (
							<span
								onClick={() =>
									openRequestedFeatureModal({
										name: permission.name,
										id: permission.requestedPermission[0].id
									})}
							>
								<Field
									component={Switch}
									name={`${permission.id}-${permission.name}`}
									label={
										<FormattedMessage
											id={permission.requestedPermission[0].requestedPermissionStatus.name
												.toUpperCase()}
											defaultMessage={
												permission.requestedPermission[0]
													.requestedPermissionStatus.name
											}
										/>
									}
									checked
									disabled
								/>
							</span>
						)}
					{!companyId && !permission.blacklisted && (
						<Switch
							className={styles.active_permission}
							checked
							label={<FormattedMessage id="ACTIVE" defaultMessage="Active" />}
							disabled
						/>
					)}
					{!companyId &&
						permission.blacklisted &&
						((permission.requestedPermission[0] &&
							permission.requestedPermission[0].requestedPermissionStatus &&
							permission.requestedPermission[0].requestedPermissionStatus.code.toLowerCase() !==
								'requested') ||
							permission.requestedPermission.length < 1) && (
							<span
								onClick={() =>
									openRequestFeatureModal(permission.blacklistedPermissionId)}
							>
								<Switch
									className={styles.request_permission}
									checked
									label={
										<FormattedMessage
											id="ONBOARDING.REQUEST_PERMISSION"
											defaultMessage="Request Permission"
										/>
									}
									disabled
								/>
							</span>
						)}
					{!companyId &&
						permission.blacklisted &&
						permission.requestedPermission &&
						permission.requestedPermission[0] &&
						permission.requestedPermission[0].requestedPermissionStatus &&
						permission.requestedPermission[0].requestedPermissionStatus.code.toLowerCase() ===
							'requested' && (
							<span
								onClick={() =>
									openCancelRequestedFeatureModal({
										name: permission.name,
										id: permission.requestedPermission[0].id
									})}
							>
								<Switch
									className={styles.cancel_permission}
									checked
									label={
										<FormattedMessage
											id="ONBOARDING.CANCEL_REQUEST"
											defaultMessage="Cancel Request"
										/>
									}
									disabled
								/>
							</span>
						)} */}
				</td>
			</tr>

			{isExpanded && (
				<>
					{permission.childPermission &&
						permission.childPermission.map((item) => (
							<PermissionItem
								key={item.id}
								permission={item}
								indentLevel={indentLevel + 1}
								blacklistPermission={blacklistPermission}
								editBlacklistedPermission={editBlacklistedPermission}
								editBlacklistedPermissionRequest={
									editBlacklistedPermissionRequest
								}
								getFieldValue={getFieldValue}
								openRequestFeatureModal={openRequestFeatureModal}
								companyId={companyId}
								openRequestedFeatureModal={openRequestedFeatureModal}
								openCancelRequestedFeatureModal={
									openCancelRequestedFeatureModal
								}
								user={user}
							/>
						))}
				</>
			)}
		</>
	);
}

function Desktop(props) {
	const {
		blacklistedPermissions,
		editBlacklistedPermission,
		editBlacklistedPermissionRequest,
		getFieldValue,
		openRequestFeatureModal,
		companyId,
		openRequestedFeatureModal,
		openCancelRequestedFeatureModal,
		user,
	} = props;
	return (
		<table data-spec="permissions-desktop" className={styles.permissions_table}>
			<thead>
				<tr>
					<th>
						<FormattedMessage
							id="ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS"
							defaultMessage="System-Level Entitlements"
						/>
					</th>
					<th>{}</th>
				</tr>
			</thead>
			<tbody>
				{blacklistedPermissions &&
					blacklistedPermissions.resultList.map((item) => (
						<PermissionItem
							key={item.id}
							permission={item}
							indentLevel={0}
							editBlacklistedPermission={editBlacklistedPermission}
							editBlacklistedPermissionRequest={
								editBlacklistedPermissionRequest
							}
							getFieldValue={getFieldValue}
							openRequestFeatureModal={openRequestFeatureModal}
							companyId={companyId}
							openRequestedFeatureModal={openRequestedFeatureModal}
							openCancelRequestedFeatureModal={openCancelRequestedFeatureModal}
							user={user}
						/>
					))}
			</tbody>
		</table>
	);
}

Desktop.propTypes = {
	blacklistedPermissions: object,
	editBlacklistedPermission: func,
	editBlacklistedPermissionRequest: bool,
	getFieldValue: func,
	openRequestFeatureModal: func,
	companyId: string,
	openRequestedFeatureModal: func,
	openCancelRequestedFeatureModal: func,
	user: object,
};

PermissionItem.propTypes = {
	permission: object,
	indentLevel: number,
	editBlacklistedPermission: func,
	editBlacklistedPermissionRequest: bool,
	getFieldValue: func,
	openRequestFeatureModal: func,
	companyId: string,
	openRequestedFeatureModal: func,
	openCancelRequestedFeatureModal: func,
	user: object,
};

let DesktopForm = reduxForm({
	form: 'editBlacklistedPermissionsForm',
	enableReinitialize: true
})(Desktop);

DesktopForm = connect((state, props) => {
	const selector = formValueSelector('editBlacklistedPermissionsForm');

	const getFieldValue = (field) => selector(state, field);
	let fields = {};
	const initialPermissions = props.blacklistedPermissions;
	if (initialPermissions && initialPermissions.resultList.length > 0) {
		initialPermissions.resultList.forEach((permissions) => {
			fields = {
				...fields,
				[`${permissions.id}-${permissions.name}`]: !permissions.blacklisted
			};
			if (
				permissions.childPermission &&
				permissions.childPermission.length > 0
			) {
				permissions.childPermission.forEach((item) => {
					fields = {
						...fields,
						[`${item.id}-${item.name}`]: !item.blacklisted
					};
					if (item.childPermission && item.childPermission.length > 0) {
						item.childPermission.forEach((newItem) => {
							fields = {
								...fields,
								[`${newItem.id}-${newItem.name}`]: !newItem.blacklisted
							};
						});
					}
				});
			}
		});

		return {
			initialValues: fields,
			getFieldValue
		};
	}
	return { fields };
})(DesktopForm);

export default hoc(DesktopForm);
