import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './DeleteModal.scss';

const UploadIcon = ImageSelector(CurrentContext.theme, 'svgs/alert-modal.svg');

class DeleteModal extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			checkboxClicked: false
		};
	}

	setCheckboxState = (data) => {
		this.setState({
			checkboxClicked: data.target.checked
		});
	};

	renderModalContent = () => {
		const { modalTitle, requestDeletion } = this.props;
		return (
			<div
				data-spec="automation-request-deletion-modal-content"
				className={styles.content}
			>
				<div className={styles.icon}>
					<UploadIcon />
				</div>
				<div className={styles.text}>
					<div className={styles.bolded_text}>{modalTitle}</div>
					<div>
						<FormattedMessage
							id="AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT"
							defaultMessage="Are you sure you want to do that?"
						/>
					</div>
					{requestDeletion && (
						<div className={styles.checkbox_part}>
							<Checkbox onChange={(data) => this.setCheckboxState(data)} />
							<span className={styles.checkbox_text}>
								<FormattedMessage
									id="AUTOMATION.REQUEST_DELETION_CONFIRMATION"
									defaultMessage="I am aware of the delay in the implementation process."
								/>
							</span>
						</div>
					)}
				</div>
			</div>
		);
	};

	renderButtons = () => {
		const { onClose, onConfirm, requestDeletion } = this.props;
		const { checkboxClicked } = this.state;
		return (
			<div
				data-spec="automation-change-state-modal-buttons"
				className={styles.buttons}
			>
				<div>
					<Button
						onClick={onClose}
						variant="link"
						type="button"
						label={
							<FormattedMessage
								id={'AUTOMATION.CANCEL'}
								defaultMessage={'Cancel'}
							/>
						}
					/>
					<span className={styles.confirmation_button}>
						<Button
							variant="primary"
							type="button"
							label={
								<FormattedMessage
									id={'AUTOMATION.ACCEPT'}
									defaultMessage={'Accept'}
								/>
							}
							onClick={() => {
								onConfirm();
								onClose();
							}}
							disabled={requestDeletion && !checkboxClicked}
						/>
					</span>
				</div>
			</div>
		);
	};

	render() {
		const { show, onClose, modalHeader } = this.props;
		return (
			<Modal
				data-spec="automation-request-deletion-modal"
				show={show}
				onClose={onClose}
			>
				<ModalHeader title={modalHeader} />
				<ModalBody className={styles.modal__body}>
					{this.renderModalContent()}
				</ModalBody>
				<ModalFooter data-spec="ds-modal-footer">
					{this.renderButtons()}
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, object } = PropTypes;

DeleteModal.propTypes = {
	show: bool,
	onClose: func,
	onConfirm: func,
	modalTitle: object,
	modalHeader: object,
	requestDeletion: bool
};

export default DeleteModal;
