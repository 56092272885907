import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './SideBarItem.scss';

const Caret = ImageSelector(CurrentContext.theme, 'svgs/arrow-down.svg');
const CaretRight = ImageSelector(CurrentContext.theme, 'svgs/arw-right.svg');

class SideBarItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showDropdownMenuItem: false,
			isSidebarMenuOpen: false
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	handleClickOutside = (event) => {
		const parentElement = event.target.parentElement
			? 'parentElement'
			: 'parentNode';
		const domNode = this.menu;
		if (
			(!domNode || !domNode.contains(event.target)) &&
			event.target.id !== 'sub-menu' &&
			event.target[parentElement] &&
			event.target[parentElement][parentElement] &&
			event.target[parentElement][parentElement].id !== 'sub-menu'
		) {
			this.setState({ isSidebarMenuOpen: false });
		}
	};

	renderSubmenus = (submenus) =>
		submenus.map(({ link, label, dataSpec, wrapper: Wrapper, target }) => {
			const { isCollapsed, setDataForFiltersInit, location } = this.props;

			const navLink = target ? (
				<a
					href={link}
					target={target}
					style={{ width: '100%' }}
					key={`mainNav${link}`}
				>
					{!isCollapsed && <div className={styles.icon} />}
					<span data-spec={dataSpec}>{label}</span>
				</a>
			) : (
				<NavLink
					exact
					data-spec={`mainNav${link}`}
					to={link}
					activeClassName={styles.active}
					key={`mainNav${link}`}
					onClick={async () => {
            if (location && location.pathname !== link) {
              await setDataForFiltersInit();
						}
          }}
				>
					{!isCollapsed && <div className={styles.icon} />}
					<span data-spec={dataSpec}>{label}</span>
				</NavLink>
			);

			return Wrapper ? (
				<Wrapper key={`sidebar-submenu-${link}`} data-spec="sidebar-submenu">
					{navLink}
				</Wrapper>
			) : (
				navLink
			);
		});

	isDropMenuActive = () => {
		const { location, submenu } = this.props;

		for (let i = 0; i < submenu.length; i += 1) {
			if (location.pathname.includes(submenu[i].link)) return true;
		}

		return false;
	};

	handleDropdownMenuItemClick = () => {
		const { showDropdownMenuItem } = this.state;
		this.setState({
			showDropdownMenuItem: !showDropdownMenuItem
		});
	};

	render() {
		const {
			link,
			icon: Icon,
			dataSpec,
			exact,
			alternateLink,
			label,
			submenu,
			isCollapsed,
      target,
      setDataForFiltersInit,
      location
		} = this.props;
    const { showDropdownMenuItem, isSidebarMenuOpen } = this.state;

		if (submenu && submenu.length > 0) {
			return (
				<li
					data-spec="sidebar-li"
					className={cn({
						[styles.selected_dropdown_collapsed]:
							this.isDropMenuActive() && isCollapsed,
						[styles.selected_dropdown]: this.isDropMenuActive() && !isCollapsed
					})}
					key={label}
				>
					<div
						className={cn(styles.lead_div, styles.menu_item)}
						onClick={() => !isCollapsed && this.handleDropdownMenuItemClick()}
						onMouseEnter={() =>
							isCollapsed && this.setState({ isSidebarMenuOpen: true })}
						onMouseLeave={() =>
							isCollapsed && this.setState({ isSidebarMenuOpen: false })}
					>
						<div
							className={cn(
								styles.icon,
								isCollapsed ? '' : styles.submenu_indent
							)}
						>
							{Icon}
						</div>
						{!isCollapsed && (
							<span
								data-spec={dataSpec}
								className={styles.menu_item}
								style={{ margin: 0 }}
							>
								{label}
							</span>
						)}
						{!isCollapsed && showDropdownMenuItem ? (
							<div className={styles.carret_right_uncollapsed}>
								<Caret id="account-carret" />
							</div>
						) : (
							<div
								className={
									isCollapsed
										? styles.carret_right
										: styles.carret_right_uncollapsed
								}
							>
								<CaretRight id="account-carret" />
							</div>
						)}
						{isCollapsed && (
							<div
								className={cn(
									styles.collapsed_submenu,
									isSidebarMenuOpen ? '' : styles.hidden
								)}
								id="sub-menu"
							>
								{this.renderSubmenus(submenu)}
							</div>
						)}
					</div>
					{!isCollapsed && showDropdownMenuItem && this.renderSubmenus(submenu)}
				</li>
			);
		}

		const renderMenuItem = () => {
			const navLink = target ? (
				<a
					href={link}
					target={target}
				>
					<div className={styles.icon}>{Icon}</div>
					{!isCollapsed && <span data-spec={dataSpec}>{label}</span>}
				</a>
			) : (
				<NavLink
					exact={exact}
					to={alternateLink || link}
					activeClassName={styles.active}
					onClick={async () => {
						if (location && location.pathname !== link) {
              await setDataForFiltersInit();
						}
						showDropdownMenuItem && this.handleDropdownMenuItemClick();
					}}
				>
					<div className={styles.icon}>{Icon}</div>
					{!isCollapsed && <span data-spec={dataSpec}>{label}</span>}
				</NavLink>
			);

			return navLink;
		};
		return (
			<li
				role="menuitem"
				key={label}
				data-spec="sidebar-li"
				className={styles.menu_item}
			>
				{renderMenuItem()}
			</li>
		);
	}
}

const { string, element, object, bool, arrayOf, objectOf, func } = PropTypes;

SideBarItem.propTypes = {
	alternateLink: string,
	dataSpec: string,
	exact: bool,
	icon: element,
	isCollapsed: bool,
	label: element,
	link: string,
	location: objectOf(string),
	submenu: arrayOf(object),
  target: string,
  setDataForFiltersInit: func
};

SideBarItem.defaultProps = {
	alternateLink: '',
	dataSpec: '',
	exact: false,
	icon: null,
	isCollapsed: false,
	label: {},
	link: '',
	location: {},
	submenu: [],
  setDataForFiltersInit: undefined
};

export default SideBarItem;
