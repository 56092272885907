export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Categorias de gráficos',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Agrupar dados por',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filtros',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Opções do gráfico',
	'ANALYTICS.EDIT_CHART': 'Revisar tabela',
	'ANALYTICS.CREATE_NEW_CHART': 'Criar novo gráfico',
	'ANALYTICS.DISCLAIMER': 'Aviso de isenção de responsabilidade: ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': 'Os dados dos gráficos são fornecidos por sistemas múltiplos em diversos intervalos de tempo. Os dados nos gráficos fornecerão uma soma total aproximada. Os dados são representados no fuso horário UTC-0. Os dados finais relacionados aos serviços cobrados estarão disponíveis na fatura do ciclo de cobrança.',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Totais da contagem de endpoints',
	'ANALYTICS.NO_USAGE_SIMS': 'SIMs sem utilização',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Uso total de SMS',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Uso total de dados',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Uso total de voz',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Uso médio estimado',
	'ANALYTICS.NO_CATEGORIES': 'Não há nenhuma categoria disponível para esta conta',
	'ANALYTICS.LINE_GRAPH': 'Gráfico de linhas',
	'ANALYTICS.PIE_CHART': 'Gráfico de setores',
	'ANALYTICS.BAR_GRAPH': 'Gráfico de barra',
	'ANALYTICS.WORLD_MAP_GRAPH': 'Gráfico do mapa mundi',
	'ANALYTICS.PLATFORM': 'Plataforma',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Tecnologia de acesso',
	'ANALYTICS.OPERATOR': 'Operador',
	'ANALYTICS.RATEPLAN': 'Plano de tarifas',
	'ANALYTICS.COUNTRYCODE': 'País',
	'ANALYTICS.SIM_STATE': 'Estado do SIM',
	'ANALYTICS.DATE_RANGE': 'INTERVALO DE DATAS',
	'ANALYTICS.TODAY': 'Hoje',
	'ANALYTICS.PREVIOUS_DAY': 'Dia anterior',
	'ANALYTICS.LAST_10_DAYS': 'Últimos 10 dias',
	'ANALYTICS.LAST_30_DAYS': 'Últimos 30 dias',
	'ANALYTICS.CUSTOM_RANGE': 'Intervalo personalizado',
	'ANALYTICS.CYCLE': 'CICLO',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Ciclo atual até o momento',
	'ANALYTICS.HISTORICAL': 'Dados históricos',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'PLATAFORMA M2M',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'AUMENTAR POR',
	'ANALYTICS.DAY': 'Dia',
	'ANALYTICS.WEEK': 'Semana',
	'ANALYTICS.MONTH': 'Mês',
	'ANALYTICS.SHOW': 'MOSTRAR',
	'ANALYTICS.SUM_TOTALS': 'Totais da soma',
	'ANALYTICS.AVERAGE_TOTALS': 'Calcular média dos totais',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': 'Selecionar uma categoria na primeira etapa',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': 'Não há filtros disponíveis para esta categoria',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Personalizar os dados do gráfico',
	'ANALYTICS.EXPORT_CHART_DATA': 'Exportar dados do gráfico',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'Baixar PNG',
	'ANALYTICS.REMOVE_CHART': 'Remover gráfico',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Adicionar gráfico',
	'ANALYTICS.NO_ATTRIBUTES': 'Agrupar por atributos não está disponível para esta conta',
	'ANALYTICS.MOVING_AVERAGE': 'Média de movimentações',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Mostrar totais do resumo',
};
