export default {
	'TICKETING.YES': 'Ja',
	'TICKETING.NO': 'Nee',
	'TICKETING.VIEW_NOTE': 'Opmerking weergeven',
	'TICKETING.TICKET_ID': 'Ticket-id',
	'TICKETING.CATEGORY_NAME': 'Categorie',
	'TICKETING.DATE_CREATED': 'Gemaakt op',
	'TICKETING.CREATED_BY': 'Gemaakt door',
	'TICKETING.TICKETING_STATUS': 'Ticketstatus',
	'TICKETING.LAST_UPDATE': 'Laatste update',
	'TICKETING.SEVERITY': 'Ernst',
	'TICKETING.SUBMISSION_LOCATION': 'Verzendlocatie',
	'TICKETING.UPDATE_NOTE': 'Opmerking updaten',
	'TICKETING.STATUS': 'Status',
	'TICKETING.SUBMITED_BY': 'Verzonden door',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': 'Betreft uw ticket een bepaald eindpunt? Zo ja, ga dan naar het gegevensscherm voor het betreffende eindpunt en klik op de link ‘Ondersteuning vragen voor dit eindpunt’. In alle andere gevallen vult u het onderstaande formulier in.',
	'TICKETING.ISSUE': 'Probleem',
	'TICKETING.TICKET_DETAILS': 'Ticketgegevens',
	'TICKETING.CATEGORY': 'Categorie',
	'TICKETING.SELECT_CATEGORY': 'Selecteer een categorie',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Subcategorie (optioneel)',
	'TICKETING.SUBCATEGORY': 'Subcategorie',
	'TICKETING.ROAMING_ISSUE': 'Roamingprobleem',
	'TICKETING.PRIORITY': 'Prioriteit',
	'TICKETING.ENDPOINT': 'Eindpunt',
	'TICKETING.ICCID': 'ICCID',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'MSISDN',
	'TICKETING.ENDPOINT_LOCATION': 'Locatie eindpunt',
	'TICKETING.ENDPOINT_COUNTRY': 'Land eindpunt',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Eindpuntparameters wissen',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Eindpuntparameters ophalen',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': 'U kunt deze optie gebruiken om de eindpuntparameters op te halen door ofwel de ICCID- of IMSI- of MSISDN-waarde in te vullen. ',
	'TICKETING.ENDPOINT_STREET': 'Straat eindpunt',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Adres eindpunt',
	'TICKETING.ZIP_CODE': 'Postcode',
	'TICKETING.ENDPOINT_STATE': 'Provincie eindpunt',
	'TICKETING.DETAILS': 'Gegevens',
	'TICKETING.BARS_SHOWN': 'Getoonde staven',
	'TICKETING.ENDPOINT_CITY': 'Plaatsnaam eindpunt',
	'TICKETING.ENDPOINT_MODEL': 'Model eindpunt',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': 'Hebt u probleemoplossing uitgevoerd?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Beschrijving van het probleem',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': 'Geef in maximaal 255 tekens een beschrijving van het probleem',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': 'Verstrek zo nodig in maximaal 2000 tekens aanvullende gegevens over het probleem',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Opmerkingen',
	'TICKETING.ISSUE_DETAILS': 'Gegevens probleem',
	'TICKETING.CUSTOMER': 'Klant',
	'TICKETING.ACCOUNT_NUMBER': 'Accountnummer',
	'TICKETING.ACCOUNT_NAME': 'Naam account',
	'TICKETING.PHONE': 'Telefoon',
	'TICKETING.CONTACT_PHONE': 'Telefoon contactpersoon',
	'TICKETING.CONTACT_EMAIL': 'E-mail contactpersoon',
	'TICKETING.ATTACH_FILE': 'Bestand bijvoegen',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': 'Bestandsnaam is te lang om te uploaden. De bestandsnaam mag maximaal 25 tekens bevatten.',
	'TICKETING.EMAIL': 'E-mail',
	'TICKETING.TICKETING': 'Ticketsysteem',
	'TICKETING.ADD_TICKET': 'Ticket toevoegen',
	'TICKETING.SEARCH_RESULTS': 'Zoekresultaten',
	'TICKETING.NO_TICKETS_AVAILABLE': 'Geen tickets beschikbaar',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': 'Instellingen tickettabel zijn gewijzigd!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': 'De instellingen van de tickettabel zijn geüpdatet.',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': 'Fout tijdens kolomwijziging tickettabel!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': 'Kan de kolommen van de tickettabel op dit moment niet updaten. Probeer het later opnieuw.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'Ticket is bewerkt.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': 'Fout tijdens bewerken van ticket. Probeer het later opnieuw.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'Bestandsnaam mag geen spaties bevatten.',
	'TICKETING.NEW_TICKET': 'Nieuw ticket',
	'TICKETING.OFF': 'Uit',
	'TICKETING.ON': 'Aan',
	'TICKETING.EMAIL_NOTIFICATIONS': 'E-mailmeldingen',
	'TICKETING.CONTACT_NAME': 'Naam contactpersoon',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Laatste update door',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Datum/tijd laatste update',
	'TICKETING.SOMETHING_WENT_WRONG': 'Er is iets misgegaan, probeer het opnieuw.',
	'TICKETING.CANCEL_TICKET': 'Ticket annuleren',
	'TICKETING.ADD_NOTE': 'Opmerking toevoegen',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'Ticket is verzonden!',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': 'Kan ticket niet verzenden!',
	'TICKETING.CREATED_AT': 'Gemaakt op',
	'TICKETING.GO_BACK_TO_TICKETING': 'Terug naar ticketsysteem',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': 'Niet-technisch factureringsprobleem',
	'TICKETING.CATEGORY_PROVISIONING': 'Inrichting',
	'TICKETING.CATEGORY_DATA': 'Data',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Documentatie',
	'TICKETING.CATEGORY_DUPLICATE': 'Duplicaat',
	'TICKETING.CATEGORY_BILLING': 'Facturering',
	'TICKETING.CATEGORY_NOTIFICATION': 'Melding',
	'TICKETING.CATEGORY_OTHER': 'Overig',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': 'Aanvraag productuitbreiding',
	'TICKETING.CATEGORY_PROJECT': 'Project',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Probleem met verkoop/marketing',
	'TICKETING.CATEGORY_QUESTION': 'Vraag',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Serviceaanvraag',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Technische problemen',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': 'Gebruiker-/accountbeheer',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Connectiviteitsprobleem',
	'TICKETING.CATEGORY_EQUIPMENT': 'Apparatuur',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'Algemene vraag',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Verloren/gestolen apparaat',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Poortaanvraag',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'Productgerelateerd',
	'TICKETING.CATEGORY_REPORTING': 'Rapporten',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Suggesties/aanbevelingen',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Websiteprobleem',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': 'In wachtrij',
	'TICKETING.STATUS_ACTIVE': 'Actief',
	'TICKETING.STATUS_DEFERRED': 'Uitgesteld',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'In afwachting van reactie klant',
	'TICKETING.STATUS_CLOSED': 'Gesloten',
	'TICKETING.STATUS_CANCEL': 'Annuleren',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Door klant betwist',
	'TICKETING.STATUS_CLEARED': 'Opgelost',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Kan worden gesloten',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'In afwachting van reactie ATT',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': 'Kritiek',
	'TICKETING.SEVERITY_MAJOR': 'Zeer ernstig',
	'TICKETING.SEVERITY_MINOR': 'Minder ernstig',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': 'Activeringskosten',
	'TICKETING.AIRTIME_USAGE': 'Airtime-verbruik',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Algemene accountvragen',
	'TICKETING.BILLING_DISPUTES': 'Betwiste facturering',
	'TICKETING.BILLING_FILES_ISSUES': 'Problemen met factureringsbestanden',
	'TICKETING.BILL_RECONCILIATION': 'Verrekening factuur',
	'TICKETING.CUSTOMER_EDUCATION': 'Educatie klant',
	'TICKETING.DATA_USAGE_CANADA': 'Dataverbruik Canada',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Internationaal dataverbruik',
	'TICKETING.DATA_USAGE_OFF_NET': 'Dataverbruik off-net',
	'TICKETING.DATA_USAGE_ON_NET': 'Dataverbruik on-net',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Vertraagde facturering of factuur',
	'TICKETING.DISCOUNT': 'Korting',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Vraag of probleem m.b.t. fraude',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Printproblemen met facturen',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Vaste maandkosten',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'Eenmalige kosten - correctie',
	'TICKETING.OTHER': 'Overig',
	'TICKETING.PAYMENT_ISSUES': 'Betalingsprobleem',
	'TICKETING.PRORATED_CHARGES': 'Pro rata kosten',
	'TICKETING.REACTIVATION_FEE': 'Kosten heractivering',
	'TICKETING.SMS_TEXT_CANADA': 'Sms/tekst Canada',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'Internationale sms/tekst',
	'TICKETING.SMS_TEXT_OFF_NET': 'Sms/tekst off-net',
	'TICKETING.SMS_TEXT_ON_NET': 'Sms/tekst on-net',
	'TICKETING.STATUS_INVOICE': 'Status factuur',
	'TICKETING.TAXES_AND_SURCHARGES': 'Belasting en toeslagen',
	'TICKETING.DATA': 'Data',
	'TICKETING.MESSAGING_SMS_MMS_': 'Berichten (SMS/MMS)',
	'TICKETING.VOICE': 'Voice',
	'TICKETING.CLARIFICATION': 'Verklaring',
	'TICKETING.PREPAID': 'Prepaid',
	'TICKETING.USAGE_INQUIRY': 'Informatieverzoek verbruik',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': 'Vragen over facturering',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Probleem met factuur',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Probleem met tariefplan',
	'TICKETING.USAGE_RELATED_ISSUE': 'Probleem met verbruik',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': 'Mix van 2G en 3G',
	'TICKETING.2G_ONLY': 'ALLEEN 2G',
	'TICKETING.3G_ONLY': 'ALLEEN 3G',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Verbonden, maar geen data',
	'TICKETING.FRAME_RELAY_PVC': 'Frame Relay/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Latentieprobleem',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Roaming/eigen land',
	'TICKETING.ROAMING_INTERNATIONAL': 'Roaming/internationaal',
	'TICKETING.SIGNAL_ISSUES': 'Signaalproblemen',
	'TICKETING.SMPP_BIND': 'SMPP bind',
	'TICKETING.SMS': 'Sms',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': 'Feedback',
	'TICKETING.NEW_REQUEST': 'Nieuwe aanvraag',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': 'Compatibiliteit',
	'TICKETING.DEVICE_UNLOCK': 'Apparaat ontgrendelen',
	'TICKETING.ORDERING': 'Bestellen',
	// TICKETING LOST/STOLEN DEVICE SUBCATEGORIES
	'TICKETING.BLOCK_DEVICE': 'Apparaat blokkeren',
	'TICKETING.FRAUD_REPORT': 'Frauderapport',
	'TICKETING.UNBLOCK_DEVICE': 'Apparaat deblokkeren',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': 'Melding incident',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Gepland onderhoud',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Interface netwerkkanaal (NCI)',
	'TICKETING.WIRELESS': 'Draadloos',
	'TICKETING.WIRELINE': 'Wireline',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': 'Vraag',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': 'Batchbestanden',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Nieuw SIM-profiel maken',
	'TICKETING.EQUIPMENT_INQUIRY': 'Informatie over apparatuur',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Inrichting één SIM',
	'TICKETING.WEB_SERVICE': 'Webservice',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'Inrichting SIM’s/functies',
	'TICKETING.ACTIVATION': 'Activering',
	'TICKETING.BATCH_BULK_CHANGE': 'Batch-/bulkwijziging',
	'TICKETING.CALL_FORWARDING': 'Doorschakelen',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Communicatieplan wijzigen',
	'TICKETING.CHANGE_RATE_PLAN': 'Wijzig tariefplan',
	'TICKETING.CHANGE_SIM_STATUS': 'Wijzig SIM-status',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'SIM tussen accounts overdragen',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Vraag over automatiseringsregel',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Vraag over Control Center',
	'TICKETING.COVERAGE_QUESTION': 'Vragen over bereik',
	'TICKETING.GENERAL_QUESTION': 'Algemene vraag',
	'TICKETING.OUTAGE_QUESTION': 'Vraag over storing',
	'TICKETING.ROAMING_QUESTION': 'Vraag over roaming',
	'TICKETING.JPO_COVERAGE': 'JPO-bereik',
	//  TICKETING SALES/MARKETING ISSUE SUBCATEGORIES
	'TICKETING.CUSTOMER_INQUIRY': 'Informatie over klant',
	'TICKETING.DEV_KIT': 'Ontwikkelaarspakket',
	'TICKETING.MARKETING_INQUIRY': 'Informatie over marketing',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': 'Beëindiging account',
	'TICKETING.API_SETUP': 'API-configuratie',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': 'Factureringszone maken/wijzigen',
	'TICKETING.CC_ACCESS_UPDATES': 'Toegang/updates CC',
	'TICKETING.COMMUNICATION_PLAN': 'Communicatieplan',
	'TICKETING.CUSTOM_APN': 'Aangepaste APN',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Algemene configuratie/toewijzing SIM',
	'TICKETING.GPRS_VPN': 'GPRS VPN',
	'TICKETING.LEGAL_SUBPOENA': 'Juridisch/dagvaarding',
	'TICKETING.LTE_MIGRATION': 'LTE-migratie',
	'TICKETING.MSISDN_UPDATES': 'MSISDN-updates',
	'TICKETING.NEW_ACCOUNT': 'Nieuw account',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Instellen config radius',
	'TICKETING.REPORT': 'Rapport',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES (aanvraag roamingzone)',
	'TICKETING.SECURESIM': 'Beveiligde SIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'Bestellen/inrichten SIM',
	'TICKETING.TRACE': 'Traceren',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': 'Problemen met applicatie',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Technisch probleem met facturering',
	'TICKETING.CONNECTIVITY': 'Connectiviteit',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Algemeen probleem SIM',
	'TICKETING.JPO_FILE_ISSUE': 'Probleem met JPO-bestand',
	'TICKETING.JPO_LINK_ISSUE': 'Probleem met JPO-link',
	'TICKETING.NOC_BRIDGE': 'NOC-brug',
	'TICKETING.CDR_FILES': 'CDR-bestanden',
	'TICKETING.JPO_REPORT': 'JPO-rapport',
	//  TICKETING USER/ACCOUNT ADMINISTRATION SUBCATEGORIES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': 'Functionaliteitsopties toevoegen aan nieuwe account',
	'TICKETING.DEACTIVATE_USER': 'Gebruiker deactiveren',
	'TICKETING.ID_ISSUE': 'Id-probleem',
	'TICKETING.NEW_USER': 'Nieuwe gebruiker',
	'TICKETING.PASSWORD_RESET': 'Wachtwoord resetten',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'AT&T Control Center (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Verbonden autopoort/Apollo',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise on Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	// OTHER
	'TICKETING.CRITICAL': 'Kritiek',
	'TICKETING.MAJOR': 'Zeer ernstig',
	'TICKETING.MINOR': 'Minder ernstig',
	'TICKETING.LOW': 'Laag',
	'TICKETING.MEDIUM': 'Medium',
	'TICKETING.HIGH': 'Hoog',
	'TICKETING.OUTAGE': 'Storing',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': 'Dynamisch IP-adres',
	'TICKETING.FIXED_IP': 'Vast IP-adres',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Automatiseringsregel',
	'TICKETING.CC_ISSUE_OTHER': 'CC-probleem - Overig',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Exporteren en batch-update',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Locatievoorzieningen/derden',
	'TICKETING.PROVISIONING': 'Inrichting',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Vertraging sessie-informatie',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': 'Problemen met WebUI',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': 'Dataprobleem',
	'TICKETING.DEVICE_ISSUE': 'Apparaatprobleem',
	'TICKETING.SFTP_FILE_TRANSFERS': 'SFTP/bestandsoverdracht',
	'TICKETING.SMS_ISSUE': 'SMS-probleem',
	'TICKETING.VOICE_ISSUE': 'Voice-probleem',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': 'Netwerk',
	'TICKETING.RADIUS': 'Radius',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Bijlage toevoegen',
	'TICKETING.NOTE': 'Opmerking',
	'TICKETING.LAST_UPDATED_DATE': 'Datum laatste ticketupdate',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': 'Er bestaat geen eindpunt met die parameter.',
	// meldingen ticketsysteem
	'TICKETING.ATTACHMENT_FAIL_TITLE': 'Kan uw aanvraag niet volledig voltooien.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': 'Ticket is gemaakt {ticketId}, maar de bijlagen({attachments}) zijn niet geüpload',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': 'Ticket is bewerkt {ticketId}, maar de bijlagen({attachments}) zijn niet geüpload.',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Ticket is bewerkt {ticketId}, maar de bijlagen({attachments}) zijn niet verwijderd.',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Ticket is bewerkt {ticketId}, maar de bijlagen ({deleteAttachments}) zijn niet verwijderd en bijlagen ({uploadAttachments}) zijn niet geüpload.'
};
