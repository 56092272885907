import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

export const required = (value) =>
	typeof value !== 'number' &&
	(!value || (value && typeof value !== 'object' && value.trim() === '')) && (
		<FormattedMessage id="REQUIRED" defaultMessage="Required" />
	);

export const requiredCheckbox = (value) =>
	typeof value !== 'number' &&
	!value && <FormattedMessage id="REQUIRED" defaultMessage="Required" />;

export const email = (value) =>
	value &&
	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) && (
		<FormattedMessage
			id="INVALID_EMAIL_ADDRESS"
			defaultMessage="Invalid email address"
		/>
	);

export const onlyLetters = (value) =>
	value &&
	!/^[A-Za-z]*$/i.test(value) && (
		<FormattedMessage
			id="ONLY_LETTERS_ARE_ALLOWED"
			defaultMessage="Only letters are allowed"
		/>
	);

export const onlyLettersAndSpaces = (value) =>
	value &&
	!/^[A-Za-z ]*$/i.test(value) && (
		<FormattedMessage
			id="ONLY_LETTERS_AND_SPACES_ARE_ALLOWED"
			defaultMessage="Only letters and spaces are allowed"
		/>
	);

export const onlyNumbers = (value) =>
	Number.isNaN(Number(value)) && (
		<FormattedMessage
			id="ONLY_NUMBERS_ARE_ALLOWED"
			defaultMessage="Only numbers are allowed"
		/>
	);

export const ipAddress = (value) =>
	!value.match(
		/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i
	) && (
		<FormattedMessage
			id="INVALID_IP_ADDRESS"
			defaultMessage="Invalid IP Address"
		/>
	);

export const ipAddressWithMask = (value) =>
	!value.match(
		/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/i
	) && (
		<FormattedMessage
			id="INVALID_IP_ADDRESS"
			defaultMessage="Invalid IP Address"
		/>
	);

export const url = (value) =>
	!value.match(
		/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/i
	) && (
		<FormattedMessage
			id="INVALID_URL_FORMAT"
			defaultMessage="Invalid URL format"
		/>
	);

export const port = (value) =>
	!value.match(
		/^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/i
	) && (
		<FormattedMessage
			id="INVALID_PORT_NUMBER"
			defaultMessage="Invalid PORT number"
		/>
	);

export const onlyNumbersWithoutSpaces = (value) =>
	!value.match(/^[0-9]+$/) && (
		<FormattedMessage
			id="ONLY_NUMBERS_ARE_ALLOWED"
			defaultMessage="Only numbers are allowed"
		/>
	);

export const phoneNumber = (value) =>
	value &&
	Number.isNaN(Number(value)) && (
		<FormattedMessage
			id="PHONE_MUST_BE_A_NUMBER"
			defaultMessage="Phone must be a number"
		/>
	);

export const phoneFormat = (value) =>
	value &&
	!/^(\+|00)([0-9-]{1,3})([0-9-]{1,16})$/i.test(value) && (
		<FormattedMessage
			id="WRONG_PHONE_FORMAT"
			defaultMessage="Wrong phone format"
		/>
	);

export const phoneFormatWithoutPlus = (value) =>
	value &&
	!/^([0-9-]{1,16})$/i.test(value) && (
		<FormattedMessage
			id="WRONG_PHONE_FORMAT"
			defaultMessage="Wrong phone format"
		/>
	);

export const domain = (value) =>
	value &&
	!/^@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) && (
		<FormattedMessage
			id="INVALID_DOMAIN_FORMAT"
			defaultMessage="Invalid domain format"
		/>
	);

export const domainWithAllLetters = (value) =>
	value &&
	!/^[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/i.test(value) && (
		<FormattedMessage
			id="INVALID_DOMAIN_FORMAT"
			defaultMessage="Invalid domain format"
		/>
	);

export const urlFormat = (value) =>
	value &&
	!/^(http:\/\/|https:\/\/)+[a-z0-9.-]+?$/i.test(value) && (
		<FormattedMessage
			id="INVALID_URL_FORMAT"
			defaultMessage="Invalid URL format"
		/>
	);

export const maxLength = (max) => (value) =>
	value &&
	value.length > max && (
		<FormattedMessage
			id="MUST_BE_LESS_THAN_OR_EQUAL_TO_CHARACTERS"
			defaultMessage="Must be less than or equal to {max} characters"
			values={{ max }}
		>
			{(formattedValue) => formattedValue}
		</FormattedMessage>
	);

export const exactLength = (number) => (value) =>
	value &&
	value.length !== number && (
		<FormattedMessage
			id="MUST_BE_CHARACTERS_LONG"
			defaultMessage="Must be {number} characters long"
			values={{ number }}
		>
			{(formattedValue) => formattedValue}
		</FormattedMessage>
	);

export const rangeLength = (min, max) => (value) =>
	value &&
	(value.length > max || value.length < min) && (
		<FormattedMessage
			id="MUST_BE_BETWEEN_CHARACTERS_LONG"
			defaultMessage="Must be between {min} and {max} characters long"
			values={{ min, max }}
		>
			{(formattedValue) => formattedValue}
		</FormattedMessage>
	);

export const onlyPositiveNumbers = (value) =>
	Number(value) <= 0 && (
		<FormattedMessage
			id="ONLY_POSITIVE_NUMBERS_ARE_ALLOWED"
			defaultMessage="Only numbers greater than 0 are allowed"
		/>
	);

export const isLessThan = (prop, propName) => (value, allValues) =>
	Number(value) > Number(allValues[prop]) && (
		<FormattedMessage
			id="NUMBER_HAS_TO_BE_LESS_THAN"
			defaultMessage="Number has to be less than {propName}"
			values={{ propName }}
		/>
	);

export const isLessThanOrEqualTo = (prop, propName) => (value, allValues) =>
	Number(value) > Number(allValues[prop]) && (
		<FormattedMessage
			id="NUMBER_HAS_TO_BE_LESS_THAN_OR_EQUAL_TO"
			defaultMessage="Number has to be less than or equal to {propName}"
			values={{ propName }}
		/>
	);

export const isGreaterThan = (prop, propName) => (value, allValues) =>
	Number(value) < Number(allValues[prop]) && (
		<FormattedMessage
			id="NUMBER_HAS_TO_BE_GREATER_THAN"
			defaultMessage="Number has to be greater than {propName}"
			values={{ propName }}
		/>
	);

export const isGreaterThanOrEqualTo = (prop, propName) => (value, allValues) =>
	Number(value) < Number(allValues[prop]) && (
		<FormattedMessage
			id="NUMBER_HAS_TO_BE_GREATER_THAN_OR_EQUAL_TO"
			defaultMessage="Number has to be greater than or equal to {propName}"
			values={{ propName }}
		/>
	);

export const range = (min, max) => (value) =>
	(Number(value) < Number(min) || Number(value) > Number(max)) && (
		<FormattedMessage id="OUT_OF_RANGE" defaultMessage="Out of range" />
	);

export const incremental = (increment) => (value) =>
	Number(value) % Number(increment) !== 0 && (
		<FormattedMessage
			id="NUMBER_HAS_TO_BE_IN_INCREMENTAL_STEPS"
			defaultMessage="Number has to be in incremental steps of {increment}"
			values={{ increment }}
		>
			{(formattedValue) => formattedValue}
		</FormattedMessage>
	);

export const dateNotBefore = (date, dateFormat) => (value) =>
	value &&
	value < date && (
		<FormattedMessage
			id="NOT_BEFORE"
			defaultMessage="Date is not allowed to be before {date}"
			values={{ date: format(date, dateFormat) }}
		/>
	);

export const onlyLettersAndNumbers = (value) =>
	!value.match(/^[A-Za-z0-9]+$/) && (
		<FormattedMessage
			id="ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED"
			defaultMessage="Only letters and numbers are allowed"
		/>
	);

export const zipCodeValidation = (value) =>
	!value.match(/^[A-Za-z0-9-]+$/) && (
		<FormattedMessage
			id="ONLY_LETTERS_AND_NUMBERS_ARE_ALLOWED"
			defaultMessage="Only letters, numbers and hyphens are allowed"
		/>
	);

export const version = (value) =>
	value &&
	!/^\d{1,3}(\.\d{1,3}){0,2}$/i.test(value) && (
		<FormattedMessage
			id="INVALID_VERSION"
			defaultMessage="Only major.minor.patch format allowed, ie. x.y.z"
		/>
	);

export const noWhiteSpaceOnly = (value) =>
	value &&
	!/.*\S.*/.test(value) && (
		<FormattedMessage
			id="NO_WHITE_SPACE_ONLY"
			defaultMessage="Text should contain letters and/or numbers"
		/>
	);

	export const passwordsMustMatch = (value, allValues) =>
		(value !== allValues.password &&
			<FormattedMessage
				id="PASSWORDS_DO_NOT_MATCH"
				defaultMessage="Passwords do not match"
			/>
		);

export const uploadFileName = (value) =>
	value &&
	!/^[^!?<>%$()+=\s]*$/i.test(value) && (
		<FormattedMessage
			id="INVOICES.UPLOAD_FILE_NAME_VALIDATION"
			defaultMessage="File name is not valid (please use _ instead of the whitespace and the following characters are not allowed: '!?<>%$()+=')"
		/>
	);
