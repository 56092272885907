import { connect } from 'react-redux';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';
import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';
import { anyModalOpened } from '../../../Shared/redux/modals/actions';

import {
	getRegistrations,
	cancelRegistration,
	resendRegistration
} from '../../redux/registrations/actions';

import {
	grantAccessSuccess
} from '../../redux/accounts/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';
import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import {
	getRegistrationsFail,
	getRegistrationsSuccess,
	getRegistrationsRequest,
	cancelPendingInvitationFail,
	cancelPendingInvitationSuccess,
	cancelPendingInvitationRequest,
	resendInvitationFail,
	resendInvitationSuccess,
	resendInvitationRequest
} from '../../redux/registrations/selectors';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import {
	setPaginationInit,
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import PendingInvitationsDesktop from './Desktop';
import PendingInvitationsMobile from './Mobile';

const mapStateToProps = (state) => ({
	filterModalOpened: isFilterModalOpen(state),
	getRegistrationsFail: getRegistrationsFail(state),
	getRegistrationsRequest: getRegistrationsRequest(state),
	registrations: getRegistrationsSuccess(state),
	cancelRegistrationFail: cancelPendingInvitationFail(state),
	cancelRegistrationRequest: cancelPendingInvitationRequest(state),
	cancelRegistration: cancelPendingInvitationSuccess(state),
	resendInvitationFail: resendInvitationFail(state),
	resendInvitationRequest: resendInvitationRequest(state),
	resendInvitation: resendInvitationSuccess(state),
	grantAccess: grantAccessSuccess(state),
	filterData: filterDataSuccess(state),
	user: getUser(state),
	searchParameter: getSearchParameter(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
});

const mapDispatchToProps = (dispatch) => ({
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	getRegistrations: (params) => dispatch(getRegistrations(params)),
	patchCancelRegistration: (params) => dispatch(cancelRegistration(params)),
	patchResendRegistration: (params) => dispatch(resendRegistration(params)),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(PendingInvitationsDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(PendingInvitationsMobile);
