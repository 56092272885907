import { connect } from 'react-redux';
import AddSimSkuModal from './AddSimSkuModal';

import {
	getParentSimSkus,
	getParentSimSkusInit,
	addSubAccountSimSku
} from '../../redux/simOrdering/actions';

import {
	getParentSimSkusRequest,
	getParentSimSkusFail,
	getParentSimSkusSuccess,
	addSubAccountSimSkuRequest
} from '../../redux/simOrdering/selectors';

const mapStateToProps = (state) => ({
	parentSimSkuRequest: getParentSimSkusRequest(state),
	parentSimSkuFail: getParentSimSkusFail(state),
	simSkus: getParentSimSkusSuccess(state),
	addSubAccountSimSkuRequest: addSubAccountSimSkuRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	getParentSimSkus: (params) => dispatch(getParentSimSkus(params)),
	getParentSimSkusInit: () => dispatch(getParentSimSkusInit()),
	addSubAccountSimSku: (params) => dispatch(addSubAccountSimSku(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSimSkuModal);
