export default {
	'SHARED.NO_DATA_AVAILABLE': 'Aucune donnée disponible',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE': 'Aucune donnée disponible à vous montrer actuellement',
	'SHARED.EXIT_THIS_COMPANY': 'Quitter',
	// ACTION BAR SELECTED FILTERS TOOLTIP
	// ** ENDPOINTS **
	'ACTION_BAR.ENDPOINTSTATUS': 'État',
	'ACTION_BAR.NETWORKOPERATOR': 'Opérateur réseau',
	'ACTION_BAR.NETWORKCOUNTRY': 'Pays réseau',
	'ACTION_BAR.NETWORKENTITLEMENT': 'ID droit d’utiliser le réseau',
	'ACTION_BAR.NETWORKTYPE': 'Type de réseau',
	'ACTION_BAR.TRANSFERSTATUS': 'État du transfert IMSI',
	'ACTION_BAR.RATEPLAN': 'Forfait',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'État réaffectation',
	'ACTION_BAR.M2MPLATFORM': 'Plateforme machine-machine',
	'ACTION_BAR.ROAMINGCOUNTRY': 'Pays itinérance',
	'ACTION_BAR.ROAMINGCARRIER': 'Opérateur itinérance',
	'ACTION_BAR.SERVICESTATUS': 'État 360L',
	'ACTION_BAR.OEM': 'Sous-comptes',
	'ACTION_BAR.M2MDATEADDED': 'Date ajout machine-machine',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'Date activation machine-machine',
	//  **  INVOICES  **
	'ACTION_BAR.M2MACCOUNTID': 'ID compte machine-machine',
	'ACTION_BAR.CUSTOMERNAME': 'Nom du client',
	'ACTION_BAR.INVOICENUMBER': 'Numéro de facture',
	'ACTION_BAR.COUNTRYNAME': 'Nom du pays',
	'ACTION_BAR.BILLINGCYCLE': 'Cycle de facturation',
	'ACTION_BAR.CUSTOMERID': 'ID client',
	'ACTION_BAR.INVOICEDATE': 'Date facture',
	//  **  REPORTS **
	'ACTION_BAR.M2M_ACC_ID': 'ID compte machine-machine',
	'ACTION_BAR.M2M_PLATFORM': 'Plateforme machine-machine',
	'ACTION_BAR.PLATFORM': 'Plateforme',
	'ACTION_BAR.FREQUENCY': 'Fréquence',
	'ACTION_BAR.REPORT_NAME': 'Nom du rapport',
	'ACTION_BAR.SERVICEPROVIDER': 'Plateforme',
	'ACTION_BAR.REPORTFREQUENCY': 'Fréquence',
	//  **  SIM ORDERING  **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': 'État',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'Plateforme machine-machine',
	'ACTION_BAR.FILTER_CATEGORY_SKU': 'UGS',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Accéléré',
	//  **  SECURITY FEATURES **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'Compte machine-machine',
	//  **  TICKETING **
	'ACTION_BAR.CATEGORIES': 'Catégories',
	'ACTION_BAR.STATUS': 'État',
	'ACTION_BAR.SEVERITY': 'Gravité',
	//  ACTION BAR
	'ACTION_BAR.CLEAR_FILTERS': 'Effacer filtres',
	'ACTION_BAR.SELECT_FILTERS': 'Sélectionner filtres',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Modifier paramètres de la table',
	'ACTION_BAR.SHOWING': 'Montre',
	'ACTION_BAR.OF': 'de',
	'ACTION_BAR.DOWNLOAD_FILE': 'Téléchargement fichier',
	'ACTION_BAR.REFRESH_PAGE': 'Actualiser page',
	//  ******************* FILTER MODAL *****************************
	//  **  ENDPOINTS **
	'FILTER_MODAL.ENDPOINTSTATUS': 'État',
	'FILTER_MODAL.NETWORKOPERATOR': 'Opérateur réseau',
	'FILTER_MODAL.NETWORKCOUNTRY': 'Pays réseau',
	'FILTER_MODAL.NETWORKENTITLEMENT': 'ID droit d’utiliser le réseau',
	'FILTER_MODAL.NETWORKTYPE': 'Type de réseau',
	'FILTER_MODAL.TRANSFERSTATUS': 'État du transfert IMSI',
	'FILTER_MODAL.RATEPLAN': 'Forfait',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'État réaffectation',
	'FILTER_MODAL.M2MPLATFORM': 'Plateforme machine-machine',
	'FILTER_MODAL.ROAMINGCOUNTRY': 'Pays itinérance',
	'FILTER_MODAL.ROAMINGCARRIER': 'Opérateur itinérance',
	'FILTER_MODAL.SERVICESTATUS': 'État 360L',
	'FILTER_MODAL.OEM': 'Sous-comptes',
	'FILTER_MODAL.M2MDATEADDED': 'Date ajout machine-machine',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'Date activation machine-machine',
	//  tabs
	'FILTER_MODAL.TABS_ENDPOINTSTATE': 'État du point terminal',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Opérateur réseau',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'Pays réseau',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Droit d’utiliser le réseau',
	'FILTER_MODAL.TABS_NETWORKTYPE': 'Type de réseau',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': 'État du transfert',
	'FILTER_MODAL.TABS_RATEPLAN': 'Forfait',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': 'État du transfert de réaffectation',
	'FILTER_MODAL.TABS_M2MPLATFORM': 'Plateforme machine-machine',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'Pays itinérance',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Opérateur itinérance',
	'FILTER_MODAL.TABS_SERVICESTATUS': 'État 360L',
	'FILTER_MODAL.TABS_OEM': 'Sous-comptes',
	'FILTER_MODAL.TABS_M2MDATEADDED': 'Date ajout machine-machine',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'Date activation machine-machine',
	//  **  INVOICES  **
	'FILTER_MODAL.M2MACCOUNTID': 'ID COMPTE MACHINE-MACHINE',
	'FILTER_MODAL.CUSTOMERNAME': 'NOM DU CLIENT',
	'FILTER_MODAL.INVOICENUMBER': 'NUMÉRO DE FACTURE',
	'FILTER_MODAL.INVOICEDATE': 'DATE FACTURE',
	'FILTER_MODAL.COUNTRYNAME': 'NOM DU PAYS',
	'FILTER_MODAL.CUSTOMERID': 'ID CLIENT',
	'FILTER_MODAL.BILLINGCYCLE': 'CYCLE DE FACTURATION',
	//  tabs
	'FILTER_MODAL.TABS_M2MACCOUNTID': 'ID compte machine-machine',
	'FILTER_MODAL.TABS_CUSTOMERNAME': 'Nom du client',
	'FILTER_MODAL.TABS_INVOICENUMBER': 'Numéro de facture',
	'FILTER_MODAL.TABS_COUNTRYNAME': 'Nom du pays',
	'FILTER_MODAL.TABS_CUSTOMERID': 'ID client',
	'FILTER_MODAL.TABS_BILLINGCYCLE': 'Cycle de facturation',
	'FILTER_MODAL.TABS_INVOICEDATE': 'Date facture',

	//  **  REPORTS **
	'FILTER_MODAL.M2M_ACC_ID': 'ID COMPTE MACHINE-MACHINE',
	'FILTER_MODAL.M2M_PLATFORM': 'PLATEFORME MACHINE-MACHINE',
	'FILTER_MODAL.FREQUENCY': 'FRÉQUENCE',
	'FILTER_MODAL.REPORT_NAME': 'NOM DU RAPPORT',
	'FILTER_MODAL.PLATFORM': 'PLATEFORME',
	'FILTER_MODAL.SERVICEPROVIDER': 'PLATEFORME',
	'FILTER_MODAL.REPORTFREQUENCY': 'FRÉQUENCE',
	//  tabs
	'FILTER_MODAL.TABS_M2M_ACC_ID': 'ID compte machine-machine',
	'FILTER_MODAL.TABS_M2M_PLATFORM': 'Plateforme machine-machine',
	'FILTER_MODAL.TABS_FREQUENCY': 'Fréquence',
	'FILTER_MODAL.TABS_REPORT_NAME': 'Nom du rapport',
	'FILTER_MODAL.TABS_PLATFORM': 'Plateforme',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Plateforme',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Fréquence',
	//  **  SIM ORDERING  **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'ÉTAT',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'PLATEFORME MACHINE-MACHINE',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': 'UGS',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'ACCÉLÉRÉ',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'État',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'Plateforme machine-machine',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'UGS',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Accéléré',
	//  **  SECURITY FEATURES **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'COMPTE MACHINE-MACHINE',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'Compte machine-machine',
	//  **  TICKETING **
	'FILTER_MODAL.CATEGORIES': 'CATÉGORIES',
	'FILTER_MODAL.STATUS': 'ÉTAT',
	'FILTER_MODAL.SEVERITY': 'GRAVITÉ',
	//  tabs
	'FILTER_MODAL.TABS_CATEGORIES': 'Catégories',
	'FILTER_MODAL.TABS_STATUS': 'État',
	'FILTER_MODAL.TABS_SEVERITY': 'Gravité',
	//  **  INVOICE DETAILS **
	'FILTER_MODAL.CURRENCY': 'DEVISE',
	'FILTER_MODAL.CUSTOMER': 'CLIENT',
	'FILTER_MODAL.SUBSCRIBER_STATUS': 'ÉTAT ABONNÉ',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': 'FORFAIT MENSUEL',
	'FILTER_MODAL.STANDARD_RATE_PLAN': 'FORFAIT STANDARD',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'PRÉPAIEMENT FACTURÉ',
	'FILTER_MODAL.RATE_PLAN': 'FORFAIT',
	'FILTER_MODAL.PLAN_TYPE': 'TYPE FORFAIT',
	'FILTER_MODAL.ZONE': 'ZONE',
	'FILTER_MODAL.PRIORITY': 'PRIORITÉ',
	'FILTER_MODAL.ROAMING_ZONE': 'ZONE ITINÉRANCE',
	'FILTER_MODAL.GROUP_NAME': 'NOM DU GROUPE',
	'FILTER_MODAL.INVOICE_ID': 'ID FACTURE',
	'FILTER_MODAL.THE_PLAN_TYPE': 'TYPE FORFAIT',
	'FILTER_MODAL.ACTIVATION_TYPE': 'TYPE ACTIVATION',
	'FILTER_MODAL.CHARGE_TYPE': 'TYPE FRAIS',
	'FILTER_MODAL.PRIMARY_PLACE': 'ENDROIT PRINCIPAL',
	'FILTER_MODAL.TECH_TYPE': 'TYPE TECH',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'PRINCIPAL ENDROIT D’UTILISATION',
	'FILTER_MODAL.STATE': 'ÉTAT',
	'FILTER_MODAL.CITY': 'VILLE',
	'FILTER_MODAL.DISTRICT_COUNTY': 'COMTÉ DISTRICT',
	'FILTER_MODAL.DISTRICT_CITY': 'VILLE DISTRICT',
	'FILTER_MODAL.DESCRIPTION': 'DESCRIPTION',
	//  tabs
	'FILTER_MODAL.TABS_CURRENCY': 'Devise',
	'FILTER_MODAL.TABS_CUSTOMER': 'Client',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'État abonné',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'Forfait mensuel',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'Forfait standard',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'Prépaiement facturé',
	'FILTER_MODAL.TABS_RATE_PLAN': 'Forfait',
	'FILTER_MODAL.TABS_PLAN_TYPE': 'Type forfait',
	'FILTER_MODAL.TABS_ZONE': 'Zone',
	'FILTER_MODAL.TABS_PRIORITY': 'Priorité',
	'FILTER_MODAL.TABS_ROAMING_ZONE': 'Zone itinérance',
	'FILTER_MODAL.TABS_GROUP_NAME': 'Nom du groupe',
	'FILTER_MODAL.TABS_INVOICE_ID': 'ID facture',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'Type forfait',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'Type activation',
	'FILTER_MODAL.TABS_CHARGE_TYPE': 'Type frais',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': 'Endroit principal',
	'FILTER_MODAL.TABS_TECH_TYPE': 'Type tech',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'Principal endroit d’utilisation',
	'FILTER_MODAL.TABS_STATE': 'État',
	'FILTER_MODAL.TABS_CITY': 'Ville',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'Comté district',
	'FILTER_MODAL.TABS_DISTRICT_CITY': 'Ville district',
  'FILTER_MODAL.TABS_DESCRIPTION': 'Description',
  //  **    AUTOMATION    **  //
  'FILTER_MODAL.PLATFORMS': 'PLATEFORMES',
  'FILTER_MODAL.TRIGGERS': 'DÉCLENCHEURS',
  'FILTER_MODAL.M2MACCOUNTS': 'COMPTES MACHINE-MACHINE',
  'FILTER_MODAL.ACTIVE': 'ACTIVÉ',
  'FILTER_MODAL.STATUSES': 'ÉTATS',
  //  tabs
  'FILTER_MODAL.TABS_PLATFORMS': 'Plateformes',
  'FILTER_MODAL.TABS_TRIGGERS': 'Déclencheurs',
  'FILTER_MODAL.TABS_M2MACCOUNTS': 'Compte machine-machine',
	'FILTER_MODAL.TABS_ACTIVE': 'Activé',
	'FILTER_MODAL.TABS_USERGROUPS': 'Groupes d’utilisateurs',
  'FILTER_MODAL.USERGROUPS': 'Groupes d’utilisateurs',
  'FILTER_MODAL.TABS_STATUSES': 'États',
  // action bar
  'ACTION_BAR.PLATFORMS': 'Plateformes',
  'ACTION_BAR.TRIGGERS': 'Déclencheurs',
  'ACTION_BAR.M2MACCOUNTS': 'Comptes machine-machine',
  'ACTION_BAR.CURRENCY': 'Devise',
  'ACTION_BAR.ACTIVE': 'Activé',
	'ACTION_BAR.CUSTOMER': 'Client',
	'ACTION_BAR.SUBSCRIBER_STATUS': 'État abonné',
	'ACTION_BAR.MONTHLY_RATE_PLAN': 'Forfait mensuel',
	'ACTION_BAR.STANDARD_RATE_PLAN': 'Forfait standard',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': 'Prépaiement facturé',
	'ACTION_BAR.RATE_PLAN': 'Forfait',
	'ACTION_BAR.PLAN_TYPE': 'Type forfait',
	'ACTION_BAR.ZONE': 'Zone',
	'ACTION_BAR.PRIORITY': 'Priorité',
	'ACTION_BAR.ROAMING_ZONE': 'Zone d’itinérance',
	'ACTION_BAR.GROUP_NAME': 'Nom du groupe',
	'ACTION_BAR.INVOICE_ID': 'ID facture',
	'ACTION_BAR.THE_PLAN_TYPE': 'Type forfait',
	'ACTION_BAR.ACTIVATION_TYPE': 'Type activation',
	'ACTION_BAR.CHARGE_TYPE': 'Type frais',
	'ACTION_BAR.PRIMARY_PLACE': 'Endroit principal',
	'ACTION_BAR.TECH_TYPE': 'Type tech',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'Principal endroit d’utilisation',
	'ACTION_BAR.STATE': 'État',
	'ACTION_BAR.CITY': 'Ville',
	'ACTION_BAR.DISTRICT_COUNTY': 'Comté district',
	'ACTION_BAR.DISTRICT_CITY': 'Ville district',
	'ACTION_BAR.DESCRIPTION': 'Description',
  'ACTION_BAR.USERGROUPS': 'Groupes d’utilisateurs',
  'ACTION_BAR.STATUSES': 'États',
  // UPLOAD COMPONENT
	'UPLOAD_FILE.MAX_FILE_SIZE': 'La taille du fichier doit être inférieure à {size}Mo.',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Type de fichier incorrect',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': 'Seuls les formats suivants sont autorisés pour le téléversement :',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'Fichier trop gros',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': 'Le fichier que vous essayez de téléverser est trop gros, la taille des fichiers doit être inférieure à',
  'UPLOAD_FILE.ALLOWED_FORMATS': 'Formats autorisés : ',
	'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'Seul un fichier est autorisé'
};
