import { Status } from '../../../../utils/constants';

/* ******************** GET ENDPOINTS ******************** */
export const getEndpointsRequest = (state) =>
	state.onboarding.endpoints.getEndpoints.status === Status.PENDING;
export const getEndpointsFail = (state) =>
	state.onboarding.endpoints.getEndpoints.status === Status.FAIL;
export const getEndpointsSuccess = (state) =>
	state.onboarding.endpoints.getEndpoints.data;

/* ******************** MOVE TO SUB ACCOUNT MODAL ******************** */
export const isMoveToSubAccountModalOpen = (state) =>
	state.onboarding.endpoints.moveToSubAccountModal.isOpen;

/* ******************** MOVE ENDPOINTS ******************** */
export const moveEndpointsRequest = (state) =>
	state.onboarding.endpoints.moveEndpoints.status === Status.PENDING;
export const moveEndpointsFail = (state) =>
	state.onboarding.endpoints.moveEndpoints.status === Status.FAIL;
export const moveEndpointsSuccess = (state) =>
	state.onboarding.endpoints.moveEndpoints.data;
