import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_SIM_ORDERS,
	GET_SIM_ORDER,
	ADD_SIM_ORDER,
	EDIT_SIM_ORDER,
  GET_SIM_ORDERING_FILTERS,
  GET_SIM_ORDER_STATUSES
} from './constants';

export default combineReducers({
	getSimOrders: globalReducer(GET_SIM_ORDERS),
	getSimOrder: globalReducer(GET_SIM_ORDER),
	addSimOrder: globalReducer(ADD_SIM_ORDER),
	editSimOrder: globalReducer(EDIT_SIM_ORDER),
  getSimOrderingFilters: globalReducer(GET_SIM_ORDERING_FILTERS),
  getSimOrderStatuses: globalReducer(GET_SIM_ORDER_STATUSES)
});
