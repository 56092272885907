/* eslint-disable import/prefer-default-export */
import globalAction from '../../../../redux/global/globalAction';

import { GET_BATCH_ACTIONS } from './constants';

import { getBatchActions as getBatchActionsService } from '../../services/BatchActionsService';

/** ************ get batch actions ************* */
export const getBatchActions = (params) =>
	globalAction(GET_BATCH_ACTIONS, getBatchActionsService, params);
