import { createAction } from 'redux-actions';
import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';

import {
	ADD_RATE_PLAN,
	EDIT_RATE_PLAN,
	GET_ALLOWED_TYPES,
	GET_RATE_PLAN,
	GET_RATE_PLANS,
	GET_PARENT_RATE_PLANS,
	ADD_RATE_PLANS_MODAL,
	ADD_SUB_ACCOUNT_RATE_PLANS
} from './constants';

import {
	addRatePlan as addRatePlanService,
	editRatePlan as editRatePlanService,
	getAllowedTypes as getAllowedTypesService,
	getRatePlan as getRatePlanService,
	getRatePlans as getRatePlansService
} from '../../services/RatePlanService';

import { editSubAccount as editSubAccountService } from '../../services/AccountsService';

/* ******************** ADD RATE PLAN ******************** */
export const addRatePlan = (params) => {
	const { companyId, accountId, platformType, m2mAccountId } = params.additionalData;
	return globalAction(
		ADD_RATE_PLAN,
		addRatePlanService,
		{ data: params.ratePlan, m2mAccountId, companyId },
		{
			notify: 'add-rate-plan',
			init: true,
			redirect: `/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/rate-plans`
		}
	);
};

export const addRatePlanInit = () => actionInit(ADD_RATE_PLAN);

/* ******************** EDIT RATE PLAN ******************** */
export const editRatePlan = (params) => {
	const { companyId, accountId, platformType, m2mAccountId } = params.additionalData;
	const { id, ratePlan } = params;
	return globalAction(
		EDIT_RATE_PLAN,
		editRatePlanService,
		{
			companyId,
			m2mAccountId,
			id,
			data: ratePlan
		},
		{
			notify: 'edit-rate-plan',
			init: true,
			redirect: `/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/rate-plans`
		}
	);
};

export const editRatePlanInit = () => actionInit(EDIT_RATE_PLAN);

/* ******************** GET ALLOWED TYPES ******************** */
export const getAllowedTypes = () =>
	globalAction(GET_ALLOWED_TYPES, getAllowedTypesService);

/* ******************** GET RATE PLAN ******************** */
export const getRatePlan = (params) =>
	globalAction(GET_RATE_PLAN, getRatePlanService, params);

export const getRatePlanInit = () => actionInit(GET_RATE_PLAN);

/* ******************** GET RATE PLANS ******************** */
export const getRatePlans = (params) =>
	globalAction(GET_RATE_PLANS, getRatePlansService, params);

/* ******************** ADD RATE PLANS MODAL ******************** */
export const openAddRatePlansModal = createAction(
	`OPEN_${ADD_RATE_PLANS_MODAL}`
);
export const closeAddRatePlansModal = createAction(
	`CLOSE_${ADD_RATE_PLANS_MODAL}`
);

/* ******************** GET PARENT NETWORK ENTITLEMENTS ******************** */
export const getParentRatePlans = (params) =>
	globalAction(GET_PARENT_RATE_PLANS, getRatePlansService, params);

export const getParentRatePlansInit = () => actionInit(GET_PARENT_RATE_PLANS);

/* ******************** ADD SUB ACCOUNT NETWORK ENTITLEMENTS ******************** */
export const addSubAccountRatePlans = (params) =>
	globalAction(ADD_SUB_ACCOUNT_RATE_PLANS, editSubAccountService, params, {
		modals: closeAddRatePlansModal(),
		notify: 'add-rate-plans'
	});
