export default {
	'REPORTS.REPORT': 'Report',
	'REPORTS.M2M_ACCOUNT_ID': 'ID account M2M',
	'REPORTS.M2M_PLATFORM': 'Piattaforma M2M',
	'REPORTS.FREQUENCY': 'Frequenza',
	'REPORTS.REPORT_DATE': 'Data report',
	'REPORTS_TABS.ENDPOINTS': 'Endpoint',
	'REPORTS_TABS.USAGE_SUMMARY': 'Riepilogo utilizzo',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Attività trasferimento',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Riepilogo account',
	'REPORTS_TABS.BILLING_DETAILS': 'Dettagli fatturazione',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Report riferimento',
	'REPORTS_TABS.MY_EXPORTS': 'Esportazioni personali',
	'REPORTS_TABS.USAGE_REPORTS': 'Report utilizzo',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Rapporti ripubblicati',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'Nessun report disponibile',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': 'Attualmente non ci sono rapporti visualizzabili per questa tabella',
	'REPORTS.FILE_NAME': 'Nome del file',
	'REPORTS.FILE_DATE': 'Data del file',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Richiesto da'
};
