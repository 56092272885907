import React from 'react';
import {
	objectOf,
	string,
	object,
	arrayOf,
	func,
	bool,
	array,
} from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ratePlanTableHeaders } from '../../../utils/constants';

export default function RatePlanTable({
	ratePlans,
	styles,
	onClick,
	selected,
	currentRatePlan,
	isMultiAction,
	transposedData
}) {
	return (
		<div data-spec="rateplan-table" className={styles.rate_plan_table}>
			<div>
				<table>
					<thead>
					<tr>
						<th aria-label="empty-header" />
						{ratePlans &&
						ratePlans.map((plan, index) => {
							const selectRatePlanTableHeader = () => plan.ratePlan
								&& plan.ratePlan.id !== '-'
								&& onClick(plan.ratePlan.id);

							return (
								<th
									data-spec="table-header"
									onClick={index > 0 ? selectRatePlanTableHeader : undefined}
									className={cn(
										isMultiAction
											? plan.ratePlan &&
													selected === plan.ratePlan.id &&
													styles.selected
											: currentRatePlan.id !== selected &&
													plan.ratePlan &&
													selected === plan.ratePlan.id &&
													styles.selected
									)}
									key={`${plan.ratePlan.id}-${plan.ratePlan.m2mAccountId}`}
								>
									{!isMultiAction && index === 0 ? (
										<FormattedMessage
											id="SELECTED_RATE_PLAN"
											defaultMessage="Selected Rate Plan"
										/>
									) : (
										<FormattedMessage
											id="SELECT_RATE_PLAN"
											defaultMessage="Select Rate Plan"
										/>
									)}
								</th>
							);
						})}
					</tr>
					</thead>
					<tbody>
					{ratePlans &&
					ratePlanTableHeaders.map((head) => (
						<tr key={head.title}>
							<td>
								<FormattedMessage
									id={head.title}
									defaultMessage={head.defaultMessage}
								/>
							</td>
							{transposedData[head.name] && transposedData[head.name].map((
								data,
								index
							) => {
								const selectRatePlanTableData = () => (
									transposedData.id[index] !== '-'
									&& onClick(transposedData.id[index])
								);

								return (
									<td
										data-spec="table-data"
										role="gridcell"
										key={`${transposedData.id[index]}-${transposedData.m2mAccountId[index]}`}
										onClick={
											isMultiAction || index > 0
												? selectRatePlanTableData
												: undefined
										}
										className={cn(
											isMultiAction
												? selected === transposedData.id[index] &&
												styles.selected
												: currentRatePlan.id !== selected &&
												selected === transposedData.id[index] &&
												styles.selected
										)}
									>
										{data || ''}
									</td>
								);
							})}
						</tr>
					))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

RatePlanTable.propTypes = {
	ratePlans: arrayOf(object),
	styles: objectOf(string),
	onClick: func,
	selected: string,
	currentRatePlan: object,
	isMultiAction: bool,
	transposedData: objectOf(array),
};
RatePlanTable.defaultProps = {
	ratePlans: [],
	styles: {},
	onClick: undefined,
	selected: '0',
	isMultiAction: false,
	transposedData: {},
};
