import React, { PureComponent } from 'react';
import { func, object, number, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';

import PageTitle from '../../../Shared/views/PageTitleView';
import ActionBar from '../../../Shared/views/ActionBarView';
import NoData from '../../../Shared/views/NoDataView';

import AutomationZonesModal from '../AutomationZonesModal';

import {
	AutomationZonesTableHeader as tableHeader,
	automationZonesSearchMenu
} from '../../utils/constants';

import {
	isUserAllowedToAccess,
	checkIfUserIsSystem
} from '../../../../utils/AuthSelector';

import { onOrderClick, newOnPageChange } from '../../../../utils/tableHelpers';

import styles from './AutomationZones.scss';

export default function AutomationZones(WrappedComponent) {
	class AutomationZonesComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				orderColumn: {}
			};
		}

		componentDidMount() {
			this.getAutomationZones();
		}

		componentDidUpdate(prevProps) {
			const { uploadFile } = this.props;
			if (
				uploadFile !== null &&
				prevProps.uploadFile === null
			) {
				this.getAutomationZones();
			}
		}

		getAutomationZones = () => {
			const {
				getAutomationZones,
				searchParameter,
				dataLimit,
				dataOffset
			} = this.props;
			const { orderColumn } = this.state;

			let simpleSearchParams = [];

			if (searchParameter) {
				if (searchParameter.prop === 'zoneId') {
					simpleSearchParams = [
						{
							operator: '=',
							prop: searchParameter.prop,
							propValue: Number.isNaN(Number(searchParameter.propValue))
								? 0
								: parseInt(searchParameter.propValue, 10)
						}
					];
				} else {
					simpleSearchParams = [
						{
							operator: 'iLike',
							prop: searchParameter.prop,
							propValue: `${searchParameter.propValue}%`
						}
					];
				}
			}

			const params = {
				searchParams: simpleSearchParams,
				additionalParams: {
					dataLimit: dataLimit || 50,
					dataOffset,
					dataSort: orderColumn.apiString
				}
			};
			getAutomationZones(params);
		};

		onSearchSubmitCallback = () => {
			this.getAutomationZones();
		};

		onChange = () => {
			this.getAutomationZones();
		};

		onPageChange = async (data) => {
			const { setDataOffset, setSelectedPage, dataLimit } = this.props;
			const { selected } = data;
			await setDataOffset(dataLimit * selected);
			await setSelectedPage(selected);
			this.getAutomationZones();
		};

		totalCount = () => {
			const { automationZones, dataLimit } = this.props;
			return Math.ceil(automationZones.totalCount / dataLimit);
		};

		openAutomationZonesModal = () => {
			const { openAutomationZonesModal, anyModalOpened } = this.props;
			anyModalOpened();
			openAutomationZonesModal();
		};

		getOptions = () => {
			const { orderColumn } = this.state;
			const {
				dataLimit,
				selectedPage,
				automationZones,
				setDataOffset,
				setSelectedPage
			} = this.props;

			const options = {};

			options.header = tableHeader;

			options.tableOptions = {
				orderByData: ['all'],
				showPagination:
					automationZones && automationZones.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				getOrderData: (data) =>
					onOrderClick(data, this.getAutomationZones, this),
				orderColumn,
				onPageChange: (data) =>
					newOnPageChange(
						data,
						this.getAutomationZones,
						dataLimit,
						setDataOffset,
						setSelectedPage
					)
			};

			return options;
		};

		render() {
			const {
				automationZonesRequest,
				automationZonesFail,
				automationZones,
				closeAutomationZonesModal,
				isAutomationZonesModalOpen,
				messages,
				selectedPage,
				dataLimit,
				uploadFileRequest,
				user
			} = this.props;

			return (
				<div data-spec="automation-zones">
					<PageTitle
						title={
							<FormattedMessage
								id="AUTOMATION_ZONES.AUTOMATION_ZONES"
								defaultMessage="Automation Zones"
							/>
						}
					/>
					<ActionBar
						menu={automationZonesSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						onChange={this.onChange}
						selectedPage={selectedPage}
						actions={
							<Button
								variant="primary"
								label={
									<FormattedMessage
										id="AUTOMATION_ZONES.UPLOAD_FILES"
										defaultMessage="Upload Files"
									/>
								}
								dataSpec="upload-files-button"
								onClick={this.openAutomationZonesModal}
								className={styles.upload_button}
								disabled={
									checkIfUserIsSystem(user)
										? !isUserAllowedToAccess(
												['system.mobilityzones_rw'],
												user
										  )
										: !isUserAllowedToAccess(
												['system.mobilityzones_ro'],
												user
										  )
								}
							/>
						}
						showPagination
						totalCount={automationZones && automationZones.totalCount}
						dataLimit={dataLimit}
					/>
					{(uploadFileRequest || automationZonesRequest) && (
						<Loading data-spec="loading" />
					)}
					{automationZonesFail && null}
					{!uploadFileRequest && !automationZonesRequest && automationZones && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}
					{automationZones &&
						automationZones.resultList &&
						automationZones.resultList.length === 0 && (
							<NoData
								title={
									<FormattedMessage
										id="AUTOMATION_ZONES.NO_AUTOMATION_ZONES_AVAILABLE"
										defaultMessage="No automation zones available."
									/>
								}
								subtitle={
									<FormattedMessage
										id="AUTOMATION_ZONES.THERE_ARE_NO_AUTOMATION_ZONES_AVAILABLE"
										defaultMessage="There are no automation zones available."
									/>
								}
							/>
						)}

					{isAutomationZonesModalOpen && (
						<AutomationZonesModal
							show
							messages={messages}
							onClose={closeAutomationZonesModal}
						/>
					)}
				</div>
			);
		}
	}

	AutomationZonesComponent.propTypes = {
		messages: object,
		user: object,
		searchParameter: object,
		dataOffset: number,
		setDataOffset: func,
		setSelectedPage: func,
		selectedPage: number,
		dataLimit: number,
		automationZones: object,
		isAutomationZonesModalOpen: bool,
		openAutomationZonesModal: func,
		closeAutomationZonesModal: func,
		anyModalOpened: func,
		automationZonesRequest: bool,
		automationZonesFail: bool,
		getAutomationZones: func,
		uploadFile: object,
		uploadFileRequest: bool
	};
	return AutomationZonesComponent;
}
