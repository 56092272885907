import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';

import ApiSandbox from '../ApiSandbox';
import GettingStarted from '../GettingStarted';

import styles from './Swagger.scss';

import { renderTabs, onNewTabClick } from '../../../../utils/tabHelpers';
import { SwaggerTabsIds } from '../../utils/helper';

class Swagger extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: SwaggerTabsIds.GettingStarted
		};
	}

	getSwaggerTabs = () => {
		const { activeTab } = this.state;
		const swaggerTabs = [
			{
				id: SwaggerTabsIds.GettingStarted,
				name: (
					<FormattedMessage
						id="SWAGGER.GETTING_STARTED"
						defaultMessage="Getting started"
					/>
				),
				component: (
					<GettingStarted
						key={SwaggerTabsIds.GettingStarted}
						data-spec="getting-started-tab"
					/>
				),
				isActive: activeTab === SwaggerTabsIds.GettingStarted,
				onClick: () => onNewTabClick(SwaggerTabsIds.GettingStarted, this)()
			},
			{
				id: SwaggerTabsIds.ApiSandbox,
				name: (
					<FormattedMessage
						id="SWAGGER.API_SANDBOX"
						defaultMessage="API Sandbox"
					/>
				),
				component: (
					<ApiSandbox
						key={SwaggerTabsIds.ApiSandbox}
						data-spec="api-sandbox-tab"
					/>
				),
				isActive: activeTab === SwaggerTabsIds.ApiSandbox,
				onClick: () => onNewTabClick(SwaggerTabsIds.ApiSandbox, this)()
			}
		];
		return swaggerTabs;
	};

	render() {
		const { messages } = this.props;
		return (
			<div data-spec="swagger">
				<PageTitle
					title={
						<FormattedMessage
							id="SWAGGER.DEVELOPER_API"
							defaultMessage="Developer API"
						/>
					}
					messages={messages}
				/>
				<div className={styles.tabs}>
					<TabsBar tabs={this.getSwaggerTabs()} />
				</div>

				<div className={styles.wrapper}>
					{renderTabs(this.getSwaggerTabs())}
				</div>
			</div>
		);
	}
}

const { objectOf, string } = PropTypes;

Swagger.propTypes = {
	messages: objectOf(string)
};

export default Swagger;
