import { connect } from 'react-redux';
import CancelRequestFeatureModal from './CancelRequestedFeatureModal';

import { cancelRequestedFeatureData } from '../../redux/blacklistedPermissions/selectors';
import { cancelRequestedFeature } from '../../redux/blacklistedPermissions/actions';

const mapStateToProps = (state) => ({
	permission: cancelRequestedFeatureData(state)
});

const mapDispatchToProps = (dispatch) => ({
	cancelRequestedFeature: (params) => dispatch(cancelRequestedFeature(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CancelRequestFeatureModal);
