import React, { PureComponent } from 'react';
import { object, string, func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { defaultUserGroupsTab } from '../../utils/constants';
import { renderTabs } from '../../../../utils/tabHelpers';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';
import Loader from '../../../../lib/DigitalComponents/Loader';

import EditUserGroupPermissions from '../EditUserGroupPermissions';
import EditUserGroupAssignedAccounts from '../EditUserGroupAssignedAccounts';

import styles from './EditUserGroup.scss';

const availableTabs = [
	{
		tab: {
			id: 'permissions',
			system: true,
			label: (
				<FormattedMessage
					id="ONBOARDING.PERMISSIONS"
					defaultMessage="Permissions"
				/>
			)
		}
	},
	{
		tab: {
			id: 'assignedAccounts',
			system: false,
			label: (
				<FormattedMessage
					id="ONBOARDING.ASSIGNED_ACCOUNTS"
					defaultMessage="Assigned Accounts"
				/>
			)
		}
	}
];

class EditUserGroup extends PureComponent {
	constructor(props) {
		super(props);

		this.UserGroupsTabs = availableTabs.map((i) => i.tab);

		let activeTab =
			this.UserGroupsTabs.length > 0 ? this.UserGroupsTabs[0] : null;
		if (props.defaultTab === defaultUserGroupsTab.permissions) {
			activeTab = this.UserGroupsTabs.find(
				(tab) => tab.id === defaultUserGroupsTab.permissions
			);
		} else if (props.defaultTab === defaultUserGroupsTab.assignedAccounts) {
			activeTab = this.UserGroupsTabs.find(
				(tab) => tab.id === defaultUserGroupsTab.assignedAccounts
			);
		}

		this.state = { activeTab };
	}

	componentDidMount() {
		const {
			userGroup,
			match: {
				params: { userGroupId }
			}
		} = this.props;
		if (
			!userGroup ||
			(userGroup && userGroup.id !== parseInt(userGroupId, 10))
		) {
			this.getUserGroup(userGroupId);
		}
	}

	componentDidUpdate(prevProps) {
		const {
			editUserGroupAssignedAccountsSuccess,
			match: {
				params: { userGroupId }
			}
		} = this.props;
		if (
			prevProps.editUserGroupAssignedAccountsSuccess !==
			editUserGroupAssignedAccountsSuccess
		) {
			this.getUserGroup(userGroupId);
		}
	}

	getUserGroup = (id) => {
		const { getUserGroup, system } = this.props;
		const params = {
			additionalParams: {
				include: ['Platform']
			}
		};
		getUserGroup({
			id,
			system,
			params
		});
	};

	onNewTabClick = (id) => {
		const {
			push,
			match: {
				params: { userGroupId }
			}
		} = this.props;
		if (id === defaultUserGroupsTab.permissions) {
			push(`/user-groups/${userGroupId}/permissions/edit`);
		} else if (id === defaultUserGroupsTab.assignedAccounts) {
			push(`/user-groups/${userGroupId}/assigned-accounts/edit`);
		}
	};

	pushBack = () => {
		const { history: { goBack } } = this.props;
		goBack();
	};

	isPersonalUserGroup = (type) => {
		if (type === 3 || type === 4) return true;
	};

	render() {
		const {
			match: {
				params: { id, userGroupId }
			},
			userGroupRequest,
			userGroup,
			system,
			messages
		} = this.props;
		const tabComponents = {
			permissions: (
				<EditUserGroupPermissions
					key="user-group-permissions"
					userGroupId={userGroupId}
					goToEditUserGroupPermissions={this.goToEditUserGroupPermissions}
					userGroup={userGroup}
					pushBack={this.pushBack}
					system={system}
					userId={id}
					individual={this.isPersonalUserGroup(userGroup && userGroup.userGroupTypeId)}
				/>
			),
			assignedAccounts: (
				<EditUserGroupAssignedAccounts
					key="user-group-assigned-accounts"
					accounts={userGroup && userGroup.platform}
					userGroupId={userGroupId}
					pushBack={this.pushBack}
					messages={messages}
				/>
			)
		};

		const actionTabs = this.UserGroupsTabs.map((tab) => {
			const { activeTab } = this.state;
			const name = tab.label;
			return {
				name,
				isActive: activeTab && tab.id === activeTab.id,
				onClick: () => this.onNewTabClick(tab.id),
				component: tabComponents[tab.id],
				system: tab.system
			};
		});

		return (
			<div data-spec="user-group">
				<PageTitle
					title={
						this.isPersonalUserGroup(userGroup && userGroup.userGroupTypeId) ? (
							<FormattedMessage
								id="ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS"
								defaultMessage="Edit Individual Permissions"
							/>
						) : (
							<FormattedMessage
								id="ONBOARDING.EDIT_USER_GROUP"
								defaultMessage="Edit User Group"
							/>
						)
					}
					pushBack={this.pushBack}
				/>
				{!userGroupRequest && userGroup && (
					<>
						{!this.isPersonalUserGroup(
							userGroup && userGroup.userGroupTypeId
						) && (
							<div className={styles.block}>
								<h2 className={styles.section_title}>
									{userGroup && userGroup.name}
								</h2>
								{!system && (
									<div className={styles.section_subtitle}>
										<p>
											<FormattedMessage
												id="ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL"
												defaultMessage="User groups are built using least-prvilege model."
											/>
										</p>
										<p>
											<FormattedMessage
												id="ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS"
												defaultMessage="Some configurations are limited to AT&T Super Users."
											/>
										</p>
										<p>
											<FormattedMessage
												id="ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION"
												defaultMessage="If you need to perform a resticted operation reach out to your technical contact for help."
											/>
										</p>
									</div>
								)}
							</div>
						)}
						<TabsBar
							tabs={actionTabs.filter((item) =>
								(system ||
								this.isPersonalUserGroup(userGroup && userGroup.userGroupTypeId)
									? item.system
									: item))}
						/>
						{renderTabs(
							actionTabs.filter((item) =>
								(system ||
								this.isPersonalUserGroup(userGroup && userGroup.userGroupTypeId)
									? item.system
									: item))
						)}
					</>
				)}
				{userGroupRequest && <Loader />}
			</div>
		);
	}
}

EditUserGroup.propTypes = {
	defaultTab: string,
	match: object,
	push: func,
	getUserGroup: func,
	userGroupRequest: bool,
	userGroup: object,
	editUserGroupAssignedAccountsSuccess: bool,
	system: bool,
	history: object,
	messages: object
};

export default EditUserGroup;
