import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

const DownCarretIcon = ImageSelector(CurrentContext.theme, 'svgs/down.svg');
const SearchIcon = ImageSelector(CurrentContext.theme, 'svgs/search.svg');

class SearchBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isMenuOpen: false,
			searchParameter: props.menu && props.menu[0],
			value: ''
		};
	}

	componentDidMount() {
		const { menu, simpleSearchParameter } = this.props;
		menu &&
			this.setInitialParameter(
				menu,
				simpleSearchParameter.length ? simpleSearchParameter[0].value : ''
			);
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentDidUpdate(prevProps) {
		const { simpleSearchParameter, menu } = this.props;
		if (simpleSearchParameter !== prevProps.simpleSearchParameter) {
			if (!simpleSearchParameter.length && menu) {
				this.updateSearchParameter();
			}
		}
  }

	componentWillUnmount() {
    const { onSubmit } = this.props;
		document.removeEventListener('click', this.handleClickOutside, true);
		onSubmit({ resetSearch: true });
  }

  updateSearchParameter = () => {
    const { menu } = this.props;
    this.setState({
			value: '',
			searchParameter: menu && menu[0]
		});
  }

	renderMenu = () => {
		const { styles, menu } = this.props;
		const { searchParameter } = this.state;
		return (
			<ul
				ref={(menuOptions) => {
					this.menu = menuOptions;
				}}
				className={styles.menu}
				data-spec="menu"
			>
				{menu &&
					menu
						.filter((item) => !item.hide)
						.map((item) => (
							<li
								role="menuitem"
								data-spec={`search-bar-item-${item &&
									item.value &&
									item.value}`}
								className={
									item.label === (searchParameter && searchParameter.label)
										? styles.selected
										: ''
								}
								key={item.value}
								onClick={() => this.setSearchParameter(item)}
							>
								{item.label}
							</li>
						))}
			</ul>
		);
	};

	handleClickOutside = (event) => {
		const parentelement = event.target.parentElement
			? 'parentElement'
			: 'parentNode';
		const domNode = this.menu;
		if (
			(!domNode || !domNode.contains(event.target)) &&
			event.target.id != 'search-menu' &&
			event.target[parentelement] &&
			event.target[parentelement][parentelement] &&
			event.target[parentelement][parentelement].id != 'search-menu'
		) {
			this.closeMenu();
		}
	};

	toggleMenu = () => {
		const { isMenuOpen } = this.state;
		this.setState({
			isMenuOpen: !isMenuOpen
		});
	};

	closeMenu = () => {
		this.setState({
			isMenuOpen: false
		});
	};

	setSearchParameter = (parameter) => {
		this.setState({
			searchParameter: parameter
		});
	};

	renderInitialParameters = () => {
		const { searchParameter } = this.state;
		if (searchParameter) {
			return searchParameter.label;
		}
	};

	setInitialParameter = (menu, value) => {
		const { simpleSearchParameter } = this.props;
		if (!simpleSearchParameter || !simpleSearchParameter.length) {
			this.setState({
				searchParameter: menu[0],
				value
			});
		} else {
			const index = findIndex(menu, {
				value: simpleSearchParameter[0].value
			});
			this.setState({
				searchParameter: menu[index],
				value
			});
		}
	};

	handleOnKeyUp = (e) => {
    const { onSubmit, isSimpleSearchActive } = this.props;
    const { searchParameter } = this.state;
		const searchValue = e.currentTarget.value;
		if (e.keyCode === 13 && searchValue && onSubmit) {
			onSubmit(searchValue, searchParameter);
		} else if (
			e.keyCode === 13 &&
			isSimpleSearchActive &&
			!searchValue &&
			onSubmit
		) {
			onSubmit({ resetSearch: true });
		}
	};

	onChange = (e) => {
		this.setState({
			value: e.currentTarget.value
		});
	};

	onSubmit = () => {
		const { onSubmit, isSimpleSearchActive } = this.props;
		const { searchParameter, value } = this.state;
		const searchValue = value;
		if (isSimpleSearchActive && !searchValue && onSubmit) {
			onSubmit({ resetSearch: true });
		} else if (searchValue && onSubmit) {
				onSubmit(searchValue, searchParameter);
			}
	};

	render() {
		const { styles } = this.props;
		const { isMenuOpen, value, searchParameter } = this.state;
		return (
			<div data-spec="search-bar" className={styles.right}>
				<div
					id="search-menu"
					onClick={this.toggleMenu}
					className={cn(styles.search_left, isMenuOpen ? styles.opened : '')}
				>
					{this.renderInitialParameters()}
					<DownCarretIcon className={styles.down_carret} />
					{isMenuOpen && this.renderMenu()}
				</div>
				<div className={styles.search_right}>
					<FormattedMessage id="QUICK_SEARCH" defaultMessage="Quick search">
						{(formattedValue) => (
							<input
								type="text"
								maxLength={60}
								onKeyUp={this.handleOnKeyUp}
								onChange={this.onChange}
								value={value || ''}
								placeholder={
									(searchParameter && searchParameter.placeholder) ||
									formattedValue
								}
								data-spec="quick-search"
							/>
						)}
					</FormattedMessage>

					<div onClick={this.onSubmit} className={styles.search_icon}>
						<SearchIcon />
					</div>
				</div>
			</div>
		);
	}
}

const { arrayOf, func, string, bool, objectOf, object } = PropTypes;

SearchBar.propTypes = {
	menu: arrayOf(object),
	simpleSearchParameter: arrayOf(object),
	onSubmit: func.isRequired,
	styles: objectOf(string),
	isSimpleSearchActive: bool
};

export default SearchBar;
