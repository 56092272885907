export default {
	'SUPPORT.USER_GUIDE': 'Manuale dell’utente',
	'SUPPORT.PRINT_PDF': 'Stampa PDF',
	'SUPPORT.SUPPORT': 'Assistenza',
	'SUPPORT.FAQ': 'Domande frequenti',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Come trovare risposte rapide alle domande più frequenti dei nostri utenti.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Leggere le istruzioni dettagliate per le funzioni del portale. La guida è personalizzata in base al tipo di account.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Sommario',
	'SUPPORT.TICKETING': 'Creazione di ticket',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'Se si verificano interruzioni del servizio o problemi di servizio relativamente agli endpoint dell’utente e i test diagnostici non offrono una soluzione, è possibile creare un ticket per problema presso il nostro team Ops. È anche possibile porre domande relative alla fatturazione e all’assistenza.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Notifiche recenti',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Crea nuova notifica',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'Visualizza tutte le notifiche',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Domande frequenti',
	'SUPPORT.TOPICS': 'Argomenti',
	'SUPPORT.EDIT_NOTIFICATION': 'Modifica notifica',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Notifica globale',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': 'Notifica relativa all’azienda',
	'SUPPORT.NOTIFICATION_TYPE': 'Tipo di notifica?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER': 'Selezionare segnaposto notifica',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Scegliere i gruppi utenti a cui inviare la notifica:',
	'SUPPORT.CONTENT': 'Contenuti',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Aggiungi contenuti alla notifica',
	'SUPPORT.PUBLISH': 'Pubblica',
	'SUPPORT.GLOBAL': 'Globale',
	'SUPPORT.COMPANY_RELATED': 'Relativa all’azienda',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Conferma',
	'SUPPORT.NOTIFICATION_INFO': 'Informazioni',
	'SUPPORT.NOTIFICATION_WARNING': 'Avvertenza',
	'SUPPORT.NOTIFICATION_ERROR': 'Errore',
	'SUPPORT.SELECT_SECTION': 'Seleziona sezione',
	'SUPPORT.ALL': 'Tutto',
	'SUPPORT.ADMIN': 'Amministratore',
	'SUPPORT.USER': 'Utente',
	'SUPPORT.FINANCE': 'Finanza',
	'SUPPORT.OPERATIONS': 'Operazioni',
	'SUPPORT.RESTRICTEDACCESS': 'Accesso limitato',
	'SUPPORT.COMPANYVIEW': 'Vista azienda',
	'SUPPORT.COMPANYVIEWREADONLY': 'Vista azienda sola lettura',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Aggiungere nuove note sulla versione',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'Il documento sottostante non è tradotto interamente nella vostra lingua. La versione in inglese è inclusa. La traduzione nella vostra lingua sarà disponibile nella prossima distribuzione del prodotto.'
};
