import { connect } from 'react-redux';
import { reset } from 'redux-form';

import AutomationZonesModal from './AutomationZonesModal';

import { anyModalClosed } from '../../../../redux/modals/globalModal/actions';

import { hideNotification } from '../../../Shared/redux/notifications/actions';
import getNotifications from '../../../Shared/redux/notifications/selectors';
import { getErrors } from '../../../Shared/redux/getStatusCode/selectors';

import {
	fileUploadRequest,
	fileUploadSuccess
} from '../../../Shared/redux/uploadFile/selectors';

import { resetFiles } from '../../../Shared/redux/uploadFile/actions';

import { patchFileUpload, downloadTemplate } from '../../redux/zones/actions';

import {
	patchFileUploadRequest,
	downloadTemplateFail,
	downloadTemplateRequest,
	downloadTemplateSuccess
} from '../../redux/zones/selectors';

const mapStateToProps = (state) => ({
	notifications: getNotifications(state),
	errors: getErrors(state),
	filePending: fileUploadRequest(state),
	fileSuccess: fileUploadSuccess(state),
	uploadFileRequest: patchFileUploadRequest(state),
	downloadTemplateFail: downloadTemplateFail(state),
	downloadTemplateRequest: downloadTemplateRequest(state),
	downloadTemplateSuccess: downloadTemplateSuccess(state)
});
const mapDispatchToProps = (dispatch) => ({
	resetFiles: () => dispatch(resetFiles()),
	patchFileUpload: (id, data, notifOptions) =>
		dispatch(patchFileUpload(id, data, notifOptions)),
	anyModalClosed: () => dispatch(anyModalClosed()),
	resetForm: () => dispatch(reset('automationZones')),
	hideNotification: (id) => dispatch(hideNotification(id)),
	downloadTemplate: () => dispatch(downloadTemplate())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomationZonesModal);
