import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';

import {
	GET_RESTRAINTS,
	ADD_RESTRAINTS,
	UPDATE_RESTRAINTS,
	GET_RESTRAINT_PERIODS
} from './constants';

import {
	getRestraints as getRestraintsService,
	addRestraints as addRestraintsService,
	updateRestraints as updateRestraintsService,
	getRestraintPeriods as getRestraintPeriodsService
} from '../../services/SimOrderingService';

// GET RESTRAINTS
export const getRestraints = (params) =>
	globalAction(GET_RESTRAINTS, getRestraintsService, {
		params: params.params,
		companyId: params.companyId
	});

export const getRestraintsInit = () => actionInit(GET_RESTRAINTS);

// ADD RESTRAINTS
export const addRestraints = (data) =>
	globalAction(ADD_RESTRAINTS, addRestraintsService, data, {
		notify: 'add-restraints',
		init: true,
		notifyMessageSuccess: 'ADD_RESTRAINTS_SUCCESS',
		notifyMessageError: 'ADD_RESTRAINTS_ERROR'
	});

// UPDATE RESTRAINTS
export const updateRestraints = (params) =>
	globalAction(UPDATE_RESTRAINTS, updateRestraintsService, params, {
		init: true,
		notify: 'update-restraints',
		notifyMessageSuccess: 'UPDATE_RESTRAINTS_SUCCESS',
		notifyMessageError: 'UPDATE_RESTRAINTS_ERROR'
	});

//  GET RESTRAINT PERIODS
export const getRestraintPeriods = () =>
	globalAction(GET_RESTRAINT_PERIODS, getRestraintPeriodsService);
