import { connect } from 'react-redux';

import DiagnosticGdsp from './DiagnosticGdsp';
import {
	isGetGdspPending,
	getDiagnosticGdspData,
	isGetGdspSuccess
} from '../../redux/selectors';
import {
	getDiagnosticGdsp,
	getDiagnosticGdspInit,
} from '../../redux/actions';

const mapStateToProps = (state) => (
	{
		isPending: isGetGdspPending(state),
		isSuccess: isGetGdspSuccess(state),
		data: getDiagnosticGdspData(state)
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getDiagnosticGdsp: (iccid) => dispatch(getDiagnosticGdsp(iccid)),
		setDiagnosticGdspStateToInit: () => dispatch(getDiagnosticGdspInit())
	}
);

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticGdsp);
