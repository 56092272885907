import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './Automation.scss';

import Actions from '../../views/Actions';
import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';
import Rules from '../../views/Rules';
import Alerts from '../../views/Alerts';

import { renderTabs } from '../../../../utils/tabHelpers';

const tabNames = {
	RULES: 'rules',
	ALERTS: 'alerts'
};

class Automation extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: tabNames.RULES,
			deleteAlertsModalOpened: false
		};
	}

	onNewTabClick = async (tabName) => {
		const { setDataForFiltersInit, setSelectedItemsInit } = this.props;
		await setDataForFiltersInit();
		await setSelectedItemsInit();
		this.setState({
			activeTab: tabName
		});
	};

	getAutomationTabs = () => {
		const { activeTab, deleteAlertsModalOpened } = this.state;
		const {
			messages,
			automationAlertsRequest,
			automationRulesRequest
		} = this.props;
		const isAnyRequest = automationAlertsRequest || automationRulesRequest;
		const automationTabs = [
			{
				id: tabNames.RULES,
				name: (
					<FormattedMessage
						id="AUTOMATION.RULES_TAB_NAME"
						defaultMessage="Automation rules"
					/>
				),
				component: <Rules key="automation-rules" messages={messages} />,
				isActive: tabNames.RULES === activeTab,
				onClick: () => this.onNewTabClick(tabNames.RULES),
				disabled: isAnyRequest && activeTab !== tabNames.RULES
			},
			{
				id: tabNames.ALERTS,
				name: (
					<FormattedMessage
						id="AUTOMATION.ALERTS_TAB_NAME"
						defaultMessage="Automation alerts"
					/>
				),
				component: (
					<Alerts
						key="automation-alerts"
						messages={messages}
						deleteAlertsModalOpened={deleteAlertsModalOpened}
						closeDeleteAlertsModal={this.closeDeleteAlertsModal}
					/>
				),
				isActive: tabNames.ALERTS === activeTab,
				onClick: () => this.onNewTabClick(tabNames.ALERTS),
				disabled: isAnyRequest && activeTab !== tabNames.ALERTS
			}
		];
		return automationTabs;
	};

	openDeleteAlertsModal = () => {
		this.setState({
			deleteAlertsModalOpened: true
		});
	};

	closeDeleteAlertsModal = () => {
		this.setState({
			deleteAlertsModalOpened: false
		});
	};

	render() {
		const { activeTab } = this.state;
		const isAlertsTab = activeTab === tabNames.ALERTS;
		return (
			<div data-spec="automation">
				<PageTitle
					title={
						<FormattedMessage
							id={'AUTOMATION.TITLE'}
							defaultMessage={'Automation'}
						/>
					}
				/>
				<div className={styles.tabs}>
					<TabsBar tabs={this.getAutomationTabs()} />
				</div>
				<Actions isAlertsTab={isAlertsTab} openDeleteAlertsModal={this.openDeleteAlertsModal} />
				{renderTabs(this.getAutomationTabs())}
			</div>
		);
	}
}

const { object, bool, func } = PropTypes;

Automation.propTypes = {
	messages: object,
	automationAlertsRequest: bool,
	automationRulesRequest: bool,
	setDataForFiltersInit: func,
	setSelectedItemsInit: func
};

export default Automation;
