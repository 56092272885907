import React, { PureComponent } from 'react';
import {
	objectOf,
	string,
	func,
	bool,
	object,
} from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import SMSTabView from '../../views/SMSTabView';
import DiagnosticHlrDetails from '../HLRHSSDetails';
import GroupButton from '../GroupButton';
import DiagnosticsDetails from '../DiagnosticDetailsWing';
import RegistrationDetails from '../RegistrationDetailsWing';
import { ddFieldsWing, rdFields, ddCarrierField } from '../../utils/constants';

import styles from './DiagnosticWing.scss';

class DiagnosticWrapperWing extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedButton: 0,
			refresh: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { refresh } = this.state;

		if (prevState.refresh !== refresh) {
			this.refresh();
		}
	}

	refresh = () => this.setState({
		refresh: false,
	});

	startDiagnosticsButton = () => {
		const { initDiagnosticHlr, onButtonClick, iccid } = this.props;

		initDiagnosticHlr();
		onButtonClick(iccid, 'wing');
		this.setState({ selectedButton: 1 });
	};

	openSmsTab = () => this.setState({ selectedButton: 2, refresh: true });

	onDeviceWakeUp = () => {
		const { deviceWakeUp, iccid, intl } = this.props;

		deviceWakeUp(
			{
				data: {
					type: 'smsMessage',
					attributes: {
						message: 'DCS 0xF4',
					},
				},
			},
			iccid,
			{
				success_message: intl.formatMessage({
					id: 'DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_SUCCESS',
					defaultMessage: 'Device Wake Up Successful',
				}),
				error_message: intl.formatMessage({
					id: 'DEVICE_WAKE_UP_NOTIFICATION_MESSAGE_FAIL',
					defaultMessage: 'Device Wake Up Failed',
				}),
				retry_message: intl.formatMessage({
					id: 'RETRY',
					defaultMessage: 'Retry',
				}),
			},
		);
	};

	openHlrTab = () => {
		const { diagnosticsDetailsClear, getDiagnosticHlr, iccid } = this.props;

		diagnosticsDetailsClear();
		getDiagnosticHlr(iccid);
		this.setState({ selectedButton: 3 });
	};

	render() {
		const {
			iccid,
			endpoint = {},
			dataDiagnosticsDetails,
			dataRegistrationDetails,
			isFetchingDiagnosticsDetails,
			isFetchingRegistrationDetails,
			isSuccessEndpointsRegistrationDetails,
			isCanceling,
			submitCancelRegistration,
			deviceWakeUpRequest,
			isHLRPending,
			isHLRDone,
			isHLRFail,
			dataHLR,
			messages,
			user,
		} = this.props;
		const { selectedButton, refresh } = this.state;

		return (
			<div
				data-spec="diagnostics-wrapper"
				className={styles.diagnostics_wrapper}
			>
				<header className={styles.header}>
					<div className={styles.button_wrapper}>
						<GroupButton>
							<Button
								dataSpec="diagnostic-button"
								variant="outline-primary"
								onClick={this.startDiagnosticsButton}
								label={<FormattedMessage
									id="DIAGNOSTIC_BUTTON"
									defaultMessage="Start Diagnostics"
								/>}
								disabled={false}
							/>
							{isUserAllowedToAccess([
								'mnc.endpoints.smstab_rw',
								'mnc.endpoints.smstab_ro',
							], user) ? (
								<Button
									dataSpec="sms-button"
									variant="outline-primary"
									onClick={this.openSmsTab}
									label={<FormattedMessage
										id="SEND_SMS_TO_ENDPOINT"
										defaultMessage="Send SMS to Endpoint"
									/>}
									disabled={false}
								/>
							) : null}
							<Button
								dataSpec="device-wake-up"
								variant="outline-primary"
								onClick={this.onDeviceWakeUp}
								label={deviceWakeUpRequest ? (
									<FormattedMessage id="LOADING" defaultMessage="Loading" />
								) : (
									<FormattedMessage
										id="DEVICE_WAKE_UP"
										defaultMessage="Device Wake Up"
									/>
								)}
							/>
							<Button
								dataSpec="hlr-hss-details"
								variant="outline-primary"
								onClick={this.openHlrTab}
								label={<FormattedMessage
									id="SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS"
									defaultMessage="Get HLR/HSS Details"
								/>}
							/>
						</GroupButton>
					</div>
				</header>
				{selectedButton === 1 && (
					Object.keys(dataDiagnosticsDetails).length ||
					Object.keys(dataRegistrationDetails).length ||
					isFetchingDiagnosticsDetails ||
					isFetchingRegistrationDetails
				) && (
					<main className={styles.main}>
						<DiagnosticsDetails
							iccid={iccid}
							carrierName={endpoint.networkOperator}
							endpoint={endpoint}
							data={dataDiagnosticsDetails}
							fields={ddFieldsWing}
							isFetching={isFetchingDiagnosticsDetails}
							carrier={ddCarrierField}
						/>
						<RegistrationDetails
							iccid={iccid}
							endpoint={endpoint}
							data={dataRegistrationDetails}
							fields={rdFields}
							isFetching={isFetchingRegistrationDetails}
							isCanceling={isCanceling}
							submitCancelRegistration={submitCancelRegistration}
							isSuccess={isSuccessEndpointsRegistrationDetails}
						/>
					</main>
				)}
				{!refresh && selectedButton === 2 && (
					<SMSTabView
						messages={messages}
						id={iccid}
						m2mAccountId={endpoint.m2mAccountId}
					/>
				)}
				{selectedButton === 3 && (
					<main className={styles.main}>
						<DiagnosticHlrDetails
							isPending={isHLRPending}
							data={dataHLR}
							isFail={isHLRFail}
							isDone={isHLRDone}
						/>
					</main>
				)}
			</div>
		);
	}
}

DiagnosticWrapperWing.propTypes = {
	messages: objectOf(string),
	onButtonClick: func,
	iccid: string.isRequired,
	endpoint: object,
	deviceWakeUp: func,
	deviceWakeUpRequest: bool,
	dataDiagnosticsDetails: object,
	dataRegistrationDetails: object,
	isFetchingDiagnosticsDetails: bool,
	isFetchingRegistrationDetails: bool,
	isSuccessEndpointsRegistrationDetails: bool,
	isCanceling: bool,
	submitCancelRegistration: func,
	getDiagnosticHlr: func,
	isHLRPending: bool,
	isHLRDone: bool,
	isHLRFail: bool,
	dataHLR: object,
	diagnosticsDetailsClear: func,
	initDiagnosticHlr: func,
	intl: object,
	user: object,
};
DiagnosticWrapperWing.defaultProps = {
	messages: {},
	onButtonClick: undefined,
	endpoint: {},
	user: {},
};

export default injectIntl(DiagnosticWrapperWing);
