import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import AddCompany from './AddCompany';

import {
	addCompanySuccess,
	addCompanyRequest
} from '../../redux/companies/selectors';
import { addCompany } from '../../redux/companies/actions';

const mapStateToProps = (state) => ({
	addCompanyRequest: addCompanyRequest(state),
	addCompanySuccess: addCompanySuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	addCompany: (params) => dispatch(addCompany(params)),
	pushBack: (url) => dispatch(localizedRoute(url)),
	goToAddCompanySuccess: (url) => dispatch(localizedRoute(url))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCompany);
