import { connect } from 'react-redux';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import {
	setPaginationInit,
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import {
	openAutomationZonesModal,
	closeAutomationZonesModal,
	getAutomationZones
} from '../../redux/zones/actions';

import {
	isAutomationZonesModalOpen,
	getAutomationZonesFail,
	getAutomationZonesRequest,
	getAutomationZonesSuccess,
	patchFileUploadRequest,
	patchFileUploadSuccess
} from '../../redux/zones/selectors';

import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';

import AutomationZonesDesktop from './Desktop';
import AutomationZonesMobile from './Mobile';

const mapStateToProps = (state) => ({
	searchParameter: getSearchParameter(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
	isAutomationZonesModalOpen: isAutomationZonesModalOpen(state),
	automationZonesRequest: getAutomationZonesRequest(state),
	automationZonesFail: getAutomationZonesFail(state),
	automationZones: getAutomationZonesSuccess(state),
	uploadFileRequest: patchFileUploadRequest(state),
	uploadFile: patchFileUploadSuccess(state),
	user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit()),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	openAutomationZonesModal: () => dispatch(openAutomationZonesModal()),
	closeAutomationZonesModal: () => dispatch(closeAutomationZonesModal()),
	anyModalOpened: () => dispatch(anyModalOpened()),
	getAutomationZones: (params) => dispatch(getAutomationZones(params))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomationZonesDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomationZonesMobile);
