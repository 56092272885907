import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import { Desktop, Mobile } from '../../components/BatchReports';

const BatchReportsView = (props) => (
	<>
		<DesktopMediaQuery>
			<Desktop {...props} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Desktop {...props} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<Mobile {...props} />
		</MobileMediaQuery>
	</>
);
export default BatchReportsView;
