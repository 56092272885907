import {
	userIsAuthenticated,
	userInStandardMode
} from '../../../utils/AuthSelector';
import SecurityFeatures from '../components/SecurityFeatures';

export default [
	{
		path: '/security-features',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.securityfeatures_ro',
				'mnc.securityfeatures_rw'
			])(SecurityFeatures)
		)
	}
];
