/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { cloneDeep } from 'lodash';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';

import hoc from '../ActionBar';

import NewSearchBar from '../../../views/NewSearchBarView';
import SearchBar from '../../../views/SearchBarView';
import Switch from '../../../../../lib/DigitalComponents/Switch';

import styles from './Desktop.scss';

import { getNewFilterData } from '../../../../../utils/tableHelpers';
import { localizationHelper } from '../../../../../utils/helperFunctions';

const InfoIcon = ImageSelector(CurrentContext.theme, 'svgs/question-full.svg');
const FilterIcon = ImageSelector(CurrentContext.theme, 'svgs/filter.svg');
const RefreshPageIcon = ImageSelector(CurrentContext.theme, 'svgs/reset.svg');
const ClearFilterIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/clear-filters.svg'
);
const GuideSetupIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/guide-setup.svg'
);

const DownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg'
);

class Desktop extends React.PureComponent {
	onChange = async (e, category, item) => {
		const {
			setFilterData,
			setOriginalFilter,
			filterData,
			onChange,
			savedFiltersParams,
			updateUserSettings,
			filters,
			setPaginationInit
		} = this.props;
		const newFilterData = getNewFilterData(e, category, item, filterData);
		await setPaginationInit();
		await setFilterData(cloneDeep(newFilterData));
		// check if modal was opened ( filters was fetched )
		if (filters) {
			await setOriginalFilter(cloneDeep(newFilterData));
		}
		if (savedFiltersParams) {
			await updateUserSettings(
				{
					value: JSON.stringify({
						...JSON.parse(this.getUserSettings().value),
						filters: this.prepareFiltersForSettings()
					})
				},
				this.getUserSettings().id
			);
			savedFiltersParams.getData();
		} else {
			onChange();
		}
	};

	clearFilters = async () => {
		const {
			clearFilters,
			resetFilter,
			setFilterData,
			setOriginalFilter,
			savedFiltersParams,
			updateUserSettings,
			filters,
			setPaginationInit
		} = this.props;
		// check if modal was not opened ( filters was not fetched )
		await setPaginationInit();
		if (!filters) {
			await setFilterData({});
		} else {
			await setFilterData(cloneDeep(resetFilter));
			await setOriginalFilter(cloneDeep(resetFilter));
		}

		if (savedFiltersParams) {
			// here is logic for user settings
			await updateUserSettings(
				{
					value: JSON.stringify({
						...JSON.parse(this.getUserSettings().value),
						filters: this.prepareFiltersForSettings()
					})
				},
				this.getUserSettings().id
			);
			savedFiltersParams.getData();
		} else {
			clearFilters();
		}
	};

	getUserSettings = () => {
		const { userSettings, savedFiltersParams } = this.props;
		return userSettings.find(
			(x) =>
				x.name === savedFiltersParams.name &&
				x.refTypeId === savedFiltersParams.refTypeId
		);
	};

	prepareFiltersForSettings = () => {
		const { filterData } = this.props;
		let onlySelectedFilterDataArrays = {};
		let savedFilters = {};
		if (filterData) {
			Object.keys(filterData).forEach((key) => {
				filterData[key].forEach((filter) => {
					if (filter.checked) {
						onlySelectedFilterDataArrays = {
							...onlySelectedFilterDataArrays,
							[key]: filterData[key]
						};
					}
				});
				if (onlySelectedFilterDataArrays[key]) {
					savedFilters = {
						...savedFilters,
						[key]: onlySelectedFilterDataArrays[key]
							.filter((x) => x.checked)
							.map((filter) => filter.value)
					};
				}
			});
		}
		return savedFilters;
	};

	calculateFirst = () => {
		const { dataLimit, selectedPage } = this.props;

		return dataLimit * selectedPage + 1;
	};

	calculateLast = () => {
		const { totalCount, dataLimit, selectedPage } = this.props;
		const last = dataLimit * (selectedPage + 1);

		return totalCount && last < totalCount ? last : totalCount;
	};

	render() {
		const {
			openFilterModal,
			simpleSearchParameter,
			clearFilters,
			menu,
			onSearchSubmit,
			isSimpleSearchActive,
			actions,
			hasBorder,
			openEditTableModal,
			filterData,
			isDisabled,
			refreshPage,
			totalCount,
			showPagination,
			downloadFile,
			savedFiltersParams,
			onSearchSubmitCallback,
			showAdvancedSearchTooltip,
			showInputValueInTooltip
		} = this.props;

		const last = this.calculateLast();
		let filterDataKeys;
		if (filterData) {
			filterDataKeys = Object.keys(filterData);
		}

		let checkedFilters = [];

		const actionBarClassname = cn({
			[styles.action_bar_border]: hasBorder,
			[styles.action_bar]: !hasBorder,
			[styles.opacity]: isDisabled
		});
		return (
			<div data-spec="action-bar-desktop" className={actionBarClassname}>
				{isDisabled && <div className={styles.loading_cover} />}
				<div className={styles.action_bar_active_filters}>
					{filterDataKeys &&
						filterDataKeys.length > 0 &&
						filterDataKeys.map((item) => {
							const category = item;
							const activeFilters = filterData[item].filter(
								(filterItem) => filterItem.checked
							);
							checkedFilters = [...checkedFilters, ...activeFilters];
							return activeFilters.map((activeFilterItem) => (
								<div
									key={activeFilterItem.value}
									className={styles.switch_wrapper}
									data-spec="switch-wrapper"
								>
									<FormattedMessage
										id={`ACTION_BAR.${localizationHelper(category)}`}
										defaultMessage={category}
									>
										{(formattedValue) => (
											<div
												data-tip={formattedValue}
												data-for={`switchTooltip-${activeFilterItem.label}`}
											>
												<Switch
													data-spec="switch"
													checked={activeFilterItem.checked}
													label={activeFilterItem.label}
													onChange={(e) =>
														this.onChange(e, category, activeFilterItem)
													}
												/>
											</div>
										)}
									</FormattedMessage>

									<ReactTooltip
										className={styles.tooltip}
										type="light"
										id={`switchTooltip-${activeFilterItem.label}`}
									/>
								</div>
							));
						})}
				</div>
				<div className={styles.action_bar_right}>
					{showPagination && totalCount ? (
						<div className={styles.total_number}>
							<FormattedMessage
								id={'ACTION_BAR.SHOWING'}
								defaultMessage={'Showing'}
							/>
							&nbsp;
							{this.calculateFirst()}
							{last && `-${last}`}
							&nbsp;
							<FormattedMessage id={'ACTION_BAR.OF'} defaultMessage={'of'} />
							&nbsp;
							{totalCount}
						</div>
					) : null}
					{onSearchSubmit && (
						<SearchBar
							menu={menu}
							onSubmit={onSearchSubmit}
							simpleSearchParameter={simpleSearchParameter}
							isSimpleSearchActive={isSimpleSearchActive}
						/>
					)}
					{onSearchSubmitCallback && menu && (
						<NewSearchBar
							onSearchSubmitCallback={onSearchSubmitCallback}
							menu={menu}
							showInputValueInTooltip={showInputValueInTooltip}
						/>
					)}
					{showAdvancedSearchTooltip && (
						<div className={`${styles.icon} ${styles.info_icon}`}>
							<FormattedMessage
								id="ACTION_BAR.SEARCH_INFO"
								defaultMessage="You can search by wildcard (ie. Endpoint ID*) or perform multiple searches separated by comma (ie. Endpoint 1, Endpoint 2)."
							>
								{(formattedValue) => (
									<>
										<InfoIcon
											data-tip={formattedValue}
											data-for="searchInfo"
											data-spec="question-icon"
										/>
										<ReactTooltip
											className={styles.tooltip_blue}
											type="light"
											id="searchInfo"
										/>
									</>
								)}
							</FormattedMessage>
						</div>
					)}
					<div
						className={
							clearFilters || openFilterModal
								? styles.action_bar_icons
								: cn(styles.action_bar_icons, styles.empty)
						}
					>
						{refreshPage && (
							<FormattedMessage
								id={'ACTION_BAR.REFRESH_PAGE'}
								defaultMessage={'Refresh page'}
							>
								{(formattedValue) => (
									<div className={`${styles.icon} ${styles.refresh_page_icon}`}>
										<RefreshPageIcon
											onClick={() => refreshPage()}
											data-tip={formattedValue}
											data-for="refreshTooltip"
											data-spec="refresh-icon"
										/>
										<ReactTooltip
											className={styles.tooltip}
											type="light"
											id="refreshTooltip"
										/>
									</div>
								)}
							</FormattedMessage>
						)}
						{(clearFilters || savedFiltersParams) && (
							<div className={styles.clear_filter}>
								<FormattedMessage
									id={'ACTION_BAR.CLEAR_FILTERS'}
									defaultMessage={'Clear selected filters'}
								>
									{(formattedValue) => (
										<div
											className={`${styles.icon} ${
												!checkedFilters.length && styles.disabled
											}`}
										>
											<ClearFilterIcon
												onClick={
													!checkedFilters.length
														? undefined
														: () => this.clearFilters()
												}
												data-spec="reset-filter-icon"
												data-tip={formattedValue}
												data-for="clearFiltersTooltip"
											/>
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id="clearFiltersTooltip"
											/>
										</div>
									)}
								</FormattedMessage>
							</div>
						)}
						{openFilterModal && (
							<div className={styles.icon}>
								<FormattedMessage
									id={'ACTION_BAR.SELECT_FILTERS'}
									defaultMessage={'Select Filters'}
								>
									{(formattedValue) => (
										<div>
											<FilterIcon
												onClick={() => {
													openFilterModal();
												}}
												data-spec="filter-icon"
												data-tip={formattedValue}
												data-for="selectFiltersTooltip"
											/>
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id="selectFiltersTooltip"
											/>
										</div>
									)}
								</FormattedMessage>
							</div>
						)}
						{openEditTableModal && (
							<div
								className={`${styles.icon} ${styles.edit_table_icon}`}
								data-spec="edit-table-icon"
							>
								<FormattedMessage
									id={'ACTION_BAR.EDIT_TABLE_PARAMETERS'}
									defaultMessage={'Edit Table Parameters'}
								>
									{(formattedValue) => (
										<div>
											{
												<GuideSetupIcon
													onClick={() => {
														openEditTableModal();
													}}
													data-tip={formattedValue}
													data-for="editTableParametersTooltip"
													data-spec="guide-setup-icon"
												/>
											}
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id="editTableParametersTooltip"
											/>
										</div>
									)}
								</FormattedMessage>
							</div>
						)}
						{downloadFile && (
							<div className={`${styles.icon}`} data-spec="download-file-icon">
								<FormattedMessage
									id={'ACTION_BAR.DOWNLOAD_FILE'}
									defaultMessage={'Download File'}
								>
									{(formattedValue) => (
										<div>
											{
												<DownloadIcon
													onClick={() => {
														downloadFile();
													}}
													data-tip={formattedValue}
													data-for="downloadFileTooltip"
													data-spec="download-file-icon"
												/>
											}
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id="downloadFileTooltip"
											/>
										</div>
									)}
								</FormattedMessage>
							</div>
						)}
						{actions}
					</div>
				</div>
			</div>
		);
	}
}

const { bool, func, object, arrayOf, shape, number, array, oneOfType } = PropTypes;

Desktop.propTypes = {
	menu: arrayOf(object),
	simpleSearchParameter: arrayOf(object),
	onSearchSubmit: func,
	filterData: shape(),
	openFilterModal: oneOfType([func, bool]),
	clearFilters: oneOfType([func, bool]),
	isSimpleSearchActive: bool,
	actions: shape(),
	hasBorder: bool,
	openEditTableModal: func,
	onChange: func,
	resetFilter: shape(),
	setFilterData: func,
	setOriginalFilter: func,
	isDisabled: bool,
	showPagination: bool,
	totalCount: number,
	selectedPage: number,
	dataLimit: number,
	refreshPage: func,
	downloadFile: func,
	savedFiltersParams: oneOfType([object, bool]),
	updateUserSettings: func,
	userSettings: array,
	filters: object,
	setPaginationInit: func,
	onSearchSubmitCallback: func,
	showAdvancedSearchTooltip: bool,
	showInputValueInTooltip: bool
};

export default hoc()(Desktop);
