import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';
import globalModalReducer from '../../../../redux/global/globalModalReducer';

import {
	GET_ACCOUNT_ENDPOINTS,
	MOVE_TO_SUB_ACCOUNT_MODAL,
	MOVE_ACCOUNT_ENDPOINTS
} from './constants';

export default combineReducers({
	getEndpoints: globalReducer(GET_ACCOUNT_ENDPOINTS),
	moveToSubAccountModal: globalModalReducer(MOVE_TO_SUB_ACCOUNT_MODAL),
	moveEndpoints: globalReducer(MOVE_ACCOUNT_ENDPOINTS)
});
