import { createAction } from 'redux-actions';

import globalAction, { actionInit } from '../../../redux/global/globalAction';
import { postRatePlan } from '../../Endpoint/services/EndpointsService';
import { downloadHelper } from '../../AnalyticsInvoices/redux/invoiceAttachment/actions';
import {
	hideNotification,
	showNotification,
} from '../../Shared/redux/notifications/actions';
import {
	getSingleEndpoint as serviceGetSingleEndpoint,
	postCancelRegistration,
	postCancelPending,
	changeNetworkEntitlement as serviceChangeNetworkEntitlement,
	patchEndpointCustomFields,
	getRegistrationHistory,
	getDataHistory as serviceGetDataHistory,
	getMessageHistory as serviceGetMessageHistory,
	getNetworkEntitlement as serviceGetNetworkEntitlement,
	getNetworkEntitlementApn as serviceGetNetworkEntitlementApn,
	getNote as serviceGetNote,
	getHistory,
	postm2mAccount,
	updateNote as serviceUpdateNote,
	postDiagnosticSMSMessage,
	getBlockedSubscription as serviceGetBlockedSubscription,
	postSMSMessage,
	getDiagnosticCcip as serviceGetDiagnosticCcip,
	getDiagnosticDcp as serviceGetDiagnosticDcp,
	getDiagnosticGdsp as serviceGetDiagnosticGdsp,
	getDiagnosticHlr as serviceGetDiagnosticHlr,
	getDiagnostics as serviceGetDiagnostic,
	getRegistration as serviceGetRegistration,
	getTroubleshootDcp as serviceGetTroubleshootDcp,
	getEndpointUsageData as serviceGetEndpointUsageData,
	getRatePlans as serviceGetRatePlans,
	patchSingleEndpoint,
	getSessionHistory as serviceGetSessionHistory,
	exportSessions as serviceExportSessions,
	getM2MAccount as serviceGetM2MAccount,
	exportUsageData as serviceExportDataUsage,
	pollForFile,
} from '../services/SingleEndpointService';
import {
	CANCEL_REGISTRATION,
	CANCEL_SCHEDULE_STATUS,
	CHANGE_NETWORK_ENTITLEMENT,
	GET_ENDPOINT_REGISTRATION_HISTORY,
	GET_DATA_HISTORY,
	GET_MESSAGE_HISTORY,
	SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT,
	GET_NETWORK_ENTITLEMENT_APN,
	GET_NOTE,
	GET_HISTORY_CHANGE,
	UPDATE_CUSTOM_FIELDS_VALUES,
	UPDATE_M2M_ACCOUNT,
	UPDATE_NOTE,
	SEND_DIAGNOSTIC_SMS_MESSAGE,
	SEND_SMS_MESSAGE,
	GET_BLOCKED_SUBSCRIPTION_DCP,
	GET_CCIP_ENDPOINTS_DATA,
	GET_DCP_ENDPOINTS_DATA,
	GET_GDSP_ENDPOINTS_DATA,
	GET_DIAGNOSTIC_HLR,
	GET_DIAGNOSTICS_DETAILS,
	GET_REGISTRATION_DETAILS,
	GET_TROUBLESHOOT_DCP,
	GET_ENDPOINT_USAGE_DATA,
	UPDATE_RATE_PLAN,
	SINGLE_ENDPOINT_GET_RATE_PLANS,
	UPDATE_SINGLE_ENDPOINT,
	GET_SINGLE_ENDPOINT,
	GET_SESSION_HISTORY,
	EXPORT_SESSIONS_DATA_CSV,
	GET_SINGLE_ENDPOINT_M2M_ACCOUNTS,
	EXPORT_USAGE_DATA,
} from './constants';

export const cancelRegistration = (
	iccid,
	locationData,
	platform,
) => globalAction(
	CANCEL_REGISTRATION,
	postCancelRegistration,
	{ iccid, locationData, platform },
	{
		notify: 'cancel-registration',
		notifyMessageSuccess: 'SUCCESS',
		notifyMessageError: 'ERROR',
	}
);

export const cancelScheduleStatus = (referenceId) => globalAction(
	CANCEL_SCHEDULE_STATUS,
	postCancelPending,
	referenceId,
	{
		notify: 'cancel-pending',
		notifyMessageSuccess: 'CANCEL_PENDING_SUCCESS_TEXT',
		notifyMessageError: 'CANCEL_PENDING_FAIL_TEXT',
	}
);

export const changeNetworkEntitlement = (
	endpointId,
	networkEntitlementId,
	startDate,
) => globalAction(
	CHANGE_NETWORK_ENTITLEMENT,
	serviceChangeNetworkEntitlement,
	{ endpointId, networkEntitlementId, startDate },
);

export const changeNetworkEntitlementInit = () => actionInit(
	CHANGE_NETWORK_ENTITLEMENT
);

export const getRegistrationHistoryData = (data) => globalAction(
	GET_ENDPOINT_REGISTRATION_HISTORY,
	getRegistrationHistory,
	data,
);

export const getDataHistory = (params, deviceId) => globalAction(
	GET_DATA_HISTORY,
	serviceGetDataHistory,
	{ params, deviceId }
);

export const getDataHistoryInit = () => actionInit(GET_DATA_HISTORY);

export const getMessageHistory = (params, deviceId) => globalAction(
	GET_MESSAGE_HISTORY,
	serviceGetMessageHistory,
	{ params, deviceId },
);

export const getMessageHistoryInit = () => actionInit(GET_MESSAGE_HISTORY);

export const getNetworkEntitlement = (networkEntitlementId) => globalAction(
	SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT,
	serviceGetNetworkEntitlement,
	networkEntitlementId,
);

export const getNetworkEntitlementInit = () => actionInit(
	SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT,
);

export const getNetworkEntitlementApn = (
	m2mAccountId,
	networkEntitlementId
) => globalAction(
	GET_NETWORK_ENTITLEMENT_APN,
	serviceGetNetworkEntitlementApn,
	{ m2mAccountId, networkEntitlementId },
);

export const getNote = (deviceId, m2mAccountId) => globalAction(
	GET_NOTE,
	serviceGetNote,
	{ deviceId, m2mAccountId },
);

export const getHistoryChanges = (params) => globalAction(
	GET_HISTORY_CHANGE,
	getHistory,
	params,
);

export const getHistoryChangesInit = () => actionInit(GET_HISTORY_CHANGE);

export const updateCustomFieldsValues = (id, fields) => globalAction(
	UPDATE_CUSTOM_FIELDS_VALUES,
	patchEndpointCustomFields,
	{ id, fields },
	{
		notify: 'update-custom-fields',
		notifyMessageSuccess: 'CUSTOM_FIELDS_VALUES_UPDATED',
		notifyMessageError: 'CUSTOM_FIELDS_VALUES_UPDATED_ERROR',
	},
);

export const updateCustomFieldsValuesInit = () => actionInit(
	UPDATE_CUSTOM_FIELDS_VALUES,
);

export const updateM2MAccount = (props) => globalAction(
	UPDATE_M2M_ACCOUNT,
	postm2mAccount,
	props,
);

export const updateM2MAccountInit = () => actionInit(UPDATE_M2M_ACCOUNT);

export const sendDiagnosticSMSMessage = (
	iccid,
	messageContent,
	m2mAccountId
) => globalAction(
	SEND_DIAGNOSTIC_SMS_MESSAGE,
	postDiagnosticSMSMessage,
	{ iccid, messageContent, m2mAccountId },
	{
		notify: 'send-sms',
		notifyMessageSuccess: 'SMS_NOTIFICATION_MESSAGE_SUCCESS',
		notifyMessageError: 'SMS_NOTIFICATION_MESSAGE',
	}
);

export const updateNote = (
	deviceId,
	m2mAccountId,
	data,
	flag,
	isSingleEndpointView,
) => {
	const notificationObject = {
		notify: 'add-rule',
		notifyMessageSuccess: flag === 'delete'
			? 'DELETE_NOTE_SUCCESS'
			: 'ADD_NOTE_SUCCESS',
		notifyMessageError: flag === 'delete'
			? 'DELETE_NOTE_ERROR'
			: 'ADD_NOTE_ERROR',
	};

	return globalAction(
		UPDATE_NOTE,
		serviceUpdateNote,
		{
			deviceId,
			m2mAccountId,
			data,
			flag,
		},
		isSingleEndpointView ? notificationObject : undefined,
	);
};

const sendSmsMessageRequest = createAction(`${SEND_SMS_MESSAGE}_REQUEST`);
const sendSmsMessageSuccess = createAction(`${SEND_SMS_MESSAGE}_SUCCESS`);
const sendSmsMessageFail = createAction(`${SEND_SMS_MESSAGE}_FAIL`);

export const sendSMSMessage = (
	message,
	iccid,
	platform,
	notification,
) => async (dispatch) => {
	dispatch(sendSmsMessageRequest());
	try {
		const data = await postSMSMessage({ message, iccid, platform });

		dispatch(sendSmsMessageSuccess(data));
		dispatch(showNotification({
			id: 'send-sms-success',
			message: [notification.success_message],
			type: 'success',
			notificationType: 'toaster',
			close: true,
			duration: 5000,
		}));
	} catch (error) {
		dispatch(sendSmsMessageFail(error.toString()));
		dispatch(showNotification({
			id: 'send-sms-fail',
			message: [notification.error_message],
			type: 'error',
			notificationType: 'toaster',
			actionText: notification.retry_message,
			action: () => {
				dispatch(hideNotification('send-sms-fail'));
				dispatch(sendSMSMessage(message, iccid, platform));
			}
		}));
	}
};

export const getBlockedSubscriptionDcp = (iccid, m2mAccountId) => globalAction(
	GET_BLOCKED_SUBSCRIPTION_DCP,
	serviceGetBlockedSubscription,
	{ iccid, m2mAccountId },
);

export const getBlockedSubscriptionDcpInit = () => actionInit(
	GET_BLOCKED_SUBSCRIPTION_DCP,
);

export const getDiagnosticCcip = (iccid) => globalAction(
	GET_CCIP_ENDPOINTS_DATA,
	serviceGetDiagnosticCcip,
	iccid,
);

export const getDiagnosticCcipInit = () => actionInit(GET_CCIP_ENDPOINTS_DATA);

export const getDiagnosticDcp = (iccid) => globalAction(
	GET_DCP_ENDPOINTS_DATA,
	serviceGetDiagnosticDcp,
	iccid,
);

export const getDiagnosticDcpInit = () => actionInit(GET_DCP_ENDPOINTS_DATA);

export const getDiagnosticGdsp = (iccid) => globalAction(
	GET_GDSP_ENDPOINTS_DATA,
	serviceGetDiagnosticGdsp,
	iccid,
);

export const getDiagnosticGdspInit = () => actionInit(GET_GDSP_ENDPOINTS_DATA);

export const getDiagnosticHlr = (deviceId) => globalAction(
	GET_DIAGNOSTIC_HLR,
	serviceGetDiagnosticHlr,
	deviceId,
);

export const getDiagnosticHlrInit = () => actionInit(GET_DIAGNOSTIC_HLR);

export const getDiagnostic = (iccid, platform) => globalAction(
	GET_DIAGNOSTICS_DETAILS,
	serviceGetDiagnostic,
	{ iccid, platform },
);

export const getDiagnosticInit = () => actionInit(GET_DIAGNOSTICS_DETAILS);

export const getRegistration = (iccid) => globalAction(
	GET_REGISTRATION_DETAILS,
	serviceGetRegistration,
	iccid,
);

export const getRegistrationInit = () => actionInit(GET_REGISTRATION_DETAILS);

export const getTroubleshootDcp = (iccid) => globalAction(
	GET_TROUBLESHOOT_DCP,
	serviceGetTroubleshootDcp,
	iccid,
);

export const getTroubleshootDcpInit = () => actionInit(GET_TROUBLESHOOT_DCP);

export const getEndpointUsageData = (
	imsi,
	params,
	type,
	platform
) => globalAction(
	GET_ENDPOINT_USAGE_DATA,
	serviceGetEndpointUsageData,
	{ imsi, params, type, platform },
);

export const updateRatePlan = (props) => globalAction(
	UPDATE_RATE_PLAN,
	postRatePlan,
	props,
);

export const updateRatePlanInit = () => actionInit(UPDATE_RATE_PLAN);

export const getRatePlans = (m2mAccountId) => globalAction(
	SINGLE_ENDPOINT_GET_RATE_PLANS,
	serviceGetRatePlans,
	m2mAccountId,
);

export const updateSingleEndpoint = (props) => globalAction(
	UPDATE_SINGLE_ENDPOINT,
	patchSingleEndpoint,
  props
);

export const updateSingleEndpointInit = () => actionInit(UPDATE_SINGLE_ENDPOINT);

export const getSingleEndpoint = (iccid, type) => globalAction(
	GET_SINGLE_ENDPOINT,
	serviceGetSingleEndpoint,
	{ iccid, type },
);

export const getSessionHistory = (id) => globalAction(
	GET_SESSION_HISTORY,
	serviceGetSessionHistory,
	id,
);

const request = createAction(`${EXPORT_SESSIONS_DATA_CSV}_REQUEST`);
const success = createAction(`${EXPORT_SESSIONS_DATA_CSV}_SUCCESS`);
const fail = createAction(`${EXPORT_SESSIONS_DATA_CSV}_FAIL`);

export const exportSessions = (id, intl) => async (dispatch) => {
	dispatch(request());
	try {
		const data = await serviceExportSessions(id);

		dispatch(success(data));
		downloadHelper(false, `${intl.formatMessage(
			{
				id: 'EXPORT_SESSIONS_DATA_FOR_ID',
				defaultMessage: 'Recent Sessions for {id}',
			},
			{
				id,
			},
		)}.csv`, data.data, 'text/csv:encoding:utf-8');
	} catch (error) {
		dispatch(fail(error.toString()));
	}
};

export const getM2MAccount = () => globalAction(
	GET_SINGLE_ENDPOINT_M2M_ACCOUNTS,
	serviceGetM2MAccount,
);

const requestExportUsageData = createAction(`${EXPORT_USAGE_DATA}_REQUEST`);
const successExportUsageData = createAction(`${EXPORT_USAGE_DATA}_SUCCESS`);
const failExportUsageData = createAction(`${EXPORT_USAGE_DATA}_FAIL`);
const initExportUsageData = createAction(`${EXPORT_USAGE_DATA}_INIT`);

export const exportUsageData = (params, formatMessage) => async (dispatch) => {
	dispatch(initExportUsageData());

	try {
		dispatch(requestExportUsageData());

		const fileName = 'Export Sessions Data.csv';
		const response = await serviceExportDataUsage(params);
		const pollResponse = await pollForFile(response.data.id, 4000);

		dispatch(successExportUsageData(response));

		return downloadHelper(pollResponse.downloadUrl, fileName);
	} catch (error) {
		dispatch(showNotification({
			id: 'export-usage-data-failed',
			title: formatMessage({
				id: 'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE',
				defaultMessage: 'Exporting Data Failed',
			}),
			message: [
				formatMessage({
					id: 'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE',
					defaultMessage: 'Cannot export usage data at the moment.',
				})
			],
			type: 'error',
			notificationType: 'toaster',
			close: false,
		}));
		dispatch(failExportUsageData(error.toString()));
	}
};
