import { connect } from 'react-redux';
import Steps from './Steps';
import { getBatchFileUploadFail } from '../../../redux/batchFiles/selectors';

const mapStateToProps = (state) => ({
		batchFileUploadFail: getBatchFileUploadFail(state)
	});

export default connect(
	mapStateToProps,
	null
)(Steps);
