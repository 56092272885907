import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import DSSteps from '../../../../lib/DigitalComponents/Steps';
import StepFirst from './Steps/StepFirst';
import StepSecond from './Steps/StepSecond';
import Notifications from '../../../../lib/DigitalComponents/Notifications';
import styles from './RatePlanScheduleModal.scss';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import {
	RatePlanScheduleModalContext,
	RatePlanScheduleModalContextProvider,
	RatePlanScheduleModalContextConsumer
} from './RatePlanScheduleModalContext';

class ScheduleModal extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onFinish = () => {
		const { onCancel } = this.props;
		onCancel();
	};

	mergeValues = (values) => {
		this.setState({
			...values
		});
	};

	renderNotifications = (endpoint) => {
    const { intl } = this.props;
		const item = {
			id: 'endpoint-rateplan-change-error',
			type: 'error',
			title: (
				<FormattedMessage
					id="PROVISIONING_ERROR"
					defaultMessage="Provisioning Error"
				/>
			),
			message: [
				intl.formatMessage(
					{
						id: 'ENDPOINTS.NOTIFICATION_ERROR',
						defaultMessage: `Endpoind ID ${
							endpoint ? endpoint.iccid : ''
						} failed to provision after Rate plan change. Get support`
					},
					{
						endpointICCID: endpoint ? endpoint.iccid : ''
					}
				)
			]
		};

		return (
			<div data-spec="notification-wrapper" className={styles.notification_margin}>
				<Notifications data-spec="notification" {...item} />
			</div>
		);
	};

	render() {
		const {
			onCancel,
			newRatePlan,
			endpoint,
			user,
			fetchSingleEndpoint,
			type,
			endpointName,
			ratePlanUpdateFail,
			show
		} = this.props;

		const customSteps = [
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP"
						defaultMessage="1. Schedule Rate plan change"
					/>
				),
				component: (
					<RatePlanScheduleModalContextConsumer>
						<StepFirst
							onCancel={onCancel}
							newRatePlan={newRatePlan}
							endpoint={endpoint}
							user={user}
						/>
					</RatePlanScheduleModalContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SYSTEM_CONFIRMATION_STEP"
						defaultMessage="2. System confirmation"
					/>
				),
				component: (
					<RatePlanScheduleModalContextConsumer>
						<StepSecond
							values={this.state}
							onCancel={onCancel}
							fetchSingleEndpoint={fetchSingleEndpoint}
							type={type}
							endpointName={endpointName}
						/>
					</RatePlanScheduleModalContextConsumer>
				)
			}
		];

		const tabs = [
			{
				id: 'ratePlanScheduleSteps',
				displayName: (
					<FormattedMessage
						id="SCHEDULE_RATE_PLAN_CHANGE"
						defaultMessage="Schedule Rate plan change"
					/>
				),
				tabContentClassName: styles.modal_tab_content,
				content: () => (
					<div key="ratePlanScheduleModal">
						<DSSteps
							steps={customSteps}
							onNext={this.mergeValues}
							noActions
							onBack={this.mergeValues}
							onFinish={this.onFinish}
							isActionError={ratePlanUpdateFail}
						/>
					</div>
				),
			},
		];

		return (
			<div data-spec="batch modal">
				<RatePlanScheduleModalContextProvider>
					<Modal show={show} onClose={onCancel} className={styles.modal}>
						<ModalHeader
							title={
								<FormattedMessage
									id="ENDPOINTS.RATE_PLAN_CHANGE"
									defaultMessage="Rate Plan change"
								/>
							}
						/>
						<ModalBody className={styles.modal_body_notf}>
							{ratePlanUpdateFail && this.renderNotifications(endpoint)}
							<ModalTabs tabs={tabs} />
						</ModalBody>
						<ModalFooter>
							<RatePlanScheduleModalContext.Consumer>
								{(context) => context.state.footer}
							</RatePlanScheduleModalContext.Consumer>
						</ModalFooter>
					</Modal>
				</RatePlanScheduleModalContextProvider>
			</div>
		);
	}
}

const { bool, func, object, string } = PropTypes;

ScheduleModal.propTypes = {
	show: bool,
	ratePlanUpdateFail: bool,
	onCancel: func,
	newRatePlan: object,
	endpoint: object,
	user: object,
	fetchSingleEndpoint: func,
	type: string,
	endpointName: string,
  isPendingStatus: bool,
  intl: object,
};

ScheduleModal.defaultProps = {
	show: false,
	ratePlanUpdateFail: false,
	onCancel: undefined,
	newRatePlan: {},
	endpoint: {},
	user: {},
	fetchSingleEndpoint: undefined,
	type: '',
	endpointName: '',
	isPendingStatus: false
};

export default injectIntl(ScheduleModal);
