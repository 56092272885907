import { handleActions } from 'redux-actions';

export const initialState = {
	isOpen: false
};

export default (prefix, customActionHandlers = {}) =>
	handleActions(
		{
			[`OPEN_${prefix}`]: (state) => ({
				...state,
				isOpen: true
			}),
			[`CLOSE_${prefix}`]: (state) => ({
				...state,
				isOpen: false
			}),
			...customActionHandlers
		},
		initialState
	);
