import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import {
	getMessageHistory as getMessageHistoryAction,
	sendDiagnosticSMSMessage,
} from '../../redux/actions';
import {
	getMessageHistoryPending,
	getMessageHistorySuccess,
	sendDiagnosticSmsMessageRequest,
	sendDiagnosticSmsMessageSuccess,
} from '../../redux/selectors';
import Desktop from './Desktop';
import Mobile from './Mobile';

const mapStateToProps = (state) => (
	{
		formValues: getFormValues('sendSMSForm')(state),
		sendSmsMessageRequest: sendDiagnosticSmsMessageRequest(state),
		initialValues: {
			message: '',
		},
		isFetching: getMessageHistoryPending(state),
		messageHistoryData: getMessageHistorySuccess(state),
		sendSmsMessageSuccess: sendDiagnosticSmsMessageSuccess(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		sendSmsMessage: (iccid, message, m2mAccountId) => (
			dispatch(sendDiagnosticSMSMessage(iccid, message, m2mAccountId))
		),
		getMessageHistory: (params, deviceId) => (
			dispatch(getMessageHistoryAction(params, deviceId))
		),
	}
);

export const DesktopSMSTab = connect(
	mapStateToProps,
	mapDispatchToProps
)(Desktop);
export const MobileSMSTab = connect(
	mapStateToProps,
	mapDispatchToProps
)(Mobile);
