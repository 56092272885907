import { handleActions } from 'redux-actions';

import { Status } from '../../../../utils/constants';

import {
	EXPORT_CSV_FAIL,
	EXPORT_CSV_REQUEST,
	EXPORT_CSV_SUCCESS
} from '../constants';

export const initialState = {
	status: Status.INIT,
	error: null
};

export default handleActions(
	{
		[EXPORT_CSV_REQUEST]: (state) => ({
				...state,
				status: Status.PENDING,
				error: null
			}),
		[EXPORT_CSV_SUCCESS]: (state) => ({
				...state,
				status: Status.DONE,
				error: null
			}),
		[EXPORT_CSV_FAIL]: (state, action) => ({
				...state,
				status: Status.FAIL,
				error: action.payload
			})
	},
	initialState
);
