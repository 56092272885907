import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../Users';

const Mobile = (props) => {
	const { getOptions, messages, users } = props;
	return (
		<div data-spec="user-users-mobile">
			<Table
				options={getOptions('mobile')}
				messages={messages}
				data={users.resultList}
			/>
		</div>
	);
};

const { func, object, shape } = PropTypes;

Mobile.propTypes = {
	getOptions: func,
	messages: object,
	users: shape()
};

export default hoc()(Mobile);
