export default {
	'SHARED.NO_DATA_AVAILABLE': 'Geen data beschikbaar',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE': 'Er zijn momenteel geen data beschikbaar om te laten zien',
	'SHARED.EXIT_THIS_COMPANY': 'Afsluiten',
	// ACTIEBALK GESELECTEERDE FILTERTOOLTIP
	// ** EINDPUNTEN **
	'ACTION_BAR.ENDPOINTSTATUS': 'Status',
	'ACTION_BAR.NETWORKOPERATOR': 'Netwerkoperator',
	'ACTION_BAR.NETWORKCOUNTRY': 'Land netwerk',
	'ACTION_BAR.NETWORKENTITLEMENT': 'Netwerkrecht-id',
	'ACTION_BAR.NETWORKTYPE': 'Type netwerk',
	'ACTION_BAR.TRANSFERSTATUS': 'Status IMSI-overdracht',
	'ACTION_BAR.RATEPLAN': 'Tariefplan',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'Status hertoewijzing referenties',
	'ACTION_BAR.M2MPLATFORM': 'M2M-platform',
	'ACTION_BAR.ROAMINGCOUNTRY': 'Roamingland',
	'ACTION_BAR.ROAMINGCARRIER': 'Roamingprovider',
	'ACTION_BAR.SERVICESTATUS': 'Status 360L',
	'ACTION_BAR.OEM': 'Subaccounts',
	'ACTION_BAR.M2MDATEADDED': 'Datum M2M toegevoegd',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'Datum activering M2M',
	//  **  FACTUREN  **
	'ACTION_BAR.M2MACCOUNTID': 'Id M2M-account',
	'ACTION_BAR.CUSTOMERNAME': 'Naam klant',
	'ACTION_BAR.INVOICENUMBER': 'Factuurnummer',
	'ACTION_BAR.COUNTRYNAME': 'Naam land',
	'ACTION_BAR.BILLINGCYCLE': 'Factureringscyclus',
	'ACTION_BAR.CUSTOMERID': 'Klant-id',
	'ACTION_BAR.INVOICEDATE': 'Factuurdatum',
	//  **  RAPPORTEN **
	'ACTION_BAR.M2M_ACC_ID': 'Id M2M-account',
	'ACTION_BAR.M2M_PLATFORM': 'M2M-platform',
	'ACTION_BAR.PLATFORM': 'Platform',
	'ACTION_BAR.FREQUENCY': 'Frequentie',
	'ACTION_BAR.REPORT_NAME': 'Naam rapport',
	'ACTION_BAR.SERVICEPROVIDER': 'Platform',
	'ACTION_BAR.REPORTFREQUENCY': 'Frequentie',
	//  **  SIM-BESTELLING  **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': 'Status',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'M2M-platform',
	'ACTION_BAR.FILTER_CATEGORY_SKU': 'SKU',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Spoed',
	//  **  BEVEILIGINGSFUNCTIES **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'M2M-account',
	//  **  TICKETSYSTEEM **
	'ACTION_BAR.CATEGORIES': 'Categorieën',
	'ACTION_BAR.STATUS': 'Status',
	'ACTION_BAR.SEVERITY': 'Ernst',
	//  ACTIEBALK
	'ACTION_BAR.CLEAR_FILTERS': 'Filters wissen',
	'ACTION_BAR.SELECT_FILTERS': 'Filters selecteren',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Tabelparameters bewerken',
	'ACTION_BAR.SHOWING': 'Weergegeven',
	'ACTION_BAR.OF': 'van',
	'ACTION_BAR.DOWNLOAD_FILE': 'Bestand downloaden',
	'ACTION_BAR.REFRESH_PAGE': 'Pagina vernieuwen',
	//  ******************* MODAAL FILTER *****************************
	//  **  EINDPUNTEN **
	'FILTER_MODAL.ENDPOINTSTATUS': 'Status',
	'FILTER_MODAL.NETWORKOPERATOR': 'Netwerkoperator',
	'FILTER_MODAL.NETWORKCOUNTRY': 'Land netwerk',
	'FILTER_MODAL.NETWORKENTITLEMENT': 'Netwerkrecht-id',
	'FILTER_MODAL.NETWORKTYPE': 'Type netwerk',
	'FILTER_MODAL.TRANSFERSTATUS': 'Status IMSI-overdracht',
	'FILTER_MODAL.RATEPLAN': 'Tariefplan',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'Status hertoewijzing referenties',
	'FILTER_MODAL.M2MPLATFORM': 'M2M-platform',
	'FILTER_MODAL.ROAMINGCOUNTRY': 'Roamingland',
	'FILTER_MODAL.ROAMINGCARRIER': 'Roamingprovider',
	'FILTER_MODAL.SERVICESTATUS': 'Status 360L',
	'FILTER_MODAL.OEM': 'Subaccounts',
	'FILTER_MODAL.M2MDATEADDED': 'Datum M2M toegevoegd',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'Datum activering M2M',
	//  tabbladen
	'FILTER_MODAL.TABS_ENDPOINTSTATE': 'Status eindpunt',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Netwerkoperator',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'Land netwerk',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Netwerkrecht',
	'FILTER_MODAL.TABS_NETWORKTYPE': 'Type netwerk',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': 'Overdrachtsstatus',
	'FILTER_MODAL.TABS_RATEPLAN': 'Tariefplan',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': 'Hertoewijzing overdrachtsstatus',
	'FILTER_MODAL.TABS_M2MPLATFORM': 'M2M-platform',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'Roamingland',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Roamingprovider',
	'FILTER_MODAL.TABS_SERVICESTATUS': 'Status 360L',
	'FILTER_MODAL.TABS_OEM': 'Subaccounts',
	'FILTER_MODAL.TABS_M2MDATEADDED': 'Datum M2M toegevoegd',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'Datum activering M2M',
	//  **  FACTUREN  **
	'FILTER_MODAL.M2MACCOUNTID': 'ID M2M-ACCOUNT',
	'FILTER_MODAL.CUSTOMERNAME': 'NAAM KLANT',
	'FILTER_MODAL.INVOICENUMBER': 'FACTUURNUMMER',
	'FILTER_MODAL.INVOICEDATE': 'FACTUURDATUM',
	'FILTER_MODAL.COUNTRYNAME': 'NAAM LAND',
	'FILTER_MODAL.CUSTOMERID': 'KLANT-ID',
	'FILTER_MODAL.BILLINGCYCLE': 'FACTURERINGSCYCLUS',
	//  tabbladen
	'FILTER_MODAL.TABS_M2MACCOUNTID': 'Id M2M-account',
	'FILTER_MODAL.TABS_CUSTOMERNAME': 'Naam klant',
	'FILTER_MODAL.TABS_INVOICENUMBER': 'Factuurnummer',
	'FILTER_MODAL.TABS_COUNTRYNAME': 'Naam land',
	'FILTER_MODAL.TABS_CUSTOMERID': 'Klant-id',
	'FILTER_MODAL.TABS_BILLINGCYCLE': 'Billing Cycle',
	'FILTER_MODAL.TABS_INVOICEDATE': 'Factuurdatum',

	//  **  RAPPORTEN **
	'FILTER_MODAL.M2M_ACC_ID': 'ID M2M-ACCOUNT',
	'FILTER_MODAL.M2M_PLATFORM': 'M2M-PLATFORM',
	'FILTER_MODAL.FREQUENCY': 'FREQUENTIE',
	'FILTER_MODAL.REPORT_NAME': 'NAAM RAPPORT',
	'FILTER_MODAL.PLATFORM': 'PLATFORM',
	'FILTER_MODAL.SERVICEPROVIDER': 'PLATFORM',
	'FILTER_MODAL.REPORTFREQUENCY': 'FREQUENTIE',
	//  tabbladen
	'FILTER_MODAL.TABS_M2M_ACC_ID': 'Id M2M-account',
	'FILTER_MODAL.TABS_M2M_PLATFORM': 'M2M-platform',
	'FILTER_MODAL.TABS_FREQUENCY': 'Frequentie',
	'FILTER_MODAL.TABS_REPORT_NAME': 'Naam rapport',
	'FILTER_MODAL.TABS_PLATFORM': 'Platform',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Platform',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Frequentie',
	//  **  SIM-BESTELLING  **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'STATUS',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'M2M-PLATFORM',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'SPOED',
	//  tabbladen
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'Status',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'M2M-platform',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Spoed',
	//  **  BEVEILIGINGSFUNCTIES **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'M2M-ACCOUNT',
	//  tabbladen
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'M2M-account',
	//  **  TICKETSYSTEEM **
	'FILTER_MODAL.CATEGORIES': 'CATEGORIEËN',
	'FILTER_MODAL.STATUS': 'STATUS',
	'FILTER_MODAL.SEVERITY': 'ERNST',
	//  tabbladen
	'FILTER_MODAL.TABS_CATEGORIES': 'Categorieën',
	'FILTER_MODAL.TABS_STATUS': 'Status',
	'FILTER_MODAL.TABS_SEVERITY': 'Ernst',
	//  **  FACTUURGEGEVENS **
	'FILTER_MODAL.CURRENCY': 'VALUTA',
	'FILTER_MODAL.CUSTOMER': 'KLANT',
	'FILTER_MODAL.SUBSCRIBER_STATUS': 'STATUS ABONNEE',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': 'MAANDELIJKS TARIEFPLAN',
	'FILTER_MODAL.STANDARD_RATE_PLAN': 'STANDAARD TARIEFPLAN',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'BEREKENDE PREPAID-TIJDVAKKEN',
	'FILTER_MODAL.RATE_PLAN': 'TARIEFPLAN',
	'FILTER_MODAL.PLAN_TYPE': 'TYPE PLAN',
	'FILTER_MODAL.ZONE': 'ZONE',
	'FILTER_MODAL.PRIORITY': 'PRIORITEIT',
	'FILTER_MODAL.ROAMING_ZONE': 'ROAMINGZONE',
	'FILTER_MODAL.GROUP_NAME': 'GROEPSNAAM',
	'FILTER_MODAL.INVOICE_ID': 'FACTUUR-ID',
	'FILTER_MODAL.THE_PLAN_TYPE': 'HET TYPE PLAN',
	'FILTER_MODAL.ACTIVATION_TYPE': 'TYPE ACTIVERING',
	'FILTER_MODAL.CHARGE_TYPE': 'TYPE KOSTEN',
	'FILTER_MODAL.PRIMARY_PLACE': 'PRIMAIRE PLAATS',
	'FILTER_MODAL.TECH_TYPE': 'TECH-TYPE',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'PRIMAIRE GEBRUIKSPLAATS',
	'FILTER_MODAL.STATE': 'STATUS',
	'FILTER_MODAL.CITY': 'PLAATSNAAM',
	'FILTER_MODAL.DISTRICT_COUNTY': 'GEMEENTE DISTRICT',
	'FILTER_MODAL.DISTRICT_CITY': 'PLAATS DISTRICT',
	'FILTER_MODAL.DESCRIPTION': 'BESCHRIJVING',
	//  tabbladen
	'FILTER_MODAL.TABS_CURRENCY': 'Valuta',
	'FILTER_MODAL.TABS_CUSTOMER': 'Klant',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'Status abonnee',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'Maandelijks tariefplan',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'Standaard tariefplan',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'Berekende prepaid-tijdvakken',
	'FILTER_MODAL.TABS_RATE_PLAN': 'Tariefplan',
	'FILTER_MODAL.TABS_PLAN_TYPE': 'Type plan',
	'FILTER_MODAL.TABS_ZONE': 'Zone',
	'FILTER_MODAL.TABS_PRIORITY': 'Prioriteit',
	'FILTER_MODAL.TABS_ROAMING_ZONE': 'Roamingzone',
	'FILTER_MODAL.TABS_GROUP_NAME': 'Groepsnaam',
	'FILTER_MODAL.TABS_INVOICE_ID': 'Factuur-id',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'Het type plan',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'Type activering',
	'FILTER_MODAL.TABS_CHARGE_TYPE': 'Type kosten',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': 'Primaire plaats',
	'FILTER_MODAL.TABS_TECH_TYPE': 'Tech-type',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'Primaire gebruiksplaats',
	'FILTER_MODAL.TABS_STATE': 'Status',
	'FILTER_MODAL.TABS_CITY': 'Plaatsnaam',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'Gemeente district',
	'FILTER_MODAL.TABS_DISTRICT_CITY': 'Plaats district',
  'FILTER_MODAL.TABS_DESCRIPTION': 'Beschrijving',
  //  **    AUTOMATISERING    **  //
  'FILTER_MODAL.PLATFORMS': 'PLATFORMS',
  'FILTER_MODAL.TRIGGERS': 'TRIGGERS',
  'FILTER_MODAL.M2MACCOUNTS': 'M2M-ACCOUNTS',
  'FILTER_MODAL.ACTIVE': 'GEACTIVEERD',
  'FILTER_MODAL.STATUSES': 'STATUSSEN',
  //  tabbladen
  'FILTER_MODAL.TABS_PLATFORMS': 'Platforms',
  'FILTER_MODAL.TABS_TRIGGERS': 'Triggers',
  'FILTER_MODAL.TABS_M2MACCOUNTS': 'M2M-account',
	'FILTER_MODAL.TABS_ACTIVE': 'Geactiveerd',
	'FILTER_MODAL.TABS_USERGROUPS': 'Gebruikersgroepen',
  'FILTER_MODAL.USERGROUPS': 'Gebruikersgroepen',
  'FILTER_MODAL.TABS_STATUSES': 'Statussen',
  // actiebalk
  'ACTION_BAR.PLATFORMS': 'Platforms',
  'ACTION_BAR.TRIGGERS': 'Triggers',
  'ACTION_BAR.M2MACCOUNTS': 'M2M-accounts',
  'ACTION_BAR.CURRENCY': 'Valuta',
  'ACTION_BAR.ACTIVE': 'Geactiveerd',
	'ACTION_BAR.CUSTOMER': 'Customer',
	'ACTION_BAR.SUBSCRIBER_STATUS': 'Status abonnee',
	'ACTION_BAR.MONTHLY_RATE_PLAN': 'Maandelijks tariefplan',
	'ACTION_BAR.STANDARD_RATE_PLAN': 'Standaard tariefplan',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': 'Berekende prepaid-tijdvakken',
	'ACTION_BAR.RATE_PLAN': 'Tariefplan',
	'ACTION_BAR.PLAN_TYPE': 'Type plan',
	'ACTION_BAR.ZONE': 'Zone',
	'ACTION_BAR.PRIORITY': 'Prioriteit',
	'ACTION_BAR.ROAMING_ZONE': 'Roamingzone',
	'ACTION_BAR.GROUP_NAME': 'Groepsnaam',
	'ACTION_BAR.INVOICE_ID': 'Invoice ID',
	'ACTION_BAR.THE_PLAN_TYPE': 'Het type plan',
	'ACTION_BAR.ACTIVATION_TYPE': 'Type activering',
	'ACTION_BAR.CHARGE_TYPE': 'Type kosten',
	'ACTION_BAR.PRIMARY_PLACE': 'Primaire plaats',
	'ACTION_BAR.TECH_TYPE': 'Tech-type',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'Primaire gebruiksplaats',
	'ACTION_BAR.STATE': 'Provincie',
	'ACTION_BAR.CITY': 'Plaatsnaam',
	'ACTION_BAR.DISTRICT_COUNTY': 'Gemeente district',
	'ACTION_BAR.DISTRICT_CITY': 'Plaats district',
	'ACTION_BAR.DESCRIPTION': 'Beschrijving',
  'ACTION_BAR.USERGROUPS': 'Gebruikersgroepen',
  'ACTION_BAR.STATUSES': 'Statussen',
  // ONDERDEEL UPLOADEN
	'UPLOAD_FILE.MAX_FILE_SIZE': 'Bestand moet kleiner zijn dan {size}MB.',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Onjuist bestandstype',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': 'Alleen de volgende indelingen mogen worden geüpload:',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'Bestand is te groot',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': 'Het bestand dat u probeert te uploaden is te groot, bestanden moeten kleiner zijn dan',
  'UPLOAD_FILE.ALLOWED_FORMATS': 'Toegestane indelingen: ',
  'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'Er is maar één bestand toegestaan'
};
