import { connect } from 'react-redux';

import {
	getSessionHistory,
	exportSessions,
} from '../../redux/actions';
import {
	isExportSessionsDataPending,
	isSessionsPending,
	sessionHistoryData,
} from '../../redux/selectors';
import createUserSettings
	from '../../../../redux/user/createUserSettings/actions';
import { getUserSettings } from '../../../../redux/user/getUserData/selectors';
import patchUserSettings
	from '../../../../redux/user/updateUserSettings/actions';

import Desktop from './Desktop';
import Mobile from './Mobile';

const mapStateToProps = (state) => (
	{
		sessionData: sessionHistoryData(state),
		isSessionPending: isSessionsPending(state),
		settings: getUserSettings(state, 10),
		isExportSessionsDataPending: isExportSessionsDataPending(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getSessionsData: (id) => dispatch(getSessionHistory(id)),
		updateSettings: (setting, id) => dispatch(patchUserSettings(setting, id)),
		createSettings: (setting) => dispatch(createUserSettings(setting)),
		exportSessionsData: (id, intl) => dispatch(exportSessions(id, intl)),
	}
);

export const SessionsDesktop = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Desktop);
export const SessionsMobile = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Mobile);
