import {
	GET_USER_GROUP_PERMISSIONS,
	EDIT_USER_GROUP_PERMISSIONS,
	GET_USER_EFFECTIVE_PERMISSIONS
} from './constants';
import globalAction from '../../../../redux/global/globalAction';

import {
	getUserGroupPermissions as getUserGroupPermissionsService,
	editUserGroupPermissions as editUserGroupPermissionsService,
	getUserEffectivePermissions as getUserEffectivePermissionsService
} from '../../services/PermissionsService';

export const getUserGroupPermissions = (params) =>
	globalAction(
		GET_USER_GROUP_PERMISSIONS,
		getUserGroupPermissionsService,
		params
	);

export const editPermissions = (params) =>
	globalAction(
		EDIT_USER_GROUP_PERMISSIONS,
		editUserGroupPermissionsService,
		params,
		{
			notify: 'edit-permissions',
			redirect: params.redirect
		}
	);

export const getUserEffectivePermissions = (params) =>
	globalAction(
		GET_USER_EFFECTIVE_PERMISSIONS,
		getUserEffectivePermissionsService,
		params
	);
