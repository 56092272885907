import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';

import styles from './StepFive.scss';

const SuccessIcon = ImageSelector(CurrentContext.theme, 'svgs/ok-empty.svg');
const FailIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/security-alert-empty.svg'
);

class StepFive extends PureComponent {
	render() {
		const {
			addTicketRequest,
			addTicketSuccess,
			addTicketFail,
			pushBack
		} = this.props;

		if (addTicketRequest) {
			return <Loading data-spec="loader" />;
		}
		return (
			<div data-spec="step-five">
				<div className={styles.submit_column}>
					{addTicketSuccess && (
						<div className={styles.submit_wrapper}>
							<div className={styles.success_ticket}>
								<FormattedMessage
									id="TICKETING.TICKET_SUBMITTED_SUCCESSFULLY"
									defaultMessage="Ticket submitted successfully!"
								/>
							</div>
							<div className={styles.icon_wrapper}>
								<SuccessIcon className={styles.success_icon} />
							</div>
						</div>
					)}
					{addTicketFail && (
						<div className={styles.submit_wrapper}>
							<div className={styles.fail_ticket}>
								<FormattedMessage
									id="TICKETING.TICKET_COULD_NOT_BE_SUBMITTED"
									defaultMessage="Ticket could not be submitted successfully!"
								/>
							</div>
							<div className={styles.icon_wrapper}>
								<FailIcon className={styles.fail_icon} />
							</div>
						</div>
					)}

					<div className={styles.submit_wrapper}>
						{addTicketSuccess && (
							<div className={styles.field_wrapper}>
								<div className={styles.field_label}>
									<FormattedMessage
										id="TICKETING.TICKET_ID"
										defaultMessage="Ticket ID"
									/>
								</div>
								<div>{addTicketSuccess.externalTicketId}</div>
								<div>
									<FormattedMessage
										id="TICKETING.CREATED_AT"
										defaultMessage="Created at: "
									/>
									<FormattedDate
										value={addTicketSuccess.createdAt}
										month="2-digit"
										day="2-digit"
										year="numeric"
										hour="numeric"
										minute="numeric"
										second="numeric"
										hour12={false}
									/>
								</div>
							</div>
						)}
						{addTicketFail && (
							<div className={styles.field_wrapper}>
								<div className={styles.field_label}>
									<FormattedMessage
										id="TICKETING.PLEASE_TRY_AGAIN"
										defaultMessage="Please try again!"
									/>
								</div>
							</div>
						)}
					</div>
					<div className={styles.submit_wrapper}>
						<Button
							variant="primary"
							label={
								<FormattedMessage
									id="TICKETING.GO_BACK_TO_TICKETING"
									defaultMessage="Go back to ticketing"
								/>
							}
							onClick={() => pushBack()}
							dataSpec="go-back-to-ticketing"
						/>
					</div>
				</div>
			</div>
		);
	}
}

const { func, bool, shape } = PropTypes;

StepFive.propTypes = {
	pushBack: func,
	addTicketRequest: bool,
	addTicketSuccess: shape(),
	addTicketFail: bool
};

export default StepFive;
