import globalAction from '../../../../redux/global/globalAction';

import {
	GET_PREPAID_SUBSCRIPTIONS,
	GET_PREPAID_SUBSCRIPTIONS_FILTERS
} from './constants';

import {
	getPrepaidSubscriptions as getPrepaidSubscriptionsService,
	getPrepaidSubscriptionsFilters as getPrepaidSubscriptionsFiltersService
} from '../../services/PrepaidSubscriptionsService';

/** ************ get prepaid subscriptions ************* */
export const getPrepaidSubscriptions = (id, params = {}) => globalAction(
		GET_PREPAID_SUBSCRIPTIONS,
		getPrepaidSubscriptionsService,
		{ id, params }
	);

/** ************ get prepaid subscriptions filters ************* */
export const getPrepaidSubscriptionsFilters = (id, params = {}) => globalAction(
		GET_PREPAID_SUBSCRIPTIONS_FILTERS,
		getPrepaidSubscriptionsFiltersService,
		{ id, params }
	);
