import React from 'react';
import { FormattedMessage } from 'react-intl';

const validate = (values) => {
	const errors = {};
	let checkboxes = [];
	if (!values.notificationTypeId) {
		errors.notificationTypeId = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}
	if (!values.note || values.note.trim().length === 0) {
		errors.note = <FormattedMessage id="REQUIRED" defaultMessage="Required" />;
	}

	if (!values.accountId) {
		errors.accountId = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}

	Object.keys(values).forEach((key) => {
		if (key.startsWith('notificationGroupTypeId') && values[key] === true) {
			checkboxes = [
				...checkboxes,
				{
					item: values[key]
				}
			];
		}
	});

	if (checkboxes.length < 1) {
		errors.notificationGroupTypeId = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}

	return errors;
};

export default validate;
