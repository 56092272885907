import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../Accounts';

const Mobile = (props) => {
	const { accounts, getOptions, messages } = props;
	return (
		<div data-spec="my-accounts-mobile">
			<Table
				data-spec="my-accounts-table-mobile"
				data={accounts.resultList}
				options={getOptions('mobile')}
				messages={messages}
				mobile
			/>
		</div>
	);
};

const { object, func } = PropTypes;

Mobile.propTypes = {
	accounts: object,
	getOptions: func,
	messages: object
};

export default hoc()(Mobile);
