import { connect } from 'react-redux';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import { getAvailableStates } from '../../../Endpoint/redux/actions';
import { getAvailableStatesData } from '../../../Endpoint/redux/selectors';

const mapStateToProps = (state) => ({
	availableStates: getAvailableStatesData(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAvailableStates: () => dispatch(getAvailableStates())
});

export const HardwareDesktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(Desktop);
export const HardwareMobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(Mobile);
