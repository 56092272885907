import { handleActions } from 'redux-actions';
import { Status } from 'utils/constants';

import {
	GET_ENDPOINT_DATA_REQUEST,
	GET_ENDPOINT_DATA_SUCCESS,
	GET_ENDPOINT_DATA_FAIL,
	SET_ENDPOINT_TICKETING_INIT,
	SET_ENDPOINT_TICKETING_RESET,
} from '../constants';

export const initialState = {
	data: null,
	state: Status.INIT,
	error: null
};

export default handleActions(
	{
		[GET_ENDPOINT_DATA_REQUEST]: (state) => ({
				...state,
				data: null,
				state: Status.PENDING,
				error: null
			}),
		[GET_ENDPOINT_DATA_SUCCESS]: (state, action) => ({
				...state,
				data: action.payload.resultList[0],
				state: Status.DONE,
				error: null
			}),
		[GET_ENDPOINT_DATA_FAIL]: (state, action) => ({
				...state,
				state: Status.FAIL,
				error: action.payload.error
			}),
		[SET_ENDPOINT_TICKETING_INIT]: (state) => ({
				...state,
				data: null,
				state: Status.INIT,
				error: null,
			}),
		[SET_ENDPOINT_TICKETING_RESET]: (state) => ({
			...state,
			data: null,
			state: Status.INIT,
			error: null,
			isReset: true,
		})
	},
	initialState
);
