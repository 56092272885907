import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import CurrentContext from '../../../utils/currentContext';
import ImageSelector from '../../../utils/imageSelector';

import styles from './TableHeader.scss';

const Info = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');

export const reportsRefTypeId = 12;

export const ReportsUserSettingsNames = {
  ENDPOINTS: 'endpoints',
  USAGE_SUMMARY: 'usage-summaries',
  TRANSFER_ACTIVITIES: 'transfer-activities',
  ACCOUNT_SUMMARY: 'account-summary',
  BILLING_DETAILS: 'billing-details-reports',
  REFERENCE_REPORTS: 'reference',
  MY_EXPORTS: 'myexports',
  USAGE_REPORTS: 'usagereports',
  REPORT_HEADERS: 'report-headers'
};

const getDateTitle = () => (
	<FormattedMessage
		id="REPORTS.REPORT_DATE_TOOLTIP"
		defaultMessage="This date represents when the report was created. The downloaded file name contains the date for the report data. The date selected and the report date will differ by up to 3 calendar days."
	>
		{(formattedValue) => (
			<span
				className={styles.info}
				data-tip={formattedValue}
				data-for="processedOnInfo"
			>
				<FormattedMessage
					id="REPORTS.PROCESSED_ON"
					defultMessage="Processed On"
				/>
				<Info />
				<ReactTooltip
					id="processedOnInfo"
					type="light"
					className={styles.text_initial}
				/>
			</span>
		)}
	</FormattedMessage>
);

export const ReportsTableHeader = [
	{
		title: <FormattedMessage id="REPORTS.REPORT" defultMessage="Report" />,
		name: 'report_name'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_ACCOUNT_ID"
				defultMessage="M2M Account ID"
			/>
		),
		name: 'm2m_acc_id'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_PLATFORM"
				defultMessage="M2M Platform"
			/>
		),
		name: 'm2m_platform'
	},
	{
		title: (
			<FormattedMessage id="REPORTS.FREQUENCY" defultMessage="Frequency" />
		),
		name: 'frequency'
	},
	{
		title: getDateTitle(),
		name: 'dates'
	}
];

export const ReportsTableHeaderMobile = [
	{
		title: <FormattedMessage id="REPORTS.REPORT" defultMessage="Report" />,
		name: 'report_name'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_ACCOUNT_ID"
				defultMessage="M2M Account ID"
			/>
		),
		name: 'm2m_acc_id'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_PLATFORM"
				defultMessage="M2M Platform"
			/>
		),
		name: 'm2m_platform'
	},
	{
		title: (
			<FormattedMessage id="REPORTS.FREQUENCY" defultMessage="Frequency" />
		),
		name: 'frequency'
	},
	{
		title: getDateTitle(),
		name: 'dates'
	}
];

export const CustomerReportsTableHeader = [
	{
		title: <FormattedMessage id="REPORTS.REPORT" defultMessage="Report" />,
		name: 'reportName'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_ACCOUNT_ID"
				defultMessage="M2M Account ID"
			/>
		),
		name: 'm2mAccountId'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_PLATFORM"
				defultMessage="M2M Platform"
			/>
		),
		name: 'serviceProvider'
	},
	{
		title: (
			<FormattedMessage id="REPORTS.FREQUENCY" defultMessage="Frequency" />
		),
		name: 'reportFrequency'
	},
	{
		title: getDateTitle(),
		name: 'date'
	}
];

export const CustomerReportsTableHeaderMobile = [
	{
		title: <FormattedMessage id="REPORT" defultMessage="Report" />,
		name: 'reportName'
	},
	{
		title: (
			<FormattedMessage id="M2M_ACCOUNT_ID" defultMessage="M2M Account ID" />
		),
		name: 'm2mAccountId'
	},
	{
		title: <FormattedMessage id="M2M_PLATFORM" defultMessage="M2M Platform" />,
		name: 'serviceProvider'
	},
	{
		title: <FormattedMessage id="FREQUENCY" defultMessage="Frequency" />,
		name: 'reportFrequency'
	},
	{
		title: getDateTitle(),
		name: 'date'
	}
];

export const RepostedReportsTableHeader = [
	{
		title: <FormattedMessage id="REPORTS.FILE_NAME" defaultMessage="File Name" />,
		name: 'reportFileName'
	},
	{
		title: <FormattedMessage id="REPORTS.M2M_ACCOUNT_ID" defaultMessage="M2M Account ID" />,
		name: 'm2mAccountId'
	},
	{
		title: <FormattedMessage id="REPORTS.FILE_DATE" defaultMessage="File Date" />,
		name: 'fileDate'
	},
	{
		title: <FormattedMessage id="REPORTS.REQUESTED_BY" defaultMessage="Requested by" />,
		name: 'requestor'
	},
	{
		title: <FormattedMessage id="REPORTS.REPOST_DATE" defaultMessage="Repost Date" />,
		name: 'repostedOn'
	},
	{
		title: <FormattedMessage id="REPORTS.STATUS" defaultMessage="Status" />,
		name: 'repostStatus'
	}
];

export const RepostedReportsTableHeaderMobile = [
	{
		title: <FormattedMessage id="REPORTS.FILE_NAME" defaultMessage="File Name" />,
		name: 'reportFileName'
	},
	{
		title: <FormattedMessage id="REPORTS.M2M_ACCOUNT_ID" defaultMessage="M2M Account ID" />,
		name: 'm2mAccountId'
	},
	{
		title: <FormattedMessage id="REPORTS.FILE_DATE" defaultMessage="File Date" />,
		name: 'yearOfGeneration'
	},
	{
		title: <FormattedMessage id="REPORTS.REQUESTED_BY" defaultMessage="Requested by" />,
		name: 'requestor'
	},
	{
		title: <FormattedMessage id="REPORTS.STATUS" defaultMessage="Status" />,
		name: 'repostStatus'
	}
];

export const ReportHeadersTableHeader = [
	{
		title: <FormattedMessage id="REPORTS.REPORT" defultMessage="Report" />,
		name: 'report_name'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_PLATFORM"
				defultMessage="M2M Platform"
			/>
		),
		name: 'm2m_platform'
	},
	{
		title: (
			<FormattedMessage id="REPORTS.REPORT_DATE" defultMessage="Report Date" />
		),
		name: 'dates'
	}
];

export const ReportHeadersTableHeaderMobile = [
	{
		title: <FormattedMessage id="REPORTS.REPORT" defultMessage="Report" />,
		name: 'report_name'
	},
	{
		title: (
			<FormattedMessage
				id="REPORTS.M2M_PLATFORM"
				defultMessage="M2M Platform"
			/>
		),
		name: 'm2m_platform'
	},
	{
		title: (
			<FormattedMessage id="REPORTS.REPORT_DATE" defultMessage="Report Date" />
		),
		name: 'dates'
	}
];
