/* global GLOBALS */
import ApiClient from 'utils/ApiClient';

const postTiming = (data) => {
	const temp = {
		frontTimings: Object.keys(data).map((key) => data[key])
	};
	return ApiClient.post(`${GLOBALS.API_BASE_URL}/utility/client-logging`, temp);
};

export default postTiming;
