import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import EditUserGroup from './EditUserGroup';
import { getUserGroup } from '../../redux/userGroups/actions';
import {
	getUserGroupRequest,
	getUserGroupSuccess
} from '../../redux/userGroups/selectors';
import { editUserGroupAssignedAccountsSuccess } from '../../redux/userGroupAssignedAccounts/selectors';

const mapStateToProps = (state) => ({
	userGroupRequest: getUserGroupRequest(state),
	userGroup: getUserGroupSuccess(state),
	editUserGroupAssignedAccountsSuccess: editUserGroupAssignedAccountsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	push: (url) => dispatch(localizedRoute(url)),
	getUserGroup: (params) => dispatch(getUserGroup(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserGroup);
