import { combineReducers } from 'redux';
import globalReducer from '../../../redux/global/globalReducer';
import simOrders from './simOrders/reducers';
import restraints from './restraints/reducers';

import { GET_PLATFORMS, GET_SKUS, GET_USED_QUANTITY } from './constants';

export default combineReducers({
	simOrders,
	restraints,
	getPlatforms: globalReducer(GET_PLATFORMS),
	getSKUs: globalReducer(GET_SKUS),
	getUsedQuantity: globalReducer(GET_USED_QUANTITY)
});
