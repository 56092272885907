import React from 'react';
import { func, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter
	from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalHeader
	from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './SpecificationsModal.scss';

function SpecificationsModal({ endpoint, show, onCancel }) {
	const tabs = [
		{
			id: 'hardware',
			displayName: <FormattedMessage id="HARDWARE" defaultMessage="Hardware" />,
			content: () => (
				<p className={styles.p_box}>
					{endpoint.specifications || '-'}
				</p>
			)
		}
	];

	return (
		<Modal
			data-spec="specification-modal"
			show={show}
			onClose={onCancel}
		>
			<ModalHeader
				title={<FormattedMessage
					id="SPECIFICATIONS"
					defaultMessage="Specifications"
				/>}
			/>
			<ModalBody className={styles.modal_body_notf}>
				<ModalTabs tabs={tabs} />
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				<div data-spec="buttons">
					<Button
						variant="primary"
						onClick={onCancel}
						label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
}

SpecificationsModal.propTypes = {
	onCancel: func,
	show: bool,
	endpoint: object
};

export default SpecificationsModal;
