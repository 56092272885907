import { Status } from '../../../../utils/constants';

export const isBulkNetworkEntitlementUpdating = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.status === Status.PENDING;
export const bulkNetworkEntitlementUpdateFail = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.status === Status.FAIL;
export const bulkNetworkEntitlementUpdateSuccess = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.status === Status.DONE;
export const getCurrentSim = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.currentSim;
export const getActionError = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.actionError;
export const getFailSims = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.failSims;
export const getSuccessSims = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.successSims;
export const getIsStarted = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.started;
export const getFilteredSims = (state) =>
	state.Endpoints.updateBulkNetworkEntitlement.filteredSims;
