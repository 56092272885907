import { connect } from 'react-redux';

import InvoiceSwitchTable from './InvoiceSwitchTable';

import { simpleSearchClose } from '../../../Shared/redux/simpleSearch/actions';
import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';
import { exportInvoiceDetails } from '../../redux/invoices/actions';

import {
	hideNotification,
	showNotification
} from '../../../Shared/redux/notifications/actions';

const mapDispatchToProps = (dispatch) => ({
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
	exportInvoiceDetails: (id, params) =>
		dispatch(exportInvoiceDetails(id, params)),
  hideNotification: (id) => dispatch(hideNotification(id)),
  showNotification: (message) =>
  dispatch(
    showNotification({
      id: 'download-invoice-details-error',
      message: [message],
      type: 'error',
      close: false,
      notificationType: 'admin'
    })
  ),
});

export default connect(null, mapDispatchToProps)(InvoiceSwitchTable);
