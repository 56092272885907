import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';

import styles from './RemoveUserModal.scss';

class RemoveUserModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			value: ''
		};
	}

	onChange = (e) => {
		this.setState({
			value: e.target.value
		});
	};

	removeUser = () => {
		const { removeUser, data, system, onClose } = this.props;
		const params = {
			data,
			system
		};
		removeUser(params);
		onClose();
	};

	submitForm = (e) => {
		e.preventDefault();

		this.removeUser();
	};

	render() {
		const { show, onClose, intl } = this.props;
		const { value } = this.state;
		return (
			<Modal data-spec="add-users-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.DELETE_USER"
							defaultMessage="Delete User"
						/>
					}
					closeModal={onClose}
				/>

				<form data-spec="add-users-form" onSubmit={this.submitForm}>
					<ModalBody className={styles.modal_body}>
						<div className={styles.modal_body_content}>
							<div className={styles.notice}>
								<FormattedMessage
									id="ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS"
									defaultMessage="Deleting the profile will remove user access from all accounts you currently have access to."
								/>
								<br />
								<FormattedMessage
									id="ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN"
									defaultMessage="This action is irreversible, and any history of the user will be forgotten by our systems."
								/>
							</div>
							<div className={styles.delete_profile_section}>
								<div className={styles.field_holder}>
									<div className={styles.message}>
										<FormattedMessage
											id="ONBOARDING.TYPE"
											defaultMessage="Type"
										/>
										&nbsp;
										<span className={styles.delete}>
											<FormattedMessage
												id="ONBOARDING.DELETE"
												defaultMessage="DELETE"
											/>
										</span>
										&nbsp;
										<FormattedMessage
											id="ONBOARDING.ENTER_CONFIRMATION_PHRASE"
											defaultMessage="To Completly Remove [userFirstName]"
										/>
									</div>
									<div className={styles.delete_section}>
										<FormattedMessage
											id="ONBOARDING.ENTER_CONFIRMATION_PHRASE"
											defaultMessage="Enter Confirmation Phrase"
										>
											{(formattedValue) => (
												<Input
													placeholder={formattedValue}
													value={value}
													onChange={this.onChange}
												/>
											)}
										</FormattedMessage>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<Button
							dataSpec={'close-button'}
							variant="link"
							label={
								<FormattedMessage
									id="ONBOARDING.CANCEL"
									defaultMessage="Cancel"
								/>
							}
							onClick={onClose}
							type="button"
						/>
						<Button
							type="submit"
							variant="primary"
							label={
								<FormattedMessage
									id="ONBOARDING.REMOVE"
									defaultMessage="REMOVE"
								/>
							}
							dataSpec="submit-button"
							disabled={
								value !==
								intl
									.formatMessage({
										id: 'ONBOARDING.DELETE',
										defaultMessage: 'DELETE'
									})
									.toUpperCase()
							}
						/>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { bool, func, number, oneOfType, object } = PropTypes;

RemoveUserModal.propTypes = {
	onClose: func,
	show: bool,
	removeUser: func,
	data: oneOfType([number, object]),
  system: bool,
  intl: object
};

RemoveUserModal.defaultProps = {
	show: false
};

export default injectIntl(RemoveUserModal);
