import React from 'react';
import { FormattedMessage } from 'react-intl';

export const analyticsInvoicesTableHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.INVOICE_ID"
				defaultMessage="Invoice ID"
			/>
		),
		name: 'invoiceNumber'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.BILLING_CYCLE"
				defaultMessage="Billing Cycle"
			/>
		),
		name: 'billingCycle'
	}
];

export const InvoicesTableHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.INVOICE_ID"
				defaultMessage="Invoice ID"
			/>
		),
		name: 'invoiceId'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.BILLING_CYCLE"
				defaultMessage="Billing Cycle"
			/>
		),
		name: 'billingCycle'
	}
];

export const EndpointsHeaderMobile = [
	{
		title: (
			<FormattedMessage id="ANALYTICS_INVOICES.ICCID" defaultMessage="ICCID" />
		),
		name: 'iccid'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.MSISDN"
				defaultMessage="MSISDN"
			/>
		),
		name: 'msisdn'
	}
];

export const PrepaidSubscriptionsHeaderMobile = [
	{
		title: (
			<FormattedMessage id="ANALYTICS_INVOICES.ICCID" defaultMessage="ICCID" />
		),
		name: 'iccid'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.MSISDN"
				defaultMessage="MSISDN"
			/>
		),
		name: 'msisdn'
	}
];

export const AddonsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.RATE_PLAN"
				defaultMessage="Rate Plan"
			/>
		),
		name: 'rate_plan'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.PLAN_VERSION"
				defaultMessage="Plan Version"
			/>
		),
		name: 'plan_version'
	}
];

export const RatePlansHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS"
				defaultMessage="Activation Period Commitment Subs"
			/>
		),
		name: 'activation_period_commitment_subs'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS"
				defaultMessage="Active Subscriptions"
			/>
		),
		name: 'active_subscriptions'
	}
];

export const RatePlanZonesHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE"
				defaultMessage="Active Overage Rate"
			/>
		),
		name: 'active_overag_rate'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CURRENCY"
				defaultMessage="Currency"
			/>
		),
		name: 'currency'
	}
];

export const BillingGroupsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.RATE_PLAN"
				defaultMessage="Rate Plan"
			/>
		),
		name: 'rate_plan'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.PLAN_VERSION"
				defaultMessage="Plan Version"
			/>
		),
		name: 'plan_version'
	}
];

export const DataDetailsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CUSTOMER"
				defaultMessage="Customer"
			/>
		),
		name: 'customer'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT"
				defaultMessage="Daily Rounding Adjustment"
			/>
		),
		name: 'daily_rounding_adjustment'
	}
];

export const SmsDetailsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CUSTOMER"
				defaultMessage="Customer"
			/>
		),
		name: 'customer'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.DEVICE_ID"
				defaultMessage="Device ID"
			/>
		),
		name: 'device_id'
	}
];

export const ActivationsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ACTIVATION_CHARGE"
				defaultMessage="Activation Charge"
			/>
		),
		name: 'activation_charge'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ACTIVATION_TYPE"
				defaultMessage="Activation Type"
			/>
		),
		name: 'activation_type'
	}
];

export const DiscountsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CHARGE_TYPE"
				defaultMessage="Charge Type"
			/>
		),
		name: 'charge_type'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.DISCOUNT"
				defaultMessage="Discount"
			/>
		),
		name: 'discount'
	}
];

export const TaxesHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.PRIMARY_PLACE"
				defaultMessage="Primary Place"
			/>
		),
		name: 'primary_place'
	},
	{
		title: (
			<FormattedMessage id="ANALYTICS_INVOICES.MRC" defaultMessage="MRC" />
		),
		name: 'mrc'
	}
];

export const SurchargesHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE"
				defaultMessage="Primary place of use"
			/>
		),
		name: 'primary_place_of_use'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.FEDERAL_STATE"
				defaultMessage="Federal State"
			/>
		),
		name: 'federal_state'
	}
];

export const TechTypesHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.RATE_PLAN"
				defaultMessage="Rate Plan"
			/>
		),
		name: 'rate_plan'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.PLAN_ID"
				defaultMessage="Plan ID"
			/>
		),
		name: 'plan_id'
	}
];

export const OtherChargesHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CHARGE_AMOUNT"
				defaultMessage="Charge Amount"
			/>
		),
		name: 'charge_amount'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CHARGE_TYPE"
				defaultMessage="Charge Type"
			/>
		),
		name: 'charge_type'
	}
];

export const VoiceDetailsHeaderMobile = [
	{
		title: (
			<FormattedMessage id="ANALYTICS_INVOICES.ICCID" defaultMessage="ICCID" />
		),
		name: 'iccid'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.RATE_PLAN"
				defaultMessage="Rate Plan"
			/>
		),
		name: 'rate_plan_name'
	},
	{
		title: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.CUSTOMER"
				defaultMessage="Customer"
			/>
		),
		name: 'customer'
	}
];
