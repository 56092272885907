import React, { PureComponent } from 'react';
import {
	string,
	object,
	bool,
	func,
} from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import GroupButton from '../GroupButton';
import SMSTab from '../../views/SMSTabView';
import NoDataView from '../../../Shared/views/NoDataView';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './DiagnosticCcip.scss';

const Check = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');
const NCheck = ImageSelector(CurrentContext.theme, 'svgs/close.svg');
const GSucces = ImageSelector(
	CurrentContext.theme,
	'svgs/diagnostic-success.svg'
);
const GError = ImageSelector(CurrentContext.theme, 'svgs/diagnostic-error.svg');
const GDots = ImageSelector(CurrentContext.theme, 'svgs/diagnostic-dots.svg');

export default class DiagnosticCcip extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedButton: 0,
			refresh: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { refresh } = this.state;

		if (prevState.refresh !== refresh) {
			this.refresh();
		}
	}

	componentWillUnmount() {
		const { setDiagnosticCcipStateToInit } = this.props;

		setDiagnosticCcipStateToInit();
	}

	refresh = () => this.setState({
		refresh: false,
	});

	getDiagnosticCcipFunction = () => {
		const { getDiagnosticCcip, iccid } = this.props;

		getDiagnosticCcip(iccid);
		this.setState({ selectedButton: 1 });
	};

	render() {
		const {
			iccid,
			isPending,
			isSuccess,
			endpoint,
			cancelRegistration,
			isCanceling,
			data,
			messages,
			user,
		} = this.props;
		const { selectedButton, refresh } = this.state;

		if (isPending) {
			return (
				<Loader
					msg={<FormattedMessage
						id="FETCH_DATA_LOADING_MESSAGE"
						defaultMessage="Please wait while we load the data"
					/>}
					data-spec="diagnostics-loader"
				/>
			);
		}

		const ccipData = data && Object.keys(data).length > 1
			? {
				carrier: endpoint.serviceProvider,
				voiceEnabled: Object.keys(data.hlrProfile.barring).length,
				dataEnabled: data.hlrProfile.pdpContext.packetDataProtocolName,
				smsEnabled:
					Object.keys(
						data.hlrProfile.callingFeatures.smsRestrictions
							.originatingRestriction
					).length ||
					Object.keys(
						data.hlrProfile.callingFeatures.smsRestrictions
							.terminatingRestriction
					).length,
				voiceRoamingEnabled: data.hlrProfile.roamEntitlement,
				dataRoamingEnabled: data.hlrProfile.dataRoamEntitlement,
				lteEnabled:
					data.lte && data.lte.dataEnabled && data.lte.voiceEnabled,
				lteDataEnabled:
					data.epchssProfile &&
					data.epchssProfile.packetDataProtocol.accessPointConfigEntry[0] &&
					data.epchssProfile.packetDataProtocol.accessPointConfigEntry[0]
						.name,
				lteRoamingEnabled: !data.roamingRestrictionsClass,
				voiceOnly:
					data.hspa && data.hspa.voiceEnabled && data.lte.voiceEnabled,
				registeredOnNetwork:
					data.hlrProfile.vlr.currentVLRAttached &&
					data.hlrProfile.sgsn.currentSGSNAttached,
				lteRegisteredOnNetwork: data.mmeRegistrationAttachStatus,
				lteRegisteredOn3gNetwork:
					!data.mmeRegistrationAttachStatus && data.sgsnAttachStatus,
				ipAddress: data.hlrProfile.pdpContext.ipAddress,
				provisioningInformation3g: () =>
					this.voiceEnabled &&
					this.dataEnabled &&
					this.smsEnabled && this.voiceRoamingEnabled &&
					this.dataRoamingEnabled,
				provisioningInformationLte: () =>
					this.lteEnabled && this.lteDataEnabled && this.lteRoamingEnabled,
				networkConnectionLte: () =>
					this.lteRegisteredOnNetwork &&
					this.lteRegisteredOn3gNetwork &&
					this.ipAddress
			}
			: {};

		return (
			<div
				data-spec="diagnostic-ccip-wrapper"
				className={styles.diagnostics_wrapper}
			>
				<header className={styles.header}>
					<GroupButton>
						<Button
							dataSpec="diagnostic-button"
							variant="outline-primary"
							onClick={this.getDiagnosticCcipFunction}
							label={
								<FormattedMessage
									id="DIAGNOSTIC_BUTTON"
									defaultMessage="Start Diagnostics"
								/>
							}
						/>
						{isUserAllowedToAccess(
							['mnc.endpoints.smstab_rw', 'mnc.endpoints.smstab_ro'],
							user
						) ? (
							<Button
								dataSpec="sms-button"
								variant={selectedButton === 2 ? 'primary' : 'outline-primary'}
								onClick={() =>
									this.setState({
										selectedButton: 2,
										refresh: true
									})}
								label={
									<FormattedMessage
										id="SEND_SMS_TO_ENDPOINT"
										defaultMessage="Send SMS to Endpoint"
									/>
								}
								disabled={false}
							/>
						) : null}
					</GroupButton>
				</header>
				{selectedButton === 1 && isSuccess && (
					<main className={styles.main}>
						{(Object.keys(data).length === 1 &&
							Object.keys(data)[0] === 'messageId') ||
						Object.keys(data).length === 0 ? (
							<NoDataView />
						) : (
							<div
								data-spec="diagnostic-ccip-details"
								className={styles.diagnostics_details}
							>
								<div className={styles.test_wrapper}>
									<div className={styles.graphics}>
										{ccipData.carrier ? (
											<GSucces data-spec="success" className={styles.succes} />
										) : (
											<GError className={styles.error} />
										)}
									</div>
									<div className={styles.data}>
										<div
											className={cn(
												styles.title,
												!ccipData.carrier && styles.error
											)}
										>
											<FormattedMessage id="CARRIER" defaultMessage="Carrier" />
										</div>
										<div className={styles.data_name}>
											<span>{endpoint.serviceProvider}</span>
											<>
												{ccipData.carrier ? (
													<Check data-tip={'test'} data-for="carrier" />
												) : (
													<NCheck data-tip={'test'} data-for="carrier" />
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="carrier"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="CARRIER"
																defaultMessage="Carrier"
															/>
														</span>
														<div>
															{endpoint.serviceProvider &&
																endpoint.serviceProvider}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
									</div>
								</div>
								<div className={styles.section_title}>
									<span>
										<FormattedMessage
											id="ENDPOINT_PROVISIONING_INFORMATION"
											defaultMessage="Endpoint Provisioning Information"
										/>
									</span>
								</div>
								<div className={styles.test_wrapper}>
									<div className={styles.graphics}>
										{ccipData.provisioningInformation3g &&
										ccipData.provisioningInformation3g() ? (
											<>
												<GSucces
													data-spec="success"
													className={styles.succes}
												/>
												<GDots className={cn(styles.dots)} />
											</>
										) : (
											<>
												<GError data-spec="success" className={styles.error} />
												<GDots className={cn(styles.dots, styles.dots_error)} />
											</>
										)}
									</div>
									<div className={styles.data}>
										<div
											className={cn(
												styles.title,
												!(
													ccipData.provisioningInformation3g &&
													ccipData.provisioningInformation3g()
												) && styles.error
											)}
										>
											<FormattedMessage id="CCIP_3G" defaultMessage="3G" />
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="VOICE_ENABLED"
													defaultMessage="Voice Enabled"
												/>
											</span>
											<>
												{ccipData.voiceEnabled ? (
													<NCheck
														data-tip={'test'}
														className={styles.ncheck}
														data-for="voiceEnabled"
													/>
												) : (
													<Check data-tip={'test'} data-for="voiceEnabled" />
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="voiceEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="VOICE_ENABLED"
																defaultMessage="Voice Enabled"
															/>
														</span>
														<div>
															<code>
																<pre>
																	{data.hlrProfile &&
																		data.hlrProfile.barring &&
																		JSON.stringify(
																			data.hlrProfile.barring,
																			null,
																			1
																		)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="DATA_ENABLED"
													defaultMessage="Data Enabled"
												/>
											</span>
											<>
												{ccipData.dataEnabled ? (
													<Check data-tip={'test'} data-for="dataEnabled" />
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="dataEnabled"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="dataEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="DATA_ENABLED"
																defaultMessage="Data Enabled"
															/>
														</span>
														<div>
															{data.hlrProfile &&
																data.hlrProfile.pdpContext
																	.packetDataProtocolName &&
																data.hlrProfile.pdpContext
																	.packetDataProtocolName}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="SMS_ENABLED"
													defaultMessage="SMS Enabled"
												/>
											</span>
											<>
												{ccipData.smsEnabled ? (
													<NCheck
														data-tip={'test'}
														className={styles.ncheck}
														data-for="smsEnabled"
													/>
												) : (
													<Check data-tip={'test'} data-for="smsEnabled" />
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="smsEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="SMS_ENABLED"
																defaultMessage="SMS Enabled"
															/>
														</span>
														<div>
															<code>
																<pre>
																	{data.hlrProfile &&
																		data.hlrProfile.callingFeatures
																			.smsRestrictions &&
																		JSON.stringify(
																			data.hlrProfile.callingFeatures
																				.smsRestrictions,
																			null,
																			1
																		)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="VOICE_ROAMING_ENABLED"
													defaultMessage="Voice Roaming Enabled"
												/>
											</span>
											<>
												{ccipData.voiceRoamingEnabled ? (
													<Check
														data-tip={'test'}
														data-for="voiceRoamingEnabled"
													/>
												) : (
													<NCheck
														data-tip={'test'}
														data-for="voiceRoamingEnabled"
														className={styles.ncheck}
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="voiceRoamingEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="VOICE_ROAMING_ENABLED"
																defaultMessage="Voice Roaming Enabled"
															/>
														</span>
														<div>
															{data.hlrProfile &&
																data.hlrProfile.roamEntitlement &&
																data.hlrProfile.roamEntitlement}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="DATA_ROAMING_ENABLED"
													defaultMessage="Data Roaming Enabled"
												/>
											</span>
											<>
												{ccipData.dataRoamingEnabled ? (
													<Check
														data-tip={'test'}
														data-for="dataRoamingEnabled"
													/>
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="dataRoamingEnabled"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="dataRoamingEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="DATA_ROAMING_ENABLED"
																defaultMessage="Data Roaming Enabled"
															/>
														</span>
														<div>
															{data.hlrProfile &&
																data.hlrProfile.dataRoamEntitlement &&
																data.hlrProfile.dataRoamEntitlement}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
									</div>
								</div>
								<div className={styles.test_wrapper}>
									<div className={styles.graphics}>
										{ccipData.provisioningInformationLte &&
										ccipData.provisioningInformationLte() ? (
											<>
												<GSucces
													data-spec="success"
													className={styles.succes}
												/>
												<GDots className={cn(styles.dots)} />
											</>
										) : (
											<>
												<GError data-spec="success" className={styles.error} />
												<GDots className={cn(styles.dots, styles.dots_error)} />
											</>
										)}
									</div>
									<div className={styles.data}>
										<div
											className={cn(
												styles.title,
												!(
													ccipData.provisioningInformationLte &&
													ccipData.provisioningInformationLte()
												) && styles.error
											)}
										>
											<FormattedMessage id="CCIP_LTE" defaultMessage="LTE" />
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="LTE_ENABLED"
													defaultMessage="LTE Enabled"
												/>
											</span>
											<>
												{ccipData.lteEnabled ? (
													<Check data-tip={'test'} data-for="lteEnabled" />
												) : (
													<NCheck
														data-tip={'test'}
														data-for="lteEnabled"
														className={styles.ncheck}
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="lteEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="LTE_ENABLED"
																defaultMessage="LTE Enabled"
															/>
														</span>
														<div>
															<code>
																<pre>
																	{data.lte &&
																		JSON.stringify(data.lte, null, 1)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="DATA_ENABLED"
													defaultMessage="Data Enabled"
												/>
											</span>
											<>
												{ccipData.lteDataEnabled ? (
													<Check data-tip={'test'} data-for="dataLteEnabled" />
												) : (
													<NCheck
														data-tip={'test'}
														data-for="dataLteEnabled"
														className={styles.ncheck}
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="dataLteEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="DATA_ENABLED"
																defaultMessage="Data Enabled"
															/>
														</span>
														<div>
															<code>
																<pre>
																	{data.epchssProfile &&
																		data.epchssProfile.packetDataProtocol
																			.accessPointConfigEntry &&
																		JSON.stringify(
																			data.epchssProfile.packetDataProtocol
																				.accessPointConfigEntry,
																			null,
																			1
																		)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="ROAMING_ENABLED"
													defaultMessage="Roaming Enabled"
												/>
											</span>
											<>
												{ccipData.lteRoamingEnabled ? (
													<Check
														data-tip={'test'}
														data-for="roamingLteEnabled"
													/>
												) : (
													<NCheck
														data-tip={'test'}
														className={styles.ncheck}
														data-for="roamingLteEnabled"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="roamingLteEnabled"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="ROAMING_ENABLED"
																defaultMessage="Roaming Enabled"
															/>
														</span>
														<div>
															{data.roamingRestrictionsClass &&
																data.roamingRestrictionsClass}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
									</div>
								</div>
								<div className={styles.test_wrapper}>
									<div className={styles.graphics}>
										{ccipData.voiceOnly ? (
											<GSucces data-spec="success" className={styles.succes} />
										) : (
											<GError data-spec="success" className={styles.error} />
										)}
									</div>
									<div className={styles.data}>
										<div
											className={cn(
												styles.title,
												!ccipData.voiceOnly && styles.error
											)}
										>
											<FormattedMessage
												id="MOBILE_NETWORK_ACCESS"
												defaultMessage="Mobile Network Access"
											/>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="VOICE_ONLY"
													defaultMessage="Voice Only"
												/>
											</span>
											<>
												{ccipData.voiceOnly ? (
													<Check data-tip={'test'} data-for="voiceOnly" />
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="voiceOnly"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="voiceOnly"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="VOICE_ONLY"
																defaultMessage="Voice Only"
															/>
														</span>
														<div>
															<code>
																<pre>
																	<FormattedMessage
																		id="SINGLE_ENDPOINT.DIAGNOSTICS_HSPA"
																		defaultMessage="HSPA: "
																	/>
																	{data.hspa &&
																		JSON.stringify(data.hspa, null, 1)}
																	<br />
																	<FormattedMessage
																		id="SINGLE_ENDPOINT.DIAGNOSTICS_LTE"
																		defaultMessage="LTE: "
																	/>
																	{data.lte &&
																		JSON.stringify(data.lte, null, 1)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
									</div>
								</div>
								<div className={styles.section_title}>
									<span>
										<FormattedMessage
											id="SINGLE_ENDPOINT.NETWORK_CONNECTION"
											defaultMessage="Network Connection"
										/>
									</span>
								</div>
								<div className={styles.test_wrapper}>
									<div className={styles.graphics}>
										{ccipData.registeredOnNetwork ? (
											<>
												<GSucces
													data-spec="success"
													className={styles.succes}
												/>
												<GDots className={cn(styles.dots)} />
											</>
										) : (
											<>
												<GError data-spec="success" className={styles.error} />
												<GDots className={cn(styles.dots, styles.dots_error)} />
											</>
										)}
									</div>
									<div className={styles.data}>
										<div
											className={cn(
												styles.title,
												!ccipData.registeredOnNetwork && styles.error
											)}
										>
											<FormattedMessage
												id="SINGLE_ENDPOINT.3G"
												defaultMessage="3G"
											/>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="REGISTERED_ON_NETWORK"
													defaultMessage="Registered On Network"
												/>
											</span>
											<>
												{ccipData.registeredOnNetwork ? (
													<Check
														data-tip={'test'}
														data-for="registeredOnNetwork"
													/>
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="registeredOnNetwork"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="registeredOnNetwork"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="REGISTERED_ON_NETWORK"
																defaultMessage="Registered On Network"
															/>
														</span>
														<div>
															<code>
																<pre>
																	<FormattedMessage
																		id="SINGLE_ENDPOINT.DIAGNOSTICS_VLR"
																		defaultMessage="VLR: "
																	/>
																	{data.hlrProfile &&
																		data.hlrProfile.vlr &&
																		JSON.stringify(
																			data.hlrProfile.vlr,
																			null,
																			1
																		)}
																	<br />
																	<FormattedMessage
																		id="SINGLE_ENDPOINT.DIAGNOSTICS_SGSN"
																		defaultMessage="SGSN: "
																	/>
																	{data.hlrProfile &&
																		data.hlrProfile.sgsn &&
																		JSON.stringify(
																			data.hlrProfile.sgsn,
																			null,
																			1
																		)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
									</div>
								</div>
								<div className={styles.test_wrapper}>
									<div className={styles.graphics}>
										{ccipData.networkConnectionLte &&
										ccipData.networkConnectionLte() ? (
											<GSucces data-spec="success" className={styles.succes} />
										) : (
											<GError data-spec="success" className={styles.error} />
										)}
									</div>
									<div className={styles.data}>
										<div
											className={cn(
												styles.title,
												!(
													ccipData.networkConnectionLte &&
													ccipData.networkConnectionLte()
												) && styles.error
											)}
										>
											<FormattedMessage id="LTE" defaultMessage="LTE" />
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="REGISTERED_ON_NETWORK"
													defaultMessage="Registered On Network"
												/>
											</span>
											<>
												{ccipData.lteRegisteredOnNetwork ? (
													<Check
														data-tip={'test'}
														data-for="registeredLteOnNetwork"
													/>
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="registeredLteOnNetwork"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="registeredLteOnNetwork"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="REGISTERED_ON_NETWORK"
																defaultMessage="Registered On Network"
															/>
														</span>
														<div>
															{data.mmeRegistrationAttachStatus &&
																data.mmeRegistrationAttachStatus}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="REGISTERED_ON_3G_NETWORK"
													defaultMessage="Registered On 3G Network"
												/>
											</span>
											<>
												{ccipData.lteRegisteredOn3gNetwork ? (
													<Check
														data-tip={'test'}
														data-for="registeredLteOn3gNetwork"
													/>
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="registeredLteOn3gNetwork"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="registeredLteOn3gNetwork"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="REGISTERED_ON_3G_NETWORK"
																defaultMessage="Registered On 3G Network"
															/>
														</span>
														<div>
															{data.sgsnAttachStatus && data.sgsnAttachStatus}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
										<div className={styles.data_name}>
											<span>
												<FormattedMessage
													id="IP_ADDRESS"
													defaultMessage="IP Address"
												/>
											</span>
											<>
												{ccipData.ipAddress ? (
													<Check data-tip={'test'} data-for="ipaddress" />
												) : (
													<NCheck
														className={styles.ncheck}
														data-tip={'test'}
														data-for="ipaddress"
													/>
												)}
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="ipaddress"
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="IP_ADDRESS"
																defaultMessage="IP Address"
															/>
														</span>
														<div>
															{data.hlrProfile &&
																data.hlrProfile.pdpContext.ipAddress &&
																data.hlrProfile.pdpContext.ipAddress}
														</div>
													</div>
												</ReactTooltip>
											</>
										</div>
									</div>
								</div>
							</div>
						)}
						<div
							data-spec="endpoint-data"
							className={styles.registration_details}
						>
							<div className={styles.rd_title}>
								<FormattedMessage id="ACTIONS" defaultMessage="Actions" />
							</div>
							<Button
								dataSpec="cancel-registration"
								variant="outline-primary"
								label={
									isCanceling ? (
										<FormattedMessage id="LOADING" defaultMessage="Loading" />
									) : (
										<FormattedMessage
											id="CANCEL_LOCATION"
											defaultMessage="Cancel Location"
										/>
									)
								}
								disabled={isCanceling}
								onClick={() => cancelRegistration(iccid)}
							/>
						</div>
					</main>
				)}
				{!refresh && selectedButton === 2 && (
					<SMSTab
						messages={messages}
						id={iccid}
						m2mAccountId={endpoint.m2mAccountId}
					/>
				)}
			</div>
		);
	}
}

DiagnosticCcip.propTypes = {
	iccid: string,
	endpoint: object,
	isPending: bool,
	isSuccess: bool,
	getDiagnosticCcip: func,
	cancelRegistration: func,
	isCanceling: bool,
	data: object,
	setDiagnosticCcipStateToInit: func,
	messages: object,
	user: object,
};
DiagnosticCcip.defaultProps = {
	endpoint: {},
	data: {},
	iccid: '',
	isPending: false,
	isSuccess: false,
	getDiagnosticCcip: undefined,
	cancelRegistration: undefined,
	isCanceling: false,
	setDiagnosticCcipStateToInit: undefined,
	messages: {},
	user: {},
};
