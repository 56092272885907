import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import submitForm from '../../EditNetworkEntitlement/submitForm';
import { maxLength } from '../../../../../utils/validators';

import Input from '../../../../../lib/DigitalComponents/FieldGroup';
import Checkbox from '../../../../../lib/DigitalComponents/Checkbox';
import StaticField from '../../../../../lib/DigitalComponents/StaticField';
import Button from '../../../../../lib/DigitalComponents/Button';

import styles from './AccDetails.scss';

const ArrowDown = ImageSelector(CurrentContext.theme, 'svgs/arrow-down.svg');

const max50 = maxLength(50);

class AccEntitlementDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showOnStateAPNs: false,
			showOffStateAPNs: false
		};
	}

	renderApns = (profile) => {
		const { entitlement, intl } = this.props;
		const profileDetails = entitlement && entitlement[profile];

		return (
			<div data-spec="network-entitlement-apns" className={styles.apn_list}>
				{profileDetails &&
					profileDetails.apns &&
					profileDetails.apns.map((apn, index) => (
						<div key={apn} className={styles.apn_details}>
							{profileDetails &&
								profileDetails.apns &&
								profileDetails.apns[index] && (
									<>
										<div>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.NAME',
													defaultMessage: 'Name'
												})}
												data={apn.name}
											/>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.DESCRIPTION',
													defaultMessage: 'Description'
												})}
												data={apn.description}
											/>
										</div>

										<div>
											{apn.lte ? (
												<StaticField
													label={intl.formatMessage({
														id: 'ONBOARDING.APNS_PDNINDEX',
														defaultMessage: 'PDN Index'
													})}
													data={apn.pdpindex}
												/>
											) : (
												<StaticField
													label={intl.formatMessage({
														id: 'ONBOARDING.APNS_PDPINDEX',
														defaultMessage: 'PDP Index'
													})}
													data={apn.pdpindex}
												/>
											)}
										</div>

										<div>
											<div
												className={cn(
													styles.left_content,
													styles.checkbox_margin
												)}
											>
												<Checkbox
													label={intl.formatMessage({
														id: 'ONBOARDING.APNS_LTE_CAPABLE',
														defaultMessage: 'LTE capable'
													})}
													disabled
													checked={apn.lte}
												/>
											</div>
											<div className={styles.field_wrapper}>
												<Checkbox
													label={intl.formatMessage({
														id: 'ONBOARDING.APNS_SECURITY_ENABLED',
														defaultMessage: 'Security enabled'
													})}
													disabled
													checked={apn.security}
												/>
											</div>
										</div>
										<div>
											<div
												className={cn(
													styles.left_content,
													styles.checkbox_margin
												)}
											>
												<Checkbox
													label={intl.formatMessage({
														id: 'ONBOARDING.APNS_FIXEDIP',
														defaultMessage: 'Fixed IP'
													})}
													disabled
													checked={apn.fixedIP}
												/>
											</div>
											<div className={styles.field_wrapper}>
												<Checkbox
													label={intl.formatMessage({
														id: 'ONBOARDING.APNS_SUBSCRIPTION_ENABLED',
														defaultMessage: 'Subscription enabled'
													})}
													disabled
													checked={apn.subscription}
												/>
											</div>
										</div>
									</>
								)}
						</div>
					))}
			</div>
		);
	};

	renderProfileDetails = (profile) => {
		const { entitlement, intl } = this.props;
		const profileDetails = entitlement && entitlement[profile];

		return (
			profileDetails && (
				<>
					<div>
						<div className={styles.field_wrapper}>
							<StaticField
								label={intl.formatMessage({
									id: 'ONBOARDING.ROAMING_RESTRICTION_SET_ID',
									defaultMessage: 'Roaming Restriction Set ID'
								})}
								data={profileDetails.roamingRestrictionSetId}
							/>
						</div>
					</div>

					<div className={styles.left_content}>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.GPRS',
									defaultMessage: 'GPRS'
								})}
								disabled
								checked={profileDetails.data}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.LTE_DATA',
									defaultMessage: 'LTE Data'
								})}
								disabled
								checked={profileDetails.dataLTE}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.VOLTE_ENABLED',
									defaultMessage: 'VoLTE Enabled'
								})}
								disabled
								checked={profileDetails.voLTE}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.SMS_MT',
									defaultMessage: 'SMS MT'
								})}
								disabled
								checked={profileDetails.smsMT}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.SMS_MO',
									defaultMessage: 'SMS MO'
								})}
								disabled
								checked={profileDetails.smsMO}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.BLOCK_INTERNATIONAL_MT',
									defaultMessage:
										'Block international SMS MO except home country'
								})}
								disabled
								checked={profileDetails.blockInternationalMO}
								multiline="true"
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.VOICE_MT',
									defaultMessage: 'VOICE MT'
								})}
								disabled
								checked={profileDetails.voiceMT}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.VOICE_MO',
									defaultMessage: 'VOICE MO'
								})}
								disabled
								checked={profileDetails.voiceMO}
							/>
						</div>

						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.INTERNATIONAL_VOICE',
									defaultMessage: 'International voice'
								})}
								disabled
								checked={profileDetails.internationalVoice}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.BLOCK_PREMIUM_NUMBERS',
									defaultMessage: 'Block Premium Numbers'
								})}
								disabled
								checked={profileDetails.blockPremiumNumbers}
							/>
						</div>
						<div className={styles.checkbox_margin}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.CREATED_ON_HLR',
									defaultMessage: 'Created on HLR'
								})}
								disabled
								checked={profileDetails.createdOnHlr}
							/>
						</div>
					</div>

					<div className={styles.field_wrapper}>
						<div className={cn(styles.checkbox_margin, styles.extend)}>
							<Checkbox
								label={intl.formatMessage({
									id: 'ONBOARDING.FACETIME',
									defaultMessage: 'Facetime'
								})}
								disabled
								checked={profileDetails.facetime}
							/>
						</div>
						<Field
							name={`${profile}.roaming`}
							component={Input}
							label={
								<FormattedMessage
									id="ONBOARDING.ROAMING"
									defaultMessage="Roaming"
								/>
							}
							validate={[max50]}
						/>
						<div className={styles.checkbox_wrapper}>
							<Field
								name={`${profile}.roamingLTE`}
								type="checkbox"
								component={Checkbox}
								label={
									<FormattedMessage
										id="ONBOARDING.ROAMING_LTE"
										defaultMessage="Roaming LTE"
									/>
								}
							/>
						</div>
						<Field
							name={`${profile}.camelServiceType`}
							component={Input}
							label={
								<FormattedMessage
									id="ONBOARDING.CAMEL_SERVICE_TYPE"
									defaultMessage="Camel service type"
								/>
							}
							validate={[max50]}
						/>
						<Field
							name={`${profile}.networkCountry`}
							component={Input}
							label={
								<FormattedMessage
									id="ONBOARDING.NETWORK_COUNTRY"
									defaultMessage="Network country"
								/>
							}
							validate={[max50]}
						/>
						<Field
							name={`${profile}.hotlineNumber`}
							component={Input}
							label={
								<FormattedMessage
									id="ONBOARDING.HOTLINE_NUBER"
									defaultMessage="Hotline number"
								/>
							}
							validate={[max50]}
						/>
						<Field
							name={`${profile}.networkAccessConfig`}
							component={Input}
							label={
								<FormattedMessage
									id="ONBOARDING.NETWORK"
									defaultMessage="Network"
								/>
							}
							validate={[max50]}
						/>
					</div>
				</>
			)
		);
	};

	toggleOnStateAPNs = () => {
		const { showOnStateAPNs } = this.state;

		this.setState({ showOnStateAPNs: !showOnStateAPNs });
	};

	toggleOffStateAPNs = () => {
		const { showOffStateAPNs } = this.state;

		this.setState({ showOffStateAPNs: !showOffStateAPNs });
	};

	render() {
		const {
			entitlement,
			intl,
			pushBack,
			handleSubmit,
			invalid,
			submitting,
			dirty
		} = this.props;
    const { showOnStateAPNs, showOffStateAPNs } = this.state;

		return (
			<div data-spec="acc-entitlement">
				<div>
					<Checkbox
						label={intl.formatMessage({
							id: 'ONBOARDING.DEFAULT_NETWORK_ENTITLEMENT',
							defaultMessage: 'Default network entitlement'
						})}
						disabled
						checked={
							!!(entitlement.default === 'true' || entitlement.default === true)
						}
					/>
				</div>
				<h3
					className={cn(styles.headline, styles.extend_margin)}
					data-spec="entitlement-title"
				>
					<FormattedMessage
						id="ONBOARDING.NETWORK_ENTITLEMENT_DETAILS"
						defaultMessage="Network Entitlement Details"
					/>
				</h3>

				<div>
					<StaticField
						label={intl.formatMessage({
							id: 'ONBOARDING.NETWORK_ENTITLEMENT_NAME',
							defaultMessage: 'Network Entitlement Name'
						})}
						data={entitlement.name}
					/>
					<StaticField
						label={intl.formatMessage({
							id: 'ONBOARDING.NETWORK_ENTITLEMENT_ID',
							defaultMessage: 'Network Entitlement ID'
						})}
						data={entitlement.id}
					/>
				</div>

				<div className={cn(styles.left_content, styles.no_margin)}>
					<StaticField
						label={intl.formatMessage({
							id: 'ONBOARDING.ENTITLEMENT_HLR_TEMPLATE',
							defaultMessage: 'HLR Template'
						})}
						data={
							entitlement.onStateProfile &&
							entitlement.onStateProfile.hlrTemplate
						}
					/>
					<StaticField
						label={intl.formatMessage({
							id: 'ONBOARDING.DESCRIPTION',
							defaultMessage: 'Description'
						})}
						data={
							entitlement.onStateProfile &&
							entitlement.onStateProfile.description
						}
					/>
				</div>

				<div className={styles.field_wrapper}>
					<div className={styles.checkbox_margin}>
						<Checkbox
							label={intl.formatMessage({
								id: 'ONBOARDING.FIXED_IP',
								defaultMessage: 'Fixed IP Enabled'
							})}
							disabled
							checked={
								entitlement.onStateProfile && entitlement.onStateProfile.fixedIp
							}
						/>
					</div>
					<div className={styles.checkbox_margin}>
						<Checkbox
							label={intl.formatMessage({
								id: 'ONBOARDING.CREATED_ON_HLR',
								defaultMessage: 'Created on HLR'
							})}
							disabled
							checked={
								entitlement.onStateProfile &&
								entitlement.onStateProfile.createdOnHlr
							}
						/>
					</div>
				</div>

				<h3
					className={cn(styles.headline, styles.extend_margin)}
					data-spec="on-state-title"
				>
					<FormattedMessage
						id="ONBOARDING.ON_STATE_NETWORK_PROFILE"
						defaultMessage="On-state Network Profile"
					/>
				</h3>

				{this.renderProfileDetails('onStateProfile')}

				{showOnStateAPNs ? (
					<div
						className={cn(styles.link, styles.link_margin)}
						onClick={this.toggleOnStateAPNs}
					>
						<FormattedMessage
							id="ONBOARDING.HIDE_APNS"
							defaultMessage="Hide APNs"
						/>
						<ArrowDown />
					</div>
				) : (
					<div
						className={cn(styles.link, styles.link_margin)}
						onClick={this.toggleOnStateAPNs}
					>
						<FormattedMessage
							id="ONBOARDING.SHOW_APNS"
							defaultMessage="Show APNs"
						/>
						<ArrowDown />
					</div>
				)}

				<div className={cn(styles.apn_section, showOnStateAPNs && styles.show)}>
					<h3
						className={cn(
							styles.headline,
							styles.border,
							showOnStateAPNs && styles.apns_margin
						)}
						data-spec="on-state-apns"
					>
						<FormattedMessage id="ONBOARDING.APNS" defaultMessage="APNs" />
					</h3>
					{this.renderApns('onStateProfile')}
				</div>

				<h3
					className={cn(styles.headline, styles.off_state)}
					data-spec="off-state-title"
				>
					<FormattedMessage
						id="ONBOARDING.OFF_STATE_NETWORK_PROFILE"
						defaultMessage="Off-state Network Profile"
					/>
				</h3>

				{this.renderProfileDetails('offStateProfile')}

				<div className={styles.off_state_apns}>
					{showOffStateAPNs ? (
						<div
							className={cn(styles.link, styles.link_margin)}
							onClick={this.toggleOffStateAPNs}
						>
							<FormattedMessage
								id="ONBOARDING.HIDE_APNS"
								defaultMessage="Hide APNs"
							/>
							<ArrowDown />
						</div>
					) : (
						<div
							className={cn(styles.link, styles.link_margin)}
							onClick={this.toggleOffStateAPNs}
						>
							<FormattedMessage
								id="ONBOARDING.SHOW_APNS"
								defaultMessage="Show APNs"
							/>
							<ArrowDown />
						</div>
					)}

					<div
						className={cn(styles.apn_section, showOffStateAPNs && styles.show)}
					>
						<h3
							className={cn(
								styles.headline,
								styles.border,
								showOffStateAPNs && styles.apns_margin
							)}
							data-spec="off-state-apns"
						>
							<FormattedMessage id="ONBOARDING.APNS" defaultMessage="APNs" />
						</h3>
						{this.renderApns('offStateProfile')}
					</div>
				</div>
				<br />

				<div className={styles.divider} />
				<div className={styles.section_buttons}>
					<Button
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						variant="outline-primary"
						onClick={pushBack}
					/>
					<Button
						label={<FormattedMessage id="SUBMIT" defaultMessage="Submit" />}
						variant="primary"
						className={styles.submit_button}
						onClick={handleSubmit}
						disabled={invalid || submitting || !dirty}
					/>
				</div>
			</div>
		);
	}
}

const { object, func, bool } = PropTypes;

AccEntitlementDetails.propTypes = {
	intl: object,
  entitlement: object,
  pushBack: func,
  handleSubmit: func,
  invalid: bool,
  submitting: bool,
  dirty: bool
};

let AccDetailsForm = reduxForm({
  form: 'EditNetworkEntitlement',
  onSubmit: submitForm,
  enableReinitialize: true
})(AccEntitlementDetails);

AccDetailsForm = connect((state, props) => ({
	initialValues: props.entitlement
}))(AccDetailsForm);

export default injectIntl(AccDetailsForm);
