import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import { showNotification } from '../../../Shared/redux/notifications/actions';
import DesktopView from './Desktop';
import MobileView from './Mobile';

const mapDispatchToProps = (dispatch) => ({
	goBack: () => dispatch(localizedRoute('/support')),
	showNotification: (message) =>
		dispatch(
			showNotification({
				id: 'faq-translation-notif',
				message: [message],
				type: 1,
				close: false,
				notificationType: 'admin'
			})
		)
});

export const Desktop = connect(
	null,
	mapDispatchToProps
)(DesktopView);
export const Mobile = connect(
	null,
	mapDispatchToProps
)(MobileView);
