export default {
	'SUPPORT.USER_GUIDE': 'Guía del usuario',
	'SUPPORT.PRINT_PDF': 'Imprimir PDF',
	'SUPPORT.SUPPORT': 'Asistencia',
	'SUPPORT.FAQ': 'PREGUNTAS FRECUENTES',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Respuestas a las preguntas frecuentes de nuestros usuarios.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Lea las instrucciones detalladas de las funciones del portal. La guía está adaptada a su tipo de cuenta.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Índice',
	'SUPPORT.TICKETING': 'Gestión de tickets',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'Si hay interrupciones del sistema o problemas de servicio con sus terminales y las pruebas de diagnóstico no los resuelven, cree un ticket del problema con nuestro equipo de operaciones. También puede hacer preguntas de facturación y servicio.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Notificaciones recientes',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Crear notificación nueva',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'Ver todas las notificaciones',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Preguntas frecuentes',
	'SUPPORT.TOPICS': 'Temas',
	'SUPPORT.EDIT_NOTIFICATION': 'Editar notificaciones',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Notificación global',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': 'Notificación de la empresa',
	'SUPPORT.NOTIFICATION_TYPE': '¿Tipo de notificación?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER':
		'Seleccionar marcador de posición de notif.',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Elegir a qué grupos de usuario notificar:',
	'SUPPORT.CONTENT': 'Contenido',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Ingresar contenido de notificación',
	'SUPPORT.PUBLISH': 'Publicar',
	'SUPPORT.GLOBAL': 'Global',
	'SUPPORT.COMPANY_RELATED': 'Relacionado con la empresa',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Confirmación',
	'SUPPORT.NOTIFICATION_INFO': 'Info.',
	'SUPPORT.NOTIFICATION_WARNING': 'Advertencia',
	'SUPPORT.NOTIFICATION_ERROR': 'Error',
	'SUPPORT.SELECT_SECTION': 'Seleccionar sección',
	'SUPPORT.ALL': 'Todos',
	'SUPPORT.ADMIN': 'Administrador',
	'SUPPORT.USER': 'Usuario',
	'SUPPORT.FINANCE': 'Finanzas',
	'SUPPORT.OPERATIONS': 'Operaciones',
	'SUPPORT.RESTRICTEDACCESS': 'Acceso restringido',
	'SUPPORT.COMPANYVIEW': 'Vista de la empresa',
	'SUPPORT.COMPANYVIEWREADONLY': 'Vista de la empresa - solo lectura',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Agregar notas de la versión',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'El documento siguiente no está traducido a su idioma totalmente. Se muestra la versión en inglés. Futuras versiones del producto estarán disponibles en su idioma.'
};
