import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import MyCompany from './MyCompany';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import { getMyCompany, editMyCompany } from '../../redux/companies/actions';
import {
	getMyCompanyRequest,
	getMyCompanySuccess,
	getMyCompanyFail,
	editMyCompanyRequest,
	editMyCompanySuccess,
	editMyCompanyFail,
} from '../../redux/companies/selectors';

const mapStateToProps = (state) => ({
	user: getUser(state),
	companyRequest: getMyCompanyRequest(state),
	companyFail: getMyCompanyFail(state),
	company: getMyCompanySuccess(state),
	editMyCompanyRequest: editMyCompanyRequest(state),
	editMyCompanySuccess: editMyCompanySuccess(state),
	editMyCompanyFail: editMyCompanyFail(state),
	getMyCompanyRequest: getMyCompanyRequest(state),
});

const mapDispatchToProps = (dispatch) => ({
	push: (url) => dispatch(localizedRoute(url)),
	getMyCompany: (params) => dispatch(getMyCompany(params)),
	editMyCompany: (params) => dispatch(editMyCompany(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCompany);
