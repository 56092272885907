import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import SubAccountAccordion from './SubAccountAccordion';

const mapDispatchToProps = (dispatch) => ({
	goToEditSubAccount: (url) => dispatch(localizedRoute(url))
});

export default connect(
	null,
	mapDispatchToProps
)(SubAccountAccordion);
