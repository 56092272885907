import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_ENDPOINTS, GET_ENDPOINTS_FILTERS } from './constants';

export default combineReducers({
	getEndpoints: globalReducer(GET_ENDPOINTS),
	getEndpointsFilters: globalReducer(GET_ENDPOINTS_FILTERS)
});
