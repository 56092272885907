import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../SimOrdering';

const Mobile = (props) => {
	const { simOrders, getOptions } = props;
	return (
		<Table
			data-spec="sim-ordering-table-mobile"
			data={simOrders.resultList}
			options={getOptions('mobile')}
			mobile
		/>
	);
};

const { array, func, shape } = PropTypes;

Mobile.propTypes = {
	simOrders: shape({
		resultList: array
	}),
	getOptions: func
};

export default hoc()(Mobile);
