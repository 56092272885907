import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { find } from 'lodash';
import { add } from 'stickyfilljs';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import {
	ComponentsRender,
	isUserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import SideBarItem from '../SideBarItem';

import styles from './SideBar.scss';

const CreateCompanyRWComponent = ComponentsRender('system.companies_rw');
const Carret = ImageSelector(CurrentContext.theme, 'svgs/arrow-down.svg');
const CloseSidebar = ImageSelector(
	CurrentContext.theme,
	'svgs/close-sidebar.svg'
);
const CompanyLogo = ImageSelector(
	CurrentContext.theme,
	'svgs/company-image-placeholder.svg'
);

class SideBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showDropmenuCompany: false,
		};
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);

		const domNode = this.sidebar;

		add(domNode);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	toggleSideBar = () => {
		const { toggleSideBar, isCollapsed } = this.props;
		localStorage.setItem('mnc-sidebar', `${!isCollapsed}`);
		toggleSideBar();
	};

	handleShowDropmenuCompany = () => {
		const { showDropmenuCompany } = this.state;

		this.setState({
			showDropmenuCompany: !showDropmenuCompany,
		});
	};

	handleClickOutside = (event) => {
		const { showDropmenuCompany } = this.state;
		const domNode = this.menu;
		const parentelement = event.target.parentElement
			? 'parentElement'
			: 'parentNode';

		if (
			showDropmenuCompany &&
			(!domNode || !domNode.contains(event.target)) &&
			event.target.id !== 'current-company' &&
			event.target[parentelement] &&
			event.target[parentelement][parentelement] &&
			event.target[parentelement][parentelement].id !== 'current-company' &&
			event.target[parentelement][parentelement].id !== 'account-carret' &&
			event.target.tagName.toLowerCase() !== 'path'
		) {
			this.setState({ showDropmenuCompany: false });
		}
	};

	renderSideBarMenu = () => {
		const { mainMenu, isCollapsed, location } = this.props;

		return (
			<div className={styles.menu_wrap} data-spec="menu-wrap">
				<ul className={styles.menu_list}>
					{mainMenu
						.filter((m) => m.sidebar)
						.map((item) => {
							const { wrapper: Wrapper } = item;

							return Wrapper ? (
								<Wrapper
									key={`sidebar-navigation-${item.id}`}
									data-spec="sidebar-navigation"
								>
									<SideBarItem
										alternateLink={item.alternateLink}
										dataSpec={item.data_spec}
										exact={item.exact}
										icon={item.icon}
										isCollapsed={isCollapsed}
										label={item.label}
										link={item.link}
										location={location}
										styles={styles}
										submenu={item.submenu}
										target={item.target}
									/>
								</Wrapper>
							) : (
								<SideBarItem
									alternateLink={item.alternateLink}
									className={item.className}
									dataSpec={item.data_spec}
									exact={item.exact}
									icon={item.icon}
									isCollapsed={isCollapsed}
									label={item.label}
									link={item.link}
									location={location}
									styles={styles}
									submenu={item.submenu}
									target={item.target}
									key={`sidebar-navigation-${item.id}`}
									data-spec="sidebar-navigation"
								/>
							);
						})}
				</ul>
			</div>
		);
	};

	renderCurrentCompany = () => {
		const {
			companyFriendlyName,
			isCollapsed,
			user,
			companyAccountId: companyServiceProp,
		} = this.props;

		const companyAccountId = find(user.companies, {
			externalCompanyId: companyServiceProp,
		});

		const regularCompany = user.companies.find(
			(item) => item.externalCompanyId === companyServiceProp
		);

		return (
			<div
				id="current-company"
				data-spec="current-company"
				className={styles.top_dropdown}
				onClick={this.handleShowDropmenuCompany}
			>
				<div
					className={cn(
						isCollapsed ? styles.account_title : styles.account_detail
					)}
				>
					{user.systemUser
						? (user.company &&
								user.company.companyLogo &&
								user.company.companyLogo.length > 0 &&
								user.company.companyLogo[0].logo && (
									<img
										alt="company-logo"
										className={styles.company_logo}
										src={user.company.companyLogo[0].logo}
									/>
								)) || (
								<div className={styles.company_logo}>
									<CompanyLogo />
								</div>
						  )
						: (regularCompany &&
								regularCompany.companyLogo &&
								regularCompany.companyLogo.length > 0 && (
									<img
										alt="company-logo"
										className={styles.company_logo}
										src={regularCompany.companyLogo[0].logo}
									/>
								)) || (
								<div className={styles.company_logo}>
									<CompanyLogo />
								</div>
						  )}
					<div className={styles.account_name}>
						{!isCollapsed && (
							<>
								<span>
									<FormattedMessage id="ACCOUNT" defaultMessage="ACCOUNT" />
								</span>
								<span>
									{(companyAccountId && companyAccountId.companyFriendlyName) ||
										companyFriendlyName}
								</span>
							</>
						)}
					</div>
				</div>
				<div className={styles.carret}>
					<Carret id="account-carret" />
				</div>
			</div>
		);
	};

	setUserCompany = (data) => {
		sessionStorage.setItem('company', data.externalCompanyId);
		window.location.href = `/${sessionStorage.getItem('language') || 'en-us'}`;
	};

	render() {
		const { addCompany, companyAccountId, user, isCollapsed } = this.props;
		const { showDropmenuCompany } = this.state;
		const getClass = cn({
			[styles.sidebar]: true,
			[styles.collapsed]: isCollapsed,
		});

		return (
			<div
				data-spec="sidebar"
				ref={(sidebar) => {
					this.sidebar = sidebar;
				}}
				className={getClass}
			>
				{this.renderCurrentCompany()}
				<div
					ref={(menu) => {
						this.menu = menu;
					}}
					className={cn(
						styles.account_dropdown,
						showDropmenuCompany ? styles.show : ''
					)}
				>
					<div className={styles.companies_wrapper}>
						{companyAccountId &&
							companyAccountId.length > 0 &&
							(isUserAllowedToAccess(
								['system.companies_rw', 'system.companies_ro'],
								user
							) ? (
								<div
									className={styles.account_dropdown_item}
									style={{ cursor: 'auto' }}
									data-spec="company-item"
								>
									<div className={styles.account_detail}>
										{(user.systemUser &&
											user.company &&
											user.company.companyLogo &&
											user.company.companyLogo.length > 0 &&
											user.company.companyLogo[0].logo && (
												<img
													alt="company-logo"
													className={styles.company_logo}
													src={user.company.companyLogo[0].logo}
												/>
											)) || (
											<div className={styles.company_logo}>
												<CompanyLogo />
											</div>
										)}
										<div className={styles.account_name}>
											<span>
												<FormattedMessage
													id="ACCOUNT"
													defaultMessage="ACCOUNT"
												/>
											</span>
											<span>
												{
													user.companies.find(
														({ externalCompanyId }) =>
															companyAccountId === externalCompanyId
													).companyFriendlyName
												}
											</span>
										</div>
									</div>
								</div>
							) : (
								user.companies.map((item) => (
									<div
										onClick={
											companyAccountId === item.externalCompanyId
												? undefined
												: () => {
														this.setUserCompany(item);
												  }
										}
										className={cn(styles.account_dropdown_item, {
											[styles.active]:
												companyAccountId === item.externalCompanyId,
										})}
										data-spec="company-item"
										key={item.company}
									>
										<div className={styles.account_detail}>
											{item.companyLogo &&
											item.companyLogo.length > 0 &&
											item.companyLogo[0].logo ? (
												<img
													alt="company-logo"
													className={styles.company_logo}
													src={item.companyLogo[0].logo}
												/>
											) : (
												<div className={styles.company_logo}>
													<CompanyLogo />
												</div>
											)}
											<div className={styles.account_name}>
												<span>
													<FormattedMessage
														id="ACCOUNT"
														defaultMessage="ACCOUNT"
													/>
												</span>
												<span>{item.companyFriendlyName}</span>
											</div>
										</div>
									</div>
								))
							))}
					</div>
					{companyAccountId.length === 0 && <hr className={styles.divider} />}
					{companyAccountId.length === 0 && (
						<CreateCompanyRWComponent>
							<div
								className={styles.add_account_text}
								onClick={() => addCompany()}
							>
								<FormattedMessage
									id="ADD_ANOTHER_ACCOUNT"
									defaultMessage="Add another account"
								/>
							</div>
						</CreateCompanyRWComponent>
					)}
				</div>
				{this.renderSideBarMenu()}
				<div
					onClick={this.toggleSideBar}
					className={styles.sidebar_toggle}
					data-spec="collapse_sidebar_toggle"
				>
					<span className={`${isCollapsed ? styles.collapsed_text : ''}`}>
						<FormattedMessage
							id="COLLAPSE_SIDEBAR"
							defaultMessage="Collapse sidebar"
						/>
					</span>
					<div className={styles.icon} data-spec="collapse_sidebar_toggle">
						<CloseSidebar />
					</div>
				</div>
			</div>
		);
	}
}

export default SideBar;

const {
	array,
	func,
	bool,
	string,
	object,
	objectOf,
	oneOfType,
	arrayOf,
} = PropTypes;

SideBar.propTypes = {
	addCompany: func,
	companyFriendlyName: string,
	companyAccountId: string,
	isCollapsed: bool,
	location: objectOf(string),
	mainMenu: arrayOf(object),
	toggleSideBar: func,
	user: oneOfType([array, object, string]),
};

SideBar.defaultProps = {
	addCompany: undefined,
	companyFriendlyName: '',
	companyAccountId: '',
	isCollapsed: false,
	location: {},
	mainMenu: [],
	toggleSideBar: undefined,
	user: {},
};
