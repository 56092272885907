export default {
	'SWAGGER.DEVELOPER_API': 'API sviluppatore',
	'SWAGGER.GETTING_STARTED': 'Come iniziare',
	'SWAGGER.API_SANDBOX': 'Sandbox API',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': 'Il sandbox Swagger seguente specifica l’elenco delle risorse disponibili nell’API REST e le operazioni che è possibile chiamare su tali risorse. Il documento specifica inoltre l’elenco dei parametri di un’operazione, compresi il nome e il tipo dei parametri, se i parametri sono obbligatori o facoltativi e informazioni sui valori accettabili per tali parametri.',
	'SWAGGER.JSON_SCHEMA_INCLUDES': 'È incluso uno schema JSON che descrive la struttura del corpo della richiesta inviato a un’operazione in un’API REST, insieme allo schema JSON che descrive la struttura di tutti i corpi di risposta restituiti da un’operazione.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Come iniziare con le API',
	'SWAGGER.GETTING_STARTED_WITH_API_P': 'Prima di poter effettuare la prima chiamata API, l’indirizzo IP di origine (server) deve essere autorizzato. L’IP di origine viene normalmente configurato durante il processo di onboarding quando viene creato l’accesso API. Se si ricevono errori, ciò è probabilmente dovuto a un blocco dell’accesso al gateway IoT AT&T.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': 'Per avviare lo sviluppo, assicurarsi di aver effettuato le seguenti operazioni:',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '- Aver impostato un server pubblicamente accessibile e averci fornito il relativo indirizzo IP durante l’onboarding.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '- L’IP di origine è stato autorizzato all’accesso del gateway IoT AT&T.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '- Il Suo amministratore aziendale ha fornito il nome utente e la password dell’API creati al momento dell’attivazione del servizio API.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '- Si ha accesso al proprio server delle risorse per l’esecuzione di comandi cURL, oppure si usano app di connessione scritte nel proprio linguaggio preferito indicate di seguito.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'Ottenere il token di accesso OAuth',
	'SWAGGER.GET_OAUTH_TOKEN_P': 'Ottenere il token OAuth da AT&T IoT Identity utilizzando nome utente e password forniti dal proprio amministratore utilizzando il metodo preferito tra quelli seguenti. Il token è valido per 7 giorni.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'Connettori API',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': 'Selezionare tra gli esempi seguenti come si desidera connettersi alla nostra API.',
	'SWAGGER.TEST_CONNECTION_T': 'Provare la connessione al gateway IoT',
	'SWAGGER.TEST_CONNECTION_P': 'Esempio curl:',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Risultato desiderato',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': 'Se si riceve il codice di risposta HTTP 200 OK, la configurazione è completa e si è pronti per avviare lo sviluppo.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': 'Risoluzione dei problemi di connessione',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': "In caso di errori, verificare innanzitutto se l’autenticazione è impostata correttamente nell’intestazione HTTP e che il token di accesso non sia scaduto (nel qual caso deve essere aggiornato). Inoltre, effettuare il ping del gateway IoT AT&T per verificare se il proprio IP di origine è stato autorizzato. Se si ottengono risultati imprevisti, è possibile inviare un ticket di risoluzione dei problemi all’assistenza.'"
};
