import React from 'react';
import { object, string } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import {
	FormattedDate,
	FormattedMessage,
} from 'react-intl';

import Collapsible from '../../../../lib/DigitalComponents/Accordion/Collapsible';
import { entitlementData } from '../../utils/constants';
import imageSelector from '../../../../utils/imageSelector';
import currentContext from '../../../../utils/currentContext';

import styles from './NetworkEntitlementDetails.scss';

const InfoFull = imageSelector(currentContext.theme, 'svgs/info-full.svg');

export default function NetworkEntitlementDetails({
	networkEntitlementId,
	entitlement,
}) {
	const renderBooleanValue = (value) => {
		if (value === undefined || value === null) return undefined;

		return value
			? <FormattedMessage id="YES" defaultMessage="Yes" />
			: <FormattedMessage id="NO" defaultMessage="No" />;
	};
	const renderTooltip = (id, defaultMessage, tooltipId) => (
		<div className={styles.label_tooltip}>
			<FormattedMessage id={id} defaultMessage={defaultMessage}>
				{(formattedValue) => (
					<InfoFull
						data-tip={formattedValue}
						data-for={`tooltip_${tooltipId}`}
					/>
				)}
			</FormattedMessage>
			<ReactTooltip
				className={styles.tooltip}
				type="light"
				id={`tooltip_${tooltipId}`}
			/>
		</div>
	);

	return (
		<div data-spec="network-entitlement-details">
			<Collapsible
				classParentString={styles.container}
				trigger={
					<div className={cn(styles.data, styles.entitlement_data)}>
						<span className={cn(styles.status_span, styles.entitlement)}>
							<FormattedMessage
								id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT"
								defaultMessage="Network Entitlement"
							/>
						</span>
						<span>
							<FormattedMessage
								id="SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID"
								defaultMessage="{name} - ID: {id}"
								values={{
									name: (entitlement && entitlement.name) || 'No Name',
									id: networkEntitlementId || 'No ID'
								}}
							/>
						</span>
					</div>
				}
			>
				<div className={styles.content}>
					{entitlement && Object.keys(entitlement).length > 0 ? (
						entitlementData(
							entitlement,
							entitlement &&
								entitlement.onStateProfile &&
								entitlement.onStateProfile.apns &&
								entitlement.onStateProfile.apns.length > 0
								? entitlement.onStateProfile.apns[0].fixedIP
								: undefined
						).map((item) => (
							<div
								className={styles.data}
								key={item.label.id}
								data-spec="network-entitlement-item"
							>
								<span
									className={cn({
										[styles.status_span]: true,
										[styles.grayedout]: item.value === undefined
									})}
								>
									<FormattedMessage
										id={item.label.id}
										defaultMessage={item.label.defaultMessage}
									/>
									{item.labelTooltip &&
										renderTooltip(
											item.labelTooltip.id,
											item.labelTooltip.defaultMessage,
											item.labelTooltip.tooltipId
										)}
								</span>
								<span
									className={cn({
										[styles.grayedout]: item.value === undefined
									})}
								>
									{(!item.value && (
										<FormattedMessage
											id="SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT"
											defaultMessage="Not Applicable For This Endpoint"
										/>
									)) ||
										(item.value && item.isDate && (
											<FormattedDate
												value={item.value}
												year="numeric"
												month="2-digit"
												day="2-digit"
											/>
										)) ||
										(item.value &&
											item.isBool &&
											renderBooleanValue(item.value)) ||
										item.value}
								</span>
							</div>
						))
					) : (
						<div>
							<FormattedMessage
								id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT"
								defaultMessage="Error on network entitlement, please contact Customer Support."
							/>
						</div>
					)}
				</div>
			</Collapsible>
			<div className={styles.apns}>
				{entitlement &&
				entitlement.onStateProfile &&
				entitlement.onStateProfile.apns &&
				entitlement.onStateProfile.apns.length > 0
					? entitlement.onStateProfile.apns.map(
							(apn) =>
								apn &&
								apn.name && (
									<div className={styles.apns_container} key={apn.id}>
										<div className={styles.apn_content}>
											<div data-spec="apn-data-item" className={styles.data}>
												<span className={styles.status_span}>
													<FormattedMessage
														id="SINGLE_ENDPOINT.APN"
														defaultMessage="APN"
													/>
												</span>
												<span>{apn.name}</span>
											</div>
										</div>
									</div>
								)
					  )
					: null}
			</div>
		</div>
	);
}

NetworkEntitlementDetails.propTypes = {
	networkEntitlementId: string,
	entitlement: object,
};

NetworkEntitlementDetails.defaultProps = {
	networkEntitlementId: '',
	entitlement: {},
};
