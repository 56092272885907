import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { isEqual } from 'lodash';

import Button from '../../../../../lib/DigitalComponents/Button';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import {
	updateBulkNetworkEntitlement,
	updateBulkNetworkEntitlementStatusStarted
} from '../../../redux/updateBulkNetworkEntitlement/actions';

import {
	getCurrentSim,
	isBulkNetworkEntitlementUpdating,
	bulkNetworkEntitlementUpdateFail,
	bulkNetworkEntitlementUpdateSuccess,
	getFailSims,
	getSuccessSims,
	getIsStarted,
	getActionError,
	getFilteredSims
} from '../../../redux/updateBulkNetworkEntitlement/selector';

import styles from '../ChangeSimStateSteps/Steps.scss';

const Error = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const Success = ImageSelector(CurrentContext.theme, 'svgs/ok-full.svg');
const ErrorNotification = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-error.svg'
);
const Approval = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-approval.svg'
);

class StepThird extends Component {
	componentDidMount() {
		const {
			updateBulkEntitlement,
			updateBulkEntitlementStatusStarted,
			networkEntitlement,
			context
		} = this.props;

		updateBulkEntitlementStatusStarted();
		updateBulkEntitlement(networkEntitlement);

		if (context) {
			this.setFooter();
		}
	}

	componentDidUpdate(prevProps) {
		const { context, isStarted } = this.props;
		if (context && !isEqual(isStarted, prevProps.isStarted)) {
			this.setFooter();
		}
	}

	setFooter = () => {
		const { context, isStarted, onClose } = this.props;

		const footer = (
			<>
				<Button
					variant="success"
					onClick={onClose}
					disabled={isStarted}
					label={<FormattedMessage id="DONE" defaultMesssage="Done" />}
					dataSpec="done"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderNotification = (networkEntitlement, successSims) => {
		if (!networkEntitlement.data) {
			return;
		}
		if (successSims && successSims.length) {
			return (
				<div data-spec="notification" className={styles.notification}>
					<div className={styles.image}>
						<Approval />
					</div>
					{networkEntitlement.data.startDate !==
					format(new Date(), 'yyyy-MM-dd') ? (
						<div className={styles.schedule}>
							<div className={styles.text} data-spec="action-status-success">
								<FormattedMessage
									id="SCHEDULE_NETWORK_ENTITLEMENT_SUCCESSFUL"
									defaultMesssage="Network entitlement for multiple SIMs is successfully scheduled"
								/>
							</div>
							<div className={styles.schedule_text}>
								<FormattedMessage
									id="SIM_NETWORK_ENTITLEMENT_CHANGE_FOR"
									defaultMesssage="SIM network entitlement change for"
								/>
								{successSims.length}
								<FormattedMessage id="OF" defaultMesssage="of" />
								{networkEntitlement.selectedSims.length}
								&nbsp;
								<FormattedMessage
									id="SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR"
									defaultMesssage="SIMS is successfully scheduled for"
								/>
								&nbsp;
								{format(
									new Date(networkEntitlement.data.startDate),
									'MM/dd/yyyy'
								)}
							</div>
						</div>
					) : (
						<div className={styles.text} data-spec="action-status-success">
							{successSims.length}
							<FormattedMessage id="OF" defaultMesssage=" of " />
							{networkEntitlement.selectedSims.length}
							&nbsp;
							<FormattedMessage
								id="ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED"
								defaultMesssage="SIMs network entitlement successfully updated"
							/>
						</div>
					)}
				</div>
			);
		}

		return (
			<div
				data-spec="notification"
				className={cn(styles.notification, styles.error)}
			>
				<div className={styles.image}>
					<ErrorNotification />
				</div>
				{networkEntitlement.data.startDate !==
				format(new Date(), 'yyyy-MM-dd') ? (
					<div className={styles.schedule}>
						<div className={styles.text} data-spec="action-status-error">
							<FormattedMessage
								id="MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_SCHEDULE_ERROR"
								defaultMesssage="Multiple SIM network entitlement change could not be scheduled successfully"
							/>
						</div>
						<div className={styles.schedule_text}>
							<FormattedMessage
								id="PLEASE_TRY_AGAIN"
								defaultMesssage="Please try again."
							/>
						</div>
					</div>
				) : (
					<div className={styles.schedule}>
						<div className={styles.text} data-spec="action-status-error">
							<FormattedMessage
								id="ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR"
								defaultMesssage="Multiple SIM network entitlement change could not be requested successfully"
							/>
						</div>
						<div className={styles.schedule_text}>
							<FormattedMessage
								id="PLEASE_TRY_AGAIN"
								defaultMesssage="Please try again."
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	renderSimData = (
		sim,
		index,
		currentSim,
		isUpdating,
		failSims,
		successSims,
		filteredSims
	) => {
		if (sim === currentSim && isUpdating) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<div className={styles.loader}>
						<Loader size={15} data-spec="loader" />
					</div>
				</li>
			);
		}

		if (failSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<span
							className={styles.data}
							data-spec={`tooltip-${sim}`}
							data-tip={this.getErrorMessage()}
							data-for={sim}
						>
							<FormattedMessage id="ERROR" defaultMesssage="Error" />
						</span>
						<ReactTooltip type={'error'} id={sim} className={styles.tooltip} />
					</div>
				</li>
			);
		}

		if (successSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index} className={styles.success}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Success />
					</span>
					<span>
						<FormattedMessage id="SUCCESS_KEY" defaultMesssage="Success" />
					</span>
				</li>
			);
		}

		if (filteredSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<FormattedMessage id="ERROR" defaultMesssage="Error" />
            [
						<span
							className={styles.data}
							data-spec={`tooltip-${sim}`}
							data-tip="Network entitlement change is not allowed!"
							data-for={sim}
						>
							<FormattedMessage id="REASON" defaultMesssage="reason" />
						</span>
						]
						<ReactTooltip type={'error'} id={sim} className={styles.tooltip} />
					</div>
				</li>
			);
		}

		return (
			<li data-spec="sim" key={index} className={styles.start}>
				<div className={styles.sim}>{sim}</div>
			</li>
		);
	};

	renderSims = (
		networkEntitlement,
		currentSim,
		isUpdating,
		failSims,
		successSims,
		filteredSims
	) => (
		<ul data-spec="sims" className={styles.sims_result}>
			{networkEntitlement.selectedSims.map((item, index) =>
				this.renderSimData(
					item.endpointName,
					index,
					currentSim,
					isUpdating,
					failSims,
					successSims,
					filteredSims
				))}
		</ul>
	);

	render() {
		const {
			networkEntitlement,
			isStarted,
			successSims,
			isUpdating,
			currentSim,
			failSims,
			filteredSims
		} = this.props;

		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className="confirm-content">
						<div className={`${styles.step_first} ${styles.step_third}`}>
							<h2 className={styles.touppercase}>
								<FormattedMessage
									id="ENDPOINTS.CONFIRM_NETWORK_ENTITLEMENT_CHANGE"
									defaultMesssage="Confirm network entitlement change"
								/>
							</h2>
							<p>
								{networkEntitlement.selectedSims.length}
								&nbsp;
								<FormattedMessage
									id="SIMS_SELECTED"
									defaultMesssage="SIMs selected"
								/>
							</p>
						</div>
					</div>
					{!isStarted &&
						this.renderNotification(networkEntitlement, successSims)}
					{this.renderSims(
						networkEntitlement,
						currentSim,
						isUpdating,
						failSims,
						successSims,
						filteredSims
					)}
				</div>
			</div>
		);
	}
}

const { object, func, array, bool, string } = PropTypes;

StepThird.propTypes = {
	networkEntitlement: object,
	selectedSims: array,
	isStarted: bool,
	context: object,
	updateBulkEntitlement: func,
	updateBulkEntitlementStatusStarted: func,
	onClose: func,
	successSims: array,
	isUpdating: bool,
	currentSim: string,
	failSims: array,
	filteredSims: array
};

const mapStateToProps = (state) => ({
	currentSim: getCurrentSim(state),
	isUpdating: isBulkNetworkEntitlementUpdating(state),
	isFail: bulkNetworkEntitlementUpdateFail(state),
	isSucces: bulkNetworkEntitlementUpdateSuccess(state),
	failSims: getFailSims(state),
	successSims: getSuccessSims(state),
	isStarted: getIsStarted(state),
	errors: getActionError(state),
	filteredSims: getFilteredSims(state)
});

const mapDispatchToProps = (dispatch) => ({
	updateBulkEntitlement: (data) =>
		dispatch(updateBulkNetworkEntitlement(data)),
	updateBulkEntitlementStatusStarted: () =>
		dispatch(updateBulkNetworkEntitlementStatusStarted())
});

export default connect(mapStateToProps, mapDispatchToProps)(StepThird);
