/* eslint-disable array-callback-return */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SecurityFeaturesForm from '../SecurityFeaturesForm';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './SecurityFeaturesModal.scss';

class SecurityFeaturesModal extends PureComponent {
	addSubmit = () => {
		const { formValues, addItem, id, toggleModal } = this.props;
		const data = {
			customerSecurityFeatureTypeValue:
				formValues.itemFields[0].customerSecurityFeatureTypeValue,
			m2mAccountId: formValues.itemFields[0].m2mAccountId.value,
			customerSecurityFeatureTypeId: id
		};
		toggleModal();
		addItem(data);
	};

	editSubmit = () => {
		const { formValues, toggleModal, updateItem } = this.props;
		const toDelete = formValues.itemFields
			.map((item) => {
				if (item.delete) {
					return { id: item.id };
				}
			})
			.filter((item) => item);
		const toUpdate = formValues.itemFields
			.filter((item) => !item.delete)
			.map((item) => ({
				id: item.id,
				customerSecurityFeatureTypeValue: String(
					item.customerSecurityFeatureTypeValue
				),
				customerSecurityFeatureStatusId: item.customerSecurityFeatureStatusId,
				m2mAccountId:
					typeof item.m2mAccountId === 'string'
						? item.m2mAccountId
						: item.m2mAccountId.value
			}));
		const data = {
			customerSecurityFeaturesToUpdate: toUpdate,
			customerSecurityFeaturesToDelete: toDelete
		};
		updateItem(data);
		toggleModal();
	};

	render() {
		const {
			messages,
			id,
			type,
			fields,
			isDirty,
			isInvalid,
			show,
			toggleModal
		} = this.props;
		return (
			<div data-spec="add-securityFeatures-modal">
				<Modal
					className={type === 'edit' ? styles.modal_edit : styles.modal_add}
					show={show}
					onClose={() => toggleModal()}
				>
					<ModalHeader
						messages={messages}
						title={
							type === 'edit' ? (
								<FormattedMessage
									id={`SECURITY_FEATURES_EDIT_${id}`}
									defaultMessage="Security Features Edit"
								/>
							) : (
								<FormattedMessage
									id={`SECURITY_FEATURES_ADD_${id}`}
									defaultMessage="Security Features Add"
								/>
							)
						}
					/>
					<ModalBody className={styles.modal_body}>
						<SecurityFeaturesForm
							messages={messages}
							id={id}
							type={type}
							fields={fields}
						/>
					</ModalBody>
					<ModalFooter className={styles.footer}>
						<Button
							onClick={() => toggleModal()}
							variant="link"
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
							data-spec="edit-table-modal-cancel-button"
						/>
						{type === 'add' ? (
							<Button
								disabled={isInvalid || !isDirty}
								onClick={this.addSubmit}
								variant="primary"
								label={<FormattedMessage id="SUBMIT" defaultMessage="Submit" />}
								data-spec="edit-table-modal-cancel-button"
							/>
						) : (
							<Button
								disabled={isInvalid || !isDirty}
								onClick={this.editSubmit}
								variant="primary"
								label={<FormattedMessage id="UPDATE" defaultMessage="Update" />}
								data-spec="edit-table-modal-cancel-button"
							/>
						)}
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}
const {
	func,
	arrayOf,
	number,
	bool,
	string,
	objectOf,
	shape,
	object
} = PropTypes;

SecurityFeaturesModal.propTypes = {
	formValues: shape(),
	addItem: func,
	id: number,
	toggleModal: func,
	updateItem: func,
	type: string,
	messages: objectOf(string),
	show: bool,
	fields: arrayOf(object),
	isDirty: bool,
	isInvalid: bool
};

export default SecurityFeaturesModal;
