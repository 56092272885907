import React from 'react';
import { FormattedMessage } from 'react-intl';

export const rulesTableHeadersDesktop = [
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.RULE_NAME"
				defaultMessage="Rule Name"
			/>
		),
		name: 'name'
	},
	{
		title: <FormattedMessage id="AUTOMATION_RULES.ID" defaultMessage="ID" />,
		name: 'id'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.PLATFORM"
				defaultMessage="Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.CATEGORY"
				defaultMessage="Category"
			/>
		),
		name: 'category'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.TRIGGER"
				defaultMessage="Trigger"
			/>
		),
		name: 'trigger'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.ACTIVE"
				defaultMessage="Activated"
			/>
		),
		name: 'active'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.STATUS"
				defaultMessage="Implementation Status"
			/>
		),
		name: 'status'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.M2M_ACCOUNT_ID"
				defaultMessage="M2M Account ID"
			/>
		),
		name: 'm2mAccountId'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.DATE_CREATED"
				defaultMessage="Date Created"
			/>
		),
		name: 'createdAt'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_RULES.NOTES" defaultMessage="Notes" />
		),
		name: 'notes'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_RULES.USER" defaultMessage="User" />
		),
		name: 'userId'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_RULES.ACTIONS" defaultMessage=" " />
		),
		name: 'userActions'
	}
];
export const rulesTableHeadersMobile = [
	{
		title: <FormattedMessage id="AUTOMATION_RULES.ID" defaultMessage="ID" />,
		name: 'id'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.PLATFORM"
				defaultMessage="Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.CATEGORY"
				defaultMessage="Category"
			/>
		),
		name: 'category'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.TRIGGER"
				defaultMessage="Trigger"
			/>
		),
		name: 'trigger'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_RULES.ACTIVE" defaultMessage="Actived" />
		),
		name: 'active'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.M2M_ACCOUNT_ID"
				defaultMessage="M2M Account ID"
			/>
		),
		name: 'm2mAccountId'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULES.DATE_CREATED"
				defaultMessage="Date Created"
			/>
		),
		name: 'createdAt'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_RULES.USER" defaultMessage="User" />
		),
		name: 'userId'
	}
];

export const alertsTableHeadersDesktop = [
	{
		title: <FormattedMessage id="AUTOMATION_ALERTS.ID" defaultMessage="ID" />,
		name: 'id'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.M2M_ACCOUNT_ID"
				defaultMessage="M2M Account ID"
			/>
		),
		name: 'm2mAccountId'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.RULE_NAME"
				defaultMessage="Rule Name"
			/>
		),
		name: 'name'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.PLATFORM"
				defaultMessage="Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.CATEGORY"
				defaultMessage="Category"
			/>
		),
		name: 'category'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.TRIGGER"
				defaultMessage="Trigger"
			/>
		),
		name: 'trigger'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_ALERTS.DATE" defaultMessage="Date" />
		),
		name: 'alertDate'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_ALERTS.USER" defaultMessage="User" />
		),
		name: 'userId'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_ALERTS.ACTION" defaultMessage="Action" />
		),
		name: 'actionName'
	}
];
export const alertsTableHeadersMobile = [
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.RULE_NAME"
				defaultMessage="Rule Name"
			/>
		),
		name: 'name'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.PLATFORM"
				defaultMessage="Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.CATEGORY"
				defaultMessage="Category"
			/>
		),
		name: 'category'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_ALERTS.TRIGGER"
				defaultMessage="Trigger"
			/>
		),
		name: 'trigger'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_ALERTS.DATE" defaultMessage="Date" />
		),
		name: 'alertDate'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_ALERTS.USER" defaultMessage="User" />
		),
		name: 'userId'
	}
];

export const ruleHistoryTableHeader = [
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.USER"
				defaultMessage="User"
			/>
		),
		name: 'user'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.DATE"
				defaultMessage="Date"
			/>
		),
		name: 'date'
	},

	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.ACTION"
				defaultMessage="Action"
			/>
		),
		name: 'action'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.PLATFORM"
				defaultMessage="Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.CATEGORY"
				defaultMessage="Category"
			/>
		),
		name: 'category'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.TRIGGER"
				defaultMessage="Trigger"
			/>
		),
		name: 'trigger'
	},
	{
		title: (
			<FormattedMessage id="AUTOMATION_RULE_HISTORY.IF" defaultMessage="If" />
		),
		name: 'if'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.THRESHOLD"
				defaultMessage="Threshold"
			/>
		),
		name: 'threshold'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.RULE_ACTION"
				defaultMessage="Rule Action"
			/>
		),
		name: 'rule_action'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.FILTERS"
				defaultMessage="Filters"
			/>
		),
		name: 'filters'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.FOLLOW_UP_ACTION"
				defaultMessage="Follow Up Action"
			/>
		),
		name: 'follow_up_action'
	},
	{
		title: (
			<FormattedMessage
				id="AUTOMATION_RULE_HISTORY.RULE_NAME"
				defaultMessage="Rule Name"
			/>
		),
		name: 'rule_name'
	}
];
