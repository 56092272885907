export default {
	'BATCH_HISTORY.LINK': 'Historial de lote',
	'BATCH_HISTORY.TITLE': 'Historial de lote',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Acciones de lote',
	'BATCH_HISTORY.BATCH_REPORTS': 'Informe de lote',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'No hay acciones de lote disponibles',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': 'No hay acciones de lote para esta tabla para mostrarle ahora',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'No hay informes de lote disponibles',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': 'No hay informes de lote para esta tabla para mostrarle ahora',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Nombre de lote de archivo',
	'BATCH_HISTORY.DATE_UPLOADED': 'Fecha de carga',
	'BATCH_HISTORY.STATUS': 'Estado',
	'BATCH_HISTORY.REPORT_NAME': 'Informe',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
	'BATCH_HISTORY.DATE': 'Fecha',
	'BATCH_HISTORY.M2M_PLATFORM': 'Plataforma M2M',
	'BATCH_HISTORY.FREQUENCY': 'Frecuencia',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Enviado',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Frecuencia',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'En progreso',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Completado',
  'BATCH_HISTORY.ERROR_STATUS': 'Error',
  'BATCH_HISTORY.CANCELLED_STATUS': 'Cancelado',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': 'El archivo se cargó con éxito para procesar',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': 'El archivo se cargó con éxito, programado para procesar',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Procesamiento en curso',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'El archivo se procesó con éxito',
  'BATCH_HISTORY.ERROR_TOOLTIP': 'Se produjo un error en el procesamiento',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': 'Descripción emergente cancelada',
};
