import { Status } from '../../../../utils/constants';

export const getRatePlansRequest = (state) =>
	state.analyticsInvoices.tables.ratePlans.getRatePlans.status ===
	Status.PENDING;
export const getRatePlansFail = (state) =>
	state.analyticsInvoices.tables.ratePlans.getRatePlans.status === Status.FAIL;
export const getRatePlansSuccess = (state) =>
	state.analyticsInvoices.tables.ratePlans.getRatePlans.data;

export const getRatePlansFiltersRequest = (state) =>
	state.analyticsInvoices.tables.ratePlans.getRatePlansFilters.status ===
	Status.PENDING;
export const getRatePlansFiltersFail = (state) =>
	state.analyticsInvoices.tables.ratePlans.getRatePlansFilters.status ===
	Status.FAIL;
export const getRatePlansFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.ratePlans.getRatePlansFilters.data;
