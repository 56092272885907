import React from 'react';
import PropTypes from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import Analytics from '../../components/Analytics';

const AnalyticsView = ({ dispatch }) => (
	<>
		<DesktopMediaQuery>
			<Analytics dispatch={dispatch} dataGridSize="lg" />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Analytics dispatch={dispatch} dataGridSize="md" />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<Analytics dispatch={dispatch} dataGridSize="sm" />
		</MobileMediaQuery>
	</>
);

const { func } = PropTypes;

AnalyticsView.propTypes = {
	dispatch: func
};

AnalyticsView.defaultProps = {
	dispatch: undefined
};

export default AnalyticsView;
