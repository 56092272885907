import React from 'react';
import { string, objectOf, object, arrayOf, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import { ddCarrierField } from '../../utils/constants';

import styles from './DiagnosticDetails.scss';

export default function DiagnosticsDetails({
	isPending,
	carrier,
	fields,
	carrierName,
	data,
}) {
	const getValue = (value) => (
		<FormattedMessage
			id={`dd_${value.toLowerCase()}`}
			defaultMessage={value}
		/>
	);
	const getSubFieldStatus = ({ id, successMessages, errorMessages }) => {
		if (!data[id]) {
			return 'disabled';
		}
		if (successMessages.indexOf(data[id].toLowerCase()) !== -1) {
			return 'success';
		}
		if (errorMessages.indexOf(data[id].toLowerCase()) !== -1) {
			return 'error';
		}
		return 'disabled';
	};
	const getFieldStatus = (statusArray) => {
		let iconStatus = 'success';
		let fieldStatus = 'success';

		if (statusArray.indexOf('error') !== -1) {
			iconStatus = 'error';
			fieldStatus = 'error';
		} else if (statusArray.filter((item) => item !== 'disabled').length === 0) {
			iconStatus = 'disabled';
			fieldStatus = 'disabled';
		} else if (statusArray.indexOf('disabled') !== -1) {
			iconStatus = 'disabled';
		}

		return [iconStatus, fieldStatus];
	};
	const parseFields = () => fields.reduce((modFields, field) => {
		const fieldsStatuses = [];
		const tempFieldData = field.data.map((item) => {
			const itemStatus = getSubFieldStatus(item);

			fieldsStatuses.push(itemStatus);

			return {
				id: item.id,
				defaultLabel: item.defaultLabel,
				value: data[item.id] ? getValue(data[item.id]) : '',
				status: itemStatus,
			};
		});
		const [iconStatus, fieldStatus] = getFieldStatus(fieldsStatuses);
		modFields.push({
			...field,
			data: tempFieldData,
			iconStatus,
			fieldStatus,
		});

		return modFields;
	}, []);

	if (isPending) {
		return (
			<div
				data-spec="registration-details-is-fetching"
				className={styles.diagnostics_details}
			>
				<Loader data-spec="diagnostics-details-loader" />
			</div>
		);
	}

	const parsedFields = parseFields(fields);
	const renderIcon = (icon, iconStatus) => {
		const Icon = ImageSelector(
			CurrentContext.theme,
			`svgs/${icon}${iconStatus === 'error' ? `_${iconStatus}` : ''}.svg`,
		);

		return <Icon data-spec="diagnostics-icon" />;
	};
	const status = carrierName ? 'success' : 'disabled';

	return (
		<div
			data-spec="diagnostics-details"
			className={styles.diagnostics_details}
		>
			{Object.keys(data).length > 0 && (
				<>
					<div className={styles.dd_title}>
						<div
							data-spec={`dd-field-${carrier.id}`}
							className={`${styles.dd_field} ${styles[carrier.fieldStatus]}`}
						>
							<div className={styles.dd_title}>
								<div className={styles.dd_title_left} />
								<div
									className={
										`${styles.dd_title_right} ${styles[carrier.fieldStatus]}`
									}
								>
									<FormattedMessage
										id={carrier.id}
										defaultMessage={carrier.defaultLabel}
									/>
								</div>
							</div>
							<div className={styles.dd_content}>
								<div
									className={
										`${styles.dd_content_left} ${styles[carrier.fieldStatus]}`
									}
								>
									{renderIcon(carrier.icon)}
								</div>
								<div className={styles.dd_content_right}>
									{ddCarrierField.data.map((item) => (
										<div
											data-spec={`dd-field-item-${item.id}`}
											className={
												`${styles.dd_field_item} ${styles[status]}`
											}
											key={item.id}
										>
											<div
												className={
													`${styles.dd_item_title} ${styles[status]}`
												}
											>
												{item.id && <FormattedMessage
													id={item.id}
													defaultMessage={item.defaultLabel}
												/>}
											</div>
											<div
												className={
													`${styles.dd_item_content} ${styles[status]}`
												}
											>
												{carrierName || '-'}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.dd_fields}>
						{parsedFields.map((item) => (
							<div
								data-spec={`dd-field-${item.id}`}
								className={`${styles.dd_field} ${styles[item.fieldStatus]}`}
								key={item.id}
							>
								<div className={styles.dd_title}>
									<div
										className={
											`${styles.dd_title_left} ${styles[item.iconStatus]}`
										}
									>
										<div className={styles[item.iconStatus]} />
										<div className={styles[item.iconStatus]} />
										<div className={styles[item.iconStatus]} />
									</div>
									<div
										className={
											`${styles.dd_title_right} ${styles[item.fieldStatus]}`
										}
									>
										<FormattedMessage
											id={item.id}
											defaultMessage={item.defaultLabel}
										/>
									</div>
								</div>
								<div className={styles.dd_content}>
									<div
										className={
											`${styles.dd_content_left} ${styles[item.iconStatus]}`
										}
									>
										{renderIcon(item.icon, item.iconStatus)}
									</div>
									<div className={styles.dd_content_right}>
										{item.data.map((field) => (
											<div
												data-spec={`dd-field-item-${field.id}`}
												className={
													`${styles.dd_field_item} ${styles[field.status]}`
												}
												key={field.id}
											>
												<div
													className={
														`${styles.dd_item_title} ${styles[field.status]}`
													}
												>
													{field.id && <FormattedMessage
														id={field.id}
														defaultMessage={field.defaultLabel}
													/>}
												</div>
												<div
													className={
														`${styles.dd_item_content} ${styles[field.status]}`
													}
												>
													{field.value || '-'}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}

DiagnosticsDetails.propTypes = {
	data: objectOf(string).isRequired,
	fields: arrayOf(object).isRequired,
	isPending: bool,
	carrier: object,
	carrierName: string,
};

DiagnosticsDetails.defaultProps = {
	isPending: true,
	carrier: {},
	carrierName: '',
};
