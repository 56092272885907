import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import UserManagement from './UserManagement';

import { simpleSearchClose } from '../../../Shared/redux/simpleSearch/actions';
import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';
import { setPaginationInit } from '../../../Shared/redux/pagination/actions';
import { getMyCompany } from '../../redux/companies/actions';
import {
	getMyCompanySuccess,
	getMyCompanyRequest
} from '../../redux/companies/selectors';

import {
	getUser as currentUser
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	companyRequest: getMyCompanyRequest(state),
	company: getMyCompanySuccess(state),
	currentUser: currentUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	push: (url) => dispatch(localizedRoute(url)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	setPaginationInit: () => dispatch(setPaginationInit()),
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
	getMyCompany: (params) => dispatch(getMyCompany(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
