import globalAction from '../../../../redux/global/globalAction';
import { EDIT_COMPANY_REPORTS, GET_COMPANY_REPORTS } from './constants';
import {
	editCompanyReports as editCompanyReportsService,
	getCompanyReports as getCompanyReportsService
} from '../../services/CompanyReportsService';

/* ******************** EDIT COMPANY REPORTS ******************** */
export const editCompanyReports = (params) =>
	globalAction(EDIT_COMPANY_REPORTS, editCompanyReportsService, params, {
		notify: 'update-company-reports',
		notifyMessageSuccess:
			'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED',
		notifyMessageError: 'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS',
		init: true
	});

/* ******************** GET COMPANY REPORTS ******************** */
export const getCompanyReports = (params) =>
	globalAction(GET_COMPANY_REPORTS, getCompanyReportsService, params);
