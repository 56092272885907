import React from 'react';
import { FormattedMessage } from 'react-intl';

export const manualRuleStates = {
	NOT_APPLICABLE: 'Not Applicable',
	PENDING: 'Pending',
	RECEIVED: 'Received',
	IMPLEMENTED: 'Implemented',
	CONFLICTED: 'Conflicted',
	PENDING_FOR_DELETION: 'Pending for Deletion'
};

export const ruleStatusIds = {
	NOT_APPLICABLE: 1,
	PENDING: 2,
	RECEIVED: 3,
	IMPLEMENTED: 4,
	CONFLICTED: 5,
	PENDING_FOR_DELETION: 6,
	DELETED: 7
};

export const getPossibleStates = (state) => {
	switch (state) {
		case manualRuleStates.PENDING:
			return [
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_SELECT_ACTION"
							defaultMessage="Select Action:"
						/>
					),
					value: 'selectAction',
					isDisabled: true
				},
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_RECEIVED"
							defaultMessage="Received"
						/>
					),
					value: manualRuleStates.RECEIVED
				}
			];
		case manualRuleStates.RECEIVED:
			return [
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_SELECT_ACTION"
							defaultMessage="Select Action:"
						/>
					),
					value: 'selectAction',
					isDisabled: true
				},
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_IMPLEMENTED"
							defaultMessage="Implemented"
						/>
					),
					value: manualRuleStates.IMPLEMENTED
				},
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_CONFLICTED"
							defaultMessage="Conflicted"
						/>
					),
					value: manualRuleStates.CONFLICTED
				}
			];
		case manualRuleStates.CONFLICTED:
			return [
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_SELECT_ACTION"
							defaultMessage="Select Action:"
						/>
					),
					value: 'selectAction',
					isDisabled: true
				},
				{
					label: (
						<FormattedMessage
							id="AUTOMATION.RULE_STATUS_IMPLEMENTED"
							defaultMessage="Implemented"
						/>
					),
					value: manualRuleStates.IMPLEMENTED
				}
			];
		default:
			return [];
	}
};
