import AutomationZonesView from '../views/AutomationZonesView';
import {
	userInSupermode,
	userIsAuthenticated
} from '../../../utils/AuthSelector';

const automationZonesRoutes = [
	{
		path: '/automation-zones',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated([
				'system.mobilityzones_rw',
				'system.mobilityzones_ro'
			])(AutomationZonesView)
		)
	}
];

export default automationZonesRoutes;
