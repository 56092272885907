export default {
	'BATCH_HISTORY.LINK': 'Histórico de lotes',
	'BATCH_HISTORY.TITLE': 'Histórico de lotes',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Ações de lotes',
	'BATCH_HISTORY.BATCH_REPORTS': 'Relatórios de lotes’',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'Nenhuma ação de lotes disponível',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': 'Não há ações de lotes para exibir nesta tabela no momento',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'Nenhum relatório de lotes disponível',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': 'Não há relatórios de lotes para exibir nesta tabela no momento',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Nome do arquivo de lotes',
	'BATCH_HISTORY.DATE_UPLOADED': 'Data de carregamento',
	'BATCH_HISTORY.STATUS': 'Status',
	'BATCH_HISTORY.REPORT_NAME': 'Relatório',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'BATCH_HISTORY.DATE': 'Data',
	'BATCH_HISTORY.M2M_PLATFORM': 'Plataforma M2M',
	'BATCH_HISTORY.FREQUENCY': 'Frequência',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Enviado',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Frequência',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'Em andamento',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Concluído',
  'BATCH_HISTORY.ERROR_STATUS': 'Erro',
  'BATCH_HISTORY.CANCELLED_STATUS': 'Cancelado',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': 'O arquivo foi carregado com êxito para processamento',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': 'O arquivo foi carregado com êxito; agendado para processamento',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Processamento em andamento',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'Arquivo processado com êxito',
  'BATCH_HISTORY.ERROR_TOOLTIP': 'Ocorreu um erro durante o processamento',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': 'Tooltip cancelado'
};
