import { connect } from 'react-redux';
import { isDirty, formValueSelector } from 'redux-form';
import NotesModal from './NotesModal';

import {
	fileUploadRequest,
	fileUploadSuccess
} from '../../../Shared/redux/uploadFile/selectors';

import {
	anyModalClosed,
	anyModalOpened
} from '../../../../redux/modals/globalModal/actions';

import {
	updateInvoice,
	getInvoiceAttachments
} from '../../redux/invoices/actions';

import {
	getInvoiceAttachmentsRequest,
	getInvoiceAttachmentsFail,
	getInvoiceAttachmentsSuccess
} from '../../redux/invoices/selector';

import { resetFiles } from '../../../Shared/redux/uploadFile/actions';

import { hideNotification } from '../../../Shared/redux/notifications/actions';

import { invoiceAttachmentDownload } from '../../redux/invoiceAttachment/actions';
import { downloadInvoiceAttachmentRequest } from '../../redux/invoiceAttachment/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';

const selector = formValueSelector('analyticsInvoicesNotes');

const mapStateToProps = (state) => ({
	filePending: fileUploadRequest(state),
	fileSuccess: fileUploadSuccess(state),
	getAttachmentsRequest: getInvoiceAttachmentsRequest(state),
	getAttachmentsFail: getInvoiceAttachmentsFail(state),
	attachments: getInvoiceAttachmentsSuccess(state),
	downloadInvoiceAttachmentRequest: downloadInvoiceAttachmentRequest(state),
	isDirty: isDirty('analyticsInvoicesNotes')(state),
	noteForm: selector(state, 'note'),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	modalOpened: () => dispatch(anyModalOpened()),
	closeModal: () => dispatch(anyModalClosed()),
	updateInvoice: (id, data, notifOptions) =>
		dispatch(updateInvoice(id, data, notifOptions)),
	getInvoiceAttachments: (id) => dispatch(getInvoiceAttachments(id)),
	resetFiles: () => dispatch(resetFiles()),
	removeError: () => {
		dispatch(hideNotification('upload-file-error'));
	},
	invoiceAttachmentDownload: (data) => dispatch(invoiceAttachmentDownload(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
