export default {
	'REPORTS.REPORT': 'Informe',
	'REPORTS.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
	'REPORTS.M2M_PLATFORM': 'Plataforma M2M',
	'REPORTS.FREQUENCY': 'Frecuencia',
	'REPORTS.REPORT_DATE': 'Fecha del informe',
	'REPORTS_TABS.ENDPOINTS': 'Terminales',
	'REPORTS_TABS.USAGE_SUMMARY': 'Resumen de uso',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Transferencia de actividades',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Resumen de la cuenta',
	'REPORTS_TABS.BILLING_DETAILS': 'Detalles de facturación',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Informes de referencia',
	'REPORTS_TABS.MY_EXPORTS': 'Mis exportaciones',
	'REPORTS_TABS.USAGE_REPORTS': 'Informes de uso',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Informes vueltos a publicar',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'No hay informes disponibles',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': 'No hay informes de esta tabla para mostrarle ahora',
	'REPORTS.FILE_NAME': 'Nombre del archivo',
	'REPORTS.FILE_DATE': 'Fecha de archivo',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Solicitado por'
};
