export default {
	// filters
	CURRENCY: 'Valuta',
	BILLABLE: 'Fatturabile',
	TAX_STATUS: 'Stato fiscale',
	SUBSCRIBER_STATUS: 'Stato abbonato',
	MONTHLY_RATE_PLAN: 'Piano tariffario mensile',
	STANDARD_RATE_PLAN: 'Piano tariffario standard',
	PREPAID_TERMS_CHARGED: 'Termini prepagati addebitati',
	PLAN_TYPE: 'Tipo di piano',
	ZONE: 'Zona',
	ROAMING_ZONE: 'Zona roaming',
	GROUP_NAME: 'Nome gruppo',
	THE_PLAN_TYPE: 'Tipo di piano',
	ACTIVATION_TYPE: 'Tipo attivazione',
	CHARGE_TYPE: 'Tipo addebito',
	TECH_TYPE: 'Tipo tecnologia',
	PRIMARY_PLACE: 'Località primaria',
	DISTRICT_COUNTY: 'Contea distretto',
	DISTRICT_CITY: 'Città distretto',
	PRIMARY_PLACE_OF_USE: 'Località di utilizzo primaria',
	// notifications
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': 'Aggiornamento impostazioni utente riuscito',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'Impossibile aggiornare impostazioni utente',
	// invoices
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'ID account',
	'ANALYTICS_INVOICES.ADD_NOTE': 'Aggiungi nota',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Aggiungi allegato',
	'ANALYTICS_INVOICES.COUNTRY_NAME': 'Paese',
	'ANALYTICS_INVOICES.CUSTOMER_ID': 'ID cliente',
	'ANALYTICS_INVOICES.INVOICE_DATE': 'Data fattura',
	'ANALYTICS_INVOICES.DOCUMENT_ID': 'ID documento',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Sottoconto',
	'ANALYTICS_INVOICES.NOTES': 'Note',
	'ANALYTICS_INVOICES.LINK': 'Fatture',
	'ANALYTICS_INVOICES.BY': 'Per ',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Selezionare le statistiche da mostrare qui',
	'ANALYTICS_INVOICES.NA': 'N/A',
	'ANALYTICS_INVOICES.YES': 'Sì',
	'ANALYTICS_INVOICES.NO': 'No',
	'ANALYTICS_INVOICES.INVOICE_ID': 'ID fattura',
	'ANALYTICS_INVOICES.BILLING_CYCLE': 'Ciclo di fatturazione',
	'ANALYTICS_INVOICES.ACCOUNT_ID': 'ID account',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Nome account',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'ID account operatore',
	'ANALYTICS_INVOICES.DEVICES': 'Dispositivi',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Addebito totale',
	'ANALYTICS_INVOICES.CURRENCY': 'Valuta',
	'ANALYTICS_INVOICES.DATA_VOLUME': 'Volume dati (MB)',
	'ANALYTICS_INVOICES.SMS_VOLUME': 'Volume SMS',
	'ANALYTICS_INVOICES.BILLABLE': 'Fatturabile (Sì o No)',
	'ANALYTICS_INVOICES.BILL': 'Fatturabile',
	'ANALYTICS_INVOICES.DATA_RATES': 'Tariffe dati',
	'ANALYTICS_INVOICES.DATE': 'Data fattura',
	'ANALYTICS_INVOICES.BILLING_DETAILS': 'Dettagli fatturazione',
	'ANALYTICS_INVOICES.DUE_DATE': 'Data scadenza',
	'ANALYTICS_INVOICES.OPERATOR_ID': 'ID operatore',
	'ANALYTICS_INVOICES.PUBLISHED': 'Pubblicato',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Eccedenza standard',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Roaming standard',
	'ANALYTICS_INVOICES.TAXABLE': 'Account soggetto a imposte',
	'ANALYTICS_INVOICES.TAX_STATUS': 'Stato fiscale',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Segmento account',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'Visualizza/Stampa/Salva',
	'ANALYTICS_INVOICES.INVOICE_TITLE': 'Dettagli fattura',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Scarica fattura',
	'ANALYTICS_INVOICES.ENDPOINTS': 'Endpoint',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Abbonamenti prepagati',
	'ANALYTICS_INVOICES.ADDONS': 'Componenti aggiuntivi',
	'ANALYTICS_INVOICES.RATE_PLANS': 'Piani tariffari',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Zone piano tariffario',
	'ANALYTICS_INVOICES.BILLING_GROUPS': 'Gruppi fatturazione',
	'ANALYTICS_INVOICES.DOWNLOAD': 'Download',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'Scarica PDF',
	'ANALYTICS_INVOICES.UPLOAD': 'Caricamento',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Carica fattura',
	'ANALYTICS_INVOICES.ACTIVATIONS': 'Attivazioni',
	'ANALYTICS_INVOICES.DISCOUNTS': 'Sconti',
	'ANALYTICS_INVOICES.TAX': 'Imposte',
	'ANALYTICS_INVOICES.SURCHARGES': 'Supplementi',
	'ANALYTICS_INVOICES.TECH_TYPES': 'Tipi tecnologia',
	'ANALYTICS_INVOICES.OTHER_CHARGES': 'Altri addebiti',
	'ANALYTICS_INVOICES.NO_DATA': 'Nessuna fattura disponibile',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': 'Non ci sono attualmente fatture da mostrare',
	'ANALYTICS_INVOICES.TITLE': 'Fatture',
	'ANALYTICS_INVOICES.DATA_DETAILS': 'Dettagli dati',
	'ANALYTICS_INVOICES.SMS_DETAILS': 'Dettagli SMS',
	'ANALYTICS_INVOICES.TAXES': 'Imposte',
	// endpoints table
	'ANALYTICS_INVOICES.ICCID': 'ICCID',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Indice ripartizione mensile',
	'ANALYTICS_INVOICES.DEVICE_ID': 'ID dispositivo',
	'ANALYTICS_INVOICES.CUSTOMER': 'Cliente',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Piano tariffario mensile',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Termini prepagati addebitati',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Piano tariffario standard',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'Stato abbonato',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Addebito abbonamento',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Addebito pool fisso',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'Volume SMS MO (msg)',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'Volume SMS MT (msg)',
	// prepaid subscription table
	'ANALYTICS_INVOICES.MSISDN': 'MSISDN',
	'ANALYTICS_INVOICES.RATE_PLAN': 'Piano tariffario',
	'ANALYTICS_INVOICES.PLAN_VERSION': 'Versione piano',
	'ANALYTICS_INVOICES.PLAN_TYPE': 'Tipo di piano',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Indice ripartizione utilizzo',
	'ANALYTICS_INVOICES.TERM_START_DATE': 'Data iniziale periodo',
	'ANALYTICS_INVOICES.TERM_END_DATE': 'Data finale periodo',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'Utilizzo dati incluso in MB',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'SMS MO inclusi (msg)',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'SMS MT inclusi (msg)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Vocale incluso (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'Vocale MO incluso (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'Vocale MT incluso (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD': 'CSD inclusi (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'CSD MO inclusi (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'CSD MT inclusi (m:ss)',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': 'Utilizzo dati dal periodo fino alla data (zona primaria)',
	'ANALYTICS_INVOICES.VOICE_VOLUME': 'Volume vocale (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Volume vocale MO (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Volume vocale MT (m:ss)',
	// Addons Table
	'ANALYTICS_INVOICES.ADDON_SIZE': 'Dimensioni componenti aggiuntivi (MB)',
	'ANALYTICS_INVOICES.ZONE': 'Zona',
	'ANALYTICS_INVOICES.ADDON_CHARGES': 'Addebiti componenti aggiuntivi',
	'ANALYTICS_INVOICES.DATE_PURCHASED': 'Data acquisto',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Piano base applicato a',
	// Rate Plan Table
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': 'Abb. impegno periodo attivazione',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Abbonamenti attivi',
	'ANALYTICS_INVOICES.ACTIVE_TIER': 'Livello attivo',
	'ANALYTICS_INVOICES.DATA_CHARGE': 'Addebito dati',
	'ANALYTICS_INVOICES.INCLUDED_DATA': 'Dati inclusi',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Modalità dati inclusi',
	'ANALYTICS_INVOICES.INCLUDED_SMS': 'SMS inclusi',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Modalità SMS MO inclusi',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Modalità SMS inclusi',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Modalità SMS MT inclusi',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Modalità vocale MO inclusa',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Modalità vocale inclusa',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': 'Abb. periodo attivazione minimo',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Addebito dati non roaming',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Volume dati non roaming',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Tipo di pagamento',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Attivazioni prepagate',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Addebito dati roaming',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Volume dati roaming',
	'ANALYTICS_INVOICES.SMS_CHARGE': 'Addebito SMS',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Campo abbonamento',
	'ANALYTICS_INVOICES.VOICE_CHARGE': 'Addebito vocale',
	// Rate Plan Zone table
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Tariffa eccedenza attiva',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Limite dati inclusi',
	'ANALYTICS_INVOICES.INCLUDED_MODE': 'Modalità inclusa',
	'ANALYTICS_INVOICES.PRIORITY': 'Priorità',
	'ANALYTICS_INVOICES.ROAMING_ZONE': 'Zona roaming',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Utilizzo incluso totale (MB)',
	//  Billing groups table
	'ANALYTICS_INVOICES.GROUP_NAME': 'Nome gruppo',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Totale incluso',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'Addebito SMS MO',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'SMS MO inclusi (msg)',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': 'Utilizzo SMS MO incluso totale',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': 'Vocale MO incluso totale',
	// Data details table
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Rettifica arrotondamento giornaliero',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'Tipo di piano',
	// sms details table
	'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Tipo messaggio',
	// activations table
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Addebito di attivazione',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Tipo attivazione',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Data di attivazione iniziale',
	// discount table
	'ANALYTICS_INVOICES.CHARGE_TYPE': 'Tipo addebito',
	'ANALYTICS_INVOICES.DISCOUNT': 'Sconto',
	// taxes table
	'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Località primaria',
	'ANALYTICS_INVOICES.MRC': 'MRC',
	'ANALYTICS_INVOICES.DATA': 'Dati',
	'ANALYTICS_INVOICES.SMS': 'SMS',
	'ANALYTICS_INVOICES.VOICE': 'Vocale',
	'ANALYTICS_INVOICES.ACTIVATION': 'Attivazione',
	'ANALYTICS_INVOICES.VPN': 'VPN',
	'ANALYTICS_INVOICES.SIM': 'SIM',
	'ANALYTICS_INVOICES.MRF': 'MRF',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': 'Tipo tecnologia',
	// surcharges table
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Località di utilizzo primaria',
	'ANALYTICS_INVOICES.FEDERAL': 'Federale',
	'ANALYTICS_INVOICES.STATE': 'Statale',
	'ANALYTICS_INVOICES.COUNTY': 'Contea',
	'ANALYTICS_INVOICES.CITY': 'Città',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'Contea distretto',
	'ANALYTICS_INVOICES.DISTRICT_CITY': 'Città distretto',
	// tech type table
	'ANALYTICS_INVOICES.PLAN_ID': 'ID piano',
	'ANALYTICS_INVOICES.VERSION_ID': 'ID versione',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'N. di dispositivi 2G',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': 'Addebito tipo tecnologia 2G',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'N. di dispositivi 3G',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': 'Addebito tipo tecnologia 3G',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'N. di dispositivi 4G',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': 'Addebito tipo tecnologia 4G',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Frequenza addebito tipo tecnologia',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Addebito tipo tecnologia totale',

	// Subscription charges:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Addebiti abbonamento',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Addebito abbonamento',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Addebito dati',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'Addebiti SMS',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Addebito di attivazione',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Addebito tipo tecnologia',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Obiettivo sconto fisso',
	'ANALYTICS_INVOICES_DETAILS.TAX': 'Imposte',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Addebito totale',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Supplemento',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Data scadenza ',

	// Endpoint stats:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Stat endpoint',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'ENDPOINT',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'ABBONATI ATTIVI',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'VOLUME DATI',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'VOLUME SMS',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'RICAVI SERVIZIO',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'SCONTO VARIABILE',

	// other charges table
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Importo addebito',
	'ANALYTICS_INVOICES.DESCRIPTION': 'Descrizione',
	'ANALYTICS_INVOICES.QUANTITY': 'Quantità',
	'ANALYTICS_INVOICES.ORDER_NUMBER': 'N. ordine',
	'ANALYTICS_INVOICES.REFERENCE_ID': 'ID riferimento',
	//  placeholderS
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Inserire qui la nota',
	'ANALYTICS_INVOICES.REMOVE': 'Rimuovi',
	'ANALYTICS_INVOICES.EDIT_NOTE': 'Modifica nota',
	'ANALYTICS_INVOICES.CANCEL': 'Annulla',
	'ANALYTICS_INVOICES.SUBMIT': 'Invia',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Allegati aggiunti',
	//  edit table modal
	'ANALYTICS_INVOICES.APPLY_CHANGE': 'Applica cambiamento',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Ripristina impostazioni predefinite',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Modifica parametri tabella',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': 'Selezionarne almeno 2 da applicare alla tabella. ',
	'ANALYTICS_INVOICES.OF': ' di ',
	'ANALYTICS_INVOICES.SELECTED': ' Selezionati',
	// invoice details
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'Dati non disponibili',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': 'Non ci sono dati per questa fattura',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': 'Database dettagli fattura vuoto per questa fattura',
	'ANALYTICS_INVOICES.ADD_FILES': 'File fattura',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'Visualizza/Aggiungi/Modifica',
	//  ATTACHMENTS MODAL
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Allegati per fattura',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Modifica allegato',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Carica un file',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Elimina allegato',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Chiudi',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Annulla',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Salva',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Elimina',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Carica file',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Carica allegato',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Carica fattura',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'Nome file',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Descrizione',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': 'Si sta per rimuovere un allegato da MC. Questo rimuoverà permanentemente questo file dal sistema, si è sicuri di voler eseguire l’operazione?',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'File da eliminare:',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'File allegato',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': 'Nome file (se vuoto, verrà utilizzato il nome file predefinito)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': 'Descrizione file visibile per il cliente (opzionale)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': 'Questa fattura sarà attiva tra qualche settimana.',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': 'Trascinare il file o cliccare per caricare',
	'INVOICES_NOTES_UPDATE.SUCCESS': 'Aggiornamento nota fattura riuscito.',
	//  NOTES MODAL
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'Aggiornamento riuscito',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': 'Aggiunta nota riuscita.',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Aggiornamento non riuscito',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': 'Aggiunta nota riuscita.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'Caricamento riuscito',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Caricamento file riuscito.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Errore in fase di caricamento',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Si è verificato un errore durante il caricamento di questo file su MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': 'Allegato eliminato',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': 'Il file è stato rimosso da MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': 'Errore in fase di eliminazione',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': 'Si è verificato un errore durante l’eliminazione di questo file da MC.',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Data fattura',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': 'Ciclo di fatturazione non valido, pertanto i dettagli della fattura non possono essere visualizzati.',
	// ADD NEW INVOICE
	'INVOICES.ADD_NEW_INVOICE': 'Aggiungi nuova fattura',
	'INVOICES.CANCEL': 'Annulla',
	'INVOICES.ADD_INVOICE': 'Aggiungi fattura',
	'INVOICES.EDIT': 'Modifica',
	'INVOICES.CONFIRM': 'Conferma',
	'INVOICES.CONFIRMATION': 'Conferma',
	'INVOICES.INVOICE_DETAILS': 'Dettagli fattura',
	'ADD_INVOICE.INVOICE_ID': 'ID fattura',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': 'ID account M2M',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'Seleziona ID account M2M',
	'ADD_INVOICE.INVOICE_DATE': 'Data fattura',
	'ADD_INVOICE.BILLING_CYCLE': 'Ciclo di fatturazione',
	'ADD_INVOICE.DATE_PLACEHOLDER': 'Seleziona data',
	'ADD_INVOICE.UPLOAD_FILE': 'Carica un file',
	'ADD_INVOICE.UPLOAD_FILE_NAME': 'Nome file (opzionale: se vuoto, verrà utilizzato il nome file predefinito)',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': 'Nome file (massimo 255 caratteri)',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': 'Nome file',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Aggiunta nuova fattura riuscita.',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Si è verificato un errore durante l’aggiunta della fattura. Riprovare.',
	'ADD_INVOICE.TIME_STAMP': 'Allegato il {date} alle {time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION': 'Nome file non valido (utilizzare _ invece dello spazio; inoltre i seguenti caratteri non sono permessi: ! ? <> % $ () + =',
};
