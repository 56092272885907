import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../RatePlans';

import styles from './Desktop.scss';

const Desktop = (props) => {
	const { ratePlans, getOptions, messages } = props;
	return (
		<div data-spec="rate-plans-desktop" className={styles.rate_plans_wrapper}>
			<Table
				data={ratePlans.rateplans}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { object, func } = PropTypes;

Desktop.propTypes = {
	ratePlans: object,
	getOptions: func,
	messages: object
};

export default hoc()(Desktop);
