import React, { PureComponent } from 'react';
import { object, string, func, bool, shape } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';

import AccountInfoOverview from '../AccountInfoOverview';
import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import InputField from '../../../../lib/DigitalComponents/FieldGroup';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox/Checkbox';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import {
	companyBillingCategories,
	billingPlatform
} from '../../utils/constants';
import { localizationHelper } from '../../../../utils/helperFunctions';

import styles from './Billings.scss';

import {
	isUserAllowedToAccess,
	UserAllowedToAccess
} from '../../../../utils/AuthSelector';

const EditIcon = ImageSelector(CurrentContext.theme, 'svgs/pen.svg');
const CheckIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');
const CancelIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');

const EditButtonComponentRW = UserAllowedToAccess(['system.companies_rw']);
const EditReportsComponentRW = UserAllowedToAccess([
	'system.companies_rw',
	'mnc.reports.configuration_rw'
]);

class Billings extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isInfoSectionStatic: true,
			isReportsSectionStatic: true,
			selectAllIotc: false,
		};
	}

	componentDidMount() {
		this.getAccount();
	}

	componentDidUpdate(prevProps) {
		const {
			companyReportsEditRequest,
			account,
			editAccountSuccess,
			editAccountFail,
			resetForm,
			companyReports,
			match: {
				params: { companyId }
			}
		} = this.props;

		if (
			(prevProps.companyReportsEditRequest && !companyReportsEditRequest) ||
			(account && prevProps.account !== account)
		) {
			if (account) {
				this.getCompanyReports({
					companyId,
					accountId: account.company.externalCompanyId,
					m2mAccountId: account.m2mAccountId,
					platformType:
						(account.platformType &&
							account.platformType.platformTypeCategory &&
							account.platformType.platformTypeCategory.code) ||
						account.platformType.code
				});
			}
		}
		if (!prevProps.companyReports && companyReports && companyReports.resultList) {
			const tempIotcSelectAll = companyReports.resultList
					.filter((report) => report.iot_c === true).length ===
				companyReports.resultList.length;

			this.setSelectAllField(tempIotcSelectAll);
		}
		if (
			(prevProps.editAccountSuccess !== editAccountSuccess &&
				editAccountSuccess) ||
			(prevProps.editAccountFail !== editAccountFail && editAccountFail)
		) {
			this.getAccount();
		}
		if (prevProps.editAccountFail !== editAccountFail && editAccountFail) {
			resetForm();
		}
	}

	componentWillUnmount() {
		const { getAccountInit } = this.props;
		getAccountInit();
	}

	setSelectAllField = (iotc) => {
		this.setState({
			selectAllIotc: iotc
		});
	}

	getCompanyReports = () => {
		const {
			getCompanyReports,
			account,
			match: {
				params: { companyId }
			}
		} = this.props;
		if (account) {
			getCompanyReports({
				companyId,
				accountId: account.company.externalCompanyId,
				m2mAccountId: account.m2mAccountId,
				platformType:
					(account.platformType &&
						account.platformType.platformTypeCategory &&
						account.platformType.platformTypeCategory.code) ||
					account.platformType.code,
				searchParams: [
					{
						operator: '=',
						prop: 'report_frequency',
						propValue: 'Billing'
					}
				],
				additionalParams: {
					dataSort: 'report_friendly_name asc'
				}
			});
		}
	};

	getAccount = () => {
		const {
			getAccount,
			match: {
				params: { accountId }
			}
		} = this.props;

		getAccount({
			id: accountId,
			additionalParams: {
				include: [
					'PlatformType',
					'PlatformMobileOperator',
					'PlatformSatelliteOperator',
					'Company',
					'PlatformType.PlatformTypeCategory'
				]
			}
		});
	};

	submit = (
		values,
		dispatch,
		{
			initialValues,
			editCompanyReports,
			account,
			hideNotification,
			match: {
				params: { companyId }
			}
		}
	) => {
		hideNotification('edit-account-fail');

		const temp = {};

		Object.keys(values)
			.filter(
				(key) => !key.includes('field') && values[key] !== initialValues[key]
			)
			.forEach((key) => {
				const splitKey = key.split('_');

				if (temp[splitKey[0]]) {
					temp[splitKey[0]][splitKey[1]] = values[key];
				} else {
					temp[splitKey[0]] = { [splitKey[1]]: values[key] };
				}
			});

		const reportConfiguration = Object.keys(temp).map((key) => {
			const obj = {
				available_report_id: parseInt(key, 10)
			};

			if (temp[key].iotc || temp[key].iotc === false) {
				obj.iot_c = temp[key].iotc;
			}
			if (temp[key].sfg || temp[key].sfg === false) {
				obj.sfg = temp[key].sfg;
			}
			if (temp[key].sfgMailbox) {
				obj.sfg = true;
				obj.sfg_mailbox = temp[key].sfgMailbox;
			}

			return obj;
		});

		const validReportConfigData = reportConfiguration.filter(
			(item) => item.available_report_id
		);

		if (validReportConfigData.length > 0) {
			editCompanyReports({
				companyId,
				accountId: account.company.externalCompanyId,
				m2mAccountId: account.m2mAccountId,
				platformType:
					(account.platformType &&
						account.platformType.platformTypeCategory &&
						account.platformType.platformTypeCategory.code) ||
					account.platformType.code,
				data: { reportConfiguration: validReportConfigData }
			});
		}
	};

	updateBillingInfo = () => {
    const {
			formValues,
			editAccount,
			match: {
				params: { accountId }
			}
		} = this.props;

    editAccount({
			id: accountId,
			data: {
				m2mBillingAccountId: formValues.account.m2mBillingAccountId || '',
				m2mBillingPlatform:
					(formValues.account.m2mBillingPlatform &&
						formValues.account.m2mBillingPlatform.value) ||
					formValues.account.m2mBillingPlatform ||
					''
			}
		});
  }

	setSectionsToStatic = () => {
		this.setState({
			isInfoSectionStatic: true,
			isReportsSectionStatic: true
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId, accountId }
			}
		} = this.props;

		if (companyId) {
			pushBack(`/companies/${companyId}/accounts/${accountId}`);
		} else {
			pushBack(`/my-company/${accountId}`);
		}
	};

	render() {
		const {
			user,
			account,
			accountFail,
			accountRequest,
			editAccountRequest,
			intl,
			handleSubmit,
			companyReports,
			dirty,
			disableEditing,
			companyReportsGetRequest,
			companyReportsEditRequest,
			change,
			invalid,
			resetForm,
			dispatch,
			submit
		} = this.props;
		const { selectAllIotc, isInfoSectionStatic, isReportsSectionStatic } = this.state;
		const reportColumns = [];

		if (
			companyReports &&
			companyReports.resultList &&
			companyReports.resultList.length > 0
		) {
			companyBillingCategories.forEach((categorie) => {
				reportColumns.push({
					categorie: categorie.toUpperCase(),
					availableReports:
						companyReports &&
						companyReports.resultList &&
						companyReports.resultList.length > 0 &&
						companyReports.resultList.filter(
							(item) => item.report_frequency.toLowerCase() === categorie
						)
				});
			});
		}

		return (
			<Form onSubmit={handleSubmit(this.submit)} data-spec="billing">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.BILLING"
							defaultMessage="Billing"
						/>
					}
					pushBack={this.pushBack}
				/>
				{!companyReports ||
				companyReportsGetRequest ||
				companyReportsEditRequest ||
				!account ||
				accountRequest ||
				editAccountRequest ? (
					<Loading data-spec="loader" />
				) : (
					<>
						<div className={styles.reports_wrapper}>
							{accountFail && !accountRequest && null}
							{account && !accountRequest && (
								<>
									<AccountInfoOverview
										account={account}
										title={
											<FormattedMessage
												id="ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION"
												defaultMessage="View or Edit Billing Configuration"
												values={{
													companyName: account.company.companyFriendlyName
												}}
											/>
										}
									/>
									<h5 className={styles.section_headline}>
										<FormattedMessage
											id="ONBOARDING.BILLING_INFORMATION"
											defaultMessage="Billing Information"
										/>
									</h5>
									{isInfoSectionStatic ? (
										<div className={styles.section_wrapper}>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.BILLING_PLATFORM',
													defaultMessage: 'Billing Platform'
												})}
												data={account.m2mBillingPlatform}
											/>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.INVOICE_ACCOUNT_NUMBER',
													defaultMessage: 'Invoice Account Number'
												})}
												data={account.m2mBillingAccountId}
											/>
											<div className={styles.button_wrapper}>
												<EditButtonComponentRW>
													<EditIcon
														onClick={() => {
															resetForm();
															this.setState({
																isInfoSectionStatic: false,
																isReportsSectionStatic: true
															});
														}}
													/>
												</EditButtonComponentRW>
											</div>
										</div>
									) : (
										<div className={styles.section_wrapper}>
											<FieldWrapper>
												<Field
													component={Select}
													name="account.m2mBillingPlatform"
													label={intl.formatMessage({
														id: 'ONBOARDING.BILLING_PLATFORM',
														defaultMessage: 'Billing Platform'
													})}
													options={
														billingPlatform &&
														billingPlatform.map((item) => ({
															value: item.name,
															label: (
																<FormattedMessage
																	id={`ONBOARDING.${localizationHelper(
																		item.name
																	)}`}
																	defaultMessage={item.name}
																/>
															)
														}))
													}
													isDisabled={
														!isUserAllowedToAccess(
															['system.companies_rw'],
															user
														)
													}
													cleanValue
												/>
											</FieldWrapper>
											<FieldWrapper>
												<Field
													name="account.m2mBillingAccountId"
													component={InputField}
													label={intl.formatMessage({
														id: 'ONBOARDING.INVOICE_ACCOUNT_NUMBER',
														defaultMessage: 'Invoice Account Number'
													})}
													placeholder={intl.formatMessage({
														id: 'ONBOARDING.INVOICE_ACCOUNT_NUMBER',
														defaultMessage: 'Invoice Account Number'
													})}
													disabled={
														!isUserAllowedToAccess(
															['system.companies_rw'],
															user
														)
													}
												/>
											</FieldWrapper>
											<div className={styles.button_wrapper}>
												<CancelIcon
													onClick={() => {
														resetForm();
														this.setSectionsToStatic();
													}}
												/>
												{!dirty || invalid ? null : (
													<CheckIcon
														onClick={() => {
															this.updateBillingInfo();
															this.setSectionsToStatic();
														}}
													/>
												)}
											</div>
										</div>
									)}
									{reportColumns.filter(
										(report) => report && report.availableReports.length > 0
									).length > 0 ? (
										<>
											<h5 className={styles.section_headline}>
												<FormattedMessage
													id="ONBOARDING.BILLING_REPORTS"
													defaultMessage="Billing Reports"
												/>
											</h5>
											<div className={styles.subtitle}>
												<FormattedMessage
													id="ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS"
													defaultMessage="This box must be checked to view invoice details."
												/>
											</div>
											<div className={styles.section_wrapper}>
												{isReportsSectionStatic ? (
													<div className={styles.button_wrapper}>
														<EditReportsComponentRW>
															<EditIcon
																onClick={() => {
																	resetForm();
																	this.setState({
																		isInfoSectionStatic: true,
																		isReportsSectionStatic: false
																	});
																}}
															/>
														</EditReportsComponentRW>
													</div>
												) : (
													<div className={styles.button_wrapper}>
														<CancelIcon
															onClick={() => {
																resetForm();
																this.setSectionsToStatic();
															}}
														/>
														{!dirty || invalid ? null : (
															<CheckIcon
																onClick={() => {
																	dispatch(submit());
																	this.setSectionsToStatic();
																}}
															/>
														)}
													</div>
												)}
											</div>
										{reportColumns
											.filter(
												(report) =>
													report && report.availableReports.length > 0
											)
											.map((reportCategorie) => (
												<div
													key={reportCategorie.categorie}
													className={styles.reports_column}
												>
													<div className={styles.first_row}>
														<span>
															<FormattedMessage
																id="ONBOARDING.SELECT_DESELECT_ALL"
																defaultMessage="Select/Deselect All"
															/>
														</span>
														<div className={styles.sfg_iotc}>
															<span>
																<FormattedMessage
																	id="ONBOARDING.IOT_C"
																	defaultMessage="IoT-C"
																/>
															</span>
															<Checkbox
																checked={selectAllIotc}
																data-spec="select-all-iotc-checkbox"
																disabled={
																	isReportsSectionStatic ||
																	disableEditing ||
																	!isUserAllowedToAccess(
																		[
																			'system.companies_rw',
																			'mnc.reports.configuration_rw'
																		],
																		user
																	)
																}
																onChange={() => {
																	reportCategorie.availableReports
																		.forEach((report) => (
																			change(
																				`${report.id}_iotc`,
																				!selectAllIotc,
																			)
																		));
																	this.setState({
																		selectAllIotc: !selectAllIotc
																	});
																}}
															/>
														</div>
													</div>
													{reportCategorie.availableReports.map((report) => (
														<div key={report.id} className={styles.row}>
															<span>
																<FormattedMessage
																	id={`ONBOARDING.${localizationHelper(
																		report.report_friendly_name
																	).toUpperCase()}`}
																	defaultMessage={report.report_friendly_name}
																/>
															</span>
															<Field
																name={`${report.id}_iotc`}
																type="checkbox"
																value={report.iot_c}
																component={Checkbox}
																disabled={
																	isReportsSectionStatic ||
																	disableEditing ||
																	!isUserAllowedToAccess(
																		[
																			'system.companies_rw',
																			'mnc.reports.configuration_rw'
																		],
																		user
																	)
																}
																onChange={(event, newValue) => (
																	!newValue && this.setState({
																		selectAllIotc: newValue
																	})
																)}
															/>
														</div>
													))}
												</div>
											))}
										</>
									) : (
										<div className={styles.block}>
											<FormattedMessage
												id="ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM"
												defaultMessage="Billing details are not supported for this platform."
											/>
										</div>
									)}
								</>
							)}
						</div>
					</>
				)}
			</Form>
		);
	}
}

const BillingForm = reduxForm({
	form: 'BillingForm',
	enableReinitialize: true
})(injectIntl(Billings));

Billings.propTypes = {
	handleSubmit: func,
	getCompanyReports: func,
	companyId: string,
	companyReportsGetRequest: bool,
	companyReportsEditRequest: bool,
	companyReports: object,
	change: func,
	dirty: bool,
	disableEditing: bool,
	match: object,
	pushBack: func,
	getAccount: func,
  getAccountInit: func,
  editAccount: func,
	editAccountSuccess: bool,
	editAccountRequest: bool,
	editAccountFail: bool,
	account: object,
	accountRequest: bool,
	accountFail: bool,
	user: shape(),
	intl: object,
  invalid: bool,
  resetForm: func,
  formValues: object,
  dispatch: func,
  submit: func
};

Billings.defaultProps = {
	disableEditing: false
};

export default BillingForm;
