import React, { Component } from 'react';
import { func, bool, object, string } from 'prop-types';

import NoData from '../../../Shared/views/NoDataView';
import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './BlacklistedPermissions.scss';
import RequestFeatureModal from '../RequestFeatureModal';
import RequestedFeatureModal from '../RequestedFeatureModal';
import CancelRequestedFeatureModal from '../CancelRequestedFeatureModal';

export default function BlacklistedPermissions(WrappedComponent) {
	class BlacklistedPermissionsComponent extends Component {
		componentDidMount() {
			const { companyId } = this.props;
			if (companyId) this.getBlacklistedPermissions(companyId);
			else {
				this.getBlacklistedPermissions();
			}
		}

		componentDidUpdate(prevProps) {
			const { editBlacklistedPermissionFail, companyId } = this.props;
			if (editBlacklistedPermissionFail !== prevProps.editBlacklistedPermissionFail) {
				if (companyId) this.getBlacklistedPermissions(companyId);
				else {
					this.getBlacklistedPermissions();
				}
			}
		}

		getBlacklistedPermissions = (id) => {
			const { getBlacklistedPermissions } = this.props;
			getBlacklistedPermissions({ id });
		};

		render() {
			const {
				blacklistedPermissionsRequest,
				blacklistedPermissionsFail,
				blacklistedPermissions,
				isRequestFeatureModalOpened,
				closeRequestFeatureModal,
				isRequestedFeatureModalOpened,
				closeRequestedFeatureModal,
				isCancelRequestedFeatureModalOpened,
				closeCancelRequestedFeatureModal,
			} = this.props;
			return (
				<div
					data-spec="blacklisted-permissions"
					className={styles.blacklisted_permissions_wrapper}
				>
					{blacklistedPermissionsRequest && <Loader />}
					{blacklistedPermissionsFail && null}
					{!blacklistedPermissionsRequest &&
						blacklistedPermissions &&
						blacklistedPermissions.resultList &&
						blacklistedPermissions.resultList.length > 0 && (
							<WrappedComponent {...this.state} {...this.props} />
						)}
					{blacklistedPermissions &&
						blacklistedPermissions.resultList &&
						blacklistedPermissions.resultList.length === 0 && <NoData table />}
					{isRequestFeatureModalOpened && (
						<RequestFeatureModal show onCancel={closeRequestFeatureModal} />
					)}
					{isCancelRequestedFeatureModalOpened && (
						<CancelRequestedFeatureModal
							show
							onCancel={closeCancelRequestedFeatureModal}
						/>
					)}
					{isRequestedFeatureModalOpened && (
						<RequestedFeatureModal show onCancel={closeRequestedFeatureModal} />
					)}
				</div>
			);
		}
	}

	BlacklistedPermissionsComponent.propTypes = {
		getBlacklistedPermissions: func,
		blacklistedPermissionsRequest: bool,
		blacklistedPermissionsFail: bool,
		blacklistedPermissions: object,
		editBlacklistedPermissionSuccess: bool,
		isRequestFeatureModalOpened: bool,
		requestFeature: func,
		closeRequestFeatureModal: func,
		companyId: string,
		requestFeatureSuccess: bool,
		isCancelRequestedFeatureModalOpened: bool,
		isRequestedFeatureModalOpened: bool,
		closeRequestedFeatureModal: func,
		closeCancelRequestedFeatureModal: func,
		editBlacklistedPermissionFail: bool
	};

	const wrappedComponentName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	BlacklistedPermissionsComponent.displayName = `BlacklistedPermissions(${wrappedComponentName})`;
	return BlacklistedPermissionsComponent;
}
