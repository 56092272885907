import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, orderBy } from 'lodash';
import List from '../../views/ListView';
import ActionBar from '../../../Shared/views/ActionBarView';
import FilterModal from '../../../Shared/components/FilterModal';

import { RatePlansHeaderMobile } from '../../utils/DetailsHeaders';

import { ratePlansSearchMenu } from '../../utils/SearchMenus';

import {
	onOrderClick,
	filterHelpers,
	newOnPageChange,
} from '../../../../utils/tableHelpers';

import EditTableModal from '../EditTableModal';

import {
	RatePlansSignature,
	RatePlansTableTabs,
} from '../../utils/tableSignatures';

import { UserSettingsTableName } from '../../utils/constants';

import { getRatePlansFilters as getRatePlansFiltersService } from '../../services/RatePlansService';

import { downloadHelper } from '../../redux/invoiceAttachment/actions';

const headerConstants = RatePlansTableTabs[0].value.data;

class RatePlansTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderColumn: {},
			isEditTableModalOpen: false,
			settings: this.getInvoicesUserSettings()
				? JSON.parse(this.getInvoicesUserSettings().value)
				: {},
			updatedSetings: {},
		};
	}

	componentDidMount() {
		const { createUserSettings, createUserSettingsPending } = this.props;

		this.getRatePlans();

		if (!this.getInvoicesUserSettings() && !createUserSettingsPending) {
			createUserSettings({
				refTypeId: 9,
				name: UserSettingsTableName.RATE_PLANS,
				value: JSON.stringify({
					signature: RatePlansSignature,
					header: headerConstants,
				}),
			});
			this.setState({
				settings: {
					signature: RatePlansSignature,
					header: headerConstants,
				},
			});
		}
	}

	componentDidUpdate(prevProps) {
		const {
			updateUserSettingsSuccess,
      exportInvoiceDetailsSuccess,
      showReportFailNotification
		} = this.props;
		const { settings, updatedSetings } = this.state;
		if (
			updateUserSettingsSuccess != prevProps.updateUserSettingsSuccess &&
			updateUserSettingsSuccess &&
			!isEmpty(updatedSetings)
		) {
			this.updateRatePlans();
		}
		if (
			this.getInvoicesUserSettings() &&
			settings.signature !== RatePlansSignature
		) {
			this.updateInvoiceUserSettings();
    }
    if (
			exportInvoiceDetailsSuccess != prevProps.exportInvoiceDetailsSuccess &&
			exportInvoiceDetailsSuccess
		) {
			if (exportInvoiceDetailsSuccess.downloadUrl === '') {
				showReportFailNotification();
			} else {
				downloadHelper(exportInvoiceDetailsSuccess.downloadUrl);
			}
		}
	}

	updateRatePlans = () => {
		const { updatedSetings } = this.state;
		this.setState(
			{
				settings: updatedSetings,
				updatedSetings: {},
			},
			() => {
				this.getRatePlans();
			}
		);
	};

	updateInvoiceUserSettings = () => {
		const { patchUserSetting } = this.props;
		const temp = {
			value: JSON.stringify({
				signature: RatePlansSignature,
				header: headerConstants,
			}),
		};
		const settings = this.getInvoicesUserSettings();
		patchUserSetting(temp, settings.id);
		this.setState({ settings: JSON.parse(temp.value) });
	};

	getInvoicesUserSettings = () => {
		const { userSettings } = this.props;
		return userSettings.find(
			(x) => x.name === UserSettingsTableName.RATE_PLANS
		);
	};

	getFilters = () => {
		const { getRatePlansFilters, id } = this.props;
		const params = {
			additionalParams: {
				columnsToSelect: 'currency,rate_plan,plan_type',
			},
		};
		return getRatePlansFilters(id, params);
	};

	clearFilters = () => {
		this.getRatePlans();
	};

	onApply = () => {
		this.getRatePlans();
	};

	onChange = () => {
		this.getRatePlans();
	};

	onSearchSubmitCallback = () => {
		this.getRatePlans();
	};

	openFilterModal = () => {
		const { openFilterModal } = this.props;
		const { filterData } = this.state;
		openFilterModal();
		if (isEmpty(filterData)) {
			this.getFilters();
		}
	};

	getRatePlans = () => {
		const { orderColumn } = this.state;
		const {
			id,
			dataLimit,
			getRatePlans,
			filterData,
			dataOffset,
			searchParameter,
		} = this.props;

		// here we define real names of the properties that we get from backend!
		const realProps = {
			currency: 'currency',
			rate_plan: 'rate_plan',
			plan_type: 'plan_type',
		};

		let filterParams = [];

		if (filterData) {
			filterParams = filterHelpers(filterData, realProps);
		}

		let searchParams = {};

		if (searchParameter) {
			searchParams = {
				operator: 'like',
				prop: searchParameter.prop,
				propValue: `${searchParameter.propValue}%`,
			};
		}

		const params = {
			additionalParams: {
				dataLimit,
				dataOffset,
				dataSort: orderColumn.apiString || 'invoice_id asc',
			},
			searchParams: [searchParams, ...filterParams],
		};

		getRatePlans(id, params);
	};

	getOptions = (target) => {
		const {
			dataLimit,
			ratePlans,
			selectedPage,
			setDataOffset,
			setSelectedPage,
		} = this.props;
		const { totalCount } = 0 || ratePlans;
		const { orderColumn } = this.state;

		const isMobile = target === 'mobile';
		const isDesktop = target === 'desktop';

		const options = {};

		if (isDesktop) {
			options.header = this.getTableHeader();
		}

		if (isMobile) {
			options.header = RatePlansHeaderMobile;
		}

		options.tableOptions = {
			showPagination: totalCount > dataLimit,
			pageCount: this.totalCount(),
			forcePage: selectedPage,
			onPageChange: (data) =>
				newOnPageChange(
					data,
					this.getRatePlans,
					dataLimit,
					setDataOffset,
					setSelectedPage
				),
			getOrderData: (data) => onOrderClick(data, this.getRatePlans, this),
		};

		if (isDesktop) {
			options.tableOptions = {
				...options.tableOptions,
				orderByData: ['all'],
				orderColumn,
				firstColumnMarked: true,
				fixedFirstColumn: true,
				excludeDraggableColumns: [],
				draggable: true,
        onDragEnd: (fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex),
        enableTableGrabbing: true
			};
		}

		options.customComponents = {};

		return options;
	};

	totalCount = () => {
		const { dataLimit, ratePlans } = this.props;
		return Math.ceil(ratePlans.totalCount / dataLimit);
	};

	// edit table parameters
	openEditTableModal = () => {
		this.setState({
			isEditTableModalOpen: true,
		});
	};

	closeEditTableModal = () => {
		this.setState({ isEditTableModalOpen: false });
	};

	submitColumns = (newValue, id) => {
		const { patchUserSetting } = this.props;
		patchUserSetting({ value: JSON.stringify(newValue) }, id);
		this.setState({
			updatedSetings: newValue,
		});
	};

	getTableHeader = () => {
		const { settings } = this.state;
		return isEmpty(settings)
			? headerConstants
			: orderBy(settings.header, ['order'], ['asc']);
	};

	onDragEnd = async (fromIndex, toIndex) => {
		const { patchUserSetting } = this.props;
		if (toIndex < 0) return;

		const data = await this.getTableHeader();
		[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];
		const temp = {
			value: JSON.stringify({
				signature: RatePlansSignature,
				header: data.map((item, index) => {
					item.order = index + 1;
					return item;
				}),
			}),
		};
		const settings = await this.getInvoicesUserSettings();
		patchUserSetting(temp, settings.id);
		this.setState({ updatedSetings: JSON.parse(temp.value) });
	};

	render() {
		const {
			ratePlans,
			ratePlansRequest,
			ratePlansFail,
			messages,
			user,
			updateUserSettingsRequest,
			id,
      downloadFile,
      exportInvoiceDetailsRequest
		} = this.props;

		const { isEditTableModalOpen } = this.state;

		const excludeColumns = [];

		const params = {
			service: getRatePlansFiltersService,
			params: {
				id,
				params: {
					additionalParams: {
						columnsToSelect: 'currency,rate_plan,plan_type',
					},
				},
			},
		};

		const isAnyRequest =
			ratePlansRequest ||
			updateUserSettingsRequest ||
      exportInvoiceDetailsRequest;

		const isDataEmpty =
			ratePlans && ratePlans.resultList && ratePlans.resultList.length === 0;

		return (
			<div data-spec="invoice-rate-plan-table">
				<div>
					<ActionBar
						menu={ratePlansSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						openFilterModal={this.openFilterModal}
						messages={messages}
						clearFilters={this.clearFilters}
						onChange={this.onChange}
						openEditTableModal={this.openEditTableModal}
						isDisabled={isAnyRequest}
						isDataEmpty={isDataEmpty}
						downloadFile={() => downloadFile('rate_plans')}
					/>

					<FilterModal
						type="checkbox"
						messages={messages}
						onApply={this.onApply}
						params={params}
					/>

					{this.getInvoicesUserSettings() && isEditTableModalOpen && (
						<EditTableModal
							show
							onClose={this.closeEditTableModal}
							customFieldLabels={[]}
							messages={messages}
							user={user}
							submitColumns={this.submitColumns}
							columns={this.getInvoicesUserSettings()}
							signature={RatePlansSignature}
							tableTabs={RatePlansTableTabs}
							excludeColumns={excludeColumns}
						/>
					)}
				</div>
				<List
					options={this.getOptions}
					data={ratePlans}
					dataRequest={isAnyRequest}
					dataFail={ratePlansFail}
					messages={messages}
				/>
			</div>
		);
	}
}

const { func, object, string, number, bool, array, objectOf } = PropTypes;

RatePlansTable.propTypes = {
	getRatePlans: func,
	id: string,
	dataLimit: number,
	ratePlans: object,
	ratePlansRequest: bool,
	ratePlansFail: bool,
	getRatePlansFilters: func,
	openFilterModal: func,
	messages: objectOf(string),
	userSettings: array,
	user: object,
	createUserSettings: func,
	patchUserSetting: func,
	createUserSettingsPending: bool,
	updateUserSettingsSuccess: bool,
	updateUserSettingsRequest: bool,
	filterData: object,
	downloadFile: func,
	dataOffset: number,
	selectedPage: number,
	setDataOffset: func,
	setSelectedPage: func,
  searchParameter: object,
  exportInvoiceDetailsRequest: bool,
	exportInvoiceDetailsSuccess: object,
	showReportFailNotification: func
};

export default RatePlansTable;
