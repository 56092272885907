export default {
		'RELEASE_NOTES.RELEASE_NOTES': 'Note sulla versione',
		'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'La IoT Console viene aggiornata frequentemente.',
		'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': 'La IoT Console viene aggiornata frequentemente per garantire il funzionamento ottimale e offrire funzionalità e capacità estese e miglioramenti dell’esperienza utente. Di seguito sono riportati gli aggiornamenti introdotti in questa versione.',
		'RELEASE_NOTES.RELEASE_VERSION': 'Versione',
		'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'Nuove funzionalità piattaforma',
		'RELEASE_NOTES.DEPRECATIONS': 'Elementi deprecati',
		'RELEASE_NOTES.DEPRECATION': 'Deprecazione',
		'RELEASE_NOTES.KNOWN_ISSUES': 'Problema noto',
		'RELEASE_NOTES.NEW_FEATURES': 'Nuova funzionalità',
		'RELEASE_NOTES.IMPROVEMENTS': 'Miglioramenti',
		'RELEASE_NOTES.FEATURE': 'Funzionalità',
		'RELEASE_NOTES.IMPROVEMENT': 'Miglioramento',
		'RELEASE_NOTES.ISSUE': 'Problema',

		'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Aggiungere un’altra deprecazione',
		'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Aggiungere un’altra funzionalità alla piattaforma',
		'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Aggiungere un’altra funzionalità',
		'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Aggiungere un altro problema noto',
		'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Aggiungere un altro miglioramento',

		'RELEASE_NOTES.PUBLISH': 'Pubblica',
		'RELEASE_NOTES.SAVE': 'Salva',
		'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Aggiungere note sulla versione',
		'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': 'Si stanno per pubblicare note sulla versione {releaseVersion}. Confermare l’operazione?',
		'RELEASE_NOTES.CANCEL': 'Annulla'
};
