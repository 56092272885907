import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import {
	InvoiceDropdownOptions,
	TableType
} from '../../utils/AnalyticDetailsOptions';

import EndpointsTable from '../EndpointsTable';
import PrepaidSubscriptionTable from '../PrepaidSubscriptionTable';
import AddonsTable from '../AddonsTable';
import RatePlansTable from '../RatePlansTable';
import RatePlanZonesTable from '../RatePlanZonesTable';
import BillingGroupsTable from '../BillingGroupsTable';
import DataDetailsTable from '../DataDetailsTable';
import SmsDetailsTable from '../SmsDetailsTable';
import ActivationsTable from '../ActivationsTable';
import DiscountsTable from '../DiscountsTable';
import SurchargesTable from '../SurchargesTable';
import OtherChargesTable from '../OtherChargesTable';
import TaxesTable from '../TaxesTable';
import TechTypesTable from '../TechTypesTable';

import styles from './InvoiceSwitchTable.scss';

class InvoiceSwitchTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: this.getOption(TableType.ENDPOINTS)
		};
	}

	componentWillUnmount() {
		this.hideReportFailNotification();
	}

	getOption = (value) =>
		InvoiceDropdownOptions.find((option) => option.value === value);

	// this is called when dropdown option is clicked!
	handleChange = (selectedOption) => {
		const { setDataForFiltersInit } = this.props;
		setDataForFiltersInit();
		this.hideReportFailNotification();
		this.setState({ selectedOption: this.getOption(selectedOption.value) });
	};

	downloadFile = (tableName) => {
		const { exportInvoiceDetails, id } = this.props;
		this.hideReportFailNotification();
		const params = {
			searchParams: [
				{
					operator: '=',
					prop: 'tab_name',
					propValue: tableName
				}
			]
		};
		exportInvoiceDetails(id, params);
	};

	showReportFailNotification = () => {
		const { showNotification, intl } = this.props;

		const notificationMessage = intl.formatMessage({
			id: 'INVOICES.REPORTS_DOWNLOAD_ERROR',
			defaultMessage: 'Could not locate the file you are looking for.'
		});
		showNotification(notificationMessage);
	};

	hideReportFailNotification = () => {
		const { hideNotification } = this.props;
		hideNotification('download-invoice-details-error');
	};

	// show the table when selected option is changed (this is called when state changes!)
	renderTables = () => {
		const { id, messages, dataLimit } = this.props;
		const { selectedOption } = this.state;
		switch (selectedOption.value) {
			case TableType.ENDPOINTS:
				return (
					<EndpointsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-endpoints-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.PREPAID_SUBSCRIPTIONS:
				return (
					<PrepaidSubscriptionTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-prepaid-subscriptions-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.ADDONS:
				return (
					<AddonsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-addons-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.RATE_PLANS:
				return (
					<RatePlansTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-rate-plans-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.RATE_PLAN_ZONES:
				return (
					<RatePlanZonesTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-rate-plan-zones-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.BILLING_GROUPS:
				return (
					<BillingGroupsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-billing-groups-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.DATA_DETAILS:
				return (
					<DataDetailsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-data-details-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.SMS_DETAILS:
				return (
					<SmsDetailsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-sms-details-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.ACTIVATIONS:
				return (
					<ActivationsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-activations-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.DISCOUNTS:
				return (
					<DiscountsTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-discounts-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.SURCHARGES:
				return (
					<SurchargesTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-surcharges-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.OTHER_CHARGES:
				return (
					<OtherChargesTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-other-charges-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.TAX:
				return (
					<TaxesTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-taxes-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			case TableType.TECH_TYPES:
				return (
					<TechTypesTable
						id={id}
						messages={messages}
						dataLimit={dataLimit}
						data-spec="switch-tech-types-table"
						downloadFile={this.downloadFile}
						showReportFailNotification={this.showReportFailNotification}
					/>
				);
			// no default
		}
	};

	render() {
		const { selectedOption } = this.state;
		return (
			<div data-spec="invoice-switch-table">
				<div className={styles.starting}>
						<FormattedMessage
							id="ANALYTICS_INVOICES.SWITCH_MESSAGE"
							defaultMessage="Invoice details data: "
						/>
					<div className={styles.select}>
						<Select
							placeholder={'Placeholder'}
							options={InvoiceDropdownOptions}
							value={selectedOption}
							onChange={this.handleChange}
							dataSpec="invoice-details-dropdown"
							loading={false}
							noBorder
						/>
					</div>
				</div>
				<div>{this.renderTables()}</div>
			</div>
		);
	}
}

const { func, string, number, objectOf, object } = PropTypes;

InvoiceSwitchTable.propTypes = {
	id: string,
	messages: objectOf(string),
	dataLimit: number,
	setDataForFiltersInit: func,
	exportInvoiceDetails: func,
	hideNotification: func,
	showNotification: func,
	intl: object
};

export default injectIntl(InvoiceSwitchTable);
