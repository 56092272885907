import { createAction } from 'redux-actions';
import globalAction from '../../../../redux/global/globalAction';

import {
	GET_BLACKLISTED_PERMISSIONS,
	EDIT_BLACKLISTED_PERMISSION,
	REQUEST_FEATURE,
	REQUESTED_FEATURE,
	APPROVE_REQUESTED_FEATURE,
	REJECT_REQUESTED_FEATURE
} from './constants';

import {
	getBlacklistedPermissions as getBlacklistedPermissionsService,
	editBlacklistedPermission as editBlacklistedPermissionService,
	requestFeature as requestFeatureService,
	cancelRequestedFeature as cancelRequestedFeatureService,
	getBlacklistedPermissionsForCompany as getBlacklistedPermissionsForCompanyService,
	approveRequestedFeature as approveRequestedFeatureService,
	approveRequestedFeatureWithTimeLimit as approveRequestedFeatureWithTimeLimitService,
	rejectRequestedFeature as rejectRequestedFeatureServce
} from '../../services/PermissionsService';

// BOTH
export const getBlacklistedPermissions = (params) => {
	if (params && params.id) {
		return globalAction(
			GET_BLACKLISTED_PERMISSIONS,
			getBlacklistedPermissionsForCompanyService,
			params
		);
	}
	return globalAction(
		GET_BLACKLISTED_PERMISSIONS,
		getBlacklistedPermissionsService
	);
};

// SUPER ADMIN

export const editBlacklistedPermission = (params) =>
	globalAction(
		EDIT_BLACKLISTED_PERMISSION,
		editBlacklistedPermissionService,
		params,
		{
			init: true,
			notifyFail: `edit-blacklisted-permissions-${params.permissionId}`,
		}
	);

export const openRequestedFeatureModal = createAction(
	`OPEN_${REQUESTED_FEATURE}_MODAL`
);
export const closeRequestedFeatureModal = createAction(
	`CLOSE_${REQUESTED_FEATURE}_MODAL`
);

export const setRequestedFeature = createAction(`SET_${REQUESTED_FEATURE}`);
export const unsetRequestedFeature = createAction(`UNSET_${REQUESTED_FEATURE}`);

export const approveRequestedFeature = (params) =>
	globalAction(
		APPROVE_REQUESTED_FEATURE,
		approveRequestedFeatureService,
		params,
		{
			init: true,
			notifyFail: `approve-blacklisted-permissions-${params.permissionId}`
		}
	);

	export const approveRequestedFeatureWithTimeLimit = (params) =>
	globalAction(
		APPROVE_REQUESTED_FEATURE,
		approveRequestedFeatureWithTimeLimitService,
		params,
		{
			init: true,
			notifyFail: `approve-blacklisted-permissions-time-limit-${params.permissionId}`
		}
	);

export const rejectRequestedFeature = (params) =>
	globalAction(REJECT_REQUESTED_FEATURE, rejectRequestedFeatureServce, params, {
		init: true,
		notifyFail: `reject-blacklisted-permissions-${params.permissionId}`
	});

// COMPANY ADMIN

export const openRequestFeatureModal = createAction(
	`OPEN_${REQUEST_FEATURE}_MODAL`
);
export const closeRequestFeatureModal = createAction(
	`CLOSE_${REQUEST_FEATURE}_MODAL`
);

export const setRequestFeature = createAction(`SET_${REQUEST_FEATURE}`);
export const unsetRequestFeature = createAction(`UNSET_${REQUEST_FEATURE}`);

export const requestFeature = (params) =>
	globalAction(REQUEST_FEATURE, requestFeatureService, params, {
		modals: closeRequestFeatureModal(),
		final: getBlacklistedPermissions(),
		notifyFail: `request-fail-${params.id}`
	});

export const openCancelRequestedFeatureModal = createAction(
	`OPEN_CANCEL_${REQUESTED_FEATURE}_MODAL`
);
export const closeCancelRequestedFeatureModal = createAction(
	`CLOSE_CANCEL_${REQUESTED_FEATURE}_MODAL`
);
export const setCancelRequestedFeature = createAction(`SET_CANCEL_${REQUESTED_FEATURE}`);
export const unsetCancelRequestedFeature = createAction(`UNSET_CANCEL_${REQUESTED_FEATURE}`);

export const cancelRequestedFeature = (params) =>
	globalAction(`CANCEL_${REQUESTED_FEATURE}`, cancelRequestedFeatureService, params, {
		modals: closeCancelRequestedFeatureModal(),
		final: getBlacklistedPermissions(),
		notifyFail: `cancel-request-fail-${params.id}`
	});
