import BatchHistoryView from '../views/BatchHistoryView';
import {
	userIsAuthenticated,
	userInStandardMode
} from '../../../utils/AuthSelector';

export default [
	{
		path: '/batch-history',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.endpoints.batchupload_rw',
				'mnc.endpoints.batchupload_ro'
			])(BatchHistoryView)
		)
	}
];
