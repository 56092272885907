import { createAction } from 'redux-actions';

import { patchUserSettings } from '../../../services/UserService';
import { getLocale } from '../../../utils/constants';
import { FETCH_USER, UPDATE_USER_SETTINGS } from '../constants';
import { showNotification as show } from '../../../components/Shared/redux/notifications/actions';

const request = createAction(`${UPDATE_USER_SETTINGS}_REQUEST`);
const success = createAction(`${UPDATE_USER_SETTINGS}_SUCCESS`);
const fail = createAction(`${UPDATE_USER_SETTINGS}_ERROR`);
const editSuccess = createAction(`${FETCH_USER}_EDIT`);

const updateUserSettings = (setting, id, message) => async (
	dispatch
) => {
  const locale = getLocale();
	const { default: messages } = await import(
		`../../../localizations/${locale}`
	);
	dispatch(request());
	patchUserSettings(setting, id)
		.then((response) => {
			dispatch(editSuccess({ data: setting, id })); // PROBLEM
			message &&
				dispatch(
					show({
						id: 'table-preferences-updated-success',
						message: message.success
							? [messages[message.success]]
							: [messages.ACTION_COMPLETED_SUCCESSFULLY],
						type: 'success',
						notificationType: 'toaster',
						close: true,
						duration: 5000
					})
				);
			dispatch(success(response));
		})
		.catch((error) => {
			dispatch(fail(error));
			message &&
				dispatch(
					show({
						id: 'table-preferences-updated-fail',
						message: message.error
							? [messages[message.error]]
							: [messages.ACTION_COULD_NOT_BE_COMPLETED],
						type: 'error',
						notificationType: 'toaster',
						close: false
					})
				);
		});
};

export default updateUserSettings;
