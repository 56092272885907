import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import user from './user/reducer';
import batchFiles from './batchFiles/reducer';
import modals from './modals/reducer';
import feedback from '../components/UserFeedback/redux/reducer';
import singleEndpoint from '../components/SingleEndpoint/redux/reducer';
import automation from '../components/Automation/redux/reducer';
import analytics from '../components/Analytics/redux/reducer';
import accountOverview from '../components/AccountOverview/redux/reducer';
import onboarding from '../components/Onboarding/redux/reducer';
import shared from '../components/Shared/redux/reducer';
import simOrdering from '../components/SimOrdering/redux/reducer';
import ticketing from '../components/Ticketing/redux/reducer';
import support from '../components/Support/redux/reducer';
import reports from '../components/Reports/redux/reducer';
import analyticsInvoices from '../components/AnalyticsInvoices/redux/reducer';
import failover from '../components/Failover/redux/reducer';
import batchHistory from '../components/BatchHistory/redux/reducer';
import securityFeatures from '../components/SecurityFeatures/redux/reducer';
import releaseNotes from '../components/ReleaseNotes/redux/reducer';
import Endpoints from '../components/Endpoint/redux/reducer';
import windowRedux from './windowRedux/reducer';
import automationZones from '../components/AutomationZones/redux/reducer';
import locale from './locale/reducer';

export default (routerHistory) =>
	combineReducers({
		form,
		router: connectRouter(routerHistory),
		user,
		Endpoints,
		batchFiles,
		modals,
		feedback,
		singleEndpoint,
		automation,
		analytics,
		securityFeatures,
		accountOverview,
		onboarding,
		shared,
		simOrdering,
		ticketing,
		support,
		reports,
		analyticsInvoices,
		failover,
		batchHistory,
		releaseNotes,
		windowRedux,
		automationZones,
		locale
	});
