import { connect } from 'react-redux';
import { getFormValues, isDirty } from 'redux-form';

import EditCompanyOverview from './EditCompanyOverview';

import { getUser } from '../../../../redux/user/getUserData/selectors';
import {
	getCountries,
	getStates,
} from '../../redux/companies/actions';
import {
	getStatesSuccess,
	getCountriesSuccess,
	getStatesRequest
} from '../../redux/companies/selectors';
import {
	getLanguages,
} from '../../redux/users/actions';

import {
	getLanguagesFail,
	getLanguagesRequest,
	getLanguagesSuccess,
} from '../../redux/users/selectors';

const mapStateToProps = (state) => ({
	user: getUser(state),
	getLanguagesFail: getLanguagesFail(state),
	getLanguagesRequest: getLanguagesRequest(state),
	getLanguagesSuccess: getLanguagesSuccess(state),
	formData: getFormValues('EditCompany')(state),
	states: getStatesSuccess(state),
	countries: getCountriesSuccess(state),
	getStatesRequest: getStatesRequest(state),
	streetFieldDirty: isDirty('EditCompany')(state, ['addresses[0].street1']),
	countryFieldDirty: isDirty('EditCompany')(state, ['addresses[0].countryId']),
	stateFieldDirty: isDirty('EditCompany')(state, ['addresses[0].stateId']),
	zipCodeFieldDirty: isDirty('EditCompany')(state, ['addresses[0].zipCode']),
	cityFieldDirty: isDirty('EditCompany')(state, ['addresses[0].city']),
});

const mapDispatchToProps = (dispatch) => ({
	getLanguages: () => dispatch(getLanguages()),
	getCountries: (params) => dispatch(getCountries(params)),
	getStates: (params) => dispatch(getStates(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyOverview);
