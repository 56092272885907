import globalAction from '../../../../redux/global/globalAction';
import {
	GET_CATEGORIES,
	GET_FIRST_SUBCATEGORIES,
	GET_SECOND_SUBCATEGORIES,
	GET_THIRD_SUBCATEGORIES,
	GET_SEVERITIES,
	GET_PRIORITIES,
	GET_COUNTRIES,
	GET_STATES,
	GET_CONFIRMATION,
	GET_HISTORY
} from './constants';
import {
	getCategories as getCategoriesService,
	getFirstSubCategories as getFirstSubCategoriesService,
	getSecondSubCategories as getSecondSubCategoriesService,
	getThirdSubCategories as getThirdSubCategoriesService,
	getSeverities as getSeveritiesService,
	getPriorities as getPrioritiesService,
	getCountries as getCountriesService,
	getStates as getStatesService,
	getConfirmation as getConfirmationService,
	getHistory as getHistoryService
} from '../../services/TicketingService';

/* GET CATEGORIES */
export const getCategories = (params) =>
	globalAction(GET_CATEGORIES, getCategoriesService, params);

/* GET FIRST SUBCATEGORIES */
export const getFirstSubCategories = (params) =>
	globalAction(GET_FIRST_SUBCATEGORIES, getFirstSubCategoriesService, params);

/* GET FIRST SUBCATEGORIES */
export const getSecondSubCategories = (params) =>
	globalAction(GET_SECOND_SUBCATEGORIES, getSecondSubCategoriesService, params);

/* GET FIRST SUBCATEGORIES */
export const getThirdSubCategories = (params) =>
	globalAction(GET_THIRD_SUBCATEGORIES, getThirdSubCategoriesService, params);

/* GET SEVERITIES */
export const getSeverities = () =>
	globalAction(GET_SEVERITIES, getSeveritiesService);

/* GET PRIORITIES */
export const getPriorities = () =>
	globalAction(GET_PRIORITIES, getPrioritiesService);

/* GET COUNTRIES */
export const getCountries = () =>
	globalAction(GET_COUNTRIES, getCountriesService);

/* GET STATES */
export const getStates = (params) => globalAction(GET_STATES, getStatesService, params);

/* GET CONFIRMATION */
export const getConfirmation = () =>
  globalAction(GET_CONFIRMATION, getConfirmationService);

/*  GET TICKET HISTORY */
export const getHistory = (params) =>
	globalAction(GET_HISTORY, getHistoryService, params);
