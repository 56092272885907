import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Error404.scss';

const Error404 = () => (
	<div data-spec="error-404">
		<div className={styles.title}>404</div>
		<div className={styles.sub_title}>
			<FormattedMessage id="ERRORS.ERROR" defaultMessage="Error" />
		</div>
		<div className={styles.message}>
			<FormattedMessage
				id="ERRORS.PAGE_NOT_FOUND"
				defaultMessage="It seems we can’t find the page you are looking for."
			/>
		</div>
	</div>
);

export default Error404;
