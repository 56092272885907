import PropTypes from 'prop-types';
import React from 'react';
import hoc from '../AccountActivation';
import styles from './Desktop.scss';

const Desktop = ({ description, title, renderForm }) => (
	<div data-spec="reset-password-desktop">
		<div className={styles.page_title}>
			<span className={styles.title}>{title}</span>
		</div>
		<div className={styles.description}>
			<span>{description}</span>
		</div>
		<div className={styles.form}>{renderForm()}</div>
	</div>
);

const { func, object } = PropTypes;
Desktop.propTypes = {
	renderForm: func,
	title: object,
	description: object
};

export default hoc()(Desktop);
