import { connect } from 'react-redux';
import { batchFileUploadFail } from 'redux/batchFiles/actions';
import BatchFileUpload from './BatchFileUpload';

import { getUploadTemplate } from '../../redux/uploadTemplate/actions';
import { downloadTemplate } from '../../redux/downloadTemplate/actions';

import {
	getUploadTemplateRequest,
	getUploadTemplateFail,
	getUploadTemplateSuccess
} from '../../redux/uploadTemplate/selectors';

import {
	downloadTemplateRequest,
	downloadTemplateFail,
	downloadTemplateSuccess
} from '../../redux/downloadTemplate/selectors';

const mapStateToProps = (state) => ({
	uploadTemplateRequest: getUploadTemplateRequest(state),
	uploadTemplateFail: getUploadTemplateFail(state),
	uploadTemplate: getUploadTemplateSuccess(state),
	downloadTemplateRequest: downloadTemplateRequest(state),
	downloadTemplateFail: downloadTemplateFail(state),
	template: downloadTemplateSuccess(state)
});

const mapDispatchToprops = (dispatch) => ({
	fileUploadFail: (error) => dispatch(batchFileUploadFail(error)),
	getUploadTemplate: (params) => dispatch(getUploadTemplate(params)),
  downloadTemplate: (params) => dispatch(downloadTemplate(params))
});

export default connect(mapStateToProps, mapDispatchToprops)(BatchFileUpload);
