export default {
	'SUPPORT.USER_GUIDE': 'Guide de l’utilisateur',
	'SUPPORT.PRINT_PDF': 'Imprimer PDF',
	'SUPPORT.SUPPORT': 'Assistance',
	'SUPPORT.FAQ': 'FAQ',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Trouver des réponses rapides aux questions courantes de nos utilisateurs.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Lire les instructions détaillées étape par étape sur les fonctions du portail. Le guide est adapté à votre type de compte.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Table des matières',
	'SUPPORT.TICKETING': 'Établissement de billets',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'Si vous éprouvez des pannes de système ou des problèmes de service avec vos points terminaux que vos tests de diagnostic ne résolvent pas, vous pouvez créer un billet de dérangement avec notre équipe Ops. Vous pouvez poser également des questions sur la facturation et le service.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Notifications récentes',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Créer une nouvelle notification',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'Afficher toutes les notifications',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Foire aux questions',
	'SUPPORT.TOPICS': 'Sujets',
	'SUPPORT.EDIT_NOTIFICATION': 'Modifier la notification',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Notification globale',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': 'Notification liée à la société',
	'SUPPORT.NOTIFICATION_TYPE': 'Type de notification ?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER':
		"Sélectionner l'espace réservé de la notification",
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Choisir les groupes d’utilisateurs à notifier :',
	'SUPPORT.CONTENT': 'Contenu',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Ajouter contenu de notification',
	'SUPPORT.PUBLISH': 'Publier',
	'SUPPORT.GLOBAL': 'Globale',
	'SUPPORT.COMPANY_RELATED': 'Liée à la société',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Confirmation',
	'SUPPORT.NOTIFICATION_INFO': 'Info',
	'SUPPORT.NOTIFICATION_WARNING': 'Avertissement',
	'SUPPORT.NOTIFICATION_ERROR': 'Erreur',
	'SUPPORT.SELECT_SECTION': 'Sélectionner la section',
	'SUPPORT.ALL': 'Toutes',
	'SUPPORT.ADMIN': 'Administration',
	'SUPPORT.USER': 'Utilisateur',
	'SUPPORT.FINANCE': 'Finances',
	'SUPPORT.OPERATIONS': 'Opérations',
	'SUPPORT.RESTRICTEDACCESS': 'Accès restreint',
	'SUPPORT.COMPANYVIEW': 'Vue de la société',
	'SUPPORT.COMPANYVIEWREADONLY': 'Vue de la société en lecture seule',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Ajouter nouvelles notes de version',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'Le document ci-dessous n’est pas entièrement traduit dans votre langue. La version anglaise est affichée. La traduction dans votre langue sera disponible avec les prochaines versions du produit.'
};
