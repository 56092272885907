import { handleActions } from 'redux-actions';
import { Status } from '../../../../../utils/constants';

import { RESET_FILTER_INIT, RESET_FILTER_SUCCESS } from './constants';

const initialState = {
	data: null,
	error: null,
	status: Status.INIT
};

export default handleActions(
	{
		[RESET_FILTER_INIT]: (state) => ({
			...state,
			error: null,
			status: Status.INIT,
			data: null
		}),
		[RESET_FILTER_SUCCESS]: (state, action) => ({
			...state,
			error: null,
			status: Status.DONE,
			data: action.payload
		})
	},
	initialState
);
