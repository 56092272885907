import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import DesktopView from './Desktop';
import MobileView from './Mobile';
import {
	getReleaseNotesList,
  getReleaseNotes,
  publishReleaseNotes,
	getReleaseNotesInit
} from '../../redux/actions';
import {
	getReleaseNotesListRequest,
	getReleaseNotesListFail,
	getReleaseNotesListSuccess,
	getReleaseNotesRequest,
	getReleaseNotesFail,
  getReleaseNotesSuccess,
  publishReleaseNotesRequest,
  publishReleaseNotesFail,
	publishReleaseNotesSuccess
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	releaseNotesListRequest: getReleaseNotesListRequest(state),
	releaseNotesListFail: getReleaseNotesListFail(state),
	releaseNotesList: getReleaseNotesListSuccess(state),
	releaseNotesRequest: getReleaseNotesRequest(state),
	releaseNotesFail: getReleaseNotesFail(state),
  releaseNotes: getReleaseNotesSuccess(state),
  publishReleaseNotesRequest: publishReleaseNotesRequest(state),
  publishReleaseNotesFail: publishReleaseNotesFail(state),
  publishReleaseNotesSuccess: publishReleaseNotesSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	goBack: (url) => dispatch(localizedRoute(url)),
	goToAddNewReleaseNotes: (url) => dispatch(localizedRoute(url)),
	goToEditReleaseNotes: (url) => dispatch(localizedRoute(url)),
	getReleaseNotesList: (params) => dispatch(getReleaseNotesList(params)),
  getReleaseNotes: (params) => dispatch(getReleaseNotes(params)),
  publishReleaseNotes: (params) => dispatch(publishReleaseNotes(params)),
	getReleaseNotesInit: () => dispatch(getReleaseNotesInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopView);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileView);
