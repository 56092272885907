import { connect } from 'react-redux';

import EditTableModal from './EditTableModal';
import { updateCustomLabels } from '../../redux/actions';
import getActiveTab from '../../redux/endpointsTabs/selectors';
import {
	customLabelsUpdatePending,
	getCustomLabelsSuccess
} from '../../redux/selectors';

const mapStateToProps = (state, props) => (
	{
		messages: props.messages,
		EditCustomFieldLabelsValues: state.form
			? state.form.EditCustomFieldLabels
			: {},
		isCustomLabelsUpdating: customLabelsUpdatePending(state),
		customLabelsColumns: getCustomLabelsSuccess(state),
		activeTab: getActiveTab(state)
	}
);

const mapDispatchToProps = (dispatch) => ({
	submitEditLabelForm: (data, paginationData) =>
		dispatch(updateCustomLabels(data, paginationData))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditTableModal);
