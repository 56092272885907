import { handleActions } from 'redux-actions';
import { Status } from '../../utils/constants';

export const initialState = {
	data: null,
	status: Status.INIT,
	error: null
};

export default (prefix, customActionHandlers = {}) =>
	handleActions(
		{
			[`${prefix}_REQUEST`]: (state) => ({
				...state,
				data: null,
				status: Status.PENDING,
				error: null
			}),
			[`${prefix}_FAIL`]: (state, action) => ({
				...state,
				data: null,
				status: Status.FAIL,
				error: action.payload
			}),
			[`${prefix}_SUCCESS`]: (state, action) => ({
					...state,
					data: action.payload.data,
					status: Status.DONE,
					error: null
				}),
			[`${prefix}_INIT`]: (state) => ({
				...state,
				data: null,
				status: Status.INIT,
				error: null
			}),
			...customActionHandlers
		},
		initialState
	);
