export default {
	'BATCH_HISTORY.LINK': 'Historique des lots',
	'BATCH_HISTORY.TITLE': 'Historique des lots',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Actions par lots',
	'BATCH_HISTORY.BATCH_REPORTS': 'Rapports de lots',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'Aucune action par lots disponible',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': 'Aucune action par lots à montrer actuellement pour cette table',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'Aucun rapport par lots disponible',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': 'Aucun rapport par lots à montrer actuellement pour cette table',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Nom du fichier séquentiel',
	'BATCH_HISTORY.DATE_UPLOADED': 'Date du téléversement',
	'BATCH_HISTORY.STATUS': 'État',
	'BATCH_HISTORY.REPORT_NAME': 'Rapport',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'ID de compte machine-machine',
	'BATCH_HISTORY.DATE': 'Date',
	'BATCH_HISTORY.M2M_PLATFORM': 'Sélectionner plateforme machine-machine',
	'BATCH_HISTORY.FREQUENCY': 'Fréquence',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Envoyé',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Fréquence',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'En cours',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Exécuté',
  'BATCH_HISTORY.ERROR_STATUS': 'Erreur',
  'BATCH_HISTORY.CANCELLED_STATUS': 'Annulé',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': 'Téléversement du fichier réussi pour le traitement',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': 'Téléversement du fichier réussi, programmé pour le traitement',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Traitement en cours',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'Traitement du fichier réussi',
  'BATCH_HISTORY.ERROR_TOOLTIP': 'Une erreur s’est produite pendant le traitement',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': 'Infobulle annulée'
};
