import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import { hideNotification } from '../../../Shared/redux/notifications/actions';
import NetworkEntitlementDetails from './NetworkEntitlementDetails';

import {
	getNetworkEntitlement,
	getNetworkEntitlementInit,
	addNetworkEntitlementInit,
	editNetworkEntitlementInit
} from '../../redux/networkEntitlements/actions';
import {
	getNetworkEntitlementRequest,
  getNetworkEntitlementSuccess,
  editNetworkEntitlementRequest,
  editNetworkEntitlementSuccess
} from '../../redux/networkEntitlements/selectors';

import {
	getAccountSuccess,
	getAccountRequest,
	getAccountFail
} from '../../redux/accounts/selectors';
import { getAccount, getAccountInit } from '../../redux/accounts/actions';

const mapStateToProps = (state) => ({
	entitlementRequest: getNetworkEntitlementRequest(state),
	entitlement: getNetworkEntitlementSuccess(state),
	accountRequest: getAccountRequest(state),
	accountFail: getAccountFail(state),
	account: getAccountSuccess(state),
	editEntitlementRequest: editNetworkEntitlementRequest(state),
  editEntitlementSuccess: editNetworkEntitlementSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	pushBack: (url) => dispatch(localizedRoute(url)),
	getNetworkEntitlement: (params) => dispatch(getNetworkEntitlement(params)),
	networkEntitlementInit: () => {
		dispatch(addNetworkEntitlementInit());
		dispatch(editNetworkEntitlementInit());
		dispatch(getNetworkEntitlementInit());
	},
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit()),
	onSubmit: () => dispatch(submit('EditNetworkEntitlement')),
	hideNotification: (id) => dispatch(hideNotification(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NetworkEntitlementDetails);
