import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';
import Switch from '../../../../lib/DigitalComponents/Switch';

import {
	email,
} from '../../../../utils/validators';

import styles from './StepFour.scss';

const validate = (values) => {
	const errors = {
		user: {}
	};

	if (!values.user) {
		errors.user.uid = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}
	if (values.user) {
		if (
			(!values.user.uid || !values.user.uid.trim().length)
		) {
			errors.user.uid = (
				<FormattedMessage id="REQUIRED" defaultMessage="Required" />
			);
		}
	}

	return errors;
};

class StepFour extends Component {
	componentDidMount() {
		const { formValues, initialize } = this.props;
		initialize({
			...formValues,
			emailNotification: true,
			mask: { value: '+1', label: 'United States +1' }
		});
	}

	render() {
		const {
			handleSubmit,
			previousPage,
			user,
			formValues = {},
      invalid,
      pushBack
		} = this.props;

		return (
			<div data-spec="step-two">
				<form onSubmit={handleSubmit}>
					<div className={styles.summary}>
						<div className={styles.multiple_column_container}>
							<h3 className={styles.column_title}>
								<FormattedMessage
									id="TICKETING.CUSTOMER"
									defaultMessage="Customer"
								/>
							</h3>
						</div>
						<div className={styles.multiple_column_container}>
							<div className={styles.column}>
								<div className={styles.field_wrapper}>
									<div className={styles.field_label}>
										<FormattedMessage
											id="TICKETING.ACCOUNT_NAME"
											defaultMessage="Account name"
										/>
									</div>
									<div>
										{formValues.userDetails ? formValues.userDetails.companyName : '-'}
									</div>
								</div>
								<div className={styles.field_wrapper}>
									<div className={styles.field_label}>
										<FormattedMessage
											id="TICKETING.CREATED_BY"
											defaultMessage="Created by"
										/>
									</div>
									<div>{user.uid}</div>
								</div>
							</div>
							<div className={styles.column}>
								<div className={styles.field_wrapper}>
									<div className={styles.field_label}>
										<FormattedMessage
											id="TICKETING.ACCOUNT_NUMBER"
											defaultMessage="Account number"
										/>
									</div>
									<div>{formValues.userDetails ? formValues.userDetails.accountId : '-'}</div>
								</div>
							</div>
						</div>
						<div className={styles.multiple_column_container}>
							<hr className={styles.divider} />
						</div>
						<div className={styles.multiple_column_container}>
							<div className={styles.field_root}>
								<div className={styles.field_wrapper_column}>
									<FormattedMessage
										id="TICKETING.CONTACT_EMAIL"
										defaultMessage="Contact email"
									>
										{(formattedValue) => (
											<Field
												component={Input}
												name="user.uid"
												placeholder={formattedValue}
												label={formattedValue}
												validate={[email]}
											/>
										)}
									</FormattedMessage>
								</div>
								<div className={styles.field_wrapper_column_email}>
									<div className={styles.field_label}>
										<FormattedMessage
											id="TICKETING.EMAIL_NOTIFICATIONS"
											defaultMessage="Email notifications"
										/>
									</div>
									<Field
										component={Switch}
										name="emailNotification"
										label={
											formValues && formValues.emailNotification === true ? (
												<FormattedMessage
													id="TICKETING.ON"
													defaultMessage="On"
												/>
											) : (
												<FormattedMessage
													id="TICKETING.OFF"
													defaultMessage="Off"
												/>
											)
										}
									/>
								</div>
							</div>
						</div>

						<div className={styles.multiple_column_container}>
							<div className={styles.field_root}>
								<div className={styles.field_wrapper_column}>
									<FormattedMessage
										id="TICKETING.CONTACT_NAME"
										defaultMessage="Contact Name"
									>
										{(formattedValue) => (
											<Field
												component={Input}
												dataSpec={'contact-name'}
												name="user.contactName"
												placeholder={formattedValue}
												label={formattedValue}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
						</div>

						<div className={styles.buttons_wrapper}>
							<div className={styles.left_button}>
								<Button
									label={
										<FormattedMessage
											id="TICKETING.CANCEL_TICKET"
											defaultMessage="Cancel ticket"
										/>
									}
									variant="link"
									type="button"
									dataSpec="back-to-ticketing"
									onClick={pushBack}
								/>
							</div>
							<div className={styles.buttons_right}>
								<Button
									label={<FormattedMessage id="BACK" defaultMessage="Back" />}
									variant="outline-primary"
									type="button"
									dataSpec="previous"
									onClick={previousPage}
								/>
								<Button
									label={
										<FormattedMessage id="SUBMIT" defaultMessage="Submit" />
									}
									variant="primary"
									type="submit"
									dataSpec="submit"
									disabled={invalid}
								/>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const { func, bool, shape } = PropTypes;

StepFour.propTypes = {
	handleSubmit: func,
	previousPage: func,
	user: shape(),
	formValues: shape(),
	invalid: bool,
  initialize: func,
  pushBack: func
};

const StepFourForm = reduxForm({
	form: 'addTicket',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	validate
})(StepFour);

export default StepFourForm;
