import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import InvoicesDesktop from './Desktop';
import InvoicesMobile from './Mobile';

// import { downloadReportRequestURL } from '../../../../redux/invoices/invoicesReport/actions';

// import getIsDownloadingReport from '../../../../redux/invoices/invoicesReport/selectors';

import { downloadReportRequestURL } from '../../redux/invoiceReport/actions';
import { invoiceReportRequest } from '../../redux/invoiceReport/selectors';

import {
	getAnalyticsInvoices,
	getInvoicesFilters,
	exportInvoice,
	getInvoices,
  getFilters
} from '../../redux/invoices/actions';

import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';

import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';

import {
	getInvoicesRequest,
	getInvoicesFail,
	getInvoicesSuccess,
	getFiltersRequest,
	getFiltersFail,
	getFiltersSuccess,
	exportInvoiceRequest,
	exportInvoiceFail,
	exportInvoiceSuccess,
	patchInvoiceSuccess,
  patchInvoiceRequest,
  postInvoiceRequest,
  postInvoiceSuccess
} from '../../redux/invoices/selector';

import {
	getUser,
	getUserSettings
} from '../../../../redux/user/getUserData/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import { createUserSettingsPending } from '../../../../redux/user/createUserSettings/selectors';
import deleteUserSettings from '../../../../redux/user/deleteUserSettings/actions';
import resetUserSettings from '../../../../redux/user/resetUserSettings/actions';
import {
	updateUserSettingsSuccess,
	updateUserSettingsPending
} from '../../../../redux/user/updateUserSettings/selectors';

import getNotifications from '../../../Shared/redux/notifications/selectors';
import { getErrors } from '../../../Shared/redux/getStatusCode/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

const mapStateToProps = (state) => ({
	invoicesRequest: getInvoicesRequest(state),
	invoicesFail: getInvoicesFail(state),
	invoices: getInvoicesSuccess(state),
	filtersRequest: getFiltersRequest(state),
	filtersFail: getFiltersFail(state),
	filters: getFiltersSuccess(state),
	exportInvoiceRequest: exportInvoiceRequest(state),
	exportInvoiceFail: exportInvoiceFail(state),
	exportInvoiceData: exportInvoiceSuccess(state),
	filterModalOpened: isFilterModalOpen(state),
	user: getUser(state),
	userSettings: getUserSettings(state, 7),
	createUserSettingsPending: createUserSettingsPending(state),
	updateUserSettingsPending: updateUserSettingsPending(state),
	updateUserSettingsSuccess: updateUserSettingsSuccess(state),
	notifications: getNotifications(state),
	errors: getErrors(state),
	patchInvoiceSuccess: patchInvoiceSuccess(state),
  patchInvoiceRequest: patchInvoiceRequest(state),
  postInvoiceRequest: postInvoiceRequest(state),
  postInvoiceSuccess: postInvoiceSuccess(state),
	invoiceReportRequest: invoiceReportRequest(state),
  filterData: filterDataSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAnalyticsInvoices: (params) => dispatch(getAnalyticsInvoices(params)),
	getInvoices: (params) => dispatch(getInvoices(params)),
	getFilters: () => dispatch(getFilters()),
	getInvoicesFilters: (params) => dispatch(getInvoicesFilters(params)),
  goToInvoiceDetails: (m2mAccountId, billingCycle) => dispatch(localizedRoute(`/invoices/${m2mAccountId}/${billingCycle}`)),
  goToAddInvoice: () => dispatch(localizedRoute('/invoices/add-invoice')),
  exportInvoice: (id) => dispatch(exportInvoice(id)),
  anyModalOpened: () => dispatch(anyModalOpened()),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	patchUserSetting: (newValue, id) =>
		dispatch(
			updateUserSettings(newValue, id, {
				success: 'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS',
				error: 'ANALYTICS_INVOICES.NOTIFICATION_FAIL'
			})
		),
	createUserSettings: (newValue) => dispatch(createUserSettings(newValue)),
	deleteUserSettings: (id) => dispatch(deleteUserSettings(id, 'test')),
	resetUserSettings: (refId) => dispatch(resetUserSettings(refId)),
	downloadFile: (fileName, selectedAccountId) =>
		dispatch(
			downloadReportRequestURL({
				reportFileName: `${fileName.docviewerGeneratedDocId}.pdf`,
				reportCategory: 'BillingInvoiceReport',
				m2mAccountId: selectedAccountId
			})
		)
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvoicesDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvoicesMobile);
