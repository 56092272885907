import { Status } from '../../../utils/constants';

export const isDeleteDefinedSettingPending = ({ accountOverview }) =>
	accountOverview.deleteDefinedSetting.status === Status.PENDING;

export const isDeleteDefinedSettingSuccess = ({ accountOverview }) =>
	accountOverview.deleteDefinedSetting.status === Status.DONE;

export const isGetDefinedSettingsPending = ({ accountOverview }) =>
	accountOverview.getDefinedSettings.status === Status.PENDING;

export const getDefinedSettingsData = ({ accountOverview }) =>
	accountOverview.getDefinedSettings.data &&
	accountOverview.getDefinedSettings.data.resultList;

export const isGetDefinedSettingByIdPending = ({ accountOverview }) =>
	accountOverview.getDefinedSettingById.status === Status.PENDING;

export const getDefinedSettingByIdData = ({ accountOverview }) =>
	accountOverview.getDefinedSettingById.data;

export const isUpdateDefinedSettingPending = ({ accountOverview }) =>
	accountOverview.updateDefinedSetting.status === Status.PENDING;

export const isUpdateDefinedSettingSuccess = ({ accountOverview }) =>
	accountOverview.updateDefinedSetting.status === Status.DONE;
