import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../../../lib/DigitalComponents/Tabs';

const TabsBar = (props) => {
	const { styles, tabs, actions } = props;
	return (
		<div data-spec="tabs-bar" className={styles}>
			<Tabs tabs={tabs} actions={actions} />
		</div>
	);
};

const { oneOfType, string, arrayOf, object } = PropTypes;

TabsBar.propTypes = {
	styles: oneOfType([string, object]),
	tabs: arrayOf(object),
	actions: object
};

export default TabsBar;
