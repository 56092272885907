import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../List';

const Desktop = (props) => {
	const { data, getOptions, messages } = props;
	return (
		<Table
			data-spec="reports-table"
			data={data}
			options={getOptions('desktop')}
			messages={messages}
		/>
	);
};

const { arrayOf, object, objectOf, func, string } = PropTypes;

Desktop.propTypes = {
	messages: objectOf(string),
	getOptions: func,
	data: arrayOf(object)
};

export default hoc()(Desktop);
