import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_SURCHARGES, GET_SURCHARGES_FILTERS } from './constants';

export default combineReducers({
	getSurcharges: globalReducer(GET_SURCHARGES),
	getSurchargesFilters: globalReducer(GET_SURCHARGES_FILTERS)
});
