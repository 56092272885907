export const analyticsInvoicesBaseURL = '/invoices';
export const userSettingsID = 9;

export const UserSettingsTableName = {
	INVOICES: 'InvoicesTable',
	ENDPOINTS: 'EndpointsTable',
	PREPAID_SUBSCRIPTIONS: 'PrepaidSubscriptionsTable',
	ADDONS: 'AddonsTable',
	RATE_PLANS: 'RatePlansTable',
	RATE_PLAN_ZONES: 'RatePlanZonesTable',
	BILLING_GROUPS: 'BillingGroupsTable',
	DATA_DETAILS: 'DataDetailsTable',
	SMS_DETAILS: 'SmsDetailsTable',
	ACTIVATIONS: 'ActivationsTable',
	DISCOUNTS: 'DiscountsTable',
	TAXES: 'TaxesTable',
	SURCHARGES: 'SurchargesTable',
	TECH_TYPES: 'TechTypesTable',
  OTHER_CHARGES: 'OtherChargesTable',
  VOICE_DETAILS: 'VoiceDetailsTable'
};
