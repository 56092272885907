import { handleActions } from 'redux-actions';
import { SELECTED_ITEMS, SELECTED_ITEMS_INIT } from './constants';

const initialState = {
	selectedItems: []
};

export default handleActions(
	{
		[SELECTED_ITEMS_INIT]: (state) => ({
			...state,
			selectedItems: []
		}),
		[SELECTED_ITEMS]: (state, action) => ({
			...state,
			selectedItems: action.payload
		})
	},
	initialState
);
