import React from 'react';
import PropTypes from 'prop-types';

import styles from './Card.scss';

const Card = ({ children, customStyle }) => (
	<div data-spec="card" className={`${styles.card_wrapper} ${customStyle}`}>
		{children}
	</div>
);

const { object, array, oneOfType, string } = PropTypes;

Card.propTypes = {
	children: oneOfType([object, array]),
	customStyle: string,
};

Card.defaultProps = {
	children: [],
	customStyle: '',
};

export default Card;
