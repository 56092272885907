class CurrentContext {
	constructor() {
		this.theme = 'default';
		this.locale = 'en-US';
	}
}

const currentContext = new CurrentContext();

export default currentContext;
