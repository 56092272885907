import ErrorPageView from '../views/ErrorPageView';

const errorPageViewRoutes = [
	{
		path: '/error',
		exact: true,
		Component: ErrorPageView,
		noLayout: true
	},
	{
		path: '/forbidden-access',
		exact: true,
		Component: ErrorPageView,
		noLayout: true
	},
	{
		path: '/page-not-found',
		exact: true,
		Component: ErrorPageView,
		noLayout: true
	}
];

export default errorPageViewRoutes;
