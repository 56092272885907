import { Status } from '../../../../utils/constants';

export const getDiscountsRequest = (state) =>
	state.analyticsInvoices.tables.discounts.getDiscounts.status ===
	Status.PENDING;
export const getDiscountsFail = (state) =>
	state.analyticsInvoices.tables.discounts.getDiscounts.status === Status.FAIL;
export const getDiscountsSuccess = (state) =>
	state.analyticsInvoices.tables.discounts.getDiscounts.data;

export const getDiscountsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.discounts.getDiscountsFilters.status ===
	Status.PENDING;
export const getDiscountsFiltersFail = (state) =>
	state.analyticsInvoices.tables.discounts.getDiscountsFilters.status ===
	Status.FAIL;
export const getDiscountsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.discounts.getDiscountsFilters.data;
