import { Status } from '../../../../utils/constants';

export const getBillingGroupsRequest = (state) =>
	state.analyticsInvoices.tables.billingGroups.getBillingGroups.status ===
	Status.PENDING;
export const getBillingGroupsFail = (state) =>
	state.analyticsInvoices.tables.billingGroups.getBillingGroups.status ===
	Status.FAIL;
export const getBillingGroupsSuccess = (state) =>
	state.analyticsInvoices.tables.billingGroups.getBillingGroups.data;

export const getBillingGroupsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.billingGroups.getBillingGroupsFilters
		.status === Status.PENDING;
export const getBillingGroupsFiltersFail = (state) =>
	state.analyticsInvoices.tables.billingGroups.getBillingGroupsFilters
		.status === Status.FAIL;
export const getBillingGroupsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.billingGroups.getBillingGroupsFilters.data;
