export const UPDATE_BULK_M2M_ACCOUNT_REQUEST =
	'UPDATE_BULK_M2M_ACCOUNT_REQUEST';
export const UPDATE_BULK_M2M_ACCOUNT_SUCCESS =
	'UPDATE_BULK_M2M_ACCOUNT_SUCCESS';
export const UPDATE_BULK_M2M_ACCOUNT_FAIL = 'UPDATE_BULK_M2M_ACCOUNT_FAIL';
export const UPDATE_BULK_M2M_ACCOUNT_STARTED =
	'UPDATE_BULK_M2M_ACCOUNT_STARTED';
export const UPDATE_BULK_M2M_ACCOUNT_ENDED = 'UPDATE_BULK_M2M_ACCOUNT_ENDED';
export const UPDATE_BULK_M2M_ACCOUNT_ADD_FILTERED_SIM =
	'UPDATE_BULK_M2M_ACCOUNT_ADD_FILTERED_SIM';
export const UPDATE_BULK_M2M_ACCOUNT_ERROR_CLEAR =
	'UPDATE_BULK_M2M_ACCOUNT_ERROR_CLEAR';
