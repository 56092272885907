import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import Desktop from './Desktop';
import Mobile from './Mobile';

const mapDispatchToProps = (dispatch) => ({
	goToNewTicket: (id, type) => {
		dispatch(localizedRoute(`/support/ticketing/new-ticket/${type}/${id}`));
	},
	goToTicketing: () => {
		dispatch(localizedRoute('/support/ticketing/'));
	},
});

export const CardsDesktop = connect(null, mapDispatchToProps)(Desktop);
export const CardsMobile = connect(null, mapDispatchToProps)(Mobile);
