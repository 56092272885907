import { connect } from 'react-redux';

import {
	getBatchFileUploadErrors,
	getBatchFileUploadFail,
	getBatchFileUploadSuccess,
	getIsBatchFilesUploading
} from '../../../../redux/batchFiles/selectors';

import { getErrors } from '../../../Shared/redux/getStatusCode/selectors';

import StepThree from './StepThree';

const mapStateToProps = (state) => ({
	batchFileUploadSuccess: getBatchFileUploadSuccess(state),
	batchFileUploadFail: getBatchFileUploadFail(state),
	batchFileUploadRequest: getIsBatchFilesUploading(state),
	batchFileUploadErrors: getBatchFileUploadErrors(state),
	statusUploadErrors: getErrors(state)
});

export default connect(mapStateToProps)(StepThree);
