export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': '¿Borrar regla de automatización?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': '¿Borrar reglas de automatización seleccionadas?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': '¿Está seguro de que quiere borrar las reglas de automatización seleccionadas?',
	'AUTOMATION.CANCEL': 'Cancelar',
	'AUTOMATION.DELETE': 'Borrar',
	'AUTOMATION.ARE_YOU_SURE': '¿Está seguro?',
	'AUTOMATION.ACTIVE': 'Activado',
	'AUTOMATION.ACC_IF': 'Si',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Agregar otro umbral',
	'AUTOMATION.EDIT_RULE': 'Editar regla',
	'AUTOMATION.CREATE_NEW_RULE': 'Crear regla nueva',
	'AUTOMATION.PLATFORM': 'Plataforma',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Cuenta',
	'AUTOMATION.CATEGORY': 'Categoría',
	'AUTOMATION.RULE_STATE': 'Estado de regla',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Activo',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inactivo',
	'AUTOMATION.TRIGGER': 'Activador',
	'AUTOMATION.ACC_RULE_NAME': 'Nombre de regla',
	'AUTOMATION.SAVE': 'Guardar',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Eliminar filtros seleccionados',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Seleccionar filtros',
	'AUTOMATION.AUTOMATION': 'Automatización',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Crear nueva regla de automatización',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': '¿Está seguro de que quiere borrar la regla de automatización ',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Regla creada',
	'AUTOMATION.ADD_RULE_ERROR': 'Fallo al crear la regla de automatización. Vuelva a intentarlo nuevamente.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Regla editada',
	'AUTOMATION.EDIT_RULE_ERROR': 'Hubo un fallo al editar la regla de automatización. Vuelva a intentarlo nuevamente.',
	'AUTOMATION.THEN': 'Luego',
	'AUTOMATION.NUMBER_OF_DAYS': 'Cantidad de días',
	'AUTOMATION.NUMBER_OF_HOURS': 'Cantidad de horas (máx. 24 h)',
	'AUTOMATION.AT': 'A',
	'AUTOMATION.SIM_STATE': 'Estado de SIM',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'ID de plan de tarifas',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'ID de cuenta suscrita M2M',
	'AUTOMATION.CUSTOMER_ID': 'ID de cliente',
	'AUTOMATION.CUSTOM': 'Personalizado',
	'AUTOMATION.FILTER': 'FILTRO',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'No hay filtros disponibles.',
	'AUTOMATION.THRESHOLD': 'Umbral',
	'AUTOMATION.ZONE_MODEL': 'Modelo de zona',
	'AUTOMATION.ZONE_NAME': 'Nombre de Zona',
	'AUTOMATION.POOL_ID': 'ID de Grupo',
	'AUTOMATION.COUNTRY': 'País',
	'AUTOMATION.FROM_KEY': 'De',
	'AUTOMATION.TO_KEY': 'A',
	'AUTOMATION.FOLLOW_UP_WITH': 'Hacer seguimiento con',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': 'Se borró con éxito la regla de automatización',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': 'Se borraron con éxito las alertas de automatización',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': 'Hubo un fallo al borrar la regla de automatización. Vuelva a intentarlo nuevamente.',
	'AUTOMATION.PLATFORMS': 'Plataformas',
	'AUTOMATION.CATEGORIES': 'Categorías',
	'AUTOMATION.TRIGGERS': 'Activadores',
	'AUTOMATION.M2MACCOUNTS': 'Cuentas M2M',
	'AUTOMATION.HOURS_USING': 'En las últimas 24 h de uso',
	'AUTOMATION.LOCATIONUPDATE': 'Actualización de ubicación',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Actualización de autenticación',
	'AUTOMATION.ZONE': 'ZONA',
	'AUTOMATION.ACCOUNT_ID': 'ID de cuenta',
	'AUTOMATION.ACTION': 'Acción',
	'AUTOMATION.DCP': 'DCP',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'SMS',
	'AUTOMATION.USAGE': 'Uso',
	'AUTOMATION.SIM_PROVISIONING': 'Suministro de SIM',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Monitoreo de suscripción',
	'AUTOMATION.USAGE_MONITORING': 'Monitoreo de uso',
	'AUTOMATION.NETWORK_MONITORING': 'Monitoreo de red',
	'AUTOMATION.PRICING_AUTOMATION': 'Precios de automatización',
	'AUTOMATION.SECURITY_MONITORING': 'Monitoreo de seguridad',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'Cambio en plan de tarifas de SIM',
	'AUTOMATION.SIM_STATE_CHANGE': 'Cambio en estado de SIM',
	'AUTOMATION.SMS_USAGE': 'Uso de SMS',
	'AUTOMATION.SIM_DATA_LIMIT': 'Límite de datos de SIM',
	'AUTOMATION.SIM_EXPIRATION': 'Vencimiento de SIM',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Ciclo hasta la fecha de uso de datos',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Ciclo hasta la fecha de uso de voz',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': 'Se superó el uso de datos en las últimas 24 h',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': 'Cantidad de sesiones de conexión en las últimas 24 h',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Uso de datos combinados mensual',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Uso de SMS combinado mensual',
	'AUTOMATION.NO_CONNECTION': 'Sin conexión',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Uso de voz en las últimas 24 h',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'Registrar en una zona',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Demasiadas conexiones',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'Excedente de SMS MO por período',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'Uso inferior de SMS MO por período',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'Excedente de SMS MT por período',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'Uso inferior de SMS MT por período',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'Excedente de GPRS a largo plazo',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'Excedente de GPRS a corto plazo',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'Primera sesión de datos establecida',
	'AUTOMATION.IMEI_CHANGED': 'Se cambió el IMEI',
	'AUTOMATION.NETWORK_REGISTRATION': 'Registro de red',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Registro de red en una zona',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'Cambio en el campo personalizado de la SIM',
	'AUTOMATION.SIM_STATUS': 'Estado de la SIM',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Cambió el plan de tarifas',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'Cambio en el país de la SIM',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'El plan de tarifas de la SIM está por vencer',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Sesión de datos terminada',
	'AUTOMATION.DATA_SESSION_STARTED': 'Comenzó la sesión de datos',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Uso de datos en un país',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': 'Conteo de sesión de datos - Ciclo de facturación',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Uso de datos en una zona',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'Ciclo de facturación sin conexión',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': 'Cantidad de sesiones de conexión en las últimas 24 h',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'Conteo de SMS en un país',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'Uso de SMS en una zona',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'Uso de SMS en las últimas 24 h',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Uso total de datos - 24 h',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': 'Uso total de datos - Ciclo de facturación',
	'AUTOMATION.SMS_MO_RECEIVED': 'SMS MO recibido',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': 'Ciclo hasta la fecha de uso de voz en una zona',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'Finalización del plan de SIM para voz',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'Finalización del plan de SIM para datos',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'Acción de lista blanca de IMEI',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'Cambio en cuenta de SIM',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Uso reciente de SMS (24 horas)',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': 'Registro de red en una zona',
	'AUTOMATION.MSISDN_CHANGE': 'Cambio de MSISDN',
	'AUTOMATION.IMEI_CHANGE': 'Cambio de IMEI',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': 'Ciclo hasta la fecha de uso de datos en una zona',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': 'Se transfirió una terminal de',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': 'Cualquier terminal del ciclo actual usa',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Cualquier terminal de un plan prepagado de tarifas completa su término prepagado',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': 'La red detecta un cambio en la IMEI de la terminal',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': 'El sistema detecta un cambio de MSISDN en una terminal',
	'AUTOMATION.INITIATE_OTA_SWAP': 'Iniciar intercambio de OTA',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': 'Cualquier terminal envía o recibe más de',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': 'Cualquier SIM experimenta el cambio de uno de los campos personalizados',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': 'No se encontró la IMEI de la terminal en la lista blanca',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': 'Cualquier terminal del ciclo actual supera',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': 'Se recibe un SMS MO para el código abreviado',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'Conteo total de SMS - Ciclo de facturación',
	'AUTOMATION.1_HOUR': '1 hora',
	'AUTOMATION.2_HOURS': '2 horas',
	'AUTOMATION.3_HOURS': '3 horas',
	'AUTOMATION.4_HOURS': '4 horas',
	'AUTOMATION.5_HOURS': '5 horas',
	'AUTOMATION.6_HOURS': '6 horas',
	'AUTOMATION.7_HOURS': '7 horas',
	'AUTOMATION.8_HOURS': '8 horas',
	'AUTOMATION.9_HOURS': '9 horas',
	'AUTOMATION.10_HOURS': '10 horas',
	'AUTOMATION.11_HOURS': '11 horas',
	'AUTOMATION.12_HOURS': '12 horas',
	'AUTOMATION.13_HOURS': '13 horas',
	'AUTOMATION.14_HOURS': '14 horas',
	'AUTOMATION.15_HOURS': '15 horas',
	'AUTOMATION.16_HOURS': '16 horas',
	'AUTOMATION.17_HOURS': '17 horas',
	'AUTOMATION.18_HOURS': '18 horas',
	'AUTOMATION.19_HOURS': '19 horas',
	'AUTOMATION.20_HOURS': '20 horas',
	'AUTOMATION.21_HOURS': '21 horas',
	'AUTOMATION.22_HOURS': '22 horas',
	'AUTOMATION.23_HOURS': '23 horas',
	'AUTOMATION.24_HOURS': '24 horas',
	'AUTOMATION.25_HOURS': '25 horas',
	'AUTOMATION.26_HOURS': '26 horas',
	'AUTOMATION.27_HOURS': '27 horas',
	'AUTOMATION.28_HOURS': '28 horas',
	'AUTOMATION.29_HOURS': '29 horas',
	'AUTOMATION.30_HOURS': '30 horas',
	'AUTOMATION.31_HOURS': '31 horas',
	'AUTOMATION.32_HOURS': '32 horas',
	'AUTOMATION.33_HOURS': '33 horas',
	'AUTOMATION.34_HOURS': '34 horas',
	'AUTOMATION.35_HOURS': '35 horas',
	'AUTOMATION.36_HOURS': '36 horas',
	'AUTOMATION.37_HOURS': '37 horas',
	'AUTOMATION.38_HOURS': '38 horas',
	'AUTOMATION.39_HOURS': '39 horas',
	'AUTOMATION.40_HOURS': '40 horas',
	'AUTOMATION.41_HOURS': '41 horas',
	'AUTOMATION.42_HOURS': '42 horas',
	'AUTOMATION.43_HOURS': '43 horas',
	'AUTOMATION.44_HOURS': '44 horas',
	'AUTOMATION.45_HOURS': '45 horas',
	'AUTOMATION.46_HOURS': '46 horas',
	'AUTOMATION.47_HOURS': '47 horas',
	'AUTOMATION.48_HOURS': '48 horas',
	'AUTOMATION.LAST_24_HOURS': 'Últimas 24 h',
	'AUTOMATION.CYCLE_TO_DATE': 'Ciclo hasta la fecha',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'El plan de tarifas prepagado de la SIM supera',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'El uso de datos supera el límite',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'El uso de datos se acerca al límite',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'El plan de tarifas prepagado de las SIM está entre',
	'AUTOMATION.MO_MT': 'MO/MT',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'El uso de voz supera el límite',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'El uso de voz se acerca al límite',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'La SIM estableció más de',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'La SIM estableció menos de',
	'AUTOMATION.EXCEEDES': 'Superado',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': 'La SIM no estableció conexión de datos en las últimas N h',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'La SIM se registra a la red de la zona',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'La SIM estableció más que conexiones en el ciclo actual',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': 'La red detecta un equipo nuevo',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'El uso en el ciclo hasta la fecha supera',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'El uso en el ciclo hasta la fecha se acerca',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': 'La red detecta un cambio en ...',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'La SIM se registró a una red nueva por primera vez',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'La terminal se registró en la zona',
	'AUTOMATION.CUSTOM_FIELD_1': 'Campo personalizado 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Campo personalizado 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Campo personalizado 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Campo personalizado 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Campo personalizado 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Campo personalizado 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Campo personalizado 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Campo personalizado 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Campo personalizado 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Campo personalizado 10',
	'AUTOMATION.CHANGED': 'Cambiado',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Cambio en plan de tarifas',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': 'La cant. de sesiones de datos para una SIM supera',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'Sesión de datos de una SIM comienza',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'Sesión de datos de una SIM termina',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': 'Uso de datos en un país supera',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'Uso de datos en A supera',
	'AUTOMATION.EXCEEDS': 'Superado',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': 'Uso de SMS facturable en cualquier grupo de plan de tarifas alcanza',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': 'Cualquier SIM no ha establecido conexión de datos en el último ciclo de facturación',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'Una SIM tiene más de',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'Conteo de SMS en un país supera',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'Conteo de SMS supera el límite',
	'AUTOMATION.1_WEEK': '1 semana',
	'AUTOMATION.2_WEEKS': '2 semanas',
	'AUTOMATION.3_WEEKS': '3 semanas',
	'AUTOMATION.4_WEEKS': '4 semanas',
	'AUTOMATION.SEND_EMAIL': 'Enviar correo electrónico',
	'AUTOMATION.SEND_SMS': 'Enviar SMS',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': 'Cambiar permiso de red del equipo',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': 'Cambiar estado de SIM del equipo',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': 'Cambiar plan de tarifas del equipo',
	'AUTOMATION.PUSH_API': 'Push API',
	'AUTOMATION.COMBINED': 'Combinado',
	'AUTOMATION.MO': 'MO',
	'AUTOMATION.MT': 'MT',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Conexiones en plazo de 24 h',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Cambiar permiso de red',
	'AUTOMATION.CHANGE_SIM_STATUS': 'Cambiar estado de SIM',
	'AUTOMATION.SMS_MESSAGES': 'Mensajes SMS',
	'AUTOMATION.HOURS': 'Horas',
	'AUTOMATION.MIN': 'min.',
	'AUTOMATION.MINUTES': 'Minutos',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Cambiar plan de tarifas',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': 'Conexiones en el ciclo actual',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Volver al plan de tarifas anterior',
	'AUTOMATION.MODIFIED_DELETED': 'Modificado/Borrado',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': 'El plan de tarifas de las SIM vencerá en un día',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': 'Cant. de uso de SMS incluido en su grupo',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': 'Cant. de sesiones de datos en las últimas 24 h',
	'AUTOMATION.MSGS': 'MSJ.',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'Conteo de SMS en la zona supera',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': 'Cant. de sesiones de datos en las últimas 24 h',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Horas de consumo',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Volver al estado de SIM anterior',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Días tras el evento activado',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'Final del ciclo de facturación actual',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': 'La hora UTC tras el evento activado',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': 'Volver al permiso de red anterior',
	'AUTOMATION.GMNA': 'GM Norteamérica',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Empresa',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM Norteamérica',
	'AUTOMATION.ENTERPRISE': 'Empresa',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'Cambiar estado de SIM',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'Uso de SMS en las últimas 24 h',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': 'El uso de SMS en las últimas 24 h supera',
	// 'Activadores',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': 'Uso de SMS MO (originado en móvil) - 24 horas',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': 'Uso de SMS MO (originado en móvil) - En una zona',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': 'Uso de SMS MO (originado en móvil) - Plan de grupo',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': 'Uso de SMS MT (terminado en móvil) - 24 horas',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': 'Uso de SMS MT (terminado en móvil) - Ciclo de facturación',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': 'Uso de SMS MT (terminado en móvil) - En una zona',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': 'Uso de SMS MT (terminado en móvil) - Plan de grupo',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'El día después de que caduque el plan de tarifas de la SIM',
	'AUTOMATION.BYTES': 'Bytes',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Agregar texto personalizado al mensaje SMS (opcional)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': 'Texto de mensaje personalizado (lím. 140 caracteres)',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'Finalización del plan de SIM',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Si cualquier SIM de un plan de tarifas prepagado completa su término prepagado',
	// new automation
	'AUTOMATION.TITLE': 'Automatización',
	'AUTOMATION.RULES_TAB_NAME': 'Reglas de automatización',
	'AUTOMATION.ALERTS_TAB_NAME': 'Alertas de automatización',
	'AUTOMATION_RULES.YES': 'Sí',
	'AUTOMATION_RULES.NO': 'No',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'No hay reglas de automatización disponibles',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': 'No hay reglas de automatización disponibles para mostrarle ahora',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'No hay alertas de automatización disponibles',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': 'No hay alertas de automatización disponibles para mostrarle ahora',
	//  rules table headers
	'AUTOMATION_RULES.ID': 'ID',
	'AUTOMATION_RULES.RULE_NAME': 'Nombre de regla',
	'AUTOMATION_RULES.PLATFORM': 'Plataforma',
	'AUTOMATION_RULES.CATEGORY': 'Categoría',
	'AUTOMATION_RULES.TRIGGER': 'Activador',
	'AUTOMATION_RULES.ACTIVE': 'Activado',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
	'AUTOMATION_RULES.DATE_CREATED': 'Fecha de creación',
	'AUTOMATION_RULES.USER': 'Usuario',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'Estado de implementación',
	// alerts table headers
	'AUTOMATION_ALERTS.ID': 'ID',
	'AUTOMATION_ALERTS.RULE_NAME': 'Nombre de regla',
	'AUTOMATION_ALERTS.PLATFORM': 'Plataforma',
	'AUTOMATION_ALERTS.CATEGORY': 'Categoría',
	'AUTOMATION_ALERTS.TRIGGER': 'Activador',
	'AUTOMATION_ALERTS.ACTIVE': 'Activado',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
	'AUTOMATION_ALERTS.DATE': 'Fecha',
	'AUTOMATION_ALERTS.USER': 'Usuario',
	'AUTOMATION_ALERTS.ACTION': 'Acción',
	// rules
	'AUTOMATION.ACTIVATE': 'Activar',
	'AUTOMATION.DEACTIVATE': 'Desactivar',
	'AUTOMATION.RULE_CREATED_OR_EDITED': 'Ha seleccionado una regla de automatización que requiere un proceso extenso y no se puede configurar inmediatamente.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': 'Se activó con éxito la regla de automatización',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'No hubo éxito al activar la regla de automatización',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': 'Se desactivó con éxito la regla de automatización',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': 'No hubo éxito al desactivar la regla de automatización',
	'AUTOMATION.SHORT_CODE': 'Código corto',
	'AUTOMATION.BASE_RATE_PLAN': 'Plan de tarifas base',
	'AUTOMATION.RATE_PLAN_TYPE': 'Tipo de plan de tarifas',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'Estado de implementación',
	'AUTOMATION.YES': 'Sí',
	'AUTOMATION.NO': 'No',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Cambio de estado de la regla',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': '¿Está seguro de que desea hacer esto?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': "Está a punto de cambiar el {currentState} estado de la regla a '{selectedState}'",
	'AUTOMATION.REQUEST_DELETION_TEXT': 'Está a punto de solicitar borrar una regla',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Borrado de una regla',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': 'Está a punto de borrar la regla{ruleName}',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Solicitud de borrar una regla de automatización',
	'AUTOMATION.REQUEST_DELETION': 'Borrado de una solicitud',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': "Tuvo éxito al cambiar el estado de implementación a 'Recibido'",
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': "Tuvo éxito al cambiar el estado de implementación a 'Implementado'",
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': "Tuvo éxito al cambiar el estado de implementación a 'En conflicto'",
	'AUTOMATION.SET_RULE_STATE_FAIL': 'Se produjo un error al cambiar el estado de implementación. Inténtelo nuevamente.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': 'Tuvo éxito al solicitar que se borrara la regla.',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': 'Se produjo un error al solicitar que se borrara la regla.',
	'AUTOMATION.RULE_CHANGE': 'Cambio de una regla de automatización',
	'AUTOMATION.RULE_CREATE': 'Creación de una regla de automatización',
	'AUTOMATION.ACCEPT': 'Aceptar',
	'AUTOMATION.MANUAL_RULE_DELAY': 'Habrá retraso en la implementación de esta regla.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': 'Tardará un tiempo en ejecutarse esta regla.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': 'Soy consciente del retraso del proceso de implementación.',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Seleccionar acción:',
	// statuses
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'Pendiente de borrado',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Implementado',
	'AUTOMATION.RULE_STATUS_PENDING': 'Pendiente',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'En conflicto',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Recibido',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'No corresponde.',
	//  'Modelo de zona',
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'Zona estándar de ATT 1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'Zona estándar de ATT 2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'Zona estándar de ATT 3',
	//  'Nombre de Zona',
	'AUTOMATION.US_DOMESTIC': 'EE.UU. nacional',
	'AUTOMATION.EU': 'UE'
};
