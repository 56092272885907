import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import Reports from './Reports';

import {
	editCompanyReports,
	getCompanyReports
} from '../../redux/companyReports/actions';
import {
	editCompanyReportsRequest,
	getCompanyReportsSuccess,
	getCompanyReportsRequest,
} from '../../redux/companyReports/selectors';

import {
	getAccountSuccess,
	getAccountRequest,
	getAccountFail
} from '../../redux/accounts/selectors';
import { getAccount, getAccountInit } from '../../redux/accounts/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => {
	let initialValues = {};
	if (
		getCompanyReportsSuccess(state) &&
		getCompanyReportsSuccess(state).resultList
	) {
		getCompanyReportsSuccess(state).resultList.forEach((item) => {
			initialValues = {
				...initialValues,
				[`${item.id}_iotc`]: item.iot_c,
				[`${item.id}_sfg`]: item.sfg,
				[`${item.id}_sfgMailbox`]: item.sfg_mailbox,
			};
		});
	}

	return {
		companyReports: getCompanyReportsSuccess(state),
		companyReportsGetRequest: getCompanyReportsRequest(state),
		companyReportsEditRequest: editCompanyReportsRequest(state),
		initialValues,
		account: getAccountSuccess(state),
		accountFail: getAccountFail(state),
		accountRequest: getAccountRequest(state),
		user: getUser(state),
		formValues: getFormValues('companyReportsForm')(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCompanyReports: (params) => dispatch(getCompanyReports(params)),
	editCompanyReports: (data) => dispatch(editCompanyReports(data)),
	pushBack: (url) => dispatch(localizedRoute(url)),
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
