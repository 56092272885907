import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import DSLastStep from '../../../../Steps';
import Loading from '../../../../../lib/DigitalComponents/Loader';
import Button from '../../../../../lib/DigitalComponents/Button';

import { closeModal } from '../../../../../redux/modals/batchFileUploadModal/actions';
import { clearUploadErrors } from '../../../../../redux/batchFiles/actions';
import { getErrors } from '../../../../Shared/redux/getStatusCode/selectors';
import { clearError } from '../../../../Shared/redux/getStatusCode/actions';
import {
	getBatchFileUploadErrors,
	getBatchFileUploadFail,
	getBatchFileUploadSuccess,
	getIsBatchFilesUploading
} from '../../../../../redux/batchFiles/selectors';

import styles from './StepSecond.scss';

class StepThird extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
    const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
    const { context, isBatchFilesUploading } = this.props;
		if (
			context &&
			!isEqual(
				isBatchFilesUploading,
				prevProps.isBatchFilesUploading
			)
		) {
			this.setFooter(context);
		}
	}

	renderLastStepSuccess = () => {
    const { batchFileUploadSuccess } = this.props;
		if (batchFileUploadSuccess) {
			return (
				<DSLastStep
					data-spec="last success step"
					title={
						<FormattedMessage
							id="BATCH_FILE_UPLOAD_SUCCESS"
							defaultMessage="Batch files successfully received!"
						/>
					}
					msg={
						<FormattedMessage
							id="BATCH_FILE_UPLOAD_SUCCESS_MESSAGE"
							defaultMessage="Changes are pending at the system completes your updates"
						/>
					}
				/>
			);
		}
	};

	renderLastStepError = () => {
    const {
			batchFileUploadFail,
			batchFileUploadErrors,
			statusUploadErrors
		} = this.props;
		if (batchFileUploadFail) {
			// check if errors and save them to list
			const errors = batchFileUploadErrors || null;

			const statusUploadErrorMessages = statusUploadErrors || null;
			return (
				<DSLastStep
					data-spec="last step error"
					type="error"
					statusUploadErrors={statusUploadErrorMessages}
					errors={errors}
					title={
						<FormattedMessage
							id="BATCH_FILE_SYSTEM_ERROR"
							defaultMessage="Batch file system error!"
						/>
					}
					msg={
						<FormattedMessage
							id="BATCH_FILE_SYSTEM_ERROR_MESSAGE"
							defaultMessage="There was a problem processing your updates. Please try again later."
						/>
					}
					className={styles.last_step}
				/>
			);
		}
	};

	setFooter = (context) => {
    const {
			closeBatchUploadModal,
			isBatchFilesUploading,
			disabled
		} = this.props;
		const footer = (
			<>
				<Button
					dataSpec="done-upload"
					variant="success"
					onClick={() => closeBatchUploadModal()}
					disabled={disabled || isBatchFilesUploading}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
				/>
			</>
		);
		context.updateContext({ footer });
	};

	render() {
    const { isBatchFilesUploading } = this.props;
		return (
			<div data-spec="third-step-modal-body" className="batch-wrapper">
				<div
					data-spec="ds-modal-body"
					className={styles.modal_body_batch_last_step}
				>
					{isBatchFilesUploading && (
						<FormattedMessage
							id="ENDPOINTS.PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES"
							defaultMessage="Please wait. This may take a few minutes, depending on the file size."
						>
							{(formattedValue) => (
								<Loading data-spec="loading" msg={formattedValue} />
							)}
						</FormattedMessage>
					)}

					{!isBatchFilesUploading && this.renderLastStepSuccess()}

					{!isBatchFilesUploading && this.renderLastStepError()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
		batchFileUploadSuccess: getBatchFileUploadSuccess(state),
		batchFileUploadFail: getBatchFileUploadFail(state),
		isBatchFilesUploading: getIsBatchFilesUploading(state),
		batchFileUploadErrors: getBatchFileUploadErrors(state),
		statusUploadErrors: getErrors(state)
	});

const mapDispatchToProps = (dispatch) => ({
		closeBatchUploadModal: () => {
			dispatch(clearUploadErrors());
			dispatch(clearError());
			return dispatch(closeModal());
		}
  });

const { bool, func, array, object } = PropTypes;

StepThird.propTypes = {
	batchFileUploadSuccess: bool,
	batchFileUploadFail: bool,
	isBatchFilesUploading: bool,
	batchFileUploadErrors: array,
	statusUploadErrors: array,
	closeBatchUploadModal: func,
	context: object,
	disabled: bool
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepThird);
