import { Status } from '../../../../utils/constants';

export const getOtherChargesRequest = (state) =>
	state.analyticsInvoices.tables.otherCharges.getOtherCharges.status ===
	Status.PENDING;
export const getOtherChargesFail = (state) =>
	state.analyticsInvoices.tables.otherCharges.getOtherCharges.status ===
	Status.FAIL;
export const getOtherChargesSuccess = (state) =>
	state.analyticsInvoices.tables.otherCharges.getOtherCharges.data;

export const getOtherChargesFiltersRequest = (state) =>
	state.analyticsInvoices.tables.otherCharges.getOtherChargesFilters.status ===
	Status.PENDING;
export const getOtherChargesFiltersFail = (state) =>
	state.analyticsInvoices.tables.otherCharges.getOtherChargesFilters.status ===
	Status.FAIL;
export const getOtherChargesFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.otherCharges.getOtherChargesFilters.data;
