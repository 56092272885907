import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import AddSubAccount from './AddSubAccount';

import { addSubAccount } from '../../redux/accounts/actions';
import { getCompany, getCompanyInit } from '../../redux/companies/actions';
import {
	getCompanyRequest,
	getCompanyFail,
	getCompanySuccess
} from '../../redux/companies/selectors';
import { addSubAccountRequest } from '../../redux/accounts/selectors';

const mapStateToProps = (state) => ({
	companyRequest: getCompanyRequest(state),
	companyFail: getCompanyFail(state),
	company: getCompanySuccess(state),
	addSubAccountRequest: addSubAccountRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	getCompany: (params) => dispatch(getCompany(params)),
	getCompanyInit: () => dispatch(getCompanyInit()),
	addSubAccount: (params) => dispatch(addSubAccount(params)),
	pushBack: (url) => dispatch(localizedRoute(url))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddSubAccount);
