import { createAction } from 'redux-actions';
import { changeNetworkEntitlement } from '../../services/EndpointsService';
import { sameDateFormatter } from '../../../../utils/constants';

import {
	UPDATE_BULK_NETWORK_ENTITLEMENT_REQUEST,
	UPDATE_BULK_NETWORK_ENTITLEMENT_SUCCESS,
	UPDATE_BULK_NETWORK_ENTITLEMENT_FAIL,
	UPDATE_BULK_NETWORK_ENTITLEMENT_STARTED,
	UPDATE_BULK_NETWORK_ENTITLEMENT_ENDED,
	UPDATE_BULK_NETWORK_ENTITLEMENT_ADD_FILTERED_SIM,
	UPDATE_BULK_NETWORK_ENTITLEMENT_ERROR_CLEAR
} from './constants';

const updateBulkNetworkEntitlementStatusRequest = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_REQUEST
);
const updateBulkNetworkEntitlementStatusSuccess = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_SUCCESS
);
const updateBulkNetworkEntitlementStatusFail = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_FAIL
);
const updateBulkNetworkEntitlementAddFilteredSims = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_ADD_FILTERED_SIM
);
export const updateBulkNetworkEntitlementStatusStarted = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_STARTED
);
export const updateBulkNetworkEntitlementStatusEnded = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_ENDED
);
export const updateBulkNetworkEntitlementErrorClear = createAction(
	UPDATE_BULK_NETWORK_ENTITLEMENT_ERROR_CLEAR
);

export const updateBulkNetworkEntitlement = (data, count = 0) => async (
	dispatch
) => {
	if (count >= data.selectedSims.length) {
		dispatch(updateBulkNetworkEntitlementStatusEnded());
	} else {
		const scheduledDate = data.data.startDate;
		const updateData = {
			id: data.selectedSims[count].endpointName,
			data: {
				networkEntitlementId: `${data.networkEntitlement.value}`,
				startDate: scheduledDate
			}
    };

		if (
			data.selectedSims[count].networkEntitlementId ===
			data.networkEntitlement.value
		) {
			dispatch(
				updateBulkNetworkEntitlementAddFilteredSims({
					id: data.selectedSims[count].endpointName
				})
			);
			dispatch(updateBulkNetworkEntitlement(data, count + 1));
		} else {
			dispatch(updateBulkNetworkEntitlementStatusRequest(updateData));
			setTimeout(() => {
				changeNetworkEntitlement(
					data.selectedSims[count].endpointName,
					data.networkEntitlement.value,
					sameDateFormatter(scheduledDate) ? undefined : scheduledDate
				)
					.then(() => {
						dispatch(updateBulkNetworkEntitlementStatusSuccess(updateData));
						dispatch(updateBulkNetworkEntitlement(data, count + 1));
					})
					.catch(() => {
						dispatch(updateBulkNetworkEntitlementStatusFail(updateData));
						dispatch(updateBulkNetworkEntitlement(data, count + 1));
					});
			}, 1000);
		}
	}
};
