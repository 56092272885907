import React from 'react';
import { string, array, bool } from 'prop-types';
import { Field } from 'redux-form';

import Select from '../DropdownSelectNew';
import Input from '../FieldGroup';

import {
	required,
	onlyNumbers
} from '../../../utils/validators';

import styles from './PhoneSelector.scss';

const PhoneSelectorPropTypes = {
	label: string,
	options: array,
  name: string,
  isRequired: bool
};

const PhoneSelectorDefaultProps = {
	label: '',
	options: [],
  name: '',
  isRequired: false
};

export default function PhoneSelector({ label, options, name, isRequired }) {
	return (
		<>
			<label className={styles.label}>{label}</label>
			<div className={styles.mask_field_wrapper}>
				<div className={styles.component_wrapper_left}>
					<Field
						component={Select}
						name={`${name}.mask`}
						options={options.map((item) => ({
							label: item.value,
							value: item.value
						}))}
						styles={{
							control: (base) => ({
								...base,
								minHeight: 30,
								borderRadius: '6px 0 0 6px !important',
								borderRight: '0 !important'
							})
						}}
						isSearchable
						validate={[isRequired && required]}
					/>
				</div>
				<div className={styles.component_wrapper_right}>
					<Field
						component={Input}
						placeholder="eg. (555) 555-5555"
						name={`${name}.phoneNumber`}
						className={styles.custom_input}
						dataSpec="phone-number"
						validate={[isRequired && required, onlyNumbers]}
					/>
				</div>
			</div>
		</>
	);
}

PhoneSelector.propTypes = PhoneSelectorPropTypes;
PhoneSelector.defaultProps = PhoneSelectorDefaultProps;
