import React, { PureComponent } from 'react';

const Functionalities = () => (WrappedComponent) => {
	class FunctionalitiesComponent extends PureComponent {
		render() {
			return (
				<WrappedComponent
					data-spec="functionalities"
					{...this.state}
					{...this.props}
				/>
			);
		}
	}
	return FunctionalitiesComponent;
};

export default Functionalities;
