import globalAction from '../../../../redux/global/globalAction';

import { GET_RATE_PLANS, GET_RATE_PLANS_FILTERS } from './constants';

import {
	getRatePlans as getRatePlansService,
	getRatePlansFilters as getRatePlansFiltersService
} from '../../services/RatePlansService';

/** ************ get rate plans ************* */
export const getRatePlans = (id, params = {}) =>
	globalAction(GET_RATE_PLANS, getRatePlansService, { id, params });

/** ************ get rate plans filters ************* */
export const getRatePlansFilters = (id, params = {}) =>
	globalAction(GET_RATE_PLANS_FILTERS, getRatePlansFiltersService, {
		id,
		params
	});
