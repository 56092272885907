export default {
	'RELEASE_NOTES.RELEASE_NOTES': 'Release Notes',
	'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO':
		'IoT Console is updated frequently.',
	'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE':
		'IoT Console is updated frequently to ensure most optimal operation and offer extended features and capabilities and user experience improvements. Below are the updates introduced in this release.',
	'RELEASE_NOTES.RELEASE_VERSION': 'Release Version',
	'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'New Platform Features',
	'RELEASE_NOTES.DEPRECATIONS': 'Deprecations',
	'RELEASE_NOTES.DEPRECATION': 'Deprecation',
	'RELEASE_NOTES.KNOWN_ISSUES': 'Known Issues',
	'RELEASE_NOTES.NEW_FEATURES': 'New Features',
	'RELEASE_NOTES.IMPROVEMENTS': 'Improvements',
	'RELEASE_NOTES.FEATURE': 'Feature',
	'RELEASE_NOTES.IMPROVEMENT': 'Improvement',
	'RELEASE_NOTES.ISSUE': 'Issue',
	'RELEASE_NOTES.FIXED': 'Fixed',
	'RELEASE_NOTES.ENHANCED': 'Enhanced',

	'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Add another Deprication',
	'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Add another Platform Feature',
	'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Add another Feature',
	'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Add another Known Issue',
	'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Add another Improvement',

	'RELEASE_NOTES.PUBLISH': 'Publish',
	'RELEASE_NOTES.SAVE': 'Save',
	'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Publish Release Notes',
	'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE':
		'You are about to publish Release Notes {releaseVersion}. Are you sure you wish to do this?',
	'RELEASE_NOTES.CANCEL': 'Cancel'
};
