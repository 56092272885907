export default {
	'BATCH_HISTORY.LINK': 'Batch-Verlauf',
	'BATCH_HISTORY.TITLE': 'Batch-Verlauf',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Batch-Aktionen',
	'BATCH_HISTORY.BATCH_REPORTS': 'Batch-Berichte',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'Keine Batch-Aktionen verfügbar',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': 'Für diese Tabelle sind derzeit keine Batch-Aktionen zur Ansicht verfügbar',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'Keine Batch-Berichte verfügbar',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': 'Für diese Tabelle sind derzeit keine Batch-Berichte zur Ansicht verfügbar',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Batch-Dateiname',
	'BATCH_HISTORY.DATE_UPLOADED': 'Upload-Datum',
	'BATCH_HISTORY.STATUS': 'Status',
	'BATCH_HISTORY.REPORT_NAME': 'Bericht',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'BATCH_HISTORY.DATE': 'Datum',
	'BATCH_HISTORY.M2M_PLATFORM': 'M2M-Plattform',
	'BATCH_HISTORY.FREQUENCY': 'Frequenz',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Abgesendet',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Frequenz',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'Findet statt',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Abgeschlossen',
  'BATCH_HISTORY.ERROR_STATUS': 'Fehler',
  'BATCH_HISTORY.CANCELLED_STATUS': 'Abgebrochen',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': 'Die Datei wurde erfolgreich zur Verarbeitung hochgeladen',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': 'Die Datei wurde erfolgreich hochgeladen, Verarbeitung ist eingeplant',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Verarbeitung findet statt',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'Die Datei wurde erfolgreich verarbeitet',
  'BATCH_HISTORY.ERROR_TOOLTIP': 'Bei der Verarbeitung ist ein Fehler aufgetreten',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': 'QuickInfo abgebrochen',
};
