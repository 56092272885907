import { handleActions } from 'redux-actions';

import { SET_DATA_OFFSET_SUCCESS, SET_PAGINATION_INIT } from '../constants';

const initialState = {
	data: 0,
};

export default handleActions(
	{
		[SET_PAGINATION_INIT]: (state) => ({
			...state,
			data: 0
		}),
		[SET_DATA_OFFSET_SUCCESS]: (state, action) => ({
			...state,
			data: action.payload
		})
	},
	initialState
);
