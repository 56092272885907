import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import CustomFieldLabels from './CustomFieldLabels';
import { getCustomFieldLabels } from '../../redux/customFieldLabels/getCustomFieldLabels/actions';
import {
	getCustomFieldLabelsSuccess,
	getCustomFieldLabelsRequest
} from '../../redux/customFieldLabels/getCustomFieldLabels/selectors';
import { editCustomFieldLabelsRequest, editCustomFieldLabelsSuccess } from '../../redux/customFieldLabels/editCustomFieldLabels/selectors';
import { editCustomFieldLabels } from '../../redux/customFieldLabels/editCustomFieldLabels/actions';

const mapStateToProps = (state) => ({
	initialValues: isEmpty(getCustomFieldLabelsSuccess(state))
		? null
		: getCustomFieldLabelsSuccess(state),
	customFieldLabelsIsNull: isEmpty(getCustomFieldLabelsSuccess(state)),
	customFieldLabelsRequest: getCustomFieldLabelsRequest(state),
	editCustomFieldLabelsRequest: editCustomFieldLabelsRequest(state),
	editCustomFieldLabelsSuccess: editCustomFieldLabelsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getCustomFieldLabels: () => dispatch(getCustomFieldLabels()),
	editCustomFieldLabels: (values) => dispatch(editCustomFieldLabels(values))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomFieldLabels);
