import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import RatePlanDetails from './RatePlanDetails';

import {
	getRatePlan,
	getRatePlanInit
} from '../../redux/ratePlans/actions';

import {
	getRatePlanSuccess,
	getRatePlanRequest
} from '../../redux/ratePlans/selectors';

import {
	getAccountRequest,
	getAccountFail,
	getAccountSuccess
} from '../../redux/accounts/selectors';

import { getAccount, getAccountInit } from '../../redux/accounts/actions';

const mapStateToProps = (state) => ({
	ratePlanRequest: getRatePlanRequest(state),
	ratePlan: getRatePlanSuccess(state),
	accountRequest: getAccountRequest(state),
	accountFail: getAccountFail(state),
	account: getAccountSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getRatePlan: (params) => dispatch(getRatePlan(params)),
	ratePlanInit: () => dispatch(getRatePlanInit()),
	pushBack: (url) => dispatch(localizedRoute(url)),
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RatePlanDetails);
