import React from 'react';

function Php() {
	return (
		<div data-spec="php-asset">
			<pre>
				{`
        <?php

          $request = new HttpRequest();
          $request->setUrl('https://cisiot.att.com/EAI/oauth/token');
          $request->setMethod(HTTP_METH_POST);

          $request->setHeaders(array(
          'Authorization' => 'Basic YOUR_ENCODED_CREDENTIALS',
          'Content-Type' => 'application/x-www-form-urlencoded'
          ));

          $request->setContentType('application/x-www-form-urlencoded');
          $request->setPostFields(array(
          'username' => 'USERNAME',
          'password' => 'PASSWORD',
          'grant_type' => 'password'
          ));

          try {
          $response = $request->send();

          echo $response->getBody();
          } catch (HttpException $ex) {
          echo $ex;
          }
        ?>
      `}
			</pre>
		</div>
	);
}

export default Php;
