import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import Button from '../../../../lib/DigitalComponents/Button';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import styles from './ResetPasswordModal.scss';

const Icon = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

class RestartPasswordModal extends PureComponent {
	resetPassword = () => {
		const { resetPassword, data, system, onClose, myProfile } = this.props;
		const params = {
			id: data.id,
			system,
			myProfile
		};
		resetPassword(params);
		onClose();
	};

	render() {
		const { show, onClose } = this.props;
		return (
			<Modal data-spec="confirmation-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.RESET_USER_PASSWORD"
							defaultMessage="Reset User Password"
						/>
					}
					closeModal={onClose}
				/>
				<ModalBody className={styles.modal__body}>
					<div data-spec="body-message" className={styles.body}>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET"
								defaultMessage="Are you sure you want to proceed with the password restart?"
							/>
						</div>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY"
								defaultMessage="If you click yes, you will receive password restart email shortly."
							/>
						</div>
					</div>
					<div>
						<Icon />
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						dataSpec={'close-button'}
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						onClick={onClose}
						type="button"
					/>
					<Button
						type="submit"
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.SUBMIT"
								defaultMessage="Submit"
							/>
						}
						dataSpec="submit-button"
						onClick={() => this.resetPassword()}
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, object } = PropTypes;

RestartPasswordModal.propTypes = {
	onClose: func,
	show: bool,
	system: bool,
	resetPassword: func,
	data: object,
	myProfile: bool
};

export default RestartPasswordModal;
