export default {
	'REPORTS.REPORT': 'Rapport',
	'REPORTS.M2M_ACCOUNT_ID': 'Id M2M-account',
	'REPORTS.M2M_PLATFORM': 'M2M-platform',
	'REPORTS.FREQUENCY': 'Frequentie',
	'REPORTS.REPORT_DATE': 'Rapportdatum',
	'REPORTS_TABS.ENDPOINTS': 'Eindpunten',
	'REPORTS_TABS.USAGE_SUMMARY': 'Verbruiksoverzicht',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Activiteitenoverdracht',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Accountoverzicht',
	'REPORTS_TABS.BILLING_DETAILS': 'Factureringsgegevens',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Referentierapporten',
	'REPORTS_TABS.MY_EXPORTS': 'Mijn exports',
	'REPORTS_TABS.USAGE_REPORTS': 'Verbruiksrapporten',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Opnieuw geposte rapporten',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'Geen rapporten beschikbaar',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': 'Er zijn momenteel geen rapporten voor deze tabel om te laten zien',
	'REPORTS.FILE_NAME': 'Bestandsnaam',
	'REPORTS.FILE_DATE': 'Bestandsdatum',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Aangevraagd door'
};
