export default {
  'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Ações de endpoints múltiplos',
  'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Selecionar dois ou mais endpoints',
  'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': 'Aplicar um ou mais filtros para habilitar o recurso de exportação',
  'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': 'Atualmente atribuído para os endpoints selecionados',
  'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': 'Qualificado para usar com o endpoint selecionado',
  'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': 'Não foi possível alterar o direito de acesso à rede de múltiplos SIMs com êxito',
  'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': 'Direito de acesso à rede de múltiplos SIMs foi atualizado com êxito',
  'ENDPOINTS.VIEW_NOTE': 'Visualizar nota',
  'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'Não há endpoints disponíveis.',
  'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': 'Não há nenhum endpoint para exibir nesta tabela no momento',
  //  EDIT TABLE MODAL
  'ENDPOINTS.TABLE_NAME': 'Nome da tabela',
  'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Revisar nome de exibição da tabela',
  'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Digitar nome da tabela',
  'ENDPOINTS.EDIT_TABLE': 'Revisar parâmetros da tabela',
  'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Revisar etiquetas de campos personalizados',
  'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Revisar parâmetros da tabela',
  'ENDPOINTS.APPLY_CHANGE': 'Aplicar alteração',
  'ENDPOINTS.DELETE_TAB': 'Excluir guia',
  'ENDPOINTS.CUSTOM_LABEL': 'Etiqueta personalizada {index}',
  'ENDPOINTS.CLEAR_ALL': 'Redefinir para o padrão',
  'ENDPOINTS.DEVICE_TYPE': 'Tipo de dispositivo',
  'ENDPOINTS.BULK_UPLOAD': 'Upload em massa',

  //  EDIT TABLE PARAMETERS
  'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': 'Selecionar pelo menos dois para aplicar na tabela. ',
  'ENDPOINTS.CELLULAR_COLUMNS': 'Parâmetros específicos do celular',
  'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Parâmetros específicos do satélite',

  //  CELLULAR SPECIFIC PARAMETERS
  'ENDPOINTS.M2MACCOUNTID': 'ID da conta do M2M',
  'ENDPOINTS.IMEI': 'IMEI',
  'ENDPOINTS.STATUS': 'Status',
  'ENDPOINTS.IMSI': 'IMSI',
  'ENDPOINTS.NETWORK_OPERATOR': 'Operador de rede',
  'ENDPOINTS.NETWORK_TYPE': 'Tipo de rede',
  'ENDPOINTS.IMSI_TRANSFER': 'Status da transferência de IMSI',
  'ENDPOINTS.RATE_PLAN': 'Plano de tarifas',
  'ENDPOINTS.RECREDENTIAL_STATUS': 'Status de recredenciamento',
  'ENDPOINTS.ICCID': 'ICCID',
  'ENDPOINTS.SERVICE_PROVIDER': 'Plataforma M2M',
  'ENDPOINTS.MSISDN': 'MSISDN',
  'ENDPOINTS.COUNTRY_OPERATOR': 'País da rede',
  'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'ID do direito de acesso à rede',
  'ENDPOINTS.OEM': 'Contas de assinatura',
  'ENDPOINTS.SERVICE_STATUS': 'Status de 360L',
  'ENDPOINTS.VIN': 'VIN',
  'ENDPOINTS.ROAMING_COUNTRY': 'País de roaming',
  'ENDPOINTS.ROAMING_CARRIER': 'Operadora de roaming',
  'ENDPOINTS.M2M_DATE_ADDED': 'Data de M2M adicionada',
  'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'Data de ativação de M2M',
  'ENDPOINTS.NOTES': 'Notas',
  'ENDPOINTS.CTD_DATA_USAGE': 'Uso de dados de CTD',
  'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'Uso de SMS de CTD',
  'ENDPOINTS.CTD_VOICE_USAGE': 'Uso de voz de CTD',
  'ENDPOINTS.INSESSION': 'Sessão em andamento',

  //  SATELLITE SPECIFIC PARAMETERS
  'ENDPOINTS.M2M_ACC_ID': 'ID da conta do M2M',
  'ENDPOINTS.DEVICE_NAME': 'Nome do dispositivo',
  'ENDPOINTS.DEVICE_ID': 'ID do dispositivo',
  'ENDPOINTS.DEVICE_MODEL_NUMBER': 'Número de modelo do dispositivo',

  //  RATE PLAN SCHEDULE MODAL
  'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': 'Há um erro na plataforma subjacente e a alteração do plano de tarifas não está disponível no momento.',
  'ENDPOINTS.RATE_PLAN_CHANGE': 'Alteração no plano de tarifas',
  'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'Você optou por alterar o plano de tarifas para ',
  'ENDPOINTS.TAP_INITIATE_CHANGE': "Toque em 'Iniciar alteração' para tomar uma ação imediata.",
  'ENDPOINTS.CHANGE_LATER': 'Você pode também agendar a alteração para mais tarde.',
  'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': 'A alteração agendada ocorrerá no início da data selecionada.',
  'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'Agendar alteração',
  'ENDPOINTS.INITIATE_CHANGE': 'Iniciar alteração',
  'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'A alteração do plano de tarifas foi agendada com êxito',
  'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'A alteração do plano de tarifas foi agendada com êxito para {scheduleDate}',
  'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Não foi possível agendar a alteração do plano de tarifas com êxito!',
  'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Agendar alteração do plano de tarifas',
  'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. Confirmação do sistema',
  'ENDPOINTS.NOTIFICATION_ERROR': ' ID do endpoint {endpointICCID} falhou ao provisionar depois da alteração do plano de tarifas.  Obter suporte',
  'ENDPOINTS.SCHEDULE_DATE': 'Data',
  'ENDPOINTS.SIM_STATE_CHANGE': 'Mudança do estado do SIM',
  'ENDPOINTS.SIM_STATE_CHANGED_TO': 'Você optou por alterar o estado do SIM para',
  'ENDPOINTS.STATUS_CHANGE_ERROR': 'Usuário {user} alterou status em {changeDate}',
  'ENDPOINTS.SCHEDULING': 'Agendando...',

  //  SIM STATUS
  'ENDPOINTS.ACTIVE': 'Ativado',
  'ENDPOINTS.TERMINATE': 'Encerrado',
  'ENDPOINTS.PROVISIONED': 'Provisionado',
  'ENDPOINTS.DEACTIVE': 'Desativo',
  'ENDPOINTS.INACTIVE': 'Inativo',
  'ENDPOINTS.TEST': 'Testar',
  'ENDPOINTS.SUSPENDED': 'Suspenso',
  'ENDPOINTS.SUSPEND': 'Suspender',
  'ENDPOINTS.INVENTORY': 'Inventário',

  //  SIM STATE SCHEDULE CHANGE
  'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. Agendar alteração do estado do SIM',
  'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'Agendar alteração do estado do SIM',
  'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Alteração do estado do SIM agendado com êxito!',
  'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Alteração do estado do SIM foi agendada com êxito para{scheduledDate}',
  'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Não foi possível agendar alteração do estado do SIM com êxito!',
  'ENDPOINTS.PLEASE_TRY_AGAIN': 'Tentar novamente.',
  'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': 'ID do endpoint {endpointICCID} falhou no provisionamento depois da alteração do status de IMSI.  Obter suporte',
  'ENDPOINTS.STATUS_CHANGE_USER_NOTE': 'Usuário {user} alterou status em {changeDate}',
  'ENDPOINTS.UPDATE_ENDPOINT_STATUS': 'Usuário {user} alterou status em {date}',
  'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': 'Não foi possível agendar alteração do estado de múltiplos SIMs com êxito!',
  'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': 'Não foi possível solicitar alteração do estado de múltiplos SIMs com êxito!',

  //  GENERATE REPORT MODAL
  'ENDPOINTS.CREATE_REPORT': 'Criar relatório',
  'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Criar relatório personalizado',
  'ENDPOINTS.ENDPOINTS_ARE': 'endpoints estão selecionados para a exportação.',
  'ENDPOINTS.ENDPOINT_IS': 'endpoint está selecionado para a exportação.',
  'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': 'Este relatório personalizado estará disponível na seção de relatórios.',
  'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': 'Você pode acrescentar um nome de relatório personalizado aqui',
  'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Apêndice do nome de relatório personalizado',
  'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': 'Adicionar apêndice do nome do relatório personalizado (somente dígitos e letras são permitidos)',

  //  MULTI-ACTION MODAL
  'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. Selecionar estado de SIM',
  'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Confirmar alterações',
  'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. Confirmação do sistema',
  'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Selecionar plano de tarifas',
  'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1. Selecionar direito de acesso à rede',
  'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Alterar notas',
  'ENDPOINTS.CONFIRM_STEP_SECOND': '2. Confirmação do sistema',
  'ENDPOINTS.CHANGE_SIM_STATE': 'Alterar estado de SIM',
  'ENDPOINTS.CHANGE_RATE_PLAN': 'Alterar plano de tarifas',
  'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Alterar direito de acesso à rede',
  'ENDPOINTS.CHANGE_NOTES': 'Notas',

  //  MODAL DAS NOTAS DE AÇÕES MÚLTIPLAS
  'ENDPOINTS.SAVE_NOTE': 'Salvar nota',
  'ENDPOINTS.ADD_NOTE_BELOW': 'Adicionar nota abaixo:',
  'ENDPOINTS.ENTER_NOTE_HERE': 'Digitar a nota aqui (máx. de 2000 caracteres)',
  'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Limite de caracteres excedido',
  'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'Não pode ter somente espaços em branco',
  'ENDPOINTS.CHANGING_NOTES': 'Alterando as notas',
  'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': 'Endpoints alterados com êxito',
  'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': 'Não foi possível iniciar a alteração de múltiplos endpoints com êxito',
  'ENDPOINTS.SUCCESS_KEY': 'Êxito',
  'ENDPOINTS.ERROR': 'Erro',
  'ENDPOINTS.REASON': 'razão',
  'ENDPOINTS.ENDPOINTS_SELECTED': 'endpoints selecionados',
  'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'Não é permitida a alteração de notas',

  //  CHANGE RATE PLAN
  'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Selecione alguns SIMS.',
  'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'Não é permitida a alteração do plano de tarifas',
  'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'Nenhum plano de tarifas disponível',
  'ENDPOINTS.SIMS_HAVE': 'SIMs têm',
  'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': 'Selecionar plano de tarifas diferente para aplicar alteração em todos os SIMs selecionados',
  'ENDPOINTS.NO_RATE_PLAN_SELECTED': ' nenhum plano de tarifas selecionado',
  'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Confirmar alteração do plano de tarifas',
  'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': 'Você optou por alterar o plano de tarifas dos',
  'ENDPOINTS.SELECTED_SIMS_TO': 'SIMs selecionados para',
  'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': 'Você deseja iniciar a alteração agora?',
  'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': 'Ou você pode agendar a alteração para: ',
  'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': 'Plano de tarifas para múltiplos SIMs agendado com êxito',
  'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'Alteração do plano de tarifas do SIM para',
  'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': 'SIMS agendados com êxito para',
  'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': 'Planos de tarifas do SIM atualizados com êxito',
  'ENDPOINTS.SIMS_SELECTED': 'SIMs selecionados',
  'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': 'Não foi possível alterar o plano de tarifas para múltiplos SIMs com êxito',
  'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': 'Não foi possível solicitar alteração do plano de tarifas para múltiplos SIMs com êxito',
  'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Foi selecionado o mesmo plano de tarifas',
  'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': 'ID do endpoint {endpointID} foi provisionado com êxito. O plano de tarifas foi mudado para {ratePlan}',
  'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': ' ID do endpoint {endpointID} falhou ao provisionar depois da alteração do plano de tarifas.  Obter suporte',

  //  CHANGE SIM STATE
  'ENDPOINTS.CURRENT': 'ATUAL',
  'ENDPOINTS.NETWORK_TYPE_CHECK': 'Infelizmente não é permitida a alteração do estado de endpoints múltiplos para endpoints de diferentes tipos de redes ou diferentes provedores de serviços.',
  'ENDPOINTS.SELECT_SIM_STATE': 'Selecionar estado de SIM',
  'ENDPOINTS.CURRENT_STATES': 'Estados atuais',
  'ENDPOINTS.SELECTED_TIP': 'Dica: Se você selecionou SIM que se encontrem em diferentes estados, só será possível alterar todos os SIMS selecionados para um estado no qual esteja disponível.',
  'ENDPOINTS.VIEW_RULES': 'Visualizar regras da alteração do estado para esta conta',
  'ENDPOINTS.STATES_MESSAGE': 'Nenhum estado disponível',
  'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'Confirmar alteração do estado do SIM',
  'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': 'Depois de iniciar esta alteração, os SIMs selecionados serão',
  'ENDPOINTS.PROCEED_ACTION': 'Deseja continuar com esta ação?',
  'ENDPOINTS.CAN_SCHEDULE': 'Ou você pode agendar a alteração para',
  'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': 'ID do endpoint {endpointID} foi provisionado com êxito. Status de IMSI mudou para {status}',
  'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': 'ID do endpoint {endpointID} falhou ao provisionar depois da alteração do status de IMSI.  Obter suporte',
  'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': 'Usuário {user} alterou status em {date}',
  'ENDPOINTS.SCHEDULE_SUCCESSFUL': 'Alteração do estado de múltiplos SIM foi agendada com êxito!',
  'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'Alteração do estado do SIM para',
  'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': 'Alteração do status dos SIMS solicitada com êxito!',

  //  GENERAT REPORT
  'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Relatório personalizado criado com êxito',
  'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': 'Erro ao tentar baixar o arquivo',
  'ENDPOINTS.GO_TO_REPORTS': 'Ir para os relatórios',

  //  UPDATE CUSTOM LABELS
  'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': 'Uma ou mais etiquetas de campos personalizados foram atualizadas com êxito.',
  'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': 'Uma ou mais etiquetas de campos personalizados não puderam ser atualizadas neste momento. Tentar novamente.',

  //  Plataforma de ativação
  'ENDPOINTS.GMNA': 'GMNA',
  'ENDPOINTS.POD3': 'POD3',
  'ENDPOINTS.POD19': 'POD19',
  'ENDPOINTS.DCP': 'DCP',
  'ENDPOINTS.ACC': 'ACC',
  'ENDPOINTS.CCIP': 'CCIP',
  'ENDPOINTS.VIVO': 'VIVO',
  'ENDPOINTS.SXMACC': 'SXMACC',
  'ENDPOINTS.EOD': 'EOD',
  'ENDPOINTS.WING': 'WING',
  'ENDPOINTS.GBCM': 'GBCM',
  'ENDPOINTS.GDSP': 'GDSP',

  TECH_TYPE: 'Tipo de Tecnologia',
  TECH_TYPE_SUB_TYPE: 'Subcategoria de tipo de tecnologia',
  'ENDPOINTS.TECH_TYPE': 'Tipo de Tecnologia',
  'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Subcategoria de tipo de tecnologia',
  'SINGLE_ENDPOINT.TECH_TYPE': 'Tipo de Tecnologia',
  'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Subcategoria de tipo de tecnologia'
};
