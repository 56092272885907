import { handleActions } from 'redux-actions';

import { CHANGE_INITAL_COMPANY_TAB } from './constants';

export const initialState = {
	initTab: 'details'
};

export default () => handleActions(
	{
		[CHANGE_INITAL_COMPANY_TAB]: (state, action) => ({
			...state,
			initTab: action.payload
		})
	},
	initialState
);
