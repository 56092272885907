import React, { PureComponent } from 'react';
import {
	func,
	object,
	bool,
	array,
	number,
} from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';
import PageTitleView from '../../../Shared/views/PageTitleView';
import CardsView from '../../views/CardsView';
import ActionBar from '../../../GlobalActionBar';
import Diagnostic from '../Diagnostic';
import DiagnosticDcp from '../DiagnosticDcp';
import DiagnosticCcip from '../DiagnosticCcip';
import DataOverviewView from '../../views/DataOverviewView';
import GCDesktopDetails from '../SatelliteDetails';
import HardwareView from '../../views/HardwareView';
import ModuleView from '../../views/ModuleView';
import SatelliteRatePlanView from '../../views/SatelliteRatePlanView';
import Changes from '../../views/ChangesView';
import Button from '../../../../lib/DigitalComponents/Button';
import CancelSchedulePromptModal from '../CancelSchedulePromptModal';
import SessionsView from '../../views/SessionsView';
import SingleEndpointActionsModal from '../SingleEndpointActionsModal';
import DiagnosticGdsp from '../DiagnosticGdsp';
import Notes from '../Notes';
import {
	ComponentsRenderCustom,
	isUserAllowedToAccess,
	UserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import DiagnosticWing from '../DiagnosticWing';
import DataHistory from '../DataHistory';
import Usage from '../../views/UsageView';
import isPendingChange from '../../utils/helpers';

import styles from './SingleEndpoint.scss';

const DashboardButtonRWComponent = UserAllowedToAccess([
	'mnc.endpoints_rw',
	'mnc.endpoints.rateplans_rw',
	'mnc.endpoints.networkentitlements_rw',
]);

class SingleEndpoint extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isSingleEndpointActionsModalOpened: false,
			activeTab:
				props.match.params.type === 'cellular' ? 'details' : 'globecommDetails',
			isCancelScheduleStatusModalOpen: false,
		};
	}

	componentDidMount() {
		const {
			getSingleEndpoint,
			match: {
				params: { iccid, type }
			}
		} = this.props;

		getSingleEndpoint(iccid, type);
	}

	componentDidUpdate(prevProps) {
		const {
			endpoint,
			getNetworkEntitlement,
			getRatePlans,
			isPending,
			showPendingStatusNotification,
			transactionStatus,
			intl,
			getSingleEndpoint,
			isCustomFieldsValuesUpdateSuccess,
			isCancelPendingSuccess,
			match: {
				params: { type, iccid }
			},
		} = this.props;

		if (prevProps.isPending !== isPending && isPendingChange(endpoint)) {
			showPendingStatusNotification(
				endpoint,
				transactionStatus,
				this.openCancelScheduleModal,
				intl,
			);
		}
		if (
			prevProps.isPending &&
			endpoint &&
			Object.keys(endpoint).length > 0 &&
			endpoint.m2mAccountId &&
			endpoint.networkEntitlementId
		) {
			getNetworkEntitlement(endpoint.m2mAccountId);
			getRatePlans(endpoint.m2mAccountId);
			this.onNewTabClick(type === 'cellular' ? 'details' : 'globecommDetails');
		}
		if ((
			!prevProps.isCustomFieldsValuesUpdateSuccess
			&& isCustomFieldsValuesUpdateSuccess
		) || (
			!prevProps.isCancelPendingSuccess && isCancelPendingSuccess
		)) {
			getSingleEndpoint(iccid, type);
		}
	}

	componentWillUnmount() {
		const { updateSingleEndpointStatusInit } = this.props;

		updateSingleEndpointStatusInit();
	}

	openCancelScheduleModal = () => this.setState({
		isCancelScheduleStatusModalOpen: true,
	});

	closeCancelScheduleModal = () => this.setState({
		isCancelScheduleStatusModalOpen: false,
	});

	openSingleEndpointActionsModal = () => this.setState({
		isSingleEndpointActionsModalOpened: true
	});

	closeSingleEndpointActionsModal = () => this.setState({
		isSingleEndpointActionsModalOpened: false
	});

	confirmCancellation = () => {
		const {
			cancelScheduleStatus,
			match: {
				params: { iccid, type }
			}
		} = this.props;

		cancelScheduleStatus(iccid, type);
		this.closeCancelScheduleModal();
	};

	renderDiagnostic = () => {
		const {
			endpoint,
			messages,
			match: {
				params: { iccid },
			},
			user,
		} = this.props;

		if (endpoint) {
			switch (endpoint.serviceProvider) {
				case 'DCP':
					return (
						<DiagnosticDcp
							data-spec="diagnostic-dcp"
							key="diagnostic-dcp"
							endpoint={endpoint}
							iccid={iccid}
							messages={messages}
							user={user}
						/>
					);
				// TODO: This is just a temporary fix for EOD diagnostics tab on single endpoint
				case 'EOD':
				case 'CCIP':
					return (
						<DiagnosticCcip
							data-spec="diagnostic-ccip"
							key="diagnostic-ccip"
							endpoint={endpoint}
							iccid={iccid}
							messages={messages}
							user={user}
						/>
					);
				case 'GDSP':
					return (
						<DiagnosticGdsp
							data-spec="diagnostic-gdsp"
							key="diagnostic-gdsp"
							endpoint={endpoint}
							iccid={iccid}
							carrierName={endpoint.networkOperator}
							messages={messages}
							user={user}
						/>
					);
				case 'WING':
					return (
						<DiagnosticWing
							data-spec="diagnostic-wing"
							key="diagnostic-wing"
							endpoint={endpoint}
							iccid={iccid}
							carrierName={endpoint.networkOperator}
							messages={messages}
							user={user}
						/>
					);
				default:
					return (
						<Diagnostic
							data-spec="diagnostic"
							key="diagnostic"
							endpoint={endpoint}
							iccid={iccid}
							messages={messages}
							user={user}
						/>
					);
			}
		}
	};

	getEndpointModule = (endpoint) => {
		if (!endpoint.modules || endpoint.modules.length === 0) {
			return {};
		}
		if (
			endpoint.modules[0].type === 'Satellite-Narrowband'
		) {
			return endpoint.modules[0];
    }
    if (
			endpoint.modules[0].sim
		) {
			return endpoint.modules[0].sim;
    }
	};

	renderTabs = (actionBarTabs) => actionBarTabs.map((item) => (
		item.isActive && item.component ? item.component() : null
	));

	onNewTabClick = (id) => this.setState({ activeTab: id });

	render() {
		const {
			isPending,
			endpoint,
			areCustomFieldsValuesUpdating,
			isSingleEndpointUpdating,
			isSingleEndpointRequestFailed,
			messages,
			user,
			customFieldLabels,
			getSingleEndpoint,
			ratePlans,
			entitlements,
			dataLimit,
			ratePlanUpdateFail,
      match,
			match: {
				params: { iccid, type }
			},
			pushBack,
		} = this.props;
		const {
			isSingleEndpointActionsModalOpened,
			activeTab,
			isCancelScheduleStatusModalOpen,
		} = this.state;

		if (
			(
				isPending ||
				!endpoint ||
				areCustomFieldsValuesUpdating ||
				isSingleEndpointUpdating
			) &&
			!isSingleEndpointRequestFailed &&
			!isSingleEndpointActionsModalOpened
		) {
			return (
				<Loader data-spec="single-endpoint-loader" />
			);
		}

		const tabComponents = {
			details: () => (
				<DataOverviewView
					key="details"
					customFieldLabels={customFieldLabels}
					endpoint={endpoint}
					isPendingStatus={isPendingChange(endpoint)}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={iccid}
					ratePlans={ratePlans}
					refreshEndpoint={() => getSingleEndpoint(iccid, type)}
					entitlements={entitlements}
				/>
			),
			diagnostics: () => this.renderDiagnostic(),
			changes: () => (
				<Changes
					key="changes"
					dataLimit={dataLimit}
					iccid={iccid}
					messages={messages}
				/>
			),
			sessions: () => (
				<SessionsView
					key="sessions"
					endpointId={iccid}
					messages={messages}
				/>
			),
			usage: () => (
				<Usage
					key="usage"
					messages={messages}
					imsi={endpoint.imsi}
					m2mAccountId={endpoint.m2mAccountId}
					platform={endpoint.serviceProvider}
				/>
			),
			dataHistory: () => (
				<DataHistory
					key="session-history-wing"
					messages={messages}
					iccid={iccid}
				/>
			),
			globecommDetails: () => (
				<GCDesktopDetails
					key="globecommDetails"
					endpoint={
						endpoint.modules &&
						endpoint.modules.length &&
						endpoint.modules[0].sim
							? endpoint.modules[0].sim
							: {}
					}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointData={endpoint || {}}
					hardware={endpoint && endpoint.hardware ? endpoint.hardware : {}}
				/>
			),
			module: () => (
				<ModuleView
					key="module"
					endpoint={this.getEndpointModule(endpoint)}
					rateplan={
						endpoint.modules &&
						endpoint.modules.length &&
						endpoint.modules[0].rateplan
							? endpoint.modules[0].rateplan
							: {}
					}
					modules={
						endpoint.modules && endpoint.modules.length
							? endpoint.modules[0]
							: {}
					}
					isPendingStatus={isPendingChange(endpoint)}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={iccid}
					endpointData={endpoint || {}}
				/>
			),
			hardware: () => (
				<HardwareView
					key="hardware"
					endpoint={endpoint && endpoint.hardware ? endpoint.hardware : {}}
				/>
			),
			ratePlan: () => (
				<SatelliteRatePlanView
					key="ratePlan"
					endpoint={
						endpoint.modules &&
						endpoint.modules.length &&
						endpoint.modules[0].rateplan
							? endpoint.modules[0].rateplan
							: {}
					}
				/>
			),
			globecommChanges: () => (
				<Changes
					key="globecommChanges"
					dataLimit={dataLimit}
					iccid={iccid}
					messages={messages}
				/>
			),
			notes: () => (
				<Notes
					key="notes"
					endpoint={endpoint}
					getSingleEndpoint={getSingleEndpoint}
					match={match}
				/>
			),
		};
		const endpointTabs = [
			{
				id: 'details',
				label: 'DETAILS',
				defaultLabel: 'Details',
				type: 'cellular'
			},
			{
				id: 'diagnostics',
				label: 'DIAGNOSTIC',
				defaultLabel: 'Diagnostic',
				type: 'cellular'
			},
			{
				id: 'sessions',
				label: 'SESSIONS',
				defaultLabel: 'Sessions',
				type: 'cellular',
				hide:
					endpoint.serviceProvider === 'WING' ||
					endpoint.serviceProvider === 'GDSP'
			},
			{
				id: 'usage',
				label: 'USAGE_HISTORY',
				defaultLabel: 'Usage History',
				type: 'cellular',
				hide: !isUserAllowedToAccess(
					['mnc.endpoints_rw', 'mnc.endpoints_ro'],
					user
				)
			},
			{
				id: 'dataHistory',
				label: 'DATA_HISTORY',
				defaultLabel: 'Data History',
				type: 'cellular',
				hide: endpoint.serviceProvider !== 'WING'
			},
			{
				id: 'changes',
				label: 'CHANGES',
				defaultLabel: 'Changes',
				type: 'cellular',
				hide: !isUserAllowedToAccess(
					['mnc.endpoints.changestab_rw', 'mnc.endpoints.changestab_ro'],
					user
				)
			},
			{
				id: 'globecommDetails',
				label: 'DETAILS',
				defaultLabel: 'Details',
				type: 'satellite'
			},
			{
				id: 'module',
				label: 'MODULE',
				defaultLabel: 'Module',
				type: 'satellite'
			},
			{
				id: 'hardware',
				label: 'HARDWARE',
				defaultLabel: 'Hardware',
				type: 'satellite'
			},
			{
				id: 'ratePlan',
				label: 'RATE_PLAN',
				defaultLabel: 'Rate Plan',
				type: 'satellite'
			},
			{
				id: 'globecommChanges',
				label: 'CHANGES',
				defaultLabel: 'Changes',
				type: 'satellite',
				hide: !isUserAllowedToAccess(
					['mnc.endpoints.changestab_rw', 'mnc.endpoints.changestab_ro'],
					user
				)
			},
			{
				id: 'notes',
				label: 'NOTES',
				defaultLabel: 'Notes',
				type,
				hide: !ComponentsRenderCustom(
					['system.senotes_ro', 'system.senotes_rw'],
					user
				)
			}
		];
		const actionBarTabs = endpointTabs
			.filter((item) => item.type === type && !item.hide)
			.map((tab) => (
				{
					name: <FormattedMessage
						id={tab.label}
						defaultMessage={tab.defaultLabel}
					/>,
					isActive: tab.id === activeTab,
					onClick: () => this.onNewTabClick(tab.id),
					component: tabComponents[tab.id],
				}
			));

		return (
			<div data-spec="single-endpoint">
				<PageTitleView
					title={<FormattedMessage
						id={'SINGLE_ENDPOINT.ENDPOINT_TITLE'}
						defaultMessage={'Endpoint {iccid}'}
						values={{ iccid }}
					/>}
					actions={
						<DashboardButtonRWComponent>
							<Button
								variant="primary"
								onClick={this.openSingleEndpointActionsModal}
								label={<FormattedMessage
									id="SINGLE_ENDPOINT.EDIT_ENDPOINT"
									defaultMessage="Edit Endpoint"
								/>}
								dataSpec="edit-endpoint"
							/>
						</DashboardButtonRWComponent>
					}
					pushBack={pushBack}
				/>
				<CardsView
					endpoint={endpoint}
					type={type}
					iccid={iccid}
					user={user}
				/>
				<ActionBar tabs={actionBarTabs} classname={styles.mb30} />
				{this.renderTabs(actionBarTabs, activeTab)}
				<CancelSchedulePromptModal
					onCancel={this.closeCancelScheduleModal}
					onConfirm={this.confirmCancellation}
					show={isCancelScheduleStatusModalOpen}
				/>
				<SingleEndpointActionsModal
					show={isSingleEndpointActionsModalOpened}
					onClose={this.closeSingleEndpointActionsModal}
					serviceProvider={endpoint.serviceProvider}
					endpointId={iccid}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					ratePlan={endpoint.ratePlan}
					ratePlanId={endpoint.ratePlanId}
					ratePlanUpdateFail={ratePlanUpdateFail}
					endpoint={endpoint}
					isPendingStatus={isPendingChange(endpoint)}
					ratePlans={ratePlans}
					customFieldLabels={customFieldLabels || {}}
					showLoaderOnCustomValuesTab={areCustomFieldsValuesUpdating}
				/>
			</div>
		);
	}
}

SingleEndpoint.propTypes = {
	match: object,
	endpoint: object,
	customFieldLabels: object,
	isPending: bool,
	areCustomFieldsValuesUpdating: bool,
	isSingleEndpointUpdating: bool,
	getSingleEndpoint: func,
	updateSingleEndpointStatusInit: func,
	pushBack: func,
	messages: object,
	getNetworkEntitlement: func,
	cancelScheduleStatus: func,
	isCancelPendingSuccess: bool,
	ratePlans: array,
	isSingleEndpointRequestFailed: bool,
	dataLimit: number,
	ratePlanUpdateFail: func,
	user: object,
	getRatePlans: func,
	entitlements: array,
	showPendingStatusNotification: func,
	intl: object,
	transactionStatus: object,
	isCustomFieldsValuesUpdateSuccess: bool,
};

SingleEndpoint.defaultProps = {
	endpoint: {},
	match: {},
	customFieldLabels: {},
	isPending: false,
	areCustomFieldsValuesUpdating: false,
	isSingleEndpointUpdating: false,
	getSingleEndpoint: undefined,
	updateSingleEndpointStatusInit: undefined,
	pushBack: undefined,
	messages: {},
	getNetworkEntitlement: undefined,
	cancelScheduleStatus: undefined,
	isCancelPendingSuccess: false,
	ratePlans: [],
	isSingleEndpointRequestFailed: false,
	ratePlanUpdateFail: undefined,
	user: {},
	getRatePlans: undefined,
	entitlements: [],
	showPendingStatusNotification: undefined,
	intl: {},
	transactionStatus: {},
	isCustomFieldsValuesUpdateSuccess: false,
};

export default injectIntl(SingleEndpoint);
