/* eslint-disable no-plusplus */
import qs from 'qs';
import * as ApiService from '../../../services/ApiService';
import ApiClient from '../../../utils/ApiClient';

/* TICKETS */
export const getTickets = async (params) =>
	ApiService.get(`/ticketing/overview?${qs.stringify(params)}`);

export const getTicket = (params) =>
	ApiService.get(`/ticketing/${params.id}?${qs.stringify(params.params)}`);

export const addTicket = (data) => ApiService.post('/ticketing', data);

export const editTicket = (params) =>
	ApiService.patch(`/ticketing/${params.id}`, params.ticket);

/* OPTIONS */
export const getCategories = (params) =>
	ApiService.get(`/ticketing/categories?${qs.stringify(params)}`);

export const getFirstSubCategories = (params) =>
	ApiService.get(`/ticketing/firstSubCategories?${qs.stringify(params)}`);

export const getSecondSubCategories = (params) =>
	ApiService.get(`/ticketing/secondSubCategories?${qs.stringify(params)}`);

export const getThirdSubCategories = (params) =>
	ApiService.get(`/ticketing/thirdSubCategories?${qs.stringify(params)}`);

export const getSeverities = (params) =>
	ApiService.get(`/ticketing/severities?${qs.stringify(params)}`);

export const getPriorities = (params) =>
	ApiService.get(`/ticketing/priorities?${qs.stringify(params)}`);

export const getCountries = () => ApiService.get('/ticketing/issue/countries');

export const getStates = (params) =>
	ApiService.get(`/ticketing/issue/states?${qs.stringify(params)}`);

export const getConfirmation = (params) =>
  ApiService.get(`/ticketing/issue/confirmation?${qs.stringify(params)}`);

export const getHistory = ({ id, params }) =>
	ApiService.get(`ticketing/${id}/history?${qs.stringify(params)}`);

/* CHARTS */
export const getSeveritiesAndStatuses = async () => {
	const calls = [
		ApiService.get('/ticketing/statuses'),
		ApiService.get('/ticketing/severities')
	];

	const response = await Promise.all(calls);
	return {
		statuses: response[0].data,
		severities: response[1].data
	};
};

export const getChartData = (requestObject) =>
	ApiClient.get(`/ticketing/overview?${qs.stringify(requestObject)}`)
		.then((response) => response.data.resultList)
		.catch((error) => {
			throw { error: error.response.data };
		});

/* ------------ ADD ATTACHMENT FILE ------------ */
export const addAttachment = (files, id) => {
	const filesPromises = files
		.filter((file) => file.location)
		.map((file) => ApiClient.post(`/ticketing/${id}/add-attachment`, {
				fileLocation: file.location
			}));
	return filesPromises;
};

export const getFilters = async () => {
	const calls = [
		ApiService.get('/ticketing/categories'),
		ApiService.get('/ticketing/statuses'),
		ApiService.get('/ticketing/severities')
	];

	const response = await Promise.all(calls);
	const data = {
		categories: response[0].data,
		status: response[1].data,
		severity: response[2].data
	};

	return { data };
};
/** ****DOWNLOAD TICKET FILE***** */

export const downloadTicketFile = (data) => {
	const url = '/batch/file-download';

	return ApiClient.post(url, data)
		.then((response) => response.data.id)
		.catch((error) => {
			throw { error: error.response.data };
		});
};

export const checkTicketDownloadStatus = (id) => ApiClient.get(`/batch/file-download/${id}`)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const ticketPollForFile = (data, retries, delay) => {
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				checkTicketDownloadStatus(data)
					.then((response) => {
						if (response.status == 0 || response.status == 1) {
							resolve(ticketPollForFile(data, retries, delay));
						} else if (response.status == 2) {
							resolve(response);
						} else {
							reject({ error: response });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
  return new Promise(function (resolve, reject) {
    reject({ error: [] });
  });
};

export const exportCsv = (data) => {
	const url = '/batch/file-download';

	return ApiClient.post(url, data)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});
};
