// import AutomationRuleDetailView from '../views/AutomationRuleDetailView';
import NewAutomation from '../components/NewAutomation';
import RuleDetailsParent from '../components/RuleDetailsParent';

import {
	userInStandardMode,
	userIsAuthenticated
} from '../../../utils/AuthSelector';

export default [
	{
		path: '/automation',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.automation_rw',
				'mnc.automation_ro',
				'system.managemanualrules_rw',
				'system.managemanualrules_ro'
			])(NewAutomation)
		)
	},
	{
		path: '/automation/rule/:ruleId?',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.automation_rw', 'system.managemanualrules_rw'])(
				RuleDetailsParent
			)
		)
	}
];
