export default {
	'SECURITY_FEATURES.TITLE': 'Sicherheitsfunktionen',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'IMEI-Whitelist',
	'SECURITY_FEATURES.SMS_WHITELIST': 'SMS-Whitelist',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'VOICE-Whitelist',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'IP,URL Port-Blacklist',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'IP-Bereichs-Whitelist',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': 'Keine Sicherheitsfunktionen verfügbar',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': 'Für diese Tabelle sind derzeit keine Sicherheitsfunktionen zur Ansicht verfügbar',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'M2M-KONTONAME',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'SENDEDATUM',
	'SECURITY_FEATURES.STATUS': 'STATUS',
	'SECURITY_FEATURES.PENDING': 'Ausstehend',
	'SECURITY_FEATURES.FAILED': 'Fehlgeschlagen',
	'SECURITY_FEATURES.SUCCESSFUL': 'Erfolgreich',
	'SECURITY_FEATURES.CCIP': 'Control Center Integration Program (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Vivo Control Center',
	'SECURITY_FEATURES.DCP': 'Geräteverbindungsplattform (DCP)',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'AT&T Control Center (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SUCHMENÜ
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOICE',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, PORT',
	'SECURITY_FEATURES.MENU_5': 'IP-BEREICH',
	'SECURITY_FEATURES.ADD_1': 'Neue IMEI der Whitelist hinzufügen',
	'SECURITY_FEATURES.ADD_2': 'Neue SMS der Whitelist hinzufügen',
	'SECURITY_FEATURES.ADD_3': 'Neue VOICE der Whitelist hinzufügen',
	'SECURITY_FEATURES.ADD_4': ' Neue(n/s) IP, URL, PORT der Blacklist hinzufügen',
	'SECURITY_FEATURES.ADD_5': 'Neuen IP-Bereich der Whitelist hinzufügen',
	'SECURITY_FEATURES.EXPORT': 'Exportieren',
	'SECURITY_FEATURES.EDIT': 'Bearbeiten',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'M2M-Plattform auswählen',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Sammel-Upload',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Datei hochladen',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Änderungen bestätigen',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. Systembestätigung',
	'SECURITY_FEATURES.CANCEL': 'Abbrechen',
	'SECURITY_FEATURES.CONTINUE': 'Weiter',
	'SECURITY_FEATURES.DONE': 'Fertig',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Datei hochladen',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': 'Dateien für ausgewählte Batch-Aktion hier hochladen:',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Datei zum Hochladen ziehen oder klicken',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Hochladen der Batch-Datei bestätigen',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Geplante Aktion am',
	'SECURITY_FEATURES.LOADER_MESSAGE': 'Bitte warten. Dies kann je nach Dateigröße einige Minuten dauern.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': 'Batch-Dateien erfolgreich empfangen!',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': 'Es stehen Änderungen aus, während das System Ihre Aktualisierungen abschließt',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'Batch-Dateisystemfehler!',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': 'Bei der Verarbeitung Ihrer Aktualisierungen ist ein Problem aufgetreten. Bitte versuchen Sie es später noch einmal.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Vorlage herunterladen',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': 'Dateien müssen mit Erweiterung .csv eingereicht werden.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': 'Maximale Sicherheitsfunktionen pro Upload: ',
};
