export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_PLATFORMS = 'GET_PLATFORMS';
export const GET_PARENT_ACCOUNT = 'GET_PARENT_ACCOUNT';
export const ADD_MY_SUB_ACCOUNT = 'ADD_MY_SUB_ACCOUNT';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const REQUEST_ACCESS = 'REQUEST_ACCESS';
export const GRANT_ACCESS = 'GRANT_ACCESS';
export const GET_PLATFORM_TYPES = 'GET_PLATFORM_TYPES';
export const VALIDATE_ACCOUNT = 'VALIDATE_ACCOUNT';
