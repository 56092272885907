/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-body-style */
/* global GLOBALS */
import axios from 'axios';
import { localizedRoute } from './helperFunctions';
import { internalServerError } from '../components/Shared/redux/getStatusCode/actions';
import {
	showNotification,
	hideNotification
} from '../components/Shared/redux/notifications/actions';
import postTiming from '../services/LoggingService';
import { getLocale } from './constants';
import AuthService from '../services/AuthService';

let config = {
	baseURL: GLOBALS.API_BASE_URL
};

if (
	GLOBALS.FRONT_ENV !== 'dev' &&
	GLOBALS.FRONT_ENV !== 'qa' &&
	GLOBALS.FRONT_ENV !== 'idm'
) {
	config = {
		...config,
		withCredentials: GLOBALS.FRONT_ENV !== 'development'
	};
}

class ApiClient {
	constructor(configData) {
		this.client = axios.create(configData);
		this.authorizationInterceptor = null;
		this.authService = new AuthService();
	}

	get(...params) {
		return this.client.get(...params);
	}

	post(...params) {
		return this.client.post(...params);
	}

	patch(...params) {
		return this.client.patch(...params);
	}

	put(...params) {
		return this.client.put(...params);
	}

	delete(...params) {
		return this.client.delete(...params);
	}

	postWithUrl(url, data) {
		return this.client.post(url, data);
	}

	timings = {};

	sentTimings = [];

	getTimings = () => {
		window.setInterval(async () => {
			const timings = Object.keys(this.timings);
			if (timings.length) {
				await postTiming(this.timings);
				this.sentTimings = timings.map((key) => key);
				this.sentTimings.forEach((client_request_id) => {
					delete this.timings[client_request_id];
				});
			}
		}, 10000);
	};

	addSlash = (url) => {
		if (url && url.charAt(0) !== '/') {
			return `/${url}`;
		}
		return url;
	};

	checkIfResourceIsAllowed = (resource) => {
		const forbiddenResources = [
			'client-logging',
			'users/registrations/accept-invite'
		];
		let isAllowed = true;
		forbiddenResources.forEach((item) => {
			if (resource.includes(item)) {
				isAllowed = false;
			}
		});
		return isAllowed;
	};

	saveResponseToTimings = (response) => {
		response.config.metadata.endTime = Date.now();
		response.config.metadata.totalSec =
			(response.config.metadata.endTime - response.config.metadata.startTime) /
			1000;
		response.config.metadata.totalMiliSec =
			response.config.metadata.endTime - response.config.metadata.startTime;
		response.config.metadata.statusCode = response.status;
		if (this.checkIfResourceIsAllowed(response.config.metadata.resource)) {
			this.timings = {
				...this.timings,
				[response.config.metadata.client_request_id]: {
					client_request_id: response.config.metadata.client_request_id,
					resource: response.config.metadata.resource,
					totalSec: response.config.metadata.totalSec,
					totalMiliSec: response.config.metadata.totalMiliSec,
					statusCode: response.config.metadata.statusCode,
					logTime: response.config.metadata.logTime
				}
			};
		}
	};

	registerAuthInterceptor = (dispatch) => {
		this.client.interceptors.request.use(
			async (configData) => {
        const authData = await this.authService.getAuthData();
				if (authData) {
					configData.headers.Authorization = `Bearer ${authData.access_token}`;
				}
				return configData;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		this.client.interceptors.request.use((configData) => {
			const client_request_id = `id_${Math.random().toString(36).substr(2, 9)}`;
			configData.metadata = {
				resource: `(${configData.method.toUpperCase()}) ${this.addSlash(
					configData.url
						.split('?')[0]
						.split('/')
						.map((x) => {
							if (
								x.match(/^([A-Za-z]+\d+.*|\d+[A-Za-z]+.*|[0-9]*)$/gm) &&
								x &&
								!x.includes('.')
							) {
								return '{param}';
							}
							return x;
						})
						.join('/')
				)}`,
				startTime: Date.now(),
				logTime: new Date(),
				client_request_id
      };
			configData.headers['client-request-id'] = client_request_id;
			return configData;
		});

		this.client.interceptors.response.use(
			(response) => {
				this.saveResponseToTimings(response);
				if (response && response.config.method === 'get') {
					dispatch(hideNotification('400-get-error'));
				}

				return response;
			},
			async (error) => {
				this.saveResponseToTimings(error.response);
				dispatch(internalServerError(error));
				if (error && error.response && error.response.status === 400) {
					const locale = getLocale();
					const {
						default: { FETCH_DATA_ERROR }
					} = await import(`../localizations/${locale}`);

					error.response.config.method === 'get'
						? dispatch(
								showNotification({
									id: '400-get-error',
									message: [FETCH_DATA_ERROR],
									type: 'error',
									notificationType: 'toaster'
								})
						  )
						: {
								// Error 400 other than GET are handled inside HTTP services => Do nothing here
						  };
				} else if (error.response.status === 401) {
					// redirect to IDM login page
					// window.location = `${GLOBALS.LOGIN_PATH}?redirect=${pathName}`;
					this.authService.login();
				} else {
					dispatch(localizedRoute('/error'));
				}
			}
		);
	};

	registerCompanyAccountInterceptor = (store) => {
		this.client.interceptors.request.use((configData) => {
			const companyAccountId =
				store.getState().user.getUserData.accountId || 'default';
			const companyToken = sessionStorage.getItem('company-token');

			configData = {
				...configData,
				headers: {
					...configData.headers,
					'account-id': companyAccountId
				}
			};

			if (companyToken) {
				configData = {
					...configData,
					headers: {
						...configData.headers,
						'company-token': companyToken
					}
				};
			}

			return configData;
		});
	};

	registerLanguageInterceptor = (store) => {
		this.client.interceptors.request.use((configData) => {
			const language = store.getState().locale.language;

			if (language) {
				configData = {
					...configData,
					headers: {
						...configData.headers,
						language: language.toLowerCase(),
					}
				};
			}

			return configData;
		});
	};
}

export default new ApiClient(config);
