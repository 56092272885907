export default {
	'ERRORS.ERROR': 'Erreur',
	'ERRORS.FORBIDDEN_ACCESS': 'Accès interdit',
	'ERRORS.RETURN_TO_HOME_PAGE': 'RETOUR À LA PAGE D’ACCUEIL',
	'ERRORS.PAGE_NOT_FOUND': 'Il semble que la page que vous recherchez soit introuvable.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Erreur serveur interne',
	'ERRORS.REQUEST_TIMED_OUT': 'Délai expiré',
	'ERRORS.LOGOUT': 'Déconnexion',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': 'Il semble que vous n’avez pas l’autorisation d’accéder à cette application.',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': 'Si vous pensez que vous devez avoir l’accès, veuillez vous adresser à l’administrateur de votre société.',
};
