/* eslint-disable function-paren-newline */
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './ChangeStateModal.scss';

import { manualRuleStates } from '../../utils/manualRuleStatesHelper';
import { maxLength } from '../../../../utils/validators';

const UploadIcon = ImageSelector(CurrentContext.theme, 'svgs/alert-modal.svg');
const maxLength250 = maxLength(250);

const submitForm = (values, callNeededAction) => {
	// console.log(values);
	callNeededAction(values.note);
};

class ChangeStateModalComponent extends React.PureComponent {
	renderModalContent = () => {
		const { currentState, selectedState } = this.props;
		return (
			<div
				data-spec="automation-change-state-modal-content"
				className={styles.content_wrapper}
			>
				<div className={styles.content}>
					<div className={styles.icon}>
						<UploadIcon />
					</div>
					<div className={styles.text}>
						<div className={styles.bolded_text}>
							<FormattedMessage
								id="AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT"
								defaultMessage="You are about to change {currentState} status of a rule to '{selectedState}'"
								values={{
									currentState: currentState.value,
									selectedState: selectedState.value
								}}
							/>
						</div>
						<div>
							<FormattedMessage
								id="AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT"
								defaultMessage="Are you sure you want to do that?"
							/>
						</div>
					</div>
				</div>
				<div className={styles.note_wrapper}>
					<span className={styles.note_label}>
						<FormattedMessage
							id="AUTOMATION.ADD_NOTE"
							defaultMessage="Add Note (Optional)"
						/>
					</span>
					<span className={styles.note}>
						<FormattedMessage
							id="AUTOMATION.ADD_NOTE_PLACEHOLDER"
							defaultMessage="Add note"
						>
							{(formattedValue) => (
								<Field
									componentClass="textarea"
									dataSpec="automation-rule-state-change-note"
									component={Input}
									name="note"
									placeholder={formattedValue}
									validate={[maxLength250]}
								/>
							)}
						</FormattedMessage>
					</span>
				</div>
			</div>
		);
	};

	callNeededAction = (note) => {
		const {
			id,
			selectedState,
			onClose,
			setRuleReceived,
			setRuleImplemented,
			setRuleConflicted
		} = this.props;
		const data = {
			id,
			params: {
				note: note || null
			}
		};
		switch (selectedState.value) {
			case manualRuleStates.RECEIVED:
				setRuleReceived(data);
				break;
			case manualRuleStates.IMPLEMENTED:
				setRuleImplemented(data);
				break;
			case manualRuleStates.CONFLICTED:
				setRuleConflicted(data);
				break;
			default:
				break;
		}
		onClose();
	};

	renderButtons = () => {
		const { onClose, valid } = this.props;
		return (
			<div
				data-spec="automation-change-state-modal-buttons"
				className={styles.buttons}
			>
				<div>
					<Button
						onClick={onClose}
						variant="link"
						type="button"
						label={
							<FormattedMessage id={'AUTOMATION.NO'} defaultMessage={'No'} />
						}
					/>
					<span className={styles.confirmation_button}>
						<Button
							variant="primary"
							type="submit"
							label={
								<FormattedMessage
									id={'AUTOMATION.YES'}
									defaultMessage={'Yes'}
								/>
							}
							disabled={!valid}
							// onClick={() => this.callNeededAction()}
						/>
					</span>
				</div>
			</div>
		);
	};

	render() {
		const { show, onClose, handleSubmit } = this.props;
		return (
			<Modal
				show={show}
				onClose={onClose}
				data-spec="automation-change-state-modal"
			>
				<form
					onSubmit={handleSubmit((values) =>
						submitForm(values, this.callNeededAction)
					)}
				>
					<ModalHeader
						title={
							<FormattedMessage
								id={'AUTOMATION.RULE_STATUS_CHANGE'}
								defaultMessage={'Rule Status Change'}
							/>
						}
					/>
					<ModalBody className={styles.modal__body}>
						{this.renderModalContent()}
					</ModalBody>
					<ModalFooter data-spec="ds-modal-footer">
						{this.renderButtons()}
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { bool, func, object, string } = PropTypes;

ChangeStateModalComponent.propTypes = {
	show: bool,
	onClose: func,
	selectedState: object,
	currentState: object,
	setRuleReceived: func,
	setRuleImplemented: func,
	setRuleConflicted: func,
	id: string,
	valid: bool,
	handleSubmit: func
};

const ChangeStateModal = reduxForm({
	form: 'changeRuleState',
	enableReinitialize: true
})(ChangeStateModalComponent);

export default ChangeStateModal;
