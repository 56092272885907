import { connect } from 'react-redux';
import BlacklistedPermissionsDesktop from './Desktop';

import {
	getBlacklistedPermissionsRequest,
	getBlacklistedPermissionsFail,
	getBlacklistedPermissionsSuccess,
	editBlacklistedPermissionRequest,
	editBlacklistedPermissionSuccess,
	isRequestFeatureModalOpened,
	requestFeatureRequest,
	requestFeatureSuccess,
	isRequestedFeatureModalOpened,
	isCancelRequestedFeatureModalOpened,
	editBlacklistedPermissionFail
} from '../../redux/blacklistedPermissions/selectors';
import {
	getBlacklistedPermissions,
	editBlacklistedPermission,
	openRequestFeatureModal,
	closeRequestFeatureModal,
	setRequestFeature,
	unsetRequestFeature,
	closeRequestedFeatureModal,
	openRequestedFeatureModal,
	setRequestedFeature,
	unsetRequestedFeature,
	setCancelRequestedFeature,
	openCancelRequestedFeatureModal,
	unsetCancelRequestedFeature,
	closeCancelRequestedFeatureModal
} from '../../redux/blacklistedPermissions/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	blacklistedPermissionsRequest: getBlacklistedPermissionsRequest(state),
	blacklistedPermissionsFail: getBlacklistedPermissionsFail(state),
	blacklistedPermissions: getBlacklistedPermissionsSuccess(state),
	editBlacklistedPermissionRequest: editBlacklistedPermissionRequest(state),
	editBlacklistedPermissionFail: editBlacklistedPermissionFail(state),
	editBlacklistedPermissionSuccess: editBlacklistedPermissionSuccess(state),
	isRequestFeatureModalOpened: isRequestFeatureModalOpened(state),
	requestFeatureRequest: requestFeatureRequest(state),
	requestFeatureSuccess: requestFeatureSuccess(state),
	isRequestedFeatureModalOpened: isRequestedFeatureModalOpened(state),
	isCancelRequestedFeatureModalOpened: isCancelRequestedFeatureModalOpened(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getBlacklistedPermissions: (params) => dispatch(getBlacklistedPermissions(params)),
	editBlacklistedPermission: (params) =>
		dispatch(editBlacklistedPermission(params)),
	openRequestFeatureModal: (id) => {
		dispatch(setRequestFeature(id));
		dispatch(openRequestFeatureModal());
	},
	closeRequestFeatureModal: () => {
		dispatch(unsetRequestFeature());
		dispatch(closeRequestFeatureModal());
	},
	openRequestedFeatureModal: (params) => {
		dispatch(setRequestedFeature(params));
		dispatch(openRequestedFeatureModal());
	},
	closeRequestedFeatureModal: () => {
		dispatch(unsetRequestedFeature());
		dispatch(closeRequestedFeatureModal());
	},
	openCancelRequestedFeatureModal: (params) => {
		dispatch(setCancelRequestedFeature(params));
		dispatch(openCancelRequestedFeatureModal());
	},
	closeCancelRequestedFeatureModal: () => {
		dispatch(unsetCancelRequestedFeature());
		dispatch(closeCancelRequestedFeatureModal());
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BlacklistedPermissionsDesktop);
