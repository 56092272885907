import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';

import { getFullDateTime } from '../../../../utils/constants';
import { maxLength, required } from '../../../../utils/validators';
import styles from './EditNoteModal.scss';

// const maxLength2000 = maxLength(2000);
const maxLength2000 = maxLength(2000);

class EditNoteModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			show,
			data,
			onCloseReset,
			handleSubmit,
			note,
			invalid
		} = this.props;

		return (
			<Modal
				data-spec="ticketing-notes-modal"
				show={show}
				onClose={onCloseReset}
			>
				<FormattedMessage id="TICKETING.NOTE" defaultMessage="Note">
					{(formattedValue) => (
						<ModalHeader
							title={formattedValue}
							closeModal={() => {
								onCloseReset();
							}}
						/>
					)}
				</FormattedMessage>
				<form onSubmit={handleSubmit}>
					<ModalBody className={styles.modal_body}>
						<div data-spec="body-note" className={styles.notes_body}>
							<div className={styles.modal_note}>
								<div className={styles.row}>
									<h4 className={styles.title_row}>
										<FormattedMessage
											id="TICKETING.CREATED_BY"
											defaultMessage="Created by"
										/>
									</h4>
									<div className={styles.detail_holder}>{data.user}</div>
								</div>
								<div className={styles.row}>
									<h4 className={styles.title_row}>
										<FormattedMessage
											id="TICKETING.DATE_CREATED"
											defaultMessage="Date Created"
										/>
									</h4>
									<div className={styles.detail_holder}>
										{data.externalCreatedAt
											? getFullDateTime(data.externalCreatedAt)
											: ''}
									</div>
								</div>
							</div>
							<div className={styles.description}>
								{note ? (
									<pre>{note}</pre>
								) : (
									<Field
										componentClass="textarea"
										dataSpec="ticket-note"
										component={Input}
										name="note"
										// validate={[required, maxLength10000]}
										validate={[required, maxLength2000]}
									/>
								)}
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							dataSpec="close-note"
							variant="link"
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
							onClick={() => {
								onCloseReset();
							}}
							type="button"
						/>
						{!note ? (
							<Button
								dataSpec="submit-note"
								variant="primary"
								label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
								type="submit"
								disabled={invalid}
							/>
						) : (
							[]
						)}
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { func, bool, object, string, oneOfType } = PropTypes;

EditNoteModal.propTypes = {
	show: bool,
	onCloseReset: func,
	data: oneOfType([object, string]),
	note: string,
	handleSubmit: func,
	invalid: bool
};

EditNoteModal.defaultProps = {
	show: false
};

const EditNoteModalForm = reduxForm({
	form: 'EditNoteModalForm',
	enableReinitialize: true
})(EditNoteModal);

export default EditNoteModalForm;
