import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getAddons = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/addons?${qs.stringify(
			params.params
		)}`
	);

export const getAddonsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/addons/filters?${qs.stringify(
			params.params
		)}`
	);
