export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Actions sur plusieurs points terminaux',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Veuillez sélectionner deux points terminaux ou plus',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': 'Veuillez appliquer un ou plusieurs filtres pour activer la fonctionnalité d’exportation',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': 'Actuellement affecté aux points terminaux sélectionnés',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': 'Utilisation admissible avec les points terminaux sélectionnés',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': 'Échec de la demande de changement de droits d’utiliser le réseau pour plusieurs SIM',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': 'Mise à jour de droits d’utiliser le réseau des SIM réussie',
	'ENDPOINTS.VIEW_NOTE': 'Afficher note',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'Aucun point terminal disponible',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': 'Aucun point terminal à montrer actuellement pour cette table',
	//  EDIT TABLE MODAL
	'ENDPOINTS.TABLE_NAME': 'Nom de la table',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Modifier nom de vue de la table',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Veuillez saisir le nom de la table',
	'ENDPOINTS.EDIT_TABLE': 'Modifier paramètres de la table',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Modifier étiquettes de champ personnalisées',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Modifier paramètres de la table',
	'ENDPOINTS.APPLY_CHANGE': 'Appliquer changement',
	'ENDPOINTS.DELETE_TAB': 'Supprimer onglet',
	'ENDPOINTS.CUSTOM_LABEL': 'Étiquette personnalisée {index}',
	'ENDPOINTS.CLEAR_ALL': 'Remettre à la valeur par défaut',
	'ENDPOINTS.DEVICE_TYPE': 'Type d’appareil',
	'ENDPOINTS.BULK_UPLOAD': 'Transfert groupé',

	//  EDIT TABLE PARAMETERS
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': 'Sélectionner un minimum de 2 à appliquer à la table. ',
	'ENDPOINTS.CELLULAR_COLUMNS': 'Paramètres spécifiques cellulaire',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Paramètres spécifiques satellite',

	//  CELLULAR SPECIFIC PARAMETERS
	'ENDPOINTS.M2MACCOUNTID': 'ID compte machine-machine',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': 'État',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': 'Opérateur réseau',
	'ENDPOINTS.NETWORK_TYPE': 'Type de réseau',
	'ENDPOINTS.IMSI_TRANSFER': 'État du transfert IMSI',
	'ENDPOINTS.RATE_PLAN': 'Forfait',
	'ENDPOINTS.RECREDENTIAL_STATUS': 'État réaffectation',
	'ENDPOINTS.ICCID': 'Identifiant de carte SIM',
	'ENDPOINTS.SERVICE_PROVIDER': 'Plateforme machine-machine',
	'ENDPOINTS.MSISDN': 'Nº réseau numérique à intégration de services d’abonné mobile',
	'ENDPOINTS.COUNTRY_OPERATOR': 'Pays réseau',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'ID droit d’utiliser le réseau',
	'ENDPOINTS.OEM': 'Sous-comptes',
	'ENDPOINTS.SERVICE_STATUS': 'État 360L',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': 'Pays itinérance',
	'ENDPOINTS.ROAMING_CARRIER': 'Opérateur itinérance',
	'ENDPOINTS.M2M_DATE_ADDED': 'Date ajout machine-machine',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'Date activation machine-machine',
	'ENDPOINTS.NOTES': 'Notes',
	'ENDPOINTS.CTD_DATA_USAGE': 'Utilisation données CTD',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'Utilisation SMS CTD',
	'ENDPOINTS.CTD_VOICE_USAGE': 'Utilisation voix CTD',
	'ENDPOINTS.INSESSION': 'En session',

	//  SATELLITE SPECIFIC PARAMETERS
	'ENDPOINTS.M2M_ACC_ID': 'ID compte machine-machine',
	'ENDPOINTS.DEVICE_NAME': 'Nom de l’appareil',
	'ENDPOINTS.DEVICE_ID': 'ID appareil',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': 'N° modèle appareil',

	//  RATE PLAN SCHEDULE MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': 'Présence d’une erreur sur la plateforme sous-jacente et changement de forfait non disponible pour le moment.',
	'ENDPOINTS.RATE_PLAN_CHANGE': 'Changement de forfait',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'Vous avez choisi de changer le forfait à ',
	'ENDPOINTS.TAP_INITIATE_CHANGE': 'Saisir « Lancer changement » pour une action immédiate.',
	'ENDPOINTS.CHANGE_LATER': 'Vous pouvez aussi programmer le changement pour plus tard.',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': 'La programmation du changement s’exécutera au début de la date sélectionnée.',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'Programmer changement',
	'ENDPOINTS.INITIATE_CHANGE': 'Lancer changement',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Programmation réussie du changement de forfait',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Programmation réussie du changement de forfait pour {scheduleDate}',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Échec de la programmation du changement de forfait !',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Programmer changement de forfait',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. Confirmation du système',
	'ENDPOINTS.NOTIFICATION_ERROR': 'Échec du provisionnement du point terminal ID {endpointICCID} après le changement de forfait. Obtenir assistance',
	'ENDPOINTS.SCHEDULE_DATE': 'Date',
	'ENDPOINTS.SIM_STATE_CHANGE': 'Changement état SIM',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': 'Vous avez choisi de changer l’état SIM à',
	'ENDPOINTS.STATUS_CHANGE_ERROR': 'L’utilisateur {user} a effectué un changement d’état le {changeDate}',
	'ENDPOINTS.SCHEDULING': 'Programmation en cours…',

	//  SIM STATUS
	'ENDPOINTS.ACTIVE': 'Activé',
	'ENDPOINTS.TERMINATE': 'Résilié',
	'ENDPOINTS.PROVISIONED': 'Provisionné',
	'ENDPOINTS.DEACTIVE': 'Désactivé',
	'ENDPOINTS.INACTIVE': 'Inactif',
	'ENDPOINTS.TEST': 'Test',
	'ENDPOINTS.SUSPENDED': 'Suspendu',
	'ENDPOINTS.SUSPEND': 'Suspendre',
	'ENDPOINTS.INVENTORY': 'Inventaire',

	//  SIM STATE SCHEDULE CHANGE
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. Programmer le changement état SIM',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'Programmer le changement état SIM',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Programmation réussie du changement de l’état SIM',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Programmation réussie du changement de l’état SIM pour {scheduledDate}',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Échec de la programmation du changement de l’état SIM',
	'ENDPOINTS.PLEASE_TRY_AGAIN': 'Veuillez réessayer.',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': 'Échec du provisionnement du point terminal ID {endpointICCID} après le changement d’état IMSI. Obtenir assistance',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE': 'L’utilisateur {user} a effectué un changement d’état le {changeDate}',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS': 'L’utilisateur {user} a effectué un changement d’état le {date}',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': 'Échec de la programmation du changement d’état de plusieurs SIM',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': 'Échec de la demande de changement d’état de plusieurs SIM',

	//  GENERATE REPORT MODAL
	'ENDPOINTS.CREATE_REPORT': 'Créer rapport',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Créer rapport personnalisé',
	'ENDPOINTS.ENDPOINTS_ARE': 'points terminaux sélectionnés pour l’exportation.',
	'ENDPOINTS.ENDPOINT_IS': 'point terminal sélectionné pour l’exportation.',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': 'Ce rapport personnalisé sera disponible dans la section des rapports.',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': 'Vous pouvez ajouter le nom personnalisé au rapport ici',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Annexe du nom du rapport personnalisé',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': 'Ajouter l’annexe du nom du rapport personnalisé (caractères alphanumériques uniquement)',

	//  MULTI-ACTION MODAL
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. Sélectionner état SIM',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Confirmer changements',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. Confirmation du système',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Sélectionner forfait',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1. Sélectionner droit d’utiliser le réseau',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Changer notes',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2. Confirmation du système',
	'ENDPOINTS.CHANGE_SIM_STATE': 'Changer état SIM',
	'ENDPOINTS.CHANGE_RATE_PLAN': 'Changer forfait',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Changer le droit d’utiliser le réseau',
	'ENDPOINTS.CHANGE_NOTES': 'Notes',

	//  MULTI-ACTION NOTES MODAL
	'ENDPOINTS.SAVE_NOTE': 'Enregistrer note',
	'ENDPOINTS.ADD_NOTE_BELOW': 'Ajouter note ci-dessous : ',
	'ENDPOINTS.ENTER_NOTE_HERE': 'Saisir la note ici (2 000 caractères max.)',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Nbre limite de caractères dépassé',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'Ne peut pas être uniquement composé d’espaces',
	'ENDPOINTS.CHANGING_NOTES': 'Changement des notes',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': 'Changement réussi des points terminaux',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': 'Échec du changement des notes pour plusieurs points terminaux',
	'ENDPOINTS.SUCCESS_KEY': 'Réussi',
	'ENDPOINTS.ERROR': 'Erreur',
	'ENDPOINTS.REASON': 'raison',
	'ENDPOINTS.ENDPOINTS_SELECTED': 'points terminaux sélectionnés',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'Changement des notes non autorisé',

	//  CHANGE RATE PLAN
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Veuillez sélectionner quelques SIM.',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'Changement de forfait non permis',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'Aucun forfait disponible',
	'ENDPOINTS.SIMS_HAVE': 'SIM ont',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': 'Sélectionnez un autre forfait pour appliquer le changement à toutes les SIM sélectionnées',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': ' aucun forfait sélectionné',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Confirmer changement de forfait',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': 'Vous avez choisi de changer le forfait des',
	'ENDPOINTS.SELECTED_SIMS_TO': 'SIM sélectionnées pour',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': 'Souhaitez-vous procéder au changement maintenant ?',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': 'Vous pouvez aussi programmer le changement pour : ',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': 'Programmation du forfait réussie pour plusieurs SIM',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'Changement forfait SIM pour',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Programmation réussie des SIM pour',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': 'Mise à jour réussie des forfaits SIM',
	'ENDPOINTS.SIMS_SELECTED': 'SIM sélectionnées',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': 'Échec de la programmation du changement de forfait pour plusieurs SIM',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': 'Échec de la demande de changement de forfait pour plusieurs SIM',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Même forfait sélectionné',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': 'Le point terminal ID {endpointID} a bien été provisionné. État forfait changé à {ratePlan}',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': 'Échec du provisionnement du point terminal ID {endpointID} après le changement de forfait. Obtenir assistance',

	//  CHANGE SIM STATE
	'ENDPOINTS.CURRENT': 'ACTUEL',
	'ENDPOINTS.NETWORK_TYPE_CHECK': 'Désolé, le changement d’état de points terminaux multiples n’est pas autorisé pour les points terminaux ayant des types de réseau ou des fournisseurs de services différents.',
	'ENDPOINTS.SELECT_SIM_STATE': 'Sélectionner état SIM',
	'ENDPOINTS.CURRENT_STATES': 'États actuels',
	'ENDPOINTS.SELECTED_TIP': 'Astuce : si les SIM sélectionnées ont des états différents, vous ne pourrez changer que pour un état disponible pour toutes les SIM sélectionnées.',
	'ENDPOINTS.VIEW_RULES': 'Afficher les règles de changement d’état pour ce compte',
	'ENDPOINTS.STATES_MESSAGE': 'Aucun état disponible.',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'Confirmer changement état SIM',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': 'Une fois le changement exécuté, les SIM sélectionnées seront',
	'ENDPOINTS.PROCEED_ACTION': 'Souhaitez-vous exécuter cette action ?',
	'ENDPOINTS.CAN_SCHEDULE': 'Vous pouvez aussi programmer le changement pour',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': 'Le point terminal ID {endpointID} a bien été provisionné. État IMSI changé à {status}',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': 'Échec du provisionnement du point terminal ID {endpointID} après le changement d’état IMSI. Obtenir assistance',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': 'L’utilisateur {user} a effectué un changement d’état le {date}',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL': 'Programmation réussie du changement d’état de plusieurs SIM',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'Changement état de SIM pour',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': 'Demande réussie de changement d’état de SIM',

	//  GENERAT REPORT
	'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Création du rapport personnalisé réussie',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': 'Erreur lors de l’essai du téléchargement de votre fichier',
	'ENDPOINTS.GO_TO_REPORTS': 'Aller aux rapports',

	//  UPDATE CUSTOM LABELS
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': 'Mise à jour réussie d’une ou plusieurs étiquettes de champ personnalisé',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': 'Impossible de mettre à jour une ou plusieurs étiquettes de champ personnalisé. Veuillez réessayer.',

	//  Plateforme d’activation
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': 'Plate-forme de connexion d’appareils',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

	TECH_TYPE: 'Type de technologie',
	TECH_TYPE_SUB_TYPE: 'Sous-catégorie de type de technologie',
	'ENDPOINTS.TECH_TYPE': 'Type de technologie',
	'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Sous-catégorie de type de technologie',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Type de technologie',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Sous-catégorie de type de technologie'
};
