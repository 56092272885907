import React, { Component } from 'react';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { getm2mAccount } from '../../redux/actions';
import {
	getm2mAccountRequest,
	getm2mAccountSuccess
} from '../../redux/selectors';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './ActivationPlatform.scss';

const validate = (values) => {
	const errors = {};

	if (!values.m2mPlatform) {
		errors.m2mPlatform = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}

	if (!values.m2mAccountId) {
		errors.m2mAccountId = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}

	return errors;
};

class ActivationPlatform extends Component {
	constructor(props) {
    super(props);
		this.state = {};
  }

	componentDidMount() {
    const { fetchm2mAccount } = this.props;
    fetchm2mAccount();
	}

	renderM2mAccountId = () => {
		let { data } = this.props;
		const { platform } = this.state;
		if (platform) {
			data = data && data.filter((item) => item.platformName === platform);
		}
		return data && data.map((item) => ({
			label: item.m2mAccountId,
			value: item.m2mAccountId
		}));
	};

	renderPlatform = () => {
    const { data } = this.props;
		const platformData = data && uniqBy(data, 'platformName');
		return platformData
			.filter((item) => item.platformName)
			.map((item) => ({
				label: (
					<FormattedMessage
						id={`ENDPOINTS.${item.platformName.toUpperCase()}`}
						defaultMessage={item.platformName}
					/>
				),
				value: item.platformName
			}));
	};

	handlePlatformChange = ({ value }) => {
    const { dispatch } = this.props;
		dispatch(change('activationFileForm', 'm2mAccountId', ''));
		dispatch(untouch('activationFileForm', 'm2mAccountId'));
		this.setState({
			platform: value
		});
	};

	Option = () => (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.Option data-spec="m2mPlatform-select" {...optionProps}>
				<div data-spec={data && data.label}>{children}</div>
			</components.Option>
		);
	};

	render() {
    const { isFetching, onMenuOpen, data } = this.props;
		const { platform } = this.state;

		if (isFetching || !data) {
			return <div data-spec="activation-file-form" className={styles.form_wrapper}><Loader className={styles.custom_loader} data-spec="loader" /></div>;
		}
		return (
			<div data-spec="activation-file-form" className={styles.form_wrapper}>
				<div className={styles.select_wrapper}>
					<label className={styles.label}>
						<FormattedMessage id="M2MPlatform" defaultMessage="M2M Platform" />
					</label>
					<Field
						component={Select}
						name="m2mPlatform"
						options={this.renderPlatform()}
						onMenuOpen={onMenuOpen}
						onChange={this.handlePlatformChange}
						components={{ Option: this.Option() }}
						dataSpec="m2mPlatform"
					/>
				</div>
				<div className={styles.select_wrapper}>
					<label className={styles.label}>
						<FormattedMessage
							id="M2M_ACCOUNT_ID_PLATFORM"
							defaultMessage="M2M Account ID"
						/>
					</label>
					<Field
						component={Select}
						name="m2mAccountId"
						options={this.renderM2mAccountId()}
						onMenuOpen={onMenuOpen}
						isDisabled={!platform}
						components={{ Option: this.Option() }}
						dataSpec="m2mAccountId"
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
		isFetching: getm2mAccountRequest(state),
		data: getm2mAccountSuccess(state)
	});

const mapDispatchToProps = (dispatch) => ({
	fetchm2mAccount: () => dispatch(getm2mAccount())
});

const ActivationFileForm = reduxForm({
	form: 'activationFileForm',
	validate
})(ActivationPlatform);

ActivationPlatform.propTypes = {
	fetchm2mAccount: PropTypes.func,
	isFetching: PropTypes.bool,
	data: PropTypes.array,
	onMenuOpen: PropTypes.func,
	dispatch: PropTypes.func
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ActivationFileForm);
