import React from 'react';
import styles from './CustomFieldLabels.scss';
import CustomFieldLabels from '../index';

const DesktopCustomFieldLabels = (props) => (
		<CustomFieldLabels
			data-spec="custom-field-labels"
			{...props}
			styles={styles}
		/>
	);

export default DesktopCustomFieldLabels;
