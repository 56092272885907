import { handleActions } from 'redux-actions';

import {
	SET_SEARCH_PARAMETER,
	SET_SEARCH_PARAMETER_INIT,
	SET_SEARCH_PARAMETER_PROP,
	SET_SEARCH_PARAMETER_PROP_INIT
} from './constants';

export const initialState = {
  searchParameter: null,
  searchParameterProp: null
};

export default handleActions(
	{
		[SET_SEARCH_PARAMETER]: (state, action) => ({
			...state,
			searchParameter: action.payload
		}),
		[SET_SEARCH_PARAMETER_INIT]: (state) => ({
			...state,
			searchParameter: null
		}),
		[SET_SEARCH_PARAMETER_PROP]: (state, action) => ({
			...state,
			searchParameterProp: action.payload
		}),
		[SET_SEARCH_PARAMETER_PROP_INIT]: (state) => ({
			...state,
			searchParameterProp: null
		})
	},
	initialState
);
