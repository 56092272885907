import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { format, endOfDay, isValid } from 'date-fns';
import { isEmpty } from 'lodash';
import ActionBar from '../../../Shared/views/ActionBarView';
import Functionalities from '../../views/Functionalities';
import FilterModal from '../../../Shared/components/FilterModal';
import Loader from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';
import styles from './List.scss';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';

import {
	securityFeaturesHeaderDesktop,
	securityFeaturesHeaderMobile
} from '../../utils/headers';

import { ConditionIf } from '../../../../utils/helperFunctions';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import {
	onOrderClick,
	filterHelpers,
	newOnPageChange
} from '../../../../utils/tableHelpers';
import { getSecurityFeaturesFilters as getSecurityFeaturesFiltersService } from '../../services/SecurityFeaturesService';
import getSearchMenu from '../../utils/searchMenu';
import { getUTCDate } from '../../../AnalyticsInvoices/utils/helpers';

const List = () => (WrappedComponent) => {
	class SecurityFeaturesList extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				orderColumn: {}
			};
		}

		componentDidMount() {
			const { getStatuses } = this.props;
			getStatuses();
			this.getSecurityFeatures();
		}

		componentDidUpdate(prevProps) {
			const {
				editSecurityFeaturesSuccess,
				addSecurityFeatureSuccess,
				setSelectedItemsInit,
				editSecurityFeatureSuccess
			} = this.props;
			if (
				(editSecurityFeaturesSuccess &&
					!prevProps.editSecurityFeaturesSuccess) ||
				(addSecurityFeatureSuccess && !prevProps.addSecurityFeatureSuccess) ||
				(editSecurityFeatureSuccess && !prevProps.editSecurityFeatureSuccess)
			) {
				this.getSecurityFeatures();
				setSelectedItemsInit();
			}
		}

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		onChange = () => {
			const { setSelectedItemsInit } = this.props;
			setSelectedItemsInit();
			this.getSecurityFeatures();
		};

		onApply = () => {
			const { setSelectedItemsInit } = this.props;
			setSelectedItemsInit();
			this.getSecurityFeatures();
		};

		clearFilters = () => {
			const { setSelectedItemsInit } = this.props;
			setSelectedItemsInit();
			this.getSecurityFeatures();
		};

		createFilterData = (data) => {
			const { messages } = this.props;
			const filterDataKeys = Object.keys(data);
			let filter = {};
			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					if (item === 'filter_category_status') {
						filter = {
							...filter,
							[`${item}`]: data[item].map((categoryStatusItem) => {
								const lbl = categoryStatusItem.name;
								return {
									value: categoryStatusItem.id,
									label: messages[`FILTER_VALUE_${lbl.toUpperCase()}`] || lbl,
									checked: false
								};
							})
						};
					} else {
						filter = {
							...filter,
							[`${item}`]: data[item].map((m2mAccountItem) => {
								const lbl = m2mAccountItem.platformName;
								return {
									value: m2mAccountItem.m2mAccountId,
									label:
										`${messages[`SECURITY_FEATURES.${lbl.toUpperCase()}`]} (${
											m2mAccountItem.m2mAccountId
										})` || `${lbl} ${m2mAccountItem.m2mAccountId}`,
									checked: false
								};
							})
						};
					}
				});
			return filter;
		};

		getDataSort = () => {
			const { orderColumn } = this.state;
			let dataSort = '';
			if (!isEmpty(orderColumn)) {
				const { name, apiString } = orderColumn;
				dataSort =
					name === 'status'
						? apiString.replace('status', 'customerSecurityFeatureStatusId')
						: apiString;
			} else {
				dataSort = 'createdAt desc';
			}
			return dataSort;
		};

		getSecurityFeatures = () => {
			const {
				getSecurityFeatures,
				dataLimit,
				id,
				filterData,
				dataOffset,
				searchParameter
			} = this.props;

			const realProps = {
				filter_category_status: 'customerSecurityFeatureStatusId',
				filter_category_m2mAccount: 'm2mAccountId'
			};

			let filterParams = [];
			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}

			let simpleSearchParams = [];
			if (searchParameter) {
				if (['createdAt'].includes(searchParameter.prop)) {
					if (isValid(getUTCDate(new Date(searchParameter.propValue)))) {
						simpleSearchParams = [
							{
								operator: '>=',
								prop: searchParameter.prop,
								propValue: getUTCDate(
									new Date(searchParameter.propValue)
								).toISOString()
							},
							{
								operator: '<=',
								prop: searchParameter.prop,
								propValue: endOfDay(
									getUTCDate(new Date(searchParameter.propValue))
								)
							}
						];
					}
				} else if (
					searchParameter.prop === 'customerSecurityFeatureTypeValue'
				) {
					simpleSearchParams = [
						{
							operator: 'iLike',
							prop: searchParameter.prop,
							propValue: `${searchParameter.propValue}%`
						}
					];
				} else {
					simpleSearchParams = [
						{
							operator: '=',
							prop: searchParameter.prop,
							propValue: Number.isNaN(Number(searchParameter.propValue))
								? 0
								: parseInt(searchParameter.propValue, 10)
						}
					];
				}
			}

			const params = {
				additionalParams: {
					dataLimit,
					dataOffset,
					dataSort: this.getDataSort(),
					include: ['CustomerSecurityFeatureStatus']
				},
				searchParams: [
					{
						prop: 'customerSecurityFeatureTypeId',
						propValue: id,
						operator: '='
					},
					...filterParams,
					...simpleSearchParams
				]
			};
			getSecurityFeatures(params);
		};

		totalCount = () => {
			const { dataLimit, securityFeatures } = this.props;
			return Math.ceil(securityFeatures.totalCount / dataLimit);
		};

		onCheckBoxChange = (data) => {
			const { securityFeatures, setSelectedItems } = this.props;
			let selectedIndexes = [];
			if (data && data.selected) {
				selectedIndexes = data.selected.map(
					(selectedItem) =>
						securityFeatures && securityFeatures.resultList[selectedItem]
				);
			}

			setSelectedItems([...selectedIndexes]);
		};

		onSearchSubmitCallback = () => {
			this.getSecurityFeatures();
		};

		getOptions = (target) => {
			const {
				dataLimit,
				id,
				securityFeatures,
				selectedPage,
				setDataOffset,
				setSelectedPage
			} = this.props;
			const { orderColumn } = this.state;
			const { totalCount } = 0 || securityFeatures;
			const dateFormat = 'MM/dd/yyyy';
			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {
				tableOptions: {
					orderByData: ['all'],
					getOrderData: (data) =>
						onOrderClick(data, this.getSecurityFeatures, this),
					orderColumn,
					showPagination: totalCount > dataLimit,
					pageCount: this.totalCount(),
					forcePage: selectedPage,
					onPageChange: (data) =>
						newOnPageChange(
							data,
							this.getSecurityFeatures,
							dataLimit,
							setDataOffset,
							setSelectedPage
						),
					marginPagesDisplayed: 2,
					pageRangeDisplayed: 2
				}
			};

			if (isMobile) {
				options.header = securityFeaturesHeaderMobile;
			}

			if (isDesktop) {
				options.header = securityFeaturesHeaderDesktop;
				options.tableOptions = {
					...options.tableOptions,
					firstColumnMarked: true,
					fixedFirstColumn: true,
					selectRows: true,
					enableSelectAllRows: true,
					onCheckBoxChange: (data) => this.onCheckBoxChange(data)
				};
			}

			options.header = [
				{
					title: (
						<FormattedMessage
							id={`SECURITY_FEATURES.MENU_${id}`}
							defaultMessage={'ID'}
						/>
					),
					name: 'customerSecurityFeatureTypeValue'
				},
				...options.header
			];

			options.customComponents = {
				status: {
					type: 'custom',
					component: (data) => this.renderOrderStatus(data)
				},
				createdAt: {
					type: 'custom',
					component: (val) => format(new Date(val.createdAt), dateFormat)
				},
				m2mAccountName: {
					type: 'custom',
					component: (val) => this.renderM2MAccountName(val)
				},
				customerSecurityFeatureTypeValue: {
					type: 'custom',
					component: (data) => this.renderTypeValue(data)
				}
			};

			return options;
		};

		renderM2MAccountName = (val) => (
			<div data-spec="sf-m2m-account-name">
				<FormattedMessage
					id={`SECURITY_FEATURES.${val.m2mAccountName.toUpperCase()}`}
					defaultMessage={val.m2mAccountName}
				/>
			</div>
		);

		Option = (optionProps) => {
			const { data, children } = optionProps;
			return (
				<components.Option {...optionProps} data-spec={`${data.value}`}>
					<div
						data-spec={`${data.value}`}
						className={`${styles[this.renderStatusClass(data.value)]} ${
							styles.custom_option
						}`}
					>
						{children}
					</div>
				</components.Option>
			);
		};

		SingleValue = (optionProps) => {
			const { data } = optionProps;
			return (
				<components.SingleValue
					{...optionProps}
					data-spec={`${data.value}`}
					className={`${styles[this.renderStatusClass(data.value)]} ${
						styles.custom_option
					} ${styles.select_single_value}`}
				/>
			);
		};

		updateOrderStatus = (id, newStatus) => {
			const { securityFeatures, editSecurityFeature } = this.props;
			if (securityFeatures && securityFeatures.resultList.length) {
				const orderData = securityFeatures.resultList.find((f) => f.id === id);
				if (
					orderData &&
					orderData.customerSecurityFeatureStatusId !== newStatus
				) {
					const params = {
						customerSecurityFeatureStatusId: newStatus,
						customerSecurityFeatureTypeValue:
							orderData.customerSecurityFeatureTypeValue,
						m2mAccountId: orderData.m2mAccountId
					};
					editSecurityFeature(orderData.id, params);
				}
			}
		};

		renderTypeValue = (data) => (
			<div
				data-spec="type-value-security-features"
				className={styles.type_value_wrapper}
			>
				{data.customerSecurityFeatureTypeValue}
			</div>
		);

		renderStatusClass = (value) => {
			let className = '';

			if (value === 1) className = 'failed';
			if (value === 2) className = 'pending';
			if (value === 3) className = 'successful';
			return className;
		};

		renderOrderStatus = (val) => {
			const { statuses, statusesRequest, user } = this.props;
			const SUComponent = isUserAllowedToAccess(
				['system.simorderstatus_rw'],
				user
			);
			const RWOComponent = isUserAllowedToAccess(
				['mnc.securityfeatures_ro', 'mnc.securityfeatures_rw'],
				user
			);
			return (
				<div
					data-spec="order-status-wrapper"
					className={styles.order_status_select}
				>
					<ConditionIf condition={SUComponent}>
						<div
							data-spec={`order-status-${
								val && val.customerSecurityFeatureTypeValue
							}`}
							className={styles.order_status_select}
						>
							<Select
								name="customerSecurityFeatureStatusId"
								menuPosition="fixed"
								className={styles.order_status_select_c}
								options={
									statuses &&
									statuses.resultList &&
									statuses.resultList.map((item) => ({
										value: item.id,
										label: (
											<FormattedMessage
												id={`SECURITY_FEATURES.${item.name.toUpperCase()}`}
												defaultMessage={item.name}
											/>
										)
									}))
								}
								onChange={(data) => this.updateOrderStatus(val.id, data.value)}
								value={{
									value: val.customerSecurityFeatureStatus.id,
									label: (
										<FormattedMessage
											id={`SECURITY_FEATURES.${val.customerSecurityFeatureStatus.name.toUpperCase()}`}
											defaultMessage={val.customerSecurityFeatureStatus.name}
										/>
									)
								}}
								components={{
									Option: this.Option,
									SingleValue: this.SingleValue
								}}
								isDisabled={
									statusesRequest || !statuses || !statuses.resultList
								}
							/>
						</div>
					</ConditionIf>
					<ConditionIf condition={!SUComponent && RWOComponent}>
						<div
							data-spec={`status-label-${val.customerSecurityFeatureTypeValue}`}
							className={`${
								styles[
									this.renderStatusClass(val.customerSecurityFeatureStatus.id)
								]
							} ${styles.custom_option}`}
						>
							{
								<FormattedMessage
									id={`SECURITY_FEATURES.${val.customerSecurityFeatureStatus.name.toUpperCase()}`}
									defaultMessage={val.customerSecurityFeatureStatus.name}
								/>
							}
						</div>
					</ConditionIf>
				</div>
			);
		};

		render() {
			const {
				securityFeaturesRequest,
				securityFeaturesFail,
				securityFeatures,
				statusesRequest,
				editSecurityFeatureRequest,
				editSecurityFeaturesRequest,
				messages,
				id,
				exportToCsvRequest,
				addSecurityFeatureRequest
			} = this.props;

			const params = {
				service: getSecurityFeaturesFiltersService,
				createFilterData: this.createFilterData
			};

			const isAnyRequest =
				securityFeaturesRequest ||
				statusesRequest ||
				editSecurityFeatureRequest ||
				editSecurityFeaturesRequest ||
				exportToCsvRequest ||
				addSecurityFeatureRequest;

			const isDataEmpty =
				securityFeatures &&
				securityFeatures.resultList &&
				securityFeatures.resultList.length === 0;

			return (
				<div data-spec="security-features-list">
					<Functionalities
						isAnyRequest={isAnyRequest}
						messages={messages}
						id={id}
					/>
					<ActionBar
						menu={getSearchMenu(id)}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						openFilterModal={this.openFilterModal}
						clearFilters={this.clearFilters}
						onChange={this.onChange}
						isDisabled={isAnyRequest}
						isDataEmpty={isDataEmpty}
					/>
					<FilterModal
						type="checkbox"
						messages={messages}
						onApply={this.onApply}
						params={params}
					/>
					{isAnyRequest && <Loader />}
					{securityFeaturesFail && null}
					{securityFeatures && !isAnyRequest && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
							securityFeatures={securityFeatures.resultList}
						/>
					)}
					{isDataEmpty && (
						<NoData
							table
							title={
								<FormattedMessage
									id="SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE"
									defaultMessage="No Security Features Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE"
									defaultMessage="There are No Security Features for this Table to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const {
		number,
		func,
		object,
		bool,
		objectOf,
		string,
		shape,
		arrayOf
	} = PropTypes;

	SecurityFeaturesList.propTypes = {
		id: number,
		getSecurityFeatures: func,
		dataLimit: number,
		securityFeatures: shape(),
		securityFeaturesRequest: bool,
		securityFeaturesFail: bool,
		statuses: shape(),
		editSecurityFeature: func,
		user: shape(),
		getStatuses: func,
		statusesRequest: bool,
		editSecurityFeaturesSuccess: bool,
		editSecurityFeatureSuccess: bool,
		editSecurityFeatureRequest: bool,
		messages: objectOf(string),
		openFilterModal: func,
		filterData: shape(),
		simpleSearchParameter: arrayOf(object),
		exportToCsv: func,
		exportToCsvRequest: bool,
		openBatchUploadModal: func,
		closeBatchUploadModal: func,
		pushItems: func,
		addSecurityFeatureSuccess: bool,
		addSecurityFeatureRequest: bool,
		setSelectedItemsInit: func,
		selectedItems: arrayOf(object),
		setSelectedItems: func,
		editSecurityFeaturesRequest: bool,
		dataOffset: number,
		selectedPage: number,
		setDataOffset: func,
		setSelectedPage: func,
		setPaginationInit: func,
		searchParameter: object
	};
	return SecurityFeaturesList;
};

export default List;
