import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_OTHER_CHARGES, GET_OTHER_CHARGES_FILTERS } from './constants';

export default combineReducers({
	getOtherCharges: globalReducer(GET_OTHER_CHARGES),
	getOtherChargesFilters: globalReducer(GET_OTHER_CHARGES_FILTERS)
});
