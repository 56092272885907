import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getEndpoints = (params) => {
	const { m2mAccountId, searchParams, isMyCompany } = params;
	let url = '';
	if (isMyCompany) {
		url = `onboarding/my-company/m2m-accounts/${
			params.m2mAccountId
		}/endpoints?${qs.stringify({
			searchParams: params.searchParams
		})}`;
	} else {
		url = `/onboarding/m2m-accounts/${m2mAccountId}/endpoints?${qs.stringify({
			searchParams
		})}`;
	}
	return ApiService.get(url);
};

export const moveEndpoints = (params) => {
	const { m2mAccountId, endpoints, isMyCompany } = params;
	let url = '';
	if (isMyCompany) {
		url = `onboarding/my-company/m2m-accounts/${params.m2mAccountId}/endpoints`;
	} else {
		url = `/onboarding/m2m-accounts/${m2mAccountId}/endpoints`;
	}
	return ApiService.patch(url, endpoints);
};
