import React, { PureComponent } from 'react';
import { object, func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { renderTabs, onNewTabClick } from '../../../../utils/tabHelpers';

import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import TabsBar from '../../../Shared/views/TabsBarView';
import AccountEndpointsView from '../../views/AccountEndpointsView';

class AssignEndpoints extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: null
		};
	}

	componentDidMount() {
		const {
			match: {
				params: { companyId }
			}
		} = this.props;
		if (companyId) {
			this.getAccounts(companyId);
		} else {
			this.getAccounts();
		}
	}

	componentDidUpdate(prevProps) {
		const { accounts } = this.props;
		if (
			prevProps.accounts !== accounts &&
			accounts &&
			accounts.resultList &&
			accounts.resultList.length > 0
		) {
			this.updateActiveTab();
		}
	}

	componentWillUnmount() {
		const { getAccountsInit } = this.props;
		getAccountsInit();
	}

	updateActiveTab = () => {
		const {
			match: {
				params: { accountId }
			}
		} = this.props;

		this.setState({
			activeTab: accountId
		});
	};

	getAccounts = (companyId) => {
		const { getAccounts } = this.props;

		let searchParams = [
			{ propValue: 7, prop: 'platformTypeId', operator: '=' }
		];

		if (companyId) {
			searchParams = [
				...searchParams,
				{ propValue: companyId, prop: 'companyId', operator: '=' }
			];
		}
		getAccounts({
			searchParams,
			additionalParams: {
				include: ['PlatformType', 'SubPlatforms', 'SubPlatforms.SubPlatforms']
			}
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { parentAccountM2mId, companyId, accountId, m2mAccountId }
			},
			isMyCompany
		} = this.props;

		if (parentAccountM2mId) {
			if (isMyCompany) {
				pushBack(
					`/my-company/${accountId}/${m2mAccountId}/${parentAccountM2mId}`
				);
			} else {
				pushBack(
					`/companies/${companyId}/accounts/${accountId}/${m2mAccountId}/${parentAccountM2mId}`
				);
			}
		} else if (isMyCompany) {
			pushBack(`/my-company/${accountId}`);
		} else {
			pushBack(`/companies/${companyId}/accounts/${accountId}`);
		}
	};

	renderEndpointsTabs = (accounts) => {
		const { messages } = this.props;
		const { activeTab } = this.state;
		let tabs = [];
		accounts.resultList.forEach((item) => {
			tabs = [
				...tabs,
				{
					id: item.id,
					name: item.m2mAccountName,
					component: (
						<AccountEndpointsView
							key={item.id}
							messages={messages}
							account={item}
						/>
					),
					isActive: item.id === parseInt(activeTab, 10),
					onClick: () => onNewTabClick(item.id, this)()
				}
			];
		});
		return tabs;
	};

	render() {
		const { messages, accountsRequest, accountsFail, accounts } = this.props;

		return (
			<div data-spec="edit-company">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.ENDPOINTS"
							defaultMessage="Endpoints"
						/>
					}
					pushBack={!accountsRequest ? this.pushBack : undefined}
					messages={messages}
				/>
				{accountsRequest && <Loading data-spec="loading" />}
				{accountsFail && null}
				{!accountsRequest && !accountsFail && accounts && (
					<>
						<TabsBar tabs={this.renderEndpointsTabs(accounts)} />
						{renderTabs(this.renderEndpointsTabs(accounts))}
					</>
				)}
			</div>
		);
	}
}

AssignEndpoints.propTypes = {
	messages: object,
	match: object,
	pushBack: func,
	getAccounts: func,
	accountsRequest: bool,
	accountsFail: bool,
	accounts: object,
	getAccountsInit: func,
	isMyCompany: bool
};

export default AssignEndpoints;
