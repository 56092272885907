export default {
		'RELEASE_NOTES.RELEASE_NOTES': '版本说明',
		'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'IoT Console频繁更新版本。',
		'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': '我们会频繁更新IoT Console的版本，以确保最佳的运行状态，并提供更多特性、能力，以及更好的用户体验。下面是此版本中的更新清单。',
		'RELEASE_NOTES.RELEASE_VERSION': '发布版本',
		'RELEASE_NOTES.NEW_PLATFORM_FEATURES': '新平台特性',
		'RELEASE_NOTES.DEPRECATIONS': '弃用',
		'RELEASE_NOTES.DEPRECATION': '弃用',
		'RELEASE_NOTES.KNOWN_ISSUES': '已知问题',
		'RELEASE_NOTES.NEW_FEATURES': '新特性',
		'RELEASE_NOTES.IMPROVEMENTS': '改进',
		'RELEASE_NOTES.FEATURE': '特性',
		'RELEASE_NOTES.IMPROVEMENT': '改进',
		'RELEASE_NOTES.ISSUE': '问题',

		'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': '添加另一项弃用',
		'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': '添加另一项平台特性',
		'RELEASE_NOTES.ADD_ANOTHER_FEATURE': '添加另一项特性',
		'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': '添加另一项已知问题',
		'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': '添加另一项改进',

		'RELEASE_NOTES.PUBLISH': '发布',
		'RELEASE_NOTES.SAVE': '保存',
		'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': '发布版本说明',
		'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': '您即将发布版本说明{releaseVersion}。您确定想要继续吗？',
		'RELEASE_NOTES.CANCEL': '取消'
	};
