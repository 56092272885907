import { connect } from 'react-redux';

import createUserSettings
	from '../../../../redux/user/createUserSettings/actions';
import { getUserSettings } from '../../../../redux/user/getUserData/selectors';
import updateUserSettings
	from '../../../../redux/user/updateUserSettings/actions';
import {
	exportUsageData,
	getEndpointUsageData,
} from '../../redux/actions';
import {
	getEndpointUsageDataRequest,
	getEndpointUsageDataSuccess,
	isExportUsageDataPending,
} from '../../redux/selectors';
import Desktop from './Desktop';
import Mobile from './Mobile';

const mapStateToProps = (state) => (
	{
		settings: getUserSettings(state, 11),
		usageData: getEndpointUsageDataSuccess(state),
		getEndpointUsageDataRequest: getEndpointUsageDataRequest(state),
		isExportUsageDataPending: isExportUsageDataPending(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getEndpointUsageData: (iccid, params, type, platform) => (
			dispatch(getEndpointUsageData(iccid, params, type, platform))
		),
		updateSettings: (setting, id) => dispatch(updateUserSettings(
			setting,
			id,
			{}
		)),
		createSettings: (setting) => dispatch(createUserSettings(setting, {})),
		exportUsageData: (params, formatMessage) => dispatch(exportUsageData(
			params,
			formatMessage,
		)),
	}
);

export const DesktopUsage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Desktop);
export const MobileUsage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Mobile);
