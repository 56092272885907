export default {
	'SWAGGER.DEVELOPER_API': 'Entwickler-API',
	'SWAGGER.GETTING_STARTED': 'Erste Schritte',
	'SWAGGER.API_SANDBOX': 'API-Sandbox',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': 'Die Swagger-Sandbox unten gibt die Liste der in der REST-API verfügbaren Ressourcen sowie die Operationen an, die an diesen Ressourcen aufgerufen werden können. Das Dokument enthält auch die Liste der Parameter der jeweiligen Operation einschließlich Name und Typ der Parameter, ob die Parameter obligatorisch oder optional sind sowie Informationen über für diese Parameter zulässige Werte.',
	'SWAGGER.JSON_SCHEMA_INCLUDES': 'JSON Schema ist eingeschlossen und beschreibt die Struktur Anforderungskörpers, der an eine Operation in einer REST-API gesendet wird, sowie das JSON-Schema, das die Struktur aller Antwortkörper beschreibt, die von einer Operation zurückgegeben werden.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Erste Schritte mit API',
	'SWAGGER.GETTING_STARTED_WITH_API_P': 'Bevor Sie Ihren ersten API-Aufruf tätigen, muss Ihre Quell-IP-Adresse (Server) auf der zulässigen Liste stehen („whitelisted"). Die Quell-IP-Adresse wird in der Regel beim Onboarding-Prozess konfiguriert, wenn der API-Zugriff erstellt wird. Falls Sie Fehler erhalten, liegt das wahrscheinlich daran, dass der Zugriff auf AT&T IoT Gateway gesperrt ist.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': 'Vor dem Start der Entwicklung müssen Sie die folgenden Schritte abgeschlossen haben:',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '- Sie haben einen öffentlich zugänglichen Server eingerichtet und uns beim Onboarding dessen IP-Adresse bereitgestellt.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '- Ihre Quell-IP ist für den Zugriff auf AT&T IoT Gateway zugelassen („whitelisted“).',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '- Ihr Unternehmensadministrator hat Ihnen einen API-Benutzernamen und ein Passwort gegeben, die bei der Aktivierung des API-Dienstes aktiviert wurden.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '- Sie haben Zugriff auf den Ressourcenserver, um cURL-Befehle auszuführen oder Verbindungs-Apps zu verwenden, die in Ihrer nachstehend aufgeführten bevorzugten Sprache verfasst wurden.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'OAuth-Zugriffstoken abrufen',
	'SWAGGER.GET_OAUTH_TOKEN_P': 'OAuth-Token von AT&T IoT Identity mithilfe des Benutzernamens und Passworts, die Sie von Ihrem Administrator erhalten haben, über die bevorzugte nachstehende Methode abrufen. Token ist 7 Tage lang gültig.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'API-Konnektoren',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': 'Wählen Sie aus den nachstehenden Beispielen aus, wie Sie eine Verbindung mit unserer API herstellen möchten.',
	'SWAGGER.TEST_CONNECTION_T': 'Testverbindung mit dem IoT-Gateway',
	'SWAGGER.TEST_CONNECTION_P': 'Curl-Beispiel:',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Gewünschtes Ergebnis',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': 'Falls Sie HTTP-Antwortcode 200 OK erhalten, können Sie mit der Entwicklung beginnen.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': 'Fehlersuche bei Verbindungsfehlern',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': "Falls Sie Fehler erhalten, prüfen Sie zunächst, ob Ihre Authentifizierung im HTTPHeader korrekt eingerichtet ist und das Zugriffs-Token nicht abgelaufen ist bzw. aktualisiert werden muss. Pingen Sie außerdem AT&T IoT Gateway, um zu prüfen ob Ihre Quell-IP zugelassen („whitelisted“) ist. Falls Sie unerwartete Ergebnisse erhalten, können Sie im Support-Abschnitt ein Ticket zur Fehlersuche einreichen.'"
};
