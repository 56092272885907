import React from 'react';
import PropTypes from 'prop-types';
import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';
import hoc from '../List';

const Mobile = (props) => {
	const { securityFeatures, getOptions, messages } = props;
	return (
		<div data-spec="security-features-desktop">
			<CardsTable
				data={securityFeatures}
				options={getOptions('mobile')}
				messages={messages}
			/>
		</div>
	);
};

const { arrayOf, func, object, objectOf, string } = PropTypes;

Mobile.propTypes = {
	getOptions: func,
	securityFeatures: arrayOf(object),
	messages: objectOf(string)
};

export default hoc()(Mobile);
