import { connect } from 'react-redux';

import TaxesTable from './TaxesTable';

import { userSettingsID } from '../../utils/constants';

import { getTaxes, getTaxesFilters } from '../../redux/taxes/actions';

import {
	getTaxesRequest,
	getTaxesFail,
	getTaxesSuccess
} from '../../redux/taxes/selector';

import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';

import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';
import { getSimpleSearchParameter } from '../../../Shared/redux/simpleSearch/selectors';

import {
	getUser,
	getUserSettings
} from '../../../../redux/user/getUserData/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import { createUserSettingsPending } from '../../../../redux/user/createUserSettings/selectors';
import deleteUserSettings from '../../../../redux/user/deleteUserSettings/actions';
import resetUserSettings from '../../../../redux/user/resetUserSettings/actions';
import {
	updateUserSettingsSuccess,
	updateUserSettingsPending
} from '../../../../redux/user/updateUserSettings/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import {
	setDataOffset,
	setSelectedPage,
	setPaginationInit
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import {
	exportInvoiceDetailsRequest,
	exportInvoiceDetailsSuccess
} from '../../redux/invoices/selector';

const mapStateToProps = (state) => ({
	taxesRequest: getTaxesRequest(state),
	taxesFail: getTaxesFail(state),
	taxes: getTaxesSuccess(state),
	filterModalOpened: isFilterModalOpen(state),
	simpleSearchParameter: getSimpleSearchParameter(state),
	user: getUser(state),
	userSettings: getUserSettings(state, userSettingsID),
	createUserSettingsPending: createUserSettingsPending(state),
	updateUserSettingsSuccess: updateUserSettingsSuccess(state),
	updateUserSettingsRequest: updateUserSettingsPending(state),
	filterData: filterDataSuccess(state),
	dataOffset: getDataOffset(state),
  selectedPage: getSelectedPage(state),
  searchParameter: getSearchParameter(state),
  exportInvoiceDetailsRequest: exportInvoiceDetailsRequest(state),
	exportInvoiceDetailsSuccess: exportInvoiceDetailsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getTaxes: (id, params) => dispatch(getTaxes(id, params)),
	getTaxesFilters: (id, params) => dispatch(getTaxesFilters(id, params)),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	patchUserSetting: (newValue, id) =>
		dispatch(
			updateUserSettings(newValue, id, {
				success: 'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS',
				error: 'ANALYTICS_INVOICES.NOTIFICATION_FAIL'
			})
		),
	createUserSettings: (newValue) => dispatch(createUserSettings(newValue)),
	deleteUserSettings: (id) => dispatch(deleteUserSettings(id)),
	resetUserSettings: (refId) => dispatch(resetUserSettings(refId)),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxesTable);
