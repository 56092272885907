/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
import qs from 'qs';
import * as ApiService from './ApiService';

export const downloadReportRequestURL = (data) =>
	ApiService.post('/batch/downloadFile', data);

export const checkReportDownloadStatus = (data) =>
	ApiService.get(`/batch/downloadStatus?${qs.stringify(data)}`);

export const reportPollForFile = (data, retries, delay) => {
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				-checkReportDownloadStatus(data)
					.then((response) => {
						if (response.data.status === 0 || response.data.status === 1) {
							resolve(reportPollForFile(data, retries, delay));
						} else if (response.data.status === 2) {
							resolve(response);
						} else {
							reject({ error: response });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
  return new Promise(function (resolve, reject) {
    reject({ error: [] });
  });
};

export const download = (paginationData, searchData = {}) => {
	const url = '/endpoints/advancedSearchExport';
	const data = {
		exportFormat: 'xlsx',
		additionalParams: {
			dataOffset: paginationData.dataOffset,
			dataLimit: paginationData.dataLimit
		}
	};
	if (Object.keys(searchData).length) {
		data.searchParams = searchData.searchFilter;
	}
	if (paginationData.dataSort) {
		data.additionalParams.dataSort = paginationData.dataSort;
	}
	return ApiService.post(url, data, { responseType: 'blob' });
};
