import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './UploadFile.scss';

const Upload = ImageSelector(CurrentContext.theme, 'svgs/upload.svg');

class FileUpload extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			dropZoneMessage: null,
			error: false,
			errorMessages: [],
		};
	}

	componentDidUpdate(prevProps) {
		const { error } = this.props;

		if (prevProps.error !== error) {
			this.setStateAfterUpdate();
		}
	}

	setStateAfterUpdate = () =>
		this.setState({
			dropZoneMessage: (
				<FormattedMessage
					id="USER_FEEDBACK.FILE_UPLOAD_FAIL"
					defaultMessage="Fail Upload Failed"
				/>
			),
			error: false,
			errorMessages: [],
		});

	checkFileExtension = (file) => {
		const validator = 'jpg jpeg png';
		const fname = file.name;
		const extension = fname.slice(fname.lastIndexOf('.') + 1);

		return validator.includes(extension);
	};

	onDrop = (files) => {
		if (!files || files.length === 0) return;

		const { feedbackFileRequestURL } = this.props;
		const { errorMessages } = this.state;
		const filteredFiles = files.filter(
			(file) => this.checkFileExtension(file) === true
		);

		if (filteredFiles.length > 0) {
			const uploadBody = {
				request: {
					fileName: filteredFiles[0].name,
				},
				file: filteredFiles[0],
			};

			feedbackFileRequestURL(uploadBody);
		} else {
			this.setState({
				error: true,
				errorMessages: [...errorMessages, 'FEEDBACK_FILE_UPLOAD_EXTENSIONS'],
			});
		}
	};

	onDropRejected = (errorMessages) =>
		this.setState({
			error: true,
			errorMessages: [...errorMessages, 'FILE_TO_LARGE_TO_UPLOAD'],
		});

	renderDropZoneMessage = (dropZoneMessage) => (
		<div className={styles.title} data-spec="dropzone-message">
			{dropZoneMessage}
			<br />
			<FormattedMessage
				id="DRAG_FILE_OR_CLICK_TO_UPLOAD"
				defaultMessage="Drag file or click to upload"
			/>
		</div>
	);

	render() {
		const { error, dropZoneMessage, errorMessages } = this.state;

		return (
			<div data-spec="ticket-file-upload">
				<Dropzone
					onDrop={this.onDrop}
					maxSize={3000000}
					className={cn(styles.dropzone, {
						[`${styles.error}`]: dropZoneMessage !== null,
					})}
					onDropRejected={() => this.onDropRejected(errorMessages)}
				>
					<div>
						<Upload className={styles.uploadIcon} />
					</div>
					<div className={styles.text}>
						{this.renderDropZoneMessage(dropZoneMessage)}
					</div>
				</Dropzone>
				<p
					className={
						error && errorMessages.includes('FEEDBACK_FILE_UPLOAD_EXTENSIONS')
							? styles.error
							: ''
					}
				>
					<FormattedMessage
						id="FEEDBACK_FILE_UPLOAD_EXTENSIONS"
						defaultMessage="Upload files with .jpg or .png extensions only"
					/>
				</p>
				{error && errorMessages.includes('FILE_TO_LARGE_TO_UPLOAD') && (
					<p className={styles.error}>
						<FormattedMessage
							id="FILE_TO_LARGE_TO_UPLOAD"
							defaultMessage="File is too large to upload. Maximum file size is 3MB."
						/>
					</p>
				)}
			</div>
		);
	}
}

const { func, bool } = PropTypes;

FileUpload.propTypes = {
	feedbackFileRequestURL: func,
	error: bool
};

FileUpload.defaultProps = {
	feedbackFileRequestURL: undefined,
	error: false
};

export default FileUpload;
