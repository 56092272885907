import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_DISCOUNTS, GET_DISCOUNTS_FILTERS } from './constants';

export default combineReducers({
	getDiscounts: globalReducer(GET_DISCOUNTS),
	getDiscountsFilters: globalReducer(GET_DISCOUNTS_FILTERS)
});
