import { createAction } from 'redux-actions';
import { getEndpoints } from '../../../Endpoint/services/EndpointsService';

import { showNotification, hideNotification } from '../../../Shared/redux/notifications/actions';
import { getLocale } from '../../../../utils/constants';

import {
	GET_ENDPOINT_DATA_REQUEST,
	GET_ENDPOINT_DATA_SUCCESS,
	GET_ENDPOINT_DATA_FAIL,
	SET_ENDPOINT_TICKETING_INIT,
	SET_ENDPOINT_TICKETING_RESET,
} from '../constants';

export const getEndpointDataRequest = createAction(GET_ENDPOINT_DATA_REQUEST);
export const getEndpointDataSuccess = createAction(GET_ENDPOINT_DATA_SUCCESS);
export const getEndpointDataFail = createAction(GET_ENDPOINT_DATA_FAIL);
export const setEndpointTicketingInit = createAction(
	SET_ENDPOINT_TICKETING_INIT
);
export const setEndpointTicketingReset = createAction(
	SET_ENDPOINT_TICKETING_RESET
);

export const getEndpointData = (params) => async (
	dispatch
) => {
	const locale = getLocale();
	const {
		default: { ENDPOINT_WITH_PARAMETAR }
	} = await import(`../../../../localizations/${locale}`);
	const {
		default: { 'TICKETING.ENDPOINT_WITH_PARAMETAR': message }
	} = await import(`../../localization/${locale}`);
	dispatch(getEndpointDataRequest());
  dispatch(hideNotification('endpoint-invalid-parametar'));
	getEndpoints(params)
		.then((response) => {
			dispatch(getEndpointDataSuccess(response.data));
			if (response.data.resultList.length < 1) {
				dispatch(
					showNotification({
						id: 'endpoint-invalid-parametar',
						message: message ? [message] : [ENDPOINT_WITH_PARAMETAR],
						type: 'error',
						notificationType: 'toaster'
					})
				);
			}
		})
		.catch((error) => {
			dispatch(getEndpointDataFail(error));
		});
};
