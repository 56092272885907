import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';

import hoc from '../TicketDetails';

import styles from './Mobile.scss';

const Mobile = (props) => {
	const { ticketDetails, getDateTimeFormat, getOptions } = props;

	return (
		<div data-spec="ticket-details-mobile">
			<div className={styles.row_wrapper}>
				<div className={styles.row}>
					<div className={styles.block_message}>
						<div className={styles.block_title}>
							<FormattedMessage
								id="TICKETING.TICKET_ID"
								defaultMessage="Ticket ID"
							/>
						</div>
						{ticketDetails.externalTicketId}
					</div>

					<div className={styles.block_message}>
						<div className={styles.block_title}>
							<FormattedMessage id="TICKETING.STATUS" defaultMessage="Status" />
						</div>
						{ticketDetails.status && ticketDetails.status.name}
					</div>

					<div className={styles.block_message}>
						<div className={styles.block_title}>
							<FormattedMessage
								id="TICKETING.SUBMITED_BY"
								defaultMessage="Submited By"
							/>
						</div>
						{ticketDetails.userId}
						<br />
						{getDateTimeFormat(ticketDetails.createdAt)}
					</div>
				</div>
			</div>
			<hr className={styles.divider} />
			<div>
				<div className={styles.row_wrapper}>
					<div className={styles.row}>
						<h4
							className={cn(
								styles.to_uppercase,
								styles.title,
								styles.title_margin,
								styles.title_edit
							)}
						>
							<FormattedMessage id="TICKETING.ISSUE" defaultMessage="Issue" />
						</h4>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.CATEGORY"
									defaultMessage="Category"
								/>
							</div>
							{ticketDetails.category && ticketDetails.category.name}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.SUBCATEGORY_OPTIONAL"
									defaultMessage="Subcategory (optional)"
								/>
							</div>
							{ticketDetails.firstSubCategory &&
								ticketDetails.firstSubCategory.name}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.SUBCATEGORY_OPTIONAL"
									defaultMessage="Subcategory (optional)"
								/>
							</div>
							{ticketDetails.secondSubCategory &&
								ticketDetails.secondSubCategory.name}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.SUBCATEGORY_OPTIONAL"
									defaultMessage="Subcategory (optional)"
								/>
							</div>
							{ticketDetails.thirdSubCategory &&
								ticketDetails.thirdSubCategory.name}
						</div>

						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ROAMING_ISSUE"
									defaultMessage="Roaming Issue"
								/>
							</div>
							{ticketDetails.endpointIssue.roamingIssue === null && '-'}
							{ticketDetails.endpointIssue.roamingIssue === true && (
								<FormattedMessage id="YES" defaultMessage="Yes" />
							)}
							{ticketDetails.endpointIssue.roamingIssue === false && (
								<FormattedMessage id="NO" defaultMessage="No" />
							)}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.SEVERITY"
									defaultMessage="Severity"
								/>
							</div>
							{ticketDetails.severity && ticketDetails.severity.name}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.PRIORITY"
									defaultMessage="Priority"
								/>
							</div>
							{ticketDetails.priority && ticketDetails.priority.name}
						</div>
					</div>
					<div className={styles.row}>
						<h4
							className={cn(
								styles.to_uppercase,
								styles.title,
								styles.title_margin,
								styles.title_edit
							)}
						>
							<FormattedMessage
								id="TICKETING.ENDPOINT"
								defaultMessage="Endpoint"
							/>
						</h4>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage id="TICKETING.ICCID" defaultMessage="ICCID" />
							</div>
							{ticketDetails.endpointIssue.iccid}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage id="TICKETING.IMSI" defaultMessage="IMSI" />
							</div>
							{ticketDetails.endpointIssue.imsi}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.MSISDN"
									defaultMessage="MSISDN"
								/>
							</div>
							{ticketDetails.endpointIssue.msisdn}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage id="TICKETING.APN" defaultMessage="APN" />
							</div>
							{ticketDetails.endpointIssue.apn}
						</div>

						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_COUNTRY"
									defaultMessage="Endpoint Country"
								/>
							</div>
							{ticketDetails.endpointIssue.country &&
								ticketDetails.endpointIssue.country.name}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_STREET_ADDRESS"
									defaultMessage="Endpoint Street Address"
								/>
							</div>
							{ticketDetails.endpointIssue.deviceLocationAddress}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_CITY"
									defaultMessage="Endpoint City"
								/>
							</div>
							{ticketDetails.endpointIssue.deviceLocationCity}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_STATE"
									defaultMessage="Endpoint State"
								/>
							</div>
							{ticketDetails.endpointIssue.state &&
								ticketDetails.endpointIssue.state.name}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ZIP_CODE"
									defaultMessage="ZIP Code"
								/>
							</div>
							{ticketDetails.endpointIssue.zipCode}
						</div>
					</div>
					<div className={styles.row}>
						<h4
							className={cn(
								styles.to_uppercase,
								styles.title,
								styles.title_margin,
								styles.title_edit
							)}
						>
							<FormattedMessage
								id="TICKETING.DETAILS"
								defaultMessage="Details"
							/>
						</h4>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_MODEL"
									defaultMessage="Endpoint model"
								/>
							</div>
							{ticketDetails.endpointIssue.endpointModel}
						</div>

						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.WAS_TROUBLESHOOTING_DONE"
									defaultMessage="Was troubleshooting done?"
								/>
							</div>
							{ticketDetails.endpointIssue.troubleshootingConfirmationId ===
							1 ? (
									<FormattedMessage id="YES" defaultMessage="Yes" />
								) : (
									<FormattedMessage id="NO" defaultMessage="No" />
								)}
						</div>

						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.PROBLEM_DESCRIPTION"
									defaultMessage="Problem Description"
								/>
							</div>
							<pre>{ticketDetails.endpointIssue.issue}</pre>
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage id="TICKETING.NOTES" defaultMessage="Notes" />
							</div>
							<pre>{ticketDetails.endpointIssue.issueDetails}</pre>
						</div>
						{
							<div className={styles.block_message}>
								<div className={styles.block_title}>
									<FormattedMessage
										id="TICKETING.ATTACH_FILE"
										defaultMessage="Attach File"
									/>
								</div>
								<div>
									{ticketDetails &&
										ticketDetails.ticketFile &&
										ticketDetails.ticketFile.length > 0 &&
										ticketDetails.ticketFile.map((item) => item.fileName)}
								</div>
							</div>
						}
					</div>
					<div className={styles.row}>
						<h4
							className={cn(
								styles.to_uppercase,
								styles.title,
								styles.title_margin,
								styles.title_edit
							)}
						>
							<FormattedMessage
								id="TICKETING.CUSTOMER"
								defaultMessage="Customer"
							/>
						</h4>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ACCOUNT_NAME"
									defaultMessage="Account Name"
								/>
							</div>
							{ticketDetails.accountName}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.ACCOUNT_NUMBER"
									defaultMessage="Account Number"
								/>
							</div>
							{ticketDetails.accountId}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage
									id="TICKETING.CREATED_BY"
									defaultMessage="Created By"
								/>
							</div>
							{ticketDetails.userId}
						</div>
						<div className={styles.block_message}>
							<div className={styles.block_title}>
								<FormattedMessage id="TICKETING.EMAIL" defaultMessage="Email" />
							</div>
							{ticketDetails.user.email}
						</div>
					</div>
				</div>
			</div>
			<CardsTable
				data-spec="ticket-details-table-mobile"
				data={ticketDetails.history}
				options={getOptions('mobile')}
			/>
		</div>
	);
};

const { shape, func, string, object, array } = PropTypes;

Mobile.propTypes = {
	ticketDetails: shape({
		externalTicketId: string,
		status: object,
		createdAt: string,
		userId: string,
		category: object,
		endpointIssue: object,
		severity: object,
		priority: object,
		ticketFile: array,
		accountId: string,
		user: object
	}),
	getOptions: func,
	getDateTimeFormat: func
};

export default hoc()(Mobile);
