import { Status } from '../../../../utils/constants';

/* ******************** ADD USERS MODAL ******************** */
export const isAddUsersModalOpened = (state) =>
	state.onboarding.users.addUsersModal.isOpen;

/* ******************** REMOVE USER MODAL ******************** */
export const isRemoveUserModalOpened = (state) =>
	state.onboarding.users.removeUserModal.isOpen;

/* ******************** PAUSE USER MODAL ******************** */
export const isPauseUserModalOpened = (state) =>
	state.onboarding.users.pauseUserModal.isOpen;

/* ******************** GET USERS ******************** */
export const getUsersRequest = (state) =>
	state.onboarding.users.getUsers.status === Status.PENDING;
export const getUsersFail = (state) =>
	state.onboarding.users.getUsers.status === Status.FAIL;
export const getUsersSuccess = (state) => state.onboarding.users.getUsers.data;

/* ******************** GET USER ******************** */
export const getUserRequest = (state) =>
	state.onboarding.users.getUser.status === Status.PENDING;
export const getUserFail = (state) =>
	state.onboarding.users.getUser.status === Status.FAIL;
export const getUserSuccess = (state) => state.onboarding.users.getUser.data;

/* ******************** EDIT USER ******************** */
export const editUserRequest = (state) =>
	state.onboarding.users.editUser.status === Status.PENDING;
export const editUserFail = (state) =>
	state.onboarding.users.editUser.status === Status.FAIL;
export const editUserSuccess = (state) =>
	state.onboarding.users.editUser.status === Status.DONE;

/* ******************** INVITE USERS ******************** */
export const inviteUsersRequest = (state) =>
	state.onboarding.users.inviteUsers.status === Status.PENDING;
export const inviteUsersFail = (state) =>
	state.onboarding.users.inviteUsers.status === Status.FAIL;
export const inviteUsersSuccess = (state) =>
	state.onboarding.users.inviteUsers.data &&
	state.onboarding.users.inviteUsers.data.status === 'Success';

/* ******************** ADD USER GROUP MODAL ******************** */
export const isAddUserGrupModalOpened = (state) =>
	state.onboarding.users.addUserGroupModal.isOpen;

/* ******************** PAUSE USER ******************** */
export const getPauseUserRequest = (state) =>
	state.onboarding.users.pauseUser.status === Status.PENDING;
export const getPauseUserFail = (state) =>
	state.onboarding.users.pauseUser.status === Status.FAIL;
export const getPauseUserSuccess = (state) =>
	state.onboarding.users.pauseUser.data;

/* ******************** REACTIVATE USER ******************** */
export const getReactivateUserRequest = (state) =>
	state.onboarding.users.reactivateUser.status === Status.PENDING;
export const getReactivateUserFail = (state) =>
	state.onboarding.users.reactivateUser.status === Status.FAIL;
export const getReactivateUserSuccess = (state) =>
	state.onboarding.users.reactivateUser.data;

/* ******************** RESET USER PASSWORD ******************** */
export const getUserResetPasswordRequest = (state) =>
	state.onboarding.users.resetUserPassword.status === Status.PENDING;
export const getUserResetPasswordFail = (state) =>
	state.onboarding.users.resetUserPassword.status === Status.FAIL;
export const getUserResetPasswordSuccess = (state) =>
	state.onboarding.users.resetUserPassword.data;

/* ******************** REMOVE USER ******************** */
export const removeUserRequest = (state) =>
	state.onboarding.users.removeUser.status === Status.PENDING;
export const removeUserFail = (state) =>
	state.onboarding.users.removeUser.status === Status.FAIL;
export const removeUserSuccess = (state) =>
	state.onboarding.users.removeUser.data;

/* ******************** GET ADDRESS TYPES ******************** */
export const getAddressTypeRequest = (state) =>
	state.onboarding.users.getAddressTypes.status === Status.PENDING;
export const getAddressTypeFail = (state) =>
	state.onboarding.users.getAddressTypes.status === Status.FAIL;
export const getAddressTypeSuccess = (state) =>
	state.onboarding.users.getAddressTypes.data;

/* ******************** DELETE PROFILE  ******************** */
export const deleteProfileRequest = (state) =>
	state.onboarding.users.deleteUserProfile.status === Status.PENDING;
export const deleteProfileFail = (state) =>
	state.onboarding.users.deleteUserProfile.status === Status.FAIL;
export const deleteProfileSuccess = (state) =>
	state.onboarding.users.deleteUserProfile.data;

/* ******************** GET COUNTRIES ******************** */
export const getCountriesRequest = (state) =>
	state.onboarding.users.getCountries.status === Status.PENDING;
export const getCountriesFail = (state) =>
	state.onboarding.users.getCountries.status === Status.FAIL;
export const getCountriesSuccess = (state) =>
	state.onboarding.users.getCountries.data;

/* ******************** GET FIRST ADDRESS STATES ******************** */
export const getFirstAddressStatesRequest = (state) =>
	state.onboarding.users.getFirstAddressStates.status === Status.PENDING;
export const getFirstAddressStatesFail = (state) =>
	state.onboarding.users.getFirstAddressStates.status === Status.FAIL;
export const getFirstAddressStatesSuccess = (state) =>
	state.onboarding.users.getFirstAddressStates.data;

/* ******************** GET SECOND ADDRESS STATES ******************** */
export const getSecondAddressStatesRequest = (state) =>
	state.onboarding.users.getSecondAddressStates.status === Status.PENDING;
export const getSecondAddressStatesFail = (state) =>
	state.onboarding.users.getSecondAddressStates.status === Status.FAIL;
export const getSecondAddressStatesSuccess = (state) =>
	state.onboarding.users.getSecondAddressStates.data;

/* ******************** GET THIRD ADDRESS STATES ******************** */
export const getThirdAddressStatesRequest = (state) =>
	state.onboarding.users.getThirdAddressStates.status === Status.PENDING;
export const getThirdAddressStatesFail = (state) =>
	state.onboarding.users.getThirdAddressStates.status === Status.FAIL;
export const getThirdAddressStatesSuccess = (state) =>
	state.onboarding.users.getThirdAddressStates.data;

/* ******************** GET LANGUAGES ******************** */
export const getLanguagesRequest = (state) =>
	state.onboarding.users.getLanguages.status === Status.PENDING;
export const getLanguagesFail = (state) =>
	state.onboarding.users.getLanguages.status === Status.FAIL;
export const getLanguagesSuccess = (state) =>
	state.onboarding.users.getLanguages.data;
