import { connect } from 'react-redux';
import EditTableModal from './EditTableModal';

import {
	anyModalOpened,
	anyModalClosed
} from '../../../Shared/redux/modals/actions';

const mapDispatchToProps = (dispatch) => ({
		anyModalClosed: () => dispatch(anyModalClosed()),
		anyModalOpened: () => dispatch(anyModalOpened())
	});

export default connect(
	null,
	mapDispatchToProps
)(EditTableModal);
