export default {
	// Filter
	CURRENCY: 'Währung',
	BILLABLE: 'In Rechnung zu stellen',
	TAX_STATUS: 'Steuerstatus',
	SUBSCRIBER_STATUS: 'Abonnentenstatus',
	MONTHLY_RATE_PLAN: 'Monatstarifplan',
	STANDARD_RATE_PLAN: 'Standard-Tarifplan',
	PREPAID_TERMS_CHARGED: 'Prepaid-Bedingungen berechnet',
	PLAN_TYPE: 'Plantyp',
	ZONE: 'Zone',
	ROAMING_ZONE: 'Roaming-Zone',
	GROUP_NAME: 'Gruppenname',
	THE_PLAN_TYPE: 'Der Plantyp',
	ACTIVATION_TYPE: 'Aktivierungstyp',
	CHARGE_TYPE: 'Kostentyp',
	TECH_TYPE: 'Tech-Typ',
	PRIMARY_PLACE: 'Primärer Ort',
	DISTRICT_COUNTY: 'Distrikt Landkreis',
	DISTRICT_CITY: 'Distrikt Stadt',
	PRIMARY_PLACE_OF_USE: 'Primärer Verwendungsort',
	// Benachrichtigungen
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': 'Benutzereinstellungen erfolgreich aktualisiert',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'Benutzereinstellungen können nicht aktualisiert werden',
	// Rechnungen
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'Konto-ID',
	'ANALYTICS_INVOICES.ADD_NOTE': 'Anmerkung hinzufügen',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Anhang hinzufügen',
	'ANALYTICS_INVOICES.COUNTRY_NAME': 'Land',
	'ANALYTICS_INVOICES.CUSTOMER_ID': 'Kunden-ID',
	'ANALYTICS_INVOICES.INVOICE_DATE': 'Rechnungsdatum',
	'ANALYTICS_INVOICES.DOCUMENT_ID': 'Dokument-ID',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Unterkonto',
	'ANALYTICS_INVOICES.NOTES': 'Anmerkungen',
	'ANALYTICS_INVOICES.LINK': 'Rechnungen',
	'ANALYTICS_INVOICES.BY': 'Nach',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Hier anzuzeigende Statistik auswählen',
	'ANALYTICS_INVOICES.NA': 'n. z.',
	'ANALYTICS_INVOICES.YES': 'Ja',
	'ANALYTICS_INVOICES.NO': 'Nein',
	'ANALYTICS_INVOICES.INVOICE_ID': 'Rechnungs-ID',
	'ANALYTICS_INVOICES.BILLING_CYCLE': 'Abrechnungszyklus',
	'ANALYTICS_INVOICES.ACCOUNT_ID': 'Konto-ID',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Kontoname',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'Bedienerkonto-ID',
	'ANALYTICS_INVOICES.DEVICES': 'Geräte',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Gesamtkosten',
	'ANALYTICS_INVOICES.CURRENCY': 'Währung',
	'ANALYTICS_INVOICES.DATA_VOLUME': 'Datenvolumen (MB)',
	'ANALYTICS_INVOICES.SMS_VOLUME': 'SMS-Volumen',
	'ANALYTICS_INVOICES.BILLABLE': 'In Rechnung zu stellen (Ja oder Nein)',
	'ANALYTICS_INVOICES.BILL': 'In Rechnung zu stellen',
	'ANALYTICS_INVOICES.DATA_RATES': 'Datentarife',
	'ANALYTICS_INVOICES.DATE': 'Rechnungsdatum',
	'ANALYTICS_INVOICES.BILLING_DETAILS': 'Abrechnungsdetails',
	'ANALYTICS_INVOICES.DUE_DATE': 'Fälligkeitsdatum',
	'ANALYTICS_INVOICES.OPERATOR_ID': 'Bediener-ID',
	'ANALYTICS_INVOICES.PUBLISHED': 'Veröffentlicht',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Standard-Überschreitung',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Standard-Roaming',
	'ANALYTICS_INVOICES.TAXABLE': 'Zu besteuerndes Konto',
	'ANALYTICS_INVOICES.TAX_STATUS': 'Steuerstatus',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Kontosegment',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'Anzeigen/Drucken/Speichern',
	'ANALYTICS_INVOICES.INVOICE_TITLE': 'Rechnungsdetails',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Rechnung herunterladen',
	'ANALYTICS_INVOICES.ENDPOINTS': 'Endpunkte',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Prepaid-Abonnements',
	'ANALYTICS_INVOICES.ADDONS': 'Zubuchoptionen',
	'ANALYTICS_INVOICES.RATE_PLANS': 'Tarifpläne',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Tarifplanzonen',
	'ANALYTICS_INVOICES.BILLING_GROUPS': 'Abrechnungsgruppen',
	'ANALYTICS_INVOICES.DOWNLOAD': 'Herunterladen',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'PDF herunterladen',
	'ANALYTICS_INVOICES.UPLOAD': 'Hochladen',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Rechnung hochladen',
	'ANALYTICS_INVOICES.ACTIVATIONS': 'Aktivierungen',
	'ANALYTICS_INVOICES.DISCOUNTS': 'Rabatte',
	'ANALYTICS_INVOICES.TAX': 'Steuer',
	'ANALYTICS_INVOICES.SURCHARGES': 'Zuschläge',
	'ANALYTICS_INVOICES.TECH_TYPES': 'Tech-Typen',
	'ANALYTICS_INVOICES.OTHER_CHARGES': 'Sonstige Gebühren',
	'ANALYTICS_INVOICES.NO_DATA': 'Keine Rechnungen verfügbar',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': 'Es sind derzeit keine Rechnungen zur Ansicht verfügbar',
	'ANALYTICS_INVOICES.TITLE': 'Rechnungen',
	'ANALYTICS_INVOICES.DATA_DETAILS': 'Daten-Details',
	'ANALYTICS_INVOICES.SMS_DETAILS': 'SMS-Details',
	'ANALYTICS_INVOICES.TAXES': 'Steuern',
	// Endpunkte - Tabelle
	'ANALYTICS_INVOICES.ICCID': 'ICCID',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Montatlicher Zuteilungsindex',
	'ANALYTICS_INVOICES.DEVICE_ID': 'Geräte-ID',
	'ANALYTICS_INVOICES.CUSTOMER': 'Kunde',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Monatstarifplan',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Prepaid-Bedingungen berechnet',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Standard-Tarifplan',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'Abonnentenstatus',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Abonnementgebühr',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Feste Pool-Gebühr',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'SMS MO-Volumen(Nachr.)',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'SMS MT-Volumen(Nachr.)',
	// Prepaid-Abonnements - Tabelle
	'ANALYTICS_INVOICES.MSISDN': 'MSISDN',
	'ANALYTICS_INVOICES.RATE_PLAN': 'Tarifplan',
	'ANALYTICS_INVOICES.PLAN_VERSION': 'Planversion',
	'ANALYTICS_INVOICES.PLAN_TYPE': 'Plantyp',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Nutzungs-Zuteilungsindex',
	'ANALYTICS_INVOICES.TERM_START_DATE': 'Laufzeitanfangsdatum',
	'ANALYTICS_INVOICES.TERM_END_DATE': 'Laufzeitenddatum',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'Datennutzung inbegriffen MB',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'SMS MO inbegriffen (Nachr.)',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'SMS MT inbegriffen (Nachr.)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Voice inbegriffen (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'Voice-MO inbegriffen (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'Voice-MT inbegriffen (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD': 'CSD inbegriffen (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'CSD MO inbegriffen (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'CSD MT inbegriffen (m:ss)',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': 'Datennutzung Laufzeit bis dato (primäre Zone)',
	'ANALYTICS_INVOICES.VOICE_VOLUME': 'Voice-Volumen (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Voice-MO-Volumen (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Voice-MT-Volumen (m:ss)',
	// Endpunkt- Tabelle
	'ANALYTICS_INVOICES.ADDON_SIZE': 'Zubuchoptions-Größe (MB)',
	'ANALYTICS_INVOICES.ZONE': 'Zone',
	'ANALYTICS_INVOICES.ADDON_CHARGES': 'Zubuchoptions-Gebühren',
	'ANALYTICS_INVOICES.DATE_PURCHASED': 'Kaufdatum',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Basisplan angewendet auf',
	// Tarifplan- Tabelle
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': 'Aktivierungszeitraum-Verpflichtung Abonn.',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Aktive Abonnements',
	'ANALYTICS_INVOICES.ACTIVE_TIER': 'Aktive Stufe',
	'ANALYTICS_INVOICES.DATA_CHARGE': 'Datengebühr',
	'ANALYTICS_INVOICES.INCLUDED_DATA': 'Inbegriffene Daten',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Modus Inbegriffene Daten',
	'ANALYTICS_INVOICES.INCLUDED_SMS': 'Inbegriffene SMS',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Modus SMS MO inbegriffen',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Modus SMS inbegriffen',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Modus SMS MT inbegriffen',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Modus Voice-MO inbegriffen',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Modus Voice inbegriffen',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': 'Mindestaktivierungszeitraum Abb.',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Nicht-Roaming-Datengebühren',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Nicht-Roaming-Datenvolumen',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Zahlungstyp',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Prepaid-Aktivierungen',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Roaming-Datengebühren',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Roaming-Datenvolumen',
	'ANALYTICS_INVOICES.SMS_CHARGE': 'SMS-Gebühren',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Abbonement-Feld',
	'ANALYTICS_INVOICES.VOICE_CHARGE': 'Voice-Gebühren',
	// Tarifplanzonen - Tabelle
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Aktiver Überschreitungstarif',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Obergrenze inbegriffene Daten',
	'ANALYTICS_INVOICES.INCLUDED_MODE': 'Modus inbegriffen',
	'ANALYTICS_INVOICES.PRIORITY': 'Priorität',
	'ANALYTICS_INVOICES.ROAMING_ZONE': 'Roaming-Zone',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Insgesamt enthaltene Nutzung (MB)',
	//  Abrechnungsgruppen - Tabelle
	'ANALYTICS_INVOICES.GROUP_NAME': 'Gruppenname',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Insgesamt enthaltene Summe',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'SMS MO-Gebühren',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'SMS MO inbegriffen (Nachr.)',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': 'SMS MO-Nutzung inbegriffen Gesamtsumme',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': 'Insgesamt enthaltene Summe Voice-MO',
	// Datendetails - Tabelle
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Tägliche Rundungsanpassung',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'Der Plantyp',
	// SMS-Details - Tabelle
	'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Nachrichtentyp',
	// Aktivierungen - Tabelle
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Aktivierungsgebühr',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Aktivierungstyp',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Datum erste Aktivierung',
	// Rabatte - Tabelle
	'ANALYTICS_INVOICES.CHARGE_TYPE': 'Kostentyp',
	'ANALYTICS_INVOICES.DISCOUNT': 'Rabatt',
	// Steuern - Tabelle
	'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Primärer Ort',
	'ANALYTICS_INVOICES.MRC': 'MRC',
	'ANALYTICS_INVOICES.DATA': 'Daten',
	'ANALYTICS_INVOICES.SMS': 'SMS',
	'ANALYTICS_INVOICES.VOICE': 'Voice',
	'ANALYTICS_INVOICES.ACTIVATION': 'Aktivierung',
	'ANALYTICS_INVOICES.VPN': 'VPN',
	'ANALYTICS_INVOICES.SIM': 'SIM',
	'ANALYTICS_INVOICES.MRF': 'MRF',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': 'Tech-Typ',
	// Zuschläge - Tabelle
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Primärer Verwendungsort',
	'ANALYTICS_INVOICES.FEDERAL': 'Bund',
	'ANALYTICS_INVOICES.STATE': 'Bundesland',
	'ANALYTICS_INVOICES.COUNTY': 'Landkreis',
	'ANALYTICS_INVOICES.CITY': 'Ort',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'Distrikt Landkreis',
	'ANALYTICS_INVOICES.DISTRICT_CITY': 'Distrikt Ort',
	// Tech-Typ - Tabelle
	'ANALYTICS_INVOICES.PLAN_ID': 'Plan-ID',
	'ANALYTICS_INVOICES.VERSION_ID': 'Versions-ID',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'Anz. 2G-Geräte',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': '2G-Tech-Typ-Gebühr',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'Anz. 3G-Geräte',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': '3G-Tech-Typ-Gebühr',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'Anz. 4G-Geräte',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': '4G-Tech-Typ-Gebühr',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Tech-Typ-Berechnungsfrequenz',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Tech-Typ-Berechnungssumme',

	// Abonnementgebühren:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Abonnementgebühren',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Abonnementgebühr',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Datengebühr',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'SMS-Gebühren',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Aktivierungsgebühr',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Tech-Typ-Gebühr',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Festes Rabattziel',
	'ANALYTICS_INVOICES_DETAILS.TAX': 'Steuer',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Gesamtkosten',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Zuschlag',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Fällig bis',

	// Endpunktstatistik:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Endpunktstatus',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'ENDPUNKTE',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'AKTIVE ABONNENTEN',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'DATENVOLUMEN',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'SMS-VOLUMEN',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'SERVICE-UMSATZ',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'VARIABLER RABATT',

	// Andere Gebühren - Tabelle
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Berechnungsbetrag',
	'ANALYTICS_INVOICES.DESCRIPTION': 'Beschreibung',
	'ANALYTICS_INVOICES.QUANTITY': 'Menge',
	'ANALYTICS_INVOICES.ORDER_NUMBER': 'Bestellungsnummer',
	'ANALYTICS_INVOICES.REFERENCE_ID': 'Referenz-ID',
	//  Platzhalter
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Anmerkung hier eingeben',
	'ANALYTICS_INVOICES.REMOVE': 'Entfernen',
	'ANALYTICS_INVOICES.EDIT_NOTE': 'Anmerkung bearbeiten',
	'ANALYTICS_INVOICES.CANCEL': 'Abbrechen',
	'ANALYTICS_INVOICES.SUBMIT': 'Absenden',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Anlagen hinzugefügt',
	//  Tabelle bearbeiten modal
	'ANALYTICS_INVOICES.APPLY_CHANGE': 'Änderung übernehmen',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Auf Voreinstellung zurücksetzen',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Tabellenparameter bearbeiten',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': 'Mindestens 2 auswählen und auf Tabelle anwenden. ',
	'ANALYTICS_INVOICES.OF': ' von ',
	'ANALYTICS_INVOICES.SELECTED': ' Ausgewählt',
	// Rechnungsdetails
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'Keine Daten verfügbar',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': 'Keine Daten für diese Rechnung vorhanden.',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': 'Die Datenbank für Rechnungsdetails ist für diese Rechnung leer',
	'ANALYTICS_INVOICES.ADD_FILES': 'Rechnungsdateien',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'Anzeigen/Hinzufügen/Bearbeiten',
	//  ANHANG MODALDIALOG
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Anhängefür Rechnung',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Anhang hinzufügen',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Datei hochladen',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Anhang löschen',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Schließen',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Abbrechen',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Speichern',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Löschen',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Datei hochladen',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Anhang hochladen',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Rechnung hochladen',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'Dateiname',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Beschreibung',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': 'Sie stehen kurz davor, einen Anhang von MC zu entfernen. Damit wird diese Datei dauerhaft aus dem System gelöscht. Sind Sie sicher, dass Sie diese Aufgabe ausführen möchten?',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'Zu löschende Datei:',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'Angehängte Datei',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': 'Dateiname (Falls leer, wird der Standard-Dateiname verwendet)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': 'Dateibeschreibung, für Kunden sichtbar (optional)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': 'Diese Rechnung wird in ein paar Wochen aktiv sein.',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': 'Datei zum Hochladen ziehen oder klicken',
	'INVOICES_NOTES_UPDATE.SUCCESS': 'Rechnungsanmerkung mit Erfolg aktualisiert.',
	//  ANMERKUNGEN MODALDIALOG
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'Aktualisierung erfolgreich',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': 'Ihre Anmerkung wurde mit Erfolg hinzugefügt.',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Aktualisierung fehlgeschlagen',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': 'Ihre Anmerkung wurde mit Erfolg hinzugefügt.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'Upload erfolgreich',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Datei mit Erfolg hochgeladen.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Fehler beim Hochladen',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Beim Hochladen dieser Datei zu MC ist ein Fehler aufgetreten.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': 'Anhang gelöscht',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': 'Die Datei wurde von MC entfernt.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': 'Fehler beim Löschen',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': 'Beim Entfernen dieser Datei von MC ist ein Fehler aufgetreten.',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Rechnungsdatum',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': 'Die Rechnungsdetails können nicht angezeigt werden, da der Rechnungszyklus ungültig ist.',
	// NEUE RECHNUNG HINZUFÜGEN
	'INVOICES.ADD_NEW_INVOICE': 'Neue Rechnung hinzufügen',
	'INVOICES.CANCEL': 'Abbrechen',
	'INVOICES.ADD_INVOICE': 'Rechnung hinzufügen',
	'INVOICES.EDIT': 'Bearbeiten',
	'INVOICES.CONFIRM': 'Bestätigen',
	'INVOICES.CONFIRMATION': 'Bestätigung',
	'INVOICES.INVOICE_DETAILS': 'Rechnungsdetails',
	'ADD_INVOICE.INVOICE_ID': 'Rechnungs-ID',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'M2M Konto-ID auswählen',
	'ADD_INVOICE.INVOICE_DATE': 'Rechnungsdatum',
	'ADD_INVOICE.BILLING_CYCLE': 'Abrechnungszyklus',
	'ADD_INVOICE.DATE_PLACEHOLDER': 'Datum auswählen',
	'ADD_INVOICE.UPLOAD_FILE': 'Datei hochladen',
	'ADD_INVOICE.UPLOAD_FILE_NAME': 'Dateiname (Optional: Falls leer, wird der Standard-Dateiname verwendet)',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': 'Dateiname (max. 255 Zeichen)',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': 'Dateiname',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Sie haben die neue Rechnung mit Erfolg hinzugefügt.',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Beim Hinzufügen der Rechnung ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.',
	'ADD_INVOICE.TIME_STAMP': 'Angehängt am {date} um {time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION': 'Ungültiger Dateiname (Verwenden Sie bitte _ anstelle des Leerzeichens. Die folgenden Zeichen sind nicht gestattet: ! ? <> % $ () + =',
};
