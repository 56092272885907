import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import InvoiceDetailsDesktop from './Desktop';
import InvoiceDetailsMobile from './Mobile';

import {
	getInvoiceDetails,
	getInvoiceDetailsInit
} from '../../redux/invoices/actions';

import {
	getInvoiceDetailsRequest,
	getInvoiceDetailsFail,
	getInvoiceDetailsSuccess
} from '../../redux/invoices/selector';

import { simpleSearchClose } from '../../../Shared/redux/simpleSearch/actions';

import { downloadReportRequestURL } from '../../redux/invoiceReport/actions';
import { invoiceReportRequest } from '../../redux/invoiceReport/selectors';
import {
	showNotification,
	hideNotification
} from '../../../Shared/redux/notifications/actions';

const mapStateToProps = (state) => ({
	invoiceRequest: getInvoiceDetailsRequest(state),
	invoiceFail: getInvoiceDetailsFail(state),
	invoice: getInvoiceDetailsSuccess(state),
	downloadInvoiceRequest: invoiceReportRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	getInvoice: (params) => dispatch(getInvoiceDetails(params)),
	pushBack: () => {
		dispatch(simpleSearchClose());
		dispatch(localizedRoute('/invoices'));
	},
	downloadFile: (fileName, selectedAccountId) =>
		dispatch(
			downloadReportRequestURL({
				reportFileName: `${fileName}.pdf`,
				reportCategory: 'BillingInvoiceReport',
				m2mAccountId: selectedAccountId
			})
		),
	showNotification: (message) =>
		dispatch(
			showNotification({
				id: 'invoice-details-notif',
				message: [message],
				type: 'error',
				close: true,
				notificationType: 'admin',
				handleClose: () => dispatch(hideNotification('invoice-details-notif'))
			})
		),
  hideNotification: () => dispatch(hideNotification('invoice-details-notif')),
  getInvoiceDetailsInit: () => dispatch(getInvoiceDetailsInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvoiceDetailsDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvoiceDetailsMobile);
