import React from 'react';
import hash from 'object-hash';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../utils/constants';

const locale = getLocale();
const messages = require(`../../../localizations/${locale}`).default;

const dateFormat = messages.DISPLAY_DATE_FORMAT || 'MM/dd/yyyy';

export const TicketingTableHeader = [
	{
		title: 'TICKET_ID',
		name: 'externalTicketId'
	},
	{
		title: 'CATEGORY_NAME',
		name: 'categoryName'
	},
	{
		title: 'DATE_CREATED',
		name: 'createdAt'
	},
	{
		title: 'CREATED_BY',
		name: 'userId'
	},
	{
		title: 'TICKETING_STATUS',
		name: 'statusName'
	},
	{
		title: 'SEVERITY',
		name: 'severityName'
	},
	{
		title: 'SUBMISSION_LOCATION',
		name: 'deviceLocationCity'
	},
	{
		title: 'UPDATE_NOTE',
		name: 'notes'
	},
	{
		title: 'EXTERNAL_LAST_UPDATED_BY',
		name: 'user'
	},
	{
		title: 'EXTERNAL_LAST_UPDATED_DATE',
		name: 'updatedAt'
	}
];

export const TicketingTableHeaderMobile = [
	{
		title: <FormattedMessage id="TICKET_ID" />,
		name: 'externalTicketId'
	},
	{
		title: <FormattedMessage id="CATEGORY_NAME" />,
		name: 'categoryName'
	}
];

export const ticketSignature = hash(JSON.stringify(TicketingTableHeader));

export const ticketingSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="TICKET_ID" />
			</div>
		),
		value: 'externalTicketId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="CREATED_BY_TICKETING" />
			</div>
		),
		value: 'userId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="SUBMISSION_LOCATION" />
			</div>
		),
		value: 'deviceLocationCity'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="TICKET_CREATED_DATE" />
			</div>
		),
		value: 'createdAt',
		placeholder: dateFormat
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="TICKETING.LAST_UPDATED_DATE" />
			</div>
		),
		value: 'externalCreatedAt',
		placeholder: dateFormat
	}
];

export const notificationMessages = {
	success: 'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE',
	error: 'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE'
};
