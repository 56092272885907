import globalAction from '../../../redux/global/globalAction';

import {
	getFailoverLinks as getFailoverLinksService,
	changeEnvironment as changeEnvironmentService
} from '../services/FailoverService';

import { GET_FAILOVER_LINKS, CHANGE_ENVIRONMENT } from './constants';

export const getFailoverLinks = (params) =>
	globalAction(GET_FAILOVER_LINKS, getFailoverLinksService, params);

export const changeEnvironment = (params) =>
	globalAction(CHANGE_ENVIRONMENT, changeEnvironmentService, params, {
		notify: true,
		init: true
	});
