import React from 'react';
import { object, bool } from 'prop-types';
import cn from 'classnames';
import {
	FormattedMessage,
} from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './DiagnosticHlrDetails.scss';

export default function DiagnosticHlrDetails({
	isPending,
	data,
	isFail,
	isDone,
}) {
	const attributes = data ? data.attributes : {};

	if (isPending) {
		return (
			<FormattedMessage
				id="FETCH_DATA_LOADING_MESSAGE"
				defaultMessage="Please wait while we load the data"
				data-spec="loader"
			>
				{(formattedValue) => (
					<Loader
						msg={formattedValue}
					/>
				)}
			</FormattedMessage>
		);
	}

	return (!data && isDone) || isFail ? (
		<div
			data-spec="diagnostic-hlr-details"
			className={styles.diagnostic_hlr_details}
		>
			<span>
				<FormattedMessage
					id="NO_DATA_TO_DISPLAY_AT_THE_MOMENT"
					defaultMessage="No data to display at the moment"
				/>
			</span>
		</div>
	) : (
		<div
			data-spec="diagnostic-hlr-details"
			className={styles.diagnostic_hlr_details}
		>
			<h3 className={styles.h3}>
				<FormattedMessage
					id="SUBSCRIBER_PROFILE"
					defaultMessage="Subscriber Profile"
				/>
			</h3>
			<div className={styles.profile_data}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="ENDPOINT_ID_ICCID"
							defaultMessage="Endpoint ID - ICCID"
						/>
					</span>
					<span>{attributes.iccid}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="IMSI"
							defaultMessage="IMSI"
						/>
					</span>
					<span>{attributes.imsi}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="MSISDN"
							defaultMessage="MSISDN"
						/>
					</span>
					<span>{attributes.msisdnDataGprs}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="BARRING_STATUS"
							defaultMessage="Barring Status"
						/>
					</span>
					<span>{attributes.barringDataGprs}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NETWORK_ACCESS"
							defaultMessage="Network Access"
						/>
					</span>
					<span>{attributes.networkAccess}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="CURRENT_VLR_NUMBER"
							defaultMessage="Current VLR Number"
						/>
					</span>
					<span>{attributes.numberVlr}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="PREVIOUS_VLR_NUMBER"
							defaultMessage="Previous VLR Number"
						/>
					</span>
					<span>{attributes.numberVlrPrevious}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="CURRENT_SGSN_NUMBER"
							defaultMessage="Current SGSN Number"
						/>
					</span>
					<span>{attributes.numberSgsn}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="PREVIOUS_SGSN_NUMBER"
							defaultMessage="Previous SGSN Number"
						/>
					</span>
					<span>{attributes.numberSgsnPrevious}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="ROAMING_RESTRICTION"
							defaultMessage="Roaming Restriction"
						/>
					</span>
					<span>{attributes.roamingRestriction}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="DATA_3G_ENABLED"
							defaultMessage="3G Data Enabled"
						/>
					</span>
					<span>{attributes.dataHspaEnabled}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NETWORK_STATUS"
							defaultMessage="Network Status"
						/>
					</span>
					<span>{attributes.networkStatus}</span>
				</div>
			</div>
			<h3 className={styles.h3}>
				<FormattedMessage
					id="APN_LIST"
					defaultMessage="APN List"
				/>
			</h3>
			{attributes.epsList && attributes.epsList.map((apn) => (
				<div className={styles.apn_list_data} key={apn.apnIdentifier}>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="APN_IDENTIFIER"
								defaultMessage="APN Identifier"
							/>
						</span>
						<span>{apn.apnIdentifier}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="IP_ADDRESS_TYPE"
								defaultMessage="IP Address Type"
							/>
						</span>
						<span>{apn.ipAddressType}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="IP_ADDRESS"
								defaultMessage="IP Address"
							/>
						</span>
						<span>{apn.ipAddress}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="QOS_PROFILE"
								defaultMessage="QOS Profile"
							/>
						</span>
						<span>{apn.qosProfile}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="PDN_GW_DYNAMIC_ALLOCATION"
								defaultMessage="PDN GW Dynamic Allocation"
							/>
						</span>
						<span>{apn.pdnGwDynamicAllocation}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="VPLMN_ADDRESS_ALLOWED"
								defaultMessage="VPLMN Address Allowed"
							/>
						</span>
						<span>{apn.vplmnAddressAllowed}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="MAX_UPLOAD"
								defaultMessage="Max Upload"
							/>
						</span>
						<span>{apn.maxBandwidthUp}</span>
					</div>
					<div className={cn(styles.data, styles.apn)}>
						<span>
							<FormattedMessage
								id="MAX_DOWNLOAD"
								defaultMessage="Max Download"
							/>
						</span>
						<span>{apn.maxBandwidthDown}</span>
					</div>
				</div>
			))}
		</div>
	);
}

DiagnosticHlrDetails.propTypes = {
	isPending: bool,
	isDone: bool,
	isFail: bool,
	data: object,
};
