import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../../lib/DigitalComponents/Loader';
import Button from '../../../../lib/DigitalComponents/Button';
import DSLastStep from '../../../Steps';
import styles from './StepThree.scss';

class StepThree extends PureComponent {
	componentDidMount() {
		const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
		const { batchFileUploadRequest, context } = this.props;
		if (!batchFileUploadRequest && prevProps.batchFileUploadRequest) {
			this.setFooter(context);
		}
	}

	onClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	setFooter = (context) => {
		const { batchFileUploadRequest } = this.props;
		const footer = (
			<>
				<Button
					dataSpec="done-upload"
					variant="success"
					onClick={() => this.onClose()}
					disabled={batchFileUploadRequest}
					label={
						<FormattedMessage
							id="SECURITY_FEATURES.DONE"
							defaultMessage="Done"
						/>
					}
				/>
			</>
		);
		context.updateContext({ footer });
	};

	renderLastStepFail = () => {
		const { statusUploadErrors, batchFileUploadErrors } = this.props;

		const errors = batchFileUploadErrors || null;

		const allStatusUploadErrors = statusUploadErrors || null;
		return (
			<DSLastStep
				data-spec="last-step-fail"
				title={
					<FormattedMessage
						id="SECURITY_FEATURES.LAST_STEP_FAIL_TITLE"
						defaultMessage="Batch file system error!"
					/>
				}
				msg={
					<FormattedMessage
						id="SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE"
						defaultMessage="There was a problem processing your updates. Please try again later."
					/>
				}
				type="error"
				errors={errors}
				statusUploadErrors={allStatusUploadErrors}
				className={styles.last_step}
			/>
		);
	};

	renderLastStepSuccess = () => (
		<DSLastStep
			data-spec="last-step-success"
			title={
				<FormattedMessage
					id="SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE"
					defaultMessage="Batch files successfully received!"
				/>
			}
			msg={
				<FormattedMessage
					id="SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE"
					defaultMessage="Changes are pending at the system completes your updates"
				/>
			}
		/>
	);

	render() {
		const {
			batchFileUploadRequest,
			batchFileUploadFail,
			batchFileUploadSuccess
		} = this.props;

		return (
			<div
				data-spec="step-threee"
				className={styles.modal_body_batch_last_step}
			>
				{batchFileUploadRequest && (
					<FormattedMessage
						id="SECURITY_FEATURES.LOADER_MESSAGE"
						defaultMessage="Please wait. This may take a few minutes, depending on the file size."
					>
						{(formattedValue) => <Loader msg={formattedValue} />}
					</FormattedMessage>
				)}
				{batchFileUploadFail && this.renderLastStepFail()}
				{batchFileUploadSuccess && this.renderLastStepSuccess()}
			</div>
		);
	}
}

const { object, func, bool, shape, arrayOf } = PropTypes;

StepThree.propTypes = {
	context: shape(),
	onClose: func,
	batchFileUploadRequest: bool,
	batchFileUploadSuccess: bool,
	batchFileUploadFail: bool,
	batchFileUploadErrors: arrayOf(object),
	statusUploadErrors: arrayOf(object)
};

export default StepThree;
