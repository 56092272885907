import { handleActions } from 'redux-actions';

import {
	GLOBAL_FILTER_MODAL_OPEN,
	GLOBAL_FILTER_MODAL_CLOSE
} from './constants';

export const initialState = {
	globalFilterModalOpened: false
};

export default handleActions(
	{
		[GLOBAL_FILTER_MODAL_OPEN]: (state) => ({
				...state,
				globalFilterModalOpened: true
			}),
		[GLOBAL_FILTER_MODAL_CLOSE]: (state) => ({
				...state,
				globalFilterModalOpened: false
			})
	},
	initialState
);
