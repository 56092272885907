import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import { RatePlansTableHeader as tableHeader } from '../../utils/constants';

import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';

import styles from './RatePlans.scss';

const CheckIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');

const RatePlans = () => (WrappedComponent) => {
	class RatePlansComponent extends PureComponent {
		componentDidMount() {
			const {
				match: {
					params: { companyId, m2mAccountId, accountId }
				}
			} = this.props;
			this.getRatePlans(companyId, m2mAccountId);
			if (accountId) {
				this.getAccount(accountId);
			}
		}

		getRatePlans = (companyId, m2mAccountId) => {
			const { getRatePlans } = this.props;
			getRatePlans({
				companyId,
				m2mAccountId,
				additionalParams: {
					searchParams: [
						{
							propValue: 'pricePlanTopOff',
							prop: 'include',
							operator: '='
						}
					]
				}
			});
		};

		getAccount = (id) => {
			const { getAccount } = this.props;
			getAccount({
				id,
				additionalParams: {
					include: [
						'Company',
						'PlatformType',
						'PlatformMobileOperator',
						'PlatformSatelliteOperator'
					]
				}
			});
		};

		goToRatePlanDetails = (ratePlanId) => {
			const {
				push,
				match: {
					params: { companyId, accountId, platformType, m2mAccountId }
				}
			} = this.props;
			push(
				`/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/rate-plans/${ratePlanId}`
			);
		};

		getOptions = (target) => {
			const {
				match: {
					params: { companyId }
				}
			} = this.props;
			const { user, account } = this.props;

			const isDesktop = target === 'desktop';

			const options = {
				tableOptions: {
					fixedFirstColumn: true,
					firstColumnMarked: true
				}
			};

			options.header = tableHeader;
			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions
				};
			}

			options.customComponents = {
				name: {
					type: 'custom',
					component: (item) => (
						<span
							className={cn(
								isUserAllowedToAccess(['system.companies_rw'], user) &&
									companyId
									? styles.clickable
									: '',
								styles.rate_plan_name
							)}
							onClick={() =>
								isUserAllowedToAccess(['system.companies_rw'], user) &&
								companyId &&
								this.goToRatePlanDetails(item.ratePlan.id)}
						>
							{item.ratePlan.name}
						</span>
					)
				},
				defaultPlan: {
					type: 'custom',
					component: (item) => (
						<span>
							{Boolean(
								item.ratePlan.default || item.ratePlan.isDefaultIndicator
							) && <CheckIcon className={styles.check_icon} />}
						</span>
					)
				},
				iotAccountName: {
					type: 'custom',
					component: () => account && account.m2mAccountName
				},
				m2mAccountId: {
					type: 'custom',
					component: (item) => item.ratePlan.m2mAccountId
				},
				subscriptionItemType: {
					type: 'custom',
					component: (item) => item.ratePlan.subscriptionItemType
				},
				pricePlanId: {
					type: 'custom',
					component: (item) => item.ratePlan.id
				},
				description: {
					type: 'custom',
					component: (item) => item.ratePlan.description
				}
			};
			return options;
		};

		pushBack = () => {
			const {
				match: {
					params: { companyId, accountId }
				}
			} = this.props;
			const { push } = this.props;

			if (companyId) {
				push(`/companies/${companyId}/accounts/${accountId}`);
			} else {
				push(`/my-company/${accountId}`);
			}
		};

		render() {
			const {
				ratePlansRequest,
				ratePlansFail,
				ratePlans
			} = this.props;

			return (
				<div data-spec="rate-plans">
					<PageTitle
						title={
							<FormattedMessage
								id="ONBOARDING.RATE_PLANS"
								defaultMessage="Rate Plans"
							/>
						}
						pushBack={this.pushBack}
					/>
					{ratePlansRequest && <Loading data-spec="loading" />}
					{ratePlansFail && null}
					{!ratePlansRequest && ratePlans && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}
					{ratePlans &&
						ratePlans.rateplans &&
						ratePlans.rateplans.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.NO_RATE_PLANS_AVAILABLE"
										defaultMessage="No Rate Plans Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_RATE_PLANS_SUBTITLE"
										defaultMessage="There are No Rate Plans for this Table to show you right now"
									/>
								}
								message={
									<FormattedMessage
										id="ONBOARDING.NO_RATE_PLANS_AVAILABLE"
										defaultMessage="No Rate Plans Available"
									/>
								}
							/>
						)}
				</div>
			);
		}
	}
	const { func, bool, object } = PropTypes;

	RatePlansComponent.propTypes = {
		getRatePlans: func,
		ratePlansRequest: bool,
		ratePlansFail: bool,
		ratePlans: object,
		goToEditRatePlan: func,
		match: object,
		push: func,
		getAccount: func,
		account: object,
		user: object
	};
	return RatePlansComponent;
};

export default RatePlans;
