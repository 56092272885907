import React, { PureComponent } from 'react';
import { array, func, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import ActionBar from '../../../Shared/views/ActionBarView';
import Button from '../../../../lib/DigitalComponents/Button';
import Table from '../../../../lib/DigitalComponents/Table';
import Loader from '../../../../lib/DigitalComponents/Loader';

import AssignAccountAccordion from '../AssignAccountAccordion';

import { AssignedAccountsTableHeader as tableHeader } from '../../utils/constants';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import NoData from '../../../Shared/views/NoDataView';

import styles from './UserGroupAssignedAccounts.scss';

import { UserAllowedToAccess } from '../../../../utils/AuthSelector';

const CheckIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');

const RWComponent = UserAllowedToAccess([
	'system.accessmanagement.usergroups_rw',
	'mnc.accessmanagement.usergroups_rw'
]);

class UserGroupAssignedAccounts extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			dataOffset: 0,
			dataLimit: 50,
			selectedPage: 0,
			orderColumn: {},
			expanded: []
		};
	}

	componentDidMount() {
		this.getAccounts();
	}

	getAccounts = () => {
		const { getAccounts } = this.props;
		const { orderColumn, dataLimit, dataOffset } = this.state;

		const searchParams = [
			{ propValue: null, prop: 'parentPlatformId', operator: '=' }
		];
		getAccounts({
			isMyCompany: true,
			searchParams,
			additionalParams: {
				include: ['SubPlatforms', 'SubPlatforms.SubPlatforms'],
				dataLimit: dataLimit || 50,
				dataOffset: dataOffset || 0,
				dataSort: orderColumn.apiString || 'createdAt desc'
			}
		});
	};

	getOptions = (target) => {
		const { accounts, accountsSuccess } = this.props;
		const { selectedPage, orderColumn, dataLimit } = this.state;

		// let isMobile = target === 'mobile';
		const isDesktop = target === 'desktop';

		const options = {};

		options.header = tableHeader;

		options.tableOptions = {
			showPagination:
				accountsSuccess &&
				accountsSuccess.resultList &&
				accountsSuccess.resultList.length > dataLimit,
			pageCount: this.totalCount(),
			forcePage: selectedPage,
			onPageChange: (data) => this.handlePageClick(data)
		};

		if (isDesktop) {
			options.tableOptions = {
				...options.tableOptions,
				orderByData: ['all'],
				excludeFromOrdering: ['actions'],
				getOrderData: (data) => this.onOrderClick(data),
				orderColumn
			};
		}

		options.customComponents = {};

		if (isDesktop) {
			options.customComponents = {
				...options.customComponents,
				m2mAccountName: {
					type: 'custom',
					component: (val, index) => this.renderM2mAccountName(val, index)
				},
				actions: {
					type: 'custom',
					component: (val, index) => this.renderActions(val, index)
				},
				customExpand: {
					type: 'custom',
					component: (val) => (
						<AssignAccountAccordion
							data={val.subPlatforms}
							parentAccountId={val.m2mAccountId}
							accounts={accounts}
						/>
					)
				}
			};
		}
		return options;
	};

	renderM2mAccountName = (val, index) => {
		const getRender = Boolean(val.subPlatforms && val.subPlatforms.length);
		const triggerIcon = cn({
			[styles.trigger__icon]: true,
			[styles.no_subplatforms]: !this.hasSubPlatforms(val),
			[styles.open]: this.findLevel(index)
		});
		return (
			<div data-spec="m2m-acc-name" className={styles.m2m_account_name}>
				{getRender && (
					<span
						onClick={() => this.handleIconClick(index)}
						className={triggerIcon}
					/>
				)}
				<div>{val.m2mAccountName}</div>
			</div>
		);
	};

	renderActions = (data) => {
		const { accounts } = this.props;
		if (accounts && accounts.length > 0) {
			return (
				accounts.find((item) => item.id === data.id) && (
					<CheckIcon className={styles.check_icon} />
				)
			);
		}
		return <CheckIcon className={styles.check_icon} data-spec="checkbox" />;
	};

	totalCount = () => {
		const { accountsSuccess } = this.props;
		const { dataLimit } = this.state;
		return Math.ceil(accountsSuccess && accountsSuccess.totalCount / dataLimit);
	};

	findLevel = (id) => {
		const { expanded } = this.state;
		return expanded.includes(id);
	};

	handleIconClick = (id) => {
		const { expanded } = this.state;
		if (expanded.includes(id)) {
			const index = expanded.indexOf(id);
			this.setState({
				expanded: [...expanded.slice(0, index), ...expanded.slice(index + 1)]
			});
		} else {
			this.setState({
				expanded: [...expanded, id]
			});
		}
	};

	hasSubPlatforms = (level) =>
		Boolean(level && level.subPlatforms && level.subPlatforms.length);

	onOrderClick = (data) => {
		const { name: column, apiString } = data;
		const { orderColumn } = this.state;
		this.setState(
			{
				orderColumn: {
					name: column,
					order: orderColumn.name === column ? !orderColumn.order : true,
					apiString
				}
			},
			() => this.getAccounts()
		);
	};

	handlePageClick = (data) => {
		const { dataLimit } = this.state;
		this.setState(
			{
				dataLimit,
				dataOffset: dataLimit * data.selected,
				selectedPage: data.selected
			},
			() => this.getAccounts()
		);
		window.scrollTo(0, 0);
	};

	render() {
		const {
			accountsRequest,
			accountsSuccess,
			messages,
			goToEditUserGroupAssignedAccounts
		} = this.props;
		const { expanded } = this.state;
		return (
			<div data-spec="user-group-assigned-accounts">
				<ActionBar
					actions={
						<RWComponent>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.EDIT_ASSIGNED_ACCOUNTS"
										defaultMessage="Edit Assigned Accounts"
									/>
								}
								disabled={accountsRequest}
								dataSpec="edit-group-assigned-accounts-button"
								variant="primary"
								onClick={goToEditUserGroupAssignedAccounts}
							/>
						</RWComponent>
					}
				/>
				{accountsRequest && <Loader data-spec="loading" />}
				{!accountsRequest && (
					<Table
						data-spec="accounts-table-desktop"
						data={accountsSuccess && accountsSuccess.resultList}
						options={this.getOptions('desktop')}
						messages={messages}
						expanded={expanded}
					/>
				)}
				{accountsSuccess &&
					accountsSuccess.resultList &&
					accountsSuccess.resultList.length === 0 && (
						<NoData
							title={
								<FormattedMessage
									id="ONBOARDING.NO_ACCOUNTS_AVAILABLE"
									defaultMessage="No Accounts Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE"
									defaultMessage="There are No Accounts for this Table to show you right now"
								/>
							}
							table
						/>
					)}
			</div>
		);
	}
}

UserGroupAssignedAccounts.propTypes = {
	getAccounts: func,
	accounts: array,
	accountsRequest: bool,
	accountsSuccess: object,
	goToEditUserGroupAssignedAccounts: func,
	messages: object
};

export default UserGroupAssignedAccounts;
