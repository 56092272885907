import { combineReducers } from 'redux';

import accounts from './accounts/reducer';
import companies from './companies/reducer';
import companyReports from './companyReports/reducer';
import customFieldLabels from './customFieldLabels/reducer';
import endpoints from './endpoints/reducer';
import networkEntitlements from './networkEntitlements/reducer';
import ratePlans from './ratePlans/reducer';
import simOrdering from './simOrdering/reducer';
import users from './users/reducer';
import userGroups from './userGroups/reducer';
import userGroupPermissions from './userGroupPermissions/reducer';
import registrations from './registrations/reducer';
import userGroupAssignedAccounts from './userGroupAssignedAccounts/reducer';
import blacklistedPermissions from './blacklistedPermissions/reducer';

export default combineReducers({
	accounts,
	companies,
	companyReports,
	customFieldLabels,
	endpoints,
	networkEntitlements,
	ratePlans,
	simOrdering,
	users,
	userGroups,
	userGroupPermissions,
	registrations,
	userGroupAssignedAccounts,
	blacklistedPermissions
});
