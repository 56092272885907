export default {
	'USER_FEEDBACK.TRIGGER_TITLE': '¿Tiene comentarios?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Indíquenos aquí',
	'USER_FEEDBACK.ADDITIONAL_FILE': 'Archivo adicional',
	'USER_FEEDBACK.PAGE_LABEL': 'Su comentario está relacionado con',
	'USER_FEEDBACK.FEED_LABEL': 'Con sus palabras, ¿cómo podemos mejorar la situación?',
	'USER_FEEDBACK.FEED_PLACEHOLDER': 'Ingrese sus comentarios aquí...',
	'USER_FEEDBACK.CONTACT_LABEL': 'Si es necesario, ¿podemos contactarlo acerca de esto?',
	'USER_FEEDBACK.EMAIL_LABEL': 'Correo electrónico',
	'USER_FEEDBACK.PHONE_LABEL': 'Teléfono',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Enviar comentario',
	'USER_FEEDBACK.ICON': 'Icono de envío exitoso de comentario',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Gracias por sus comentarios',
	'USER_FEEDBACK.SUCCESS_MESSAGE': 'Sus comentarios ayudarán a mejorar nuestro producto. Los evaluaremos detenidamente y tomaremos medidas.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': 'Puede que lo contactemos por más información sobre su sugerencia.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'El equipo de AT&T IoT Console',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Listo',
	'USER_FEEDBACK.TITLE': 'Comentarios del usuario',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Falló la carga del archivo'
};
