import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import NetworkEntitlementsDesktop from './Desktop';
import NetworkEntitlementsMobile from './Mobile';

import { getNetworkEntitlements } from '../../redux/networkEntitlements/actions';
import {
	getNetworkEntitlementsRequest,
	getNetworkEntitlementsFail,
	getNetworkEntitlementsSuccess
} from '../../redux/networkEntitlements/selectors';

import { getAccount, getAccountInit } from '../../redux/accounts/actions';
import {
	getAccountRequest,
	getAccountFail,
	getAccountSuccess
} from '../../redux/accounts/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	networkEntitlementsRequest: getNetworkEntitlementsRequest(state),
	networkEntitlementsFail: getNetworkEntitlementsFail(state),
	networkEntitlements: getNetworkEntitlementsSuccess(state),
	accountRequest: getAccountRequest(state),
	accountFail: getAccountFail(state),
	account: getAccountSuccess(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit()),
	getNetworkEntitlements: (params) => dispatch(getNetworkEntitlements(params)),
	goToNetworkEntitlementDetails: (url) => dispatch(localizedRoute(url)),
	pushBack: (url) => dispatch(localizedRoute(url))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(NetworkEntitlementsDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(NetworkEntitlementsMobile);
