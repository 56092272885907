import { combineReducers } from 'redux';
import globalModalReducer from '../../../../redux/global/globalModalReducer';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	ADD_USERS_MODAL,
	REMOVE_USER_MODAL,
	PAUSE_USER_MODAL,
	GET_USERS,
	GET_USER,
	EDIT_USER,
	INVITE_USERS,
	ADD_USER_GROUP_MODAL,
	PAUSE_USER,
	REACTIVATE_USER,
	RESET_USER_PASSWORD,
	REMOVE_USER,
	GET_ADDRESS_TYPES,
	DELETE_USER_PROFILE,
	GET_COUNTRIES,
	GET_FIRST_ADDRESS_STATES,
	GET_SECOND_ADDRESS_STATES,
	GET_THIRD_ADDRESS_STATES,
	GET_LANGUAGES,
} from './constants';

export default combineReducers({
	addUsersModal: globalModalReducer(ADD_USERS_MODAL),
	removeUserModal: globalModalReducer(REMOVE_USER_MODAL),
	pauseUserModal: globalModalReducer(PAUSE_USER_MODAL),
	getUsers: globalReducer(GET_USERS),
	getUser: globalReducer(GET_USER),
	editUser: globalReducer(EDIT_USER),
	inviteUsers: globalReducer(INVITE_USERS),
	addUserGroupModal: globalModalReducer(ADD_USER_GROUP_MODAL),
	pauseUser: globalReducer(PAUSE_USER),
	reactivateUser: globalReducer(REACTIVATE_USER),
	resetUserPassword: globalReducer(RESET_USER_PASSWORD),
	removeUser: globalReducer(REMOVE_USER),
	getAddressTypes: globalReducer(GET_ADDRESS_TYPES),
	deleteUserProfile: globalReducer(DELETE_USER_PROFILE),
	getCountries: globalReducer(GET_COUNTRIES),
	getFirstAddressStates: globalReducer(GET_FIRST_ADDRESS_STATES),
	getSecondAddressStates: globalReducer(GET_SECOND_ADDRESS_STATES),
	getThirdAddressStates: globalReducer(GET_THIRD_ADDRESS_STATES),
	getLanguages: globalReducer(GET_LANGUAGES),
});
