import React, { PureComponent } from 'react';
import {
	object,
	func,
	string,
	bool
} from 'prop-types';
import cn from 'classnames';

import Loader from '../../../../lib/DigitalComponents/Loader';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import PromptModal from '../PromptModal';
import { normalize } from '../../../../utils/constants';

import styles from './CustomFieldsValues.scss';

const Delete = ImageSelector(CurrentContext.theme, 'svgs/delete.svg');
const Save = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');

export default function CustomFieldsValue(WrapperComponent) {
	class CustomFieldsValuesComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				isModalOpen: false,
				type: '',
				temp: [],
			};
		}

		componentDidMount() {
			const { customFieldLabels, endpoint } = this.props;

			this.renderInitialProps(customFieldLabels, endpoint);
		}

		componentDidUpdate(prevProps) {
			const { customFieldLabels, endpoint } = this.props;

			if (prevProps !== this.props) {
				this.renderInitialProps(customFieldLabels, endpoint);
			}
		}

		getNumber = (key) => key.substr('customLabel'.length);

		renderInitialProps = (customFieldLabels, endpoint) => {
			let temp = {};

			if (customFieldLabels !== null) {
				Object.keys(customFieldLabels).forEach((key) => {
					if (key.startsWith('customLabel') && key !== 'messageId') {
						const newKey = `customField${this.getNumber(key)}`;

						temp = {
							...temp,
							[newKey]: endpoint[`customFieldValue${this.getNumber(key)}`]
							|| '',
						};
					}
				});
			}

			this.setState({
				temp,
				original: temp
			});
		};

		renderFields = (customFieldLabels) => (
			customFieldLabels !== null ? (
				Object.keys(customFieldLabels).map((key) => {
					if (key.startsWith('customLabel') && key !== 'messageId') {
						const {
							inlineFields,
							renderAction,
						} = this.props;
						const { temp } = this.state;
						const field = customFieldLabels[
							`isCustomField${this.getNumber(key)}Required`
							];

						return (
							<div
								key={key}
								data-spec="custom-field"
								className={
									`${styles.input_wrapper} ${styles.input_wrapper_mobile} ${inlineFields
										? styles.inline_input_wrapper
										: ''}`
								}
							>
								{Object.keys(temp).length > 0
								&& renderAction
								&& this.renderAction(key)}
								<WrapperComponent
									renderAction={renderAction}
									temp={temp}
									keyName={key}
									onChange={this.onChange}
									getNumber={this.getNumber}
									field={field}
									customFieldLabels={customFieldLabels}
									saveFieldValue={this.saveFieldValue}
								/>
							</div>
						);
					}

					return null;
				})
			) : null
		);

		onChange = (e, key) => {
			const { temp } = this.state;

			this.setState({
				temp: {
					...temp,
					[`customField${this.getNumber(key)}`]: normalize(
						e.currentTarget.value,
					),
				}
			});
		};

		renderAction = (key) => {
			const { temp } = this.state;
			const { endpoint } = this.props;
			const saveField = () => this.saveFieldValue(key);

			if (this.isEmptyValue(key, endpoint, temp)) {
				return (
					<div
						onClick={() => this.deleteFieldValue(key)}
						className={cn(styles.action)}
						data-spec={`action-${key}`}
					>
						<Delete />
					</div>
				);
			}
			if (
				temp[`customField${this.getNumber(key)}`] === '' ||
				!temp[`customField${this.getNumber(key)}`].replace(/\s/g, '').length ||
				temp[`customField${this.getNumber(key)}`] ===
				endpoint[`customFieldValue${this.getNumber(key)}`]
			) {
				return (
					<div
						className={cn(
							styles.action,
							styles.action_check,
							styles.action_save
						)}
						data-spec={`action-${key}`}
					>
						<Save />
					</div>
				);
			}

			return (
				<div
					onClick={saveField}
					className={cn(styles.action, styles.action_check)}
					data-spec={`action-${key}`}
				>
					<Save />
				</div>
			);
		};

		deleteFieldValue = (key) => {
			const { customFieldLabels } = this.props;
			const field = customFieldLabels[
				`isCustomField${this.getNumber(key)}Required`
				];

			if (field) {
				this.openPromptModal('required');
			} else {
				this.openPromptModal('', key);
			}
		};

		saveFieldValue = (key) => {
			const { customFieldLabels } = this.props;
			const { temp } = this.state;
			const field = customFieldLabels[
				`isCustomField${this.getNumber(key)}Required`
				];

			if (field && !temp[`customField${this.getNumber(key)}`]) {
				this.openPromptModal('required');
			} else if (!temp[`customField${this.getNumber(key)}`]) {
				this.openPromptModal('', key);
			} else {
				this.onSubmit(key);
			}
		};

		isEmptyValue = (key, endpoint, temp) => (
			endpoint[`customFieldValue${this.getNumber(key)}`] !== '' &&
			temp[`customField${this.getNumber(key)}`] ===
			endpoint[`customFieldValue${this.getNumber(key)}`]
		);

		onCancel = () => this.setState({ isModalOpen: false });

		onConfirm = () => {
			const { key } = this.state;

			this.onSubmit(key, 'clear');
		};

		openPromptModal = (type, key) => {
			const { inModal } = this.props;
			let showConfirmation = false;

			if (inModal) {
				showConfirmation = true;
			}

			this.setState({
				isModalOpen: !showConfirmation,
				showConfirmation,
				type,
				key,
			});
		};

		onSubmit = (key, clear) => {
			const { original, temp } = this.state;
			const { updateCustomFieldsValues, endpointName } = this.props;
			const fields = {
				...original,
				[`customField${this.getNumber(key)}`]: clear === 'clear'
					? ''
					: temp[`customField${this.getNumber(key)}`].trim()
			};

			updateCustomFieldsValues(
				endpointName,
				fields,
			);
		};

		onCancelConfirmation = () => this.setState({ showConfirmation: false });

		onConfirmConfirmation = () => {
			this.onConfirm();
			this.setState({ showConfirmation: false });
		};

		render() {
			const {
				customFieldLabels,
				inModal,
				showLoader,
				inlineFields,
			} = this.props;
			const { temp, showConfirmation, type, isModalOpen } = this.state;

			if (temp.length < 1 || (inModal && showLoader)) {
				return <Loader data-spec="loader" />;
			}

			return (
				<div
					data-spec="custom-fields-values"
					className={
						`${styles.column} ${styles.field_column_block} ${inlineFields ? `${styles.inline_column} ${styles.remove_left_padding}` : ''}`
					}
				>
					{inModal && showConfirmation ? (
						<PromptModal
							show
							onlyBody
							onCancel={this.onCancelConfirmation}
							onConfirm={this.onConfirmConfirmation}
							type={type}
						/>
					) : (
						this.renderFields(customFieldLabels)
					)}
					<PromptModal
						show={isModalOpen}
						preventBodyScrollingWhenOpened={!inModal}
						onCancel={this.onCancel}
						onConfirm={this.onConfirm}
						type={type}
					/>
				</div>
			);
		}
	}

	CustomFieldsValuesComponent.propTypes = {
		endpoint: object,
		customFieldLabels: object,
		updateCustomFieldsValues: func,
		renderAction: bool,
		endpointName: string,
		inModal: bool,
		showLoader: bool,
		inlineFields: bool,
	};
	CustomFieldsValuesComponent.defaultProps = {
		endpoint: {},
		customFieldLabels: {},
		updateCustomFieldsValues: undefined,
		renderAction: false,
		endpointName: '',
		inModal: false,
		showLoader: false,
		inlineFields: false,
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	CustomFieldsValuesComponent.displayName = `CustomFieldsValues(${wrappedComponentName})`;

	return CustomFieldsValuesComponent;
}
