/* global GLOBALS */
import React from 'react';
import { NavLink } from 'react-router-dom';
import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import { FormattedMessage } from 'react-intl';
import { UserAllowedToAccess } from './AuthSelector';
import AuthService from '../services/AuthService';
import { setLanguageCode } from './helperFunctions';

const ReleaseNotesRWComponent = UserAllowedToAccess([
	'system.releasenotes_rw',
	'system.releasenotes_ro'
]);

const UserManagmentSidebarRWComponent = UserAllowedToAccess([
	'mnc.accessmanagement.users_rw',
	'mnc.accessmanagement.users_ro'
]);

const UserGroupsSidebarRWComponent = UserAllowedToAccess([
	'mnc.accessmanagement.usergroups_rw',
	'mnc.accessmanagement.usergroups_ro'
]);

const ApiDocumentationRWComponent = UserAllowedToAccess([
	'mnc.api_rw',
	'mnc.api_ro'
]);
const SimOrderingRWComponent = UserAllowedToAccess([
	'mnc.simordering_rw',
	'mnc.simordering_ro'
]);

const AutomationRWComponent = UserAllowedToAccess([
	'mnc.automation_rw',
	'mnc.automation_ro',
	'system.managemanualrules_rw',
	'system.managemanualrules_ro'
]);

const AnalyticsRWComponent = UserAllowedToAccess([
	'mnc.analytics_rw',
	'mnc.analytics_ro'
]);

const BatchHistoryRWComponent = UserAllowedToAccess([
	'mnc.endpoints.batchupload_rw',
	'mnc.endpoints.batchupload_ro'
]);

const SecurityFeturesRWComponent = UserAllowedToAccess([
	'mnc.securityfeatures_ro',
	'mnc.securityfeatures_rw'
]);

const ReportsRWComponent = UserAllowedToAccess([
	'mnc.reports.endpoints_rw',
	'mnc.reports.endpoints_ro',
	'mnc.reports.usagesummaries_rw',
	'mnc.reports.usagesummaries_ro',
	'mnc.reports.transfer_rw',
	'mnc.reports.transfer_ro',
	'mnc.reports.accountsummary_rw',
	'mnc.reports.accountsummary_ro',
	'mnc.reports.usage_rw',
	'mnc.reports.usage_ro',
	'mnc.endpoints_rw',
	'mnc.endpoints_ro'
]);

const EndpointsRWComponent = UserAllowedToAccess([
	'mnc.endpoints_rw',
	'mnc.endpoints_ro'
]);

const SystemNotificationsRWComponent = UserAllowedToAccess([
	'mnc.alerting_rw',
	'mnc.alerting_ro'
]);

const GraphSettingsRWComponent = UserAllowedToAccess([
	'system.graphsettings_rw'
]);

const TicketingWComponent = UserAllowedToAccess([
	'mnc.ticketing_rw',
	'mnc.ticketing_ro'
]);

const SubaccountsRWComponent = UserAllowedToAccess([
	'mnc.m2msubaccounts_rw',
	'mnc.m2msubaccounts_ro'
]);

const InvoicesRWComponent = UserAllowedToAccess([
	'mnc.invoices_rw',
	'mnc.invoices_ro'
]);

const CustomFieldLabels = UserAllowedToAccess([
	'mnc.endpoints.customlabels_rw',
	'mnc.endpoints.customlabels_ro'
]);

const SystemSimOrderingRWComponent = UserAllowedToAccess([
	'system.managesimorders_ro',
	'system.managesimorders_rw'
]);

const AutomationZonesRWComponent = UserAllowedToAccess([
	'system.mobilityzones_rw',
	'system.mobilityzones_ro',
]);

const Home = ImageSelector(CurrentContext.theme, 'svgs/home.svg');
const Endpoint = ImageSelector(CurrentContext.theme, 'svgs/endpoint.svg');
const Reports = ImageSelector(CurrentContext.theme, 'svgs/reports.svg');
const Support = ImageSelector(CurrentContext.theme, 'svgs/support.svg');
const Invoices = ImageSelector(CurrentContext.theme, 'svgs/invoices.svg');
const BatchHistory = ImageSelector(CurrentContext.theme, 'svgs/history.svg');
const ApiIcon = ImageSelector(CurrentContext.theme, 'svgs/api.svg');
const Automation = ImageSelector(CurrentContext.theme, 'svgs/automation.svg');
const Analytics = ImageSelector(
	CurrentContext.theme,
	'svgs/analytic-chart-icon.svg'
);
const IotgwFailover = ImageSelector(
	CurrentContext.theme,
	'svgs/iot-gw-failover.svg'
);
const AccessManagement = ImageSelector(
	CurrentContext.theme,
	'svgs/access-management.svg'
);
const AccountSettings = ImageSelector(
	CurrentContext.theme,
	'svgs/account-settings.svg'
);
const SimOrderingIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/sim-ordering.svg'
);
const ReleaseNotesIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/release-notes.svg'
);
const AutomationZonesIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/world.svg'
);
const usFlag = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const frFlag = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const esFlag = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const itFlag = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const nlFlag = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const deFlag = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const ptFlag = ImageSelector(CurrentContext.theme, 'svgs/brasil.svg');
const zhFlag = ImageSelector(CurrentContext.theme, 'svgs/china.svg');

const renderNavlink = (link, label) => (
	<NavLink exact data-spec={`mainNav${link}`} to={link}>
		{label}
	</NavLink>
);

const mainMenu = (lng) => [
	{
		label: (
			<FormattedMessage
				id="ACCOUNT_OVERVIEW"
				defaultMessage="Account Overview"
			/>
		),
		link: `/${lng}/`,
		icon: <Home />,
		sidebar: true,
		data_spec: 'sidebar-account-overview',
		id: 'account-overview',
		exact: true,
		Component: renderNavlink(
			`/${lng}/`,
			<FormattedMessage
				id="ACCOUNT_OVERVIEW"
				defaultMessage="Account Overview"
			/>
		)
	},
	{
		label: <FormattedMessage id="ENDPOINTS" defaultMessage="Endpoints" />,
		link: `/${lng}/endpoints`,
		id: 'endpoints',
		icon: <Endpoint />,
		sidebar: true,
		data_spec: 'sidebar-endpoints',
		Component: renderNavlink(
			`/${lng}/endpoints`,
			<FormattedMessage id="ENDPOINTS" defaultMessage="Endpoints" />
		),
		wrapper: EndpointsRWComponent
	},
	{
		label: <FormattedMessage id="AUTOMATION" defaultMessage="Automation" />,
		link: `/${lng}/automation`,
		id: 'automation',
		icon: <Automation />,
		sidebar: true,
		data_spec: 'sidebar-automation',
		Component: renderNavlink(
			`/${lng}/automation`,
			<FormattedMessage id="AUTOMATION" defaultMessage="Automation" />
		),
		wrapper: AutomationRWComponent
	},
	{
		label: <FormattedMessage id="ANALYTICS" defaultMessage="Analytics" />,
		link: `/${lng}/analytics`,
		wrapper: AnalyticsRWComponent,
		icon: <Analytics />,
		id: 'analytics',
		sidebar: true,
		data_spec: 'sidebar-analytics',
		Component: renderNavlink(
			`/${lng}/analytics`,
			<FormattedMessage id="ANALYTICS" defaultMessage="Analytics" />
		)
	},
	{
		label: <FormattedMessage id="REPORTS" defaultMessage="Reports" />,
		link: `/${lng}/reports`,
		icon: <Reports />,
		id: 'reports',
		sidebar: true,
		data_spec: 'sidebar-reports',
		Component: renderNavlink(
			`/${lng}/reports`,
			<FormattedMessage id="REPORTS" defaultMessage="Reports" />
		),
		wrapper: ReportsRWComponent
	},
	{
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.TITLE"
				defaultMessage="Invoices"
			/>
		),
		link: `/${lng}/invoices`,
		id: 'invoices',
		icon: <Invoices />,
		sidebar: true,
		data_spec: 'invoices',
		Component: renderNavlink(
			`/${lng}/invoices`,
			<FormattedMessage
				id="ANALYTICS_INVOICES.LINK"
				defaultMessage="Invoices"
			/>
		),
		wrapper: InvoicesRWComponent
	},
	{
		label: <FormattedMessage id="SIM_ORDERING" defaultMessage="SIM Ordering" />,
		id: 'sim-ordering',
		icon: <SimOrderingIcon />,
		link: `/${lng}/sim-ordering`,
		sidebar: true,
		wrapper: SimOrderingRWComponent,
		data_spec: 'sidebar-sim-ordering',
		Component: renderNavlink(
			`/${lng}/sim-ordering`,
			<FormattedMessage id="SIM_ORDERING" defaultMessage="SIM Ordering" />
		)
	},
	{
		label: (
			<FormattedMessage
				id="BATCH_HISTORY.TITLE"
				defaultMessage="Batch History"
			/>
		),
		link: `/${lng}/batch-history`,
		id: 'batch-history',
		icon: <BatchHistory />,
		wrapper: BatchHistoryRWComponent,
		sidebar: true,
		data_spec: 'sidebar-history',
		Component: renderNavlink(
			`/${lng}/batch-history`,
			<FormattedMessage
				id="BATCH_HISTORY.LINK"
				defaultMessage="Batch History"
			/>
		)
	},
	{
		label: (
			<FormattedMessage
				id="API_DOCUMENTATION"
				defaultMessage="API Documentation"
			/>
		),
		link: `/${lng}/developer-api`,
		id: 'developer-api',
		alternateLink: `/${lng}/developer-api`,
		wrapper: ApiDocumentationRWComponent,
		icon: <ApiIcon />,
		sidebar: true,
		data_spec: 'sidebar-api-documentation',
		notOnMobile: true
	},
	{
		label: (
			<FormattedMessage
				id="ACCOUNT_SETTINGS"
				defaultMessage="Account Settings"
			/>
		),
		icon: <AccountSettings />,
		id: 'account-settings',
		sidebar: true,
		data_spec: 'sidebar-account-settings',
		Component: renderNavlink(
			`/${lng}/support`,
			<FormattedMessage id="SUPPORT" defaultMessage="Support" />
		),
		submenu: [
			{
				link: `/${lng}/my-company/companyoverview`,
				id: 'company-details',
				label: (
					<FormattedMessage
						id="COMPANY_DETAILS"
						defaultMessage="Company Details"
					/>
				),
				Component: renderNavlink(
					`/${lng}/my-company/companyoverview`,
					<FormattedMessage
						id="COMPANY_DETAILS"
						defaultMessage="Company Details"
					/>
				)
			},
			{
				link: `/${lng}/my-company/accounts`,
				id: 'company-accounts',
				label: <FormattedMessage id="ACCOUNTS" defaultMessage="Accounts" />,
				wrapper: SubaccountsRWComponent,
				Component: renderNavlink(
					`/${lng}/my-company/accounts`,
					<FormattedMessage id="ACCOUNTS" defaultMessage="Accounts" />
				)
			},
			{
				label: (
					<FormattedMessage
						id="OPEN_CHART_SETTINGS_PAGE"
						defaultMessage="Graph Settings"
					/>
				),
				id: 'graph-settings',
				link: `/${lng}/account-overview/admin-page`,
				wrapper: GraphSettingsRWComponent,
				Component: renderNavlink(
					`/${lng}/account-overview/admin-page`,
					<FormattedMessage
						id="OPEN_CHART_SETTINGS_PAGE"
						defaultMessage="Graph Settings"
					/>
				),
				notOnMobile: true
			},
			{
				link: `/${lng}/my-company/custom-field-labels`,
				id: 'custom-fields',
				label: (
					<FormattedMessage id="CUSTOM_FIELDS" defaultMessage="Custom Fields" />
				),
				wrapper: CustomFieldLabels,
				Component: renderNavlink(
					`/${lng}/my-company/custom-field-labels`,
					<FormattedMessage id="CUSTOM_FIELDS" defaultMessage="Custom Fields" />
				)
			},
			{
				label: (
					<FormattedMessage
						id="SECURITY_FEATURES.TITLE"
						defaultMessage="Security Features"
					/>
				),
				id: 'security-features',
				link: `/${lng}/security-features`,
				wrapper: SecurityFeturesRWComponent,
				Component: renderNavlink(
					`/${lng}/security-features`,
					<FormattedMessage
						id="SECURITY_FEATURES.TITLE"
						defaultMessage="Security Features"
					/>
				)
			},
			{
				link: `/${lng}/user-management`,
				id: 'userManagement',
				label: (
					<FormattedMessage
						id="USER_MANAGEMENT"
						defaultMessage="User Management"
					/>
				),
				wrapper: UserManagmentSidebarRWComponent,
				Component: renderNavlink(
					`/${lng}/user-management`,
					<FormattedMessage
						id="USER_MANAGEMENT"
						defaultMessage="User Management"
					/>
				)
			},
			{
				link: `/${lng}/user-groups`,
				id: 'userGroups',
				label: (
					<FormattedMessage id="USER_GROUPS" defaultMessage="User Groups" />
				),
				wrapper: UserGroupsSidebarRWComponent,
				Component: renderNavlink(
					`/${lng}/user-groups`,
					<FormattedMessage id="USER_GROUPS" defaultMessage="User Groups" />
				)
			}
		]
	},
	{
		label: (
			<FormattedMessage id="HELP_SUPPORT" defaultMessage="Help & Support" />
		),
		icon: <Support />,
		id: 'support',
		sidebar: true,
		data_spec: 'sidebar-support',
		Component: renderNavlink(
			`/${lng}/support`,
			<FormattedMessage id="SUPPORT" defaultMessage="Support" />
		),
		submenu: [
			{
				link: `/${lng}/support/user-guide`,
				id: 'user-guide',
				label: <FormattedMessage id="USER_GUIDE" defaultMessage="User Guide" />,
				Component: renderNavlink(
					`/${lng}/support/user-guide`,
					<FormattedMessage id="USER_GUIDE" defaultMessage="User Guide" />
				)
			},
			{
				link: `/${lng}/support/faq`,
				id: 'faq',
				label: <FormattedMessage id="FAQS" defaultMessage="FAQs" />,
				Component: renderNavlink(
					`/${lng}/support/faq`,
					<FormattedMessage id="FAQS" defaultMessage="FAQs" />
				)
			},
			{
				link: `/${lng}/support/ticketing`,
				label: <FormattedMessage id="TICKETING" defaultMessage="Ticketing" />,
				wrapper: TicketingWComponent,
				id: 'ticketing',
				Component: renderNavlink(
					`/${lng}/support/ticketing`,
					<FormattedMessage id="TICKETING" defaultMessage="Ticketing" />
				)
			},
			{
				link: `/${lng}/support/release-notes`,
				id: 'release-notes',
				label: (
					<FormattedMessage id="RELEASE_NOTES" defaultMessage="Release Notes" />
				),
				Component: renderNavlink(
					`/${lng}/support/release-notes`,
					<FormattedMessage id="RELEASE_NOTES" defaultMessage="Release Notes" />
				)
			},
			{
				wrapper: SystemNotificationsRWComponent,
				link: `/${lng}/support`,
				id: 'system-notifications',
				label: (
					<FormattedMessage
						id="SYSTEM_NOTIFICATIONS"
						defaultMessage="System Notifications"
					/>
				),
				Component: renderNavlink(
					`/${lng}/support`,
					<FormattedMessage
						id="SYSTEM_NOTIFICATIONS"
						defaultMessage="System Notifications"
					/>
				)
			}
		]
	},
	{
		sectionTitle: <FormattedMessage id="MORE_IOT" defaultMessage="MORE IoT" />
	},
	{
		label: (
			<FormattedMessage
				id="LEARN_ABOUT_OFFERS"
				defaultMessage="Learn About Offers"
			/>
		),
		link: 'https://www.att.com/iot',
		target: '_blank'
	},
	{
		label: <FormattedMessage id="BUY_DEVICES" defaultMessage="Buy Devices" />,
		link: 'https://marketplace.att.com',
		target: '_blank'
	},
	{
		label: (
			<FormattedMessage id="ATT_SERVICES" defaultMessage="AT&amp;T Services" />
		),
		link: 'https://iotplatform.att.com/services',
		target: '_blank'
	},
	{
		label: (
			<FormattedMessage
				id="USE_TOOLS_APPS"
				defaultMessage="Use Tools &amp; Apps"
			/>
		),
		submenuLabel: (
			<FormattedMessage
				id="IOT_PLATFORMS_PRODUCTS_APPS_AND_APIS"
				defaultMessage="IOT PLATFORMS, PRODUCTS, APPS AND API'S"
			/>
		),
		submenu: [
			{
				label: <FormattedMessage id="AMOC" defaultMessage="AMOC" />,
				link: 'https://amoc.att.com',
				target: '_blank'
			},
			{
				label: <FormattedMessage id="M2X" defaultMessage="M2X" />,
				link: 'https://m2x.att.com',
				target: '_blank'
			},
			{
				label: (
					<FormattedMessage id="FLOW_DESIGNER" defaultMessage="Flow Designer" />
				),
				link: 'https://flow.att.com',
				target: '_blank'
			},
			{
				label: (
					<FormattedMessage
						id="IOT_STARTER_KITS"
						defaultMessage="IoT Starter Kits"
					/>
				),
				link: 'https://marketplace.att.com/iot-starter-kits',
				target: '_blank'
			},
			{
				label: (
					<FormattedMessage
						id="IOT_DATA_PLANS"
						defaultMessage="IoT Data Plans"
					/>
				),
				link: 'https://marketplace.att.com/iot-connectivity',
				target: '_blank'
			}
		]
	},
	{
		label: <FormattedMessage id="SUPPORT" defaultMessage="Support" />,
		link: 'https://www.business.att.com/enterprise/support',
		target: '_blank'
	},
	{
		sectionTitle: (
			<FormattedMessage id="MORE_ATT" defaultMessage="MORE AT&amp;T" />
		)
	},
	{
		label: <FormattedMessage id="ENTERPRISE" defaultMessage="Enterprise" />,
		link: 'https://www.business.att.com/enterprise',
		target: '_blank'
	},
	{
		label: <FormattedMessage id="BUSINESS" defaultMessage="Business" />,
		link: 'https://www.business.att.com',
		target: '_blank'
	},
	{
		label: <FormattedMessage id="CONSUMER" defaultMessage="Consumer" />,
		link: 'https://www.att.com',
		target: '_blank'
	}
];

const profileMenu = (lng) => [
	{
		id: 'my-profile',
		label: <FormattedMessage id="PROFILE" defaultMessage="Profile" />,
		link: `/${lng}/my-profile`,
		exact: true,
		data_spec: 'my-profile',
		Component: renderNavlink(
			`/${lng}/my-profile`,
			<FormattedMessage id="PROFILE" defaultMessage="Profile" />
		)
	},
	{
		label: <FormattedMessage id="LOGOUT" defaultMessage="Logout" />,
		link: `${GLOBALS.LOGOUT_PATH}?redirect=${window.location.origin}/`,
		target: '_self',
		onClickHandler: () => {
			const authService = new AuthService();
			authService.logout();
		}
	}
];

const supportedLanguages = [
	{
		label: <FormattedMessage id="ENGLISH" defaultMessage="English (USA)" />,
		icon: usFlag,
		code: 'en-US',
		onClickHandler: () => {
			setLanguageCode('en-US');
		},
	},
	{
		label: (
			<FormattedMessage id="FRANCAIS" defaultMessage="Français (France)" />
		),
		icon: frFlag,
		code: 'fr-FR',
		onClickHandler: () => {
			setLanguageCode('fr-FR');
		},
	},
	{
		label: <FormattedMessage id="ESPANOL" defaultMessage="Español (España)" />,
		icon: esFlag,
		code: 'es-ES',
		onClickHandler: () => {
			setLanguageCode('es-ES');
		},
	},
	{
		label: (
			<FormattedMessage id="ITALIANO" defaultMessage="Italiano (Italia)" />
		),
		icon: itFlag,
		code: 'it-IT',
		onClickHandler: () => {
			setLanguageCode('it-IT');
		},
	},
	{
		label: (
			<FormattedMessage
				id="NEDERLANDS"
				defaultMessage="Netherlands (Holland)"
			/>
		),
		icon: nlFlag,
		code: 'nl-NL',
		onClickHandler: () => {
			setLanguageCode('nl-NL');
		},
	},
	{
		label: (
			<FormattedMessage id="DEUTCHE" defaultMessage="Deutsche (Deutschland)" />
		),
		icon: deFlag,
		code: 'de-DE',
		onClickHandler: () => {
			setLanguageCode('de-DE');
		},
	},
	{
		label: (
			<FormattedMessage id="PORTUGUES" defaultMessage="Português (Brasil)" />
		),
		icon: ptFlag,
		code: 'pt-BR',
		onClickHandler: () => {
			setLanguageCode('pt-BR');
		},
	},
	{
		label: <FormattedMessage id="CHINESE" defaultMessage="中文 (中国)" />,
		icon: zhFlag,
		code: 'zh-CN',
		onClickHandler: () => {
			setLanguageCode('zh-CN');
		},
	},
];

/* ATT MENU PERMISSIONS */

const FailoverRWComponent = UserAllowedToAccess([
	'system.failover_rw',
	'system.failover_ro'
]);

const AccessManagementRWComponent = UserAllowedToAccess([
	'system.accessmanagement.registrations_rw',
	'system.accessmanagement.usergroups_rw',
	'system.accessmanagement.users_rw',
	'system.accessmanagement.registrations_ro',
	'system.accessmanagement.usergroups_ro',
	'system.accessmanagement.users_ro'
]);

const CompaniesRWComponent = UserAllowedToAccess([
	'system.companies_rw',
	'system.companies_ro'
]);

const attMainMenu = (lng) => [
	{
		id: 'companies',
		label: <FormattedMessage id="COMPANIES" defaultMessage="Companies" />,
		link: `/${lng}/`,
		icon: <Endpoint />,
		exact: true,
		sidebar: true,
		data_spec: 'sidebar-companies',
		Component: renderNavlink(
			`/${lng}/`,
			<FormattedMessage id="COMPANIES" defaultMessage="Companies" />
		),
		wrapper: CompaniesRWComponent
	},
	{
		id: 'failover',
		label: (
			<FormattedMessage id="IOTGW_FAILOVER" defaultMessage="IoTGW Failover" />
		),
		link: `/${lng}/failover`,
		wrapper: FailoverRWComponent,
		icon: <IotgwFailover />,
		exact: true,
		sidebar: true,
		data_spec: 'sidebar-failover',
		Component: renderNavlink(
			`/${lng}/failover`,
			<FormattedMessage id="IOTGW_FAILOVER" defaultMessage="IoTGW Failover" />
		)
	},
	{
		id: 'access-managment',
		label: (
			<FormattedMessage
				id="ACCESS_MANAGEMENT"
				defaultMessage="Access Management"
			/>
		),
		link: `/${lng}/access-management`,
		wrapper: AccessManagementRWComponent,
		icon: <AccessManagement />,
		exact: true,
		sidebar: true,
		data_spec: 'sidebar-access-management',
		Component: renderNavlink(
			`/${lng}/access-management`,
			<FormattedMessage
				id="ACCESS_MANAGEMENT"
				defaultMessage="Access Management"
			/>
		)
	},
	{
		id: 'release-notes',
		label: (
			<FormattedMessage id="RELEASE_NOTES" defaultMessage="Release Notes" />
		),
		link: `/${lng}/release-notes`,
		wrapper: ReleaseNotesRWComponent,
		icon: <ReleaseNotesIcon />,
		sidebar: true,
		data_spec: 'sidebar-release-notes',
		Component: renderNavlink(
			`/${lng}/release-notes`,
			<FormattedMessage id="RELEASE_NOTES" defaultMessage="Release Notes" />
		),
		permissions: ['system.releasenotes_rw', 'system.releasenotes_ro']
	},
	{
		label: <FormattedMessage id="SIM_ORDERING" defaultMessage="SIM Ordering" />,
		id: 'sim-ordering',
		icon: <SimOrderingIcon />,
		link: `/${lng}/company-sim-ordering`,
		sidebar: true,
		wrapper: SystemSimOrderingRWComponent,
		data_spec: 'sidebar-sim-ordering',
		Component: renderNavlink(
			`/${lng}/company-sim-ordering`,
			<FormattedMessage id="SIM_ORDERING" defaultMessage="SIM Ordering" />
		)
	},
	{
		label: (
			<FormattedMessage
				id="AUTOMATION_ZONES"
				defaultMessage="Automation Zones"
			/>
		),
		id: 'automation-zones',
		icon: <AutomationZonesIcon />,
		link: `/${lng}/automation-zones`,
		sidebar: true,
		wrapper: AutomationZonesRWComponent,
		data_spec: 'sidebar-automation-zones',
		Component: renderNavlink(
			`/${lng}/automation-zones`,
			<FormattedMessage
				id="AUTOMATION_ZONES"
				defaultMessage="Automation Zones"
			/>
		)
	}
];

export default {
	mainMenu,
	profileMenu,
	attMainMenu,
	supportedLanguages
};
