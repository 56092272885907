import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SupportTableHeader = [
	{
		title: <FormattedMessage id="DATE" />,
		name: 'createdAt'
	},
	{
		title: <FormattedMessage id="TYPE" />,
		name: 'notificationType'
	},
	{
		title: <FormattedMessage id="NOTIFICATION_SCOPE" />,
		name: 'notificationScope'
	},
	{
		title: <FormattedMessage id="CONTENT" />,
		name: 'note'
	},
	{
		title: '',
		name: 'editNotification'
	}
];

export const SupportTableHeaderAll = [
	{
		title: <FormattedMessage id="DATE" />,
		name: 'createdAt'
	},
	{
		title: <FormattedMessage id="TYPE" />,
		name: 'notificationType'
	},
	{
		title: <FormattedMessage id="NOTIFICATION_SCOPE" />,
		name: 'notificationScope'
	},
	{
		title: <FormattedMessage id="CONTENT" />,
		name: 'note'
	}
];
