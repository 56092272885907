import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { getLocation } from 'connected-react-router';

import FeedbackWindow from './FeedbackWindow';
import {
	addFeedback,
	resetFeedback,
	feedbackFileRequestURL,
	feedbackFileUploadClear
} from '../../redux/actions';
import {
	isFeedbackSending,
	isFeedbackSent,
	isFeedbackFileFail,
	isFeedbackFileSending,
	isFeedbackFileSuccess,
	feedbackFile
} from '../../redux/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import { localizedRoute } from '../../../../utils/helperFunctions';

const mapStateToProps = (state) => ({
	isFeedbackSending: isFeedbackSending(state),
	isFeedbackSent: isFeedbackSent(state),
	user: getUser(state),
	isFeedbackFileSending: isFeedbackFileSending(state),
	isFeedbackFileFail: isFeedbackFileFail(state),
	isFeedbackFileSuccess: isFeedbackFileSuccess(state),
	feedbackFile: feedbackFile(state),
	getFormValues: getFormValues('userFeedback')(state),
	getLocation: getLocation(state),
});

const mapDispatchToProps = (dispatch) => ({
	addFeedback: (postObj) => dispatch(addFeedback(postObj)),
	resetFeedback: () => dispatch(resetFeedback()),
	feedbackFileRequestURL: (data) => dispatch(feedbackFileRequestURL(data)),
	feedbackFileUploadClear: () => dispatch(feedbackFileUploadClear()),
	goToTicketing: (url) => dispatch(localizedRoute(url))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FeedbackWindow);
