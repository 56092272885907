export default (brand, name, type = null) => {
	// ToDo Change 'default' to brand once it's needed
	let img;
	try {
    img = require(`brands/default/images/${name}`);
	} catch (ex) {
		if (type === 'networkOperator') {
			img = require('brands/default/images/generic-operator.png');
		} else {
			img = require('brands/default/images/svgs/missing-image.svg');
		}
	}
	return img;
};
