import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_ANALYTICS_INVOICES,
	GET_ANALYTICS_INVOICES_FILTERS,
	GET_INVOICE_DETAILS,
	EXPORT_INVOICE,
	PATCH_INVOICE,
	GET_INVOICE_ATTACHMENTS,
	GET_INVOICES,
	GET_FILTERS,
	POST_INVOICE,
  EXPORT_INVOICE_DETAILS,
  INVOICES_GET_M2M_ACCOUNTS
} from './constants';

import invoiceReport from '../invoiceReport/reducer';
import invoiceAttachment from '../invoiceAttachment/reducer';

export default combineReducers({
	getAnalyticsInvoices: globalReducer(GET_ANALYTICS_INVOICES),
	getAnalyticsInvoicesFilters: globalReducer(GET_ANALYTICS_INVOICES_FILTERS),
	getInvoiceDetails: globalReducer(GET_INVOICE_DETAILS),
	exportInvoice: globalReducer(EXPORT_INVOICE),
	patchInvoice: globalReducer(PATCH_INVOICE),
	getInvoiceAttachments: globalReducer(GET_INVOICE_ATTACHMENTS),
	getInvoices: globalReducer(GET_INVOICES),
	getFilters: globalReducer(GET_FILTERS),
	postInvoice: globalReducer(POST_INVOICE),
  exportInvoiceDetails: globalReducer(EXPORT_INVOICE_DETAILS),
  getM2mAccounts: globalReducer(INVOICES_GET_M2M_ACCOUNTS),
	invoiceReport,
	invoiceAttachment
});
