/* eslint-disable import/prefer-default-export */
const mapAdvancedSearchParameterValues = (value) => {
	const newValue = value.replace(/(\*)\**/g, '%');
	return newValue
		.split(',')
		.map((searchValue) => {
			const trimmedValue = searchValue.trim();
			const noOfStars = (trimmedValue.match(/%/g) || []).length;
			if (trimmedValue.length === noOfStars) {
				return null;
			}
			return trimmedValue;
		})
		.filter((finalValue) => finalValue !== null);
};

const checkIfOnlyStars = (value) => {
	const newValue = value.split(',').map((searchValue) => searchValue.trim());

	let onlyStars = true;
	newValue.forEach((parameter) => {
		if (parameter.length > 0) {
			const noOfStars = (parameter.match(/\*/g) || []).length;
			if (parameter.length !== noOfStars) {
				onlyStars = false;
			}
		}
	});
	return onlyStars;
};

export const mapAdvancedSearchParameter = (searchParameter) => ({
	prop: searchParameter.prop,
	propValue: mapAdvancedSearchParameterValues(searchParameter.propValue)
});

export const checkIfAdvancedSearchParameter = (menu, searchParameter) =>
	menu.includes(searchParameter.prop) &&
	['*', ','].some((sign) => searchParameter.propValue.includes(sign)) &&
	!checkIfOnlyStars(searchParameter.propValue);
