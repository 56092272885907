import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import Desktop from '../../components/Permissions';
import {
	MobileNotSupported,
	TabletNotSupported
} from '../../../Shared/components/NotSupported';

import { globalPaginationNumber } from '../../../../utils/constants';

const PermissionsView = (props) => (
	<>
		<DesktopMediaQuery>
			<Desktop {...props} dataLimit={globalPaginationNumber.desktop} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<TabletNotSupported />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<MobileNotSupported />
		</MobileMediaQuery>
	</>
);

export default PermissionsView;
