import ApiClient from 'utils/ApiClient';

export const get = async (url, params = {}) => {
	try {
		const response = await ApiClient.get(url, {
			params
		});
		return { data: response.data };
	} catch (e) {
		throw { error: e.response.data };
	}
};

export const post = async (url, postData = {}) => {
	try {
		const response = await ApiClient.post(url, postData);
		return { data: response.data };
	} catch (e) {
		throw { error: e.response.data };
	}
};

export const patch = async (url, patchData = {}) => {
	try {
		const response = await ApiClient.patch(url, patchData);
		return { data: response.data };
	} catch (e) {
		throw { error: e.response.data };
	}
};

export const remove = async (url, deleteData = {}) => {
	try {
		const response = await ApiClient.delete(url, deleteData);
		return { data: response.data };
	} catch (e) {
		throw { error: e.response.data };
	}
};
