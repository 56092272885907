import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import getChartData from '../../../Analytics/redux/getChartData/actions';
import {
	getChartDataSuccess,
	isGetChartDataPending
} from '../../../Analytics/redux/selectors';
import {
	deleteDefinedSetting,
	getDefinedSettingById,
	getDefinedSettingByIdInit,
	getDefinedSettings,
	updateDefinedSetting
} from '../../redux/actions';
import {
	getDefinedSettingByIdData,
	getDefinedSettingsData,
	isDeleteDefinedSettingPending,
	isDeleteDefinedSettingSuccess,
	isGetDefinedSettingByIdPending,
	isGetDefinedSettingsPending,
	isUpdateDefinedSettingPending,
	isUpdateDefinedSettingSuccess
} from '../../redux/selectors';

import AccountOverviewAdmin from './AccountOverviewAdmin';

const mapStateToProps = (state) => ({
	definedSettings: getDefinedSettingsData(state),
	pendingData: isGetDefinedSettingsPending(state),
	chartData: (chartId) => getChartDataSuccess(state, chartId),
	isGetChartDataPending: (chartId) => isGetChartDataPending(state, chartId),
	definedSettingById: getDefinedSettingByIdData(state),
	isDefinedSettingByIdPending: isGetDefinedSettingByIdPending(state),
	isDeleteDefinedSettingPending: isDeleteDefinedSettingPending(state),
	isUpdateDefinedSettingsPending: isUpdateDefinedSettingPending(state),
	isDeleteDefinedSettingSuccess: isDeleteDefinedSettingSuccess(state),
	isUpdateDefinedSettingsSuccess: isUpdateDefinedSettingSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getDefinedSettings: (includeChartSettings) =>
		dispatch(getDefinedSettings(includeChartSettings)),
	pushBack: () => dispatch(localizedRoute('/')),
	getChartData: (chartId, requestSettings) =>
		dispatch(getChartData(chartId, requestSettings)),
	updateDefinedSettings: (settingsObject, settingId = null) =>
		dispatch(updateDefinedSetting(settingsObject, settingId)),
	getDefinedSettingsById: (settingId) =>
		dispatch(getDefinedSettingById(settingId, true)),
	getDefinedSettingsByIdInit: () => dispatch(getDefinedSettingByIdInit()),
	deleteDefinedSetting: (settingId) => dispatch(deleteDefinedSetting(settingId))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountOverviewAdmin);
