import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_PREPAID_SUBSCRIPTIONS,
	GET_PREPAID_SUBSCRIPTIONS_FILTERS
} from './constants';

export default combineReducers({
	getPrepaidSubscriptions: globalReducer(GET_PREPAID_SUBSCRIPTIONS),
	getPrepaidSubscriptionsFilters: globalReducer(
		GET_PREPAID_SUBSCRIPTIONS_FILTERS
	)
});
