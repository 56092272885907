export default {
	'SECURITY_FEATURES.TITLE': '安全特性',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'IMEI白名单',
	'SECURITY_FEATURES.SMS_WHITELIST': '短信服务（SMS）白名单',
	'SECURITY_FEATURES.VOICE_WHITELIST': '语音白名单',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'IP、URL、端口黑名单',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'IP范围白名单',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': '无可用的安全特性',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': '本表格目前无可显示的安全特性',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': '机对机（M2M）账户名称',
	'SECURITY_FEATURES.DATE_SUBMITTED': '提交日期',
	'SECURITY_FEATURES.STATUS': '状态',
	'SECURITY_FEATURES.PENDING': '待决',
	'SECURITY_FEATURES.FAILED': '失败',
	'SECURITY_FEATURES.SUCCESSFUL': '成功',
	'SECURITY_FEATURES.CCIP': '控制中心整合计划（CCIP）',
	'SECURITY_FEATURES.VIVO': 'Vivo控制中心',
	'SECURITY_FEATURES.DCP': '设备连接平台（DCP）',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'AT&T控制中心（ACC）',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': '诺基亚（WING）',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SEARCH MENU
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': '短信服务（SMS）',
	'SECURITY_FEATURES.MENU_3': '语音',
	'SECURITY_FEATURES.MENU_4': 'IP、URL、端口',
	'SECURITY_FEATURES.MENU_5': 'IP范围',
	'SECURITY_FEATURES.ADD_1': '将新IMEI添加至白名单',
	'SECURITY_FEATURES.ADD_2': '将新短信服务（SMS）添加至白名单',
	'SECURITY_FEATURES.ADD_3': '将新语音添加至白名单',
	'SECURITY_FEATURES.ADD_4': '将新IP、URL、端口添加至黑名单',
	'SECURITY_FEATURES.ADD_5': '将新IP范围添加至白名单',
	'SECURITY_FEATURES.EXPORT': '输出',
	'SECURITY_FEATURES.EDIT': '编辑',
	'SECURITY_FEATURES.SELECT_PLATFORM': '选择机对机（M2M）平台',
	'SECURITY_FEATURES.FORM_1': '短信服务（SMS）',
	'SECURITY_FEATURES.FORM_2': '短信服务（SMS）',
	'SECURITY_FEATURES.FORM_3': '短信服务（SMS）',
	'SECURITY_FEATURES.FORM_4': '短信服务（SMS）',
	'SECURITY_FEATURES.FORM_5': '短信服务（SMS）',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': '成批上载',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1.上载文件',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2.确认更改',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3.系统确认',
	'SECURITY_FEATURES.CANCEL': '取消',
	'SECURITY_FEATURES.CONTINUE': '继续',
	'SECURITY_FEATURES.DONE': '完成',
	'SECURITY_FEATURES.UPLOAD_FILE': '上载文件',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': '在此处为选择的批处理行动上载文件：',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': '拖放文件或点击上载',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': '确认批处理文件上载',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': '已安排定时行动',
	'SECURITY_FEATURES.LOADER_MESSAGE': '请稍候。取决于文件大小，本操作可能需要数分钟。',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': '已成功收到批处理文件！',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': '更改在系统中处于待决状态，完成您的更新',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': '批处理文件系统错误！',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': '处理您的更新时出现问题。请稍后重试。',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': '下载模板',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': '提交的文件扩展名必须为.csv。',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': '每次上载的安全特性数量上限为 '
};
