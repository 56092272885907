import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DSSteps from 'lib/DigitalComponents/Steps';
import Notifications from '../../../../lib/DigitalComponents/Notifications';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import StepThree from '../StepThree';
import styles from './BulkUploadModal.scss';

import {
	BulkUploadModalContext,
	BulkUploadModalContextConsumer,
	BulkUploadModalContextProvider
} from '../BulkUploadModalContext';

class BulkUploadModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			batchUpload: {}
		};
	}

	mergeValues = (values) => {
		const { batchUpload } = this.state;
		this.setState({
			batchUpload: {
				...batchUpload,
				...values
			}
		});
	};

	onFinish = () => {
		const { onClose, clearError, clearUploadErrors } = this.props;
		clearUploadErrors();
		clearError();
		onClose();
	};

	renderNotifications = () => {
		const item = {
			id: 'batch-modal-error',
			type: 'error',
			title: 'Error',
			message: ['Validation Failed'],
			notificationType: 'general'
		};

		return <Notifications data-spec="notification" {...item} />;
	};

	getCustomSteps = () => {
		const { batchUpload } = this.state;
		const { id } = this.props;
		return [
			{
				label: (
					<FormattedMessage
						id={'SECURITY_FEATURES.STEP_UPLOAD_FILE'}
						defaultMessage={'1. Upload File'}
					/>
				),
				component: (
					<BulkUploadModalContextConsumer>
						<StepOne onClose={this.onFinish} id={id} />
					</BulkUploadModalContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id={'SECURITY_FEATURES.STEP_CONFIRM_CHANGES'}
						defaultMessage={'2. Confirm Changes'}
					/>
				),
				component: (
					<BulkUploadModalContextConsumer>
						<StepTwo
							onClose={this.onFinish}
							batchUpload={batchUpload}
							id={id}
						/>
					</BulkUploadModalContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id={'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION'}
						defaultMessage={'3. System Confirmation'}
					/>
				),
				component: (
					<BulkUploadModalContextConsumer>
						<StepThree onClose={this.onFinish} />
					</BulkUploadModalContextConsumer>
				)
			}
		];
	};

	prepareErrors = (errors, messages) =>
		errors.map((error) => messages[error.code] || error.message);

	render() {
		const {
			show,
			batchUploadFail,
			notifications,
			errors,
			messages
		} = this.props;
		return (
			<div data-spec="security-features-bulk-upload">
				<BulkUploadModalContextProvider>
					<Modal show={show} className={styles.modal} onClose={this.onFinish}>
						<ModalHeader
							title={
								<FormattedMessage
									id={'SECURITY_FEATURES.BULK_MODAL_TITLE'}
									defaultMessage={'Bulk Upload'}
								/>
							}
							notifications={notifications}
							errors={errors ? this.prepareErrors(errors, messages) : []}
						/>
						<ModalBody className={styles.modal_body}>
							<div className={styles.modal_notification_wrapper}>
								{batchUploadFail && this.renderNotifications()}
							</div>
							<DSSteps
								steps={this.getCustomSteps()}
								noActions
								onNext={this.mergeValues}
								onBack={this.mergeValues}
								onFinish={this.onFinish}
							/>
						</ModalBody>
						<ModalFooter>
							<BulkUploadModalContext.Consumer>
								{(context) => context.state.footer}
							</BulkUploadModalContext.Consumer>
						</ModalFooter>
					</Modal>
				</BulkUploadModalContextProvider>
			</div>
		);
	}
}

const { bool, func, number, array, object } = PropTypes;

BulkUploadModal.propTypes = {
	show: bool,
	onClose: func,
	clearError: func,
	batchUploadFail: bool,
	clearUploadErrors: func,
	id: number,
	notifications: array,
	errors: array,
	messages: object
};

export default BulkUploadModal;
