import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../../../utils/constants';

const locale = getLocale();
const messages = require(`../../../localizations/${locale}`).default;

export const dateFormatPlaceholder =
	messages.DISPLAY_DATE_FORMAT || 'MM/dd/yyyy';
export const dateFormat = messages.DATE_FORMAT_CODE || 'MM/dd/yyyy';

export const SimOrderingTableHeader = [
	{
		title: <FormattedMessage id="ORDER_ID" defaultMessage="Order ID" />,
		name: 'simOrderId'
	},
	{
		title: <FormattedMessage id="M2M_PLATFORM" defaultMessage="M2M Platform" />,
		name: 'platformTypeName'
	},
	{
		title: <FormattedMessage id="ORDER_DATE" defaultMessage="Order Date" />,
		name: 'createdAt'
	},
	{
		title: <FormattedMessage id="ACCOUNT_NAME" defaultMessage="Account Name" />,
		name: 'm2mAccountId'
	},
	{
		title: (
			<FormattedMessage id="PURCHASE_ORDER_NUMBER" defaultMessage="PO #" />
		),
		name: 'po'
	},
	{
		title: <FormattedMessage id="SKU" defaultMessage="SKU" />,
		name: 'simSkuName'
	},
	{
		title: <FormattedMessage id="QUANTITY" defaultMessage="Quantity" />,
		name: 'quantity'
	},
	{
		title: <FormattedMessage id="ORDER_STATUS" defaultMessage="Order Status" />,
		name: 'orderStatusName'
	},
	{
		title: (
			<FormattedMessage
				id="TARGET_IMPLEMENTATION_DATE"
				defaultMessage="Target Implementation Date"
			/>
		),
		name: 'targetImplementationDate'
	},
	{
		title: <FormattedMessage id="EXPEDITED" defaultMessage="Expedited" />,
		name: 'expeditedShipping'
	}
];

export const SystemSimOrderingTableHeader = [
	{
		title: <FormattedMessage id="ORDER_ID" defaultMessage="Order ID" />,
		name: 'simOrderId'
	},
	{
		title: <FormattedMessage id="CUSTOMER" defaultMessage="Customer" />,
		name: 'companyName'
	},
	{
		title: <FormattedMessage id="M2M_PLATFORM" defaultMessage="M2M Platform" />,
		name: 'platformTypeName'
	},
	{
		title: <FormattedMessage id="M2M_ACCOUNT_ID" defaultMessage="M2M Account Id" />,
		name: 'm2mAccountId'
	},
	{
		title: <FormattedMessage id="ORDER_DATE" defaultMessage="Order Date" />,
		name: 'createdAt'
	},
	{
		title: <FormattedMessage id="ACCOUNT_NAME" defaultMessage="Account Name" />,
		name: 'm2mAccountName'
	},
	{
		title: (
			<FormattedMessage id="PURCHASE_ORDER_NUMBER" defaultMessage="PO #" />
		),
		name: 'po'
	},
	{
		title: <FormattedMessage id="SKU" defaultMessage="SKU" />,
		name: 'simSkuName'
	},
	{
		title: <FormattedMessage id="QUANTITY" defaultMessage="Quantity" />,
		name: 'quantity'
	},
	{
		title: <FormattedMessage id="ORDER_STATUS" defaultMessage="Order Status" />,
		name: 'orderStatusName'
	},
	{
		title: (
			<FormattedMessage
				id="TARGET_IMPLEMENTATION_DATE"
				defaultMessage="Target Implementation Date"
			/>
		),
		name: 'targetImplementationDate'
	},
	{
		title: <FormattedMessage id="EXPEDITED" defaultMessage="Expedited" />,
		name: 'expeditedShipping'
	}
];

export const SimOrderingTableHeaderMobile = [
	{
		title: <FormattedMessage id="ORDER_ID" defaultMessage="Order ID" />,
		name: 'simOrderId'
	},
	{
		title: <FormattedMessage id="M2M_PLATFORM" defaultMessage="M2M Platform" />,
		name: 'platformTypeName'
	}
];

export const simOrderingSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="ORDER_ID" defaultMessage="Order Id" />
			</div>
		),
		value: 'simOrderId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="ORDER_DATE" defaultMessage="Order Date" />
			</div>
		),
		value: 'createdAt',
		placeholder: dateFormatPlaceholder
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="ACCOUNT_NAME" defaultMessage="Account Name" />
			</div>
		),
		value: 'm2mAccountId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="PURCHASE_ORDER_NUMBER" defaultMessage="PO #" />
			</div>
		),
		value: 'po'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="SKU" defaultMessage="SKU" />
			</div>
		),
		value: 'simSkuName'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="QUANTITY" defaultMessage="Quantity" />
			</div>
		),
		value: 'quantity'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage
					id="TARGET_IMPLEMENTATION_DATE"
					defaultMessage="Target Implementation Date"
				/>
			</div>
		),
		value: 'targetImplementationDate',
		placeholder: dateFormatPlaceholder
	}
];
