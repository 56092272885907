import { handleActions } from 'redux-actions';
import { groupBy } from 'lodash';

import { Status } from '../../../../utils/constants';
import {
	GET_AVAILABLE_STATES
} from '../constants';

export const initialState = {
	availableStates: null,
	status: Status.INIT
};

export default handleActions(
	{
		[`${GET_AVAILABLE_STATES}_REQUEST`]: (state) => ({
				...state,
				status: Status.PENDING
			}),
		[`${GET_AVAILABLE_STATES}_SUCCESS`]: (state, action) => {
			const availableStates = groupBy(
				action.payload.data.allowedStates,
				'serviceProvider'
			);
			return {
				...state,
				availableStates,
				status: Status.DONE
			};
		},
		[`${GET_AVAILABLE_STATES}_FAIL`]: (state, action) => ({
				...state,
				status: Status.FAIL,
				error: action.payload.error
			})
	},
	initialState
);
