/* eslint-disable no-plusplus */
import axios from 'axios';
import ApiClient from '../../../utils/ApiClient';

/* ------------ REQUEST UPLOAD ------------ */
export const uploadFileRequestURL = (data) => ApiClient.post('/batch/file-upload', data)
		.then((response) => {
			const fileRequest = response ? response.data : { name: data.fileName };
			return fileRequest;
		})
		.catch((error) => {
			throw { error: error.response.data };
		});

/* ------------ FILE UPLOAD ------------ */
export const fileUpload = (url, file) => {
	const options = {
		headers: {
			'Content-Type': 'application/octet-stream'
		}
	};
	return axios.put(url, file, options)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});
};

/* ------------ CHECK UPLOAD STATUS ------------ */
export const checkFileUploadStatus = (data) => ApiClient.get(`/batch/file-upload/${data.id}`)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});

/* ------------ POLL FOR FILE ------------ */
export const pollForFile = (data, retries, delay) => {
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				checkFileUploadStatus(data)
					.then((response) => {
						/* 	if (response.status == 0 || response.status == 1) {
							console.log('poll 0')
							resolve(pollForFile(data, retries, delay));
						} else */
						if (response.status === '1' || response.status === '6') {
							resolve(response);
						} else {
							reject({ error: response });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
  return new Promise(function (resolve, reject) {
    reject({ error: [] });
  });
};
