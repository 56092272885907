import React from 'react';
import PropTypes from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import AccountOverview from '../../components/AccountOverview';

const AccountOverviewView = ({ dispatch }) => (
	<>
		<DesktopMediaQuery>
			<AccountOverview
				dispatch={dispatch}
				dataGridSize="lg"
			/>
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<AccountOverview
				dispatch={dispatch}
				dataGridSize="md"
			/>
		</TabletMediaQuery>
		<MobileMediaQuery>
			<AccountOverview
				dispatch={dispatch}
				dataGridSize="sm"
			/>
		</MobileMediaQuery>
	</>
);

const { func } = PropTypes;

AccountOverviewView.propTypes = {
	dispatch: func,
};

AccountOverviewView.defaultProps = {
	dispatch: undefined,
};

export default AccountOverviewView;
