import { handleActions } from 'redux-actions';

import { OPEN_MODAL, CLOSE_MODAL } from './constants';

export const initialState = {
	isOpened: false,
	type: ''
};

export default handleActions(
	{
		[OPEN_MODAL]: (state, action) => ({
				...state,
				isOpened: true,
				type: action.payload
			}),
		[CLOSE_MODAL]: (state) => ({
				...state,
				isOpened: false
			})
	},
	initialState
);
