import React from 'react';
import {
	func,
	object,
	array,
	string,
} from 'prop-types';

import DSSteps from '../../../../lib/DigitalComponents/Steps';
import { StepFirst, StepSecond, StepThird } from './Steps';

export default function NetworkEntitlementChangeStepper({
	entitlements,
	ModalContextConsumer,
	stepFirstProps,
	stepSecondProps,
	stepThirdProps,
	onNext,
	onBack,
	onFinish,
	networkEntitlementId,
	intl,
}) {
	let entitlementsList = null;
	let originalEntitlement = null;

	if (entitlements) {
		entitlementsList = entitlements;

		originalEntitlement = entitlements.find(
			(item) => item.id === networkEntitlementId
		);
	}

	const customSteps = [
		{
			label: `1. ${intl.formatMessage({
				id: 'SELECT_NETWORK_ENTITLEMENT',
				defaultMessage: 'Select Network Entitlement',
			})}`,
			component: (
				<ModalContextConsumer>
					<StepFirst
						originalEntitlement={originalEntitlement}
						entitlements={entitlementsList}
						endpointId={stepFirstProps.endpointId}
						chosenEntitlementId={stepFirstProps.chosenEntitlementId}
						onClose={stepFirstProps.onClose}
						m2mAccountId={stepFirstProps.m2mAccountId}
					/>
				</ModalContextConsumer>
			)
		},
		{
			label: `2. ${intl.formatMessage({
				id: 'CONFIRM_CHANGES',
				defaultMessage: 'Confirm Changes'
			})}`,
			component: (
				<ModalContextConsumer>
					<StepSecond
						onClose={stepSecondProps.onClose}
						chosenEntitlementId={stepSecondProps.chosenEntitlementId}
						endpointId={stepSecondProps.endpointId}
						networkEntitlementName={stepSecondProps.networkEntitlementName}
						changeNetworkEntitlement={stepSecondProps.changeNetworkEntitlement}
					/>
				</ModalContextConsumer>
			)
		},
		{
			label: `3. ${intl.formatMessage({
				id: 'SYSTEM_CONFIRMATION',
				defaultMessage: 'System Confirmation',
			})}`,
			component: (
				<ModalContextConsumer>
					<StepThird
						onClose={stepThirdProps.onClose}
						chosenEntitlementId={stepThirdProps.chosenEntitlementId}
						endpointId={stepThirdProps.endpointId}
						lastStep={stepThirdProps.lastStep}
						isChanging={stepThirdProps.isChanging}
						changeSuccess={stepThirdProps.changeSuccess}
						changeFail={stepThirdProps.changeFail}
						startDate={stepThirdProps.startDate}
						getSingleEndpoint={stepThirdProps.getSingleEndpoint}
						type={stepThirdProps.type}
					/>
				</ModalContextConsumer>
			)
		}
	];

	return (
		<DSSteps
			data-spec="ds-steps"
			steps={customSteps}
			onNext={onNext}
			noActions
			onBack={onBack}
			onFinish={onFinish}
		/>
	);
}

NetworkEntitlementChangeStepper.propTypes = {
	onNext: func,
	onBack: func,
	onFinish: func,
	stepThirdProps: object,
	stepFirstProps: object,
	stepSecondProps: object,
	ModalContextConsumer: func,
	entitlements: array,
	networkEntitlementId: string,
	intl: object,
};
