import React from 'react';
import { func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './CancelSchedulePromptModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

export default function CancelSchedulePromptModal({
	show,
	onCancel,
	onConfirm,
}) {
	return (
		<Modal
			data-spec="dashboard-prompt-modal"
			show={show}
			onClose={onCancel}
		>
			<ModalHeader
				title={<FormattedMessage id="CAUTION" defaultMessage="Caution" />}
			/>
			<ModalBody className={styles.dashboard_modal_body}>
				<div data-spec="body-message" className={styles.prompt_body}>
					<div className={styles.modal_text_block}>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE"
								defaultMessage="Are you sure you want to cancel pending change?"
							/>
							<br />
							<FormattedMessage
								id="SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT"
								defaultMessage="This action cannot be undone."
							/>
						</div>
					</div>
				</div>
				<div>
					<Prompt />
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				<div data-spec="buttons">
					<Button
						onClick={onCancel}
						variant="link"
						label={<FormattedMessage
							id="CANCEL"
							defaultMessage="Cancel"
						/>}
					/>
					<Button
						variant="primary"
						onClick={onConfirm}
						label={<FormattedMessage
							id="CONFIRM_CANCELLATION"
							defaultMessage="Confirm cancellation"
						/>}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
}

CancelSchedulePromptModal.propTypes = {
	onCancel: func,
	onConfirm: func,
	show: bool,
};
CancelSchedulePromptModal.defaultProps = {
	onCancel: undefined,
	onConfirm: undefined,
	show: false,
};
