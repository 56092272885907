import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { getDateTimeFormat } from '../../../../utils/constants';
import { ComponentsRender } from '../../../../utils/AuthSelector';

import {
	SupportTableHeader as tableHeader,
	SupportTableHeaderAll as tableHeaderAll
} from '../../utils/constants';

import AddNotification from '../AddNotification';

import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';

import styles from './NotificationsList.scss';

import { localizationHelper } from '../../../../utils/helperFunctions';

const AddIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/pluss-add-collapse.svg'
);

const SupportRWComponent = ComponentsRender('mnc.alerting_rw');
const GlobalSupportRWComponent = ComponentsRender('system.globalnotifications_rw');

const NotificationsList = () => (WrappedComponent) => {
	class NotificationsListComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				isAll: false,
				dataOffset: 0,
				selectedPage: 0
			};
		}

		async componentDidMount() {
			await this.getNotifications();
			this.c && window.scrollTo(0, this.c.offsetTop);
		}

		componentDidUpdate(prevProps) {
			const {
				addNotificationSuccess,
				editNotificationSuccess,
				getActiveNotifications,
				removeNotificationSuccess
			} = this.props;
			if (
				prevProps.addNotificationSuccess !== addNotificationSuccess ||
				prevProps.editNotificationSuccess !== editNotificationSuccess ||
				prevProps.removeNotificationSuccess !== removeNotificationSuccess
			) {
				this.updateIsAll();
				this.getNotifications();
				getActiveNotifications();
			}
		}

		updateIsAll = () => {
			this.setState({ isAll: false });
		};

		getNotifications = () => {
			const { getNotifications, dataLimit } = this.props;
			getNotifications({
				additionalParams: {
					include: ['NotificationType'],
					dataLimit,
					dataOffset: 0,
					dataSort: 'createdAt DESC'
				}
			});
		};

		getAllNotifications = () => {
			const { getAllNotifications, dataLimit } = this.props;
			getAllNotifications({
				additionalParams: {
					include: ['NotificationType'],
					dataLimit,
					dataOffset: 0,
					dataSort: 'createdAt DESC'
				}
			});
		};

		getAll = () => {
			this.setState({ isAll: true });
		};

		openModal = () => {
			const {
				openAddNotificationModal,
				getNotificationsGroups,
				getNotificationsTypes
			} = this.props;
			openAddNotificationModal();
			getNotificationsGroups();
			getNotificationsTypes();
		};

		closeModal = () => {
			const { closeAddNotificationModal, getNotificationInit } = this.props;
			getNotificationInit();
			closeAddNotificationModal();
		};

		handlePageClick = (data) => {
			const { getAllNotifications, dataLimit } = this.props;
			getAllNotifications({
				additionalParams: {
					include: ['NotificationType'],
					dataLimit,
					dataOffset: dataLimit * data.selected,
					dataSort: 'createdAt desc'
				}
			});
			this.setState({
				first: dataLimit * data.selected,
				last: dataLimit + dataLimit * data.selected,
				selectedPage: data.selected
			});
			window.scrollTo(0, 0);
		};

		totalCount = () => {
			const { notifications, dataLimit } = this.props;
			return Math.ceil(notifications.totalCount / dataLimit);
		};

		editNotification = (data) => {
			const { getNotification } = this.props;
			getNotification(data.notificationId, data.accountId, {
				additionalParams: {
					include: ['NotificationType', 'NotificationGroupActives']
				}
			});
			this.openModal();
		};

		onSubmit = () => {
			const { values } = this.props;
			this.submitNotification(values);
		};

		submitNotification = (values) => {
			const { editNotification, addNotification } = this.props;
			if (values && values.id) {
				const notification = {
					note: values.note,
					notificationTypeId:
						values.notificationTypeId.value || values.notificationTypeId,
					notificationGroupActiveUpdateRequests: []
				};

				Object.keys(values).forEach((key) => {
					if (
						key.startsWith('notificationGroupTypeId') &&
						values[key] === true
					) {
						const notificationGroupTypeId = key.substr(
							'notificationGroupTypeId'.length
						);
						notification.notificationGroupActiveUpdateRequests = [
							...notification.notificationGroupActiveUpdateRequests,
							{
								notificationGroupTypeId: parseInt(notificationGroupTypeId, 10)
							}
						];
					}
				});
				editNotification(notification, values.id, values.accountId);
			} else {
				const notification = {
					note: values.note,
					notificationTypeId:
						values.notificationTypeId.value || values.notificationTypeId,
					notificationGroupInsertRequests: []
				};
				Object.keys(values).forEach((key) => {
					if (
						key.startsWith('notificationGroupTypeId') &&
						values[key] === true
					) {
						const notificationGroupTypeId = key.substr(
							'notificationGroupTypeId'.length
						);
						notification.notificationGroupInsertRequests = [
							...notification.notificationGroupInsertRequests,
							{
								notificationGroupTypeId: parseInt(notificationGroupTypeId, 10)
							}
						];
					}
				});
				addNotification(notification, values.accountId);
			}
		};

		onRemove = (id, global) => {
			const { removeNotification } = this.props;
			removeNotification(id, global);
		};

		renderCreatedAt = (val) => getDateTimeFormat(val.createdAt);

		renderNotificationType = (val) => (
			<span
				data-spec={`notification-type-${val.note}`}
				className={cn(
					styles.status,
					styles[val.notificationType.name.toLowerCase()]
				)}
			>
				<FormattedMessage
					id={`SUPPORT.NOTIFICATION_${localizationHelper(
						val.notificationType.name
					).toUpperCase()}`}
					defaultMessage={val.notificationType.name}
				/>
			</span>
		);

		renderNotificationScope = (val) => (
			<span data-spec="notification-scope">
				{val.accountId.toLowerCase() === 'global' ? (
					<FormattedMessage id="SUPPORT.GLOBAL" defaultMessage="Global" />
				) : (
					<FormattedMessage
						id="SUPPORT.COMPANY_RELATED"
						defaultMessage="Company Related"
					/>
				)}
			</span>
		);

		renderNote = (val) => (
			<div data-spec={`notification-type-${val.note}`} className={styles.note}>
				{val.note}
			</div>
		);

		renderEditNotification = (val) => {
			if (val.accountId.toLowerCase() === 'global') {
				return (
					<GlobalSupportRWComponent data-spec={`notification-type-${val.note}`}>
						<span
							data-spec={`notification-type-${val.note}`}
							className={styles.link}
							onClick={() => this.editNotification(val)}
						>
							<FormattedMessage id="EDIT" defaultMessage="Edit" />
						</span>
					</GlobalSupportRWComponent>
				);
			}
				return (
					<SupportRWComponent data-spec={`notification-type-${val.note}`}>
						<span
							data-spec={`notification-type-${val.note}`}
							className={styles.link}
							onClick={() => this.editNotification(val)}
						>
							<FormattedMessage id="EDIT" defaultMessage="Edit" />
						</span>
					</SupportRWComponent>
				);
		};

		getOptions = (target) => {
			const { selectedPage, isAll } = this.state;
			const { notifications, dataLimit } = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			options.header = !isAll ? tableHeader : tableHeaderAll;
			options.tableOptions = {
				showPagination: notifications.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				onPageChange: (data) => this.handlePageClick(data)
			};

			if (isDesktop) {
				options.customComponents = {
					createdAt: {
						type: 'custom',
						component: (val) => this.renderCreatedAt(val)
					},
					notificationType: {
						type: 'custom',
						component: (val) => this.renderNotificationType(val)
					},
					notificationScope: {
						type: 'custom',
						component: (val) => this.renderNotificationScope(val)
					},
					note: {
						type: 'custom',
						component: (val) => this.renderNote(val)
					},
					editNotification: {
						type: 'custom',
						component: (val) => this.renderEditNotification(val)
					}
				};
			}

			if (isMobile) {
				options.tableOptions = {
					...options.tableOptions,
					headerNameComponent: (val) => this.renderCreatedAt(val)
				};
				options.customComponents = {
					createdAt: {
						type: 'custom',
						component: (val) => this.renderCreatedAt(val)
					},
					notificationType: {
						type: 'custom',
						component: (val) => this.renderNotificationType(val)
					},
					notificationScope: {
						type: 'custom',
						component: (val) => this.renderNotificationScope(val)
					},
					note: {
						type: 'custom',
						component: (val) => this.renderNote(val)
					}
				};
			}

			return options;
		};

		render() {
			const {
				messages,
				notificationsRequest,
				notificationsTypes,
				notificationsGroups,
				addNotificationRequest,
				isAddNotificationModalOpen,
				notifications,
				notificationsGroupsRequest,
				notificationsTypesRequest,
				getNotificationInit
			} = this.props;

			return (
				<div
					data-spec="support"
					ref={(c) => {
						this.c = c;
					}}
				>
					{isAddNotificationModalOpen && (
						<AddNotification
							show
							onCancel={this.closeModal}
							messages={messages}
							types={notificationsTypes}
							typesRequest={notificationsTypesRequest}
							groups={notificationsGroups}
							groupsRequest={notificationsGroupsRequest}
							onSubmit={this.onSubmit}
							onRemove={(a, b) => this.onRemove(a, b)}
							getNotificationInit={getNotificationInit}
						/>
					)}
					<PageTitle
						title={
							<FormattedMessage
								id="SUPPORT.RECENT_NOTIFICATIONS"
								defaultMessage="Recent Notifications"
							/>
						}
						actions={
							<SupportRWComponent>
								<Button
									label={
										<FormattedMessage
											id="SUPPORT.CREATE_NEW_NOTIFICATION"
											defaultMessage="Create New Notification"
										/>
									}
									labelIcon={<AddIcon className={styles.add_icon} />}
									variant="primary"
									className={styles.button_width}
									dataSpec="create-new-company-button"
									onClick={() => this.openModal()}
								/>
							</SupportRWComponent>
						}
						messages={messages}
						showNotifications={false}
					/>
					{(notificationsRequest || addNotificationRequest) && (
						<Loading data-spec="loading" />
					)}
					{notifications && notifications.totalCount !== undefined && (
						<div className={styles.notification_bar}>
							<WrappedComponent
								{...this.state}
								{...this.props}
								getOptions={this.getOptions}
								getAllNotifications={this.getAllNotifications}
								getAll={this.getAll}
							/>
						</div>
					)}
				</div>
			);
		}
	}

	const { func, number, bool, string, objectOf, shape, array } = PropTypes;

	NotificationsListComponent.propTypes = {
		messages: objectOf(string),
		getNotifications: func,
		getNotificationsGroups: func,
		getNotificationsTypes: func,
		dataLimit: number,
		getAllNotifications: func,
		totalCount: number,
		getNotification: func,
		openAddNotificationModal: func,
		closeAddNotificationModal: func,
		values: shape(),
		editNotification: func,
		getNotificationInit: func,
		addNotification: func,
		removeNotification: func,
		notificationsRequest: bool,
		notificationsTypes: shape({
			resultList: array
		}),
		notificationsGroups: shape({
			resultList: array
		}),
		addNotificationRequest: bool,
		isAddNotificationModalOpen: bool,
		notifications: shape({
			resultList: array
		}),
		addNotificationSuccess: bool,
		editNotificationSuccess: bool,
		getActiveNotifications: func,
		removeNotificationSuccess: bool,
		notificationsGroupsRequest: bool,
		notificationsTypesRequest: bool
	};
	return NotificationsListComponent;
};

export default NotificationsList;
