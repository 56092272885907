import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, getFormValues } from 'redux-form';
import Button from '../../../../../lib/DigitalComponents/Button';
import styles from './Steps.scss';

import FieldGroup from '../../../../../lib/DigitalComponents/FieldGroup';

const validate = (values) => {
  const errors = {};

	if (!values.seNotes) {
		errors.seNotes = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	} else if (values.seNotes.length > 2000) {
		errors.seNotes = (
			<FormattedMessage
				id="ENDPOINTS.MAX_CHARACTERS_EXCEEDED"
				defaultMessage="Character limit exceeded"
			/>
		);
	} else if (!values.seNotes.replace(/\s/g, '').length) {
		errors.seNotes = (
			<FormattedMessage
				id="ENDPOINTS.NO_ONLY_WHITE_SPACE"
				defaultMessage="Cannot be only white spaces"
			/>
		);
	}

	return errors;
};

class StepFirst extends Component {
	constructor(props) {
		super(props);
		this.state = {
			originalSelectedEndpoints: props.selectedSims
		};
	}

	componentDidMount() {
		const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
		const { context, invalid } = this.props;
		if (context && invalid !== prevProps.invalid) {
			this.setFooter(context);
		}
	}

	setFooter = (context) => {
		const { onClose, invalid } = this.props;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<Button
					dataSpec="continue"
					variant="primary"
					onClick={this.onNext}
					label={
						<FormattedMessage
							id="ENDPOINTS.SAVE_NOTE"
							defaultMessage="Save Note"
						/>
					}
					disabled={invalid}
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderModalBody = () => (
		<div data-spec="add-note-area">
			<span className={styles.note_span}>
				<FormattedMessage
					id="ENDPOINTS.ADD_NOTE_BELOW"
					defaultMessage="Add Note below: "
				/>
			</span>
			<div className={styles.text_area_wrapper}>
				<FormattedMessage
					id="ENDPOINTS.ENTER_NOTE_HERE"
					defaultMessage="Enter Note here (2000 Characters Max)"
				>
					{(formattedValue) => (
						<Field
							componentClass="textarea"
							component={FieldGroup}
							className={styles.text_area_field}
							name="seNotes"
							dataSpec="notes-modal-textarea"
							placeholder={formattedValue}
						/>
					)}
				</FormattedMessage>
			</div>
		</div>
	);

	// onNext
	onNext = () => {
		const { onNext, formValues } = this.props;
		const { originalSelectedEndpoints } = this.state;
		onNext({
			seNotes: formValues.seNotes,
			originalSelectedEndpoints
		});
	};

	render() {
		return <div data-spec="add-note-first-step">{this.renderModalBody()}</div>;
	}
}

const { array, func, object, string, any } = PropTypes;

StepFirst.propTypes = {
	formValues: object,
	seNotes: string,
	selectedSims: array,
	onClose: func,
	onNext: func,
	context: object,
	invalid: any
};

const mapStateToProps = (state) => ({
		formValues: getFormValues('addNoteForm')(state)
	});

const NoteForm = reduxForm({
	form: 'addNoteForm',
	validate,
	destroyOnUnmount: true
})(StepFirst);

export default connect(mapStateToProps)(NoteForm);
