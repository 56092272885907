import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Icon from 'lib/DigitalComponents/Icons';
import PageTitle from '../../../Shared/views/PageTitleView';
import { getLocale } from '../../../../utils/constants';

import styles from './Faq.scss';

const Faq = (WrappedComponent) => {
	class FaqComponent extends Component {
		constructor(props) {
			super(props);
			this.state = {
				faqAvailable: false,
				fileMarkdown: null,
			};
		}

		async componentDidMount() {
      const locale = getLocale().toLowerCase();
      const importedFile = await import(
				`../../../../brands/md/faq/Faq-${locale}.md`
			);

			this.setState({
				fileMarkdown: importedFile.default,
				faqAvailable: true,
			});
		}

		goBack = () => {
			const { goBack } = this.props;
			goBack();
		};

		render() {
			return (
				<div data-spec="faq">
					<PageTitle
						title={
							<FormattedMessage
								id="SUPPORT.FAQ"
								defaultMessage="Frequently Asked Questions"
							/>
						}
						actions={
							<a
								href={`/assets/Faq-${getLocale().toLowerCase()}.pdf`}
								className={`${styles.download_link}`}
								download={`Faq-${getLocale().toLowerCase()}.pdf`}
							>
								<Icon name="print" className={'ds-icon'} />
								<FormattedMessage
									id="SUPPORT.PRINT_PDF"
									defaultMessage="Print PDF"
								/>
							</a>
						}
						pushBack={this.goBack}
					/>
					<WrappedComponent
						{...this.state}
						{...this.props}
						contentId={styles.wrapper}
					/>
				</div>
			);
		}
	}

	const { func } = PropTypes;

	FaqComponent.propTypes = {
		goBack: func
	};

	return FaqComponent;
};

export default Faq;
