import { Status } from '../../../../utils/constants';

export const addRatePlanRequest = (state) =>
	state.onboarding.ratePlans.addRatePlan.status === Status.PENDING;
export const addRatePlanFail = (state) =>
	state.onboarding.ratePlans.addRatePlan.status === Status.FAIL;
export const addRatePlanSuccess = (state) =>
	state.onboarding.ratePlans.addRatePlan.data;

export const editRatePlanRequest = (state) =>
	state.onboarding.ratePlans.editRatePlan.status === Status.PENDING;
export const editRatePlanFail = (state) =>
	state.onboarding.ratePlans.editRatePlan.status === Status.FAIL;
export const editRatePlanSuccess = (state) =>
	state.onboarding.ratePlans.editRatePlan.status === Status.DONE;

export const getAllowedTypesRequest = (state) =>
	state.onboarding.ratePlans.getAllowedTypes.status === Status.PENDING;
export const getAllowedTypesFail = (state) =>
	state.onboarding.ratePlans.getAllowedTypes.status === Status.FAIL;
export const getAllowedTypesSuccess = (state) =>
	state.onboarding.ratePlans.getAllowedTypes.data;

export const getRatePlanRequest = (state) =>
	state.onboarding.ratePlans.getRatePlan.status === Status.PENDING;
export const getRatePlanFail = (state) =>
	state.onboarding.ratePlans.getRatePlan.status === Status.FAIL;
export const getRatePlanSuccess = (state) =>
	state.onboarding.ratePlans.getRatePlan.data;

export const getRatePlansRequest = (state) =>
	state.onboarding.ratePlans.getRatePlans.status === Status.PENDING;
export const getRatePlansFail = (state) =>
	state.onboarding.ratePlans.getRatePlans.status === Status.FAIL;
export const getRatePlansSuccess = (state) =>
	state.onboarding.ratePlans.getRatePlans.data;

export const isAddRatePlansModalOpened = (state) =>
	state.onboarding.ratePlans.addRatePlansModal.isOpen;

export const getParentRatePlansRequest = (state) =>
	state.onboarding.ratePlans.getParentRatePlans.status === Status.PENDING;
export const getParentRatePlansFail = (state) =>
	state.onboarding.ratePlans.getParentRatePlans.status === Status.FAIL;
export const getParentRatePlansSuccess = (state) =>
	state.onboarding.ratePlans.getParentRatePlans.data;
