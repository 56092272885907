import { connect } from 'react-redux';
import { destroy, getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';

import AddTicket from './AddTicket';

import { addTicket } from '../../redux/tickets/actions';
import { hideNotification } from '../../../Shared/redux/notifications/actions';

import {
	addTicketRequest,
	addTicketFail,
	addTicketSuccess
} from '../../redux/tickets/selectors';
import {
	getCategories,
	getFirstSubCategories,
	getSecondSubCategories,
	getThirdSubCategories,
	getSeverities,
	getPriorities,
	getCountries,
	getStates,
	getConfirmation
} from '../../redux/options/actions';
import {
	getCategoriesRequest,
	getCategoriesSuccess,
	getFirstSubCategoriesRequest,
	getFirstSubCategoriesSuccess,
	getSecondSubCategoriesRequest,
	getSecondSubCategoriesSuccess,
	getThirdSubCategoriesRequest,
	getThirdSubCategoriesSuccess,
	getSeveritiesRequest,
	getSeveritiesSuccess,
	getPrioritiesRequest,
	getPrioritiesSuccess,
	getConfirmationRequest,
	getConfirmationSuccess,
	getCountriesRequest,
	getCountriesSuccess,
	getStatesRequest,
	getStatesSuccess
} from '../../redux/options/selectors';
import {
	setTicketFormPageNext,
	setTicketFormPagePrev,
	setTicketFormPageInit
} from '../../redux/getTicketFormPage/actions';

import {
	getEndpointDataPending,
	getEndpointDataSuccess
} from '../../redux/getEndpointData/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';
import getTicketFormPage from '../../redux/getTicketFormPage/selectors';
import {
	fileUploadRequest,
	fileUploadSuccess,
	uploadFileNumber
} from '../../../Shared/redux/uploadFile/selectors';

import {
	getEndpointData,
	setEndpointTicketingInit,
	setEndpointTicketingReset,
} from '../../redux/getEndpointData/actions';

import {
	resetFiles,
	clearUploadFiles,
	fileRequest,
	resetFileNumbers,
	resetErrors
} from '../../../Shared/redux/uploadFile/actions';

import {
	getNetworkEntitlement,
	getNetworkEntitlementInit
} from '../../../SingleEndpoint/redux/actions';

import { getNetworkEntitlementData } from '../../../SingleEndpoint/redux/selectors';

const mapStateToProps = (state) => ({
	addTicketRequest: addTicketRequest(state),
	addTicketFail: addTicketFail(state),
	addTicketSuccess: addTicketSuccess(state),
	categoriesRequest: getCategoriesRequest(state),
	categories: getCategoriesSuccess(state),
	firstSubCategoriesRequest: getFirstSubCategoriesRequest(state),
	firstSubCategories: getFirstSubCategoriesSuccess(state),
	secondSubCategoriesRequest: getSecondSubCategoriesRequest(state),
	secondSubCategories: getSecondSubCategoriesSuccess(state),
	thirdSubCategoriesRequest: getThirdSubCategoriesRequest(state),
	thirdSubCategories: getThirdSubCategoriesSuccess(state),
	severitiesRequest: getSeveritiesRequest(state),
	severities: getSeveritiesSuccess(state),
	prioritiesRequest: getPrioritiesRequest(state),
	priorities: getPrioritiesSuccess(state),
	confirmationRequest: getConfirmationRequest(state),
	confirmation: getConfirmationSuccess(state),
	countriesRequest: getCountriesRequest(state),
	countries: getCountriesSuccess(state),
	statesRequest: getStatesRequest(state),
	states: getStatesSuccess(state),
	endpointRequest: getEndpointDataPending(state),
	endpointSuccess: getEndpointDataSuccess(state),
	user: getUser(state),
	currentFormPage: getTicketFormPage(state),
	fileRequest: fileUploadRequest(state),
	fileSuccess: fileUploadSuccess(state),
	uploadFileNumber: uploadFileNumber(state),
	formValues: getFormValues('addTicket')(state),
	apns: getNetworkEntitlementData(state)
});

const mapDispatchToProps = (dispatch) => ({
	addTicket: (data, formatMessage) => dispatch(addTicket(data, formatMessage)),
	getCategories: (params) => dispatch(getCategories(params)),
	getFirstSubCategories: (params) => dispatch(getFirstSubCategories(params)),
	getSecondSubCategories: (params) => dispatch(getSecondSubCategories(params)),
	getThirdSubCategories: (params) => dispatch(getThirdSubCategories(params)),
	getSeverities: (params) => dispatch(getSeverities(params)),
	getPriorities: (params) => dispatch(getPriorities(params)),
	pushBack: () => dispatch(localizedRoute('/support/ticketing')),
	nextPage: () => dispatch(setTicketFormPageNext()),
	previousPage: () => dispatch(setTicketFormPagePrev()),
	destroyForm: () => {
		dispatch(destroy('addTicket'));
		dispatch(setTicketFormPageInit());
	},
	getCountriesStates: (params) => {
		dispatch(getCountries());
		dispatch(getStates(params));
	},
	getConfirmation: (params) => dispatch(getConfirmation(params)),
	getEndpointData: (paginationData, searchData) =>
		dispatch(getEndpointData(paginationData, searchData)),
	setEndpointTicketingInit: () => dispatch(setEndpointTicketingInit()),
	setEndpointTicketingReset: () => dispatch(setEndpointTicketingReset()),
	resetFiles: () => dispatch(resetFiles()),
	clearUploadFiles: (name) =>
		(name && dispatch(clearUploadFiles(name))) || dispatch(clearUploadFiles()),
	fileRequest: (data) => dispatch(fileRequest(data)),
	resetFileNumbers: () => dispatch(resetFileNumbers()),
	resetErrors: () => dispatch(resetErrors()),
	getNetworkEntitlement: (m2mAccountId) =>
		dispatch(getNetworkEntitlement(m2mAccountId)),
	removeError: () => {
		dispatch(hideNotification('endpoint-invalid-parametar'));
		dispatch(hideNotification('upload-file-error'));
	},
	getNetworkEntitlementInit: () => dispatch(getNetworkEntitlementInit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTicket);
