export const releaseDetailType = {
	KNOWN_ISSUES: 1,
	NEW_FEATURES: 2,
	NEW_PLATFORM_FEATURES: 3,
	IMPROVEMENTS: 4,
	DEPRECATION: 5
};

export const releaseStatus = {
  DRAFT: 1,
  PUBLISHED: 2
};
