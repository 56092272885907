import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_BILLING_GROUPS, GET_BILLING_GROUPS_FILTERS } from './constants';

export default combineReducers({
	getBillingGroups: globalReducer(GET_BILLING_GROUPS),
	getBillingGroupsFilters: globalReducer(GET_BILLING_GROUPS_FILTERS)
});
