import React, { Component } from 'react';
import { object, func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { destroy } from 'redux-form';

import PageTitle from '../../../Shared/views/PageTitleView';

import Loading from '../../../../lib/DigitalComponents/Loader';

import AddCompanyForm from '../AddCompanyForm';
import AddCompanyOverview from '../AddCompanyOverview';

class AddCompany extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'form'
		};
	}

	componentDidUpdate(prevProps) {
		const { addCompanySuccess } = this.props;
		if (addCompanySuccess !== prevProps.addCompanySuccess) {
			this.goToAddCompanySuccess(addCompanySuccess.id);
		}
	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch(destroy('AddCompanyForm'));
	}

	pushBack = () => {
		const { pushBack } = this.props;
		pushBack('/companies');
	};

	goToAddCompanySuccess = (id) => {
		const { goToAddCompanySuccess } = this.props;
		goToAddCompanySuccess(`/companies/add-company-success/${id}`);
	};

	nextPage = () => {
		this.setState({ page: 'overview' });
	};

	previousPage = () => {
		this.setState({ page: 'form' });
	};

	submitForm = (values) => {
		const { addCompany } = this.props;
		const company = {
			companyFriendlyName: values.companyFriendlyName,
			companyLegalName: values.companyLegalName,
			languageId: values.languageId.value,
			supportType: values.supportType
		};

		company.addresses = values.addresses.map((address) => {
			const temp = {
				countryId: address.countryId.value,
				street1: address.street1,
				street2: address.street2 || '',
				city: address.city,
				stateId: address.stateId.value,
				zipCode: address.zipCode
			};
			return temp;
		});

		const companyContacts = values.companyContacts.map((contact) => {
			const temp = {
				firstName: contact.firstName,
				lastName: contact.lastName,
				jobTitle: contact.jobTitle || '',
				phoneNumber: `${contact.mask.value}-${contact.phoneNumber}`,
				email: contact.email,
				companyContactTypeId: 2
			};
			return temp;
		});

		const attContacts = values.attContacts.map((contact) => {
			const temp = {
				firstName: contact.firstName,
				lastName: contact.lastName,
				jobTitle: contact.jobTitle,
				phoneNumber: `${contact.mask.value}-${contact.phoneNumber}`,
				email: contact.email,
				companyContactTypeId: 1
			};
			return temp;
		});

		company.contacts = companyContacts.concat(attContacts);

		if (values.logo) {
			company.companyLogo = {
				logo: values.logo
			};
		}

		addCompany(company);
	};

	render() {
		const { messages, addCompanyRequest } = this.props;
		const { page } = this.state;

		return (
			<div data-spec="add-company">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.COMPANIES"
							defaultMessage="Companies"
						/>
					}
					pushBack={() => (page === 'form' ? this.pushBack() : this.previousPage())}
					messages={messages}
				/>
				{!addCompanyRequest && page === 'form' && (
					<AddCompanyForm onClose={this.pushBack} onSubmit={this.nextPage} />
				)}
				{!addCompanyRequest && page === 'overview' && (
					<AddCompanyOverview
						onClose={this.previousPage}
						onSubmit={this.submitForm}
					/>
				)}
				{addCompanyRequest && <Loading />}
			</div>
		);
	}
}

AddCompany.propTypes = {
	messages: object,
	pushBack: func,
	addCompany: func,
	addCompanyRequest: bool,
	addCompanySuccess: object,
	goToAddCompanySuccess: func,
	dispatch: func
};

export default AddCompany;
