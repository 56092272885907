/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import { Field, FieldArray, arrayRemove, reduxForm } from 'redux-form';
import { Field, reduxForm } from 'redux-form';

// import cx from 'classnames';
// import ImageSelector from 'utils/imageSelector';
// import CurrentContext from 'utils/currentContext';
import { FormattedMessage, injectIntl } from 'react-intl';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Loader from '../../../../lib/DigitalComponents/Loader';

import { ConditionIf } from '../../../../utils/helperFunctions';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

// import Dropzone from '../../../Shared/components/UploadFile';
import styles from './NotesModal.scss';

// const PlusIcon = ImageSelector(
// 	CurrentContext.theme,
// 	'svgs/pluss-add-collapse.svg'
// );
// const Document = ImageSelector(CurrentContext.theme, 'svgs/document.svg');
// const TrashIcon = ImageSelector(CurrentContext.theme, 'svgs/trash.svg');

let deletedFiles = [];

const submitForm = (val, _, props) => {
	const {
		updateInvoice,
		closeModal,
		onSubmitNotesModal,
		invoiceId,
		noteForm,
		intl
	} = props;

	const invoice = {
		notes: noteForm
	};

	const successTitle = intl.formatMessage({
		id: 'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE',
		defaultMessage: 'Update Succesfull'
	});

	const successMessage = intl.formatMessage({
		id: 'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE',
		defaultMessage: 'Your note has been successfully added.'
	});

	const errorTitle = intl.formatMessage({
		id: 'NOTES_MODAL.NOTIFICATION_ERROR_TITLE',
		defaultMessage: 'Update Failed'
	});

	const errorMessage = intl.formatMessage({
		id: 'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE',
		defaultMessage: 'Your note has been successfully added.'
	});

	const notifOptions = {
		id: 'invoice-notes',
		successMessage,
		successTitle,
		errorMessage,
		errorTitle
	};

	// let invoice = {
	// 	notes: props.noteForm
	// };

	// let fileInsertRequest = [];
	// let fileDeleteRequest = [];

	// if (deletedFiles && deletedFiles.length > 0) {
	// 	deletedFiles.forEach((item) => {
	// 		fileDeleteRequest = [
	// 			...fileDeleteRequest,
	// 			{
	// 				attachmentId: item.inovoiceAttachmentId
	// 			}
	// 		];
	// 	});
	// }
	// if (props.fileSuccess && props.fileSuccess.length > 0) {
	// 	props.fileSuccess.forEach((item) => {
	// 		fileInsertRequest = [
	// 			...fileInsertRequest,
	// 			{
	// 				fileLocation: item.location
	// 			}
	// 		];
	// 	});
	// }
	// if (fileDeleteRequest.length > 0) {
	// 	invoice = {
	// 		...invoice,
	// 		fileDeleteRequest
	// 	};
	// }
	// if (fileInsertRequest.length > 0) {
	// 	invoice = {
	// 		...invoice,
	// 		fileInsertRequest
	// 	};
	// }

	updateInvoice(invoiceId, invoice, notifOptions);
	closeModal();
	onSubmitNotesModal();

	deletedFiles = [];
};

class NotesModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showDropZone: false
		};
	}

	componentDidMount() {
		// const { modalOpened, getInvoiceAttachments, invoiceId } = this.props;
		// getInvoiceAttachments(invoiceId);
		const { modalOpened } = this.props;
		modalOpened();
	}

	// componentDidUpdate(prevProps) {
	// 	const array = [];
	// 	const {
	// 		attachments,
	// 		initialValues,
	// 		initialize,
	// 		fileSuccess,
	// 		removeError
	// 	} = this.props;
	// 	if (attachments && attachments != prevProps.attachments) {
	// 		attachments.forEach((item) => {
	// 			array.push(item);
	// 		});
	// 		initialize({ ...initialValues, analyticsInvoiceFile: array });
	// 	}
	// 	if (fileSuccess !== prevProps.fileSuccess) {
	// 		removeError();
	// 	}
	// }

	componentWillUnmount() {
		// const { resetFiles, dispatch } = this.props;
		// resetFiles();
		// dispatch(arrayRemove('analyticsInvoicesNotes'));
		this.onCancel();
	}

	isROComponent = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['mnc.invoices_ro'], user);
	};

	isRWOComponent = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['mnc.invoices_rw'], user);
	};

	showDropZoneHandler = () => {
		const { showDropZone } = this.state;
		this.setState({
			showDropZone: !showDropZone
		});
	};

	onCancel = () => {
		const { onCancel, closeModal } = this.props;
		onCancel();
		closeModal();
	};

	// downloadFile = (file) => {
	// 	const { invoiceAttachmentName, inovoiceAttachmentId } = file;
	// 	const { invoiceAttachmentDownload, invoiceId } = this.props;
	// 	const fileRequest = {
	// 		service: 'invoice',
	// 		fileName: invoiceAttachmentName,
	// 		transactionType: 'attachmentInvoice',
	// 		invoiceId,
	// 		attachmentId: inovoiceAttachmentId
	// 	};
	// 	invoiceAttachmentDownload(fileRequest);
	// };

	renderModalContent = () => {
		// const { showDropZone } = this.state;
		// const triggerClass = cx({
		// 	[styles.dropzone_trigger]: true,
		// 	[styles.dropzone__active]: showDropZone
		// });
		const {
			// messages,
			getAttachmentsRequest,
			downloadInvoiceAttachmentRequest
		} = this.props;
		return getAttachmentsRequest || downloadInvoiceAttachmentRequest ? (
			<Loader data-spec={'analytics-invoices-notes-modal'} />
		) : (
			<div data-spec="add-note-modal" style={{ padding: '20px' }}>
				<FormattedMessage
					id="ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE"
					defaultMessage="Enter your note here"
				>
					{(formattedValue) => (
						<Field
							componentClass="textarea"
							dataSpec="analytics-invoice-note"
							component={Input}
							name="note"
							disabled={this.isROComponent() && !this.isRWOComponent()}
							placeholder={formattedValue}
						/>
					)}
				</FormattedMessage>

				{/* <ConditionIf condition={this.isRWOComponent()}>
					<div
						onClick={this.showDropZoneHandler}
						className={styles.label_wrapper}
					>
						<span>
							<PlusIcon />
							<FormattedMessage
								id="ANALYTICS_INVOICES.ADD_ATTACHMENT"
								defaultMessage="Add attachment"
							/>
						</span>
					</div>
				</ConditionIf>
				<div className={triggerClass}>
					<Dropzone
						{...this.props}
						messages={messages}
						service="invoice"
						disabled
						transactionType="attachmentInvoice"
					/>
				</div> */}
				{/* <FieldArray
					name={'analyticsInvoiceFile'}
					messages={messages}
					component={this.renderNoteAttachments}
				/> */}
			</div>
		);
	};

	// renderNoteAttachments = ({ fields }) => (
	// 	<div data-spec="render-note-attachments">
	// 		{fields.length > 0 && (
	// 			<div className={styles.attachment_wrapper}>
	// 				<label className={styles.attachment}>
	// 					{
	// 						<FormattedMessage
	// 							id={'ANALYTICS_INVOICES.ATTACHMENTS_ADDED'}
	// 							defaultMessage="Attachments Added"
	// 						/>
	// 					}
	// 				</label>
	// 			</div>
	// 		)}
	// 		{fields.map((item, index) => (
	// 			<div key={index} className={styles.invoice_note}>
	// 				{fields.get(index).inovoiceAttachmentId && (
	// 					<div className={styles.existing_files}>
	// 						<ConditionIf condition={this.isRWOComponent()}>
	// 							<span
	// 								className={styles.existing_files_name}
	// 								onClick={() => this.downloadFile(fields.get(index))}
	// 							>
	// 								<Document />
	// 								{fields.get(index).invoiceAttachmentName}
	// 							</span>
	// 							<span
	// 								className={styles.remove_icon_wrapper}
	// 								onClick={() => {
	// 									deletedFiles.push(fields.get(index));
	// 									fields.remove(index);
	// 								}}
	// 							>
	// 								<TrashIcon />
	// 								{
	// 									<FormattedMessage
	// 										id={'ANALYTICS_INVOICES.REMOVE'}
	// 										defaultMessage="Remove"
	// 									/>
	// 								}
	// 							</span>
	// 						</ConditionIf>
	// 						<ConditionIf
	// 							condition={this.isROComponent() && !this.isRWOComponent()}
	// 						>
	// 							<span
	// 								className={styles.existing_files_name}
	// 								onClick={() => this.downloadFile(fields.get(index))}
	// 							>
	// 								<Document />
	// 								{fields.get(index).invoiceAttachmentName}
	// 							</span>
	// 						</ConditionIf>
	// 					</div>
	// 				)}
	// 			</div>
	// 		))}
	// 	</div>
	// );

	renderButtons = () => {
		const {
			invoiceId,
			// fileSuccess,
			isDirty,
			// filePending,
			noteForm
		} = this.props;

		const isNoteFormOnlyWhiteSpaces = noteForm
			? noteForm.trim().length === 0
			: true;
		// const disabled =
		// 	((filePending || !invoiceId || !isDirty) && fileSuccess.length === 0) ||
		// 	isNoteFormOnlyWhiteSpaces;
		const disabled = isNoteFormOnlyWhiteSpaces || !isDirty || !invoiceId;
		return (
			<div data-spec="buttons">
				<Button
					onClick={this.onCancel}
					variant="link"
					type="button"
					label={
						<FormattedMessage
							id={'ANALYTICS_INVOICES.CANCEL'}
							defaultMessage="Cancel"
						/>
					}
				/>
				<ConditionIf condition={this.isRWOComponent()}>
					<Button
						dataSpec="go-to-reports"
						type="submit"
						disabled={disabled}
						variant="primary"
						label={
							<FormattedMessage
								id={'ANALYTICS_INVOICES.SUBMIT'}
								defaultMessage="Submit"
							/>
						}
					/>
				</ConditionIf>
			</div>
		);
	};

	prepareErrors = (errors, messages) =>
		errors.map((error) => messages[error.code] || error.message);

	render() {
		const {
			show,
			messages,
			note,
			errors,
			handleSubmit,
			notifications
		} = this.props;
		return (
			<Modal
				data-spec="analytics-invoices-notes-modal"
				show={show}
				onClose={this.onCancel}
				className={styles.modal}
			>
				<ModalHeader
					data-spec="analytics-invoices-notes-modal-header"
					messages={messages}
					title={
						note ? (
							<FormattedMessage
								id={'ANALYTICS_INVOICES.EDIT_NOTE'}
								defaultMessage="Edit Note"
							/>
						) : (
							<FormattedMessage
								id={'ANALYTICS_INVOICES.ADD_NOTE'}
								defaultMessage="Add Note"
							/>
						)
					}
					notifications={notifications}
					errors={errors ? this.prepareErrors(errors, messages) : []}
				/>
				<form onSubmit={handleSubmit(submitForm)}>
					<ModalBody className={styles.modal_body}>
						{this.renderModalContent()}
					</ModalBody>
					<ModalFooter
						data-spec="analytics-invoices-notes-modal-footer"
						className={styles.modal_footer}
					>
						{this.renderButtons(deletedFiles)}
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { func, array, object, bool, string, objectOf } = PropTypes;

NotesModal.propTypes = {
	onCancel: func,
	closeModal: func,
	modalOpened: func,
	show: bool,
	messages: objectOf(string),
	isDirty: bool,
	errors: array,
	// updateInvoice: func,
	invoiceId: string,
	note: string,
	handleSubmit: func,
	// getInvoiceAttachments: func,
	// dirty: bool,
	getAttachmentsRequest: bool,
	// attachments: array,
	// initialValues: object,
	// resetFiles: func,
	// dispatch: func,
	// fileSuccess: array,
	// initialize: func,
	// filePending: bool,
	// removeError: func,
	notifications: array,
	noteForm: string,
	// invoiceAttachmentDownload: func,
	downloadInvoiceAttachmentRequest: bool,
	user: object
};

const NotesModalForm = reduxForm({
	form: 'analyticsInvoicesNotes',
	enableReinitialize: true
})(NotesModal);

export default injectIntl(NotesModalForm);
