import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getAnalyticsReports = (params) =>
	ApiService.get(
		`/reports/${params.tab}?${qs.stringify(params.params)}`
	);

export const getReportDownloadUrl = (params) =>
	ApiService.get(`/reports/${params.tab}/${params.id}/download`);

export const getReportsFilters = (params) =>
	ApiService.get(
		`/reports/${params.tab}/filters?${qs.stringify(params.params)}`
	);

export const getCustomerReports = (params) =>
	ApiService.get(`reports/${params.tab}?${qs.stringify(params.params)}`);

export const sfgRepost = (report) =>
	ApiService.post('/reports/sfg-repost', report);
