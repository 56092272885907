import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import FieldGroup from '../../../../lib/DigitalComponents/FieldGroup';

import { paginationData } from '../../../../utils/constants';
import {
	EndpointsTableTabs as tableHeader,
	endpointSignature
} from '../../utils/constants';
import Button from '../../../../lib/DigitalComponents/Button';
import {
	ComponentsRender,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';
import EditTableColumns from './Tabs/EditTableColumns';
import EditCustomFieldLabels from './Tabs/EditCustomFieldLabels';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';

import styles from './EditTableModal.scss';

const CustomLabelRWComponent = ComponentsRender(
	'mnc.endpoints.customlabels_rw'
);

class EditTableModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isLabelsButtonDisabled: true,
			submitColumnsButtonDisabled: true,
			activeTab: 'tab1',
			changeTableNameButtonIsDisabled: true,
			tableHeaderWithCustomLabels: [
				...tableHeader[0].value.data,
				...this.getCustomFieldLabelsHeaders(props.customFieldLabels)
			],
			value: this.getTabName(),
			original: this.getTabName()
		};
	}

	componentDidUpdate(prevProps) {
		const { activeTab } = this.props;

		if (prevProps.activeTab !== activeTab) {
			const currentTab = this.getTabName();
			this.setTabName(currentTab);
		}
	}

	closeModal = () => {
		const { onClose } = this.props;

		this.setState({
			activeTab: 'tab1',
			changeTableNameButtonIsDisabled: true,
			submitColumnsButtonDisabled: true,
			isLabelsButtonDisabled: true
		});
		onClose();
	};

	renderButtons = (tab) => {
		const {
			activeTab,
			settings,
			isCustomLabelsUpdating,
			EditCustomFieldLabelsValues
    } = this.props;
		const {
			changeTableNameButtonIsDisabled,
			tableHeaderWithCustomLabels,
			submitColumnsButtonDisabled,
			isLabelsButtonDisabled
		} = this.state;

		if (tab === 'tab1') {
			return (
				<span data-spec="tab1-buttons">
					<Button
						onClick={() => this.deleteTab('deleteTab')}
						variant="link"
						className={styles.clear_all}
						disabled={activeTab === 'tab'}
						label={
							<FormattedMessage
								id="ENDPOINTS.DELETE_TAB"
								defaultMessage="Delete Tab"
							/>
						}
						dataSpec="edit-table-modal-cancel"
					/>
					<Button
						onClick={() => this.submitNewTableName('submitNewTableName')}
						variant="primary"
						disabled={changeTableNameButtonIsDisabled}
						label={
							<FormattedMessage
								id="ENDPOINTS.APPLY_CHANGE"
								defaultMessage="Apply Change"
							/>
						}
						dataSpec="edit-table-modal-apply-button"
					/>
				</span>
			);
		}
		if (tab === 'tab2') {
			const tabValue = settings.find((val) => val.name === activeTab);
      const tabActiveSettings = tabValue ? JSON.parse(tabValue.value).data : [];
			const tabDefaultSettings = [
				tabActiveSettings[0],
				...tableHeaderWithCustomLabels
					.filter((item) => item.checked)
					.map((item, index) => ({
						title: item.title,
						name: item.name,
						value: index + 1
					}))
      ];

			return (
				<span data-spec="tab2-buttons">
					<Button
						onClick={() => this.handleDelete('handleDelete')}
						className={styles.clear_all}
						variant="primary"
						label={
							<FormattedMessage
								id="ENDPOINTS.CLEAR_ALL"
								defaultMessage="Clear All"
							/>
						}
						dataSpec="reset-to-default"
						disabled={isEqual(tabActiveSettings, tabDefaultSettings)}
					/>
					<Button
						onClick={() => this.handleSubmit('handleSubmit')}
						variant="primary"
						label={
							<FormattedMessage
								id="ENDPOINTS.APPLY_CHANGE"
								defaultMessage="Apply Change"
							/>
						}
						dataSpec="apply-change"
						disabled={submitColumnsButtonDisabled}
					/>
				</span>
			);
		}
		if (tab === 'tab3') {
			return (
				<span data-spec="tab3-buttons">
					<CustomLabelRWComponent>
						{isCustomLabelsUpdating ? (
							<Button variant="primary" disabled label="Loading" />
						) : (
							<Button
								onClick={() =>
									this.submitForm(EditCustomFieldLabelsValues.values)}
								disabled={isLabelsButtonDisabled}
								variant="primary"
								label={
									<FormattedMessage
										id="ENDPOINTS.APPLY_CHANGE"
										defaultMessage="Apply Change"
									/>
								}
								data-spec="edit-table-modal-apply-button"
							/>
						)}
					</CustomLabelRWComponent>
				</span>
			);
		}
	};

	deleteTab = () => {
		const {
			settings,
			activeTab,
			deleteUserSettings,
      setSearchParameterInit,
			clearSelectedSims
		} = this.props;
		const tab = settings.find((setting) => setting.name === activeTab);

		deleteUserSettings(tab.id);
		setSearchParameterInit();
		clearSelectedSims();
	};

	submitForm = (data) => {
		const {
			submitEditLabelForm,
			setSearchParameterInit,
			clearSelectedSims
		} = this.props;
		let values = {};

		Object.keys(data).forEach((key) => {
			if (key.startsWith('customLabel') && key !== 'messageId') {
				const number = key.substr('customLabel'.length);

				values = {
					...values,
					[`customLabel${number}`]:
						data[key] !== ''
							? data[key]
							: `Custom Label ${key.replace(/^\D+/g, '')}`,
					[`isCustomField${number}Required`]: data[
						`isCustomField${number}Required`
					]
						? data[`isCustomField${number}Required`]
						: false
				};
			}
		});
		submitEditLabelForm(values, {
			dataLimit: paginationData.dataLimit,
			dataOffset: 0,
			selectedPage: 0,
			dataSort: null
		});
		setSearchParameterInit();
    clearSelectedSims();
    this.closeModal();
	};

	setButtonLabels = (valid) => this.setState({ isLabelsButtonDisabled: valid });

	setActiveTab = (activeTab) => this.setState({ activeTab });

	onSubmitColumns = (newValues) =>
		this.setState({
			newValues
		});

	setTabName = (tabName) =>
    this.setState({ value: tabName, original: tabName });

  getNewValue = (name, tabName, filters) => {
    const { newValues, tableHeaderWithCustomLabels } = this.state;

    return {
			name,
			refTypeId: 1,
			value: JSON.stringify({
				signature: endpointSignature,
				data: [
					{
						tabName,
						value: 0,
						filters: filters || undefined
					},
					...newValues
						.filter((item) =>
							tableHeaderWithCustomLabels.find((val) => val.name === item.name))
						.map((item, index) => ({
							title: tableHeaderWithCustomLabels.find(
								(val) => val.name === item.name
							).title,
							name: item.name,
							value: index + 1
						}))
				]
			})
		};
  }

	handleSubmit = () => {
		const {
			settings,
			activeTab,
			updateUserSettings,
			createUserSettings,
			setSearchParameterInit,
			clearSelectedSims
		} = this.props;
		const tab = settings.find((setting) => setting.name === activeTab);

		if (tab) {
			const tabData = JSON.parse(tab.value);
      const { filters } = tabData.data[0];
      const newValue = this.getNewValue(tab.name, tabData.data[0].tabName, filters);

			updateUserSettings(newValue, tab.id);
		} else {
      const newValue = this.getNewValue(
				tableHeader[0].name,
				tableHeader[0].value.data[0].tabName
			);
      createUserSettings(newValue);
		}

		setSearchParameterInit();
		clearSelectedSims();
		this.closeModal();
	};

	handleDelete = () => {
		const {
			settings,
			activeTab,
			updateUserSettings,
			createUserSettings,
			setSearchParameterInit,
			clearSelectedSims
		} = this.props;
    const tab = settings.find((setting) => setting.name === activeTab);

		if (tab) {
      const tabData = JSON.parse(tab.value);
      const { filters } = tabData.data[0];
			const newValue = {
				name: tab.name,
				refTypeId: 1,
				value: JSON.stringify({
					signature: endpointSignature,
					data: [
						{
							tabName: tabData.data[0].tabName,
              value: 0,
              filters: filters || undefined
						},
						...tableHeader[0].value.data
							.filter((item) => item.checked)
							.map((item, index) => ({
								title: item.title,
								name: item.name,
								value: index + 1
							}))
					]
				})
			};

			updateUserSettings(newValue, tab.id);
		} else {
			createUserSettings({
				name: activeTab,
				refTypeId: 1,
				value: JSON.stringify({
					signature: endpointSignature,
					data: [
						{
							tabName: 'Endpoints',
							value: 0
						},
						...tableHeader[0].value.data
							.filter((item) => item.checked)
							.map((item, index) => ({
								title: item.title,
								name: item.name,
								value: index + 1
							}))
					]
				})
			});
		}

		setSearchParameterInit();
		clearSelectedSims();
		this.closeModal();
	};

	submitColumnsDisabled = (newValues, oldValues) => {
		let disable = true;

		if (newValues.length > 2) {
			newValues.length !== oldValues.length
				? (disable = false)
				: newValues.forEach((value, index) => {
						if (value.name !== oldValues[index].name) {
							disable = false;
						}
				  });
		}

		this.setState({ submitColumnsButtonDisabled: disable });
	};

	onChangeTableName = (check, value) => {
		this.setState({
			changeTableNameButtonIsDisabled: check,
			newTableName: value
		});
	};

	submitNewTableName = () => {
		const {
			settings,
			activeTab,
			updateUserSettings,
			createUserSettings,
			setSearchParameterInit,
			clearSelectedSims
		} = this.props;
		const { newTableName } = this.state;
		const tab = settings.find((setting) => setting.name === activeTab);

		if (tab) {
			const tabData = JSON.parse(tab.value);

			tabData.data[0].tabName = newTableName;
			tabData.signature = endpointSignature;
			updateUserSettings(
				{
					name: tab.name,
					refTypeId: 1,
					value: JSON.stringify(tabData)
				},
				tab.id
      );
      this.setState({ original: newTableName });
		} else {
			createUserSettings({
				name: tableHeader[0].name,
				refTypeId: 1,
				value: JSON.stringify({
					signature: endpointSignature,
					data: [
						{
							tabName: newTableName,
							value: 0
						},
						...tableHeader[0].value.data
							.filter((item) => item.checked)
							.map((item, index) => ({
								name: item.name,
								refTypeId: 1,
								value: index + 1
							}))
					]
				})
      });
      this.setState({ original: newTableName });
		}

		setSearchParameterInit();
		clearSelectedSims();
		this.closeModal();
	};

	getCustomFieldLabelsHeaders = (data) => {
		let headers = [];
		Object.keys(data).forEach((key) => {
			if (key.startsWith('customLabel') && key !== 'messageId') {
				const number = key.substr('customLabel'.length);
				headers = [
					...headers,
					{
						title: data[key],
						name: `customFieldValue${number}`,
						checked: false
					}
				];
			}
		});

		return headers;
	};

	getTabName = () => {
		const { settings, activeTab } = this.props;
    const tab = find(settings, { name: activeTab });

		if (tab) {
			const tabData = JSON.parse(tab.value);

			return tabData.data[0].tabName;
		}

		return tableHeader[0].value.data[0].tabName;
	};

	onChange = (tableName) => {
		this.setState({ value: tableName }, () => {
      const { original, value } = this.state;

			if (value === original || value.trim() === '') {
				this.onChangeTableName(true, value);
			} else {
				this.onChangeTableName(false, value);
			}
		});
	};

	render() {
		const {
			settings,
			activeTab,
			customLabelsColumns,
			user,
			customFieldLabels,
			show
		} = this.props;
    const { activeTab: activeTabState, value } = this.state;

		const tabs = [
			{
				id: 'tab1',
				displayName: (
					<FormattedMessage
						id="ENDPOINTS.TABLE_NAME"
						defaultMessage="Table Name"
					/>
				),
				content: () => (
					<>
						<div className={styles.title}>
							<FormattedMessage
								id="ENDPOINTS.EDIT_TABLE_VIEW_NAME"
								defaultMessage="Edit table view name"
							/>
						</div>
						<div data-spec="table-name">
							<FormattedMessage
								id="ENDPOINTS.PLEASE_ENTER_TABLE_NAME"
								defaultMessage="Please enter table name"
							>
								{(formattedValue) => (
									<FieldGroup
										label={
											<FormattedMessage
												id="ENDPOINTS.TABLE_NAME"
												defaultMessage="Table Name"
											/>
										}
										placeholder={formattedValue}
										value={value}
										onChange={(e) => this.onChange(e.currentTarget.value)}
										maxLength="12"
										dataSpec="table-name"
									/>
								)}
							</FormattedMessage>
						</div>
					</>
				),
			},
			{
				id: 'tab2',
				displayName: (
					<FormattedMessage
						id="ENDPOINTS.EDIT_TABLE"
						defaultMessage="Edit Table"
					/>
				),
				content: () => (
					<EditTableColumns
						columns={settings && settings.length ? settings : tableHeader}
						allColumns={tableHeader}
						hasOriginal={settings}
						onSubmitColumns={this.onSubmitColumns}
						submitColumnsDisabled={this.submitColumnsDisabled}
						customLabelsColumns={customLabelsColumns}
						activeTab={activeTab}
						user={user}
					/>
				),
			},
			{
				id: 'tab3',
				displayName: (
					<FormattedMessage
						id="ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS"
						defaultMessage="Edit Custom Field Labels"
					/>
				),
				content: () => (
					<>
						<div className={styles.title}>
							<FormattedMessage
								id="ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS"
								defaultMessage="Edit Custom Field Labels"
							/>
						</div>
						<div className={styles.customFieldLabels}>
							<EditCustomFieldLabels
								items={customFieldLabels}
								setButtonLabels={this.setButtonLabels}
							/>
						</div>
					</>
				),
				hide: !isUserAllowedToAccess(
					['mnc.endpoints.customlabels_rw', 'mnc.endpoints.customlabels_ro'],
					user
				),
			},
		];

		return (
			<Modal
				data-spec="edit-table-modal"
				className={styles.modal}
				show={show}
				onClose={this.closeModal}
			>
				<ModalHeader
					title={
						<FormattedMessage
							id="ENDPOINTS.EDIT_TABLE_PARAMETERS"
							defaultMessage="Edit Table Parameters"
						/>
					}
				/>
				<ModalBody className={styles.modalBody}>
					<ModalTabs
						tabs={tabs.filter((item) => !item.hide)}
						onTabChange={(tab) => this.setActiveTab(tab.id)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={this.closeModal}
						className="btn btn-link"
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						data-spec="edit-table-modal-cancel-button"
					/>
					{this.renderButtons(activeTabState)}
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, object, array, string } = PropTypes;

EditTableModal.propTypes = {
	submitEditLabelForm: func,
	customFieldLabels: object,
	EditCustomFieldLabelsValues: object,
	isCustomLabelsUpdating: bool,
	settings: array,
	customLabelsColumns: object,
	activeTab: string,
	clearSelectedSims: func,
	user: object,
	show: bool,
	createUserSettings: func,
	deleteUserSettings: func,
	onClose: func,
	updateUserSettings: func,
	setSearchParameterInit: func
};

EditTableModal.defaultProps = {
	submitEditLabelForm: undefined,
	customFieldLabels: {},
	EditCustomFieldLabelsValues: {},
	isCustomLabelsUpdating: false,
	settings: [],
	customLabelsColumns: {},
	activeTab: '',
	clearSelectedSims: undefined,
	user: {},
	show: false,
	createUserSettings: undefined,
	deleteUserSettings: undefined,
	onClose: undefined,
	updateUserSettings: undefined,
	setSearchParameterInit: undefined
};

export default EditTableModal;
