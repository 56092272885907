import { connect } from 'react-redux';
import BulkUploadModal from './BulkUploadModal';

import { clearError } from '../../../Shared/redux/getStatusCode/actions';
import { getBatchFileUploadFail } from '../../../../redux/batchFiles/selectors';
import { clearUploadErrors } from '../../../../redux/batchFiles/actions';

import getNotifications from '../../../Shared/redux/notifications/selectors';
import { getErrors } from '../../../Shared/redux/getStatusCode/selectors';

const mapStateToProps = (state) => ({
	batchUploadFail: getBatchFileUploadFail(state),
	notifications: getNotifications(state),
	errors: getErrors(state)
});
const mapDispatchToProps = (dispatch) => ({
	clearError: () => dispatch(clearError()),
	clearUploadErrors: () => dispatch(clearUploadErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadModal);
