export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Catégories de graphique',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Regrouper données par',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filtres',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Options graphique',
	'ANALYTICS.EDIT_CHART': 'Modifier diagramme',
	'ANALYTICS.CREATE_NEW_CHART': 'Créer nouveau graphique',
	'ANALYTICS.DISCLAIMER': 'Déni de responsabilité : ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': 'Les données des graphiques proviennent de plusieurs systèmes à divers intervalles de temps. Les données des graphiques fourniront un total approximatif. Les données sont représentées en temps UTC-0. Les données finales liées aux services facturés figureront sur la facture pour le cycle de facturation.',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Totaux du nombre de points terminaux',
	'ANALYTICS.NO_USAGE_SIMS': 'Pas d’utilisation de SIM',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Utilisation totale SMS',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Utilisation totale données',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Utilisation totale, voix',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Utilisation moyenne estimée',
	'ANALYTICS.NO_CATEGORIES': 'Aucune catégorie disponible pour ce compte',
	'ANALYTICS.LINE_GRAPH': 'Graphique linéaire',
	'ANALYTICS.PIE_CHART': 'Graphique en secteurs',
	'ANALYTICS.BAR_GRAPH': 'Graphique à barres',
	'ANALYTICS.WORLD_MAP_GRAPH': 'Graphique de carte du monde',
	'ANALYTICS.PLATFORM': 'Plateforme',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Technologie d’accès',
	'ANALYTICS.OPERATOR': 'Opérateur',
	'ANALYTICS.RATEPLAN': 'Forfait tarifaire',
	'ANALYTICS.COUNTRYCODE': 'Pays',
	'ANALYTICS.SIM_STATE': 'État SIM',
	'ANALYTICS.DATE_RANGE': 'PLAGE DATES',
	'ANALYTICS.TODAY': 'Aujourd’hui',
	'ANALYTICS.PREVIOUS_DAY': 'Jour précédent',
	'ANALYTICS.LAST_10_DAYS': 'Derniers 10 jours',
	'ANALYTICS.LAST_30_DAYS': 'Derniers 30 jours',
	'ANALYTICS.CUSTOM_RANGE': 'Plage personnalisée',
	'ANALYTICS.CYCLE': 'CYCLE',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Cycle actuel jusqu’à ce jour',
	'ANALYTICS.HISTORICAL': 'Historique',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'PLATEFORME MACHINE-MACHINE',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'INCRÉMENTER PAR',
	'ANALYTICS.DAY': 'Jour',
	'ANALYTICS.WEEK': 'Semaine',
	'ANALYTICS.MONTH': 'Mois',
	'ANALYTICS.SHOW': 'MONTRER',
	'ANALYTICS.SUM_TOTALS': 'Somme des totaux',
	'ANALYTICS.AVERAGE_TOTALS': 'Moyenne des totaux',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': 'Veuillez sélectionner une catégorie au cours de la première étape',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': 'Aucun filtre disponible pour cette catégorie',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Personnaliser données graphique',
	'ANALYTICS.EXPORT_CHART_DATA': 'Exporter données graphique',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'Télécharger PNG',
	'ANALYTICS.REMOVE_CHART': 'Supprimer graphique',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Ajouter graphique',
	'ANALYTICS.NO_ATTRIBUTES': 'Aucun groupe par attributs disponible pour ce compte',
	'ANALYTICS.MOVING_AVERAGE': 'Moyenne mobile',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Montre la synthèse totale',
};
