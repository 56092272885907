export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': '¿Borrar parámetro de gráfica definida?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': '¿Está seguro de que quiere borrar el parámetro{settingName}?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Gráficos disponibles actuales',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': 'Este gráfico ya existe en sus parámetros. Solo se permite un tipo de gráfico.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'No hay descripción para este gráfico',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Tipo de gráfico',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Exportar datos del cuadro',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Eliminar gráfico',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Parámetros de gráfico',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Agregar gráfico',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'Se agregó con éxito la nueva gráfica',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Hubo un fallo al agregar la nueva gráfica',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'La gráfica se actualizó con éxito',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Hubo un fallo al actualizar la gráfica',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Se borró con éxito la configuración de la gráfica',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Hubo un fallo al configurar la gráfica',
};
