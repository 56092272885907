import { handleActions } from 'redux-actions';

import { Status } from '../../../utils/constants';
import {
	FETCH_USER,
	SET_COMPANY_SERVICE,
	SET_COMPANY_SERVICE_INIT,
	SET_COMPANY_FRIENDLY_NAME,
	SET_COMPANY_FRIENDLY_NAME_INIT
} from '../constants';

export const initialState = {
	data: null,
	status: Status.INIT,
	error: null,
	accountId: '',
	settings: null
};

export default handleActions(
	{
		[`${FETCH_USER}_REQUEST`]: (state) => ({
			...state,
			data: null,
			status: Status.PENDING,
			error: null
		}),
		[SET_COMPANY_SERVICE]: (state, action) => ({
			...state,
			accountId: action.payload
		}),
		[SET_COMPANY_SERVICE_INIT]: (state) => ({
			...state,
			accountId: ''
		}),
		[SET_COMPANY_FRIENDLY_NAME]: (state, action) => ({
			...state,
			companyFriendlyName: action.payload
		}),
		[SET_COMPANY_FRIENDLY_NAME_INIT]: (state) => ({
			...state,
			companyFriendlyName: ''
		}),
		[`${FETCH_USER}_SUCCESS`]: (state, action) => ({
			...state,
			data: action.payload.data.user
				? action.payload.data.user
				: { ...state.data },
			settings: action.payload.data.settings,
			status: Status.DONE,
			error: null
		}),
		[`${FETCH_USER}_FAIL`]: (state, action) => ({
			...state,
			data: null,
			settings: null,
			status: Status.FAIL,
			error: action.payload.error
		}),
		[`${FETCH_USER}_DELETE`]: (state, action) => ({
			...state,
			settings: {
				...state.settings,
				resultList: state.settings.resultList.filter(
					(setting) => setting.id !== action.payload.id
				)
			}
		}),
		[`${FETCH_USER}_CREATE`]: (state, action) => ({
			...state,
			settings: {
				...state.settings,
				resultList: state.settings.resultList.concat([action.payload])
			}
		}),
		[`${FETCH_USER}_EDIT`]: (state, action) => ({
			...state,
			settings: {
				...state.settings,
				resultList: state.settings.resultList.map((setting) =>
					(setting.id === action.payload.id
						? { ...setting, ...action.payload.data }
						: setting))
			}
		}),
		[`${FETCH_USER}_RESET`]: (state, action) => ({
			...state,
			settings: {
				...state.settings,
				resultList: state.settings.resultList.filter(
					(setting) => setting.refTypeId !== action.payload.refTypeId
				)
			}
		})
	},
	initialState
);
