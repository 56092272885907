/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Field, reduxForm, reset } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import { ConditionIf } from '../../../../utils/helperFunctions';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import DSDatePicker from '../../../../lib/DigitalComponents/DatePicker';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';

import AttachmentsTable from '../AttachmentsTable';
import Dropzone from '../../../Shared/components/UploadFile';

import styles from './AttachmentsModal.scss';

import { uploadFileName } from '../../../../utils/validators';
import { formatInvoiceDateForApi } from '../../utils/helpers';

const UploadIcon = ImageSelector(CurrentContext.theme, 'svgs/document.svg');

const submitForm = (
	values,
	_,
	props,
	uploadType,
	setViewScreen,
	closeAttachmentsModal
) => {
	const { fileSuccess, intl } = props;
	const successTitle = intl.formatMessage({
		id: 'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE',
		defaultMessage: 'Upload Succesfull',
	});

	const successMessage = intl.formatMessage({
		id: 'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE',
		defaultMessage: 'Successfully uploaded file.',
	});

	const errorTitle = intl.formatMessage({
		id: 'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE',
		defaultMessage: 'Error uploading',
	});

	const errorMessage = intl.formatMessage({
		id: 'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE',
		defaultMessage: 'There was an error uploading this file to MC.',
	});
	if (uploadType === 'invoice') {
		const date = values.invoiceDate ? values.invoiceDate : new Date();
		const { invoiceNumber, postInvoiceAttachment, m2mAccountId } = props;
		const file = fileSuccess[0];
		const splitedFiles = file.name.split('.');
		const extension = splitedFiles[splitedFiles.length - 1];

		let data = {
			m2mAccountId,
			invoiceId: invoiceNumber,
			fileLocation: file.location,
			invoiceDate: formatInvoiceDateForApi(date),
		};

		const invoiceFileName = values.fileName
			? `${values.fileName}.${extension}`
			: null;

		if (invoiceFileName) {
			data = {
				...data,
				invoiceFileName,
			};
		}

		const notifOptions = {
			id: 'invoice-upload',
			successMessage,
			successTitle,
			errorMessage,
			errorTitle,
		};
		postInvoiceAttachment(file.id, data, notifOptions);
		closeAttachmentsModal();
	} else {
		const { updateInvoiceAttachment, invoiceId } = props;
		const file = fileSuccess[0];
		const splitedFiles = file.name.split('.');
		const extension = splitedFiles[splitedFiles.length - 1];
		let data = {
			fileLocation: file.location,
			invoiceId,
			attachmentDescription: values.fileDescription,
		};

		const attachmentFileName =
			values.fileName && values.fileName.trim().length > 0
				? `${values.fileName}.${extension}`
				: null;
		if (attachmentFileName) {
			data = {
				...data,
				attachmentFileName,
			};
		}

		const notifOptions = {
			id: 'invoice-attachment',
			successMessage,
			successTitle,
			errorMessage,
			errorTitle,
		};
		updateInvoiceAttachment(file.id, data, notifOptions);
	}

	setViewScreen();
};

const afterSubmit = (result, dispatch) => dispatch(reset('invoiceAttachments'));

class AttachmentsModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isView: true,
			isUpload: false,
			isDelete: false,
			attachmentId: 0,
			uploadType: '',
		};
	}

	componentDidMount() {
		const { getInvoiceAttachments, invoiceId } = this.props;
		getInvoiceAttachments(invoiceId);
	}

	componentDidUpdate(prevProps) {
		const {
			patchInvoiceSuccess,
			getInvoiceAttachments,
			invoiceId,
			postInvoiceSuccess,
		} = this.props;
		if (
			(patchInvoiceSuccess !== null &&
				prevProps.patchInvoiceSuccess === null) ||
			(postInvoiceSuccess !== null && prevProps.postInvoiceSuccess === null)
		) {
			getInvoiceAttachments(invoiceId);
		}
	}

	componentWillUnmount() {
		const { resetFiles } = this.props;
		resetFiles();
	}

	isROComponent = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['mnc.invoices_ro'], user);
	};

	isRWOComponent = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['mnc.invoices_rw'], user);
	};

	isUploadRWComponent = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['system.uploadinvoice_rw'], user);
	};

	setViewScreen = () => {
		const { resetFiles, resetForm } = this.props;
		resetForm();
		resetFiles();
		this.setState({
			isView: true,
			isUpload: false,
			isDelete: false,
		});
	};

	isViewScreen = () => {
		const { isView } = this.state;
		return isView;
	};

	setUploadScreen = (type) => {
		const { hideNotification } = this.props;
		hideNotification('invoice-attachment');
		hideNotification('invoice-upload');
		hideNotification('delete-invoice-attachment');
		this.setState({
			isView: false,
			isUpload: true,
			isDelete: false,
			uploadType: type,
		});
	};

	isUploadScreen = () => {
		const { isUpload } = this.state;
		return isUpload;
	};

	setDeleteScreen = (id) => {
		const { hideNotification } = this.props;
		hideNotification('invoice-attachment');
		hideNotification('invoice-upload');
		hideNotification('delete-invoice-attachment');
		this.setState({
			isView: false,
			isUpload: false,
			isDelete: true,
			attachmentId: id,
		});
	};

	isDeleteScreen = () => {
		const { isDelete } = this.state;
		return isDelete;
	};

	closeAttachmentsModal = () => {
		const { anyModalClosed, onCloseAttachmentsModal } = this.props;
		onCloseAttachmentsModal();
		anyModalClosed();
	};

	onDeleteAttachment = (id) => {
		this.setDeleteScreen(id);
	};

	deleteAttachment = () => {
		const { attachmentId } = this.state;
		const { updateInvoice, invoiceId, intl } = this.props;
		const request = {
			fileDeleteRequest: [
				{
					attachmentId,
				},
			],
		};
		const successTitle = intl.formatMessage({
			id: 'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE',
			defaultMessage: 'Attachment Deleted',
		});

		const successMessage = intl.formatMessage({
			id: 'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE',
			defaultMessage: 'File has been removed from MC.',
		});

		const errorTitle = intl.formatMessage({
			id:
				'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE',
			defaultMessage: 'Error Deleting',
		});

		const errorMessage = intl.formatMessage({
			id:
				'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE',
			defaultMessage: 'There was an error removing this file from MC.',
		});
		const notifOptions = {
			id: 'delete-invoice-attachment',
			successMessage,
			successTitle,
			errorMessage,
			errorTitle,
		};
		updateInvoice(invoiceId, request, notifOptions);
		this.setViewScreen();
	};

	downloadAttachment = (attachmentName) => {
		const { attachmentId } = this.state;
		const { invoiceAttachmentDownload, invoiceId } = this.props;
		const fileRequest = {
			service: 'invoice',
			fileName: attachmentName,
			transactionType: 'attachmentInvoice',
			invoiceId,
			attachmentId,
		};
		invoiceAttachmentDownload(fileRequest);
	};

	renderViewBody = () => {
		const {
			invoiceDocumentName,
			invoiceId,
			m2mAccountId,
			attachments,
		} = this.props;
		return (
			<div
				data-spec="attachments-modal-view"
				className={styles.attachments_modal_view}
			>
				<AttachmentsTable
					attachments={attachments}
					onEditAttachment={this.onEditAttachment}
					onDeleteAttachment={this.onDeleteAttachment}
					invoiceDocumentName={invoiceDocumentName}
					invoiceId={invoiceId}
					m2mAccountId={m2mAccountId}
					isRWOComponent={this.isRWOComponent()}
				/>
				<div className={styles.icons}>
					<span>
						<ConditionIf condition={this.isRWOComponent()}>
							<span
								className={styles.clickable}
								onClick={() => this.setUploadScreen('attachment')}
							>
								<UploadIcon />
								<FormattedMessage
									id="INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT"
									defaultMessage="Upload Attachment"
								/>
							</span>
						</ConditionIf>
					</span>

					<ConditionIf condition={this.isUploadRWComponent()}>
						<Button
							type="button"
							variant="outline-primary"
							onClick={() => this.setUploadScreen('invoice')}
							label={
								<FormattedMessage
									id="INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE"
									defaultMessage="Upload Invoice"
								/>
							}
						/>
					</ConditionIf>
				</div>
			</div>
		);
	};

	renderViewButtons = () => (
		<Button
			type="button"
			variant="primary"
			label={
				<FormattedMessage
					id="INVOICES_ATTACHMENTS_MODAL.CLOSE"
					defaultMessage="Close"
				/>
			}
			onClick={this.closeAttachmentsModal}
		/>
	);

	renderEditButtons = () => {
		const { dirty } = this.props;
		return (
			<div data-spec="attachments-modal-edit-buttons">
				<Button
					type="button"
					variant="link"
					onClick={this.setViewScreen}
					label={
						<FormattedMessage
							id="INVOICES_ATTACHMENTS_MODAL.CANCEL"
							defaultMessage="Cancel"
						/>
					}
				/>
				<Button
					type="submit"
					variant="primary"
					label={
						<FormattedMessage
							id="INVOICES_ATTACHMENTS_MODAL.SAVE"
							defaultMessage="Save"
						/>
					}
					disabled={!dirty}
				/>
			</div>
		);
	};

	renderUploadBody = () => {
		const { uploadType } = this.state;
		const transactionType =
			uploadType === 'invoice' ? 'basicInvoice' : 'attachmentInvoice';
		const isUploadInvoice = uploadType === 'invoice';
		const accept = isUploadInvoice
			? ['.pdf']
			: ['.zip', '.gzip', '.pdf', '.txt', '.csv', '.dat', '.xml', '.xlsx'];
		const { messages } = this.props;
		return (
			<div data-spec="attachments-modal-upload" className={styles.upload_body}>
				<Dropzone
					messages={messages}
					service="invoice"
					transactionType={transactionType}
					singleFileUpload
					accept={accept}
					maxSize={104857600}
				/>
				<div
					className={
						isUploadInvoice ? styles.invoice_inputs : styles.attachment_inputs
					}
				>
					<span className={styles.input}>
						<div className={styles.bolded_text}>
							<FormattedMessage
								id="INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL"
								defaultMessage="File Name (If left blank the default file name will be used)"
							/>
						</div>
						<Field
							name="fileName"
							component={Input}
							placeholder={'File Name (255 characters maximum)'}
							validate={[uploadFileName]}
						/>
					</span>
					<span>
						{isUploadInvoice && (
							<span className={styles.input}>
								<div className={styles.bolded_text}>
									<FormattedMessage
										id="INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE"
										defaultMessage="Invoice Date"
									/>
								</div>
								<Field
									name="invoiceDate"
									component={DSDatePicker}
									data-spec="upload-invoice-date"
									canSelectBeforeDate
								/>
							</span>
						)}
					</span>
				</div>

				{!isUploadInvoice && (
					<div className={styles.input}>
						<div className={styles.bolded_text}>
							<FormattedMessage
								id="INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL"
								defaultMessage="File description visible to customer (optional)"
							/>
						</div>
						<FormattedMessage
							id="INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER"
							defaultMessage="This invoice will be active in a few weeks."
						>
							{(formattedValue) => (
								<Field
									name="fileDescription"
									componentClass="textarea"
									component={Input}
									placeholder={formattedValue}
								/>
							)}
						</FormattedMessage>
					</div>
				)}
			</div>
		);
	};

	renderUploadButtons = () => {
		const { filePending, fileSuccess, invalid } = this.props;
		return (
			<div data-spec="upload-buttons">
				<Button
					type="button"
					variant="link"
					onClick={this.setViewScreen}
					label={
						<FormattedMessage
							id="INVOICES_ATTACHMENTS_MODAL.CANCEL"
							defaultMessage="Cancel"
						/>
					}
				/>
				<Button
					type="submit"
					variant="primary"
					label={
						<FormattedMessage
							id="INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE"
							defaultMessage="Upload File"
						/>
					}
					disabled={filePending || fileSuccess.length === 0 || invalid}
				/>
			</div>
		);
	};

	renderDeleteBody = () => {
		const { attachmentId } = this.state;
		const { attachments } = this.props;
		const attachment = attachments.find(
			(x) => x.inovoiceAttachmentId === attachmentId
		);
		return (
			<div className={styles.delete_body} data-spec="attachments-modal-body">
				<FormattedMessage
					id="INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION"
					defaultMessage="You are about to remove an attachment from MC, this will permanently
        remove this file from the system, are you sure you want to perform
        this task?"
				/>
				<div className={styles.bolded_text}>
					<FormattedMessage
						id="INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE"
						defaultMessage="File to Delete:"
					/>
				</div>
				<span
					className={styles.clickable}
					onClick={() =>
						this.downloadAttachment(attachment.invoiceAttachmentName)
					}
				>
					{attachment.invoiceAttachmentName}
				</span>
			</div>
		);
	};

	renderDeleteButtons = () => (
		<div>
			<Button
				type="button"
				variant="link"
				onClick={this.setViewScreen}
				label={
					<FormattedMessage
						id="INVOICES_ATTACHMENTS_MODAL.CANCEL"
						defaultMessage="Cancel"
					/>
				}
			/>
			<Button
				type="button"
				variant="primary"
				label={
					<FormattedMessage
						id="INVOICES_ATTACHMENTS_MODAL.DELETE"
						defaultMessage="Delete"
					/>
				}
				onClick={() => this.deleteAttachment()}
			/>
		</div>
	);

	getModalTitle = () => {
		const { invoiceId } = this.props;
		if (this.isViewScreen()) {
			return (
				<span data-spec="modal-title">
					<FormattedMessage
						id="INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE"
						defaultMessage="Attachments for invoice"
					/>
					{` #${invoiceId}`}
				</span>
			);
		}
		if (this.isUploadScreen()) {
			return (
				<FormattedMessage
					data-spec="modal-title"
					id="INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE"
					defaultMessage="Upload a File"
				/>
			);
		}
		return (
			<FormattedMessage
				data-spec="modal-title"
				id="INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE"
				defaultMessage="Delete Attachment"
			/>
		);
	};

	prepareErrors = (errors, messages) =>
		errors.map((error) => messages[error.code] || error.message);

	render() {
		const {
			errors,
			notifications,
			messages,
			handleSubmit,
			invoiceDownloadRequest,
			getAttachmentsRequest,
			downloadInvoiceAttachmentRequest,
			patchInvoiceRequest,
			postInvoiceRequest,
		} = this.props;

		const { isView, isUpload, isDelete, uploadType } = this.state;
		const isAnyRequest =
			invoiceDownloadRequest ||
			getAttachmentsRequest ||
			downloadInvoiceAttachmentRequest ||
			patchInvoiceRequest ||
			postInvoiceRequest;
		const bodyClassName = cn({
			[styles.delete_modal_body]: isDelete,
			[styles.upload_invoice_modal_body]: isUpload && uploadType === 'invoice',
			[styles.upload_attachments_modal_body]:
				isUpload && uploadType !== 'invoice',
		});

		return (
			<div data-spec="invoice-attachments-modal">
				<Modal show onClose={this.closeAttachmentsModal}>
					<ModalHeader
						title={this.getModalTitle()}
						notifications={notifications}
						errors={errors ? this.prepareErrors(errors, messages) : []}
						messages={messages}
					/>
					<form
						onSubmit={handleSubmit((values, _, props) =>
							submitForm(
								values,
								_,
								props,
								uploadType,
								this.setViewScreen,
								this.closeAttachmentsModal
							)
						)}
					>
						<ModalBody className={bodyClassName}>
							{isAnyRequest && <Loader data-spec="loader" />}
							{!isAnyRequest && (
								<div className={styles.modal_body}>
									{isView && this.renderViewBody()}
									{isUpload && this.renderUploadBody()}
									{isDelete && this.renderDeleteBody()}
								</div>
							)}
						</ModalBody>
						<ModalFooter>
							<div>
								{isView && this.renderViewButtons()}
								{isUpload && this.renderUploadButtons()}
								{isDelete && this.renderDeleteButtons()}
							</div>
						</ModalFooter>
					</form>
				</Modal>
			</div>
		);
	}
}

const { string, func, array, object, bool, any } = PropTypes;

AttachmentsModal.propTypes = {
	invoiceId: string,
	onCloseAttachmentsModal: func,
	anyModalClosed: func,
	notifications: array,
	errors: array,
	messages: object,
	handleSubmit: func,
	dirty: bool,
	invoiceDocumentName: string,
	m2mAccountId: string,
	invoiceDownloadRequest: bool,
	getAttachmentsRequest: bool,
	attachments: array,
	getInvoiceAttachments: func,
	updateInvoice: func,
	downloadInvoiceAttachmentRequest: bool,
	patchInvoiceRequest: bool,
	filePending: bool,
	fileSuccess: array,
	resetFiles: func,
	postInvoiceRequest: bool,
	resetForm: func,
	invoiceAttachmentDownload: func,
	user: object,
	invalid: bool,
	intl: object,
	patchInvoiceSuccess: string,
	postInvoiceSuccess: any,
	hideNotification: func,
};

const AttachmentsModalForm = reduxForm({
	form: 'invoiceAttachments',
	enableReinitialize: true,
	onSubmitSuccess: afterSubmit,
})(AttachmentsModal);

export default injectIntl(AttachmentsModalForm);
