export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Categorías de gráfico',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Agrupar datos por',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filtros',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Opciones de gráfico',
	'ANALYTICS.EDIT_CHART': 'Editar cuadro',
	'ANALYTICS.CREATE_NEW_CHART': 'Crear gráfico nuevo',
	'ANALYTICS.DISCLAIMER': 'Aviso legal: ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': 'La elaboración de cuadros de datos es provista por múltiples sistemas en varios intervalos de tiempo. Los datos en los gráficos ofrecerán una suma total aproximada. Los datos se representan en hora UTC-0. Los datos finales relacionados con los servicios facturados estarán disponibles en la factura del ciclo de facturación.',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Recuento total de terminales',
	'ANALYTICS.NO_USAGE_SIMS': 'SIM no usadas',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Uso total de SMS',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Uso total de datos',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Uso total de voz',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Uso promedio estimado',
	'ANALYTICS.NO_CATEGORIES': 'No hay categorías disponibles para esta cuenta',
	'ANALYTICS.LINE_GRAPH': 'Gráfico lineal',
	'ANALYTICS.PIE_CHART': 'Gráfico circular',
	'ANALYTICS.BAR_GRAPH': 'Gráfico de barra',
	'ANALYTICS.WORLD_MAP_GRAPH': 'Gráfico de mapa global',
	'ANALYTICS.PLATFORM': 'Plataforma',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Tecnología de acceso',
	'ANALYTICS.OPERATOR': 'Operador',
	'ANALYTICS.RATEPLAN': 'Plan de tarifas',
	'ANALYTICS.COUNTRYCODE': 'País',
	'ANALYTICS.SIM_STATE': 'Estado de SIM',
	'ANALYTICS.DATE_RANGE': 'RANGO DE FECHA',
	'ANALYTICS.TODAY': 'Hoy',
	'ANALYTICS.PREVIOUS_DAY': 'Día anterior',
	'ANALYTICS.LAST_10_DAYS': 'Últimos 10 días',
	'ANALYTICS.LAST_30_DAYS': 'Últimos 30 días',
	'ANALYTICS.CUSTOM_RANGE': 'Rango personalizado',
	'ANALYTICS.CYCLE': 'CICLO',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Ciclo actual hasta la fecha',
	'ANALYTICS.HISTORICAL': 'Histórico',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'PLATAFORMA M2M',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'INCREMENTAR POR',
	'ANALYTICS.DAY': 'Día',
	'ANALYTICS.WEEK': 'Semana',
	'ANALYTICS.MONTH': 'Mes',
	'ANALYTICS.SHOW': 'MOSTRAR',
	'ANALYTICS.SUM_TOTALS': 'Sumas globales',
	'ANALYTICS.AVERAGE_TOTALS': 'Promedios totales',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': 'Seleccione una categoría en el primer paso',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': 'No hay filtros disponibles para esta categoría',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Personalizar datos de gráfico',
	'ANALYTICS.EXPORT_CHART_DATA': 'Exportar datos de gráfico',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'Descargar PNG',
	'ANALYTICS.REMOVE_CHART': 'Eliminar gráfico',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Agregar gráfico',
	'ANALYTICS.NO_ATTRIBUTES': 'No hay atributos de agrupación disponibles para esta cuenta',
	'ANALYTICS.MOVING_AVERAGE': 'Promedio variable',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Mostrar totales resumidos',
};
