import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery
} from '../../../../utils/CustomMediaQuery';
import { Desktop } from '../../components/Actions';

const ActionsView = (props) => (
	<>
		<DesktopMediaQuery>
			<Desktop {...props} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Desktop {...props} />
		</TabletMediaQuery>
	</>
);
export default ActionsView;
