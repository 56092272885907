import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';
import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';
import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';
import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import UserGroupsDesktop from './Desktop';
import UserGroupsMobile from './Mobile';

import {
	openAddUserGroupModal,
	closeAddUserGroupModal,
	getUserGroups,
	suspendUserGroup,
	reactivateUserGroup
} from '../../redux/userGroups/actions';

import {
	isAddUserGroupModalOpened,
	getUserGroupsFail,
	getUserGroupsSuccess,
	getUserGroupsRequest,
	addUserGroupRequest,
	addUserGroupFail,
	addUserGroupSuccess,
	suspendUserGroupRequest,
	suspendUserGroupFail,
	suspendUserGroupSuccess,
	reactivateUserGroupRequest,
	reactivateUserGroupFail,
	reactivateUserGroupSuccess
} from '../../redux/userGroups/selectors';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import {
	setPaginationInit,
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

const mapStateToProps = (state) => ({
	filterModalOpened: isFilterModalOpen(state),
	isAddUserGroupModalOpened: isAddUserGroupModalOpened(state),
	userGroupsRequest: getUserGroupsRequest(state),
	userGroupsFail: getUserGroupsFail(state),
	userGroups: getUserGroupsSuccess(state),
	addUserGroupRequest: addUserGroupRequest(state),
	addUserGroupFail: addUserGroupFail(state),
	addUserGroupSuccess: addUserGroupSuccess(state),
	suspendUserGroupRequest: suspendUserGroupRequest(state),
	suspendUserGroupFail: suspendUserGroupFail(state),
	suspendUserGroupSuccess: suspendUserGroupSuccess(state),
	reactivateUserGroupRequest: reactivateUserGroupRequest(state),
	reactivateUserGroupFail: reactivateUserGroupFail(state),
	reactivateUserGroupSuccess: reactivateUserGroupSuccess(state),
	filterData: filterDataSuccess(state),
	user: getUser(state),
	searchParameter: getSearchParameter(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
});

const mapDispatchToProps = (dispatch) => ({
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	openAddUserGroupModal: () => dispatch(openAddUserGroupModal()),
	closeAddUserGroupModal: () => dispatch(closeAddUserGroupModal()),
	push: (url) => dispatch(localizedRoute(url)),
	getUserGroups: (params) => dispatch(getUserGroups(params)),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	suspendUserGroup: (params) => dispatch(suspendUserGroup(params)),
	reactivateUserGroup: (params) => dispatch(reactivateUserGroup(params)),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserGroupsDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserGroupsMobile);
