import {
	userInStandardMode,
	userIsAuthenticated
} from '../../../utils/AuthSelector';
import AnalyticsView from '../views/AnalyticsView';

export default [
	{
		path: '/analytics',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.analytics_rw',
				'mnc.analytics_ro'
			])(AnalyticsView)
		)
	}
];
