import { Status } from '../../../../utils/constants';

export const isFilterModalOpen = (state) =>
	state.shared.filterModal.isOpen.isOpen;

export const getFiltersRequest = (state) =>
	state.shared.filterModal.getFilters.status === Status.PENDING;
export const getFiltersFail = (state) =>
	state.shared.filterModal.getFilters.status === Status.FAIL;
export const getFiltersSuccess = (state) =>
	state.shared.filterModal.getFilters.data;
