import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DSSteps from 'lib/DigitalComponents/Steps';
import Button from '../../../../lib/DigitalComponents/Button';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';

import { clearUploadErrors } from '../../../../redux/batchFiles/actions';
import { getActionError as getUploadBulkActionError } from '../../redux/updateBulkRatePlan/selectors';
import { updateBulkm2mAccountErrorClear } from '../../redux/updateBulkm2mAccount/actions';
import { updateBulkRatePlanErrorClear } from '../../redux/updateBulkRatePlan/actions';

// change sim state steps
import ChangeStepFirst from './ChangeSimStateSteps/StepFirst';
import ChangeStepSecond from './ChangeSimStateSteps/StepSecond';
import ChangeStepThird from './ChangeSimStateSteps/StepThird';

// rate plan steps
import RatePlanStepFirst from './ChangeRatePlanSteps/StepFirst';
import RatePlanStepSecond from './ChangeRatePlanSteps/StepSecond';
import RatePlanStepThird from './ChangeRatePlanSteps/StepThird';

// network entitlement steps
import NetworkEntitlementStepFirst from './ChangeNetworkEntitlementSteps/StepFirst';
import NetworkEntitlementStepSecond from './ChangeNetworkEntitlementSteps/StepSecond';
import NetworkEntitlementStepThird from './ChangeNetworkEntitlementSteps/StepThird';

// recredential steps
// import RecredentialStepFirst from './RecredentialSteps/StepFirst';
// import RecredentialStepSecond from './RecredentialSteps/StepSecond';
// import RecredentialStepThird from './RecredentialSteps/StepThird';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import styles from './MultiEndpointAction.scss';

// note steps
import NoteFirstStep from './AddNoteSteps/StepFirst';
import NoteSecondStep from './AddNoteSteps/StepSecond';

import {
	MultiEndpointActionContextProvider,
	MultiEndpointActionContextConsumer,
	MultiEndpointActionContext
} from './MultiEndpointActionContext';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

const { bool, func, array } = PropTypes;

const customProps = {
	onHide: func,
	clearError: func,
	show: bool,
	selectedSims: array,
	actionError: bool,
	updateBulkm2mAccountClearError: func,
	updateBulkRatePlanClearError: func,
	fetchAllEndpoints: func,
	user: PropTypes.object
};

class MultiEndpointActionModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			schedule: {},
			ratePlan: {},
			networkEntitlement: {},
			// recredential: {},
			notes: {}
		};
	}

	modalHide = () => {
		const {
			onHide,
			clearError,
			updateBulkm2mAccountClearError,
			updateBulkRatePlanClearError,
			fetchAllEndpoints
		} = this.props;
		const { schedule } = this.state;
		onHide();
		clearError();
		updateBulkm2mAccountClearError();
		updateBulkRatePlanClearError();
		this.setState({
			schedule: {
				...schedule,
				scheduledDate: ''
			},
      ratePlan: {},
      networkEntitlement: {},
			notes: {}
		});
		fetchAllEndpoints();
	};

	mergeValuesSims = (values) => {
    const { schedule } = this.state;
		this.setState({
			schedule: {
				...schedule,
				...values
			}
		});
	};

	mergeValuesSteps = (values) => {
		const { ratePlan } = this.state;
		this.setState({
			ratePlan: {
				...ratePlan,
				...values
			}
		});
	};

	mergeEntitlementSteps = (values) => {
    const { networkEntitlement } = this.state;
		this.setState({
			networkEntitlement: {
				...networkEntitlement,
				...values
			}
		});
	};

	// mergeRecredentialSteps = (values) => {
	// 	const { recredential } = this.state;
	// 	this.setState({
	// 		recredential: {
	// 			...recredential,
	// 			...values
	// 		}
	// 	});
	// };

	mergeNoteSteps = (values) => {
		const { notes } = this.state;
		this.setState({
			notes: {
				...notes,
				...values
			}
		});
	};

	_customActions = ({ isComplete, isInFinalStep }) => {
		const { onHide } = this.props;
		return (
			<ModalFooter data-spec="ds-modal-footer">
				<Button
					onClick={onHide}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					onClick={onHide}
					disabled={isComplete}
					variant="primary"
					label={
						isInFinalStep ? (
							<FormattedMessage id="DONE" defaultMessage="Done" />
						) : (
							<FormattedMessage id="CONTINUE" defaultMessage="Continue" />
						)
					}
					dataSpec="continue-button"
				/>
			</ModalFooter>
		);
	};

	onFinish = () => {
		this.modalHide();
	};

	render() {
		const {
			selectedSims,
			actionError,
			// recredentialActionError,
			user,
			show,
			onHide
		} = this.props;
		const { ratePlan, networkEntitlement } = this.state;

		// COMMENTING THIS CODE TO REMOVE RECREDENTIAL TAB FROM TABS
		// const { recredential, ratePlan } = this.state;
		// const recredentialSteps = [
		// 	{
		// 		label: `1. ${messages.SELECT_OPERATOR ||
		// 			'Select operator'}`,
		// 		component: (
		// 			<MultiEndpointActionContextConsumer>
		// 				<RecredentialStepFirst
		// 					selectedSims={selectedSims}
		// 					onClose={this.modalHide}
		// 					messages={messages}
		// 				/>
		// 			</MultiEndpointActionContextConsumer>
		// 		)
		// 	},
		// 	{
		// 		label: `2. ${messages.CONFIRM_CHANGES}`,
		// 		component: (
		// 			<MultiEndpointActionContextConsumer>
		// 				<RecredentialStepSecond
		// 					selectedSims={selectedSims}
		// 					onClose={this.modalHide}
		// 					messages={messages}
		// 					recredential={recredential}
		// 				/>
		// 			</MultiEndpointActionContextConsumer>
		// 		)
		// 	},
		// 	{
		// 		label: `3. ${messages.SYSTEM_CONFIRMATION}`,
		// 		component: (
		// 			<MultiEndpointActionContextConsumer>
		// 				<RecredentialStepThird
		// 					selectedSims={selectedSims}
		// 					onClose={this.modalHide}
		// 					messages={messages}
		// 					recredential={recredential}
		// 				/>
		// 			</MultiEndpointActionContextConsumer>
		// 		)
		// 	}
		// ];

		const changeSimStateSteps = [
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST"
						defaultMessage="1. Select SIM State"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<ChangeStepFirst
							selectedSims={selectedSims}
							onClose={this.modalHide}
						/>
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND"
						defaultMessage="2. Confirm changes"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<ChangeStepSecond
							onClose={this.modalHide}
							values={this.state}
						/>
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP"
						defaultMessage="3. System confirmation"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<ChangeStepThird
							onClose={this.modalHide}
							values={this.state}
						/>
					</MultiEndpointActionContextConsumer>
				)
			}
		];

		const changeRatePlanSteps = [
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST"
						defaultMessage="1. Select Rate Plan"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<RatePlanStepFirst
							selectedSims={selectedSims}
							onClose={this.modalHide}
						/>
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND"
						defaultMessage="2. Confirm changes"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<RatePlanStepSecond onClose={this.modalHide} ratePlans={ratePlan} />
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP"
						defaultMessage="3. System confirmation"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<RatePlanStepThird onClose={this.modalHide} ratePlans={ratePlan} />
					</MultiEndpointActionContextConsumer>
				)
			}
		];

		const changeNetworkEntitlementSteps = [
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST"
						defaultMessage="1. Select Network Entitlement"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<NetworkEntitlementStepFirst
							selectedSims={selectedSims}
							onClose={this.modalHide}
						/>
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND"
						defaultMessage="2. Confirm changes"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<NetworkEntitlementStepSecond
							onClose={this.modalHide}
							networkEntitlement={networkEntitlement}
						/>
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP"
						defaultMessage="3. System confirmation"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<NetworkEntitlementStepThird
							onClose={this.modalHide}
							networkEntitlement={networkEntitlement}
						/>
					</MultiEndpointActionContextConsumer>
				)
			}
		];

		const changeNoteSteps = [
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.CHANGE_NOTES_STEP_FIRST"
						defaultMessage="1. Change Notes"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<NoteFirstStep
							selectedSims={selectedSims}
							onClose={this.modalHide}
						/>
					</MultiEndpointActionContextConsumer>
				)
			},
			{
				label: (
					<FormattedMessage
						id="ENDPOINTS.CONFIRM_STEP_SECOND"
						defaultMessage="2. System confirmation"
					/>
				),
				component: (
					<MultiEndpointActionContextConsumer>
						<NoteSecondStep onClose={this.modalHide} values={this.state} />
					</MultiEndpointActionContextConsumer>
				)
			}
		];

		const tabs = [
			{
				services: ['mnc.endpoints.simstatus_rw', 'mnc.endpoints.simstatus_ro'],
				tab: {
					id: 'changeSimStateSteps',
					displayName: (
						<FormattedMessage
							id="ENDPOINTS.CHANGE_SIM_STATE"
							defaultMessage="Change SIM State"
						/>
					),
					tabContentClassName: styles.modal_tab_content,
					content: () => (
						<div key="changeSimStateSteps">
							<DSSteps
								isActionError={actionError}
								steps={changeSimStateSteps}
								onNext={this.mergeValuesSims}
								noActions
								onBack={this.mergeValuesSims}
								onFinish={this.onFinish}
							/>
						</div>
					)
				}
			},
			{
				services: ['mnc.endpoints.rateplans_rw', 'mnc.endpoints.rateplans_ro'],
				tab: {
					id: 'changeRatePlanSteps',
					displayName: (
						<FormattedMessage
							id="ENDPOINTS.CHANGE_RATE_PLAN"
							defaultMessage="Change Rate Plan"
						/>
					),
					tabContentClassName: styles.modal_tab_content,
					content: () => (
						<div key="changeRatePlanSteps">
							<DSSteps
								steps={changeRatePlanSteps}
								onNext={this.mergeValuesSteps}
								noActions
								onBack={this.mergeValuesSteps}
								onFinish={this.onFinish}
							/>
						</div>
					)
				}
			},
			{
				services: [
					'mnc.endpoints.networkentitlements_rw',
					'mnc.endpoints.networkentitlements_ro'
				],
				tab: {
					id: 'changenetworkEntitlement',
					displayName: (
						<FormattedMessage
							id="ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT"
							defaultMessage="Change Network Entitlement"
						/>
					),
					tabContentClassName: styles.modal_tab_content,
					content: () => (
						<div key="changeNetworkEntitlementSteps">
							<DSSteps
								steps={changeNetworkEntitlementSteps}
								onNext={this.mergeEntitlementSteps}
								noActions
								onBack={this.mergeEntitlementSteps}
								onFinish={this.onFinish}
							/>
						</div>
					)
				}
			},
			// {
			// 	tab: {
			// 		id: 'recredentialSteps',
			// 		displayName: messages.RE_CREDENTIAL_SIM,
			// 		tabContentClassName: styles.modal_tab_content,
			// 		content: () => (
			// 			<div key="recredentialSteps">
			// 				<DSSteps
			// 					isActionError={recredentialActionError}
			// 					steps={recredentialSteps}
			// 					onNext={this.mergeRecredentialSteps}
			// 					noActions
			// 					onBack={this.mergeRecredentialSteps}
			// 					onFinish={this.onFinish}
			// 					messages={messages}
			// 				/>
			// 			</div>
			// 		)
			// 	}
			// },
			{
				services: ['system.senotes_rw'],
				tab: {
					id: 'noteSteps',
					displayName: (
						<FormattedMessage
							id="ENDPOINTS.CHANGE_NOTES"
							defaultMessage="Notes"
						/>
					),
					tabContentClassName: styles.modal_tab_content,
					content: () => (
						<div key="noteSteps">
							<DSSteps
								steps={changeNoteSteps}
								onNext={this.mergeNoteSteps}
								noActions
								onBack={this.mergeNoteSteps}
								onFinish={this.onFinish}
							/>
						</div>
					)
				}
			}
		];

		const renderTab = tabs
			.filter(
				(item) => !item.services || isUserAllowedToAccess(item.services, user)
			)
			.map((i) => i.tab);

		return (
			<div data-spec="batch modal">
				<MultiEndpointActionContextProvider>
					<Modal show={show} onClose={onHide} className={styles.modal}>
						<ModalHeader
							title={
								<FormattedMessage
									id="ENDPOINTS.MULTI_ENDPOINT_ACTIONS"
									defaultMessage="Multi-endpoint actions"
								/>
							}
						/>
						<ModalBody className={styles.modal_body_notf}>
							<ModalTabs tabs={renderTab} />
						</ModalBody>
						<ModalFooter>
							<MultiEndpointActionContext.Consumer>
								{(context) => context.state.footer}
							</MultiEndpointActionContext.Consumer>
						</ModalFooter>
					</Modal>
				</MultiEndpointActionContextProvider>
			</div>
		);
	}
}

MultiEndpointActionModal.propTypes = customProps;

const mapDispatchToProps = (dispatch) => ({
	clearUploadErrors: () => dispatch(clearUploadErrors()),
	updateBulkm2mAccountClearError: () =>
		dispatch(updateBulkm2mAccountErrorClear()),
	updateBulkRatePlanClearError: () => dispatch(updateBulkRatePlanErrorClear())
});

const mapStateToProps = (state) => ({
	// recredentialActionError: getActionError(state),
	getUploadBulkActionError: getUploadBulkActionError(state)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MultiEndpointActionModal);
