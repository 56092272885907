export default {
	'TICKETING.YES': 'Oui',
	'TICKETING.NO': 'Non',
	'TICKETING.VIEW_NOTE': 'Afficher note',
	'TICKETING.TICKET_ID': 'ID billet',
	'TICKETING.CATEGORY_NAME': 'Catégorie',
	'TICKETING.DATE_CREATED': 'Date création',
	'TICKETING.CREATED_BY': 'Créé par',
	'TICKETING.TICKETING_STATUS': 'État établissement de billets',
	'TICKETING.LAST_UPDATE': 'Dernière mise à jour',
	'TICKETING.SEVERITY': 'Gravité',
	'TICKETING.SUBMISSION_LOCATION': 'Endroit de l’envoi',
	'TICKETING.UPDATE_NOTE': 'Note de mise à jour',
	'TICKETING.STATUS': 'État',
	'TICKETING.SUBMITED_BY': 'Envoyé par',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': 'Votre billet se rapporte-t-il à un point terminal particulier ? Si c’est le cas, allez à l’écran des détails de ce point terminal et cliquez sur Obtenir assistance pour la liaison de ce point terminal. Sinon, remplissez le formulaire ci-dessous.',
	'TICKETING.ISSUE': 'Problème',
	'TICKETING.TICKET_DETAILS': 'Détails du billet',
	'TICKETING.CATEGORY': 'Catégorie',
	'TICKETING.SELECT_CATEGORY': 'Sélectionner catégorie',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Sous-catégorie (facultatif)',
	'TICKETING.SUBCATEGORY': 'Sous-catégorie',
	'TICKETING.ROAMING_ISSUE': 'Problème d’itinérance',
	'TICKETING.PRIORITY': 'Priorité',
	'TICKETING.ENDPOINT': 'Point terminal',
	'TICKETING.ICCID': 'Identifiant de carte SIM',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'Nº réseau numérique à intégration de services d’abonné mobile',
	'TICKETING.ENDPOINT_LOCATION': 'Localisation du point terminal',
	'TICKETING.ENDPOINT_COUNTRY': 'Pays point terminal',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Effacer paramètres point terminal',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Extraire paramètres point terminal',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': 'Vous pouvez utiliser cette option pour extraire les paramètres de point terminal en entrant l’identifiant de carte SIM, la valeur IMSI ou le no de réseau numérique à intégration de services d’abonné mobile. ',
	'TICKETING.ENDPOINT_STREET': 'Rue point terminal',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Adresse point terminal',
	'TICKETING.ZIP_CODE': 'Code postal',
	'TICKETING.ENDPOINT_STATE': 'État point terminal',
	'TICKETING.DETAILS': 'Détails',
	'TICKETING.BARS_SHOWN': 'Barres affichées',
	'TICKETING.ENDPOINT_CITY': 'Ville point terminal',
	'TICKETING.ENDPOINT_MODEL': 'Modèle point terminal',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': 'Dépannage exécuté ?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Description du problème',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': 'Fournissez une courte description du problème de 255 caractères max.',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': 'Au besoin fournissez plus de détails, 2 000 caractères maximum',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Notes',
	'TICKETING.ISSUE_DETAILS': 'Détails problème',
	'TICKETING.CUSTOMER': 'Client',
	'TICKETING.ACCOUNT_NUMBER': 'Numéro de compte',
	'TICKETING.ACCOUNT_NAME': 'Nom de compte',
	'TICKETING.PHONE': 'Téléphone',
	'TICKETING.CONTACT_PHONE': 'Téléphone contact',
	'TICKETING.CONTACT_EMAIL': 'E-mail contact',
	'TICKETING.ATTACH_FILE': 'Joindre fichier',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': 'Nom du fichier trop long pour le téléversement. La longueur max. du nom de fichier est de 25 caractères.',
	'TICKETING.EMAIL': 'E-mail',
	'TICKETING.TICKETING': 'Établissement de billets',
	'TICKETING.ADD_TICKET': 'Ajouter billet',
	'TICKETING.SEARCH_RESULTS': 'Résultats de la recherche',
	'TICKETING.NO_TICKETS_AVAILABLE': 'Aucun billet disponible',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': 'Changement des paramètres de la table des billets réussi !',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': 'Mise à jour des paramètres de la table des billets réussie !',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': 'Erreur dans changement des colonnes de la table des billets !',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': 'Impossible de mettre à jour les colonnes de la table des billets. Veuillez réessayer.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'Modification du billet réussie.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': 'Erreur pendant la modification du billet. Veuillez réessayer ultérieurement.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'Le nom du fichier ne doit pas contenir d’espaces.',
	'TICKETING.NEW_TICKET': 'Nouveau billet',
	'TICKETING.OFF': 'Désactivé',
	'TICKETING.ON': 'Activé',
	'TICKETING.EMAIL_NOTIFICATIONS': 'Notifications e-mail',
	'TICKETING.CONTACT_NAME': 'Nom du contact',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Dernière mise à jour par',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Date/heure dernière mise à jour',
	'TICKETING.SOMETHING_WENT_WRONG': 'Un problème est survenu, veuillez réessayer.',
	'TICKETING.CANCEL_TICKET': 'Annuler billet',
	'TICKETING.ADD_NOTE': 'Ajouter note',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'Envoi du billet réussi !',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': 'Échec de l’envoi du billet !',
	'TICKETING.CREATED_AT': 'Créé à',
	'TICKETING.GO_BACK_TO_TICKETING': 'Retour à l’établissement de billets',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': 'Problème de facturation non technique',
	'TICKETING.CATEGORY_PROVISIONING': 'Provisionnement',
	'TICKETING.CATEGORY_DATA': 'Données',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Documentation',
	'TICKETING.CATEGORY_DUPLICATE': 'Reproduire',
	'TICKETING.CATEGORY_BILLING': 'Facturation',
	'TICKETING.CATEGORY_NOTIFICATION': 'Notification',
	'TICKETING.CATEGORY_OTHER': 'Autre',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': 'Demande d’amélioration du produit',
	'TICKETING.CATEGORY_PROJECT': 'Projet',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Problème Ventes/Marketing',
	'TICKETING.CATEGORY_QUESTION': 'Question',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Demande de service',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Problèmes techniques',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': 'Administration utilisateur/compte',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Problème de connectivité',
	'TICKETING.CATEGORY_EQUIPMENT': 'Équipement',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'Question générale',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Appareil perdu/volé',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Demande de port',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'En lien avec le produit',
	'TICKETING.CATEGORY_REPORTING': 'Établissement de rapport',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Suggestion/Recommandation',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Problème de site Web',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': 'En file d’attente',
	'TICKETING.STATUS_ACTIVE': 'Actif',
	'TICKETING.STATUS_DEFERRED': 'Différé',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'Réponse client en attente',
	'TICKETING.STATUS_CLOSED': 'Fermé',
	'TICKETING.STATUS_CANCEL': 'Annuler',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Contesté par le client',
	'TICKETING.STATUS_CLEARED': 'Résolu',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Prêt à fermer',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'Réponse ATT en attente',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': 'Critique',
	'TICKETING.SEVERITY_MAJOR': 'Majeur',
	'TICKETING.SEVERITY_MINOR': 'Mineur',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': 'Frais d’activation',
	'TICKETING.AIRTIME_USAGE': 'Frais utilisation réseau',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Questions de base sur le compte',
	'TICKETING.BILLING_DISPUTES': 'Contestations facturation',
	'TICKETING.BILLING_FILES_ISSUES': 'Problèmes de fichier de facturation',
	'TICKETING.BILL_RECONCILIATION': 'Rapprochement des factures',
	'TICKETING.CUSTOMER_EDUCATION': 'Formation du client',
	'TICKETING.DATA_USAGE_CANADA': 'Utilisation données au Canada',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Utilisation données à l’international',
	'TICKETING.DATA_USAGE_OFF_NET': 'Utilisation données hors réseau',
	'TICKETING.DATA_USAGE_ON_NET': 'Utilisation données sur réseau',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Facturation ou facture différée',
	'TICKETING.DISCOUNT': 'Rabais',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Enquête ou problème fraude',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Problèmes d’impression de facture',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Frais mensuels récurrents',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'Frais ponctuels – rajustement',
	'TICKETING.OTHER': 'Autre',
	'TICKETING.PAYMENT_ISSUES': 'Problèmes de paiement',
	'TICKETING.PRORATED_CHARGES': 'Frais au prorata',
	'TICKETING.REACTIVATION_FEE': 'Frais de réactivation',
	'TICKETING.SMS_TEXT_CANADA': 'SMS/Texte Canada',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'SMS/Texte international',
	'TICKETING.SMS_TEXT_OFF_NET': 'SMS/Texte hors réseau',
	'TICKETING.SMS_TEXT_ON_NET': 'SMS/Texte sur réseau',
	'TICKETING.STATUS_INVOICE': 'État facture',
	'TICKETING.TAXES_AND_SURCHARGES': 'Taxes et surtaxes',
	'TICKETING.DATA': 'Données',
	'TICKETING.MESSAGING_SMS_MMS_': 'Messagerie (SMS/MMS)',
	'TICKETING.VOICE': 'Voix',
	'TICKETING.CLARIFICATION': 'Clarification',
	'TICKETING.PREPAID': 'Prépayé',
	'TICKETING.USAGE_INQUIRY': 'Requête utilisation',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': 'Question sur facturation',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Problème de facture',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Problème de forfait',
	'TICKETING.USAGE_RELATED_ISSUE': 'Problème d’utilisation',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': '2G et 3G combinés',
	'TICKETING.2G_ONLY': '2G UNIQUEMENT',
	'TICKETING.3G_ONLY': '3G UNIQUEMENT',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Connecté, mais ne transmet pas les données',
	'TICKETING.FRAME_RELAY_PVC': 'Relais de trames/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Problème de latence',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Itinérance/Domestique',
	'TICKETING.ROAMING_INTERNATIONAL': 'Itinérance/International',
	'TICKETING.SIGNAL_ISSUES': 'Problèmes de signal',
	'TICKETING.SMPP_BIND': 'Liaison SMPP',
	'TICKETING.SMS': 'SMS',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': 'Commentaires',
	'TICKETING.NEW_REQUEST': 'Nouvelle demande',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': 'Compatibilité',
	'TICKETING.DEVICE_UNLOCK': 'Déverrouillage appareil',
	'TICKETING.ORDERING': 'Commande',
	// TICKETING LOST/STOLEN DEVICE SUBCATEGORIES
	'TICKETING.BLOCK_DEVICE': 'Blocage appareil',
	'TICKETING.FRAUD_REPORT': 'Signalement de fraude',
	'TICKETING.UNBLOCK_DEVICE': 'Déblocage appareil',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': 'Notification d’incident',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Maintenance programmée',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Interface de canal de réseau (NCI)',
	'TICKETING.WIRELESS': 'Sans fil',
	'TICKETING.WIRELINE': 'Filaire',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': 'Question',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': 'Fichiers séquentiels',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Créer nouveau profil SIM',
	'TICKETING.EQUIPMENT_INQUIRY': 'Requête équipement',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Provisionnement SIM unique',
	'TICKETING.WEB_SERVICE': 'Service Web',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'Commandes de SIM/Fonctionnalités',
	'TICKETING.ACTIVATION': 'Activation',
	'TICKETING.BATCH_BULK_CHANGE': 'Changement lot/bloc',
	'TICKETING.CALL_FORWARDING': 'Renvoi d’appel',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Changement forfait communication',
	'TICKETING.CHANGE_RATE_PLAN': 'Changer forfait',
	'TICKETING.CHANGE_SIM_STATUS': 'Changer l’état SIM',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'Transfert SIM entre comptes',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Question sur la règle d’automatisation',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Question sur le centre de contrôle',
	'TICKETING.COVERAGE_QUESTION': 'Question sur la couverture',
	'TICKETING.GENERAL_QUESTION': 'Question générale',
	'TICKETING.OUTAGE_QUESTION': 'Question sur une panne',
	'TICKETING.ROAMING_QUESTION': 'Question sur l’itinérance',
	'TICKETING.JPO_COVERAGE': 'Couverture JPO',
	//  TICKETING SALES/MARKETING ISSUE SUBCATEGORIES
	'TICKETING.CUSTOMER_INQUIRY': 'Requête client',
	'TICKETING.DEV_KIT': 'Kit de dév.',
	'TICKETING.MARKETING_INQUIRY': 'Requête Marketing',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': 'Résiliation du compte',
	'TICKETING.API_SETUP': 'Configuration API',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': 'Création/Modification zone de facturation',
	'TICKETING.CC_ACCESS_UPDATES': 'CC accès/mises à jour',
	'TICKETING.COMMUNICATION_PLAN': 'Forfait communication',
	'TICKETING.CUSTOM_APN': 'APN personnalisé',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Configuration/Association SIM mondiale',
	'TICKETING.GPRS_VPN': 'VPN GPRS',
	'TICKETING.LEGAL_SUBPOENA': 'Juridique/citation à comparaître',
	'TICKETING.LTE_MIGRATION': 'Migration LTE',
	'TICKETING.MSISDN_UPDATES': 'Mises à jour de nº réseau numérique à intégration de services d’abonné mobile',
	'TICKETING.NEW_ACCOUNT': 'Nouveau compte',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Configuration RADIUS',
	'TICKETING.REPORT': 'Rapport',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'Restriction d’itinérance (requête Zone d’itinérance)',
	'TICKETING.SECURESIM': 'SIM sécurisée',
	'TICKETING.SIM_ORDER_PROVISIONING': 'Commande/provisionnement SIM',
	'TICKETING.TRACE': 'Tracer',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': 'Problèmes d’application',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Problème technique de facturation',
	'TICKETING.CONNECTIVITY': 'Connectivité',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Problème SIM mondiale',
	'TICKETING.JPO_FILE_ISSUE': 'Problème fichier JPO',
	'TICKETING.JPO_LINK_ISSUE': 'Problème liaison JPO',
	'TICKETING.NOC_BRIDGE': 'Pont centre opérations de réseau',
	'TICKETING.CDR_FILES': 'Fichiers d’enregistrement des données d’appels',
	'TICKETING.JPO_REPORT': 'Rapport JPO',
	//  TICKETING USER/ACCOUNT ADMINISTRATION SUBCATEGORIES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': 'Ajouter options de fonctionnalité au nouveau compte',
	'TICKETING.DEACTIVATE_USER': 'Désactiver utilisateur',
	'TICKETING.ID_ISSUE': 'Problème ID',
	'TICKETING.NEW_USER': 'Nouvel utilisateur',
	'TICKETING.PASSWORD_RESET': 'Réinitialisation mot de passe',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'Centre de contrôle AT&T (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Portail voiture connectée/Apollo',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise on Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	// OTHER
	'TICKETING.CRITICAL': 'Critique',
	'TICKETING.MAJOR': 'Majeur',
	'TICKETING.MINOR': 'Mineur',
	'TICKETING.LOW': 'Faible',
	'TICKETING.MEDIUM': 'Moyen',
	'TICKETING.HIGH': 'Élevé',
	'TICKETING.OUTAGE': 'Panne',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': 'IP dynamique',
	'TICKETING.FIXED_IP': 'IP fixe',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Règle d’automatisation',
	'TICKETING.CC_ISSUE_OTHER': 'Problème CC – Autre',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Exporter et mettre à jour le lot',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Services de localisation/tiers',
	'TICKETING.PROVISIONING': 'Provisionnement',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Information séance différée',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': 'Problèmes WebUI',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': 'Problème de données',
	'TICKETING.DEVICE_ISSUE': 'Problème d’appareil',
	'TICKETING.SFTP_FILE_TRANSFERS': 'Transferts sFTP/Fichiers',
	'TICKETING.SMS_ISSUE': 'Problème de SMS',
	'TICKETING.VOICE_ISSUE': 'Problème de voix',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': 'Réseau',
	'TICKETING.RADIUS': 'RADIUS',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Ajouter pièce jointe',
	'TICKETING.NOTE': 'Note',
	'TICKETING.LAST_UPDATED_DATE': 'Date dernière mise à jour billet',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': 'Point terminal comportant ce paramètre non existant.',
	// ticketing notifications
	'TICKETING.ATTACHMENT_FAIL_TITLE': 'Votre demande n’a pas pu être entièrement traitée.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': 'Le billet a été créé {ticketId}, mais les pièces jointes ({attachments}) n’ont pas été téléversées.',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': 'Le billet a été modifié {ticketId}, mais les pièces jointes ({attachments}) n’ont pas été téléversées.',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Le billet a été modifié {ticketId}, mais les pièces jointes ({attachments}) n’ont pas été supprimées.',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Le billet a été modifié {ticketId}, mais les pièces jointes ({deleteAttachments}) n’ont pas été supprimées et les pièces jointes ({uploadAttachments}) n’ont pas été téléversées.'
};
