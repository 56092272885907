import {
	userIsAuthenticated,
	userInStandardMode
} from '../../../utils/AuthSelector';
import Ticketing from '../views/TicketingView';
import TicketDetails from '../views/TicketDetailsView';
import AddTicketView from '../views/AddTicketView';

const ticketingRoutes = [
	{
		path: '/support/ticketing',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.ticketing_rw', 'mnc.ticketing_ro'])(
				Ticketing
			)
		)
	},
	{
		path: '/support/ticketing/ticket/:id',
		exact: true,
		Component: userInStandardMode(TicketDetails)
	},
	{
		path: '/support/ticketing/new-ticket',
		exact: true,
		Component: userInStandardMode(AddTicketView),
		withoutSidebar: true
	},
	{
		path: '/support/ticketing/new-ticket/:type/:iccid/',
		exact: true,
		Component: userInStandardMode(AddTicketView),
		withoutSidebar: true
	}
];

export default ticketingRoutes;
