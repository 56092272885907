import { combineReducers } from 'redux';
import globalReducer from '../../../redux/global/globalReducer';

import {
	GET_ANALYTICS_REPORTS,
	GET_CUSTOMER_REPORTS,
	GET_REPORTS_FILTERS,
	DOWNLOAD_REPORT,
	SFG_REPOST
} from './constants';

export default combineReducers({
	getAnalyticsReports: globalReducer(GET_ANALYTICS_REPORTS),
	getCustomerReports: globalReducer(GET_CUSTOMER_REPORTS),
	getReportsFilters: globalReducer(GET_REPORTS_FILTERS),
	downloadReport: globalReducer(DOWNLOAD_REPORT),
	sfgRepost: globalReducer(SFG_REPOST)
});
