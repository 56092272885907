import { createAction } from 'redux-actions';
import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';

import {
	addNotification as addNotificationService,
	editNotification as editNotificationService,
	getActiveNotifications as getActiveNotificationsService,
	getNotification as getNotificationService,
	getNotifications as getNotificationsService,
	getAllNotifications as getAllNotificationsService,
	getNotificationsTypes as getNotificationsTypesService,
	getNotificationsGroups as getNotificationsGroupsService,
	removeNotification as removeNotificationService
} from '../../services/NotificationsService';

import {
	ADD_NOTIFICATION,
	EDIT_NOTIFICATION,
	GET_ACTIVE_NOTIFICATIONS,
	GET_NOTIFICATION,
	GET_NOTIFICATIONS,
	GET_NOTIFICATIONS_GROUPS,
	GET_NOTIFICATIONS_TYPES,
	REMOVE_NOTIFICATION,
	ADD_NOTIFICATION_MODAL,
	REMOVE_ACTIVE_NOTIFICATION
} from './constants';

export const openAddNotificationModal = createAction(
	`OPEN_${ADD_NOTIFICATION_MODAL}`
);
export const closeAddNotificationModal = createAction(
	`CLOSE_${ADD_NOTIFICATION_MODAL}`
);
export const removeActiveNotification = createAction(
	REMOVE_ACTIVE_NOTIFICATION
);

export const addNotification = (params) =>
	globalAction(ADD_NOTIFICATION, addNotificationService, params, {
		notify: 'add-notification',
		init: true,
		modals: closeAddNotificationModal()
	});

export const editNotification = (params) =>
	globalAction(EDIT_NOTIFICATION, editNotificationService, params, {
		notify: 'edit-notification',
		init: true,
		modals: closeAddNotificationModal()
	});

export const getActiveNotifications = (params) =>
	globalAction(GET_ACTIVE_NOTIFICATIONS, getActiveNotificationsService, params);

export const getAllNotifications = (params) =>
	globalAction(GET_NOTIFICATIONS, getAllNotificationsService, params);

export const getNotification = (params) =>
	globalAction(GET_NOTIFICATION, getNotificationService, params);

export const getNotificationInit = () => actionInit(GET_NOTIFICATION);

export const getNotifications = (params) =>
	globalAction(GET_NOTIFICATIONS, getNotificationsService, params);

export const getNotificationsGroups = (params) =>
	globalAction(GET_NOTIFICATIONS_GROUPS, getNotificationsGroupsService, params);

export const getNotificationsTypes = (params) =>
	globalAction(GET_NOTIFICATIONS_TYPES, getNotificationsTypesService, params);

export const removeNotification = (params) =>
	globalAction(REMOVE_NOTIFICATION, removeNotificationService, params, {
		notify: 'remove-notification',
		init: true,
		modals: closeAddNotificationModal()
	});
