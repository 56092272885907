import { Status } from '../../../../utils/constants';

export const getBatchReportsRequest = (state) =>
	state.batchHistory.batchReports.getBatchReports.status === Status.PENDING;
export const getBatchReportsFail = (state) =>
	state.batchHistory.batchReports.getBatchReports.status === Status.FAIL;
export const getBatchReportsSuccess = (state) =>
	state.batchHistory.batchReports.getBatchReports.data;

export const downloadReportRequest = (state) =>
	state.reports.downloadReport.status === Status.PENDING;
