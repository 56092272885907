import { handleActions } from 'redux-actions';

import { SIMPLE_SEARCH_START, SIMPLE_SEARCH_CLOSE } from './constants';

export const initialState = {
	isSimpleSearch: false,
	searchParameter: []
};

export default handleActions(
	{
		[SIMPLE_SEARCH_START]: (state, action) => ({
				...state,
				isSimpleSearch: true,
				searchParameter: [action.payload]
			}),
		[SIMPLE_SEARCH_CLOSE]: (state) => ({
				...state,
				isSimpleSearch: false,
				searchParameter: []
			})
	},
	initialState
);
