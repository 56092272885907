import { createAction } from 'redux-actions';

import { getDirectionIndex as service } from '../../services/AnalyticsService';

import {
	GET_ANALYTICS_DIRECTIONAL_INDEX_REQUEST,
	GET_ANALYTICS_DIRECTIONAL_INDEX_SUCCESS,
	GET_ANALYTICS_DIRECTIONAL_INDEX_ERROR
} from '../constants';

const request = createAction(GET_ANALYTICS_DIRECTIONAL_INDEX_REQUEST);
const success = createAction(GET_ANALYTICS_DIRECTIONAL_INDEX_SUCCESS);
const fail = createAction(GET_ANALYTICS_DIRECTIONAL_INDEX_ERROR);

const getDirectionalIndex = (chartId, chartSetting) => async (dispatch) => {
	dispatch(request({ id: chartId }));
	service(chartSetting)
		.then((response) => dispatch(success({ id: chartId, data: response })))
		.catch((error) => dispatch(fail({ id: chartId, error })));
};

export default getDirectionalIndex;
