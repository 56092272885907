import { combineReducers } from 'redux';

import singleSimStatusChangeModal from './singleSimStatusChangeModal/reducer';
import batchFileUploadModal from './batchFileUploadModal/reducer';
import editTableModal from './editTableModal/reducer';
import addChartModal from './addChartModal/reducer';
import sendSmsModal from './sendSmsModal/reducer';
import cancelScheduleStatusModal from './cancelScheduleStatusModal/reducer';
import globalModalReducer from './globalModal/reducer';
import globalFilterModal from './globalFilterModal/reducer';

export default combineReducers({
	singleSimStatusChangeModal,
	batchFileUploadModal,
	editTableModal,
	addChartModal,
	sendSmsModal,
	cancelScheduleStatusModal,
	globalModalReducer,
	globalFilterModal
});
