import { createAction } from 'redux-actions';

import { exportChartDataCSV as exportCSV } from '../../services/AnalyticsService';
import { showNotification } from '../../../Shared/redux/notifications/actions';
import { getLocale } from '../../../../utils/constants';
import { downloadHelper } from '../../../AnalyticsInvoices/redux/invoiceAttachment/actions';
import {
	EXPORT_CHART_DATA_CSV_REQUEST,
	EXPORT_CHART_DATA_CSV_SUCCESS,
	EXPORT_CHART_DATA_CSV_FAIL
} from './constants';

const request = createAction(EXPORT_CHART_DATA_CSV_REQUEST);
const success = createAction(EXPORT_CHART_DATA_CSV_SUCCESS);
const fail = createAction(EXPORT_CHART_DATA_CSV_FAIL);

const exportChartDataCSV = (chartId, chartName, requestParams) => async (
	dispatch
) => {
  const locale = getLocale();
	const {
		default: {
			ACTION_COMPLETED_SUCCESSFULLY,
			ACTION_COULD_NOT_BE_COMPLETED,
			SECURITY_FEATURES_SUCCESSFULLY_EXPORT,
			SECURITY_FEATURES_FAIL_EXPORT
		}
	} = await import(`../../../../localizations/${locale}`);

	dispatch(request({ chartId }));
	exportCSV(requestParams)
		.then((response) => {
			dispatch(success({ chartId }));
			downloadHelper(
				false,
				`${chartName}.csv`,
				response.data,
				'text/csv;encoding:utf-8'
			);
			dispatch(
				showNotification({
					id: 'export-success',
					message: SECURITY_FEATURES_SUCCESSFULLY_EXPORT
						? [SECURITY_FEATURES_SUCCESSFULLY_EXPORT]
						: [ACTION_COMPLETED_SUCCESSFULLY],
					type: 'success',
					notificationType: 'toaster',
					close: true,
					duration: 5000
				})
			);
		})
		.catch((error) => {
			dispatch(fail({ error, chartId }));
			dispatch(
				showNotification({
					id: 'export-fail',
					message: SECURITY_FEATURES_FAIL_EXPORT
						? [SECURITY_FEATURES_FAIL_EXPORT]
						: [ACTION_COULD_NOT_BE_COMPLETED],
					type: 'error',
					notificationType: 'toaster',
					close: true,
					duration: 5000
				})
			);
		});
};

export default exportChartDataCSV;
