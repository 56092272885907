import React from 'react';
import {
	DesktopAndTabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import { Desktop, Mobile } from '../../components/Reports';
import { globalPaginationNumber } from '../../../../utils/constants';

const ReportsView = (props) => (
	<>
		<DesktopAndTabletMediaQuery>
			<Desktop {...props} dataLimit={globalPaginationNumber.desktop} />
		</DesktopAndTabletMediaQuery>
		<MobileMediaQuery>
			<Mobile {...props} dataLimit={globalPaginationNumber.mobile} />
		</MobileMediaQuery>
	</>
);

export default ReportsView;
