import { handleActions } from 'redux-actions';

import {
	SET_TICKET_FORM_PAGE_NEXT,
	SET_TICKET_FORM_PAGE_PREV,
	SET_TICKET_FORM_PAGE_INIT
} from './constants';

export const initialState = {
	currentPage: 1
};

export default handleActions(
	{
		[SET_TICKET_FORM_PAGE_NEXT]: (state) => ({
				...state,
				currentPage: state.currentPage + 1
			}),
		[SET_TICKET_FORM_PAGE_PREV]: (state) => ({
				...state,
				currentPage: state.currentPage - 1
			}),
		[SET_TICKET_FORM_PAGE_INIT]: (state) => ({
				...state,
				currentPage: 1
			})
	},
	initialState
);
