import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './RuleStateDropdown.scss';

const Option = (optionProps) => {
	const { data, children } = optionProps;
	return (
		<components.Option
			data-spec={`${data.value}`}
			{...optionProps}
			className={
				data && data.value === 'selectAction'
					? styles.select_action
					: cn(
							styles[
								data &&
									data.value &&
									data.value.replace(/\s/g, '_').toLowerCase()
							],
							styles.custom_option
					  )
			}
		>
			<div data-spec="option" className={styles.paddingOption}>
				<span data-spec={`${data.value}`}>{children}</span>
			</div>
		</components.Option>
	);
};

const SingleValue = (optionProps) => {
	const { data } = optionProps;
	return (
		<components.SingleValue
			data-spec={`${data.value}`}
			{...optionProps}
			className={cn(
				styles[
					data && data.value && data.value.replace(/\s/g, '_').toLowerCase()
				],
				styles.custom_option,
				styles.select_single_value
			)}
		/>
	);
};

const RuleStateDropdown = (props) => (
	<Select
		data-spec="select"
		components={{ Option, SingleValue }}
		onChange={() => {}}
		autoFocusFirstOption={false}
		styles={styles}
		{...props}
	/>
);

const { object } = PropTypes;

RuleStateDropdown.propTypes = {
	currentValue: object
};

export default RuleStateDropdown;
