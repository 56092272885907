import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getDiscounts = (params) => ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/discounts?${qs.stringify(
			params.params
		)}`
	);

export const getDiscountsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/discounts/filters?${qs.stringify(
			params.params
		)}`
	);
