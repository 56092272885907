import React, { PureComponent } from 'react';
import { objectOf, string, func, bool, array } from 'prop-types';
import { FormattedDate } from 'react-intl';

import Table from '../../../../lib/DigitalComponents/Table';
import { dataHistoryHeader } from '../../utils/constants';
import Loading from '../../../../lib/DigitalComponents/Loader';

class DataHistory extends PureComponent {
	componentDidMount() {
		const { data, getDataHistory, iccid } = this.props;

		if (!data) {
			const params = {
				additionalParams: {
					dataLimit: 50,
					dataOffset: 0
				}
			};

			getDataHistory(params, iccid);
		}
	}

	formatData = (data) => (data ? data.map((item) => item.attributes) : []);

	options = () => ({
		header: dataHistoryHeader,
		customComponents: {
			dataVolumeDownlink: {
				type: 'custom',
				component: ({ dataVolumeDownlink }) =>
					(
						typeof dataVolumeDownlink === 'number'
							? parseFloat((
							dataVolumeDownlink / 1024
							).toFixed(2))
							: ''
					)
			},
			dataVolumeUplink: {
				type: 'custom',
				component: ({ dataVolumeUplink }) =>
					(
						typeof dataVolumeUplink === 'number'
							? parseFloat((
							dataVolumeUplink / 1024
							).toFixed(2))
							: ''
					)
			},
			eventStartTime: {
				type: 'custom',
				component: ({ eventStartTime }) => (
					<FormattedDate
						value={eventStartTime}
						year="numeric"
						month="2-digit"
						day="2-digit"
						hour="2-digit"
						minute="2-digit"
						second="2-digit"
						hour12={false}
					>
						{(formattedDate) => formattedDate.replace(',', '')}
					</FormattedDate>
				)
			},
			dataVolumeTotal: {
				type: 'custom',
				component: ({ dataVolumeTotal }) =>
					(
						typeof dataVolumeTotal === 'number'
							? parseFloat((
							dataVolumeTotal / 1024
							).toFixed(2))
							: ''
					)
			}
		},
		tableOptions: {
			firstColumnMarked: true,
			fixedFirstColumn: true
		}
	});

	render() {
		const { isPending, data, messages } = this.props;

		return isPending ? (
			<Loading />
		) : (
			<div data-spec="session-history-wing-component">
				<Table
					data={this.formatData(data)}
					options={this.options()}
					messages={messages}
				/>
			</div>
		);
	}
}

DataHistory.propTypes = {
	getDataHistory: func,
	iccid: string,
	isPending: bool,
	data: array,
	messages: objectOf(string),
};
DataHistory.defaultProps = {
	getDataHistory: undefined,
	iccid: '',
	isPending: false,
	data: [],
	messages: {},
};

export default DataHistory;
