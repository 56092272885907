export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Eindpunt {iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Eindpunt bewerken',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Netwerkrecht',
	'SINGLE_ENDPOINT.ID': 'Id',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'Kan eindpunt-id {args1} niet inrichten na IMSI-statuswijziging. Vraag ondersteuning',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE': '{step}. SIM-status selecteren',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. Wijziging SIM-status plannen',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. Systeembevestiging',
	'SINGLE_ENDPOINT.ICCID': 'ICCID',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': 'MSISDN',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'Status',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Vernieuw het eindpunt om te controleren of de status is gewijzigd',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'M2M-platform',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'Id M2M-account',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Type netwerk',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Netwerkoperator',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'Land netwerk',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'Datum M2M toegevoegd',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': 'Datum activering M2M',
	'SINGLE_ENDPOINT.IN_SESSION': 'In sessie',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Tariefplan',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Fout in tariefplan, neem contact op met klantenservice.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Fout in netwerkrecht, neem contact op met klantenservice.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'Status IMSI-overdracht',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'Roamingland',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Naam account',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': 'Naam netwerkrecht',
	'SINGLE_ENDPOINT.ROAMING': 'Roaming',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Gemaakt op',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Datum laatste update',
	'SINGLE_ENDPOINT.SMS_MO': 'SMS MO',
	'SINGLE_ENDPOINT.SMS_MT': 'SMS MT',
	'SINGLE_ENDPOINT.VOICE_MO': 'Voice MO',
	'SINGLE_ENDPOINT.VOICE_MT': 'Voice MT',
	'SINGLE_ENDPOINT.LTE_DATA': 'LTE-data',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'Roaming-id',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Type Camel-service',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Blokkeer 09XY- of 18XY-nummers',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': 'Blokkeer internationaal SMS MO',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'HLR-sjabloon',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'Voice internationaal',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Helpdesk-nummer',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Beschrijving',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'Vast IP-adres',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'Niet van toepassing voor dit eindpunt',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Naam tariefplan',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Type tariefplan',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Abonnementsprijs',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Accountkosten',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Type betaling',
	'SINGLE_ENDPOINT.TERM': 'Tijdvak',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Verbruikslimiet',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Vlag opslaglagen',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID: {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD': 'Verhoogde machtigingen nodig!',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Alleen beheerders kunnen aangepaste veldtypen wijzigen, omdat ze de configuratie voor het hele systeem beïnvloeden. Neem contact op met uw beheerder om de opties voor aangepaste velden te updaten.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID': 'Eindpuntacties voor {endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. SIM-status selecteren',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'Eindpunt-id',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'Er is een geplande activiteit voor dit eindpunt.',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'Weet u zeker dat u de in behandeling zijnde wijziging wilt annuleren?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT':
		'Deze actie kan niet ongedaan worden gemaakt.',
	'SINGLE_ENDPOINT.CARRIER': 'Provider',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Referenties opnieuw toegewezen',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Opmerking',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'Gebruiker {uid} heeft status gewijzigd op {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Wijziging SIM-status is gepland voor ',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'Huidige SIM-status voor eindpunt-id {endpointId} is ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Geschikte netwerkrechten',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'Vast IP-adres',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Momenteel toegewezen aan dit eindpunt',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS': 'Gegevens netwerkrecht',
	SELECT_MINIMUM_OF_2: 'Selecteer minimaal 2 om op de tabel toe te passen. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Diagnose starten',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Informatie provider',
	'SINGLE_ENDPOINT.COUNTRY': 'Land',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'Beschrijving M2M-platformstatus',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'Actief live',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Inactief nieuw',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Actief slaapstand',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Inactief gestopt',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I':
		'Gemigreerd van andere GDSP-omgeving',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'Niet ingericht',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O':
		'Gemigreerd naar andere GDSP-omgeving',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Actief ready',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Actief opgeschort',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Actief test',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'Actief stand-by',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Inactief getransformeerd',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Wettelijke opschorting',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Beëindigd',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'Niet beschikbaar',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Inrichtingsinformatie eindpunt',
	'SINGLE_ENDPOINT.PROVISIONED': 'Ingericht',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME':
		'Inrichtingsdatum en -tijd {date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Netwerkverbindingen',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Voice ingeschakeld',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Data ingeschakeld',
	'SINGLE_ENDPOINT.SESSIONS': 'Sessies',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Voorraad',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Geactiveerd',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Geactiveerd',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inactief',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inactief',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Gedeactiveerd',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired':
		'Buiten gebruik gesteld',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Leeggemaakt',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Test ready',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Activering ready',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Vervangen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Proef',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Starten',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Overdracht',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Bron wereldwijd in transit',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Bestemming wereldwijd in transit',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Wereldwijd overgedragen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Overig',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Doorgaand verkeer toegestaan',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Doorgaand verkeer niet toegestaan',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Toegestane draadloze TollSec-resources',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Geweerd van netwerk',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Niet-toegestaan verkeer SIM-status',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Opgeschort door serviceprovider',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Opgeschort wegens verbruikslimiet',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Niet-toegestaan verkeer (overig)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Verbonden met netwerk (in sessie)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'Niet-verbonden met netwerk (in sessie)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Geldige registratie (geldige netwerkactiviteit)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Geldige registratie (geen netwerkactiviteit)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Alleen geldige gsm-registratie',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'Geen geldige registratie (heeft activiteit in afgelopen 24 uur)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'Geen geldige registratie (heeft auth-aanvraag in afgelopen 24 uur)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'Geen geldige registratie (heeft meer dan 24 uur geleden lokale updates)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'Geen geldige registratie (heeft meer dan 24 uur geleden een auth-aanvraag)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'Meer dan 24 uur geleden (geen netwerkactiviteit)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'Apparaat is momenteel niet aangesloten. Eerde IP-sessie geslaagd.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Datasessie momenteel niet geslaagd',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Datasessie in verleden geslaagd',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Verbindingspoging mislukt (ongeldige referenties)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'Geen geschiedenis geslaagde datasessies',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Toegestane draadloze TollSec-resources',
	'SINGLE_ENDPOINT.ACTIONS': 'Acties',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'HLR/HSS-gegevens ophalen',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Vaste kosten',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Tariefplan selecteren',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Wijzigingen bevestigen',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. Systeembevestiging',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Geschikt voor gebruik met dit eindpunt',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		'Wijziging tariefplan is geüpdatet',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Wijziging tariefplan is gepland voor ',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Wijziging tariefplan is gepland',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Wijziging netwerkrecht bevestigen',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Nadat deze wijziging is geïnitieerd, wordt het geselecteerde netwerkrecht van het eindpunt ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION':
		' Wilt u doorgaan met deze actie?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Of u kunt de wijziging plannen voor: ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Wijziging netwerkrecht is gepland.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Wijziging netwerkrecht is gepland voor ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'Wijziging netwerkrecht kan op dit moment niet worden uitgevoerd.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'Wijziging netwerkrecht kan op dit moment niet worden gepland.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'Statuswijziging netwerkrecht',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'Huidig tariefplan voor eindpunt-id {id} is ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Roamingprovider',
	'SINGLE_ENDPOINT.OEM': 'Subaccounts',
	'SINGLE_ENDPOINT.SERVICE_STATUS': 'Status 360L',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE: ',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Netwerkverbinding',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN: ',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'Status registratie',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Gekoppelde 360L-accounts',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Dual-modus',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Verbonden apparaat',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Tariefplan',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'Vastgelegde status',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Datum activering',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': 'Dataverbruik exporteren',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE': 'Exporteren data mislukt',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'Kan momenteel dataverbruik niet exporteren.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Even geduld graag tot we het bestand voor u hebben voorbereid. Afhankelijk van het aantal records kan het enige tijd duren voordat het voltooid is.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'Er zijn geen weer te geven data voor de geselecteerde periode',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'Probeer het databereik te wijzigen om de lijst te vernieuwen als dit beschikbaar is',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Geactiveerd',
	'SINGLE_ENDPOINT.TERMINATE': 'Beëindigd',
	'SINGLE_ENDPOINT.DEACTIVE': 'Niet-actief',
	'SINGLE_ENDPOINT.INACTIVE': 'Inactief',
	'SINGLE_ENDPOINT.TEST': 'Test',
	'SINGLE_ENDPOINT.SUSPENDED': 'Opgeschort',
	'SINGLE_ENDPOINT.SUSPEND': 'Opschorten',
	'SINGLE_ENDPOINT.INVENTORY': 'Voorraad'
};
