import React, { PureComponent } from 'react';
import { func, string, bool } from 'prop-types';

import EditPermissionsView from '../../views/EditPermissionsView';

import styles from './EditUserGroupPermissions.scss';

import submitForm from './submitForm';

class EditUserGroupPermissions extends PureComponent {
	render() {
		const { userGroupId, pushBack, system } = this.props;
		return (
			<div data-spec="edit-user-group-permissions" className={styles.wrapper}>
				<EditPermissionsView
					{...this.props}
					id={userGroupId}
					submitForm={submitForm}
					onClose={pushBack}
					system={system}
				/>
			</div>
		);
	}
}

EditUserGroupPermissions.propTypes = {
	userGroupId: string,
	pushBack: func,
	system: bool
};

export default EditUserGroupPermissions;
