import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_TAXES, GET_TAXES_FILTERS } from './constants';

export default combineReducers({
	getTaxes: globalReducer(GET_TAXES),
	getTaxesFilters: globalReducer(GET_TAXES_FILTERS)
});
