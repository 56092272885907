import * as ApiService from '../../../services/ApiService';

export const getNetworkEntitlements = (params) => {
	const { companyId, m2mAccountId } = params;
	let url = `/customers/network-entitlements/${m2mAccountId}`;
	if (companyId) {
		url = `/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/all-network-entitlements`;
	}
	return ApiService.get(url);
};

export const getNetworkEntitlement = (params) => {
	const { companyId, m2mAccountId, id } = params;
	return ApiService.get(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/network-entitlements/${id}`
	);
};

export const addNetworkEntitlement = (params) => {
	const { companyId, m2mAccountId, data } = params;
	return ApiService.post(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/network-entitlements`,
		data
	);
};

export const editNetworkEntitlement = (params) => {
	const { companyId, m2mAccountId, id, data } = params;
	return ApiService.patch(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/network-entitlements/${id}`,
		data
	);
};

export const showAssociatedApns = (params) => {
	const { companyId, m2mAccountId } = params;
	return ApiService.get(
		`onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/apns`
	);
};
