import { Status } from '../../../../utils/constants';

/* GET TICKETS */
export const getTicketsRequest = (state) =>
	state.ticketing.tickets.getTickets.status === Status.PENDING;
export const getTicketsFail = (state) =>
	state.ticketing.tickets.getTickets.status === Status.FAIL;
export const getTicketsSuccess = (state) =>
	state.ticketing.tickets.getTickets.data;

/* GET TICKET */
export const getTicketRequest = (state) =>
	state.ticketing.tickets.getTicket.status === Status.PENDING;
export const getTicketFail = (state) =>
	state.ticketing.tickets.getTicket.status === Status.FAIL;
export const getTicketSuccess = (state) =>
	state.ticketing.tickets.getTicket.data;

/* ADD TICKET */
export const addTicketRequest = (state) =>
	state.ticketing.tickets.addTicket.status === Status.PENDING;
export const addTicketFail = (state) =>
	state.ticketing.tickets.addTicket.status === Status.FAIL;
export const addTicketSuccess = (state) =>
	state.ticketing.tickets.addTicket.data;

/* EDIT TICKET */
export const editTicketRequest = (state) =>
	state.ticketing.tickets.editTicket.status === Status.PENDING;
export const editTicketFail = (state) =>
	state.ticketing.tickets.editTicket.status === Status.FAIL;
export const editTicketSuccess = (state) =>
	state.ticketing.tickets.editTicket.data;
