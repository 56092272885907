import { createAction } from 'redux-actions';

import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './constants';

const add = createAction(SHOW_NOTIFICATION);
const remove = createAction(HIDE_NOTIFICATION);

export const showNotification = (options) => async (dispatch) => {
	dispatch(add(options));
};

export const hideNotification = (id) => async (dispatch) => {
	dispatch(remove(id));
};
