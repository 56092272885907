export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Endpoint {iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Modifica endpoint',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Diritti di rete',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'Provisioning ID endpoint {args1} non riuscito dopo cambiamento stato IMSI. Ottieni assistenza',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE': '{step}. Seleziona stato SIM',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. Pianifica cambiamento stato SIM',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. Conferma del sistema',
	'SINGLE_ENDPOINT.ICCID': 'ICCID',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': 'MSISDN',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'Stato',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Aggiorna l’endpoint per verificare se lo stato è cambiato',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'Piattaforma M2M',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'ID account M2M',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Tipo di rete',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Operatore di rete',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'Paese della rete',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'Aggiunta data M2M',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': 'Data attivazione M2M',
	'SINGLE_ENDPOINT.IN_SESSION': 'In uso',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Piano tariffario',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Errore piano tariffario, rivolgersi all’assistenza clienti.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Errore su diritti di rete, rivolgersi all’assistenza clienti.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'Stato trasferimento IMSI',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'Paese roaming',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Nome account',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': 'Nome diritti di rete',
	'SINGLE_ENDPOINT.ROAMING': 'Roaming',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Data creazione',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Data ultimo aggiornamento',
	'SINGLE_ENDPOINT.SMS_MO': 'SMS MO',
	'SINGLE_ENDPOINT.SMS_MT': 'SMS MT',
	'SINGLE_ENDPOINT.VOICE_MO': 'Vocale MO',
	'SINGLE_ENDPOINT.VOICE_MT': 'Vocale MT',
	'SINGLE_ENDPOINT.LTE_DATA': 'Dati LTE',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'ID roaming',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Tipo servizio Camel',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Blocca numeri premium',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': 'Bloccare SMS MO internazionali',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'Modello HLR',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'Vocale internazionale',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Numero linea diretta',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Descrizione',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'IP fisso',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'Non applicabile per questo endpoint',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Nome piano tariffario',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Tipo piano tariffario',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Prezzo abbonamento',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Addebito account',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Tipo di pagamento',
	'SINGLE_ENDPOINT.TERM': 'Termine',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Limite utilizzo',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Contrassegno livelli',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name}- ID: {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD':
		'È obbligatorio disporre di autorizzazioni elevate!',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Solo gli amministratori possono modificare i tipi di campo personalizzati in quanto hanno un impatto sulla configurazione a livello di sistema. Contattare l’amministratore per aggiornare le opzioni dei campi personalizzati.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID': 'Azioni endpoint per {endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. Seleziona stato SIM',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'ID endpoint',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'Attività pianificata per questo endpoint.',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'Confermare l’annullamento del cambiamento in sospeso?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT':
		'Questa azione non può essere annullata!',
	'SINGLE_ENDPOINT.CARRIER': 'Vettore',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Credenziali rigenerate',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Nota',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'L’utente {uid} ha effettuato il cambiamento di stato il {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Pianificazione cambiamento stato SIM riuscita per ',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'Lo stato corrente della SIM per l’endpoint {endpointId} è ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Diritti di rete idonei',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'IP fisso',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Assegnato attualmente a questo endpoint',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS': 'Dettagli diritti di rete',
	SELECT_MINIMUM_OF_2: 'Selezionarne almeno 2 da applicare alla tabella. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Avvia diagnostica',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Informazioni vettore',
	'SINGLE_ENDPOINT.COUNTRY': 'Paese',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'Descrizione stato piattaforma M2M',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'Attivo connesso',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Inattivo nuovo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Attivo sospeso',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Inattivo interrotto',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I': 'Migrato da altro ambiente GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'Senza provisioning',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O': 'Migrato ad altro ambiente GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Attivo pronto',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Attivo sospendi',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Attivo prova',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'Attivo standby',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Inattivo trasformato',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Sospensione normativa',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Chiuso',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'Non disponibile',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Informazioni provisioning endpoint',
	'SINGLE_ENDPOINT.PROVISIONED': 'Provisioning effettuato',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME': 'Data e ora provisioning {date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Connessioni di rete',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Vocale attivato',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Dati attivati',
	'SINGLE_ENDPOINT.SESSIONS': 'Sessioni',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Inventario',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Attivato',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Attivato',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inattivo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inattivo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Disattivato',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': 'Ritirato',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Cancellato',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Pronto alla prova',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Pronto all’attivazione',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Sostituito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Prova',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Avvio',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Trasferimento',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Sorgente globale in transito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Destinazione globale in transito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Globale trasferito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Altro',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Traffico di passaggio permesso',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Traffico di passaggio non permesso',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Risorse wireless TollSec permesse',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Escluso dalla rete',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Stato SIM traffico non permesso',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Sospeso da provider di servizi',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Sospeso a causa di limiti di utilizzo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Traffico non permesso (altro)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Connesso a rete (in sessione)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'Non connesso a rete (in sessione)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Registrazione valida (attività di rete valida)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Registrazione valida (nessuna attività di rete)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Solo registrazione GSM valida',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'Nessuna registrazione valida (attività presente nelle ultime 24 ore)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'Nessuna registrazione valida (richiesta autorizzazione nelle ultime 24 ore)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'Nessuna registrazione valida (aggiornamenti locali più di 24 ore fa)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'Nessuna registrazione valida (richiesta di autorizzazione più di 24 ore fa)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'Più di 24 ore fa (nessuna attività di rete)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'Dispositivo non attualmente connesso. Sessioni IP precedenti riuscite.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Sessione dati attualmente non riuscita',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Sessione dati riuscita in passato',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Tentativo di connessione non riuscito (credenziali non valide)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'Nessun precedente di sessioni dati riuscite',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Risorse wireless TollSec permesse',
	'SINGLE_ENDPOINT.ACTIONS': 'Azioni',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'Ottieni dettagli HLR/HSS',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Canone ricorrente',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Seleziona piano tariffario',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Conferma cambiamenti',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. Conferma del sistema',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Idoneo per l’utilizzo con questo endpoint',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		'Aggiornamento cambiamento piano tariffario riuscito',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Cambiamento del piano tariffario pianificato per ',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Cambiamento del piano tariffario pianificato',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Conferma cambiamento diritti di rete',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Una volta avviato questo cambiamento, i diritti di rete dell’endpoint selezionato saranno ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION': 'Continuare?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Oppure è possibile pianificare il cambiamento per: ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Cambiamento diritti di rete pianificato.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Cambiamento diritti di rete pianificato per ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'Al momento è impossibile effettuare il cambiamento dei diritti di rete.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'Al momento è impossibile pianificare il cambiamento dei diritti di rete.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'Stato cambiamento diritti di rete',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'Il piano tariffario attuale per l’endpoint {id} è ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Vettore roaming',
	'SINGLE_ENDPOINT.OEM': 'Sottoconti',
	'SINGLE_ENDPOINT.SERVICE_STATUS': 'Stato 360L',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE: ',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Connessione di rete',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN: ',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'Stato registrazione',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Account 360L collegati',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Modalità duale',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Dispositivo connesso',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Piano tariffario',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'Stato registrato',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Data attivazione',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': 'Esportare dati di utilizzo',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE':
		'Esportazione dati non riuscita',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'Al momento non è possibile esportare i dati di utilizzo.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Attendere la preparazione del file. A seconda del numero di record, l’operazione potrebbe richiedere del tempo.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'Non ci sono dati da mostrare per il periodo selezionato',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'Se disponibile, provare a modificare l’intervallo di date per aggiornare l’elenco',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Attivato',
	'SINGLE_ENDPOINT.TERMINATE': 'Chiuso',
	'SINGLE_ENDPOINT.DEACTIVE': 'Disattivo',
	'SINGLE_ENDPOINT.INACTIVE': 'Inattivo',
	'SINGLE_ENDPOINT.TEST': 'Test',
	'SINGLE_ENDPOINT.SUSPENDED': 'Sospeso',
	'SINGLE_ENDPOINT.SUSPEND': 'Sospendi',
	'SINGLE_ENDPOINT.INVENTORY': 'Inventario'
};
