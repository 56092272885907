import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import { EDIT_USER_GROUP_ASSIGNED_ACCOUNTS } from './constants';

export default combineReducers({
	editUserGroupAssignedAccounts: globalReducer(
		EDIT_USER_GROUP_ASSIGNED_ACCOUNTS
	)
});
