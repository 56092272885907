export default {
	'TICKETING.YES': 'Sì',
	'TICKETING.NO': 'No',
	'TICKETING.VIEW_NOTE': 'Visualizza nota',
	'TICKETING.TICKET_ID': 'ID ticket',
	'TICKETING.CATEGORY_NAME': 'Categoria',
	'TICKETING.DATE_CREATED': 'Data creazione',
	'TICKETING.CREATED_BY': 'Creato da',
	'TICKETING.TICKETING_STATUS': 'Stato creazione ticket',
	'TICKETING.LAST_UPDATE': 'Ultimo aggiornamento',
	'TICKETING.SEVERITY': 'Gravità',
	'TICKETING.SUBMISSION_LOCATION': 'Ubicazione invio',
	'TICKETING.UPDATE_NOTE': 'Aggiorna nota',
	'TICKETING.STATUS': 'Stato',
	'TICKETING.SUBMITED_BY': 'Inviato da',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': 'Il ticket riguarda un endpoint specifico? In tal caso, andare allo schermo dei dettagli di tale endpoint e cliccare sul link Ottieni assistenza per questo endpoint. In caso contrario, compilare il seguente modulo.',
	'TICKETING.ISSUE': 'Problema',
	'TICKETING.TICKET_DETAILS': 'Dettagli ticket',
	'TICKETING.CATEGORY': 'Categoria',
	'TICKETING.SELECT_CATEGORY': 'Seleziona categoria',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Sottocategoria (opzionale)',
	'TICKETING.SUBCATEGORY': 'Sottocategoria',
	'TICKETING.ROAMING_ISSUE': 'Problema roaming',
	'TICKETING.PRIORITY': 'Priorità',
	'TICKETING.ENDPOINT': 'Endpoint',
	'TICKETING.ICCID': 'ICCID',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'MSISDN',
	'TICKETING.ENDPOINT_LOCATION': 'Ubicazione endpoint',
	'TICKETING.ENDPOINT_COUNTRY': 'Paese endpoint',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Cancellare parametri endpoint',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Recupera parametri endpoint',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': 'È possibile utilizzare questa opzione per recuperare i parametri dell’endpoint inserendo i valori ICCID, IMSI o MSISDN. ',
	'TICKETING.ENDPOINT_STREET': 'Stradale endpoint',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Indirizzo stradale endpoint',
	'TICKETING.ZIP_CODE': 'Codice postale',
	'TICKETING.ENDPOINT_STATE': 'Stato endpoint',
	'TICKETING.DETAILS': 'Dettagli',
	'TICKETING.BARS_SHOWN': 'Barre visualizzate',
	'TICKETING.ENDPOINT_CITY': 'Città endpoint',
	'TICKETING.ENDPOINT_MODEL': 'Modello endpoint',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': 'È stata effettuata la risoluzione dei problemi?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Descrizione del problema',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': 'Fornire una breve descrizione del problema (max 255 caratteri)',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': 'Se necessario, fornire ulteriori dettagli (max 2000 caratteri)',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Note',
	'TICKETING.ISSUE_DETAILS': 'Dettagli problema',
	'TICKETING.CUSTOMER': 'Cliente',
	'TICKETING.ACCOUNT_NUMBER': 'Numero account',
	'TICKETING.ACCOUNT_NAME': 'Nome account',
	'TICKETING.PHONE': 'Telefono',
	'TICKETING.CONTACT_PHONE': 'Telefono contatto',
	'TICKETING.CONTACT_EMAIL': 'E-mail contatto',
	'TICKETING.ATTACH_FILE': 'Allega file',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': 'Il nome del file è troppo lungo e non può essere caricato. La lunghezza massima del nome del file è 25 caratteri.',
	'TICKETING.EMAIL': 'E-mail',
	'TICKETING.TICKETING': 'Creazione di ticket',
	'TICKETING.ADD_TICKET': 'Aggiungi ticket',
	'TICKETING.SEARCH_RESULTS': 'Risultati ricerca',
	'TICKETING.NO_TICKETS_AVAILABLE': 'Nessun ticket disponibile',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': 'Cambiamento impostazioni tabella ticket riuscito!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': 'Le impostazioni della tabella ticket sono state aggiornate.',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': 'Errore cambiamento colonne tabella ticket!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': 'Impossibile aggiornare le colonne della tabella ticket in questo momento. Riprovare.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'Modifica ticket riuscita.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': 'Errore durante modifica ticket. Riprovare più tardi.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'Il nome del file non deve contenere spazi vuoti.',
	'TICKETING.NEW_TICKET': 'Nuovo ticket',
	'TICKETING.OFF': 'Non attivo',
	'TICKETING.ON': 'Attivo',
	'TICKETING.EMAIL_NOTIFICATIONS': 'Notifiche e-mail',
	'TICKETING.CONTACT_NAME': 'Nome contatto',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Ultimo aggiornamento da',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Data/ora ultimo aggiornamento',
	'TICKETING.SOMETHING_WENT_WRONG': 'Qualcosa non ha funzionato. Riprovare.',
	'TICKETING.CANCEL_TICKET': 'Annullare ticket',
	'TICKETING.ADD_NOTE': 'Aggiungi nota',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'Invio ticket riuscito!',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': 'Impossibile inviare il ticket!',
	'TICKETING.CREATED_AT': 'Data e ora creazione',
	'TICKETING.GO_BACK_TO_TICKETING': 'Torna a creazione ticket',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': 'Problema non tecnico fatturazione',
	'TICKETING.CATEGORY_PROVISIONING': 'Provisioning',
	'TICKETING.CATEGORY_DATA': 'Dati',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Documentazione',
	'TICKETING.CATEGORY_DUPLICATE': 'Duplicato',
	'TICKETING.CATEGORY_BILLING': 'Fatturazione',
	'TICKETING.CATEGORY_NOTIFICATION': 'Notifica',
	'TICKETING.CATEGORY_OTHER': 'Altro',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': 'Richiesta miglioramento prodotto',
	'TICKETING.CATEGORY_PROJECT': 'Progetto',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Problema vendite/marketing',
	'TICKETING.CATEGORY_QUESTION': 'Domanda',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Richiesta assistenza',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Problemi tecnici',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': 'Amministrazione utente/account',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Problema di connettività',
	'TICKETING.CATEGORY_EQUIPMENT': 'Attrezzatura',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'Domande generica',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Dispositivo smarrito/rubato',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Richiesta porta',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'Relativo a prodotto',
	'TICKETING.CATEGORY_REPORTING': 'Reportistica',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Suggerimento/Raccomandazione',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Problema sito Web',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': 'In coda',
	'TICKETING.STATUS_ACTIVE': 'Attiva',
	'TICKETING.STATUS_DEFERRED': 'Differito',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'In attesa di risposta dal cliente',
	'TICKETING.STATUS_CLOSED': 'Chiuso',
	'TICKETING.STATUS_CANCEL': 'Annulla',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Risposta dal cliente',
	'TICKETING.STATUS_CLEARED': 'Risolto',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Pronto a chiusura',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'In attesa di risposta AT&T',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': 'Critico',
	'TICKETING.SEVERITY_MAJOR': 'Serio',
	'TICKETING.SEVERITY_MINOR': 'Meno importante',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': 'Canone di attivazione',
	'TICKETING.AIRTIME_USAGE': 'Utilizzo orario trasmissione',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Domande di base riguardo all’account',
	'TICKETING.BILLING_DISPUTES': 'Contestazioni fatturazione',
	'TICKETING.BILLING_FILES_ISSUES': 'Problemi file fatturazione',
	'TICKETING.BILL_RECONCILIATION': 'Riconciliazione fattura',
	'TICKETING.CUSTOMER_EDUCATION': 'Formazione cliente',
	'TICKETING.DATA_USAGE_CANADA': 'Utilizzo dati Canada',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Utilizzo dati internazionale',
	'TICKETING.DATA_USAGE_OFF_NET': 'Utilizzo dati fuori rete',
	'TICKETING.DATA_USAGE_ON_NET': 'Utilizzo dati in rete',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Fatturazione o fattura ritardate',
	'TICKETING.DISCOUNT': 'Sconto',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Richiesta di informazioni o problema riguardo a frode',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Problemi stampa fattura',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Addebito ricorrente mensile',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'Addebito una tantum - rettifica',
	'TICKETING.OTHER': 'Altro',
	'TICKETING.PAYMENT_ISSUES': 'Problemi di pagamento',
	'TICKETING.PRORATED_CHARGES': 'Addebiti ripartiti',
	'TICKETING.REACTIVATION_FEE': 'Canone di riattivazione',
	'TICKETING.SMS_TEXT_CANADA': 'SMS/Testo Canada',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'SMS/Testo internazionale',
	'TICKETING.SMS_TEXT_OFF_NET': 'SMS/Testo fuori rete',
	'TICKETING.SMS_TEXT_ON_NET': 'SMS/Testo in rete',
	'TICKETING.STATUS_INVOICE': 'Stato fattura',
	'TICKETING.TAXES_AND_SURCHARGES': 'Imposte e supplementi',
	'TICKETING.DATA': 'Dati',
	'TICKETING.MESSAGING_SMS_MMS_': 'Messaggistica (SMS/MMS)',
	'TICKETING.VOICE': 'Vocale',
	'TICKETING.CLARIFICATION': 'Precisazione',
	'TICKETING.PREPAID': 'Prepagato',
	'TICKETING.USAGE_INQUIRY': 'Richiesta informazioni sull’utilizzo',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': 'Domanda riguardo a fatturazione',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Problema relativo a fattura',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Problema relativo a piano tariffario',
	'TICKETING.USAGE_RELATED_ISSUE': 'Problema relativo a utilizzo',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': '2G e 3G combinati',
	'TICKETING.2G_ONLY': 'SOLO 2G',
	'TICKETING.3G_ONLY': 'SOLO 3G',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Connesso, ma senza passare dati',
	'TICKETING.FRAME_RELAY_PVC': 'Frame Relay/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Problema di latenza',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Roaming/Nazionale',
	'TICKETING.ROAMING_INTERNATIONAL': 'Roaming/Internazionale',
	'TICKETING.SIGNAL_ISSUES': 'Problema di segnale',
	'TICKETING.SMPP_BIND': 'Associazione SMPP',
	'TICKETING.SMS': 'SMS',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': 'Feedback',
	'TICKETING.NEW_REQUEST': 'Nuova richiesta',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': 'Compatibilità',
	'TICKETING.DEVICE_UNLOCK': 'Sblocco dispositivo',
	'TICKETING.ORDERING': 'Ordinazione',
	// TICKETING LOST/STOLEN DEVICE SUBCATEGORIES
	'TICKETING.BLOCK_DEVICE': 'Blocco dispositivo',
	'TICKETING.FRAUD_REPORT': 'Segnalazione frode',
	'TICKETING.UNBLOCK_DEVICE': 'Sbloccare dispositivo',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': 'Notifica incidente',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Manutenzione programmata',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Network Channel Interface (NCI)',
	'TICKETING.WIRELESS': 'Wireless',
	'TICKETING.WIRELINE': 'Wireline',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': 'Domanda',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': 'File batch',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Crea nuovo profilo SIM',
	'TICKETING.EQUIPMENT_INQUIRY': 'Richiesta informazioni riguardo a dispositivi',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Provisioning SIM singola',
	'TICKETING.WEB_SERVICE': 'Servizio web',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'Provisioning SIM/Funzionalità',
	'TICKETING.ACTIVATION': 'Attivazione',
	'TICKETING.BATCH_BULK_CHANGE': 'Cambiamento in batch/blocco',
	'TICKETING.CALL_FORWARDING': 'Inoltro chiamate',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Cambia piano di comunicazione',
	'TICKETING.CHANGE_RATE_PLAN': 'Cambia piano tariffario',
	'TICKETING.CHANGE_SIM_STATUS': 'Cambia stato SIM',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'Trasferire SIM tra diversi account',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Domanda riguardo a regola automazione',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Domanda riguardo a centro controllo',
	'TICKETING.COVERAGE_QUESTION': 'Domanda riguardo a copertura',
	'TICKETING.GENERAL_QUESTION': 'Domande generica',
	'TICKETING.OUTAGE_QUESTION': 'Domanda riguardo a interruzioni del servizio',
	'TICKETING.ROAMING_QUESTION': 'Domanda riguardo a roaming',
	'TICKETING.JPO_COVERAGE': 'Copertura JPO',
	//  TICKETING SALES/MARKETING ISSUE SUBCATEGORIES
	'TICKETING.CUSTOMER_INQUIRY': 'Richiesta informazioni cliente',
	'TICKETING.DEV_KIT': 'Kit sviluppo',
	'TICKETING.MARKETING_INQUIRY': 'Richiesta informazioni di marketing',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': 'Chiusura account',
	'TICKETING.API_SETUP': 'Configurazione API',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': 'Creazione/Modifica zona fatturazione',
	'TICKETING.CC_ACCESS_UPDATES': 'CC accesso/aggiornamenti',
	'TICKETING.COMMUNICATION_PLAN': 'Piano di comunicazione',
	'TICKETING.CUSTOM_APN': 'APN personalizzato',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Configurazione/Mappatura globale SIM',
	'TICKETING.GPRS_VPN': 'VPN GPRS',
	'TICKETING.LEGAL_SUBPOENA': 'Legale/citazioni',
	'TICKETING.LTE_MIGRATION': 'Migrazione LTE',
	'TICKETING.MSISDN_UPDATES': 'Aggiornamenti MSISDN',
	'TICKETING.NEW_ACCOUNT': 'Nuovo account',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Impostazione configurazione Radius',
	'TICKETING.REPORT': 'Report',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES (richiesta zona roaming)',
	'TICKETING.SECURESIM': 'SecureSIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'Ordinazione/Provisioning SIM',
	'TICKETING.TRACE': 'Traccia',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': 'Problemi applicazione',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Problema tecnico fatturazione',
	'TICKETING.CONNECTIVITY': 'Connettività',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Problema globale SIM',
	'TICKETING.JPO_FILE_ISSUE': 'Problema file JPO',
	'TICKETING.JPO_LINK_ISSUE': 'Problema link JPO',
	'TICKETING.NOC_BRIDGE': 'Bridge NOC',
	'TICKETING.CDR_FILES': 'File CDR',
	'TICKETING.JPO_REPORT': 'Report JPO',
	//  TICKETING USER/ACCOUNT ADMINISTRATION SUBCATEGORIES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': 'Aggiungi nuove opzioni funzionalità account',
	'TICKETING.DEACTIVATE_USER': 'Disattiva utente',
	'TICKETING.ID_ISSUE': 'Problema ID',
	'TICKETING.NEW_USER': 'Nuovo utente',
	'TICKETING.PASSWORD_RESET': 'Reimpostazione password',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'AT&T Control Center (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Portale auto/Apollo connesso',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise on Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	// OTHER
	'TICKETING.CRITICAL': 'Critico',
	'TICKETING.MAJOR': 'Serio',
	'TICKETING.MINOR': 'Meno importante',
	'TICKETING.LOW': 'Basso',
	'TICKETING.MEDIUM': 'Medio',
	'TICKETING.HIGH': 'Alto',
	'TICKETING.OUTAGE': 'Interruzione del servizio',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': 'IP dinamico',
	'TICKETING.FIXED_IP': 'IP fisso',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Regola automazione',
	'TICKETING.CC_ISSUE_OTHER': 'Problema CC - Altro',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Esportazione e aggiornamento batch',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Servizi di posizione/Terzi',
	'TICKETING.PROVISIONING': 'Provisioning',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Ritardo informazioni sulle sessioni',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': 'Problemi WebUI',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': 'Problema dati',
	'TICKETING.DEVICE_ISSUE': 'Problema dispositivo',
	'TICKETING.SFTP_FILE_TRANSFERS': 'sFTP/Trasferimenti file',
	'TICKETING.SMS_ISSUE': 'Problema SMS',
	'TICKETING.VOICE_ISSUE': 'Problema vocale',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': 'Rete',
	'TICKETING.RADIUS': 'Radius',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Aggiungi allegato',
	'TICKETING.NOTE': 'Nota',
	'TICKETING.LAST_UPDATED_DATE': 'Data ultimo aggiornamento ticket',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': 'L’endpoint con quel parametro non esiste.',
	// ticketing notifications
	'TICKETING.ATTACHMENT_FAIL_TITLE': 'Impossibile completare la Sua richiesta.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': 'Il ticket {ticketId} è stato creato, ma gli allegati ({attachments}) non sono stati caricati',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': 'Il ticket {ticketId} è stato modificato, ma gli allegati ({attachments}) non sono stati caricati.',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Il ticket {ticketId} è stato modificato, ma gli allegati ({attachments}) non sono stati eliminati.',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Il ticket {ticketId} è stato modificato, ma gli allegati ({deleteAttachments}) non sono stati eliminati e gli allegati ({uploadAttachments}) non sono stati caricati.'
};
