import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import TicketDetailsDesktop from './Desktop';
import TicketDetailsMobile from './Mobile';

import {
	getCategories,
	getFirstSubCategories,
	getSecondSubCategories,
	getThirdSubCategories,
	getSeverities,
	getPriorities,
	getCountries,
	getStates,
  getConfirmation,
  getHistory
} from '../../redux/options/actions';

import { resetFiles } from '../../../Shared/redux/uploadFile/actions';
import {
	filesUploadSuccess,
	fileUploadRequest
} from '../../../Shared/redux/uploadFile/selectors';

import { fileDownload } from '../../redux/ticketFileDownload/actions';
import {
	fileDownloadId,
	downloadTicketFileRequest
} from '../../redux/ticketFileDownload/selectors';

import {
	getTicket,
	editTicket,
	editTicketInit
} from '../../redux/tickets/actions';
import {
	getTicketRequest,
	getTicketFail,
	getTicketSuccess,
	editTicketRequest,
	editTicketFail,
	editTicketSuccess
} from '../../redux/tickets/selectors';
import {
	getCategoriesSuccess,
	getFirstSubCategoriesSuccess,
	getSecondSubCategoriesSuccess,
	getThirdSubCategoriesSuccess,
	getSeveritiesSuccess,
	getPrioritiesSuccess,
	getCountriesSuccess,
	getStatesSuccess,
  getConfirmationSuccess,
  getHistorySuccess,
  getHistoryRequest
} from '../../redux/options/selectors';

const mapStateToProps = (state) => ({
	ticketDetailsRequest: getTicketRequest(state),
	ticketDetailsFail: getTicketFail(state),
	ticketDetails: getTicketSuccess(state),
	categories: getCategoriesSuccess(state),
	firstSubCategories: getFirstSubCategoriesSuccess(state),
	secondSubCategories: getSecondSubCategoriesSuccess(state),
	thirdSubCategories: getThirdSubCategoriesSuccess(state),
	severities: getSeveritiesSuccess(state),
	priorities: getPrioritiesSuccess(state),
	countries: getCountriesSuccess(state),
  states: getStatesSuccess(state),
  historyRequest: getHistoryRequest(state),
  history: getHistorySuccess(state),
	formValues: getFormValues('TicketDetailsForm')(state),
	confirmation: getConfirmationSuccess(state),
	editTicketRequest: editTicketRequest(state),
	editTicketFail: editTicketFail(state),
	editTicketSuccess: editTicketSuccess(state),
	fileSuccess: filesUploadSuccess(state),
	filesUploading: fileUploadRequest(state),
	fileDownloadId: fileDownloadId(state),
	fileDownload: downloadTicketFileRequest(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getTicketDetails: (params) => dispatch(getTicket(params)),
	pushBack: () => dispatch(localizedRoute('/support/ticketing')),
	getCategories: () => dispatch(getCategories()),
	getFirstSubCategories: (params) => dispatch(getFirstSubCategories(params)),
	getSecondSubCategories: (params) => dispatch(getSecondSubCategories(params)),
	getThirdSubCategories: (params) => dispatch(getThirdSubCategories(params)),
	getSeverities: () => dispatch(getSeverities()),
	getPriorities: (params) => dispatch(getPriorities(params)),
	getCountries: () => dispatch(getCountries()),
	getStates: (params) => dispatch(getStates(params)),
  getConfirmation: (params) => dispatch(getConfirmation(params)),
  getHistory: (params) => dispatch(getHistory(params)),
	editTicket: (params, formatMessage) => dispatch(editTicket(params, formatMessage)),
	editTicketInit: () => dispatch(editTicketInit()),
	resetFiles: () => dispatch(resetFiles()),
	fileDownloadTicket: (data) => dispatch(fileDownload(data))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(TicketDetailsDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(TicketDetailsMobile);
