import React, { PureComponent } from 'react';
import {
	func,
	object,
	string,
	array,
} from 'prop-types';
import { find, isEqual } from 'lodash';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { transpose } from '../../../utils/helpers';
import StepFirstRatePlanTable from './StepFirstRatePlanTable';

import styles from '../RatePlanChangeStepper.scss';

const EndpointsRWComponent = UserAllowedToAccess([
	'mnc.endpoints_rw',
	'mnc.endpoints.rateplans_rw',
]);

export default class StepFirst extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			newRatePlan: {},
			currentRatePlan: {},
			transposedData: {},
			transposed: false,
		};
	}

	componentDidMount() {
    const {
			context,
			ratePlans,
	    getRatePlans,
			endpoint,
			updateRatePlanStatusInit,
	    ratePlanId,
		} = this.props;

		if (context) {
			this.setFooter(context);
		}
		if (!ratePlans.length) {
			getRatePlans(endpoint.m2mAccountId);
		} else {
			this.setRatePlan(ratePlans, ratePlanId);
		}

		updateRatePlanStatusInit();
	}

	componentDidUpdate(prevProps, prevState) {
    const { context, ratePlanId, ratePlans } = this.props;
    const { newRatePlan, transposed } = this.state;

		if (
			context &&
			!(
				isEqual(ratePlanId, prevProps.ratePlanId) &&
				isEqual(newRatePlan, prevState.newRatePlan)
			)
		) {
			setTimeout(() => this.setFooter(context), 0);
		}
		if (ratePlans && !transposed) {
			this.setTransposedData(ratePlans);
		}
	}

	setRatePlan = (ratePlans, ratePlanId) => {
		const ratePlan = find(
			ratePlans,
			(plan) => plan.ratePlan.id === ratePlanId,
		);

		if (ratePlan) {
			this.setState({
				newRatePlan: ratePlan,
				currentRatePlan: ratePlan.ratePlan,
			});
		}
	};

	setTransposedData = (ratePlans) => this.setState({
		transposedData: transpose(ratePlans),
		transposed: true,
	})

	onNext = () => {
    const { onNext } = this.props;
    const { newRatePlan } = this.state;

		onNext({ newRatePlan });
	};

	handleSelectRatePlan = (planId) => {
    const { ratePlans } = this.props;

		this.setState({
			newRatePlan: find(
				ratePlans,
				(plan) => plan.ratePlan.id === planId
			)
		});
	};

	checkButton = () => {
    const { ratePlanId } = this.props;
    const { newRatePlan } = this.state;

    return !!(
			!newRatePlan.ratePlan ||
      (newRatePlan.ratePlan &&
        newRatePlan.ratePlan.id === ratePlanId)
		);
  };

	setFooter = (context) => {
    const { onClose } = this.props;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage
						id="CANCEL"
						defaultMessage="Cancel"
					/>}
					dataSpec="cancel-button"
				/>
				<EndpointsRWComponent>
					<Button
						variant="primary"
						onClick={this.onNext}
						disabled={this.checkButton()}
						label={<FormattedMessage
							id="CONTINUE"
							defaultMessage="Continue"
						/>}
						dataSpec="continue-button"
					/>
				</EndpointsRWComponent>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
    const { ratePlans, endpointId, ratePlanId } = this.props;
		const { currentRatePlan, newRatePlan, transposedData } = this.state;
		const selectedRatePlanId =
			newRatePlan.ratePlan && newRatePlan.ratePlan.id;
		const ratePlanName = find(
			ratePlans,
			(plan) => plan.ratePlan.id === ratePlanId
		);

		return (
			<div data-spec="first-step" className={styles.step}>
				<div data-spec="ds-modal-body">
					<div className={styles.select_field_label}>
						<FormattedMessage
							id="CHANGE_RATE_PLAN"
							defaultMessage="Change Rate Plan"
						/>
					</div>
					<div className={styles.select_field_label_text}>
						<FormattedMessage
							id="SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID"
							defaultMessage="Current Rate Plan for Endpoint ID {id} is "
							values={{ id: endpointId }}
						/>
						<span
							className={cn(styles.select_field_label_text, styles.rate_plan)}
						>
							{ratePlanName && ratePlanName.ratePlan.name}
						</span>
					</div>
					{<StepFirstRatePlanTable
						transposedData={transposedData}
						ratePlans={ratePlans}
						styles={styles}
						onClick={this.handleSelectRatePlan}
						selected={selectedRatePlanId}
						currentRatePlan={currentRatePlan}
					/>}
				</div>
			</div>
		);
	}
}

StepFirst.propTypes = {
	onClose: func,
	onNext: func,
	getRatePlans: func,
	updateRatePlanStatusInit: func,
	endpointId: string,
	ratePlanId: string,
	ratePlans: array,
	endpoint: object,
	context: object
};
StepFirst.defaultProps = {
	onClose: undefined,
	onNext: undefined,
	getRatePlans: undefined,
	updateRatePlanStatusInit: undefined,
	endpointId: '',
	ratePlanId: '',
	ratePlans: [],
	endpoint: {},
	context: {},
};
