import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_RESTRAINTS,
	ADD_RESTRAINTS,
	UPDATE_RESTRAINTS,
	GET_RESTRAINT_PERIODS
} from './constants';

export default combineReducers({
	getRestraints: globalReducer(GET_RESTRAINTS),
	addRestraints: globalReducer(ADD_RESTRAINTS),
	updateRestraints: globalReducer(UPDATE_RESTRAINTS),
	getRestraintPeriods: globalReducer(GET_RESTRAINT_PERIODS)
});
