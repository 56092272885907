import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import {
	GET_SIM_ORDERS,
	GET_SIM_ORDER,
	ADD_SIM_ORDER,
	EDIT_SIM_ORDER,
	GET_SIM_ORDERING_FILTERS,
	GET_SIM_ORDER_STATUSES
} from './constants';
import {
	getSimOrders as getSimOrdersService,
	getSimOrder as getSimOrderService,
	addSimOrder as addSimOrderService,
	editSimOrder as editSimOrderService,
	getSimOrderingFilters as getSimOrderingFiltersService,
	getSimOrderStatuses as getSimOrderStatusesService
} from '../../services/SimOrderingService';

// GET SIM ORDERS
export const getSimOrders = (params) =>
	globalAction(GET_SIM_ORDERS, getSimOrdersService, params);

//  GET SIM ORDER
export const getSimOrder = (id, params) =>
	globalAction(GET_SIM_ORDER, getSimOrderService, { id, params });

export const getSimOrderInit = () => actionInit(GET_SIM_ORDER);

//  ADD SIM ORDER
export const addSimOrder = (data) =>
	globalAction(ADD_SIM_ORDER, addSimOrderService, data, {
		redirect: '/sim-ordering',
		notify: 'add-sim-order',
		notifyMessageSuccess: 'ADD_SIM_ORDER_SUCCESS',
		notifyMessageError: 'ADD_SIM_ORDER_ERROR'
	});

//  EDIT SIM ORDER
export const editSimOrder = (params) =>
	globalAction(
		EDIT_SIM_ORDER,
		editSimOrderService,
		params,
		{
			redirect: params.system ? '/company-sim-ordering' : '/sim-ordering',
			notify: 'edit-sim-order',
			notifyMessageSuccess: 'EDIT_SIM_ORDER_SUCCESS',
			notifyMessageError: 'EDIT_SIM_ORDER_ERROR'
		}
	);

// GET SIM ORDERING FILTERS
export const getSimOrderingFilters = () =>
	globalAction(GET_SIM_ORDERING_FILTERS, getSimOrderingFiltersService);

export const getSimOrderingFiltersInit = () =>
	actionInit(GET_SIM_ORDERING_FILTERS);

// GET SIM ORDER STATUSES
export const getSimOrderStatuses = () =>
	globalAction(GET_SIM_ORDER_STATUSES, getSimOrderStatusesService);
