import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getAutomationZones = (params) =>
	ApiService.get(`/automation/automation-zones?${qs.stringify(params)}`);

export const initiateAutomationZonesFileUpload = (id, data) =>
	ApiService.patch(`/batch/file-upload/${id}`, data)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const checkAutomationZonesPoolStatus = (id) =>
	ApiService.get(`/batch/file-upload/${id}`)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});

export const automationZonesPollForFile = (id, retries, delay) => {
	// eslint-disable-next-line no-plusplus
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				// eslint-disable-next-line no-unused-expressions
				checkAutomationZonesPoolStatus(id)
					.then((response) => {
						if (response.status == 3) {
							resolve(automationZonesPollForFile(id, retries, delay));
						} else if (response.status == 5) {
							resolve(response);
						} else {
							reject({ error: response });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
	return new Promise(function (_resolve, reject) {
		reject({ error: [] });
	});
};

export const getUploadTemplate = () =>
	ApiService.get(
		'/automation/zones/templates'
	);
