import React, { Component } from 'react';
import { object, bool, string, func } from 'prop-types';
import {
	FormattedMessage,
} from 'react-intl';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import {
	find,
	isEmpty,
} from 'lodash';

import Button from '../../../../lib/DigitalComponents/Button';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Loader from '../../../../lib/DigitalComponents/Loader';
import GroupButton from '../GroupButton';
import SMSTabView from '../../views/SMSTabView';

import styles from './DiagnosticGdsp.scss';

const Check = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');
const NCheck = ImageSelector(CurrentContext.theme, 'svgs/close.svg');
const GSucces = ImageSelector(
	CurrentContext.theme,
	'svgs/diagnostic-success.svg',
);
const GError = ImageSelector(CurrentContext.theme, 'svgs/diagnostic-error.svg');
const GDots = ImageSelector(CurrentContext.theme, 'svgs/diagnostic-dots.svg');

export default class DiagnosticGdsp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedButton: 0,
			refresh: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { refresh } = this.state;

		if (prevState.refresh !== refresh) {
			this.refresh();
		}
	}

	componentWillUnmount() {
		const { setDiagnosticGdspStateToInit } = this.props;

		setDiagnosticGdspStateToInit();
	}

	refresh = () => this.setState({
		refresh: false,
	});

	getDiagnosticGdspFunction = () => {
		const { getDiagnosticGdsp, iccid } = this.props;

		getDiagnosticGdsp(iccid);
		this.setState({ selectedButton: 1 });
	};

	render() {
		const {
			iccid,
			isPending,
			isSuccess,
			endpoint,
			data,
			messages,
			user,
		} = this.props;
		const { refresh, selectedButton } = this.state;

		if (isPending) {
			return (
				<FormattedMessage
					id="FETCH_DATA_LOADING_MESSAGE"
					defaultMessage="Please wait while we load the data"
					data-spec="loader"
				>
					{(formattedValue) => (
						<Loader
							msg={formattedValue}
						/>
					)}
				</FormattedMessage>
			);
		}

		const gdspData = !isEmpty(data)
			? {
				carrier: !!endpoint.networkOperator,
				country:
					!!data.GDSPSessionData.deviceDetailsBasic &&
					!!data.GDSPSessionData.deviceDetailsBasic.country,
				m2mPlatformStatus:
					!!data.GDSPSessionData.deviceDetailsBasic &&
					!!data.GDSPSessionData.deviceDetailsBasic.state,
				provisioned: !!find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'provisioningStatus'
				]),
				ragStatus: !!find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'ragStatus'
				]),
				provisionedTimeStamp: find(
					data.GDSPSessionData.deviceInformationList,
					['name', 'provisionedAtTimestamp']
				),
				dataEnabled: false,
				voiceEnabled: false,
				session: find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'sessionActive'
				]),
				sessions:
					!!find(data.GDSPSessionData.deviceInformationList, [
						'name',
						'sessionActive'
					]) &&
					find(data.GDSPSessionData.deviceInformationList, [
						'name',
						'sessionActive'
					]).value === 'Y',
				time: find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'provisionedAtTimestamp'
				]),
				sessionIp: find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'sessionLastIpAddress'
				]),
				sessionStartedTime: find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'sessionLastStartedTimestamp'
				]),
				ragStatusColor: find(data.GDSPSessionData.deviceInformationList, [
					'name',
					'ragStatus'
				]),
				diagnosticData: !isEmpty(data.GDSPDiagnosticData)
					? data.GDSPDiagnosticData.status
					: [],
				dataEnabledData: [],
				voiceEnabledData: []
			}
			: {};

		if (!isEmpty(gdspData) && gdspData.diagnosticData.length > 0) {
			gdspData.diagnosticData.forEach((item) => {
				if (find(item.param, ['value', 'SGSN KNOWN'])) {
					gdspData.dataEnabledData = item.param;
					gdspData.dataEnabled = true;
				}
				if (find(item.param, ['value', 'VLR KNOWN'])) {
					gdspData.voiceEnabledData = item.param;
					gdspData.voiceEnabled = true;
				}
			});
		}

		return (
			<div
				data-spec="diagnostic-gdsp-wrapper"
				className={styles.diagnostics_wrapper}
			>
				<header className={styles.header}>
					<GroupButton>
						<Button
							dataSpec="diagnostic-button"
							variant={selectedButton === 1 ? 'primary' : 'outline-primary'}
							onClick={this.getDiagnosticGdspFunction}
							label={<FormattedMessage
								id="DIAGNOSTIC_BUTTON"
								defaultMessage="Start Diagnostics"
							/>}
						/>
						{isUserAllowedToAccess([
							'mnc.endpoints.smstab_rw',
							'mnc.endpoints.smstab_ro',
						], user) ? (
							<Button
								dataSpec="sms-button"
								variant={selectedButton === 2 ? 'primary' : 'outline-primary'}
								onClick={() => this.setState({
									selectedButton: 2,
									refresh: true,
								})}
								label={<FormattedMessage
									id="SEND_SMS_TO_ENDPOINT"
									defaultMessage="Send SMS to Endpoint"
								/>}
								disabled={false}
							/>
						) : null}
					</GroupButton>
				</header>
				{selectedButton === 1 && isSuccess && (
					<main className={styles.main}>
						<div
							data-spec="diagnostic-ccip-details"
							className={styles.diagnostics_details}
						>
							<div className={styles.test_wrapper}>
								<div className={styles.graphics}>
									{gdspData.carrier && gdspData.country ? (
										<>
											<GSucces data-spec="success" className={styles.success} />
											<GDots className={cn(styles.dots)} />
										</>
									) : (
										<>
											<GError data-spec="success" className={styles.error} />
											<GDots className={cn(styles.dots, styles.dots_error)} />
										</>
									)}
								</div>
								<div className={styles.data}>
									<div
										className={cn(
											styles.title,
											(
												!gdspData.carrier || !gdspData.country
											) && styles.error
										)}
									>
										<FormattedMessage
											id="SINGLE_ENDPOINT.CARRIER_INFORMATION"
											defaultMessage="Carrier Information"
										/>
									</div>
									<div className={styles.data_name}>
										<span>
											<FormattedMessage
												id="SINGLE_ENDPOINT.CARRIER"
												defaultMessage="Carrier"
											/>
											:
											{endpoint.networkOperator}
										</span>
										<>
											{gdspData.carrier ? (
												<Check data-tip={'test'} data-for="carrier" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="carrier"
												/>
											)}
										</>
									</div>
									<div className={styles.data_country}>
										<span>
											<FormattedMessage
												id="SINGLE_ENDPOINT.COUNTRY"
												defaultMessage="Country"
											/>
											:
											{gdspData.country &&
											data.GDSPSessionData.deviceDetailsBasic.country}
										</span>
									</div>
								</div>
							</div>
							<div className={styles.test_wrapper}>
								<div className={styles.graphics}>
									{gdspData.m2mPlatformStatus ? (
										<>
											<GSucces data-spec="success" className={styles.success} />
											<GDots className={cn(styles.dots)} />
										</>
									) : (
										<>
											<GError data-spec="success" className={styles.error} />
											<GDots className={cn(styles.dots, styles.dots_error)} />
										</>
									)}
								</div>
								<div className={styles.data}>
									<div
										className={cn(
											styles.title,
											!gdspData.m2mPlatformStatus && styles.error
										)}
									>
										<FormattedMessage
											id="SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION"
											defaultMessage="M2M Platform Status Description"
										/>
									</div>
									<div className={styles.data_name}>
										{gdspData.m2mPlatformStatus ? (
											<span>
												<FormattedMessage
													id={`SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_${data.GDSPSessionData.deviceDetailsBasic.state}`}
													defaultMessage="No M2M Platform Status"
												/>
											</span>
										) : (
											<span>
												<FormattedMessage
													id="SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE"
													defaultMessage="Not Available"
												/>
											</span>
										)}
										<>
											{gdspData.m2mPlatformStatus ? (
												<Check data-tip={'test'} data-for="m2mPlatformStatus" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="m2mPlatformStatus"
												/>
											)}
										</>
									</div>
								</div>
							</div>
							<div className={styles.test_wrapper}>
								<div className={styles.graphics}>
									{gdspData.provisioned && gdspData.ragStatus ? (
										<>
											<GSucces data-spec="success" className={styles.success} />
											<GDots className={cn(styles.dots)} />
										</>
									) : (
										<>
											<GError data-spec="success" className={styles.error} />
											<GDots className={cn(styles.dots, styles.dots_error)} />
										</>
									)}
								</div>
								<div className={styles.data}>
									<div
										className={cn(
											styles.title,
											(
												!gdspData.provisioned || !gdspData.ragStatus
											) && styles.error
										)}
									>
										<FormattedMessage
											id="SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION"
											defaultMessage="Endpoint Provisioning Information"
										/>
									</div>
									<div className={styles.data_name}>
										<span>
											<FormattedMessage
												id="SINGLE_ENDPOINT.PROVISIONED"
												defaultMessage="Provisioned"
											/>
										</span>
										<>
											{gdspData.provisioned ? (
												<Check data-tip={'test'} data-for="provisioned" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="provisioned"
												/>
											)}
											{gdspData.provisionedTimeStamp && (
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="provisioned"
												>
													<div className={styles.tooltip_data}>
														<div>
															<FormattedMessage
																id="SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME"
																defaultMessage="Provisioned Date & Time {date}"
																values={{ date: gdspData.provisionedTimeStamp.value }}
															/>
														</div>
													</div>
												</ReactTooltip>
											)}
										</>
									</div>
									<div className={styles.data_name}>
										<span className={styles.ragWrapper}>
											<FormattedMessage
												id="SINGLE_ENDPOINT.REGISTRATION_STATUS"
												defaultMessage="Registration Status"
											/>
											<span
												className={cn(
													styles.ragDot,
													gdspData.ragStatus &&
													gdspData.ragStatusColor &&
													styles[`${gdspData.ragStatusColor.value.toLowerCase()}`]
												)}
											/>
										</span>
										<>
											{gdspData.ragStatus ? (
												<Check data-tip={'test'} data-for="ragStatus" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="ragStatus"
												/>
											)}
										</>
									</div>
								</div>
							</div>
							<div className={styles.test_wrapper}>
								<div className={styles.graphics}>
									{gdspData.sessions &&
									gdspData.voiceEnabled &&
									gdspData.dataEnabled ? (
										<>
											<GSucces data-spec="success" className={styles.success} />
											<GDots className={cn(styles.dots)} />
										</>
									) : (
										<>
											<GError data-spec="success" className={styles.error} />
											<GDots className={cn(styles.dots, styles.dots_error)} />
										</>
									)}
								</div>
								<div className={styles.data}>
									<div
										className={cn(
											styles.title,
											(
												!gdspData.sessions ||
												!gdspData.voiceEnabled ||
												!gdspData.dataEnabled
											) &&
											styles.error
										)}
									>
										<FormattedMessage
											id="SINGLE_ENDPOINT.NETWORK_CONNECTIONS"
											defaultMessage="Network Connections"
										/>
									</div>
									<div className={styles.data_name}>
										<span>
											<FormattedMessage
												id="SINGLE_ENDPOINT.VOICE_ENABLED"
												defaultMessage="Voice Enabled"
											/>
										</span>
										<>
											{gdspData.voiceEnabled ? (
												<Check data-tip={'test'} data-for="voiceEnabled" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="voiceEnabled"
												/>
											)}
											{gdspData.voiceEnabled
											&& gdspData.voiceEnabledData.length
											> 0
											&& (
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="voiceEnabled"
												>
													<div className={styles.tooltip_data}>
														<div>
															{gdspData.voiceEnabledData.map((item, index) => (
																<div
																	data-spec={`gdsm-tooltip-voice-${index}`}
																	key={item.name}
																	className={cn(
																		styles.data,
																		index === 1 && styles.break_lines
																	)}
																>
																	{`${item.name.toLowerCase()} ${
																		item.value
																			? `: ${item.value.toLowerCase()}`
																			: ''
																	}`}
																</div>
															))}
														</div>
													</div>
												</ReactTooltip>
											)}
										</>
									</div>
									<div className={styles.data_name}>
										<span>
											<FormattedMessage
												id="SINGLE_ENDPOINT.DATA_ENABLED"
												defaultMessage="Data Enabled"
											/>
										</span>
										<>
											{gdspData.dataEnabled ? (
												<Check data-tip={'test'} data-for="dataEnabled" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="dataEnabled"
												/>
											)}
											{gdspData.dataEnabled
											&& gdspData.dataEnabledData.length
											> 0
											&& (
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="dataEnabled"
												>
													<div className={styles.tooltip_data}>
														<div>
															{gdspData.dataEnabledData.map((item, index) => (
																<div
																	data-spec={`gdsm-tooltip-data-${index}`}
																	key={item.name}
																	className={cn(
																		styles.data,
																		index === 1 && styles.break_lines
																	)}
																>
																	{`${item.name.toLowerCase()} ${
																		item.value
																			? `: ${item.value.toLowerCase()}`
																			: ''
																	}`}
																</div>
															))}
														</div>
													</div>
												</ReactTooltip>
											)}
										</>
									</div>
								</div>
							</div>
							<div className={styles.test_wrapper}>
								<div className={styles.graphics}>
									{gdspData.sessions ? (
										<>
											<GSucces data-spec="success" className={styles.success} />
										</>
									) : (
										<>
											<GError data-spec="success" className={styles.error} />
										</>
									)}
								</div>
								<div className={styles.data}>
									<div className={styles.data_name}>
										<span>
											<FormattedMessage
												id="SINGLE_ENDPOINT.SESSIONS"
												defaultMessage="Sessions"
											/>
										</span>
										<>
											{gdspData.sessions ? (
												<Check data-tip={'test'} data-for="sessions" />
											) : (
												<NCheck
													className={styles.ncheck}
													data-tip={'test'}
													data-for="sessions"
												/>
											)}
											{(
												gdspData.sessionIp || gdspData.sessionStartedTime
											) && (
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id="sessions"
												>
													<div className={styles.tooltip_data}>
														{gdspData.sessionIp && (
															<div className={styles.data}>
																{`${gdspData.sessionIp.name}: ${gdspData.sessionIp.value}`}
															</div>
														)}
														{gdspData.sessionStartedTime && (
															<div className={styles.data}>
																{`${gdspData.sessionStartedTime.name}: ${gdspData.sessionStartedTime.value}`}
															</div>
														)}
													</div>
												</ReactTooltip>
											)}
										</>
									</div>
								</div>
							</div>
						</div>
						<div
							data-spec="endpoint-data"
							className={styles.registration_details}
						/>
					</main>
				)}
				{!refresh && selectedButton === 2 && (
					<SMSTabView
						messages={messages}
						id={iccid}
						m2mAccountId={endpoint.m2mAccountId}
					/>
				)}
			</div>
		);
	}
}

DiagnosticGdsp.propTypes = {
	iccid: string,
	endpoint: object,
	isPending: bool,
	isSuccess: bool,
	getDiagnosticGdsp: func,
	data: object,
	setDiagnosticGdspStateToInit: func,
	messages: object,
	user: object,
};
DiagnosticGdsp.defaultProps = {
	iccid: '',
	endpoint: {},
	isPending: false,
	isSuccess: false,
	getDiagnosticGdsp: undefined,
	data: {},
	setDiagnosticGdspStateToInit: undefined,
	messages: {},
	user: {},
};
