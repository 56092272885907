import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import Loader from '../../../../lib/DigitalComponents/Loader';
import Table from '../../../../lib/DigitalComponents/Table';
import NoData from '../../../Shared/views/NoDataView';

import { getDateFormat } from '../../../../utils/constants';
import { ruleHistoryTableHeader } from '../../utils/tableHeaders';

import RuleHistoryFiltersModal from '../RuleHistoryFiltersModal';

import styles from './RuleHistory.scss';

class RuleHistory extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			rule: null,
			ruleFilterModalOpened: false
		};
	}

	componentDidMount() {
		this.getHistory();
	}

	getHistory = () => {
		const { id, getRuleHistory } = this.props;
		const data = {
			id,
			params: {
				additionalParams: {
					dataSort: 'createdAt desc',
					include: [
						'AutomationCategory',
						'AutomationPlatform',
						'AutomationTrigger',
						'AutomationRuleFrameActionValueAudits',
						'AutomationRuleFrameActionValueAudits.AutomationFrame',
						'AutomationAction',
						'AutomationAction.AutomationActionType',
						'AutomationRuleStatus',
						'AutomationRuleType',
						'AutomationRuleAuditAction',
						'AutomationFollowUpAction',
						'AutomationFollowUpAction.AutomationActionType',
						'AutomationRuleFrameActionValueAudits.AutomationFrameUnit'
					]
				}
			}
		};
		getRuleHistory(data);
	};

	renderUser = (val) => <div className={styles.user_name}>{val.creatorId}</div>;

	renderDate = (val) => <div>{getDateFormat(val.createdAt)}</div>;

	renderAction = (val) => (
		<div>
			{val.automationRuleAuditAction && val.automationRuleAuditAction.name}
		</div>
	);

	renderPlatform = (val) => (
		<div>{val.automationPlatform && val.automationPlatform.name}</div>
	);

	renderCategory = (val) => (
		<div>{val.automationCategory && val.automationCategory.name}</div>
	);

	renderTrigger = (val) => (
		<div>{val.automationTrigger && val.automationTrigger.name}</div>
	);

	renderIf = (val) => (
		<div>
			{val.automationRuleFrameActionValueAudits &&
				val.automationRuleFrameActionValueAudits[0] &&
				val.automationRuleFrameActionValueAudits[0].automationFrame &&
				val.automationRuleFrameActionValueAudits[0].automationFrame.name}
		</div>
	);

	renderThreshold = (val) => {
		const frameValue1 =
			val.automationRuleFrameActionValueAudits &&
			val.automationRuleFrameActionValueAudits[0] &&
			val.automationRuleFrameActionValueAudits[0].frameValue;
		const frameValue2 =
			val.automationRuleFrameActionValueAudits &&
			val.automationRuleFrameActionValueAudits[0] &&
			val.automationRuleFrameActionValueAudits[0].frameValue2;
		return (
			<div data-spec="history-threshold">
				{frameValue1 ? (
					<div>
						{frameValue2 ? (
							<span>{`${frameValue1} - ${frameValue2}`}</span>
						) : (
							<span>{frameValue1}</span>
						)}
						{val.automationRuleFrameActionValueAudits[0]
							.automationFrameUnit && (
							<span>
								{` (${val.automationRuleFrameActionValueAudits[0].automationFrameUnit.name})`}
							</span>
						)}
					</div>
				) : (
					<div>-</div>
				)}
			</div>
		);
	};

	renderRuleAction = (val) => (
		<div>
			{val.automationAction &&
				val.automationAction.automationActionType &&
				val.automationAction.automationActionType.name}
		</div>
	);

	renderFilters = (val) => {
		const accountIdFilter =
			val.automationTrigger &&
			val.automationTrigger.hasAccountId &&
			val.filterAccountId;
		const baseRatePlanFilter =
			val.automationTrigger &&
			val.automationTrigger.hasBaseRatePlan &&
			val.baseRatePlanId;
		const customFieldsFilter =
			val.automationTrigger &&
			val.automationTrigger.hasCustomFields &&
			(val.customField1 ||
				val.customField2 ||
				val.customField3 ||
				val.customField4 ||
				val.customField5 ||
				val.customField6 ||
				val.customField7 ||
				val.customField8 ||
				val.customField9 ||
				val.customField10);
		const customerIdFilter =
			val.automationTrigger &&
			val.automationTrigger.hasCustomerId &&
			val.customerId;
		const ratePlanIdFilter =
			val.automationTrigger &&
			(val.automationTrigger.hasRatePlanId ||
				val.automationTrigger.hasPrepaidRatePlanId) &&
			val.ratePlanId;
		const ratePlanTypeFilter =
			val.automationTrigger &&
			val.automationTrigger.hasRatePlanId &&
			val.ratePlanType;
		const statusFilter =
			val.automationTrigger && val.automationTrigger.hasStatus && val.simState;
		return (
			<div data-spec="rule-history-filters">
				{accountIdFilter ||
				baseRatePlanFilter ||
				customFieldsFilter ||
				customerIdFilter ||
				ratePlanIdFilter ||
				ratePlanTypeFilter ||
				statusFilter ? (
					<span
						className={styles.clickable}
						onClick={() => this.openHistoryFiltersModal(val)}
					>
						View Filters
					</span>
				) : (
					<span>-</span>
				)}
			</div>
		);
	};

	renderFollowUpAction = (val) => (
		<div>
			{(val.automationFollowUpAction &&
				val.automationFollowUpAction.automationActionType &&
				val.automationFollowUpAction.automationActionType.name) ||
				'-'}
		</div>
	);

	renderRuleName = (val) => <div>{val.name}</div>;

	getOptions = () => {
		const options = {
			tableOptions: {
				enableTableGrabbing: true,
				firstColumnMarked: true,
				fixedFirstColumn: true
			},
			header: ruleHistoryTableHeader,
			customComponents: {
				user: {
					type: 'custom',
					component: (val) => this.renderUser(val)
				},
				date: {
					type: 'custom',
					component: (val) => this.renderDate(val)
				},
				action: {
					type: 'custom',
					component: (val) => this.renderAction(val)
				},
				platform: {
					type: 'custom',
					component: (val) => this.renderPlatform(val)
				},
				category: {
					type: 'custom',
					component: (val) => this.renderCategory(val)
				},
				trigger: {
					type: 'custom',
					component: (val) => this.renderTrigger(val)
				},
				if: {
					type: 'custom',
					component: (val) => this.renderIf(val)
				},
				threshold: {
					type: 'custom',
					component: (val) => this.renderThreshold(val)
				},
				rule_action: {
					type: 'custom',
					component: (val) => this.renderRuleAction(val)
				},
				filters: {
					type: 'custom',
					component: (val) => this.renderFilters(val)
				},
				follow_up_action: {
					type: 'custom',
					component: (val) => this.renderFollowUpAction(val)
				},
				rule_name: {
					type: 'custom',
					component: (val) => this.renderRuleName(val)
				}
			}
		};
		return options;
	};

	openHistoryFiltersModal = (rule) => {
		const { anyModalOpened } = this.props;
		anyModalOpened();
		this.setState({
			ruleFilterModalOpened: true,
			rule
		});
	};

	closeHistoryFiltersModal = () => {
		const { anyModalClosed } = this.props;
		anyModalClosed();
		this.setState({
			ruleFilterModalOpened: false
		});
	};

	render() {
		const { ruleHistoryRequest, messages, ruleHistory } = this.props;
		const { rule, ruleFilterModalOpened } = this.state;
		return (
			<div data-spec="rule-history" className={styles.rule_history}>
				{ruleHistoryRequest && <Loader />}
				{ruleFilterModalOpened && (
					<RuleHistoryFiltersModal
						show
						onClose={this.closeHistoryFiltersModal}
						rule={rule}
					/>
				)}
				{!ruleHistoryRequest && ruleHistory && (
					<Table
						messages={messages}
						data={ruleHistory.resultList}
						options={this.getOptions()}
					/>
				)}
				{!ruleHistoryRequest && ruleHistory && ruleHistory.totalCount === 0 && (
					<NoData
						table
						title={
							<FormattedMessage
								id="AUTOMATION_RULE_HISTORY.NO_DATA_TITLE"
								defaultMessage="No Rule History Available"
							/>
						}
						subtitle={
							<FormattedMessage
								id="AUTOMATION_RULE_HISTORY.NO_DATA_MESSAGE"
								defaultMessage="There is No Rule History to show you right now"
							/>
						}
					/>
				)}
			</div>
		);
	}
}

const { string, bool, object, func } = PropTypes;

RuleHistory.propTypes = {
	id: string,
	ruleHistoryRequest: bool,
	ruleHistory: object,
	messages: object,
	getRuleHistory: func,
	anyModalOpened: func,
	anyModalClosed: func
};

export default RuleHistory;
