import { handleActions } from 'redux-actions';

import { Status } from 'utils/constants';

import {
	TICKET_FILE_DOWNLOAD_REQUEST,
	TICKET_FILE_DOWNLOAD_SUCCESS,
	TICKET_FILE_DOWNLOAD_FAIL
} from '../constants';

export const initialState = {
	status: Status.INIT,
	fileId: null,
	error: null
};

export default handleActions(
	{
		[TICKET_FILE_DOWNLOAD_REQUEST]: (state) => ({
				...state,
				status: Status.PENDING,
				error: null
			}),
		[TICKET_FILE_DOWNLOAD_SUCCESS]: (state, action) => ({
				...state,
				status: Status.DONE,
				fileId: action.payload,
				error: null
			}),
		[TICKET_FILE_DOWNLOAD_FAIL]: (state, action) => ({
				...state,
				status: Status.FAIL,
				fileId: null,
				error: action.payload
			})
	},
	initialState
);
