// constants for analytical invoices
export const GET_ANALYTICS_INVOICES = 'GET_ANALYTICS_INVOICES';
export const GET_ANALYTICS_INVOICES_FILTERS = 'GET_ANALYTICS_INVOICES_FILTERS';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_DETAILS_INIT = 'GET_INVOICE_DETAILS_INIT';
export const EXPORT_INVOICE = 'EXPORT_INVOICE';
export const GET_INVOICE_ATTACHMENTS = 'GET_INVOICE_ATTACHMENTS';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_FILTERS = 'GET_FILTERS';
export const PATCH_INVOICE = 'PATCH_INVOICE';
export const PATCH_INVOICE_INIT = 'PATCH_INVOICE_INIT';
export const PATCH_INVOICE_REQUEST = 'PATCH_INVOICE_REQUEST';
export const PATCH_INVOICE_FAIL = 'PATCH_INVOICE_FAIL';
export const PATCH_INVOICE_SUCCESS = 'PATCH_INVOICE_SUCCESS';
export const POST_INVOICE = 'POST_INVOICE';
export const POST_INVOICE_INIT = 'POST_INVOICE_INIT';
export const POST_INVOICE_REQUEST = 'POST_INVOICE_REQUEST';
export const POST_INVOICE_FAIL = 'POST_INVOICE_FAIL';
export const POST_INVOICE_SUCCESS = 'POST_INVOICE_SUCCESS';
export const EXPORT_INVOICE_DETAILS = 'EXPORT_INVOICE_DETAILS';
export const INVOICES_GET_M2M_ACCOUNTS = 'INVOICES_GET_M2M_ACCOUNTS';
