import React, { PureComponent } from 'react';
import { object, bool, func } from 'prop-types';
import { sortBy } from 'lodash';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
	Field,
	FieldArray,
	reduxForm,
	arrayRemove,
	Form,
} from 'redux-form';

import Divider from '../../../../lib/DigitalComponents/Divider';
import Button from '../../../../lib/DigitalComponents/Button';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import LanguageSelector from '../../../../lib/DigitalComponents/LanguageSelector';
import PhoneSelector from '../../../../lib/DigitalComponents/PhoneSelector';
import Radio from '../../../../lib/DigitalComponents/Radio';
import SectionHeadline from '../../../../lib/DigitalComponents/SectionHeadline';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import { resetFields } from '../../../../utils/constants';
import Loader from '../../../../lib/DigitalComponents/Loader';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import phonesList from '../../../Ticketing/utils/countryAndPhoneCodes';
import CompanyAddressOverview from '../CompanyAddressOverview';
import CompanyContactOverview from '../CompanyContactOverview';
import UploadImage from '../UploadImage';
import ValidateAddressModal from '../ValidateAddressModal';
import RemoveCompanyContact from './RemoveCompanyContact';
import { localizationHelper } from '../../../../utils/helperFunctions';
import {
	required,
	maxLength,
	zipCodeValidation,
	onlyLettersAndSpaces,
	email
} from '../../../../utils/validators';
import {
	isUserAllowedToAccess,
	UserAllowedToAccess
} from '../../../../utils/AuthSelector';
import { companySupportType } from '../../utils/constants';

import styles from './EditCompanyOverview.scss';

const CompanyLogo = ImageSelector(
	CurrentContext.theme,
	'company-image-placeholder.png'
);
const PlusIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/pluss-add-collapse.svg'
);
const RemoveIcon = ImageSelector(CurrentContext.theme, 'svgs/trash.svg');
const EditIcon = ImageSelector(CurrentContext.theme, 'svgs/pen.svg');
const CheckIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');
const CancelIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');
const UnitedStates = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const France = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const Espana = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const Italia = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const Holland = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const Brazil = ImageSelector(CurrentContext.theme, 'svgs/brazil.svg');
const Deutschland = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const PRC = ImageSelector(CurrentContext.theme, 'svgs/china.svg');
const maxLength40 = maxLength(40);
const maxLength50 = maxLength(50);
const maxLength20 = maxLength(20);
const EditButtonComponentRW = UserAllowedToAccess(['system.companies_rw']);

const countryCallingCodes = sortBy(phonesList, 'value');

class EditCompanyOverview extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isAccountNameSectionStatic: true,
			isAddressesSectionStatic: true,
			isCompanyContactsSectionStatic: true,
			isATTContactsSectionStatic: true,
			isVTMSectionStatic: true,
			isRemoveCompanyContact: false,
			deleteContactsIdArray: [],
			validateAddressModalOpened: false,
			addressValidated: false
		};
	}

	componentDidMount() {
		const { getCountries } = this.props;

		this.getLanguages();
		getCountries({
			additionalParams: {
				dataSort: 'priority asc, name asc'
			}
		});
		this.initializeForm();
	}

	componentDidUpdate(prevProps) {
		const {
			editCompanyFail,
			editCompanySuccess,
			getCompany,
			company,
			states
		} = this.props;
		if (
			prevProps.editCompanyFail !== editCompanyFail ||
			prevProps.editCompanySuccess !== editCompanySuccess
		) {
			getCompany();
		}

		if (company !== null && company.addresses && states === null) {
			this.getStates(company.addresses[0].countryId);
		}

		if (prevProps.company !== company && company !== null) {
			this.initializeForm(true);
		}
	}

	initializeForm = (completeInitialization) => {
		const { company, initialize } = this.props;

		initialize({
			...company,
			companyContacts:
				company &&
				company.contacts &&
				company.contacts
					.filter((contact) => contact.companyContactTypeId === 2)
					.map((contact) => ({
						...contact,
						phoneNumber:
							(contact.phoneNumber && contact.phoneNumber.split('-')[1]) ||
							contact.phoneNumber,
						mask: {
							value:
								contact.phoneNumber && contact.phoneNumber.split('-').length > 1
									? contact.phoneNumber.split('-')[0]
									: '',
							label:
								contact.phoneNumber && contact.phoneNumber.split('-').length > 1
									? contact.phoneNumber.split('-')[0]
									: ''
						}
					})),
			attContacts:
				company &&
				company.contacts &&
				company.contacts
					.filter((contact) => contact.companyContactTypeId === 1)
					.map((contact) => ({
						...contact,
						phoneNumber:
							(contact.phoneNumber && contact.phoneNumber.split('-')[1]) ||
							contact.phoneNumber,
						mask: {
							value:
								contact.phoneNumber && contact.phoneNumber.split('-').length > 1
									? contact.phoneNumber.split('-')[0]
									: '',
							label:
								contact.phoneNumber && contact.phoneNumber.split('-').length > 1
									? contact.phoneNumber.split('-')[0]
									: ''
						}
					}))
		});
		if (completeInitialization) {
			this.setState({
				isAccountNameSectionStatic: true,
				isAddressesSectionStatic: true,
				isCompanyContactsSectionStatic: true,
				isATTContactsSectionStatic: true,
				isVTMSectionStatic: true,
				deleteContactsIdArray: []
			});
		}
	};

	onSubmit = (values) => {
		const { editCompany, isMyCompanyOverview } = this.props;
		const {
			isAccountNameSectionStatic,
			isAddressesSectionStatic,
			isCompanyContactsSectionStatic,
			isATTContactsSectionStatic,
			isVTMSectionStatic,
			deleteContactsIdArray
		} = this.state;
		const contacts = isMyCompanyOverview
			? [...values.companyContacts]
			: [...values.companyContacts, ...values.attContacts];
		const contactsInsert = [];
		const contactsUpdate = [];
		const addressesUpdate = [];
		const paramObject = {};

		if (!isAccountNameSectionStatic) {
			paramObject.languageId = values.languageId.value || values.languageId;
			if (
				values.companyLogo &&
				values.companyLogo.id &&
				values.companyLogo.logo
			) {
				paramObject.companyLogoUpdateRequest = {
					id: values.companyLogo.id,
					logo: values.companyLogo.logo
				};
			} else if (values.companyLogo && values.companyLogo.logo) {
				paramObject.companyLogoInsertRequest = {
					logo: values.companyLogo.logo
				};
			}
		}
		if (!isAddressesSectionStatic) {
			values.addresses.forEach((address) => {
				const tempAddress = {
					id: address.id,
					city: address.city,
					street1: address.street1,
					street2: address.street2 || '',
					zipCode: address.zipCode,
					countryId: address.countryId.value || address.countryId,
					stateId: address.stateId.value || address.stateId
				};

				addressesUpdate.push(tempAddress);
			});

			if (addressesUpdate.length > 0) {
				paramObject.companyAddressUpdateRequest = addressesUpdate;
			}
		}
		if (!isCompanyContactsSectionStatic || !isATTContactsSectionStatic) {
			if (deleteContactsIdArray.length > 0) {
				paramObject.companyContactDeleteRequest = deleteContactsIdArray;
			}

			contacts.forEach((contact) => {
				const tempContact = {
					firstName: contact.firstName,
					lastName: contact.lastName,
					jobTitle: contact.jobTitle || '',
					phoneNumber: `${contact.mask && contact.mask.value}-${
						contact.phoneNumber
					}`,
					email: contact.email,
					companyContactTypeId: contact.companyContactTypeId
				};

				if (contact.id) {
					tempContact.id = contact.id;
					contactsUpdate.push(tempContact);
				} else {
					contactsInsert.push(tempContact);
				}
			});

			if (contactsInsert.length > 0) {
				paramObject.companyContactInsertRequest = contactsInsert;
			}
			if (contactsUpdate.length > 0) {
				paramObject.companyContactUpdateRequest = contactsUpdate;
			}
		}
		if (!isVTMSectionStatic) {
			paramObject.supportType = values.supportType;
		}

		editCompany(paramObject);
	};

	getLanguages = () => {
		const { getLanguages } = this.props;
		getLanguages();
	};

	getStates = (item) => {
		const { getStates } = this.props;
		getStates({
			searchParams: [{ propValue: item, prop: 'countryId', operator: '=' }],
			additionalParams: {
				dataSort: 'name ASC'
			}
		});
	};

	updateLogo = (logoUrl) => {
		const { change } = this.props;

		change('companyLogo.logo', logoUrl);
	};

	userCanChangeLogo = () => {
		const { user } = this.props;

		return isUserAllowedToAccess(['system.companies_rw'], user);
	};

	renderFlags = (data) => {
		switch (data && data.value) {
			case 1:
				return <UnitedStates data-spec="USA" />;
			case 2:
				return <France data-spec="France" />;
			case 3:
				return <Espana data-spec="Espana" />;
			case 4:
				return <Italia data-spec="Italia" />;
			case 5:
				return <Holland data-spec="Holland" />;
			case 6:
				return <Deutschland data-spec="Deutschland" />;
			case 7:
				return <Brazil data-spec="Brazil" />;
			case 8:
				return <PRC data-spec="China" />;
			// no default
		}
	};

	openRemoveCompanyContactModal = (item, indexItem, contactType) => {
		this.setState({
			item,
			indexItem,
			contactType,
			isRemoveCompanyContact: true
		});
	};

	closeRemoveCompanyContactModal = () => {
		this.setState({
			deleteContactsIdArray: [],
			isRemoveCompanyContact: false
		});
	};

	confirmRemoveCompanyContact = () => {
		const { deleteContactsIdArray, item, indexItem, contactType } = this.state;

		const { dispatch } = this.props;
		dispatch(arrayRemove('EditCompany', contactType, indexItem));
		if (item) {
			this.setState({
				deleteContactsIdArray: [...deleteContactsIdArray, item]
			});
		}
		this.setState({
			isRemoveCompanyContact: false
		});
	};

	openValidateAddressModal = () =>
		this.setState({
			validateAddressModalOpened: true,
			addressValidated: false
		});

	closeValidateAddressModal = () => this.setState({ validateAddressModalOpened: false });

	useSuggestedAddress = (data, state) => {
		const { formData, initialize } = this.props;
		initialize({
			...formData,
			addresses: [{
				...formData.addresses[0],
				city: data.city,
				street1: data.street1,
				zipCode: data.zipCode,
				state,
				stateId: state.id
			}]
		});
		this.setState({
			addressValidated: true
		});
	}

	renderAddresses = ({ fields }) => {
		const {
			formData,
			countries,
			getStatesRequest,
			states,
			streetFieldDirty,
			countryFieldDirty,
			stateFieldDirty,
			zipCodeFieldDirty,
			cityFieldDirty,
			intl
		} = this.props;

		const dataPresentInAddressFields = (
			formData && formData.addresses && formData.addresses[0]
		) ? (
			!formData.addresses[0].countryId
			|| !formData.addresses[0].stateId || !formData.addresses[0].street1
			|| !formData.addresses[0].zipCode || !formData.addresses[0].city
			|| (
				!formData.addresses[0].countryId.value ?
					formData.addresses[0].countryId !== 231 :
					(
						formData.addresses[0].countryId.value &&
						formData.addresses[0].countryId.value !== 231
					)
			)
		) : true;
		const addressFieldsTouched = streetFieldDirty || countryFieldDirty || stateFieldDirty
		|| zipCodeFieldDirty || cityFieldDirty;

		return (
			<div data-spec="addresses" className={styles.name_and_logo}>
				{fields.map((address) => (
					<div key={address}>
						<FieldWrapper required single>
							<FormattedMessage
								id="ONBOARDING.COUNTRY"
								defaultMessage="Country"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name={`${address}.countryId`}
										placeholder={formattedValue}
										options={
											countries &&
											countries.resultList &&
											countries.resultList.length > 0 &&
											countries.resultList.map((item) => ({
												value: item.id,
												label: intl.formatMessage({
													id: `COUNTRY_${localizationHelper(
														item.name
													).toUpperCase()}`,
													defaultMessage: item.name
												})
											}))
										}
										label={formattedValue}
										onChange={(item) => {
											const { dispatch } = this.props;
											this.getStates(item.value || item);
											resetFields(dispatch, 'AddCompanyForm', {
												stateId: ''
											});
										}}
										validate={[required]}
										cleanValue
										isSearchable
									/>
								)}
							</FormattedMessage>
							{formData &&
								formData.addresses &&
								formData.addresses[0].countryId &&
								(!formData.addresses[0].countryId.value
									? formData.addresses[0].countryId !== 231
									: formData.addresses[0].countryId.value &&
									  formData.addresses[0].countryId.value !== 231) && (
									<div className="help-block">
										<i className="icon-badgealert" aria-hidden="true" />
										<FormattedMessage
											id="ONBOARDING.COUNTRY_NOT_SUPPORTED"
											defaultMessage="Warning: Country other than USA is currently not supported"
										/>
									</div>
								)}
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.ADDRESS_LINE_1"
								defaultMessage="Address Line 1"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.street1`}
										dataSpec="street1"
										validate={[required]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper>
							<FormattedMessage
								id="ONBOARDING.ADDRESS_LINE_2_OPTIONAL"
								defaultMessage="Address Line 2 (Optional)"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.street2`}
										dataSpec="street2"
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper>
							<FormattedMessage
								id="ONBOARDING.CITY_TOWN"
								defaultMessage="City/Town"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.city`}
										dataSpec="city"
										validate={[required]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper>
							<FormattedMessage
								id="ONBOARDING.STATE_PROVINCE"
								defaultMessage="State/Province"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name={`${address}.stateId`}
										placeholder={formattedValue}
										options={
											states &&
											states.resultList &&
											states.resultList.length > 0 &&
											states.resultList.map((item) => ({
												value: item.id,
												label: intl.formatMessage({
													id: `STATE_${localizationHelper(
														item.name
													).toUpperCase()}`,
													defaultMessage: item.name
												})
											}))
										}
										label={formattedValue}
										loading={getStatesRequest}
										validate={[required]}
										isDisabled={getStatesRequest}
										isSearchable
										cleanValue
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.ZIP_POSTAL_CODE"
								defaultMessage="ZIP/Postal code"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.zipCode`}
										dataSpec="zip-code"
										validate={[required, zipCodeValidation]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper customStyle={styles.validate_button}>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.VALIDATE_ADDRESS"
										defaultMessage="Validate Address"
									/>
								}
								variant="outline-primary"
								type="button"
								dataSpec="validate-address-button"
								disabled={dataPresentInAddressFields || !addressFieldsTouched}
								onClick={() => this.openValidateAddressModal()}
							/>
						</FieldWrapper>
					</div>
				))}
			</div>
		);
	};

	renderContacts = ({ fields, contactType }) => {
		const { formData, intl } = this.props;

		return (
			<div data-spec="contacts" className={styles.name_and_logo}>
				<div>
					{fields.map((contact, index) => (
						<div key={contact}>
							<div className={styles.counter}>
								{index !== 0 && <Divider />}
								<FormattedMessage
									id="ONBOARDING.CONTACT"
									defaultMessage="Contact"
								/>
								{` ${index + 1}`}
								<div className={styles.remove_icon_wrapper}>
									{formData &&
										formData[contactType] &&
										formData[contactType][index] &&
										fields.length > 1 && (
											<div
												className={styles.icon_wrapper}
												onClick={() => {
													this.openRemoveCompanyContactModal(
														formData[contactType][index].id,
														index,
														contactType
													);
												}}
											>
												<RemoveIcon />
											</div>
										)}
								</div>
							</div>
							<FieldWrapper required>
								<FormattedMessage
									id="ONBOARDING.FIRST_NAME"
									defaultMessage="First Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name={`${contact}.firstName`}
											label={formattedValue}
											dataSpec="first-name"
											validate={[required, onlyLettersAndSpaces, maxLength20]}
										/>
									)}
								</FormattedMessage>
							</FieldWrapper>
							<FieldWrapper required>
								<FormattedMessage
									id="ONBOARDING.LAST_NAME"
									defaultMessage="Last Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name={`${contact}.lastName`}
											label={formattedValue}
											dataSpec="last-name"
											validate={[required, onlyLettersAndSpaces, maxLength20]}
										/>
									)}
								</FormattedMessage>
							</FieldWrapper>
							<FieldWrapper single>
								<FormattedMessage
									id="ONBOARDING.JOB_TITLE"
									defaultMessage="Job Title"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name={`${contact}.jobTitle`}
											label={formattedValue}
											dataSpec="job-title"
											validate={[onlyLettersAndSpaces]}
										/>
									)}
								</FormattedMessage>
							</FieldWrapper>
							<FieldWrapper required>
								<PhoneSelector
									label={intl.formatMessage({
										id: 'ONBOARDING.PHONE_NUMBER',
										defaultMessage: 'Phone Number'
									})}
									isRequired
									options={countryCallingCodes}
									name={`${contact}`}
								/>
							</FieldWrapper>
							<FieldWrapper required>
								<Field
									component={Input}
									placeholder="contact@company.com"
									name={`${contact}.email`}
									label={
										<FormattedMessage
											id="ONBOARDING.EMAIL_ADDRESS"
											defaultMessage="Email Address"
										/>
									}
									dataSpec="contact-email-address"
									validate={[required, email]}
								/>
							</FieldWrapper>
						</div>
					))}
					<span
						className={cn({
							[styles.icon_wrapper]: true
						})}
						onClick={() =>
							fields.push({
								companyContactTypeId: contactType === 'attContacts' ? 1 : 2
							})}
					>
						<PlusIcon />
						{
							<FormattedMessage
								id="ONBOARDING.ADD_ANOTHER_CONTACT"
								defaultMessage="Add Another Contact"
							/>
						}
					</span>
				</div>
			</div>
		);
  };

  renderSupportType = () => {
    const { company, intl } = this.props;
    let description = '';

    switch (company && company.supportType) {
			case companySupportType.PRIORITY:
				description = intl.formatMessage({
					id: 'ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION',
					defaultMessage:
						'All tickets will be supported by the IoT Priority Care team. The account’s contract must specify that they qualify for IoT Priority Care support.'
				});
				break;
			case companySupportType.FULL:
				description = intl.formatMessage({
					id: 'ONBOARDING.FULL_TSM_DESCRIPTION',
					defaultMessage:
						'All tickets, regardless of severity, will be supported by the Technical Solutions Manager team. The account’s contract must specify that they qualify for TSM support or the Advanced Solutions Design and Implementation (ASDI) team has specified that the account qualifies for TSM support.'
				});
				break;
			case companySupportType.PARTIAL:
				description = intl.formatMessage({
					id: 'ONBOARDING.PARTIAL_TSM_DESCRIPTION',
					defaultMessage:
						'Critical tickets will be supported by the Technical Solutions Manager team. All other severities will be supported by the ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team. The account’s contract must specify that they qualify for TSM support for critical issues.'
				});
				break;
			case companySupportType.GATEWAY:
				description = intl.formatMessage({
					id: 'ONBOARDING.IOT_GATEWAY_DESCRIPTION',
					defaultMessage:
						'Only for use when Equipment Inquiries will be submitted for manually processed TCU (Transceiver Control Unit) replacement requests.'
				});
				break;
			default:
				description = intl.formatMessage({
					id: 'ONBOARDING.STANDARD_DESCRIPTION',
					defaultMessage:
						'This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support.'
				});
		}

  return (
		<>
			<span className={styles.bold}>
				{(company && company.supportType) || (
					<FormattedMessage
						id="ONBOARDING.STANDARD"
						defaultMessage="Standard (Default)"
					/>
				)}
			</span>
			{' '}
			- {description}
		</>
	);
  }

	render() {
		const {
			company,
			isMyCompanyOverview,
			getLanguagesSuccess,
			getLanguagesRequest,
			formData,
			intl,
			handleSubmit,
			pristine,
			invalid,
			editCompanyRequest,
			getCompanyRequest,
			submit,
			dispatch,
			countries,
			states,
			isMobile,
		} = this.props;
		const {
			isAccountNameSectionStatic,
			isAddressesSectionStatic,
			isCompanyContactsSectionStatic,
			isATTContactsSectionStatic,
			isVTMSectionStatic,
			isRemoveCompanyContact,
			validateAddressModalOpened,
			addressValidated
		} = this.state;

		const dataPresent = !!formData && formData.addresses && formData.addresses[0]
		&& formData.addresses[0].countryId &&
		(formData.addresses[0].countryId === 231 || formData.addresses[0].country.id === 231)
		&& formData.addresses[0].stateId
		&& formData.addresses[0].street1 && formData.addresses[0].zipCode
		&& formData.addresses[0].city !== undefined;

		if (!company) {
			return null;
		}

		if (editCompanyRequest || getCompanyRequest) {
			return <Loader data-spec="edit-company-loader" />;
		}

		return (
			<div data-spec="company-details" className={styles.details_wrapper}>
				<div className={styles.field_wrapper_upper}>
					<span className={styles.label}>
						<FormattedMessage
							id="ONBOARDING.IOT_CONSOLE_ID"
							defaultMessage="IoT Console ID: "
						/>
					</span>
					<span className={styles.info}>
						&nbsp;
						{company.externalCompanyId}
					</span>
				</div>
				<Form onSubmit={handleSubmit(this.onSubmit)}>
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.ACCOUNT_NAME',
							defaultMessage: 'Account Name'
						})}
					/>
					<div className={styles.name_and_logo}>
						{isAccountNameSectionStatic ? (
							<>
								<div>
									<StaticField
										label={intl.formatMessage({
											id: 'ONBOARDING.COMPANY_NAME',
											defaultMessage: 'Company Name'
										})}
										data={company.companyFriendlyName}
										single
									/>
									<StaticField
										label={intl.formatMessage({
											id: 'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY',
											defaultMessage: 'Business or DBA legal entity'
										})}
										data={company.companyLegalName}
										single
									/>
									<StaticField
										label={intl.formatMessage({
											id: 'ONBOARDING.PREFERRED_LANGUAGE',
											defaultMessage: 'Preferred Language'
										})}
										data={
											<div className={styles.preferred_language_info}>
												{this.renderFlags(
													company && company.language && company.language.id
												)}
												{company && company.language && company.language.name}
											</div>
										}
										single
									/>
								</div>
								<div className={styles.logo}>
									<UploadImage
										customDefaultLogo={CompanyLogo}
										canChange={false}
										logo={
											company && company.companyLogo && company.companyLogo.logo
										}
									/>
								</div>
								{!isMobile ? (
									<div className={styles.button_wrapper}>
										<EditButtonComponentRW>
											<EditIcon
												onClick={() =>
													this.setState({
														isAccountNameSectionStatic: false,
														isAddressesSectionStatic: true,
														isCompanyContactsSectionStatic: true,
														isATTContactsSectionStatic: true,
														isVTMSectionStatic: true
													})}
											/>
										</EditButtonComponentRW>
									</div>
								) : null}
							</>
						) : (
							<div className={styles.account_name_wrapper}>
								<div>
									<FieldWrapper required single>
										<Field
											component={Input}
											name="companyFriendlyName"
											label={intl.formatMessage({
												id: 'ONBOARDING.COMPANY_NAME',
												defaultMessage: 'Company Name'
											})}
											dataSpec="company-friendly-name"
											validate={[required, maxLength40]}
											disabled
										/>
									</FieldWrapper>
									<FieldWrapper required single>
										<Field
											component={Input}
											name="companyLegalName"
											label={intl.formatMessage({
												id: 'ONBOARDING.BUSINES_LEGAL_NAME',
												defaultMessage: 'Business Legal Name'
											})}
											dataSpec="company-legal-name"
											validate={[required, maxLength50]}
											disabled
										/>
									</FieldWrapper>
									<FieldWrapper single required>
										<LanguageSelector
											options={
												(getLanguagesSuccess &&
													getLanguagesSuccess.resultList) ||
												[]
											}
											isLoading={getLanguagesRequest}
											isDisabled={getLanguagesRequest}
										/>
									</FieldWrapper>
								</div>
								<div className={styles.logo}>
									<UploadImage
										updateLogo={this.updateLogo}
										customDefaultLogo={CompanyLogo}
										logo={
											formData &&
											formData.companyLogo &&
											formData.companyLogo.logo
										}
									/>
								</div>
								<div className={styles.button_wrapper}>
									<CancelIcon
										onClick={() => {
											this.initializeForm();
											this.setState({
												isAccountNameSectionStatic: true
											});
										}}
									/>
									{pristine || invalid ? null : (
										<CheckIcon onClick={() => dispatch(submit())} />
									)}
								</div>
							</div>
						)}
					</div>
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.MAIN_CORPORATE_ADDRESS',
							defaultMessage: 'Main Corporate Address'
						})}
					/>
					{isAddressesSectionStatic ? (
						<div className={styles.name_and_logo}>
							<div>
								{company &&
									company.addresses &&
									company.addresses.length > 0 &&
									company.addresses.map((item) => (
										<CompanyAddressOverview address={item} key={item.id} />
									))}
							</div>
							{!isMobile ? (
								<div className={styles.button_wrapper}>
									<EditButtonComponentRW>
										<EditIcon
											onClick={() =>
												this.setState({
													isAddressesSectionStatic: false,
													isAccountNameSectionStatic: true,
													isCompanyContactsSectionStatic: true,
													isATTContactsSectionStatic: true,
													isVTMSectionStatic: true
												})}
										/>
									</EditButtonComponentRW>
								</div>
							) : null}
						</div>
					) : (
						<div className={styles.name_and_logo}>
							<FieldArray name="addresses" component={this.renderAddresses} />
							<div className={styles.button_wrapper}>
								<CancelIcon
									onClick={() => {
										this.initializeForm();
										this.setState({
											isAddressesSectionStatic: true
										});
									}}
								/>
								{(pristine || invalid) && !addressValidated ? null : (
									<CheckIcon onClick={() => dispatch(submit())} />
								)}
							</div>
						</div>
					)}
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.COMPANY_CONTACTS',
							defaultMessage: 'Company Contacts'
						})}
					/>
					{isCompanyContactsSectionStatic ? (
						<div className={styles.name_and_logo}>
							<div>
								{company &&
									company.contacts &&
									company.contacts.length > 0 &&
									company.contacts
										.filter((contact) => contact.companyContactTypeId === 2)
										.map((item) => (
											<CompanyContactOverview contact={item} key={item.id} />
										))}
							</div>
							{!isMobile ? (
								<div className={styles.button_wrapper}>
									<EditButtonComponentRW>
										<EditIcon
											onClick={() =>
												this.setState({
													isCompanyContactsSectionStatic: false,
													isAccountNameSectionStatic: true,
													isAddressesSectionStatic: true,
													isATTContactsSectionStatic: true,
													isVTMSectionStatic: true
												})}
										/>
									</EditButtonComponentRW>
								</div>
							) : null}
						</div>
					) : (
						<div className={styles.name_and_logo}>
							<FieldArray
								name={'companyContacts'}
								component={this.renderContacts}
								contactType="companyContacts"
							/>
							<div className={styles.button_wrapper}>
								<CancelIcon
									onClick={() => {
										this.setState({
											isCompanyContactsSectionStatic: true
										});
										this.initializeForm();
									}}
								/>
								{pristine || invalid ? null : (
									<CheckIcon onClick={() => dispatch(submit())} />
								)}
							</div>
						</div>
					)}
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.ATT_CONTACTS',
							defaultMessage: 'AT&T Contacts'
						})}
					/>
					{isATTContactsSectionStatic ? (
						<div className={styles.name_and_logo}>
							<div>
								{company &&
									company.contacts &&
									company.contacts.length > 0 &&
									company.contacts
										.filter((contact) => contact.companyContactTypeId === 1)
										.map((item) => (
											<CompanyContactOverview contact={item} key={item.id} />
										))}
							</div>
							{!isMobile ? (
								<div className={styles.button_wrapper}>
									<EditButtonComponentRW>
										{!isMyCompanyOverview && (
											<EditIcon
												onClick={() =>
													this.setState({
														isATTContactsSectionStatic: false,
														isAccountNameSectionStatic: true,
														isAddressesSectionStatic: true,
														isCompanyContactsSectionStatic: true,
														isVTMSectionStatic: true
													})}
											/>
										)}
									</EditButtonComponentRW>
								</div>
							) : null}
						</div>
					) : (
						<div className={styles.name_and_logo}>
							<FieldArray
								name="attContacts"
								component={this.renderContacts}
								contactType="attContacts"
							/>
							<div className={styles.button_wrapper}>
								<CancelIcon
									onClick={() => {
										this.setState({
											isATTContactsSectionStatic: true
										});
										this.initializeForm();
									}}
								/>
								{pristine || invalid ? null : (
									<CheckIcon onClick={() => dispatch(submit())} />
								)}
							</div>
						</div>
					)}
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.VTM_SUPPORT_TYPE',
							defaultMessage: 'vTM Support Type'
						})}
					/>
					<div className={styles.name_and_logo}>
						{isVTMSectionStatic ? (
							<>
								<span className={styles.support_type_wrapper}>
									{company && this.renderSupportType()}
								</span>
								{!isMobile && (
									<div className={styles.button_wrapper}>
										<EditButtonComponentRW>
											<EditIcon
												onClick={() =>
													this.setState({
														isVTMSectionStatic: false,
														isAccountNameSectionStatic: true,
														isAddressesSectionStatic: true,
														isCompanyContactsSectionStatic: true,
														isATTContactsSectionStatic: true
													})}
											/>
										</EditButtonComponentRW>
									</div>
								)}
							</>
						) : (
							<div className={styles.support_type_radio_buttons_wrapper}>
								<div>
									<Field
										component={Radio}
										name="supportType"
										label={
											<span>
												<span className={styles.bold}>
													<FormattedMessage
														id="ONBOARDING.IOT_PRIORITY_CARE"
														defaultMessage="IoT Priority Care"
													/>
												</span>
												&nbsp;-&nbsp;
												<FormattedMessage
													id="ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION"
													defaultMessage="All tickets will be supported by the IoT Priority Care team. The account’s contract must specify that they qualify for IoT Priority Care support."
												/>
											</span>
										}
										value={'IoT Priority Care'}
										type="radio"
										props={{ multiline: 'true' }}
									/>
									<Field
										component={Radio}
										name="supportType"
										label={
											<span>
												<span className={styles.bold}>
													<FormattedMessage
														id="ONBOARDING.FULL_TSM"
														defaultMessage="Full TSM"
													/>
												</span>
												&nbsp;-&nbsp;
												<FormattedMessage
													id="ONBOARDING.FULL_TSM_DESCRIPTION"
													defaultMessage="All tickets, regardless of severity, will be supported by the Technical Solutions Manager team. The account’s contract must specify that they qualify for TSM support or the Advanced Solutions Design and Implementation (ASDI) team has specified that the account qualifies for TSM support."
												/>
											</span>
										}
										value={'Full TSM'}
										type="radio"
										props={{ multiline: 'true' }}
									/>
									<Field
										component={Radio}
										name="supportType"
										label={
											<span>
												<span className={styles.bold}>
													<FormattedMessage
														id="ONBOARDING.PARTIAL_TSM"
														defaultMessage="Partial TSM"
													/>
												</span>
												&nbsp;-&nbsp;
												<FormattedMessage
													id="ONBOARDING.PARTIAL_TSM_DESCRIPTION"
													defaultMessage="Critical tickets will be supported by the Technical Solutions Manager team. All other severities will be supported by the ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team. The account’s contract must specify that they qualify for TSM support for critical issues."
												/>
											</span>
										}
										value={'Partial TSM'}
										type="radio"
										props={{ multiline: 'true' }}
									/>
									<Field
										component={Radio}
										name="supportType"
										label={
											<span>
												<span className={styles.bold}>
													<FormattedMessage
														id="ONBOARDING.IOT_GATEWAY"
														defaultMessage="IoT Gateway"
													/>
												</span>
												&nbsp;-&nbsp;
												<FormattedMessage
													id="ONBOARDING.IOT_GATEWAY_DESCRIPTION"
													defaultMessage="Only for use when Equipment Inquiries will be submitted for manually processed TCU (Transceiver Control Unit) replacement requests."
												/>
											</span>
										}
										value={'IoT Gateway'}
										type="radio"
										props={{ multiline: 'true' }}
									/>
									<Field
										component={Radio}
										name="supportType"
										label={
											<span>
												<span className={styles.bold}>
													<FormattedMessage
														id="ONBOARDING.STANDARD"
														defaultMessage="Standard (Default)"
													/>
												</span>
												&nbsp;-&nbsp;
												<FormattedMessage
													id="ONBOARDING.STANDARD_DESCRIPTION"
													defaultMessage="This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support."
												/>
											</span>
										}
										value={''}
										type="radio"
										props={{ multiline: 'true' }}
									/>
								</div>
								<div className={styles.button_wrapper}>
									<CancelIcon
										onClick={() => {
											this.initializeForm();
											this.setState({
												isVTMSectionStatic: true
											});
										}}
									/>
									{pristine || invalid ? null : (
										<CheckIcon onClick={() => dispatch(submit())} />
									)}
								</div>
							</div>
						)}
					</div>
					{isRemoveCompanyContact && (
						<RemoveCompanyContact
							confirm={this.confirmRemoveCompanyContact}
							show
							onClose={this.closeRemoveCompanyContactModal}
						/>
					)}
					{formData && formData.addresses && formData.addresses[0] && (
						<ValidateAddressModal
							show={validateAddressModalOpened}
							onClose={this.closeValidateAddressModal}
							data={formData.addresses[0]}
							dataPresent={dataPresent || false}
							useSuggestedAddress={this.useSuggestedAddress}
							countries={countries}
							states={states}
						/>
					)}
				</Form>
			</div>
		);
	}
}

EditCompanyOverview.propTypes = {
	company: object,
	editCompanyRequest: bool,
	getCompanyRequest: bool,
	editCompany: func,
	user: object,
	getCompany: func,
	editCompanyFail: bool,
	editCompanySuccess: bool,
	getLanguagesSuccess: object,
	getLanguages: func,
	intl: object,
	getLanguagesRequest: bool,
	formData: object,
	getCountries: func,
	countries: object,
	states: object,
	getStatesRequest: bool,
	dispatch: func,
	handleSubmit: func,
	pristine: bool,
	invalid: bool,
	initialize: func,
	change: func,
	isMyCompanyOverview: bool,
	getStates: func,
	submit: func,
	streetFieldDirty: bool,
	countryFieldDirty: bool,
	stateFieldDirty: bool,
	zipCodeFieldDirty: bool,
	cityFieldDirty: bool,
	isMobile: bool,
};

export default reduxForm({
	form: 'EditCompany',
})(injectIntl(EditCompanyOverview));
