import React from 'react';
import PropTypes from 'prop-types';
import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';
import hoc from '../BatchActions';
// import styles from './Mobile.scss';

const Mobile = (props) => {
	const { getOptions, messages, batchActions } = props;
	return (
		<div data-spec="batch-actions-mobile">
			<CardsTable
				data={batchActions.resultList}
				options={getOptions('mobile')}
				messages={messages}
			/>
		</div>
	);
};

const { func, object } = PropTypes;

Mobile.propTypes = {
	getOptions: func,
	messages: object,
	batchActions: object
};

export default hoc()(Mobile);
