import React from 'react';
import { func, bool } from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { required } from '../../../../utils/validators';

import styles from './RequestFeatureModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

const onSubmit = (values, _, props) => {
	const { requestFeature, permission } = props;
	requestFeature({ note: values.note, permissionId: permission });
};

const RequestFeatureModal = ({ onCancel, show, invalid, handleSubmit }) => (
	<Modal data-spec="dashboard-prompt-modal" show={show} onClose={onCancel}>
		<form onSubmit={handleSubmit(onSubmit)}>
			<ModalHeader
				title={
					<FormattedMessage
						id="OBOARDING.PREMIUM_FEATURE_REQUEST"
						defaultMessage="Premium feature request"
					/>
				}
			/>
			<ModalBody className={styles.modal_body}>
				<div data-spec="body-message" className={styles.body}>
					<div className={styles.modal_message}>
						Premium features are subject to a recuring fee and require a
						contract to be in place before they can be enabled.
					</div>
					<div className={styles.modal_message}>
						Your account manager will contact you about enabling this feature
					</div>
					<div className={styles.modal_message}>
						<FormattedMessage
							id="ONBOARDING.ENTER_YOUR_NOTE_HERE"
							defaultMessage="Enter your note here"
						>
							{(formattedValue) => (
								<Field
									label={
										<FormattedMessage
											id="ONBOARDING.NOTE"
											defaultMessage="Note"
										/>
									}
									componentClass="textarea"
									dataSpec="note"
									component={Input}
									name="note"
									placeholder={formattedValue}
									validate={[required]}
								/>
							)}
						</FormattedMessage>
					</div>
				</div>
				<div>
					<Prompt />
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				<div data-spec="buttons">
					<Button
						onClick={onCancel}
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					/>
					<Button
						variant="primary"
						type="submit"
						disabled={invalid}
						label={<FormattedMessage id="REQUEST" defaultMessage="Request" />}
					/>
				</div>
			</ModalFooter>
		</form>
	</Modal>
);

RequestFeatureModal.propTypes = {
	onCancel: func,
	show: bool,
	invalid: bool,
	handleSubmit: func
};

const RequestFeatureModalForm = reduxForm({
	form: 'RequestFeatureModalForm',
	enableReinitialize: true
})(RequestFeatureModal);

export default RequestFeatureModalForm;
