import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import PageTitleDesktop from './Desktop';
import PageTitleMobile from './Mobile';
import PageTitleTablet from './Tablet';

import getNotifications from '../../redux/notifications/selectors';
import { getErrors } from '../../redux/getStatusCode/selectors';
import { hideNotification } from '../../redux/notifications/actions';
import isAnyModalOpened from '../../../../redux/modals/globalModal/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import { removeActiveNotification } from '../../../Support/redux/notifications/actions';
import { getActiveNotificationsSuccess } from '../../../Support/redux/notifications/selectors';

const mapStateToProps = (state) => ({
	user: getUser(state),
	notifications: getNotifications(state),
	errors: getErrors(state),
	renderErrorNotifications: !isAnyModalOpened(state),
	activeNotifications: getActiveNotificationsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	hideNotification: (id) => dispatch(hideNotification(id)),
	removeNotification: (id) => dispatch(removeActiveNotification(id)),
	goToReleaseNotes: (url) => dispatch(localizedRoute(url)),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(PageTitleDesktop);
export const Tablet = connect(
	mapStateToProps,
	mapDispatchToProps
)(PageTitleTablet);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(PageTitleMobile);
