import { cloneDeep } from 'lodash';

import WorldMapData from './WorldMapData';
import { topCountriesColors } from '../Helpers/helpers';

const WorldMapDataWithColors = cloneDeep(WorldMapData);

topCountriesColors.forEach((country) => {
	if (
		WorldMapDataWithColors.objects.units.geometries.find(
			(item) => item.id === country.id
		)
	) {
		WorldMapDataWithColors.objects.units.geometries.find(
			(item) => item.id === country.id
		).properties.color = country.color;
	}
});

export default WorldMapDataWithColors;
