import { connect } from 'react-redux';
import { reset } from 'redux-form';
import AttachmentsModal from './AttachmentsModal';

import { anyModalClosed } from '../../../../redux/modals/globalModal/actions';

import getNotifications from '../../../Shared/redux/notifications/selectors';
import { getErrors } from '../../../Shared/redux/getStatusCode/selectors';

import { invoiceReportRequest } from '../../redux/invoiceReport/selectors';

import { hideNotification } from '../../../Shared/redux/notifications/actions';

import {
	getInvoiceAttachments,
	updateInvoice,
	postInvoiceAttachment,
	updateInvoiceAttachment
} from '../../redux/invoices/actions';

import {
	getInvoiceAttachmentsRequest,
	getInvoiceAttachmentsFail,
	getInvoiceAttachmentsSuccess,
	patchInvoiceRequest,
	patchInvoiceSuccess,
	postInvoiceRequest,
	postInvoiceSuccess
} from '../../redux/invoices/selector';

import { downloadInvoiceAttachmentRequest } from '../../redux/invoiceAttachment/selectors';

import {
	fileUploadRequest,
	fileUploadSuccess
} from '../../../Shared/redux/uploadFile/selectors';

import { resetFiles } from '../../../Shared/redux/uploadFile/actions';

import { invoiceAttachmentDownload } from '../../redux/invoiceAttachment/actions';

import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	notifications: getNotifications(state),
	errors: getErrors(state),
	invoiceDownloadRequest: invoiceReportRequest(state),
	getAttachmentsRequest: getInvoiceAttachmentsRequest(state),
	getAttachmentsFail: getInvoiceAttachmentsFail(state),
	attachments: getInvoiceAttachmentsSuccess(state),
	downloadInvoiceAttachmentRequest: downloadInvoiceAttachmentRequest(state),
	patchInvoiceRequest: patchInvoiceRequest(state),
	patchInvoiceSuccess: patchInvoiceSuccess(state),
	filePending: fileUploadRequest(state),
	fileSuccess: fileUploadSuccess(state),
	postInvoiceRequest: postInvoiceRequest(state),
	postInvoiceSuccess: postInvoiceSuccess(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	anyModalClosed: () => dispatch(anyModalClosed()),
	getInvoiceAttachments: (id) => dispatch(getInvoiceAttachments(id)),
	updateInvoice: (id, data, notifOptions) =>
		dispatch(updateInvoice(id, data, notifOptions)),
	resetFiles: () => dispatch(resetFiles()),
	postInvoiceAttachment: (id, data, notifOptions) =>
		dispatch(postInvoiceAttachment(id, data, notifOptions)),
	updateInvoiceAttachment: (id, data, notifOptions) =>
		dispatch(updateInvoiceAttachment(id, data, notifOptions)),
	resetForm: () => dispatch(reset('invoiceAttachments')),
	invoiceAttachmentDownload: (data) =>
		dispatch(invoiceAttachmentDownload(data)),
	hideNotification: (id) => dispatch(hideNotification(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsModal);
