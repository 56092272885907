import globalAction, { actionInit } from '../../../redux/global/globalAction';
import {
  getEndpoints as getEndpointsService,
  getCustomLabels as getCustomLabelsService,
	getm2mAccount as getm2mAccountService,
	getRatePlans as getRatePlansService,
	getAvailableStates as serviceGetAvailableStates,
	generateReportData as serviceGenerateReportData,
	updateCustomLabels as serviceUpdateCustomLabels
} from '../services/EndpointsService';
import {
	GET_ENDPOINTS,
	GET_ENDPOINTS_CUSTOM_LABELS,
	GET_M2M_ACCOUNT,
	GET_RATE_PLANS,
	GET_AVAILABLE_STATES,
	GENERATE_REPORT,
	UPDATE_CUSTOM_LABELS
} from './constants';
import { showNotification } from '../../Shared/redux/notifications/actions';
import { localizedRoute } from '../../../utils/helperFunctions';

export const getEndpoints = (params) =>
	globalAction(GET_ENDPOINTS, getEndpointsService, params, {
		notifyFail: 'endpoints'
	});

export const getCustomLabels = () =>
	globalAction(GET_ENDPOINTS_CUSTOM_LABELS, getCustomLabelsService);

export const getAvailableStates = () =>
	globalAction(GET_AVAILABLE_STATES, serviceGetAvailableStates);

export const getm2mAccount = () =>
	globalAction(GET_M2M_ACCOUNT, getm2mAccountService);

export const getRatePlans = (m2mAccountId) =>
	globalAction(GET_RATE_PLANS, getRatePlansService, m2mAccountId);

export const generateReport = (paginationData, searchData, dispatch) =>
	globalAction(
		GENERATE_REPORT,
		serviceGenerateReportData,
		{
			paginationData,
			searchData
		},
		{
			notify: 'generate-report',
			notifyMessageSuccess: 'ENDPOINTS.CUSTOM_REPORT_CREATED',
			notifyMessageError: 'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE',
			actionText: 'ENDPOINTS.GO_TO_REPORTS',
			action: () => dispatch(localizedRoute('/reports?defaultTab=MyExports'))
		}
	);

export const generateReportInit = () => actionInit(GENERATE_REPORT);

export const statusChangeSuccess = (props, formatMessage) => async (
	dispatch
) => {
	const message = formatMessage(
		{
			id: 'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS',
			defaultMessage: `Endpoint ID ${props.id} is successfully provisioned. IMSI status is changed to ${props.data.targetState}`
		},
		{
			endpointID: props.id,
			status: props.data.targetState
		}
	);

	dispatch(
		showNotification({
			id: 'status-change-sucess',
			message: [message],
			type: 'success',
			notificationType: 'toaster',
			close: true,
			duration: 5000
		})
	);
};

export const statusChangeError = (props, formatMessage) => async (dispatch) => {
	const message = formatMessage(
		{
			id: 'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR',
			defaultMessage: `Endpoint ID ${props.id} failed to provision after IMSI status change. Get support`
		},
		{
			endpointID: props.id
		}
	);

	dispatch(
		showNotification({
			id: 'status-change-error',
			message: [message],
			type: 'error',
			notificationType: 'toaster',
			close: false
		})
	);
};

export const ratePlanChangeSuccess = (props, formatMessage) => async (
	dispatch
) => {
	const message = formatMessage(
		{
			id: 'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS',
			defaultMessage: `Endpoint ID ${props.id} is successfully provisioned. Rate plan is changed to ${props.data.ratePlanId}`
		},
		{
			endpointID: props.id,
			ratePlan: props.data.ratePlanId
		}
	);

	dispatch(
		showNotification({
			id: 'rate-plan-change-sucess',
			message: [message],
			type: 'success',
			notificationType: 'toaster',
			close: true,
			duration: 5000
		})
	);
};

export const ratePlanChangeError = (props, formatMessage) => async (
	dispatch
) => {
	const message = formatMessage(
		{
			id: 'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR',
			defaultMessage: `Endpoint ID ${props.id} failed to provision after rate plan change. Get support`
		},
		{
			endpointID: props.id
		}
	);

	dispatch(
		showNotification({
			id: 'rate-plan-change-error',
			message: [message],
			type: 'error',
			notificationType: 'toaster',
			close: false
		})
	);
};

export const updateCustomLabels = (data) =>
	globalAction(UPDATE_CUSTOM_LABELS, serviceUpdateCustomLabels, data, {
		notify: 'generate-report',
		notifyMessageSuccess: 'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE',
		notifyMessageError: 'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE'
	});

export const updateCustomLabelsInit = () => actionInit(UPDATE_CUSTOM_LABELS);
