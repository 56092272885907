export default {
	'SHARED.NO_DATA_AVAILABLE': 'No Data Available',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE':
		'There is No Data available to show you right now',
	'SHARED.EXIT_THIS_COMPANY': 'Exit',
	// ACTION BAR SELECTED FILTERS TOOLTIP
	// ** ENDPOINTS **
	'ACTION_BAR.ENDPOINTSTATUS': 'Status',
	'ACTION_BAR.ENDPOINTSTATE': 'Status',
	'ACTION_BAR.NETWORKOPERATOR': 'Network Operator',
	'ACTION_BAR.NETWORKCOUNTRY': 'Network Country',
	'ACTION_BAR.NETWORKENTITLEMENT': 'Network Entitlement ID',
	'ACTION_BAR.NETWORKTYPE': 'Network Type',
	'ACTION_BAR.TRANSFERSTATUS': 'IMSI Transfer Status',
	'ACTION_BAR.RATEPLAN': 'Rate Plan',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'Re-Credential Status',
	'ACTION_BAR.M2MPLATFORM': 'M2M Platform',
	'ACTION_BAR.ROAMINGCOUNTRY': 'Roaming Country',
	'ACTION_BAR.ROAMINGCARRIER': 'Roaming Carrier',
	'ACTION_BAR.SERVICESTATUS': '360L Status',
	'ACTION_BAR.OEM': 'Sub Accounts',
	'ACTION_BAR.M2MDATEADDED': 'M2M Date Added',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'M2M Activation Date',
	//  **  INVOICES  **
	'ACTION_BAR.M2MACCOUNTID': 'M2M Account ID',
	'ACTION_BAR.CUSTOMERNAME': 'Customer Name',
	'ACTION_BAR.INVOICENUMBER': 'Invoice Number',
	'ACTION_BAR.COUNTRYNAME': 'Country Name',
	'ACTION_BAR.BILLINGCYCLE': 'Billing Cycle',
	'ACTION_BAR.CUSTOMERID': 'Customer ID',
	'ACTION_BAR.INVOICEDATE': 'Invoice Date',
	//  **  REPORTS **
	'ACTION_BAR.M2M_ACC_ID': 'M2M Account ID',
	'ACTION_BAR.M2M_PLATFORM': 'M2M Platform',
	'ACTION_BAR.PLATFORM': 'Platform',
	'ACTION_BAR.FREQUENCY': 'Frequency',
	'ACTION_BAR.REPORT_NAME': 'Report Name',
	'ACTION_BAR.SERVICEPROVIDER': 'Platform',
	'ACTION_BAR.REPORTFREQUENCY': 'Frequency',
	//  **  SIM ORDERING  **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': 'Status',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'M2M Platform',
	'ACTION_BAR.FILTER_CATEGORY_SKU': 'SKU',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Expedited',
	//  **  SECURITY FEATURES **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'M2M Account',
	//  **  TICKETING **
	'ACTION_BAR.CATEGORIES': 'Categories',
	'ACTION_BAR.STATUS': 'Status',
	'ACTION_BAR.SEVERITY': 'Severity',
	//  ACTION BAR
	'ACTION_BAR.CLEAR_FILTERS': 'Clear Filters',
	'ACTION_BAR.SELECT_FILTERS': 'Select Filters',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Edit Table Parameters',
	'ACTION_BAR.SHOWING': 'Showing',
	'ACTION_BAR.OF': 'of',
	'ACTION_BAR.DOWNLOAD_FILE': 'Download File',
	'ACTION_BAR.REFRESH_PAGE': 'Refresh page',
	//  ******************* FILTER MODAL *****************************
	//  **  ENDPOINTS **
	'FILTER_MODAL.ENDPOINTSTATE': 'Status',
	'FILTER_MODAL.NETWORKOPERATOR': 'Network Operator',
	'FILTER_MODAL.NETWORKCOUNTRY': 'Network Country',
	'FILTER_MODAL.NETWORKENTITLEMENT': 'Network Entitlement ID',
	'FILTER_MODAL.NETWORKTYPE': 'Network Type',
	'FILTER_MODAL.TRANSFERSTATUS': 'IMSI Transfer Status',
	'FILTER_MODAL.RATEPLAN': 'Rate Plan',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'Re-Credential Status',
	'FILTER_MODAL.M2MPLATFORM': 'M2M Platform',
	'FILTER_MODAL.ROAMINGCOUNTRY': 'Roaming Country',
	'FILTER_MODAL.ROAMINGCARRIER': 'Roaming Carrier',
	'FILTER_MODAL.SERVICESTATUS': '360L Status',
	'FILTER_MODAL.OEM': 'Sub Accounts',
	'FILTER_MODAL.M2MDATEADDED': 'M2M Date Added',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'M2M Activation Date',
	//  tabs
	'FILTER_MODAL.TABS_ENDPOINTSTATE': 'Endpoint Status',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Network Operator',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'Network Country',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Network Entitlement',
	'FILTER_MODAL.TABS_NETWORKTYPE': 'Network Type',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': 'Transfer Status',
	'FILTER_MODAL.TABS_RATEPLAN': 'Rate Plan',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS':
		'Recredential Transfer Status',
	'FILTER_MODAL.TABS_M2MPLATFORM': 'M2M Platform',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'Roaming Country',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Roaming Carrier',
	'FILTER_MODAL.TABS_SERVICESTATUS': '360L Status',
	'FILTER_MODAL.TABS_OEM': 'Sub Accounts',
	'FILTER_MODAL.TABS_M2MDATEADDED': 'M2M Date Added',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'M2M Activation Date',
	//  **  INVOICES  **
	'FILTER_MODAL.M2MACCOUNTID': 'M2M ACCOUNT ID',
	'FILTER_MODAL.CUSTOMERNAME': 'CUSTOMER NAME',
	'FILTER_MODAL.INVOICENUMBER': 'INVOICE NUMBER',
	'FILTER_MODAL.INVOICEDATE': 'INVOICE DATE',
	'FILTER_MODAL.COUNTRYNAME': 'COUNTRY NAME',
	'FILTER_MODAL.CUSTOMERID': 'CUSTOMER ID',
	'FILTER_MODAL.BILLINGCYCLE': 'BILLING CYCLE',
	//  tabs
	'FILTER_MODAL.TABS_M2MACCOUNTID': 'M2M Account ID',
	'FILTER_MODAL.TABS_CUSTOMERNAME': 'Customer Name',
	'FILTER_MODAL.TABS_INVOICENUMBER': 'Invoice Number',
	'FILTER_MODAL.TABS_COUNTRYNAME': 'Country Name',
	'FILTER_MODAL.TABS_CUSTOMERID': 'Customer ID',
	'FILTER_MODAL.TABS_BILLINGCYCLE': 'Billing Cycle',
	'FILTER_MODAL.TABS_INVOICEDATE': 'Invoice Date',

	//  **  REPORTS **
	'FILTER_MODAL.M2M_ACC_ID': 'M2M ACCOUNT ID',
	'FILTER_MODAL.M2M_PLATFORM': 'M2M PLATFORM',
	'FILTER_MODAL.FREQUENCY': 'FREQUENCY',
	'FILTER_MODAL.REPORT_NAME': 'REPORT NAME',
	'FILTER_MODAL.PLATFORM': 'PLATFORM',
	'FILTER_MODAL.SERVICEPROVIDER': 'PLATFORM',
	'FILTER_MODAL.REPORTFREQUENCY': 'FREQUENCY',
	//  tabs
	'FILTER_MODAL.TABS_M2M_ACC_ID': 'M2M Account ID',
	'FILTER_MODAL.TABS_M2M_PLATFORM': 'M2M Platform',
	'FILTER_MODAL.TABS_FREQUENCY': 'Frequency',
	'FILTER_MODAL.TABS_REPORT_NAME': 'Report Name',
	'FILTER_MODAL.TABS_PLATFORM': 'Platform',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Platform',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Frequency',
	//  **  SIM ORDERING  **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'STATUS',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'M2M PLATFORM',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'EXPEDITED',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'Status',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'M2M Platform',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Expedited',
	//  **  SECURITY FEATURES **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'M2M ACCOUNT',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'M2M Account',
	//  **  TICKETING **
	'FILTER_MODAL.CATEGORIES': 'CATEGORIES',
	'FILTER_MODAL.STATUS': 'STATUS',
	'FILTER_MODAL.SEVERITY': 'SEVERITY',
	//  tabs
	'FILTER_MODAL.TABS_CATEGORIES': 'Categories',
	'FILTER_MODAL.TABS_STATUS': 'Status',
	'FILTER_MODAL.TABS_SEVERITY': 'Severity',
	//  **  INVOICE DETAILS **
	'FILTER_MODAL.CURRENCY': 'CURRENCY',
	'FILTER_MODAL.CUSTOMER': 'CUSTOMER',
	'FILTER_MODAL.SUBSCRIBER_STATUS': 'SUBSCRIBER STATUS',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': 'MONTHLY RATE PLAN',
	'FILTER_MODAL.STANDARD_RATE_PLAN': 'STANDARD RATE PLAN',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'PREPAID TERMS CHARGED',
	'FILTER_MODAL.RATE_PLAN': 'RATE PLAN',
	'FILTER_MODAL.PLAN_TYPE': 'PLAN TYPE',
	'FILTER_MODAL.ZONE': 'ZONE',
	'FILTER_MODAL.PRIORITY': 'PRIORITY',
	'FILTER_MODAL.ROAMING_ZONE': 'ROAMING ZONE',
	'FILTER_MODAL.GROUP_NAME': 'GROUP NAME',
	'FILTER_MODAL.INVOICE_ID': 'INVOICE ID',
	'FILTER_MODAL.THE_PLAN_TYPE': 'THE PLAN TYPE',
	'FILTER_MODAL.ACTIVATION_TYPE': 'ACTIVATION TYPE',
	'FILTER_MODAL.CHARGE_TYPE': 'CHARGE TYPE',
	'FILTER_MODAL.PRIMARY_PLACE': 'PRIMARY PLACE',
	'FILTER_MODAL.TECH_TYPE': 'TECH TYPE',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'PRIMARY PLACE OF USE',
	'FILTER_MODAL.STATE': 'STATE',
	'FILTER_MODAL.CITY': 'CITY',
	'FILTER_MODAL.DISTRICT_COUNTY': 'DISTRICT COUNTY',
	'FILTER_MODAL.DISTRICT_CITY': 'DISTRICT CITY',
	'FILTER_MODAL.DESCRIPTION': 'DESCRIPTION',
	'FILTER_MODAL.RATE_PLAN_NAME': 'Rate Plan',
	//  tabs
	'FILTER_MODAL.TABS_CURRENCY': 'Currency',
	'FILTER_MODAL.TABS_CUSTOMER': 'Customer',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'Subscriber Status',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'Monthly Rate Plan',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'Standard Rate Plan',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'Prepaid Terms Charged',
	'FILTER_MODAL.TABS_RATE_PLAN': 'Rate Plan',
	'FILTER_MODAL.TABS_PLAN_TYPE': 'Plan Type',
	'FILTER_MODAL.TABS_ZONE': 'Zone',
	'FILTER_MODAL.TABS_PRIORITY': 'Priority',
	'FILTER_MODAL.TABS_ROAMING_ZONE': 'Roaming Zone',
	'FILTER_MODAL.TABS_GROUP_NAME': 'Group Name',
	'FILTER_MODAL.TABS_INVOICE_ID': 'Invoice ID',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'The Plan Type',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'Activation Type',
	'FILTER_MODAL.TABS_CHARGE_TYPE': 'Charge Type',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': 'Primary Place',
	'FILTER_MODAL.TABS_TECH_TYPE': 'Tech Type',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'Primary Place Of Use',
	'FILTER_MODAL.TABS_STATE': 'State',
	'FILTER_MODAL.TABS_CITY': 'City',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'District County',
	'FILTER_MODAL.TABS_DISTRICT_CITY': 'District City',
	'FILTER_MODAL.TABS_DESCRIPTION': 'Description',
	'FILTER_MODAL.TABS_RATE_PLAN_NAME': 'Rate Plan',
	//  **    AUTOMATION    **  //
	'FILTER_MODAL.PLATFORMS': 'PLATFORMS',
	'FILTER_MODAL.TRIGGERS': 'TRIGGERS',
	'FILTER_MODAL.M2MACCOUNTS': 'M2M ACCOUNTS',
	'FILTER_MODAL.ACTIVE': 'ACTIVATED',
	'FILTER_MODAL.STATUSES': 'STATUSES',
	//  tabs
	'FILTER_MODAL.TABS_PLATFORMS': 'Platforms',
	'FILTER_MODAL.TABS_TRIGGERS': 'Triggers',
	'FILTER_MODAL.TABS_M2MACCOUNTS': 'M2M Account',
	'FILTER_MODAL.TABS_ACTIVE': 'Activated',
	'FILTER_MODAL.TABS_USERGROUPS': 'User Groups',
	'FILTER_MODAL.USERGROUPS': 'User Groups',
	'FILTER_MODAL.TABS_STATUSES': 'Statuses',
	// action bar
	'ACTION_BAR.PLATFORMS': 'Platforms',
	'ACTION_BAR.TRIGGERS': 'Triggers',
	'ACTION_BAR.M2MACCOUNTS': 'M2M Accounts',
	'ACTION_BAR.CURRENCY': 'Currency',
	'ACTION_BAR.ACTIVE': 'Activated',
	'ACTION_BAR.CUSTOMER': 'Customer',
	'ACTION_BAR.SUBSCRIBER_STATUS': 'Subscriber Status',
	'ACTION_BAR.MONTHLY_RATE_PLAN': 'Monthly Rate Plan',
	'ACTION_BAR.STANDARD_RATE_PLAN': 'Standard Rate Plan',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': 'Prepaid Terms Charged',
	'ACTION_BAR.RATE_PLAN': 'Rate Plan',
	'ACTION_BAR.PLAN_TYPE': 'Plan Type',
	'ACTION_BAR.ZONE': 'Zone',
	'ACTION_BAR.PRIORITY': 'Priority',
	'ACTION_BAR.ROAMING_ZONE': 'Roaming Zone',
	'ACTION_BAR.GROUP_NAME': 'Group Name',
	'ACTION_BAR.INVOICE_ID': 'Invoice ID',
	'ACTION_BAR.THE_PLAN_TYPE': 'The Plan Type',
	'ACTION_BAR.ACTIVATION_TYPE': 'Activation Type',
	'ACTION_BAR.CHARGE_TYPE': 'Charge Type',
	'ACTION_BAR.PRIMARY_PLACE': 'Primary Place',
	'ACTION_BAR.TECH_TYPE': 'Tech Type',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'Primary Place Of Use',
	'ACTION_BAR.STATE': 'State',
	'ACTION_BAR.CITY': 'City',
	'ACTION_BAR.DISTRICT_COUNTY': 'District County',
	'ACTION_BAR.DISTRICT_CITY': 'District City',
	'ACTION_BAR.DESCRIPTION': 'Description',
	'ACTION_BAR.USERGROUPS': 'User Groups',
	'ACTION_BAR.STATUSES': 'Statuses',
	'ACTION_BAR.SEARCH_INFO':
		'You can search by wildcard (ie. Endpoint ID*) or perform multiple searches separated by comma (ie. Endpoint 1, Endpoint 2).',
	// UPLOAD COMPONENT
	'UPLOAD_FILE.MAX_FILE_SIZE': 'File must be less than {size}MB.',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Wrong file type',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE':
		'Only the following formats are allowed for upload:',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'File is too large',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE':
		'The file you tried to upload is too large, files must be less than',
	'UPLOAD_FILE.ALLOWED_FORMATS': 'Allowed formats: ',
	'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'Only one file allowed',
};
