import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import qs from 'qs';

import { renderTabs } from '../../../../utils/tabHelpers';

import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';

import List from '../../views/ListView';

import { ReportsIDs, isIOTTab, isRepostedReportsTab } from '../../utils/helpers';

class Reports extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: null
		};
	}

	componentDidMount() {
		const { location } = this.props;
		const { defaultTab } = qs.parse(location.search, {
			ignoreQueryPrefix: true
		});
		this.setState({
			activeTab:
				defaultTab === ReportsIDs.MY_EXPORTS
					? ReportsIDs.MY_EXPORTS
					: this.getDefaultTab()
		});
	}

	componentWillUnmount() {
		const { getAnalyticsReportsInit, getCustomerReportsInit } = this.props;
		getAnalyticsReportsInit();
		getCustomerReportsInit();
	}

	onTabClick = async (tabName) => {
		const {
			setDataForFiltersInit,
			getAnalyticsReportsInit,
      getCustomerReportsInit,
      hideNotification
		} = this.props;
    await setDataForFiltersInit();
    hideNotification('report-download-error');
		(isIOTTab(tabName) || isRepostedReportsTab(tabName))
			? getAnalyticsReportsInit()
			: getCustomerReportsInit();

		this.setState({
			activeTab: tabName
		});
	};

	getDefaultTab = () => {
		const filteredTabs = this.getReportTabs().filter((item) => !item.hide);
		return filteredTabs.length ? filteredTabs[0].id : '';
	};

	getReportTabs = () => {
		const {
			messages,
			user,
			reportsRequest,
			downloadReportRequest
		} = this.props;
		const { activeTab } = this.state;
		return [
			{
				id: ReportsIDs.ENDPOINTS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.ENDPOINTS"
						defaultMessage="Endpoints"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.ENDPOINTS}
					/>
				),
				isActive: activeTab === ReportsIDs.ENDPOINTS,
				onClick: () => this.onTabClick(ReportsIDs.ENDPOINTS),
				hide: !isUserAllowedToAccess(
					['mnc.reports.endpoints_rw', 'mnc.reports.endpoints_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.ENDPOINTS) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.USAGE_SUMMARY,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.USAGE_SUMMARY"
						defaultMessage="Usage Summary"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.USAGE_SUMMARY}
					/>
				),
				isActive: activeTab === ReportsIDs.USAGE_SUMMARY,
				onClick: () => this.onTabClick(ReportsIDs.USAGE_SUMMARY),
				hide: !isUserAllowedToAccess(
					['mnc.reports.usagesummaries_rw', 'mnc.reports.usagesummaries_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.USAGE_SUMMARY) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.TRANSFER_ACTIVITIES,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.TRANSFER_ACTIVITIES"
						defaultMessage="Transfer Activities"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.TRANSFER_ACTIVITIES}
					/>
				),
				isActive: activeTab === ReportsIDs.TRANSFER_ACTIVITIES,
				onClick: () => this.onTabClick(ReportsIDs.TRANSFER_ACTIVITIES),
				hide: !isUserAllowedToAccess(
					['mnc.reports.transfer_rw', 'mnc.reports.transfer_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.TRANSFER_ACTIVITIES) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.ACCOUNT_SUMMARY,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.ACCOUNT_SUMMARY"
						defaultMessage="Account Summary"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.ACCOUNT_SUMMARY}
					/>
				),
				isActive: activeTab === ReportsIDs.ACCOUNT_SUMMARY,
				onClick: () => this.onTabClick(ReportsIDs.ACCOUNT_SUMMARY),
				hide: !isUserAllowedToAccess(
					['mnc.reports.accountsummary_rw', 'mnc.reports.accountsummary_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.ACCOUNT_SUMMARY) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.BILLING_DETAILS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.BILLING_DETAILS"
						defaultMessage="Billing Details"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.BILLING_DETAILS}
					/>
				),
				isActive: activeTab === ReportsIDs.BILLING_DETAILS,
				onClick: () => this.onTabClick(ReportsIDs.BILLING_DETAILS),
				hide: !isUserAllowedToAccess(
					['mnc.reports.billingdetails_rw', 'mnc.reports.billingdetails_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.BILLING_DETAILS) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.REFERENCE_REPORTS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.REFERENCE_REPORTS"
						defaultMessage="Reference Reports"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.REFERENCE_REPORTS}
					/>
				),
				isActive: activeTab === ReportsIDs.REFERENCE_REPORTS,
				onClick: () => this.onTabClick(ReportsIDs.REFERENCE_REPORTS),
				hide: !isUserAllowedToAccess(
					['mnc.reports.reference_rw', 'mnc.reports.reference_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.REFERENCE_REPORTS) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.MY_EXPORTS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.MY_EXPORTS"
						defaultMessage="My Exports"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.MY_EXPORTS}
					/>
				),
				isActive: activeTab === ReportsIDs.MY_EXPORTS,
				onClick: () => this.onTabClick(ReportsIDs.MY_EXPORTS),
				hide: !isUserAllowedToAccess(
					['mnc.endpoints_rw', 'mnc.endpoints_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.MY_EXPORTS) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.USAGE_REPORTS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.USAGE_REPORTS"
						defaultMessage="Usage Reports"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.USAGE_REPORTS}
					/>
				),
				isActive: activeTab === ReportsIDs.USAGE_REPORTS,
				onClick: () => this.onTabClick(ReportsIDs.USAGE_REPORTS),
				hide: !isUserAllowedToAccess(
					['mnc.reports.usage_rw', 'mnc.reports.usage_ro'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.USAGE_REPORTS) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.REPORT_HEADERS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.REPORT_SCHEMA"
						defaultMessage="Report Schema"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.REPORT_HEADERS}
					/>
				),
				isActive: activeTab === ReportsIDs.REPORT_HEADERS,
				onClick: () => this.onTabClick(ReportsIDs.REPORT_HEADERS),
				hide: !isUserAllowedToAccess(
					['mnc.reports_ro', 'mnc.reports_rw'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.REPORT_HEADERS) ||
					downloadReportRequest
			},
			{
				id: ReportsIDs.REPOSTED_REPORTS,
				name: (
					<FormattedMessage
						id="REPORTS_TABS.REPOSTED_REPORTS"
						defaultMessage="Reposted Reports"
					/>
				),
				component: (
					<List
						messages={messages}
						tab={activeTab}
						key={ReportsIDs.REPOSTED_REPORTS}
					/>
				),
				isActive: activeTab === ReportsIDs.REPOSTED_REPORTS,
				onClick: () => this.onTabClick(ReportsIDs.REPOSTED_REPORTS),
				hide: !isUserAllowedToAccess(
					['mnc.reports.repost_rw'],
					user
				),
				disabled:
					(reportsRequest && activeTab !== ReportsIDs.REPOSTED_REPORTS) ||
					downloadReportRequest
			}
		];
	};

	render() {
		const { activeTab } = this.state;
		const reportTabs = activeTab && this.getReportTabs();

		return (
			<div data-spec="reports">
				<PageTitle
					title={<FormattedMessage id="REPORTS" defaultMessage="Reports" />}
				/>
				{activeTab && (
					<>
						<TabsBar tabs={reportTabs.filter((item) => !item.hide)} />
						{renderTabs(reportTabs)}
					</>
				)}
			</div>
		);
	}
}

const { bool, func, shape, objectOf, string } = PropTypes;

Reports.propTypes = {
	reportsRequest: bool,
	downloadReportRequest: bool,
	messages: objectOf(string),
	location: shape(),
	getAnalyticsReportsInit: func,
	getCustomerReportsInit: func,
	user: shape(),
  setDataForFiltersInit: func,
  hideNotification: func
};

Reports.defaultProps = {
	messages: {}
};

export default Reports;
