import { Status } from '../../../../utils/constants';

//  GET SIM ORDERS
export const getSimOrdersRequest = (state) =>
	state.simOrdering.simOrders.getSimOrders.status === Status.PENDING;
export const getSimOrdersFail = (state) =>
	state.simOrdering.simOrders.getSimOrders.status === Status.FAIL;
export const getSimOrdersSuccess = (state) =>
	state.simOrdering.simOrders.getSimOrders.data;
export const getSimOrdersCount = (state) =>
	state.simOrdering.simOrders.getSimOrders.data &&
	state.simOrdering.simOrders.getSimOrders.data.totalCount;

//  GET SIM ORDER
export const getSimOrderRequest = (state) =>
	state.simOrdering.simOrders.getSimOrder.status === Status.PENDING;
export const getSimOrderFail = (state) =>
	state.simOrdering.simOrders.getSimOrder.status === Status.FAIL;
export const getSimOrderSuccess = (state) =>
	state.simOrdering.simOrders.getSimOrder.data;

//  ADD SIM ORDER
export const addSimOrderRequest = (state) =>
	state.simOrdering.simOrders.addSimOrder.status === Status.PENDING;
export const addSimOrderFail = (state) =>
	state.simOrdering.simOrders.addSimOrder.status === Status.FAIL;
export const addSimOrderSuccess = (state) =>
	state.simOrdering.simOrders.addSimOrder.status === Status.DONE;

// EDIT SIM ORDER
export const editSimOrderRequest = (state) =>
	state.simOrdering.simOrders.editSimOrder.status === Status.PENDING;
export const editSimOrderFail = (state) =>
	state.simOrdering.simOrders.editSimOrder.status === Status.FAIL;
export const editSimOrderSuccess = (state) =>
	state.simOrdering.simOrders.editSimOrder.status === Status.DONE;

//  GET SIM ORDERING FILTERS
export const getSimOrderingFiltersRequest = (state) =>
	state.simOrdering.simOrders.getSimOrderingFilters.status === Status.PENDING;
export const getSimOrderingFiltersFail = (state) =>
	state.simOrdering.simOrders.getSimOrderingFilters.status === Status.FAIL;
export const getSimOrderingFiltersSuccess = (state) =>
	state.simOrdering.simOrders.getSimOrderingFilters.data;

// GET SIM ORDERING STATUSES
export const getSimOrderStatusesRequest = (state) =>
	state.simOrdering.simOrders.getSimOrderStatuses.status === Status.PENDING;
export const getSimOrderStatusesFail = (state) =>
	state.simOrdering.simOrders.getSimOrderStatuses.status === Status.FAIL;
export const getSimOrderStatusesSuccess = (state) =>
	state.simOrdering.simOrders.getSimOrderStatuses.data;
