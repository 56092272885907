import React, { PureComponent } from 'react';
import {
	func,
	bool,
	string,
	object,
	array,
} from 'prop-types';
import cn from 'classnames';
import { isEqual, find } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import Select, {
	components
} from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import {
	fifthEntitlementArray,
	firstEntitlementArray,
	fourthEntitlementArray,
	secondEntitlementArray,
	thirdEntitlementArray,
} from '../../../utils/constants';
import {
	UserAllowedToAccess,
} from '../../../../../utils/AuthSelector';
import { getDateFormat, isValidDate } from '../../../../../utils/constants';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';

import styles from '../NetworkEntitlementChangeStepper.scss';

const EndpointsRWComponent = UserAllowedToAccess([
	'mnc.endpoints_rw',
	'mnc.endpoints.networkentitlements_rw',
]);
const Success = ImageSelector(CurrentContext.theme, 'svgs/ok-full.svg');

export default class StepFirst extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			chosenEntitlementId: props.chosenEntitlementId,
			chosenEntitlementName: null,
		};
	}

	componentDidMount() {
		const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context, originalEntitlement } = this.props;
		const { chosenEntitlementId } = this.state;

		if (
			context &&
			!(
				isEqual(
					originalEntitlement,
					prevProps.originalEntitlement
				) &&
				isEqual(chosenEntitlementId, prevState.chosenEntitlementId)
			)
		) {
			this.setFooter(context);
		}
	}

	handleOnClick = () => {
		const { onNext } = this.props;
		const { chosenEntitlementName, chosenEntitlementId } = this.state;

		onNext({
			chosenEntitlementName,
			chosenEntitlementId,
			step: 2
		});
	};

	changeChosenEntitlement = ({ value, label }) => this.setState({
		chosenEntitlementId: value,
		chosenEntitlementName: label
	});

	setFooter = (context) => {
		const { onClose, originalEntitlement } = this.props;
		const { chosenEntitlementId } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage
						id="CANCEL"
						defaultMessage="Cancel"
					/>}
					dataSpec="cancel-button"
				/>
				<EndpointsRWComponent>
					<Button
						variant="primary"
						onClick={this.handleOnClick}
						disabled={
							originalEntitlement &&
							originalEntitlement.id === chosenEntitlementId
						}
						label={<FormattedMessage
							id="CONTINUE"
							defaultMessage="Continue"
						/>}
						dataSpec="continue-button"
					/>
				</EndpointsRWComponent>
			</>
		);

		context.updateContext({ footer });
	};

	getInitialValue = () => {
		const { entitlements } = this.props;
		const { chosenEntitlementId } = this.state;
		const entitlement = find(
			entitlements,
			(item) => item.id === chosenEntitlementId
		);

		return entitlement ? {
			value: entitlement.id,
			label: entitlement.name
		} : null;
	};

	Option = (optionProps) => {
		const { data, children } = optionProps;

		return (
			<components.Option data-spec="recredential-select" {...optionProps}>
				<div data-spec={`entitlement-${data.value}`}>{children}</div>
			</components.Option>
		);
	};

	checkAndGetDateFormat = (value) => (
		isValidDate(value) ? getDateFormat(value) : undefined
	);

	renderInfo = (info) => {
		switch (info) {
			case true:
				return <FormattedMessage
					id="YES"
					defaultMessage="Yes"
					data-spec="render-info-yes-message"
				/>;
			case false:
				return <FormattedMessage
					id="NO"
					defaultMessage="No"
					data-spec="render-info-no-message"
				/>;
			default:
				return info;
		}
	};

	entitlementDataItem = (label, info, grayedOutIfMissing) => (
		<div
			className={styles.entitlements_details_item}
			data-spec="entitlement-item"
			key={label.id}
		>
			<div
				className={cn({
					[styles.entitlements_item_title]: true,
					[styles.grayed_out]: grayedOutIfMissing && info === undefined
				})}
			>
				<FormattedMessage id={label.id} defaultMessage={label.defaultMessage} />
			</div>
			<div className={styles.entitlements_item_data}>
				{info || (grayedOutIfMissing ? '' : '-')}
			</div>
		</div>
	);

	render() {
		const { originalEntitlement, entitlements } = this.props;
		const { chosenEntitlementId, chosenEntitlementName } = this.state;
		const options = entitlements.map((item) => (
			{
				value: item.id,
				label: item.name
			}
		));
		const entitlement = entitlements.find(
			(item) => item.id === chosenEntitlementId
		) || entitlements[0];

		return (
			<div data-spec="first-step">
				<div
					data-spec="ds-modal-body"
					className={cn(styles.custom, styles.step_1)}
				>
					<div className={styles.select_field_label}>
						<FormattedMessage
							id="SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS"
							defaultMessage="Eligible Network Entitlements"
						/>
					</div>
					<div className={styles.select_field_wrapper}>
						<Select
							value={this.getInitialValue()}
							options={options}
							onChange={this.changeChosenEntitlement}
							dataSpec="eligible-network-entitlement"
							components={{ Option: this.Option }}
						/>
					</div>
					<div data-spec="entitlement-info">
						<div className={styles.entitlement_info}>
							<h3>
								<FormattedMessage
									id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS"
									defaultMessage="Network Entitlement Details"
								/>
							</h3>
							{chosenEntitlementName ||
								(originalEntitlement && originalEntitlement.name)}
							<p className={styles.current_network_entitlement}>
								<Success />
								{originalEntitlement &&
								originalEntitlement.id === chosenEntitlementId ? (
									<FormattedMessage
										id="SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT"
										defaultMessage="Currently assigned to this endpoint"
									/>
								) : (
									<FormattedMessage
										id="SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT"
										defaultMessage="Eligible to use with this endpoint"
									/>
								)}
							</p>
						</div>
						{firstEntitlementArray(
							entitlement,
							this.renderInfo
						).map(({ label, info, grayedOutIfMissing }) =>
							this.entitlementDataItem(label, info, grayedOutIfMissing))}
						<div
							className={styles.entitlements_details}
							data-spec="entitlement-details"
						>
							<div className={styles.entitlements_details_container}>
								{secondEntitlementArray(
									entitlement,
									this.renderInfo
								).map(({ label, info, grayedOutIfMissing }) =>
									this.entitlementDataItem(label, info, grayedOutIfMissing))}
							</div>
							<div className={styles.entitlements_details_container}>
								{thirdEntitlementArray(
									entitlement,
									this.renderInfo
								).map(({ label, info, grayedOutIfMissing }) =>
									this.entitlementDataItem(label, info, grayedOutIfMissing))}
							</div>
						</div>
						<div
							className={styles.entitlements_details}
							data-spec="entitlement-details"
						>
							<div className={styles.entitlements_details_container}>
								{fourthEntitlementArray(
									entitlement,
									this.renderInfo
								).map(({ label, info, grayedOutIfMissing }) =>
									this.entitlementDataItem(label, info, grayedOutIfMissing))}
							</div>
							<div className={styles.entitlements_details_container}>
								{fifthEntitlementArray(
									entitlement,
									this.renderInfo
								).map(({ label, info, grayedOutIfMissing }) =>
									this.entitlementDataItem(label, info, grayedOutIfMissing))}
							</div>
						</div>
						<div
							className={cn(styles.entitlements_details, styles.apns)}
							data-spec="entitlement-apn"
						>
							{entitlement &&
								entitlement.onStateProfile &&
								entitlement.onStateProfile.apns &&
								entitlement.onStateProfile.apns.length > 0 &&
								entitlement.onStateProfile.apns.map((itm) => (
									<div
										key={itm.id}
										className={styles.entitlements_details_container}
										data-spec="apn-container"
									>
										{Object.keys(itm).map((key) =>
											this.entitlementDataItem(
												{
													id: `APNS_${key.toUpperCase()}`,
													defaultMessage: key
												},
												this.renderInfo(
													key === 'createDate' ||
														key === 'lastUpdateDate' ||
														key === 'lastUpdateTimestamp'
														? this.checkAndGetDateFormat(itm[key])
														: itm[key]
												),
												false
											))}
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

StepFirst.propTypes = {
	onClose: func,
	onNext: func,
	chosenEntitlementId: string,
	messages: object,
	originalEntitlement: object,
	entitlements: array,
	entitlementApn: object,
	entitlementApnIsFetching: bool,
	m2mAccountId: string,
	context: object,
};
