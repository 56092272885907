import React from 'react';
import PropTypes from 'prop-types';
import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';

import hoc from '../UserGroups';

const Mobile = (props) => {
	const { getOptions, messages, userGroups } = props;
	return (
		<div data-spec="user-groups-mobile">
			<CardsTable
				data-spec="user-groups-table-mobile"
				options={getOptions('mobile')}
				messages={messages}
				data={userGroups.resultList}
			/>
		</div>
	);
};

const { func, object, shape } = PropTypes;

Mobile.propTypes = {
	getOptions: func,
	messages: object,
	userGroups: shape()
};

export default hoc(Mobile);
