import React from 'react';
import PropTypes from 'prop-types';
import hoc from '../ActionBar';

import SearchBar from '../../../views/SearchBarView';
import NewSearchBar from '../../../views/NewSearchBarView';

import styles from './Mobile.scss';

class Mobile extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {
			menu,
			simpleSearchParameter,
			onSearchSubmit,
      isSimpleSearchActive,
      onSearchSubmitCallback
		} = this.props;

		return (
			<div data-spec="action-bar-mobile" className={styles.action_bar}>
				{onSearchSubmit && (
					<SearchBar
						menu={menu}
						onSubmit={onSearchSubmit}
						simpleSearchParameter={simpleSearchParameter}
						isSimpleSearchActive={isSimpleSearchActive}
					/>
				)}

				{onSearchSubmitCallback && menu && (
					<NewSearchBar
						onSearchSubmitCallback={onSearchSubmitCallback}
						menu={menu}
					/>
				)}
			</div>
		);
	}
}

const { bool, func, arrayOf, object } = PropTypes;

Mobile.propTypes = {
	menu: arrayOf(object),
	simpleSearchParameter: arrayOf(object),
	onSearchSubmit: func,
  isSimpleSearchActive: bool,
  onSearchSubmitCallback: func
};

export default hoc()(Mobile);
