import { connect } from 'react-redux';

import SatelliteDetails from './SatelliteDetails';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import { getAvailableStates } from '../../../Endpoint/redux/actions';
import { getAvailableStatesData } from '../../../Endpoint/redux/selectors';

const mapStateToProps = (state) => ({
	availableStates: getAvailableStatesData(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAvailableStates: () => dispatch(getAvailableStates())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SatelliteDetails);
