import React from 'react';
import PropTypes from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import { MobileNotSupported } from '../../../Shared/components/NotSupported';
import AccountOverviewAdmin from '../../components/AccountOverviewAdmin';

const AccountOverviewAdminView = ({ dispatch, messages }) => (
	<>
		<DesktopMediaQuery>
			<AccountOverviewAdmin dispatch={dispatch} messages={messages} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<AccountOverviewAdmin dispatch={dispatch} messages={messages} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<MobileNotSupported />
		</MobileMediaQuery>
	</>
);

const { func, objectOf, string } = PropTypes;

AccountOverviewAdminView.propTypes = {
	dispatch: func,
	messages: objectOf(string)
};

AccountOverviewAdminView.defaultProps = {
	dispatch: undefined,
	messages: {}
};

export default AccountOverviewAdminView;
