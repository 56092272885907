export default {
	'BATCH_HISTORY.LINK': 'Batch History',
	'BATCH_HISTORY.TITLE': 'Batch History',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Batch Files',
	'BATCH_HISTORY.BATCH_REPORTS': 'Batch Summary',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'No Batch Actions Available',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE':
		'There are No Batch Actions for this Table to show you right now',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'No Batch Summary Available',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE':
		'There is No Batch Summary for this Table to show you right now',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Batch File Name',
	'BATCH_HISTORY.CREATED_BY': 'Created By',
	'BATCH_HISTORY.DATE_UPLOADED': 'Date Uploaded',
	'BATCH_HISTORY.STATUS': 'Batch Status',
	'BATCH_HISTORY.REPORT_NAME': 'Report',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'M2M Account ID',
	'BATCH_HISTORY.DATE': 'Date Created',
	'BATCH_HISTORY.M2M_PLATFORM': 'M2M Platform',
	'BATCH_HISTORY.FREQUENCY': 'Frequency',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Submitted',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Frequency',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'In-Progress',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Completed',
	'BATCH_HISTORY.ERROR_STATUS': 'Error',
	'BATCH_HISTORY.CANCELLED_STATUS': 'Cancelled',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP':
		'The file uploaded successfully for processing',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP':
		'The file uploaded successfully, scheduled for processing',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Processing underway',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'The file was processed',
	'BATCH_HISTORY.ERROR_TOOLTIP': 'File was not processed. File must be uploaded again.',
	'BATCH_HISTORY.CANCELLED_TOOLTIP': 'Cancelled Tooltip',
	'BATCH_HISTORY.SUCCESS_RATE': 'Success Rate',
	'BATCH_HISTORY.DETAILS': 'Details',
	'BATCH_HISTORY.PENDING_STATUS': 'Pending',
	'BATCH_HISTORY.PENDING_TOOLTIP': 'The file is being processed',
};
