import { Status } from '../../../../utils/constants';

export const getSurchargesRequest = (state) =>
	state.analyticsInvoices.tables.surcharges.getSurcharges.status ===
	Status.PENDING;
export const getSurchargesFail = (state) =>
	state.analyticsInvoices.tables.surcharges.getSurcharges.status ===
	Status.FAIL;
export const getSurchargesSuccess = (state) =>
	state.analyticsInvoices.tables.surcharges.getSurcharges.data;

export const getSurchargesFiltersRequest = (state) =>
	state.analyticsInvoices.tables.surcharges.getSurchargesFilters.status ===
	Status.PENDING;
export const getSurchargesFiltersFail = (state) =>
	state.analyticsInvoices.tables.surcharges.getSurchargesFilters.status ===
	Status.FAIL;
export const getSurchargesFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.surcharges.getSurchargesFilters.data;
