import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { cloneDeep, debounce, find, isEmpty, isEqual, orderBy } from 'lodash';

import ReactTooltip from 'react-tooltip';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';
import EndpointsDataView from '../../views/EndpointsDataView';
import {
	ComponentsRender,
	UserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import { DesktopAndTabletMediaQuery } from '../../../../utils/CustomMediaQuery';
import { tableHeaderPermissionsCheck } from '../../../../utils/constants';
// Endpoints
import {
	endpointSignature,
	EndpointsTableTabs as tableHeader,
	notificationMessages,
	realPropName,
	getDateFormat,
  isValidDate,
  endpointsAdvancedSearchMenu
} from '../../utils/constants';

import Button from '../../../../lib/DigitalComponents/Button';
import styles from './Endpoints.scss';

import Loader from '../../../../lib/DigitalComponents/Loader';

import {
	mapAdvancedSearchParameter,
	checkIfAdvancedSearchParameter
} from '../../../../utils/searchHelpers';

const BulkActionsRWComponent = ComponentsRender('mnc.endpoints.batchupload_rw');
const MultiEndpointActionsComponent = UserAllowedToAccess([
	'mnc.endpoints_rw',
	'mnc.endpoints.simstatus_rw',
	'mnc.endpoints.rateplans_rw',
	'mnc.endpoints.networkentitlements_rw'
]);

const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-maximizeL.svg');
class Endpoints extends React.Component {
	// add new tab
	addAction = debounce(
		() => {
			const {
				createUserSettings,
				setEndpointsActiveTab,
				dataLimit,
				setDataForFiltersInit,
				setEndpointsPaginationInit
			} = this.props;
			const { settings } = this.state;

			if (find(settings, { name: 'tab' })) {
				if (settings.length >= 6) {
					this.setState({
						allowAddAction: false
					});
					return;
				}
			}

			const tabNumber = () =>
				settings.map((item) => +item.name.substr(3)).sort((a, b) => a - b)[
					settings.length - 1
				] + 1 || 1;
			const setTabName = () => `Table ${tabNumber()}`;

			const newValue = {
				name: `tab${tabNumber()}`,
				refTypeId: 1,
				value: JSON.stringify({
					signature: endpointSignature,
					data: [
						{
							tabName: setTabName(),
							value: 0
						},
						...tableHeader[0].value.data
							.filter((item) => item.checked)
							.map((item, index) => ({
								title: item.title,
								name: item.name,
								value: index + 1
							}))
					]
				})
			};
			createUserSettings(newValue, notificationMessages);
			setEndpointsPaginationInit();
			this.getEndpointsData(
				{
					dataLimit,
					dataOffset: 0
				},
				this.mergeFiltersAndSimpleSearchParameters(false, false)
			);
			setDataForFiltersInit();
			this.setActiveSearch(false);
			setEndpointsActiveTab(`tab${tabNumber()}`);
		},
		500,
		{
			leading: true,
			trailing: false
		}
	);

	constructor(props) {
		super(props);
		this.state = {
			tooltipDisabled: false,
			selectedSims: [],
			allowAddAction: true,
			settings: JSON.parse(props.settings),
			activeSearchParameter: null
		};
	}

	componentDidMount() {
		const {
			dataLimit,
			fetchRatePlans,
			getCustomLabels,
			pagination,
			activeTab,
			getAvailableStates
		} = this.props;
		const { settings } = this.state;

		this.getEndpointsData(
			{
				dataLimit,
				dataOffset: dataLimit * pagination
			},
			this.mergeFiltersAndSimpleSearchParameters(
				true,
				false,
				find(settings, { name: 'tab' })
					? JSON.parse(find(settings, { name: activeTab || 'tab' }).value)
							.data[0].filters
					: {}
      ),
      this.getOrderColumn()
		);
		getCustomLabels();
		fetchRatePlans();
		getAvailableStates();
		if (find(settings, { name: 'tab' })) {
			if (settings.length >= 6) {
				this.setState({
					allowAddAction: false
				});
			} else {
				this.setState({
					allowAddAction: true
				});
			}
		}
		this.getSavedFilters(true);
	}

	componentDidUpdate(prevProps) {
		const {
			activeTab,
			updateUserSettings,
			dataLimit,
			updateUserSettingsSuccess,
			setEndpointsPaginationInit,
			createUserSettingsSuccess,
			deleteUserSettingsSuccess,
			createUserSettingsData,
			settings: newSettings
		} = this.props;
		const { settings } = this.state;

		const activeTabSetting = find(settings, {
			name: activeTab
		});

		if (prevProps.activeTab !== activeTab && activeTabSetting) {
      const userSignature = JSON.parse(activeTabSetting.value).signature;

			if (endpointSignature !== userSignature) {
        const tabData = JSON.parse(activeTabSetting.value);
        const { filters } = tabData.data[0];
				const newValue = {
					name: activeTabSetting.name,
					refTypeId: 1,
					value: JSON.stringify({
						signature: endpointSignature,
						data: [
							{
								tabName: tabData.data[0].tabName,
                value: 0,
                filters
							},
							...tableHeader[0].value.data
								.filter((item) => item.checked)
								.map((item, index) => ({
									title: item.title,
									name: item.name,
									value: index + 1
								}))
						]
					})
        };
        updateUserSettings(newValue, activeTabSetting.id, notificationMessages);
        this.getEndpointsData({
					dataLimit,
					dataOffset: 0
				});
			}
		}

		if (prevProps.activeTab !== activeTab) {
			this.getSavedFilters(true);
		}

		if (
			prevProps.updateUserSettingsSuccess !== updateUserSettingsSuccess &&
			updateUserSettingsSuccess
		) {
			this.getEndpointsData(
				{
					dataLimit,
					dataOffset: 0
				},
        this.mergeFiltersAndSimpleSearchParameters(),
        this.getOrderColumn()
			);
			setEndpointsPaginationInit();
		}

		if (!prevProps.createUserSettingsSuccess && createUserSettingsSuccess) {
			const allowAddAction = [...settings, createUserSettingsData].length <= 6;
			this.setAllowAddAction(allowAddAction);
			this.getEndpointsData(
				{
					dataLimit,
					dataOffset: 0
				},
				this.mergeFiltersAndSimpleSearchParameters(),
				this.getOrderColumn()
			);
			setEndpointsPaginationInit();
		}

		if (!prevProps.deleteUserSettingsSuccess && deleteUserSettingsSuccess) {
			this.setActiveTab('tab');
			const allowAddAction =
				settings.filter(
					(item) =>
						item.name !==
						(createUserSettingsData && createUserSettingsData.name)
				).length <= 6;
			this.setAllowAddAction(allowAddAction);
		}

		if (!isEqual(prevProps.settings, newSettings)) {
			this.setSettings(JSON.parse(newSettings));
		}
	}

	setAllowAddAction = (allowAddAction) =>
		this.setState({
			allowAddAction
		});

	setSettings = (settings) => this.setState({ settings });

	formatFilterLabel = (label, item) => {
		const { messages } = this.props;
		switch (item) {
			case 'M2MDateAdded':
			case 'M2MInitialActivationDate':
				return isValidDate(label)
					? getDateFormat(label)
					: messages[label] || label;
			default:
				return messages[label] || label;
		}
	};

	getSavedFilters = (data) => {
		const { activeTab, setFilterData } = this.props;
		const { settings } = this.state;
		const tab = find(settings, { name: activeTab });
		// Check if flag is present (true) for logic behind prefetching filters
		if (data && tab) {
			const tabData = JSON.parse(tab.value);
			const { filters } = tabData.data[0];
			if (!isEmpty(filters)) {
				const preparedFilters = this.createFilterData(filters);
				setFilterData(preparedFilters);
				return filters || {};
			}
		} else if (tab) {
			const tabData = JSON.parse(tab.value);
			const { filters } = tabData.data[0];
			return filters || {};
		}
		return {};
	};

	createFilterData = (data) => {
		const savedFilters = this.getSavedFilters();
		const filterDataKeys = Object.keys(data);
		let filter = {};
		filterDataKeys.length &&
			filterDataKeys.forEach((item) => {
				filter = {
					...filter,
					[item]: data[item].map((a) => ({
						value: a,
						label: this.formatFilterLabel(a, item),
						checked: !!(
							savedFilters[item] && savedFilters[item].indexOf(a) !== -1
						)
					}))
				};
			});
		return filter;
	};

	mergeFiltersAndSimpleSearchParameters = (
		includeSearch = true,
		includeFilterData = true,
		filters = null
	) => {
		const { searchParameter } = this.props;
		const savedFilters = this.getSavedFilters();
		const filterData = includeFilterData ? savedFilters : filters;
    let query = [];
    let searchParams = [];

		if ((!isEmpty(filterData) && includeFilterData) || filters) {
			query = Object.keys(filterData)
				.filter((key) => filterData[key].length > 0)
				.map((key) => ({
					propValue: filterData[key],
					prop: realPropName[key],
					operator: 'in'
				}));
		}

		if (
			searchParameter &&
      includeSearch &&
      searchParameter.propValue &&
			searchParameter.propValue.trim().length > 0
		) {
			if (
				checkIfAdvancedSearchParameter(
					endpointsAdvancedSearchMenu,
					searchParameter
				)
			) {
				query = [
					...query,
					{
						...mapAdvancedSearchParameter(searchParameter),
						operator: 'or_eq_like'
					}
				];
			} else {
				query = [
					...query,
					{
						...searchParameter,
						operator: '='
					}
				];
			}
		}

    if (query.length) {
      searchParams = query.filter((m) => m.propValue && m.prop && m.operator);
    }

		return searchParams;
	};

	renderSearchTab = (message) => {
		const { tooltipDisabled } = this.state;
		return (
			<span
				onMouseEnter={this.handleMouseOverLeave.bind(this)}
				onMouseLeave={this.handleMouseOverLeave.bind(this)}
				data-spec="tooltip-container"
				className={styles.data}
			>
				<ReactTooltip
					disable={tooltipDisabled}
					className={styles.tooltip}
					type="light"
					id="searchTooltip"
				/>
				<span
					data-tip={message}
					data-for="searchTooltip"
					className={styles.message}
				>
					{message}
				</span>
			</span>
		);
	};

	setActiveTab = (tab) => {
		const {
			setEndpointsActiveTab,
			setFilterData,
			dataLimit,
			setDataForFiltersInit,
			setEndpointsOrderColumn,
			setEndpointsPaginationInit
		} = this.props;
		const { settings } = this.state;
		setDataForFiltersInit();
    setEndpointsActiveTab(tab);
		const tabData = find(settings, { name: tab });
		let filters = null;
		if (tabData) {
			filters = JSON.parse(tabData.value).data[0].filters;
			if (filters) {
				setFilterData(this.createFilterData(filters));
			}
		}
		setEndpointsPaginationInit();
		this.setState({
			selectedSims: []
		});
		setEndpointsOrderColumn({});
		this.getEndpointsData(
			{
				dataLimit,
				dataOffset: 0
			},
			this.mergeFiltersAndSimpleSearchParameters(false, false, filters)
		);
		this.setActiveSearch(false);
	};

	totalCount = () => {
		const { endpoints, dataLimit } = this.props;
		return Math.ceil(endpoints && endpoints.totalCount / dataLimit);
	};

	renderEndpointsTabs = () => {
		const { messages, activeTab } = this.props;
		const { settings, activeSearchParameter } = this.state;
		const tempSettings = cloneDeep(settings);
		const responseTabs = orderBy(
			tempSettings.map((item) => {
				item.id = +item.name.substr(3);
				return item;
			}),
			['id', 'asc']
		);

		const hasValue = messages[tableHeader[0].value.data[0].tabName]
			? messages[tableHeader[0].value.data[0].tabName]
			: tableHeader[0].value.data[0].tabName;

		let tabs = find(responseTabs, { name: 'tab' })
			? []
			: [
					{
						name:
							activeSearchParameter && tableHeader[0].name === activeTab
								? this.renderSearchTab(activeSearchParameter)
								: hasValue,
						isActive: tableHeader[0].name === activeTab,
						onClick: () => this.setActiveTab(tableHeader[0].name),
						isSearch: activeSearchParameter
					}
			  ];

		responseTabs.forEach((item) => {
			const itemValue = JSON.parse(item.value);
			const hasValues = messages[itemValue.data[0].tabName]
				? messages[itemValue.data[0].tabName]
				: itemValue.data[0].tabName;

			if (itemValue.data) {
				tabs = [
					...tabs,
					{
						name:
							activeSearchParameter && item.name === activeTab
								? this.renderSearchTab(activeSearchParameter)
								: hasValues,
						isActive: item.name === activeTab,
						onClick: () => this.setActiveTab(item.name),
						isSearch: activeSearchParameter
					}
				];
			}
		});

		return tabs;
	};

	getOrderColumn = () => {
		const { orderColumn } = this.props;
		return !isEmpty(orderColumn) ? orderColumn.apiString : null;
	};

	handlePageClick = (data) => {
    const { dataLimit, setEndpointsPagination } = this.props;

		this.getEndpointsData(
			{
				dataLimit,
				dataOffset: dataLimit * data.selected
			},
			this.mergeFiltersAndSimpleSearchParameters(),
			this.getOrderColumn()
		);
		this.setState({
			selectedSims: []
		});
		setEndpointsPagination(data.selected);
		window.scrollTo(0, 0);
	};

	setActiveSearch = (searchParam) => {
		if (searchParam) {
			this.setState({
				activeSearchParameter: searchParam.propValue
			});
		} else {
			this.setState({
				activeSearchParameter: null
			});
		}
	};

	getEndpointsData = (paginationData, searchData, dataSort) => {
    const { getEndpointsNew, hideNotification } = this.props;

    hideNotification('endpoints-fail');

		const params = {
			additionalParams: {
				...paginationData
			}
		};

		if (dataSort) {
			params.additionalParams.dataSort = dataSort;
		}

		if (searchData && searchData.length > 0) {
			params.searchParams = [...searchData];
		}

		getEndpointsNew(params);
	};

	onSearchSubmit = (includeSearch) => {
		const {
			dataLimit,
			setEndpointsPaginationInit,
			searchParameter
		} = this.props;
		this.setActiveSearch(searchParameter);
		this.getEndpointsData(
			{
				dataLimit,
				dataOffset: 0
			},
      this.mergeFiltersAndSimpleSearchParameters(includeSearch),
      this.getOrderColumn()
		);
		setEndpointsPaginationInit();
	};

	pushBack = () => {
		const { setSearchParameterInit } = this.props;

		setSearchParameterInit();
		this.onSearchSubmit(false);
		this.setActiveSearch(false);
	};

	setSearchInit = () => {
		const { setSearchParameterInit } = this.props;

		setSearchParameterInit();
		this.setActiveSearch(false);
	};

	// Table Functions Start
	onCheckboxChange = (data) => {
		const { endpoints } = this.props;
		let selectedIndexes = [];
		if (data && data.selected && endpoints) {
			selectedIndexes = data.selected.map((item) => endpoints.resultList[item]);
		}
		this.setState({
			selectedSims: [...selectedIndexes]
		});
	};

	onCellClick = (data) => {
		const { goToSingleEndpoint } = this.props;
		goToSingleEndpoint(data);
	};

	onOrderClick = (data) => {
		const {
			dataLimit,
			orderColumn,
			pagination,
			setEndpointsOrderColumn,
			setEndpointsPagination
		} = this.props;
		const column = data.name;
		const { order, apiString } = data;

		setEndpointsOrderColumn({
			name: column,
			order: orderColumn.name === column ? !orderColumn.order : true,
			apiString
		});
		this.getEndpointsData(
			{
				dataLimit,
				dataOffset: dataLimit * pagination
			},
			this.mergeFiltersAndSimpleSearchParameters(),
			`${column} ${order}`
		);
		setEndpointsPagination(pagination);
		this.clearSelectedSims();
	};

	onDragEnd = (fromIndex, toIndex) => {
		const {
			activeTab,
			updateUserSettings,
			createUserSettings,
			user
		} = this.props;
		const { settings } = this.state;

		if (toIndex < 0) return;

		const data = tableHeaderPermissionsCheck(this.getTableHeaders(), user);
		const filters = this.getSavedFilters();

		[data[fromIndex - 1], data[toIndex - 1]] = [
			data[toIndex - 1],
			data[fromIndex - 1]
		];

		const tabData = find(settings, {
			name: activeTab
		});
		const tabName = tabData
			? JSON.parse(tabData.value).data[0].tabName
			: tableHeader[0].value.data[0].tabName;

		const newValue = {
			name: activeTab,
			refTypeId: 1,
			value: JSON.stringify({
				signature: endpointSignature,
				data: [
					{ tabName, value: 0, filters },
					...data.map((item, index) => ({
						title: item.title,
						name: item.name,
						refTypeId: 1,
						value: index + 1
					}))
				]
			})
		};

		if (tabData) {
			updateUserSettings(newValue, tabData.id, notificationMessages);
		} else {
			createUserSettings(newValue, notificationMessages);
		}
	};

	getTableHeaders = () => {
		const { customFieldLabels, activeTab } = this.props;
		const { settings } = this.state;
		let columns = [];
		let customColumns = [];

		if (customFieldLabels) {
			Object.keys(customFieldLabels).forEach((key) => {
				if (key.startsWith('customLabel') && key !== 'messageId') {
					customColumns = [
						...customColumns,
						{
							name: customFieldLabels[key]
						}
					];
				}
			});
		}

		const activeTabValue = find(settings, {
			name: activeTab
		});

		if (settings.length > 0 && activeTabValue) {
			const items = orderBy(
				JSON.parse(activeTabValue.value).data,
				['value'],
				['asc']
			);
			items.forEach((item, index) => {
				if (item.name) {
					if (item.name.startsWith('customFieldValue')) {
						const customColumnRule = customColumns[item.name.match(/\d+/g) - 1];
						columns = [
							...columns,
							{
								title: customColumnRule && customColumnRule.name,
								name: `customFieldValue${item.name.match(/\d+/g)}`,
								value: item.value
							}
						];
					} else {
						columns = [
							...columns,
							{
								title: items[index].title,
								name: items[index].name,
								value: item.value
							}
						];
					}
				}
			});
		} else {
			columns = find(tableHeader, { name: 'tab' }).value.data.filter(
				(item) => item.checked === true
			);
		}
		return columns;
	};

	handleMouseOverLeave = () => {
		this.setState({
			tooltipDisabled: window.innerWidth > 1550
		});
	};

	renderTopActionBar = () => {
		const { intl, openBatchUploadModal } = this.props;
		const { selectedSims } = this.state;

		return (
			<div className={styles.right} data-spec="endpoints-action-bar-top">
				<MultiEndpointActionsComponent>
					<div
						className={styles.mr10}
						data-tip={intl.formatMessage({
							id: 'ENDPOINTS.PLEASE_SELECT_MORE_SIMS',
							defaultMessage: 'Please Select More Sims'
						})}
						data-for="selectMoreSims"
					>
						{selectedSims.length < 2 && (
							<ReactTooltip
								className={styles.tooltip}
								type="light"
								id="selectMoreSims"
							/>
						)}
						<Button
							variant="outline-primary"
							dataSpec="endpoint-action"
							label={
								<FormattedMessage
									id="MULTI_ENDPOINT_ACTION"
									defaultMessage="Multi-endpoint action"
								/>
							}
							disabled={selectedSims.length < 2}
							onClick={() => openBatchUploadModal('change')}
						/>
					</div>
				</MultiEndpointActionsComponent>
				<BulkActionsRWComponent>
					<Button
						dataSpec="bulk-upload"
						variant="outline-primary"
						label={
							<FormattedMessage id="ENDPOINTS.BULK_UPLOAD" defaultMessage="Batch upload" />
						}
						onClick={() => openBatchUploadModal('upload')}
					/>
				</BulkActionsRWComponent>
			</div>
		);
	};

	clearSelectedSims = () => {
		this.setState({
			selectedSims: []
		});
	};

	render() {
		const {
			endpoints,
			availableStates,
			ratePlans,
			user,
			pagination,
      dataLimit,
      messages,
			intl,
			deleteUserSettingsRequest,
			searchParameter
		} = this.props;
		const {
			allowAddAction,
			selectedSims,
			settings,
			activeSearchParameter
		} = this.state;

		return (
			<div data-spec="endpoints">
				<PageTitle
					title={
						activeSearchParameter ? (
							<FormattedMessage
								id="SEARCH_RESULTS"
								defaultMessage="Search results"
							/>
						) : (
							<FormattedMessage id="ENDPOINTS" defaultMessage="Endpoints" />
						)
					}
					pushBack={activeSearchParameter && this.pushBack}
				/>
				{!deleteUserSettingsRequest ? (
					<div>
						<div data-spec="tabs-bar" className={styles.tabs}>
							<div className={styles.tabs_wrapper}>
								<TabsBar
									tabs={this.renderEndpointsTabs()}
									className={styles.tabs_bar}
									actions={{
										add: true,
										allowAddAction,
										addAction: this.addAction,
										customAddComponent: () => (
											<>
												<ReactTooltip
													className={styles.tooltip}
													type="light"
													id="addTooltip"
												/>
												<AddIcon
													className={styles.add_icon}
													data-for="addTooltip"
													data-tip={intl.formatMessage({
														id: 'TABS_ADD_ICON',
														defaultMessage: 'Create Custom Endpoints Table'
													})}
												/>
											</>
										)
									}}
								/>
							</div>
							<DesktopAndTabletMediaQuery>
								{this.renderTopActionBar()}
							</DesktopAndTabletMediaQuery>
						</div>
						<EndpointsDataView
							data={endpoints}
							tableHeader={this.getTableHeaders()}
							availableStates={availableStates}
							ratePlans={ratePlans}
							changeStatus={this.changeStatus}
							user={user}
							messages={messages}
							settings={settings}
							onCheckboxChange={this.onCheckboxChange}
							onCellClick={this.onCellClick}
							onOrderClick={this.onOrderClick}
							onNoteClick={this.onNoteClick}
							onDragEnd={this.onDragEnd}
							handlePageClick={this.handlePageClick}
							totalCount={this.totalCount()}
							forcePage={pagination}
							selectedPage={pagination}
							showPagination={endpoints && endpoints.totalCount > dataLimit}
							createFilterData={this.createFilterData}
							getSavedFilters={this.getSavedFilters}
							dataLimit={dataLimit}
							selectedSims={selectedSims}
							clearSelectedSims={this.clearSelectedSims}
							mergeFiltersAndSimpleSearchParameters={
								this.mergeFiltersAndSimpleSearchParameters
							}
							getEndpoints={this.getEndpointsData}
							searchParameter={searchParameter}
							onSearchSubmit={this.onSearchSubmit}
							setSearchInit={this.setSearchInit}
						/>
					</div>
				) : (
					<Loader />
				)}
			</div>
		);
	}
}
const { object, bool, string, array, func, number } = PropTypes;
Endpoints.propTypes = {
	setEndpointsActiveTab: func,
  getEndpointsNew: func,
  getCustomLabels: func,
	getAvailableStates: func,
	updateUserSettings: func,
	createUserSettings: func,
	fetchRatePlans: func,
	openBatchUploadModal: func,
	setDataForFiltersInit: func,
  setEndpointsPagination: func,
  setEndpointsPaginationInit: func,
	setFilterData: func,
	setEndpointsOrderColumn: func,
	goToSingleEndpoint: func,
	messages: object,
	pagination: number,
	customFieldLabels: object,
	availableStates: object,
	orderColumn: object,
	user: object,
	intl: object,
	createUserSettingsData: object,
	deleteUserSettingsRequest: bool,
	createUserSettingsSuccess: bool,
	deleteUserSettingsSuccess: bool,
	updateUserSettingsSuccess: bool,
	endpoints: object,
	ratePlans: array,
	settings: string,
	dataLimit: number,
	activeTab: string,
	searchParameter: object,
  setSearchParameterInit: func,
  hideNotification: func
};

export default injectIntl(Endpoints);
