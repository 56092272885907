import { handleActions } from 'redux-actions';

import { Status } from '../../../../utils/constants';

import {
	GET_ANALYTICS_DIRECTIONAL_INDEX_REQUEST,
	GET_ANALYTICS_DIRECTIONAL_INDEX_SUCCESS,
	GET_ANALYTICS_DIRECTIONAL_INDEX_ERROR
} from '../constants';

const initialState = {
	data: null,
	error: null,
	status: Status.INIT,
	dispatcher: ''
};

export default handleActions(
	{
		[GET_ANALYTICS_DIRECTIONAL_INDEX_REQUEST]: (state, action) => ({
			...state,
			error: null,
			status: { ...state.status, [action.payload.id]: Status.PENDING },
			dispatcher: action.payload.id
		}),
		[GET_ANALYTICS_DIRECTIONAL_INDEX_SUCCESS]: (state, action) => ({
			...state,
			data: { ...state.data, [action.payload.id]: action.payload.data },
			error: null,
			status: { ...state.status, [action.payload.id]: Status.DONE },
			dispatcher: action.payload.id
		}),
		[GET_ANALYTICS_DIRECTIONAL_INDEX_ERROR]: (state, action) => ({
			...state,
			error: action.payload.error,
			status: { ...state.status, [action.payload.id]: Status.FAIL },
			dispatcher: action.payload.id
		})
	},
	initialState
);
