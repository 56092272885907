import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { isEmpty, capitalize } from 'lodash';
import { isValid, endOfDay } from 'date-fns';

import ActionBar from '../../../Shared/views/ActionBarView';
import FilterModal from '../../../Shared/components/FilterModal';
import Loader from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';

import { Date as DateNew, DateOld } from '../Date';

import { getReportsFilters as getReportsFiltersService } from '../../services/ReportsService';
import { reportsFiltersService } from '../../utils/reportFilters';

import {
	ReportsTableHeader,
	ReportsTableHeaderMobile,
	CustomerReportsTableHeader,
  CustomerReportsTableHeaderMobile,
  ReportHeadersTableHeader,
  ReportHeadersTableHeaderMobile,
  RepostedReportsTableHeader,
  reportsRefTypeId,
  RepostedReportsTableHeaderMobile
} from '../../utils/constants';

import {
	filterHelpers,
	newOnPageChange,
	onOrderClick
} from '../../../../utils/tableHelpers';

import {
	getSearchMenu,
	isIOTTab,
	ReportsIDs,
	isReportHeadersTab,
	isRepostedReportsTab
} from '../../utils/helpers';

import styles from './List.scss';

import { getUTCDate } from '../../../AnalyticsInvoices/utils/helpers';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

const List = () => (WrappedComponent) => {
	class ReportsList extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				orderColumn: {}
			};
		}

		getSearchParams = () => {
			const { searchParameter } = this.props;
			let searchParams = [];
			if (searchParameter) {
				if (['report_date'].includes(searchParameter.prop)) {
					if (isValid(getUTCDate(new Date(searchParameter.propValue)))) {
						searchParams = [
							{
								operator: '>=',
								prop: searchParameter.prop,
								propValue: getUTCDate(
									new Date(searchParameter.propValue)
								).toISOString()
							},
							{
								operator: '<=',
								prop: searchParameter.prop,
								propValue: endOfDay(
									getUTCDate(new Date(searchParameter.propValue))
								)
							}
						];
					}
				} else if (searchParameter.prop === 'report_name') {
					searchParams = [
						{
							operator: 'iLike',
							prop: searchParameter.prop,
							propValue: `${searchParameter.propValue}%`
						}
					];
				} else {
					searchParams = [
						{
							operator: '=',
							prop: searchParameter.prop,
							propValue: Number.isNaN(Number(searchParameter.propValue))
								? 0
								: parseInt(searchParameter.propValue, 10)
						}
					];
				}
			}
			return searchParams;
		};

		getReports = () => {
			const {
				dataLimit,
				getAnalyticsReports,
				getCustomerReports,
				filterData,
				dataOffset,
				searchParameter,
				tab
			} = this.props;

			const { orderColumn } = this.state;

			if (isIOTTab(tab) || isRepostedReportsTab(tab)) {
				const serviceProvider =
					!isEmpty(filterData) &&
					filterData.serviceProvider &&
					filterData.serviceProvider
						.filter((item) => item.checked)
						.map((item) => item.value)[0];
				const reportFrequency =
					!isEmpty(filterData) &&
					filterData.reportFrequency &&
					filterData.reportFrequency
						.filter((item) => item.checked)
						.map((item) => capitalize(item.value))[0];
				return getCustomerReports({
					tab:
						(tab === 'MyExports' && 'my-exports') ||
						(tab === 'UsageReports' && 'usage') ||
						(tab === 'REPOSTED_REPORTS' && 'reposted-reports'),
					params: {
						...(serviceProvider && { serviceProvider }),
						...(reportFrequency && { reportFrequency }),
						...(searchParameter &&
							searchParameter.propValue && {
								reportFileName: searchParameter.propValue
							})
					}
				});
			}

			const realProps = {
				m2m_acc_id: 'm2m_acc_id',
				m2m_platform: 'm2m_platform',
				report_category: 'report_category',
				frequency: 'frequency',
				report_name: 'report_name'
			};

			const searchParams = this.getSearchParams();

			let filterParams = [];

			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}
			const apiTab = tab.toLowerCase().replace(/_/g, '-');
			const params = {
				searchParams: [
					...searchParams,
					...filterParams,
					{
						operator: '=',
						prop: 'report_category',
						propValue: tab
					}
				],
				additionalParams: {
					dataOffset,
					dataLimit
				}
			};
			// 	Ordering
			params.additionalParams.dataSort = !isEmpty(orderColumn)
			? `${orderColumn.apiString},details report_date desc`
			: 'report_name asc, details report_date desc';

			getAnalyticsReports({ tab: apiTab, params });
		};

		processIOTReportsData = (reportsData) => {
			let newReportsData = [];

			reportsData &&
				reportsData.map(
					(item) =>
						item.data &&
						item.data.forEach((data) => {
							newReportsData = [
								...newReportsData,
								{
									...data,
									reportName: item.reportName
								}
							];
						})
				);
			return newReportsData;
		};

		processRepostedReportsData = (reportsData) => {
			let newReportsData = [];

			if (reportsData) {
				newReportsData = reportsData &&
					reportsData.map((item) => (
						{
							...item,
							fileDate: new Date(item.yearOfGeneration, parseInt(item.monthOfGeneration, 10) - 1, item.dateOfGeneration).toLocaleDateString('en-US'),
							repostedOn: item.repostYearOfGeneration && item.repostMonthOfGeneration && item.repostDateOfGeneration ? new Date(item.repostYearOfGeneration, parseInt(item.repostMonthOfGeneration, 10) - 1, item.repostDateOfGeneration).toLocaleDateString('en-US') : ''
						}));
			}
			return newReportsData;
		}

		getTableData = () => {
			const { tab, reports } = this.props;
			const data =
				(isIOTTab(tab) && this.processIOTReportsData(reports && reports.resultList)) ||
				(isRepostedReportsTab(tab) && this.processRepostedReportsData(reports.reports)) ||
				(reports && reports.resultList);
			return data;
		};

		onSearchSubmitCallback = () => {
			this.getReports();
		};

		onApply = () => {
			this.getReports();
		};

		clearFilters = () => {
			this.getReports();
		};

		onChange = () => {
			this.getReports();
		};

		getReportTabUserSettings = () => {
			const { userSettings, tab } = this.props;
			return userSettings.find((x) => x.name === tab && x.refTypeId === 12);
		};

		createFilterData = (data) => {
			// const { activeTab } = this.state;
			const { messages } = this.props;
			const savedFilters =
				this.getReportTabUserSettings() &&
				JSON.parse(this.getReportTabUserSettings().value).filters;

			const filterDataKeys = Object.keys(data);
			let filter = {};
			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					filter = {
						...filter,
						[item]: data[item].map((filterItem) => ({
							value: filterItem,
							label: messages[filterItem.toUpperCase()] || filterItem,
							// check if there is this label in user settings, if yes checked true
							checked: !!(
								savedFilters &&
								savedFilters[item] &&
								savedFilters[item].indexOf(filterItem) !== -1
							)
						}))
					};
				});

			return filter;
		};

		totalCount = () => {
			const { reports, dataLimit } = this.props;
			return Math.ceil(reports.totalCount / dataLimit);
		};

		getOptions = (platform) => {
			const {
				dataLimit,
				reports: { totalCount },
				selectedPage,
				setDataOffset,
				setSelectedPage,
				tab,
				user
			} = this.props;
      const { orderColumn } = this.state;
      const isDesktop = platform === 'desktop';
      const isMobile = platform === 'mobile';

			const tableOptions = {
				orderByData: (!isIOTTab(tab) && !isRepostedReportsTab(tab)) && ['all'],
				showPagination: !isIOTTab(tab) && totalCount > dataLimit,
				pageCount: this.totalCount(),
				getOrderData: (data) => onOrderClick(data, this.getReports, this),
				orderColumn,
				excludeFromOrdering: ['dates'], // Add ordering of dates
				marginPagesDisplayed: 2,
				pageRangeDisplayed: 2,
				forcePage: selectedPage,
				onPageChange: (data) =>
					newOnPageChange(
						data,
						this.getReports,
						dataLimit,
						setDataOffset,
						setSelectedPage
					)
			};
			const data = this.getTableData();

			const customComponents = (isIOTTab(tab) && {
				date: {
					type: 'custom',
					component: (val, index) => (
						<DateOld
							data={val}
							uniqueId={index}
							fileCategory={
								(tab === ReportsIDs.MY_EXPORTS && 'Exports') ||
								(tab === ReportsIDs.USAGE_REPORTS && 'Usage')
							}
							allowRepost={
								tab === ReportsIDs.USAGE_REPORTS
								&& isUserAllowedToAccess(['mnc.reports.repost_rw'], user)
							}
							dataLength={data && data.length}
						/>
					)
				}
			}) || {
				dates: {
					type: 'custom',
					component: (val, index) => (
						<DateNew
							data={val}
							uniqueId={index}
							dataLength={data && data.length}
						/>
					)
				},
				repostStatus: {
					type: 'custom',
					component: (val) => (
						<span className={
							styles[val.repostStatus.toLowerCase()]
						}
						>{val.repostStatus}
						</span>
					)
				}
      };

      let header = [];

      if (isDesktop) {
        header =
					(isIOTTab(tab) && CustomerReportsTableHeader) ||
					(isReportHeadersTab(tab) && ReportHeadersTableHeader) ||
					(isRepostedReportsTab(tab) && RepostedReportsTableHeader) ||
					ReportsTableHeader;
      }

      if (isMobile) {
        header =
					(isIOTTab(tab) && CustomerReportsTableHeaderMobile) ||
					(isReportHeadersTab(tab) && ReportHeadersTableHeaderMobile) ||
					(isRepostedReportsTab(tab) && RepostedReportsTableHeaderMobile) ||
					ReportsTableHeaderMobile;
      }

			return {
				header,
				tableOptions,
				customComponents
			};
		};

		onOrderClick = (data) => {
			const { name: column, apiString } = data;
			const { orderColumn } = this.state;

			this.setState(
				{
					orderColumn: {
						name: column,
						order: orderColumn.name === column ? !orderColumn.order : true,
						apiString
					}
				},
				() => this.getReports()
			);
		};

		getParams = () => {
			const { tab } = this.props;
			const apiTab = tab.toLowerCase().replace(/_/g, '-');
			const params = {
				searchParams: [
					{
						operator: '=',
						prop: 'report_category',
						propValue: tab
					}
				],
				additionalParams: {
					columnsToSelect: 'm2m_acc_id,m2m_platform,frequency, report_name'
				}
			};
			return {
				tab: apiTab,
				params
			};
		};

		getFilterModalParams = () => {
			const { tab } = this.props;
			const service = isIOTTab(tab)
				? reportsFiltersService
				: getReportsFiltersService;

			const params = {
				service,
				createFilterData: this.createFilterData,
				params: this.getParams()
			};

			return params;
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		render() {
			const {
				reports,
				reportsFail,
				reportsRequest,
				tab,
				messages,
				downloadReportRequest,
				getFiltersRequest
			} = this.props;

			let params = {};

			params = this.getFilterModalParams();

			const isAnyRequest =
				reportsRequest || downloadReportRequest || getFiltersRequest;

			const isDataEmpty =
				reports && reports.resultList && reports.resultList.length === 0;

			const savedFiltersParams = {
				refTypeId: reportsRefTypeId,
				name: tab,
				getData: this.getReports
			};

			return (
				<div data-spec="reports-list">
					<ActionBar
						menu={getSearchMenu(tab)}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						openFilterModal={
							!isReportHeadersTab(tab)
							&& !isRepostedReportsTab(tab)
							&& this.openFilterModal
						}
						clearFilters={
							!isReportHeadersTab(tab)
							&& !isRepostedReportsTab(tab)
							&& this.clearFilters
						}
						onChange={this.onChange}
						isDisabled={isAnyRequest}
						isDataEmpty={isDataEmpty}
						savedFiltersParams={
							!isReportHeadersTab(tab)
							&& !isRepostedReportsTab(tab)
							&& savedFiltersParams
						}
					/>
					<FilterModal
						messages={messages}
						onApply={this.onApply}
						params={params}
						savedFiltersParams={savedFiltersParams}
						type={isIOTTab(tab) ? 'radio' : 'checkbox'}
					/>
					{isAnyRequest && <Loader />}
					{reportsFail && null}
					{reports && !isAnyRequest && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
							data={this.getTableData()}
						/>
					)}
					{isDataEmpty && (
						<NoData
							table
							title={
								<FormattedMessage
									id="SECURITY_FEATURES.NO_REPORTS_TITLE"
									defaultMessage="No Reports Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="SECURITY_FEATURES.NO_REPORTS_SUBTITLE"
									defaultMessage="There are No Reports for this Table to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { object, bool, string, shape, func, array, number } = PropTypes;

	ReportsList.propTypes = {
		reports: object,
		reportsRequest: bool,
		reportsFail: bool,
		tab: string,
		messages: object,
		downloadReportRequest: bool,
		getFiltersRequest: bool,
		getAnalyticsReports: func,
		getCustomerReports: func,
		dataLimit: number,
		location: shape(),
		getAnalyticsReportsInit: func,
		getCustomerReportsInit: func,
		openFilterModal: func,
		user: shape(),
		filterData: shape(),
		setDataForFiltersInit: func,
		userSettings: array,
		dataOffset: number,
		selectedPage: number,
		setDataOffset: func,
		setSelectedPage: func,
		searchParameter: object
	};

	return ReportsList;
};

export default List;
