import { connect } from 'react-redux';
import AddUserGroupModal from './AddUserGroupModal';
import { addUserGroup } from '../../redux/userGroups/actions';
import { addUserGroupRequest } from '../../redux/userGroups/selectors';

const mapStateToProps = (state) => ({
	addUserGroupRequest: addUserGroupRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	addUserGroup: (params) => dispatch(addUserGroup(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserGroupModal);
