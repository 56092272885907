import { Status } from '../../../../utils/constants';

export const getAddonsRequest = (state) =>
	state.analyticsInvoices.tables.addons.getAddons.status === Status.PENDING;
export const getAddonsFail = (state) =>
	state.analyticsInvoices.tables.addons.getAddons.status === Status.FAIL;
export const getAddonsSuccess = (state) =>
	state.analyticsInvoices.tables.addons.getAddons.data;

export const getAddonsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.addons.getAddonsFilters.status ===
	Status.PENDING;
export const getAddonsFiltersFail = (state) =>
	state.analyticsInvoices.tables.addons.getAddonsFilters.status === Status.FAIL;
export const getAddonsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.addons.getAddonsFilters.data;
