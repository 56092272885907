import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import Selector from '../../../../../lib/DigitalComponents/Selector';

import styles from './Step.scss';

const StepFirst = ({
	categories,
	onNext,
	currentStep,
	shouldRefresh,
	getFilters,
	dispatch,
	change
}) => (
	<div data-spec="analytics-step-first">
		{categories && categories.length > 0 ? (
			categories.map((category) => (
				<div
					data-spec="radio-button"
					key={category.id}
					className={styles.margin_top_bottom_10}
				>
					<FormattedMessage
						id={`ANALYTICS.${category.name}`}
						defaultMessage={category.name && category.name.toLowerCase()
							.split('_')
							.map((word) => word[0].toUpperCase() + word.slice(1))
							.join(' ')}
					>
						{(formattedValue) => (
							<Field
								component={Selector}
								name="radioCategory"
								label={formattedValue}
								value={`${category.name}`}
								type="radio"
								onChange={(event, newValue, previousValue) => {
									onNext();
									getFilters(category.id);
									dispatch(change('filtersForm', {}));
									if (previousValue === 'ESTIMATED_AVERAGE_USAGE') {
										dispatch(change('groupByShow', '0'));
									}
									if (currentStep > 3) shouldRefresh();
								}}
							/>
						)}
					</FormattedMessage>
				</div>
			))
		) : (
			<div>
				<FormattedMessage
					id="ANALYTICS.NO_CATEGORIES"
					defaultMessage="There are no categories available for this account"
				/>
			</div>
		)}
	</div>
);

const { func, arrayOf, number, object } = PropTypes;

StepFirst.propTypes = {
	onNext: func,
	categories: arrayOf(object),
	currentStep: number,
	shouldRefresh: func,
	getFilters: func,
	dispatch: func,
	change: func
};

StepFirst.defaultProps = {
	categories: [],
	currentStep: 0,
	onNext: undefined,
	shouldRefresh: undefined,
	getFilters: undefined,
	dispatch: undefined,
	change: undefined
};

export default StepFirst;
