/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { orderBy } from 'lodash';
import Loading from '../../../../lib/DigitalComponents/Loader';
import Button from '../../../../lib/DigitalComponents/Button';
import styles from './AnalyticsInvoices.scss';
import PageTitle from '../../../Shared/views/PageTitleView';

import ActionBar from '../../../Shared/views/ActionBarView';
import FilterModal from '../../../Shared/components/FilterModal';
import NoData from '../../../Shared/views/NoDataView';

import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import { ConditionIf } from '../../../../utils/helperFunctions';

import { analyticsInvoicesTableHeaderMobile } from '../../utils/DetailsHeaders';

import EditTableModal from '../EditTableModal';
import NotesModal from '../NotesModal';
import AttachmentsModal from '../AttachmentsModal';

import {
	InvoicesSignature,
	InvoicesTableTabs
} from '../../utils/tableSignatures';

import { getFilters as getFiltersService } from '../../services/AnalyticsInvoicesService';

import { UserSettingsTableName } from '../../utils/constants';
import { isValidBillingCycle } from '../../utils/helpers';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

const headerConstants = InvoicesTableTabs[0].value.data.filter(
	(x) => x.checked
);

const DownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/downloadable-file-l.svg'
);

const AnalyticsInvoices = () => (WrappedComponent) => {
	class AnalyticsInvoicesComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				isEditTableModalOpen: false,
				note: '',
				invoiceId: 0,
				isNotesModalOpened: false,
				isAttachmentsModalOpened: false,
				attachmentModalInvoiceId: 0,
				invoiceDocumentName: '',
				m2mAccountId: 0,
				settings: this.getInvoicesUserSettings()
					? JSON.parse(this.getInvoicesUserSettings().value)
					: {}
			};
		}

		componentDidMount() {
			const { createUserSettings, createUserSettingsPending } = this.props;
			this.getInvoices();
			if (!this.getInvoicesUserSettings() && !createUserSettingsPending) {
				createUserSettings({
					refTypeId: 7,
					name: UserSettingsTableName.INVOICES,
					value: JSON.stringify({
						signature: InvoicesSignature,
						header: headerConstants
					})
				});
				this.setState({
					settings: {
						signature: InvoicesSignature,
						header: headerConstants
					}
				});
			}
		}

		componentDidUpdate(prevProps) {
			const { patchInvoiceSuccess, postInvoiceSuccess } = this.props;
			const { settings } = this.state;

			if (
				patchInvoiceSuccess != null &&
				prevProps.patchInvoiceSuccess === null
			) {
				this.getInvoices();
			}
			if (
				this.getInvoicesUserSettings() &&
				settings.signature !== InvoicesSignature
			) {
				this.updateInvoiceUserSettings();
			}
			if (
				postInvoiceSuccess &&
				prevProps.postInvoiceSuccess != postInvoiceSuccess
			) {
				this.getInvoices();
			}
		}

		updateInvoiceUserSettings = async () => {
			const { patchUserSetting } = this.props;
			const temp = {
				value: JSON.stringify({
					signature: InvoicesSignature,
					header: headerConstants
				})
			};
			const settings = await this.getInvoicesUserSettings();
			patchUserSetting(temp, settings.id);
			this.setState({
				settings: JSON.parse(temp.value)
			});
		};

		isRWODetailsComponent = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(
				['mnc.invoices.details_ro', 'mnc.invoices.details_rw'],
				user
			);
		};

		isRWComponent = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(['mnc.invoices_rw'], user);
		};

		isUploadRWComponent = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(['system.uploadinvoice_rw'], user);
		};

		createFilterData = (data) => {
			let filter = {};
			data.length &&
				data.forEach((item) => {
					filter = {
						...filter,
						[`${item.category}`]: item.filterValuesAllowed.map(
							(allowedFilterValue) => ({
								value: allowedFilterValue,

								label: allowedFilterValue,

								checked: false
							})
						)
					};
				});
			return filter;
		};

		getInvoicesUserSettings = () => {
			const { userSettings } = this.props;
			return userSettings.find(
				(x) => x.name === UserSettingsTableName.INVOICES
			);
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		// on apply is called when you press button change on modal
		onApply = () => {
			this.getInvoices();
		};

		clearFilters = () => {
			this.getInvoices();
		};

		// callback function for getting invoices ( get params and than dispatch action to redux)
		getInvoices = () => {
			const { getInvoices, filterData } = this.props;

			let filters = {};
			if (filterData) {
				const filterDataKeys = Object.keys(filterData);
				filterDataKeys.map((item) =>
					filterData[item].forEach((filter) => {
						if (filter.checked) {
							filters = { ...filters, [item]: filter.value };
						}
					})
				);
			}

			getInvoices(filters);
		};

		onChange = () => {
			this.getInvoices();
		};

		// custom component for table column notes
		addNoteModal = (val) => (
			<div
				data-spec=""
				className={styles.icon_wrapper}
				onClick={() => {
					(val.notes || this.isRWComponent()) && this.openNotesModal(val);
				}}
			>
				{val.notes ? (
					<span className={styles.notes}>{val.notes}</span>
				) : (
					<ConditionIf condition={this.isRWComponent()}>
						<span className={styles.clickable}>
							<FormattedMessage
								id="ANALYTICS_INVOICES.ADD_NOTE"
								defaultMessage="Add a note"
							/>
						</span>
					</ConditionIf>
				)}
			</div>
		);

		downloadRenderer = (val) => {
			const { downloadFile } = this.props;
			return (
				<div
					data-spec="download-invoice"
					className={styles.icon_wrapper}
					onClick={() => downloadFile(val, val.m2mAccountId)}
				>
					<DownloadIcon className={styles.icon} />
					<span>
						<FormattedMessage
							id="ANALYTICS_INVOICES.DOWNLOAD"
							defaultMessage="Download"
						/>
					</span>
				</div>
			);
		};

		renderBillingAccountIDs = (val) => (
			<div>
				{val.m2mAccountIdChildArray && val.m2mAccountIdChildArray.length > 0 ? (
					<div>
						{val.m2mAccountIdChildArray.map((m2mAccountId, index) =>
							index === 0 ? (
								<span key={m2mAccountId}>{m2mAccountId}</span>
							) : (
								<span key={m2mAccountId}>{`, ${m2mAccountId}`}</span>
							)
						)}
					</div>
				) : null}
			</div>
		);

		// options for desktop and mobile tables
		getOptions = (target) => {
			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			if (isDesktop) {
				options.header = this.getTableHeader();
			}

			if (isMobile) {
				options.header = analyticsInvoicesTableHeaderMobile;
				options.tableOptions = {
					mobileRowClick: (val) => this.renderMobileRowClick(val)
				};
			}

			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions,
					firstColumnMarked: true,
					excludeDraggableColumns: ['invoiceNumber'],
					fixedFirstColumn: true,
					draggable: true,
					onDragEnd: (fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex)
				};
				options.customComponents = {
					invoiceNumber: {
						type: 'custom',
						component: (val) => this.renderInvoiceIds(val)
					},
					download: {
						type: 'custom',
						component: (val) => this.downloadRenderer(val)
					},
					notes: {
						type: 'custom',
						component: (val) => this.addNoteModal(val)
					},
					invoice_files: {
						type: 'custom',
						component: (val) => this.renderInvoiceFiles(val)
					},
					billing_account_ids: {
						type: 'custom',
						component: (val) => this.renderBillingAccountIDs(val)
					}
				};
			}
			return options;
		};

		renderMobileRowClick = (val) =>
			this.isRWODetailsComponent() &&
			isValidBillingCycle(val.billingCycle) &&
			val.m2mAccountId
				? this.goToInvoiceDetails(val.m2mAccountId, val.billingCycle)
				: null;

		renderInvoiceIds = (val) =>
			this.isRWODetailsComponent() &&
			isValidBillingCycle(val.billingCycle) &&
			val.m2mAccountId ? (
				<span
					className={styles.clickable}
					onClick={() =>
						this.goToInvoiceDetails(val.m2mAccountId, val.billingCycle)
					}
				>
					{val.invoiceNumber}
				</span>
			) : (
				<span>{val.invoiceNumber}</span>
			);

		renderInvoiceFiles = (val) => (
			<span
				className={styles.clickable}
				onClick={() =>
					this.openInvoiceAttachmentsModal(
						val.invoiceId,
						val.docviewerGeneratedDocId,
						val.m2mAccountId,
						val.invoiceNumber
					)
				}
			>
				<FormattedMessage
					id="ANALYTICS_INVOICES.VIEW_ADD_EDIT"
					defaultMessage="View/Add/Edit"
				/>
			</span>
		);

		openInvoiceAttachmentsModal = (
			invoiceId,
			documentName,
			m2mAccountId,
			invoiceNumber
		) => {
			const { anyModalOpened } = this.props;
			anyModalOpened();
			this.setState({
				isAttachmentsModalOpened: true,
				attachmentModalInvoiceId: invoiceId,
				attachmentModalInvoiceNumber: invoiceNumber,
				invoiceDocumentName: documentName,
				m2mAccountId
			});
		};

		closeInvoiceAttachmentsModal = () => {
			this.setState({
				isAttachmentsModalOpened: false
			});
		};

		openNotesModal = (val) => {
			const { anyModalOpened } = this.props;
			anyModalOpened();
			this.setState({
				note: val.notes ? val.notes : '',
				invoiceId: val.invoiceId,
				isNotesModalOpened: true
			});
		};

		closeNotesModal = () => {
			this.setState({
				isNotesModalOpened: false
			});
		};

		onSubmitNotesModal = () => {
			this.setState({
				isNotesModalOpened: false
			});
		};

		goToInvoiceDetails = (m2mAccountId, billingCycle) => {
			const { goToInvoiceDetails } = this.props;
			// goToInvoiceDetails(encodeURIComponent(id));
			goToInvoiceDetails(m2mAccountId, billingCycle);
		};

		totalCount = () => {
			const { dataLimit, invoices } = this.props;
			return Math.ceil(invoices.totalCount / dataLimit);
		};

		openEditTableModal = () => {
			this.setState({
				isEditTableModalOpen: true
			});
		};

		closeEditTableModal = () => {
			this.setState({ isEditTableModalOpen: false });
		};

		submitColumns = (newValue, id) => {
			const { patchUserSetting } = this.props;
			patchUserSetting({ value: JSON.stringify(newValue) }, id);
			this.setState({
				settings: newValue
			});
		};

		getTableHeader = () => {
			const { settings } = this.state;
			// return isEmpty(settings)
			// 	? headerConstants
			// 	: orderBy(settings.header, ['order'], ['asc']);
			return orderBy(settings.header, ['order'], ['asc']);
		};

		onDragEnd = async (fromIndex, toIndex) => {
			const { patchUserSetting } = this.props;
			if (toIndex < 0) return;

			const data = await this.getTableHeader();
			[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];
			const temp = {
				value: JSON.stringify({
					signature: InvoicesSignature,
					header: data.map((item, index) => {
						item.order = index + 1;
						return item;
					})
				})
			};
			const settings = await this.getInvoicesUserSettings();
			patchUserSetting(temp, settings.id);
			this.setState({ settings: JSON.parse(temp.value) });
		};

		renderAddInvoiceButton = () => {
			const { goToAddInvoice } = this.props;
			return this.isUploadRWComponent() ? (
				<div data-spec="add-new-invoice">
					<Button
						type="button"
						variant="primary"
						label={
							<FormattedMessage
								id={'INVOICES.ADD_NEW_INVOICE'}
								defaultMessage={'Add New Invoice'}
							/>
						}
						onClick={() => goToAddInvoice()}
					/>
				</div>
			) : null;
		};

		render() {
			const {
				invoicesRequest,
				invoicesFail,
				invoices,
				messages,
				user,
				exportInvoiceRequest,
				updateUserSettingsPending,
				patchInvoiceRequest,
				invoiceReportRequest,
				postInvoiceRequest
			} = this.props;

			const { notifications, errors } = this.props;

			const {
				isEditTableModalOpen,
				isNotesModalOpened,
				note,
				invoiceId,
				isAttachmentsModalOpened,
				attachmentModalInvoiceId,
				attachmentModalInvoiceNumber,
				invoiceDocumentName,
				m2mAccountId
			} = this.state;

			const excludeColumns = ['invoiceNumber'];

			const params = {
				createFilterData: this.createFilterData,
				service: getFiltersService,
				objectsString: 'filters'
			};

			const isAnyRequest =
				invoicesRequest ||
				exportInvoiceRequest ||
				updateUserSettingsPending ||
				invoiceReportRequest ||
				patchInvoiceRequest ||
				postInvoiceRequest;

			const isDataEmpty =
				invoices && invoices.invoices && invoices.invoices.length === 0;
			return (
				<div className={styles.tessdt} data-spec="invoices">
					<PageTitle
						title={
							<FormattedMessage
								id="ANALYTICS_INVOICES.TITLE"
								defaultMessage="Invoices"
							/>
						}
						actions={this.renderAddInvoiceButton()}
					/>

					{!isDataEmpty && (
						<ActionBar
							openFilterModal={this.openFilterModal}
							onChange={this.onChange}
							clearFilters={this.clearFilters}
							openEditTableModal={this.openEditTableModal}
							isDisabled={isAnyRequest}
							isDataEmpty={isDataEmpty}
						/>
					)}

					<FilterModal type="radio" params={params} onApply={this.onApply} />

					{this.getInvoicesUserSettings() && isEditTableModalOpen && (
						<EditTableModal
							show
							onClose={this.closeEditTableModal}
							customFieldLabels={[]}
							messages={messages}
							user={user}
							submitColumns={this.submitColumns}
							columns={this.getInvoicesUserSettings()}
							signature={InvoicesSignature}
							tableTabs={InvoicesTableTabs}
							excludeColumns={excludeColumns}
							errors={errors}
							notifications={notifications}
						/>
					)}

					{isNotesModalOpened && (
						<NotesModal
							show
							messages={messages}
							invoiceId={invoiceId}
							note={note}
							onCancel={this.closeNotesModal}
							errors={errors}
							onSubmitNotesModal={this.onSubmitNotesModal}
							initialValues={{ note }}
							notifications={notifications}
						/>
					)}

					{isAttachmentsModalOpened && (
						<AttachmentsModal
							invoiceId={attachmentModalInvoiceId}
							invoiceNumber={attachmentModalInvoiceNumber}
							invoiceDocumentName={invoiceDocumentName}
							m2mAccountId={m2mAccountId}
							onCloseAttachmentsModal={() =>
								this.closeInvoiceAttachmentsModal()
							}
							messages={messages}
						/>
					)}

					{isAnyRequest && <Loading data-spec="loading" />}
					{invoicesFail && null}
					{!isAnyRequest && invoices && invoices.invoices && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}
					{isDataEmpty && (
						<NoData
							table
							title={
								<FormattedMessage
									id="ANALYTICS_INVOICES.NO_DATA"
									defaultMessage="No Invoices Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="ANALYTICS_INVOICES.NO_DATA_SUBTITILE"
									defaultMessage="There are No Invoices to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { func, object, bool, number, array, string } = PropTypes;

	AnalyticsInvoicesComponent.propTypes = {
		getAnalyticsInvoices: func,
		messages: object,
		invoicesRequest: bool,
		invoicesFail: bool,
		invoices: object,
		dataLimit: number,
		goToInvoiceDetails: func,
		getInvoicesFilters: func,
		openFilterModal: func,
		filterModalOpened: bool,
		filters: object,
		filtersFail: bool,
		filtersRequest: bool,
		userSettings: array,
		user: object,
		createUserSettings: func,
		deleteUserSettings: func,
		patchUserSetting: func,
		createUserSettingsPending: bool,
		updateUserSettingsSuccess: bool,
		exportInvoice: func,
		exportInvoiceData: string,
		notifications: array,
		errors: array,
		exportInvoiceRequest: bool,
		updateUserSettingsPending: bool,
		patchInvoiceSuccess: string,
		patchInvoiceRequest: bool,
		getInvoices: func,
		getFilters: func,
		downloadFile: func,
		invoiceReportRequest: bool,
		filterData: object,
		anyModalOpened: func,
		goToAddInvoice: func,
		postInvoiceRequest: bool,
		postInvoiceSuccess: object
	};
	return AnalyticsInvoicesComponent;
};

export default AnalyticsInvoices;
