import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import {
	getStatusCodeForErrorPage,
	getErrorsForErrorPage
} from '../../../Shared/redux/getStatusCode/selectors';

import ErrorPageDesktop from './Desktop';
import ErrorPageMobile from './Mobile';

const mapStateToProps = (state) => ({
	statusCode: getStatusCodeForErrorPage(state),
	error: getErrorsForErrorPage(state)
});

const mapDispatchToProps = (dispatch) => ({
	goToHomePage: () => dispatch(localizedRoute('/'))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(ErrorPageDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(ErrorPageMobile);
