import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	arrayPush,
	arrayRemoveAll,
	change,
	destroy,
	Field,
	FieldArray,
	reduxForm,
	untouch
} from 'redux-form';
import { connect } from 'react-redux';
import cn from 'classnames';
import { find, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

// import ChangeStateModal from '../ChangeStateModal';
import DeleteModal from '../DeleteModal';
import ManulRuleConfirmationModal from '../ManulRuleConfirmationModal';

import Loader from '../../../../lib/DigitalComponents/Loader';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
// import PageTitle from '../../../Shared/views/PageTitleView';
import Button from '../../../../lib/DigitalComponents/Button';
import Switch from '../../../../lib/DigitalComponents/Switch';
import { required } from '../../../../utils/validators';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import validate from './validate';
import submitForm from './submitForm';
import {
	allFilterFieldsForResetting,
	automationRuleFiltersConfig,
	isFieldDisabled,
	renderActions,
	renderActionsValue,
	renderFollowUpCheckbox,
	renderFrameComponents,
	renderRuleFilters,
	renderSchedulers,
	setInitialValuesForCustomFields,
	Option,
	SingleValue,
	isWingPlaform
} from './automationHelpers';

import { localizationHelper } from '../../../../utils/helperFunctions';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import ruleTypes from '../../utils/ruleTypes';
import { manualRuleStates } from '../../utils/manualRuleStatesHelper';

import styles from './AutomationRuleDetail.scss';

import { getFrameValue, getFrameValue2 } from '../../utils/helpers';

const Delete = ImageSelector(CurrentContext.theme, 'svgs/close.svg');

const AutomationRuleDetailForm = () => {
	class AutomationRuleDetail extends Component {
		constructor(props) {
			super(props);
			this.state = {
				loadingFields: [],
				deleteModalOpened: false,
				confirmationModalOpened: false
			};
		}

		componentDidMount() {
			const {
				getPlatforms,
				match: {
					params: { ruleId }
				}
			} = this.props;

			getPlatforms();
			this.setLoadingFields('automationPlatformId');
			if (ruleId) {
				this.getAutomationRule();
			}
		}

		componentDidUpdate(prevProps) {
			const {
				rule,
				getAutomationPlatformsSuccessStatus,
				categories,
				formValues,
				triggers,
				hideNotification,
				match: {
					params: { ruleId }
				}
			} = this.props;

			if (
				prevProps.getAutomationPlatformsPending &&
				getAutomationPlatformsSuccessStatus &&
				rule &&
				rule.id
			) {
				this.getCategories(rule.automationPlatformId);
			}
			if (
				JSON.stringify(prevProps.categories) !== JSON.stringify(categories) &&
				formValues &&
				formValues.category &&
				formValues.category.value
			) {
				this.getTriggers(formValues.category.value);
			}
			if (
				JSON.stringify(prevProps.triggers) !== JSON.stringify(triggers) &&
				formValues &&
				formValues.trigger &&
				formValues.trigger.value
			) {
				this.getFrames(formValues.trigger.value);
				this.getTriggerSubcategories(formValues.trigger.value);
			}
			if (
				prevProps.rule &&
				!isEqual(prevProps.rule.automationTrigger, rule.automationTrigger)
			) {
				this.getFilterData(rule, this.props);
			}

			if (
				rule &&
				rule.id &&
				rule.automationTrigger &&
				rule.automationTrigger.isManualApprovalRequired &&
				prevProps.rule != rule
			) {
				this.showManualTriggerNotification();
			}
			if (
				!ruleId &&
				formValues &&
				formValues.trigger &&
				formValues.trigger != prevProps.formValues.trigger
			) {
				if (formValues.trigger.isManualApprovalRequired) {
					this.showManualTriggerNotification();
				} else {
					hideNotification();
				}
			}
		}

		componentWillUnmount() {
			const { dispatch, setOptionsToInit } = this.props;
			dispatch(destroy('AutomationRuleDetailForm'));
			setOptionsToInit();
		}

		showManualTriggerNotification = () => {
			const { intl, showNotification } = this.props;
			const message = intl.formatMessage({
				id: 'AUTOMATION.RULE_CREATED_OR_EDITED',
				defaultMessage:
					'You have selected an Automation rule that requires extended processing and will not be set immediately.'
			});
			showNotification(message);
		};

		getAutomationRule = () => {
			const {
				getAutomationRule,
				match: {
					params: { ruleId }
				}
			} = this.props;
			getAutomationRule(ruleId, {
				additionalParams: {
					include: [
						'AutomationCategory',
						'AutomationPlatform',
						'AutomationTrigger',
						'AutomationRuleFrameActionValues',
						'AutomationRuleFrameActionValues.AutomationFrame',
						'AutomationAction',
						'AutomationAction.AutomationActionType',
						'AutomationRuleStatus',
						'AutomationRuleType'
					]
				}
			});
		};

		getM2MAccountId = (m2mAccounts) => {
			const { platforms, formValues = {}, rule = {} } = this.props;
			const platformId =
				(formValues.automationPlatformId &&
					formValues.automationPlatformId.value) ||
				(rule && rule.automationPlatformId);
			if (m2mAccounts && platformId) {
				const selectedPlatform = find(platforms, { id: platformId }) || {};
				const platformName = selectedPlatform.code || '';
				const options = m2mAccounts.filter(
					(item) => item.platformName === platformName.toUpperCase()
				);
				return options.map((item) => ({
					label: item.m2mAccountId,
					value: item.m2mAccountId
				}));
			}
		};

		getCategories = (id) => {
			const { getCategories } = this.props;

			id &&
				getCategories({
					searchParams: [
						{
							propValue: id,
							prop: 'automationPlatformId',
							operator: '='
						}
					],
					additionalParams: {
						dataSort: 'name ASC'
					}
				});
		};

		getTriggers = (id) => {
			const { getTriggers } = this.props;

			getTriggers({
				searchParams: [
					{
						propValue: id,
						prop: 'automationCategoryId',
						operator: '='
					}
				],
				additionalParams: {
					dataSort: 'name ASC'
				}
			});
		};

		getTriggerSubcategories = (id) => {
			const { getTriggerSubcategories } = this.props;

			getTriggerSubcategories({
				searchParams: [
					{
						propValue: id,
						prop: 'automationTriggerId',
						operator: '='
					}
				],
				additionalParams: {
					dataSort: 'name ASC'
				}
			});
		};

		getFrames = (id) => {
			const { getFrames } = this.props;

			getFrames({
				searchParams: [
					{
						propValue: id,
						prop: 'automationTriggerId',
						operator: '='
					}
				],
				additionalParams: {
					dataSort: 'id ASC'
				}
			});
		};

		resetFields = (formName, fieldsObj) => {
			const { dispatch } = this.props;

			Object.keys(fieldsObj).forEach((fieldKey) => {
				dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
				dispatch(untouch(formName, fieldKey));
			});
		};

		pushBack = () => {
			const { pushBack } = this.props;

			pushBack();
		};

		getFilterData = (item, props) => {
			automationRuleFiltersConfig(item, props);
		};

		setLoadingFields = (...rest) => this.setState({ loadingFields: rest });

		showLoaderOrIsDisabled = (fieldName, type, classname) => {
			const { isDataGetting } = this.props;
			const { loadingFields } = this.state;
			if (isDataGetting && loadingFields.indexOf(fieldName) !== -1) {
				return type !== 'select' ? (
					<div
						data-spec="specific-field-loader"
						className={cn(styles.loader, styles[classname])}
					>
						<Loader size={15} data-spec="loader" />
					</div>
				) : (
					true
				);
			}
		};

		createNewFieldArrayItem = (fields) => {
			const { formValues = {} } = this.props;
			const automationActionId =
				formValues.automationRuleFrameActionValues &&
				formValues.automationRuleFrameActionValues[0] &&
				formValues.automationRuleFrameActionValues[0].automationActionId;
			fields.push({ automationActionId });
		};

		renderAutomationRuleFrameActionValues = ({ fields }) => {
			// if (fields.length === 0) {
			// 	fields.push({});
			// }
			const { formValues = {}, frames, triggerSubcategories } = this.props;
			const type =
				(formValues.trigger &&
					formValues.trigger.automationTriggerFrameValueTypeId) ||
				1;
			const classname = cn({
				[styles.select_field]: true,
				[styles.display_flex]: true,
				[styles.flex_start]: true,
				[styles.flex_wrap]: type === 5 || type === 9 || type === 11
			});

			const getautomationFrameUnit = (id) => {
				const automationFrameUnit =
					frames &&
					frames.find((item) => item.id === id) &&
					frames.find((item) => item.id === id).automationFrameUnits &&
					frames
						.find((item) => item.id === id)
						.automationFrameUnits.find((item) => item.isDefaultIndicator);
				return automationFrameUnit
					? {
							value: automationFrameUnit.id,
							label: (
								<FormattedMessage
									id={`AUTOMATION.${localizationHelper(
										automationFrameUnit.name
									).toUpperCase()}`}
									defaultMessage={automationFrameUnit.name}
								/>
							)
					  }
					: '';
			};
			return fields.map((field, index) => (
				<React.Fragment key={field}>
					<div className={cn(styles.divider, styles.has_child, styles.mt15)} />
					<div className={classname}>
						<div
							className={`${styles.custom_select_width_first} ${styles.mb20}`}
						>
							<div className={styles.label}>
								<FormattedMessage
									id={'AUTOMATION.ACC_IF'}
									defaultMessage={'If'}
								/>
							</div>
							<Field
								component={Select}
								name={`${field}.frame`}
								options={
									frames
										? frames.map((item) => {
												const localizationId = localizationHelper(item.name);
												return {
													value: item.id,
													label: (
														<FormattedMessage
															id={`AUTOMATION.${localizationId.toUpperCase()}`}
															defaultMessage={item.name}
														/>
													)
												};
										  })
										: []
								}
								onChange={({ value }) => {
									this.resetFields('AutomationRuleDetailForm', {
										[`${field}.frameValue`]: '',
										[`${field}.frameValue2`]: '',
										[`${field}.zoneModelId`]: '',
										[`${field}.simPoolId`]: '',
										[`${field}.zoneId`]: '',
										[`${field}.automationFrameUnitId`]: getautomationFrameUnit(
											value
										)
									});
								}}
								isDisabled={
									this.showLoaderOrIsDisabled('frame', 'select') ||
									!frames ||
									isFieldDisabled(this.props, ['trigger']) ||
									isWingPlaform(this.props)
								}
								validate={[required]}
								cleanValue
								components={{ Option, SingleValue }}
							/>
							{this.showLoaderOrIsDisabled('frame')}
						</div>
						{renderFrameComponents(
							this.props,
							this.state,
							styles,
							this,
							field,
							index
						)}
						{index > 0 && (
							<Delete
								className={styles.remove_item_from_array}
								onClick={() => fields.remove(index)}
								data-spec={`delete-item-from-array-${index}`}
							/>
						)}
					</div>
					{formValues.trigger &&
						triggerSubcategories &&
						triggerSubcategories.length > 0 && (
							<div className={cn(styles.select_field, styles.display_flex)}>
								<div className={styles.custom_select_width_first}>
									<div className={styles.label}>
										<FormattedMessage
											id={'AUTOMATION.MO_MT'}
											defaultMessage={'MO/MT'}
										/>
									</div>
									<Field
										component={Select}
										name={`${field}.automationTriggerSubCategoryId`}
										options={
											triggerSubcategories &&
											triggerSubcategories.map((item) => {
												const localizationId = localizationHelper(item.name);
												return {
													value: item.id,
													label: (
														<FormattedMessage
															id={`AUTOMATION.${localizationId.toUpperCase()}`}
															defaultMessage={item.name}
														/>
													)
												};
											})
										}
										cleanValue
										validate={[required]}
										components={{ Option, SingleValue }}
										isDisabled={isWingPlaform(this.props)}
									/>
								</div>
							</div>
						)}
					<div
						className={cn(
							styles.select_field,
							styles.display_flex,
							styles.flex_wrap
						)}
					>
						{renderActions(
							styles,
							this.props,
							this,
							'parent',
							field,
							index,
							true
						)}
						{renderActionsValue(
							styles,
							this.props,
							this,
							'parent',
							field,
							index
						)}
					</div>
					{formValues.automationRuleFrameActionValues &&
						formValues.automationRuleFrameActionValues[0] &&
						formValues.automationRuleFrameActionValues[0].automationActionId &&
						formValues.automationRuleFrameActionValues[0].automationActionId
							.ladderingAllowed &&
						index + 1 === formValues.automationRuleFrameActionValues.length &&
						formValues.automationRuleFrameActionValues.length < 3 && (
							<span
								className={styles.add_another}
								onClick={() => this.createNewFieldArrayItem(fields)}
								data-spec="add-another-item-to-array"
							>
								<span className={styles.trigger__icon} />
								<FormattedMessage
									id={'AUTOMATION.ADD_ANOTHER_THRESHOLD'}
									defaultMessage={'Add Another Threshold'}
								/>
							</span>
						)}
				</React.Fragment>
			));
		};

		clearFieldsArray = () => {
			const { dispatch } = this.props;

			dispatch(
				arrayRemoveAll(
					'AutomationRuleDetailForm',
					'automationRuleFrameActionValues'
				)
			);
			dispatch(
				arrayPush(
					'AutomationRuleDetailForm',
					'automationRuleFrameActionValues',
					{}
				)
			);
		};

		renderPlatform = () => {
			const {
				platforms,
				match: {
					params: { ruleId }
				}
			} = this.props;
			return (
				<div
					className={styles.custom_select_width_first}
					data-spec="rule-platform"
				>
					<div className={styles.label}>
						<FormattedMessage
							id={'AUTOMATION.PLATFORM'}
							defaultMessage={'Platform'}
						/>
					</div>
					<Field
						component={Select}
						name="automationPlatformId"
						options={
							platforms
								? platforms.map((item) => ({
										value: item.id,
										label: (
											<FormattedMessage
												id={`AUTOMATION.${item.code}`}
												defaultMessage={`${item.name}`}
											/>
										)
								  }))
								: []
						}
						isDisabled={
							!!(
								ruleId ||
								this.showLoaderOrIsDisabled('automationPlatformId', 'select')
							) || isWingPlaform(this.props)
						}
						onChange={(item) => {
							this.setLoadingFields('m2mAccountId', 'category');
							this.getCategories(item.value);
							this.resetFields('AutomationRuleDetailForm', {
								m2mAccountId: [],
								category: [],
								trigger: [],
								...allFilterFieldsForResetting
							});
							this.clearFieldsArray();
						}}
						cleanValue
						components={{ Option, SingleValue }}
					/>
					{this.showLoaderOrIsDisabled('automationPlatformId')}
				</div>
			);
		};

		renderAccount = () => {
			const {
				m2mAccounts,
				platformsPending,
				categoriesPending,
				match: {
					params: { ruleId }
				}
			} = this.props;
			return (
				<div
					className={styles.custom_select_width_first}
					data-spec="rule-account"
				>
					<div className={styles.label}>
						<FormattedMessage
							id={'AUTOMATION.AUTOMATION_ACCOUNT'}
							defaultMessage={'Account'}
						/>
					</div>
					<Field
						component={Select}
						name="m2mAccountId"
						options={!platformsPending ? this.getM2MAccountId(m2mAccounts) : []}
						onChange={() => {
							this.resetFields('AutomationRuleDetailForm', {
								category: [],
								trigger: [],
								...allFilterFieldsForResetting
							});
							this.clearFieldsArray();
						}}
						isDisabled={
							ruleId
								? true
								: !!(
										categoriesPending ||
										this.showLoaderOrIsDisabled('m2mAccountId', 'select') ||
										!this.getM2MAccountId(m2mAccounts) ||
										isWingPlaform(this.props)
								  )
						}
						cleanValue
						components={{ Option, SingleValue }}
					/>
					{this.showLoaderOrIsDisabled('m2mAccountId')}
				</div>
			);
		};

		renderCategory = () => {
			const {
				categories,
				formValues = {},
				rule,
				match: {
					params: { ruleId }
				}
			} = this.props;
			return (
				<div className={styles.select_field} data-spec="rule-category">
					<div className={styles.label}>
						<FormattedMessage
							id={'AUTOMATION.CATEGORY'}
							defaultMessage={'Category'}
						/>
					</div>
					<Field
						component={Select}
						name="category"
						options={
							categories
								? categories.map((item) => {
										const localizationId = localizationHelper(item.name);
										return {
											value: item.id,
											label: (
												<FormattedMessage
													id={`AUTOMATION.${localizationId.toUpperCase()}`}
													defaultMessage={item.name}
												/>
											)
										};
								  })
								: []
						}
						onChange={(item) => {
							this.setLoadingFields('trigger');
							this.getTriggers(item.value);
							this.resetFields('AutomationRuleDetailForm', {
								trigger: [],
								...allFilterFieldsForResetting
							});
							this.clearFieldsArray();
						}}
						isDisabled={
							ruleId ||
							this.showLoaderOrIsDisabled('category', 'select') ||
							!categories ||
							(formValues.m2mAccountId &&
								!formValues.m2mAccountId.value &&
								!(rule && rule.id)) ||
							isWingPlaform(this.props)
						}
						components={{ Option, SingleValue }}
					/>
					{this.showLoaderOrIsDisabled('category', '', 'mt6')}
				</div>
			);
		};

		renderRuleState = () => {
			const { formValues } = this.props;
			return (
				<div className={styles.select_field} data-spec="rule-state">
					<div className={styles.label}>
						<FormattedMessage
							id={'AUTOMATION.RULE_STATE'}
							defaultMessage={'Rule State'}
						/>
					</div>
					<FormattedMessage
						id={
							formValues && formValues.active
								? 'AUTOMATION.RULE_STATE_ACTIVE'
								: 'AUTOMATION.RULE_STATE_INACTIVE'
						}
						defaultMessage={
							formValues && formValues.active ? 'Active' : 'Inactive'
						}
					>
						{(formattedValue) => (
							<Field
								component={Switch}
								name="active"
								label={formattedValue}
								disabled
							/>
						)}
					</FormattedMessage>
				</div>
			);
		};

		renderTrigger = () => {
			const {
				triggers,
				match: {
					params: { ruleId }
				}
			} = this.props;
			return (
				<div
					className={cn(styles.select_field, styles.display_flex)}
					data-spec="rule-trigger"
				>
					<div className={styles.custom_select_width_first}>
						<div className={styles.label}>
							<FormattedMessage
								id={'AUTOMATION.TRIGGER'}
								defaultMessage={'Trigger'}
							/>
						</div>
						<Field
							component={Select}
							name="trigger"
							options={
								triggers
									? triggers.map((item) => {
											const localizationId = localizationHelper(item.name);
											return {
												value: item.id,
												label: (
													<FormattedMessage
														id={`AUTOMATION.${localizationId}`}
														defaultMessage={item.name}
													/>
												),
												hasTriggerSubCategory: item.hasTriggerSubCategory,
												unit: item.unit,
												hasStatus: item.hasStatus,
												hasRatePlanId: item.hasRatePlanId,
												hasCustomerId: item.hasCustomerId,
												hasCustomFields: item.hasCustomFields,
												hasAccountId: item.hasAccountId,
												hasBaseRatePlan: item.hasBaseRatePlan,
												hasRatePlanType: item.hasRatePlanType,
												automationTriggerFrameValueTypeId:
													item.automationTriggerFrameValueTypeId,
												isManualApprovalRequired: item.isManualApprovalRequired,
												hasPrepaidRatePlanId: item.hasPrepaidRatePlanId
											};
									  })
									: []
							}
							onChange={(item) => {
								this.setLoadingFields(
									'frame',
									'automationActionId',
									'frameValue2'
								);
								this.getFrames(item.value);
								this.getTriggerSubcategories(item.value);
								this.resetFields('AutomationRuleDetailForm', {
									...allFilterFieldsForResetting
								});
								this.clearFieldsArray();
								this.getFilterData(item, this.props);
								this.getRuleAutomationZones(item);
							}}
							isDisabled={
								ruleId ||
								this.showLoaderOrIsDisabled('trigger', 'select') ||
								!triggers ||
								isFieldDisabled(this.props, [
									'automationPlatformId',
									'category',
									'm2mAccountId'
								]) ||
								isWingPlaform(this.props)
							}
							components={{ Option, SingleValue }}
						/>
						{this.showLoaderOrIsDisabled('trigger')}
					</div>
				</div>
			);
		};

		renderRuleName = () => (
			<div data-spec="rule-name">
				<div className={styles.label}>
					<FormattedMessage
						id={'AUTOMATION.ACC_RULE_NAME'}
						defaultMessage={'Rule name'}
					/>
				</div>
				<FormattedMessage
					id={'AUTOMATION.ACC_RULE_NAME'}
					defaultMessage={'Rule Name'}
				>
					{(formattedValue) => (
						<Field
							component={Input}
							name="name"
							dataSpec="name"
							placeholder={formattedValue}
							disabled={isWingPlaform(this.props)}
						/>
					)}
				</FormattedMessage>
			</div>
		);

		renderFollowUpActionValues = () => {
			const { formValues } = this.props;
			return (
				<>
					<div className={cn(styles.select_field, styles.display_flex)}>
						{formValues &&
							formValues.automationRuleFrameActionValues &&
							formValues.automationRuleFrameActionValues[0] &&
							formValues.automationRuleFrameActionValues[0]
								.automationActionId &&
							renderActions(styles, this.props, this, 'child', {}, 0)}
						{formValues &&
							formValues.automationActionId2 &&
							renderActionsValue(styles, this.props, this, 'child', {}, 0)}
					</div>
					<div className={cn(styles.select_field, styles.display_flex)}>
						{formValues &&
							formValues.automationActionId2 &&
							renderSchedulers(styles, this.props, this)}
					</div>
				</>
			);
		};

		renderButtons = () => {
			const {
				history,
				invalid,
				submitting,
				dirty,
				rule,
				formValues
			} = this.props;
			const isManualRule =
				(rule &&
					rule.automationRuleType &&
					rule.automationRuleType.code === ruleTypes.ManualRule) ||
				(formValues &&
					formValues.trigger &&
					formValues.trigger.isManualApprovalRequired);
			const isRequestDeletionVisible =
				rule &&
				isManualRule &&
				rule.automationRuleStatus &&
				rule.automationRuleStatus.name !==
					manualRuleStates.PENDING_FOR_DELETION &&
				this.isRWComponent();
			return (
				<div data-spec="automation-details-buttons" className={styles.buttons}>
					{isRequestDeletionVisible ? (
						<div className={styles.request_deletion_button}>
							<Button
								label={
									<FormattedMessage
										id={'AUTOMATION.REQUEST_DELETION'}
										defaultMessage={'Request Deletion'}
									/>
								}
								variant="outline-primary"
								type="button"
								onClick={this.openDeleteModal}
							/>
						</div>
					) : (
						<span />
					)}

					<div className={styles.buttons_wrapper}>
						<Button
							label={
								<FormattedMessage
									id={'AUTOMATION.CANCEL'}
									defaultMessage={'Cancel'}
								/>
							}
							variant="outline-primary"
							className={styles.cancel_button}
							type="button"
							onClick={() => history.goBack()}
							dataSpec="cancel-automation-rule-action"
						/>
						{isManualRule ? (
							<Button
								label={
									<FormattedMessage
										id={'AUTOMATION.SAVE'}
										defaultMessage={'Save'}
									/>
								}
								variant="primary"
								type="button"
								disabled={
									invalid || submitting || !dirty || isWingPlaform(this.props)
								}
								onClick={() => this.openConfirmationModal()}
								dataSpec="save-automation-rule-action"
							/>
						) : (
							<Button
								label={
									<FormattedMessage
										id={'AUTOMATION.SAVE'}
										defaultMessage={'Save'}
									/>
								}
								variant="primary"
								type="submit"
								disabled={
									invalid || submitting || !dirty || isWingPlaform(this.props)
								}
								dataSpec="save-automation-rule-action"
							/>
						)}
					</div>
				</div>
			);
		};

		isRWComponent = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(['mnc.automation_rw'], user);
		};

		openDeleteModal = () => {
			this.setState({
				deleteModalOpened: true
			});
		};

		closeDeleteModal = () => {
			this.setState({
				deleteModalOpened: false
			});
		};

		openConfirmationModal = () => {
			this.setState({
				confirmationModalOpened: true
			});
		};

		closeConfirmationModal = () => {
			this.setState({
				confirmationModalOpened: false
			});
		};

		getRuleAutomationZones = (item) => {
			const { getRuleAutomationZones } = this.props;
			if (item && item.automationTriggerFrameValueTypeId === 11) {
				const params = {
					additionalParams: {
						dataSort: 'name asc'
					}
				};
				getRuleAutomationZones(params);
			}
		};

		render() {
			const {
				rulePending,
				handleSubmit,
				formValues = {},
				dirty,
				isDataGetting,
				rule,
				addRulePending,
				editRulePending,
				activateRuleRequest,
				match: {
					params: { ruleId }
				},
				requestForDeletionRequest,
				setRuleReceivedRequest,
				setRuleImplementedRequest,
				setRuleConflictedRequest,
				dispatch,
				requestForDeletion
			} = this.props;

			const { deleteModalOpened, confirmationModalOpened } = this.state;

			const isAnyRequest =
				rulePending ||
				addRulePending ||
				editRulePending ||
				(rule && rule.id && !dirty && isDataGetting) ||
				activateRuleRequest ||
				requestForDeletionRequest ||
				setRuleReceivedRequest ||
				setRuleImplementedRequest ||
				setRuleConflictedRequest;

			return (
				<div data-spec="automation-details">
					{deleteModalOpened && (
						<DeleteModal
							show
							onClose={() => this.closeDeleteModal()}
							onConfirm={() => requestForDeletion(ruleId)}
							requestDeletion
							modalTitle={
								<FormattedMessage
									id="AUTOMATION.REQUEST_DELETION_TEXT"
									defaultMessage="You are about to Request a Deletion of a Rule"
								/>
							}
							modalHeader={
								<FormattedMessage
									id={'AUTOMATION.REQUEST_DELETION_TITLE'}
									defaultMessage={'Automation Rule Deletion Request'}
								/>
							}
						/>
					)}

					{confirmationModalOpened && (
						<ManulRuleConfirmationModal
							show
							onClose={() => this.closeConfirmationModal()}
							onAccept={() => submitForm(formValues, dispatch)}
							ruleId={ruleId}
						/>
					)}

					{isAnyRequest && <Loader data-spec="loader" key="loader" />}
					{!isAnyRequest && (
						<form onSubmit={handleSubmit(submitForm)}>
							<div
								data-spec="automation-accrule"
								className={styles.detail_wrapper}
								key="rule-detail-form"
							>
								<div className={styles.form_wrap}>
									<div className={cn(styles.select_field, styles.display_flex)}>
										{/* Platform */}
										{this.renderPlatform()}
										{/* Account */}
										{this.renderAccount()}
									</div>
									<div className={styles.divider} />
									<div className={cn(styles.select_field, styles.display_flex)}>
										{/* Category */}
										{this.renderCategory()}
										{/* Rule state */}
										{rule && rule.id && this.renderRuleState()}
									</div>
									<div className={cn(styles.divider, styles.has_child)} />
									{/* Trigger */}
									{this.renderTrigger()}
									{/* Action */}
									<FieldArray
										name={'automationRuleFrameActionValues'}
										component={this.renderAutomationRuleFrameActionValues}
									/>
									{/* Follow up CheckBox */}
									{renderFollowUpCheckbox(styles, this.props, this)}
									{/* Follow up Action */}
									{formValues.follow_up_with &&
										this.renderFollowUpActionValues()}
									{/* Rule Filters */}
									{renderRuleFilters(styles, this.props)}
									{/* Rule Name */}
									{this.renderRuleName()}
								</div>
								{/* Buttons */}
								{this.renderButtons()}
							</div>
						</form>
					)}
				</div>
			);
		}
	}

	const { func, array, bool, object } = PropTypes;

	AutomationRuleDetail.propTypes = {
		getAutomationRule: func,
		m2mAccounts: array,
		match: object,
		rulePending: bool,
		handleSubmit: func,
		rule: object,
		formValues: object,
		dispatch: func,
		invalid: bool,
		getPlatforms: func,
		platformsPending: bool,
		platforms: array,
		getCategories: func,
		categoriesPending: bool,
		categories: array,
		getTriggers: func,
		triggers: array,
		getFrames: func,
		frames: array,
		history: object,
		setOptionsToInit: func,
		submitting: bool,
		getTriggerSubcategories: func,
		triggerSubcategories: array,
		pushBack: func,
		isDataGetting: bool,
		dirty: bool,
		editRulePending: bool,
		addRulePending: bool,
		getAutomationPlatformsPending: bool,
		getAutomationPlatformsSuccessStatus: bool,
		activateRuleRequest: bool,
		user: object,
		showNotification: func,
		hideNotification: func,
		intl: object,
		requestForDeletionRequest: bool,
		setRuleReceivedRequest: bool,
		setRuleImplementedRequest: bool,
		setRuleConflictedRequest: bool,
		requestForDeletion: func,
		getRuleAutomationZones: func
	};
	let AutomationRuleDetailFormData = reduxForm({
		form: 'AutomationRuleDetailForm',
		validate,
		enableReinitialize: true
	})(AutomationRuleDetail);

	AutomationRuleDetailFormData = connect((state, props) => {
		const automationRule = state.automation.getAutomationRule.data;
		const { messages } = props;
		let rule = {};

		if (
			automationRule &&
			state.automation.getAutomationRule.status === 2 &&
			props.match &&
			props.match.params
		) {
			rule = {
				id: automationRule.id,
				automationPlatformId: automationRule.automationPlatformId,
				name: automationRule.name,
				m2mAccountId: automationRule.m2mAccountId,
				category: (() => {
					const localizationId = localizationHelper(
						automationRule.automationCategory.name
					);
					return {
						value: automationRule.automationCategory.id,
						label:
							messages[`AUTOMATION.${localizationId.toUpperCase()}`] ||
							automationRule.automationCategory.name
					};
				})(),
				trigger: (() => {
					const localizationId = localizationHelper(
						automationRule.automationTrigger.name
					);
					return {
						value: automationRule.automationTrigger.id,
						label:
							messages[`AUTOMATION.${localizationId.toUpperCase()}`] ||
							automationRule.automationTrigger.name,
						unit: automationRule.automationTrigger.unit,
						hasStatus: automationRule.automationTrigger.hasStatus,
						hasRatePlanId: automationRule.automationTrigger.hasRatePlanId,
						hasCustomFields: automationRule.automationTrigger.hasCustomFields,
						automationTriggerFrameValueTypeId:
							automationRule.automationTrigger
								.automationTriggerFrameValueTypeId,
						hasAccountId: automationRule.automationTrigger.hasAccountId,
						hasCustomerId: automationRule.automationTrigger.hasCustomerId,
						hasPrepaidRatePlanId:
							automationRule.automationTrigger.hasPrepaidRatePlanId
					};
				})(),
				simState: automationRule.simState,
				ratePlanId: automationRule.ratePlanId,
				automationActionId: automationRule.automationActionId,
				automationActionId2: automationRule.automationActionId2,
				automationActionValue2: automationRule.automationActionValue2,
				automationActionSchedulerId2:
					automationRule.automationActionSchedulerId2,
				automationActionSchedulerValue2:
					automationRule.automationActionSchedulerValue2,
				...setInitialValuesForCustomFields(automationRule),
				follow_up_with: !!automationRule.automationActionId2,
				customerId: automationRule.customerId,
				filterAccountId: automationRule.filterAccountId,
				active: automationRule.active,
				automationRuleFrameActionValues: automationRule.automationRuleFrameActionValues.map(
					(item) => ({
						automationActionId: {
							...automationRule.automationAction,
							value: automationRule.automationAction.id,
							label: automationRule.automationAction.automationActionType.name
						},
						frame: item.automationFrame.id,
						automationFrameId: item.automationFrameId,
						automationTriggerSubCategoryId: item.automationTriggerSubCategoryId,
						frameValue: getFrameValue(automationRule, item),
						frameValue2: getFrameValue2(automationRule, item),
						automationActionValue: item.automationActionValue,
						id: item.id,
						zoneId: item.zoneId,
						simPoolId: item.simPoolId,
						zoneModelId: item.zoneModelId,
						customMessage: item.customMessage,
						automationFrameUnitId: item.automationFrameUnitId
					})
				),
				automationRuleFrameActionOriginalIds: automationRule.automationRuleFrameActionValues.map(
					(item) => item.id
				),
				isManualRule:
					automationRule.automationRuleType.code === ruleTypes.ManualRule
			};
			return {
				initialValues: rule
			};
		}

		if (!automationRule && !props.match.params.ruleId) {
			return {
				initialValues: {
					follow_up_with: false,
					automationRuleFrameActionValues: [{}]
				}
			};
		}

		return { rule };
	})(AutomationRuleDetailFormData);

	return AutomationRuleDetailFormData;
};

export default AutomationRuleDetailForm;
