export default {
	'SWAGGER.DEVELOPER_API': '开发商API',
	'SWAGGER.GETTING_STARTED': '入门指南',
	'SWAGGER.API_SANDBOX': 'API沙盒',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': '下面的Swagger沙盒列出了可在REST API中使用的资源清单，以及可从这些资源调用的操作。本文档还列出了操作参数清单，包括参数的名称和类型、是必需的参数还是选择参数、以及这些参数可接受的值等信息。',
	'SWAGGER.JSON_SCHEMA_INCLUDES': '文档还包括JSON模式，描述向REST API操作发送请求时的正文结构，以及操作返回的任何响应正文结构。',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'API入门指南',
	'SWAGGER.GETTING_STARTED_WITH_API_P': '首次调用API之前，您的源IP地址（服务器）必须被列入白名单。配置源IP的步骤通常在新用户引导过程中、创建API访问时完成。如果您收到任何错误讯息，很可能与AT&T IoT网关访问受阻有关。',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': '请先确保您已经完成下列步骤，再着手开发：',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '- 您已经设置了可公开访问的服务器，并在新用户引导的过程中向我们提供了该服务器的IP地址。',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '- 您的源IP已经被列入白名单，可以访问AT&T IoT网关。',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '- 贵公司的管理员已经向您提供了启用API服务时创建的API用户名和密码。',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '- 您可以访问资源服务器、执行curl命令，或使用您偏好的语言（详见下文）编写的连接应用程序。',
	'SWAGGER.GET_OAUTH_TOKEN_T': '获取OAuth访问令牌',
	'SWAGGER.GET_OAUTH_TOKEN_P': '使用贵公司管理员提供的用户名和密码，通过AT&T IoT身份获取OAuth令牌。可根据您的偏好，选用下列方法。令牌有效期为7天。',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'API连接器',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': '在下列范例中选择您希望如何连接到我们的API。',
	'SWAGGER.TEST_CONNECTION_T': 'IoT网关连接测试',
	'SWAGGER.TEST_CONNECTION_P': 'Curl命令范例：',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': '希望得到的结果',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': '如果您得到的HTTP响应代码是200 OK，您就可以开始开发了。',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': '连接错误故障排除',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': "如果您收到任何错误讯息，请首先查看您是否在HTTP头字段中正确设置了身份验证、访问令牌是否已过期需要更新。同时，请对AT&T IoT网关使用ping命令，检查您的源IP是否在白名单中。如果得到意外的结果，您也可以在用户支持部分提交故障排除通知单。'"
};
