import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, FieldArray } from 'redux-form';
import cn from 'classnames';

import { releaseDetailType } from '../../utils/constants';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { required, version, maxLength } from '../../../../utils/validators';

import PageTitle from '../../../Shared/views/PageTitleView';

import Button from '../../../../lib/DigitalComponents/Button';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './ReleaseNotesEdit.scss';
import { localizationHelper } from '../../../../utils/helperFunctions';

const PlusIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/pluss-add-collapse.svg'
);
const RemoveIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');

const deletedFields = [];
const maxLength250 = maxLength(250);

class ReleaseNotesEdit extends Component {
	componentDidMount() {
		const {
			match: {
				params: { id }
			},
			getReleaseNotesInit
		} = this.props;
		if (id) {
			this.getReleaseNotes(id);
		}
		getReleaseNotesInit();
		this.getAdditionalDetailStatuses();
	}

	componentWillUnmount() {
		const { getReleaseNotesInit } = this.props;
		getReleaseNotesInit();
	}

	submitForm = (values) => {
		const { addReleaseNotes, editReleaseNotes, backUrl } = this.props;
		const { version: releaseVersion } = values;
		let insertReleaseDetailsRequests = [];
		let updateReleaseDetailsRequests = [];
		let deleteReleaseDetailsRequests = [];

		values.knownIssues &&
			values.knownIssues.forEach((item, index) => {
				if (item.id) {
					updateReleaseDetailsRequests = [
						...updateReleaseDetailsRequests,
						{
							id: values.knownIssues[index].id,
							name: values.knownIssues[index].name,
							description: values.knownIssues[index].description,
							additionalDetailStatusId:
								values.knownIssues[index].additionalDetailStatusId.value ||
								values.knownIssues[index].additionalDetailStatusId,
							releaseDetailTypeId: 1
						}
					];
				} else {
					insertReleaseDetailsRequests = [
						...insertReleaseDetailsRequests,
						{
							name: values.knownIssues[index].name,
							description: values.knownIssues[index].description,
							additionalDetailStatusId:
								values.knownIssues[index].additionalDetailStatusId.value ||
								values.knownIssues[index].additionalDetailStatusId,
							releaseDetailTypeId: 1
						}
					];
				}
			});

		values.newFeatures &&
			values.newFeatures.forEach((item, index) => {
				if (item.id) {
					updateReleaseDetailsRequests = [
						...updateReleaseDetailsRequests,
						{
							id: values.newFeatures[index].id,
							description: values.newFeatures[index].description,
							releaseDetailTypeId: 2
						}
					];
				} else {
					insertReleaseDetailsRequests = [
						...insertReleaseDetailsRequests,
						{
							description: values.newFeatures[index].description,
							releaseDetailTypeId: 2
						}
					];
				}
			});

		values.newPlatformFeatures &&
			values.newPlatformFeatures.forEach((item, index) => {
				if (item.id) {
					updateReleaseDetailsRequests = [
						...updateReleaseDetailsRequests,
						{
							id: values.newPlatformFeatures[index].id,
							description: values.newPlatformFeatures[index].description,
							releaseDetailTypeId: 3
						}
					];
				} else {
					insertReleaseDetailsRequests = [
						...insertReleaseDetailsRequests,
						{
							description: values.newPlatformFeatures[index].description,
							releaseDetailTypeId: 3
						}
					];
				}
			});

		values.improvements &&
			values.improvements.forEach((item, index) => {
				if (item.id) {
					updateReleaseDetailsRequests = [
						...updateReleaseDetailsRequests,
						{
							id: values.improvements[index].id,
							description: values.improvements[index].description,
							releaseDetailTypeId: 4
						}
					];
				} else {
					insertReleaseDetailsRequests = [
						...insertReleaseDetailsRequests,
						{
							description: values.improvements[index].description,
							releaseDetailTypeId: 4
						}
					];
				}
			});

		values.deprications &&
			values.deprications.forEach((item, index) => {
				if (item.id) {
					updateReleaseDetailsRequests = [
						...updateReleaseDetailsRequests,
						{
							id: values.deprications[index].id,
							description: values.deprications[index].description,
							releaseDetailTypeId: 5
						}
					];
				} else {
					insertReleaseDetailsRequests = [
						...insertReleaseDetailsRequests,
						{
							description: values.deprications[index].description,
							releaseDetailTypeId: 5
						}
					];
				}
			});

		if (deletedFields && deletedFields.length > 0) {
			deletedFields.forEach((item) => {
				deleteReleaseDetailsRequests = [
					...deleteReleaseDetailsRequests,
					item.id
				];
			});
		}

		const releaseNotes = {
      version: releaseVersion,
			insertReleaseDetailsRequests
		};

		if (values.id) {
			editReleaseNotes({
				id: values.id,
				data: {
          ...releaseNotes,
					updateReleaseDetailsRequests,
					deleteReleaseDetailsRequests
				}
			});
		} else {
			addReleaseNotes({ data: releaseNotes, redirect: backUrl });
    }
	};

	goBack = () => {
		const {
			goBack,
			backUrl
		} = this.props;
		goBack(backUrl);
	};

	getReleaseNotes = (id) => {
		const { getReleaseNotes } = this.props;
		const additionalParams = {
			dataOffset: 0,
			include: [
				'ReleaseDetail',
				'ReleaseDetail.ReleaseDetailType',
				'ReleaseDetail.AdditionalDetailStatus'
			],
			dataSort: 'createdAt ASC'
		};

		getReleaseNotes({ id, additionalParams });
	};

	getAdditionalDetailStatuses = () => {
		const { getAdditionalDetailStatuses } = this.props;
		getAdditionalDetailStatuses();
	};

	Option = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.Option {...optionProps} data-spec={`${data.value}`}>
				<div
					data-spec={`${data.value}`}
					className={`${styles[this.renderStatusClass(data.value)]}`}
				>
					{children}
				</div>
			</components.Option>
		);
	};

	SingleValue = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.SingleValue {...optionProps} data-spec={`${data.value}`}>
				<div
					data-spec={`${data.value}`}
					className={`${styles[this.renderStatusClass(data.value)]}`}
				>
					{children}
				</div>
			</components.SingleValue>
		);
	};

	renderStatusClass = (value) => {
		let className = '';

		if (value === 1) className = 'fixed';
		else if (value === 2) className = 'enhanced';

		return className;
	};

	renderKnownIssues = ({ fields }) => {
		const { additionalDetailStatuses, formValues } = this.props;
		return (
			<div className={styles.known_issues_wrapper} data-spec="known-issues">
				{fields.map((knownIssue, index) => (
					<div className={styles.fields_wrapper} key={knownIssue}>
						<div className={styles.field_container}>
							<div className={styles.known_issue_name}>
								<FormattedMessage
									id="RELEASE_NOTES.ISSUE"
									defaultMessage="Issue"
								>
									{(formattedValue) => (
										<Field
											name={`${knownIssue}.name`}
											component={Input}
											label={`${formattedValue} # ${index + 1}`}
											validate={[required, maxLength250]}
										/>
									)}
								</FormattedMessage>
							</div>

							<div className={styles.known_issue_status}>
								<Field
									label="Select"
									component={Select}
									name={`${knownIssue}.additionalDetailStatusId`}
									cleanValue
									styles={{
										control: (base) => ({
											...base,
											minHeight: 30
										})
									}}
									options={
										additionalDetailStatuses &&
										additionalDetailStatuses.resultList &&
										additionalDetailStatuses.resultList.map((item) => ({
											value: item.id,
											label: (
												<FormattedMessage
													id={`RELEASE_NOTES.${localizationHelper(
														item.name
													).toUpperCase()}`}
													defaultMessage={item.name}
												/>
											)
										}))
									}
									components={{
										Option: this.Option,
										SingleValue: this.SingleValue
									}}
									validate={[required]}
								/>
							</div>
							<div
								className={styles.remove_button}
								onClick={() =>
									(formValues.knownIssues[index].id
										? ((fields.remove(index),
										  deletedFields.push(fields.get(index))))
										: fields.remove(index))}
							>
								<RemoveIcon />
							</div>
						</div>
						<div className={styles.known_issue_name}>
							<Field
								name={`${knownIssue}.description`}
								component={Input}
								componentClass="textarea"
								validate={[required, maxLength250]}
							/>
						</div>
					</div>
				))}
				<div className={styles.icon_wrapper} onClick={() => fields.push({})}>
					<PlusIcon />
					{
						<FormattedMessage
							id="RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE"
							defaultMessage="Add another Known Issue"
						/>
					}
				</div>
			</div>
		);
	};

	renderNewFeatures = ({ fields }) => {
		const { formValues } = this.props;
		return (
			<div data-spec="new-features">
				{fields.map((newFeature, index) => (
					<div className={styles.fields_wrapper} key={newFeature}>
						<div className={styles.field_container}>
							<div className={styles.field_full_width}>
								<FormattedMessage
									id="RELEASE_NOTES.FEATURE"
									defaultMessage="Feature"
								>
									{(formattedValue) => (
										<Field
											name={`${newFeature}.description`}
											component={Input}
											label={`${formattedValue} # ${index + 1}`}
											componentClass="textarea"
											validate={[required, maxLength250]}
										/>
									)}
								</FormattedMessage>
							</div>
							<div
								className={styles.remove_button}
								onClick={() =>
									(formValues.newFeatures[index].id
										? ((fields.remove(index),
										  deletedFields.push(fields.get(index))))
										: fields.remove(index))}
							>
								<RemoveIcon />
							</div>
						</div>
					</div>
				))}
				<div className={styles.icon_wrapper} onClick={() => fields.push({})}>
					<PlusIcon />
					{
						<FormattedMessage
							id="RELEASE_NOTES.ADD_ANOTHER_FEATURE"
							defaultMessage="Add another Feature"
						/>
					}
				</div>
			</div>
		);
	};

	renderNewPlatformFeatures = ({ fields }) => {
		const { formValues } = this.props;
		return (
			<div data-spec="new-platform-features">
				{fields.map((newPlatformFeature, index) => (
					<div className={styles.fields_wrapper} key={newPlatformFeature}>
						<div className={styles.field_container}>
							<div className={styles.field_full_width}>
								<FormattedMessage
									id="RELEASE_NOTES.FEATURE"
									defaultMessage="Feature"
								>
									{(formattedValue) => (
										<Field
											name={`${newPlatformFeature}.description`}
											component={Input}
											label={`${formattedValue} # ${index + 1}`}
											componentClass="textarea"
											validate={[required, maxLength250]}
										/>
									)}
								</FormattedMessage>
							</div>
							<div
								className={styles.remove_button}
								onClick={() =>
									(formValues.newPlatformFeatures[index].id
										? ((fields.remove(index),
										  deletedFields.push(fields.get(index))))
										: fields.remove(index))}
							>
								<RemoveIcon />
							</div>
						</div>
					</div>
				))}
				<div className={styles.icon_wrapper} onClick={() => fields.push({})}>
					<PlusIcon />
					{
						<FormattedMessage
							id="RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE"
							defaultMessage="Add another Platform Feature"
						/>
					}
				</div>
			</div>
		);
	};

	renderImprovements = ({ fields }) => {
		const { formValues } = this.props;
		return (
			<div data-spec="improvements">
				{fields.map((improvement, index) => (
					<div className={styles.fields_wrapper} key={improvement}>
						<div className={styles.field_container}>
							<div className={styles.field_full_width}>
								<FormattedMessage
									id="RELEASE_NOTES.IMPROVEMENT"
									defaultMessage="Improvement"
								>
									{(formattedValue) => (
										<Field
											name={`${improvement}.description`}
											component={Input}
											label={`${formattedValue} # ${index + 1}`}
											componentClass="textarea"
											validate={[required, maxLength250]}
										/>
									)}
								</FormattedMessage>
							</div>
							<div
								className={styles.remove_button}
								onClick={() =>
									(formValues.improvements[index].id
										? ((fields.remove(index),
										  deletedFields.push(fields.get(index))))
										: fields.remove(index))}
							>
								<RemoveIcon />
							</div>
						</div>
					</div>
				))}
				<div className={styles.icon_wrapper} onClick={() => fields.push({})}>
					<PlusIcon />
					{
						<FormattedMessage
							id="RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT"
							defaultMessage="Add another Improvement"
						/>
					}
				</div>
			</div>
		);
	};

	renderDeprications = ({ fields }) => {
		const { formValues } = this.props;
		return (
			<div data-spec="deprications">
				{fields.map((deprication, index) => (
					<div className={styles.fields_wrapper} key={deprication}>
						<div className={styles.field_container}>
							<div className={styles.field_full_width}>
								<FormattedMessage
									id="RELEASE_NOTES.DEPRICATION"
									defaultMessage="Deprication"
								>
									{(formattedValue) => (
										<Field
											name={`${deprication}.description`}
											component={Input}
											label={`${formattedValue} # ${index + 1}`}
											componentClass="textarea"
											validate={[required, maxLength250]}
										/>
									)}
								</FormattedMessage>
							</div>
							<div
								className={styles.remove_button}
								onClick={() =>
									(formValues.deprications[index].id
										? ((fields.remove(index),
										  deletedFields.push(fields.get(index))))
										: fields.remove(index))}
							>
								<RemoveIcon />
							</div>
						</div>
					</div>
				))}
				<div className={styles.icon_wrapper} onClick={() => fields.push({})}>
					<PlusIcon />
					{
						<FormattedMessage
							id="RELEASE_NOTES.ADD_ANOTHER_DEPRICATION"
							defaultMessage="Add another Deprication"
						/>
					}
				</div>
			</div>
		);
	};

	render() {
		const {
      invalid,
      pristine,
			handleSubmit,
			releaseNotesRequest,
			addReleaseNotesRequest,
      editReleaseNotesRequest
		} = this.props;

		return (
			<div data-spec="release-notes-edit">
				<PageTitle
					title={
						<FormattedMessage
							id="RELEASE_NOTES.RELEASE_NOTES"
							defaultMessage="Release Notes"
						/>
					}
					pushBack={this.goBack}
				/>
				<div className={styles.content_wrapper}>
					<div className={styles.subtitle}>
						<FormattedMessage
							id="RELEASE_NOTES.RELEASE_NOTES_SUBTITLE"
							defaultMessage="IoT Console is updated frequently to ensure most optimal operation and offer extended features and capabilities and user experience improvements. Below are the updates introduced in this release."
						/>
					</div>
					{(releaseNotesRequest ||
						addReleaseNotesRequest ||
						editReleaseNotesRequest) && <Loader />}
					{!(
						releaseNotesRequest ||
						addReleaseNotesRequest ||
						editReleaseNotesRequest
					) && (
						<form onSubmit={handleSubmit(this.submitForm)}>
							<h3 className={cn(styles.headline, styles.border)}>
								<div className={styles.release_version}>
									<FormattedMessage
										id="RELEASE_NOTES.RELEASE_VERSION"
										defaultMessage="Release Version"
									/>
									<Field
										component={Input}
										data-spec="version"
										name="version"
										validate={[required, version]}
									/>
								</div>
							</h3>
							<div className={styles.form_container}>
								<h3 className={cn(styles.headline, styles.border)}>
									<FormattedMessage
										id="RELEASE_NOTES.KNOWN_ISSUES"
										defaultMessage="Known Issues"
									/>
								</h3>
								<div className={styles.form_section_container}>
									<FieldArray
										name={'knownIssues'}
										component={this.renderKnownIssues}
									/>
								</div>

								<h3 className={cn(styles.headline, styles.border)}>
									<FormattedMessage
										id="RELEASE_NOTES.NEW_FEATURES"
										defaultMessage="New Features"
									/>
								</h3>
								<div className={styles.form_section_container}>
									<FieldArray
										name={'newFeatures'}
										component={this.renderNewFeatures}
									/>
								</div>

								<h3 className={cn(styles.headline, styles.border)}>
									<FormattedMessage
										id="RELEASE_NOTES.NEW_PLATFORM_FEATURES"
										defaultMessage="New Platform Features"
									/>
								</h3>
								<div className={styles.form_section_container}>
									<FieldArray
										name={'newPlatformFeatures'}
										component={this.renderNewPlatformFeatures}
									/>
								</div>

								<h3 className={cn(styles.headline, styles.border)}>
									<FormattedMessage
										id="RELEASE_NOTES.IMPROVEMENTS"
										defaultMessage="Improvements"
									/>
								</h3>
								<div className={styles.form_section_container}>
									<FieldArray
										name={'improvements'}
										component={this.renderImprovements}
									/>
								</div>

								<h3 className={cn(styles.headline, styles.border)}>
									<FormattedMessage
										id="RELEASE_NOTES.DEPRECATION"
										defaultMessage="Deprecation"
									/>
								</h3>
								<div className={styles.form_section_container}>
									<FieldArray
										name={'deprications'}
										component={this.renderDeprications}
									/>
								</div>
							</div>
							<hr className={styles.divider} />
							<div className={styles.section_buttons}>
								<Button
									label={
										<FormattedMessage id="CANCEL" defaultMessage="Cancel" />
									}
									variant="outline-primary"
									type="button"
									dataSpec="cancel-button"
									onClick={this.goBack}
								/>
								<Button
									label={
										<FormattedMessage
											id="RELEASE_NOTES.SAVE"
											defaultMessage="Save"
										/>
									}
									variant="primary"
									type="submit"
									dataSpec="submit-button"
									disabled={invalid || pristine}
								/>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}

const { func, object, bool, string } = PropTypes;

ReleaseNotesEdit.propTypes = {
	goBack: func,
	match: object,
	getReleaseNotes: func,
	getAdditionalDetailStatuses: func,
	additionalDetailStatuses: object,
	invalid: bool,
	pristine: bool,
	handleSubmit: func,
	releaseNotes: object,
	releaseNotesRequest: bool,
	getReleaseNotesInit: func,
	formValues: object,
	addReleaseNotes: func,
	addReleaseNotesRequest: bool,
	editReleaseNotes: func,
	editReleaseNotesRequest: bool,
	backUrl: string
};

let ReleaseNotesEditForm = reduxForm({
	form: 'ReleaseNotesEditForm',
	enableReinitialize: true
})(ReleaseNotesEdit);

ReleaseNotesEditForm = connect((state, props) => {
	let fields = {};

	if (props.releaseNotes) {
		const knownIssues = props.releaseNotes.releaseDetails.filter(
			(item) => item.releaseDetailTypeId === releaseDetailType.KNOWN_ISSUES
		);
		const newFeatures = props.releaseNotes.releaseDetails.filter(
			(item) => item.releaseDetailTypeId === releaseDetailType.NEW_FEATURES
		);
		const newPlatformFeatures = props.releaseNotes.releaseDetails.filter(
			(item) =>
				item.releaseDetailTypeId === releaseDetailType.NEW_PLATFORM_FEATURES
		);
		const improvements = props.releaseNotes.releaseDetails.filter(
			(item) => item.releaseDetailTypeId === releaseDetailType.IMPROVEMENTS
		);
		const deprications = props.releaseNotes.releaseDetails.filter(
			(item) => item.releaseDetailTypeId === releaseDetailType.DEPRECATION
		);
		fields = {
			id: props.releaseNotes.id,
			version: props.releaseNotes.version,
			knownIssues,
			newFeatures,
			newPlatformFeatures,
			improvements,
			deprications
		};
		return {
			initialValues: fields
		};
	}

	return { initialValues: fields };
})(ReleaseNotesEditForm);

export default ReleaseNotesEditForm;
