import hash from 'object-hash';
import { UserSettingsTableName, userSettingsID } from './constants';

export const AnalyticsInvoicesTableTabs = [
	{
		name: 'AnalyticsBillingInvoices',
		refTypeId: 9,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.INVOICE_ID',
					name: 'invoice_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.BILLING_CYCLE',
					name: 'billing_cycle',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACCOUNT_ID',
					name: 'm2maccount_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACCOUNT_NAME',
					name: 'account_name',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID',
					name: 'operator_account_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DEVICES',
					name: 'devices',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TOTAL_CHARGE',
					name: 'total_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_VOLUME',
					name: 'data_volume_mb',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_VOLUME',
					name: 'sms_volume_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.BILLABLE',
					name: 'billable',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATE',
					name: 'date_key',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TAXABLE',
					name: 'taxable_account',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TAX_STATUS',
					name: 'tax_status',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACCOUNT_SEGMENT',
					name: 'account_segment',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NOTES',
					name: 'invoiceNote',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DOWNLOAD',
					name: 'download',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.UPLOAD',
					name: 'upload',
					checked: true
				}
			]
		}
	}
];

export const AnalyticsInvoicesSignature = hash(
	JSON.stringify(AnalyticsInvoicesTableTabs[0].value.data)
);

export const InvoicesTableTabs = [
	{
		name: UserSettingsTableName.INVOICES,
		refTypeId: 7,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.INVOICE_ID',
					name: 'invoiceNumber',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.M2M_ACCOUNT_ID',
					name: 'm2mAccountId',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.COUNTRY_NAME',
					name: 'countryName',
					checked: false
				},
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER_ID',
					name: 'customerId',
					checked: false
				},
				{
					title: 'ANALYTICS_INVOICES.INVOICE_DATE',
					name: 'invoiceDate',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DOCUMENT_ID',
					name: 'documentId',
					checked: false
				},
				{
					title: 'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME',
					name: 'customerName',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.BILLING_CYCLE',
					name: 'billingCycle',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NOTES',
					name: 'notes',
					checked: true
				},
				{
					title: 'DOWNLOAD',
					name: 'download',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ADD_FILES',
					name: 'invoice_files',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.BILLING_ACCOUNT_IDS',
					name: 'billing_account_ids',
					checked: true
				}
			]
		}
	}
];

export const InvoicesSignature = hash(
	JSON.stringify(InvoicesTableTabs[0].value.data.filter((x) => x.checked))
);

export const EndpointsTableTabs = [
	{
		name: UserSettingsTableName.ENDPOINTS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.ICCID',
					name: 'iccid',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MSISDN',
					name: 'msisdn',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX',
					name: 'monthly_proration_index',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DEVICE_ID',
					name: 'device_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER',
					name: 'customer',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN',
					name: 'monthly_rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED',
					name: 'prepaid_terms_charged',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.STANDARD_RATE_PLAN',
					name: 'standard_rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SUBSCRIBER_STATUS',
					name: 'subscriber_status',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE',
					name: 'subscription_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.FIXED_POOL_CHARGE',
					name: 'fixed_pool_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_VOLUME',
					name: 'data_volume_mb',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_VOLUME',
					name: 'sms_volume_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MO_VOLUME',
					name: 'sms_mo_volume_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MT_VOLUME',
					name: 'sms_mt_volume_msg',
					checked: true
				}
			]
		}
	}
];

export const endpointsSignature = hash(
	JSON.stringify(EndpointsTableTabs[0].value.data)
);

export const PrepaidSubscriptionsTableTabs = [
	{
		name: UserSettingsTableName.PREPAID_SUBSCRIPTIONS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.ICCID',
					name: 'iccid',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER',
					name: 'customer',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DEVICE_ID',
					name: 'device_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_VERSION',
					name: 'plan_version',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_TYPE',
					name: 'plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MSISDN',
					name: 'msisdn',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE',
					name: 'subscription_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX',
					name: 'usage_proration_index',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TERM_START_DATE',
					name: 'term_start_date',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TERM_END_DATE',
					name: 'term_end_date',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE',
					name: 'included_data_usage_mb',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MO',
					name: 'included_sms_mo_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MT',
					name: 'included_sms_mt_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE',
					name: 'included_voice',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MO',
					name: 'included_voice_mo',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MT',
					name: 'included_voice_mt',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_CSD',
					name: 'included_csd',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_CSD_MO',
					name: 'included_csd_mo',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_CSD_MT',
					name: 'included_csd_mt',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE',
					name: 'terms_to_date_data_usage_primary_zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MO_VOLUME',
					name: 'sms_mo_volume_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MT_VOLUME',
					name: 'sms_mt_volume_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_VOLUME',
					name: 'voice_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_MO_VOLUME',
					name: 'voice_mo_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_MT_VOLUME',
					name: 'voice_volume_mt',
					checked: true
				}
			]
		}
	}
];

export const PrepaidSubscriptionsSignature = hash(
	JSON.stringify(PrepaidSubscriptionsTableTabs[0].value.data)
);

export const AddonsTableTabs = [
	{
		name: UserSettingsTableName.ADDONS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_VERSION',
					name: 'plan_version',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ADDON_SIZE',
					name: 'add_on_size_mb',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ZONE',
					name: 'zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ADDON_CHARGES',
					name: 'add_on_charges',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATE_PURCHASED',
					name: 'date_purchased',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO',
					name: 'base_plan_applied_to',
					checked: true
				}
			]
		}
	}
];

export const AddonsSignature = hash(
	JSON.stringify(AddonsTableTabs[0].value.data)
);

export const RatePlansTableTabs = [
	{
		name: UserSettingsTableName.RATE_PLANS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.PAYMENT_TYPE',
					name: 'payment_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_TYPE',
					name: 'plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS',
					name: 'activation_period_commitment_subs',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS',
					name: 'active_subscriptions',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVE_TIER',
					name: 'active_tier',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_CHARGE',
					name: 'data_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_VOLUME',
					name: 'data_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.FIXED_POOL_CHARGE',
					name: 'fixed_pool_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA',
					name: 'included_data',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA_MODE',
					name: 'included_data_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS',
					name: 'included_sms',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MO',
					name: 'included_sms_mo',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE',
					name: 'included_sms_mo_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MODE',
					name: 'included_sms_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MT',
					name: 'included_sms_mt',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE',
					name: 'included_sms_mt_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE',
					name: 'included_voice',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MO',
					name: 'included_voice_mo',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE',
					name: 'included_voice_mo_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE',
					name: 'included_voice_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MT',
					name: 'included_voice_mt',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS',
					name: 'minimum_activation_period_subs',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE',
					name: 'non_roaming_data_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME',
					name: 'non_roaming_data_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_VERSION',
					name: 'plan_version',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS',
					name: 'prepaid_activation',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE',
					name: 'roaming_data_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME',
					name: 'roaming_data_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_CHARGE',
					name: 'sms_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MO_VOLUME',
					name: 'sms_mo_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MT_VOLUME',
					name: 'sms_mt_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_VOLUME',
					name: 'sms_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE',
					name: 'subscription_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD',
					name: 'subscriptions_field',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TOTAL_CHARGE',
					name: 'total_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_CHARGE',
					name: 'voice_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_MT_VOLUME',
					name: 'voice_mt_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_VOLUME',
					name: 'voice_volume',
					checked: true
				}
			]
		}
	}
];

export const RatePlansSignature = hash(
	JSON.stringify(RatePlansTableTabs[0].value.data)
);

export const RatePlanZonesTableTabs = [
	{
		name: UserSettingsTableName.RATE_PLAN_ZONES,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_TYPE',
					name: 'plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PRIORITY',
					name: 'priority',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_ZONE',
					name: 'roaming_zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ZONE',
					name: 'zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE',
					name: 'active_overag_rate',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_CHARGE',
					name: 'data_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_VOLUME',
					name: 'data_volume_mb',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED',
					name: 'included_data_capped',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE',
					name: 'included_data_usage_mb',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_MODE',
					name: 'included_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_VERSION',
					name: 'plan_version',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE',
					name: 'total_included_usage_mb',
					checked: true
				}
			]
		}
	}
];

export const RatePlanZonesSignature = hash(
	JSON.stringify(RatePlanZonesTableTabs[0].value.data)
);

export const BillingGroupsTableTabs = [
	{
		name: UserSettingsTableName.BILLING_GROUPS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_VERSION',
					name: 'plan_version',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_TYPE',
					name: 'plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ZONE',
					name: 'zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PRIORITY',
					name: 'priority',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.GROUP_NAME',
					name: 'group_name',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_ZONE',
					name: 'roaming_zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS',
					name: 'included_sms_mo_msgs',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE',
					name: 'total_included_sms_mo_usage',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE',
					name: 'included_sms_mo_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MO_VOLUME',
					name: 'sms_mo_volume_msg',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_MO_CHARGE',
					name: 'sms_mo_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MO',
					name: 'included_voice_mo',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE',
					name: 'included_voice_mo_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO_USAGE',
					name: 'total_included_voice_mo_usage',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_MO_VOLUME',
					name: 'voice_mo_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_MO_CHARGE',
					name: 'voice_mo_charge',
					checked: true
				}
			]
		}
	}
];

export const BillingGroupsSignature = hash(
	JSON.stringify(BillingGroupsTableTabs[0].value.data)
);

export const DataDetailsTableTabs = [
	{
		name: UserSettingsTableName.DATA_DETAILS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.DEVICE_ID',
					name: 'device_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ICCID',
					name: 'iccid',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER',
					name: 'customer',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT',
					name: 'daily_rounding_adjustment',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_CHARGE',
					name: 'data_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA_VOLUME',
					name: 'data_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA',
					name: 'included_data',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED',
					name: 'included_data_capped',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_MODE',
					name: 'included_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX',
					name: 'monthly_proration_index',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MSISDN',
					name: 'msisdn',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.THE_PLAN_TYPE',
					name: 'the_plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_ZONE',
					name: 'roaming_zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ZONE',
					name: 'zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.BULK_OVERAGE_ADJUSTMENT',
					name: 'bulk_overage_adjustment',
					checked: true
				}
			]
		}
	}
];

export const DataDetailsSignature = hash(
	JSON.stringify(DataDetailsTableTabs[0].value.data)
);

export const SmsDetailsTableTabs = [
	{
		name: UserSettingsTableName.SMS_DETAILS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER',
					name: 'customer',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DEVICE_ID',
					name: 'device_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ICCID',
					name: 'iccid',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ZONE',
					name: 'zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_MODE',
					name: 'included_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_SMS',
					name: 'included_sms',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MESSAGE_TYPE',
					name: 'message_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX',
					name: 'monthly_proration_index',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MSISDN',
					name: 'msisdn',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_TYPE',
					name: 'plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_ZONE',
					name: 'roaming_zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_CHARGE',
					name: 'sms_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS_VOLUME',
					name: 'sms_volume',
					checked: true
				}
			]
		}
	}
];

export const SmsDetailsSignature = hash(
	JSON.stringify(SmsDetailsTableTabs[0].value.data)
);

export const ActivationsTableTabs = [
	{
		name: UserSettingsTableName.ACTIVATIONS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.DEVICE_ID',
					name: 'device_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ICCID',
					name: 'iccid',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER',
					name: 'customer',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVATION_CHARGE',
					name: 'activation_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVATION_TYPE',
					name: 'activation_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE',
					name: 'initial_activation_date',
					checked: true
				}
			]
		}
	}
];

export const ActivationsSignature = hash(
	JSON.stringify(ActivationsTableTabs[0].value.data)
);

export const DiscountsTableTabs = [
	{
		name: UserSettingsTableName.DISCOUNTS,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.CHARGE_TYPE',
					name: 'charge_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DISCOUNT',
					name: 'discount',
					checked: true
				}
			]
		}
	}
];

export const DiscountsSignature = hash(
	JSON.stringify(DiscountsTableTabs[0].value.data)
);

export const TaxesTableTabs = [
	{
		name: UserSettingsTableName.TAXES,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.PRIMARY_PLACE',
					name: 'primary_place',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TECH_TYPE',
					name: 'tech_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MRC',
					name: 'mrc',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DATA',
					name: 'data',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SMS',
					name: 'sms',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE',
					name: 'voice',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ACTIVATION',
					name: 'activation',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VPN',
					name: 'vpn',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.SIM',
					name: 'sim',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MRF',
					name: 'mfr',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PDP',
					name: 'pdp',
					checked: true
				}
			]
		}
	}
];

export const TaxesSignature = hash(
	JSON.stringify(TaxesTableTabs[0].value.data)
);

export const SurchargesTableTabs = [
	{
		name: UserSettingsTableName.SURCHARGES,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE',
					name: 'primary_place_of_use',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.FEDERAL',
					name: 'federal',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.STATE',
					name: 'state',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CITY',
					name: 'city',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.COUNTY',
					name: 'county',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DISTRICT_COUNTY',
					name: 'district_county',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DISTRICT_CITY',
					name: 'district_city',
					checked: true
				}
			]
		}
	}
];

export const SurchargesSignature = hash(
	JSON.stringify(SurchargesTableTabs[0].value.data)
);

export const TechTypesTableTabs = [
	{
		name: UserSettingsTableName.TECH_TYPES,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_ID',
					name: 'plan_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VERSION_ID',
					name: 'version_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES',
					name: 'no_of_2g_devices',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE',
					name: '2g_tech_type_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES',
					name: 'no_of_3g_devices',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE',
					name: '3g_tech_type_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES',
					name: 'no_of_4g_devices',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE',
					name: '4g_tech_type_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY',
					name: 'tech_type_charges_frequency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE',
					name: 'total_tech_type_charge',
					checked: true
				}
			]
		}
	}
];

export const TechTypesSignature = hash(
	JSON.stringify(TechTypesTableTabs[0].value.data)
);

export const OtherChargesTableTabs = [
	{
		name: UserSettingsTableName.OTHER_CHARGES,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.CHARGE_AMOUNT',
					name: 'charge_amount',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CHARGE_TYPE',
					name: 'charge_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DESCRIPTION',
					name: 'description',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.QUANTITY',
					name: 'quantity',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ORDER_NUMBER',
					name: 'order_number',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CURRENCY',
					name: 'currency',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.REFERENCE_ID',
					name: 'reference_id',
					checked: true
				}
			]
		}
	}
];

export const OtherChargesSignature = hash(
	JSON.stringify(OtherChargesTableTabs[0].value.data)
);

export const VoiceDetailsTableTabs = [
	{
		name: UserSettingsTableName.OTHER_CHARGES,
		refTypeId: userSettingsID,
		default: true,
		value: {
			data: [
				{
					title: 'ANALYTICS_INVOICES.ICCID',
					name: 'iccid',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.DEVICE_ID',
					name: 'device_id',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.CUSTOMER',
					name: 'customer',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.RATE_PLAN',
					name: 'rate_plan_name',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ZONE',
					name: 'zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.ROAMING_ZONE',
					name: 'roaming_zone',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.GROUP_NAME',
					name: 'group_name',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PLAN_TYPE',
					name: 'plan_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_MODE',
					name: 'included_mode',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_VOLUME',
					name: 'voice_volume',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.VOICE_CHARGE',
					name: 'voice_charge',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.INCLUDED_VOICE',
					name: 'included_voice',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.MESSAGE_TYPE',
					name: 'message_type',
					checked: true
				},
				{
					title: 'ANALYTICS_INVOICES.PRORATION_INDEX',
					name: 'proration_index',
					checked: true
				}
			]
		}
	}
];

export const VoiceDetailsSignature = hash(
	JSON.stringify(OtherChargesTableTabs[0].value.data)
);
