import globalAction from '../../../../redux/global/globalAction';

import { GET_DISCOUNTS, GET_DISCOUNTS_FILTERS } from './constants';

import {
	getDiscounts as getDiscountsService,
	getDiscountsFilters as getDiscountsFiltersService
} from '../../services/DiscountService';

/** ************ get discounts ************* */
export const getDiscounts = (id, params = {}) =>
	globalAction(GET_DISCOUNTS, getDiscountsService, { id, params });

/** ************ get discounts filters ************* */
export const getDiscountsFilters = (id, params = {}) =>
	globalAction(GET_DISCOUNTS_FILTERS, getDiscountsFiltersService, {
		id,
		params
	});
