import { createAction } from 'redux-actions';

import { RESET_FILTER_INIT, RESET_FILTER_SUCCESS } from './constants';

export const resetFilterInit = createAction(RESET_FILTER_INIT);
export const resetFilterSuccess = createAction(RESET_FILTER_SUCCESS);

/** ************ set reset filter ************* */
export const setResetFilter = (filterData) => async (dispatch) => {
	dispatch(resetFilterSuccess(filterData));
};
