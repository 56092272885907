import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { format } from 'date-fns';
import { CurrencyFormatters } from '../../../../../utils/constants';
import hoc from '../SimOrderEdit';

import { localizationHelper } from '../../../../../utils/helperFunctions';

import styles from './Mobile.scss';

const currencyFormatter = (value) =>
	new Intl.NumberFormat(
		CurrencyFormatters.usdDecimals.locale,
		CurrencyFormatters.usdDecimals.options
	).format(parseFloat(value || 0));

const quantityFormatter = (value) =>
	new Intl.NumberFormat(
		CurrencyFormatters.usdQuantity.locale,
		CurrencyFormatters.usdQuantity.options
	).format(parseFloat(value));

class Mobile extends PureComponent {
	render() {
		const { simOrder } = this.props;
		return (
			<div data-spec="ticket-details-mobile" className={styles.wrapper}>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="M2M_ACCOUNT" defaultMessage="M2M Account" />
					</div>
					<div className={styles.info}>
						{simOrder.platform &&
							simOrder.platform.platformType &&
							simOrder.platform.platformType.name}
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="M2M_ACCOUNT_ID"
							defaultMessage="M2M Account ID"
						/>
					</div>
					<div className={styles.info}>{simOrder.m2mAccountId}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="ORDER_NUMBER_ID"
							defaultMessage="Order Number ID"
						/>
					</div>
					<div className={styles.info}>{simOrder.id}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="ORDER_STATUS" defaultMessage="Order Status" />
					</div>
					<div
						className={cn(
							styles.status,
							styles[simOrder.simOrderStatus && simOrder.simOrderStatus.name.toLowerCase()]
						)}
					>
						<FormattedMessage
							id={`${localizationHelper(
								simOrder.simOrderStatus && simOrder.simOrderStatus.name
							).toUpperCase()}`}
							defaultMessage={
								simOrder.simOrderStatus && simOrder.simOrderStatus.name
							}
						/>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="ORDER_CONFIRMATION_NUMBER"
							defaultMessage="Order Confirmation Number"
						/>
					</div>
					<div className={styles.info}>{simOrder.orderConfirmationNumber}</div>
				</div>
				<div className={styles.block}>
					<div className={styles.row}>
						<div className={styles.label}>
							<FormattedMessage id="SKU" defaultMessage="SKU" />
						</div>
						<div className={styles.info}>
							{simOrder.simSku && simOrder.simSku.name}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<FormattedMessage id="PLATFORM" defaultMessage="Platform" />
						</div>
						<div className={styles.info}>
							{simOrder.platform && simOrder.platform.m2mAccountId}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<FormattedMessage id="DESCRIPTION" defaultMessage="Description" />
						</div>
						<div className={styles.info}>
							{simOrder.simSku && simOrder.simSku.description}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<FormattedMessage id="UNIT_PRICE" defaultMessage="Unit Price" />
						</div>
						<div className={styles.info}>
							{currencyFormatter(simOrder.simSku && simOrder.simSku.unitPrice)}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<FormattedMessage id="QUANTITY" defaultMessage="Quantity" />
						</div>
						<div className={styles.info}>
							{quantityFormatter(simOrder.quantity)}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.label}>
							<FormattedMessage id="TOTAL_PRICE" defaultMessage="Total Price" />
						</div>
						<div className={styles.total_info}>
							{currencyFormatter(
								simOrder.simSku && simOrder.simSku.unitPrice * simOrder.quantity
							)}
						</div>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="CONTACT_NAME" defaultMessage="Contact Name" />
					</div>
					<div className={styles.info}>{simOrder.contactName}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="PURCHASE_ORDER_NUMBER"
							defaultMessage="PO #"
						/>
					</div>
					<div className={styles.info}>{simOrder.po}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="TELEPHONE_NUMBER"
							defaultMessage="Telephone Number"
						/>
					</div>
					<div className={styles.info}>{simOrder.telephoneNumber}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="TARGET_IMPLEMENTATION_DATE"
							defaultMessage="Target Implementation Date"
						/>
					</div>
					<div className={styles.info}>
						{format(new Date(simOrder.targetImplementationDate), 'MM/dd/yyyy')}
					</div>
				</div>
				<h4 className={styles.section_headline}>
					<FormattedMessage
						id="SHIPPING_ADDRESS"
						defaultMessage="Shipping Address"
					/>
				</h4>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="ADDRESS_LINE_1" defaultMessage="Address 1" />
					</div>
					<div className={styles.info}>{simOrder.address1}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="ADDRESS_LINE_2" defaultMessage="Address 2" />
					</div>
					<div className={styles.info}>{simOrder.address2}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="ADDRESS_LINE_2" defaultMessage="Address 2" />
					</div>
					<div className={styles.info}>{simOrder.address2}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="CITY" defaultMessage="City" />
					</div>
					<div className={styles.info}>{simOrder.city}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="COUNTRY" defaultMessage="Country" />
					</div>
					<div className={styles.info}>
						{simOrder.country && simOrder.country.name}
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="STATE" defaultMessage="State" />
					</div>
					<div className={styles.info}>
						{simOrder.state && simOrder.state.name}
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage id="ZIP_CODE" defaultMessage="ZIP Code" />
					</div>
					<div className={styles.info}>{simOrder.zipcode}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="SHIPPING_NOTES"
							defaultMessage="Shipping Notes"
						/>
					</div>
					<div className={styles.info}>{simOrder.shippingNotes}</div>
				</div>

				<div className={styles.row}>
					<div className={styles.label}>
						<FormattedMessage
							id="EXPEDITED_SHIPPING"
							defaultMessage="Expedited Shipping"
						/>
					</div>
					<div className={styles.info}>
						{simOrder.expeditedShipping ? (
							<FormattedMessage id="YES" defaultMessage="Yes" />
						) : (
							<FormattedMessage id="NO" defaultMessage="No" />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const { shape, array } = PropTypes;

Mobile.propTypes = {
	simOrder: shape({
		resultList: array
	})
};

export default hoc()(Mobile);
