/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { find, uniqBy, flattenDeep, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { getUser } from '../../../../../redux/user/getUserData/selectors';
import { getEndpointsSuccess, getAvailableStatesData } from '../../../redux/selectors';

import Button from '../../../../../lib/DigitalComponents/Button';
import StatusBox from '../StatusBox';
import { updateBulkSingleEndpointStatusStarted as updateBulkSingleEndpointStatusStartedAction } from '../../../redux/updateBulkSingleEndpoints/actions';

import styles from './Steps.scss';

const Show = ImageSelector(CurrentContext.theme, 'svgs/pluss-add-collapse.svg');
const Hide = ImageSelector(CurrentContext.theme, 'svgs/minus-add-collapse.svg');

const StatusRWComponent = UserAllowedToAccess([
	'mnc.endpoints.simstatus_rw'
]);

class StepFirst extends Component {
	constructor(props) {
		super(props);
		this.state = {
			originalSelectedSims: props.selectedSims,
			processedSims: [],
			newSimStatus: '',
			isRuleOpened: false,
			currentSelectedStates: [],
			commonStates: []
		};
	}

	componentDidMount() {
		const {
			context,
			updateBulkSingleEndpointStatusStarted,
			selectedSims
		} = this.props;
		updateBulkSingleEndpointStatusStarted();

		if (context) {
			this.setFooter(context);
		}

		if (selectedSims.length) {
			this.setState({
				processedSims: this.processSelectedSims(),
				commonStates: this.getCommonAvailableStates(this.processSelectedSims()),
				currentSelectedStates: uniqBy(this.processSelectedSims(), 'status').map(
					(item) => item.status
				)
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context, selectedSims } = this.props;
		const { newSimStatus } = this.state;
		if (context && !isEqual(newSimStatus, prevState.newSimStatus)) {
			this.setFooter(context);
		}
		if (prevProps.selectedSims.length !== selectedSims.length) {
			this.setProcessedSimsAndCommonStates();
		}
	}

	setProcessedSimsAndCommonStates = () =>
		this.setState({
			processedSims: this.processSelectedSims(),
			commonStates: this.getCommonAvailableStates(this.processSelectedSims())
		});

	setFooter = (context) => {
		const { onClose } = this.props;
		const { newSimStatus } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<StatusRWComponent>
					<Button
						dataSpec="continue"
						variant="primary"
						onClick={this.onNext}
						disabled={!newSimStatus}
						label={<FormattedMessage id="CONTINUE" defaultMessage="Continue" />}
					/>
				</StatusRWComponent>
			</>
		);

		context.updateContext({ footer });
	};

	checkSimsNetworkType = () => {
		const { processedSims } = this.state;
		if (
			uniqBy(processedSims, 'networkType').length > 1 ||
			uniqBy(processedSims, 'serviceProvider').length > 1
		) {
			return true;
		}
		return false;
	};

	renderModalBody = () => {
		const { selectedSims } = this.props;
		const { processedSims, commonStates, isRuleOpened } = this.state;
		this.checkSimsNetworkType();
		if (selectedSims.length < 2) {
			return (
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className={styles.step_first}>
						<h2 className={styles.touppercase}>
							<FormattedMessage
								id="ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS"
								defaultMessage="Please select a few of SIMS."
							/>
						</h2>
					</div>
				</div>
			);
		}
		if (this.checkSimsNetworkType()) {
			return (
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className={styles.step_first}>
						<h2 className={styles.touppercase}>
							<FormattedMessage
								id="ENDPOINTS.NETWORK_TYPE_CHECK"
								defaultMessage="We are sorry, multiple endpoint state change is not allowed for endpoints from different network types or different service providers."
							/>
						</h2>
					</div>
				</div>
			);
		}
		return (
			<div data-spec="ds-modal-body" className="first-step-modal-body">
				<div className={styles.step_first}>
					<h2 className={styles.touppercase}>
						<FormattedMessage
							id="ENDPOINTS.SELECT_SIM_STATE"
							defaultMessage="Select SIM state"
						/>
					</h2>
					<p>
						{processedSims.length}
            &nbsp;
						<FormattedMessage
							id="ENDPOINTS.SIMS_SELECTED"
							defaultMessage="SIMs selected"
						/>
						(
						<FormattedMessage
							id="ENDPOINTS.CURRENT_STATES"
							defaultMessage="Current states"
						/>
						:
            {this.getCurrentSimStatus()}
            )
					</p>
					<div className={styles.status_list}>
						{this.renderAvailableStates()}
					</div>
					<p>
						<FormattedMessage
							id="ENDPOINTS.SELECTED_TIP"
							defaultMessage="Tip: If you have selected SIMs that are in different states, you will only be able to change to a state that is available for all selected SIMs."
						/>
					</p>
					{!!commonStates.length && (
						<div className={styles.change_rules}>
							<div className={styles.view} onClick={this.toggleRules}>
								<span>
									<FormattedMessage
										id="VIEW_RULES"
										defaultMessage="View state change rules for this account"
									/>
								</span>
								{!isRuleOpened ? <Show /> : <Hide />}
							</div>
							<div className={styles.rules_list}>
								{this.renderChangeRules()}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	};

	// onNext
	onNext = () => {
		const { onNext, user } = this.props;
		const {
			newSimStatus,
			currentSelectedStates,
			originalSelectedSims,
			processedSims
		} = this.state;
		onNext({
			user,
			newSimStatus,
			currentSelectedStates,
			originalSelectedSims,
			processedSims
		});
	};

	// create new array processedSims from this.state.originalSelectedSims
	// map endpointName from originalSelectedSims with this.props.endpoints
	processSelectedSims = () => {
		const { originalSelectedSims } = this.state;
		return originalSelectedSims;
	};

	// get unique current state from all selected sims
	getCurrentSimStatus = () => {
		const { currentSelectedStates } = this.state;
		return currentSelectedStates.map((item, index) => (
			<span data-spec="states" key={index}>
				<FormattedMessage id={item} defaultMessage={item} />
				{index + 1 < currentSelectedStates.length ? ', ' : ''}
			</span>
		));
	};

	// get available change states that are common for all selected sim
	getCommonAvailableStates = (processedSims) => {
		const { availableStates } = this.props;
		if (!Object.keys(availableStates).length) {
			return [];
		}
		let tempStates = [];
		let check = [];
		const uniqueArray = uniqBy(processedSims, 'status');
		const uniqueServiceProvider = uniqBy(processedSims, 'serviceProvider')[0]
			.serviceProvider;

		uniqueArray.forEach((item) => {
			const targetState = find(availableStates[uniqueServiceProvider], {
				currentState: item.status
			});
			let targetStates = [];
			if (targetState) {
				targetStates = targetState.targetStates;
			}
			check = [...check, targetStates.length];
			tempStates = [...tempStates, targetStates];
		});

		let result = [];

		const flatenTemp = flattenDeep(tempStates);
		let newCounter = 0;

		flatenTemp.forEach((flaten) => {
			tempStates.forEach((temp) => {
				if (find(temp, { state: flaten.state })) {
					newCounter += 1;
				}
			});
			if (newCounter === tempStates.length) {
				if (result.indexOf(flaten.state) === -1) {
					result = [...result, flaten.state];
				}
			}
			newCounter = 0;
		});

		if (check.indexOf(0) === -1) {
			return result;
		}
		return [];
	};

	renderAvailableStates = () => {
		const { commonStates, newSimStatus } = this.state;
		if (!commonStates.length) {
			return (
				<div className={styles.states_message} data-spec="no-common-states">
					<FormattedMessage
						id="ENDPOINTS.STATES_MESSAGE"
						defaultMessage="No states available."
					/>
				</div>
			);
		}
		return commonStates.map((item, index) => (
			<FormattedMessage id={item} defaultMessage={item} key={index}>
				{(formattedValue) => (
					<StatusBox
						data-spec="status"
						label={item}
						displayLabel={formattedValue}
						onClick={(state) => this.setNewState(state)}
						newSimStatus={newSimStatus}
					/>
				)}
			</FormattedMessage>
		));
	};

	setNewState = (state) => {
		this.setState({
			newSimStatus: state
		});
	};

	renderChangeRules = () => {
		const { isRuleOpened } = this.state;
		const { availableStates } = this.props;
		const processedSims = this.processSelectedSims();
		const uniqueServiceProvider = uniqBy(processedSims, 'serviceProvider')[0]
			.serviceProvider;
		return (
			<div
				data-spec="state-rules"
				className={cn(
					styles.rule_data,
					isRuleOpened ? styles.rule_data_show : ''
				)}
			>
				{availableStates[uniqueServiceProvider] &&
					availableStates[uniqueServiceProvider].map((item, index) => {
						const tempArray = item.targetStates;
						return (
							<div data-spec="stae-rule" key={index}>
								<FormattedMessage
									id={item.currentState}
									defaultValue={item.currentState}
								>
									{(formattedValue) => (
										<span>
											{formattedValue.toLowerCase()}
											&nbsp;
										</span>
									)}
								</FormattedMessage>
								{tempArray.map((stateItem, newIndex) => (
									<FormattedMessage
										id={stateItem.state}
										defaultValue={stateItem.state}
										key={index + newIndex}
									>
										{(formattedValue) => (
											<span>
												{formattedValue.toLowerCase()}
												{newIndex + 1 < tempArray.length ? ', ' : ''}
											</span>
										)}
									</FormattedMessage>
								))}
							</div>
						);
					})}
			</div>
		);
	};

	toggleRules = () => {
		const { isRuleOpened } = this.state;
		this.setState({
			isRuleOpened: !isRuleOpened
		});
	};

	render() {
		return (
			<div data-spec="batch-upload-first-step">{this.renderModalBody()}</div>
		);
	}
}

const { array, func, object } = PropTypes;

StepFirst.propTypes = {
	selectedSims: array,
	onClose: func,
	availableStates: object,
	user: object,
	onNext: func,
	updateBulkSingleEndpointStatusStarted: func,
	context: object
};

const mapDispatchToProps = (dispatch) => ({
	updateBulkSingleEndpointStatusStarted: () =>
		dispatch(updateBulkSingleEndpointStatusStartedAction())
});

const mapStateToProps = (state) => ({
	endpoints: getEndpointsSuccess(state),
	availableStates: getAvailableStatesData(state),
	user: getUser(state)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepFirst);
