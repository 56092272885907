import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_ACTIVATIONS, GET_ACTIVATIONS_FILTERS } from './constants';

export default combineReducers({
	getActivations: globalReducer(GET_ACTIVATIONS),
	getActivationsFilters: globalReducer(GET_ACTIVATIONS_FILTERS)
});
