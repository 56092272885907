/* eslint-disable max-classes-per-file */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';

const BulkUploadModalContext = React.createContext();

const { shape } = PropTypes;

class BulkUploadModalContextProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { children } = this.props;
		return (
			<BulkUploadModalContext.Provider
				data-spec="bulk-upload-provider"
				value={{
					state: this.state,
					updateContext: (newState) => this.setState(newState)
				}}
			>
				{children}
			</BulkUploadModalContext.Provider>
		);
	}
}

BulkUploadModalContextProvider.propTypes = {
	children: shape()
};

class BulkUploadModalContextConsumer extends PureComponent {
	render() {
		const { children } = this.props;
		return (
			<BulkUploadModalContext.Consumer data-spec="bulk-upload-consumer">
				{(context) =>
					React.Children.map(children, (child) =>
						React.cloneElement(child, { ...this.props, context }))}
			</BulkUploadModalContext.Consumer>
		);
	}
}

BulkUploadModalContextConsumer.propTypes = {
  children: shape()
};

export {
	BulkUploadModalContext,
	BulkUploadModalContextProvider,
	BulkUploadModalContextConsumer
};
