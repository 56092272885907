import { combineReducers } from 'redux';

import invoices from './invoices/reducer';
import activations from './activations/reducer';
import endpoints from './endpoints/reducer';
import prepaidSubscriptions from './prepaidSubscriptions/reducer';
import addons from './addons/reducer';
import ratePlans from './ratePlans/reducer';
import ratePlanZones from './ratePlanZones/reducer';
import billingGroups from './billingGroups/reducer';
import dataDetails from './dataDetails/reducer';
import smsDetails from './smsDetails/reducer';
import discounts from './discounts/reducer';
import taxes from './taxes/reducer';
import surcharges from './surcharges/reducer';
import techTypes from './techTypes/reducer';
import otherCharges from './otherCharges/reducer';
import voiceDetails from './voiceDetails/reducer';

export default combineReducers({
	invoices,
	tables: combineReducers({
		activations,
		endpoints,
		prepaidSubscriptions,
		addons,
		ratePlans,
		ratePlanZones,
		billingGroups,
		dataDetails,
		smsDetails,
		discounts,
		taxes,
		surcharges,
		techTypes,
		otherCharges,
		voiceDetails
	})
});
