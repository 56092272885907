import { createAction } from 'redux-actions';
import * as BatchFilesService from '../../services/BatchFilesService';

import {
	BATCH_FILE_UPLOAD_FAIL,
	BATCH_FILE_UPLOAD_REQUEST,
	BATCH_FILE_UPLOAD_SUCCESS,
	GET_BATCH_FILES_FAIL,
	GET_BATCH_FILES_REQUEST,
	GET_BATCH_FILES_SUCCESS,
	CLEAR_UPLOAD_ERRORS
} from './constants';

const getBatchFilesFail = createAction(GET_BATCH_FILES_FAIL);
const getBatchFilesSuccess = createAction(GET_BATCH_FILES_SUCCESS);
const getBatchFilesRequest = createAction(GET_BATCH_FILES_REQUEST);

export const batchFileUploadFail = createAction(BATCH_FILE_UPLOAD_FAIL);
export const clearUploadErrors = createAction(CLEAR_UPLOAD_ERRORS);
const batchFileUploadSuccess = createAction(BATCH_FILE_UPLOAD_SUCCESS);
const batchFileUploadRequest = createAction(BATCH_FILE_UPLOAD_REQUEST);

export const batchFileRequestURL = (data, type) => async (dispatch) => {
	let id = '';
	dispatch(batchFileUploadRequest());
	BatchFilesService.batchFileRequestURL(data.request, data.activationFile, type)
		.then((response) => response)
		.then((response) => {
			const { uploadURL } = response;
			id = response.id;
			return BatchFilesService.batchFileUpload(uploadURL, data.file);
		})
		.then(() => BatchFilesService.pollForFile({ id }, 200, 4000, type))
		.then(() => {
			dispatch(batchFileUploadSuccess());
		})
		.catch((error) => {
			dispatch(batchFileUploadFail(error));
		});
};

export const getBatchFiles = (paginationData) => async (dispatch) => {
	dispatch(getBatchFilesRequest());
	BatchFilesService.getBatchFiles(paginationData)
		.then((response) => {
			dispatch(getBatchFilesSuccess(response));
		})
		.catch((error) => {
			dispatch(getBatchFilesFail(error));
		});
};
