import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const Version = () => (WrappedComponent) => {
  class VersionComponent extends PureComponent {
		getOptions = () => {
      const { releaseNotesList } = this.props;

			return (
        releaseNotesList &&
        releaseNotesList.resultList &&
        releaseNotesList.resultList.length &&
				releaseNotesList.resultList.map((item) => ({
					value: item.id,
					label: item.version
				}))
			);
		};

		getValue = () => {
      const { releaseNotes } = this.props;
			if (releaseNotes) {
				return {
					value: releaseNotes.id,
					label: releaseNotes.version
				};
			}
		};

		goToEditReleaseNotes = (id) => {
      const { goToEditReleaseNotes } = this.props;
			goToEditReleaseNotes(`/release-notes/${id}`);
		};

		getReleaseNotes = (id) => {
			const { getReleaseNotes, published } = this.props;
			const additionalParams = {
				dataOffset: 0,
				include: [
					'ReleaseDetail',
					'ReleaseDetail.ReleaseDetailType',
					'ReleaseDetail.AdditionalDetailStatus'
				],
				dataSort: 'createdAt ASC'
      };

			getReleaseNotes({ id, additionalParams, published });
    };

		render() {
      const { publishReleaseNotes, publishReleaseNotesRequest } = this.props;

			return (
				<WrappedComponent
					data-spec="release-notes-version-wrapper"
					{...this.props}
					{...this.state}
					getReleaseNotes={this.getReleaseNotes}
					getOptions={this.getOptions}
					getValue={this.getValue}
					goToEditReleaseNotes={this.goToEditReleaseNotes}
					publishReleaseNotes={publishReleaseNotes}
					publishReleaseNotesRequest={publishReleaseNotesRequest}
				/>
			);
		}
  }

  const { func, object, bool } = PropTypes;

	VersionComponent.propTypes = {
		releaseNotesList: object,
		releaseNotesListRequest: bool,
		releaseNotesRequest: bool,
		releaseNotes: object,
		goToEditReleaseNotes: func,
		getReleaseNotes: func,
		publishReleaseNotes: bool,
		publishReleaseNotesRequest: bool,
		published: bool
	};

  return VersionComponent;
};

export default Version;
