import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_USER_GROUP_PERMISSIONS,
	EDIT_USER_GROUP_PERMISSIONS,
	GET_USER_EFFECTIVE_PERMISSIONS
} from './constants';

export default combineReducers({
	getUserGroupPermissions: globalReducer(GET_USER_GROUP_PERMISSIONS),
	editUserGroupPermissions: globalReducer(EDIT_USER_GROUP_PERMISSIONS),
	getUserEffectivePermissions: globalReducer(GET_USER_EFFECTIVE_PERMISSIONS)
});
