import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import { Desktop, Mobile } from '../../components/ActionBar';

const ActionBarView = (props) => (
	<>
		<DesktopMediaQuery>
			<Desktop {...props} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Desktop {...props} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<Mobile {...props} />
		</MobileMediaQuery>
	</>
);

export default ActionBarView;
