import { combineReducers } from 'redux';
import globalModalReducer from '../../../../redux/global/globalModalReducer';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	AUTOMATION_ZONES_MODAL,
	PATCH_FILE_UPLOAD,
	GET_AUTOMATION_ZONES,
	GET_UPLOAD_TEMPLATES
} from './constants';

export default combineReducers({
	automationZonesModal: globalModalReducer(AUTOMATION_ZONES_MODAL),
	patchFileUpload: globalReducer(PATCH_FILE_UPLOAD),
	getAutomationZones: globalReducer(GET_AUTOMATION_ZONES),
	getUploadTemplate: globalReducer(GET_UPLOAD_TEMPLATES)
});
