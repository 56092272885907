/* eslint-disable import/prefer-default-export */
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import EditUserProfileDesktop from './Desktop';

import {
	getUser,
	editUser,
	openAddUserGroupModal,
	closeAddUserGroupModal,
	editUserInit,
	getAddressTypes,
	deleteUserProfile,
	getCountries,
	getUserInit,
	getFirstAddressStates,
	getSecondAddressStates,
	getThirdAddressStates,
	getLanguages,
} from '../../redux/users/actions';

import {
	getUserRequest,
	getUserFail,
	getUserSuccess,
	editUserRequest,
	editUserFail,
	editUserSuccess,
	isAddUserGrupModalOpened,
	getAddressTypeRequest,
	getAddressTypeFail,
	getAddressTypeSuccess,
	deleteProfileFail,
	deleteProfileRequest,
	deleteProfileSuccess,
	getCountriesSuccess,
	getCountriesFail,
	getCountriesRequest,
	getFirstAddressStatesRequest,
	getFirstAddressStatesFail,
	getFirstAddressStatesSuccess,
	getSecondAddressStatesRequest,
	getSecondAddressStatesFail,
	getSecondAddressStatesSuccess,
	getThirdAddressStatesRequest,
	getThirdAddressStatesFail,
	getThirdAddressStatesSuccess,
	getLanguagesFail,
	getLanguagesRequest,
	getLanguagesSuccess,
} from '../../redux/users/selectors';

import {
	getUserSettings,
	getUser as currentUser,
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	userRequest: getUserRequest(state),
	userFail: getUserFail(state),
	user: getUserSuccess(state),
	initialValues: getUserSuccess(state),
	countriesRequest: getCountriesRequest(state),
	countriesFail: getCountriesFail(state),
	countries: getCountriesSuccess(state),
	formData: getFormValues('EditUserProfileForm')(state),
	editUserRequest: editUserRequest(state),
	editUserFail: editUserFail(state),
	editUserSuccess: editUserSuccess(state),
	isAddUserGrupModalOpened: isAddUserGrupModalOpened(state),
	getAddressTypeRequest: getAddressTypeRequest(state),
	getAddressTypeFail: getAddressTypeFail(state),
	addressTypes: getAddressTypeSuccess(state),
	settings: getUserSettings(state, 3),
	currentUser: currentUser(state),
	deleteProfileRequest: deleteProfileRequest(state),
	deleteProfileFail: deleteProfileFail(state),
	deleteProfile: deleteProfileSuccess(state),
	getFirstAddressStatesRequest: getFirstAddressStatesRequest(state),
	getFirstAddressStatesFail: getFirstAddressStatesFail(state),
	firstAddressStates: getFirstAddressStatesSuccess(state),
	getSecondAddressStatesRequest: getSecondAddressStatesRequest(state),
	getSecondAddressStatesFail: getSecondAddressStatesFail(state),
	secondAddressStates: getSecondAddressStatesSuccess(state),
	getThirdAddressStatesRequest: getThirdAddressStatesRequest(state),
	getThirdAddressStatesFail: getThirdAddressStatesFail(state),
	thirdAddressStates: getThirdAddressStatesSuccess(state),
	getLanguagesFail: getLanguagesFail(state),
	getLanguagesRequest: getLanguagesRequest(state),
	getLanguagesSuccess: getLanguagesSuccess(state),
});

const mapDispatchToProps = (dispatch) => ({
	getUser: (id, params) => dispatch(getUser(id, params)),
	getUserInit: () => dispatch(getUserInit()),
	push: (url) => dispatch(localizedRoute(url)),
	goToIndividualPermissions: (url) => dispatch(localizedRoute(url)),
	goToEffectivePermissions: (url) => dispatch(localizedRoute(url)),
	getCountries: (params) => dispatch(getCountries(params)),
	getFirstAddressStates: (params) => dispatch(getFirstAddressStates(params)),
	getSecondAddressStates: (params) => dispatch(getSecondAddressStates(params)),
	getThirdAddressStates: (params) => dispatch(getThirdAddressStates(params)),
	getAddressTypes: () => dispatch(getAddressTypes()),
	editUser: (params) => dispatch(editUser(params)),
	openAddUserGroupModal: () => dispatch(openAddUserGroupModal()),
	closeAddUserGroupModal: () => dispatch(closeAddUserGroupModal()),
	editUserInit: () => dispatch(editUserInit()),
	deleteUserProfile: (params) => dispatch(deleteUserProfile(params)),
	getLanguages: () => dispatch(getLanguages())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(EditUserProfileDesktop);
