import { createAction } from 'redux-actions';

import { getChartData as getChartDataService } from '../../../services/TicketingService';

import {
	GET_TICKETING_CHART_DATA_REQUEST,
	GET_TICKETING_CHART_DATA_SUCCESS,
	GET_TICKETING_CHART_DATA_FAIL
} from './constants';

const request = createAction(GET_TICKETING_CHART_DATA_REQUEST);
const success = createAction(GET_TICKETING_CHART_DATA_SUCCESS);
const fail = createAction(GET_TICKETING_CHART_DATA_FAIL);

const getTicketingChartData = (req) => async (dispatch) => {
	const requestObject = {
		additionalParams: { columnsToSelect: req.id.column },
		aggregationParams: [
			{ function: 'COUNT', column: req.id.value, as: 'value' }
		]
	};

	dispatch(request({ id: req.id.column }));
	getChartDataService(requestObject)
		.then((response) =>
			dispatch(success({ data: response, id: req.id.column })))
		.catch((error) => dispatch(fail({ error, id: req.id.column })));
};

export default getTicketingChartData;
