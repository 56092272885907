import React from 'react';
import Select from '../../../../../../lib/DigitalComponents/DropdownSelectNew';

function RatePlanSelect(props) {
  return (
		<Select
			data-spec="rate-plan-change"
			noBorder
			{...props}
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
		/>
	);
}

export default RatePlanSelect;
