import { createAction } from 'redux-actions';
import { patchEndpoint } from '../../services/EndpointsService';

import {
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_REQUEST,
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_SUCCESS,
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_FAIL,
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_STARTED,
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_ENDED
} from './constants';
import {
	sameDateFormatter,
	startOfDayFormatter
} from '../../../../utils/constants';

const updateBulkSingleEndpointStatusRequest = createAction(
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_REQUEST
);
const updateBulkSingleEndpointStatusSuccess = createAction(
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_SUCCESS
);
const updateBulkSingleEndpointStatusFail = createAction(
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_FAIL
);
export const updateBulkSingleEndpointStatusStarted = createAction(
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_STARTED
);
export const updateBulkSingleEndpointStatusEnded = createAction(
	UPDATE_BULK_SINGLE_ENDPOINT_STATUS_ENDED
);

export const updateBulkSingleEndpoint = (data, count = 0) => async (
	dispatch
) => {
	if (count >= data.originalSelectedSims.length) {
		dispatch(updateBulkSingleEndpointStatusEnded());
	} else {
		const updateData = {
			id: data.originalSelectedSims[count].endpointName,
			data: {
				targetState: data.newSimStatus,
				startDate: sameDateFormatter(data.scheduledDate)
					? undefined
					: startOfDayFormatter(data.scheduledDate),
				userNotes: data.userNote
			}
		};
		dispatch(updateBulkSingleEndpointStatusRequest(updateData));
		setTimeout(() => {
			patchEndpoint(updateData)
				.then(() => {
					dispatch(updateBulkSingleEndpointStatusSuccess(updateData));
					dispatch(updateBulkSingleEndpoint(data, count + 1));
				})
				.catch(() => {
					dispatch(updateBulkSingleEndpointStatusFail(updateData));
					dispatch(updateBulkSingleEndpoint(data, count + 1));
				});
		}, 1000);
	}
};
