import React, { Component } from 'react';
import {
	object,
	func,
	bool,
	objectOf,
	string,
	shape,
	number,
	oneOfType,
	array
} from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import {
	TicketHistoryTableHeader as tableHeader,
	getFullDateTime
} from '../../../../utils/constants';

import styles from './TicketDetails.scss';

const TicketDetails = () => (WrappedComponent) => {
	class TicketDetailsComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				selectedHistory: {}
			};
		}

		componentDidMount() {
			const {
				getCategories,
				getSeverities,
				getPriorities,
				getCountries,
				getStates,
				match: {
					params: { id }
				}
			} = this.props;
      this.getTicketDetails(id);
      this.getHistory(id);
			getCategories();
			getSeverities();
			getPriorities({
				additionalParams: {
					dataSort: 'id desc'
				}
			});
      getCountries();
			getStates({
				additionalParams: {
					dataSort: 'name ASC'
				}
      });
      this.getConfirmation();
		}

		componentDidUpdate(prevProps) {
			const { ticketDetails, editTicketSuccess, resetFiles } = this.props;
			if (ticketDetails) {
				if (
					ticketDetails.ticketCategoryId !==
					(prevProps &&
						prevProps.ticketDetails &&
						prevProps.ticketDetails.ticketCategoryId)
				) {
					this.getFirstSubCategories(ticketDetails.ticketCategoryId);
				}
				if (
					ticketDetails.ticketFirstSubCategoryId !==
					(prevProps &&
						prevProps.ticketDetails &&
						prevProps.ticketDetails.ticketFirstSubCategoryId)
				) {
					this.getSecondSubCategories(ticketDetails.ticketFirstSubCategoryId);
				}
			}
			if (
				editTicketSuccess !== prevProps.editTicketSuccess &&
				editTicketSuccess
			) {
				const {
					match: {
						params: { id }
					}
				} = this.props;
				resetFiles();
				this.getTicketDetails(id);
				this.getHistory(id);
			}
		}

		componentWillUnmount() {
			const { editTicketInit } = this.props;
			editTicketInit();
		}

		getTicketDetails = (id) => {
			const { getTicketDetails } = this.props;
			const params = {
				additionalParams: {
					include: [
						'TicketSeverity',
						'TicketCategory',
						'TicketFirstSubCategory',
						'TicketSecondSubCategory',
						'TicketThirdSubCategory',
						'TicketUser',
						'TicketStatus',
						'TicketType',
						'EndpointIssue',
						'TicketHistory',
						'TicketPriority',
						'TicketFile',
						'EndpointIssue.IssueCountry',
						'EndpointIssue.IssueState'
					]
				}
			};
			getTicketDetails({ id, params });
		};

		pushBack = () => {
			const { pushBack } = this.props;
			pushBack();
		};

		renderCreatedAt = (val) => (
				<div data-spec="created-at" className={styles.column}>
					{getFullDateTime(val.externalCreatedAt)}
				</div>
			);

		renderUser = (val) => (
				<div data-spec="user" className={styles.column}>
					{val.user}
				</div>
			);

		renderNotes = (val, openNoteModal) => (
				<div
					data-spec="note"
					className={styles.notes_column}
					onClick={() => {
						openNoteModal();
						this.setState({ selectedHistory: val });
					}}
				>
					<pre>
						{val.notes}
					</pre>
				</div>
			);

		renderExternalLastUpdatedDate = (val) => (
				<div data-spec="last-updated-date" className={styles.column}>
					{val.externalLastUpdatedDate || '-'}
				</div>
			);

		getFirstSubCategories = (item) => {
			const { getFirstSubCategories } = this.props;
			getFirstSubCategories({
				searchParams: [
					{ propValue: item, prop: 'ticketCategoryId', operator: '=' }
				],
				additionalParams: {
					dataSort: 'name ASC'
				}
			});
		};

		getSecondSubCategories = (item) => {
			const { getSecondSubCategories } = this.props;
			getSecondSubCategories({
				searchParams: [
					{
						propValue: item,
						prop: 'ticketFirstSubCategoryId',
						operator: '='
					}
				],
				additionalParams: {
					dataSort: 'name ASC'
				}
			});
		};

		getThirdSubCategories = (item) => {
			const { getThirdSubCategories } = this.props;
			getThirdSubCategories({
				searchParams: [
					{
						propValue: item,
						prop: 'ticketSecondSubCategoryId',
						operator: '='
					}
				],
				additionalParams: {
					dataSort: 'name ASC'
				}
			});
		};

		getConfirmation = () => {
			const { getConfirmation } = this.props;
			getConfirmation({
				additionalParams: {
					dataSort: 'name ASC'
				}
			});
    };

    getHistory = (id) => {
      const { getHistory } = this.props;
      getHistory({
				id,
				params: {
					additionalParams: {
						dataSort: 'externalCreatedAt desc'
					}
				}
			});
    }

		getOptions = (target, openNoteModal) => {
			const isDesktop = target === 'desktop';
			const isMobile = target === 'mobile';

			const options = {};

			options.header = tableHeader;
			if (isDesktop) {
				options.customComponents = {
					externalCreatedAt: {
						type: 'custom',
						component: (val) => this.renderCreatedAt(val)
					},
					user: {
						type: 'custom',
						component: (val) => this.renderUser(val)
					},
					notes: {
						type: 'custom',
						component: (val) => this.renderNotes(val, openNoteModal)
					},
					externalLastUpdatedDate: {
						type: 'custom',
						component: (val) => this.renderExternalLastUpdatedDate(val)
					}
				};
			}

			if (isMobile) {
				options.customComponents = {
					externalCreatedAt: {
						type: 'custom',
						component: (val) => this.renderCreatedAt(val)
					}
				};
			}

			return options;
		};

		render() {
			const {
				ticketDetailsRequest,
				ticketDetailsFail,
				ticketDetails,
				editTicketRequest,
				historyRequest,
				messages,
				user
			} = this.props;
      const { selectedHistory } = this.state;

			return (
				<div data-spec="ticketing">
					<PageTitle
						title={
							<FormattedMessage
								id="TICKETING.TICKET_DETAILS"
								defaultMessage="Ticket Details"
							/>
						}
						pushBack={this.pushBack}
						messages={messages}
					/>
					{(ticketDetailsRequest || editTicketRequest || historyRequest) && (
						<Loading data-spec="loading" />
					)}
					{ticketDetailsFail && null}
					{!ticketDetailsRequest && !editTicketRequest && ticketDetails && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
							getDateTimeFormat={getFullDateTime}
							initialValues={{
								...ticketDetails,
								note: ''
							}}
							selectedHistory={selectedHistory}
							getFirstSubCategories={this.getFirstSubCategories}
							getSecondSubCategories={this.getSecondSubCategories}
							getThirdSubCategories={this.getThirdSubCategories}
							user={user}
						/>
					)}
				</div>
			);
		}
	}

	TicketDetailsComponent.propTypes = {
		messages: objectOf(string),
		match: shape(),
		pushBack: func,
		getTicketDetails: func,
		ticketDetailsRequest: bool,
		ticketDetailsFail: bool,
		ticketDetails: shape({
			ticketCategoryId: number,
			ticketFirstSubCategoryId: number
		}),
		getFirstSubCategories: func,
		getSecondSubCategories: func,
		getThirdSubCategories: func,
		getConfirmation: func,
		getCategories: func,
		getSeverities: func,
		getPriorities: func,
		getCountries: func,
    getStates: func,
    historyRequest: bool,
    getHistory: func,
		editTicketRequest: bool,
		editTicketSuccess: shape(),
		editTicketInit: func,
		user: objectOf(oneOfType([array, object, string, bool])),
		resetFiles: func
	};
	return TicketDetailsComponent;
};

export default TicketDetails;
