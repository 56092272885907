import globalAction from '../../../../redux/global/globalAction';

import { GET_ACTIVATIONS, GET_ACTIVATIONS_FILTERS } from './constants';

import {
	getActivations as getActivationsService,
	getActivationsFilters as getActivationsFiltersService
} from '../../services/ActivationsService';

/** ************ get activations ************* */
export const getActivations = (id, params = {}) =>
	globalAction(GET_ACTIVATIONS, getActivationsService, { id, params });

/** ************ get activations filters ************* */
export const getActivationsFilters = (id, params = {}) =>
	globalAction(GET_ACTIVATIONS_FILTERS, getActivationsFiltersService, {
		id,
		params
	});
