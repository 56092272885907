import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../RatePlans';

const Mobile = (props) => {
	const { ratePlans, getOptions, messages } = props;
	return (
		<div data-spec="rate-plans-mobile">
			<Table
				data-spec="rate-plans-table-mobile"
				data={ratePlans.resultList}
				options={getOptions('mobile')}
				messages={messages}
				mobile
			/>
		</div>
	);
};

const { object, func } = PropTypes;

Mobile.propTypes = {
	ratePlans: object,
	getOptions: func,
	messages: object
};

export default hoc()(Mobile);
