import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import TicketingDesktop from './Desktop';
import TicketingMobile from './Mobile';

import { getTickets } from '../../redux/tickets/actions';
import {
	getTicketsRequest,
	getTicketsFail,
	getTicketsSuccess
} from '../../redux/tickets/selectors';
import {
	getSimpleSearchParameter,
	getIsSimpleSearchActive
} from '../../../Shared/redux/simpleSearch/selectors';
import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';
import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';
import {
	getTicketingFilters,
	getTicketingFiltersInit
} from '../../redux/getTicketingFilters/actions';

import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';
import getTicketingChartData from '../../redux/charts/getTicketingChartData/actions';
import {
	getTicketingChartDataSuccess,
	getTicketingChartDataRequest
} from '../../redux/charts/getTicketingChartData/selectors';
import {
	getSeveritiesAndStatuses,
	getSeveritiesAndStatusesInit
} from '../../redux/charts/getSeveritiesAndStatuses/actions';
import {
	getTicketingSeveritiesAndStatusesSuccess,
	getTicketingSeveritiesAndStatusesRequest
} from '../../redux/charts/getSeveritiesAndStatuses/selectors';
import { exportCsvFile } from '../../redux/exportCsv/actions';
import {
	exportCsvRequest,
	exportCsvSuccess
} from '../../redux/exportCsv/selectors';
import { getUserSettings } from '../../../../redux/user/getUserData/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import { notificationMessages } from '../../utils/constants';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import {
	setDataOffset,
	setSelectedPage,
	setPaginationInit
} from '../../../Shared/redux/pagination/actions';
import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

const mapStateToProps = (state) => ({
	ticketsRequest: getTicketsRequest(state),
	ticketsFail: getTicketsFail(state),
	tickets: getTicketsSuccess(state),
	userSettings: getUserSettings(state, 6),
	simpleSearchParameter: getSimpleSearchParameter(state),
	isSimpleSearchActive: getIsSimpleSearchActive(state),
	filterModalOpened: isFilterModalOpen(state),
	chartData: (id) => getTicketingChartDataSuccess(state, id),
	isGetTicketingChartDataPending: (id) =>
		getTicketingChartDataRequest(state, id),
	severitiesAndStatuses: getTicketingSeveritiesAndStatusesSuccess(state),
	getTicketingSeveritiesAndStatusesPending: getTicketingSeveritiesAndStatusesRequest(
		state
	),
	isExportCsvRequest: exportCsvRequest(state),
	isExportCsvSuccess: exportCsvSuccess(state),
	filterData: filterDataSuccess(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
	searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	getTickets: (params) => dispatch(getTickets(params)),
	pushBack: () => dispatch(localizedRoute('/support')),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	getTicketingFilters: () => dispatch(getTicketingFilters()),
	getTicketingFiltersInit: () => dispatch(getTicketingFiltersInit()),
	goToTicketDetails: (id) => dispatch(localizedRoute(`/support/ticketing/ticket/${id}`)),
	addNewTicket: () => dispatch(localizedRoute('/support/ticketing/new-ticket')),
	getTicketingChartData: (requestBy) =>
		dispatch(getTicketingChartData(requestBy)),
	getSeveritiesAndStatuses: () => dispatch(getSeveritiesAndStatuses()),
	getSeveritiesAndStatusesInit: () => dispatch(getSeveritiesAndStatusesInit()),
	createUserSettings: (newValue) => dispatch(createUserSettings(newValue)),
	patchUserSetting: (newValue, id) =>
		dispatch(updateUserSettings(newValue, id, notificationMessages)),
	getExportCsv: (data) => dispatch(exportCsvFile(data)),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(TicketingDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(TicketingMobile);
