import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { format } from 'date-fns';
import { isEqual } from 'lodash';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import Button from '../../../../../lib/DigitalComponents/Button';

import styles from './Steps.scss';

import {
	getDateFormat,
	sameDateFormatter,
	startOfDayFormatter
} from '../../../../../utils/constants';
import { updateRatePlan as updateRatePlanAction } from '../../../../SingleEndpoint/redux/actions';

class StepFirst extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
		const { context } = this.props;

		this.onDefaultDateChange(new Date());
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context } = this.props;
		const { selectedDay } = this.state;
		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

	updateEndpointRatePlan = (val, endpoint, startDate) => {
		if (val.value === endpoint.status) return;
    const { updateRatePlan } = this.props;
    const isSameDate = sameDateFormatter(startDate);
		const updateData = {
			id: endpoint.endpointName,
			data: {
				ratePlanId: val.value,
				startDate: isSameDate ? undefined : startOfDayFormatter(startDate)
			}
		};
		updateRatePlan(updateData);
	};

	handleOnClick = () => {
		const { newRatePlan, endpoint, onNext } = this.props;
		const { selectedDay } = this.state;

		this.updateEndpointRatePlan(newRatePlan, endpoint, selectedDay);
		onNext({
			scheduleDate: format(selectedDay, 'MM/dd/yyyy')
		});
	};

	setFooter = (context) => {
		const { onCancel } = this.props;
		const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					onClick={onCancel}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					onClick={this.handleOnClick}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
					variant="primary"
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="ENDPOINTS.SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule change"
							/>
						) : (
							<FormattedMessage
								id="ENDPOINTS.INITIATE_CHANGE"
								defaultMessage="Initiate change"
							/>
						)
					}
					dataSpec="schedule-initiate-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	onDefaultDateChange = (date) => {
		this.setState({
			selectedDay: date
		});
	};

	render() {
		const { selectedDay } = this.state;

		return (
			<div data-spec="first-step">
				<div className={styles.custom}>
					<div className="confirm-content">
						<DSDatePicker
							label={
								<FormattedMessage id="SCHEDULE_DATE" defaultMessage="Date" />
							}
							value={selectedDay}
							onDayChange={this.onDefaultDateChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const { func, object } = PropTypes;

StepFirst.propTypes = {
	onCancel: func,
	onNext: func,
	updateRatePlan: func,
	newRatePlan: object,
	endpoint: object,
	context: object
};

const mapDispatchToProps = (dispatch) => ({
	updateRatePlan: (data) => dispatch(updateRatePlanAction(data))
});

export default connect(null, mapDispatchToProps)(StepFirst);
