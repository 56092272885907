import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getEndpoints = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/endpoints?${qs.stringify(
			params.params
		)}`
	);

export const getEndpointsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/endpoints/filters?${qs.stringify(
			params.params
		)}`
	);
