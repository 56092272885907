export default {
	'SECURITY_FEATURES.TITLE': 'Security Features',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'IMEI Whitelist',
	'SECURITY_FEATURES.SMS_WHITELIST': 'SMS Whitelist',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'VOICE Whitelist',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'IP,URL Port Blacklist',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'IP Range Whitelist',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE':
		'No Security Features Available',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE':
		'There are No Security Features for this Table to show you right now',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'M2M ACCOUNT NAME',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'DATE SUBMITTED',
	'SECURITY_FEATURES.STATUS': 'STATUS',
	'SECURITY_FEATURES.PENDING': 'Pending',
	'SECURITY_FEATURES.FAILED': 'Failed',
	'SECURITY_FEATURES.SUCCESSFUL': 'Successful',
	'SECURITY_FEATURES.CCIP': 'Control Center Integration Program (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Vivo Control Center',
	'SECURITY_FEATURES.DCP': 'Device Connection Platform (DCP)',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'AT&T Control Center (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SEARCH MENU
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOICE',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, PORT',
	'SECURITY_FEATURES.MENU_5': 'IP RANGE',
	'SECURITY_FEATURES.ADD_1': 'Add new IMEI to the Whitelist',
	'SECURITY_FEATURES.ADD_2': 'Add new SMS to the Whitelist',
	'SECURITY_FEATURES.ADD_3': 'Add new VOICE to the Whitelist',
	'SECURITY_FEATURES.ADD_4': ' Add new IP, URL, PORT to the Blacklist',
	'SECURITY_FEATURES.ADD_5': 'Add new IP Range to the Whitelist',
	'SECURITY_FEATURES.EXPORT': 'Export',
	'SECURITY_FEATURES.EDIT': 'Edit',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'Select M2M Platform',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Batch Upload',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Upload File',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Confirm Changes',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. System Confirmation',
	'SECURITY_FEATURES.CANCEL': 'Cancel',
	'SECURITY_FEATURES.CONTINUE': 'Continue',
	'SECURITY_FEATURES.DONE': 'Done',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Upload file',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE':
		'Upload files here for selected batch action:',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Drag file or click to upload',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Confirm batch file upload',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Scheduled Action on',
	'SECURITY_FEATURES.LOADER_MESSAGE':
		'Please wait. This may take a few minutes, depending on the file size.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE':
		'Batch files successfully received!',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE':
		'Changes are pending at the system completes your updates',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'Batch file system error!',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE':
		'There was a problem processing your updates. Please try again later.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Download template',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED':
		'Files must be submitted with .csv extension.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD':
		'Maximum Security Features per upload is '
};
