import { createAction } from 'redux-actions';

import { deleteUserSettings as service } from '../../../services/UserService';
import { getLocale } from '../../../utils/constants';
import { DELETE_USER_SETTINGS, FETCH_USER } from '../constants';
import { showNotification as show } from '../../../components/Shared/redux/notifications/actions';

const request = createAction(`${DELETE_USER_SETTINGS}_REQUEST`);
const success = createAction(`${DELETE_USER_SETTINGS}_SUCCESS`);
const fail = createAction(`${DELETE_USER_SETTINGS}_ERROR`);
const deleteSuccess = createAction(`${FETCH_USER}_DELETE`);

const deleteUserSettings = (id, message) => async (dispatch) => {
  const locale = getLocale();
	const { default: messages } = await import(
		`../../../localizations/${locale}`
	);

	dispatch(request());
	service(id)
		.then((response) => {
			dispatch(success(response.data));
			dispatch(deleteSuccess({ id }));
			message &&
				dispatch(
					show({
						id: 'table-preferences-updated-success',
						message: message.success
							? [messages[message.success]]
							: [messages.ACTION_COMPLETED_SUCCESSFULLY],
						type: 'success',
						notificationType: 'toaster',
						close: true,
						duration: 5000
					})
				);
		})
		.catch((error) => {
			dispatch(fail(error));
			message &&
				dispatch(
					show({
						id: 'table-preferences-updated-fail',
						message: message.error
							? [messages[message.error]]
							: [messages.ACTION_COULD_NOT_BE_COMPLETED],
						type: 'error',
						notificationType: 'toaster',
						close: false
					})
				);
		});
};

export default deleteUserSettings;
