import { connect } from 'react-redux';
import ChangeStateModal from './ChangeStateModal';

import {
	setRuleReceived,
	setRuleImplemented,
	setRuleConflicted,
} from '../../redux/actions';

const mapDispatchToProps = (dispatch) => ({
	setRuleReceived: (data) => dispatch(setRuleReceived(data)),
	setRuleImplemented: (data) => dispatch(setRuleImplemented(data)),
	setRuleConflicted: (data) => dispatch(setRuleConflicted(data)),
});

export default connect(null, mapDispatchToProps)(ChangeStateModal);
