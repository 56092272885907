import { Status } from '../../../../utils/constants';

export const getRatePlanZonesRequest = (state) =>
	state.analyticsInvoices.tables.ratePlanZones.getRatePlanZones.status ===
	Status.PENDING;
export const getRatePlanZonesFail = (state) =>
	state.analyticsInvoices.tables.ratePlanZones.getRatePlanZones.status ===
	Status.FAIL;
export const getRatePlanZonesSuccess = (state) =>
	state.analyticsInvoices.tables.ratePlanZones.getRatePlanZones.data;

export const getRatePlanZonesFiltersRequest = (state) =>
	state.analyticsInvoices.tables.ratePlanZones.getRatePlanZonesFilters
		.status === Status.PENDING;
export const getRatePlanZonesFiltersFail = (state) =>
	state.analyticsInvoices.tables.ratePlanZones.getRatePlanZonesFilters
		.status === Status.FAIL;
export const getRatePlanZonesFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.ratePlanZones.getRatePlanZonesFilters.data;
