export const ADD_NETWORK_ENTITLEMENT = 'ADD_NETWORK_ENTITLEMENT';
export const EDIT_NETWORK_ENTITLEMENT = 'EDIT_NETWORK_ENTITLEMENT';
export const GET_NETWORK_ENTITLEMENT = 'GET_NETWORK_ENTITLEMENT';
export const GET_NETWORK_ENTITLEMENT_APNS = 'GET_NETWORK_ENTITLEMENT_APNS';
export const GET_NETWORK_ENTITLEMENTS = 'GET_NETWORK_ENTITLEMENTS';
export const GET_NETWORK_ENTITLEMENT_APNS_REQUEST =
	'GET_NETWORK_ENTITLEMENT_APNS_REQUEST';
export const GET_NETWORK_ENTITLEMENT_APNS_SUCCESS =
	'GET_NETWORK_ENTITLEMENT_APNS_SUCCESS';
export const GET_NETWORK_ENTITLEMENT_APNS_FAIL =
	'GET_NETWORK_ENTITLEMENT_APNS_FAIL';

export const ADD_NETWORK_ENTITLEMENTS_MODAL = 'ADD_NETWORK_ENTITLEMENTS_MODAL';
export const GET_PARENT_NETWORK_ENTITLEMENTS =
	'GET_PARENT_NETWORK_ENTITLEMENTS';
export const ADD_SUB_ACCOUNT_NETWORK_ENTITLEMENTS =
	'ADD_SUB_ACCOUNT_NETWORK_ENTITLEMENTS';
export const SHOW_ASSOCIATED_APNS = 'SHOW_ASSOCIATED_APNS';
