import SupportView from '../views/SupportView';
import UserGuideView from '../views/UserGuideView';
import FaqView from '../views/FaqView';
import ReleaseNotesView from '../../ReleaseNotes/views/ReleaseNotesView';
import { userInStandardMode } from '../../../utils/AuthSelector';

const supportRoutes = [
	{
		path: '/support',
		exact: true,
		Component: userInStandardMode(SupportView)
	},
	{
		path: '/support/user-guide',
		exact: true,
		Component: userInStandardMode(UserGuideView)
	},
	{
		path: '/support/faq',
		exact: true,
		Component: userInStandardMode(FaqView)
	},
	{
		path: '/support/release-notes',
		exact: true,
		Component: userInStandardMode(ReleaseNotesView),
		customProps: { backUrl: '/support', published: true }
	}
];

export default supportRoutes;
