import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TableType = {
	ENDPOINTS: 1,
	PREPAID_SUBSCRIPTIONS: 2,
	ADDONS: 3,
	RATE_PLANS: 4,
	RATE_PLAN_ZONES: 5,
	BILLING_GROUPS: 6,
	DATA_DETAILS: 7,
	SMS_DETAILS: 8,
	ACTIVATIONS: 9,
	DISCOUNTS: 10,
	TAX: 11,
	SURCHARGES: 12,
	TECH_TYPES: 13,
  OTHER_CHARGES: 14
};

export const InvoiceDropdownOptions = [
	{
		value: TableType.ENDPOINTS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ENDPOINTS"
				defaultMessage="Endpoints"
			/>
		)
	},
	{
		value: TableType.PREPAID_SUBSCRIPTIONS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS"
				defaultMessage="Prepaid Subscription"
			/>
		)
	},
	{
		value: TableType.RATE_PLANS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.RATE_PLANS"
				defaultMessage="Rate Plans"
			/>
		)
	},
	{
		value: TableType.RATE_PLAN_ZONES,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.RATE_PLAN_ZONES"
				defaultMessage="Rate Plan Zones"
			/>
		)
	},
	{
		value: TableType.BILLING_GROUPS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.BILLING_GROUPS"
				defaultMessage="Billing Groups"
			/>
		)
	},
	{
		value: TableType.DATA_DETAILS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.DATA_DETAILS"
				defaultMessage="Data Details"
			/>
		)
	},
	{
		value: TableType.SMS_DETAILS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.SMS_DETAILS"
				defaultMessage="SMS Details"
			/>
		)
	},
	{
		value: TableType.ACTIVATIONS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.ACTIVATIONS"
				defaultMessage="Activations"
			/>
		)
	},
	{
		value: TableType.DISCOUNTS,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.DISCOUNTS"
				defaultMessage="Discounts"
			/>
		)
	},
	// {
	// 	value: TableType.SURCHARGES,
	// 	label: (
	// 		<FormattedMessage
	// 			id="ANALYTICS_INVOICES.SURCHARGES"
	// 			defaultMessage="Surcharges"
	// 		/>
	// 	)
	// },
	// {
	// 	value: TableType.TECH_TYPES,
	// 	label: (
	// 		<FormattedMessage
	// 			id="ANALYTICS_INVOICES.TECH_TYPES"
	// 			defaultMessage="Tech Types"
	// 		/>
	// 	)
	// },
	{
		value: TableType.OTHER_CHARGES,
		label: (
			<FormattedMessage
				id="ANALYTICS_INVOICES.OTHER_CHARGES"
				defaultMessage="Other Charges"
			/>
		)
  }
];
