import React from 'react';
import { string, objectOf, func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button/Button';

import styles from './NotesModal.scss';

const NotesModal = ({ show, onClose, notes, title }) => (
	<Modal data-spec="ticketing-notes-modal" show={show} onClose={onClose}>
		<FormattedMessage
			id={title && title.id ? title.id : 'TICKETING.VIEW_NOTE'}
			defaultMessage={
				title && title.defaultMessage ? title.defaultMessage : 'View Note'
			}
		>
			{(formattedValue) => (
				<ModalHeader title={formattedValue} closeModal={onClose} />
			)}
		</FormattedMessage>
		<ModalBody className={styles.modal_body}>
			<div data-spec="body-note" className={styles.notes_body}>
				<pre className={styles.modal_note}>{notes}</pre>
			</div>
		</ModalBody>
		<ModalFooter>
			<Button
				dataSpec={'close-note'}
				variant="link"
				label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				onClick={onClose}
				type="button"
			/>
		</ModalFooter>
	</Modal>
);

NotesModal.propTypes = {
	onClose: func,
	show: bool,
	notes: string,
	title: objectOf(string),
};

NotesModal.defaultProps = {
	show: false,
	notes: '',
	title: {},
};

export default NotesModal;
