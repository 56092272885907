import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { UserAllowedToAccess } from '../../../../utils/AuthSelector';

import PageTitle from '../../../Shared/views/PageTitleView';
import NotificationsList from '../../views/NotificationsListView';

import styles from './Support.scss';

const FAQ = ImageSelector(CurrentContext.theme, 'svgs/lamp.svg');
const GUIDE = ImageSelector(CurrentContext.theme, 'svgs/user-guide.svg');
const TICKETING = ImageSelector(CurrentContext.theme, 'svgs/ticketing.svg');
const RELEASENOTES = ImageSelector(
	CurrentContext.theme,
	'svgs/release-notes.svg'
);
const AddTicketRWComponent = UserAllowedToAccess([
	'mnc.ticketing_rw',
	'mnc.ticketing_ro'
]);

const ReleaseNotesRWComponent = UserAllowedToAccess([
	'system.releasenotes_rw',
	'system.releasenotes_ro'
]);

class Support extends PureComponent {
	goToFaq = () => {
		const { goToFaq } = this.props;
		goToFaq('/support/faq');
	};

	goToUserGuide = () => {
		const { goToUserGuide } = this.props;
		goToUserGuide('/support/user-guide');
	};

	goToReleaseNotes = () => {
		const { goToReleaseNotes } = this.props;
		goToReleaseNotes('/support/release-notes');
	};

	render() {
		const { messages, goToTicketing } = this.props;
		return (
			<div data-spec="support">
				<PageTitle
					title={
						<FormattedMessage id="SUPPORT.SUPPORT" defaultMessage="Support" />
					}
					messages={messages}
				/>
				<div className={styles.content_wrapper}>
					<div className={styles.cards_wrapper}>
						<div
							className={styles.card}
							onClick={this.goToFaq}
							data-spec="faq-support"
						>
							<div className={styles.icon_holder}>
								<FAQ className={styles.icon} />
							</div>
							<div className={styles.title}>
								<FormattedMessage id="SUPPORT.FAQ" defaultMessage="FAQ" />
							</div>
							<div className={styles.intro}>
								<FormattedMessage
									id="SUPPORT.FAQ_SUPPORT_INFO"
									defaultMessage="Find quick answers to frequently asked questions by our users."
								/>
							</div>
						</div>

						<div
							className={styles.card}
							onClick={this.goToUserGuide}
							data-spec="user-guide-support"
						>
							<div className={styles.icon_holder}>
								<GUIDE className={styles.icon} />
							</div>
							<div className={styles.title}>
								<FormattedMessage
									id="SUPPORT.USER_GUIDE"
									defaultMessage="User guide"
								/>
							</div>
							<div className={styles.intro}>
								<FormattedMessage
									id="SUPPORT.USER_GUIDE_SUPPORT_INFO"
									defaultMessage="Read detailed step-by-step instructions for portal functions. The guide is tailored to your account type."
								/>
							</div>
						</div>
						<AddTicketRWComponent>
							<div
								className={styles.card}
								onClick={goToTicketing}
								data-spec="ticketing-support"
							>
								<div className={styles.icon_holder}>
									<TICKETING className={styles.icon} />
								</div>
								<div className={styles.title}>
									<FormattedMessage
										id="SUPPORT.TICKETING"
										defaultMessage="Ticketing"
									/>
								</div>
								<div className={styles.intro}>
									<FormattedMessage
										id="SUPPORT.TICKETING_SUPPORT_INFO"
										defaultMessage="If you experience system outages or service issues with your endpoints and diagnostics tests do not provide the resolution you can create a trouble ticket with our Ops team. You can ask billing and service related questions as well."
									/>
								</div>
							</div>
						</AddTicketRWComponent>
						<ReleaseNotesRWComponent>
							<div
								className={styles.card}
								onClick={this.goToReleaseNotes}
								data-spec="release-notes-support"
							>
								<div className={styles.icon_holder}>
									<RELEASENOTES className={styles.icon} />
								</div>
								<div className={styles.title}>
									<FormattedMessage
										id="RELEASE_NOTES.RELEASE_NOTES"
										defaultMessage="Release Notes"
									/>
								</div>
								<div className={styles.intro}>
									<FormattedMessage
										id="RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO"
										defaultMessage="IoT Console is updated frequently."
									/>
								</div>
							</div>
						</ReleaseNotesRWComponent>
					</div>
				</div>
				<NotificationsList />
			</div>
		);
	}
}

const { objectOf, func, string } = PropTypes;

Support.propTypes = {
	messages: objectOf(string),
	goToTicketing: func,
	goToUserGuide: func,
	goToFaq: func,
	goToReleaseNotes: func
};

export default Support;
