import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import SimOrdering from './SimOrdering';

import {
	getAccountSuccess,
	getAccountRequest,
	getAccountFail
} from '../../redux/accounts/selectors';

import { getAccount, getAccountInit } from '../../redux/accounts/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	account: getAccountSuccess(state),
	accountRequest: getAccountRequest(state),
	accountFail: getAccountFail(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	pushBack: (url) => dispatch(localizedRoute(url)),
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SimOrdering);
