export default {
	'ERRORS.ERROR': 'Errore',
	'ERRORS.FORBIDDEN_ACCESS': 'Accesso proibito',
	'ERRORS.RETURN_TO_HOME_PAGE': 'RITORNA ALLA HOME PAGE',
	'ERRORS.PAGE_NOT_FOUND': 'Impossibile trovare la pagina richiesta.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Errore interno server',
	'ERRORS.REQUEST_TIMED_OUT': 'Timeout della richiesta',
	'ERRORS.LOGOUT': 'Logout',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': 'Sembra che non abbia l’autorizzazione per accedere a questa applicazione.',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': 'Se pensa che dovrebbe avere accesso, si rivolga al Suo amministratore aziendale.'
};
