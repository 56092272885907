import React from 'react';
import {
	func,
	object,
	string,
} from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

export default function Cards(WrapperComponent) {
	function CardsComponent({
		endpoint,
		goToTicketing,
		goToNewTicket,
		type,
		user,
	}) {
		const cards = [
			{
				id: 'CARRIER',
				defaultMessage: 'CARRIER',
				data: endpoint.networkOperator ? (
					<FormattedMessage
						id={endpoint.networkOperator}
						defaultMessage={endpoint.networkOperator}
					/>
				) : null,
				hide: !endpoint.networkOperator
			},
			{
				id: 'PLATFORM',
				defaultMessage: 'PLATFORM',
				data: endpoint.serviceProvider ? (
					<FormattedMessage
						id={endpoint.serviceProvider}
						defaultMessage={endpoint.serviceProvider}
					/>
				) : null,
				hide:
					!endpoint.serviceProvider ||
					!isUserAllowedToAccess(['mnc.endpoints.m2mplatform'], user)
			},
			{
				id: 'ENDPOINT_STATE',
				defaultMessage: 'ENDPOINT STATE',
				data: endpoint.status,
				hide:
					!endpoint.status ||
					!isUserAllowedToAccess(
						['mnc.endpoints.simstatus_ro', 'mnc.endpoints.simstatus_rw'],
						user
					)
			},
			{
				id: 'CONNECTION_STATUS',
				defaultMessage: 'CONNECTION STATUS',
				data:
					endpoint.dataSession === '1' ? (
						<FormattedMessage id="YES" defaultMessage="Yes" />
					) : (
						<FormattedMessage id="NO" defaultMessage="No" />
					),
				hide: endpoint.dataSession === undefined
			},
			{
				id: 'DATA_USAGE',
				defaultMessage: 'DATA USAGE',
				data: (
					<>
						<FormattedNumber
							value={endpoint.dataBytesCtd / (1024 * 1024)}
							maximumFractionDigits={4}
						/>
						<FormattedMessage id="MB" defaultMessage=" MB" />
					</>
				),
				hide: !endpoint.dataBytesCtd
			}
		];
		const goToNewTicketFunction = () => goToNewTicket(endpoint.iccid, type);

		return (
			<WrapperComponent
				data-spec="cards-wrapper"
				endpoint={endpoint}
				goToTicketing={goToTicketing}
				goToNewTicket={goToNewTicketFunction}
				cards={cards}
				user={user}
			/>
		);
	}

	CardsComponent.propTypes = {
		endpoint: object,
		goToTicketing: func,
		goToNewTicket: func,
		type: string,
		user: object,
	};

	CardsComponent.defaultProps = {
		endpoint: {},
		goToTicketing: undefined,
		goToNewTicket: undefined,
		type: '',
		user: {},
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	CardsComponent.displayName = `Cards(${wrappedComponentName})`;

	return CardsComponent;
}
