import { combineReducers } from 'redux';
import { findIndex } from 'lodash';
import globalReducer from '../../../../redux/global/globalReducer';
import globalModalReducer from '../../../../redux/global/globalModalReducer';

import {
	ADD_NOTIFICATION,
	EDIT_NOTIFICATION,
	GET_ACTIVE_NOTIFICATIONS,
	GET_NOTIFICATION,
	GET_NOTIFICATIONS,
	GET_NOTIFICATIONS_GROUPS,
	GET_NOTIFICATIONS_TYPES,
	REMOVE_NOTIFICATION,
	ADD_NOTIFICATION_MODAL,
	REMOVE_ACTIVE_NOTIFICATION
} from './constants';

export default combineReducers({
	addNotification: globalReducer(ADD_NOTIFICATION),
	editNotification: globalReducer(EDIT_NOTIFICATION),
	getActiveNotifications: globalReducer(GET_ACTIVE_NOTIFICATIONS, {
		[REMOVE_ACTIVE_NOTIFICATION]: (state, action) => {
			const index = findIndex(state.data.resultList, {
				notificationId: action.payload
			});
			return {
				...state,
				data: {
					resultList: [
						...state.data.resultList.slice(0, index),
						...state.data.resultList.slice(index + 1)
					]
				}
			};
		}
	}),
	getAllNotifications: globalReducer(GET_NOTIFICATIONS),
	getNotification: globalReducer(GET_NOTIFICATION),
	getNotifications: globalReducer(GET_NOTIFICATIONS),
	getNotificationsGroups: globalReducer(GET_NOTIFICATIONS_GROUPS),
	getNotificationsTypes: globalReducer(GET_NOTIFICATIONS_TYPES),
	removeNotification: globalReducer(REMOVE_NOTIFICATION),
	addNotificationModal: globalModalReducer(ADD_NOTIFICATION_MODAL)
});
