import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../lib/DigitalComponents/Table';

import hoc from '../List';

const Desktop = (props) => {
	const { securityFeatures, getOptions, messages } = props;
	return (
		<div data-spec="security-features-desktop">
			<Table
				data={securityFeatures}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { arrayOf, object, func, objectOf, string } = PropTypes;

Desktop.propTypes = {
	securityFeatures: arrayOf(object),
	getOptions: func,
	messages: objectOf(string)
};

export default hoc()(Desktop);
