import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import {
	ComponentsRender,
	isUserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import styles from './AddNotification.scss';
import { maxLength } from '../../../../utils/validators';

// modal stuff
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Radio from '../../../../lib/DigitalComponents/Radio';
// buttons for modal
import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';

import validate from './validate';

import { localizationHelper } from '../../../../utils/helperFunctions';

const NotificationRWComponent = ComponentsRender('mnc.alerting_rw');
const GlobalNotificationRWComponent = ComponentsRender(
	'system.globalnotifications_rw'
);

const maxLength510 = maxLength(510);

class AddNotification extends Component {
	componentWillUnmount() {
		const { getNotificationInit } = this.props;
		getNotificationInit();
	}

	renderButtons = () => {
		const {
			removeNotificationRequest,
			onCancel,
			invalid,
			pristine,
			onRemove,
			notification,
			submitting,
			addNotificationRequest,
		} = this.props;

		return (
			<div data-spec="buttons">
				<Button
					type="button"
					variant="link"
					onClick={onCancel}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					disabled={removeNotificationRequest}
				/>
				<Button
					variant="primary"
					label={
						<FormattedMessage id="SUPPORT.PUBLISH" defaultMessage="Publish" />
					}
					type="submit"
					disabled={
						removeNotificationRequest ||
						invalid ||
						pristine ||
						submitting ||
						addNotificationRequest
					}
				/>
				<Button
					onClick={() => onRemove(notification.id, notification.accountId)}
					variant="link"
					className={styles.clear_all}
					label={<FormattedMessage id="DELETE" defaultMessage="Delete" />}
					disabled={
						(notification && !notification.id) || removeNotificationRequest
					}
					type="button"
				/>
			</div>
		);
	};

	selectAll = (type) => {
		const { change, groups } = this.props;

		groups &&
			groups.resultList &&
			groups.resultList.forEach((value) => {
				change(`notificationGroupTypeId${value.id}`, type);
			});
	};

	render() {
		const {
			messages,
			types,
			groups,
			handleSubmit,
			getNotificationRequest,
			onCancel,
			notification,
			removeNotificationRequest,
			typesRequest,
			groupsRequest,
			addNotificationRequest,
			show,
			formData,
			change,
		} = this.props;

		const renderError = ({ meta: { touched, error } }) =>
			(touched && error ? (
				<div className="help-block">
					<i className="icon-badgealert" aria-hidden="true" />
					{error}
				</div>
			) : (
				false
			));

		return (
			<Modal
				data-spec="eula-modal"
				show={show}
				className={styles.modal}
				onClose={onCancel}
			>
				<ModalHeader
					messages={messages}
					title={
						!notification || (notification && !notification.id) ? (
							<FormattedMessage
								id="SUPPORT.CREATE_NEW_NOTIFICATION"
								defaultMessage="Create New Notification"
							/>
						) : (
							<FormattedMessage
								id="SUPPORT.EDIT_NOTIFICATION"
								defaultMessage="Edit Notification"
							/>
						)
					}
				/>
				<form onSubmit={handleSubmit}>
					{!(
						removeNotificationRequest ||
						getNotificationRequest ||
						typesRequest ||
						groupsRequest ||
						addNotificationRequest
					) &&
						types &&
						groups && (
							<ModalBody className={styles.modal_body}>
								{/* <h4 className={cx(styles.to_uppercase, styles.block_message)}>
								{messages.EDIT_FORM_TO_CREATE_NOTIFICATION ||
								'Edit form to create new notification.'}
							</h4> */}
								<NotificationRWComponent>
									{((notification && !notification.id) || !notification) && (
										<div>
											<div className={styles.radio_button_wrapper}>
												<GlobalNotificationRWComponent>
													<Field
														component={Radio}
														name="accountId"
														label={
															<FormattedMessage
																id="SUPPORT.GLOBAL_NOTIFICATION"
																Ca
																defaultMessage="Global Notification"
															/>
														}
														value="Global"
														type="radio"
													/>
												</GlobalNotificationRWComponent>
												<Field
													component={Radio}
													name="accountId"
													label={
														<FormattedMessage
															id="SUPPORT.COMPANY_RELATED_NOTIFICATION"
															defaultMessage="Company Related Notification"
														/>
													}
													value="Company"
													type="radio"
												/>
											</div>
											<Field name="accountId" component={renderError} />
										</div>
									)}
								</NotificationRWComponent>
								<div>
									<h4>
										<FormattedMessage
											id="SUPPORT.NOTIFICATION_TYPE"
											defaultMessage="Notification Type?"
										/>
									</h4>

									<FormattedMessage
										id="SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER"
										defaultMessage="Select Notification Placeholder"
									>
										{(formattedValue) => (
											<Field
												component={Select}
												name="notificationTypeId"
												placeholder={formattedValue}
												options={
													types &&
													types.resultList &&
													types.resultList
														.filter((item) => item.isVisible === true)
														.map((item) => {
															const localizationId = localizationHelper(
																item.name
															);
															return {
																value: item.id,
																label: (
																	<FormattedMessage
																		id={`SUPPORT.NOTIFICATION_${localizationId.toUpperCase()}`}
																		defaultMessage={item.name}
																	/>
																),
															};
														})
												}
												cleanValue
											/>
										)}
									</FormattedMessage>
								</div>
								{formData && formData.accountId !== 'Global' && (
									<>
										<div className={styles.notify_all}>
											<h4 className={styles.block_message}>
												<FormattedMessage
													id="SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY"
													defaultMessage="Choose Which User Groups to Notify:"
												/>
											</h4>
										</div>
										<div className={styles.checkbox_wrapper}>
											<Field
												type="checkbox"
												component={Checkbox}
												label={
													<FormattedMessage
														id="SUPPORT.ALL"
														defaultMessage="All"
													/>
												}
												name="All"
												onClick={
													formData.All
														? () => this.selectAll(false)
														: () => this.selectAll(true)
												}
											/>
										</div>

										{groups &&
											groups.resultList &&
											groups.resultList.map((value, index) => (
													<div
														className={styles.checkbox_wrapper}
														data-spec={index}
														key={value.id}
													>
														<Field
															type="checkbox"
															component={Checkbox}
															label={value.name}
															name={`notificationGroupTypeId${value.id}`}
															onClick={
																`formData.notificationGroupTypeId${value.id}` !==
																false
																	? () => change('All', false)
																	: undefined
															}
														/>
													</div>
												))}
										<Field
											name="notificationGroupTypeId"
											component={renderError}
										/>
									</>
								)}
								<h4 className={styles.block_message}>
									<FormattedMessage
										id="SUPPORT.CONTENT"
										defaultMessage="Content"
									/>
								</h4>
								<FormattedMessage
									id="SUPPORT.ADD_NOTIFICATION_CONTENT"
									defaultMessage="Add Notification Content"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											componentClass="textarea"
											placeholder={formattedValue}
											name="note"
											validate={[maxLength510]}
										/>
									)}
								</FormattedMessage>
							</ModalBody>
						)}
					{(removeNotificationRequest ||
						getNotificationRequest ||
						typesRequest ||
						groupsRequest ||
						addNotificationRequest) && <Loading data-spec="loader" />}
					<ModalFooter data-spec="modal-footer">
						{this.renderButtons()}
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { func, objectOf, bool, string, shape, array } = PropTypes;

const PrivacyModalProps = {
	onCancel: func,
	messages: objectOf(string),
	show: bool,
	types: shape({
		resultList: array,
	}),
	groups: shape({
		resultList: array,
	}),
	handleSubmit: func,
	notification: shape({
		resultList: array,
	}),
	onRemove: func,
	removeNotificationRequest: bool,
	getNotificationRequest: bool,
	invalid: bool,
	pristine: bool,
	typesRequest: bool,
	groupsRequest: bool,
	submitting: bool,
	addNotificationRequest: bool,
	getNotificationInit: func,
	formData: object,
	change: func,
};

AddNotification.propTypes = PrivacyModalProps;

let AddNotificationForm = reduxForm({
	form: 'AddNotificationForm',
	validate,
	enableReinitialize: true,
})(AddNotification);

AddNotificationForm = connect((state, props) => {
	const { groups } = props;
	const user = state.user.getUserData.data;
	let notification = {};
	const { getNotification } = state.support.notifications;
	if (getNotification.data) {
		notification = {
			id: getNotification.data.id,
			accountId: getNotification.data.accountId,
			note: getNotification.data.note,
			notificationTypeId: getNotification.data.notificationTypeId,
		};
		groups &&
			groups.resultList &&
			groups.resultList.forEach((value) => {
				notification[
					`notificationGroupTypeId${value.id}`
				] = !!state.support.notifications.getNotification.data.notificationGroupActives.find(
					(active) => active.notificationGroupTypeId === value.id
				);
			});
		return {
			initialValues: notification,
		};
	}

	if (
		!isUserAllowedToAccess(['system.globalnotifications_rw'], user) &&
		getNotification.data === null
	) {
		notification = {
			accountId: 'Company',
		};
		return { initialValues: notification };
	}
	return { notification };
})(AddNotificationForm);

export default AddNotificationForm;
