export default {
	'BATCH_HISTORY.LINK': 'Batchgeschiedenis',
	'BATCH_HISTORY.TITLE': 'Batchgeschiedenis',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Batchacties',
	'BATCH_HISTORY.BATCH_REPORTS': 'Batchrapporten',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'Geen batchacties beschikbaar',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': 'Er zijn momenteel geen batchacties voor deze tabel om te laten zien',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'Geen batchrapporten beschikbaar',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': 'Er zijn momenteel geen batchrapporten voor deze tabel om te laten zien',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Naam batchbestand',
	'BATCH_HISTORY.DATE_UPLOADED': 'Uploaddatum',
	'BATCH_HISTORY.STATUS': 'Status',
	'BATCH_HISTORY.REPORT_NAME': 'Rapport',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'Id M2M-account',
	'BATCH_HISTORY.DATE': 'Datum',
	'BATCH_HISTORY.M2M_PLATFORM': 'M2M-platform',
	'BATCH_HISTORY.FREQUENCY': 'Frequentie',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Verzonden',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Frequentie',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'Wordt uitgevoerd',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Voltooid',
  'BATCH_HISTORY.ERROR_STATUS': 'Fout',
  'BATCH_HISTORY.CANCELLED_STATUS': 'Geannuleerd',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': 'Het bestand is geüpload voor verwerking',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': 'Het bestand is geüpload en gepland voor verwerking',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Verwerking is begonnen',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'Het bestand is verwerkt',
  'BATCH_HISTORY.ERROR_TOOLTIP': 'Er is een fout opgetreden tijdens de verwerking',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': 'Knopinfo geannuleerd'
};
