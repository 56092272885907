import React from 'react';
import { func, bool, object, string } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import Divider from '../../../../lib/DigitalComponents/Divider';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import Loader from '../../../../lib/DigitalComponents/Loader';

import NoData from '../../../Shared/views/NoDataView';

import styles from './ViewApnsModal.scss';

const ViewApnsModal = ({
	onClose,
	show,
	apns,
	intl,
	companyName,
	apnsRequest
}) => (
	<Modal data-spec="promptModal" show={show} onClose={onClose}>
		<ModalHeader
			title={<FormattedMessage id="ONBOARDING.APNS" defaultMessage="APNs" />}
		/>
		<ModalBody className={styles.modal_body}>
			<div data-spec="body-message" className={styles.body}>
				Showing APNs for {companyName}
				{apnsRequest && <Loader />}
				{!apnsRequest &&
					(apns && apns.apns.length > 0 ? (
						apns.apns.map((item) => (
							<div key={item.id}>
								<Divider />

								<StaticField
									single
									label={intl.formatMessage({
										id: 'ONBOARDING.NAME',
										defaultMessage: 'Name'
									})}
									data={item.name}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.DESCRIPTION',
										defaultMessage: 'Description'
									})}
									data={item.description}
								/>
								<StaticField
									label={
										item.lte
											? intl.formatMessage({
													id: 'ONBOARDING.APNS_PDNINDEX',
													defaultMessage: 'PDN Index'
											  })
											: intl.formatMessage({
													id: 'ONBOARDING.APNS_PDPINDEX',
													defaultMessage: 'PDP Index'
											  })
									}
									data={item.pdpindex}
								/>
								<FieldWrapper customStyle={styles.field_modifiction}>
									<Checkbox
										label={intl.formatMessage({
											id: 'ONBOARDING.APNS_LTE_CAPABLE',
											defaultMessage: 'LTE Capable'
										})}
										disabled
										checked={item.lte}
									/>
								</FieldWrapper>
								<FieldWrapper customStyle={styles.field_modifiction}>
									<Checkbox
										label={intl.formatMessage({
											id: 'ONBOARDING.APNS_SECURITY_ENABLED',
											defaultMessage: 'Security Enabled'
										})}
										disabled
										checked={item.lte}
									/>
								</FieldWrapper>
								<FieldWrapper customStyle={styles.field_modifiction}>
									<Checkbox
										label={intl.formatMessage({
											id: 'ONBOARDING.FIXED_IP',
											defaultMessage: 'Fixed IP'
										})}
										disabled
										checked={item.fixedIP}
									/>
								</FieldWrapper>
								<FieldWrapper customStyle={styles.field_modifiction}>
									<Checkbox
										label={intl.formatMessage({
											id: 'ONBOARDING.APNS_SUBSCRIPTION_ENABLED',
											defaultMessage: 'Subscription enabled'
										})}
										disabled
										checked={item.subscription}
									/>
								</FieldWrapper>
							</div>
						))
					) : (
						<NoData />
					))}
			</div>
		</ModalBody>
		<ModalFooter data-spec="ds-modal-footer">
			<div data-spec="buttons">
				<Button
					onClick={onClose}
					variant="link"
					label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
				/>
			</div>
		</ModalFooter>
	</Modal>
);

ViewApnsModal.propTypes = {
	onClose: func,
	show: bool,
	apns: object,
	intl: object,
	companyName: string,
	apnsRequest: bool
};

export default injectIntl(ViewApnsModal);
