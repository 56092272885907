import { combineReducers } from 'redux';

import globalReducer from '../../../redux/global/globalReducer';
import {
	GET_ANALYTICS_ATTRIBUTES,
	GET_ANALYTICS_CATEGORIES,
	GET_ANALYTICS_FILTERS
} from './constants';

import getChartData from './getChartData/reducer';
import getDirectionalIndex from './getDirectionalIndex/reducer';
import exportChartData from './exportChartData/reducer';

export default combineReducers({
	getChartData,
	getDirectionalIndex,
	exportChartData,
	getAttributes: globalReducer(GET_ANALYTICS_ATTRIBUTES),
	getCategories: globalReducer(GET_ANALYTICS_CATEGORIES),
	getFilters: globalReducer(GET_ANALYTICS_FILTERS)
});
