import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getUserGroups = (params) =>
	ApiService.get(
		`/users/${params.system ? 'system-' : ''}user-group-overviews?${qs.stringify(
			params.params
		)}`
	);

export const getUserGroup = (params) => {
	if (params.system) {
		return ApiService.get(
			`/users/system-user-groups/${params.id}?${qs.stringify(params.params)}`
		);
	}
	if (params.myProfile && params.currentUser.systemUser) {
		return ApiService.get(
			`/users/system-user-groups/${params.id}?${qs.stringify(params.params)}`
		);
	}
	if (params.myProfile) {
		return ApiService.get(
			`/users/user-groups/${params.id}?${qs.stringify(params.params)}`
		);
	}
	return ApiService.get(
		`/users/user-groups/${params.id}?${qs.stringify(params.params)}`
	);
};

export const addUserGroup = (params) =>
	ApiService.post(
		`/users/${params.system ? 'system-' : ''}user-groups`,
		params.userGroup
	);

export const editUserGroupAssignedAccounts = (params) =>
	ApiService.patch(
		`/users/${params.system ? 'system-' : ''}user-groups/${params.id}`,
		{
			userGroupPlatformInsertRequest: params.userGroupPlatformInsertRequest
		}
	);

export const suspendUserGroup = (params) =>
	ApiService.patch(
		`/users/${params.system ? 'system-' : ''}user-groups/${params.id}/suspend`,
		{
			id: params.id
		}
	);

export const reactivateUserGroup = (params) =>
	ApiService.patch(
		`/users/${params.system ? 'system-' : ''}user-groups/${
			params.id
		}/reactivate`,
		{
			id: params.id
		}
	);

export const getFilters = async () => {
	const data = {
		status: {
			resultList: [
				{
					id: true,
					name: 'Active'
				},
				{
					id: false,
					name: 'Suspended'
				}
			],
			totalCount: 2
		}
	};

	return {
		data
	};
};
