import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './AddInvoiceOverview.scss';
import {
	formatInvoiceDate,
	formatBillingCycle,
	formatTime,
} from '../../utils/helpers';

class AddInvoiceOverview extends PureComponent {
	renderInvoiceId = () => {
		const { formData } = this.props;
		return (
			<span
				className={styles.value}
				data-spec="add-invoice-overview-invoice-id"
			>
				<div className={styles.bolded_text}>
					<FormattedMessage
						id="ADD_INVOICE.INVOICE_ID"
						defaultMessage="Invoice ID"
					/>
				</div>
				<div className={styles.field}>{formData.invoiceId}</div>
			</span>
		);
	};

	renderM2MAccountId = () => {
		const { formData } = this.props;
		return (
			<span
				className={styles.value}
				data-spec="add-invoice-overview-m2m-account-id"
			>
				<div className={styles.bolded_text}>
					<FormattedMessage
						id="ADD_INVOICE.M2M_ACCOUNT_ID"
						defaultMessage="M2M Account ID"
					/>
				</div>
				<div className={styles.field}>{formData.m2mAccountId.value}</div>
			</span>
		);
	};

	renderInvoiceDate = () => {
		const { formData } = this.props;
		return (
			<span
				className={styles.value}
				data-spec="add-invoice-overview-invoice-date"
			>
				<div className={styles.bolded_text}>
					<FormattedMessage
						id="ADD_INVOICE.INVOICE_DATE"
						defaultMessage="Invoice Date"
					/>
				</div>
				<div className={styles.field}>
					{formData.invoiceDate && formatInvoiceDate(formData.invoiceDate)}
				</div>
			</span>
		);
	};

	renderBillingCycle = () => {
		const { formData } = this.props;
		return (
			<span
				className={styles.value}
				data-spec="add-invoice-overview-billing-cycle"
			>
				<div className={styles.bolded_text}>
					<FormattedMessage
						id="ADD_INVOICE.BILLING_CYCLE"
						defaultMessage="Billing Cycle"
					/>
				</div>
				<div className={styles.field}>
					{formData.billingCycle && formatBillingCycle(formData.billingCycle)}
				</div>
			</span>
		);
	};

	renderUploadFile = () => {
		const { fileSuccess, formData, fileTimeStamp } = this.props;
		const fileName = formData.fileName
			? formData.fileName
			: fileSuccess[0].name;
		return (
			<div className={styles.upload} data-spec="add-invoice-upload-file">
				<span className={styles.upload_title}>
					<FormattedMessage
						id="ADD_INVOICE.UPLOAD_FILE"
						defaultMessage="Upload a File"
					/>
				</span>
				<span className={styles.uploaded_file_name}>{fileSuccess[0].name}</span>
				<span className={styles.file_date_stamp}>
					<FormattedMessage
						id="ADD_INVOICE.TIME_STAMP"
						defaultMessage="Attached on {date} at {time}"
						values={{
							date: formatInvoiceDate(fileTimeStamp),
							time: formatTime(fileTimeStamp),
						}}
					/>
				</span>
				<span className={styles.upload_file_name_title}>
					<FormattedMessage
						id="ADD_INVOICE.OVERVIEW_FILE_NAME"
						defaultMessage="File Name"
					/>
				</span>
				{fileName}
			</div>
		);
	};

	render() {
		return (
			<div data-spec="add-invoice-overview">
				<div className={styles.row}>
					{this.renderInvoiceId()}
					{this.renderM2MAccountId()}
				</div>
				<div className={styles.row}>
					{this.renderInvoiceDate()}
					{this.renderBillingCycle()}
				</div>
				{this.renderUploadFile()}
			</div>
		);
	}
}
const { object, array, any } = PropTypes;

AddInvoiceOverview.propTypes = {
	formData: object,
	fileSuccess: array,
	fileTimeStamp: any,
};

export default AddInvoiceOverview;
