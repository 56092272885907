import PropTypes from 'prop-types';
import React from 'react';

import hoc from '../AccountActivation';
import styles from './Mobile.scss';
// FOR FUTURE MOBILE IMPLEMENTATION
const Mobile = ({ title, resetPasswordFail, renderNotifications, renderForm }) => (
		<div data-spec="account-activation-mobile">
			<div className={styles.title_background}>
				<h1 className={styles.title}>{title}</h1>
			</div>
			<div
				className={
					(resetPasswordFail && styles.error_form) || styles.form
				}
			>
				<div className={styles.error_notification}>
					{resetPasswordFail && renderNotifications()}
				</div>
				{renderForm()}
			</div>
		</div>
	);

const { bool, func, object } = PropTypes;
Mobile.propTypes = {
	renderForm: func,
	title: object,
	resetPasswordFail: bool,
	renderNotifications: func
};

export default hoc()(Mobile);
