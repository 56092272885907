import { includes } from 'lodash';
import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import SingleEndpoint from './SingleEndpoint';
import { allowedPlatformsPendingStatus } from '../../../../utils/constants';
import { showNotification } from '../../../Shared/redux/notifications/actions';
import {
	isSingleEndpointUpdating,
	isSingleEndpointRequestFailed,
	getCustomFieldLabels,
	getSingleEndpointData,
	isSingleEndpointPending,
	areCustomFieldsValuesUpdating,
	getNetworkEntitlementApnData,
	getNetworkEntitlementData,
	cancelScheduleStatusSuccess,
	getRatePlansData,
	getUpdateCustomFieldsValuesSuccess,
	getSingleEndpointTransactionStatus,
} from '../../redux/selectors';
import {
	getSingleEndpoint,
	updateSingleEndpointInit,
	changeNetworkEntitlementInit,
	updateCustomFieldsValuesInit,
	cancelScheduleStatus,
	getDiagnosticDcpInit,
	getBlockedSubscriptionDcpInit,
	getTroubleshootDcpInit,
	getDiagnosticInit,
	getRegistrationInit,
	getRatePlans,
	getNetworkEntitlement,
	getNetworkEntitlementApn,
	getDataHistoryInit,
	getMessageHistoryInit,
	getHistoryChangesInit,

} from '../../redux/actions';
import { openModal } from '../../../../redux/modals/singleSimStatusChangeModal/actions';
import isCancelScheduleStatusModalOpened
	from '../../../../redux/modals/cancelScheduleStatusModal/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => (
	{
		endpoint: getSingleEndpointData(state),
		isPending: isSingleEndpointPending(state),
		customFieldLabels: getCustomFieldLabels(state),
		areCustomFieldsValuesUpdating: areCustomFieldsValuesUpdating(state),
		isSingleEndpointUpdating: isSingleEndpointUpdating(state),
		isCancelPendingSuccess: cancelScheduleStatusSuccess(state),
		ratePlans: getRatePlansData(state),
		isCancelScheduleStatusModalOpen: isCancelScheduleStatusModalOpened(state),
		isSingleEndpointRequestFailed: isSingleEndpointRequestFailed(state),
		user: getUser(state),
		entitlementApn: getNetworkEntitlementApnData(state),
		entitlements: getNetworkEntitlementData(state),
		isCustomFieldsValuesUpdateSuccess: getUpdateCustomFieldsValuesSuccess(state),
		transactionStatus: getSingleEndpointTransactionStatus(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getSingleEndpoint: (iccid, type) => {
			dispatch(changeNetworkEntitlementInit());
			dispatch(getSingleEndpoint(iccid, type));
		},
		showPendingStatusNotification: (
			endpoint,
			transactionStatus,
			openCancelScheduleModal,
			intl
		) => (
			dispatch(showNotification({
				id: 'endpoint-pending-status',
				title: intl.formatMessage({
					id: 'PENDING_STATUS_CHANGE',
					defaultMessage: 'Pending change!'
				}),
				message: [
					intl.formatMessage({
						id: 'STATUS_CHANGE_FOR',
						defaultMessage: 'There is a Scheduled Activity for this Endpoint',
					})
				],
				type: 'pending',
				notificationType: 'general',
				close: false,
				actionText: endpoint.serviceProvider &&
				includes(
					allowedPlatformsPendingStatus,
					endpoint.serviceProvider.toLowerCase()
				) ? intl.formatMessage({
					id: 'CANCEL_CHANGE',
					defaultMessage: 'Cancel change',
				}) : '',
				action: endpoint.serviceProvider &&
				includes(
					allowedPlatformsPendingStatus,
					endpoint.serviceProvider.toLowerCase()
				) &&
				transactionStatus &&
				transactionStatus.scheduledDate &&
				(
					new Date(
						Date.parse(transactionStatus.scheduledDate)
					).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
				) && transactionStatus.status
				&& transactionStatus.status.toLowerCase() !== 'cancelled'
					? openCancelScheduleModal
					: undefined,
			}))
		),
		getRatePlans: (m2mAccountId) => dispatch(getRatePlans(m2mAccountId)),
		pushBack: () => dispatch(localizedRoute('/endpoints')),
		openModal: () => dispatch(openModal()),
		updateSingleEndpointStatusInit: () => {
			dispatch(changeNetworkEntitlementInit());
			dispatch(updateCustomFieldsValuesInit());
			dispatch(getHistoryChangesInit());
			dispatch(getDiagnosticDcpInit());
			dispatch(getBlockedSubscriptionDcpInit());
			dispatch(getTroubleshootDcpInit());
			dispatch(getDiagnosticInit());
			dispatch(getRegistrationInit());
			dispatch(getDataHistoryInit());
			dispatch(getMessageHistoryInit());
			dispatch(updateSingleEndpointInit());
		},
		cancelScheduleStatus: (id, type) => dispatch(cancelScheduleStatus(
			id,
			type,
		)),
		getNetworkEntitlement: (id) => dispatch(getNetworkEntitlement(id)),
		getNetworkEntitlementApn: (
			m2mAccountId,
			networkEntitlementId,
		) => dispatch(getNetworkEntitlementApn(m2mAccountId, networkEntitlementId)),
	}
);

export const DesktopSingleEndpoint = connect(
	mapStateToProps,
	mapDispatchToProps
)(SingleEndpoint);
export const TabletSingleEndpoint = connect(
	mapStateToProps,
	mapDispatchToProps
)(SingleEndpoint);
export const MobileSingleEndpoint = connect(
	mapStateToProps,
	mapDispatchToProps
)(SingleEndpoint);
