/* eslint-disable no-plusplus */

export const getFrameValue2 = (rule, item) => {
	if (
		rule &&
		rule.automationTrigger &&
		rule.automationTrigger.automationTriggerFrameValueTypeId === 11
	) {
		return {
			value: item.frameValue2,
			label: item.frameValue2
		};
	}
	return item.frameValue2;
};

export const getFrameValue = (rule, item) => {
	if (
		rule &&
		rule.automationTrigger &&
		rule.automationTrigger.automationTriggerFrameValueTypeId === 13
	) {
		const number = item.frameValue.replace(/^\D+/g, '');
		return {
			value: item.frameValue,
			label: `Custom Field ${number}`
		};
	}
	if (
		rule &&
		rule.automationTrigger &&
		rule.automationTrigger.automationTriggerFrameValueTypeId === 14
	) {
		return {
			value: item.frameValue,
			label: item.frameValue
		};
	}
	return item.frameValue;
};

export const getCustomFieldsOptions = () => {
	const customFieldsOptions = [];
	for (let i = 1; i < 11; i++) {
		customFieldsOptions.push({
			label: `Custom Field ${i}`,
			value: `custom${i}`
		});
	}
	return customFieldsOptions;
};

const imeiWhitelistRestrictions = [
	'Allow Data Access',
	'Block Data Access',
	'Block Data Access for 12 hours'
];

export const getImeiWhiteListActionOptions = () =>
	imeiWhitelistRestrictions.map((item) => ({
		value: item,
		label: item
	}));
