import { createAction } from 'redux-actions';

import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import {
	hideNotification,
	showNotification
} from '../../../Shared/redux/notifications/actions';

import {
	getTickets as getTicketsService,
	getTicket as getTicketService,
	addTicket as addTicketService,
	editTicket as editTicketService
} from '../../services/TicketingService';

import {
	GET_TICKETS,
	GET_TICKET,
	EDIT_TICKET,
	ADD_TICKET_INIT,
	ADD_TICKET_REQUEST,
	ADD_TICKET_FAIL,
	ADD_TICKET_SUCCESS,
	EDIT_TICKET_INIT,
	EDIT_TICKET_REQUEST,
	EDIT_TICKET_FAIL,
	EDIT_TICKET_SUCCESS
} from './constants';

//  ADD TICKET
const addTicketInitAction = createAction(ADD_TICKET_INIT);
const addTicketRequest = createAction(ADD_TICKET_REQUEST);
const addTicketFail = createAction(ADD_TICKET_FAIL);
const addTicketSuccess = createAction(ADD_TICKET_SUCCESS);

//  EDIT TICKET
const editTicketInitAction = createAction(EDIT_TICKET_INIT);
const editTicketRequest = createAction(EDIT_TICKET_REQUEST);
const editTicketFail = createAction(EDIT_TICKET_FAIL);
const editTicketSuccess = createAction(EDIT_TICKET_SUCCESS);

export const addTicket = (params, formatMessage) => async (dispatch) => {
	dispatch(addTicketInitAction());
	try {
		dispatch(addTicketRequest());
		const request = await addTicketService(params);
		dispatch(addTicketSuccess(request));
		const { fileErrors } = request.data;

		if (fileErrors.length > 0) {
			let attachments = '';
			fileErrors.forEach((fileError, index) => {
				if (index === 0) attachments += fileError.message;
				attachments += `, ${fileError.message}`;
			});

			const message = formatMessage(
				{
					id: 'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE',
					defaultMessage: `Ticket was created ${request.data.id}, but the attachments {${attachments}} were not uploaded`
				},
				{
					ticketId: request.data.id,
					attachments
				}
			);

			const title = formatMessage({
				id: 'TICKETING.ATTACHMENT_FAIL_TITLE',
				defaultMessage: 'Could not complete your request entirely.'
			});
			dispatch(
				showNotification({
					id: 'add-ticket-attachment-fail',
					message: [message],
					title,
					type: 'error',
					close: true,
					duration: 5000,
					handleDismiss: () =>
						dispatch(hideNotification('add-ticket-attachment-fail'))
				})
			);
		}
	} catch (error) {
		dispatch(addTicketFail(error));
	}
};

/* GET TICKETS */
export const getTickets = (params) =>
	globalAction(GET_TICKETS, getTicketsService, params);

/* GET TICKET */
export const getTicket = (params) =>
	globalAction(GET_TICKET, getTicketService, params);

export const editTicketInit = () => actionInit(EDIT_TICKET);

/* EDIT TICKET */
export const editTicket = (params, formatMessage) => async (dispatch) => {
	dispatch(editTicketInitAction());
	try {
		dispatch(editTicketRequest());
		const request = await editTicketService(params);
		dispatch(editTicketSuccess(request));
		const { fileErrors, deleteFileErrors } = request.data;
		if (fileErrors.length > 0 || deleteFileErrors.length > 0) {
			let uploadAttachments = '';
			let deleteAttachments = '';
			if (fileErrors.length > 0) {
				fileErrors.forEach((fileError, index) => {
					if (index === 0) uploadAttachments += fileError.message;
					else uploadAttachments += `, ${fileError.message}`;
				});
			}
			if (deleteFileErrors.length > 0) {
				deleteFileErrors.forEach((fileError, index) => {
					if (index === 0) deleteAttachments += fileError.message;
					else deleteAttachments += `, ${fileError.message}`;
				});
			}

			const messageUploadFail = formatMessage(
				{
					id: 'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE',
					defaultMessage: `Ticket was edited ${request.data.id}, but the attachments (${uploadAttachments}) were not uploaded.`
				},
				{
					ticketId: request.data.id,
					attachments: uploadAttachments
				}
			);

			const messageDeleteFail = formatMessage(
				{
					id: 'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE',
					defaultMessage: `Ticket was edited ${request.data.id}, but the attachments (${deleteAttachments}) were not deleted.`
				},
				{
					ticketId: request.data.id,
					attachments: deleteAttachments
				}
			);

			const messageUploadAndDeleteFail = formatMessage(
				{
					id: 'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE',
					defaultMessage: `Ticket was edited ${request.data.id}, but the attachments (${deleteAttachments}) were not deleted and attachments (${uploadAttachments}) were not uploaded.`
				},
				{
					ticketId: request.data.id,
					deleteAttachments,
					uploadAttachments
				}
			);

			const title = formatMessage({
				id: 'TICKETING.ATTACHMENT_FAIL_TITLE',
				defaultMessage: 'Could not complete your request entirely.'
			});

			let message;
			if (fileErrors.length > 0 && deleteFileErrors.length > 0) {
				message = messageUploadAndDeleteFail;
			} else if (fileErrors.length > 0) {
				message = messageUploadFail;
			} else {
				message = messageDeleteFail;
			}

			dispatch(
				showNotification({
					id: 'edit-ticket-attachments-fail',
					message: [message],
					title,
					type: 'error',
					close: true,
					duration: 5000,
					handleDismiss: () =>
						dispatch(hideNotification('edit-ticket-attachments-fail'))
				})
			);
		}
	} catch (error) {
		dispatch(editTicketFail(error));
	}
};
