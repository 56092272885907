import { createAction } from 'redux-actions';

import { postUserSettings } from '../../../services/UserService';
import { getLocale } from '../../../utils/constants';
import { CREATE_USER_SETTINGS, FETCH_USER } from '../constants';
import { showNotification as show } from '../../../components/Shared/redux/notifications/actions';

const request = createAction(`${CREATE_USER_SETTINGS}_REQUEST`);
const success = createAction(`${CREATE_USER_SETTINGS}_SUCCESS`);
const fail = createAction(`${CREATE_USER_SETTINGS}_ERROR`);
const createSuccess = createAction(`${FETCH_USER}_CREATE`);

const createUserSettings = (setting, message) => async (dispatch) => {
  const locale = getLocale();
	const { default: messages } = await import(
		`../../../localizations/${locale}`
	);
	dispatch(request());
	postUserSettings(setting)
		.then((response) => {
			dispatch(createSuccess(response.data));
			message &&
				dispatch(
					show({
						id: 'table-preferences-updated-success',
						message: message.success
							? [messages[message.success]]
							: [messages.ACTION_COMPLETED_SUCCESSFULLY],
						type: 'success',
						notificationType: 'toaster',
						close: true,
						duration: 5000
					})
				);
			dispatch(success(response));
		})
		.catch((error) => {
			dispatch(fail(error));
			message &&
				dispatch(
					show({
						id: 'table-preferences-updated-fail',
						message: message.error
							? [messages[message.error]]
							: [messages.ACTION_COULD_NOT_BE_COMPLETED],
						type: 'error',
						notificationType: 'toaster',
						close: false
					})
				);
		});
};

export default createUserSettings;
