export default {
  'RELEASE_NOTES.RELEASE_NOTES': 'Notas de la versión',
'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'IoT Console se actualiza frecuentemente.',
'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': 'IoT Console se actualiza frecuentemente para asegurar el funcionamiento óptimo y ofrecer amplias funciones y capacidades y mejoras de la experiencia del usuario. Estas son las actualizaciones de la nueva versión.',
'RELEASE_NOTES.RELEASE_VERSION': 'Versión',
'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'Funciones de la nueva plataforma',
'RELEASE_NOTES.DEPRECATIONS': 'Depreciaciones',
'RELEASE_NOTES.DEPRECATION': 'Depreciación',
'RELEASE_NOTES.KNOWN_ISSUES': 'Problemas conocidos',
'RELEASE_NOTES.NEW_FEATURES': 'Nuevas funciones',
'RELEASE_NOTES.IMPROVEMENTS': 'Mejoras',
'RELEASE_NOTES.FEATURE': 'Función',
'RELEASE_NOTES.IMPROVEMENT': 'Mejora',
'RELEASE_NOTES.ISSUE': 'Problema',

'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Agregar otra depreciación',
'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Agregar otra función de plataforma',
'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Agregar otra función',
'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Agregar otro problema conocido',
'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Agregar otra mejora',

'RELEASE_NOTES.PUBLISH': 'Publicar',
'RELEASE_NOTES.SAVE': 'Guardar',
'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Publicar notas de la versión',
'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': 'Está a punto de publicar notas de la versión {releaseVersion}. ¿Está seguro de que desea hacer esto?',
'RELEASE_NOTES.CANCEL': 'Cancelar',
};
