import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import styles from './BulkUploadInstructionsModal.scss';

const bulkUploadInstructions = [{
	title: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FILE_TEMPLATES',
	defaultTitle: 'BATCH FILE TEMPLATES',
	description: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FILE_TEMPLATES_DESCRIPTION',
	defaultDescription: 'The following templates are used to process batch uploads: Activation.csv template is used to activate ICCID. Custom_Fields.csv template will allow you to add/edit a custom field to a device. Network_Entitlement_Change.csv template is used to change a network entitlement (communication plan) for an ICCID. Rate_Plan_Change.csv template is used to make rate plan changes. Termination.csv template is used to terminate ICCID.'
}, {
	title: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FORMATTING_YOUR_DATA',
	defaultTitle: 'FORMATTING YOUR DATA',
	description: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FORMATTING_YOUR_DATA_DESCRIPTION',
	defaultDescription: 'The templates are action specific and contain headers that are relevant for the bulk action to be performed. Inside each template you will find instructions and headers that are specific to that template. Do not delete the instructions. Headers for each template are displayed at the bottom of the instructions. Each ICCID should be its own line. Every field value must be accounted for and separated by a comma even if the field value is blank. Field values left blank will not delete/change current field value. Maximum number of SIM upload per template is 10,000.'
}, {
	title: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.NAMING_BATCH_FILE',
	defaultTitle: 'NAMING BATCH FILE',
	description: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.NAMING_BATCH_FILE_DESCRIPTION',
	defaultDescription: 'Batch file names must be the same as template name, however you can add an _ (underscore) to include additional filename identifier after the template name but before .csv file type. Ex: Rate_Plan_Change_filename.csv. The system will automatically append the date+time stamp to the file',
}, {
	title: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.SUBMITTING_BATCH_FILE',
	defaultTitle: 'SUBMITTING BATCH FILE',
	description: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.SUBMITTING_BATCH_FILE_DESCRIPTION',
	defaultDescription: 'Navigate to Endpoints page and click Batch Upload. Follow directions to upload the file'
}, {
	title: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.BATCH_PROCESSING_AND_RESULTS',
	defaultTitle: 'BATCH PROCESSING AND RESULTS',
	description: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.BATCH_PROCESSING_AND_RESULTS_DESCRIPTION',
	defaultDescription: "Click on Batch History link in the left navigation to see the results of the Batch Upload. Status will state as 'Pending' until the file is processed. Status will state as 'Complete' after the file has successfully been processed. Status will state as 'Error' if the file is has formatting errors during upload. After the batch is processed successfully and the status changes to 'Complete' user who submitted the batch upload will receive an email notification. Success Rate will display the the total records processed successfully over the total numbers of records submitted.",
}, {
	title: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.ERROR_HANDLING',
	defaultTitle: 'ERROR HANDLING',
	description: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.ERROR_HANDLING_DESCRIPTION',
	defaultDescription: 'Status completed means that your batch was processed, however your batch may contain errors if Success Rate column displays number of records processed successfully is less than the total numbers. In order to inspect the errors download the Batch History report and review the Batch file where the error message is displayed.'
}];

function BulkUploadInstructionsModal({ show, onCancel, intl }) {
  return (
		<Modal data-spec="dashboard-instructions-modal" show={show} onClose={onCancel}>
			<ModalHeader
				title={
					intl.formatMessage({ id: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS', defaultMessage: 'Batch Upload Instructions' })
				}
			/>
			<ModalBody className={styles.modal_body}>
				<div data-spec="body-instructions" className={styles.instructions_body}>
					<div className={styles.modal_instructions}>
						<p>
							{
							 intl.formatMessage({ id: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.GENERAL', defaultMessage: 'Download the desired batch action template to your computer,open the template in text editor (Notepad on Windows or TextEdit on Mac) and follow the instructions on how to format the data for the selected template. Instructions are available inside each template.' })
							}
						</p>
						{ bulkUploadInstructions.map((x) => (<>
							<h4>
								{	intl.formatMessage({ id: x.title, defaultMessage: x.defaultTitle })}
							</h4>
						<p>
								{	intl.formatMessage({ id: x.description, defaultMessage: x.defaultDescription })}
						</p>
                                           </>))}

					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					onClick={onCancel}
					variant="primary"
					label={<FormattedMessage id="ENDPOINTS.OK" defaultMessage="Ok" />}
					data-spec="bulk-instructions-ok-button"
				/>
			</ModalFooter>
		</Modal>
	);
}

BulkUploadInstructionsModal.propTypes = {
	onCancel: PropTypes.func,
	show: PropTypes.bool,
	intl: intlShape.isRequired,
};

BulkUploadInstructionsModal.defaultProps = {
	onCancel: undefined,
	show: false,
};

export default injectIntl(BulkUploadInstructionsModal);
