export default {
	'USER_FEEDBACK.TRIGGER_TITLE': 'Può fornirci del feedback?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Ce lo dica qui',
	'USER_FEEDBACK.ADDITIONAL_FILE': 'File aggiuntivo',
	'USER_FEEDBACK.PAGE_LABEL': 'Il Suo feedback riguarda',
	'USER_FEEDBACK.FEED_LABEL': 'Descrivere in che modo possiamo migliorare.',
	'USER_FEEDBACK.FEED_PLACEHOLDER': 'Inserire qui il proprio feedback...',
	'USER_FEEDBACK.CONTACT_LABEL': 'Se necessario, possiamo contattarLa al riguardo?',
	'USER_FEEDBACK.EMAIL_LABEL': 'E-mail',
	'USER_FEEDBACK.PHONE_LABEL': 'Telefono',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Invia feedback',
	'USER_FEEDBACK.ICON': 'Icona invio feedback riuscito',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Grazie per il feedback.',
	'USER_FEEDBACK.SUCCESS_MESSAGE': 'Il feedback fornitoci ci permetterà di migliorare il prodotto. Valuteremo con attenzione i commenti e agiremo di conseguenza.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': 'Potremmo anche contattarLa per ottenere maggiori chiarimenti riguardo al Suo suggerimento.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'Il Team IoT Console',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Fatto',
	'USER_FEEDBACK.TITLE': 'Feedback utente',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Caricamento file non riuscito'
};
