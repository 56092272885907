import qs from 'qs';
import { uniqBy } from 'lodash';
import * as ApiService from '../../../services/ApiService';
import { ruleStatusIds } from '../utils/manualRuleStatesHelper';

/* ---------- GET AUTOMATION RULES ---------- */
export const getAutomationRules = (params) =>
	ApiService.get(
		`/automation/rules?${qs.stringify({
			additionalParams: params.additionalParams,
			searchParams: params.searchParams
		})}`
	);

/* ---------- GET AUTOMATON RULE ---------- */
export const getAutomationRule = ({ id, params }) =>
	ApiService.get(`/automation/rules/${id}?${qs.stringify(params)}`);

/* ---------- DELETE AUTOMATON RULE ---------- */
export const removeAutomationRule = (id) =>
	ApiService.remove(`/automation/rules/${id}`, { data: { id } });

/* ---------- ADD AUTOMATON RULE ---------- */
export const postAutomationRule = (data) =>
	ApiService.post('/automation/rules', data);

/* ---------- EDIT AUTMATON RULE ---------- */
export const patchAutomationRule = ({ data, id }) =>
	ApiService.patch(`/automation/rules/${id}`, data);

/* ---------- GET AUTOMATION PLATFORMS ---------- */
export const getAutomationPlatforms = async () => {
	const m2mResponse = await ApiService.get('/customers/m2mAccount');

	const temp =
		m2mResponse &&
		m2mResponse.data &&
		m2mResponse.data.m2mAccounts &&
		m2mResponse.data.m2mAccounts.length
			? {
					searchParams: [
						{
							propValue: uniqBy(
								m2mResponse.data.m2mAccounts,
								'platformName'
							).map((item) => item.platformName),
							prop: 'code',
							operator: 'in'
						}
					]
			  }
			: {};

	return {
		platforms: await ApiService.get(
			`/automation/platforms?${qs.stringify(temp)}`
		),
		m2mAccounts: m2mResponse
	};
};

/* ---------- GET PLATFORM AND M2MACC ---------- */
export const getAutomationPlatform = async (platform) => ({
	platform: await ApiService.get(
		`/automation/platforms?${qs.stringify({
			searchParams: [{ propValue: platform, prop: 'name', operator: '=' }]
		})}`
	),
	m2mAccountId: await ApiService.get('/customers/m2mAccount')
});

/* ---------- GET AUTOMATION CATEGORIES ---------- */
export const getAutomationCategories = (params) =>
	ApiService.get(
		`/automation/categories?${qs.stringify({
			additionalParams: params.additionalParams,
			searchParams: params.searchParams
		})}`
	);

/* ---------- GET AUTOMATION TRIGGERS ---------- */
export const getAutomationTriggers = (params) =>
	ApiService.get(
		`/automation/triggers?${qs.stringify({
			additionalParams: params.additionalParams,
			searchParams: params.searchParams
		})}`
	);

/* ---------- GET AUTOMATION TRIGGER SUBCATEGORIES ---------- */
export const getAutomationTriggerSubcategories = (params) =>
	ApiService.get(
		`/automation/trigger-sub-categories?${qs.stringify({
			additionalParams: params.additionalParams,
			searchParams: params.searchParams
		})}`
	);

/* ---------- GET AUTOMATION FRAMES ---------- */
export const getAutomationFrames = (params) =>
	ApiService.get(
		`/automation/frames?${qs.stringify({
			additionalParams: {
				...params.additionalParams,
				include: ['AutomationFrameUnits']
			},
			searchParams: params.searchParams
		})}`
	);

/* ---------- GET AUTOMATION ALERTS ---------- */
export const getAutomationAlerts = (params) =>
	ApiService.get(`/automation/alerts?${qs.stringify(params)}`);

/* ---------- DELETE AUTOMATION ALERTS ---------- */
export const removeAutomationAlert = (params) =>
	ApiService.patch('/automation/alerts', { ids: params });

/* ---------- GET FILTERS FOR RULES ---------- */
export const getAutomationRulesFilters = async (alerts = false) => {
	const m2mResponse = await ApiService.get('/customers/m2mAccount');
	const platforms = await ApiService.get(
		`/automation/platforms?${qs.stringify({
			searchParams: [
				{
					propValue: uniqBy(m2mResponse.data.m2mAccounts, 'platformName').map(
						(item) => item.platformName
					),
					prop: 'code',
					operator: 'in'
				}
			]
		})}`
	);
	const categories = await ApiService.get(
		`/automation/categories?${qs.stringify({
			additionalParams: {
				include: ['AutomationPlatform']
			},
			searchParams: [
				{
					propValue: platforms.data.resultList.map((item) => item.id),
					prop: 'automationPlatformId',
					operator: 'in'
				}
			]
		})}`
	);
	const triggers = await ApiService.get(
		`/automation/triggers?${qs.stringify({
			searchParams: [
				{
					propValue: categories.data.resultList.map((item) => item.id),
					prop: 'automationCategoryId',
					operator: 'in'
				}
			]
		})}`
	);

	const allStatuses = await ApiService.get('automation/statuses');
	const statuses = allStatuses.data.resultList.filter(
		(status) =>
			status.id !== ruleStatusIds.NOT_APPLICABLE &&
			status.id !== ruleStatusIds.DELETED
	);

	const active = {
		resultList: [
			{ id: true, name: 'Yes' },
			{ id: false, name: 'No' }
		],
		totalCount: 2
	};
	return {
		data: {
			platforms: platforms.data,
			categories: categories.data,
			triggers: triggers.data,
			statuses: { resultList: statuses, totalCount: allStatuses.totalCount },
			...(!alerts && { m2mAccounts: m2mResponse.data, active })
		}
	};
};

/* ---------- GET FILTERS FOR ALERTS ---------- */
export const getAutomationAlertsFilters = async () =>
	// eslint-disable-next-line no-return-await
	await getAutomationRulesFilters(true);

/* ---------- GET AUTOMATION ACTIONS ---------- */
export const getAutomationActions = (params) =>
	ApiService.get(`/automation/actions?${qs.stringify(params)}`);

/* ---------- GET AUTOMATION SCHEDULERS ---------- */
export const getAutomationSchedulers = (params) =>
	ApiService.get(`/automation/action-schedulers?${qs.stringify(params)}`);

/* ---------- GET AUTOMATION COUNTRIES ---------- */
export const getAutomationCountries = () =>
	ApiService.get('automation/countries');

/* ---------- GET AUTOMATION ZONE MODEL ---------- */
export const getAutomationZoneModel = () =>
	ApiService.get('/automation/zone-models');

/* ---------- GET AUTOMATION ZONE NAME ---------- */
export const getAutomationZoneName = (params) =>
	ApiService.get(`/automation/zones?${qs.stringify(params)}`);

/* ---------- GET AUTOMATION POOL ---------- */
export const getAutomationPool = ({ m2mAccountId }) =>
	ApiService.get(
		`/onboarding/my-company/m2m-accounts/${m2mAccountId}/rate-plans?${qs.stringify(
			{
				searchParams: [
					{
						propValue: true,
						prop: 'poolStack',
						operator: '='
					}
				]
			}
		)}`
	);

/* ---------- GET AUTOMATION RATE PLANS ---------- */
export const getAutomationRatePlans = ({ m2mAccountId }) =>
	ApiService.get(
		`/onboarding/my-company/m2m-accounts/${m2mAccountId}/rate-plans`
	);

export const getAutomationPrepaidRatePlans = (data) =>
	ApiService.get(
		`/onboarding/my-company/m2m-accounts/${
			data.m2mAccountId
		}/rate-plans?${qs.stringify({
			searchParams: [
				{
					propValue: 'Prepaid',
					prop: 'paymentType',
					operator: '='
				}
			]
		})}`
	);

/* ---------- GET AUTOMATION NETWORK ENTITLEMENTS ---------- */
export const getAutomationNetworkEntitlements = ({ m2mAccountId }) =>
	ApiService.get(`/customers/automation-network-entitlements/${m2mAccountId}`);

/* ---------- GET AUTOMATION AVAILABLE STATES ---------- */
export const getAutomationAvailableStates = () =>
	ApiService.get('endpoints/availableStates');

/*  ------------- ACTIVATE AUTOMATION RULE ------------- */
export const activateRule = (params) =>
	ApiService.patch(
		`automation/rules/${params.id}/activation`,
		params.activeStatus
	);

/*  ------------- RULE STATE CHANGE ------------- */

export const requestForDeletion = (id) =>
	ApiService.patch(`automation/rules/${id}/request-deletion`);

export const setRuleReceived = (data) =>
	ApiService.patch(`automation/rules/${data.id}/receive`, data.params);

export const setRuleImplemented = (data) =>
	ApiService.patch(`automation/rules/${data.id}/implement`, data.params);

export const setRuleConflicted = (data) =>
	ApiService.patch(`automation/rules/${data.id}/conflict`, data.params);

/*  ------------- RULE HISTORY ------------- */
export const getRuleHistory = (data) =>
	ApiService.get(
		`automation/rules/${data.id}/history/?${qs.stringify(data.params)}`
	);

/*  ------------- RULE NOTES ------------- */
export const getRuleNotes = (data) =>
	ApiService.get(
		`automation/rules/${data.id}/notes?${qs.stringify(data.params)}`
	);

/*  ------------- RULE AUTOMATION ZONES ------------- */
export const getRuleAutomationZones = (params) =>
	ApiService.get(`/automation/automation-zones?${qs.stringify(params)}`);
