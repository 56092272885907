import { Status } from '../../../../utils/constants';

export const getActivationsRequest = (state) =>
	state.analyticsInvoices.tables.activations.getActivations.status ===
	Status.PENDING;
export const getActivationsFail = (state) =>
	state.analyticsInvoices.tables.activations.getActivations.status ===
	Status.FAIL;
export const getActivationsSuccess = (state) =>
	state.analyticsInvoices.tables.activations.getActivations.data;

export const getActivationsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.activations.getActivationsFilters.status ===
	Status.PENDING;
export const getActivationsFiltersFail = (state) =>
	state.analyticsInvoices.tables.activations.getActivationsFilters.status ===
	Status.FAIL;
export const getActivationsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.activations.getActivationsFilters.data;
