import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import RatePlansDesktop from './Desktop';
import RatePlansMobile from './Mobile';

import {
	getRatePlans,
} from '../../redux/ratePlans/actions';

import {
	getRatePlansRequest,
	getRatePlansFail,
	getRatePlansSuccess,
} from '../../redux/ratePlans/selectors';
import { getAccount, getAccountInit } from '../../redux/accounts/actions';
import {
	getAccountRequest,
	getAccountFail,
	getAccountSuccess
} from '../../redux/accounts/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	ratePlansRequest: getRatePlansRequest(state),
	ratePlansFail: getRatePlansFail(state),
	ratePlans: getRatePlansSuccess(state),
	accountRequest: getAccountRequest(state),
	accountFail: getAccountFail(state),
	account: getAccountSuccess(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit()),
	getRatePlans: (params) => dispatch(getRatePlans(params)),
	push: (url) => dispatch(localizedRoute(url)),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(RatePlansDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(RatePlansMobile);
