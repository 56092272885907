import { Status } from '../../../../utils/constants';

export const getAnalyticsInvoicesRequest = (state) =>
	state.analyticsInvoices.invoices.getAnalyticsInvoices.status ===
	Status.PENDING;
export const getAnalyticsInvoicesFail = (state) =>
	state.analyticsInvoices.invoices.getAnalyticsInvoices.status === Status.FAIL;
export const getAnalyticsInvoicesSuccess = (state) =>
	state.analyticsInvoices.invoices.getAnalyticsInvoices.data;

export const getInvoicesRequest = (state) =>
	state.analyticsInvoices.invoices.getInvoices.status === Status.PENDING;
export const getInvoicesFail = (state) =>
	state.analyticsInvoices.invoices.getInvoices.status === Status.FAIL;
export const getInvoicesSuccess = (state) =>
	state.analyticsInvoices.invoices.getInvoices.data;

export const getInvoiceDetailsRequest = (state) =>
	state.analyticsInvoices.invoices.getInvoiceDetails.status === Status.PENDING;
export const getInvoiceDetailsFail = (state) =>
	state.analyticsInvoices.invoices.getInvoiceDetails.status === Status.FAIL;
export const getInvoiceDetailsSuccess = (state) =>
	state.analyticsInvoices.invoices.getInvoiceDetails.data;

export const getFiltersRequest = (state) =>
	state.analyticsInvoices.invoices.getFilters.status === Status.PENDING;
export const getFiltersFail = (state) =>
	state.analyticsInvoices.invoices.getFilters.status === Status.FAIL;
export const getFiltersSuccess = (state) =>
	state.analyticsInvoices.invoices.getFilters.data;

export const getAnalyticsInvoicesFiltersRequest = (state) =>
	state.analyticsInvoices.invoices.getAnalyticsInvoicesFilters.status ===
	Status.PENDING;

export const getAnalyticsInvoicesFiltersFail = (state) =>
	state.analyticsInvoices.invoices.getAnalyticsInvoicesFilters.status ===
	Status.FAIL;

export const getAnalyticsInvoicesFiltersSuccess = (state) =>
	state.analyticsInvoices.invoices.getAnalyticsInvoicesFilters.data;

export const exportInvoiceRequest = (state) =>
	state.analyticsInvoices.invoices.exportInvoice.status === Status.PENDING;

export const exportInvoiceFail = (state) =>
	state.analyticsInvoices.invoices.exportInvoice.status === Status.FAIL;

export const exportInvoiceSuccess = (state) =>
	state.analyticsInvoices.invoices.exportInvoice.data;

export const patchInvoiceRequest = (state) =>
	state.analyticsInvoices.invoices.patchInvoice.status === Status.PENDING;

export const patchInvoiceFail = (state) =>
	state.analyticsInvoices.invoices.patchInvoice.status === Status.FAIL;

export const patchInvoiceSuccess = (state) =>
	state.analyticsInvoices.invoices.patchInvoice.data;

export const getInvoiceAttachmentsRequest = (state) =>
	state.analyticsInvoices.invoices.getInvoiceAttachments.status ===
	Status.PENDING;

export const getInvoiceAttachmentsFail = (state) =>
	state.analyticsInvoices.invoices.getInvoiceAttachments.status === Status.FAIL;

export const getInvoiceAttachmentsSuccess = (state) =>
	state.analyticsInvoices.invoices.getInvoiceAttachments.data;

export const postInvoiceRequest = (state) =>
	state.analyticsInvoices.invoices.postInvoice.status === Status.PENDING;

export const postInvoiceFail = (state) =>
	state.analyticsInvoices.invoices.postInvoice.status === Status.FAIL;

export const postInvoiceSuccess = (state) =>
	state.analyticsInvoices.invoices.postInvoice.data;

export const getM2mAccountsRequest = (state) =>
	state.analyticsInvoices.invoices.getM2mAccounts.status === Status.PENDING;

export const getM2mAccountsFail = (state) =>
	state.analyticsInvoices.invoices.getM2mAccounts.status === Status.FAIL;

export const getM2mAccountsSuccess = (state) =>
	state.analyticsInvoices.invoices.getM2mAccounts.data &&
	state.analyticsInvoices.invoices.getM2mAccounts.data.m2mAccounts;

export const exportInvoiceDetailsRequest = (state) =>
	state.analyticsInvoices.invoices.exportInvoiceDetails.status === Status.PENDING;

export const exportInvoiceDetailsFail = (state) =>
	state.analyticsInvoices.invoices.exportInvoiceDetails.status === Status.FAIL;

export const exportInvoiceDetailsSuccess = (state) =>
  state.analyticsInvoices.invoices.exportInvoiceDetails.data;
