import { combineReducers } from 'redux';

import tabs from './tabs/reducers';
import securityFeatures from './securityFeatures/reducers';
import exportToCsv from './exportToCsv/reducer';
import m2mAccounts from './m2mAccounts/reducer';
import selectedItems from './selectedItems/reducer';
import uploadTemplate from './uploadTemplate/reducer';
import downloadTemplate from './downloadTemplate/reducer';

export default combineReducers({
	tabs,
	securityFeatures,
	export: exportToCsv,
	m2mAccounts,
  selectedItems,
  uploadTemplate,
  downloadTemplate
});
