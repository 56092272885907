import React from 'react';
import { object, bool, string } from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import CustomFieldsValuesView from '../../../views/CustomFieldsValuesView';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { localizationHelper } from '../../../../../utils/helperFunctions';
import hoc from '../DataOverview';

import styles from './Mobile.scss';

const InfoFull = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const M2MAccountIDRWROComponent = UserAllowedToAccess([
	'mnc.endpoints.accountid_ro',
	'mnc.endpoints.accountid_rw'
]);
const M2MPlatformRWROComponent = UserAllowedToAccess([
	'mnc.endpoints.m2mplatform_ro',
	'mnc.endpoints.m2mplatform_rw'
]);

function Mobile({
	endpoint,
	customFieldLabels,
	isPendingStatus,
	endpointName,
  type,
  intl,
}) {
	const showRatePlanTooltip = () => (
		isPendingStatus ? (
			<div data-spec="tooltip-wrapper" className={styles.pending}>
				<FormattedMessage
					id="SINGLE_ENDPOINT.STATUS_CHANGE_PENDING"
					defaultMessage="There is a Scheduled Activity for this Endpoint"
				>
					{(formattedValue) => (
						<InfoFull
							data-tip={formattedValue}
							data-for="changeRatePlanTooltip"
							data-place="right"
						/>
					)}
				</FormattedMessage>
				<ReactTooltip
					className={styles.tooltip}
					type="light"
					id="changeRatePlanTooltip"
				/>
			</div>
		) : null
	);

	return (
		<div
			data-spec="data-overview-mobile"
			className={`${styles.data_wrap} ${styles.block_wrap}`}
		>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.ENDPOINT_NAME"
						defaultMessage="Endpoint ID"
					/>
				</span>
				<span>{endpoint.endpointName}</span>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage id="SINGLE_ENDPOINT.IMSI" defaultMessage="IMSI" />
				</span>
				<span>{endpoint.imsi}</span>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.MSISDN"
						defaultMessage="MSISDN"
					/>
				</span>
				<span>{endpoint.msisdn}</span>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.DUAL_MODE"
						defaultMessage="Dual Mode"
					/>
				</span>
				<span />
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.CONNECTED_DEVICE"
						defaultMessage="Connected Device"
					/>
				</span>
				<span />
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage id="SINGLE_ENDPOINT.IMEI" defaultMessage="IMEI" />
				</span>
				<span>{endpoint.imei}</span>
			</div>
			<div className={styles.data}>
				<span className={styles.status_span}>
					<FormattedMessage
						id="SINGLE_ENDPOINT.STATUS"
						defaultMessage="STATUS"
					/>
					<div className={styles.pending}>
						{isPendingStatus && (
							<FormattedMessage
								id="SINGLE_ENDPOINT.STATUS_CHANGE_PENDING"
								defaultMessage="There is a Scheduled Activity for this Endpoint"
							>
								{(formattedValue) => (
									<InfoFull
										data-tip={formattedValue}
										data-for="statusTooltip"
										data-place="right"
									/>
								)}
							</FormattedMessage>
						)}
						{isPendingStatus && (
							<ReactTooltip
								className={styles.tooltip}
								type="light"
								id="statusTooltip"
							/>
						)}
					</div>
				</span>
				<div
					className={cn(
						styles.status_col,
						styles[
							endpoint.status &&
								endpoint.status
									.toLowerCase()
									.replace(' ', '_')
									.replace('-', '_')
						],
						styles.status_col_res
					)}
				>
					{endpoint.status
						? intl
								.formatMessage({
									id: `SINGLE_ENDPOINT.${localizationHelper(endpoint.status)}`,
									defaultMessage: `${endpoint.status}`
								})
								.toUpperCase()
						: ''}
				</div>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.NETWORK_OPERATOR"
						defaultMessage="Network Operator"
					/>
				</span>
				<span>{endpoint.networkOperator}</span>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.NETWORK_COUNTRY"
						defaultMessage="Network Country"
					/>
				</span>
				<span>{endpoint.networkCountry}</span>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage id="SINGLE_ENDPOINT.APN" defaultMessage="APN" />
				</span>
				<span />
			</div>
			<div className={styles.data}>
				<span className={cn(styles.status_span, styles.entitlement)}>
					<FormattedMessage
						id="SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN"
						defaultMessage="Rate plan"
					/>
					{showRatePlanTooltip()}
				</span>
				<span>{endpoint.ratePlan}</span>
			</div>
			<div className={styles.data}>
				<span className={cn(styles.status_span, styles.entitlement)}>
					<FormattedMessage
						id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT"
						defaultMessage="Network Entitlement"
					/>
				</span>
				<span>{endpoint.networkEntitlementId}</span>
			</div>
			<M2MPlatformRWROComponent>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.SOURCE_PLATFORM"
							defaultMessage="M2M Platform"
						/>
					</span>
					<span>{endpoint.serviceProvider}</span>
				</div>
			</M2MPlatformRWROComponent>
			<M2MAccountIDRWROComponent>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.M2M_ACCOUNT_ID"
							defaultMessage="M2M Account ID"
						/>
					</span>
					<span>{endpoint.m2mAccountId}</span>
				</div>
			</M2MAccountIDRWROComponent>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.RECORDED_STATUS"
						defaultMessage="Recorded status"
					/>
				</span>
				<span />
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage
						id="SINGLE_ENDPOINT.ACTIVATION_DATE"
						defaultMessage="Activation date"
					/>
				</span>
				<span />
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage id="SHIPPED_DATE" defaultMessage="Shipped Date" />
				</span>
				<span />
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage id="SINGLE_ENDPOINT.TECH_TYPE" />
				</span>
				<span>{endpoint.techType || '-'}</span>
			</div>
			<div className={styles.data}>
				<span>
					<FormattedMessage id="SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE" />
				</span>
				<span>{endpoint.techtypeSubtype || '-'}</span>
			</div>
			<CustomFieldsValuesView
				endpointName={endpointName}
				type={type}
				endpoint={endpoint}
				customFieldLabels={customFieldLabels}
			/>
		</div>
	);
}

Mobile.propTypes = {
	endpoint: object,
	customFieldLabels: object,
	isPendingStatus: bool,
	type: string,
  endpointName: string,
  intl: object,
};
Mobile.defaultProps = {
	endpoint: {},
	customFieldLabels: {},
	isPendingStatus: false,
	type: '',
	endpointName: '',
};

export default hoc(injectIntl(Mobile));
