import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import CompaniesDesktop from './Desktop';
import CompaniesMobile from './Mobile';

import { getCompanies, getCompanyToken } from '../../redux/companies/actions';
import {
	getCompaniesRequest,
	getCompaniesFail,
	getCompaniesSuccess,
	getCompanyTokenRequest,
	getCompanyTokenSuccess
} from '../../redux/companies/selectors';
import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';
import changeCompanyInitalTabAction from '../../redux/companiesInitTab/actions';
import {
	getSimpleSearchParameter,
	getIsSimpleSearchActive
} from '../../../Shared/redux/simpleSearch/selectors';
import {
	getUser,
	getUserSettings
} from '../../../../redux/user/getUserData/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import { notificationMessages } from '../../utils/constants';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';
import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import { openFilterModal } from '../../../Shared/redux/filterModal/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import {
	setPaginationInit,
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';

import {
	anyModalOpened,
} from '../../../../redux/modals/globalModal/actions';

const mapStateToProps = (state) => ({
	companiesRequest: getCompaniesRequest(state),
	companiesFail: getCompaniesFail(state),
	companies: getCompaniesSuccess(state),
	simpleSearchParameter: getSimpleSearchParameter(state),
	filterData: filterDataSuccess(state),
	isSimpleSearchActive: getIsSimpleSearchActive(state),
	userSettings: getUserSettings(state, 8),
	user: getUser(state),
	getCompanyTokenRequest: getCompanyTokenRequest(state),
	getCompanyTokenSuccess: getCompanyTokenSuccess(state),
	searchParameter: getSearchParameter(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
});

const mapDispatchToProps = (dispatch) => ({
	getCompanies: (params) => dispatch(getCompanies(params)),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	goToAddAccount: (url) => dispatch(localizedRoute(url)),
	addNewCompany: (url) => dispatch(localizedRoute(url)),
	changeCompanyInitalTab: (params) => dispatch(changeCompanyInitalTabAction(params)),
	editCompany: (url) => dispatch(localizedRoute(url)),
	editUserSetting: (newValue, id) =>
		dispatch(updateUserSettings(newValue, id, notificationMessages)),
	createUserSettings: (newValue) => dispatch(createUserSettings(newValue)),
	getCompanyToken: (params) => dispatch(getCompanyToken(params)),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(CompaniesDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(CompaniesMobile);
