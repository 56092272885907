import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';
import globalCompanyInitTab from '../companiesInitTab/reducer';

import {
	ACTIVATE_COMPANY,
	GET_COMPANIES,
	GET_COMPANY,
	ADD_COMPANY,
	GET_COUNTRIES,
	GET_STATES,
	GET_ADDRESS_TYPES,
	GET_CONTACT_TYPES,
	GET_MY_COMPANY,
	EDIT_MY_COMPANY,
	GET_COMPANY_LANGUAGES,
	GET_COMPANY_TOKEN,
	CHANGE_INITAL_COMPANY_TAB,
	EDIT_COMPANY,
	VALIDATE_ADDRESS
} from './constants';

export default combineReducers({
	activateCompany: globalReducer(ACTIVATE_COMPANY),
	addCompany: globalReducer(ADD_COMPANY),
	getCompanies: globalReducer(GET_COMPANIES),
	getCompany: globalReducer(GET_COMPANY),
	getCountries: globalReducer(GET_COUNTRIES),
	getStates: globalReducer(GET_STATES),
	getAddressTypes: globalReducer(GET_ADDRESS_TYPES),
	getContactTypes: globalReducer(GET_CONTACT_TYPES),
	getMyCompany: globalReducer(GET_MY_COMPANY),
	editMyCompany: globalReducer(EDIT_MY_COMPANY),
	getCompanyLanguages: globalReducer(GET_COMPANY_LANGUAGES),
	getCompanyToken: globalReducer(GET_COMPANY_TOKEN),
	changeCompanyInitalTab: globalCompanyInitTab(CHANGE_INITAL_COMPANY_TAB),
	validateAddress: globalReducer(VALIDATE_ADDRESS),
	editCompany: globalReducer(EDIT_COMPANY),
});
