/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Loader from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import {
	batchReportsHeaderDesktop,
	batchReportsHeaderMobile
} from '../../utils/tableHeaders';
import styles from './BatchReports.scss';

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/downloadable-file-l.svg'
);

const reportCategory = 'BatchSummary';

const BatchReports = () => (WrappedComponent) => {
	class BatchReportsComponent extends PureComponent {
		constructor() {
			super();
			this.state = { selectedDates: [] };
		}

		componentDidMount() {
			this.getBatchReports();
		}

		componentDidUpdate() {
			if (this.state.selectedDates.length === 0 && this.props.batchReports
				&& this.props.batchReports.resultList && this.props.batchReports.resultList.length > 0) {
				const batchReports = this.processBatchData(this.props.batchReports.resultList);
				const initialSelectedDates = [];
				batchReports.forEach((report) => {
					const selectOptions = report.date ? report.date.map((date) => ({
						value: `${date.monthOfGeneration}/${date.dateOfGeneration}/${date.yearOfGeneration}`,
						label: `${date.monthOfGeneration}/${date.dateOfGeneration}/${date.yearOfGeneration}`,
						reportName: date.fullReportFileName,
					})) : [];

					const selectedDate = selectOptions && selectOptions.length > 0 ? selectOptions[0] : null;

					initialSelectedDates.push({ ...report, selectedDate });
				});

				this.setState({
					selectedDates: [...initialSelectedDates]
				});
			}
		}

		getBatchReports = () => {
			const { getBatchReports } = this.props;
			const fileType = 'report';

			const params = {
				fileType,
				fileCategory: reportCategory,
			};

			getBatchReports(params);
		};

		getOptions = (target) => {
			const options = {
				header: target === 'desktop' ? batchReportsHeaderDesktop : batchReportsHeaderMobile,
				tableOptions: {
					fullWidthFields: ['date'],
				},
				customComponents: {
					date: {
						type: 'custom',
						component: (report, index) => {
							const selectOptions = report.date ? report.date.map((date) => ({
								value: `${date.monthOfGeneration}/${date.dateOfGeneration}/${date.yearOfGeneration}`,
								label: `${date.monthOfGeneration}/${date.dateOfGeneration}/${date.yearOfGeneration}`,
								reportName: date.fullReportFileName,
							})) : [];

							const { selectedDates } = this.state;
							const currentValue = selectedDates && selectedDates
								.find((x) => x.reportName === report.reportName
									&& x.serviceProvider === report.serviceProvider
									&& x.m2mAccountId === report.m2mAccountId
									&& x.reportFrequency === report.reportFrequency);

							return <Select
								data-spec={`report_index_${index}`}
								name={report.reportName}
								value={currentValue ? currentValue.selectedDate : null}
								options={selectOptions}
								onChange={(e) => {
									const currentVal = selectedDates && selectedDates
										.find((x) => x.reportName === report.reportName
											&& x.serviceProvider === report.serviceProvider
											&& x.m2mAccountId === report.m2mAccountId
											&& x.reportFrequency === report.reportFrequency);
									if (currentVal) {
										currentVal.selectedDate = e;
										this.setState({
											selectedDates: [...selectedDates]
										});
									}
								}}
								menuPosition={'absolute'}
							/>;
						}
					},
					details: {
						type: 'custom',
						component: (report) => {
							const { selectedDates } = this.state;
							const { downloadReport } = this.props;
							const currentVal = selectedDates && selectedDates
								.find((x) => x.reportName === report.reportName
									&& x.serviceProvider === report.serviceProvider
									&& x.m2mAccountId === report.m2mAccountId
									&& x.reportFrequency === report.reportFrequency);

							const [month, day, year] = currentVal && currentVal.selectedDate ? currentVal.selectedDate.value.split('/') : [];
							return currentVal && currentVal.selectedDate ? (
								<div
									className={styles.color}
									onClick={() => {
										downloadReport(currentVal.selectedDate.reportName,
											currentVal.reportFrequency,
											currentVal.serviceProvider,
											year,
											month,
											day,
											reportCategory,
											currentVal.m2mAccountId);
									}}
								>
									<DocumentDownloadIcon
										data-spec="download-icon"
										className={styles.icon}
									/>
									<FormattedMessage id="DOWNLOAD" defaultMessage="Download" />
								</div>
							) : (
								<div className={cn(styles.color, styles.disabled)}>
									<DocumentDownloadIcon
										data-spec="download-icon"
										className={cn(styles.icon, styles.disabled)}
									/>
									<FormattedMessage id="DOWNLOAD" defaultMessage="Download" />
								</div>
							);
						}
					}
				}
			};
			return options;
		};

		processBatchData = (batchReports) => {
			let newBatchReports = [];

			batchReports &&
				batchReports.map((item) => (
						item.data &&
						item.data.forEach((data) => {
							newBatchReports = [
								...newBatchReports,
								{
									...data,
									reportName: item.reportName
								}
							];
						})
					));

			return newBatchReports;
		};

		render() {
			const {
				batchReportsRequest,
				batchReports,
				downloadReportRequest
      } = this.props;
			return (
				<div data-spec="batch-reports">
					{(batchReportsRequest || downloadReportRequest) && <Loader />}
					{!batchReportsRequest && !downloadReportRequest && batchReports && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
							batchReports={this.processBatchData(batchReports.resultList)}
						/>
					)}
					{batchReports && batchReports.resultList.length === 0 && (
						<NoData
							table
							title={
								<FormattedMessage
									id="BATCH_HISTORY.NO_BATCH_REPORTS_TITLE"
									defaultMessage="No Batch Reports Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE"
									defaultMessage="There are No Batch Reports for this Table to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { func, bool, shape, number } = PropTypes;

	BatchReportsComponent.propTypes = {
		getBatchReports: func,
		batchReportsRequest: bool,
		batchReports: shape(),
		dataLimit: number,
		downloadReportRequest: bool,
		downloadReport: func,
	};
	return BatchReportsComponent;
};

export default BatchReports;
