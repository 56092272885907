import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import SecurityFeaturesForm from './SecurityFeaturesForm';

import { getM2mAccounts } from '../../redux/m2mAccounts/actions';
import { getm2mAccountsRequest, getm2mAccountsFail, getm2mAccountsSuccess } from '../../redux/m2mAccounts/selectors';
import { selectedItems } from '../../redux/selectedItems/selectors';

const mapStateToProps = (state) => ({
  formValues: getFormValues('FormSecurity')(state),
  m2mAccountsRequest: getm2mAccountsRequest(state),
  m2mAccountsFail: getm2mAccountsFail(state),
	m2mAccounts: getm2mAccountsSuccess(state),
	initialValues: {
		itemFields: selectedItems(state)
	}
});
const mapDispatchToProps = (dispatch) => ({
  getM2mAccounts: () => dispatch(getM2mAccounts())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SecurityFeaturesForm);
