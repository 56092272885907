import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './SubAccountAccordion.scss';

class SubAccountAccordion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: []
		};
	}

	hasSubPlatforms = (level) =>
		Boolean(level && level.subPlatforms && level.subPlatforms.length);

	handleIconClick = (id) => {
		const { expanded } = this.state;
		if (expanded.includes(id)) {
			const index = expanded.indexOf(id);
			this.setState({
				expanded: [...expanded.slice(0, index), ...expanded.slice(index + 1)]
			});
		} else {
			this.setState({
				expanded: [...expanded, id]
			});
		}
	};

	findLevel = (id) => {
		const { expanded } = this.state;
		return expanded.includes(id);
	};

	renderData = (data, count = 1) => {
		const { isDisabledDropdown } = this.props;
		const { expanded } = this.state;
		return data.map((level) => {
			const { m2mAccountId, m2mAccountName, id } = level;
			const classnames = cn({
				[styles.level]: true
			});

			const triggerIcon = cn({
				[styles.trigger__icon]: true,
				[styles.no_subplatforms]: !this.hasSubPlatforms(level),
				[styles.open]: this.findLevel(id)
			});

			const paddingLeft = 20 * count;

			const getRender = Boolean(
				level.subPlatforms && level.subPlatforms.length && expanded.includes(id)
			);
			return [
				<React.Fragment key={`level-${level.id}`}>
					<div
						data-spec={`level-${level.id}`}
						key={level.id}
						className={classnames}
						style={{ paddingLeft }}
					>
						<span
							onClick={() => this.handleIconClick(id)}
							className={triggerIcon}
						/>
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="ONBOARDING.SUB_ACCOUNT_NAME"
									defaultMessage="Sub Account Name"
								/>
							</span>
							<span>{m2mAccountName}</span>
						</div>
						<div className={styles.divider} />
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="ONBOARDING.M2M_ACCOUNT_ID"
									defaultMessage="M2M Account ID"
								/>
							</span>
							<span>{m2mAccountId}</span>
						</div>
						<div className={styles.select_wrapper}>
							<div>
								<Select
									name="new-platform"
									options={this.getOptions()}
									onChange={() => this.onChange(level)}
									isDisabled={isDisabledDropdown}
								/>
							</div>
						</div>
					</div>
					{getRender && this.renderData(level.subPlatforms, count + 1)}
				</React.Fragment>
			];
		});
	};

	getOptions = () => [
		{
			label: <FormattedMessage id="EDIT" defaultMessage="Edit" />,
			value: 'edit'
		}
	];

	onChange = (val) => {
		const { goToEditSubAccount, parentAccountId, isMyCompany } = this.props;
		if (isMyCompany) {
			goToEditSubAccount(
				`/my-company/${val.id}/${val.m2mAccountId}/${parentAccountId}`
			);
		} else {
			goToEditSubAccount(
				`/companies/${val.companyId}/accounts/${val.id}/${val.m2mAccountId}/${parentAccountId}`
			);
		}
	};

	render() {
		const { data } = this.props;
		return <div data-spec="levels">{this.renderData(data, 1)}</div>;
	}
}

SubAccountAccordion.propTypes = {
	data: PropTypes.array,
	goToEditSubAccount: PropTypes.func,
	parentAccountId: PropTypes.string,
	isDisabledDropdown: PropTypes.bool,
	isMyCompany: PropTypes.bool
};

export default SubAccountAccordion;
