import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_DATA_DETAILS, GET_DATA_DETAILS_FILTERS } from './constants';

export default combineReducers({
	getDataDetails: globalReducer(GET_DATA_DETAILS),
	getDataDetailsFilters: globalReducer(GET_DATA_DETAILS_FILTERS)
});
