export default {
	// COMMON
	'ONBOARDING.EDIT': 'Modifier',
	'ONBOARDING.DELETE': 'Supprimer',
	'ONBOARDING.NAME': 'Nom',
	'ONBOARDING.DESCRIPTION': 'Description',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': 'Nom de compte',
	'ONBOARDING.FRIENDLY_NAME': 'Diminutif',
	'ONBOARDING.ACCOUNT_ID': 'ID compte',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Créer nouvelle société',
	'ONBOARDING.IOTC_CUSTOMERS': 'Clients IoT Console',
	'ONBOARDING.COMPANIES': 'Sociétés',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'Aucune société disponible',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': 'Aucune société à vous montrer actuellement pour cette table',
	'ONBOARDING.COMPANY_NAME': 'Nom de société',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'p. ex. : AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'Nom de compte CRM',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'Nom de compte machine-machine',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'Numéro de compte IOT-C',
	'ONBOARDING.SIM_ORDERS': 'Commandes de SIM',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Propriétaire technique', - supprimé pour le compte créé par
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Compte créé par',
	'ONBOARDING.VIEW_MC': 'Afficher MC',
	'ONBOARDING.DATE_CREATED': 'Date création',
	'ONBOARDING.ACTIONS': 'Actions',
	'ONBOARDING.PLATFORMS': 'Plateformes',
	'ONBOARDING.ACTIVATION_DENIED': 'Impossibilité de traiter la demande !',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Compte activé',
	'ONBOARDING.CONTACT_SYSADMIN': 'Adressez-vous à l’administrateur IoT Console pour obtenir de l’aide',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': 'Mise à jour réussie des paramètres de table des sociétés.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': 'Impossible de mettre à jour les colonnes de la table des sociétés. Veuillez réessayer.',
	'ONBOARDING.RETRY': 'Réessayer',

	// SEARCH MENU
	'ONBOARDING.BY': 'Par ',
	'ONBOARDING.BUSINESS_NAME': 'Nom d’entreprise',
	'ONBOARDING.CONTACT_PERSON': 'Contact',
	'ONBOARDING.PLATFORM_ID': 'ID plateforme',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'ID IoT Console : ',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'Comptes machine-machine société',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': 'Création de compte IoT Console réussie pour :',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': 'Numéro de passerelle IoT associé à ce compte :',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Créer compte de nouvelle société',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': 'Ne peut pas comprendre d’espaces ou de chiffres',
	'ONBOARDING.IDM_SERVCE_NAME': 'Nom service GID (Gestion de l’identité)',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Visible pour le client',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Entreprise ou entité juridique',
	'ONBOARDING.COMPANY_CONTACTS': 'Contacts dans la société',
	'ONBOARDING.ATT_CONTACTS': 'Contacts à AT&T',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'Type de prise en charge vTM',
	'ONBOARDING.FULL_TSM': 'TSM complet (tous les billets, indépendamment du niveau de gravité, vont à l’équipe TSM)',
	'ONBOARDING.PARTIAL_TSM': 'TSM partiel (les billets de niveau 1 vont à l’équipe TSM, les billets de niveau 2 et 3 vont à l’équipe hors ligne du centre d’assistance)',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care (tous les billets, indépendamment du niveau de gravité, vont à l’équipe IoT Priority Care)',
	'ONBOARDING.NO_SUPPORT': 'Aucune assistance (tous les billets, indépendamment du niveau de gravité, vont à l’équipe hors ligne du centre d’assistance)',
	'ONBOARDING.CONTACT': 'Contact',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Nom amical de société',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Nom d’entreprise légal',
	'ONBOARDING.COMPANY_CONTACT': 'Contact dans la société',
	'ONBOARDING.ATT_CONTACT': 'Contact à AT&T',
	'ONBOARDING.COMPANY': 'Société',
	'ONBOARDING.COUNTRY': 'Pays',
	'ONBOARDING.STATE_PROVINCE': 'État/Province',
	'ONBOARDING.STREET_ADDRESS': 'Adresse',
	'ONBOARDING.ADDRESS_LINE_1': 'Ligne d’adresse 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Ligne d’adresse 2 (facultatif)',
	'ONBOARDING.CITY_TOWN': 'Ville',
	'ONBOARDING.ZIP_POSTAL_CODE': 'Code postal',
	'ONBOARDING.COMPANY_DOMAIN': 'Domaine société',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'Domaine e-mail société',
	'ONBOARDING.FULL_NAME': 'Nom complet',
	'ONBOARDING.PHONE_NUMBER': 'Numéro de téléphone',
	'ONBOARDING.EMAIL_ADDRESS': 'Adresse e-mail',
	'ONBOARDING.SIM_OPTIONS': 'Options SIM',
	'ONBOARDING.ORDER_MOVE_SIMS': 'Commander ou transférer SIM',
	'ONBOARDING.ORDERING': 'Commande',
	'ONBOARDING.TRANSFER': 'Transfert',
	'ONBOARDING.SIM_TRANSFER': 'Transfert SIM',
	'ONBOARDING.TRANSFERING_SIM': 'Transfert d’une SIM d’un compte machine-machine à un autre',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': 'Commander les SIM, sélectionner ou téléverser liste des SIM',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'Afficher ou modifier les informations de facturation',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': 'Plateforme',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Description de la plateforme',
	'ONBOARDING.APNS': 'APN',
	'ONBOARDING.M2M_ACCOUNT_ID': 'ID compte machine-machine',
	'ONBOARDING.EDIT_ACTIONS': 'Modifier comptes',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'Modifier plateforme machine-machine',
	'ONBOARDING.ADD_M2M_PLATFORM': 'Ajouter plateforme machine-machine',
	'ONBOARDING.MC_ACCOUNT_ID': 'ID de compte IOT-C',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'Centre de contrôle ATT',
	'ONBOARDING.PLATFORM_CCIP': 'Programme d’intégration du centre de contrôle',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Plateforme de connexion d’appareils',
	'ONBOARDING.PLATFORM_GMNA': 'GM Amérique du Nord',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Entreprise',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': 'Plateforme machine-machine',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Droit d’utiliser le réseau',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Droits d’utiliser le réseau',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Nom de profil de communication',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': 'Aucun droit d’utiliser le réseau disponible',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': 'Aucun droit d’utiliser le réseau à vous montrer actuellement pour cette table',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Ajouter nouveau droit d’utiliser le réseau',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Ajouter droit d’utiliser le réseau',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Modifier droit d’utiliser le réseau',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Modifier droits d’utiliser le réseau',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Choisir droit d’utiliser le réseau',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Sélectionner droit',
	'ONBOARDING.PULL_DATA': 'Extraire données',
	'ONBOARDING.HLR_TEMPLATE': 'Modèle détails HLRHSS',
	'ONBOARDING.HOTLINE_NUMBER': 'Numéro d’urgence',
	'ONBOARDING.VOICE_MT': 'Voix arrivée',
	'ONBOARDING.ROAMING_LTE': 'Itinérance LTE',
	'ONBOARDING.DATA': 'Données',
	'ONBOARDING.DATA_LTE': 'Données LTE',
	'ONBOARDING.VOICE_MO': 'Voix départ',
	'ONBOARDING.INTERNATIONAL_VOICE': 'Voix international',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': 'Bloquer SMS de départ international sauf pays d’accueil',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Bloquer numéros surfacturés',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Type de service Camel',
	'ONBOARDING.APNS_PDPINDEX': 'Indice PDP',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'IP fixe',
	'ONBOARDING.APNS_SECURITY': 'Sécurité',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Abonnement',
	'ONBOARDING.ADD_NEW_APN': 'Ajouter nouvel APN',
	'ONBOARDING.PLATFORM_DETAILS': 'Détails plateforme',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Itinérance',
	'ONBOARDING.SMS_MO': 'SMS départ',
	'ONBOARDING.SMS_MT': 'SMS arrivée',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Droit d’utiliser le réseau admissible',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': 'Type paiement',
	'ONBOARDING.USAGE_LIMIT': 'Limite utilisation',
	'ONBOARDING.RATE_PLAN': 'Forfait',
	'ONBOARDING.RATE_PLANS': 'Forfaits',
	'ONBOARDING.RATE_PLAN_NAME': 'Nom de forfait',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'Aucun forfait disponible',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Ajouter nouveau forfait',
	'ONBOARDING.EDIT_RATE_PLAN': 'Modifier forfait',
	'ONBOARDING.PLAN_ID': 'ID forfait',
	'ONBOARDING.RATE_PLAN_TYPE': 'Type de forfait',
	'ONBOARDING.ACCOUNT_CHARGE': 'Frais de compte',
	'ONBOARDING.TERM': 'Période',
	'ONBOARDING.TIERING_FLAG': 'Indicateur de niveau',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'Frais mensuels par abonné',
	'ONBOARDING.CURRENCY': 'Devise',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Utilisation données incluse',
	'ONBOARDING.USAGE_LIMIT_MB': 'Limite utilisation (Mo)',
	'ONBOARDING.PLAN_TYPE': 'Type forfait',
	'ONBOARDING.TYPE': 'Type',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'FACTURER NOM DE COMPTE',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'ID FORFAIT MACHINE-MACHINE',
	'ONBOARDING.PRICE_PLAN_ID': 'ID FORFAIT',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'TYPE ARTICLE ABONNEMENT',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'ID ARTICLE ABONNEMENT MACHINE-MACHINE',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'ID COMPTE INTERNE MACHINE-MACHINE',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'NUMÉRO COMPTE MACHINE-MACHINE',
	'ONBOARDING.DEFAULT_PLAN': 'FORFAIT PAR DÉFAUT',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'ID fournisseur de services',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'ID droit d’utiliser le réseau',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': 'Aucun forfait à vous montrer actuellement pour cette table',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SKU SIM
	'ONBOARDING.SIM_SKU': 'UGS SIM',
	'ONBOARDING.DELETE_SIM_SKU': 'Supprimer UGS SIM',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': 'La suppression de cette USG supprimera toutes les commandes SIM associées. Voulez-vous vraiment continuer ?',
	'ONBOARDING.SIM_ORDERING': 'Commande de SIM',
	'ONBOARDING.CURRENT_SIM_SKUS': 'UGS SIM actuelle',
	'ONBOARDING.ADD_SIM_SKU': 'Ajouter UGS SIM',
	'ONBOARDING.EDIT_SIM_SKU': 'Modifier UGS SIM',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'Saisir UGS SIM ici',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'Saisir description UGS',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Restrictions du contrat',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': 'Veuillez définir les restrictions sur le processus de commande de SIM.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Nombre min. autorisé',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Nombre min.',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Nombre max. autorisé',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Nombre max.',
	'ONBOARDING.INCREMENT': 'Incrément',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Par période',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Sélectionner une période',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'nombre min.',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'nombre max.',
	'ONBOARDING.UNIT_PRICE': 'Prix unitaire',
	'ONBOARDING.ALLOW_TO_ORDER': 'Autoriser la commande',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'Modification UGS réussie',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'Impossible de modifier UGS',
	'ONBOARDING.RATE_PLAN_ID': 'ID forfait',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'Aucun Sku Sim disponible',

	// Platform
	'ONBOARDING.OPERATOR': 'Opérateur',
	'ONBOARDING.LAUNCH_MC': 'Lancer MC',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Opérateur (facultatif)',
	'ONBOARDING.MCC': 'Indicatif de pays de la station mobile (IPSM)',
	'ONBOARDING.MNC': 'Code de réseau mobile (CRM)',
	'ONBOARDING.POD': 'Pod',
	'ONBOARDING.MCC_TOOLTIP': 'L’indicatif de pays de la station mobile (IPSM) est un nombre à 3 chiffres qui désigne un pays.',
	'ONBOARDING.MNC_TOOLTIP': 'Le code de réseau mobile (CRM) est un nombre à 2 ou 3 chiffres qui désigne un réseau mobile.',
	'ONBOARDING.PLATFORM_SETUP': 'Configuration plateforme',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': 'La configuration de la plateforme sera possible après l’ajout réussi d’une plateforme. ',
	'ONBOARDING.SETTINGS': 'Paramètres',
	'ONBOARDING.EDIT_PLATFORM': 'Modifier plateforme',
	'ONBOARDING.ADD_PLATFORM': 'Ajouter plateforme',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'Aucune plateforme disponible',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Renseignements requis manquants',

	// Edit platform
	'ONBOARDING.SIM_LOADING': 'Chargement de SIM',
	'ONBOARDING.GATEWAY': 'Passerelle',
	'ONBOARDING.ENTER_GATEWAY': 'Saisir passerelle',
	'ONBOARDING.BILLING_INFORMATION': 'Renseignements sur la facturation',
	'ONBOARDING.BILLING_PLATFORM': 'Plateforme de facturation',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Facturer numéro de compte',

	// Reports
	'ONBOARDING.REPORT': 'Rapport',
	'ONBOARDING.REPORTS': 'Rapports',
	'ONBOARDING.COMPANY_REPORTS': 'Rapports',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'Erreur d’intégration GID (Gestion de l’identité)',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': 'Il semble y avoir problème avec l’intégration GID. ',
	'ONBOARDING.ONBOARDING_STATUS': 'État provisionnement',
	'ONBOARDING.SUCCESS': 'Réussi',
	'ONBOARDING.ERROR': 'Erreur',

	'ONBOARDING.MUST_BE_BETWEEN': 'Doit comprendre de ',
	'ONBOARDING.AND': ' à ',
	'ONBOARDING.CHARACTERS_LONG': ' caractères',
	'ONBOARDING.DEFAULT': 'Défaut',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Ajouter forfaits {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': 'Mise à jour réussie des paramètres des rapports de la société',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': 'Erreur lors de la mise à jour des rapports de la société',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': 'Définir les rapports à activer pour {companyName}',
	'ONBOARDING.REPORTS_SUBTITLE': 'Les rapports vérifiés seront affichés sur le portail IoT Console pour permettre aux utilisateurs de les télécharger',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': 'Ajouter les droits d’utiliser le réseau pour {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': 'Associer les droits d’utiliser le réseau pour ce compte avec le centre de contrôle AT&T.',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Adresse principale société',
	'ONBOARDING.COMPANY_DETAILS': 'Détails société',
	'ONBOARDING.ACCOUNTS': 'Comptes',
	'ONBOARDING.EDIT_ACCOUNT': 'Modifier compte',
	'ONBOARDING.VIEW_ACCOUNT': 'Afficher compte',
	'ONBOARDING.SIM_LOADING_SUB': 'Généralement, la passerelle IoT exécute automatiquement le chargement des SIM pour ce compte si le compte de la plateforme machine-machine est établi.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Importer à partir de la passerelle IoT',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Configuration de compte',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': 'Sélectionner les services applicables à activer dans ce compte. Si vous ne voyez pas le service souhaité, vous pouvez en ajouter un au niveau du compte de base, sous réserve du processus d’approbation. ',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Ajouter droits d’utiliser le réseau',
	'ONBOARDING.ADD_RATE_PLANS': 'Ajouter forfaits',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': 'Afficher ou modifier les rapports à présenter',
	'ONBOARDING.USERS': 'Utilisateurs',
	'ONBOARDING.USERS_SUB': 'Le fait de cliquer sur le bouton ci-dessous vous envoie à l’écran d’identité de votre société où vous pouvez inviter de nouveaux utilisateurs à rejoindre ce compte ou ajouter des utilisateurs existants à cette société.',
	'ONBOARDING.CREATE_USERS': 'Créer utilisateurs',
	'ONBOARDING.USER_ACCESS': 'Accès utilisateur',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Créer/ajouter utilisateur',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Vérifier les renseignements de la nouvelle société',
	'ONBOARDING.SUBMIT': 'Envoyer',
	'ONBOARDING.CREATE_COMPANY': 'Créer société',
	'ONBOARDING.NEW_ACCOUNT': 'Nouveau compte',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': 'Un compte ou sous-compte doit exister sur la plateforme machine-machine avant de pouvoir être ajouté au CRM. Exécutez les étapes ci-dessous pour créer un compte machine-machine à provisionner dans IoT Console. ',
	'ONBOARDING.VALIDATE': 'Valider',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Valider nom de compte',
	'ONBOARDING.CREATE_ACCOUNT': 'Créer compte',
	'ONBOARDING.ADD_ACCOUNT': 'Ajouter compte',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'Aucun compte disponible',
	'ONBOARDING.M2M_PLARFORM': 'Plateforme machine-machine',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'Création UGS réussie',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'Impossible de créer UGS',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': 'Modifier droits d’utiliser le réseau pour {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Modifier sous-compte',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'Afficher sous-compte',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Modifier forfaits pour {companyName}',
	'ONBOARDING.VIEW': 'Afficher',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'Afficher droits d’utiliser le réseau',
	'ONBOARDING.VIEW_RATE_PLANS': 'Afficher forfaits',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Créer sous-compte',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'Nouveau sous-compte pour {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'Générer compte machine-machine',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Nom de sous-compte',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': 'Sélectionner forfaits pour ce compte',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': 'Sélectionner droits d’utiliser le réseau pour ce compte',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': 'Avertissement : Les pays autres que les É.U. ne sont pas pris en charge pour l’instant',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Affecter au sous-compte',
	'ONBOARDING.ENDPOINT_ID': 'ID point terminal',
	'ONBOARDING.NETWORK_OPERATOR': 'Opérateur réseau',
	'ONBOARDING.STATUS': ' État',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': ' les points terminaux seront transférés à un compte',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Confirmer transfert points terminaux',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': 'Lorsque vous lancerez ce transfert, les points terminaux sélectionnés seront transférés en quelques minutes. Souhaitez-vous exécuter cette action ?',
	'ONBOARDING.ENDPOINTS': 'Points terminaux',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'Transférer au sous-compte',
	'ONBOARDING.FROM': 'De',
	'ONBOARDING.TO': 'À',
	'ONBORDING.SIM_SKU': 'UGS SIM',
	'ONBORDING.UNIT_PRICE': 'Prix unitaire',
	'ONBORDING.DATE_SUBMITED': 'Date d’envoi',
	'ONBORDING.EDIT_DELETE_SKU': 'Modifier/Supprimer UGS',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'Aucun point terminal disponible',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'Aucune SIM disponible',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'Sélectionner SIM pour ce compte',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': 'Aucun compte à vous montrer actuellement pour cette table',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': 'Mensuel',
	'ONBOARDING.PREPAID': 'Prépayé',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Mensuel – Abonné individuel',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Mensuel – Groupe fixe – Unique',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Essentiel – Groupe fixe mensuel',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Mensuel – Groupe flexible',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': 'Essentiel – Groupe flexible mensuel',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': 'Mensuel – Niveau d’utilisation groupe flexible',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Mensuel – Complémentaire',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Prépayé – Abonné individuel',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Prépayé – Groupe flexible',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Prépayé – Groupe fixe',
	'ONBOARDING.PREPAID_ADD_ON': 'Prépayé – Supplément',
	'ONBOARDING.PREPAID_EVENT': 'Prépayé – Événement',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Prépayé – Événement empilé',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': 'Détails utilisation données',
	'ONBOARDING.SMS_USAGE_DETAILS': 'Détails utilisation SMS',
	'ONBOARDING.IMSI_SNAPSHOT': 'Aperçu IMSI',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Répertoire abonné',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Aperçu abonné',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Changements abonné',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Aperçu quotidien du compte',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Aperçu forfait',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Aperçu forfait communication',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Référence cycle de facturation',
	'ONBOARDING.CARRIER_REFERENCE': 'Référence opérateur',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Référence type de forfait',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'Référence changement SIM',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': 'Référence cause fermeture relevé d’utilisation',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Aperçu zone forfait',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Champs personnalisés fournisseur de services',
	'ONBOARDING.USER_SNAPSHOT': 'Aperçu utilisateur',
	'ONBOARDING.NEW_SUBSCRIBERS': 'Nouveaux abonnés',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Abonnés combinés',
	'ONBOARDING.COMBINED_USAGE': 'Utilisation combinée',
	'ONBOARDING.APN_SNAPSHOT': 'Aperçu APN',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Champs personnalisés fournisseur de services',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Comptes combinés',
	'ONBOARDING.DAILY_USAGE_RAW': 'Utilisation brute quotidienne',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'Toutes les SIM actives',
	'ONBOARDING.DAILY_USAGE': 'Utilisation quotidienne',
	'ONBOARDING.ALL_SMS_MESSAGES': 'Tous les messages SMS',
	'ONBOARDING.SMS_DETAIL': 'Détails SMS',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'Synthèse cumulative d’identifiant de carte SIM',
	'ONBOARDING.SIM_STATE_REFERENCE': 'Référence état SIM',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Détails utilisation voix',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'Toutes les SIM affectées',
	'ONBOARDING.INVOICE_DETAIL': 'Détails facture',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Synthèse forfait',
	'ONBOARDING.OTHER_CHARGES': 'Autres frais',
	'ONBOARDING.USAGE_SUMMARY': 'Synthèse d’utilisation',
	'ONBOARDING.ACTIVATION_CHARGES': 'Frais activation',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Détails utilisation zone forfait',
	'ONBOARDING.INVOICE_CHARGES': 'Frais facture',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Frais utilisation groupée',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Frais abonné',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Frais utilisation non groupée',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Utilisation vente en gros abonné',
	'ONBOARDING.FUNDED_PACKAGES': 'Forfaits financés',
	'ONBOARDING.TRIAL_COST_SHARE': 'Frais partagés d’essai',
	'ONBOARDING.REVENUE_SHARE': 'Recettes partagées',
	'ONBOARDING.BOUNTY': 'Prime',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Détails utilisation données',
	'ONBOARDING.SMS_DETAILS': 'Détails SMS',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Différence abonnements',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Messages SMS non délivrés',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': 'Aucune recette vente en gros abonné',
	'ONBOARDING.INVOICE_DETAILS': 'Détails facture',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Détails utilisation zone forfait',
	'ONBOARDING.BILLING_INVOICE': 'Facture',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1 mois',
	'ONBOARDING.3_MONTHS': '3 mois',
	'ONBOARDING.1_WEEK': '1 semaine',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Transfert de compte réussi',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Échec du transfert de compte',
	'ONBOARDING.MY_COMPANY': 'Ma société',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': 'Groupes d’utilisateurs',
	'ONBOARDING.USER_COUNT': 'Nombre d’utilisateurs',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Utilisateurs actuellement actifs',
	'ONBOARDING.FIRST_NAME': 'Prénom',
	'ONBOARDING.LAST_NAME': 'Nom',
	'ONBOARDING.JOB_TITLE': 'Profession',
	'ONBOARDING.EMAIL': 'E-mail',
	'ONBOARDING.USER_GROUP': 'Groupe d’utilisateurs',
	'ONBOARDING.ACCOUNT_ACCESS': 'Accès au compte',
	'ONBOARDING.CREATE_NEW_GROUP': 'Créer nouveau groupe',
	'ONBOARDING.INVITE_USERS': 'Inviter utilisateurs',
	'ONBOARDING.ADD_USERS': 'Ajouter utilisateurs',
	'ONBOARDING.EDIT_USER_PROFILE': 'Modifier profil d’utilisateur',
	'ONBOARDING.USER_INFORMATION': 'Renseignements sur l’utilisateur',
	'ONBOARDING.ADDRESSES': 'Adresses',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Comptes attribués',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Droit et réglementation',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Créer nouveau groupe d’utilisateurs',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': 'Créer groupes de droits d’accès personnalisés pour vos utilisateurs.',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name} {lastName} a accepté',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'Contrat de licence d’utilisateur final',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': 'Si vous n’acceptez pas les conditions du CLUF (Contrat de licence d’utilisateur final), vous pouvez supprimer ce compte.',
	'ONBOARDING.ON_DATE': 'le {date}.',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': 'Si vous n’acceptez pas les conditions du CLUF (Contrat de licence d’utilisateur final), vous pouvez supprimer ce compte.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': 'Saisissez les adresses e-mail des utilisateurs que vous souhaitez inviter. Les utilisateurs peuvent avoir accès au compte de la société dès qu’ils acceptent le lien d’invitation fourni dans la notification par e-mail.',
	'ONBOARDING.ADD_NEW_USER': 'Ajouter nouvel utilisateur',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Peut recevoir SMS',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': 'État/Province/Territoire/Région',
	'ONBOARDING.COMPANY_EMAIL': 'E-mail société',
	'ONBOARDING.POSTAL_CODE': 'Code postal',
	'ONBOARDING.BUSINESS': 'Entreprise',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': 'La protection des données personnelles est importante à nos yeux, et nous ne vendons vos données d’utilisateur à personne.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': 'Le CLUF (Contrat de licence d’utilisateur final) contient des informations détaillées sur la façon dont nous protégeons vos données personnelles, sur votre droit à l’effacement et autres règles de conformité.',
	'ONBOARDING.STREET_ADDRESS_2': 'Adresse 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Adresse 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'Ville ou commune',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'Même adresse que l’adresse principale',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Ajouter une autre adresse',
	'ONBOARDING.YES': 'Oui',
	'ONBOARDING.NO': 'Non',
	'ONBOARDING.PRIMARY_ADDRESS': 'Adresse principale',
	'ONBOARDING.ADDRESS_TYPE': 'Type d’adresse',
	'ONBOARDING.SECONDARY_NUMBER': 'Numéro auxiliaire',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Langue préférée',
	'ONBOARDING.PRIMARY_NUMBER': 'Numéro principal',
	'ONBOARDING.UPLOAD_IMAGE': 'Téléverser image',
	'ONBOARDING.FILTER_USER_LIST': 'Filtrer liste d’utilisateurs',
	'ONBOARDING.CANCEL': 'Annuler',
	'ONBOARDING.ADD': 'Ajouter',
	'ONBOARDING.USER': 'Utilisateur',
	'ONBOARDING.GROUP': 'Groupe',
	'ONBOARDING.PASSWORD_RESET': 'Réinitialisation mot de passe',
	'ONBOARDING.SELECT_ACTION': 'Sélectionner action',
	'ONBOARDING.ACTIVE_STATUS': 'État actif',
	'ONBOARDING.PENDING_STATUS': 'État en attente',
	'ONBOARDING.ADD_MORE_USERS': 'Ajouter plus d’utilisateurs',
	'ONBOARDING.ENTITLEMENT_NAME': 'Nom droit',
	'ONBOARDING.NO_ACCESS': 'Aucun accès',
	'ONBOARDING.READ_ONLY': 'Lecture seule',
	'ONBOARDING.READ_WRITE': 'Lecture-écriture',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Permissions groupe administration',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Modifier permissions groupe',
	'ONBORDING.USER_GROUPS': 'Groupes d’utilisateurs',
	'ONBOARDING.CREATE_GROUP': 'Créer groupe',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Sélectionner groupe d’utilisateurs existant',
	'ONBOARDING.GROUP_NAME': 'Nom du groupe',
	'ONBOARDING.PAUSE': 'Désactivation temporaire',
	'ONBOARDING.REACTIVATE': 'Réactiver',
	'ONBOARDING.ACTIVATE': 'Activer',
	'ONBOARDING.REMOVE': 'Supprimer',
	'ONBOARDING.RESEND_INVITE': 'Renvoyer invitation',
	'ONBOARDING.CANCEL_INVITE': 'Annuler invitation',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': 'Voulez-vous vraiment supprimer l’utilisateur ?',
	'ONBOARDING.USER_PROFILE': 'Profil d’utilisateur',
	'ONBOARDING.SUSPEND': 'Suspendre',
	'ONBOARDING.USER_MANAGEMENT': 'Gestion utilisateur',
	'ONBOARDING.MAILING': 'Publipostage',
	'ONBOARDING.BILLING': 'Facturation',
	'ONBOARDING.SECONDARY': 'Auxiliaire',
	'ONBOARDING.PRIMARY': 'Principal',
	'ONBOARDING.PHONE': 'Téléphone',
	'ONBOARDING.ENGLISH': 'Anglais (États-Unis)',
	'ONBOARDING.SAVE_USER': 'Enregistrer utilisateur',
	'ONBOARDING.BULK_UPLOAD': 'Téléversement groupé',
	'ONBOARDING.FOR_BULK_UPLOADS': 'pour téléversements groupés',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'Télécharger modèle CSV',
	'ONBOARDING.SEND_INVITE': 'Envoyer invitation',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': 'Les utilisateurs temporairement désactivés perdront l’accès aux comptes {companyName}. Les utilisateurs temporairement désactivés ne sont pas autorisés à demander un accès, mais l’administrateur peut réactiver le compte d’utilisateur ; afin de regagner l’accès, les utilisateurs temporairement désactivés doivent réinitialiser leur mot de passe de compte.',
	'ONBOARDING.PAUSE_USER': 'Désactiver temporairement un utilisateur',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Groupe d’utilisateurs attribué',
	'ONBOARDING.ACCESS_LEVEL': 'Niveau d’accès',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': 'Envoyer lien de réinitialisation de mot de passe vers e-mail',
	'ONBOARDING.EDIT_USER': 'Modifier utilisateur',
	'ONBOARDING.RESET_PASSWORD': 'Réinitialiser mot de passe',
	'ONBOARDING.CURRENT_STATUS': 'État actuel',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'Afficher permissions attribuées à l’utilisateur',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Gérer permissions individuelles',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Permissions individuelles',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Permissions effectives',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Groupes d’utilisateurs attribués',
	'ONBOARDING.ACTION': 'Action',
	'ONBOARDING.DELETE_PERMISSIONS': 'Supprimer permissions',
	'ONBOARDING.VIEW_PERMISSIONS': 'Afficher permissions',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Ajouter un autre groupe d’utilisateurs',
	'ONBOARDING.PENDING_INVITATIONS': 'Invitations en attente',
	'ONBOARDING.PERMISSIONS': 'Permissions',
	'ONBOARDING.CLOSE': 'Fermer',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Enregistrer permissions individuelles',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': 'L’accès sera accordé aux comptes machine-machine suivants',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Gestion de l’accès',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Utilisateurs du fournisseur de services',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Groupes d’utilisateurs du fournisseur de services',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': 'Ajouter les utilisateurs du fournisseur de services pour pouvoir gérer les comptes clients et provisionner les nouveaux.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'Aucun groupe d’utilisateurs disponible',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': 'Si vous devez effectuer une opération comportant des restrictions, demandez de l’aide à votre consultant technique.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': 'Certaines configurations sont réservées aux « super utilisateurs » AT&T.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': 'Les groupes d’utilisateurs sont constitués en utilisant un modèle offrant le moins de privilèges.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'Droits à l’échelle du système',
	'ONBOARDING.DENY_ACCESS': 'Refuser l’accès',
	'ONBOARDING.ALLOW_READ': 'Permettre la lecture',
	'ONBOARDING.ALLOW_WRITE': 'Permettre l’écriture',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Modifier permissions groupe',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'Aucune permission disponible',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': 'Aucun droit d’utiliser le réseau à vous montrer actuellement pour cette table',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'Modifier comptes machine-machine',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'Transférer au sous-compte',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Peut recevoir e-mail',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Accès sans restrictions',
	'ONBOARDING.ACTIVE': 'Actif', // delete later
	'ONBOARDING.PAUSED': 'Désactivé temporairement', // delete later
	'ONBOARDING.VIEW_USER_GROUP': 'Afficher groupe d’utilisateurs',
	'ONBOARDING.DELETE_USER_GROUP': 'Supprimer groupe d’utilisateurs',
	'ONBOARDING.SAVE': 'Enregistrer',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': 'Sélectionner le groupe auquel l’utilisateur doit appartenir :',

	'ONBOARDING.ALLOW_ACCESS': 'Permettre accès',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Créer nouveau mot de passe',
	'ONBOARDING.NEW_PASSWORD': 'Nouveau mot de passe',
	'ONBOARDING.CONFIRM_PASSWORD': 'Confirmer nouveau mot de passe',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Saisir nouveau mot de passe',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Veuillez saisir votre nouveau mot de passe',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Retour à la connexion',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'Informations non fournies.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': 'pour ajouter l’adresse.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Modifier comptes attribués',
	'ONBOARDING.EDIT_PERMISSIONS': 'Modifier permissions',
	'ONBOARDING.SUSPENDED': 'Suspendu',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Invitation en attente disponible',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': 'À l’étape suivante, vous pourrez régler les permissions du nouveau groupe',
	'ONBOARDING.GRANT_ACCESS': 'Accorder accès',
	'ONBOARDING.SELECT_USER_GROUP': 'Veuillez sélectionner un groupe d’utilisateurs',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': 'Aucune invitation système disponible',
	'ONBOARDING.ADD_USER_GROUP': 'Ajouter groupe d’utilisateurs',
	'ONBOARDING.ADD_ADDRESS': 'Ajouter adresse',
	'ONBOARDING.CROP_IMAGE': 'Recadrer image',
	'ONBOARDING.CROP': 'Recadrage',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': 'Cette action est irréversible, et tous les historiques de l’utilisateur seront oubliés par nos systèmes.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': 'La suppression du profil entraînera la suppression de l’accès utilisateur à tous les comptes auxquels vous avez actuellement accès.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Supprimer {name} {lastName} profil',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Saisir la phrase de confirmation',
	'ONBOARDING.DELETE_PROFILE': 'Supprimer profil',
	'ONBOARDING.DELETE_USER': 'Supprimer utilisateur',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': 'Justification de la désactivation temporaire (facultatif)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': 'Voulez-vous vraiment continuer ?',
	'ONBOARDING.REACTIVATE_USER': 'Réactiver utilisateur',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': 'Si vous cliquez oui, vous recevrez un e-mail de réinitialisation de mot de passe prochainement.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Réinitialiser mot de passe utilisateur',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': 'Voulez-vous vraiment continuer la réinitialisation du mot de passe ?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Attribuer groupe d’utilisateurs',
	'ONBOARDING.ASSIGN': 'Attribuer',
	'ONBOARDING.AND_OTHERS': 'Et {totalCount} autres...',
	'ONBOARDING.MY_PROFILE': 'Mon profil',
	'ONBOARDING.EDIT_USER_GROUP': 'Modifier groupe d’utilisateurs',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Modifier permissions individuelles',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'Afficher permissions individuelles',
	'ONBOARDING.NO_USERS_AVAILABLE': 'Aucun utilisateur disponible',
	'ONBOARDING.CONTACTS': 'Contacts',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': 'Voulez-vous vraiment effectuer cette opération ?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Supprimer groupe d’utilisateurs',
	'ONBOARDING.CONTACT_TYPE': 'Type de contact',
	'ONBOARDING.CANCEL_INVITATION': 'Annuler invitation',
	'ONBOARDING.RESEND_INVITATION': 'Renvoyer invitation',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Réactiver groupe d’utilisateurs',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Suspendre groupe d’utilisateurs',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': 'Aucun groupe d’utilisateurs à attribuer',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'Type d’adresse existant',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Ajouter nouvelle adresse',
	'ONBOARDING.ADD_NEW_CONTACT': 'Ajouter nouveau contact',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Ajouter un autre contact',
	'ONBOARDING.COMPANY_FEATURES': 'Caractéristiques de la société',
	'ONBOARDING.INFO_NOT_PROVIDED': 'Informations non fournies',
	'ONBOARDING.COMPANY_SETTINGS': 'Paramètres de la société',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': 'Si vous possédez déjà un compte, veuillez procéder à la connexion.',
	'ONBOARDING.ACCOUNT_ACTIVATED': 'Dans quelques instants, vous recevrez un e-mail comportant des instructions pour créer votre mot de passe et accéder à votre compte.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': 'La réinitialisation du mot de passe est gérée par AT&T Global Sign-On',

	//  Billing platform
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Address type
	'ONBOARDING.BILLING_ADDRESS': 'Adresse de facturation',
	'ONBOARDING.HOME_ADDRESS': 'Adresse domicile',

	//  Contact type
	'ONBOARDING.MAIN_CONTACT': 'Contact principal',
	'ONBOARDING.BACKUP_CONTACT': 'Contact de secours',
	'ONBOARDING.TECHNICAL_OWNER': 'Propriétaire technique',

	//  Address type
	'ONBOARDING.SHIPPING': 'Expédition',
	'ONBOARDING.HOME': 'Domicile',
	'ONBOARDING.ANOTHER': 'Autre',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'Lancer IoT Console',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': 'Ce numéro est requis pour afficher les factures dans IoT Console.',
	'ONBOARDING.BILLING_REPORTS': 'Rapports de facturation',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': 'Cette case doit être cochée pour visualiser la facture.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': 'Détails facturation non pris en charge pour cette plateforme.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': 'Afficher ou modifier les paramètres de facturation',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'Identifiant de compte non trouvé.',
	'ONBOARDING.ARE_YOU_SURE': 'Voulez-vous vraiment ?',
	'ONBOARDING.REMOVE_CONTACT': 'Supprimer contact',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'ID pod, locataire ou instance',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': 'Plateforme de connexion d’appareils',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': 'Voulez-vous vraiment effectuer cette action ?',

// ADDED POST 7.4
'ONBOARDING.ANONYMIZED': 'Anonymisé',
    'ONBOARDING.ACQUISITION_BOUNTY': 'Prime à l’acquisition',
    'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Prime à l’acquisition combinée',
    'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Subvention matérielle combinée',
    'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': 'Forfaits combinés financés par partenaire',
    'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Frais utilisation groupée combinés',
    'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Frais abonnement combinés',
    'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Frais partagés d’essai combinés',
    'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Synthèse d’utilisation combinée',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': 'Frais facture combinée mensuelle',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': 'Détail facture combinée mensuelle',
    'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': 'Utilisation non groupée combinée mensuelle facturée',
    'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Autres frais combinés mensuels',
    'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Forfaits financés par partenaire',
    'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'État appareil compte par mois',
    'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': 'Rapport d’utilisation de compte par réseau satellite',
};
