import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { orderBy } from 'lodash';
import cn from 'classnames';
import { endOfDay, startOfDay, isValid } from 'date-fns';

import ReactTooltip from 'react-tooltip';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import PageTitle from '../../../Shared/views/PageTitleView';
import ActionBar from '../../../Shared/views/ActionBarView';
import Loading from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';

import Card from '../../../Shared/components/Card';
import WrapperGridCard from '../../../Shared/components/WrapperGridCard';

import Button from '../../../../lib/DigitalComponents/Button';

import FilterModal from '../../../Shared/components/FilterModal';

import {
	TicketingTableHeader as tableHeader,
	TicketingTableHeaderMobile as tableHeaderMobile,
	ticketSignature,
	ticketingSearchMenu
} from '../../utils/constants';

import {
	onOrderClick,
  filterHelpers,
  newOnPageChange
} from '../../../../utils/tableHelpers';

import { getDateFormat, getFullDateTime } from '../../../../utils/constants';

import NotesModal from '../NotesModal';

import styles from './Ticketing.scss';
import { ComponentsRender } from '../../../../utils/AuthSelector';
import { localizationHelper } from '../../../../utils/helperFunctions';

import { getFilters as getTicketingFiltersService } from '../../services/TicketingService';

const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');
const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg'
);

const TicketingRWComponent = ComponentsRender('mnc.ticketing_rw');

const Ticketing = () => (WrappedComponent) => {
	class TicketingComponent extends Component {
		constructor(props) {
			super(props);
			this.state = {
				orderColumn: {},
				isNoteModalOpen: false,
				notes: '',
				chartSettings: [],
				settings: props.userSettings[0]
					? JSON.parse(props.userSettings[0].value)
          : {},
          isSearchActive: false
			};
		}

		componentDidMount() {
			const { createUserSettings, userSettings } = this.props;
			this.getTickets();

			if (!userSettings[0]) {
				createUserSettings({
					name: 'TableHeader',
					refTypeId: 6,
					value: JSON.stringify({
						signature: ticketSignature,
						header: tableHeader.map((item, index) => {
							item.order = index + 1;
							return item;
						})
					})
				});
				this.setState({
					settings: {
						signature: ticketSignature,
						header: tableHeader.map((item, index) => {
							item.order = index + 1;
							return item;
						})
					}
				});
			}
		}

		componentDidUpdate(prevProps) {
			const {
				severitiesAndStatuses,
				userSettings
			} = this.props;

      const { settings } = this.state;

			if (userSettings[0] && settings.signature !== ticketSignature) {
				this.updateUserSettings();
			}

			if (!prevProps.severitiesAndStatuses && severitiesAndStatuses) {
				this.updateChartSettings();
			}
		}

		componentWillUnmount() {
			const {
				getSeveritiesAndStatusesInit,
			} = this.props;
			getSeveritiesAndStatusesInit();
    }

    updateUserSettings = () => {
      const { patchUserSetting, userSettings } = this.props;
      const temp = {
				value: JSON.stringify({
					signature: ticketSignature,
					header: tableHeader.map((item, index) => {
						item.order = index + 1;
						return item;
					})
				})
			};

			patchUserSetting(temp, userSettings[0].id);
			this.setState({ settings: JSON.parse(temp.value) });
    }

    updateChartSettings = () => {
      const { messages, severitiesAndStatuses } = this.props;

      this.setState({
				chartSettings: [
					{
						groupByColumn: 'statusName',
						groupByDateId: 0,
						incrementBy: severitiesAndStatuses.statuses
							.map((status) => status.name)
							.join(','),
						id: { column: 'statusName', value: 'ticketStatusId' },
						name: messages.TOTAL_COUNT_BY_STATUS || 'TOTAL_COUNT_BY_STATUS',
						type: 'bar',
						w: 2
					},
					{
						groupByColumn: 'severityName',
						groupByDateId: 0,
						incrementBy: severitiesAndStatuses.severities
							.map((severity) => severity.name)
							.join(','),
						id: { column: 'severityName', value: 'ticketSeverityId' },
						name: messages.TOTAL_COUNT_BY_SEVERITY || 'TOTAL_COUNT_BY_SEVERITY',
						type: 'pie',
						w: 2
					}
				]
			});
    }

		pushBack = () => {
			const { pushBack } = this.props;
			pushBack();
		};

		onApply = () => {
			this.getTickets();
		};

		onChange = () => {
			this.getTickets();
		};

		clearFilters = () => {
			this.getTickets();
		};

		getTickets = (isExport = false) => {
			const realProps = {
				categories: 'ticketCategoryId',
				status: 'ticketStatusId',
				severity: 'ticketSeverityId'
			};

			const { orderColumn } = this.state;
			const {
				getTickets,
				getSeveritiesAndStatuses,
				dataLimit,
        filterData,
        dataOffset,
        searchParameter
      } = this.props;

			let simpleSearchParams = [];

			if (searchParameter) {
				if (
					['createdAt', 'updatedAt', 'externalCreatedAt'].includes(
						searchParameter.prop
					)
				) {
					if (isValid(new Date(searchParameter.propValue))) {
						simpleSearchParams = [
							{
								operator: '>=',
								prop: searchParameter.prop,
								propValue: startOfDay(
									new Date(searchParameter.propValue)
								)
							},
							{
								operator: '<=',
								prop: searchParameter.prop,
								propValue: endOfDay(
									new Date(searchParameter.propValue)
								)
							}
						];
					} else {
						simpleSearchParams = [
							{
								operator: '>=',
								prop: searchParameter.prop,
								propValue: ''
							},
							{
								operator: '<=',
								prop: searchParameter.prop,
								propValue: ''
							}
						];
					}
				} else {
					simpleSearchParams = [
						{
							operator: 'iLike',
							prop: searchParameter.prop,
							propValue: `${searchParameter.propValue}%`
						}
					];
				}
			}
			let filterParams = [];
			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}

			const params = {
				searchParams: [...simpleSearchParams, ...filterParams],
				additionalParams: {
					dataLimit: dataLimit || 50,
					dataOffset,
					dataSort: orderColumn.apiString || 'severityName asc'
				}
			};
			if (isExport) {
				return params;
			}
			getTickets(params);
      getSeveritiesAndStatuses({});
			if (searchParameter) {
				this.setState({
					isSearchActive: true
				});
			} else {
				this.setState({
					isSearchActive: false
				});
			}
		};

		totalCount = () => {
			const { tickets, dataLimit } = this.props;
			return Math.ceil(tickets.totalCount / dataLimit);
		};

		onDragEnd = async (fromIndex, toIndex) => {
			const { patchUserSetting, userSettings } = this.props;
			if (toIndex < 0) return;

			const data = await this.getTableHeader();
			[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];
			const temp = {
				value: JSON.stringify({
					signature: ticketSignature,
					header: data.map((item, index) => {
						item.order = index + 1;
						return item;
					})
				})
			};

			patchUserSetting(temp, userSettings[0].id);
			this.setState({ settings: JSON.parse(temp.value) });
		};

		onNoteClick = (notes) => {
			this.setState({ notes, isNoteModalOpen: true });
		};

		closeNoteModal = () => {
			this.setState({
				isNoteModalOpen: false,
				notes: ''
			});
		};

		renderExternalTicketId = (val) => (
			<span
				data-spec="external-ticket-id"
				className={styles.ticket_id}
				onClick={() => this.onCellClick(val.id)}
			>
				{val.externalTicketId}
			</span>
		);

		renderCategory = (val) => (
			<span data-spec="category">
				<FormattedMessage
					id={`TICKETING.CATEGORY_${localizationHelper(
						val.categoryName
					).toUpperCase()}`}
					defaultMessage={val.categoryName}
				/>
			</span>
		);

		renderCreatedAt = (val) => getDateFormat(val.createdAt);

		lastUpdatedBy = (val) => <div data-spec="user">{val.user || ''}</div>;

		lastUpdatedDate = (val) => (
			<div data-spec="external-created-at">
				{val.externalCreatedAt ? getFullDateTime(val.externalCreatedAt) : ''}
			</div>
		);

		onCellClick = (id) => {
			const { goToTicketDetails } = this.props;
			goToTicketDetails(id);
		};

		renderTicketStatus = (val) => (
			<span data-spec="ticketStatus">
				<FormattedMessage
					id={`TICKETING.STATUS_${localizationHelper(
						val.statusName
					).toUpperCase()}`}
					defaultMessage={val.statusName}
				/>
			</span>
		);

		renderLastUpdate = (val) => getDateFormat(val.updatedAt);

		renderSeverity = (val) => (
			<div
				data-spec="ticketSeverity"
				className={cn(
					styles.severity_status,
					styles[val.severityName.toLowerCase()]
				)}
			>
				<FormattedMessage
					id={`TICKETING.SEVERITY_${localizationHelper(
						val.severityName
					).toUpperCase()}`}
					defaultMessage={val.severityName}
				/>
			</div>
		);

		renderSubmissionLocation = (val) => (
			<span data-spec="submissionLocation">{val.deviceLocationCity}</span>
		);

		renderNote = (val) => (
			<FormattedMessage id="TICKETING.VIEW_NOTE" defaultMessage="View Note">
				{(formattedValue) =>
					(val.notes ? (
						<div
							className={styles.view_note}
							onClick={() => this.onNoteClick(val.notes)}
						>
							{formattedValue}
						</div>
					) : (
						''
					))}
			</FormattedMessage>
		);

		getTableHeader = () => {
			const { userSettings } = this.props;
			const { settings } = this.state;

			return !userSettings[0]
				? tableHeader
				: orderBy(settings.header, ['order'], ['asc']);
		};

		// ON SEARCH SUBMIT
		// ON SEARCH SUBMIT
		onSearchSubmitCallback = () => {
			this.getTickets();
		};

    onPageChange = async (data) => {
      const { setDataOffset, setSelectedPage, dataLimit } = this.props;
			const { selected } = data;
			await setDataOffset(dataLimit * selected);
			await setSelectedPage(selected);
			this.getTickets();
    }

		getOptions = (target) => {
			const { orderColumn } = this.state;
			const {
				dataLimit,
				tickets,
				selectedPage,
				setDataOffset,
				setSelectedPage
			} = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			if (isDesktop) {
				options.header = this.getTableHeader();
			}

			if (isMobile) {
				options.header = tableHeaderMobile;
			}

			options.tableOptions = {
				excludeDraggableColumns: ['externalTicketId'],
				orderByData: ['all'],
				showPagination: tickets && tickets.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				getOrderData: (data) => onOrderClick(data, this.getTickets, this),
				orderColumn,
				onPageChange: (data) =>
					newOnPageChange(
						data,
						this.getTickets,
						dataLimit,
						setDataOffset,
						setSelectedPage
					)
			};

			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions,
					firstColumnMarked: true,
					fixedFirstColumn: true,
					orderByData: ['all'],
					draggable: true,
					onDragEnd: (fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex)
				};

				options.customComponents = {
					externalTicketId: {
						type: 'custom',
						component: (val) => this.renderExternalTicketId(val)
					},
					categoryName: {
						type: 'custom',
						component: (val) => this.renderCategory(val)
					},
					createdAt: {
						type: 'custom',
						component: (val) => this.renderCreatedAt(val)
					},
					statusName: {
						type: 'custom',
						component: (val) => this.renderTicketStatus(val)
					},
					updatedAt: {
						type: 'custom',
						component: (val) => this.renderLastUpdate(val)
					},
					severityName: {
						type: 'custom',
						component: (val) => this.renderSeverity(val)
					},
					deviceLocationCity: {
						type: 'custom',
						component: (val) => this.renderSubmissionLocation(val)
					},
					notes: {
						type: 'custom',
						component: (val) => this.renderNote(val)
					},
					user: {
						type: 'custom',
						component: (val) => this.lastUpdatedBy(val)
					},
					externalCreatedAt: {
						type: 'custom',
						component: (val) => this.lastUpdatedDate(val)
					}
				};
			}

			if (isMobile) {
				options.tableOptions = {
					...options.tableOptions,
					mobileRowClick: (val) => this.onCellClick(val.id)
				};
				options.customComponents = {
					categoryName: {
						type: 'custom',
						component: (val) => this.renderCategory(val)
					}
				};
			}

			return options;
		};

		exportCsvFile = () => {
			const { getExportCsv, messages } = this.props;
			const { settings } = this.state;
			const exportRequest = [];
			settings.header.forEach((header) => {
				exportRequest.push({
					label: messages[`TICKETING.${header.title}`] || header.title,
					value: header.name
				});
			});
			const params = this.getTickets(true);
			const exportCsvParams = {
				searchParams: params.searchParams,
				additionalParams: {
					dataSort: params.additionalParams.dataSort,
					exportHeaders: exportRequest
				}
			};

			const fileRequest = {
				service: 'ticketing',
				fileName: `ticketsExport${Date.now()}.csv`,
				transactionType: 'exportTicketing',
				additionalData: exportCsvParams
			};
			getExportCsv(fileRequest);
    };

		render() {
			const {
				messages,
				ticketsRequest,
				ticketsFail,
        tickets,
				openFilterModal,
        addNewTicket,
				isExportCsvRequest,
				chartData,
				getTicketingChartData,
        isGetTicketingChartDataPending,
        dataLimit,
        selectedPage
      } = this.props;

			const {
				chartSettings,
				isNoteModalOpen,
				notes,
				isSearchActive
			} = this.state;
			const params = {
				service: getTicketingFiltersService
      };
      const isDataEmpty =
      tickets && tickets.resultList && tickets.resultList.length === 0;

			return (
				<div data-spec="ticketing">
					<PageTitle
						pushBack={this.pushBack}
						title={
							isSearchActive ? (
								<FormattedMessage
									id="TICKETING.SEARCH_RESULTS"
									defaultMessage="Search Results"
								/>
							) : (
								<FormattedMessage
									id="TICKETING.TICKETING"
									defaultMessage="Ticketing"
								/>
							)
						}
						messages={messages}
						actions={
							<TicketingRWComponent>
								<Button
									label={
										<FormattedMessage
											id="TICKETING.ADD_TICKET"
											defaultMessage="Add Ticket"
										/>
									}
									variant="primary"
									dataSpec="create-sim-order-button"
									onClick={() => addNewTicket()}
									labelIcon={<AddIcon className={styles.add_icon} />}
									disabled={ticketsRequest}
								/>
							</TicketingRWComponent>
						}
					/>

					{!!chartSettings.length && (
						<div className={styles.pie_chart_wrapper}>
							{chartSettings.map((setting) => (
								<Card
									key={setting.groupByColumn}
									className={styles.pie_chart_card}
								>
									<WrapperGridCard
										gridItem={{
											...setting,
											units: 'TICKETS',
											showSummary: true,
										}}
										contentData={chartData}
										messages={messages}
										getContent={getTicketingChartData}
										pendingData={isGetTicketingChartDataPending(
											setting.id.column
										)}
										hasRefresh
										isTicketingCharts
									/>
								</Card>
							))}
						</div>
					)}

					<ActionBar
						menu={ticketingSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						openFilterModal={openFilterModal}
						onChange={this.onChange}
						clearFilters={this.clearFilters}
						isDisabled={ticketsRequest}
						isDataEmpty={isDataEmpty}
						showPagination
						totalCount={tickets && tickets.totalCount}
						selectedPage={selectedPage}
						dataLimit={dataLimit}
						actions={
							<div
								data-tip={messages.EXPORT_TICKETS || 'Export tickets'}
								className={`${
									tickets && tickets.resultList.length > 0
										? styles.export_csv_icon
										: styles.disabled
								}`}
								data-for="createCustomReportTooltip"
							>
								<DocumentDownloadIcon
									onClick={
										tickets && tickets.resultList.length > 0
											? this.exportCsvFile
											: undefined
									}
								/>
								<ReactTooltip
									className={styles.tooltip}
									type="light"
									id="createCustomReportTooltip"
								/>
							</div>
						}

					/>

					<FilterModal
						messages={messages}
						onApply={this.onApply}
						params={params}
					/>
					{(ticketsRequest ||
						isExportCsvRequest ||
						!chartSettings.length > 0) && <Loading data-spec="loading" />}
					{ticketsFail && null}
					{!ticketsRequest &&
						!isExportCsvRequest &&
						chartSettings.length > 0 &&
						tickets && (
							<WrappedComponent
								{...this.state}
								{...this.props}
								getOptions={this.getOptions}
							/>
						)}
					{isDataEmpty && <NoData table />}
					<NotesModal
						show={isNoteModalOpen}
						notes={notes}
						onClose={this.closeNoteModal}
					/>
				</div>
			);
		}
	}

	const {
		object,
		func,
		array,
		bool,
		number,
		objectOf,
		string,
		shape,
		arrayOf
	} = PropTypes;

	TicketingComponent.propTypes = {
		pushBack: func,
		messages: objectOf(string),
		dataLimit: number,
		getTickets: func,
		ticketsRequest: bool,
		ticketsFail: bool,
		tickets: shape({
			resultList: array
		}),
		getTicketingFilters: func,
		ticketingFiltersRequest: bool,
		ticketingFiltersFail: bool,
		filterModalOpened: bool,
		openFilterModal: func,
		goToTicketDetails: func,
		addNewTicket: func,
		chartData: func,
		getTicketingChartData: func,
		isGetTicketingChartDataPending: func,
		getSeveritiesAndStatusesInit: func,
		patchUserSetting: func,
		userSettings: arrayOf(object),
		severitiesAndStatuses: objectOf(array),
		getSeveritiesAndStatuses: func,
		getTicketingFiltersInit: func,
		getExportCsv: func,
		isExportCsvRequest: bool,
		createUserSettings: func,
    filterData: objectOf(array),
    dataOffset: number,
    selectedPage: number,
    setDataOffset: func,
    setSelectedPage: func,
    setPaginationInit: func,
    searchParameter: object
	};
	return TicketingComponent;
};

export default Ticketing;
