import { connect } from 'react-redux';
import {
	getFormValues,
	isDirty,
	isInvalid
} from 'redux-form';
import AccountActivationDesktop from './Desktop';
import AccountActivationMobile from './Mobile';

import { activateAccount } from '../../redux/accounts/actions';
import {
	accountActivationFail,
	accountActivationRequest,
	accountActivationData
} from '../../redux/accounts/selectors';
import { getErrors } from '../../../Shared/redux/getStatusCode/selectors';
import { clearError } from '../../../Shared/redux/getStatusCode/actions';

const mapStateToProps = (state) => ({
	accountActivationFail: accountActivationFail(state),
	accountActivationRequest: accountActivationRequest(state),
	accountActivationData: accountActivationData(state),
	formValues: getFormValues('AccountActivationForm')(state),
	isDirty: isDirty('AccountActivationForm')(state),
	isInvalid: isInvalid('AccountActivationForm')(state),
	errors: getErrors(state)
});

const mapDispatchToProps = (dispatch) => ({
	activateAccount: (values) => dispatch(activateAccount(values)),
	clearError: () => dispatch(clearError())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountActivationDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountActivationMobile);
