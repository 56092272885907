import { Status } from '../../../../utils/constants';

export const isBulkSingleEndpointUpdating = (state) =>
	state.Endpoints.updateBulkEndpoints.status === Status.PENDING;
export const singleBulkEndpointUpdateFail = (state) =>
	state.Endpoints.updateBulkEndpoints.status === Status.FAIL;
export const singleBulkEndpointUpdateSuccess = (state) =>
	state.Endpoints.updateBulkEndpoints.status === Status.DONE;
export const getCurrentSim = (state) =>
	state.Endpoints.updateBulkEndpoints.currentSim;
export const getActionError = (state) =>
	state.Endpoints.updateBulkEndpoints.actionError;
export const getFailSims = (state) =>
	state.Endpoints.updateBulkEndpoints.failSims;
export const getSuccessSims = (state) =>
	state.Endpoints.updateBulkEndpoints.successSims;
export const getIsStarted = (state) =>
	state.Endpoints.updateBulkEndpoints.started;
