import React, { PureComponent } from 'react';
import { bool, object, number, func, string, oneOfType } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import NoData from '../../../Shared/views/NoDataView';
import Loader from '../../../../lib/DigitalComponents/Loader';

export default function Permissions(WrappedComponent) {
	class PermissionsComponent extends PureComponent {
		componentDidMount() {
			const { getPermissions, id, system, currentUser, myProfile } = this.props;
			getPermissions({ id, system, myProfile, currentUser });
		}

		componentDidUpdate(prevProps) {
			const { getPermissions, id, system, currentUser, myProfile } = this.props;
			if (prevProps.id !== id) getPermissions({ id, system, myProfile, currentUser });
		}

		render() {
			const { permissionsRequest, permissionsFail, permissions } = this.props;
			return (
				<div data-spec="permissions">
					{permissionsRequest && <Loader />}
					{permissionsFail && null}
					{!permissionsRequest &&
						permissions &&
						permissions.resultList &&
						permissions.resultList.length > 0 && (
							<WrappedComponent {...this.state} {...this.props} />
						)}
					{permissions &&
						permissions.resultList &&
						permissions.resultList.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.NO_PERMISSIONS_AVAILABLE"
										defaultMessage="No Permissions Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_PERMISSIONS_AVAILABLE"
										defaultMessage="No Permissions Available"
									/>
								}
							/>
						)}
				</div>
			);
		}
	}

	PermissionsComponent.propTypes = {
		dataLimit: number,
		push: func,
		getPermissions: func,
		permissionsRequest: bool,
		permissionsFail: bool,
		permissions: object,
		match: object,
		pushBackUrl: string,
		editPermissionUrl: string,
		permissionsTitle: object,
		id: oneOfType([string, number]),
		system: bool,
		currentUser: object,
		myProfile: bool
	};

	const wrappedComponentName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	PermissionsComponent.displayName = `Permissions(${wrappedComponentName})`;
	return PermissionsComponent;
}
