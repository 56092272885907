import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../../Shared/views/PageTitleView';
import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';
import ResetPasswordModal from '../ResetPasswordModal';

import {
	isUserAllowedToAccess,
	checkIfUserIsSystem,
} from '../../../../utils/AuthSelector';

const UserProfile = () => (WrappedComponent) => {
	class UserProfileComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				isResetPasswordModalOpen: false,
			};
		}

		componentDidMount() {
			this.getUserData();
		}

		componentDidUpdate(prevProps) {
			const { resetUserPassword } = this.props;

			if (prevProps.resetUserPassword !== resetUserPassword) {
				this.getUserData();
			}
		}

		componentWillUnmount() {
			const { getUserInit } = this.props;
			getUserInit();
		}

		getUserData = () => {
			const {
				getUser,
				match: {
					params: { id },
				},
				system,
				myProfile,
			} = this.props;

			const params = {
				additionalParams: {
					include: [
						'Address',
						'Address.AddressType',
						'ProfilePicture',
						'Language',
					],
				},
				system,
				myProfile,
			};
			getUser(id, params);
		};

		goToEditProfile = () => {
			const {
				match: {
					params: { id },
				},
				goToEditProfile,
				system,
				myProfile,
			} = this.props;

			if (system && !myProfile) {
				goToEditProfile(`/access-management/users/${id}/edit`);
			} else if (myProfile) {
				goToEditProfile('/my-profile/edit');
			} else {
				goToEditProfile(`/user/${id}/edit`);
			}
		};

		goToUserGroupPermissions = (userGroupId) => {
			const {
				push,
				match: {
					params: { id },
				},
				system,
				currentUser,
				myProfile,
			} = this.props;

			if (system) {
				push(
					`/access-management/users/${id}/user-groups/${userGroupId}/permissions`
				);
			} else if (myProfile || (myProfile && currentUser.systemUser)) {
				push(`/my-profile/user-groups/${userGroupId}/permissions`);
			} else {
				push(`/user/${id}/user-groups/${userGroupId}/permissions`);
			}
		};

		goToIndividualPermissions = (userGroup) => {
			const {
				goToIndividualPermissions,
				system,
				match: {
					params: { id },
				},
				myProfile,
				currentUser,
			} = this.props;

			let url = '';
			if (system) {
				url = `/access-management/users/${id}/user-groups/${userGroup.id}/individual-permissions`;
			} else if (myProfile || (myProfile && currentUser.systemUser)) {
				url = `/my-profile/user-groups/${userGroup.id}/individual-permissions`;
			} else {
				url = `/user/${id}/user-groups/${userGroup.id}/individual-permissions`;
			}
			goToIndividualPermissions(url);
		};

		goToEffectivePermissions = () => {
			const {
				goToEffectivePermissions,
				match: {
					params: { id },
				},
				system,
				myProfile,
			} = this.props;
			let url = `/user/${id}/effective-permissions`;
			if (system) {
				url = `/access-management/users/${id}/effective-permissions`;
			}
			if (myProfile) {
				url = '/my-profile/effective-permissions';
			}
			goToEffectivePermissions(url);
		};

		pushBack = () => {
			const { push, system } = this.props;
			if (system) {
				push('/access-management');
			} else {
				push('/user-management');
			}
		};

		openResetPasswordModal = () => {
			this.setState({
				isResetPasswordModalOpen: true,
			});
		};

		closeResetPasswordModal = () => {
			this.setState({
				isResetPasswordModalOpen: false,
			});
		};

		render() {
			const {
				userRequest,
				user,
				system,
				settings,
				myProfile,
				currentUser,
				getPasswordResetUser,
			} = this.props;

			const { isResetPasswordModalOpen } = this.state;

			return (
				<div data-spec="users">
					<PageTitle
						title={
							(system && (
								<FormattedMessage
									id="ONBOARDING.SERVICE_PROVIDER_USERS"
									defaultMessage="Service Provider Users"
								/>
							)) ||
							(myProfile && (
								<FormattedMessage
									id="ONBOARDING.MY_PROFILE"
									defaultMessage="My Profile"
								/>
							)) || (
								<FormattedMessage
									id="ONBOARDING.USER_MANAGEMENT"
									defaultMessage="User Management"
								/>
							)
						}
						pushBack={myProfile ? null : this.pushBack}
						actions={
							(!myProfile && checkIfUserIsSystem(currentUser)
								? isUserAllowedToAccess(
										['system.accessmanagement.users_rw'],
										currentUser
								  )
								: isUserAllowedToAccess(
										['mnc.accessmanagement.users_rw'],
										currentUser
								  )) || myProfile ? (
								<Button
									variant="primary"
									label={
										<FormattedMessage
											id="ONBOARDING.EDIT_USER"
											defaultMessage="Edit User"
										/>
									}
									data-spec="edit-user-button"
									onClick={this.goToEditProfile}
								/>
							) : null
						}
					/>
					{isResetPasswordModalOpen && (
						<ResetPasswordModal
							show
							onClose={this.closeResetPasswordModal}
							system={system}
							resetPassword={getPasswordResetUser}
							data={user}
							myProfile={myProfile}
						/>
					)}
					{userRequest && <Loading />}
					{!userRequest && user && (
						<WrappedComponent
							user={user}
							goToIndividualPermissions={this.goToIndividualPermissions}
							goToEffectivePermissions={this.goToEffectivePermissions}
							goToUserGroupPermissions={this.goToUserGroupPermissions}
							goToEditProfile={this.goToEditProfile}
							system={system}
							myProfile={myProfile}
							settings={settings}
							currentUser={currentUser}
							openResetPasswordModal={this.openResetPasswordModal}
						/>
					)}
				</div>
			);
		}
	}

	const { func, bool, shape, object, arrayOf } = PropTypes;

	UserProfileComponent.propTypes = {
		push: func,
		goToIndividualPermissions: func,
		goToEffectivePermissions: func,
		match: shape(),
		getUser: func,
		user: shape(),
		userRequest: bool,
		resetUserPassword: object,
		getPasswordResetUser: func,
		goToEditProfile: func,
		system: bool,
		settings: arrayOf(object),
		myProfile: bool,
		currentUser: object,
		getUserInit: func,
	};

	return UserProfileComponent;
};

export default UserProfile;
