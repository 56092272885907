/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import qs from 'qs';
import { FormattedMessage } from 'react-intl';
import PropTypes, { array } from 'prop-types';
import { withRouter } from 'react-router-dom';

import styles from './AccountActivation.scss';
import Loader from '../../../../lib/DigitalComponents/Loader';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

const WarningIcon = ImageSelector(CurrentContext.theme, 'svgs/warning.svg');
const SuccessIcon = ImageSelector(CurrentContext.theme, 'svgs/success.svg');

const SUCCESS = 'Success'; // CHANGE THIS TO TRUE OR FALSE
const ERROR = 'error';

const renderErrorMessage = () => (
	<>
		<FormattedMessage
			id="ONBOARDING.CONTACT_SYSADMIN"
			defaultMessage="Contact your IoT Console administrator for further assistance"
		>
			{(formattedValue) => (
				<div className={styles.contact_text}>
					<span>{formattedValue}</span>
				</div>
			)}
		</FormattedMessage>
		<a href="/" className={styles.homepage}>
			<span>
				<FormattedMessage
					id="ONBOARDING.GO_TO_HOME_PAGE"
					defaultMessage="Back to Login"
				/>
			</span>
		</a>
	</>
);

const renderSuccessMessage = () => (
	<>
		<FormattedMessage
			id="ONBOARDING.CHANGES_TO_YOUR_ACCOUNT"
			defaultMessage="If you already have an account, please proceed to login."
		>
			{(formattedValue) => (
				<div className={styles.contact_text}>
					<span>{formattedValue}</span>
				</div>
			)}
		</FormattedMessage>
		<a href="/" className={styles.homepage}>
			<span>
				<FormattedMessage
					id="ONBOARDING.GO_TO_HOME_PAGE"
					defaultMessage="Back to Login"
				/>
			</span>
		</a>
	</>
);

const Notifications = ({ messages, type }) => {
	const error = type === ERROR;
	return (
		<div
			className={styles.notification_wrapper}
			data-spec="notification-wrapper"
		>
			<div
				className={[
					styles.title,
					error ? styles.title_error : styles.title_success
				].join(' ')}
			>
				<div className={styles.header}>
					<div className={styles.icon}>
						{error ? <WarningIcon /> : <SuccessIcon />}
					</div>
					<div className={styles.description}>
						{error ? (
							<FormattedMessage
								id="ONBOARDING.ACTIVATION_DENIED"
								defaultMessage="Request cannot be processed!"
							/>
						) : (
							<FormattedMessage
								id="ONBOARDING.ACTIVATION_SUCCESS"
								defaultMessage="Account Activated"
							/>
						)}
					</div>
				</div>
			</div>
			<div className={styles.content}>
				{error &&
					messages.map((item) => (
						<div key={item}>
							<span>{item}</span>
							<br />
						</div>
					))}
				{!error && (
					<FormattedMessage
						id="ONBOARDING.ACCOUNT_ACTIVATED"
						defaultMessage="In a few moments you will receive an email with instructions on how to create your password and access to your account."
					>
						{(formattedValue) => (
							<span className={styles.contact_text}>{formattedValue}</span>
						)}
					</FormattedMessage>
				)}
				{error ? renderErrorMessage() : renderSuccessMessage()}
			</div>
		</div>
	);
};

const AccountActivationWrapper = () => () => {
	class AccountActivation extends Component {
		componentDidMount() {
			const {
				location: { search },
				activateAccount
			} = this.props;
			const val = qs.parse(search, { ignoreQueryPrefix: true });
			activateAccount({ verificationToken: val.token });
		}

		renderSuccess = () => (
			<Notifications
				data-spec="success-notification"
			/>
		);

		renderError = () => {
			const { errors } = this.props;
			const errorMessage = errors.map((item) => item.message);
			return (
				<Notifications
					messages={errorMessage}
					data-spec="error-notification"
					type="error"
				/>
			);
		};

		render() {
			const {
				accountActivationData,
				accountActivationRequest,
				errors
			} = this.props;

			if (accountActivationRequest) {
				return <Loader data-spec="loader" />;
			}
			return (
				<div
					className={styles.title_background}
					data-spec="account-activation-wrapper"
				>
					<div>
						{accountActivationData &&
						accountActivationData.status === SUCCESS ? (
							<div className={styles.inner_wrapper}>{this.renderSuccess()}</div>
						) : (
							''
						)}
						{errors && errors.length ? (
							<div className={styles.inner_wrapper}>{this.renderError()}</div>
						) : null}
					</div>
				</div>
			);
		}
	}

	const { func, bool, object } = PropTypes;

	AccountActivation.propTypes = {
		location: object,
		accountActivationRequest: bool,
		activateAccount: func,
		accountActivationData: object,
		errors: array
	};

	return withRouter(AccountActivation);
};

export default AccountActivationWrapper;
