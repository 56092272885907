import { connect } from 'react-redux';

import EditUserGroupPermissions from './EditUserGroupPermissions';
import { getUserGroupPermissions } from '../../redux/userGroupPermissions/actions';
import {
	getUserGroupPermissionsRequest,
	getUserGroupPermissionsSuccess,
	editUserGroupPermissionsRequest,
	editUserGroupPermissionsSuccess
} from '../../redux/userGroupPermissions/selectors';

const mapStateToProps = (state) => ({
	permissionsRequest: getUserGroupPermissionsRequest(state),
	permissions: getUserGroupPermissionsSuccess(state),
	editPermissionsRequest: editUserGroupPermissionsRequest(state),
	editPermissionsSuccess: editUserGroupPermissionsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getPermissions: (params) => dispatch(getUserGroupPermissions(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditUserGroupPermissions);
