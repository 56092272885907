import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { localizationHelper } from '../../../../utils/helperFunctions';
import NoData from '../../../Shared/views/NoDataView';

import {
	required,
	onlyPositiveNumbers,
	isLessThanOrEqualTo,
	isGreaterThanOrEqualTo
} from '../../../../utils/validators';

import {
	SimOrderingTableHeader as tableHeader,
	SimOrderingWingTableHeader as tableHeaderWing,
	simOrderingSearchMenu
} from '../../utils/constants';

import Table from '../../../../lib/DigitalComponents/Table';
import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';

import Loading from '../../../../lib/DigitalComponents/Loader';
import ActionBar from '../../../Shared/views/ActionBarView';

import { getDateFormat } from '../../../../utils/constants';
import styles from './SimOrderingForm.scss';
import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';
import EditSimSkusModal from '../EditSimSkusModal';
import DeleteSimSkuModal from '../DeleteSimSkuModal';
import AddSimSkuModal from '../AddSimSkusModal';

const isLessThanOrEqualToMax = isLessThanOrEqualTo(
	'maxQuantity',
	<FormattedMessage
		id="ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY"
		defaultMessage="max quantity"
	/>
);
const isGreaterThanOrEqualToMin = isGreaterThanOrEqualTo(
	'minQuantity',
	<FormattedMessage
		id="ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY"
		defaultMessage="min quantity"
	/>
);

const CompaniesRWComponent = UserAllowedToAccess(['system.companies_rw']);

class SimOrdering extends Component {
	constructor(props) {
		super(props);
		this.state = {
			simSku: {},
			orderColumn: {}
		};
	}

	componentDidMount() {
		const { restraintPeriods, getRestraintPeriods } = this.props;
		if (!restraintPeriods) {
			getRestraintPeriods();
		}
		this.getSkus();
		this.getRestraints();
	}

	componentDidUpdate(prevProps) {
		const {
			addSimSkuSuccess,
			editSimSkuSuccess,
			deleteSimSkuSuccess,
			addRestraintsSuccess,
			updateRestraintsSuccess,
			addSubAccountSimSkuSuccess
		} = this.props;

		if (
			addSimSkuSuccess !== prevProps.addSimSkuSuccess ||
			editSimSkuSuccess !== prevProps.editSimSkuSuccess ||
			deleteSimSkuSuccess !== prevProps.deleteSimSkuSuccess ||
			addRestraintsSuccess !== prevProps.addRestraintsSuccess ||
			updateRestraintsSuccess !== prevProps.updateRestraintsSuccess ||
			addSubAccountSimSkuSuccess !== prevProps.addSubAccountSimSkuSuccess
		) {
			this.getSkus();
			this.getRestraints();
		}
	}

	componentWillUnmount() {
		const { simpleSearchClose } = this.props;
		simpleSearchClose();
	}

	getSkus = () => {
		const { orderColumn } = this.state;
		const {
			getSkus,
			match: {
				params: { accountId }
			},
			isMyCompany,
			searchParameter
		} = this.props;
		const searchParams = [
			{
				prop: 'platformId',
				propValue: accountId,
				operator: '='
			}
		];

		if (searchParameter) {
			searchParams.push({
				operator: 'iLike',
				prop: searchParameter.prop,
				propValue: `${searchParameter.propValue}%`
			});
		}

		getSkus({
			isMyCompany,
			searchParams,
			additionalParams: { dataSort: orderColumn.apiString || 'name asc' }
		});
	};

	getRestraints = () => {
		const {
			getRestraints,
			match: {
				params: { accountId, companyId }
			}
		} = this.props;
		getRestraints({
			companyId,
			params: {
				searchParams: [
					{
						prop: 'platformId',
						propValue: accountId,
						operator: '='
					}
				],
				additionalParams: {
					include: ['ContractRestraintPeriod']
				}
			}
		});
	};

	handleSubmit = (values) => {
		const {
			restraints,
			initialValues,
			updateRestraints,
			addRestraints,
			match: {
				params: { accountId }
			}
		} = this.props;

		const submitRestraint = {
			minQuantity: parseInt(values.minQuantity, 10),
			maxQuantity: parseInt(values.maxQuantity, 10),
			increment: parseInt(values.increment, 10),
			contractRestraintPeriodId:
				values.contractRestraintPeriodId.value ||
				values.contractRestraintPeriodId
		};

		if (
			restraints &&
			restraints.resultList.length &&
			restraints.resultList[0].id
		) {
			if (
				initialValues.minQuantity !== values.minQuantity ||
				initialValues.maxQuantity !== values.maxQuantity ||
				initialValues.increment !== values.increment ||
				initialValues.contractRestraintPeriodId !==
					values.contractRestraintPeriodId
			) {
				updateRestraints({
					id: restraints.resultList[0].id,
					data: submitRestraint
				});
			}
		} else {
			submitRestraint.platformId = accountId;
			addRestraints(submitRestraint);
		}
	};

	editSimSku = (simSku) => {
		const { openEditSimSkuModal, account } = this.props;
		if (this.checkIfWing() && account.parentPlatformId !== null) {
			this.openAddSimSkuModal();
		} else {
			this.setState({ simSku }, () => openEditSimSkuModal());
		}
	};

	deleteSimSku = (simSku) => {
		const { openDeleteSimSkuModal } = this.props;
		this.setState({ simSku }, () => openDeleteSimSkuModal());
	};

	checkIfWing = () => {
		const { account } = this.props;
		if (
			account &&
			account.platformType &&
			account.platformType.code.toLowerCase() === 'wing'
		) {
			return true;
		}
	};

	openAddSimSkuModal = () => {
		const { openAddSimSkuModal } = this.props;
		openAddSimSkuModal();
	};

	selectOption = (item) => {
		switch (item.value) {
			case 0:
				this.editSimSku(item.data);
				break;
			case 1:
				this.deleteSimSku(item.data);
				break;
			// no default
		}
	};

	renderSelect = (val) => [
		{
			value: 0,
			label: <FormattedMessage id="ONBOARDING.EDIT" defaultMessage="Edit" />,
			data: val
		},
		{
			value: 1,
			label: (
				<FormattedMessage id="ONBOARDING.DELETE" defaultMessage="Delete" />
			),
			data: val
		}
	];

	onSearchSubmitCallback = () => {
		this.getSkus();
	};

	onOrderClick = (data) => {
		const { name: column, apiString } = data;
		const { orderColumn } = this.state;
		this.setState(
			{
				orderColumn: {
					name: column,
					order: orderColumn.name === column ? !orderColumn.order : true,
					apiString
				}
			},
			() => this.getSkus()
		);
	};

	renderEditDeleteSKU = (data) => {
		const { isMyCompany } = this.props;
		return (
			<Select
				value={''}
				placeholder={<FormattedMessage id="SELECT" defaultMessage="Select" />}
				options={this.renderSelect(data)}
				onChange={this.selectOption}
				data-spec="dropdown"
				isDisabled={isMyCompany}
			/>
		);
	};

	renderSimSkus = () => {
		const { orderColumn } = this.state;
		const { account } = this.props;

		const options = {
			header:
				account &&
				(!this.checkIfWing() ||
					(this.checkIfWing() && account.parentPlatformId === null))
					? tableHeader
					: tableHeaderWing,
			customComponents: {
				createdAt: {
					type: 'custom',
					component: ({ createdAt }) => getDateFormat(createdAt)
				},
				editDeleteSKU: {
					type: 'custom',
					component: (val) => this.renderEditDeleteSKU(val)
				},
				unitPrice: {
					type: 'custom',
					component: ({ unitPrice }) => `$${parseFloat(unitPrice).toFixed(2)}`
				}
			},
			tableOptions: {
				orderByData: ['name', 'unitPrice', 'createdAt'],
				orderColumn,
				getOrderData: (data) => this.onOrderClick(data)
			}
		};

		const { getSkusRequest, skus, messages } = this.props;
		if (getSkusRequest) {
			return <Loading data-spec="loading" />;
		}
		return (
			<div data-spec="skus-table">
				<Table
					data-spec="skus-table"
					data={(skus && skus.resultList.length > 0 && skus.resultList) || []}
					options={options}
					messages={messages}
				/>

				{skus && skus.resultList && skus.resultList.length === 0 && (
					<NoData
						title={
							<FormattedMessage
								id="ONBOARDING.NO_SIM_SKUS_AVAILABLE"
								defaultMessage="No Sim Skus Available"
							/>
						}
						table
					/>
				)}
			</div>
		);
	};

	render() {
		const {
			// reset,
			messages,
			account,
			closeDeleteSimSkuModal,
			closeEditSimSkuModal,
			closeAddSimSkuModal,
			skus,
			isEditSimSkuModalOpen,
			isDeleteSimSkuModalOpen,
			isAddSimSkuModalOpened,
			restraintPeriodsRequest,
			restraintPeriods,
			restraintsRequest,
			addRestraintsRequest,
			getSkusRequest,
			user,
			handleSubmit,
			invalid,
			pristine,
			match: {
				params: { accountId, companyId, m2mAccountId, platformType }
			},
			pushBack
		} = this.props;

		const { simSku } = this.state;

		return (
			<div data-spec="modals">
				<form
					onSubmit={handleSubmit(this.handleSubmit)}
					data-spec="platform-details"
				>
					<div className={styles.sim_ordering_wrapper}>
						<h5 className={styles.section_headline}>
							<FormattedMessage
								id="ONBOARDING.CURRENT_SIM_SKUS"
								defaultMessage="Current SIM SKU'S"
							/>
						</h5>
						<ActionBar
							actions={
								<CompaniesRWComponent>
									<Button
										variant="primary"
										className={styles.add_sim_sku_button}
										label={
											<FormattedMessage
												id="ONBOARDING.ADD_SIM_SKU"
												defaultMessage="Add SIM SKU"
											/>
										}
										disabled={getSkusRequest}
										dataSpec="add-sim-sku-button"
										type="button"
										onClick={() => this.editSimSku({})}
									/>
								</CompaniesRWComponent>
							}
						/>

						<ActionBar
							menu={simOrderingSearchMenu}
							hasSearch
							onSearchSubmitCallback={this.onSearchSubmitCallback}
						/>
					</div>
					{this.renderSimSkus()}
					{account &&
					((this.checkIfWing() && account.parentPlatformId === null) ||
						!this.checkIfWing()) &&
					isUserAllowedToAccess(['system.companies_rw'], user) ? (
						<div className={styles.sim_ordering_wrapper}>
							<div className={styles.container}>
								<h5 className={styles.section_headline}>
									<FormattedMessage
										id="ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS"
										defaultMessage="Contract restraints"
									/>
								</h5>
								<span>
									<FormattedMessage
										id="ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE"
										defaultMessage="Please set Restraints on the SIM Order Process."
									/>
								</span>
								{restraintsRequest || addRestraintsRequest ? (
									<Loading data-spec="loading" />
								) : (
									<div
										data-spec="contract-restraints"
										className={styles.contract_restraints}
									>
										<div className={styles.fields_wrapper}>
											<div className={styles.select_wrapper}>
												<FormattedMessage
													id="ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER"
													defaultMessage="Min Quantity"
												>
													{(formattedValue) => (
														<Field
															dataSpec="min_quantity"
															name="minQuantity"
															type="text"
															component={Input}
															label={
																<FormattedMessage
																	id="ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED"
																	defaultMessage="Min Quantity Allowed"
																/>
															}
															placeholder={formattedValue}
															validate={[
																required,
																onlyPositiveNumbers,
																isLessThanOrEqualToMax
															]}
															parse={(value) =>
																(!Number.isNaN(parseInt(value, 10))
																	? parseInt(value, 10)
																	: '')}
														/>
													)}
												</FormattedMessage>
											</div>
											<div className={styles.select_wrapper}>
												<FormattedMessage
													id="ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER"
													defaultMessage="Max Quantity"
												>
													{(formattedValue) => (
														<Field
															dataSpec="max_quantity"
															name="maxQuantity"
															type="text"
															component={Input}
															label={
																<FormattedMessage
																	id="ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED"
																	defaultMessage="Max Quantity Allowed"
																/>
															}
															placeholder={formattedValue}
															validate={[
																required,
																onlyPositiveNumbers,
																isGreaterThanOrEqualToMin
															]}
															parse={(value) =>
																(!Number.isNaN(parseInt(value, 10))
																	? parseInt(value, 10)
																	: '')}
														/>
													)}
												</FormattedMessage>
											</div>
										</div>
										<div className={styles.field_wrapper}>
											<div className={styles.select_wrapper}>
												<FormattedMessage
													id="ONBOARDING.INCREMENT"
													defaultMessage="Increment"
												>
													{(formattedValue) => (
														<Field
															dataSpec="increment"
															name="increment"
															type="text"
															component={Input}
															label={formattedValue}
															placeholder={formattedValue}
															validate={[
																required,
																onlyPositiveNumbers,
																isLessThanOrEqualToMax
															]}
															parse={(value) =>
																(!Number.isNaN(parseInt(value, 10))
																	? parseInt(value, 10)
																	: '')}
														/>
													)}
												</FormattedMessage>
											</div>
											<div className={styles.select_wrapper}>
												<FormattedMessage
													id="ONBOARDING.SIM_ORDERING_PER_PERIOD"
													defaultMessage="Per Period"
												>
													{(formattedValue) => (
														<FormattedMessage
															id="ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER"
															defaultMessage="Select a Time Period"
														>
															{(formattedValuePlaceholder) => (
																<Field
																	dataSpec="time_period"
																	name="contractRestraintPeriodId"
																	type="text"
																	component={Select}
																	options={
																		restraintPeriods &&
																		restraintPeriods.resultList &&
																		restraintPeriods.resultList.map((item) => ({
																			value: item.id,
																			label: (
																				<FormattedMessage
																					id={`ONBOARDING.${localizationHelper(
																						item.name
																					).toUpperCase()}`}
																					defaultMessage={item.name}
																				/>
																			)
																		}))
																	}
																	label={formattedValue}
																	placeholder={formattedValuePlaceholder}
																	validate={[required]}
																	disabled={restraintPeriodsRequest}
																	loading={restraintPeriodsRequest}
																	cleanValue
																/>
															)}
														</FormattedMessage>
													)}
												</FormattedMessage>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					) : null}
					<div className={styles.bottom_line} />
					<ActionBar
						actions={
							account &&
							((this.checkIfWing() && account.parentPlatformId === null) ||
								!this.checkIfWing()) ? (
								<CompaniesRWComponent>
									<div className={styles.section_buttons}>
										<Button
											label={
												<FormattedMessage id="CANCEL" defaultMessage="CANCEL" />
											}
											variant="outline-primary"
											type="button"
											dataSpec="cancel-button"
											onClick={pushBack}
										/>
										<Button
											label={
												<FormattedMessage id="SAVE" defaultMessage="Save" />
											}
											variant="primary"
											type="submit"
											dataSpec="submit-button"
											disabled={invalid || pristine}
										/>
									</div>
								</CompaniesRWComponent>
							) : null
						}
					/>
				</form>
				{isEditSimSkuModalOpen && (
					<EditSimSkusModal
						show
						messages={messages}
						onClose={closeEditSimSkuModal}
						accountId={accountId}
						initialValues={simSku}
					/>
				)}
				{isDeleteSimSkuModalOpen && (
					<DeleteSimSkuModal
						show
						messages={messages}
						onClose={closeDeleteSimSkuModal}
						accountId={accountId}
						simSku={simSku}
					/>
				)}
				{isAddSimSkuModalOpened && (
					<AddSimSkuModal
						onClose={closeAddSimSkuModal}
						existingSimSkus={skus}
						accountId={accountId}
						companyId={companyId}
						m2mAccountId={m2mAccountId}
						platformType={platformType}
						account={account}
					/>
				)}
			</div>
		);
	}
}

const { array, string, func, object, bool, shape, objectOf } = PropTypes;

SimOrdering.propTypes = {
	companyId: string,
	handleSubmit: func,
	initialValues: object,
	invalid: bool,
	pristine: bool,
	match: object,
	messages: objectOf(string),
	pushBack: func,
	account: object,

	getSkusRequest: bool,
	getSkus: func,
	skus: object,

	getRestraints: func,
	restraintsRequest: bool,
	restraints: object,
	addRestraints: func,
	addRestraintsRequest: bool,
	addRestraintsSuccess: bool,
	updateRestraints: func,
	updateRestraintsSuccess: bool,
	addSubAccountSimSkuSuccess: array,

	getRestraintPeriods: func,
	restraintPeriodsRequest: bool,
	restraintPeriods: object,

	addSimSkuSuccess: bool,
	editSimSkuSuccess: bool,
	deleteSimSkuSuccess: bool,

	isDeleteSimSkuModalOpen: bool,
	isEditSimSkuModalOpen: bool,
	isAddSimSkuModalOpened: bool,

	openEditSimSkuModal: func,
	closeEditSimSkuModal: func,
	openDeleteSimSkuModal: func,
	closeDeleteSimSkuModal: func,
	openAddSimSkuModal: func,
	closeAddSimSkuModal: func,

	simpleSearchClose: func,
	user: shape(),
	isMyCompany: bool,
	searchParameter: object
};

let SimOrderingForm = reduxForm({
	form: 'simOrderingForm',
	enableReinitialize: true
})(SimOrdering);

SimOrderingForm = connect((state, props) => {
	let fields = {};
	if (
		props.restraints &&
		props.restraints.resultList &&
		props.restraints.resultList.length > 0
	) {
		const field = props.restraints.resultList[0];
		fields = {
			minQuantity: field.minQuantity,
			maxQuantity: field.maxQuantity,
			increment: field.increment,
			contractRestraintPeriodId: field.contractRestraintPeriodId
		};
	}
	return {
		initialValues: fields
	};
})(SimOrderingForm);

export default SimOrderingForm;
