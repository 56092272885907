import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import DesktopView from './Desktop';
import MobileView from './Mobile';
import { exportCsvFile } from '../../redux/exportCsv/actions';
import { exportCsvRequest } from '../../redux/exportCsv/selectors';
import { showNotification } from '../../../Shared/redux/notifications/actions';

const mapDispatchToProps = (dispatch) => ({
	goBack: () => dispatch(localizedRoute('/support')),
	exportCsvFile: (data) => dispatch(exportCsvFile(data)),
	showNotification: (message) =>
		dispatch(
			showNotification({
				id: 'user-guide-translation-notif',
				message: [message],
				type: 1,
				close: false,
				notificationType: 'admin'
			})
		)
});

const mapStateToProps = (state) => ({
	exportCsvRequest: exportCsvRequest(state),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopView);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileView);
