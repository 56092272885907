export const GET_AUTOMATION_PLATFORMS_REQUEST =
	'GET_AUTOMATION_PLATFORMS_REQUEST';
export const GET_AUTOMATION_PLATFORMS_SUCCESS =
	'GET_AUTOMATION_PLATFORMS_SUCCESS';
export const GET_AUTOMATION_PLATFORMS_FAIL = 'GET_AUTOMATION_PLATFORMS_FAIL';

export const GET_AUTOMATION_CATEGORIES_REQUEST =
	'GET_AUTOMATION_CATEGORIES_REQUEST';
export const GET_AUTOMATION_CATEGORIES_SUCCESS =
	'GET_AUTOMATION_CATEGORIES_SUCCESS';
export const GET_AUTOMATION_CATEGORIES_FAIL = 'GET_AUTOMATION_CATEGORIES_FAIL';

export const GET_AUTOMATION_TRIGGERS_REQUEST =
	'GET_AUTOMATION_TRIGGERS_REQUEST';
export const GET_AUTOMATION_TRIGGERS_SUCCESS =
	'GET_AUTOMATION_TRIGGERS_SUCCESS';
export const GET_AUTOMATION_TRIGGERS_FAIL = 'GET_AUTOMATION_TRIGGERS_FAIL';

export const GET_AUTOMATION_FRAMES_REQUEST = 'GET_AUTOMATION_FRAMES_REQUEST';
export const GET_AUTOMATION_FRAMES_SUCCESS = 'GET_AUTOMATION_FRAMES_SUCCESS';
export const GET_AUTOMATION_FRAMES_FAIL = 'GET_AUTOMATION_FRAMES_FAIL';

export const GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_REQUEST =
	'GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_REQUEST';
export const GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_SUCCESS =
	'GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_SUCCESS';
export const GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_FAIL =
	'GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_FAIL';

export const GET_AUTOMATION_OPTIONS_INIT = 'GET_AUTOMATION_OPTIONS_INIT';
