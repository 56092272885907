/* eslint-disable react/jsx-curly-newline */
import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { FormattedMessage } from 'react-intl';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import NoData from '../../../Shared/views/NoDataView';
import Loader from '../../../../lib/DigitalComponents/Loader';
import ActionBar from '../../../Shared/views/ActionBarView';
import FilterModal from '../../../Shared/components/FilterModal';

import DeleteModal from '../DeleteModal';
import NotesModal from '../NotesModal';

import { filterHelpers, newOnPageChange } from '../../../../utils/tableHelpers';
import {
	rulesTableHeadersDesktop,
	rulesTableHeadersMobile
} from '../../utils/tableHeaders';

import { getAutomationRulesFilters as getAutomationRulesFiltersService } from '../../services/AutomationService';

import { localizationHelper } from '../../../../utils/helperFunctions';
import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';

import { getDateFormat } from '../../../../utils/constants';
import ruleTypes from '../../utils/ruleTypes';
import {
	manualRuleStates,
	ruleStatusIds
} from '../../utils/manualRuleStatesHelper';

import styles from './Rules.scss';

const RWComponent = UserAllowedToAccess([
	'mnc.automation_rw',
	'system.managemanualrules_rw'
]);

const Delete = ImageSelector(CurrentContext.theme, 'svgs/delete.svg');
const Edit = ImageSelector(CurrentContext.theme, 'svgs/pen.svg');

const InfoFull = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');

const Rules = () => (WrappedComponent) => {
	class RulesComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				isDeleteRuleModalOpen: false,
				ruleDeletion: null,
				ruleId: null,
				modalHeader: null,
				modalTitle: null,
				isNotesModalOpened: false
			};
		}

		componentDidMount() {
			this.getRules();
		}

		componentDidUpdate(prevProps) {
			const { deleteRuleSuccess, requestForDeletionSuccess } = this.props;
			if (
				(deleteRuleSuccess &&
					prevProps.deleteRuleSuccess !== deleteRuleSuccess) ||
				(requestForDeletionSuccess &&
					prevProps.requestForDeletionSuccess !== requestForDeletionSuccess)
			) {
				this.getRules();
			}
		}

		getRules = () => {
			const { dataLimit, dataOffset, filterData, getRules } = this.props;

			const realProps = {
				categories: 'automationCategoryId',
				platforms: 'automationPlatformId',
				triggers: 'automationTriggerId',
				m2mAccounts: 'm2mAccountId',
				active: 'active',
				statuses: 'automationRuleStatusId'
			};

			let searchParams = [];

			if (filterData) {
				searchParams = filterHelpers(filterData, realProps);
				// include not applicable
				searchParams = searchParams.map((searchParam) => {
					if (
						searchParam.prop === realProps.statuses &&
						searchParam.propValue.includes(ruleStatusIds.IMPLEMENTED)
					) {
						searchParam.propValue = [
							...searchParam.propValue,
							ruleStatusIds.NOT_APPLICABLE
						];
					}
					return searchParam;
				});
			}

			const params = {
				additionalParams: {
					include: [
						'AutomationTrigger',
						'AutomationPlatform',
						'AutomationCategory',
						'AutomationRuleStatus',
						'AutomationRuleType',
						'AutomationRuleNotes'
					],
					dataSort: 'createdAt desc',
					dataLimit,
					dataOffset
				},
				searchParams
			};

			getRules(params);
		};

		renderNameMobileView = (val) => (
			<div className={styles.rule_name} data-spec="rule-name">
				<span>{val.name}</span>
				{val.automationTrigger &&
					val.automationTrigger.isManualApprovalRequired && (
						<div className={styles.label_tooltip}>
							<FormattedMessage
								id={'AUTOMATION.RULE_CREATED_OR_EDITED'}
								defaultMessage={
									'You have selected an Automation rule that requires extended processing and will not be set immediately.'
								}
							>
								{(formattedValue) => (
									<>
										<InfoFull
											data-tip={formattedValue}
											data-for={`automation-manual-trigger${val.id}`}
										/>
									</>
								)}
							</FormattedMessage>
							<ReactTooltip
								className={styles.tooltip}
								type="light"
								id={`automation-manual-trigger${val.id}`}
							/>
						</div>
					)}
			</div>
		);

		renderName = (val) => {
			const { goToDetail } = this.props;
			return (
				<div className={styles.rule_name} data-spec="rule-name">
					<span
						className={
							(this.isRWComponent() || this.isProccessManager()) &&
							styles.clickable
						}
						onClick={() =>
							(this.isRWComponent() || this.isProccessManager()) &&
							goToDetail(val.id)
						}
					>
						{val.name}
					</span>
					{val.automationTrigger &&
						val.automationTrigger.isManualApprovalRequired && (
							<div className={styles.label_tooltip}>
								<FormattedMessage
									id={'AUTOMATION.RULE_CREATED_OR_EDITED'}
									defaultMessage={
										'You have selected an Automation rule that requires extended processing and will not be set immediately.'
									}
								>
									{(formattedValue) => (
										<>
											<InfoFull
												data-tip={formattedValue}
												data-for={`automation-manual-trigger${val.id}`}
											/>
										</>
									)}
								</FormattedMessage>
								<ReactTooltip
									className={styles.tooltip}
									type="light"
									id={`automation-manual-trigger${val.id}`}
								/>
							</div>
						)}
				</div>
			);
		};

		renderPlatform = (val) => {
			const localizationId = localizationHelper(val.automationPlatform.name);
			return (
				<span data-spec="platform-name">
					<FormattedMessage
						id={`AUTOMATION.${localizationId}`}
						defaultMessage={val.automationPlatform.name}
					/>
				</span>
			);
		};

		renderCategory = (val) => {
			const localizationId = localizationHelper(val.automationCategory.name);
			return (
				<span data-spec="category-name">
					<FormattedMessage
						id={`AUTOMATION.${localizationId}`}
						defaultMessage={val.automationCategory.name}
					/>
				</span>
			);
		};

		renderTrigger = (val) => {
			const localizationId = localizationHelper(val.automationTrigger.name);
			return (
				<span data-spec="category-name">
					<FormattedMessage
						id={`AUTOMATION.${localizationId}`}
						defaultMessage={val.automationTrigger.name}
					/>
				</span>
			);
		};

		renderActive = (val) => {
			const activated = val.active ? 'Yes' : 'No';
			return (
				<div className={styles.rule_name} data-spec="column-activated">
					<FormattedMessage
						id={`AUTOMATION_RULES.${activated.toUpperCase()}`}
						defaultMessage={activated}
					/>
				</div>
			);
		};

		renderDate = (date) => getDateFormat(date);

		// ide nova ikona za request-deletion
		// todo request-deletion ide za manual rules i to jedino iz state-a implemented!
		// trenutna kanta ide za process managera ukoliko je u stanju conflicted || pending for deletion
		renderUserActions = (val) => {
			const { goToDetail } = this.props;
			const isPendingForDeletion =
				val.automationRuleStatus.name === manualRuleStates.PENDING_FOR_DELETION;
			return (
				<div data-spec="user-actions" className={styles.user_actions}>
					<RWComponent>
						<Edit
							onClick={() => goToDetail(val.id)}
							data-spec="edit-automation-ruke"
						/>
					</RWComponent>
					<RWComponent>
						{(this.isProccessManager() ||
							(this.isRWComponent() && !isPendingForDeletion)) && (
							<Delete
								onClick={() => this.openDeleteRuleModal(val)}
								data-spec="delete-automation-ruke"
							/>
						)}
					</RWComponent>
				</div>
			);
		};

		renderStatus = (val) => {
			const isManualRule = val.automationRuleType.code === ruleTypes.ManualRule;
			const manualRuleStatus = val.automationRuleStatus.name;
			return (
				<div data-spec="rule-status">
					{isManualRule ? (
						<span className={styles.rule_status}>
							<span
								className={cn(
									styles.circle,
									styles[manualRuleStatus.replace(/\s/g, '_').toLowerCase()]
								)}
							/>
							<FormattedMessage
								id={`AUTOMATION.RULE_STATUS_${localizationHelper(
									manualRuleStatus
								)}`}
								defaultMessage={manualRuleStatus}
							/>
						</span>
					) : (
						<span className={styles.rule_status}>
							<span className={cn(styles.circle, styles.implemented)} />
							<FormattedMessage
								id="AUTOMATION.RULE_STATUS_IMPLEMENTED"
								defaultMessage="Implemented"
							/>
						</span>
					)}
				</div>
			);
		};

		totalCount = () => {
			const { totalCount, dataLimit } = this.props;

			return Math.ceil(totalCount / dataLimit);
		};

		renderEmail = (val) => (
			<div data-spec="automation-rule-user-id" className={styles.email_wrap}>
				<a href={`mailto:${val.userId}`}>{val.userId}</a>
			</div>
		);

		renderNotes = (val) => {
			const hasNotes =
				val.automationRuleNotes && val.automationRuleNotes.length > 0;
			return (
				<div data-spec="rule-notes">
					{hasNotes ? (
						<span
							className={styles.clickable}
							onClick={() => this.openNotesModal(val.id)}
						>
							<FormattedMessage
								id="AUTOMATION.VIEW_NOTES"
								defaultMessage="View Notes"
							/>
						</span>
					) : null}
				</div>
			);
		};

		getOptions = (target) => {
			const {
				totalCount,
				dataLimit,
				selectedPage,
				setDataOffset,
				setSelectedPage
			} = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {
				tableOptions: {
					showPagination: totalCount > dataLimit,
					pageCount: this.totalCount(),
					forcePage: selectedPage,
					onPageChange: (data) =>
						newOnPageChange(
							data,
							this.getRules,
							dataLimit,
							setDataOffset,
							setSelectedPage
						)
				},
				customComponents: {
					platform: {
						type: 'custom',
						component: (val) => this.renderPlatform(val)
					},
					category: {
						type: 'custom',
						component: (val) => this.renderCategory(val)
					},
					trigger: {
						type: 'custom',
						component: (val) => this.renderTrigger(val)
					},
					active: {
						type: 'custom',
						component: (val) => this.renderActive(val)
					},
					createdAt: {
						type: 'custom',
						component: (val) => this.renderDate(val.createdAt)
					}
				}
			};

			if (isMobile) {
				options.header = rulesTableHeadersMobile;
				options.tableOptions = {
					...options.tableOptions,
					headerNameComponent: (val) => this.renderNameMobileView(val)
				};
			}
			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions,
					fixedFirstColumn: true,
					firstColumnMarked: true,
					enableTableGrabbing: true
				};
				options.header = rulesTableHeadersDesktop;
				options.customComponents = {
					...options.customComponents,
					name: {
						type: 'custom',
						component: (val) => this.renderName(val)
					},
					userActions: {
						type: 'custom',
						component: (val) => this.renderUserActions(val)
					},
					status: {
						type: 'custom',
						component: (val) => this.renderStatus(val)
					},
					userId: {
						type: 'custom',
						component: (val) => this.renderEmail(val)
					},
					notes: {
						type: 'custom',
						component: (val) => this.renderNotes(val)
					}
				};
			}
			return options;
		};

		isRWComponent = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(['mnc.automation_rw'], user);
		};

		isProccessManager = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(['system.managemanualrules_rw'], user);
		};

		openDeleteRuleModal = (rule) => {
			const isManualRule =
				rule.automationRuleType.code === ruleTypes.ManualRule;
			const modalTitle =
				this.isProccessManager() || !isManualRule ? (
					<FormattedMessage
						id="AUTOMATION.DELETE_AUTOMATION_RULE_TITLE"
						defaultMessage="You are about to delete rule {ruleName}"
						values={{ ruleName: rule.name }}
					/>
				) : (
					<FormattedMessage
						id="AUTOMATION.REQUEST_DELETION_TEXT"
						defaultMessage="You are about to Request a Deletion of a Rule"
					/>
				);

			const modalHeader =
				this.isProccessManager() || !isManualRule ? (
					<FormattedMessage
						id="AUTOMATION.DELETE_AUTOMATION_RULE_HEADER"
						defaultMessage="Rule deletion"
					/>
				) : (
					<FormattedMessage
						id={'AUTOMATION.REQUEST_DELETION_TITLE'}
						defaultMessage={'Automation Rule Deletion Request'}
					/>
				);
			this.setState({
				isDeleteRuleModalOpen: true,
				ruleId: rule.id,
				modalTitle,
				modalHeader,
				ruleDeletion: this.isProccessManager() || !isManualRule
			});
		};

		closeDeleteRuleModal = () => {
			this.setState({
				isDeleteRuleModalOpen: false,
				requestDeletion: null,
				ruleId: null
			});
		};

		openNotesModal = (ruleId) => {
			this.setState({
				isNotesModalOpened: true,
				ruleId
			});
		};

		closeNotesModal = () => {
			this.setState({
				isNotesModalOpened: false
			});
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		clearFilters = () => {
			this.getRules();
		};

		onChange = () => {
			this.getRules();
		};

		onApply = () => {
			this.getRules();
		};

		createFilterData = (data) => {
			const filterDataKeys = Object.keys(data);
			let filter = {};

			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					if (item === 'm2mAccounts') {
						filter = {
							...filter,
							[`${item}`]: data[item].m2mAccounts
								.filter(
									(m2mAccount) =>
										m2mAccount.platformName &&
										['ACC', 'DCP', 'WING', 'GMNA', 'POD3', 'POD19'].includes(
											m2mAccount.platformName.toUpperCase()
										)
								)
								.map((m2mAccount) => ({
									value: m2mAccount.m2mAccountId,
									label: m2mAccount.m2mAccountId,
									checked: false
								}))
						};
					} else {
						filter = {
							...filter,
							[`${item}`]: data[item].resultList.map((filterItem) => ({
								value: filterItem.id,
								label: filterItem.automationPlatform
									? `${filterItem.name} ${filterItem.automationPlatform.name}`
									: `${filterItem.name}`,
								checked: false
							}))
						};
					}
				});
			return filter;
		};

		deleteRule = () => {
			const { deleteRule, requestForDeletion } = this.props;
			const { ruleId, ruleDeletion } = this.state;
			ruleDeletion ? deleteRule(ruleId) : requestForDeletion(ruleId);
		};

		render() {
			const {
				isDeleteRuleModalOpen,
				modalHeader,
				modalTitle,
				ruleDeletion,
				isNotesModalOpened,
				ruleId
			} = this.state;
			const {
				rulesRequest,
				rules,
				deleteRuleRequest,
				messages,
				requestForDeletionRequest
			} = this.props;
			const isAnyRequest =
				rulesRequest || deleteRuleRequest || requestForDeletionRequest;
			const isDataEmpty = rules && rules.length === 0;

			const params = {
				service: getAutomationRulesFiltersService,
				createFilterData: this.createFilterData
			};

			return (
				<div data-spec="automation-rules">
					<ActionBar
						openFilterModal={this.openFilterModal}
						clearFilters={this.clearFilters}
						onChange={this.onChange}
						isDisabled={isAnyRequest}
						isDataEmpty={isDataEmpty}
					/>
					<FilterModal
						type="checkbox"
						messages={messages}
						onApply={this.onApply}
						params={params}
					/>
					{isDeleteRuleModalOpen && (
						<DeleteModal
							show
							onClose={this.closeDeleteRuleModal}
							onConfirm={this.deleteRule}
							modalHeader={modalHeader}
							modalTitle={modalTitle}
							requestDeletion={!ruleDeletion}
						/>
					)}

					{isNotesModalOpened && (
						<NotesModal show onClose={this.closeNotesModal} id={ruleId} />
					)}
					{isAnyRequest && <Loader />}
					{!isAnyRequest && rules && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							options={this.getOptions}
						/>
					)}
					{!isAnyRequest && isDataEmpty && (
						<NoData
							table
							title={
								<FormattedMessage
									id="AUTOMATION_RULES.NO_DATA_TITLE"
									defaultMessage="No Automation Rules Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="AUTOMATION_RULES.NO_DATA_MESSAGE"
									defaultMessage="There are No Automation Rules to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { number, func, bool, object, array } = PropTypes;

	RulesComponent.propTypes = {
		dataLimit: number,
		dataOffset: number,
		selectedPage: number,
		setDataOffset: func,
		setSelectedPage: func,
		rulesRequest: bool,
		rulesFail: bool,
		rules: array,
		filterData: object,
		getRules: func,
		goToDetail: func,
		totalCount: number,
		deleteRule: func,
		deleteRuleRequest: bool,
		deleteRuleSuccess: bool,
		messages: object,
		openFilterModal: func,
		user: object,
		requestForDeletion: func,
		requestForDeletionRequest: bool,
		requestForDeletionSuccess: bool
	};

	return RulesComponent;
};

export default Rules;
