import React, { PureComponent } from 'react';
import { func, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, submit } from 'redux-form';
import { required } from '../../../../utils/validators';

import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import styles from './RequestedFeatureModal.scss';

const submitForm = (values, _, props) => {
	const { rejectRequestedFeature, approveRequestedFeature, permission } = props;
	if (values.reject) {
		rejectRequestedFeature({ id: permission.id, note: values.note });
	} else {
		approveRequestedFeature({ id: permission.id, note: values.note });
	}
};

class RequestedFeatureModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			page: 'promt'
		};
	}

	setReject = () => {
		const { initialize } = this.props;
		this.setState(
			{
				page: 'reject'
			},
			() => {
				initialize({ reject: true });
			}
		);
	};

	setAccept = () => {
		const { initialize } = this.props;
		this.setState(
			{
				page: 'accept'
			},
			() => {
				initialize({ reject: false });
			}
		);
	};

	renderBody = () => {
		const { page } = this.state;
		const { handleSubmit, permission } = this.props;

		if (page === 'promt') {
			return (
				<div data-spec="body-message" className={styles.body}>
					<div className={styles.modal_message}>
						Whould you like to enable the access for
						{permission.name}
					</div>
				</div>
			);
		}
		if (page === 'reject') {
			return (
				<div data-spec="body-message" className={styles.body}>
					<form onSubmit={handleSubmit}>
						<div className={styles.modal_message}>
							{`You are about to Deny the Access for ${permission.name}`}
						</div>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.ENTER_YOUR_NOTE_HERE"
								defaultMessage="Enter your note here"
							>
								{(formattedValue) => (
									<Field
										label={
											<FormattedMessage
												id="ONBOARDING.NOTE"
												defaultMessage="Note"
											/>
										}
										componentClass="textarea"
										dataSpec="note"
										component={Input}
										name="note"
										placeholder={formattedValue}
										validate={[required]}
									/>
								)}
							</FormattedMessage>
						</div>
					</form>
				</div>
			);
		}
		if (page === 'accept') {
			return (
				<div data-spec="body-message" className={styles.body}>
					<form onSubmit={handleSubmit}>
						<div className={styles.modal_message}>
							{`You are about to Allow the Access for ${permission.name}`}
						</div>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.ENTER_YOUR_NOTE_HERE"
								defaultMessage="Enter your note here"
							>
								{(formattedValue) => (
									<Field
										label={
											<FormattedMessage
												id="ONBOARDING.NOTE"
												defaultMessage="Note"
											/>
										}
										componentClass="textarea"
										dataSpec="note"
										component={Input}
										name="note"
										placeholder={formattedValue}
										validate={[required]}
									/>
								)}
							</FormattedMessage>
						</div>
					</form>
				</div>
			);
		}
	};

	renderFooter = () => {
		const { onCancel, dispatch, invalid } = this.props;
		const { page } = this.state;

		if (page === 'promt') {
			return (
				<div data-spec="buttons" className={styles.default_buttons}>
					<Button
						onClick={onCancel}
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					/>
					<div>
						<Button
							onClick={this.setReject}
							variant="primary"
							label={<FormattedMessage id="NO" defaultMessage="No" />}
						/>
						<Button
							variant="primary"
							onClick={this.setAccept}
							label={<FormattedMessage id="YES" defaultMessage="Yes" />}
						/>
					</div>
				</div>
			);
		}
		if (page === 'reject' || page === 'accept') {
			return (
				<div data-spec="buttons">
					<Button
						onClick={onCancel}
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					/>
					<Button
						variant="primary"
						onClick={() => dispatch(submit('RequestedFeatureModalForm'))}
						label={<FormattedMessage id="OK" defaultMessage="OK" />}
						disabled={invalid}
					/>
				</div>
			);
		}
	};

	render() {
		const { onCancel, show } = this.props;

		return (
			<Modal data-spec="dashboard-prompt-modal" show={show} onClose={onCancel}>
				<ModalHeader
					title={
						<FormattedMessage
							id="OBOARDING.FEATURE_REQUESTED"
							defaultMessage="Feature Requested"
						/>
					}
				/>
				<ModalBody className={styles.modal_body}>{this.renderBody()}</ModalBody>
				<ModalFooter data-spec="ds-modal-footer">
					{this.renderFooter()}
				</ModalFooter>
			</Modal>
		);
	}
}

RequestedFeatureModal.propTypes = {
	onCancel: func,
	show: bool,
	invalid: bool,
	initialize: func,
	permission: object,
	handleSubmit: func,
	dispatch: func
};

const RequestedFeatureModalForm = reduxForm({
	form: 'RequestedFeatureModalForm',
	onSubmit: submitForm
})(RequestedFeatureModal);

export default RequestedFeatureModalForm;
