import { connect } from 'react-redux';
import AttachmentsTable from './AttachmentsTable';

import { downloadReportRequestURL } from '../../redux/invoiceReport/actions';

import { invoiceAttachmentDownload } from '../../redux/invoiceAttachment/actions';

const mapDispatchToProps = (dispatch) => ({
	downloadInvoice: (fileName, m2mAccountId) =>
		dispatch(
			downloadReportRequestURL({
				reportFileName: `${fileName}.pdf`,
				reportCategory: 'BillingInvoiceReport',
				m2mAccountId
			})
		),
	invoiceAttachmentDownload: (data) => dispatch(invoiceAttachmentDownload(data))
});

export default connect(null, mapDispatchToProps)(AttachmentsTable);
