export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const ADD_USERS_MODAL = 'ADD_USERS_MODAL';
export const REMOVE_USER_MODAL = 'REMOVE_USER_MODAL';
export const PAUSE_USER_MODAL = 'PAUSE_USER_MODAL';
export const INVITE_USERS = 'INVITE_USERS';
export const ADD_USER_GROUP_MODAL = 'ADD_USER_GROUP_MODAL';
export const PAUSE_USER = 'PAUSE_USER';
export const REACTIVATE_USER = 'REACTIVATE_USER';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_ADDRESS_TYPES = 'GET_ADDRESS_TYPES';
export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_FIRST_ADDRESS_STATES = 'GET_FIRST_ADDRESS_STATES';
export const GET_SECOND_ADDRESS_STATES = 'GET_SECOND_ADDRESS_STATES';
export const GET_THIRD_ADDRESS_STATES = 'GET_THIRD_ADDRESS_STATES';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_USER_USAGE_DATA_CSV = 'GET_USER_USAGE_DATA_CSV';
