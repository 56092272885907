import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../Ticketing';

const Desktop = (props) => {
	const { tickets, getOptions, messages } = props;
	return (
		<div data-spec="desktop">
			<Table
				data-spec="ticketing-table-desktop"
				data={tickets.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { shape, array, objectOf, string, func } = PropTypes;

Desktop.propTypes = {
	tickets: shape({
		resultList: array
	}),
	getOptions: func,
	messages: objectOf(string),
};

export default hoc()(Desktop);
