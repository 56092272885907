export default {
	// COMMON
	'ONBOARDING.EDIT': 'Revisar',
	'ONBOARDING.DELETE': 'Excluir',
	'ONBOARDING.NAME': 'Nome',
	'ONBOARDING.DESCRIPTION': 'Descrição',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': 'Nome da conta',
	'ONBOARDING.FRIENDLY_NAME': 'Apelido',
	'ONBOARDING.ACCOUNT_ID': 'ID da conta',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Criar nova empresa',
	'ONBOARDING.IOTC_CUSTOMERS': 'Clientes do IoT Console',
	'ONBOARDING.COMPANIES': 'Empresas',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'Nenhuma empresa disponível',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': 'Não há nenhuma empresa para exibir nesta tabela no momento',
	'ONBOARDING.COMPANY_NAME': 'Nome da empresa',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'p. ex.: AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'Nome da conta de MNC',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'Nome da conta de M2M',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'Número da conta de IOT-C',
	'ONBOARDING.SIM_ORDERS': 'Pedidos de SIM',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Proprietário técnico', - removido da Conta criada por
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Conta criada por',
	'ONBOARDING.VIEW_MC': 'Visualizar MC',
	'ONBOARDING.DATE_CREATED': 'Data criado',
	'ONBOARDING.ACTIONS': 'Ações',
	'ONBOARDING.PLATFORMS': 'Plataformas',
	'ONBOARDING.ACTIVATION_DENIED': 'A solicitação não pode ser processada!',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Conta ativada',
	'ONBOARDING.CONTACT_SYSADMIN': 'Entre em contato com o administrador do IoT Console para obter mais ajuda.',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': 'As configurações da tabela de empresa foram atualizadas com êxito.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': 'As colunas da tabela das empresas não puderam ser atualizadas no momento. Tentar novamente.',
	'ONBOARDING.RETRY': 'Tente novamente',

	// SEARCH MENU
	'ONBOARDING.BY': 'Por ',
	'ONBOARDING.BUSINESS_NAME': 'Nome da empresa',
	'ONBOARDING.CONTACT_PERSON': 'Pessoa de contato',
	'ONBOARDING.PLATFORM_ID': 'ID da plataforma',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'ID do IoT Console: ',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'Contas de M2M da empresa',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': 'Você criou com êxito uma conta do IoT Console para:',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': 'Número do Gateway IoT associado com esta conta:',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Criar nova conta da empresa',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': 'Não pode conter espaços nem números',
	'ONBOARDING.IDM_SERVCE_NAME': 'Nome do serviço da IDM',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Visível para o cliente',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Empresa ou nome de fantasia da pessoa jurídica',
	'ONBOARDING.COMPANY_CONTACTS': 'Contatos da empresa',
	'ONBOARDING.ATT_CONTACTS': 'Contatos da AT&T',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'Tipo de suporte vTM',
	'ONBOARDING.FULL_TSM': 'TSM completo (Todos os tíquetes, não importa a gravidade, vá para a equipe TSM)',
	'ONBOARDING.PARTIAL_TSM': 'TSM parcial (Tíquetes de gravidade 1, vá para a equipe TSM: gravidade 2 e 3, vá para a equipe Central de Ajuda Off-line) ',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care (Todos os tíquetes, não importa a gravidade, vá para a equipe IoT Priority Care)',
	'ONBOARDING.NO_SUPPORT': 'Sem suporte (Todos os tíquetes, não importa a gravidade, vá para a equipe Central de Ajuda Off-line)',
	'ONBOARDING.CONTACT': 'Contato',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Nome amigável da empresa',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Razão social da empresa',
	'ONBOARDING.COMPANY_CONTACT': 'Contato da empresa',
	'ONBOARDING.ATT_CONTACT': 'Contato da AT&T',
	'ONBOARDING.COMPANY': 'Empresa',
	'ONBOARDING.COUNTRY': 'País',
	'ONBOARDING.STATE_PROVINCE': 'Estado/Província',
	'ONBOARDING.STREET_ADDRESS': 'Endereço do logradouro',
	'ONBOARDING.ADDRESS_LINE_1': 'Linha de endereço 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Linha de endereço 2 (opcional)',
	'ONBOARDING.CITY_TOWN': 'Cidade/Município',
	'ONBOARDING.ZIP_POSTAL_CODE': 'CEP/Código Postal',
	'ONBOARDING.COMPANY_DOMAIN': 'Domínio da empesa',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'Domínio de e-mail da empresa',
	'ONBOARDING.FULL_NAME': 'Nome completo',
	'ONBOARDING.PHONE_NUMBER': 'Telefone',
	'ONBOARDING.EMAIL_ADDRESS': 'Endereço de e-mail',
	'ONBOARDING.SIM_OPTIONS': 'Opções de SIM',
	'ONBOARDING.ORDER_MOVE_SIMS': 'Pedir ou mudar SIMs',
	'ONBOARDING.ORDERING': 'Pedindo',
	'ONBOARDING.TRANSFER': 'Transferir',
	'ONBOARDING.SIM_TRANSFER': 'Transferência de SIM',
	'ONBOARDING.TRANSFERING_SIM': 'Transferência de um SIM de uma conta de M2M para outra',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': 'Pedir SIMs, selecionar ou atualizar lista de SIMs',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'Visualizar ou revisar as informações de cobrança',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': 'Plataforma',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Descrição da plataforma',
	'ONBOARDING.APNS': 'APNs',
	'ONBOARDING.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'ONBOARDING.EDIT_ACTIONS': 'Revisar contas',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'Revisar plataforma M2M',
	'ONBOARDING.ADD_M2M_PLATFORM': 'Adicionar plataforma M2M',
	'ONBOARDING.MC_ACCOUNT_ID': 'ID da conta de IOT-C',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'AT&T Control Center',
	'ONBOARDING.PLATFORM_CCIP': 'Programa de Integração de Centrais de Controle',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Plataforma de conexão de dispositivos',
	'ONBOARDING.PLATFORM_GMNA': 'GM América do Norte',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Enterprise',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': 'Plataforma M2M',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Direito de acesso à rede',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Direitos de acesso à rede',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Nome do perfil de comunicação',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': 'Não há direitos de acesso à rede disponíveis',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': 'Não há direitos de acesso à rede disponíveis para exibir nesta tabela no momento',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Adicionar novo direito de acesso à rede',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Adicionar direito de acesso à rede',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Revisar direito de acesso à rede',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Revisar direitos de acesso à rede',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Selecionar direito de acesso à rede',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Selecionar direito de acesso',
	'ONBOARDING.PULL_DATA': 'Obter dados',
	'ONBOARDING.HLR_TEMPLATE': 'Detalhes do modelo HLRHSS',
	'ONBOARDING.HOTLINE_NUMBER': 'Número da linha direta',
	'ONBOARDING.VOICE_MT': 'MT de voz',
	'ONBOARDING.ROAMING_LTE': 'LTE roaming',
	'ONBOARDING.DATA': 'Dados',
	'ONBOARDING.DATA_LTE': 'LTE de dados',
	'ONBOARDING.VOICE_MO': 'MO de voz',
	'ONBOARDING.INTERNATIONAL_VOICE': 'Voz internacional',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': 'Bloquear MO de SMS internacional, exceto no país de residência',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Bloquear números Premium',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Tipo de serviço Camel',
	'ONBOARDING.APNS_PDPINDEX': 'Índice PDP',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'IP fixo',
	'ONBOARDING.APNS_SECURITY': 'Segurança',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Assinatura',
	'ONBOARDING.ADD_NEW_APN': 'Adicionar novo APN',
	'ONBOARDING.PLATFORM_DETAILS': 'Detalhes da plataforma',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Roaming',
	'ONBOARDING.SMS_MO': 'MO de SMS',
	'ONBOARDING.SMS_MT': 'MT de SMS',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Direito qualificado de acesso à rede',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': 'Tipo de pagamento',
	'ONBOARDING.USAGE_LIMIT': 'Limite de uso',
	'ONBOARDING.RATE_PLAN': 'Plano de tarifas',
	'ONBOARDING.RATE_PLANS': 'Planos de tarifas',
	'ONBOARDING.RATE_PLAN_NAME': 'Nome do plano de tarifas',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'Nenhum plano de tarifas disponível',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Adicionar novo plano de tarifas',
	'ONBOARDING.EDIT_RATE_PLAN': 'Revisar plano de tarifas',
	'ONBOARDING.PLAN_ID': 'ID do plano',
	'ONBOARDING.RATE_PLAN_TYPE': 'Tipo do plano de tarifas',
	'ONBOARDING.ACCOUNT_CHARGE': 'Cobrança da conta',
	'ONBOARDING.TERM': 'Termo',
	'ONBOARDING.TIERING_FLAG': 'Sinalizador de camada',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'Por MRC do assinante',
	'ONBOARDING.CURRENCY': 'Moeda',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Uso de dados incluídos',
	'ONBOARDING.USAGE_LIMIT_MB': 'Limite de uso (MB)',
	'ONBOARDING.PLAN_TYPE': 'Tipo de plano',
	'ONBOARDING.TYPE': 'Tipo',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'NOME DA CONTA DA FATURA',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'ID DO PLANO DE TARIFAS DE M2M',
	'ONBOARDING.PRICE_PLAN_ID': 'ID DO PLANO DE PREÇOS',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'TIPO DE ITEM DA ASSINATURA',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'ID DO ITEM DA ASSINATURA DE M2M',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'ID DA CONTA INTERNA DE M2M',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'NÚMERO DA CONTA DE M2M',
	'ONBOARDING.DEFAULT_PLAN': 'PLANO PADRÃO',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'ID do provedor de serviços',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'ID do direito de acesso à rede',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': 'Não há nenhum plano de tarifas para exibir nesta tabela no momento',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SIM SKU
	'ONBOARDING.SIM_SKU': 'SKU do SIM',
	'ONBOARDING.DELETE_SIM_SKU': 'Excluir SKU do SIM',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': 'Ao excluir este SKU serão excluídos todos os pedidos de SIM vinculados a ele. Tem certeza que quer continuar?',
	'ONBOARDING.SIM_ORDERING': 'Pedido de SIM',
	'ONBOARDING.CURRENT_SIM_SKUS': 'SKUs de SIMs atuais',
	'ONBOARDING.ADD_SIM_SKU': 'Adicionar SKU do SIM',
	'ONBOARDING.EDIT_SIM_SKU': 'Revisar SKU do SIM',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'Digitar SKU do SIM aqui',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'Digitar descrição do SIM',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Restrições contratuais',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': 'Definir as restrições no processo de pedido do SIM.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Quantidade mín. permitida',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Quantidade mín.',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Quantidade máx. permitida',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Quantidade máx.',
	'ONBOARDING.INCREMENT': 'Incrementar',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Por período',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Selecionar um período',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'quantidade mín.',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'quantidade máx.',
	'ONBOARDING.UNIT_PRICE': 'Preço unitário',
	'ONBOARDING.ALLOW_TO_ORDER': 'Permitir que se faça o pedido',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'SKU revisado com êxito',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'SKU não pode ser revisado',
	'ONBOARDING.RATE_PLAN_ID': 'ID do plano de tarifas',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'Nenhum SKU de SIM disponível',

	// Plataforma
	'ONBOARDING.OPERATOR': 'Operador',
	'ONBOARDING.LAUNCH_MC': 'Iniciar MC',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Operador (Opcional)',
	'ONBOARDING.MCC': 'MCC',
	'ONBOARDING.MNC': 'MNC',
	'ONBOARDING.POD': 'Pod',
	'ONBOARDING.MCC_TOOLTIP': 'Mobile Country Code (MCC) é um número exclusivo de 3 dígitos para identificar um país.',
	'ONBOARDING.MNC_TOOLTIP': 'Mobile Network Code (MNC) é um número exclusivo de 2 ou 3 dígitos para identificar uma rede móvel.',
	'ONBOARDING.PLATFORM_SETUP': 'Configuração da plataforma',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': 'A configuração da plataforma estará disponível após a adição bem-sucedida da plataforma. ',
	'ONBOARDING.SETTINGS': 'Configurações',
	'ONBOARDING.EDIT_PLATFORM': 'Revisar plataforma',
	'ONBOARDING.ADD_PLATFORM': 'Adicionar plataforma',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'Não há plataformas disponíveis.',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Faltam informações obrigatórias',

	// Revisar plataforma
	'ONBOARDING.SIM_LOADING': 'SIM carregando',
	'ONBOARDING.GATEWAY': 'Gateway',
	'ONBOARDING.ENTER_GATEWAY': 'Digitar Gateway',
	'ONBOARDING.BILLING_INFORMATION': 'Informações de cobrança',
	'ONBOARDING.BILLING_PLATFORM': 'Plataforma de cobrança',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Número de conta da fatura',

	// Relatórios
	'ONBOARDING.REPORT': 'Relatório',
	'ONBOARDING.REPORTS': 'Relatórios',
	'ONBOARDING.COMPANY_REPORTS': 'Relatórios',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'Erro de integração de IDM',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': 'Parece ter ocorrido um problema com a integração de IDM. ',
	'ONBOARDING.ONBOARDING_STATUS': 'Status de integração',
	'ONBOARDING.SUCCESS': 'Êxito',
	'ONBOARDING.ERROR': 'Erro',

	'ONBOARDING.MUST_BE_BETWEEN': 'Deve ter entre ',
	'ONBOARDING.AND': ' e ',
	'ONBOARDING.CHARACTERS_LONG': ' caracteres',
	'ONBOARDING.DEFAULT': 'Padrão',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Adicionar planos de tarifas para {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': 'Configurações dos relatórios da empresa atualizados com êxito',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': 'Erro ao atualizar os relatórios da empresa',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': 'Definir relatórios a serem habilitados para {companyName}',
	'ONBOARDING.REPORTS_SUBTITLE': 'Os relatórios assinalados serão exibidos no portal do IoT Console para serem baixados pelos usuários',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': 'Adicionar direitos de acesso à rede para {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': 'Vincular direitos de acesso à rede habilitados para o AT&T Control Center desta conta.',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Principal endereço da empresa',
	'ONBOARDING.COMPANY_DETAILS': 'Detalhes da empresa',
	'ONBOARDING.ACCOUNTS': 'Contas',
	'ONBOARDING.EDIT_ACCOUNT': 'Revisar conta',
	'ONBOARDING.VIEW_ACCOUNT': 'Visualizar conta',
	'ONBOARDING.SIM_LOADING_SUB': 'Quando os SIMS estiverem carregados para esta conta, o Gateway IoT geralmente faz isso automaticamente quando a conta da plataforma M2M for estabelecida.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Importar do Gateway IoT',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Configuração da conta',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': 'Selecionar serviços pertinentes a serem habilitados nesta conta. Se você não vir o serviço desejado, um pode ser acrescentado no nível da contra primária, sujeito ao processo de aprovação. ',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Adicionar direitos de acesso à rede',
	'ONBOARDING.ADD_RATE_PLANS': 'Adicionar planos de tarifas',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': 'Visualizar ou revisar relatórios a serem exibidos',
	'ONBOARDING.USERS': 'Usuários',
	'ONBOARDING.USERS_SUB': 'Ao clicar no botão abaixo, você passará à tela de identidade da empresa onde poderá convidar novos usuários para participar desta conta, ou adicionar usuários existentes a esta empresa.',
	'ONBOARDING.CREATE_USERS': 'Criar usuários',
	'ONBOARDING.USER_ACCESS': 'Acesso do usuário',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Criar/adicionar usuário',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Verificar informações da nova empresa',
	'ONBOARDING.SUBMIT': 'Enviar',
	'ONBOARDING.CREATE_COMPANY': 'Criar empresa',
	'ONBOARDING.NEW_ACCOUNT': 'Nova conta',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': 'Deve haver uma conta ou conta de assinatura na plataforma de M2M antes que possam ser adicionadas no MNC. Siga as etapas abaixo para criar uma conta de M2M a fim de ser provisionada no IoT Console. ',
	'ONBOARDING.VALIDATE': 'Validar',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Validar nome da conta',
	'ONBOARDING.CREATE_ACCOUNT': 'Criar conta',
	'ONBOARDING.ADD_ACCOUNT': 'Adicionar conta',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'Nenhuma conta disponível',
	'ONBOARDING.M2M_PLARFORM': 'Plataforma M2M',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'SKU criado com êxito',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'Não foi possível criar o SKU',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': 'Revisar direitos de acesso à rede para {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Revisar conta de assinatura',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'Visualizar conta de assinatura',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Revisar planos de tarifas para {companyName}',
	'ONBOARDING.VIEW': 'Visualizar',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'Visualizar direitos de acesso à rede',
	'ONBOARDING.VIEW_RATE_PLANS': 'Visualizar planos de tarifas',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Criar conta de assinatura',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'Nova conta de assinatura para {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'Gerar conta de M2M',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Nome da conta de assinatura',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': 'Selecionar planos de tarifas para esta conta',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': 'Selecionar direitos de acesso à rede para esta conta',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': 'Aviso: Atualmente não há suporte para nenhum país além dos EUA',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Atribuir para conta de assinatura',
	'ONBOARDING.ENDPOINT_ID': 'ID do endpoint',
	'ONBOARDING.NETWORK_OPERATOR': 'Operador de rede',
	'ONBOARDING.STATUS': ' Status',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': ' endpoints serão movidos para uma conta',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Confirmar movimentação dos Endpoints',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': 'Depois de iniciada esta movimentação, os endpoints serão movidos dentro de poucos minutos. Deseja continuar com esta ação?',
	'ONBOARDING.ENDPOINTS': 'Endpoints',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'Mover para a conta de assinatura',
	'ONBOARDING.FROM': 'De',
	'ONBOARDING.TO': 'Para',
	'ONBORDING.SIM_SKU': 'SKU do SIM',
	'ONBORDING.UNIT_PRICE': 'Preço unitário',
	'ONBORDING.DATE_SUBMITED': 'Data de envio',
	'ONBORDING.EDIT_DELETE_SKU': 'Revisar/Excluir Sku',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'Não há endpoints disponíveis.',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'Não há SIMs disponíveis.',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'Selecionar SIM para esta conta',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': 'Não há nenhuma conta para exibir nesta tabela no momento',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': 'Mensalmente',
	'ONBOARDING.PREPAID': 'Pré-pago',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Mensalmente- Assinante individual',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Mensalmente - Pool fixo- Único',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Essencial - Pool fixo mensal',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Mensalmente - Pool flexível',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': 'Essencial - Pool flexível mensal',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': 'Mensalmente - Nível de uso de pool flexível',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Mensalmente - Bolt On',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Pré-pago - Assinante individual',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Pré-pago - Pool flexível',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Pré-pago - Pool fixo',
	'ONBOARDING.PREPAID_ADD_ON': 'Pré-pago - Complemento',
	'ONBOARDING.PREPAID_EVENT': 'Pré-pago - Evento',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Pré-pago - Evento empilhado',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': 'Detalhes de uso de dados',
	'ONBOARDING.SMS_USAGE_DETAILS': 'Detalhes de uso de SMS',
	'ONBOARDING.IMSI_SNAPSHOT': 'Instantâneo de IMSI',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Diretório de assinantes',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Instantâneo de assinantes',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Alterações de assinantes',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Instantâneo diário da conta',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Instantâneo do plano de tarifas',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Instantâneo do plano de comunicação',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Referência do ciclo de cobrança',
	'ONBOARDING.CARRIER_REFERENCE': 'Referência da operadora',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Referência do tipo do plano de tarifas',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'Referência de alteração do SIM',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': 'Referência da causa de fechamento do registro de uso',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Instantâneo da zona do plano de tarifas',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Campos personalizados do provedor de serviço',
	'ONBOARDING.USER_SNAPSHOT': 'Instantâneo do usuário',
	'ONBOARDING.NEW_SUBSCRIBERS': 'Novos assinantes',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Assinantes combinados',
	'ONBOARDING.COMBINED_USAGE': 'Uso combinado',
	'ONBOARDING.APN_SNAPSHOT': 'Instantâneo de APN',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Campos personalizados do provedor de serviço',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Contas combinadas',
	'ONBOARDING.DAILY_USAGE_RAW': 'Uso bruto diário',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'Todos SIMs ativos',
	'ONBOARDING.DAILY_USAGE': 'Uso diário',
	'ONBOARDING.ALL_SMS_MESSAGES': 'Todas mensagens de SMS',
	'ONBOARDING.SMS_DETAIL': 'Detalhe de SMS',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'Resumo acumulativo de ICCID',
	'ONBOARDING.SIM_STATE_REFERENCE': 'Referência do estado de SIM',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Detalhe de uso de voz',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'Todos SIMs atribuídos',
	'ONBOARDING.INVOICE_DETAIL': 'Detalhe da fatura',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Resumo do plano de tarifas',
	'ONBOARDING.OTHER_CHARGES': 'Outras cobranças',
	'ONBOARDING.USAGE_SUMMARY': 'Resumo de uso',
	'ONBOARDING.ACTIVATION_CHARGES': 'Cobranças de ativação',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Detalhe de uso das zonas dos planos de tarifas',
	'ONBOARDING.INVOICE_CHARGES': 'Cobranças da fatura',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Cobranças de uso em pool',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Cobranças de assinantes',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Cobranças de uso não em conjunto',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Uso no atacado do assinante',
	'ONBOARDING.FUNDED_PACKAGES': 'Pacotes financiados',
	'ONBOARDING.TRIAL_COST_SHARE': 'Distribuição da avaliação de custo',
	'ONBOARDING.REVENUE_SHARE': 'Distribuição da receita',
	'ONBOARDING.BOUNTY': 'Prêmio',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Detalhes de uso de dados',
	'ONBOARDING.SMS_DETAILS': 'Detalhes de SMS',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Delta de assinaturas',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Mensagens de SMS não enviadas',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': 'Receita zero no atacado do assinante',
	'ONBOARDING.INVOICE_DETAILS': 'Detalhes da fatura',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Detalhes de uso da zona do plano de tarifas',
	'ONBOARDING.BILLING_INVOICE': 'Fatura de cobrança',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1 mês',
	'ONBOARDING.3_MONTHS': '3 meses',
	'ONBOARDING.1_WEEK': '1 semana',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Conta transferida com êxito',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Falha ao transferir a conta',
	'ONBOARDING.MY_COMPANY': 'Minha empresa',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': 'Grupos de usuários',
	'ONBOARDING.USER_COUNT': 'Conta do usuário',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Usuários atualmente ativos',
	'ONBOARDING.FIRST_NAME': 'Nome',
	'ONBOARDING.LAST_NAME': 'Sobrenome',
	'ONBOARDING.JOB_TITLE': 'Título do cargo',
	'ONBOARDING.EMAIL': 'E-mail',
	'ONBOARDING.USER_GROUP': 'Grupo de usuários',
	'ONBOARDING.ACCOUNT_ACCESS': 'Acesso à conta',
	'ONBOARDING.CREATE_NEW_GROUP': 'Criar novo grupo',
	'ONBOARDING.INVITE_USERS': 'Convidar usuários',
	'ONBOARDING.ADD_USERS': 'Adicionar usuários',
	'ONBOARDING.EDIT_USER_PROFILE': 'Revisar perfil do usuário',
	'ONBOARDING.USER_INFORMATION': 'Informações do usuário',
	'ONBOARDING.ADDRESSES': 'Endereços',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Contas atribuídas',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Legal e regulatório',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Criar novo grupo de usuários',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': 'Criar grupos de direito de acesso personalizado para seus usuários.',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name}{lastName}aceito',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'Contrato de Licença para Usuário Final (EULA)',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': 'Se não concordar com os termos do EULA, você pode excluir esta conta.',
	'ONBOARDING.ON_DATE': 'em {date}.',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': 'Se não concordar com os termos do EULA, você pode excluir esta conta.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': 'Digite o endereço de e-mail dos usuários que você deseja convidar. Os usuários terão acesso à conta da empresa assim que aceitarem o link de convite enviado no e-mail de notificação.',
	'ONBOARDING.ADD_NEW_USER': 'Adicionar novo usuário',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Pode receber SMS',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': 'Estado/Província/Território/Região',
	'ONBOARDING.COMPANY_EMAIL': 'E-mail da empresa',
	'ONBOARDING.POSTAL_CODE': 'Código Postal',
	'ONBOARDING.BUSINESS': 'Empresa',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': 'A privacidade do usuário é importante para nós, e não vendemos seus dados de usuário.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': 'O EULA contém informações detalhadas sobre como protegemos sua privacidade, direito de apagar e outros regulamentos sobre conformidade.',
	'ONBOARDING.STREET_ADDRESS_2': 'Endereço do logradouro 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Endereço do logradouro 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'Cidade ou município',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'Endereço igual ao endereço principal',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Adicionar outro endereço',
	'ONBOARDING.YES': 'Sim',
	'ONBOARDING.NO': 'Não',
	'ONBOARDING.PRIMARY_ADDRESS': 'Endereço principal',
	'ONBOARDING.ADDRESS_TYPE': 'Tipo de endereço',
	'ONBOARDING.SECONDARY_NUMBER': 'Número secundário',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Idioma de preferência',
	'ONBOARDING.PRIMARY_NUMBER': 'Número principal',
	'ONBOARDING.UPLOAD_IMAGE': 'Carregar imagem',
	'ONBOARDING.FILTER_USER_LIST': 'Filtrar lista de usuários',
	'ONBOARDING.CANCEL': 'Cancelar',
	'ONBOARDING.ADD': 'Adicionar',
	'ONBOARDING.USER': 'Usuário',
	'ONBOARDING.GROUP': 'Grupo',
	'ONBOARDING.PASSWORD_RESET': 'Redefinição da senha',
	'ONBOARDING.SELECT_ACTION': 'Selecionar ação',
	'ONBOARDING.ACTIVE_STATUS': 'Status ativo',
	'ONBOARDING.PENDING_STATUS': 'Status pendente',
	'ONBOARDING.ADD_MORE_USERS': 'Adicionar mais usuários',
	'ONBOARDING.ENTITLEMENT_NAME': 'Nome do direito de acesso',
	'ONBOARDING.NO_ACCESS': 'Sem acesso',
	'ONBOARDING.READ_ONLY': 'Somente leitura',
	'ONBOARDING.READ_WRITE': 'Leitura-gravação',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Permissões do Grupo Admin.',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Revisar permissões do grupo',
	'ONBORDING.USER_GROUPS': 'Grupos de usuários',
	'ONBOARDING.CREATE_GROUP': 'Criar grupo',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Selecionar grupo de usuários existente',
	'ONBOARDING.GROUP_NAME': 'Nome do grupo',
	'ONBOARDING.PAUSE': 'Pausar',
	'ONBOARDING.REACTIVATE': 'Reativar',
	'ONBOARDING.ACTIVATE': 'Ativar',
	'ONBOARDING.REMOVE': 'Remover',
	'ONBOARDING.RESEND_INVITE': 'Reenviar convite',
	'ONBOARDING.CANCEL_INVITE': 'Cancelar convite',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': 'Tem certeza que deseja remover o usuário?',
	'ONBOARDING.USER_PROFILE': 'Perfil do usuário',
	'ONBOARDING.SUSPEND': 'Suspender',
	'ONBOARDING.USER_MANAGEMENT': 'Gerenciamento de usuários',
	'ONBOARDING.MAILING': 'Endereçamento',
	'ONBOARDING.BILLING': 'Cobrança',
	'ONBOARDING.SECONDARY': 'Secundário',
	'ONBOARDING.PRIMARY': 'Principal',
	'ONBOARDING.PHONE': 'Telefone',
	'ONBOARDING.ENGLISH': 'Inglês dos EUA',
	'ONBOARDING.SAVE_USER': 'Salvar usuário',
	'ONBOARDING.BULK_UPLOAD': 'Carregamento em massa',
	'ONBOARDING.FOR_BULK_UPLOADS': 'para carregamentos em massa',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'Baixar modelo em CSV',
	'ONBOARDING.SEND_INVITE': 'Enviar o convite',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': 'Pausar usuários causa perda do acesso às contas da {companyName}. Pausar usuário não será permitido para solicitar acesso, mas o administrador pode reativar a conta do usuário, para voltar a ter acesso, usuários pausados precisarão redefinir a senha de suas contas.',
	'ONBOARDING.PAUSE_USER': 'Pausar usuário',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Grupo de usuários atribuído',
	'ONBOARDING.ACCESS_LEVEL': 'Nível de acesso',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': 'Enviar link de redefinição de senha para o e-mail',
	'ONBOARDING.EDIT_USER': 'Revisar usuário',
	'ONBOARDING.RESET_PASSWORD': 'Redefinir senha',
	'ONBOARDING.CURRENT_STATUS': 'Status atual',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'Visualizar permissões atribuídas ao usuário',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Gerenciar permissões individuais',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Permissões individuais',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Permissões em vigor',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Grupo de usuários atribuído',
	'ONBOARDING.ACTION': 'Ação',
	'ONBOARDING.DELETE_PERMISSIONS': 'Excluir permissões',
	'ONBOARDING.VIEW_PERMISSIONS': 'Visualizar permissões',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Adicionar outro grupo de usuários',
	'ONBOARDING.PENDING_INVITATIONS': 'Convites pendentes',
	'ONBOARDING.PERMISSIONS': 'Permissões',
	'ONBOARDING.CLOSE': 'Fechar',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Salvar permissões individuais',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': 'O acesso será concedido para as seguintes contas de M2M',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Gerenciamento de acesso',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Usuários do provedor de serviço',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Grupo de usuários do provedor de serviço',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': 'Adicionar usuários do provedor de serviço para possibilitar o gerenciamento de contas de clientes e integrar novos clientes.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'Nenhum grupo de usuários disponível',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': 'Se for preciso realizar uma operação restrita, solicite ajuda do seu contato técnico.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': 'Algumas configurações são limitadas aos super usuários da AT&T.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': 'Os grupos de usuários são formados de acordo com um modelo menos privilegiado.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'Direitos de acesso em nível do sistema',
	'ONBOARDING.DENY_ACCESS': 'Negar acesso',
	'ONBOARDING.ALLOW_READ': 'Permitir acesso',
	'ONBOARDING.ALLOW_WRITE': 'Permitir gravação',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Revisar permissões do grupo',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'Nenhuma permissão disponível',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': 'Não há direitos de acesso à rede disponíveis para exibir nesta tabela no momento',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'Revisar contas de M2M',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'Mover para a conta de assinatura',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Pode receber e-mail',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Acesso irrestrito',
	'ONBOARDING.ACTIVE': 'Ativo’,// excluir mais tarde',
	'ONBOARDING.PAUSED': 'Pausado’,// excluir mais tarde',
	'ONBOARDING.VIEW_USER_GROUP': 'Visualizar grupo de usuários',
	'ONBOARDING.DELETE_USER_GROUP': 'Excluir grupo de usuários',
	'ONBOARDING.SAVE': 'Salvar',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': 'Selecionar o grupo ao qual o usuário deve pertencer:',

	'ONBOARDING.ALLOW_ACCESS': 'Permitir acesso',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Criar nova senha',
	'ONBOARDING.NEW_PASSWORD': 'Nova senha',
	'ONBOARDING.CONFIRM_PASSWORD': 'Confirmar senha',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Digitar sua nova senha',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Digite sua nova senha',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Voltar para login',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'Informação não fornecida.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': 'para adicionar detalhes do endereço.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Revisar contas atribuídas',
	'ONBOARDING.EDIT_PERMISSIONS': 'Revisar permissões',
	'ONBOARDING.SUSPENDED': 'Suspenso',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Convite pendente disponível',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': 'Na próxima etapa você poderá sintonizar novas permissões de grupo',
	'ONBOARDING.GRANT_ACCESS': 'Conceder acesso',
	'ONBOARDING.SELECT_USER_GROUP': 'Selecione grupo de usuários',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': 'Nenhum convite do sistema disponível',
	'ONBOARDING.ADD_USER_GROUP': 'Adicionar grupo de usuários',
	'ONBOARDING.ADD_ADDRESS': 'Adicionar endereço',
	'ONBOARDING.CROP_IMAGE': 'Cortar imagem',
	'ONBOARDING.CROP': 'Cortar',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': 'Esta ação é irreversível, e todo histórico do usuário será esquecido pelos nossos sistemas.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': 'Excluir o perfil removerá o acesso do usuário a todas as contar às quais você tem acesso atualmente.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Excluir perfil de {name} {lastName}',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Digitar a fase de confirmação',
	'ONBOARDING.DELETE_PROFILE': 'Excluir perfil',
	'ONBOARDING.DELETE_USER': 'Excluir usuário',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': 'Justificativa para a pausa (opcional)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': 'Tem certeza que deseja continuar?',
	'ONBOARDING.REACTIVATE_USER': 'Reativar usuário',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': 'Se clicar em Sim, você receberá em breve um e-mail para reiniciar a senha.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Redefinir a senha do usuário',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': 'Tem certeza que deseja continuar a reiniciar a senha?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Atribuir grupo de usuários',
	'ONBOARDING.ASSIGN': 'Atribuir',
	'ONBOARDING.AND_OTHERS': 'E {totalCount} de outros...',
	'ONBOARDING.MY_PROFILE': 'Meu perfil',
	'ONBOARDING.EDIT_USER_GROUP': 'Revisar grupo de usuários',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Revisar permissões individuais',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'Visualizar permissões individuais',
	'ONBOARDING.NO_USERS_AVAILABLE': 'Nenhum usuário disponível',
	'ONBOARDING.CONTACTS': 'Contatos',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': 'Tem certeza que deseja realizar essa operação?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Remover grupo de usuários',
	'ONBOARDING.CONTACT_TYPE': 'Tipo de contato',
	'ONBOARDING.CANCEL_INVITATION': 'Cancelar convite',
	'ONBOARDING.RESEND_INVITATION': 'Reenviar convite',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Reativar grupo de usuários',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Suspender grupo de usuários',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': 'Nenhum grupo de usuários para ser atribuído',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'O tipo de endereço existe',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Adicionar novo endereço',
	'ONBOARDING.ADD_NEW_CONTACT': 'Adicionar novo contato',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Adicionar outro contato',
	'ONBOARDING.COMPANY_FEATURES': 'Recursos da empresa',
	'ONBOARDING.INFO_NOT_PROVIDED': 'Informação não fornecida',
	'ONBOARDING.COMPANY_SETTINGS': 'Configurações da empresa',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': 'Se você já tiver uma conta, prossiga para o login.',
	'ONBOARDING.ACCOUNT_ACTIVATED': 'Em breve você receberá um e-mail com instruções de como criar sua senha e acessar sua conta.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': 'A redefinição de senha é gerenciada pelo AT&T Global Sign-On',

	//  Plataforma de cobrança
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Tipo de endereço
	'ONBOARDING.BILLING_ADDRESS': 'Endereço de cobrança',
	'ONBOARDING.HOME_ADDRESS': 'Endereço residencial',

	//  Tipo de contato
	'ONBOARDING.MAIN_CONTACT': 'Contato principal',
	'ONBOARDING.BACKUP_CONTACT': 'Contato de reserva',
	'ONBOARDING.TECHNICAL_OWNER': 'Proprietário técnico',

	//  Tipo de endereço
	'ONBOARDING.SHIPPING': 'Remessa',
	'ONBOARDING.HOME': 'Página inicial',
	'ONBOARDING.ANOTHER': 'Outro',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'Lançar o IoT Console',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': 'Esse número é necessário para exibir faturas no IoT Console.',
	'ONBOARDING.BILLING_REPORTS': 'Relatórios de cobrança',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': 'Esta caixa deve ser marcada para visualizar detalhes da fatura.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': 'Detalhes da cobrança não têm suporte nesta plataforma.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': 'Visualizar ou revisar a configuração de cobrança',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'ID da conta não encontrada.',
	'ONBOARDING.ARE_YOU_SURE': 'Tem certeza?',
	'ONBOARDING.REMOVE_CONTACT': 'Remover contato',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'ID de Pod, locatário ou instância',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': 'Plataforma de conexão de dispositivos',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': 'Tem certeza que deseja realizar essa ação?',

	// Added after 7.4
	'ONBOARDING.ANONYMIZED': 'Anonimizado',
    'ONBOARDING.ACQUISITION_BOUNTY': 'Prêmio de aquisição',
    'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Prêmio de aquisição combinado',
    'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Subsídio de hardware combinado',
    'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': 'Pacotes financiados de parceiros combinados',
    'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Cobranças de uso em pool combinadas',
    'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Cobranças de assinaturas combinadas',
    'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Distribuição da avaliação de custo combiinada',
    'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Resumo de uso combinado',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': 'Cobranças de faturas mensais combinadas',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': 'Detalhes de faturas mensais combinadas',
    'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': 'Uso mensal não em conjunto cobrado combinado',
    'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Outras cobranças mensais combinadas',
    'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Pacotes financiados de parceiros',
    'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'Stats do dispositivo da conta por mês',
    'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': 'Relatório‘ de uso da conta por rede de satélite',
};
