import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageTitle from '../../../Shared/views/PageTitleView';

import { renderTabs, onNewTabClick } from '../../../../utils/tabHelpers';

import BatchActions from '../../views/BatchActionsView';
import BatchReporting from '../../views/BatchReportsView';
import TabsBar from '../../../Shared/views/TabsBarView';

import styles from './BatchHistory.scss';

const tabNames = {
	ACTIONS: 'actions',
	REPORTS: 'reports'
};

class BatchHistory extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: tabNames.ACTIONS
		};
	}

	getBatchHistoryTabs = () => {
		const { dataLimit } = this.props;
		const { activeTab } = this.state;
		const historyTabs = [
			{
				id: tabNames.ACTIONS,
				name: (
					<FormattedMessage
						id="BATCH_HISTORY.BATCH_ACTIONS"
						defaultMessage="Batch Actions"
					/>
				),
				component: (
					<BatchActions
						key="batch-history-actions"
						dataLimit={dataLimit}
					/>
				),
				isActive: tabNames.ACTIONS === activeTab,
				onClick: () => onNewTabClick(tabNames.ACTIONS, this)()
			},
			{
				id: tabNames.REPORTS,
				name: (
					<FormattedMessage
						id="BATCH_HISTORY.BATCH_REPORTS"
						defaultMessage="Batch Reports"
					/>
				),
				component: (
					<BatchReporting
						key="batch-history-reports"
						dataLimit={dataLimit}
					/>
				),
				isActive: tabNames.REPORTS === activeTab,
				onClick: () => onNewTabClick(tabNames.REPORTS, this)()
			}
		];
		return historyTabs;
	};

	render() {
		return (
			<div data-spec="batch-history">
				<PageTitle
					title={
						<FormattedMessage
							id="BATCH_HISTORY.TITLE"
							defaultMessage="Batch History"
						/>
					}
				/>
				<div className={styles.tabs}>
					<TabsBar tabs={this.getBatchHistoryTabs()} />
				</div>

				{renderTabs(this.getBatchHistoryTabs())}
			</div>
		);
	}
}
const { number } = PropTypes;
BatchHistory.propTypes = {
	dataLimit: number
};

export default BatchHistory;
