import React from 'react';
import { array, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import { required } from '../../../utils/validators';

import CurrentContext from '../../../utils/currentContext';
import ImageSelector from '../../../utils/imageSelector';

import Select, { components } from '../DropdownSelectNew';

import styles from './LanguageSelector.scss';

const UnitedStates = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const France = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const Spain = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const Italy = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const Netherlands = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const Brazil = ImageSelector(CurrentContext.theme, 'svgs/brazil.svg');
const Germany = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const PRC = ImageSelector(CurrentContext.theme, 'svgs/china.svg');

const LanguageSelectorPropTypes = {
	options: array,
	isDisabled: bool,
	isLoading: bool,
	isRequired: bool
};

const LanguageSelectorDefaultProps = {
	options: [],
	isDisabled: false,
	isLoading: false
};

export default function LanguageSelector({ options, isDisabled, isLoading, isRequired }) {
	// Render Flags in options
	const renderFlags = (data) => {
		switch (data.value) {
			case 1:
				return <UnitedStates data-spec="UnitedStates" />;
			case 2:
				return <France data-spec="France" />;
			case 3:
				return <Spain data-spec="Spain" />;
			case 4:
				return <Italy data-spec="Italy" />;
			case 5:
				return <Netherlands data-spec="Netherlands" />;
			case 6:
				return <Germany data-spec="Germany" />;
			case 7:
				return <Brazil data-spec="Brazil" />;
			case 8:
				return <PRC data-spec="PRC" />;
			// no default
		}
	};

	const Option = (props) => {
		const { data } = props;
		return (
			<components.Option {...props} data-spec={data.value}>
				<div className={styles.option}>
					<span>{renderFlags(data)}</span>
					{data.label}
				</div>
			</components.Option>
		);
	};

	Option.propTypes = {
		data: object
	};

	const SingleValue = (props) => {
		const { data } = props;
		return (
			<components.SingleValue {...props} data-spec={data.value}>
				<div className={styles.option}>
					<span>{renderFlags(data)}</span>
					{data.label}
				</div>
			</components.SingleValue>
		);
	};

	SingleValue.propTypes = {
		data: object
	};

	return (
		<Field
			data-spec="language-selector"
			component={Select}
			name="languageId"
			label={
				<FormattedMessage
					id="ONBOARDING.PREFERRED_LANGUAGE"
					defaultMessage="Preferred Language"
				/>
			}
			components={{
				Option,
				SingleValue
			}}
			options={options.map((item) => ({
				value: item.id,
				label: item.name
			}))}
			styles={{
				control: (base) => ({
					...base,
					minHeight: 36
				})
			}}
			cleanValue
			isDisabled={isDisabled}
			loading={isLoading}
			validate={isRequired ? [required] : null}
		/>
	);
}

LanguageSelector.propTypes = LanguageSelectorPropTypes;
LanguageSelector.defaultProps = LanguageSelectorDefaultProps;
