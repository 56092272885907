import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';

import { globalPaginationNumber } from '../../../../utils/constants';
import BatchHistory from '../../components/BatchHistory';

const BatchHistoryView = (props) => (
	<>
		<DesktopMediaQuery>
			<BatchHistory {...props} dataLimit={globalPaginationNumber.desktop} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<BatchHistory {...props} dataLimit={globalPaginationNumber.desktop} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<BatchHistory {...props} dataLimit={globalPaginationNumber.mobile} />
		</MobileMediaQuery>
	</>
);
export default BatchHistoryView;
