export default {
	// filters
	CURRENCY: 'Currency',
	BILLABLE: 'Billable',
	TAX_STATUS: 'Tax Status',
	SUBSCRIBER_STATUS: 'Subscriber Status',
	MONTHLY_RATE_PLAN: 'Monthly Rate Plan',
	STANDARD_RATE_PLAN: 'Standard Rate Plan',
	PREPAID_TERMS_CHARGED: 'Prepaid Terms Charged',
	PLAN_TYPE: 'Plan Type',
	ZONE: 'Zone',
	ROAMING_ZONE: 'Roaming Zone',
	GROUP_NAME: 'Group Name',
	THE_PLAN_TYPE: 'The Plan Type',
	ACTIVATION_TYPE: 'Activation Type',
	CHARGE_TYPE: 'Charge Type',
	TECH_TYPE: 'Tech Type',
	PRIMARY_PLACE: 'Primary Place',
	DISTRICT_COUNTY: 'District County',
	DISTRICT_CITY: 'District City',
	PRIMARY_PLACE_OF_USE: 'Primary Place of Use',
	// notifications
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS':
		'Succesfully updated user settings',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'Unable to update user settings',
	// invoices
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'Account ID',
	'ANALYTICS_INVOICES.ADD_NOTE': 'Add a Note',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Add Attachment',
	'ANALYTICS_INVOICES.COUNTRY_NAME': 'Country',
	'ANALYTICS_INVOICES.CUSTOMER_ID': 'Customer ID',
	'ANALYTICS_INVOICES.INVOICE_DATE': 'Invoice Date',
	'ANALYTICS_INVOICES.DOCUMENT_ID': 'Document ID',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Sub-Account',
	'ANALYTICS_INVOICES.BILLING_ACCOUNT_IDS': 'Billing Account IDs',
	'ANALYTICS_INVOICES.NOTES': 'Notes',
	'ANALYTICS_INVOICES.LINK': 'Invoices',
	'ANALYTICS_INVOICES.BY': 'By ',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Invoice details data: ',
	'ANALYTICS_INVOICES.NA': 'N/A',
	'ANALYTICS_INVOICES.YES': 'Yes',
	'ANALYTICS_INVOICES.NO': 'No',
	'ANALYTICS_INVOICES.INVOICE_ID': 'Invoice ID',
	'ANALYTICS_INVOICES.BILLING_CYCLE': 'Billing Cycle',
	'ANALYTICS_INVOICES.ACCOUNT_ID': 'Account ID',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Account Name',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'Operator Account ID',
	'ANALYTICS_INVOICES.DEVICES': 'Devices',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Total Charge',
	'ANALYTICS_INVOICES.CURRENCY': 'Currency',
	'ANALYTICS_INVOICES.DATA_VOLUME': 'Data Volume (MB)',
	'ANALYTICS_INVOICES.SMS_VOLUME': 'Sms Volume',
	'ANALYTICS_INVOICES.BILLABLE': 'Billable (Yes or No)',
	'ANALYTICS_INVOICES.BILL': 'Billable',
	'ANALYTICS_INVOICES.DATA_RATES': 'Data Rates',
	'ANALYTICS_INVOICES.DATE': 'Invoice Date',
	'ANALYTICS_INVOICES.BILLING_DETAILS': 'Billing Details',
	'ANALYTICS_INVOICES.DUE_DATE': 'Due Date',
	'ANALYTICS_INVOICES.OPERATOR_ID': 'Operator ID',
	'ANALYTICS_INVOICES.PUBLISHED': 'Published',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Standard Overage',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Standard Roaming',
	'ANALYTICS_INVOICES.TAXABLE': 'Taxable Account',
	'ANALYTICS_INVOICES.TAX_STATUS': 'Tax Status',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Account Segment',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'View/Print/Save',
	'ANALYTICS_INVOICES.INVOICE_TITLE': 'Invoice Details',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Download Invoice',
	'ANALYTICS_INVOICES.ENDPOINTS': 'Endpoints',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Prepaid Subscriptions',
	'ANALYTICS_INVOICES.ADDONS': 'Add-Ons',
	'ANALYTICS_INVOICES.RATE_PLANS': 'Rate Plans',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Rate Plan Zones',
	'ANALYTICS_INVOICES.BILLING_GROUPS': 'Billing Groups',
	'ANALYTICS_INVOICES.DOWNLOAD': 'Download',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'Download PDF',
	'ANALYTICS_INVOICES.UPLOAD': 'Upload',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Upload Invoice',
	'ANALYTICS_INVOICES.ACTIVATIONS': 'Activations',
	'ANALYTICS_INVOICES.DISCOUNTS': 'Discounts',
	'ANALYTICS_INVOICES.TAX': 'Tax',
	'ANALYTICS_INVOICES_DETAILS.OTHER_CHARGES': 'Other Charges',
	'ANALYTICS_INVOICES.SURCHARGES': 'Surcharges',
	'ANALYTICS_INVOICES.TECH_TYPES': 'Tech Types',
	'ANALYTICS_INVOICES.OTHER_CHARGES': 'Other Charges',
	'ANALYTICS_INVOICES.NO_DATA': 'No Invoices Available',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE':
		'There are No Invoices to show you right now',
	'ANALYTICS_INVOICES.TITLE': 'Invoices',
	'ANALYTICS_INVOICES.DATA_DETAILS': 'Data Details',
	'ANALYTICS_INVOICES.SMS_DETAILS': 'SMS Details',
	'ANALYTICS_INVOICES.TAXES': 'Taxes',
	// endpoints table
	'ANALYTICS_INVOICES.ICCID': 'ICCID',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Monthly Proration Index',
	'ANALYTICS_INVOICES.DEVICE_ID': 'Device ID',
	'ANALYTICS_INVOICES.CUSTOMER': 'Customer',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Monthly Rate Plan',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Prepaid Terms Charged',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Standard Rate Plan',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'Subscriber Status',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Subscription Charge',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Fixed Pool Charge',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'SMS MO Volume(msg)',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'SMS MT Volume(msg)',
	// prepaid subscription table
	'ANALYTICS_INVOICES.MSISDN': 'MSISDN',
	'ANALYTICS_INVOICES.RATE_PLAN': 'Rate Plan',
	'ANALYTICS_INVOICES.PLAN_VERSION': 'Plan Version',
	'ANALYTICS_INVOICES.PLAN_TYPE': 'Plan Type',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Usage Proration Index',
	'ANALYTICS_INVOICES.TERM_START_DATE': 'Term Start Date',
	'ANALYTICS_INVOICES.TERM_END_DATE': 'Term End Date',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'Included Data Usage MB',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'Included SMS MO (msg)',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'Included SMS MT (msg)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Included Voice (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'Included Voice MO (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'Included Voice MT (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD': 'Included CSD (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'Included CSD MO (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'Included CSD MT (m:ss)',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE':
		'Term to Date Data Usage (Primary Zone)',
	'ANALYTICS_INVOICES.VOICE_VOLUME': 'Voice Volume (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Voice MO Volume (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Voice MT Volume (m:ss)',
	// Addons Table
	'ANALYTICS_INVOICES.ADDON_SIZE': 'Add On Size (MB)',
	'ANALYTICS_INVOICES.ZONE': 'Zone',
	'ANALYTICS_INVOICES.ADDON_CHARGES': 'Add On Charges',
	'ANALYTICS_INVOICES.DATE_PURCHASED': 'Date Purchased',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Base Plan Applied To',
	// Rate Plan Table
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS':
		'Activation Period Commitment Subs',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Active Subscriptions',
	'ANALYTICS_INVOICES.ACTIVE_TIER': 'Active Tier',
	'ANALYTICS_INVOICES.DATA_CHARGE': 'Data Charge',
	'ANALYTICS_INVOICES.INCLUDED_DATA': 'Included Data',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Included Data Mode',
	'ANALYTICS_INVOICES.INCLUDED_SMS': 'Included SMS',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Included SMS MO Mode',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Included SMS Mode',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Included SMS MT Mode',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Included Voice MO Mode',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Included Voice Mode',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS':
		'Minimum Activation Period Subs',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Non-Roaming Data Charge',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Non-Roaming Data Volume',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Payment Type',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Prepaid Activations',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Roaming Data Charge',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Roaming Data Volume',
	'ANALYTICS_INVOICES.SMS_CHARGE': 'Sms Charge',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Subscription Field',
	'ANALYTICS_INVOICES.VOICE_CHARGE': 'Voice Charge',
	// Rate Plan Zone table
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Active Overage Rate',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Included Data Capped',
	'ANALYTICS_INVOICES.INCLUDED_MODE': 'Included Mode',
	'ANALYTICS_INVOICES.PRIORITY': 'Priority',
	'ANALYTICS_INVOICES.ROAMING_ZONE': 'Roaming Zone',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Total Included Usage (MB)',
	//  Billing groups table
	'ANALYTICS_INVOICES.GROUP_NAME': 'Group Name',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Total Included',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'SMS MO Charge',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'Included SMS MO (msgs)',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE':
		'Total Included SMS MO Usage',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO_USAGE':
		'Total Included Voice MO Usage',
	'ANALYTICS_INVOICES.VOICE_MO_CHARGE': 'Voice MO Charge',
	// Data details table
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Daily Rounding Adjustment',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'The Plan Type',
	'ANALYTICS_INVOICES.BULK_OVERAGE_ADJUSTMENT': 'Bulk Overage Adjustment',
	// sms details table
	'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Message Type',
	// activations table
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Activation Charge',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Activation Type',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Initial Activation Date',
	// discount table
	'ANALYTICS_INVOICES.CHARGE_TYPE': 'Charge Type',
	'ANALYTICS_INVOICES.DISCOUNT': 'Discount',
	// taxes table
	'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Primary Place',
	'ANALYTICS_INVOICES.MRC': 'MRC',
	'ANALYTICS_INVOICES.DATA': 'Data',
	'ANALYTICS_INVOICES.SMS': 'SMS',
	'ANALYTICS_INVOICES.VOICE': 'Voice',
	'ANALYTICS_INVOICES.ACTIVATION': 'Activation',
	'ANALYTICS_INVOICES.VPN': 'VPN',
	'ANALYTICS_INVOICES.SIM': 'SIM',
	'ANALYTICS_INVOICES.MRF': 'MRF',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': 'Tech Type',
	// surcharges table
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Primary Place of Use',
	'ANALYTICS_INVOICES.FEDERAL': 'Federal',
	'ANALYTICS_INVOICES.STATE': 'State',
	'ANALYTICS_INVOICES.COUNTY': 'County',
	'ANALYTICS_INVOICES.CITY': 'City',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'District County',
	'ANALYTICS_INVOICES.DISTRICT_CITY': 'District City',
	// tech type table
	'ANALYTICS_INVOICES.PLAN_ID': 'Plan ID',
	'ANALYTICS_INVOICES.VERSION_ID': 'Version ID',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'No. of 2G Devices',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': '2G Tech Type Charge',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'No. of 3g Devices',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': '3g Tech Type Charge',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'No. of 4g Devices',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': '4g Tech Type Charge',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Tech Type Charge Frequency',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Total Tech Type Charge',

	// Subscription charges:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Subscription Charges',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Subscription Charge',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Data Charge',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'SMS Charges',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Activation Charge',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Tech Type Charge',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Fixed Discount Target',
	'ANALYTICS_INVOICES_DETAILS.TAX': 'Tax',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Total Charge',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Surcharge',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Due by ',

	// Endpoint stats:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Endpoint Stats',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'ENDPOINTS',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'ACTIVE SUBSCRIBERS',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'DATA VOLUME',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'SMS VOLUME',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'SERVICE REVENUE',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'VARIABLE DISCOUNT',

	// other charges table
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Charge Amount',
	'ANALYTICS_INVOICES.DESCRIPTION': 'Description',
	'ANALYTICS_INVOICES.QUANTITY': 'Quantity',
	'ANALYTICS_INVOICES.ORDER_NUMBER': 'Order Number',
	'ANALYTICS_INVOICES.REFERENCE_ID': 'Reference ID',
	// voice details table
	'ANALYTICS_INVOICES.PRORATION_INDEX': 'Proration Index',
	//  placeholderS
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Enter Your Note Here',
	'ANALYTICS_INVOICES.REMOVE': 'Remove',
	'ANALYTICS_INVOICES.EDIT_NOTE': 'Edit Note',
	'ANALYTICS_INVOICES.CANCEL': 'Cancel',
	'ANALYTICS_INVOICES.SUBMIT': 'Submit',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Attachments Added',
	//  edit table modal
	'ANALYTICS_INVOICES.APPLY_CHANGE': 'Apply Change',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Reset to Default',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Edit Table Parameters',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2':
		'Select Minimum of 2 to Apply to the Table. ',
	'ANALYTICS_INVOICES.OF': ' of ',
	'ANALYTICS_INVOICES.SELECTED': ' Selected',
	// invoice details
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'Data not Available',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE':
		'There is no Data for this Invoice',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE':
		'Invoice details database is empty for this invoice',
	'ANALYTICS_INVOICES.ADD_FILES': 'Invoice Files',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'View/Add/Edit',
	//  ATTACHMENTS MODAL
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Attachments for Invoice',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Edit Attachment',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Upload a File',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Delete Attachment',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Close',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Cancel',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Save',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Delete',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Upload File',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Upload Attachment',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Upload Invoice',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'File Name',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Description',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION':
		'You are About to Remove an Attachment from MC, This will permanently remove this file from the system, are you sure you want to perform this task?',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'File to Delete:',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'Attached File',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL':
		'File Name (If left blank the default file name will be used)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL':
		'File description visible to customer (optional)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER':
		'This invoice will be active in a few weeks.',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK':
		'Drag file or click to upload',
	'INVOICES_NOTES_UPDATE.SUCCESS': 'Succesfully updated invoice note.',
	//  NOTES MODAL
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'Update Succesfull',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE':
		'Your note has been successfully added.',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Update Failed',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE':
		'Your note has been successfully added.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE':
		'Upload Succesfull',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE':
		'Successfully Uploaded File.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE':
		'Error uploading',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE':
		'There was an error uploading this file to MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE':
		'Attachment Deleted',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE':
		'File has been removed from MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE':
		'Error Deleting',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE':
		'There was an error removing this file from MC.',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Invoice Date',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION':
		'Billing cycle is not valid, therefore invoice details cannot be displayed.',
	// ADD NEW INVOICE
	'INVOICES.ADD_NEW_INVOICE': 'Add New Invoice',
	'INVOICES.CANCEL': 'Cancel',
	'INVOICES.ADD_INVOICE': 'Add Invoice',
	'INVOICES.EDIT': 'Edit',
	'INVOICES.CONFIRM': 'Confirm',
	'INVOICES.CONFIRMATION': 'Confirmation',
	'INVOICES.INVOICE_DETAILS': 'Invoice Details',
	'ADD_INVOICE.INVOICE_ID': 'Invoice ID',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': 'M2M Account ID',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'Select M2M Account ID',
	'ADD_INVOICE.INVOICE_DATE': 'Invoice Date',
	'ADD_INVOICE.BILLING_CYCLE': 'Billing Cycle',
	'ADD_INVOICE.DATE_PLACEHOLDER': 'Select Date',
	'ADD_INVOICE.UPLOAD_FILE': 'Upload a File',
	'ADD_INVOICE.UPLOAD_FILE_NAME':
		'File Name (Optional: If left blank the file name will be used)',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER':
		'File Name (255 characters maximum)',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': 'File Name',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE':
		'You have succesfully added new invoice.',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE':
		'An error has occured while adding invoice. Please try again.',
	'ADD_INVOICE.TIME_STAMP': 'Attached on {date} at {time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION':
		"File name is not valid (please use _ instead of the whitespace and the following characters are not allowed: '!?<>%$()+=')",
	'INVOICES.REPORTS_DOWNLOAD_ERROR':
		'Could not locate the file you are looking for.',
	// overall
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE_EXPLANATION':
		'(Without tax & other charges)',
	'ANALYTICS_INVOICES.VOICE_DETAILS': 'Voice Details'
};
