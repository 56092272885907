import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import hoc from '../Functionalities';

import SecurityFeaturesModal from '../../SecurityFeaturesModal';
import BulkUploadModal from '../../BulkUploadModal';

import Button from '../../../../../lib/DigitalComponents/Button';

import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';

import styles from '../Functionalities.scss';

const SecurityFeaturesRW = UserAllowedToAccess(['mnc.securityfeatures_rw']);

const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');

class Desktop extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalShow: false,
			modalType: null,
			bulkUploadModalOpened: false
		};
	}

	toggleModal = (type) => {
		const {
			setSelectedItems,
			setSelectedItemsInit,
			selectedItems
		} = this.props;

		const { modalShow } = this.state;
		type === 'add' ? setSelectedItemsInit() : setSelectedItems(selectedItems);
		this.setState({
			modalShow: !modalShow,
			modalType: type
		});
	};

	openBulkUploadModal = () => {
		const { openBatchUploadModal } = this.props;
		openBatchUploadModal();
		this.setState({
			bulkUploadModalOpened: true
		});
	};

	onCloseBulkUploadModal = () => {
		const { closeBatchUploadModal } = this.props;
		closeBatchUploadModal();
		this.setState({
			bulkUploadModalOpened: false
		});
	};

	exportCsv = () => {
		const { exportToCsv, id, setSelectedItemsInit, selectedItems } = this.props;
		const ids = selectedItems.map((item) => item.id);
		const params = {
			searchParams: [
				{
					prop: 'id',
					propValue: ids,
					operator: 'in'
				}
			],
			additionalParams: {
				customerSecurityFeatureTypeId: id
			}
		};
		setSelectedItemsInit();
		exportToCsv(params);
	};

	renderButtons = () => {
		const { id, selectedItems, isAnyRequest } = this.props;
		return (
			<div className={styles.top_container} data-spec="security-features-list">
				<SecurityFeaturesRW>
					<div className={styles.add_buttons_container}>
						<div
							className={
								isAnyRequest
									? styles.add_container_disabled
									: styles.add_container
							}
							onClick={() => (isAnyRequest ? null : this.toggleModal('add'))}
						>
							<AddIcon className={styles.add_icon_plus} />
							<div className={styles.add_text}>
								<FormattedMessage
									id={`SECURITY_FEATURES.ADD_${id}`}
									defaultMessage="Add new security feature"
								/>
							</div>
						</div>
						<div className={styles.button_container}>
							{selectedItems && !isEmpty(selectedItems[0]) && (
								<Button
									label={
										<FormattedMessage
											id="SECURITY_FEATURES.EXPORT"
											defaultMessage="Export"
										/>
									}
									variant="primary"
									onClick={() => this.exportCsv()}
									dataSpec="add_graph_button"
									disabled={isAnyRequest}
								/>
							)}
							<Button
								label={
									<FormattedMessage
										id="BULK_UPLOAD"
										defaultMessage="Bulk Upload"
									/>
								}
								variant="primary"
								onClick={() => this.openBulkUploadModal()}
								dataSpec="add_graph_button"
								disabled={isAnyRequest}
							/>
							<Button
								label={
									<FormattedMessage
										id="SECURITY_FEATURES.EDIT"
										defaultMessage="Edit"
									/>
								}
								variant="primary"
								onClick={() => this.toggleModal('edit')}
								dataSpec="add_graph_button"
								disabled={
									(selectedItems && isEmpty(selectedItems[0])) || isAnyRequest
								}
							/>
						</div>
					</div>
				</SecurityFeaturesRW>
			</div>
		);
	};

	render() {
		const { bulkUploadModalOpened, modalShow, modalType } = this.state;
		const { selectedItems, messages, id } = this.props;

		return (
			<div data-spec="functionalities">
				{this.renderButtons()}
				{bulkUploadModalOpened && (
					<BulkUploadModal
						show
						onClose={this.onCloseBulkUploadModal}
						id={id}
						messages={messages}
					/>
				)}

				<SecurityFeaturesModal
					fields={selectedItems}
					type={modalType}
					id={id}
					messages={messages}
					show={modalShow}
					toggleModal={this.toggleModal}
				/>
			</div>
		);
	}
}

const { number, object, array, func, bool } = PropTypes;

Desktop.propTypes = {
	id: number,
	selectedItems: array,
	isAnyRequest: bool,
	setSelectedItems: func,
	setSelectedItemsInit: func,
	messages: object,
	openBatchUploadModal: func,
	closeBatchUploadModal: func,
	exportToCsv: func
};

export default hoc()(Desktop);
