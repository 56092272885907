/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
	reduxForm,
	Field,
	change as changeFormValues,
	untouch,
} from 'redux-form';
import { format, startOfDay, subWeeks, subMonths } from 'date-fns';
import { isEqual } from 'lodash';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import { isUserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { CurrencyFormatters } from '../../../../../utils/constants';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import {
	required,
	range,
	dateNotBefore,
	incremental,
	phoneFormat,
	maxLength,
	noWhiteSpaceOnly,
	onlyLettersAndSpaces,
} from '../../../../../utils/validators';

import StaticField from '../../../../Shared/components/StaticField';
import Button from '../../../../../lib/DigitalComponents/Button';
import Loading from '../../../../../lib/DigitalComponents/Loader';
import Input from '../../../../../lib/DigitalComponents/FieldGroup';
import Select, {
	components,
} from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Checkbox from '../../../../../lib/DigitalComponents/Checkbox';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import SimOrderConfirmationModal from '../../SimOrderConfirmationModal';

import styles from './Desktop.scss';
import hoc from '../SimOrderEdit';

const currencyFormatter = (value) =>
	new Intl.NumberFormat(
		CurrencyFormatters.usdDecimals.locale,
		CurrencyFormatters.usdDecimals.options
	).format(parseFloat(value || 0));

const quantityFormatter = (value) =>
	new Intl.NumberFormat(
		CurrencyFormatters.usdQuantity.locale,
		CurrencyFormatters.usdQuantity.options
	).format(parseFloat(value));

const Info = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');

const max255 = maxLength(255);

class SimOrderEdit extends Component {
	constructor(props) {
		super(props);

		const { initialValues } = props;
		const initialState = {
			selectedAccountID:
				(initialValues &&
					initialValues.platform &&
					initialValues.platform.m2mAccountId) ||
				'',
			selectedSkuId:
				(initialValues &&
					initialValues.platform &&
					initialValues.platform.m2mAccountId &&
					initialValues.platform.m2mAccountId) ||
				'',
			selectedSkuDescription:
				(initialValues &&
					initialValues.simSku &&
					initialValues.simSku.description) ||
				'',
			selectedSkuUnitPrice:
				(initialValues &&
					initialValues.simOrderUnitPrice &&
					initialValues.simOrderUnitPrice) ||
				(initialValues &&
					initialValues.simSku &&
					initialValues.simSku.unitPrice) ||
				0,
			quantity: (initialValues && initialValues.quantity) || 0,
			minQuantity:
				(props.restraints &&
					props.restraints.resultList &&
					props.restraints.resultList.length > 0 &&
					props.restraints.resultList[0].minQuantity) ||
				0,
			maxQuantity:
				(props.restraints &&
					props.restraints.resultList &&
					props.restraints.resultList.length > 0 &&
					props.restraints.resultList[0].maxQuantity) ||
				0,
			increment:
				(props.restraints &&
					props.restraints.resultList &&
					props.restraints.resultList.length > 0 &&
					props.restraints.resultList[0].increment) ||
				0,
			periodUnit:
				(props.restraints &&
					props.restraints.resultList &&
					props.restraints.resultList.length > 0 &&
					props.restraints.resultList[0].contractRestraintPeriod &&
					props.restraints.resultList[0].contractRestraintPeriod.unit) ||
				'',
			periodValue:
				(props.restraints &&
					props.restraints.resultList &&
					props.restraints.resultList.length > 0 &&
					props.restraints.resultList[0].contractRestraintPeriod &&
					props.restraints.resultList[0].contractRestraintPeriod.value) ||
				0,
			usedQuantity:
				(props.usedQuantity &&
					props.usedQuantity.resultList &&
					props.usedQuantity.resultList.length > 0 &&
					props.usedQuantity.resultList[0].quantity) ||
				0,
			isConfirmationModalOpen: false,
		};
		initialState.total =
			initialState.selectedSkuUnitPrice * initialState.quantity;
		initialState.limitValidator = this.quantityLimit(
			initialState.minQuantity,
			initialState.maxQuantity - initialState.usedQuantity,
			initialState.increment
		);
		initialState.rangeValidator = range(
			initialState.minQuantity,
			initialState.maxQuantity - initialState.usedQuantity
		);
		initialState.incrementalValidator = incremental(initialState.increment);

		this.state = initialState;
	}

	componentDidMount() {
		const {
			getPlatforms,
			id,
			initialValues,
			getRestraints,
			orderStatuses,
			getOrderStatuses,
			states,
			getStates,
			countries,
			getCountries,
			system
		} = this.props;

		if (!system) {
			getPlatforms({
				additionalParams: { include: ['PlatformType'] },
			});
			if (id && initialValues.platformId) {
				getRestraints({
					searchParams: [
						{
							prop: 'platformId',
							propValue: initialValues.platformId,
							operator: '=',
						},
					],
					additionalParams: { include: ['ContractRestraintPeriod'] },
				});
			}
			if (id && initialValues && initialValues.platformId) {
				this.getSkus(initialValues.platformId);
			}
			if (!states) {
				getStates({
					additionalParams: {
						dataSort: 'name ASC',
					},
					searchParams: [{ prop: 'countryId', operator: '=', propValue: 231 }],
				});
			}

			if (!countries) {
				getCountries({
					additionalParams: {
						dataSort: 'name ASC',
					},
					searchParams: [{ prop: 'id', operator: '=', propValue: 231 }],
				});
			}
		}
		if (!orderStatuses) {
			getOrderStatuses();
		}
	}

	componentDidUpdate(prevProps) {
		const { initialValues, restraints, usedQuantity } = this.props;

		const { minQuantity, maxQuantity } = this.state;

		if (initialValues !== prevProps.initialValues) {
			if (initialValues && initialValues.platformId) {
				this.getSkus(initialValues.platformId);
			}
		}

		if (!isEqual(restraints, prevProps.restraints)) {
			this.updateSimOrderParams(restraints);
		}

		if (!isEqual(usedQuantity, prevProps.usedQuantity)) {
			this.updateQuantity(usedQuantity, minQuantity, maxQuantity);
		}
	}

	handleFieldChange(e) {
		this.setState(
			{
				[e.target.name]: parseInt(e.target.value, 10),
			},
			() => {
				const { selectedSkuUnitPrice, quantity } = this.state;
				this.setState({
					total:
						parseFloat(selectedSkuUnitPrice || 0) * parseInt(quantity || 0, 10),
				});
			}
		);
	}

	getSkus(platformId) {
		const { getSkus } = this.props;
		getSkus({
			searchParams: [
				{ prop: 'platformId', propValue: platformId, operator: '=' },
				{ prop: 'allowedToOrder', propValue: true, operator: '=' },
			],
			additionalParams: {
				include: ['Platform', 'Platform.PlatformType'],
			},
		});
	}

	updateSimOrderParams = (restraints) => {
		const { formValues, getUsedQuantity } = this.props;
		const platformId =
			(formValues && formValues.platformId && formValues.platformId.value) || 0;
		const restraint =
			restraints &&
			restraints.resultList &&
			restraints.resultList.find((x) => x.platformId === platformId);
		const min = (restraint && restraint.minQuantity) || 0;
		const max = (restraint && restraint.maxQuantity) || 0;
		const inc = (restraint && restraint.increment) || 0;

		const unit =
			(restraints &&
				restraints.resultList &&
				restraints.resultList.length &&
				restraints.resultList[0].contractRestraintPeriod &&
				restraints.resultList[0].contractRestraintPeriod.unit) ||
			'';
		const value =
			(restraints &&
				restraints.resultList &&
				restraints.resultList.length &&
				restraints.resultList[0].contractRestraintPeriod &&
				restraints.resultList[0].contractRestraintPeriod.value) ||
			0;

		if (platformId && unit && value) {
			if (unit) {
				let afterDate;
				if (unit === 'week') {
					afterDate = subWeeks(new Date(), value);
				} else {
					afterDate = subMonths(new Date(), value);
				}

				getUsedQuantity({
					searchParams: [
						{ prop: 'platformId', propValue: platformId, operator: '=' },
						{
							prop: 'createdAt',
							propValue: `${afterDate}`,
							operator: '>=',
						},
					],
					aggregationParams: [
						{
							function: 'SUM',
							column: 'quantity',
							as: 'quantity',
						},
					],
				});
			}
		}

		this.setState({
			minQuantity: min,
			maxQuantity: max,
			usedQuantity: 0,
			increment: inc,
			periodUnit: unit,
			periodValue: value,
			limitValidator: this.quantityLimit(min, max, inc),
			rangeValidator: range(min, max),
			incrementalValidator: incremental(inc),
		});
	};

	updateQuantity = (usedQuantity, minQuantity, maxQuantity) => {
		const { increment } = this.state;
		const qty =
			(usedQuantity &&
				usedQuantity.resultList &&
				usedQuantity.resultList.length &&
				usedQuantity.resultList[0].quantity) ||
			0;

		this.setState({
			usedQuantity: qty,
			limitValidator: this.quantityLimit(
				minQuantity,
				maxQuantity - qty,
				increment
			),
			rangeValidator: range(minQuantity, maxQuantity - qty),
		});
	};

	Option = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.Option {...optionProps} data-spec={`${data.value}`}>
				<div
					data-spec={`${data.value}`}
					className={`${styles[this.renderStatusClass(data.value)]} ${
						styles.custom_option
					}`}
				>
					{children}
				</div>
			</components.Option>
		);
	};

	SingleValue = (optionProps) => {
		const { data } = optionProps;
		return (
			<components.SingleValue
				{...optionProps}
				data-spec={`${data.value}`}
				className={`${styles[this.renderStatusClass(data.value)]} ${
					styles.custom_option
				} ${styles.select_single_value}`}
			/>
		);
	};

	AllowedToAccess = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['mnc.simordering_rw'], user);
	};

	quantityLimit = (min, max, increment) => () =>
		((Number(increment) > Number(min) &&
			Number(min) + Number(increment) > Number(max)) ||
			Number(min) > Number(max)) && (
			<FormattedMessage
				id="QUANTITY_LIMIT_TEMPORARILY_REACHED"
				defaultMessage="Quantity limit temporarily reached"
			>
				{(formattedValue) => formattedValue}
			</FormattedMessage>
		);

	handlePlatformChange = (item) => {
		const { platforms, change, getRestraints } = this.props;

		const platform =
			platforms &&
			platforms.resultList &&
			platforms.resultList.find((f) => f.id === item.value);

		this.setState({
			selectedAccountID: platform && platform.m2mAccountId,
			selectedSkuId: '',
			selectedSkuDescription: '',
			selectedSkuUnitPrice: 0,
			total: 0,
		});
		change('quantity', '');
		getRestraints({
			searchParams: [
				{ prop: 'platformId', propValue: item.value, operator: '=' },
			],
			additionalParams: { include: ['ContractRestraintPeriod'] },
		});
		this.getSkus(item.value);
		this.resetFields('simOrderForm', { simSkuId: '' });
	};

	resetFields = (formName, fieldsObj) => {
		const { dispatch } = this.props;
		Object.keys(fieldsObj).forEach((fieldKey) => {
			dispatch(changeFormValues(formName, fieldKey, fieldsObj[fieldKey]));
			dispatch(untouch(formName, fieldKey));
		});
	};

	submitSimOrder = (values) => {
		const { editSimOrder, addSimOrder, system } = this.props;
		const simOrder = {};

		if (values.id) {
			if (values.simOrderStatusId) {
				simOrder.simOrderStatusId = values.simOrderStatusId.value;
			}
			if (values.orderConfirmationNumber) {
				simOrder.orderConfirmationNumber = values.orderConfirmationNumber.trim();
			}
			if (values.shippingNumber) {
				simOrder.shippingNumber = values.shippingNumber.trim();
			}
		} else {
			if (values.simSkuId && values.simSkuId.value) {
				simOrder.simSkuId = values.simSkuId.value;
			}
			if (values.quantity) simOrder.quantity = values.quantity;
			if (values.contactName) {
				simOrder.contactName = values.contactName.trim();
			}
			if (values.telephoneNumber) {
				simOrder.telephoneNumber = values.telephoneNumber.trim();
			}
			if (values.targetImplementationDate) {
				simOrder.targetImplementationDate = format(
					new Date(values.targetImplementationDate),
					'MM/dd/yyyy'
				);
			} else {
				simOrder.targetImplementationDate = format(new Date(), 'MM/dd/yyyy');
			}
			if (values.expeditedShipping) {
				simOrder.expeditedShipping = values.expeditedShipping;
			}
			if (values.address1) simOrder.address1 = values.address1.trim();
			if (values.address2) simOrder.address2 = values.address2.trim();
			if (values.city) simOrder.city = values.city.trim();
			if (values.stateId && values.stateId.value) {
				simOrder.stateId = values.stateId.value;
			}
			if (values.zipcode) simOrder.zipcode = values.zipcode.trim();
		}
		if (values.shippingNotes) {
			simOrder.shippingNotes = values.shippingNotes.trim();
		}

		if (values.id) {
			editSimOrder({ id: values.id, data: simOrder, system });
		} else {
			addSimOrder(simOrder);
		}
	};

	openConfirmationModal = () => {
		this.setState({ isConfirmationModalOpen: true });
	};

	closeConfirmationModal = () => {
		this.setState({ isConfirmationModalOpen: false });
	};

	renderStatusClass = (value) => {
		let className = '';

		if (value === 1) className = 'pending';
		if (value === 2) className = 'received';
		if (value === 3) className = 'shipped';
		if (value === 4) className = 'completed';

		return className;
	};

	render() {
		const {
			messages,
			invalid,
			pristine,
			handleSubmit,
			id,
			isReadOnly,
			addSimOrderRequest,
			editSimOrderRequest,
			platformsRequest,
			platforms,
			skusRequest,
			skus,
			orderStatusesRequest,
			orderStatuses,
			statesRequest,
			states,
			initialValues,
			formValues,
			restraintsRequest,
			countries,
			countriesRequest,
			onCancel,
		} = this.props;

		const {
			limitValidator,
			selectedAccountID,
			quantity,
			selectedSkuId,
			selectedSkuDescription,
			selectedSkuUnitPrice,
			rangeValidator,
			incrementalValidator,
			minQuantity,
			maxQuantity,
			usedQuantity,
			total,
			isConfirmationModalOpen,
			increment,
		} = this.state;

		const dateFormat = messages.DATE_FORMAT_CODE || 'MM/dd/yyyy';

		const dateNotBeforeToday = dateNotBefore(
			startOfDay(new Date()),
			dateFormat
		);

		const isExisting = id !== undefined;

		if (
			addSimOrderRequest ||
			editSimOrderRequest ||
			(isExisting && initialValues === {})
		) {
			return <Loading data-spec="loading" />;
		}
		return (
			<form
				className={styles.form_container}
				data-spec="sim-order-form"
				onSubmit={handleSubmit(this.submitSimOrder)}
			>
				{/* M2M Account and Status */}
				<div className={styles.section}>
					<div className={styles.section_m2m}>
						<div className={styles.m2m_account}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="M2M_ACCOUNT_PLACEHOLDER"
									defaultMessage="Select your M2M Account"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="M2M_ACCOUNT"
												defaultMessage="M2M Account"
												value={formValues && formValues.platformId}
												dataSpec="select-m2m-account"
											/>
										) : (
											<Field
												dataSpec="select-m2m-account"
												component={Select}
												name="platformId"
												label={
													<FormattedMessage
														id="M2M_ACCOUNT"
														defaultMessage="M2M Account"
													/>
												}
												validate={isExisting ? [] : [required, limitValidator]}
												className={styles.field}
												placeholder={formattedValue}
												clearable={false}
												searchable={false}
												options={
													platforms &&
													platforms.resultList &&
													platforms.resultList.map((item) => ({
														value: item.id,
														label: item.m2mAccountName,
													}))
												}
												onChange={this.handlePlatformChange}
												isDisabled={
													platformsRequest ||
													!platforms ||
													!this.AllowedToAccess()
												}
												loading={platformsRequest}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.m2m_account_id}>
							<div className={styles.form_field}>
								<StaticField
									labelId="M2M_ACCOUNT_ID"
									defaultMessage="M2M Account ID"
									value={selectedAccountID}
									dataSpec="m2m-account-id"
								/>
							</div>
						</div>
					</div>

					{isExisting && (
						<div className={styles.section_order}>
							<div className={styles.order_number_id}>
								<div className={styles.form_field}>
									<StaticField
										labelId="ORDER_NUMBER_ID"
										defaultMessage="Order Number ID"
										value={formValues && formValues.id}
										dataSpec="order-number-id"
									/>
								</div>
							</div>
							<div className={styles.order_status}>
								<div className={styles.form_field}>
									<FormattedMessage
										id="ORDER_STATUS"
										defaultMessage="Order Status"
									>
										{(formattedValue) =>
											(isReadOnly || !this.AllowedToAccess() ? (
												<StaticField
													labelId="ORDER_STATUS"
													defaultMessage="Order Status"
													value={formValues && formValues.simOrderStatusId}
													dataSpec="order-status"
												/>
											) : (
												<Field
													dataSpec="order-status"
													component={Select}
													name="simOrderStatusId"
													label={formattedValue}
													validate={[required]}
													placeholder={formattedValue}
													options={
														orderStatuses &&
														orderStatuses.resultList &&
														orderStatuses.resultList.map((item) => ({
															value: item.id,
															label: (
																<FormattedMessage
																	id={`ORDER_STATUS_${item.name.toUpperCase()}`}
																	defaultMessage={item.name}
																/>
															),
														}))
													}
													isDisabled={orderStatusesRequest || !orderStatuses}
													loading={orderStatusesRequest}
													cleanValue
													components={{
														Option: this.Option,
														SingleValue: this.SingleValue,
													}}
													styles={{
														valueContainer: (provided) => ({
															...provided,
															paddingLeft: '0px',
															paddingRight: '0px',
														}),
													}}
												/>
											))
										}
									</FormattedMessage>
								</div>
							</div>
						</div>
					)}

					{isExisting && (
						<div className={styles.section_shipping}>
							<div className={styles.order_confirmation_number}>
								<div className={styles.form_field}>
									<FormattedMessage
										id="ORDER_CONFIRMATION_NUMBER"
										defaultMessage="Order Confirmation Number"
									>
										{(formattedValue) =>
											(isReadOnly || !this.AllowedToAccess() ? (
												<StaticField
													labelId="ORDER_CONFIRMATION_NUMBER"
													defaultMessage="Order Confirmation Number"
													value={
														formValues && formValues.orderConfirmationNumber
													}
													dataSpec="order-confirmation-number"
												/>
											) : (
												<Field
													component={Input}
													name="orderConfirmationNumber"
													label={formattedValue}
													placeholder={formattedValue}
													className={styles.field}
													dataSpec="order-confirmation-number"
												/>
											))
										}
									</FormattedMessage>
								</div>
							</div>
							<div className={styles.shipping_number}>
								<div className={styles.form_field}>
									<FormattedMessage
										id="SHIPPING_NUMBER"
										defaultMessage="Shipping Number"
									>
										{(formattedValue) =>
											(isReadOnly || !this.AllowedToAccess() ? (
												<StaticField
													labelId="SHIPPING_NUMBER"
													defaultMessage="Shipping Number"
													value={formValues && formValues.shippingNumber}
													dataSpec="shipping-number"
												/>
											) : (
												<Field
													component={Input}
													name="shippingNumber"
													label={formattedValue}
													placeholder={formattedValue}
													className={styles.field}
													dataSpec="shipping-number"
												/>
											))
										}
									</FormattedMessage>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className={styles.section}>
					<div className={styles.separator} />
				</div>

				{/* SKU and Total */}
				<div className={styles.section}>
					<div className={styles.section_sku_total}>
						<div className={styles.section_sku}>
							<div className={styles.sku_wrapper}>
								<div className={styles.sku}>
									<div className={styles.form_field}>
										<FormattedMessage
											id="SKU_PLACEHOLDER"
											defaultMessage="Select SKU"
										>
											{(formattedValue) =>
												(isExisting ? (
													<StaticField
														labelId="SKU_PLACEHOLDER"
														defaultMessage="Select SKU"
														value={formValues && formValues.simSkuId}
														dataSpec="select-sku"
													/>
												) : (
													<Field
														dataSpec="select-sku"
														component={Select}
														name="simSkuId"
														label={
															<FormattedMessage id="SKU" defaultMessage="SKU" />
														}
														validate={isExisting ? [] : [required]}
														className={styles.field}
														placeholder={formattedValue}
														options={
															skus &&
															skus.resultList &&
															skus.resultList.map((item) => ({
																value: item.id,
																label: item.name,
															}))
														}
														onChange={(item) => {
															const soUnitPrice =
																initialValues &&
																initialValues.simOrderUnitPrice;
															const sku =
																skus &&
																skus.resultList &&
																skus.resultList.find(
																	(f) => f.id === item.value
																);
															const skuUnitPrice = sku && sku.unitPrice;

															this.setState({
																selectedSkuId:
																	sku && sku.platform.platformType.name,
																selectedSkuDescription: sku && sku.description,
																selectedSkuUnitPrice:
																	soUnitPrice || skuUnitPrice,
																total:
																	parseFloat(soUnitPrice || skuUnitPrice || 0) *
																	parseInt(quantity || 0, 10),
															});
														}}
														isDisabled={
															skusRequest ||
															!skus ||
															!skus.resultList.length ||
															!formValues ||
															!formValues.platformId
														}
														loading={skusRequest}
														cleanValue
													/>
												))
											}
										</FormattedMessage>
									</div>
								</div>
								<div className={styles.sku_platform}>
									<div className={styles.form_field}>
										<StaticField
											labelId="PLATFORM"
											defaultMessage="Platform"
											value={selectedSkuId}
											dataSpec="sku-platform"
										/>
									</div>
								</div>
							</div>
							<div className={styles.total_wrapper}>
								<div className={styles.unit_price}>
									<div className={styles.form_field}>
										<StaticField
											labelId="UNIT_PRICE"
											defaultMessage="Unit Price"
											value={selectedSkuUnitPrice}
											dataSpec="sku-unit-price"
										/>
									</div>
								</div>
								<div className={styles.quantity}>
									<div className={styles.form_field}>
										<FormattedMessage id="QUANTITY" defaultMessage="Quantity">
											{(formattedValue) =>
												(isExisting ? (
													<StaticField
														labelId="QUANTITY"
														defaultMessage="Quantity"
														value={
															formValues &&
															quantityFormatter(formValues.quantity)
														}
														dataSpec="quantity"
													/>
												) : (
													<Field
														component={Input}
														name="quantity"
														label={formattedValue}
														validate={
															isExisting
																? []
																: [
																		required,
																		rangeValidator,
																		incrementalValidator,
																  ]
														}
														// className={styles.field}
														dataSpec="quantity"
														onChange={(e) => this.handleFieldChange(e)}
														// eslint-disable-next-line react/destructuring-assignment
														value={(e) => this.state[e.target.name]}
														parse={(value) =>
															(!Number.isNaN(parseInt(value, 10))
																? parseInt(value, 10)
																: '')
														}
														formatter={quantityFormatter}
														disabled={
															restraintsRequest ||
															!formValues ||
															!formValues.simSkuId ||
															!formValues.simSkuId.value
														}
													/>
												))
											}
										</FormattedMessage>
										{!isExisting && (
											<div className={styles.label_below}>
												<FormattedMessage
													id="MIN_MAX_QUANTITY"
													defaultMessage="Min {min} / Max {available} {max}"
													values={{
														min: minQuantity,
														available: maxQuantity - usedQuantity,
														max: usedQuantity > 0 ? `(${maxQuantity})` : '',
													}}
												/>
												<br />
												<FormattedMessage
													id="INCREMENT_QUANTITY"
													defaultMessage="Increment {increment}"
													values={{ increment }}
												/>
											</div>
										)}
									</div>
								</div>
								<div className={styles.total}>
									<div className={styles.form_field}>
										<StaticField
											labelId="TOTAL_PRICE"
											defaultMessage="Total Price"
											value={currencyFormatter(total)}
											dataSpec="total"
											customValueClass={styles.total_label}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.sku_description}>
							<div className={styles.form_field}>
								<StaticField
									labelId="DESCRIPTION"
									defaultMessage="Description"
									value={selectedSkuDescription}
									dataSpec="sku-description"
								/>
							</div>
						</div>
					</div>
				</div>

				{/* Contact Name & Purchase Order */}
				<div className={styles.section}>
					<div className={styles.section_contact_po}>
						<div className={styles.contact}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="CONTACT_NAME"
									defaultMessage="Contact Name"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly || !this.AllowedToAccess() ? (
											<StaticField
												labelId="CONTACT_NAME"
												defaultMessage="Contact Name"
												value={formValues && formValues.contactName}
												dataSpec="contact-name"
											/>
										) : (
											<Field
												component={Input}
												name="contactName"
												label={formattedValue}
												className={styles.field}
												placeholder={formattedValue}
												dataSpec="contact-name"
												validate={
													isExisting ? [] : [required, onlyLettersAndSpaces]
												}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.purchase_order_number}>
							<div className={cn(styles.form_field, styles.label_tooltip)}>
								{!isExisting && (
									<span className={styles.tooltip_wrapper}>
										<FormattedMessage
											id="PO_TOOLTIP"
											defaultMessage="PO # will be generated upon SIM Order submission"
										>
											{(formattedValue) => (
												<Info
													data-spec="po-number"
													data-tip={formattedValue}
													data-for="po-number"
												/>
											)}
										</FormattedMessage>

										<ReactTooltip
											className={styles.tooltip}
											type="light"
											id={'po-number'}
										/>
									</span>
								)}
								<FormattedMessage
									id="PURCHASE_ORDER_NUMBER"
									defaultMessage="PO #"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly || !this.AllowedToAccess() ? (
											<StaticField
												labelId="PURCHASE_ORDER_NUMBER"
												defaultMessage="PO #"
												value={formValues && formValues.po}
												dataSpec="purchase-order-number"
											/>
										) : (
											<Field
												component={Input}
												name="po"
												maxLength="15"
												label={formattedValue}
												className={styles.field}
												placeholder={formattedValue}
												dataSpec="purchase-order-number"
												disabled
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
					</div>
				</div>

				{/* Telephone Number & Target Implementation Date */}
				<div className={styles.section}>
					<div className={styles.section_tel_tid}>
						<div className={styles.telephone_number}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="TELEPHONE_NUMBER"
									defaultMessage="Telephone Number"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly || !this.AllowedToAccess() ? (
											<StaticField
												labelId="TELEPHONE_NUMBER"
												defaultMessage="Telephone Number"
												value={formValues && formValues.telephoneNumber}
												dataSpec="telephone-number"
											/>
										) : (
											<Field
												component={Input}
												name="telephoneNumber"
												label={formattedValue}
												className={styles.field}
												placeholder={'+1 415 5552671'}
												dataSpec="telephone-number"
												validate={isExisting ? [] : [required, phoneFormat]}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.target_implementation_date}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="TARGET_IMPLEMENTATION_DATE"
									defaultMessage="Target Implementation Date"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<div className={styles.form_field}>
												<label className={styles.form_label}>
													{formattedValue}
												</label>
												<label className="form-control-static">
													{format(
														new Date(initialValues.targetImplementationDate),
														'MM/dd/yyyy'
													)}
												</label>
											</div>
										) : (
											<Field
												label={formattedValue}
												component={DSDatePicker}
												validate={isExisting ? [] : [dateNotBeforeToday]}
												name="targetImplementationDate"
												dataSpec="target-implementation-date"
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
					</div>
				</div>

				{/* ADDRESS */}
				<div className={styles.section}>
					<div className={styles.section_title}>
						<FormattedMessage
							id="SHIPPING_ADDRESS"
							defaultMessage="Shipping Address"
						/>
					</div>
					<div className={styles.section_address}>
						<div className={styles.address_line_1}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="ADDRESS_LINE_1"
									defaultMessage="Address 1"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="ADDRESS_LINE_1"
												defaultMessage="Address 1"
												value={formValues && formValues.address1}
												dataSpec="address-1"
											/>
										) : (
											<Field
												component={Input}
												name="address1"
												label={formattedValue}
												className={styles.field}
												placeholder={formattedValue}
												dataSpec="address-1"
												validate={isExisting ? [] : [required]}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.address_line_2}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="ADDRESS_LINE_2"
									defaultMessage="Address 2"
								>
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="ADDRESS_LINE_2"
												defaultMessage="Address 2"
												value={formValues && formValues.address2}
												dataSpec="address-2"
											/>
										) : (
											<Field
												component={Input}
												name="address2"
												label={formattedValue}
												className={styles.field}
												placeholder={formattedValue}
												dataSpec="address-2"
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.address_city}>
							<div className={styles.form_field}>
								<FormattedMessage id="CITY" defaultMessage="City">
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="CITY"
												defaultMessage="City"
												value={formValues && formValues.city}
												dataSpec="city"
											/>
										) : (
											<Field
												component={Input}
												name="city"
												label={formattedValue}
												className={styles.field}
												placeholder={formattedValue}
												dataSpec="city"
												validate={isExisting ? [] : [required]}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.address_city}>
							<div className={styles.form_field}>
								<FormattedMessage id="COUNTRY" defaultMessage="Country">
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="COUNTRY"
												defaultMessage="Country"
												value={formValues && formValues.countryId}
												dataSpec="country"
											/>
										) :
										<Field
											component={Select}
											name="countryId"
											label={formattedValue}
											validate={isExisting ? [] : [required]}
											className={styles.field}
											placeholder={formattedValue}
											dataSpec="country"
											options={
												countries &&
												countries.resultList &&
												countries.resultList.map((item) => ({
													value: item.id,
													label: item.name
												}))
											}
											isDisabled={
												countriesRequest ||
												!countries ||
												!countries.resultList.length
											}
											loading={countriesRequest}
											cleanValue
											isSearchable
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.address_state}>
							<div className={styles.form_field}>
								<FormattedMessage id="STATE" defaultMessage="State">
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="STATE"
												defaultMessage="State"
												value={formValues && formValues.stateId}
												dataSpec="state"
											/>
										) :
										<Field
											component={Select}
											name="stateId"
											label={formattedValue}
											validate={isExisting ? [] : [required]}
											className={styles.field}
											placeholder={formattedValue}
											dataSpec="state"
											options={
												states &&
												states.resultList &&
												states.resultList.map((item) => ({
													value: item.id,
													label: item.name
												}))
											}
											isDisabled={
												statesRequest || !states || !states.resultList.length
											}
											loading={statesRequest}
											cleanValue
											isSearchable
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.address_zip_code}>
							<div className={styles.form_field}>
								<FormattedMessage id="ZIP_CODE" defaultMessage="ZIP Code">
									{(formattedValue) =>
										(isExisting || isReadOnly ? (
											<StaticField
												labelId="ZIP_CODE"
												defaultMessage="ZIP Code"
												value={formValues && formValues.zipcode}
												dataSpec="zip-code"
											/>
										) : (
											<Field
												component={Input}
												name="zipcode"
												label={formattedValue}
												className={styles.field}
												placeholder={formattedValue}
												dataSpec="zip-code"
												validate={isExisting ? [] : [required]}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
					</div>

					<div className={styles.section_notes}>
						<div className={styles.shipping_notes}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="SHIPPING_NOTES"
									defaultMessage="Shipping Notes"
								>
									{(formattedValue) =>
										((isExisting && isReadOnly) || !this.AllowedToAccess() ? (
											<StaticField
												labelId="SHIPPING_NOTES"
												defaultMessage="Shipping Notes"
												value={formValues && formValues.shippingNotes}
												dataSpec="shipping-notes"
											/>
										) : (
											<Field
												component={Input}
												componentClass="textarea"
												className={styles.shipping_notes_message}
												name="shippingNotes"
												label={formattedValue}
												placeholder={formattedValue}
												dataSpec="shipping-notes"
												validate={[noWhiteSpaceOnly, max255]}
											/>
										))
									}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.expedited_shipping}>
							<div className={styles.form_field}>
								<FormattedMessage
									id="EXPEDITED_SHIPPING"
									defaultMessage="Expedited Shipping"
								>
									{(formattedValue) => (
										<Field
											type="checkbox"
											component={Checkbox}
											name="expeditedShipping"
											label={formattedValue}
											disabled={isExisting || isReadOnly}
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.section}>
					<div className={styles.separator} />
				</div>

				<div className={styles.section}>
					<div className={styles.section_buttons}>
						<Button
							label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
							variant="outline-primary"
							type="button"
							dataSpec="close-button"
							onClick={() => onCancel()}
						/>
						{(!isExisting || (isExisting && !isReadOnly)) && (
							<Button
								label={
									isExisting ? (
										<FormattedMessage
											id="SAVE_UPDATES"
											defaultMessage="Save Updates"
										/>
									) : (
										<FormattedMessage
											id="SUBMIT_ORDER_REQUEST"
											defaultMessage="Submit Order Request"
										/>
									)
								}
								variant="primary"
								type="button"
								dataSpec="submit-button"
								disabled={invalid || pristine}
								onClick={() => this.openConfirmationModal()}
							/>
						)}
					</div>
				</div>

				<SimOrderConfirmationModal
					title={
						isExisting ? (
							<FormattedMessage
								id="EDIT_ORDER_REQUEST"
								defaultMessage="Edit Order Request"
							/>
						) : (
							<FormattedMessage
								id="SUBMIT_ORDER_REQUEST"
								defaultMessage="Submit Order Request"
							/>
						)
					}
					message={
						isExisting ? (
							<FormattedMessage
								id="EDIT_ORDER_REQUEST_CONFIRMATION"
								defaultMessage="Are you sure you want to save changes?"
							/>
						) : (
							<FormattedMessage
								id="SUBMIT_ORDER_REQUEST_CONFIRMATION"
								defaultMessage="Are you sure you want to submit this order?"
							/>
						)
					}
					onCancel={this.closeConfirmationModal}
					onSubmit={handleSubmit(this.submitSimOrder)}
					show={isConfirmationModalOpen}
				/>
			</form>
		);
	}
}

const { func, bool, string, shape, objectOf, array } = PropTypes;

SimOrderEdit.propTypes = {
	dispatch: func,
	handleSubmit: func,
	initialValues: shape(),
	formValues: shape(),
	invalid: bool,
	pristine: bool,
	change: func,
	messages: objectOf(string),
	onCancel: func.isRequired,

	id: string,
	isReadOnly: bool,

	editSimOrder: func.isRequired,
	editSimOrderRequest: bool,

	addSimOrder: func.isRequired,
	addSimOrderRequest: bool,

	getPlatforms: func,
	platformsRequest: bool,
	platforms: shape({
		resultList: array,
	}),

	getRestraints: func,
	restraintsRequest: bool,
	restraints: shape({
		resultList: array,
	}),

	getUsedQuantity: func,
	usedQuantity: shape(),

	getSkus: func,
	skusRequest: bool,
	skus: shape({
		resultList: array,
	}),

	getOrderStatuses: func,
	orderStatusesRequest: bool,
	orderStatuses: shape(),

	getStates: func,
	statesRequest: bool,
	states: shape({
		resultList: array,
	}),

	getCountries: func,
	countriesRequest: bool,
	countries: shape({
		resultList: array,
	}),
	user: shape(),
	system: bool
};

const SimOrderEditDesktop = reduxForm({
	form: 'simOrderForm',
	enableReinitialize: true,
})(SimOrderEdit);

export default hoc()(SimOrderEditDesktop);
