import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';

import mapObject from './WorldMapDataWithColors';
import NoData from '../NoChartData';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import { countrCodeConversion2To3 } from './countryCodeConverter';
import { renderUnit, renderValue } from '../Helpers/helpers';
// import { onChartClick } from '../../../services/DashboardService/dashboardHelpers';

import styles from './WorldMapChart.scss';

const geographyStileDefault = {
	default: {
		transform: 'scale(1.25,1.25) translate(-100px, -10px)',
		fill: '#ffffff',
		stroke: '#959595',
		strokeWidth: 0.5,
		outline: 'none'
	},
	hover: {
		transform: 'scale(1.25,1.25) translate(-100px, -10px)',
		fill: '#ffffff',
		stroke: '#959595',
		strokeWidth: 0.5,
		outline: 'none'
	},
	pressed: {
		transform: 'scale(1.25,1.25) translate(-100px, -10px)',
		fill: '#ffffff',
		stroke: '#959595',
		strokeWidth: 0.5,
		outline: 'none'
	}
};

class WorldMapChart extends Component {
	componentDidMount() {
		setTimeout(() => {
			ReactTooltip.rebuild();
		}, 100);
	}

	activeCountryStyles = (color) => {
		const colorRange = [
			'#5492e7',
			'#003366',
			'#ff7373',
			'#416600',
			'#00a7b3',
			'#e6c74d',
			'#133337',
			'#a10000'
		];
		let tempColor = color;

		if (!tempColor) {
			tempColor = colorRange[Math.floor(Math.random() * 9)];
		}

		return {
			default: {
				...geographyStileDefault.default,
				fill: tempColor
			},
			hover: {
				...geographyStileDefault.hover,
				fill: tempColor,
				cursor: 'pointer'
			},
			pressed: {
				...geographyStileDefault.pressed,
				fill: tempColor
			}
		};
	};

	render() {
		const {
			hasLegend,
			networkType,
			chartData,
			isAnalytics,
			unit,
			intl,
		} = this.props;

		if (!chartData || chartData.length === 0) {
			return (
				<div data-spec="no-data">
					<NoData />
				</div>
			);
		}

		const worldChartData = {};

		chartData.forEach((data) => {
			const countryCode =
				countrCodeConversion2To3(data.countrycode) || data.countrycode;

			if (worldChartData[countryCode] === undefined) {
				worldChartData[countryCode] = { value: 0 };
			}
			if ('value_2' in data) {
				worldChartData[countryCode].value += (data.value_2 / data.value);
			} else {
				worldChartData[countryCode].value +=
					data.value === null ? 0 : parseInt(data.value, 10);
			}
		});

		const total = Object.keys(worldChartData).reduce(
			(totalValue, item) => totalValue + worldChartData[item].value,
			0
		);
		const countries = Object.keys(worldChartData);

		return (
			<div data-spec="world-map-chart" className={styles.chart_wrapper}>
				<main className={styles.main}>
					<div
						className={
							!isAnalytics
								? styles.wrapper
								: `${styles.wrapper} ${styles.wrapper_analytics}`
						}
					>
						<ComposableMap
							projectionConfig={{
								scale: 130,
								rotation: [-10, 0, 0]
							}}
							className={styles.chart}
						>
							<Geographies geography={mapObject}>
								{(geographies, projection) =>
									geographies.map((geography) => {
										const id2 = geography.id;
										const { color } = geography.properties;
										const geographyStileCountry =
											countries.indexOf(id2) !== -1
												? this.activeCountryStyles(color)
												: geographyStileDefault;
										const dataTip =
											countries.indexOf(id2) !== -1
												? `<div><img src="${ImageSelector(
													CurrentContext,
													`${networkType && networkType.toLowerCase()}.png`,
													'networkOperator'
												  )}" alt="network type image" />${
													worldChartData[id2].operator
														? `${worldChartData[id2].operator} : `
														: ''
												  } ${geography.properties.name} ${
													worldChartData[id2].value
														? `${renderValue(
															unit,
															worldChartData[id2].value,
															true
															  )} ${renderUnit(
															unit,
															worldChartData[id2].value,
															intl.formatMessage,
															  )}`
														: ''
												  }</div>`
												: undefined;

										return geography.id !== 'ATA' ? (
											<Geography
												test="test"
												key={geography.id}
												geography={geography}
												data-tip={dataTip}
												data-html
												projection={projection}
												style={geographyStileCountry}
											/>
										) : null;
									})}
							</Geographies>
						</ComposableMap>
						<ReactTooltip className={styles.tooltip} type="light" />
					</div>
					{hasLegend && !isAnalytics ? (
						<div data-spec="'world-chart-legend" className={styles.wcd_wrapper}>
							<div className={styles.total}>
								<div className={styles.title}>
									<FormattedMessage id="TOTAL" defaultMessage="Total" />
								</div>
								<div className={styles.count}>
									{renderValue(unit, total)}
									{' '}
									{renderUnit(unit, total, intl.formatMessage)}
								</div>
							</div>
						</div>
					) : null}
				</main>
			</div>
		);
	}
}

const {
	arrayOf,
	object,
	string,
	bool,
	objectOf,
	oneOfType,
	func,
	symbol,
} = PropTypes;

WorldMapChart.propTypes = {
	chartData: arrayOf(object),
	hasLegend: bool,
	isAnalytics: bool,
	networkType: string,
	unit: string,
	intl: objectOf(oneOfType([object, func, string, symbol])),
};

WorldMapChart.defaultProps = {
	chartData: [],
	hasLegend: false,
	isAnalytics: false,
	networkType: '',
	unit: '',
	intl: {}
};

export default injectIntl(WorldMapChart);
