import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import AddCompanySuccess from './AddCompanySuccess';

import {
	getCompanyRequest,
	getCompanyFail,
	getCompanySuccess
} from '../../redux/companies/selectors';

import { getCompany, getCompanyInit } from '../../redux/companies/actions';
import changeCompanyInitalTabAction from '../../redux/companiesInitTab/actions';

const mapStateToProps = (state) => ({
	getCompanyRequest: getCompanyRequest(state),
	getCompanyFail: getCompanyFail(state),
	company: getCompanySuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getCompany: (params) => dispatch(getCompany(params)),
	getCompanyInit: () => dispatch(getCompanyInit()),
	pushBack: (url) => dispatch(localizedRoute(url)),
	addNewCompany: (url) => dispatch(localizedRoute(url)),
	editCompany: (url) => dispatch(localizedRoute(url)),
	changeCompanyInitalTab: (params) => dispatch(changeCompanyInitalTabAction(params)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddCompanySuccess);
