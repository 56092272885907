import { connect } from 'react-redux';
import { getFormValues, reset } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import Billings from './Billings';

import {
	editCompanyReports,
	getCompanyReports
} from '../../redux/companyReports/actions';
import {
	editCompanyReportsRequest,
	getCompanyReportsSuccess,
	getCompanyReportsRequest,
	editCompanyReportsSuccess
} from '../../redux/companyReports/selectors';

import {
	getAccountSuccess,
	getAccountRequest,
	getAccountFail,
	editAccountSuccess,
	editAccountRequest
} from '../../redux/accounts/selectors';

import {
	getAccount,
	getAccountInit,
	editAccount
} from '../../redux/accounts/actions';
import { hideNotification } from '../../../Shared/redux/notifications/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => {
	let initialValues = {};

	if (
		getCompanyReportsSuccess(state) &&
		getCompanyReportsSuccess(state).resultList
	) {
		getCompanyReportsSuccess(state).resultList.forEach((item) => {
			initialValues = {
				...initialValues,
				[`${item.id}_iotc`]: item.iot_c,
				[`${item.id}_sfg`]: item.sfg,
				[`${item.id}_sfgMailbox`]: item.sfg_mailbox,
			};
		});
	}

	initialValues.account = getAccountSuccess(state);

	return {
		companyReports: getCompanyReportsSuccess(state),
		companyReportsGetRequest: getCompanyReportsRequest(state),
		companyReportsEditRequest: editCompanyReportsRequest(state),
		editCompanyReportsSuccess: editCompanyReportsSuccess(state),
		initialValues,
		account: getAccountSuccess(state),
		accountFail: getAccountFail(state),
		accountRequest: getAccountRequest(state),
		user: getUser(state),
    formValues: getFormValues('BillingForm')(state),
    editAccountRequest: editAccountRequest(state),
    editAccountSuccess: editAccountSuccess(state)
	};
};

const mapDispatchToProps = (dispatch) => ({
	getCompanyReports: (params) => dispatch(getCompanyReports(params)),
	editCompanyReports: (data) => dispatch(editCompanyReports(data)),
	pushBack: (url) => dispatch(localizedRoute(url)),
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit()),
	editAccount: (params) => dispatch(editAccount(params)),
	resetForm: () => dispatch(reset('BillingForm')),
	hideNotification: (id) => dispatch(hideNotification(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Billings);
