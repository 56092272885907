/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqBy, isEqual } from 'lodash';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import { getDateFormat } from '../../../../../utils/constants';

import styles from './Steps.scss';

class StepSecond extends Component {
	constructor(props) {
		super(props);
		this.state = {
			scheduledDate: new Date(),
			schedule: {
				originalSelectedSims: [],
				newSimStatus: '',
				currentSelectedStates: []
			}
		};
	}

	componentDidMount() {
    const { context } = this.props;
		this.setScheduleState(this.props);
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
    const { context, values } = this.props;
    const { scheduledDate } = this.state;
		if (
			context &&
			!isEqual(scheduledDate, prevState.scheduledDate)
		) {
			this.setFooter(context);
		}
		if (prevProps && prevProps.values !== values) {
			this.setScheduleState(this.props);
		}
	}

	setFooter = (context) => {
    const { onClose } = this.props;
    const { scheduledDate } = this.state;
		const footer = (
			<>
				<Button
					onClick={onClose}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<Button
					dataSpec="initiate-change"
					onClick={this.handleOnClick}
					disabled={
						scheduledDate === undefined ||
						format(scheduledDate, 'yyyy-MM-dd') <
							format(new Date(), 'yyyy-MM-dd')
					}
					variant="primary"
					label={
						getDateFormat(scheduledDate) === getDateFormat(new Date()) ? (
							<FormattedMessage
								id="ENDPOINTS.INITIATE_CHANGE"
								defaultMessage="Initiate change"
							/>
						) : (
							<FormattedMessage
								id="ENDPOINTS.SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule change"
							/>
						)
					}
				/>
			</>
		);
		context.updateContext({ footer });
	};

	setScheduleState = (props) => {
		this.setState({
			schedule: props.values.schedule
		});
		this.checkSimsNetworkType(props);
	};

	checkSimsNetworkType = (props) => {
		const { networkType } = uniqBy(
			props.values.schedule.processedSims,
			'networkType'
		)[0];
		if (!networkType) {
			this.setState({
				satellite: false
			});
		} else if (networkType.toLowerCase() === 'satellite') {
			this.setState({
				satellite: true
			});
		} else {
			this.setState({
				satellite: false
			});
		}
	};

	handleOnClick = () => {
    const { onNext } = this.props;
		onNext(this.state);
	};

	// get unique current state from all selected sims
	getCurrentSimStatus = () => {
    const { schedule } = this.state;
    return (
      schedule.currentSelectedStates.map((item, index) => (
        <span data-spec="states" key={index}>
          <FormattedMessage id={item} defaultMessage={item} />
          {index + 1 < schedule.currentSelectedStates.length
            ? ', '
            : ''}
        </span>
      ))
    );
  }

	onDefaultDateChange = (date) => {
		this.setState({
			scheduledDate: date
		});
	};

	render() {
    const { schedule, scheduledDate, satellite } = this.state;
		return (
			<div data-spec="first-step">
				<div className={styles.custom}>
					<div>
						<div className={`${styles.step_first} ${styles.step_second}`}>
							<h2 className={styles.touppercase}>
								<FormattedMessage
									id="ENDPOINTS.CONFIRM_SIM_STATE_CHANGE"
									defaultMessage="Confirm SIM state change"
								/>
							</h2>
							<p>
								{schedule.originalSelectedSims.length}
								<FormattedMessage
									id="ENDPOINTS.SIMS_SELECTED"
									defaultMessage="SIMs selected"
								/>
								(
								<FormattedMessage
									id="ENDPOINTS.CURRENT_STATES"
									defaultMessage="Current states"
								/>
								:
                {this.getCurrentSimStatus()}
								).
							</p>
							<p>
								<FormattedMessage
									id="ONCE_YOU_INITIATE_CHANGE"
									defaultMessage="Once you initiate this change, the selected SIMS will be"
								/>
								{schedule.newSimStatus && (
									<FormattedMessage
										id={schedule.newSimStatus}
										defaultMessage={schedule.newSimStatus}
									>
										{(formattedValue) => (
											<span className={styles.current_state}>
												{` ${formattedValue.toUpperCase()}. `}
											</span>
										)}
									</FormattedMessage>
								)}
								<FormattedMessage
									id="ENDPOINTS.PROCEED_ACTION"
									defaultMessage="Would you like to proceed with this action?"
								/>
							</p>
							<p className={styles.text_margin}>
								<FormattedMessage
									id="ENDPOINTS.CAN_SCHEDULE"
									defaultMessage="Or you can schedule the change for"
								/>
								:
							</p>
							<DSDatePicker
								label={
									<FormattedMessage
										id="ENDPOINTS.SCHEDULE_DATE"
										defaultMessage="Date"
									/>
								}
								value={scheduledDate}
								onDayChange={this.onDefaultDateChange}
								disabled={satellite}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StepSecond;

const { object, func } = PropTypes;

StepSecond.propTypes = {
	onClose: func,
	onNext: func,
	values: object,
	context: object
};
