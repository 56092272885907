/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import Button from '../../../../lib/DigitalComponents/Button';
import EditTable from './EditTable';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';

import styles from './EditTableModal.scss';

class EditTableModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newValues: [],
			oldValues: [],
			submitColumnsButtonDisabled: true,
			activeTab: 'tab1'
		};
	}

	componentDidMount() {
		const { anyModalOpened } = this.props;
		anyModalOpened();
	}

	closeModal = () => {
		const { onClose, anyModalClosed } = this.props;
		onClose();
		anyModalClosed();
	};

	handleDelete = (tableTabs) => {
		const { signature, submitColumns, columns } = this.props;
		const newValue = {
			signature,
			header: [...tableTabs]
		};

		submitColumns(newValue, columns.id);
		this.closeModal();
	};

	renderButtons = (tab) => {
		const { tableTabs, columns } = this.props;
		const { submitColumnsButtonDisabled } = this.state;
		if (tab === 'tab1') {
			const parsedColumns = JSON.parse(columns.value);
      const columnsProps = parsedColumns ? parsedColumns.header : [];
      const checkedColumns = tableTabs[0].value.data
				.filter((item) => item.checked)
				.map((item, index) => ({
					title: item.title,
					name: item.name,
					value: index + 1
        }));

			return (
				<span data-spec="tab1-buttons">
					<Button
						onClick={() => this.handleDelete(checkedColumns)}
						variant="primary"
						className={styles.clear_all}
						label={
							<FormattedMessage
								id="ANALYTICS_INVOICES.RESET_TO_DEFAULT"
								defaultMessage="Reset to Default"
							/>
						}
						dataSpec="reset-to-default"
						disabled={isEqual(checkedColumns, columnsProps)}
					/>
					<Button
						onClick={() => this.handleSubmit('handleSubmit')}
						variant="primary"
						label={
							<FormattedMessage
								id="ANALYTICS_INVOICES.APPLY_CHANGE"
								defaultMessage="Apply change"
							/>
						}
						dataSpec="apply-change"
						disabled={submitColumnsButtonDisabled}
					/>
				</span>
			);
		}
	};

	setActiveTab = (activeTab) => {
		this.setState({
			activeTab
		});
	};

	submitColumnsDisabled = (newValues, oldValues) => {
		const { excludeColumns } = this.props;
		let compareColumns = [];
		let disable = true;
		if (newValues.length - excludeColumns.length > 1) {
			compareColumns = newValues.filter((newValue) =>
				oldValues.some((oldValue) => oldValue.name === newValue.name));

			disable =
				compareColumns.length === oldValues.length
					? oldValues.length === newValues.length
					: false;
		}
		this.setState({
			submitColumnsButtonDisabled: disable
		});
	};

	onSubmitColumns = (newValues, oldValues) => {
		this.setState({
			newValues,
			oldValues
		});
	};

	handleSubmit = () => {
		const { signature, submitColumns, columns } = this.props;
		const { newValues } = this.state;
		const newValue = { signature, header: newValues };
		submitColumns(newValue, columns.id);
		this.closeModal();
	};

	prepareErrors = (errors, messages) =>
		errors.map((error) => messages[error.code] || error.message);

	render() {
		const {
			messages,
			tableTabs,
			excludeColumns,
			columns,
			activeTab,
			user,
			show,
			errors,
			notifications
		} = this.props;
		const { activeTab: activeTabState } = this.state;
		const parsedColumns = JSON.parse(columns.value);

		const tabs = [
			{
				id: 'tab1',
				displayName: (
					<FormattedMessage
						id="ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS"
						defaultMessage="Edit Table Parameteres"
					/>
				),
				content: () => (
					<EditTable
						columns={parsedColumns.header}
						allColumns={tableTabs[0].value.data}
						hasOriginal={parsedColumns.header}
						onSubmitColumns={this.onSubmitColumns}
						submitColumnsDisabled={this.submitColumnsDisabled}
						activeTab={activeTab}
						user={user}
						excludeColumns={excludeColumns}
					/>
				)
			}
		];

		return (
			<Modal
				data-spec="edit-table-modal"
				className={styles.modal}
				show={show}
				onClose={this.closeModal}
			>
				<ModalHeader
					className={styles.modalHeader}
					messages={messages}
					title={
						<FormattedMessage
							id="ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS"
							defaultMessage="Edit Table Parameteres"
						/>
					}
					notifications={notifications}
					errors={errors ? this.prepareErrors(errors, messages) : []}
				/>
				<ModalBody className={styles.modalBody}>
					<ModalTabs
						tabs={tabs}
						onTabChange={(tab) => {
							this.setActiveTab(tab.id);
						}}
					/>
				</ModalBody>
				<ModalFooter className={styles.modalFooter}>
					<Button
						useCloseModalFunction
						variant="link"
						label={
							<FormattedMessage
								id="ANALYTICS_INVOICES.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						data-spec="edit-table-modal-cancel-button"
					/>
					{this.renderButtons(activeTabState)}
				</ModalFooter>
			</Modal>
		);
	}
}

export default EditTableModal;

const { bool, func, object, any, string, array, objectOf } = PropTypes;

EditTableModal.propTypes = {
	show: bool,
	onClose: func,
	messages: objectOf(string),
	columns: any,
	submitColumns: func,
	activeTab: string,
	user: object,
	tableTabs: array,
	signature: string,
	excludeColumns: array,
	anyModalClosed: func,
	anyModalOpened: func,
	notifications: array,
	errors: array
};
