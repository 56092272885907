import React from 'react';
import { string, any, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './StaticField.scss';

const StaticField = ({
	dataSpec,
	labelId,
	defaultMessage,
	customValueClass,
	value,
	isPre,
}) => (
	<div className={styles.static_group} data-spec={dataSpec}>
		<label className={styles.static_label}>
			<FormattedMessage id={labelId} defaultMessage={defaultMessage} />
		</label>
		{isPre ? (
			<pre className={customValueClass || styles.static_value}>{value}</pre>
		) : (
			<label className={customValueClass || styles.static_value}>{value}</label>
		)}
	</div>
);

StaticField.propTypes = {
	dataSpec: string,
	labelId: string,
	defaultMessage: string,
	customValueClass: string,
	value: any,
	isPre: bool,
};

export default StaticField;
