import cn from 'classnames';
import { find } from 'lodash';
import React from 'react';
import { bool, object, string, func, array } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {
	UserAllowedToAccess,
} from '../../../../../utils/AuthSelector';
import CurrentContext from '../../../../../utils/currentContext';
import {
	showValue,
	localizationHelper
} from '../../../../../utils/helperFunctions';
import ImageSelector from '../../../../../utils/imageSelector';
import CustomFieldsValuesView from '../../../views/CustomFieldsValuesView';
import NetworkEntitlementDetails from '../../NetworkEntitlementDetails';
import RatePlanDetails from '../../RatePlanDetails';
import Recredential from '../../Recredential';
import hoc from '../DataOverview';

import styles from './Desktop.scss';

const InfoFull = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const Reset = ImageSelector(CurrentContext.theme, 'svgs/reset.svg');
const NetworkOperatorRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networkoperator_rw',
	'mnc.endpoints.networkoperator_ro'
]);
const NetworkCountryRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networkcountry_rw',
	'mnc.endpoints.networkcountry_ro'
]);
const ServiceProviderRWComponent = UserAllowedToAccess([
	'mnc.endpoints.m2mplatform_rw',
	'mnc.endpoints.m2mplatform_ro'
]);
const NetworkEntitlementIdRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networkentitlements_rw',
	'mnc.endpoints.networkentitlements_ro'
]);
const RatePlanRWComponent = UserAllowedToAccess([
	'mnc.endpoints.rateplans_rw',
	'mnc.endpoints.rateplans_ro'
]);
const NetworkTypeRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networktype_rw',
	'mnc.endpoints.networktype_ro'
]);
const TransferStatusRWComponent = UserAllowedToAccess([
	'svc_mc_endpointreimsistatus_rw',
	'svc_mc_endpointreimsistatus_ro'
]);
const RoamingCarrierRWComponent = UserAllowedToAccess([
	'mnc.endpoints.roamingcarrier_rw',
	'mnc.endpoints.roamingcarrier_ro'
]);
const RoamingCountryRWComponent = UserAllowedToAccess([
	'mnc.endpoints.roamingcountry_rw',
	'mnc.endpoints.roamingcountry_ro'
]);
const M2mDateAddedRWComponent = UserAllowedToAccess([
	'mnc.endpoints.m2mdateadded_rw',
	'mnc.endpoints.m2mdateadded_ro'
]);
const M2mInitialActivationDateRWComponent = UserAllowedToAccess([
	'mnc.endpoints.m2mactivationdate_rw',
	'mnc.endpoints.m2mactivationdate_ro'
]);
const InSessionRWComponent = UserAllowedToAccess([
	'mnc.endpoints.insession_rw',
	'mnc.endpoints.insession_ro'
]);
const CustomFieldsValuesRWComponent = UserAllowedToAccess(
	['mnc.endpoints.customfields_rw'],
);
const CustomFieldsValuesROComponent = UserAllowedToAccess(
	['mnc.endpoints.customfields_ro']
);

const M2MAccountIDRWROComponent = UserAllowedToAccess([
	'mnc.endpoints.accountid_ro',
	'mnc.endpoints.accountid_rw'
]);
const SIMStatusRWROComponent = UserAllowedToAccess([
	'mnc.endpoints.simstatus_ro',
	'mnc.endpoints.simstatus_rw',
]);

function Desktop({
	type,
	endpoint,
	endpointName,
	entitlement,
	isPendingStatus,
	ratePlans,
	customFieldLabels,
	checkPendingStatus,
	renderDataSession,
  m2mAccounts,
  intl,
}) {
	return (
		<div
			className={cn(styles.data_wrap, styles.overview)}
			data-spec="data-overview-desktop"
		>
			{endpoint.euiccid && <Recredential endpoint={endpoint} />}
			{showValue(
				!endpoint.euiccid &&
					!endpoint.iccid &&
					!endpoint.endpointName &&
					!endpoint.imsi &&
					!endpoint.msisdn &&
					!endpoint.imei &&
					!endpoint.vin
					? undefined
					: true,
				<div className={styles.row}>
					{endpoint.euiccid ? (
						<>
							{showValue(
								endpoint.iccid,
								<div className={styles.data}>
									<span>
										<FormattedMessage
											id="SINGLE_ENDPOINT.ICCID"
											defaultMessage="ICCID"
										/>
									</span>
									<span>{endpoint.iccid}</span>
								</div>
							)}
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.EUICCID"
										defaultMessage="EUICCID"
									/>
								</span>
								<span>{endpoint.euiccid}</span>
							</div>
						</>
					) : (
						showValue(
							endpointName,
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.ENDPOINT_NAME"
										defaultMessage="Endpoint ID"
									/>
								</span>
								<span>{endpointName}</span>
							</div>
						)
					)}
					{showValue(
						endpoint.imsi,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.IMSI"
									defaultMessage="IMSI"
								/>
							</span>
							<span>{endpoint.imsi}</span>
						</div>
					)}
					{showValue(
						endpoint.msisdn,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.MSISDN"
									defaultMessage="MSISDN"
								/>
							</span>
							<span>{endpoint.msisdn}</span>
						</div>
					)}
					{showValue(
						endpoint.imei,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.IMEI"
									defaultMessage="IMEI"
								/>
							</span>
							<span>{endpoint.imei}</span>
						</div>
					)}
					{showValue(
						endpoint.vin,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.VIN"
									defaultMessage="VIN"
								/>
							</span>
							<span>{endpoint.vin}</span>
						</div>
					)}
				</div>
			)}
			{showValue(
				!endpoint.status &&
					!(
						entitlement &&
						entitlement.accountInfo &&
						entitlement.accountInfo.platform
					) &&
					!endpoint.serviceProvider &&
					!endpoint.m2mAccountId &&
					!endpoint.networkType &&
					!endpoint.networkType &&
					!endpoint.networkOperator &&
					!endpoint.networkCountry &&
					!endpoint.serviceStatus &&
					!endpoint.oem
					? undefined
					: true,
				<div className={styles.row}>
					{showValue(
						endpoint.status,
						endpoint.status && (
							<SIMStatusRWROComponent>
								<div className={styles.data}>
									<span className={styles.status_span}>
										<FormattedMessage
											id="SINGLE_ENDPOINT.STATUS"
											defaultMessage="Status"
										/>
										<div className={styles.pending}>
											{isPendingStatus && (
												<FormattedMessage
													id="SINGLE_ENDPOINT.STATUS_CHANGE_PENDING"
													defaultMessage="There is a Scheduled Activity for this Endpoint"
												>
													{(formattedValue) => (
														<InfoFull
															data-tip={formattedValue}
															data-for="statusTooltip"
														/>
													)}
												</FormattedMessage>
											)}
											{isPendingStatus && (
												<ReactTooltip
													className={styles.tooltip}
													type="light"
													id="statusTooltip"
												/>
											)}
										</div>
										<div className={styles.refresh_pending_status}>
											{
												<FormattedMessage
													id="SINGLE_ENDPOINT.REFRESH_PENDING_STATUS"
													defaultMessage="Refresh endpoint to check if the state has changed"
												>
													{(formattedValue) => (
														<Reset
															onClick={checkPendingStatus}
															data-tip={formattedValue}
															data-for="resetTooltip"
														/>
													)}
												</FormattedMessage>
											}
											{
												<ReactTooltip
													className={styles.tooltip}
													type="light"
													id="resetTooltip"
												/>
											}
										</div>
									</span>
									<div
										className={cn(
											styles.status_col,
											styles[
												endpoint.status &&
													endpoint.status
														.toLowerCase()
														.replace(' ', '_')
														.replace('-', '_')
											]
										)}
									>
										<div
											className={`${endpoint.status
												.replace(/\s/g, '_')
												.toLowerCase()} custom-option`}
										>
											{endpoint.status
												? intl
														.formatMessage({
															id: `SINGLE_ENDPOINT.${localizationHelper(
																endpoint.status
															)}`,
															defaultMessage: `${endpoint.status}`
														})
														.toUpperCase()
												: ''}
										</div>
									</div>
								</div>
							</SIMStatusRWROComponent>
						)
					)}
					{showValue(
						entitlement &&
							entitlement.accountInfo &&
							entitlement.accountInfo.platform
							? entitlement
							: undefined,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.ACCOUNT_NAME"
									defaultMessage="Account Name"
								/>
							</span>
							<span>
								{entitlement &&
								entitlement.accountInfo &&
								entitlement.accountInfo.platform
									? entitlement.accountInfo.platform
									: '-'}
							</span>
						</div>
					)}
					{showValue(
						endpoint.serviceProvider,
						<ServiceProviderRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.SOURCE_PLATFORM"
										defaultMessage="M2M Platform"
									/>
								</span>
								<span>{endpoint.serviceProvider}</span>
							</div>
						</ServiceProviderRWComponent>
					)}
					{showValue(
						endpoint.m2mAccountId,
						<M2MAccountIDRWROComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.M2M_ACCOUNT_ID"
										defaultMessage="M2M Account ID"
									/>
								</span>
								<span>{endpoint.m2mAccountId}</span>
							</div>
						</M2MAccountIDRWROComponent>
					)}
					{showValue(
						endpoint.networkType,
						<NetworkTypeRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.NETWORK_TYPE"
										defaultMessage="Network Type"
									/>
								</span>
								<span>{endpoint.networkType}</span>
							</div>
						</NetworkTypeRWComponent>
					)}
					{showValue(
						endpoint.networkOperator,
						<NetworkOperatorRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.NETWORK_OPERATOR"
										defaultMessage="Network Operator"
									/>
								</span>
								<span>{endpoint.networkOperator}</span>
							</div>
						</NetworkOperatorRWComponent>
					)}
					{showValue(
						endpoint.networkCountry,
						<NetworkCountryRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.NETWORK_COUNTRY"
										defaultMessage="Network Country"
									/>
								</span>
								<span>{endpoint.networkCountry}</span>
							</div>
						</NetworkCountryRWComponent>
					)}
					{showValue(
						endpoint.m2mDateAdded,
						<M2mDateAddedRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.M2M_DATE_ADDED"
										defaultMessage="M2M Date Added"
									/>
								</span>
								<span>{endpoint.m2mDateAdded}</span>
							</div>
						</M2mDateAddedRWComponent>
					)}
					{showValue(
						endpoint.m2mInitialActivationDate,
						<M2mInitialActivationDateRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE"
										defaultMessage="M2M Activation Date"
									/>
								</span>
								<span>{endpoint.m2mInitialActivationDate}</span>
							</div>
						</M2mInitialActivationDateRWComponent>
					)}
					{showValue(
						endpoint.dataSession,
						<InSessionRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.IN_SESSION"
										defaultMessage="In Session"
									/>
								</span>
								<span>{renderDataSession(endpoint)}</span>
							</div>
						</InSessionRWComponent>
					)}
					<div className={styles.data}>
						<span>
							<FormattedMessage id="SINGLE_ENDPOINT.TECH_TYPE" />
						</span>
						<span>{endpoint.techType || '-'}</span>
					</div>
					<div className={styles.data}>
						<span>
							<FormattedMessage id="SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE" />
						</span>
						<span>{endpoint.techtypeSubtype || '-'}</span>
					</div>
				</div>
			)}
			{showValue(
				!endpoint.serviceStatus && !endpoint.oem ? undefined : true,
				<div className={styles.row}>
					{showValue(
						endpoint.serviceStatus,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.SERVICE_STATUS"
									defaultMessage="360L Status"
								/>
							</span>
							<span>{endpoint.serviceStatus}</span>
						</div>
					)}
					{showValue(
						endpoint.oem,
						<div className={styles.data}>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.OEM"
									defaultMessage="Sub Accounts"
								/>
								<div className={styles.oem_tooltip}>
									<FormattedMessage
										id="SINGLE_ENDPOINT.OEM_TOOLTIP"
										defaultMessage="Linked 360L Accounts"
									>
										{(formattedValue) => (
											<InfoFull data-tip={formattedValue} data-for="oem" />
										)}
									</FormattedMessage>
									<ReactTooltip
										className={styles.tooltip}
										type="light"
										id="oem"
									/>
								</div>
							</span>
							<span>{endpoint.oem}</span>
						</div>
					)}
				</div>
			)}
			{showValue(
				!endpoint.ratePlanId && !endpoint.networkEntitlementId
					? undefined
					: true,
				<div className={styles.row}>
					<div className={cn(styles.column, styles.remove_left_padding)}>
						{showValue(
							endpoint.ratePlanId,
							<RatePlanRWComponent>
								<RatePlanDetails
									ratePlan={find(
										ratePlans,
										(plan) =>
											plan.ratePlan && plan.ratePlan.id === endpoint.ratePlanId
									)}
									ratePlanId={endpoint.ratePlanId}
									m2mAccounts={m2mAccounts}
								/>
							</RatePlanRWComponent>
						)}
						{showValue(
							endpoint.networkEntitlementId,
							<NetworkEntitlementIdRWComponent>
								<NetworkEntitlementDetails
									networkEntitlementId={endpoint.networkEntitlementId}
									entitlement={entitlement}
								/>
							</NetworkEntitlementIdRWComponent>
						)}
					</div>
				</div>
			)}
			{showValue(
				endpoint &&
					Object.keys(endpoint).some((k) => k.indexOf('customFieldValue') === 0)
					? true
					: undefined,
				<div className={styles.row}>
					<CustomFieldsValuesRWComponent>
						<CustomFieldsValuesView
							inlineFields
							endpointName={endpointName}
							renderAction={!isPendingStatus}
							type={type}
							endpoint={endpoint}
							customFieldLabels={customFieldLabels}
						/>
					</CustomFieldsValuesRWComponent>
					<CustomFieldsValuesROComponent>
						<CustomFieldsValuesView
							inlineFields
							endpointName={endpointName}
							type={type}
							endpoint={endpoint}
							customFieldLabels={customFieldLabels}
						/>
					</CustomFieldsValuesROComponent>
				</div>
			)}
			{showValue(
				!endpoint.transferStatus &&
					!endpoint.roamingCountry &&
					!endpoint.roamingCarrier
					? undefined
					: true,
				<div className={`${styles.row} ${styles.last_row}`}>
					{showValue(
						endpoint.transferStatus,
						<TransferStatusRWComponent>
							<div className={styles.data}>
								<span className={styles.reimsi_span}>
									<FormattedMessage
										id="SINGLE_ENDPOINT.IMSI_TRANSFER"
										defaultMessage="IMSI Transfer Status"
									/>
									<div className={styles.pending}>
										{endpoint.transferStatus === 'REIMSI PENDING DELIVERED' && (
											<InfoFull
												data-tip={
													<FormattedMessage
														id="SINGLE_ENDPOINT.IMSI_PENDING_MESSAGE"
														defaultMessage="If Device is in Pending status for more than 72 hours, please contact Customer Support"
													/>
												}
												data-for="reimsiTooltip"
											/>
										)}
										{endpoint.transferStatus === 'REIMSI PENDING DELIVERED' && (
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id="reimsiTooltip"
											/>
										)}
									</div>
								</span>
								<span>
									{endpoint.transferStatus ? (
										<FormattedMessage
											id="SINGLE_ENDPOINT.TRANSFER_STATUSES"
											defaultMessage="{transferStatus}"
											values={{
												transferStatus: endpoint.transferStatus
													.replace(/_/g, ' ')
													.toLowerCase()
											}}
										/>
									) : null}
								</span>
							</div>
						</TransferStatusRWComponent>
					)}
					{showValue(
						endpoint.roamingCountry,
						<RoamingCountryRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.ROAMING_COUNTRY"
										defaultMessage="Roaming Country"
									/>
								</span>
								<span>{endpoint.roamingCountry}</span>
							</div>
						</RoamingCountryRWComponent>
					)}
					{showValue(
						endpoint.roamingCarrier,
						<RoamingCarrierRWComponent>
							<div className={styles.data}>
								<span>
									<FormattedMessage
										id="SINGLE_ENDPOINT.ROAMING_CARRIER"
										defaultMessage="Roaming Carrier"
									/>
								</span>
								<span>{endpoint.roamingCarrier}</span>
							</div>
						</RoamingCarrierRWComponent>
					)}
				</div>
			)}
		</div>
	);
}

Desktop.propTypes = {
	type: string,
	endpoint: object,
	endpointName: string,
	entitlement: object,
	isPendingStatus: bool,
	ratePlans: array,
	customFieldLabels: object,
	checkPendingStatus: func,
	renderDataSession: func,
  m2mAccounts: array,
  intl: object,
};
Desktop.defaultProps = {
	type: '',
	endpoint: {},
	endpointName: '',
	entitlement: {},
	isPendingStatus: false,
	ratePlans: [],
	customFieldLabels: {},
	checkPendingStatus: undefined,
	renderDataSession: undefined,
	m2mAccounts: [],
};

export default hoc(injectIntl(Desktop));
