import { Status } from '../../../utils/constants';

export const isFeedbackSending = (state) =>
	state.feedback.setFeedback.status === Status.PENDING;

export const isFeedbackSent = (state) =>
	state.feedback.setFeedback.status === Status.DONE;

export const isFeedbackFileSending = (state) =>
	state.feedback.feedbackFileUpload.status === Status.PENDING;

export const isFeedbackFileFail = (state) =>
	state.feedback.feedbackFileUpload.status === Status.FAIL;

export const isFeedbackFileSuccess = (state) =>
	state.feedback.feedbackFileUpload.status === Status.DONE;

export const feedbackFile = (state) => state.feedback.feedbackFileUpload.data;
