import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Switch from '../../../../lib/DigitalComponents/Switch';
import ModalFiltersIndicator from '../../../../lib/DigitalComponents/DSModal/ModalFiltersIndicator';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import Loading from '../../../../lib/DigitalComponents/Loader';

import { getNewFilterData } from '../../../../utils/tableHelpers';
import { localizationHelper } from '../../../../utils/helperFunctions';

import NoData from '../../views/NoDataView';

import styles from './FilterModal.scss';

class FilterModal extends PureComponent {
	async componentDidMount() {
		const { savedFiltersParams } = this.props;
		if (savedFiltersParams && savedFiltersParams.name) {
			if (this.areThereSavedFilters()) {
				await this.setFilterDataFromSettings();
			}
			savedFiltersParams.getData();
		}
	}

	async componentDidUpdate(prevProps) {
		const {
			filterModalOpened,
			getFilters,
			params,
			filters,
		} = this.props;
		if (!prevProps.filterModalOpened && filterModalOpened && !filters) {
			let filterParams = { ...params };
			if (this.areThereSavedFilters()) {
				filterParams = {
					...filterParams,
					hasSavedFilters: true
				};
			}
			getFilters(filterParams);
		}
	}

	componentWillUnmount() {
		const { setDataForFiltersInit } = this.props;
		setDataForFiltersInit();
	}

	areThereSavedFilters = () =>
		this.getUserSettings() &&
		JSON.parse(this.getUserSettings().value).filters &&
		!isEmpty(JSON.parse(this.getUserSettings().value).filters);

	setFilterDataFromSettings = async () => {
		const { setFilterData } = this.props;
		const savedFilters = JSON.parse(this.getUserSettings().value).filters;
		const filterDataKeys = Object.keys(savedFilters);
		let filter = {};
		filterDataKeys.forEach((item) => {
			filter = {
				...filter,
				[item]: savedFilters[item].map((filterItem) => ({
					value: filterItem.value || filterItem,
					label: filterItem.label || filterItem,
					checked: true
				}))
			};
		});
		await setFilterData(filter);
	};

	renderFilterData = (filterData, item) => {
		if (filterData[item].length > 0) {
			const category = item;
			return (
				<div data-spec={`${item}-filters`} key={item}>
					{filterData[item].map((filterItem) => (
						<Switch
							data-spec="switch"
							dataSpec={`switch-${
								filterItem && filterItem.label && filterItem.label.toLowerCase()
							}`}
							checked={filterItem.checked}
							label={filterItem.label}
							key={filterItem.value}
							onChange={(e) => this.onChange(e, category, filterItem)}
						/>
					))}
				</div>
			);
		}
		return (
			<div data-spec="no-filters" className={styles.no_filter_data}>
				<NoData
					title={
						<FormattedMessage
							id="NO_AVAILABLE_FILTERS"
							defaultMessage="No Available Filters"
						/>
					}
					subtitle={
						<FormattedMessage
							id="NO_AVAILABLE_FILTERS_SUBTITLE"
							defaultMessage="There are No Available Filters to show you right now"
						/>
					}
				/>
			</div>
		);
	};

	dismissModal = () => {
		const { originalFilter, setFilterData, dismissModal } = this.props;
		setFilterData(cloneDeep(originalFilter));
		dismissModal();
	};

	applyFilter = async () => {
		const {
			dismissModal,
			onApply,
			setOriginalFilter,
			savedFiltersParams,
			createUserSettings,
			updateUserSettings,
			setPaginationInit
		} = this.props;
		const { filterData } = this.props;
		dismissModal();
		window.scrollTo(0, 0);
		setOriginalFilter(cloneDeep(filterData));
		await setPaginationInit();
		if (savedFiltersParams) {
			// eslint-disable-next-line no-lonely-if
			if (this.getUserSettings()) {
				await updateUserSettings(
					{
						value: JSON.stringify({
							...JSON.parse(this.getUserSettings().value),
							filters: this.prepareFiltersForSettings()
						})
					},
					this.getUserSettings().id
				);
			} else {
				await createUserSettings({
					refTypeId: savedFiltersParams.refTypeId,
					name: savedFiltersParams.name,
					value: JSON.stringify({
						filters: this.prepareFiltersForSettings()
					})
				});
			}
			savedFiltersParams.getData();
		} else {
			onApply();
		}
	};

	onChange = (e, category, item) => {
		const { setFilterData, filterData, type } = this.props;
		const newFilterData = getNewFilterData(e, category, item, filterData, type);
		setFilterData(newFilterData);
	};

	checkFilters = () => {
		const { filterData, originalFilter } = this.props;
		return isEqual(filterData, originalFilter);
	};

	renderModalBody = (filterData, filtersFail, filtersRequest) => {
		if (!filtersFail && filtersRequest) {
			return (
				<ModalBody data-spec="filter-modal-body">
					<div className={styles.loading_text}>
						<Loading data-spec="loading" customStyle={{ minHeight: 100 }} />
						<span>
							<FormattedMessage
								id="FETCHING_DATA"
								defaultMessage="Please wait. Fetching data..."
							/>
						</span>
					</div>
				</ModalBody>
			);
		}

		if (filtersFail || isEmpty(filterData)) {
			return this.renderNoData();
		}
		const filterDataKeys = Object.keys(filterData);
		const tabs = filterDataKeys.map((item) => ({
			id: item,
			displayName: (
				<FormattedMessage
					id={`FILTER_MODAL.TABS_${localizationHelper(item)}`}
					defaultMessage={item}
				/>
			),
			dataSpec: item.toLowerCase(),
			disabled: isEmpty(filterData[item]),
			content: () => (
				<>
					<div className={styles.title}>
						<FormattedMessage
							id={`FILTER_MODAL.${localizationHelper(item)}`}
							defaultMessage={item}
						/>
					</div>
					{this.renderFilterData(filterData, item)}
				</>
			)
		}));
		if (tabs.filter((item) => !item.disabled).length === 0) {
			return this.renderNoData();
		}
		return (
			<ModalBody data-spec="filter-modal-body" className={styles.modalBody}>
				<ModalTabs
					data-spec="filter-tabs"
					tabs={tabs}
					className={styles.modal_tabs}
				/>
				<ModalFiltersIndicator
					className={styles.modal_filters}
					filterData={filterData}
					onChange={this.onChange}
					selectedFilters={
						<FormattedMessage
							id="SELECTED_FILTERS"
							defaultMessage="SELECTED FILTERS"
						/>
					}
				/>
			</ModalBody>
		);
	};

	renderNoData = () => (
		<div data-spec="modal-error">
			<ModalBody>
				<NoData
					title={
						<FormattedMessage
							id="NO_AVAILABLE_FILTERS"
							defaultMessage="No Available Filters"
						/>
					}
					subtitle={
						<FormattedMessage
							id="NO_AVAILABLE_FILTERS_SUBTITLE"
							defaultMessage="There are No Available Filters to show you right now"
						/>
					}
				/>
			</ModalBody>
		</div>
	);

	/* 	isFilterDataEmpty = (filterData) => {
		const filterDataKeys = Object.keys(filterData);
		filterDataKeys.map((item) => {
			if (filterData[item].length > 0) return false;
		});
		return true;
  }; */

	getUserSettings = () => {
		const { userSettings, savedFiltersParams } = this.props;
		return (
			savedFiltersParams &&
			userSettings.find(
				(x) =>
					x.name === savedFiltersParams.name &&
					x.refTypeId === savedFiltersParams.refTypeId
			)
		);
	};

	prepareFiltersForSettings = () => {
		const { filterData } = this.props;
		let onlySelectedFilterDataArrays = {};
		let savedFilters = {};
		if (filterData) {
			Object.keys(filterData).forEach((key) => {
				filterData[key].forEach((filter) => {
					if (filter.checked) {
						onlySelectedFilterDataArrays = {
							...onlySelectedFilterDataArrays,
							[key]: filterData[key]
						};
					}
				});
				if (onlySelectedFilterDataArrays[key]) {
					savedFilters = {
						...savedFilters,
						[key]: onlySelectedFilterDataArrays[key]
							.filter((x) => x.checked)
							.map((filter) => filter.value)
					};
				}
			});
		}
		return savedFilters;
	};

	render() {
		const {
			filtersFail,
			filtersRequest,
			filterModalOpened,
			filterData
		} = this.props;
		return (
			<Modal
				data-spec="filter-modal"
				show={filterModalOpened}
				onClose={() => this.dismissModal()}
				className={styles.modal}
			>
				<ModalHeader
					className={styles.modalHeader}
					title={<FormattedMessage id="FILTERS" defaultMessage="Filters" />}
				/>
				{this.renderModalBody(filterData, filtersFail, filtersRequest)}
				<ModalFooter className={styles.modalFooter}>
					<Button
						useCloseModalFunction
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						dataSpec="cancel"
						onClick={() => this.onCancel()}
					/>
					<Button
						onClick={() => this.applyFilter()}
						dataSpec="change-filter"
						variant="primary"
						label={<FormattedMessage id="CHANGE" defaultMessage="Change" />}
						disabled={filtersFail || isEmpty(filterData) || this.checkFilters()}
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

const { func, bool, string, shape, object, array } = PropTypes;

FilterModal.propTypes = {
	dismissModal: func,
	filterModalOpened: bool,
	filterData: shape(),
	onApply: func,
	getFilters: func,
	params: shape(),
	setFilterData: func,
	setOriginalFilter: func,
	filters: shape(),
	setDataForFiltersInit: func,
	filtersFail: bool,
	filtersRequest: bool,
	originalFilter: shape(),
	type: string,
	savedFiltersParams: object,
	userSettings: array,
	createUserSettings: func,
	updateUserSettings: func,
	setPaginationInit: func
};

export default FilterModal;
