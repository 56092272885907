import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getLocale } from '../../../utils/constants';

const locale = getLocale();
const messages = require(`../../../localizations/${locale}`).default;

const dateFormat = messages.DISPLAY_DATE_FORMAT || 'MM/dd/yyyy';

export const analyticsInvoicesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.INVOICE_ID"
					defaultMessage="Invoice ID"
				/>
			</div>
		),
		value: 'invoice_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ACCOUNT_ID"
					defaultMessage="Account ID"
				/>
			</div>
		),
		value: 'm2maccount_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ACCOUNT_NAME"
					defaultMessage="Account Name"
				/>
			</div>
		),
		value: 'account_name'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.BILLING_CYCLE"
					defaultMessage="Billing Cycle"
				/>
			</div>
		),
		value: 'billing_cycle'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID"
					defaultMessage="Operator Account ID"
				/>
			</div>
		),
		value: 'operator_account_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.BILL"
					defaultMessage="Billable"
				/>
			</div>
		),
		value: 'billable'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DATE"
					defaultMessage="Invoice Date"
				/>
			</div>
		),
		value: 'date_key',
		placeholder: dateFormat
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.TAXABLE"
					defaultMessage="Taxable Account"
				/>
			</div>
		),
		value: 'taxable_account'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.TAX_STATUS"
					defaultMessage="Tax Status"
				/>
			</div>
		),
		value: 'tax_status'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ACCOUNT_SEGMENT"
					defaultMessage="Account Segment"
				/>
			</div>
		),
		value: 'account_segment'
	}
];

export const endpointsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ICCID"
					defaultMessage="ICCID"
				/>
			</div>
		),
		value: 'iccid'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.MSISDN"
					defaultMessage="MSISDN"
				/>
			</div>
		),
		value: 'msisdn'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DEVICE_ID"
					defaultMessage="Device ID"
				/>
			</div>
		),
		value: 'device_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CUSTOMER"
					defaultMessage="Customer"
				/>
			</div>
		),
		value: 'customer'
	}
];

export const prepaidSubscriptionsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ICCID"
					defaultMessage="ICCID"
				/>
			</div>
		),
		value: 'iccid'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.MSISDN"
					defaultMessage="MSISDN"
				/>
			</div>
		),
		value: 'msisdn'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DEVICE_ID"
					defaultMessage="Device ID"
				/>
			</div>
		),
		value: 'device_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CUSTOMER"
					defaultMessage="Customer"
				/>
			</div>
		),
		value: 'customer'
	}
];

export const addonsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage id="ANALYTICS_INVOICES.ZONE" defaultMessage="Zone" />
			</div>
		),
		value: 'zone'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PLAN_VERSION"
					defaultMessage="Plan version"
				/>
			</div>
		),
		value: 'plan_version'
	}
];

export const ratePlansSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PAYMENT_TYPE"
					defaultMessage="Payment Type"
				/>
			</div>
		),
		value: 'payment_type'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PLAN_TYPE"
					defaultMessage="Plan Type"
				/>
			</div>
		),
		value: 'plan_type'
	}
];

export const ratePlanZonesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ROAMING_ZONE"
					defaultMessage="Roaming Zone"
				/>
			</div>
		),
		value: 'roaming_zone'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PLAN_VERSION"
					defaultMessage="Plan version"
				/>
			</div>
		),
		value: 'plan_version'
	}
];

export const billingGroupsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage id="ANALYTICS_INVOICES.ZONE" defaultMessage="Zone" />
			</div>
		),
		value: 'zone'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PLAN_VERSION"
					defaultMessage="Plan Version"
				/>
			</div>
		),
		value: 'plan_version'
	}
];

export const dataDetailsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DEVICE_ID"
					defaultMessage="Device ID"
				/>
			</div>
		),
		value: 'device_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ICCID"
					defaultMessage="ICCID"
				/>
			</div>
		),
		value: 'iccid'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CUSTOMER"
					defaultMessage="Customer"
				/>
			</div>
		),
		value: 'customer'
	}
];

export const smsDetailsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CUSTOMER"
					defaultMessage="Customer"
				/>
			</div>
		),
		value: 'customer'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DEVICE_ID"
					defaultMessage="Device ID"
				/>
			</div>
		),
		value: 'device_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ICCID"
					defaultMessage="ICCID"
				/>
			</div>
		),
		value: 'iccid'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	}
];

export const activationsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DEVICE_ID"
					defaultMessage="Device ID"
				/>
			</div>
		),
		value: 'device_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ICCID"
					defaultMessage="ICCID"
				/>
			</div>
		),
		value: 'iccid'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CUSTOMER"
					defaultMessage="Customer"
				/>
			</div>
		),
		value: 'customer'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	}
];

export const discountsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CHARGE_TYPE"
					defaultMessage="Charge Type"
				/>
			</div>
		),
		value: 'charge_type'
	}
];

export const taxesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PRIMARY_PLACE"
					defaultMessage="Primary Place"
				/>
			</div>
		),
		value: 'primary_place'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.TECH_TYPE"
					defaultMessage="Tech Type"
				/>
			</div>
		),
		value: 'tech_type'
	}
];

export const surchargesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE"
					defaultMessage="Primary place of use"
				/>
			</div>
		),
		value: 'primary_place_of_use'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.FEDERAL_STATE"
					defaultMessage="Federal State"
				/>
			</div>
		),
		value: 'state'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.COUNTY_CITY"
					defaultMessage="County City"
				/>
			</div>
		),
		value: 'city'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DISTRICT_COUNTY"
					defaultMessage="District County"
				/>
			</div>
		),
		value: 'district_county'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DISTRICT_CITY"
					defaultMessage="District City"
				/>
			</div>
		),
		value: 'district_city'
	}
];

export const techTypesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.PLAN_ID"
					defaultMessage="Plan ID"
				/>
			</div>
		),
		value: 'plan_id'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.VERSION_ID"
					defaultMessage="Version ID"
				/>
			</div>
		),
		value: 'version_id'
	}
];

export const otherChargesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.DESCRIPTION"
					defaultMessage="Description"
				/>
			</div>
		),
		value: 'description'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CHARGE_TYPE"
					defaultMessage="Charge Type"
				/>
			</div>
		),
		value: 'charge_type'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.REFERENCE_ID"
					defaultMessage="Reference ID"
				/>
			</div>
		),
		value: 'reference_id'
	}
];

export const voiceDetailsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.ICCID"
					defaultMessage="ICCID"
				/>
			</div>
		),
		value: 'iccid'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.RATE_PLAN"
					defaultMessage="Rate Plan"
				/>
			</div>
		),
		value: 'rate_plan_name'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ANALYTICS_INVOICES.BY" defaultMessage="By" />
				<FormattedMessage
					id="ANALYTICS_INVOICES.CUSTOMER"
					defaultMessage="Customer"
				/>
			</div>
		),
		value: 'customer'
	}
];
