import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { isEqual } from 'lodash';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import Button from '../../../../../lib/DigitalComponents/Button';
import { getDateFormat, startOfDayFormatter } from '../../../../../utils/constants';

import styles from './Steps.scss';

class StepSecond extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
		const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context } = this.props;
		const { selectedDay } = this.state;
		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

	onNext = () => {
		const { onNext } = this.props;
		const { selectedDay } = this.state;
		const data = {
			startDate: startOfDayFormatter(selectedDay)
		};
		onNext({ data });
	};

	onDateChange = (date) => {
		this.setState({
			selectedDay: date
		});
	};

	setFooter = (context) => {
		const { onClose } = this.props;
		const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<Button
					dataSpec="initiate-change"
					variant="primary"
					onClick={this.onNext}
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule change"
							/>
						) : (
							<FormattedMessage
								id="INITIATE_CHANGE"
								defaultMessage="Initiate change"
							/>
						)
					}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
		const { networkEntitlement } = this.props;
		const { selectedDay } = this.state;

		return (
			<div data-spec="second-step">
				<div data-spec="ds-modal-body" className={styles.custom}>
					<div className={styles.entitlements_title}>
						<FormattedMessage
							id="ENDPOINTS.CONFIRM_NETWORK_ENTITLEMENT_CHANGE"
							defaultMessage="Confirm Network Entitlement Change"
						/>
					</div>
					<div className={styles.select_field_label_text}>
						<FormattedMessage
							id="ONCE_YOU_INITIATE_THIS_CHANGE"
							defaultMessage="Once you initiate this change, the selected endpoint’s network entitlement will be "
						/>
						<span>
							{networkEntitlement &&
								networkEntitlement.networkEntitlement.label}
						</span>
						. &nbsp;
						<FormattedMessage
							id="WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW"
							defaultMessage="Would you like to initiate change now?"
						/>
					</div>
					<div
						className={cn(
							styles.select_field_wrapper,
							styles.rate_plan,
							styles.datepicker
						)}
					>
						<FormattedMessage
							id="ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR"
							defaultMessage="Or you can schedule the change for: "
						/>
						<DSDatePicker value={selectedDay} onDayChange={this.onDateChange} />
					</div>
				</div>
			</div>
		);
	}
}

StepSecond.propTypes = {
	onClose: PropTypes.func,
	onNext: PropTypes.func,
	networkEntitlement: PropTypes.object,
	context: PropTypes.object
};

export default StepSecond;
