import { connect } from 'react-redux';

import {
	getBlockedSubscriptionDcp,
	getDiagnosticDcp,
	getTroubleshootDcp,
} from '../../redux/actions';
import {
	getBlockedSubscriptionDcpData,
	getDiagnosticDcpData,
	getTroubleshootDcpData,
	isGetBlockedSubscriptionDcpFetching,
	isGetDcpEdFetching,
	isGetDcpEdSuccess,
	isGetTroubleshootDcpFetching,
} from '../../redux/selectors';
import DiagnosticDcp from './DiagnosticDcp';

const mapStateToProps = (state) => (
	{
		isFetchingBlockedSubscription: isGetBlockedSubscriptionDcpFetching(state),
		blockedSubscriptionDcpData: getBlockedSubscriptionDcpData(state),
		isGetTroubleshootDcpFetching: isGetTroubleshootDcpFetching(state),
		troubleshootDcpData: getTroubleshootDcpData(state),
		diagnosticDcpData: getDiagnosticDcpData(state),
		isFetching: isGetDcpEdFetching(state),
		isSuccess: isGetDcpEdSuccess(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getBlockedSubscriptionDcp: (iccid, m2mAccountId) =>
			dispatch(getBlockedSubscriptionDcp(iccid, m2mAccountId)),
		getTroubleshootDcp: (iccid) => dispatch(getTroubleshootDcp(iccid)),
		getDiagnosticDcp: (iccid) => dispatch(getDiagnosticDcp(iccid))
	}
);

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticDcp);
