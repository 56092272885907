export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': 'Supprimer règle d’automatisation ?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': 'Supprimer règles d’automatisation sélectionnées ?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': 'Voulez-vous vraiment supprimer les règles d’automatisation sélectionnées ?',
	'AUTOMATION.CANCEL': 'Annuler',
	'AUTOMATION.DELETE': 'Supprimer',
	'AUTOMATION.ARE_YOU_SURE': 'Voulez-vous vraiment ?',
	'AUTOMATION.ACTIVE': 'Activé',
	'AUTOMATION.ACC_IF': 'Si',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Ajouter un autre seuil',
	'AUTOMATION.EDIT_RULE': 'Modifier règlement',
	'AUTOMATION.CREATE_NEW_RULE': 'Créer nouveau règlement',
	'AUTOMATION.PLATFORM': 'Plateforme',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Compte',
	'AUTOMATION.CATEGORY': 'Catégorie',
	'AUTOMATION.RULE_STATE': 'État de la règle',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Actif',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inactif',
	'AUTOMATION.TRIGGER': 'Déclencheur',
	'AUTOMATION.ACC_RULE_NAME': 'Nom de la règle',
	'AUTOMATION.SAVE': 'Enregistrer',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Effacer filtres sélectionnés',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Sélectionner filtres',
	'AUTOMATION.AUTOMATION': 'Automatisation',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Créer nouvelle règle d’automatisation',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': 'Voulez-vous vraiment supprimer la règle d’automatisation {ruleName} ?',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Règle créée',
	'AUTOMATION.ADD_RULE_ERROR': 'Échec de la règle d’automatisation. Veuillez réessayer.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Règle modifiée',
	'AUTOMATION.EDIT_RULE_ERROR': 'Échec de la modification de la règle d’automatisation. Veuillez réessayer.',
	'AUTOMATION.THEN': 'Alors',
	'AUTOMATION.NUMBER_OF_DAYS': 'Nombre de jours',
	'AUTOMATION.NUMBER_OF_HOURS': 'Nombre d’heures (max. 24 h)',
	'AUTOMATION.AT': 'À',
	'AUTOMATION.SIM_STATE': 'État SIM',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'ID forfait',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'ID sous-compte machine-machine',
	'AUTOMATION.CUSTOMER_ID': 'ID client',
	'AUTOMATION.CUSTOM': 'Personnaliser',
	'AUTOMATION.FILTER': 'Filtre',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'Aucun filtre disponible',
	'AUTOMATION.THRESHOLD': 'Seuil',
	'AUTOMATION.ZONE_MODEL': 'Modèle de zone',
	'AUTOMATION.ZONE_NAME': 'Nom de zone',
	'AUTOMATION.POOL_ID': 'ID groupe',
	'AUTOMATION.COUNTRY': 'Pays',
	'AUTOMATION.FROM_KEY': 'De',
	'AUTOMATION.TO_KEY': 'À',
	'AUTOMATION.FOLLOW_UP_WITH': 'Suivi avec',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': 'Suppression réussie de la règle d’automatisation',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': 'Suppression réussie des alertes d’automatisation sélectionnées',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': 'Échec de la suppression de la règle d’automatisation. Veuillez réessayer.',
	'AUTOMATION.PLATFORMS': 'Plateformes',
	'AUTOMATION.CATEGORIES': 'Catégories',
	'AUTOMATION.TRIGGERS': 'Déclencheurs',
	'AUTOMATION.M2MACCOUNTS': 'Comptes machine-machine',
	'AUTOMATION.HOURS_USING': 'Dans les dernières 24 h en utilisant',
	'AUTOMATION.LOCATIONUPDATE': 'Mise à jour endroit',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Mise à jour authentification',
	'AUTOMATION.ZONE': 'Zone',
	'AUTOMATION.ACCOUNT_ID': 'ID compte',
	'AUTOMATION.ACTION': 'Action',
	'AUTOMATION.DCP': 'Plateforme de connexion d’appareils',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'SMS',
	'AUTOMATION.USAGE': 'Utilisation',
	'AUTOMATION.SIM_PROVISIONING': 'Provisionnement SIM',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Surveillance abonnement',
	'AUTOMATION.USAGE_MONITORING': 'Surveillance utilisation',
	'AUTOMATION.NETWORK_MONITORING': 'Surveillance réseau',
	'AUTOMATION.PRICING_AUTOMATION': 'Automatisation de tarification',
	'AUTOMATION.SECURITY_MONITORING': 'Surveillance sécurité',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'Changement forfait SIM',
	'AUTOMATION.SIM_STATE_CHANGE': 'Changement État SIM',
	'AUTOMATION.SMS_USAGE': 'Utilisation SMS',
	'AUTOMATION.SIM_DATA_LIMIT': 'Limite données SIM',
	'AUTOMATION.SIM_EXPIRATION': 'Expiration SIM',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Utilisation données du cycle à ce jour',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Utilisation voix du cycle à ce jour',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': 'Utilisation données dépassée dans les dernières 24 h',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': 'Nombre de connexions de sessions dans les dernières 24 h',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Utilisation mensuelle groupée données',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Utilisation mensuelle groupée SMS',
	'AUTOMATION.NO_CONNECTION': 'Aucune connexion',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Utilisation voix dans les dernières 24 h',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'Consigner dans une zone',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Connexions trop nombreuses',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'Dépassement SMS de départ par période',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'Insuffisance SMS de départ par période',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'Dépassement SMS d’arrivée par période',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'Insuffisance SMS d’arrivée par période',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'GPRS à long terme dépassé',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'GPRS à court terme dépassé',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'Première session de données établie',
	'AUTOMATION.IMEI_CHANGED': 'IMEI changée',
	'AUTOMATION.NETWORK_REGISTRATION': 'Enregistrement réseau',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Enregistrement réseau dans une zone',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'Champ personnalisé SIM changé',
	'AUTOMATION.SIM_STATUS': 'État SIM',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Forfait changé',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'Pays SIM changé',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'Expiration forfait SIM',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Session données arrêtée',
	'AUTOMATION.DATA_SESSION_STARTED': 'Session données démarrée',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Utilisation données dans un pays',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': 'Nbre sessions données – cycle de facturation',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Utilisation données dans une zone',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'Aucune connexion au cours du cycle de facturation',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': 'Nbre connexions de sessions dans les dernières 24 h',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'Nombre de SMS dans un pays',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'Utilisation SMS dans une zone',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'Utilisation SMS dans les dernières 24 h',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Utilisation totale données – 24 heures',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': 'Utilisation totale données – cycle de facturation',
	'AUTOMATION.SMS_MO_RECEIVED': 'SMS départ reçus',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': 'Utilisation voix du cycle à ce jour dans une zone',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'Achèvement plan SIM voix',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'Achèvement plan SIM données',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'Action liste blanche IMEI',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'Changement compte SIM',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Utilisation SMS dernières 24 h',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': 'Enregistrement réseau dans une zone',
	'AUTOMATION.MSISDN_CHANGE': 'Changement MSISDN',
	'AUTOMATION.IMEI_CHANGE': 'Changement IMEI',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': 'Utilisation données du cycle à ce jour dans une zone',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': 'Un point terminal est transféré depuis',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': 'Durant le cycle actuel, tout point terminal utilise',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Tout point terminal d’un forfait prépayé va au bout de la durée prépayée',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': 'Le réseau détecte un changement d’IMEI du point terminal',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': 'Le système détecte un changement de MSISDN pour un point terminal',
	'AUTOMATION.INITIATE_OTA_SWAP': 'Lancer changement OTA',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': 'Tout point terminal envoie ou reçoit plus de',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': 'Toute carte SIM subit un changement de l’un des champs personnalisés',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': 'L’IMEI du point terminal ne figure pas dans la liste blanche',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': 'Tout point terminal dans le cycle actuel dépasse',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': 'Un SMS de départ est reçu pour un code abrégé',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'Nbre total de SMS – cycle de facturation',
	'AUTOMATION.1_HOUR': '1 heure',
	'AUTOMATION.2_HOURS': '2 heures',
	'AUTOMATION.3_HOURS': '3 heures',
	'AUTOMATION.4_HOURS': '4 heures',
	'AUTOMATION.5_HOURS': '5 heures',
	'AUTOMATION.6_HOURS': '6 heures',
	'AUTOMATION.7_HOURS': '7 heures',
	'AUTOMATION.8_HOURS': '8 heures',
	'AUTOMATION.9_HOURS': '9 heures',
	'AUTOMATION.10_HOURS': '10 heures',
	'AUTOMATION.11_HOURS': '11 heures',
	'AUTOMATION.12_HOURS': '12 heures',
	'AUTOMATION.13_HOURS': '13 heures',
	'AUTOMATION.14_HOURS': '14 heures',
	'AUTOMATION.15_HOURS': '15 heures',
	'AUTOMATION.16_HOURS': '16 heures',
	'AUTOMATION.17_HOURS': '17 heures',
	'AUTOMATION.18_HOURS': '18 heures',
	'AUTOMATION.19_HOURS': '19 heures',
	'AUTOMATION.20_HOURS': '20 heures',
	'AUTOMATION.21_HOURS': '21 heures',
	'AUTOMATION.22_HOURS': '22 heures',
	'AUTOMATION.23_HOURS': '23 heures',
	'AUTOMATION.24_HOURS': '24 heures',
	'AUTOMATION.25_HOURS': '25 heures',
	'AUTOMATION.26_HOURS': '26 heures',
	'AUTOMATION.27_HOURS': '27 heures',
	'AUTOMATION.28_HOURS': '28 heures',
	'AUTOMATION.29_HOURS': '29 heures',
	'AUTOMATION.30_HOURS': '30 heures',
	'AUTOMATION.31_HOURS': '31 heures',
	'AUTOMATION.32_HOURS': '32 heures',
	'AUTOMATION.33_HOURS': '33 heures',
	'AUTOMATION.34_HOURS': '34 heures',
	'AUTOMATION.35_HOURS': '35 heures',
	'AUTOMATION.36_HOURS': '36 heures',
	'AUTOMATION.37_HOURS': '37 heures',
	'AUTOMATION.38_HOURS': '38 heures',
	'AUTOMATION.39_HOURS': '39 heures',
	'AUTOMATION.40_HOURS': '40 heures',
	'AUTOMATION.41_HOURS': '41 heures',
	'AUTOMATION.42_HOURS': '42 heures',
	'AUTOMATION.43_HOURS': '43 heures',
	'AUTOMATION.44_HOURS': '44 heures',
	'AUTOMATION.45_HOURS': '45 heures',
	'AUTOMATION.46_HOURS': '46 heures',
	'AUTOMATION.47_HOURS': '47 heures',
	'AUTOMATION.48_HOURS': '48 heures',
	'AUTOMATION.LAST_24_HOURS': 'Dernières 24 heures',
	'AUTOMATION.CYCLE_TO_DATE': 'Cycle à ce jour',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'Dépassement forfait prépayé SIM',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'Utilisation données dépasse limite',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'Utilisation données près de la limite',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'Forfait prépayé SIM à moins de',
	'AUTOMATION.MO_MT': 'Arrivée/Départ',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'Utilisation voix dépasse limite',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'Utilisation voix près de la limite',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'La SIM a établi plus de',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'La SIM a établi moins de',
	'AUTOMATION.EXCEEDES': 'Dépassée',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': 'Aucune connexion données SIM dans les dernières N heures',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'SIM s’enregistre au réseau dans la zone',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'La SIM a établi plus de connexions au cours du cycle actuel',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': 'Le réseau détecte un nouvel appareil',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'L’utilisation cycle jusqu’à ce jour dépasse',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'Utilisation cycle jusqu’à ce jour approche',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': 'Le réseau détecte un changement dans',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'SIM enregistrée pour la première fois sur un nouveau réseau',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'Point terminal s’enregistre dans zone',
	'AUTOMATION.CUSTOM_FIELD_1': 'Champ personnalisé 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Champ personnalisé 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Champ personnalisé 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Champ personnalisé 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Champ personnalisé 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Champ personnalisé 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Champ personnalisé 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Champ personnalisé 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Champ personnalisé 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Champ personnalisé 10',
	'AUTOMATION.CHANGED': 'Changé',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Changement forfait',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': 'Le nbre de sessions de données pour une SIM dépasse',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'Une session de données SIM démarre',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'Une session de données SIM s’arrête',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': 'L’utilisation de données dans un pays dépasse',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'L’utilisation de données dans un dépasse',
	'AUTOMATION.EXCEEDS': 'Dépassée',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': 'L’utilisation SMS facturable sur tout forfait groupé atteint',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': 'Aucune SIM n’a établi de connexion données durant le dernier cycle de facturation',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'Une SIM a plus que',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'Le nbre de SMS dans un pays dépasse',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'Le nbre de SMS dépasse la limite',
	'AUTOMATION.1_WEEK': '1 semaine',
	'AUTOMATION.2_WEEKS': '2 semaines',
	'AUTOMATION.3_WEEKS': '3 semaines',
	'AUTOMATION.4_WEEKS': '4 semaines',
	'AUTOMATION.SEND_EMAIL': 'Envoyer e-mail',
	'AUTOMATION.SEND_SMS': 'Envoyer SMS',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': 'Changer le droit d’utiliser le réseau de l’appareil',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': 'Changer état SIM de l’appareil',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': 'Changer forfait de l’appareil',
	'AUTOMATION.PUSH_API': 'API de type push',
	'AUTOMATION.COMBINED': 'Combiné',
	'AUTOMATION.MO': 'Message départ',
	'AUTOMATION.MT': 'Message arrivée',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Connexions en 24 heures',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Changer droit d’utiliser le réseau',
	'AUTOMATION.CHANGE_SIM_STATUS': 'Changer l’état SIM',
	'AUTOMATION.SMS_MESSAGES': 'Messages SMS',
	'AUTOMATION.HOURS': 'Heures',
	'AUTOMATION.MIN': 'Min',
	'AUTOMATION.MINUTES': 'Minutes',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Changer forfait',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': 'Connexions durant le cycle actuel',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'Ko',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Revenir au forfait précédent',
	'AUTOMATION.MODIFIED_DELETED': 'Modifié/Supprimé',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': 'Le forfait des SIM est à la veille d’expirer',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': 'La quantité de son groupe incluait l’utilisation SMS',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': 'Nombre de sessions de données dans les dernières 24 h',
	'AUTOMATION.MSGS': 'Messages',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'Nombre de SMS dans la zone',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': 'Nbre de sessions de données dans les dernières 24 h',
	'AUTOMATION.MB': 'Mo',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Heures de consommation',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Revenir à l’état SIM précédent',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Jours après événement déclencheur',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'Fin du cycle de facturation actuel',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': 'Temps UTC après événement déclencheur',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': 'Revenir au droit d’utiliser le réseau précédent',
	'AUTOMATION.GMNA': 'GM Amérique du Nord',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Entreprise',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM Amérique du Nord',
	'AUTOMATION.ENTERPRISE': 'Entreprise',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'Changer état SIM',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'Utilisation SMS dernières 24 h',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': 'Utilisation SMS dans les dernières 24 h',
	// Triggers
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': 'Utilisation SMS de départ (provenant d’un mobile) - 24 heures',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': 'Utilisation SMS de départ (provenant d’un mobile) - Dans une zone',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': 'Utilisation SMS de départ (provenant d’un mobile) - Plan de groupe',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': 'Utilisation SMS d’arrivée (provenant d’un mobile) - 24 heures',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': 'Utilisation SMS d’arrivée (provenant d’un mobile) - Cycle de facturation',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': 'Utilisation SMS d’arrivée (provenant d’un mobile) - Dans une zone',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': 'Utilisation SMS d’arrivée (provenant d’un mobile) - Plan de groupe',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'Le jour après l’expiration du forfait SIM',
	'AUTOMATION.BYTES': 'Octets',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Ajouter texte personnalisé au message SMS (facultatif)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': 'Texte message personnalisé (limite de 140 caractères)',
	'AUTOMATION.TB': 'To',
	'AUTOMATION.B': 'o',
	'AUTOMATION.GB': 'Go',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'Achèvement plan SIM',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Si toute carte SIM d’un forfait prépayé va au bout de la durée prépayée',
	// new automation
	'AUTOMATION.TITLE': 'Automatisation',
	'AUTOMATION.RULES_TAB_NAME': 'Règles d’automatisation',
	'AUTOMATION.ALERTS_TAB_NAME': 'Alertes d’automatisation',
	'AUTOMATION_RULES.YES': 'Oui',
	'AUTOMATION_RULES.NO': 'Non',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'Aucune règle d’automatisation disponible',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': 'Aucune règle d’automatisation à vous montrer actuellement',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'Aucune alerte d’automatisation disponible',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': 'Aucune alerte d’automatisation à vous montrer actuellement',
	//  rules table headers
	'AUTOMATION_RULES.ID': 'ID',
	'AUTOMATION_RULES.RULE_NAME': 'Nom de la règle',
	'AUTOMATION_RULES.PLATFORM': 'Plateforme',
	'AUTOMATION_RULES.CATEGORY': 'Catégorie',
	'AUTOMATION_RULES.TRIGGER': 'Déclencheur',
	'AUTOMATION_RULES.ACTIVE': 'Activé',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'ID compte machine-machine',
	'AUTOMATION_RULES.DATE_CREATED': 'Date création',
	'AUTOMATION_RULES.USER': 'Utilisateur',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'État de la mise en œuvre',
	// alerts table headers
	'AUTOMATION_ALERTS.ID': 'ID',
	'AUTOMATION_ALERTS.RULE_NAME': 'Nom de la règle',
	'AUTOMATION_ALERTS.PLATFORM': 'Plateforme',
	'AUTOMATION_ALERTS.CATEGORY': 'Catégorie',
	'AUTOMATION_ALERTS.TRIGGER': 'Déclencheur',
	'AUTOMATION_ALERTS.ACTIVE': 'Activé',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'ID compte machine-machine',
	'AUTOMATION_ALERTS.DATE': 'Date',
	'AUTOMATION_ALERTS.USER': 'Utilisateur',
	'AUTOMATION_ALERTS.ACTION': 'Action',
	// rules
	'AUTOMATION.ACTIVATE': 'Activer',
	'AUTOMATION.DEACTIVATE': 'Désactiver',
	'AUTOMATION.RULE_CREATED_OR_EDITED': 'Vous avez sélectionné une règle d’automatisation qui nécessite un traitement prolongé et ne pourra être définie immédiatement.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': 'Activation réussie de la règle d’automatisation',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'Échec de l’activation de la règle d’automatisation',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': 'Désactivation réussie de la règle d’automatisation',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': 'Échec de la désactivation de la règle d’automatisation',
	'AUTOMATION.SHORT_CODE': 'Code abrégé',
	'AUTOMATION.BASE_RATE_PLAN': 'Forfait de base',
	'AUTOMATION.RATE_PLAN_TYPE': 'Type de forfait',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'État de la mise en œuvre',
	'AUTOMATION.YES': 'Oui',
	'AUTOMATION.NO': 'Non',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Changement d’état de règle',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': 'Voulez-vous vraiment continuer ?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': 'Vous êtes sur le point de changer l’état {currentState} d’une règle vers ’{selectedState}’',
	'AUTOMATION.REQUEST_DELETION_TEXT': 'Vous êtes sur le point de demander la suppression d’une règle',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Suppression de règle',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': 'Vous êtes sur le point de supprimer la règle {ruleName}',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Demande de suppression de règle d’automatisation',
	'AUTOMATION.REQUEST_DELETION': 'Demande de suppression',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': 'Vous avez bien changé l’état de la mise en œuvre à ’Reçu’',
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': 'Vous avez bien changé l’état de la mise en œuvre à ’Mis en œuvre’',
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': 'Vous avez bien changé l’état de la mise en œuvre à ’En conflit’',
	'AUTOMATION.SET_RULE_STATE_FAIL': 'Une erreur est survenue lors du changement d’état de la mise en œuvre. Veuillez réessayer.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': 'Vous avez bien demandé la suppression de la règle.',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': 'Une erreur est survenue lors de la demande de suppression d’une règle.',
	'AUTOMATION.RULE_CHANGE': 'Changement de règle d’automatisation',
	'AUTOMATION.RULE_CREATE': 'Création de règle d’automatisation',
	'AUTOMATION.ACCEPT': 'Accepter',
	'AUTOMATION.MANUAL_RULE_DELAY': 'La mise en œuvre de cette règle présentera un certain retard.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': 'L’exécution de cette règle pendra un certain temps.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': 'Je suis au courant du retard affectant le processus de mise en œuvre.',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Sélectionner action :',
	// statuses
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'En attente de suppression',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Mis en œuvre',
	'AUTOMATION.RULE_STATUS_PENDING': 'En attente',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'En conflit',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Reçu',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'Sans objet',
	//  Modèle de zone
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'Zone standard ATT 1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'Zone standard ATT 2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'Zone standard ATT 3',
	//  Nom de zone
	'AUTOMATION.US_DOMESTIC': 'Domestique États-Unis',
	'AUTOMATION.EU': 'UE'
};
