import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AutomationZonesTableHeader = [
	{
		title: 'AUTOMATION_ZONES.ZONE_ID',
		name: 'zoneId'
	},
	{
		title: 'AUTOMATION_ZONES.NAME',
		name: 'name'
	},
	{
		title: 'AUTOMATION_ZONES.DESCRIPTION',
		name: 'description'
	}
];

export const automationZonesSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="AUTOMATION_ZONES.BY" defaultMessage="By" />
				<FormattedMessage id="AUTOMATION_ZONES.ZONE_ID" defaultMessage="Zone ID" />
			</div>
		),
		value: 'zoneId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="AUTOMATION_ZONES.BY" defaultMessage="By" />
				<FormattedMessage id="AUTOMATION_ZONES.NAME" defaultMessage="Name" />
			</div>
		),
		value: 'name'
	},
	{
		label: (
			<div>
				<FormattedMessage id="AUTOMATION_ZONES.BY" defaultMessage="By" />
				<FormattedMessage id="AUTOMATION_ZONES.DESCRIPTION" defaultMessage="Description" />
			</div>
		),
		value: 'description'
	},
];
