import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getUsers = (params) => {
	const query = {
		additionalParams: params.additionalParams,
		searchParams: params.searchParams
	};
	if (params.system) {
		return ApiService.get(`/users/system-users?${qs.stringify(query)}`);
	}
	return ApiService.get(`/users/company-user-overviews?${qs.stringify(query)}`);
};

export const getUser = ({ id, params }) => {
	const query = {
		additionalParams: params.additionalParams,
		searchParams: params.searchParams
	};
	if (params.system) {
		return ApiService.get(`/users/system-users/${id}?${qs.stringify(query)}`);
	}
	if (params.myProfile) {
		return ApiService.get('/users/me');
	}
	return ApiService.get(`/users/${id}?${qs.stringify(query)}`);
};

export const editUser = (params) => {
	const { id, data } = params;
	if (params.system) {
		return ApiService.patch(`/users/system-users/${id}`, data);
	}
	if (params.myProfile) {
		return ApiService.patch('/users/me', data);
	}
	return ApiService.patch(`/users/${id}`, data);
};

export const inviteUsers = (params) =>
	ApiService.post('/users/registrations/invite-users', params);

export const inviteSystemUsers = (params) =>
	ApiService.post('/users/system-registrations/invite-users', params);

export const pauseUser = (params) => {
	if (params.system) {
		return ApiService.patch(`/users/system-users/${params.data.id}/pause`, {
			justificationForSuspention: params.justificationForSuspention
		});
	}
	return ApiService.patch(`/users/${params.data.id}/pause`, {
		justificationForSuspention: params.justificationForSuspention
	});
};

export const reactivateUser = (params) => {
	if (params.system) {
		return ApiService.patch(
			`/users/system-users/${params.data.id}/reactivate`,
			{
				id: params.data.id
			}
		);
	}
	return ApiService.patch(`/users/${params.data.id}/reactivate`, {
		id: params.data.id
	});
};

export const resetUserPassword = (params) => {
	if (params.system) {
		return ApiService.patch(`/users/system-users/${params.id}/reset-password`, {
			id: params.id
		});
	}
	if (params.myProfile) {
		return ApiService.patch('/users/me/reset-password');
	}
	return ApiService.patch(`/users/${params.id}/reset-password`, {
		id: params.id
	});
};

export const removeUser = (params) => {
	if (params.system) {
		return ApiService.remove(`/users/system-users/${params.data.id}`, {
			data: { id: params.id }
		});
	}
	return ApiService.remove(`/users/${params.data.id}`, {
		data: { id: params.id }
	});
};

export const getFilters = async () => {
	const calls = [ApiService.get('/users/system-user-group-overviews')];

	const response = await Promise.all(calls);

	const data = {
		status: {
			resultList: [
				{
					id: true,
					name: 'Active'
				},
				{
					id: false,
					name: 'Paused'
				}
			],
			totalCount: 2
		},
		userGroups: response[0].data,
	};

	return {
		data
	};
};

export const getCompanyUserGroupsFilters = async () => {
	const calls = [ApiService.get('/users/user-group-overviews')];

	const response = await Promise.all(calls);

	const data = {
		status: {
			resultList: [
				{
					id: true,
					name: 'Active'
				},
				{
					id: false,
					name: 'Paused'
				}
			],
			totalCount: 2
		},
		userGroups: response[0].data,
	};

	return {
		data
	};
};

export const getAddressTypes = () => ApiService.get('/users/address-types');

export const deleteUserProfile = (params) =>
	ApiService.remove('/users/me', { data: { id: params.user.id } });

export const getCountries = (params) =>
	ApiService.get(`/users/countries?${qs.stringify(params)}`);

export const getStates = (params) =>
	ApiService.get(`/users/states?${qs.stringify(params)}`);

export const getLanguages = () =>
	ApiService.get('/users/languages');

export const exportUserUsageData = () =>
	ApiService.get('/users/usage-statistics/export');
