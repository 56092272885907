import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../BatchActions';

const Desktop = (props) => {
	const { getOptions, batchActions, messages } = props;
	return (
		<div data-spec="batch-actions-desktop">
			<Table
				data={batchActions.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { func, object } = PropTypes;

Desktop.propTypes = {
	getOptions: func,
	batchActions: object,
	messages: object
};

export default hoc()(Desktop);
