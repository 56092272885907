import { createAction } from 'redux-actions';

import { getLocale } from '../../../../utils/constants';
import {
	uploadFileRequestURL,
	fileUpload,
	pollForFile
} from '../../services/UploadFileService';
import {
	FILE_UPLOAD_REQUEST,
	FILE_UPLOAD_SUCCESS,
	FILE_UPLOAD_FAIL,
	CLEAR_FILE_UPLOAD,
	RESET_FILE_NUMBERS,
	RESET_FILES,
	RESET_ERRORS
} from '../constants';
import { hideNotification, showNotification } from '../notifications/actions';

const fileUploadRequest = createAction(FILE_UPLOAD_REQUEST);
const fileUploadSuccess = createAction(FILE_UPLOAD_SUCCESS);
const fileUploadFail = createAction(FILE_UPLOAD_FAIL);
export const clearUploadFiles = createAction(CLEAR_FILE_UPLOAD);
export const resetFileNumbers = createAction(RESET_FILE_NUMBERS);
export const resetFiles = createAction(RESET_FILES);
export const resetErrors = createAction(RESET_ERRORS);

export const fileRequest = (data) => async (dispatch) => {
	try {
		dispatch(fileUploadRequest());
		const request = await uploadFileRequestURL(data.request);
		await fileUpload(request.uploadURL, data.file);
		const { id } = request;
		const pool = await pollForFile({ id }, 200, 4000);
		dispatch(fileUploadSuccess(pool));
		dispatch(hideNotification('upload-file-error'));
	} catch (e) {
    const locale = getLocale();
		const {
			default: { FILES_FAILED_TO_UPLOAD_MESSAGE, ACTION_COULD_NOT_BE_COMPLETED }
		} = await import(`../../../../localizations/${locale}`);

		dispatch(hideNotification('upload-file-error'));
		dispatch(
			showNotification({
				id: 'upload-file-error',
				message: FILES_FAILED_TO_UPLOAD_MESSAGE
					? [FILES_FAILED_TO_UPLOAD_MESSAGE]
					: [ACTION_COULD_NOT_BE_COMPLETED],
				type: 'error',
				notificationType: 'toaster'
			})
		);
		dispatch(fileUploadFail());
	}
};
