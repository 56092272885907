import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const SingleEndpointActionModalContext = React.createContext();

export default class SingleEndpointActionModalContextProvider
	extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { children } = this.props;
		return (
			<SingleEndpointActionModalContext.Provider
				data-spec="single-endpoint-action-modal-context-provider"
				value={{
					state: this.state,
					updateContext: (newState) => this.setState(newState)
				}}
			>
				{children}
			</SingleEndpointActionModalContext.Provider>
		);
	}
}

SingleEndpointActionModalContextProvider.propTypes = {
	children: PropTypes.any
};

export function SingleEndpointActionModalContextConsumer(props) {
	const { children } = props;

	return (
		<SingleEndpointActionModalContext.Consumer
			data-spec="single-endpoint-action-modal-context-consumer"
		>
		{(context) =>
				React.Children.map(children, (child) =>
					React.cloneElement(child, { ...props, context }))}
		</SingleEndpointActionModalContext.Consumer>
	);
}

SingleEndpointActionModalContextConsumer.propTypes = {
	children: PropTypes.any
};
