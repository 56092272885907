import React from 'react';
import { bool, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import CurrentContext from '../../../../../utils/currentContext';
import { showValue } from '../../../../../utils/helperFunctions';
import ImageSelector from '../../../../../utils/imageSelector';
import {
	renderUnit,
	renderValue,
} from '../../../../Shared/components/Charts/Helpers/helpers';
import hoc from '../Module';

import styles from '../../DataOverview/DataOverview.scss';

const InfoFull = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const NetworkTypeRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networktype_rw',
	'mnc.endpoints.networktype_ro'
]);

function Desktop({
	endpointData,
	currentEndpoint,
	statusMessage,
	status,
	isPendingStatus,
	modules,
	rateplan,
}) {
	return (
		<div className={styles.data_wrap} data-spec="module-desktop">
			<div className={styles.column}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="DEVICE_NAME"
							defaultMessage="Device Name"
						/>
					</span>
					<span>{endpointData.name || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="DEVICE_MODULE_NUMBER"
							defaultMessage="Device Model Number"
						/>
					</span>
					<span>{endpointData.hardware.modelNumber || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="IMEI"
							defaultMessage="IMEI"
						/>
					</span>
					<span>{currentEndpoint.deviceIMEI || '-'}</span>
				</div>
				<NetworkTypeRWComponent>
					<div className={styles.data}>
						<span>
							<FormattedMessage
								id="NETWORK_TYPE"
								defaultMessage="Network Type"
							/>
						</span>
						<span>{currentEndpoint.networkType || '-'}</span>
					</div>
				</NetworkTypeRWComponent>
				<div className={styles.data}>
					{showValue(
						status,
						status && (
							<div className={styles.data}>
								<span className={styles.status_span}>
									{statusMessage}
									<div className={styles.pending}>
										{isPendingStatus && (
											<FormattedMessage
												id="STATUS_CHANGE_PENDING"
												defaultMessage="There is a Scheduled Activity for this Endpoint"
											>
												{(formattedValue) => (
													<InfoFull
														data-tip={formattedValue}
														data-for="statusTooltip"
													/>
												)}
											</FormattedMessage>
										)}
										{isPendingStatus && (
											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id="statusTooltip"
											/>
										)}
									</div>
								</span>
								<div
									className={cn(
										styles.status_col,
										styles[status && status
											.toLowerCase()
											.replace(' ', '_')
											.replace('-', '_')
											]
									)}
								>
									<div
										className={`${status
											.replace(/\s/g, '_')
											.toLowerCase()} custom-option`}
									>
										<FormattedMessage
											id="SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS"
											defaultMessage="{status}"
											values={{ status: status || '-' }}
										/>
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
			<div className={styles.column}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="TRESHOLD_OF_NOTIFICATION_BY_DATA_USAGE"
							defaultMessage="Threshold of Notification by Data Usage"
						/>
					</span>
					<span>
						{modules.notificationByteCap != null
							? modules.notificationByteCap
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="TRESHOLD_OF_SUSPENSION_BY_DATA_USAGE"
							defaultMessage="Threshold of Suspension by Data Usage"
						/>
					</span>
					<span>
						{modules.suspensionByteCap != null
							? modules.suspensionByteCap
							: '-'}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="NOTIFICATION_INTERVAL"
							defaultMessage="Notification Interval"
						/>
					</span>
					<span>
						{modules.notificationRefresh != null
							? modules.notificationRefresh
							: '-'}
					</span>
				</div>
			</div>
			<div className={styles.column}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="DATA_USAGE"
							defaultMessage="Cycle to Date Data Usage"
						/>
					</span>
					<span>
						{modules.byteUsage != null ? (
							renderValue('MB', modules.byteUsage)
						) : '-'}
						{modules.byteUsage != null ? (
							renderUnit('BYTE_ABBREVIATION', modules.byteUsage)
						) : ''}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="ICCID"
							defaultMessage="ICCID"
						/>
					</span>
					<span>{currentEndpoint.iccid || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="MSISDN"
							defaultMessage="MSISDN"
						/>
					</span>
					<span>{currentEndpoint.msisdn || '-'}</span>
				</div>
			</div>
			<div className={styles.column}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="IMSI"
							defaultMessage="IMSI"
						/>
					</span>
					<span>{currentEndpoint.imsi || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="RATE_PLAN_NAME"
							defaultMessage="Rate Plan Name"
						/>
					</span>
					<span>{rateplan.name || '-'}</span>
				</div>
			</div>
		</div>
	);
}

Desktop.propTypes = {
	endpointData: object,
	currentEndpoint: object,
	statusMessage: object,
	status: string,
	isPendingStatus: bool,
	modules: object,
	rateplan: object,
};

export default hoc(Desktop);
