import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';

import {
	getAnalyticsReports as getAnalyticsReportsService,
	getCustomerReports as getCustomerReportsService,
	getReportsFilters as getReportsFiltersService,
	sfgRepost as sfgRepostService,
} from '../../services/ReportsService';

import {
	GET_ANALYTICS_REPORTS,
	GET_CUSTOMER_REPORTS,
	GET_REPORTS_FILTERS,
	SFG_REPOST
} from '../constants';

import { showNotification } from '../../../Shared/redux/notifications/actions';

/* ******************** GET ANALYTICS REPORTS ******************** */
export const getAnalyticsReports = (params) =>
	globalAction(GET_ANALYTICS_REPORTS, getAnalyticsReportsService, params);

export const getAnalyticsReportsInit = () => actionInit(GET_ANALYTICS_REPORTS);

/* ******************** GET CUSTOMER REPORTS ******************** */
export const getCustomerReports = (params) =>
	globalAction(GET_CUSTOMER_REPORTS, getCustomerReportsService, params);

export const getCustomerReportsInit = () => actionInit(GET_CUSTOMER_REPORTS);

/* ******************** GET REPORTS FILTERS ******************** */
export const getReportsFilters = (params) =>
	globalAction(GET_REPORTS_FILTERS, getReportsFiltersService, params);

export const getReportsFiltersInit = () => actionInit(GET_REPORTS_FILTERS);

/* ******************** GET REPORTS FILTERS ******************** */
export const sfgRepost = (report) =>
	globalAction(SFG_REPOST, sfgRepostService, report);

export const sfgRepostInit = () => actionInit(SFG_REPOST);

export const sfgRepostNotification = (notification) => async (
	dispatch
) => {
	dispatch(
		showNotification({
			id: 'rate-plan-change-notification',
			message: [notification.message],
			type: notification.type,
			notificationType: 'toaster',
			close: true,
			duration: 5000
		})
	);
};
