import React from 'react';
import {
	objectOf,
	string,
	array,
	func,
} from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';

import hoc from '../Changes';

function Desktop({ data, options, messages }) {
	return (
		<Table
			data-spec="history-changes-table"
			data={data}
			options={options(false)}
			messages={messages}
		/>
	);
}

Desktop.propTypes = {
	data: array,
	options: func,
	messages: objectOf(string),
};

export default hoc(Desktop);
