import axios from 'axios';

import { post, get } from '../../../services/ApiService';

export const addFeedback = (postObj) =>
	post('/users/userFeedback', postObj);

export const batchFileRequestURL = (data) =>
	post('/users/userFeedback/file', data);

export const batchFileUpload = async (url, file) => {
	try {
		const options = {
			headers: {
				'Content-Type': 'application/octet-stream'
			}
		};
		const response = await axios.put(url, file, options);

		return response.data;
	} catch (error) {
		throw new Error(error.response.data);
	}
};

export const checkBatchUploadStatus = (data) =>
	get(`/users/userFeedback/file/${data.id}`);

export const pollForFile = (data, retries, delay) => {
	const tempRetries = retries - 1;

	if (tempRetries > 0) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				checkBatchUploadStatus(data)
					.then((response) => {
						if (
							response.data.data.status === 0 ||
							response.data.data.status === 1
						) {
							resolve(pollForFile(data, tempRetries, delay));
						} else if (response.data.data.status === 2) {
							resolve(response.data.data);
						} else {
							reject({ error: response.errors });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
	return new Promise((resolve, reject) => {
		reject({ error: [] });
	});
};
