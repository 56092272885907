import { connect } from 'react-redux';
import RuleDetailsParent from './RuleDetailsParent';
import { localizedRoute } from '../../../../utils/helperFunctions';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import { activateRule, getAutomationRuleInit } from '../../redux/actions';

import {
	getAutomationRuleSuccess,
	getAutomationRulePending,
	activateRuleRequest
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
  user: getUser(state),
	ruleRequest: getAutomationRulePending(state),
	rule: getAutomationRuleSuccess(state),
	activateRuleRequest: activateRuleRequest(state)
});
const mapDispatchToProps = (dispatch) => ({
  setRuleToInit: () => dispatch(getAutomationRuleInit()),
	pushBack: () => dispatch(localizedRoute('/automation')),
	activateRule: (id, activeStatus, notifOptions) =>
		dispatch(activateRule(id, activeStatus, notifOptions))
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleDetailsParent);
