export const GET_TICKETING_FILTERS = 'GET_TICKETING_FILTERS';
export const EXPORT_CSV_FAIL = 'EXPORT_CSV_FAIL';
export const EXPORT_CSV_REQUEST = 'EXPORT_CSV_REQUEST';
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export const TICKET_FILE_DOWNLOAD_REQUEST = 'TICKET_FILE_DOWNLOAD_REQUEST';
export const TICKET_FILE_DOWNLOAD_SUCCESS = 'TICKET_FILE_DOWNLOAD_SUCCESS';
export const TICKET_FILE_DOWNLOAD_FAIL = 'TICKET_FILE_DOWNLOAD_FAIL';
export const GET_ENDPOINT_DATA_REQUEST = 'GET_ENDPOINT_DATA_REQUEST';
export const GET_ENDPOINT_DATA_SUCCESS = 'GET_ENDPOINT_DATA_SUCCESS';
export const GET_ENDPOINT_DATA_FAIL = 'GET_ENDPOINT_DATA_FAIL';
export const SET_ENDPOINT_TICKETING_INIT = 'SET_ENDPOINT_TICKETING_INIT';
export const SET_ENDPOINT_TICKETING_RESET = 'SET_ENDPOINT_TICKETING_RESET';
