import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { isEqual, cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import {
	updateBulkRatePlan as updateBulkRatePlanAction,
	updateBulkRatePlanStatusStarted as updateBulkRatePlanStatusStartedAction
} from '../../../redux/updateBulkRatePlan/actions';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import { getErrors } from '../../../../Shared/redux/getStatusCode/selectors';
import {
	getCurrentSim,
	isBulkRatePlanUpdating,
	bulkRatePlanUpdateFail,
	bulkRatePlanUpdateSuccess,
	getFailSims,
	getSuccessSims,
	getIsStarted,
	getFilteredSims
} from '../../../redux/updateBulkRatePlan/selectors';
import { sameDateFormatter } from '../../../../../utils/constants';

import styles from './Steps.scss';

const Error = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const Success = ImageSelector(CurrentContext.theme, 'svgs/ok-full.svg');
const ErrorNotification = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-error.svg'
);
const Approval = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-approval.svg'
);

class StepThird extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSim: '',
			failSims: [],
			successSims: [],
			errors: null
		};
	}

	componentDidMount() {
		const {
			updateBulkRatePlanStatusStarted,
			updateBulkRatePlan,
			ratePlans,
			context,
			isStarted,
			onClose
		} = this.props;

    const ratePlansObject = cloneDeep(ratePlans);
		updateBulkRatePlanStatusStarted();
		if (sameDateFormatter(ratePlansObject.data.startDate)) {
      delete ratePlansObject.data.startDate;
    }
    updateBulkRatePlan(ratePlansObject);

		if (context) {
			this.setFooter(context, isStarted, onClose);
		}
	}

	static getDerivedStateFromProps(nextProps) {
		const objectState = {
			currentSim: nextProps.currentSim,
			failSims: nextProps.failSims || [],
			successSims: nextProps.successSims || []
		};

		if (nextProps.errors.length) {
			objectState.errors = nextProps.errors;
		}

		return objectState;
	}

	shouldComponentUpdate(nextProps) {
		return Object.keys(nextProps.ratePlans).length;
	}

	componentDidUpdate(prevProps) {
		const { context, isStarted, onClose } = this.props;

		if (context && !isEqual(isStarted, prevProps.isStarted)) {
			this.setFooter(context, isStarted, onClose);
		}
	}

	componentWillUnmount() {
		this.setState({
			currentSim: '',
			failSims: [],
			successSims: []
		});
	}

	setFooter = (context, isStarted, onClose) => {
		const footer = (
			<>
				<Button
					variant="success"
					onClick={onClose}
					disabled={isStarted}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
					dataSpec="done"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderNotification = (ratePlans, successSims) => {
		if (!ratePlans.data) {
			return;
		}
		if (successSims && successSims.length) {
			return (
				<div data-spec="notification" className={styles.notification}>
					<div className={styles.image}>
						<Approval />
					</div>
					{ratePlans.data.startDate !== format(new Date(), 'yyyy-MM-dd') ? (
						<div className={styles.schedule}>
							<div className={styles.text} data-spec="action-status-success">
								<FormattedMessage
									id="ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL"
									defaultMessage="Rate plan for multiple SIMs is successfully scheduled"
								/>
							</div>
							<div className={styles.schedule_text}>
								<FormattedMessage
									id="ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR"
									defaultMessage="SIM rate plan change for"
								/>
								&nbsp;
								{successSims.length}
								<FormattedMessage id="OF" defaultMessage="of" />
								{` ${ratePlans.selectedSims.length} `}
								<FormattedMessage
									id="ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR"
									defaultMessage="SIMS is successfully scheduled for"
								/>
								{` ${format(ratePlans.data.startDate, 'MM/dd/yyyy')}`}
							</div>
						</div>
					) : (
						<div className={styles.text} data-spec="action-status-success">
							{successSims.length}
							<FormattedMessage id="OF" defaultMessage="of" />
							{`${ratePlans.selectedSims.length} `}
							<FormattedMessage
								id="ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED"
								defaultMessage="SIM rate plans successfully updated"
							/>
						</div>
					)}
				</div>
			);
		}

		return (
			<div
				data-spec="notification"
				className={cn(styles.notification, styles.error)}
			>
				<div className={styles.image}>
					<ErrorNotification />
				</div>
				{ratePlans.data.startDate !== format(new Date(), 'yyyy-MM-dd') ? (
					<div className={styles.schedule}>
						<div className={styles.text} data-spec="action-status-error">
							<FormattedMessage
								id="ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR"
								defaultMessage="Multiple SIM rate plan change could not be scheduled successfully"
							/>
						</div>
						<div className={styles.schedule_text}>
							<FormattedMessage
								id="ENDPOINTS.PLEASE_TRY_AGAIN"
								defaultMessage="Please try again."
							/>
						</div>
					</div>
				) : (
					<div className={styles.schedule}>
						<div className={styles.text} data-spec="action-status-error">
							<FormattedMessage
								id="ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR"
								defaultMessage="Multiple SIM rate plan change could not be requested successfully"
							/>
						</div>
						<div className={styles.schedule_text}>
							<FormattedMessage
								id="ENDPOINTS.PLEASE_TRY_AGAIN"
								defaultMessage="Please try again."
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	renderSims = (
		ratePlans,
		currentSim,
		isUpdating,
		failSims,
		successSims,
		filteredSims
	) => (
		<ul data-spec="sims" className={styles.sims_result}>
			{ratePlans.selectedSims.map((item, index) =>
				this.renderSimData(
					item.endpointName,
					index,
					currentSim,
					isUpdating,
					failSims,
					successSims,
					filteredSims
				))}
		</ul>
	);

	getErrorMessage = () => {
		const { errors } = this.state;
		let errorMessage = '';

		if (errors) {
			errors.forEach((error) => { errorMessage += `${error.message} `; });
		}

		return errorMessage;
	};

	renderSimData = (
		sim,
		index,
		currentSim,
		isUpdating,
		failSims,
		successSims,
		filteredSims
	) => {
		if (sim === currentSim && isUpdating) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<div className={styles.loader}>
						<Loader size={15} data-spec="loader" />
					</div>
				</li>
			);
		}

		if (failSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<span
							className={styles.data}
							data-spec={`tooltip-${sim}`}
							data-tip={this.getErrorMessage()}
							data-for={sim}
						>
              <FormattedMessage id="ENDPOINTS.ERROR" defaultMessage="Error" />
						</span>
						<ReactTooltip type={'error'} id={sim} className={styles.tooltip} />
					</div>
				</li>
			);
		}

		if (successSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index} className={styles.success}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Success />
					</span>
					<span>
						<FormattedMessage
							id="ENDPOINTS.SUCCESS_KEY"
							defaultMessage="Success"
						/>
					</span>
				</li>
			);
		}

		if (filteredSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<FormattedMessage id="ENDPOINTS.ERROR" defaultMessage="Error" />
						<FormattedMessage
							id="ENDPOINTS.SAME_RATE_PLAN_SELECTED"
							defaultMessage="Same rate plan selected"
						>
							{(formattedValue) => (
								<span
									className={styles.data}
									data-spec={`tooltip-${sim}`}
									data-tip={
										formattedValue || 'Change rate plan is not allowed!'
									}
									data-for={sim}
								>
                  [
									<FormattedMessage
										id="ENDPOINTS.REASON"
										defaultMessage="reason"
									/>
                  ]
								</span>
							)}
						</FormattedMessage>
						<ReactTooltip type={'error'} id={sim} className={styles.tooltip} />
					</div>
				</li>
			);
		}

		return (
			<li data-spec="sim" key={index} className={styles.start}>
				<div className={styles.sim}>{sim}</div>
			</li>
		);
	};

	render() {
		const {
			ratePlans,
			isStarted,
			isUpdating,
			filteredSims
		} = this.props;
		const { successSims, currentSim, failSims } = this.state;

		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className="confirm-content">
						<div className={`${styles.step_first} ${styles.step_third}`}>
							<h2 className={styles.touppercase}>
								<FormattedMessage
									id="ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE"
									defaultMessage="Confirm rate plan change"
								/>
							</h2>
							<p>
								{ratePlans.selectedSims.length}
                &nbsp;
								<FormattedMessage
									id="ENDPOINTS.SIMS_SELECTED"
									defaultMessage="SIMs selected"
								/>
							</p>
						</div>
					</div>
					{!isStarted &&
						this.renderNotification(ratePlans, successSims)}
					{this.renderSims(
						ratePlans,
						currentSim,
						isUpdating,
						failSims,
						successSims,
						filteredSims
					)}
				</div>
			</div>
		);
	}
}

const { object, func, string, bool, array, any } = PropTypes;

StepThird.propTypes = {
	ratePlans: object,
	updateBulkRatePlan: func,
	currentSim: string,
	isUpdating: bool,
	failSims: array,
	successSims: array,
	onClose: func,
	updateBulkRatePlanStatusStarted: func,
	isStarted: bool,
	errors: any,
	filteredSims: array,
	context: object
};

const mapStateToProps = (state) => ({
		currentSim: getCurrentSim(state),
		isUpdating: isBulkRatePlanUpdating(state),
		isFail: bulkRatePlanUpdateFail(state),
		isSucces: bulkRatePlanUpdateSuccess(state),
		failSims: getFailSims(state),
		successSims: getSuccessSims(state),
		isStarted: getIsStarted(state),
		errors: getErrors(state),
		filteredSims: getFilteredSims(state)
	});

const mapDispatchToProps = (dispatch) => ({
	updateBulkRatePlan: (data) => dispatch(updateBulkRatePlanAction(data)),
	updateBulkRatePlanStatusStarted: () =>
		dispatch(updateBulkRatePlanStatusStartedAction())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepThird);
