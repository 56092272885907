import { connect } from 'react-redux';

import { getHistoryChanges } from '../../redux/actions';
import {
	getHistoryChange,
	getTotalCount,
	isHistoryChangeFailed,
	isHistoryChangeRequest,
} from '../../redux/selectors';
import Desktop from './Desktop';
import Mobile from './Mobile';

const mapStateToProps = (state) => (
	{
		historyData: getHistoryChange(state),
		isFetching: isHistoryChangeRequest(state),
		totalCount: getTotalCount(state),
		isChangesRequestFailed: isHistoryChangeFailed(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getHistoryData: (params) => dispatch(getHistoryChanges(params)),
	}
);

export const ChangesDesktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(Desktop);
export const ChangesMobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(Mobile);
