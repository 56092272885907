import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import Radio from '../../../../../lib/DigitalComponents/Radio';
import Checkbox from '../../../../../lib/DigitalComponents/Checkbox';

const StepFourth = ({ onNext, shouldRefresh, renderMapChoice }) => {
	const chartTypeList = [
		{ value: 'line', label: 'LINE_GRAPH' },
		{ value: 'bar', label: 'BAR_GRAPH' },
		{ value: 'pie', label: 'PIE_CHART' },
		{ value: 'map', label: 'WORLD_MAP_GRAPH' }
	];

	return (
		<div data-spec="analytics-step-fourth">
			{chartTypeList
				.filter((type) => (!renderMapChoice ? type.value !== 'map' : type))
				.map((item) => (
					<div data-spec="radio-button" key={item.value}>
						<FormattedMessage
							id={`ANALYTICS.${item.label}`}
							defaultMessage={`${item.label}`}
						>
							{(formattedValue) => (
								<Field
									component={Radio}
									name="chartForm"
									label={formattedValue}
									value={`${item.value}`}
									type="radio"
									onChange={() => {
										onNext();
										shouldRefresh();
									}}
								/>
							)}
						</FormattedMessage>
					</div>
				))}
				<div data-spec="check-box">
					<FormattedMessage
						id="ANALYTICS.SHOW_SUMMARY"
						defaultMessage="Show summary totals"
					>
						{(formattedValue) => (
							<Field
								component={Checkbox}
								name="showSummary"
								label={formattedValue}
								onChange={() => {
									onNext();
									shouldRefresh();
								}}
								type="checkbox"
							/>
						)}
					</FormattedMessage>
				</div>
		</div>
	);
};

const { func, bool } = PropTypes;

StepFourth.propTypes = {
	onNext: func,
	shouldRefresh: func,
	renderMapChoice: bool
};

StepFourth.defaultProps = {
	renderMapChoice: false,
	onNext: undefined,
	shouldRefresh: undefined
};

export default StepFourth;
