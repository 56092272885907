import { createAction } from 'redux-actions';

import globalAction, { actionInit } from '../../../redux/global/globalAction';
import {
	deleteDefinedSetting as serviceDeleteDefinedSetting,
	getDefinedSettings as serviceGetDefinedSettings,
	getDefinedSettingById as serviceGetDefinedSetting,
	updateDefinedSetting as serviceUpdateDefinedSetting
} from '../services/AccountOverviewService';
import {
	DELETE_ACCOUNT_OVERVIEW_DEFINED_SETTING,
	GET_ACCOUNT_OVERVIEW_DEFINED_SETTINGS,
	GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID,
	UPDATE_ACCOUNT_OVERVIEW_DEFINED_SETTING
} from './constants';

export const deleteDefinedSetting = (settingId) =>
	globalAction(
		DELETE_ACCOUNT_OVERVIEW_DEFINED_SETTING,
		serviceDeleteDefinedSetting,
		settingId,
		{
			notify: 'delete-defined-chart-settings',
			notifyMessageSuccess: 'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS',
			notifyMessageError: 'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL',
		}
	);

export const getDefinedSettings = () =>
	globalAction(
		GET_ACCOUNT_OVERVIEW_DEFINED_SETTINGS,
		serviceGetDefinedSettings,
		true
	);

const request = createAction(
	`${GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID}_REQUEST`
);
const success = createAction(
	`${GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID}_SUCCESS`
);
const fail = createAction(
	`${GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID}_ERROR`
);
export const getDefinedSettingById = (settingId) => async (dispatch) => {
	dispatch(request());
	return serviceGetDefinedSetting(settingId)
		.then((response) => dispatch(success(response)))
		.catch((error) => dispatch(fail(error)));
};

export const getDefinedSettingByIdInit = () =>
	actionInit(GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID);

export const updateDefinedSetting = (settingObject, settingId = null) =>
	globalAction(
		UPDATE_ACCOUNT_OVERVIEW_DEFINED_SETTING,
		serviceUpdateDefinedSetting,
		{ settingObject, settingId },
		{
			notify: 'update-defined-chart-settings',
			notifyMessageSuccess: settingId
				? 'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS'
				: 'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS',
			notifyMessageError: settingId
				? 'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL'
				: 'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL',
		},
	);
