import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getPlatforms = (params) =>
	ApiService.get(`/onboarding/platform-types?${qs.stringify(params)}`);

export const getAccounts = (params) => {
	const query = {
		additionalParams: params.additionalParams,
		searchParams: params.searchParams
	};
	if (params.isMyCompany) {
		return ApiService.get(
			`/onboarding/platforms/me/overview?${qs.stringify(query)}`
		);
	}
	return ApiService.get(
		`/onboarding/platforms/overview?${qs.stringify(params)}`
	);
};

export const getAccount = (params) => {
	const { id, additionalParams } = params;
	return ApiService.get(
		`/onboarding/platforms/${id}?${qs.stringify({
			additionalParams
		})}`
	);
};

export const editAccount = (params) => {
	const { id, data } = params;
	return ApiService.patch(`/onboarding/platforms/${id}`, data);
};

export const addAccount = (data) =>
	ApiService.post('/onboarding/platforms', data);

export const addSubAccount = (params) => {
	const query = {
		platformTypeId: params.platformTypeId,
		parentPlatformId: params.parentPlatformId,
		m2mAccountName: params.m2mAccountName
	};
	if (params.isMyCompany) {
		return ApiService.post('/onboarding/platforms/me', query);
	}
	return ApiService.post('/onboarding/platforms', params);
};

export const getParentAccount = (params) =>
	ApiService.get(
		`/onboarding/wing/account-information?${qs.stringify(params)}`
	);

export const editSubAccount = (params) => {
	const { id, data } = params;
	return ApiService.patch(`/onboarding/platforms/${id}`, data);
};

export const activateAccount = (params) =>
	ApiService.post('/users/registrations/accept-invite', params);
export const requestAccess = (params) =>
	ApiService.post('/users/registrations/request-access', params);
export const grantAccess = (params) =>
	ApiService.patch('/users/registrations/grant-access', params);

export const getPlatformTypes = () =>
	ApiService.get('/onboarding/platform-type-categories');

export const validateAccount = (params) => {
	const { id, m2mAccountId } = params;
	return ApiService.get(
		`/onboarding/platform-types/${id}/platforms/${m2mAccountId}/validate`
	);
};
