export default {
	'SWAGGER.DEVELOPER_API': 'Développeur API',
	'SWAGGER.GETTING_STARTED': 'Pour démarrer',
	'SWAGGER.API_SANDBOX': 'Sandbox API',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': 'Le sandbox Swagger ci-dessous fournit la liste des ressources disponibles dans l’API REST (transfert d’état représentatif) et les opérations qui peuvent être exécutées avec ces ressources. Le document fournit aussi la liste des paramètres pour une opération, y compris leur nom et leur type, qu’ils soient requis ou optionnels, ainsi que les renseignements sur leurs valeurs acceptables.',
	'SWAGGER.JSON_SCHEMA_INCLUDES': 'JSON Schema est inclus. Il décrit la structure du corps de la demande qui est envoyée à une opération dans une API REST. JSON Schema décrit aussi la structure de tout corps de réponse renvoyé par une opération.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Pour démarrer avec API',
	'SWAGGER.GETTING_STARTED_WITH_API_P': 'Avant d’effectuer votre premier appel API, votre adresse IP source (serveur) doit figurer sur la liste blanche. L’adresse IP source est généralement configurée pendant le processus de provisionnement, lorsque l’accès API est créé. Si vous recevez des messages d’erreurs, il sont certainement liés à un blocage de l’accès à la passerelle AT&T IoT.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': 'Veuillez vous assurer que vous avez exécuté les étapes suivantes pour démarrer le développement :',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '– Vous avez établi un serveur accessible par le public et vous nous avez fourni son adresse IP au cours de l’intégration.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '– Votre adresse IP source a été inscrite sur la liste blanche aux fins d’accès à la passerelle AT&T IoT.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '– L’administrateur de votre société vous a fourni vos nom d’utilisateur et mot de passe API qui ont été créés lorsque le service API a été activé.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '– Vous avez accès à votre serveur de ressources pour exécuter des commandes cURL ou utiliser des apps de connexion écrites dans votre langage préféré ci-dessous.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'Obtenir le jeton d’accès OAuth',
	'SWAGGER.GET_OAUTH_TOKEN_P': 'Obtenez le jeton OAuth d’AT&T IoT Identity en utilisant le nom d’utilisateur et le mot de passe fournis par votre administrateur selon votre méthode préférée ci-dessous. Le jeton est valide pendant 7 jours.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'Connecteurs API',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': 'Choisissez parmi les exemples ci-dessous la façon dont vous souhaitez vous connecter à notre API.',
	'SWAGGER.TEST_CONNECTION_T': 'Testez la connexion à la passerelle IoT',
	'SWAGGER.TEST_CONNECTION_P': 'Exemple de cURL :',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Résultat souhaité',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': 'Si vous obtenez le code de réponse HTTP 200 OK, vous êtes prêt à commencer le développement.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': 'Dépannage des erreurs de connexion',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': "Si vous obtenez des erreurs, vérifiez d’abord si votre authentification est correctement définie dans l’en-tête HTTP et si le jeton d’accès n’a pas expiré et doit être régénéré. Sondez aussi par PING la passerelle AT&T IoT pour vérifier si votre adresse IP source figure sur la liste blanche. Si vous obtenez des résultats inattendus, vous pouvez remplir un billet de dérangement dans la section de l’assistance.'"
};
