import { createAction } from 'redux-actions';

import * as UserService from '../../../services/UserService';
import { getActiveNotifications } from '../../../components/Support/redux/notifications/actions';

import {
	// NOTE: fetchUserRequest sets user.data to null therefore causing
	// /endpoint route to throw forbidden access
	// FETCH_USER_REQUEST,
	FETCH_USER,
	SET_COMPANY_SERVICE,
	SET_COMPANY_SERVICE_INIT,
	SET_COMPANY_FRIENDLY_NAME,
	SET_COMPANY_FRIENDLY_NAME_INIT
} from '../constants';

// NOTE: fetchUserRequest sets user.data to null therefore
// causing /endpoint route to throw forbidden access
// const fetchUserRequest = createAction(FETCH_USER_REQUEST)
const fetchUserSuccess = createAction(`${FETCH_USER}_SUCCESS`);
const fetchUserFail = createAction(`${FETCH_USER}_FAIL`);

export const setCompanyService = createAction(SET_COMPANY_SERVICE);
export const setCompanyServiceInit = createAction(SET_COMPANY_SERVICE_INIT);

export const setCompanyFriendlyName = createAction(SET_COMPANY_FRIENDLY_NAME);
export const setCompanyFriendlyNameInit = createAction(
	SET_COMPANY_FRIENDLY_NAME_INIT
);

export const requestUserData = () => async (dispatch, getState) => {
	// NOTE: fetchUserRequest sets user.data to null
	// therefore causing /endpoint route to throw forbidden access
	// dispatch(fetchUserRequest())
	// ToDO: Cleanup state logic once API in place!!!
	const { accountId } = getState().user;

	if (!accountId) {
		const sessionCompany = sessionStorage.getItem('company');
		if (sessionCompany && sessionCompany.length > 0) {
			dispatch(setCompanyService(sessionCompany));
		}
	}

	UserService.get().then(
		(data) => {
			if (
				data &&
				data.user &&
				data.user.company &&
				data.user.company.accountId
			) {
				if (
					!(
						data.user.systemUser && !sessionStorage.getItem('company')
					)
				) { dispatch(setCompanyService(data.user.company.accountId)); }
			}
			dispatch(fetchUserSuccess({ data }));
			dispatch(getActiveNotifications());
		},
		(data) => {
			dispatch(fetchUserFail({ ...data.user }));
		}
	);
};
