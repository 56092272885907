export default {
	'SHARED.NO_DATA_AVAILABLE': 'Keine Daten verfügbar',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE': 'Es sind derzeit keine Daten zur Anzeige verfügbar',
	'SHARED.EXIT_THIS_COMPANY': 'Beenden',
	// AKTIONSLEISTE AUSGEWÄHLTE FILTER TOOLTIP
	// ** ENDPUNKTE **
	'ACTION_BAR.ENDPOINTSTATUS': 'Status',
	'ACTION_BAR.NETWORKOPERATOR': 'Netzbetreiber',
	'ACTION_BAR.NETWORKCOUNTRY': 'Netzland',
	'ACTION_BAR.NETWORKENTITLEMENT': 'Netzwerkanspruchs-ID',
	'ACTION_BAR.NETWORKTYPE': 'Netzwerktyp',
	'ACTION_BAR.TRANSFERSTATUS': 'IMSI-Übertragungsstatus',
	'ACTION_BAR.RATEPLAN': 'Tarifplan',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'Status erneute Zulassung',
	'ACTION_BAR.M2MPLATFORM': 'M2M-Plattform',
	'ACTION_BAR.ROAMINGCOUNTRY': 'Roaming-Land',
	'ACTION_BAR.ROAMINGCARRIER': 'Roaming-Betreiber',
	'ACTION_BAR.SERVICESTATUS': '360L-Status',
	'ACTION_BAR.OEM': 'Unterkonten',
	'ACTION_BAR.M2MDATEADDED': 'M2M-Datum hinzugefügt',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'M2M-Aktivierungsdatum',
	//  ** RECHNUNGEN **
	'ACTION_BAR.M2MACCOUNTID': 'M2M-Konto-ID',
	'ACTION_BAR.CUSTOMERNAME': 'Kundenname',
	'ACTION_BAR.INVOICENUMBER': 'Rechnungsnummer',
	'ACTION_BAR.COUNTRYNAME': 'Ländername',
	'ACTION_BAR.BILLINGCYCLE': 'Abrechnungszyklus',
	'ACTION_BAR.CUSTOMERID': 'Kunden-ID',
	'ACTION_BAR.INVOICEDATE': 'Rechnungsdatum',
	//  ** BERICHTE **
	'ACTION_BAR.M2M_ACC_ID': 'M2M-Konto-ID',
	'ACTION_BAR.M2M_PLATFORM': 'M2M-Plattform',
	'ACTION_BAR.PLATFORM': 'Plattform',
	'ACTION_BAR.FREQUENCY': 'Frequenz',
	'ACTION_BAR.REPORT_NAME': 'Berichtsname',
	'ACTION_BAR.SERVICEPROVIDER': 'Plattform',
	'ACTION_BAR.REPORTFREQUENCY': 'Frequenz',
	//  **  SIM-BESTELLUNG **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': 'Status',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'M2M-Plattform',
	'ACTION_BAR.FILTER_CATEGORY_SKU': 'SKU',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Beschleunigt',
	//  **  SICHERHEITSFUNKTIONEN **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'M2M-Konto',
	//  **  TICKETING **
	'ACTION_BAR.CATEGORIES': 'Kategorien',
	'ACTION_BAR.STATUS': 'Status',
	'ACTION_BAR.SEVERITY': 'Schweregrad',
	//  AKTIONSLEISTE
	'ACTION_BAR.CLEAR_FILTERS': 'Filter löschen',
	'ACTION_BAR.SELECT_FILTERS': 'Filter auswählen',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Tabellenparameter bearbeiten',
	'ACTION_BAR.SHOWING': 'Anzeige ',
	'ACTION_BAR.OF': 'von',
	'ACTION_BAR.DOWNLOAD_FILE': 'Datei herunterladen',
	'ACTION_BAR.REFRESH_PAGE': 'Seite aktualisieren',
	//  ******************* FILTER MODAL *****************************
	//  ** ENDPUNKTE **
	'FILTER_MODAL.ENDPOINTSTATUS': 'Endpunktstatus',
	'FILTER_MODAL.NETWORKOPERATOR': 'Netzbetreiber',
	'FILTER_MODAL.NETWORKCOUNTRY': 'Netzland',
	'FILTER_MODAL.NETWORKENTITLEMENT': 'Netzwerkanspruchs-ID',
	'FILTER_MODAL.NETWORKTYPE': 'Netzwerktyp',
	'FILTER_MODAL.TRANSFERSTATUS': 'IMSI-Übertragungsstatus',
	'FILTER_MODAL.RATEPLAN': 'Tarifplan',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'Status erneute Zulassung',
	'FILTER_MODAL.M2MPLATFORM': 'M2M-Plattform',
	'FILTER_MODAL.ROAMINGCOUNTRY': 'Roaming-Land',
	'FILTER_MODAL.ROAMINGCARRIER': 'Roaming-Betreiber',
	'FILTER_MODAL.SERVICESTATUS': '360L-Status',
	'FILTER_MODAL.OEM': 'Unterkonten',
	'FILTER_MODAL.M2MDATEADDED': 'M2M-Datum hinzugefügt',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'M2M-Aktivierungsdatum',
	//  Register
	'FILTER_MODAL.TABS_ENDPOINTSTATE': 'Endpunktstatus',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Netzbetreiber',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'Netzland',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Netzwerkanspruch',
	'FILTER_MODAL.TABS_NETWORKTYPE': 'Netzwerktyp',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': 'Übertragungsstatus',
	'FILTER_MODAL.TABS_RATEPLAN': 'Tarifplan',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': 'Erneute Zulassung Transferstatus',
	'FILTER_MODAL.TABS_M2MPLATFORM': 'M2M-Plattform',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'Roaming-Land',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Roaming-Betreiber',
	'FILTER_MODAL.TABS_SERVICESTATUS': '360L-Status',
	'FILTER_MODAL.TABS_OEM': 'Unterkonten',
	'FILTER_MODAL.TABS_M2MDATEADDED': 'M2M-Datum hinzugefügt',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'M2M-Aktivierungsdatum',
	//  ** RECHNUNGEN **
	'FILTER_MODAL.M2MACCOUNTID': 'M2M-KONTO-ID',
	'FILTER_MODAL.CUSTOMERNAME': 'KUNDENNAME',
	'FILTER_MODAL.INVOICENUMBER': 'RECHNUNGSNUMMER',
	'FILTER_MODAL.INVOICEDATE': 'RECHNUNGSDATUM',
	'FILTER_MODAL.COUNTRYNAME': 'LÄNDERNAME',
	'FILTER_MODAL.CUSTOMERID': 'KUNDEN-ID',
	'FILTER_MODAL.BILLINGCYCLE': 'ABRECHNUNGSZYKLUS',
	//  Register
	'FILTER_MODAL.TABS_M2MACCOUNTID': 'M2M-Konto-ID',
	'FILTER_MODAL.TABS_CUSTOMERNAME': 'Kundenname',
	'FILTER_MODAL.TABS_INVOICENUMBER': 'Rechnungsnummer',
	'FILTER_MODAL.TABS_COUNTRYNAME': 'Ländername',
	'FILTER_MODAL.TABS_CUSTOMERID': 'Kunden-ID',
	'FILTER_MODAL.TABS_BILLINGCYCLE': 'Abrechnungszyklus',
	'FILTER_MODAL.TABS_INVOICEDATE': 'Rechnungsdatum',

	//  ** BERICHTE **
	'FILTER_MODAL.M2M_ACC_ID': 'M2M-KONTO-ID',
	'FILTER_MODAL.M2M_PLATFORM': 'M2M-PLATTFORM',
	'FILTER_MODAL.FREQUENCY': 'FREQUENZ',
	'FILTER_MODAL.REPORT_NAME': 'BERICHTSNAME',
	'FILTER_MODAL.PLATFORM': 'PLATTFORM',
	'FILTER_MODAL.SERVICEPROVIDER': 'PLATTFORM',
	'FILTER_MODAL.REPORTFREQUENCY': 'FREQUENZ',
	//  Register
	'FILTER_MODAL.TABS_M2M_ACC_ID': 'M2M-Konto-ID',
	'FILTER_MODAL.TABS_M2M_PLATFORM': 'M2M-Plattform',
	'FILTER_MODAL.TABS_FREQUENCY': 'Frequenz',
	'FILTER_MODAL.TABS_REPORT_NAME': 'Berichtsname',
	'FILTER_MODAL.TABS_PLATFORM': 'Plattform',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Plattform',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Frequenz',
	//  **  SIM-BESTELLUNG **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'STATUS',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'M2M-PLATTFORM',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'BESCHLEUNIGT',
	//  Register
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'Status',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'M2M-Plattform',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Beschleunigt',
	//  **  SICHERHEITSFUNKTIONEN **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'M2M-KONTO',
	//  Register
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'M2M-Konto',
	//  **  TICKETING **
	'FILTER_MODAL.CATEGORIES': 'KATEGORIEN',
	'FILTER_MODAL.STATUS': 'STATUS',
	'FILTER_MODAL.SEVERITY': 'SCHWEREGRAD',
	//  Register
	'FILTER_MODAL.TABS_CATEGORIES': 'Kategorien',
	'FILTER_MODAL.TABS_STATUS': 'Status',
	'FILTER_MODAL.TABS_SEVERITY': 'Schweregrad',
	//  **  RECHNUNGSDETAILS **
	'FILTER_MODAL.CURRENCY': 'WÄHRUNG',
	'FILTER_MODAL.CUSTOMER': 'KUNDE',
	'FILTER_MODAL.SUBSCRIBER_STATUS': 'ABONNENTENSTATUS',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': 'MONATSTARIFPLAN',
	'FILTER_MODAL.STANDARD_RATE_PLAN': 'STANDARD-TARIFPLAN',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'PREPAID-BEDINGUNGEN BERECHNET',
	'FILTER_MODAL.RATE_PLAN': 'TARIFPLAN',
	'FILTER_MODAL.PLAN_TYPE': 'PLANTYP',
	'FILTER_MODAL.ZONE': 'ZONE',
	'FILTER_MODAL.PRIORITY': 'PRIORITÄT',
	'FILTER_MODAL.ROAMING_ZONE': 'ROAMING-ZONE',
	'FILTER_MODAL.GROUP_NAME': 'GRUPPENNAME',
	'FILTER_MODAL.INVOICE_ID': 'RECHNUNGS-ID',
	'FILTER_MODAL.THE_PLAN_TYPE': 'PLANTYP',
	'FILTER_MODAL.ACTIVATION_TYPE': 'AKTIVIERUNGSTYP',
	'FILTER_MODAL.CHARGE_TYPE': 'KOSTENTYP',
	'FILTER_MODAL.PRIMARY_PLACE': 'PRIMÄRER ORT',
	'FILTER_MODAL.TECH_TYPE': 'TECH-TYP',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'PRIMÄRER VERWENDUNGSORT',
	'FILTER_MODAL.STATE': 'STATUS',
	'FILTER_MODAL.CITY': 'ORT',
	'FILTER_MODAL.DISTRICT_COUNTY': 'DISTRIKT LANDKREIS',
	'FILTER_MODAL.DISTRICT_CITY': 'DISTRIKT ORT',
	'FILTER_MODAL.DESCRIPTION': 'BESCHREIBUNG',
	//  Register
	'FILTER_MODAL.TABS_CURRENCY': 'Währung',
	'FILTER_MODAL.TABS_CUSTOMER': 'Kunde',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'Abonnentenstatus',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'Monatstarifplan',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'Standard-Tarifplan',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'Prepaid-Bedingungen berechnet',
	'FILTER_MODAL.TABS_RATE_PLAN': 'Tarifplan',
	'FILTER_MODAL.TABS_PLAN_TYPE': 'Plantyp',
	'FILTER_MODAL.TABS_ZONE': 'Zone',
	'FILTER_MODAL.TABS_PRIORITY': 'Priorität',
	'FILTER_MODAL.TABS_ROAMING_ZONE': 'Roaming-Zone',
	'FILTER_MODAL.TABS_GROUP_NAME': 'Gruppenname',
	'FILTER_MODAL.TABS_INVOICE_ID': 'Rechnungs-ID',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'Plantyp',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'Aktivierungstyp',
	'FILTER_MODAL.TABS_CHARGE_TYPE': 'Kostentyp',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': 'Primärer Ort',
	'FILTER_MODAL.TABS_TECH_TYPE': 'Tech-Typ',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'Primärer Verwendungsort',
	'FILTER_MODAL.TABS_STATE': 'Status',
	'FILTER_MODAL.TABS_CITY': 'Ort',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'Distrikt Landkreis',
	'FILTER_MODAL.TABS_DISTRICT_CITY': 'Distrikt Ort',
  'FILTER_MODAL.TABS_DESCRIPTION': 'Beschreibung',
  //  **    AUTOMATISIERUNG    **  //
  'FILTER_MODAL.PLATFORMS': 'PLATTFORMEN',
  'FILTER_MODAL.TRIGGERS': 'AUSLÖSER',
  'FILTER_MODAL.M2MACCOUNTS': 'M2M-KONTEN',
  'FILTER_MODAL.ACTIVE': 'AKTIVIERT',
  'FILTER_MODAL.STATUSES': 'STATUS',
  //  Register
  'FILTER_MODAL.TABS_PLATFORMS': 'Plattformen',
  'FILTER_MODAL.TABS_TRIGGERS': 'Auslöser',
  'FILTER_MODAL.TABS_M2MACCOUNTS': 'M2M-Konto',
	'FILTER_MODAL.TABS_ACTIVE': 'Aktiviert',
	'FILTER_MODAL.TABS_USERGROUPS': 'Benutzergruppen',
  'FILTER_MODAL.USERGROUPS': 'Benutzergruppen',
  'FILTER_MODAL.TABS_STATUSES': 'Status',
  // Aktionsleiste
  'ACTION_BAR.PLATFORMS': 'Plattformen',
  'ACTION_BAR.TRIGGERS': 'Auslöser',
  'ACTION_BAR.M2MACCOUNTS': 'M2M-Konten',
  'ACTION_BAR.CURRENCY': 'Währung',
  'ACTION_BAR.ACTIVE': 'Aktiviert',
	'ACTION_BAR.CUSTOMER': 'Kunde',
	'ACTION_BAR.SUBSCRIBER_STATUS': 'Abonnentenstatus',
	'ACTION_BAR.MONTHLY_RATE_PLAN': 'Monatstarifplan',
	'ACTION_BAR.STANDARD_RATE_PLAN': 'Standard-Tarifplan',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': 'Prepaid-Bedingungen berechnet',
	'ACTION_BAR.RATE_PLAN': 'Tarifplan',
	'ACTION_BAR.PLAN_TYPE': 'Plantyp',
	'ACTION_BAR.ZONE': 'Zone',
	'ACTION_BAR.PRIORITY': 'Priorität',
	'ACTION_BAR.ROAMING_ZONE': 'Roaming-Zone',
	'ACTION_BAR.GROUP_NAME': 'Gruppenname',
	'ACTION_BAR.INVOICE_ID': 'Rechnungs-ID',
	'ACTION_BAR.THE_PLAN_TYPE': 'Plantyp',
	'ACTION_BAR.ACTIVATION_TYPE': 'Aktivierungstyp',
	'ACTION_BAR.CHARGE_TYPE': 'Kostentyp',
	'ACTION_BAR.PRIMARY_PLACE': 'Primärer Ort',
	'ACTION_BAR.TECH_TYPE': 'Tech-Typ',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'Primärer Verwendungsort',
	'ACTION_BAR.STATE': 'Status',
	'ACTION_BAR.CITY': 'Ort',
	'ACTION_BAR.DISTRICT_COUNTY': 'Distrikt Landkreis',
	'ACTION_BAR.DISTRICT_CITY': 'Distrikt Ort',
	'ACTION_BAR.DESCRIPTION': 'Beschreibung',
  'ACTION_BAR.USERGROUPS': 'Benutzergruppen',
  'ACTION_BAR.STATUSES': 'Status',
  // KOMPONENTE HOCHLADEN
	'UPLOAD_FILE.MAX_FILE_SIZE': 'Datei muss kleiner als {size}MB sein.',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Falscher Dateityp',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': 'Für Uploads gelten nur die folgenden Formate:',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'Datei zu groß',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': 'Datei ist zu groß. Dateien müssen kleiner sein als',
  'UPLOAD_FILE.ALLOWED_FORMATS': 'Erlaubte Formate:',
	'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'Nur eine Datei erlaubt',
};
