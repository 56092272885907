import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const RectangleButton = (props) => {
	const {
		dataSpec,
		disabled,
		title,
		subtitle,
		label,
		labelIcon,
		className,
		onClick,
		style,
		customStyle
	} = props;

	return (
		<div
			data-spec="rectangle-button-wrapper"
			className={styles.rectangle}
			style={customStyle}
		>
			<div className={styles.text_container}>
				<h3 className={(disabled && styles.title_disabled) || styles.title}>
					{title}
				</h3>
				<div
					className={(disabled && styles.subtitle_disabled) || styles.subtitle}
				>
					{subtitle}
				</div>
			</div>
			<button
				className={
					className ||
					(disabled && styles.disabled_button) ||
					styles.enabled_button
				}
				data-spec={dataSpec}
				disabled={disabled}
				onClick={onClick}
				style={style}
				type="button"
			>
				<span>
					{labelIcon && (
						<span className="icon__wrapper">{labelIcon && labelIcon}</span>
					)}
					<span>{label && label}</span>
				</span>
			</button>
		</div>
	);
};

const { bool, func, node, string, shape } = PropTypes;

RectangleButton.propTypes = {
	label: shape(),
	labelIcon: node,
	disabled: bool,
	title: shape(),
	className: string,
	style: shape(),
	subtitle: shape(),
	onClick: func,
	dataSpec: string,
	customStyle: shape()
};

export default RectangleButton;
