export default {
	'USER_FEEDBACK.TRIGGER_TITLE': '有反馈意见吗？',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': '请在此处给我们留言',
	'USER_FEEDBACK.ADDITIONAL_FILE': '更多文件',
	'USER_FEEDBACK.PAGE_LABEL': '您的反馈是关于',
	'USER_FEEDBACK.FEED_LABEL': '请用您自己的话告诉我们应当如何改善？',
	'USER_FEEDBACK.FEED_PLACEHOLDER': '在此处填写您的反馈……',
	'USER_FEEDBACK.CONTACT_LABEL': '我们可以在需要时就此事与您联系吗？',
	'USER_FEEDBACK.EMAIL_LABEL': '电子邮件',
	'USER_FEEDBACK.PHONE_LABEL': '电话',
	'USER_FEEDBACK.SEND_FEEDBACK': '发送反馈',
	'USER_FEEDBACK.ICON': '成功提交反馈图标',
	'USER_FEEDBACK.SUCCESS_THANKS': '感谢您的反馈。',
	'USER_FEEDBACK.SUCCESS_MESSAGE': '您的反馈会让我们的产品更加完善。我们会认真评估您的意见，并采取相应行动。',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': '我们也可能会与您联系，详细探讨您的建议。',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'IoT Console团队',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': '完成',
	'USER_FEEDBACK.TITLE': '用户反馈',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': '上载失败'
};
