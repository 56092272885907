import React from 'react';
import PropTypes from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import UserFeedbackWrapper from '../../components/UserFeedbackWrapper';

const UserFeedbackWrapperView = ({ location, menu }) => (
	<>
		<DesktopMediaQuery>
			<UserFeedbackWrapper location={location} menu={menu} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<UserFeedbackWrapper location={location} menu={menu} />
		</TabletMediaQuery>
		<MobileMediaQuery>{null}</MobileMediaQuery>
	</>
);

const { objectOf, string, arrayOf, object } = PropTypes;

UserFeedbackWrapperView.propTypes = {
	location: objectOf(string),
	menu: arrayOf(object)
};

UserFeedbackWrapperView.defaultProps = {
	location: {},
	menu: []
};

export default UserFeedbackWrapperView;
