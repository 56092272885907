import { combineReducers } from 'redux';

import globalReducer from '../../../redux/global/globalReducer';

import { SET_FEEDBACK, FEEDBACK_FILE_UPLOAD } from './constants';

export default combineReducers({
	setFeedback: globalReducer(SET_FEEDBACK),
	feedbackFileUpload: globalReducer(FEEDBACK_FILE_UPLOAD)
});
