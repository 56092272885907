import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';

import { localizedRoute } from '../../../../utils/helperFunctions';

import AutomationRuleDetailDesktop from './Desktop';
import {
	getAutomationActions,
	getAutomationCategories,
	getAutomationChildActions,
	getAutomationCountries,
	getAutomationFrames,
	getAutomationOptionsInit,
	getAutomationPlatformInit,
	getAutomationPlatforms,
	getAutomationPool,
	getAutomationRule,
	getAutomationRuleInit,
	getAutomationSchedulers,
	getAutomationTriggers,
	getAutomationTriggerSubcategories,
	getAutomationZoneModel,
	getAutomationZoneName,
	getAutomationRatePlans,
	getAutomationPrepaidRatePlans,
	getAutomationNetworkEntitlements,
	getAutomationAvailableStates,
	activateRule,
	requestForDeletion,
	getRuleAutomationZones
} from '../../redux/actions';
import {
	addAutomationRulePending,
	automationActions,
	automationChildActions,
	automationCountries,
	automationPool,
	automationSchedulers,
	automationZoneModel,
	automationZoneName,
	editAutomationRulePending,
	getAutomationCategoriesPending,
	getAutomationCategoriesSuccess,
	getAutomationFramesPending,
	getAutomationFramesSuccess,
	getAutomationPlatformPending,
	getAutomationPlatformsPending,
	getAutomationPlatformsSuccess,
	getAutomationPlatformsSuccessStatus,
	getAutomationRulePending,
	getAutomationRuleSuccess,
	getAutomationTriggersPending,
	getAutomationTriggersSuccess,
	getAutomationTriggerSubcategoriesPending,
	getAutomationTriggerSubcategoriesSuccess,
	getM2mAccounts,
	isDataGetting,
	automationRatePlans,
	automationPrepaidRatePlans,
	automationNetworkEntitlements,
	automationAvailableStates,
	activateRuleRequest,
	requestForDeletionRequest,
	setRuleReceivedRequest,
	setRuleImplementedRequest,
	setRuleConflictedRequest,
	getRuleAutomationZonesRequest,
	getRuleAutomationZonesSuccess
} from '../../redux/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import {
	showNotification,
	hideNotification
} from '../../../Shared/redux/notifications/actions';

const mapStateToProps = (state, props) => ({
	rulePending: getAutomationRulePending(state),
	rule: getAutomationRuleSuccess(state),
	formValues: getFormValues('AutomationRuleDetailForm')(state),
	messages: props.messages,
	m2mAccounts: getM2mAccounts(state),
	platformsPending: getAutomationPlatformPending(state),
	platforms: getAutomationPlatformsSuccess(state),
	categoriesPending: getAutomationCategoriesPending(state),
	categories: getAutomationCategoriesSuccess(state),
	triggersPending: getAutomationTriggersPending(state),
	triggers: getAutomationTriggersSuccess(state),
	triggerSubcategoriesPending: getAutomationTriggerSubcategoriesPending(state),
	triggerSubcategories: getAutomationTriggerSubcategoriesSuccess(state),
	framesPending: getAutomationFramesPending(state),
	frames: getAutomationFramesSuccess(state),
	availableStates: automationAvailableStates(state),
	isDataGetting: isDataGetting(state),
	ratePlans: automationRatePlans(state),
	prepaidRatePlans: automationPrepaidRatePlans(state),
	automationActions: automationActions(state),
	networkEntitlements: automationNetworkEntitlements(state),
	automationChildActions: automationChildActions(state),
	automationSchedulers: automationSchedulers(state),
	editRulePending: editAutomationRulePending(state),
	addRulePending: addAutomationRulePending(state),
	getAutomationPlatformsPending: getAutomationPlatformsPending(state),
	getAutomationPlatformsSuccessStatus: getAutomationPlatformsSuccessStatus(
		state
	),
	automationCountries: automationCountries(state),
	automationZoneModel: automationZoneModel(state),
	automationZoneName: automationZoneName(state),
	automationPool: automationPool(state),
	activateRuleRequest: activateRuleRequest(state),
	user: getUser(state),
	requestForDeletionRequest: requestForDeletionRequest(state),
	setRuleReceivedRequest: setRuleReceivedRequest(state),
	setRuleImplementedRequest: setRuleImplementedRequest(state),
	setRuleConflictedRequest: setRuleConflictedRequest(state),
	ruleAutomationZonesRequest: getRuleAutomationZonesRequest(state),
	ruleAutomationZones: getRuleAutomationZonesSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAutomationRule: (id, additionalParams) =>
		dispatch(getAutomationRule(id, additionalParams)),
	getPlatforms: () => dispatch(getAutomationPlatforms()),
	getCategories: (params) => dispatch(getAutomationCategories(params)),
	getTriggers: (params) => dispatch(getAutomationTriggers(params)),
	getTriggerSubcategories: (params) =>
		dispatch(getAutomationTriggerSubcategories(params)),
	getFrames: (params) => dispatch(getAutomationFrames(params)),
	setRuleToInit: () => dispatch(getAutomationRuleInit()),
	setOptionsToInit: () => {
		dispatch(getAutomationOptionsInit());
		dispatch(getAutomationPlatformInit());
	},
	pushBack: () => dispatch(localizedRoute('/automation')),
	getAvailableStates: () => dispatch(getAutomationAvailableStates()),
	getRatePlans: (data) => dispatch(getAutomationRatePlans(data)),
	getPrepaidRatePlans: (data) => dispatch(getAutomationPrepaidRatePlans(data)),
	getAutomationActions: (params) => dispatch(getAutomationActions(params)),
	getNetworkEntitlement: (data) =>
		dispatch(getAutomationNetworkEntitlements(data)),
	getAutomationChildActions: (params) =>
		dispatch(getAutomationChildActions(params)),
	getAutomationSchedulers: (params) =>
		dispatch(getAutomationSchedulers(params)),
	getAutomationCountries: () => dispatch(getAutomationCountries()),
	getAutomationZoneModel: () => dispatch(getAutomationZoneModel()),
	getAutomationZoneName: (params) => dispatch(getAutomationZoneName(params)),
	getAutomationPool: (params) => dispatch(getAutomationPool(params)),
	activateRule: (id, activeStatus, notifOptions) =>
		dispatch(activateRule(id, activeStatus, notifOptions)),
	getRuleAutomationZones: (params) => dispatch(getRuleAutomationZones(params)),
	showNotification: (message) =>
		dispatch(
			showNotification({
				id: 'automation-details-notif',
				message: [message],
				type: 1,
				close: true,
				notificationType: 'admin',
				handleClose: () =>
					dispatch(hideNotification('automation-details-notif'))
			})
		),
	hideNotification: () =>
		dispatch(hideNotification('automation-details-notif')),
	requestForDeletion: (id) => dispatch(requestForDeletion(id))
});

const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(injectIntl(AutomationRuleDetailDesktop));

export default Desktop;
