export default {
	'AUTOMATION_ZONES.AUTOMATION_ZONES': 'Automation Zones',
	'AUTOMATION_ZONES.NO_AUTOMATION_ZONES_AVAILABLE':
		'No automation zones available.',
	'AUTOMATION_ZONES.THERE_ARE_NO_AUTOMATION_ZONES_AVAILABLE':
		'There are no automation zones available',
	'AUTOMATION_ZONES.ZONE_ID': 'Zone Id',
	'AUTOMATION_ZONES.NAME': 'Name',
	'AUTOMATION_ZONES.DESCRIPTION': 'Description',
	'AUTOMATION_ZONES.BY': 'By ',
	'AUTOMATION_ZONES.UPLOAD_FILES': 'Upload Files',
	'AUTOMATION_ZONES.UPLOAD_FILE': 'Upload File',
	'AUTOMATION_ZONES.CANCEL': 'Cancel',
	'AUTOMATION_ZONES.SYSTEM_CONFIRMATION': 'System Confirmation',
	'AUTOMATION_ZONES.CONFIRM_CHANGES': 'Confirm Changes',
	'AUTOMATION_ZONES.CLOSE': 'Close',
	'AUTOMATION_ZONES.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'Upload Successful',
	'AUTOMATION_ZONES.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE':
		'Successfully uploaded file.',
	'AUTOMATION_ZONES.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Error Uploading',
	'AUTOMATION_ZONES.NOTIFICATION_ERROR_UPLOAD_MESSAGE':
		'There was an error uploading this file to IoT Console.',
	'AUTOMATION_ZONES.DOWNLOAD_TEMPLATE': 'Download Template:',
	'AUTOMATION_ZONES.TEMPLATE_CSV': 'Template.csv'
};
