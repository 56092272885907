import { connect } from 'react-redux';

import { getDataHistory } from '../../redux/actions';
import {
	getDataHistoryPending,
	getDataHistorySuccess,
} from '../../redux/selectors';
import DataHistory from './DataHistory';

const mapStateToProps = (state) => ({
	isPending: getDataHistoryPending(state),
	data: getDataHistorySuccess(state),
});
const mapDispatchToProps = (dispatch) => ({
	getDataHistory: (params, deviceId) => dispatch(getDataHistory(
		params,
		deviceId
	)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataHistory);
