import React from 'react';
import { FormattedMessage } from 'react-intl';

const validate = (values) => {
	const errors = {};
	let checkboxes = [];

	Object.keys(values).forEach((key) => {
		if (key && values[key] === true) {
			checkboxes = [
				...checkboxes,
				{
					item: values[key]
				}
			];
		}
	});

	if (checkboxes.length < 1) {
		errors.parentSimSku = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}

	return errors;
};

export default validate;
