import { Status } from 'utils/constants';

export const getBatchFileUploadSuccess = (state) =>
	state.batchFiles.batchUploadMain.uploadStatus === Status.DONE;

export const getBatchFileUploadFail = (state) =>
	state.batchFiles.batchUploadMain.uploadStatus === Status.FAIL;

export const getIsBatchFilesUploading = (state) =>
	state.batchFiles.batchUploadMain.uploadStatus === Status.PENDING;

export const getBatchFileUploadErrors = (state) =>
	state.batchFiles.batchUploadMain.errors;
