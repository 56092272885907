import qs from 'qs';
import {
	get,
	patch,
	post,
	remove,
} from '../../../services/ApiService';

export const getRegistration = (iccid) => get(
	`/endpoints/${iccid}/diagnostic/device-registration?numHours=1&lastRegistrationOnly=true`
);

export const getDiagnostics = ({
	iccid,
	platform = 'acc',
}) => get(`/endpoints/${iccid}/diagnostic/${platform}`);

export const getHistory = ({ iccid, queryStringParameters }) => get(
	`/endpoints/${iccid}/transaction-history/?${qs.stringify(
		queryStringParameters
	)}`
);

export const patchEndpointCustomFields = ({ id, fields }) => patch(
	`/endpoints/${id}/customFields`, { ...fields }
);

export const getDataHistory = ({ params, deviceId }) => get(
	`/endpoints/${deviceId}/diagnostic/data-session-history/wing?${qs.stringify(
		params
	)}`
);

export const postCancelRegistration = ({
	iccid,
	locationData,
	platform = '',
}) => post(
	`/endpoints/${iccid}/diagnostic/cancel-location${platform}`,
	platform === '/wing' ? locationData : { userNotes: 'cancel' }
);

export const postCancelPending = (referenceId) => post(
	'/batch/cancelTransaction',
	{ referenceId, type: 'endpoint' },
);

export const changeNetworkEntitlement = ({
	endpointId,
	networkEntitlementId,
	startDate
}) => patch(
	`/endpoints/${endpointId}/networkEntitlement`,
	{
		networkEntitlementId,
		startDate,
	}
);

export const getRegistrationHistory = ({ iccid, hours }) => get(
	`endpoints${iccid}/diagnostic/device-registration?numHours=${hours || 1}`
);

export const getEndpointHistory = (iccid) => get(
	`/endpoints/${iccid}/history/detail`,
);

export const getMessageHistory = ({ params, deviceId }) => get(
	`/endpoints/${deviceId}/diagnostic/messages?${qs.stringify(params)}`,
);

export const getNetworkEntitlement = (networkEntitlementId) => get(
	`/customers/endpoint-network-entitlements/${networkEntitlementId}`,
);

export const getNetworkEntitlementApn = ({
	m2mAccountId,
	networkEntitlementId,
}) => get(
	`/customers/networkEntitlement/${m2mAccountId}/apn/${networkEntitlementId}`,
);

export const getNote = ({ deviceId, m2mAccountId }) => get(
	`/endpoints/${deviceId}/platform/${m2mAccountId}/se-note`,
);

export const postm2mAccount = (props) => patch(
	`/endpoints/${props.targetICCID}/swapProfile`,
	props,
);

export const updateNote = ({ endpointName, m2mAccountId, data, flag }) => {
	switch (flag) {
		case 'post': return post(
			`/endpoints/${endpointName}/platform/${m2mAccountId}/se-note`,
			data,
		);
		case 'patch': return patch(
			`/endpoints/${endpointName}/platform/${m2mAccountId}/se-note`,
			data,
		);
		default: return remove(
			`/endpoints/${endpointName}/platform/${m2mAccountId}/se-note`,
			{ data },
		);
	}
};

export const postDiagnosticSMSMessage = ({
	iccid,
	messageContent,
	m2mAccountId,
}) => post(
	`/endpoints/${iccid}/diagnostic/messages`,
	{
		messageContent,
		m2mAccountId,
	},
);

export const getBlockedSubscription = ({ iccid, m2mAccountId }) => get(
	`/endpoints/${iccid}/diagnostic/${m2mAccountId}/blocked-subscription-dcp`
);

export const postSMSMessage = ({ message, iccid, platform = '' }) => post(
	`/endpoints/${iccid}/diagnostic/send-sms${platform}`,
	message,
);

export const getDiagnosticCcip = (iccid) => get(
	`/endpoints/${iccid}/diagnostic/troubleshoot-torch?${qs.stringify({
		deviceIdType: 'ICCID'
	})}`
);

export const getDiagnosticDcp = (iccid) => get(
	`/endpoints/${iccid}/diagnostic/dcp`
);

export const getDiagnosticGdsp = async (deviceId) => {
	try {
		const promise = await Promise.all([
			get(`/endpoints/${deviceId}/diagnostic/session-history-gdsp`),
			get(`/endpoints/${deviceId}/diagnostic/device-registration-gdsp`),
		]);

		return {
			data: {
				GDSPSessionData: promise[0].data,
				GDSPDiagnosticData: promise[1].data,
			},
		};
	} catch (error) {
		throw { error: error.response.data };
	}
};

export const getDiagnosticHlr = (deviceId) => get(
	`/endpoints/${deviceId}/hlr-subscriber-profile/wing`,
);

export const getTroubleshootDcp = (iccid) => get(
	`/endpoints/${iccid}/diagnostic/troubleshoot-dcp?${qs.stringify({
		eventFilter: ['d316-1', 'g1-2', 'd317-1']
	})}`
);

export const getEndpointUsageData = ({ imsi, params, type, platform }) => get(
	`/analytics/endpoint-usage/${imsi}/${platform}/${type}-details?${qs.stringify(params)}`,
);

export const getRatePlans = (m2mAccountId = '') => get(
	m2mAccountId
		? `/endpoints/platfrom/${m2mAccountId}/rate-plan`
		: '/endpoints/ratePlan',
);

export const patchSingleEndpoint = ({ id, data }) => patch(
	`/endpoints/${id}/targetState`,
	data,
);

export const getSingleEndpoint = async ({ iccid, type }) => {
	try {
		const responses = await Promise.all([
			get(`/endpoints/${iccid}?type=${type}`),
			get('/customers/customLabels'),
			get(`/batch/transaction-status?${qs.stringify({
				searchParams: [
					{ propValue: 'endpoint', prop: 'type', operator: '=' },
					{ propValue: iccid, prop: 'referenceId', operator: '=' }
				]
			})}`)
		]);

		return {
			data: {
				endpoint: responses[0].data || [],
				customLabels: responses[1].data || [],
				transactionStatus: responses[2].data || null,
			}
		};
	} catch (error) {
		throw { error: error.response.data };
	}
};

export const getSessionHistory = (id) => get(
	`/endpoints/${id}/diagnostic/session-history`,
);

export const exportSessions = (id) => get(
	`/endpoints/${id}/diagnostic/session-history/export`
);

export const getM2MAccount = () => get('/customers/m2mAccount');

export const exportUsageData = (params) => post(
	'batch/file-download',
	params,
);

const checkExportUsageStatus = (id) => get(`batch/file-download/${id}`);

export const pollForFile = (data, delay) => new Promise((resolve, reject) => {
	setTimeout(async () => {
		try {
			const response = await checkExportUsageStatus(data);

			if (response.data.status === 0 || response.data.status === 1) {
				resolve(pollForFile(data, delay));
			} else if (response.data.status === 2) {
				resolve(response.data);
			} else {
				reject({ error: response });
			}
		} catch (error) {
			reject({ error: [] });
		}
	}, delay);
});
