import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import { updateSingleEndpoint } from '../../../../SingleEndpoint/redux/actions';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import Button from '../../../../../lib/DigitalComponents/Button';
import {
	getDateFormat,
	sameDateFormatter,
	startOfDayFormatter
} from '../../../../../utils/constants';

import styles from './Steps.scss';

class StepFirst extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
		const { context } = this.props;
		this.onDefaultDateChange(new Date());
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context } = this.props;
		const { selectedDay } = this.state;
		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

	updateEndpointStatus = () => {
		const {
			intl,
			singleEndpointUpdate,
			user,
			newState: val,
			endpoint
		} = this.props;
		const { selectedDay } = this.state;

		if (val.value === endpoint.status) return;
		const dateFormat = 'yyyy-MM-dd';
		const changeDate = format(selectedDay, dateFormat);
		const updateData = {
			id: endpoint.endpointName,
			updateScreen: 'scheduleChangeModal',
			data: {
				targetState: val.value,
				userNotes: intl.formatMessage(
					{
						id: 'ENDPOINTS.STATUS_CHANGE_USER_NOTE',
						defaultMessage: `User ${user.uid} made Change status on ${changeDate}`
					},
					{ user: user.uid, changeDate }
				)
			}
		};
		if (!sameDateFormatter(selectedDay)) {
			updateData.data.startDate = startOfDayFormatter(selectedDay);
		}
		singleEndpointUpdate(updateData);
	};

	handleOnClick = () => {
		const { onNext } = this.props;
		const { selectedDay } = this.state;

		this.updateEndpointStatus(selectedDay);
		onNext({ scheduleDate: selectedDay });
	};

	setFooter = (context) => {
		const { onCancel } = this.props;
		const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					onClick={onCancel}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					variant="primary"
					onClick={this.handleOnClick}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="ENDPOINTS.SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule change"
							/>
						) : (
							<FormattedMessage
								id="ENDPOINTS.INITIATE_CHANGE"
								defaultMessage="Initiate change"
							/>
						)
					}
					dataSpec="schedule-initiate-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	onDefaultDateChange = (date) => {
		this.setState({
			selectedDay: date || new Date()
		});
	};

	render() {
		const { selectedDay } = this.state;
		return (
			<div data-spec="first-step">
				<div className={styles.custom}>
					<div className="confirm-content">
						<DSDatePicker
							label={
								<FormattedMessage
									id="ENDPOINTS.SCHEDULE_DATE"
									defaultMessage="Date"
								/>
							}
							value={selectedDay}
							onDayChange={this.onDefaultDateChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const { func, object } = PropTypes;

StepFirst.propTypes = {
	onCancel: func,
	onNext: func,
	singleEndpointUpdate: func,
	newState: object,
	endpoint: object,
	user: object,
	context: object,
	intl: object
};

const mapDispatchToProps = (dispatch) => ({
	singleEndpointUpdate: (data) => dispatch(updateSingleEndpoint(data))
});

export default connect(null, mapDispatchToProps)(injectIntl(StepFirst));
