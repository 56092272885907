import { connect } from 'react-redux';
import {
	formValueSelector,
	getFormValues,
	isPristine,
	reduxForm
} from 'redux-form';

import { isCycle } from '../../../Shared/components/Charts/Helpers/helpers';
import { getAttributes, getCategories, getFilters } from '../../redux/actions';
import {
	getAttributesSuccess,
	getCategoriesSuccess,
	getFiltersSuccess,
	isAttributesPending,
	isCategoriesPending,
	isFiltersPending
} from '../../redux/selectors';

import AnalyticsModal from './AnalyticsModal';

const selector = formValueSelector('analyticsModalForm');
const mapStateToProps = (state, { editItem }) => ({
	initialValues: {
		radioCategory: editItem.radioCategory || '',
		groupBySelector: isCycle(editItem.groupByDateId)
			? editItem.filters &&
			editItem.filters.platform &&
			(Object.keys(editItem.filters.platform)[0] || '')
			: editItem.groupByColumn || editItem.groupBySelector || '',
		groupByDate: editItem.groupByDateId || editItem.groupByDate || '0',
		groupByIncrement:
			editItem.incrementById || editItem.groupByIncrement || '0',
		groupByShow: editItem.aggregationFunctionId || editItem.groupByShow || '0',
		filtersForm: editItem.editFilters || editItem.filterForm || {},
		chartForm: editItem.type || editItem.chartForm || '',
		showSummary: editItem.showSummary,
	},
	groupByDate: parseInt(selector(state, 'groupByDate'), 10),
	groupBySelector: selector(state, 'groupBySelector'),
	categoryId: selector(state, 'radioCategory'),
	chartType: selector(state, 'chartForm'),
	formValues: getFormValues('analyticsModalForm')(state),
	categories: getCategoriesSuccess(state),
	categoriesPending: isCategoriesPending(state),
	attributes: getAttributesSuccess(state),
	attributesPending: isAttributesPending(state),
	filters: getFiltersSuccess(state),
	filtersPending: isFiltersPending(state),
	isPristine: isPristine('analyticsModalForm')(state)
});

const mapDispatchToProps = (dispatch) => ({
	getCategories: () => dispatch(getCategories()),
	getAttributes: () => dispatch(getAttributes()),
	getFilters: (attributes, categoryId) =>
		dispatch(getFilters(attributes, categoryId))
});

const AnalyticsModalForm = reduxForm({
	form: 'analyticsModalForm',
	enableReinitialize: true,
})(AnalyticsModal);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AnalyticsModalForm);
