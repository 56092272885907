import { createAction } from 'redux-actions';
import {
	SELECTED_ITEMS as SELECTED_ITEMS_CONSTANT,
	SELECTED_ITEMS_INIT as SELECTED_ITEMS_INIT_CONSTANT
} from './constants';

const SELECTED_ITEMS = createAction(SELECTED_ITEMS_CONSTANT);
export const SELECTED_ITEMS_INIT = createAction(SELECTED_ITEMS_INIT_CONSTANT);

export const setSelectedItems = (data) => async (dispatch) => {
	dispatch(SELECTED_ITEMS(data));
};

export const setSelectedItemsInit = () => async (dispatch) => {
	dispatch(SELECTED_ITEMS_INIT());
};
