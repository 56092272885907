export const ADD_SIM_SKU = 'ADD_SIM_SKU';
export const DELETE_SIM_SKU = 'DELETE_SIM_SKU';
export const DELETE_SIM_SKU_MODAL = 'DELETE_SIM_SKU_MODAL';
export const EDIT_SIM_SKU = 'EDIT_SIM_SKU';
export const EDIT_SIM_SKU_MODAL = 'EDIT_SIM_SKU_MODAL';

export const GET_PARENT_SIM_SKUS = 'GET_PARENT_SIM_SKUS';
export const ADD_SIM_SKU_MODAL = 'ADD_SIM_SKU_MODAL';
export const ADD_SUB_ACCOUNT_SIM_SKU = 'ADD_SUB_ACCOUNT_SIM_SKU';
export const GET_SKUS = 'GET_SKUS';

export const POST_ACCOUNT_TRANSFER = 'POST_ACCOUNT_TRANSFER';
export const GET_M2M_ACCOUNTS = 'GET_M2M_ACCOUNTS';
export const GET_RATE_PLANS = 'GET_RATE_PLANS';
export const GET_NETWORK_ENTITLEMENTS = 'GET_NETWORK_ENTITLEMENTS';
