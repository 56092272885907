import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import ReleaseNotesEdit from './ReleaseNotesEdit';

import {
	getReleaseNotes,
	getAdditionalDetailStatuses,
	getReleaseNotesInit,
	addReleaseNotes,
	editReleaseNotes
} from '../../redux/actions';

import {
	getReleaseNotesRequest,
	getReleaseNotesFail,
	getReleaseNotesSuccess,
	getAdditionalDetailStatusesRequest,
	getAdditionalDetailStatusesFail,
	getAdditionalDetailStatusesSuccess,
	addReleaseNotesRequest,
	editReleaseNotesRequest
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	releaseNotesRequest: getReleaseNotesRequest(state),
	releaseNotesFail: getReleaseNotesFail(state),
	releaseNotes: getReleaseNotesSuccess(state),
	additionalDetailStatusesRequest: getAdditionalDetailStatusesRequest(state),
	additionalDetailStatusesFail: getAdditionalDetailStatusesFail(state),
	additionalDetailStatuses: getAdditionalDetailStatusesSuccess(state),
	formValues: getFormValues('ReleaseNotesEditForm')(state),
	addReleaseNotesRequest: addReleaseNotesRequest(state),
	editReleaseNotesRequest: editReleaseNotesRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	getReleaseNotes: (params) => dispatch(getReleaseNotes(params)),
	getAdditionalDetailStatuses: () => dispatch(getAdditionalDetailStatuses()),
	goBack: (url) => dispatch(localizedRoute(url)),
	getReleaseNotesInit: () => dispatch(getReleaseNotesInit()),
	addReleaseNotes: (params) => dispatch(addReleaseNotes(params)),
	editReleaseNotes: (params) => dispatch(editReleaseNotes(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesEdit);
