import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import Button from '../../../../lib/DigitalComponents/Button';
import SectionHeadline from '../../../../lib/DigitalComponents/SectionHeadline';
import CompanyContactOverview from '../CompanyContactOverview';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import UploadImage from '../UploadImage';
import CompanyAddressOverview from '../CompanyAddressOverview';
import Divider from '../../../../lib/DigitalComponents/Divider';
import { companySupportType } from '../../utils/constants';

import styles from './AddCompanyOverview.scss';

const UnitedStates = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const France = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const Spain = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const Italy = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const Netherlands = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const Brazil = ImageSelector(CurrentContext.theme, 'svgs/brazil.svg');
const Germany = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const PRC = ImageSelector(CurrentContext.theme, 'svgs/china.svg');

const CompanyLogo = ImageSelector(
	CurrentContext.theme,
	'company-image-placeholder.png'
);

class AddCompanyOverview extends PureComponent {
	renderFlags = (data) => {
		switch (data.value) {
			case 1:
				return <UnitedStates data-spec="UnitedStates" />;
			case 2:
				return <France data-spec="France" />;
			case 3:
				return <Spain data-spec="Spain" />;
			case 4:
				return <Italy data-spec="Italy" />;
			case 5:
				return <Netherlands data-spec="Netherlands" />;
			case 6:
				return <Germany data-spec="Germany" />;
			case 7:
				return <Brazil data-spec="Brazil" />;
			case 8:
				return <PRC data-spec="PRC" />;
			// no default
		}
	};

	renderSupportType = () => {
		const { formData, intl } = this.props;
		let description = intl.formatMessage({
			id: 'ONBOARDING.STANDARD_DESCRIPTION',
			defaultMessage:
				'This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support.'
    });

		switch (formData && formData.supportType) {
			case companySupportType.PRIORITY:
				description = intl.formatMessage({
					id: 'ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION',
					defaultMessage:
						'All tickets will be supported by the IoT Priority Care team. The account’s contract must specify that they qualify for IoT Priority Care support.'
				});
				break;
			case companySupportType.FULL:
				description = intl.formatMessage({
					id: 'ONBOARDING.FULL_TSM_DESCRIPTION',
					defaultMessage:
						'All tickets, regardless of severity, will be supported by the Technical Solutions Manager team. The account’s contract must specify that they qualify for TSM support or the Advanced Solutions Design and Implementation (ASDI) team has specified that the account qualifies for TSM support.'
				});
				break;
			case companySupportType.PARTIAL:
				description = intl.formatMessage({
					id: 'ONBOARDING.PARTIAL_TSM_DESCRIPTION',
					defaultMessage:
						'Critical tickets will be supported by the Technical Solutions Manager team. All other severities will be supported by the ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team. The account’s contract must specify that they qualify for TSM support for critical issues.'
				});
				break;
			case companySupportType.GATEWAY:
				description = intl.formatMessage({
					id: 'ONBOARDING.IOT_GATEWAY_DESCRIPTION',
					defaultMessage:
						'Only for use when Equipment Inquiries will be submitted for manually processed TCU (Transceiver Control Unit) replacement requests.'
				});
				break;
			default:
				description = intl.formatMessage({
					id: 'ONBOARDING.STANDARD_DESCRIPTION',
					defaultMessage:
						'This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support.'
				});
		}

		return (
			<>
				<span className={styles.bold}>
					{(formData && formData.supportType) || (
						<FormattedMessage
							id="ONBOARDING.STANDARD"
							defaultMessage="Standard (Default)"
						/>
					)}
				</span>{' '}
				- {description}
			</>
		);
	};

	render() {
		const { formData, handleSubmit, onClose, submitting, intl } = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				data-spec="company-details"
				className={styles.page_wrapper}
			>
				<div className={styles.form_wrapper}>
					<h2 className={styles.form_headline}>
						<FormattedMessage
							id="ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION"
							defaultMessage="Verify new company information"
						/>
					</h2>
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.ACCOUNT_NAME',
							defaultMessage: 'Account Name'
						})}
					/>
					<section className={styles.form_logo}>
						<div>
							<StaticField
								label={intl.formatMessage({
									id: 'ONBOARDING.COMPANY_NAME',
									defaultMessage: 'Company Name'
								})}
								data={formData.companyFriendlyName}
								single
							/>
							<StaticField
								label={intl.formatMessage({
									id: 'ONBOARDING.BUSINES_LEGAL_NAME',
									defaultMessage: 'Business Legal Name'
								})}
								data={formData.companyLegalName}
								single
							/>
							<StaticField
								label={intl.formatMessage({
									id: 'ONBOARDING.PREFERRED_LANGUAGE',
									defaultMessage: 'Preferred Language'
								})}
								data={
									<div className={styles.preferred_language_info}>
										{this.renderFlags(formData.languageId)}
										{formData &&
											formData.languageId &&
											formData.languageId.label}
									</div>
								}
								single
							/>
						</div>
						<div className={styles.logo}>
							<UploadImage
								updateLogo={this.updateLogo}
								customDefaultLogo={CompanyLogo}
								canChange={false}
								logo={formData && formData.logo}
							/>
						</div>
					</section>

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.MAIN_CORPORATE_ADDRESS',
							defaultMessage: 'Main Corporate Address'
						})}
					/>

					{formData &&
						formData.addresses &&
						formData.addresses.length > 0 &&
						formData.addresses.map((item) => (
							<CompanyAddressOverview address={item} key={item} />
						))}

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.COMPANY_CONTACTS',
							defaultMessage: 'Company Contacts'
						})}
					/>

					{formData &&
						formData.companyContacts &&
						formData.companyContacts.length > 0 &&
						formData.companyContacts.map((item) => (
							<CompanyContactOverview contact={item} key={item} />
						))}

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.ATT_CONTACTS',
							defaultMessage: 'AT&T Contacts'
						})}
					/>

					{formData &&
						formData.attContacts &&
						formData.attContacts.length > 0 &&
						formData.attContacts.map((item) => (
							<CompanyContactOverview contact={item} key={item} />
						))}

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.VTM_SUPPORT_TYPE',
							defaultMessage: 'vTM Support Type'
						})}
					/>
					<section>
						<div className={styles.support_type_wrapper}>
							{this.renderSupportType()}
						</div>
					</section>
					<br />
				</div>

				<>
					<Divider />
					<div className={styles.section_buttons}>
						<Button
							label={<FormattedMessage id="EDIT" defaultMessage="Edit" />}
							variant="outline-primary"
							type="button"
							dataSpec="cancel-button"
							onClick={onClose}
						/>
						<Button
							label={
								<FormattedMessage
									id="ONBOARDING.SUBMIT"
									defaultMessage="Submit"
								/>
							}
							variant="primary"
							type="submit"
							dataSpec="submit-button"
							disabled={submitting}
						/>
					</div>
				</>
			</form>
		);
	}
}

const { func, object, bool } = PropTypes;

AddCompanyOverview.propTypes = {
	handleSubmit: func,
	formData: object,
	onClose: func,
	submitting: bool,
	intl: object
};

export default reduxForm({
	form: 'AddCompanyForm',
	destroyOnUnmount: false
})(injectIntl(AddCompanyOverview));
