export default {
	'SECURITY_FEATURES.TITLE': 'Beveiligingsfuncties',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'Whitelist IMEI',
	'SECURITY_FEATURES.SMS_WHITELIST': 'Whitelist sms',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'Whitelist voice',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'Blacklist IP, URL, poort',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'Whitelist IP-bereik',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': 'Geen beveiligingsfuncties beschikbaar',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': 'Er zijn momenteel geen beveiligingsfuncties voor deze tabel om te laten zien',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'NAAM M2M-ACCOUNT',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'DATUM VERZONDEN',
	'SECURITY_FEATURES.STATUS': 'STATUS',
	'SECURITY_FEATURES.PENDING': 'In behandeling',
	'SECURITY_FEATURES.FAILED': 'Mislukt',
	'SECURITY_FEATURES.SUCCESSFUL': 'Geslaagd',
	'SECURITY_FEATURES.CCIP': 'Control Center Integration Program (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Vivo Control Center',
	'SECURITY_FEATURES.DCP': 'Apparaatverbindingsplatform (DCP)',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'AT&T Control Center (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// MENU ZOEKEN
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOICE',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, POORT',
	'SECURITY_FEATURES.MENU_5': 'IP-BEREIK',
	'SECURITY_FEATURES.ADD_1': 'Nieuwe IMEI aan de whitelist toevoegen',
	'SECURITY_FEATURES.ADD_2': 'Nieuwe sms aan de whitelist toevoegen',
	'SECURITY_FEATURES.ADD_3': 'Nieuwe voice aan de whitelist toevoegen',
	'SECURITY_FEATURES.ADD_4': 'Nieuwe IP, URL, poort aan de blacklist toevoegen',
	'SECURITY_FEATURES.ADD_5': 'Nieuw IP-bereik aan de whitelist toevoegen',
	'SECURITY_FEATURES.EXPORT': 'Exporteren',
	'SECURITY_FEATURES.EDIT': 'Bewerken',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'M2M-platform selecteren',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Bulkupload',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Bestand uploaden',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Wijzigingen bevestigen',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. Systeembevestiging',
	'SECURITY_FEATURES.CANCEL': 'Annuleren',
	'SECURITY_FEATURES.CONTINUE': 'Doorgaan',
	'SECURITY_FEATURES.DONE': 'Gereed',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Bestand uploaden',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': 'Upload hier bestanden voor de geselecteerde batchactie:',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Versleep het bestand of klik om te uploaden',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Upload batchbestand bevestigen',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Geplande actie op',
	'SECURITY_FEATURES.LOADER_MESSAGE': 'Even geduld. Dit kan een paar minuten duren, afhankelijk van de bestandsgrootte.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': 'De batchbestanden zijn ontvangen!',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': 'Wijzigingen blijven in behandeling tot het systeem uw updates heeft voltooid',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'Systeemfout batchbestand!',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': 'Er is een probleem opgetreden tijdens het verwerken van uw updates. Probeer het later opnieuw.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Sjabloon downloaden',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': 'Bestanden moeten met de extensie .csv worden verzonden.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': 'Maximumaantal beveiligingsfuncties per upload is '
};
