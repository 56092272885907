import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const MultiEndpointActionContext = React.createContext();

export class MultiEndpointActionContextProvider extends PureComponent {
	constructor(props) {
    super(props);
		this.state = {};
  }

	render() {
    const { children } = this.props;
		return (
			<MultiEndpointActionContext.Provider
				data-spec="multi-endpoint-action-modal-context-provider"
				value={{
					state: this.state,
					updateContext: (newState) => this.setState(newState)
				}}
			>
				{children}
			</MultiEndpointActionContext.Provider>
		);
	}
}

MultiEndpointActionContextProvider.propTypes = {
	children: PropTypes.any
};

export function MultiEndpointActionContextConsumer(props) {
    const { children } = props;

		return (
			<MultiEndpointActionContext.Consumer data-spec="multi-endpoint-action-modal-context-consumer">
				{(context) =>
					React.Children.map(children, (child) =>
						React.cloneElement(child, { ...props, context }))}
			</MultiEndpointActionContext.Consumer>
		);
	}

MultiEndpointActionContextConsumer.propTypes = {
	children: PropTypes.any
};
