import React from 'react';
import { oneOfType, object, bool, array, string } from 'prop-types';
import cn from 'classnames';

import styles from './FieldWrapper.scss';

const FieldWrapperPropTypes = {
	children: oneOfType([object, array]).isRequired,
	required: bool,
	single: bool,
	customStyle: oneOfType([string, object])
};

const FieldWrapperDefaultProps = {
	required: false,
	single: false,
	customStyle: {}
};

export default function FieldWrapper({
	children,
	required,
	single,
	customStyle
}) {
	const FieldWrapperClass = cn({
		[styles.field_wrapper]: true,
		[styles.required_field]: required,
		[styles.single_field]: single,
		[customStyle]: true
	});

	return (
		<div data-spec="field-wrapper" className={FieldWrapperClass}>
			{children}
		</div>
	);
}

FieldWrapper.propTypes = FieldWrapperPropTypes;
FieldWrapper.defaultProps = FieldWrapperDefaultProps;
