export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': '端点{iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': '编辑端点',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': '网络权限',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE': '端点ID {args1} 更改IMSI状态后配给失败。 获得支持',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE': '{step}.选择SIM卡状态',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP': '{step}.安排SIM卡状态更改',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}.系统确认',
	'SINGLE_ENDPOINT.ICCID': '集成线路卡识别符（ICCID）',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': '移动台国际订户目录号（MSISDN）',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': '状态',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS': '刷新端点，以检查状态是否更改',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': '机对机（M2M）平台',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'SINGLE_ENDPOINT.NETWORK_TYPE': '网络类型',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': '网络运营者',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': '网络国家',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': '机对机（M2M）日期已添加',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': '机对机（M2M）激活日期',
	'SINGLE_ENDPOINT.IN_SESSION': '会话中',
	'SINGLE_ENDPOINT.RATE_PLAN': '费率计划',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT': '费率计划错误，请与客户支持联系。',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT': '网络权限出错，请与客户支持联系。',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'IMSI转让状态',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': '漫游国家',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': '账户名称',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': '网络权限名称',
	'SINGLE_ENDPOINT.ROAMING': '漫游',
	'SINGLE_ENDPOINT.CREATE_DATE': '创建日期',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': '最后一次更新日期',
	'SINGLE_ENDPOINT.SMS_MO': '移动端始发短信（SMS MO）',
	'SINGLE_ENDPOINT.SMS_MT': '移动端终止短信（SMS MT）',
	'SINGLE_ENDPOINT.VOICE_MO': '移动端始发（MO）语音',
	'SINGLE_ENDPOINT.VOICE_MT': '移动端终止（MT）语音',
	'SINGLE_ENDPOINT.LTE_DATA': 'LTE数据',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': '漫游ID',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Camel服务类型',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': '阻止付费号码',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': '阻止国际移动端始发短信（SMS MO）',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'HLR模板',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': '国际语音',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': '热线电话号码',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': '通用分组无线业务（GPRS）',
	'SINGLE_ENDPOINT.DESCRIPTION': '描述',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': '固定IP',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT': '对此端点不适用',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': '费率计划名称',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': '费率计划类型',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': '订阅价格',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': '账户收费',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': '付款类型',
	'SINGLE_ENDPOINT.TERM': '期限',
	'SINGLE_ENDPOINT.USAGE_LIMIT': '用量限制',
	'SINGLE_ENDPOINT.TIERING_FLAG': '层级标记',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID：{id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD': '要求升级许可！',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION': '修改定制字段会影响整个系统配置，仅限管理员可执行此操作。请联系您的管理员，要求其更新定制字段选项。',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID': '{endpointId}的端点操作',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}.选择SIM卡状态',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': '端点ID',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING': '已经有一项为此端点安排的活动。',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE': '您确定想要取消待决更改吗？',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT': '此行动不可逆。',
	'SINGLE_ENDPOINT.CARRIER': '服务运营商',
	'SINGLE_ENDPOINT.RECREDENTIALED': '已重新设定端点',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': '注释',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES': '用户{uid} 已经更改状态，更改日期为 {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': '已成功安排SIM卡状态更改，日期为',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID': '端点ID{endpointId}的当前SIM卡状态是',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS': '符合条件的网络权限',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': '固定IP',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT': '当前已指派给此端点',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS': '网络权限详情',
	SELECT_MINIMUM_OF_2: '至少选择应用于此表格的2个项目。',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': '开始诊断',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': '服务运营商信息',
	'SINGLE_ENDPOINT.COUNTRY': '国家',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION': '机对机（M2M） 平台状态描述',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': '使用中活跃',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': '新未使用',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': '使用中睡眠',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': '未使用已停止',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I': '从其他GDSP环境迁移',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': '未配给',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O': '向其他GDSP环境迁移',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': '使用中就绪',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': '使用中暂停',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': '使用中测试',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': '使用中待机',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': '未使用已转化',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': '暂停监管',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': '已终止',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': '不可用',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION': '端点配给信息',
	'SINGLE_ENDPOINT.PROVISIONED': '已配给',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME': '配给日期和时间{date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': '网络连接',
	'SINGLE_ENDPOINT.VOICE_ENABLED': '语音已启用',
	'SINGLE_ENDPOINT.DATA_ENABLED': '数据已启用',
	'SINGLE_ENDPOINT.SESSIONS': '会话',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': '库存',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': '已激活',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': '已激活',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': '未使用',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': '未使用',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': '已停用',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': '已淘汰',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': '已清除',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': '准备好测试',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready': '准备好激活',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': '已替换',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': '试用',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': '开始',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': '转让',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource': '全球运送中来源',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination': '全球运送中目的地',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred': '全球已转移',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': '其他',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic': '已允许传递流量',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic': '未允许传递流量',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources': '获得许可的TollSec无线资源',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork': '被网络禁止',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate': 'SIM卡状态不允许流量',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider': '已被服务提供者暂停',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit': '已因用量限制暂停',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others': '不允许流量（其他）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession': '已成功连接到网络（会话中）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession': '未连接到网络（会话中）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity': '有效的注册（有效的网络活动）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity': '有效的注册（无网络活动）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly': '仅GSM注册有效',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h': '无有效注册（过去24小时内有活动）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate': '无有效注册（过去24小时内有验证请求）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago': '无有效注册（超过24小时以前有本地更新）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity': '无有效注册（超过24小时以前有验证请求）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity': '超过24小时以前（无网络活动）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful': '设备当前未联网。之前的IP会话成功。',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful': '数据会话当前不成功',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful': '数据会话过去成功',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials': '连接尝试失败（无效凭据）',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory': '无数据会话成功历史记录',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources': '获得许可的TollSec无线资源',
	'SINGLE_ENDPOINT.ACTIONS': '行动',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': '获取HLR/HSS详情',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': '重复产生的费用',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1.选择费率计划',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2.确认更改',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3.系统确认',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT': '可用于此端点',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED': '已成功更新费率计划更改',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': '已成功地安排费率计划更改',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': '已成功安排费率计划更改',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE': '确认网络权限更改',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE': '一旦开始更改操作，被选中的网络权限将 ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION': '您确定要继续此项行动吗？',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': '您也可以将此更改安排在： ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED': '已成功安排网络权限更改。',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': '已成功将网络权限更改安排在 ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY': '目前无法完成网络权限更改。',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY': '目前无法安排网络权限更改。',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS': '网络权限更改状态',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID': '端点ID{id}当前的费率计划是',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': '漫游服务运营商',
	'SINGLE_ENDPOINT.OEM': '子账户',
	'SINGLE_ENDPOINT.SERVICE_STATUS': '360L状态',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA：',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE：',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': '网络连接',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR：',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN：',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': '注册状态',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': '关联的360L账户',
	'SINGLE_ENDPOINT.DUAL_MODE': '双模式',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': '已连接的设备',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': '费率计划',
	'SINGLE_ENDPOINT.RECORDED_STATUS': '记录的状态',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': '激活日期',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': '输出使用数据',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE': '输出使用数据失败',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE': '目前无法输出使用数据。',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE': '请稍候，我们正在为您准备文件。此过程可能需要一些时间，具体取决于记录数量。',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE': '选择的阶段没有可供显示的数据',
  'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST': '如有可能，请更改日期范围以刷新清单',

  //  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': '已激活',
	'SINGLE_ENDPOINT.TERMINATE': '已终止',
	'SINGLE_ENDPOINT.DEACTIVE': '停用',
	'SINGLE_ENDPOINT.INACTIVE': '未使用',
	'SINGLE_ENDPOINT.TEST': '测试',
	'SINGLE_ENDPOINT.SUSPENDED': '已暂停',
	'SINGLE_ENDPOINT.SUSPEND': '暂停',
	'SINGLE_ENDPOINT.INVENTORY': '库存',
};
