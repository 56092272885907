import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	ADD_ACCOUNT,
  GET_ACCOUNT,
  EDIT_ACCOUNT,
	GET_ACCOUNTS,
	GET_PLATFORMS,
	GET_PARENT_ACCOUNT,
	ADD_MY_SUB_ACCOUNT,
	ACTIVATE_ACCOUNT,
	REQUEST_ACCESS,
	GRANT_ACCESS,
	GET_PLATFORM_TYPES,
	VALIDATE_ACCOUNT
} from './constants';

export default combineReducers({
	addAccount: globalReducer(ADD_ACCOUNT),
  getAccount: globalReducer(GET_ACCOUNT),
  editAccount: globalReducer(EDIT_ACCOUNT),
	getAccounts: globalReducer(GET_ACCOUNTS),
	getPlatforms: globalReducer(GET_PLATFORMS),
	getParentAccount: globalReducer(GET_PARENT_ACCOUNT),
	addSubAccount: globalReducer(ADD_MY_SUB_ACCOUNT),
	activateAccount: globalReducer(ACTIVATE_ACCOUNT),
	requestAccess: globalReducer(REQUEST_ACCESS),
	grantAccess: globalReducer(GRANT_ACCESS),
	getPlatformTypes: globalReducer(GET_PLATFORM_TYPES),
	validateAccount: globalReducer(VALIDATE_ACCOUNT)
});
