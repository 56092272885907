export default {
	'SHARED.NO_DATA_AVAILABLE': '无可用的数据',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE': '目前无可显示的数据',
	'SHARED.EXIT_THIS_COMPANY': '退出',
	// ACTION BAR SELECTED FILTERS TOOLTIP
	// ** ENDPOINTS **
	'ACTION_BAR.ENDPOINTSTATUS': '状态',
	'ACTION_BAR.NETWORKOPERATOR': '网络运营者',
	'ACTION_BAR.NETWORKCOUNTRY': '网络国家',
	'ACTION_BAR.NETWORKENTITLEMENT': '网络权限ID',
	'ACTION_BAR.NETWORKTYPE': '网络类型',
	'ACTION_BAR.TRANSFERSTATUS': 'IMSI转让状态',
	'ACTION_BAR.RATEPLAN': '费率计划',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': '重新设定端点状态',
	'ACTION_BAR.M2MPLATFORM': '机对机（M2M）平台',
	'ACTION_BAR.ROAMINGCOUNTRY': '漫游国家',
	'ACTION_BAR.ROAMINGCARRIER': '漫游服务运营商',
	'ACTION_BAR.SERVICESTATUS': '360L状态',
	'ACTION_BAR.OEM': '子账户',
	'ACTION_BAR.M2MDATEADDED': '机对机（M2M）日期已添加',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': '机对机（M2M）激活日期',
	//  **  INVOICES  **
	'ACTION_BAR.M2MACCOUNTID': '机对机（M2M）账户ID',
	'ACTION_BAR.CUSTOMERNAME': '客户名称',
	'ACTION_BAR.INVOICENUMBER': '账单号码',
	'ACTION_BAR.COUNTRYNAME': '国家名称',
	'ACTION_BAR.BILLINGCYCLE': '账单周期',
	'ACTION_BAR.CUSTOMERID': '客户ID',
	'ACTION_BAR.INVOICEDATE': '账单日期',
	//  **  REPORTS **
	'ACTION_BAR.M2M_ACC_ID': '机对机（M2M）账户ID',
	'ACTION_BAR.M2M_PLATFORM': '机对机（M2M）平台',
	'ACTION_BAR.PLATFORM': '平台',
	'ACTION_BAR.FREQUENCY': '频率',
	'ACTION_BAR.REPORT_NAME': '报告名称',
	'ACTION_BAR.SERVICEPROVIDER': '平台',
	'ACTION_BAR.REPORTFREQUENCY': '频率',
	//  **  SIM ORDERING  **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': '状态',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': '机对机（M2M）平台',
	'ACTION_BAR.FILTER_CATEGORY_SKU': '储存单位（SKU）',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': '已加急',
	//  **  SECURITY FEATURES **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': '机对机（M2M）账户',
	//  **  TICKETING **
	'ACTION_BAR.CATEGORIES': '类别',
	'ACTION_BAR.STATUS': '状态',
	'ACTION_BAR.SEVERITY': '严重性',
	//  ACTION BAR
	'ACTION_BAR.CLEAR_FILTERS': '清除过滤器',
	'ACTION_BAR.SELECT_FILTERS': '选择过滤器',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': '编辑表格参数',
	'ACTION_BAR.SHOWING': '正在显示',
	'ACTION_BAR.OF': '共',
	'ACTION_BAR.DOWNLOAD_FILE': '下载文件',
	'ACTION_BAR.REFRESH_PAGE': '刷新页面',
	//  ******************* FILTER MODAL *****************************
	//  **  ENDPOINTS **
	'FILTER_MODAL.ENDPOINTSTATUS': '状态',
	'FILTER_MODAL.NETWORKOPERATOR': '网络运营者',
	'FILTER_MODAL.NETWORKCOUNTRY': '网络国家',
	'FILTER_MODAL.NETWORKENTITLEMENT': '网络权限ID',
	'FILTER_MODAL.NETWORKTYPE': '网络类型',
	'FILTER_MODAL.TRANSFERSTATUS': 'IMSI转让状态',
	'FILTER_MODAL.RATEPLAN': '费率计划',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': '重新设定端点状态',
	'FILTER_MODAL.M2MPLATFORM': '机对机（M2M）平台',
	'FILTER_MODAL.ROAMINGCOUNTRY': '漫游国家',
	'FILTER_MODAL.ROAMINGCARRIER': '漫游服务运营商',
	'FILTER_MODAL.SERVICESTATUS': '360L状态',
	'FILTER_MODAL.OEM': '子账户',
	'FILTER_MODAL.M2MDATEADDED': '机对机（M2M）日期已添加',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': '机对机（M2M）激活日期',
	//  标签
	'FILTER_MODAL.TABS_ENDPOINTSTATE': '端点状态',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': '网络运营者',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': '网络国家',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': '网络权限',
	'FILTER_MODAL.TABS_NETWORKTYPE': '网络类型',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': '转移状态',
	'FILTER_MODAL.TABS_RATEPLAN': '费率计划',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': '重新设定转移状态',
	'FILTER_MODAL.TABS_M2MPLATFORM': '机对机（M2M）平台',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': '漫游国家',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': '漫游服务运营商',
	'FILTER_MODAL.TABS_SERVICESTATUS': '360L状态',
	'FILTER_MODAL.TABS_OEM': '子账户',
	'FILTER_MODAL.TABS_M2MDATEADDED': '机对机（M2M）日期已添加',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': '机对机（M2M）激活日期',
	//  **  INVOICES  **
	'FILTER_MODAL.M2MACCOUNTID': '机对机（M2M）账户ID',
	'FILTER_MODAL.CUSTOMERNAME': '客户名称',
	'FILTER_MODAL.INVOICENUMBER': '账单号码',
	'FILTER_MODAL.INVOICEDATE': '账单日期',
	'FILTER_MODAL.COUNTRYNAME': '国家名称',
	'FILTER_MODAL.CUSTOMERID': '客户ID',
	'FILTER_MODAL.BILLINGCYCLE': '账单周期',
	//  标签
	'FILTER_MODAL.TABS_M2MACCOUNTID': '机对机（M2M）账户ID',
	'FILTER_MODAL.TABS_CUSTOMERNAME': '客户名称',
	'FILTER_MODAL.TABS_INVOICENUMBER': '账单号码',
	'FILTER_MODAL.TABS_COUNTRYNAME': '国家名称',
	'FILTER_MODAL.TABS_CUSTOMERID': '客户ID',
	'FILTER_MODAL.TABS_BILLINGCYCLE': '账单周期',
	'FILTER_MODAL.TABS_INVOICEDATE': '账单日期',

	//  **  REPORTS **
	'FILTER_MODAL.M2M_ACC_ID': '机对机（M2M）账户ID',
	'FILTER_MODAL.M2M_PLATFORM': '机对机（M2M）平台',
	'FILTER_MODAL.FREQUENCY': '频率',
	'FILTER_MODAL.REPORT_NAME': '报告名称',
	'FILTER_MODAL.PLATFORM': '平台',
	'FILTER_MODAL.SERVICEPROVIDER': '平台',
	'FILTER_MODAL.REPORTFREQUENCY': '频率',
	//  标签
	'FILTER_MODAL.TABS_M2M_ACC_ID': '机对机（M2M）账户ID',
	'FILTER_MODAL.TABS_M2M_PLATFORM': '机对机（M2M）平台',
	'FILTER_MODAL.TABS_FREQUENCY': '频率',
	'FILTER_MODAL.TABS_REPORT_NAME': '报告名称',
	'FILTER_MODAL.TABS_PLATFORM': '平台',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': '平台',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': '频率',
	//  **  SIM ORDERING  **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': '状态',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': '机对机（M2M）平台',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': '储存单位（SKU）',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': '已加急',
	//  标签
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': '状态',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': '机对机（M2M）平台',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': '储存单位（SKU）',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': '已加急',
	//  **  SECURITY FEATURES **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': '机对机（M2M）账户',
	//  标签
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': '机对机（M2M）账户',
	//  **  TICKETING **
	'FILTER_MODAL.CATEGORIES': '类别',
	'FILTER_MODAL.STATUS': '状态',
	'FILTER_MODAL.SEVERITY': '严重性',
	//  标签
	'FILTER_MODAL.TABS_CATEGORIES': '类别',
	'FILTER_MODAL.TABS_STATUS': '状态',
	'FILTER_MODAL.TABS_SEVERITY': '严重性',
	//  **  INVOICE DETAILS **
	'FILTER_MODAL.CURRENCY': '币种',
	'FILTER_MODAL.CUSTOMER': '客户',
	'FILTER_MODAL.SUBSCRIBER_STATUS': '订户状态',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': '每月费率计划',
	'FILTER_MODAL.STANDARD_RATE_PLAN': '标准费率计划',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': '已收费的预付期',
	'FILTER_MODAL.RATE_PLAN': '费率计划',
	'FILTER_MODAL.PLAN_TYPE': '计划类型',
	'FILTER_MODAL.ZONE': '区',
	'FILTER_MODAL.PRIORITY': '优先级',
	'FILTER_MODAL.ROAMING_ZONE': '漫游区',
	'FILTER_MODAL.GROUP_NAME': '分组名称',
	'FILTER_MODAL.INVOICE_ID': '账单ID',
	'FILTER_MODAL.THE_PLAN_TYPE': '计划类型',
	'FILTER_MODAL.ACTIVATION_TYPE': '激活类型',
	'FILTER_MODAL.CHARGE_TYPE': '收费类型',
	'FILTER_MODAL.PRIMARY_PLACE': '主要位置',
	'FILTER_MODAL.TECH_TYPE': '技术类型',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': '主要使用位置',
	'FILTER_MODAL.STATE': '州',
	'FILTER_MODAL.CITY': '市',
	'FILTER_MODAL.DISTRICT_COUNTY': '区县',
	'FILTER_MODAL.DISTRICT_CITY': '区市',
	'FILTER_MODAL.DESCRIPTION': '描述',
	//  标签
	'FILTER_MODAL.TABS_CURRENCY': '币种',
	'FILTER_MODAL.TABS_CUSTOMER': '客户',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': '订户状态',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': '每月费率计划',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': '标准费率计划',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': '已收费的预付期',
	'FILTER_MODAL.TABS_RATE_PLAN': '费率计划',
	'FILTER_MODAL.TABS_PLAN_TYPE': '计划类型',
	'FILTER_MODAL.TABS_ZONE': '区',
	'FILTER_MODAL.TABS_PRIORITY': '优先级',
	'FILTER_MODAL.TABS_ROAMING_ZONE': '漫游区',
	'FILTER_MODAL.TABS_GROUP_NAME': '分组名称',
	'FILTER_MODAL.TABS_INVOICE_ID': '账单ID',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': '计划类型',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': '激活类型',
	'FILTER_MODAL.TABS_CHARGE_TYPE': '收费类型',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': '主要位置',
	'FILTER_MODAL.TABS_TECH_TYPE': '技术类型',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': '主要使用位置',
	'FILTER_MODAL.TABS_STATE': '州',
	'FILTER_MODAL.TABS_CITY': '市',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': '区县',
	'FILTER_MODAL.TABS_DISTRICT_CITY': '区市',
  'FILTER_MODAL.TABS_DESCRIPTION': '描述',
  //  **    AUTOMATION    **  //
  'FILTER_MODAL.PLATFORMS': '平台',
  'FILTER_MODAL.TRIGGERS': '触发器',
  'FILTER_MODAL.M2MACCOUNTS': '机对机（M2M）账户',
  'FILTER_MODAL.ACTIVE': '已激活',
  'FILTER_MODAL.STATUSES': '状态',
  //  标签
  'FILTER_MODAL.TABS_PLATFORMS': '平台',
  'FILTER_MODAL.TABS_TRIGGERS': '触发',
  'FILTER_MODAL.TABS_M2MACCOUNTS': '机对机（M2M）账户',
	'FILTER_MODAL.TABS_ACTIVE': '已激活',
	'FILTER_MODAL.TABS_USERGROUPS': '用户群组',
  'FILTER_MODAL.USERGROUPS': '用户群组',
  'FILTER_MODAL.TABS_STATUSES': '状态',
  // action bar
  'ACTION_BAR.PLATFORMS': '平台',
  'ACTION_BAR.TRIGGERS': '触发',
  'ACTION_BAR.M2MACCOUNTS': '机对机（M2M）账户',
  'ACTION_BAR.CURRENCY': '币种',
  'ACTION_BAR.ACTIVE': '已激活',
	'ACTION_BAR.CUSTOMER': '客户',
	'ACTION_BAR.SUBSCRIBER_STATUS': '订户状态',
	'ACTION_BAR.MONTHLY_RATE_PLAN': '每月费率计划',
	'ACTION_BAR.STANDARD_RATE_PLAN': '标准费率计划',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': '已收费的预付期',
	'ACTION_BAR.RATE_PLAN': '费率计划',
	'ACTION_BAR.PLAN_TYPE': '计划类型',
	'ACTION_BAR.ZONE': '区',
	'ACTION_BAR.PRIORITY': '优先级',
	'ACTION_BAR.ROAMING_ZONE': '漫游区',
	'ACTION_BAR.GROUP_NAME': '分组名称',
	'ACTION_BAR.INVOICE_ID': '账单ID',
	'ACTION_BAR.THE_PLAN_TYPE': '计划类型',
	'ACTION_BAR.ACTIVATION_TYPE': '激活类型',
	'ACTION_BAR.CHARGE_TYPE': '收费类型',
	'ACTION_BAR.PRIMARY_PLACE': '主要位置',
	'ACTION_BAR.TECH_TYPE': '技术类型',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': '主要使用位置',
	'ACTION_BAR.STATE': '州',
	'ACTION_BAR.CITY': '市',
	'ACTION_BAR.DISTRICT_COUNTY': '区县',
	'ACTION_BAR.DISTRICT_CITY': '区市',
	'ACTION_BAR.DESCRIPTION': '描述',
  'ACTION_BAR.USERGROUPS': '用户群组',
  'ACTION_BAR.STATUSES': '状态',
  // UPLOAD COMPONENT
	'UPLOAD_FILE.MAX_FILE_SIZE': '文件大小不得大于{size}MB。',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': '文件类型不正确',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': '仅可上载下列格式的文件：',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': '文件过大',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': '您试图上载的文件过大。文件大小不得大于',
  'UPLOAD_FILE.ALLOWED_FORMATS': '支持的文件格式：',
  'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': '只允许一个文件'
};
