import React from 'react';
import { object, func, array } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Carousel from 're-carousel';

import Button from '../../../../../lib/DigitalComponents/Button';
import {
	isUserAllowedToAccess,
} from '../../../../../utils/AuthSelector';
import IndicatorDots from '../../GlobecommTabs/Carousel/indicator-dots';
import hoc from '../Cards';

import styles from './Mobile.scss';

function Mobile({
	endpoint,
	goToTicketing,
	goToNewTicket,
	cards,
	user,
}) {
	const frames = [];

	cards.forEach((item, index) => {
		if (!item.hide) {
			frames.push(
				<div className={styles.card}>
					<span className={styles.title}>
						<FormattedMessage
							id={item.id}
							defaultMessage={item.defaultMessage}
						/>
					</span>
					<span
						className={cn({
							[styles.item]: true,
							[styles[`item_${endpoint.status
								? endpoint.status.toLowerCase()
								: ''}`]]: index === 2,
							[styles.item_connection_yes]: (
								index === 3 && endpoint.dataSession === '1'
							),
							[styles.item_connection_no]: (
								index === 3 && endpoint.dataSession === '0'
							),
						})}
					>
						{item.data}
					</span>
				</div>,
			);
		}
	});
	if (isUserAllowedToAccess(['mnc.ticketing_rw'], user)) {
		frames.push(
			<div className={styles.card}>
				<span className={styles.button_title}>
					<FormattedMessage
						id="NEED_HELP"
						defaultMessage="NEED HELP?"
					/>
				</span>
				<span>
					<Button
						variant="link"
						onClick={goToNewTicket}
						label={<FormattedMessage
							id="GET_HELP_WITH_THIS_ENDPOINT"
							defaultMessage="Get help with this endpoint"
						/>}
					/>
				</span>
			</div>
		);
	}
	if (isUserAllowedToAccess(['mnc.ticketing_ro'], user)) {
		frames.push(
			<div className={styles.card}>
				<span className={styles.button_title}>
					<FormattedMessage
						id="NEED_HELP"
						defaultMessage="NEED HELP?"
					/>
				</span>
				<span>
					<Button
						variant="link"
						onClick={goToTicketing}
						label={<FormattedMessage
							id="HELP_SUPPORT"
							defaultMessage="Help & Support"
						/>}
					/>
				</span>
			</div>
		);
	}

	return (
		<div data-spec="cards-desktop" className={styles.wrapper}>
			<Carousel
				frames={frames}
				widgets={[IndicatorDots]}
			/>
		</div>
	);
}

Mobile.propTypes = {
	endpoint: object,
	goToTicketing: func,
	goToNewTicket: func,
	cards: array,
	user: object,
};

Mobile.defaultProps = {
	endpoint: {},
	goToTicketing: undefined,
	goToNewTicket: undefined,
	cards: [],
	user: {},
};

export default hoc(Mobile);
