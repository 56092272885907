import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Loader from '../../../../lib/DigitalComponents/Loader';

import AccEntitlementDetails from './AccDetails';

import PageTitle from '../../../Shared/views/PageTitleView';
import AccountInfoOverview from '../AccountInfoOverview';

import styles from './NetworkEntitlementDetails.scss';

class NetworkEntitlementDetails extends PureComponent {
	componentDidMount() {
		this.getData();
	}

	componentDidUpdate(prevProps) {
    const { editEntitlementSuccess } = this.props;

    if (
			prevProps.editEntitlementSuccess !== editEntitlementSuccess &&
			editEntitlementSuccess
		) {
			this.getData();
		}
	}

	componentWillUnmount() {
		const { networkEntitlementInit, getAccountInit } = this.props;
		networkEntitlementInit();
		getAccountInit();
  }

  getData = () => {
    const {
			getNetworkEntitlement,
			match: {
				params: { companyId, entitlementId, m2mAccountId, accountId }
			}
		} = this.props;
		if (entitlementId && m2mAccountId) {
			getNetworkEntitlement({
				companyId,
				m2mAccountId,
				id: entitlementId
      });
		}
		if (accountId) {
			this.getAccount(accountId);
		}
  }

	getAccount = (id) => {
		const { getAccount } = this.props;
		getAccount({
			id,
			additionalParams: {
				include: [
					'Company',
					'PlatformType',
					'PlatformMobileOperator',
					'PlatformSatelliteOperator'
				]
			}
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId, accountId, platformType, m2mAccountId }
			}
		} = this.props;
		pushBack(
			`/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/network-entitlements`
		);
  };

  onSubmit = () => {
    const { onSubmit, hideNotification } = this.props;

    hideNotification('edit-network-entitlement-fail');
    onSubmit();
  }

	render() {
		const {
			entitlement,
			entitlementRequest,
			account,
			accountFail,
			accountRequest,
      editEntitlementRequest,
			match: {
				params: { companyId, m2mAccountId }
			}
    } = this.props;

		return (
			<div data-spec="network-entitlement">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.NETWORK_ENTITLEMENT_DETAILS"
							defaultMessage="Network Entitlement Details"
						/>
					}
					pushBack={this.pushBack}
				/>
				{entitlementRequest || accountRequest || editEntitlementRequest ? (
					<Loader data-spec="loader" />
				) : (
					<div className={styles.entitlement_details_wrapper}>
						{accountFail && !accountRequest && null}
						{account && !accountRequest && (
							<AccountInfoOverview
								account={account}
								title={account.company.companyFriendlyName}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE"
										defaultMessage="Link network entitlements enabled for this account AT&amp;T Control Center."
									/>
								}
							/>
						)}
						{!entitlementRequest && !editEntitlementRequest && entitlement && (
							<AccEntitlementDetails
								entitlement={entitlement}
								pushBack={this.pushBack}
								handleSubmit={this.onSubmit}
								m2mAccountId={m2mAccountId}
								companyId={companyId}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

const { object, any, func, bool } = PropTypes;

NetworkEntitlementDetails.propTypes = {
	match: any,
	pushBack: func,
  onSubmit: func,
  hideNotification: func,
	getNetworkEntitlement: func,
	entitlementRequest: bool,
	entitlement: object,
	networkEntitlementInit: func,
	getAccount: func,
	accountRequest: bool,
	accountFail: bool,
	account: object,
	getAccountInit: func,
  editEntitlementRequest: bool,
  editEntitlementSuccess: bool
};

export default connect()(NetworkEntitlementDetails);
