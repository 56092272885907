export default {
	// filtros',
CURRENCY: 'Divisa',
BILLABLE: 'Facturable',
TAX_STATUS: 'Estado de impuestos',
SUBSCRIBER_STATUS: 'Estado de suscriptor',
MONTHLY_RATE_PLAN: 'Plan de tarifas mensual',
STANDARD_RATE_PLAN: 'Plan de tarifas estándar',
PREPAID_TERMS_CHARGED: 'Términos de cargos de prepago',
PLAN_TYPE: 'Tipo de plan',
ZONE: 'Zona',
ROAMING_ZONE: 'Zona de roaming',
GROUP_NAME: 'Nombre del grupo',
THE_PLAN_TYPE: 'El tipo de plan',
ACTIVATION_TYPE: 'Tipo de activación',
CHARGE_TYPE: 'Tipo de cargo',
TECH_TYPE: 'Tipo de tecnología',
PRIMARY_PLACE: 'Lugar principal',
DISTRICT_COUNTY: 'Condado del distrito',
DISTRICT_CITY: 'Ciudad del distrito',
PRIMARY_PLACE_OF_USE: 'Lugar principal de uso',
// notificaciones
'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': 'Se actualizaron con éxito los parámetros de usuario',
'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'No se pudo actualizar los parámetros de usuario',
// 'Facturas',
'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'ID de cuenta',
'ANALYTICS_INVOICES.ADD_NOTE': 'Agregar una nota',
'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Agregar un adjunto',
'ANALYTICS_INVOICES.COUNTRY_NAME': 'País',
'ANALYTICS_INVOICES.CUSTOMER_ID': 'ID de cliente',
'ANALYTICS_INVOICES.INVOICE_DATE': 'Fecha de la factura',
'ANALYTICS_INVOICES.DOCUMENT_ID': 'ID de documento',
'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Cta. suscrita',
'ANALYTICS_INVOICES.NOTES': 'Notas',
'ANALYTICS_INVOICES.LINK': 'Facturas',
'ANALYTICS_INVOICES.BY': 'Por ',
'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Seleccione estadísticas para mostrar aquí',
'ANALYTICS_INVOICES.NA': 'N/C',
'ANALYTICS_INVOICES.YES': 'Sí',
'ANALYTICS_INVOICES.NO': 'No',
'ANALYTICS_INVOICES.INVOICE_ID': 'ID de factura',
'ANALYTICS_INVOICES.BILLING_CYCLE': 'Ciclo de facturación',
'ANALYTICS_INVOICES.ACCOUNT_ID': 'ID de cuenta',
'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Nombre de cuenta',
'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'ID de cuenta de operador',
'ANALYTICS_INVOICES.DEVICES': 'Equipos',
'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Cargo total',
'ANALYTICS_INVOICES.CURRENCY': 'Divisa',
'ANALYTICS_INVOICES.DATA_VOLUME': 'Volumen de datos (MB)',
'ANALYTICS_INVOICES.SMS_VOLUME': 'Volumen de SMS',
'ANALYTICS_INVOICES.BILLABLE': 'Facturable (Sí o No)',
'ANALYTICS_INVOICES.BILL': 'Facturable',
'ANALYTICS_INVOICES.DATA_RATES': 'Tarifas de datos',
'ANALYTICS_INVOICES.DATE': 'Fecha de la factura',
'ANALYTICS_INVOICES.BILLING_DETAILS': 'Detalles de facturación',
'ANALYTICS_INVOICES.DUE_DATE': 'Fecha de vencimiento',
'ANALYTICS_INVOICES.OPERATOR_ID': 'ID de operador',
'ANALYTICS_INVOICES.PUBLISHED': 'Publicada',
'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Uso adicional estándar',
'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Roaming estándar',
'ANALYTICS_INVOICES.TAXABLE': 'Cta. gravable',
'ANALYTICS_INVOICES.TAX_STATUS': 'Estado de impuestos',
'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Segmento de cuenta',
'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'Ver/Imprimir/Guardar',
'ANALYTICS_INVOICES.INVOICE_TITLE': 'Detalles de la factura',
'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Descargar factura',
'ANALYTICS_INVOICES.ENDPOINTS': 'Terminales',
'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Suscripciones prepagadas',
'ANALYTICS_INVOICES.ADDONS': 'Adicionales',
'ANALYTICS_INVOICES.RATE_PLANS': 'Planes de tarifas',
'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Zonas de plan de tarifas',
'ANALYTICS_INVOICES.BILLING_GROUPS': 'Grupos de facturación',
'ANALYTICS_INVOICES.DOWNLOAD': 'Descargar',
'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'Descargar PDF',
'ANALYTICS_INVOICES.UPLOAD': 'Cargar',
'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Cargar factura',
'ANALYTICS_INVOICES.ACTIVATIONS': 'Activaciones',
'ANALYTICS_INVOICES.DISCOUNTS': 'Descuentos',
'ANALYTICS_INVOICES.TAX': 'Impuestos',
'ANALYTICS_INVOICES.SURCHARGES': 'Recargos',
'ANALYTICS_INVOICES.TECH_TYPES': 'Tipos de tecnología',
'ANALYTICS_INVOICES.OTHER_CHARGES': 'Otros cargos',
'ANALYTICS_INVOICES.NO_DATA': 'No hay facturas disponibles',
'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': 'No hay facturas disponibles para mostrarle ahora',
'ANALYTICS_INVOICES.TITLE': 'Facturas',
'ANALYTICS_INVOICES.DATA_DETAILS': 'Detalles de datos',
'ANALYTICS_INVOICES.SMS_DETAILS': 'Detalles de SMS',
'ANALYTICS_INVOICES.TAXES': 'IMPUESTOS',
// tabla de terminales
'ANALYTICS_INVOICES.ICCID': 'ICCID',
'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Índice de prorrateo mensual',
'ANALYTICS_INVOICES.DEVICE_ID': 'ID de equipo',
'ANALYTICS_INVOICES.CUSTOMER': 'Cliente',
'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Plan de tarifas mensual',
'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Términos de cargos de prepago',
'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Plan de tarifas estándar',
'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'Estado de suscriptor',
'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Cargo de suscripción',
'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Cargo de grupo fijo',
'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'Volumen de SMS MO (msj.)',
'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'Volumen de SMS MT (msj.)',
// tabla de suscripciones prepagadas
'ANALYTICS_INVOICES.MSISDN': 'MSISDN',
'ANALYTICS_INVOICES.RATE_PLAN': 'Plan de tarifas',
'ANALYTICS_INVOICES.PLAN_VERSION': 'Versión de plan',
'ANALYTICS_INVOICES.PLAN_TYPE': 'Tipo de plan',
'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Índice de prorrateo de uso',
'ANALYTICS_INVOICES.TERM_START_DATE': 'Fecha de comienzo de plazo',
'ANALYTICS_INVOICES.TERM_END_DATE': 'Fecha de fin de plazo',
'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'Uso incluido de datos MB',
'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'SMS MO (msj.) incluido',
'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'SMS MT (msj.) incluido',
'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Voz (m:ss) incluido',
'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'Voz MO (m:ss) incluido',
'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'Voz MT (m:ss) incluido',
'ANALYTICS_INVOICES.INCLUDED_CSD': 'CSD (m:ss) incluido',
'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'CSD MO (m:ss) incluido',
'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'CSD MT (m:ss) incluido',
'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': 'Uso de datos en plazo hasta la fecha (Zona principal)',
'ANALYTICS_INVOICES.VOICE_VOLUME': 'Volumen de voz (m:ss)',
'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Volumen de voz MO (m:ss)',
'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Volumen de voz MT (m:ss)',
// Tabla de adicionales
'ANALYTICS_INVOICES.ADDON_SIZE': 'Tamaño adicional (MB)',
'ANALYTICS_INVOICES.ZONE': 'Zona',
'ANALYTICS_INVOICES.ADDON_CHARGES': 'Cargos adicionales',
'ANALYTICS_INVOICES.DATE_PURCHASED': 'Fecha de compra',
'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Plan básico aplicado a',
// Tabla de planes de tarifas
'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': 'Subs. en período de activación acordado',
'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Suscripciones activas',
'ANALYTICS_INVOICES.ACTIVE_TIER': 'Nivel activo',
'ANALYTICS_INVOICES.DATA_CHARGE': 'Cargo de datos',
'ANALYTICS_INVOICES.INCLUDED_DATA': 'Datos incluidos',
'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Modo de datos incluido',
'ANALYTICS_INVOICES.INCLUDED_SMS': 'SMS incluido',
'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Modo SMS MO incluido',
'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Modo SMS incluido',
'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Modo SMS MT incluido',
'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Modo de voz MO incluido',
'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Modo de voz incluido',
'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': 'Subs. en período de activación mínimo',
'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Cargo de datos sin roaming',
'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Volumen de datos sin roaming',
'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Tipo de pago',
'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Activaciones prepagadas',
'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Cargo de datos con roaming',
'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Volumen de datos con roaming',
'ANALYTICS_INVOICES.SMS_CHARGE': 'Cargo de SMS',
'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Campo de suscripción',
'ANALYTICS_INVOICES.VOICE_CHARGE': 'Cargo de voz',
// Tabla Zonas del plan de tarifas
'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Tarifa de uso adicional activa',
'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Datos limitados incluidos',
'ANALYTICS_INVOICES.INCLUDED_MODE': 'Modo incluido',
'ANALYTICS_INVOICES.PRIORITY': 'Prioridad',
'ANALYTICS_INVOICES.ROAMING_ZONE': 'Zona de roaming',
'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Uso (MB) total incluido',
//  Tabla Grupos de facturación
'ANALYTICS_INVOICES.GROUP_NAME': 'Nombre del grupo',
'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Total incluido',
'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'Cargo de SMS MO',
'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'SMS MO (msj.) incluido',
'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': 'SMS MO total incluido',
'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': 'Voz MO total incluido',
// Tabla de detalles de datos
'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Ajuste de redondeo diario',
'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'El tipo de plan',
// Tabla de detalles de SMS
'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Tipo de mensaje',
// Tabla de activaciones
'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Cargo de activación.',
'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Tipo de activación',
'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Fecha de activación inicial',
// Tabla de descuentos
'ANALYTICS_INVOICES.CHARGE_TYPE': 'Tipo de cargo',
'ANALYTICS_INVOICES.DISCOUNT': 'Descuento',
// Tabla de impuestos
'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Lugar principal',
'ANALYTICS_INVOICES.MRC': 'MRC',
'ANALYTICS_INVOICES.DATA': 'Datos',
'ANALYTICS_INVOICES.SMS': 'SMS',
'ANALYTICS_INVOICES.VOICE': 'VOZ',
'ANALYTICS_INVOICES.ACTIVATION': 'Activación',
'ANALYTICS_INVOICES.VPN': 'VPN',
'ANALYTICS_INVOICES.SIM': 'SIM',
'ANALYTICS_INVOICES.MRF': 'MRF',
'ANALYTICS_INVOICES.PDP': 'PDP',
'ANALYTICS_INVOICES.TECH_TYPE': 'Tipo de tecnología',
// Tabla de recargos
'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Lugar principal de uso',
'ANALYTICS_INVOICES.FEDERAL': 'Federal',
'ANALYTICS_INVOICES.STATE': 'Estado',
'ANALYTICS_INVOICES.COUNTY': 'Condado',
'ANALYTICS_INVOICES.CITY': 'Ciudad',
'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'Condado del distrito',
'ANALYTICS_INVOICES.DISTRICT_CITY': 'Ciudad del distrito',
// Tabla Tipos de tecnología
'ANALYTICS_INVOICES.PLAN_ID': 'ID de plan',
'ANALYTICS_INVOICES.VERSION_ID': 'ID de versión',
'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'Cant. de equipos 2G',
'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': 'Cargo por tipo de tecnología 2G',
'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'Cant. de equipos 3G',
'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': 'Cargo por tipo de tecnología 3G',
'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'Cant. de equipos 4G',
'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': 'Cargo por tipo de tecnología 4G',
'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Frecuencia de cargo por tipo de tecnología',
'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Cargo total por tipo de tecnología',

// 'Cargos de suscripción',
'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Cargos de suscripción',
'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Cargo de suscripción',
'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Cargo de datos',
'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'Cargos de SMS',
'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Cargo de activación.',
'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Cargo por tipo de tecnología',
'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Descuento fijo objetivo',
'ANALYTICS_INVOICES_DETAILS.TAX': 'Impuestos',
'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Cargo total',
'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Recargo',
'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Vence el ',

// 'Estadísticas de terminales',
'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Estadísticas de terminales',
'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'TERMINALES',
'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'SUSCRIPTORES ACTIVOS',
'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'VOLUMEN DE DATOS',
'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'VOLUMEN DE SMS',
'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'INGRESOS POR SERVICIOS',
'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'DESCUENTO VARIABLE',

// Tabla Otros cargos
'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Importe de cargo',
'ANALYTICS_INVOICES.DESCRIPTION': 'Descripción',
'ANALYTICS_INVOICES.QUANTITY': 'Cantidad',
'ANALYTICS_INVOICES.ORDER_NUMBER': 'Número de pedido',
'ANALYTICS_INVOICES.REFERENCE_ID': 'ID de referencia',
//  marcadores
'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Ingrese su nota aquí',
'ANALYTICS_INVOICES.REMOVE': 'Eliminar',
'ANALYTICS_INVOICES.EDIT_NOTE': 'Editar nota',
'ANALYTICS_INVOICES.CANCEL': 'Cancelar',
'ANALYTICS_INVOICES.SUBMIT': 'Enviar',
'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Adjuntos agregados',
//  editar modal de tabla
'ANALYTICS_INVOICES.APPLY_CHANGE': 'Aplicar cambio',
'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Restablecer a predeterminado',
'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Editar parámetros de tabla',
'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': 'Seleccionar un mín. de 2 para aplicar a la tabla. ',
'ANALYTICS_INVOICES.OF': ' de ',
'ANALYTICS_INVOICES.SELECTED': ' seleccionado',
// 'Detalles de la factura',
'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'No hay datos disponibles',
'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': 'No hay datos para esta factura',
'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': 'No hay datos de esta factura en la base de datos de detalles de la factura',
'ANALYTICS_INVOICES.ADD_FILES': 'Archivos de facturas',
'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'Ver/Agregar/Editar',
//  MODAL DE ADJUNTOS
'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Adjuntos de la factura',
'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Editar adjunto',
'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Cargar un archivo',
'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Borrar adjunto',
'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Cerrar',
'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Cancelar',
'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Guardar',
'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Borrar',
'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Cargar archivo',
'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Cargar adjunto',
'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Cargar factura',
'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'Nombre de archivo',
'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Descripción',
'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': 'Está a punto de eliminar un adjunto de MC. Si lo hace, se eliminará permanentemente del sistema. ¿Seguro que desea ejecutar esta tarea?',
'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'Archivo a borrar:',
'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'Archivo adjunto',
'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': 'Nombre de archivo (Si lo deja en blanco se utilizará el nombre predeterminado)',
'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': 'La descripción del archivo solo es visible para el cliente (opcional)',
'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': 'Está factura estará activa en unas semanas.',
'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': 'Arrastre o haga clic en el archivo para cargarlo',
'INVOICES_NOTES_UPDATE.SUCCESS': 'La nota de la factura se actualizó con éxito',
//  MODAL DE NOTAS
'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'La actualización tuvo éxito',
'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': 'La nota se agregó con éxito.',
'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Falló la actualización',
'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': 'La nota se agregó con éxito.',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'La carga tuvo éxito',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'El archivo se cargó con éxito.',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Error de carga',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Se produjo un error al cargar el archivo a MC.',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': 'Adjunto borrado',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': 'Se eliminó el archivo de MC.',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': 'Error al borrar',
'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': 'Se produjo un error al eliminar el archivo de MC.',
'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Fecha de la factura',
'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': 'El ciclo de facturación no es válido y por lo tanto, no se pueden mostrar detalles de la factura.',
// AGREGAR FACTURA NUEVA
'INVOICES.ADD_NEW_INVOICE': 'Agregar factura nueva',
'INVOICES.CANCEL': 'Cancelar',
'INVOICES.ADD_INVOICE': 'Agregar factura',
'INVOICES.EDIT': 'Editar',
'INVOICES.CONFIRM': 'Confirmar',
'INVOICES.CONFIRMATION': 'Confirmación',
'INVOICES.INVOICE_DETAILS': 'Detalles de la factura',
'ADD_INVOICE.INVOICE_ID': 'ID de factura',
'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
'ADD_INVOICE.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'Seleccionar ID de cuenta M2M',
'ADD_INVOICE.INVOICE_DATE': 'Fecha de la factura',
'ADD_INVOICE.BILLING_CYCLE': 'Ciclo de facturación',
'ADD_INVOICE.DATE_PLACEHOLDER': 'SELECCIONAR FECHA',
'ADD_INVOICE.UPLOAD_FILE': 'Cargar un archivo',
'ADD_INVOICE.UPLOAD_FILE_NAME': 'Nombre de archivo (Opcional: (Si lo deja en blanco se utilizará el nombre predeterminado)',
'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': 'Nombre de archivo (máx. 255 caracteres)',
'ADD_INVOICE.OVERVIEW_FILE_NAME': 'Nombre de archivo',
'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'La nueva factura se agregó con éxito.',
'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Se produjo un error al agregar la factura. Inténtelo nuevamente.',
'ADD_INVOICE.TIME_STAMP': 'Conectado el {date} a las {time}',
'INVOICES.UPLOAD_FILE_NAME_VALIDATION': 'El nombre de archivo no es válido (por favor, utilice _ en vez del espacio en blanco, y no puede utilizar los caracteres ’ ! ? <> % $ () + =)',
};
