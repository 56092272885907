import React from 'react';

function Node() {
	return (
		<div data-spec="node-asset">
			<pre>
				{`
          var request = require("request");

          var options = { method: 'POST',
            url: 'https://cisiot.att.com/EAI/oauth/token',
            headers:
            {
              Authorization: 'Basic YOUR_ENCODED_CREDENTIALS',
              'Content-Type': 'application/x-www-form-urlencoded' },
            form:
            { username: 'USERNAME',
              password: 'PASSWORD',
              grant_type: 'password' } };

          request(options, function (error, response, body) {
            if (error) throw new Error(error);

            console.log(body);
          });
        `}
			</pre>
		</div>
	);
}

export default Node;
