import { connect } from 'react-redux';

import SimStateChangeStepper from './SimStateChangeStepper';
import {
	isSingleEndpointUpdating,
	singleEndpointUpdateFail,
	singleEndpointUpdateSuccess,
} from '../../redux/selectors';
import {
	updateSingleEndpoint,
} from '../../redux/actions';
import { getAvailableStatesData } from '../../../Endpoint/redux/selectors';

const mapStateToProps = (state) => (
	{
		isUpdating: isSingleEndpointUpdating(state),
		isSuccess: singleEndpointUpdateSuccess(state),
		isFail: singleEndpointUpdateFail(state),
		availableStates: getAvailableStatesData(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		singleEndpointUpdate: (data) => dispatch(updateSingleEndpoint(data)),
	}
);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SimStateChangeStepper);
