import { createAction } from 'redux-actions';

import globalAction from '../../../../redux/global/globalAction';
import {
	showNotification,
	hideNotification
} from '../../../Shared/redux/notifications/actions';

import {
	GET_ANALYTICS_INVOICES,
	GET_ANALYTICS_INVOICES_FILTERS,
	GET_INVOICE_DETAILS,
	EXPORT_INVOICE,
	GET_INVOICE_ATTACHMENTS,
	GET_INVOICES,
	GET_FILTERS,
	POST_INVOICE_INIT,
	POST_INVOICE_REQUEST,
	POST_INVOICE_FAIL,
	POST_INVOICE_SUCCESS,
	PATCH_INVOICE_INIT,
	PATCH_INVOICE_REQUEST,
	PATCH_INVOICE_FAIL,
	PATCH_INVOICE_SUCCESS,
	EXPORT_INVOICE_DETAILS,
	INVOICES_GET_M2M_ACCOUNTS,
	GET_INVOICE_DETAILS_INIT
} from './constants';

import {
	getAnalyticsInvoices as getAnalyticsInvoicesService,
	getInvoiceDetails as getInvoiceDetailsService,
	getAnalyticsInvoicesFilters as getAnalyticsInvoicesFiltersService,
	exportInvoice as exportInvoiceService,
	getInvoiceAttachments as getInvoiceAttachmentsService,
	getInvoices as getInvoicesService,
	getFilters as getFiltersService,
	invoiceAttachmentPollForFile as invoiceAttachmentPollForFileService,
	exportInvoiceDetails as exportInvoiceDetailsService,
	initiateInvoiceChange as initiateInvoiceChangeService,
	patchInvoice as patchInvoiceService,
	getM2mAccounts as getM2mAccountsService
} from '../../services/AnalyticsInvoicesService';

//  PATCH INVOICE
const patchInvoiceInitAction = createAction(PATCH_INVOICE_INIT);
const patchInvoiceRequest = createAction(PATCH_INVOICE_REQUEST);
const patchInvoiceFail = createAction(PATCH_INVOICE_FAIL);
const patchInvoiceSuccess = createAction(PATCH_INVOICE_SUCCESS);

//  POST INVOICE
const postInvoiceInitAction = createAction(POST_INVOICE_INIT);
const postInvoiceRequest = createAction(POST_INVOICE_REQUEST);
const postInvoiceFail = createAction(POST_INVOICE_FAIL);
const postInvoiceSuccess = createAction(POST_INVOICE_SUCCESS);

const getInvoiceDetailsInitAction = createAction(GET_INVOICE_DETAILS_INIT);

export const getAnalyticsInvoices = (params = {}) =>
	globalAction(GET_ANALYTICS_INVOICES, getAnalyticsInvoicesService, params);

export const getInvoices = (params = {}) =>
	globalAction(GET_INVOICES, getInvoicesService, params);

export const getFilters = () => globalAction(GET_FILTERS, getFiltersService);

/** ************ get invoices filters ************* */
export const getInvoicesFilters = (params = {}) =>
	globalAction(
		GET_ANALYTICS_INVOICES_FILTERS,
		getAnalyticsInvoicesFiltersService,
		params
	);

/** ************ get invoice details ************* */
export const getInvoiceDetails = (params) =>
	globalAction(GET_INVOICE_DETAILS, getInvoiceDetailsService, params);

export const getInvoiceDetailsInit = () => async (dispatch) => {
	dispatch(getInvoiceDetailsInitAction());
};

/** ************ export invoice ************* */
export const exportInvoice = (id) =>
	globalAction(EXPORT_INVOICE, exportInvoiceService, id);

/** ************ get invoice attachments ************* */
export const getInvoiceAttachments = (id) =>
	globalAction(GET_INVOICE_ATTACHMENTS, getInvoiceAttachmentsService, id);

// export const postInvoice = (id, data) =>
// 	globalAction(POST_INVOICE, postInvoiceService, { id, data });

export const exportInvoiceDetails = (id, params) =>
	globalAction(EXPORT_INVOICE_DETAILS, exportInvoiceDetailsService, {
		id,
		params
	});

export const postInvoiceAttachment = (
	id,
	requestUploadData,
	notifOptions = {}
) => async (dispatch) => {
	dispatch(postInvoiceInitAction());
	try {
		const data = {
			requestUploadData
		};
		dispatch(postInvoiceRequest());
		const file = await initiateInvoiceChangeService(id, data);
		const response = await invoiceAttachmentPollForFileService(
			file.id,
			200,
			4000
		);
		dispatch(postInvoiceSuccess({ data: response }));
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.successMessage],
					type: 'success',
					title: notifOptions.successTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
	} catch (error) {
		dispatch(postInvoiceFail(error));
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.errorMessage],
					type: 'error',
					title: notifOptions.errorTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
	}
};

/** ************ patch invoice note ************* */
export const updateInvoiceAttachment = (
	id,
	requestUploadData,
	notifOptions = {}
) => async (dispatch) => {
	dispatch(patchInvoiceInitAction());
	try {
		const data = {
			requestUploadData
		};
		dispatch(patchInvoiceRequest());
		const file = await initiateInvoiceChangeService(id, data);
		const response = await invoiceAttachmentPollForFileService(
			file.id,
			200,
			4000
		);
		dispatch(patchInvoiceSuccess(response));
		// show notif success
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.successMessage],
					type: 'success',
					title: notifOptions.successTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
	} catch (error) {
		dispatch(patchInvoiceFail(error));
		// error notification
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.errorMessage],
					type: 'error',
					title: notifOptions.errorTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
		// show notif error
	}
};

/** ************ patch invoice note ************* */
export const updateInvoice = (id, data, notifOptions = {}) => async (
	dispatch
) => {
	dispatch(patchInvoiceInitAction());
	try {
		const params = { id, data };
		dispatch(patchInvoiceRequest());
		const request = await patchInvoiceService(params);
		dispatch(patchInvoiceSuccess(request));
		// show notif success
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.successMessage],
					type: 'success',
					title: notifOptions.successTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
	} catch (error) {
		dispatch(patchInvoiceFail(error));
		// error notification
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.errorMessage],
					type: 'error',
					title: notifOptions.errorTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
		// show notif error
	}
};
// globalAction(
// 	PATCH_INVOICE,
// 	patchInvoiceService,
// 	{
// 		id,
// 		data
// 	},
// 	{
// 		notifySuccess: 'update-invoice',
// 		notifyFail: 'update-invoice',
// 		notifyMessageSuccess: notifMessages && notifMessages.success,
// 		notifyMessageError: notifMessages && notifMessages.error
// 	}
// );

export const getM2mAccounts = () =>
	globalAction(INVOICES_GET_M2M_ACCOUNTS, getM2mAccountsService);
