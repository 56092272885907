import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './BatchFileUpload.scss';

import { downloadHelper } from '../../../AnalyticsInvoices/redux/invoiceAttachment/actions';

const Upload = ImageSelector(CurrentContext.theme, 'svgs/upload.svg');

const fileDateFormat = 'MM-dd-yyyy_HH-mm-ss';

class BatchFileUpload extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			dropZoneMessage: null
		};
	}

	componentDidMount() {
		const { id, getUploadTemplate } = this.props;
		const params = {
			customerSecurityFeatureTypeId: id
		};
		getUploadTemplate(params);
	}

	componentDidUpdate(prevProps) {
		const { template, uploadTemplate } = this.props;
		if (template && prevProps.template !== template) {
			downloadHelper(false, uploadTemplate.fileName, template);
		}
	}

	onDropRejected = (rejected) => {
		const { fileUploadFail } = this.props;
		if (rejected) {
			const error = {
				error: [{}]
			};
			fileUploadFail(error);
		}
	};

	onDrop = (accepted, rejected) => {
		const { onClick } = this.props;
		if (rejected.length > 0) {
			this.setState({
				dropZoneMessage: (
					<FormattedMessage
						id="UNSUPPORTED_FILE_TYPE"
						defaultMessage="Unsupported file type"
					/>
				)
			});
		} else {
			const fileExtension = '.csv';
			const fileName = `${accepted[0].name.substring(
				0,
				accepted[0].name.lastIndexOf('.')
			)}_${format(new Date(), fileDateFormat)}${fileExtension}`;

			onClick({
				files: accepted,
				fileName
			});
		}
	};

	downloadTemplate = () => {
		const { downloadTemplate, uploadTemplate } = this.props;
		const params = {
			fileName: uploadTemplate.fileName
		};
		downloadTemplate(params);
	};

	render() {
		const { dropZoneMessage } = this.state;
		const {
			uploadTemplateRequest,
			uploadTemplate,
			downloadTemplateRequest
		} = this.props;
		const isAnyRequest = uploadTemplateRequest || downloadTemplateRequest;
		return (
			<div data-spec="batch-file-upload">
				{isAnyRequest && <Loader />}
				{!isAnyRequest && (
					<div>
						<div className={styles.selected_action_note}>
							<FormattedMessage
								id="SECURITY_FEATURES.UPLOAD_FILE_NOTE"
								defaultMessage="Upload files here for selected batch action:"
							/>
						</div>
						<Dropzone
							className={cn(styles.dropzone, {
								[`${styles.error}`]: dropZoneMessage !== null
							})}
							accept=".csv"
							onDropRejected={this.onDropRejected}
							onDrop={this.onDrop}
							disablePreview
							multiple={false}
						>
							<div>
								<Upload className={styles.upload_icon} />
							</div>
							<div className={styles.text}>
								{dropZoneMessage}
								<br />
								<FormattedMessage
									id="SECURITY_FEATURES.DRAG_FILE_OR_CLICK"
									defaultMessage="Drag file or click to upload"
								/>
							</div>
						</Dropzone>
						{uploadTemplate && uploadTemplate.fileName && (
							<div className={styles.restrictions}>
								<div>
									<FormattedMessage
										id="SECURITY_FEATURES.DOWNLOAD_TEMPLATE"
										defaultMessage="Download template"
									/>
                  {' '}
									<span
										className={styles.template__url}
										onClick={() => this.downloadTemplate()}
									>
										{uploadTemplate.fileName}
									</span>
								</div>
								<p>
									<FormattedMessage
										id="SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED"
										defaultMessage="Files must be submitted with .csv extension."
									/>
								</p>
								<p>
									<FormattedMessage
										id="SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD"
										defaultMessage="Maximum Security Features per upload is "
									/>
									<span>1000</span>
								</p>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

const { func, number, bool, object, any } = PropTypes;

BatchFileUpload.propTypes = {
	onClick: func,
	fileUploadFail: func,
	getUploadTemplate: func,
	id: number,
	uploadTemplateRequest: bool,
	uploadTemplate: object,
	downloadTemplate: func,
	downloadTemplateRequest: bool,
	template: any
};

export default BatchFileUpload;
