import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flattenDeep from 'lodash';
import { injectIntl } from 'react-intl';
import store from 'redux/store';

import Eula from '../../components/Eula/components/Eula';
import ErrorBoundary from '../../components/Shared/components/ErrorBoundary';
import menu from '../../utils/menu';
import { isUserAllowedToAccess } from '../../utils/AuthSelector';
import AuthService from '../../services/AuthService';
import { findUrlLanguage, setLanguageCode } from '../../utils/helperFunctions';

const MainLayoutWrapper = () => (WrapperComponent) => {
	class MainLayout extends Component {
		constructor(props) {
			super(props);
			this.time = 120 * 60 * 1000;
			this.timer = null;
		}

		componentDidMount() {
			const {
				user,
				setCompanyServiceInit,
				setCompanyFriendlyNameInit
			} = this.props;
			if (user.systemUser && !sessionStorage.getItem('company')) {
				setCompanyServiceInit();
				setCompanyFriendlyNameInit();
			}
			this.setLogoutTime();
    }

		setLogoutTime = () => {
			clearTimeout(this.timer);
			this.timer = setTimeout(function () {
				const authService = new AuthService();
				authService.logout();
			}, this.time);
		};

		resetFilterPaginationSearchData = async () => {
			const { setDataForFiltersInit } = this.props;
			await setDataForFiltersInit();
		};

		updateSessionLanguage = () => {
			const { user } = this.props;
			const { supportedLanguages } = menu;
			const urlLanguage = findUrlLanguage();
			let sessionLanguage =
				sessionStorage.getItem('language') ||
				user.language.toLowerCase() ||
        'en-us';

			if (
				urlLanguage ||
				(urlLanguage &&
					sessionLanguage &&
					urlLanguage.toLowerCase() !== sessionLanguage.toLowerCase())
			) {
				sessionStorage.setItem('language', urlLanguage);
				sessionLanguage = urlLanguage;
			} else if (!urlLanguage) {
				setLanguageCode(sessionLanguage.toLowerCase());
			}

			return (
				sessionLanguage &&
				supportedLanguages.find(
					(item) => item.code.toLowerCase() === sessionLanguage.toLowerCase()
				)
			);
		};

		render() {
			const { attMainMenu, mainMenu, profileMenu, supportedLanguages } = menu;
			const {
				companyAccountId,
				messages,
				user,
				isCollapsed,
				isEulaFail,
				location,
				children,
				companyFriendlyName,
				withoutSidebar,
				eula,
        intl,
        language
			} = this.props;
      store.subscribe(this.setLogoutTime);

      const selectedLanguage = this.updateSessionLanguage();
      const locale = (language && language.toLowerCase()) || 'en-us';
			const clientMenu = companyAccountId
				? mainMenu(locale)
				: attMainMenu(locale);
			const notSupportedSidebar = flattenDeep(
				clientMenu.map((menuItem) =>
					(menuItem.submenu ? menuItem.submenu : menuItem))
			).find((menuItem) => menuItem.link === location.pathname);
			const showContentAboveTopBar =
				companyAccountId.length > 0 &&
				isUserAllowedToAccess(
					['system.companies_rw', 'system.companies_ro'],
					user
				);
			const eulaList = JSON.parse(eula);
			const links = [
				{
					url: 'http://about.att.com/sites/privacy_policy',
					name: intl.formatMessage({
						id: 'PRIVACY_POLICY',
						defaultMessage: 'Privacy Policy'
					})
				},
				{
					url: 'https://www.att.com/legal/terms.attWebsiteTermsOfUse.html',
					name: intl.formatMessage({
						id: 'TERMS_OF_USE',
						defaultMessage: 'Terms Of Use'
					})
				},
				{
					url:
						'http://about.att.com/sites/privacy_policy/full_privacy_policy#obc',
					name: intl.formatMessage({
						id: 'ADVERTISING_CHOICES',
						defaultMessage: 'Advertising Choices'
					})
				}
			];

			const userInitials = `${user.firstName} ${user.lastName
				.charAt(0)
				.toUpperCase()}.`;

			return (
				<ErrorBoundary data-spec="main-layout" environment="layout">
					{(!eulaList || !eulaList.length || eulaList[0].value !== 'true') &&
					!isEulaFail &&
					!(user.systemUser && !sessionStorage.getItem('company')) ? (
						<Eula />
					) : (
						<WrapperComponent
							clientMenu={clientMenu}
							messages={messages}
							user={user}
							profileMenu={profileMenu(locale)}
							supportedLanguages={supportedLanguages}
							selectedLanguage={selectedLanguage}
							companyFriendlyName={companyFriendlyName}
							companyAccountId={companyAccountId}
							isCollapsed={isCollapsed}
							location={location}
							eula={eulaList}
							isEulaFail={isEulaFail}
							withoutSidebar={withoutSidebar}
							notSupportedSidebar={!!notSupportedSidebar}
							showContentAboveTopBar={showContentAboveTopBar}
							links={links}
							intl={intl}
							resetFilterPaginationSearchData={
								this.resetFilterPaginationSearchData
							}
							userInitials={userInitials}
						>
							{children}
						</WrapperComponent>
					)}
				</ErrorBoundary>
			);
		}
	}

	const {
		object,
		bool,
		array,
		string,
		func,
		objectOf,
		element,
		oneOfType,
	} = PropTypes;

	MainLayout.propTypes = {
		children: element,
		isCollapsed: bool,
		companyAccountId: string,
		eula: string,
		isEulaFail: bool,
		user: oneOfType([array, object, string]),
		messages: objectOf(string),
		location: objectOf(string),
		setCompanyServiceInit: func,
		setCompanyFriendlyNameInit: func,
		companyFriendlyName: string,
		withoutSidebar: bool,
		intl: object,
    setDataForFiltersInit: func,
    language: string
	};

	MainLayout.defaultProps = {
		children: null,
		isCollapsed: false,
		companyAccountId: '',
		eula: '[]',
		isEulaFail: false,
		user: {},
		messages: {},
		location: {},
		setCompanyServiceInit: undefined,
		setCompanyFriendlyNameInit: undefined,
		companyFriendlyName: '',
		withoutSidebar: false,
    intl: {}
	};

	return injectIntl(MainLayout);
};

export default MainLayoutWrapper;
