export default {
	'ERRORS.ERROR': 'Error',
	'ERRORS.FORBIDDEN_ACCESS': 'Acceso prohibido',
	'ERRORS.RETURN_TO_HOME_PAGE': 'VOLVER A PÁGINA DE INICIO',
	'ERRORS.PAGE_NOT_FOUND': 'No podemos encontrar la página que busca.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Error interno del servidor',
	'ERRORS.REQUEST_TIMED_OUT': 'La solicitud superó el tiempo límite',
	'ERRORS.LOGOUT': 'Cerrar sesión',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': '¡Vaya! Parece que no tiene permiso para entrar a esta aplicación.',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': 'Si cree que debería tener acceso, póngase en contacto con el administrador de su empresa.'
};
