export default {
	'TICKETING.YES': '是',
	'TICKETING.NO': '否',
	'TICKETING.VIEW_NOTE': '查看注释',
	'TICKETING.TICKET_ID': '通知单ID',
	'TICKETING.CATEGORY_NAME': '类别',
	'TICKETING.DATE_CREATED': '创建日期',
	'TICKETING.CREATED_BY': '创建者',
	'TICKETING.TICKETING_STATUS': '通知单状态',
	'TICKETING.LAST_UPDATE': '最后一次更新',
	'TICKETING.SEVERITY': '严重性',
	'TICKETING.SUBMISSION_LOCATION': '提交地点',
	'TICKETING.UPDATE_NOTE': '更新注释',
	'TICKETING.STATUS': '状态',
	'TICKETING.SUBMITED_BY': '提交者',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': '您的通知单与具体的端点有关吗？如果是，请进入该端点详情屏幕，点击该端点的“获得支持”链接。否则，请填写下面的表单。',
	'TICKETING.ISSUE': '问题',
	'TICKETING.TICKET_DETAILS': '通知单详情',
	'TICKETING.CATEGORY': '类别',
	'TICKETING.SELECT_CATEGORY': '选择类别',
	'TICKETING.SUBCATEGORY_OPTIONAL': '子类（选填）',
	'TICKETING.SUBCATEGORY': '子类',
	'TICKETING.ROAMING_ISSUE': '漫游问题',
	'TICKETING.PRIORITY': '优先级',
	'TICKETING.ENDPOINT': '端点',
	'TICKETING.ICCID': '集成线路卡识别符（ICCID）',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': '移动台国际订户目录号（MSISDN）',
	'TICKETING.ENDPOINT_LOCATION': '端点所在地点',
	'TICKETING.ENDPOINT_COUNTRY': '端点所在国家',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': '清空端点参数',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': '获取端点参数',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': '您可以用此选项，根据集成线路卡识别符（ICCID）、IMSI或移动台国际订户目录号（MSISDN）值获取端点参数。',
	'TICKETING.ENDPOINT_STREET': '端点所在街道',
	'TICKETING.ENDPOINT_STREET_ADDRESS': '端点所在街道地址',
	'TICKETING.ZIP_CODE': '邮政编码',
	'TICKETING.ENDPOINT_STATE': '端点状态',
	'TICKETING.DETAILS': '详情',
	'TICKETING.BARS_SHOWN': '显示的条形图',
	'TICKETING.ENDPOINT_CITY': '端点所在城市',
	'TICKETING.ENDPOINT_MODEL': '端点型号',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': '已经完成故障排除了吗？',
	'TICKETING.PROBLEM_DESCRIPTION': '问题描述',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': '请简短描述您的问题，不要超过255个字符',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': '如有必要，请提供其他问题详情，不要超过2000个字符',
	'TICKETING.GPRS': '通用分组无线业务（GPRS）',
	'TICKETING.NOTES': '注释',
	'TICKETING.ISSUE_DETAILS': '问题详情',
	'TICKETING.CUSTOMER': '客户',
	'TICKETING.ACCOUNT_NUMBER': '账号',
	'TICKETING.ACCOUNT_NAME': '账户名称',
	'TICKETING.PHONE': '电话',
	'TICKETING.CONTACT_PHONE': '联系人电话',
	'TICKETING.CONTACT_EMAIL': '联系人电子邮件',
	'TICKETING.ATTACH_FILE': '附件',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': '文件名太长，无法上载。文件名长度上限为25个字符。',
	'TICKETING.EMAIL': '电子邮件',
	'TICKETING.TICKETING': '通知单',
	'TICKETING.ADD_TICKET': '添加通知单',
	'TICKETING.SEARCH_RESULTS': '搜索结果',
	'TICKETING.NO_TICKETS_AVAILABLE': '无可用的通知单',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': '已成功更改通知单表格设置！',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': '已成功更新通知单表格设置。',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': '更改通知单表格列时出错！',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': '目前无法更新通知单表格列。请稍后重试。',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': '已成功编辑通知单。',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': '编辑通知单时出错。请稍后重试。',
	'TICKETING.ATTACH_FILE_TOOLTIP': '文件名中不可包含空格。',
	'TICKETING.NEW_TICKET': '新通知单',
	'TICKETING.OFF': '关闭',
	'TICKETING.ON': '开启',
	'TICKETING.EMAIL_NOTIFICATIONS': '电子邮件通知',
	'TICKETING.CONTACT_NAME': '联系人姓名',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': '最后一次更新执行者',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': '最后一次更新时间/日期',
	'TICKETING.SOMETHING_WENT_WRONG': '出错，请重试。',
	'TICKETING.CANCEL_TICKET': '取消通知单',
	'TICKETING.ADD_NOTE': '添加注释',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': '已成功提交通知单！',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': '无法成功提交通知单！',
	'TICKETING.CREATED_AT': '创建时间',
	'TICKETING.GO_BACK_TO_TICKETING': '返回通知单',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': '非技术性账单问题',
	'TICKETING.CATEGORY_PROVISIONING': '配给',
	'TICKETING.CATEGORY_DATA': '数据',
	'TICKETING.CATEGORY_DOCUMENTATION': '文档',
	'TICKETING.CATEGORY_DUPLICATE': '复制',
	'TICKETING.CATEGORY_BILLING': '账单',
	'TICKETING.CATEGORY_NOTIFICATION': '通知',
	'TICKETING.CATEGORY_OTHER': '其他',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': '产品增强请求',
	'TICKETING.CATEGORY_PROJECT': '项目',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': '销售/营销问题',
	'TICKETING.CATEGORY_QUESTION': '问题',
	'TICKETING.CATEGORY_SERVICE_REQUEST': '服务请求',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': '技术问题',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': '用户/账户管理',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': '连接问题',
	'TICKETING.CATEGORY_EQUIPMENT': '设备',
	'TICKETING.CATEGORY_GENERAL_QUESTION': '一般问题',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': '设备遗失或被盗',
	'TICKETING.CATEGORY_PORT_REQUEST': '请求端口',
	'TICKETING.CATEGORY_PRODUCT_RELATED': '相关产品',
	'TICKETING.CATEGORY_REPORTING': '报告',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': '意见与建议',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': '设备问题',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': '排队',
	'TICKETING.STATUS_ACTIVE': '使用中',
	'TICKETING.STATUS_DEFERRED': '延期',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': '等待客户回应',
	'TICKETING.STATUS_CLOSED': '关闭',
	'TICKETING.STATUS_CANCEL': '取消',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': '客户提出争议',
	'TICKETING.STATUS_CLEARED': '已解决',
	'TICKETING.STATUS_READY_TO_CLOSE': '准备关闭',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': '等待ATT响应',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': '关键',
	'TICKETING.SEVERITY_MAJOR': '重大',
	'TICKETING.SEVERITY_MINOR': '小',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': '激活费',
	'TICKETING.AIRTIME_USAGE': '通话时间用量',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': '基本账户问题',
	'TICKETING.BILLING_DISPUTES': '账单争议',
	'TICKETING.BILLING_FILES_ISSUES': '账单文件问题',
	'TICKETING.BILL_RECONCILIATION': '账单和解',
	'TICKETING.CUSTOMER_EDUCATION': '客户教育',
	'TICKETING.DATA_USAGE_CANADA': '加拿大数据用量',
	'TICKETING.DATA_USAGE_INTERNATIONAL': '国际数据用量',
	'TICKETING.DATA_USAGE_OFF_NET': '网外数据用量',
	'TICKETING.DATA_USAGE_ON_NET': '网内数据用量',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': '收款或账单延迟',
	'TICKETING.DISCOUNT': '折扣',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': '欺诈查询或问题',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': '账单打印问题',
	'TICKETING.MONTHLY_RECURRING_CHARGE': '每月重复收费',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': '一次性收费 — 调整',
	'TICKETING.OTHER': '其他',
	'TICKETING.PAYMENT_ISSUES': '付款问题',
	'TICKETING.PRORATED_CHARGES': '按比例收费',
	'TICKETING.REACTIVATION_FEE': '重新激活费',
	'TICKETING.SMS_TEXT_CANADA': '加拿大短信服务（SMS）/文本',
	'TICKETING.SMS_TEXT_INTERNATIONAL': '国际短信服务（SMS）/文本',
	'TICKETING.SMS_TEXT_OFF_NET': '网外短信服务（SMS）/文本',
	'TICKETING.SMS_TEXT_ON_NET': '网内短信服务（SMS）/文本',
	'TICKETING.STATUS_INVOICE': '状态账单',
	'TICKETING.TAXES_AND_SURCHARGES': '税款和附加费',
	'TICKETING.DATA': '数据',
	'TICKETING.MESSAGING_SMS_MMS_': '短信/彩信（SMS/MMS）',
	'TICKETING.VOICE': '语音',
	'TICKETING.CLARIFICATION': '说明',
	'TICKETING.PREPAID': '预付',
	'TICKETING.USAGE_INQUIRY': '用量查询',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': '账单问题',
	'TICKETING.INVOICE_RELATED_ISSUE': '与账单相关的问题',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': '与费率计划相关的问题',
	'TICKETING.USAGE_RELATED_ISSUE': '与用量相关的问题',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': '2G与3G混用',
	'TICKETING.2G_ONLY': '仅用2G',
	'TICKETING.3G_ONLY': '仅用3G',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': '已连接，但数据无法通过',
	'TICKETING.FRAME_RELAY_PVC': '帧中继/永久虚拟电路（PVC）',
	'TICKETING.GPRS_EDGE': '通用分组无线业务（GPRS）/EDGE',
	'TICKETING.LATENCY_ISSUE': '延迟问题',
	'TICKETING.MMS': '彩信（MMS）',
	'TICKETING.ROAMING_DOMESTIC': '漫游/国内',
	'TICKETING.ROAMING_INTERNATIONAL': '漫游/国际',
	'TICKETING.SIGNAL_ISSUES': '信号问题',
	'TICKETING.SMPP_BIND': 'SMPP协议绑定',
	'TICKETING.SMS': '短信服务（SMS）',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': '虚拟专用网络（VPN）',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': '反馈',
	'TICKETING.NEW_REQUEST': '新请求',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': '兼容性',
	'TICKETING.DEVICE_UNLOCK': '解锁设备',
	'TICKETING.ORDERING': '订购',
	// TICKETING LOST/STOLEN DEVICE SUBCATEGORIES
	'TICKETING.BLOCK_DEVICE': '阻止设备',
	'TICKETING.FRAUD_REPORT': '举报欺诈',
	'TICKETING.UNBLOCK_DEVICE': '取消阻止设备',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': '事故通知',
	'TICKETING.SCHEDULED_MAINTENANCE': '已安排维护',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': '网络信道接口（NCI）',
	'TICKETING.WIRELESS': '无线',
	'TICKETING.WIRELINE': '有线',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': '问题',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': '批处理文件',
	'TICKETING.CREATE_NEW_SIM_PROFILE': '创建新SIM卡概况',
	'TICKETING.EQUIPMENT_INQUIRY': '设备查询',
	'TICKETING.SINGLE_SIM_PROVISIONING': '单SIM卡配给',
	'TICKETING.WEB_SERVICE': '网络服务',
	'TICKETING.PROVISIONING_SIMS_FEATURES': '配给SIM卡/特性',
	'TICKETING.ACTIVATION': '激活',
	'TICKETING.BATCH_BULK_CHANGE': '批处理/批量更改',
	'TICKETING.CALL_FORWARDING': '呼叫转移',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': '更改通讯计划',
	'TICKETING.CHANGE_RATE_PLAN': '更改费率计划',
	'TICKETING.CHANGE_SIM_STATUS': '更改SIM卡状态',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': '在多个账户间转移SIM卡',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': '自动化规则问题',
	'TICKETING.CONTROL_CENTER_QUESTION': '控制中心问题',
	'TICKETING.COVERAGE_QUESTION': '覆盖范围问题',
	'TICKETING.GENERAL_QUESTION': '一般问题',
	'TICKETING.OUTAGE_QUESTION': '宕机问题',
	'TICKETING.ROAMING_QUESTION': '漫游问题',
	'TICKETING.JPO_COVERAGE': 'JPO覆盖范围',
	//  TICKETING SALES/MARKETING ISSUE SUBCATEGORIES
	'TICKETING.CUSTOMER_INQUIRY': '客户查询',
	'TICKETING.DEV_KIT': '开发套件',
	'TICKETING.MARKETING_INQUIRY': '营销查询',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': '账户终止',
	'TICKETING.API_SETUP': 'API设置',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': '创建/修改账单区',
	'TICKETING.CC_ACCESS_UPDATES': '访问/更新抄送副本',
	'TICKETING.COMMUNICATION_PLAN': '通讯计划',
	'TICKETING.CUSTOM_APN': '定制APN',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': '全局SIM卡设置/映射',
	'TICKETING.GPRS_VPN': '通用分组无线业务（GPRS）虚拟专用网络（VPN）',
	'TICKETING.LEGAL_SUBPOENA': '法律/传票',
	'TICKETING.LTE_MIGRATION': 'LTE迁移',
	'TICKETING.MSISDN_UPDATES': '移动台国际订户目录号（MSISDN）更新',
	'TICKETING.NEW_ACCOUNT': '新账户',
	'TICKETING.RADIUS_CONFIG_SETUP': '半径配置设置',
	'TICKETING.REPORT': '报告',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES（漫游区请求）',
	'TICKETING.SECURESIM': 'SecureSIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'SIM卡订购/配给',
	'TICKETING.TRACE': '追踪',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': '应用程序问题',
	'TICKETING.BILLING_TECHNICAL_ISSUE': '技术性账单问题',
	'TICKETING.CONNECTIVITY': '连接',
	'TICKETING.GLOBAL_SIM_ISSUE': '全局SIM卡问题',
	'TICKETING.JPO_FILE_ISSUE': 'JPO文件问题',
	'TICKETING.JPO_LINK_ISSUE': 'JPO链接问题',
	'TICKETING.NOC_BRIDGE': '网络运营中心桥',
	'TICKETING.CDR_FILES': 'CDR文件',
	'TICKETING.JPO_REPORT': 'JPO报告',
	//  TICKETING USER/ACCOUNT ADMINISTRATION SUBCATEGORIES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': '添加新账户功能选项',
	'TICKETING.DEACTIVATE_USER': '停用用户',
	'TICKETING.ID_ISSUE': 'ID问题',
	'TICKETING.NEW_USER': '新用户',
	'TICKETING.PASSWORD_RESET': '重设密码',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'AT&T控制中心（ACC）',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Connected Car Portal/Apollo',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': '按需服务企业（EOD）',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console（MNC）',
	// OTHER
	'TICKETING.CRITICAL': '关键',
	'TICKETING.MAJOR': '重大',
	'TICKETING.MINOR': '小',
	'TICKETING.LOW': '低',
	'TICKETING.MEDIUM': '中',
	'TICKETING.HIGH': '高',
	'TICKETING.OUTAGE': '宕机',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': '动态IP',
	'TICKETING.FIXED_IP': '固定IP',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': '自动化规则',
	'TICKETING.CC_ISSUE_OTHER': 'CC问题 — 其他',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': '输出与批处理更新',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': '位置服务/第三方',
	'TICKETING.PROVISIONING': '配给',
	'TICKETING.SESSION_INFORMATION_DELAY': '会话信息延迟',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': '网页界面问题',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': '数据问题',
	'TICKETING.DEVICE_ISSUE': '设备问题',
	'TICKETING.SFTP_FILE_TRANSFERS': 'sFTP/文件转让',
	'TICKETING.SMS_ISSUE': '短信服务（SMS）问题',
	'TICKETING.VOICE_ISSUE': '语音问题',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': '网络',
	'TICKETING.RADIUS': '半径',
	'TICKETING.SMPP': 'SMPP协议',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': '添加附件',
	'TICKETING.NOTE': '注释',
	'TICKETING.LAST_UPDATED_DATE': '通知单最后一次更新日期',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': '不存在带有此参数的端点。',
	// ticketing notifications
	'TICKETING.ATTACHMENT_FAIL_TITLE': '无法完全完成您的请求。',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': '通知单{ticketId}已创建，但附件（{attachments}）未能上载',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': '通知单{ticketId}已编辑，但附件（{attachments}）未能上载。',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': '通知单{ticketId}已编辑，但附件（{attachments}）未能删除。',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': '通知单{ticketId}已编辑，但附件（{deleteAttachments}）未能删除，且附件（{uploadAttachments}）未能上载。'
};
