import { Status } from '../../../../utils/constants';

export const downloadInvoiceAttachmentRequest = (state) =>
	state.analyticsInvoices.invoices.invoiceAttachment.status === Status.PENDING;

export const downloadInvoiceAttachmentFail = (state) =>
	state.analyticsInvoices.invoices.invoiceAttachment.status === Status.FAIL;

export const downloadInvoiceAttachmentSuccess = (state) =>
	state.analyticsInvoices.invoices.invoiceAttachment.status === Status.DONE;

export const fileDownloadId = (state) =>
	state.analyticsInvoices.invoices.invoiceAttachment.fileId;
