import { groupBy } from 'lodash';
import { Status } from '../../../utils/constants';

export const getAutomationRulesFiltersPending = (state) =>
	state.automation.getAutomationRulesFilters.status === Status.PENDING;

export const getAutomationRulesFiltersSuccess = (state) =>
	state.automation.getAutomationRulesFilters.data;

export const getAutomationRulesFiltersFail = (state) =>
	state.automation.getAutomationRulesFilters.status === Status.FAIL;

export const getAutomationAlertsFiltersPending = (state) =>
	state.automation.getAutomationAlertsFilters.status === Status.PENDING;

export const getAutomationAlertsFiltersSuccess = (state) =>
	state.automation.getAutomationAlertsFilters.data;

export const getAutomationAlertsFiltersFail = (state) =>
	state.automation.getAutomationAlertsFilters.status === Status.FAIL;

export const getAutomationAlertsPending = (state) =>
	state.automation.getAutomationAlerts.status === Status.PENDING;

export const getAutomationAlertsSuccess = (state) =>
	(state.automation.getAutomationAlerts.data &&
		state.automation.getAutomationAlerts.data.resultList) ||
	[];

export const getAutomationAlertsFail = (state) =>
	state.automation.getAutomationAlerts.status === Status.FAIL;

export const getAutomationAlertsTotalCount = (state) =>
	state.automation.getAutomationAlerts.data &&
	state.automation.getAutomationAlerts.data.totalCount;

export const removeAutomationAlertPending = (state) =>
	state.automation.removeAutomationAlert.status === Status.PENDING;

export const removeAutomationAlertSuccess = (state) =>
	state.automation.removeAutomationAlert.status === Status.DONE;

export const getAutomationRulesPending = (state) =>
	state.automation.getAutomationRules.status === Status.PENDING;

export const getAutomationRulesFail = (state) =>
	state.automation.getAutomationRules.status === Status.FAIL;

export const getAutomationRulesSuccess = (state) =>
	(state.automation.getAutomationRules.data &&
		state.automation.getAutomationRules.data.resultList) ||
	[];

export const getAutomationRulesTotalCount = (state) =>
	state.automation.getAutomationRules.data &&
	state.automation.getAutomationRules.data.totalCount;

export const getAutomationRulePending = (state) =>
	state.automation.getAutomationRule.status === Status.PENDING;

export const getAutomationRuleSuccess = (state) =>
	state.automation.getAutomationRule.data;

export const removeAutomationRulePending = (state) =>
	state.automation.removeAutomationRule.status === Status.PENDING;

export const removeAutomationRuleSuccess = (state) =>
	state.automation.removeAutomationRule.status === Status.DONE;

export const addAutomationRulePending = (state) =>
	state.automation.addAutomationRule.status === Status.PENDING;

export const addAutomationRuleSuccess = (state) =>
	state.automation.addAutomationRule.status === Status.DONE;

export const isDataGetting = (state) =>
	state.automation.getAutomationOptions.platformsStatus === Status.PENDING ||
	state.automation.getAutomationOptions.categoriesStatus === Status.PENDING ||
	state.automation.getAutomationPlatform.status === Status.PENDING ||
	state.automation.getAutomationOptions.triggersStatus === Status.PENDING ||
	state.automation.getAutomationOptions.framesStatus === Status.PENDING ||
	state.automation.getAutomationOptions.triggerSubcategoriesStatus ===
		Status.PENDING ||
	state.Endpoints.availableStates.status === Status.PENDING ||
	state.Endpoints.getRatePlans.status === Status.PENDING ||
	state.automation.getAutomationActions.status === Status.PENDING ||
	state.singleEndpoint.getNetworkEntitlement.status === Status.PENDING ||
	state.automation.getAutomationSchedulers.status === Status.PENDING ||
	state.automation.getAutomationCountries.status === Status.PENDING ||
	state.automation.getAutomationZoneModel.status === Status.PENDING ||
	state.automation.getAutomationZoneName.status === Status.PENDING ||
  state.automation.getAutomationRatePlans.status === Status.PENDING ||
  state.automation.getAutomationPrepaidRatePlans.status === Status.PENDING ||
	state.automation.getAutomationNetworkEntitlements.status === Status.PENDING ||
	state.automation.getAutomationAvailableStates.status === Status.PENDING ||
	state.automation.getRuleAutomationZones.status === Status.PENDING;

export const editAutomationRulePending = (state) =>
	state.automation.editAutomationRule.status === Status.PENDING;

export const editAutomationRuleSuccess = (state) =>
	state.automation.editAutomationRule.status === Status.DONE;

export const automationActions = (state) =>
	(state.automation.getAutomationActions.data &&
		state.automation.getAutomationActions.data.resultList) ||
	[];

export const automationChildActions = (state) =>
	(state.automation.getAutomationChildActions.data &&
		state.automation.getAutomationChildActions.data.resultList) ||
	[];

export const automationCountries = (state) =>
	(state.automation.getAutomationCountries.data &&
		state.automation.getAutomationCountries.data.resultList) ||
	[];

export const getAutomationPlatformPending = (state) =>
	state.automation.getAutomationPlatform.status === Status.PENDING;

export const getAutomationPlatformsPending = (state) =>
	state.automation.getAutomationOptions.platformsStatus === Status.PENDING;

export const getAutomationPlatformsSuccess = (state) =>
	state.automation.getAutomationOptions.platforms &&
	state.automation.getAutomationOptions.platforms.data &&
	state.automation.getAutomationOptions.platforms.data.resultList;

export const getAutomationPlatformsSuccessStatus = (state) =>
	state.automation.getAutomationOptions.platformsStatus === Status.DONE;

export const getAutomationCategoriesPending = (state) =>
	state.automation.getAutomationOptions.categoriesState === Status.PENDING;

export const getAutomationCategoriesSuccess = (state) =>
	state.automation.getAutomationOptions.categories &&
	state.automation.getAutomationOptions.categories.resultList;

export const getAutomationTriggersPending = (state) =>
	state.automation.getAutomationOptions.triggersStatus === Status.PENDING;

export const getAutomationTriggersSuccess = (state) =>
	state.automation.getAutomationOptions.triggers &&
	state.automation.getAutomationOptions.triggers.resultList;

export const getAutomationTriggerSubcategoriesPending = (state) =>
	state.automation.getAutomationOptions.triggerSubcategoriesStatus ===
	Status.PENDING;

export const getAutomationTriggerSubcategoriesSuccess = (state) =>
	state.automation.getAutomationOptions.triggerSubcategories &&
	state.automation.getAutomationOptions.triggerSubcategories.data &&
	state.automation.getAutomationOptions.triggerSubcategories.data.resultList;

export const getAutomationFramesPending = (state) =>
	state.automation.getAutomationOptions.framesStatus === Status.PENDING;

export const getAutomationFramesSuccess = (state) =>
	state.automation.getAutomationOptions.frames &&
	state.automation.getAutomationOptions.frames.data &&
	state.automation.getAutomationOptions.frames.data.resultList;

export const getM2mAccounts = (state) =>
	state.automation.getAutomationOptions.m2mAccounts &&
	state.automation.getAutomationOptions.m2mAccounts.data &&
	state.automation.getAutomationOptions.m2mAccounts.data.m2mAccounts;

export const automationPool = (state) =>
	(state.automation.getAutomationPool.data &&
		state.automation.getAutomationPool.data.rateplans) ||
	[];

export const automationZoneName = (state) =>
	(state.automation.getAutomationZoneName.data &&
		state.automation.getAutomationZoneName.data.data) ||
	[];

export const automationZoneModel = (state) =>
	(state.automation.getAutomationZoneModel.data &&
		state.automation.getAutomationZoneModel.data.data) ||
	[];

export const automationSchedulers = (state) =>
	(state.automation.getAutomationSchedulers.data &&
		state.automation.getAutomationSchedulers.data.resultList) ||
	[];

export const automationRatePlans = (state) =>
	(state.automation.getAutomationRatePlans.data &&
		state.automation.getAutomationRatePlans.data.rateplans) ||
	[];

export const automationPrepaidRatePlans = (state) =>
	(state.automation.getAutomationPrepaidRatePlans.data &&
		state.automation.getAutomationPrepaidRatePlans.data.rateplans) ||
	[];

export const automationNetworkEntitlements = (state) =>
	(state.automation.getAutomationNetworkEntitlements.data &&
		state.automation.getAutomationNetworkEntitlements.data
			.networkEntitlements) ||
	[];

export const automationAvailableStates = (state) =>
	(state.automation.getAutomationAvailableStates.data &&
		state.automation.getAutomationAvailableStates.data.allowedStates &&
		groupBy(
			state.automation.getAutomationAvailableStates.data.allowedStates,
			'serviceProvider'
		)) ||
	null;

export const activateRuleSuccess = (state) =>
	state.automation.activateRule.status === Status.DONE;

export const activateRuleRequest = (state) =>
	state.automation.activateRule.status === Status.PENDING;

// RULE STATE CHANGED
export const setRuleReceivedSuccess = (state) =>
	state.automation.setRuleReceived.status === Status.DONE;

export const setRuleReceivedRequest = (state) =>
	state.automation.setRuleReceived.status === Status.PENDING;

export const setRuleImplementedSuccess = (state) =>
	state.automation.setRuleImplemented.status === Status.DONE;

export const setRuleImplementedRequest = (state) =>
	state.automation.setRuleImplemented.status === Status.PENDING;

export const setRuleConflictedSuccess = (state) =>
	state.automation.setRuleConflicted.status === Status.DONE;

export const setRuleConflictedRequest = (state) =>
	state.automation.setRuleConflicted.status === Status.PENDING;

export const requestForDeletionSuccess = (state) =>
	state.automation.requestForDeletion.status === Status.DONE;

export const requestForDeletionRequest = (state) =>
	state.automation.requestForDeletion.status === Status.PENDING;

export const getRuleHistoryRequest = (state) =>
	state.automation.getRuleHistory.status === Status.PENDING;

export const getRuleHistorySuccess = (state) =>
	state.automation.getRuleHistory.data;

export const getRuleHistoryFail = (state) =>
	state.automation.getRuleHistory.status === Status.FAIL;

export const getRuleNotesRequest = (state) =>
	state.automation.getRuleNotes.status === Status.PENDING;

export const getRuleNotesSuccess = (state) =>
	state.automation.getRuleNotes.data;

export const getRuleNotesFail = (state) =>
	state.automation.getRuleNotes.status === Status.FAIL;

export const getRuleAutomationZonesRequest = (state) =>
	state.automation.getRuleAutomationZones.status === Status.PENDING;

export const getRuleAutomationZonesSuccess = (state) =>
	state.automation.getRuleAutomationZones.data;
