export default {
	// COMMON
	'ONBOARDING.EDIT': 'Bewerken',
	'ONBOARDING.DELETE': 'Verwijderen',
	'ONBOARDING.NAME': 'Naam',
	'ONBOARDING.DESCRIPTION': 'Beschrijving',
	'ONBOARDING.ID': 'Id',
	'ONBOARDING.ACCOUNT_NAME': 'Naam account',
	'ONBOARDING.FRIENDLY_NAME': 'Beschrijvende naam',
	'ONBOARDING.ACCOUNT_ID': 'Account-id',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Nieuw bedrijf maken',
	'ONBOARDING.IOTC_CUSTOMERS': 'IoT Console klanten',
	'ONBOARDING.COMPANIES': 'Bedrijven',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'Geen bedrijven beschikbaar',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': 'Er zijn momenteel geen bedrijven voor deze tabel om te laten zien',
	'ONBOARDING.COMPANY_NAME': 'Naam bedrijf',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'bijv.: AT&T Bedrijven',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'Naam MNC-account',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'Naam M2M-account',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'Accountnummer IOT-C',
	'ONBOARDING.SIM_ORDERS': 'SIM-bestellingen',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Technical Owner', - verwijderd voor account gemaakt door
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Account gemaakt door',
	'ONBOARDING.VIEW_MC': 'MC weergeven',
	'ONBOARDING.DATE_CREATED': 'Gemaakt op',
	'ONBOARDING.ACTIONS': 'Acties',
	'ONBOARDING.PLATFORMS': 'Platforms',
	'ONBOARDING.ACTIVATION_DENIED': 'Kan aanvraag niet verwerken!',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Account geactiveerd',
	'ONBOARDING.CONTACT_SYSADMIN': 'Neem contact op met uw IoT Console-beheerder voor verdere hulp',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': 'De instellingen van de bedrijfstabel zijn geüpdatet.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': 'Kan de kolommen van de bedrijfstabel op dit moment niet updaten. Probeer het later opnieuw.',
	'ONBOARDING.RETRY': 'Opnieuw proberen',

	// SEARCH MENU
	'ONBOARDING.BY': 'Op',
	'ONBOARDING.BUSINESS_NAME': 'Bedrijfsnaam',
	'ONBOARDING.CONTACT_PERSON': 'Contactpersoon',
	'ONBOARDING.PLATFORM_ID': 'Platform-id',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'IoT Console-id: ',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'M2M-bedrijfsaccounts',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': 'U hebt een IoT Console-account gemaakt voor:',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': 'Het bijbehorende IoT-gatewaynummer van deze account is:',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Nieuw bedrijfsaccount maken',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': 'Mag geen spaties of nummers bevatten',
	'ONBOARDING.IDM_SERVCE_NAME': 'Naam IDM-service',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Zichtbaar voor de klant',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Bedrijf of handelsnaam juridische entiteit',
	'ONBOARDING.COMPANY_CONTACTS': 'Contactpersonen bedrijf',
	'ONBOARDING.ATT_CONTACTS': 'Contactpersonen AT&T',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'Ondersteuningstype vTM',
	'ONBOARDING.FULL_TSM': 'Volledig TSM (alle tickets, ongeacht ernst, gaan naar het TSM- team)',
	'ONBOARDING.PARTIAL_TSM': 'Gedeeltelijk TSM (tickets met ernst 1 gaan naar het TSM-team, ernst 2 en 3 gaan naar het Helpdesk Offline-team)',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care (alle tickets, ongeacht ernst, gaan naar het IoT Priority Care-team)',
	'ONBOARDING.NO_SUPPORT': 'Geen ondersteuning (alle tickets, ongeacht ernst, gaan naar het Helpdesk Offline-team)',
	'ONBOARDING.CONTACT': 'Contactpersoon',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Beschrijvende naam bedrijf',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Officiële naam bedrijf',
	'ONBOARDING.COMPANY_CONTACT': 'Contactpersoon bedrijf',
	'ONBOARDING.ATT_CONTACT': 'Contactpersoon AT&T',
	'ONBOARDING.COMPANY': 'Bedrijf',
	'ONBOARDING.COUNTRY': 'Land',
	'ONBOARDING.STATE_PROVINCE': 'Staat/provincie',
	'ONBOARDING.STREET_ADDRESS': 'Adres',
	'ONBOARDING.ADDRESS_LINE_1': 'Adresregel 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Adresregel 2 (optioneel)',
	'ONBOARDING.CITY_TOWN': 'Plaatsnaam',
	'ONBOARDING.ZIP_POSTAL_CODE': 'Postcode',
	'ONBOARDING.COMPANY_DOMAIN': 'Bedrijfsdomein',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'E-maildomein bedrijf',
	'ONBOARDING.FULL_NAME': 'Volledige naam',
	'ONBOARDING.PHONE_NUMBER': 'Telefoonnummer',
	'ONBOARDING.EMAIL_ADDRESS': 'E-mailadres',
	'ONBOARDING.SIM_OPTIONS': 'SIM-opties',
	'ONBOARDING.ORDER_MOVE_SIMS': 'SIM’s bestellen of verplaatsen',
	'ONBOARDING.ORDERING': 'Bestellen',
	'ONBOARDING.TRANSFER': 'Overdracht',
	'ONBOARDING.SIM_TRANSFER': 'SIM-overdracht',
	'ONBOARDING.TRANSFERING_SIM': 'Een SIM overdragen van een M2M-account naar een ander M2M-account',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': 'SIM’s bestellen, SIM-lijst selecteren of uploaden',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'Informatie over facturering bekijken of bewerken',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': 'Platform',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Beschrijving platform',
	'ONBOARDING.APNS': 'APN’s',
	'ONBOARDING.M2M_ACCOUNT_ID': 'Id M2M-account',
	'ONBOARDING.EDIT_ACTIONS': 'Accounts bewerken',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'M2M-platform bewerken',
	'ONBOARDING.ADD_M2M_PLATFORM': 'M2M-platform toevoegen',
	'ONBOARDING.MC_ACCOUNT_ID': 'Account-id IOT-C',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'ATT Control Center',
	'ONBOARDING.PLATFORM_CCIP': 'Integratieprogramma Control Center',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Apparaatverbindingsplatform',
	'ONBOARDING.PLATFORM_GMNA': 'GM Noord-Amerika',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Onderneming',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': 'M2M-platform',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Netwerkrecht',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Netwerkrechten',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Naam communicatieprofiel',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': 'Geen netwerkrechten beschikbaar',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': 'Er zijn momenteel geen netwerkrechten voor deze tabel om te laten zien',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Nieuw netwerkrecht toevoegen',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Netwerkrecht toevoegen',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Netwerkrecht bewerken',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Netwerkrechten bewerken',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Netwerkrecht kiezen',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Selecteer netwerkrecht',
	'ONBOARDING.PULL_DATA': 'Data opvragen',
	'ONBOARDING.HLR_TEMPLATE': 'Sjabloon HLRHSS-gegevens',
	'ONBOARDING.HOTLINE_NUMBER': 'Helpdesk-nummer',
	'ONBOARDING.VOICE_MT': 'Voice MT',
	'ONBOARDING.ROAMING_LTE': 'Roaming LTE',
	'ONBOARDING.DATA': 'Data',
	'ONBOARDING.DATA_LTE': 'Data LTE',
	'ONBOARDING.VOICE_MO': 'Voice MO',
	'ONBOARDING.INTERNATIONAL_VOICE': 'Voice internationaal',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': 'Blokkeer internationaal SMS MO, uitgezonderd eigen land',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Blokkeer 09XY- of 18XY-nummers',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Type Camel-service',
	'ONBOARDING.APNS_PDPINDEX': 'PDP-index',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'Vast IP-adres',
	'ONBOARDING.APNS_SECURITY': 'Beveiliging',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Abonnement',
	'ONBOARDING.ADD_NEW_APN': 'Nieuw APN toevoegen',
	'ONBOARDING.PLATFORM_DETAILS': 'Gegevens platform',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Roaming',
	'ONBOARDING.SMS_MO': 'SMS MO',
	'ONBOARDING.SMS_MT': 'SMS MT',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Geschikt netwerkrecht',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': 'Type betaling',
	'ONBOARDING.USAGE_LIMIT': 'Verbruikslimiet',
	'ONBOARDING.RATE_PLAN': 'Tariefplan',
	'ONBOARDING.RATE_PLANS': 'Tariefplannen',
	'ONBOARDING.RATE_PLAN_NAME': 'Naam tariefplan',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'Geen tariefplannen beschikbaar',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Nieuw tariefplan toevoegen',
	'ONBOARDING.EDIT_RATE_PLAN': 'Tariefplan bewerken',
	'ONBOARDING.PLAN_ID': 'Plan-id',
	'ONBOARDING.RATE_PLAN_TYPE': 'Type tariefplan',
	'ONBOARDING.ACCOUNT_CHARGE': 'Accountkosten',
	'ONBOARDING.TERM': 'Tijdvak',
	'ONBOARDING.TIERING_FLAG': 'Vlag opslaglagen',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'Per MRC-abonnee',
	'ONBOARDING.CURRENCY': 'Valuta',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Verbruik bundeldata',
	'ONBOARDING.USAGE_LIMIT_MB': 'Verbruikslimiet (MB)',
	'ONBOARDING.PLAN_TYPE': 'Type plan',
	'ONBOARDING.TYPE': 'Type',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'NAAM FACTUURACCOUNT',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'M2M-TARIEFPLAN-ID',
	'ONBOARDING.PRICE_PLAN_ID': 'PRIJZENPLAN-ID',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'TYPE ABONNEMENTSITEM',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'ID M2M-ABONNEMENTSITEM',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'INTERNE M2M-ACCOUNT-ID',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'M2M-ACCOUNTNUMMER',
	'ONBOARDING.DEFAULT_PLAN': 'STANDAARD TARIEFPLAN',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'Serviceprovider-id',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'Netwerkrecht-id',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': 'Er zijn momenteel geen tariefplannen voor deze tabel om te laten zien',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SIM SKU
	'ONBOARDING.SIM_SKU': 'SIM-SKU',
	'ONBOARDING.DELETE_SIM_SKU': 'SIM-SKU verwijderen',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': 'Door deze SKU te verwijderen, verwijdert u alle SIM-bestellingen die aan deze SKU zijn gekoppeld. Weet u zeker dat u door wilt gaan?',
	'ONBOARDING.SIM_ORDERING': 'SIM-bestelling',
	'ONBOARDING.CURRENT_SIM_SKUS': "Huidige SIM-SKU'S",
	'ONBOARDING.ADD_SIM_SKU': 'SIM-SKU toevoegen',
	'ONBOARDING.EDIT_SIM_SKU': 'SIM-SKU bewerken',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'Voer hier de SIM-SKU in',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'Voer de SKU-beschrijving in',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Contractbeperkingen',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': 'Stel de beperkingen in voor het SIM-bestellingsproces.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Min. toegestane aantal',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Min. aantal',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Max. toegestane aantal',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Max. aantal',
	'ONBOARDING.INCREMENT': 'Toename',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Per periode',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Selecteer een tijdsperiode',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'min. aantal',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'max. aantal',
	'ONBOARDING.UNIT_PRICE': 'Prijs per stuk',
	'ONBOARDING.ALLOW_TO_ORDER': 'Bestellen toestaan',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'SKU is bewerkt',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'Kan SKU niet bewerken',
	'ONBOARDING.RATE_PLAN_ID': 'Tariefplan-id',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'Geen SIM-SKU’s beschikbaar',

	// Platform
	'ONBOARDING.OPERATOR': 'Operator',
	'ONBOARDING.LAUNCH_MC': 'MC starten',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Operator (optioneel)',
	'ONBOARDING.MCC': 'MCC',
	'ONBOARDING.MNC': 'MNC',
	'ONBOARDING.POD': 'Pod',
	'ONBOARDING.MCC_TOOLTIP': 'De MCC (Mobile Country Code) is een uniek 3-cijferig nummer dat een land identificeert.',
	'ONBOARDING.MNC_TOOLTIP': 'De MNC (Mobile Country Code) is een uniek 2- of 3-cijferig nummer dat een mobiel netwerk identificeert.',
	'ONBOARDING.PLATFORM_SETUP': 'Platform-configuratie',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': 'Platform-configuratie is beschikbaar nadat een platform is toegevoegd. ',
	'ONBOARDING.SETTINGS': 'Instellingen',
	'ONBOARDING.EDIT_PLATFORM': 'Platform bewerken',
	'ONBOARDING.ADD_PLATFORM': 'Platform toevoegen',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'Geen platforms beschikbaar',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Er ontbreekt verplichte informatie',

	// Platform bewerken
	'ONBOARDING.SIM_LOADING': 'SIM laden',
	'ONBOARDING.GATEWAY': 'Gateway',
	'ONBOARDING.ENTER_GATEWAY': 'Gateway invoeren',
	'ONBOARDING.BILLING_INFORMATION': 'Informatie over facturering',
	'ONBOARDING.BILLING_PLATFORM': 'Factureringsplatform',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Factuuraccountnummer',

	// Rapporten
	'ONBOARDING.REPORT': 'Rapport',
	'ONBOARDING.REPORTS': 'Rapporten',
	'ONBOARDING.COMPANY_REPORTS': 'Rapporten',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'Fout IDM-integratie',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': 'Er is een probleem met de IDM-integratie. ',
	'ONBOARDING.ONBOARDING_STATUS': 'Status onboarden',
	'ONBOARDING.SUCCESS': 'Geslaagd',
	'ONBOARDING.ERROR': 'Fout',

	'ONBOARDING.MUST_BE_BETWEEN': 'Moet tussen ',
	'ONBOARDING.AND': ' en ',
	'ONBOARDING.CHARACTERS_LONG': ' tekens bevatten',
	'ONBOARDING.DEFAULT': 'Standaard',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Tariefplannen toevoegen voor {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': 'Instellingen bedrijfsrapporten zijn geüpdatet',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': 'Fout tijdens updaten bedrijfsrapporten',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': 'Definieer rapporten die u voor {companyName} wilt inschakelen',
	'ONBOARDING.REPORTS_SUBTITLE': 'Gecontroleerde rapporten worden weergegeven in de IoT Console-portal waar gebruikers ze kunnen downloaden',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': 'Netwerkrechten toevoegen voor {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': 'Koppel ingeschakelde netwerkrechten voor deze account met AT&T Control Center.',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Hoofdadres bedrijf',
	'ONBOARDING.COMPANY_DETAILS': 'Bedrijfsgegevens',
	'ONBOARDING.ACCOUNTS': 'Accounts',
	'ONBOARDING.EDIT_ACCOUNT': 'Account bewerken',
	'ONBOARDING.VIEW_ACCOUNT': 'Account weergeven',
	'ONBOARDING.SIM_LOADING_SUB': 'Waar de SIM’s voor deze account worden geladen. IoT-gateway doet dit meestal automatisch wanneer de account voor het M2M-platform wordt gemaakt.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Van Iot-gateway importeren',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Accountconfiguratie',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': 'Selecteer de betreffende services die u voor deze account wilt inschakelen. Als u de gewenste service niet ziet, kunt u deze toevoegen op het bovenliggende accountniveau, maar hiervoor geldt wel een goedkeuringsproces. ',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Voeg netwerkrechten toe',
	'ONBOARDING.ADD_RATE_PLANS': 'Voeg tariefplannen toe',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': 'Bekijk of bewerk rapporten voor weergave',
	'ONBOARDING.USERS': 'Gebruikers',
	'ONBOARDING.USERS_SUB': 'Nadat u op de onderstaande knop hebt geklikt, wordt u doorgestuurd naar het scherm Bedrijfsidentiteit waar u nieuwe gebruikers kunt uitnodigen om zich bij deze account aan te sluiten of bestaande gebruikers aan dit bedrijf kunt toevoegen.',
	'ONBOARDING.CREATE_USERS': 'Gebruikers maken',
	'ONBOARDING.USER_ACCESS': 'Gebruikerstoegang',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Gebruiker maken/toevoegen',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Gegevens nieuw bedrijfsaccount verifiëren',
	'ONBOARDING.SUBMIT': 'Verzenden',
	'ONBOARDING.CREATE_COMPANY': 'Bedrijf maken',
	'ONBOARDING.NEW_ACCOUNT': 'Nieuw account',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': 'Een account of subaccount moet in het M2M-platform bestaan, voordat het aan MNC kan worden toegevoegd. Volg de onderstaande stappen om een M@M-account te maken dat in de IoT Console moet worden ingericht. ',
	'ONBOARDING.VALIDATE': 'Valideren',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Naam account valideren',
	'ONBOARDING.CREATE_ACCOUNT': 'Account maken',
	'ONBOARDING.ADD_ACCOUNT': 'Account toevoegen',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'Geen accounts beschikbaar',
	'ONBOARDING.M2M_PLARFORM': 'M2M-platform',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'SKU is gemaakt',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'Kan SKU niet maken',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': 'Netwerkrechten bewerken voor {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Subaccount bewerken',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'Subaccount weergeven',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Tariefplannen bewerken voor {companyName}',
	'ONBOARDING.VIEW': 'Weergeven',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'Netwerkrechten weergeven',
	'ONBOARDING.VIEW_RATE_PLANS': 'Tariefplannen weergeven',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Subaccount maken',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'Nieuwe subaccount voor {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'M2M-account genereren',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Naam subaccount',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': 'Selecteer tariefplannen voor deze account',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': 'Selecteer netwerkrechten voor deze account',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': 'Waarschuwing: er worden geen andere landen dan de VS ondersteund',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Aan subaccount toewijzen',
	'ONBOARDING.ENDPOINT_ID': 'Eindpunt-id',
	'ONBOARDING.NETWORK_OPERATOR': 'Netwerkoperator',
	'ONBOARDING.STATUS': ' Status',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': ' eindpunten worden naar een account verplaatst',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Bevestig verplaatsing van eindpunten',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': 'Nadat deze verplaatsing is geïnitieerd, worden de eindpunten binnen een paar minuten verplaatst. Wilt u doorgaan met deze actie?',
	'ONBOARDING.ENDPOINTS': 'Eindpunten',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'Naar subaccount verplaatsen',
	'ONBOARDING.FROM': 'Van',
	'ONBOARDING.TO': 'Naar',
	'ONBORDING.SIM_SKU': 'SIM-SKU',
	'ONBORDING.UNIT_PRICE': 'Prijs per stuk',
	'ONBORDING.DATE_SUBMITED': 'Verzenddatum',
	'ONBORDING.EDIT_DELETE_SKU': 'Bewerken/verwijderen SKU',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'Geen eindpunten beschikbaar.',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'Geen SIM’s beschikbaar',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'Selecteer een SIM voor deze account',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': 'Er zijn momenteel geen accounts voor deze tabel om te laten zien',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': 'Maandelijks',
	'ONBOARDING.PREPAID': 'Prepaid',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Maandelijks - Individuele abonnee',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Maandelijks - Vaste pool - Single',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Essential - Maandelijkse vaste pool',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Maandelijks - Flexibele pool',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': 'Essential - Maandelijkse flexibele pool',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': 'Maandelijks - Verbruiksniveau flexibele pool',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Maandelijks - Bolt-on',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Prepaid - Individuele abonnee',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Prepaid - Flexibele pool',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Prepaid - Vaste pool',
	'ONBOARDING.PREPAID_ADD_ON': 'Prepaid - Add-on',
	'ONBOARDING.PREPAID_EVENT': 'Prepaid - Gebeurtenis',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Prepaid - Gestapelde gebeurtenis',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': 'Gegevens dataverbruik',
	'ONBOARDING.SMS_USAGE_DETAILS': 'Gegevens sms-verbruik',
	'ONBOARDING.IMSI_SNAPSHOT': 'IMSI-snapshot',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Abonneelijst',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Snapshot abonnee',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Wijzigingen abonnee',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Dagelijkse snapshot account',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Snapshot tariefplan',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Snapshot communicatieplan',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Referentie factureringscyclus',
	'ONBOARDING.CARRIER_REFERENCE': 'Referentie provider',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Referentie type tariefplan',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'Referentie SIM-wijziging',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': 'Referentie sluitingsreden verbruiksrecord',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Snapshot tariefplanzone',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Aangepaste velden serviceprovider',
	'ONBOARDING.USER_SNAPSHOT': 'Snapshot gebruiker',
	'ONBOARDING.NEW_SUBSCRIBERS': 'Nieuwe abonnees',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Alle abonnees samen',
	'ONBOARDING.COMBINED_USAGE': 'Alle verbruik samen',
	'ONBOARDING.APN_SNAPSHOT': 'APN-snapshot',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Aangepaste velden serviceprovider',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Alle accounts samen',
	'ONBOARDING.DAILY_USAGE_RAW': 'Onbewerkt dagelijks verbruik',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'Alle actieve SIM’s',
	'ONBOARDING.DAILY_USAGE': 'Dagelijks verbruik',
	'ONBOARDING.ALL_SMS_MESSAGES': 'Alle sms-berichten',
	'ONBOARDING.SMS_DETAIL': 'Sms-gegevens',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'Cumulatief overzicht ICCID',
	'ONBOARDING.SIM_STATE_REFERENCE': 'Referentie SIM-status',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Gegevens voice-verbruik',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'Alle toegewezen SIM’s',
	'ONBOARDING.INVOICE_DETAIL': 'Factuurgegevens',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Overzicht tariefplan',
	'ONBOARDING.OTHER_CHARGES': 'Overige kosten',
	'ONBOARDING.USAGE_SUMMARY': 'Verbruiksoverzicht',
	'ONBOARDING.ACTIVATION_CHARGES': 'Activeringskosten',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Gegevens verbruik tariefplanzone',
	'ONBOARDING.INVOICE_CHARGES': 'Totaalbedrag factuur',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Kosten gepoold verbruik',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Abonnementskosten',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Kosten niet-gepoold verbruik',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Abonnee groothandelsverbruik',
	'ONBOARDING.FUNDED_PACKAGES': 'Gefinancierde pakketten',
	'ONBOARDING.TRIAL_COST_SHARE': 'Proef kosten delen',
	'ONBOARDING.REVENUE_SHARE': 'Omzet delen',
	'ONBOARDING.BOUNTY': 'Bonus',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Gegevens dataverbruik',
	'ONBOARDING.SMS_DETAILS': 'Sms-gegevens',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Delta-abonnementen',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Niet afgeleverde sms-berichten',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': 'Nul omzet groothandelsabonnee',
	'ONBOARDING.INVOICE_DETAILS': 'Factureringsgegevens',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Gegevens verbruik tariefplanzone',
	'ONBOARDING.BILLING_INVOICE': 'Factuur voor facturering',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1 maand',
	'ONBOARDING.3_MONTHS': '3 maanden',
	'ONBOARDING.1_WEEK': '1 week',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Account is overdragen',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Accountoverdracht is mislukt',
	'ONBOARDING.MY_COMPANY': 'Mijn bedrijf',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': 'Gebruikersgroepen',
	'ONBOARDING.USER_COUNT': 'Aantal gebruikers',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Huidig aantal actieve gebruikers',
	'ONBOARDING.FIRST_NAME': 'Voornaam',
	'ONBOARDING.LAST_NAME': 'Achternaam',
	'ONBOARDING.JOB_TITLE': 'Functie',
	'ONBOARDING.EMAIL': 'E-mail',
	'ONBOARDING.USER_GROUP': 'Gebruikersgroep',
	'ONBOARDING.ACCOUNT_ACCESS': 'Toegang account',
	'ONBOARDING.CREATE_NEW_GROUP': 'Nieuwe groep maken',
	'ONBOARDING.INVITE_USERS': 'Gebruikers uitnodigen',
	'ONBOARDING.ADD_USERS': 'Gebruikers toevoegen',
	'ONBOARDING.EDIT_USER_PROFILE': 'Gebruikersprofiel bewerken',
	'ONBOARDING.USER_INFORMATION': 'Informatie gebruiker',
	'ONBOARDING.ADDRESSES': 'Adressen',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Toegewezen accounts',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Wet en regelgeving',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Nieuwe gebruikersgroep maken',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': 'Groepen met aangepaste toegangsrechten voor uw gebruikers maken.',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name} {lastName} geaccepteerd',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'Licentieovereenkomst',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': 'Als u niet akkoord gaat met de voorwaarden van de EULA, kunt u deze account het beste verwijderen.',
	'ONBOARDING.ON_DATE': 'op {date}.',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': 'Als u niet akkoord gaat met de voorwaarden van de EULA, kunt u deze account het beste verwijderen.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': 'Voer de e-mailadressen in voor de gebruikers die u wilt uitnodigen. Gebruikers krijgen toegang tot het bedrijfsaccount zodra ze de uitnodigingslink accepteren die in de e-mailmelding is vermeld.',
	'ONBOARDING.ADD_NEW_USER': 'Nieuw gebruiker toevoegen',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Kan sms ontvangen',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': 'Staat/provincie/territorium/regio',
	'ONBOARDING.COMPANY_EMAIL': 'Zakelijk e-mailadres',
	'ONBOARDING.POSTAL_CODE': 'Postcode',
	'ONBOARDING.BUSINESS': 'Bedrijf',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': 'We hechten grote waarde aan de privacy van gebruikers, en we verkopen aan niemand gebruikersgegevens.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': 'De EULA bevat gedetailleerde informatie over hoe we uw privacy beschermen, uw recht op verwijdering van gegevens en andere nalevingsvoorschriften.',
	'ONBOARDING.STREET_ADDRESS_2': 'Adres 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Adres 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'Woonplaats of gemeente',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'Hetzelfde als het hoofdadres',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Nog een adres toevoegen',
	'ONBOARDING.YES': 'Ja',
	'ONBOARDING.NO': 'Nee',
	'ONBOARDING.PRIMARY_ADDRESS': 'Hoofdadres',
	'ONBOARDING.ADDRESS_TYPE': 'Type adres',
	'ONBOARDING.SECONDARY_NUMBER': 'Tweede nummer',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Voorkeurstaal',
	'ONBOARDING.PRIMARY_NUMBER': 'Hoofdnummer',
	'ONBOARDING.UPLOAD_IMAGE': 'Afbeelding uploaden',
	'ONBOARDING.FILTER_USER_LIST': 'Gebruikerslijst filteren',
	'ONBOARDING.CANCEL': 'Annuleren',
	'ONBOARDING.ADD': 'Toevoegen',
	'ONBOARDING.USER': 'Gebruiker',
	'ONBOARDING.GROUP': 'Groep',
	'ONBOARDING.PASSWORD_RESET': 'Wachtwoord resetten',
	'ONBOARDING.SELECT_ACTION': 'Actie selecteren',
	'ONBOARDING.ACTIVE_STATUS': 'Actieve status',
	'ONBOARDING.PENDING_STATUS': 'Status in behandeling',
	'ONBOARDING.ADD_MORE_USERS': 'Meer gebruikers toevoegen',
	'ONBOARDING.ENTITLEMENT_NAME': 'Naam netwerkrecht',
	'ONBOARDING.NO_ACCESS': 'Geen toegang',
	'ONBOARDING.READ_ONLY': 'Alleen-lezen',
	'ONBOARDING.READ_WRITE': 'Lezen-schrijven',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Groepsmachtigingen admin',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Groepsmachtigingen bewerken',
	'ONBORDING.USER_GROUPS': 'Gebruikersgroepen',
	'ONBOARDING.CREATE_GROUP': 'Groep maken',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Bestaande gebruikersgroep selecteren',
	'ONBOARDING.GROUP_NAME': 'Groepsnaam',
	'ONBOARDING.PAUSE': 'Tijdelijk stopzetten',
	'ONBOARDING.REACTIVATE': 'Opnieuw activeren',
	'ONBOARDING.ACTIVATE': 'Activeren',
	'ONBOARDING.REMOVE': 'Verwijderen',
	'ONBOARDING.RESEND_INVITE': 'Uitnodiging opnieuw verzenden',
	'ONBOARDING.CANCEL_INVITE': 'Uitnodiging annuleren',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': 'Weet u zeker dat u de gebruiker wilt verwijderen?',
	'ONBOARDING.USER_PROFILE': 'Gebruikersprofiel',
	'ONBOARDING.SUSPEND': 'Opschorten',
	'ONBOARDING.USER_MANAGEMENT': 'Gebruikersbeheer',
	'ONBOARDING.MAILING': 'Mailing',
	'ONBOARDING.BILLING': 'Facturering',
	'ONBOARDING.SECONDARY': 'Tweede',
	'ONBOARDING.PRIMARY': 'Hoofd',
	'ONBOARDING.PHONE': 'Telefoon',
	'ONBOARDING.ENGLISH': 'Engels VS',
	'ONBOARDING.SAVE_USER': 'Gebruiker opslaan',
	'ONBOARDING.BULK_UPLOAD': 'Bulkupload',
	'ONBOARDING.FOR_BULK_UPLOADS': 'voor bulkuploads',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'CSV-sjabloon downloaden',
	'ONBOARDING.SEND_INVITE': 'Uitnodiging verzenden',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': 'Door tijdelijke stopzetting verliezen gebruikers toegang tot hun {companyName}-accounts. Tijdelijk stopgezette gebruikers mogen geen toegangsaanvraag doen, maar de beheerder kan het gebruikersaccount opnieuw activeren om opnieuw toegang te verkrijgen. Tijdelijk stopgezette gebruikers moeten hun accountwachtwoord resetten.',
	'ONBOARDING.PAUSE_USER': 'Gebruiker tijdelijk stopzetten',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Toegewezen gebruikersgroep',
	'ONBOARDING.ACCESS_LEVEL': 'Toegangsniveau',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': 'Link voor wachtwoord resetten naar e-mail verzenden',
	'ONBOARDING.EDIT_USER': 'Gebruiker bewerken',
	'ONBOARDING.RESET_PASSWORD': 'Wachtwoord resetten',
	'ONBOARDING.CURRENT_STATUS': 'Actuele status',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'Toegewezen gebruikersmachtigingen bekijken',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Individuele machtigingen beheren',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Individuele machtigingen',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Effectieve machtigingen',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Toegewezen gebruikersgroepen',
	'ONBOARDING.ACTION': 'Actie',
	'ONBOARDING.DELETE_PERMISSIONS': 'Machtigingen verwijderen',
	'ONBOARDING.VIEW_PERMISSIONS': 'Machtigingen weergeven',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Nog een gebruikersgroep toevoegen',
	'ONBOARDING.PENDING_INVITATIONS': 'Lopende uitnodigingen',
	'ONBOARDING.PERMISSIONS': 'Machtigingen',
	'ONBOARDING.CLOSE': 'Sluiten',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Individuele machtigingen opslaan',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': 'Er wordt toegang verleend tot de volgende M2M-accounts',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Toegangsbeheer',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Gebruikers serviceprovider',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Gebruikersgroepen serviceprovider',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': 'Voeg serviceprovidergebruikers toe om klantenaccounts te kunnen beheren en de introductie voor nieuwe te verzorgen.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'Geen gebruikersgroepen beschikbaar',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': 'Als u een beperkte bewerking moet uitvoeren, vraagt u uw contactpersoon voor technische zaken om hulp.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': 'Sommige configuraties zijn voorbehouden aan AT&T-supergebruikers.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': 'Gebruikersgroepen worden opgebouwd met het ‘minste rechten’ model.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'Rechten op systeemniveau',
	'ONBOARDING.DENY_ACCESS': 'Toegang weigeren',
	'ONBOARDING.ALLOW_READ': 'Lezen toestaan',
	'ONBOARDING.ALLOW_WRITE': 'Schrijven toestaan',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Groepsmachtigingen bewerken',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'Geen machtigingen beschikbaar',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': 'Er zijn momenteel geen netwerkrechten beschikbaar voor deze tabel om te laten zien',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'M2M-accounts bewerken',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'Naar subaccount verplaatsen',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Kan e-mail ontvangen',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Onbeperkte toegang',
	'ONBOARDING.ACTIVE': 'Active', // later verwijderen
	'ONBOARDING.PAUSED': 'Paused', // later verwijderen
	'ONBOARDING.VIEW_USER_GROUP': 'Gebruikersgroep weergeven',
	'ONBOARDING.DELETE_USER_GROUP': 'Gebruikersgroep verwijderen',
	'ONBOARDING.SAVE': 'Opslaan',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': 'Selecteer de groep waartoe de gebruiker moet behoren:',

	'ONBOARDING.ALLOW_ACCESS': 'Toegang toestaan',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Nieuw wachtwoord maken',
	'ONBOARDING.NEW_PASSWORD': 'Nieuw wachtwoord',
	'ONBOARDING.CONFIRM_PASSWORD': 'Wachtwoord bevestigen',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Nieuwe wachtwoord invoeren',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Voer uw nieuwe wachtwoord in',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Terug naar aanmelden',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'Geen informatie verstrekt.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': 'om adresgegevens toe te voegen.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Toegewezen accounts bewerken',
	'ONBOARDING.EDIT_PERMISSIONS': 'Machtigingen bewerken',
	'ONBOARDING.SUSPENDED': 'Opgeschort',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Beschikbare lopende uitnodigingen',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': 'In volgende stap kunt u nieuwe groepsmachtigingen afstemmen',
	'ONBOARDING.GRANT_ACCESS': 'Toegang verlenen',
	'ONBOARDING.SELECT_USER_GROUP': 'Selecteer een gebruikersgroep',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': 'Geen systeemuitnodigingen beschikbaar',
	'ONBOARDING.ADD_USER_GROUP': 'Gebruikersgroep toevoegen',
	'ONBOARDING.ADD_ADDRESS': 'Adres toevoegen',
	'ONBOARDING.CROP_IMAGE': 'Afbeelding bijsnijden',
	'ONBOARDING.CROP': 'Bijsnijden',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': 'Deze actie is onherstelbaar, en de gehele gebruikersgeschiedenis zal verloren gaan in het systeem.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': 'Door het profiel te verwijderen, wordt de gebruikerstoegang tot alle accounts verwijderd waartoe u momenteel toegang hebt.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Profiel {name} {lastName} verwijderen',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Bevestigingszin invoeren',
	'ONBOARDING.DELETE_PROFILE': 'Profiel verwijderen',
	'ONBOARDING.DELETE_USER': 'Gebruiker verwijderen',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': 'Reden voor tijdelijke stopzetting (optioneel)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': 'Weet u zeker dat u door wilt gaan?',
	'ONBOARDING.REACTIVATE_USER': 'Gebruiker opnieuw activeren',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': 'Als u op Ja klikt, krijgt u binnenkort een e-mail om uw wachtwoord te herstarten.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Gebruikerswachtwoord resetten',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': 'Weet u zeker dat u door wilt gaan met het herstarten van uw wachtwoord?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Gebruikersgroep toewijzen',
	'ONBOARDING.ASSIGN': 'Toewijzen',
	'ONBOARDING.AND_OTHERS': 'En {totalCount} anderen...',
	'ONBOARDING.MY_PROFILE': 'Mijn profiel',
	'ONBOARDING.EDIT_USER_GROUP': 'Gebruikersgroep bewerken',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Individuele machtigingen bewerken',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'Individuele machtigingen weergeven',
	'ONBOARDING.NO_USERS_AVAILABLE': 'Geen gebruiker beschikbaar',
	'ONBOARDING.CONTACTS': 'Contactpersonen',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': 'Weet u zeker dat u door wilt gaan met deze bewerking?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Gebruikersgroep verwijderen',
	'ONBOARDING.CONTACT_TYPE': 'Type contactpersoon',
	'ONBOARDING.CANCEL_INVITATION': 'Uitnodiging annuleren',
	'ONBOARDING.RESEND_INVITATION': 'Uitnodiging opnieuw verzenden',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Gebruikersgroep opnieuw activeren',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Gebruikersgroep opschorten',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': 'Er zijn geen toe te wijzen gebruikersgroepen',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'Adrestype bestaat',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Nieuw adres toevoegen',
	'ONBOARDING.ADD_NEW_CONTACT': 'Nieuw contactpersoon toevoegen',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Nog een nieuw contactpersoon toevoegen',
	'ONBOARDING.COMPANY_FEATURES': 'Bedrijfsfunctie',
	'ONBOARDING.INFO_NOT_PROVIDED': 'Informatie niet verstrekt',
	'ONBOARDING.COMPANY_SETTINGS': 'Bedrijfsinstellingen',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': 'Als u al een account hebt, gaat u verder met aanmelden.',
	'ONBOARDING.ACCOUNT_ACTIVATED': 'U krijgt straks een e-mail met instructies over hoe u uw wachtwoord kunt maken en toegang tot uw account krijgt.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': 'Wachtwoordreset wordt beheerd door AT&T Global Sign-On',

	//  Factureringsplatform
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Type adres
	'ONBOARDING.BILLING_ADDRESS': 'Factuuradres',
	'ONBOARDING.HOME_ADDRESS': 'Woonadres',

	//  Type contactpersoon
	'ONBOARDING.MAIN_CONTACT': 'Hoofdcontactpersoon',
	'ONBOARDING.BACKUP_CONTACT': 'Back-upcontactpersoon',
	'ONBOARDING.TECHNICAL_OWNER': 'Technische eigenaar',

	//  Type adres
	'ONBOARDING.SHIPPING': 'Verzending',
	'ONBOARDING.HOME': 'Home',
	'ONBOARDING.ANOTHER': 'Nog een',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'IoT Console starten',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': 'Dt nummer is verplicht om facturen te kunnen weergeven in IoT Console.',
	'ONBOARDING.BILLING_REPORTS': 'Factureringsrapporten',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': 'Dit vak moet worden ingeschakeld om factureringsgegevens weer te geven.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': 'Er is geen ondersteuning voor factureringsgegevens op dit platform.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': 'Factureringsconfiguratie bekijken of bewerken',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'Kan account-id niet vinden',
	'ONBOARDING.ARE_YOU_SURE': 'Weet u het zeker?',
	'ONBOARDING.REMOVE_CONTACT': 'Contactpersoon verwijderen',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'Id voor pod, groep of exemplaar',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': 'Apparaatverbindingsplatform',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': 'Weet u zeker dat u deze bewerking wilt uitvoeren?',

	// Added after 7,4
	'ONBOARDING.ANONYMIZED': 'Anoniem gemaakt',
    'ONBOARDING.ACQUISITION_BOUNTY': 'Wervingsbonus',
'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Gecombineerde wervingsbonus',
    'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Gecombineerde hardwaresubsidie',
    'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': 'Gecombineerde door partner gefinancierde pakketten',
    'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Gecombineerde gepoolde verbruikskosten',
    'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Gecombineerde abonnementskosten',
    'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Gecombineerde proef kosten delen',
    'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Combinatie-overzicht verbruik',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': 'Maandelijks gecombineerd totaalbedrag factuur',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': 'Maandelijks gecombineerde factureringsgegevens',
    'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': 'Maandelijks gecombineerde niet-gepoolde verbruikskosten',
    'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Maandelijks gecombineerde overige kosten',
    'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Door partner gefinancierde pakketten',
    'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'Status accountapparaat per maand',
    'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': 'Verbruiksrapport account per satellietnetwerk',

};
