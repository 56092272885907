import { handleActions } from 'redux-actions';
import { ORIGINAL_FILTER_INIT, ORIGINAL_FILTER_SUCCESS } from './constants';
import { Status } from '../../../../../utils/constants';

const initialState = {
	data: null,
	error: null,
	status: Status.INIT
};

export default handleActions(
	{
		[ORIGINAL_FILTER_INIT]: (state) => ({
			...state,
			error: null,
			status: Status.INIT,
			data: null
		}),
		[ORIGINAL_FILTER_SUCCESS]: (state, action) => ({
				...state,
				error: null,
				status: Status.DONE,
				data: action.payload
			})
	},
	initialState
);
