import globalAction from '../../../../redux/global/globalAction';

import { GET_OTHER_CHARGES, GET_OTHER_CHARGES_FILTERS } from './constants';

import {
	getOtherCharges as getOtherChargesService,
	getOtherChargesFilters as getOtherChargesFiltersService
} from '../../services/OtherChargesService';

/** ************ get other charges ************* */
export const getOtherCharges = (id, params = {}) =>
	globalAction(GET_OTHER_CHARGES, getOtherChargesService, {
		id,
		params
	});

/** ************ get other charges filters ************* */
export const getOtherChargesFilters = (id, params = {}) =>
	globalAction(GET_OTHER_CHARGES_FILTERS, getOtherChargesFiltersService, {
		id,
		params
	});
