export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': 'Eliminare impostazione grafico definito?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': 'Confermare l’eliminazione dell’impostazione {settingName}?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Grafici attualmente disponibili',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': 'Questo grafico è già presente nelle impostazioni dell’Utente. È permesso un solo tipo di grafico.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'Nessuna descrizione del presente grafico',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Tipo grafico',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Esporta dati grafico',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Rimuovi grafico',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Impostazioni grafico',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Aggiungi grafico',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'Aggiunta nuovo grafico riuscita',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Aggiunta nuovo grafico non riuscita',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'Aggiornamento grafico riuscito',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Aggiornamento grafico non riuscito',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Eliminazione impostazioni grafico riuscita',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Eliminazione impostazioni grafico non riuscita'
};
