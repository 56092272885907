import React from 'react';
import { object, array, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Button from '../../../../../lib/DigitalComponents/Button';
import {
	UserAllowedToAccess,
} from '../../../../../utils/AuthSelector';
import hoc from '../Cards';

import styles from './Desktop.scss';

const TicketingComponentRW = UserAllowedToAccess(['mnc.ticketing_rw']);
const TicketingComponentRO = UserAllowedToAccess(['mnc.ticketing_ro']);

function Desktop({
	endpoint,
	goToTicketing,
	goToNewTicket,
	cards,
}) {
	return (
		<div data-spec="cards-desktop" className={styles.wrapper}>
			{cards.map((item, index) => (
				!item.hide
					? (
						<div className={styles.card} key={item.id}>
							<span className={styles.title}>
								<FormattedMessage
									id={item.id}
									defaultMessage={item.defaultMessage}
								/>
							</span>
							<span
								className={cn({
									[styles.item]: true,
									[styles[`item_${endpoint.status
										? endpoint.status.toLowerCase()
										: ''}`]]: (
										index === 2
									),
									[styles.item_connection_yes]: (
										index === 3 && endpoint.dataSession === '1'
									),
									[styles.item_connection_no]: (
										index === 3 && endpoint.dataSession === '0'
									),
								})}
							>
								{item.data}
							</span>
						</div>
					)
					: null
			))}
			<TicketingComponentRW>
					<div className={styles.card}>
						<span className={styles.button_title}>
							<FormattedMessage
								id="NEED_HELP"
								defaultMessage="NEED HELP?"
							/>
						</span>
						<span>
							<Button
								variant="link"
								onClick={goToNewTicket}
								label={<FormattedMessage
									id="GET_HELP_WITH_THIS_ENDPOINT"
									defaultMessage="Get help with this endpoint"
								/>}
							/>
						</span>
					</div>
			</TicketingComponentRW>
			<TicketingComponentRO>
					<div className={styles.card}>
						<span className={styles.button_title}>
							<FormattedMessage
								id="NEED_HELP"
								defaultMessage="NEED HELP?"
							/>
						</span>
						<span>
							<Button
								variant="link"
								onClick={goToTicketing}
								label={<FormattedMessage
									id="HELP_SUPPORT"
									defaultMessage="Help & Support"
								/>}
							/>
						</span>
					</div>
			</TicketingComponentRO>
		</div>
	);
}

Desktop.propTypes = {
	endpoint: object,
	goToTicketing: func,
	goToNewTicket: func,
	cards: array,
};

Desktop.defaultProps = {
	endpoint: {},
	goToTicketing: undefined,
	goToNewTicket: undefined,
	cards: [],
};

export default hoc(Desktop);
