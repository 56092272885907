import React, { PureComponent } from 'react';
import { func, arrayOf, object, string, bool, objectOf } from 'prop-types';
import {
	FormattedDate,
	FormattedNumber,
	FormattedTime,
	injectIntl
} from 'react-intl';
import { orderBy } from 'lodash';

import Loader from '../../../../lib/DigitalComponents/Loader';
import { isValidDate } from '../../../../utils/constants';
import { sessionsHeader, sessionsSignature } from '../../utils/constants';

import styles from './Sessions.scss';

export default function Sessions(WrapperComponent) {
	class SessionsComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				settingsState: props.settings[0]
					? JSON.parse(props.settings[0].value)
					: {},
				isEditTableModalOpen: false
			};
		}

		componentDidMount() {
			const {
				getSessionsData,
				endpointId,
				updateSettings,
				createSettings,
				settings
			} = this.props;
			const { settingsState } = this.state;

			if (settings.length === 0) {
				const newSettings = {
					header: sessionsHeader,
					signature: sessionsSignature
				};

				createSettings({
					refTypeId: 10,
					name: 'SingleEndpointSessionsSettings',
					value: JSON.stringify(newSettings)
				});
				this.setState({ settingsState: newSettings });
			}
			if (
				settings.length > 0 &&
				settingsState.signature !== sessionsSignature
			) {
				const newSettings = {
					header: sessionsHeader,
					signature: sessionsSignature
				};

				updateSettings({ value: JSON.stringify(newSettings) }, settings[0].id);
				this.setState({ settingsState: newSettings });
			}

			getSessionsData(endpointId);
		}

		refresh = (getSessionsData, endpointId) => getSessionsData(endpointId);

		formatUnits = (value = 0) => {
			const unitsObject = {
				k: 1024,
				sizes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
			};
			let i =
				value !== 0 ? Math.floor(Math.log(value) / Math.log(unitsObject.k)) : 0;

			if (i > unitsObject.sizes.length) {
				i = unitsObject.sizes.length - 1;
			} else if (i < 0) {
				i = 0;
			}

			return {
				value: value / unitsObject.k ** i,
				unit: unitsObject.sizes[i]
			};
		};

		isToday = (date) => {
			const today = new Date();
			const dateToCheck = new Date(date);

			if (dateToCheck instanceof Date && !Number.isNaN(dateToCheck.getTime())) {
				return false;
			}

			return (
				today.getFullYear() === dateToCheck.getFullYear() &&
				today.getMonth() === dateToCheck.getMonth() &&
				today.getDate() === dateToCheck.getDate()
			);
		};

		onDragEnd = (fromIndex, toIndex) => {
			const { updateSettings, settings } = this.props;
			const { settingsState } = this.state;

			if (toIndex < 0) return;

			const data = settingsState
				? orderBy(settingsState.header, ['order'], ['asc'])
				: sessionsHeader;
			[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];
			const newSettings = {
				signature: sessionsSignature,
				header: data.map((item, index) => {
					item.order = index + 1;
					return item;
				})
			};

			updateSettings({ value: JSON.stringify(newSettings) }, settings[0].id);
			this.setState({ settingsState: newSettings });
		};

		submitColumns = (newValue) => {
			const { updateSettings, settings } = this.props;

			updateSettings({ value: JSON.stringify(newValue) }, settings[0].id);
			this.setState({
				settingsState: newValue
			});
		};

		getOptions = (target) => {
			const { sessionData } = this.props;
			const { settingsState } = this.state;
			let corruptedValue = true;
			if (sessionData.sessions) {
				sessionData.sessions.forEach((element) => {
					if (Object.prototype.hasOwnProperty.call(element, 'start')) {
						corruptedValue = false;
					}
				});
			}

			const tempOptions = {
				header: settingsState.header,
				customComponents: {
					dataVolume: {
						type: 'custom',
						component: (val) => {
							if (corruptedValue) return <span data-spec="corrupted-data">-</span>;

							return !Number.isNaN(
								val.downlinkDataVolume + val.uplinkDataVolume
							) ? (
								<>
									<FormattedNumber
										value={
											this.formatUnits(
												val.downlinkDataVolume + val.uplinkDataVolume
											).value
										}
										maximumFractionDigits={1}
									/>
									{' '}
									{
										this.formatUnits(
											val.downlinkDataVolume + val.uplinkDataVolume
										).unit
									}
								</>
							) : null;
						}
					},
					dateTime: {
						type: 'custom',
						component: (val) => {
							if (corruptedValue) return <span data-spec="corrupted-data">-</span>;
							if (!isValidDate(val.start)) return null;

							return this.isToday(val.start) ? (
								<FormattedTime value={val.start} />
							) : (
								<FormattedDate
									value={val.start}
									year="numeric"
									month="short"
									day="2-digit"
									timeZone="UTC"
								/>
							);
						}
					},
					start: {
						type: 'custom',
						component: (val) => {
							if (corruptedValue) return <span data-spec="corrupted-data">-</span>;
							if (!isValidDate(val.start)) return null;

							return (
								<FormattedDate
									data-spec="session-start-date"
									value={val.start}
									year="numeric"
									month="2-digit"
									day="2-digit"
									hour="2-digit"
									minute="2-digit"
									second="2-digit"
									timeZone="UTC"
									hour12={false}
								>
									{(formattedDate) => formattedDate.replace(',', '')}
								</FormattedDate>
							);
						}
					},
					end: {
						type: 'custom',
						component: (val) => {
							if (corruptedValue) return <span data-spec="corrupted-data">-</span>;
							if (!isValidDate(val.end)) return null;

							return (
								<FormattedDate
									data-spec="session-end-date"
									value={val.end}
									year="numeric"
									month="2-digit"
									day="2-digit"
									hour="2-digit"
									minute="2-digit"
									second="2-digit"
									timeZone="UTC"
									hour12={false}
								>
									{(formattedDate) => formattedDate.replace(',', '')}
								</FormattedDate>
							);
						}
					},
					ipAddress: {
						type: 'custom',
						component: (val) =>
							(corruptedValue ? (
								<span data-spec="corrupted-data">-</span>
							) : (
								val.ipAddress
							))
					},
					carrier: {
						type: 'custom',
						component: (val) =>
							(corruptedValue ? (
								<span data-spec="corrupted-data">-</span>
							) : (
								val.carrier
							))
					},
					apn: {
						type: 'custom',
						component: (val) =>
							(corruptedValue ? (
								<span data-spec="corrupted-data">-</span>
							) : (
								val.apn
							))
					}
				}
			};

			if (target === 'mobile') {
				return tempOptions;
			}

			return {
				...tempOptions,
				tableOptions: {
					draggable: true,
					onDragEnd: (fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex)
				}
			};
		};

		openEditTableModal = () => {
			this.setState({
				isEditTableModalOpen: true
			});
		};

		closeEditTableModal = () => {
			this.setState({ isEditTableModalOpen: false });
		};

		exportSessionsDataFunction = () => {
			const { exportSessionsData, endpointId, intl } = this.props;

			exportSessionsData(endpointId, intl);
		};

		renderIndicatorDots = ({ index }) => (
			<div
				data-spec="multiple-dot-wrapper"
				className={styles.indicator_dots_wrapper}
			>
				<span
					className={
						index === 0
							? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
							: styles.indicator_dot
					}
				/>
				<span
					className={
						index === 1
							? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
							: styles.indicator_dot
					}
				/>
				<span
					className={
						index === 2
							? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
							: styles.indicator_dot
					}
				/>
				<span
					className={
						index === 3
							? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
							: styles.indicator_dot
					}
				/>
				<span
					className={
						index === 4
							? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
							: styles.indicator_dot
					}
				/>
			</div>
		);

		render() {
			const {
				isSessionPending,
				sessionData,
				getSessionsData,
				endpointId,
				messages,
				isExportSessionsDataPending
			} = this.props;
			const { settingsState, isEditTableModalOpen } = this.state;

			if (isSessionPending) {
				return <Loader data-spec="single-endpoint-sessions-loader" />;
			}

			return (
				<WrapperComponent
					data-spec="sessions-table"
					refresh={() => this.refresh(getSessionsData, endpointId)}
					currentActiveSession={
						sessionData.sessions
							? sessionData.sessions.find((session) => session.inSession)
							: null
					}
					tableData={sessionData.sessions || []}
					options={this.getOptions}
					messages={messages}
					exportSessionsData={this.exportSessionsDataFunction}
					isExportSessionsDataPending={isExportSessionsDataPending}
					settings={settingsState}
					sessionsSignature={sessionsSignature}
					header={sessionsHeader}
					submitColumns={this.submitColumns}
					isEditTableModalOpen={isEditTableModalOpen}
					openEditTableModal={this.openEditTableModal}
					closeEditTableModal={this.closeEditTableModal}
					renderIndicatorDots={this.renderIndicatorDots}
				/>
			);
		}
	}

	SessionsComponent.propTypes = {
		getSessionsData: func,
		settings: arrayOf(object),
		endpointId: string,
		updateSettings: func,
		createSettings: func,
		isSessionPending: bool,
		sessionData: object,
		messages: objectOf(string),
		exportSessionsData: func,
		intl: object,
		isExportSessionsDataPending: bool
	};
	SessionsComponent.defaultProps = {
		getSessionsData: undefined,
		settings: [],
		endpointId: '',
		updateSettings: undefined,
		createSettings: undefined,
		isSessionPending: false,
		sessionData: {},
		messages: {},
		exportSessionsData: undefined,
		intl: {},
		isExportSessionsDataPending: false
	};

	const wrappedComponentName =
		WrapperComponent.displayName || WrapperComponent.name || 'Component';

	SessionsComponent.displayName = `Sessions(${wrappedComponentName})`;

	return injectIntl(SessionsComponent);
}
