export default {
	'ERRORS.ERROR': 'Fout',
	'ERRORS.FORBIDDEN_ACCESS': 'Verboden toegang',
	'ERRORS.RETURN_TO_HOME_PAGE': 'TERUG NAAR HOMEPAGE',
	'ERRORS.PAGE_NOT_FOUND': 'We kunnen de pagina die u zoekt niet vinden.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Interne serverfout',
	'ERRORS.REQUEST_TIMED_OUT': 'Er is een time-out opgetreden voor de aanvraag',
	'ERRORS.LOGOUT': 'Afmelden',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': 'Het lijkt erop dat u niet bent gemachtigd om deze applicatie te openen.',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': 'Als u denkt dat u wel toegang tot deze applicatie zou moeten hebben, neemt u contact op met uw bedrijfsbeheerder.'
};
