import React from 'react';
import { string, func, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PermissionsView from '../../views/PermissionsView';
import ActionBar from '../../../Shared/views/ActionBarView';
import Button from '../../../../lib/DigitalComponents/Button';

import {
	checkIfUserIsSystem,
	isUserAllowedToAccess,
} from '../../../../utils/AuthSelector';

const isPersonalUserGroup = (type) => {
	if (type === 3 || type === 4) return true;
};

export default function UserGroupPermissions(props) {
	const {
		userGroupId,
		permissionsRequest,
		goToEditUserGroupPermissions,
		myProfile,
		userGroup,
		user,
	} = props;
	return (
		<div data-spec="user-group-permissions">
			<ActionBar
				actions={
					<Button
						label={
							isPersonalUserGroup(userGroup && userGroup.userGroupTypeId) ? (
								<FormattedMessage
									id="ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS"
									defaultMessage="Edit Individual Permissions"
								/>
							) : (
								<FormattedMessage
									id="ONBOARDING.EDIT_GROUP_PERMISSIONS"
									defaultMessage="Edit Group Permissions"
								/>
							)
						}
						disabled={
							(permissionsRequest || checkIfUserIsSystem(user)
								? !isUserAllowedToAccess(
										['system.accessmanagement.usergroups_rw'],
										user
								  )
								: !isUserAllowedToAccess(
										['mnc.accessmanagement.usergroups_rw'],
										user
									)
							) || myProfile
						}
						dataSpec="edit-group-permissions-button"
						variant="primary"
						onClick={goToEditUserGroupPermissions}
					/>
				}
			/>
			<PermissionsView {...props} id={userGroupId} />
		</div>
	);
}

UserGroupPermissions.propTypes = {
	userGroupId: string,
	permissionsRequest: bool,
	goToEditUserGroupPermissions: func,
	myProfile: bool,
	userGroup: object,
	user: object
};
