export default {
	'REPORTS.REPORT': 'Bericht',
	'REPORTS.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'REPORTS.M2M_PLATFORM': 'M2M-Plattform',
	'REPORTS.FREQUENCY': 'Frequenz',
	'REPORTS.REPORT_DATE': 'Berichtsdatum',
	'REPORTS_TABS.ENDPOINTS': 'Endpunkte',
	'REPORTS_TABS.USAGE_SUMMARY': 'Nutzungszusammenfassung',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Übertragungsaktivitäten',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Kontoüberblick',
	'REPORTS_TABS.BILLING_DETAILS': 'Abrechnungsdetails',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Referenzberichte',
	'REPORTS_TABS.MY_EXPORTS': 'Meine Exporte',
	'REPORTS_TABS.USAGE_REPORTS': 'Nutzungsberichte',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Umgebuchte Berichte',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'Keine Berichte verfügbar',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': 'Für diese Tabelle sind derzeit keine Berichte zur Ansicht verfügbar',
	'REPORTS.FILE_NAME': 'Dateinamen',
	'REPORTS.FILE_DATE': 'Dateidatum',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Angefordert von'
};
