import React, { Component } from 'react';
import { Field, reduxForm, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import Loading from '../../../../lib/DigitalComponents/Loader';

import { required, onlyNumbers, maxLength } from '../../../../utils/validators';
import { addSimSku, editSimSku } from '../../redux/simOrdering/actions';

import styles from './EditSimSkusModal.scss';

const maxLength200 = maxLength(200);

const submitEditSimSku = (values, dispatch, props) => {
	if (props.initialValues.id) {
		const simSku = {
			allowedToOrder: values.allowedToOrder,
			description: values.description,
			name: values.name,
			unitPrice: parseFloat(values.unitPrice)
		};

		dispatch(editSimSku({ id: props.initialValues.id, data: simSku }));
	} else {
		dispatch(
			addSimSku({
				...values,
				unitPrice: parseFloat(values.unitPrice),
				platformId: props.accountId
			})
		);
	}
};

class EditSimSkusModal extends Component {
	submitEditSimSkuForm = () => {
		const { dispatch } = this.props;
		dispatch(submit('EditSimSku'));
	};

	render() {
		const {
			show,
			onClose,
			invalid,
			submitting,
			submitSucceeded,
			initialValues
		} = this.props;

		return (
			<Modal
				data-spec="edit-sim-sku-modal"
				show={show}
				onClose={onClose}
				className={styles.modal}
			>
				<FormattedMessage
					id="ONBOARDING.EDIT_SIM_SKU"
					defaultMessage="Edit SIM SKU"
				>
					{(editSimTitle) => (
						<FormattedMessage
							id="ONBOARDING.ADD_SIM_SKU"
							defaultMessage="Add SIM SKU"
						>
							{(addSIMTitle) => (
								<ModalHeader
									title={(initialValues.id && editSimTitle) || addSIMTitle}
									closeModal={onClose}
								/>
							)}
						</FormattedMessage>
					)}
				</FormattedMessage>

				<ModalBody className={styles.modal_body}>
					{submitting || submitSucceeded ? (
						<Loading />
					) : (
						<>
							<div className={styles.first_row}>
								<div className={styles.first_field}>
									<FormattedMessage
										id="ONBOARDING.ENTER_SIM_SKU_HERE"
										defaultMessage="Enter SIM SKU Here"
									>
										{(formattedValue) => (
											<Field
												component={Input}
												name="name"
												placeholder={formattedValue}
												label={
													<FormattedMessage
														id="ONBOARDING.SIM_SKU"
														defaultMessage="SIM SKU"
													/>
												}
												validate={[required]}
											/>
										)}
									</FormattedMessage>
								</div>
								<div className={styles.second_field}>
									<FormattedMessage
										id="ONBOARDING.UNIT_PRICE"
										defaultMessage="Unit Price"
									>
										{(formattedValue) => (
											<Field
												component={Input}
												name="unitPrice"
												placeholder={formattedValue}
												label={
													<FormattedMessage
														id="ONBOARDING.UNIT_PRICE"
														defaultMessage="Unit Price"
													/>
												}
												validate={[required, onlyNumbers]}
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
							<div className={styles.checkbox}>
								<FormattedMessage
									id="ONBOARDING.ALLOW_TO_ORDER"
									defaultMessage="Allow to Order"
								>
									{(formattedValue) => (
										<Field
											type="checkbox"
											component={Checkbox}
											label={formattedValue}
											name="allowedToOrder"
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={styles.description}>
								<FormattedMessage
									id="ONBOARDING.ENTER_SKU_DESCRIPTION"
									defaultMessage="Enter SKU Description"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											validate={[maxLength200]}
											label={
												<FormattedMessage
													id="ONBOARDING.DESCRIPTION"
													defaultMessage="Description"
												/>
											}
											name="description"
											componentClass="textarea"
											placeholder={formattedValue}
										/>
									)}
								</FormattedMessage>
							</div>
						</>
					)}
				</ModalBody>

				<ModalFooter>
					<Button
						dataSpec="save-edit-sim-sku-modal"
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						onClick={onClose}
						type="button"
					/>
					<Button
						type="button"
						dataSpec="save-edit-sim-sku-modal"
						variant="primary"
						label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
						onClick={this.submitEditSimSkuForm}
						disabled={invalid || submitting || submitSucceeded}
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, object, number } = PropTypes;

EditSimSkusModal.propTypes = {
	onClose: func,
	show: bool,
	id: number,
	dispatch: func,
	invalid: bool,
	submitting: bool,
	submitSucceeded: bool,
	initialValues: object
};

EditSimSkusModal.defaultProps = {
	show: false
};

export default reduxForm({
	form: 'EditSimSku',
	onSubmit: submitEditSimSku
})(EditSimSkusModal);
