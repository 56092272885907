import React, { PureComponent } from 'react';
import {
	bool,
	func,
	any,
	string,
	array,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
	uniqBy,
	isEqual,
} from 'lodash';

import Button from '../../../../lib/DigitalComponents/Button';
import Switch from '../../../../lib/DigitalComponents/Switch';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader
	from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter
	from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';

import styles from './EditTableModal.scss';

export default class EditTableModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			newValues: [],
			submitColumnsButtonDisabled: true,
			headers: [],
			original: [],
		};
	}

	componentDidMount() {
		this.setHeaders();
	}

	componentDidUpdate() {
		const { headers } = this.state;

		if (headers.length === 0) {
			this.setHeaders();
		}
	}

	setHeaders = () => {
		const { columns, tableTabs } = this.props;
		const allItems = tableTabs;
		let allColumnsList = [];

		allItems
			.filter((item) => item.name)
			.forEach((item) => {
				allColumnsList = [
					...allColumnsList,
					{
						title: item.title,
						name: item.name,
						checked: columns.header ? !!columns.header.find((column) => (
							column.name === item.name
						)) : false,
					},
				];
			});

		const headers = uniqBy(allColumnsList, 'name');

		this.setState({
			headers,
			original: headers,
		});
	}

	closeModal = () => {
		const { onClose } = this.props;

		onClose();
		this.setState({ headers: [], submitColumnsButtonDisabled: true });
	};

	handleDelete = () => {
		const { signature, tableTabs, submitColumns } = this.props;
		const newValue = {
			signature,
			header: [
				...tableTabs
					.filter((item) => item.checked)
					.map((item, index) => (
						{
							title: item.title,
							name: item.name,
							value: index + 1,
						}
					)),
			],
		};

		this.setState({ headers: tableTabs });
		submitColumns(newValue);
		this.closeModal();
	};

	renderButtons = () => {
		const { tableTabs } = this.props;
		const { submitColumnsButtonDisabled, original } = this.state;
		const headersTemp = original.map((header) => (
			{ name: header.name, title: header.title, checked: header.checked }
		));

		return (
			<span data-spec="tab1-buttons">
				<Button
					onClick={this.handleDelete}
					variant="primary"
					className={styles.clear_all}
					label={
						<FormattedMessage
							id="RESET_TO_DEFAULT"
							defaultMessage="Reset to Default"
						/>
					}
					dataSpec="reset-to-default"
					disabled={isEqual(tableTabs, headersTemp)}
				/>
				<Button
					onClick={this.handleSubmit}
					variant="primary"
					label={
						<FormattedMessage
							id="APPLY_CHANGE"
							defaultMessage="Apply change"
						/>
					}
					dataSpec="apply-change"
					disabled={submitColumnsButtonDisabled}
				/>
			</span>
		);
	};

	submitColumnsDisabled = (newValues, oldValues) => {
		const { excludeColumns } = this.props;
		let compareColumns = [];
		let disable = true;

		if (newValues.length - excludeColumns.length > 1) {
			compareColumns = newValues.filter((newValue) => oldValues
				.some((oldValue) => oldValue.name === newValue.name));
			disable = compareColumns.length === oldValues.length
				? oldValues.length === newValues.length
				: false;
		}

		this.setState({
			submitColumnsButtonDisabled: disable,
		});
	};

	onSubmitColumns = (newValues) => {
		this.setState({
			newValues,
		});
	};

	handleSubmit = () => {
		const { signature, submitColumns, columns } = this.props;
		const { newValues } = this.state;
		const newValue = { signature, header: newValues };

		submitColumns(newValue, columns.id);
		this.closeModal();
	};

	changeList = (e, index) => {
		const { headers, original } = this.state;
		const newHeaders = headers.map((item, i) => {
			if (i === index) {
				return {
					title: item.title,
					name: item.name,
					checked: !item.checked,
					order: item.value ? item.value : index,
				};
			}
			return item;
		});

		this.setState({ headers: newHeaders }, () => {
			this.submitColumns(newHeaders, original);
		});
	};

	submitColumns = (newColumns, oldColumns) => {
		let newValues = [];
		let oldValues = [];

		newColumns.forEach((item, index) => {
			if (item.checked === true) {
				newValues = [
					...newValues,
					{
						title: item.title,
						name: newColumns[index].name,
						order: index,
					},
				];
			}
		});
		if (oldColumns.length > 0) {
			oldColumns.forEach((item, index) => {
				if (item.checked === true) {
					oldValues = [
						...oldValues,
						{
							title: item.title,
							name: oldColumns[index].name,
							order: index + 1,
						},
					];
				}
			});
		} else {
			oldValues = [];
		}

		this.onSubmitColumns(newValues);
		this.submitColumnsDisabled(newValues, oldValues);
	};

	render() {
		const {
			excludeColumns,
			show,
		} = this.props;
		const { headers } = this.state;
		const headersNumber = excludeColumns
			? headers.filter((item) => !excludeColumns.includes(item.name))
			: headers;
		const tabs = [
			{
				id: 'tab1',
				displayName: (
					<FormattedMessage
						id="EDIT_TABLE_PARAMETERS"
						defaultMessage="Edit Table Parameters"
					/>
				),
				content: () => (
					<div data-spec="edit-table-columns">
						<div className={styles.title}>
							<FormattedMessage
								id="SELECT_MINIMUM_OF_2"
								defaultMessage="Select minimum of 2 to apply to the table. "
							/>
							<span className={styles.number_of_columns}>
								{headersNumber.filter((item) => item.checked === true).length}
							</span>
							<FormattedMessage id="OF" defaultMessage=" of " />
							<span className={styles.number_of_columns}>
								{headersNumber.length}
							</span>
							<FormattedMessage
								id="SELECTED"
								defaultMessage=" selected"
							/>
						</div>
						<div data-spec="title">
							{headersNumber.map((item, index) => (
								<Switch
									data-spec="switch"
									key={item.name}
									label={
										<FormattedMessage
											id={item.title}
											defaultMessage="No title"
										/>
									}
									onChange={(event) => this.changeList(event, index)}
									checked={item.checked}
								/>
							))}
						</div>
					</div>
				),
			},
		];

		return (
			<Modal
				data-spec="edit-table-modal"
				show={show}
				onClose={this.closeModal}
			>
				<ModalHeader
					title={
						<FormattedMessage
							id="EDIT_TABLE_PARAMETERS"
							defaultMessage="Edit Table Parameters"
						/>
					}
				/>
				<ModalBody className={styles.modal_body}>
					<ModalTabs
						tabs={tabs}
						onTabChange={(tab) => {
							this.setActiveTab(tab.id);
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						useCloseModalFunction
						variant="link"
						label={
							<FormattedMessage
								id="CANCEL"
								defaultMessage="Cancel"
							/>
						}
						data-spec="edit-table-modal-cancel-button"
					/>
					{this.renderButtons()}
				</ModalFooter>
			</Modal>
		);
	}
}

EditTableModal.propTypes = {
	show: bool,
	onClose: func,
	columns: any,
	submitColumns: func,
	tableTabs: array,
	signature: string,
	excludeColumns: array,
};
