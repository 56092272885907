export default {
	'ERRORS.ERROR': 'Erro',
	'ERRORS.FORBIDDEN_ACCESS': 'Acesso proibido',
	'ERRORS.RETURN_TO_HOME_PAGE': 'VOLTAR À PÁGINA INICIAL',
	'ERRORS.PAGE_NOT_FOUND': 'Não conseguimos encontrar a página que você está procurando.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Erro de servidor interno',
	'ERRORS.REQUEST_TIMED_OUT': 'Solicitação atingiu tempo limite',
	'ERRORS.LOGOUT': 'Logoff',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': 'Opa! Parece que você não tem permissão para acessar esse aplicativo.',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': 'Se você acredita que deveria ter acesso, entre em contato com o administrador de sua empresa.',
};
