/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import styles from './RuleHistoryFiltersModal.scss';

const RuleHistoryFiltersModal = (props) => {
	const renderCustomFields = () => {
		const { rule } = props;
		let customFieldsString = '';
		// eslint-disable-next-line no-plusplus
		for (let i = 1; i < 11; i++) {
			customFieldsString +=
				rule[`customField${i}`] && ` ${rule[`customField${i}`]},`;
		}
		return (
			<div data-spec="history-custom-fields">
				{customFieldsString.slice(0, -1)}
			</div>
		);
	};

	const renderModalContent = () => {
		const { rule } = props;
		const accountIdFilter =
			rule.automationTrigger &&
			rule.automationTrigger.hasAccountId &&
			rule.filterAccountId;
		const baseRatePlanFilter =
			rule.automationTrigger &&
			rule.automationTrigger.hasBaseRatePlan &&
			rule.baseRatePlanId;
		const customFieldsFilter =
			rule.automationTrigger &&
			rule.automationTrigger.hasCustomFields &&
			(rule.customField1 ||
				rule.customField2 ||
				rule.customField3 ||
				rule.customField4 ||
				rule.customField5 ||
				rule.customField6 ||
				rule.customField7 ||
				rule.customField8 ||
				rule.customField9 ||
				rule.customField10);
		const customerIdFilter =
			rule.automationTrigger &&
			rule.automationTrigger.hasCustomerId &&
			rule.customerId;
		const ratePlanIdFilter =
			rule.automationTrigger &&
			(rule.automationTrigger.hasRatePlanId ||
				rule.automationTrigger.hasPrepaidRatePlanId) &&
			rule.ratePlanId;
		const ratePlanTypeFilter =
			rule.automationTrigger &&
			rule.automationTrigger.hasRatePlanId &&
			rule.ratePlanType;
		const statusFilter =
			rule.automationTrigger &&
			rule.automationTrigger.hasStatus &&
			rule.simState;
		return (
			<div data-spec="history-filters-content" className={styles.content}>
				{accountIdFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_ACCOUNT_ID"
								defaultMessage="Account ID"
							/>
						</div>
						<div>{rule.filterAccountId}</div>
					</div>
				)}
				{statusFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_STATUS"
								defaultMessage="Status"
							/>
						</div>
						<div>{rule.simState}</div>
					</div>
				)}
				{baseRatePlanFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_BASE_RATE_PLAN"
								defaultMessage="Base Rate Plan"
							/>
						</div>
						<div>{rule.baseRatePlanId}</div>
					</div>
				)}
				{customerIdFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_CUSTOMER_ID"
								defaultMessage="Customer ID"
							/>
						</div>
						<div>{rule.customerId}</div>
					</div>
				)}
				{ratePlanIdFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_RATE_PLAN_ID"
								defaultMessage="Rate Plan ID"
							/>
						</div>
						<div>{rule.ratePlanId}</div>
					</div>
				)}
				{ratePlanTypeFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_RATE_PLAN_TYPE"
								defaultMessage="Rate Plan Type"
							/>
						</div>
						<div>{rule.ratePlanType}</div>
					</div>
				)}
				{customFieldsFilter && (
					<div className={styles.row}>
						<div className={styles.filter_name}>
							<FormattedMessage
								id="AUTOMATION.FILTER_CUSTOM_FIELDS"
								defaultMessage="Custom Fields"
							/>
						</div>
						<div>{renderCustomFields()}</div>
					</div>
				)}
			</div>
		);
	};

	const renderButtons = () => {
		const { onClose } = props;
		return (
			<div className={styles.button} data-spec="history-filters-button">
				<Button
					onClick={onClose}
					variant="primary"
					type="button"
					label={
						<FormattedMessage
							id={'AUTOMATION.CANCEL'}
							defaultMessage={'Cancel'}
						/>
					}
				/>
			</div>
		);
	};
	const { show, onClose } = props;
	return (
		<Modal show={show} onClose={onClose} data-spec="rule-history-filters-modal">
			<ModalHeader
				title={
					<FormattedMessage
						id="AUTOMATION.APPLIED_FILTERS"
						defaultMessage="Applied Filters"
					/>
				}
			/>
			<ModalBody className={styles.modal__body}>
				{renderModalContent()}
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">{renderButtons()}</ModalFooter>
		</Modal>
	);
};

const { bool, func, object } = PropTypes;

RuleHistoryFiltersModal.propTypes = {
	show: bool,
	onClose: func,
	rule: object
};

export default RuleHistoryFiltersModal;
