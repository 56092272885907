import { connect } from 'react-redux';
import SecurityFeatures from './SecurityFeatures';

import {
	getTabsRequest,
	getTabsFail,
	getTabsSuccess
} from '../../redux/tabs/selectors';

import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';
import { setSelectedItemsInit } from '../../redux/selectedItems/actions';
import { simpleSearchClose } from '../../../Shared/redux/simpleSearch/actions';

import { getTabs } from '../../redux/tabs/actions';

import { setPaginationInit } from '../../../Shared/redux/pagination/actions';

const mapStateToProps = (state) => ({
	tabsRequest: getTabsRequest(state),
	tabsFail: getTabsFail(state),
	tabs: getTabsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getTabs: () => dispatch(getTabs()),
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
	setSelectedItemsInit: () => dispatch(setSelectedItemsInit()),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityFeatures);
