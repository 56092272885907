import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import AccountOverview from './AccountOverview';

import { getDefinedSettings } from '../../redux/actions';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import { getUserSettings, getUser } from '../../../../redux/user/getUserData/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import getChartData, {
	checkChartSetting,
	initChartData,
} from '../../redux/getChartData/actions';
import exportChartDataCSV from '../../redux/exportChartData/actions';
import {
	checkedChartSetting,
	getChartDataAsOf,
	getChartDataSuccess,
	isGetChartDataPending,
} from '../../redux/getChartData/selectors';
import {
	getDefinedSettingsData,
	isGetDefinedSettingsPending,
} from '../../redux/selectors';
import { isExportingChartDataCsv } from '../../redux/exportChartData/selectors';

const mapStateToProps = (state) => ({
	settings: getUserSettings(state, 2),
	chartData: (settingId) => getChartDataSuccess(state, settingId),
	asOfDate: (settingId) => getChartDataAsOf(state, settingId),
	isGetChartDataPending: (settingId) => isGetChartDataPending(state, settingId),
	definedSettings: getDefinedSettingsData(state),
	isGetDefinedSettingsPending: isGetDefinedSettingsPending(state),
	isExportingChartDataCsv: (chartId) => isExportingChartDataCsv(state, chartId),
	checkedChartSetting: (chartId) => checkedChartSetting(state, chartId),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	createUserSettings: (setting) => dispatch(createUserSettings(setting)),
	updateUserSettings: (setting, id) =>
		dispatch(updateUserSettings(setting, id)),
	getChartData: (settingId, additionalParams) =>
		dispatch(getChartData(settingId, additionalParams)),
	getDefinedSettings: () => dispatch(getDefinedSettings(true)),
	openAdminPage: () =>
		dispatch(localizedRoute('/account-overview/admin-page')),
	exportChartDataCSV: (chartId, chartName, requestParams) =>
		dispatch(exportChartDataCSV(false, chartId, chartName, requestParams)),
	initChartData: (id) => dispatch(initChartData(id)),
	checkChartSetting: (id) => dispatch(checkChartSetting(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountOverview);
