import globalAction from '../../../../redux/global/globalAction';

import { GET_SURCHARGES, GET_SURCHARGES_FILTERS } from './constants';

import {
	getSurcharges as getSurchargesService,
	getSurchargesFilters as getSurchargesFiltersService
} from '../../services/SurchargesService';

/** ************ get surcharges ************* */
export const getSurcharges = (id, params = {}) =>
	globalAction(GET_SURCHARGES, getSurchargesService, { id, params });

/** ************ get surcharges filters ************* */
export const getSurchargesFilters = (id, params = {}) =>
	globalAction(GET_SURCHARGES_FILTERS, getSurchargesFiltersService, {
		id,
		params
	});
