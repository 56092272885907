import { combineReducers } from 'redux';
import globalReducer from '../../../redux/global/globalReducer';

import {
	GET_RELEASE_NOTES_LIST,
	GET_RELEASE_NOTES,
	ADD_RELEASE_NOTES,
	EDIT_RELEASE_NOTES,
	GET_ADDITIONAL_DETAIL_STATUSES,
  PUBLISH_RELEASE_NOTES
} from './constants';

export default combineReducers({
	getReleaseNotesList: globalReducer(GET_RELEASE_NOTES_LIST),
	getReleaseNotes: globalReducer(GET_RELEASE_NOTES),
	addReleaseNotes: globalReducer(ADD_RELEASE_NOTES),
  editReleaseNotes: globalReducer(EDIT_RELEASE_NOTES),
  publishReleaseNotes: globalReducer(PUBLISH_RELEASE_NOTES),
	getAdditionalDetailStatuses: globalReducer(GET_ADDITIONAL_DETAIL_STATUSES)
});
