import React from 'react';
import cn from 'classnames';
import { string } from 'prop-types';

import styles from './Mobile.scss';
import TabsBar from '../TabsBar';

const TabsBarMobile = (props) => {
  const { className } = props;

  return (
		<TabsBar
			data-spec="tabs-bar-mobile"
			{...props}
			styles={cn(styles.tabs_bar, className)}
		/>
	);
};

TabsBarMobile.propTypes = {
  className: string
};

export default TabsBarMobile;
