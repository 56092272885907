import React, { PureComponent } from 'react';
import Carousel from 're-carousel';
import PropTypes from 'prop-types';
import IndicatorDots from './indicator-dots';
import Buttons from './buttons';

import './styles.scss';

const globeCarouselProps = {
	data: PropTypes.array
};

export default class GlobecomCarousel extends PureComponent {
	render() {
    const { data } = this.props;
		const carouselData = data
			? data.map((item) => ({ src: item.name }))
			: [];

		return (
			<div className="carousel" data-spec="carousel-wrapper">
				<Carousel auto loop widgets={[IndicatorDots, Buttons]}>
					{carouselData.map((item) => (
							<div
								data-spec="carousel-item"
								key={item}
								style={{
									width: '100%',
									height: '100%',
									backgroundImage: `url(${item.src})`,
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
									backgroundSize: 'contain'
								}}
							/>
						))}
				</Carousel>
			</div>
		);
	}
}

GlobecomCarousel.propTypes = globeCarouselProps;

GlobecomCarousel.defaultProps = {
	data: [
		{ src: 'https://unsplash.it/500/?image=724', title: 'Placeholder title 1' },
		{
			src: 'https://unsplash.it/500/?image=1029',
			title: 'Placeholder title 2'
		},
		{ src: 'https://unsplash.it/500/?image=12', title: 'Placeholder title 3' },
		{ src: 'https://unsplash.it/500/?image=89', title: 'Placeholder title 4' }
	]
};
