import React from 'react';
import {
	arrayOf,
	bool,
	func,
	objectOf,
	shape,
	string,
} from 'prop-types';
import {
	FormattedDate,
	FormattedMessage,
} from 'react-intl';
import { isValid } from 'date-fns';

import Loader from '../../../../lib/DigitalComponents/Loader';
import { getDateTimeFormat } from '../../../../utils/constants';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './RegistrationDetailsWing.scss';

const TabletIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/endpoint-registration-details.svg'
);

function RegistrationDetailsWing({
	data,
	fields,
	isFetching,
	isCanceling,
	isSuccess,
	submitCancelRegistration,
	iccid,
}) {
	if (Object.keys(data).length === 0 && isSuccess) {
		return (
			<div
				data-spec="registration-details-no-data"
				className={styles.registration_details}
			>
				<FormattedMessage
					id="NO_DATA_TO_DISPLAY_AT_THE_MOMENT"
					defaultMessage="No data to display at the moment"
				/>
			</div>
		);
	}

	const locationData = {
		data: {
			type: 'deviceReConnectRequest'
		}
	};

	return (
		<div data-spec="registration-details">
			<div className={styles.actions_wrapper}>
				<div className={styles.title}>
					<FormattedMessage
						id="SINGLE_ENDPOINT.ACTIONS"
						defaultMessage="Actions"
					/>
				</div>
				<Button
					dataSpec="cancel-registration"
					variant="outline-primary"
					onClick={() =>
						submitCancelRegistration(iccid, locationData, '/wing')}
					label={isCanceling ? (
						<FormattedMessage id="LOADING" defaultMessage="Loading" />
					) : (
						<FormattedMessage
							id="CANCEL_LOCATION"
							defaultMessage="Cancel Location"
						/>
					)}
					disabled={isCanceling}
				/>
			</div>
			{(
				Object.keys(data).length > 0 || isFetching
			) && (
				<div className={styles.registration_details}>
					<div className={styles.rd_title}>
						<FormattedMessage
							id="ENDPOINT_REGISTRATION_DETAILS"
							defaultMessage="Endpoint registration details"
						/>
					</div>
					{isFetching ? (
						<Loader data-spec="registration-details-loader" />
					) : (
						<>
							<div className={styles.rd_fields}>
								{fields.map((item) => {
									const value = data[`${item.id}`] || '-';

									return (
										<div
											key={item.id}
											data-spec={`rd-field-${item.id}`}
											className={styles.rd_field}
										>
											<FormattedMessage
												id={item.id}
												defaultMessage={item.defaultLabel}
												tagName="div"
											/>
											{item.type === 'date'
											&& isValid(new Date(value)) ? (
												<div
													className={styles.rd_field_value}
												>
													{getDateTimeFormat(value)}
													<FormattedDate
														value={value}
														year="numeric"
														month="2-digit"
														day="2-digit"
														hour="2-digit"
														minute="2-digit"
														second="2-digit"
													/>
												</div>
											) : (
												<div className={styles.rd_field_value}>
													{value}
												</div>
											)}
										</div>
									);
								})}
							</div>
							<TabletIcon className={styles.tablet_icon} />
						</>
					)}
				</div>
			)}
		</div>
	);
}

RegistrationDetailsWing.propTypes = {
	data: shape({
		id: string
	}).isRequired,
	fields: arrayOf(objectOf(string)).isRequired,
	iccid: string.isRequired,
	isFetching: bool,
	isCanceling: bool,
	isSuccess: bool,
	submitCancelRegistration: func,
};
RegistrationDetailsWing.defaultProps = {
	isFetching: true,
	isCanceling: false,
	isSuccess: false,
	submitCancelRegistration: undefined,
};

export default RegistrationDetailsWing;
