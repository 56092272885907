import globalAction from '../../../../redux/global/globalAction';

import { GET_DATA_DETAILS, GET_DATA_DETAILS_FILTERS } from './constants';

import {
	getDataDetails as getDataDetailsService,
	getDataDetailsFilters as getDataDetailsFiltersService
} from '../../services/DataDetailsService';

/** ************ get data details ************* */
export const getDataDetails = (id, params = {}) =>
	globalAction(GET_DATA_DETAILS, getDataDetailsService, { id, params });

/** ************ get data details filters ************* */
export const getDataDetailsFilters = (id, params = {}) =>
	globalAction(GET_DATA_DETAILS_FILTERS, getDataDetailsFiltersService, {
		id,
		params
	});
