import { createAction } from 'redux-actions';

import { getChartData as service } from '../../services/AnalyticsService';

import {
	GET_ANALYTICS_CHART_DATA_REQUEST,
	GET_ANALYTICS_CHART_DATA_SUCCESS,
	GET_ANALYTICS_CHART_DATA_ERROR
} from '../constants';

const request = createAction(GET_ANALYTICS_CHART_DATA_REQUEST);
const success = createAction(GET_ANALYTICS_CHART_DATA_SUCCESS);
const fail = createAction(GET_ANALYTICS_CHART_DATA_ERROR);

const getChartData = (chartId, chartSetting) => async (dispatch) => {
	dispatch(request({ id: chartId }));
	service(chartSetting)
		.then((response) => dispatch(success({ id: chartId, data: response })))
		.catch((error) => dispatch(fail({ id: chartId, error })));
};

export default getChartData;
