import { handleActions } from 'redux-actions';
import { SET_LOCALE } from './constants';

export const initialState = {
	language: sessionStorage.getItem('language') || 'en-US'
};

export default handleActions(
	{
		[SET_LOCALE]: (state, action) => ({
			...state,
			language: action.payload
		})
	},
	initialState
);
