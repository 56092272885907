import { createAction } from 'redux-actions';

import { FILTER_DATA_INIT, FILTER_DATA_SUCCESS } from './constants';

export const filterDataInit = createAction(FILTER_DATA_INIT);
export const filterDataSuccess = createAction(FILTER_DATA_SUCCESS);

/** ************ set filter data ************* */
export const setFilterData = (filterData) => async (dispatch) => {
	dispatch(filterDataSuccess(filterData));
};
