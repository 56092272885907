/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import AuthService from '../../services/AuthService';
import { get } from '../../services/UserService';
import Loader from '../../lib/DigitalComponents/Loader';

import styles from './AuthSignInCallback.scss';
import {
	LOCALES
} from '../../utils/constants';

class AuthSignInCallback extends Component {
	constructor() {
		super();
		this.authService = new AuthService();
		this.state = { error: null, numOfLoginRetries: 1 };
  }

	async componentDidMount() {
		try {
			await this.authService.exchangeCodeForToken();
			await this.redirectUserBackToTheApp();
			localStorage.removeItem('num_of_login_retries');
		} catch (err) {
			const numOfLoginRetries = localStorage.getItem('num_of_login_retries');
			if (
				!numOfLoginRetries ||
				parseInt(numOfLoginRetries, 10) < this.state.numOfLoginRetries
			) {
				localStorage.setItem(
					'num_of_login_retries',
					numOfLoginRetries ? parseInt(numOfLoginRetries, 10) + 1 : 1
				);
				await this.authService.login();
			} else {
				localStorage.removeItem('num_of_login_retries');
				this.setState({ error: err });
			}
		}
	}

	redirectUserBackToTheApp = async () => {
		const user = await get();
		let redirectUrl = '/';
		const allowedLanguages = LOCALES.map((locale) => locale.local);

		if (user && user.user) {
			redirectUrl = allowedLanguages.includes(user.user.language) ? `/${user.user.language}` : '/en-us';
		}
  window.location.replace(redirectUrl);
	}

	render() {
		const { error } = this.state;
		if (error) {
			return (
				<div data-spec="auth-error" className={styles.main_wrapper}>
					<div className={styles.message}>
						<FormattedMessage
							id="UH_OH_THERE_WERE_SOME_PROBLEMS_TRYING_TO_LOG_YOU_IN_PLEASE_RETRY_AGAIN_IN_A_FEW_MOMENTS"
							defaultMessage="Uh oh, there were some problems trying to log you in.
							Please retry again in a few moments. If the issue persists,
							please reach out to the support team."
						/>
					</div>
				</div>
			);
		}
		return (
			<FormattedMessage
				id="AUTHENTICATION_IN_PROGRESS"
				defaultMessage="Authentication in progress. Please wait..."
				data-spec="loader"
			>
				{(formattedValue) => <Loader msg={formattedValue} />}
			</FormattedMessage>
		);
	}
}

export default AuthSignInCallback;
