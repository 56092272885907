import { createAction } from 'redux-actions';

import {
	SET_SEARCH_PARAMETER,
	SET_SEARCH_PARAMETER_INIT,
	SET_SEARCH_PARAMETER_PROP_INIT,
	SET_SEARCH_PARAMETER_PROP
} from './constants';

const setSearchParameterInitAction = createAction(SET_SEARCH_PARAMETER_INIT);
const setSearchParameterSuccess = createAction(SET_SEARCH_PARAMETER);

/** ************ set search parameter ************* */
export const setSearchParameter = (searchParameter) => async (dispatch) => {
	dispatch(setSearchParameterSuccess(searchParameter));
};

/** ************ set search parameter init ************* */
export const setSearchParameterInit = () => async (dispatch) => {
	dispatch(setSearchParameterInitAction());
};

const setSearchParameterPropInitAction = createAction(SET_SEARCH_PARAMETER_PROP_INIT);
const setSearchParameterPropSuccess = createAction(SET_SEARCH_PARAMETER_PROP);

/** ************ set search parameter prop ************* */
export const setSearchParameterProp = (searchParameterProp) => async (dispatch) => {
	dispatch(setSearchParameterPropSuccess(searchParameterProp));
};

/** ************ set search parameter prop init ************* */
export const setSearchParameterPropInit = () => async (dispatch) => {
	dispatch(setSearchParameterPropInitAction());
};
