import { handleActions } from 'redux-actions';

import { OPEN_EDIT_TABLE_MODAL, CLOSE_EDIT_TABLE_MODAL } from './constants';

export const initialState = {
	editTableModalOpened: false
};

export default handleActions(
	{
		[OPEN_EDIT_TABLE_MODAL]: (state) => ({
				...state,
				editTableModalOpened: true
			}),
		[CLOSE_EDIT_TABLE_MODAL]: (state) => ({
				...state,
				editTableModalOpened: false
			})
	},
	initialState
);
