import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Error504.scss';

const Error504 = () => (
	<div data-spec="error-504">
		<div className={styles.title}>504</div>
		<div className={styles.sub_title}>
			<FormattedMessage id="ERRORS.ERROR" defaultMessage="Error" />
		</div>
		<div className={styles.message}>
			<FormattedMessage
				id="ERRORS.REQUEST_TIMED_OUT"
				defaultMessage="Request timed out"
			/>
		</div>
	</div>
);

export default Error504;
