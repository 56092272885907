import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { UserAllowedToAccess } from '../../../../utils/AuthSelector';
import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';

import CompanyAddressOverview from '../CompanyAddressOverview';
import CompanyContactOverview from '../CompanyContactOverview';

import styles from './AddCompanySuccess.scss';
import Divider from '../../../../lib/DigitalComponents/Divider';
import SectionHeadline from '../../../../lib/DigitalComponents/SectionHeadline';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import UploadImage from '../UploadImage';
import { companySupportType } from '../../utils/constants';

const SuccessIcon = ImageSelector(CurrentContext.theme, 'svgs/ok-empty.svg');
const CompaniesRWComponent = UserAllowedToAccess(['system.companies_rw']);

const UnitedStates = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const France = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const Espana = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const Italia = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const Holland = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const Brazil = ImageSelector(CurrentContext.theme, 'svgs/brazil.svg');
const Deutschland = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const PRC = ImageSelector(CurrentContext.theme, 'svgs/china.svg');

const CompanyLogo = ImageSelector(
	CurrentContext.theme,
	'company-image-placeholder.png'
);

class AddCompanySuccess extends Component {
	componentDidMount() {
		const {
			match: {
				params: { companyId }
			}
		} = this.props;
		if (companyId) {
			this.getCompany(companyId);
		}
	}

	componentWillUnmount() {
		const { getCompanyInit } = this.props;
		getCompanyInit();
	}

	getCompany = (companyId) => {
		const { getCompany } = this.props;
		getCompany({
			id: companyId,
			additionalParams: {
				include: [
					'CompanyContacts',
					'CompanyAddresses',
					'CompanyAddresses.AddressType',
					'CompanyAddresses.Country',
					'CompanyAddresses.State',
					'CompanyContacts.CompanyContactType',
					'Language',
					'CompanyLogo'
				]
			}
		});
	};

	pushBack = () => {
		const { pushBack } = this.props;
		pushBack('/companies');
	};

	addNewCompany = () => {
		const { addNewCompany } = this.props;
		addNewCompany('/companies/add-company');
	};

	editCompany = () => {
		const {
			changeCompanyInitalTab,
			editCompany,
			match: {
				params: { companyId }
			}
		} = this.props;
		changeCompanyInitalTab('details');
		editCompany(`/companies/${companyId}`);
	};

	renderFlags = (data) => {
		switch (data && data.id) {
			case 1:
				return <UnitedStates data-spec="USA" />;
			case 2:
				return <France data-spec="France" />;
			case 3:
				return <Espana data-spec="Espana" />;
			case 4:
				return <Italia data-spec="Italia" />;
			case 5:
				return <Holland data-spec="Holland" />;
			case 6:
				return <Deutschland data-spec="Deutschland" />;
			case 7:
				return <Brazil data-spec="Brazil" />;
			case 8:
				return <PRC data-spec="China" />;
			// no default
		}
	};

	renderSupportType = () => {
		const { company, intl } = this.props;
		let description = intl.formatMessage({
			id: 'ONBOARDING.STANDARD_DESCRIPTION',
			defaultMessage:
				'This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support.'
		});

		switch (company && company.supportType) {
			case companySupportType.PRIORITY:
				description = intl.formatMessage({
					id: 'ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION',
					defaultMessage:
						'All tickets will be supported by the IoT Priority Care team. The account’s contract must specify that they qualify for IoT Priority Care support.'
				});
				break;
			case companySupportType.FULL:
				description = intl.formatMessage({
					id: 'ONBOARDING.FULL_TSM_DESCRIPTION',
					defaultMessage:
						'All tickets, regardless of severity, will be supported by the Technical Solutions Manager team. The account’s contract must specify that they qualify for TSM support or the Advanced Solutions Design and Implementation (ASDI) team has specified that the account qualifies for TSM support.'
				});
				break;
			case companySupportType.PARTIAL:
				description = intl.formatMessage({
					id: 'ONBOARDING.PARTIAL_TSM_DESCRIPTION',
					defaultMessage:
						'Critical tickets will be supported by the Technical Solutions Manager team. All other severities will be supported by the ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team. The account’s contract must specify that they qualify for TSM support for critical issues.'
				});
				break;
			case companySupportType.GATEWAY:
				description = intl.formatMessage({
					id: 'ONBOARDING.IOT_GATEWAY_DESCRIPTION',
					defaultMessage:
						'Only for use when Equipment Inquiries will be submitted for manually processed TCU (Transceiver Control Unit) replacement requests.'
				});
				break;
			default:
				description = intl.formatMessage({
					id: 'ONBOARDING.STANDARD_DESCRIPTION',
					defaultMessage:
						'This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support.'
				});
		}

		return (
			<>
				<span className={styles.bold}>
					{(company && company.supportType) || (
						<FormattedMessage
							id="ONBOARDING.STANDARD"
							defaultMessage="Standard (Default)"
						/>
					)}
				</span>{' '}
				- {description}
			</>
		);
	};

	render() {
		const { getCompanyRequest, company, intl } = this.props;
		return (
			<div data-spec="company-overview">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.COMPANIES"
							defaultMessage="Companies"
						/>
					}
					pushBack={() => this.pushBack()}
				/>
				{!company || getCompanyRequest ? (
					<Loading data-spec="loader" />
				) : (
					<>
						<div className={styles.page_wrapper}>
							<div className={styles.success_wrapper}>
								<div className={styles.success_content}>
									<div className={styles.success_title}>
										<FormattedMessage
											id="ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR"
											defaultMessage="You have successfully created a IoT Console account for:"
										/>
									</div>
									<div className={styles.success_company}>
										{company.companyFriendlyName}
									</div>
									<div>
										<SuccessIcon className={styles.success_icon} />
									</div>
									<div className={styles.company_message}>
										<FormattedMessage
											id="ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT"
											defaultMessage="The IoT Gateway number associated with this account:"
										/>
									</div>
									<div className={styles.company_id}>
										{company.externalCompanyId}
									</div>
								</div>
							</div>
							<div className={styles.success_wrapper}>
								<div
									data-spec="company-details"
									className={styles.form_wrapper}
								>
									<SectionHeadline
										title={intl.formatMessage({
											id: 'ONBOARDING.ACCOUNT_NAME',
											defaultMessage: 'Account Name'
										})}
									/>

									<section className={styles.form_logo}>
										<div>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.COMPANY_NAME',
													defaultMessage: 'Company Name'
												})}
												data={company.companyFriendlyName}
												single
											/>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.BUSINES_LEGAL_NAME',
													defaultMessage: 'Business Legal Name'
												})}
												data={company.companyLegalName}
												single
											/>
											<StaticField
												label={intl.formatMessage({
													id: 'ONBOARDING.PREFERRED_LANGUAGE',
													defaultMessage: 'Preferred Language'
												})}
												data={
													<div className={styles.preferred_language_info}>
														{this.renderFlags(company.language)}
														{company &&
															company.language &&
															company.language.name}
													</div>
												}
												single
											/>
										</div>
										<div className={styles.logo}>
											<UploadImage
												updateLogo={this.updateLogo}
												customDefaultLogo={CompanyLogo}
												canChange={false}
												logo={
													company &&
													company.companyLogo &&
													company.companyLogo.logo
												}
											/>
										</div>
									</section>

									<SectionHeadline
										title={intl.formatMessage({
											id: 'ONBOARDING.MAIN_CORPORATE_ADDRESS',
											defaultMessage: 'Main Corporate Address'
										})}
									/>

									{company &&
										company.addresses &&
										company.addresses.length > 0 &&
										company.addresses.map((item) => (
											<CompanyAddressOverview address={item} key={item.id} />
										))}

									<SectionHeadline
										title={intl.formatMessage({
											id: 'ONBOARDING.COMPANY_CONTACTS',
											defaultMessage: 'Company Contacts'
										})}
									/>

									{company &&
										company.contacts &&
										company.contacts.length > 0 &&
										company.contacts
											.filter((item) => item.companyContactTypeId === 2)
											.map((item) => (
												<CompanyContactOverview contact={item} key={item.id} />
											))}

									<SectionHeadline
										title={intl.formatMessage({
											id: 'ONBOARDING.ATT_CONTACTS',
											defaultMessage: 'AT&T Contacts'
										})}
									/>

									{company &&
										company.contacts &&
										company.contacts.length > 0 &&
										company.contacts
											.filter((item) => item.companyContactTypeId === 1)
											.map((item) => (
												<CompanyContactOverview contact={item} key={item.id} />
											))}

									<SectionHeadline
										title={intl.formatMessage({
											id: 'ONBOARDING.VTM_SUPPORT_TYPE',
											defaultMessage: 'vTM Support Type'
										})}
									/>
									<section>
										<div className={styles.support_type_wrapper}>
											{this.renderSupportType()}
										</div>
									</section>
								</div>
							</div>
							<Divider />
						</div>
						<div className={styles.section_buttons}>
							<CompaniesRWComponent>
								<Button
									label={
										<FormattedMessage
											id="ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT"
											defaultMessage="Create New Company Account"
										/>
									}
									variant="outline-primary"
									type="button"
									dataSpec="close-button"
									onClick={this.addNewCompany}
								/>
							</CompaniesRWComponent>
							<Button
								label={
									<FormattedMessage id="CONTINUE" defaultMessage="Continue" />
								}
								variant="primary"
								type="button"
								dataSpec="submit-button"
								onClick={this.editCompany}
							/>
						</div>
					</>
				)}
			</div>
		);
	}
}

const { func, object, bool } = PropTypes;

AddCompanySuccess.propTypes = {
	getCompanyRequest: bool,
	company: object,
	match: object,
	getCompany: func,
	addNewCompany: func,
	editCompany: func,
	changeCompanyInitalTab: func,
	pushBack: func,
	getCompanyInit: func,
	intl: object
};

export default reduxForm({
	form: 'AddCompanyForm',
	destroyOnUnmount: true
})(injectIntl(AddCompanySuccess));
