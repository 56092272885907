import { Status } from '../../../../utils/constants';

export const addSimSkuRequest = (state) =>
	state.onboarding.simOrdering.addSimSku.status === Status.PENDING;
export const addSimSkuFail = (state) =>
	state.onboarding.simOrdering.addSimSku.status === Status.FAIL;
export const addSimSkuSuccess = (state) =>
	state.onboarding.simOrdering.addSimSku.status === Status.DONE;

export const deleteSimSkuRequest = (state) =>
	state.onboarding.simOrdering.deleteSimSku.status === Status.PENDING;
export const deleteSimSkuFail = (state) =>
	state.onboarding.simOrdering.deleteSimSku.status === Status.FAIL;
export const deleteSimSkuSuccess = (state) =>
	state.onboarding.simOrdering.deleteSimSku.status === Status.DONE;

export const isDeleteSimSkuModalOpen = (state) =>
	state.onboarding.simOrdering.deleteSimSkuModal.isOpen;

export const editSimSkuRequest = (state) =>
	state.onboarding.simOrdering.editSimSku.status === Status.PENDING;
export const editSimSkuFail = (state) =>
	state.onboarding.simOrdering.editSimSku.status === Status.FAIL;
export const editSimSkuSuccess = (state) =>
	state.onboarding.simOrdering.editSimSku.status === Status.DONE;

export const isEditSimSkuModalOpen = (state) =>
	state.onboarding.simOrdering.editSimSkuModal.isOpen;

export const isAddSimSkuModalOpened = (state) =>
	state.onboarding.simOrdering.addSimSkuModal.isOpen;

export const getParentSimSkusRequest = (state) =>
	state.onboarding.simOrdering.getParentSimSkus.status === Status.PENDING;
export const getParentSimSkusFail = (state) =>
	state.onboarding.simOrdering.getParentSimSkus.status === Status.FAIL;
export const getParentSimSkusSuccess = (state) =>
	state.onboarding.simOrdering.getParentSimSkus.data;

export const getSkusPending = (state) =>
	state.onboarding.simOrdering.getSKUs.status === Status.PENDING;
export const getSkusSuccess = (state) =>
	state.onboarding.simOrdering.getSKUs.data;

/* ******************** ASSIGN SIM SKUS ******************** */
export const addSubAccountSimSkuRequest = (state) =>
	state.onboarding.simOrdering.addSubAccountSimSku.status === Status.PENDING;
export const addSubAccountSimSkuFail = (state) =>
	state.onboarding.simOrdering.addSubAccountSimSku.status === Status.FAIL;
export const addSubAccountSimSkuSuccess = (state) =>
	state.onboarding.simOrdering.addSubAccountSimSku.data;

export const getM2MAccountsRequest = (state) =>
	state.onboarding.simOrdering.getM2MAccounts.status === Status.PENDING;
export const getM2MAccountsSuccess = (state) =>
	state.onboarding.simOrdering.getM2MAccounts.data &&
	state.onboarding.simOrdering.getM2MAccounts.data.resultList;

export const getNetworkEntitlementsRequest = (state) =>
	state.onboarding.simOrdering.getNetworkEntitlements.status === Status.PENDING;
export const getNetworkEntitlementsSuccess = (state) =>
	state.onboarding.simOrdering.getNetworkEntitlements.data &&
	state.onboarding.simOrdering.getNetworkEntitlements.data.networkEntitlements;

export const getRatePlansRequest = (state) =>
	state.onboarding.simOrdering.getRatePlans.status === Status.PENDING;
export const getRatePlansSuccess = (state) =>
	state.onboarding.simOrdering.getRatePlans.data &&
	state.onboarding.simOrdering.getRatePlans.data.rateplans;
export const postAccountTransferRequest = (state) =>
	state.onboarding.simOrdering.postAccountTransfer.status === Status.PENDING;
