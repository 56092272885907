export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Multi-endpoint actions',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Please select two or more endpoints',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE':
		'Please apply one or more filters to enable export feature',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS':
		'Currently assigned to selected endpoints',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS':
		'Eligible to use with selected endpoints',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR':
		'Multiple SIM network entitlement change could not be requested successfully',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED':
		'SIMs network entitlement successfully updated',
	'ENDPOINTS.VIEW_NOTE': 'View Note',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'No Endpoints Available',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE':
		'There are No Endpoints for this Table to show you right now',
	'ENDPOINTS.EXPORT_TO_FILE_TOOLTIP': 'Export to file',
	'ENDPOINTS.WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT':
		'We are sorry, there is no data to export',

	//  BULK UPLOAD MODAL
	'ENDPOINTS.BULK_UPLOAD': 'Batch Upload',
	'ENDPOINTS.CONFIRM_BATCH_FILE_UPLOAD': 'Confirm batch file upload',
	'ENDPOINTS.SCHEDULED_ACTION_ON': 'Scheduled Action on',
	'ENDPOINTS.ADDITIONAL_COMMENT': 'Additional comment',
	'ENDPOINTS.PLEASE_WAIT_THIS_MAY_TAKE_A_FEW_MINUTES':
		'Please wait. This may take a few minutes, depending on the file size.',
	'ENDPOINTS.UPLOAD_FILE': 'Upload file',
	'ENDPOINTS.CONFIRM_CHANGES': 'Confirm changes',
	'ENDPOINTS.SYSTEM_CONFIRMATION': 'System confirmation',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS': 'Batch Upload Instructions',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.GENERAL': 'Download the desired batch action template to your computer,open the template in text editor (Notepad on Windows or TextEdit on Mac) and follow the instructions on how to format the data for the selected template. Instructions are available inside each template.',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FILE_TEMPLATES': 'BATCH FILE TEMPLATES',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FILE_TEMPLATES_DESCRIPTION': 'The following templates are used to process batch uploads: Activation.csv template is used to activate ICCID. Custom_Fields.csv template will allow you to add/edit a custom field to a device. Network_Entitlement_Change.csv template is used to change a network entitlement (communication plan) for an ICCID. Rate_Plan_Change.csv template is used to make rate plan changes. Termination.csv template is used to terminate ICCID.',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FORMATTING_YOUR_DATA': 'FORMATTING YOUR DATA',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.FORMATTING_YOUR_DATA_DESCRIPTION': 'The templates are action specific and contain headers that are relevant for the bulk action to be performed. Inside each template you will find instructions and headers that are specific to that template. Do not delete the instructions. Do not enter # when entering data. Headers for each template are displayed at the bottom of the instructions. Each ICCID should be its own line. Every field value must be accounted for and separated by a comma even if the field value is blank. Field values left blank will not delete/change current field value. Maximum number of SIM upload per template is 10,000.',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.NAMING_BATCH_FILE': 'NAMING BATCH FILE',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.NAMING_BATCH_FILE_DESCRIPTION': 'Batch file names must be the same as template name, however you can add an _ (underscore) to include additional filename identifier after the template name but before .csv file type. Ex: Rate_Plan_Change_filename.csv The system will automatically append the date+time stamp to the file',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.SUBMITTING_BATCH_FILE': 'SUBMITTING BATCH FILE',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.SUBMITTING_BATCH_FILE_DESCRIPTION': 'Navigate to Endpoints page and click Batch Upload. Follow directions to upload the file',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.BATCH_PROCESSING_AND_RESULTS': 'BATCH PROCESSING AND RESULTS',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.BATCH_PROCESSING_AND_RESULTS_DESCRIPTION': "Click on Batch History link in the left navigation to see the results of the Batch Upload. Status will state as 'Pending' until the file is processed. Status will state as 'Complete' after the file has successfully been processed. Status will state as 'Error' if the file is has formatting errors during upload. After the batch is processed successfully and the status changes to 'Complete' user who submitted the batch upload will receive an email notification. Success Rate will display the the total records processed successfully over the total numbers of records submitted.",
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.ERROR_HANDLING': 'ERROR HANDLING',
	'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS.ERROR_HANDLING_DESCRIPTION': 'Status completed means that your batch was processed, however your batch may contain errors; if Success Rate column displays number of records processed successfully is less than the total number submitted. In order to inspect the errors download the Batch History report and review the Batch file where the error message is displayed.',
	'ENDPOINTS.OK': 'OK',

	//  EDIT TABLE MODAL
	'ENDPOINTS.TABLE_NAME': 'Table Name',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Edit table view name',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Please enter table name',
	'ENDPOINTS.EDIT_TABLE': 'Edit Table Parameters',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Edit Custom Field Labels',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Edit Table Parameters',
	'ENDPOINTS.APPLY_CHANGE': 'Apply Change',
	'ENDPOINTS.DELETE_TAB': 'Delete Tab',
	'ENDPOINTS.CUSTOM_LABEL': 'Custom Label {index}',
	'ENDPOINTS.CLEAR_ALL': 'Reset to Default',
	'ENDPOINTS.DEVICE_TYPE': 'Device Type',

	//  EDIT TABLE PARAMETERS
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE':
		'Select a minimum of 2 to apply to the table. ',
	'ENDPOINTS.CELLULAR_COLUMNS': 'Cellular Specific Parameters',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Satellite Specific Parameters',

	//  CELLULAR SPECIFIC PARAMETERS
	'ENDPOINTS.M2MACCOUNTID': 'M2M Account ID',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': 'Status',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': 'Network Operator',
	'ENDPOINTS.NETWORK_TYPE': 'Network Type',
	'ENDPOINTS.IMSI_TRANSFER': 'IMSI Transfer Status',
	'ENDPOINTS.RATE_PLAN': 'Rate Plan',
	'ENDPOINTS.RECREDENTIAL_STATUS': 'Re-Credential Status',
	'ENDPOINTS.ICCID': 'ICCID',
	'ENDPOINTS.SERVICE_PROVIDER': 'M2M Platform',
	'ENDPOINTS.MSISDN': 'MSISDN',
	'ENDPOINTS.COUNTRY_OPERATOR': 'Network Country',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'Network Entitlement ID',
	'ENDPOINTS.OEM': 'Sub Accounts',
	'ENDPOINTS.SERVICE_STATUS': '360L Status',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': 'Roaming Country',
	'ENDPOINTS.ROAMING_CARRIER': 'Roaming Carrier',
	'ENDPOINTS.M2M_DATE_ADDED': 'M2M Date Added',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'M2M Activation Date',
	'ENDPOINTS.NOTES': 'Notes',
	'ENDPOINTS.CTD_DATA_USAGE': 'CTD Data Usage',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'CTD SMS Usage',
	'ENDPOINTS.CTD_VOICE_USAGE': 'CTD Voice Usage',
	'ENDPOINTS.INSESSION': 'In Session',

	//  SATELLITE SPECIFIC PARAMETERS
	'ENDPOINTS.M2M_ACC_ID': 'M2M Account ID',
	'ENDPOINTS.DEVICE_NAME': 'Device Name',
	'ENDPOINTS.DEVICE_ID': 'Device ID',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': 'Device Model #',

	//  RATE PLAN SCHEDULE MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE':
		'There is an error on underlying platform and rate plan change is not available at the moment.',
	'ENDPOINTS.RATE_PLAN_CHANGE': 'Rate Plan change',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'You have chosen to change Rate plan to ',
	'ENDPOINTS.TAP_INITIATE_CHANGE':
		"Tap 'Initiate change' to take immediate action.",
	'ENDPOINTS.CHANGE_LATER': 'You can also schedule the change for later.',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN':
		'Scheduled change will happen at the beginning of the date you select.',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'Schedule change',
	'ENDPOINTS.INITIATE_CHANGE': 'Initiate change',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Rate plan change is successfully scheduled',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Rate plan change is successfully scheduled for {scheduleDate}',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR':
		'Rate plan change could not be scheduled successfully!',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Schedule Rate plan change',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. System confirmation',
	'ENDPOINTS.NOTIFICATION_ERROR':
		'Endpoind ID {endpointICCID} failed to provision after Rate plan change. Get support',
	'ENDPOINTS.SCHEDULE_DATE': 'Date',
	'ENDPOINTS.SIM_STATE_CHANGE': 'SIM state change',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': 'You have chosen to change SIM state to',
	'ENDPOINTS.STATUS_CHANGE_ERROR':
		'User {user} made Change status on {changeDate}',
	'ENDPOINTS.SCHEDULING': 'Scheduling...',

	//  SIM STATUS
	'ENDPOINTS.ACTIVE': 'Activated',
	'ENDPOINTS.TERMINATE': 'Terminated',
	'ENDPOINTS.PROVISIONED': 'Provisioned',
	'ENDPOINTS.DEACTIVE': 'Deactive',
	'ENDPOINTS.INACTIVE': 'Inactive',
	'ENDPOINTS.TEST': 'Test',
	'ENDPOINTS.SUSPENDED': 'Suspended',
	'ENDPOINTS.SUSPEND': 'Suspend',
	'ENDPOINTS.INVENTORY': 'Inventory',

	//  SIM STATE SCHEDULE CHANGE
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. Schedule SIM state change',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'Schedule SIM state change',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'SIM state change is successfully scheduled!',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'SIM state change is successfully scheduled for {scheduledDate}',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR':
		'SIM state change could not be scheduled successfully!',
	'ENDPOINTS.PLEASE_TRY_AGAIN': 'Please try again.',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR':
		'Endpoind ID {endpointICCID} failed to provision after IMSI status change. Get support',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE':
		'User {user} made Change status on {changeDate}',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS':
		'User {user} made Change status on {date}',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR':
		'Multiple SIM state change could not be scheduled successfully!',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR':
		'Multiple SIM state change could not be requested successfully!',

	//  GENERATE REPORT MODAL
	'ENDPOINTS.CREATE_REPORT': 'Create Report',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Create Custom Report',
	'ENDPOINTS.ENDPOINTS_ARE': 'endpoints are selected for the export.',
	'ENDPOINTS.ENDPOINT_IS': 'endpoint is selected for the export.',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE':
		'This custom report will be available in the reports section.',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME':
		'You can append custom name to the report here',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Custom report name appendix',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX':
		'Add custom report name appendix (only digits and letters allowed)',

	//  MULTI-ACTION MODAL
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. Select SIM State',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Confirm changes',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. System confirmation',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Select Rate Plan',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST':
		'1. Select Network Entitlement',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Change Notes',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2. System confirmation',
	'ENDPOINTS.CHANGE_SIM_STATE': 'Change SIM State',
	'ENDPOINTS.CHANGE_RATE_PLAN': 'Change Rate Plan',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Change Network Entitlement',
	'ENDPOINTS.CHANGE_NOTES': 'Notes',

	//  MULTI-ENDPOINT ACTION - CHANGE NETWORK ENTITLEMENT
	'ENDPOINTS.NETWORK_ENTITLEMENT_DETAILS': 'Network entitlement details',
	'ENDPOINTS.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Confirm Network Entitlement Change',
	'ENDPOINTS.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Eligible network entitlements',

	//  MULTI-ACTION NOTES MODAL
	'ENDPOINTS.SAVE_NOTE': 'Save Note',
	'ENDPOINTS.ADD_NOTE_BELOW': 'Add Note below: ',
	'ENDPOINTS.ENTER_NOTE_HERE': 'Enter Note here (2000 Characters Max)',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Character limit exceeded',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'Cannot be only white spaces',
	'ENDPOINTS.CHANGING_NOTES': 'Changing Notes',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED':
		'Endpoints is successfully changed',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR':
		'Changing Notes for multiple Endpoints could not be initiated successfully',
	'ENDPOINTS.SUCCESS_KEY': 'Success',
	'ENDPOINTS.ERROR': 'Error',
	'ENDPOINTS.REASON': 'reason',
	'ENDPOINTS.ENDPOINTS_SELECTED': 'endpoints selected',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'Changing Notes are not allowed',

	//  CHANGE RATE PLAN
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Please select a few of SIMS.',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'Change rate plan is not allowed',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'No rate plans available',
	'ENDPOINTS.SIMS_HAVE': 'SIMs have',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS':
		'Select different rate plan to apply change for all selected SIMs',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': ' no Rate plan selected',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Confirm rate plan change',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE':
		'You have chosen to change the rate plan of the',
	'ENDPOINTS.SELECTED_SIMS_TO': 'selected SIMs to',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW':
		'Would you like to initiate change now?',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Or you can schedule the change for: ',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL':
		'Rate plan for multiple SIMs is successfully scheduled',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'SIM rate plan change for',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'SIMS is successfully scheduled for',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED':
		'SIM rate plans successfully updated',
	'ENDPOINTS.SIMS_SELECTED': 'SIMs selected',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR':
		'Multiple SIM rate plan change could not be scheduled successfully',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR':
		'Multiple SIM rate plan change could not be requested successfully',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Same rate plan selected',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS':
		'Endpoint ID {endpointID} is successfully provisioned. Rate plan is changed to {ratePlan}',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR':
		'Endpoint ID {endpointID} failed to provision after Rate plan change. Get support',

	//  CHANGE SIM STATE
	'ENDPOINTS.CURRENT': 'CURRENT',
	'ENDPOINTS.NETWORK_TYPE_CHECK':
		'We are sorry, multiple endpoint state change is not allowed for endpoints from different network types or different service providers.',
	'ENDPOINTS.SELECT_SIM_STATE': 'Select SIM state',
	'ENDPOINTS.CURRENT_STATES': 'Current states',
	'ENDPOINTS.SELECTED_TIP':
		'Tip: If you have selected SIMs that are in different states, you will only be able to change to a state that is available for all selected SIMs.',
	'ENDPOINTS.VIEW_RULES': 'View state change rules for this account',
	'ENDPOINTS.STATES_MESSAGE': 'No state available.',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'Confirm SIM state change',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE':
		'Once you initiate this change, the selected SIMS will be',
	'ENDPOINTS.PROCEED_ACTION': 'Would you like to proceed with this action?',
	'ENDPOINTS.CAN_SCHEDULE': 'Or you can schedule the change for',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS':
		'Endpoint ID {endpointID} is successfully provisioned. IMSI status is changed to {status}',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR':
		'Endpoint ID {endpointID} failed to provision after IMSI status change. Get support',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE':
		'User {user} made Change status on {date}',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL':
		'Multiple SIM state change is successfully scheduled!',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'SIM state change for',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED':
		'SIMS status change successfully requested!',

	//  GENERAT REPORT
	'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Custom report successfully created',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE':
		'Error while trying to download your file',
	'ENDPOINTS.GO_TO_REPORTS': 'Go to reports',

	//  UPDATE CUSTOM LABELS
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE':
		'One or more custom field labels are successfully updated.',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE':
		'One or more custom field labels could not be updated at this time. Please try again.',

	//  Activation platform
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': 'DCP',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

	TECH_TYPE: 'Tech Type',
	TECH_TYPE_SUB_TYPE: 'Tech Type Subcategory',
	'ENDPOINTS.TECH_TYPE': 'Tech Type',
	'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Tech Type Subcategory',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Tech Type',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Tech Type Subcategory'
};
