import { Status } from '../../../utils/constants';
import EULA_SETTINGS_NUMBER, { ENDPOINTS_SETTINGS_NUMBER } from './constants';

export const getIsFetching = (state) =>
	state.user.getUserData.status === Status.PENDING;

export const getHasFetched = (state) =>
	state.user.getUserData.status === Status.DONE ||
	state.user.getUserData.status === Status.FAIL;

export const getHasFetchedFail = (state) =>
	state.user.getUserData.status === Status.FAIL;

export const getUser = (state) => state.user.getUserData.data;

export const getCompanyAccountId = (state) =>
	state.user.getUserData.accountId;

export const getCompanyFriendlyName = (state) =>
	state.user.getUserData.companyFriendlyName;

export const getUserSettings = (state, refTypeId) =>
	state.user.getUserData.settings &&
	state.user.getUserData.settings.resultList.filter(
		(item) => item.refTypeId === refTypeId
	);

// TODO: merge these individual user settings selectors
// into one single selector that receives refTypeId as constant
// once we start refactoring endpoints
export const getEulaUserSettings = (state) => {
	const eula =
		state.user.getUserData.settings &&
		state.user.getUserData.settings.resultList.filter(
			(item) => item.refTypeId === EULA_SETTINGS_NUMBER
		);

	return eula ? JSON.stringify(eula) : JSON.stringify([]);
};

export const getEndpointsUserSettings = (state) => {
	const eula =
		state.user.getUserData.settings &&
		state.user.getUserData.settings.resultList.filter(
			(item) => item.refTypeId === ENDPOINTS_SETTINGS_NUMBER
		);

	return eula ? JSON.stringify(eula) : JSON.stringify([]);
};

export const getAllUserSettings = (state) =>
	state.user.getUserData.settings &&
	state.user.getUserData.settings.resultList;
