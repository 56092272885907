import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Select, {
	components
} from '../../../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './StatusSelect.scss';

const Option = (optionProps) => {
	const { data, children } = optionProps;
	return (
		<components.Option
			data-spec={`${data.value}`}
			{...optionProps}
			className={cn(
				styles[
					data && data.value && data.value.replace(/\s/g, '_').toLowerCase()
				],
				styles['custom-option']
			)}
		>
			<span data-spec={`${data.value}`}>{children}</span>
		</components.Option>
	);
};

const SingleValue = (optionProps) => {
	const { data } = optionProps;
	return (
		<components.SingleValue
			data-spec={`${data.value}`}
			{...optionProps}
			className={cn(
				styles[
					data && data.value && data.value.replace(/\s/g, '_').toLowerCase()
				],
				styles['custom-option'],
				styles['select-single-value']
			)}
		/>
	);
};

const StatusSelect = (props) => {
  const { index, dataLenght } = props;
  const menuPosition = index + 1 > dataLenght - 3 ? 'fixed' : 'absolute';

  return (
		<Select
			data-spec="select"
			noBorder
			components={{ Option, SingleValue }}
			{...props}
			styles={{
				container: () => ({
					width: '140px'
				}),
				valueContainer: (provided) => ({
					...provided,
					paddingLeft: '0px',
					paddingRight: '0px'
				})
			}}
			menuPosition={menuPosition}
		/>
	);
};

export default StatusSelect;

StatusSelect.propTypes = {
	index: PropTypes.number,
	dataLenght: PropTypes.number
};
