import { Status } from '../../../utils/constants';

export const updateUserSettingsData = (state) =>
	state.user.updateUserSettings.data;
export const updateUserSettingsSuccess = (state) =>
	state.user.updateUserSettings.status === Status.DONE;
export const updateUserSettingsFail = (state) =>
	state.user.updateUserSettings.status === Status.FAIL;
export const updateUserSettingsPending = (state) =>
	state.user.updateUserSettings.status === Status.PENDING;
