import React from 'react';
import {
	string,
	object,
	array
} from 'prop-types';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Collapsible from '../../../../lib/DigitalComponents/Accordion/Collapsible';
import { newRatePlanData } from '../../utils/constants';

import styles from './RatePlanDetails.scss';

const getValue = (value) => {
  const decimals = cloneDeep(value).toString().split('.')[1];

  if (decimals && decimals.length > 4) {
    return value.toString().substring(0, 6);
  }

  return value;
};

export default function RatePlanDetails({
	ratePlan = {},
	ratePlanId,
	m2mAccounts
}) {
		const m2mAccount =
			m2mAccounts &&
			ratePlan.ratePlan &&
			m2mAccounts.find(
				(account) =>
					account.m2mAccountId ===
					(ratePlan.ratePlan.m2mAccountId &&
						ratePlan.ratePlan.m2mAccountId.toString())
			);

	return (
		<div data-spec="rate-plan-details">
			{ratePlan && ratePlan.ratePlan && (
				<Collapsible
					classParentString={styles.container}
					trigger={
						<div className={cn(styles.data, styles.plan_data)}>
							<span className={cn(styles.status_span, styles.plan)}>
								<FormattedMessage
									id="SINGLE_ENDPOINT.RATE_PLAN"
									defaultMessage="Rate Plan"
								/>
							</span>
							<span>
								<FormattedMessage
									id="SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID"
									defaultMessage="{name} - ID: {id}"
									values={{
										name:
											(ratePlan.ratePlan && ratePlan.ratePlan.name) ||
											'No Name',
										id: ratePlanId || 'No ID'
									}}
								/>
							</span>
						</div>
					}
				>
					<div className={styles.top_content}>
						{ratePlan && ratePlan.ratePlan && (
							<div
								className={cn(
									styles.left_content,
									ratePlan.ratePlan.perSubscriberMRC &&
										styles.left_content_width
								)}
							>
								{ratePlan.ratePlan.perSubscriberMRC && (
									<div className={styles.price_wrapper}>
										<div className={styles.mrc_wrapper}>
											<FormattedMessage
												id="ENDPOINTS_RATE_PLAN.MRC"
												defaultMessage="MRC"
											/>
										</div>
										<div>
											<FormattedMessage
												id="ENDPOINTS_RATE_PLAN.PER_SUBSCRIBER"
												defaultMessage="Per Subscriber"
											/>
										</div>
										<div className={styles.price}>
											<div className={styles.round_price}>
                        {getValue(ratePlan.ratePlan.perSubscriberMRC)}
											</div>
										</div>
										<div className={styles.pricing_type}>
											{ratePlan.ratePlan.currency}
										</div>
									</div>
								)}
							</div>
						)}
						<div
							className={cn(
								styles.content,
								ratePlan.ratePlan && ratePlan.ratePlan.perSubscriberMRC && styles.content_width
							)}
						>
							{ratePlan && ratePlan.ratePlan ? (
								newRatePlanData(
									ratePlan,
									m2mAccount ? m2mAccount.m2mBillingAccountId || '' : null
								).map((plan) =>
									(plan.value || plan.value === 0 ? (
										<div
											className={styles.data}
											key={plan.key}
											data-spec="rate-plan-item"
										>
											<span>
												<FormattedMessage
													id={plan.label.id}
													defaultMessage={plan.label.defaultMessage}
												/>
											</span>
											<span>{plan.value}</span>
										</div>
									) : null))
							) : (
								<div>{'RATE_PLAN_CHANGE_SUPPORT'}</div>
							)}
						</div>
					</div>
				</Collapsible>
			)}
		</div>
	);
}

RatePlanDetails.propTypes = {
	ratePlan: object,
	ratePlanId: string,
	m2mAccounts: array
};
