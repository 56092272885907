export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Graph Categories',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Group Data By',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filters',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Graph Options',
	'ANALYTICS.EDIT_CHART': 'Edit Chart',
	'ANALYTICS.CREATE_NEW_CHART': 'Create New Graph',
	'ANALYTICS.DISCLAIMER': 'Disclaimer: ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': `Charting data is provided by multiple systems in various time intervals. The data in the graphs will provide sum total that is approximate.
    Data is represented in UTC-0 time. Final data related to billed services will be available on invoice for the billing cycle.`,
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Endpoints Count Totals',
	'ANALYTICS.NO_USAGE_SIMS': 'No Usage SIMs',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Total SMS Usage',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Total Data Usage',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Total Voice Usage',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Estimated Average Usage',
	'ANALYTICS.NO_CATEGORIES':
		'There are no categories available for this account',
	'ANALYTICS.LINE_GRAPH': 'Line Graph',
	'ANALYTICS.PIE_CHART': 'Pie Graph',
	'ANALYTICS.BAR_GRAPH': 'Bar Graph',
	'ANALYTICS.WORLD_MAP_GRAPH': 'World Map Graph',
	'ANALYTICS.PLATFORM': 'Platform',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Access Technology',
	'ANALYTICS.OPERATOR': 'Operator',
	'ANALYTICS.RATEPLAN': 'Rate Plan',
	'ANALYTICS.COUNTRYCODE': 'Country',
	'ANALYTICS.SIM_STATE': 'SIM State',
	'ANALYTICS.DATE_RANGE': 'DATE RANGE',
	'ANALYTICS.TODAY': 'Today',
	'ANALYTICS.PREVIOUS_DAY': 'Previous Day',
	'ANALYTICS.LAST_10_DAYS': 'Last 10 Days',
	'ANALYTICS.LAST_30_DAYS': 'Last 30 Days',
	'ANALYTICS.CUSTOM_RANGE': 'Custom Range',
	'ANALYTICS.CYCLE': 'CYCLE',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Current Cycle to Date',
	'ANALYTICS.HISTORICAL': 'Historical',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'M2M PLATFORM',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'INCREMENT BY',
	'ANALYTICS.DAY': 'Day',
	'ANALYTICS.WEEK': 'Week',
	'ANALYTICS.MONTH': 'Month',
	'ANALYTICS.SHOW': 'SHOW',
	'ANALYTICS.SUM_TOTALS': 'Sum Totals',
	'ANALYTICS.AVERAGE_TOTALS': 'Average Totals',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL':
		'Please select a category in the first step',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL':
		'No filters available for this category',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Customize Graph Data',
	'ANALYTICS.EXPORT_CHART_DATA': 'Export Graph Data',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'Download PNG',
	'ANALYTICS.REMOVE_CHART': 'Remove Graph',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Add Graph',
	'ANALYTICS.NO_ATTRIBUTES':
		'There are no Group By Attributes available for this account',
	'ANALYTICS.MOVING_AVERAGE': 'Moving Average',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Show summary totals',
	'ANALYTICS.DEFAULT_NAME': 'Graph Name',
	'ANALYTICS.DEFAULT_DESCRIPTION': 'This is a description of the graph.',
};
