import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';
import { isFilterModalOpen } from '../../../Shared/redux/filterModal/selectors';
import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import {
	openAddUsersModal,
	closeAddUsersModal,
	openRemoveUserModal,
	closeRemoveUserModal,
	openPauseUserModal,
	closePauseUserModal,
	getUsers,
	getPauseUser,
	getReactivateUser,
	getPasswordResetUser,
	getRemoveUser,
	getAddressTypes,
	exportUserUsageData,
} from '../../redux/users/actions';

import {
	isAddUsersModalOpened,
	isRemoveUserModalOpened,
	isPauseUserModalOpened,
	getUsersRequest,
	getUsersFail,
	getUsersSuccess,
	getPauseUserRequest,
	getPauseUserFail,
	getPauseUserSuccess,
	getReactivateUserRequest,
	getReactivateUserFail,
	getReactivateUserSuccess,
	getUserResetPasswordRequest,
	getUserResetPasswordFail,
	getUserResetPasswordSuccess,
	removeUserRequest,
	removeUserFail,
	removeUserSuccess,
	inviteUsersSuccess,
	getAddressTypeRequest,
	getAddressTypeFail,
	getAddressTypeSuccess
} from '../../redux/users/selectors';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import {
	setPaginationInit,
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import UsersDesktop from './Desktop';
import UsersMobile from './Mobile';

const mapStateToProps = (state) => ({
	filterModalOpened: isFilterModalOpen(state),
	isAddUsersModalOpened: isAddUsersModalOpened(state),
	isRemoveUserModalOpened: isRemoveUserModalOpened(state),
	isPauseUserModalOpened: isPauseUserModalOpened(state),
	usersRequest: getUsersRequest(state),
	usersFail: getUsersFail(state),
	users: getUsersSuccess(state),
	pauseUserRequest: getPauseUserRequest(state),
	pauseUserFail: getPauseUserFail(state),
	pauseUser: getPauseUserSuccess(state),
	reactivateUserRequest: getReactivateUserRequest(state),
	reactivateUserFail: getReactivateUserFail(state),
	reactivateUser: getReactivateUserSuccess(state),
	resetUserPasswordRequest: getUserResetPasswordRequest(state),
	resetUserPasswordFail: getUserResetPasswordFail(state),
	resetUserPassword: getUserResetPasswordSuccess(state),
	removeUserRequest: removeUserRequest(state),
	removeUserFail: removeUserFail(state),
	removeUser: removeUserSuccess(state),
	inviteUsers: inviteUsersSuccess(state),
	filterData: filterDataSuccess(state),
	getAddressTypeRequest: getAddressTypeRequest(state),
	getAddressTypeFail: getAddressTypeFail(state),
	addressTypes: getAddressTypeSuccess(state),
	user: getUser(state),
	searchParameter: getSearchParameter(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
});

const mapDispatchToProps = (dispatch) => ({
	getUsers: (params) => dispatch(getUsers(params)),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	openAddUsersModal: () => dispatch(openAddUsersModal()),
	closeAddUsersModal: () => dispatch(closeAddUsersModal()),
	openRemoveUserModal: () => dispatch(openRemoveUserModal()),
	closeRemoveUserModal: () => dispatch(closeRemoveUserModal()),
	openPauseUserModal: () => dispatch(openPauseUserModal()),
	closePauseUserModal: () => dispatch(closePauseUserModal()),
	pushTo: (url) => dispatch(localizedRoute(url)),
	goToEditUser: (url) => dispatch(localizedRoute(url)),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	getPauseUser: (params) => dispatch(getPauseUser(params)),
	getReactivateUser: (params) => dispatch(getReactivateUser(params)),
	getPasswordResetUser: (params) => dispatch(getPasswordResetUser(params)),
	getRemoveUser: (params) => dispatch(getRemoveUser(params)),
	getAddressTypes: () => dispatch(getAddressTypes()),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit()),
	exportUserUsageData: () => dispatch(exportUserUsageData()),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(UsersDesktop);

export const Mobile = connect(mapStateToProps, mapDispatchToProps)(UsersMobile);
