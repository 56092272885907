import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import ValidateAddressModal from './ValidateAddressModal';

import {
	validateAddress
} from '../../redux/companies/actions';

import {
  validateAddressRequest,
  validateAddressFail,
  validateAddressSuccess
} from '../../redux/companies/selectors';

const mapStateToProps = (state) => ({
  formData: getFormValues('ValidateAddressModal')(state),
  validateAddressRequest: validateAddressRequest(state),
  validateAddressFail: validateAddressFail(state),
  validatedAddress: validateAddressSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
  validateAddress: (params) => dispatch(validateAddress(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateAddressModal);
