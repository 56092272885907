import React from 'react';
import { FormattedMessage } from 'react-intl';

export const securityFeaturesHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="SECURITY_FEATURES.M2M_ACCOUNT_NAME"
				defaultMessage="M2M ACCOUNT NAME"
			/>
		),
		name: 'm2mAccountName'
	},
	{
		title: (
			<FormattedMessage
				id="SECURITY_FEATURES.DATE_SUBMITTED"
				defaultMessage="DATE SUBMITTED"
			/>
		),
		name: 'createdAt'
	},
	{
		title: (
			<FormattedMessage
				id="SECURITY_FEATURES.STATUS"
				defaultMessage="STATUS"
			/>
		),
		name: 'status'
	},
];
export const securityFeaturesHeaderDesktop = [
	{
		title: (
			<FormattedMessage
				id="SECURITY_FEATURES.M2M_ACCOUNT_NAME"
				defaultMessage="M2M ACCOUNT NAME"
			/>
		),
		name: 'm2mAccountName'
	},
	{
		title: (
			<FormattedMessage
				id="SECURITY_FEATURES.DATE_SUBMITTED"
				defaultMessage="DATE SUBMITTED"
			/>
		),
		name: 'createdAt'
	},
	{
		title: (
			<FormattedMessage
				id="SECURITY_FEATURES.STATUS"
				defaultMessage="STATUS"
			/>
		),
		name: 'status'
	},
];
