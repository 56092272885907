import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty, includes } from 'lodash';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import FilterModal from '../../../Shared/components/FilterModal';
import DownloadReport from './Assets/DownloadReport';
import Loader from '../../../../lib/DigitalComponents/Loader';
import StatusPromptModal from '../StatusPromptModal';
import ScheduleModal from '../ScheduleModal';
import RatePlanPromptModal from '../RatePlanPromptModal';
import RatePlanScheduleModal from '../RatePlanScheduleModal';
import NotesModal from '../NotesModal';
import GenerateReportModal from '../GenerateReportModal';
import MultiEndpointAction from '../MultiEndpointAction';
import BulkUploadModal from '../BulkUploadModal';
import BulkUploadInstructionsModal from '../BulkUploadInstructionsModal';
import {
	tableHeaderPermissionsCheck,
	filtersPermissionsCheck
} from '../../../../utils/constants';

import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import styles from './EndpointsData.scss';
import {
	EndpointsTableTabs as tableHeaderTabs,
	endpointSignature,
	endpointsFiltersData,
	notificationMessages,
	searchMenu,
	realPropName,
	supportAdvancedSearch
} from '../../utils/constants';
import ActionBar from '../../../Shared/views/ActionBarView';
import EditTableModal from '../EditTableModal';
import NoData from '../../../Shared/views/NoDataView';
import { getEndpointsFilters } from '../../services/EndpointsService';

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg'
);

function EndpointsDataWrapper(WrappedComponent) {
	class EndpointsData extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				bulkModalShow: false,
				isEditTableModalOpen: false,
				isGenerateReportModalOpen: false,
				allowAddAction: true,
				savedFilters: {},
				isStatusPromptModalOpen: false,
				isScheduleModalOpen: false,
				isScheduleModeOn: false,
				isNoteModalOpen: false,
				newState: {},
				seNotes: '',
				isRatePlanScheduleModalOpen: false,
				isRatePlanPromptModalOpen: false,
				bulkInstructionsHidden: true,
			};
		}

		componentDidUpdate(prevProps) {
			const { customLabelsUpdateSuccess } = this.props;

			this.checkSingleEndpointStatusUpdate(
				prevProps.singleEndpointUpdateSuccess,
				prevProps.singleEndpointUpdateFail
			);

			this.checkSingleRatePlanUpdate(
				prevProps.ratePlanUpdateSuccess,
				prevProps.ratePlanUpdateFail
			);

			if (
				prevProps.customLabelsUpdateSuccess !== customLabelsUpdateSuccess &&
				customLabelsUpdateSuccess
			) {
				this.refreshPage();
			}
		}

		checkSingleEndpointStatusUpdate = (
			prevSingleEndpointUpdateSuccess,
			prevSingleEndpointUpdateFail
		) => {
			const {
				singleEndpointUpdateSuccess,
				singleEndpointUpdateFail,
				updateSingleEndpointStatusInit,
				showNotificationSuccess,
				showNotificationError,
				intl
			} = this.props;
			const { changeStatusSubmitData, isScheduleModeOn } = this.state;

			if (
				prevSingleEndpointUpdateSuccess !== singleEndpointUpdateSuccess &&
				singleEndpointUpdateSuccess &&
				!isScheduleModeOn
			) {
				updateSingleEndpointStatusInit();
				this.scheduleModeOff();
				this.refreshPage();
				showNotificationSuccess(changeStatusSubmitData, intl.formatMessage);
			}

			if (
				prevSingleEndpointUpdateFail !== singleEndpointUpdateFail &&
				singleEndpointUpdateFail &&
				!isScheduleModeOn
			) {
				updateSingleEndpointStatusInit();
				this.scheduleModeOff();
				showNotificationError(changeStatusSubmitData, intl.formatMessage);
			}
		};

		checkSingleRatePlanUpdate = (
			prevRatePlanUpdateSuccess,
			prevRatePlanUpdateFail
		) => {
			const {
				updateRatePlanInit,
				ratePlanUpdateSuccess,
				ratePlanUpdateFail,
				ratePlanNotificationSuccess,
				ratePlanNotificationError,
				intl
			} = this.props;
			const { changeRatePlanSubmitData, isScheduleModeOn } = this.state;

			if (
				prevRatePlanUpdateSuccess !== ratePlanUpdateSuccess &&
				ratePlanUpdateSuccess &&
				!isScheduleModeOn
			) {
				updateRatePlanInit();
				this.scheduleModeOff();
				this.refreshPage();
				ratePlanNotificationSuccess(
					changeRatePlanSubmitData,
					intl.formatMessage
				);
			}

			if (
				prevRatePlanUpdateFail !== ratePlanUpdateFail &&
				ratePlanUpdateFail &&
				!isScheduleModeOn
			) {
				updateRatePlanInit();
				this.scheduleModeOff();
				ratePlanNotificationError(changeRatePlanSubmitData, intl.formatMessage);
			}
		};

		checkFilters = () => {
			const { getSavedFilters } = this.props;
			const savedFilters = getSavedFilters();
			const filterDataKeys = Object.keys(savedFilters);
			let checkedFilters = [];

			filterDataKeys.length > 0 &&
				// eslint-disable-next-line array-callback-return
				filterDataKeys.map((item) => {
					const activeFilters = savedFilters[item].filter((val) => val);
					checkedFilters = [...checkedFilters, ...activeFilters];
				});

			return checkedFilters.length === 0;
		};

		openGenerateReportModal = () => {
			const { modalOpened } = this.props;
			this.setState({
				isGenerateReportModalOpen: true
			});
			modalOpened();
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		openPromptModal = () => {
			const { modalOpened } = this.props;
			this.setState({
				isStatusPromptModalOpen: true
			});
			modalOpened();
		};

		closePromptModal = () => {
			const { modalClosed } = this.props;
			this.setState({
				isStatusPromptModalOpen: false,
				isScheduleModeOn: false
			});
			modalClosed();
		};

		closeScheduleModal = () => {
			const { updateSingleEndpointStatusInit } = this.props;
			updateSingleEndpointStatusInit();
			this.setState({
				isScheduleModalOpen: false,
				isScheduleModeOn: false
			});
		};

		scheduleModeOff = () => this.setState({ isScheduleModeOn: false });

		initiateChange = () => {
			const { clearSelectedSims, updateStatus } = this.props;
			const { changeStatusSubmitData } = this.state;
			this.closePromptModal();
			updateStatus(changeStatusSubmitData);
			clearSelectedSims();
		};

		changeStatus = (data, endpoint) => {
			const { intl } = this.props;
			this.openPromptModal();
			this.setState({
				newState: {
					value: data && data.data && data.data.targetState,
					label: intl.formatMessage({
						id: `ENDPOINTS.${data && data.data && data.data.targetState}`,
						defaultMessage: data && data.data && data.data.targetState
					})
				},
				changeStatusSubmitData: data,
				endpoint
			});
		};

		clearFilters = () => {
			const { settings, updateUserSettings, activeTab } = this.props;
			const activeTabInTableColumns = find(settings, { name: activeTab });
			let newValue = {};

			if (activeTabInTableColumns) {
				newValue = {
					value: JSON.parse(activeTabInTableColumns.value)
				};

				delete newValue.value.data[0].filters;

				newValue = {
					name: activeTabInTableColumns.name,
					refTypeId: 1,
					value: JSON.stringify(newValue.value)
				};
			}
			updateUserSettings(
				newValue,
				activeTabInTableColumns.id,
				notificationMessages
			);
		};

		openEditTableModal = () => {
			this.setState({
				isEditTableModalOpen: true
			});
		};

		onChange = () => {
			const {
				filterData,
				updateUserSettings,
				createUserSettings,
				settings,
				activeTab,
				clearSelectedSims,
				tableHeader
			} = this.props;
			const activeTabInTableColumns = find(settings, { name: activeTab });

			let newValue = {};
			let filters = {};
			Object.keys(filterData).forEach((key) => {
				filterData[key]
					.filter((data) => data.checked)
					.forEach((item) => {
						const current = filters[key] ? [...filters[key]] : [];
						filters = {
							...filters,
							[key]: [...current, item.value]
						};
					});
			});

			if (activeTabInTableColumns) {
				const tabName = activeTabInTableColumns
					? JSON.parse(activeTabInTableColumns.value).data[0].tabName
					: tableHeaderTabs[0].value.data[0].tabName;

				if (Object.keys(filterData).length > 0) {
					newValue = {
						name: activeTab,
						refTypeId: 1,
						value: JSON.stringify({
							signature: endpointSignature,
							data: [
								{
									tabName,
									value: 0,
									filters
								},
								...tableHeader
							]
						})
					};
				}
				updateUserSettings(
					newValue,
					activeTabInTableColumns.id,
					notificationMessages
				);
			} else {
				createUserSettings(
					{
						name: activeTab,
						refTypeId: 1,
						value: JSON.stringify({
							signature: endpointSignature,
							data: [
								{
									tabName: tableHeaderTabs[0].value.data[0].tabName,
									value: 0,
									filters
								},
								...tableHeaderTabs[0].value.data
									.filter((item) => item.checked)
									.map((item, index) => ({
										title: item.title,
										name: item.name,
										value: index + 1
									}))
							]
						})
					},
					notificationMessages
				);
			}

			clearSelectedSims();
		};

		getOrderColumn = () => {
			const { orderColumn } = this.props;
			return !isEmpty(orderColumn) ? orderColumn.apiString : null;
		};

		renderDownload = () => {
			const { data } = this.props;
			return (
				<FormattedMessage
					id="ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE"
					defaultMessage="Please apply one or more filters to enable export feature"
					data-spec="download"
				>
					{(formattedValue) => (
						<div
							className={styles.icon}
							data-tip={
								this.checkFilters() ? formattedValue : this.exportDataMessage()
							}
							data-for="createCustomReportTooltip"
						>
							<DocumentDownloadIcon
								id="download-icon"
								data-spec="download-icon"
								onClick={() =>
									!this.checkFilters() &&
									data &&
									data.totalCount &&
									this.openGenerateReportModal()}
								className={cn(
									styles.icon,
									this.checkFilters() || (data && !data.totalCount)
										? styles.disabled
										: undefined
								)}
							/>
							<ReactTooltip
								className={styles.tooltip}
								type="light"
								id="createCustomReportTooltip"
							/>
						</div>
					)}
				</FormattedMessage>
			);
		};

		exportDataMessage = () => {
			const { data, intl } = this.props;
			return data && data.totalCount
				? intl.formatMessage({
						id: 'ENDPOINTS.EXPORT_TO_FILE_TOOLTIP',
						defaultMessage: 'Export to file'
				  })
				: intl.formatMessage({
						id: 'ENDPOINTS.WE_ARE_SORRY_THERE_IS_NO_DATA_TO_EXPORT',
						defaultMessage: 'We are sorry, there is no data to export'
				  });
		};

		refreshPage = () => {
			const {
				getEndpoints,
				getCustomLabels,
				dataLimit,
				pagination,
				mergeFiltersAndSimpleSearchParameters,
				clearSelectedSims
			} = this.props;
			clearSelectedSims();
			getEndpoints(
				{
					dataLimit,
					dataOffset: dataLimit * pagination
				},
				mergeFiltersAndSimpleSearchParameters(),
				this.getOrderColumn()
			);
			getCustomLabels();
		};

		// single sim schedule change
		scheduleChange = () => {
			this.closePromptModal();
			this.openScheduleModal(); // LOGIKA ZA MODAL
		};

		openScheduleModal = () => {
			this.setState({
				isScheduleModalOpen: true,
				isScheduleModeOn: true
			});
		};

		onNoteClick = (seNotes) => {
			this.setState({ seNotes, isNoteModalOpen: true });
		};

		closeNoteModal = () => {
			this.setState({
				isNoteModalOpen: false,
				seNotes: ''
			});
		};

		batchModalHide = (disabled) => {
			const { bulkBatchUploadModalClose } = this.props;
			bulkBatchUploadModalClose(disabled);
		};

		closeGenerateReportModal = () => {
			const { updateSingleEndpointStatusInit, modalClosed } = this.props;
			updateSingleEndpointStatusInit();
			this.setState({
				isGenerateReportModalOpen: false
			});
			modalClosed();
		};

		generateReport = (value) => {
			const {
				generateReport,
				dataLimit,
				pagination,
				mergeFiltersAndSimpleSearchParameters,
				tableHeader,
				setSearchInit
			} = this.props;
			const columnsToSelect = tableHeader.map((item) => item.name).join(',');
			generateReport(
				{
					dataLimit,
					dataOffset: dataLimit * pagination,
					selectedPage: pagination,
					dataSort: this.getOrderColumn(),
					columnsToSelect,
					reportPrefix: value
				},
				mergeFiltersAndSimpleSearchParameters()
			);
			this.closeGenerateReportModal();
			setSearchInit();
		};

		closeRatePlanScheduleModal = () => {
			this.setState({
				isRatePlanScheduleModalOpen: false
			});
		};

		closeRatePlanPromptModal = () => {
			const { modalClosed } = this.props;
			this.setState({
				isRatePlanPromptModalOpen: false,
				isScheduleModeOn: false
			});
			modalClosed();
		};

		openRatePlanPromptModal = () => {
			this.setState({ isRatePlanPromptModalOpen: true });
		};

		changeRatePlan = (data, endpoint) => {
			this.openRatePlanPromptModal();
			this.setState({
				newRatePlan: {
					value: data.data.ratePlanId,
					label: data.label
				},
				changeRatePlanSubmitData: data,
				endpoint
			});
		};

		initiateRatePlan = () => {
			const { updateRatePlan } = this.props;
			const { changeRatePlanSubmitData } = this.state;
			this.closeRatePlanPromptModal();
			updateRatePlan({
				id: changeRatePlanSubmitData.id,
				data: { ratePlanId: changeRatePlanSubmitData.data.ratePlanId }
			});
		};

		openRatePlanScheduleModal = () => {
			this.setState({
				isRatePlanScheduleModalOpen: true,
				isScheduleModeOn: true
			});
		};

		scheduleRatePlanChange = () => {
			this.closeRatePlanPromptModal();
			this.openRatePlanScheduleModal();
		};

		showInfoIcon = () => {
      const { searchParameterProp } = this.props;

      return includes(supportAdvancedSearch, searchParameterProp);
    }

		render() {
			const {
				data,
				user,
				customFieldLabels,
				createUserSettings,
				updateUserSettings,
				deleteUserSettings,
				pagination,
				createFilterData,
				areEndpointsFetching,
				endpointsRequestFail,
				updateUserSettingsPending,
				isBatchUploadModalOpened,
				actionError,
				clearError,
				batchModalType,
				handlePageClick,
				dataLimit,
				selectedSims,
				clearSelectedSims,
				isTemplateDownloading,
				isBatchFilesUploading,
				settings,
				getFiltersRequest,
				isSingleEndpointUpdating,
				isRatePlanUpdating,
				getCustomLabelsRequest,
				onSearchSubmit,
				setSearchInit
			} = this.props;
			const {
				isEditTableModalOpen,
				isStatusPromptModalOpen,
				isScheduleModalOpen,
				isNoteModalOpen,
				newState,
				endpoint,
				seNotes,
				isGenerateReportModalOpen,
				isRatePlanScheduleModalOpen,
				isRatePlanPromptModalOpen,
				newRatePlan,
				isScheduleModeOn,
				bulkInstructionsHidden
      } = this.state;

			const params = {
				createFilterData,
				service: getEndpointsFilters,
				params: filtersPermissionsCheck(
					endpointsFiltersData,
					realPropName,
					user
				)
			};
			const disabled =
				isTemplateDownloading || isBatchFilesUploading || getFiltersRequest;
			const isAnyRequest =
				updateUserSettingsPending ||
				areEndpointsFetching ||
				getCustomLabelsRequest ||
				(isSingleEndpointUpdating && !isScheduleModeOn) ||
				(isRatePlanUpdating && !isScheduleModeOn);

			return (
				<div data-spec="EndpointsData">
					<ActionBar
						menu={tableHeaderPermissionsCheck(searchMenu, user)}
						onSearchSubmitCallback={onSearchSubmit}
						showAdvancedSearchTooltip={this.showInfoIcon()}
						showInputValueInTooltip
						openFilterModal={this.openFilterModal}
						clearFilters={this.clearFilters}
						onChange={this.onChange}
						openEditTableModal={this.openEditTableModal}
						refreshPage={this.refreshPage}
						actions={
							<DownloadReport
								checkFilters={this.checkFilters}
								exportDataMessage={this.exportDataMessage}
								pagination={pagination}
								totalCount={data && data.totalCount}
								openGenerateReportModal={this.openGenerateReportModal}
							/>
						}
						isDisabled={isAnyRequest}
						showPagination
						totalCount={data && data.totalCount}
						selectedPage={pagination}
						dataLimit={dataLimit}
					/>
					{isAnyRequest && <Loader />}
					{!isAnyRequest && data && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							changeStatus={this.changeStatus}
							changeRatePlan={this.changeRatePlan}
							onNoteClick={this.onNoteClick}
						/>
					)}
					{((!isAnyRequest && data && data.totalCount === 0) ||
						endpointsRequestFail) && (
						<NoData
							table
							title={
								<FormattedMessage
									id="ENDPOINTS.NO_ENDPOINTS_AVAILABLE"
									defaultMessage="No Endpoints Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="ENDPOINTS.NO_ENDPOINTS_SUBTITLE"
									defaultMessage="There are No Endpoints for this Table to show you right now"
								/>
							}
						/>
					)}
					{isEditTableModalOpen && (
						<EditTableModal
							show
							onClose={() => this.setState({ isEditTableModalOpen: false })}
							customFieldLabels={customFieldLabels}
							clearSelectedSims={clearSelectedSims}
							user={user}
							settings={settings}
							setSearchParameterInit={setSearchInit}
							createUserSettings={(newValue) =>
								createUserSettings(newValue, notificationMessages)}
							updateUserSettings={(newValue, id) =>
								updateUserSettings(newValue, id, notificationMessages)}
							deleteUserSettings={(id) =>
								deleteUserSettings(id, notificationMessages)}
						/>
					)}
					<FilterModal
						type="checkbox"
						onApply={this.onChange}
						params={params}
					/>
					{isStatusPromptModalOpen && (
						<StatusPromptModal
							show
							onCancel={this.closePromptModal}
							initiateChange={this.initiateChange}
							newState={newState}
							scheduleChange={this.scheduleChange}
							endpoint={endpoint}
						/>
					)}
					{isScheduleModalOpen && (
						<ScheduleModal
							show
							onCancel={this.closeScheduleModal}
							newState={newState}
							endpoint={endpoint}
						/>
					)}
					{isNoteModalOpen && (
						<NotesModal show note={seNotes} onCancel={this.closeNoteModal} />
					)}
					{isBatchUploadModalOpened && batchModalType === 'change' && (
						<MultiEndpointAction
							show
							onHide={() => this.batchModalHide(disabled)}
							selectedSims={selectedSims}
							actionError={actionError}
							clearError={clearError}
							batchModalType={batchModalType}
							fetchAllEndpoints={() =>
								handlePageClick({ selected: pagination })}
							dataLimit={dataLimit}
						/>
					)}
					{isBatchUploadModalOpened && batchModalType === 'upload' && bulkInstructionsHidden && (
						<BulkUploadModal
							show
							onHide={() => this.batchModalHide(disabled)}
							clearError={clearError}
							fetchAllEndpoints={() =>
								handlePageClick({ selected: pagination })}
							dataLimit={dataLimit}
							bulkInstructionsCallback={() => this.setState({ bulkInstructionsHidden: false })}
						/>
					)}
					{isBatchUploadModalOpened && batchModalType === 'upload' && !bulkInstructionsHidden && (
						<BulkUploadInstructionsModal
							show
							onCancel={() => this.setState({ bulkInstructionsHidden: true })}
						/>
					)}
					{isGenerateReportModalOpen && (
						<GenerateReportModal
							show
							onCancel={this.closeGenerateReportModal}
							totalCount={data && data.totalCount}
							onClick={(value) => {
								this.generateReport(value);
								this.closeGenerateReportModal();
							}}
						/>
					)}
					{isRatePlanScheduleModalOpen && (
						<RatePlanScheduleModal
							show
							onCancel={this.closeRatePlanScheduleModal}
							newRatePlan={newRatePlan}
							endpoint={endpoint}
						/>
					)}
					{isRatePlanPromptModalOpen && (
						<RatePlanPromptModal
							show
							onCancel={this.closeRatePlanPromptModal}
							initiateChange={this.initiateRatePlan}
							newRatePlan={newRatePlan}
							scheduleChange={this.scheduleRatePlanChange}
							endpoint={endpoint}
						/>
					)}
				</div>
			);
		}
	}

	const { object, bool, number, func, array, string } = PropTypes;

	EndpointsData.propTypes = {
		data: object,
		user: object,
		pagination: number,
		openBatchUploadModal: func,
		openGlobalFilterModal: func,
		createUserSettings: func,
		fetchAllEndpoints: func,
		endpointsRequestFail: bool,
		getEndpoints: func,
		getCustomLabels: func,
		setEndpointsActiveTab: func,
		modalOpened: func,
		allowAddAction: bool,
		totalCount: number,
		dataLimit: number,
		endpointsFilters: array,
		settings: array,
		filterData: object,
		activeTab: string,
		createFilterData: func,
		getSavedFilters: func,
		modalClosed: func,
		singleEndpointScheduleModeOff: func,
		updateSingleEndpointStatusInit: func,
		mergeFiltersAndSimpleSearchParameters: func,
		updateUserSettings: func,
		updateStatus: func,
		openFilterModal: func,
		orderColumn: object,
		bulkBatchUploadModalClose: func,
		updateRatePlan: func,
		deleteUserSettings: func,
		customFieldLabels: object,
		areEndpointsFetching: bool,
		updateUserSettingsPending: bool,
		isBatchUploadModalOpened: bool,
		actionError: bool,
		clearError: func,
		batchModalType: string,
		handlePageClick: func,
		selectedSims: array,
		isTemplateDownloading: bool,
		isBatchFilesUploading: bool,
		generateReport: func,
		setEndpointsPagination: func,
		getTableHeaders: func,
		tableHeader: array,
		getFiltersRequest: bool,
		clearSelectedSims: func,
		isSingleEndpointUpdating: bool,
		singleEndpointUpdateSuccess: bool,
		singleEndpointUpdateFail: bool,
		showNotificationSuccess: func,
		showNotificationError: func,
		isRatePlanUpdating: bool,
		ratePlanUpdateSuccess: bool,
		ratePlanUpdateFail: bool,
		updateRatePlanInit: func,
		ratePlanNotificationSuccess: func,
		ratePlanNotificationError: func,
		customLabelsUpdateSuccess: bool,
		intl: object,
		searchParameterProp: string,
		onSearchSubmit: func,
		getCustomLabelsRequest: bool,
		setSearchInit: func
	};
	return injectIntl(EndpointsData);
}

export default EndpointsDataWrapper;
