import { connect } from 'react-redux';

import { updateCustomFieldsValues } from '../../redux/actions';
import Desktop from './Desktop';
import Mobile from './Mobile';

const mapDispatchToProps = (dispatch) => (
	{
		updateCustomFieldsValues: (id, fields, type) => dispatch(
			updateCustomFieldsValues(id, fields, type)
		),
	}
);

export const CustomFieldsValuesDesktop = connect(
	null,
	mapDispatchToProps
)(Desktop);
export const CustomFieldsValuesMobile = connect(
	null,
	mapDispatchToProps
)(Mobile);
