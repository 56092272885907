import React from 'react';
import {
	string,
	object,
	func,
} from 'prop-types';

import hoc from '../CustomFieldsValues';

import styles from './Mobile.scss';

function Mobile({ keyName, temp, getNumber, customFieldLabels }) {
	return (
		<div className={styles.data} data-spec="custom-field-labels-mobile">
			<span>{customFieldLabels[keyName]}</span>
			<span>{temp[`customField${getNumber(keyName)}`]}</span>
		</div>
	);
}

Mobile.propTypes = {
	keyName: string,
	temp: object,
	getNumber: func,
	customFieldLabels: object,
};
Mobile.defaultProps = {
	keyName: '',
	temp: {},
	getNumber: undefined,
	customFieldLabels: {},
};

export default hoc(Mobile);
