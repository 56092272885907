import globalAction from '../../../../redux/global/globalAction';

import { GET_SMS_DETAILS, GET_SMS_DETAILS_FILTERS } from './constants';

import {
	getSmsDetails as getSmsDetailsService,
	getSmsDetailsFilters as getSmsDetailsFiltersService
} from '../../services/SmsDetailsService';

/** ************ get sms details ************* */
export const getSmsDetails = (id, params = {}) =>
	globalAction(GET_SMS_DETAILS, getSmsDetailsService, { id, params });

/** ************ get sms details filters ************* */
export const getSmsDetailsFilters = (id, params = {}) =>
	globalAction(GET_SMS_DETAILS_FILTERS, getSmsDetailsFiltersService, {
		id,
		params
	});
