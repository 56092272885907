import React, { PureComponent } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import FilterModal from '../../../Shared/components/FilterModal';
import ActionBar from '../../../Shared/views/ActionBarView';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import NoData from '../../../Shared/views/NoDataView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import GrantAccessModal from '../GrantAccessModal';
import AccountAccessListModal from '../ViewAccountAccessModal';
import ResendInvitationModal from './ResendInvitationModal';
import CancelInvitationModal from './CancelInvitationModal';

import {
	onOrderClick,
	newOnPageChange,
	filterHelpers,
} from '../../../../utils/tableHelpers';

import {
	RegistrationsTableHeader as tableHeader,
	RegistrationsMobileTableHeader as mobileTableHeader,
	userSearchMenu,
} from '../../utils/constants';

import {
	getFilters as getRegistrationsFiltersService,
	getFiltersCompanyUser,
} from '../../services/RegistrationService';

import {
	isUserAllowedToAccess,
	checkIfUserIsSystem,
} from '../../../../utils/AuthSelector';

import styles from './PendingInvitations.scss';

const PendingInvitations = () => (WrappedComponent) => {
	class PendingInvitationsComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				orderColumn: {},
				isGrantAccessModalOpened: false,
				seletedId: null,
				isAccountAccessModalOpen: false,
				selectedAccount: null,
				isActionsModalOpen: false,
				isCancelActionModalOpen: false,
				selectedUser: null,
			};
		}

		componentDidMount() {
			this.getRegistrations();
		}

		componentDidUpdate(prevProps) {
			const {
				cancelRegistration,
				resendInvitation,
				grantAccess,
			} = this.props;

			if (prevProps.cancelRegistration !== cancelRegistration) {
				this.getRegistrations();
			}

			if (prevProps.resendInvitation !== resendInvitation) {
				this.getRegistrations();
			}

			if (prevProps.grantAccess !== grantAccess) {
				this.getRegistrations();
			}
		}

		getRegistrations = () => {
			const {
				getRegistrations,
				dataLimit,
				system,
				filterData,
				searchParameter,
				dataOffset
			} = this.props;

			const { orderColumn } = this.state;

			const realProps = {
				status: 'registrationStatusId',
				userGroups: 'userGroupId',
			};

			let simpleSearchParams = [];

			if (searchParameter) {
				simpleSearchParams = [
					{
						operator: 'iLike',
						prop: searchParameter.prop,
						propValue: `${searchParameter.propValue}%`,
					},
				];
			}

			let filterParams = [];
			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}

			const filterPendingInvitations = [
				{
					propValue: [1, 2],
					prop: 'registrationStatusId',
					operator: 'in',
				},
				...simpleSearchParams,
				...filterParams,
			];

			const params = {
				searchParams: filterPendingInvitations,
				additionalParams: {
					include: ['RegistrationStatus'],
					dataLimit: dataLimit || 50,
					dataOffset,
					dataSort: orderColumn.apiString && orderColumn.apiString.includes('status')
						? `registrationStatusId ${orderColumn.apiString.split(' ')[1]}`
						: orderColumn.apiString,
				},
				system,
			};

			getRegistrations(params);
		};

		openGrantAccessModal = (params) => {
			this.setState({
				isGrantAccessModalOpened: true,
				seletedId: params.id,
			});
		};

		closeGrantAccessModal = () => {
			this.setState({
				isGrantAccessModalOpened: false,
				seletedId: null,
			});
		};

		onSearchSubmitCallback = () => {
			this.getRegistrations();
		};

		totalCount = () => {
			const { registrations, dataLimit } = this.props;
			return Math.ceil(registrations.totalCount / dataLimit);
		};

		onPageChange = async (data) => {
      const { setDataOffset, setSelectedPage, dataLimit } = this.props;
			const { selected } = data;
			await setDataOffset(dataLimit * selected);
			await setSelectedPage(selected);
			this.getRegistrations();
    }

		renderAction = (data) => {
			const { user } = this.props;
			const options = [
				{
					value: 0,
					label: (
						<FormattedMessage
							id="ONBOARDING.RESEND_INVITE"
							defaultMessage="Resend Invite"
						/>
					),
					data,
				},
				{
					value: 1,
					label: (
						<FormattedMessage
							id="ONBOARDING.CANCEL_INVITE"
							defaultMessage="Cancel Invite"
						/>
					),
					data,
				},
			];
			if (data.registrationStatusId === 1) {
				options.push({
					value: 2,
					label: (
						<FormattedMessage
							id="ONBOARDING.GRANT_ACCESS"
							defaultMessage="Grant Access"
						/>
					),
					data,
				});
			}

			return (
				<div data-spec="pending-status" className={styles.order_status_select}>
					<Select
						menuPosition="fixed"
						name="status"
						placeholder={
							<FormattedMessage
								id="ONBOARDING.SELECT_ACTION"
								defaultMessage="Select Action"
							/>
						}
						onChange={this.selectOption}
						options={options}
						noBorder
						isDisabled={
							checkIfUserIsSystem(user)
								? !isUserAllowedToAccess(
										['system.accessmanagement.users_rw'],
										user
								  )
								: !isUserAllowedToAccess(
										['mnc.accessmanagement.users_rw'],
										user
								  )
						}
					/>
				</div>
			);
		};

		selectOption = (item) => {
			switch (item.value) {
				case 0:
					this.openActionsModal(item.data);
					break;
				case 1:
					this.openCancelActionsModal(item.data);
					break;
				case 2:
					this.openGrantAccessModal(item.data);
					break;
				// no default
			}
		};

		renderUserFirstName = (data) => (
			<div className={styles.highlight}>{data.firstName}</div>
		);

		renderEmail = (data) => (
			<div className={styles.email_wrap}>
				<a href={`mailto:${data.email}`}>{data.email}</a>
			</div>
		);

		renderUserGroup = (data) => (
			<div>{data.userGroup && data.userGroup.name}</div>
		);

		renderStatus = (data) => (
			<div
				className={cn(
					styles.status,
					styles[data.registrationStatus.name.replace(/\s/g, '_').toLowerCase()]
				)}
			>
				{data.registrationStatus.name}
			</div>
		);

		renderAccountAccess = (data) => (
			<div className={styles.assigned_accounts}>
				{data.userGroup && data.userGroup.platform < 1 ? (
					<FormattedMessage
						id="ONBOARDING.UNRESTRICTED_ACCESS"
						defaultMessage="Unrestricted Access"
					/>
				) : (
					data.userGroup &&
					data.userGroup.platform && (
						<div>
							{data.userGroup.platform[0].m2mAccountName}
							{data.userGroup.platform.length < 2 ? null : (
								<div
									className={styles.highlight}
									onClick={() =>
										this.openAccountAccessModal(data.userGroup.platform)}
								>
									<FormattedMessage
										id="ONBOARDING.AND_OTHERS"
										defaultMessage={'and {totalCount} others...'}
										values={{
											totalCount: data.userGroup.platform.length - 1,
										}}
										key={data.userGroup.platform.id}
									/>
								</div>
							)}
						</div>
					)
				)}
			</div>
		);

		getOptions = (target) => {
			const { orderColumn } = this.state;
			const {
				dataLimit,
				selectedPage,
				setDataOffset,
				setSelectedPage,
				registrations
			} = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			if (isDesktop) {
				options.header = tableHeader;
			}

			options.tableOptions = {
				orderByData: ['all'],
				excludeFromOrdering: ['actions', 'accountAccess', 'userGroup'],
				showPagination: registrations && registrations.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				getOrderData: (data) => onOrderClick(data, this.getRegistrations, this),
				orderColumn,
				onPageChange: (data) =>
				newOnPageChange(
					data,
					this.getRegistrations,
					dataLimit,
					setDataOffset,
					setSelectedPage
				)
			};

			options.customComponents = {
				firstName: {
					type: 'custom',
					component: (data) => this.renderUserFirstName(data),
				},
				email: {
					type: 'custom',
					component: (data) => this.renderEmail(data),
				},
				userGroup: {
					type: 'custom',
					component: (data) => this.renderUserGroup(data),
				},
				accountAccess: {
					type: 'custom',
					component: (data) => this.renderAccountAccess(data),
				},
				status: {
					type: 'custom',
					component: (data) => this.renderStatus(data),
				},
			};

			if (isDesktop) {
				options.customComponents = {
					...options.customComponents,
					actions: {
						type: 'custom',
						component: (data) => this.renderAction(data),
					},
				};
			}

			if (isMobile) {
				options.header = mobileTableHeader;
			}

			return options;
		};

		clearFilters = () => {
			this.getRegistrations();
		};

		onApply = () => {
			this.getRegistrations();
		};

		onChange = () => {
			this.getRegistrations();
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		createFilterData = (data) => {
			const { messages } = this.props;
			const filterDataKeys = Object.keys(data);
			let filter = {};
			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					filter = {
						...filter,
						[`${item}`]: data[item].resultList.map((filterItem) => {
							const lbl = filterItem.code || filterItem.name;
							return {
								value: filterItem.id,
								label: messages[`FILTER_VALUE_${lbl.toUpperCase()}`] || lbl,
								checked: false,
							};
						}),
					};
				});
			return filter;
		};

		openAccountAccessModal = (data) => {
			this.setState({
				isAccountAccessModalOpen: true,
				selectedAccount: data,
			});
		};

		closeAccountAccessModal = () => {
			this.setState({
				isAccountAccessModalOpen: false,
				selectedAccount: null,
			});
		};

		openActionsModal = (data) => {
			this.setState({
				isActionsModalOpen: true,
				selectedUser: data,
			});
		};

		closeActionsModal = () => {
			this.setState({
				isActionsModalOpen: false,
				selectedUser: null,
			});
		};

		openCancelActionsModal = (data) => {
			this.setState({
				isCancelActionModalOpen: true,
				selectedUser: data,
			});
		};

		closeCancelActionsModal = () => {
			this.setState({
				isCancelActionModalOpen: false,
				selectedUser: null,
			});
		};

		render() {
			const {
				registrations,
				getRegistrationsRequest,
				getRegistrationsFail,
				dataLimit,
				messages,
				patchResendRegistration,
				patchCancelRegistration,
				system,
				selectedPage
			} = this.props;

			const {
				isGrantAccessModalOpened,
				seletedId,
				isAccountAccessModalOpen,
				selectedAccount,
				isActionsModalOpen,
				selectedUser,
				isCancelActionModalOpen,
			} = this.state;

			const params = {
				service: system
					? getRegistrationsFiltersService
					: getFiltersCompanyUser,
				createFilterData: this.createFilterData,
			};

			const isDataEmpty =
				registrations &&
				registrations.resultList &&
				registrations.resultList.length === 0;

			return (
				<div data-spec="registrations">
					<ActionBar
						menu={userSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						clearFilters={this.clearFilters}
						openFilterModal={this.openFilterModal}
						onChange={this.onChange}
						showPagination
						totalCount={registrations && registrations.totalCount}
						selectedPage={selectedPage}
						dataLimit={dataLimit}
						isDataEmpty={isDataEmpty}
						isDisabled={getRegistrationsRequest}
					/>

					{getRegistrationsRequest && <Loading data-spec="loading" />}
					{getRegistrationsFail && null}

					{!getRegistrationsRequest && registrations && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}

					{registrations &&
						registrations.resultList &&
						registrations.resultList.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.PENDING_INVITATION_AVAILABLE"
										defaultMessage="No Pending Invitation Available"
									/>
								}
							/>
						)}

					<FilterModal
						type="radio"
						params={params}
						messages={messages}
						onApply={this.onApply}
					/>

					{isGrantAccessModalOpened && (
						<GrantAccessModal
							data={seletedId}
							show
							onClose={this.closeGrantAccessModal}
						/>
					)}

					{isAccountAccessModalOpen && (
						<AccountAccessListModal
							show
							onClose={this.closeAccountAccessModal}
							data={selectedAccount}
						/>
					)}

					{isActionsModalOpen && (
						<ResendInvitationModal
							show
							onClose={this.closeActionsModal}
							data={selectedUser}
							resendRegistration={patchResendRegistration}
							system={system}
						/>
					)}

					{isCancelActionModalOpen && (
						<CancelInvitationModal
							show
							onClose={this.closeCancelActionsModal}
							data={selectedUser}
							cancelRegistration={patchCancelRegistration}
							system={system}
						/>
					)}
				</div>
			);
		}
	}
	const { func, objectOf, string, shape, bool, number } = PropTypes;

	PendingInvitationsComponent.propTypes = {
		openFilterModal: func,
		messages: objectOf(string),
		getRegistrations: func,
		registrations: shape(),
		getRegistrationsRequest: bool,
		getRegistrationsFail: bool,
		patchCancelRegistration: func,
		cancelRegistration: object,
		resendInvitation: object,
		patchResendRegistration: func,
		simpleSearchStart: func,
		simpleSearchClose: func,
		dataLimit: number,
		grantAccess: bool,
		system: bool,
		filterData: shape(),
		user: object,
		searchParameter: object,
		dataOffset: number,
		setDataOffset: func,
    setSelectedPage: func,
		setPaginationInit: func,
		selectedPage: number,
	};

	return PendingInvitationsComponent;
};

export default PendingInvitations;
