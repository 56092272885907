import { combineReducers } from 'redux';

import globalReducer from '../../../redux/global/globalReducer';
import {
	CANCEL_REGISTRATION,
	CANCEL_SCHEDULE_STATUS,
	CHANGE_NETWORK_ENTITLEMENT,
	GET_BLOCKED_SUBSCRIPTION_DCP,
	GET_CCIP_ENDPOINTS_DATA,
	GET_DATA_HISTORY,
	GET_DCP_ENDPOINTS_DATA,
	GET_DIAGNOSTIC_HLR,
	GET_DIAGNOSTICS_DETAILS,
	GET_ENDPOINT_REGISTRATION_HISTORY,
	GET_GDSP_ENDPOINTS_DATA,
	GET_HISTORY_CHANGE,
	GET_MESSAGE_HISTORY,
	SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT,
	GET_NETWORK_ENTITLEMENT_APN,
	GET_NOTE,
	SINGLE_ENDPOINT_GET_RATE_PLANS,
	GET_SINGLE_ENDPOINT,
	GET_TROUBLESHOOT_DCP,
	SEND_DIAGNOSTIC_SMS_MESSAGE,
	SEND_SMS_MESSAGE,
	UPDATE_CUSTOM_FIELDS_VALUES,
	UPDATE_M2M_ACCOUNT,
	UPDATE_NOTE,
	UPDATE_RATE_PLAN,
	UPDATE_SINGLE_ENDPOINT,
	GET_SESSION_HISTORY,
	EXPORT_SESSIONS_DATA_CSV,
	GET_ENDPOINT_USAGE_DATA,
	GET_REGISTRATION_DETAILS,
	GET_SINGLE_ENDPOINT_M2M_ACCOUNTS,
	EXPORT_USAGE_DATA,
} from './constants';

export default combineReducers({
	cancelRegistration: globalReducer(CANCEL_REGISTRATION),
	cancelScheduleStatus: globalReducer(CANCEL_SCHEDULE_STATUS),
	changeNetworkEntitlement: globalReducer(CHANGE_NETWORK_ENTITLEMENT),
	endpointRegistrationHistory: globalReducer(GET_ENDPOINT_REGISTRATION_HISTORY),
	getBlockedSubscriptionDcp: globalReducer(GET_BLOCKED_SUBSCRIPTION_DCP),
	getTroubleShootDcp: globalReducer(GET_TROUBLESHOOT_DCP),
	getRegistrationDetails: globalReducer(GET_REGISTRATION_DETAILS),
	getDiagnosticsDetails: globalReducer(GET_DIAGNOSTICS_DETAILS),
	getDiagnosticHlr: globalReducer(GET_DIAGNOSTIC_HLR),
	getGdspEndpointsData: globalReducer(GET_GDSP_ENDPOINTS_DATA),
	getDcpEndpointsData: globalReducer(GET_DCP_ENDPOINTS_DATA),
	getCcipEndpointsData: globalReducer(GET_CCIP_ENDPOINTS_DATA),
	getDataHistory: globalReducer(GET_DATA_HISTORY),
	getMessageHistory: globalReducer(GET_MESSAGE_HISTORY),
	getNetworkEntitlement: globalReducer(SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT),
	getNetworkEntitlementApn: globalReducer(GET_NETWORK_ENTITLEMENT_APN),
	getNote: globalReducer(GET_NOTE),
	getHistoryChange: globalReducer(GET_HISTORY_CHANGE),
	sendDiagnosticSMSMessage: globalReducer(SEND_DIAGNOSTIC_SMS_MESSAGE),
	sendSMSMessage: globalReducer(SEND_SMS_MESSAGE),
	updateCustomFieldsValues: globalReducer(UPDATE_CUSTOM_FIELDS_VALUES),
	updateM2MAccount: globalReducer(UPDATE_M2M_ACCOUNT),
	updateNote: globalReducer(UPDATE_NOTE),
	updateRatePlan: globalReducer(UPDATE_RATE_PLAN),
	getSingleEndpoint: globalReducer(GET_SINGLE_ENDPOINT),
	updateSingleEndpoint: globalReducer(UPDATE_SINGLE_ENDPOINT),
	getRatePlans: globalReducer(SINGLE_ENDPOINT_GET_RATE_PLANS),
	getSessionHistory: globalReducer(GET_SESSION_HISTORY),
	exportSessions: globalReducer(EXPORT_SESSIONS_DATA_CSV),
	getEndpointUsageData: globalReducer(GET_ENDPOINT_USAGE_DATA),
	getM2MAccount: globalReducer(GET_SINGLE_ENDPOINT_M2M_ACCOUNTS),
	exportUsageData: globalReducer(EXPORT_USAGE_DATA),
});
