import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import DesktopView from './Desktop';
import MobileView from './Mobile';
import {
	getReleaseNotes,
	getReleaseNotesInit
} from '../../redux/actions';
import {
	getReleaseNotesListRequest,
	getReleaseNotesListSuccess,
	getReleaseNotesRequest,
  getReleaseNotesSuccess,
  publishReleaseNotesSuccess,
  publishReleaseNotesRequest
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	releaseNotesListRequest: getReleaseNotesListRequest(state),
	releaseNotesList: getReleaseNotesListSuccess(state),
	releaseNotesRequest: getReleaseNotesRequest(state),
  releaseNotes: getReleaseNotesSuccess(state),
  publishReleaseNotes: publishReleaseNotesSuccess(state),
  publishReleaseNotesRequest: publishReleaseNotesRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	goToEditReleaseNotes: (url) => dispatch(localizedRoute(url)),
	getReleaseNotes: (params) => dispatch(getReleaseNotes(params)),
	getReleaseNotesInit: () => dispatch(getReleaseNotesInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopView);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileView);
