import React from 'react';
import { string, objectOf } from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import { DesktopUsage, MobileUsage } from '../../components/Usage';

function UsageView({ messages, imsi, m2mAccountId, platform }) {
	return (
		<>
			<DesktopMediaQuery>
				<DesktopUsage
					messages={messages}
					dataLimit={50}
					imsi={imsi}
					m2mAccountId={m2mAccountId}
					platform={platform}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<DesktopUsage
					messages={messages}
					dataLimit={50}
					imsi={imsi}
					m2mAccountId={m2mAccountId}
					platform={platform}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<MobileUsage
					messages={messages}
					dataLimit={20}
					imsi={imsi}
					m2mAccountId={m2mAccountId}
					platform={platform}
				/>
			</MobileMediaQuery>
		</>
	);
}

UsageView.propTypes = {
	messages: objectOf(string),
	imsi: string,
	m2mAccountId: string,
	platform: string,
};

UsageView.defaultProps = {
	messages: {},
	imsi: '',
	m2mAccountId: '',
	platform: '',
};

export default UsageView;
