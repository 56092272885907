import { Status } from '../../../../utils/constants';

/* ******************** ACTIVATE COMPANY ******************** */
export const activateCompanyRequest = (state) =>
	state.onboarding.companies.getCompany.status === Status.PENDING;
export const activateCompanyFail = (state) =>
	state.onboarding.companies.getCompany.status === Status.FAIL;
export const activateCompanySuccess = (state) =>
	state.onboarding.companies.getCompany.data;

/* ******************** ADD COMPANY ******************** */
export const addCompanyRequest = (state) =>
	state.onboarding.companies.addCompany.status === Status.PENDING;
export const addCompanyFail = (state) =>
	state.onboarding.companies.addCompany.status === Status.FAIL;
export const addCompanySuccess = (state) =>
	state.onboarding.companies.addCompany.data;

/* ******************** GET COUNTRIES ******************** */
export const getCountriesRequest = (state) =>
	state.onboarding.companies.getCountries.status === Status.PENDING;
export const getCountriesFail = (state) =>
	state.onboarding.companies.getCountries.status === Status.FAIL;
export const getCountriesSuccess = (state) =>
	state.onboarding.companies.getCountries.data;

/* ******************** GET COMPANIES ******************** */
export const getCompaniesRequest = (state) =>
	state.onboarding.companies.getCompanies.status === Status.PENDING;
export const getCompaniesFail = (state) =>
	state.onboarding.companies.getCompanies.status === Status.FAIL;
export const getCompaniesSuccess = (state) =>
	state.onboarding.companies.getCompanies.data;

/* ******************** GET COMPANY ******************** */
export const getCompanyRequest = (state) =>
	state.onboarding.companies.getCompany.status === Status.PENDING;
export const getCompanyFail = (state) =>
	state.onboarding.companies.getCompany.status === Status.FAIL;
export const getCompanySuccess = (state) =>
	state.onboarding.companies.getCompany.data;

/* ******************** GET STATES ******************** */
export const getStatesRequest = (state) =>
	state.onboarding.companies.getStates.status === Status.PENDING;
export const getStatesFail = (state) =>
	state.onboarding.companies.getStates.status === Status.FAIL;
export const getStatesSuccess = (state) =>
	state.onboarding.companies.getStates.data;

/* ******************** GET ADDRESS TYPES ******************** */
export const getAddressTypesRequest = (state) =>
	state.onboarding.companies.getAddressTypes.status === Status.PENDING;
export const getAddressTypesFail = (state) =>
	state.onboarding.companies.getAddressTypes.status === Status.FAIL;
export const getAddressTypesSuccess = (state) =>
	state.onboarding.companies.getAddressTypes.data;

/* ******************** GET CONTACT TYPES ******************** */
export const getContactTypesRequest = (state) =>
	state.onboarding.companies.getContactTypes.status === Status.PENDING;
export const getContactTypesFail = (state) =>
	state.onboarding.companies.getContactTypes.status === Status.FAIL;
export const getContactTypesSuccess = (state) =>
	state.onboarding.companies.getContactTypes.data;

/* ******************** GET MY COMPANY ******************** */
export const getMyCompanyRequest = (state) =>
	state.onboarding.companies.getMyCompany.status === Status.PENDING;
export const getMyCompanyFail = (state) =>
	state.onboarding.companies.getMyCompany.status === Status.FAIL;
export const getMyCompanySuccess = (state) =>
	state.onboarding.companies.getMyCompany.data;

/* ******************** EDIT MY COMPANY ******************** */
export const editMyCompanyRequest = (state) =>
	state.onboarding.companies.editMyCompany.status === Status.PENDING;
export const editMyCompanyFail = (state) =>
	state.onboarding.companies.editMyCompany.status === Status.FAIL;
export const editMyCompanySuccess = (state) =>
	state.onboarding.companies.editMyCompany.status === Status.DONE;

/* ******************** GET COMPANY LANGUAGES ******************** */
export const getCompanyLanguagesRequest = (state) =>
	state.onboarding.companies.getCompanyLanguages.status === Status.PENDING;
export const getCompanyLanguagesFail = (state) =>
	state.onboarding.companies.getCompanyLanguages.status === Status.FAIL;
export const getCompanyLanguagesSuccess = (state) =>
	state.onboarding.companies.getCompanyLanguages.data;

/* ******************** GET COMPANY TOKEN ******************** */

export const getCompanyTokenRequest = (state) =>
	state.onboarding.companies.getCompanyToken.status === Status.PENDING;
export const getCompanyTokenFail = (state) =>
	state.onboarding.companies.getCompanyToken.status === Status.FAIL;
export const getCompanyTokenSuccess = (state) =>
	state.onboarding.companies.getCompanyToken.data;

/* ******************** GET COMPANY INIT TAB ******************** */
export const getCompanyInitTab = (state) =>
	state.onboarding.companies.changeCompanyInitalTab.initTab;

export const editCompanyRequest = (state) =>
	state.onboarding.companies.editCompany.status === Status.PENDING;
export const editCompanyFail = (state) =>
	state.onboarding.companies.editCompany.status === Status.FAIL;
export const editCompanySuccess = (state) =>
	state.onboarding.companies.editCompany.status === Status.DONE;

/* ******************** VALIDATE COMPANY ADDRESS ******************** */
export const validateAddressRequest = (state) =>
	state.onboarding.companies.validateAddress.status === Status.PENDING;
export const validateAddressFail = (state) =>
	state.onboarding.companies.validateAddress.status === Status.FAIL;
export const validateAddressSuccess = (state) =>
	state.onboarding.companies.validateAddress.data;
