import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../Ticketing';

const Mobile = (props) => {
	const { tickets, getOptions } = props;
	return (
		<div data-spec="mobile">
			<Table
				data-spec="ticketing-table-mobile"
				data={tickets.resultList}
				options={getOptions('mobile')}
				mobile
			/>
		</div>
	);
};

const { shape, array, func } = PropTypes;

Mobile.propTypes = {
	tickets: shape({
		resultList: array
	}),
	getOptions: func
};

export default hoc()(Mobile);
