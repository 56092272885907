export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Meerdere eindpuntacties',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Selecteer twee of meer eindpunten',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': 'Pas een of meer filters toe om de exportfunctie in te schakelen',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': 'Momenteel toegewezen aan het geselecteerde eindpunt',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': 'Geschikt voor gebruik met geselecteerde eindpunten',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': 'Kan geen wijziging aanvragen voor meerdere SIM-netwerkrechten',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': 'Netwerkrecht SIM geüpdatet',
	'ENDPOINTS.VIEW_NOTE': 'Opmerking weergeven',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'Geen eindpunten beschikbaar',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': 'Er zijn momenteel geen eindpunten voor deze tabel om te laten zien',
	//  EDIT TABLE MODAL
	'ENDPOINTS.TABLE_NAME': 'Tabelnaam',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Naam tabelweergave bewerken',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Voer de tabelnaam in',
	'ENDPOINTS.EDIT_TABLE': 'Tabelparameters bewerken',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Labels aangepast veld bewerken',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Tabelparameters bewerken',
	'ENDPOINTS.APPLY_CHANGE': 'Wijziging toepassen',
	'ENDPOINTS.DELETE_TAB': 'Tabblad verwijderen',
	'ENDPOINTS.CUSTOM_LABEL': 'Aangepaste label {index}',
	'ENDPOINTS.CLEAR_ALL': 'Standaardinstellingen herstellen',
	'ENDPOINTS.DEVICE_TYPE': 'Type apparaat',
	'ENDPOINTS.BULK_UPLOAD': 'Massa upload',

	//  EDIT TABLE PARAMETERS
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': 'Selecteer minimaal twee om op de tabel toe te passen. ',
	'ENDPOINTS.CELLULAR_COLUMNS': 'Mobielspecifieke parameters',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Satellietspecifieke parameters',

	//  CELLULAR SPECIFIC PARAMETERS
	'ENDPOINTS.M2MACCOUNTID': 'Id M2M-account',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': 'Status',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': 'Netwerkoperator',
	'ENDPOINTS.NETWORK_TYPE': 'Type netwerk',
	'ENDPOINTS.IMSI_TRANSFER': 'Status IMSI-overdracht',
	'ENDPOINTS.RATE_PLAN': 'Tariefplan',
	'ENDPOINTS.RECREDENTIAL_STATUS': 'Status hertoewijzing referenties',
	'ENDPOINTS.ICCID': 'ICCID',
	'ENDPOINTS.SERVICE_PROVIDER': 'M2M-platform',
	'ENDPOINTS.MSISDN': 'MSISDN',
	'ENDPOINTS.COUNTRY_OPERATOR': 'Land netwerk',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'Netwerkrecht-id',
	'ENDPOINTS.OEM': 'Subaccounts',
	'ENDPOINTS.SERVICE_STATUS': 'Status 360L',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': 'Roamingland',
	'ENDPOINTS.ROAMING_CARRIER': 'Roamingprovider',
	'ENDPOINTS.M2M_DATE_ADDED': 'Datum M2M toegevoegd',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'Datum activering M2M',
	'ENDPOINTS.NOTES': 'Opmerkingen',
	'ENDPOINTS.CTD_DATA_USAGE': 'Dataverbruik CTD',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'Sms-verbruik CTD',
	'ENDPOINTS.CTD_VOICE_USAGE': 'Voice-verbruik CTD',
	'ENDPOINTS.INSESSION': 'In sessie',

	//  SATELLITE SPECIFIC PARAMETERS
	'ENDPOINTS.M2M_ACC_ID': 'Id M2M-account',
	'ENDPOINTS.DEVICE_NAME': 'Naam apparaat',
	'ENDPOINTS.DEVICE_ID': 'Device ID',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': 'Modelnr. apparaat',

	//  RATE PLAN SCHEDULE MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': 'Er is een fout op het onderliggende platform en Wijziging tariefplan is momenteel niet beschikbaar.',
	'ENDPOINTS.RATE_PLAN_CHANGE': 'Wijziging tariefplan',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'U hebt ervoor gekozen om Tariefplan te wijzigen in ',
	'ENDPOINTS.TAP_INITIATE_CHANGE': 'Tik op ’Wijziging initiëren’ om onmiddellijk actie te nemen.',
	'ENDPOINTS.CHANGE_LATER': 'U kunt de wijziging ook voor later plannen.',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': 'De geplande wijziging wordt uitgevoerd aan het begin van de geselecteerde datum.',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'Wijziging plannen',
	'ENDPOINTS.INITIATE_CHANGE': 'Wijziging initiëren',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Wijziging tariefplan is gepland',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Wijziging tariefplan is gepland voor {scheduleDate}',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Kan wijziging tariefplan niet plannen!',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Wijziging tariefplan plannen',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. Systeembevestiging',
	'ENDPOINTS.NOTIFICATION_ERROR': 'Kan eindpunt-id {endpointICCID} niet inrichten na wijziging tariefplan. Vraag ondersteuning',
	'ENDPOINTS.SCHEDULE_DATE': 'Datum',
	'ENDPOINTS.SIM_STATE_CHANGE': 'Wijziging SIM-status',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': 'U hebt ervoor gekozen om de SIM-status te wijzigen in',
	'ENDPOINTS.STATUS_CHANGE_ERROR': 'Gebruiker {user} heeft een statuswijziging aangebracht op {changeDate}',
	'ENDPOINTS.SCHEDULING': 'Wordt gepland ...',

	//  SIM STATUS
	'ENDPOINTS.ACTIVE': 'Geactiveerd',
	'ENDPOINTS.TERMINATE': 'Beëindigd',
	'ENDPOINTS.PROVISIONED': 'Ingericht',
	'ENDPOINTS.DEACTIVE': 'Niet-actief',
	'ENDPOINTS.INACTIVE': 'Inactief',
	'ENDPOINTS.TEST': 'Test',
	'ENDPOINTS.SUSPENDED': 'Opgeschort',
	'ENDPOINTS.SUSPEND': 'Opschorten',
	'ENDPOINTS.INVENTORY': 'Voorraad',

	//  SIM STATE SCHEDULE CHANGE
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. Wijziging SIM-status plannen',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'Wijziging SIM-status plannen',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Wijziging SIM-status is gepland!',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Wijziging SIM-status is gepland voor {scheduledDate}',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Kan SIM-statuswijziging niet plannen!',
	'ENDPOINTS.PLEASE_TRY_AGAIN': 'Probeer het opnieuw.',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': 'Kan eindpunt-id {endpointICCID} niet inrichten na IMSI-statuswijziging. Vraag ondersteuning',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE': 'Gebruiker {user} heeft een statuswijziging aangebracht op {changeDate}',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS': 'Gebruiker {user} heeft een statuswijziging aangebracht op {date}',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': 'Kan statuswijziging van meerdere SIM’s niet plannen!',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': 'Kan statuswijziging van meerdere SIM’s niet aanvragen!',

	//  GENERATE REPORT MODAL
	'ENDPOINTS.CREATE_REPORT': 'Rapport maken',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Aangepast rapport maken',
	'ENDPOINTS.ENDPOINTS_ARE': 'eindpunten zijn geselecteerd voor export.',
	'ENDPOINTS.ENDPOINT_IS': 'eindpunt is geselecteerd voor export.',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': 'Dit aangepaste rapport is beschikbaar in het rapportgedeelte.',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': 'U kunt hier een aangepaste naam aan het rapport toevoegen',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Appendix naam aangepast rapport',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': 'Voeg een appendix toe aan naam aangepast rapport (alleen nummers en letters toegestaan)',

	//  MULTI-ACTION MODAL
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. SIM-status selecteren',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Wijzigingen bevestigen',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. Systeembevestiging',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Tariefplan selecteren',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1. Netwerkrecht selecteren',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Opmerkingen wijzigen',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2. Systeembevestiging',
	'ENDPOINTS.CHANGE_SIM_STATE': 'Wijzig SIM-status',
	'ENDPOINTS.CHANGE_RATE_PLAN': 'Wijzig tariefplan',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Wijzig netwerkrecht',
	'ENDPOINTS.CHANGE_NOTES': 'Opmerkingen',

	//  MULTI-ACTION NOTES MODAL
	'ENDPOINTS.SAVE_NOTE': 'Opmerking opslaan',
	'ENDPOINTS.ADD_NOTE_BELOW': 'Voeg uw opmerking hieronder toe: ',
	'ENDPOINTS.ENTER_NOTE_HERE': 'Noteer hier uw opmerking (max. 2000 tekens)',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Tekenlimiet overschreden',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'Mag niet alleen spaties bevatten',
	'ENDPOINTS.CHANGING_NOTES': 'Berichtswijzigingen',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': 'Eindpunten zijn gewijzigd',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': 'Kan wijzigen van berichten voor meerdere eindpunten niet initiëren',
	'ENDPOINTS.SUCCESS_KEY': 'Success',
	'ENDPOINTS.ERROR': 'Fout',
	'ENDPOINTS.REASON': 'reden',
	'ENDPOINTS.ENDPOINTS_SELECTED': 'eindpunten geselecteerd',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'Berichtswijzigingen zijn niet toegestaan',

	//  CHANGE RATE PLAN
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Selecteer een aantal SIM’s.',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'Tariefplan mag niet worden gewijzigd',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'Geen tariefplannen beschikbaar',
	'ENDPOINTS.SIMS_HAVE': 'SIM’s hebben',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': 'Selecteer een ander tariefplan om de wijziging op alle geselecteerde SIM’s toe te passen',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': ' geen tariefplan geselecteerd',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Wijziging tariefplan bevestigen',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': 'U hebt ervoor gekozen om het tariefplan te wijzigen van de',
	'ENDPOINTS.SELECTED_SIMS_TO': 'geselecteerde SIM’s in',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': 'Wilt u de wijziging nu initiëren?',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': 'Of u kunt de wijziging plannen voor: ',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': 'Tariefplan voor meerdere SIM’s gepland',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'Wijziging tariefplan SIM voor',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': 'SIM is gepland voor',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': 'Tariefplannen SIM geüpdatet',
	'ENDPOINTS.SIMS_SELECTED': 'Geselecteerde SIM’s',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': 'Kan wijziging van tariefplan voor meerdere SIM’s niet plannen',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': 'Kan wijziging van tariefplan voor meerdere SIM’s niet aanvragen',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Hetzelfde tariefplan geselecteerd',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': 'Eindpunt-id {endpointID} is ingericht. Tariefplan is gewijzigd in {ratePlan}',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': 'Kan eindpunt-id {endpointID} niet inrichten na wijziging tariefplan. Vraag ondersteuning',

	//  CHANGE SIM STATE
	'ENDPOINTS.CURRENT': 'HUIDIG',
	'ENDPOINTS.NETWORK_TYPE_CHECK': 'Statuswijzigingen van meerdere eindpunten van verschillende netwerktypen of verschillende serviceproviders zijn helaas niet toegestaan.',
	'ENDPOINTS.SELECT_SIM_STATE': 'SIM-status selecteren',
	'ENDPOINTS.CURRENT_STATES': 'Huidige status',
	'ENDPOINTS.SELECTED_TIP': 'Tip: Als u SIM’s in verschillende statussen hebt geselecteerd, kunt u ze alleen naar een status wijzigen die voor alle geselecteerde SIM’s beschikbaar is.',
	'ENDPOINTS.VIEW_RULES': 'Regels voor statuswijziging voor deze account bekijken',
	'ENDPOINTS.STATES_MESSAGE': 'Geen status beschikbaar.',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'Wijziging SIM-status bevestigen',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': 'Nadat u deze wijziging initieert, worden de geselecteerde SIM’s',
	'ENDPOINTS.PROCEED_ACTION': 'Wilt u doorgaan met deze actie?',
	'ENDPOINTS.CAN_SCHEDULE': 'Of u kunt de wijziging plannen voor ',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': 'Eindpunt-id {endpointID} is ingericht. IMSI-status is gewijzigd in {status}',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': 'Kan eindpunt-id {endpointID} niet inrichten na IMSI-statuswijziging. Vraag ondersteuning',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': 'Gebruiker {user} heeft een statuswijziging aangebracht op {date}',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL': 'Er zijn meerdere SIM-statuswijzigingen gepland!',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'SIM-statuswijziging voor',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': 'SIM-statuswijziging is aangevraagd!',

	//  GENERAT REPORT
	'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Aangepast rapport is gemaakt',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': 'Er is een fout opgetreden tijdens het downloaden van uw bestand',
	'ENDPOINTS.GO_TO_REPORTS': 'Naar rapporten',

	//  UPDATE CUSTOM LABELS
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': 'Een of meer labels van aangepaste velden zijn geüpdatet.',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': 'Een of meer labels van aangepaste velden kunnen op dit moment niet worden geüpdatet. Probeer het later opnieuw.',

	//  Activeringsplatform
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': 'DCP',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

	TECH_TYPE: 'Technisch type',
	TECH_TYPE_SUB_TYPE: 'Tech Type subcategorie',
	'ENDPOINTS.TECH_TYPE': 'Technisch type',
	'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Tech Type subcategorie',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Technisch type',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Tech Type subcategorie'
};
