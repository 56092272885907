import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import { GET_TICKETING_FILTERS } from '../constants';
import { getFilters as getFiltersService } from '../../services/TicketingService';

export const getTicketingFilters = () =>
	globalAction(GET_TICKETING_FILTERS, getFiltersService);

export const getTicketingFiltersInit = () => actionInit(GET_TICKETING_FILTERS);
