import { Status } from '../../../../utils/constants';

/* ******************** GET ANALYTICS REPORTS ******************** */
export const getAnalyticsReportsRequest = (state) =>
	state.reports.getAnalyticsReports.status === Status.PENDING;
export const getAnalyticsReportsFail = (state) =>
	state.reports.getAnalyticsReports.status === Status.FAIL;
export const getAnalyticsReportsSuccess = (state) =>
	state.reports.getAnalyticsReports.data;

/* ******************** GET CUSTOMER REPORTS ******************** */
export const getCustomerReportsRequest = (state) =>
	state.reports.getCustomerReports.status === Status.PENDING;
export const getCustomerReportsFail = (state) =>
	state.reports.getCustomerReports.status === Status.FAIL;
export const getCustomerReportsSuccess = (state) =>
	state.reports.getCustomerReports.data;

/* ******************** GET REPORTS FILTERS ******************** */
export const getReportsFiltersRequest = (state) =>
	state.reports.getReportsFilters.status === Status.PENDING;
export const getReportsFiltersFail = (state) =>
	state.reports.getReportsFilters.status === Status.FAIL;
export const getReportsFiltersSuccess = (state) =>
	state.reports.getReportsFilters.data;

/* ******************** REPOST REPORT ******************** */
export const sfgRepostRequest = (state) =>
	state.reports.sfgRepost.status === Status.PENDING;
export const sfgRepostFail = (state) =>
	state.reports.sfgRepost.status === Status.FAIL;
export const repostSuccess = (state) =>
	state.reports.sfgRepost.status === Status.DONE;
export const repostInit = (state) =>
	state.reports.sfgRepost.status === Status.INIT;
