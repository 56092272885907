import { handleActions } from 'redux-actions';
import {
	SET_PAGINATION_INIT,
	SET_SELECTED_PAGE_SUCCESS
} from '../constants';

const initialState = {
	data: 0
};

export default handleActions(
	{
		[SET_PAGINATION_INIT]: (state) => ({
			...state,
			data: 0
		}),
		[SET_SELECTED_PAGE_SUCCESS]: (state, action) => ({
			...state,
			data: action.payload
		})
	},
	initialState
);
