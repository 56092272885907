import { createAction } from 'redux-actions';
import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';

import {
	ADD_NETWORK_ENTITLEMENT,
	ADD_NETWORK_ENTITLEMENTS_MODAL,
	EDIT_NETWORK_ENTITLEMENT,
	GET_NETWORK_ENTITLEMENT,
	GET_NETWORK_ENTITLEMENTS,
	GET_PARENT_NETWORK_ENTITLEMENTS,
	ADD_SUB_ACCOUNT_NETWORK_ENTITLEMENTS,
	SHOW_ASSOCIATED_APNS
} from './constants';

import {
	addNetworkEntitlement as addNetworkEntitlementService,
	editNetworkEntitlement as editNetworkEntitlementService,
	getNetworkEntitlement as getNetworkEntitlementService,
	getNetworkEntitlements as getNetworkEntitlementsService,
	showAssociatedApns as showAssociatedApnsService
} from '../../services/NetworkEntitlementsService';

import { editSubAccount as editSubAccountService } from '../../services/AccountsService';

/* ******************** ADD NETWORK ENTITLEMENT ******************** */
export const addNetworkEntitlement = (params) => {
	const {
		companyId,
		platformType,
		m2mAccountId,
		accountId
	} = params.additionalData;
	return globalAction(
		ADD_NETWORK_ENTITLEMENT,
		addNetworkEntitlementService,
		{ data: params.networkEntitlement, m2mAccountId, companyId },
		{
			notify: 'add-network-entitlement',
			init: true,
			redirect: `/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/network-entitlements`
		}
	);
};

export const addNetworkEntitlementInit = () =>
	actionInit(ADD_NETWORK_ENTITLEMENT);

/* ******************** ADD NETWORK ENTITLEMENT MODAL ******************** */
export const openAddNetworkEntitlementsModal = createAction(
	`OPEN_${ADD_NETWORK_ENTITLEMENTS_MODAL}`
);
export const closeAddNetworkEntitlementsModal = createAction(
	`CLOSE_${ADD_NETWORK_ENTITLEMENTS_MODAL}`
);

/* ******************** EDIT NETWORK ENTITLEMENT ******************** */
export const editNetworkEntitlement = (params) => {
	const { companyId, m2mAccountId } = params.additionalData;
	return globalAction(
		EDIT_NETWORK_ENTITLEMENT,
		editNetworkEntitlementService,
		{
			companyId,
			m2mAccountId,
			id: params.id,
			data: params.networkEntitlement
		},
		{
			notify: 'edit-network-entitlement',
			init: true
		}
	);
};

export const editNetworkEntitlementInit = () =>
	actionInit(EDIT_NETWORK_ENTITLEMENT);

/* ******************** GET NETWORK ENTITLEMENT ******************** */
export const getNetworkEntitlement = (params) =>
	globalAction(GET_NETWORK_ENTITLEMENT, getNetworkEntitlementService, params, {
		notifyFail: 'get-network-entitlement'
	});

export const getNetworkEntitlementInit = () =>
	actionInit(GET_NETWORK_ENTITLEMENT);

/* ******************** GET NETWORK ENTITLEMENTS ******************** */
export const getNetworkEntitlements = (params) =>
	globalAction(
		GET_NETWORK_ENTITLEMENTS,
		getNetworkEntitlementsService,
		params,
		{
			notifyFail: 'get-network-entitlements'
		}
	);

/* ******************** GET PARENT NETWORK ENTITLEMENTS ******************** */
export const getParentNetworkEntitlements = (params) =>
	globalAction(
		GET_PARENT_NETWORK_ENTITLEMENTS,
		getNetworkEntitlementsService,
		params
	);

export const getParentNetworkEntitlementsInit = () =>
	actionInit(GET_PARENT_NETWORK_ENTITLEMENTS);

/* ******************** ADD SUB ACCOUNT NETWORK ENTITLEMENTS ******************** */
export const addSubAccountNetworkEntitlements = (params) =>
	globalAction(
		ADD_SUB_ACCOUNT_NETWORK_ENTITLEMENTS,
		editSubAccountService,
		params,
		{
			modals: closeAddNetworkEntitlementsModal(),
			notify: 'add-network-entitlement'
		}
	);

/* ******************** SHOW ASSOCIATED APNS ******************** */
export const showAssociatedApns = (params) =>
	globalAction(SHOW_ASSOCIATED_APNS, showAssociatedApnsService, params);

export const showAssociatedApnsInit = () => actionInit(SHOW_ASSOCIATED_APNS);
