import { connect } from 'react-redux';
import { getFormValues, isDirty, isInvalid } from 'redux-form';
import SecurityFeaturesModal from './SecurityFeaturesModal';

import {
	addSecurityFeatures,
	editSecurityFeatures
} from '../../redux/securityFeatures/actions';

const mapStateToProps = (state) => ({
	formValues: getFormValues('FormSecurity')(state),
	isDirty: isDirty('FormSecurity')(state),
	isInvalid: isInvalid('FormSecurity')(state)
});

const mapDispatchToProps = (dispatch) => ({
	addItem: (data) => dispatch(addSecurityFeatures(data)),
	updateItem: (data) => dispatch(editSecurityFeatures(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SecurityFeaturesModal);
