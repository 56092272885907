import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import FeedbackWindow from '../FeedbackWindow';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './UserFeedbackWrapper.scss';

const Trigger = ImageSelector(CurrentContext.theme, 'svgs/user-feedback.svg');

class UserFeedbackWrapper extends Component {
	handleClose = debounce((e) => {
		const ESC = 27;

		if (e.keyCode === ESC) {
			this.setState({
				opened: false
			});
		}
	}, 100);

	constructor(props) {
		super(props);

		this.state = {
			opened: false
		};
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleClose);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleClose);
	}

	handleClick = () => {
		const { opened } = this.state;

		this.setState({
			opened: !opened
		});
	};

	handleUserFeedbackWindowClick = (e) => {
		if (e.target.getAttribute('data-spec') === 'user-feedback-window') {
			this.setState({
				opened: false
			});
		}
	};

	render() {
		const { opened } = this.state;
		const { location, menu } = this.props;
		const triggerClass = cn({
			[styles.feedback_trigger]: true,
			[styles.trigger_active]: opened
		});

		return (
			<div
				data-spec="user-feedback-wrapper"
				onClick={this.handleUserFeedbackWindowClick}
				onKeyDown={undefined}
			>
				<FeedbackWindow
					handleClose={this.handleClose}
					name={location.pathname}
					opened={opened}
					menu={menu}
				/>
				<button
					className={triggerClass}
					type="button"
					onClick={this.handleClick}
				>
					<span className={styles.icon}>
						<Trigger />
					</span>
					<span className={styles.right}>
						<div className={styles.title}>
							<FormattedMessage
								id="USER_FEEDBACK.TRIGGER_TITLE"
								defaultMessage="Got feedback?"
							/>
						</div>
						<div className={styles.subtitle}>
							<FormattedMessage
								id="USER_FEEDBACK.TRIGGER_SUBTITLE"
								defaultMessage="Tell us here"
							/>
						</div>
					</span>
				</button>
			</div>
		);
	}
}

const { objectOf, string, arrayOf, object } = PropTypes;

UserFeedbackWrapper.propTypes = {
	location: objectOf(string),
	menu: arrayOf(object)
};

UserFeedbackWrapper.defaultProps = {
	location: {},
	menu: []
};

export default UserFeedbackWrapper;
