import React from 'react';
import {
	func,
	string,
	object,
	bool,
} from 'prop-types';
import {
	FormattedMessage,
	injectIntl,
} from 'react-intl';

import Input from '../../../../../lib/DigitalComponents/FieldGroup';

import hoc from '../CustomFieldsValues';

import styles from './Desktop.scss';

function Desktop({
	renderAction,
	temp,
	keyName,
	onChange,
	getNumber,
	field,
	customFieldLabels,
	saveFieldValue,
	intl,
}) {
	const onKeyUp = (e) => (
		e.key === 'Enter'
		&& temp[`customField${getNumber(keyName)}`].replace(/\s/g, '').length
			? saveFieldValue(keyName)
			: undefined
	);
	const onChangeEvent = (e) => onChange(e, keyName);

	return (
		<Input
			data-spec="custom-field-values-input-field"
			readOnly={!renderAction}
			requiredLabel={
				field
					? intl.formatMessage({ id: 'REQUIRED', defaultMessage: 'required' })
					: ''
			}
			label={
				<span>
					{customFieldLabels[keyName]}
					<span className={styles.required_message}>
						&nbsp;
						{field ? (
							<FormattedMessage id="REQUIRED" defaultMessage="(required)" />
						) : ''}
					</span>
				</span>
			}
			value={temp[`customField${getNumber(keyName)}`]}
			onChange={onChangeEvent}
			placeholder={intl.formatMessage({
				id: 'CUSTOM_FIELD_VALUE',
				defaultMessage: 'Custom Field Value'
			})}
			maxLength="40"
			onKeyUp={onKeyUp}
			dataSpec={`customField${getNumber(keyName)}`}
		/>
	);
}

Desktop.propTypes = {
	renderAction: bool,
	temp: object,
	keyName: string,
	onChange: func,
	getNumber: func,
	field: bool,
	customFieldLabels: object,
	saveFieldValue: func,
	intl: object,
};
Desktop.defaultProps = {
	renderAction: false,
	temp: {},
	keyName: '',
	onChange: undefined,
	getNumber: undefined,
	field: false,
	customFieldLabels: {},
	saveFieldValue: undefined,
	intl: {},
};

export default hoc(injectIntl(Desktop));
