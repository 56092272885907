export default {
	'SWAGGER.DEVELOPER_API': 'Diseñador API',
	'SWAGGER.GETTING_STARTED': 'Para comenzar.',
	'SWAGGER.API_SANDBOX': 'Entorno de pruebas API',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': 'El entorno de pruebas Swagger de abajo especifica la lista de recursos disponibles en REST API y las operaciones que pueden invocar en dichos recursos. El documento también detalla la lista de parámetros por operación, incluidos el nombre y tipo de parámetro, si los parámetros son obligatorios u opcionales e información sobre los valores aceptables de dichos parámetros.',
	'SWAGGER.JSON_SCHEMA_INCLUDES': 'Se incluye el esquema JSON que describe la estructura del cuerpo de la solicitud que se envía a una operación en un REST API junto con el esquema JSON que describe la estructura de cualquier cuerpo de respuesta de retorno de una operación.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Para comenzar con la API.',
	'SWAGGER.GETTING_STARTED_WITH_API_P': 'Antes de que pueda hacer la primera llamada a la API, la dirección de IP de origen (servidor) debe agregarse a la lista blanca. El IP de origen normalmente se configura durante el proceso de incorporación cuando se crea el acceso a la API. Si recibe algún error, es posible que se relacione al bloqueo de acceso a la pasarela IoT de AT&T.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': 'Asegúrese de seguir los siguientes pasos para comenzar el desarrollo:',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '- Configurar un servidor de acceso público y proporcionarnos la dirección de IP en el proceso de incorporación.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '- Agregue el IP de origen a la lista blanca para acceder a la pasarela IoT de AT&T.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '- El admin. de su empresa debe darle su nombre de usuario y contraseña API que se haya creado al habilitar el servicio API.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '- Obtenga acceso a su servidor de recursos para ejecutar comandos curl o usar aplicaciones de conexión escritas en su lenguaje preferido a continuación.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'Obtener token de acceso OAuth',
	'SWAGGER.GET_OAUTH_TOKEN_P': 'Obtenga el token de acceso OAuth de AT&T IoT Identity usando el nombre de usuario y contraseña suministrado por su admin. mediante su método preferido a continuación. El token es válido por 7 días.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'Conectores API',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': 'Elija entre los ejemplos de abajo cómo quiere conectarse a su API.',
	'SWAGGER.TEST_CONNECTION_T': 'Probar la conexión a la pasarela IoT',
	'SWAGGER.TEST_CONNECTION_P': 'Ejemplo de curl:',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Resultado deseado',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': 'Si recibe el código de respuesta HTTP 200 OK, ya está listo para comenzar el desarrollo.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': 'Solución de problemas de errores de conexión',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': 'Si recibe algún error, primero verifique si su permiso está configurado correctamente en la cabecera HTTP, y que el token de acceso no se haya vencido y deba actualizarse. Compruebe la disponibilidad de la pasarela IoT de AT&T para verificar que el IP de origen esté en la lista blanca. Si se producen resultados inesperados, cree un ticket de solución de problemas en la sección de asistencia.',
};
