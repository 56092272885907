import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import cn from 'classnames';

import Button from '../../../../lib/DigitalComponents/Button';

import styles from './Accounts.scss';

import { AccountsTableHeader as tableHeader } from '../../utils/constants';

import Loading from '../../../../lib/DigitalComponents/Loader';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import ActionBar from '../../../Shared/views/ActionBarView';
import NoData from '../../../Shared/views/NoDataView';
import SubAccountAccordion from '../SubAccountAccordion';

import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';
import ViewApnsModal from '../ViewApnsModal';

const RWComponent = UserAllowedToAccess(['system.companies_rw']);

const Accounts = () => (WrappedComponent) => {
	class AccountsComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				dataOffset: 0,
				dataLimit: props.dataLimit,
				selectedPage: 0,
				orderColumn: {},
				expanded: [],
				isViewApnsModalOpen: false
			};
		}

		componentDidMount() {
			this.getAccounts();
		}

		componentDidUpdate(prevProps) {
			const { simpleSearchParameter } = this.props;

			if (!isEqual(prevProps.simpleSearchParameter, simpleSearchParameter)) {
				this.getAccounts();
			}
		}

		getAccounts = () => {
			const {
				getAccounts,
				companyId,
				simpleSearchParameter,
				isMyCompany
			} = this.props;
			const { orderColumn } = this.state;
			let simpleSearchParams = [];

			if (simpleSearchParameter && simpleSearchParameter.length) {
				simpleSearchParams = [
					{
						operator: 'iLike',
						prop: simpleSearchParameter[0].prop,
						propValue: `${simpleSearchParameter[0].propValue}%`
					}
				];
			}

			const myCompanySearchParams = [
				{ propValue: null, prop: 'parentPlatformId', operator: '=' }
			];

			const onboardingSearchParams = [
				{ propValue: companyId, prop: 'companyId', operator: '=' },
				{ propValue: null, prop: 'parentPlatformId', operator: '=' },
				...simpleSearchParams
			];

			getAccounts({
				isMyCompany,
				searchParams: isMyCompany
					? myCompanySearchParams
					: onboardingSearchParams,
				additionalParams: {
					include: ['SubPlatforms', 'SubPlatforms.SubPlatforms'],
					dataSort: orderColumn.apiString
				}
			});
		};

		handlePageClick = (data) => {
			const { dataLimit } = this.state;
			this.setState(
				{
					dataLimit,
					dataOffset: dataLimit * data.selected,
					selectedPage: data.selected
				},
				() => this.getAccounts()
			);
			window.scrollTo(0, 0);
		};

		totalCount = () => {
			const {
				accounts: { totalCount }
			} = this.props;
			const { dataLimit } = this.state;
			return Math.ceil(totalCount / dataLimit);
		};

		onOrderClick = (data) => {
			const { name: column, apiString } = data;
			const { orderColumn } = this.state;
			this.setState(
				{
					orderColumn: {
						name: column,
						order: orderColumn.name === column ? !orderColumn.order : true,
						apiString
					}
				},
				() => this.getAccounts()
			);
		};

		getOptions = (target) => {
			const {
				user,
				accounts: { totalCount },
				isMyCompany
			} = this.props;
			const { dataLimit, selectedPage, orderColumn } = this.state;

			// let isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			options.header = tableHeader;

			options.tableOptions = {
				showPagination: totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				onPageChange: (data) => this.handlePageClick(data)
			};

			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions,
					orderByData: ['all'],
					excludeFromOrdering: ['apns', 'actions'],
					getOrderData: (data) => this.onOrderClick(data),
					orderColumn
				};
			}

			options.customComponents = {};

			if (isDesktop) {
				options.customComponents = {
					...options.customComponents,
					m2mAccountName: {
						type: 'custom',
						component: (val, index) => this.renderM2mAccountName(val, index)
					},
					platformName: {
						type: 'custom',
						component: (val) => val.platformCategoryName
					},
					apns: {
						type: 'custom',
						component: (val) => this.renderApns(val)
					},
					actions: {
						type: 'custom',
						component: (val, index) =>
							this.renderActionsSelect(val, user, index)
					},
					customExpand: {
						type: 'custom',
						component: (val) => (
							<SubAccountAccordion
								data={val.subPlatforms}
								parentAccountId={val.m2mAccountId}
								user={user}
								isMyCompany={isMyCompany}
								isDisabledDropdown={
									!isUserAllowedToAccess(
										[
											'system.companies_rw',
											'system.companies_ro',
											'mnc.m2maccounts_rw',
											'mnc.m2maccounts_ro'
										],
										user
									)
								}
							/>
						)
					}
				};
			}
			return options;
		};

		Option = (optionProps) => {
			const { data, children } = optionProps;
			return (
				<components.Option {...optionProps} data-spec="option">
					<span
						data-spec={`select_${
							data && data.data && data.data.m2mAccountName
						}`}
					>
						{children}
					</span>
				</components.Option>
			);
		};

		renderActionsSelect = (data, user, index) => {
			const { accounts } = this.props;
			const menuPosition =
				accounts &&
				accounts.resultList &&
				index + 1 > accounts.resultList.length - 3
					? 'fixed'
					: 'absolute';
			return (
				<Select
					noBorder
					placeholder={<FormattedMessage id="SELECT" defaultMessage="Select" />}
					options={this.renderSelect(data)}
					onChange={this.selectOption}
					data-spec="dropdown"
					loading={false}
					components={{ Option: this.Option }}
					isDisabled={
						/* data.platformCode.toUpperCase() === 'GBCM' || */
						!isUserAllowedToAccess(
							[
								'system.companies_rw',
								'system.companies_ro',
								'mnc.m2maccounts_rw',
								'mnc.m2maccounts_ro'
							],
							user
						)
					}
					menuPosition={menuPosition}
				/>
			);
		};

		selectOption = (item) => {
			switch (item.value) {
				case 0:
					this.goToEditAccount(item.data.companyId, item.data.id);
					break;
				case 1:
					this.goToAddSubAccount(
						item.data.companyId,
						item.data.id,
						item.data.platformName.toLowerCase(),
						item.data.platformTypeId
					);
					break;
				// no default
			}
		};

		renderSelect = (val) => {
			const { user } = this.props;
			const options = [
				{
					value: 0,
					label: isUserAllowedToAccess(['system.companies_rw'], user) ? (
						<FormattedMessage id="ONBOARDING.EDIT" defaultMessage="Edit" />
					) : (
						<FormattedMessage id="ONBOARDING.VIEW" defaultMessage="View" />
					),
					data: val
				}
			];

			return options;
		};

		renderM2mAccountName = (val, index) => {
			const getRender = Boolean(val.subPlatforms && val.subPlatforms.length);
			const triggerIcon = cn({
				[styles.trigger__icon]: true,
				[styles.no_subplatforms]: !this.hasSubPlatforms(val),
				[styles.open]: this.findLevel(index)
			});
			return (
				<div data-spec="m2m-acc-name" className={styles.m2m_account_name}>
					{getRender && (
						<span
							onClick={() => this.handleIconClick(index)}
							className={triggerIcon}
						/>
					)}
					<div>{val.m2mAccountName}</div>
				</div>
			);
		};

		renderApns = (data) => (
			<div
				className={styles.clickable}
				onClick={() =>
					this.openViewApnsModal({
						companyId: data.companyId,
						m2mAccountId: data.m2mAccountId
					})}
			>
				<FormattedMessage
					id="ONBOARDING.SHOW_ASSOCIATED_APNS"
					defaultMessage="Show associated APNs"
				/>
			</div>
		);

		findLevel = (id) => {
			const { expanded } = this.state;
			return expanded.includes(id);
		};

		handleIconClick = (id) => {
			const { expanded } = this.state;
			if (expanded.includes(id)) {
				const index = expanded.indexOf(id);
				this.setState({
					expanded: [...expanded.slice(0, index), ...expanded.slice(index + 1)]
				});
			} else {
				this.setState({
					expanded: [...expanded, id]
				});
			}
		};

		hasSubPlatforms = (level) =>
			Boolean(level && level.subPlatforms && level.subPlatforms.length);

		goToEditAccount = (companyId, accountId) => {
			const { goToEditAccount, isMyCompany } = this.props;
			if (isMyCompany) {
				goToEditAccount(`/my-company/${accountId}`);
			} else {
				goToEditAccount(`/companies/${companyId}/accounts/${accountId}`);
			}
		};

		goToAddAccount = () => {
			const { goToAddAccount, companyId } = this.props;
			goToAddAccount(`/companies/${companyId}/accounts/new-account`);
		};

		goToAddSubAccount = (
			companyId,
			accountId,
			platformType,
			platformTypeId
		) => {
			const { goToAddSubAccount, isMyCompany } = this.props;
			if (isMyCompany) {
				goToAddSubAccount(
					`/my-company/${accountId}/${platformType}/${platformTypeId}/new-sub-account`
				);
			} else {
				goToAddSubAccount(
					`/companies/${companyId}/accounts/${accountId}/${platformType}/${platformTypeId}/new-sub-account`
				);
			}
		};

		openViewApnsModal = (params) => {
			const { showAssociatedApns } = this.props;
			this.setState(
				{
					isViewApnsModalOpen: true
				},
				() => showAssociatedApns(params)
			);
		};

		closeViewApnsModal = () => {
			const { showAssociatedApnsInit } = this.props;
			this.setState(
				{
					isViewApnsModalOpen: false
				},
				() => showAssociatedApnsInit()
			);
		};

		render() {
			const {
				accountsRequest,
				accountsFail,
				accounts,
				isMyCompany,
				showAssociatedApnsSuccess,
				company,
				myCompany,
				showAssociatedApnsRequest
			} = this.props;

			const { isViewApnsModalOpen } = this.state;

			return (
				<div
					data-spec="accounts"
					className={cn(isMyCompany ? styles.accounts_wrapper : '')}
				>
					{!isMyCompany && (
						<ActionBar
							actions={
								<RWComponent>
									<Button
										label={
											<FormattedMessage
												id="ONBOARDING.ADD_ACCOUNT"
												defaultMessage="Add Account"
											/>
										}
										disabled={accountsRequest}
										dataSpec="add-account-button"
										variant="primary"
										onClick={this.goToAddAccount}
									/>
								</RWComponent>
							}
						/>
					)}

					{accountsRequest && <Loading data-spec="loading" />}
					{accountsFail && null}
					{!accountsRequest && accounts && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}
					{accounts &&
						accounts.resultList &&
						accounts.resultList.length === 0 && (
							<NoData
								title={
									<FormattedMessage
										id="ONBOARDING.NO_ACCOUNTS_AVAILABLE"
										defaultMessage="No Accounts Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE"
										defaultMessage="There are No Accounts for this Table to show you right now"
									/>
								}
								table
							/>
						)}
					{isViewApnsModalOpen && (
						<ViewApnsModal
							show
							onClose={this.closeViewApnsModal}
							apns={showAssociatedApnsSuccess}
							apnsRequest={showAssociatedApnsRequest}
							companyName={
								(company && company.companyFriendlyName) ||
								(myCompany &&
									myCompany.resultList &&
									myCompany.resultList.length > 0 &&
									myCompany.resultList[0].companyFriendlyName)
							}
						/>
					)}
				</div>
			);
		}
	}
	const { number, func, object, bool, array, string } = PropTypes;

	AccountsComponent.propTypes = {
		getAccounts: func,
		accountsRequest: bool,
		accountsFail: bool,
		accounts: object,
		companyId: string,
		dataLimit: number,
		simpleSearchStart: func,
		simpleSearchClose: func,
		simpleSearchParameter: array,
		isSimpleSearchActive: bool,
		goToEditAccount: func,
		goToAddAccount: func,
		match: object,
		goToAddSubAccount: func,
		user: object,
		isMyCompany: bool,
		showAssociatedApns: func,
		showAssociatedApnsInit: func,
		showAssociatedApnsSuccess: object,
		company: object,
		myCompany: object,
		showAssociatedApnsRequest: bool
	};
	return AccountsComponent;
};

export default Accounts;
