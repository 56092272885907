export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Azioni con endpoint multipli',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Selezionare due o più endpoint',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': 'Applicare uno o più filtri per attivare la funzionalità di esportazione',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': 'Assegnato attualmente agli endpoint selezionati',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': 'Idoneo all’utilizzo con gli endpoint selezionati',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': 'Richiesta cambiamento diritti di rete per più SIM non riuscita',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': 'Aggiornamento diritti di rete SIM riuscito',
	'ENDPOINTS.VIEW_NOTE': 'Visualizza nota',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'Nessun endpoint disponibile.',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': 'Non ci sono attualmente endpoint da mostrare per questa tabella',
	//  EDIT TABLE MODAL
	'ENDPOINTS.TABLE_NAME': 'Nome tabella',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Modifica nome vista tabella',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Inserire nome tabella',
	'ENDPOINTS.EDIT_TABLE': 'Modifica parametri tabella',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Modifica etichette campi personalizzati',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Modifica parametri tabella',
	'ENDPOINTS.APPLY_CHANGE': 'Applica cambiamento',
	'ENDPOINTS.DELETE_TAB': 'Elimina scheda',
	'ENDPOINTS.CUSTOM_LABEL': 'Etichetta personalizzata {index}',
	'ENDPOINTS.CLEAR_ALL': 'Ripristina impostazioni predefinite',
	'ENDPOINTS.DEVICE_TYPE': 'Tipo dispositivo',
	'ENDPOINTS.BULK_UPLOAD': 'Caricamento collettivo',

	//  EDIT TABLE PARAMETERS
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': 'Selezionarne almeno 2 da applicare alla tabella. ',
	'ENDPOINTS.CELLULAR_COLUMNS': 'Parametri specifici cellulari',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Parametri specifici satellitari',

	//  CELLULAR SPECIFIC PARAMETERS
	'ENDPOINTS.M2MACCOUNTID': 'ID account M2M',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': 'Stato',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': 'Operatore di rete',
	'ENDPOINTS.NETWORK_TYPE': 'Tipo di rete',
	'ENDPOINTS.IMSI_TRANSFER': 'Stato trasferimento IMSI',
	'ENDPOINTS.RATE_PLAN': 'Piano tariffario',
	'ENDPOINTS.RECREDENTIAL_STATUS': 'Stato rigenerazione credenziali',
	'ENDPOINTS.ICCID': 'ICCID',
	'ENDPOINTS.SERVICE_PROVIDER': 'Piattaforma M2M',
	'ENDPOINTS.MSISDN': 'MSISDN',
	'ENDPOINTS.COUNTRY_OPERATOR': 'Paese della rete',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'ID diritti di rete',
	'ENDPOINTS.OEM': 'Sottoconti',
	'ENDPOINTS.SERVICE_STATUS': 'Stato 360L',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': 'Paese roaming',
	'ENDPOINTS.ROAMING_CARRIER': 'Vettore roaming',
	'ENDPOINTS.M2M_DATE_ADDED': 'Aggiunta data M2M',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'Data attivazione M2M',
	'ENDPOINTS.NOTES': 'Note',
	'ENDPOINTS.CTD_DATA_USAGE': 'Utilizzo dati CTD',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'Utilizzo SMS CTD',
	'ENDPOINTS.CTD_VOICE_USAGE': 'Utilizzo vocale CTD',
	'ENDPOINTS.INSESSION': 'In uso',

	//  SATELLITE SPECIFIC PARAMETERS
	'ENDPOINTS.M2M_ACC_ID': 'ID account M2M',
	'ENDPOINTS.DEVICE_NAME': 'Nome dispositivo',
	'ENDPOINTS.DEVICE_ID': 'ID dispositivo',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': 'N. modello dispositivo',

	//  RATE PLAN SCHEDULE MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': 'Si è verificato un errore sulla piattaforma sottostante e la modifica del piano tariffario non è attualmente disponibile.',
	'ENDPOINTS.RATE_PLAN_CHANGE': 'Cambiamento piano tariffario',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'Si è scelto di passare al piano tariffario ',
	'ENDPOINTS.TAP_INITIATE_CHANGE': 'Toccare “Avvia modifica” per avviare immediatamente l’azione.',
	'ENDPOINTS.CHANGE_LATER': 'È anche possibile pianificare il cambiamento per dopo.',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': 'Il cambiamento pianificato avverrà all’inizio della data selezionata.',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'Pianifica cambiamento',
	'ENDPOINTS.INITIATE_CHANGE': 'Avvia cambiamento',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Cambiamento del piano tariffario pianificato',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Cambiamento del piano tariffario pianificato per {scheduleDate}',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Pianificazione cambiamento piano tariffario non riuscita.',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Pianifica cambiamento piano tariffario',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. Conferma del sistema',
	'ENDPOINTS.NOTIFICATION_ERROR': 'Provisioning ID endpoind {endpointICCID} non riuscito dopo cambiamento piano tariffario. Ottieni assistenza',
	'ENDPOINTS.SCHEDULE_DATE': 'Data',
	'ENDPOINTS.SIM_STATE_CHANGE': 'Cambiamento stato SIM',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': 'Si è scelto di cambiare lo stato della SIM in',
	'ENDPOINTS.STATUS_CHANGE_ERROR': 'L’utente {user} ha effettuato il cambiamento di stato il {changeDate}',
	'ENDPOINTS.SCHEDULING': 'Pianificazione in corso...',

	//  SIM STATUS
	'ENDPOINTS.ACTIVE': 'Attivato',
	'ENDPOINTS.TERMINATE': 'Chiuso',
	'ENDPOINTS.PROVISIONED': 'Provisioning effettuato',
	'ENDPOINTS.DEACTIVE': 'Disattivo',
	'ENDPOINTS.INACTIVE': 'Inattivo',
	'ENDPOINTS.TEST': 'Test',
	'ENDPOINTS.SUSPENDED': 'Sospeso',
	'ENDPOINTS.SUSPEND': 'Sospendi',
	'ENDPOINTS.INVENTORY': 'Inventario',

	//  SIM STATE SCHEDULE CHANGE
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. Pianifica cambiamento stato SIM',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'Pianifica cambiamento stato SIM',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Pianificazione cambiamento di stato SIM riuscita.',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Pianificazione cambiamento stato SIM per il {scheduledDate} riuscita',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Pianificazione cambiamento di stato SIM non riuscita.',
	'ENDPOINTS.PLEASE_TRY_AGAIN': 'Riprovare.',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': 'Provisioning ID endpoint {endpointICCID} non riuscito dopo cambiamento stato IMSI. Ottieni assistenza',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE': 'L’utente {user} ha effettuato il cambiamento di stato il {changeDate}',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS': 'L’utente {user} ha effettuato il cambiamento di stato il {date}',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': 'Pianificazione cambiamento di stato di SIM multiple non riuscita.',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': 'Richiesta cambiamento di stato di SIM multiple non riuscita.',

	//  GENERATE REPORT MODAL
	'ENDPOINTS.CREATE_REPORT': 'Crea report',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Crea report personalizzato',
	'ENDPOINTS.ENDPOINTS_ARE': 'endpoint selezionati per esportazione',
	'ENDPOINTS.ENDPOINT_IS': 'endpoint selezionato per esportazione',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': 'Questo report personalizzato sarà disponibile nella sezione report',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': 'È possibile aggiungere il nome personalizzato al report qui',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Appendice nome personalizzato report',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': 'Aggiungi appendice nome personalizzato report (sono permesse solo cifre e lettere)',

	//  MULTI-AZIONE MODALE
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. Seleziona stato SIM',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Conferma cambiamenti',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. Conferma del sistema',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Seleziona piano tariffario',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1. Seleziona diritti di rete',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Cambia note',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2. Conferma del sistema',
	'ENDPOINTS.CHANGE_SIM_STATE': 'Cambia stato SIM',
	'ENDPOINTS.CHANGE_RATE_PLAN': 'Cambia piano tariffario',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Cambia diritti di rete',
	'ENDPOINTS.CHANGE_NOTES': 'Note',

	//  NOTE MULTI-AZIONE MODALE
	'ENDPOINTS.SAVE_NOTE': 'Salva nota',
	'ENDPOINTS.ADD_NOTE_BELOW': 'Aggiungere nota qui sotto: ',
	'ENDPOINTS.ENTER_NOTE_HERE': 'Inserire nota qui (massimo 2000 caratteri)',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Superato il numero massimo di caratteri',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'Non possono essere solo spazi vuoti',
	'ENDPOINTS.CHANGING_NOTES': 'Modifica note',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': 'Modifica endpoint riuscita',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': 'Avvio modifica note per più endpoint non riuscito',
	'ENDPOINTS.SUCCESS_KEY': 'Riuscito',
	'ENDPOINTS.ERROR': 'Errore',
	'ENDPOINTS.REASON': 'motivo',
	'ENDPOINTS.ENDPOINTS_SELECTED': 'endpoint selezionati',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'Non è permesso modificare le note',

	//  CHANGE RATE PLAN
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Selezionare alcune SIM.',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'Cambiamento piano tariffario non permesso',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'Nessun piano tariffario disponibile',
	'ENDPOINTS.SIMS_HAVE': 'SIM hanno',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': 'Selezionare un piano tariffario diverso in modo da applicare il cambiamento a tutte le SIM selezionate',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': 'nessun piano tariffario selezionato',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Confermare cambiamento piano tariffario',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': 'Si è scelto di cambiare il piano tariffario delle',
	'ENDPOINTS.SELECTED_SIMS_TO': 'SIM selezionate a',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': 'Avviare ora il cambiamento?',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': 'Oppure è possibile pianificare il cambiamento per: ',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': 'Pianificazione piano tariffario per più SIM riuscita',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'Cambiamento piano tariffario SIM per',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Le SIM sono state pianificate per',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': 'Aggiornamento piani tariffari SIM riuscito',
	'ENDPOINTS.SIMS_SELECTED': 'SIM selezionate',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': 'Pianificazione cambiamento piano tariffario per più SIM non riuscita',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': 'Richiesta cambiamento piano tariffario per più SIM non riuscita',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Stesso piano tariffario selezionato',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': 'Provisioning endpoint ID {endpointID} riuscito. Piano tariffario cambiato a {ratePlan}',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': 'Provisioning ID endpoint {endpointID} non riuscito dopo cambiamento piano tariffario. Ottieni assistenza',

	//  CHANGE SIM STATE
	'ENDPOINTS.CURRENT': 'CORRENTE',
	'ENDPOINTS.NETWORK_TYPE_CHECK': 'Siamo spiacenti, non è consentito il cambiamento di stato di più endpoint di diversi tipi di rete o diversi provider di servizi.',
	'ENDPOINTS.SELECT_SIM_STATE': 'Seleziona stato SIM',
	'ENDPOINTS.CURRENT_STATES': 'Stati correnti',
	'ENDPOINTS.SELECTED_TIP': 'Suggerimento: Se si sono selezionate SIM con stati diversi, sarà possibile impostare solo uno stato disponibile per tutte le SIM scelte.',
	'ENDPOINTS.VIEW_RULES': 'Visualizza regole cambiamento di stato per questo account',
	'ENDPOINTS.STATES_MESSAGE': 'Nessuno stato disponibile.',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'Conferma cambiamento stato SIM',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': 'Una volta avviato questo cambiamento, le SIM selezionate saranno',
	'ENDPOINTS.PROCEED_ACTION': 'Continuare?',
	'ENDPOINTS.CAN_SCHEDULE': 'Oppure è possibile pianificare il cambiamento per',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': 'Provisioning endpoint ID {endpointID} riuscito. Stato IMSI cambiato a {status}',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': 'Provisioning endpoint ID {endpointID} non riuscito dopo cambiamento stato IMSI. Ottieni assistenza',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': 'L’utente {user} ha effettuato il cambiamento di stato il {date}',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL': 'Pianificazione cambiamento di stato di SIM multiple riuscita.',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'Cambiamento stato SIM per',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': 'Richiesta cambiamento di stato SIM riuscita.',

	//  GENERAT REPORT
	'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Creazione report personalizzato riuscita',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': 'Errore durante il tentativo di download del file',
	'ENDPOINTS.GO_TO_REPORTS': 'Vai a report',

	//  UPDATE CUSTOM LABELS
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': 'Aggiornamento di una o più etichette campo personalizzate riuscito.',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': 'Al momento, è impossibile aggiornare una o più etichette campo personalizzate. Riprovare.',

	//  Activation platform
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': 'DCP',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

	TECH_TYPE: 'Tipo tecnico',
	TECH_TYPE_SUB_TYPE: 'Sottocategoria Tipo di tecnologia',
	'ENDPOINTS.TECH_TYPE': 'Tipo tecnico',
	'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Sottocategoria Tipo di tecnologia',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Tipo tecnico',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Sottocategoria Tipo di tecnologia'
};
