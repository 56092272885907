import React from 'react';
import { connect } from 'react-redux';
import {
	DesktopAndTabletMediaQuery,
	MobileMediaQuery
} from '../../../utils/CustomMediaQuery';
import { hideNotification } from '../../../components/Shared/redux/notifications/actions';

import DesktopTabs from './Desktop';
import MobileTabs from './Mobile';

const mapDispatchToProps = (dispatch) => ({
		hideNotification: () => dispatch(hideNotification('400-get-error'))
	});

const ReduxDesktopTabs = connect(
	null,
	mapDispatchToProps
)(DesktopTabs);
const ReduxMobileTabs = connect(
	null,
	mapDispatchToProps
)(MobileTabs);

const Tabs = (props) => [
	<DesktopAndTabletMediaQuery key="tabs-query-desktop">
		<ReduxDesktopTabs {...props} />
	</DesktopAndTabletMediaQuery>,
	<MobileMediaQuery key="tabs-query-mobile">
		<ReduxMobileTabs {...props} />
	</MobileMediaQuery>
];

export default Tabs;
