import React from 'react';
import { object, func, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import GlobecomCarousel from '../../GlobecommTabs/Carousel';
import Modal from '../../SpecificationsModal';
import hoc from '../Hardware';

import styles from './Desktop.scss';

function Desktop({
	isStatusPromptModalOpen,
	openPromptModal,
	closePromptModal,
	endpoint,
}) {
	return (
		<div className={styles.data_wrap} data-spec="hardware-desktop">
			<Modal
				show={isStatusPromptModalOpen}
				onCancel={closePromptModal}
				endpoint={endpoint || {}}
			/>
			<div className={`${styles.column} ${styles.column_custom}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.MANUFACTURER"
							defaultMessage="Manufacturer"
						/>
					</span>
					<span>{endpoint.manufacturer || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.MODEL_NUMBER"
							defaultMessage="Model Number"
						/>
					</span>
					<span>{endpoint.modelNumber || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.DESCRIPTION"
							defaultMessage="DESCRIPTION"
						/>
					</span>
					<span>{endpoint.description || '-'}</span>
				</div>
			</div>
			<div className={`${styles.column} ${styles.column_custom}`}>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.ID"
							defaultMessage="ID"
						/>
					</span>
					<span>{endpoint.guid || '-'}</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.NETWORK_TYPE"
							defaultMessage="Network Type"
						/>
					</span>
					<span>
						{endpoint.types.length
							? endpoint.types.map((item) => (
								<span key={`${item.hardwareType}`}>
									{item.hardwareType}
									<br />
								</span>
							))
							: ''}
					</span>
				</div>
				<div className={styles.data}>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.SUPPORTED_NETWORKS"
							defaultMessage="Supported Networks"
						/>
					</span>
					<span>
						{endpoint.supportedNetworks.length
							? endpoint.supportedNetworks.map((item) => (
								<span key={`${item.network}`}>
									{item.network}
									<br />
								</span>
							))
							: ''}
					</span>
				</div>
				<div>
					<div
						className={styles.specificationLink}
						onClick={() => openPromptModal()}
					>
						<FormattedMessage
							id="SINGLE_ENDPOINT.SPECIFICATION"
							defaultMessage="Specification"
						/>
					</div>
				</div>
			</div>
			<div className={styles.column}>
				<GlobecomCarousel data={endpoint.images} />
			</div>
		</div>
	);
}

Desktop.propTypes = {
	endpoint: object,
	isStatusPromptModalOpen: bool,
	closePromptModal: func,
	openPromptModal: func,
};

export default hoc(Desktop);
