import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import styles from './NotesModal.scss';

function NotesModal({ show, onCancel, note }) {
  return (
		<Modal data-spec="dashboard-notes-modal" show={show} onClose={onCancel}>
			<ModalHeader />
			<ModalBody className={styles.modal_body}>
				<div data-spec="body-note" className={styles.notes_body}>
					<div className={styles.modal_note}>{note}</div>
				</div>
			</ModalBody>
		</Modal>
	);
}

NotesModal.propTypes = {
	onCancel: PropTypes.func,
	show: PropTypes.bool,
	note: PropTypes.string
};

NotesModal.defaultProps = {
	onCancel: undefined,
	show: false,
	note: ''
};

export default NotesModal;
