import React from 'react';
import PropTypes, { shape } from 'prop-types';

import hoc from '../Users';
import Table from '../../../../../lib/DigitalComponents/Table';

const Desktop = (props) => {
	const { getOptions, messages, users } = props;
	return (
		<div data-spec="users-desktop">
			<Table
				data={users.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { func, objectOf, string } = PropTypes;

Desktop.propTypes = {
	getOptions: func,
	messages: objectOf(string),
	users: shape()
};

export default hoc()(Desktop);
