import React from 'react';
import { FormattedMessage } from 'react-intl';

const dateFormat = 'MM/dd/yyyy';

export const ReportsIDs = {
	ENDPOINTS: 'ENDPOINTS',
	USAGE_SUMMARY: 'USAGE_SUMMARIES',
	TRANSFER_ACTIVITIES: 'TRANSFER_ACTIVITIES',
	ACCOUNT_SUMMARY: 'ACCOUNT_SUMMARY',
	BILLING_DETAILS: 'BILLING_DETAILS_REPORTS',
	REFERENCE_REPORTS: 'REFERENCE',
	MY_EXPORTS: 'MyExports',
	USAGE_REPORTS: 'UsageReports',
	REPORT_HEADERS: 'report_headers',
	REPOSTED_REPORTS: 'REPOSTED_REPORTS'
};

export const isIOTTab = (tabName) =>
  [ReportsIDs.MY_EXPORTS, ReportsIDs.USAGE_REPORTS].includes(tabName);

export const isReportHeadersTab = (tabName) =>
	[ReportsIDs.REPORT_HEADERS].includes(tabName);

export const isRepostedReportsTab = (tabName) =>
	[ReportsIDs.REPOSTED_REPORTS].includes(tabName);

export const getSearchMenu = (tabName) => {
	const searchMenu = [
		{
			label: (
				<div>
					<FormattedMessage id="BY" defaultMessage="By" />
					<FormattedMessage id="REPORT" defaultMessage="Report" />
				</div>
			),
			value: 'report_name'
		}
	];

	if (!isIOTTab(tabName) && !isRepostedReportsTab(tabName)) {
		searchMenu.push({
			label: (
				<div>
					<FormattedMessage id="BY" defaultMessage="By" />
					<FormattedMessage id="DATE" defaultMessage="Date" />
				</div>
			),
			value: 'report_date',
			placeholder: dateFormat
		});
	}
	return searchMenu;
};
