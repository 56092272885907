import { Status } from '../../../../utils/constants';

export const getSmsDetailsRequest = (state) =>
	state.analyticsInvoices.tables.smsDetails.getSmsDetails.status ===
	Status.PENDING;
export const getSmsDetailsFail = (state) =>
	state.analyticsInvoices.tables.smsDetails.getSmsDetails.status ===
	Status.FAIL;
export const getSmsDetailsSuccess = (state) =>
	state.analyticsInvoices.tables.smsDetails.getSmsDetails.data;

export const getSmsDetailsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.smsDetails.getSmsDetailsFilters.status ===
	Status.PENDING;
export const getSmsDetailsFiltersFail = (state) =>
	state.analyticsInvoices.tables.smsDetails.getSmsDetailsFilters.status ===
	Status.FAIL;
export const getSmsDetailsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.smsDetails.getSmsDetailsFilters.data;
