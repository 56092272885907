import React from 'react';
import { func, bool, array } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './ViewAccountAccessModal.scss';

const ViewAccountAccessModal = ({ onClose, show, data }) => (
	<Modal data-spec="prompt-modal" show={show} onClose={onClose}>
		<ModalHeader
			title={
				<FormattedMessage
					id="ONBOARDING.ACCOUNT_ACCESS"
					defaultMessage="Account Access"
				/>
			}
		/>
		<ModalBody>
			<div data-spec="body-message" className={styles.body}>
      {
        data.map((platform) => <div key={platform.id}>{platform.m2mAccountName}</div>)
      }
			</div>
		</ModalBody>
		<ModalFooter data-spec="ds-modal-footer">
			<div data-spec="buttons">
				<Button
					onClick={onClose}
					variant="link"
					label={<FormattedMessage id="CLOSE" defaultMessage="CLOSE" />}
				/>
			</div>
		</ModalFooter>
	</Modal>
);

ViewAccountAccessModal.propTypes = {
	onClose: func,
  show: bool,
  data: array
};

export default ViewAccountAccessModal;
