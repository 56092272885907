export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': 'Automatisierungsregel löschen?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': 'Ausgewählte Automatisierungsregeln löschen?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': 'Möchten Sie die ausgewählten Automatisierungsregeln wirklich löschen?',
	'AUTOMATION.CANCEL': 'Abbrechen',
	'AUTOMATION.DELETE': 'Löschen',
	'AUTOMATION.ARE_YOU_SURE': 'Sind Sie sicher?',
	'AUTOMATION.ACTIVE': 'Aktiviert',
	'AUTOMATION.ACC_IF': 'Wenn',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Weiteren Schwellenwert hinzufügen',
	'AUTOMATION.EDIT_RULE': 'Regel bearbeiten',
	'AUTOMATION.CREATE_NEW_RULE': 'Neue Regel erstellen',
	'AUTOMATION.PLATFORM': 'Plattform',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Konto',
	'AUTOMATION.CATEGORY': 'Kategorie',
	'AUTOMATION.RULE_STATE': 'Regel-Status',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Aktiv',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inaktiv',
	'AUTOMATION.TRIGGER': 'Auslöser',
	'AUTOMATION.ACC_RULE_NAME': 'Regelname',
	'AUTOMATION.SAVE': 'Speichern',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Ausgewählte Filter löschen',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Filter auswählen',
	'AUTOMATION.AUTOMATION': 'Automatisierung',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Neue Automatisierungsregel erstellen',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': 'Automatisierungsregel {ruleName} wirklich löschen?',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Regel erstellt',
	'AUTOMATION.ADD_RULE_ERROR': 'Automatisierungsregel konnte nicht erstellt werden, bitte erneut versuchen.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Regel bearbeitet',
	'AUTOMATION.EDIT_RULE_ERROR': 'Automatisierungsregel konnte nicht bearbeitet werden, bitte erneut versuchen.',
	'AUTOMATION.THEN': 'Dann',
	'AUTOMATION.NUMBER_OF_DAYS': 'Anzahl der Tage',
	'AUTOMATION.NUMBER_OF_HOURS': 'Anzahl der Stunden (max. 24)',
	'AUTOMATION.AT': 'Um',
	'AUTOMATION.SIM_STATE': 'SIM-Status',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'Tarifplan-ID',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'M2M-Unterkonto-ID',
	'AUTOMATION.CUSTOMER_ID': 'Kunden-ID',
	'AUTOMATION.CUSTOM': 'Benutzerdef.',
	'AUTOMATION.FILTER': 'Filter',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'Keine Filter verfügbar',
	'AUTOMATION.THRESHOLD': 'Schwellenwert',
	'AUTOMATION.ZONE_MODEL': 'Zonenmodell',
	'AUTOMATION.ZONE_NAME': 'Zonenname',
	'AUTOMATION.POOL_ID': 'Pool-ID',
	'AUTOMATION.COUNTRY': 'Land',
	'AUTOMATION.FROM_KEY': 'Von',
	'AUTOMATION.TO_KEY': 'Bis',
	'AUTOMATION.FOLLOW_UP_WITH': 'Nachfassen mit',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': 'Automatisierungsregel erfolgreich gelöscht',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': 'Ausgewählte Automatisierungsbenachrichtigungen erfolgreich gelöscht',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': 'Automatisierungsregel konnte nicht gelöscht werden, bitte erneut versuchen.',
	'AUTOMATION.PLATFORMS': 'Plattformen',
	'AUTOMATION.CATEGORIES': 'Kategorien',
	'AUTOMATION.TRIGGERS': 'Auslöser',
	'AUTOMATION.M2MACCOUNTS': 'M2M-Konten',
	'AUTOMATION.HOURS_USING': 'In den vergangenen 24 Stunden unter Verwendung von',
	'AUTOMATION.LOCATIONUPDATE': 'Standortaktualisierung',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Authentifizierungs-Aktualisierung',
	'AUTOMATION.ZONE': 'Zone',
	'AUTOMATION.ACCOUNT_ID': 'Konto-ID',
	'AUTOMATION.ACTION': 'Aktion',
	'AUTOMATION.DCP': 'DCP',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'SMS',
	'AUTOMATION.USAGE': 'Nutzung',
	'AUTOMATION.SIM_PROVISIONING': 'SIM-Bereitstellung',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Abonnementüberwachung',
	'AUTOMATION.USAGE_MONITORING': 'Nutzungsüberwachung',
	'AUTOMATION.NETWORK_MONITORING': 'Netzwerküberwachung',
	'AUTOMATION.PRICING_AUTOMATION': 'Preisautomatisierung',
	'AUTOMATION.SECURITY_MONITORING': 'Sicherheitsüberwachung',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'SIM-Tarifplanänderung',
	'AUTOMATION.SIM_STATE_CHANGE': 'SIM-Statusänderung',
	'AUTOMATION.SMS_USAGE': 'SMS-Nutzung',
	'AUTOMATION.SIM_DATA_LIMIT': 'SIM-Datenlimit',
	'AUTOMATION.SIM_EXPIRATION': 'SIM-Ablauf',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Datennutzung Zyklus bis dato',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Voice-Nutzung Zyklus bis dato',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': 'Datennutzung in vergangenen 24 Stunden überschritten',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': 'Anzahl Sitzungsverbindungen letzte 24 Stunden',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Monatliche gepoolte Datennutzung',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Monatliche gepoolte SMS-Nutzung',
	'AUTOMATION.NO_CONNECTION': 'Keine Verbindung',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Voice-Nutzung vergangene 24 Stunden',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'In einer Zone registrieren',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Zu viele Verbindungen',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'SMS MO-Überlauf pro Zeitrahmen',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'SMS MO-Unterlauf pro Zeitrahmen',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'SMS MT-Überlauf pro Zeitrahmen',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'SMS MT-Unterlauf pro Zeitrahmen',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'GPRS langfristiger Überlauf',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'GPRS kurzfristiger Überlauf',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'Erste Datensitzung hergestellt',
	'AUTOMATION.IMEI_CHANGED': 'IMEI geändert',
	'AUTOMATION.NETWORK_REGISTRATION': 'Netzwerkregistrierung',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Netzwerkregistrierung in einer Zone',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'Änderung benutzerdefiniertes SIM-Feld',
	'AUTOMATION.SIM_STATUS': 'SIM-Status',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Tarifplan geändert',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'SIM-Land geändert',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'SIM-Tarifplan läuft ab',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Datensitzung gestoppt',
	'AUTOMATION.DATA_SESSION_STARTED': 'Datensitzung gestartet',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Datennutzung in einem Land',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': 'Zahl Datensitzungen – Abrechnungszyklus',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Datennutzung in einer Zone',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'Kein Verbindungs-Abrechnungszyklus',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': 'Anzahl Sitzungsverbindungen letzte 24 Stunden',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'SMS-Zahl in einem Land',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'SMS-Nutzung in einer Zone',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'SMS-Nutzung letzte 24 Stunden',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Gesamtdatennutzung – 24 Stunden',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': 'Gesamtdatennutzung – Abrechnungszyklus',
	'AUTOMATION.SMS_MO_RECEIVED': 'SMS MO erhalten',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': 'Voice-Nutzung Zyklus bis dato in einer Zone',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'SIM-Plan Fertigstellung Voice',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'SIM-Plan Fertigstellung Daten',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'IMEI-Whitelist Aktion',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'SIM-Kontoänderung',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Neueste SMS-Nutzung (24 Stunden)',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': 'Netzwerkregistrierung in einer Zone',
	'AUTOMATION.MSISDN_CHANGE': 'MSISDN-Änderung',
	'AUTOMATION.IMEI_CHANGE': 'IMEI-Änderung',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': 'Datennutzung Zyklus bis dato in einer Zone',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': 'Ein Endpunkt wird übertragen von',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': 'Beliebiger Endpunkt im aktuellen Zyklus nutzt',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Beliebiger Endpunkt eines Prepaid-Tarifplans schließt den entsprechenden Prepaid-Zeitrahmen ab',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': 'Das Netzwerk erfasst eine Änderung des IMEI des Endpunkts',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': 'The System erkennt MSISDN-Änderung für einen Endpunkt',
	'AUTOMATION.INITIATE_OTA_SWAP': 'OTA-Austausch initiieren',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': 'Beliebiger Endpunkt sendet oder empfängt mehr als',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': 'Beliebige SIM erfährt eine Änderung in einem der benutzerdefinierten Felder',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': 'Endpunkt-IMEI wurde in der Whitelist nicht gefunden',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': 'Beliebiger Endpunkt im aktuellen Zyklus überschreitet',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': 'Für Short Code wird eine MO SMS empfangen',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'SMS-Gesamtzahl – Abrechnungszyklus',
	'AUTOMATION.1_HOUR': '1 Stunde',
	'AUTOMATION.2_HOURS': '2 Stunden',
	'AUTOMATION.3_HOURS': '3 Stunden',
	'AUTOMATION.4_HOURS': '4 Stunden',
	'AUTOMATION.5_HOURS': '5 Stunden',
	'AUTOMATION.6_HOURS': '6 Stunden',
	'AUTOMATION.7_HOURS': '7 Stunden',
	'AUTOMATION.8_HOURS': '8 Stunden',
	'AUTOMATION.9_HOURS': '9 Stunden',
	'AUTOMATION.10_HOURS': '10 Stunden',
	'AUTOMATION.11_HOURS': '11 Stunden',
	'AUTOMATION.12_HOURS': '12 Stunden',
	'AUTOMATION.13_HOURS': '13 Stunden',
	'AUTOMATION.14_HOURS': '14 Stunden',
	'AUTOMATION.15_HOURS': '15 Stunden',
	'AUTOMATION.16_HOURS': '16 Stunden',
	'AUTOMATION.17_HOURS': '17 Stunden',
	'AUTOMATION.18_HOURS': '18 Stunden',
	'AUTOMATION.19_HOURS': '19 Stunden',
	'AUTOMATION.20_HOURS': '20 Stunden',
	'AUTOMATION.21_HOURS': '21 Stunden',
	'AUTOMATION.22_HOURS': '22 Stunden',
	'AUTOMATION.23_HOURS': '23 Stunden',
	'AUTOMATION.24_HOURS': '24 Stunden',
	'AUTOMATION.25_HOURS': '25 Stunden',
	'AUTOMATION.26_HOURS': '26 Stunden',
	'AUTOMATION.27_HOURS': '27 Stunden',
	'AUTOMATION.28_HOURS': '28 Stunden',
	'AUTOMATION.29_HOURS': '29 Stunden',
	'AUTOMATION.30_HOURS': '30 Stunden',
	'AUTOMATION.31_HOURS': '31 Stunden',
	'AUTOMATION.32_HOURS': '32 Stunden',
	'AUTOMATION.33_HOURS': '33 Stunden',
	'AUTOMATION.34_HOURS': '34 Stunden',
	'AUTOMATION.35_HOURS': '35 Stunden',
	'AUTOMATION.36_HOURS': '36 Stunden',
	'AUTOMATION.37_HOURS': '37 Stunden',
	'AUTOMATION.38_HOURS': '38 Stunden',
	'AUTOMATION.39_HOURS': '39 Stunden',
	'AUTOMATION.40_HOURS': '40 Stunden',
	'AUTOMATION.41_HOURS': '41 Stunden',
	'AUTOMATION.42_HOURS': '42 Stunden',
	'AUTOMATION.43_HOURS': '43 Stunden',
	'AUTOMATION.44_HOURS': '44 Stunden',
	'AUTOMATION.45_HOURS': '45 Stunden',
	'AUTOMATION.46_HOURS': '46 Stunden',
	'AUTOMATION.47_HOURS': '47 Stunden',
	'AUTOMATION.48_HOURS': '48 Stunden',
	'AUTOMATION.LAST_24_HOURS': 'Letzte 24 Stunden',
	'AUTOMATION.CYCLE_TO_DATE': 'Zyklus bis dato',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'SIM-Prepaid-Tarifplan überschreitet',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'Datennutzung überschreitet Limit',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'Datennutzung nähert sich Limit',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'SIM-Prepaid-Tarifplan kommt innerhalb von',
	'AUTOMATION.MO_MT': 'MO/MT',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'Voice-Nutzung überschreitet Limit',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'Voice-Nutzung nähert sich Limit',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'SIM hat hergestellt: mehr als',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'SIM hat hergestellt: weniger als',
	'AUTOMATION.EXCEEDES': 'Überschritten',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': 'SIM hat in den vergangenen N Stunden keine Datenverbindung hergestellt',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'SIM-Registrierung bei Netzwerk in Zone',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'SIM hat im aktuellen Zyklus mehr Verbindungen hergestellt',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': 'Das Netzwerk erfasst ein neues Gerät',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'Zyklus bis dato-Nutzung überschreitet',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'Zyklus bis dato-Nutzung nähert sich',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': 'Das Netzwerk erfasst eine Änderung in ...',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'SIM hat sich zum ersten Mal in einem neuen Netzwerk registriert',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'Endpunkt registriert in Zone',
	'AUTOMATION.CUSTOM_FIELD_1': 'Benutzerdefiniertes Feld 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Benutzerdefiniertes Feld 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Benutzerdefiniertes Feld 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Benutzerdefiniertes Feld 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Benutzerdefiniertes Feld 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Benutzerdefiniertes Feld 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Benutzerdefiniertes Feld 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Benutzerdefiniertes Feld 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Benutzerdefiniertes Feld 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Benutzerdefiniertes Feld 10',
	'AUTOMATION.CHANGED': 'Geändert',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Tarifplanänderung',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': 'Anzahl der Datensitzungen für eine SIM überschreitet',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'Eine SIMS-Datensitzung beginnt',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'Eine SIMS-Datensitzung stoppt',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': 'Datennutzung in einem Land überschreitet',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'Datennutzung in A überschreitet',
	'AUTOMATION.EXCEEDS': 'Überschritten',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': 'Zu berechnende SMS-Nutzung in beliebigem gepoltem Tarifplan erreicht',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': 'Keine SIM hat im vergangenen Abrechnungszyklus eine Datenverbindung hergestellt',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'Eine SIM hat mehr als',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'SMS-Zahl in einem Land überschreitet',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'SMS-Zahl überschreitet Limit',
	'AUTOMATION.1_WEEK': '1 Woche',
	'AUTOMATION.2_WEEKS': '2 Wochen',
	'AUTOMATION.3_WEEKS': '3 Wochen',
	'AUTOMATION.4_WEEKS': '4 Wochen',
	'AUTOMATION.SEND_EMAIL': 'E-Mail senden',
	'AUTOMATION.SEND_SMS': 'SMS senden',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': 'Netzwerkanspruch des Geräts ändern',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': 'SIM-Status des Geräts ändern',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': 'Tarifplan des Geräts ändern',
	'AUTOMATION.PUSH_API': 'Push-API',
	'AUTOMATION.COMBINED': 'Kombiniert',
	'AUTOMATION.MO': 'MO',
	'AUTOMATION.MT': 'MT',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Verbindungen in einem 24-Stunden-',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Netzwerkanspruch ändern',
	'AUTOMATION.CHANGE_SIM_STATUS': 'SIM-Status ändern',
	'AUTOMATION.SMS_MESSAGES': 'SMS-Nachrichten',
	'AUTOMATION.HOURS': 'Stunden',
	'AUTOMATION.MIN': 'Min.',
	'AUTOMATION.MINUTES': 'Minuten',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Tarifplan ändern',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': 'Verbindungen im aktuellen Zyklus',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Auf vorherigen Tarifplan zurücksetzen',
	'AUTOMATION.MODIFIED_DELETED': 'Geändert/Gelöscht',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': 'Der SMS-Tarifplan läuft in einem Tag ab',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': 'Betrag gepoolte inbegriffene SMS-Nutzung',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': 'Anzahl Datensitzungen in den letzten 24 Stunden',
	'AUTOMATION.MSGS': 'Nachr.',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'SMS-Zahl in Zone überschreitet',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': 'Anzahl Datensitzungen in den letzten 24 Stunden',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Verbrauchsstunden',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Auf vorherigen SIM-Status zurücksetzen',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Tage nach auslösendem Ereignis',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'Ende des aktuellen Abrechnungszyklus',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': 'UTC-Zeit nach auslösendem Ereignis',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': 'Auf vorherigen Netzwerkanspruch zurücksetzen',
	'AUTOMATION.GMNA': 'GM Nordamerika',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Enterprise',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM Nordamerika',
	'AUTOMATION.ENTERPRISE': 'Enterprise',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'SIM-Status ändern',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'SMS-Nutzung vergangene 24 Stunden',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': 'SMS-Nutzung vergangene 24 Stunden überschreitet',
	// Auslöser
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': 'SMS MO (Mobile Originated) Nutzung - 24 Stunden',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': 'SMS MO (Mobile Originated) Nutzung - in einer Zone',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': 'SMS MO (Mobile Originated) Nutzung - Pool-Vertrag',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': 'SMS MT (Mobil Terminated) Nutzung - 24 Stunden',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': 'SMS MT (Mobil Terminated) Nutzung - Abrechnungszyklus',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': 'SMS MT (Mobil Terminated) Nutzung - in einer Zone',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': 'SMS MT (Mobil Terminated) Nutzung - Pool-Vertrag',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'Der Tag nach Ablauf des SIM-Tarifplans',
	'AUTOMATION.BYTES': 'Bytes',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Benutzerdefinierten Text zur SMS-Nachricht hinzufügen (optional)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': 'Benutzerdefinierter Nachrichtentext (max. 140 Zeichen)',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'SIM-Plan-Fertigstellung',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Wenn ein SIM eines Prepaid-Tarifplans den entsprechenden Prepaid-Zeitrahmen abschließt',
	// neue Automatisierung
	'AUTOMATION.TITLE': 'Automatisierung',
	'AUTOMATION.RULES_TAB_NAME': 'Automatisierungsregeln',
	'AUTOMATION.ALERTS_TAB_NAME': 'Automatisierungsbenachrichtigungen',
	'AUTOMATION_RULES.YES': 'Ja',
	'AUTOMATION_RULES.NO': 'Nein',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'Keine Automatisierungsregeln verfügbar',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': 'Es sind derzeit keine Automatisierungsregeln zur Ansicht verfügbar',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'Keine Automatisierungsregeln verfügbar',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': 'Es sind derzeit keine Automatisierungsbenachrichtigungen zur Ansicht verfügbar',
	//  Header für Regeltabelle
	'AUTOMATION_RULES.ID': 'ID sind derzeit keine',
	'AUTOMATION_RULES.RULE_NAME': 'Regelname',
	'AUTOMATION_RULES.PLATFORM': 'Plattform',
	'AUTOMATION_RULES.CATEGORY': 'Kategorie',
	'AUTOMATION_RULES.TRIGGER': 'Auslöser',
	'AUTOMATION_RULES.ACTIVE': 'Aktiviert',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'AUTOMATION_RULES.DATE_CREATED': 'Erstellungsdatum',
	'AUTOMATION_RULES.USER': 'Benutzer',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'Implementierungsstatus',
	// Header für Benachrichtigungstabelle
	'AUTOMATION_ALERTS.ID': 'ID sind derzeit keine',
	'AUTOMATION_ALERTS.RULE_NAME': 'Regelname',
	'AUTOMATION_ALERTS.PLATFORM': 'Plattform',
	'AUTOMATION_ALERTS.CATEGORY': 'Kategorie',
	'AUTOMATION_ALERTS.TRIGGER': 'Auslöser',
	'AUTOMATION_ALERTS.ACTIVE': 'Aktiviert',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'AUTOMATION_ALERTS.DATE': 'Datum',
	'AUTOMATION_ALERTS.USER': 'Benutzer',
	'AUTOMATION_ALERTS.ACTION': 'Aktion',
	// Regeln
	'AUTOMATION.ACTIVATE': 'Aktivieren',
	'AUTOMATION.DEACTIVATE': 'Deaktivieren',
	'AUTOMATION.RULE_CREATED_OR_EDITED': 'Sie haben eine Automatisierungsregel ausgewählt, deren Bearbeitung längere Zeit in Anspruch nimmt und die nicht direkt in Kraft tritt.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': 'Automatisierungsregel erfolgreich aktiviert',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'Automatisierungsregel nicht erfolgreich aktiviert',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': 'Automatisierungsregel erfolgreich deaktiviert',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': 'Automatisierungsregel nicht erfolgreich deaktiviert',
	'AUTOMATION.SHORT_CODE': 'Kurzcode',
	'AUTOMATION.BASE_RATE_PLAN': 'Basis-Tarifplan',
	'AUTOMATION.RATE_PLAN_TYPE': 'Tarifplantyp',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'Implementierungsstatus',
	'AUTOMATION.YES': 'Ja',
	'AUTOMATION.NO': 'Nein',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Regel-Statusänderung',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': 'Wirklich fortfahren?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': 'Sie sind kurz davor, den {currentState} Status einer Regel auf {selectedState} abzuändern',
	'AUTOMATION.REQUEST_DELETION_TEXT': 'Sie sind kurz davor, den Status einer Regel abzuändern',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Regellöschung',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': 'Sie sind kurz davor, Regel {ruleName} zu löschen',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Automatisierungsregel Löschungsanforderung',
	'AUTOMATION.REQUEST_DELETION': 'Löschung anfordern',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': 'Sie haben den Implementierungsstatus mit Erfolg auf Empfangen abgeändert',
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': 'Sie haben den Implementierungsstatus mit Erfolg auf Implementiert abgeändert',
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': 'Sie haben den Implementierungsstatus mit Erfolg auf Konflikt abgeändert',
	'AUTOMATION.SET_RULE_STATE_FAIL': 'Beim Ändern des Implementierungsstatus ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': 'Sie haben die Löschung der Regel mit Erfolg angefordert.',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': 'Beim Anfordern der Löschung einer Regel ist ein Fehler aufgetreten.',
	'AUTOMATION.RULE_CHANGE': 'Automatisierungsregel - Änderung',
	'AUTOMATION.RULE_CREATE': 'Automatisierungsregel - Erstellen',
	'AUTOMATION.ACCEPT': 'Akzeptieren',
	'AUTOMATION.MANUAL_RULE_DELAY': 'Die Implementierung dieser Regel wird sich verzögern.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': 'Die Umsetzung dieser Regel wird etwas Zeit in Anspruch nehmen.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': 'Mir ist bewusst, dass sich die Implementierung verzögern wird.',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Aktion auswählen:',
	// Status
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'Löschung ausstehend',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Implementiert',
	'AUTOMATION.RULE_STATUS_PENDING': 'Ausstehend',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'Konflikt',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Empfangen',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'Trifft nicht zu',
	//  Zonenmodell
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'ATT Standard Zone 1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'ATT Standard Zone 2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'ATT Standard Zone 3',
	//  Zonenname
	'AUTOMATION.US_DOMESTIC': 'US Domestic',
	'AUTOMATION.EU': 'EU'
};
