import qs from 'qs';

import * as ApiService from '../../../services/ApiService';

export const getCompanyReports = ({
	companyId,
	accountId,
	m2mAccountId,
	platformType,
	searchParams,
	additionalParams
}) => {
	let url = `reports/my-company/m2m-accounts/${m2mAccountId}/${platformType}/report-configuration`;

	if (companyId) {
		url = `reports/accounts/${accountId}/m2m-accounts/${m2mAccountId}/${platformType}/report-configuration`;
	}

	return ApiService.get(`${url}?${qs.stringify({ searchParams, additionalParams })}`);
};

export const editCompanyReports = (params) => {
	const { companyId, accountId, m2mAccountId, platformType, data } = params;
	let url = `reports/my-company/m2m-accounts/${params.m2mAccountId}/${params.platformType}/report-configuration`;

	if (companyId) {
		url = `reports/accounts/${accountId}/m2m-accounts/${m2mAccountId}/${platformType}/report-configuration`;
	}

	return ApiService.patch(url, data);
};
