// eslint-disable-next-line import/prefer-default-export
import { format } from 'date-fns';

const INVOICE_DATE_FORMAT = 'MM/dd/yyyy';
const BILLING_CYCLE_FORMAT = 'MMM yyyy';
const TIME_FORMAT = 'HH:mm:ss';

export const isValidBillingCycle = (billingCycle) =>
	/^(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC) ([12]\d{3})$/i.test(
		billingCycle
	);

export const getUTCDate = (date) =>
	new Date(
		Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
	);

export const formatInvoiceDate = (date) =>
	format(date, INVOICE_DATE_FORMAT);

export const formatInvoiceDateForApi = (date) => {
	const utcDate = getUTCDate(date).toISOString().split('T')[0];
	return `${utcDate}_00-00-00`;
};

export const formatBillingCycle = (date) =>
	format(date, BILLING_CYCLE_FORMAT).toUpperCase();

export const formatTime = (date) => format(date, TIME_FORMAT);
