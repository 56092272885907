import React, { PureComponent } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';

import {
	required,
  ipAddress,
  ipAddressWithMask,
	onlyNumbersWithoutSpaces,
	url,
	port
} from '../../../../utils/validators';
import { normalize } from '../../../../utils/constants';

import styles from './SecurityFeaturesForm.scss';

class SecurityFeaturesForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { getM2mAccounts } = this.props;
		getM2mAccounts();
	}

	getOptions = () => {
		const { m2mAccounts } = this.props;
		const m2mAccountsOptions = m2mAccounts ? m2mAccounts.m2mAccounts : [];
		return m2mAccountsOptions.map((item) => ({
			value: item.m2mAccountId,
			label: `${item.platformName}(${item.m2mAccountId})`
		}));
	};

	validator = (value) => {
		if (ipAddress(value) && url(value) && port(value)) {
			return (
				<FormattedMessage
					data-spec="invalid-format-message"
					id="INVALID_INPUT"
					defaultMessage="Invalid Input"
				/>
			);
		}
	};

	Option = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<components.Option {...optionProps} data-spec={`${data.value}`}>
				<div data-spec={`${data.value}`}>{children}</div>
			</components.Option>
		);
	};

	SingleValue = (optionProps) => {
		const { data } = optionProps;
		return (
			<components.SingleValue {...optionProps} data-spec={`${data.value}`} />
		);
	};

	renderFields = ({ fields }) => {
		const { id, type, formValues, m2mAccountsRequest } = this.props;
		let validate = [required, onlyNumbersWithoutSpaces];
		if (id === 4) {
			validate = [required, this.validator];
		} else if (id === 5) {
			validate = [required, ipAddressWithMask];
		}
		return fields.map((item, i) => (
			<div className={styles[`field_array_${type}`]} key={item}>
				<div className={styles.row_container}>
					<div className={styles.input_container}>
						<FormattedMessage
							id={`SECURITY_FEATURES_${id}`}
							defaultMessage="Number"
						>
							{(formattedValue) => (
								<Field
									disabled={formValues && formValues.itemFields[i].delete}
									component={Input}
									name={`${item}.customerSecurityFeatureTypeValue`}
									className={styles.field}
									placeholder={formattedValue}
									dataSpec="contact-name"
									validate={validate}
									normalize={normalize}
								/>
							)}
						</FormattedMessage>
					</div>
					<div className={styles.input_container}>
						<Field
							name={`${item}.m2mAccountId`}
							dataSpec={`${item}.m2mAccountId`}
							component={Select}
							validate={[required]}
							placeholder={
								<FormattedMessage
									id="SECURITY_FEATURES.SELECT_PLATFORM"
									defaultMessage="Select M2M Platform"
								/>
							}
							options={this.getOptions()}
							isDisabled={
								m2mAccountsRequest ||
								(formValues && formValues.itemFields[i].delete)
							}
							loading={m2mAccountsRequest}
							components={{
								Option: this.Option,
								SingleValue: this.SingleValue
							}}
							cleanValue
						/>
					</div>
				</div>
				{type === 'edit' && (
					<div className={styles.row_container}>
						<div className={styles.input_container_checkbox}>
							<Field
								type="checkbox"
								component={Checkbox}
								label={
									<FormattedMessage
										id="SECURITY_FEATURES_REMOVE"
										defaultMessage="Remove from Whitelist"
									/>
								}
								name={`${item}.delete`}
							/>
						</div>
					</div>
				)}
			</div>
		));
	};

	render() {
		const { messages } = this.props;
		return (
			<div data-spec className={styles.form_container}>
				<FieldArray
					name={'itemFields'}
					component={this.renderFields}
					styles={styles}
					messages={messages}
				/>
			</div>
		);
	}
}
const { func, number, bool, string, objectOf, shape } = PropTypes;

SecurityFeaturesForm.propTypes = {
	m2mAccountsRequest: bool,
	m2mAccounts: shape(),
	getM2mAccounts: func,
	formValues: shape(),
	id: number,
	type: string,
	messages: objectOf(string)
};

export default reduxForm({
	form: 'FormSecurity',
	destroyOnUnmount: true
})(SecurityFeaturesForm);
