export default {
	'USER_FEEDBACK.TRIGGER_TITLE': 'Quer dar feedback?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Escreva aqui',
	'USER_FEEDBACK.ADDITIONAL_FILE': 'Arquivo adicional',
	'USER_FEEDBACK.PAGE_LABEL': 'Seu feedback está relacionado com',
	'USER_FEEDBACK.FEED_LABEL': 'Em suas próprias palavras, como podemos melhorar o que oferecemos aos nossos clientes?',
	'USER_FEEDBACK.FEED_PLACEHOLDER': 'Escreva seu feedback aqui...',
	'USER_FEEDBACK.CONTACT_LABEL': 'Se necessário, podemos entrar em contato com você para discutir este assunto?',
	'USER_FEEDBACK.EMAIL_LABEL': 'E-mail',
	'USER_FEEDBACK.PHONE_LABEL': 'Telefone',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Enviar feedback',
	'USER_FEEDBACK.ICON': 'Ícone de envio bem-sucedido de feedback',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Obrigado pelo feedback.',
	'USER_FEEDBACK.SUCCESS_MESSAGE': 'Seu feedback aperfeiçoará o nosso produto. Avaliaremos seus comentários atentamente e tomaremos as devidas providências.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': 'Poderemos entrar em contato para obter mais informações sobre suas sugestões.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'Equipe do IoT Console',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Pronto',
	'USER_FEEDBACK.TITLE': 'Feedback do usuário',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Falha no carregamento'
};
