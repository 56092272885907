import { createAction } from 'redux-actions';
import { updateNote } from '../../services/EndpointsService';

import {
	UPDATE_BULK_NOTES_REQUEST,
	UPDATE_BULK_NOTES_SUCCESS,
	UPDATE_BULK_NOTES_FAIL,
	UPDATE_BULK_NOTES_STARTED,
	UPDATE_BULK_NOTES_ENDED,
	UPDATE_BULK_NOTES_ADD_FILTERED_ENDPOINTS,
	UPDATE_BULK_NOTES_ERROR_CLEAR
} from './constants';

const updateBulkNotesStatusRequest = createAction(UPDATE_BULK_NOTES_REQUEST);
const updateBulkNotesStatusSuccess = createAction(UPDATE_BULK_NOTES_SUCCESS);
const updateBulkNotesStatusFail = createAction(UPDATE_BULK_NOTES_FAIL);
const updateBulkNotesAddFilteredEndpoints = createAction(
	UPDATE_BULK_NOTES_ADD_FILTERED_ENDPOINTS
);
export const updateBulkNotesStatusStarted = createAction(
	UPDATE_BULK_NOTES_STARTED
);
export const updateBulkNotesStatusEnded = createAction(UPDATE_BULK_NOTES_ENDED);
export const updateBulkNotesErrorClear = createAction(
	UPDATE_BULK_NOTES_ERROR_CLEAR
);

export const updateBulkNotes = (data, count = 0) => async (dispatch) => {
	if (count >= data.selectedEndpoints.length) {
		dispatch(updateBulkNotesStatusEnded());
	} else {
		const updateData = {
			data: data.seNotes,
			targetICCID: data.selectedEndpoints[count].endpointName
		};
		const flag = data.selectedEndpoints[count].seNotes ? 'patch' : 'post';
		if (!data.selectedEndpoints[count].iccid) {
			dispatch(
				updateBulkNotesAddFilteredEndpoints({
					id: data.selectedEndpoints[count].endpointName
				})
			);
			dispatch(updateBulkNotes(data, count + 1));
		} else {
			dispatch(updateBulkNotesStatusRequest(updateData));
			setTimeout(() => {
				updateNote(
					data.selectedEndpoints[count].iccid,
					data.selectedEndpoints[count].m2mAccountId,
					{ seNote: data.seNotes },
					flag
				)
					.then(() => {
						dispatch(updateBulkNotesStatusSuccess(updateData));
						dispatch(updateBulkNotes(data, count + 1));
					})
					.catch(() => {
						dispatch(updateBulkNotesStatusFail(updateData));
						dispatch(updateBulkNotes(data, count + 1));
					});
			}, 1000);
			// }
		}
	}
};
