import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getSmsDetails = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/sms-details?${qs.stringify(
			params.params
		)}`
	);

export const getSmsDetailsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/sms-details/filters?${qs.stringify(params.params)}`
	);
