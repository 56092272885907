import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../List';

const Mobile = (props) => {
	const { data, options } = props;
	return (
		<div data-spec="entities-list-mobile">
			<Table
				data-spec="entities-table-mobile"
				data={data}
				options={options('mobile')}
				mobile
			/>
		</div>
	);
};

const { array, func } = PropTypes;

Mobile.propTypes = {
	data: array,
	options: func
};

export default hoc()(Mobile);
