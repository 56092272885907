import { createAction } from 'redux-actions';

import {
	SET_TICKET_FORM_PAGE_NEXT,
	SET_TICKET_FORM_PAGE_PREV,
	SET_TICKET_FORM_PAGE_INIT
} from './constants';

export const setTicketFormPageNext = createAction(SET_TICKET_FORM_PAGE_NEXT);
export const setTicketFormPagePrev = createAction(SET_TICKET_FORM_PAGE_PREV);
export const setTicketFormPageInit = createAction(SET_TICKET_FORM_PAGE_INIT);
