import React from 'react';
import { object } from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import {
	DesktopRatePlan,
	MobileRatePlan,
} from '../../components/SatelliteRatePlan';

export default function SatelliteRatePlanView({
	endpoint,
}) {
	return (
		<>
			<DesktopMediaQuery>
				<DesktopRatePlan
					endpoint={endpoint}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<DesktopRatePlan
					endpoint={endpoint}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<MobileRatePlan
					endpoint={endpoint}
				/>
			</MobileMediaQuery>
		</>
	);
}

SatelliteRatePlanView.propTypes = {
	endpoint: object,
};
SatelliteRatePlanView.defaultProps = {
	endpoint: {},
};
