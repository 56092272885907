import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import UserGroup from './UserGroup';
import { getUserGroup } from '../../redux/userGroups/actions';
import { getUserGroupRequest, getUserGroupSuccess } from '../../redux/userGroups/selectors';
import {
	getUser
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	userGroupRequest: getUserGroupRequest(state),
	userGroup: getUserGroupSuccess(state),
	currentUser: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	push: (url) => dispatch(localizedRoute(url)),
	getUserGroup: (params) => dispatch(getUserGroup(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserGroup);
