import { createAction } from 'redux-actions';

import globalAction from '../../../../redux/global/globalAction';

import {
	showNotification,
	hideNotification
} from '../../../Shared/redux/notifications/actions';

import {
	initiateAutomationZonesFileUpload as initiateAutomationZonesFileUploadService,
	automationZonesPollForFile as automationZonesPollForFileService,
	getAutomationZones as getAutomationZonesService,
	getUploadTemplate as getUploadTemplateService
} from '../../services/AutomationZonesService';

import {
	AUTOMATION_ZONES_MODAL,
	PATCH_FILE_UPLOAD_FAIL,
	PATCH_FILE_UPLOAD_REQUEST,
	PATCH_FILE_UPLOAD_INIT,
	PATCH_FILE_UPLOAD_SUCCESS,
	GET_AUTOMATION_ZONES,
	GET_UPLOAD_TEMPLATES
} from './constants';

/* Automation Zones File Upload */
const patchFileUploadInitAction = createAction(PATCH_FILE_UPLOAD_INIT);
const patchFileUploadRequest = createAction(PATCH_FILE_UPLOAD_REQUEST);
const patchFileUploadFail = createAction(PATCH_FILE_UPLOAD_FAIL);
const patchFileUploadSuccess = createAction(PATCH_FILE_UPLOAD_SUCCESS);

/* ******************** GET AUTOMATION ZONES ******************** */
export const getAutomationZones = (params) =>
	globalAction(GET_AUTOMATION_ZONES, getAutomationZonesService, params);

/* ******************** GET UPLOAD TEMPLATES ******************** */
export const downloadTemplate = () =>
	globalAction(GET_UPLOAD_TEMPLATES, getUploadTemplateService);

/* ******************** AUTOMATION ZONES MODAL ******************** */
export const openAutomationZonesModal = createAction(
	`OPEN_${AUTOMATION_ZONES_MODAL}`
);
export const closeAutomationZonesModal = createAction(
	`CLOSE_${AUTOMATION_ZONES_MODAL}`
);

/** ************ patch file ************* */
export const patchFileUpload = (
	id,
	requestUploadData,
	notifOptions = {}
) => async (dispatch) => {
	dispatch(patchFileUploadInitAction());
	try {
		const data = {
			requestUploadData
		};
		dispatch(patchFileUploadRequest());
		const file = await initiateAutomationZonesFileUploadService(id, data);
		const response = await automationZonesPollForFileService(
			file.id,
			200,
			4000
		);
		dispatch(patchFileUploadSuccess(response));
		// show notif success
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.successMessage],
					type: 'success',
					title: notifOptions.successTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
	} catch (error) {
		dispatch(patchFileUploadFail(error));
		// error notification
		if (notifOptions && notifOptions.id) {
			dispatch(
				showNotification({
					id: notifOptions.id,
					message: [notifOptions.errorMessage],
					type: 'error',
					title: notifOptions.errorTitle,
					close: true,
					duration: 5000,
					handleDismiss: () => dispatch(hideNotification(notifOptions.id))
				})
			);
		}
		// show notif error
	}
};
