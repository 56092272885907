import React from 'react';
import {
	bool,
	object,
	string,
} from 'prop-types';

import {
	CustomFieldsValuesDesktop,
	CustomFieldsValuesMobile,
} from '../../components/CustomFieldsValues';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';

export default function CustomFieldsValuesView({
	endpoint,
	customFieldLabels,
	type,
	renderAction,
	endpointName,
	inModal,
	showLoader,
	inlineFields,
}) {
	return (
		<>
			<DesktopMediaQuery>
				<CustomFieldsValuesDesktop
					endpoint={endpoint}
					customFieldLabels={customFieldLabels}
					type={type}
					renderAction={renderAction}
					endpointName={endpointName}
					inModal={inModal}
					showLoader={showLoader}
					inlineFields={inlineFields}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<CustomFieldsValuesDesktop
					endpoint={endpoint}
					customFieldLabels={customFieldLabels}
					type={type}
					renderAction={renderAction}
					endpointName={endpointName}
					inModal={inModal}
					showLoader={showLoader}
					inlineFields={inlineFields}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<CustomFieldsValuesMobile
					endpoint={endpoint}
					customFieldLabels={customFieldLabels}
					type={type}
					renderAction={renderAction}
					endpointName={endpointName}
					inModal={inModal}
					showLoader={showLoader}
					inlineFields={inlineFields}
				/>
			</MobileMediaQuery>
		</>
	);
}

CustomFieldsValuesView.propTypes = {
	endpoint: object,
	customFieldLabels: object,
	type: string,
	renderAction: bool,
	endpointName: string,
	inModal: bool,
	showLoader: bool,
	inlineFields: bool,
};
CustomFieldsValuesView.defaultProps = {
	endpoint: {},
	customFieldLabels: {},
	type: '',
	renderAction: false,
	endpointName: '',
	inModal: false,
	showLoader: false,
	inlineFields: false,
};
