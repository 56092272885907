import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import 'react-image-crop/dist/ReactCrop.css';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import UploadImageModal from '../UploadImageModal/UploadImageModal';
import styles from './UploadImage.scss';

const placeholderImage = ImageSelector(
	CurrentContext.theme,
	'user_image_placeholder.png'
);

export default class UploadImage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			src: null,
			openUploadModal: false,
			croppedImageUrl: null
		};
	}

	onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () =>
				this.setState({ src: reader.result }));

			reader.readAsDataURL(e.target.files[0]);
		}
		this.openUploadModal();
	};

	closeUploadModal = () => {
		this.setState({ openUploadModal: false, src: null });
		this.selectedImage.value = null;
	};

	openUploadModal = () => {
		this.setState({ openUploadModal: true });
	};

	onCropSave = (croppedImageUrl) => {
		const { updateLogo } = this.props;

		this.setState({ croppedImageUrl });
		updateLogo(croppedImageUrl);
	};

	render() {
		const { logo, customDefaultLogo, canChange } = this.props;
		const { croppedImageUrl, src, openUploadModal } = this.state;

		return (
			<div data-spec="upload-logo" className={styles.logo_wrapper}>
				<img
					alt="Crop"
					className={styles.logo_image}
					src={croppedImageUrl || logo || customDefaultLogo || placeholderImage}
				/>
				{canChange && (
					<div className={styles.file_upload_wrapper}>
						<label className={styles.custom_file_upload}>
							<input
								type="file"
								accept="image/*"
								onChange={this.onSelectFile}
								ref={(el) => {
									this.selectedImage = el;
								}}
							/>
							<FormattedMessage
								id="ONBOARDING.UPLOAD_IMAGE"
								defaultMessage="Upload Image"
							/>
						</label>
					</div>
				)}
				{src && (
					<UploadImageModal
						show={openUploadModal}
						onCancel={this.closeUploadModal}
						src={src}
						onClick={this.onCropSave}
					/>
				)}
			</div>
		);
	}
}

const { func, number, string, bool, oneOfType } = PropTypes;

UploadImage.propTypes = {
	updateLogo: func,
	logo: oneOfType([number, string]),
	customDefaultLogo: string,
	canChange: bool
};

UploadImage.defaultProps = {
	canChange: true
};
