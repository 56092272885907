import React from 'react';
import { object, string } from 'prop-types';

import { CardsDesktop, CardsMobile } from '../../components/Cards';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';

export default function Card({
	endpoint,
	type,
	user
}) {
	return (
		<>
			<DesktopMediaQuery key="card-query-desktop">
				<CardsDesktop
					endpoint={endpoint}
					type={type}
					user={user}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery key="card-query-tablet">
				<CardsMobile
					endpoint={endpoint}
					type={type}
					user={user}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery key="card-query-mobile">
				<CardsMobile
					endpoint={endpoint}
					type={type}
					user={user}
				/>
			</MobileMediaQuery>
		</>
	);
}

Card.propTypes = {
	endpoint: object,
	type: string,
	user: object,
};

Card.defaultProps = {
	endpoint: {},
	type: '',
	user: {},
};
