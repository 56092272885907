import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import StepThree from '../StepThree';
import StepFour from '../StepFour';
import StepFive from '../StepFive';

import PageTitle from '../../../Shared/views/PageTitleView';
import StepsIndicator from '../../../../lib/DigitalComponents/Steps/StepsIndicator';

import styles from './AddTicket.scss';

class AddTicket extends PureComponent {
	steps = [
		{ label: <FormattedMessage id="TICKETING.ISSUE" defaultMessage="Issue" /> },
		{
			label: (
				<FormattedMessage id="TICKETING.ENDPOINT" defaultMessage="Endpoint" />
			)
		},
		{
			label: (
				<FormattedMessage id="TICKETING.DETAILS" defaultMessage="Details" />
			)
		},
		{
			label: (
				<FormattedMessage id="TICKETING.CUSTOMER" defaultMessage="Customer" />
			)
		}
	];

	constructor(props) {
		super(props);
		this.nextPage = this.nextPage.bind(this);
		this.previousPage = this.previousPage.bind(this);
	}

	componentDidMount() {
		const { getCountriesStates, getConfirmation } = this.props;

		getCountriesStates({
			additionalParams: {
				dataSort: 'name ASC'
			}
		});
		getConfirmation({
			additionalParams: {
				dataSort: 'id ASC'
			}
		});
	}

	componentWillUnmount() {
		const {
			resetFiles,
			destroyForm,
			setEndpointTicketingInit,
			location,
			history
		} = this.props;

		resetFiles();
		if (location.pathname !== history.location.pathname) {
			destroyForm();
			setEndpointTicketingInit();
		}
	}

	onSubmit = (values) => {
		this.nextPage();
		this.submitForm(values);
	};

	submitForm = (values) => {
		const { fileSuccess, addTicket, intl } = this.props;
		const ticket = {
			ticketInsertRequest: {
				ticketSeverityId: values.ticketSeverityId.value,
				ticketCategoryId: values.ticketCategoryId.value,
				ticketPriorityId: values.ticketPriorityId.value,
				emailNotification: values.emailNotification,
				userInsertRequest: {
					email: values.user.uid || undefined
				},
				endpointIssueInsertRequest: {
					roamingIssue: false
				}
			}
		};
		if (values.roamingIssue) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.roamingIssue = true;
		}
		if (values.deviceLocationCity) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.deviceLocationCity =
				values.deviceLocationCity;
		}
		if (values.deviceLocationAddress) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.deviceLocationAddress =
				values.deviceLocationAddress;
		}
		if (values.zipCode) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.zipCode =
				values.zipCode;
		}
		if (values.issueStateId) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.issueStateId =
				values.issueStateId.value;
		}
		if (values.issueCountryId) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.issueCountryId =
				values.issueCountryId.value;
		}
		if (values.ticketFirstSubCategoryId) {
			ticket.ticketInsertRequest.ticketFirstSubCategoryId =
				values.ticketFirstSubCategoryId.value;
		}
		if (values.ticketSecondSubCategoryId) {
			ticket.ticketInsertRequest.ticketSecondSubCategoryId =
				values.ticketSecondSubCategoryId.value;
		}
		if (values.ticketThirdSubCategoryId) {
			ticket.ticketInsertRequest.ticketThirdSubCategoryId =
				values.ticketThirdSubCategoryId.value;
		}
		if (values.troubleshootingConfirmationId) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.troubleshootingConfirmationId =
			parseInt(
        values.troubleshootingConfirmationId.value,
        10
			);
		}

		if (values.endpointModel) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.endpointModel =
				values.endpointModel;
		}

		if (values.issue) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.issue =
				values.issue;
		}

		if (values.issueDetails) {
			ticket.ticketInsertRequest.endpointIssueInsertRequest.issueDetails =
				values.issueDetails;
		}

		if (values.endpoint) {
			if (values.endpoint.iccid) {
				ticket.ticketInsertRequest.endpointIssueInsertRequest.iccid =
					values.endpoint.iccid;
			}
			if (values.endpoint.imsi) {
				ticket.ticketInsertRequest.endpointIssueInsertRequest.imsi =
					values.endpoint.imsi;
			}
			if (values.endpoint.msisdn) {
				ticket.ticketInsertRequest.endpointIssueInsertRequest.msisdn =
					values.endpoint.msisdn;
			}
			if (values.endpoint.apn) {
				ticket.ticketInsertRequest.endpointIssueInsertRequest.apn =
					values.endpoint.apn;
			}
		}

		if (values.user && values.user.contactName) {
			ticket.ticketInsertRequest.userInsertRequest.contactName =
				values.user.contactName;
		}

		let fileInsertRequest = [];
		if (fileSuccess && fileSuccess.length > 0) {
			fileSuccess.forEach((item) => {
				fileInsertRequest = [
					...fileInsertRequest,
					{
						fileLocation: item.location
					}
				];
			});
		}
		if (fileInsertRequest.length > 0) {
			ticket.ticketInsertRequest.fileInsertRequest = fileInsertRequest;
		}

		addTicket(ticket, intl.formatMessage);
	};

	pushBack = () => {
		const { pushBack } = this.props;
		pushBack();
	};

	nextPage() {
		const { nextPage, removeError } = this.props;
		nextPage();
		removeError();
	}

	previousPage() {
		const { previousPage, removeError } = this.props;
		previousPage();
		removeError();
	}

	render() {
		const {
			currentFormPage,
			user,
			countries,
			states,
			confirmation,
			match: {
				params: { iccid, type }
			}
		} = this.props;

		return (
			<div data-spec="add-ticket">
				<PageTitle
					title={
						<FormattedMessage
							id="TICKETING.NEW_TICKET"
							defaultMessage="New Ticket"
						/>
					}
					pushBack={this.pushBack}
				/>

				<div className={styles.wrapper}>
					{currentFormPage < 5 && (
						<StepsIndicator steps={this.steps} nextStep={currentFormPage} />
					)}
					{currentFormPage === 1 && (
						<StepOne
							{...this.props}
							onSubmit={this.nextPage}
							user={user}
							iccid={iccid || null}
							type={type || null}
						/>
					)}
					{currentFormPage === 2 && (
						<StepTwo
							{...this.props}
							previousPage={this.previousPage}
							onSubmit={this.nextPage}
							countries={countries && countries.resultList}
							states={states && states.resultList}
						/>
					)}
					{currentFormPage === 3 && (
						<StepThree
							{...this.props}
							previousPage={this.previousPage}
							onSubmit={this.nextPage}
							confirmation={confirmation}
						/>
					)}
					{currentFormPage === 4 && (
						<StepFour
							{...this.props}
							previousPage={this.previousPage}
							onSubmit={this.onSubmit}
							user={user}
						/>
					)}
					{currentFormPage === 5 && <StepFive {...this.props} />}
				</div>
			</div>
		);
	}
}

const { object, func, number, shape, array, string, arrayOf } = PropTypes;

AddTicket.propTypes = {
	addTicket: func,
	pushBack: func,
	currentFormPage: number,
	match: shape({
		params: object
	}),
	countries: shape({
		resultList: array
	}),
	states: shape({
		resultList: array
	}),
	user: shape(),
	location: shape({
		pathname: string
	}),
	history: shape({
		location: shape({
			pathname: string
		})
	}),
	confirmation: shape({
		resultList: array
	}),
	resetFiles: func,
	destroyForm: func,
	nextPage: func,
	previousPage: func,
	fileSuccess: arrayOf(object),
	getCountriesStates: func,
	getConfirmation: func,
	setEndpointTicketingInit: func,
	setEndpointTicketingReset: func,
  removeError: func,
  intl: object
};

export default injectIntl(AddTicket);
