import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import { getLocale } from '../../../../utils/constants';

import {
	ACTIVATE_COMPANY,
	ADD_COMPANY,
	GET_COUNTRIES,
	GET_COMPANY,
	GET_STATES,
	GET_COMPANIES,
	GET_ADDRESS_TYPES,
	GET_CONTACT_TYPES,
	GET_MY_COMPANY,
	EDIT_MY_COMPANY,
	GET_COMPANY_LANGUAGES,
	GET_COMPANY_TOKEN,
	EDIT_COMPANY,
	VALIDATE_ADDRESS,
} from './constants';

import {
	activateCompany as activateCompanyService,
	addCompany as addCompanyService,
	getCountries as getCountriesService,
	getCompanies as getCompaniesService,
	getCompany as getCompanyService,
	getStates as getStatesService,
	getAddressTypes as getAddressTypesService,
	getContactTypes as getContactTypesService,
	getMyCompany as getMyCompanyService,
	editMyCompany as editMyCompanyService,
	getCompanyLanguages as getCompanyLanguagesService,
	getCompanyToken as getCompanyTokenService,
	editCompany as editCompanyService,
	validateAddress as validateAddressService
} from '../../services/CompaniesService';

import {
	showNotification,
	hideNotification
} from '../../../Shared/redux/notifications/actions';

import { requestUserData } from '../../../../redux/user/getUserData/actions';

/* ******************** ACTIVATE COMPANY ******************** */
export const activateCompany = (id) => async (dispatch) => {
	dispatch(hideNotification('company-integration-error'));
	dispatch(
		globalAction(ACTIVATE_COMPANY, activateCompanyService, id, {
			init: true
		})
	);
};

export const companyIntegrationError = (id) => async (dispatch) => {
	const locale = getLocale();
	const {
		default: {
			'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': message,
			'ONBOARDING.RETRY': retry
		}
	} = await import(`../../localization/${locale}`);

	dispatch(
		showNotification({
			id: 'company-integration-error',
			message: [message],
			type: 'error',
			notificationType: 'toaster',
			close: false,
			actionText: retry,
			action: () => dispatch(activateCompany(id))
		})
	);
};

export const companyIntegrationSuccess = () => async (dispatch) => {
	const locale = getLocale();
	const {
		default: { ACTION_COMPLETED_SUCCESSFULLY }
	} = await import(`../../../../localizations/${locale}`);

	dispatch(
		showNotification({
			id: 'company-integration-success',
			message: [ACTION_COMPLETED_SUCCESSFULLY],
			type: 'success',
			notificationType: 'toaster',
			close: true
		})
	);
};

/* ******************** ADD COMPANY ******************** */
export const addCompany = (params) =>
	globalAction(ADD_COMPANY, addCompanyService, params, {
		notify: 'add-company',
		init: true
	});

/* ******************** GET COUNTRIES ******************** */
export const getCountries = (params) =>
	globalAction(GET_COUNTRIES, getCountriesService, params);

/* ******************** GET COMPANIES******************** */
export const getCompanies = (params) =>
	globalAction(GET_COMPANIES, getCompaniesService, params);

/* ******************** GET COMPANY ******************** */
export const getCompany = (params) =>
	globalAction(GET_COMPANY, getCompanyService, params);

export const getCompanyInit = () => actionInit(GET_COMPANY);

/* ******************** GET STATES ******************** */
export const getStates = (params) =>
	globalAction(GET_STATES, getStatesService, params);

/* ******************** GET ADDRESS TYPE ******************** */
export const getAddressTypes = () =>
	globalAction(GET_ADDRESS_TYPES, getAddressTypesService);

/* ******************** GET CONTANCT TYPE ******************** */
export const getContactTypes = () =>
	globalAction(GET_CONTACT_TYPES, getContactTypesService);

/* ******************** GET MY COMPANY ******************** */
export const getMyCompany = (params) =>
	globalAction(GET_MY_COMPANY, getMyCompanyService, params);

/* ******************** GET MY COMPANY ******************** */
export const editMyCompany = (params) =>
	globalAction(EDIT_MY_COMPANY, editMyCompanyService, params, {
		notify: 'edit-company-logo',
		final: requestUserData()
	});

/* ******************** GET COMPANY LANGUAGES ******************** */
export const getCompanyLanguages = () =>
	globalAction(GET_COMPANY_LANGUAGES, getCompanyLanguagesService);

export const getCompanyToken = (params) =>
	globalAction(GET_COMPANY_TOKEN, getCompanyTokenService, params);

export const editCompany = (params, companyId) =>
	globalAction(EDIT_COMPANY, editCompanyService, { params, companyId }, {
		notify: 'edit-company-logo',
		final: requestUserData()
	});

/* ******************** GET SUGGESTED ADDRESS ******************** */
export const validateAddress = (data) =>
	globalAction(VALIDATE_ADDRESS, validateAddressService, data);
