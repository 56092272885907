import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Field,
	reduxForm,
	FieldArray,
	arrayPush,
	arrayRemove,
	touch
} from 'redux-form';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import { resetFields } from '../../../../../utils/constants';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import {
	required,
	email,
	maxLength
} from '../../../../../utils/validators';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';

import Dropzone from '../../../../Shared/components/UploadFile';
import Table from '../../../../../lib/DigitalComponents/Table';
import Input from '../../../../../lib/DigitalComponents/FieldGroup';
import Select from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Switch from '../../../../../lib/DigitalComponents/Switch';
import Button from '../../../../../lib/DigitalComponents/Button';
import TabsBar from '../../../../Shared/views/TabsBarView';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import EditNotesModal from '../../EditNotesModal';
import StaticField from '../../../../Shared/components/StaticField';
import { localizationHelper } from '../../../../../utils/helperFunctions';

import hoc from '../TicketDetails';

import styles from './Desktop.scss';
import NotesModal from '../../NotesModal';

const Edit = ImageSelector(CurrentContext.theme, 'svgs/pen.svg');
const RemoveIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');
const Document = ImageSelector(CurrentContext.theme, 'svgs/document.svg');
const Info = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');

const RWComponent = UserAllowedToAccess(['mnc.ticketing_rw']);

const maxLength2000 = maxLength(2000);
const maxLength60 = maxLength(60);
const maxLength140 = maxLength(140);

class Desktop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstColumnStatic: true,
			secondColumnStatic: true,
			thirdColumnStatic: true,
			fourthColumnStatic: true,
			deletedFiles: [],
			isNoteModalOpen: false,
			isAddingNote: false,
			openProblemDescriptionModal: false,
			openIssueDetailsModal: false,
		};
	}

	componentDidMount() {
		const { ticketDetails } = this.props;
		if (ticketDetails && !ticketDetails.insertTicketFile) {
			// Setting [] as default value so pristine will work accordingly
			ticketDetails.insertTicketFile = [];
		}
	}

	componentDidUpdate(prevProps) {
		const { fileSuccess, dispatch } = this.props;
		if (fileSuccess !== prevProps.fileSuccess) {
			if (fileSuccess.length > 0) {
				fileSuccess.forEach((item) =>
					dispatch(arrayPush('TicketDetailsForm', 'insertTicketFile', item)));
			}
		}
	}

	onSubmit = (values) => {
		const { deletedFiles } = this.state;
		const { editTicket, editTicketInit, intl } = this.props;
		let ticket = {
			ticketSeverityId:
				values.ticketSeverityId.value || values.ticketSeverityId,
			ticketCategoryId:
				values.ticketCategoryId.value || values.ticketCategoryId,
			ticketPriorityId:
				values.ticketPriorityId.value || values.ticketPriorityId,
			endpointIssueUpdateRequest: {},
			userUpdateRequest: {},
			ticketFirstSubCategoryId: null,
			ticketSecondSubCategoryId: null,
			ticketThirdSubCategoryId: null,
			emailNotification: values.emailNotification
		};

		if (values.note) {
			ticket.notes = values.note;
		}

		if (values.ticketFirstSubCategoryId) {
			ticket.ticketFirstSubCategoryId =
				values.ticketFirstSubCategoryId.value ||
				values.ticketFirstSubCategoryId;
		}
		if (values.ticketSecondSubCategoryId) {
			ticket.ticketSecondSubCategoryId =
				values.ticketSecondSubCategoryId.value ||
				values.ticketSecondSubCategoryId;
		}
		if (values.ticketThirdSubCategoryId) {
			ticket.ticketThirdSubCategoryId =
				values.ticketThirdSubCategoryId.value ||
				values.ticketThirdSubCategoryId;
		}

		if (values.endpointIssue) {
			ticket.endpointIssueUpdateRequest.roamingIssue =
				values.endpointIssue.roamingIssue;

			if (values.endpointIssue.endpointModel) {
				ticket.endpointIssueUpdateRequest.endpointModel =
					values.endpointIssue.endpointModel;
			}

			if (values.endpointIssue.issue) {
				ticket.endpointIssueUpdateRequest.issue = values.endpointIssue.issue;
			}

			if (values.endpointIssue.issueDetails) {
				ticket.endpointIssueUpdateRequest.issueDetails =
					values.endpointIssue.issueDetails;
			}

			if (values.endpointIssue.troubleshootingConfirmationId) {
				ticket.endpointIssueUpdateRequest.troubleshootingConfirmationId =
					values.endpointIssue.troubleshootingConfirmationId.value ||
					values.endpointIssue.troubleshootingConfirmationId;
			}

			if (values.endpointIssue.deviceLocationAddress) {
				ticket.endpointIssueUpdateRequest.deviceLocationAddress =
					values.endpointIssue.deviceLocationAddress;
			}

			if (values.endpointIssue.deviceLocationCity) {
				ticket.endpointIssueUpdateRequest.deviceLocationCity =
					values.endpointIssue.deviceLocationCity;
			}

			if (values.endpointIssue.zipCode) {
				ticket.endpointIssueUpdateRequest.zipCode =
					values.endpointIssue.zipCode;
			}

			if (values.endpointIssue.country && values.endpointIssue.country.id) {
				ticket.endpointIssueUpdateRequest.issueCountryId =
					values.endpointIssue.country.id.value ||
					values.endpointIssue.country.id;
			}

			if (values.endpointIssue.state && values.endpointIssue.state.id) {
				ticket.endpointIssueUpdateRequest.issueStateId =
					values.endpointIssue.state.id.value || values.endpointIssue.state.id;
			}
		}

		if (values.user) {
			if (values.user.email) {
				ticket.userUpdateRequest.email = values.user.email;
			}

			if (values.user.contactName) {
				ticket.userUpdateRequest.contactName = values.user.contactName;
			}
		}

		let fileInsertRequest = [];
		let fileDeleteRequest = [];

		if (values.insertTicketFile && values.insertTicketFile.length > 0) {
			values.insertTicketFile.forEach((item) => {
				fileInsertRequest = [
					...fileInsertRequest,
					{
						fileLocation: item.location
					}
				];
			});
		}

		if (deletedFiles && deletedFiles.length > 0) {
			deletedFiles.forEach((item) => {
				fileDeleteRequest = [
					...fileDeleteRequest,
					{
						attachmentId: item.attachmentId
					}
				];
			});
		}
		if (fileDeleteRequest.length > 0) {
			ticket = {
				...ticket,
				fileDeleteRequest
			};
		}

		if (fileInsertRequest.length > 0) {
			ticket = {
				...ticket,
				fileInsertRequest
			};
		}

		editTicketInit();
		editTicket({ id: values.id, ticket }, intl.formatMessage);
		this.setState({ deletedFiles: [] });
	};

	downloadFile = (file) => {
		const { fileName, attachmentId } = file;
		const { fileDownloadTicket } = this.props;
		const fileRequest = {
			service: 'ticketing',
			fileName,
			transactionType: 'basicTicketing',
			attachmentId
		};
		fileDownloadTicket(fileRequest);
	};

	renderTicketFiles = ({ fields }) => {
		const { fileDownload } = this.props;
		const { thirdColumnStatic, deletedFiles } = this.state;
		return fileDownload ? (
			<Loader data-spec={'file-download'} />
		) : (
			<div>
				{fields.map((item, index) => (
					<div key={item}>
						{fields.get(index).id && (
							<div className={styles.existing_files}>
								<span
									className={styles.existing_files_name}
									onClick={() => this.downloadFile(fields.get(index))}
								>
									<Document />
									{fields.get(index).fileName}
								</span>
								<span className={styles.existing_files_button}>
									{!thirdColumnStatic && (
										<RemoveIcon
											onClick={() => {
												fields.remove(index);
												this.setState({
													deletedFiles: [...deletedFiles, fields.get(index)]
												});
											}}
										/>
									)}
								</span>
							</div>
						)}
					</div>
				))}
			</div>
		);
	};

	onCloseResetFields = () => {
		const { dispatch } = this.props;
		resetFields(dispatch, 'EditNoteModalForm', {
			note: ''
		});
		this.setState({
			isNoteModalOpen: false
		});
	};

	onNoteClick = (isAddingNote = false) => {
		this.setState({
			isAddingNote,
			isNoteModalOpen: true
		});
	};

	validateUpload = (file) => file.name.length > 25;

	closeShowMoreModal = () => this.setState({
		openProblemDescriptionModal: false,
		openIssueDetailsModal: false,
	})

	render() {
		const {
			handleSubmit,
			initialValues,
			formValues,
			ticketDetails,
			getOptions,
			getDateTimeFormat,
			categories,
			firstSubCategories,
			secondSubCategories,
			thirdSubCategories,
			severities,
			priorities,
			countries,
			states,
			confirmation,
			history,
			getFirstSubCategories,
			getSecondSubCategories,
			getThirdSubCategories,
			pristine,
			invalid,
			filesUploading,
			selectedHistory,
			user,
			dispatch,
			resetFiles,
			initialize,
			intl
		} = this.props;
		const {
			firstColumnStatic,
			secondColumnStatic,
			thirdColumnStatic,
			fourthColumnStatic,
			isNoteModalOpen,
			isAddingNote,
			openProblemDescriptionModal,
			openIssueDetailsModal,
		} = this.state;
		const triggerClass = cn({
			[styles.dropzone_trigger]: true,
			[styles.dropzone__active]: !thirdColumnStatic
		});

		return (
			<div data-spec="ticket-details-desktop">
				<div className={styles.row_wrapper}>
					<div className={styles.row}>
						<h4 className={styles.title_row}>
							<FormattedMessage
								id="TICKETING.TICKET_ID"
								defaultMessage="Ticket ID"
							/>
						</h4>
						<div className={styles.detail_holder}>
							{ticketDetails.externalTicketId}
						</div>
					</div>
					<div className={styles.row}>
						<h4 className={styles.title_row}>
							<FormattedMessage id="TICKETING.STATUS" defaultMessage="Status" />
						</h4>
						<div className={styles.detail_holder}>
							{ticketDetails.status.name}
						</div>
					</div>
					<div className={styles.row}>
						<h4 className={styles.title_row}>
							<FormattedMessage
								id="TICKETING.SUBMITED_BY"
								defaultMessage="Submited By"
							/>
						</h4>
						<div className={styles.detail_holder}>
							{ticketDetails.userId}
							<br />
							{getDateTimeFormat(ticketDetails.createdAt)}
						</div>
					</div>
				</div>
				<hr className={styles.divider} />
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<div className={styles.row_wrapper}>
						<div className={cn(styles.row, styles.border_right)}>
							<h4
								className={cn(
									styles.to_uppercase,
									styles.title,
									styles.title_margin,
									styles.title_edit
								)}
							>
								<FormattedMessage id="TICKETING.ISSUE" defaultMessage="Issue" />
								{firstColumnStatic &&
									!(
										ticketDetails.status.name === 'Closed' ||
										ticketDetails.status.name === 'Cancel'
									) && (
										<RWComponent>
											<Edit
												onClick={() =>
													this.setState({ firstColumnStatic: false })}
											/>
										</RWComponent>
									)}
							</h4>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.CATEGORY"
									defaultMessage="Category"
								>
									{(formattedValue) =>
										(firstColumnStatic ? (
											<StaticField
												labelId="TICKETING.CATEGORY"
												defaultMessage="Category"
												value={
													ticketDetails &&
													ticketDetails.category &&
													ticketDetails.category.name
														? intl.formatMessage({
																id: `TICKETING.CATEGORY_${localizationHelper(
																	ticketDetails.category.name
																).toUpperCase()}`,
																defaultMessage:
																	ticketDetails.category.name
														  })
														: ''
												}
												dataSpec="ticketing-category"
												label={formattedValue}
											/>
										) : (
											<Field
												component={Select}
												placeholder={formattedValue}
												label={formattedValue}
												options={
													categories &&
													categories.resultList &&
													categories.resultList.map((item) => ({
														value: item.id,
														label: (
															<FormattedMessage
																id={`TICKETING.CATEGORY_${localizationHelper(
																	item.name
																).toUpperCase()}`}
																defaultMessage={item.name}
															/>
														)
													}))
												}
												onChange={(item) => {
													getFirstSubCategories(item.value);
													resetFields(dispatch, 'TicketDetailsForm', {
														ticketFirstSubCategoryId: '',
														ticketSecondSubCategoryId: '',
														ticketThirdSubCategoryId: ''
													});
												}}
												name="ticketCategoryId"
												dataSpec="category"
												validate={[required]}
												cleanValue
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.SUBCATEGORY_OPTIONAL"
									defaultMessage="Subcategory (optional)"
								>
									{(formattedValue) =>
										(firstColumnStatic ? (
											<StaticField
												labelId="TICKETING.SUBCATEGORY_OPTIONAL"
												defaultMessage="Subcategory (optional)"
												value={
													ticketDetails &&
													ticketDetails.firstSubCategory &&
													ticketDetails.firstSubCategory.name
														? intl.formatMessage({
																id: `TICKETING.${localizationHelper(
																	ticketDetails.firstSubCategory.name
																).toUpperCase()}`,
																defaultMessage:
																	ticketDetails.firstSubCategory.name
														  })
														: ''
												}
												dataSpec="ticketing-first-subcategory"
												label={formattedValue}
											/>
										) : (
											<Field
												component={Select}
												placeholder={formattedValue}
												label={formattedValue}
												options={
													firstSubCategories &&
													firstSubCategories.resultList &&
													firstSubCategories.resultList.map((item) => ({
														value: item.id,
														label: (
															<FormattedMessage
																id={`TICKETING.${localizationHelper(
																	item.name
																).toUpperCase()}`}
																defaultMessage={item.name}
															/>
														)
													}))
												}
												onChange={(item) => {
													getSecondSubCategories(item.value);
													resetFields(dispatch, 'TicketDetailsForm', {
														ticketSecondSubCategoryId: '',
														ticketThirdSubCategoryId: ''
													});
												}}
												name="ticketFirstSubCategoryId"
												dataSpec="firstSubcategory"
												cleanValue
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.SUBCATEGORY_OPTIONAL"
									defaultMessage="Subcategory (optional)"
								>
									{(formattedValue) =>
										(firstColumnStatic ? (
											<StaticField
												labelId="TICKETING.SUBCATEGORY_OPTIONAL"
												defaultMessage="Subcategory (optional)"
												value={
													ticketDetails &&
													ticketDetails.secondSubCategory &&
													ticketDetails.secondSubCategory.name
														? intl.formatMessage({
																id: `TICKETING.${localizationHelper(
																	ticketDetails.secondSubCategory.name
																).toUpperCase()}`,
																defaultMessage:
																	ticketDetails.secondSubCategory.name
														  })
														: ''
												}
												dataSpec="ticketing-second-subcategory"
												label={formattedValue}
											/>
										) : (
											<Field
												component={Select}
												placeholder={formattedValue}
												label={formattedValue}
												isDisabled={!secondSubCategories}
												options={
													secondSubCategories &&
													secondSubCategories.resultList &&
													secondSubCategories.resultList.map((item) => ({
														value: item.id,
														label: (
															<FormattedMessage
																id={`TICKETING.${localizationHelper(
																	item.name
																).toUpperCase()}`}
																defaultMessage={item.name}
															/>
														)
													}))
												}
												onChange={(item) => {
													getThirdSubCategories(item.value);
													resetFields(dispatch, 'TicketDetailsForm', {
														ticketThirdSubCategoryId: ''
													});
												}}
												name="ticketSecondSubCategoryId"
												dataSpec="secondSubcategory"
												cleanValue
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.SUBCATEGORY_OPTIONAL"
									defaultMessage="Subcategory (optional)"
								>
									{(formattedValue) =>
										(firstColumnStatic ? (
											<StaticField
												labelId="TICKETING.SUBCATEGORY_OPTIONAL"
												defaultMessage="Subcategory (optional)"
												value={
													ticketDetails &&
													ticketDetails.thirdSubCategory &&
													ticketDetails.thirdSubCategory.name
														? intl.formatMessage({
																id: `TICKETING.${localizationHelper(
																	ticketDetails.thirdSubCategory.name
																).toUpperCase()}`,
																defaultMessage:
																	ticketDetails.thirdSubCategory.name
														  })
														: ''
												}
												dataSpec="ticketing-third-subcategory"
												label={formattedValue}
											/>
										) : (
											<Field
												component={Select}
												placeholder={formattedValue}
												label={formattedValue}
												isDisabled={!thirdSubCategories}
												options={
													thirdSubCategories &&
													thirdSubCategories.resultList &&
													thirdSubCategories.resultList.map((item) => ({
														value: item.id,
														label: (
															<FormattedMessage
																id={`TICKETING.${localizationHelper(
																	item.name
																).toUpperCase()}`}
																defaultMessage={item.name}
															/>
														)
													}))
												}
												name="ticketThirdSubCategoryId"
												dataSpec="thirdSubcategory"
												cleanValue
											/>
										))}
								</FormattedMessage>
							</div>
							{!firstColumnStatic && (
								<div className={styles.block_message}>
									<div className={styles.block_title}>
										<FormattedMessage
											id="TICKETING.ROAMING_ISSUE"
											defaultMessage="Roaming Issue"
										/>
									</div>
									<Field
										component={Switch}
										name="endpointIssue.roamingIssue"
										label={
											formValues.endpointIssue.roamingIssue === true ? (
												<FormattedMessage id="YES" defaultMessage="Yes" />
											) : (
												<FormattedMessage id="NO" defaultMessage="No" />
											)
										}
									/>
								</div>
							)}
							{firstColumnStatic && (
								<div className={styles.block_message}>
									<div className={styles.block_title}>
										<FormattedMessage
											id="TICKETING.ROAMING_ISSUE"
											defaultMessage="Roaming Issue"
										/>
									</div>
									<div className={styles.block_data}>
										{ticketDetails.endpointIssue.roamingIssue === true && (
											<FormattedMessage id="YES" defaultMessage="Yes" />
										)}
										{ticketDetails.endpointIssue.roamingIssue === false && (
											<FormattedMessage id="NO" defaultMessage="No" />
										)}
									</div>
								</div>
							)}
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.SEVERITY"
									defaultMessage="Severity"
								>
									{(formattedValue) => (
										<Field
											component={Select}
											placeholder={formattedValue}
											label={formattedValue}
											options={
												severities &&
												severities.resultList &&
												severities.resultList.map((item) => ({
													value: item.id,
													label: (
														<FormattedMessage
															id={`TICKETING.${item.name.toUpperCase()}`}
															defaultMessage={item.name}
														/>
													)
												}))
											}
											static={firstColumnStatic}
											name="ticketSeverityId"
											dataSpec="ticketSeverityId"
											validate={[required]}
											cleanValue
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.PRIORITY"
									defaultMessage="Priority"
								>
									{(formattedValue) => (
										<Field
											component={Select}
											placeholder={formattedValue}
											label={formattedValue}
											options={
												priorities &&
												priorities.resultList &&
												priorities.resultList.map((item) => ({
													value: item.id,
													label: (
														<FormattedMessage
															id={`TICKETING.${item.name.toUpperCase()}`}
															defaultMessage={item.name}
														/>
													)
												}))
											}
											static={firstColumnStatic}
											name="ticketPriorityId"
											dataSpec="ticketPriorityId"
											validate={[required]}
											cleanValue
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
						<div className={cn(styles.row, styles.border_right)}>
							<h4
								className={cn(
									styles.to_uppercase,
									styles.title,
									styles.title_margin,
									styles.title_edit
								)}
							>
								<FormattedMessage
									id="TICKETING.ENDPOINT"
									defaultMessage="Endpoint"
								/>
								{secondColumnStatic &&
									!(
										ticketDetails.status.name === 'Closed' ||
										ticketDetails.status.name === 'Cancel'
									) && (
										<RWComponent>
											<Edit
												onClick={() =>
													this.setState({ secondColumnStatic: false })}
											/>
										</RWComponent>
									)}
							</h4>
							<div className={styles.block_message}>
								<FormattedMessage id="TICKETING.ICCID" defaultMessage="ICCID">
									{(formattedValue) =>
										(secondColumnStatic ? (
											<StaticField
												labelId="TICKETING.ICCID"
												defaultMessage="ICCID"
												value={
													formValues &&
													formValues.endpointIssue &&
													formValues.endpointIssue.iccid
												}
												dataSpec="ticketing-iccid"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.iccid"
												dataSpec="iccid"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage id="TICKETING.IMSI" defaultMessage="IMSI">
									{(formattedValue) =>
										(secondColumnStatic ? (
											<StaticField
												labelId="TICKETING.IMSI"
												defaultMessage="IMSI"
												value={
													formValues &&
													formValues.endpointIssue &&
													formValues.endpointIssue.imsi
												}
												dataSpec="ticketing-imsi"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.imsi"
												dataSpec="imsi"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage id="TICKETING.MSISDN" defaultMessage="MSISDN">
									{(formattedValue) =>
										(secondColumnStatic ? (
											<StaticField
												labelId="TICKETING.MSISDN"
												defaultMessage="MSISDN"
												value={
													formValues &&
													formValues.endpointIssue &&
													formValues.endpointIssue.msisdn
												}
												dataSpec="ticketing-msisdn"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.msisdn"
												dataSpec="msisdn"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage id="TICKETING.APN" defaultMessage="APN">
									{(formattedValue) =>
										(secondColumnStatic ? (
											<StaticField
												labelId="TICKETING.APN"
												defaultMessage="APN"
												value={
													formValues &&
													formValues.endpointIssue &&
													formValues.endpointIssue.apn
												}
												dataSpec="ticketing-apn"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.apn"
												dataSpec="apn"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							{secondColumnStatic && (
								<div className={styles.block_message}>
									<div className={styles.block_title}>
										<FormattedMessage
											id="TICKETING.ENDPOINT_LOCATION"
											defaultMessage="Endpoint Location"
										/>
									</div>
									{formValues && formValues.endpointIssue && (
										<div className={styles.block_data}>
											{formValues.endpointIssue.deviceLocationAddress
												? formValues.endpointIssue.deviceLocationAddress
												: '-'}
											<div>
												{formValues.endpointIssue.deviceLocationCity
													? `${formValues.endpointIssue.deviceLocationCity},`
													: ''}
												&nbsp;
												{formValues.endpointIssue.state
													? formValues.endpointIssue.state.code
													: ''}
												&nbsp;
												{formValues.endpointIssue.zipCode
													? formValues.endpointIssue.zipCode
													: ''}
											</div>
											<div>
												{formValues.endpointIssue.country
													? formValues.endpointIssue.country.name
													: ''}
											</div>
										</div>
									)}
								</div>
							)}

							{!secondColumnStatic && (
								<div>
									<div className={styles.block_message}>
										<FormattedMessage
											id="TICKETING.ENDPOINT_COUNTRY"
											defaultMessage="Endpoint Country"
										>
											{(formattedValue) => (
												<Field
													component={Select}
													placeholder={formattedValue}
													label={formattedValue}
													options={
														countries &&
														countries.resultList &&
														countries.resultList.map((item) => ({
															value: item.id,
															label: item.name
														}))
													}
													name="endpointIssue.country.id"
													dataSpec="country"
													cleanValue
												/>
											)}
										</FormattedMessage>
									</div>
									<div className={styles.block_message}>
										<FormattedMessage
											id="TICKETING.ENDPOINT_STREET_ADDRESS"
											defaultMessage="Endpoint Street Address"
										>
											{(formattedValue) => (
												<Field
													component={Input}
													placeholder={formattedValue}
													label={formattedValue}
													name="endpointIssue.deviceLocationAddress"
													dataSpec="deviceLocationAddress"
													validate={[maxLength140]}
												/>
											)}
										</FormattedMessage>
									</div>
									<div className={styles.block_message}>
										<FormattedMessage
											id="TICKETING.ENDPOINT_CITY"
											defaultMessage="Endpoint City"
										>
											{(formattedValue) => (
												<Field
													component={Input}
													placeholder={formattedValue}
													label={formattedValue}
													name="endpointIssue.deviceLocationCity"
													dataSpec="deviceLocationCity"
													validate={[maxLength60]}
												/>
											)}
										</FormattedMessage>
									</div>
									<div className={styles.block_message}>
										<FormattedMessage
											id="TICKETING.ENDPOINT_STATE"
											defaultMessage="Endpoint State"
										>
											{(formattedValue) => (
												<Field
													component={Select}
													placeholder={formattedValue}
													label={formattedValue}
													options={
														states &&
														states.resultList &&
														states.resultList.map((item) => ({
															value: item.id,
															label: item.name
														}))
													}
													name="endpointIssue.state.id"
													dataSpec="state"
													cleanValue
												/>
											)}
										</FormattedMessage>
									</div>
									<div className={styles.block_message}>
										<FormattedMessage
											id="TICKETING.ZIP_CODE"
											defaultMessage="ZIP Code"
										>
											{(formattedValue) => (
												<Field
													component={Input}
													placeholder={formattedValue}
													label={formattedValue}
													name="endpointIssue.zipCode"
													dataSpec="zipCode"
												/>
											)}
										</FormattedMessage>
									</div>
								</div>
							)}
						</div>
						<div className={cn(styles.row, styles.border_right)}>
							<h4
								className={cn(
									styles.to_uppercase,
									styles.title,
									styles.title_margin,
									styles.title_edit
								)}
							>
								<FormattedMessage
									id="TICKETING.DETAILS"
									defaultMessage="Details"
								/>
								{thirdColumnStatic &&
									!(
										ticketDetails.status.name === 'Closed' ||
										ticketDetails.status.name === 'Cancel'
									) && (
										<RWComponent>
											<Edit
												onClick={() =>
													this.setState({ thirdColumnStatic: false })}
											/>
										</RWComponent>
									)}
							</h4>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_MODEL"
									defaultMessage="Endpoint model"
								>
									{(formattedValue) =>
										(thirdColumnStatic ? (
											<StaticField
												labelId="TICKETING.ENDPOINT_MODEL"
												defaultMessage="Endpoint model"
												value={
													formValues &&
													formValues.endpointIssue &&
													formValues.endpointIssue.endpointModel
												}
												dataSpec="ticketing-endpointModel"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.endpointModel"
												dataSpec="endpointModel"
											/>
										))}
								</FormattedMessage>
							</div>

							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.WAS_TROUBLESHOOTING_DONE"
									defaultMessage="Was troubleshooting done?"
								>
									{(formattedValue) => (
										<Field
											component={Select}
											placeholder={formattedValue}
											label={formattedValue}
											options={
												confirmation &&
												confirmation.resultList &&
												confirmation.resultList.map((item) => ({
													value: item.id,
													label: (
														<FormattedMessage
															id={`TICKETING.${localizationHelper(item.name)}`}
															defaultMessage={item.name}
														/>
													)
												}))
											}
											static={thirdColumnStatic}
											name="endpointIssue.troubleshootingConfirmationId"
											dataSpec="troubleshootingConfirmationId"
											cleanValue
										/>
									)}
								</FormattedMessage>
							</div>

							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.PROBLEM_DESCRIPTION"
									defaultMessage="Problem Description"
								>
									{(formattedValue) =>
										(thirdColumnStatic ? (
											<div className={styles.show_more}>
												<StaticField
													labelId="TICKETING.PROBLEM_DESCRIPTION"
													defaultMessage="Problem description"
													value={
														formValues &&
														formValues.endpointIssue &&
														formValues.endpointIssue.issue
													}
													dataSpec="ticketing-issue"
													isPre
													customValueClass={styles.static_field}
												/>
												<Button
													variant="link"
													label={
														<FormattedMessage
															id="DETAILS"
															defaultMessage="DETAILS"
														/>
													}
													onClick={() =>
														this.setState({
															openProblemDescriptionModal: true
														})}
													type="button"
													className={styles.show_more_button}
												/>
											</div>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.issue"
												dataSpec="issue"
												componentClass="textarea"
												staticClassName={styles.overflow_fix}
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.ISSUE_DETAILS"
									defaultMessage="Issue Details"
								>
									{(formattedValue) =>
										(thirdColumnStatic ? (
											<div className={styles.show_more}>
												<StaticField
													labelId="TICKETING.ISSUE_DETAILS"
													defaultMessage="Issue details"
													value={
														formValues &&
														formValues.endpointIssue &&
														formValues.endpointIssue.issueDetails
													}
													dataSpec="ticketing-issue-details"
													isPre
													customValueClass={styles.static_field}
												/>
												<Button
													variant="link"
													label={
														<FormattedMessage
															id="DETAILS"
															defaultMessage="DETAILS"
														/>
													}
													onClick={() =>
														this.setState({
															openIssueDetailsModal: true
														})}
													type="button"
													className={styles.show_more_button}
												/>
											</div>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="endpointIssue.issueDetails"
												dataSpec="issueDetails"
												componentClass="textarea"
												validate={[required, maxLength2000]}
												staticClassName={styles.overflow_fix}
											/>
										))}
								</FormattedMessage>
							</div>
							{
								<div className={styles.block_message}>
									<div className={styles.block_title}>
										<FormattedMessage
											id="TICKETING.ATTACH_FILE"
											defaultMessage="Attach File"
										/>
										<span className={styles.tooltip_wrapper}>
											<FormattedMessage
												id="TICKETING.ATTACH_FILE_TOOLTIP"
												defaultMessage="File name should not contain empty spaces."
											>
												{(formattedValue) => (
													<Info
														data-spec="attach"
														data-tip={formattedValue}
														data-for="attach"
													/>
												)}
											</FormattedMessage>

											<ReactTooltip
												className={styles.tooltip}
												type="light"
												id={'attach'}
											/>
										</span>
									</div>
									<div>
										{thirdColumnStatic && (
											<div>
												<FieldArray
													name={'ticketFile'}
													component={this.renderTicketFiles}
												/>
											</div>
										)}
										{!thirdColumnStatic && (
											<div>
												<FieldArray
													name={'ticketFile'}
													component={this.renderTicketFiles}
												/>
												<div className={triggerClass}>
													<Dropzone
														resetFiles={resetFiles}
														service="ticketing"
														transactionType="basicTicketing"
														removeFileFromForm={(i) =>
															dispatch(
																arrayRemove(
																	'TicketDetailsForm',
																	'insertTicketFile',
																	i
																)
															)}
														validateUpload={this.validateUpload}
														validationFailMessage={
															<FormattedMessage
																id="TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG"
																defaultMessage="File name is too long for upload. Maximum file name length is 25 characters."
															/>
														}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							}
						</div>
						<div className={cn(styles.row, styles.border_right)}>
							<h4
								className={cn(
									styles.to_uppercase,
									styles.title,
									styles.title_margin,
									styles.title_edit
								)}
							>
								<FormattedMessage
									id="TICKETING.CUSTOMER"
									defaultMessage="Customer"
								/>
								{fourthColumnStatic &&
									!(
										ticketDetails.status.name === 'Closed' ||
										ticketDetails.status.name === 'Cancel'
									) && (
										<RWComponent>
											<Edit
												onClick={() => {
													this.setState({ fourthColumnStatic: false });
													dispatch(touch('TicketDetailsForm'));
												}}
											/>
										</RWComponent>
									)}
							</h4>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.ACCOUNT_NAME"
									defaultMessage="Account Name"
								>
									{(formattedValue) =>
										(fourthColumnStatic ? (
											<StaticField
												labelId="TICKETING.ACCOUNT_NAME"
												defaultMessage="Account name"
												value={formValues && formValues.accountName}
												dataSpec="ticketing-account-name"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="accountName"
												dataSpec="accountName"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.ACCOUNT_NUMBER"
									defaultMessage="Account Number"
								>
									{(formattedValue) =>
										(fourthColumnStatic ? (
											<StaticField
												labelId="TICKETING.ACCOUNT_NUMBER"
												defaultMessage="Account number"
												value={formValues && formValues.accountId}
												dataSpec="ticketing-account-id"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="accountId"
												dataSpec="accountNumber"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.CREATED_BY"
									defaultMessage="Created By"
								>
									{(formattedValue) =>
										(fourthColumnStatic ? (
											<StaticField
												labelId="TICKETING.CREATED_BY"
												defaultMessage="Created by"
												value={formValues && formValues.userId}
												dataSpec="ticketing-user-id"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="userId"
												dataSpec="accountNumber"
												disabled
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage
									id="TICKETING.CONTACT_NAME"
									defaultMessage="Contact name"
								>
									{(formattedValue) =>
										(fourthColumnStatic ? (
											<StaticField
												labelId="TICKETING.CONTACT_NAME"
												defaultMessage="Contact name"
												value={
													formValues &&
													formValues.user &&
													formValues.user.contactName
												}
												dataSpec="ticketing-contact-name"
											/>
										) : (
											<Field
												component={Input}
												dataSpec="contact-name"
												name="user.contactName"
												placeholder={formattedValue}
												label={formattedValue}
											/>
										))}
								</FormattedMessage>
							</div>
							<div className={styles.block_message}>
								<FormattedMessage id="TICKETING.EMAIL" defaultMessage="Email">
									{(formattedValue) =>
										(fourthColumnStatic ? (
											<StaticField
												labelId="TICKETING.EMAIL"
												defaultMessage="Email"
												value={
													formValues && formValues.user && formValues.user.email
												}
												dataSpec="ticketing-email"
											/>
										) : (
											<Field
												component={Input}
												placeholder={formattedValue}
												label={formattedValue}
												name="user.email"
												dataSpec="email"
												validate={[email]}
											/>
										))}
								</FormattedMessage>
							</div>

							{!fourthColumnStatic && (
								<div className={styles.block_message}>
									<div className={styles.block_title}>
										<FormattedMessage
											id="TICKETING.EMAIL_NOTIFICATIONS"
											defaultMessage="Email notifications"
										/>
									</div>
									<Field
										component={Switch}
										name="emailNotification"
										label={
											formValues.emailNotification === true ? (
												<FormattedMessage
													id="TICKETING.ON"
													defaultMessage="On"
												/>
											) : (
												<FormattedMessage
													id="TICKETING.OFF"
													defaultMessage="Off"
												/>
											)
										}
									/>
								</div>
							)}
							{fourthColumnStatic && (
								<div className={styles.block_message}>
									<div className={styles.block_title}>
										<FormattedMessage
											id="TICKETING.EMAIL_NOTIFICATIONS"
											defaultMessage="Email notifications"
										/>
									</div>
									<div className={styles.block_data}>
										{ticketDetails.emailNotification === true && (
											<FormattedMessage id="TICKETING.ON" defaultMessage="On" />
										)}
										{ticketDetails.emailNotification === false && (
											<FormattedMessage
												id="TICKETING.OFF"
												defaultMessage="Off"
											/>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					{(!firstColumnStatic ||
						!secondColumnStatic ||
						!thirdColumnStatic ||
						!fourthColumnStatic) && (
						<div className={styles.section_buttons}>
							<Button
								label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
								variant="outline-primary"
								type="button"
								dataSpec="cancel"
								onClick={() =>
									this.setState(
										{
											firstColumnStatic: true,
											secondColumnStatic: true,
											thirdColumnStatic: true,
											fourthColumnStatic: true
										},
										() => initialize(initialValues)
									)}
							/>
							<Button
								label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
								variant="primary"
								type="submit"
								dataSpec="save"
								disabled={pristine || invalid || filesUploading}
							/>
						</div>
					)}
				</form>
				<div className={styles.add_note_wrapper}>
					<TabsBar tabs={[{ name: 'History', isActive: true }]} />
					{!(
						ticketDetails.status.name === 'Closed' ||
						ticketDetails.status.name === 'Cancel'
					) && (
						<Button
							label={
								<FormattedMessage
									id="TICKETING.ADD_NOTE"
									defaultMessage="Add Note"
								/>
							}
							variant="primary"
							dataSpec={'ticketing-add-note'}
							onClick={() => this.onNoteClick(true)}
						/>
					)}
				</div>
				<Table
					data-spec="ticket-details-table-desktop"
					data={history && history.resultList}
					options={getOptions('desktop', this.onNoteClick)}
				/>
				<EditNotesModal
					show={isNoteModalOpen}
					data={
						!isAddingNote
							? selectedHistory
							: {
									user: user.uid.split('@')[0],
									externalCreatedAt: new Date()
							  }
					}
					onCloseReset={this.onCloseResetFields}
					onSubmit={this.onSubmit}
					note={!isAddingNote ? selectedHistory.notes : ''}
					initialValues={initialValues}
				/>
				<NotesModal
					show={openProblemDescriptionModal || openIssueDetailsModal}
					onClose={this.closeShowMoreModal}
					notes={
						(openProblemDescriptionModal && formValues.endpointIssue.issue) ||
						(openIssueDetailsModal && formValues.endpointIssue.issueDetails) ||
						''
					}
					title={
						(openProblemDescriptionModal && {
							id: 'TICKETING.PROBLEM_DESCRIPTION',
							defaultMessage: 'Problem Description'
						}) ||
						(openIssueDetailsModal && {
							id: 'TICKETING.ISSUE_DETAILS',
							defaultMessage: 'Issue Details'
						}) ||
						null
					}
				/>
			</div>
		);
	}
}

const { object, func, bool, array, shape, string, arrayOf } = PropTypes;

Desktop.propTypes = {
	handleSubmit: func,
	dispatch: func,
	initialize: func,
	initialValues: shape(),
	formValues: shape(),
	ticketDetails: shape({
		externalTicketId: string,
		status: object,
		createdAt: string,
		userId: string,
		category: object,
		endpointIssue: object,
		severity: object,
		priority: object,
		ticketFile: array,
		accountId: string,
		user: object
	}),
	getOptions: func,
	getDateTimeFormat: func,
	categories: shape({
		resultList: array
	}),
	firstSubCategories: shape({
		resultList: array
	}),
	secondSubCategories: shape({
		resultList: array
	}),
	thirdSubCategories: shape({
		resultList: array
	}),
	severities: shape({
		resultList: array
	}),
	priorities: shape({
		resultList: array
	}),
	countries: shape({
		resultList: array
	}),
	states: shape({
		resultList: array
	}),
	confirmation: shape({
		resultList: array
  }),
  history: shape({
    resultList: array
  }),
	getFirstSubCategories: func,
	getSecondSubCategories: func,
	getThirdSubCategories: func,
	editTicket: func,
	pristine: bool,
	invalid: bool,
	fileSuccess: arrayOf(object),
	resetFiles: func,
	filesUploading: bool,
	editTicketInit: func,
	fileDownloadTicket: func,
	fileDownload: bool,
	selectedHistory: shape(),
  user: shape(),
  intl: object
};

const TicketDetailsForm = reduxForm({
	form: 'TicketDetailsForm',
	enableReinitialize: true
})(Desktop);

export default injectIntl(hoc()(TicketDetailsForm));
