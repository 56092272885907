import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { object, string, func, } from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
	Field,
	getFormValues,
	reduxForm,
} from 'redux-form';
import Button from '../../../../../lib/DigitalComponents/Button';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';

import FieldGroup from '../../../../../lib/DigitalComponents/FieldGroup';
import { batchFileRequestURL as batchFileRequestURLAction } from '../../../../../redux/batchFiles/actions';
import { closeModal } from '../../../../../redux/modals/batchFileUploadModal/actions';
import {
	sameDateFormatter,
	startOfDayFormatter,
} from '../../../../../utils/constants';

import './StepSecond.scss';

const validate = () => ({});

class StepSecond extends Component {
  constructor(props) {
		super(props);

    this.state = {
      selectedDay: new Date()
    };
  }

	componentDidMount() {
		const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context } = this.props;
		const { selectedDay } = this.state;

		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

  onNext = () => {
		const { batchFileRequestURL, onNext, batchUpload, type } = this.props;
		const { selectedDay } = this.state;
    let obj = Object.assign(this.state, batchUpload);
    const formattedSelectedDay = startOfDayFormatter(selectedDay);
    const isSameDate = sameDateFormatter(selectedDay);

    obj = {
      ...obj,
      scheduledDate: isSameDate ? undefined : formattedSelectedDay,
      request: {
        ...obj.request,
        scheduledDate: isSameDate ? undefined : formattedSelectedDay
      }
    };
    batchFileRequestURL(obj, type);
    onNext(obj);
	};

  setFooter = (context) => {
		const { onClose } = this.props;
		const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					onClick={onClose}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					dataSpec="upload-file"
					onClick={this.onNext}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') <
							format(new Date(), 'yyyy-MM-dd')
					}
					variant="primary"
					label={
						<FormattedMessage id="UPLOAD_FILE" defaultMessage="Upload file" />
					}
				/>
			</>
		);
		context.updateContext({ footer });
  }

  onDefaultDateChange = (date) => {
		this.setState({
			selectedDay: date,
		});
  };

  render() {
		const { selectedDay } = this.state;

    return (
			<div data-spec="step-second-batch-file-upload">
				<div className={'second-step'}>
					<h4 className="confirm-upload-title">
						<FormattedMessage
							id="ENDPOINTS.CONFIRM_BATCH_FILE_UPLOAD"
							defaultMessage="Confirm batch file upload"
						/>
					</h4>
					<div className="confirm-content">
						<DSDatePicker
							label={
								<FormattedMessage
									id="ENDPOINTS.SCHEDULED_ACTION_ON"
									defaultMessage="Scheduled Action on"
								/>
							}
							value={selectedDay}
							onDayChange={this.onDefaultDateChange}
						/>
						<FormattedMessage
							id="ENDPOINTS.ADDITIONAL_COMMENT"
							defaultMessage="Additional comment"
						>
							{(formattedValue) => (
								<Field
									name="textarea"
									component={FieldGroup}
									id="formControlsText"
									type="textarea"
									placeholder={formattedValue}
									componentClass="textarea"
								/>
							)}
						</FormattedMessage>
					</div>
				</div>
			</div>
		);
  }
}

const mapStateToProps = (state) => ({
	formValues: getFormValues('DSBatchUploadForm')(state),
});

const mapDispatchToProps = (dispatch) => (
	{
		batchFileRequestURL: (data, type) => (
			dispatch(batchFileRequestURLAction(data, type))
		),
		bulkBatchUploadModalClose: () => dispatch(closeModal())
	}
);

StepSecond.propTypes = {
  type: string,
  context: object,
	batchFileRequestURL: func,
	onNext: func,
	batchUpload: object,
	onClose: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'DSBatchUploadForm',
	validate,
	initialValues: {
		textarea: ''
	}
})(StepSecond));
