import { connect } from 'react-redux';

import { getUser } from '../../../../redux/user/getUserData/selectors';
import { getAvailableStates } from '../../../Endpoint/redux/actions';
import { getAvailableStatesData } from '../../../Endpoint/redux/selectors';
import { getM2MAccount } from '../../redux/actions';
import { getM2MAccountData } from '../../redux/selectors';
import Desktop from './Desktop';
import Mobile from './Mobile';

const mapStateToProps = (state) => (
	{
		user: getUser(state),
		availableStates: getAvailableStatesData(state),
		getM2MAccountData: getM2MAccountData(state),
	}
);
const mapDispatchToProps = (dispatch) => ({
	getAvailableStates: () => dispatch(getAvailableStates()),
	getM2MAccount: () => dispatch(getM2MAccount()),
});

export const DataOverviewDesktop = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Desktop);
export const DataOverviewMobile = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Mobile);
