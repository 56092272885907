import React, { Component } from 'react';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import { format } from 'date-fns';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

import { connect } from 'react-redux';
import { batchFileUploadFail as batchFileUploadFailAction } from 'redux/batchFiles/actions';

import { downloadReportRequestURL } from '../../../../redux/batchFiles/templateDownload/actions';
import ActivationPlatform from './ActivationPlatform';
import { batchFileUploadActions } from '../../utils/constants';
import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './DialogBodyStyle.scss';

const Upload = ImageSelector(CurrentContext.theme, 'svgs/upload.svg');

let fileExtension = '';
const fileDateFormat = 'MM-dd-yyyy_HH-mm-ss';

class BatchFileUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedAction: null,
			files: [],
			disabled: true,
			isBatchFormatsOpen: false,
			dropZoneMessage: null,
			isActivationFile: false
		};
	}

	onDrop = (accepted, rejected) => {
		// Multiple upload is disabled therefore we will only have one file.
		// TODO: Update once requirements are known
		const { onClick } = this.props;
		if (rejected.length > 0) {
			this.setState({
				dropZoneMessage: (
					<FormattedMessage
						id="UNSUPPORTED_FILE_TYPE"
						defaultMessage="Unsupported file type"
					/>
				),
				disabled: true
			});
		} else {
			const isActivationFile =
				accepted[0].name.toLowerCase().indexOf('activation') !== -1;
			fileExtension = accepted[0].name.substring(
				accepted[0].name.lastIndexOf('.'),
				accepted[0].name.length
			);
			const fileName = `${accepted[0].name.substring(
				0,
				accepted[0].name.lastIndexOf('.')
			)}_${format(new Date(), fileDateFormat)}${fileExtension}`;
			this.setState(
				{
					files: accepted,
					disabled: false,
					dropZoneMessage: null,
					fileName,
					isActivationFile
				},
				() => onClick(this.state)
			);
		}
		fileExtension = '';
	}

	onChange = (value) => {
		const { onClick } = this.props;
		const { files } = this.state;
		this.setState(
			{
				selectedAction: value,
				disabled: files.length === 0
			},
			() => onClick(this.state)
		);
	};

	onDropRejected = (rejected) => {
		const { batchFileUploadFail } = this.props;
		if (rejected) {
			const error = {
				error: [{}]
			};
			batchFileUploadFail(error);
		}
	};

	scrollToBottom = () => {
		const parentelement =
			this.modal && this.modal.parentElement ? 'parentElement' : 'parentNode';
		const modal =
			this.modal && this.modal[parentelement] ? this.modal[parentelement] : {};
		const { scrollHeight } = modal;
		const height = modal.clientHeight;
		const maxScrollTop = scrollHeight - height;
		modal.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
	};

	renderDropZoneMessage = () => {
		const { dropZoneMessage } = this.state;
		return (
			<div className={styles.title} data-spec="dropzone-message">
				{dropZoneMessage}
				<br />
				<FormattedMessage id="DRAG_FILE_OR_CLICK_TO_UPLOAD" />
			</div>
		);
	}

	render() {
		const { selectedAction, type, templates, downloadTemplate,
			bulkInstructionsCallback, intl } = this.props;
		const { dropZoneMessage, files, isActivationFile, fileName } = this.state;
		return (
			<div
				ref={(modal) => {
					this.modal = modal;
				}}
				data-spec="swap-sims"
				className={styles.dialog_wrap}
			>
				<div className={styles.modal_content}>
					<div className={styles.selected_action_note}>
						<FormattedMessage id="UPLOAD_FILE_NOTE" tagName="div" />
						<span>{batchFileUploadActions[selectedAction]}</span>
					</div>
					<Dropzone
						accept={'.csv'}
						onDrop={this.onDrop}
						onDropRejected={this.onDropRejected}
						multiple={false}
						disablePreview
						className={cn(styles.dropzone, {
							[`${styles.error}`]: dropZoneMessage !== null
						})}
					>
						<div>
							<Upload className={styles.uploadIcon} />
						</div>
						<div className={styles.text}>
							{files.length > 0
								? files.map((f) => (
									<span data-spec="selected-file" key={f.name}>
										{fileName}
									</span>
								))
								: this.renderDropZoneMessage()}
						</div>
					</Dropzone>
					{type !== 'securityFeatures' && (
						<div>
							<div className={styles.batch_file_upload} />
							<p>
								<FormattedMessage
									id="BATCH_INSTRUCTIONS_NOTE"
									defaultMessage="Important: Please read {linkToInstructions} before downloading/uploading bulk files."
									values={{
										linkToInstructions:
											<span
												data-spec="batch-instructions-url"
												className={styles.template__url}
												onClick={() => {
													if (bulkInstructionsCallback) {
														bulkInstructionsCallback();
													}
												}}
											>
												{intl.formatMessage({ id: 'ENDPOINTS.BULK_UPLOAD_INSTRUCTIONS', defaultMessage: 'Batch Upload Instructions' })}
											</span>
									}}
								/>
							</p>
							<div className={styles.template_and_activation_platform_container}>
								<div>
									<p>
										<FormattedMessage
											id="DOWNLOAD_TEMPLATE_BATCH"
											defaultMessage="Download template"
										/>
									</p>
									<div>
										{templates.length > 0 ?
										<ul>
											{templates.map((template) => {
												const { fullReportFileName } = template.data[0].date[0];
												return (
													<li data-spec="template-download-url">
														<span
															data-spec="template-download-url"
															className={styles.template__url}
															key={template.reportName}
															onClick={() => downloadTemplate(fullReportFileName)}
														>
															{fullReportFileName}
														</span>
													</li>
												);
											})}
										</ul> : <Loader className={styles.custom_loader} data-spec="loader" />}
									</div>
								</div>
								{isActivationFile && (
									<ActivationPlatform onMenuOpen={this.scrollToBottom} />
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const { func, array, string } = PropTypes;

BatchFileUpload.propTypes = {
	onClick: func,
	selectedAction: string,
	downloadTemplate: func,
	batchFileUploadFail: func,
	templates: array,
	type: string,
	bulkInstructionsCallback: func,
	intl: intlShape.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	batchFileUploadFail: (error) => dispatch(batchFileUploadFailAction(error)),
	downloadTemplate: (reportFileName) =>
		dispatch(
			downloadReportRequestURL({
				reportFileName,
				reportCategory: 'template'
				// fileType: "template"
			})
		)
});

export default connect(
	null,
	mapDispatchToProps
)(injectIntl(BatchFileUpload));
