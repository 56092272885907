import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';

import AddTicket from '../../components/AddTicket';

const AddTicketView = (props) => (
	<>
		<DesktopMediaQuery>
			<AddTicket {...props} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<AddTicket {...props} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<AddTicket {...props} />
		</MobileMediaQuery>
	</>
);

export default AddTicketView;
