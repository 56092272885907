import React from 'react';
import { func, bool, object } from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import { required } from '../../../../utils/validators';

import styles from './CancelRequestedFeatureModal.scss';

const onSubmit = (values, _, props) => {
	props.cancelRequestedFeature({
		note: values.note,
		id: props.permission.id
	});
};

const CancelRequestFeatureModal = ({
	onCancel,
	show,
	invalid,
	handleSubmit,
	permission
}) => (
	<Modal
		data-spec="cancel-feature-request-modal"
		show={show}
		onClose={onCancel}
	>
		<form onSubmit={handleSubmit(onSubmit)}>
			<ModalHeader
				title={
					<FormattedMessage
						id="OBOARDING.CANCEL_REQUES"
						defaultMessage="Cancel Request"
					/>
				}
			/>
			<ModalBody className={styles.modal_body}>
				<div data-spec="body-message" className={styles.body}>
					<div className={styles.modal_message}>
						You are about to cancel the request for
						{`You are about to Allow the Access for ${permission.name}`}
					</div>
					<div className={styles.modal_message}>
						<FormattedMessage id="OBOARDING.COMMENT" defaultMessage="Comment">
							{(formattedValue) => (
								<Field
									label={
										<FormattedMessage
											id="OBOARDING.JUSTIFICATION_FOR_CANCELATION"
											defaultMessage="Justification for Cancelation"
										/>
									}
									componentClass="textarea"
									dataSpec="note"
									component={Input}
									name="note"
									placeholder={formattedValue}
									validate={[required]}
								/>
							)}
						</FormattedMessage>
					</div>
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				<div data-spec="buttons">
					<Button
						onClick={onCancel}
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					/>
					<Button
						variant="primary"
						type="submit"
						disabled={invalid}
						label={<FormattedMessage id="SUBMIT" defaultMessage="Submit" />}
					/>
				</div>
			</ModalFooter>
		</form>
	</Modal>
);

CancelRequestFeatureModal.propTypes = {
	onCancel: func,
	show: bool,
	invalid: bool,
	handleSubmit: func,
	permission: object
};

const CancelRequestFeatureModalForm = reduxForm({
	form: 'CancelRequestFeatureModalForm'
})(CancelRequestFeatureModal);

export default CancelRequestFeatureModalForm;
