import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import NoData from '../../../Shared/views/NoDataView';
import Loading from '../../../../lib/DigitalComponents/Loader';

const List = () => (WrappedComponent) => {
	class InvoicesList extends PureComponent {
		getMappedData = () => {
			const { data } = this.props;
			if (data.resultList.length > 0) {
				const keys = Object.keys(data.resultList[0]);
				const formattedArray = data.resultList.map((row) => {
					const formattedRow = {};
					keys.forEach((key) => {
						const value = row[key] ? (
							row[key]
						) : (
							<FormattedMessage
								id="ANALYTICS_INVOICES.DATA_NOT_AVAILABLE"
								defaultMessage="Data not available"
							/>
						);
						formattedRow[key] = value;
					});
					return formattedRow;
				});
				return formattedArray;
			}
			return data.resultList;
		};

		render() {
			const { dataRequest, dataFail, data } = this.props;
			return (
				<div data-spec="list">
					{dataRequest && <Loading data-spec="loading" />}
					{dataFail && null}
					{!dataRequest && data && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							data={data.resultList}
						/>
					)}
					{data && data.resultList && data.resultList.length === 0 && (
						<NoData
							message={
								<FormattedMessage
									id="ANALYTICS_INVOICES.NO_DATA"
									defaultMessage="No data available."
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { func, object, number, string, bool } = PropTypes;

	InvoicesList.propTypes = {
		getData: func,
		dataRequest: bool,
		dataFail: bool,
		data: object,
		dataLimit: number,
		id: string,
		totalCount: string,
		tableHeader: object,
		options: func
	};

	return InvoicesList;
};

export default List;
