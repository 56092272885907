/* eslint-disable nonblock-statement-body-position */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Field } from 'redux-form';
import { isEmpty, find } from 'lodash';

import { FormattedMessage } from 'react-intl';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import {
	range,
	onlyNumbers,
	required,
	maxLength,
	noWhiteSpaceOnly
} from '../../../../utils/validators';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import { removeLeadingZero } from '../../../../utils/normalizers';
import { localizationHelper } from '../../../../utils/helperFunctions';
import ruleTypes from '../../utils/ruleTypes';
import { manualRuleStates } from '../../utils/manualRuleStatesHelper';
import {
	getCustomFieldsOptions,
	getImeiWhiteListActionOptions
} from '../../utils/helpers';

const range1_30 = range(1, 30);

const getAutomationActionsFunction = (value, getAction, prop) => {
	const id = value.value || value.id;
	const params = {
		searchParams: [{ propValue: id, prop, operator: '=' }],
		additionalParams: { include: ['AutomationActionType'] }
	};

	id && getAction(params);
};

const getAutomationSchedulers = (automationProps, item) => {
	const { getAutomationSchedulers, rule = {} } = automationProps;
	const id = (item && item.value) || rule.automationActionId2;
	const params = {
		searchParams: [{ propValue: id, prop: 'automationActionId', operator: '=' }]
	};

	getAutomationSchedulers(params);
};

const getAutomationSchedulersData = (automationProps) => {
	const { automationSchedulers } = automationProps;

	return automationSchedulers.map((scheduler) => {
		const { id, name, code } = scheduler;
		return {
			value: id,
			label: (
				<FormattedMessage
					id={`AUTOMATION.${localizationHelper(name).toUpperCase()}`}
					defaultMessage={name}
				/>
			),
			code
		};
	});
};

const getNumberOfHours = () => {
	const hours = [...new Array(24)];

	return hours.map((...rest) => {
		const index = rest[1] + 1;
		return {
			value: `${index}`,
			label: index
		};
	});
};

const getM2MAccountId = ({ formValues, rule }) => {
	const platform =
		(formValues &&
			formValues.automationPlatformId &&
			formValues.automationPlatformId.label) ||
		(rule && rule.automationPlatform && rule.automationPlatform.name);
	const m2mAccountId =
		(formValues && formValues.m2mAccountId && formValues.m2mAccountId.value) ||
		(formValues && formValues.m2mAccountId) ||
		(rule && rule.m2mAccountId);
	return {
		m2mAccountId,
		platform:
			platform && platform.props ? platform.props.defaultMessage : platform
	};
};

export const isFieldDisabled = (automationProps, fields = []) => {
	const { formValues = {} } = automationProps;
	return Boolean(
		fields.filter(
			(field) => !formValues[field] || formValues[field].length === 0
		).length
	);
};

const checkAndGetNetworkEntitlements = (props) => {
	const {
		getNetworkEntitlement,
		currentM2MaccountId,
		networkEntitlements
	} = props;
	const m2mAccountId = getM2MAccountId(props);
	if (m2mAccountId !== currentM2MaccountId || !networkEntitlements) {
		getNetworkEntitlement(m2mAccountId);
	}
};

export const automationRuleFiltersConfig = (rule, automationProps) => {
	const automationTrigger = rule.automationTrigger || rule;
	const {
		getAvailableStates,
		availableStates,
		getRatePlans,
		getPrepaidRatePlans,
		getAutomationActions,
		getAutomationChildActions,
		getAutomationCountries,
		automationCountries,
		getAutomationZoneModel,
		getAutomationZoneName,
		getAutomationPool,
		automationPool,
		match: {
			params: { ruleId }
		}
	} = automationProps;
	const callUpdateFunctions = () => {
		getAutomationActionsFunction(
			{ id: rule.automationActionId },
			getAutomationChildActions,
			'parentAutomationActionId'
		);
		rule.automationActionId2 && getAutomationSchedulers(automationProps);
		checkAndGetNetworkEntitlements(automationProps);
	};

	const availableRuleFilters = [
		{
			name: 'hasStatus',
			action: getAvailableStates,
			validation: !availableStates && isEmpty(availableStates)
		},
		{
			name: 'hasRatePlanId',
			action: getRatePlans,
			validation:
				automationTrigger.hasRatePlanId ||
				automationTrigger.automationTriggerFrameValueTypeId === 3
		},
		{
			name: 'hasPrepaidRatePlanId',
			action: getPrepaidRatePlans,
			validation: automationTrigger.hasPrepaidRatePlanId
		},
		{
			name: 'hasCustomFields'
		},
		{
			name: 'hasCustomerId'
		},
		{
			name: 'hasAccountId'
		},
		{
			name: 'hasBaseRatePlan'
		},
		{
			name: 'hasRatePlanType'
		}
	];

	getAutomationActionsFunction(
		automationTrigger,
		getAutomationActions,
		'automationTriggerId'
	);
	const m2mAccountId = getM2MAccountId(automationProps);
	ruleId && callUpdateFunctions();
	automationTrigger &&
		Object.keys(automationTrigger).forEach((key) => {
			const item = find(availableRuleFilters, { name: key });
			if (item) {
				const { action, validation } = item;
				validation && action(m2mAccountId);
			}
		});

	// Treshold zones, pull ID and countries
	const { automationTriggerFrameValueTypeId } = automationTrigger;

	// TODO: this should be refactored to support multiple zone name models and zone names
	// inside automationRuleFrameActionValues array
	const zoneModelId =
		rule &&
		rule.automationRuleFrameActionValues &&
		rule.automationRuleFrameActionValues.length &&
		rule.automationRuleFrameActionValues[0] &&
		rule.automationRuleFrameActionValues[0].zoneModelId;
	const zoneModelParams = zoneModelId
		? {
				searchParams: [
					{ propValue: zoneModelId, prop: 'zoneModel.id', operator: '=' }
				]
		  }
		: undefined;
	const handleGetAutomationZoneName = () => {
		getAutomationZoneModel();
		if (zoneModelParams) {
			getAutomationZoneName(zoneModelParams);
		}
	};

	const thresholdConfig = {
		automationTriggerFrameValueTypeId_6: {
			action: getAutomationCountries,
			validation: automationCountries && isEmpty(automationCountries)
		},
		automationTriggerFrameValueTypeId_5: {
			action: handleGetAutomationZoneName,
			validation: true
		},
		automationTriggerFrameValueTypeId_8: {
			action: handleGetAutomationZoneName,
			validation: true
		},
		automationTriggerFrameValueTypeId_7: {
			action: () => getAutomationPool(m2mAccountId),
			validation: automationPool && isEmpty(automationPool)
		},
		automationTriggerFrameValueTypeId_9: {
			action: () => {},
			validation: true
		}
	};

	const currentTypeId =
		thresholdConfig[
			`automationTriggerFrameValueTypeId_${automationTriggerFrameValueTypeId}`
		];
	automationTriggerFrameValueTypeId &&
		currentTypeId &&
		currentTypeId.validation &&
		currentTypeId.action();
};

const getPlatformName = (automationProps) => {
	const { formValues = {}, platforms = [] } = automationProps;
	return (
		platforms &&
		platforms.find(
			(platform) =>
				platform.id ==
				(formValues.automationPlatformId.value ||
					formValues.automationPlatformId)
		)
	);
};

const getAvailableStates = (automationProps) => {
	const { messages, availableStates } = automationProps;
	const platform = getPlatformName(automationProps);
	return availableStates &&
		platform &&
		platform.code &&
		availableStates[platform.code]
		? availableStates[platform.code].map((m) => {
				const { currentState } = m;
				return {
					value: currentState,
					label: messages[currentState] || currentState
				};
		  })
		: [];
};

const getAutomationCountries = (automationProps) => {
	const { automationCountries } = automationProps;
	return automationCountries
		? automationCountries.map((m) => {
				const { countryName, mcc, mnc } = m;
				return {
					value: `${mcc}${mnc}`,
					label: `${mcc} ${mnc} ${countryName}`
				};
		  })
		: [];
};

const getAutomationZoneModel = (automationProps) => {
	const { automationZoneModel } = automationProps;
	return automationZoneModel
		? automationZoneModel.map((m) => {
				const {
					id,
					attributes: { name }
				} = m;
				return {
					value: `${id}`,
					label: (
						<FormattedMessage
							id={`AUTOMATION.${localizationHelper(name)}`}
							defaultMessage={name}
						/>
					)
				};
		  })
		: [];
};

const getAutomationZoneName = (automationProps) => {
	const { automationZoneName } = automationProps;
	return automationZoneName
		? automationZoneName.map((m) => {
				const {
					attributes: { name },
					id
				} = m;
				return {
					value: id,
					label: (
						<FormattedMessage
							id={`AUTOMATION.${localizationHelper(name)}`}
							defaultMessage={name}
						/>
					)
				};
		  })
		: [];
};

const getNetworkEntitlements = (automationProps) => {
	const { networkEntitlements } = automationProps;
	return networkEntitlements
		? networkEntitlements.map((networkEntitlement) => {
				// const { networkEntitlement = {} } = m;
				const { id, name } = networkEntitlement;
				return {
					value: id,
					label: name
				};
		  })
		: [];
};

const getAutomationPool = (automationProps) => {
	const { automationPool } = automationProps;
	return automationPool
		? automationPool.map((m) => {
				const {
					ratePlan: { accountSubscriptionItemID, name }
				} = m;
				return {
					value: `${accountSubscriptionItemID}`,
					label: name
				};
		  })
		: [];
};

const getFilteredAvailableStates = (automationProps, fieldIndex) => {
	const { formValues, messages, availableStates } = automationProps;
	const frameValue =
		formValues.automationRuleFrameActionValues &&
		formValues.automationRuleFrameActionValues[fieldIndex] &&
		formValues.automationRuleFrameActionValues[fieldIndex].frameValue;
	const platform = getPlatformName(automationProps);
	let temp = [];
	availableStates &&
		platform &&
		platform.code &&
		availableStates[platform.code] &&
		availableStates[platform.code].forEach((m) => {
			const { currentState, targetStates } = m;
			if (currentState === ((frameValue && frameValue.value) || frameValue)) {
				targetStates.forEach((item) => {
					const { state } = item;
					temp = [
						...temp,
						{
							value: state,
							label: messages[state] || state
						}
					];
				});
			}
		});
	return temp;
};

const getRatePlans = (automationProps, hasPrepaidRatePlanId) => {
	const { ratePlans, prepaidRatePlans } = automationProps;
	const ratePlansArray = hasPrepaidRatePlanId ? prepaidRatePlans : ratePlans;
	return ratePlansArray.map((plan) => ({
		value: plan.ratePlan.id,
		label: plan.ratePlan.name
	}));
};

export const getM2MAccountsByPlatform = (automationProps) => {
	const {
		platforms,
		formValues = {},
		rule = {},
		m2mAccounts
	} = automationProps;
	const platformId =
		(formValues.automationPlatformId &&
			formValues.automationPlatformId.value) ||
		(rule && rule.automationPlatformId);
	if (m2mAccounts && platformId) {
		const selectedPlatform = find(platforms, { id: platformId }) || {};
		const platformName = selectedPlatform.code || '';
		const options = m2mAccounts.filter(
			(item) => item.platformName === platformName.toUpperCase()
		);
		return options.map((item) => ({
			label: item.m2mAccountId,
			value: item.m2mAccountId
		}));
	}
};

const checkAndGetAvailableStates = (props) => {
	const { availableStates, getAvailableStates } = props;
	if (!availableStates || !availableStates.length) {
		getAvailableStates();
	}
};

const checkAndGetRatePlans = (props) => {
	const { ratePlans, getRatePlans } = props;

	if (!ratePlans || !ratePlans.length) {
		getRatePlans(getM2MAccountId(props));
	}
};

const actionsValuesConfig = {
	EMAIL: {
		label: 'EMAIL',
		placeholder: 'john.doe@example.com'
	},
	EMAIL_SUB: {
		label: 'SEND_EMAIL_TO_CONSUMER',
		placeholder: 'john.doe@example.com'
	},
	SMS: {
		label: 'SEND_SMS',
		placeholder: '+14155552671'
	},
	PUSH_API: {
		label: 'PUSH_API',
		placeholder: ''
	},
	'REST-API': {
		label: 'PUSH_API',
		placeholder: ''
	},
	UPDATE_PCRF: {
		label: 'UPDATE_PCRF',
		placeholder: ''
	},
	UPDATE_COMM_PLAN: {
		label: 'CHANGE_DEVICES_NETWORK_ENTITLEMENT',
		options: (props) => getNetworkEntitlements(props),
		action: (props) => checkAndGetNetworkEntitlements(props)
	},
	'CHANGE-SIM-SERVICE-PROFILE': {
		label: 'CHANGE_DEVICES_NETWORK_ENTITLEMENT',
		options: (props) => getNetworkEntitlements(props),
		action: (props) => checkAndGetNetworkEntitlements(props)
	},
	CHANGE_SIM_STATE: {
		label: 'CHANGE_DEVICES_SIM_STATE',
		options: (props) => getAvailableStates(props),
		action: (props) => checkAndGetAvailableStates(props)
	},
	'CHANGE-SIM-NETWORK-STATUS': {
		label: 'CHANGE_DEVICES_SIM_STATE',
		options: (props) => getAvailableStates(props),
		action: (props) => checkAndGetAvailableStates(props)
	},
	UPDATE_RATE_PLAN: {
		label: 'CHANGE_DEVICES_RATE_PLAN',
		options: (props) => getRatePlans(props),
		action: (props) => checkAndGetRatePlans(props)
	},
	'CHANGE-SIM-TARIFF': {
		label: 'CHANGE_DEVICES_RATE_PLAN',
		options: (props) => getRatePlans(props),
		action: (props) => checkAndGetRatePlans(props)
	},
	INITIATE_OTA_SWAP: {
		label: 'INITIATE_OTA_SWAP',
		placeholder: ''
	}
};

export const Option = (optionProps) => {
	const { data, children } = optionProps;
	return (
		<components.Option data-spec="options" {...optionProps}>
			<span data-spec={`${data.value}`}>{children}</span>
		</components.Option>
	);
};

export const SingleValue = (optionProps) => {
	const { data, children } = optionProps;
	return (
		<components.SingleValue {...optionProps} data-spec="data-spec">
			<span data-spec={`${data.value}`}>{children}</span>
		</components.SingleValue>
	);
};

//  todo : wing rule || (manual  && status != IMPLEMENTED)
export const isWingPlaform = (props) => {
	const { rule, platforms } = props;
	if (rule && rule.id) {
		const platform =
			platforms &&
			platforms.find((item) => rule.automationPlatformId === item.id);
		return (
			(platform && platform.name === 'WING') ||
			(rule.automationRuleType &&
				rule.automationRuleType.code === ruleTypes.ManualRule &&
				rule.automationRuleStatus &&
				rule.automationRuleStatus.name !== manualRuleStates.IMPLEMENTED)
		);
	}
	return false;
};

export const renderActions = (
	styles,
	automationProps,
	automationThis,
	actionType,
	field,
	fieldIndex,
	isFieldArray = false
) => {
	const {
		automationActions,
		getAutomationChildActions,
		automationChildActions,
		formValues,
		rule
	} = automationProps;
	const automationActionId =
		(formValues &&
			((formValues.automationRuleFrameActionValues &&
				formValues.automationRuleFrameActionValues[fieldIndex] &&
				formValues.automationRuleFrameActionValues[fieldIndex]
					.automationActionId &&
				formValues.automationRuleFrameActionValues[fieldIndex]
					.automationActionId.value) ||
				formValues.automationActionId)) ||
		(rule && rule.automationActionId);
	const renderConfig = {
		parent: {
			name: `${field}.automationActionId`,
			data: automationActions,
			loaderName: `${field}.automationActionValue`,
			resetFields: {
				automationActionValue: '',
				automationActionId2: '',
				automationActionValue2: '',
				automationActionSchedulerId2: '',
				automationActionSchedulerValue2: ''
			},
			isDisabled: () =>
				isFieldDisabled(automationProps, ['trigger']) ||
				!(
					formValues.automationRuleFrameActionValues &&
					formValues.automationRuleFrameActionValues[fieldIndex] &&
					formValues.automationRuleFrameActionValues[fieldIndex].frame
				)
		},
		child: {
			name: 'automationActionId2',
			data: automationChildActions,
			loaderName: 'automationActionValue2',
			resetFields: {
				automationActionValue2: '',
				automationActionSchedulerId2: '',
				automationActionSchedulerValue2: ''
			},
			isDisabled: () => false
		}
	};

	const activeAction = find(automationActions, { id: automationActionId });

	if (actionType === 'child' && activeAction && !activeAction.hasChildActions) {
		return null;
	}

	const { name, data, isDisabled } = renderConfig[actionType];
	const getActionsOptions = () =>
		data.map((action) => {
			const {
				id,
				automationActionTypeId,
				automationActionType: { name, code },
				hasChildActions,
				hasSchedulers,
				ladderingAllowed
			} = action;
			return {
				label: (
					<FormattedMessage
						id={`AUTOMATION.${localizationHelper(name).toUpperCase()}`}
						defaultMessage={name}
					/>
				),
				value: id,
				automationActionTypeId,
				hasChildActions,
				code,
				hasSchedulers,
				ladderingAllowed
			};
		});
	const handleOnChange = (item) => {
		const { data, resetFields } = renderConfig[actionType];
		// automationThis.setLoadingFields(Object.keys(resetFields).map((key) => key));
		automationThis.setLoadingFields(renderConfig[actionType].loaderName);
		const activeAction = find(data, { id: item.value });
		automationThis.resetFields('AutomationRuleDetailForm', {
			[`${field}.automationActionValue`]: '',
			[`${field}.customMessage`]: '',
			...resetFields
		});
		const code = activeAction && activeAction.automationActionType.code;
		const { action } = actionsValuesConfig[code] || {};
		action && action(automationProps);
		activeAction &&
			activeAction.hasChildActions &&
			getAutomationActionsFunction(
				item,
				getAutomationChildActions,
				'parentAutomationActionId'
			);
		activeAction &&
			activeAction.hasSchedulers &&
			getAutomationSchedulers(automationProps, item);
	};

	const isDisabledWenArrayField = () => isFieldArray && fieldIndex > 0;

	return (
		<div
			className={styles.custom_select_width_first}
			data-spec={`automation-actions-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.THEN'} defaultMessage={'Then'} />
			</div>
			<Field
				component={Select}
				name={name}
				options={getActionsOptions()}
				isDisabled={
					automationThis.showLoaderOrIsDisabled(name, 'select') ||
					!data ||
					!data.length ||
					isDisabled() ||
					(!formValues.follow_up_with && actionType === 'child') ||
					isDisabledWenArrayField() ||
					isWingPlaform(automationProps)
				}
				onChange={handleOnChange}
				validate={[required]}
				cleanValue
				components={{ Option, SingleValue }}
			/>
			{automationThis.showLoaderOrIsDisabled(name)}
		</div>
	);
};

export const renderActionsValue = (
	styles,
	automationProps,
	automationThis,
	actionType,
	field,
	fieldIndex
) => {
	const renderConfig = {
		parent: {
			id: 'automationActionId',
			name: `${field}.automationActionValue`,
			actionsName: 'automationActions'
		},
		child: {
			id: 'automationActionId2',
			name: 'automationActionValue2',
			actionsName: 'automationChildActions'
		}
	};
	const { id, name, actionsName } = renderConfig[actionType];
	const { formValues = {}, rule, messages } = automationProps;
	const actionId =
		((formValues.automationRuleFrameActionValues &&
			formValues.automationRuleFrameActionValues[fieldIndex] &&
			formValues.automationRuleFrameActionValues[fieldIndex][id]) ||
			formValues[id]) &&
		((formValues.automationRuleFrameActionValues &&
			formValues.automationRuleFrameActionValues[fieldIndex] &&
			formValues.automationRuleFrameActionValues[fieldIndex][id] &&
			formValues.automationRuleFrameActionValues[fieldIndex][id].value) ||
			(rule && rule[id]));
	const activeAction = find(automationProps[actionsName], { id: actionId });
	const code = (activeAction && activeAction.automationActionType.code) || '';
	let type = code;
	if (actionType === 'child') {
		type = (formValues[id] && formValues[id].code) || code;
	}

	const typeField = {
		SMS: () => (
			<FormattedMessage
				id={'AUTOMATION.CUSTOM_SMS_PLACEHOLDER'}
				defaultMessage={'Custom message text (140 characters limit)'}
			>
				{(formattedValue) => (
					<Field
						componentClass="textarea"
						className={styles.width100}
						label={
							<FormattedMessage
								id={'AUTOMATION.CUSTOM_SMS_LABEL'}
								defaultMessage={'Add custom text to SMS message (optional)'}
							/>
						}
						component={Input}
						name={`${field}.customMessage`}
						placeholder={formattedValue}
						dataSpec={`customMessage-${fieldIndex}`}
						validate={[maxLength(140), noWhiteSpaceOnly]}
						disabled={isWingPlaform(automationProps)}
					/>
				)}
			</FormattedMessage>
		)
	};

	switch (type) {
		case 'EMAIL':
		case 'EMAIL_SUB':
		case 'SMS':
		case 'PUSH_API':
		case 'UPDATE_PCRF':
		case 'REST-API':
		case 'INITIATE_OTA_SWAP':
			return (
				<>
					<div
						className={styles.custom_select_width_first}
						data-spec={`automation-action-value-${fieldIndex}`}
					>
						<div className={styles.label}>
							{messages[actionsValuesConfig[type].label] ||
								actionsValuesConfig[type].label}
						</div>
						<Field
							component={Input}
							name={name}
							placeholder={actionsValuesConfig[type].placeholder}
							validate={[required]}
							dataSpec={`automation-action-value-${fieldIndex}`}
							disabled={isWingPlaform(automationProps)}
						/>
					</div>
					{typeField[type] && typeField[type]()}
				</>
			);
		case 'UPDATE_COMM_PLAN':
		case 'CHANGE_SIM_STATE':
		case 'UPDATE_RATE_PLAN':
		case 'CHANGE-SIM-SERVICE-PROFILE':
		case 'CHANGE-SIM-TARIFF':
		case 'CHANGE-SIM-NETWORK-STATUS':
			return (
				<div
					className={styles.custom_select_width_first}
					data-spec={`automation-action-value-${fieldIndex}`}
				>
					<div className={styles.label}>
						{messages[actionsValuesConfig[type].label] ||
							actionsValuesConfig[type].label}
					</div>
					<Field
						component={Select}
						name={name}
						options={actionsValuesConfig[type].options(automationProps)}
						validate={[required]}
						cleanValue
						components={{ Option, SingleValue }}
						isDisabled={
							automationThis.showLoaderOrIsDisabled(name, 'select') ||
							isWingPlaform(automationProps)
						}
					/>
					{automationThis.showLoaderOrIsDisabled(name)}
				</div>
			);
		default:
			return null;
	}
};

export const renderSchedulersValue = (styles, automationProps) => {
	const { formValues, rule, automationSchedulers } = automationProps;
	const schedulerId =
		(formValues.automationActionSchedulerId2 &&
			formValues.automationActionSchedulerId2.value) ||
		formValues.automationActionSchedulerId2 ||
		(formValues.automationActionSchedulerId2 &&
			formValues.automationActionSchedulerId2.value === '');
	rule && rule.automationActionSchedulerId2;
	const activeScheduler = find(automationSchedulers, { id: schedulerId });
	const code = activeScheduler && activeScheduler.code;

	switch (code) {
		case 'DAYS_FROM_TRIGGER':
			return (
				<div
					className={styles.custom_select_width_first}
					data-spec="days-from-trigger"
					key="automation-scheduler-value"
				>
					<div className={styles.label}>
						<FormattedMessage
							id={'AUTOMATION.NUMBER_OF_DAYS'}
							defaultMessage={'Number of Days'}
						/>
					</div>
					<Field
						component={Input}
						name="automationActionSchedulerValue2"
						placeholder="Days"
						validate={[onlyNumbers, range1_30]}
						parse={(value) =>
							!Number.isNaN(parseInt(value, 10)) && parseInt(value, 10) > 0
								? value
								: ''
						}
						dataSpec="automationActionSchedulerValue2"
						disabled={isWingPlaform(automationProps)}
					/>
				</div>
			);
		case 'COMING_HOUR':
			return (
				<div data-spec="coming_hour">
					<div className={styles.custom_select_width_first}>
						<div className={styles.label}>
							<FormattedMessage
								id={'AUTOMATION.NUMBER_OF_HOURS'}
								defaultMessage={'Number of Hours (Max 24h)'}
							/>
						</div>
						<Field
							component={Select}
							name="automationActionSchedulerValue2"
							options={getNumberOfHours()}
							cleanValue
							components={{ Option, SingleValue }}
							isDisabled={isWingPlaform(automationProps)}
						/>
					</div>
				</div>
			);
		default:
			return null;
	}
};

export const renderSchedulers = (styles, automationProps, automationThis) => {
	const {
		automationSchedulers,
		automationChildActions,
		formValues = {},
		rule = {}
	} = automationProps;
	const automationActionId2 =
		(formValues &&
			((formValues.automationActionId2 &&
				formValues.automationActionId2.value) ||
				formValues.automationActionId2)) ||
		(rule && rule.automationActionId2);
	const activeAction = find(automationChildActions, {
		id: automationActionId2
	});
	return activeAction && !activeAction.hasSchedulers ? (
		<></>
	) : (
		[
			<div
				className={cn(styles.select_field)}
				data-spec="automation-scheduler"
				key="automation-scheduler"
			>
				<div className={styles.custom_select_width_first}>
					<div className={styles.label}>
						<FormattedMessage id={'AUTOMATION.AT'} defaultMessage={'At'} />
					</div>
					<Field
						component={Select}
						onChange={() =>
							automationThis.resetFields('AutomationRuleDetailForm', {
								automationActionSchedulerValue2: ''
							})
						}
						name="automationActionSchedulerId2"
						options={getAutomationSchedulersData(automationProps)}
						isDisabled={
							!automationSchedulers.length || isWingPlaform(automationProps)
						}
						cleanValue
						components={{ Option, SingleValue }}
					/>
				</div>
			</div>,
			<React.Fragment key="automation-scheduler-value2">
				{renderSchedulersValue(styles, automationProps)}
			</React.Fragment>
		]
	);
};

export const renderRuleFilters = (styles, automationProps) => {
	const { formValues = {} } = automationProps;

	const areFiltersAvailableCheck = () =>
		!(
			!(formValues.trigger && formValues.trigger.hasStatus) &&
			!(formValues.trigger && formValues.trigger.hasRatePlanId) &&
			!(formValues.trigger && formValues.trigger.hasCustomFields) &&
			!(formValues.trigger && formValues.trigger.hasCustomerId) &&
			!(formValues.trigger && formValues.trigger.hasAccountId) &&
			!(formValues.trigger && formValues.trigger.hasPrepaidRatePlanId)
		);

	const renderSimState = () => {
		// eslint-disable-next-line curly
		if (!(formValues && formValues.trigger && formValues.trigger.hasStatus))
			return;
		return (
			<div className={styles.custom_select_width_first} data-spec="sim-state">
				<FormattedMessage
					id={'AUTOMATION.SIM_STATE'}
					defaultMessage={'Sim State'}
				>
					{(formattedValue) => (
						<Field
							placeholder={formattedValue}
							component={Select}
							name="simState"
							options={getAvailableStates(automationProps)}
							cleanValue
							components={{ Option, SingleValue }}
							isDisabled={isWingPlaform(automationProps)}
						/>
					)}
				</FormattedMessage>
			</div>
		);
	};

	const renderRatePlan = () => {
		// eslint-disable-next-line curly
		if (
			!(
				(formValues &&
					formValues.trigger &&
					formValues.trigger.hasRatePlanId) ||
				(formValues &&
					formValues.trigger &&
					formValues.trigger.hasPrepaidRatePlanId)
			)
		)
			return;
		return (
			<div className={styles.custom_select_width_first} data-spec="rate-plan">
				<FormattedMessage
					id={'AUTOMATION.RATE_PLAN_ID_PLAN'}
					defaultMessage={'Rate Plan ID'}
				>
					{(formattedValue) => (
						<Field
							placeholder={formattedValue}
							component={Select}
							name="ratePlanId"
							options={getRatePlans(
								automationProps,
								formValues.trigger.hasPrepaidRatePlanId
							)}
							cleanValue
							components={{ Option, SingleValue }}
							isDisabled={isWingPlaform(automationProps)}
						/>
					)}
				</FormattedMessage>
			</div>
		);
	};

	const renderAccountId = () => {
		// eslint-disable-next-line curly
		if (!(formValues && formValues.trigger && formValues.trigger.hasAccountId))
			return;
		return (
			<div
				className={styles.custom_select_width_first}
				data-spec="account-id-filter"
			>
				<FormattedMessage
					id={'AUTOMATION.M2M_SUB_ACCOUNT_ID'}
					defaultMessage={'M2M Sub Account ID'}
				>
					{(formattedValue) => (
						<Field
							placeholder={formattedValue}
							component={Input}
							name="filterAccountId"
							dataSpec="filterAccountId"
							disabled={isWingPlaform(automationProps)}
						/>
					)}
				</FormattedMessage>
			</div>
		);
	};

	const renderCustomerId = () => {
		// eslint-disable-next-line curly
		if (!(formValues && formValues.trigger && formValues.trigger.hasCustomerId))
			return;
		return (
			<div
				className={styles.custom_select_width_first}
				data-spec="customer-id-filter"
			>
				<FormattedMessage
					id={'AUTOMATION.CUSTOMER_ID'}
					defaultMessage={'Customer ID'}
				>
					{(formattedValue) => (
						<Field
							placeholder={formattedValue}
							component={Input}
							name="customerId"
							dataSpec="customerId"
							disabled={isWingPlaform(automationProps)}
						/>
					)}
				</FormattedMessage>
			</div>
		);
	};

	const renderCustomFields = () => {
		// eslint-disable-next-line curly
		if (
			!(formValues && formValues.trigger && formValues.trigger.hasCustomFields)
		)
			return;
		const customFields = [...new Array(10)];
		return customFields.map((...rest) => {
			const index = rest[1] + 1;
			return (
				<div
					className={styles.custom_filter_fields}
					data-spec={`custom-field${index}`}
					key={`custom-field${index}`}
				>
					<FormattedMessage id={'AUTOMATION.CUSTOM'} defaultMessage={'Custom'}>
						{(formattedValue) => (
							<Field
								component={Input}
								name={`customField${index}`}
								placeholder={`${formattedValue} ${index}`}
								dataSpec={`customField${index}`}
								disabled={isWingPlaform(automationProps)}
							/>
						)}
					</FormattedMessage>
				</div>
			);
		});
	};

	const renderBaseRatePlan = () => {
		// eslint-disable-next-line curly
		if (
			!(formValues && formValues.trigger && formValues.trigger.hasBaseRatePlan)
		)
			return;
		return (
			<div
				className={styles.custom_select_width_first}
				data-spec="base-rate-plan-filter"
			>
				<FormattedMessage
					id={'AUTOMATION.BASE_RATE_PLAN'}
					defaultMessage={'Base Rate Plan'}
				>
					{(formattedValue) => (
						<Field
							placeholder={formattedValue}
							component={Input}
							name="baseRatePlanId"
							dataSpec="baseRatePlanId"
							disabled={isWingPlaform(automationProps)}
						/>
					)}
				</FormattedMessage>
			</div>
		);
	};

	const renderRatePlanType = () => {
		// eslint-disable-next-line curly
		if (
			!(formValues && formValues.trigger && formValues.trigger.hasRatePlanType)
		)
			return;
		return (
			<div
				className={styles.custom_select_width_first}
				data-spec="base-rate-plan-filter"
			>
				<FormattedMessage
					id={'AUTOMATION.RATE_PLAN_TYPE'}
					defaultMessage={'Rate Plan Type'}
				>
					{(formattedValue) => (
						<Field
							placeholder={formattedValue}
							component={Input}
							name="ratePlanType"
							dataSpec="ratePlanType"
							disabled={isWingPlaform(automationProps)}
						/>
					)}
				</FormattedMessage>
			</div>
		);
	};

	return (
		<div data-spec="rule-filters">
			<div className={styles.divider} />
			<div className={cn(styles.label, styles.filter)}>
				<FormattedMessage id={'AUTOMATION.FILTER'} defaultMessage={'Filter'} />
			</div>
			{areFiltersAvailableCheck() ? (
				<>
					<div className={cn(styles.select_field, styles.display_flex)}>
						{renderSimState()}
						{renderRatePlan()}
					</div>
					<div className={cn(styles.select_field, styles.display_flex)}>
						{renderAccountId()}
						{renderCustomerId()}
					</div>
					<div className={cn(styles.select_field, styles.display_flex)}>
						{renderBaseRatePlan()}
						{renderRatePlanType()}
					</div>
					<div
						className={cn(
							styles.select_field,
							styles.display_flex,
							styles.flex_wrap
						)}
					>
						{renderCustomFields()}
					</div>
				</>
			) : (
				<div className={cn(styles.label, styles.filter)}>
					<FormattedMessage
						id={'AUTOMATION.NO_AVAILABLE_FILTERS'}
						defaultMessage={'No Available Filters'}
					/>
				</div>
			)}
			<div className={styles.divider} />
		</div>
	);
};

export const setInitialValuesForCustomFields = (rule) => {
	const customFields = [...new Array(10)];
	let temp = {};

	customFields.forEach((...rest) => {
		const index = rest[1] + 1;

		if (rule[`customField${index}`]) {
			temp = {
				...temp,
				[`customField${index}`]: rule[`customField${index}`]
			};
		}
	});

	return temp;
};

export const allFilterFieldsForResetting = {
	simState: '',
	ratePlanId: '',
	frameValue: '',
	frameValue2: '',
	automationActionId: '',
	automationActionValue: '',
	automationActionId2: '',
	automationActionValue2: '',
	automationActionSchedulerId2: '',
	automationActionSchedulerValue2: '',
	customerId: '',
	follow_up_with: '',
	...(() => {
		const customFields = [...new Array(10)];
		let temp = {};

		customFields.forEach((...rest) => {
			const index = rest[1] + 1;

			temp = {
				...temp,
				[`customField${index}`]: ''
			};
		});

		return temp;
	})()
};

const renderShortCode = (
	styles,
	formValues,
	frames,
	state,
	frameId,
	field,
	fieldIndex,
	automationProps
) => {
	const showOnlyUnit = [4].includes(frameId);
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.mt24]: showOnlyUnit
	});

	return (
		<div className={classnames} data-spec={`treshold-${fieldIndex}`}>
			{!showOnlyUnit && (
				<div className={styles.label}>
					<FormattedMessage
						id="AUTOMATION.SHORT_CODE"
						defaultMessage="Short code"
					/>
				</div>
			)}
			<div className={styles.message_text}>
				{!showOnlyUnit && (
					<FormattedMessage
						id="AUTOMATION.SHORT_CODE"
						defaultMessage="Short code"
					>
						{(formattedValue) => (
							<Field
								component={Input}
								name={`${field}.frameValue`}
								placeholder={formattedValue}
								disabled={
									!(
										formValues.automationRuleFrameActionValues &&
										formValues.automationRuleFrameActionValues[fieldIndex] &&
										formValues.automationRuleFrameActionValues[fieldIndex].frame
									) || isWingPlaform(automationProps)
								}
								validate={[required]}
								normalize={removeLeadingZero}
								dataSpec={`${field}.frameValue`}
								className={styles.custom_input}
							/>
						)}
					</FormattedMessage>
				)}
				{frames && state.tresholdMessage}
				{formValues && formValues.trigger && formValues.trigger.unit && (
					<Units
						field={field}
						className={styles.ml10}
						frames={frames}
						formValues={formValues}
						fieldIndex={fieldIndex}
						automationProps={automationProps}
					/>
				)}
			</div>
		</div>
	);
};

const renderTreshold = (
	styles,
	formValues,
	frames,
	state,
	frameId,
	field,
	fieldIndex,
	automationProps
) => {
	const showOnlyUnit = [4].includes(frameId);
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.mt24]: showOnlyUnit
	});

	return (
		<div className={classnames} data-spec={`treshold-${fieldIndex}`}>
			{!showOnlyUnit && (
				<div className={styles.label}>
					<FormattedMessage
						id={'AUTOMATION.THRESHOLD'}
						defaultMessage={'Threshold'}
					/>
				</div>
			)}
			<div className={styles.message_text}>
				{!showOnlyUnit && (
					<FormattedMessage
						id={'AUTOMATION.THRESHOLD'}
						defaultMessage={'Threshold'}
					>
						{(formattedValue) => (
							<Field
								component={Input}
								name={`${field}.frameValue`}
								placeholder={formattedValue}
								disabled={
									!(
										formValues.automationRuleFrameActionValues &&
										formValues.automationRuleFrameActionValues[fieldIndex] &&
										formValues.automationRuleFrameActionValues[fieldIndex].frame
									) || isWingPlaform(automationProps)
								}
								validate={[required, onlyNumbers]}
								normalize={removeLeadingZero}
								dataSpec={`${field}.frameValue`}
								className={styles.custom_input}
							/>
						)}
					</FormattedMessage>
				)}
				{frames && state.tresholdMessage}
				{formValues && formValues.trigger && formValues.trigger.unit && (
					<Units
						field={field}
						className={styles.ml10}
						frames={frames}
						formValues={formValues}
						fieldIndex={fieldIndex}
						automationProps={automationProps}
					/>
				)}
			</div>
		</div>
	);
};

const renderZoneThreshold = (
	styles,
	formValues,
	frames,
	state,
	field,
	fieldIndex,
	automationProps,
	automationThis
) => {
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.width303]: true
	});
	const handleOnChange = (item) => {
		const { value } = item;
		const { getAutomationZoneName } = automationProps;
		const params = {
			searchParams: [{ propValue: value, prop: 'zoneModel.id', operator: '=' }]
		};

		getAutomationZoneName(params);
		automationThis.resetFields('AutomationRuleDetailForm', {
			[`${field}.zoneId`]: ''
		});
		automationThis.setLoadingFields(field.zoneId);
	};

	return [
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.THRESHOLD'}
					defaultMessage={'Threshold'}
				/>
			</div>
			<div className={styles.message_text}>
				<FormattedMessage
					id={'AUTOMATION.THRESHOLD'}
					defaultMessage={'Threshold'}
				>
					{(formattedValue) => (
						<Field
							component={Input}
							name={`${field}.frameValue`}
							placeholder={formattedValue}
							isDisabled={
								!(
									formValues.automationRuleFrameActionValues &&
									formValues.automationRuleFrameActionValues[fieldIndex] &&
									formValues.automationRuleFrameActionValues[fieldIndex].frame
								) || isWingPlaform(automationProps)
							}
							validate={[required, onlyNumbers]}
							normalize={removeLeadingZero}
							dataSpec={`${field}.frameValue`}
							className={styles.custom_input}
						/>
					)}
				</FormattedMessage>
				{frames && state.tresholdMessage}
				{formValues && formValues.trigger && formValues.trigger.unit && (
					<Units
						field={field}
						className={styles.ml10}
						frames={frames}
						formValues={formValues}
						fieldIndex={fieldIndex}
						automationProps={automationProps}
					/>
				)}
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width_first, styles.mb20)}
			data-spec={`zonemodel-${fieldIndex}`}
			key={`zonemodel-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.ZONE_MODEL'}
					defaultMessage={'Zone Model'}
				/>
			</div>
			<Field
				component={Select}
				name={`${field}.zoneModelId`}
				options={getAutomationZoneModel(automationProps)}
				onChange={(item) => handleOnChange(item)}
				cleanValue
				components={{ Option, SingleValue }}
				isDisabled={isWingPlaform(automationProps)}
			/>
		</div>,
		<div
			className={cn(styles.custom_select_width_first, styles.ml30, styles.mb20)}
			data-spec={`zoneId-${fieldIndex}`}
			key={`zoneId-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.ZONE_NAME'}
					defaultMessage={'Zone Name'}
				/>
			</div>
			<Field
				component={Select}
				name={`${field}.zoneId`}
				dataSpec={`zoneId-${fieldIndex}`}
				options={getAutomationZoneName(automationProps)}
				isDisabled={
					automationThis.showLoaderOrIsDisabled(field.zoneId, 'select') ||
					isWingPlaform(automationProps)
				}
				validate={[required]}
				cleanValue
				components={{ Option, SingleValue }}
			/>
		</div>
	];
};

const renderZoneThresholdInput = (
	styles,
	formValues,
	frames,
	state,
	field,
	fieldIndex,
	automationProps,
	automationThis
) => {
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.width303]: true
	});

	const mapAutomationZones = (automationZones) =>
		automationZones && automationZones.totalCount !== 0
			? automationZones.resultList.map((automationZone) => ({
					value: automationZone.name,
					label: automationZone.name
			  }))
			: [];

	return [
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.THRESHOLD'}
					defaultMessage={'Threshold'}
				/>
			</div>
			<div className={styles.message_text}>
				<FormattedMessage
					id={'AUTOMATION.THRESHOLD'}
					defaultMessage={'Threshold'}
				>
					{(formattedValue) => (
						<Field
							component={Input}
							name={`${field}.frameValue`}
							placeholder={formattedValue}
							disabled={
								!(
									formValues.automationRuleFrameActionValues &&
									formValues.automationRuleFrameActionValues[fieldIndex] &&
									formValues.automationRuleFrameActionValues[fieldIndex].frame
								) || isWingPlaform(automationProps)
							}
							validate={[required, onlyNumbers]}
							normalize={removeLeadingZero}
							dataSpec={`${field}.frameValue`}
							className={styles.custom_input}
						/>
					)}
				</FormattedMessage>
				{frames && state.tresholdMessage}
				{formValues && formValues.trigger && formValues.trigger.unit && (
					<Units
						field={field}
						className={styles.ml10}
						frames={frames}
						formValues={formValues}
						fieldIndex={fieldIndex}
						automationProps={automationProps}
					/>
				)}
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width_first, styles.mb20)}
			data-spec={`zonemodel-${fieldIndex}`}
			key={`zonemodel-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.ZONE'} defaultMessage={'Zone'} />
			</div>

			<Field
				component={Select}
				name={`${field}.frameValue2`}
				isDisabled={
					isWingPlaform(automationProps) ||
					automationThis.showLoaderOrIsDisabled('frameValue2', 'select')
				}
				options={mapAutomationZones(automationProps.ruleAutomationZones)}
				validate={[required]}
				isSearchable
			/>
			{automationThis.showLoaderOrIsDisabled('frameValue2')}
		</div>
	];
};

const renderLocationUpdate = (
	styles,
	formValues,
	frames,
	state,
	field,
	fieldIndex,
	automationProps
) => {
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.width303]: true
	});
	return [
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.ZONE'} defaultMessage={'Zone'} />
			</div>
			<div className={cn(styles.message_text, styles.location_update)}>
				<FormattedMessage id={'AUTOMATION.ZONE'} defaultMessage={'Zone'}>
					{(formattedValue) => (
						<Field
							component={Input}
							name={`${field}.frameValue2`}
							placeholder={formattedValue}
							disabled={
								!(
									formValues.automationRuleFrameActionValues &&
									formValues.automationRuleFrameActionValues[fieldIndex] &&
									formValues.automationRuleFrameActionValues[fieldIndex].frame
								) || isWingPlaform(automationProps)
							}
							validate={[required]}
							normalize={removeLeadingZero}
							dataSpec={`${field}.frameValue2`}
						/>
					)}
				</FormattedMessage>
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width_first, styles.mb20)}
			data-spec={`location-update-${fieldIndex}`}
			key={`location-update-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.HOURS_USING'}
					defaultMessage={'In past 24 hours using'}
				/>
			</div>
			<Field
				component={Select}
				name={`${field}.frameValue`}
				options={[
					{
						value: 'LOCATIONUPDATE',
						label: (
							<FormattedMessage
								id={'AUTOMATION.LOCATIONUPDATE'}
								defaultMessage={'Location Update'}
							/>
						)
					},
					{
						value: 'TRIPLE_LOCATION_UPDATE',
						label: (
							<FormattedMessage
								id={'AUTOMATION.TRIPLE_LOCATION_UPDATE'}
								defaultMessage={'Authentication Update'}
							/>
						)
					}
				]}
				validate={[required]}
				cleanValue
				components={{ Option, SingleValue }}
				isDisabled={isWingPlaform(automationProps)}
			/>
		</div>
	];
};

const renderZoneNoThreshold = (
	styles,
	formValues,
	frames,
	state,
	field,
	fieldIndex,
	automationProps,
	automationThis
) => {
	const handleOnChange = (item) => {
		const { value } = item;
		const { getAutomationZoneName } = automationProps;
		const params = {
			searchParams: [{ propValue: value, prop: 'zoneModel.id', operator: '=' }]
		};
		getAutomationZoneName(params);
		automationThis.resetFields('AutomationRuleDetailForm', {
			[`${field}.zoneId`]: ''
		});
		automationThis.setLoadingFields(field.zoneId);
	};

	return [
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`zonemodel-${fieldIndex}`}
			key={`zonemodel-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.ZONE_MODEL'}
					defaultMessage={'Zone Model'}
				/>
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.zoneModelId`}
					options={getAutomationZoneModel(automationProps)}
					onChange={(item) => handleOnChange(item)}
					cleanValue
					components={{ Option, SingleValue }}
					isDisabled={isWingPlaform(automationProps)}
				/>
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`zoneId-${fieldIndex}`}
			key={`zoneId-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.ZONE_NAME'}
					defaultMessage={'Zone Name'}
				/>
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.zoneId`}
					dataSpec={`zoneId-${fieldIndex}`}
					options={getAutomationZoneName(automationProps)}
					isDisabled={
						automationThis.showLoaderOrIsDisabled(field.zoneId, 'select') ||
						isWingPlaform(automationProps)
					}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
				/>
			</div>
		</div>
	];
};

const renderPoolIdTreshold = (
	styles,
	formValues,
	frames,
	state,
	field,
	fieldIndex,
	automationProps
) => {
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.width303]: true
	});

	return [
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.THRESHOLD'}
					defaultMessage={'Threshold'}
				/>
			</div>
			<div className={styles.message_text}>
				<FormattedMessage
					id={'AUTOMATION.THRESHOLD'}
					defaultMessage={'Threshold'}
				>
					{(formattedValue) => (
						<Field
							component={Input}
							name={`${field}.frameValue`}
							placeholder={formattedValue}
							disabled={
								!(
									formValues.automationRuleFrameActionValues &&
									formValues.automationRuleFrameActionValues[fieldIndex] &&
									formValues.automationRuleFrameActionValues[fieldIndex].frame
								) || isWingPlaform(automationProps)
							}
							validate={[required, onlyNumbers]}
							dataSpec={`${field}.frameValue`}
							className={styles.custom_input}
						/>
					)}
				</FormattedMessage>
				{frames && state.tresholdMessage}
				{formValues && formValues.trigger && formValues.trigger.unit && (
					<Units
						field={field}
						className={styles.ml10}
						frames={frames}
						formValues={formValues}
						fieldIndex={fieldIndex}
						automationProps={automationProps}
					/>
				)}
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`simPoolId-${fieldIndex}`}
			key={`simPoolId-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.POOL_ID'}
					defaultMessage={'Pool ID'}
				/>
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.simPoolId`}
					options={getAutomationPool(automationProps)}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
					isDisabled={isWingPlaform(automationProps)}
				/>
			</div>
		</div>
	];
};

const renderCountries = (styles, automationProps, field, fieldIndex) => {
	const classnames = cn({
		[styles.custom_select_width]: true
	});

	return [
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div
				className={cn(styles.custom_select_width, styles.custom_margin)}
				data-spec={`countries-${fieldIndex}`}
				key={`countries-${fieldIndex}`}
			>
				<div className={styles.label}>
					<FormattedMessage
						id={'AUTOMATION.COUNTRY'}
						defaultMessage={'Country'}
					/>
				</div>
				<div className={styles.message_text}>
					<Field
						component={Select}
						name={`${field}.frameValue`}
						options={getAutomationCountries(automationProps)}
						validate={[required]}
						cleanValue
						components={{ Option, SingleValue }}
						isDisabled={isWingPlaform(automationProps)}
					/>
				</div>
			</div>
		</div>
	];
};
/* render sim state change */
const renderSimStateChange = (
	styles,
	automationProps,
	automationThis,
	field,
	fieldIndex
) => {
	const { formValues } = automationProps;
	return [
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`sim-state-change-from-${fieldIndex}`}
			key={`sim-state-change-from-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.FROM_KEY'} defaultMessage={'From'} />
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.frameValue`}
					options={getAvailableStates(automationProps)}
					onChange={() => {
						automationThis.resetFields('AutomationRuleDetailForm', {
							[`${field}.frameValue2`]: ''
						});
					}}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
					isDisabled={isWingPlaform(automationProps)}
				/>
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`sim-state-change-to-${fieldIndex}`}
			key={`sim-state-change-to-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.TO_KEY'} defaultMessage={'To'} />
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.frameValue2`}
					options={getFilteredAvailableStates(automationProps, fieldIndex)}
					isDisabled={
						!(
							formValues.automationRuleFrameActionValues &&
							formValues.automationRuleFrameActionValues[fieldIndex] &&
							formValues.automationRuleFrameActionValues[fieldIndex].frameValue
						) || isWingPlaform(automationProps)
					}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
				/>
			</div>
		</div>
	];
};

const renderRatePlanChange = (
	styles,
	automationProps,
	automationThis,
	field,
	fieldIndex
) => {
	const { formValues } = automationProps;

	return [
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`rate-plan-change-from-${fieldIndex}`}
			key={`rate-plan-change-from-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.FROM_KEY'} defaultMessage={'From'} />
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.frameValue`}
					options={getRatePlans(automationProps)}
					onChange={() => {
						automationThis.resetFields('AutomationRuleDetailForm', {
							[`${field}.frameValue2`]: ''
						});
					}}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
					isDisabled={isWingPlaform(automationProps)}
				/>
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`rate-plan-change-to-${fieldIndex}`}
			key={`rate-plan-change-to-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.TO_KEY'} defaultMessage={'To'} />
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.frameValue2`}
					options={getRatePlans(automationProps)}
					isDisabled={
						!(
							formValues.automationRuleFrameActionValues &&
							formValues.automationRuleFrameActionValues[fieldIndex] &&
							formValues.automationRuleFrameActionValues[fieldIndex].frameValue
						) || isWingPlaform(automationProps)
					}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
				/>
			</div>
		</div>
	];
};

const renderM2MAccounts = (
	styles,
	automationProps,
	automationThis,
	field,
	fieldIndex
) => {
	const { formValues } = automationProps;

	return [
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`rate-plan-change-from-${fieldIndex}`}
			key={`rate-plan-change-from-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.FROM_KEY'} defaultMessage={'From'} />
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.frameValue`}
					options={getM2MAccountsByPlatform(automationProps)}
					onChange={() => {
						automationThis.resetFields('AutomationRuleDetailForm', {
							[`${field}.frameValue2`]: ''
						});
					}}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
					isDisabled={isWingPlaform(automationProps)}
				/>
			</div>
		</div>,
		<div
			className={cn(styles.custom_select_width, styles.custom_margin)}
			data-spec={`rate-plan-change-to-${fieldIndex}`}
			key={`rate-plan-change-to-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage id={'AUTOMATION.TO_KEY'} defaultMessage={'To'} />
			</div>
			<div className={styles.message_text}>
				<Field
					component={Select}
					name={`${field}.frameValue2`}
					options={getM2MAccountsByPlatform(automationProps)}
					isDisabled={
						!(
							formValues.automationRuleFrameActionValues &&
							formValues.automationRuleFrameActionValues[fieldIndex] &&
							formValues.automationRuleFrameActionValues[fieldIndex].frameValue
						) || isWingPlaform(automationProps)
					}
					validate={[required]}
					cleanValue
					components={{ Option, SingleValue }}
				/>
			</div>
		</div>
	];
};

const renderCustomFields = (styles, field, fieldIndex, automationProps) => {
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.width303]: true
	});

	return (
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.CUSTOM_FIELDS'}
					defaultMessage={'Custom Fields'}
				/>
			</div>
			<div className={styles.custom_select_width_first}>
				<FormattedMessage
					id={'AUTOMATION.CUSTOM_FIELDS'}
					defaultMessage={'Custom fields'}
				>
					{(formattedValue) => (
						<Field
							component={Select}
							name={`${field}.frameValue`}
							placeholder={formattedValue}
							isDisabled={isWingPlaform(automationProps)}
							validate={[required]}
							dataSpec={`${field}.frameValue`}
							options={getCustomFieldsOptions()}
						/>
					)}
				</FormattedMessage>
			</div>
		</div>
	);
};

const renderAccessRestrictions = (
	styles,
	field,
	fieldIndex,
	automationProps
) => {
	const classnames = cn({
		[styles.custom_select_width]: true,
		[styles.custom_margin]: true,
		[styles.width303]: true
	});

	return (
		<div
			className={classnames}
			data-spec={`treshold-${fieldIndex}`}
			key={`treshold-${fieldIndex}`}
		>
			<div className={styles.label}>
				<FormattedMessage
					id={'AUTOMATION.WHITELIST'}
					defaultMessage="Whitelist"
				/>
			</div>
			<div className={styles.custom_select_width_first}>
				<FormattedMessage
					id={'AUTOMATION.WHITELIST'}
					defaultMessage="Whitelist"
				>
					{(formattedValue) => (
						<Field
							component={Select}
							name={`${field}.frameValue`}
							placeholder={formattedValue}
							isDisabled={isWingPlaform(automationProps)}
							validate={[required]}
							dataSpec={`${field}.frameValue`}
							options={getImeiWhiteListActionOptions()}
						/>
					)}
				</FormattedMessage>
			</div>
		</div>
	);
};

export const renderFrameComponents = (
	automationProps,
	state,
	styles,
	automationThis,
	field,
	fieldIndex
) => {
	const { frames, formValues = {} } = automationProps;
	const type =
		(formValues.trigger &&
			formValues.trigger.automationTriggerFrameValueTypeId) ||
		1;
	switch (type) {
		case 2:
			return renderSimStateChange(
				styles,
				automationProps,
				automationThis,
				field,
				fieldIndex
			);
		case 3:
			return renderRatePlanChange(
				styles,
				automationProps,
				automationThis,
				field,
				fieldIndex
			);
		case 4:
			return renderTreshold(
				styles,
				formValues,
				frames,
				state,
				type,
				field,
				fieldIndex,
				automationProps
			);
		case 5:
			return renderZoneThreshold(
				styles,
				formValues,
				frames,
				state,
				field,
				fieldIndex,
				automationProps,
				automationThis
			);
		case 6:
			return renderCountries(styles, automationProps, field, fieldIndex);
		case 7:
			return renderPoolIdTreshold(
				styles,
				formValues,
				frames,
				state,
				field,
				fieldIndex,
				automationProps
			);
		case 8:
			return renderZoneNoThreshold(
				styles,
				formValues,
				frames,
				state,
				field,
				fieldIndex,
				automationProps,
				automationThis
			);
		case 9:
			return renderLocationUpdate(
				styles,
				formValues,
				frames,
				state,
				field,
				fieldIndex,
				automationProps,
				automationThis
			);
		case 10:
			return renderM2MAccounts(
				styles,
				automationProps,
				automationThis,
				field,
				fieldIndex
			);
		case 11:
			return renderZoneThresholdInput(
				styles,
				formValues,
				frames,
				state,
				field,
				fieldIndex,
				automationProps,
				automationThis
			);
		case 12:
			return renderShortCode(
				styles,
				formValues,
				frames,
				state,
				null,
				field,
				fieldIndex,
				automationProps
			);
		case 13:
			return renderCustomFields(styles, field, fieldIndex, automationProps);
		case 14:
			return renderAccessRestrictions(
				styles,
				field,
				fieldIndex,
				automationProps
			);
		default:
			return renderTreshold(
				styles,
				formValues,
				frames,
				state,
				null,
				field,
				fieldIndex,
				automationProps
			);
	}
};

const onCheckboxChange = (e, automationThis) => {
	if (!e.currentTarget.checked) {
		automationThis.resetFields('AutomationRuleDetailForm', {
			automationActionId2: '',
			automationActionValue2: '',
			automationActionSchedulerId2: '',
			automationActionSchedulerValue2: ''
		});
	}
};

export const renderFollowUpCheckbox = (
	styles,
	automationProps,
	automationThis
) => {
	const { formValues, automationActions } = automationProps;
	const automationActionId =
		formValues &&
		formValues.automationRuleFrameActionValues &&
		formValues.automationRuleFrameActionValues[0] &&
		formValues.automationRuleFrameActionValues[0].automationActionId &&
		(formValues.automationRuleFrameActionValues[0].automationActionId.value ||
			formValues.automationRuleFrameActionValues[0].automationActionId);

	const activeAction = find(automationActions, { id: automationActionId });
	return activeAction && activeAction.hasChildActions && automationActionId ? (
		<div className={styles.follow_up_with} data-spec="follow-up-with">
			<FormattedMessage
				id={'AUTOMATION.FOLLOW_UP_WITH'}
				defaultMessage={'Follow Up With'}
			>
				{(formattedValue) => (
					<Field
						type="checkbox"
						component={Checkbox}
						label={formattedValue}
						name="follow_up_with"
						onChange={(e) => onCheckboxChange(e, automationThis)}
						disabled={isWingPlaform(automationProps)}
					/>
				)}
			</FormattedMessage>
		</div>
	) : (
		<></>
	);
};

const Units = ({
	field,
	className,
	frames,
	formValues,
	fieldIndex,
	automationProps
}) => {
	const frame =
		formValues &&
		formValues.automationRuleFrameActionValues &&
		formValues.automationRuleFrameActionValues[fieldIndex] &&
		formValues.automationRuleFrameActionValues[fieldIndex].frame &&
		formValues.automationRuleFrameActionValues[fieldIndex].frame;
	const selectedFrameId = frame && (frame.value || frame);
	const getUnitsOptions = () => {
		const selectedFrame = find(frames, { id: selectedFrameId });
		if (selectedFrame) {
			return selectedFrame.automationFrameUnits.map((unit) => ({
				value: unit.id,
				label: (
					<FormattedMessage
						id={`AUTOMATION.${localizationHelper(unit.name).toUpperCase()}`}
						defaultMessage={unit.name}
					/>
				),
				isDefaultIndicator: unit.isDefaultIndicator
			}));
		}
		return [];
	};
	return selectedFrameId ? (
		<div className={className} data-spec="units">
			<Field
				component={Select}
				name={`${field}.automationFrameUnitId`}
				options={getUnitsOptions()}
				validate={[required]}
				cleanValue
				isDisabled={isWingPlaform(automationProps)}
			/>
		</div>
	) : null;
};

Units.propTypes = {
	field: PropTypes.string,
	className: PropTypes.string,
	frames: PropTypes.array,
	formValues: PropTypes.object,
	fieldIndex: PropTypes.number,
	automationProps: PropTypes.object
};
