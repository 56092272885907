import {
	userInStandardMode,
	userIsAuthenticated,
} from '../../../utils/AuthSelector';
import SingleEndpointView from '../views/SingleEndpointView';

export default [
	{
		path: '/endpoints/:type/:iccid',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.endpoints_rw', 'mnc.endpoints_ro'])(
				SingleEndpointView,
			),
		),
	},
];
