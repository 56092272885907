import qs from 'qs';
import { get } from '../../../services/ApiService';

export const getChartData = (requestChartSettings) =>
	get(`/analytics/data-usage?${qs.stringify(requestChartSettings)}`);

export const getDirectionIndex = (requestChartSettings) =>
	get(`/analytics/data-usage/adx?${qs.stringify(requestChartSettings)}`);

export const getCategories = () => get('/analytics/categories');

export const getAttributes = () => {
	const searchParams = {
		searchParams: [{ propValue: true, prop: 'groupby', operator: '=' }]
	};

	return get(`/analytics/attributes?${qs.stringify(searchParams)}`);
};

export const getFilters = ({ attributes, categoryId }) => {
	const additionalParams = {
		columnsToSelect: attributes
			.filter((attribute) => attribute.filter === true)
			.reduce((string, column) => `${column.name},${string}`, '')
	};
	const searchParams = [
		{
			propValue: categoryId,
			prop: 'categoryid',
			operator: '='
		}
	];

	return get(
		`/analytics/data-usage/filters?${qs.stringify({
			additionalParams,
			searchParams
		})}`
	);
};

export const exportChartDataCSV = (requestParams) =>
	get(`analytics/data-usage/export?${qs.stringify(requestParams)}`);
