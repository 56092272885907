import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import common from './StatusBox.scss';

function StatusBox(props) {
	const { disabled, label, newSimStatus, displayLabel, current } = props;
	const textToClass = (text) =>
		text
			.toLowerCase()
			.replace(' ', '_')
      .replace('-', '_');

	return (
		<div
			onClick={
				disabled
					? undefined
					: () => {
							props.onClick(props.label);
					  }
			}
			data-spec="status"
			className={cn(common.status, common[textToClass(label)], {
				[`${common.selected}`]: newSimStatus === label,
				[`${common.disabled}`]: disabled
			})}
		>
			<div className={cn(common.circle, common[textToClass(label)])} />
			<span>{displayLabel.toUpperCase()}</span>
			{current && (
				<span className={common.current_state}>
					<FormattedMessage id="ENDPOINTS.CURRENT" defaultMessage="CURRENT" />
				</span>
			)}
		</div>
	);
}

const { string, func, bool } = PropTypes;

StatusBox.propTypes = {
	label: string,
	newSimStatus: string,
	displayLabel: string,
	onClick: func,
	disabled: bool,
	current: bool
};

export default StatusBox;
