import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import { GET_SKUS } from '../constants';
import { getSkus as service } from '../../services/SimOrderingService';

export const getSkus = (params, me) =>
	globalAction(GET_SKUS, service, { params, me });

export const getSkusInit = () => actionInit(GET_SKUS);
