import React from 'react';
import {
	objectOf,
	string,
	array,
	func,
} from 'prop-types';

import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';

import hoc from '../Changes';

function Mobile({ data, options, messages }) {
	return (
		<CardsTable
			data-spec="history-changes-table-mobile"
			data={data}
			options={options(true)}
			messages={messages}
			mobile
		/>
	);
}

Mobile.propTypes = {
	data: array,
	options: func,
	messages: objectOf(string),
};

export default hoc(Mobile);
