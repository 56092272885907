import globalAction from '../../../redux/global/globalAction';
import {
	getAttributes as serviceGetAttributes,
	getCategories as serviceGetCategories,
	getFilters as serviceGetFilters
} from '../services/AnalyticsService';
import {
	GET_ANALYTICS_ATTRIBUTES,
	GET_ANALYTICS_CATEGORIES,
	GET_ANALYTICS_FILTERS
} from './constants';

export const getAttributes = () =>
	globalAction(GET_ANALYTICS_ATTRIBUTES, serviceGetAttributes);

export const getCategories = () =>
	globalAction(GET_ANALYTICS_CATEGORIES, serviceGetCategories);

export const getFilters = (attributes, categoryId) =>
	globalAction(GET_ANALYTICS_FILTERS, serviceGetFilters, {
		attributes,
		categoryId
	});
