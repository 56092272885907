import { Status } from '../../../../utils/constants';

/* ******************** GET PENDING INVITATIONS ******************** */
export const getRegistrationsRequest = (state) =>
	state.onboarding.registrations.getRegistrations.status === Status.PENDING;
export const getRegistrationsFail = (state) =>
	state.onboarding.registrations.getRegistrations.status === Status.FAIL;
export const getRegistrationsSuccess = (state) =>
	state.onboarding.registrations.getRegistrations.data;

/* ******************** CANCEL PENDING INVITATIONS ******************** */
export const cancelPendingInvitationRequest = (state) =>
	state.onboarding.registrations.cancelRegistration.status === Status.PENDING;
export const cancelPendingInvitationFail = (state) =>
	state.onboarding.registrations.cancelRegistration.status === Status.FAIL;
export const cancelPendingInvitationSuccess = (state) =>
	state.onboarding.registrations.cancelRegistration.data;

/* ******************** RESEND PENDING INVITATIONS ******************** */
export const resendInvitationRequest = (state) =>
	state.onboarding.registrations.resendRegistration.status === Status.PENDING;
export const resendInvitationFail = (state) =>
	state.onboarding.registrations.resendRegistration.status === Status.FAIL;
export const resendInvitationSuccess = (state) =>
	state.onboarding.registrations.resendRegistration.data;
