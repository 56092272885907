import React, { PureComponent } from 'react';
import { reduxForm } from 'redux-form';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

import Modal from '../../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../../lib/DigitalComponents/DSModal/ModalFooter';

import Button from '../../../../../lib/DigitalComponents/Button';
import styles from './RemoveUserGroupModal.scss';

const Icon = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');
class RemoveUserGroupModal extends PureComponent {
	render() {
		const { show, onClose, handleSubmit, invalid } = this.props;

		return (
			<Modal data-spec="add-user-group-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.REMOVE_USER_GROUP"
							defaultMessage="Remove User Group"
						/>
					}
					closeModal={onClose}
				/>
				<form onSubmit={handleSubmit}>
				<ModalBody className={styles.modal_body}>

					<div data-spec="body-message" className={styles.body}>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION"
								defaultMessage="Are you sure you want to perform this operation?"
							/>
						</div>
					</div>
					<div>
						<Icon />
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						type="button"
						data-spec="close-button"
						onClick={onClose}
					/>
					<Button
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.REMOVE"
								defaultMessage="REMOVE"
							/>
						}
						type="submit"
						data-spec="save-button"
						disabled={invalid}
					/>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { bool, func } = PropTypes;

RemoveUserGroupModal.propTypes = {
	onClose: func,
	show: bool,
	handleSubmit: func,
	invalid: bool
};

RemoveUserGroupModal.defaultProps = {
	show: false
};
const DeleteUserGroupForm = reduxForm({
	form: 'EditUserProfileForm',
	enableReinitialize: true,
	destroyOnUnmount: false,
})(RemoveUserGroupModal);

export default DeleteUserGroupForm;
