import {
	userIsAuthenticated,
	userInSupermode
} from '../../../utils/AuthSelector';
import FailoverView from '../views/FailoverView/FailoverView';

const failoverRoutes = [
	{
		path: '/failover',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated(['system.failover_rw', 'system.failover_ro'])(
				FailoverView
			)
		)
	}
];

export default failoverRoutes;
