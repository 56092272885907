import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';
import globalModalReducer from '../../../../redux/global/globalModalReducer';

import {
	ADD_RATE_PLAN,
	EDIT_RATE_PLAN,
	GET_ALLOWED_TYPES,
	GET_RATE_PLAN,
	GET_RATE_PLANS,
	GET_PARENT_RATE_PLANS,
	ADD_RATE_PLANS_MODAL
} from './constants';

export default combineReducers({
	addRatePlan: globalReducer(ADD_RATE_PLAN),
	editRatePlan: globalReducer(EDIT_RATE_PLAN),
	getAllowedTypes: globalReducer(GET_ALLOWED_TYPES),
	getRatePlan: globalReducer(GET_RATE_PLAN),
	getRatePlans: globalReducer(GET_RATE_PLANS),
	addRatePlansModal: globalModalReducer(ADD_RATE_PLANS_MODAL),
	getParentRatePlans: globalReducer(GET_PARENT_RATE_PLANS)
});
