import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import SideBar from './SideBar';

import {
	getCompanyAccountId,
	getUser,
	getCompanyFriendlyName
} from '../../../../redux/user/getUserData/selectors';

import getSidebarToggleStatus from '../../redux/sidebar/selectors';
import toggleSideBar from '../../redux/sidebar/actions';

const mapStateToProps = (state) => ({
	isCollapsed: getSidebarToggleStatus(state),
	user: getUser(state),
	companyAccountId: getCompanyAccountId(state),
	companyFriendlyName: getCompanyFriendlyName(state)
});

const mapDispatchToProps = (dispatch) => ({
	toggleSideBar: () => dispatch(toggleSideBar()),
	addCompany: () => dispatch(localizedRoute('/companies/add-company'))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SideBar);
