import { handleActions } from 'redux-actions';
import { findIndex as findIndexInArray } from 'lodash';

import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './constants';

export const initialState = {
	notifications: []
};

export default handleActions(
	{
		[SHOW_NOTIFICATION]: (state, action) => {
			const index = findIndexInArray(state.notifications, {
				id: action.payload.id
			});
			let notifications = [...state.notifications];

			if (index !== -1) {
				notifications = [
					...notifications.slice(0, index),
					...notifications.slice(index + 1)
				];
			}

			notifications = [...notifications, action.payload];

			return {
				...state,
				notifications
			};
		},
		[HIDE_NOTIFICATION]: (state, action) => {
			const placeInArray = state.notifications.findIndex(
				(x) => x.id === action.payload
			);
			return {
				...state,
				notifications:
					placeInArray !== -1
						? [
							...state.notifications.slice(0, placeInArray),
							...state.notifications.slice(placeInArray + 1)
						  ]
						: state.notifications
			};
		}
	},
	initialState
);
