import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const addSimSku = (data) =>
	ApiService.post('/onboarding/sim-orders/skus', data);

export const editSimSku = (params) => {
	const { id, data } = params;
	return ApiService.patch(`/onboarding/sim-orders/skus/${id}`, data);
};

export const deleteSimSku = (id) =>
	ApiService.remove(`/onboarding/sim-orders/skus/${id}`, {
		data: { simSkuId: id }
	});

export const getSkus = (params) => {
	const { isMyCompany, additionalParams, searchParams } = params;
	return ApiService.get(
		`/onboarding/sim-orders/skus${isMyCompany ? '/me' : ''}?${qs.stringify({
			additionalParams,
			searchParams
		})}`
	);
};

export const assignSimSkus = (params) => {
	const { accountId, simSkus, isMyCompany } = params;
	let url = `onboarding/platforms/${accountId}/assign-skus`;
	if (isMyCompany) {
		url = `onboarding/my-company/platforms/${accountId}/assign-skus`;
	}
	return ApiService.post(url, simSkus);
};

export const postAccountTransfer = (params) => {
	const { companyId, m2mAccountId, requestData } = params;
	return ApiService.post(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/account-transfer`,
		requestData
	);
};

export const getM2MAccounts = (params) =>
	ApiService.get(
		`/onboarding/platforms?${qs.stringify({
			searchParams: [
				{
					propValue: params.companyId,
					prop: 'companyId',
					operator: '='
				}
			]
		})}`
	);

export const getNetworkEntitlements = (params) => {
	const { companyId, m2mAccountId } = params;
	return ApiService.get(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/all-network-entitlements`
	);
};

export const getRatePlans = (params) =>
	ApiService.get(
		`/onboarding/companies/${params.companyId}/m2m-accounts/${params.m2mAccountId}/rate-plans`
	);
