import React from 'react';
import {
	func,
	bool,
	string,
} from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './PromptModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

export default function PromptModal({
	onlyBody,
	type,
	show,
	onCancel,
	preventBodyScrollingWhenOpened,
	onConfirm,
}) {
	if (onlyBody) {
		return (
			<div data-spec="prompt">
				<div
					className={cn(
						styles.dashboard_modal_body,
						type === 'required' ? styles.dashboard_modal_body_required : '',
						styles.modal_body_only,
					)}
				>
					{type === 'required'
						? (
							<div data-spec="body-message" className={styles.prompt_body}>
								<div className={styles.modal_message}>
									<FormattedMessage
										id="SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD"
										defaultMessage="Elevated permissions required!"
									/>
								</div>
								<div className={styles.modal_text_block}>
									<div className={styles.modal_message}>
										<FormattedMessage
											id="SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION"
											defaultMessage="Only administrators can modify custom field types as they have a system-wide configuration impact. Contact your administrator to update custom fields options."
										/>
									</div>
								</div>
							</div>
						)
						: (
							<div data-spec="body-message" className={styles.prompt_body}>
								<div className={styles.modal_message}>
									<FormattedMessage
										id="SINGLE_ENDPOINT.PERMANENT_CUSTOM_FIELD_DELETION"
										defaultMessage="Permanent custom field deletion!"
									/>
								</div>
								<div className={styles.modal_text_block}>
									<div className={styles.modal_message}>
										<FormattedMessage
											id="SINGLE_ENDPOINT.DELETING_THIS_CUSTOM_FIELD"
											defaultMessage="Deleting this custom field will delete its all related data! This action cannot be undone!"
										/>
									</div>
								</div>
							</div>
						)}
					<div>
						<Prompt />
					</div>
				</div>
				<div className={styles.modal_prompt_buttons}>
					{type === 'required'
						? (
							<Button
								variant="primary"
								onClick={onCancel}
								label={<FormattedMessage
									id="DONE"
									defaultMessage="Done"
								/>}
							/>
						)
						: (
							<>
								<Button
									onClick={onCancel}
									variant="link"
									label={<FormattedMessage
										id="CANCEL"
										defaultMessage="Cancel"
									/>}
								/>
								<Button
									variant="primary"
									onClick={onConfirm}
									label={<FormattedMessage
										id="DELETE_FIELD"
										defaultMessage="Delete Field"
									/>}
								/>
							</>
						)}
				</div>
			</div>
		);
	}

	return (
		<Modal
			data-spec="dashboard-prompt-modal"
			show={show}
			onClose={onCancel}
			preventBodyScrollingWhenOpened={preventBodyScrollingWhenOpened}
		>
			<ModalHeader
				title={<FormattedMessage
					id="ARE_YOU_SURE"
					defaultMessage="Are you sure?"
				/>}
			/>
			<ModalBody
				className={cn(
					styles.dashboard_modal_body,
					type === 'required' ? styles.dashboard_modal_body_required : '',
				)}
			>
				{type === 'required'
					? (
						<div data-spec="body-message" className={styles.prompt_body}>
							<div className={styles.modal_message}>
								<FormattedMessage
									id="SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD"
									defaultMessage="Elevated permissions required!"
								/>
							</div>
							<div className={styles.modal_text_block}>
								<div className={styles.modal_message}>
									<FormattedMessage
										id="SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION"
										defaultMessage="Only administrators can modify custom field types as they have a system-wide configuration impact. Contact your administrator to update custom fields options."
									/>
								</div>
							</div>
						</div>
					)
					: (
						<div data-spec="body-message" className={styles.prompt_body}>
							<div className={styles.modal_message}>
								<FormattedMessage
									id="SINGLE_ENDPOINT.PERMANENT_CUSTOM_FIELD_DELETION"
									defaultMessage="Permanent custom field deletion!"
								/>
							</div>
							<div className={styles.modal_text_block}>
								<div className={styles.modal_message}>
									<FormattedMessage
										id="SINGLE_ENDPOINT.DELETING_THIS_CUSTOM_FIELD"
										defaultMessage="Deleting this custom field will delete its all related data! This action cannot be undone!"
									/>
								</div>
							</div>
						</div>
					)}
				<div>
					<Prompt />
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				{type === 'required'
					? (
						<Button
							variant="primary"
							onClick={onCancel}
							label={<FormattedMessage
								id="DONE"
								defaultMessage="Done"
							/>}
						/>
					)
					: (
						<>
							<Button
								onClick={onCancel}
								variant="link"
								label={<FormattedMessage
									id="CANCEL"
									defaultMessage="Cancel"
								/>}
							/>
							<Button
								variant="primary"
								onClick={onConfirm}
								label={<FormattedMessage
									id="DELETE_FIELD"
									defaultMessage="Delete Field"
								/>}
							/>
						</>
					)}
			</ModalFooter>
		</Modal>
	);
}

PromptModal.propTypes = {
	onCancel: func,
	onConfirm: func,
	show: bool,
	type: string,
	onlyBody: bool,
	preventBodyScrollingWhenOpened: bool,
};

PromptModal.defaultProps = {
	onCancel: undefined,
	onConfirm: undefined,
	show: false,
	type: '',
	onlyBody: false,
	preventBodyScrollingWhenOpened: false,
};
