import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';
import globalModalReducer from '../../../../redux/global/globalModalReducer';

import {
	ADD_SIM_SKU,
	DELETE_SIM_SKU,
	DELETE_SIM_SKU_MODAL,
	EDIT_SIM_SKU,
	EDIT_SIM_SKU_MODAL,
	GET_PARENT_SIM_SKUS,
	ADD_SIM_SKU_MODAL,
	ADD_SUB_ACCOUNT_SIM_SKU,
	GET_SKUS,
	POST_ACCOUNT_TRANSFER,
	GET_M2M_ACCOUNTS,
	GET_NETWORK_ENTITLEMENTS,
	GET_RATE_PLANS
} from './constants';

export default combineReducers({
	addSimSku: globalReducer(ADD_SIM_SKU),
	deleteSimSku: globalReducer(DELETE_SIM_SKU),
	deleteSimSkuModal: globalModalReducer(DELETE_SIM_SKU_MODAL),
	editSimSku: globalReducer(EDIT_SIM_SKU),
	editSimSkuModal: globalModalReducer(EDIT_SIM_SKU_MODAL),
	getParentSimSkus: globalReducer(GET_PARENT_SIM_SKUS),
	addSimSkuModal: globalModalReducer(ADD_SIM_SKU_MODAL),
	addSubAccountSimSku: globalReducer(ADD_SUB_ACCOUNT_SIM_SKU),
	getSKUs: globalReducer(GET_SKUS),
	getM2MAccounts: globalReducer(GET_M2M_ACCOUNTS),
	getNetworkEntitlements: globalReducer(GET_NETWORK_ENTITLEMENTS),
	getRatePlans: globalReducer(GET_RATE_PLANS),
	postAccountTransfer: globalReducer(POST_ACCOUNT_TRANSFER)
});
