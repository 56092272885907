import React, { PureComponent } from 'react';
import { func, object, bool, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { findIndex } from 'lodash';

import Button from '../../../../lib/DigitalComponents/Button';
import Table from '../../../../lib/DigitalComponents/Table';
import Loader from '../../../../lib/DigitalComponents/Loader';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';

import EditAssignAccountAccordion from '../EditAssignAccountAccordion';

import { AssignedAccountsTableHeader as tableHeader } from '../../utils/constants';

import styles from './EditUserGroupAssignedAccounts.scss';

class EditUserGroupAssignedAccounts extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			dataOffset: 0,
			dataLimit: 50,
			selectedPage: 0,
			orderColumn: {},
			expanded: [],
			selectedAccounts: [],
			selectedAccountsFiltered: false,
			enableButton: false,
		};
	}

	componentDidMount() {
		this.getAccounts();
	}

	componentDidUpdate(prevProps) {
		const { accountsSuccess, userGroupSuccess } = this.props;
		if (
			accountsSuccess &&
			prevProps.accountsSuccess !== accountsSuccess &&
			userGroupSuccess &&
			userGroupSuccess.platform
		) {
			this.filterAccounts();
		}
	}

	componentWillUnmount() {
		const { getAccountsInit } = this.props;
		getAccountsInit();
	}

	filterAccounts = () => {
		const filteredAccounts = [];
		const { accountsSuccess, userGroupSuccess } = this.props;
		accountsSuccess &&
			accountsSuccess.resultList.forEach((account) => {
				if (userGroupSuccess.platform.find((item) => item.id === account.id)) {
					filteredAccounts.push(account);
					if (account.subPlatforms && account.subPlatforms.length > 0) {
						account.subPlatforms.forEach((subAccount) => {
							if (
								userGroupSuccess.platform.find(
									(item) => item.id === subAccount.id
								)
							) {
								filteredAccounts.push(account);
							}
						});
					}
				}
				this.setSelectedAccounts(filteredAccounts);
			});
	};

	setSelectedAccounts = (filteredAccounts) => {
		this.setState({
			selectedAccounts: [...filteredAccounts],
			selectedAccountsFiltered: true,
		});
	};

	getUserGroup = (id) => {
		const { getUserGroup } = this.props;
		const params = {
			additionalParams: {
				include: ['Platform'],
			},
		};
		getUserGroup({
			id,
			params,
		});
	};

	getAccounts = () => {
		const { getAccounts } = this.props;
		const { orderColumn, dataLimit, dataOffset } = this.state;

		const searchParams = [
			{ propValue: null, prop: 'parentPlatformId', operator: '=' },
		];
		getAccounts({
			isMyCompany: true,
			searchParams,
			additionalParams: {
				include: ['SubPlatforms', 'SubPlatforms.SubPlatforms'],
				dataLimit: dataLimit || 50,
				dataOffset: dataOffset || 0,
				dataSort: orderColumn.apiString || 'createdAt desc',
			},
		});
	};

	getOptions = (target) => {
		const { accountsSuccess } = this.props;
		const { selectedPage, orderColumn, dataLimit } = this.state;

		// let isMobile = target === 'mobile';
		const isDesktop = target === 'desktop';

		const options = {};

		options.header = tableHeader;

		options.tableOptions = {
			showPagination:
				accountsSuccess &&
				accountsSuccess.resultList &&
				accountsSuccess.resultList.length > dataLimit,
			pageCount: this.totalCount(),
			forcePage: selectedPage,
			onPageChange: (data) => this.handlePageClick(data),
		};

		if (isDesktop) {
			options.tableOptions = {
				...options.tableOptions,
				orderByData: ['all'],
				excludeFromOrdering: ['actions'],
				getOrderData: (data) => this.onOrderClick(data),
				orderColumn,
			};
		}

		options.customComponents = {};

		if (isDesktop) {
			options.customComponents = {
				...options.customComponents,
				m2mAccountName: {
					type: 'custom',
					component: (val, index) => this.renderM2mAccountName(val, index),
				},
				actions: {
					type: 'custom',
					component: (val, index) => this.renderActions(val, index),
				},
				customExpand: {
					type: 'custom',
					component: (val) => (
						<EditAssignAccountAccordion
							data={val.subPlatforms}
							parentAccountId={val.m2mAccountId}
							accounts={accountsSuccess.resultList}
							checkIfRowIsSelected={this.checkIfRowIsSelected}
							onCheckBoxChange={this.onCheckBoxChange}
						/>
					),
				},
			};
		}
		return options;
	};

	renderM2mAccountName = (val, index) => {
		const getRender = Boolean(val.subPlatforms && val.subPlatforms.length);
		const triggerIcon = cn({
			[styles.trigger__icon]: true,
			[styles.no_subplatforms]: !this.hasSubPlatforms(val),
			[styles.open]: this.findLevel(index),
		});
		return (
			<div data-spec="m2m-acc-name" className={styles.m2m_account_name}>
				{getRender && (
					<span
						onClick={() => this.handleIconClick(index)}
						className={triggerIcon}
					/>
				)}
				<div>{val.m2mAccountName}</div>
			</div>
		);
	};

	renderActions = (data, index) => (
		<Checkbox
			data-spec="checkbox"
			checked={this.checkIfRowIsSelected(data)}
			onChange={() => this.onCheckBoxChange(data, index)}
		/>
	);

	onCheckBoxChange = (data) => {
		const { selectedAccounts } = this.state;
		const index = findIndex(selectedAccounts, { id: data.id });
		if (index != -1) {
			this.setState({
				selectedAccounts: [
					...selectedAccounts.slice(0, index),
					...selectedAccounts.slice(index + 1),
				],
				enableButton: true,
			});
		} else {
			this.setState({
				selectedAccounts: [...selectedAccounts, data],
				enableButton: true,
			});
		}
	};

	checkIfRowIsSelected = (data) => {
		const { accountsSuccess } = this.props;
		const { selectedAccounts } = this.state;
		if (accountsSuccess && selectedAccounts.length > 0) {
			return !!selectedAccounts.find((item) => item.id === data.id);
		}
		if (accountsSuccess && selectedAccounts.length === 0) {
			return true;
		}
	};

	totalCount = () => {
		const { accountsSuccess } = this.props;
		const { dataLimit } = this.state;
		return Math.ceil(accountsSuccess && accountsSuccess.totalCount / dataLimit);
	};

	findLevel = (id) => {
		const { expanded } = this.state;
		return expanded.includes(id);
	};

	handleIconClick = (id) => {
		const { expanded } = this.state;
		if (expanded.includes(id)) {
			const index = expanded.indexOf(id);
			this.setState({
				expanded: [...expanded.slice(0, index), ...expanded.slice(index + 1)],
			});
		} else {
			this.setState({
				expanded: [...expanded, id],
			});
		}
	};

	hasSubPlatforms = (level) =>
		Boolean(level && level.subPlatforms && level.subPlatforms.length);

	onOrderClick = (data) => {
		const { name: column, apiString } = data;
		const { orderColumn } = this.state;
		this.setState(
			{
				orderColumn: {
					name: column,
					order: orderColumn.name === column ? !orderColumn.order : true,
					apiString,
				},
			},
			() => this.getAccounts()
		);
	};

	handlePageClick = (data) => {
		const { dataLimit } = this.state;
		this.setState(
			{
				dataLimit,
				dataOffset: dataLimit * data.selected,
				selectedPage: data.selected,
			},
			() => this.getAccounts()
		);
		window.scrollTo(0, 0);
	};

	pushBack = () => {
		const { push, userGroupId } = this.props;
		const url = `/user-groups/${userGroupId}/assigned-accounts`;

		push(url);
	};

	submitForm = (e) => {
		e.preventDefault();
		const { editUserGroupAssignedAccounts, userGroupId } = this.props;
		const { selectedAccounts } = this.state;

		const userGroupPlatformInsertRequest = selectedAccounts.map((item) => ({
			platformId: item.id,
		}));

		editUserGroupAssignedAccounts({
			id: userGroupId,
			userGroupPlatformInsertRequest,
		});
	};

	render() {
		const {
			accountsRequest,
			accountsSuccess,
			userGroupRequest,
			editUserGroupAssignedAccountsRequest,
			messages,
		} = this.props;

		const {
			expanded,
			selectedAccountsFiltered,
			enableButton
		} = this.state;
		return (
			<div data-spec="edit-assigned-accounts" className={styles.wrapper}>
				{(accountsRequest ||
					userGroupRequest ||
					editUserGroupAssignedAccountsRequest ||
					!selectedAccountsFiltered) && <Loader data-spec="loading" />}
				{!accountsRequest &&
					!userGroupRequest &&
					!editUserGroupAssignedAccountsRequest &&
					selectedAccountsFiltered && (
						<form onSubmit={this.submitForm}>
							<Table
								data-spec="accounts-table-desktop"
								data={accountsSuccess && accountsSuccess.resultList}
								options={this.getOptions('desktop')}
								messages={messages}
								expanded={expanded}
							/>
							<br />
							<>
								<div className={styles.section_buttons}>
									<Button
										label={
											<FormattedMessage id="CANCEL" defaultMessage="Cancel" />
										}
										variant="outline-primary"
										type="button"
										dataSpec="cancel-button"
										onClick={this.pushBack}
									/>
									<Button
										label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
										variant="primary"
										type="submit"
										dataSpec="submit-button"
										disabled={
											!enableButton ||
											(accountsSuccess &&
												accountsSuccess.resultList &&
												accountsSuccess.resultList.length < 2)
										}
									/>
								</div>
							</>
						</form>
					)}
			</div>
		);
	}
}

EditUserGroupAssignedAccounts.propTypes = {
	getAccounts: func,
	getAccountsInit: func,
	accountsRequest: bool,
	accountsSuccess: object,
	getUserGroup: func,
	userGroupRequest: bool,
	userGroupSuccess: object,
	editUserGroupAssignedAccounts: func,
	editUserGroupAssignedAccountsRequest: bool,
	messages: object,
	push: func,
	userGroupId: string,
};

export default EditUserGroupAssignedAccounts;
