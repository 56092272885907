import {
	addNetworkEntitlement,
	editNetworkEntitlement
} from '../../redux/networkEntitlements/actions';

const submitForm = (values, dispatch, props) => {
	const { companyId, platformType, m2mAccountId, accountId } = props;
	const networkEntitlement = {};

	if (values.onStateProfile) {
    networkEntitlement.onStateProfile = {};

		if (values.onStateProfile.roaming !== undefined) {
			networkEntitlement.onStateProfile.roaming = values.onStateProfile.roaming;
		}

		// Checkbox
		if (values.onStateProfile.roamingLTE !== undefined) {
			networkEntitlement.onStateProfile.roamingLTE = !!(
				values.onStateProfile.roamingLTE === 'true' ||
				values.onStateProfile.roamingLTE === true
			);
		}

		if (values.onStateProfile.camelServiceType !== undefined) {
			networkEntitlement.onStateProfile.camelServiceType =
				values.onStateProfile.camelServiceType;
		}

		if (values.onStateProfile.networkCountry !== undefined) {
			networkEntitlement.onStateProfile.networkCountry =
				values.onStateProfile.networkCountry;
		}

		if (values.onStateProfile.hotlineNumber !== undefined) {
			networkEntitlement.onStateProfile.hotlineNumber =
				values.onStateProfile.hotlineNumber;
		}

		if (values.onStateProfile.networkAccessConfig !== undefined) {
			networkEntitlement.onStateProfile.networkAccessConfig =
				values.onStateProfile.networkAccessConfig;
		}
	}

  if (values.offStateProfile) {
    networkEntitlement.offStateProfile = {};

		if (values.offStateProfile.roaming !== undefined) {
			networkEntitlement.offStateProfile.roaming = values.offStateProfile.roaming;
		}

		// Checkbox
		if (values.offStateProfile.roamingLTE !== undefined) {
			networkEntitlement.offStateProfile.roamingLTE = !!(
				values.offStateProfile.roamingLTE === 'true' ||
				values.offStateProfile.roamingLTE === true
			);
		}

		if (values.offStateProfile.camelServiceType !== undefined) {
			networkEntitlement.offStateProfile.camelServiceType =
				values.offStateProfile.camelServiceType;
		}

		if (values.offStateProfile.networkCountry !== undefined) {
			networkEntitlement.offStateProfile.networkCountry =
				values.offStateProfile.networkCountry;
		}

		if (values.offStateProfile.hotlineNumber !== undefined) {
			networkEntitlement.offStateProfile.hotlineNumber =
				values.offStateProfile.hotlineNumber;
		}

		if (values.offStateProfile.networkAccessConfig !== undefined) {
			networkEntitlement.offStateProfile.networkAccessConfig =
				values.offStateProfile.networkAccessConfig;
		}
	}

	const additionalData = {
		platformType,
		companyId,
		m2mAccountId,
		accountId
	};

	if (props && props.entitlement && props.entitlement.id) {
		dispatch(
			editNetworkEntitlement({
				id: values.id,
				networkEntitlement,
				additionalData
			})
		);
	} else {
		networkEntitlement.id = values.id;
		networkEntitlement.apnInsertRequest = values.apns
			? values.apns.map((apn) => {
					const temp = {
						name: apn.name,
						pdpindex: apn.pdpindex,
						description: apn.description,
						lte: !!(apn.lte === 'true' || apn.lte === true),
						security: !!(apn.security === 'true' || apn.security === true),
						fixedIP: !!(apn.fixedIP === 'true' || apn.fixedIP === true),
						subscription: !!(
							apn.subscription === 'true' || apn.subscription === true
						)
					};
					delete temp.id;
					return temp;
			  })
			: [];
		dispatch(addNetworkEntitlement({ networkEntitlement, additionalData }));
	}
};

export default submitForm;
