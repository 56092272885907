import React, { PureComponent } from 'react';
import { object, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './SatelliteDetails.scss';

export default class SatelliteDetails extends PureComponent {
	componentDidMount() {
		const { availableStates, getAvailableStates } = this.props;

		if (!availableStates) {
			getAvailableStates();
		}
	}

	render() {
		const { availableStates, endpointData, hardware } = this.props;

		if (!availableStates) {
			return <Loader data-spec="loader" />;
		}

    return (
      <div data-spec="data-overview" className={styles.data_wrap}>
        <div className={`${styles.column} ${styles.column_custom}`}>
          <div className={styles.data}>
	          <span>
		          <FormattedMessage
			          id="DEVICE_NAME"
			          defaultMessage="Device Name"
		          />
	          </span>
            <span>{endpointData.name || '-'}</span>
          </div>
          <div className={styles.data}>
            <span>
	            <FormattedMessage
		            id="DEVICE_MODULE_NUMBER"
		            defaultMessage="Device Module Number"
	            />
            </span>
            <span>{hardware.modelNumber || '-'}</span>
          </div>
          <div className={styles.data}>
            <span>
	            <FormattedMessage
		            id="DEVICE_MANUFACTURER"
		            defaultMessage="Device Manufacturer"
	            />
            </span>
            <span>{hardware.manufacturer || '-'}</span>
          </div>
        </div>
        <div className={`${styles.column} ${styles.column_custom}`}>
          <div className={styles.data}>
            <span>
	            <FormattedMessage
		            id="DEVICE_NETWORK_TYPE"
		            defaultMessage="Device Network Type"
	            />
            </span>
            <span>{endpointData.type || '-'}</span>
          </div>

          <div className={styles.data}>
            <span>
	            <FormattedMessage
		            id="DEVICE_ID"
		            defaultMessage="Device ID"
	            />
            </span>
            <span>{endpointData.name || '-'}</span>
          </div>
        </div>
      </div>
    );
  }
}

SatelliteDetails.propTypes = {
	availableStates: object,
	getAvailableStates: func,
	endpointData: object,
	hardware: object
};
SatelliteDetails.defaultProps = {
	availableStates: {},
	getAvailableStates: undefined,
	endpointData: {},
	hardware: {},
};
