/* eslint-disable no-plusplus */
import ApiClient from 'utils/ApiClient';
import axios from 'axios';
import qs from 'qs';

export const getBatchFiles = (paginationData) => ApiClient.get(`/batch/getFiles?${qs.stringify(paginationData)}`)
		.then((response) => {
			if (response.data === '') {
				return {};
			}
			return response.data;
		})
		.catch((error) => {
			throw { error: error.response.data };
		});

export const batchFileRequestURL = (data, activationFile, type) => {
	if (activationFile) {
		(data.m2mAccountId = activationFile.m2mAccountId);
		(data.m2mPlatform = activationFile.m2mPlatform);
	}
	const url =
		type === 'securityFeatures'
			? '/customers/security-features/upload'
			: '/batch/upload';
	return ApiClient.post(url, data)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});
};

export const batchFileUpload = (url, file) => {
	const options = {
		headers: {
			'Content-Type': 'application/octet-stream'
		}
	};
	return axios
		.put(url, file, options)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});
};

export const checkBatchUploadStatus = (data, type) => {
	const url =
		type === 'securityFeatures'
			? `/customers/security-features/upload/${data.id}`
			: `/batch/upload?${qs.stringify(data)}`;

	return ApiClient.get(url)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});
};

export const pollForFile = (data, retries, delay, type) => {
	if (--retries > 0) {
		return new Promise(function (resolve, reject) {
			setTimeout(() => {
				checkBatchUploadStatus(data, type)
					.then((response) => {
						const responseType =
							type === 'securityFeatures' ? response : response.data;
						if (responseType.status == 0 || responseType.status == 1) {
							resolve(pollForFile(data, retries, delay, type));
						} else if (responseType.status == 2) {
							resolve(responseType);
						} else {
							reject({ error: response.errors });
						}
					})
					.catch(() => {
						reject({ error: [] });
					});
			}, delay);
		});
	}
  return new Promise(function (resolve, reject) {
    reject({ error: [] });
  });
};

export const getBatchFileTemplates = () => {
	const url = '/reports/allReports?fileType=template&fileCategory=Other';
	return ApiClient.get(url)
		.then((response) => {
			let values = [];
			Object.keys(response.data).forEach((key) => {
				if (key !== 'messageId') {
					values = [...values, ...response.data[key]];
				}
			});
			return values;
		})
		.catch((error) => {
			throw { error: error.response.data };
		});
};
