import { connect } from 'react-redux';

import isSendSmsModalOpened
	from '../../../../redux/modals/sendSmsModal/selectors';
import {
	cancelRegistration,
	getDiagnostic,
	getRegistration,
} from '../../redux/actions';
import {
	cancelRegistrationRequest,
	getEndpointDiagnosticsDetails,
	getEndpointRegistrationDetails,
	isGettingEndpointsDiagnosticsDetails,
	isGettingEndpointsRegistrationDetails,
	isSuccessEndpointsRegistrationDetails,
	sendSmsMessageRequest,
} from '../../redux/selectors';
import DiagnosticWrapper from './DiagnosticWrapper';

const mapStateToProps = (state) => (
	{
		dataRegistrationDetails: getEndpointRegistrationDetails(state),
		isFetchingRegistrationDetails: isGettingEndpointsRegistrationDetails(state),
		isCanceling: cancelRegistrationRequest(state),
		isSendSmsModalOpen: isSendSmsModalOpened(state),
		dataDiagnosticsDetails: getEndpointDiagnosticsDetails(state),
		isFetchingDiagnosticsDetails: isGettingEndpointsDiagnosticsDetails(state),
		isSuccessEndpointsRegistrationDetails: isSuccessEndpointsRegistrationDetails(
			state
		),
		deviceWakeUpRequest: sendSmsMessageRequest(state)
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		onButtonClick: (iccid) => dispatch(getDiagnostic(iccid)),
		getRegistrationDetails: (iccid) =>
			dispatch(getRegistration(iccid)),
		submitCancelRegistration: (iccid) => dispatch(cancelRegistration(iccid)),
	}
);

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticWrapper);
