import React from 'react';
import { object } from 'prop-types';
import { globalPaginationNumber } from '../../../../utils/constants';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import {
	DesktopSingleEndpoint,
	TabletSingleEndpoint,
	MobileSingleEndpoint,
} from '../../components/SingleEndpoint';

export default function SingleEndpointView({ match, messages }) {
	return (
		<>
			<DesktopMediaQuery>
				<DesktopSingleEndpoint
					match={match}
					dataLimit={globalPaginationNumber.desktop}
					messages={messages}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<TabletSingleEndpoint
					match={match}
					dataLimit={globalPaginationNumber.desktop}
					messages={messages}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<MobileSingleEndpoint
					match={match}
					dataLimit={globalPaginationNumber.mobile}
					messages={messages}
				/>
			</MobileMediaQuery>
		</>
	);
}

SingleEndpointView.propTypes = {
	match: object,
	messages: object,
};
