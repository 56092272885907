import React, { useState } from 'react';
import { object, bool, func, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import hoc from '../EditPermissions';

import UnselectRadio from '../../../../../lib/DigitalComponents/UnselectRadio';
import Button from '../../../../../lib/DigitalComponents/Button';

import styles from './Desktop.scss';

function PermissionItem({
	permission,
	read,
	readWrite,
	noAccess,
	dispatch,
	indentLevel
}) {
	const [isExpanded, setIsExpanded] = useState(true);

	const renderName = (val) => {
		const getRender = Boolean(
			val.childPermission && val.childPermission.length
		);
		const triggerIcon = cn({
			[styles.trigger__icon]: true,
			[styles.no_subpermissions]:
				val.childPermission && val.childPermission.length < 1,
			[styles.open]: isExpanded
		});

		const name = cn({
			[styles.level_one_indent]: indentLevel === 1,
			[styles.level_one_indent_no_children]: indentLevel === 1 && !getRender,
			[styles.level_two_indent]: indentLevel === 2
		});

		return (
			<div data-spec="name" className={styles.name_container}>
				{getRender && (
					<span
						onClick={() => setIsExpanded(!isExpanded)}
						className={triggerIcon}
					/>
				)}
				<div className={name}>{val.name}</div>
			</div>
		);
	};

	const getRowClass = (val) => {
		const getRender = Boolean(
			val.childPermission && val.childPermission.length
		);

		const level = cn({
			[styles.gray_row]: !getRender
		});

		return level;
	};

	return (
		<>
			<tr className={getRowClass(permission)}>
				<td>{renderName(permission)}</td>
				<td>
					<Field
						component={UnselectRadio}
						name={`${permission.id}-${permission.name}`}
						value="noAccess"
						type="radio"
						unselectradio={() =>
							dispatch(change('editPermissionsForm', `${permission.id}-${permission.name}`, ''))}
					/>
				</td>
				<td>
					<Field
						component={UnselectRadio}
						name={`${permission.id}-${permission.name}`}
						value="read"
						type="radio"
						unselectradio={() =>
							dispatch(change('editPermissionsForm', `${permission.id}-${permission.name}`, ''))}
					/>
				</td>
				<td>
					<Field
						component={UnselectRadio}
						name={`${permission.id}-${permission.name}`}
						value="readWrite"
						type="radio"
						unselectradio={() =>
							dispatch(change('editPermissionsForm', `${permission.id}-${permission.name}`, ''))}
					/>
				</td>
			</tr>

			{isExpanded && (
				<>
					{permission.childPermission &&
						permission.childPermission.map((item) => (
							<PermissionItem
								key={item.id}
								permission={item}
								read={read || permission.read}
								readWrite={readWrite || permission.readWrite}
								noAccess={noAccess || permission.noAccess}
								dispatch={dispatch}
								indentLevel={indentLevel + 1}
							/>
						))}
				</>
			)}
		</>
	);
}

function Desktop(props) {
	const { permissions, dispatch, onClose, handleSubmit } = props;
	return (
		<form data-spec="permissions-form" onSubmit={handleSubmit}>
			<table
				data-spec="permissions-desktop"
				className={styles.permissions_table}
			>
				<thead>
					<tr>
						<th>
							<FormattedMessage
								id="ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS"
								defaultMessage="System-Level Entitlements"
							/>
						</th>
						<th>
							<FormattedMessage
								id="ONBOARDING.DENY_ACCESS"
								defaultMessage="Deny Access"
							/>
						</th>
						<th>
							<FormattedMessage
								id="ONBOARDING.ALLOW_READ"
								defaultMessage="Allow Read"
							/>
						</th>
						<th>
							<FormattedMessage
								id="ONBOARDING.ALLOW_WRITE"
								defaultMessage="Allow Write"
							/>
						</th>
					</tr>
				</thead>
				<tbody>
					{permissions.resultList.map((item) => (
						<PermissionItem
							key={item.id}
							permission={item}
							read={item.read}
							readWrite={item.readWrite}
							noAccess={item.noAccess}
							dispatch={dispatch}
							indentLevel={0}
						/>
					))}
				</tbody>
			</table>
			<br />
			<>
				<hr className={styles.divider} />
				<div className={styles.section_buttons}>
					<Button
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						variant="outline-primary"
						type="button"
						dataSpec="cancel-button"
						onClick={onClose}
					/>
					<Button
						label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
						variant="primary"
						type="submit"
						dataSpec="submit-button"
					/>
				</div>
			</>
		</form>
	);
}

Desktop.propTypes = {
	permissions: object,
	dispatch: func,
	onClose: func,
	handleSubmit: func
};

PermissionItem.propTypes = {
	permission: object,
	readWrite: bool,
	read: bool,
	noAccess: bool,
	dispatch: func,
	indentLevel: number
};

let DesktopForm = reduxForm({
	form: 'editPermissionsForm',
	enableReinitialize: true
})(Desktop);

DesktopForm = connect((state, props) => {
	let fields = {};
	const initialPermissions = props.permissions;
	if (initialPermissions && initialPermissions.resultList.length > 0) {
		initialPermissions.resultList.forEach((permissions) => {
			fields = {
				...fields,
				[`${permissions.id}-${permissions.name}`]:
					(permissions.noAccess && 'noAccess') ||
					(permissions.read && 'read') ||
					(permissions.readWrite && 'readWrite') ||
					''
			};
			if (
				permissions.childPermission &&
				permissions.childPermission.length > 0
			) {
				permissions.childPermission.forEach((item) => {
					fields = {
						...fields,
						[`${item.id}-${item.name}`]:
							(item.noAccess && 'noAccess') ||
							(item.read && 'read') ||
							(item.readWrite && 'readWrite') ||
							''
					};
					if (item.childPermission && item.childPermission.length > 0) {
						item.childPermission.forEach((newItem) => {
							fields = {
								...fields,
								[`${newItem.id}-${newItem.name}`]:
									(newItem.noAccess && 'noAccess') ||
									(newItem.read && 'read') ||
									(newItem.readWrite && 'readWrite') ||
									''
							};
						});
					}
				});
			}
		});

		return {
			initialValues: fields
		};
	}
	return { fields };
})(DesktopForm);

export default hoc(DesktopForm);
