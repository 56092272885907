import React from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import { defaultUserManagementTab } from '../../utils/constants';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';

import UserGroups from '../../views/UserGroupsView';
import Users from '../../views/UsersView';
import PendingInvitations from '../../views/PendingInvitationsView';

import styles from './UserManagement.scss';
import Loader from '../../../../lib/DigitalComponents/Loader';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

const CompanyLogo = ImageSelector(
	CurrentContext.theme,
	'svgs/company-image-placeholder.svg'
);

const availableUserManagementTabs = [
	{
		services: [
			'mnc.accessmanagement.users_rw',
			'mnc.accessmanagement.users_ro',
		],
		tab: {
			id: 'userManagement',
			label: <FormattedMessage id="ONBOARDING.USERS" defaultMessage="Users" />,
		},
	},
	{
		services: [
			'mnc.accessmanagement.usergroups_rw',
			'mnc.accessmanagement.usergroups_ro',
		],
		tab: {
			id: 'userGroups',
			label: (
				<FormattedMessage
					id="ONBOARDING.USER_GROUPS"
					defaultMessage="User Groups"
				/>
			),
		},
	},
	{
		services: [
			'mnc.accessmanagement.registrations_rw',
			'mnc.accessmanagement.registrations_ro',
		],
		tab: {
			id: 'pendingInvitations',
			label: (
				<FormattedMessage
					id="ONBOARDING.PENDING_INVITATIONS"
					defaultMessage="Pending Invitations"
				/>
			),
		},
	},
];

class UserManagement extends React.Component {
	constructor(props) {
		super(props);
		this.UserManagementTabs = availableUserManagementTabs
			.filter((item) => isUserAllowedToAccess(item.services, props.currentUser))
			.map((i) => i.tab);

		let activeTab =
			this.UserManagementTabs.length > 0 ? this.UserManagementTabs[0] : null;
		if (props.defaultUserManagementTab === defaultUserManagementTab.users) {
			activeTab = this.userManagementTabs.find(
				(tab) => tab.id === defaultUserManagementTab.users
			);
		} else if (
			props.defaultUserManagementTab === defaultUserManagementTab.userGroups
		) {
			activeTab = this.UserManagementTabs.find(
				(tab) => tab.id === defaultUserManagementTab.userGroups
			);
		} else if (
			props.defaultUserManagementTab ===
			defaultUserManagementTab.pendingInvitations
		) {
			activeTab = this.UserManagementTabs.find(
				(tab) => tab.id === defaultUserManagementTab.pendingInvitations
			);
		}
		this.state = { activeTab };
	}

	componentDidMount() {
		const { getMyCompany, company } = this.props;
		if (!company) {
			getMyCompany({
				additionalParams: {
					include: ['CompanyLogo'],
				},
			});
		}
	}

	onNewTabClick = (id) => {
		const {
			push,
			simpleSearchClose,
			setDataForFiltersInit,
			setPaginationInit,
		} = this.props;

		simpleSearchClose();
		setDataForFiltersInit();
		setPaginationInit();

		if (id === defaultUserManagementTab.userManagement) {
			push('/user-management');
		} else if (id === defaultUserManagementTab.userGroups) {
			push('/user-groups');
		} else if (id === defaultUserManagementTab.pendingInvitations) {
			push('/pending-invitations');
		}
	};

	render() {
		const { messages, company, companyRequest } = this.props;

		const myCompany = company && company.resultList[0];

		const tabComponents = {
			userManagement: (
				<React.Fragment key="userManagement">
					<Users
						messages={messages}
						companyFriendlyName={myCompany && myCompany.companyFriendlyName}
					/>
				</React.Fragment>
			),
			userGroups: (
				<React.Fragment key="userGroups">
					<UserGroups messages={messages} />
				</React.Fragment>
			),
			pendingInvitations: (
				<React.Fragment key="pendingInvitations">
					<PendingInvitations messages={messages} />
				</React.Fragment>
			),
		};

		const actionTabs = this.UserManagementTabs.map((tab) => {
			const { activeTab } = this.state;
			const name = messages[tab.label] || tab.label;
			return {
				name,
				isActive: activeTab && tab.id === activeTab.id,
				onClick: () => this.onNewTabClick(tab.id),
				component: tabComponents[tab.id],
			};
		});

		return (
			<div data-spec="user-management">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.USER_MANAGEMENT"
							defaultMessage="User Management"
						/>
					}
				/>

				{companyRequest ? (
					<Loader />
				) : (
					<div className={styles.jumbotrone}>
						<div className={styles.oval}>
							{myCompany &&
							myCompany.companyLogo &&
							myCompany.companyLogo.logo ? (
								<img
									src={myCompany.companyLogo.logo}
									alt="company-profile"
								/>
							) : (
								<CompanyLogo />
							)}
						</div>
						<span className={styles.company_name}>
							{myCompany && myCompany.companyFriendlyName}
						</span>
					</div>
				)}

				<TabsBar tabs={actionTabs} />
				{(() => {
					const active = actionTabs.find((tab) => tab.isActive);
					return active.component;
				})()}
			</div>
		);
	}
}
const { objectOf, string, func, bool } = PropTypes;

UserManagement.propTypes = {
	messages: objectOf(string),
	defaultUserManagementTab: string,
	push: func,
	setDataForFiltersInit: func,
	simpleSearchClose: func,
	setPaginationInit: func,
	getMyCompany: func,
	company: object,
	companyRequest: bool,
	currentUser: object
};

export default UserManagement;
