import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './ErrorBoundary.scss';
import { sendErrorToServer } from './sendErrorToServer';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error) {
		this.setState({ hasError: true });
		sendErrorToServer(error);
	}

	handleRedirect = () => {
		window.location = `/${sessionStorage.getItem('language') || 'en-us'}`;
	};

	render() {
		const { hasError } = this.state;
		const { environment, children } = this.props;
		if (hasError) {
			return (
				<div data-spec="error-boundary">
					{environment === 'layout' && (
						<div data-spec="page-500" className={styles.main_wrapper_blue}>
							<span className={styles.title}>
								<FormattedMessage id="SNAP" defaultMessage="Snap" />
							</span>
							<span className={styles.sub_title}>
								<FormattedMessage id="ERROR" defaultMessage="Error" />
							</span>
							<span className={styles.message}>
								<FormattedMessage
									id="SNAP_ERROR_MESSAGE"
									defaultMessage="Unknown error occured. If this error persist, please contact support."
								/>
							</span>
							<span
								className={styles.homepage}
								onClick={() => this.handleRedirect()}
							>
								<span>
									<FormattedMessage id="RETURN TO HOME PAGE" />
								</span>
							</span>
						</div>
					)}
					{environment === 'modal' && (
						<div data-spec="page-500" className={styles.main_wrapper_white}>
							<span className={styles.title}>
								<FormattedMessage id="SNAP" defaultMessage="Snap" />
							</span>
							<span className={styles.sub_title}>
								<FormattedMessage id="ERROR" defaultMessage="Error" />
							</span>
							<span className={styles.message}>
								<FormattedMessage
									id="SNAP_ERROR_MESSAGE"
									defaultMessage="Unknown error occured. If this error persist, please contact support."
								/>
							</span>
						</div>
					)}
				</div>
			);
		}

		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.shape(),
	environment: PropTypes.string
};

export default ErrorBoundary;
