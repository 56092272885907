import { connect } from 'react-redux';
import { singleEndpointUpdateFail } from '../../../SingleEndpoint/redux/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import ScheduleModal from './ScheduleModal';

const mapStateToProps = (state) => ({
	singleEndpointUpdateFail: singleEndpointUpdateFail(state),
	user: getUser(state)
});

export default connect(mapStateToProps)(ScheduleModal);
