import { connect } from 'react-redux';
import NotesModal from './NotesModal';

import { getRuleNotes } from '../../redux/actions';
import {
	getRuleNotesRequest,
	getRuleNotesSuccess
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	ruleNotesRequest: getRuleNotesRequest(state),
	ruleNotes: getRuleNotesSuccess(state)
});
const mapDispatchToProps = (dispatch) => ({
	getRuleNotes: (data) => dispatch(getRuleNotes(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
