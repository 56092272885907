import { Status } from '../../../utils/constants';

export const getEndpointsRequest = (state) =>
	state.Endpoints.getEndpoints.status === Status.PENDING;
export const getEndpointsFail = (state) =>
	state.Endpoints.getEndpoints.status === Status.FAIL;
export const getEndpointsSuccess = (state) => state.Endpoints.getEndpoints.data;

export const getCustomLabelsRequest = (state) =>
	state.Endpoints.getCustomLabels.status === Status.PENDING;
export const getCustomLabelsFail = (state) =>
	state.Endpoints.getCustomLabels.status === Status.FAIL;
export const getCustomLabelsSuccess = (state) =>
	state.Endpoints.getCustomLabels.data;

export const getAvailableStatesData = (state) =>
	state.Endpoints.availableStates.availableStates;
export const areAvailableStatesFetching = (state) =>
	state.Endpoints.availableStates.status === Status.PENDING;

export const getm2mAccountRequest = (state) =>
	state.Endpoints.m2mAccount.status === Status.PENDING;
export const getm2mAccountSuccess = (state) =>
	state.Endpoints.m2mAccount.data &&
	state.Endpoints.m2mAccount.data.m2mAccounts;

export const getRatePlansRequest = (state) =>
	state.Endpoints.getRatePlans.status === Status.PENDING;
export const getRatePlansSuccess = (state) =>
	state.Endpoints.getRatePlans.data &&
	state.Endpoints.getRatePlans.data.rateplans;

export const generateReportRequest = (state) =>
	state.Endpoints.generateReport.status === Status.PENDING;
export const generateReportSuccess = (state) =>
	state.Endpoints.generateReport.status === Status.DONE;
export const generateReportFail = (state) =>
	state.Endpoints.generateReport.status === Status.FAIL;

export const customLabelsUpdatePending = (state) =>
	state.Endpoints.updateCustomLabels.status === Status.PENDING;
export const customLabelsUpdateFail = (state) =>
	state.Endpoints.updateCustomLabels.status === Status.FAIL;
export const customLabelsUpdateSuccess = (state) =>
	state.Endpoints.updateCustomLabels.status === Status.DONE;
