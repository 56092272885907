import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import SimOrderEditDesktop from './Desktop';
import SimOrderEditMobile from './Mobile';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import { getPlatforms } from '../../redux/getPlatforms/actions';
import {
	getPlatformsPending,
	getPlatformsSuccess
} from '../../redux/getPlatforms/selectors';

import getUsedQuantity from '../../redux/getUsedQuantity/actions';
import {
	getUsedQuantityPending,
	getUsedQuantitySuccess
} from '../../redux/getUsedQuantity/selectors';

import { getSkus } from '../../redux/getSKUs/actions';
import { getSkusPending, getSkusSuccess } from '../../redux/getSKUs/selectors';

import {
	getCountries,
	getStates
} from '../../../Onboarding/redux/companies/actions';

import {
	getCountriesSuccess,
	getCountriesRequest,
	getStatesSuccess,
	getStatesRequest
} from '../../../Onboarding/redux/companies/selectors';

import {
	addSimOrder,
	editSimOrder,
	getSimOrderStatuses,
	getSimOrder,
	getSimOrderInit
} from '../../redux/simOrders/actions';

import {
	addSimOrderRequest,
	editSimOrderRequest,
	getSimOrderStatusesRequest,
	getSimOrderStatusesSuccess,
	getSimOrderRequest,
	getSimOrderSuccess,
	getSimOrderFail,
	addSimOrderSuccess,
	editSimOrderSuccess
} from '../../redux/simOrders/selectors';

import { getRestraints } from '../../redux/restraints/actions';

import {
	getRestraintsSuccess,
	getRestraintsRequest
} from '../../redux/restraints/selectors';

const mapStateToProps = (state) => ({
	simOrderPending: getSimOrderRequest(state),
	simOrderFail: getSimOrderFail(state),
	simOrder: getSimOrderSuccess(state),
	addSimOrderSuccess: addSimOrderSuccess(state),
	editSimOrderSuccess: editSimOrderSuccess(state),
	platformsRequest: getPlatformsPending(state),
	platforms: getPlatformsSuccess(state),
	restraintsRequest: getRestraintsRequest(state),
	restraints: getRestraintsSuccess(state),
	usedQuantityRequest: getUsedQuantityPending(state),
	usedQuantity: getUsedQuantitySuccess(state),
	skusRequest: getSkusPending(state),
	skus: getSkusSuccess(state),
	orderStatusesRequest: getSimOrderStatusesRequest(state),
	orderStatuses: getSimOrderStatusesSuccess(state),
	statesRequest: getStatesRequest(state),
	countriesPending: getCountriesRequest(state),
	states: getStatesSuccess(state),
	countries: getCountriesSuccess(state),
	formValues: getFormValues('simOrderForm')(state),
	addSimOrderRequest: addSimOrderRequest(state),
	editSimOrderRequest: editSimOrderRequest(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	pushBack: (url) => dispatch(localizedRoute(url)),
	getSimOrder: (id, params) => dispatch(getSimOrder(id, params)),
	getSimOrderInit: () => dispatch(getSimOrderInit()),
	getPlatforms: (params) => dispatch(getPlatforms(params, true)),
	getRestraints: (params) => dispatch(getRestraints(params, true)),
	getUsedQuantity: (params) => dispatch(getUsedQuantity(params)),
	getSkus: (params) => dispatch(getSkus(params, true)),
	getOrderStatuses: (params) => dispatch(getSimOrderStatuses(params)),
	getStates: (params) => dispatch(getStates(params)),
	getCountries: (params) => dispatch(getCountries(params)),
	addSimOrder: (data) => dispatch(addSimOrder(data)),
	editSimOrder: (params) => dispatch(editSimOrder(params))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(SimOrderEditDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(SimOrderEditMobile);
