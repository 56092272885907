import React, { PureComponent } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import {
	FormattedDate,
	injectIntl,
} from 'react-intl';
import { reduxForm } from 'redux-form';

import Loader from '../../../../lib/DigitalComponents/Loader';
import { onOrderClick, onPageChange } from '../../../../utils/tableHelpers';

export default function SMSTab(WrapperComponent) {
	class SMSTabComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				selectedPage: 0,
				orderColumn: {},
				dataOffset: 0,
			};
		}

		componentDidMount() {
			this.getMessageData();
		}

		componentDidUpdate(prevProps) {
			const { sendSmsMessageSuccess } = this.props;

			if (prevProps.sendSmsMessageSuccess !== sendSmsMessageSuccess) {
				this.getMessageData();
			}
		}

		getMessageData = () => {
			const { getMessageHistory, id, dataLimit } = this.props;
			const { dataOffset, orderColumn } = this.state;
			const params = {
				additionalParams: {
					dataLimit,
					dataOffset,
					dataSort: orderColumn.apiString || 'createdAt desc',
				},
			};

			getMessageHistory(params, id);
		};

		sendSms = (sendSmsMessage, message, m2mAccountId, id) => sendSmsMessage(
			id,
			message,
			m2mAccountId,
		);

		getOptions = () => {
			const { messageHistoryData, dataLimit, intl } = this.props;
			const { selectedPage, orderColumn } = this.state;
			const totalCount = messageHistoryData ? messageHistoryData.totalCount : 0;

			return {
				header: [
					{
						title: intl.formatMessage({ id: 'DATE', defaultMessage: 'Date' }),
						name: 'createdAt',
						checked: true,
					},
					{
						title: intl.formatMessage({ id: 'BY', defaultMessage: 'By' }),
						name: 'createdBy',
						checked: true,
					},
					{
						title: intl.formatMessage({
							id: 'MESSAGE_CONTENT',
							defaultMessage: 'Message Content'
						}),
						name: 'messageContent',
						checked: true,
					},
				],
				tableOptions: {
					showPagination: totalCount > dataLimit,
					pageCount: Math.ceil(totalCount / dataLimit),
					forcePage: selectedPage,
					onPageChange: (data) => onPageChange(data, this.getMessageData, this),
					getOrderData: (data) => onOrderClick(data, this.getMessageData, this),
					orderByData: ['createdAt', 'createdBy'],
					orderColumn,
				},
				customComponents: {
					createdAt: {
						type: 'custom',
						component: (data) => (
							<FormattedDate
								value={data.createdAt}
								year="numeric"
								month="short"
								day="2-digit"
								hour="numeric"
								minute="numeric"
								timeZone="UTC"
								hour12={false}
							/>
						),
					},
				},
			};
		};

		render() {
			const {
				messages,
				sendSmsMessage,
				formValues,
				messageHistoryData,
				id,
				m2mAccountId,
				sendSmsMessageRequest,
				isFetching,
				reset,
				invalid,
			} = this.props;
			const maxCharacters = 160;

			if (sendSmsMessageRequest || isFetching) {
				return <Loader data-spec="sms-tab-loader" />;
			}

			return (
				<WrapperComponent
					data-spec="sms-tab-wrapper-component"
					maxCharacters={maxCharacters}
					sendSms={() => (
						this.sendSms(sendSmsMessage, formValues.message, m2mAccountId, id)
					)}
					reset={reset}
					messages={messages}
					messageHistoryData={messageHistoryData
						? messageHistoryData.resultList : []}
					options={this.getOptions}
					message={formValues.message}
					invalid={invalid}
				/>
			);
		}
	}

	SMSTabComponent.propTypes = {
		messages: object,
		getMessageHistory: func,
		id: string,
		dataLimit: number,
		sendSmsMessage: func,
		formValues: object,
		m2mAccountId: string,
		messageHistoryData: object,
		sendSmsMessageRequest: bool,
		isFetching: bool,
		sendSmsMessageSuccess: bool,
		reset: func,
    invalid: bool,
    intl: object,
	};
	SMSTabComponent.defaultProps = {
		messages: {},
		getMessageHistory: undefined,
		id: '',
		dataLimit: 0,
		sendSmsMessage: undefined,
		formValues: {},
		m2mAccountId: '',
		messageHistoryData: {},
		sendSmsMessageRequest: false,
		isFetching: false,
		sendSmsMessageSuccess: false,
		reset: undefined,
		invalid: false,
	};

	const SMSTabComponentForm = reduxForm({
		form: 'sendSMSForm',
	})(injectIntl(SMSTabComponent));
	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	SMSTabComponentForm.displayName = `SMSTab(${wrappedComponentName})`;

	return SMSTabComponentForm;
}
