import React from 'react';
import { string } from 'prop-types';

import styles from './SectionHeadline.scss';

const SectionHeadlinePropTypes = {
	title: string
};

const SectionHeadlineDefaultProps = {
	title: ''
};

export default function SectionHeadline({ title }) {
	return <h5 data-spec={title} className={styles.section_headline}>{title}</h5>;
}

SectionHeadline.propTypes = SectionHeadlinePropTypes;
SectionHeadline.defaultProps = SectionHeadlineDefaultProps;
