export default {
	'RELEASE_NOTES.RELEASE_NOTES': 'Notas do lançamento',
	'RELEASE_NOTES.RELEASE_NOTES_SUPPORT_INFO': 'O IoT Console é atualizado frequentemente.',
	'RELEASE_NOTES.RELEASE_NOTES_SUBTITLE': 'O IoT Console é atualizado frequentemente para garantia a melhor operação e oferecer recursos e capacidades, e melhorias da experiência do usuário. Seguem abaixo as atualizações introduzidas neste lançamento.',
	'RELEASE_NOTES.RELEASE_VERSION': 'Versão do lançamento',
	'RELEASE_NOTES.NEW_PLATFORM_FEATURES': 'Novos recursos da plataforma',
	'RELEASE_NOTES.DEPRECATIONS': 'Reprovações',
	'RELEASE_NOTES.DEPRECATION': 'Reprovação',
	'RELEASE_NOTES.KNOWN_ISSUES': 'Problemas conhecidos',
	'RELEASE_NOTES.NEW_FEATURES': 'Novos recursos',
	'RELEASE_NOTES.IMPROVEMENTS': 'Melhorias',
	'RELEASE_NOTES.FEATURE': 'Recurso',
	'RELEASE_NOTES.IMPROVEMENT': 'Melhoria',
	'RELEASE_NOTES.ISSUE': 'Problema',

	'RELEASE_NOTES.ADD_ANOTHER_DEPRICATION': 'Adicionar outra reprovação',
	'RELEASE_NOTES.ADD_ANOTHER_PLATFORM_FEATURE': 'Adicionar outro recurso da plataforma',
	'RELEASE_NOTES.ADD_ANOTHER_FEATURE': 'Adicionar outro recurso',
	'RELEASE_NOTES.ADD_ANOTHER_KNOWN_ISSUE': 'Adicionar outro problema conhecido',
	'RELEASE_NOTES.ADD_ANOTHER_IMPROVEMENT': 'Adicionar outra melhoria',

	'RELEASE_NOTES.PUBLISH': 'Publicar',
	'RELEASE_NOTES.SAVE': 'Salvar',
	'RELEASE_NOTES.PUBLISH_RELEASE_NOTES': 'Publicar notas do lançamento',
	'RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE': 'Você está prestes a publicar notas do lançamento{releaseVersion} Tem certeza que deseja fazer isso?',
	'RELEASE_NOTES.CANCEL': 'Cancelar',
};
