export default {
	'SHARED.NO_DATA_AVAILABLE': 'No hay datos disponibles',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE': 'No hay datos disponibles para mostrarle ahora',
	'SHARED.EXIT_THIS_COMPANY': 'SALIR',
	// ACTION BAR SELECTED FILTERS TOOLTIP
	// ** ENDPOINTS **
	'ACTION_BAR.ENDPOINTSTATUS': 'Estado',
	'ACTION_BAR.NETWORKOPERATOR': 'Operador de red',
	'ACTION_BAR.NETWORKCOUNTRY': 'País de red',
	'ACTION_BAR.NETWORKENTITLEMENT': 'ID de permiso de red',
	'ACTION_BAR.NETWORKTYPE': 'Tipo de red',
	'ACTION_BAR.TRANSFERSTATUS': 'Transferencia de estado IMSI',
	'ACTION_BAR.RATEPLAN': 'Plan de tarifas',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'Estado de reacreditación',
	'ACTION_BAR.M2MPLATFORM': 'Plataforma M2M',
	'ACTION_BAR.ROAMINGCOUNTRY': 'País de roaming',
	'ACTION_BAR.ROAMINGCARRIER': 'Operador de roaming',
	'ACTION_BAR.SERVICESTATUS': 'Estado de 360L',
	'ACTION_BAR.OEM': 'Cuentas suscritas',
	'ACTION_BAR.M2MDATEADDED': 'Fecha agregada a M2M',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'Fecha de activación de M2M',
	//  **  INVOICES  **
	'ACTION_BAR.M2MACCOUNTID': 'ID de cuenta M2M',
	'ACTION_BAR.CUSTOMERNAME': 'Nombre del cliente',
	'ACTION_BAR.INVOICENUMBER': 'Número de factura',
	'ACTION_BAR.COUNTRYNAME': 'Nombre del país',
	'ACTION_BAR.BILLINGCYCLE': 'Ciclo de facturación',
	'ACTION_BAR.CUSTOMERID': 'ID de cliente',
	'ACTION_BAR.INVOICEDATE': 'Fecha de la factura',
	//  **  REPORTS **
	'ACTION_BAR.M2M_ACC_ID': 'ID de cuenta M2M',
	'ACTION_BAR.M2M_PLATFORM': 'Plataforma M2M',
	'ACTION_BAR.PLATFORM': 'Plataforma',
	'ACTION_BAR.FREQUENCY': 'Frecuencia',
	'ACTION_BAR.REPORT_NAME': 'Nombre del informe',
	'ACTION_BAR.SERVICEPROVIDER': 'Plataforma',
	'ACTION_BAR.REPORTFREQUENCY': 'Frecuencia',
	//  **  SIM ORDERING  **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': 'Estado',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'Plataforma M2M',
	'ACTION_BAR.FILTER_CATEGORY_SKU': 'SKU',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Urgente',
	//  **  SECURITY FEATURES **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'Cuenta M2M',
	//  **  TICKETING **
	'ACTION_BAR.CATEGORIES': 'Categorías',
	'ACTION_BAR.STATUS': 'Estado',
	'ACTION_BAR.SEVERITY': 'Severidad',
	//  ACTION BAR
	'ACTION_BAR.CLEAR_FILTERS': 'Eliminar filtros',
	'ACTION_BAR.SELECT_FILTERS': 'Seleccionar filtros',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Editar parámetros de tabla',
	'ACTION_BAR.SHOWING': 'Mostrando ',
	'ACTION_BAR.OF': ' de ',
	'ACTION_BAR.DOWNLOAD_FILE': 'Descargar archivo',
	'ACTION_BAR.REFRESH_PAGE': 'Refrescar página',
	//  ******************* FILTER MODAL *****************************
	//  ** ENDPOINTS **
	'FILTER_MODAL.ENDPOINTSTATUS': 'Estado',
	'FILTER_MODAL.NETWORKOPERATOR': 'Operador de red',
	'FILTER_MODAL.NETWORKCOUNTRY': 'País de red',
	'FILTER_MODAL.NETWORKENTITLEMENT': 'ID de permiso de red',
	'FILTER_MODAL.NETWORKTYPE': 'Tipo de red',
	'FILTER_MODAL.TRANSFERSTATUS': 'Transferencia de estado IMSI',
	'FILTER_MODAL.RATEPLAN': 'Plan de tarifas',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'Estado de reacreditación',
	'FILTER_MODAL.M2MPLATFORM': 'Plataforma M2M',
	'FILTER_MODAL.ROAMINGCOUNTRY': 'País de roaming',
	'FILTER_MODAL.ROAMINGCARRIER': 'Operador de roaming',
	'FILTER_MODAL.SERVICESTATUS': 'Estado de 360L',
	'FILTER_MODAL.OEM': 'Cuentas suscritas',
	'FILTER_MODAL.M2MDATEADDED': 'Fecha agregada a M2M',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'Fecha de activación de M2M',
	//  tabs
	'FILTER_MODAL.TABS_ENDPOINTSTATE': 'Estado de terminal',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Operador de red',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'País de red',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Permiso de red',
	'FILTER_MODAL.TABS_NETWORKTYPE': 'Tipo de red',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': 'Estado de transferencia',
	'FILTER_MODAL.TABS_RATEPLAN': 'Plan de tarifas',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': 'Estado de transferencia de reacreditación',
	'FILTER_MODAL.TABS_M2MPLATFORM': 'Plataforma M2M',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'País de roaming',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Operador de roaming',
	'FILTER_MODAL.TABS_SERVICESTATUS': 'Estado de 360L',
	'FILTER_MODAL.TABS_OEM': 'Cuentas suscritas',
	'FILTER_MODAL.TABS_M2MDATEADDED': 'Fecha agregada a M2M',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'Fecha de activación de M2M',
	//  **  INVOICES  **
	'FILTER_MODAL.M2MACCOUNTID': 'ID de cuenta M2M',
	'FILTER_MODAL.CUSTOMERNAME': 'NOMBRE DEL CLIENTE',
	'FILTER_MODAL.INVOICENUMBER': 'NÚMERO DE FACTURA',
	'FILTER_MODAL.INVOICEDATE': 'FECHA DE LA FACTURA',
	'FILTER_MODAL.COUNTRYNAME': 'NOMBRE DEL PAÍS',
	'FILTER_MODAL.CUSTOMERID': 'ID DE CLIENTE',
	'FILTER_MODAL.BILLINGCYCLE': 'CICLO DE FACTURACIÓN',
	//  tabs
	'FILTER_MODAL.TABS_M2MACCOUNTID': 'ID de cuenta M2M',
	'FILTER_MODAL.TABS_CUSTOMERNAME': 'Nombre del cliente',
	'FILTER_MODAL.TABS_INVOICENUMBER': 'Número de factura',
	'FILTER_MODAL.TABS_COUNTRYNAME': 'Nombre del país',
	'FILTER_MODAL.TABS_CUSTOMERID': 'ID de cliente',
	'FILTER_MODAL.TABS_BILLINGCYCLE': 'Ciclo de facturación',
	'FILTER_MODAL.TABS_INVOICEDATE': 'Fecha de la factura',

	//  **  REPORTS **
	'FILTER_MODAL.M2M_ACC_ID': 'ID de cuenta M2M',
	'FILTER_MODAL.M2M_PLATFORM': 'PLATAFORMA M2M',
	'FILTER_MODAL.FREQUENCY': 'FRECUENCIA',
	'FILTER_MODAL.REPORT_NAME': 'NOMBRE DEL INFORME',
	'FILTER_MODAL.PLATFORM': 'PLATAFORMA',
	'FILTER_MODAL.SERVICEPROVIDER': 'PLATAFORMA',
	'FILTER_MODAL.REPORTFREQUENCY': 'FRECUENCIA',
	//  tabs
	'FILTER_MODAL.TABS_M2M_ACC_ID': 'ID de cuenta M2M',
	'FILTER_MODAL.TABS_M2M_PLATFORM': 'Plataforma M2M',
	'FILTER_MODAL.TABS_FREQUENCY': 'Frecuencia',
	'FILTER_MODAL.TABS_REPORT_NAME': 'Nombre del informe',
	'FILTER_MODAL.TABS_PLATFORM': 'Plataforma',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Plataforma',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Frecuencia',
	//  **  SIM ORDERING  **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'ESTADO',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'PLATAFORMA M2M',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'URGENTE',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'Estado',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'Plataforma M2M',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Urgente',
	//  **  SECURITY FEATURES **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'CUENTA M2M',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'Cuenta M2M',
	//  **  TICKETING **
	'FILTER_MODAL.CATEGORIES': 'CATEGORÍAS',
	'FILTER_MODAL.STATUS': 'ESTADO',
	'FILTER_MODAL.SEVERITY': 'SEVERIDAD',
	//  tabs
	'FILTER_MODAL.TABS_CATEGORIES': 'Categorías',
	'FILTER_MODAL.TABS_STATUS': 'Estado',
	'FILTER_MODAL.TABS_SEVERITY': 'Severidad',
	//  **  INVOICE DETAILS **
	'FILTER_MODAL.CURRENCY': 'DIVISA',
	'FILTER_MODAL.CUSTOMER': 'CLIENTE',
	'FILTER_MODAL.SUBSCRIBER_STATUS': 'ESTADO DE SUSCRIPTOR',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': 'PLAN DE TARIFAS MENSUAL',
	'FILTER_MODAL.STANDARD_RATE_PLAN': 'PLAN DE TARIFAS ESTÁNDAR',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'TÉRMINOS DE CARGOS DE PREPAGO',
	'FILTER_MODAL.RATE_PLAN': 'PLAN DE TARIFAS',
	'FILTER_MODAL.PLAN_TYPE': 'TIPO DE PLAN',
	'FILTER_MODAL.ZONE': 'ZONA',
	'FILTER_MODAL.PRIORITY': 'PRIORIDAD',
	'FILTER_MODAL.ROAMING_ZONE': 'ZONA DE ROAMING',
	'FILTER_MODAL.GROUP_NAME': 'NOMBRE DEL GRUPO',
	'FILTER_MODAL.INVOICE_ID': 'ID DE FACTURA',
	'FILTER_MODAL.THE_PLAN_TYPE': 'EL TIPO DE PLAN',
	'FILTER_MODAL.ACTIVATION_TYPE': 'TIPO DE ACTIVACIÓN',
	'FILTER_MODAL.CHARGE_TYPE': 'TIPO DE CARGO',
	'FILTER_MODAL.PRIMARY_PLACE': 'LUGAR PRINCIPAL',
	'FILTER_MODAL.TECH_TYPE': 'TIPO DE TECNOLOGÍA',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'LUGAR PRINCIPAL DE USO',
	'FILTER_MODAL.STATE': 'ESTADO',
	'FILTER_MODAL.CITY': 'CIUDAD',
	'FILTER_MODAL.DISTRICT_COUNTY': 'CONDADO DEL DISTRITO',
	'FILTER_MODAL.DISTRICT_CITY': 'CIUDAD DEL DISTRITO',
	'FILTER_MODAL.DESCRIPTION': 'DESCRIPCIÓN',
	//  tabs
	'FILTER_MODAL.TABS_CURRENCY': 'DIVISA',
	'FILTER_MODAL.TABS_CUSTOMER': 'Cliente',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'ESTADO DE SUSCRIPTOR',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'PLAN DE TARIFAS MENSUAL',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'PLAN DE TARIFAS ESTÁNDAR',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'TÉRMINOS DE CARGOS DE PREPAGO',
	'FILTER_MODAL.TABS_RATE_PLAN': 'Plan de tarifas',
	'FILTER_MODAL.TABS_PLAN_TYPE': 'TIPO DE PLAN',
	'FILTER_MODAL.TABS_ZONE': 'ZONA',
	'FILTER_MODAL.TABS_PRIORITY': 'Prioridad',
	'FILTER_MODAL.TABS_ROAMING_ZONE': 'ZONA DE ROAMING',
	'FILTER_MODAL.TABS_GROUP_NAME': 'NOMBRE DEL GRUPO',
	'FILTER_MODAL.TABS_INVOICE_ID': 'ID de factura',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'EL TIPO DE PLAN',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'TIPO DE ACTIVACIÓN',
	'FILTER_MODAL.TABS_CHARGE_TYPE': 'TIPO DE CARGO',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': 'LUGAR PRINCIPAL',
	'FILTER_MODAL.TABS_TECH_TYPE': 'TIPO DE TECNOLOGÍA',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'LUGAR PRINCIPAL DE USO',
	'FILTER_MODAL.TABS_STATE': 'Estado',
	'FILTER_MODAL.TABS_CITY': 'Ciudad',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'CONDADO DEL DISTRITO',
	'FILTER_MODAL.TABS_DISTRICT_CITY': 'CIUDAD DEL DISTRITO',
  'FILTER_MODAL.TABS_DESCRIPTION': 'Descripción',
  //  **    AUTOMATION    **  //
  'FILTER_MODAL.PLATFORMS': 'PLATAFORMAS',
  'FILTER_MODAL.TRIGGERS': 'ACTIVADORES',
  'FILTER_MODAL.M2MACCOUNTS': 'Cuentas M2M',
  'FILTER_MODAL.ACTIVE': 'ACTIVADO',
  'FILTER_MODAL.STATUSES': 'ESTADOS',
  //  tabs
  'FILTER_MODAL.TABS_PLATFORMS': 'Plataformas',
  'FILTER_MODAL.TABS_TRIGGERS': 'Activadores',
  'FILTER_MODAL.TABS_M2MACCOUNTS': 'Cuenta M2M',
	'FILTER_MODAL.TABS_ACTIVE': 'Activado',
	'FILTER_MODAL.TABS_USERGROUPS': 'Grupos de usuario',
  'FILTER_MODAL.USERGROUPS': 'Grupos de usuario',
  'FILTER_MODAL.TABS_STATUSES': 'Estados',
  // action bar
  'ACTION_BAR.PLATFORMS': 'Plataformas',
  'ACTION_BAR.TRIGGERS': 'Activadores',
  'ACTION_BAR.M2MACCOUNTS': 'Cuentas M2M',
  'ACTION_BAR.CURRENCY': 'DIVISA',
  'ACTION_BAR.ACTIVE': 'Activado',
	'ACTION_BAR.CUSTOMER': 'Cliente',
	'ACTION_BAR.SUBSCRIBER_STATUS': 'ESTADO DE SUSCRIPTOR',
	'ACTION_BAR.MONTHLY_RATE_PLAN': 'PLAN DE TARIFAS MENSUAL',
	'ACTION_BAR.STANDARD_RATE_PLAN': 'PLAN DE TARIFAS ESTÁNDAR',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': 'TÉRMINOS DE CARGOS DE PREPAGO',
	'ACTION_BAR.RATE_PLAN': 'Plan de tarifas',
	'ACTION_BAR.PLAN_TYPE': 'TIPO DE PLAN',
	'ACTION_BAR.ZONE': 'ZONA',
	'ACTION_BAR.PRIORITY': 'Prioridad',
	'ACTION_BAR.ROAMING_ZONE': 'ZONA DE ROAMING',
	'ACTION_BAR.GROUP_NAME': 'NOMBRE DEL GRUPO',
	'ACTION_BAR.INVOICE_ID': 'ID de factura',
	'ACTION_BAR.THE_PLAN_TYPE': 'EL TIPO DE PLAN',
	'ACTION_BAR.ACTIVATION_TYPE': 'TIPO DE ACTIVACIÓN',
	'ACTION_BAR.CHARGE_TYPE': 'TIPO DE CARGO',
	'ACTION_BAR.PRIMARY_PLACE': 'LUGAR PRINCIPAL',
	'ACTION_BAR.TECH_TYPE': 'TIPO DE TECNOLOGÍA',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'LUGAR PRINCIPAL DE USO',
	'ACTION_BAR.STATE': 'Estado',
	'ACTION_BAR.CITY': 'Ciudad',
	'ACTION_BAR.DISTRICT_COUNTY': 'CONDADO DEL DISTRITO',
	'ACTION_BAR.DISTRICT_CITY': 'CIUDAD DEL DISTRITO',
	'ACTION_BAR.DESCRIPTION': 'Descripción',
  'ACTION_BAR.USERGROUPS': 'Grupos de usuario',
  'ACTION_BAR.STATUSES': 'Estados',
  // UPLOAD COMPONENT
	'UPLOAD_FILE.MAX_FILE_SIZE': 'El archivo debe tener menos de {size}MB.',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Nombre de archivo incorrecto',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': 'Solo se permite cargar los siguientes formatos:',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'El archivo es demasiado grande',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': 'El archivo que trató de cargar es demasiado largo. Los archivos deben tener menos de',
  'UPLOAD_FILE.ALLOWED_FORMATS': 'Formatos permitidos: ',
	'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'Solo se permite un archivo'
};
