import CompaniesView from '../views/CompaniesView';
import EditCompany from '../components/EditCompany';
import AddCompany from '../components/AddCompany';
import AddCompanySuccess from '../components/AddCompanySuccess';
import RatePlansView from '../views/RatePlansView';
import NetworkEntitlementsView from '../views/NetworkEntitlementsView';
import SimOrdering from '../components/SimOrdering';
import Reports from '../components/Reports';
import Billings from '../components/Billings';
import AddAccount from '../components/AddAccount';
import EditAccount from '../components/EditAccount';
import AddSubAccount from '../components/AddSubAccount';
import EditSubAccount from '../components/EditSubAccount';
import AssignEndpoints from '../components/AssignEndpoints';
import {
	userInSupermode,
	userInStandardMode,
	userIsAuthenticated,
} from '../../../utils/AuthSelector';
import MyCompanyView from '../views/MyCompanyView';
import {
	defaultTab,
	defaultUserManagementTab,
	defaultUserGroupsTab,
	defaultSystemManagmentTab,
} from '../utils/constants';

import UserManagementView from '../views/UserManagementView';
import EditUserProfileView from '../views/EditUserProfileView';

import UserProfileView from '../views/UserProfileView';
import AccessManagementView from '../views/AccessManagmentView';
import UserGroup from '../components/UserGroup';
import EditUserGroup from '../components/EditUserGroup';
import UserEffectivePermissions from '../components/EffectivePermissions';
import UserIndividualPermissions from '../components/IndividualPermissions';
import RatePlanDetails from '../components/RatePlanDetails';
import NetworkEntitlementDetails from '../components/NetworkEntitlementDetails';

/* eslint-disable react/display-name */
// please be advised to take a caution when ordering routes

const onboardingRoutes = [
	{
		path: '/companies',
		exact: true,
		Component: userInSupermode(CompaniesView),
	},
	{
		path: '/companies/add-company',
		exact: true,
		Component: userInSupermode(AddCompany),
	},
	{
		path: '/companies/add-company-success/:companyId',
		exact: true,
		Component: userInSupermode(AddCompanySuccess),
	},
	{
		path: '/companies/:companyId',
		exact: true,
		Component: userInSupermode(EditCompany),
	},
	{
		path: '/companies/:companyId/accounts/new-account',
		exact: true,
		Component: userInSupermode(AddAccount),
	},
	{
		path: '/companies/:companyId/accounts/:accountId',
		exact: true,
		Component: userInSupermode(EditAccount),
	},
	{
		path: '/companies/:companyId/accounts/:accountId/endpoints',
		exact: true,
		Component: userInSupermode(AssignEndpoints),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:m2mAccountId/:parentAccountM2mId/endpoints',
		exact: true,
		Component: userInSupermode(AssignEndpoints),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:platformType/:platformTypeId/new-sub-account',
		exact: true,
		Component: userInSupermode(AddSubAccount),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:m2mAccountId/:parentAccountM2mId/',
		exact: true,
		Component: userInSupermode(EditSubAccount),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:m2mAccountId/:parentAccountM2mId/:platformType/network-entitlements',
		exact: true,
		Component: userInSupermode(NetworkEntitlementsView),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:m2mAccountId/:parentAccountM2mId/:platformType/rate-plans',
		exact: true,
		Component: userInSupermode(RatePlansView),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:platformType/:m2mAccountId/rate-plans',
		exact: true,
		Component: userInSupermode(RatePlansView),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:platformType/:m2mAccountId/rate-plans/:ratePlanId',
		exact: true,
		Component: userInSupermode(RatePlanDetails),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:platformType/:m2mAccountId/network-entitlements',
		exact: true,
		Component: userInSupermode(NetworkEntitlementsView),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:platformType/:m2mAccountId/network-entitlements/:entitlementId',
		exact: true,
		Component: userInSupermode(NetworkEntitlementDetails),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:m2mAccountId/:parentAccountM2mId/:platformType/sim-ordering',
		exact: true,
		Component: userInSupermode(SimOrdering),
	},
	{
		path:
			'/companies/:companyId/accounts/:accountId/:platformType/:m2mAccountId/sim-ordering',
		exact: true,
		Component: userInSupermode(SimOrdering),
	},
	{
		path: '/companies/:companyId/accounts/:accountId/reports',
		exact: true,
		Component: userInSupermode(Reports),
	},
	{
		path: '/companies/:companyId/accounts/:accountId/billings',
		exact: true,
		Component: userInSupermode(Billings),
	},
	{
		path: '/my-company/companyoverview',
		exact: true,
		Component: userInStandardMode(MyCompanyView),
		customProps: { defaultTab: defaultTab.companyoverview, isMyCompany: true },
	},
	{
		path: '/my-company/accounts',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.m2msubaccounts_rw', 'mnc.m2msubaccounts_ro'])(
				MyCompanyView
			)
		),
		customProps: { defaultTab: defaultTab.accounts, isMyCompany: true },
	},
	{
		path: '/my-company/custom-field-labels',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.endpoints.customlabels_rw',
				'mnc.endpoints.customlabels_ro',
			])(MyCompanyView)
		),
		customProps: { defaultTab: defaultTab.customFieldLabels },
	},
	{
		path: '/my-company/company-features',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.endpoints.customlabels_rw',
				'mnc.endpoints.customlabels_ro',
			])(MyCompanyView)
		),
		customProps: { defaultTab: defaultTab.features },
	},
	{
		path:
			'/my-company/:accountId/:platformType/:platformTypeId/new-sub-account',
		exact: true,
		Component: userInStandardMode(AddSubAccount),
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/:m2mAccountId/:parentAccountM2mId',
		exact: true,
		Component: userInStandardMode(EditSubAccount), // edit sub account
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId',
		exact: true,
		Component: userInStandardMode(EditAccount), // edit account
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/endpoints',
		exact: true,
		Component: userInStandardMode(AssignEndpoints),
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/:m2mAccountId/:parentAccountM2mId/endpoints',
		exact: true,
		Component: userInStandardMode(AssignEndpoints),
		customProps: { isMyCompany: true },
	},
	{
		path:
			'/my-company/:accountId/:m2mAccountId/:platformType/network-entitlements',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.m2msubaccounts_ro',
				'mnc.m2msubaccounts_rw',
				'system.companies_ro',
				'system.companies_rw',
			])(NetworkEntitlementsView)
		), // network entitlements
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/:m2mAccountId/:platformType/rate-plans',
		exact: true,
		Component: userInStandardMode(RatePlansView), // rate plans
		customProps: { isMyCompany: true },
	},
	{
		path:
			'/my-company/:accountId/:m2mAccountId/:parentAccountM2mId/:platformType/network-entitlements',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.m2msubaccounts_ro',
				'mnc.m2msubaccounts_rw',
				'system.companies_ro',
				'system.companies_rw',
			])(NetworkEntitlementsView)
		), // network entitlements
		customProps: { isMyCompany: true },
	},
	{
		path:
			'/my-company/:accountId/:m2mAccountId/:parentAccountM2mId/:platformType/rate-plans',
		exact: true,
		Component: userInStandardMode(RatePlansView), // rate plans
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/:m2mAccountId/:platformType/sim-ordering',
		exact: true,
		Component: userInStandardMode(SimOrdering),
		customProps: { isMyCompany: true },
	},
	{
		path:
			'/my-company/:accountId/:m2mAccountId/:parentAccountM2mId/:platformType/sim-ordering',
		exact: true,
		Component: userInStandardMode(SimOrdering),
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/reports',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'system.companies_rw',
				'mnc.reports.configuration_rw',
				'mnc.reports.configuration_ro',
			])(Reports)
		),
		customProps: { isMyCompany: true },
	},
	{
		path: '/my-company/:accountId/billings',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.reports.configuration_ro',
				'mnc.reports.configuration_rw'
			])(Billings)
		),
		customProps: { isMyCompany: true },
	},
	{
		path: '/user-management',
		exact: true,
		Component: userInStandardMode(UserManagementView),
		customProps: {
			defaultUserManagementTab: defaultUserManagementTab.userManagement,
		},
	},
	{
		path: '/user/:id',
		exact: true,
		Component: userInStandardMode(UserProfileView),
	},
	{
		path: '/user/:id/edit',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'system.accessmanagement.users_rw',
				'mnc.accessmanagement.users_rw',
			])(EditUserProfileView)
		),
	},
	{
		path: '/user/:id/effective-permissions',
		exact: true,
		Component: userInStandardMode(UserEffectivePermissions),
	},
	{
		path: '/user/:id/user-groups/:userGroupId/individual-permissions',
		exact: true,
		Component: userInStandardMode(UserIndividualPermissions),
		customProps: {
			fromUser: true,
		},
	},
	{
		path: '/user/:id/user-groups/:userGroupId/individual-permissions/edit',
		exact: true,
		Component: userInStandardMode(EditUserGroup),
		customProps: {
			fromUser: true,
		},
	},
	{
		path: '/user/:id/user-groups/:userGroupId/permissions',
		exact: true,
		Component: userInStandardMode(UserGroup),
		customProps: {
			fromUser: true,
		},
	},
	{
		path: '/user/:id/user-groups/:userGroupId/permissions/edit',
		exact: true,
		Component: userInStandardMode(EditUserGroup),
		customProps: {
			fromUser: true,
		},
	},
	{
		path: '/user-groups',
		exact: true,
		Component: userInStandardMode(UserManagementView),
		customProps: {
			defaultUserManagementTab: defaultUserManagementTab.userGroups,
		},
	},
	{
		path: '/pending-invitations',
		exact: true,
		Component: userInStandardMode(UserManagementView),
		customProps: {
			defaultUserManagementTab: defaultUserManagementTab.pendingInvitations,
		},
	},
	{
		path: '/user-groups/:userGroupId/permissions',
		exact: true,
		Component: userInStandardMode(UserGroup),
		customProps: {
			defaultTab: defaultUserGroupsTab.permissions,
		},
	},
	{
		path: '/user-groups/:userGroupId/permissions/edit',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'system.accessmanagement.usergroups_rw',
				'mnc.accessmanagement.usergroups_rw',
			])(EditUserGroup)
		),
	},
	{
		path: '/user-groups/:userGroupId/assigned-accounts',
		exact: true,
		Component: userInStandardMode(UserGroup),
		customProps: {
			defaultTab: defaultUserGroupsTab.assignedAccounts,
		},
	},
	{
		path: '/user-groups/:userGroupId/assigned-accounts/edit',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'system.accessmanagement.usergroups_rw',
				'mnc.accessmanagement.usergroups_rw',
			])(EditUserGroup)
		),
		customProps: {
			defaultTab: defaultUserGroupsTab.assignedAccounts,
		},
	},
	{
		path: '/access-management',
		exact: true,
		Component: userInSupermode(AccessManagementView),
		customProps: {
			defaultSystemManagmentTab:
				defaultSystemManagmentTab.systemUsersManagement,
			system: true,
		},
	},
	{
		path: '/access-management/user-groups',
		exact: true,
		Component: userInSupermode(AccessManagementView),
		customProps: {
			defaultSystemManagmentTab: defaultSystemManagmentTab.systemUserGroups,
		},
	},
	{
		path: '/access-management/user-groups/:userGroupId/permissions',
		exact: true,
		Component: userInSupermode(UserGroup),
		customProps: {
			defaultTab: defaultUserGroupsTab.permissions,
			system: true,
		},
	},
	{
		path: '/access-management/user-groups/:userGroupId/permissions/edit',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated(['system.accessmanagement.usergroups_rw'])(
				EditUserGroup
			)
		),
		customProps: {
			system: true,
		},
	},
	{
		path: '/access-management/users/:id',
		exact: true,
		Component: userInSupermode(UserProfileView),
		customProps: {
			system: true,
		},
	},
	{
		path: '/access-management/users/:id/edit',
		Component: userInSupermode(
			userIsAuthenticated(['system.accessmanagement.users_rw'])(
				EditUserProfileView
			)
		),
		customProps: {
			system: true,
		},
	},
	{
		path: '/access-management/users/:id/effective-permissions',
		exact: true,
		Component: userInSupermode(UserEffectivePermissions),
		customProps: {
			system: true,
		},
	},
	{
		path:
			'/access-management/users/:id/user-groups/:userGroupId/individual-permissions',
		exact: true,
		Component: userInSupermode(UserIndividualPermissions),
		customProps: {
			system: true,
			fromUser: true,
		},
	},
	{
		path:
			'/access-management/users/:id/user-groups/:userGroupId/individual-permissions/edit',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated(['system.accessmanagement.users_rw'])(EditUserGroup)
		),
		customProps: {
			system: true,
			fromUser: true,
		},
	},
	{
		path: '/access-management/users/:id/user-groups/:userGroupId/permissions',
		exact: true,
		Component: userInSupermode(UserGroup),
		customProps: {
			system: true,
			fromUser: true,
		},
	},
	{
		path:
			'/access-management/users/:id/user-groups/:userGroupId/permissions/edit',
		exact: true,
		Component: userInSupermode(EditUserGroup),
		customProps: {
			system: true,
			fromUser: true,
		},
	},
	{
		path: '/access-management/pending-invitations',
		exact: true,
		Component: userInSupermode(AccessManagementView),
		customProps: {
			defaultSystemManagmentTab:
				defaultSystemManagmentTab.systemUserPendingInvitations,
			system: true,
		},
	},
	{
		path: '/my-profile',
		exact: true,
		Component: UserProfileView,
		customProps: {
			myProfile: true,
		},
	},
	{
		path: '/my-profile/edit',
		exact: true,
		Component: EditUserProfileView,
		customProps: {
			myProfile: true,
		},
	},
	{
		path: '/my-profile/user-groups/:userGroupId/permissions',
		exact: true,
		Component: UserGroup,
		customProps: {
			myProfile: true,
		},
	},
	{
		path: '/my-profile/user-groups/:userGroupId/individual-permissions',
		exact: true,
		Component: UserIndividualPermissions,
		customProps: {
			myProfile: true,
		},
	},
	{
		path: '/my-profile/effective-permissions',
		exact: true,
		Component: UserEffectivePermissions,
		customProps: {
			myProfile: true,
		},
	},
];

export default onboardingRoutes;
