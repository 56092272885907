import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, submit } from 'redux-form';
import validate from './validate';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import Loading from '../../../../lib/DigitalComponents/Loader';

import NoData from '../../../Shared/views/NoDataView';

import styles from './AddSimSkuModal.scss';

const submitForm = (values, _, props) => {
	const { addSubAccountSimSku, accountId } = props;

	let simSkus = [];

	Object.keys(values).forEach((key) => {
		if (key && values[key] === true) {
			const simSku = key.split('<#>');
			simSkus = [...simSkus, parseInt(simSku[1], 10)];
		}
	});

	addSubAccountSimSku({ accountId, simSkus: { simSkus } });
};

class AddSimSkuModal extends PureComponent {
	componentDidMount() {
		this.getParentSimSkus();
	}

	componentWillUnmount() {
		const { getParentSimSkusInit } = this.props;
		getParentSimSkusInit();
	}

	getParentSimSkus = () => {
		const { getParentSimSkus, account } = this.props;
		const searchParams = [
			{
				prop: 'platformId',
				propValue: account.parentPlatformId,
				operator: '='
			}
		];

		getParentSimSkus({
			searchParams
		});
	};

	render() {
		const {
			onClose,
			parentSimSkuRequest,
			parentSimSkuFail,
			simSkus,
			handleSubmit,
			invalid,
			dispatch
		} = this.props;

		const renderError = ({ meta: { touched, error } }) =>
			(touched && error ? (
				<div className="help-block">
					<i className="icon-badgealert" aria-hidden="true" />
					{error}
				</div>
			) : false);
		return (
			<Modal data-spec="ticketing-notes-modal" show onClose={onClose}>
				<form onSubmit={handleSubmit}>
					<FormattedMessage
						id="ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT"
						defaultMessage="Select sim for this account"
					>
						{(formattedValue) => (
							<ModalHeader title={formattedValue} closeModal={onClose} />
						)}
					</FormattedMessage>
					<ModalBody className={styles.modal_body}>
						<div data-spec="body-rate-plans" className={styles.body}>
							{parentSimSkuRequest && <Loading />}

							{!parentSimSkuRequest &&
								simSkus &&
								simSkus.resultList.length === 0 && <NoData />}
							{parentSimSkuFail && !parentSimSkuRequest && null}
							{simSkus && simSkus.resultList.length > 0 && (
								<div>
									{simSkus.resultList.map((item) => (
										<div className={styles.field_wrapper} key={item.name}>
											<Field
												type="checkbox"
												component={Checkbox}
												name={`${item.name}<#>${item.id}`}
												label={item.name}
											/>
										</div>
									))}
									<Field name="parentSimSku" component={renderError} />
								</div>
							)}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							dataSpec={'close'}
							variant="link"
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
							onClick={onClose}
							type="button"
						/>
						<Button
							type="button"
							variant="primary"
							label={<FormattedMessage id="APPLY" defaultMessage="Apply" />}
							disabled={
								(simSkus && simSkus.resultList.length === 0) ||
								invalid ||
								parentSimSkuRequest
							}
							onClick={() => dispatch(submit('AddSimSkusFromParent'))}
						/>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { func, bool, object } = PropTypes;

AddSimSkuModal.propTypes = {
	onClose: func,
	handleSubmit: func,
	invalid: bool,
	getParentSimSkus: func,
	getParentSimSkusInit: func,
	parentSimSkuRequest: bool,
	parentSimSkuFail: bool,
	simSkus: object,
	account: object,
	dispatch: func
};

export default reduxForm({
	form: 'AddSimSkusFromParent',
	validate,
	onSubmit: submitForm
})(AddSimSkuModal);
