import { Status } from '../../../../utils/constants';

export const getBatchActionsRequest = (state) =>
	state.batchHistory.batchActions.getBatchActions.status === Status.PENDING;
export const getBatchActionsFail = (state) =>
	state.batchHistory.batchActions.getBatchActions.status === Status.FAIL;
export const getBatchActionsSuccess = (state) => (
	state.batchHistory.batchActions.getBatchActions.data ?
	{
		...state.batchHistory.batchActions.getBatchActions.data,
		resultList: state.batchHistory.batchActions.getBatchActions.data
			.resultList.map((x) => ({ ...x, displayFileName: x.fileName.replace('batchfiles/', '') }))
	} : null);
