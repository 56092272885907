export const UPDATE_BULK_NOTES_REQUEST = 'UPDATE_BULK_NOTES_REQUEST';
export const UPDATE_BULK_NOTES_SUCCESS = 'UPDATE_BULK_NOTES_SUCCESS';
export const UPDATE_BULK_NOTES_FAIL = 'UPDATE_BULK_NOTES_FAIL';

export const UPDATE_BULK_NOTES_STARTED = 'UPDATE_BULK_NOTES_STARTED';
export const UPDATE_BULK_NOTES_ENDED = 'UPDATE_BULK_NOTES_ENDED';
export const UPDATE_BULK_NOTES_ADD_FILTERED_ENDPOINTS =
	'UPDATE_BULK_NOTES_ADD_FILTERED_ENDPOINTS';

export const UPDATE_BULK_NOTES_ERROR_CLEAR = 'UPDATE_BULK_NOTES_ERROR_CLEAR';
