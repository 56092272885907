import { combineReducers } from 'redux';
import globalModalReducer from '../../../../redux/global/globalModalReducer';
import globalReducer from '../../../../redux/global/globalReducer';
import { FILTER_MODAL, GET_FILTERS } from './constants';
import filterData from './filterData/reducers';
import originalFilter from './originalFilter/reducers';
import resetFilter from './resetFilter/reducers';

export default combineReducers({
	isOpen: globalModalReducer(FILTER_MODAL),
	getFilters: globalReducer(GET_FILTERS),
	filterData,
	originalFilter,
	resetFilter
});
