import React from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
	isUserAllowedToAccess,
	ComponentsRender
} from '../../../../utils/AuthSelector';
import { defaultTab } from '../../utils/constants';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import Accounts from '../../views/AccountsView';
import CustomFieldLabels from '../../views/CustomFieldLabelsView';
// import BlacklistedPermissions from '../../views/BlacklistedPermissionsView';
import EditCompanyOverview from '../../views/EditCompanyOverviewView';

const CustomLabelsROComponent = ComponentsRender(
	'mnc.endpoints.customlabels_ro'
);
const CustomLabelsRWComponent = ComponentsRender(
	'mnc.endpoints.customlabels_rw'
);

const availableTabs = [
	{
		tab: {
			id: 'companyoverview',
			label: (
				<FormattedMessage
					id="ONBOARDING.COMPANY_DETAILS"
					defaultMessage="Company Details"
				/>
			)
		}
	},
	{
		services: ['mnc.m2msubaccounts_rw', 'mnc.m2msubaccounts_ro'],
		tab: {
			id: 'accounts',
			label: (
				<FormattedMessage id="ONBOARDING.ACCOUNTS" defaultMessage="Accounts" />
			)
		}
	},
	{
		services: [
			'mnc.endpoints.customlabels_ro',
			'mnc.endpoints.customlabels_rw'
		],
		tab: {
			id: 'customfieldlabels',
			label: (
				<FormattedMessage id="CUSTOM_FIELDS" defaultMessage="Custom Fields" />
			)
		}
	}
	/* {
		services: [
			'mnc.endpoints.customlabels_ro',
			'mnc.endpoints.customlabels_rw'
		],
		tab: {
			id: 'features',
			label: (
				<FormattedMessage
					id="ONBOARDING.COMPANY_FEATURES"
					defaultMessage="Company Features"
				/>
			)
		}
	} */
];

class MyCompany extends React.Component {
	constructor(props) {
		super(props);
		this.CompanyProfileTabs = availableTabs
			.filter((item) => isUserAllowedToAccess(item.services, props.user))
			.map((i) => i.tab);

		let activeTab =
			this.CompanyProfileTabs.length > 0 ? this.CompanyProfileTabs[0] : null;
		if (props.defaultTab === defaultTab.companyoverview) {
			activeTab = this.CompanyProfileTabs.find(
				(tab) => tab.id === defaultTab.companyoverview
			);
		}
		if (props.defaultTab === defaultTab.accounts) {
			activeTab = this.CompanyProfileTabs.find(
				(tab) => tab.id === defaultTab.accounts
			);
		}
		if (props.defaultTab === defaultTab.customFieldLabels) {
			activeTab = this.CompanyProfileTabs.find(
				(tab) => tab.id === defaultTab.customFieldLabels
			);
		}
		if (props.defaultTab === defaultTab.features) {
			activeTab = this.CompanyProfileTabs.find(
				(tab) => tab.id === defaultTab.features
			);
		}

		this.state = { activeTab };
	}

	componentDidMount() {
		this.getMyCompanyFunction();
	}

	getMyCompanyFunction = () => {
		const { getMyCompany } = this.props;

		getMyCompany({
			additionalParams: {
				include: [
					'CompanyContacts',
					'CompanyAddresses',
					'CompanyAddresses.Country',
					'CompanyAddresses.State',
					'CompanyContacts.CompanyContactType',
					'CompanyAddresses.AddressType',
					'CompanyLogo',
					'Language'
				]
			}
		});
	};

	onNewTabClick = (id) => {
		const { push } = this.props;
		if (id === defaultTab.companyoverview) push('/my-company/companyoverview');
		if (id === defaultTab.accounts) push('/my-company/accounts');
		if (id === defaultTab.customFieldLabels) {
			push('/my-company/custom-field-labels');
		}
		if (id === defaultTab.features) {
			push('/my-company/company-features');
		}
	};

	render() {
		const {
			messages,
			isMyCompany,
			companyRequest,
			companyFail,
			company,
			editMyCompany,
			editMyCompanyRequest,
			editMyCompanySuccess,
			editMyCompanyFail,
			getMyCompanyRequest,
		} = this.props;

		const tabComponents = {
			companyoverview: (
				<React.Fragment key="accounts">
					{companyRequest && <Loading data-spec="loading" />}
					{companyFail && null}
					{!companyRequest && !companyFail && (
						<EditCompanyOverview
							company={company && company.resultList[0]}
							isMyCompanyOverview
							editCompany={editMyCompany}
							getCompany={this.getMyCompanyFunction}
							getCompanyRequest={getMyCompanyRequest}
							editCompanyRequest={editMyCompanyRequest}
							editCompanySuccess={editMyCompanySuccess}
							editCompanyFail={editMyCompanyFail}
						/>
					)}
				</React.Fragment>
			),
			customfieldlabels: (
				<React.Fragment key="custom-field-labels">
					<CustomLabelsROComponent>
						<CustomFieldLabels messages={messages} disableEditing />
					</CustomLabelsROComponent>
					<CustomLabelsRWComponent>
						<CustomFieldLabels messages={messages} />
					</CustomLabelsRWComponent>
				</React.Fragment>
			),
			accounts: (
				<React.Fragment key="accounts">
					<Accounts messages={messages} isMyCompany={isMyCompany} />
				</React.Fragment>
			)
			/* features: (
				<React.Fragment key="features">
					<BlacklistedPermissions />
				</React.Fragment>
			) */
		};

		const actionTabs = this.CompanyProfileTabs.map((tab) => {
			const { activeTab } = this.state;
			const name = messages[tab.label] || tab.label;
			return {
				name,
				isActive: activeTab && tab.id === activeTab.id,
				onClick: () => this.onNewTabClick(tab.id),
				component: tabComponents[tab.id]
			};
		});

		return (
			<div data-spec="company_profile">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.MY_COMPANY"
							defaultMessage="My Company"
						/>
					}
				/>
				<TabsBar tabs={actionTabs} />
				{(() => {
					const active = actionTabs.find((tab) => tab.isActive);
					return active.component;
				})()}
			</div>
		);
	}
}

const { string, objectOf, shape, bool, func } = PropTypes;

MyCompany.propTypes = {
	messages: objectOf(string),
	user: shape(),
	defaultTab: string,
	isMyCompany: bool,
	push: func,
	company: object,
	getMyCompany: func,
	companyRequest: bool,
	companyFail: bool,
	editMyCompany: func,
	editMyCompanyRequest: bool,
	editMyCompanySuccess: bool,
	editMyCompanyFail: bool,
	getMyCompanyRequest: bool,
};

export default MyCompany;
