import React, { PureComponent } from 'react';
import { objectOf, func, string, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import GroupButton from '../GroupButton';
import SMSTab from '../../views/SMSTabView';
import DiagnosticsDetails from '../DiagnosticDetails';
import RegistrationDetails from '../RegistrationDetails';
import { rdFields, ddFields, ddCarrierField } from '../../utils/constants';

import styles from './Diagnostic.scss';

export default class DiagnosticWrapper extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedButton: 0,
			refresh: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { refresh } = this.state;

		if (prevState.refresh !== refresh) {
			this.refresh();
		}
	}

	refresh = () => this.setState({
		refresh: false,
	});

	render() {
		const {
			messages,
			onButtonClick,
			iccid,
			endpoint = {},
			dataDiagnosticsDetails,
			dataRegistrationDetails,
			isFetchingDiagnosticsDetails,
			isFetchingRegistrationDetails,
			isSuccessEndpointsRegistrationDetails,
			isCanceling,
			submitCancelRegistration,
			getRegistrationDetails,
			user,
		} = this.props;
		const { selectedButton, refresh } = this.state;

		if (endpoint.serviceProvider === 'VIVO') {
			return (
				<div
					data-spec="diagnostics-wrapper"
					className={styles.diagnostics_wrapper}
				>
					<header className={styles.header}>
						<span>
							<FormattedMessage
								id="DIAGNOSTICS_FOR_THIS_ENDPOINT"
								defaultMessage="Diagnostics for this endpoint is currently unavailable"
							/>
						</span>
					</header>
				</div>
			);
		}

		return (
			<div
				data-spec="diagnostics-wrapper"
				className={styles.diagnostics_wrapper}
			>
				<header className={styles.header}>
					<div className={styles.button_wrapper}>
						<GroupButton>
							<Button
								dataSpec="diagnostic-button"
								variant="outline-primary"
								onClick={() => {
									this.setState({ selectedButton: 1 });
									onButtonClick(iccid);
								}}
								label={<FormattedMessage
									id="DIAGNOSTIC_BUTTON"
									defaultMessage="Start Diagnostic"
								/>}
								disabled={false}
							/>
							<Button
								dataSpec="diagnostic-button"
								variant="outline-primary"
								onClick={() => {
									this.setState({ selectedButton: 1 });
									getRegistrationDetails(iccid);
								}}
								label={<FormattedMessage
									id="DEVICE_REGISTRATION_INFO_BUTTON"
									defaultMessage="Device Registration Info"
								/>}
								disabled={false}
							/>
							{isUserAllowedToAccess([
								'mnc.endpoints.smstab_rw',
								'mnc.endpoints.smstab_ro',
							], user) ? (
								<Button
									dataSpec="sms-button"
									variant="outline-primary"
									onClick={() => this.setState({
										selectedButton: 2,
										refresh: true,
									})}
									label={<FormattedMessage
										id="SEND_SMS_TO_ENDPOINT"
										defaultMessage="Send SMS to Endpoint"
									/>}
									disabled={false}
								/>
							) : null}
						</GroupButton>
					</div>
				</header>
				{selectedButton === 1 && (
					Object.keys(dataDiagnosticsDetails).length ||
					Object.keys(dataRegistrationDetails).length ||
					isFetchingDiagnosticsDetails ||
					isFetchingRegistrationDetails
				) && (
					<main className={styles.main}>
						<DiagnosticsDetails
							iccid={iccid}
							carrierName={endpoint.networkOperator}
							endpoint={endpoint}
							data={dataDiagnosticsDetails}
							fields={ddFields}
							isPending={isFetchingDiagnosticsDetails}
							carrier={ddCarrierField}
						/>
						<RegistrationDetails
							iccid={iccid}
							endpoint={endpoint}
							data={dataRegistrationDetails}
							fields={rdFields}
							isFetching={isFetchingRegistrationDetails}
							isCanceling={isCanceling}
							submitCancelRegistration={submitCancelRegistration}
							isSuccess={isSuccessEndpointsRegistrationDetails}
						/>
					</main>
				)}
				{!refresh && selectedButton === 2 && (
					<SMSTab
						messages={messages}
						id={iccid}
						m2mAccountId={endpoint.m2mAccountId}
					/>
				)}
			</div>
		);
	}
}

DiagnosticWrapper.propTypes = {
	messages: objectOf(string),
	onButtonClick: func,
	iccid: string.isRequired,
	endpoint: object,
	dataDiagnosticsDetails: object,
	dataRegistrationDetails: object,
	isFetchingDiagnosticsDetails: bool,
	isFetchingRegistrationDetails: bool,
	isSuccessEndpointsRegistrationDetails: bool,
	isCanceling: bool,
	submitCancelRegistration: func,
	getRegistrationDetails: func,
	user: object,
};
DiagnosticWrapper.defaultProps = {
	messages: {},
	onButtonClick: undefined,
	endpoint: {},
	dataDiagnosticsDetails: {},
	dataRegistrationDetails: {},
	isFetchingDiagnosticsDetails: false,
	isFetchingRegistrationDetails: false,
	isSuccessEndpointsRegistrationDetails: false,
	isCanceling: false,
	submitCancelRegistration: undefined,
	getRegistrationDetails: undefined,
	user: {},
};
