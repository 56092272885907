import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import styles from './MoveToSubAccountModal.scss';

import { required } from '../../../../utils/validators';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Button from '../../../../lib/DigitalComponents/Button';

import Loading from '../../../../lib/DigitalComponents/Loader';

class MoveToSubAccountModal extends Component {
	submitForm = (values, _, props) => {
		const { moveEndpoints } = this.props;
		const m2mAccountId = values.to.value;
		const endpoints = props.endpoints.map((item) => item);
		moveEndpoints({ m2mAccountId, endpoints: { endpoints } });
	};

	render() {
		const {
			account,
			accounts,
			onClose,
			endpoints,
			invalid,
			handleSubmit,
			moveEndpointsRequest
		} = this.props;
		const tabs = [
			{
				id: 'move-endpoints',
				displayName: 'Move Endpoints',
				active: true,
				content: () => (
					<div key="move-endpoints">
						<div className={styles.headline}>
							<FormattedMessage
								id="ONBOARDING.CONFIRM_ENDPOINTS_MOVE"
								defaultMessage="Confirm Endpoints Move"
							/>
						</div>
						<div>
							<div className={styles.sub_headline}>
								{endpoints.length}
								<FormattedMessage
									id="ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE"
									defaultMessage=" endpoints will be moved to an account"
								/>
							</div>
							<div className={styles.field_wrapper}>
								<div className={styles.label}>
									<FormattedMessage
										id="ONBOARDING.FROM"
										defaultMessage="From "
									/>
								</div>
								<div className={styles.info}>{account.m2mAccountName}</div>
							</div>
							<div className={styles.field_wrapper}>
								<FormattedMessage id="ONBOARDING.TO" defaultMessage="To">
									{(formattedValue) => (
										<Field
											component={Select}
											name="to"
											placeholder={formattedValue}
											options={
												accounts &&
												accounts.resultList.length > 0 &&
												accounts.resultList
													.filter((item) => item.id !== account.id)
													.map((item) => ({
														value: item.m2mAccountId,
														label: item.m2mAccountName
													}))
											}
											label={formattedValue}
											validate={[required]}
											cleanValue
										/>
									)}
								</FormattedMessage>
							</div>
							<div>
								<FormattedMessage
									id="ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE"
									defaultMessage="Once you Initiate this move, the selected Endpoints will be moved within a few minutes. Would you like to proceed with this action?"
								/>
							</div>
						</div>
					</div>
				)
			}
		];
		return (
			<Modal
				show
				onClose={onClose}
				data-spec="network-entitlements-modal"
			>
				<form onSubmit={handleSubmit(this.submitForm)}>
					<ModalHeader
						title={
							<FormattedMessage
								id="ONBOARDING.MOVE_TO_SUB_ACCOUNT"
								defaultMessage="Move to Sub-Account"
							/>
						}
						closeModal={onClose}
					/>

					<ModalBody>
						{moveEndpointsRequest ? <Loading /> : <ModalTabs tabs={tabs} />}
					</ModalBody>
					<ModalFooter>
						<Button
							dataSpec="close"
							variant="link"
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
							onClick={onClose}
							type="button"
							disabled={moveEndpointsRequest}
						/>
						<Button
							type="submit"
							variant="primary"
							label={<FormattedMessage id="SUBMIT" defaultMessage="Submit" />}
							dataSpec="submit"
							disabled={
								(endpoints && endpoints.length === 0) ||
								invalid ||
								moveEndpointsRequest
							}
						/>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { func, object, array, bool } = PropTypes;

MoveToSubAccountModal.propTypes = {
	account: object,
	accounts: object,
	onClose: func,
	endpoints: array,
	invalid: bool,
	handleSubmit: func,
	moveEndpoints: func,
	moveEndpointsRequest: bool
};

export default reduxForm({
	form: 'MoveToSubAccount'
})(MoveToSubAccountModal);
