export default {
	'SUPPORT.USER_GUIDE': '用户指南',
	'SUPPORT.PRINT_PDF': '打印PDF',
	'SUPPORT.SUPPORT': '支持',
	'SUPPORT.FAQ': '常见问题',
	'SUPPORT.FAQ_SUPPORT_INFO': '浏览用户常见问题，快速找到答案。',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'阅读关于门户网站功能的逐步详细说明。本指南专门针对您的账户类型而编写。',
	'SUPPORT.TABLE_OF_CONTENTS': '目录',
	'SUPPORT.TICKETING': '工单',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'如果您遇到系统宕机，或您的端点出现服务问题，且诊断测试无法为您提供解决方案，请创建一份故障通知单，发给我们的运营团队。您也可以用通知单提出与账单及服务相关的问题。',
	'SUPPORT.RECENT_NOTIFICATIONS': '最近通知',
	'SUPPORT.CREATE_NEW_NOTIFICATION': '创建新通知',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': '查看全部通知',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': '常见问题',
	'SUPPORT.TOPICS': '主题',
	'SUPPORT.EDIT_NOTIFICATION': '编辑通知',
	'SUPPORT.GLOBAL_NOTIFICATION': '全局通知',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': '与公司相关的通知',
	'SUPPORT.NOTIFICATION_TYPE': '通知类型？',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER': '选择通知占位符',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY': '选择需要通知的用户群组：',
	'SUPPORT.CONTENT': '内容',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': '添加通知内容',
	'SUPPORT.PUBLISH': '发布',
	'SUPPORT.GLOBAL': '全局',
	'SUPPORT.COMPANY_RELATED': '与公司相关',
	'SUPPORT.NOTIFICATION_CONFIRMATION': '确认',
	'SUPPORT.NOTIFICATION_INFO': '信息',
	'SUPPORT.NOTIFICATION_WARNING': '警告',
	'SUPPORT.NOTIFICATION_ERROR': '错误',
	'SUPPORT.SELECT_SECTION': '选择部门',
	'SUPPORT.ALL': '全部',
	'SUPPORT.ADMIN': '管理员',
	'SUPPORT.USER': '用户',
	'SUPPORT.FINANCE': '财务',
	'SUPPORT.OPERATIONS': '运营',
	'SUPPORT.RESTRICTEDACCESS': '访问受限',
	'SUPPORT.COMPANYVIEW': '公司视图',
	'SUPPORT.COMPANYVIEWREADONLY': '只读公司视图',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': '添加新版本说明',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'以下文档没有全部翻译到所选语言。这里显示的是英文版本。 翻译至所选语言的版本将在未来产品发布时提供。'
};
