import globalAction from '../../../../redux/global/globalAction';

import { GET_RATE_PLAN_ZONES, GET_RATE_PLAN_ZONES_FILTERS } from './constants';

import {
	getRatePlanZones as getRatePlanZonesService,
	getRatePlanZonesFilters as getRatePlanZonesFiltersService
} from '../../services/RatePlanZonesService';

/** ************ get rate plan zones ************* */
export const getRatePlanZones = (id, params = {}) =>
	globalAction(GET_RATE_PLAN_ZONES, getRatePlanZonesService, {
		id,
		params
	});

/** ************ get rate plan zones filters ************* */
export const getRatePlanZonesFilters = (id, params = {}) =>
	globalAction(GET_RATE_PLAN_ZONES_FILTERS, getRatePlanZonesFiltersService, {
		id,
		params
	});
