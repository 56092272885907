export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': 'Delete Automation Rule?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION':
		'Delete selected automation rules?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE':
		'Are you sure you would like to delete selected automation rules?',
	'AUTOMATION.CANCEL': 'Cancel',
	'AUTOMATION.DELETE': 'Delete',
	'AUTOMATION.ARE_YOU_SURE': 'Are you sure?',
	'AUTOMATION.ACTIVE': 'Activated',
	'AUTOMATION.ACC_IF': 'If',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Add Another Threshold',
	'AUTOMATION.EDIT_RULE': 'Edit Rule',
	'AUTOMATION.CREATE_NEW_RULE': 'Create New Rule',
	'AUTOMATION.PLATFORM': 'Platform',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Account',
	'AUTOMATION.CATEGORY': 'Category',
	'AUTOMATION.RULE_STATE': 'Rule State',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Active',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inactive',
	'AUTOMATION.TRIGGER': 'Trigger',
	'AUTOMATION.ACC_RULE_NAME': 'Rule Name',
	'AUTOMATION.SAVE': 'Save',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Clear Selected Filters',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Select Filters',
	'AUTOMATION.AUTOMATION': 'Automation',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Create new automation rule',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE':
		'Are you sure you would like to delete automation rule {ruleName}?',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Rule Created',
	'AUTOMATION.ADD_RULE_ERROR':
		'Failed to Create Automation Rule, Please Try Again.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Rule Edited',
	'AUTOMATION.EDIT_RULE_ERROR':
		'Failed to Edit Automation Rule, Please Try Again.',
	'AUTOMATION.THEN': 'Then',
	'AUTOMATION.NUMBER_OF_DAYS': 'Number of Days',
	'AUTOMATION.NUMBER_OF_HOURS': 'Number of Hours (Max 24h)',
	'AUTOMATION.AT': 'At',
	'AUTOMATION.SIM_STATE': 'SIM State',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'Rate Plan ID',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'M2M Sub Account ID',
	'AUTOMATION.CUSTOMER_ID': 'Customer ID',
	'AUTOMATION.CUSTOM': 'Custom',
	'AUTOMATION.FILTER': 'Filter',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'No Available Filters',
	'AUTOMATION.THRESHOLD': 'Threshold',
	'AUTOMATION.ZONE_MODEL': 'Zone Model',
	'AUTOMATION.ZONE_NAME': 'Zone Name',
	'AUTOMATION.POOL_ID': 'Pool ID',
	'AUTOMATION.COUNTRY': 'Country',
	'AUTOMATION.FROM_KEY': 'From',
	'AUTOMATION.TO_KEY': 'To',
	'AUTOMATION.FOLLOW_UP_WITH': 'Follow Up With',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED':
		'Automation Rule Successfully Deleted',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED':
		'Selected automation alerts successfully deleted',
	'AUTOMATION.AUTOMATION_DELETE_ERROR':
		'Failed to Delete Automation Rule, Please Try Again.',
	'AUTOMATION.PLATFORMS': 'Platforms',
	'AUTOMATION.CATEGORIES': 'Categories',
	'AUTOMATION.TRIGGERS': 'Triggers',
	'AUTOMATION.M2MACCOUNTS': 'M2M Accounts',
	'AUTOMATION.HOURS_USING': 'In past 24 hours using',
	'AUTOMATION.LOCATIONUPDATE': 'Location Update',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Authentication Update',
	'AUTOMATION.ZONE': 'Zone',
	'AUTOMATION.ACCOUNT_ID': 'Account ID',
	'AUTOMATION.ACTION': 'Action',
	'AUTOMATION.DCP': 'DCP',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'SMS',
	'AUTOMATION.USAGE': 'Usage',
	'AUTOMATION.SIM_PROVISIONING': 'SIM Provisioning',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Subscription Monitoring',
	'AUTOMATION.USAGE_MONITORING': 'Usage Monitoring',
	'AUTOMATION.NETWORK_MONITORING': 'Network Monitoring',
	'AUTOMATION.PRICING_AUTOMATION': 'Pricing Automation',
	'AUTOMATION.SECURITY_MONITORING': 'Security Monitoring',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'SIM Rate Plan Change',
	'AUTOMATION.SIM_STATE_CHANGE': 'SIM State Change',
	'AUTOMATION.SMS_USAGE': 'SMS Usage',
	'AUTOMATION.SIM_DATA_LIMIT': 'SIM Data Limit',
	'AUTOMATION.SIM_EXPIRATION': 'SIM Expiration',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Cycle To Date Data Usage',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Cycle To Date Voice Usage',
	'AUTOMATION.CYCLE_TO_DATE_SMS_USAGE': 'Cycle to Date SMS Usage',
	'AUTOMATION.LAST_24_HOURS_SMS_USAGE': 'Last 24 Hours SMS Usage',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS':
		'Data Usage Exceeded In Past 24 Hours',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_APPROACHES':
		'SMS usage in past 24 hours approaches',
	'AUTOMATION.CYCLE_TO_DATE_SMS_USAGE_IS': 'Cycle to Date SMS usage is',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION':
		'Last 24 Hours Number Of Sessions Connection',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Monthly Pooled Data Usage',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Monthly Pooled SMS Usage',
	'AUTOMATION.NO_CONNECTION': 'No Connection',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Past 24 Hours Voice Usage',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'Register In A Zone',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Too Many Connections',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'SMS MO Over Run Per Timeframe',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'SMS MO Under Run Per Timeframe',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'SMS MT Over Run Per Timeframe',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'SMS MT Under Run Per Timeframe',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'GPRS Long Term Over Run',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'GPRS Short Term Over Run',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'First Data Session Established',
	'AUTOMATION.IMEI_CHANGED': 'IMEI Changed',
	'AUTOMATION.NETWORK_REGISTRATION': 'Network Registration',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Network Registration In A Zone',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'SIM Custom Field Change',
	'AUTOMATION.SIM_STATUS': 'SIM Status',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Rate Plan Changed',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'SIM Country Changed',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'SIM Rate Plan Expiring',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Data Session Stopped',
	'AUTOMATION.DATA_SESSION_STARTED': 'Data Session Started',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Data Usage In A Country',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE':
		'Data Sessions Count - Bill Cycle',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Data Usage In A Zone',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'No Connection Bill Cycle',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS':
		'Number Of Session Connections Last 24 Hours',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'SMS Count In A Country',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'SMS Usage In A Zone',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'SMS Usage Last 24 Hours',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Total Data Usage - 24 Hours',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE':
		'Total Data Usage - Billing Cycle',
	'AUTOMATION.SMS_MO_RECEIVED': 'SMS MO Received',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE':
		'Cycle to Date Voice Usage in a Zone',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'SIM Plan Completion Voice',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'SIM Plan Completion Data',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'IMEI Whitelist Action',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'SIM Account Change',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Recent (24 hours) SMS Usage',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE':
		'Network Registration in a Zone',
	'AUTOMATION.MSISDN_CHANGE': 'MSISDN Change',
	'AUTOMATION.IMEI_CHANGE': 'IMEI Change',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE':
		'Cycle to Date Data Usage in a Zone',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM':
		'An endpoint is transferred from',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES':
		'Any Endpoint in the current cycle uses',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM':
		'Any Endpoint on a prepaid rate plan completes its prepaid term',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI':
		"The network detects a change in the Endpoint's IMEI",
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT':
		'The system detects MSISDN change for an Endpoint',
	'AUTOMATION.INITIATE_OTA_SWAP': 'Initiate OTA Swap',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES':
		'Any Endpoint sends or receives more than',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS':
		'Any SIM experiences a change in one of the custom fields',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST':
		'Endpoint IMEI was not found in the whitelist',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS':
		'Any Endpoint in the current cycle exceeds',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE':
		'A MO SMS is received for a short code',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'Total SMS Count - Billing Cycle',
	'AUTOMATION.1_HOUR': '1 Hour',
	'AUTOMATION.2_HOURS': '2 Hours',
	'AUTOMATION.3_HOURS': '3 Hours',
	'AUTOMATION.4_HOURS': '4 Hours',
	'AUTOMATION.5_HOURS': '5 Hours',
	'AUTOMATION.6_HOURS': '6 Hours',
	'AUTOMATION.7_HOURS': '7 Hours',
	'AUTOMATION.8_HOURS': '8 Hours',
	'AUTOMATION.9_HOURS': '9 Hours',
	'AUTOMATION.10_HOURS': '10 Hours',
	'AUTOMATION.11_HOURS': '11 Hours',
	'AUTOMATION.12_HOURS': '12 Hours',
	'AUTOMATION.13_HOURS': '13 Hours',
	'AUTOMATION.14_HOURS': '14 Hours',
	'AUTOMATION.15_HOURS': '15 Hours',
	'AUTOMATION.16_HOURS': '16 Hours',
	'AUTOMATION.17_HOURS': '17 Hours',
	'AUTOMATION.18_HOURS': '18 Hours',
	'AUTOMATION.19_HOURS': '19 Hours',
	'AUTOMATION.20_HOURS': '20 Hours',
	'AUTOMATION.21_HOURS': '21 Hours',
	'AUTOMATION.22_HOURS': '22 Hours',
	'AUTOMATION.23_HOURS': '23 Hours',
	'AUTOMATION.24_HOURS': '24 Hours',
	'AUTOMATION.25_HOURS': '25 Hours',
	'AUTOMATION.26_HOURS': '26 Hours',
	'AUTOMATION.27_HOURS': '27 Hours',
	'AUTOMATION.28_HOURS': '28 Hours',
	'AUTOMATION.29_HOURS': '29 Hours',
	'AUTOMATION.30_HOURS': '30 Hours',
	'AUTOMATION.31_HOURS': '31 Hours',
	'AUTOMATION.32_HOURS': '32 Hours',
	'AUTOMATION.33_HOURS': '33 Hours',
	'AUTOMATION.34_HOURS': '34 Hours',
	'AUTOMATION.35_HOURS': '35 Hours',
	'AUTOMATION.36_HOURS': '36 Hours',
	'AUTOMATION.37_HOURS': '37 Hours',
	'AUTOMATION.38_HOURS': '38 Hours',
	'AUTOMATION.39_HOURS': '39 Hours',
	'AUTOMATION.40_HOURS': '40 Hours',
	'AUTOMATION.41_HOURS': '41 Hours',
	'AUTOMATION.42_HOURS': '42 Hours',
	'AUTOMATION.43_HOURS': '43 Hours',
	'AUTOMATION.44_HOURS': '44 Hours',
	'AUTOMATION.45_HOURS': '45 Hours',
	'AUTOMATION.46_HOURS': '46 Hours',
	'AUTOMATION.47_HOURS': '47 Hours',
	'AUTOMATION.48_HOURS': '48 Hours',
	'AUTOMATION.LAST_24_HOURS': 'Last 24 Hours',
	'AUTOMATION.CYCLE_TO_DATE': 'Cycle To Date',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'SIM Prepaid Rate Plan Exceeds',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'Data Usage Exceeds Limit',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'Data Usage Approaches Limit',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN':
		'SIMS Prepaid Rate Plan Comes Within',
	'AUTOMATION.MO_MT': 'MO/MT',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'Voice Usage Exceeds Limit',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'Voice Usage Approaches Limit',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'SIM Has Established More Than',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'SIM Has Established Less Than',
	'AUTOMATION.EXCEEDES': 'Exceeded',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS':
		'SIM Established No Data Connection For Past N Hours',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE':
		'SIM Registers To Network In Zone',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE':
		'SIM Established More Than Connections In The Current Cycle',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE':
		'The Network Detects A New Device',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'Cycle To Date Usage Exceeds',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'Cycle To Date Usage Approaches',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_':
		'The Network Detects A Change In ...',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK':
		'SIM Has Registered For The First Time In A New Network',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'Endpoint Registers In Zone',
	'AUTOMATION.CUSTOM_FIELD_1': 'Custom Field 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Custom Field 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Custom Field 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Custom Field 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Custom Field 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Custom Field 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Custom Field 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Custom Field 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Custom Field 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Custom Field 10',
	'AUTOMATION.CHANGED': 'Changed',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Rate Plan Change',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES':
		'The Number Of Data Sessions For A Sim Exceedes',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'A SIMS Data Session Starts',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'A SIMS Data Session Stops',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS':
		'Data Usage In A Country Exceeds',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'Data Usage In A Exceeds',
	'AUTOMATION.EXCEEDS': 'Exceeded',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES':
		'Billable SMS Usage On Any Pooled Rate Plan Reaches',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE':
		'Any SIM Has Established No Data Connection For The Past Billing Cycle',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'A SIM Has More Than',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'SMS Count In A Country Exceeds',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'SMS Count Exceeds Limit',
	'AUTOMATION.1_WEEK': '1 Week',
	'AUTOMATION.2_WEEKS': '2 Weeks',
	'AUTOMATION.3_WEEKS': '3 Weeks',
	'AUTOMATION.4_WEEKS': '4 Weeks',
	'AUTOMATION.SEND_EMAIL': 'Send Email',
	'AUTOMATION.SEND_SMS': 'Send SMS',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT':
		"Change Device's Network Entitlement",
	'AUTOMATION.CHANGE_DEVICE_S_NETWORK_ENTITLEMENT':
		"Change Device's Network Entitlement",
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': "Change Device's SIM State",
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': "Change Device's Rate Plan",
	'AUTOMATION.PUSH_API': 'Push API',
	'AUTOMATION.COMBINED': 'Combined',
	'AUTOMATION.MO': 'MO',
	'AUTOMATION.MT': 'MT',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Connections In A 24 Hour',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Change Network Entitlement',
	'AUTOMATION.CHANGE_SIM_STATUS': 'Change SIM Status',
	'AUTOMATION.SMS_MESSAGES': 'SMS messages',
	'AUTOMATION.HOURS': 'Hours',
	'AUTOMATION.MIN': 'Min',
	'AUTOMATION.MINUTES': 'Minutes',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Change Rate Plan',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE':
		'Connections In The Current Cycle',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Revert To Previous Rate Plan',
	'AUTOMATION.MODIFIED_DELETED': 'Modified/Deleted',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING':
		'The SIMS Rate Plan Is One Day From Expiring',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE':
		'Amount Of Its Pooled Included SMS Usage',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS':
		'Number Of Data Session In The Last 24 Hours',
	'AUTOMATION.MSGS': 'MSGs',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'SMS Count In Zone Exceeds',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS':
		'Number Of Data Sessions In The Last 24 Hours',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Hours Of Consumption',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Revert To Previous SIM State',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Days After The Trigger Event',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'End Of Current Billing Cycle',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT':
		'The UTC Time After Trigger Event',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT':
		'Revert To Previous Network Entitlement',
	'AUTOMATION.GMNA': 'GM North America',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Enterprise',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM North America',
	'AUTOMATION.ENTERPRISE': 'Enterprise',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'Change SIM State',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'Past 24 Hours SMS Usage',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS':
		'SMS Usage In Past 24 Hours Exceeds',
	// Triggers
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS':
		'SMS MO (Mobile Originated) Usage - 24 Hours',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE':
		'SMS MO (Mobile Originated) Usage - In a Zone',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN':
		'SMS MO (Mobile Originated) Usage - Pooled Plan',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS':
		'SMS MT (Mobile Terminated) Usage - 24 Hours',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE':
		'SMS MT (Mobile Terminated) Usage - Bill Cycle',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE':
		'SMS MT (Mobile Terminated) Usage - In a Zone',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN':
		'SMS MT (Mobile Terminated) Usage - Pooled Plan',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED':
		'The Day After the SIM Rate Plan Expired',
	'AUTOMATION.BYTES': 'Bytes',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Add custom text to SMS message (optional)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER':
		'Custom message text (140 characters limit)',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'SIM Plan Completion',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM':
		'If any SIM on a prepaid rate plan completes its prepaid term',
	// new automation
	'AUTOMATION.TITLE': 'Automation',
	'AUTOMATION.RULES_TAB_NAME': 'Automation rules',
	'AUTOMATION.ALERTS_TAB_NAME': 'Automation alerts',
	'AUTOMATION_RULES.YES': 'Yes',
	'AUTOMATION_RULES.NO': 'No',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'No Automation Rules Available',
	'AUTOMATION_RULES.NO_DATA_MESSAGE':
		'There are No Automation Rules to show you right now',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'No Automation Alerts Available',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE':
		'There are No Automation Alerts to show you right now',
	//  rules table headers
	'AUTOMATION_RULES.ID': 'ID',
	'AUTOMATION_RULES.RULE_NAME': 'Rule Name',
	'AUTOMATION_RULES.PLATFORM': 'Platform',
	'AUTOMATION_RULES.CATEGORY': 'Category',
	'AUTOMATION_RULES.TRIGGER': 'Trigger',
	'AUTOMATION_RULES.ACTIVE': 'Activated',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'M2M Account ID',
	'AUTOMATION_RULES.DATE_CREATED': 'Date Created',
	'AUTOMATION_RULES.USER': 'User',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'Implementation Status',
	'AUTOMATION_RULES.NOTES': 'Notes',
	// alerts table headers
	'AUTOMATION_ALERTS.ID': 'ID',
	'AUTOMATION_ALERTS.RULE_NAME': 'Rule Name',
	'AUTOMATION_ALERTS.PLATFORM': 'Platform',
	'AUTOMATION_ALERTS.CATEGORY': 'Category',
	'AUTOMATION_ALERTS.TRIGGER': 'Trigger',
	'AUTOMATION_ALERTS.ACTIVE': 'Activated',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'M2M Account ID',
	'AUTOMATION_ALERTS.DATE': 'Date',
	'AUTOMATION_ALERTS.USER': 'User',
	'AUTOMATION_ALERTS.ACTION': 'Action',
	// rules
	'AUTOMATION.ACTIVATE': 'Activate',
	'AUTOMATION.DEACTIVATE': 'Deactivate',
	'AUTOMATION.RULE_CREATED_OR_EDITED':
		'You have selected an Automation rule that requires extended processing and will not be set immediately.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY':
		'Automation Rule Successfully Activated',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'Automation Rule Unsuccessfully Activated',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY':
		'Automation Rule Successfully Deactivated',
	'AUTOMATION.DEACTIVATED_RULE_ERROR':
		'Automation Rule Unsuccessfully Deactivated',
	'AUTOMATION.SHORT_CODE': 'Short Code',
	'AUTOMATION.BASE_RATE_PLAN': 'Base Rate Plan',
	'AUTOMATION.RATE_PLAN_TYPE': 'Rate Plan Type',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'Implementation Status',
	'AUTOMATION.YES': 'Yes',
	'AUTOMATION.NO': 'No',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Rule Status Change',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT':
		'Are you sure you want to do that?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT':
		"You are about to change {currentState} status of a rule to '{selectedState}'",
	'AUTOMATION.REQUEST_DELETION_TEXT':
		'You are about to Request a Deletion of a Rule',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Rule Deletion',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE':
		'You are about to delete rule {ruleName}',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Automation Rule Deletion Request',
	'AUTOMATION.REQUEST_DELETION': 'Request Deletion',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS':
		"You have succesfully changed implementation status to 'Received'",
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS':
		"You have succesfully changed implementation status to 'Implemented'",
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS':
		"You have succesfully changed implementation status to 'Conflicted'",
	'AUTOMATION.SET_RULE_STATE_FAIL':
		'An error has occured while changing implementation status. Please try again.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS':
		'You have succesfully requested deletion of the rule.',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL':
		'An error has occured while requesting deletion of a rule.',
	'AUTOMATION.RULE_CHANGE': 'Automation Rule Change',
	'AUTOMATION.RULE_CREATE': 'Automation Rule Create',
	'AUTOMATION.ACCEPT': 'Accept',
	'AUTOMATION.MANUAL_RULE_DELAY':
		'There will be delay in the implementation of this rule.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION':
		'This rule will take a certain amount of time to be executed.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION':
		'I am aware of the delay in the implementation process.',
	'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Select Action:',
	// statuses
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'Pending for Deletion',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Implemented',
	'AUTOMATION.RULE_STATUS_PENDING': 'Pending',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'Conflicted',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Received',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'Not Applicable',
	//  Zone Model
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'ATT Standard Zone 1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'ATT Standard Zone 2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'ATT Standard Zone 3',
	//  Zone name
	'AUTOMATION.US_DOMESTIC': 'US Domestic',
	'AUTOMATION.EU': 'EU',
	// Tabs
	'AUTOMATION.DETAILS_TAB_NAME': 'Details',
	'AUTOMATION.HISTORY_TAB_NAME': 'History',
	// Change State Modal
	'AUTOMATION.ADD_NOTE': 'Add Note (Optional)',
	'AUTOMATION.ADD_NOTE_PLACEHOLDER': 'Add note',
	'AUTOMATION_RULE_HISTORY.NO_DATA_TITLE': 'No Rule History Available',
	'AUTOMATION_RULE_HISTORY.NO_DATA_MESSAGE':
		'There is No Rule History to show you right now',
	// overall
	'AUTOMATION.VIEW_NOTES': 'View Notes',
	'AUTOMATION.NOTES': 'Notes',
	'AUTOMATION.CLOSE': 'Close',
	'AUTOMATION.EDITED_RULE_INDICATOR':
		'Existing rule is in effect until the new rule has been implemented',
	'AUTOMATION_RULES.NO_RULE_NOTES_TITLE': 'No Rule Notes Available',
	'AUTOMATION_RULES.NO_RULE_NOTES_MESSAGE':
		'There are No Notes to show you right now',
	'AUTOMATION.CREATED_BY': 'Created By',
	'AUTOMATION.DATE_CREATED': 'Date Created',
	'AUTOMATION.MANUAL_RULE_NOTIF_MESSAGE':
		'Rule edited. Existing rule is in effect until the new rule has been implemented. ',
	'AUTOMATION.APPLIED_FILTERS': 'Applied Filters',
	// Rule History table headers
	'AUTOMATION_RULE_HISTORY.DATE': 'Date',
	'AUTOMATION_RULE_HISTORY.USER': 'User',
	'AUTOMATION_RULE_HISTORY.ACTION': 'Action',
	'AUTOMATION_RULE_HISTORY.PLATFORM': 'Platform',
	'AUTOMATION_RULE_HISTORY.CATEGORY': 'Category',
	'AUTOMATION_RULE_HISTORY.TRIGGER': 'Trigger',
	'AUTOMATION_RULE_HISTORY.IF': 'If',
	'AUTOMATION_RULE_HISTORY.THRESHOLD': 'Threshold',
	'AUTOMATION_RULE_HISTORY.RULE_ACTION': 'Rule Action',
	'AUTOMATION_RULE_HISTORY.FILTERS': 'Filters',
	'AUTOMATION_RULE_HISTORY.FOLLOW_UP_ACTION': 'Follow Up Action',
	'AUTOMATION_RULE_HISTORY.RULE_NAME': 'Rule Name',
	// filters modal
	'AUTOMATION.FILTER_ACCOUNT_ID': 'Account ID',
	'AUTOMATION.FILTER_BASE_RATE_PLAN': 'Base Rate Plan',
	'AUTOMATION.FILTER_CUSTOMER_ID': 'Customer ID',
	'AUTOMATION.FILTER_RATE_PLAN_ID': 'Rate Plan ID',
	'AUTOMATION.FILTER_RATE_PLAN_TYPE': 'Rate Plan Type',
	'AUTOMATION.FILTER_CUSTOM_FIELDS': 'Custom Fields',
	'AUTOMATION.FILTER_STATUS': 'Status',
	// overall
	'AUTOMATION.CUSTOM_FIELDS': 'Custom Fields',
	'AUTOMATION.WHITELIST': 'Whitelist',
	'AUTOMATION.SIM_DATA_LIMITS': 'Sim Data Limits',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMES_WITHIN':
		'If any SIM on a prepaid rate plan comes within',
	'AUTOMATION.ANY_SIM_CHANGES_RATE_PLAN_ASSIGNMENTS':
		'Any SIM changes rate plan assignments'
};
