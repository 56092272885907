import React from 'react';
import { FormattedMessage } from 'react-intl';
import hash from 'object-hash';

export const OnboardingTableHeader = [
	{
		title: 'ONBOARDING.COMPANY_NAME',
		name: 'companyFriendlyName'
	},
	{
		title: 'ONBOARDING.BUSINES_LEGAL_NAME',
		name: 'companyLegalName'
	},
	{
		title: 'ONBOARDING.MC_ACCOUNT_NUMBER',
		name: 'externalCompanyId'
	},
	{
		title: 'ONBOARDING.ACCOUNT_CREATED_BY',
		name: 'creatorId'
	},
	{
		title: 'ONBOARDING.PLATFORMS',
		name: 'platforms'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];

export const OnboardingTableHeaderMobile = [
	{
		title: 'ONBOARDING.COMPANY_NAME',
		name: 'companyFriendlyName'
	},
	{
		title: 'ONBOARDING.COMPANY_NAME',
		name: 'launchIoTConsole'
	},
	{
		title: 'ONBOARDING.BUSINES_LEGAL_NAME',
		name: 'companyLegalName'
	},
	{
		title: 'ONBOARDING.ACCOUNT_CREATED_BY',
		name: 'creatorId'
	},
	{
		title: 'ONBOARDING.PLATFORMS',
		name: 'platforms'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];

export const AccountsTableHeader = [
	{
		title: 'ONBOARDING.ACCOUNT_NAME',
		name: 'm2mAccountName'
	},
	{
		title: 'ONBOARDING.PLATFORM',
		name: 'platformName'
	},
	{
		title: 'ONBOARDING.PLATFORM_DESCRIPTION',
		name: 'platformDescription'
	},
	{
		title: 'ONBOARDING.M2M_ACCOUNT_ID',
		name: 'm2mAccountId'
	},
	{
		title: 'ONBOARDING.APNS',
		name: 'apns'
	},
	{
		title: 'ONBOARDING.EDIT_ACTIONS',
		name: 'actions'
	}
];

export const NetworkEntitlementsTableHeader = [
	{
		title: 'ONBOARDING.NETWORK_ENTITLEMENT_PLAN_NAME',
		name: 'name'
	},
	{
		title: 'ONBOARDING.IOT_ACCOUNT_NAME',
		name: 'iotAccountName'
	},
	{
		title: 'ONBOARDING.M2M_ACCOUNT_ID',
		name: 'm2mAccountId'
	},
	{
		title: 'ONBOARDING.NETWORK_ENTITLEMENT_ID',
		name: 'id'
	},
	{
		title: 'ONBOARDING.DEFAULT_PLAN',
		name: 'default'
	}
];

export const RatePlansTableHeader = [
	{
		title: 'ONBOARDING.RATE_PLAN_NAME',
		name: 'name'
	},
	{
		title: 'ONBOARDING.IOT_ACCOUNT_NAME',
		name: 'iotAccountName'
	},
	{
		title: 'ONBOARDING.M2M_ACCOUNT_ID',
		name: 'm2mAccountId'
	},
	{
		title: 'ONBOARDING.SUBSCRIPTION_ITEM_TYPE',
		name: 'subscriptionItemType'
	},
	{
		title: 'ONBOARDING.PRICE_PLAN_ID',
		name: 'pricePlanId'
	},
	{
		title: 'ONBOARDING.DEFAULT',
		name: 'defaultPlan'
	}
];

export const onboardingSignature = hash(JSON.stringify(OnboardingTableHeader));

export const onboardingSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage
					id="ONBOARDING.COMPANY_NAME"
					defaultMessage="Company Name"
				/>
			</div>
		),
		value: 'companyFriendlyName'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage
					id="ONBOARDING.BUSINES_LEGAL_NAME"
					defaultMessage="Business Legal Name"
				/>
			</div>
		),
		value: 'companyLegalName'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage
					id="ONBOARDING.ACCOUNT_CREATED_BY"
					defaultMessage="Account Created By"
				/>
			</div>
		),
		value: 'creatorId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage
					id="ONBOARDING.MC_ACCOUNT_NUMBER"
					defaultMessage="IOT-C Account Number"
				/>
			</div>
		),
		value: 'externalCompanyId'
	}
];

export const simOrderingSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage id="ONBOARDING.SIM_SKU" defaultMessage="SIM SKU" />
			</div>
		),
		value: 'name'
	}
];

export const AccountEndpointsTableHeader = [
	{
		title: 'ONBOARDING.ENDPOINT_ID',
		name: 'simIdentifier'
	},
	{
		title: 'ONBOARDING.NETWORK_OPERATOR',
		name: 'networkOperator'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	},
	{
		title: 'ONBOARDING.COUNTRY',
		name: 'country'
	},
	{
		title: 'ONBOARDING.M2M_PLATFORM',
		name: 'm2mPlatform'
	}
];

export const SimOrderingTableHeader = [
	{
		title: 'ONBORDING.SIM_SKU',
		name: 'name'
	},
	{
		title: 'ONBORDING.UNIT_PRICE',
		name: 'unitPrice'
	},
	{
		title: 'ONBORDING.DATE_SUBMITED',
		name: 'createdAt'
	},
	{
		title: 'ONBORDING.EDIT_DELETE_SKU',
		name: 'editDeleteSKU'
	}
];

export const SimOrderingWingTableHeader = [
	{
		title: 'ONBORDING.SIM_SKU',
		name: 'name'
	},
	{
		title: 'ONBORDING.UNIT_PRICE',
		name: 'unitPrice'
	},
	{
		title: 'ONBORDING.DATE_SUBMITED',
		name: 'createdAt'
	}
];

export const WING = 'WING';

export const notificationMessages = {
	success: 'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS',
	error: 'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR'
};

export const defaultTab = {
	companyoverview: 'companyoverview',
	accounts: 'accounts',
	customFieldLabels: 'customfieldlabels',
	features: 'features'
};

export const UserGroupsTableHeader = [
	{
		title: 'ONBOARDING.GROUP_NAME',
		name: 'name'
	},
	{
		title: 'ONBOARDING.USER_COUNT',
		name: 'totalUserCount'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];

export const MobileUserGroupsTableHeader = [
	{
		title: 'ONBOARDING.GROUP_NAME',
		name: 'name'
	},
	{
		title: 'ONBOARDING.USER_COUNT',
		name: 'totalUserCount'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	}
];

export const UsersTableHeader = [
	{
		title: 'ONBOARDING.FIRST_NAME',
		name: 'firstName'
	},
	{
		title: 'ONBOARDING.LAST_NAME',
		name: 'lastName'
	},
	{
		title: 'ONBOARDING.EMAIL',
		name: 'email'
	},
	{
		title: 'ONBOARDING.USER_GROUP',
		name: 'userGroup'
	},
	{
		title: 'ONBOARDING.ACCOUNT_ACCESS',
		name: 'accountAccess'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];

export const SystemUsersTableHeader = [
	{
		title: 'ONBOARDING.FIRST_NAME',
		name: 'firstName'
	},
	{
		title: 'ONBOARDING.LAST_NAME',
		name: 'lastName'
	},
	{
		title: 'ONBOARDING.EMAIL',
		name: 'email'
	},
	{
		title: 'ONBOARDING.USER_GROUP',
		name: 'userGroup'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];

export const UsersMobileHeader = [
	{
		title: 'ONBOARDING.FIRST_NAME',
		name: 'firstName'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	}
];

export const RegistrationsTableHeader = [
	{
		title: 'ONBOARDING.FIRST_NAME',
		name: 'firstName'
	},
	{
		title: 'ONBOARDING.LAST_NAME',
		name: 'lastName'
	},
	{
		title: 'ONBOARDING.EMAIL',
		name: 'email'
	},
	{
		title: 'ONBOARDING.USER_GROUP',
		name: 'userGroup'
	},
	{
		title: 'ONBOARDING.ACCOUNT_ACCESS',
		name: 'accountAccess'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];
export const RegistrationsMobileTableHeader = [
	{
		title: 'ONBOARDING.FIRST_NAME',
		name: 'firstName'
	},
	{
		title: 'ONBOARDING.LAST_NAME',
		name: 'lastName'
	},
	{
		title: 'ONBOARDING.EMAIL',
		name: 'email'
	},
	{
		title: 'ONBOARDING.USER_GROUP',
		name: 'userGroup'
	},
	{
		title: 'ONBOARDING.ACCOUNT_ACCESS',
		name: 'accountAccess'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	}
];

export const SystemUserPendingInvitationsHeader = [
	{
		title: 'ONBOARDING.FIRST_NAME',
		name: 'firstName'
	},
	{
		title: 'ONBOARDING.LAST_NAME',
		name: 'lastName'
	},
	{
		title: 'ONBOARDING.EMAIL',
		name: 'email'
	},
	{
		title: 'ONBOARDING.USER_GROUP',
		name: 'userGroup'
	},
	{
		title: 'ONBOARDING.ACCOUNT_ACCESS',
		name: 'accountAccess'
	},
	{
		title: 'ONBOARDING.STATUS',
		name: 'status'
	},
	{
		title: 'ONBOARDING.ACTIONS',
		name: 'actions'
	}
];

export const userSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage
					id="ONBOARDING.FIRST_NAME"
					defaultMessage="First Name"
				/>
			</div>
		),
		value: 'firstName'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage
					id="ONBOARDING.LAST_NAME"
					defaultMessage="Last Name"
				/>
			</div>
		),
		value: 'lastName'
	},
	{
		label: (
			<div>
				<FormattedMessage id="ONBOARDING.BY" defaultMessage="By" />
				<FormattedMessage id="ONBOARDING.EMAIL" defaultMessage="Email" />
			</div>
		),
		value: 'email'
	}
];

export const userGroupsSearchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="BY" defaultMessage="By" />
				<FormattedMessage id="GROUP_NAME" defaultMessage="Group Name" />
			</div>
		),
		value: 'name'
	}
];

export const UserPermissionsTableHeader = [
	{
		title: 'ONBOARDING.ENTITLEMENT_NAME',
		name: 'entitlementName'
	},
	{
		title: 'ONBOARDING.NO_ACCESS',
		name: 'noAccess'
	},
	{
		title: 'ONBOARDING.READ_ONLY',
		name: 'readOnly'
	},
	{
		title: 'ONBOARDING.READ_WRITE',
		name: 'readWrite'
	}
];

export const defaultUserManagementTab = {
	userManagement: 'userManagement',
	userGroups: 'userGroups',
	pendingInvitations: 'pendingInvitations'
};

export const defaultSystemManagmentTab = {
	systemUsersManagement: 'systemUsersManagement',
	systemUserGroups: 'systemUserGroups',
	systemUserPendingInvitations: 'systemUserPendingInvitations'
};

export const defaultUserGroupsTab = {
	permissions: 'permissions',
	assignedAccounts: 'assignedAccounts'
};

export const AssignedAccountsTableHeader = [
	{
		title: 'ONBOARDING.ASSIGNED_ACCOUNTS',
		name: 'm2mAccountName'
	},
	{
		title: 'ONBOARDING.ALLOW_ACCESS',
		name: 'actions'
	}
];

export const companyReportCategories = [
	'Daily',
	'Monthly',
	'Reference',
	'DailyCombined',
	'Weekly',
];

export const companyBillingCategories = [
	'billing',
];

export const companySupportType = {
	PRIORITY: 'IoT Priority Care',
	FULL: 'Full TSM',
  PARTIAL: 'Partial TSM',
  GATEWAY: 'IoT Gateway',
	STANDARD: 'Standard (Default)'
};

export const billingPlatform = [
	{ name: 'UB' },
	{ name: 'EOD' },
	{ name: 'mPower' }
];
