import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import DesktopComponent from './Desktop';
import MobileComponent from './Mobile';

import {
	getAutomationRulesPending,
	getAutomationRulesFail,
	getAutomationRulesSuccess,
	getAutomationRulesTotalCount,
	removeAutomationRuleSuccess,
	removeAutomationRulePending,
	requestForDeletionSuccess,
  requestForDeletionRequest
} from '../../redux/selectors';

import {
	getAutomationRules,
	removeAutomationRule,
	requestForDeletion,
} from '../../redux/actions';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';

import {
	setDataOffset,
	setSelectedPage,
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
  getSelectedPage,
} from '../../../Shared/redux/pagination/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	rulesRequest: getAutomationRulesPending(state),
	rulesFail: getAutomationRulesFail(state),
	rules: getAutomationRulesSuccess(state),
	deleteRuleRequest: removeAutomationRulePending(state),
	deleteRuleSuccess: removeAutomationRuleSuccess(state),
	totalCount: getAutomationRulesTotalCount(state),
	filterData: filterDataSuccess(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
	user: getUser(state),
	requestForDeletionSuccess: requestForDeletionSuccess(state),
  requestForDeletionRequest: requestForDeletionRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	getRules: (params) => dispatch(getAutomationRules(params)),
	deleteRule: (id) => dispatch(removeAutomationRule(id)),
	goToDetail: (id) => dispatch(localizedRoute(`/automation/rule/${id}`)),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	openFilterModal: () => dispatch(openFilterModal()),
	requestForDeletion: (id) => dispatch(requestForDeletion(id)),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopComponent);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileComponent);
