/* eslint-disable */
import StackTrace from 'stacktrace-js';
import store from 'redux/store';

const getReduxMessage = () => {
	let reduxMessage = '';
	if (
		store &&
		store.getState() &&
		store.getState().shared &&
		store.getState().shared.getStatusCode &&
		store.getState().shared.getStatusCode.response &&
		store.getState().shared.getStatusCode.response.request
	) {
		if (store.getState().shared.getStatusCode.response.request.status) {
			reduxMessage += `${
				store.getState().shared.getStatusCode.response.request.status
			} `;
		}
		if (store.getState().shared.getStatusCode.response.request.responseURL) {
			reduxMessage += `${
				store.getState().shared.getStatusCode.response.request.responseURL
			}`;
		}
	}
	if (
		store &&
		store.getState() &&
		store.getState().shared &&
		store.getState().shared.getStatusCode &&
		store.getState().shared.getStatusCode.response &&
		store.getState().shared.getStatusCode.response.config
	) {
		if (
			store.getState().shared.getStatusCode.response.config &&
			store.getState().shared.getStatusCode.response.config.headers &&
			store.getState().shared.getStatusCode.response.config.headers[
				'client-request-id'
			]
		) {
			reduxMessage += ` ${
				store.getState().shared.getStatusCode.response.config.headers[
					'client-request-id'
				]
			}`;
		}
	}

	return reduxMessage ? reduxMessage : undefined;
};

export const sendErrorToServer = async (error = {}) => {
	const response =
		StackTrace && error.type !== 'custom'
			? await StackTrace.fromError(error)
			: undefined;
	//this function is defined in index.html
	sendErrorToCloudWatch({
		type: 'front',
		url: window.location.href,
		error: error.message,
		stackTrace: response,
		redux: store ? store.getState() : {},
		reduxMessage: getReduxMessage()
	});
};
