import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import {
	ADD_ACCOUNT,
  GET_ACCOUNT,
  EDIT_ACCOUNT,
	GET_ACCOUNTS,
	GET_PLATFORMS,
	GET_PARENT_ACCOUNT,
	ADD_MY_SUB_ACCOUNT,
	ACTIVATE_ACCOUNT,
	REQUEST_ACCESS,
	GRANT_ACCESS,
	GET_PLATFORM_TYPES,
	VALIDATE_ACCOUNT
} from './constants';

import {
	addAccount as addAccountService,
  getAccount as getAccountService,
  editAccount as editAccountService,
	getAccounts as getAccountsService,
	getPlatforms as getPlatformsService,
	getParentAccount as getParentAccountService,
	addSubAccount as addSubAccountService,
	activateAccount as activateAccountService,
	requestAccess as requestAccessService,
	grantAccess as grantAccessService,
	getPlatformTypes as getPlatformTypesService,
	validateAccount as validateAccountService
} from '../../services/AccountsService';

/* ******************** ADD ACCOUNT ******************** */
export const addAccount = (data) =>
	globalAction(ADD_ACCOUNT, addAccountService, data, {
		notify: 'add-account',
		init: true,
		redirect: `/companies/${data.companyId}`
	});

/* ******************** GET ACCOUNT ******************** */
export const getAccount = (params) =>
	globalAction(GET_ACCOUNT, getAccountService, params);

export const getAccountInit = () => actionInit(GET_ACCOUNT);

export const editAccount = (params) =>
	globalAction(EDIT_ACCOUNT, editAccountService, params, {
		notifyFail: 'edit-account'
	});

/* ******************** GET ACCOUNTS ******************** */
export const getAccounts = (params) =>
	globalAction(GET_ACCOUNTS, getAccountsService, params);

/* ******************** GET PLATFORMS ******************** */
export const getPlatforms = (params) =>
	globalAction(GET_PLATFORMS, getPlatformsService, params);

/* ******************** GET PARENT ACCOUNT ******************** */
export const getParentAccount = (params) =>
	globalAction(GET_PARENT_ACCOUNT, getParentAccountService, params);

export const getParentAccountInit = () => actionInit(GET_PARENT_ACCOUNT);

/* ******************** ADD SUB ACCOUNT ******************** */
export const addSubAccount = (params) =>
	globalAction(ADD_MY_SUB_ACCOUNT, addSubAccountService, params, {
		notify: 'add-sub-account',
		init: true,
		redirect: params.isMyCompany
			? '/my-company/accounts'
			: `/companies/${params.companyId}`
	});

/* ******************** ACCOUNT ACTIVATION ******************** */
export const activateAccount = (params) =>
	globalAction(ACTIVATE_ACCOUNT, activateAccountService, params, {
		notify: 'activate-account'
	});

/* ******************** REQUEST ACCESS ******************** */
export const requestAccess = (params) =>
	globalAction(REQUEST_ACCESS, requestAccessService, params, {
		notify: 'request-access'
	});
/* ******************** GRANT ACCESS ******************** */
export const grantAccess = (params) =>
	globalAction(GRANT_ACCESS, grantAccessService, params, {
		notify: 'grant-access'
	});

/* ******************** GET PLATFORM TYPES ******************** */
export const getPlatformTypes = () =>
	globalAction(GET_PLATFORM_TYPES, getPlatformTypesService);

/* ******************** GET ACCOUNT ******************** */
export const validateAccount = (params) =>
	globalAction(VALIDATE_ACCOUNT, validateAccountService, params);

export const validateAccountInit = () => actionInit(VALIDATE_ACCOUNT);
