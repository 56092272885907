import React, { PureComponent } from 'react';
import { func, bool, object, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import RectangleButton from '../../../../lib/DigitalComponents/RectangleButton';
import Loading from '../../../../lib/DigitalComponents/Loader';

import PageTitle from '../../../Shared/views/PageTitleView';
import AccountInfoOverview from '../AccountInfoOverview';

import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';

import styles from './EditAccount.scss';

const WrapperComponent = UserAllowedToAccess([
	'system.companies_rw',
	'mnc.reports.configuration_rw',
	'mnc.reports.configuration_ro'
]);

const CreateUsersComponentPermissions = UserAllowedToAccess([
	'mnc.accessmanagement.users_rw',
	'mnc.accessmanagement.users_ro'
]);

class EditAccount extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			companyId: null
		};
	}

	componentDidMount() {
		const {
			match: {
				params: { accountId }
			}
		} = this.props;
		if (accountId) {
			this.getAccount(accountId);
		}
	}

	componentDidUpdate(prevProps) {
		const { getCompanyTokenSuccess, user } = this.props;

		const { companyId } = this.state;

		if (
			prevProps.getCompanyTokenSuccess !== getCompanyTokenSuccess &&
			getCompanyTokenSuccess
		) {
			sessionStorage.setItem('company-token', getCompanyTokenSuccess.token);
			const sessionLanguage = sessionStorage.getItem('language');
			sessionStorage.setItem('company', companyId);
			window.location.href = `/${
				sessionLanguage || user.language || 'en-us'
			}/user-management`;
		}
	}

	componentWillUnmount() {
		const { getAccountInit } = this.props;
		getAccountInit();
	}

	launchMc = () => {
		const { getCompanyToken, account } = this.props;

		this.setState(
			{
				companyId:
					account && account.company && account.company.externalCompanyId
			},
			() => {
				getCompanyToken({ companyId: account && account.companyId });
			}
		);
	};

	getAccount = (id) => {
		const { getAccount } = this.props;
		getAccount({
			id,
			additionalParams: {
				include: [
					'Company',
					'PlatformType',
					'PlatformMobileOperator',
					'PlatformSatelliteOperator',
					'PlatformType.PlatformTypeCategory'
				]
			}
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId }
			}
		} = this.props;
		let url = '/my-company/accounts';
		if (companyId) {
			url = `/companies/${companyId}`;
		}
		pushBack(url);
	};

	goToNetworkEntitlements = (platformType, m2mAccountId) => {
		const {
			goToNetworkEntitlements,
			match: {
				params: { companyId, accountId }
			}
		} = this.props;
		let url = '';

		if (companyId) {
			url = `/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/network-entitlements`;
		} else {
			url = `/my-company/${accountId}/${m2mAccountId}/${platformType}/network-entitlements`;
		}
		goToNetworkEntitlements(url);
	};

	goToRatePlans = (platformType, m2mAccountId) => {
		const {
			goToRatePlans,
			match: {
				params: { companyId, accountId }
			}
		} = this.props;
		let url = '';

		if (companyId) {
			url = `/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/rate-plans`;
		} else {
			url = `/my-company/${accountId}/${m2mAccountId}/${platformType}/rate-plans`;
		}
		goToRatePlans(url);
	};

	goToSimOrdering = (platformType, m2mAccountId) => {
		const {
			goToSimOrdering,
			match: {
				params: { companyId, accountId }
			}
		} = this.props;
		let url = '';

		if (companyId) {
			url = `/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/sim-ordering`;
		} else {
			url = `/my-company/${accountId}/${m2mAccountId}/${platformType}/sim-ordering`;
		}
		goToSimOrdering(url);
	};

	goToReports = (accountId) => {
		const {
			goToReports,
			match: {
				params: { companyId }
			}
		} = this.props;
		let url = '';

		if (companyId) {
			url = `/companies/${companyId}/accounts/${accountId}/reports`;
		} else {
			url = `/my-company/${accountId}/reports`;
		}
		goToReports(url);
	};

	goToBillings = (accountId) => {
		const {
			goToBillings,
			match: {
				params: { companyId }
			}
		} = this.props;
		const url = companyId
			? `/companies/${companyId}/accounts/${accountId}/billings`
			: `/my-company/${accountId}/billings`;
		goToBillings(url);
	};

	goToEndpoints = () => {
		const {
			goToEndpoints,
			match: {
				params: { companyId, accountId }
			}
		} = this.props;
		let url = '';

		if (companyId) {
			url = `/companies/${companyId}/accounts/${accountId}/endpoints`;
		} else {
			url = `/my-company/${accountId}/endpoints`;
		}
		goToEndpoints(url);
	};

	goToUserManagement = () => {
		const { account } = this.props;
		sessionStorage.setItem(
			'company',
			account && account.company && account.company.externalCompanyId
		);
		window.location.href = '/user-management';
	};

	checkIfWing = () => {
		const { account } = this.props;
		if (account.platformType.code.toLowerCase() === 'wing') return true;
	};

	checkIfSpeedcast = () => {
		const { account } = this.props;
		if (account.platformType.code.toLowerCase() === 'gbcm') return true;
	};

	checkIfGDSP = () => {
		const { account } = this.props;
		if (account.platformType.code.toLowerCase() === 'gdsp') return true;
	};

	getButtonLabel = () => {
		const {
			user,
			match: {
				params: { companyId }
			}
		} = this.props;

		return isUserAllowedToAccess(['system.companies_rw'], user) && companyId ? (
			<FormattedMessage id="ONBOARDING.EDIT" defaultMessage="Edit" />
		) : (
			<FormattedMessage id="ONBOARDING.VIEW" defaultMessage="View" />
		);
	};

	render() {
		const {
			messages,
			account,
			getAccountRequest,
			user,
			getCompanyTokenRequest,
			getCompanyTokenSuccess,
			match: {
				params: { companyId }
			}
		} = this.props;

		return (
			<div data-spec="account-details">
				<PageTitle
					title={
						account && account.company && account.company.companyFriendlyName
					}
					pushBack={this.pushBack}
					messages={messages}
				/>
				{(getAccountRequest ||
					getCompanyTokenRequest ||
					getCompanyTokenSuccess) && <Loading />}
				{!getAccountRequest && account && (
					<div className={styles.details_wrapper}>
						<AccountInfoOverview
							account={account}
							title={account.company && account.company.companyFriendlyName}
						/>
						<div>
							<h5 className={styles.section_headline}>
								<FormattedMessage
									id="ONBOARDING.ACCOUNT_CONFIGURATION"
									defaultMessage="Account Configuration"
								/>
							</h5>
							<div className={styles.form_subheadline}>
								<FormattedMessage
									id="ONBOARDING.ACCOUNT_CONFIGURATION_SUB"
									defaultMessage="Select applicable services to be enabled in this account. If you do not see desired service, one can be added at the parent account level, subject to approval process. "
								/>
							</div>
							{!this.checkIfSpeedcast() && !this.checkIfGDSP() && (
								<div className={styles.rectangle_buttons_wrapper}>
									<RectangleButton
										label={
											this.checkIfWing() ? (
												<FormattedMessage
													id="ONBOARDING.VIEW"
													defaultMessage="View"
												/>
											) : (
												this.getButtonLabel()
											)
										}
										title={
											<FormattedMessage
												id="ONBOARDING.NETWORK_ENTITLEMENTS"
												defaultMessage="Network Entitlements"
											/>
										}
										subtitle={
											this.checkIfWing() && !companyId ? (
												<FormattedMessage
													id="ONBOARDING.VIEW_NETWORK_ENTITLEMENTS"
													defaultMessage="View Network Entitlements"
												/>
											) : (
												<FormattedMessage
													id="ONBOARDING.ADD_NETWORK_ENTITLEMENTS"
													defaultMessage="Add Network Entitlements"
												/>
											)
										}
										onClick={() =>
											this.goToNetworkEntitlements(
												account.platformType.code.toLowerCase(),
												account.m2mAccountId
											)}
										customStyle={{ width: '260px' }}
									/>
									<RectangleButton
										label={
											this.checkIfWing() ? (
												<FormattedMessage
													id="ONBOARDING.VIEW"
													defaultMessage="View"
												/>
											) : (
												this.getButtonLabel()
											)
										}
										title={
											<FormattedMessage
												id="ONBOARDING.RATE_PLANS"
												defaultMessage="Rate Plans"
											/>
										}
										subtitle={
											this.checkIfWing() && !companyId ? (
												<FormattedMessage
													id="ONBOARDING.VIEW_RATE_PLANS"
													defaultMessage="View Rate Plans"
												/>
											) : (
												<FormattedMessage
													id="ONBOARDING.ADD_RATE_PLANS"
													defaultMessage="Add Rate Plans"
												/>
											)
										}
										onClick={() =>
											this.goToRatePlans(
												account.platformType.code.toLowerCase(),
												account.m2mAccountId
											)}
										customStyle={{ width: '260px' }}
									/>
								</div>
							)}

							<div className={styles.rectangle_buttons_wrapper}>
								<WrapperComponent>
									<RectangleButton
										label={
											isUserAllowedToAccess(
												['system.companies_rw', 'mnc.reports.configuration_rw'],
												user
											) ? (
												<FormattedMessage
													id="ONBOARDING.EDIT"
													defaultMessage="Edit"
												/>
											) : (
												<FormattedMessage
													id="ONBOARDING.VIEW"
													defaultMessage="View"
												/>
											)
										}
										title={
											<FormattedMessage
												id="ONBOARDING.REPORTS"
												defaultMessage="Reports"
											/>
										}
										subtitle={
											<FormattedMessage
												id="ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED"
												defaultMessage="View or edit reports to be displayed"
											/>
										}
										onClick={() => this.goToReports(account.id)}
										customStyle={{ width: '260px' }}
									/>
								</WrapperComponent>
								{!this.checkIfSpeedcast() && (
									<RectangleButton
										label={this.getButtonLabel()}
										title={
											<FormattedMessage
												id="ONBOARDING.SIM_ORDERING"
												defaultMessage="SIM Ordering"
											/>
										}
										subtitle={
											isUserAllowedToAccess(
												[
													'system.simorderstatus_rw',
													'system.simorderstatus_ro'
												],
												user
											) ? (
												<FormattedMessage
													id="ONBOARDING.ORDER_MOVE_SIMS"
													defaultMessage="Order or move SIMs"
												/>
											) : (
												<FormattedMessage
													id="ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST"
													defaultMessage="Order SIMs, select or upload SIM list"
												/>
											)
										}
										onClick={() =>
											this.goToSimOrdering(
												account.platformType.code.toLowerCase(),
												account.m2mAccountId
											)}
										customStyle={{ width: '260px' }}
									/>
								)}
							</div>
							<div className={styles.rectangle_buttons_wrapper}>
								<RectangleButton
									onClick={() => this.goToBillings(account.id)}
									label={this.getButtonLabel()}
									title={
										<FormattedMessage
											id="ONBOARDING.BILLING"
											defaultMessage="Billing"
										/>
									}
									subtitle={
										<FormattedMessage
											id="ONBOARDING.VIEW_OR_EDIT_BILLING"
											defaultMessage="View or edit billing information"
										/>
									}
									customStyle={{ width: '260px' }}
								/>
							</div>
							{this.checkIfWing() && (
								<div className={styles.rectangle_buttons_wrapper}>
									<RectangleButton
										label={this.getButtonLabel()}
										title={
											<FormattedMessage
												id="ONBOARDING.ENDPOINTS"
												defaultMessage="Endpoints"
											/>
										}
										subtitle={
											<FormattedMessage
												id="ONBOARDING.ASSIGN_TO_SUB_ACCOUNT"
												defaultMessage="Assign to Sub Account"
											/>
										}
										onClick={this.goToEndpoints}
										customStyle={{ width: '260px' }}
									/>
								</div>
							)}
							<CreateUsersComponentPermissions>
								{isUserAllowedToAccess(['system.companies_rw'], user) &&
									!this.checkIfSpeedcast() && (
										<>
											<h5 className={styles.section_headline}>
												<FormattedMessage
													id="ONBOARDING.USERS"
													defaultMessage="Users"
												/>
											</h5>
											<div className={styles.form_subheadline}>
												<FormattedMessage
													id="ONBOARDING.USERS_SUB"
													defaultMessage="Clicking button below will send you to your company identity screen where you can invite new users to join this account, or add existing users to this company."
												/>
											</div>
											<div className={styles.rectangle_buttons_wrapper}>
												<RectangleButton
													label={
														<FormattedMessage
															id="ONBOARDING.CREATE_USERS"
															defaultMessage="Create Users"
														/>
													}
													title={
														<FormattedMessage
															id="ONBOARDING.USER_ACCESS"
															defaultMessage="User Access"
														/>
													}
													subtitle={
														<FormattedMessage
															id="ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP"
															defaultMessage="Create/add user"
														/>
													}
													onClick={this.launchMc}
													customStyle={{ width: '260px' }}
												/>
											</div>
										</>
									)}
							</CreateUsersComponentPermissions>
						</div>
					</div>
				)}
			</div>
		);
	}
}

EditAccount.propTypes = {
	match: object,
	getAccount: func,
	getAccountInit: func,
	pushBack: func,
	goToNetworkEntitlements: func,
	goToSimOrdering: func,
	goToRatePlans: func,
	goToReports: func,
	accountId: string,
	messages: object,
	getAccountRequest: bool,
	account: object,
	goToEndpoints: func,
	user: object,
	getCompanyToken: func,
	getCompanyTokenRequest: bool,
	getCompanyTokenSuccess: object,
	goToBillings: func
};

export default EditAccount;
