import React from 'react';
import ImageSelector from '../../../utils/imageSelector';
import CurrentContext from '../../../utils/currentContext';

import Node from '../components/Node';
import C from '../components/C';
import CSharp from '../components/CSharp';
import Curl from '../components/Curl';
import GoLang from '../components/GoLang';
import Java from '../components/Java';
import Php from '../components/Php';
import Python from '../components/Python';
import Ruby from '../components/Ruby';

const nodeIcon = ImageSelector(CurrentContext.theme, 'node.png');
const cIcon = ImageSelector(CurrentContext.theme, 'c.png');
const cSharpIcon = ImageSelector(CurrentContext.theme, 'c-sharp.png');
const curlIcon = ImageSelector(CurrentContext.theme, 'curl.png');
const goLangIcon = ImageSelector(CurrentContext.theme, 'go-lang.png');
const javaIcon = ImageSelector(CurrentContext.theme, 'java.png');
const phpIcon = ImageSelector(CurrentContext.theme, 'php.png');
const pythonIcon = ImageSelector(CurrentContext.theme, 'python.png');
const rubyIcon = ImageSelector(CurrentContext.theme, 'ruby.png');

export const SwaggerTabsIds = {
	GettingStarted: 'GettingStarted',
	ApiSandbox: 'ApiSandbox'
};

export const gettingStartedTabsIds = {
	Node: 'node',
	C: 'c',
	CSharp: 'cSharp',
	Curl: 'curl',
	GoLang: 'goLang',
	Java: 'java',
	Php: 'php',
	Python: 'python',
	Ruby: 'ruby'
};

export const getGettingStartedTabs = (className) => {
	const tabs = [
		{
			id: gettingStartedTabsIds.Node,
			label: <img src={nodeIcon} className={className} alt="nodeIcon" />
		},
		{
			id: gettingStartedTabsIds.C,
			label: <img src={cIcon} className={className} alt="cIcon" />
		},
		{
			id: gettingStartedTabsIds.CSharp,
			label: <img src={cSharpIcon} className={className} alt="cSharp" />
		},
		{
			id: gettingStartedTabsIds.Curl,
			label: <img src={curlIcon} className={className} alt="curl" />
		},
		{
			id: gettingStartedTabsIds.GoLang,
			label: <img src={goLangIcon} className={className} alt="goLang" />
		},
		{
			id: gettingStartedTabsIds.Java,
			label: <img src={javaIcon} className={className} alt="java" />
		},
		{
			id: gettingStartedTabsIds.Php,
			label: <img src={phpIcon} className={className} alt="php" />
		},
		{
			id: gettingStartedTabsIds.Python,
			label: <img src={pythonIcon} className={className} alt="python" />
		},
		{
			id: gettingStartedTabsIds.Ruby,
			label: <img src={rubyIcon} className={className} alt="ruby" />
		}
	];
	return tabs;
};

export const getGettingStartedComponents = () => {
	const tabComponents = {
		[gettingStartedTabsIds.Node]: <Node key={gettingStartedTabsIds.Node} />,
		[gettingStartedTabsIds.C]: <C key={gettingStartedTabsIds.C} />,
		[gettingStartedTabsIds.CSharp]: (
			<CSharp key={gettingStartedTabsIds.CSharp} />
		),
		[gettingStartedTabsIds.Curl]: <Curl key={gettingStartedTabsIds.Curl} />,
		[gettingStartedTabsIds.GoLang]: (
			<GoLang key={gettingStartedTabsIds.GoLang} />
		),
		[gettingStartedTabsIds.Java]: <Java key={gettingStartedTabsIds.Java} />,
		[gettingStartedTabsIds.Php]: <Php key={gettingStartedTabsIds.Php} />,
		[gettingStartedTabsIds.Python]: (
			<Python key={gettingStartedTabsIds.Python} />
		),
		[gettingStartedTabsIds.Ruby]: <Ruby key={gettingStartedTabsIds.Ruby} />
	};

	return tabComponents;
};
