import { Status } from '../../../utils/constants';

/* ******************** GET FAILOVER LINKS ******************** */
export const getFailoverLinksRequest = (state) =>
	state.failover.getFailoverLinks.status === Status.PENDING;
export const getFailoverLinksFail = (state) =>
	state.failover.getFailoverLinks.status === Status.FAIL;
export const getFailoverLinksSuccess = (state) =>
	state.failover.getFailoverLinks.data;

/* ******************** CHANGE ENVIRONMENT ******************** */
export const changeEnvironmentRequest = (state) =>
	state.failover.changeEnvironment.status === Status.PENDING;
export const changeEnvironmentFail = (state) =>
	state.failover.changeEnvironment.status === Status.FAIL;
export const changeEnvironmentSuccess = (state) =>
	state.failover.changeEnvironment.status === Status.DONE;
