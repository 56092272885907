import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { push } from 'connected-react-router'
import { requestUserData as requestUserDataAction } from '../../redux/user/getUserData/actions';
import {
	getHasFetched,
	getIsFetching,
	getUser
} from '../../redux/user/getUserData/selectors';
import Loader from '../../lib/DigitalComponents/Loader';

const loaderStyle = {
	display: 'block',
	margin: '280px auto'
};

function mapStateToProps(state) {
	return {
		user: getUser(state),
		isFetchingUserData: getIsFetching(state),
		hasFetchedUserData: getHasFetched(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		goToLogin: () => {
			// dispatch(push("/login"));
		},
		requestUserData() {
			dispatch(requestUserDataAction());
		}
	};
}

const AuthWrapper = (ComponentToWrap) => {
	class WrappedComponent extends Component {
		componentDidMount() {
			const { user, requestUserData } = this.props;
			if (!user) {
				requestUserData();
			}
		}

		componentDidUpdate(prevProps) {
			const { location, user, requestUserData } = this.props;
			if (prevProps.location.pathname !== location.pathname) {
				if (!user) {
					requestUserData();
				}
				window.scrollTo(0, 0);
				// window.refreshTGuardSession();
			} else {
				this.checkUser();
			}
		}

		checkUser() {
			const { hasFetchedUserData, user, goToLogin } = this.props;
			if (hasFetchedUserData && !user) {
				goToLogin();
			}
		}

		render() {
			const { isFetchingUserData, user } = this.props;
			if (isFetchingUserData || !user) {
				return <Loader data-spec="loader" style={loaderStyle} />;
			}

			return <ComponentToWrap data-spec="component-to-wrap" {...this.props} />;
		}
	}

	const { bool, func, string, shape } = PropTypes;

	WrappedComponent.propTypes = {
		user: shape(),
		hasFetchedUserData: bool,
		isFetchingUserData: bool,
		goToLogin: func,
		requestUserData: func,
		location: shape({
			pathname: string
		}),
	};

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(WrappedComponent);
};

export default AuthWrapper;
