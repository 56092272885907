import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, orderBy } from 'lodash';
import ActionBar from '../../../Shared/views/ActionBarView';
import FilterModal from '../../../Shared/components/FilterModal';
import List from '../../views/ListView';

import { EndpointsHeaderMobile } from '../../utils/DetailsHeaders';

import { endpointsSearchMenu } from '../../utils/SearchMenus';

import {
	onOrderClick,
	filterHelpers,
	newOnPageChange,
} from '../../../../utils/tableHelpers';

import EditTableModal from '../EditTableModal';

import {
	endpointsSignature,
	EndpointsTableTabs,
} from '../../utils/tableSignatures';

import { UserSettingsTableName } from '../../utils/constants';

import { getEndpointsFilters as getEndpointsFiltersService } from '../../services/EndpointsService';

import { downloadHelper } from '../../redux/invoiceAttachment/actions';

const headerConstants = EndpointsTableTabs[0].value.data;

class EndpointsTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orderColumn: {},
			isEditTableModalOpen: false,
			settings: this.getInvoicesUserSettings()
				? JSON.parse(this.getInvoicesUserSettings().value)
				: {},
			isUpdating: false,
			updatedSetings: {},
		};
	}

	componentDidMount() {
		const { createUserSettings, createUserSettingsPending } = this.props;

		this.getEndpoints();

		if (!this.getInvoicesUserSettings() && !createUserSettingsPending) {
			createUserSettings({
				refTypeId: 9,
				name: UserSettingsTableName.ENDPOINTS,
				value: JSON.stringify({
					signature: endpointsSignature,
					header: headerConstants,
				}),
			});
			this.setState({
				settings: {
					signature: endpointsSignature,
					header: headerConstants,
				},
			});
		}
	}

	componentDidUpdate(prevProps) {
		const {
			updateUserSettingsSuccess,
      exportInvoiceDetailsSuccess,
      showReportFailNotification
		} = this.props;
		const { settings, updatedSetings } = this.state;

		if (
			updateUserSettingsSuccess != prevProps.updateUserSettingsSuccess &&
			updateUserSettingsSuccess &&
			!isEmpty(updatedSetings)
		) {
			this.updateEndpointsData();
		}
		if (
			this.getInvoicesUserSettings() &&
			settings.signature !== endpointsSignature
		) {
			this.updateInvoiceUserSettings();
    }
    if (
			exportInvoiceDetailsSuccess != prevProps.exportInvoiceDetailsSuccess &&
			exportInvoiceDetailsSuccess
		) {
			if (exportInvoiceDetailsSuccess.downloadUrl === '') {
				showReportFailNotification();
			} else {
				downloadHelper(exportInvoiceDetailsSuccess.downloadUrl);
			}
		}
	}

	updateEndpointsData = () => {
		const { updatedSetings, isUpdating } = this.state;
		this.setState(
			{
				settings: updatedSetings,
				isUpdating,
				updatedSetings: {},
			},
			() => {
				this.getEndpoints();
			}
		);
	};

	updateInvoiceUserSettings = () => {
		const { patchUserSetting } = this.props;
		const temp = {
			value: JSON.stringify({
				signature: endpointsSignature,
				header: headerConstants,
			}),
		};
		const settings = this.getInvoicesUserSettings();
		patchUserSetting(temp, settings.id);
		this.setState({ settings: JSON.parse(temp.value) });
	};

	getInvoicesUserSettings = () => {
		const { userSettings } = this.props;
		return userSettings.find((x) => x.name === UserSettingsTableName.ENDPOINTS);
	};

	getFilters = () => {
		const { getEndpointsFilters, id } = this.props;
		const params = {
			additionalParams: {
				columnsToSelect:
					'currency,customer,subscriber_status,monthly_rate_plan,standard_rate_plan,prepaid_terms_charged',
			},
		};
		return getEndpointsFilters(id, params);
	};

	clearFilters = () => {
		this.getEndpoints();
	};

	onApply = () => {
		this.getEndpoints();
	};

	onChange = () => {
		this.getEndpoints();
	};

	onSearchSubmitCallback = () => {
		this.getEndpoints();
	};

	openFilterModal = () => {
		const { openFilterModal } = this.props;
		openFilterModal();
	};

	getEndpoints = () => {
		const { orderColumn } = this.state;
		const {
			id,
			dataLimit,
			getEndpoints,
			filterData,
			dataOffset,
			searchParameter,
		} = this.props;

		// here we define real names of the properties that we get from backend!
		const realProps = {
			currency: 'currency',
			subscriber_status: 'subscriber_status',
			monthly_rate_plan: 'monthly_rate_plan',
			standard_rate_plan: 'standard_rate_plan',
			prepaid_terms_charged: 'prepaid_terms_charged',
			customer: 'customer',
		};

		let filterParams = [];

		if (filterData) {
			filterParams = filterHelpers(filterData, realProps);
		}

		let searchParams = {};

		if (searchParameter) {
			searchParams = {
				operator: 'like',
				prop: searchParameter.prop,
				propValue: `${searchParameter.propValue}%`,
			};
		}

		const params = {
			additionalParams: {
				dataLimit,
				dataOffset,
				dataSort: orderColumn.apiString || 'invoice_id asc',
			},
			searchParams: [searchParams, ...filterParams],
		};

		getEndpoints(id, params);
	};

	getOptions = (target) => {
		const {
			dataLimit,
			endpoints,
			selectedPage,
			setDataOffset,
			setSelectedPage,
		} = this.props;
		const { totalCount } = 0 || endpoints;
		const { orderColumn } = this.state;

		const isMobile = target === 'mobile';
		const isDesktop = target === 'desktop';

		const options = {};

		if (isDesktop) {
			options.header = this.getTableHeader();
		}

		if (isMobile) {
			options.header = EndpointsHeaderMobile;
		}

		options.tableOptions = {
			showPagination: totalCount > dataLimit,
			pageCount: this.totalCount(),
			forcePage: selectedPage,
			onPageChange: (data) =>
				newOnPageChange(
					data,
					this.getEndpoints,
					dataLimit,
					setDataOffset,
					setSelectedPage
				),
			getOrderData: (data) => onOrderClick(data, this.getEndpoints, this),
		};

		if (isDesktop) {
			options.tableOptions = {
				...options.tableOptions,
				orderByData: ['all'],
				orderColumn,
				// selectRows: true,
				// enableSelectAllRows: true,
				firstColumnMarked: true,
				fixedFirstColumn: true,
				excludeDraggableColumns: [],
				draggable: true,
        onDragEnd: (fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex),
        enableTableGrabbing: true
			};
		}
		options.customComponents = {};
		return options;
	};

	totalCount = () => {
		const { dataLimit, endpoints } = this.props;
		return Math.ceil(endpoints.totalCount / dataLimit);
	};

	// edit table parameters
	openEditTableModal = () => {
		this.setState({
			isEditTableModalOpen: true,
		});
	};

	closeEditTableModal = () => {
		this.setState({ isEditTableModalOpen: false });
	};

	submitColumns = (newValue, id) => {
		const { patchUserSetting } = this.props;
		patchUserSetting({ value: JSON.stringify(newValue) }, id);
		this.setState({
			updatedSetings: newValue,
		});
	};

	getTableHeader = () => {
		const { settings } = this.state;
		return isEmpty(settings)
			? headerConstants
			: orderBy(settings.header, ['order'], ['asc']);
	};

	onDragEnd = async (fromIndex, toIndex) => {
		const { patchUserSetting } = this.props;
		if (toIndex < 0) return;

    const data = await this.getTableHeader();
		[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];
		const temp = {
			value: JSON.stringify({
				signature: endpointsSignature,
				header: data.map((item, index) => {
					item.order = index + 1;
					return item;
				}),
			}),
    };
		const settings = await this.getInvoicesUserSettings();
		patchUserSetting(temp, settings.id);
		this.setState({ updatedSetings: JSON.parse(temp.value) });
	};

	render() {
		// show loader when data is not fetched!
		const {
			endpoints,
			endpointsRequest,
			endpointsFail,
			messages,
			user,
			updateUserSettingsRequest,
			id,
      downloadFile,
      exportInvoiceDetailsRequest
		} = this.props;

		const { isEditTableModalOpen, isUpdating } = this.state;

		const excludeColumns = ['iccid'];

		const params = {
			service: getEndpointsFiltersService,
			params: {
				id,
				params: {
					additionalParams: {
						columnsToSelect:
							'currency,customer,subscriber_status,monthly_rate_plan,standard_rate_plan,prepaid_terms_charged',
					},
				},
			},
		};

		const isAnyRequest =
			endpointsRequest ||
			updateUserSettingsRequest ||
      exportInvoiceDetailsRequest;

		const isDataEmpty =
			endpoints && endpoints.resultList && endpoints.resultList.length === 0;

		return (
			<div data-spec="invoice-endpoints-table">
				<div>
					<div>
						<ActionBar
							menu={endpointsSearchMenu}
							onSearchSubmitCallback={this.onSearchSubmitCallback}
							openFilterModal={this.openFilterModal}
							messages={messages}
							clearFilters={this.clearFilters}
							onChange={this.onChange}
							openEditTableModal={this.openEditTableModal}
							isDisabled={isAnyRequest}
							isDataEmpty={isDataEmpty}
							downloadFile={() => downloadFile('endpoints')}
						/>

						<FilterModal
							type="checkbox"
							messages={messages}
							onApply={this.onApply}
							params={params}
						/>

						{this.getInvoicesUserSettings() && isEditTableModalOpen && (
							<EditTableModal
								show
								onClose={this.closeEditTableModal}
								messages={messages}
								user={user}
								submitColumns={this.submitColumns}
								columns={this.getInvoicesUserSettings()}
								signature={endpointsSignature}
								tableTabs={EndpointsTableTabs}
								excludeColumns={excludeColumns}
							/>
						)}
					</div>
					<List
						isUpdating={isUpdating}
						data={endpoints}
						dataRequest={isAnyRequest}
						dataFail={endpointsFail}
						options={this.getOptions}
						messages={messages}
					/>
				</div>
			</div>
		);
	}
}

const { func, object, string, number, bool, array, objectOf } = PropTypes;

EndpointsTable.propTypes = {
	getEndpoints: func,
	endpoints: object,
	id: string,
	dataLimit: number,
	endpointsRequest: bool,
	endpointsFail: bool,
	messages: objectOf(string),
	getEndpointsFilters: func,
	openFilterModal: func,
	userSettings: array,
	user: object,
	createUserSettings: func,
	patchUserSetting: func,
	createUserSettingsPending: bool,
	updateUserSettingsSuccess: bool,
	updateUserSettingsRequest: bool,
	filterData: object,
	downloadFile: func,
	dataOffset: number,
	selectedPage: number,
	setDataOffset: func,
	setSelectedPage: func,
  searchParameter: object,
  exportInvoiceDetailsRequest: bool,
	exportInvoiceDetailsSuccess: object,
	showReportFailNotification: func
};

export default EndpointsTable;
