import { connect } from 'react-redux';

import exportChartDataCSV from '../../redux/exportChartData/actions';
import { isExportingChartDataCsv } from '../../redux/exportChartData/selectors';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import { getUserSettings } from '../../../../redux/user/getUserData/selectors';
import resetUserSettings from '../../../../redux/user/resetUserSettings/actions';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import getChartData from '../../redux/getChartData/actions';
import getDirectionalIndex from '../../redux/getDirectionalIndex/actions';
import {
	getChartDataSuccess,
	getDirectionalIndexSuccess,
	isGetChartDataPending,
	isGetDirectionalIndexPending
} from '../../redux/selectors';

import Analytics from './Analytics';

const mapStateToProps = (state) => ({
	settings: getUserSettings(state, 5),
	chartData: (chartId) => getChartDataSuccess(state, chartId),
	isGetChartDataPending: (chartId) => isGetChartDataPending(state, chartId),
	directionIndex: (chartId) => getDirectionalIndexSuccess(state, chartId),
	isGetDirectionIndexPending: (chartId) =>
		isGetDirectionalIndexPending(state, chartId),
	isExportingChartDataCsv: (chartId) => isExportingChartDataCsv(state, chartId)
});

const mapDispatchToProps = (dispatch) => ({
	createUserSettings: (setting) => dispatch(createUserSettings(setting)),
	updateUserSettings: (setting, id) =>
		dispatch(updateUserSettings(setting, id)),
	getChartData: (chartId, chartSetting) =>
		dispatch(getChartData(chartId, chartSetting)),
	getDirectionalIndex: (chartId, chartSetting) =>
		dispatch(getDirectionalIndex(chartId, chartSetting)),
	resetUserSettings: () => dispatch(resetUserSettings(5)),
	exportChartDataCSV: (chartId, chartName, requestParams) =>
		dispatch(exportChartDataCSV(chartId, chartName, requestParams))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Analytics);
