export default {
	// COMMON
	'ONBOARDING.EDIT': 'Edit',
	'ONBOARDING.DELETE': 'Delete',
	'ONBOARDING.NAME': 'Name',
	'ONBOARDING.DESCRIPTION': 'Description',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': 'Account Name',
	'ONBOARDING.FRIENDLY_NAME': 'Friendly Name',
	'ONBOARDING.ACCOUNT_ID': 'Account ID',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Create New Company',
	'ONBOARDING.IOTC_CUSTOMERS': 'IoT Console Customers',
	'ONBOARDING.COMPANIES': 'Companies',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'No Companies Available',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE':
		'There are No Companies for this Table to show you right now',
	'ONBOARDING.COMPANY_NAME': 'Company Name',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'e.g: AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'MNC Account Name',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'M2M Account Name',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'IOT-C Account Number',
	'ONBOARDING.SIM_ORDERS': 'SIM Orders',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Technical Owner', - removed for Account Created By
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Account Created By',
	'ONBOARDING.VIEW_MC': 'View MC',
	'ONBOARDING.DATE_CREATED': 'Date Created',
	'ONBOARDING.ACTIONS': 'Actions',
	'ONBOARDING.PLATFORMS': 'Platforms',
	'ONBOARDING.ACTIVATION_DENIED': 'Request cannot be processed!',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Account Activated',
	'ONBOARDING.CONTACT_SYSADMIN':
		'Contact your IoT Console administrator for further assistance',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS':
		'Companies table settings have been successfully updated.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR':
		'Companies table columns could not be updated this time. Please try again.',
	'ONBOARDING.RETRY': 'Retry',

	// SEARCH MENU
	'ONBOARDING.BY': 'By ',
	'ONBOARDING.BUSINESS_NAME': 'Business Name',
	'ONBOARDING.CONTACT_PERSON': 'Contact Person',
	'ONBOARDING.PLATFORM_ID': 'Platform ID',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'IoT Console ID: ',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'Company M2M Accounts',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR':
		'You have successfully created a IoT Console account for:',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT':
		'The IoT Gateway number associated with this account:',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Create New Company Account',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS':
		'Cannot contain spaces or numbers',
	'ONBOARDING.IDM_SERVCE_NAME': 'IDM Service Name',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Visible to the customer',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Business or DBA legal entity',
	'ONBOARDING.COMPANY_CONTACTS': 'Company Contacts',
	'ONBOARDING.ATT_CONTACTS': 'AT&T Contacts',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'vTM Support Type',
	'ONBOARDING.FULL_TSM': 'Full TSM',
	'ONBOARDING.FULL_TSM_DESCRIPTION':
		'All tickets, regardless of severity, will be supported by the Technical Solutions Manager team. The account’s contract must specify that they qualify for TSM support or the Advanced Solutions Design and Implementation (ASDI) team has specified that the account qualifies for TSM support.',
	'ONBOARDING.PARTIAL_TSM': 'Partial TSM',
	'ONBOARDING.PARTIAL_TSM_DESCRIPTION':
		'Critical tickets will be supported by the Technical Solutions Manager team. All other severities will be supported by the ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team. The account’s contract must specify that they qualify for TSM support for critical issues.',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care',
	'ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION':
		'All tickets will be supported by the IoT Priority Care team. The account’s contract must specify that they qualify for IoT Priority Care support.',
	'ONBOARDING.STANDARD': 'Standard (Default)',
	'ONBOARDING.STANDARD_DESCRIPTION':
		'This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support.',
	'ONBOARDING.IOT_GATEWAY': 'IoT Gateway',
	'ONBOARDING.IOT_GATEWAY_DESCRIPTION':
		'Only for use when Equipment Inquiries will be submitted for manually processed TCU (Transceiver Control Unit) replacement requests.',
	'ONBOARDING.CONTACT': 'Contact',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Company Friendly Name',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Business Legal Name',
	'ONBOARDING.COMPANY_CONTACT': 'Company Contact',
	'ONBOARDING.ATT_CONTACT': 'AT&T Contact',
	'ONBOARDING.COMPANY': 'Company',
	'ONBOARDING.COUNTRY': 'Country',
	'ONBOARDING.STATE_PROVINCE': 'State/Province',
	'ONBOARDING.STREET_ADDRESS': 'Street Address',
	'ONBOARDING.ADDRESS_LINE_1': 'Address Line 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Address Line 2 (optional)',
	'ONBOARDING.CITY_TOWN': 'City/Town',
	'ONBOARDING.ZIP_POSTAL_CODE': 'ZIP/Postal Code',
	'ONBOARDING.VALIDATE_ADDRESS': 'Validate Address',
	'ONBOARDING.WE_FOUND_FOLLOWING_MAILING_ADDRESS':
		'We found the following mailing address. Please choose the preferred address',
	'ONBOARDING.ENTERED_ADDRESS': 'Entered Address',
	'ONBOARDING.SUGGESTED_ADDRESS': 'Suggested Address',
	'ONBOARDING.USE_ENTERED': 'Use Entered',
	'ONBOARDING.USE_SUGGESTED': 'Use Suggested',
	'ONBOARDING.VALIDATE_THE_ADDRESS': 'Validate the address',
	'ONBOARDING.CANNOT_SUGGEST_ADDRES': 'We cannot recommend any address',
	'ONBOARDING.COMPANY_DOMAIN': 'Company Domain',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'Company Email Domain',
	'ONBOARDING.FULL_NAME': 'Full Name',
	'ONBOARDING.PHONE_NUMBER': 'Phone Number',
	'ONBOARDING.EMAIL_ADDRESS': 'Email Address',
	'ONBOARDING.SIM_OPTIONS': 'SIM Options',
	'ONBOARDING.ORDER_MOVE_SIMS': 'Order or move SIMs',
	'ONBOARDING.ORDERING': 'Ordering',
	'ONBOARDING.TRANSFER': 'Transfer',
	'ONBOARDING.SIM_TRANSFER': 'SIM Transfer',
	'ONBOARDING.TRANSFERING_SIM':
		'Transferring a SIM from one M2M Account to another M2M Account',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST':
		'Order SIMs, select or upload SIM list',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'View or edit billing information',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': 'Platform',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Platform Description',
	'ONBOARDING.APNS': 'APNs',
	'ONBOARDING.M2M_ACCOUNT_ID': 'M2M Account ID',
	'ONBOARDING.EDIT_ACTIONS': 'Edit Accounts',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'Edit M2M Platform',
	'ONBOARDING.ADD_M2M_PLATFORM': 'Add M2M Platform',
	'ONBOARDING.MC_ACCOUNT_ID': 'IOT-C Account ID',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'ATT Control Center',
	'ONBOARDING.PLATFORM_CCIP': 'Control Center Integration Program',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Device Connection Platform',
	'ONBOARDING.PLATFORM_GMNA': 'GM North America',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Enterprise',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': 'M2M Platform',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Network Entitlement',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Network Entitlements',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Communication Profile Name',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE':
		'No Network Entitlements Available',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE':
		'There are No Network Entitlements for this Table to show you right now',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Add New Network Entitlement',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Add Network Entitlement',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Edit Network Entitlement',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Edit Network Entitlements',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Choose Network Entitlement',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Select Entitlement',
	'ONBOARDING.PULL_DATA': 'Pull Data',
	'ONBOARDING.HLR_TEMPLATE': 'HLR/HSS Details Template',
	'ONBOARDING.HOTLINE_NUMBER': 'Hotline Number',
	'ONBOARDING.VOICE_MT': 'Voice MT',
	'ONBOARDING.ROAMING_LTE': 'Roaming LTE',
	'ONBOARDING.DATA': 'Data',
	'ONBOARDING.DATA_LTE': 'Data LTE',
	'ONBOARDING.VOICE_MO': 'Voice MO',
	'ONBOARDING.INTERNATIONAL_VOICE': 'International voice',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO':
		'Block International SMS MO Except Home Country',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Block Premium Numbers',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Camel Service Type',
	'ONBOARDING.APNS_PDPINDEX': 'PDP Index',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'Fixed IP',
	'ONBOARDING.APNS_SECURITY': 'Security',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Subscription',
	'ONBOARDING.ADD_NEW_APN': 'Add New APN',
	'ONBOARDING.PLATFORM_DETAILS': 'Platform Details',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Roaming',
	'ONBOARDING.SMS_MO': 'SMS MO',
	'ONBOARDING.SMS_MT': 'SMS MT',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Eligible Network Entitlement',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': 'Payment Type',
	'ONBOARDING.USAGE_LIMIT': 'Usage Limit',
	'ONBOARDING.RATE_PLAN': 'Rate Plan',
	'ONBOARDING.RATE_PLANS': 'Rate Plans',
	'ONBOARDING.RATE_PLAN_NAME': 'Rate Plan Name',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'No Rate Plans Available',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Add New Rate Plan',
	'ONBOARDING.EDIT_RATE_PLAN': 'Edit Rate Plan',
	'ONBOARDING.PLAN_ID': 'Plan ID',
	'ONBOARDING.RATE_PLAN_TYPE': 'Rate Plan Type',
	'ONBOARDING.ACCOUNT_CHARGE': 'Account Charge',
	'ONBOARDING.TERM': 'Term',
	'ONBOARDING.TIERING_FLAG': 'Tiering Flag',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'Per Subscriber MRC',
	'ONBOARDING.CURRENCY': 'Currency',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Included Data Usage',
	'ONBOARDING.USAGE_LIMIT_MB': 'Usage Limit (MB)',
	'ONBOARDING.PLAN_TYPE': 'Plan Type',
	'ONBOARDING.TYPE': 'Type',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'INVOICE ACCOUNT NAME',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'M2M RATE PLAN ID',
	'ONBOARDING.PRICE_PLAN_ID': 'PRICE PLAN ID',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'SUBSCRIPTION ITEM TYPE',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'M2M SUBSCRIPTION ITEM ID',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'M2M INTERNAL ACCOUNT ID',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'M2M ACCOUNT NUMBER',
	'ONBOARDING.DEFAULT_PLAN': 'DEFAULT PLAN',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'Service Provider ID',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'Network Entitlement ID',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE':
		'There are No Rate Plans for this Table to show you right now',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SIM SKU
	'ONBOARDING.SIM_SKU': 'SIM SKU',
	'ONBOARDING.DELETE_SIM_SKU': 'Delete SIM SKU',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE':
		'By deleting this SKU you will delete all SIM Orders tied to this SKU. Are you sure you want to proceed?',
	'ONBOARDING.SIM_ORDERING': 'SIM Ordering',
	'ONBOARDING.CURRENT_SIM_SKUS': "Current SIM SKU'S",
	'ONBOARDING.ADD_SIM_SKU': 'Add SIM SKU',
	'ONBOARDING.EDIT_SIM_SKU': 'Edit SIM SKU',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'Enter SIM SKU Here',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'Enter SKU Description',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Contract restraints',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE':
		'Please set Restraints on the SIM Order Process.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Min Quantity Allowed',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Min Quantity',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Max Quantity Allowed',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Max Quantity',
	'ONBOARDING.INCREMENT': 'Increment',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Per Period',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Select a Time Period',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'min quantity',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'max quantity',
	'ONBOARDING.UNIT_PRICE': 'Unit Price',
	'ONBOARDING.ALLOW_TO_ORDER': 'Allow to Order',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'SKU successfully edited',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'SKU could not be edited',
	'ONBOARDING.RATE_PLAN_ID': 'Rate Plan ID',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'No Sim SKUS Available',

	// Platform
	'ONBOARDING.OPERATOR': 'Operator',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Operator (Optional)',
	'ONBOARDING.MCC': 'MCC',
	'ONBOARDING.MNC': 'MNC',
	'ONBOARDING.POD': 'Pod',
	'ONBOARDING.MCC_TOOLTIP':
		'The Mobile Country Code (MCC) is a unique 3-digit number to identify a country.',
	'ONBOARDING.MNC_TOOLTIP':
		'The Mobile Network Code (MNC) is a unique 2 or 3-digit number to identify a mobile network.',
	'ONBOARDING.PLATFORM_SETUP': 'Platform Setup',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP':
		'Platform Setup will be available after Successfully adding a Platform. ',
	'ONBOARDING.SETTINGS': 'Settings',
	'ONBOARDING.EDIT_PLATFORM': 'Edit Platform',
	'ONBOARDING.ADD_PLATFORM': 'Add Platform',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'No platforms available.',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Required information missing',
	'ONBOARDING.NO_PLATFORMS': 'No platforms',

	// Edit platform
	'ONBOARDING.SIM_LOADING': 'Sim Loading',
	'ONBOARDING.GATEWAY': 'Gateway',
	'ONBOARDING.ENTER_GATEWAY': 'Enter Gateway',
	'ONBOARDING.BILLING_INFORMATION': 'Billing Information',
	'ONBOARDING.BILLING_PLATFORM': 'Billing Platform',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Invoice Account Number',

	// Reports
	'ONBOARDING.REPORT': 'Report',
	'ONBOARDING.REPORTS': 'Reports',
	'ONBOARDING.COMPANY_REPORTS': 'Reports',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'IDM Integration Error',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE':
		'It seems that there was a problem with IDM Integration. ',
	'ONBOARDING.ONBOARDING_STATUS': 'Onboarding Status',
	'ONBOARDING.SUCCESS': 'Success',
	'ONBOARDING.ERROR': 'Error',

	'ONBOARDING.MUST_BE_BETWEEN': 'Must be between ',
	'ONBOARDING.AND': ' and ',
	'ONBOARDING.CHARACTERS_LONG': ' characters long',
	'ONBOARDING.DEFAULT': 'Default',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Add Rate Plans for {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED':
		'Company reports settings successfully updated',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS':
		'Error while updating company reports',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR':
		'Define reports to be enabled for {companyName}',
	'ONBOARDING.REPORTS_SUBTITLE':
		'Reports checked will be displayed in IoT Console portal for users to download',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR':
		'Add Network Entitlements for {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE':
		'Link network entitlements enabled for this account AT&T Control Center.',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Main Corporate Address',
	'ONBOARDING.COMPANY_DETAILS': 'Company Details',
	'ONBOARDING.ACCOUNTS': 'Accounts',
	'ONBOARDING.EDIT_ACCOUNT': 'Edit Account',
	'ONBOARDING.VIEW_ACCOUNT': 'View Account',
	'ONBOARDING.SIM_LOADING_SUB':
		'Where the SIMs are loaded for this account, typically IoT Gateway does this automatically when the M2M platform account is established.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Import from Iot Gateway',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Account Configuration',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB':
		'Select applicable services to be enabled in this account. If you do not see desired service, one can be added at the parent account level, subject to approval process. ',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Add Network Entitlements',
	'ONBOARDING.ADD_RATE_PLANS': 'Add Rate Plans',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED':
		'View or edit reports to be displayed',
	'ONBOARDING.USERS': 'Users',
	'ONBOARDING.USERS_SUB':
		'Clicking button below will send you to your company identity screen where you can invite new users to join this account, or add existing users to this company.',
	'ONBOARDING.CREATE_USERS': 'Create Users',
	'ONBOARDING.USER_ACCESS': 'User Access',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Create/add user',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Verify new company information',
	'ONBOARDING.SUBMIT': 'Submit',
	'ONBOARDING.CREATE_COMPANY': 'Create Company',
	'ONBOARDING.NEW_ACCOUNT': 'New Account',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE':
		'An account or sub-account must exist in M2M platform before it can be added to MNC. Follow the steps below to create an M2M account to be provisioned in IoT Console. ',
	'ONBOARDING.VALIDATE': 'Validate',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Validate Account Name',
	'ONBOARDING.CREATE_ACCOUNT': 'Create Account',
	'ONBOARDING.ADD_ACCOUNT': 'Add Account',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'No Accounts Available',
	'ONBOARDING.M2M_PLARFORM': 'M2M Platform',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'SKU successfully created',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'SKU could not be created',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR':
		'Edit Network Entitlements for {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Edit Sub Account',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'View Sub Account',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Edit Rate Plans for {companyName}',
	'ONBOARDING.VIEW': 'View',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'View Network Entitlements',
	'ONBOARDING.VIEW_RATE_PLANS': 'View Rate Plans',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Create Sub Account',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'New sub account for {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'Generate M2M Account',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Sub Account Name',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT':
		'Select rate plans for this account',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT':
		'Select network entitlements for this account',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED':
		'Warning: Country other than USA is currently not supported',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Assign to Sub Account',
	'ONBOARDING.ENDPOINT_ID': 'Endpoint ID',
	'ONBOARDING.NETWORK_OPERATOR': 'Network Operator',
	'ONBOARDING.STATUS': ' Status',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE':
		' endpoints will be moved to an account',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Confirm Endpoints Move',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE':
		'Once you Initiate this move, the selected Endpoints will be moved within a few minutes. Would you like to proceed with this action?',
	'ONBOARDING.ENDPOINTS': 'Endpoints',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'Move to Sub-Account',
	'ONBOARDING.FROM': 'From',
	'ONBOARDING.TO': 'To',
	'ONBORDING.SIM_SKU': 'Sim Sku',
	'ONBORDING.UNIT_PRICE': 'Unit Price',
	'ONBORDING.DATE_SUBMITED': 'Date Submitted',
	'ONBORDING.EDIT_DELETE_SKU': 'Edit / Delete Sku',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'No Endpoints Available.',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'No Sims Available',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'Select sim for this account',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE':
		'There are No Accounts for this Table to show you right now',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': 'Monthly',
	'ONBOARDING.PREPAID': 'Prepaid',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Monthly - Individual Subscriber',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Monthly - Fixed Pool - Single',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Essential - Monthly Fixed Pool',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Monthly - Flexible Pool',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL':
		'Essential - Monthly Flexible Pool',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER':
		'Monthly - Flexible Pool Usage Tier',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Monthly - Bolt On',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Prepaid - Individual Subscriber',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Prepaid - Flexible Pool',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Prepaid - Fixed Pool',
	'ONBOARDING.PREPAID_ADD_ON': 'Prepaid - Add On',
	'ONBOARDING.PREPAID_EVENT': 'Prepaid - Event',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Prepaid - Stacked Event',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': 'Data Usage Detail',
	'ONBOARDING.SMS_USAGE_DETAILS': 'SMS Usage Details',
	'ONBOARDING.IMSI_SNAPSHOT': 'IMSI Snapshot',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Subscriber Directory',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Subscriber Snapshot',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Subscriber Changes',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Account Daily Snapshot',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Rate Plan Snapshot',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Communication Plan Snapshot',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Billing Cycle Reference',
	'ONBOARDING.CARRIER_REFERENCE': 'Carrier Reference',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Rate Plan Type Reference',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'Sim Change Reference',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE':
		'Usage Record Close Cause Reference',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Rate Plan Zone Snapshot',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Service Provider Custom Fields',
	'ONBOARDING.USER_SNAPSHOT': 'User Snapshot',
	'ONBOARDING.NEW_SUBSCRIBERS': 'New Subscribers',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Combined Subscribers',
	'ONBOARDING.COMBINED_USAGE': 'Combined Usage',
	'ONBOARDING.APN_SNAPSHOT': 'APN Snapshot',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Service Provider Custom Fields',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Combined Accounts',
	'ONBOARDING.DAILY_USAGE_RAW': 'Daily Usage Raw',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'All Active SIMs',
	'ONBOARDING.ALL_ACTIVE_SIM_COMBINED_': 'All Active SIMs Combined',
	'ONBOARDING.ALL_ASSIGNED_SIM_COMBINED_': 'All Assigned SIMs Combined',
	'ONBOARDING.ACTIVATION_STATUS_CHANGE': 'Activation Status Change',
	'ONBOARDING.ACCOUNT_USAGE_REPORT': 'Account Usage Report',
	'ONBOARDING.DAILY_USAGE': 'Daily Usage',
	'ONBOARDING.ALL_SMS_MESSAGES': 'All SMS Messages',
	'ONBOARDING.SMS_DETAIL': 'SMS Detail',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'ICCID Cumulative Summary',
	'ONBOARDING.SIM_STATE_REFERENCE': 'Sim State Reference',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Voice Usage Detail',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'All Assigned SIMs',
	'ONBOARDING.INVOICE_DETAIL': 'Invoice Detail',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Rate Plan Summary',
	'ONBOARDING.OTHER_CHARGES': 'Other Charges',
	'ONBOARDING.USAGE_SUMMARY': 'Usage Summary',
	'ONBOARDING.ACTIVATION_CHARGES': 'Activation Charges',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Rate Plan Zone Usage Detail',
	'ONBOARDING.INVOICE_CHARGES': 'Invoice Charges',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Pooled Usage Charges',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Subscriber Charges',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Non Pooled Usage Charges',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Subscriber Wholesale Usage',
	'ONBOARDING.FUNDED_PACKAGES': 'Funded Packages',
	'ONBOARDING.TRIAL_COST_SHARE': 'Trial Cost Share',
	'ONBOARDING.REVENUE_SHARE': 'Revenue share',
	'ONBOARDING.BOUNTY': 'Bounty',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Data Usage Details',
	'ONBOARDING.SMS_DETAILS': 'SMS Details',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Subscriptions Delta',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Undelivered SMS Messages',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE':
		'Subscriber Wholesale Zero Revenue',
	'ONBOARDING.INVOICE_DETAILS': 'Invoice Details',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Rate Plan Zone Usage Details',
	'ONBOARDING.BILLING_INVOICE': 'Billing Invoice',
	'ONBOARDING.SUBSCRIPTION_CHARGES': 'Subscription Charges',
	'ONBOARDING.USAGE_DETAIL_VOICE': 'Usage Detail Voice',
	'ONBOARDING.USAGE_DETAIL_SMS': 'Usage Detail SMS',
	'ONBOARDING.USAGE_DETAIL_DATA': 'Usage Detail Data',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGE': 'Undelivered SMS Message',
	'ONBOARDING.ANONYMIZED': 'Anonymized',
	'ONBOARDING.ACQUISITION_BOUNTY': 'Acquisition Bounty',
	'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Combined Acquisition Bounty',
	'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Combined Hardware Subsidy',
	'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES':
		'Combined Partner Funded Packages',
	'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Combined Pooled Usage Charges',
	'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Combined Subscription Charges',
	'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Combined Trial Cost Share',
	'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Combined Usage Summary',
	'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES':
		'Monthly Combined Invoice Charges',
	'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL':
		'Monthly Combined Invoice Detail',
	'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES':
		'Monthly Combined Non-Pooled Usage Charges',
	'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Monthly Combined Other Charges',
	'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Partner Funded Packages',
	'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'Account Device Status By Month',
	'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK':
		'Account Usage Report By Satellite Network',
	'ONBOARDING.COMBINED_REVENUE_SHARE': 'Combined Revenue Share',
	'ONBOARDING.ANONYMIZED_MARKETING_REPORT': 'Anonymized Marketing Report',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1 Month',
	'ONBOARDING.3_MONTHS': '3 Months',
	'ONBOARDING.1_WEEK': '1 Week',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Account Transferred Successfully',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Failed to Transfer Account',
	'ONBOARDING.MY_COMPANY': 'My Company',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': 'User Groups',
	'ONBOARDING.USER_COUNT': 'User Count',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Currently active users',
	'ONBOARDING.FIRST_NAME': 'First Name',
	'ONBOARDING.LAST_NAME': 'Last Name',
	'ONBOARDING.JOB_TITLE': 'Job Title',
	'ONBOARDING.EMAIL': 'Email',
	'ONBOARDING.USER_GROUP': 'User Group',
	'ONBOARDING.ACCOUNT_ACCESS': 'Account Access',
	'ONBOARDING.CREATE_NEW_GROUP': 'Create New Group',
	'ONBOARDING.INVITE_USERS': 'Invite Users',
	'ONBOARDING.ADD_USERS': 'Add Users',
	'ONBOARDING.EDIT_USER_PROFILE': 'Edit User Profile',
	'ONBOARDING.USER_INFORMATION': 'User Information',
	'ONBOARDING.ADDRESSES': 'Addresses',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Assigned Accounts',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Legal And Regulatory',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Create New User Group',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP':
		'Create customized access entitlement groups for your users.',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name} {lastName} accepted',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'End-User Licence Agreement',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE':
		'If you do not agree with the terms of EULA, you may delete this account.',
	'ONBOARDING.ON_DATE': 'on {date}.',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT':
		'If you do not agree with the terms of EULA, you may delete this account.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS':
		'Enter the email addresses for the users you would like to invite. Users will be able to access the company account as soon as they accept the invite link provided in the email notification.',
	'ONBOARDING.ADD_NEW_USER': 'Add New User',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Can Receive SMS',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION':
		'State/Province/Territory/Region',
	'ONBOARDING.COMPANY_EMAIL': 'Company Email',
	'ONBOARDING.POSTAL_CODE': 'Postal Code',
	'ONBOARDING.BUSINESS': 'Business',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US':
		'User privacy is important to us, and we do not sell your user data to anyone.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY':
		'EULA contains in-depth information about how we protect your privacy, right to erasure and other compliance regulations.',
	'ONBOARDING.STREET_ADDRESS_2': 'Street Address 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Street Address 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'City Or Municipality',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'Same Address As Primary',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Add Another Address',
	'ONBOARDING.YES': 'Yes',
	'ONBOARDING.NO': 'No',
	'ONBOARDING.PRIMARY_ADDRESS': 'Primary Address',
	'ONBOARDING.ADDRESS_TYPE': 'Address Type',
	'ONBOARDING.SECONDARY_NUMBER': 'Secondary Number',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Preferred Language',
	'ONBOARDING.PRIMARY_NUMBER': 'Primary Number',
	'ONBOARDING.UPLOAD_IMAGE': 'Upload Image',
	'ONBOARDING.FILTER_USER_LIST': 'Filter User List',
	'ONBOARDING.CANCEL': 'Cancel',
	'ONBOARDING.ADD': 'Add',
	'ONBOARDING.USER': 'User',
	'ONBOARDING.GROUP': 'Group',
	'ONBOARDING.PASSWORD_RESET': 'Password Reset',
	'ONBOARDING.SELECT_ACTION': 'Select action',
	'ONBOARDING.ACTIVE_STATUS': 'Active Status',
	'ONBOARDING.PENDING_STATUS': 'Pending Status',
	'ONBOARDING.ADD_MORE_USERS': 'Add More Users',
	'ONBOARDING.ENTITLEMENT_NAME': 'Entitlement Name',
	'ONBOARDING.NO_ACCESS': 'No Access',
	'ONBOARDING.READ_ONLY': 'Read-Only',
	'ONBOARDING.READ_WRITE': 'Read-Write',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Admin Group Permissions',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Edit Group Permissions',
	'ONBORDING.USER_GROUPS': 'User Groups',
	'ONBOARDING.CREATE_GROUP': 'Create Group',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Select Existing User Group',
	'ONBOARDING.GROUP_NAME': 'Group Name',
	'ONBOARDING.PAUSE': 'Pause',
	'ONBOARDING.REACTIVATE': 'Reactivate',
	'ONBOARDING.ACTIVATE': 'Activate',
	'ONBOARDING.REMOVE': 'Remove',
	'ONBOARDING.RESEND_INVITE': 'Resend Invite',
	'ONBOARDING.CANCEL_INVITE': 'Cancel Invite',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER':
		'Are you sure you want to remove the user?',
	'ONBOARDING.USER_PROFILE': 'User Profile',
	'ONBOARDING.SUSPEND': 'Suspend',
	'ONBOARDING.USER_MANAGEMENT': 'User Management',
	'ONBOARDING.MAILING': 'Mailing',
	'ONBOARDING.BILLING': 'Billing',
	'ONBOARDING.SECONDARY': 'Secondary',
	'ONBOARDING.PRIMARY': 'Primary',
	'ONBOARDING.PHONE': 'Phone',
	'ONBOARDING.ENGLISH': 'English U.S',
	'ONBOARDING.SAVE_USER': 'Save User',
	'ONBOARDING.BULK_UPLOAD': 'Batch Upload',
	'ONBOARDING.FOR_BULK_UPLOADS': 'for batch uploads',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'Download CSV template',
	'ONBOARDING.SEND_INVITE': 'Send Invite',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS':
		'Pausing users will lose access to {companyName} accounts. Pausing users will not be allowed to request access, but administrator can reactivate user account, to regain access, paused users will need to reset their account password.',
	'ONBOARDING.PAUSE_USER': 'Pause User',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Assigned User Group',
	'ONBOARDING.ACCESS_LEVEL': 'Access Level',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL':
		'Send Password Reset Link To Email',
	'ONBOARDING.EDIT_USER': 'Edit User',
	'ONBOARDING.RESET_PASSWORD': 'Reset Password',
	'ONBOARDING.CURRENT_STATUS': 'Current Status',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'View User Assigned Permissions',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Manage Individual Permissions',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Individual Permissions',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Effective Permissions',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Assigned User Groups',
	'ONBOARDING.ACTION': 'Action',
	'ONBOARDING.DELETE_PERMISSIONS': 'Delete Permissions',
	'ONBOARDING.VIEW_PERMISSIONS': 'View Permissions',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Add another user group',
	'ONBOARDING.PENDING_INVITATIONS': 'Pending Invitations',
	'ONBOARDING.PERMISSIONS': 'Permissions',
	'ONBOARDING.CLOSE': 'Close',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Save Individual Permissions',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS':
		'Access will be granted to the following M2M Accounts',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Access Management',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Service Provider Users',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Service Provider User Groups',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS':
		'Add service provider users to be able to manage customer accounts and onboard new ones.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'No User Groups Available',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION':
		'If you need to perform a restricted operation reach out to your technical contact for help.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS':
		'Some configurations are limited to AT&T Super Users.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL':
		'User groups are built using least-privilege model.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'System-level entitlements',
	'ONBOARDING.DENY_ACCESS': 'Deny Access',
	'ONBOARDING.ALLOW_READ': 'Allow Read',
	'ONBOARDING.ALLOW_WRITE': 'Allow Write',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Edit Group Permissions',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'No Permissions Available',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE':
		'There are No Network Entitlements Available for this Table to show you right now',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'Edit M2M Accounts',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'Move to Sub-Account',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Can Receive Email',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Unrestricted Access',
	'ONBOARDING.ACTIVE': 'Active', // delete later
	'ONBOARDING.PAUSED': 'Paused', // delete later
	'ONBOARDING.VIEW_USER_GROUP': 'View User Group',
	'ONBOARDING.DELETE_USER_GROUP': 'Delete User Group',
	'ONBOARDING.SAVE': 'Save',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO':
		'Select the group user should belong to:',

	'ONBOARDING.ALLOW_ACCESS': 'Allow Access',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Create New Password',
	'ONBOARDING.NEW_PASSWORD': 'New Password',
	'ONBOARDING.CONFIRM_PASSWORD': 'Confirm Password',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Enter new password',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Please enter your new password',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Back to Login',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'Information not provided.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': 'to add address details.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Edit Assigned Accounts',
	'ONBOARDING.EDIT_PERMISSIONS': 'Edit Permissions',
	'ONBOARDING.SUSPENDED': 'Suspended',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Pending Invitation Available',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS':
		'In next step you will be able to tune new group permissions',
	'ONBOARDING.GRANT_ACCESS': 'Grant Access',
	'ONBOARDING.SELECT_USER_GROUP': 'Please select user group',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE':
		'No System Invitations Available',
	'ONBOARDING.ADD_USER_GROUP': 'Add User Group',
	'ONBOARDING.ADD_ADDRESS': 'Add Address',
	'ONBOARDING.CROP_IMAGE': 'Crop Image',
	'ONBOARDING.CROP': 'Crop',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN':
		'This action is irreversible, and any history of the user will be forgotten by our systems.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS':
		'Deleting the profile will remove user access from all accounts you currently have access to.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Delete {name} {lastName} Profile',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Enter Confirmation Phrase',
	'ONBOARDING.DELETE_PROFILE': 'Delete Profile',
	'ONBOARDING.DELETE_USER': 'Delete User',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL':
		'Justification For Pausing (Optional)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED':
		'Are You Sure You Want Proceed?',
	'ONBOARDING.REACTIVATE_USER': 'Reactivate User',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY':
		'If you click yes, you will receive password restart email shortly.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Reset User Password',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET':
		'Are you sure you want to proceed with the password restart?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Assign User Group',
	'ONBOARDING.ASSIGN': 'Assign',
	'ONBOARDING.AND_OTHERS': 'And {totalCount} others...',
	'ONBOARDING.MY_PROFILE': 'My Profile',
	'ONBOARDING.EDIT_USER_GROUP': 'Edit User Group',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Edit Individual Permissions',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'View Individual Permissions',
	'ONBOARDING.NO_USERS_AVAILABLE': 'No Users Available',
	'ONBOARDING.CONTACTS': 'Contacts',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION':
		'Are you sure you want to perform this operation?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Remove User Group',
	'ONBOARDING.CONTACT_TYPE': 'Contact type',
	'ONBOARDING.CANCEL_INVITATION': 'Cancel Invitation',
	'ONBOARDING.RESEND_INVITATION': 'Resend Invitation',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Reactivate User Group',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Suspend User Group',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN':
		'There Are No User Groups To Assign',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'Address Type Exists',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Add New Address',
	'ONBOARDING.ADD_NEW_CONTACT': 'Add New Contact',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Add Another Contact',
	'ONBOARDING.COMPANY_FEATURES': 'Company Features',
	'ONBOARDING.INFO_NOT_PROVIDED': 'Info Not Provided',
	'ONBOARDING.COMPANY_SETTINGS': 'Company Settings',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT':
		'If you already have an account, please proceed to login.',
	'ONBOARDING.ACCOUNT_ACTIVATED':
		'In a few moments you will receive an email with instructions on how to create your password and access to your account.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON':
		'Password reset is managed by AT&T Global Sign-On',

	//  Billing platform
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Address type
	'ONBOARDING.BILLING_ADDRESS': 'Billing Address',
	'ONBOARDING.HOME_ADDRESS': 'Home Address',

	//  Contact type
	'ONBOARDING.MAIN_CONTACT': 'Main Contact',
	'ONBOARDING.BACKUP_CONTACT': 'Backup Contact',
	'ONBOARDING.TECHNICAL_OWNER': 'Technical Owner',

	//  Address type
	'ONBOARDING.SHIPPING': 'Shipping',
	'ONBOARDING.HOME': 'Home',
	'ONBOARDING.ANOTHER': 'Another',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'Launch IoT Console',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE':
		'This number is required to display invoices in the IoT Console.',
	'ONBOARDING.BILLING_REPORTS': 'Billing Reports',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS':
		'This box must be checked to view invoice details.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM':
		'Billing details are not supported for this platform.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION':
		'View Or Edit Billing Configuration',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'Account ID Not Found.',
	'ONBOARDING.ARE_YOU_SURE': 'Are You Sure?',
	'ONBOARDING.REMOVE_CONTACT': 'Remove Contact',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'Pod, Tenant, or Instance ID',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM':
		'Device Connection Platform',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION':
		'Are you sure you want to perform this action?',
	'ONBOARDING.VERSION_ID': 'Version ID',
	'ONBOARDING.VERSION_NUMBER': 'Version #',
	'ONBOARDING.IOT_ACCOUNT_NAME': 'IoT-C Account Name',
	'ONBOARDING.BACK_TO_RATE_PLANS': 'Back to Rate Plans',
	'ONBOARDING.SUBSCRIPTION_CHARGE_UNIT': 'Subscription Charge Unit',
	'ONBOARDING.DEFAULT_RATE_PLAN': 'Default Rate Plan',
	'ONBOARDING.RATE_PLAN_CONFIGURATION': 'Rate Plan Configuration',
	'ONBOARDING.RATE_PLAN_DETAILS': 'Rate Plan Details',
	'ONBOARDING.IOT_C_ACCOUNT_ID': 'IOT-C Account ID',
	'ONBOARDING.CATEGORY_REPORTS': '{category} Reports',
	'ONBOARDING.DAILY_REPORTS': 'Daily Reports',
	'ONBOARDING.MONTHLY_REPORTS': 'Monthly Reports',
	'ONBOARDING.REFERENCE_REPORTS': 'Reference Reports',
	'ONBOARDING.DAILYCOMBINED_REPORTS': 'Daily Combined Reports',
	'ONBOARDING.SENDING_REPORTS_BY_STERLING':
		'If sending reports by Sterling File Gateway, please fill out the {sfgConnectionsFormLink} before proceeding.',
	'ONBOARDING.SFG_CONNECTIONS_FORM': 'SFG Connections Form',
	'ONBOARDING.DAILY_COMBINED_REPORTS_UNDERLINE':
		'A task must be opened in Jira to have analytics create these reports.',
	'ONBOARDING.SELECT_DESELECT_ALL': 'Select/Deselect All',
	'ONBOARDING.IOT_C': 'IoT-C',
	'ONBOARDING.SFG': 'SFG',
	'ONBOARDING.IOTC_ACCOUNT_ID': 'IoT-C Account ID',
	'ONBOARDING.SFG_MAILBOX': 'SFG Mailbox',
	'ONBOARDING.SFG_ROUTING': 'SFG Routing',
	'ONBOARDING.WEEKLY_REPORTS': 'Weekly Reports',

	//  NETWORK ENTITLEMENT DETAILS
	'ONBOARDING.NETWORK_ENTITLEMENT_PLAN_NAME': 'Network Entitlement Plan Name',
	'ONBOARDING.NETWORK_ENTITLEMENT_DETAILS': 'Network Entitlement Details',
	'ONBOARDING.DEFAULT_NETWORK_ENTITLEMENT': 'Default network entitlement',
	'ONBOARDING.NETWORK_ENTITLEMENT_NAME': 'Network Entitlement Name',
	'ONBOARDING.ENTITLEMENT_HLR_TEMPLATE': 'HLR Template',
	'ONBOARDING.SHARED': 'Shared',
	'ONBOARDING.FIXED_IP': 'Fixed IP Enabled',
	'ONBOARDING.CREATED_ON_HLR': 'Created on HLR',
	'ONBOARDING.ROAMING_RESTRICTION_SET_ID': 'Roaming Restriction Set ID',
	'ONBOARDING.GPRS': 'GPRS',
	'ONBOARDING.LTE_DATA': 'LTE Data',
	'ONBOARDING.VOLTE_ENABLED': 'VoLTE Enabled',
	'ONBOARDING.BLOCK_INTERNATIONAL_MT':
		'Block international SMS MO except home country',
	'ONBOARDING.NETWORK_COUNTRY': 'Network country',
	'ONBOARDING.HOTLINE_NUBER': 'Hotline number',
	'ONBOARDING.NETWORK': 'Network',
	'ONBOARDING.APNS_PDNINDEX': 'PDN Index',
	'ONBOARDING.APNS_IP_VERSION': 'IP Version',
	'ONBOARDING.APNS_LTE_CAPABLE': 'LTE capable',
	'ONBOARDING.APNS_SECURITY_ENABLED': 'Security enabled',
	'ONBOARDING.APNS_SUBSCRIPTION_ENABLED': 'Subscription enabled',
	'ONBOARDING.ON_STATE_NETWORK_PROFILE': 'On-state Network Profile',
	'ONBOARDING.OFF_STATE_NETWORK_PROFILE': 'Off-state Network Profile',
	'ONBOARDING.HIDE_APNS': 'Hide APNs',
	'ONBOARDING.SHOW_APNS': 'Show APNs',
	'ONBOARDING.SHOW_ASSOCIATED_APNS': 'Show associated APNs'
};
