import { Status } from '../../../../utils/constants';

export const downloadTicketFileRequest = (state) =>
	state.ticketing.ticketFileDownload.status === Status.PENDING;
export const ticketFileDownloadSuccess = (state) =>
	state.ticketing.ticketFileDownload.status === Status.FAIL;
export const ticketFileDownloadFail = (state) =>
	state.ticketing.ticketFileDownload.status === Status.DONE;
export const fileDownloadId = (state) =>
	state.ticketing.ticketFileDownload.fileId;
