export default {
	'SHARED.NO_DATA_AVAILABLE': 'Nessun dato disponibile',
	'SHARED.NO_DATA_AVAILABLE_SUBTITLE': 'Non ci sono attualmente dati disponibili da mostrare',
	'SHARED.EXIT_THIS_COMPANY': 'ESCI',
	// ACTION BAR SELECTED FILTERS TOOLTIP
	// ** ENDPOINT **
	'ACTION_BAR.ENDPOINTSTATUS': 'Stato',
	'ACTION_BAR.NETWORKOPERATOR': 'Operatore di rete',
	'ACTION_BAR.NETWORKCOUNTRY': 'Paese della rete',
	'ACTION_BAR.NETWORKENTITLEMENT': 'ID diritti di rete',
	'ACTION_BAR.NETWORKTYPE': 'Tipo di rete',
	'ACTION_BAR.TRANSFERSTATUS': 'Stato trasferimento IMSI',
	'ACTION_BAR.RATEPLAN': 'Piano tariffario',
	'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'Stato rigenerazione credenziali',
	'ACTION_BAR.M2MPLATFORM': 'Piattaforma M2M',
	'ACTION_BAR.ROAMINGCOUNTRY': 'Paese roaming',
	'ACTION_BAR.ROAMINGCARRIER': 'Vettore roaming',
	'ACTION_BAR.SERVICESTATUS': 'Stato 360L',
	'ACTION_BAR.OEM': 'Sottoconti',
	'ACTION_BAR.M2MDATEADDED': 'Aggiunta data M2M',
	'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'Data attivazione M2M',
	//  **  INVOICES  **
	'ACTION_BAR.M2MACCOUNTID': 'ID account M2M',
	'ACTION_BAR.CUSTOMERNAME': 'Nome cliente',
	'ACTION_BAR.INVOICENUMBER': 'Numero fattura',
	'ACTION_BAR.COUNTRYNAME': 'Nome paese',
	'ACTION_BAR.BILLINGCYCLE': 'Ciclo di fatturazione',
	'ACTION_BAR.CUSTOMERID': 'ID cliente',
	'ACTION_BAR.INVOICEDATE': 'Data fattura',
	//  **  REPORTS **
	'ACTION_BAR.M2M_ACC_ID': 'ID account M2M',
	'ACTION_BAR.M2M_PLATFORM': 'Piattaforma M2M',
	'ACTION_BAR.PLATFORM': 'Piattaforma',
	'ACTION_BAR.FREQUENCY': 'Frequenza',
	'ACTION_BAR.REPORT_NAME': 'Nome report',
	'ACTION_BAR.SERVICEPROVIDER': 'Piattaforma',
	'ACTION_BAR.REPORTFREQUENCY': 'Frequenza',
	//  **  SIM ORDERING  **
	'ACTION_BAR.FILTER_CATEGORY_STATUS': 'Stato',
	'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'Piattaforma M2M',
	'ACTION_BAR.FILTER_CATEGORY_SKU': 'SKU',
	'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Accelerato',
	//  **  SECURITY FEATURES **
	'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'Account M2M',
	//  **  TICKETING **
	'ACTION_BAR.CATEGORIES': 'Categorie',
	'ACTION_BAR.STATUS': 'Stato',
	'ACTION_BAR.SEVERITY': 'Gravità',
	//  ACTION BAR
	'ACTION_BAR.CLEAR_FILTERS': 'Cancella filtri',
	'ACTION_BAR.SELECT_FILTERS': 'Seleziona filtri',
	'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Modifica parametri tabella',
	'ACTION_BAR.SHOWING': 'Visualizzazione',
	'ACTION_BAR.OF': 'di',
	'ACTION_BAR.DOWNLOAD_FILE': 'Scaricamento file',
	'ACTION_BAR.REFRESH_PAGE': 'Aggiornamento pagina',
	//  ******************* FILTER MODAL *****************************
	//  **  ENDPOINTS **
	'FILTER_MODAL.ENDPOINTSTATUS': 'Stato',
	'FILTER_MODAL.NETWORKOPERATOR': 'Operatore di rete',
	'FILTER_MODAL.NETWORKCOUNTRY': 'Paese della rete',
	'FILTER_MODAL.NETWORKENTITLEMENT': 'ID diritti di rete',
	'FILTER_MODAL.NETWORKTYPE': 'Tipo di rete',
	'FILTER_MODAL.TRANSFERSTATUS': 'Stato trasferimento IMSI',
	'FILTER_MODAL.RATEPLAN': 'Piano tariffario',
	'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'Stato rigenerazione credenziali',
	'FILTER_MODAL.M2MPLATFORM': 'Piattaforma M2M',
	'FILTER_MODAL.ROAMINGCOUNTRY': 'Paese roaming',
	'FILTER_MODAL.ROAMINGCARRIER': 'Vettore roaming',
	'FILTER_MODAL.SERVICESTATUS': 'Stato 360L',
	'FILTER_MODAL.OEM': 'Sottoconti',
	'FILTER_MODAL.M2MDATEADDED': 'Aggiunta data M2M',
	'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'Data attivazione M2M',
	//  tabs
	'FILTER_MODAL.TABS_ENDPOINTSTATE': 'Stato endpoint',
	'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Operatore di rete',
	'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'Paese della rete',
	'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Diritti di rete',
	'FILTER_MODAL.TABS_NETWORKTYPE': 'Tipo di rete',
	'FILTER_MODAL.TABS_TRANSFERSTATUS': 'Stato trasferimento',
	'FILTER_MODAL.TABS_RATEPLAN': 'Piano tariffario',
	'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': 'Rigenerazione credenziali stato trasferimento',
	'FILTER_MODAL.TABS_M2MPLATFORM': 'Piattaforma M2M',
	'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'Paese roaming',
	'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Vettore roaming',
	'FILTER_MODAL.TABS_SERVICESTATUS': 'Stato 360L',
	'FILTER_MODAL.TABS_OEM': 'Sottoconti',
	'FILTER_MODAL.TABS_M2MDATEADDED': 'Aggiunta data M2M',
	'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'Data attivazione M2M',
	//  **  INVOICES  **
	'FILTER_MODAL.M2MACCOUNTID': 'ID ACCOUNT M2M',
	'FILTER_MODAL.CUSTOMERNAME': 'NOME CLIENTE',
	'FILTER_MODAL.INVOICENUMBER': 'NUMERO FATTURA',
	'FILTER_MODAL.INVOICEDATE': 'DATA FATTURA',
	'FILTER_MODAL.COUNTRYNAME': 'NOME PAESE',
	'FILTER_MODAL.CUSTOMERID': 'ID CLIENTE',
	'FILTER_MODAL.BILLINGCYCLE': 'CICLO DI FATTURAZIONE',
	//  tabs
	'FILTER_MODAL.TABS_M2MACCOUNTID': 'ID account M2M',
	'FILTER_MODAL.TABS_CUSTOMERNAME': 'Nome cliente',
	'FILTER_MODAL.TABS_INVOICENUMBER': 'Numero fattura',
	'FILTER_MODAL.TABS_COUNTRYNAME': 'Nome paese',
	'FILTER_MODAL.TABS_CUSTOMERID': 'ID cliente',
	'FILTER_MODAL.TABS_BILLINGCYCLE': 'Ciclo di fatturazione',
	'FILTER_MODAL.TABS_INVOICEDATE': 'Data fattura',

	//  **  REPORTS **
	'FILTER_MODAL.M2M_ACC_ID': 'ID ACCOUNT M2M',
	'FILTER_MODAL.M2M_PLATFORM': 'PIATTAFORMA M2M',
	'FILTER_MODAL.FREQUENCY': 'FREQUENZA',
	'FILTER_MODAL.REPORT_NAME': 'NOME REPORT',
	'FILTER_MODAL.PLATFORM': 'PIATTAFORMA',
	'FILTER_MODAL.SERVICEPROVIDER': 'PIATTAFORMA',
	'FILTER_MODAL.REPORTFREQUENCY': 'FREQUENZA',
	//  tabs
	'FILTER_MODAL.TABS_M2M_ACC_ID': 'ID account M2M',
	'FILTER_MODAL.TABS_M2M_PLATFORM': 'Piattaforma M2M',
	'FILTER_MODAL.TABS_FREQUENCY': 'Frequenza',
	'FILTER_MODAL.TABS_REPORT_NAME': 'Nome report',
	'FILTER_MODAL.TABS_PLATFORM': 'Piattaforma',
	'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Piattaforma',
	'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Frequenza',
	//  **  SIM ORDERING  **
	'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'STATO',
	'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'PIATTAFORMA M2M',
	'FILTER_MODAL.FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'ACCELERATO',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'Stato',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'Piattaforma M2M',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'SKU',
	'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Accelerato',
	//  **  SECURITY FEATURES **
	'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'ACCOUNT M2M',
	//  tabs
	'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'Account M2M',
	//  **  TICKETING **
	'FILTER_MODAL.CATEGORIES': 'CATEGORIE',
	'FILTER_MODAL.STATUS': 'STATO',
	'FILTER_MODAL.SEVERITY': 'GRAVITÀ',
	//  tabs
	'FILTER_MODAL.TABS_CATEGORIES': 'Categorie',
	'FILTER_MODAL.TABS_STATUS': 'Stato',
	'FILTER_MODAL.TABS_SEVERITY': 'Gravità',
	//  **  INVOICE DETAILS **
	'FILTER_MODAL.CURRENCY': 'VALUTA',
	'FILTER_MODAL.CUSTOMER': 'CLIENTE',
	'FILTER_MODAL.SUBSCRIBER_STATUS': 'STATO ABBONATO',
	'FILTER_MODAL.MONTHLY_RATE_PLAN': 'PIANO TARIFFARIO MENSILE',
	'FILTER_MODAL.STANDARD_RATE_PLAN': 'PIANO TARIFFARIO STANDARD',
	'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'TERMINI PREPAGATI ADDEBITATI',
	'FILTER_MODAL.RATE_PLAN': 'PIANO TARIFFARIO',
	'FILTER_MODAL.PLAN_TYPE': 'TIPO DI PIANO',
	'FILTER_MODAL.ZONE': 'ZONA',
	'FILTER_MODAL.PRIORITY': 'PRIORITÀ',
	'FILTER_MODAL.ROAMING_ZONE': 'ZONA ROAMING',
	'FILTER_MODAL.GROUP_NAME': 'NOME GRUPPO',
	'FILTER_MODAL.INVOICE_ID': 'ID FATTURA',
	'FILTER_MODAL.THE_PLAN_TYPE': 'TIPO DI PIANO',
	'FILTER_MODAL.ACTIVATION_TYPE': 'TIPO ATTIVAZIONE',
	'FILTER_MODAL.CHARGE_TYPE': 'TIPO ADDEBITO',
	'FILTER_MODAL.PRIMARY_PLACE': 'LOCALITÀ PRIMARIA',
	'FILTER_MODAL.TECH_TYPE': 'TIPO TECNOLOGIA',
	'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'LOCALITÀ DI UTILIZZO PRIMARIA',
	'FILTER_MODAL.STATE': 'STATO',
	'FILTER_MODAL.CITY': 'CITTÀ',
	'FILTER_MODAL.DISTRICT_COUNTY': 'CONTEA DISTRETTO',
	'FILTER_MODAL.DISTRICT_CITY': 'CITTÀ DISTRETTO',
	'FILTER_MODAL.DESCRIPTION': 'DESCRIZIONE',
	//  tabs
	'FILTER_MODAL.TABS_CURRENCY': 'Valuta',
	'FILTER_MODAL.TABS_CUSTOMER': 'Cliente',
	'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'Stato abbonato',
	'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'Piano tariffario mensile',
	'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'Piano tariffario standard',
	'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'Termini prepagati addebitati',
	'FILTER_MODAL.TABS_RATE_PLAN': 'Piano tariffario',
	'FILTER_MODAL.TABS_PLAN_TYPE': 'Tipo di piano',
	'FILTER_MODAL.TABS_ZONE': 'Zona',
	'FILTER_MODAL.TABS_PRIORITY': 'Priorità',
	'FILTER_MODAL.TABS_ROAMING_ZONE': 'Zona roaming',
	'FILTER_MODAL.TABS_GROUP_NAME': 'Nome gruppo',
	'FILTER_MODAL.TABS_INVOICE_ID': 'ID fattura',
	'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'Tipo di piano',
	'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'Tipo attivazione',
	'FILTER_MODAL.TABS_CHARGE_TYPE': 'Tipo addebito',
	'FILTER_MODAL.TABS_PRIMARY_PLACE': 'Località primaria',
	'FILTER_MODAL.TABS_TECH_TYPE': 'Tipo tecnologia',
	'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'Località di utilizzo primaria',
	'FILTER_MODAL.TABS_STATE': 'Stato',
	'FILTER_MODAL.TABS_CITY': 'Città',
	'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'Contea distretto',
	'FILTER_MODAL.TABS_DISTRICT_CITY': 'Città distretto',
  'FILTER_MODAL.TABS_DESCRIPTION': 'Descrizione',
  //  **    AUTOMATION    **  //
  'FILTER_MODAL.PLATFORMS': 'PIATTAFORME',
  'FILTER_MODAL.TRIGGERS': 'ATTIVAZIONI',
  'FILTER_MODAL.M2MACCOUNTS': 'ACCOUNT M2M',
  'FILTER_MODAL.ACTIVE': 'ATTIVATO',
  'FILTER_MODAL.STATUSES': 'STATI',
  //  tabs
  'FILTER_MODAL.TABS_PLATFORMS': 'Piattaforme',
  'FILTER_MODAL.TABS_TRIGGERS': 'Attivazioni',
  'FILTER_MODAL.TABS_M2MACCOUNTS': 'Account M2M',
	'FILTER_MODAL.TABS_ACTIVE': 'Attivato',
	'FILTER_MODAL.TABS_USERGROUPS': 'Gruppi utenti',
  'FILTER_MODAL.USERGROUPS': 'Gruppi utenti',
  'FILTER_MODAL.TABS_STATUSES': 'Stati',
  // action bar
  'ACTION_BAR.PLATFORMS': 'Piattaforme',
  'ACTION_BAR.TRIGGERS': 'Attivazioni',
  'ACTION_BAR.M2MACCOUNTS': 'Account M2M',
  'ACTION_BAR.CURRENCY': 'Valuta',
  'ACTION_BAR.ACTIVE': 'Attivato',
	'ACTION_BAR.CUSTOMER': 'Cliente',
	'ACTION_BAR.SUBSCRIBER_STATUS': 'Stato abbonato',
	'ACTION_BAR.MONTHLY_RATE_PLAN': 'Piano tariffario mensile',
	'ACTION_BAR.STANDARD_RATE_PLAN': 'Piano tariffario standard',
	'ACTION_BAR.PREPAID_TERMS_CHARGED': 'Termini prepagati addebitati',
	'ACTION_BAR.RATE_PLAN': 'Piano tariffario',
	'ACTION_BAR.PLAN_TYPE': 'Tipo di piano',
	'ACTION_BAR.ZONE': 'Zona',
	'ACTION_BAR.PRIORITY': 'Priorità',
	'ACTION_BAR.ROAMING_ZONE': 'Zona roaming',
	'ACTION_BAR.GROUP_NAME': 'Nome gruppo',
	'ACTION_BAR.INVOICE_ID': 'ID fattura',
	'ACTION_BAR.THE_PLAN_TYPE': 'Tipo di piano',
	'ACTION_BAR.ACTIVATION_TYPE': 'Tipo attivazione',
	'ACTION_BAR.CHARGE_TYPE': 'Tipo addebito',
	'ACTION_BAR.PRIMARY_PLACE': 'Località primaria',
	'ACTION_BAR.TECH_TYPE': 'Tipo tecnologia',
	'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'Località di utilizzo primaria',
	'ACTION_BAR.STATE': 'Stato',
	'ACTION_BAR.CITY': 'Città',
	'ACTION_BAR.DISTRICT_COUNTY': 'Contea distretto',
	'ACTION_BAR.DISTRICT_CITY': 'Città distretto',
	'ACTION_BAR.DESCRIPTION': 'Descrizione',
  'ACTION_BAR.USERGROUPS': 'Gruppi utenti',
  'ACTION_BAR.STATUSES': 'Stati',
  // UPLOAD COMPONENT
	'UPLOAD_FILE.MAX_FILE_SIZE': 'Le dimensioni del file devono essere meno di {size}MB.',
	'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Tipo file errato',
	'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': 'Solo i seguenti formati possono essere caricati:',
	'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'File troppo grande',
	'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': 'Il file che si è cercato di caricare è troppo grande, i file devono essere inferiori a',
  'UPLOAD_FILE.ALLOWED_FORMATS': 'Formati consentiti: ',
	'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'È consentito un solo file'
};
