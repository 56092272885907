export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Endpoint {iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Revisar endpoint',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Direito de acesso à rede',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'ID do endpoint {args1} falhou no provisionamento depois da alteração do status de IMSI. Obter suporte',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE':
		'{step}. Selecionar estado do SIM',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. Agendar alteração do estado do SIM',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. Confirmação do sistema',
	'SINGLE_ENDPOINT.ICCID': 'ICCID',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': 'MSISDN',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'Status',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Atualizar endpoint para verificar se o estado foi alterado',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'Plataforma M2M',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Tipo de rede',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Operador de rede',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'País da rede',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'Data de M2M adicionada',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': 'Data de ativação de M2M',
	'SINGLE_ENDPOINT.IN_SESSION': 'Sessão em andamento',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Plano de tarifas',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Erro no plano de tarifas, entre em contato com o serviço de atendimento ao cliente.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Erro nos direitos de acesso à rede, entre em contato com serviço de atendimento ao cliente.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'Status da transferência de IMSI',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'País de roaming',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Nome da conta',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME':
		'Nome do direito de acesso à rede',
	'SINGLE_ENDPOINT.ROAMING': 'Roaming',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Data de criação',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Data da última atualização',
	'SINGLE_ENDPOINT.SMS_MO': 'MO de SMS',
	'SINGLE_ENDPOINT.SMS_MT': 'MT de SMS',
	'SINGLE_ENDPOINT.VOICE_MO': 'MO de voz',
	'SINGLE_ENDPOINT.VOICE_MT': 'MT de voz',
	'SINGLE_ENDPOINT.LTE_DATA': 'Dados de LTE',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'ID de roaming',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Tipo de serviço Camel',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Bloquear números Premium',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': 'Bloquear MO de SMS internacional',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'Modelo HLR',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'Voz internacional',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Número da linha direta',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Descrição',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'IP fixo',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'Não se aplica a este endpoint',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Nome do plano de tarifas',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Tipo do plano de tarifas',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Preço de assinaturas',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Cobrança da conta',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Tipo de pagamento',
	'SINGLE_ENDPOINT.TERM': 'Termo',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Limite de uso',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Sinalizador de camada',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID: {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD':
		'São necessárias permissões elevadas!',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Somente os administradores podem modificar os tipos de campos personalizados, porque esses campos afetam a configuração de todo o sistema. Entrar em contato com seu administrador para atualizar as opções dos campos personalizados.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID': 'Ações de endpoint para {endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. Selecionar estado de SIM',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'ID do endpoint',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'Há uma atividade agendada para este endpoint.',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'Tem certeza que deseja cancelar a alteração pendente?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT': 'Esta ação não pode ser desfeita.',
	'SINGLE_ENDPOINT.CARRIER': 'Operadora',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Recredenciada',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Nota',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'Usuário {uid} alterou status em {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Alteração do estado do SIM foi agendada com êxito para ',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'Atual estado do Sim para ID do endpoint {endpointId} é ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS':
		'Direitos qualificados de acesso à rede',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'IP fixo',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Atualmente atribuído para este endpoint',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS':
		'Detalhes do direito de acesso à rede',
	SELECT_MINIMUM_OF_2: 'Selecionar pelo menos dois para aplicar na tabela. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Iniciar diagnóstico',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Informações da operadora',
	'SINGLE_ENDPOINT.COUNTRY': 'País',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'Descrição do status da plataforma M2M',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'Vida ativa',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Novo inativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Suspensão ativa',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Parado inativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I': 'Migrado de outro ambiente GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'Não provisionado',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O': 'Migrado para outro ambiente GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Pronto ativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Suspender ativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Teste ativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'Em standby ativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Transformado inativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Suspender regulatório',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Encerrado',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'Indisponível',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Informação de provisionamento de endpoint',
	'SINGLE_ENDPOINT.PROVISIONED': 'Provisionado',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME':
		'Data e hora provisionadas {date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Conexões da rede',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Habilitado para voz',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Habilitado para dados',
	'SINGLE_ENDPOINT.SESSIONS': 'Sessões',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Inventário',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Ativado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Ativado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inativo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inativo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Desativado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': 'Aposentado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Limpo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Pronto para teste',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Data de ativação',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Substituído',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Avaliação',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Iniciar',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Transferir',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Global em origem de trânsito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Global em destino de trânsito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Global transferido',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Outro',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Passagem de tráfego permitida',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Passagem de tráfego não permitida',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Permitiu recursos sem fio TollSec',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Barrado da rede',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Tráfego não permitiu estado de SIM',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Suspenso pelo provedor de serviço',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Suspenso devido ao limite de uso',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Tráfego não permitido (outro)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Conectado à rede com êxito (em sessão)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'Não conectado à rede (em sessão)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Registro válido (atividade de rede válida)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Registro válido (sem atividade de rede)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Somente registro de GSM válido',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'Nenhum registro válido (apresentou atividade nas últimas 24 horas)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'Nenhum registro válido (fez solicitação de autorização nas últimas 24 horas)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'Nenhum registro válido (possui atualizações locais há mais de 24 horas)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'Nenhum registro válido (fez solicitação de autorização há mais de 24 horas)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'Há mais de 24 horas (sem atividade de rede)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'Dispositivo não está conectado no momento, Sessões IP anteriores bem-sucedidas.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Sessão de dados atual mal sucedida',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Sessão de dados passada bem-sucedida',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Falha na tentativa de conexão (credenciais inválidas)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'Nenhum histórico bem-sucedido da sessão de dados',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Permitiu recursos sem fio TollSec',
	'SINGLE_ENDPOINT.ACTIONS': 'Ações',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'Obter detalhes de HLR/HSS',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Taxa recorrente',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Selecionar plano de tarifas',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Confirmar mudanças',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. Confirmação do sistema',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Qualificado para usar com este endpoint',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		'A alteração do plano de tarifas foi atualizada com êxito',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'A alteração do plano de tarifas foi agendada com êxito para ',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'A alteração do plano de tarifas foi agendada com êxito',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Confirmar alteração de direitos de acesso à rede',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Depois de iniciar esta alteração, o direito de acesso à rede selecionado do endpoint será ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION':
		'Deseja continuar com esta ação?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Ou você pode agendar a alteração para: ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Alteração de direito de acesso à rede foi agendada com êxito.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Alteração de direito de acesso à rede foi agendada com êxito para',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'Alteração de direito de acesso à rede não pôde ser realizada neste momento.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'Alteração do direito de acesso à rede não pôde ser agendada neste momento.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'Status de alteração do direito de acesso à rede',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'Atual plano de tarifas para ID do endpoint {id} é ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Operadora de roaming',
	'SINGLE_ENDPOINT.OEM': 'Contas de assinatura',
	'SINGLE_ENDPOINT.SERVICE_STATUS': 'Status de 360L',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE: ',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Conexão da rede',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN: ',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'Status do registro',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Contas 360L vinculadas',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Modo dual',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Dispositivo conectado',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Plano de tarifas',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'Status registrado',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Data de ativação',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': 'Exportar dados de uso',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE':
		'Exportação dos dados falhou',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'Não é possível exportar dados de uso no momento.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Aguarde enquanto preparamos o arquivo para você. Dependendo do número de registros, pode demorar um pouco até terminarmos.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'Não há dados do período selecionado para mostrar.',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'Se disponível, tente alterar o intervalo de datas para atualizar a lista',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Ativado',
	'SINGLE_ENDPOINT.TERMINATE': 'Encerrado',
	'SINGLE_ENDPOINT.DEACTIVE': 'Desativo',
	'SINGLE_ENDPOINT.INACTIVE': 'Inativo',
	'SINGLE_ENDPOINT.TEST': 'Testar',
	'SINGLE_ENDPOINT.SUSPENDED': 'Suspenso',
	'SINGLE_ENDPOINT.SUSPEND': 'Suspender',
	'SINGLE_ENDPOINT.INVENTORY': 'Inventário'
};
