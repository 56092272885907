import { connect } from 'react-redux';

import { localizedRoute } from '../../../../utils/helperFunctions';
import EffectivePermissions from './EffectivePermissions';
import { getUserEffectivePermissions } from '../../redux/userGroupPermissions/actions';
import {
	getUserEffectivePermissionsRequest,
	getUserEffectivePermissionsSuccess
} from '../../redux/userGroupPermissions/selectors';

const mapStateToProps = (state) => ({
	permissionsRequest: getUserEffectivePermissionsRequest(state),
	permissions: getUserEffectivePermissionsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getPermissions: (params) => dispatch(getUserEffectivePermissions(params)),
	push: (url) => dispatch(localizedRoute(url))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EffectivePermissions);
