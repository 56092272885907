import React from 'react';
import { func, bool, object } from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import EditCompanyOverview from '../../components/EditCompanyOverview';

export default function EditCompanyOverviewView({
	company,
	isMyCompanyOverview,
	editCompany,
	getCompany,
	getCompanyRequest,
	editCompanySuccess,
	editCompanyRequest,
	editCompanyFail,
	onClose,
}) {
	return (
		<>
			<DesktopMediaQuery>
				<EditCompanyOverview
					company={company}
					isMyCompanyOverview={isMyCompanyOverview}
					editCompany={editCompany}
					getCompany={getCompany}
					getCompanyRequest={getCompanyRequest}
					editCompanyRequest={editCompanyRequest}
					editCompanySuccess={editCompanySuccess}
					editCompanyFail={editCompanyFail}
					onClose={onClose}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<EditCompanyOverview
					company={company}
					isMyCompanyOverview={isMyCompanyOverview}
					editCompany={editCompany}
					getCompany={getCompany}
					getCompanyRequest={getCompanyRequest}
					editCompanyRequest={editCompanyRequest}
					editCompanySuccess={editCompanySuccess}
					editCompanyFail={editCompanyFail}
					onClose={onClose}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<EditCompanyOverview
					company={company}
					isMyCompanyOverview={isMyCompanyOverview}
					editCompany={editCompany}
					getCompany={getCompany}
					getCompanyRequest={getCompanyRequest}
					editCompanyRequest={editCompanyRequest}
					editCompanySuccess={editCompanySuccess}
					editCompanyFail={editCompanyFail}
					onClose={onClose}
					isMobile
				/>
			</MobileMediaQuery>
		</>
	);
}

EditCompanyOverviewView.propTypes = {
	company: object,
	isMyCompanyOverview: bool,
	editCompany: func,
	getCompany: func,
	getCompanyRequest: bool,
	editCompanySuccess: bool,
	editCompanyRequest: bool,
	editCompanyFail: bool,
	onClose: func,
};

EditCompanyOverviewView.defaultProps = {
	company: {},
	isMyCompanyOverview: false,
	editCompany: undefined,
	getCompany: undefined,
	getCompanyRequest: false,
	editCompanySuccess: false,
	editCompanyRequest: false,
	editCompanyFail: false,
	onClose: undefined,
};
