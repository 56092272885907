import { findIndex } from 'lodash';

export const onChange = (e, category, item, callback, self) => {
	const index = findIndex(self.state.filterData[category], {
		value: item.value
	});
	item.checked = e.currentTarget.checked;
	if (!self.props.filterModalOpened) {
		self.setState(
			{
				filterData: {
					...self.state.filterData,
					[category]: [
						...self.state.filterData[category].slice(0, index),
						item,
						...self.state.filterData[category].slice(index + 1)
					]
				}
			},
			() => callback()
		);
	}
};

// export const createFilterData = (data, self) => {
// 	const filterDataKeys = Object.keys(data);

// 	let filter = {};
// 	filterDataKeys.length &&
// 		filterDataKeys.map((item) => {
// 			if (data[item].resultList) {
// 				filter = {
// 					...filter,
// 					[`${item}`]: data[item].resultList.map((item) => {
// 						return {
// 							value: item.code,
// 							label: `${item.name}`,
// 							checked: false
// 						};
// 					})
// 				};
// 			} else {
// 				filter = {
// 					...filter,
// 					[`${item}`]: data[item].map((item) => {
// 						return {
// 							value: item,
// 							label: `${item}`,
// 							checked: false
// 						};
// 					})
// 				};
// 			}
// 		});
// 	self.setState({
// 		filterData: filter,
// 		originalFilter: filter
// 	});
// };

export const createFilterData = (data) => {
	const filterDataKeys = Object.keys(data);

	let filter = {};
	filterDataKeys.length &&
		filterDataKeys.forEach((item) => {
			if (data[item].resultList) {
				filter = {
					...filter,
					[`${item}`]: data[item].resultList.map((filterItem) => ({
						value: filterItem.id,
						label: `${filterItem.name}`,
						checked: false
					}))
				};
			} else {
				filter = {
					...filter,
					[`${item}`]: data[item].map((filterItem) => ({
						value: filterItem,
						label: `${filterItem}`,
						checked: false
					}))
				};
			}
		});
	return filter;
};

export const onOrderClick = (data, callback, self) => {
	const { apiString, name, order } = data;
	self.setState(
		{
			orderColumn: {
				apiString,
				name,
				order: order === 'desc'
			}
		},
		() => {
			callback();
		}
	);
};

export const newOnPageChange = async (
	data,
	callback,
	dataLimit,
	setDataOffset,
	setSelectedPage
) => {
	const { selected } = data;
	await setDataOffset(dataLimit * selected);
	await setSelectedPage(selected);
	callback && callback();
};

export const onPageChange = (data, callback, self) => {
	const { selected } = data;
	const { dataLimit } = self.props;
	self.setState(
		{
			selectedPage: selected,
			dataOffset: dataLimit * selected
		},
		() => {
			callback();
		}
	);
};

export const filterHelpers = (filters, realProps) => {
	let searchParams = [];
	Object.keys(filters).forEach((key) => {
		const item = filters[key].filter((filterItem) => filterItem.checked);
		if (item.length) {
			const temp = {
				propValue: item.map((m) => m.value),
				prop: realProps[key],
				operator: 'in'
			};
			searchParams = [...searchParams, temp];
		}
	});
	return searchParams;
};

const getElements = (category, item, index, filterData, type) => {
	if (type === 'radio') {
		return [
			...filterData[category].map((m) => {
				if (m.value === item.value) {
					return m;
				}
				m.checked = false;
				return m;
			})
		];
	}
	return [
		...filterData[category].slice(0, index),
		item,
		...filterData[category].slice(index + 1)
	];
};

export const getNewFilterData = (e, category, item, filterData, type) => {
	const index = findIndex(filterData[category], {
		value: item.value
	});
	item.checked = !item.checked;
	const newFilterDataObject = {
		...filterData,
		[category]: getElements(category, item, index, filterData, type)
	};
	return newFilterDataObject;
};
