import { connect } from 'react-redux';
import EditSimSkusModal from './EditSimSkusModal';

import {
	addSimSkuRequest,
	editSimSkuRequest
} from '../../redux/simOrdering/selectors';

const mapStateToProps = (state) => ({
	addSimSkuRequest: addSimSkuRequest(state),
	editSimSkuRequest: editSimSkuRequest(state)
});

export default connect(mapStateToProps)(EditSimSkusModal);
