export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Categorie grafico',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Raggruppa i dati per',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filtri',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Opzioni grafico',
	'ANALYTICS.EDIT_CHART': 'Modifica grafico',
	'ANALYTICS.CREATE_NEW_CHART': 'Crea nuovo grafico',
	'ANALYTICS.DISCLAIMER': 'Esclusione di responsabilità: ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': 'I dati dei grafici sono forniti da più sistemi in vari intervalli di tempo. I dati nei grafici offriranno una somma totale approssimativa. I dati sono rappresentati in base all’ora UTC-0. I dati finali relativi ai servizi fatturati saranno disponibili sulla fattura per il ciclo di fatturazione.',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Totali conteggio endpoint',
	'ANALYTICS.NO_USAGE_SIMS': 'SIM senza utilizzo',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Utilizzo SMS totale',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Utilizzo dati totale',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Utilizzo vocale totale',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Stima utilizzo medio',
	'ANALYTICS.NO_CATEGORIES': 'Nessuna categoria disponibile per questo account',
	'ANALYTICS.LINE_GRAPH': 'Grafico lineare',
	'ANALYTICS.PIE_CHART': 'Grafico a torta',
	'ANALYTICS.BAR_GRAPH': 'Grafico a barre',
	'ANALYTICS.WORLD_MAP_GRAPH': 'Grafico mappa del mondo',
	'ANALYTICS.PLATFORM': 'Piattaforma',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Tecnologia di accesso',
	'ANALYTICS.OPERATOR': 'Operatore',
	'ANALYTICS.RATEPLAN': 'Piano tariffario',
	'ANALYTICS.COUNTRYCODE': 'Paese',
	'ANALYTICS.SIM_STATE': 'Stato SIM',
	'ANALYTICS.DATE_RANGE': 'INTERVALLO DATE',
	'ANALYTICS.TODAY': 'Oggi',
	'ANALYTICS.PREVIOUS_DAY': 'Giorno precedente',
	'ANALYTICS.LAST_10_DAYS': 'Ultimi 10 giorni',
	'ANALYTICS.LAST_30_DAYS': 'Ultimi 30 giorni',
	'ANALYTICS.CUSTOM_RANGE': 'Intervallo personalizzato',
	'ANALYTICS.CYCLE': 'CICLO',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Per il ciclo corrente fino alla data',
	'ANALYTICS.HISTORICAL': 'Cronologia',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'PIATTAFORMA M2M',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'INCREMENTO DI',
	'ANALYTICS.DAY': 'Giorno',
	'ANALYTICS.WEEK': 'Settimana',
	'ANALYTICS.MONTH': 'Mese',
	'ANALYTICS.SHOW': 'MOSTRA',
	'ANALYTICS.SUM_TOTALS': 'Totali somme',
	'ANALYTICS.AVERAGE_TOTALS': 'Totali medie',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': 'Selezionare una categoria durante la prima operazione',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': 'Nessun filtro disponibile per questa categoria',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Personalizza dati grafico',
	'ANALYTICS.EXPORT_CHART_DATA': 'Esporta dati grafico',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'Scarica PNG',
	'ANALYTICS.REMOVE_CHART': 'Rimuovi grafico',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Aggiungi grafico',
	'ANALYTICS.NO_ATTRIBUTES': 'Nessun raggruppamento per attributi disponibile per questo account',
	'ANALYTICS.MOVING_AVERAGE': 'Media mobile',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Mostra totali di riepilogo',
};
