import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import {
	trimInput,
	localizationHelper
} from '../../../../utils/helperFunctions';
import { maxLength, required } from '../../../../utils/validators';

import Dropzone from '../../../Shared/components/UploadFile';

import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './StepThree.scss';

const maxLength50 = maxLength(50);
const maxLength255 = maxLength(255);
const maxLength2000 = maxLength(2000);

const PlusIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/pluss-add-collapse.svg'
);

class StepThree extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false
		};
	}

	showDropzone = () => {
		const { show } = this.state;
		this.setState({
			show: !show
		});
	};

	validateUpload = (file) => file.name.length > 25;

	render() {
		const {
			handleSubmit,
			previousPage,
			filePending,
			confirmation,
			blur,
      invalid,
      pushBack
		} = this.props;

		const { show } = this.state;

		const trimValue = trimInput(blur);

		const triggerClass = cx({
			[styles.dropzone_trigger]: true,
			[styles.dropzone__active]: show
		});

		return (
			<form onSubmit={handleSubmit} data-spec="step-three">
				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<h5 className={styles.column_title}>
							<FormattedMessage
								id="TICKETING.ENDPOINT"
								defaultMessage="Endpoint"
							/>
						</h5>
					</div>
				</div>
				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="TICKETING.PLACEHOLDER_MODEL"
								defaultMessage="AT&amp;T Z431"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										name="endpointModel"
										placeholder={formattedValue}
										label={
											<FormattedMessage
												id="TICKETING.ENDPOINT_MODEL"
												defaultMessage="Endpoint model"
											/>
										}
										validate={[maxLength50]}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.field_label}>
							<FormattedMessage
								id="TICKETING.WAS_TROUBLESHOOTING_DONE"
								defaultMessage="Was troubleshooting done?"
							/>
						</div>

						<div className={styles.field_wrapper}>
							<Field
								component={Select}
								name="troubleshootingConfirmationId"
								options={
									confirmation &&
									confirmation.resultList.map((item) => ({
										value: item.id,
										label: (
											<FormattedMessage
												id={`TICKETING.${localizationHelper(item.name)}`}
												defaultMessage={item.name}
											/>
										)
									}))
								}
								cleanValue
							/>
						</div>
					</div>
				</div>
				<div className={styles.multiple_column_container}>
					<div className={styles.full_column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX"
								defaultMessage="Provide a short description of the problem. (255 characters maximum)"
							>
								{(formattedValue) => (
									<Field
										componentClass="textarea"
										component={Input}
										name="issue"
										label={
											<FormattedMessage
												id="TICKETING.PROBLEM_DESCRIPTION"
												defaultMessage="Problem description"
											/>
										}
										placeholder={formattedValue}
										validate={[maxLength255]}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>

						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX"
								defaultMessage="Provide additional issue details, if needed. (2000 characters maximum)"
							>
								{(formattedValue) => (
									<Field
										componentClass="textarea"
										component={Input}
										name="issueDetails"
										label={
											<FormattedMessage
												id="TICKETING.ISSUE_DETAILS"
												defaultMessage="Issue details"
											/>
										}
										placeholder={formattedValue}
										validate={[maxLength2000, required]}
										dataSpec="issue-details"
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
						<div onClick={this.showDropzone} className={styles.label_wrapper}>
							<span>
								<PlusIcon />
								<FormattedMessage
									id="TICKETING.ADD_ATTACHMENT"
									defaultMessage="Add Attachment"
								/>
							</span>
						</div>
						<div className={triggerClass}>
							<Dropzone
								service="ticketing"
								transactionType="basicTicketing"
								validateUpload={this.validateUpload}
								validationFailMessage={
									<FormattedMessage
										id="TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG"
										defaultMessage="File name is too long for upload. Maximum file name length is 25 characters."
									/>
								}
							/>
						</div>
					</div>
				</div>
				<div className={styles.buttons_wrapper}>
					<div className={styles.left_button}>
						<Button
							label={
								<FormattedMessage
									id="TICKETING.CANCEL_TICKET"
									defaultMessage="Cancel ticket"
								/>
							}
							variant="link"
							type="button"
							dataSpec="back-to-ticketing"
							onClick={pushBack}
						/>
					</div>
					<div className={styles.buttons_right}>
						<Button
							label={<FormattedMessage id="BACK" defaultMessage="Back" />}
							variant="outline-primary"
							type="button"
							dataSpec="previous"
							onClick={previousPage}
							disabled={filePending}
						/>
						<Button
							label={<FormattedMessage id="NEXT" defaultMessage="Next" />}
							variant="primary"
							type="submit"
							dataSpec="next"
							disabled={invalid || filePending}
						/>
					</div>
				</div>
			</form>
		);
	}
}

const { shape, func, bool, array } = PropTypes;

StepThree.propTypes = {
	handleSubmit: func,
	previousPage: func,
	filePending: bool,
	confirmation: shape({
		resultList: array
	}),
	invalid: bool,
  blur: func,
  pushBack: func
};

const StepThreeForm = reduxForm({
	form: 'addTicket',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(StepThree);

export default StepThreeForm;
