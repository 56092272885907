import ReleaseNotesView from '../views/ReleaseNotesView/ReleaseNotesView';
import ReleaseNotesEdit from '../components/ReleaseNotesEdit';
import {
	userIsAuthenticated,
	userInSupermode
} from '../../../utils/AuthSelector';

const releaseNotesRoutes = [
	{
		path: '/release-notes',
		exact: true,
		Component: ReleaseNotesView
	},
	{
		path: '/release-notes/add-release-notes',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated(['system.releasenotes_rw', 'system.releasenotes_ro'])(
				ReleaseNotesEdit
			)
		),
		customProps: { backUrl: '/release-notes' }
	},
	{
		path: '/release-notes/:id',
		exact: true,
		Component: userInSupermode(
			userIsAuthenticated(['system.releasenotes_rw'])(
				ReleaseNotesEdit
			)
		),
		customProps: { backUrl: '/release-notes' }
	}
];

export default releaseNotesRoutes;
