import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import {
	Field,
	reduxForm,
	FieldArray,
	change,
	arrayRemove,
	arrayInsert,
} from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { resetFields, getDateFormat } from '../../../../../utils/constants';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';

import Input from '../../../../../lib/DigitalComponents/FieldGroup';
import Select, {
	components,
} from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Switch from '../../../../../lib/DigitalComponents/Switch';
import Button from '../../../../../lib/DigitalComponents/Button';
import phonesList from '../../../../Ticketing/utils/countryAndPhoneCodes';
import Checkbox from '../../../../../lib/DigitalComponents/Checkbox';
import AddUserGroupModal from '../AddUserGroupModal';
import UploadImage from '../../UploadImage';

import PrivacyModal from '../../../../Eula/components/PrivacyModal';
import RemoveUserGroupModal from '../RemoveUserGroupModal';

import {
	required,
	onlyNumbers,
	maxLength,
} from '../../../../../utils/validators';

import hoc from '../EditUserProfile';
import styles from './Desktop.scss';
import {
	checkIfUserIsSystem,
	isUserAllowedToAccess,
} from '../../../../../utils/AuthSelector';
import { localizationHelper } from '../../../../../utils/helperFunctions';

const maxLength20 = maxLength(20);

const RemoveIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');
const UsFlag = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const France = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const Espana = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const Italia = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const Holland = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const Brazil = ImageSelector(CurrentContext.theme, 'svgs/brazil.svg');
const Deutschland = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const SimplifiedChinese = ImageSelector(CurrentContext.theme, 'svgs/china.svg');
const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');
const Eye = ImageSelector(CurrentContext.theme, 'svgs/eye.svg');
const Delete = ImageSelector(CurrentContext.theme, 'svgs/delete.svg');
const Danger = ImageSelector(CurrentContext.theme, 'svgs/alert.svg');
const UserImg = ImageSelector(
	CurrentContext.theme,
	'user_image_placeholder.png'
);

const validate = (values) => {
	const addressesErrors = new Array(
		(values.address && values.address.length) || 0
	);

	const addresses = values.address;

	if (addresses && addresses.length > 1) {
		addresses.forEach((address, aindex) => {
			const addressErrors = {};
			addresses.forEach((item, iindex) => {
				if (
					(item.addressTypeId === address.addressTypeId ||
						(address.addressTypeId &&
							address.addressTypeId.value &&
							address.addressTypeId.value === item.addressTypeId) ||
						(item.addressTypeId &&
							item.addressTypeId.value &&
							item.addressTypeId.value !== undefined &&
							address.addressTypeId &&
							address.addressTypeId.value &&
							address.addressTypeId.value !== undefined &&
							address.addressTypeId.value === item.addressTypeId.value)) &&
					aindex !== iindex
				) {
					addressErrors.addressTypeId = (
						<FormattedMessage
							id="ONBOARDING.ADDRESS_TYPE_EXISTS"
							defaultMessage="That Address Type Already Exists"
						/>
					);
					addressesErrors[aindex] = addressErrors;
				}
			});
		});
	}
	const address = addressesErrors;
	return { address };
};

const countryCallingCodes = sortBy(phonesList, 'value');

class Desktop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deletedUserGroups: [],
			eula: props.settings.pop(),
			privacyModalOpen: false,
			deleteProfileValue: '',
			removeUserGroupModalOpen: false,
			deletedUserAddresses: [],
		};
	}

	goToUserGroupPermissions = (userGroupId) => {
		const {
			push,
			match: {
				params: { id },
			},
			system,
			currentUser,
			myProfile,
		} = this.props;

		if (system) {
			push(
				`/access-management/users/${id}/user-groups/${userGroupId}/permissions`
			);
		} else if (myProfile || (myProfile && currentUser.systemUser)) {
			push(`/my-profile/user-groups/${userGroupId}/permissions`);
		} else {
			push(`/user/${id}/user-groups/${userGroupId}/permissions`);
		}
	};

	submitForm = (values) => {
		const { deletedUserGroups, deletedUserAddresses } = this.state;
		const { editUser, system, user, myProfile } = this.props;

		let data = {
			firstName: values.firstName,
			lastName: values.lastName,
			receivePhone1Sms: values.receivePhone1Sms,
			receivePhone2Sms: values.receivePhone2Sms,
		};

		if (myProfile && values.languageId) {
			data.languageId = values.languageId.value || values.languageId;
		}

		if (values.phone1 && values.mask) {
			data.phone1 = `${values.mask.value || values.mask}-${values.phone1}`;
		} else if (values.phone1 && !values.mask) {
			data.phone1 = `-${values.phone1}`;
		} else if (!values.phone1 && values.mask) {
			data.phone1 = `${values.mask.value || values.mask}-`;
		} else if (!values.phone1 && !values.mask) {
			data.phone1 = null;
		} else if (values.phone1 && values.phone1.length === 0) {
			data.phone1 = null;
		}

		if (values.phone2 && values.mask2) {
			data.phone2 = `${values.mask2.value || values.mask2}-${values.phone2}`;
		} else if (values.phone2 && !values.mask2) {
			data.phone2 = `-${values.phone2}`;
		} else if (!values.phone2 && values.mask2) {
			data.phone2 = `${values.mask2.value || values.mask2}-`;
		} else if (!values.phone2 && !values.mask2) {
			data.phone2 = null;
		} else if (values.phone2 && values.phone2.length === 0) {
			data.phone2 = null;
		}

		if (values.choosenUserGroup) {
			data.userGroupUserInsertRequest = [
				{
					userGroupId: values.choosenUserGroup.value,
				},
			];
		}

		if (user.address) {
			values.address.forEach((address) => {
				if (address.id) {
					let street2 = null;
					if (!data.addressesUpdateRequest) {
						data.addressesUpdateRequest = [];
					}
					if (address.street2) {
						street2 = address.street2.trim();
					}
					data.addressesUpdateRequest.push({
						id: address.id,
						city: address.city,
						street1: address.street1.trim(),
						street2,
						zipCode: address.zipCode,
						primary: address.primary,
						countryId:
							address.countryId && address.countryId.value
								? address.countryId.value
								: address.countryId,
						stateId:
							address.stateId && address.stateId.value
								? address.stateId.value
								: address.stateId,
						addressTypeId:
							address.addressTypeId && address.addressTypeId.value
								? address.addressTypeId.value
								: address.addressTypeId,
					});
				} else {
					if (!data.addressesInsertRequest) {
						data.addressesInsertRequest = [];
					}
					let street2 = null;
					if (address.street2) {
						street2 = address.street2.trim();
					}
					data.addressesInsertRequest.push({
						city: address.city,
						street1: address.street1.trim(),
						street2,
						zipCode: address.zipCode,
						primary: address.primary,
						countryId:
							address.countryId && address.countryId.value
								? address.countryId.value
								: address.countryId,
						stateId:
							address.stateId && address.stateId.value
								? address.stateId.value
								: address.stateId,
						addressTypeId:
							address.addressTypeId && address.addressTypeId.value
								? address.addressTypeId.value
								: address.addressTypeId,
					});
				}
			});
		}

		let userGroupUserDeleteRequest = [];
		let addressesDeleteRequest = [];

		if (deletedUserGroups && deletedUserGroups.length > 0) {
			deletedUserGroups.forEach((item) => {
				userGroupUserDeleteRequest = [
					...userGroupUserDeleteRequest,
					{
						userGroupId: item.UserGroupUser.userGroupId,
					},
				];
			});
		}

		if (userGroupUserDeleteRequest.length > 0) {
			data = {
				...data,
				userGroupUserDeleteRequest,
			};
		}

		if (deletedUserAddresses && deletedUserAddresses.length > 0) {
			deletedUserAddresses.forEach((item) => {
				addressesDeleteRequest = [
					...addressesDeleteRequest,
					{
						id: item.id,
					},
				];
			});
		}

		if (addressesDeleteRequest.length > 0) {
			data = {
				...data,
				addressesDeleteRequest,
			};
		}

		if (user.profilePicture.length === 0 && values.logo) {
			data.profilePictureInsertRequest = [
				{
					picture: values.logo,
				},
			];
		}

		if (user.profilePicture.length > 0 && values.logo) {
			data.profilePictureUpdateRequest = [
				{
					id: user.profilePicture[0].id,
					picture: values.logo,
				},
			];
		}

		editUser({ id: values.id, data, system, myProfile });
		this.setState({
			deletedUserGroups: [],
			deletedUserAddresses: [],
		});
  };

	deleteUserProfile = () => {
		const { deleteUserProfile, user } = this.props;
		const params = {
			user,
		};
		deleteUserProfile(params);
	};

	onChange = (e) => {
		this.setState({
			deleteProfileValue: e.target.value,
		});
	};

	changePrimaryAddress = async () => {
		const { formData, dispatch } = this.props;
		formData.address.forEach((item, idx) =>
			dispatch(change('EditUserProfileForm', `address[${idx}].primary`, false)));
	};

	getOptions = (addressIndex) => {
		const {
			firstAddressStates,
			secondAddressStates,
			thirdAddressStates,
		} = this.props;
		switch (addressIndex) {
			case 0:
				return (
					firstAddressStates &&
					firstAddressStates.resultList.map((item) => ({
						value: item.id,
						label: item.name,
					}))
				);
			case 1:
				return (
					secondAddressStates &&
					secondAddressStates.resultList.map((item) => ({
						value: item.id,
						label: item.name,
					}))
				);
			case 2:
				return (
					thirdAddressStates &&
					thirdAddressStates.resultList.map((item) => ({
						value: item.id,
						label: item.name,
					}))
				);
			// no default
		}
	};

	isRequired = (addressIndex) => {
		const {
			getFirstAddressStatesRequest,
			getSecondAddressStatesRequest,
			getThirdAddressStatesRequest,
		} = this.props;
		if (addressIndex === 0 && getFirstAddressStatesRequest) {
			return true;
		}
		if (addressIndex === 1 && getSecondAddressStatesRequest) {
			return true;
		}
		if (addressIndex === 2 && getThirdAddressStatesRequest) {
			return true;
		}
	};

	renderAddress = ({ fields }) => {
		const {
			countries,
			formData,
			addressTypes,
			getStates,
			dispatch,
		} = this.props;

		const { deletedUserAddresses } = this.state;

		const classnames = cn({
			[styles.icon_wrapper]: true,
			[styles.button_without_data]: fields && fields.length === 0,
			[styles.disabled]: fields && fields.length === 3,
		});
		return (
			<div data-spec="edit-user">
				{fields.map((address, index) => (
					<div className={styles.columns_holder} key={address}>
						<div className={styles.column}>
							<div className={cn(styles.field_wrapper, styles.required_field)}>
								<FormattedMessage
									id="ONBOARDING.ADDRESS_TYPE"
									defaultMessage="Address Type"
								>
									{(formattedValue) => (
										<Field
											component={Select}
											name={`${address}.addressTypeId`}
											placeholder={formattedValue}
											options={
												addressTypes &&
												addressTypes.resultList &&
												addressTypes.resultList.map((item) => ({
													value: item.id,
													label: (
														<FormattedMessage
															id={`ONBOARDING.${localizationHelper(
																item.name
															).toUpperCase()}`}
															defaultMessage={item.name}
														/>
													)
												}))
											}
											label={formattedValue}
											validate={[required]}
											disabled={!addressTypes}
											cleanValue
										/>
									)}
								</FormattedMessage>
							</div>

							<div className={styles.field_wrapper}>
								<label className={styles.label}>
									<FormattedMessage
										id="ONBOARDING.PRIMARY_ADDRESS"
										defaultMessage="Primary Address"
									/>
								</label>

								<Field
									component={Switch}
									name={`${address}.primary`}
									label={
										formData &&
										formData.address &&
										formData.address[index] &&
										formData.address[index].primary === true ? (
											<FormattedMessage id="YES" defaultMessage="Yes" />
										) : (
											<FormattedMessage id="NO" defaultMessage="No" />
										)
									}
									onChange={this.changePrimaryAddress}
								/>
							</div>
						</div>

						<div className={styles.column}>
							<div className={cn(styles.field_wrapper, styles.required_field)}>
								<FormattedMessage
									id="ONBOARDING.COUNTRY"
									defaultMessage="Country"
								>
									{(formattedValue) => (
										<Field
											component={Select}
											name={`${address}.countryId`}
											placeholder={formattedValue}
											options={
												countries &&
												countries.resultList &&
												countries.resultList.length > 0 &&
												countries.resultList.map((item) => ({
													value: item.id,
													label: item.name
												}))
											}
											label={formattedValue}
											onChange={(item) => {
												getStates(item.value || item, index);
												resetFields(dispatch, 'EditUserProfileForm', {
													[`${address}.stateId`]: ''
												});
											}}
											validate={[required]}
											cleanValue
											isSearchable
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={cn(styles.field_wrapper, styles.required_field)}>
								<FormattedMessage
									id="ONBOARDING.STREET_ADDRESS_1"
									defaultMessage="Street Address 1"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name={`${address}.street1`}
											label={formattedValue}
											data-spec="street-address-1"
											validate={[required]}
										/>
									)}
								</FormattedMessage>
							</div>

							<div className={cn(styles.field_wrapper, styles.required_field)}>
								<FormattedMessage
									id="ONBOARDING.CITY_OR_MUNICIPALITY"
									defaultMessage="City Or Municipality"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name={`${address}.city`}
											label={formattedValue}
											data-spec="city-or-municipality"
											validate={[required]}
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={cn(styles.field_wrapper, styles.required_field)}>
								<FormattedMessage
									id="ONBOARDING.POSTAL_CODE"
									defaultMessage="Postal Code"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name={`${address}.zipCode`}
											label={formattedValue}
											data-spec="postal-code"
											validate={[required]}
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.address_column_margin}>
								<div className={styles.field_wrapper}>
									<FormattedMessage
										id="ONBOARDING.STREET_ADDRESS_2"
										defaultMessage="Street Address 2"
									>
										{(formattedValue) => (
											<Field
												component={Input}
												placeholder={formattedValue}
												name={`${address}.street2`}
												label={formattedValue}
												data-spec="street-address-2"
											/>
										)}
									</FormattedMessage>
								</div>
								<div
									className={cn(styles.field_wrapper, styles.required_field)}
								>
									<FormattedMessage
										id="ONBOARDING.STATE_PROVINCE_TERRITORY_REGION"
										defaultMessage="State/Province/Territory/Region"
									>
										{(formattedValue) => (
											<Field
												component={Select}
												name={`${address}.stateId`}
												placeholder={formattedValue}
												options={this.getOptions(index)}
												label={formattedValue}
												loading={this.isRequired(index)}
												isDisabled={this.isRequired(index)}
												validate={[required]}
												isSearchable
												cleanValue
											/>
										)}
									</FormattedMessage>
								</div>
							</div>
						</div>
						<div className={styles.remove_icon_wrapper}>
							<div
								className={styles.icon_wrapper}
								onClick={() => {
									fields.remove(index);
									if (formData.address[index].id) {
										this.setState({
											deletedUserAddresses: [
												...deletedUserAddresses,
												fields.get(index)
											]
										});
									}
								}}
							>
								<RemoveIcon />
							</div>
						</div>
					</div>
				))}

				<span
					className={classnames}
					onClick={() => fields && fields.length < 3 && fields.push({})}
				>
					<AddIcon className={styles.addIconPlus} />
					{fields && fields.length === 0 ? (
						<FormattedMessage
							id="ONBOARDING.ADD_ADDRESS"
							defaultMessage="Add Address"
						/>
					) : (
						<FormattedMessage
							id="ONBOARDING.ADD_ANOTHER_ADDRESS"
							defaultMessage="Add Another Address"
						/>
					)}
				</span>
			</div>
		);
	};

	renderUserGroups = ({ fields }) => {
		const { formData, system, myProfile, currentUser } = this.props;
		const classnames = cn({
			[styles.assigned_accounts_table]: true,
			[styles.table_without_data]: fields && fields.length === 0,
		});
		const filteredUserGroups =
			formData &&
			formData.userGroup.filter((item) =>
				(system || (myProfile && currentUser.systemUser)
					? item.userGroupTypeId === 2
					: item.userGroupTypeId === 1));
		return (
			<div data-spec="edit-user">
				<table data-spec="table" className={classnames}>
					{filteredUserGroups && filteredUserGroups.length > 0 && (
						<thead>
							<tr>
								<th className={styles.accounts}>
									<FormattedMessage
										id="ONBOARDING.ASSIGNED_USER_GROUPS"
										defaultMessage="Assigned User Groups"
									/>
								</th>
								<th className={styles.allow_access}>
									<FormattedMessage
										id="ONBOARDING.ACTION"
										defaultMessage="Action"
									/>
								</th>
							</tr>
						</thead>
					)}
					<tbody>
						{filteredUserGroups &&
							filteredUserGroups.map((userGroup) => (
								<tr key={userGroup.id} data-spec="ug">
									<td>{userGroup.name}</td>
									<td>
										<FormattedMessage
											id="ONBOARDING.VIEW_USER_GROUP"
											defaultMessage="View User Group"
										>
											{(formattedValue) =>
												(checkIfUserIsSystem(currentUser)
													? isUserAllowedToAccess(
															[
																'system.accessmanagement.usergroups_rw',
																'system.accessmanagement.usergroups_ro',
															],
															currentUser
													  )
													: isUserAllowedToAccess(
															[
																'mnc.accessmanagement.usergroups_rw',
																'mnc.accessmanagement.usergroups_ro',
															],
															currentUser
													  )) && (
													<span>
														<Eye
															data-tip={formattedValue}
															data-for={`viewPermissionsTooltip_${userGroup.id}`}
															className={styles.pointer}
															onClick={() =>
																this.goToUserGroupPermissions(userGroup.id)}
														/>
														<ReactTooltip
															id={`viewPermissionsTooltip_${userGroup.id}`}
															className={styles.tooltip}
														/>
													</span>
												)}
										</FormattedMessage>
										<FormattedMessage
											id="ONBOARDING.DELETE_USER_GROUP"
											defaultMessage="Delete User Group"
										>
											{(formattedValue) => (
												<span>
													<Delete
														className={
															filteredUserGroups.length === 1 || myProfile
																? styles.disabled
																: styles.delete_icon
														}
														data-tip={formattedValue}
														data-for={`deletePermissionsTooltip_${userGroup.id}`}
														onClick={
															filteredUserGroups.length === 1 || myProfile
																? null
																: () => {
																		this.openRemoveUserGroupModal(
																			userGroup,
																			formData.userGroup.indexOf(userGroup)
																		);
																  }
														}
													/>
													<ReactTooltip
														id={`deletePermissionsTooltip_${userGroup.id}`}
														className={styles.tooltip}
													/>
												</span>
											)}
										</FormattedMessage>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		);
	};

	renderFlags = (data) => {
		switch (data.value) {
			case 1:
				return <UsFlag data-spec="USA" />;
			case 2:
				return <France data-spec="France" />;
			case 3:
				return <Espana data-spec="Espana" />;
			case 4:
				return <Italia data-spec="Italia" />;
			case 5:
				return <Holland data-spec="Holland" />;
			case 6:
				return <Deutschland data-spec="Deutschland" />;
			case 7:
				return <Brazil data-spec="Brazil" />;
			case 8:
				return <SimplifiedChinese data-spec="China" />;
			// no default
		}
	};

	option = (props) => {
		const { data } = props;
		return (
			<components.Option {...props} data-spec={data.value}>
				<div className={styles.option}>
					<span>{this.renderFlags(data)}</span>
					{data.label}
				</div>
			</components.Option>
		);
	};

	singleValue = (props) => {
		const { data } = props;
		return (
			<components.SingleValue {...props} data-spec={data.value}>
				<div className={styles.option}>
					<span>{this.renderFlags(data)}</span>
					{data.label}
				</div>
			</components.SingleValue>
		);
	};

	openAddUserGroupsModal = () => {
		const { openAddUserGroupModal } = this.props;
		openAddUserGroupModal();
	};

	updateLogo = (logoUrl) => {
		const { dispatch } = this.props;
		dispatch(change('EditUserProfileForm', 'logo', logoUrl));
	};

	openPrivacyModal = () =>
		this.setState({
			privacyModalOpen: true,
		});

	closePrivacyModal = () => this.setState({ privacyModalOpen: false });

	openRemoveUserGroupModal = (item, indexItem) => {
		const { deletedUserGroups } = this.state;
		const { dispatch } = this.props;
		dispatch(arrayRemove('EditUserProfileForm', 'userGroup', indexItem));
		this.setState({
			item,
			indexItem,
			removeUserGroupModalOpen: true,
			deletedUserGroups: [...deletedUserGroups, item],
		});
	};

	closeRemoveUserGroupModal = () => {
		const { dispatch } = this.props;
		const { item, indexItem } = this.state;
		dispatch(arrayInsert('EditUserProfileForm', 'userGroup', indexItem, item));
		this.setState({ deletedUserGroups: [], removeUserGroupModalOpen: false });
	};

	closeAddUserGroupModal = () => {
		const { dispatch, closeAddUserGroupModal } = this.props;
		dispatch(change('EditUserProfileForm', 'choosenUserGroup', null));
		closeAddUserGroupModal();
	};

	render() {
		const {
			handleSubmit,
			isAddUserGrupModalOpened,
			formData,
			invalid,
			system,
			user,
			pushBack,
			myProfile,
			dirty,
			currentUser,
			getLanguagesSuccess,
		} = this.props;

		const {
			eula,
			privacyModalOpen,
			deleteProfileValue,
			removeUserGroupModalOpen,
		} = this.state;

		const addUserGroupClasses = cn({
			[styles.icon_wrapper]: true,
			[styles.button_margin]: true,
			[styles.disabledRow]:
				(checkIfUserIsSystem(currentUser)
					? !isUserAllowedToAccess(
							[
								'system.accessmanagement.usergroups_rw',
								'system.accessmanagement.usergroups_ro',
							],
							currentUser
					  )
					: !isUserAllowedToAccess(
							[
								'mnc.accessmanagement.usergroups_rw',
								'mnc.accessmanagement.usergroups_ro',
							],
							currentUser
					  )) &&
				((system && !user.active) ||
					(!myProfile && !system && user.companyUserStatus.length > 0) ||
					myProfile),
		});

		return (
			<div data-spec="edit-user-profile-desktop">
				<form onSubmit={handleSubmit(this.submitForm)}>
					<div className={styles.content_block}>
						<p className={styles.section_title}>
							<FormattedMessage
								id="ONBOARDING.USER_INFORMATION"
								defaultMessage="User Information"
							/>
						</p>
						<div className={styles.columns_holder}>
							<div className={cn(styles.column, styles.center_text)}>
								<div>
									{myProfile ? (
										<UploadImage
											updateLogo={this.updateLogo}
											logo={
												user &&
												user.profilePicture.length &&
												user.profilePicture[0].picture
											}
										/>
									) : (
										<img
											src={
												user && user.profilePicture.length
													? user.profilePicture[0].picture
													: UserImg
											}
											alt="user-profile"
											className={styles.image_holder}
										/>
									)}
								</div>
							</div>
							<div className={styles.column}>
								<FormattedMessage
									id="ONBOARDING.FIRST_NAME"
									defaultMessage="First Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name="firstName"
											label={formattedValue}
											data-spec="first-name"
											validate={[maxLength20]}
										/>
									)}
								</FormattedMessage>
								<FormattedMessage
									id="ONBOARDING.COMPANY_EMAIL"
									defaultMessage="Company Email"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name="email"
											label={formattedValue}
											dataSpec="input-company-email"
											disabled
										/>
									)}
								</FormattedMessage>
								<label className={styles.label}>
									<FormattedMessage
										id="ONBOARDING.PRIMARY_NUMBER"
										defaultMessage="Primary Number"
									/>
								</label>
								<div className={styles.mask_field_wrapper}>
									<div className={styles.component_wrapper_left}>
										<Field
											component={Select}
											name="mask"
											options={countryCallingCodes.map((item) => ({
												label: item.value,
												value: item.value,
											}))}
											styles={{
												control: (base) => ({
													...base,
													minHeight: 30,
													borderRadius: '6px 0 0 6px !important',
													borderRight: '0 !important',
												}),
											}}
											isSearchable
											cleanValue
											isClearable
										/>
									</div>
									<div className={styles.component_wrapper_right}>
										<Field
											component={Input}
											data-spec="primaryNumber_1"
											name="phone1"
											placeholder={'205111111'}
											className={styles.custom_input}
											validate={[onlyNumbers]}
										/>
									</div>
								</div>
								<FormattedMessage
									id="ONBOARDING.CAN_RECEIVE_SMS"
									defaultMessage="Can Receive SMS"
								>
									{(formattedValue) => (
										<Field
											component={Checkbox}
											type="checkbox"
											name="receivePhone1Sms"
											label={formattedValue}
										/>
									)}
								</FormattedMessage>
							</div>
							<div className={styles.column}>
								<FormattedMessage
									id="ONBOARDING.LAST_NAME"
									defaultMessage="Last Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											name="lastName"
											label={formattedValue}
											data-spec="input-last-name"
											validate={[maxLength20]}
										/>
									)}
								</FormattedMessage>
								<Field
									component={Select}
									name="languageId"
									label={
										<FormattedMessage
											id="ONBOARDING.PREFERRED_LANGUAGE"
											defaultMessage="Preferred Language"
										/>
									}
									components={{
										Option: this.option,
										SingleValue: this.singleValue,
									}}
									options={
										getLanguagesSuccess &&
										getLanguagesSuccess.resultList &&
										getLanguagesSuccess.resultList.map((item) => ({
											value: item.id,
											label: item.name,
										}))
									}
									styles={{
										control: (base) => ({
											...base,
											minHeight: 36,
										}),
									}}
									cleanValue
									isDisabled={!myProfile}
								/>
								<label className={styles.label}>
									<FormattedMessage
										id="ONBOARDING.SECONDARY_NUMBER"
										defaultMessage="Secondary Number"
									/>
								</label>
								<div className={styles.mask_field_wrapper}>
									<div className={styles.component_wrapper_left}>
										<Field
											component={Select}
											name="mask2"
											options={countryCallingCodes.map((item) => ({
												label: item.value,
												value: item.value,
											}))}
											styles={{
												control: (base) => ({
													...base,
													minHeight: 30,
													borderRadius: '6px 0 0 6px !important',
													borderRight: '0 !important',
												}),
											}}
											isClearable
											cleanValue
											isSearchable
										/>
									</div>
									<div className={styles.component_wrapper_right}>
										<Field
											component={Input}
											data-spec="contact-phone"
											name="phone2"
											placeholder={'205111111'}
											className={styles.custom_input}
											validate={[onlyNumbers]}
										/>
									</div>
								</div>
								<FormattedMessage
									id="ONBOARDING.CAN_RECEIVE_SMS"
									defaultMessage="Can Receive SMS"
								>
									{(formattedValue) => (
										<Field
											type="checkbox"
											name="receivePhone2Sms"
											component={Checkbox}
											label={formattedValue}
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
						<p className={styles.section_title}>
							<FormattedMessage
								id="ONBOARDING.ADDRESSES"
								defaultMessage="Addresses"
							/>
						</p>

						<FieldArray name={'address'} component={this.renderAddress} />

						<p className={styles.section_title}>
							<FormattedMessage
								id="ONBOARDING.ACCESS_LEVEL"
								defaultMessage="Access Level"
							/>
						</p>

						<FieldArray name={'userGroup'} component={this.renderUserGroups} />

						{!myProfile && (
							<div>
								<span
									className={addUserGroupClasses}
									onClick={
										(checkIfUserIsSystem(currentUser)
											? !isUserAllowedToAccess(
													[
														'system.accessmanagement.usergroups_rw',
														'system.accessmanagement.usergroups_ro',
													],
													currentUser
											  )
											: !isUserAllowedToAccess(
													[
														'mnc.accessmanagement.usergroups_rw',
														'mnc.accessmanagement.usergroups_ro',
													],
													currentUser
											  )) &&
										((system && !user.active) ||
											(!myProfile &&
												!system &&
												user.companyUserStatus.length > 0) ||
											myProfile)
											? null
											: this.openAddUserGroupsModal
									}
								>
									<AddIcon className={styles.addIconPlus} />
									{formData &&
									formData.userGroup &&
									formData.userGroup.length === 0 ? (
										<FormattedMessage
											id="ONBOARDING.ADD_USER_GROUP"
											defaultMessage="Add User Group"
										/>
									) : (
										<FormattedMessage
											id="ONBOARDING.ADD_ANOTHER_USER_GROUP"
											defaultMessage="Add Another User Group"
										/>
									)}
								</span>
								<hr className={styles.horizontal_separator} />
							</div>
						)}
						{system || myProfile ? null : (
							<p className={styles.section_title}>
								<FormattedMessage
									id="ONBOARDING.ASSIGNED_ACCOUNTS"
									defaultMessage="Assigned Accounts"
								/>
							</p>
						)}
						<div className={styles.assigned_accounts_container}>
							{formData &&
								formData.userGroup &&
								formData.userGroup
									.filter((item) =>
										(system || (myProfile && currentUser.systemUser)
											? item.userGroupTypeId === 2
											: item.userGroupTypeId === 1))
									.map((group) =>
										(group.platform && group.platform.length === 0 ? (
											<div key={group.id}>
												<FormattedMessage
													id="ONBOARDING.UNRESTRICTED_ACCESS"
													defaultMessage="Unrestricted Access"
												/>
											</div>
										) : (
											group.platform &&
											group.platform.map((platform) => (
												<div
													className={styles.assigned_accounts}
													key={platform.m2mAccountName}
												>
													{platform.m2mAccountName}
												</div>
											))
										)))}
						</div>

						<p className={styles.section_title}>
							<FormattedMessage
								id="ONBOARDING.LEGAL_AND_REGULATORY"
								defaultMessage="Legal And Regulatory"
							/>
						</p>
						<div className={styles.legal_information}>
							<p>
								<FormattedMessage
									id="ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US"
									defaultMessage="User privacy is important to us, and we do not sell your user data to anyone."
								/>
							</p>
							<p>
								<FormattedMessage
									id="ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY"
									defaultMessage="EULA contains in-depth information about how we protect your privacy, right to errasure and other compliance regulations."
								/>
							</p>
							<p>
								<FormattedMessage
									id="ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE"
									defaultMessage={'{name} {lastName} accepted'}
									values={{
										name: user.firstName,
										lastName: user.lastName,
									}}
								/>
								&nbsp;
								<span
									className={styles.highlight_licence}
									onClick={() => this.openPrivacyModal()}
								>
									<FormattedMessage
										id="ONBOARDING.END_USER_LICENCE_AGREEMENT"
										defaultMessage="End-User Licence Agreement"
									/>
								</span>
								&nbsp;
								<FormattedMessage
									id="ONBOARDING.ON_DATE"
									defaultMessage={'on {date}.'}
									values={{
										date:
											eula && eula.createdAt && getDateFormat(eula.createdAt),
									}}
								/>
								&nbsp;
								<FormattedMessage
									id="ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT"
									defaultMessage="If you do not agree with the terms of EULA, you may delete this account."
								/>
							</p>
						</div>
						{myProfile && (
							<>
								<p className={styles.section_title}>
									<FormattedMessage
										id="ONBOARDING.DELETE_USERNAME_PROFILE"
										defaultMessage={'Delete {name} {lastName} Profil'}
										values={{
											name: user.firstName,
											lastName: user.lastName,
										}}
									/>
								</p>

								<div>
									<p className={styles.delete_profile}>
										<FormattedMessage
											id="ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS"
											defaultMessage="Deleting the profile will remove user access from all accounts you currently have access to."
										/>
										<br />
										<FormattedMessage
											id="ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN"
											defaultMessage="This action is irreversible, and any history of the user will be forgotten by our systems."
										/>
									</p>
									<div className={styles.delete_profile_section}>
										<div>
											<Danger className={styles.alert} />
										</div>
										<div className={styles.field_holder}>
											<div className={styles.message}>
												<FormattedMessage
													id="ONBOARDING.TYPE"
													defaultMessage="Type"
												/>
												&nbsp;
												<span className={styles.delete}>
													<FormattedMessage
														id="ONBOARDING.DELETE"
														defaultMessage="DELETE"
													/>
												</span>
												&nbsp;
												<FormattedMessage
													id="ONBOARDING.ENTER_CONFIRMATION_PHRASE"
													defaultMessage="Enter Confirmation Phrase"
												/>
											</div>
											<FormattedMessage
												id="ONBOARDING.ENTER_CONFIRMATION_PHRASE"
												defaultMessage="Enter Confirmation Phrase"
											>
												{(formattedValue) => (
													<Input
														placeholder={formattedValue}
														value={deleteProfileValue}
														onChange={this.onChange}
													/>
												)}
											</FormattedMessage>
											<FormattedMessage
												id="ONBOARDING.DELETE"
												defaultMessage="Delete"
											>
												{(formattedValue) => (
													<Button
														label={
															<FormattedMessage
																id="ONBOARDING.DELETE_PROFILE"
																defaultMessage="Delete Profile"
															/>
														}
														variant="primary"
														type="button"
														disabled={deleteProfileValue !== formattedValue.toUpperCase()}
														onClick={() => this.deleteUserProfile()}
													/>
												)}
											</FormattedMessage>
										</div>
									</div>
								</div>
							</>
						)}
						<div className={styles.button_wrapper}>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.CANCEL"
										defaultMessage="Cancel"
									/>
								}
								variant="link"
								type="button"
								onClick={pushBack}
							/>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.SAVE"
										defaultMessage="Save"
									/>
								}
								variant="primary"
								type="submit"
								data-spec="save"
								disabled={invalid || !dirty}
							/>
							{isAddUserGrupModalOpened && (
								<AddUserGroupModal
									show
									onClose={this.closeAddUserGroupModal}
									onSubmit={this.submitForm}
									formData={formData}
									system={system}
								/>
							)}
							<RemoveUserGroupModal
								show={removeUserGroupModalOpen}
								onClose={this.closeRemoveUserGroupModal}
								onSubmit={this.submitForm}
							/>
							<PrivacyModal
								show={privacyModalOpen}
								onClose={this.closePrivacyModal}
								userEula
							/>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const { object, shape, func, bool, arrayOf } = PropTypes;

Desktop.propTypes = {
	addressTypes: object,
	match: shape(),
	handleSubmit: func,
	dispatch: func,
	editUser: func,
	getStates: func,
	countries: object,
	getStatesRequest: bool,
	formData: object,
	user: shape(),
	openAddUserGroupModal: func,
	isAddUserGrupModalOpened: bool,
	closeAddUserGroupModal: func,
	push: func,
	invalid: bool,
	system: bool,
	pushBack: func,
	settings: arrayOf(object),
	myProfile: bool,
	currentUser: object,
	deleteUserProfile: func,
	dirty: bool,
	getFirstAddressStatesRequest: bool,
	firstAddressStates: object,
	getSecondAddressStatesRequest: bool,
	secondAddressStates: object,
	getThirdAddressStatesRequest: bool,
	thirdAddressStates: object,
	getLanguagesSuccess: object,
};

let EditUserProfileForm = reduxForm({
	form: 'EditUserProfileForm',
	enableReinitialize: true,
	validate,
})(Desktop);

EditUserProfileForm = connect((state, props) => {
	const fields = {
		...props.user,
	};

	if (props.user.phone1 && props.user.phone2) {
		const phoneMask = {
			...props.user,
			mask: props.user.phone1.substring(0, props.user.phone1.indexOf('-')),
			phone1: props.user.phone1.substring(
				props.user.phone1.lastIndexOf('-') + 1
			),
			mask2: props.user.phone2.substring(0, props.user.phone2.indexOf('-')),
			phone2: props.user.phone2.substring(
				props.user.phone2.lastIndexOf('-') + 1
			),
		};

		return {
			initialValues: phoneMask,
		};
	}

	if (props.user.phone1) {
		const phoneMask = {
			...props.user,
			mask: props.user.phone1.substring(0, props.user.phone1.indexOf('-')),
			phone1: props.user.phone1.substring(
				props.user.phone1.lastIndexOf('-') + 1
			),
		};

		return {
			initialValues: phoneMask,
		};
	}

	if (props.user.phone2) {
		const phoneMask = {
			...props.user,
			mask2: props.user.phone2.substring(0, props.user.phone2.indexOf('-')),
			phone2: props.user.phone2.substring(
				props.user.phone2.lastIndexOf('-') + 1
			),
		};

		return {
			initialValues: phoneMask,
		};
	}
	return { initialValues: fields };
})(EditUserProfileForm);

export default hoc()(EditUserProfileForm);
