import { Status } from '../../../../utils/constants';

export const getEndpointsRequest = (state) =>
	state.analyticsInvoices.tables.endpoints.getEndpoints.status ===
	Status.PENDING;
export const getEndpointsFail = (state) =>
	state.analyticsInvoices.tables.endpoints.getEndpoints.status === Status.FAIL;
export const getEndpointsSuccess = (state) =>
	state.analyticsInvoices.tables.endpoints.getEndpoints.data;

export const getEndpointsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.endpoints.getEndpointsFilters.status ===
	Status.PENDING;
export const getEndpointsFiltersFail = (state) =>
	state.analyticsInvoices.tables.endpoints.getEndpointsFilters.status ===
	Status.FAIL;
export const getEndpointsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.endpoints.getEndpointsFilters.data;
