import { combineReducers } from 'redux';
import {
  GET_ENDPOINTS,
  GET_ENDPOINTS_CUSTOM_LABELS,
	GET_M2M_ACCOUNT,
	GET_RATE_PLANS,
	GENERATE_REPORT,
	UPDATE_CUSTOM_LABELS
} from './constants';
import globalReducer from '../../../redux/global/globalReducer';

// fetch all endpoints
import pagination from './endpointsPagination/reducer';

import availableStates from './getAvailableStates/reducers';

//  update bulk network entitlement
import updateBulkNetworkEntitlement from './updateBulkNetworkEntitlement/reducer';

// update bulk endpoints
import updateBulkEndpoints from './updateBulkSingleEndpoints/reducer';

// Endpoints tabs
import endpointsTabs from './endpointsTabs/reducer';

// update bulk rate plans
import updateBulkRatePlan from './updateBulkRatePlan/reducer';

// update M2M account
import updateBulkm2mAccount from './updateBulkm2mAccount/reducer';

import updateBulkNotes from './updateBulkNotes/reducer';

export default combineReducers({
  getEndpoints: globalReducer(GET_ENDPOINTS),
  getCustomLabels: globalReducer(GET_ENDPOINTS_CUSTOM_LABELS),
	pagination,
	updateBulkNetworkEntitlement,
	availableStates,
	updateCustomLabels: globalReducer(UPDATE_CUSTOM_LABELS),
	updateBulkEndpoints,
	endpointsTabs,
	getRatePlans: globalReducer(GET_RATE_PLANS),
	updateBulkRatePlan,
	generateReport: globalReducer(GENERATE_REPORT),
	m2mAccount: globalReducer(GET_M2M_ACCOUNT),
	updateBulkm2mAccount,
	updateBulkNotes
});
