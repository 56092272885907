export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const GET_ACTIVE_NOTIFICATIONS = 'GET_ACTIVE_NOTIFICATIONS';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_GROUPS = 'GET_NOTIFICATIONS_GROUPS';
export const GET_NOTIFICATIONS_TYPES = 'GET_NOTIFICATIONS_TYPES';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_NOTIFICATION_MODAL = 'ADD_NOTIFICATION_MODAL';
export const REMOVE_ACTIVE_NOTIFICATION = 'REMOVE_ACTIVE_NOTIFICATION';
