export default {
	'SUPPORT.USER_GUIDE': 'User Guide',
	'SUPPORT.PRINT_PDF': 'Print PDF',
	'SUPPORT.SUPPORT': 'Support',
	'SUPPORT.FAQ': 'FAQ',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Find quick answers to frequently asked questions by our users.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Read detailed step-by-step instructions for portal functions. The guide is tailored to your account type.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Table of Contents',
	'SUPPORT.TICKETING': 'Ticketing',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'If you experience system outages or service issues with your endpoints and diagnostics tests do not provide the resolution you can create a trouble ticket with our Ops team. You can ask billing and service related questions as well.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Recent Notifications',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Create New Notification',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'View All Notifications',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Frequently Asked Questions',
	'SUPPORT.TOPICS': 'Topics',
	'SUPPORT.EDIT_NOTIFICATION': 'Edit Notification',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Global Notification',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': 'Company Related Notification',
	'SUPPORT.NOTIFICATION_TYPE': 'Notification Type?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER': 'Select Notification Placeholder',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Choose Which User Groups to Notify:',
	'SUPPORT.CONTENT': 'Content',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Add Notification Content',
	'SUPPORT.PUBLISH': 'Publish',
	'SUPPORT.GLOBAL': 'Global',
	'SUPPORT.COMPANY_RELATED': 'Company Related',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Confirmation',
	'SUPPORT.NOTIFICATION_INFO': 'Info',
	'SUPPORT.NOTIFICATION_WARNING': 'Warning',
	'SUPPORT.NOTIFICATION_ERROR': 'Error',
	'SUPPORT.SELECT_SECTION': 'Select Section',
	'SUPPORT.ALL': 'All',
	'SUPPORT.ADMIN': 'Admin',
	'SUPPORT.USER': 'User',
	'SUPPORT.FINANCE': 'Finance',
	'SUPPORT.OPERATIONS': 'Operations',
	'SUPPORT.RESTRICTEDACCESS': 'Restricted Access',
	'SUPPORT.COMPANYVIEW': 'Company View',
	'SUPPORT.COMPANYVIEWREADONLY': 'Company View Read Only',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Add New Release Notes',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'Document below is shown in U.S. English. Translation into other supported languages will be available in upcoming product releases.'
};
