import { connect } from 'react-redux';
import SearchBarDesktop from './Desktop';
import SearchBarMobile from './Mobile';

import {
	setSearchParameter,
	setSearchParameterInit,
	setSearchParameterProp,
	setSearchParameterPropInit
} from '../../redux/searchBar/actions';

import { setPaginationInit } from '../../redux/pagination/actions';

import { getSearchParameter } from '../../redux/searchBar/selectors';

const mapStateToProps = (state) => ({
  searchParameter: getSearchParameter(state),
});

const mapDispatchToProps = (dispatch) => ({
	setSearchParameter: (searchParameter) =>
		dispatch(setSearchParameter(searchParameter)),
	setSearchParameterInit: () => dispatch(setSearchParameterInit()),
	setPaginationInit: () => dispatch(setPaginationInit()),
	setSearchParameterProp: (prop) => dispatch(setSearchParameterProp(prop)),
	setSearchParameterPropInit: () => dispatch(setSearchParameterPropInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchBarDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchBarMobile);
