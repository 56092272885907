import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { releaseDetailType } from '../../../utils/constants';

import hoc from '../ReleaseNotes';

import styles from './Desktop.scss';

function Desktop({ releaseNotes }) {
	return (
		<div className={styles.content_wrapper} data-spec="release-notes-desktop">
			<main>
				<h3 className={styles.headline}>
					<FormattedMessage
						id="RELEASE_NOTES.KNOWN_ISSUES"
						defaultMessage="Known Issues"
					/>
				</h3>
				<article>
					{releaseNotes.releaseDetails
						.filter(
							(item) =>
								item.releaseDetailTypeId === releaseDetailType.KNOWN_ISSUES
						)
						.map((item) => (
							<div className={styles.known_issues} key={item.id}>
								<div>
									<span>{item.name}</span>
									<span
										className={cn(
											styles.issue_type,
											item.additionalDetailStatus.name.toLowerCase() ===
												'enhanced'
												? styles.enhanced
												: styles.fixed
										)}
									>
										{item.additionalDetailStatus.name}
									</span>
								</div>
								<div>
									<span>{item.description}</span>
								</div>
							</div>
						))}
				</article>

				<h3 className={styles.headline}>
					<FormattedMessage
						id="RELEASE_NOTES.NEW_FEATURES"
						defaultMessage="New Features"
					/>
				</h3>
				<article>
					<ul>
						{releaseNotes.releaseDetails
							.filter(
								(item) =>
									item.releaseDetailTypeId === releaseDetailType.NEW_FEATURES
							)
							.map((item) => (
								<li key={item.id}>{item.description}</li>
							))}
					</ul>
				</article>

				{/* START ATT ADMIN USER */}
				<h3 className={styles.headline}>
					<FormattedMessage
						id="RELEASE_NOTES.NEW_PLATFORM_FEATURES"
						defaultMessage="New Platform Features"
					/>
				</h3>
				<article>
					<ul>
						{releaseNotes.releaseDetails
							.filter(
								(item) =>
									item.releaseDetailTypeId ===
									releaseDetailType.NEW_PLATFORM_FEATURES
							)
							.map((item) => (
								<li key={item.id}>{item.description}</li>
							))}
					</ul>
				</article>
				{/* END ATT ADMIN USER */}

				<h3 className={styles.headline}>
					<FormattedMessage
						id="RELEASE_NOTES.IMPROVEMENTS"
						defaultMessage="Improvements"
					/>
				</h3>
				<article>
					<ul>
						{releaseNotes.releaseDetails
							.filter(
								(item) =>
									item.releaseDetailTypeId === releaseDetailType.IMPROVEMENTS
							)
							.map((item) => (
								<li key={item.id}>{item.description}</li>
							))}
					</ul>
				</article>

				<h3 className={styles.headline}>
					<FormattedMessage
						id="RELEASE_NOTES.DEPRECATION"
						defaultMessage="Deprecation"
					/>
				</h3>
				<article>
					<ul>
						{releaseNotes.releaseDetails
							.filter(
								(item) =>
									item.releaseDetailTypeId === releaseDetailType.DEPRECATION
							)
							.map((item) => (
								<li key={item.id}>{item.description}</li>
							))}
					</ul>
				</article>
			</main>
		</div>
	);
}

const { object } = PropTypes;

Desktop.propTypes = {
	releaseNotes: object
};

export default hoc()(Desktop);
