export default {
	'USER_FEEDBACK.TRIGGER_TITLE': 'Vous avez des commentaires ?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Faites-nous-en part ici',
	'USER_FEEDBACK.ADDITIONAL_FILE': 'Fichier supplémentaire',
	'USER_FEEDBACK.PAGE_LABEL': 'Vos commentaires concernent',
	'USER_FEEDBACK.FEED_LABEL': 'À votre avis, comment pouvons-nous améliorer les choses ?',
	'USER_FEEDBACK.FEED_PLACEHOLDER': 'Vos commentaires vont ici...',
	'USER_FEEDBACK.CONTACT_LABEL': 'Au besoin, pouvons-nous vous contacter à ce sujet ?',
	'USER_FEEDBACK.EMAIL_LABEL': 'E-mail',
	'USER_FEEDBACK.PHONE_LABEL': 'Téléphone',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Envoyer commentaires',
	'USER_FEEDBACK.ICON': 'Icône d’envoi réussi des commentaires',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Merci pour vos commentaires.',
	'USER_FEEDBACK.SUCCESS_MESSAGE': 'Vos commentaires permettront d’améliorer le produit. Nous évaluerons scrupuleusement vos commentaires et agirons en conséquence.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': 'Nous pourrions également vous contacter à propos de votre suggestion.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'L’équipe IoT Console',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Terminé',
	'USER_FEEDBACK.TITLE': 'Commentaires de l’utilisateur',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Échec de téléversement du fichier'
};
