import React, { PureComponent } from 'react';
import PropTypes, { shape } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import ReactTooltip from 'react-tooltip';
import FilterModal from '../../../Shared/components/FilterModal';
import AddUsersModal from '../AddUsersModal';
import RemoveUserModal from '../RemoveUserModal';
import PauseUserModal from '../PauseUserModal';
import ReactivateUserModal from '../ReactivateUserModal';
import ResetPasswordModal from '../ResetPasswordModal';
import AccountAccessListModal from '../ViewAccountAccessModal';
import ViewUserGroupsModal from '../ViewUserGroupsModal';
import ActionBar from '../../../Shared/views/ActionBarView';
import Button from '../../../../lib/DigitalComponents/Button';
import Select, {
	components
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Loading from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import {
	UsersTableHeader as tableHeader,
	UsersMobileHeader as mobileTableHeader,
	SystemUsersTableHeader as systemUsersTableHeader,
	userSearchMenu
} from '../../utils/constants';

import { filteredDomains } from '../../../../utils/constants';

import {
	onOrderClick,
	newOnPageChange,
	filterHelpers
} from '../../../../utils/tableHelpers';

import {
	getFilters as getUsersFiltersService,
	getCompanyUserGroupsFilters
} from '../../services/UsersService';

import styles from './Users.scss';

import {
	isUserAllowedToAccess,
	checkIfUserIsSystem
} from '../../../../utils/AuthSelector';

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg'
);

const Users = () => (WrappedComponent) => {
	class UsersComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				orderColumn: {},
				selectedUser: null,
				selectedAccount: null,
				isReactivateUserModalOpen: false,
				isResetPasswordModalOpen: false,
				isAccountAccessModalOpen: false,
				isViewGroupsModalOpen: false
			};
		}

		componentDidMount() {
			this.getUsers();
		}

		componentDidUpdate(prevProps) {
			const {
				pauseUser,
				reactivateUser,
				resetUserPassword,
				removeUser,
				inviteUsers
			} = this.props;

			if (
				prevProps.pauseUser !== pauseUser ||
				prevProps.reactivateUser !== reactivateUser ||
				prevProps.resetUserPassword !== resetUserPassword ||
				prevProps.removeUser !== removeUser ||
				prevProps.inviteUsers !== inviteUsers
			) {
				this.getUsers();
			}
		}

		getUsers = () => {
			const { orderColumn } = this.state;
			const {
				getUsers,
				system,
				filterData,
				searchParameter,
				dataLimit,
				dataOffset
			} = this.props;

			const realProps = {
				status: 'active',
				userGroups: 'userGroupId'
			};

			let simpleSearchParams = [];

			if (searchParameter) {
				simpleSearchParams = [
					{
						operator: 'iLike',
						prop: searchParameter.prop,
						propValue: `${searchParameter.propValue}%`
					}
				];
			}

			let filterParams = [];
			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}

			let tempApiString = '';
			if (orderColumn.name === 'status') {
				tempApiString = `active ${orderColumn.apiString.split(' ')[1]}`;
			} else {
				tempApiString = orderColumn.apiString;
			}

			const params = {
				searchParams: [...simpleSearchParams, ...filterParams],
				additionalParams: {
					dataLimit: dataLimit || 50,
					dataOffset,
					dataSort: tempApiString || 'createdAt desc'
				},
				system
			};
			getUsers(params);
		};

		goToEditUser = (id) => {
			const { goToEditUser, system } = this.props;
			if (system) {
				goToEditUser(`/access-management/users/${id}/edit`);
			} else {
				goToEditUser(`/user/${id}/edit`);
			}
		};

		pushTo = (id) => {
			const { pushTo, system } = this.props;
			if (system) {
				pushTo(`/access-management/users/${id}`);
			} else {
				pushTo(`/user/${id}`);
			}
		};

		option = (props) => {
			const { data } = props;
			const email = data && data.data && data.data.email;
			if (email) {
				const [, userDomain] = email.split('@');
				const attDomain = filteredDomains(userDomain);
				return (
					<components.Option {...props} data-spec={data.value}>
						{attDomain && data.value === 3 ? (
							<div className={styles.disabled}>{data.label}</div>
						) : (
							data.label
						)}
					</components.Option>
				);
			}
			return (
				<components.Option {...props} data-spec={data.value}>
					{data.label}
				</components.Option>
			);
		};

		renderAction = (data) => {
			const { user } = this.props;

			let options = [
				{
					value: 0,
					label: (
						<FormattedMessage id="ONBOARDING.EDIT" defaultMessage="Edit" />
					),
					data
				},
				{
					value: 2,
					label: (
						<FormattedMessage
							id="ONBOARDING.REACTIVATE"
							defaultMessage="Password Reset"
						/>
					),
					data
				},
				{
					value: 4,
					label: (
						<FormattedMessage id="ONBOARDING.REMOVE" defaultMessage="Remove" />
					),
					data
				}
			];

			if (data.active) {
				options = [
					{
						value: 0,
						label: (
							<FormattedMessage id="ONBOARDING.EDIT" defaultMessage="Edit" />
						),
						data
					},
					{
						value: 1,
						label: (
							<FormattedMessage id="ONBOARDING.PAUSE" defaultMessage="Pause" />
						),
						data
					},
					{
						value: 3,
						label: (
							<FormattedMessage
								id="ONBOARDING.PASSWORD_RESET"
								defaultMessage="Password Reset"
							/>
						),
						data
					}
				];
			}

			return (
				<div
					data-spec="order-status-wrapper"
					className={styles.order_status_select}
				>
					<FormattedMessage
						id="ONBOARDING.SELECT_ACTION"
						defaultMessage="Select Action"
					>
						{(formattedValue) => (
							<Select
								menuPosition="fixed"
								name="status"
								placeholder={formattedValue}
								options={options}
								onChange={this.selectOption}
								value={formattedValue}
								noBorder
								isDisabled={
									checkIfUserIsSystem(user)
										? !isUserAllowedToAccess(
												['system.accessmanagement.users_rw'],
												user
										  )
										: !isUserAllowedToAccess(
												['mnc.accessmanagement.users_rw'],
												user
										  )
								}
								components={{
									Option: this.option
								}}
							/>
						)}
					</FormattedMessage>
				</div>
			);
		};

		selectOption = (item) => {
			const [, userDomain] = item.data.email.split('@');
			const attDomain = filteredDomains(userDomain);
			switch (item.value) {
				case 0:
					this.goToEditUser(item.data.id);
					break;
				case 1:
					this.openPauseUserModal(item.data);
					break;
				case 2:
					this.openReactivateUserModal(item.data);
					break;
				case 3:
					if (attDomain) {
						break;
					} else {
						this.openResetPasswordModal(item.data);
						break;
					}
				case 4:
					this.openDeleteUserModal(item.data);
					break;
				// no default
			}
		};

		renderUserFirstName = (data) => (
			<div className={styles.highlight}>
				<span onClick={() => this.pushTo(data.id)}>{data.firstName}</span>
			</div>
		);

		renderUserLastName = (data) => (
			<div className={styles.lastName}>{data.lastName}</div>
		);

		renderEmail = (data) => (
			<div className={styles.email_wrap}>
				<a href={`mailto:${data.email}`}>{data.email}</a>
			</div>
		);

		renderUserGroup = (data) =>
			data &&
			data.userGroup &&
			data.userGroup.length > 0 && (
				<div>
					{data.userGroup[0].name}
					{data.userGroup.length < 2 ? null : (
						<div
							className={styles.highlight}
							onClick={() => this.openViewGroupModal(data.userGroup)}
						>
							<FormattedMessage
								id="ONBOARDING.AND_OTHERS"
								defaultMessage={'and {totalCount} others...'}
								values={{
									totalCount: data.userGroup.length - 1
								}}
								key={data.userGroup.id}
							/>
						</div>
					)}
				</div>
			);

		renderAccountAccess = (data) => (
			<div className={styles.assigned_accounts}>
				{data &&
					data.userGroup &&
					data.userGroup.length > 0 &&
					data.userGroup.map((group) =>
						(group.platform.length < 1 ? (
							<div key={group.id}>
								<FormattedMessage
									id="ONBOARDING.UNRESTRICTED_ACCESS"
									defaultMessage="Unrestricted Access"
								/>
							</div>
						) : (
							<div key={group.id}>
								{group.platform[0].m2mAccountName}
								{group.platform.length < 2 ? null : (
									<div
										className={styles.highlight}
										onClick={() => this.openAccountAccessModal(group.platform)}
									>
										<FormattedMessage
											id="ONBOARDING.AND_OTHERS"
											defaultMessage={'and {totalCount} others...'}
											values={{
												totalCount: group.platform.length - 1
											}}
										/>
									</div>
								)}
							</div>
						)))}
			</div>
		);

		renderStatus = (data) =>
			(data.active ? (
				<div className={cn(styles.status, styles.active)}>
					<FormattedMessage id={'ONBOARDING.ACTIVE'} defaultMessage="Active" />
				</div>
			) : (
				<div className={cn(styles.status, styles.paused)}>
					<FormattedMessage id={'ONBOARDING.PAUSED'} defaultMessage="Paused" />
				</div>
			));

		totalCount = () => {
			const { users, dataLimit } = this.props;
			return Math.ceil(users.totalCount / dataLimit);
		};

		onPageChange = async (data) => {
      const { setDataOffset, setSelectedPage, dataLimit } = this.props;
			const { selected } = data;
			await setDataOffset(dataLimit * selected);
			await setSelectedPage(selected);
			this.getUsers();
    }

		getOptions = (target) => {
			const { orderColumn } = this.state;
			const {
				dataLimit,
				selectedPage,
				setDataOffset,
				setSelectedPage,
				users,
				system
			} = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			options.tableOptions = {
				orderByData: ['all'],
				excludeFromOrdering: ['actions', 'userGroup', 'accountAccess'],
				showPagination: users && users.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				getOrderData: (data) => onOrderClick(data, this.getUsers, this),
				orderColumn,
				onPageChange: (data) =>
				newOnPageChange(
					data,
					this.getUsers,
					dataLimit,
					setDataOffset,
					setSelectedPage
				)
			};

			if (isMobile) {
				options.header = mobileTableHeader;
				options.customComponents = {
					firstName: {
						type: 'custom',
						component: (data) => this.renderUserFirstName(data)
					},
					status: {
						type: 'custom',
						component: (data) => this.renderStatus(data)
					}
				};
			}

			if (isDesktop) {
				options.customComponents = {
					actions: {
						type: 'custom',
						component: (data) => this.renderAction(data)
					},
					firstName: {
						type: 'custom',
						component: (data) => this.renderUserFirstName(data)
					},
					lastName: {
						type: 'custom',
						component: (data) => this.renderUserLastName(data)
					},
					email: {
						type: 'custom',
						component: (data) => this.renderEmail(data)
					},
					userGroup: {
						type: 'custom',
						component: (data) => this.renderUserGroup(data)
					},
					accountAccess: {
						type: 'custom',
						component: (data) => this.renderAccountAccess(data)
					},
					status: {
						type: 'custom',
						component: (data) => this.renderStatus(data)
					}
				};

				if (system) {
					options.header = systemUsersTableHeader;
				} else {
					options.header = tableHeader;
				}
			}

			return options;
		};

		onSearchSubmitCallback = () => {
			this.getUsers();
		};

		exportCsvFile = () => {
			this.exportUserUsageData();
    };

		createFilterData = (data) => {
			const { messages } = this.props;
			const filterDataKeys = Object.keys(data);
			let filter = {};
			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					filter = {
						...filter,
						[`${item}`]: data[item].resultList.map((filterItem) => {
							const lbl = filterItem.code || filterItem.name;
							return {
								value: filterItem.id,
								label: messages[`FILTER_VALUE_${lbl.toUpperCase()}`] || lbl,
								checked: false
							};
						})
					};
				});
			return filter;
		};

		clearFilters = () => {
			this.getUsers();
		};

		onApply = () => {
			this.getUsers();
		};

		onChange = () => {
			this.getUsers();
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		openUsersModal = () => {
			const { openAddUsersModal } = this.props;
			openAddUsersModal();
		};

		openDeleteUserModal = (data) => {
			const { openRemoveUserModal } = this.props;
			openRemoveUserModal();
			this.setState({
				selectedUser: data
			});
		};

		openPauseUserModal = (data) => {
			const { openPauseUserModal } = this.props;
			openPauseUserModal();
			this.setState({
				selectedUser: data
			});
		};

		openReactivateUserModal = (data) => {
			this.setState({
				isReactivateUserModalOpen: true,
				selectedUser: data
			});
		};

		closeReactivateUserModal = () => {
			this.setState({
				isReactivateUserModalOpen: false,
				selectedUser: null
			});
		};

		openResetPasswordModal = (data) => {
			this.setState({
				isResetPasswordModalOpen: true,
				selectedUser: data
			});
		};

		closeResetPasswordModal = () => {
			this.setState({
				isResetPasswordModalOpen: false,
				selectedUser: null
			});
		};

		openAccountAccessModal = (data) => {
			this.setState({
				isAccountAccessModalOpen: true,
				selectedAccount: data
			});
		};

		closeAccountAccessModal = () => {
			this.setState({
				isAccountAccessModalOpen: false,
				selectedAccount: null
			});
		};

		openViewGroupModal = (data) => {
			this.setState({
				isViewGroupsModalOpen: true,
				selectedAccount: data
			});
		};

		closeViewGroupModal = () => {
			this.setState({
				isViewGroupsModalOpen: false,
				selectedAccount: null
			});
		};

		exportUserUsageData = () => {
			const { exportUserUsageData } = this.props;
			exportUserUsageData();
		};

		render() {
			const {
				messages,
				closeAddUsersModal,
				isAddUsersModalOpened,
				closeRemoveUserModal,
				isRemoveUserModalOpened,
				isPauseUserModalOpened,
				closePauseUserModal,
				usersRequest,
				usersFail,
        users,
        removeUserRequest,
				system,
				dataLimit,
				selectedPage,
				getPauseUser,
				getRemoveUser,
				getReactivateUser,
				getPasswordResetUser,
				user,
				companyFriendlyName
			} = this.props;

			const {
				selectedUser,
				isReactivateUserModalOpen,
				isResetPasswordModalOpen,
				isAccountAccessModalOpen,
				selectedAccount,
				isViewGroupsModalOpen
			} = this.state;

			const params = {
				service: system ? getUsersFiltersService : getCompanyUserGroupsFilters,
				createFilterData: this.createFilterData
			};
			return (
				<div data-spec="users">
					<ActionBar
						menu={userSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						clearFilters={this.clearFilters}
						openFilterModal={this.openFilterModal}
						onChange={this.onChange}
						actions={
							<>
								<div>
									<div
										data-tip={messages.EXPORT_USER_USAGE_DATA || 'Export user usage data'}
										className={`${
										users && users.resultList.length > 0
										? styles.export_csv_icon
										: styles.disabled
										}`}
										data-for="createCustomReportTooltip"
									>
									<DocumentDownloadIcon onClick={
									users && users.resultList.length > 0
									? this.exportCsvFile
									: undefined
									}
									/>
									<ReactTooltip
										className={styles.tooltip}
										type="light"
										id="createCustomReportTooltip"
									/>
         </div>
        </div>
					<div>
							<Button
								variant="primary"
								label={
									<FormattedMessage
										id="ONBOARDING.ADD_USERS"
										defaultMessage="Add Users"
									/>
								}
								dataSpec="invite-users-button"
								onClick={this.openUsersModal}
								disabled={
									checkIfUserIsSystem(user)
										? !isUserAllowedToAccess(
											['system.accessmanagement.users_rw'],
											user
											)
											: !isUserAllowedToAccess(
											['mnc.accessmanagement.users_rw'],
											user
										)
									}
							/>
     </div>
       </>

						}
						showPagination
						totalCount={users && users.totalCount}
						selectedPage={selectedPage}
						dataLimit={dataLimit}
						isDisabled={usersRequest}
					/>

					{(usersRequest || removeUserRequest) && (
						<Loading data-spec="loading" />
					)}
					{usersFail && null}
					{!usersRequest && !removeUserRequest && users && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}

					{users && users.resultList && users.resultList.length === 0 && (
						<NoData
							title={
								<FormattedMessage
									id="ONBOARDING.NO_USERS_AVAILABLE"
									defaultMessage="No Users Available"
								/>
							}
							table
						/>
					)}

					<FilterModal
						type="radio"
						params={params}
						messages={messages}
						onApply={this.onApply}
					/>

					{isAddUsersModalOpened && (
						<AddUsersModal
							show
							system={system}
							messages={messages}
							onClose={closeAddUsersModal}
						/>
					)}

					{isRemoveUserModalOpened && (
						<RemoveUserModal
							show
							messages={messages}
							onClose={closeRemoveUserModal}
							system={system}
							data={selectedUser}
							removeUser={getRemoveUser}
						/>
					)}

					{isPauseUserModalOpened && (
						<PauseUserModal
							show
							messages={messages}
							onClose={closePauseUserModal}
							system={system}
							data={selectedUser}
							getPauseUser={getPauseUser}
							companyName={companyFriendlyName}
						/>
					)}

					{isReactivateUserModalOpen && (
						<ReactivateUserModal
							data={selectedUser}
							show
							onClose={this.closeReactivateUserModal}
							system={system}
							reactivateUser={getReactivateUser}
						/>
					)}

					{isResetPasswordModalOpen && (
						<ResetPasswordModal
							data={selectedUser}
							show
							onClose={this.closeResetPasswordModal}
							system={system}
							resetPassword={getPasswordResetUser}
						/>
					)}

					{isAccountAccessModalOpen && (
						<AccountAccessListModal
							show
							onClose={this.closeAccountAccessModal}
							data={selectedAccount}
						/>
					)}

					{isViewGroupsModalOpen && (
						<ViewUserGroupsModal
							show
							onClose={this.closeViewGroupModal}
							data={selectedAccount}
						/>
					)}
				</div>
			);
		}
	}
	const {
		func,
		objectOf,
		string,
		bool,
		object,
		number,
		oneOfType,
		array
	} = PropTypes;

	UsersComponent.propTypes = {
		openFilterModal: func,
		messages: objectOf(string),
		isAddUsersModalOpened: bool,
		openAddUsersModal: func,
		closeAddUsersModal: func,
		pushTo: func,
		goToEditUser: func,
		closeRemoveUserModal: func,
		isRemoveUserModalOpened: bool,
		openRemoveUserModal: func,
		openPauseUserModal: func,
		isPauseUserModalOpened: bool,
		closePauseUserModal: func,
		getUsers: func,
		usersRequest: bool,
		usersFail: bool,
    users: shape(),
    removeUserRequest: bool,
		simpleSearchStart: func,
		simpleSearchClose: func,
		getPauseUser: func,
		pauseUser: oneOfType([object, array]),
		reactivateUser: oneOfType([number, object]),
		getReactivateUser: func,
		resetUserPassword: object,
		getPasswordResetUser: func,
		dataLimit: number,
		push: func,
		removeUser: object,
		getRemoveUser: func,
		inviteUsers: bool,
		system: bool,
		filterData: shape(),
		user: object,
		companyFriendlyName: string,
		searchParameter: object,
		dataOffset: number,
		setDataOffset: func,
    setSelectedPage: func,
		setPaginationInit: func,
		selectedPage: number,
		exportUserUsageData: func,
	};

	return UsersComponent;
};

export default Users;
