import React from 'react';
import PropTypes from 'prop-types';

import hoc from '../UserGroups';
import Table from '../../../../../lib/DigitalComponents/Table';

const Desktop = (props) => {
	const { getOptions, messages, userGroups } = props;

	return (
		<div data-spec="user-groups-desktop">
			<Table
				data-spec="user-groups-table-desktop"
				data={userGroups.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { func, objectOf, string, shape } = PropTypes;

Desktop.propTypes = {
	getOptions: func,
	messages: objectOf(string),
	userGroups: shape()
};

export default hoc(Desktop);
