export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': 'Excluir configuração definida da tabela?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': 'Tem certeza de que deseja excluir a configuração {settingName}?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Gráficos atualmente disponíveis',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': 'Já existe este gráfico em suas configurações. Somente um tipo de gráfico é permitido.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'Não há descrição para este gráfico',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Tipo de gráfico',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Exportar dados da tabela',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Remover gráfico',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Configurações do gráfico',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Adicionar gráfico',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'Nova tabela adicionada com êxito',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Adição da nova tabela falhou',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'Tabela atualizada com êxito',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Atualização da tabela falhou',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Configuração da tabela excluída com êxito',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Excluir configuração da tabela falhou'
};
