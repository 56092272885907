import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import cx from 'classnames';

import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Button from '../../../../lib/DigitalComponents/Button';
import Switch from '../../../../lib/DigitalComponents/Switch';

import { localizationHelper } from '../../../../utils/helperFunctions';
import { resetFields } from '../../../../utils/constants';

import styles from './StepOne.scss';
import { required } from '../../../../utils/validators';

class StepOne extends Component {
	componentDidMount() {
		const {
			getCategories,
			getPriorities,
			getSeverities,
			clearUploadFiles
		} = this.props;

		getCategories({
			additionalParams: {
				dataSort: 'name ASC'
			}
		});

		getSeverities({
			additionalParams: {
				dataSort: 'name ASC'
			}
		});

		getPriorities({
			additionalParams: {
				dataSort: 'name ASC'
			}
		});

		clearUploadFiles();
	}

	getFirstSubCategory = (item) => {
		const { getFirstSubCategories } = this.props;
		getFirstSubCategories({
			searchParams: [
				{ propValue: item.value, prop: 'ticketCategoryId', operator: '=' }
			],
			additionalParams: {
				dataSort: 'name ASC'
			}
		});
	};

	getSecondSubCategory = (item) => {
		const { getSecondSubCategories } = this.props;
		getSecondSubCategories({
			searchParams: [
				{
					propValue: item.value,
					prop: 'ticketFirstSubCategoryId',
					operator: '='
				}
			],
			additionalParams: {
				dataSort: 'name ASC'
			}
		});
	};

	getThirdSubCategory = (item) => {
		const { getThirdSubCategories } = this.props;
		getThirdSubCategories({
			searchParams: [
				{
					propValue: item.value,
					prop: 'ticketSecondSubCategoryId',
					operator: '='
				}
			],
			additionalParams: {
				dataSort: 'name ASC'
			}
		});
	};

	render() {
		const {
			handleSubmit,
			categoriesRequest,
			categories,
			severitiesRequest,
			severities,
			firstSubCategoriesRequest,
			firstSubCategories,
			secondSubCategoriesRequest,
			secondSubCategories,
			thirdSubCategoriesRequest,
			thirdSubCategories,
			prioritiesRequest,
			priorities,
			iccid,
			type,
			ticketCategoryId,
			invalid,
			pushBack,
			formValues,
			dispatch
		} = this.props;

		return (
			<form onSubmit={handleSubmit} data-spec="step-one">
				<div className={styles.multiple_column_container}>
					{iccid && type ? (
						''
					) : (
						<div className={styles.ticketing_info}>
							<FormattedMessage
								id="TICKETING.ADD_TICKET_ADDITIONAL_INFO"
								defaultMessage="Is your ticket related to a specific endpoint? If so, go to that endpoint details screen and click Get support for this endpoint link. Otherwise, fill out the form below."
							/>
						</div>
					)}
				</div>
				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<h5 className={styles.column_title}>
							<FormattedMessage id="TICKETING.ISSUE" defaultMessage="Issue" />
						</h5>
						<div className={styles.field_wrapper}>
							<div className={styles.field_label}>
								<FormattedMessage
									id="TICKETING.CATEGORY"
									defaultMessage="Category"
								/>
							</div>
							<Field
								component={Select}
								isDisabled={!categories || categoriesRequest}
								name="ticketCategoryId"
								placeholder={
									<FormattedMessage
										id="TICKETING.SELECT_CATEGORY"
										defaultMessage="Select category"
									/>
								}
								options={
									categories &&
									categories.resultList.map((item) => ({
										value: item.id,
										label: (
											<FormattedMessage
												id={`TICKETING.CATEGORY_${localizationHelper(
													item.name
												).toUpperCase()}`}
												defaultMessage={item.name}
											/>
										)
									}))
								}
								onChange={(item) => {
									this.getFirstSubCategory(item);
									resetFields(dispatch, 'addTicket', {
										ticketFirstSubCategoryId: '',
										ticketSecondSubCategoryId: ''
									});
								}}
								validate={[required]}
								loading={categoriesRequest}
								cleanValue
							/>
						</div>
						<div
							className={cx({
								[styles.form_hidden]: true,
								[styles.form_active]: ticketCategoryId && ticketCategoryId.value
							})}
						>
							<div className={styles.field_wrapper}>
								<div className={styles.field_label}>
									<FormattedMessage
										id="TICKETING.SUBCATEGORY"
										defaultMessage="Subcategory"
									/>
								</div>
								<Field
									component={Select}
									name="ticketFirstSubCategoryId"
									placeholder={
										<FormattedMessage
											id="TICKETING.SUBCATEGORY"
											defaultMessage="Subcategory"
										/>
									}
									options={
										firstSubCategories &&
										firstSubCategories.resultList.map((item) => ({
											value: item.id,
											label: (
												<FormattedMessage
													id={`TICKETING.${localizationHelper(
														item.name
													).toUpperCase()}`}
													defaultMessage={item.name}
												/>
											)
										}))
									}
									onChange={(item) => {
										this.getSecondSubCategory(item);
										resetFields(dispatch, 'addTicket', {
											ticketSecondSubCategoryId: ''
										});
									}}
									loading={firstSubCategoriesRequest}
									cleanValue
									isDisabled={firstSubCategoriesRequest}
								/>
							</div>
							<div
								className={cx({
									[styles.form_hidden]: true,
									[styles.form_active]:
										formValues &&
										formValues.ticketFirstSubCategoryId &&
										formValues.ticketFirstSubCategoryId.value
								})}
							>
								<div className={styles.field_wrapper}>
									<div className={styles.field_label}>
										<FormattedMessage
											id="TICKETING.SUBCATEGORY_OPTIONAL"
											defaultMessage="Subcategory (optional)"
										/>
									</div>
									<Field
										component={Select}
										name="ticketSecondSubCategoryId"
										placeholder={
											<FormattedMessage
												id="TICKETING.SUBCATEGORY_OPTIONAL"
												defaultMessage="Subcategory (optional)"
											/>
										}
										options={
											secondSubCategories &&
											secondSubCategories.resultList.map((item) => ({
												value: item.id,
												label: (
													<FormattedMessage
														id={`TICKETING.${localizationHelper(
															item.name
														).toUpperCase()}`}
														defaultMessage={item.name}
													/>
												)
											}))
										}
										onChange={(item) => {
											this.getThirdSubCategory(item);
										}}
										loading={secondSubCategoriesRequest}
										cleanValue
										isDisabled={secondSubCategoriesRequest}
									/>
								</div>
							</div>
							<div
								className={cx({
									[styles.form_hidden]: true,
									[styles.form_active]:
										formValues &&
										formValues.ticketSecondSubCategoryId &&
										formValues.ticketSecondSubCategoryId.value
								})}
							>
								<div className={styles.field_wrapper}>
									<div className={styles.field_label}>
										<FormattedMessage
											id="TICKETING.SUBCATEGORY_OPTIONAL"
											defaultMessage="Subcategory (optional)"
										/>
									</div>
									<Field
										component={Select}
										name="ticketThirdSubCategoryId"
										placeholder={
											<FormattedMessage
												id="TICKETING.SUBCATEGORY_OPTIONAL"
												defaultMessage="Subcategory (optional)"
											/>
										}
										options={
											thirdSubCategories &&
											thirdSubCategories.resultList.map((item) => ({
												value: item.id,
												label: (
													<FormattedMessage
														id={`TICKETING.${localizationHelper(
															item.name
														).toUpperCase()}`}
														defaultMessage={item.name}
													/>
												)
											}))
										}
										loading={thirdSubCategoriesRequest}
										cleanValue
										isDisabled={thirdSubCategoriesRequest}
									/>
								</div>
							</div>
							<div className={styles.field_wrapper}>
								<div className={styles.field_label}>
									<FormattedMessage
										id="TICKETING.ROAMING_ISSUE"
										defaultMessage="Roaming issue"
									/>
								</div>
								<Field
									component={Switch}
									name="roamingIssue"
									label={
										formValues && formValues.roamingIssue === true ? (
											<FormattedMessage id="YES" defaultMessage="Yes" />
										) : (
											<FormattedMessage id="NO" defaultMessage="No" />
										)
									}
								/>
							</div>
							<div className={styles.field_wrapper}>
								<div className={styles.field_label}>
									<FormattedMessage
										id="TICKETING.SEVERITY"
										defaultMessage="Severity"
									/>
								</div>
								<Field
									component={Select}
									name="ticketSeverityId"
									placeholder={
										<FormattedMessage
											id="TICKETING.SEVERITY"
											defaultMessage="Severity"
										/>
									}
									options={
										severities &&
										severities.resultList.map((item) => ({
											value: item.id,
											label: (
												<FormattedMessage
													id={`TICKETING.${item.name.toUpperCase()}`}
													defaultMessage={item.name}
												/>
											)
										}))
									}
									validate={[required]}
									loading={severitiesRequest}
									cleanValue
									isDisabled={!severities}
								/>
							</div>
							<div className={styles.field_wrapper}>
								<div className={styles.field_label}>
									<FormattedMessage
										id="TICKETING.PRIORITY"
										defaultMessage="Priority"
									/>
								</div>
								<Field
									component={Select}
									name="ticketPriorityId"
									placeholder={
										<FormattedMessage
											id="TICKETING.PRIORITY"
											defaultMessage="Priority"
										/>
									}
									options={
										priorities &&
										priorities.resultList.map((item) => ({
											value: item.id,
											label: (
												<FormattedMessage
													id={`TICKETING.${item.name.toUpperCase()}`}
													defaultMessage={item.name}
												/>
											)
										}))
									}
									validate={[required]}
									loading={prioritiesRequest}
									cleanValue
									isDisabled={!priorities}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.buttons_wrapper}>
					<div className={styles.left_button}>
						<Button
							label={
								<FormattedMessage
									id="TICKETING.CANCEL_TICKET"
									defaultMessage="Cancel ticket"
								/>
							}
							variant="link"
							type="button"
							dataSpec="previous"
							onClick={pushBack}
						/>
					</div>
					<Button
						label={<FormattedMessage id="NEXT" defaultMessage="Next" />}
						variant="primary"
						type="submit"
						dataSpec="next"
						disabled={invalid}
					/>
				</div>
			</form>
		);
	}
}

const { func, string, bool, shape, array } = PropTypes;

StepOne.propTypes = {
	handleSubmit: func,
	getCategories: func,
	categoriesRequest: bool,
	categories: shape({
		resultList: array
	}),
	getSeverities: func,
	severitiesRequest: bool,
	severities: shape({
		resultList: array
	}),
	getFirstSubCategories: func,
	firstSubCategoriesRequest: bool,
	firstSubCategories: shape({
		resultList: array
	}),
	getSecondSubCategories: func,
	secondSubCategoriesRequest: bool,
	secondSubCategories: shape({
		resultList: array
	}),
	getThirdSubCategories: func,
	thirdSubCategoriesRequest: bool,
	thirdSubCategories: shape({
		resultList: array
	}),
	dispatch: func,
	ticketCategoryId: shape(),
	clearUploadFiles: func,
	getPriorities: func,
	prioritiesRequest: bool,
	priorities: shape({
		resultList: array
	}),
	iccid: string,
	type: string,
	invalid: bool,
	pushBack: func,
	formValues: shape({
		roamingIssue: bool
	})
};

let StepOneForm = reduxForm({
	form: 'addTicket',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
})(StepOne);

StepOneForm = connect((state, props) => {
	const user = {};
  let endpoint = {};

	if (props.user) {
		const userDetails = {
			uid: props.user.uid,
			companyName: props.user.company.companyFriendlyName,
			accountId: props.user.company.accountId
		};
		if (
			props.iccid &&
			state.singleEndpoint.getSingleEndpoint.data &&
			state.singleEndpoint.getSingleEndpoint.data.endpoint &&
			props.type
		) {
			if (props.type === 'satellite') {
				endpoint = {
					iccid:
						state.singleEndpoint.getSingleEndpoint.data.endpoint.modules[0].sim
							.iccid,
					msisdn:
						state.singleEndpoint.getSingleEndpoint.data.endpoint.modules[0].sim
							.msisdn,
					imsi:
						state.singleEndpoint.getSingleEndpoint.data.endpoint.modules[0].sim
							.imsi
				};
			} else if (props.type === 'cellular') {
				endpoint = {
					iccid: state.singleEndpoint.getSingleEndpoint.data.endpoint.iccid,
					msisdn: state.singleEndpoint.getSingleEndpoint.data.endpoint.msisdn,
					imsi: state.singleEndpoint.getSingleEndpoint.data.endpoint.imsi,
					networkEntitlementId:
						state.singleEndpoint.getSingleEndpoint.data.endpoint
							.networkEntitlementId
				};
			}
			return {
				initialValues: { userDetails, endpoint }
			};
		}
		if (!props.iccid) {
			return {
				initialValues: { userDetails }
			};
		}
	}
	return { user, endpoint };
})(StepOneForm);

const selector = formValueSelector('addTicket');
StepOneForm = connect((state) => {
	const ticketCategoryId = selector(state, 'ticketCategoryId');
	return {
		ticketCategoryId
	};
})(StepOneForm);

export default StepOneForm;
