import { connect } from 'react-redux';
import StepOne from './StepOne';

import { clearUploadErrors } from '../../../../redux/batchFiles/actions';

const mapDispatchToProps = (dispatch) => ({
	clearUploadErrors: () => dispatch(clearUploadErrors())
});

export default connect(
	null,
	mapDispatchToProps
)(StepOne);
