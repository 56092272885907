import { userIsAuthenticated, userInStandardMode } from '../../../utils/AuthSelector';
// Views
import EndpointsView from '../views/EndpointsView';

// Routes
export default [
		{
		path: '/endpoints',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.endpoints_rw', 'mnc.endpoints_ro'])(
				EndpointsView
			)
		)
	},
];
