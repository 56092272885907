import { connect } from 'react-redux';
import RequestFeatureModal from './RequestFeatureModal';

import { requestFeatureData } from '../../redux/blacklistedPermissions/selectors';
import { requestFeature } from '../../redux/blacklistedPermissions/actions';

const mapStateToProps = (state) => ({
	permission: requestFeatureData(state)
});

const mapDispatchToProps = (dispatch) => ({
	requestFeature: (params) => dispatch(requestFeature(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RequestFeatureModal);
