// /* global GLOBALS */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PrivacyModal from '../PrivacyModal';
import AuthService from '../../../../services/AuthService';

const eulaProps = {
	updateUserEula: PropTypes.func,
	isEulaUpdating: PropTypes.bool,
	isEulaUpdateFail: PropTypes.bool,
  isEulaUpdateSuccess: PropTypes.bool,
  user: PropTypes.object
};

export default class Eula extends PureComponent {
	constructor(props) {
		super(props);
		this.authService = new AuthService();

		this.state = {
			isStatusPromptModalOpen: true
		};
	}

	closePromptModal = () => {
		const { user } = this.props;
		if (user && user.systemUser) {
			sessionStorage.removeItem('company');
      sessionStorage.removeItem('company-token');
      const sessionLanguage = sessionStorage.getItem('language');
      window.location.href = `/${
        sessionLanguage || user.language || 'en-us'
        }`;
		} else {
			this.authService.logout();
		}
	};

	render() {
		const {
			updateUserEula,
			isEulaUpdating,
			isEulaUpdateFail,
			isEulaUpdateSuccess
		} = this.props;
		const { isStatusPromptModalOpen } = this.state;
		return (
			<div data-spec="eula">
				<PrivacyModal
					show={isStatusPromptModalOpen}
					onCancel={this.closePromptModal}
					updateUserEula={updateUserEula}
					isEulaUpdating={isEulaUpdating}
					isEulaUpdateFail={isEulaUpdateFail}
					isEulaUpdateSuccess={isEulaUpdateSuccess}
				/>
			</div>
		);
	}
}

Eula.propTypes = eulaProps;
