import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getActivations = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/activations?${qs.stringify(
			params.params
		)}`
	);

export const getActivationsFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/activations/filters?${qs.stringify(params.params)}`
	);
