import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const addRestraints = (data) =>
	ApiService.post('/onboarding/sim-orders/contract-restraints', data);

export const addSimOrder = (data) =>
	ApiService.post('onboarding/sim-orders/me', data);

export const editSimOrder = (params) =>
	ApiService.patch(`/onboarding/sim-orders/${params.id}`, params.data);

export const getPlatforms = ({ params, me }) =>
	ApiService.get(
		`/onboarding/platforms${me ? '/me' : ''}?${qs.stringify(params)}`
	);

export const getRestraintPeriods = () =>
	ApiService.get('/onboarding/sim-orders/contract-restraints/periods');

export const getRestraints = ({ params, companyId }) =>
	ApiService.get(
		`/onboarding/sim-orders/contract-restraints${
			companyId ? '' : '/me'
		}?${qs.stringify(params)}`
	);

export const getSimOrder = ({ id, params }) => {
	const query = {
		additionalParams: params.additionalParams,
	};
	if (params.system) {
		return ApiService.get(
			`/onboarding/sim-orders/${id}?${qs.stringify(query)}`
		);
	}
	return ApiService.get(`/onboarding/sim-orders/me/${id}?${qs.stringify(query)}`);
};

export const getSimOrderStatuses = () =>
	ApiService.get('/onboarding/sim-orders/statuses');

export const getSimOrderingFilters = async () => {
	const paramsSkus = {
		additionalParams: {
			dataLimit: 30,
		},
	};

	const calls = [
		ApiService.get('/onboarding/sim-orders/statuses'),
		ApiService.get('/onboarding/my-platform-type-overviews'),
		ApiService.get(
			`/onboarding/sim-orders/skus/me?${qs.stringify(paramsSkus)}`
		),
	];

	const response = await Promise.all(calls);
	const data = {
		filter_category_status: response[0].data,
		filter_category_platform: response[1].data,
		filter_category_sku: response[2].data,
		filter_category_expedited: {
			resultList: [
				{
					id: true,
					name: 'Yes',
				},
				{
					id: false,
					name: 'No',
				},
			],
			totalCount: 2,
		},
	};

	return { data };
};

export const getSimOrders = (params) => {
	const query = {
		additionalParams: params.additionalParams,
		searchParams: params.searchParams
	};
	if (params.system) {
		return ApiService.get(
			`/onboarding/sim-orders/overview?${qs.stringify(query)}`
		);
	}
	return ApiService.get(
		`/onboarding/sim-orders/overview/me?${qs.stringify(query)}`
	);
};

export const getSkus = ({ params, me }) =>
	ApiService.get(
		`/onboarding/sim-orders/skus${me ? '/me' : ''}?${qs.stringify(params)}`
	);

export const getUsedQuantity = (params) =>
	ApiService.get(`/onboarding/sim-orders/me?${qs.stringify(params)}`);

export const updateRestraints = (params) =>
	ApiService.patch(
		`/onboarding/sim-orders/contract-restraints/${params.id}`,
		params.data
	);

export const getSystemSimOrderingFilters = async () => {
	const paramsSkus = {
		additionalParams: {
			dataLimit: 30,
		},
	};

	const calls = [
		ApiService.get('/onboarding/sim-orders/statuses'),
		ApiService.get('/onboarding/platform-types'),
		ApiService.get(
			`/onboarding/sim-orders/skus?${qs.stringify(paramsSkus)}`
		)
	];

	const response = await Promise.all(calls);
	const data = {
		filter_category_status: response[0].data,
		filter_category_platform: response[1].data,
		filter_category_sku: response[2].data,
		filter_category_expedited: {
			resultList: [
				{
					id: true,
					name: 'Yes',
				},
				{
					id: false,
					name: 'No',
				},
			],
			totalCount: 2,
		}
	};

	return { data };
};
