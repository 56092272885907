import {
	userIsAuthenticated,
	userInStandardMode,
} from '../../../utils/AuthSelector';
import AnalyticInvoicesView from '../views/AnalyticsInvoices';
import InvoiceDetailView from '../views/InvoiceDetails';
import AddInvoice from '../components/AddInvoice';

const analyticsInvoicesRoutes = [
	{
		path: '/invoices',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.invoices_rw', 'mnc.invoices_ro'])(
				AnalyticInvoicesView
			)
		),
	},
	{
		path: '/invoices/:m2mAccountId/:billingCycle',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated([
				'mnc.invoices.details_rw',
				'mnc.invoices.details_ro',
			])(InvoiceDetailView)
		),
	},
	{
		path: '/invoices/add-invoice',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['system.uploadinvoice_rw'])(AddInvoice)
		),
	},
];

export default analyticsInvoicesRoutes;
