import React from 'react';
import hash from 'object-hash';
import { FormattedMessage } from 'react-intl';

export const header = [
	{
		title: 'DATE',
		name: 'date',
	},
	{
		title: 'TIME',
		name: 'time'
	},
	{
		title: 'CHANGED_BY',
		name: 'changedBy'
	},
	{
		title: 'USER_NAME',
		name: 'username'
	},
	{
		title: 'CHANGE',
		name: 'change'
	},
	{
		title: 'FROM_VALUE',
		name: 'changedFromValue'
	},
	{
		title: 'TO_VALUE',
		name: 'changedToValue'
	},
	{
		title: 'M2M_PLATFORM',
		name: 'm2mplatform'
	},
	{
		title: 'ACCOUNT_ID',
		name: 'accountid'
	}
];

export const firstEntitlementArray = (entitlement, renderInfo) => [
	{
		label: {
			id: 'ACCOUNT_NAME',
			defaultMessage: 'Account Name'
		},
		info: renderInfo(entitlement.accountInfo.platfrom),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'DESCRIPTION',
			defaultMessage: 'Description'
		},
		info: renderInfo(entitlement.onStateProfile.description),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'ROAMING',
			defaultMessage: 'Roaming'
		},
		info: renderInfo(entitlement.onStateProfile.roaming),
		grayedOutIfMissing: true
	},
	// {
	// 	label: {
	// 		id: 'CREATE_DATE',
	// 		defaultMessage: 'Create Date'
	// 	},
	// 	info: isValidDate(entitlement.timeStamp.createDate)
	// 		? renderInfo(getDateFormat(entitlement.timeStamp.createDate))
	// 		: undefined,
	// 	grayedOutIfMissing: true
	// },
	// {
	// 	label: {
	// 		id: 'LAST_UPDATE_DATE',
	// 		defaultMessage: 'Last Update Date'
	// 	},
	// 	info: isValidDate(entitlement.timeStamp.lastUpdateDate)
	// 		? renderInfo(getDateFormat(entitlement.timeStamp.lastUpdateDate))
	// 		: undefined,
	// 	grayedOutIfMissing: true
	// }
];

export const secondEntitlementArray = (entitlement, renderInfo) => [
	{
		label: {
			id: 'SMS_MT',
			defaultMessage: 'SMS MT'
		},
		info: renderInfo(entitlement.onStateProfile.smsMT),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'SMS_MO',
			defaultMessage: 'SMS MO'
		},
		info: renderInfo(entitlement.onStateProfile.smsMO),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'VOICE_MO',
			defaultMessage: 'Voice MO'
		},
		info: renderInfo(entitlement.onStateProfile.voiceMO),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'VOICE_MT',
			defaultMessage: 'Voice MT'
		},
		info: renderInfo(entitlement.onStateProfile.voiceMT),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'INTERNATIONAL_VOICE',
			defaultMessage: 'International Voice'
		},
		info: renderInfo(entitlement.onStateProfile.internationalVoice),
		grayedOutIfMissing: true
	}
];

export const thirdEntitlementArray = (entitlement, renderInfo) => [
	{
		label: {
			id: 'LTE_DATA',
			defaultMessage: 'LTE Data'
		},
		info: renderInfo(entitlement.onStateProfile.dataLTE),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'LTE_ROAMING_RESTRICT_SET_ID',
			defaultMessage: 'Roaming LTE'
		},
		info: renderInfo(entitlement.onStateProfile.roamingLTE),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'CAMEL_SUBSCRIPTION_INFO',
			defaultMessage: 'Camel'
		},
		info: renderInfo(entitlement.onStateProfile.camel),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE',
			defaultMessage: 'Camel'
		},
		info: renderInfo(entitlement.onStateProfile.camelServiceType),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'M2M_PLATFORM',
			defaultMessage: 'M2M Platform'
		},
		info: renderInfo(entitlement.accountInfo.platform),
		grayedOutIfMissing: true
	}
];

export const fourthEntitlementArray = (entitlement, renderInfo) => [
	{
		label: {
			id: 'BLOCK_PREMIUM_NUMBERS',
			defaultMessage: 'Block Premium Numbers'
		},
		info: renderInfo(entitlement.onStateProfile.blockPremiumNumbers),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'BLOCK_INTERNATIONAL_MO',
			defaultMessage: 'Block International MO'
		},
		info: renderInfo(entitlement.onStateProfile.blockInternationalMO),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'FACETIME',
			defaultMessage: 'Facetime'
		},
		info: renderInfo(entitlement.onStateProfile.facetime),
		grayedOutIfMissing: true
	}
];

export const fifthEntitlementArray = (entitlement, renderInfo) => [
	{
		label: {
			id: 'HLR_TEMPLATE',
			defaultMessage: 'HLR Template'
		},
		info: renderInfo(entitlement.onStateProfile.hlrTemplate),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'HOTLINE_NUMBER',
			defaultMessage: 'Hotline Number'
		},
		info: renderInfo(entitlement.onStateProfile.hotlineNumber),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'GPRS',
			defaultMessage: 'GPRS'
		},
		info: renderInfo(entitlement.onStateProfile.data),
		grayedOutIfMissing: true
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.APNS_FIXED_IP',
			defaultMessage: 'Fixed IP'
		},
		info: renderInfo(
			entitlement &&
				entitlement.onStateProfile &&
				entitlement.onStateProfile.apns &&
				entitlement.onStateProfile.apns.length > 0
				? entitlement.onStateProfile.apns[0].fixedIP
				: undefined
		),
		grayedOutIfMissing: true
	}
];

export const ratePlanTableHeaders = [
	{
		title: 'RATE_PLAN_ID_PLAN',
		name: 'id',
		key: 1,
		defaultMessage: 'Rate Plan ID',
	},
	{
		title: 'RATE_PLAN_NAME',
		name: 'name',
		key: 2,
		defaultMessage: 'Rate Plan Name',
	},
	{
		title: 'ACCOUNT_ID',
		name: 'm2mAccountId',
		key: 3,
		defaultMessage: 'Account ID'
	},
	{
		title: 'RATE_PLAN_TYPE',
		name: 'type',
		key: 4,
		defaultMessage: 'Rate Plan Type'
	},
	// {title: 'CURRENCY', name: 'currency', key: 5},
	{
		title: 'SUBSCRIPTION_PRICE',
		name: 'subscriptionPrice',
		key: 6,
		defaultMessage: 'Subscription Price'
	},
	{
		title: 'ACCOUNT_CHARGE',
		name: 'accountCharge',
		key: 7,
		defaultMessage: 'Account Charge'
	},
	{
		title: 'PAYMENT_TYPE',
		name: 'paymentType',
		key: 8,
		defaultMessage: 'Payment Type',
	},
	{ title: 'TERM', name: 'term', key: 9, defaultMessage: 'Term' },
	{
		title: 'USAGE_LIMIT',
		name: 'usageLimit',
		key: 10,
		defaultMessage: 'Usage Limit'
	},
	{
		title: 'TIERING_FLAG',
		name: 'tieringFlag',
		key: 11,
		defaultMessage: 'Tiering Flag',
	}
	// {title: 'PER_SUBSCRIBER_MRC', name: 'perSubscriberMRC', key: 12},
	// {title: 'INCLUDED_DATA_USAGE', name: 'includedDataUsage', key: 13},
	// {title: 'DEFAULT', name: 'default', key: 14}
];

export const endpointUsageDataHeaders = {
	data: [
		{
			title: 'OFFPEAK_USAGE_FLAG',
			name: 'offpeak_usage_flag',
			checked: false,
		},
		{
			title: 'DATA_USAGE_TOTAL',
			name: 'data_usage',
			checked: true,
		},
		{
			title: 'DATA_USAGE_UPLINK',
			name: 'data_usage_uplink',
			checked: true,
		},
		{
			title: 'DATA_USAGE_DOWNLINK',
			name: 'data_usage_downlink',
			checked: true,
		},
		{
			title: 'DATA_USAGE_ROUNDED',
			name: 'data_usage_rounded',
			checked: true,
		},
		{
			title: 'APN',
			name: 'apn',
			checked: true,
		},
		{
			title: 'DEVICE_IP_ADDRESS',
			name: 'device_ip_address',
			checked: true,
		},
		{
			title: 'SESSION_DURATION',
			name: 'session_duration',
			checked: true,
		},
		{
			title: 'RECORD_SEQUENCE_NUMBER',
			name: 'record_sequence_number',
			checked: false,
		},
		{
			title: 'CHARGING_ID',
			name: 'charging_id',
			checked: false,
		},
		{
			title: 'SESSION_CLOSE_CAUSE',
			name: 'session_close_cause',
			checked: true,
		},
		{
			title: 'TECH_TYPE',
			name: 'call_technology_type',
			checked: false,
		},
		{
			title: 'STREAM_ID',
			name: 'stream_id',
			checked: true,
		},
		{
			title: 'SERVING_SGSN',
			name: 'serving_sgsn',
			checked: false,
		},
		{
			title: 'COUNTRY_LASTEST_SESSION',
			name: 'country',
			checked: true,
		},
		{
			title: 'CARRIER_LASTEST_SESSION',
			name: 'carrier',
			checked: true,
		},
		{
			title: 'SIM_STATE',
			name: 'sim_state',
			checked: true,
		},
		{
			title: 'BILLABLE_FLAG',
			name: 'billable_flag',
			checked: true,
		},
		{
			title: 'BILLING_CYCLE',
			name: 'billing_cycle',
			checked: true,
		},
		{
			title: 'CYCLE_MONTH',
			name: 'cycle_month',
			checked: false,
		},
		{
			title: 'CYCLE_YEAR',
			name: 'cycle_year',
			checked: false,
		},
		{
			title: 'ASSIGNED_RATE_PLAN_ID',
			name: 'rate_plan_id',
			checked: true,
		},
		{
			title: 'ASSIGNED_RATING_ZONE_ID',
			name: 'rating_zone_id',
			checked: true,
		},
		{
			title: 'RATE_PLAN_VERSION_ID',
			name: 'rate_plan_version_id',
			checked: true,
		},
		{
			title: 'SERVICE_TYPE',
			name: 'service_type',
			checked: true,
		},
		{
			title: 'TRAFFIC_DETAIL_ID',
			name: 'traffic_detail_id',
			checked: true,
		},
		{
			title: 'RECORD_RECEIVED_DATE',
			name: 'record_received_date',
			checked: false,
		},
		{
			title: 'RECORD_OPEN_TIME',
			name: 'record_open_time',
			checked: false,
		},
		{
			title: 'OPERATOR_NETWORK',
			name: 'operator_network',
			checked: true,
		},
		{
			title: 'OPERATOR_ACCOUNT_ID',
			name: 'operator_account_id',
			checked: false,
		},
		{
			title: 'TAP_CODE',
			name: 'tap_code',
			checked: false,
		},
	],
	sms: [
		{
			title: 'FROM',
			name: 'from',
			checked: true,
		},
		{
			title: 'TO',
			name: 'to',
			checked: true,
		},
		{
			title: 'ICCID',
			name: 'iccid',
			checked: true,
		},
		{
			title: 'MSISDN',
			name: 'msisdn',
			checked: true,
		},
		{
			title: 'IMSI',
			name: 'imsi',
			checked: true,
		},
		{
			title: 'SIM_STATE',
			name: 'sim_state',
			checked: false,
		},
		{
			title: 'BILLABLE_FLAG',
			name: 'billable_flag',
			checked: false,
		},
		{
			title: 'BILLING_CYCLE',
			name: 'billing_cycle',
			checked: false,
		},
		{
			title: 'ASSIGNED_RATE_PLAN_ID',
			name: 'rate_plan_id',
			checked: false,
		},
		{
			title: 'ASSIGNED_RATING_ZONE_ID',
			name: 'rating_zone_id',
			checked: false,
		},
		{
			title: 'SERVICE_TYPE',
			name: 'service_type',
			checked: false,
		},
		{
			title: 'TRAFFIC_DETAIL_ID',
			name: 'traffic_detail_id',
			checked: false,
		},
		{
			title: 'RECORD_RECEIVED_DATE',
			name: 'record_received_date',
			checked: true,
		},
		{
			title: 'RECORD_OPEN_TIME',
			name: 'record_open_time',
			checked: true,
		},
		{
			title: 'OPERATOR_NETWORK',
			name: 'operator_network',
			checked: true,
		},
		{
			title: 'OPERATOR_ACCOUNT_ID',
			name: 'operator_account_id',
			checked: false,
		},
		{
			title: 'TAP_CODE',
			name: 'tap_code',
			checked: false,
		},
	],
	voice: [
		{
			title: 'FROM',
			name: 'from',
			checked: true,
		},
		{
			title: 'TO',
			name: 'to',
			checked: true,
		},
		{
			title: 'DURATION_RAW',
			name: 'duration_raw',
			checked: false,
		},
		{
			title: 'DURATION_ROUNDED',
			name: 'duration_rounded',
			checked: false,
		},
		{
			title: 'SIM_STATE',
			name: 'sim_state',
			checked: true,
		},
		{
			title: 'BILLABLE_FLAG',
			name: 'billable_flag',
			checked: false,
		},
		{
			title: 'BILLING_CYCLE',
			name: 'billing_cycle',
			checked: false,
		},
		{
			title: 'ASSIGNED_RATE_PLAN_ID',
			name: 'rate_plan_id',
			checked: true,
		},
		{
			title: 'ASSIGNED_RATING_ZONE_ID',
			name: 'rating_zone_id',
			checked: true,
		},
		{
			title: 'RATE_PLAN_VERSION_ID',
			name: 'rate_plan_version_id',
			checked: false,
		},
		{
			title: 'SERVICE_TYPE',
			name: 'service_type',
			checked: true,
		},
		{
			title: 'TRAFFIC_DETAIL_ID',
			name: 'traffic_detail_id',
			checked: false,
		},
		{
			title: 'RECORD_RECEIVED_DATE',
			name: 'record_received_date',
			checked: true,
		},
		{
			title: 'RECORD_OPEN_TIME',
			name: 'record_open_time',
			checked: true,
		},
		{
			title: 'OPERATOR_NETWORK',
			name: 'operator_network',
			checked: true,
		},
		{
			title: 'OPERATOR_ACCOUNT_ID',
			name: 'operator_account_id',
			checked: false,
		},
		{
			title: 'TAP_CODE',
			name: 'tap_code',
			checked: false,
		},
	],
};

export const usageSignature = hash(JSON.stringify({
	refTypeId: 11,
	name: 'EndpointUsageDetails',
	value: endpointUsageDataHeaders,
}));

export const rdFields = [
	{ id: 'iccid', defaultLabel: 'ICCID' },
	{ id: 'eventTime', defaultLabel: 'Date and time stamp', type: 'date' },
	{ id: 'carrierName', defaultLabel: 'Carrier name' },
	{ id: 'registrationType', defaultLabel: 'Registration type' },
	{ id: 'status', defaultLabel: 'Status' },
];

export const ddFields = [
	{
		id: 'M2MPlatform',
		defaultLabel: 'M2M Platform',
		icon: 'dd_m2m_platform',
		data: [
			{
				id: 'simStatusM2M',
				defaultLabel: 'Getting device status',
				successMessages: [
					'inventory',
					'activated',
					'deactivated',
					'retired',
					'purged',
					'testReady',
					'activationReady',
					'replaced',
					'trial',
					'start',
					'transfer',
					'globalInTransitSource',
					'globalInTransitDestination',
					'globalTransferred',
					'other',
				],
				errorMessages: [],
			},
		],
	},
	{
		id: 'EndpointProvisioningInformation',
		defaultLabel: 'Endpoint Provisioning Information',
		icon: 'dd_epi',
		data: [
			{
				id: 'trafficAllowed',
				defaultLabel: 'Get device provisioning information',
				successMessages: ['permitpassingtraffic'],
				errorMessages: ['notpermitpassingtraffic'],
			},
			{
				id: 'verifySimState',
				defaultLabel: 'Get device information',
				successMessages: ['allowedtousewirelessnetworkresources'],
				errorMessages: [
					'barredfromnetwork',
					'trafficnotallowed_simstate',
					'suspendedbyserviceprovider',
					'suspendedsuetousagelimit',
					'trafficnotallowed_others',
				],
			},
		],
	},
	{
		id: 'NetworkConnection',
		defaultLabel: 'Network Connection',
		icon: 'dd_nc',
		data: [
			{
				id: 'verifyConnection',
				defaultLabel: 'Get device network',
				successMessages: [
					'successfullyconnectedtonetwork_insession',
					'validregistration_validnetworkactivity',
					'validregistration_nonetworkactivity',
					'validgsmregistrationonly',
				],
				errorMessages: [
					'novalidregistration_hasactivityinlast24h',
					'novalidregistration_hasauthrequestinlast24h_nolocupdate',
					'novalidregistration_haslocupdatesmorethan24hago',
					'novalidregistration_hasauthrequestmorethan24hago_noactivity',
					'novalidregistration_nonetworkactivity',
				],
			},
			{
				id: 'verifyIPSession',
				defaultLabel: 'Get device IP',
				successMessages: [
					'datasessioncurrentlysuccessful',
					'datasessionpastsuccessful',
					'connectionattemptfailed_invalidcredentials',
				],
				errorMessages: ['nodatasessionsuccessfulhistory'],
			},
		],
	},
];

export const ddCarrierField = {
	id: 'carrier',
	defaultLabel: 'Carrier',
	icon: 'dd_carrier',
	data: [
		{
			id: 'ATTACHED_CARRIER_NAME',
			defaultLabel: 'Attached Carrier Name',
			successMessages: [],
			errorMessages: [],
		},
	],
};

export const ddFieldsWing = [
	{
		id: 'M2M Platform',
		defaultLabel: 'M2M Platform',
		icon: 'dd_m2m_platform',
		data: [
			{
				id: 'simState',
				defaultLabel: 'Getting device status',
				successMessages: [
					'active',
					'inactive',
					'inventory',
					'deactivated',
					'retired',
					'purged',
					'testReady',
					'activationReady',
					'replaced',
					'trial',
					'start',
					'transfer',
					'globalInTransitSource',
					'globalInTransitDestination',
					'globalTransferred',
					'other',
				],
				errorMessages: [],
			},
		],
	},
	{
		id: 'Endpoint Provisioning Information',
		defaultLabel: 'Endpoint Provisioning Information',
		icon: 'dd_epi',
		data: [
			{
				id: 'permitPassingTraffic',
				defaultLabel: 'Get device provisioning information',
				successMessages: ['permitpassingtraffic'],
				errorMessages: ['notpermitpassingtraffic'],
			},
		],
	},
	{
		id: 'Network Connection',
		defaultLabel: 'Network Connection',
		icon: 'dd_nc',
		data: [
			{
				id: 'successfullyConnectedToNetwork_inSession',
				defaultLabel: 'Get device network',
				successMessages: ['successfullyconnectedtonetwork_insession'],
				errorMessages: ['notsuccessfullyconnectedtonetwork_insession'],
			},
			{
				id: 'dataSessionCurrentlySuccessful',
				defaultLabel: 'Get device IP',
				successMessages: ['datasessioncurrentlysuccessful'],
				errorMessages: ['notdatasessioncurrentlysuccessful'],
			},
		],
	},
];

export const dataHistoryHeader = [
	{
		title: 'ICCID',
		name: 'iccid',
		checked: true
	},
	{
		title: 'DEVICE_ID',
		name: 'deviceId',
		checked: true
	},
	{
		title: 'DATA_VOLUME_DOWN_KB',
		name: 'dataVolumeDownlink',
		checked: true
	},
	{
		title: 'DATA_VOLUME_UP_KB',
		name: 'dataVolumeUplink',
		checked: true
	},
	{
		title: 'DATA_VOLUME',
		name: 'dataVolumeTotal',
		checked: true
	},
	{
		title: 'DURATION_MINUTES',
		name: 'duration',
		checked: true
	},
	{
		title: 'COUNTRY',
		name: 'country',
		checked: true
	},
	{
		title: 'SESSION_CHARGING_ID',
		name: 'chargingId',
		checked: true
	},
	{
		title: 'SESSION_START',
		name: 'eventStartTime',
		checked: true
	},
	{
		title: 'RATE_PLAN',
		name: 'tariffName',
		checked: true
	},
	{
		title: 'ACCESS_POINT_NAME',
		name: 'accessPointName',
		checked: true
	},
	{
		title: 'DEVICE_IP_V4',
		name: 'servedPdpAddress',
		checked: true
	},
	{
		title: 'DEVICE_IP_V6',
		name: 'deviceIpV6',
		checked: true
	},
	{
		title: 'RATING_ZONE_NAME',
		name: 'ratingZoneName',
		checked: true
	},
	{
		title: 'RATING_GROUP_ID_NAME',
		name: 'ratingGroupIdName',
		checked: true
	},
	{
		title: 'RATING_GROUP_ID',
		name: 'ratingGroupId',
		checked: true
	}
];

export const entitlementData = (entitlement, fixedIP) => [
	// {
	// 	label: {
	// 		id: 'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME',
	// 		defaultMessage: 'Network Entitlement Name',
	// 	},
	// 	value: entitlement.networkEntitlement.name
	// },
	// {
	// 	label: {
	// 		id: 'SINGLE_ENDPOINT.CREATE_DATE',
	// 		defaultMessage: 'Create Date',
	// 	},
	// 	value: isValidDate(entitlement.networkEntitlement.createDate)
	// 		? entitlement.networkEntitlement.createDate
	// 		: undefined,
	// 	isDate: true,
	// },
	// {
	// 	label: {
	// 		id: 'SINGLE_ENDPOINT.LAST_UPDATE_DATE',
	// 		defaultMessage: 'Last Update Date',
	// 	},
	// 	value: isValidDate(entitlement.networkEntitlement.lastUpdateTimestamp)
	// 		? entitlement.networkEntitlement.lastUpdateTimestamp
	// 		: undefined,
	// 	isDate: true,
	// },
	// {
	// 	label: {
	// 		id: 'SINGLE_ENDPOINT.LTE_DATA',
	// 		defaultMessage: 'LTE Data',
	// 	},
	// 	value: entitlement.networkEntitlement.dataLTE,
	// 	isBool: true,
	// },
	// {
	// 	label: {
	// 		id: 'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID',
	// 		defaultMessage: 'Roaming LTE',
	// 	},
	// 	value: entitlement.networkEntitlement.roamingLTE,
	// 	isBool: true,
	// },
	// {
	// 	label: {
	// 		id: 'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO',
	// 		defaultMessage: 'Camel',
	// 	},
	// 	value: entitlement.networkEntitlement.camel,
	// 	isBool: true,
	// },
	{
		label: {
			id: 'SINGLE_ENDPOINT.DESCRIPTION',
			defaultMessage: 'Description',
		},
		value: entitlement.onStateProfile.description,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP',
			defaultMessage: 'Fixed IP',
		},
		value: fixedIP,
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.GPRS',
			defaultMessage: 'GPRS',
		},
		value: entitlement.onStateProfile.data,
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.LTE_DATA',
			defaultMessage: 'LTE Data',
		},
		value: entitlement.onStateProfile.dataLTE,
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.SMS_MT',
			defaultMessage: 'SMS MT',
		},
		value: entitlement.onStateProfile.smsMT,
		labelTooltip: {
			id: 'MOBILE_TERMINATING',
			defaultMessage: 'Mobile Terminating',
			tooltipId: '2',
		},
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.SMS_MO',
			defaultMessage: 'SMS MO',
		},
		value: entitlement.onStateProfile.smsMO,
		labelTooltip: {
			id: 'MOBILE_ORIGINATING',
			defaultMessage: 'Mobile Originating',
			tooltipId: '1',
		},
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO',
			defaultMessage: 'Block International SMS MO Except Home Country',
		},
		value: entitlement.onStateProfile.blockInternationalMO,
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.VOICE_MO',
			defaultMessage: 'Voice MO',
		},
		value: entitlement.onStateProfile.voiceMO,
		labelTooltip: {
			id: 'MOBILE_ORIGINATING',
			defaultMessage: 'Mobile Originating',
			tooltipId: '3',
		},
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.VOICE_MT',
			defaultMessage: 'Voice MT',
		},
		value: entitlement.onStateProfile.voiceMT,
		labelTooltip: {
			id: 'MOBILE_TERMINATING',
			defaultMessage: 'Mobile Terminating',
			tooltipId: '4',
		},
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.INTERNATIONAL_VOICE',
			defaultMessage: 'International Voice',
		},
		value: entitlement.onStateProfile.internationalVoice,
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS',
			defaultMessage: 'Block Premium Numbers',
		},
		value: entitlement.onStateProfile.blockPremiumNumbers,
		isBool: true,
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.HLR_TEMPLATE',
			defaultMessage: 'HRL Template',
		},
		value: entitlement.onStateProfile.hlrTemplate
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.ROAMING_ID',
			defaultMessage: 'Roaming ID',
		},
		value: entitlement.onStateProfile.roamingRestrictionSetId
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.ROAMING',
			defaultMessage: 'Roaming',
		},
		value: entitlement.onStateProfile.roaming
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE',
			defaultMessage: 'Camel Service Type',
		},
		value: entitlement.onStateProfile.camelServiceType
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.HOTLINE_NUMBER',
			defaultMessage: 'Hotline number',
		},
		value: entitlement.onStateProfile.hotlineNumber
	},
	{
		label: {
			id: 'SINGLE_ENDPOINT.FACETIME',
			defaultMessage: 'FACETIME',
		},
		value: entitlement.onStateProfile.facetime,
		isBool: true,
	},
];

export const sessionsHeader = [
	{
		title: 'DATE_TIME',
		name: 'dateTime',
		checked: true,
	},
	{
		title: 'SESSION_START',
		name: 'start',
		checked: true,
	},
	{
		title: 'SESSION_END',
		name: 'end',
		checked: true,
	},
	{
		title: 'DATA_VOLUME',
		name: 'dataVolume',
		checked: true,
	},
	{
		title: 'IP_ADDRESS',
		name: 'ipAddress',
		checked: true,
	},
	{
		title: 'CARRIER',
		name: 'carrier',
		checked: true,
	},
	{
		title: 'APN',
		name: 'apn',
		checked: true,
	},
];

export const sessionsSignature = hash(JSON.stringify({
	refTypeId: 10,
	name: 'SingleEndpointSessionsSettings',
	value: { data: header },
}));

const renderBooleanValue = (value) => {
	if (value === undefined || value === null) return undefined;

	return value.toString() === 'false' || value.toString() === '0' ? (
		<FormattedMessage id="NO" defaultMessage="No" />
	) : (
		<FormattedMessage id="YES" defaultMessage="Yes" />
	);
};

export const newRatePlanData = (ratePlan, billingAccountId) => [
	{
		key: 0,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.USAGE_LIMIT',
			defaultMessage: 'Data Usage Limit'
		},
		value: ratePlan.ratePlan.usageLimit
	},
	{
		key: 1,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.RATE_PLAN_TYPE',
			defaultMessage: 'Rate Plan Type'
		},
		value: ratePlan.ratePlan.subscriptionItemType
	},
	{
		key: 2,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.BILLING_ACCOUNT_ID',
			defaultMessage: 'Billing Account ID'
		},
		value: billingAccountId
	},
	{
		key: 3,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.INCLUDED_USAGE',
			defaultMessage: 'Included Data Usage'
		},
		value: ratePlan.ratePlan.includedDataUsage
	},
	{
		key: 4,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.TIERED_PLAN',
			defaultMessage: 'Tiered Plan'
		},
		value: renderBooleanValue(ratePlan.ratePlan.tieringFlag)
	},
	{
		key: 5,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.PAYMENT_TYPE',
			defaultMessage: 'Payment Type'
		},
		value: ratePlan.ratePlan.paymentType
	},
	{
		key: 6,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.DEFAULT_PLAN',
			defaultMessage: 'Default Plan'
		},
		value: renderBooleanValue(
			ratePlan.ratePlan.defaultPlan || ratePlan.ratePlan.default
		)
	},
	{
		key: 7,
		label: {
			id: 'ENDPOINTS_RATE_PLAN.CURRENCY',
			defaultMessage: 'Currency'
		},
		value: ratePlan.ratePlan.currency
	}
];
