import React from 'react';

function Java() {
	return (
		<div data-spec="java-asset">
			<pre>
				{`
          OkHttpClient client = new OkHttpClient();

          MediaType mediaType = MediaType.parse("application/x-www-form-urlencoded");
          RequestBody body = RequestBody.create(mediaType,
            "username=USERNAME&password=PASSWORD&grant_type=password");
          Request request = new Request.Builder()
            .url("https://cisiot.att.com/EAI/oauth/token")
            .post(body)
            .addHeader("Content-Type", "application/x-www-form-urlencoded")
            .addHeader("Authorization", "Basic YOUR_ENCODED_CREDENTIALS")
            .build();

          Response response = client.newCall(request).execute();
        `}
			</pre>
		</div>
	);
}

export default Java;
