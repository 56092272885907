import { combineReducers } from 'redux';

import simpleSearch from './simpleSearch/reducer';
import sidebar from './sidebar/reducer';
import getStatusCode from './getStatusCode/reducer';
import notifications from './notifications/reducer';
import modals from './modals/reducer';
import filterModal from './filterModal/reducers';

import pagination from './pagination/reducer';
import uploadFile from './uploadFile/reducer';

import searchBar from './searchBar/reducer';

export default combineReducers({
	simpleSearch,
	sidebar,
	getStatusCode,
	notifications,
	modals,
	filterModal,
  uploadFile,
  pagination,
  searchBar
});
