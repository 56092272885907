import { createAction } from 'redux-actions';

import { resetUserSettings as service } from '../../../services/UserService';
import { RESET_USER_SETTINGS, FETCH_USER } from '../constants';

const request = createAction(`${RESET_USER_SETTINGS}_REQUEST`);
const success = createAction(`${RESET_USER_SETTINGS}_SUCCESS`);
const fail = createAction(`${RESET_USER_SETTINGS}_ERROR`);
const resetSuccess = createAction(`${FETCH_USER}_RESET`);

const resetUserSettings = (refTypeId) => async (dispatch) => {
	dispatch(request());
	service(refTypeId)
		.then((response) => {
			dispatch(success(response.data));
			dispatch(resetSuccess({ refTypeId }));
		})
		.catch((error) => {
			dispatch(fail(error));
		});
};

export default resetUserSettings;
