import { connect } from 'react-redux';
import MoveToSubAccountModal from './MoveToSubAccountModal';

import { getAccountsSuccess } from '../../redux/accounts/selectors';
import { moveEndpoints } from '../../redux/endpoints/actions';
import { moveEndpointsRequest } from '../../redux/endpoints/selectors';

const mapStateToProps = (state) => ({
	accounts: getAccountsSuccess(state),
	moveEndpointsRequest: moveEndpointsRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	moveEndpoints: (params) => dispatch(moveEndpoints(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MoveToSubAccountModal);
