export const renderTabs = (actionBarTabs) =>
	actionBarTabs.map((item) => {
		const tabs = [];
		if (item.isActive && item.component) {
			tabs.push(item.component);
		}
		return tabs;
	});

export const onNewTabClick = (id, self) => () =>
	self.setState({
		activeTab: id,
		filterData: {}
	});
