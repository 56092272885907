import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './DeleteSettingModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

const DeleteSettingModal = ({
	show,
	onCancel,
	onClick,
	settingName,
	settingId
}) => (
	<Modal data-spec="delete-setting-prompt-modal" show={show} onClose={onCancel}>
		<ModalHeader
			title={
				<FormattedMessage id="ARE_YOU_SURE" defaultMessage="Are you sure?" />
			}
		/>
		<ModalBody className={styles.modal__body}>
			<div data-spec="body-message" className={styles.body}>
				<div className={styles.modal_message}>
					<FormattedMessage
						id="ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION"
						defaultMessage="Delete Defined Chart Setting?"
					/>
				</div>
				<div className={styles.modal_text_block}>
					<div className={styles.modal_message}>
						<FormattedMessage
							id="ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE"
							defaultMessage={
								'Are you sure you would like to delete setting {settingName}?'
							}
							values={{ settingName }}
						/>
					</div>
				</div>
			</div>
			<div>
				<Prompt />
			</div>
		</ModalBody>
		<ModalFooter data-spec="ds-modal-footer">
			<div data-spec="buttons">
				<Button
					dataSpec="cancel-delete-setting"
					onClick={onCancel}
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<Button
					dataSpec="delete-chart-setting"
					variant="primary"
					onClick={() => {
						onCancel();
						onClick(settingId);
					}}
					label={<FormattedMessage id="DELETE" defaultMessage="Delete" />}
				/>
			</div>
		</ModalFooter>
	</Modal>
);

const { func, bool, string, number } = PropTypes;

DeleteSettingModal.propTypes = {
	show: bool,
	onCancel: func,
	onClick: func,
	settingName: string,
	settingId: number
};

DeleteSettingModal.defaultProps = {
	show: false,
	settingName: '',
	settingId: '',
	onCancel: undefined,
	onClick: undefined
};

export default DeleteSettingModal;
