import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_ADDONS, GET_ADDONS_FILTERS } from './constants';

export default combineReducers({
	getAddons: globalReducer(GET_ADDONS),
	getAddonsFilters: globalReducer(GET_ADDONS_FILTERS)
});
