import { connect } from 'react-redux';

import { getUser } from '../../../../redux/user/getUserData/selectors';
import {
	updateSingleEndpoint,
	updateSingleEndpointInit,
} from '../../redux/actions';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { getAvailableStates } from '../../../Endpoint/redux/actions';
import { getAvailableStatesData } from '../../../Endpoint/redux/selectors';

const mapStateToProps = (state) => ({
	availableStates: getAvailableStatesData(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAvailableStates: () => dispatch(getAvailableStates()),
	singleEndpointUpdate: (data) => dispatch(updateSingleEndpoint(data)),
	updateSingleEndpointStatusInit: () =>
		dispatch(updateSingleEndpointInit())
});

export const DesktopModule = connect(
	mapStateToProps,
	mapDispatchToProps
)(Desktop);

export const MobileModule = connect(
	mapStateToProps,
	mapDispatchToProps
)(Mobile);
