export default {
	'SECURITY_FEATURES.TITLE': 'Funzionalità di sicurezza',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'Elenco IMEI consentiti',
	'SECURITY_FEATURES.SMS_WHITELIST': 'Elenco SMS consentiti',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'Elenco VOCALE consentiti',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'Blacklist IP, URL, Porte',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'Elenco intervalli IP consentiti',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': 'Nessuna funzionalità di sicurezza disponibile',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': 'Non ci sono attualmente funzionalità di sicurezza da mostrare per questa tabella',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'NOME ACCOUNT M2M',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'DATA INVIO',
	'SECURITY_FEATURES.STATUS': 'STATO',
	'SECURITY_FEATURES.PENDING': 'In sospeso',
	'SECURITY_FEATURES.FAILED': 'Non riuscito',
	'SECURITY_FEATURES.SUCCESSFUL': 'Riuscito',
	'SECURITY_FEATURES.CCIP': 'Control Center Integration Program (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Vivo Control Center',
	'SECURITY_FEATURES.DCP': 'Device Connection Platform (DCP)',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'AT&T Control Center (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SEARCH MENU
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOCALE',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, PORTA',
	'SECURITY_FEATURES.MENU_5': 'INTERVALLO IP',
	'SECURITY_FEATURES.ADD_1': 'Aggiungere nuovo IMEI all’elenco dei consentiti',
	'SECURITY_FEATURES.ADD_2': 'Aggiungere nuovo SMS all’elenco dei consentiti',
	'SECURITY_FEATURES.ADD_3': 'Aggiungere nuovo VOCALE all’elenco dei consentiti',
	'SECURITY_FEATURES.ADD_4': ' Aggiungere nuovo IP, URL, PORTA alla blacklist',
	'SECURITY_FEATURES.ADD_5': 'Aggiungere nuovo intervallo IP all’elenco dei consentiti',
	'SECURITY_FEATURES.EXPORT': 'Esporta',
	'SECURITY_FEATURES.EDIT': 'Modifica',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'Seleziona piattaforma M2M',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Caricamento in blocco',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Carica file',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Conferma cambiamenti',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. Conferma del sistema',
	'SECURITY_FEATURES.CANCEL': 'Annulla',
	'SECURITY_FEATURES.CONTINUE': 'Continua',
	'SECURITY_FEATURES.DONE': 'Fatto',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Carica file',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': 'Caricare qui i file per l’azione batch selezionata:',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Trascinare il file o cliccare per caricare',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Conferma caricamento file batch',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Azione pianificata il',
	'SECURITY_FEATURES.LOADER_MESSAGE': 'Attendere. L’operazione potrebbe richiedere qualche minuto, a seconda delle dimensioni del file.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': 'File batch ricevuti!',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': 'Le modifiche sono in sospeso in attesa del completamento degli aggiornamenti da parte del sistema',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'Errore sistema file batch!',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': 'Si è verificato un problema durante l’elaborazione degli aggiornamenti. Riprovare più tardi.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Scarica modello',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': 'I file devono essere inviati con estensione .csv.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': 'Il massimo delle caratteristiche di sicurezza per ogni caricamento è '
};
