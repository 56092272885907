import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const BulkUploadModalContext = React.createContext();

export class BulkUploadModalContextProvider extends PureComponent {
	constructor(props) {
    super(props);
		this.state = {};
  }

	render() {
    const { children } = this.props;
		return (
			<BulkUploadModalContext.Provider
				data-spec="bulk-upload-modal-context-provider"
				value={{
					state: this.state,
					updateContext: (newState) => this.setState(newState)
				}}
			>
				{children}
			</BulkUploadModalContext.Provider>
		);
	}
}

BulkUploadModalContextProvider.propTypes = {
	children: PropTypes.any
};

export function BulkUploadModalContextConsumer(props) {
		const { children } = props;
		return (
			<BulkUploadModalContext.Consumer data-spec="bulk-upload-modal-context-consumer">
				{(context) =>
					React.Children.map(children, (child) =>
						React.cloneElement(child, { ...props, context }))}
			</BulkUploadModalContext.Consumer>
		);
	}

BulkUploadModalContextConsumer.propTypes = {
	children: PropTypes.any
};
