import { Status } from '../../../../utils/constants';

/* ******************** ADD NETWORK ENTITLEMENT MODAL ******************** */
export const isAddUserGroupModalOpened = (state) =>
	state.onboarding.userGroups.addUserGroupModal.isOpen;

/* ******************** GET USER GROUPS ******************** */
export const getUserGroupsRequest = (state) =>
	state.onboarding.userGroups.getUserGroups.status === Status.PENDING;
export const getUserGroupsFail = (state) =>
	state.onboarding.userGroups.getUserGroups.status === Status.FAIL;
export const getUserGroupsSuccess = (state) =>
	state.onboarding.userGroups.getUserGroups.data;

/* ******************** GET USER GROUP ******************** */
export const getUserGroupRequest = (state) =>
	state.onboarding.userGroups.getUserGroup.status === Status.PENDING;
export const getUserGroupFail = (state) =>
	state.onboarding.userGroups.getUserGroup.status === Status.FAIL;
export const getUserGroupSuccess = (state) =>
	state.onboarding.userGroups.getUserGroup.data;

/* ******************** ADD USER GROUP ******************** */
export const addUserGroupRequest = (state) =>
	state.onboarding.userGroups.addUserGroup.status === Status.PENDING;
export const addUserGroupFail = (state) =>
	state.onboarding.userGroups.addUserGroup.status === Status.FAIL;
export const addUserGroupSuccess = (state) =>
	state.onboarding.userGroups.addUserGroup.data;

/* ******************** SUSPEND USER GROUP ******************** */
export const suspendUserGroupRequest = (state) =>
	state.onboarding.userGroups.getSuspendUserGroup.status === Status.PENDING;
export const suspendUserGroupFail = (state) =>
	state.onboarding.userGroups.getSuspendUserGroup.status === Status.FAIL;
export const suspendUserGroupSuccess = (state) =>
	state.onboarding.userGroups.getSuspendUserGroup.status === Status.DONE;

/* ******************** REACTIVATE USER GROUP ******************** */
export const reactivateUserGroupRequest = (state) =>
	state.onboarding.userGroups.getReactivateUserGroup.status === Status.PENDING;
export const reactivateUserGroupFail = (state) =>
	state.onboarding.userGroups.getReactivateUserGroup.status === Status.FAIL;
export const reactivateUserGroupSuccess = (state) =>
	state.onboarding.userGroups.getReactivateUserGroup.status === Status.DONE;
