import { combineReducers } from 'redux';

import globalReducer from '../../../redux/global/globalReducer';
import {
	ADD_AUTOMATION_RULE,
	EDIT_AUTOMATION_RULE,
	GET_AUTOMATION_ALERTS,
	GET_AUTOMATION_ALERTS_FILTERS,
	REMOVE_AUTOMATION_ALERT,
	GET_AUTOMATION_COUNTRIES,
	GET_AUTOMATION_PLATFORM,
	GET_AUTOMATION_POOL,
	GET_AUTOMATION_RULE,
	GET_AUTOMATION_RULES,
	GET_AUTOMATION_RULES_ACTIONS,
	GET_AUTOMATION_RULES_CHILD_ACTIONS,
	GET_AUTOMATION_RULES_FILTERS,
	GET_AUTOMATION_SCHEDULERS,
	GET_AUTOMATION_ZONE_MODEL,
	GET_AUTOMATION_ZONE_NAME,
	REMOVE_AUTOMATION_RULE,
	GET_AUTOMATION_RATE_PLANS,
	GET_AUTOMATION_PREPAID_RATE_PLANS,
	GET_AUTOMATION_NETWORK_ENTITLEMENTS,
	GET_AUTOMATION_AVAILABLE_STATES,
	ACTIVATE_RULE,
	SET_RULE_STATE_RECEIVED,
	SET_RULE_STATE_IMPLEMENTED,
	SET_RULE_STATE_CONFLICTED,
	REQUEST_FOR_DELETION,
	GET_RULE_HISTORY,
	GET_RULE_NOTES,
	GET_RULE_AUTOMATION_ZONES
} from './constants';
import getAutomationOptions from './getAutomationOptions/reducer';
import selectedItems from './alerts/selectedItems/reducer';

export default combineReducers({
	getAutomationOptions,
	alerts: selectedItems,
	getAutomationRulesFilters: globalReducer(GET_AUTOMATION_RULES_FILTERS),
	getAutomationAlertsFilters: globalReducer(GET_AUTOMATION_ALERTS_FILTERS),
	getAutomationAlerts: globalReducer(GET_AUTOMATION_ALERTS),
	removeAutomationAlert: globalReducer(REMOVE_AUTOMATION_ALERT),
	getAutomationRules: globalReducer(GET_AUTOMATION_RULES),
	getAutomationRule: globalReducer(GET_AUTOMATION_RULE),
	removeAutomationRule: globalReducer(REMOVE_AUTOMATION_RULE),
	addAutomationRule: globalReducer(ADD_AUTOMATION_RULE),
	editAutomationRule: globalReducer(EDIT_AUTOMATION_RULE),
	getAutomationActions: globalReducer(GET_AUTOMATION_RULES_ACTIONS),
	getAutomationChildActions: globalReducer(GET_AUTOMATION_RULES_CHILD_ACTIONS),
	getAutomationCountries: globalReducer(GET_AUTOMATION_COUNTRIES),
	getAutomationPlatform: globalReducer(GET_AUTOMATION_PLATFORM),
	getAutomationPool: globalReducer(GET_AUTOMATION_POOL),
	getAutomationSchedulers: globalReducer(GET_AUTOMATION_SCHEDULERS),
	getAutomationZoneModel: globalReducer(GET_AUTOMATION_ZONE_MODEL),
	getAutomationZoneName: globalReducer(GET_AUTOMATION_ZONE_NAME),
	getAutomationRatePlans: globalReducer(GET_AUTOMATION_RATE_PLANS),
	getAutomationPrepaidRatePlans: globalReducer(
		GET_AUTOMATION_PREPAID_RATE_PLANS
	),
	getAutomationNetworkEntitlements: globalReducer(
		GET_AUTOMATION_NETWORK_ENTITLEMENTS
	),
	getAutomationAvailableStates: globalReducer(GET_AUTOMATION_AVAILABLE_STATES),
	activateRule: globalReducer(ACTIVATE_RULE),
	setRuleReceived: globalReducer(SET_RULE_STATE_RECEIVED),
	setRuleImplemented: globalReducer(SET_RULE_STATE_IMPLEMENTED),
	setRuleConflicted: globalReducer(SET_RULE_STATE_CONFLICTED),
	requestForDeletion: globalReducer(REQUEST_FOR_DELETION),
	getRuleHistory: globalReducer(GET_RULE_HISTORY),
	getRuleNotes: globalReducer(GET_RULE_NOTES),
	getRuleAutomationZones: globalReducer(GET_RULE_AUTOMATION_ZONES)
});
