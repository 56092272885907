export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': 'Automatiseringsregel verwijderen?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': 'Geselecteerde automatiseringsregels verwijderen?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': 'Weet u zeker dat u de geselecteerde automatiseringsregels wilt verwijderen?',
	'AUTOMATION.CANCEL': 'Annuleren',
	'AUTOMATION.DELETE': 'Verwijderen',
	'AUTOMATION.ARE_YOU_SURE': 'Weet u het zeker?',
	'AUTOMATION.ACTIVE': 'Geactiveerd',
	'AUTOMATION.ACC_IF': 'Als',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Nog een drempel toevoegen',
	'AUTOMATION.EDIT_RULE': 'Regel bewerken',
	'AUTOMATION.CREATE_NEW_RULE': 'Nieuwe regel maken',
	'AUTOMATION.PLATFORM': 'Platform',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Account',
	'AUTOMATION.CATEGORY': 'Categorie',
	'AUTOMATION.RULE_STATE': 'Status regel',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Actief',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inactief',
	'AUTOMATION.TRIGGER': 'Trigger',
	'AUTOMATION.ACC_RULE_NAME': 'Naam regel',
	'AUTOMATION.SAVE': 'Opslaan',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Geselecteerde filters wissen',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Filters selecteren',
	'AUTOMATION.AUTOMATION': 'Automatisering',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Nieuwe automatiseringsregel maken',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': 'Weet u zeker dat u automatiseringsregel {ruleName} wilt verwijderen?',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Regel gemaakt',
	'AUTOMATION.ADD_RULE_ERROR': 'Kan automatiseringsregel niet maken. Probeer het opnieuw.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Regel bewerkt',
	'AUTOMATION.EDIT_RULE_ERROR': 'Kan automatiseringsregel niet bewerken. Probeer het opnieuw.',
	'AUTOMATION.THEN': 'Dan',
	'AUTOMATION.NUMBER_OF_DAYS': 'Aantal dagen',
	'AUTOMATION.NUMBER_OF_HOURS': 'Aantal uur (max. 24 uur)',
	'AUTOMATION.AT': 'Te',
	'AUTOMATION.SIM_STATE': 'SIM-status',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'Tariefplan-id',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'M2M-subaccount-id',
	'AUTOMATION.CUSTOMER_ID': 'Klant-id',
	'AUTOMATION.CUSTOM': 'Aangepast',
	'AUTOMATION.FILTER': 'Filter',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'Geen filters beschikbaar',
	'AUTOMATION.THRESHOLD': 'Drempel',
	'AUTOMATION.ZONE_MODEL': 'Model zone',
	'AUTOMATION.ZONE_NAME': 'Naam zone',
	'AUTOMATION.POOL_ID': 'Pool-id',
	'AUTOMATION.COUNTRY': 'Land',
	'AUTOMATION.FROM_KEY': 'Van',
	'AUTOMATION.TO_KEY': 'Naar',
	'AUTOMATION.FOLLOW_UP_WITH': 'Follow-up met',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': 'Automatiseringsregel verwijderd',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': 'Geselecteerde automatiseringswaarschuwingen zijn verwijderd',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': 'Kan automatiseringsregel niet verwijderen. Probeer het opnieuw.',
	'AUTOMATION.PLATFORMS': 'Platforms',
	'AUTOMATION.CATEGORIES': 'Categorieën',
	'AUTOMATION.TRIGGERS': 'Triggers',
	'AUTOMATION.M2MACCOUNTS': 'M2M-accounts',
	'AUTOMATION.HOURS_USING': 'In de afgelopen 24 uur m.b.v.',
	'AUTOMATION.LOCATIONUPDATE': 'Locatie-update',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Verificatie-update',
	'AUTOMATION.ZONE': 'Zone',
	'AUTOMATION.ACCOUNT_ID': 'Account-id',
	'AUTOMATION.ACTION': 'Actie',
	'AUTOMATION.DCP': 'DCP',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'Sms',
	'AUTOMATION.USAGE': 'Verbruik',
	'AUTOMATION.SIM_PROVISIONING': 'SIM-inrichting',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Abonnementsbewaking',
	'AUTOMATION.USAGE_MONITORING': 'Verbruiksbewaking',
	'AUTOMATION.NETWORK_MONITORING': 'Netwerkbewaking',
	'AUTOMATION.PRICING_AUTOMATION': 'Prijsautomatisering',
	'AUTOMATION.SECURITY_MONITORING': 'Beveiligingsbewaking',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'Wijziging tariefplan SIM',
	'AUTOMATION.SIM_STATE_CHANGE': 'Wijziging SIM-status',
	'AUTOMATION.SMS_USAGE': 'Sms-verbruik',
	'AUTOMATION.SIM_DATA_LIMIT': 'Datalimiet SIM',
	'AUTOMATION.SIM_EXPIRATION': 'Verloopdatum SIM',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Dataverbruik in cyclus tot op heden',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Voice-verbruik in cyclus tot op heden',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': 'Overschrijding dataverbruik in de afgelopen 24 uur',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': 'Aantal sessieverbindingen in de afgelopen 24 uur',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Maandelijks gepoold dataverbruik',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Maandelijks gepoold sms-verbruik',
	'AUTOMATION.NO_CONNECTION': 'Geen verbinding',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Voice-verbruik in de afgelopen 24 uur',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'In een zone registreren',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Te veel verbindingen',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'Overschrijding SMS MO per tijdvak',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'SMS MO onder max. verbruik per tijdvak',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'Overschrijding SMS MT per tijdvak',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'SMS MT onder max. verbruik per tijdvak',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'Langdurige overschrijding GPRS',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'Kortdurende overschrijding GPRS',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'Eerste datasessie tot stand gebracht',
	'AUTOMATION.IMEI_CHANGED': 'IMEI gewijzigd',
	'AUTOMATION.NETWORK_REGISTRATION': 'Netwerkregistratie',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Netwerkregistratie in een zone',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'Wijziging aangepast SIM-veld',
	'AUTOMATION.SIM_STATUS': 'SIM-status',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Tariefplan gewijzigd',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'SIM-land gewijzigd',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'Tariefplan SIM verloopt',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Datasessie gestopt',
	'AUTOMATION.DATA_SESSION_STARTED': 'Datasessie gestart',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Dataverbruik in een land',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': 'Aantal datasessies - factuurcyclus',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Dataverbruik in een zone',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'Geen verbinding in factuurcyclus',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': 'Aantal sessieverbindingen in de afgelopen 24 uur',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'Aantal sms-berichten in een land',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'Sms-verbruik in een zone',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'Sms-verbruik in de afgelopen 24 uur',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Totaal dataverbruik - 24 uur',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': 'Totaal dataverbruik - factureringscyclus',
	'AUTOMATION.SMS_MO_RECEIVED': 'SMS MO ontvangen',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': 'Voice-verbruik in een zone in cyclus tot op heden',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'Einde SIM-voiceplan',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'Einde SIM-dataplan',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'Actie whitelist IMEI',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'Wijziging SIM-account',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Recent (afgelopen 24 uur) sms-verbruik',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': 'Netwerkregistratie in een zone',
	'AUTOMATION.MSISDN_CHANGE': 'Wijziging MSISDN',
	'AUTOMATION.IMEI_CHANGE': 'Wijziging IMEI',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': 'Dataverbruik in een zone in cyclus tot op heden',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': 'Er wordt een eindpunt overgedragen vanaf',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': 'Elk eindpunt in de huidige cyclus dat gebruikmaakt van',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Elk eindpunt op een prepaid tariefplan waarvan de prepaid periode eindigt',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': 'Het netwerk detecteert een wijziging in de IMEI van het eindpunt',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': 'Het systeem detecteert een MSISDN-wijziging voor een eindpunt',
	'AUTOMATION.INITIATE_OTA_SWAP': 'OTA-omwisseling initiëren',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': 'Alle eindpunten die meer hebben verzonden of ontvangen dan',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': 'Elke SIM met een wijziging in een van de aangepaste velden',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': 'Kan eindpunt IMEI niet vinden in de whitelist',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': 'Elk eindpunt in de huidige cyclus dat het volgende overstijgt',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': 'Er is een MO SMS ontvangen voor een sms-nummer',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'Totale aantal sms-berichten - factureringscyclus',
	'AUTOMATION.1_HOUR': '1 uur',
	'AUTOMATION.2_HOURS': '2 uur',
	'AUTOMATION.3_HOURS': '3 uur',
	'AUTOMATION.4_HOURS': '4 uur',
	'AUTOMATION.5_HOURS': '5 uur',
	'AUTOMATION.6_HOURS': '6 uur',
	'AUTOMATION.7_HOURS': '7 uur',
	'AUTOMATION.8_HOURS': '8 uur',
	'AUTOMATION.9_HOURS': '9 uur',
	'AUTOMATION.10_HOURS': '10 uur',
	'AUTOMATION.11_HOURS': '11 uur',
	'AUTOMATION.12_HOURS': '12 uur',
	'AUTOMATION.13_HOURS': '13 uur',
	'AUTOMATION.14_HOURS': '14 uur',
	'AUTOMATION.15_HOURS': '15 uur',
	'AUTOMATION.16_HOURS': '16 uur',
	'AUTOMATION.17_HOURS': '17 uur',
	'AUTOMATION.18_HOURS': '18 uur',
	'AUTOMATION.19_HOURS': '19 uur',
	'AUTOMATION.20_HOURS': '20 uur',
	'AUTOMATION.21_HOURS': '21 uur',
	'AUTOMATION.22_HOURS': '22 uur',
	'AUTOMATION.23_HOURS': '23 uur',
	'AUTOMATION.24_HOURS': '24 uur',
	'AUTOMATION.25_HOURS': '25 uur',
	'AUTOMATION.26_HOURS': '26 uur',
	'AUTOMATION.27_HOURS': '27 uur',
	'AUTOMATION.28_HOURS': '28 uur',
	'AUTOMATION.29_HOURS': '29 uur',
	'AUTOMATION.30_HOURS': '30 uur',
	'AUTOMATION.31_HOURS': '31 uur',
	'AUTOMATION.32_HOURS': '32 uur',
	'AUTOMATION.33_HOURS': '33 uur',
	'AUTOMATION.34_HOURS': '34 uur',
	'AUTOMATION.35_HOURS': '35 uur',
	'AUTOMATION.36_HOURS': '36 uur',
	'AUTOMATION.37_HOURS': '37 uur',
	'AUTOMATION.38_HOURS': '38 uur',
	'AUTOMATION.39_HOURS': '39 uur',
	'AUTOMATION.40_HOURS': '40 uur',
	'AUTOMATION.41_HOURS': '41 uur',
	'AUTOMATION.42_HOURS': '42 uur',
	'AUTOMATION.43_HOURS': '43 uur',
	'AUTOMATION.44_HOURS': '44 uur',
	'AUTOMATION.45_HOURS': '45 uur',
	'AUTOMATION.46_HOURS': '46 uur',
	'AUTOMATION.47_HOURS': '47 uur',
	'AUTOMATION.48_HOURS': '48 uur',
	'AUTOMATION.LAST_24_HOURS': 'Afgelopen 24 uur',
	'AUTOMATION.CYCLE_TO_DATE': 'Cyclus tot op heden',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'SIM prepaid tariefplan overschrijdt',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'Dataverbruik overschrijdt limiet',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'Dataverbruik nadert limiet',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'SIM prepaid tariefplan komt binnen',
	'AUTOMATION.MO_MT': 'MO/MT',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'Voice-verbruik overschrijdt limiet',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'Voice-verbruik nadert limiet',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'Door SIM tot stand gebracht is meer dan',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'Door SIM tot stand gebracht is minder dan',
	'AUTOMATION.EXCEEDES': 'Overschreden',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': 'SIM heeft in de afgelopen N uur geen dataverbinding tot stand gebracht',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'SIM registreert op netwerk in zone',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'SIM heeft in de huidige cyclus meer dan verbindingen tot stand gebracht',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': 'Het netwerk detecteert een nieuw apparaat',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'Verbruik in cyclus tot op heden overschrijdt',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'Verbruik in cyclus tot op heden nadert',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': 'Het netwerk detecteert een wijziging in ...',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'SIM heeft voor het eerst op een nieuw netwerk geregistreerd',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'Eindpunt registreert in zone',
	'AUTOMATION.CUSTOM_FIELD_1': 'Aangepast veld 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Aangepast veld 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Aangepast veld 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Aangepast veld 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Aangepast veld 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Aangepast veld 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Aangepast veld 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Aangepast veld 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Aangepast veld 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Aangepast veld 10',
	'AUTOMATION.CHANGED': 'Gewijzigd',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Wijziging tariefplan',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': 'Het aantal datasessies voor een SIM overschrijdt',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'Er begint een SIM-datasessie',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'Er stopt een SIM-datasessie',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': 'Dataverbruik in een land overschrijdt',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'Dataverbruik in A overschrijdt',
	'AUTOMATION.EXCEEDS': 'Overschreden',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': 'Factureerbaar sms-verbruik op een gepoold tariefplan bereikt',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': 'Een SIM heeft in de afgelopen factureringscyclus geen dataverbinding tot stand gebracht',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'Een SIM heeft meer dan',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'Aantal sms-berichten in een land overschrijdt',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'Aantal sms-berichten overschrijdt limiet',
	'AUTOMATION.1_WEEK': '1 week',
	'AUTOMATION.2_WEEKS': '2 weken',
	'AUTOMATION.3_WEEKS': '3 weken',
	'AUTOMATION.4_WEEKS': '4 weken',
	'AUTOMATION.SEND_EMAIL': 'E-mail verzenden',
	'AUTOMATION.SEND_SMS': 'Sms verzenden',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': 'Netwerkrecht van apparaat wijzigen',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': 'SIM-status van apparaat wijzigen',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': 'Tariefplan van apparaat wijzigen',
	'AUTOMATION.PUSH_API': 'Push-API',
	'AUTOMATION.COMBINED': 'Gecombineerd',
	'AUTOMATION.MO': 'MO',
	'AUTOMATION.MT': 'MT',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Verbindingen binnen 24 uur',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Wijzig netwerkrecht',
	'AUTOMATION.CHANGE_SIM_STATUS': 'Wijzig SIM-status',
	'AUTOMATION.SMS_MESSAGES': 'Sms-berichten',
	'AUTOMATION.HOURS': 'Uur',
	'AUTOMATION.MIN': 'min',
	'AUTOMATION.MINUTES': 'Minuten',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Wijzig tariefplan',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': 'Verbindingen in de huidige cyclus',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Herstel vorig tariefplan',
	'AUTOMATION.MODIFIED_DELETED': 'Gewijzigd/verwijderd',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': 'Het SIM-tariefplan verloopt over één dag',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': 'Sms-verbruik is in poolbedrag opgenomen',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': 'Aantal datasessies in de afgelopen 24 uur',
	'AUTOMATION.MSGS': 'Berichten',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'Aantal sms-berichten in zone overschrijdt',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': 'Aantal datasessies in de afgelopen 24 uur',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Consumptie-uren',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Herstel vorige SIM-status',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Dagen na de triggergebeurtenis',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'Einde huidige factureringscyclus',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': 'De UTC-tijd na de triggergebeurtenis',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': 'Herstel vorige netwerkrechten',
	'AUTOMATION.GMNA': 'GM Noord-Amerika',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Onderneming',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM Noord-Amerika',
	'AUTOMATION.ENTERPRISE': 'Onderneming',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'Wijzig SIM-status',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'Sms-verbruik afgelopen 24 uur',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': 'Sms-verbruik in de afgelopen 24 uur overschrijdt',
	// Triggers
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': 'Verbruik SMS MO (Mobile Originated) - 24 uur',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': 'Verbruik SMS MO (Mobile Originated) - In een zone',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': 'Verbruik SMS MO (Mobile Originated) - Gepoold plan',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': 'Verbruik SMS MT (Mobile Terminated) - 24 uur',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': 'Verbruik SMS MT (Mobile Terminated) - Factuurcyclus',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': 'Verbruik SMS MT (Mobile Terminated) - In een zone',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': 'Verbruik SMS MT (Mobile Terminated) - Gepoold plan',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'De dag nadat het SIM-tariefplan vervallen is',
	'AUTOMATION.BYTES': 'Bytes',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Aangepaste tekst toevoegen aan een sms-bericht (optioneel)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': 'Aangepaste berichttekst(limiet van 140 tekens)',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'Einde SIM-plan',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Elke SIM op een prepaid tariefplan waarvan de prepaid periode eindigt',
	// nieuwe automatisering
	'AUTOMATION.TITLE': 'Automatisering',
	'AUTOMATION.RULES_TAB_NAME': 'Automatiseringsregels',
	'AUTOMATION.ALERTS_TAB_NAME': 'Automatiseringswaarschuwingen',
	'AUTOMATION_RULES.YES': 'Ja',
	'AUTOMATION_RULES.NO': 'Nee',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'Geen automatiseringsregels beschikbaar',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': 'Er zijn momenteel geen automatiseringsregels om u te tonen',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'Geen automatiseringswaarschuwingen beschikbaar',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': 'Er zijn momenteel geen automatiseringswaarschuwingen om u te tonen',
	//  koppen regeltabel
	'AUTOMATION_RULES.ID': 'Id',
	'AUTOMATION_RULES.RULE_NAME': 'Naam regel',
	'AUTOMATION_RULES.PLATFORM': 'Platform',
	'AUTOMATION_RULES.CATEGORY': 'Categorie',
	'AUTOMATION_RULES.TRIGGER': 'Trigger',
	'AUTOMATION_RULES.ACTIVE': 'Geactiveerd',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'Id M2M-account',
	'AUTOMATION_RULES.DATE_CREATED': 'Gemaakt op',
	'AUTOMATION_RULES.USER': 'Gebruiker',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'Implementatiestatus',
	// koppen waarschuwingentabel
	'AUTOMATION_ALERTS.ID': 'Id',
	'AUTOMATION_ALERTS.RULE_NAME': 'Naam regel',
	'AUTOMATION_ALERTS.PLATFORM': 'Platform',
	'AUTOMATION_ALERTS.CATEGORY': 'Categorie',
	'AUTOMATION_ALERTS.TRIGGER': 'Trigger',
	'AUTOMATION_ALERTS.ACTIVE': 'Geactiveerd',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'Id M2M-account',
	'AUTOMATION_ALERTS.DATE': 'Datum',
	'AUTOMATION_ALERTS.USER': 'Gebruiker',
	'AUTOMATION_ALERTS.ACTION': 'Actie',
	// regels
	'AUTOMATION.ACTIVATE': 'Activeren',
	'AUTOMATION.DEACTIVATE': 'Deactiveren',
	'AUTOMATION.RULE_CREATED_OR_EDITED': 'U hebt een automatiseringsregel geselecteerd die uitgebreide verwerking vereist en niet onmiddellijk wordt ingesteld.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': 'Automatiseringsregel is geactiveerd',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'Automatiseringsregel is niet geactiveerd',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': 'Automatiseringsregel is gedeactiveerd',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': 'Automatiseringsregel is niet gedeactiveerd',
	'AUTOMATION.SHORT_CODE': 'Sms-nummer',
	'AUTOMATION.BASE_RATE_PLAN': 'Basistariefplan',
	'AUTOMATION.RATE_PLAN_TYPE': 'Type tariefplan',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'Implementatiestatus',
	'AUTOMATION.YES': 'Ja',
	'AUTOMATION.NO': 'Nee',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Status regelwijziging',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': 'Weet u zeker dat u dat wilt doen?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': 'U staat op het punt om van een regel de status {currentState} te wijzigen in {selectedState}',
	'AUTOMATION.REQUEST_DELETION_TEXT': 'U staat op het punt om te verzoeken een regel te verwijderen',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Verwijdering regel',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': 'U staat op het punt om regel {ruleName} te verwijderen',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Verwijderingsverzoek automatiseringsregel',
	'AUTOMATION.REQUEST_DELETION': 'Verzoek om verwijdering',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': 'U hebt de implementatiestatus gewijzigd in Ontvangen',
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': 'U hebt de implementatiestatus gewijzigd in Geïmplementeerd',
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': 'U hebt de implementatiestatus gewijzigd in Conflicterend',
	'AUTOMATION.SET_RULE_STATE_FAIL': 'Er is een fout opgetreden tijdens het wijzigen van de status. Probeer het opnieuw.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': 'Het verzoek om de regel te verwijderen is ingediend.',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': 'Er is een fout opgetreden tijdens het verzoek om een regel te verwijderen.',
	'AUTOMATION.RULE_CHANGE': 'Wijziging automatiseringsregel',
	'AUTOMATION.RULE_CREATE': 'Automatiseringsregel maken',
	'AUTOMATION.ACCEPT': 'Accepteren',
	'AUTOMATION.MANUAL_RULE_DELAY': 'Er zal vertraging zijn bij de implementatie van deze regel.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': 'Het vergt zekere tijd om deze regel uit te voeren.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': 'Ik besef dat er vertraging in het implementatieproces zal zijn.',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Actie selecteren:',
	// statussen
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'Verwijderen in behandeling',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Geïmplementeerd',
	'AUTOMATION.RULE_STATUS_PENDING': 'In behandeling',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'Conflicterend',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Ontvangen',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'Niet van toepassing',
	//  Model zone
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'ATT-standaardzone 1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'ATT-standaardzone 2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'ATT-standaardzone 3',
	//  Naam zone
	'AUTOMATION.US_DOMESTIC': 'Binnen VS',
	'AUTOMATION.EU': 'EU'
};
