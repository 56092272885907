import React, { PureComponent } from 'react';
import {
	object,
	func,
	string,
} from 'prop-types';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import {
	getDateFormat,
	startOfDayFormatter,
} from '../../../../../utils/constants';

import styles from '../NetworkEntitlementChangeStepper.scss';

export default class StepSecond extends PureComponent {
	dateFormat = 'yyyy-MM-dd';

	constructor(props) {
		super(props);

		this.state = {
			selectedDay: new Date(),
		};
	}

	componentDidMount() {
    const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
    const { context } = this.props;
    const { selectedDay } = this.state;

		if (context && !isEqual(selectedDay, prevState.selectedDay)) {
			this.setFooter(context);
		}
	}

	handleOnClick = () => {
    const {
      changeNetworkEntitlement,
			endpointId,
      chosenEntitlementId,
      onNext
		} = this.props;
    const { selectedDay } = this.state;

		if (
			format(new Date(), this.dateFormat)
			!== format(selectedDay, this.dateFormat)
		) {
			changeNetworkEntitlement(
				endpointId,
				chosenEntitlementId,
				startOfDayFormatter(selectedDay)
			);
			onNext({
				startDate: format(selectedDay, this.dateFormat)
			});
		} else {
			changeNetworkEntitlement(
				endpointId,
				chosenEntitlementId,
			);
			onNext();
		}
	};

	onDateChange = (date) => this.setState({ selectedDay: date });

	setFooter = (context) => {
    const { onClose } = this.props;
    const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					variant="primary"
					onClick={this.handleOnClick}
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule Change"
							/>
						) : (
							<FormattedMessage
								id="INITIATE_CHANGE"
								defaultMessage="Initiate Change"
							/>
						)
					}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
					dataSpec="schedule-initiate-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
    const { networkEntitlementName } = this.props;
    const { selectedDay } = this.state;

		return (
			<div data-spec="second-step" className={styles.custom}>
				<div data-spec="ds-modal-body" className={styles.custom}>
					<div className={styles.entitlements_title}>
						<FormattedMessage
							id="SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE"
							defaultMessage="Confirm Network Entitlement Change"
						/>
					</div>
					<div className={styles.schedule_initiate_message}>
						<span>
							<FormattedMessage
								id="SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE"
								defaultMessage="Once you initiate this change, the selected endpoint’s Network Entitlement will be "
							/>
						</span>
						<span>{networkEntitlementName}</span>
						<span>
							<FormattedMessage
								id="SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION"
								defaultMessage=" Would you like to proceed with this action?"
							/>
						</span>
					</div>
					<div>
						<FormattedMessage
							id="SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR"
							defaultMessage="Or you can schedule the change for: "
						/>
						<DSDatePicker
							value={selectedDay}
							onDayChange={this.onDateChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

StepSecond.propTypes = {
	onClose: func,
	networkEntitlementName: string,
	onNext: func,
	changeNetworkEntitlement: func,
	endpointId: string,
	chosenEntitlementId: string,
	context: object
};
