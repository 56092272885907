import { connect } from 'react-redux';

import Eula from './Eula';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import {
	createUserSettingsSuccess,
	createUserSettingsFail,
	createUserSettingsPending
} from '../../../../redux/user/createUserSettings/selectors';

import {
	getUser
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	isEulaUpdating: createUserSettingsPending(state),
	isEulaUpdateFail: createUserSettingsFail(state),
  isEulaUpdateSuccess: createUserSettingsSuccess(state),
  user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	updateUserEula: () =>
		dispatch(createUserSettings({ refTypeId: 3, name: 'eula', value: 'true' }))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Eula);
