import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import EditAccount from './EditAccount';

import { getAccount, getAccountInit } from '../../redux/accounts/actions';

import {
	getAccountRequest,
	getAccountFail,
	getAccountSuccess
} from '../../redux/accounts/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

import { getCompanyToken } from '../../redux/companies/actions';
import {
	getCompanyTokenRequest,
	getCompanyTokenSuccess
} from '../../redux/companies/selectors';

const mapStateToProps = (state) => ({
	getAccountRequest: getAccountRequest(state),
	getAccountFail: getAccountFail(state),
	account: getAccountSuccess(state),
	user: getUser(state),
	getCompanyTokenRequest: getCompanyTokenRequest(state),
	getCompanyTokenSuccess: getCompanyTokenSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAccount: (params) => dispatch(getAccount(params)),
	getAccountInit: () => dispatch(getAccountInit()),
	pushBack: (url) => dispatch(localizedRoute(url)),
	goToNetworkEntitlements: (url) => dispatch(localizedRoute(url)),
	goToSimOrdering: (url) => dispatch(localizedRoute(url)),
	goToReports: (url) => dispatch(localizedRoute(url)),
	goToBillings: (url) => dispatch(localizedRoute(url)),
	goToRatePlans: (url) => dispatch(localizedRoute(url)),
  goToEndpoints: (url) => dispatch(localizedRoute(url)),
  getCompanyToken: (params) => dispatch(getCompanyToken(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount);
