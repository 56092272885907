import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getRatePlanZones = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/rate-plan-zones?${qs.stringify(
			params.params
		)}`
	);

export const getRatePlanZonesFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/rate-plan-zones/filters?${qs.stringify(params.params)}`
	);
