import globalAction from '../../../../redux/global/globalAction';
import { downloadTemplate as downloadTemplateService } from '../../services/SecurityFeaturesService';
import { DOWNLOAD_SECURITY_FEATURES_TEMPLATE } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const downloadTemplate = (params) =>
	globalAction(
		DOWNLOAD_SECURITY_FEATURES_TEMPLATE,
		downloadTemplateService,
		params
	);
