import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';

import styles from './GenerateReportModal.scss';

class GenerateReportModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			input: ''
		};
	}

	onChange = (e) => {
		this.setState({
			input: e.currentTarget.value.replace(/[^a-zA-Z0-9]/g, '')
		});
	};

	onCancel = () => {
    const { onCancel } = this.props;
		onCancel();
		this.setState({
			input: ''
		});
	};

	renderMessages = () => {
    const { totalCount } = this.props;
    const { input } = this.state;

		return (
			<div data-spec="body-message" className={styles.prompt__body}>
				<div className={styles.modal_text_block}>
					<div className={styles.modal_message}>
						<p>
							<span>{totalCount}</span>
							&nbsp;
							{totalCount > 1 ? (
								<FormattedMessage
									id="ENDPOINTS.ENDPOINTS_ARE"
									defaultMessage="endpoints are selected for the export."
								/>
							) : (
								<FormattedMessage
									id="ENDPOINTS.ENDPOINT_IS"
									defaultMessage="endpoint is selected for the export."
								/>
							)}
							&nbsp;
							<FormattedMessage
								id="ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE"
								defaultMessage="This custom report will be available in the reports section."
							/>
						</p>
						<p className={styles.report_name}>
							<FormattedMessage
								id="ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME"
								defaultMessage="You can append custom name to the report here"
							/>
							:
						</p>
						<FormattedMessage
							id="ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX"
							defaultMessage="Add custom report name appendix (only digits and letters allowed)"
						>
							{(formattedValue) => (
								<Input
									value={input}
									onChange={this.onChange}
									dataSpec="report name appendix"
									label={
										<FormattedMessage
											id="ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX"
											defaultMessage="Custom report name appendix"
										/>
									}
									placeholder={formattedValue}
									maxLength="50"
								/>
							)}
						</FormattedMessage>
					</div>
				</div>
			</div>
		);
	};

	renderButtons = (footerProps) => {
    const { onClick } = this.props;
    const { input } = this.state;

		return (
			<div data-spec="buttons">
				<Button
					onClick={footerProps.closeModal}
					variant="link"
					label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
				/>
				<Button
					dataSpec="go-to-reports"
					variant="primary"
					disabled={input === ''}
					onClick={() => onClick(input)}
					label={
						<FormattedMessage
							id="ENDPOINTS.CREATE_REPORT"
							defaultMessage="Create report"
						/>
					}
				/>
			</div>
		);
	};

	render() {
		const { show } = this.props;
		return (
			<Modal
				data-spec="dashboard-prompt-modal"
				show={show}
				onClose={this.onCancel}
				className={styles.modal}
			>
				<ModalHeader
					title={
						<FormattedMessage
							id="ENDPOINTS.CREATE_CUSTOM_REPORT"
							defaultMessage="Create custom report"
						/>
					}
				/>
				<ModalBody className={styles.modal_body}>
					{this.renderMessages()}
				</ModalBody>
				<ModalFooter
					data-spec="ds-modal-footer"
					className={styles.modal_footer}
				>
					{(footerProps) => this.renderButtons(footerProps)}
				</ModalFooter>
			</Modal>
		);
	}
}

const { func, bool, number } = PropTypes;
GenerateReportModal.propTypes = {
	onCancel: func,
	show: bool,
	totalCount: number,
	onClick: func
};

export default GenerateReportModal;
