import { connect } from 'react-redux';
import PermissionsDesktop from './Desktop';

import {
	getUser
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	currentUser: getUser(state)
});

export default connect(
	mapStateToProps
)(PermissionsDesktop);
