import { createAction } from 'redux-actions';
import globalAction, {
	actionInit,
} from '../../../../redux/global/globalAction';

import {
	GET_USERS,
	GET_USER,
	EDIT_USER,
	ADD_USERS_MODAL,
	REMOVE_USER_MODAL,
	PAUSE_USER_MODAL,
	INVITE_USERS,
	ADD_USER_GROUP_MODAL,
	PAUSE_USER,
	REACTIVATE_USER,
	RESET_USER_PASSWORD,
	REMOVE_USER,
	GET_ADDRESS_TYPES,
	DELETE_USER_PROFILE,
	GET_COUNTRIES,
	GET_FIRST_ADDRESS_STATES,
	GET_SECOND_ADDRESS_STATES,
	GET_THIRD_ADDRESS_STATES,
	GET_LANGUAGES,
	GET_USER_USAGE_DATA_CSV,
} from './constants';

import {
	getUsers as getUsersService,
	getUser as getUserService,
	editUser as editUserService,
	inviteUsers as inviteUserService,
	pauseUser as pauseUserService,
	reactivateUser as reactivateUserService,
	resetUserPassword as resetUserPasswordService,
	removeUser as removeUserService,
	inviteSystemUsers as inviteSystemUsersService,
	getAddressTypes as addressTypesUsersService,
	deleteUserProfile as deleteUserProfileService,
	getCountries as getCountriesService,
	getStates as getStatesService,
	getLanguages as getLanguagesService,
	exportUserUsageData as exportUserUsageDataService,
} from '../../services/UsersService';
import { requestUserData } from '../../../../redux/user/getUserData/actions';
import { downloadHelper } from '../../../AnalyticsInvoices/redux/invoiceAttachment/actions';

/* ******************** ADD USER GROUP modal ******************** */
export const openAddUserGroupModal = createAction(
	`OPEN_${ADD_USER_GROUP_MODAL}`
);
export const closeAddUserGroupModal = createAction(
	`CLOSE_${ADD_USER_GROUP_MODAL}`
);

/* ******************** GET USERS ******************** */
export const getUsers = (params) =>
	globalAction(GET_USERS, getUsersService, params);

/* ******************** GET USER ******************** */
export const getUser = (id, params) =>
	globalAction(GET_USER, getUserService, { id, params });

export const getUserInit = () => actionInit(GET_USER);

/* ******************** EDIT USER ******************** */
export const editUser = (params) =>
	globalAction(EDIT_USER, editUserService, params, {
		notify: 'edit-user',
		init: true,
    modals: closeAddUserGroupModal(),
		final: requestUserData(),
	});

export const editUserInit = () => actionInit(EDIT_USER);

/* ******************** ADD USERS MODAL ******************** */
export const openAddUsersModal = createAction(`OPEN_${ADD_USERS_MODAL}`);
export const closeAddUsersModal = createAction(`CLOSE_${ADD_USERS_MODAL}`);

export const inviteUsers = (params) =>
	globalAction(INVITE_USERS, inviteUserService, params, {
		notify: 'invite-users',
		init: true,
	});

/* ******************** REMOVE USER MODAL ******************** */
export const openRemoveUserModal = createAction(`OPEN_${REMOVE_USER_MODAL}`);
export const closeRemoveUserModal = createAction(`CLOSE_${REMOVE_USER_MODAL}`);

/* ******************** PAUSE USER MODAL ******************** */
export const openPauseUserModal = createAction(`OPEN_${PAUSE_USER_MODAL}`);
export const closePauseUserModal = createAction(`CLOSE_${PAUSE_USER_MODAL}`);

/* ******************** PAUSE USER ******************** */
export const getPauseUser = (params) =>
	globalAction(PAUSE_USER, pauseUserService, params, {
		notify: 'users',
	});

/* ******************** REACTIVATE USER ******************** */
export const getReactivateUser = (params) =>
	globalAction(REACTIVATE_USER, reactivateUserService, params, {
		notify: 'users',
	});

/* ******************** RESET USER PASSWORD ******************** */
export const getPasswordResetUser = (params) =>
	globalAction(RESET_USER_PASSWORD, resetUserPasswordService, params, {
		notify: 'users',
	});

/* ******************** REMOVE USER ******************** */
export const getRemoveUser = (params) =>
	globalAction(REMOVE_USER, removeUserService, params, {
		notify: 'users',
	});

export const inviteSystemUsers = (params) =>
	globalAction(INVITE_USERS, inviteSystemUsersService, params, {
		notify: 'invite-users',
		init: true,
	});

/* ******************** GET ADDRESS TYPES ******************** */
export const getAddressTypes = () =>
	globalAction(GET_ADDRESS_TYPES, addressTypesUsersService);

/* ******************** DELETE PROFILE ******************** */
export const deleteUserProfile = (params) =>
	globalAction(DELETE_USER_PROFILE, deleteUserProfileService, params);

/* ******************** GET COUNTRIES ******************** */
export const getCountries = (params) =>
	globalAction(GET_COUNTRIES, getCountriesService, params);

/* ******************** GET STATES ******************** */
export const getFirstAddressStates = (params) =>
	globalAction(GET_FIRST_ADDRESS_STATES, getStatesService, params);

export const getSecondAddressStates = (params) =>
	globalAction(GET_SECOND_ADDRESS_STATES, getStatesService, params);

export const getThirdAddressStates = (params) =>
	globalAction(GET_THIRD_ADDRESS_STATES, getStatesService, params);

/* ******************** GET LANGUAGES ******************** */
export const getLanguages = () =>
	globalAction(GET_LANGUAGES, getLanguagesService);

/* ******************** EXPORT USERS CSV ******************** */
const request = createAction(`${GET_USER_USAGE_DATA_CSV}_REQUEST`);
const success = createAction(`${GET_USER_USAGE_DATA_CSV}_SUCCESS`);
const fail = createAction(`${GET_USER_USAGE_DATA_CSV}_FAIL`);

export const exportUserUsageData = () => async (dispatch) => {
	dispatch(request());
	try {
		const data = await exportUserUsageDataService();
		dispatch(success(data));
		downloadHelper(false, 'UsersUsageDataExport.csv', data.data, 'text/csv:encoding:utf-8');
	} catch (error) {
		dispatch(fail(error.toString()));
	}
};
