import React, { Component } from 'react';
import { array, func } from 'prop-types';
import cn from 'classnames';

import Checkbox from '../../../../lib/DigitalComponents/Checkbox';

import styles from './EditAssignAccountAccordion.scss';

class EditAssignAccountAccordion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: []
		};
	}

	hasSubPlatforms = (level) =>
		Boolean(level && level.subPlatforms && level.subPlatforms.length);

	handleIconClick = (id) => {
		const { expanded } = this.state;
		if (expanded.includes(id)) {
			const index = expanded.indexOf(id);
			this.setState({
				expanded: [...expanded.slice(0, index), ...expanded.slice(index + 1)]
			});
		} else {
			this.setState({
				expanded: [...expanded, id]
			});
		}
	};

	findLevel = (id) => {
		const { expanded } = this.state;
		return expanded.includes(id);
	};

	renderData = (accounts, count = 1) => {
		const { onCheckBoxChange, checkIfRowIsSelected } = this.props;
		const { expanded } = this.state;
		return accounts.map((level, index) => {
			const { m2mAccountName, id } = level;
			const classnames = cn({
				[styles.level]: true
			});

			const triggerIcon = cn({
				[styles.trigger__icon]: true,
				[styles.no_subplatforms]: !this.hasSubPlatforms(level),
				[styles.open]: this.findLevel(id)
			});

			const paddingLeft = 20 * count;

			const getRender = Boolean(
				level.subPlatforms && level.subPlatforms.length && expanded.includes(id)
			);
			return [
				<React.Fragment key={`level-${level.id}`}>
					<div
						data-spec={`level-${level.id}`}
						key={level.id}
						className={classnames}
						style={{ paddingLeft }}
					>
						<span
							onClick={() => this.handleIconClick(id)}
							className={triggerIcon}
						/>
						<div className={styles.data}>{m2mAccountName}</div>
						<div className={styles.data}>
							<Checkbox
								data-spec="checkbox"
								checked={checkIfRowIsSelected(level)}
								onChange={() => onCheckBoxChange(level, index)}
							/>
						</div>
					</div>
					{getRender && this.renderData(level.subPlatforms, count + 1)}
				</React.Fragment>
			];
		});
	};

	render() {
		const { data } = this.props;
		return <div data-spec="levels">{this.renderData(data, 1)}</div>;
	}
}

EditAssignAccountAccordion.propTypes = {
	data: array,
	onCheckBoxChange: func,
	checkIfRowIsSelected: func
};

export default EditAssignAccountAccordion;
