export default {
	'REPORTS.REPORT': 'Relatório',
	'REPORTS.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'REPORTS.M2M_PLATFORM': 'Plataforma M2M',
	'REPORTS.FREQUENCY': 'Frequência',
	'REPORTS.REPORT_DATE': 'Data do relatório',
	'REPORTS_TABS.ENDPOINTS': 'Endpoints',
	'REPORTS_TABS.USAGE_SUMMARY': 'Resumo de uso',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Atividades de transferência',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Resumo da conta',
	'REPORTS_TABS.BILLING_DETAILS': 'Detalhes da cobrança',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Relatórios de referência',
	'REPORTS_TABS.MY_EXPORTS': 'Minhas exportações',
	'REPORTS_TABS.USAGE_REPORTS': 'Relatórios de uso',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Relatórios republicados',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'Nenhum relatório disponível',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': 'Não há relatórios para exibir nesta tabela no momento',
	'REPORTS.FILE_NAME': 'Nome do arquivo',
	'REPORTS.FILE_DATE': 'Data do arquivo',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Requerido por'
};
