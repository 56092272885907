/* eslint-disable import/prefer-default-export */
import { connect } from 'react-redux';

import {
	getFormValues,
	isDirty,
	isInvalid
} from 'redux-form';
import {
	getUserGroups,
} from '../../redux/userGroups/actions';
import {
	inviteUsers,
	inviteSystemUsers
} from '../../redux/users/actions';

import {
	getUserGroupsFail,
	getUserGroupsSuccess,
	getUserGroupsRequest
} from '../../redux/userGroups/selectors';

import AddUserModal from './AddUsersModal';

const mapStateToProps = (state) => ({
  userGroupsRequest: getUserGroupsRequest(state),
	userGroupsFail: getUserGroupsFail(state),
	userGroups: getUserGroupsSuccess(state),
	formValues: getFormValues('InviteNewUser')(state),
	isDirty: isDirty('InviteNewUser')(state),
	isInvalid: isInvalid('InviteNewUser')(state)
});

const mapDispatchToProps = (dispatch) => ({
	getUserGroups: (data) => dispatch(getUserGroups(data)),
	inviteUsers: (data) => dispatch(inviteUsers(data)),
	inviteSystemUsers: (data) => dispatch(inviteSystemUsers(data))
 });

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddUserModal);
