import { Status } from '../../../utils/constants';

export const createUserSettingsData = (state) =>
	state.user.createUserSettings.data;
export const createUserSettingsSuccess = (state) =>
	state.user.createUserSettings.status === Status.DONE;
export const createUserSettingsPending = (state) =>
	state.user.createUserSettings.status === Status.PENDING;
export const createUserSettingsFail = (state) =>
	state.user.createUserSettings.status === Status.FAIL;
