import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import UserGroupAssignedAccounts from './UserGroupAssignedAccounts';

import { getAccounts, getAccountInit } from '../../redux/accounts/actions';
import {
	getAccountsRequest,
	getAccountsFail,
	getAccountsSuccess
} from '../../redux/accounts/selectors';

const mapStateToProps = (state) => ({
	accountsRequest: getAccountsRequest(state),
	accountsFail: getAccountsFail(state),
	accountsSuccess: getAccountsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAccounts: (params) => dispatch(getAccounts(params)),
	getAccountsInit: () => dispatch(getAccountInit()),
	push: (url) => dispatch(localizedRoute(url)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserGroupAssignedAccounts);
