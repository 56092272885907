import React from 'react';
import { FormattedMessage } from 'react-intl';

const dateFormat = 'MM/dd/yyyy';

const getSearchMenu = (id) => [
		{
			label: (
				<div>
					<FormattedMessage id="BY" defaultMessage="By" />
					<FormattedMessage
						id={`SECURITY_FEATURES.MENU_${id}`}
						defaultMessage="Order"
					/>
				</div>
			),
			value: 'customerSecurityFeatureTypeValue'
		},
		{
			label: (
				<div>
					<FormattedMessage id="BY" defaultMessage="By" />
					<FormattedMessage
						id="DATE_SUBMITTED"
						defaultMessage="Date Submitted"
					/>
				</div>
			),
			value: 'createdAt',
			placeholder: dateFormat
		}
	];

export default getSearchMenu;
