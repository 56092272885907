import { connect } from 'react-redux';
import DesktopComponent from './Desktop';
import MobileComponent from './Mobile';

import {
	getAutomationAlerts,
	removeAutomationAlert
} from '../../redux/actions';

import {
	setSelectedItems,
	setSelectedItemsInit
} from '../../redux/alerts/selectedItems/actions';

import {
	getAutomationAlertsFail,
	getAutomationAlertsPending,
	getAutomationAlertsSuccess,
	getAutomationAlertsTotalCount,
	removeAutomationAlertPending,
	removeAutomationAlertSuccess
} from '../../redux/selectors';
import { selectedItems } from '../../redux/alerts/selectedItems/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';
import {
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import { openFilterModal } from '../../../Shared/redux/filterModal/actions';

const mapStateToProps = (state) => ({
	alertsRequest: getAutomationAlertsPending(state),
	alertsFail: getAutomationAlertsFail(state),
	alerts: getAutomationAlertsSuccess(state),
	totalCount: getAutomationAlertsTotalCount(state),
	deleteAlertRequest: removeAutomationAlertPending(state),
	removeAlertSuccess: removeAutomationAlertSuccess(state),
	filterData: filterDataSuccess(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
	selectedItems: selectedItems(state),
	user: getUser(state)
});
const mapDispatchToProps = (dispatch) => ({
	getAlerts: (params) => dispatch(getAutomationAlerts(params)),
	deleteAlert: (data) => dispatch(removeAutomationAlert(data)),
	setSelectedItems: (data) => dispatch(setSelectedItems(data)),
	setSelectedItemsInit: () => dispatch(setSelectedItemsInit()),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	openFilterModal: () => dispatch(openFilterModal())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopComponent);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileComponent);
