import React, { PureComponent } from 'react';
import { object, func } from 'prop-types';

import Loader from '../../../../lib/DigitalComponents/Loader';

export default function Hardware(WrapperComponent) {
	class HardwareComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				isStatusPromptModalOpen: false
			};
		}

		componentDidMount() {
			const { availableStates, getAvailableStates } = this.props;

			if (!availableStates) {
				getAvailableStates();
			}
		}

		openPromptModal = () => {
			this.setState({
				isStatusPromptModalOpen: true
			});
		};

		closePromptModal = () => {
			this.setState({
				isStatusPromptModalOpen: false
			});
		};

		render() {
			const {
				availableStates,
				endpoint,
			} = this.props;
			const { isStatusPromptModalOpen } = this.state;

			if (!availableStates) {
				return <Loader data-spec="loader" />;
			}

			return (
				<div data-spec="hardware">
					<WrapperComponent
						endpoint={endpoint}
						openPromptModal={this.openPromptModal}
						closePromptModal={this.closePromptModal}
						isStatusPromptModalOpen={isStatusPromptModalOpen}
					/>
				</div>
			);
		}
	}

	HardwareComponent.propTypes = {
		endpoint: object,
		availableStates: object,
		getAvailableStates: func,
	};

	const wrapperComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	HardwareComponent.displayName = `Hardware(${wrapperComponentName}`;

	return HardwareComponent;
}
