import React, { PureComponent } from 'react';

const Actions = () => (WrappedComponent) => {
	class ActionsComponent extends PureComponent {
		render() {
			return (
				<div data-spec="automation-actions">
					<WrappedComponent {...this.props} />
				</div>
			);
		}
	}

	return ActionsComponent;
};

export default Actions;
