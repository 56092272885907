import { Status } from '../../../../utils/constants';

export const getTaxesRequest = (state) =>
	state.analyticsInvoices.tables.taxes.getTaxes.status === Status.PENDING;
export const getTaxesFail = (state) =>
	state.analyticsInvoices.tables.taxes.getTaxes.status === Status.FAIL;
export const getTaxesSuccess = (state) =>
	state.analyticsInvoices.tables.taxes.getTaxes.data;

export const getTaxesFiltersRequest = (state) =>
	state.analyticsInvoices.tables.taxes.getTaxesFilters.status ===
	Status.PENDING;
export const getTaxesFiltersFail = (state) =>
	state.analyticsInvoices.tables.taxes.getTaxesFilters.status === Status.FAIL;
export const getTaxesFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.taxes.getTaxesFilters.data;
