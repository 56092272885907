import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	SF_EXPORT_TO_CSV
} from './constants';

export default combineReducers({
	exportToCsv: globalReducer(SF_EXPORT_TO_CSV)
});
