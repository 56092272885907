import { connect } from 'react-redux';
import Automation from './Automation';

import {
	getAutomationAlertsPending,
	getAutomationRulesPending
} from '../../redux/selectors';

import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';

import { setSelectedItemsInit } from '../../redux/alerts/selectedItems/actions';

const mapStateToProps = (state) => ({
	automationAlertsRequest: getAutomationAlertsPending(state),
	automationRulesRequest: getAutomationRulesPending(state)
});

const mapDispatchToProps = (dispatch) => ({
  setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
  setSelectedItemsInit: () => dispatch(setSelectedItemsInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(Automation);
