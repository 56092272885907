import React, { Component } from 'react';
import { array, string, func, bool, object, any } from 'prop-types';
import {
	FormattedMessage,
	injectIntl,
} from 'react-intl';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import Button from '../../../../lib/DigitalComponents/Button';
import CustomFieldsValuesView from '../../views/CustomFieldsValuesView';
import {
	ComponentsRender,
	isUserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import { allowedServiceProviders } from '../../../../utils/constants';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';
import SimStateChangeStepper from '../SimStateChangeStepper';
import NetworkEntitlementChangeStepper
	from '../NetworkEntitlementChangeStepper';
import { findEntitlement } from '../../../../utils/helperFunctions';
import RatePlanChangeStepper from '../RatePlanChangeStepper';
import SingleEndpointActionModalContextProvider,
{
	SingleEndpointActionModalContextConsumer,
	SingleEndpointActionModalContext,
} from './SingleEndpointActionModalContext';

import styles from './SingleEndpointActionsModal.scss';

const EndpointsRWComponent = ComponentsRender('mnc.endpoints_rw');
const EndpointsROComponent = ComponentsRender('mnc.endpoints_ro');

class SingleEndpointActionsModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTabId: 'simStateChangeSteps',
		};
	}

	componentDidMount() {
		const { show, anyModalOpened } = this.props;

		show && anyModalOpened();
	}

	componentDidUpdate(prevProps) {
		const { show } = this.props;

		if (!show && prevProps.show) {
			this.setActiveTabId();
		}
	}

	componentWillUnmount() {
		const { anyModalClosed } = this.props;

		anyModalClosed();
	}

	setActiveTabId = () => this.setState({ activeTabId: 'simStateChangeSteps' });

	closeModal = () => {
		const { onClose } = this.props;

		onClose();
	};

	onFinish = () => {
		const { onClose } = this.props;

		onClose();
	};

	mergeValues = (values) => {
		const { state } = this;

		this.setState({
			...state,
			...values,
		});
	};

	mergeValuesPlan = (values) => {
		const { ratePlan } = this.state;

		this.setState({
			ratePlan: {
				...ratePlan,
				...values
			}
		});
	};

	simStateChangeError = () => {
		const { isPendingStatus } = this.props;
		const { activeTabId } = this.state;

		return activeTabId === 'simStateChangeSteps' && isPendingStatus;
	};

	renderStatusChangePending = () => (
		<div data-spec="pending-error">
			<div className={styles.modal_body_error_message}>
				<FormattedMessage
					id="STATUS_CHANGE_PENDING"
					defaultMessage="There is a Scheduled Activity for this Endpoint"
				/>
			</div>
		</div>
	);

	renderSimStateChangeTabContent = () => {
		const {
			isPendingStatus,
			singleEndpointUpdateFail,
			onClose,
			endpoint,
			endpointId,
			user,
			getSingleEndpoint,
			type,
		} = this.props;
		const { activeTabId, newState } = this.state;

		if (isPendingStatus && activeTabId === 'simStateChangeSteps') {
			return this.renderStatusChangePending();
		}

		return (
			<SimStateChangeStepper
				data-spec="simStateChangeSteps"
				ModalContextConsumer={SingleEndpointActionModalContextConsumer}
				includeZeroStep
				onNext={this.mergeValues}
				onBack={this.mergeValues}
				onFinish={this.onFinish}
				isActionError={singleEndpointUpdateFail}
				stepZeroProps={{
					onCancel: onClose,
					newState,
					endpoint,
					endpointId,
				}}
				stepFirstProps={{
					onCancel: onClose,
					newState,
					endpoint,
					user
				}}
				stepSecondProps={{
					values: this.state,
					onCancel: onClose,
					getSingleEndpoint,
					type,
					endpointName: endpointId
				}}
			/>
		);
	};

	networkEntitlementChangeError = () => {
		const { endpoint, isPendingStatus, entitlements } = this.props;
		const { activeTabId } = this.state;

		return (
			activeTabId === 'changeNetworkEntitlement' &&
			(isPendingStatus ||
				(!endpoint ||
					!endpoint.m2mAccountId ||
					!entitlements) ||
				!findEntitlement(
					entitlements,
					endpoint.networkEntitlementId
				))
		);
	};

	renderChangeNetworkEntitlementTabContent = () => {
		const {
			isPendingStatus,
			endpoint,
			entitlements,
			endpointId,
			changeNetworkEntitlement,
			isChanging,
			changeSuccess,
			changeFail,
			getSingleEndpoint,
			type,
			intl,
		} = this.props;
		const {
			chosenEntitlementId,
			chosenEntitlementName,
			lastStep,
			startDate,
		} = this.state;

		if (isPendingStatus) {
			return this.renderStatusChangePending();
		}

		if (!endpoint || !endpoint.m2mAccountId || !entitlements) {
			return (
				<div data-spec="not-allowed-error">
					<div className={styles.modal_body_error_message}>
						<FormattedMessage
							id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT"
							defaultMessage="Error on network entitlement, please contact Customer Support."
						/>
					</div>
				</div>
			);
		}

		if (
			endpoint &&
			endpoint.serviceProvider &&
			endpoint.m2mAccountId &&
			entitlements
		) {
			const entitlement = findEntitlement(
				entitlements,
				endpoint.networkEntitlementId
			);

			if (!entitlement) {
				return (
					<div data-spec="not-allowed-error">
						<div className={styles.modal_body_error_message}>
							<FormattedMessage
								id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT"
								defaultMessage="Error on network entitlement, please contact Customer Support."
							/>
						</div>
					</div>
				);
			}
		}

		return (
			<NetworkEntitlementChangeStepper
				data-spec="changeNetworkEntitlement"
				ModalContextConsumer={SingleEndpointActionModalContextConsumer}
				onNext={this.mergeValues}
				onBack={this.mergeValues}
				onFinish={this.closeModal}
				entitlements={entitlements}
				networkEntitlementId={endpoint.networkEntitlementId}
				stepFirstProps={{
					endpointId,
					chosenEntitlementId: endpoint.networkEntitlementId,
					onClose: this.closeModal,
					m2mAccountId: endpoint.m2mAccountId,
				}}
				stepSecondProps={{
					onClose: this.closeModal,
					chosenEntitlementId,
					endpointId,
					networkEntitlementName: chosenEntitlementName,
					changeNetworkEntitlement,
				}}
				stepThirdProps={{
					onClose: this.closeModal,
					chosenEntitlementId,
					endpointId,
					lastStep,
					isChanging,
					changeSuccess,
					changeFail,
					startDate,
					getSingleEndpoint,
					type,
				}}
				intl={intl}
			/>
		);
	};

	ratePlanChangeError = () => {
		const { isPendingStatus, ratePlans, endpoint } = this.props;
		const { activeTabId } = this.state;

		return (
			activeTabId === 'changeRatePlan' &&
			(isPendingStatus ||
				(ratePlans && ratePlans.length === 1 &&
					ratePlans[0].ratePlan.id ===
						endpoint.ratePlanId) ||
				allowedServiceProviders.indexOf(
					endpoint.serviceProvider &&
						endpoint.serviceProvider.toLowerCase()
				) === -1)
		);
	};

	renderRatePlanChangeTabContent = () => {
		const {
			isPendingStatus,
			ratePlans,
			endpoint,
			ratePlanUpdateFail,
			endpointId,
			getSingleEndpoint,
			type
		} = this.props;

		const { ratePlan } = this.state;

		if (isPendingStatus) {
			return this.renderStatusChangePending();
		}

		if (ratePlans && ratePlans.length > 0) {
			if (
				(ratePlans && ratePlans.length === 1 &&
					(ratePlans[0].ratePlan.id ===
						endpoint.ratePlanId &&
						endpoint.ratePlanId)) ||
				allowedServiceProviders.indexOf(
					endpoint.serviceProvider &&
					endpoint.serviceProvider.toLowerCase()
				) === -1
			) {
				return (
					<div data-spec="not-allowed-error">
						<div className={styles.modal_body_error_message}>
							<FormattedMessage
								id="NO_RATE_PLANS_AVAILABLE"
								defaultMessage="No rate plans available"
							/>
						</div>
					</div>
				);
			}
      return (
	      <RatePlanChangeStepper
		      data-spec="changeRatePlanSteps"
		      ModalContextConsumer={SingleEndpointActionModalContextConsumer}
		      onNext={this.mergeValuesPlan}
		      onBack={this.mergeValuesPlan}
		      onFinish={this.closeModal}
		      isActionError={ratePlanUpdateFail}
		      stepFirstProps={{
			      onClose: this.closeModal,
			      endpointId,
			      ratePlanId: endpoint.ratePlanId
		      }}
		      stepSecondProps={{
			      onClose: this.closeModal,
			      ratePlans: ratePlan,
			      endpointId
		      }}
		      stepThirdProps={{
			      onClose: this.closeModal,
			      ratePlans: ratePlan,
			      getSingleEndpoint,
			      type,
			      endpointId
		      }}
	      />
			);
    }
    return (
			<div
				data-spec="not-allowed-error"
				className={styles.modal_body_error_message}
			>
				<FormattedMessage
					id="NO_RATE_PLANS_AVAILABLE"
					defaultMessage="No rate plans available"
				/>
			</div>
		);
	};

	changeActiveTab = (tab) => {
		const {
			changeNetworkEntitlementInit,
		} = this.props;

		this.setState({ activeTabId: tab.id });
		changeNetworkEntitlementInit();
	};

	render() {
		const {
			onClose,
			user,
			endpointId,
			isPendingStatus,
			type,
			endpoint,
			customFieldLabels,
			showLoaderOnCustomValuesTab,
			show,
			intl,
		} = this.props;
		const { activeTabId } = this.state;
		const thereIsAnError = this.simStateChangeError() ||
			this.networkEntitlementChangeError() ||
			this.ratePlanChangeError();
		const tabFooters = {
			customFieldLabels: (
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage
						id="CANCEL"
						defaultMessage="Cancel"
					/>}
					dataSpec="cancel-button"
				/>
			)
		};
		const tabs = [
			{
				id: 'simStateChangeSteps',
				displayName: intl.formatMessage({
					id: 'CHANGE_SIM_STATE',
					defaultMessage: 'Change SIM State',
				}),
				tabContentClassName: styles.modal_tab_content,
				content: () => (
					<div key="simStateChangeSteps">
						{this.renderSimStateChangeTabContent()}
					</div>
				),
				hide: !isUserAllowedToAccess(
					['mnc.endpoints.simstatus_rw', 'mnc.endpoints.simstatus_ro'],
					user
				)
			},
			{
				id: 'changeRatePlan',
				displayName: intl.formatMessage({
					id: 'CHANGE_RATE_PLAN',
					defaultMessage: 'Change Rate Plan',
				}),
				tabContentClassName: styles.modal_tab_content,
				content: () => (
					<div key="changeRatePlan">
						{this.renderRatePlanChangeTabContent()}
					</div>
				),
				hide: !isUserAllowedToAccess(
					['mnc.endpoints.rateplans_rw', 'mnc.endpoints.rateplans_ro'],
					user
				)
			},
			{
				id: 'changeNetworkEntitlement',
				displayName: intl.formatMessage({
					id: 'CHANGE_NETWORK_ENTITLEMENTS',
					defaultMessage: 'Change Network Entitlements',
				}),
				tabContentClassName: styles.modal_tab_content,
				content: () => (
					<div key="changeNetworkEntitlement">
						{this.renderChangeNetworkEntitlementTabContent()}
					</div>
				),
				hide: !isUserAllowedToAccess(
					[
						'mnc.endpoints.networkentitlements_rw',
						'mnc.endpoints.networkentitlements_ro'
					],
					user
				)
			},
			{
				id: 'customFieldLabels',
				displayName: intl.formatMessage({
					id: 'CUSTOM_DATA_FIELDS',
					defaultMessage: 'Custom Data Fields',
				}),
				content: () => (
					<div className={styles.customFieldLabelsContainer}>
						<EndpointsRWComponent>
							<CustomFieldsValuesView
								inModal
								endpointName={endpointId}
								renderAction={!isPendingStatus}
								type={type}
								endpoint={endpoint}
								customFieldLabels={customFieldLabels}
								showLoader={showLoaderOnCustomValuesTab}
							/>
						</EndpointsRWComponent>
						<EndpointsROComponent>
							<CustomFieldsValuesView
								inModal
								endpointName={endpointId}
								type={type}
								endpoint={endpoint}
								customFieldLabels={customFieldLabels}
							/>
						</EndpointsROComponent>
					</div>
				),
				hide: !isUserAllowedToAccess(
					['mnc.endpoints.customfields_rw', 'mnc.endpoints.customfields_ro'],
					user
				)
			}
		];

		return (
			<div data-spec="single-endpoint-actions-modal">
				<SingleEndpointActionModalContextProvider>
					<Modal
						data-spec="single-endpoint-actions-modal"
						show={show}
						onClose={this.closeModal}
						className={styles.modal}
					>
						<ModalHeader
							title={<FormattedMessage
								id="SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID"
								defaultMessage="Endpoint Actions for {endpointId}"
								values={{ endpointId }}
							/>}
						/>
						<ModalBody className={styles.modal_body_custom}>
							<ModalTabs
								tabs={tabs.filter((item) => !item.hide)}
								onTabChange={this.changeActiveTab}
							/>
						</ModalBody>
						{activeTabId && tabFooters[activeTabId] ? (
							<ModalFooter>{tabFooters[activeTabId]}</ModalFooter>
						) : (
							<ModalFooter>
								{!thereIsAnError ? (
									<SingleEndpointActionModalContext.Consumer>
										{(context) => context.state.footer}
									</SingleEndpointActionModalContext.Consumer>
								) : (
									<Button
										onClick={onClose}
										variant="link"
										label={<FormattedMessage
											id="CANCEL"
											defaultMessage="Cancel"
										/>}
									/>
								)}
							</ModalFooter>
						)}
					</Modal>
				</SingleEndpointActionModalContextProvider>
			</div>
		);
	}
}

SingleEndpointActionsModal.propTypes = {
	onClose: func,
	endpointId: any,
	endpoint: object,
	getSingleEndpoint: func,
	isPendingStatus: bool,
	type: string,
	customFieldLabels: object,
	ratePlans: array,
	anyModalOpened: func,
	anyModalClosed: func,
	showLoaderOnCustomValuesTab: bool,
	singleEndpointUpdateFail: bool,
	user: object,
	show: bool,
	entitlements: array,
	serviceProvider: string,
	networkEntitlementId: string,
	changeNetworkEntitlement: func,
	isChanging: bool,
	changeSuccess: bool,
	changeFail: bool,
	ratePlanUpdateFail: bool,
	ratePlan: string,
	intl: object,
	changeNetworkEntitlementInit: func,
};
SingleEndpointActionsModal.defaultProps = {
	onClose: undefined,
	endpointId: '',
	endpoint: {},
	getSingleEndpoint: undefined,
	isPendingStatus: false,
	type: '',
	customFieldLabels: {},
	ratePlans: [],
	anyModalOpened: undefined,
	anyModalClosed: undefined,
	showLoaderOnCustomValuesTab: false,
	singleEndpointUpdateFail: false,
	user: {},
	show: false,
	entitlements: [],
	serviceProvider: '',
	networkEntitlementId: '',
	changeNetworkEntitlement: undefined,
	isChanging: false,
	changeSuccess: false,
	changeFail: false,
	ratePlanUpdateFail: false,
	ratePlan: '',
	intl: {},
	changeNetworkEntitlementInit: undefined,
};

export default injectIntl(SingleEndpointActionsModal);
