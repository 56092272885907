import React, { PureComponent } from 'react';

import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

import Modal from '../../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../../lib/DigitalComponents/DSModal/ModalFooter';

import Button from '../../../../../lib/DigitalComponents/Button';
import Select from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Loading from '../../../../../lib/DigitalComponents/Loader';

import PermissionsView from '../../../views/PermissionsView/PermissionsView';
import NoData from '../../../../Shared/views/NoDataView';

import styles from './AddUserGroupModal.scss';

class AddUserGroupModal extends PureComponent {
	componentDidMount() {
		this.getUserGroups();
	}

	getUserGroups = () => {
		const { getUserGroups, system } = this.props;
		const params = {
			system,
		};
		getUserGroups(params);
	};

	filteredUserGroups = () => {
		const { userGroups, formData } = this.props;
		const choosenUserGroups =
			formData &&
			formData.userGroup &&
			formData.userGroup.length > 0 &&
			formData.userGroup.map((existingUserGroups) => existingUserGroups.id);
		if (choosenUserGroups) {
			return (
				userGroups &&
				userGroups.resultList &&
				userGroups.resultList.length > 0 &&
				userGroups.resultList
					.filter((item) => !choosenUserGroups.includes(item.id))
					.map((item) => ({
						value: item.id,
						label: item.name,
					}))
			);
		}
		return (
			userGroups &&
			userGroups.resultList &&
			userGroups.resultList.length > 0 &&
			userGroups.resultList.map((item) => ({
				value: item.id,
				label: item.name,
			}))
		);
	};

	render() {
		const {
			show,
			onClose,
			handleSubmit,
			formData,
			userGroupsRequest,
			invalid,
		} = this.props;

		const userGroupsCount = this.filteredUserGroups();

		return (
			<Modal data-spec="add-user-group-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.ASSIGN_USER_GROUP"
							defaultMessage="Assign User Group"
						/>
					}
					closeModal={onClose}
				/>
				<form onSubmit={handleSubmit}>
					<ModalBody>
						<div className={styles.modal_content}>
							<p className={styles.instructions}>
								<FormattedMessage
									id="ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO"
									defaultMessage="Select the group user should belong to: "
								/>
							</p>
							<div className={styles.column}>
								<FormattedMessage
									id="ONBOARDING.SELECT_USER_GROUP"
									defaultMessage="User Group"
								>
									{(formattedValue) => (
										<Field
											component={Select}
											name="choosenUserGroup"
											placeholder={formattedValue}
											options={this.filteredUserGroups()}
											label={formattedValue}
											cleanValue
										/>
									)}
								</FormattedMessage>
							</div>
							{userGroupsRequest && <Loading data-spec="loading" />}
							{userGroupsCount && userGroupsCount.length === 0 && (
								<NoData
									title={
										<FormattedMessage
											id="ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN"
											defaultMessage="There are no user groups to assign."
										/>
									}
									table
								/>
							)}
							{formData &&
								formData.choosenUserGroup &&
								formData.choosenUserGroup.value && (
									<PermissionsView
										{...this.props}
										id={formData.choosenUserGroup.value}
									/>
								)}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="link"
							label={
								<FormattedMessage
									id="ONBOARDING.CANCEL"
									defaultMessage="Cancel"
								/>
							}
							type="button"
							data-spec="close-button"
							onClick={onClose}
						/>
						<Button
							variant="primary"
							label={
								<FormattedMessage
									id="ONBOARDING.ASSIGN"
									defaultMessage="Assign"
								/>
							}
							type="submit"
							data-spec="save-button"
							disabled={
								invalid ||
								(formData && formData.choosenUserGroup === undefined) ||
								(formData && formData.choosenUserGroup === null) ||
								(userGroupsCount && userGroupsCount.length === 0)
							}
						/>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const { bool, func, object } = PropTypes;

AddUserGroupModal.propTypes = {
	onClose: func,
	show: bool,
	handleSubmit: func,
	userGroups: object,
	getUserGroups: func,
	formData: object,
	system: bool,
	userGroupsRequest: bool,
	invalid: bool,
};

AddUserGroupModal.defaultProps = {
	show: false,
};

const AddUserGroupForm = reduxForm({
	form: 'EditUserProfileForm',
	enableReinitialize: true,
	destroyOnUnmount: false,
})(AddUserGroupModal);

export default AddUserGroupForm;
