import React from 'react';
import { string, object, array, bool, func } from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import {
	DataOverviewDesktop,
	DataOverviewMobile,
} from '../../components/DataOverview';

export default function DataOverviewView({
	customFieldLabels,
	endpoint,
	isPendingStatus,
	getSingleEndpoint,
	type,
	endpointName,
	ratePlans,
	refreshEndpoint,
	entitlements,
}) {
	return (
		<>
			<DesktopMediaQuery>
				<DataOverviewDesktop
					customFieldLabels={customFieldLabels}
					endpoint={endpoint}
					isPendingStatus={isPendingStatus}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={endpointName}
					ratePlans={ratePlans}
					refreshEndpoint={refreshEndpoint}
					entitlements={entitlements}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<DataOverviewDesktop
					customFieldLabels={customFieldLabels}
					endpoint={endpoint}
					isPendingStatus={isPendingStatus}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={endpointName}
					ratePlans={ratePlans}
					refreshEndpoint={refreshEndpoint}
					entitlements={entitlements}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<DataOverviewMobile
					customFieldLabels={customFieldLabels}
					endpoint={endpoint}
					isPendingStatus={isPendingStatus}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={endpointName}
					ratePlans={ratePlans}
					refreshEndpoint={refreshEndpoint}
					entitlements={entitlements}
				/>
			</MobileMediaQuery>
		</>
	);
}

DataOverviewView.propTypes = {
	customFieldLabels: object,
	endpoint: object,
	isPendingStatus: bool,
	getSingleEndpoint: func,
	type: string,
	endpointName: string,
	ratePlans: array,
	refreshEndpoint: func,
	entitlements: array,
};
DataOverviewView.defaultProps = {
	customFieldLabels: {},
	endpoint: {},
	isPendingStatus: false,
	getSingleEndpoint: undefined,
	type: '',
	endpointName: '',
	ratePlans: [],
	refreshEndpoint: undefined,
	entitlements: [],
};
