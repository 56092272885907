import { combineReducers } from 'redux';

import getUserData from './getUserData/reducer';
import globalReducer from '../global/globalReducer';
import {
	CREATE_USER_SETTINGS,
	DELETE_USER_SETTINGS,
	RESET_USER_SETTINGS,
	UPDATE_USER_SETTINGS
} from './constants';

export default combineReducers({
	getUserData,
	createUserSettings: globalReducer(CREATE_USER_SETTINGS),
	updateUserSettings: globalReducer(UPDATE_USER_SETTINGS),
	deleteUserSettings: globalReducer(DELETE_USER_SETTINGS),
	resetUserSettings: globalReducer(RESET_USER_SETTINGS)
});
