import React, { PureComponent } from 'react';
import { bool, object, number, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import NoData from '../../../Shared/views/NoDataView';
import Loader from '../../../../lib/DigitalComponents/Loader';

export default function EditPermissions(WrappedComponent) {
	class EditPermissionsComponent extends PureComponent {
		componentDidMount() {
			const { getPermissions, id, system } = this.props;
			getPermissions({ id, system });
		}

		componentDidUpdate(prevProps) {
			const { editPermissionsSuccess, getPermissions, id, system } = this.props;
			if (prevProps.editPermissionsSuccess !== editPermissionsSuccess) {
				getPermissions({ id, system });
			}
		}

		render() {
			const {
				permissionsRequest,
				permissionsFail,
				permissions,
				editPermissionsRequest,
				submitForm
			} = this.props;
			return (
				<div data-spec="edit-permissions">
					{(permissionsRequest || editPermissionsRequest) && <Loader />}
					{permissionsFail && null}
					{!editPermissionsRequest &&
						!permissionsRequest &&
						permissions &&
						permissions.resultList &&
						permissions.resultList.length > 0 && (
							<WrappedComponent
								{...this.state}
								{...this.props}
								onSubmit={submitForm}
							/>
						)}
					{permissions &&
						permissions.resultList &&
						permissions.resultList.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.NO_PERMISSIONS_AVAILABLE"
										defaultMessage="No Permissions Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_PERMISSIONS_AVAILABLE"
										defaultMessage="No Permissions Available"
									/>
								}
							/>
						)}
				</div>
			);
		}
	}

	EditPermissionsComponent.propTypes = {
		dataLimit: number,
		push: func,
		getPermissions: func,
		permissionsRequest: bool,
		permissionsFail: bool,
		permissions: object,
		match: object,
		editPermissionsRequest: bool,
		editPermissionsSuccess: bool,
		id: string,
		submitForm: func,
		system: bool
	};

	const wrappedComponentName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	EditPermissionsComponent.displayName = `EditPermissions(${wrappedComponentName})`;
	return EditPermissionsComponent;
}
