/* import { createAction } from 'redux-actions';
import { getUsedQuantity } from '../../../services/SimOrderingService';

import {
	FETCH_USED_QUANTITY_REQUEST,
	FETCH_USED_QUANTITY_FAIL,
	FETCH_USED_QUANTITY_SUCCESS
} from './constants';

const fetchUsedQuantityRequest = createAction(FETCH_USED_QUANTITY_REQUEST);
const fetchUsedQuantityFail = createAction(FETCH_USED_QUANTITY_FAIL);
const fetchUsedQuantitySuccess = createAction(FETCH_USED_QUANTITY_SUCCESS);

export const fetchUsedQuantity = (params, me) => async dispatch => {
	dispatch(fetchUsedQuantityRequest());
	getUsedQuantity(params, me)
		.then(data => {
			dispatch(fetchUsedQuantitySuccess(data));
		})
		.catch(error => {
			dispatch(fetchUsedQuantityFail(error));
		});
};
 */

import globalAction from '../../../../redux/global/globalAction';
import { GET_USED_QUANTITY } from '../constants';
import { getUsedQuantity as service } from '../../services/SimOrderingService';

const getUsedQuantity = (params) =>
	globalAction(GET_USED_QUANTITY, service, params);

export default getUsedQuantity;
