import { handleActions } from 'redux-actions';

import {
	SET_ENDPOINTS_ORDER_COLUMN,
	SET_ENDPOINTS_PAGINATION,
	SET_ENDPOINTS_PAGINATION_INIT
} from './constants';

export const initialState = {
  selectedPage: 0,
	orderColumn: {},
};

export default handleActions(
	{
		[SET_ENDPOINTS_ORDER_COLUMN]: (state, { payload }) => ({
			...state,
			orderColumn: payload
    }),
    [SET_ENDPOINTS_PAGINATION_INIT]: (state) => ({
			...state,
			selectedPage: 0
		}),
		[SET_ENDPOINTS_PAGINATION]: (state, { payload }) => ({
			...state,
			selectedPage: payload
		})
	},
	initialState
);
