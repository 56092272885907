import React, { PureComponent } from 'react';
import {
	string,
	number,
	func,
	bool,
	array,
	object,
	objectOf,
} from 'prop-types';
import {
	format,
} from 'date-fns';
import {
	injectIntl,
} from 'react-intl';
import { orderBy } from 'lodash';

import { onOrderClick, onPageChange } from '../../../../utils/tableHelpers';
import {
	endpointUsageDataHeaders,
	usageSignature,
} from '../../utils/constants';

import styles from './Usage.scss';

export default function Usage(WrapperComponent) {
	class UsageComponent extends PureComponent {
		constructor(props) {
			super(props);

			const today = new Date();

			this.state = {
				type: {
					value: 'data',
					label: props.intl.formatMessage({
						id: 'DATA',
						defaultMessage: 'Data'
					}),
				},
				dataOffset: 0,
				orderColumn: {},
				selectedPage: 0,
				settingsState: props.settings[0]
					? JSON.parse(props.settings[0].value) : {},
				isEditTableModalOpen: false,
				from: new Date(Date.UTC(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - 7,
					0,
					0,
					0,
					0
				)),
				to: new Date(Date.UTC(
					today.getFullYear(),
					today.getMonth(),
					today.getDate(),
					0,
					0,
					0,
					0
				)),
				types: [
					{
						value: 'data',
						label: props.intl.formatMessage({
							id: 'DATA',
							defaultMessage: 'Data'
						}),
					},
					{
						value: 'sms',
						label: props.intl.formatMessage({
							id: 'SMS',
							defaultMessage: 'SMS'
						}),
					},
					{
						value: 'voice',
						label: props.intl.formatMessage({
							id: 'VOICE',
							defaultMessage: 'Voice'
						}),
					},
				]
			};
		}

		componentDidMount() {
			const {
				updateSettings,
				createSettings,
				settings,
			} = this.props;
      const { settingsState } = this.state;

      let newSettings;

			if (settings.length === 0) {
				newSettings = {
					header: {
						data: endpointUsageDataHeaders.data.filter((item) => item.checked),
						sms: endpointUsageDataHeaders.sms.filter((item) => item.checked),
						voice: endpointUsageDataHeaders.voice.filter((item) => item.checked),
					},
					signature: usageSignature,
				};

				createSettings({
					refTypeId: 11,
					name: 'EndpointUsageDetails',
					value: JSON.stringify(newSettings),
				});
				this.setState({ settingsState: newSettings });
			}

			if (
				settings.length > 0
				&& settingsState.signature !== usageSignature
			) {
				newSettings = {
					header: {
						data: endpointUsageDataHeaders.data.filter((item) => item.checked),
						sms: endpointUsageDataHeaders.sms.filter((item) => item.checked),
						voice: endpointUsageDataHeaders.voice.filter((item) => item.checked),
					},
					signature: usageSignature,
				};

				updateSettings({ value: JSON.stringify(newSettings) }, settings[0].id);
				this.setState({ settingsState: newSettings });
			}

			this.getEndpointUsageData(newSettings || settingsState);
		}

		componentDidUpdate(prevProps, prevState) {
			const { type, from, to } = this.state;

			if (
				prevState.type.value !== type.value
				|| prevState.from !== from
				|| prevState.to !== to
			) {
				this.getEndpointUsageData();
			}
		}

		onDragEnd = (fromIndex, toIndex) => {
			const { updateSettings, settings } = this.props;
			const { settingsState, type } = this.state;

			if (toIndex < 0) return;

			const data = settingsState
				? orderBy(settingsState.header[type.value], ['order'], ['asc'])
				: endpointUsageDataHeaders[type.value];
			[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];

			const newSettings = {
				signature: usageSignature,
				header: {
					...settingsState.header,
					[type.value]: data.map((item, index) => {
						item.order = index + 1;
						return item;
					}),
				},
			};

			updateSettings({ value: JSON.stringify(newSettings) }, settings[0].id);
			this.setState({ settingsState: newSettings });
		};

		submitColumns = (newValue) => {
			const { updateSettings, settings } = this.props;
			const { type, settingsState } = this.state;

			updateSettings({
				value: JSON.stringify({
					header: {
						...settingsState.header,
						[type.value]: newValue.header,
					},
					signature: newValue.signature,
				}),
			}, settings[0].id);
			this.setState({
				settingsState: {
					header: {
						...settingsState.header,
						[type.value]: newValue.header,
					},
					signature: newValue.signature,
				},
			});
		};

		changeDataType = (type) => this.setState({
			type,
			selectedPage: 0,
      dataOffset: 0,
      orderColumn: {}
		});

		handleConfirm = ({ startDate, endDate }) => this.setState({
			selectedPage: 0,
			dataOffset: 0,
			from: startDate,
			to: endDate,
		});

		getEndpointUsageData = (settings) => {
			const {
				getEndpointUsageData,
				imsi,
				m2mAccountId,
				dataLimit,
				platform,
			} = this.props;
      const { type, orderColumn, from, to, dataOffset, settingsState } = this.state;
      const currentSettings = settings || settingsState;
			const tableParams = {
				searchParams: [
					{ propValue: m2mAccountId, prop: 'm2m_account_id', operator: '=' },
					{
						propValue: format(from, 'yyyy-MM-dd'),
						prop: 'date_from',
						operator: '=',
					},
					{
						propValue: format(to, 'yyyy-MM-dd'),
						prop: 'date_to',
						operator: '=',
					},
				],
				additionalParams: {
          columnsToSelect: currentSettings.header[type.value].map((p) => p.name).join(','),
					dataLimit,
					dataOffset,
					dataSort: orderColumn.apiString || 'record_open_time desc',
				},
      };

			getEndpointUsageData(imsi, tableParams, type.value, platform);
		};

		getTableOptions = (isMobile) => {
			const { dataLimit, usageData } = this.props;
			const { type, selectedPage, orderColumn, settingsState } = this.state;
			const totalCount = usageData ? usageData.totalCount : 0;
			const options = {
				showPagination: totalCount > dataLimit,
				pageCount: Math.ceil(totalCount / dataLimit),
				forcePage: selectedPage,
				onPageChange: (data) => onPageChange(
					data,
					this.getEndpointUsageData,
					this,
				),
				getOrderData: (data) => onOrderClick(
					data,
					this.getEndpointUsageData,
					this,
				),
				orderByData: ['all'],
				orderColumn,
			};
			const desktopOptions = {
				draggable: true,
				onDragEnd: (fromIndex, toIndex) => this.onDragEnd(
					fromIndex,
					toIndex,
				),
			};
			const customComponents = {};

			endpointUsageDataHeaders[type.value].forEach((header) => {
				customComponents[header.name] = {
					type: 'custom',
					component: (data) => (
						<div className={styles.data}>
							{data[header.name]}
						</div>
					)
				};
			});

			return (
				{
					header: settingsState.header
						? settingsState.header[type.value]
						: endpointUsageDataHeaders[type.value].filter((item) => item.checked),
					tableOptions: isMobile ? options : { ...desktopOptions, ...options },
					customComponents,
				}
			);
		};

		openEditTableModal = () => this.setState({ isEditTableModalOpen: true });

		closeEditTableModal = () => this.setState({ isEditTableModalOpen: false });

		exportUsageData = () => {
			const { imsi, m2mAccountId, platform, exportUsageData, intl } = this.props;
			const { from, to, orderColumn, type } = this.state;
			let transactionType = '';

			switch (type.value) {
				case 'sms': transactionType = 'endpointSmsUsage'; break;
				case 'voice': transactionType = 'endpointSmsUsage'; break;
				default: transactionType = 'endpointDataUsage'; break;
			}

			const params = {
				service: 'analytics',
				transactionType,
				imsi,
				serviceProvider: platform,
				m2mAccountId,
				dateFrom: format(from, 'yyyy-MM-dd'),
				dateTo: format(to, 'yyyy-MM-dd'),
				dataSort: orderColumn.apiString || 'record_open_time desc',
			};

			exportUsageData(params, intl.formatMessage);
		};

		render() {
			const {
				getEndpointUsageDataRequest,
				usageData,
				messages,
				intl,
				isExportUsageDataPending,
			} = this.props;
			const {
				type,
				settingsState,
				isEditTableModalOpen,
				types,
				from,
				to,
			} = this.state;

			return (
				<div data-spec="usage-wrapper-component">
					<WrapperComponent
						tableData={usageData ? usageData.resultList : []}
						tableOptions={this.getTableOptions}
						settings={{
							header: settingsState.header
								? settingsState.header[type.value]
								: endpointUsageDataHeaders[type.value].filter((item) => item.checked),
							signature: settingsState.signature,
						}}
						usageSignature={usageSignature}
						header={endpointUsageDataHeaders[type.value]}
						submitColumns={this.submitColumns}
						isEditTableModalOpen={isEditTableModalOpen}
						closeEditTableModal={this.closeEditTableModal}
						messages={messages}
						getEndpointUsageDataRequest={getEndpointUsageDataRequest}
						types={types}
						from={from}
						to={to}
						type={type}
						changeDataType={this.changeDataType}
						handleConfirm={this.handleConfirm}
						openEditTableModal={this.openEditTableModal}
						dateRangePickerLabel={intl.formatMessage({
							id: 'RANGE',
							defaultMessage: 'Range',
						})}
						dateRangePickerInputLabel={
							intl.formatMessage(
								{
									id: 'DATE_RANGE_INPUT_LABEL',
									defaultMessage: 'From: {startDate} To: {endDate}',
								},
								{
									startDate: intl.formatDate(
										from,
										{ month: 'short', day: '2-digit' }
									),
									endDate: intl.formatDate(
										to,
										{ month: 'short', day: '2-digit' }
									),
								}
							)
						}
						limitByDays={90}
						dateRangePickerButtonConfirmLabel={intl.formatMessage({
							id: 'APPLY',
							defaultMessage: 'Apply',
						})}
						dateRangePickerButtonCancelLabel={intl.formatMessage({
							id: 'CANCEL',
							defaultMessage: 'Cancel',
						})}
						exportUsageData={this.exportUsageData}
						isExportUsageDataPending={isExportUsageDataPending}
					/>
				</div>
			);
		}
	}

	UsageComponent.propTypes = {
		m2mAccountId: string,
		dataLimit: number,
		getEndpointUsageData: func,
		imsi: string,
		getEndpointUsageDataRequest: bool,
		usageData: object,
		settings: array,
		updateSettings: func,
		createSettings: func,
		messages: objectOf(string),
		platform: string,
		intl: object,
		exportUsageData: func,
		isExportUsageDataPending: bool,
	};
	UsageComponent.defaultProps = {
		m2mAccountId: '',
		dataLimit: 0,
		getEndpointUsageData: undefined,
		imsi: '',
		getEndpointUsageDataRequest: false,
		usageData: {},
		messages: {},
		platform: '',
		intl: {},
		exportUsageData: undefined,
		isExportUsageDataPending: false,
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	UsageComponent.displayName = `Usage(${wrappedComponentName})`;

	return injectIntl(UsageComponent);
}
