import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_TECH_TYPES, GET_TECH_TYPES_FILTERS } from './constants';

export default combineReducers({
	getTechTypes: globalReducer(GET_TECH_TYPES),
	getTechTypesFilters: globalReducer(GET_TECH_TYPES_FILTERS)
});
