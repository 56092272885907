import { connect } from 'react-redux';

import {
	getNote,
	updateNote,
} from '../../redux/actions';
import {
	getNotePending as getNotePendingSelector,
	getNoteSuccess as getNoteSuccessSelector,
} from '../../redux/selectors';
import Notes from './Notes';

const mapStateToProps = (state) => (
	{
		getNotePending: getNotePendingSelector(state),
		note: getNoteSuccessSelector(state),
		initialValues: {
			seNote: getNoteSuccessSelector(state)
				&& getNoteSuccessSelector(state).seNote,
		},
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getNote: (endpointName, m2mAccountId) =>
			dispatch(getNote(endpointName, m2mAccountId)),
		updateNote: (endpointName, m2mAccountId, data, flag) =>
			dispatch(updateNote(endpointName, m2mAccountId, data, flag, true)),
	}
);

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
