import { combineReducers } from 'redux';
import globalReducer from '../../../redux/global/globalReducer';

import getEndpointData from './getEndpointData/reducer';
import getTicketFormPage from './getTicketFormPage/reducer';
import ticketFileDownload from './ticketFileDownload/reducer';
import exportCsv from './exportCsv/reducer';

import tickets from './tickets/reducer';
import options from './options/reducer';
import charts from './charts/reducer';

import { GET_TICKETING_FILTERS } from './constants';

export default combineReducers({
	getTicketingFilters: globalReducer(GET_TICKETING_FILTERS),
	getEndpointData,
	getTicketFormPage,
	ticketFileDownload,
	exportCsv,
	tickets,
	options,
	charts
});
