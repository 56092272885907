import React, { PureComponent } from 'react';
import {
	object,
	func,
	string,
	bool,
} from 'prop-types';
import { isEqual } from 'lodash';
import {
	FormattedDate,
	FormattedMessage,
} from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import DSLastStep from '../../../../Steps';
import Loading from '../../../../../lib/DigitalComponents/Loader';

import styles from '../NetworkEntitlementChangeStepper.scss';

export default class StepThird extends PureComponent {
	componentDidMount() {
    const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
    const { context, isChanging } = this.props;

		if (context && !(isEqual(isChanging, prevProps.isChanging))) {
			this.setFooter(context);
		}
	}

	onFinish = () => {
    const { onClose, getSingleEndpoint, endpointId, type } = this.props;

		onClose();
		getSingleEndpoint(endpointId, type);
	};

	renderChangeSuccess = () => {
    const { startDate } = this.props;

		if (startDate === undefined) {
			return (
				<DSLastStep
					data-spec="last success step"
					title={<FormattedMessage
						id="NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SUBMITTED"
						defaultMessage="Network Entitlement change is successfully submitted."
					/>}
				/>
			);
		}

		return (
			<DSLastStep
				data-spec="last success step"
				title={<FormattedMessage
					id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED"
					defaultMessage="Network Entitlement change is successfully scheduled."
				/>}
				msg={
					<>
						<FormattedMessage
							id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR"
							defaultMessage="Network Entitlement change is successfully scheduled for "
						/>
						<FormattedDate value={startDate} />
					</>
				}
			/>
		);
	};

	renderChangeError = () => {
    const { startDate } = this.props;

		if (startDate === undefined) {
			return (
				<DSLastStep
					data-spec="last step error"
					type="error"
					title={<FormattedMessage
						id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY"
						defaultMessage="Network Entitlement change could not be carried out at this time."
					/>}
					msg={<FormattedMessage
						id="PLEASE_TRY_AGAIN"
						defaultMessage="Please try again."
					/>}
				/>
			);
		}

		return (
			<DSLastStep
				data-spec="last step error"
				type="error"
				title={<FormattedMessage
					id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY"
					defaultMessage="Network Entitlement change could not be scheduled at this time."
				/>}
				msg={<FormattedMessage
					id="PLEASE_TRY_AGAIN"
					defaultMessage="Please try again."
				/>}
			/>
		);
	};

	setFooter = (context) => {
    const { isChanging } = this.props;
		const footer = (
			<>
				<Button
					variant="success"
					disabled={isChanging}
					onClick={this.onFinish}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
					dataSpec="done-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
		const { isChanging, changeSuccess, changeFail, startDate } = this.props;

		return (
			<div data-spec="third-step">
				<div data-spec="ds-modal-body" className={styles.custom}>
					<div className={styles.entitlements_title}>
						<FormattedMessage
							id="SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS"
							defaultMessage="Network Entitlement Change Status"
						/>
					</div>
					{isChanging && startDate === undefined && (
						<Loading data-spec="loading" />
					)}
					{isChanging && startDate !== undefined && (
						<FormattedMessage
							id="SCHEDULING"
							defaultMessage="Scheduling"
						>
							{(formattedValue) => (
								<Loading
									data-spec="loading"
									msg={formattedValue}
								/>
						)}
						</FormattedMessage>
					)}
					{!isChanging && changeSuccess && this.renderChangeSuccess()}
					{!isChanging && changeFail && this.renderChangeError()}
				</div>
			</div>
		);
	}
}

StepThird.propTypes = {
	onClose: func,
	isChanging: bool,
	changeSuccess: bool,
	changeFail: bool,
	startDate: string,
	getSingleEndpoint: func,
	endpointId: string,
	type: string,
	context: object
};
