/* eslint-disable camelcase */
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import {
	getCurrencyFormatWithCurrencyName,
	getCurrencyFormatWithCurrencySymbol,
	getNumberFormat
} from '../../../../utils/helperFunctions';

import PageTitle from '../../../Shared/views/PageTitleView';
import NoData from '../../../Shared/views/NoDataView';

import Loader from '../../../../lib/DigitalComponents/Loader';

import styles from './InvoiceDetails.scss';
import InvoiceSwitchTable from '../InvoiceSwitchTable';

import Card from '../../../Shared/components/Card';

import { isValidBillingCycle } from '../../utils/helpers';

const DEFAULT = (
	<FormattedMessage id="ANALYTICS_INVOICES.NA" defaultMessage="N/A" />
);
const YES = (
	<FormattedMessage id="ANALYTICS_INVOICES.YES" defaultMessage="Yes" />
);
const NO = <FormattedMessage id="ANALYTICS_INVOICES.NO" defaultMessage="No" />;
const DATE_FORMAT = 'MMM d, yyyy';

class InvoiceDetails extends Component {
	componentDidMount() {
		const { match, showNotification, intl } = this.props;
		const { billingCycle } = match.params;
		if (isValidBillingCycle(billingCycle)) {
			this.getInvoiceDetails();
		} else {
			const message = intl.formatMessage({
				id: 'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION',
				defaultMessage:
					'Billing cycle is not valid, therefore invoice details cannot be displayed.'
			});
			showNotification(message);
		}
	}

	componentWillUnmount() {
		const { hideNotification, getInvoiceDetailsInit } = this.props;
		getInvoiceDetailsInit();
		hideNotification();
	}

	getInvoiceDetails() {
		const { match, getInvoice } = this.props;
		const { m2mAccountId, billingCycle } = match.params;
		const params = {
			m2mAccountId,
			params: {
				searchParams: [
					{
						prop: 'billing_cycle',
						propValue: billingCycle,
						operator: '='
					}
				]
			}
		};
		getInvoice(params);
	}

	renderInvoiceDetails = () => {
		const { invoice } = this.props;
		const {
			// First Row
			invoice_id,
			invoice_date,
			due_date,
			account_name,
			m2maccount_id,
			operator_account_id,
			// Second Row
			currency,
			billing_cycle,
			taxable_account,
			published,
			billable,
			tax_status,
			// Third Row
			standard_overage,
			standard_roaming
		} = invoice;
		const currencyNameFormatter = getCurrencyFormatWithCurrencyName(currency);
		return (
			<div className={styles.column} data-spec="invoice-details">
				<div>
					<div className={styles.row}>
						<label className={styles.header}>
							<FormattedMessage
								id="ANALYTICS_INVOICES.INVOICE_TITLE"
								defaultMessage="Invoice Details"
							/>
						</label>
					</div>
					<div className={styles.row}>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.INVOICE_ID"
									defaultMessage="Invoice ID"
								/>
							</label>
							<label className={styles.value}>{invoice_id || DEFAULT}</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.DATE"
									defaultMessage="Invoice Date"
								/>
							</label>
							<label className={styles.value}>
								{format(new Date(invoice_date), DATE_FORMAT) || DEFAULT}
							</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.DUE_DATE"
									defaultMessage="Due Date"
								/>
							</label>
							<label className={styles.value}>
								{format(new Date(due_date), DATE_FORMAT) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.ACCOUNT_NAME"
									defaultMessage="Account Name"
								/>
							</label>
							<label className={styles.value}>{account_name || DEFAULT}</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.ACCOUNT_ID"
									defaultMessage="Account ID"
								/>
							</label>
							<label className={styles.value}>{m2maccount_id || DEFAULT}</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.OPERATOR_ID"
									defaultMessage="Operator ID"
								/>
							</label>
							<label className={styles.value}>
								{operator_account_id || DEFAULT}
							</label>
						</div>
					</div>
				</div>
				<div>
					<div className={styles.row}>
						<label className={styles.header}>
							<FormattedMessage
								id="ANALYTICS_INVOICES.BILLING_DETAILS"
								defaultMessage="Billing Details"
							/>
						</label>
					</div>
					<div className={styles.row}>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.CURRENCY"
									defaultMessage="Currency"
								/>
							</label>
							<label className={styles.value}>{currency || DEFAULT}</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.BILLING_CYCLE"
									defaultMessage="Billing Cycle"
								/>
							</label>
							<label className={styles.value}>{billing_cycle}</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.TAXABLE"
									defaultMessage="Taxable Account"
								/>
							</label>
							{taxable_account !== null && (
								<label className={styles.value}>
									{taxable_account ? YES : NO}
								</label>
							)}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.PUBLISHED"
									defaultMessage="Published"
								/>
							</label>
							{published !== null && (
								<label className={styles.value}>{published ? YES : NO}</label>
							)}
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.BILL"
									defaultMessage="Billable"
								/>
							</label>
							{billable !== null && (
								<label className={styles.value}>{billable ? YES : NO}</label>
							)}
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.TAX_STATUS"
									defaultMessage="Tax Status"
								/>
							</label>
							{tax_status !== null && (
								<label className={styles.value}>{tax_status || DEFAULT}</label>
							)}
						</div>
					</div>
				</div>
				<div>
					<div className={styles.row}>
						<label className={styles.header}>
							<FormattedMessage
								id="ANALYTICS_INVOICES.DATA_RATES"
								defaultMessage="Data rates"
							/>
						</label>
					</div>
					<div className={styles.row}>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.STANDARD_OVERAGE"
									defaultMessage="Standard Overage"
								/>
							</label>
							<label className={styles.value}>
								{currencyNameFormatter.format(standard_overage) || DEFAULT}
							</label>
						</div>
						<div className={styles.data}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES.STANDARD_ROAMING"
									defaultMessage="Standard Roaming"
								/>
							</label>
							<label className={styles.value}>
								{currencyNameFormatter.format(standard_roaming) || DEFAULT}
							</label>
						</div>
						<div className={styles.data} />
					</div>
				</div>
			</div>
		);
	};

	renderSubscriptionCharges = () => {
		const { invoice } = this.props;
		const {
			subscription_charge,
			data_charge,
			sms_charge,
			activation_charge,
			tech_type_charge,
			fixed_discount_target,
			tax,
			surcharge,
			total_charge,
			currency,
			due_date,
			other_charges
		} = invoice;
		const currencySymbolFormatter = getCurrencyFormatWithCurrencySymbol(
			currency
		);
		// const currencyNameFormatter = getCurrencyFormatWithCurrencyName(currency);
		const formatNumber = getNumberFormat(currency);
		const totalCharge = String(formatNumber.format(total_charge)).split('.');
		return (
			<div className={styles.column} data-spec="subscription-charges">
				<div className={styles.row}>
					<label className={styles.header}>
						<FormattedMessage
							id="ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES"
							defaultMessage="Subscription Charges"
						/>
					</label>
				</div>
				<div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE"
									defaultMessage="Subscription Charge"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(subscription_charge) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.DATA_CHARGE"
									defaultMessage="Data Charge"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(data_charge) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.SMS_CHARGES"
									defaultMessage="SMS Charges"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(sms_charge) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE"
									defaultMessage="Activation Charge"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(activation_charge) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE"
									defaultMessage="Tech Type Charge"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(tech_type_charge) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET"
									defaultMessage="Fixed Discount Target"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(fixed_discount_target) ||
									DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.TAX"
									defaultMessage="Tax"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(tax) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.OTHER_CHARGES"
									defaultMessage="Other Charges"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(other_charges) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.SURCHARGE"
									defaultMessage="Surcharge"
								/>
							</label>
							<label className={styles.value}>
								{currencySymbolFormatter.format(surcharge) || DEFAULT}
							</label>
						</div>
					</div>
					<div className={styles.divider} />
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header_value}>
								<div>
									<div className={styles.total_charge}>
										<FormattedMessage
											id="ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE"
											defaultMessage="Total Charge"
										/>
									</div>
									<div className={styles.total_charge_explanation}>
										<FormattedMessage
											id="ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE_EXPLANATION"
											defaultMessage="(Without tax & other charges)"
										/>
									</div>
								</div>
							</label>
							<label className={styles.total_value}>
								<sup className={styles.sup}>{currency}</sup>
								{totalCharge[0]}
								<sup className={styles.sup}>{totalCharge[1]}</sup>
							</label>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.charges_wrapper}>
							<label className={styles.label_header}>
								<FormattedMessage
									id="ANALYTICS_INVOICES_DETAILS.DUE_BY"
									defaultMessage="Due by "
								/>
								{format(new Date(due_date), DATE_FORMAT) || DEFAULT}
							</label>
						</div>
					</div>
				</div>
			</div>
		);
	};

	renderEndpointStats = () => {
		const { invoice } = this.props;
		const { data_volume_mb, sms_volume_msg, devices } = invoice;
		const formattedDataVolume =
			data_volume_mb && this.mBytesToSize(data_volume_mb).split(' ');
		return (
			<div className={styles.column} data-spec="enpoint-stats">
				<div className={styles.row}>
					<label className={styles.header}>
						<FormattedMessage
							id="ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS"
							defaultMessage="Endpoint Stats"
						/>
					</label>
				</div>
				<div className={styles.row_stats}>
					<div className={styles.stats_column}>
						<label className={styles.value}>{devices || DEFAULT}</label>
						<label className={styles.label_header}>
							{' '}
							<FormattedMessage
								id="ANALYTICS_INVOICES_DETAILS.ENDPOINTS"
								defaultMessage="ENDPOINTS"
							/>
						</label>
					</div>
					<div className={styles.stats_column}>
						<label className={styles.value}>
							{(formattedDataVolume && formattedDataVolume[0]) || DEFAULT}
							<span className={styles.sub}>
								{formattedDataVolume && formattedDataVolume[1]}
							</span>
						</label>
						<label className={styles.label_header}>
							{' '}
							<FormattedMessage
								id="ANALYTICS_INVOICES_DETAILS.DATA_VOLUME"
								defaultMessage="DATA VOLUME"
							/>
						</label>
					</div>
					<div className={styles.stats_column}>
						<label className={styles.value}>{sms_volume_msg || DEFAULT}</label>
						<label className={styles.label_header}>
							{' '}
							<FormattedMessage
								id="ANALYTICS_INVOICES_DETAILS.SMS_VOLUME"
								defaultMessage="SMS VOLUME"
							/>
						</label>
					</div>
				</div>
			</div>
		);
	};

	pushBack = () => {
		const { pushBack } = this.props;
		pushBack();
	};

	downloadInvoice = () => {
		const { downloadFile, match, invoice } = this.props;
		const { document } = match.params;
		downloadFile(document, invoice.m2maccount_id);
	};

	mBytesToSize = (mBytes) => {
		const sizes = ['MB', 'GB', 'TB'];
		if (mBytes == 0) return '0 MB';
		let i = parseInt(Math.floor(Math.log(mBytes) / Math.log(1024)), 10);
		if (i > 2) i = 2;
		if (i === 0) return `${mBytes} ${sizes[i]})`;
		return `${(mBytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
	};

	render() {
		const {
			messages,
			invoice,
			downloadInvoiceRequest,
			dataLimit,
			// match,
			invoiceRequest
		} = this.props;
		// const { id } = match.params;

		const isAnyRequest = invoiceRequest || downloadInvoiceRequest;

		return (
			<div data-spec="analytical-invoice-details">
				<PageTitle
					title={
						<FormattedMessage
							id="ANALYTICS_INVOICES.INVOICE_TITLE"
							defaultMessage="Invoice Details"
						/>
					}
					pushBack={this.pushBack}
					messages={messages}
				/>
				{isAnyRequest && <Loader data-spec="loader" />}
				{isEmpty(invoice) && !isAnyRequest && (
					<NoData
						title={
							<FormattedMessage
								id="ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE"
								defaultMessage="There is no data for this invoice"
							/>
						}
						subtitle={
							<FormattedMessage
								id="ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE"
								defaultMessage="Invoice details database is empty for this invoice"
							/>
						}
					/>
				)}
				{!isAnyRequest && !isEmpty(invoice) && (
					<div>
						<div className={styles.wrapper}>
							<div className={styles.wrapper_row}>
								<div className={styles.details_wrapper_first}>
									<Card>{this.renderInvoiceDetails()}</Card>
								</div>
								<div className={styles.details_wrapper_second}>
									<Card>{this.renderSubscriptionCharges()}</Card>
								</div>
							</div>
							<div className={styles.endpoint_stats}>
								<Card>{this.renderEndpointStats()}</Card>
							</div>
						</div>
						{invoice && invoice.csv_generated && (
							<InvoiceSwitchTable
								messages={messages}
								id={invoice.invoice_id}
								dataLimit={dataLimit}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

const { func, object, number, string, objectOf, bool, any } = PropTypes;

InvoiceDetails.propTypes = {
	getInvoice: func,
	messages: objectOf(string),
	pushBack: func,
	match: object,
	invoice: any,
	dataLimit: number,
	downloadInvoiceRequest: bool,
	downloadFile: func,
	invoiceRequest: bool,
	showNotification: func,
	hideNotification: func,
	intl: object,
	getInvoiceDetailsInit: func
};

export default injectIntl(InvoiceDetails);
