import isAddChartModalOpen from '../addChartModal/selectors';
import { isBatchUploadModalOpened } from '../batchFileUploadModal/selectors';
import isEditTableModalOpened from '../editTableModal/selectors';
import isCancelScheduleStatusModalOpened from '../cancelScheduleStatusModal/selectors';
import isSendSmsModalOpened from '../sendSmsModal/selectors';
import isSingleSimStatusChangeModalOpened from '../singleSimStatusChangeModal/selectors';

const isAnyModalOpened = (state) => (
		state.modals.globalModalReducer.anyModalOpened ||
		isAddChartModalOpen(state) ||
		isBatchUploadModalOpened(state) ||
		isEditTableModalOpened(state) ||
		isCancelScheduleStatusModalOpened(state) ||
		isSendSmsModalOpened(state) ||
		isSingleSimStatusChangeModalOpened(state)
	);

export default isAnyModalOpened;
