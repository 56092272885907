import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../AccountEndpoints';

const Desktop = (props) => {
	const { endpoints, getOptions, messages } = props;
	return (
		<div data-spec="account-endpoints-desktop">
			<Table
				data-spec="account-endpoints-desktop-table"
				data={endpoints.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { object, func } = PropTypes;

Desktop.propTypes = {
	endpoints: object,
	getOptions: func,
	messages: object
};

export default hoc()(Desktop);
