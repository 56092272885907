import { createAction } from 'redux-actions';
import { showNotification } from '../../../Shared/redux/notifications/actions';
import {
	downloadReportRequestURL as downloadReportRequestURLService,
	reportPollForFile
} from '../../../../services/DownloadService';
import { getReportDownloadUrl } from '../../services/ReportsService';
import { getLocale, downloadReportHelper } from '../../../../utils/constants';

import { DOWNLOAD_REPORT } from '../constants';

const request = (prefix) => createAction(`${prefix}_REQUEST`);
const success = (prefix) => createAction(`${prefix}_SUCCESS`);
const fail = (prefix) => createAction(`${prefix}_FAIL`);

export const downloadReportRequestURL = (
	data,
	prefix = DOWNLOAD_REPORT
) => async (dispatch) => {
  const locale = getLocale();
	const {
		default: { DOWNLOAD_REPORT_ERROR_MESSAGE }
	} = await import(`../../../../localizations/${locale}`);
	const downloadURL = '';
	let downloadFileId = null;

	dispatch(request(prefix)());
	downloadReportRequestURLService(data)
		.then((response) => response)
		.then((response) => {
			// downloadURL = response.fileName;
			downloadFileId = response.data.id;

			return reportPollForFile({ id: downloadFileId }, 100, 4000);
		})
		.then((response) => {
			dispatch(success(prefix)(response));
			downloadReportHelper(response.data.downloadUrl, downloadURL);
		})
		.catch((error) => {
			dispatch(
				showNotification({
					id: 'report-download-error',
					message: [DOWNLOAD_REPORT_ERROR_MESSAGE],
					type: 'error',
					notificationType: 'toaster',
					close: false
				})
			);
			dispatch(fail(prefix)(error));
		});
};

export const downloadReportFile = (params, prefix = DOWNLOAD_REPORT) => async (
	dispatch
) => {
  const locale = getLocale();
	const {
		default: { DOWNLOAD_REPORT_ERROR_MESSAGE }
	} = await import(`../../../../localizations/${locale}`);
	dispatch(request(prefix)());
	getReportDownloadUrl(params)
		.then((response) => {
			downloadReportHelper(response.data && response.data.downloadUrl);
			dispatch(success(prefix)(response));
		})
		.catch((error) => {
			dispatch(
				showNotification({
					id: 'report-download-error',
					message: [DOWNLOAD_REPORT_ERROR_MESSAGE],
					type: 'error',
					notificationType: 'toaster',
					close: false
				})
			);
			dispatch(fail(prefix)(error));
		});
};
