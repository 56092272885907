import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';

import hoc from '../UserProfile';
import styles from './Mobile.scss';

import ImageSelector from '../../../../../utils/imageSelector';
import CurrentContext from '../../../../../utils/currentContext';

import PrivacyModal from '../../../../Eula/components/PrivacyModal';
import { getDateFormat } from '../../../../../utils/constants';

import {
	isUserAllowedToAccess,
	checkIfUserIsSystem
} from '../../../../../utils/AuthSelector';

const Eye = ImageSelector(CurrentContext.theme, 'svgs/eye.svg');
const UserImg = ImageSelector(
	CurrentContext.theme,
	'user_image_placeholder.png'
);
const UnitedStates = ImageSelector(CurrentContext.theme, 'svgs/usFlag.svg');
const France = ImageSelector(CurrentContext.theme, 'svgs/france.svg');
const Espana = ImageSelector(CurrentContext.theme, 'svgs/espanol.svg');
const Italia = ImageSelector(CurrentContext.theme, 'svgs/italia.svg');
const Holland = ImageSelector(CurrentContext.theme, 'svgs/holland.svg');
const Brazil = ImageSelector(CurrentContext.theme, 'svgs/brazil.svg');
const Deutschland = ImageSelector(CurrentContext.theme, 'svgs/deutchland.svg');
const PRC = ImageSelector(CurrentContext.theme, 'svgs/china.svg');

class Mobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			eula: props.settings.pop(),
			privacyModalOpen: false
		};
	}

	openPrivacyModal = () =>
		this.setState({
			privacyModalOpen: true
		});

	closePrivacyModal = () => this.setState({ privacyModalOpen: false });

	renderFlags = (data) => {
		switch (data && data.id) {
			case 1:
				return <UnitedStates data-spec="USA" />;
			case 2:
				return <France data-spec="France" />;
			case 3:
				return <Espana data-spec="Espana" />;
			case 4:
				return <Italia data-spec="Italia" />;
			case 5:
				return <Holland data-spec="Holland" />;
			case 6:
				return <Deutschland data-spec="Deutschland" />;
			case 7:
				return <Brazil data-spec="Brazil" />;
			case 8:
				return <PRC data-spec="China" />;
			// no default
		}
	};

	render() {
		const {
			user,
			goToEffectivePermissions,
			goToUserGroupPermissions,
			system,
			myProfile,
			currentUser
		} = this.props;

		const { eula, privacyModalOpen } = this.state;

		return (
			<div data-spec="user-profile-mobile">
				<div className={styles.content_block}>
					<p className={styles.section_title}>
						<FormattedMessage
							id="ONBOARDING.USER_INFORMATION"
							defaultMessage="User Information"
						/>
					</p>
					<div className={styles.row_image}>
						<img
							src={
								user && user.profilePicture.length
									? user.profilePicture[0].picture
									: UserImg
							}
							alt="user-profile"
							className={styles.image_holder}
						/>
					</div>
					<div className={styles.row}>
						<p className={styles.username}>
							{user.firstName}&nbsp;
							{user.lastName}
						</p>
						<div className={styles.data_block}>
							<label className={styles.label}>
								<FormattedMessage
									id="ONBOARDING.COMPANY_EMAIL"
									defaultMessage="Company Email"
								/>
							</label>
							{user.email}
						</div>
						<div className={styles.data_block}>
							<label className={styles.label}>
								<FormattedMessage
									id="ONBOARDING.PREFERRED_LANGUAGE"
									defaultMessage="Primary Number"
								/>
							</label>
							<div className={styles.preferred_language_info}>
								{this.renderFlags(user.language)}
								{user && user.language && user.language.name}
							</div>
						</div>
						<div className={styles.data_block}>
							<label className={styles.label}>
								<FormattedMessage
									id="ONBOARDING.PRIMARY"
									defaultMessage="Primary"
								/>
							</label>
							{user.phone1}
						</div>
						<div className={styles.data_block}>
							<label className={styles.label}>
								<FormattedMessage
									id="ONBOARDING.SECONDARY"
									defaultMessage="Secondary"
								/>
							</label>
							{user.phone2}
						</div>
						<div className={styles.data_block}>
							<label className={styles.label}>
								<FormattedMessage
									id="ONBOARDING.CURRENT_STATUS"
									defaultMessage="Current Status"
								/>
							</label>
							{(system && user.active) ||
							(!system && !myProfile && user.companyUserStatus.length === 0) ||
							(myProfile && user.active) ? (
								<div className={cn(styles.status, styles.active)}>
									<FormattedMessage
										id={'ONBOARDING.ACTIVE'}
										defaultMessage="Active"
									/>
								</div>
							) : (
								<div className={cn(styles.status, styles.paused)}>
									<FormattedMessage
										id={'ONBOARDING.PAUSED'}
										defaultMessage="Paused"
									/>
								</div>
							)}
						</div>
					</div>

					<p className={styles.section_title}>
						<FormattedMessage
							id="ONBOARDING.ADDRESSES"
							defaultMessage="Addresses"
						/>
					</p>

					<div className={styles.address_columns_holder}>
						{user.address && user.address.length > 0 ? (
							user.address.map((address) => (
								<div className={styles.address_column} key={address.id}>
									<div className={styles.data_block}>
										<label className={styles.label}>
											{address &&
												address.addressType &&
												address.addressType.name}
											{address && address.primary ? (
												<span className={styles.primary_address}>
													&#40;
													<FormattedMessage
														id="ONBOARDING.PRIMARY"
														defaultMessage="Primary"
													/>
													&#41;
												</span>
											) : null}
										</label>
										<div className={styles.data}>{address.street1}</div>
										<div className={styles.data}>{address.street2}</div>
										<div className={styles.data}>
											{address.zipCode}
											&nbsp;
											{address.city}
										</div>
										<div className={styles.data}>
											{address.state && address.state.name}
											,&nbsp;
											{address.country && address.country.name}
										</div>
									</div>
								</div>
							))
						) : (
							<div className={styles.no_address_message}>
								<FormattedMessage
									id="ONBOARDING.INFORMATION_NOT_PROVIDED"
									defaultMessage="Information not provided"
								/>
							</div>
						)}
					</div>

					<p className={styles.section_title}>
						<FormattedMessage
							id="ONBOARDING.ACCESS_LEVEL"
							defaultMessage="Access Level"
						/>
					</p>
					<div>
						<h3 className={styles.assigned_group_head}>
							<FormattedMessage
								id="ONBOARDING.ASSIGNED_USER_GROUP"
								defaultMessage="Assigned User Group"
							/>
						</h3>
						{user && user.userGroup && user.userGroup.length > 0 && (
							<FormattedMessage
								id="ONBOARDING.VIEW_USER_GROUP"
								defaultMessage="View User Group"
							>
								{(formattedValue) =>
									user.userGroup
										.filter((item) =>
											(system || (myProfile && currentUser.systemUser)
												? item.userGroupTypeId === 2
												: item.userGroupTypeId === 1))
										.map((group) => (
											<div
												className={styles.assigned_user_group}
												key={group.id}
											>
												<div className={styles.group_name}>
													{group.name}
													&nbsp;
													{!group.active && (
														<>
															(
															<FormattedMessage
																id="ONBOARDING.SUSPENDED"
																defaultMessage="Suspended"
															/>
															)
														</>
													)}
												</div>

												{(checkIfUserIsSystem(currentUser)
													? isUserAllowedToAccess(
															[
																'system.accessmanagement.usergroups_rw',
																'system.accessmanagement.usergroups_ro'
															],
															currentUser
													  )
													: isUserAllowedToAccess(
															[
																'mnc.accessmanagement.usergroups_rw',
																'mnc.accessmanagement.usergroups_ro'
															],
															currentUser
													  )) && (
													<div className={styles.view_icon}>
														<Eye
															data-tip={formattedValue}
															data-for={`viewPermissionsTooltip_${group.id}`}
															className={styles.pointer}
															onClick={() => goToUserGroupPermissions(group.id)}
														/>
														<ReactTooltip
															id={`viewPermissionsTooltip_${group.id}`}
															className={styles.tooltip}
														/>
													</div>
												)}
											</div>
										))}
							</FormattedMessage>
						)}
						<hr className={styles.horizontal_separator} />
						<div className={styles.manage_permissions}>
							<span onClick={goToEffectivePermissions}>
								<FormattedMessage
									id="ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS"
									defaultMessage="View User Assigned Permissions"
								/>
							</span>
						</div>
					</div>
					{system || (myProfile && currentUser.systemUser) ? null : (
						<p className={styles.section_title}>
							<FormattedMessage
								id="ONBOARDING.ASSIGNED_ACCOUNTS"
								defaultMessage="Assigned Accounts"
							/>
						</p>
					)}
					<div>
						{user &&
							user.userGroup &&
							user.userGroup
								.filter((item) =>
									(system || (myProfile && currentUser.systemUser)
										? item.userGroupTypeId === 2
										: item.userGroupTypeId === 1))
								.map((group) =>
									(group.platform && group.platform.length === 0 ? (
										<div key={group.id}>
											<FormattedMessage
												id="ONBOARDING.UNRESTRICTED_ACCESS"
												defaultMessage="Unrestricted Access"
											/>
										</div>
									) : (
										group.platform &&
										group.platform.map((platform) => (
											<div
												className={styles.assigned_account}
												key={platform.m2mAccountName}
											>
												{platform.m2mAccountName}
											</div>
										))
									)))}
					</div>

					<p className={styles.section_title}>
						<FormattedMessage
							id="ONBOARDING.LEGAL_AND_REGULATORY"
							defaultMessage="Legal And Regulatory"
						/>
					</p>
					<div className={styles.legal_information}>
						<p>
							<FormattedMessage
								id="ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US"
								defaultMessage="User privacy is important to us, and we do not sell your user data to anyone."
							/>
						</p>
						<p>
							<FormattedMessage
								id="ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY"
								defaultMessage="EULA contains in-depth information about how we protect your privacy, right to errasure and other compliance regulations."
							/>
						</p>
						<p>
							<FormattedMessage
								id="ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE"
								defaultMessage={'{name} {lastName} accepted'}
								values={{
									name: user.firstName,
									lastName: user.lastName
								}}
							/>
							&nbsp;
							<span onClick={() => this.openPrivacyModal()} className={styles.highlight_licence}>
								<FormattedMessage
									id="ONBOARDING.END_USER_LICENCE_AGREEMENT"
									defaultMessage="End-User Licence Agreement"
								/>
							</span>
							&nbsp;
							<FormattedMessage
								id="ONBOARDING.ON_DATE"
								defaultMessage={'on {date}.'}
								values={{
									date: eula && eula.createdAt && getDateFormat(eula.createdAt)
								}}
							/>
							&nbsp;
							<FormattedMessage
								id="ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT"
								defaultMessage="If you do not agree with the terms of EULA, you may delete this account."
							/>
						</p>
					</div>
					<PrivacyModal
						show={privacyModalOpen}
						onClose={this.closePrivacyModal}
						userEula
					/>
				</div>
			</div>
		);
	}
}

const { func, shape, bool, object, arrayOf } = PropTypes;

Mobile.propTypes = {
	goToEffectivePermissions: func,
	user: shape(),
	goToUserGroupPermissions: func,
	system: bool,
	myProfile: bool,
	currentUser: object,
	settings: arrayOf(object),
};

export default hoc()(Mobile);
