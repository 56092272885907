export default {
	'ERRORS.ERROR': '错误',
	'ERRORS.FORBIDDEN_ACCESS': '禁止访问',
	'ERRORS.RETURN_TO_HOME_PAGE': '返回主页',
	'ERRORS.PAGE_NOT_FOUND': '我们似乎无法找到您在搜素的页面。',
	'ERRORS.INTERNAL_SERVER_ERROR': '内部服务器错误',
	'ERRORS.REQUEST_TIMED_OUT': '请求超时',
	'ERRORS.LOGOUT': '登出',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': '哎呀，您似乎没有访问本应用程序的权限。',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': '如果您认为自己应该拥有访问权限，请联系您的公司管理员。'
};
