import { connect } from 'react-redux';
import AccountEndpointsDesktop from './Desktop';

import {
	getEndpoints,
	openMoveToSubAccountModal,
	closeMoveToSubAccountModal
} from '../../redux/endpoints/actions';

import {
	getEndpointsRequest,
	getEndpointsFail,
	getEndpointsSuccess,
	isMoveToSubAccountModalOpen,
	moveEndpointsRequest,
	moveEndpointsSuccess
} from '../../redux/endpoints/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	endpointsRequest: getEndpointsRequest(state),
	endpointsFail: getEndpointsFail(state),
	endpoints: getEndpointsSuccess(state),
	isMoveToSubAccountModalOpen: isMoveToSubAccountModalOpen(state),
	moveEndpointsRequest: moveEndpointsRequest(state),
	moveEndpointsSuccess: moveEndpointsSuccess(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getEndpoints: (params) => dispatch(getEndpoints(params)),
	openMoveToSubAccountModal: () => dispatch(openMoveToSubAccountModal()),
	closeMoveToSubAccountModal: () => dispatch(closeMoveToSubAccountModal())
});

const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountEndpointsDesktop);

export default Desktop;
