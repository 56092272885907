import { handleActions } from 'redux-actions';
import { Status } from 'utils/constants';

import {
	FILE_UPLOAD_REQUEST,
	FILE_UPLOAD_SUCCESS,
	FILE_UPLOAD_FAIL,
	CLEAR_FILE_UPLOAD,
	RESET_FILE_NUMBERS,
	RESET_FILES,
	RESET_ERRORS
} from '../constants';

export const initialState = {
	status: Status.INIT,
	file: [],
	errors: [],
	uploadFileNumber: 0,
	showErrors: false
};

export default handleActions(
	{
		[FILE_UPLOAD_REQUEST]: (state) => ({
				...state,
				status: Status.PENDING,
				showErrors: false
			}),
		[FILE_UPLOAD_SUCCESS]: (state, action) => {
			const filteredFiles = state.file.filter(
				(file) => file.name !== action.payload.fileName
			);
			return {
				...state,
				files: [
					{
						location: action.payload.fileLocation,
						name: action.payload.fileName,
						id: action.payload.id
					}
				],
				file: [
					...filteredFiles,
					{
						location: action.payload.fileLocation,
						name: action.payload.fileName,
						id: action.payload.id
					}
				],
				status: Status.DONE,
				uploadFileNumber: state.uploadFileNumber + 1
			};
		},
		[FILE_UPLOAD_FAIL]: (state) => ({
				...state,
				status: Status.FAIL,
				uploadFileNumber: state.uploadFileNumber + 1
			}),
		[CLEAR_FILE_UPLOAD]: (state, action) => ({
				...state,
				status: Status.INIT,
				file: state.file.filter((file) => file.name !== action.payload),
				errors: []
			}),
		[RESET_FILE_NUMBERS]: (state) => ({
				...state,
				uploadFileNumber: 0,
				showErrors: true
			}),
		[RESET_FILES]: (state) => ({
				...state,
				file: [],
				files: [],
				errors: [],
				showErrors: false
			}),
		[RESET_ERRORS]: (state) => ({
				...state,
				errors: [],
				showErrors: false
			}),
		DEPRECATED_INTERNAL_SERVER_ERROR: (state, action) => {
			// Currently not used by any component
			let isServiceTrue = false;
			if (action.payload.config.data) {
				const data = JSON.parse(action.payload.config.data);
				isServiceTrue = data.service || data.fileLocation;
			}
			if (isServiceTrue) {
				let itemErrors = [];
				if (action.payload.response.data.resultList) {
					itemErrors = action.payload.response.data.resultList.map((item) => ({
						message: item.message
					}));
				} else {
					const location = JSON.parse(action.payload.config.data).fileLocation;
					const errorNameIndex = location.lastIndexOf('/');
					const error = location.slice(errorNameIndex);
					itemErrors = [
						{ message: `${error} + ${action.payload.response.status}` }
					];
				}

				return {
					...state,
					errors: [...state.errors, ...itemErrors]
				};
			}
			return {
				...state
			};
		}
	},
	initialState
);
