import { createAction } from 'redux-actions';
import globalAction from '../../../../redux/global/globalAction';

import {
	GET_ACCOUNT_ENDPOINTS,
	MOVE_TO_SUB_ACCOUNT_MODAL,
	MOVE_ACCOUNT_ENDPOINTS
} from './constants';

import {
	getEndpoints as getEndpointsService,
	moveEndpoints as moveEndpointsService
} from '../../services/EndpointsService';

/* ******************** GET ENDPOINTS ******************** */
export const getEndpoints = (params) =>
	globalAction(GET_ACCOUNT_ENDPOINTS, getEndpointsService, params);

/* ******************** MOVE TO SUB ACCOUNT MODAL ******************** */
export const openMoveToSubAccountModal = createAction(
	`OPEN_${MOVE_TO_SUB_ACCOUNT_MODAL}`
);
export const closeMoveToSubAccountModal = createAction(
	`CLOSE_${MOVE_TO_SUB_ACCOUNT_MODAL}`
);

/* ******************** MOVE ENDPOINTS ******************** */
export const moveEndpoints = (params) =>
	globalAction(MOVE_ACCOUNT_ENDPOINTS, moveEndpointsService, params, {
		modals: closeMoveToSubAccountModal(),
		notify: 'move-endpoints',
		init: true
	});
