import { Status } from '../../../../utils/constants';

export const getTechTypesRequest = (state) =>
	state.analyticsInvoices.tables.techTypes.getTechTypes.status ===
	Status.PENDING;
export const getTechTypesFail = (state) =>
	state.analyticsInvoices.tables.techTypes.getTechTypes.status === Status.FAIL;
export const getTechTypesSuccess = (state) =>
	state.analyticsInvoices.tables.techTypes.getTechTypes.data;

export const getTechTypesFiltersRequest = (state) =>
	state.analyticsInvoices.tables.techTypes.getTechTypesFilters.status ===
	Status.PENDING;
export const getTechTypesFiltersFail = (state) =>
	state.analyticsInvoices.tables.techTypes.getTechTypesFilters.status ===
	Status.FAIL;
export const getTechTypesFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.techTypes.getTechTypesFilters.data;
