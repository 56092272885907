import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Icon from 'lib/DigitalComponents/Icons';
import Loader from 'lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import styles from './UserGuide.scss';
import { getLocale } from '../../../../utils/constants';

const UserGuideHOC = (WrappedComponent) => {
	class UserGuide extends Component {
		constructor(props) {
			super(props);
			this.state = {
				userGuideAvailable: false,
				fileMarkdown: null,
			};
		}

		async componentDidMount() {
      const locale = getLocale().toLowerCase();
      const importedFile = await import(`../../../../brands/md/userGuide/UserGuide-${locale}.md`);

			this.setState({
				fileMarkdown: importedFile.default,
				userGuideAvailable: true,
      });
		}

		exportPdf = () => {
			const { exportCsvFile } = this.props;
			const fileRequest = {
				service: 'userGuide',
				fileName: 'UserGuide.pdf', // Add logic for multilanguage
				transactionType: 'userGuideDownload',
			};
			exportCsvFile(fileRequest);
		}

		goBack = () => {
			const { goBack } = this.props;
			goBack();
		};

		render() {
      const { exportCsvRequest } = this.props;

			return (
				<div data-spec="user-guide">
					<PageTitle
						title={
							<FormattedMessage
								id="SUPPORT.USER_GUIDE"
								defaultMessage="User Guide"
							/>
						}
						actions={
							<div className={styles.download_link}>
								{!exportCsvRequest ? (
									<div onClick={this.exportPdf}>
										<Icon name="print" className={'ds-icon'} />
										<FormattedMessage
											id="SUPPORT.PRINT_PDF"
											defaultMessage="Print PDF"
										/>
									</div>
								) : (
									<Loader />
								)}
							</div>
						}
						pushBack={this.goBack}
					/>
					<div>
						<WrappedComponent
							{...this.state}
							{...this.props}
							contentId={styles.wrapper}
						/>
					</div>
				</div>
			);
		}
	}

	const { func, bool } = PropTypes;

	UserGuide.propTypes = {
		goBack: func,
		exportCsvFile: func,
		exportCsvRequest: bool
	};
	return UserGuide;
};
export default UserGuideHOC;
