import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './DeleteRuleModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

const DeleteRuleModal = ({
	onCancel,
	show,
	onClick,
	modalHeader,
	modalMessage
}) => {
	const renderMessages = () => (
		<div data-spec="body-message" className={styles.body}>
			<div className={styles.modal_message}>{modalHeader}</div>
			<div className={styles.modal_text_block}>
				<div className={styles.modal_message}>{modalMessage}</div>
			</div>
		</div>
	);
	const renderButtons = () => (
		<div data-spec="buttons">
			<Button
				onClick={onCancel}
				variant="link"
				label={
					<FormattedMessage
						id={'AUTOMATION.CANCEL'}
						defaultMessage={'Cancel'}
					/>
				}
			/>
			<Button
				dataSpec="go-to-reports"
				variant="primary"
				onClick={() => {
					onCancel();
					onClick();
				}}
				label={
					<FormattedMessage
						id={'AUTOMATION.DELETE'}
						defaultMessage={'Delete'}
					/>
				}
			/>
		</div>
	);

	return (
		<Modal data-spec="dashboard-prompt-modal" show={show} onClose={onCancel}>
			<ModalHeader
				title={
					<FormattedMessage
						id={'AUTOMATION.ARE_YOU_SURE'}
						defaultMessage={'Are you sure?'}
					/>
				}
			/>
			<ModalBody className={styles.modal__body}>
				{renderMessages()}
				<div>
					<Prompt />
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">{renderButtons()}</ModalFooter>
		</Modal>
	);
};

const { func, bool, any } = PropTypes;

DeleteRuleModal.propTypes = {
	onCancel: func,
	show: bool,
	onClick: func,
  modalHeader: any,
  modalMessage: any
};

export default DeleteRuleModal;
