export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Point terminal {iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Modifier point terminal',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Droit d’utiliser le réseau',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'Échec du provisionnement du point terminal ID {args1} après le changement d’état IMSI. Obtenir assistance',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE': '{step}. Sélectionner état SIM',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. Programmer le changement état SIM',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. Confirmation système',
	'SINGLE_ENDPOINT.ICCID': 'Identifiant de carte SIM',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN':
		'Nº réseau numérique à intégration de services d’abonné mobile',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'État',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Actualiser le point terminal pour vérifier si l’état a changé',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'Plateforme machine-machine',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'ID compte machine-machine',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Type de réseau',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Opérateur réseau',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'Pays réseau',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'Date ajout machine-machine',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE':
		'Date activation machine-machine',
	'SINGLE_ENDPOINT.IN_SESSION': 'En session',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Forfait',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Erreur de forfait, veuillez contacter l’assistance à la clientèle.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Erreur de droits d’utiliser le réseau, veuillez contacter l’assistance à la clientèle.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'État du transfert IMSI',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'Pays itinérance',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Nom de compte',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': 'Nom droit d’utiliser le réseau',
	'SINGLE_ENDPOINT.ROAMING': 'Itinérance',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Date création',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Date dernière mise à jour',
	'SINGLE_ENDPOINT.SMS_MO': 'SMS départ',
	'SINGLE_ENDPOINT.SMS_MT': 'SMS arrivée',
	'SINGLE_ENDPOINT.VOICE_MO': 'Voix départ',
	'SINGLE_ENDPOINT.VOICE_MT': 'Voix arrivée',
	'SINGLE_ENDPOINT.LTE_DATA': 'Données LTE',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'ID itinérance',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Type de service Camel',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Bloquer numéros surfacturés',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO':
		'Bloquer SMS de départ international',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'Modèle HLR',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'Voix international',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Numéro d’urgence',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Description',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'IP fixe',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'Non applicable à ce point terminal',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Nom de forfait',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Type de forfait',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Prix abonnement',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Frais de compte',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Type paiement',
	'SINGLE_ENDPOINT.TERM': 'Période',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Limite utilisation',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Indicateur de niveau',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID : {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD':
		'Autorisations de plus haut niveau requises !',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Seuls les administrateurs peuvent modifier les types de champs personnalisés, car ils ont un impact sur la configuration de tout le système. Contactez votre administrateur pour mettre à jour les options des champs personnalisés.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID':
		'Actions point terminal pour {endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. Sélectionner état SIM',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'ID point terminal',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'Il y a une activité programmée pour ce point terminal.',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'Voulez-vous vraiment annuler le changement en attente ?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT':
		'Cette action ne peut pas être annulée.',
	'SINGLE_ENDPOINT.CARRIER': 'Opérateur',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Réaffecté',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Note',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'L’utilisateur {uid} a effectué un changement d’état le {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Programmation réussie du changement de l’état SIM pour ',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'L’état actuel SIM pour le point terminal ID {endpointId} est ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS':
		'Droits d’utiliser le réseau admissibles',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'IP fixe',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Actuellement affecté à ce point terminal',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS':
		'Détails des droits d’utiliser le réseau',
	SELECT_MINIMUM_OF_2: 'Sélectionner un minimum de 2 à appliquer à la table. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Commencer le diagnostic',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Renseignements opérateur',
	'SINGLE_ENDPOINT.COUNTRY': 'Pays',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'Description état plateforme machine-machine',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'En service actif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Nouveau inactif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Veille actif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Arrêté inactif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I':
		'Migré à partir d’un autre environnement GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'Non provisionné',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O':
		'Migré vers autre environnement GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Prêt actif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Suspendu actif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Test actif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'En attente actif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Transformé inactif',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Suspendu réglementaire',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Résilié',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'Non disponible.',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Info provisionnement du point terminal',
	'SINGLE_ENDPOINT.PROVISIONED': 'Provisionné',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME':
		'Date et heure provisionnement {date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Connexions réseau',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Voix activée',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Données activées',
	'SINGLE_ENDPOINT.SESSIONS': 'Sessions',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Inventaire',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Activé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Activé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inactif',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inactif',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Désactivé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': 'Mis hors service',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Purgé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Prêt pour test',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Prêt pour activation',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Remplacé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Essai',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Début',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Transfert',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Source en transit, global',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Destination en transit, global',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Transféré, global',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Autre',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Trafic passant autorisé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Trafic passant non autorisé',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Ressources sans fil TollSec autorisées',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Interdit de réseau',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Trafic non autorisé – état SIM',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Suspendu par fournisseur de services',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Suspendu en raison de la limite utilisation',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Trafic non autorisé (autre)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Connexion au réseau réussie (session ouverte)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'Non connecté au réseau (session ouverte)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Enregistrement valide (activité réseau valide)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Enregistrement valide (aucune activité réseau)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Enregistrement GSM valide seulement',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'Aucun enregistrement valide (activité durant dernières 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'Aucun enregistrement valide (demande autor. durant dernières 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'Aucun enregistrement valide (mises à jour locales de plus de 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'Aucun enregistrement valide (demande autor. de plus de 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'Il y a plus de 24 heures (aucune activité réseau)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'Appareil actuellement non connecté. Sessions IP précédentes réussies.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Échec de la session de données actuelle',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Session de données antérieure réussie',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Échec de la tentative de connexion (authentifiants non valides)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'Aucun historique de session de données réussie',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Ressources sans fil TollSec autorisées',
	'SINGLE_ENDPOINT.ACTIONS': 'Actions',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'Obtenir les détails HLR/HSS',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Frais récurrents',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Sélectionner forfait',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Confirmer changements',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. Confirmation système',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Utilisation admissible avec ce point terminal',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		'Mise à jour réussie du changement de forfait',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Programmation réussie du changement de forfait pour ',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Programmation réussie du changement de forfait',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Confirmer le changement de droits d’utiliser le réseau',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Après l’exécution de ce changement, le droit d’utiliser le réseau des points terminaux sélectionnés sera ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION':
		' Souhaitez-vous exécuter cette action ?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Vous pouvez aussi programmer le changement pour : ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Programmation réussie du changement de droits d’utiliser le réseau',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Programmation réussie du changement de droits d’utiliser le réseau pour ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'Le changement de droits d’utiliser le réseau n’a pas pu être exécuté.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'Le changement de droits d’utiliser le réseau n’a pas pu être programmé.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'État du changement de droits d’utiliser le réseau',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'Le forfait actuel pour le point terminal ID {id} est ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Opérateur itinérance',
	'SINGLE_ENDPOINT.OEM': 'Sous-comptes',
	'SINGLE_ENDPOINT.SERVICE_STATUS': 'État 360L',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA : ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE : ',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Connexion réseau',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR : ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN : ',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'État enregistrement',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Comptes 360L liés',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Bimode',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Appareil connecté',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Forfait',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'État enregistré',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Date d’activation',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE':
		'Exporter données d’utilisation',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE':
		'Échec de l’exportation des données',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'Impossibilité d’exporter les données d’utilisation actuellement.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Veuillez patienter pendant que nous préparons le fichier. Selon le nombre d’enregistrements, cela peut prendre un certain temps.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'Aucune donnée à montrer pour la période sélectionnée',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'Le cas échéant, essayer de changer la plage des dates pour actualiser la liste',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Activé',
	'SINGLE_ENDPOINT.TERMINATE': 'Résilié',
	'SINGLE_ENDPOINT.DEACTIVE': 'Désactivé',
	'SINGLE_ENDPOINT.INACTIVE': 'Inactif',
	'SINGLE_ENDPOINT.TEST': 'Test',
	'SINGLE_ENDPOINT.SUSPENDED': 'Suspendu',
	'SINGLE_ENDPOINT.SUSPEND': 'Suspendre',
	'SINGLE_ENDPOINT.INVENTORY': 'Inventaire'
};
