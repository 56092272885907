import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import styles from './UploadImageModal.scss';

export default class UploadImageModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			croppedImageUrl: null,
			crop: {
				unit: 'px',
				width: 125,
				height: 125,
				aspect: 1 / 1
			}
		};
	}

	onCropComplete = (crop) => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop) => {
		this.setState({ crop });
	};

	getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = 125;
		canvas.height = 125;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#ffffff';
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			125,
			125
    );

		const base64Str = canvas.toDataURL('image/jpeg');

    return base64Str;
	}

	onImageLoaded = (image) => {
		this.imageRef = image;
	};

	saveAndClose = () => {
		const { onCancel, onClick } = this.props;
		const { croppedImageUrl } = this.state;

		onCancel();
		onClick(croppedImageUrl);
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				'newFile.jpeg'
			);
			this.setState({ croppedImageUrl });
		}
	}

	render() {
		const { src, show, onCancel } = this.props;
		const { crop } = this.state;

		return (
			<Modal data-spec="logo-upload-modal" show={show} onClose={onCancel}>
				<ModalHeader
					title={
						<FormattedMessage
							id={'ONBOARDING.CROP_IMAGE'}
							defaultMessage={'Crop the image'}
						/>
					}
				/>
				<ModalBody className={styles.modal__body}>
					<ReactCrop
						src={src}
						crop={crop}
						ruleOfThirds
						onImageLoaded={this.onImageLoaded}
						onComplete={this.onCropComplete}
						onChange={this.onCropChange}
					/>
				</ModalBody>
				<ModalFooter data-spec="ds-modal-footer">
					<div data-spec="buttons">
						<Button
							onClick={onCancel}
							variant="link"
							label={
								<FormattedMessage
									id={'ONBOARDING.CANCEL'}
									defaultMessage={'Cancel'}
								/>
							}
						/>
						<Button
							dataSpec="go-to-reports"
							variant="primary"
							onClick={this.saveAndClose}
							label={
								<FormattedMessage
									id={'ONBOARDING.CROP'}
									defaultMessage={'Crop'}
								/>
							}
						/>
					</div>
				</ModalFooter>
			</Modal>
		);
	}
}

const { func, bool, any } = PropTypes;

UploadImageModal.propTypes = {
	onCancel: func,
	show: bool,
	src: any,
	onClick: func
};
