export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Terminal',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Editar terminal',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Permiso de red',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'Fallo al suministrar la ID de terminal {args1} tras el cambio de estado de IMSI. Obtener asistencia',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE':
		'{step}. Seleccionar estado de SIM',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. Fijar cambio en estado de SIM',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. Confirmación de sistema',
	'SINGLE_ENDPOINT.ICCID': 'ICCID',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': 'MSISDN',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'Estado',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Actualizar terminal para verificar cambio de estado',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'Plataforma M2M',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Tipo de red',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Operador de red',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'País de red',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'Fecha agregada a M2M',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': 'Fecha de activación de M2M',
	'SINGLE_ENDPOINT.IN_SESSION': 'En sesión',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Plan de tarifas',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Error en plan de tarifas, comuníquese con Atención al cliente.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Error en permiso de red, comuníquese con Atención al cliente.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'Transferencia de estado IMSI',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'País de roaming',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Nombre de cuenta',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': 'Nombre de permiso de red',
	'SINGLE_ENDPOINT.ROAMING': 'Roaming',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Fecha de creación',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Fecha de última actualización',
	'SINGLE_ENDPOINT.SMS_MO': 'SMS MO',
	'SINGLE_ENDPOINT.SMS_MT': 'SMS MT',
	'SINGLE_ENDPOINT.VOICE_MO': 'Voz MO',
	'SINGLE_ENDPOINT.VOICE_MT': 'Voz MT',
	'SINGLE_ENDPOINT.LTE_DATA': 'Datos en LTE',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'ID de Roaming',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Tipo de servicio Camel',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Bloquear números premium',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': 'Bloquear SMS MO internacionales',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'Plantilla HLR',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'Voz internacional',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Número de tel. directo',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Descripción',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'IP fijo',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'No aplica a esta terminal',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Nombre de plan de tarifas',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Tipo de plan de tarifas',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Precio de suscripción',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Cargo de la cuenta',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Tipo de pago',
	'SINGLE_ENDPOINT.TERM': 'Plazo',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Límite de uso',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Indicador por niveles',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID: {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD':
		'¡Se requieren privilegios elevados!',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Solo los admin. pueden modificar los tipos de campos personalizados porque tienen una config. que afecta todo el sistema. Contacte a su admin. para actualizar las opciones de campos personalizados.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID':
		'Acciones de terminales de{endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. Seleccionar estado de SIM',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'ID de terminal',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'Hay una actividad fijada para esta terminal',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'¿Está seguro de que quiere cancelar el cambio pendiente?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT': '¡Esta acción no puede revertirse!',
	'SINGLE_ENDPOINT.CARRIER': 'Operador',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Reacreditado',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Nota',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'El usuario {uid} hizo un cambio el {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Cambio de estado de SIM fijado con éxito para',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'El estado actual de SIM para ID de terminal{endpointId} es ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Permisos de red elegibles',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'IP fijo',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Actualmente asignado a esta terminal',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS': 'Detalles de permiso de red',
	SELECT_MINIMUM_OF_2: 'Seleccionar un mín. de 2 para aplicar a la tabla. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Iniciar diagnóstico',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Información de operador',
	'SINGLE_ENDPOINT.COUNTRY': 'País',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'Descripción de estado de plataforma M2M',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'Activo - En vivo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Inactivo - Nuevo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Activo - En reposo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Inactivo - Detenido',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I': 'Migró de otro entorno GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'No suministrado',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O': 'Migró a otro entorno GDSP',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Activo - Listo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Activo - Suspendido',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Activo - Prueba',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'Activo - En espera',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Inactivo - Transformado',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Suspendido - Normativo',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Cancelado',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'No disponible',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Info. de suministro de terminal',
	'SINGLE_ENDPOINT.PROVISIONED': 'Suministrado',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME': 'Fecha y hora suministradas',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Conexiones de red',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Compatible con voz',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Compatible con datos',
	'SINGLE_ENDPOINT.SESSIONS': 'Sesiones',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Inventario',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Activado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Activado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inactivo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inactivo',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Desactivado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': 'Retirado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Depurado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Prueba lista',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Activación lista',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Reemplazado',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Prueba',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Comenzar',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Transferencia',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Fuente global en tránsito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Destino global en tránsito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Global transferido',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Otro',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Paso de tráfico permitido',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Paso de tráfico no permitido',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Recursos inalámbricos TollSec permitidos',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Excluido de la red',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Tráfico no permitido de estado de SIM',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Suspendido por proveedor de servicio',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Suspendido por límite de uso',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Tráfico no permitido (otro)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Conectado a la red con éxito (en sesión)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'No conectado a la red (en sesión)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Registro válido (Actividad de red válida)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Registro válido (No hay actividad de red)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Solo registro GSM válido',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'No hay registro válido (Tiene actividad en las últimas 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'No hay registro válido (Tiene solicitud de permiso en las últimas 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'No hay registro válido (Tiene actualizaciones locales de hace más de 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'No hay registro válido (Tiene solicitud de permiso hace más de 24 h)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'Hace más de 24 h (No hay actividad de red)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'El dispositivo no está conectado actualmente. Las sesiones anteriores de IP tuvieron éxito.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Sesión de datos actual sin éxito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Sesión de datos pasada con éxito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Intento fallido de conexión (Credenciales inválidas)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'No hay historial de sesión de datos con éxito',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Recursos inalámbricos TollSec permitidos',
	'SINGLE_ENDPOINT.ACTIONS': 'Acciones',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'Obtener detalles HLR/HSS',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Cargo recurrente',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Seleccionar plan de tarifas',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Confirmar cambios',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. Confirmación de sistema',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Elegible para usar con esta terminal',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		' Cambio de plan de tarifas actualizado con éxito',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Cambio de plan de tarifas fijado con éxito para el',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Cambio de plan de tarifas fijado con éxito',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Confirmar cambio de permiso de red',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Cuando inicie este cambio, el permiso de red de la terminal seleccionada será ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION':
		'¿Desea continuar con esta acción?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'O puede fijar el cambio para',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Cambio de permiso de red fijado con éxito.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Cambio de permiso de red fijado con éxito para ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'El cambio de permiso de red no se pudo hacer en este momento.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'El cambio de permiso de red no se pudo fijar en este momento.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'Confirmar estado de permiso de red',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'El plan de tarifas actual para la ID de terminal {id} es ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Operador de roaming',
	'SINGLE_ENDPOINT.OEM': 'Cuentas suscritas',
	'SINGLE_ENDPOINT.SERVICE_STATUS': 'Estado de 360L',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Conexión de red',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'Estado de registro',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Cuentas 360L vinculadas',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Modo dual',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Equipo conectado',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Plan de tarifas',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'Estado registrado',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Fecha de activación',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': 'Exportar datos de uso',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE': 'Fallo al exportar datos',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'No se pueden exportar datos de uso en este momento.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Espere mientras cargamos los datos. Según el número de informes, puede tardar en completarse.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'No hay datos que mostrar para el periodo seleccionado',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'Si es posible, trate de cambiar el rango de fechas para refrescar la lista',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Activado',
	'SINGLE_ENDPOINT.TERMINATE': 'Cancelado',
	'SINGLE_ENDPOINT.DEACTIVE': 'Desactivado',
	'SINGLE_ENDPOINT.INACTIVE': 'Inactivo',
	'SINGLE_ENDPOINT.TEST': 'Prueba',
	'SINGLE_ENDPOINT.SUSPENDED': 'Suspendido',
	'SINGLE_ENDPOINT.SUSPEND': 'Suspender',
	'SINGLE_ENDPOINT.INVENTORY': 'Inventario'
};
