import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import PageTitle from '../../../Shared/views/PageTitleView';

import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';
import ReleaseVersion from '../../views/ReleaseVersionViews';
import PublishReleaseNoteModal from '../ReleaseNotesPublishModal';
import styles from './ReleaseNotes.scss';

import { UserAllowedToAccess } from '../../../../utils/AuthSelector';

const RWComponent = UserAllowedToAccess(['system.releasenotes_rw']);

const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');

const ReleaseNotes = () => (WrappedComponent) => {
	class ReleaseNotesComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				isPublishReleaseNoteModalOpen: false
			};
		}

		componentDidMount() {
			this.getReleaseNotesList();
		}

		componentDidUpdate(prevProps) {
			const { releaseNotesList, publishReleaseNotesSuccess } = this.props;
			if (
				(releaseNotesList &&
					releaseNotesList.resultList &&
					releaseNotesList.resultList.length &&
					prevProps.releaseNotesList !== releaseNotesList) ||
				publishReleaseNotesSuccess
			) {
				this.getReleaseNotes(releaseNotesList.resultList[0].id);
			}
		}

		componentWillUnmount() {
			const { getReleaseNotesInit } = this.props;
			getReleaseNotesInit();
		}

		goBack = () => {
			const { goBack, backUrl } = this.props;
			goBack(backUrl);
		};

		goToAddNewReleaseNotes = () => {
			const { goToAddNewReleaseNotes } = this.props;

			goToAddNewReleaseNotes('/release-notes/add-release-notes');
		};

		getReleaseNotesList = () => {
			const { getReleaseNotesList, published } = this.props;
			const additionalParams = {
				dataSort: 'versionNumber DESC'
			};
			getReleaseNotesList({ additionalParams, published });
		};

		getReleaseNotes = (id) => {
			const { getReleaseNotes, published } = this.props;
			const additionalParams = {
				dataOffset: 0,
				include: [
					'ReleaseDetail',
					'ReleaseDetail.ReleaseDetailType',
					'ReleaseDetail.AdditionalDetailStatus'
				],
				dataSort: 'createdAt ASC'
			};

			getReleaseNotes({ id, additionalParams, published });
		};

		openPublishReleaseNoteModal = () => {
			this.setState({ isPublishReleaseNoteModalOpen: true });
		};

		closePublishReleaseNoteModal = () => {
			this.setState({ isPublishReleaseNoteModalOpen: false });
		};

		publishReleaseNotes = () => {
			const { publishReleaseNotes, releaseNotes } = this.props;

			publishReleaseNotes({ id: releaseNotes.id });
		};

		render() {
			const {
				releaseNotesListRequest,
				releaseNotesRequest,
				releaseNotes,
				backUrl,
				published
			} = this.props;
			const { isPublishReleaseNoteModalOpen } = this.state;

			return (
				<div data-spec="release-notes">
					<PageTitle
						title={
							<FormattedMessage
								id="RELEASE_NOTES.RELEASE_NOTES"
								defaultMessage="Release Notes"
							/>
						}
						actions={
							<>
								<RWComponent>
									{!published && (
										<Button
											label={
												<FormattedMessage
													id="SUPPORT.ADD_NEW_RELEASE_NOTES"
													defaultMessage="Add New Release Notes"
												/>
											}
											variant="primary"
											className={styles.button_width}
											dataSpec="add-new-release-notes-button"
											onClick={() => this.goToAddNewReleaseNotes()}
											labelIcon={<AddIcon className={styles.add_icon_plus} />}
											disabled={releaseNotesListRequest || releaseNotesRequest}
										/>
									)}
								</RWComponent>
							</>
						}
						pushBack={backUrl ? this.goBack : null}
					/>
					<ReleaseVersion
						published={published}
						openPublishReleaseNoteModal={this.openPublishReleaseNoteModal}
					/>
					{(releaseNotesListRequest || releaseNotesRequest) && <Loader />}
					{!(releaseNotesListRequest || releaseNotesRequest) &&
						releaseNotes && (
							<WrappedComponent
								{...this.props}
								{...this.state}
								getReleaseNotes={this.getReleaseNotes}
							/>
						)}
					<PublishReleaseNoteModal
						onCancel={this.closePublishReleaseNoteModal}
						show={isPublishReleaseNoteModalOpen}
						onClick={this.publishReleaseNotes}
						releaseVersion={releaseNotes && releaseNotes.version}
					/>
				</div>
			);
		}
	}

	const { func, object, bool, string } = PropTypes;

	ReleaseNotesComponent.propTypes = {
		goBack: func,
		goToAddNewReleaseNotes: func,
		getReleaseNotesList: func,
		releaseNotesListRequest: bool,
		getReleaseNotes: func,
		releaseNotesList: object,
		releaseNotesRequest: bool,
		releaseNotes: object,
		goToEditReleaseNotes: func,
		getReleaseNotesInit: func,
		backUrl: string,
		published: bool,
		match: object,
		publishReleaseNotes: func,
		publishReleaseNotesRequest: bool,
		publishReleaseNotesSuccess: bool
	};
	return ReleaseNotesComponent;
};

export default ReleaseNotes;
