import { Status } from 'utils/constants';

export const fileUploadRequest = (state) =>
	state.shared.uploadFile.status === Status.PENDING;
export const fileUploadFail = (state) =>
	state.shared.uploadFile.status === Status.FAIL;
export const fileUploadSuccess = (state) => state.shared.uploadFile.file;
export const filesUploadSuccess = (state) => state.shared.uploadFile.files;
export const fileUploadInit = (state) =>
	state.shared.uploadFile.status === Status.INIT;
export const uploadErrors = (state) => state.shared.uploadFile.errors;
export const showErrors = (state) => state.shared.uploadFile.showErrors;
export const uploadFileNumber = (state) =>
	state.shared.uploadFile.uploadFileNumber;
