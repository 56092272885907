import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';

import store, { history } from 'redux/store';

import Routes from 'routes';
import ApiClient from 'utils/ApiClient';

ApiClient.registerAuthInterceptor(store.dispatch, window.location.href);
ApiClient.registerCompanyAccountInterceptor(store);
ApiClient.getTimings();
ApiClient.registerLanguageInterceptor(store);

class Root extends PureComponent {
	render() {
		return (
			<Provider data-spec="index" store={store}>
				<ConnectedRouter data-spec="router" history={history}>
					<Routes {...this.props} />
				</ConnectedRouter>
			</Provider>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('react-root'));
