import { handleActions } from 'redux-actions';

import {
	GET_TICKETING_CHART_DATA_REQUEST,
	GET_TICKETING_CHART_DATA_SUCCESS,
	GET_TICKETING_CHART_DATA_FAIL
} from './constants';
import { Status } from '../../../../../utils/constants';

const initState = {
	data: null,
	error: null,
	status: Status.INIT
};

export default handleActions(
	{
		[GET_TICKETING_CHART_DATA_REQUEST]: (state, action) => ({
			...state,
			status: { ...state.status, [action.payload.id]: Status.PENDING }
		}),
		[GET_TICKETING_CHART_DATA_SUCCESS]: (state, action) => ({
			...state,
			data: { ...state.data, [action.payload.id]: action.payload.data },
			status: { ...state.status, [action.payload.id]: Status.DONE }
		}),
		[GET_TICKETING_CHART_DATA_FAIL]: (state, action) => ({
			...state,
			error: action.payload.error,
			status: { ...state.status, [action.payload.id]: Status.FAIL }
		})
	},
	initState
);
