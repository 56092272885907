import React, { PureComponent } from 'react';
import { object, func } from 'prop-types';

import Loader from '../../../../lib/DigitalComponents/Loader';

export default function SatelliteRatePlan(WrapperComponent) {
	class RatePlanComponent extends PureComponent {
		componentDidMount() {
	    const { availableStates, getAvailableStates } = this.props;

			if (!availableStates) {
				getAvailableStates();
			}
	  }

		render() {
	    const { availableStates, endpoint } = this.props;

			if (!availableStates) {
				return <Loader data-spec="rate-plan-loader" />;
			}

	    return (
				<WrapperComponent
					data-spec="rate-plan-wrapper"
					endpoint={endpoint}
					renderActivationFee={
						endpoint.activationFee === 0.0
							? 0
							: endpoint.activationFee
					}
				/>
			);
	  }
	}

	RatePlanComponent.propTypes = {
		endpoint: object,
		availableStates: object,
		getAvailableStates: func,
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	RatePlanComponent.displayName = `RatePlan(${wrappedComponentName})`;
	return RatePlanComponent;
}
