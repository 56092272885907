import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_SECURITY_FEATURES,
	EDIT_SECURITY_FEATURES,
  GET_SECURITY_FEATURES_STATUSES,
  ADD_SECURITY_FEATURE,
  EDIT_SECURITY_FEATURE
} from './constants';

export default combineReducers({
	getSecurityFeatures: globalReducer(GET_SECURITY_FEATURES),
  editSecurityFeatures: globalReducer(EDIT_SECURITY_FEATURES),
  editSecurityFeature: globalReducer(EDIT_SECURITY_FEATURE),
  getStatuses: globalReducer(GET_SECURITY_FEATURES_STATUSES),
  addSecurityFeature: globalReducer(ADD_SECURITY_FEATURE)
});
