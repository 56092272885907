import customMediaQuery from './CustomMediaQuery';
import CONSTANTS from '../constants';

export const DashboardXLDesktopMediaQuery = customMediaQuery(
	CONSTANTS.screen_xlg_min,
	null
);
export const DashboardDesktopMediaQuery = customMediaQuery(
	CONSTANTS.screen_lg_min,
	CONSTANTS.screen_lg_max
);
export const DesktopMediaQuery = customMediaQuery(
	CONSTANTS.screen_lg_min,
	null
);
export const TabletMediaQuery = customMediaQuery(
	CONSTANTS.screen_md_min,
	CONSTANTS.screen_md_max
);
export const MobileMediaQuery = customMediaQuery(null, CONSTANTS.screen_sm_max);
export const DesktopAndTabletMediaQuery = customMediaQuery(
	CONSTANTS.screen_md_min,
	null
);
