import { connect } from 'react-redux';
import { destroy } from 'redux-form';

import DiagnosticWrapperWing from './DiagnosticWrapperWing';
import {
	getEndpointRegistrationDetails,
	isGettingEndpointsRegistrationDetails,
	isSuccessEndpointsRegistrationDetails,
	sendSmsMessageRequest,
	getEndpointDiagnosticsDetails,
	isGettingEndpointsDiagnosticsDetails,
	cancelRegistrationRequest,
	getDiagnosticHlrDone,
	getDiagnosticHlrFail,
	getDiagnosticHlrRequest,
	getDiagnosticHlrSuccess,
} from '../../redux/selectors';
import {
	getDiagnostic,
	getRegistration,
	cancelRegistration,
	sendSMSMessage,
	getDiagnosticHlrInit,
	getDiagnosticInit,
	getDiagnosticHlr,
} from '../../redux/actions';

const mapStateToProps = (state) => ({
	dataRegistrationDetails: getEndpointRegistrationDetails(state),
	isFetchingRegistrationDetails: isGettingEndpointsRegistrationDetails(state),
	isCanceling: cancelRegistrationRequest(state),
	dataDiagnosticsDetails: getEndpointDiagnosticsDetails(state),
	isFetchingDiagnosticsDetails: isGettingEndpointsDiagnosticsDetails(state),
	isSuccessEndpointsRegistrationDetails: isSuccessEndpointsRegistrationDetails(
		state,
	),
	deviceWakeUpRequest: sendSmsMessageRequest(state),
	isHLRPending: getDiagnosticHlrRequest(state),
	isHLRDone: getDiagnosticHlrDone(state),
	isHLRFail: getDiagnosticHlrFail(state),
	dataHLR: getDiagnosticHlrSuccess(state),
});

const mapDispatchToProps = (dispatch) => ({
	onButtonClick: (iccid, platform) =>
		dispatch(getDiagnostic(iccid, platform)),
	getRegistrationDetails: (iccid) =>
		dispatch(getRegistration(iccid)),
	submitCancelRegistration: (iccid, locationData, platform) =>
		dispatch(cancelRegistration(iccid, locationData, platform)),
	deviceWakeUp: (message, iccid, notification) => {
		dispatch(sendSMSMessage(message, iccid, '/wing', notification));
		return dispatch(destroy('sendSmsForm'));
	},
	initDiagnosticHlr: () => dispatch(getDiagnosticHlrInit()),
	diagnosticsDetailsClear: () => dispatch(getDiagnosticInit()),
	getDiagnosticHlr: (deviceId) => dispatch(getDiagnosticHlr(deviceId)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DiagnosticWrapperWing);
