import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

const SimOrderEdit = () => (WrappedComponent) => {
	class SimOrderEditComponent extends PureComponent {
		componentDidMount() {
			const {
				getSimOrder,
				getSimOrderInit,
				match: {
					params: { id }
				},
				system
			} = this.props;

			const params = {
				additionalParams: {
					include: [
						'Platform',
						'Platform.PlatformType',
						'SimSku',
						'SimOrderStatus',
						'Country',
						'State'
					]
				},
				system
			};

			if (id) {
				getSimOrder(id, params);
			} else {
				getSimOrderInit();
			}
		}

		componentWillUnmount() {
			const { getSimOrderInit } = this.props;
			getSimOrderInit();
		}

		pushBack = () => {
			const { pushBack, system } = this.props;
			if (system) {
				pushBack('/company-sim-ordering');
			} else {
				pushBack('/sim-ordering');
			}
		};

		render() {
			const {
				simOrderRequest,
				simOrderFail,
				simOrder,
				messages,
				user,
				match: {
					params: { id }
				}
			} = this.props;

			return (
				<div data-spec="sim-order-edit">
					<PageTitle
						title={
							(id && (
								<FormattedMessage
									id="EXISTING_SIM_ORDER"
									defaultMessage="Order ID: {id}"
									values={{
										id: simOrder && simOrder.simOrderId
									}}
								/>
							)) || (
								<FormattedMessage
									id="NEW_SIM_ORDER"
									defaultMessage="New Order"
								/>
							)
						}
						messages={messages}
						pushBack={this.pushBack}
					/>
					<div>
						{simOrderRequest || (id && !simOrder) ? (
							<Loading data-spec="loading" />
						) : (
							<>
								<WrappedComponent
									{...this.props}
									{...this.state}
									id={id}
									messages={messages}
									initialValues={simOrder}
									onCancel={this.pushBack}
									isReadOnly={
										isUserAllowedToAccess(
											['system.managesimorders_rw'],
											user
										)
											? false
											: id && true
									}
								/>
							</>
						)}
						{!simOrderRequest && simOrderFail && null}
					</div>
				</div>
			);
		}
	}

	const { func, bool, string, objectOf, shape, array } = PropTypes;

	SimOrderEditComponent.propTypes = {
		match: shape(),
		messages: objectOf(string),
		pushBack: func.isRequired,

		getSimOrder: func.isRequired,
		simOrderRequest: bool,
		simOrderFail: bool,
		simOrder: shape({
			resultList: array
		}),
		getSimOrderInit: func.isRequired,

		user: shape(),
		system: bool
	};

	return SimOrderEditComponent;
};

export default SimOrderEdit;
