import { connect } from 'react-redux';
import ListDesktop from './Desktop';
import ListMobile from './Mobile';

import {
	getSecurityFeatures,
	editSecurityFeature,
	getSecurityFeaturesStatuses
} from '../../redux/securityFeatures/actions';

import {
	getSecurityFeaturesRequest,
	getSecurityFeaturesFail,
	getSecurityFeaturesSuccess,
	getSecurityFeaturesStatusRequest,
	getSecurityFeaturesStatusFail,
	getSecurityFeaturesStatuseSuccess,
	editSecurityFeaturesRequest,
	editSecurityFeaturesSuccess,
	editSecurityFeatureRequest,
	editSecurityFeatureSuccess,
	addSecurityFeatureRequest,
	addSecurityFeatureSuccess
} from '../../redux/securityFeatures/selectors';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import { anyModalOpened } from '../../../Shared/redux/modals/actions';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';
import { getSimpleSearchParameter } from '../../../Shared/redux/simpleSearch/selectors';

import {
	getExportToCsvRequest,
	getExportToCsvFail,
	getExportToCsvSuccess
} from '../../redux/exportToCsv/selectors';

import { clearUploadErrors } from '../../../../redux/batchFiles/actions';

import {
	setSelectedItems,
	setSelectedItemsInit
} from '../../redux/selectedItems/actions';

import { selectedItems } from '../../redux/selectedItems/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import {
	setPaginationInit,
	setDataOffset,
	setSelectedPage
} from '../../../Shared/redux/pagination/actions';
import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

const mapStateToProps = (state) => ({
	securityFeaturesRequest: getSecurityFeaturesRequest(state),
	securityFeaturesFail: getSecurityFeaturesFail(state),
	securityFeatures: getSecurityFeaturesSuccess(state),
	statusesRequest: getSecurityFeaturesStatusRequest(state),
	statusesFail: getSecurityFeaturesStatusFail(state),
	statuses: getSecurityFeaturesStatuseSuccess(state),
	editSecurityFeatureRequest: editSecurityFeatureRequest(state),
	editSecurityFeatureSuccess: editSecurityFeatureSuccess(state),
	editSecurityFeaturesSuccess: editSecurityFeaturesSuccess(state),
	editSecurityFeaturesRequest: editSecurityFeaturesRequest(state),
	exportToCsvRequest: getExportToCsvRequest(state),
	exportToCsvFail: getExportToCsvFail(state),
	exportToCsvSuccess: getExportToCsvSuccess(state),
	user: getUser(state),
	simpleSearchParameter: getSimpleSearchParameter(state),
	filterData: filterDataSuccess(state),
	addSecurityFeatureRequest: addSecurityFeatureRequest(state),
	addSecurityFeatureSuccess: addSecurityFeatureSuccess(state),
	selectedItems: selectedItems(state),
	dataOffset: getDataOffset(state),
  selectedPage: getSelectedPage(state),
  searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	getSecurityFeatures: (params) => dispatch(getSecurityFeatures(params)),
	editSecurityFeature: (id, params) =>
		dispatch(editSecurityFeature(id, params)),
	getStatuses: () => dispatch(getSecurityFeaturesStatuses()),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	clearUploadErrors: () => dispatch(clearUploadErrors()),
	setSelectedItems: (data) => dispatch(setSelectedItems(data)),
	setSelectedItemsInit: () => dispatch(setSelectedItemsInit()),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(ListDesktop);

export const Mobile = connect(mapStateToProps, mapDispatchToProps)(ListMobile);
