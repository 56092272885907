import React from 'react';
import {
	objectOf,
	string,
	number,
} from 'prop-types';

import {
	ChangesDesktop,
	ChangesMobile,
} from '../../components/Changes';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';

export default function Card({
	dataLimit,
	iccid,
	messages,
}) {
	return (
		<>
			<DesktopMediaQuery key="card-query-desktop">
				<ChangesDesktop
					dataLimit={dataLimit}
					iccid={iccid}
					messages={messages}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery key="card-query-tablet">
				<ChangesDesktop
					dataLimit={dataLimit}
					iccid={iccid}
					messages={messages}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery key="card-query-mobile">
				<ChangesMobile
					dataLimit={dataLimit}
					iccid={iccid}
					messages={messages}
				/>
			</MobileMediaQuery>
		</>
	);
}

Card.propTypes = {
	dataLimit: number,
	iccid: string,
	messages: objectOf(string),
};

Card.defaultProps = {
	// dataLimit: 0,
	iccid: '',
	messages: {},
};
