export default {
	// ALLGEMEIN
	'ONBOARDING.EDIT': 'Bearbeiten',
	'ONBOARDING.DELETE': 'Löschen',
	'ONBOARDING.NAME': 'Name',
	'ONBOARDING.DESCRIPTION': 'Beschreibung',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': 'Kontoname',
	'ONBOARDING.FRIENDLY_NAME': 'Anzeigename',
	'ONBOARDING.ACCOUNT_ID': 'Konto-ID',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Neues Unternehmen erstellen',
	'ONBOARDING.IOTC_CUSTOMERS': 'IoT Console-Kunden',
	'ONBOARDING.COMPANIES': 'Unternehmen',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'Keine Unternehmen verfügbar',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': 'Für diese Tabelle sind derzeit keine Unternehmen zur Ansicht verfügbar',
	'ONBOARDING.COMPANY_NAME': 'Unternehmensname',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'z. B.: AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'MNC-Kontoname',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'M2M-Kontoname',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'IOT-C-Kontonummer',
	'ONBOARDING.SIM_ORDERS': 'SIM-Bestellungen',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Technischer Eigentümer', - entfernt für Konto erstellt von
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Konto erstellt von',
	'ONBOARDING.VIEW_MC': 'MC anzeigen',
	'ONBOARDING.DATE_CREATED': 'Erstellungsdatum',
	'ONBOARDING.ACTIONS': 'Aktionen',
	'ONBOARDING.PLATFORMS': 'Plattformen',
	'ONBOARDING.ACTIVATION_DENIED': 'Anforderung kann nicht bearbeitet werden!',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Konto aktiviert',
	'ONBOARDING.CONTACT_SYSADMIN': 'Wenden Sie sich für weitere Hilfe an Ihren IoT Console-Administrator',

	// BENACHRICHTIGUNGEN
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': 'Einstellungen der Unternehmenstabelle wurden erfolgreich aktualisiert.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': 'Unternehmenstabellenspalten konnten zu diesem Zeitpunkt nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',
	'ONBOARDING.RETRY': 'Erneut versuchen',

	// SUCHMENÜ
	'ONBOARDING.BY': 'Nach ',
	'ONBOARDING.BUSINESS_NAME': 'Unternehmensname',
	'ONBOARDING.CONTACT_PERSON': 'Kontaktperson',
	'ONBOARDING.PLATFORM_ID': 'Plattform-ID',

	// UNTERNEHMEN HINZUFÜGEN
	'ONBOARDING.IOT_CONSOLE_ID': 'IoT Console-ID',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'Unternehmens-M2M-Konten',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': 'Sie haben mit Erfolg ein IoT Console-Konto erstellt für:',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': 'Die diesem Konto zugeordnete IoT-Gateway-Nummer:',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Neues Unternehmenskonto erstellen',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': 'Darf keine Leerzeichen oder Ziffern enthalten',
	'ONBOARDING.IDM_SERVCE_NAME': 'IDM-Dienstname',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Für den Kunden sichtbar',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Juristische Person - Unternehmen oder „Handelnd unter“',
	'ONBOARDING.COMPANY_CONTACTS': 'Unternehmenskontakte',
	'ONBOARDING.ATT_CONTACTS': 'AT&T-Kontakte',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'vTM-Supporttyp',
	'ONBOARDING.FULL_TSM': 'Full TSM (Alle Tickets an das TSM-Team, ungeachtet des Schweregrads)',
	'ONBOARDING.PARTIAL_TSM': 'Partial TSM (Tickets Schweregrad 1 an das TSM-Team, Schweregrad 2 und 3 an das Helpdesk Offline-Team)',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care (Alle Tickets an das IoT Priority Care-Team, ungeachtet des Schweregrads)',
	'ONBOARDING.NO_SUPPORT': 'Kein Support (Alle Tickets an das Helpdesk Offline-Team, ungeachtet des Schweregrads)',
	'ONBOARDING.CONTACT': 'Kontakt',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Anzeigename des Unternehmens',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Firma',
	'ONBOARDING.COMPANY_CONTACT': 'Unternehmenskontakt',
	'ONBOARDING.ATT_CONTACT': 'AT&T-Kontakt',
	'ONBOARDING.COMPANY': 'Unternehmen',
	'ONBOARDING.COUNTRY': 'Land',
	'ONBOARDING.STATE_PROVINCE': 'Bundesland/Kanton',
	'ONBOARDING.STREET_ADDRESS': 'Straße/Hausnr.',
	'ONBOARDING.ADDRESS_LINE_1': 'Adresszeile 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Adresszeile 2 (optional)',
	'ONBOARDING.CITY_TOWN': 'Ort',
	'ONBOARDING.ZIP_POSTAL_CODE': 'Postleitzahl',
	'ONBOARDING.COMPANY_DOMAIN': 'Domain des Unternehmens',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'E-Mail-Domain des Unternehmens',
	'ONBOARDING.FULL_NAME': 'Vor- und Zuname',
	'ONBOARDING.PHONE_NUMBER': 'Telefonnummer',
	'ONBOARDING.EMAIL_ADDRESS': 'E-Mail-Adresse',
	'ONBOARDING.SIM_OPTIONS': 'SIM-Optionen',
	'ONBOARDING.ORDER_MOVE_SIMS': 'SIMs bestellen oder verschieben',
	'ONBOARDING.ORDERING': 'Bestellung',
	'ONBOARDING.TRANSFER': 'Übertragung',
	'ONBOARDING.SIM_TRANSFER': 'SIM-Übertragung',
	'ONBOARDING.TRANSFERING_SIM': 'Eine SIM wird von einem M2M-Konto auf ein anderes übertragen',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': 'SIMs bestellen, auswählen oder SIM-Liste hochladen',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'Abrechnungsinformationen anzeigen oder bearbeiten',

	// PLATTFORMTABELLEN-HEADER
	'ONBOARDING.PLATFORM': 'Plattform',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Plattformbeschreibung',
	'ONBOARDING.APNS': 'APNs',
	'ONBOARDING.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'ONBOARDING.EDIT_ACTIONS': 'Konten bearbeiten',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'M2M-Plattform bearbeiten',
	'ONBOARDING.ADD_M2M_PLATFORM': 'M2M-Plattform hinzufügen',
	'ONBOARDING.MC_ACCOUNT_ID': 'IOT-C-Konto-ID',

	// M2M-PLATTFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'ATT Control Center',
	'ONBOARDING.PLATFORM_CCIP': 'Control Center Integration Program',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Geräteverbindungsplattform',
	'ONBOARDING.PLATFORM_GMNA': 'GM North America',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Enterprise',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATTFORMMENÜ
	'ONBOARDING.M2M_PLATFORM': 'M2M-Plattform',

	// NETZWERKANSPRÜCHE
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Netzwerkanspruch',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Netzwerkansprüche',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Kommunikationsprofilname',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': 'Keine Netzwerkansprüche verfügbar',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': 'Für diese Tabelle sind derzeit keine Netzwerkansprüche zur Ansicht verfügbar',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Neuen Netzwerkanspruch hinzufügen',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Netzwerkanspruch hinzufügen',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Netzwerkanspruch bearbeiten',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Netzwerkansprüche bearbeiten',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Netzwerkanspruch wählen',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Anspruch auswählen',
	'ONBOARDING.PULL_DATA': 'Daten abrufen',
	'ONBOARDING.HLR_TEMPLATE': 'HLRHSS-Details - Vorlage',
	'ONBOARDING.HOTLINE_NUMBER': 'Hotline-Nummer',
	'ONBOARDING.VOICE_MT': 'Voice MT',
	'ONBOARDING.ROAMING_LTE': 'Roaming LTE',
	'ONBOARDING.DATA': 'Daten',
	'ONBOARDING.DATA_LTE': 'Daten LTE',
	'ONBOARDING.VOICE_MO': 'Voice MO',
	'ONBOARDING.INTERNATIONAL_VOICE': 'Voice international',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': 'Internationale SMS MO sperren außer Heimatland',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Premium-Nummern sperren',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Camel-Servicetyp',
	'ONBOARDING.APNS_PDPINDEX': 'PDP-Index',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'Feste IP',
	'ONBOARDING.APNS_SECURITY': 'Sicherheit',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Abonnement',
	'ONBOARDING.ADD_NEW_APN': 'Neuen APN hinzufügen',
	'ONBOARDING.PLATFORM_DETAILS': 'Plattformdetails',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Roaming',
	'ONBOARDING.SMS_MO': 'SMS MO',
	'ONBOARDING.SMS_MT': 'SMS MT',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Qualifizierter Netzwerkanspruch',

	// TARIFPLÄNE
	'ONBOARDING.PAYMENT_TYPE': 'Zahlungstyp',
	'ONBOARDING.USAGE_LIMIT': 'Nutzungslimit',
	'ONBOARDING.RATE_PLAN': 'Tarifplan',
	'ONBOARDING.RATE_PLANS': 'Tarifpläne',
	'ONBOARDING.RATE_PLAN_NAME': 'Tarifplanname',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'Keine Tarifpläne verfügbar',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Neuen Tarifplan hinzufügen',
	'ONBOARDING.EDIT_RATE_PLAN': 'Tarifplan bearbeiten',
	'ONBOARDING.PLAN_ID': 'Plan-ID',
	'ONBOARDING.RATE_PLAN_TYPE': 'Tarifplantyp',
	'ONBOARDING.ACCOUNT_CHARGE': 'Kontogebühr',
	'ONBOARDING.TERM': 'Laufzeit',
	'ONBOARDING.TIERING_FLAG': 'Tiering-Flag',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'Per Abonnenten-MRC',
	'ONBOARDING.CURRENCY': 'Währung',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Inbegriffene Datennutzung',
	'ONBOARDING.USAGE_LIMIT_MB': 'Nutzungslimit (MB)',
	'ONBOARDING.PLAN_TYPE': 'Plantyp',
	'ONBOARDING.TYPE': 'Typ',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'RECHNUNGSKONTONAME',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'M2M-TARIFPLAN-ID',
	'ONBOARDING.PRICE_PLAN_ID': 'PREISPLAN-ID',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'ABONNEMENT-OBJEKTTYP',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'M2M-ABONNEMENT-OBJEKT-ID',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'M2M-INTERNE KONTO-ID',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'M2M-KONTONUMMER',
	'ONBOARDING.DEFAULT_PLAN': 'STANDARDPLAN',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'Dienstanbieter-ID',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'Netzwerkanspruchs-ID',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': 'Für diese Tabelle sind derzeit keine Tarifpläne zur Ansicht verfügbar',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SIM-SKU
	'ONBOARDING.SIM_SKU': 'SIM-SKU',
	'ONBOARDING.DELETE_SIM_SKU': 'SIM-SKU löschen',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': 'Durch Löschen dieser SKU werden alle mit dieser SKU verknüpften SIM-Bestellungen gelöscht. Wirklich fortfahren?',
	'ONBOARDING.SIM_ORDERING': 'SIM-Bestellung',
	'ONBOARDING.CURRENT_SIM_SKUS': 'Aktuelle SIM-SKUs',
	'ONBOARDING.ADD_SIM_SKU': 'SIM-SKU hinzufügen',
	'ONBOARDING.EDIT_SIM_SKU': 'SIM-SKU bearbeiten',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'SIM-SKU hier eingeben',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'SKU-Beschreibung eingeben',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Vertragsbeschränkungen',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': 'Bitte legen Sie Einschränkungen für den SIM-Bestellvorgang fest.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Zulässige Mindestmenge',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Mindestmenge',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Zulässige Höchstmenge',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Höchstmenge',
	'ONBOARDING.INCREMENT': 'Inkrement',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Pro Zeitraum',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Zeitraum auswählen',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'Mindestmenge',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'Höchstmenge',
	'ONBOARDING.UNIT_PRICE': 'Preis pro Einheit',
	'ONBOARDING.ALLOW_TO_ORDER': 'Bestellung zulassen',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'SKU erfolgreich bearbeitet',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'SKU konnte nicht bearbeitet werden',
	'ONBOARDING.RATE_PLAN_ID': 'Tarifplan-ID',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'Keine Sim Skus verfügbar',

	// Plattform
	'ONBOARDING.OPERATOR': 'Bediener',
	'ONBOARDING.LAUNCH_MC': 'MC starten',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Bediener (optional)',
	'ONBOARDING.MCC': 'MCC',
	'ONBOARDING.MNC': 'MNC',
	'ONBOARDING.POD': 'Pod',
	'ONBOARDING.MCC_TOOLTIP': 'Der Mobile Country Code (MCC) ist eine eindeutige dreistellige Nummer zur Identifizierung eines Landes.',
	'ONBOARDING.MNC_TOOLTIP': 'Der Mobile Network Code (MNC) ist eine eindeutige zwei- oder dreistellige Nummer zur Identifizierung eines Mobilnetzes.',
	'ONBOARDING.PLATFORM_SETUP': 'Plattformeinrichtung',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': 'Plattformeinrichtung ist nach erfolgreichem Hinzufügen einer Plattform verfügbar. ',
	'ONBOARDING.SETTINGS': 'Einstellungen',
	'ONBOARDING.EDIT_PLATFORM': 'Plattform bearbeiten',
	'ONBOARDING.ADD_PLATFORM': 'Plattform hinzufügen',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'Keine Plattformen verfügbar.',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Erforderliche Informationen fehlen',

	// Plattform bearbeiten
	'ONBOARDING.SIM_LOADING': 'SIM wird geladen',
	'ONBOARDING.GATEWAY': 'Gateway',
	'ONBOARDING.ENTER_GATEWAY': 'Gateway eingeben',
	'ONBOARDING.BILLING_INFORMATION': 'Abrechnungsinformationen',
	'ONBOARDING.BILLING_PLATFORM': 'Abrechnungsplattform',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Rechnungskontonummer',

	// Berichte
	'ONBOARDING.REPORT': 'Bericht',
	'ONBOARDING.REPORTS': 'Berichte',
	'ONBOARDING.COMPANY_REPORTS': 'Berichte',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'IDM-Integrationsfehler',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': 'Bei der IDM-Integration ist ein Problem aufgetreten. ',
	'ONBOARDING.ONBOARDING_STATUS': 'Onboarding-Status',
	'ONBOARDING.SUCCESS': 'Erfolg',
	'ONBOARDING.ERROR': 'Fehler',

	'ONBOARDING.MUST_BE_BETWEEN': 'Muss zwischen Folgendem liegen: ',
	'ONBOARDING.AND': ' und ',
	'ONBOARDING.CHARACTERS_LONG': ' Zeichen lang',
	'ONBOARDING.DEFAULT': 'Standard',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Tarifpläne hinzufügen für {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': 'Unternehmensberichtseinstellungen erfolgreich aktualisiert',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': 'Fehler bei der Aktualisierung der Unternehmensberichte',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': 'Berichte definieren, die aktiviert sind für {companyName}',
	'ONBOARDING.REPORTS_SUBTITLE': 'Aktivierte Berichte werden im IoT Console-Portal für Benutzer zum Herunterladen angezeigt',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': 'Netzwerkansprüche hinzufügen für {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': 'Für dieses Konto aktivierte Netzwerkansprüche mit AT&T Control Center verknüpfen.',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Hauptgeschäftsadresse',
	'ONBOARDING.COMPANY_DETAILS': 'Unternehmensdetails',
	'ONBOARDING.ACCOUNTS': 'Konten',
	'ONBOARDING.EDIT_ACCOUNT': 'Konto bearbeiten',
	'ONBOARDING.VIEW_ACCOUNT': 'Konto anzeigen',
	'ONBOARDING.SIM_LOADING_SUB': 'Wenn die SIMs für dieses Konto geladen werden, erfolgt dies in der Regel automatisch durch IoT Gateway, wenn das M2M-Plattformkonto erstellt wird.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Aus IoT Gateway importieren',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Kontokonfiguration',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': 'Wählen Sie die jeweils in diesem Konto zu aktivierenden Dienste aus.  Wird der gewünschte Dienst nicht angezeigt, kann er unter Einhaltung des Genehmigungsprozesses auf der übergeordneten Kontoebene hinzugefügt werden.',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Netzwerkansprüche hinzufügen',
	'ONBOARDING.ADD_RATE_PLANS': 'Tarifpläne hinzufügen',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': 'Anzuzeigende Berichte anzeigen oder bearbeiten',
	'ONBOARDING.USERS': 'Benutzer',
	'ONBOARDING.USERS_SUB': 'Durch Klicken auf die nachstehende Schaltfläche wird der Unternehmens-Identitätsbildschirm angezeigt, auf dem Sie neue Benutzer zur Teilnahme an diesem Konto einladen oder vorhandene Benutzer diesem Unternehmen hinzufügen können.',
	'ONBOARDING.CREATE_USERS': 'Benutzer erstellen',
	'ONBOARDING.USER_ACCESS': 'Benutzerzugriff',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Benutzer erstellen/hinzufügen',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Neue Unternehmensinformationen überprüfen',
	'ONBOARDING.SUBMIT': 'Absenden',
	'ONBOARDING.CREATE_COMPANY': 'Unternehmen erstellen',
	'ONBOARDING.NEW_ACCOUNT': 'Neues Konto',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': 'Es muss ein Konto oder Unterkonto in der M2M-Plattform vorhanden sein, bevor es MNC hinzugefügt werden kann. Folgen Sie den Schritten unten, um ein M2M-Konto zu erstellen, das in IoT Console bereitgestellt wird.',
	'ONBOARDING.VALIDATE': 'Validieren',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Kontonamen validieren',
	'ONBOARDING.CREATE_ACCOUNT': 'Konto erstellen',
	'ONBOARDING.ADD_ACCOUNT': 'Konto hinzufügen',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'Keine Konten verfügbar',
	'ONBOARDING.M2M_PLARFORM': 'M2M-Plattform',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'SKU erfolgreich erstellt',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'SKU konnte nicht erstellt werden',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': 'Netzwerkansprüche bearbeiten für {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Unterkonto bearbeiten',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'Unterkonto anzeigen',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Tarifpläne bearbeiten für {companyName}',
	'ONBOARDING.VIEW': 'Ansicht',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'Netzwerkansprüche anzeigen',
	'ONBOARDING.VIEW_RATE_PLANS': 'Tarifpläne anzeigen',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Unterkonto erstellen',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'Neues Unterkonto für {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'M2M-Konto erstellen',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Unterkontoname',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': 'Tarifpläne für dieses Konto auswählen',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': 'Netzwerkansprüche für dieses Konto auswählen',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': 'Warnung: Außer USA wird derzeit kein Land unterstützt',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Unterkonto zuweisen',
	'ONBOARDING.ENDPOINT_ID': 'Endpunkt-ID',
	'ONBOARDING.NETWORK_OPERATOR': 'Netzbetreiber',
	'ONBOARDING.STATUS': ' Status',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': ' Endpunkte werden in ein Konto verschoben',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Verschieben von Endpunkten bestätigen',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': 'Wenn Sie das Verschieben einleiten, werden die ausgewählten Endpunkte in wenigen Minuten verschoben. Mit dieser Aktion fortfahren?',
	'ONBOARDING.ENDPOINTS': 'Endpunkte',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'In Unterkonto verschieben',
	'ONBOARDING.FROM': 'Von',
	'ONBOARDING.TO': 'Nach',
	'ONBORDING.SIM_SKU': 'SIM-SKU',
	'ONBORDING.UNIT_PRICE': 'Preis pro Einheit',
	'ONBORDING.DATE_SUBMITED': 'Datum der Einreichung',
	'ONBORDING.EDIT_DELETE_SKU': 'SKU bearbeiten/löschen',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'Keine Endpunkte verfügbar.',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'Keine SIMs verfügbar.',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'SIM für dieses Konto auswählen',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': 'Für diese Tabelle sind derzeit keine Konten zur Ansicht verfügbar',
	//  TARIFPLAN ZAHLUNGSTYP DROPDOWNS
	'ONBOARDING.MONTHLY': 'Monatlich',
	'ONBOARDING.PREPAID': 'Prepaid',
	//  TARIFPLAN ZAHLUNGSTYP DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Monatlich – einzelner Abonnent',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Monatlich – fester Pool – einzeln',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Grundlegend – monatlicher fester Pool',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Monatlich – flexibler Pool',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': 'Grundlegend – monatlicher flexibler Pool',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': 'Monatlich – flexibler Pool Nutzungsstufe',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Monatlich – Zusatz',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Prepaid – einzelner Abonnent',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Prepaid – flexibler Pool',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Prepaid – fester Pool',
	'ONBOARDING.PREPAID_ADD_ON': 'Prepaid – Zubuchoption',
	'ONBOARDING.PREPAID_EVENT': 'Prepaid – Ereignis',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Prepaid – Stapelereignis',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  BERICHTE - CHECKBOXEN
	'ONBOARDING.DATA_USAGE_DETAIL': 'Datennutzungsdetail',
	'ONBOARDING.SMS_USAGE_DETAILS': 'SMS-Nutzungsdetails',
	'ONBOARDING.IMSI_SNAPSHOT': 'IMSI-Snapshot',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Abonnentenverzeichnis',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Abonnenten-Snapshot',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Abonnentenänderungen',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Täglicher Konto-Snapshot',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Tarifplan-Snapshot',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Kommunikationsplan-Snapshot',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Abrechnungszyklus-Referenz',
	'ONBOARDING.CARRIER_REFERENCE': 'Betreiberreferenz',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Tarifplantyp-Referenz',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'SIM-Änderungsreferenz',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': 'Nutzungsdatensatz-Schließursachen-Referenz',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Tarifplanzonen-Snapshot',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Von Dienstanbieter benutzerdefinierte Felder',
	'ONBOARDING.USER_SNAPSHOT': 'Benutzer-Snapshot',
	'ONBOARDING.NEW_SUBSCRIBERS': 'Neue Abonnenten',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Kombinierte Abonnenten',
	'ONBOARDING.COMBINED_USAGE': 'Kombinierte Nutzung',
	'ONBOARDING.APN_SNAPSHOT': 'APN-Snapshot',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Von Dienstanbieter benutzerdefinierte Felder',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Kombinierte Konten',
	'ONBOARDING.DAILY_USAGE_RAW': 'Tägliche Nutzung roh',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'Alle aktiven SIMs',
	'ONBOARDING.DAILY_USAGE': 'Tägliche Nutzung',
	'ONBOARDING.ALL_SMS_MESSAGES': 'Alle SMS-Nachrichten',
	'ONBOARDING.SMS_DETAIL': 'SMS-Detail',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'ICCID kumulative Zusammenfassung',
	'ONBOARDING.SIM_STATE_REFERENCE': 'SIM-Status-Referenz',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Voice-Nutzungsdetail',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'Alle zugewiesenen SIMs',
	'ONBOARDING.INVOICE_DETAIL': 'Rechnungsdetail',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Tarifplanzusammenfassung',
	'ONBOARDING.OTHER_CHARGES': 'Sonstige Gebühren',
	'ONBOARDING.USAGE_SUMMARY': 'Nutzungszusammenfassung',
	'ONBOARDING.ACTIVATION_CHARGES': 'Aktivierungsgebühren',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Tarifplanzonen-Nutzungsdetail',
	'ONBOARDING.INVOICE_CHARGES': 'Rechnungsgebühren',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Gepoolte Nutzungsgebühren',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Abonnentengebühren',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Nicht gepoolte Nutzungsgebühren',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Großhandelsverbrauch - Abonnent',
	'ONBOARDING.FUNDED_PACKAGES': 'Finanzierte Pakete',
	'ONBOARDING.TRIAL_COST_SHARE': 'Provision für Testkosten',
	'ONBOARDING.REVENUE_SHARE': 'Umsatzprovision',
	'ONBOARDING.BOUNTY': 'Prämie',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Datennutzungsdetails',
	'ONBOARDING.SMS_DETAILS': 'SMS-Details',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Differenz - Abonnements',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Nicht zugestellte SMS-Nachrichten',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': 'Großhandel Nullumsatz - Abonnent',
	'ONBOARDING.INVOICE_DETAILS': 'Rechnungsdetails',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Tarifplanzonen-Nutzungsdetails',
	'ONBOARDING.BILLING_INVOICE': 'Rechnungsstellung Rechnung',
	//  DROPDOWN SIM-BESTELLUNG
	'ONBOARDING.1_MONTH': '1 Monat',
	'ONBOARDING.3_MONTHS': '3 Monate',
	'ONBOARDING.1_WEEK': '1 Woche',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Konto erfolgreich übertragen',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Konto konnte nicht übertragen werden',
	'ONBOARDING.MY_COMPANY': 'Mein Unternehmen',
	// BENUTZERVERWALTUNG
	'ONBOARDING.USER_GROUPS': 'Benutzergruppen',
	'ONBOARDING.USER_COUNT': 'Benutzeranzahl',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Aktuell aktive Benutzer',
	'ONBOARDING.FIRST_NAME': 'Vorname',
	'ONBOARDING.LAST_NAME': 'Nachname',
	'ONBOARDING.JOB_TITLE': 'Tätigkeit',
	'ONBOARDING.EMAIL': 'E-Mail',
	'ONBOARDING.USER_GROUP': 'Benutzergruppe',
	'ONBOARDING.ACCOUNT_ACCESS': 'Kontozugriff',
	'ONBOARDING.CREATE_NEW_GROUP': 'Neue Gruppe erstellen',
	'ONBOARDING.INVITE_USERS': 'Benutzer einladen',
	'ONBOARDING.ADD_USERS': 'Benutzer hinzufügen',
	'ONBOARDING.EDIT_USER_PROFILE': 'Benutzerprofil bearbeiten',
	'ONBOARDING.USER_INFORMATION': 'Benutzerinformationen',
	'ONBOARDING.ADDRESSES': 'Adressen',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Zugewiesene Konten',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Rechtliche und regulatorische Informationen',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Neue Benutzergruppe erstellen',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': 'Für Benutzer Zugriffsgruppen mit benutzerdefinierten Rechte erstellen.',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name} {lastName} akzeptiert',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'Endnutzer-Lizenzvereinbarung',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': 'Wenn Sie den Bedingungen der Endnutzer-Lizenzvereinbarung nicht zustimmen, können Sie dieses Konto löschen.',
	'ONBOARDING.ON_DATE': 'am {date}',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': 'Wenn Sie den Bedingungen der Endnutzer-Lizenzvereinbarung nicht zustimmen, können Sie dieses Konto löschen.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': 'Geben Sie die E-Mail-Adressen der Benutzer ein, die Sie einladen möchten. Die Benutzer können nach dem Akzeptieren des Einladungslinks in der E-Mail-Benachrichtigung auf das Unternehmenskonto zugreifen.',
	'ONBOARDING.ADD_NEW_USER': 'Neuen Benutzer hinzufügen',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Kann SMS empfangen',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': 'Bundesstaat/Provinz/Territorium/Region',
	'ONBOARDING.COMPANY_EMAIL': 'Unternehmens-E-Mail',
	'ONBOARDING.POSTAL_CODE': 'Postleitzahl',
	'ONBOARDING.BUSINESS': 'Business',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': 'Wir nehmen den Schutz der Benutzerdaten ernst und verkaufen Ihre Benutzerdaten an niemanden.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': 'Die Endnutzer-Lizenzvereinbarung enthält detaillierte Informationen zum Schutz Ihrer Daten, zu Ihren Löschungsrechten und anderen Compliance-Vorschriften.',
	'ONBOARDING.STREET_ADDRESS_2': 'Straße/Hausnr. 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Straße/Hausnr. 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'Ort',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'Gleiche Adresse wie Primärkontakt',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Weitere Adresse hinzufügen',
	'ONBOARDING.YES': 'Ja',
	'ONBOARDING.NO': 'Nein',
	'ONBOARDING.PRIMARY_ADDRESS': 'Primäre Adresse',
	'ONBOARDING.ADDRESS_TYPE': 'Adresstyp',
	'ONBOARDING.SECONDARY_NUMBER': 'Zweitnummer',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Bevorzugte Sprache',
	'ONBOARDING.PRIMARY_NUMBER': 'Primäre Nummer',
	'ONBOARDING.UPLOAD_IMAGE': 'Bild hochladen',
	'ONBOARDING.FILTER_USER_LIST': 'Benutzerliste filtern',
	'ONBOARDING.CANCEL': 'Abbrechen',
	'ONBOARDING.ADD': 'Hinzufügen',
	'ONBOARDING.USER': 'Benutzer',
	'ONBOARDING.GROUP': 'Gruppe',
	'ONBOARDING.PASSWORD_RESET': 'Passwortrücksetzung',
	'ONBOARDING.SELECT_ACTION': 'Aktion auswählen',
	'ONBOARDING.ACTIVE_STATUS': 'Aktiver Status',
	'ONBOARDING.PENDING_STATUS': 'Ausstehender Status',
	'ONBOARDING.ADD_MORE_USERS': 'Weitere Benutzer hinzufügen',
	'ONBOARDING.ENTITLEMENT_NAME': 'Anspruchsname',
	'ONBOARDING.NO_ACCESS': 'Kein Zugriff',
	'ONBOARDING.READ_ONLY': 'Schreibgeschützt',
	'ONBOARDING.READ_WRITE': 'Lesen und Schreiben',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Admin-Gruppenrechte',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Gruppenrechte bearbeiten',
	'ONBORDING.USER_GROUPS': 'Benutzergruppen',
	'ONBOARDING.CREATE_GROUP': 'Gruppe erstellen',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Neue Benutzergruppe auswählen',
	'ONBOARDING.GROUP_NAME': 'Gruppenname',
	'ONBOARDING.PAUSE': 'Pausieren',
	'ONBOARDING.REACTIVATE': 'Wiederaktivieren',
	'ONBOARDING.ACTIVATE': 'Aktivieren',
	'ONBOARDING.REMOVE': 'Entfernen',
	'ONBOARDING.RESEND_INVITE': 'Einladung noch einmal zusenden',
	'ONBOARDING.CANCEL_INVITE': 'Einladung zurückziehen',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': 'Sind Sie sicher, dass Sie den Benutzer entfernen möchten?',
	'ONBOARDING.USER_PROFILE': 'Benutzerprofil',
	'ONBOARDING.SUSPEND': 'Suspendieren',
	'ONBOARDING.USER_MANAGEMENT': 'Benutzerverwaltung',
	'ONBOARDING.MAILING': 'Postadresse',
	'ONBOARDING.BILLING': 'Abrechnung',
	'ONBOARDING.SECONDARY': 'Sekundär',
	'ONBOARDING.PRIMARY': 'Primär',
	'ONBOARDING.PHONE': 'Telefon',
	'ONBOARDING.ENGLISH': 'English (USA)',
	'ONBOARDING.SAVE_USER': 'Benutzer speichern',
	'ONBOARDING.BULK_UPLOAD': 'Sammel-Upload',
	'ONBOARDING.FOR_BULK_UPLOADS': 'für Sammel-Uploads',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'CSV-Vorlage herunterladen',
	'ONBOARDING.SEND_INVITE': 'Einladung senden',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': 'Pausierte Benutzer haben keinen Zugriff auf {companyName}-Konten. Pausierten Benutzern ist es nicht gestattet, Zugriff anzufordern. Der Benutzerzugriff kann jedoch von den Administratoren reaktiviert werden. Die pausierten Benutzer müssen dann ihr Kontopasswort zurücksetzen.',
	'ONBOARDING.PAUSE_USER': 'Benutzer pausieren',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Zugewiesene Benutzergruppe',
	'ONBOARDING.ACCESS_LEVEL': 'Zugriffslevel',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': 'Passwort-Rücksetungslink an E-Mail senden',
	'ONBOARDING.EDIT_USER': 'Benutzer bearbeiten',
	'ONBOARDING.RESET_PASSWORD': 'Passwort zurücksetzen',
	'ONBOARDING.CURRENT_STATUS': 'Aktueller Status',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'Zugewiesene Benutzerrechte anzeigen',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Einzelne Rechte verwalten',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Einzelne Rechte',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Geltende Rechte',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Zugewiesene Benutzergruppen',
	'ONBOARDING.ACTION': 'Aktion',
	'ONBOARDING.DELETE_PERMISSIONS': 'Rechte löschen',
	'ONBOARDING.VIEW_PERMISSIONS': 'Rechte anzeigen',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Weitere Benutzergruppe hinzufügen',
	'ONBOARDING.PENDING_INVITATIONS': 'Ausstehender Rechte ',
	'ONBOARDING.PERMISSIONS': 'Rechte',
	'ONBOARDING.CLOSE': 'Schließen',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Gespeicherte einzelne Rechte',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': 'Folgenden M2M-Konten wird Zugriff gewährt',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Zugriffsverwaltung',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Dienstanbieter-Benutzer',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Dienstanbieter-Benutzergruppen',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': 'Dienstanbieter-Benutzergruppen hinzugüfen, um Kundenkonten zu verwalten und neue Kunden aufzunehmen.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'Keine verfügbaren Benutzergruppen',

	// BENUTZERGRUPPENRECHTE
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': 'Wenn Sie eine eingeschränkten Vorgang durchführen möchten, wenden Sie sich bitte an Ihren Ansprechpartner für technische Angelegenheiten.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': 'Einige Konfigurationen sind auf AT&T Super-User vorgesehen.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': 'Benutzergruppen werden mithilfe des Least-Privilege-Modells erstellt.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'Ansprüche auf Systemebene',
	'ONBOARDING.DENY_ACCESS': 'Zugriff verweigern',
	'ONBOARDING.ALLOW_READ': 'Schreiben erlauben',
	'ONBOARDING.ALLOW_WRITE': 'Schreiben erlauben',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Gruppenrechte bearbeiten',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'Keine Genehmigungen verfügbar',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': 'Für diese Tabelle sind derzeit keine Netzwerkansprüche zur Ansicht verfügbar',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'M2M-Konten bearbeiten',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'In Unterkonto verschieben',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Kann E-Mails erhalten',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Uneingeschränkter Zugriff',
	'ONBOARDING.ACTIVE': 'Aktiv', // später löschen
	'ONBOARDING.PAUSED': 'Pausiert', // später löschen
	'ONBOARDING.VIEW_USER_GROUP': 'Benutzergruppe anzeigen',
	'ONBOARDING.DELETE_USER_GROUP': 'Benutzergruppe löschen',
	'ONBOARDING.SAVE': 'Speichern',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': 'Gruppe auswählen, zu der der Benutzer gehören soll:',

	'ONBOARDING.ALLOW_ACCESS': 'Zugriff erlauben',

	// KONTOAKTIVIERUNG
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Neues Passwort erstellen',
	'ONBOARDING.NEW_PASSWORD': 'Neues Passwort',
	'ONBOARDING.CONFIRM_PASSWORD': 'Passwort bestätigen',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Neues Passwort eingeben',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Geben Sie bitte Ihr neues Passwort ein',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Zurück zur Anmeldung',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'Informationen nicht angegeben.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': ', um die Adressendetails hinzuzufügen.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Zugewiesene Konten bearbeiten',
	'ONBOARDING.EDIT_PERMISSIONS': 'Rechte bearbeiten',
	'ONBOARDING.SUSPENDED': 'Suspendiert',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Ausstehende Einladung verfügbar',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': 'In nächsten Schritt können Sie die neuen Gruppenrechte einstellen',
	'ONBOARDING.GRANT_ACCESS': 'Zugriff gewähren',
	'ONBOARDING.SELECT_USER_GROUP': 'Bitte Benutzergruppe auswählen',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': 'Keine Systemeinladungen verfügbar',
	'ONBOARDING.ADD_USER_GROUP': 'Benutzergruppe hinzufügen',
	'ONBOARDING.ADD_ADDRESS': 'Adresse hinzufügen',
	'ONBOARDING.CROP_IMAGE': 'Bild zuschneiden',
	'ONBOARDING.CROP': 'Zuschneiden',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': 'Diese Aktion kann nicht rückgängig gemacht werden. Der Verlauf des Benutzers wird von unseren Systemen vergessen.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': 'Mit dem Löschen des Profils wird der Benutzerzugriff von allen Konten gelöscht, auf die Sie derzeit zugreifen können.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Profil für {name} {lastName} löschen',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Bestätigungsphrase eingeben',
	'ONBOARDING.DELETE_PROFILE': 'Profil löschen',
	'ONBOARDING.DELETE_USER': 'Benutzer löschen',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': 'Rechtfertigung für Pausierung (optional)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': 'Wirklich fortfahren?',
	'ONBOARDING.REACTIVATE_USER': 'Benutzer wiederaktivieren',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': 'Wenn Sie auf „Ja“ klicken, erhalten Sie in Kürze eine E-Mail zum Zurücksetzen Ihres Passworts.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Benutzerpasswort zurücksetzen',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': 'Mit dem Neustart des Passworts wirklich fortfahren?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Benutzergruppe zuweisen',
	'ONBOARDING.ASSIGN': 'Zuweisen',
	'ONBOARDING.AND_OTHERS': 'Und {totalCount} weitere...',
	'ONBOARDING.MY_PROFILE': 'Mein Profil',
	'ONBOARDING.EDIT_USER_GROUP': 'Benutzergruppe bearbeiten',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Einzelne Rechte bearbeiten',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'Einzelne Rechte anzeigen',
	'ONBOARDING.NO_USERS_AVAILABLE': 'Keine Benutzer verfügbar',
	'ONBOARDING.CONTACTS': 'Kontakte',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': 'Diese Operation wirklich durchführen?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Benutzergruppe entfernen',
	'ONBOARDING.CONTACT_TYPE': 'Kontakttyp',
	'ONBOARDING.CANCEL_INVITATION': 'Einladung zurückziehen',
	'ONBOARDING.RESEND_INVITATION': 'Einladung noch einmal zusenden',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Benutzergruppe wiederaktivieren',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Benutzergruppe suspendieren',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': 'Keine zuzuweisenden Benutzergruppen vorhanden',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'Adresstyp bereits vorhanden',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Neue Adresse hinzufügen',
	'ONBOARDING.ADD_NEW_CONTACT': 'Neuen Kontakt hinzufügen',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Weiteren Kontakt hinzufügen',
	'ONBOARDING.COMPANY_FEATURES': 'Unternehmensfunktionen',
	'ONBOARDING.INFO_NOT_PROVIDED': 'Informationen nicht angegeben',
	'ONBOARDING.COMPANY_SETTINGS': 'Unternehmenseinstellungen',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': 'Wenn Sie bereits über ein Konto verfügen, melden Sie sich bitte an.',
	'ONBOARDING.ACCOUNT_ACTIVATED': 'Sie erhalten in Kürze eine E-Mail mit Anweisungen zum Erstellen Ihres Passworts und für den Zugriff auf Ihr Konto.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': 'Die Rücksetzung des Passworts erfolgt über AT&T Global Sign-On',

	//  Abrechnungsplattform
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Adresstyp
	'ONBOARDING.BILLING_ADDRESS': 'Abrechnungsadresse',
	'ONBOARDING.HOME_ADDRESS': 'Privatadresse',

	//  Kontakttyp
	'ONBOARDING.MAIN_CONTACT': 'Primäre Kontaktperson',
	'ONBOARDING.BACKUP_CONTACT': 'Alternative Kontaktperson',
	'ONBOARDING.TECHNICAL_OWNER': 'Technischer Eigner',

	//  Adresstyp
	'ONBOARDING.SHIPPING': 'Versand',
	'ONBOARDING.HOME': 'Home',
	'ONBOARDING.ANOTHER': 'Weitere',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'IoT Console starten',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': 'This nummer is erforderlich, um Rechnungen in IoT Console aufzurufen.',
	'ONBOARDING.BILLING_REPORTS': 'Abrechnungsberichte',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': 'Dieses Feld muss markiert sein, um Rechnungsdetails aufzurufen.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': 'Abrechnungsdetails werden für diese Plattform nicht unterstützt.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': 'Abrechnungskonfiguration anzeigen oder bearbeiten',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'Konto-ID nicht gefunden.',
	'ONBOARDING.ARE_YOU_SURE': 'Sind Sie sicher?',
	'ONBOARDING.REMOVE_CONTACT': 'Kontakt entfernen',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'Pod, Tenant der Instanz-ID',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': 'Geräteverbindungsplattform',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': 'Wirklich fortfahren?',

// Added after 7.4
	'ONBOARDING.ANONYMIZED': 'Anonymisiert',
  'ONBOARDING.ACQUISITION_BOUNTY': 'Prämie für Erwerb',
  'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Prämie für kombinierten Erwerb',
  'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Zuschuss für kombinierte Hardware',
  'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': 'Von Partnern finanzierte Pakete, kombiniert',
  'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Gepoolte Nutzungsgebühren, kombiniert',
  'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Gepoolte Abonnementgebühren, kombiniert',
  'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Provision für kombinierte Testkosten',
  'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Gepoolte Nutzungszusammenfassung, kombiniert',
  'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': 'Monatliche Rechnungsgebühren, kombiniert',
  'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': 'Monatliche Rechnungsdetails, kombiniert',
  'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': 'Monatliche nicht-gepoolte Nutzungsgebühren, kombiniert',
  'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Monatliche sonstige Gebühren, kombiniert',
  'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Von Partnern finanzierte Pakete',
  'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'Kontogerätestatus nach Monaten',
  'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': 'Kontonutzungsbericht nach Satellitennetzwerk',
};
