import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import NoDataView from '../../../Shared/views/NoDataView';
import PermissionsView from '../../views/PermissionsView/PermissionsView';
import {
	required
} from '../../../../utils/validators';

import styles from './GrantAccessModal.scss';

const searchParams = [{ propValue: true, prop: 'active', operator: '=' }];

class GrantAccessModal extends Component {
	componentDidMount() {
		const { getUserGroups } = this.props;
		getUserGroups({ searchParams });
	}

	grantAccess = () => {
		const { grantAccess, data, formValues, onClose } = this.props;
		const request = {
			userGroupId: formValues.userGroupId && formValues.userGroupId.value,
			registrationId: data
		};
		grantAccess(request);
		onClose();
	};

	getOptions = () => {
		const { userGroups } = this.props;
		const userGroupOptions = userGroups ? userGroups.resultList : [];
		return userGroupOptions.map((item) => ({
			value: item.id,
			label: item.name
		}));
	};

	renderForm = () => {
		const { handleSubmit } = this.props;
		return (
			<form
				onSubmit={handleSubmit}
				data-spec="grant-access-form"
			>
				<div className={styles.column}>
					<p>
						<FormattedMessage
							id="ONBOARDING.SELECT_USER_GROUP"
							defaultMessage="Please select user group"
						/>
					</p>
					<Field
						validate={[required]}
						component={Select}
						name="userGroupId"
						options={this.getOptions()}
					/>
				</div>
			</form>
		);
	};

	render() {
		const { show, onClose, userGroupsRequest, isInvalid, isDirty, formValues } = this.props;
		const userGroups = this.getOptions();
		return (
			<Modal
				data-spec="grant-access-modal"
				show={show}
				onClose={onClose}
				className={styles.modal}
			>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.GRANT_ACCESS"
							defaultMessage="Grant access"
						/>
					}
				/>
				<ModalBody className={styles.modal_body}>
					{userGroupsRequest && <Loader data-spec="grant-access-loader" />}
					{userGroups && userGroups.length > 0 && this.renderForm()}
					{formValues &&
						formValues.userGroupId &&
						formValues.userGroupId.value && (
								<PermissionsView
									{...this.props}
									id={formValues.userGroupId.value}
								/>
						)}
					{!userGroupsRequest && (!userGroups || userGroups.length === 0) && (
						<NoDataView />
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						dataSpec="close-grant-access-modal"
						variant="link"
						label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
						onClick={onClose}
					/>
					<Button
						dataSpec="apply-grant-access-modal"
						variant="primary"
						label={<FormattedMessage id="ONBOARDING.ACTIVATE" defaultMessage="Activate" />}
						onClick={() => {
							this.grantAccess();
						}}
						disabled={isInvalid || !isDirty}
					/>
				</ModalFooter>
			</Modal>
		);
	}
}
const { func, bool, number, object } = PropTypes;

GrantAccessModal.propTypes = {
	show: bool,
	onClose: func,
	grantAccess: func,
	getUserGroups: func,
	handleSubmit: func,
	userGroupsRequest: bool,
	userGroups: object,
	data: number,
	isInvalid: bool,
	isDirty: bool,
	formValues: object
};

GrantAccessModal.defaultProps = {
	show: false
};

export default reduxForm({
	form: 'GrantAccessModalForm',
	destroyOnUnmount: true
})(GrantAccessModal);
