import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import Button from '../../../../lib/DigitalComponents/Button';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';

import styles from './StatusPromptModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

export default function StatusPromptModal({
	show,
	onCancel,
	newState,
	endpoint,
	type,
	initiateChange,
	scheduleChange
}) {
	return (
		<Modal
			data-spec="dashboard-prompt-modal"
			show={show}
			onClose={onCancel}
			className={styles.modal}
		>
			<ModalHeader
				title={
					<FormattedMessage
						id="ENDPOINTS.SIM_STATE_CHANGE"
						defaultMessage="SIM state change"
					/>
				}
			/>
			<ModalBody className={styles.dashboard__modal__body}>
				<div data-spec="body-message" className={styles.prompt__body}>
					<div className={styles.modal_message}>
						<FormattedMessage
							id="ENDPOINTS.SIM_STATE_CHANGED_TO"
							defaultMessage="You have chosen to change SIM state to"
						/>
						&nbsp;
						<span className={styles.uppercase}>
							{newState ? newState.label : ''}
						</span>
						!
					</div>
					<div className={styles.modal_message}>
						<FormattedMessage
							id="ENDPOINTS.TAP_INITIATE_CHANGE"
							defaultMessage="Tap 'Initiate change' to take immediate action."
						/>
					</div>

					{(endpoint.networkType && endpoint.networkType.toLowerCase()) !==
						'satellite' &&
						type !== 'satellite' && (
							<>
								<div className={styles.modal_text_block}>
									<div className={styles.modal_message}>
										<FormattedMessage
											id="ENDPOINTS.CHANGE_LATER"
											defaultMessage="You can also schedule the change for later."
										/>
									</div>
									<div className={styles.modal_message}>
										<FormattedMessage
											id="ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN"
											defaultMessage="Scheduled change will happen at the beginning of the date you select."
										/>
									</div>
								</div>
								<Button
									data-spec="schedule-button"
									variant="outline-primary"
									onClick={scheduleChange}
									label={
										<FormattedMessage
											id="ENDPOINTS.SCHEDULE_CHANGE_BUTTON"
											defaultMessage="Schedule change"
										/>
									}
								/>
							</>
						)}
				</div>
				<div>
					<Prompt />
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				<Button
					variant="link"
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					useCloseModalFunction
				/>
				<Button
					variant="primary"
					onClick={initiateChange}
					label={
						<FormattedMessage
							id="ENDPOINTS.INITIATE_CHANGE"
							defaultMessage="Initiate change"
						/>
					}
				/>
			</ModalFooter>
		</Modal>
	);
}

StatusPromptModal.propTypes = {
	onCancel: PropTypes.func,
	show: PropTypes.bool,
	newState: PropTypes.object,
	initiateChange: PropTypes.func,
	scheduleChange: PropTypes.func,
	endpoint: PropTypes.object,
	type: PropTypes.string
};

StatusPromptModal.defaultProps = {
	endpoint: {}
};
