export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': '多个端点操作',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': '请选择两个或多个端点',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': '请应用一个或多个过滤器，以便启用导出特性',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': '当前已指派给选中的端点',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': '可用于选中的端点',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': '目前无法成功地安排多个SIM卡网络权限更改',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': '已成功更新SIM卡网络权限',
	'ENDPOINTS.VIEW_NOTE': '查看注释',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': '无可用的端点',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': '本表格目前无可显示的端点',
	//  EDIT TABLE MODAL
	'ENDPOINTS.TABLE_NAME': '表格名称',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': '编辑表格视图名称',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': '请输入表格名称',
	'ENDPOINTS.EDIT_TABLE': '编辑表格参数',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': '编辑定制字段标签',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': '编辑表格参数',
	'ENDPOINTS.APPLY_CHANGE': '应用更改',
	'ENDPOINTS.DELETE_TAB': '删除标签',
	'ENDPOINTS.CUSTOM_LABEL': '定制标签{index}',
	'ENDPOINTS.CLEAR_ALL': '恢复默认',
	'ENDPOINTS.DEVICE_TYPE': '设备类型',
	'ENDPOINTS.BULK_UPLOAD': '批量上传',

	//  EDIT TABLE PARAMETERS
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': '至少选择应用于此表格的2个项目。',
	'ENDPOINTS.CELLULAR_COLUMNS': '蜂窝特定参数',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': '卫星特定参数',

	//  CELLULAR SPECIFIC PARAMETERS
	'ENDPOINTS.M2MACCOUNTID': '机对机（M2M）账户ID',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': '状态',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': '网络运营者',
	'ENDPOINTS.NETWORK_TYPE': '网络类型',
	'ENDPOINTS.IMSI_TRANSFER': 'IMSI转让状态',
	'ENDPOINTS.RATE_PLAN': '费率计划',
	'ENDPOINTS.RECREDENTIAL_STATUS': '重新设定端点状态',
	'ENDPOINTS.ICCID': '集成线路卡识别符（ICCID）',
	'ENDPOINTS.SERVICE_PROVIDER': '机对机（M2M）平台',
	'ENDPOINTS.MSISDN': '移动台国际订户目录号（MSISDIN）',
	'ENDPOINTS.COUNTRY_OPERATOR': '网络国家',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': '网络权限ID',
	'ENDPOINTS.OEM': '子账户',
	'ENDPOINTS.SERVICE_STATUS': '360L状态',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': '漫游国家',
	'ENDPOINTS.ROAMING_CARRIER': '漫游服务运营商',
	'ENDPOINTS.M2M_DATE_ADDED': '机对机（M2M）日期已添加',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': '机对机（M2M）激活日期',
	'ENDPOINTS.NOTES': '注释',
	'ENDPOINTS.CTD_DATA_USAGE': 'CTD数据用量',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'CTD短信服务（SMS）用量',
	'ENDPOINTS.CTD_VOICE_USAGE': 'CTD语音用量',
	'ENDPOINTS.INSESSION': '会话中',

	//  SATELLITE SPECIFIC PARAMETERS
	'ENDPOINTS.M2M_ACC_ID': '机对机（M2M）账户ID',
	'ENDPOINTS.DEVICE_NAME': '设备名称',
	'ENDPOINTS.DEVICE_ID': '设备ID',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': '设备型号#',

	//  RATE PLAN SCHEDULE MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': '底层平台出现错误，目前不可更改费率计划。',
	'ENDPOINTS.RATE_PLAN_CHANGE': '费率计划更改',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': '您已经选择将费率计划更改为',
	'ENDPOINTS.TAP_INITIATE_CHANGE': '点按“开始更改”即可立即开始行动。',
	'ENDPOINTS.CHANGE_LATER': '您也可以安排稍后再更改。',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': '安排的更改将在您选择的日期开始时执行。',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': '安排更改',
	'ENDPOINTS.INITIATE_CHANGE': '启动更改',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': '已成功安排费率计划更改',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': '已成功地安排费率计划更改{scheduleDate}',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': '无法成功地安排费率计划更改！',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1.安排费率计划更改',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2.系统确认',
	'ENDPOINTS.NOTIFICATION_ERROR': '端点ID {endpointICCID} 未能在费率计划更改后完成配给。获得支持',
	'ENDPOINTS.SCHEDULE_DATE': '日期',
	'ENDPOINTS.SIM_STATE_CHANGE': 'SIM卡状态更改',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': '您已经选择将SIM卡状态更改为',
	'ENDPOINTS.STATUS_CHANGE_ERROR': '用户{user} 已经更改状态，更改日期为 {changeDate}',
	'ENDPOINTS.SCHEDULING': '正在安排……',

	//  SIM STATUS
	'ENDPOINTS.ACTIVE': '已激活',
	'ENDPOINTS.TERMINATE': '已终止',
	'ENDPOINTS.PROVISIONED': '已配给',
	'ENDPOINTS.DEACTIVE': '停用',
	'ENDPOINTS.INACTIVE': '未使用',
	'ENDPOINTS.TEST': '测试',
	'ENDPOINTS.SUSPENDED': '已暂停',
	'ENDPOINTS.SUSPEND': '暂停',
	'ENDPOINTS.INVENTORY': '库存',

	//  SIM STATE SCHEDULE CHANGE
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1.安排SIM卡状态更改',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': '安排SIM卡状态更改',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': '已成功安排SIM卡状态更改！',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': '已成功安排SIM卡状态更改，日期为 {scheduledDate}',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': '无法成功地安排SIM卡状态更改！',
	'ENDPOINTS.PLEASE_TRY_AGAIN': '请重试。',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': '端点ID {endpointICCID} 更改IMSI状态后配给失败。 获得支持',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE': '用户{user} 已经更改状态，更改日期为 {changeDate}',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS': '用户{user} 已经更改状态，更改日期为 {date}',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': '目前无法成功地安排多个SIM卡状态更改！',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': '目前无法成功地安排多个SIM卡状态更改！',

	//  GENERATE REPORT MODAL
	'ENDPOINTS.CREATE_REPORT': '创建报告',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': '创建定制报告',
	'ENDPOINTS.ENDPOINTS_ARE': '已为导出选择端点。',
	'ENDPOINTS.ENDPOINT_IS': '已为导出选择端点。',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': '此定制报告将在报告部分提供。',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': '您可以在此处为报告附加定制名称',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': '定制报告名称附录',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': '添加定制报告名称附录（只允许使用数字和字母）',

	//  MULTI-ACTION MODAL
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1.选择SIM卡状态',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2.确认更改',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3.系统确认',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1.选择费率计划',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1.选择网络权限',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1.更改注释',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2.系统确认',
	'ENDPOINTS.CHANGE_SIM_STATE': '更改SIM卡状态',
	'ENDPOINTS.CHANGE_RATE_PLAN': '更改费率计划',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': '更改网络权限',
	'ENDPOINTS.CHANGE_NOTES': '注释',

	//  MULTI-ACTION NOTES MODAL
	'ENDPOINTS.SAVE_NOTE': '保存注释',
	'ENDPOINTS.ADD_NOTE_BELOW': '在下面添加注释：',
	'ENDPOINTS.ENTER_NOTE_HERE': '在这里输入注释（最多2000个字符）',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': '超出字符上限',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': '不可仅包含空格',
	'ENDPOINTS.CHANGING_NOTES': '更改注释',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': '已成功更改端点',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': '无法成功地开始为多个端点更改注释',
	'ENDPOINTS.SUCCESS_KEY': '成功',
	'ENDPOINTS.ERROR': '错误',
	'ENDPOINTS.REASON': '原因',
	'ENDPOINTS.ENDPOINTS_SELECTED': '已选择端点',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': '不允许更改注释',

	//  更改费率计划
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': '请选择几个SIM卡。',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': '不允许更改费率计划',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': '无可用的费率计划',
	'ENDPOINTS.SIMS_HAVE': 'SIM卡已经',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': '选择不同的费率计划，应用于所有选择的SIM卡',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': '未选择费率计划',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': '确认费率计划更改',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': '您已经选择更改费率计划，',
	'ENDPOINTS.SELECTED_SIMS_TO': '即选择的SIM卡的费率计划，更改为',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': '您确定要开始更改吗？',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': '您也可以将此更改安排在： ',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': '已成功地为多张SIM卡安排费率计划',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'SIM卡费率计划更改',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': '已成功将SIM卡安排在',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': '已成功地上载SIM卡费率计划',
	'ENDPOINTS.SIMS_SELECTED': '已选择SIM卡',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': '未能成功安排多张SIM卡的费率计划更改',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': '未能成功要求多张SIM卡的费率计划更改',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': '选择了相同的费率计划',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': '端点ID {endpointID} 已成功配给。费率计划已更改为 {ratePlan}',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': '端点ID {endpointID} 未能在费率计划更改后完成配给。获得支持',

	//  CHANGE SIM STATE
	'ENDPOINTS.CURRENT': '当前',
	'ENDPOINTS.NETWORK_TYPE_CHECK': '抱歉，不允许对来自不同网络类型或服务提供商的端点执行多个端点状态更改。',
	'ENDPOINTS.SELECT_SIM_STATE': '选择SIM卡状态',
	'ENDPOINTS.CURRENT_STATES': '当前状态',
	'ENDPOINTS.SELECTED_TIP': '提示：如果您选择了处于不同状态的SIM卡，您只能将所有选择的SIM卡更改到一个可用的状态。',
	'ENDPOINTS.VIEW_RULES': '查看此账户适用的状态更改规则',
	'ENDPOINTS.STATES_MESSAGE': '没有可用的状态',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': '确认更改SIM卡状态',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': '一旦开始此更改，被选中的SIM卡将',
	'ENDPOINTS.PROCEED_ACTION': '您确定要继续此项行动吗？',
	'ENDPOINTS.CAN_SCHEDULE': '或者您也可以将此更改安排在',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': '端点ID {endpointID} 已成功配给。IMSI状态被更改为{status}',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': '端点ID {endpointID} 更改IMSI状态后配给失败。 获得支持',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': '用户{user} 已经更改状态，更改日期为 {date}',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL': '已成功安排多个SIM卡状态更改！',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'SIM卡状态更改为',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': '已成功请求更改SIM卡状态！',

	//  GENERAT REPORT
	'ENDPOINTS.CUSTOM_REPORT_CREATED': '已成功创建定制报告',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': '尝试下载您的文件时出错',
	'ENDPOINTS.GO_TO_REPORTS': '前往报告',

	//  UPDATE CUSTOM LABELS
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': '已成功更新一个或多个定制字段标签。',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': '本次未能更新一个或多个定制字段标签。请稍后重试。',

	//  激活平台
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': '设备连接平台（DCP）',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

  TECH_TYPE: '技术类型',
  TECH_TYPE_SUB_TYPE: '技术类型子类别',
  'ENDPOINTS.TECH_TYPE': '技术类型',
  'ENDPOINTS.TECH_TYPE_SUB_TYPE': '技术类型子类别',
  'SINGLE_ENDPOINT.TECH_TYPE': '技术类型',
  'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': '技术类型子类别'
};
