import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import { GET_TICKETS, ADD_TICKET, EDIT_TICKET, GET_TICKET } from './constants';

export default combineReducers({
	getTickets: globalReducer(GET_TICKETS),
	getTicket: globalReducer(GET_TICKET),
	addTicket: globalReducer(ADD_TICKET),
	editTicket: globalReducer(EDIT_TICKET)
});
