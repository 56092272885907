import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Error500.scss';

const Error500 = () => (
	<div data-spec="error-500">
		<div className={styles.title}>500</div>
		<div className={styles.sub_title}>
			<FormattedMessage id="ERRORS.ERROR" defaultMessage="Error" />
		</div>
		<div className={styles.message}>
			<FormattedMessage
				id="ERRORS.INTERNAL_SERVER_ERROR"
				defaultMessage="Internal Server Error"
			/>
		</div>
	</div>
);

export default Error500;
