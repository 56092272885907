export default {
	// COMMON
	'ONBOARDING.EDIT': 'Editar',
	'ONBOARDING.DELETE': 'Borrar',
	'ONBOARDING.NAME': 'Nombre',
	'ONBOARDING.DESCRIPTION': 'Descripción',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': 'Nombre de cuenta',
	'ONBOARDING.FRIENDLY_NAME': 'Nombre descriptivo',
	'ONBOARDING.ACCOUNT_ID': 'ID de cuenta',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Crear empresa nueva',
	'ONBOARDING.IOTC_CUSTOMERS': 'Clientes de IoT Console',
	'ONBOARDING.COMPANIES': 'Empresas',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'No hay empresas disponibles',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': 'No hay empresas para esta tabla para mostrarle ahora',
	'ONBOARDING.COMPANY_NAME': 'Nombre de la empresa',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'p.ej.: AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'Nombre de cuenta MNC',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'Nombre de cuenta M2M',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'Número de cuenta IOT-C',
	'ONBOARDING.SIM_ORDERS': 'Pedidos de SIM',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Technical Owner', - removed for Account Created By
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Cuenta creada por',
	'ONBOARDING.VIEW_MC': 'Ver MC',
	'ONBOARDING.DATE_CREATED': 'Fecha de creación',
	'ONBOARDING.ACTIONS': 'Acciones',
	'ONBOARDING.PLATFORMS': 'Plataformas',
	'ONBOARDING.ACTIVATION_DENIED': 'No se pudo procesar la solicitud',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Cuenta activada',
	'ONBOARDING.CONTACT_SYSADMIN': 'Para más asistencia póngase en contacto con su administrador de IoT Console',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': 'Los parámetros de la tabla de empresas se actualizaron con éxito.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': 'Las columnas de la tabla de empresas no se pudieron actualizar esta vez. Inténtelo nuevamente.',
	'ONBOARDING.RETRY': 'Reintentar',

	// SEARCH MENU
	'ONBOARDING.BY': 'Por ',
	'ONBOARDING.BUSINESS_NAME': 'Nombre del comercio',
	'ONBOARDING.CONTACT_PERSON': 'Persona de contacto',
	'ONBOARDING.PLATFORM_ID': 'ID de plataforma',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'ID de IoT Console: ',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'Cuentas M2M de la empresa',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': 'Creó con éxito una cuenta IoT Console para:',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': 'Número de pasarela IoT asociado con esta cuenta:',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Crear cuenta de empresa nueva',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': 'No puede contener espacios ni números',
	'ONBOARDING.IDM_SERVCE_NAME': 'Nombre de servicio IDM',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Visible para el cliente',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Nombre comercial o de la entidad legal',
	'ONBOARDING.COMPANY_CONTACTS': 'Contacto de la empresa',
	'ONBOARDING.ATT_CONTACTS': 'Contactos de AT&T',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'Tipo de asistencia vTM',
	'ONBOARDING.FULL_TSM': 'TSM total (Todos los tickets, sea cual sea su gravedad, van al equipo de TSM)',
	'ONBOARDING.PARTIAL_TSM': 'TSM parcial (los tickets de gravedad 1 van a TSM, y los de gravedad 2 y 3 van al Servicio de asistencia técnica fuera de línea)',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care (Todos los tickets, sea cual sea su gravedad, van a IoT Priority Care)',
	'ONBOARDING.NO_SUPPORT': 'Sin asistencia (Todos los tickets, sea cual sea su gravedad, van al Servicio de asistencia técnica fuera de línea)',
	'ONBOARDING.CONTACT': 'Contacto',

	// ADDED AFTER 7.4
	'ONBOARDING.FULL_TSM_DESCRIPTION':
		'Todos los tickets, independientemente de su gravedad, serán respaldados por el equipo de Technical Solutions Manager. El contrato de la cuenta debe especificar que califican para el soporte de TSM o que el equipo de Diseño e Implementación de Soluciones Avanzadas (ASDI) ha especificado que la cuenta califica para el soporte de TSM.',
	'ONBOARDING.PARTIAL_TSM_DESCRIPTION':
		'Los tickets críticos serán apoyados por el equipo de Technical Solutions Manager. Todas las demás severidades serán compatibles con el equipo de ASCS HD Offline (Servicio de atención al cliente de soluciones avanzadas). El contrato de la cuenta debe especificar que califican para el soporte de TSM para problemas críticos.',
	'ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION':
		'Todas las entradas serán respaldadas por el equipo de IoT Priority Care. El contrato de la cuenta debe especificar que califican para el soporte de IoT Priority Care.',
	'ONBOARDING.STANDARD': 'Estándar (predeterminado)',
	'ONBOARDING.STANDARD_DESCRIPTION':
		'Este es el Tipo de soporte predeterminado para todas las cuentas que no tienen un Tipo de soporte especializado descrito en su contrato. El equipo de ASCS HD Offline (Servicio de atención al cliente de soluciones avanzadas) proporcionará el primer nivel de soporte.',
	'ONBOARDING.IOT_GATEWAY': 'IoT Gateway',
	'ONBOARDING.IOT_GATEWAY_DESCRIPTION':
		'Solo para uso cuando se envíen consultas de equipo para solicitudes de reemplazo de TCU (Unidad de control del transceptor) procesadas manualmente.',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Nombre descriptivo de la empresa',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Nombre legal del comercio',
	'ONBOARDING.COMPANY_CONTACT': 'Contacto de la empresa',
	'ONBOARDING.ATT_CONTACT': 'Contacto de AT&T',
	'ONBOARDING.COMPANY': 'Empresa',
	'ONBOARDING.COUNTRY': 'País',
	'ONBOARDING.STATE_PROVINCE': 'Estado/Provincia',
	'ONBOARDING.STREET_ADDRESS': 'Dirección',
	'ONBOARDING.ADDRESS_LINE_1': 'Línea de dirección 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Línea de dirección 2 (opcional)',
	'ONBOARDING.CITY_TOWN': 'Ciudad/Localidad',
	'ONBOARDING.ZIP_POSTAL_CODE': 'CP/Código postal',
	'ONBOARDING.COMPANY_DOMAIN': 'Dominio de la empresa',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'Dominio de correo electrónico de la empresa',
	'ONBOARDING.FULL_NAME': 'Nombre completo',
	'ONBOARDING.PHONE_NUMBER': 'Número de teléfono',
	'ONBOARDING.EMAIL_ADDRESS': 'Correo electrónico',
	'ONBOARDING.SIM_OPTIONS': 'Opciones de SIM',
	'ONBOARDING.ORDER_MOVE_SIMS': 'Pedir o mover tarjetas SIM',
	'ONBOARDING.ORDERING': 'Pedido',
	'ONBOARDING.TRANSFER': 'Transferencia',
	'ONBOARDING.SIM_TRANSFER': 'Transferir SIM',
	'ONBOARDING.TRANSFERING_SIM': 'Transfiriendo SIM de una cuenta M2M a otra cuenta M2M',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': 'Pedir tarjetas SIM, seleccionar o cargar lista de SIM',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'Ver o editar información de facturación',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': 'Plataforma',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Descripción de la plataforma',
	'ONBOARDING.APNS': 'APNs',
	'ONBOARDING.M2M_ACCOUNT_ID': 'ID de cuenta M2M',
	'ONBOARDING.EDIT_ACTIONS': 'Editar cuentas',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'Editar plataforma M2M',
	'ONBOARDING.ADD_M2M_PLATFORM': 'Agregar plataforma M2M',
	'ONBOARDING.MC_ACCOUNT_ID': 'ID de cuenta IOT-C',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'Centro de Control ATT',
	'ONBOARDING.PLATFORM_CCIP': 'Programa de integración del centro de control',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Plataforma de conexión de equipo',
	'ONBOARDING.PLATFORM_GMNA': 'GM Norteamérica',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Empresa',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': 'Plataforma M2M',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Permiso de red',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Permisos de red',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Nombre de perfil de comunicación',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': 'No hay permisos de red disponibles',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': 'No hay permisos de red para esta tabla para mostrarle ahora',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Agregar permiso de red nuevo',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Agregar permiso de red',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Editar permiso de red',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Editar permisos de red',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Elegir permiso de red',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Seleccionar permiso',
	'ONBOARDING.PULL_DATA': 'Recoger datos',
	'ONBOARDING.HLR_TEMPLATE': 'Plantilla de Detalles HLRHSS',
	'ONBOARDING.HOTLINE_NUMBER': 'Número de tel. directo',
	'ONBOARDING.VOICE_MT': 'Voz MT',
	'ONBOARDING.ROAMING_LTE': 'Roaming en LTE',
	'ONBOARDING.DATA': 'Datos',
	'ONBOARDING.DATA_LTE': 'Datos en LTE',
	'ONBOARDING.VOICE_MO': 'Voz MO',
	'ONBOARDING.INTERNATIONAL_VOICE': 'Voz internacional',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': 'Bloquear SMS MO internacional excepto en país de origen',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Bloquear números premium',
	'ONBOARDING.FACETIME': 'FACETIME',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Tipo de servicio Camel',
	'ONBOARDING.APNS_PDPINDEX': 'Índice PDP',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'IP fijo',
	'ONBOARDING.APNS_SECURITY': 'Seguridad',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Suscripción',
	'ONBOARDING.ADD_NEW_APN': 'Agregar APN nuevo',
	'ONBOARDING.PLATFORM_DETAILS': 'Detalles de plataforma',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Roaming',
	'ONBOARDING.SMS_MO': 'SMS MO',
	'ONBOARDING.SMS_MT': 'SMS MT',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Permiso de red elegible',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': 'Tipo de pago',
	'ONBOARDING.USAGE_LIMIT': 'Límite de uso',
	'ONBOARDING.RATE_PLAN': 'Plan de tarifas',
	'ONBOARDING.RATE_PLANS': 'Planes de tarifas',
	'ONBOARDING.RATE_PLAN_NAME': 'Nombre de plan de tarifas',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'No hay planes de tarifas disponibles',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Agregar plan de tarifas nuevo',
	'ONBOARDING.EDIT_RATE_PLAN': 'Editar plan de tarifas',
	'ONBOARDING.PLAN_ID': 'ID de plan',
	'ONBOARDING.RATE_PLAN_TYPE': 'Tipo de plan de tarifas',
	'ONBOARDING.ACCOUNT_CHARGE': 'Cargo de la cuenta',
	'ONBOARDING.TERM': 'Plazo',
	'ONBOARDING.TIERING_FLAG': 'Indicador por niveles',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'MRC por suscriptor',
	'ONBOARDING.CURRENCY': 'DIVISA',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Uso incluido de datos',
	'ONBOARDING.USAGE_LIMIT_MB': 'Límite de uso (MB)',
	'ONBOARDING.PLAN_TYPE': 'Tipo de plan',
	'ONBOARDING.TYPE': 'Tipo',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'NOMBRE DE CUENTA EN LA FACTURA',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'ID DE PLAN DE TARIFAS M2M',
	'ONBOARDING.PRICE_PLAN_ID': 'ID DE PRECIO DE PLAN',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'TIPO DE SUSCRIPCIÓN',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'ID DE SUSCRIPCIÓN M2M',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'ID DE CUENTA INTERNA M2M',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'NÚMERO DE CUENTA M2M',
	'ONBOARDING.DEFAULT_PLAN': 'PLAN PREDETERMINADO',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'ID DE PROVEEDOR DE SERVICIO',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'ID de permiso de red',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': 'No hay planes de tarifas para esta tabla para mostrarle ahora',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SIM SKU
	'ONBOARDING.SIM_SKU': 'SKU DE SIM',
	'ONBOARDING.DELETE_SIM_SKU': 'Borrar SKU de SIM',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': 'Al borrar este SKU borrará todos los pedidos de SIM vinculados a este SKU. ¿Está seguro de que quiere continuar?',
	'ONBOARDING.SIM_ORDERING': 'Pedido de SIM',
	'ONBOARDING.CURRENT_SIM_SKUS': 'SKU de SIM actual',
	'ONBOARDING.ADD_SIM_SKU': 'Agregar SKU de SIM',
	'ONBOARDING.EDIT_SIM_SKU': 'Editar SKU de SIM',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'Ingresar SKU de SIM aquí',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'Ingresar descripción de SKU',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Restricciones de contrato',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': 'Fije las restricciones en el proceso de pedido de SIM.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Cant. mín. permitida',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Cant. mín.',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Cant. máx. permitida',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Cant. máx.',
	'ONBOARDING.INCREMENT': 'Incrementar',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Por período',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Seleccionar período',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'Cant. mín.',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'Cant. máx.',
	'ONBOARDING.UNIT_PRICE': 'Precio unitario',
	'ONBOARDING.ALLOW_TO_ORDER': 'Permitir hacer pedido',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'SKU editado con éxito',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'No se pudo editar SKU',
	'ONBOARDING.RATE_PLAN_ID': 'ID de plan de tarifas',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'No hay Skus de Sim disponibles',

	// 'Plataforma',
	'ONBOARDING.OPERATOR': 'Operador',
	'ONBOARDING.LAUNCH_MC': 'Ejecutar MC',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Operador (opcional)',
	'ONBOARDING.MCC': 'MCC',
	'ONBOARDING.MNC': 'MNC',
	'ONBOARDING.POD': 'POD',
	'ONBOARDING.MCC_TOOLTIP': 'El código de país para móvil (MCC) es un número de identificación único de 3 dígitos de un país.',
	'ONBOARDING.MNC_TOOLTIP': 'El código de red de serv. móvil (MNC) es un número de 2 ó 3 dígitos que identifica una red.',
	'ONBOARDING.PLATFORM_SETUP': 'Configuración de plataforma',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': 'La config. de plataforma estará disponible tras agregar una plataforma con éxito. ',
	'ONBOARDING.SETTINGS': 'Parámetros',
	'ONBOARDING.EDIT_PLATFORM': 'Editar plataforma',
	'ONBOARDING.ADD_PLATFORM': 'Agregar plataforma',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'No hay plataformas disponibles.',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Información obligatoria faltante',

	// 'Editar plataforma',
	'ONBOARDING.SIM_LOADING': 'Cargando SIM',
	'ONBOARDING.GATEWAY': 'Pasarela',
	'ONBOARDING.ENTER_GATEWAY': 'Ingresar pasarela',
	'ONBOARDING.BILLING_INFORMATION': 'Información de facturación',
	'ONBOARDING.BILLING_PLATFORM': 'Plataforma de facturación',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Número de cuenta de la factura',

	// 'Informes',
	'ONBOARDING.REPORT': 'Informe',
	'ONBOARDING.REPORTS': 'Informes',
	'ONBOARDING.COMPANY_REPORTS': 'Informes',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'Error de integración IDM',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': 'Parece que hubo un error de integración IDM. ',
	'ONBOARDING.ONBOARDING_STATUS': 'Estado de incorporación',
	'ONBOARDING.SUCCESS': 'SUCCESS',
	'ONBOARDING.ERROR': 'Error',

	'ONBOARDING.MUST_BE_BETWEEN': 'Debe tener entre ',
	'ONBOARDING.AND': 'Y',
	'ONBOARDING.CHARACTERS_LONG': ' caracteres',
	'ONBOARDING.DEFAULT': 'Predeterminado',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Agregar planes de tarifas para {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': 'La empresa informa que los parámetros se actualizaron con éxito',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': 'Error al actualizar informes de la empresa',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': 'Definir informes para habilitarlos para {companyName}',
	'ONBOARDING.REPORTS_SUBTITLE': 'Los informes marcados se mostrarán en el portal IoT Console para que los usuarios los descarguen',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': 'Agregar permisos de red para {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': 'Vincular permisos de red habilitados para esta cuenta con el Centro de control de AT&T',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Dirección de la empresa',
	'ONBOARDING.COMPANY_DETAILS': 'Detalles de la empresa',
	'ONBOARDING.ACCOUNTS': 'Cuentas',
	'ONBOARDING.EDIT_ACCOUNT': 'Editar cuenta',
	'ONBOARDING.VIEW_ACCOUNT': 'Ver cuenta',
	'ONBOARDING.SIM_LOADING_SUB': 'El lugar donde las SIM están cargadas para esta cuenta, normalmente la Pasarela IoT hace esto automáticamente cuando se establece la cuenta de plataforma M2M.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Importar de pasarela IoT',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Configuración de cuenta',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': 'Seleccionar servicios aplicables para habilitar en esta cuenta. Si no ve los servicios deseados, se puede agregar uno al nivel de la cuenta primaria, sujeto a proceso de aprobación. ',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Agregar permisos de red',
	'ONBOARDING.ADD_RATE_PLANS': 'Agregar planes de tarifas',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': 'Ver o editar informes para mostrar',
	'ONBOARDING.USERS': 'Usuarios',
	'ONBOARDING.USERS_SUB': 'Al hacer clic en el botón de abajo aparecerá la pantalla de identidad de su empresa‚ allí puede invitar a usuarios nuevos para unirse a esta cuenta o agregar usuarios a esta empresa.',
	'ONBOARDING.CREATE_USERS': 'Crear usuarios',
	'ONBOARDING.USER_ACCESS': 'Acceso del usuario',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Crear/agregar usuario',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Verificar información de nueva empresa',
	'ONBOARDING.SUBMIT': 'Enviar',
	'ONBOARDING.CREATE_COMPANY': 'Crear empresa',
	'ONBOARDING.NEW_ACCOUNT': 'Nueva cuenta',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': 'Debe existir una cuenta o cta. secundaria en la plataforma M2M antes de poder agregarla a MNC. Siga los siguientes pasos para crear una cuenta M2M para activar en IoT Console. ',
	'ONBOARDING.VALIDATE': 'Verificar',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Verificar nombre de cuenta',
	'ONBOARDING.CREATE_ACCOUNT': 'Crear cuenta',
	'ONBOARDING.ADD_ACCOUNT': 'Agregar cuenta',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'No hay cuentas disponibles',
	'ONBOARDING.M2M_PLARFORM': 'Plataforma M2M',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'SKU creado con éxito',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'No se pudo crear SKU',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': 'Editar permisos de red para {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Editar cuenta suscrita',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'Ver cuenta suscrita',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Editar planes de tarifas para {companyName}',
	'ONBOARDING.VIEW': 'Ver',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'Ver permisos de red',
	'ONBOARDING.VIEW_RATE_PLANS': 'Ver planes de tarifas',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Crear cuenta de suscripción',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'Nueva cuenta suscrita para {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'Generar cuenta M2M',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Nombre de cuenta suscrita',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': 'Seleccione planes de tarifas para esta cuenta',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': 'Seleccione permisos de red para esta cuenta',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': 'Advertencia. En este momento no se permite otro país que no sea EE. UU.',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Asignar a cta. suscrita',
	'ONBOARDING.ENDPOINT_ID': 'ID de terminal',
	'ONBOARDING.NETWORK_OPERATOR': 'Operador de red',
	'ONBOARDING.STATUS': 'Estado',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': ' las terminales se moverán a una cuenta',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Confirmar traslado de terminales',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': 'Una vez que inicie el traslado, las terminales seleccionadas se moverán en unos minutos. ¿Desea continuar con esta acción?',
	'ONBOARDING.ENDPOINTS': 'Terminales',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'Mover a cta. suscrita',
	'ONBOARDING.FROM': 'De',
	'ONBOARDING.TO': 'A',
	'ONBORDING.SIM_SKU': 'SKU de SIM',
	'ONBORDING.UNIT_PRICE': 'Precio unitario',
	'ONBORDING.DATE_SUBMITED': 'Fecha de envío',
	'ONBORDING.EDIT_DELETE_SKU': 'Editar / Borrar SKU',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'No hay terminales disponibles.',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'No hay SIM disponibles',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'Seleccionar SIM para esta cuenta',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': 'No hay cuentas para esta tabla para mostrarle ahora',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': 'Mensual',
	'ONBOARDING.PREPAID': 'Prepagado',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Mensual - Suscriptor individual',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Mensual - Grupo fijo - Único',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Esencial - Grupo fijo mensual',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Mensual - Grupo flexible',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': 'Esencial - Grupo flexible mensual',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': 'Mensual - Nivel de uso de grupo flexible',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Mensual - Extra',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Prepagado - Suscriptor individual',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Prepagado - Grupo flexible',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Prepagado - Grupo fijo',
	'ONBOARDING.PREPAID_ADD_ON': 'Prepagado - Adicional',
	'ONBOARDING.PREPAID_EVENT': 'Prepagado - Evento',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Prepagado - Evento apilado',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': 'Detalle de uso de datos',
	'ONBOARDING.SMS_USAGE_DETAILS': 'Detalle de uso de SMS',
	'ONBOARDING.IMSI_SNAPSHOT': 'Instantánea de IMSI',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Directorio de suscriptor',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Instantánea de suscriptor',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Cambios de suscriptor',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Instantánea diaria de cuenta',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Instantánea de plan de tarifas',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Instantánea de plan de comunicación',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Referencia de ciclo de facturación',
	'ONBOARDING.CARRIER_REFERENCE': 'Referencia de operador',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Referencia de tipo de plan de tarifas',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'Referencia de cambio de SIM',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': 'Referencia de causa de cierre de registro de uso',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Instantánea de zona de tarifas',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Campos personalizados de proveedor de servicio',
	'ONBOARDING.USER_SNAPSHOT': 'Instantánea de usuario',
	'ONBOARDING.NEW_SUBSCRIBERS': 'Suscriptores nuevos',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Suscriptores combinados',
	'ONBOARDING.COMBINED_USAGE': 'Uso combinado',
	'ONBOARDING.APN_SNAPSHOT': 'Instantánea de APN',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Campos personalizados de proveedor de servicio',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Cuentas combinadas',
	'ONBOARDING.DAILY_USAGE_RAW': 'Uso bruto diario',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'Todas las SIM activas',
	'ONBOARDING.DAILY_USAGE': 'Uso diario',
	'ONBOARDING.ALL_SMS_MESSAGES': 'Todos los mensajes SMS',
	'ONBOARDING.SMS_DETAIL': 'Detalles de SMS',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'Resumen acumulativo de ICCID',
	'ONBOARDING.SIM_STATE_REFERENCE': 'Referencia de estado de SIM',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Detalle de uso de Voz',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'Todas las SIM asignadas',
	'ONBOARDING.INVOICE_DETAIL': 'Detalle de la factura',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Resumen del plan de tarifas',
	'ONBOARDING.OTHER_CHARGES': 'Otros cargos',
	'ONBOARDING.USAGE_SUMMARY': 'Resumen de uso',
	'ONBOARDING.ACTIVATION_CHARGES': 'Cargos de activación',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Detalle de uso de zona de plan de tarifas',
	'ONBOARDING.INVOICE_CHARGES': 'Cargos de la factura',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Cargos de uso no combinado',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Cargos del suscriptor',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Cargos de uso no combinado',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Uso de suscripción al por mayor',
	'ONBOARDING.FUNDED_PACKAGES': 'Paquetes financiados',
	'ONBOARDING.TRIAL_COST_SHARE': 'Costos compartidos de prueba',
	'ONBOARDING.REVENUE_SHARE': 'Ingresos compartidos',
	'ONBOARDING.BOUNTY': 'Bono',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Detalles de uso de datos',
	'ONBOARDING.SMS_DETAILS': 'Detalles de SMS',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Delta de suscripciones',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Mensajes SMS no entregados',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': 'Ingresos nulos de suscripción al por mayor',
	'ONBOARDING.INVOICE_DETAILS': 'Detalles de la factura',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Detalles de uso de zona de plan de tarifas',
	'ONBOARDING.BILLING_INVOICE': 'Factura',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1 mes',
	'ONBOARDING.3_MONTHS': '3 meses',
	'ONBOARDING.1_WEEK': '1 semana',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Cuenta transferida con éxito',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Fallo al transferir cuenta',
	'ONBOARDING.MY_COMPANY': 'Mi empresa',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': 'Grupos de usuario',
	'ONBOARDING.USER_COUNT': 'Conteo de usuario',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Usuarios activos actualmente',
	'ONBOARDING.FIRST_NAME': 'Nombre',
	'ONBOARDING.LAST_NAME': 'Apellido',
	'ONBOARDING.JOB_TITLE': 'Cargo',
	'ONBOARDING.EMAIL': 'Correo electrónico',
	'ONBOARDING.USER_GROUP': 'Grupo de usuario',
	'ONBOARDING.ACCOUNT_ACCESS': 'Acceso a la cuenta',
	'ONBOARDING.CREATE_NEW_GROUP': 'Crear nuevo grupo',
	'ONBOARDING.INVITE_USERS': 'Invitar usuarios',
	'ONBOARDING.ADD_USERS': 'Agregar usuarios',
	'ONBOARDING.EDIT_USER_PROFILE': 'Editar perfil de usuario',
	'ONBOARDING.USER_INFORMATION': 'Información de usuario',
	'ONBOARDING.ADDRESSES': 'Direcciones',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Cuentas asignadas',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Legal y reglamentario',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Crear nuevo grupo de usuarios',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': 'Crear grupos de permiso de acceso personalizado para sus usuarios',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name} {lastName} aceptados',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'Contrato de licencia de usuario final',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': 'Si no está de acuerdo con los términos del EULA, puede borrar esta cuenta.',
	'ONBOARDING.ON_DATE': 'el {date}.',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': 'Si no está de acuerdo con los términos del EULA, puede borrar esta cuenta.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': 'Ingrese la dirección de correo electrónico de los usuarios que desee invitar. Los usuarios tendrán acceso a la cuenta de la compañía tan pronto como acepten el enlace de invitación del correo de aviso.',
	'ONBOARDING.ADD_NEW_USER': 'Agregar nuevo usuario',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Puede recibir SMS',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': 'Estado/Provincia/Territorio/Región',
	'ONBOARDING.COMPANY_EMAIL': 'Correo electrónico de la empresa',
	'ONBOARDING.POSTAL_CODE': 'Código postal',
	'ONBOARDING.BUSINESS': 'Comercial',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': 'La privacidad de los usuarios es importante para nosotros y no venderemos sus datos a nadie.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': 'El EULA contiene información detallada sobre cómo proteger su privacidad, el derecho de cancelación y otras normas.',
	'ONBOARDING.STREET_ADDRESS_2': 'Línea de dirección 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Línea de dirección 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'Ciudad o municipio',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'La misma dirección',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Agregar otra dirección',
	'ONBOARDING.YES': 'Sí',
	'ONBOARDING.NO': 'No',
	'ONBOARDING.PRIMARY_ADDRESS': 'Dirección principal',
	'ONBOARDING.ADDRESS_TYPE': 'Tipo de dirección',
	'ONBOARDING.SECONDARY_NUMBER': 'Número alternativo',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Idioma preferido',
	'ONBOARDING.PRIMARY_NUMBER': 'Número principal',
	'ONBOARDING.UPLOAD_IMAGE': 'Cargar imagen',
	'ONBOARDING.FILTER_USER_LIST': 'Filtrar lista de usuarios',
	'ONBOARDING.CANCEL': 'Cancelar',
	'ONBOARDING.ADD': 'Agregar',
	'ONBOARDING.USER': 'Usuario',
	'ONBOARDING.GROUP': 'Grupo',
	'ONBOARDING.PASSWORD_RESET': 'Restablecer contraseña',
	'ONBOARDING.SELECT_ACTION': 'Seleccionar acción',
	'ONBOARDING.ACTIVE_STATUS': 'Estado activo',
	'ONBOARDING.PENDING_STATUS': 'Estado pendiente',
	'ONBOARDING.ADD_MORE_USERS': 'Agregar más usuarios',
	'ONBOARDING.ENTITLEMENT_NAME': 'Nombre de permiso',
	'ONBOARDING.NO_ACCESS': 'No hay acceso',
	'ONBOARDING.READ_ONLY': 'De lectura solamente',
	'ONBOARDING.READ_WRITE': 'De escritura solamente',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Admin. permisos de grupo',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Editar permisos de grupo',
	'ONBORDING.USER_GROUPS': 'Grupos de usuario',
	'ONBOARDING.CREATE_GROUP': 'Crear grupo',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Seleccionar grupo de usuarios existente',
	'ONBOARDING.GROUP_NAME': 'NOMBRE DEL GRUPO',
	'ONBOARDING.PAUSE': 'Pausar',
	'ONBOARDING.REACTIVATE': 'Reactivar',
	'ONBOARDING.ACTIVATE': 'Activar',
	'ONBOARDING.REMOVE': 'Eliminar',
	'ONBOARDING.RESEND_INVITE': 'Reenviar invitación',
	'ONBOARDING.CANCEL_INVITE': 'Cancelar invitación',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': '¿Está seguro de que quiere eliminar el usuario?',
	'ONBOARDING.USER_PROFILE': 'Perfil de usuario',
	'ONBOARDING.SUSPEND': 'Suspender',
	'ONBOARDING.USER_MANAGEMENT': 'Gestión de usuario',
	'ONBOARDING.MAILING': 'Correo',
	'ONBOARDING.BILLING': 'Facturación',
	'ONBOARDING.SECONDARY': 'Secundario',
	'ONBOARDING.PRIMARY': 'Principal',
	'ONBOARDING.PHONE': 'Teléfono',
	'ONBOARDING.ENGLISH': 'Inglés (EE. UU.)',
	'ONBOARDING.SAVE_USER': 'Guardar usuario',
	'ONBOARDING.BULK_UPLOAD': 'Carga masiva',
	'ONBOARDING.FOR_BULK_UPLOADS': 'para cargas masivas',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'Descargar plantilla de CSV',
	'ONBOARDING.SEND_INVITE': 'Enviar invitación',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': 'Al pausar a los usuarios se perderá acceso a las cuentas de {companyName}. Los usuarios pausados no podrán solicitar acceso, pero el administrador puede reactivar las cuentas de los usuarios. Para recuperar el acceso, los usuarios pausados tienen que restablecer su contraseña.',
	'ONBOARDING.PAUSE_USER': 'Pausar usuario',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Grupo de usuarios asignado',
	'ONBOARDING.ACCESS_LEVEL': 'Nivel de acceso',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': 'Enviar enlace para restablecer contraseña al correo',
	'ONBOARDING.EDIT_USER': 'Editar usuario',
	'ONBOARDING.RESET_PASSWORD': 'Restablecer contraseña',
	'ONBOARDING.CURRENT_STATUS': 'Estado actual',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'Ver permisos asignados a los usuarios',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Gestionar permisos individuales',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Permisos individuales',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Permisos vigentes',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Grupos de usuarios asignados',
	'ONBOARDING.ACTION': 'Acción',
	'ONBOARDING.DELETE_PERMISSIONS': 'Eliminar permisos',
	'ONBOARDING.VIEW_PERMISSIONS': 'Ver permisos',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Agregar otro grupo de usuarios',
	'ONBOARDING.PENDING_INVITATIONS': 'Invitaciones pendientes',
	'ONBOARDING.PERMISSIONS': 'Permisos',
	'ONBOARDING.CLOSE': 'Cerrar',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Guardar permisos individuales',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': 'Se otorgará permiso a las siguientes cuentas M2M',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Gestión de acceso',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Usuarios de proveedores de servicios',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Grupos de usuarios de proveedores de servicios',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': 'Agregar usuarios de proveedores de servicios para que puedan gestionar cuentas de clientes e incorporar otras nuevas.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'No hay grupos de usuarios disponibles',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': 'Si necesita ejecutar una operación restringida, solicite ayuda al contacto técnico.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': 'Algunas configuraciones están limitadas a superusuarios de AT&T.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': 'Los grupos de usuario se configuran siguiendo el modelo de menor privilegio.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'Permisos de nivel de sistema',
	'ONBOARDING.DENY_ACCESS': 'Negar acceso',
	'ONBOARDING.ALLOW_READ': 'Permitir lectura',
	'ONBOARDING.ALLOW_WRITE': 'Permitir escritura',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Editar permisos de grupo',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'No hay permisos disponibles.',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': 'No hay permisos de red disponibles para esta tabla para mostrarle ahora',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'Editar cuentas M2M',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'Mover a cta. suscrita',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Puede recibir correo',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Acceso restringido',
	'ONBOARDING.ACTIVE': 'Activo/a', // borrar después
	'ONBOARDING.PAUSED': 'Pausado/a', // borrar después
	'ONBOARDING.VIEW_USER_GROUP': 'Ver grupo de usuarios',
	'ONBOARDING.DELETE_USER_GROUP': 'Borrar grupo de usuarios',
	'ONBOARDING.SAVE': 'Guardar',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': 'Seleccionar el grupo al que debería pertenecer el usuario:',

	'ONBOARDING.ALLOW_ACCESS': 'Permitir acceso',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Crear contraseña nueva',
	'ONBOARDING.NEW_PASSWORD': 'Nueva contraseña',
	'ONBOARDING.CONFIRM_PASSWORD': 'Confirmar contraseña',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Ingresar contraseña nueva',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Ingrese su nueva contraseña',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Volver a inicio de sesión',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'No se suministró información.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': 'para agregar detalles de la dirección.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Editar cuentas asignadas',
	'ONBOARDING.EDIT_PERMISSIONS': 'Editar permisos',
	'ONBOARDING.SUSPENDED': 'Suspendido',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Invitación pendiente disponible',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': 'En el paso siguiente podrá activar los permisos de grupo',
	'ONBOARDING.GRANT_ACCESS': 'Otorgar acceso',
	'ONBOARDING.SELECT_USER_GROUP': 'Seleccione el grupo de usuarios',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': 'No hay invitaciones de sistema disponibles',
	'ONBOARDING.ADD_USER_GROUP': 'Agregar grupo de usuarios',
	'ONBOARDING.ADD_ADDRESS': 'Agregar dirección',
	'ONBOARDING.CROP_IMAGE': 'Recortar imagen',
	'ONBOARDING.CROP': 'Recortar',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': 'Esta acción es irreversible y los sistemas olvidarán el historial del usuario.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': 'Borrar el perfil eliminará el acceso de usuario a todas las cuentas a las que tenga acceso actualmente.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Borrar perfil de {name} {lastName}',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Ingresar frase de confirmación',
	'ONBOARDING.DELETE_PROFILE': 'Borrar perfil',
	'ONBOARDING.DELETE_USER': 'Borrar usuario',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': 'Justificación para pausar (Opcional)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': '¿Está seguro de que quiere continuar?',
	'ONBOARDING.REACTIVATE_USER': 'Reactivar usuario',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': 'Si hace clic en Sí, recibirá un correo para restablecer la contraseña en unos momentos.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Restablecer contraseña de usuario',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': '¿Está seguro de que quiere continuar y reiniciar la contraseña?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Asignar grupo de usuarios',
	'ONBOARDING.ASSIGN': 'Asignar',
	'ONBOARDING.AND_OTHERS': 'Y otros {totalCount}...',
	'ONBOARDING.MY_PROFILE': 'Mi perfil',
	'ONBOARDING.EDIT_USER_GROUP': 'Editar grupo de usuarios',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Editar permisos individuales',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'Ver permisos individuales',
	'ONBOARDING.NO_USERS_AVAILABLE': 'No hay usuarios disponibles',
	'ONBOARDING.CONTACTS': 'Contactos',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': '¿Está seguro de que desea ejecutar esta operación?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Eliminar grupo de usuarios',
	'ONBOARDING.CONTACT_TYPE': 'Tipo de contacto',
	'ONBOARDING.CANCEL_INVITATION': 'Cancelar invitación',
	'ONBOARDING.RESEND_INVITATION': 'Reenviar invitación',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Reactivar grupo de usuarios',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Suspender grupo de usuarios',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': 'No hay grupos de usuarios para asignar',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'Existe ese tipo de dirección',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Agregar dirección nueva',
	'ONBOARDING.ADD_NEW_CONTACT': 'Agregar contacto nuevo',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Agregar otro contacto',
	'ONBOARDING.COMPANY_FEATURES': 'Características de la empresa',
	'ONBOARDING.INFO_NOT_PROVIDED': 'No se suministró información',
	'ONBOARDING.COMPANY_SETTINGS': 'Parámetros de la empresa',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': 'Si ya tiene cuenta, por favor inicie sesión.',
	'ONBOARDING.ACCOUNT_ACTIVATED': 'En unos momentos recibirá un correo con instrucciones sobre cómo crear su contraseña y entrar a su cuenta.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': 'AT&T Global Sign-On gestiona el restablecimiento de contraseñas',

	//  'Plataforma de facturación',
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Address type
	'ONBOARDING.BILLING_ADDRESS': 'Dirección de facturación',
	'ONBOARDING.HOME_ADDRESS': 'Domicilio',

	//  Contact type
	'ONBOARDING.MAIN_CONTACT': 'Contacto principal',
	'ONBOARDING.BACKUP_CONTACT': 'Contacto secundario',
	'ONBOARDING.TECHNICAL_OWNER': 'Propietario técnico',

	//  Address type
	'ONBOARDING.SHIPPING': 'Envío',
	'ONBOARDING.HOME': 'Inicio',
	'ONBOARDING.ANOTHER': 'Otro',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'Lanzar IoT Console',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': 'Este número es obligatorio para mostrar facturas en IoT Console.',
	'ONBOARDING.BILLING_REPORTS': 'informes de facturación',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': 'Debe marcar esta casilla para poder ver los detalles de la factura.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': 'Esta plataforma no soporta detalles de facturación.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': 'Ver o editar configuración de facturación',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'No se encuentra la ID de la cuenta.',
	'ONBOARDING.ARE_YOU_SURE': '¿Está seguro?',
	'ONBOARDING.REMOVE_CONTACT': 'Eliminar contacto',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'ID de Pod, Tenant o Instancia',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': 'Plataforma de conexión de equipo',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': '¿Está seguro de que desea ejecutar esta acción?',

	// 7.4 LABELS
	'ONBOARDING.ANONYMIZED': 'Anonimizado',
  'ONBOARDING.ACQUISITION_BOUNTY': 'Bono de adquisición',
	'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Bono de adquisición combinado',
  'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Subsidio de hardware combinado',
	'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': 'Paquetes financiados por socios combinados',
  'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Cargos de uso combinado combinados',
  'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Cargos de suscripción combinados',
	'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Costos compartidos de prueba combinados',
  'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Resumen de uso combinado',
  'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': 'Cargos de facturas mensuales combinados',
  'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': 'Detalles de facturas mensuales combinados',
	'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': 'Cargos mensuales combinados de uso no combinado',
  'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Otros cargos mensuales combinados',
  'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Paquetes financiados por socios',
  'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'Estado de dispositivos de cuenta por mes',
  'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': 'Informe de uso de cuenta por red satélite',
};
