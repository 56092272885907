import { Status } from '../../../../utils/constants';

/* ******************** EDIT COMPANY REPORTS ******************** */
export const editCompanyReportsRequest = (state) =>
	state.onboarding.companyReports.editCompanyReports.status === Status.PENDING;
export const editCompanyReportsFail = (state) =>
	state.onboarding.companyReports.editCompanyReports.status === Status.FAIL;
export const editCompanyReportsSuccess = (state) =>
	state.onboarding.companyReports.editCompanyReports.data;

/* ******************** GET COMPANY REPORTS ******************** */
export const getCompanyReportsRequest = (state) =>
	state.onboarding.companyReports.getCompanyReports.status === Status.PENDING;
export const getCompanyReportsFail = (state) =>
	state.onboarding.companyReports.getCompanyReports.status === Status.FAIL;
export const getCompanyReportsSuccess = (state) =>
	state.onboarding.companyReports.getCompanyReports.data;
