export const UPDATE_BULK_NETWORK_ENTITLEMENT_REQUEST =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_REQUEST';
export const UPDATE_BULK_NETWORK_ENTITLEMENT_SUCCESS =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_SUCCESS';
export const UPDATE_BULK_NETWORK_ENTITLEMENT_FAIL =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_FAIL';
export const UPDATE_BULK_NETWORK_ENTITLEMENT_STARTED =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_STARTED';
export const UPDATE_BULK_NETWORK_ENTITLEMENT_ENDED =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_ENDED';
export const UPDATE_BULK_NETWORK_ENTITLEMENT_ADD_FILTERED_SIM =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_ADD_FILTERED_SIM';
export const UPDATE_BULK_NETWORK_ENTITLEMENT_ERROR_CLEAR =
	'UPDATE_BULK_NETWORK_ENTITLEMENT_ERROR_CLEAR';
