/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import ImageSelector from 'utils/imageSelector';
import CurrentContext from 'utils/currentContext';
import { FormattedMessage } from 'react-intl';

import './ModalLastStep.scss';

const { string, objectOf, shape, oneOfType, array } = PropTypes;

const lastStepProps = {
	title: oneOfType([shape(), string]),
	type: string,
	msg: oneOfType([shape(), string]),
	errors: shape(),
	statusUploadErrors: oneOfType([shape(), array]),
	messages: objectOf(string)
};

const ModalLastStep = (props) => {
	const {
		type,
		title,
		msg,
		statusUploadErrors,
		messages,
		errors
	} = props;

	const typeError = type === 'error';
	const headerClass = typeError
		? 'header-title error'
		: 'header-title approval';
	const Approval = ImageSelector(
		CurrentContext.theme,
		'svgs/controls-approval.svg'
	);
	const Error = ImageSelector(CurrentContext.theme, 'svgs/controls-error.svg');

	return (
		<div className="ds-last-step" data-spec="ds-last-step">
			<header className="last-step-header">
				<div className="header-icon">
					{typeError && (
						<div className="svg-icn svg-icn-error">
							<Error />
						</div>
					)}
					{!typeError && (
						<div className="svg-icn svg-icn-approval">
							<Approval />
						</div>
					)}
				</div>
				{typeError && (
					<h3 className={headerClass} data-spec="action-status-error">
						{title}
					</h3>
				)}
				{!typeError && (
					<h3 className={headerClass} data-spec="action-status-success">
						{title}
					</h3>
				)}
			</header>
			<main className="last-step-content">
				{msg}

				{statusUploadErrors && (
					<ul className="last-step-error-list">
						{statusUploadErrors.map((error, index) => (
								<li key={index} data-spec="list error item status">
									<b>{error.message}</b>
								</li>
							))}
					</ul>
				)}
				{messages && errors && errors.length && (
					<ul className="last-step-error-list">
						{errors.map((error, index) => (
								<li key={index} data-spec="error list item">
									<FormattedMessage
										id="ERROR_CODE"
										defaultMessage="Error Code"
									/>
									:
									<b>{error.error}</b>
									,
									<FormattedMessage
										id="ERROR_TYPE"
										defaultMessage="Error Type"
									/>
									:
									<b>{props.messages[error.error]}</b>
									,
									<FormattedMessage id="AT_LINE" defaultMessage="At line" />
									<span>
										{error.lineNumber}
									</span>
								</li>
							))}
					</ul>
				)}
			</main>
		</div>
	);
};

ModalLastStep.propTypes = lastStepProps;

export default ModalLastStep;
