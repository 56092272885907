import { combineReducers } from 'redux';

import globalReducer from '../../../redux/global/globalReducer';
import getChartData from './getChartData/reducer';
import exportChartData from './exportChartData/reducer';
import {
	DELETE_ACCOUNT_OVERVIEW_DEFINED_SETTING,
	GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID,
	GET_ACCOUNT_OVERVIEW_DEFINED_SETTINGS,
	UPDATE_ACCOUNT_OVERVIEW_DEFINED_SETTING
} from './constants';

export default combineReducers({
	getChartData,
	exportChartData,
	deleteDefinedSetting: globalReducer(DELETE_ACCOUNT_OVERVIEW_DEFINED_SETTING),
	getDefinedSettings: globalReducer(GET_ACCOUNT_OVERVIEW_DEFINED_SETTINGS),
	getDefinedSettingById: globalReducer(
		GET_ACCOUNT_OVERVIEW_DEFINED_SETTING_BY_ID
	),
	updateDefinedSetting: globalReducer(UPDATE_ACCOUNT_OVERVIEW_DEFINED_SETTING)
});
