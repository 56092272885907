import React from 'react';
import { string, objectOf } from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import { SessionsDesktop, SessionsMobile } from '../../components/Sessions';

function SessionsView({ endpointId, messages }) {
	return (
		<>
			<DesktopMediaQuery>
				<SessionsDesktop endpointId={endpointId} messages={messages} />
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<SessionsMobile endpointId={endpointId} messages={messages} />
			</TabletMediaQuery>
			<MobileMediaQuery>
				<SessionsMobile endpointId={endpointId} messages={messages} />
			</MobileMediaQuery>
		</>
	);
}

SessionsView.propTypes = {
	endpointId: string,
	messages: objectOf(string),
};

SessionsView.defaultProps = {
	endpointId: '',
	messages: {},
};

export default SessionsView;
