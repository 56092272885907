import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Loading from '../../../../../lib/DigitalComponents/Loader';
import Button from '../../../../../lib/DigitalComponents/Button';
import DSLastStep from '../../../../Steps';
import {
	isSingleEndpointUpdating,
	singleEndpointUpdateSuccess,
	singleEndpointUpdateFail
} from '../../../../SingleEndpoint/redux/selectors';
import { getDateFormat } from '../../../../../utils/constants';

class StepSecond extends Component {
	componentDidMount() {
		const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
		const { context, isUpdating } = this.props;
		if (context && !isEqual(isUpdating, prevProps.isUpdating)) {
			this.setFooter(context);
		}
	}

	onClick = () => {
		const { onCancel, fetchSingleEndpoint, endpointName, type } = this.props;
		onCancel();
		if (fetchSingleEndpoint) {
			fetchSingleEndpoint(endpointName, type);
		}
	};

	setFooter = (context) => {
		const { isUpdating } = this.props;
		const footer = (
			<>
				<Button
					variant="success"
					onClick={this.onClick}
					disabled={isUpdating}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
					dataSpec="done-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderLastStepSuccess = () => {
		const { isSuccess, values } = this.props;
		if (isSuccess) {
			return (
				<DSLastStep
					data-spec="last success step"
					title={
						<FormattedMessage
							id="ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED"
							defaultMessage="SIM state change is successfully scheduled!"
						/>
					}
					msg={
						<FormattedMessage
							id="ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR"
							defaultMessage={`SIM state change is successfully scheduled for ${getDateFormat(
								values.scheduleDate
							)}`}
							values={{ scheduledDate: getDateFormat(values.scheduleDate) }}
						/>
					}
				/>
			);
		}
	};

	renderLastStepError = () => {
		const { isFail } = this.props;
		if (isFail) {
			return (
				<DSLastStep
					data-spec="last step error"
					type="error"
					title={
						<FormattedMessage
							id="ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR"
							defaultMessage="SIM state change could not be scheduled successfully!"
						/>
					}
					msg={
						<FormattedMessage
							id="ENDPOINTS.PLEASE_TRY_AGAIN"
							defaultMessage="Please try again."
						/>
					}
				/>
			);
		}
	};

	render() {
		const { isUpdating } = this.props;

		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body">
					{isUpdating && (
						<FormattedMessage
							id="ENDPOINTS.SCHEDULING"
							defaultMessage="Scheduling..."
						>
							{(formattedValue) => (
								<Loading data-spec="loading" msg={formattedValue} />
							)}
						</FormattedMessage>
					)}

					{!isUpdating && this.renderLastStepSuccess()}
					{!isUpdating && this.renderLastStepError()}
				</div>
			</div>
		);
	}
}

const { bool, func, object, string } = PropTypes;

StepSecond.propTypes = {
	isUpdating: bool,
	isSuccess: bool,
	isFail: bool,
	onCancel: func,
	values: object,
	fetchSingleEndpoint: func,
	type: string,
	endpointName: string,
	context: object
};

const mapStateToProps = (state) => ({
	isUpdating: isSingleEndpointUpdating(state),
	isSuccess: singleEndpointUpdateSuccess(state),
	isFail: singleEndpointUpdateFail(state)
});

export default connect(mapStateToProps)(StepSecond);
