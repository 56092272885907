export default {
	'USER_FEEDBACK.TRIGGER_TITLE': 'Sie haben Feedback?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Teilen Sie es uns hier mit',
	'USER_FEEDBACK.ADDITIONAL_FILE': 'Weitere Datei',
	'USER_FEEDBACK.PAGE_LABEL': 'Ihr Feedback bezieht sich auf',
	'USER_FEEDBACK.FEED_LABEL': 'Geben Sie bitte mit eigenen Worten an, wie wir Verbesserungen vornehmen können.',
	'USER_FEEDBACK.FEED_PLACEHOLDER': 'Ihr Feedback kommt hierhin...',
	'USER_FEEDBACK.CONTACT_LABEL': 'Dürfen wir Sie bei Bedarf dazu kontaktieren?',
	'USER_FEEDBACK.EMAIL_LABEL': 'E-Mail',
	'USER_FEEDBACK.PHONE_LABEL': 'Telefon',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Feedback senden',
	'USER_FEEDBACK.ICON': 'Symbol für erfolgreiches Einreichen von Feedback',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Vielen Dank für Ihr Feedback.',
	'USER_FEEDBACK.SUCCESS_MESSAGE': 'Ihr Feedback wird für ein besseres Produkt sorgen. Wir werden Ihre Anmerkungen sorgfältig evaluieren und entsprechend agieren.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': 'Evtl. kontaktieren wir Sie auch, um weitere Informationen zu Ihrem Vorschlag einzuholen.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'IoT Console Team',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Fertig',
	'USER_FEEDBACK.TITLE': 'Benutzerfeedback',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Datei-Upload fehlgeschlagen'
};
