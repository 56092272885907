import React from 'react';
import {
	string,
	object,
	bool,
	func
} from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import {
	DesktopModule,
	MobileModule,
} from '../../components/Module';

export default function ModuleView({
	endpoint,
	rateplan,
	modules,
	isPendingStatus,
	getSingleEndpoint,
	type,
	iccid,
	endpointData,
}) {
	return (
		<>
			<DesktopMediaQuery>
				<DesktopModule
					endpoint={endpoint}
					rateplan={rateplan}
					modules={modules}
					isPendingStatus={isPendingStatus}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={iccid}
					endpointData={endpointData}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<DesktopModule
					endpoint={endpoint}
					rateplan={rateplan}
					modules={modules}
					isPendingStatus={isPendingStatus}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={iccid}
					endpointData={endpointData}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<MobileModule
					endpoint={endpoint}
					rateplan={rateplan}
					modules={modules}
					isPendingStatus={isPendingStatus}
					getSingleEndpoint={getSingleEndpoint}
					type={type}
					endpointName={iccid}
					endpointData={endpointData}
				/>
			</MobileMediaQuery>
		</>
	);
}

ModuleView.propTypes = {
	endpoint: object,
	rateplan: object,
	modules: object,
	isPendingStatus: bool,
	getSingleEndpoint: func,
	type: string,
	iccid: string,
	endpointData: object,
};
ModuleView.defaultProps = {
	endpoint: {},
	rateplan: {},
	modules: [],
	isPendingStatus: false,
	getSingleEndpoint: undefined,
	type: '',
	iccid: '',
	endpointData: {},
};
