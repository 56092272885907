import { Status } from '../../../../utils/constants';

export const getDataDetailsRequest = (state) =>
	state.analyticsInvoices.tables.dataDetails.getDataDetails.status ===
	Status.PENDING;
export const getDataDetailsFail = (state) =>
	state.analyticsInvoices.tables.dataDetails.getDataDetails.status ===
	Status.FAIL;
export const getDataDetailsSuccess = (state) =>
	state.analyticsInvoices.tables.dataDetails.getDataDetails.data;

export const getDataDetailsFiltersRequest = (state) =>
	state.analyticsInvoices.tables.dataDetails.getDataDetailsFilters.status ===
	Status.PENDING;
export const getDataDetailsFiltersFail = (state) =>
	state.analyticsInvoices.tables.dataDetails.getDataDetailsFilters.status ===
	Status.FAIL;
export const getDataDetailsFiltersSuccess = (state) =>
	state.analyticsInvoices.tables.dataDetails.getDataDetailsFilters.data;
