import React from 'react';
import PropTypes from 'prop-types';
import hoc from '../Rules';
import Table from '../../../../../lib/DigitalComponents/Table';

const Desktop = (props) => {
	const { options, messages, rules } = props;
	return (
		<div data-spec="automation-rules-desktop">
			<Table
				data-spec="automation-rules-desktop-table"
				options={options('desktop')}
				messages={messages}
				data={rules}
			/>
		</div>
	);
};

const { func, object, array } = PropTypes;

Desktop.propTypes = {
	options: func,
  messages: object,
  rules: array
};

export default hoc()(Desktop);
