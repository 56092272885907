import React from 'react';
import {
	string,
	objectOf,
	func,
	arrayOf,
	object,
	bool,
	oneOfType,
	number,
} from 'prop-types';
import {
	FormattedDate,
	FormattedMessage,
	FormattedTime,
} from 'react-intl';
import Carousel from 're-carousel';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import Card from '../../../../Shared/components/Card';
import Table from '../../../../../lib/DigitalComponents/Table';
import {
	renderValue,
} from '../../../../Shared/components/Charts/Helpers/helpers';
import NoData from '../../../../Shared/components/NoData/NoData';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import hoc from '../Sessions';

import styles from './Mobile.scss';

const RefreshIcon = ImageSelector(CurrentContext.theme, 'svgs/reset.svg');
const ExportCSVIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg',
);

function Mobile({
	refresh,
	currentActiveSession,
	tableData,
	options,
	messages,
	exportSessionsData,
	isExportSessionsDataPending,
	renderIndicatorDots,
}) {
	return (
		<div data-spec="sessions-mobile" className={styles.wrapper}>
			{currentActiveSession ? (
				<Card customStyle={styles.custom_card}>
					<div className={styles.card_header}>
						<span>
							<FormattedMessage
								id="CURRENTLY_ACTIVE_SESSION"
								defaultMessage="Currently Active Session"
							/>
						</span>
						<div className={styles.refresh_icon}>
							<RefreshIcon onClick={refresh} />
						</div>
					</div>
					<div className={styles.card_body}>
						<Carousel widgets={[renderIndicatorDots]}>
							<div>
								<div className={styles.in_session}>
									{/*	 <span> */}
									{/*		<FormattedMessage */}
									{/*			id={'IN_SESSION'} */}
									{/*			defaultMessage={'IN SESSION'} */}
									{/*		/> */}
									{/*	</span> */}
									{/*	<div> */}
									{/*		<RefreshIcon /> */}
									{/*	</div> */}
									<div>
										<FormattedMessage
											id={'ACTIVE_SESSION_CARRIER'}
											defaultMessage={'CARRIER'}
										/>
									</div>
									<div>{currentActiveSession.carrier}</div>
								</div>
							</div>
							<div>
								<div>
									<span>
										<FormattedMessage
											id={'ACTIVE_SESSION_RECEIVING'}
											defaultMessage={'RECEIVING'}
										/>
									</span>
									<div className={styles.receiving_transmitting}>
										<span className={styles.receiving_arrow} />
										<span>
											{renderValue(
												'MB',
												currentActiveSession.downlinkDataVolume
											)}
										</span>
										<span>B</span>
									</div>
								</div>
								<span>
									<FormattedMessage
										id={'ACTIVE_SESSION_IP_ADDRESS'}
										defaultMessage={'IP ADDRESS'}
									/>
								</span>
								<div className={styles.bottom_items}>
									{currentActiveSession.ipAddress}
								</div>
							</div>
							<div>
								<div>
									<span>
										<FormattedMessage
											id={'ACTIVE_SESSION_TRANSMITTING'}
											defaultMessage={'TRANSMITTING'}
										/>
									</span>
									<div className={styles.receiving_transmitting}>
										<span className={styles.transmitting_arrow} />
										<span>
											{renderValue('MB', currentActiveSession.uplinkDataVolume)}
										</span>
										<span>B</span>
									</div>
								</div>
								<span>
									<FormattedMessage id={'APN'} defaultMessage={'APN'} />
								</span>
								<div className={styles.bottom_items}>
									{currentActiveSession.apn}
								</div>
							</div>
							<div>
								<div className={styles.total_volume_wrapper}>
									<span>
										<FormattedMessage
											id={'ACTIVE_SESSION_TOTAL_VOLUME'}
											defaultMessage={'TOTAL VOLUME'}
										/>
									</span>
									<div className={styles.total_volume}>
										<span>
											{renderValue(
												'MB',
												currentActiveSession.downlinkDataVolume +
													currentActiveSession.uplinkDataVolume
											)}
										</span>
										<span>B</span>
									</div>
								</div>
							</div>
							<div>
								<div className={styles.session_start_wrapper}>
									<span>
										<FormattedMessage
											id={'ACTIVE_SESSION_SESSION_START_TIME'}
											defaultMessage={'SESSION START TIME'}
										/>
									</span>
									<div className={styles.session_start}>
										<span>
											<FormattedTime
												value={currentActiveSession.start || new Date()}
												hour="numeric"
												minute="numeric"
												hour12={false}
												timeZone="UTC"
											/>
										</span>
										<span>
											<FormattedDate
												value={currentActiveSession.start || new Date()}
												month="2-digit"
												day="2-digit"
												year="numeric"
												hour="numeric"
												minute="numeric"
												timeZone="UTC"
												hour12={false}
											/>
											{'UTC'}
										</span>
									</div>
								</div>
							</div>
						</Carousel>
					</div>
				</Card>
			) : null}
			<div className={styles.table_wrapper}>
				<header className={styles.table_header}>
					<span>
						<FormattedMessage
							id="RECENT_SESSIONS"
							defaultMessage="Recent Sessions"
						/>
					</span>
					<div className={styles.table_header_buttons}>
						{!isExportSessionsDataPending ? (
							<ExportCSVIcon onClick={exportSessionsData} />
						) : (
							<Loader />
						)}
					</div>
				</header>
				{tableData.length > 0 ? (
					<Table
						data={tableData}
						options={options('mobile')}
						messages={messages}
						dataspec="sessions-table"
						mobile
					/>
				) : (
					<NoData styles={styles} table />
				)}
			</div>
		</div>
	);
}

Mobile.propTypes = {
	refresh: func,
	currentActiveSession: objectOf(oneOfType([string, bool, number])),
	messages: objectOf(string),
	tableData: arrayOf(object),
	options: func,
	exportSessionsData: func,
	isExportSessionsDataPending: bool,
	renderIndicatorDots: func,
};
Mobile.defaultProps = {
	refresh: undefined,
	messages: {},
	currentActiveSession: {},
	tableData: {},
	options: undefined,
	renderIndicatorDots: undefined,
};

export default hoc(Mobile);
