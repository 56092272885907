export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKET = 'GET_TICKET';
export const ADD_TICKET = 'ADD_TICKET';
export const EDIT_TICKET = 'EDIT_TICKET';

//  ADD TICKET
export const ADD_TICKET_INIT = 'ADD_TICKET_INIT';
export const ADD_TICKET_REQUEST = 'ADD_TICKET_REQUEST';
export const ADD_TICKET_FAIL = 'ADD_TICKET_FAIL';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';

//  EDIT TICKET
export const EDIT_TICKET_INIT = 'EDIT_TICKET_INIT';
export const EDIT_TICKET_REQUEST = 'EDIT_TICKET_REQUEST';
export const EDIT_TICKET_FAIL = 'EDIT_TICKET_FAIL';
export const EDIT_TICKET_SUCCESS = 'EDIT_TICKET_SUCCESS';
