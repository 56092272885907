import { createAction } from 'redux-actions';
// import { getLocale } from '../../../../utils/constants'; Link is left for future integration

import { downloadReportHelper } from '../../../../utils/constants';
import exportCsv from '../../services/UserGuideService';
import {
	EXPORT_CSV_REQUEST,
	EXPORT_CSV_FAIL,
	EXPORT_CSV_SUCCESS
} from './constants';

const exportRequest = createAction(EXPORT_CSV_REQUEST);
const exportSuccess = createAction(EXPORT_CSV_SUCCESS);
const exportFail = createAction(EXPORT_CSV_FAIL);

// eslint-disable-next-line import/prefer-default-export
export const exportCsvFile = (data) => async (dispatch) => {
	dispatch(exportRequest());
	exportCsv(data)
		.then((response) => {
			dispatch(exportSuccess(response));
			downloadReportHelper(response, data.fileName);
		})
		.catch((error) => {
			dispatch(exportFail(error));
		});
};
