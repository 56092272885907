import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import AccountsDesktop from './Desktop';
import AccountsMobile from './Mobile';

import { getAccounts } from '../../redux/accounts/actions';
import {
	getAccountsRequest,
	getAccountsFail,
	getAccountsSuccess
} from '../../redux/accounts/selectors';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';
import {
	getSimpleSearchParameter,
	getIsSimpleSearchActive
} from '../../../Shared/redux/simpleSearch/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import { showAssociatedApnsRequest, showAssociatedApnsSuccess } from '../../redux/networkEntitlements/selectors';
import { showAssociatedApns, showAssociatedApnsInit } from '../../redux/networkEntitlements/actions';
import { getCompanySuccess, getMyCompanySuccess } from '../../redux/companies/selectors';

const mapStateToProps = (state) => ({
	accountsRequest: getAccountsRequest(state),
	accountsFail: getAccountsFail(state),
	accounts: getAccountsSuccess(state),
	simpleSearchParameter: getSimpleSearchParameter(state),
	isSimpleSearchActive: getIsSimpleSearchActive(state),
	user: getUser(state),
	showAssociatedApnsRequest: showAssociatedApnsRequest(state),
	showAssociatedApnsSuccess: showAssociatedApnsSuccess(state),
	company: getCompanySuccess(state),
	myCompany: getMyCompanySuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAccounts: (params) => dispatch(getAccounts(params)),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	goToEditAccount: (url) => dispatch(localizedRoute(url)),
	goToAddAccount: (url) => dispatch(localizedRoute(url)),
	goToAddSubAccount: (url) => dispatch(localizedRoute(url)),
	showAssociatedApns: (params) => dispatch(showAssociatedApns(params)),
	showAssociatedApnsInit: () => showAssociatedApnsInit(),
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountsDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountsMobile);
