export default {
	'SUPPORT.USER_GUIDE': 'Benutzerhandbuch',
	'SUPPORT.PRINT_PDF': 'PDF drucken',
	'SUPPORT.SUPPORT': 'Support',
	'SUPPORT.FAQ': 'FAQ',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Finden Sie schnell Antworten auf häufig gestellte Fragen unserer Benutzer.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Lesen Sie detaillierte, schrittweise Anleitungen zu den Portalfunktionen. Die Anleitung ist speziell auf Ihren Kontotyp zugeschnitten.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Inhaltsverzeichnis',
	'SUPPORT.TICKETING': 'Ticketing',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'Falls es zu Systemausfällen oder Dienstproblemen mit Ihren Endpunkten kommt und Diagnosetests keine Lösung ergeben, können Sie bei unserem Betriebsteam ein Fehlerticket einreichen. Sie können auch Fragen zum Thema Abrechnung und Service stellen.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Neueste Benachrichtigungen',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Neue Benachrichtigung erstellen',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'Alle Benachrichtigungen anzeigen',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Häufig gestellte Fragen (FAQ)',
	'SUPPORT.TOPICS': 'Themen',
	'SUPPORT.EDIT_NOTIFICATION': 'Benachrichtigung bearbeiten',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Globale Benachrichtigung',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION':
		'Unternehmensbezogene Benachrichtigung',
	'SUPPORT.NOTIFICATION_TYPE': 'Benachrichtigungstyp?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER':
		'Benachrichtigungsplatzhalter auswählen',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Wählen Sie die zu benachrichtigenden Benutzergruppen aus:',
	'SUPPORT.CONTENT': 'Inhalt',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Benachrichtigungsinhalt hinzufügen',
	'SUPPORT.PUBLISH': 'Veröffentlichen',
	'SUPPORT.GLOBAL': 'Global',
	'SUPPORT.COMPANY_RELATED': 'Unternehmensbezogen',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Bestätigung',
	'SUPPORT.NOTIFICATION_INFO': 'Info',
	'SUPPORT.NOTIFICATION_WARNING': 'Warnung',
	'SUPPORT.NOTIFICATION_ERROR': 'Fehler',
	'SUPPORT.SELECT_SECTION': 'Abschnitt auswählen',
	'SUPPORT.ALL': 'Alle',
	'SUPPORT.ADMIN': 'Admin',
	'SUPPORT.USER': 'Benutzer',
	'SUPPORT.FINANCE': 'Finanzen',
	'SUPPORT.OPERATIONS': 'Betrieb',
	'SUPPORT.RESTRICTEDACCESS': 'Eingeschränkter Zugriff',
	'SUPPORT.COMPANYVIEW': 'Unternehmensansicht',
	'SUPPORT.COMPANYVIEWREADONLY': 'Unternehmensansicht schreibgeschützt',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Neue Versionshinweise hinzufügen',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'Das Dokument unten ist nicht vollständig in Ihre Sprache übersetzt. Die englische Version wird angezeigt. Künftige Produktversionen werden in Ihrer Sprache verfügbar sein.'
};
