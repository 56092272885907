import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';
import globalModalReducer from '../../../../redux/global/globalModalReducer';

import {
	GET_BLACKLISTED_PERMISSIONS,
	EDIT_BLACKLISTED_PERMISSION,
	REQUEST_FEATURE,
	APPROVE_REQUESTED_FEATURE,
	REJECT_REQUESTED_FEATURE,
	CANCEL_REQUESTED_FEATURE,
	REQUESTED_FEATURE
} from './constants';

export default combineReducers({
	getBlacklistedPermissions: globalReducer(GET_BLACKLISTED_PERMISSIONS),
	editBlacklistedPermission: globalReducer(EDIT_BLACKLISTED_PERMISSION),
	requestFeatureModal: globalModalReducer(`${REQUEST_FEATURE}_MODAL`, {
		[`SET_${REQUEST_FEATURE}`]: (state, action) => ({
			...state,
			data: action.payload
		}),
		[`UNSET_${REQUEST_FEATURE}`]: (state) => ({
			...state,
			data: null
		})
	}),
	requestFeature: globalReducer(REQUEST_FEATURE),
	cancelRequestedFeature: globalReducer(CANCEL_REQUESTED_FEATURE),
	cancelRequestedFeatureModal: globalModalReducer(
		`${CANCEL_REQUESTED_FEATURE}_MODAL`,
		{
			[`SET_${CANCEL_REQUESTED_FEATURE}`]: (state, action) => ({
				...state,
				data: action.payload
			}),
			[`UNSET_${CANCEL_REQUESTED_FEATURE}`]: (state) => ({
				...state,
				data: null
			})
		}
	),

	requestedFeatureModal: globalModalReducer(`${REQUESTED_FEATURE}_MODAL`, {
		[`SET_${REQUESTED_FEATURE}`]: (state, action) => ({
			...state,
			data: action.payload
		}),
		[`UNSET_${REQUESTED_FEATURE}`]: (state) => ({
			...state,
			data: null
		})
	}),
	approveRequestedFeature: globalReducer(APPROVE_REQUESTED_FEATURE),
	rejectRequestedFeature: globalReducer(REJECT_REQUESTED_FEATURE)
});
