import { connect } from 'react-redux';
import SimOrdering from './SimOrderingForm';

import {
	openDeleteSimSkuModal,
	closeDeleteSimSkuModal,
	openEditSimSkuModal,
	closeEditSimSkuModal,
	openAddSimSkuModal,
	closeAddSimSkuModal,
	getSkus
} from '../../redux/simOrdering/actions';

import {
	isDeleteSimSkuModalOpen,
	isEditSimSkuModalOpen,
	addSimSkuSuccess,
	editSimSkuSuccess,
	deleteSimSkuSuccess,
	isAddSimSkuModalOpened,
	getSkusPending,
	getSkusSuccess,
	addSubAccountSimSkuSuccess
} from '../../redux/simOrdering/selectors';

import {
	simpleSearchStart,
	simpleSearchClose
} from '../../../Shared/redux/simpleSearch/actions';

import { getUser } from '../../../../redux/user/getUserData/selectors';

import {
	getRestraints,
	addRestraints,
	updateRestraints,
	getRestraintPeriods
} from '../../../SimOrdering/redux/restraints/actions';
import {
	getRestraintsRequest,
	getRestraintsSuccess,
	addRestraintsRequest,
	addRestraintsSuccess,
	updateRestraintsRequest,
	updateRestraintsSuccess,
	getRestraintPeriodsRequest,
	getRestraintPeriodsSuccess
} from '../../../SimOrdering/redux/restraints/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

const mapStateToProps = (state) => ({
	getSkusRequest: getSkusPending(state),
	skus: getSkusSuccess(state),
	restraintsRequest: getRestraintsRequest(state),
	restraints: getRestraintsSuccess(state),
	addRestraintsRequest: addRestraintsRequest(state),
	addRestraintsSuccess: addRestraintsSuccess(state),
	updateRestraintsRequest: updateRestraintsRequest(state),
	updateRestraintsSuccess: updateRestraintsSuccess(state),
	restraintPeriodsRequest: getRestraintPeriodsRequest(state),
	restraintPeriods: getRestraintPeriodsSuccess(state),
	isDeleteSimSkuModalOpen: isDeleteSimSkuModalOpen(state),
	isEditSimSkuModalOpen: isEditSimSkuModalOpen(state),
	addSimSkuSuccess: addSimSkuSuccess(state),
	editSimSkuSuccess: editSimSkuSuccess(state),
	deleteSimSkuSuccess: deleteSimSkuSuccess(state),
	isAddSimSkuModalOpened: isAddSimSkuModalOpened(state),
	addSubAccountSimSkuSuccess: addSubAccountSimSkuSuccess(state),
	user: getUser(state),
	searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	getSkus: (params) => dispatch(getSkus(params)),
	getRestraints: (params) => dispatch(getRestraints(params)),
	addRestraints: (params) => dispatch(addRestraints(params)),
	updateRestraints: (params) => dispatch(updateRestraints(params)),
	getRestraintPeriods: (params) => dispatch(getRestraintPeriods(params)),
	openDeleteSimSkuModal: () => dispatch(openDeleteSimSkuModal()),
	closeDeleteSimSkuModal: () => dispatch(closeDeleteSimSkuModal()),
	openEditSimSkuModal: () => dispatch(openEditSimSkuModal()),
	closeEditSimSkuModal: () => dispatch(closeEditSimSkuModal()),
	simpleSearchStart: (params) => dispatch(simpleSearchStart(params)),
	simpleSearchClose: () => dispatch(simpleSearchClose()),
	openAddSimSkuModal: () => dispatch(openAddSimSkuModal()),
	closeAddSimSkuModal: () => dispatch(closeAddSimSkuModal())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SimOrdering);
