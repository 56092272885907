export default {
	// COMMON
	'ONBOARDING.EDIT': 'Modifica',
	'ONBOARDING.DELETE': 'Elimina',
	'ONBOARDING.NAME': 'Nome',
	'ONBOARDING.DESCRIPTION': 'Descrizione',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': 'Nome account',
	'ONBOARDING.FRIENDLY_NAME': 'Nome descrittivo',
	'ONBOARDING.ACCOUNT_ID': 'ID account',
	'ONBOARDING.CREATE_NEW_COMPANY': 'Crea nuova azienda',
	'ONBOARDING.IOTC_CUSTOMERS': 'Clienti IoT Console',
	'ONBOARDING.COMPANIES': 'Aziende',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': 'Nessuna azienda disponibile',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': 'Non ci sono attualmente aziende da mostrare per questa tabella',
	'ONBOARDING.COMPANY_NAME': 'Nome azienda',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': 'per es.: AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'Nome account MNC',
	'ONBOARDING.M2M_ACCOUNT_NAME': 'Nome account M2M',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'Numero account IoT-C',
	'ONBOARDING.SIM_ORDERS': 'Ordini SIM',
	// 'ONBOARDING.TECHNICAL_OWNER': 'Proprietario tecnico', - rimosso per account creato da
	'ONBOARDING.ACCOUNT_CREATED_BY': 'Account creato da',
	'ONBOARDING.VIEW_MC': 'Visualizza MC',
	'ONBOARDING.DATE_CREATED': 'Data creazione',
	'ONBOARDING.ACTIONS': 'Azioni',
	'ONBOARDING.PLATFORMS': 'Piattaforme',
	'ONBOARDING.ACTIVATION_DENIED': 'Impossibile elaborare la richiesta.',
	'ONBOARDING.ACTIVATION_SUCCESS': 'Account attivato',
	'ONBOARDING.CONTACT_SYSADMIN': 'Rivolgersi al proprio amministratore di IoT Console per ulteriore assistenza',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': 'Le impostazioni della tabella Aziende sono state aggiornate.',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': 'Impossibile aggiornare le colonne della tabella Aziende in questo momento. Riprovare.',
	'ONBOARDING.RETRY': 'Riprova',

	// SEARCH MENU
	'ONBOARDING.BY': 'Entro il ',
	'ONBOARDING.BUSINESS_NAME': 'Nome azienda',
	'ONBOARDING.CONTACT_PERSON': 'Persona di contatto',
	'ONBOARDING.PLATFORM_ID': 'ID Piattaforma',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'ID IoT Console: ',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': 'Account aziendali M2M',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': 'Creazione riuscita di un account IoT Console per:',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': 'Numero gateway IoT associato a questo account:',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': 'Crea nuovo account aziendale',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': 'Non può contenere spazi o numeri',
	'ONBOARDING.IDM_SERVCE_NAME': 'Nome servizio IDM',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': 'Visibile al cliente',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': 'Persona giuridica dell’azienda o della denominazione sociale',
	'ONBOARDING.COMPANY_CONTACTS': 'Contatti azienda',
	'ONBOARDING.ATT_CONTACTS': 'Contatti AT&T',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'Tipo assistenza vTM',
	'ONBOARDING.FULL_TSM': 'TSM completa (tutti i ticket a prescindere dalla gravità vanno al team TSM)',
	'ONBOARDING.PARTIAL_TSM': 'TSM parziale (tutti i ticket di gravità 1 vanno al team TSM, quelli di gravità 2 e 3 al team di assistenza offline)',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care (tutti i ticket a prescindere dalla gravità vanno al team IoT Priority Care)',
	'ONBOARDING.NO_SUPPORT': 'Nessun supporto (tutti i ticket a prescindere dalla gravità vanno al team di assistenza offline)',
	'ONBOARDING.CONTACT': 'Contatti',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': 'Nome descrittivo azienda',
	'ONBOARDING.BUSINES_LEGAL_NAME': 'Nome legale azienda',
	'ONBOARDING.COMPANY_CONTACT': 'Contatto azienda',
	'ONBOARDING.ATT_CONTACT': 'Contatto AT&T',
	'ONBOARDING.COMPANY': 'Azienda',
	'ONBOARDING.COUNTRY': 'Paese',
	'ONBOARDING.STATE_PROVINCE': 'Stato/provincia',
	'ONBOARDING.STREET_ADDRESS': 'Indirizzo',
	'ONBOARDING.ADDRESS_LINE_1': 'Riga indirizzo 1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': 'Riga indirizzo 2 (facoltativa)',
	'ONBOARDING.CITY_TOWN': 'Città/comune',
	'ONBOARDING.ZIP_POSTAL_CODE': 'Codice postale',
	'ONBOARDING.COMPANY_DOMAIN': 'Dominio azienda',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': 'Domino e-mail aziendale',
	'ONBOARDING.FULL_NAME': 'Nome completo',
	'ONBOARDING.PHONE_NUMBER': 'Numero telefonico',
	'ONBOARDING.EMAIL_ADDRESS': 'Indirizzo e-mail',
	'ONBOARDING.SIM_OPTIONS': 'Opzioni SIM',
	'ONBOARDING.ORDER_MOVE_SIMS': 'Ordina o trasferisci SIM',
	'ONBOARDING.ORDERING': 'Ordinazione',
	'ONBOARDING.TRANSFER': 'Trasferimento',
	'ONBOARDING.SIM_TRANSFER': 'Trasferimento SIM',
	'ONBOARDING.TRANSFERING_SIM': 'Trasferimento di una SIM tra due account M2M',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': 'Ordinare SIM, selezionare o caricare elenco SIM',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': 'Visualizza o modifica le informazioni di fatturazione',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': 'Piattaforma',
	'ONBOARDING.PLATFORM_DESCRIPTION': 'Descrizione piattaforma',
	'ONBOARDING.APNS': 'APN',
	'ONBOARDING.M2M_ACCOUNT_ID': 'ID account M2M',
	'ONBOARDING.EDIT_ACTIONS': 'Modifica account',
	'ONBOARDING.EDIT_M2M_PLATFORM': 'Modifica piattaforma M2M',
	'ONBOARDING.ADD_M2M_PLATFORM': 'Aggiungi piattaforma M2M',
	'ONBOARDING.MC_ACCOUNT_ID': 'ID account IoT-C',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': 'Enterprise on Demand',
	'ONBOARDING.PLATFORM_ACC': 'ATT Control Center',
	'ONBOARDING.PLATFORM_CCIP': 'Control Center Integration Program',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': 'Device Connection Platform',
	'ONBOARDING.PLATFORM_GMNA': 'GM America Settentrionale',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': 'Enterprise',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': 'Piattaforma M2M',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': 'Diritto di rete',
	'ONBOARDING.NETWORK_ENTITLEMENTS': 'Diritti di rete',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': 'Nome profilo comunicazione',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': 'Nessun diritto di rete disponibile',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': 'Non ci sono attualmente diritti di rete da mostrare per questa tabella',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': 'Aggiungi nuovi diritti di rete',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': 'Aggiungi diritti di rete',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': 'Modifica diritti di rete',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': 'Modifica diritti di rete',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': 'Scegli diritti di rete',
	'ONBOARDING.SELECT_ENTITLEMENT': 'Seleziona diritto',
	'ONBOARDING.PULL_DATA': 'Estrai dati',
	'ONBOARDING.HLR_TEMPLATE': 'Modello dettagli HLRHSS',
	'ONBOARDING.HOTLINE_NUMBER': 'Numero linea diretta',
	'ONBOARDING.VOICE_MT': 'Vocale MT',
	'ONBOARDING.ROAMING_LTE': 'Roaming LTE',
	'ONBOARDING.DATA': 'Dati',
	'ONBOARDING.DATA_LTE': 'LTE dati',
	'ONBOARDING.VOICE_MO': 'Vocale MO',
	'ONBOARDING.INTERNATIONAL_VOICE': 'Vocale internazionale',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': 'Blocca SMS MO internazionali salvo paese di residenza',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': 'Blocca numeri premium',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Tipo servizio Camel',
	'ONBOARDING.APNS_PDPINDEX': 'Indice PDP',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': 'IP fisso',
	'ONBOARDING.APNS_SECURITY': 'Sicurezza',
	'ONBOARDING.APNS_SUBSCRIPTION': 'Abbonamento',
	'ONBOARDING.ADD_NEW_APN': 'Aggiungi nuovo APN',
	'ONBOARDING.PLATFORM_DETAILS': 'Dettagli piattaforma',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': 'Roaming',
	'ONBOARDING.SMS_MO': 'SMS MO',
	'ONBOARDING.SMS_MT': 'SMS MT',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': 'Diritto di rete idoneo',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': 'Tipo di pagamento',
	'ONBOARDING.USAGE_LIMIT': 'Limite utilizzo',
	'ONBOARDING.RATE_PLAN': 'Piano tariffario',
	'ONBOARDING.RATE_PLANS': 'Piani tariffari',
	'ONBOARDING.RATE_PLAN_NAME': 'Nome piano tariffario',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': 'Nessun piano tariffario disponibile',
	'ONBOARDING.ADD_NEW_RATE_PLAN': 'Aggiungi nuovo piano tariffario',
	'ONBOARDING.EDIT_RATE_PLAN': 'Modifica piano tariffario',
	'ONBOARDING.PLAN_ID': 'ID piano',
	'ONBOARDING.RATE_PLAN_TYPE': 'Tipo piano tariffario',
	'ONBOARDING.ACCOUNT_CHARGE': 'Addebito account',
	'ONBOARDING.TERM': 'Termine',
	'ONBOARDING.TIERING_FLAG': 'Contrassegno livelli',
	'ONBOARDING.PER_SUBSCRIBER_MRC': 'MRC per abbonato',
	'ONBOARDING.CURRENCY': 'Valuta',
	'ONBOARDING.INCLUDED_DATA_USAGE': 'Utilizzo dati incluso',
	'ONBOARDING.USAGE_LIMIT_MB': 'Limite utilizzo (MB)',
	'ONBOARDING.PLAN_TYPE': 'Tipo di piano',
	'ONBOARDING.TYPE': 'Tipo',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': 'NOME ACCOUNT FATTURA',
	'ONBOARDING.M2M_RATE_PLAN_ID': 'ID PIANO TARIFFARIO M2M',
	'ONBOARDING.PRICE_PLAN_ID': 'ID PIANO PREZZI',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': 'TIPO ARTICOLO ABBONAMENTO',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': 'ID ARTICOLO ABBONAMENTO M2M',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': 'ID ACCOUNT INTERNO M2M',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': 'NUMERO ACCOUNT M2M',
	'ONBOARDING.DEFAULT_PLAN': 'PIANO PREDEFINITO',
	'ONBOARDING.SERVICE_PROVIDER_ID': 'ID provider di servizi',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': 'ID diritti di rete',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': 'Non ci sono attualmente piani tariffari da mostrare per questa tabella',
	'ONBOARDING.USD': 'USD',
	'ONBOARDING.CAD': 'CAD',

	// SIM SKU
	'ONBOARDING.SIM_SKU': 'SKU SIM',
	'ONBOARDING.DELETE_SIM_SKU': 'Elimina SKU SIM',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': 'Cancellando questo SKU, vengono cancellati tutti gli ordini di SIM associati a questo SKU. Confermare l’operazione?',
	'ONBOARDING.SIM_ORDERING': 'Ordinazione SIM',
	'ONBOARDING.CURRENT_SIM_SKUS': 'SKU SIM correnti',
	'ONBOARDING.ADD_SIM_SKU': 'Aggiungi SKU SIM',
	'ONBOARDING.EDIT_SIM_SKU': 'Modifica SKU SIM',
	'ONBOARDING.ENTER_SIM_SKU_HERE': 'Inserire qui lo SKU della SIM',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': 'Inserire la descrizione dello SKU',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': 'Limitazioni contrattuali',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': 'Impostare limitazioni per il processo di ordinazione SIM.',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': 'Quantità minima permessa',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': 'Quantità minima',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': 'Quantità massima permessa',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': 'Quantità massima',
	'ONBOARDING.INCREMENT': 'Incremento',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': 'Per periodo',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': 'Selezionare un periodo',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': 'quantità minima',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': 'quantità massima',
	'ONBOARDING.UNIT_PRICE': 'Prezzo unitario',
	'ONBOARDING.ALLOW_TO_ORDER': 'Ordinazione permessa',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': 'Modifica SKU riuscita',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': 'Impossibile modificare SKU',
	'ONBOARDING.RATE_PLAN_ID': 'ID piano tariffario',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': 'Nessun codice SKU disponibile relativo a SIM',

	// Platform
	'ONBOARDING.OPERATOR': 'Operatore',
	'ONBOARDING.LAUNCH_MC': 'Lancia MC',
	'ONBOARDING.OPERATOR_OPTIONAL': 'Operatore (opzionale)',
	'ONBOARDING.MCC': 'MCC',
	'ONBOARDING.MNC': 'MNC',
	'ONBOARDING.POD': 'POD',
	'ONBOARDING.MCC_TOOLTIP': 'Il Mobile Country Code (MCC) è un numero univoco di 3 cifre che identifica un paese.',
	'ONBOARDING.MNC_TOOLTIP': 'Il Mobile Network Code (MNC) è un numero univoco di 2 o 3 cifre che identifica una rete mobile.',
	'ONBOARDING.PLATFORM_SETUP': 'Impostazione piattaforma',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': 'L’impostazione piattaforma sarà disponibile dopo l’aggiunta di una piattaforma. ',
	'ONBOARDING.SETTINGS': 'Impostazioni',
	'ONBOARDING.EDIT_PLATFORM': 'Modifica piattaforma',
	'ONBOARDING.ADD_PLATFORM': 'Aggiungi piattaforma',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': 'Nessuna piattaforma disponibile.',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': 'Mancano informazioni obbligatorie',

	// Edit platform
	'ONBOARDING.SIM_LOADING': 'Caricamento SIM in corso',
	'ONBOARDING.GATEWAY': 'Gateway',
	'ONBOARDING.ENTER_GATEWAY': 'Inserire il gateway',
	'ONBOARDING.BILLING_INFORMATION': 'Informazioni di fatturazione',
	'ONBOARDING.BILLING_PLATFORM': 'Piattaforma fatturazione',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': 'Numero account fattura',

	// Reports
	'ONBOARDING.REPORT': 'Report',
	'ONBOARDING.REPORTS': 'Report',
	'ONBOARDING.COMPANY_REPORTS': 'Report',

	'ONBOARDING.IDM_INTEGRATION_ERROR': 'Errore integrazione IDM',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': 'Si è verificato un problema con l’integrazione IDM. ',
	'ONBOARDING.ONBOARDING_STATUS': 'Stato onboarding',
	'ONBOARDING.SUCCESS': 'Riuscito',
	'ONBOARDING.ERROR': 'Errore',

	'ONBOARDING.MUST_BE_BETWEEN': 'Deve avere una lunghezza tra ',
	'ONBOARDING.AND': ' e ',
	'ONBOARDING.CHARACTERS_LONG': ' caratteri',
	'ONBOARDING.DEFAULT': 'Default',

	'ONBOARDING.ADD_RATE_PLANS_FOR': 'Aggiungere piani tariffari per {companyName}',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': 'Aggiornamento impostazioni report aziendali riuscito',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': 'Errore in fase di aggiornamento report aziendali',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': 'Definire i report da attivare per {companyName}',
	'ONBOARDING.REPORTS_SUBTITLE': 'I report selezionati verranno visualizzati nel portale IoT Console per il download da parte degli utenti',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': 'Aggiungere diritti di rete per {companyName}',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': 'Collegare i diritti di rete attivati per questo account a AT&T Control Center.',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': 'Indirizzo principale azienda',
	'ONBOARDING.COMPANY_DETAILS': 'Dettagli azienda',
	'ONBOARDING.ACCOUNTS': 'Account',
	'ONBOARDING.EDIT_ACCOUNT': 'Modifica account',
	'ONBOARDING.VIEW_ACCOUNT': 'Visualizza account',
	'ONBOARDING.SIM_LOADING_SUB': 'Dove vengono caricate le SIM per questo account, in genere il gateway IoT lo fa automaticamente quando viene creato l’account della piattaforma M2M.',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': 'Importazione da gateway IoT',
	'ONBOARDING.ACCOUNT_CONFIGURATION': 'Configurazione account',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': 'Selezionare i servizi pertinenti da attivare in questo account. Se non si vede il servizio desiderato, lo si può aggiungere a livello di account principale, ma tale azione è soggetta al processo di approvazione. ',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': 'Aggiungi diritti di rete',
	'ONBOARDING.ADD_RATE_PLANS': 'Aggiungi piani tariffari',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': 'Visualizza o modifica i report da visualizzare',
	'ONBOARDING.USERS': 'Utenti',
	'ONBOARDING.USERS_SUB': 'Cliccando il pulsante qui sotto, verrà visualizzata la schermata dell’identità dell’azienda in cui è possibile invitare nuovi utenti a iscriversi a questo account o aggiungere utenti esistenti a questa azienda.',
	'ONBOARDING.CREATE_USERS': 'Crea utenti',
	'ONBOARDING.USER_ACCESS': 'Accesso utente',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': 'Crea/aggiungi utente',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': 'Verifica nuove informazioni azienda',
	'ONBOARDING.SUBMIT': 'Invia',
	'ONBOARDING.CREATE_COMPANY': 'Crea azienda',
	'ONBOARDING.NEW_ACCOUNT': 'Nuovo account',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': 'Un account o sottoconto deve esistere nella piattaforma M2M prima di poter essere aggiunto a MNC. Effettuare le seguenti operazioni per creare un account M2M per il provisioning nella IoT Console. ',
	'ONBOARDING.VALIDATE': 'Convalida',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': 'Convalida nome account',
	'ONBOARDING.CREATE_ACCOUNT': 'Crea account',
	'ONBOARDING.ADD_ACCOUNT': 'Aggiungi account',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': 'Nessun account disponibile',
	'ONBOARDING.M2M_PLARFORM': 'Piattaforma M2M',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': 'Creazione SKU riuscita',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': 'Impossibile creare SKU',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': 'Modifica diritti di rete per {companyName}',

	'ONBOARDING.EDIT_SUB_ACCOUNT': 'Modifica sottoconto',
	'ONBOARDING.VIEW_SUB_ACCOUNT': 'Visualizza sottoconto',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': 'Modifica piani tariffari per {companyName}',
	'ONBOARDING.VIEW': 'Vista',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': 'Visualizza diritti di rete',
	'ONBOARDING.VIEW_RATE_PLANS': 'Visualizza piani tariffari',
	'ONBOARDING.CREATE_SUB_ACCOUNT': 'Crea sottoconto',
	'ONBOARDING.NEW_SUB_ACCOUNT': 'Nuovo sottoconto per {companyName}',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': 'Genera account M2M',
	'ONBOARDING.SUB_ACCOUNT_NAME': 'Nome sottoconto',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': 'Seleziona piani tariffari per questo account',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': 'Seleziona diritti di rete per questo account',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': 'Avvertenza: I paesi diversi dagli Stati Uniti al momento non sono supportati',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': 'Assegna a sottoconto',
	'ONBOARDING.ENDPOINT_ID': 'ID endpoint',
	'ONBOARDING.NETWORK_OPERATOR': 'Operatore di rete',
	'ONBOARDING.STATUS': ' Stato',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': ' endpoint saranno trasferiti a un account',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': 'Conferma trasferimento endpoint',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': 'Una volta avviato questo trasferimento, gli endpoint selezionati saranno trasferiti entro pochi minuti. Continuare?',
	'ONBOARDING.ENDPOINTS': 'Endpoint',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': 'Sposta a sottoconto',
	'ONBOARDING.FROM': 'Da',
	'ONBOARDING.TO': 'A',
	'ONBORDING.SIM_SKU': 'SKU SIM',
	'ONBORDING.UNIT_PRICE': 'Prezzo unitario',
	'ONBORDING.DATE_SUBMITED': 'Data invio',
	'ONBORDING.EDIT_DELETE_SKU': 'Modifica / elimina SKU',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': 'Nessun endpoint disponibile.',
	'ONBOARDING.NO_SIMS_AVAILABLE': 'Nessuna SIM disponibile',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': 'Seleziona SIM per questo account',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': 'Non ci sono attualmente account da mostrare per questa tabella',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': 'Al mese',
	'ONBOARDING.PREPAID': 'Prepagato',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': 'Mensile - singolo abbonato',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': 'Mensile - pool fisso - singolo',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': 'Essenziale - pool fisso mensile',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': 'Mensile - pool flessibile',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': 'Essenziale - pool flessibile mensile',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': 'Mensile - livello utilizzo pool flessibile',
	'ONBOARDING.MONTHLY_BOLT_ON': 'Mensile - supplementare',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': 'Prepagato - singolo abbonato',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': 'Prepagato - pool flessibile',
	'ONBOARDING.PREPAID_FIXED_POOL': 'Prepagato - pool fisso',
	'ONBOARDING.PREPAID_ADD_ON': 'Prepagato - supplementare',
	'ONBOARDING.PREPAID_EVENT': 'Prepagato - evento',
	'ONBOARDING.PREPAID_STACKED_EVENT': 'Prepagato - evento in pila',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': 'Dettagli utilizzo dati',
	'ONBOARDING.SMS_USAGE_DETAILS': 'Dettagli utilizzo SMS',
	'ONBOARDING.IMSI_SNAPSHOT': 'Istantanea IMSI',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': 'Elenco abbonati',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': 'Istantanea abbonato',
	'ONBOARDING.SUBSCRIBER_CHANGES': 'Cambiamenti abbonato',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': 'Istantanea giornaliera account',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': 'Istantanea piano tariffario',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': 'Istantanea piano di comunicazione',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': 'Riferimento ciclo di fatturazione',
	'ONBOARDING.CARRIER_REFERENCE': 'Riferimento vettore',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': 'Riferimento tipo piano tariffario',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'Riferimento cambiamento SIM',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': 'Riferimento causa chiusura record utilizzo',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': 'Istantanea zona piano tariffario',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': 'Campi personalizzati provider servizio',
	'ONBOARDING.USER_SNAPSHOT': 'Istantanea utente',
	'ONBOARDING.NEW_SUBSCRIBERS': 'Nuovi abbonati',
	'ONBOARDING.COMBINED_SUBSCRIBERS': 'Abbonati combinati',
	'ONBOARDING.COMBINED_USAGE': 'Utilizzo combinato',
	'ONBOARDING.APN_SNAPSHOT': 'Istantanea APN',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': 'Campi personalizzati provider servizio',
	'ONBOARDING.COMBINED_ACCOUNTS': 'Account combinati',
	'ONBOARDING.DAILY_USAGE_RAW': 'Utilizzo giornaliero non elaborato',
	'ONBOARDING.ALL_ACTIVE_SIMS': 'Tutte le SIM attive',
	'ONBOARDING.DAILY_USAGE': 'Utilizzo giornaliero',
	'ONBOARDING.ALL_SMS_MESSAGES': 'Tutti i messaggi SMS',
	'ONBOARDING.SMS_DETAIL': 'Dettaglio SMS',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': 'Riepilogo cumulativo ICCID',
	'ONBOARDING.SIM_STATE_REFERENCE': 'Riferimento stato SIM',
	'ONBOARDING.VOICE_USAGE_DETAIL': 'Dettaglio utilizzo vocale',
	'ONBOARDING.ALL_ASSIGNED_SIMS': 'Tutte le SIM assegnante',
	'ONBOARDING.INVOICE_DETAIL': 'Dettaglio fattura',
	'ONBOARDING.RATE_PLAN_SUMMARY': 'Riepilogo piano tariffario',
	'ONBOARDING.OTHER_CHARGES': 'Altri addebiti',
	'ONBOARDING.USAGE_SUMMARY': 'Riepilogo utilizzo',
	'ONBOARDING.ACTIVATION_CHARGES': 'Addebiti attivazione',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': 'Dettagli utilizzo zona piano tariffario',
	'ONBOARDING.INVOICE_CHARGES': 'Addebiti fattura',
	'ONBOARDING.POOLED_USAGE_CHARGES': 'Addebiti utilizzo in pool',
	'ONBOARDING.SUBSCRIBER_CHARGES': 'Addebiti abbonato',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': 'Addebiti utilizzo non in pool',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': 'Utilizzo per abbonati all’ingrosso',
	'ONBOARDING.FUNDED_PACKAGES': 'Pacchetti finanziati',
	'ONBOARDING.TRIAL_COST_SHARE': 'Percentuale costo per le prove',
	'ONBOARDING.REVENUE_SHARE': 'Quota introiti',
	'ONBOARDING.BOUNTY': 'Premio',
	'ONBOARDING.DATA_USAGE_DETAILS': 'Dettagli utilizzo dati',
	'ONBOARDING.SMS_DETAILS': 'Dettagli SMS',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': 'Delta abbonamenti',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': 'Messaggi SMS non consegnati',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': 'Ricavi zero per abbonati all’ingrosso',
	'ONBOARDING.INVOICE_DETAILS': 'Dettagli fattura',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': 'Dettagli utilizzo piano tariffario zona',
	'ONBOARDING.BILLING_INVOICE': 'Fattura',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1 mese',
	'ONBOARDING.3_MONTHS': '3 mesi',
	'ONBOARDING.1_WEEK': '1 settimana',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': 'Trasferimento account riuscito',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': 'Trasferimento account non riuscito',
	'ONBOARDING.MY_COMPANY': 'La mia azienda',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': 'Gruppi utenti',
	'ONBOARDING.USER_COUNT': 'Conteggio utenti',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': 'Utenti attualmente attivi',
	'ONBOARDING.FIRST_NAME': 'Nome',
	'ONBOARDING.LAST_NAME': 'Cognome',
	'ONBOARDING.JOB_TITLE': 'Titolo professionale',
	'ONBOARDING.EMAIL': 'E-mail',
	'ONBOARDING.USER_GROUP': 'Gruppo utente',
	'ONBOARDING.ACCOUNT_ACCESS': 'Accesso all’account',
	'ONBOARDING.CREATE_NEW_GROUP': 'Crea nuovo gruppo',
	'ONBOARDING.INVITE_USERS': 'Invita utenti',
	'ONBOARDING.ADD_USERS': 'Aggiungi utenti',
	'ONBOARDING.EDIT_USER_PROFILE': 'Modifica profilo utente',
	'ONBOARDING.USER_INFORMATION': 'Informazioni utente',
	'ONBOARDING.ADDRESSES': 'Indirizzi',
	'ONBOARDING.ASSIGNED_ACCOUNTS': 'Account assegnati',
	'ONBOARDING.LEGAL_AND_REGULATORY': 'Informazioni legali e normative',
	'ONBOARDING.CREATE_NEW_USER_GROUP': 'Crea nuovo gruppo utenti',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': 'Crea gruppi di diritti di accesso personalizzati per gli utenti.',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name} {lastName} accettato',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': 'Accordo di licenza per l’utente finale',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': 'Se non accetta i termini dell’accordo di licenza per l’utente finale (EULA, End-User Licence Agreement), può cancellare questo account.',
	'ONBOARDING.ON_DATE': 'il {date}.',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': 'Se non accetta i termini dell’accordo di licenza per l’utente finale (EULA, End-User Licence Agreement), può cancellare questo account.',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': 'Inserisca gli indirizzi e-mail degli utenti che desidera invitare. Gli utenti potranno accedere all’account aziendale non appena accetteranno il link di invito fornito nella notifica via e-mail.',
	'ONBOARDING.ADD_NEW_USER': 'Aggiungi nuovo utente',
	'ONBOARDING.CAN_RECEIVE_SMS': 'Può ricevere SMS',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': 'Stato/Provincia/Territorio/Regione',
	'ONBOARDING.COMPANY_EMAIL': 'E-mail azienda',
	'ONBOARDING.POSTAL_CODE': 'Codice postale',
	'ONBOARDING.BUSINESS': 'Commerciale',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': 'La privacy degli utenti è importante per noi; non vendiamo a nessuno i dati degli utenti.',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': 'L’EULA contiene informazioni dettagliate riguardo al modo in cui proteggiamo la Sua privacy, il Suo diritto alla cancellazione dei dati e altre norme di conformità.',
	'ONBOARDING.STREET_ADDRESS_2': 'Indirizzo 2',
	'ONBOARDING.STREET_ADDRESS_1': 'Indirizzo 1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': 'Città o comune',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': 'Stesso indirizzo dell’indirizzo principale',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': 'Aggiungi un altro indirizzo',
	'ONBOARDING.YES': 'Sì',
	'ONBOARDING.NO': 'No',
	'ONBOARDING.PRIMARY_ADDRESS': 'Indirizzo principale',
	'ONBOARDING.ADDRESS_TYPE': 'Tipo di indirizzo',
	'ONBOARDING.SECONDARY_NUMBER': 'Numero secondario',
	'ONBOARDING.PREFERRED_LANGUAGE': 'Lingua preferita',
	'ONBOARDING.PRIMARY_NUMBER': 'Numero principale',
	'ONBOARDING.UPLOAD_IMAGE': 'Carica immagine',
	'ONBOARDING.FILTER_USER_LIST': 'Filtra l’elenco degli utenti',
	'ONBOARDING.CANCEL': 'Annulla',
	'ONBOARDING.ADD': 'Aggiungi',
	'ONBOARDING.USER': 'Utente',
	'ONBOARDING.GROUP': 'Gruppo',
	'ONBOARDING.PASSWORD_RESET': 'Reimpostazione password',
	'ONBOARDING.SELECT_ACTION': 'Seleziona azione',
	'ONBOARDING.ACTIVE_STATUS': 'Stato attivo',
	'ONBOARDING.PENDING_STATUS': 'Stato in sospeso',
	'ONBOARDING.ADD_MORE_USERS': 'Aggiungi più utenti',
	'ONBOARDING.ENTITLEMENT_NAME': 'Nome diritto',
	'ONBOARDING.NO_ACCESS': 'Nessun accesso',
	'ONBOARDING.READ_ONLY': 'Solo lettura',
	'ONBOARDING.READ_WRITE': 'Lettura e scrittura',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': 'Permessi gruppo amministrazione',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': 'Permessi gruppo modifica',
	'ONBORDING.USER_GROUPS': 'Gruppi utenti',
	'ONBOARDING.CREATE_GROUP': 'Crea gruppo',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': 'Seleziona gruppo utenti esistente',
	'ONBOARDING.GROUP_NAME': 'Nome gruppo',
	'ONBOARDING.PAUSE': 'Pausa',
	'ONBOARDING.REACTIVATE': 'Riattiva',
	'ONBOARDING.ACTIVATE': 'Attiva',
	'ONBOARDING.REMOVE': 'Rimuovi',
	'ONBOARDING.RESEND_INVITE': 'Invia di nuovo l’invito',
	'ONBOARDING.CANCEL_INVITE': 'Annulla l’invito',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': 'Conferma la rimozione dell’utente?',
	'ONBOARDING.USER_PROFILE': 'Profilo utente',
	'ONBOARDING.SUSPEND': 'Sospendi',
	'ONBOARDING.USER_MANAGEMENT': 'Gestione utenti',
	'ONBOARDING.MAILING': 'Invio per posta',
	'ONBOARDING.BILLING': 'Fatturazione',
	'ONBOARDING.SECONDARY': 'Secondario',
	'ONBOARDING.PRIMARY': 'Primario',
	'ONBOARDING.PHONE': 'Telefono',
	'ONBOARDING.ENGLISH': 'Inglese USA',
	'ONBOARDING.SAVE_USER': 'Salva utente',
	'ONBOARDING.BULK_UPLOAD': 'Caricamento in blocco',
	'ONBOARDING.FOR_BULK_UPLOADS': 'per caricamenti in blocco',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': 'Scarica modello CSV',
	'ONBOARDING.SEND_INVITE': 'Spedisci invito',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': 'Gli utenti che sono stati sospesi perderanno l’accesso agli account {companyName}. Gli utenti che sono stati sospesi non possono richiedere l’accesso, ma l’amministratore può riattivare l’account dell’utente; per ottenere di nuovo l’accesso, gli utenti che sono stati sospesi dovranno reimpostare la password del proprio account.',
	'ONBOARDING.PAUSE_USER': 'Sospendi utente',
	'ONBOARDING.ASSIGNED_USER_GROUP': 'Gruppo utenti assegnato',
	'ONBOARDING.ACCESS_LEVEL': 'Livello di accesso',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': 'Inviare link reimpostazione password a e-mail',
	'ONBOARDING.EDIT_USER': 'Modifica utente',
	'ONBOARDING.RESET_PASSWORD': 'Reimpostazione password',
	'ONBOARDING.CURRENT_STATUS': 'Stato corrente',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': 'Visualizzare le autorizzazioni assegnate dall’utente',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': 'Gestire autorizzazioni individuali',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': 'Autorizzazioni individuali',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': 'Autorizzazioni efficaci',
	'ONBOARDING.ASSIGNED_USER_GROUPS': 'Gruppi utenti assegnati',
	'ONBOARDING.ACTION': 'Azione',
	'ONBOARDING.DELETE_PERMISSIONS': 'Eliminare autorizzazioni',
	'ONBOARDING.VIEW_PERMISSIONS': 'Visualizzare autorizzazioni',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': 'Aggiungere un altro gruppo utenti',
	'ONBOARDING.PENDING_INVITATIONS': 'Inviti in sospeso',
	'ONBOARDING.PERMISSIONS': 'Autorizzazioni',
	'ONBOARDING.CLOSE': 'Chiudi',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': 'Salvare autorizzazioni individuali',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': 'L’accesso sarà concesso ai seguenti account M2M',
	'ONBOARDING.ACCESS_MANAGEMENT': 'Gestione accessi',
	'ONBOARDING.SERVICE_PROVIDER_USERS': 'Utenti provider di servizi',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': 'Gruppi utenti provider di servizi',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': 'Aggiungere utenti provider di servizi in modo da poter gestire gli account dei clienti ed effettuare l’onboarding di nuovi clienti.',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': 'Nessun gruppo utenti disponibile',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': 'Se è necessario eseguire un’operazione soggetta a restrizioni, rivolgersi al proprio contatto tecnico per assistenza.',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': 'Alcune configurazioni sono limitate ai super user AT&T.',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': 'I gruppi utenti vengono creati utilizzando il modello con privilegi minimi.',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': 'Diritti a livello di sistema',
	'ONBOARDING.DENY_ACCESS': 'Negare l’accesso',
	'ONBOARDING.ALLOW_READ': 'Consentire lettura',
	'ONBOARDING.ALLOW_WRITE': 'Consentire scrittura',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': 'Permessi gruppo modifica',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': 'Nessuna autorizzazione disponibile',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': 'Non ci sono attualmente diritti di rete disponibili da mostrare per questa tabella',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': 'Modifica account M2M',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': 'Sposta a sottoconto',
	'ONBOARDING.CAN_RECIVE_EMAIL': 'Può ricevere email',
	'ONBOARDING.UNRESTRICTED_ACCESS': 'Accesso illimitato',
	'ONBOARDING.ACTIVE': 'Attivo', // delete later
	'ONBOARDING.PAUSED': 'In sospeso', // eliminare più tardi
	'ONBOARDING.VIEW_USER_GROUP': 'Visualizzare gruppo utenti',
	'ONBOARDING.DELETE_USER_GROUP': 'Eliminare gruppo utenti',
	'ONBOARDING.SAVE': 'Salva',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': 'Selezionare il gruppo a cui l’utente deve appartenere:',

	'ONBOARDING.ALLOW_ACCESS': 'Consentire l’accesso',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': 'Creare nuova password',
	'ONBOARDING.NEW_PASSWORD': 'Nuova password',
	'ONBOARDING.CONFIRM_PASSWORD': 'Confermare password',
	'ONBOARDING.ENTER_NEW_PASSWORD': 'Inserire nuova password',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': 'Inserire la nuova password',
	'ONBOARDING.GO_TO_HOME_PAGE': 'Tornare al login',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': 'Informazioni non fornite.',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': 'per aggiungere i dettagli dell’indirizzo.',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': 'Modificare account assegnati',
	'ONBOARDING.EDIT_PERMISSIONS': 'Modificare autorizzazioni',
	'ONBOARDING.SUSPENDED': 'Sospeso',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': 'Disponibile invito in sospeso',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': 'Nel passaggio successivo sarà possibile ottimizzare i permessi del nuovo gruppo',
	'ONBOARDING.GRANT_ACCESS': 'Concedere l’accesso',
	'ONBOARDING.SELECT_USER_GROUP': 'Selezionare un gruppo utenti',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': 'Nessun invito disponibile nel sistema',
	'ONBOARDING.ADD_USER_GROUP': 'Aggiungere gruppo utenti',
	'ONBOARDING.ADD_ADDRESS': 'Aggiungere indirizzo',
	'ONBOARDING.CROP_IMAGE': 'Ritaglia immagine',
	'ONBOARDING.CROP': 'Ritaglia',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': 'Questa azione è irreversibile e qualsiasi dato storico dell’utente verrà eliminato dai nostri sistemi.',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': 'L’eliminazione del profilo rimuoverà l’accesso dell’utente da tutti gli account a cui ha attualmente accesso.',
	'ONBOARDING.DELETE_USERNAME_PROFILE': 'Eliminare profilo {name} {lastName}',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': 'Inserire frase di conferma',
	'ONBOARDING.DELETE_PROFILE': 'Eliminare profilo',
	'ONBOARDING.DELETE_USER': 'Eliminare utente',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': 'Giustificazione della sospensione (opzionale)',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': 'Confermare l’operazione?',
	'ONBOARDING.REACTIVATE_USER': 'Riattivare l’utente',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': 'Facendo clic su Sì, si riceverà a breve un’email di reimpostazione della password.',
	'ONBOARDING.RESET_USER_PASSWORD': 'Reimpostare password utente',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': 'Confermare l’operazione di riavvio password?',
	'ONBOARDING.ASSIGN_USER_GROUP': 'Assegnare gruppo utenti',
	'ONBOARDING.ASSIGN': 'Assegnare',
	'ONBOARDING.AND_OTHERS': 'E {totalCount} altri...',
	'ONBOARDING.MY_PROFILE': 'Profilo personale',
	'ONBOARDING.EDIT_USER_GROUP': 'Modificare gruppo utenti',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': 'Modificare autorizzazioni individuali',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': 'Visualizzare autorizzazioni individuali',
	'ONBOARDING.NO_USERS_AVAILABLE': 'Nessun utente disponibile',
	'ONBOARDING.CONTACTS': 'Contatti',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': 'Confermare l’operazione?',
	'ONBOARDING.REMOVE_USER_GROUP': 'Rimuovere gruppo utenti',
	'ONBOARDING.CONTACT_TYPE': 'Tipo di contatto',
	'ONBOARDING.CANCEL_INVITATION': 'Annullare invito',
	'ONBOARDING.RESEND_INVITATION': 'Rinviare invito',
	'ONBOARDING.REACTIVATE_USER_GROUP': 'Riattivare gruppo utenti',
	'ONBOARDING.SUSPEND_USER_GROUP': 'Sospendere gruppo utenti',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': 'Non ci sono gruppi utenti da assegnare',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': 'Tipo indirizzo esistente',
	'ONBOARDING.ADD_NEW_ADDRESS': 'Aggiungere nuovi indirizzi',
	'ONBOARDING.ADD_NEW_CONTACT': 'Aggiungere nuovo contatto',
	'ONBOARDING.ADD_ANOTHER_CONTACT': 'Aggiungere un altro contatto',
	'ONBOARDING.COMPANY_FEATURES': 'Funzionalità aziendali',
	'ONBOARDING.INFO_NOT_PROVIDED': 'Informazioni non fornite',
	'ONBOARDING.COMPANY_SETTINGS': 'Impostazioni aziendali',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': 'Se ha già un account, effettui l’accesso.',
	'ONBOARDING.ACCOUNT_ACTIVATED': 'Tra poco riceverà un’e-mail con le istruzioni su come creare la password e accedere al Suo account.',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': 'La reimpostazione della password è gestita da AT&T Global Sign-On',

	//  Billing platform
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  Address type
	'ONBOARDING.BILLING_ADDRESS': 'Indirizzo fatturazione',
	'ONBOARDING.HOME_ADDRESS': 'Indirizzo casa',

	//  Contact type
	'ONBOARDING.MAIN_CONTACT': 'Contatto principale',
	'ONBOARDING.BACKUP_CONTACT': 'Contatto di backup',
	'ONBOARDING.TECHNICAL_OWNER': 'Proprietario tecnico',

	//  Address type
	'ONBOARDING.SHIPPING': 'Spedizione',
	'ONBOARDING.HOME': 'Home',
	'ONBOARDING.ANOTHER': 'Un altro',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': 'Avviare IoT Console',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': 'Questo numero è necessario per visualizzare le fatture nella IoT Console.',
	'ONBOARDING.BILLING_REPORTS': 'Report fatturazione',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': 'Questa casella deve essere selezionata per visualizzare i dettagli della fattura.',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': 'Questa piattaforma non supporta i dettagli di fatturazione.',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': 'Visualizzazione o modifica delle impostazioni di fatturazione',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': 'ID account non trovato.',
	'ONBOARDING.ARE_YOU_SURE': 'Confermare l’operazione?',
	'ONBOARDING.REMOVE_CONTACT': 'Rimuovere contatto',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'ID POD, inquilino o istanza',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': 'Device Connection Platform',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': 'Confermare l’operazione?',

	// Added after 7.4
	'ONBOARDING.ANONYMIZED': 'Anonimizzato',
    'ONBOARDING.ACQUISITION_BOUNTY': 'Premio per l’acquisizione',
    'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': 'Premio per l’acquisizione combinato',
    'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': 'Sovvenzione combinata per l’hardware',
    'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': 'Pacchetti finanziati da partner combinati',
    'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': 'Addebiti utilizzo in pool combinati',
    'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': 'Addebiti abbonamento combinati',
    'ONBOARDING.COMBINED_TRIAL_COST_SHARE': 'Percentuale dei costi combinati per le prove',
    'ONBOARDING.COMBINED_USAGE_SUMMARY': 'Riepilogo utilizzo combinato',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': 'Addebiti fattura mensili combinati',
    'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': 'Dettagli fattura mensili combinati',
    'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': 'Utilizzo non in pool addebitato mensile combinato',
    'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': 'Altri addebiti mensili combinati',
    'ONBOARDING.PARTNER_FUNDED_PACKAGES': 'Pacchetti finanziati da partner',
    'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': 'Stato dispositivo account su base mensile',
    'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': 'Report utilizzo account per rete satellitare',

};
