import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import Loader from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';
import { getDateFormat } from '../../../../utils/constants';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './BatchActions.scss';
import {
	batchActionsHeaderDesktop,
	batchActionsHeaderMobile
} from '../../utils/tableHeaders';

import { newOnPageChange } from '../../../../utils/tableHelpers';
import { localizationHelper } from '../../../../utils/helperFunctions';

const Info = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/downloadable-file-l.svg'
);

const BatchActions = () => (WrappedComponent) => {
	class BatchActionsComponent extends PureComponent {
		componentDidMount() {
			this.getBatchActions();
		}

		getBatchActions = () => {
			const { getBatchActions, dataLimit, dataOffset } = this.props;

			const additionalParams = {
				dataLimit,
				dataOffset,
				dataSort: 'createdAt desc'
			};

			const searchParams = [
				{
					propValue: 'batch',
					prop: 'type',
					operator: '='
				}
			];

			const params = {
				additionalParams,
				searchParams
			};

			getBatchActions(params);
		};

		totalCount = () => {
			const { dataLimit, batchActions } = this.props;
			return Math.ceil(batchActions.totalCount / dataLimit);
		};

		getOptions = (target) => {
			const {
				dataLimit,
				batchActions,
				selectedPage,
				setDataOffset,
				setSelectedPage,
				downloadReport,
			} = this.props;

			const options = {
				header: target === 'desktop' ? batchActionsHeaderDesktop : batchActionsHeaderMobile,
				customComponents: {
					scheduledDate: {
						type: 'custom',
						component: (val) => this.renderScheduledDate(val)
					},
					status: {
						type: 'custom',
						component: (data) => this.renderStatus(data)
					},
					successRate: {
						type: 'custom',
						component: (data) => data.successRate || 'N/A'
					},
					details: {
						type: 'custom',
						component: (data) => (data && data.reportFileName
							&& data.reportFrequency && data.yearOfGeneration
							&& data.monthOfGeneration && data.dateOfGeneration
							&& data.reportCategory && data.m2mAccountId ? (
								<div
									className={styles.color}
									onClick={() => {
										downloadReport(data.reportFileName,
											data.reportFrequency,
											data.serviceProvider,
											data.yearOfGeneration,
											data.monthOfGeneration,
											data.dateOfGeneration,
											data.reportCategory,
											data.m2mAccountId);
									}}
								>
									<DocumentDownloadIcon
										data-spec="download-icon"
										className={styles.icon}
									/>
									<FormattedMessage id="DOWNLOAD" defaultMessage="Download" />
								</div>
							) : (
									'N/A'
							))
					},
				},
				tableOptions: {
					forcePage: selectedPage,
					showPagination: batchActions && batchActions.totalCount > dataLimit,
					onPageChange: (data) =>
						newOnPageChange(
							data,
							this.getBatchActions,
							dataLimit,
							setDataOffset,
							setSelectedPage
						),
					pageCount: this.totalCount()
				}
			};

			return options;
		};

		renderScheduledDate = (data) => (
			<span data-spec="scheduled-date">{getDateFormat(data.createdAt)}</span>
		);

		renderTooltip = (descriptionTooltip, id) => (
			<FormattedMessage
				id={`BATCH_HISTORY.${localizationHelper(descriptionTooltip)}_TOOLTIP`}
				defaultMessage={`${descriptionTooltip} tooltip`}
			>
				{(formattedValue) => (
					<span className={styles.tooltip_data}>
						<Info
							data-tip={formattedValue}
							data-for={`BatchActionStatusTooltip_${id}`}
						/>
						<ReactTooltip
							className={styles.tooltip}
							type="light"
							id={`BatchActionStatusTooltip_${id}`}
						/>
					</span>
				)}
			</FormattedMessage>
		);

		renderStatus = (data) => (
			<div
				data-spec="batch-actions-status"
				className={cn(styles.history_status, styles[data.status.toLowerCase()])}
			>
				<FormattedMessage
					id={`BATCH_HISTORY.${localizationHelper(data.status)}_STATUS`}
					defaultMessage={`${data.status}`}
				/>
				{this.renderTooltip(data.status, data.id)}
			</div>
		);

		render() {
			const { batchActionsRequest, batchActions, downloadReportRequest } = this.props;
			return (
				<div data-spec="batch-actions">
					{(batchActionsRequest || downloadReportRequest) && <Loader />}
					{!batchActionsRequest && batchActions && !downloadReportRequest && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}
					{batchActions && batchActions.resultList.length === 0 && (
						<NoData
							table
							title={
								<FormattedMessage
									id="BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE"
									defaultMessage="No Batch Actions Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE"
									defaultMessage="There are No Batch Actions for this Table to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { func, number, bool, object } = PropTypes;

	BatchActionsComponent.propTypes = {
		getBatchActions: func,
		dataLimit: number,
		batchActionsRequest: bool,
		batchActionsFail: bool,
		batchActions: object,
		dataOffset: number,
		selectedPage: number,
		setDataOffset: func,
		setSelectedPage: func,
		downloadReport: func,
		downloadReportRequest: bool,
	};
	return BatchActionsComponent;
};

export default BatchActions;
