export const reportsFilters = {
	serviceProvider: ['EOD', 'ACC', 'GBCM', 'DCP', 'MC', 'GMNA', 'POD3', 'POD19'],
	reportFrequency: ['Daily', 'Monthly']
};

export const reportsFiltersService = () =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve({ data: reportsFilters });
		}, 500);
	});
