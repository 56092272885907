export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION':
		'Delete Defined Chart Setting?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE':
		'Are you sure you would like to delete setting {settingName}?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Current Available Graphs',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS':
		'This graph already exists in your settings. Only one type of graph is allowed.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'No description for this graph',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Graph Type',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Export Chart Data',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Remove Graph',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Graph Settings',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Add Graph',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'New Chart Added Successfully',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Adding New Chart Failed',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'Updated Chart Successfully',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Updating Chart Failed',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Chart Setting Deleted Successfully',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Chart Setting Delete Failed',
	'ACCOUNT_OVERVIEW.CHART_SETTING_NAME': 'Chart Setting Name',
	'ACCOUNT_OVERVIEW.CHART_DESCRIPTION': 'Chart Description',
	'ACCOUNT_OVERVIEW.ACTIONS': 'Actions',
	'ACCOUNT_OVERVIEW.ADD_GRAPH': 'Add New Graph Setting',
};
