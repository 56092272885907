import React from 'react';
import {
	shape,
	string,
	arrayOf,
	func,
	objectOf,
	bool,
} from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { isValid } from 'date-fns';

import Loader from '../../../../lib/DigitalComponents/Loader';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import Button from '../../../../lib/DigitalComponents/Button';

import styles from './RegistrationDetails.scss';

const TabletIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/endpoint-registration-details.svg',
);

export default function RegistrationDetails({
	data,
	fields,
	isFetching,
	isCanceling,
	isSuccess,
	submitCancelRegistration,
	iccid,
}) {
	const onClickSubmitCancelRegistration = () => submitCancelRegistration(iccid);

	if (Object.keys(data).length === 0 && isSuccess) {
		return (
			<div data-spec="registration-details-no-data">
				<div className={styles.actions_wrapper} key="cancel-location">
					<div className={styles.title}>
						<FormattedMessage id="ACTIONS" defaultMessage="Actions" />
					</div>
					<Button
						dataSpec="cancel-registration"
						variant="outline-primary"
						onClick={onClickSubmitCancelRegistration}
						label={
							isCanceling ? (
								<FormattedMessage id="LOADING" defaultMessage="Loading" />
							) : (
								<FormattedMessage
									id="CANCEL_LOCATION"
									defaultMessage="Cancel location"
								/>
							)
						}
						disabled={isCanceling}
					/>
				</div>
				<div className={styles.registration_details} key="no-data-message">
					<FormattedMessage
						id="NO_DATA_TO_DISPLAY_AT_THE_MOMENT"
						defaultMessage="No data to display at the moment"
					/>
				</div>
			</div>
		);
	}

	return (
		<div data-spec="registration-details">
			<div className={styles.actions_wrapper}>
				<div className={styles.title}>
					<FormattedMessage id="ACTIONS" defaultMessage="Actions" />
				</div>
				<Button
					dataSpec="cancel-registration"
					variant="outline-primary"
					onClick={onClickSubmitCancelRegistration}
					label={
						isCanceling ? (
							<FormattedMessage id="LOADING" defaultMessage="Loading" />
						) : (
							<FormattedMessage
								id="CANCEL_LOCATION"
								defaultMessage="Cancel location"
							/>
						)
					}
					disabled={isCanceling}
				/>
			</div>
			{(Object.keys(data).length > 0 || isFetching) && (
				<div className={styles.registration_details}>
					<div className={styles.rd_title}>
						<FormattedMessage
							id="ENDPOINT_REGISTRATION_DETAILS"
							defaultMessage="Endpoint registration details"
						/>
					</div>
					{isFetching ? (
						<Loader data-spec="registration-details-loader" />
					) : (
						<>
							<div className={styles.rd_fields}>
								{fields.map((item) => (
									<div
										data-spec={`rd-field-${item.id}`}
										className={styles.rd_field}
										key={item.id}
									>
										<FormattedMessage
											id={item.id}
											defaultMessage={item.defaultLabel}
											tagName="div"
										/>
										{item.type === 'date' &&
										isValid(new Date(data[`${item.id}`])) ? (
											<div className={styles.rd_field_value}>
												<FormattedDate
													value={new Date(data[`${item.id}`])}
													year="numeric"
													month="2-digit"
													day="2-digit"
													hour="2-digit"
													minute="2-digit"
													second="2-digit"
													hour12={false}
												/>
											</div>
										) : (
											<div className={styles.rd_field_value}>
												{data[`${item.id}`] || '-'}
											</div>
										)}
									</div>
								))}
							</div>
							<TabletIcon className={styles.tablet_icon} />
						</>
					)}
				</div>
			)}
		</div>
	);
}

RegistrationDetails.propTypes = {
	data: shape({
		id: string,
	}).isRequired,
	fields: arrayOf(objectOf(string)).isRequired,
	iccid: string.isRequired,
	isFetching: bool,
	isCanceling: bool,
	isSuccess: bool,
	submitCancelRegistration: func,
};
RegistrationDetails.defaultProps = {
	isFetching: true,
	isCanceling: false,
	isSuccess: false,
	submitCancelRegistration: undefined,
};
