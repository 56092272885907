import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import EndpointsDesktop from './Endpoints';

import getActiveTab from '../../redux/endpointsTabs/selectors';
import {
	getUser,
	getEndpointsUserSettings
} from '../../../../redux/user/getUserData/selectors';
import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';
import { setSearchParameterInit } from '../../../Shared/redux/searchBar/actions';
import { setFilterData } from '../../../Shared/redux/filterModal/filterData/actions';
import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';
import { hideNotification } from '../../../Shared/redux/notifications/actions';
import setEndpointsActiveTab from '../../redux/endpointsTabs/actions';
import {
  setEndpointsPagination,
  setEndpointsPaginationInit,
	setEndpointsOrderColumn
} from '../../redux/endpointsPagination/actions';
import {
  getEndpointsSuccess,
  getCustomLabelsSuccess,
	getRatePlansSuccess,
	getAvailableStatesData
} from '../../redux/selectors';
import {
	openModal
} from '../../../../redux/modals/batchFileUploadModal/actions';
import {
	getSelectedPage,
	getEndpointsOrderColumn
} from '../../redux/endpointsPagination/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import { createUserSettingsSuccess, createUserSettingsData } from '../../../../redux/user/createUserSettings/selectors';
import { deleteUserSettingsSuccess, deleteUserSettingsRequest } from '../../../../redux/user/deleteUserSettings/selectors';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import {
	getEndpoints,
	getCustomLabels,
	getRatePlans,
	getAvailableStates
} from '../../redux/actions';
import {
	updateUserSettingsSuccess,
	updateUserSettingsPending
} from '../../../../redux/user/updateUserSettings/selectors';

const mapStateToProps = (state) => ({
	endpoints: getEndpointsSuccess(state),
	activeTab: getActiveTab(state),
	settings: getEndpointsUserSettings(state),
	availableStates: getAvailableStatesData(state),
	ratePlans: getRatePlansSuccess(state),
	user: getUser(state),
	pagination: getSelectedPage(state),
	customFieldLabels: getCustomLabelsSuccess(state),
	updateUserSettingsSuccess: updateUserSettingsSuccess(state),
	updateUserSettingsPending: updateUserSettingsPending(state),
	createUserSettingsSuccess: createUserSettingsSuccess(state),
	orderColumn: getEndpointsOrderColumn(state),
	deleteUserSettingsRequest: deleteUserSettingsRequest(state),
	deleteUserSettingsSuccess: deleteUserSettingsSuccess(state),
	createUserSettingsData: createUserSettingsData(state),
	searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	openBatchUploadModal: (type) => dispatch(openModal(type)),
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
	resetFiltersData: () => dispatch(setDataForFiltersInit()),
	setEndpointsActiveTab: (tab) => dispatch(setEndpointsActiveTab(tab)),
	setFilterData: (data) => dispatch(setFilterData(data)),
	setSearchParameterInit: () => dispatch(setSearchParameterInit()),
  getEndpointsNew: (params) => dispatch(getEndpoints(params)),
	getCustomLabels: () => dispatch(getCustomLabels()),
	updateUserSettings: (setting, id, notificationMessages) =>
		dispatch(updateUserSettings(setting, id, notificationMessages)),
	createUserSettings: (setting, message) =>
		dispatch(createUserSettings(setting, message)),
	fetchRatePlans: () => dispatch(getRatePlans()),
	setEndpointsPaginationInit: () => dispatch(setEndpointsPaginationInit()),
	setEndpointsPagination: (pagination) =>
		dispatch(setEndpointsPagination(pagination)),
	setEndpointsOrderColumn: (orderColumn) =>
		dispatch(setEndpointsOrderColumn(orderColumn)),
  getAvailableStates: () => dispatch(getAvailableStates()),
  hideNotification: (id) => dispatch(hideNotification(id)),
	goToSingleEndpoint: (data) =>
		dispatch(
			localizedRoute(
				`/endpoints/${
					data.networkType ? data.networkType.toLowerCase() : 'cellular'
				}/${data.endpointName}`
			)
		)
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(EndpointsDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(EndpointsDesktop);
