import { createAction } from 'redux-actions';
import globalAction from '../../../../redux/global/globalAction';

import {
	GET_USER_GROUPS,
	GET_USER_GROUP,
	ADD_USER_GROUP_MODAL,
	ADD_USER_GROUP,
	SUSPEND_USER_GROUP,
	REACTIVATE_USER_GROUP
} from './constants';

import {
	getUserGroups as getUserGroupsService,
	getUserGroup as getUserGroupService,
	addUserGroup as addUserGroupService,
	suspendUserGroup as suspendUserGroupService,
	reactivateUserGroup as reactivateUserGroupService
} from '../../services/UserGroupsService';

/* ******************** ADD USERS GROUP MODAL ******************** */
export const openAddUserGroupModal = createAction(
	`OPEN_${ADD_USER_GROUP_MODAL}`
);
export const closeAddUserGroupModal = createAction(
	`CLOSE_${ADD_USER_GROUP_MODAL}`
);

/* ******************** GET USER GROUPS ******************** */
export const getUserGroups = (params) =>
	globalAction(GET_USER_GROUPS, getUserGroupsService, params);

	/* ******************** GET USER GROUP ******************** */
export const getUserGroup = (params) =>
globalAction(GET_USER_GROUP, getUserGroupService, params);

/* ******************** ADD USER GROUP ******************** */
export const addUserGroup = (params) =>
	globalAction(ADD_USER_GROUP, addUserGroupService, params, {
		notifyFail: 'add-user-group',
		modals: closeAddUserGroupModal(),
		init: true
	});

/* ******************** SUSPEND USER GROUP ******************** */
export const suspendUserGroup = (params) =>
	globalAction(SUSPEND_USER_GROUP, suspendUserGroupService, params, {
		notify: 'suspend-user-group'
	});

/* ******************** REACTIVATE USER GROUP ******************** */
export const reactivateUserGroup = (params) =>
	globalAction(REACTIVATE_USER_GROUP, reactivateUserGroupService, params, {
		notify: 'activate-user-group'
	});
