export const GET_TICKETING_SEVERITIES_AND_STATUSES =
	'GET_TICKETING_SEVERITIES_AND_STATUSES';
export const GET_TICKETING_SEVERITIES_AND_STATUSES_REQUEST =
	'GET_TICKETING_SEVERITIES_AND_STATUSES_REQUEST';
export const GET_TICKETING_SEVERITIES_AND_STATUSES_SUCCESS =
	'GET_TICKETING_SEVERITIES_AND_STATUSES_SUCCESS';
export const GET_TICKETING_SEVERITIES_AND_STATUSES_FAIL =
	'GET_TICKETING_SEVERITIES_AND_STATUSES_FAIL';
export const GET_TICKETING_SEVERITIES_AND_STATUSES_INIT =
	'GET_TICKETING_SEVERITIES_AND_STATUSES_INIT';
