import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import PageTitle from '../../../Shared/views/PageTitleView';
import Button from '../../../../lib/DigitalComponents/Button';
import GridLayout from '../../../Shared/components/GridLayout';
import AccountOverviewModal from '../AccountOverviewModal';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import WrapperGridCard from '../../../Shared/components/WrapperGridCard';
import {
	UserAllowedToAccess,
	isUserAllowedToAccess,
	checkIfUserIsSystem
} from '../../../../utils/AuthSelector';
import {
	checkEmptyPlace,
	isCycle
} from '../../../Shared/components/Charts/Helpers/helpers';

import styles from './AccountOverview.scss';

const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');
const SuperAdminPage = UserAllowedToAccess(['system.graphsettings_rw']);
const BarIcon = ImageSelector(CurrentContext.theme, 'svgs/bar-chart.svg');
const PieIcon = ImageSelector(CurrentContext.theme, 'svgs/pie-chart.svg');
const MapIcon = ImageSelector(CurrentContext.theme, 'svgs/map.svg');
const LineIcon = ImageSelector(CurrentContext.theme, 'svgs/analytic.svg');
const CountIcon = ImageSelector(CurrentContext.theme, 'svgs/data-usage.svg');

class AccountOverview extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			settingsState: props.settings[0]
				? JSON.parse(props.settings[0].value)
				: { lg: [], md: [], sm: [] },
			openAddChartModal: false,
			selectedIdInModal: -1,
			selectedChartType: 'line',
			refresh: false,
			existingCharts: [],
			checked: false
		};
	}

	componentDidMount() {
		const {
			settings,
			createUserSettings,
			definedSettings,
			getDefinedSettings
		} = this.props;
		const { settingsState } = this.state;

		this.initialCalls(
			settings,
			createUserSettings,
			settingsState,
			definedSettings,
			getDefinedSettings,
		);
	}

	componentDidUpdate(prevProps, prevState) {
		const { settings, dataGridSize, definedSettings } = this.props;
		const { refresh, checked, selectedIdInModal } = this.state;

		if (selectedIdInModal < 0 && definedSettings && definedSettings.length) {
			this.setSelectedIdInModal(definedSettings[0].id);
		}

		if (definedSettings && settings && settings.length && !checked) {
			this.checkSettings(settings, definedSettings, dataGridSize);
		}

		if (!prevState.refresh && refresh) {
			this.setRefreshToFalse();
		}
	}

	initialCalls = (
		settings,
		createUserSettings,
		settingsState,
		definedSettings,
		getDefinedSettings,
	) => {
		if (!settings.length) {
			createUserSettings({
				refTypeId: 2,
				name: 'AccountOverviewSettings',
				value: JSON.stringify(settingsState)
			});
		}
		if (!definedSettings || definedSettings.length === 0) {
			getDefinedSettings();
		}
	};

	setSelectedIdInModal = (selectedIdInModal) =>
		this.setState({
			selectedIdInModal
		});

	checkSettings = (settings, definedSettings, dataGridSize) => {
		const temp = cloneDeep(JSON.parse(settings[0].value));
		let shouldUpdateSettings = false;

		JSON.parse(settings[0].value)[dataGridSize].forEach((setting) => {
			if (
				!definedSettings.find(
					(definedSetting) => definedSetting.id === setting.id
				)
			) {
				Object.keys(temp).forEach((tempItem) => {
					temp[tempItem] = temp[tempItem].filter(
						(item) => setting.id !== item.id
					);
				});
				shouldUpdateSettings = true;
			} else {
				Object.keys(temp).forEach((tempItem) => {
					temp[tempItem].find(
						(item) => setting.id === item.id
					).name = definedSettings.find(
						(definedSetting) => definedSetting.id === setting.id
					).name;
				});
			}
		});

		if (shouldUpdateSettings) {
			this.updateSettings(temp);
		}

		this.setState({
			checked: true,
			settingsState: temp,
			existingCharts: temp[dataGridSize].map((setting) => setting.id)
		});
	};

	setRefreshToFalse = () => this.setState({ refresh: false });

	openModal = (getDefinedSettings) => {
		getDefinedSettings();
		this.setState({
			openAddChartModal: true,
			selectedChartType: 'line'
		});
	};

	closeModal = () => this.setState({ openAddChartModal: false });

	updateSettings = (settingsToBeSaved) => {
		const { updateUserSettings, settings } = this.props;

		updateUserSettings(
			{ value: JSON.stringify(settingsToBeSaved) },
			settings[0].id
		);
		this.setState({ settingsState: settingsToBeSaved });
	};

	getChartData = async (gridItem) => {
		const {
			getChartData,
			dataGridSize,
			initChartData,
			checkChartSetting,
		} = this.props;
		const { settingsState } = this.state;
		const type = ['day', 'week', 'month'];
		const setting = settingsState[dataGridSize].find(
			(item) => item.id === gridItem.id
		);
		const temp = cloneDeep(settingsState);

		if (!gridItem.id.toString().includes('modal')) {
			initChartData(gridItem.id);
			checkChartSetting(gridItem.id);

			const promisedDefinedSetting = await getChartData(
				gridItem.id,
				{ timeZoneOffset: new Date().getTimezoneOffset() },
			);
			const defined = promisedDefinedSetting.payload.data.data;
			const incrementBy = defined.resultList.length > 0
				? Object.keys(defined.resultList[0]).filter((key) => (
					key !== 'value'
					&& key !== 'value_2'
					&& key !== defined.chartModalSettings.groupBySelector
				)).join()
				: '';

			if (
				defined.chartModalSettings.groupBySelector !== setting.groupByColumn ||
				defined.chartModalSettings.groupByDate !== setting.groupByDateId ||
				incrementBy !== setting.incrementBy ||
				type[defined.chartModalSettings.groupByIncrement] !==
					setting.incrementType
			) {
				Object.keys(temp).forEach((tmpItem) => {
					temp[tmpItem].find((item) => setting.id === item.id).groupByColumn =
						defined.chartModalSettings.groupBySelector;
					temp[tmpItem].find((item) => setting.id === item.id).groupByDateId =
						defined.chartModalSettings.groupByDate;
					temp[tmpItem].find(
						(item) => setting.id === item.id
					).incrementBy = incrementBy;
					temp[tmpItem].find((item) => setting.id === item.id).incrementType =
						type[defined.chartModalSettings.groupByIncrement];
				});
				await this.updateSettings(temp);
			}
			checkChartSetting(gridItem.id);
		} else if (
			!isCycle(gridItem.groupByDateId) &&
			gridItem.groupByDateId !== '3'
		) {
			getChartData(gridItem.id, {
				timeZoneOffset: new Date().getTimezoneOffset()
			});
		} else {
			getChartData(gridItem.id);
		}
	};

	addChart = () => {
		const { definedSettings, dataGridSize } = this.props;
		const {
			settingsState,
			selectedIdInModal,
			selectedChartType,
			existingCharts
		} = this.state;
		const emptyCoordinates = checkEmptyPlace(dataGridSize, settingsState);
		const type = ['day', 'week', 'month'];
		const chartSettings = JSON.parse(
			definedSettings.find((setting) => setting.id === selectedIdInModal)
				.chartSettings.value
		);
		const itemSettings = {
			i: `${selectedIdInModal}`,
			id: selectedIdInModal,
			name: definedSettings.find((setting) => setting.id === selectedIdInModal)
				.name,
			h: 1,
			maxH: 1,
			w: 1,
			groupByColumn: chartSettings.chartQuery.additionalParams.columnsToSelect.split(
				','
			)[0],
			incrementBy: chartSettings.chartQuery.additionalParams.columnsToSelect
				.split(',')
				.slice(1)
				.join(','),
			incrementType: type[chartSettings.chartModalSettings.groupByIncrement],
			groupByDateId: chartSettings.chartModalSettings.groupByDate,
			type: selectedChartType,
			units: chartSettings.chartModalSettings.unit,
			aggregationFunction: chartSettings
				.chartModalSettings
				.groupByShow === '2'
				? 'MVA' : '',
		};

		this.setState({
			selectedIdInModal: definedSettings[0].id,
			settingsState: {
				lg: [
					...settingsState.lg,
					{
						...itemSettings,
						x: emptyCoordinates.lg.x,
						y: emptyCoordinates.lg.y,
						maxW: 3
					}
				],
				md: [
					...settingsState.md,
					{
						...itemSettings,
						x: emptyCoordinates.md.x,
						y: emptyCoordinates.md.y,
						maxW: 2
					}
				],
				sm: [
					...settingsState.sm,
					{
						...itemSettings,
						x: emptyCoordinates.sm.x,
						y: emptyCoordinates.sm.y,
						maxW: 1
					}
				]
			},
			existingCharts: [...existingCharts, selectedIdInModal],
		});
	};

	removeChart = (itemIndex) => {
		const { settingsState, existingCharts } = this.state;

		this.setState({
			settingsState: {
				lg: settingsState.lg.filter((setting) => setting.i !== itemIndex),
				md: settingsState.md.filter((setting) => setting.i !== itemIndex),
				sm: settingsState.sm.filter((setting) => setting.i !== itemIndex)
			},
			existingCharts: existingCharts.filter((id) => `${id}` !== itemIndex)
		});
	};

	handleTabChange = (gridItem) =>
		this.setState({
			selectedIdInModal: gridItem.id,
			refresh: true
		});

	updateChartType = (gridItem, type) => {
		const { settingsState } = this.state;
		const tempSettings = cloneDeep(settingsState);

		tempSettings.lg.find((item) => gridItem.i === item.i).type = type;
		tempSettings.md.find((item) => gridItem.i === item.i).type = type;
		tempSettings.sm.find((item) => gridItem.i === item.i).type = type;
		this.updateSettings(tempSettings);
	};

	exportChartCSV = (gridItem, exportChartDataCSV) =>
		(!isCycle(gridItem.groupByDateId) && gridItem.groupByDateId
			? exportChartDataCSV(gridItem.id, gridItem.name, {
				id: gridItem.id,
				additionalParams: { timeZoneOffset: new Date().getTimezoneOffset() }
			  })
			: exportChartDataCSV(gridItem.id, gridItem.name, { id: gridItem.id }));

	renderTabs = () => {
		const {
			definedSettings,
			chartData,
			isGetChartDataPending,
			asOfDate
		} = this.props;
		const { selectedChartType, refresh, existingCharts } = this.state;
		const type = ['day', 'week', 'month'];

		if (!definedSettings) return [];

		return definedSettings.map((setting) => {
			const chartSettings = setting.chartSettings
				? JSON.parse(setting.chartSettings.value)
				: [];

			if (chartSettings === null) return [];

			return {
				id: setting.id,
				displayName: setting.name,
				content: () => (
					<div>
						<div className={styles.modal_header_tabs}>
							<h5>{setting.name}</h5>
							{existingCharts.find((item) => item === setting.id) !==
							undefined ? (
									<h5>
										<FormattedMessage
											id="ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS"
											defaultMessage="This graph already exists in your settings. Only one type of graph is allowed."
										/>
									</h5>
								) : null}
							<p>
								{setting.description ? (
									setting.description
								) : (
									<FormattedMessage
										id="ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH"
										defaultMessage="No description for this graph"
									/>
								)}
							</p>
						</div>
						{this.renderChartIcons(null, selectedChartType)}
						{!refresh ? (
							<WrapperGridCard
								contentData={chartData}
								gridItem={{
									id: `${setting.id}_modal`,
									groupByColumn: chartSettings
										.chartQuery
										.additionalParams
										.columnsToSelect
										.split(',')[0],
									incrementBy: chartSettings
										.chartQuery
										.additionalParams
										.columnsToSelect
										.split(',')
										.slice(1)
										.join(','),
									incrementType:
										type[chartSettings.chartModalSettings.groupByIncrement],
									groupByDateId: chartSettings.chartModalSettings.groupByDate,
									type: selectedChartType,
									units: chartSettings.chartModalSettings.unit,
									aggregationFunction: chartSettings
										.chartModalSettings
										.groupByShow === '2'
									? 'MVA' : '',
								}}
								getContent={this.getChartData}
								isModal
								widthSize={1}
								pendingData={isGetChartDataPending(`${setting.id}_modal`)}
								asOfDate={asOfDate}
							/>
						) : null}
					</div>
				)
			};
		});
	};

	renderChartIcons = (gridItem, selectedChartType) => {
		const iconTab = (type) =>
			cn({
				[styles.iconTab]: true,
				[styles.active]: gridItem
					? type === gridItem.type
					: type === selectedChartType
			});

		return (
			<div data-spec="chart-icons-dropdown" className={styles.icon_wrapper}>
				<div className={iconTab('line')}>
					<LineIcon
						data-spec="select-line"
						className={styles.chart_Icon}
						onClick={() => {
							this.setState({ selectedChartType: 'line', refresh: true });
							if (gridItem) this.updateChartType(gridItem, 'line');
						}}
					/>
				</div>
				<div className={iconTab('bar')}>
					<BarIcon
						data-spec="select-bar"
						className={styles.chart_Icon}
						onClick={() => {
							this.setState({ selectedChartType: 'bar', refresh: true });
							if (gridItem) this.updateChartType(gridItem, 'bar');
						}}
					/>
				</div>
				<div className={iconTab('pie')}>
					<PieIcon
						data-spec="select-pie"
						className={styles.chart_Icon}
						onClick={() => {
							this.setState({ selectedChartType: 'pie', refresh: true });
							if (gridItem) this.updateChartType(gridItem, 'pie');
						}}
					/>
				</div>
				<div className={iconTab('map')}>
					<MapIcon
						data-spec="select-map"
						className={styles.chart_Icon}
						onClick={() => {
							this.setState({ selectedChartType: 'map', refresh: true });
							if (gridItem) this.updateChartType(gridItem, 'map');
						}}
					/>
				</div>
				<div className={iconTab('count')}>
					<CountIcon
						data-spec="select-total"
						className={styles.chart_Icon}
						onClick={() => {
							this.setState({ selectedChartType: 'count', refresh: true });
							if (gridItem) this.updateChartType(gridItem, 'count');
						}}
					/>
				</div>
			</div>
		);
	};

	renderDropdownItem = (gridItem, selectedChartType, exportChartDataCSV) => (
		<div data-spec="account-overview-dropdown" className={styles.dropmenu}>
			<div className={`${styles.menuTop} ${styles.menuTop__color}`}>
				<h4>
					<FormattedMessage
						id="ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE"
						defaultMessage="Graph Type"
					/>
				</h4>
				<div data-spec="dropdown-icons-wrapper">
					{this.renderChartIcons(gridItem, selectedChartType)}
				</div>
			</div>
			<div
				data-spec={`export-graph-${gridItem.i}`}
				onClick={() => this.exportChartCSV(gridItem, exportChartDataCSV)}
				className={`${styles.menuBottom} ${styles.menuBottom__color}`}
			>
				<FormattedMessage
					id="ACCOUNT_OVERVIEW.EXPORT_CHART_DATA"
					defaultMessage="Export Chart Data"
				/>
			</div>
			<hr />
			<div
				data-spec={`remove-graph-${gridItem.name}`}
				onClick={() => this.removeChart(gridItem.i)}
				className={`${styles.menuBottom} ${styles.menuBottom__color}`}
			>
				<FormattedMessage
					id="ACCOUNT_OVERVIEW.REMOVE_CHART"
					defaultMessage="Remove Graph"
				/>
			</div>
		</div>
	);

	render() {
		const {
			dataGridSize,
			settings,
			chartData,
			isGetChartDataPending,
			definedSettings,
			openAdminPage,
			isGetDefinedSettingsPending,
			asOfDate,
			isExportingChartDataCsv,
			exportChartDataCSV,
			getDefinedSettings,
			checkedChartSetting,
			user
		} = this.props;

		const {
			settingsState,
			openAddChartModal,
			existingCharts,
			selectedIdInModal,
			selectedChartType,
			checked,
		} = this.state;

		return (
			<div data-spec="account-overview">
				{
					(checkIfUserIsSystem(user) && !isUserAllowedToAccess(
						[
							'system.companies_rw',
							'system.companies_ro'
						],
						user
					) && (
						<div />
					)) || (
					<div>
						<PageTitle
							title={
								<FormattedMessage
									id="ACCOUNT_OVERVIEW"
									defaultMessage="Account Overview"
								/>
							}
							actions={
							<div className={styles.topbar_buttons_wrapper}>
								<SuperAdminPage>
									<Button
										dataSpec="open-super-admin-page"
										label={
											<FormattedMessage
												id="ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE"
												defaultMessage="Graph Settings"
											/>
										}
										variant="primary"
										onClick={openAdminPage}
									/>
								</SuperAdminPage>
								{dataGridSize !== 'sm' && (
									<Button
										dataSpec="add-graph-button"
										onClick={() => this.openModal(getDefinedSettings)}
										disabled={
											(settingsState && settingsState.length > 11)
										}
										variant="primary"
										label={
											<FormattedMessage
												id="ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON"
												defaultMessage="Add Graph"
											/>
										}
										labelIcon={<AddIcon className={styles.addIconPlus} />}
									/>
								)}
							</div>
						}
						/>
					{checked ? (
						<GridLayout
							settings={settingsState}
							propSettings={JSON.parse(settings[0].value)}
							dataGridSize={dataGridSize}
							getContent={this.getChartData}
							contentData={chartData}
							dropdownItem={(gridItem) =>
								this.renderDropdownItem(
									gridItem,
									selectedChartType,
									exportChartDataCSV
								)}
							pendingData={(id) =>
								isGetChartDataPending(id) || isExportingChartDataCsv(id) || checkedChartSetting(id)}
							updateSettings={this.updateSettings}
							hasRefresh
							asOfDate={asOfDate}
						/>
					) : null}
					</div>
					)
				}
				<AccountOverviewModal
					addNewChart={this.addChart}
					onClose={this.closeModal}
					show={openAddChartModal}
					tabs={this.renderTabs()}
					definedSettings={definedSettings}
					onTabChange={this.handleTabChange}
					existingCharts={existingCharts}
					selectedIdInModal={selectedIdInModal}
					hasChartData={
						chartData(`${selectedIdInModal}_modal`) &&
						chartData(`${selectedIdInModal}_modal`).length > 0
					}
					isGetDefinedSettingsPending={isGetDefinedSettingsPending}
				/>
			</div>
		);
	}
}

const { string, func, arrayOf, bool, object } = PropTypes;

AccountOverview.propTypes = {
	dataGridSize: string,
	settings: arrayOf(object),
	chartData: func,
	isGetChartDataPending: func,
	getChartData: func,
	updateUserSettings: func,
	definedSettings: arrayOf(object),
	getDefinedSettings: func,
	openAdminPage: func,
	isGetDefinedSettingsPending: bool,
	asOfDate: func,
	exportChartDataCSV: func,
	isExportingChartDataCsv: func,
	createUserSettings: func,
	initChartData: func,
	checkChartSetting: func,
	checkedChartSetting: func,
	user: object
};

AccountOverview.defaultProps = {
	dataGridSize: '',
	settings: [],
	definedSettings: [],
	isGetDefinedSettingsPending: false,
	chartData: undefined,
	isGetChartDataPending: undefined,
	getChartData: undefined,
	updateUserSettings: undefined,
	getDefinedSettings: undefined,
	openAdminPage: undefined,
	asOfDate: undefined,
	exportChartDataCSV: undefined,
	isExportingChartDataCsv: undefined,
	createUserSettings: undefined,
	initChartData: undefined,
	checkChartSetting: undefined,
	checkedChartSetting: undefined,
};

export default AccountOverview;
