import { handleActions } from 'redux-actions';
import { Status } from 'utils/constants';

import {
	UPDATE_BULK_NETWORK_ENTITLEMENT_REQUEST,
	UPDATE_BULK_NETWORK_ENTITLEMENT_SUCCESS,
	UPDATE_BULK_NETWORK_ENTITLEMENT_FAIL,
	UPDATE_BULK_NETWORK_ENTITLEMENT_STARTED,
	UPDATE_BULK_NETWORK_ENTITLEMENT_ENDED,
	UPDATE_BULK_NETWORK_ENTITLEMENT_ADD_FILTERED_SIM,
	UPDATE_BULK_NETWORK_ENTITLEMENT_ERROR_CLEAR
} from './constants';

export const initialState = {
	status: Status.INIT,
	error: [],
	currentSim: '',
	successSims: [],
	failSims: [],
	actionError: false,
	started: false,
	filteredSims: []
};

export default handleActions(
	{
		[UPDATE_BULK_NETWORK_ENTITLEMENT_REQUEST]: (state, action) => ({
			...state,
			currentSim: action.payload.id,
			status: Status.PENDING
		}),
		[UPDATE_BULK_NETWORK_ENTITLEMENT_STARTED]: (state) => ({
			...state,
			currentSim: '',
			successSims: [],
			failSims: [],
			actionError: false,
			started: true,
			filteredSims: []
		}),
		[UPDATE_BULK_NETWORK_ENTITLEMENT_ENDED]: (state) => ({
			...state,
			status: Status.INIT,
			actionError: !state.successSims.length,
			started: false
		}),
		[UPDATE_BULK_NETWORK_ENTITLEMENT_SUCCESS]: (state, action) => ({
			...state,
			successSims: [...state.successSims, action.payload.id],
			status: Status.DONE
		}),
		[UPDATE_BULK_NETWORK_ENTITLEMENT_FAIL]: (state, action) => ({
			...state,
			status: Status.FAIL,
			failSims: [...state.failSims, action.payload.id],
			error: action.payload.error
		}),
		[UPDATE_BULK_NETWORK_ENTITLEMENT_ADD_FILTERED_SIM]: (state, action) => ({
			...state,
			filteredSims: [...state.filteredSims, action.payload.id]
		}),
		[UPDATE_BULK_NETWORK_ENTITLEMENT_ERROR_CLEAR]: (state) => ({
			...state,
			actionError: false
		})
	},
	initialState
);
