import { createAction } from 'redux-actions';

import globalAction, { actionInit } from '../../../redux/global/globalAction';
import {
	addFeedback as postFeedbackService,
	batchFileRequestURL as serviceBatchFileRequest,
	batchFileUpload as serviceBatchFileUpload,
	pollForFile
} from '../services/FeedbackService';
import {
	SET_FEEDBACK,
	FEEDBACK_FILE_UPLOAD_REQUEST,
	FEEDBACK_FILE_UPLOAD_SUCCESS,
	FEEDBACK_FILE_UPLOAD_FAIL,
	FEEDBACK_FILE_UPLOAD
} from './constants';

export const addFeedback = (postObj) =>
	globalAction(SET_FEEDBACK, postFeedbackService, postObj);

export const resetFeedback = () => actionInit(SET_FEEDBACK);

const fail = createAction(FEEDBACK_FILE_UPLOAD_FAIL);
const success = createAction(FEEDBACK_FILE_UPLOAD_SUCCESS);
const request = createAction(FEEDBACK_FILE_UPLOAD_REQUEST);

export const feedbackFileRequestURL = (data) => async (dispatch) => {
	try {
		dispatch(request());

		const batchFileRequest = await serviceBatchFileRequest(data.request);
		const { id } = batchFileRequest.data;
		await serviceBatchFileUpload(batchFileRequest.data.uploadURL, data.file);
		const pollFor = await pollForFile({ id }, 200, 4000);

		dispatch(success({ data: pollFor }));
	} catch (error) {
		dispatch(fail(error));
	}
};

export const feedbackFileUploadClear = () => actionInit(FEEDBACK_FILE_UPLOAD);
