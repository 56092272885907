import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_RATE_PLANS, GET_RATE_PLANS_FILTERS } from './constants';

export default combineReducers({
	getRatePlans: globalReducer(GET_RATE_PLANS),
	getRatePlansFilters: globalReducer(GET_RATE_PLANS_FILTERS)
});
