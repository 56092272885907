export const CANCEL_REGISTRATION = 'CANCEL_REGISTRATION';
export const CANCEL_SCHEDULE_STATUS = 'CANCEL_SCHEDULE_STATUS';
export const CHANGE_NETWORK_ENTITLEMENT = 'CHANGE_NETWORK_ENTITLEMENT';
export const GET_ENDPOINT_REGISTRATION_HISTORY = 'GET_ENDPOINT_REGISTRATION_HISTORY';
export const GET_DATA_HISTORY = 'GET_DATA_HISTORY';
export const GET_MESSAGE_HISTORY = 'GET_MESSAGE_HISTORY';
export const SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT = 'SINGLE_ENDPOINT_GET_NETWORK_ENTITLEMENT';
export const GET_NETWORK_ENTITLEMENT_APN = 'GET_NETWORK_ENTITLEMENT_APN';
export const GET_NOTE = 'GET_NOTE';
export const GET_HISTORY_CHANGE = 'GET_HISTORY_CHANGE';
export const SEND_SMS_MESSAGE = 'SEND_SMS_MESSAGE';
export const SEND_DIAGNOSTIC_SMS_MESSAGE = 'SEND_DIAGNOSTIC_SMS_MESSAGE';
export const UPDATE_CUSTOM_FIELDS_VALUES = 'UPDATE_CUSTOM_FIELDS_VALUES';
export const UPDATE_M2M_ACCOUNT = 'UPDATE_M2M_ACCOUNT';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const GET_BLOCKED_SUBSCRIPTION_DCP = 'GET_BLOCKED_SUBSCRIPTION_DCP';
export const GET_CCIP_ENDPOINTS_DATA = 'GET_CCIP_ENDPOINTS_DATA';
export const GET_TROUBLESHOOT_DCP = 'GET_TROUBLESHOOT_DCP';
export const GET_DIAGNOSTIC_HLR = 'GET_DIAGNOSTIC_HLR';
export const GET_GDSP_ENDPOINTS_DATA = 'GET_GDSP_ENDPOINTS_DATA';
export const GET_DCP_ENDPOINTS_DATA = 'GET_DCP_ENDPOINTS_DATA';
export const GET_REGISTRATION_DETAILS = 'GET_REGISTRATION_DETAILS';
export const GET_DIAGNOSTICS_DETAILS = 'GET_DIAGNOSTICS_DETAILS';
export const GET_ENDPOINT_USAGE_DATA = 'GET_ENDPOINT_USAGE_DATA';
export const UPDATE_RATE_PLAN = 'UPDATE_RATE_PLAN';
export const SINGLE_ENDPOINT_GET_RATE_PLANS = 'SINGLE_ENDPOINT_GET_RATE_PLANS';
export const UPDATE_SINGLE_ENDPOINT = 'UPDATE_SINGLE_ENDPOINT';
export const GET_SINGLE_ENDPOINT = 'GET_SINGLE_ENDPOINT';
export const GET_SESSION_HISTORY = 'GET_SESSION_HISTORY';
export const EXPORT_SESSIONS_DATA_CSV = 'EXPORT_SESSIONS_DATA_CSV';
export const GET_SINGLE_ENDPOINT_M2M_ACCOUNTS = 'GET_SINGLE_ENDPOINT_M2M_ACCOUNTS';
export const EXPORT_USAGE_DATA = 'EXPORT_USAGE_DATA';
