import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const RatePlanScheduleModalContext = React.createContext();

export class RatePlanScheduleModalContextProvider extends PureComponent {
	constructor(props) {
    super(props);
		this.state = {};
  }

	render() {
    const { children } = this.props;

		return (
			<RatePlanScheduleModalContext.Provider
				data-spec="rate-plan-schedule-modal-provider"
				value={{
					state: this.state,
					updateContext: (newState) => this.setState(newState)
				}}
			>
				{children}
			</RatePlanScheduleModalContext.Provider>
		);
	}
}

RatePlanScheduleModalContextProvider.propTypes = {
	children: PropTypes.any
};

export function RatePlanScheduleModalContextConsumer(props) {
    const { children } = props;

		return (
			<RatePlanScheduleModalContext.Consumer data-spec="rate-plan-schedule-modal-consumer">
				{(context) =>
					React.Children.map(children, (child) =>
						React.cloneElement(child, { ...props, context }))}
			</RatePlanScheduleModalContext.Consumer>
		);
	}

RatePlanScheduleModalContextConsumer.propTypes = {
	children: PropTypes.any
};
