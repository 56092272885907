export default {
	// 过滤器
	CURRENCY: '币种',
	BILLABLE: '可计费',
	TAX_STATUS: '纳税状态',
	SUBSCRIBER_STATUS: '订户状态',
	MONTHLY_RATE_PLAN: '每月费率计划',
	STANDARD_RATE_PLAN: '标准费率计划',
	PREPAID_TERMS_CHARGED: '已收费的预付期',
	PLAN_TYPE: '计划类型',
	ZONE: '区',
	ROAMING_ZONE: '漫游区',
	GROUP_NAME: '分组名称',
	THE_PLAN_TYPE: '计划类型',
	ACTIVATION_TYPE: '激活类型',
	CHARGE_TYPE: '收费类型',
	TECH_TYPE: '技术类型',
	PRIMARY_PLACE: '主要位置',
	DISTRICT_COUNTY: '区县',
	DISTRICT_CITY: '区市',
	PRIMARY_PLACE_OF_USE: '主要使用位置',
	// notifications
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': '已成功更新用户设置',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': '无法更新用户设置',
	// invoices
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': '账户ID',
	'ANALYTICS_INVOICES.ADD_NOTE': '添加注释',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': '添加附件',
	'ANALYTICS_INVOICES.COUNTRY_NAME': '国家',
	'ANALYTICS_INVOICES.CUSTOMER_ID': '客户ID',
	'ANALYTICS_INVOICES.INVOICE_DATE': '账单日期',
	'ANALYTICS_INVOICES.DOCUMENT_ID': '文件ID',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': '子账户',
	'ANALYTICS_INVOICES.NOTES': '注释',
	'ANALYTICS_INVOICES.LINK': '账单',
	'ANALYTICS_INVOICES.BY': '开具人',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': '请在此处选择需要显示的状态',
	'ANALYTICS_INVOICES.NA': '不适用',
	'ANALYTICS_INVOICES.YES': '是',
	'ANALYTICS_INVOICES.NO': '否',
	'ANALYTICS_INVOICES.INVOICE_ID': '账单ID',
	'ANALYTICS_INVOICES.BILLING_CYCLE': '账单周期',
	'ANALYTICS_INVOICES.ACCOUNT_ID': '账户ID',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': '账户名称',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': '运营者账户ID',
	'ANALYTICS_INVOICES.DEVICES': '设备',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': '总收费',
	'ANALYTICS_INVOICES.CURRENCY': '币种',
	'ANALYTICS_INVOICES.DATA_VOLUME': '数据量（MB）',
	'ANALYTICS_INVOICES.SMS_VOLUME': '短信（SMS）量',
	'ANALYTICS_INVOICES.BILLABLE': '可计费（是或否）',
	'ANALYTICS_INVOICES.BILL': '可计费',
	'ANALYTICS_INVOICES.DATA_RATES': '数据费率',
	'ANALYTICS_INVOICES.DATE': '账单日期',
	'ANALYTICS_INVOICES.BILLING_DETAILS': '账单详情',
	'ANALYTICS_INVOICES.DUE_DATE': '到期日',
	'ANALYTICS_INVOICES.OPERATOR_ID': '运营者ID',
	'ANALYTICS_INVOICES.PUBLISHED': '已发布',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': '标准超额',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': '标准漫游',
	'ANALYTICS_INVOICES.TAXABLE': '应纳税账户',
	'ANALYTICS_INVOICES.TAX_STATUS': '纳税状态',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': '账户区段',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': '查看/打印/保存',
	'ANALYTICS_INVOICES.INVOICE_TITLE': '账单详情',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': '下载账单',
	'ANALYTICS_INVOICES.ENDPOINTS': '端点',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': '预付费订阅',
	'ANALYTICS_INVOICES.ADDONS': '附加',
	'ANALYTICS_INVOICES.RATE_PLANS': '费率计划',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': '费率计划区间',
	'ANALYTICS_INVOICES.BILLING_GROUPS': '账单分组',
	'ANALYTICS_INVOICES.DOWNLOAD': '下载',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': '下载PDF',
	'ANALYTICS_INVOICES.UPLOAD': '上载',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': '上载账单',
	'ANALYTICS_INVOICES.ACTIVATIONS': '激活',
	'ANALYTICS_INVOICES.DISCOUNTS': '折扣',
	'ANALYTICS_INVOICES.TAX': '税金',
	'ANALYTICS_INVOICES.SURCHARGES': '附加费',
	'ANALYTICS_INVOICES.TECH_TYPES': '技术类型',
	'ANALYTICS_INVOICES.OTHER_CHARGES': '其他收费',
	'ANALYTICS_INVOICES.NO_DATA': '无可用的账单',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': '目前没有可供显示的账单',
	'ANALYTICS_INVOICES.TITLE': '账单',
	'ANALYTICS_INVOICES.DATA_DETAILS': '数据详情',
	'ANALYTICS_INVOICES.SMS_DETAILS': '短信服务（SMS）详情',
	'ANALYTICS_INVOICES.TAXES': '税金',
	// endpoints table
	'ANALYTICS_INVOICES.ICCID': '集成线路卡识别符（ICCID）',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': '每月配额索引',
	'ANALYTICS_INVOICES.DEVICE_ID': '设备ID',
	'ANALYTICS_INVOICES.CUSTOMER': '客户',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': '每月费率计划',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': '已收费的预付期',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': '标准费率计划',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': '订户状态',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': '订阅收费',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': '固定汇总收费',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': '移动端始发短信（SMS MO）量',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': '移动端终止短信（SMS MT）量',
	// prepaid subscription table
	'ANALYTICS_INVOICES.MSISDN': '移动台国际订户目录号（MSISDN）',
	'ANALYTICS_INVOICES.RATE_PLAN': '费率计划',
	'ANALYTICS_INVOICES.PLAN_VERSION': '计划版本',
	'ANALYTICS_INVOICES.PLAN_TYPE': '计划类型',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': '用量配额索引',
	'ANALYTICS_INVOICES.TERM_START_DATE': '期限开始日期',
	'ANALYTICS_INVOICES.TERM_END_DATE': '期限终止日期',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': '包含的数据用量（MB）',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': '包含的移动端始发短信（SMS MO）（条）',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': '包含的移动端终止短信（SMS MT）（条）',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': '包含的语音（分：秒）',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': '包含的移动端始发（MO）语音（分：秒）',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': '包含的移动端终止（MT）语音（分：秒）',
	'ANALYTICS_INVOICES.INCLUDED_CSD': '包含的CSD（分：秒）',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': '包含的移动端始发CSD（分：秒）',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': '包含的移动端终止CSD（分：秒）',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': '本期至今数据用量（主要区间）',
	'ANALYTICS_INVOICES.VOICE_VOLUME': '语音量（分：秒）',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': '移动端始发（MO）语音量（分：秒）',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': '移动端终止（MT）语音量（分：秒）',
	// Addons Table
	'ANALYTICS_INVOICES.ADDON_SIZE': '附加大小（MB）',
	'ANALYTICS_INVOICES.ZONE': '区',
	'ANALYTICS_INVOICES.ADDON_CHARGES': '附加收费',
	'ANALYTICS_INVOICES.DATE_PURCHASED': '购买日期',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': '基础计划i应用于',
	// Rate Plan Table
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': '激活期承诺订阅',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': '使用中的订阅',
	'ANALYTICS_INVOICES.ACTIVE_TIER': '活跃等级',
	'ANALYTICS_INVOICES.DATA_CHARGE': '数据收费',
	'ANALYTICS_INVOICES.INCLUDED_DATA': '包含的数据',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': '包含的数据模式',
	'ANALYTICS_INVOICES.INCLUDED_SMS': '包含的短信服务（SMS）',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': '包含的移动端始发短信（SMS MO）模式',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': '包含的短信服务（SMS）模式',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': '包含的移动端终止短信（SMS MT）模式',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': '包含的移动端始发（MO）语音模式',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': '包含的语音模式',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': '激活期最低订阅数',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': '非漫游数据收费',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': '非漫游数据量',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': '付款类型',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': '预付费激活',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': '漫游数据收费',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': '漫游数据量',
	'ANALYTICS_INVOICES.SMS_CHARGE': '短信服务（SMS）收费',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': '订阅域',
	'ANALYTICS_INVOICES.VOICE_CHARGE': '语音收费',
	// Rate Plan Zone table
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': '使用中的超额费率',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': '包含的数据上限',
	'ANALYTICS_INVOICES.INCLUDED_MODE': '包含的模式',
	'ANALYTICS_INVOICES.PRIORITY': '优先级',
	'ANALYTICS_INVOICES.ROAMING_ZONE': '漫游区',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': '包含的总用量（MB）',
	//  Billing groups table
	'ANALYTICS_INVOICES.GROUP_NAME': '分组名称',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': '包含的总计',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': '移动端始发短信（SMS MO）收费',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': '包含的移动端始发短信（SMS MO）（条）',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': '包含的移动端始发短信（SMS MO）总用量',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': '包含的移动端始发（MO）语音总量',
	// Data details table
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': '每日舍入调整',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': '计划类型',
	// sms details table
	'ANALYTICS_INVOICES.MESSAGE_TYPE': '讯息类型',
	// activations table
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': '激活收费',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': '激活类型',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': '初始激活日期',
	// discount table
	'ANALYTICS_INVOICES.CHARGE_TYPE': '收费类型',
	'ANALYTICS_INVOICES.DISCOUNT': '折扣',
	// taxes table
	'ANALYTICS_INVOICES.PRIMARY_PLACE': '主要位置',
	'ANALYTICS_INVOICES.MRC': '每月重复收费（MRC）',
	'ANALYTICS_INVOICES.DATA': '数据',
	'ANALYTICS_INVOICES.SMS': '短信服务（SMS）',
	'ANALYTICS_INVOICES.VOICE': '语音',
	'ANALYTICS_INVOICES.ACTIVATION': '激活',
	'ANALYTICS_INVOICES.VPN': '虚拟专用网络（VPN）',
	'ANALYTICS_INVOICES.SIM': 'SIM卡',
	'ANALYTICS_INVOICES.MRF': 'MRF',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': '技术类型',
	// surcharges table
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': '主要使用位置',
	'ANALYTICS_INVOICES.FEDERAL': '联邦',
	'ANALYTICS_INVOICES.STATE': '州',
	'ANALYTICS_INVOICES.COUNTY': '县',
	'ANALYTICS_INVOICES.CITY': '市',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': '区县',
	'ANALYTICS_INVOICES.DISTRICT_CITY': '区市',
	// tech type table
	'ANALYTICS_INVOICES.PLAN_ID': '计划ID',
	'ANALYTICS_INVOICES.VERSION_ID': '版本ID',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': '2G设备数量',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': '2G技术类型收费',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': '3G设备数量',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': '3G技术类型收费',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': '4G设备数量',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': '4G技术类型收费',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': '技术类型收费频率',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': '技术类型总收费',

	// Subscription charges:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': '订阅收费',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': '订阅收费',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': '数据收费',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': '短信服务（SMS）收费',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': '激活收费',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': '技术类型收费',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': '固定折扣目标',
	'ANALYTICS_INVOICES_DETAILS.TAX': '税金',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': '总收费',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': '附加费',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': '到期日',

	// Endpoint stats:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': '端点状态',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': '端点',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': '使用中的订阅者',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': '数据量',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': '短信服务（SMS）量',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': '服务营收',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': '浮动折扣',

	// other charges table
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': '收费金额',
	'ANALYTICS_INVOICES.DESCRIPTION': '描述',
	'ANALYTICS_INVOICES.QUANTITY': '数量',
	'ANALYTICS_INVOICES.ORDER_NUMBER': '订单号',
	'ANALYTICS_INVOICES.REFERENCE_ID': '参考ID',
	//  placeholderS
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': '在此处输入您的注释',
	'ANALYTICS_INVOICES.REMOVE': '移除',
	'ANALYTICS_INVOICES.EDIT_NOTE': '编辑注释',
	'ANALYTICS_INVOICES.CANCEL': '取消',
	'ANALYTICS_INVOICES.SUBMIT': '提交',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': '已添加附件',
	//  edit table modal
	'ANALYTICS_INVOICES.APPLY_CHANGE': '应用更改',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': '恢复默认',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': '编辑表格参数',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': '至少选择应用于此表格的2个项目。',
	'ANALYTICS_INVOICES.OF': '共',
	'ANALYTICS_INVOICES.SELECTED': '已选择',
	// invoice details
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': '无可用的数据',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': '选择的账单没有数据。',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': '账单详情数据库中没有关于此账单的记录',
	'ANALYTICS_INVOICES.ADD_FILES': '账单文件',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': '查看/添加/编辑',
	//  ATTACHMENTS MODAL
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': '账单附件',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': '编辑附件',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': '上载文件',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': '删除附件',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': '关闭',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': '取消',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': '保存',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': '删除',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': '上载文件',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': '上载附件',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': '上载账单',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': '文件名',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': '描述',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': '您即将从MC中移除附件。本操作将从系统中永久删除该文件。您确定要继续吗？',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': '将要删除的文件：',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': '已附加的文件',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': '文件名（如留空则使用默认文件名）',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': '对客户可见的文件描述（选填）',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': '此账单将在未来几周一直有效。',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': '拖放文件或点击上载',
	'INVOICES_NOTES_UPDATE.SUCCESS': '已成功更新账单注释。',
	//  NOTES MODAL
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': '已成功更新',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': '已成功添加您的注释。',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': '更新失败',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': '已成功添加您的注释。',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': '已成功上载',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': '已成功上载文件。',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': '上载时出错',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': '将此文件上载至MC时出现错误。',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': '已删除附件',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': '已从MC系统中移除此文件。',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': '删除时出错',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': '将此文件从MC中移除时出现错误。',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': '账单日期',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': '账单周期无效，因此无法显示账单详情。',
	// ADD NEW INVOICE
	'INVOICES.ADD_NEW_INVOICE': '添加新账单',
	'INVOICES.CANCEL': '取消',
	'INVOICES.ADD_INVOICE': '添加账单',
	'INVOICES.EDIT': '编辑',
	'INVOICES.CONFIRM': '确认',
	'INVOICES.CONFIRMATION': '确认',
	'INVOICES.INVOICE_DETAILS': '账单详情',
	'ADD_INVOICE.INVOICE_ID': '账单ID',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': '选择机对机（M2M）账户ID',
	'ADD_INVOICE.INVOICE_DATE': '账单日期',
	'ADD_INVOICE.BILLING_CYCLE': '账单周期',
	'ADD_INVOICE.DATE_PLACEHOLDER': '选择日期',
	'ADD_INVOICE.UPLOAD_FILE': '上载文件',
	'ADD_INVOICE.UPLOAD_FILE_NAME': '文件名（选填：如留空则使用默认文件名）',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': '文件名（最长可输入255个字符）',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': '文件名',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': '您已经成功添加新账单。',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': '添加账单时发生错误。请稍后重试。',
	'ADD_INVOICE.TIME_STAMP': '附件日期为{date}，时间为{time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION': '文件名无效（请使用下划线“_”代替空格，下列字符不可出现在文件名中：! ? <> % $ () + = ',
};
