import { connect } from 'react-redux';

import {
	cancelRegistration,
	getDiagnosticCcipInit,
	getDiagnosticCcip,
} from '../../redux/actions';
import {
	cancelRegistrationRequest,
	getDiagnosticCcipData,
	isGetCcipFetching,
	isGetCcipSuccess,
} from '../../redux/selectors';
import DiagnosticCcip from './DiagnosticCcip';

const mapStateToProps = (state) => (
	{
		isFetching: isGetCcipFetching(state),
		isCanceling: cancelRegistrationRequest(state),
		isSuccess: isGetCcipSuccess(state),
		data: getDiagnosticCcipData(state),
	}
);
const mapDispatchToProps = (dispatch) => (
	{
		getDiagnosticCcip: (iccid) => dispatch(getDiagnosticCcip(iccid)),
		setDiagnosticCcipStateToInit: () => dispatch(getDiagnosticCcipInit()),
		cancelRegistration: (iccid) => dispatch(cancelRegistration(iccid)),
	}
);

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticCcip);
