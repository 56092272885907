/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { union, uniqBy } from 'lodash';
import FilterList from './FilterList';
import common from './EditTableModal.scss';
import { tableHeaderPermissionsCheck } from '../../../../utils/constants';

class EditTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: [],
			original: []
		};
	}

	componentDidMount() {
		const { hasOriginal, user, allColumns, columns } = this.props;
		const items = [];
		const allItems = allColumns;
		let columnsList = [];
		let allColumnsList = [];
		columns.forEach((item) => {
			if (item.checked !== false && item.name) {
				columnsList = [
					...columnsList,
					{
						title: item.title,
						name: item.name,
						checked: true,
						order: items.order
					}
				];
			}
		});
		if (!columns) {
			allItems
				.filter((item) => item.name)
				.forEach((item) => {
					allColumnsList = [
						...allColumnsList,
						{
							title: item.title,
							name: item.name,
							checked: true
						}
					];
				});
		} else {
			allItems
				.filter((item) => item.name)
				.forEach((item) => {
					allColumnsList = [
						...allColumnsList,
						{
							title: item.title,
							name: item.name,
							checked: false
						}
					];
				});
		}

		const allData = union(columnsList, allColumnsList);
		let headers = uniqBy(allData, 'name');
		headers = tableHeaderPermissionsCheck(headers, user, 'invoices');
		columnsList = tableHeaderPermissionsCheck(columnsList, user, 'invoices');

		if (hasOriginal && hasOriginal.length > 0) {
			this.setState({
				headers,
				original: columnsList
			});
		} else {
			this.setState({
				headers,
				original: []
			});
		}
	}

	onSubmitColumns = (newValues, oldValues) => {
		this.setState({
			newValues,
			oldValues
		});
	};

	changeList = (e, index) => {
		const { headers, original } = this.state;
		const newHeaders = headers.map((item, i) => {
			if (i === index) {
				return {
					title: item.title,
					name: item.name,
					checked: !item.checked,
					order: item.value ? item.value : index
				};
			}
			return item;
		});
		this.setState(
			{
				headers: newHeaders
			},
			() => {
				this.submitColumns(newHeaders, original);
			}
		);
	};

	submitColumns = (newColumns, oldColumns) => {
		const { onSubmitColumns, submitColumnsDisabled } = this.props;
		let newValues = [];
		let oldValues = [];
		newColumns.forEach((item, index) => {
			if (item.checked === true) {
				newValues = [
					...newValues,
					{
						title: item.title,
						name: newColumns[index].name,
						order: index
					}
				];
			}
		});
		if (oldColumns.length > 0) {
			oldColumns.forEach((item, index) => {
				if (item.checked === true) {
					oldValues = [
						...oldValues,
						{
							title: item.title,
							name: oldColumns[index].name,
							order: index + 1
						}
					];
				}
			});
		} else {
			oldValues = [];
		}
		onSubmitColumns(newValues, oldValues);
		submitColumnsDisabled(newValues, oldValues);
	};

	renderFilterList = (headers, customNames) => {
		const { excludeColumns } = this.props;
		return (
			<div data-spec="title">
				{headers.map((item, i) => {
					if (excludeColumns) {
						if (excludeColumns.includes(item.name)) {
							return null;
						}
					}

					return (
						<FilterList
							data-spec
							customNames={customNames}
							{...item}
							key={item.name}
							onClick={(e) => this.changeList(e, i)}
						/>
					);
				})}
			</div>
		);
	};

	render() {
		const { excludeColumns } = this.props;
		const { headers } = this.state;
		const headersNumber = excludeColumns
			? headers.filter((item) => !excludeColumns.includes(item.name))
			: headers;

		const customNames = [];

		return (
			<div data-spec="edit-table-columns">
				<div className={common.title}>
					<FormattedMessage
						id="ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2"
						defaultMessage="Select minimum of 2 to apply to the table. "
					/>
					<span className={common.number_of_columns}>
						{headersNumber.filter((item) => item.checked === true).length}
					</span>
					<FormattedMessage id="ANALYTICS_INVOICES.OF" defaultMessage=" of " />
					<span className={common.number_of_columns}>
						{headersNumber.length}
					</span>
					<FormattedMessage
						id="ANALYTICS_INVOICES.SELECTED"
						defaultMessage=" selected"
					/>
				</div>
				{this.renderFilterList(headers, customNames)}
			</div>
		);
	}
}

const { array, object, func, string } = PropTypes;

EditTable.propTypes = {
	columns: array,
	allColumns: array,
	onSubmitColumns: func,
	hasOriginal: array,
	submitColumnsDisabled: func,
	activeTab: string,
	user: object,
	excludeColumns: array
};

export default EditTable;
