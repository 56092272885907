import { handleActions } from 'redux-actions';

import { OPEN_ADD_CHART_MODAL, CLOSE_ADD_CHART_MODAL } from './constants';

export const initialState = {
	isOpened: false
};

export default handleActions(
	{
		[OPEN_ADD_CHART_MODAL]: (state) => ({
				...state,
				isOpened: true
			}),
		[CLOSE_ADD_CHART_MODAL]: (state) => ({
				...state,
				isOpened: false
			})
	},
	initialState
);
