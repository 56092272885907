import globalAction, { actionInit } from '../../../redux/global/globalAction';
import {
	getAutomationActions as serviceActions,
	getAutomationAlerts as serviceAlerts,
	getAutomationAlertsFilters as serviceAlertsFilters,
	getAutomationCategories as serviceCategories,
	getAutomationCountries as serviceCountries,
	getAutomationFrames as serviceFrames,
	getAutomationPlatform as servicePlatform,
	getAutomationPlatforms as servicePlatforms,
	getAutomationPool as servicePool,
	getAutomationRule as serviceGetRule,
	getAutomationRules as serviceRules,
	getAutomationRulesFilters as serviceRulesFilters,
	getAutomationSchedulers as serviceSchedulers,
	getAutomationTriggers as serviceTriggers,
	getAutomationTriggerSubcategories as serviceTriggerSubCategories,
	getAutomationZoneModel as serviceZoneModel,
	getAutomationZoneName as serviceZoneName,
	patchAutomationRule as serviceEditRule,
	postAutomationRule as serviceAddRule,
	removeAutomationRule as serviceRemoveRule,
	removeAutomationAlert as serviceRemoveAlert,
	getAutomationRatePlans as serviceRatePlans,
	getAutomationPrepaidRatePlans as servicePrepaidRatePlans,
	getAutomationNetworkEntitlements as serviceNetworkEntitlements,
	getAutomationAvailableStates as serviceAvailableStates,
	activateRule as serviceActivateRule,
	setRuleReceived as serviceSetRuleReceived,
	setRuleImplemented as serviceSetRuleImplemented,
	setRuleConflicted as serviceSetRuleConflicted,
	requestForDeletion as serviceRequestForDeletion,
	getRuleHistory as serviceGetRuleHistory,
	getRuleNotes as serviceGetRuleNotes,
	getRuleAutomationZones as servicegetRuleAutomationZones
} from '../services/AutomationService';
import {
	ADD_AUTOMATION_RULE,
	EDIT_AUTOMATION_RULE,
	GET_AUTOMATION_ALERTS,
	GET_AUTOMATION_ALERTS_FILTERS,
	GET_AUTOMATION_CATEGORIES,
	GET_AUTOMATION_COUNTRIES,
	GET_AUTOMATION_FRAMES,
	GET_AUTOMATION_OPTIONS,
	GET_AUTOMATION_PLATFORM,
	GET_AUTOMATION_PLATFORMS,
	GET_AUTOMATION_POOL,
	GET_AUTOMATION_RULE,
	GET_AUTOMATION_RULES,
	GET_AUTOMATION_RULES_ACTIONS,
	GET_AUTOMATION_RULES_CHILD_ACTIONS,
	GET_AUTOMATION_RULES_FILTERS,
	GET_AUTOMATION_SCHEDULERS,
	GET_AUTOMATION_TRIGGERS,
	GET_AUTOMATION_TRIGGERS_SUBCATEGORIES,
	GET_AUTOMATION_ZONE_MODEL,
	GET_AUTOMATION_ZONE_NAME,
	REMOVE_AUTOMATION_RULE,
	GET_AUTOMATION_RATE_PLANS,
	GET_AUTOMATION_PREPAID_RATE_PLANS,
	GET_AUTOMATION_NETWORK_ENTITLEMENTS,
	GET_AUTOMATION_AVAILABLE_STATES,
	REMOVE_AUTOMATION_ALERT,
	ACTIVATE_RULE,
	SET_RULE_STATE_RECEIVED,
	SET_RULE_STATE_IMPLEMENTED,
	SET_RULE_STATE_CONFLICTED,
	REQUEST_FOR_DELETION,
	GET_RULE_HISTORY,
	GET_RULE_NOTES,
	GET_RULE_AUTOMATION_ZONES
} from './constants';

export const getAutomationAlertsFiltersInit = () =>
	actionInit(GET_AUTOMATION_ALERTS_FILTERS);

export const getAutomationAlertsFilters = () =>
	globalAction(GET_AUTOMATION_ALERTS_FILTERS, serviceAlertsFilters);

export const getAutomationRulesFiltersInit = () =>
	actionInit(GET_AUTOMATION_RULES_FILTERS);

export const getAutomationRulesFilters = () =>
	globalAction(GET_AUTOMATION_RULES_FILTERS, serviceRulesFilters);

export const getAutomationAlerts = (params) =>
	globalAction(GET_AUTOMATION_ALERTS, serviceAlerts, params);

export const removeAutomationAlert = (params) =>
	globalAction(REMOVE_AUTOMATION_ALERT, serviceRemoveAlert, params, {
		notify: 'remove-acc-rule',
		notifyMessageSuccess: 'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED',
		notifyMessageError: 'AUTOMATION.AUTOMATION_DELETE_ERROR',
		init: true
	});

export const getAutomationRules = (params) =>
	globalAction(GET_AUTOMATION_RULES, serviceRules, params);

export const getAutomationRule = (id, params) =>
	globalAction(GET_AUTOMATION_RULE, serviceGetRule, { id, params });

export const getAutomationRuleInit = () => actionInit(GET_AUTOMATION_RULE);

export const removeAutomationRule = (id) =>
	globalAction(REMOVE_AUTOMATION_RULE, serviceRemoveRule, id, {
		notify: 'remove-acc-rule',
		notifyMessageSuccess: 'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED',
		notifyMessageError: 'AUTOMATION.AUTOMATION_DELETE_ERROR'
	});

export const removeAutomationRuleInit = () =>
	actionInit(REMOVE_AUTOMATION_RULE);

export const addAutomationRule = (data) =>
	globalAction(ADD_AUTOMATION_RULE, serviceAddRule, data, {
		notify: 'add-rule',
		notifyMessageSuccess: 'AUTOMATION.ADD_RULE_SUCCESS',
		notifyMessageError: 'AUTOMATION.ADD_RULE_ERROR',
		redirect: '/automation'
	});

export const editAutomationRule = (data, id, manualRuleSuccessMessageId) =>
	globalAction(
		EDIT_AUTOMATION_RULE,
		serviceEditRule,
		{ data, id },
		{
			notify: 'edit-rule',
			notifyMessageSuccess:
				manualRuleSuccessMessageId || 'AUTOMATION.EDIT_RULE_SUCCESS',
			notifyMessageError: 'AUTOMATION.EDIT_RULE_ERROR',
			redirect: '/automation'
		}
	);

export const getAutomationActions = (params) =>
	globalAction(GET_AUTOMATION_RULES_ACTIONS, serviceActions, params);

export const getAutomationChildActions = (params) =>
	globalAction(GET_AUTOMATION_RULES_CHILD_ACTIONS, serviceActions, params);

export const getAutomationCountries = () =>
	globalAction(GET_AUTOMATION_COUNTRIES, serviceCountries);

export const getAutomationOptionsInit = () =>
	actionInit(GET_AUTOMATION_OPTIONS);

export const getAutomationPlatforms = () =>
	globalAction(GET_AUTOMATION_PLATFORMS, servicePlatforms);

export const getAutomationCategories = (id) =>
	globalAction(GET_AUTOMATION_CATEGORIES, serviceCategories, id);

export const getAutomationTriggers = (id) =>
	globalAction(GET_AUTOMATION_TRIGGERS, serviceTriggers, id);

export const getAutomationTriggerSubcategories = (id) =>
	globalAction(
		GET_AUTOMATION_TRIGGERS_SUBCATEGORIES,
		serviceTriggerSubCategories,
		id
	);

export const getAutomationFrames = (params) =>
	globalAction(GET_AUTOMATION_FRAMES, serviceFrames, params);

export const getAutomationPlatformInit = () =>
	actionInit(GET_AUTOMATION_PLATFORM);

export const getAutomationPlatform = (platform) =>
	globalAction(GET_AUTOMATION_PLATFORM, servicePlatform, platform);

export const getAutomationPool = (params) =>
	globalAction(GET_AUTOMATION_POOL, servicePool, params);

export const getAutomationSchedulers = (params) =>
	globalAction(GET_AUTOMATION_SCHEDULERS, serviceSchedulers, params);

export const getAutomationZoneModel = () =>
	globalAction(GET_AUTOMATION_ZONE_MODEL, serviceZoneModel);

export const getAutomationZoneName = (params) =>
	globalAction(GET_AUTOMATION_ZONE_NAME, serviceZoneName, params);

export const getAutomationRatePlans = (params) =>
	globalAction(GET_AUTOMATION_RATE_PLANS, serviceRatePlans, params);

export const getAutomationPrepaidRatePlans = (params) =>
	globalAction(
		GET_AUTOMATION_PREPAID_RATE_PLANS,
		servicePrepaidRatePlans,
		params
	);

export const getAutomationNetworkEntitlements = (params) =>
	globalAction(
		GET_AUTOMATION_NETWORK_ENTITLEMENTS,
		serviceNetworkEntitlements,
		params
	);

export const getAutomationAvailableStates = (params) =>
	globalAction(GET_AUTOMATION_AVAILABLE_STATES, serviceAvailableStates, params);

export const activateRule = (id, activeStatus, notifOptions) =>
	globalAction(
		ACTIVATE_RULE,
		serviceActivateRule,
		{ id, activeStatus },
		{
			notify: notifOptions.id,
			notifyMessageSuccess: notifOptions.successMessageId,
			notifyMessageError: notifOptions.errorMessageId,
			redirect: '/automation'
		}
	);

// RULE STATE CHANGES
// TO DO NOTIFICATION
export const requestForDeletion = (id) =>
	globalAction(REQUEST_FOR_DELETION, serviceRequestForDeletion, id, {
		notify: 'rule-request-for-deletion',
		notifyMessageSuccess: 'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS',
		notifyMessageError: 'AUTOMATION.REQUEST_FOR_DELETION_FAIL',
		redirect: '/automation'
	});

export const setRuleReceived = (data) =>
	globalAction(SET_RULE_STATE_RECEIVED, serviceSetRuleReceived, data, {
		notify: 'set-rule-received',
		notifyMessageSuccess: 'AUTOMATION.SET_RULE_RECEIVED_SUCCESS',
		notifyMessageError: 'AUTOMATION.SET_RULE_STATE_FAIL',
		redirect: '/automation'
	});

export const setRuleImplemented = (data) =>
	globalAction(SET_RULE_STATE_IMPLEMENTED, serviceSetRuleImplemented, data, {
		notify: 'set-rule-implemented',
		notifyMessageSuccess: 'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS',
		notifyMessageError: 'AUTOMATION.SET_RULE_STATE_FAIL',
		redirect: '/automation'
	});

export const setRuleConflicted = (data) =>
	globalAction(SET_RULE_STATE_CONFLICTED, serviceSetRuleConflicted, data, {
		notify: 'set-rule-conflicted',
		notifyMessageSuccess: 'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS',
		notifyMessageError: 'AUTOMATION.SET_RULE_STATE_FAIL',
		redirect: '/automation'
	});

export const getRuleHistory = (data) =>
	globalAction(GET_RULE_HISTORY, serviceGetRuleHistory, data);

export const getRuleNotes = (data) =>
	globalAction(GET_RULE_NOTES, serviceGetRuleNotes, data);

export const getRuleAutomationZones = (params) =>
	globalAction(GET_RULE_AUTOMATION_ZONES, servicegetRuleAutomationZones, params);
