import { handleActions } from 'redux-actions';

import { Status } from '../../../../utils/constants';

import {
	EXPORT_CHART_DATA_CSV_REQUEST,
	EXPORT_CHART_DATA_CSV_SUCCESS,
	EXPORT_CHART_DATA_CSV_FAIL
} from './constants';

export const initialState = {
	status: Status.INIT,
	error: null
};

export default handleActions(
	{
		[EXPORT_CHART_DATA_CSV_REQUEST]: (state, action) => ({
			...state,
			status: { [action.payload.chartId]: Status.PENDING },
			error: null
		}),
		[EXPORT_CHART_DATA_CSV_SUCCESS]: (state, action) => ({
			...state,
			status: { [action.payload.chartId]: Status.DONE },
			error: null
		}),
		[EXPORT_CHART_DATA_CSV_FAIL]: (state, action) => ({
			...state,
			status: { [action.payload.chartId]: Status.FAIL },
			error: action.payload
		})
	},
	initialState
);
