import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Notifications from '../../../../lib/DigitalComponents/Notifications';
import { notificationsTitle } from '../../../../utils/constants';

const PageTitle = () => (WrappedComponent) => {
	class PageTitleComponent extends Component {
		goToReleaseNotes = () => {
			const { goToReleaseNotes, user } = this.props;
      const inStandardMode = sessionStorage.getItem('company');

      if (user.systemUser && !inStandardMode) {
				goToReleaseNotes('/release-notes');
      } else {
        goToReleaseNotes('/support/release-notes');
      }
		};

		notificationAction = (item) => {
			if (item.notificationTypeId === 5) {
				this.goToReleaseNotes();
			} else this.saveToLocalStorage(item.notificationId, item.updatedAt);
		};

		actionText = (id) => {
			if (id === 5) {
				return (
					<span data-spec="release-notes-notification-title">
						<FormattedMessage
							id="RELEASE_NOTES"
							defaultMessage="Release Notes"
						/>
					</span>
				);
			}
			return (
				<span data-spec="mark-read">
					<FormattedMessage id="MARK_READ" defaultMessage="Mark read" />
				</span>
			);
		};

		message = (item) => {
			if (item.notificationTypeId === 5) {
				return `Release ${item.note} is out. For detailed release notes, visit `;
			}
			return item.note;
		};

		saveToLocalStorage(id, date) {
			const { user, removeNotification } = this.props;
			const currentUser = user ? user.uid : '';
      removeNotification(id);
			const savedNotifications = localStorage.getItem(
				`mncnotifications_${currentUser}`
			);
			let test = {
				ids: []
			};
			if (!savedNotifications) {
				test = {
					...test,
					ids: [...test.ids, `${id}-${date}`]
				};
				localStorage.setItem(
					`mncnotifications_${currentUser}`,
					JSON.stringify(test)
				);
			} else {
				let parsedNotification = JSON.parse(savedNotifications);
				if (parsedNotification.ids.indexOf(`${id}-${date}`) === -1) {
					parsedNotification = {
						...parsedNotification,
						ids: [...parsedNotification.ids, `${id}-${date}`]
					};
				}
				localStorage.setItem(
					`mncnotifications_${currentUser}`,
					JSON.stringify(parsedNotification)
				);
			}
		}

		renderErrors = (errors) =>
			errors.map((error) => {
				const { messages } = this.props;
				return messages[error.code] || error.message;
			});

		renderAdminNotifications = () => {
			const { user, activeNotifications } = this.props;
			const currentUser = user ? user.uid : '';
			const savedNotifications = localStorage.getItem(
				`mncnotifications_${currentUser}`
			)
				? JSON.parse(localStorage.getItem(`mncnotifications_${currentUser}`))
				: null;
			let temp = [];
			if (savedNotifications) {
				temp =
					(activeNotifications &&
						activeNotifications.resultList &&
						activeNotifications.resultList.length > 0 &&
						activeNotifications.resultList.filter(
							(item) =>
								savedNotifications.ids.indexOf(
									`${item.notificationId}-${item.updatedAt}`
								) === -1
						)) ||
					[];
			} else {
				temp =
					activeNotifications && activeNotifications.resultList.length > 0
						? activeNotifications.resultList
						: [];
			}

			return temp.map((item) => (
				<Notifications
					data-spec="notification"
					key={item.id}
					id={item.id}
					type={item.notificationTypeId}
					title={notificationsTitle[item.notificationTypeId].name}
					message={this.message(item)}
					action={() => this.notificationAction(item)}
					actionText={this.actionText(item.notificationTypeId)}
					notificationType={'admin'}
					close={item.notificationTypeId === 5 && true}
					handleClose={() => {
						item.notificationTypeId === 5 &&
							this.saveToLocalStorage(item.notificationId, item.updatedAt);
					}}
				/>
			));
		};

		renderNotifications = () => {
			const {
				messages,
				notifications,
				renderErrorNotifications,
				hideNotification,
				errors
			} = this.props;

			let filteredNotifications = notifications;
			if (!renderErrorNotifications) {
				filteredNotifications = notifications.filter(
					(notification) => notification.id !== '400-get-error'
				);
			}

			return filteredNotifications.map((item) => (
				<Notifications
					data-spec="notification"
					key={item.id}
					id={item.id}
					type={item.type}
					title={item.title}
					message={item.message}
					notificationType={item.notificationType}
					close={item.close}
					handleDismiss={() => hideNotification(item.id)}
					messages={messages}
					duration={item.duration}
					action={item.action}
					actionText={item.actionText}
					errors={errors ? this.renderErrors(errors) : undefined}
					handleClose={item.handleClose}
				/>
			));
		};

		render() {
			return (
				<div data-spec="page-title">
					<WrappedComponent
						{...this.state}
						{...this.props}
						renderNotifications={this.renderNotifications}
						renderAdminNotifications={this.renderAdminNotifications}
					/>
				</div>
			);
		}
	}

	const {
		func,
		bool,
		objectOf,
		string,
		shape,
		arrayOf,
		object,
		array
	} = PropTypes;

	PageTitleComponent.propTypes = {
		pushBack: func,
		hideNotification: func,
		errors: arrayOf(object),
		messages: objectOf(string),
		notifications: arrayOf(object),
		renderErrorNotifications: bool,
		activeNotifications: shape({
			resultList: array
		}),
		user: shape(),
		removeNotification: func,
		goToReleaseNotes: func
	};

	PageTitleComponent.defaultProps = {
		messages: {},
		notifications: [],
		errors: [],
		renderErrorNotifications: true
	};
	return PageTitleComponent;
};

export default PageTitle;
