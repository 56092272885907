import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

import Modal from '../../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../../lib/DigitalComponents/Button';
import styles from './RemoveCompanyContactModal.scss';

const Icon = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

class RemoveCompanyContactModal extends PureComponent {
	render() {
		const { show, onClose, confirm } = this.props;

		return (
			<Modal data-spec="add-users-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.REMOVE_CONTACT"
							defaultMessage="Remove Contact"
						/>
					}
					closeModal={onClose}
				/>
				<form>
				<ModalBody className={styles.modal__body}>
					<div data-spec="body-message" className={styles.body}>
						<div className={styles.modal_message}>
							<FormattedMessage
								id="ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION"
								defaultMessage="Are you sure you want to perform this action?"
							/>
						</div>
					</div>
					<div>
						<Icon />
					</div>
				</ModalBody>
				</form>

				<ModalFooter>
					<Button
						dataSpec={'close-button'}
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.NO"
								defaultMessage="No"
							/>
						}
						onClick={onClose}
						type="button"
					/>
					<Button
						type="submit"
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.YES"
								defaultMessage="Yes"
							/>
						}
						dataSpec="submit-button"
						onClick={confirm}
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func } = PropTypes;

RemoveCompanyContactModal.propTypes = {
	onClose: func,
	show: bool,
	confirm: func
};

RemoveCompanyContactModal.defaultProps = {
	show: false
};

export default RemoveCompanyContactModal;
