import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

const NotSupported = (styles, Image) =>
	class EnhancedComponent extends PureComponent {
		render() {
			return (
				<div data-spec="not-supported" className={styles.wrapper}>
					<Image />
					<div className={styles.message} id="message">
						<h1>
							<FormattedMessage id="RESOLUTION_NOT_SUPPORTED_FIRST_LINE" />
						</h1>
						<br />
						<h2>
							<FormattedMessage id="RESOLUTION_NOT_SUPPORTED_SECOND_LINE" />
						</h2>
					</div>
				</div>
			);
		}
	};

export default NotSupported;
