import { Status } from '../../../../utils/constants';

/* ADD NOTIFICATION */
export const addNotificationRequest = (state) =>
	state.support.notifications.addNotification.status === Status.PENDING;
export const addNotificationFail = (state) =>
	state.support.notifications.addNotification.status === Status.FAIL;
export const addNotificationSuccess = (state) =>
	state.support.notifications.addNotification.status === Status.DONE;

/* EDIT NOTIFICATIONS */
export const editNotificationRequest = (state) =>
	state.support.notifications.editNotification.status === Status.PENDING;
export const editNotificationFail = (state) =>
	state.support.notifications.editNotification.status === Status.FAIL;
export const editNotificationSuccess = (state) =>
	state.support.notifications.editNotification.status === Status.DONE;

/* GET ACTIVE NOTIFICATIONS */
export const getActiveNotificationsRequest = (state) =>
	state.support.notifications.getActiveNotifications.status === Status.PENDING;
export const getActiveNotificationsFail = (state) =>
	state.support.notifications.getActiveNotifications.status === Status.FAIL;
export const getActiveNotificationsSuccess = (state) =>
	state.support.notifications.getActiveNotifications.data;

/* GET NOTIFICATION */
export const getNotificationRequest = (state) =>
	state.support.notifications.getNotification.status === Status.PENDING;
export const getNotificationFail = (state) =>
	state.support.notifications.getNotification.status === Status.FAIL;
export const getNotificationSuccess = (state) =>
	state.support.notifications.getNotification.data;

/* GET NOTIFICATIONS */
export const getNotificationsRequest = (state) =>
	state.support.notifications.getNotifications.status === Status.PENDING;
export const getNotificationsFail = (state) =>
	state.support.notifications.getNotifications.status === Status.FAIL;
export const getNotificationsSuccess = (state) =>
	state.support.notifications.getNotifications.data;

/* GET NOTIFICATIONS TYPES */
export const getNotificationsTypesRequest = (state) =>
	state.support.notifications.getNotificationsTypes.status === Status.PENDING;
export const getNotificationsTypesFail = (state) =>
	state.support.notifications.getNotificationsTypes.status === Status.FAIL;
export const getNotificationsTypesSuccess = (state) =>
	state.support.notifications.getNotificationsTypes.data;

/* GET NOTIFICATIONS GROUPS */
export const getNotificationsGroupsRequest = (state) =>
	state.support.notifications.getNotificationsGroups.status === Status.PENDING;
export const getNotificationsGroupsFail = (state) =>
	state.support.notifications.getNotificationsGroups.status === Status.FAIL;
export const getNotificationsGroupsSuccess = (state) =>
	state.support.notifications.getNotificationsGroups.data;

/* REMOVE NOTIFICATION */
export const removeNotificationRequest = (state) =>
	state.support.notifications.removeNotification.status === Status.PENDING;
export const removeNotificationFail = (state) =>
	state.support.notifications.removeNotification.status === Status.FAIL;
export const removeNotificationSuccess = (state) =>
	state.support.notifications.removeNotification.status === Status.DONE;

/* ADD NOTIFICATION MODAL */
export const isAddNotificationModalOpen = (state) =>
	state.support.notifications.addNotificationModal.isOpen;
