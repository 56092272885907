import React, { PureComponent } from 'react';
import { string, func, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PermissionsView from '../../views/PermissionsView';
import PageTitle from '../../../Shared/views/PageTitleView';
import ActionBar from '../../../Shared/views/ActionBarView';
import Button from '../../../../lib/DigitalComponents/Button';

export default class UserIndividualPermissions extends PureComponent {
	pushBack = () => {
		const {
			system,
			match: {
				params: { id }
			},
			myProfile,
			push
		} = this.props;
		if (system) {
			push(`/access-management/users/${id}`);
		} else if (myProfile) {
			push('/my-profile');
		} else {
			push(`/user/${id}`);
		}
	};

	goToEditUserGroupPermissions = () => {
		const {
			push,
			match: {
				params: { id, userGroupId }
			},
			system
		} = this.props;

		let url;
		if (!system) {
			url = `/user/${id}/user-groups/${userGroupId}/individual-permissions/edit`;
		}
		if (system) {
			url = `/access-management/users/${id}/user-groups/${userGroupId}/individual-permissions/edit`;
		}
		push(url);
	};

	render() {
		const {
			match: {
				params: { id }
			},
			myProfile,
			permissionsRequest
		} = this.props;

		return (
			<div data-spec="user-invividual-permissions">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.INDIVIDUAL_PERMISSIONS"
							defaultMessage="Individual Permissions"
						/>
					}
					pushBack={this.pushBack}
				/>
				<ActionBar
					actions={
						<Button
							label={
								<FormattedMessage
									id="ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS"
									defaultMessage="Edit Individual Permissions"
								/>
							}
							disabled={permissionsRequest || myProfile}
							dataSpec="edit-group-permissions-button"
							variant="primary"
							onClick={this.goToEditUserGroupPermissions}
						/>
					}
				/>
				<PermissionsView {...this.props} id={id} myProfile={myProfile} />
			</div>
		);
	}
}

UserIndividualPermissions.propTypes = {
	match: object,
	id: string,
	getPermissions: func,
	permissionsRequest: bool,
	permissions: object,
	push: func,
	system: bool,
	myProfile: bool,
	history: object
};
