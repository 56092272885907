import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import AddNotification from './AddNotification';
import {
	getNotificationRequest,
	getNotificationSuccess,
	removeNotificationRequest,
	addNotificationRequest
} from '../../redux/notifications/selectors';

import {
	getUser,
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	getNotificationRequest: getNotificationRequest(state),
	notification: getNotificationSuccess(state),
	removeNotificationRequest: removeNotificationRequest(state),
	addNotificationRequest: addNotificationRequest(state),
	formData: getFormValues('AddNotificationForm')(state),
	user: getUser(state),
});

export default connect(mapStateToProps)(AddNotification);
