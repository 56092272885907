import React from 'react';
import PropTypes from 'prop-types';

import { Desktop, Tablet, Mobile } from '../../layouts/MainLayout';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../utils/CustomMediaQuery';

const MainLayoutView = ({
	location,
	messages,
	children,
	withoutSidebar,
	language
}) => (
	<>
		<DesktopMediaQuery>
			<Desktop
				location={location}
				messages={messages}
				withoutSidebar={withoutSidebar}
				language={language}
			>
				{children}
			</Desktop>
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Tablet
				location={location}
				messages={messages}
				withoutSidebar={withoutSidebar}
				language={language}
			>
				{children}
			</Tablet>
		</TabletMediaQuery>
		<MobileMediaQuery>
			<Mobile
				location={location}
				messages={messages}
				withoutSidebar={withoutSidebar}
				language={language}
			>
				{children}
			</Mobile>
		</MobileMediaQuery>
	</>
);

const { objectOf, string, element, bool } = PropTypes;

MainLayoutView.propTypes = {
	children: element,
	location: objectOf(string),
	messages: objectOf(string),
  withoutSidebar: bool,
  language: string
};

MainLayoutView.defaultProps = {
	children: null,
	location: {},
	messages: {},
	withoutSidebar: false
};

export default MainLayoutView;
