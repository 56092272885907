import globalAction from '../../../../redux/global/globalAction';

import {
	GET_PENDING_REGISTRATIONS,
	CANCEL_USER_PENDING_INVITATION,
	RESEND_USER_INVITATION
} from './constants';

import {
	getPendingRegistrations as getRegistrationService,
	cancelRegistration as cancelRegistrationService,
	resendRegistration as resendRegistrationService
} from '../../services/RegistrationService';

/* ******************** GET PENDING INVITATIONS ******************** */
export const getRegistrations = (params) =>
	globalAction(GET_PENDING_REGISTRATIONS, getRegistrationService, params);

/* ******************** CANCEL USER PENDING INVITATION ******************** */
export const cancelRegistration = (params) =>
	globalAction(
		CANCEL_USER_PENDING_INVITATION,
		cancelRegistrationService,
		params,
		{
			notify: 'registrations'
		}
	);

/* ******************** RESEND REGISTRATIONS ******************** */
export const resendRegistration = (params) =>
	globalAction(
		RESEND_USER_INVITATION,
		resendRegistrationService,
		params,
		{
			notify: 'registrations'
		}
	);
