import { handleActions } from 'redux-actions';

import {
	GET_TICKETING_SEVERITIES_AND_STATUSES_REQUEST,
	GET_TICKETING_SEVERITIES_AND_STATUSES_SUCCESS,
	GET_TICKETING_SEVERITIES_AND_STATUSES_FAIL,
	GET_TICKETING_SEVERITIES_AND_STATUSES_INIT
} from './constants';
import { Status } from '../../../../../utils/constants';

const initState = {
	data: null,
	error: null,
	status: Status.INIT
};

export default handleActions(
	{
		[GET_TICKETING_SEVERITIES_AND_STATUSES_REQUEST]: (state) => ({
			...state,
			data: null,
			error: null,
			status: Status.PENDING
		}),
		[GET_TICKETING_SEVERITIES_AND_STATUSES_SUCCESS]: (state, action) => ({
			...state,
			data: {
				severities: action.payload.severities.resultList,
				statuses: action.payload.statuses.resultList
			},
			error: null,
			status: Status.DONE
		}),
		[GET_TICKETING_SEVERITIES_AND_STATUSES_FAIL]: (state, action) => ({
			...state,
			data: null,
			error: action.payload.error,
			status: Status.FAIL
		}),
		[GET_TICKETING_SEVERITIES_AND_STATUSES_INIT]: () => initState
	},
	initState
);
