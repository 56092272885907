import { Status } from '../../../utils/constants';

export const isFiltersPending = ({ analytics }) =>
	analytics.getFilters.status === Status.PENDING;

export const getFiltersSuccess = ({ analytics }) => analytics.getFilters.data;

export const isGetDirectionalIndexPending = ({ analytics }, chartId) =>
	analytics.getDirectionalIndex.status &&
	analytics.getDirectionalIndex.status[chartId] === Status.PENDING;

export const getDirectionalIndexSuccess = ({ analytics }, chartId) =>
	analytics.getDirectionalIndex.data &&
	analytics.getDirectionalIndex.data[chartId] &&
	analytics.getDirectionalIndex.data[chartId].data &&
	analytics.getDirectionalIndex.data[chartId].data.adx;

export const isGetChartDataPending = ({ analytics }, chartId) =>
	analytics.getChartData.status &&
	analytics.getChartData.status[chartId] === Status.PENDING;

export const getChartDataSuccess = ({ analytics }, chartId) =>
	analytics.getChartData.data &&
	analytics.getChartData.data[chartId] &&
	analytics.getChartData.data[chartId].data &&
	analytics.getChartData.data[chartId].data.resultList;

export const isCategoriesPending = ({ analytics }) =>
	analytics.getCategories.status === Status.PENDING;

export const getCategoriesSuccess = ({ analytics }) =>
	analytics.getCategories.data && analytics.getCategories.data.resultList;

export const isAttributesPending = ({ analytics }) =>
	analytics.getAttributes.status === Status.PENDING;

export const getAttributesSuccess = ({ analytics }) =>
	analytics.getAttributes.data && analytics.getAttributes.data.resultList;
