import React from 'react';
import { func, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DSSteps from '../../../../lib/DigitalComponents/Steps';
import { StepZero, StepFirst, StepSecond } from './Steps';

export default function SimStateChangeStepper({
	ModalContextConsumer,
	stepFirstProps,
	stepSecondProps,
	stepZeroProps,
	includeZeroStep,
	onNext,
	onFinish,
	onBack,
	isActionError,
	singleEndpointUpdate,
	isUpdating,
	isSuccess,
	isFail,
	availableStates,
}) {
	let customSteps = [];
	let counter = 0;

	if (includeZeroStep) {
		customSteps = [
			{
				label: <FormattedMessage
					id="SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP"
					defaultMessage="{step}. Select SIM State"
					values={{ step: counter += 1 }}
				/>,
				component: (
					<ModalContextConsumer>
						<StepZero
							onCancel={stepZeroProps.onCancel}
							endpoint={stepZeroProps.endpoint}
							endpointId={stepZeroProps.endpointId}
							availableStates={availableStates}
						/>
					</ModalContextConsumer>
				)
			}
		];
	}

	customSteps = [
		...customSteps,
		{
			label: <FormattedMessage
				id="SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP"
				defaultMessage="{step}. Schedule SIM State Change"
				values={{ step: counter += 1 }}
			/>,
			component: (
				<ModalContextConsumer>
					<StepFirst
						onCancel={stepFirstProps.onCancel}
						newState={stepFirstProps.newState}
						endpoint={stepFirstProps.endpoint}
						user={stepFirstProps.user}
						singleEndpointUpdate={singleEndpointUpdate}
					/>
				</ModalContextConsumer>
			)
		},
		{
			label: <FormattedMessage
				id="SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP"
				defaultMessage="{step}. System Confirmation"
				values={{ step: counter += 1 }}
			/>,
			component: (
				<ModalContextConsumer>
					<StepSecond
						values={stepSecondProps.values}
						onCancel={stepSecondProps.onCancel}
						getSingleEndpoint={stepSecondProps.getSingleEndpoint}
						type={stepSecondProps.type}
						endpointName={stepSecondProps.endpointName}
						isUpdating={isUpdating}
						isSuccess={isSuccess}
						isFail={isFail}
					/>
				</ModalContextConsumer>
			)
		}
	];

	return (
		<DSSteps
			data-spec="sim-state-change-steps"
			steps={customSteps}
			onNext={onNext}
			noActions
			onBack={onBack}
			onFinish={onFinish}
			isActionError={isActionError}
		/>
	);
}

SimStateChangeStepper.propTypes = {
	onNext: func,
	onBack: func,
	onFinish: func,
	isActionError: bool,
	includeZeroStep: bool,
	stepZeroProps: object,
	stepFirstProps: object,
	stepSecondProps: object,
	ModalContextConsumer: func,
	singleEndpointUpdate: func,
	isUpdating: bool,
	isSuccess: bool,
	isFail: bool,
	availableStates: object,
};
SimStateChangeStepper.defaultProps = {
	onNext: undefined,
	onBack: undefined,
	onFinish: undefined,
	isActionError: false,
	includeZeroStep: false,
	stepZeroProps: {},
	stepFirstProps: {},
	stepSecondProps: {},
	ModalContextConsumer: null,
	singleEndpointUpdate: undefined,
	isUpdating: false,
	isSuccess: false,
	isFail: false,
	availableStates: {},
};
