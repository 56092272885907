import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Switch from '../../../../lib/DigitalComponents/Switch';

const FilterList = ({ title, checked, onClick }) => (
	<Switch
		data-spec="switch"
		checked={checked}
		label={<FormattedMessage id={title} />}
		onChange={onClick}
	/>
);
const { bool, func, string } = PropTypes;
FilterList.propTypes = {
	checked: bool,
	onClick: func,
	title: string
};

export default FilterList;
