import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import { globalPaginationNumber } from '../../../../utils/constants';

import { Desktop, Mobile } from '../../components/SimOrdering';

const SimOrderingView = (props) => (
	<>
		<DesktopMediaQuery>
			<Desktop {...props} dataLimit={globalPaginationNumber.desktop} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Desktop {...props} dataLimit={globalPaginationNumber.desktop} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<Mobile {...props} dataLimit={globalPaginationNumber.mobile} />
		</MobileMediaQuery>
	</>
);

export default SimOrderingView;
