import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import { getNetworkEntitlement } from '../../../../SingleEndpoint/redux/actions';
import {
	isNetworkEntitlementPending,
	getNetworkEntitlementData
} from '../../../../SingleEndpoint/redux/selectors';

import Button from '../../../../../lib/DigitalComponents/Button';
import Select from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import NetworkEntitlementInfo from './EntitlementInfo/EntitlementInfo';

import styles from './Steps.scss';

const EntitlementRWComponent = UserAllowedToAccess([
	'mnc.endpoints.networkentitlements_rw',
]);

class StepFirst extends Component {
	constructor(props) {
		super(props);

		this.state = {
			chosenEntitlement: null
    };
	}

	componentDidMount() {
		const { selectedSims, fetchNetworkEntitlement, context } = this.props;

		if (
			selectedSims &&
			selectedSims.length &&
			selectedSims[0].m2mAccountId &&
			selectedSims[0].serviceProvider
		) {
			fetchNetworkEntitlement(
				selectedSims[0].m2mAccountId,
				selectedSims[0].serviceProvider
			);
		}
		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { context, networkEntitlements, selectedSims } = this.props;
		const { chosenEntitlement } = this.state;

		if (
			(context &&
				prevState.chosenEntitlement &&
				chosenEntitlement &&
				prevState.chosenEntitlement.value !== chosenEntitlement.value) ||
			(!prevState.chosenEntitlement &&
				chosenEntitlement &&
				chosenEntitlement.value)
		) {
			this.setFooter(context);
		}

		if (prevProps.networkEntitlements !== networkEntitlements) {
			const entitlement =
				networkEntitlements &&
				networkEntitlements.find(
					(item) =>
						item.id === selectedSims[0].networkEntitlementId
				);

			if (entitlement) {
				this.updateInitialValue({
					value: entitlement.id,
					label: entitlement.name
				});
			}
    }
	}

	updateInitialValue = (entitlement) => {
		this.setState({
			chosenEntitlement: entitlement
		});
	};

	checkButton = (prevProps, prevState) => {
		const { selectedSims } = prevProps || this.props;
		const { chosenEntitlement } = this.state || prevState;

		return (
			!selectedSims.length ||
			uniqBy(selectedSims, 'serviceProvider').length > 1 ||
			!chosenEntitlement ||
			chosenEntitlement.value === selectedSims[0].networkEntitlementId
		);
	};

	setFooter = (context) => {
		const { onClose } = this.props;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<EntitlementRWComponent>
					<Button
						dataSpec="continue"
						variant="primary"
						onClick={this.onNext}
						disabled={this.checkButton()}
						label={<FormattedMessage id="CONTINUE" defaultMessage="Continue" />}
					/>
				</EntitlementRWComponent>
			</>
		);

		context.updateContext({ footer });
	};

	onNext = () => {
		const { chosenEntitlement } = this.state;
		const { onNext, selectedSims } = this.props;

		onNext({ selectedSims, networkEntitlement: chosenEntitlement });
	};

	checkSimsServiceProvider = () => {
		const { selectedSims } = this.props;

		return (
			uniqBy(selectedSims, 'serviceProvider').length > 1 ||
			uniqBy(selectedSims, 'networkEntitlementId').length > 1
		);
	};

	handleChange = (val) => {
		this.setState({
			chosenEntitlement: val
		});
  };

	render() {
		const {
			networkEntitlements,
			networkEntitlementRequest,
			selectedSims
    } = this.props;
    const { chosenEntitlement } = this.state;
    const chosenEntitlementInfo =
			networkEntitlements &&
			chosenEntitlement &&
			networkEntitlements.find(
				(item) => item.id === chosenEntitlement.value
      );
		const options =
			networkEntitlements &&
			networkEntitlements.map((item) => ({
				value: item.id,
				label: item.name
      }));

		if (this.checkSimsServiceProvider()) {
			return (
				<span data-spec="change-entitlement-not-allowed">
					<FormattedMessage
						id="SERVICE_PROVIDER_CHECK"
						defaultMessage="We are sorry, multiple endpoint change is not allowed for endpoints from multiple platforms or with different network entitlements."
					/>
				</span>
			);
		}

		return (
			<div
				className={styles.first_step}
				data-spec="change-network-entitlement-modal"
			>
				<div className={styles.select_field_label}>
					<FormattedMessage
						id="ENDPOINTS.ELIGIBLE_NETWORK_ENTITLEMENTS"
						defaultMessage="ELIGIBLE NETWORK ENTITLEMENTS"
					/>
				</div>
				<div className={styles.select_field_wrapper}>
					<Select
						value={chosenEntitlement}
						options={options}
						onChange={(val) => {
							this.handleChange(val);
						}}
						dataSpec="eligible-network-entitlement"
						loading={networkEntitlementRequest}
					/>
				</div>
				{chosenEntitlementInfo && (
					<NetworkEntitlementInfo
						chosenEntitlement={chosenEntitlementInfo}
						selectedSims={selectedSims}
					/>
				)}
			</div>
		);
	}
}

const { array, func, any, object, bool } = PropTypes;

StepFirst.propTypes = {
	selectedSims: array,
	fetchNetworkEntitlement: func,
	networkEntitlements: any,
	onClose: func,
	onNext: func,
	context: object,
	networkEntitlementRequest: bool
};

const mapDispatchToProps = (dispatch) => ({
	fetchNetworkEntitlement: (id, platform) =>
		dispatch(getNetworkEntitlement(id, platform))
});

const mapStateToProps = (state) => ({
	networkEntitlementRequest: isNetworkEntitlementPending(state),
	networkEntitlements: getNetworkEntitlementData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepFirst);
