/* eslint-disable no-restricted-syntax */
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { routerActions } from 'connected-react-router';

const renderHelper = (props) => {
	if (Array.isArray(props)) {
		return props[0];
	}
	return props;
};

const checkPermission = (
	requiredPermission,
	services,
	r = { deniedAccess: false, hasAccess: null }
) => {
	const [action, ...nameParts] = requiredPermission.split('_').reverse();
	const name = nameParts.reverse().join('_');
	const result = {
		...r,
	};
	if (services.includes(`${name}_na`)) {
		result.deniedAccess = true;
		result.hasAccess = false;
		return result;
	}
	if (services.includes(`${name}_rw`) && action === 'ro') {
		if (result.hasAccess === null) {
			result.hasAccess = false;
		}
	}
	if (
		services.includes(requiredPermission) ||
		(services.includes(`${name}_rw`) && action === 'ro')
	) {
		if (result.hasAccess === null) {
			result.hasAccess = true;
		}
	}
	if (
		services.includes(`${name}_ro`) &&
		action === 'rw' &&
		!services.includes(`${name}_rw`)
	) {
		if (result.hasAccess === null) {
			result.hasAccess = false;
		}
	}
	const requiredPermissionParts = name.split('.');
	if (requiredPermissionParts.length < 2) {
		return result;
	}
	const parentPermission = `${requiredPermissionParts
		.slice(0, requiredPermissionParts.length - 1)
		.join('.')}_${action}`;
	return checkPermission(parentPermission, services, result);
};

export const ibmCiIsAuthorized = (requiredPermissions, services) => {
	if (!requiredPermissions) {
		return true;
	}

	if (!services) {
		return false;
	}

	const permissions = services.map((s) => s.toLowerCase());

	const permissionAuthResults = [];

	for (const permission of requiredPermissions) {
		const check = checkPermission(permission.toLowerCase(), permissions);
		permissionAuthResults.push(check);
	}

	if (permissionAuthResults.find((x) => x.hasAccess === true)) {
		return true;
	}

	return false;
};

export const ComponentsRender = (entitlement) =>
	connectedAuthWrapper({
		authenticatedSelector: (state) => {
			if (!state.user.getUserData.data) return false;
			return ibmCiIsAuthorized(
				[entitlement],
				state.user.getUserData.data.services
			);
		},
		wrapperDisplayName: 'ComponentsRender',
	})((props) => renderHelper(props.children));

export const UserAllowedToAccess = (entitlements) =>
	connectedAuthWrapper({
		authenticatedSelector: (state) => {
			if (!state.user.getUserData.data) return false;
			return ibmCiIsAuthorized(
				entitlements,
				state.user.getUserData.data.services
			);
		},
		wrapperDisplayName: 'UserAllowedToAccess',
	})((props) => renderHelper(props.children));

export const isUserAllowedToAccess = (entitlements, user) => {
	if (!user) return false;
	return ibmCiIsAuthorized(entitlements, user.services);
};

export const ComponentsRenderCustom = (entitlements, user) => {
	if (!user) return false;
	return ibmCiIsAuthorized(entitlements, user.services);
};

export const userIsAuthenticated = (
	entitlement,
	redirectPath = '/page-not-found'
) =>
	connectedReduxRedirect({
		redirectPath,
		allowRedirectBack: false,
		authenticatedSelector: (state) => {
			if (Array.isArray(entitlement)) {
				return ibmCiIsAuthorized(
					entitlement,
					state.user.getUserData.data.services
				);
			}
			return ibmCiIsAuthorized(
				[entitlement],
				state.user.getUserData.data.services
			);
		},
		redirectAction: routerActions.replace,
	});

export const userInSupermode = connectedReduxRedirect({
	redirectPath: '/forbidden-access',
	allowRedirectBack: false,
	authenticatedSelector: (state) =>
		state.user.getUserData.data.systemUser &&
		!sessionStorage.getItem('company'),
	redirectAction: routerActions.replace,
});

export const userInStandardMode = connectedReduxRedirect({
	redirectPath: '/',
	allowRedirectBack: false,
	authenticatedSelector: (state) => state.user.getUserData.accountId.length > 0,
	redirectAction: routerActions.replace,
});

export const componentAuthBasedSelector = (
	entitlement,
	SuccessComponent,
	FailureComponent
) =>
	connectedAuthWrapper({
		authenticatedSelector: (state) => {
			if (state.user.getUserData.accountId.length !== 0) return false;
			return ibmCiIsAuthorized(
				entitlement,
				state.user.getUserData.data.services
			);
		},
		FailureComponent,
	})(SuccessComponent);

export const checkIfUserIsSystem = (user) =>
	user.systemUser && !sessionStorage.getItem('company');
