import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Loading from '../../../../lib/DigitalComponents/Loader';

import FieldGroup from '../../../../lib/DigitalComponents/FieldGroup';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import Button from '../../../../lib/DigitalComponents/Button';

import validate from './validate';

class CustomFieldLabels extends React.Component {
	componentDidMount() {
		const { getCustomFieldLabels } = this.props;
		getCustomFieldLabels();
	}

	componentDidUpdate(prevProps) {
		const { editCustomFieldLabelsSuccess, getCustomFieldLabels } = this.props;
		if (
			editCustomFieldLabelsSuccess !== prevProps.editCustomFieldLabelsSuccess
		) {
			getCustomFieldLabels();
		}
	}

	onCustomFieldLabelsSubmit = (values) => {
		const { editCustomFieldLabels } = this.props;
		const temp = {
			...values
		};
		delete temp.messageId;
		Object.keys(temp).forEach((key) => {
			if (typeof temp[key] === 'string' && temp[key] === '') {
				temp[key] = `Custom Label ${key.replace(/^\D+/g, '')}`;
			}
		});
		editCustomFieldLabels(temp);
	};

	objectToArray = (fields) => {
		let newFields = [];
		const items = fields;

		Object.keys(items).forEach((key) => {
			if (key.startsWith('customLabel') && key !== 'messageId') {
				const number = key.substr('customLabel'.length);
				newFields = [
					...newFields,
					{
						label: items[key],
						isRequired: items[`isCustomField${number}Required`]
							? items[`isCustomField${number}Required`]
							: false
					}
				];
			}
		});

		return newFields;
	};

	render() {
		const {
			initialValues,
			styles,
			customFieldLabelsRequest,
			customFieldLabelsIsNull,
			editCustomFieldLabelsRequest
		} = this.props;

		if (
			customFieldLabelsRequest ||
			customFieldLabelsIsNull ||
			editCustomFieldLabelsRequest
		) {
			return <Loading data-spec="loader" />;
		}

		const fields = this.objectToArray(initialValues);

		let temp = {};

		const {
			handleSubmit,
			pristine,
			submiting,
			reset,
			invalid,
			disableEditing
		} = this.props;

		return (
			<div data-spec="custom-field-labels" className={styles.main_content}>
				<div className={styles.custom_labels_field_header}>
					<div>
						<FormattedMessage
							id="EDIT_CUSTOM_FIELD_LABELS"
							defaultMessage="Edit custom field labels"
						/>
					</div>
					<div className={styles.line} />
				</div>
				<form onSubmit={handleSubmit(this.onCustomFieldLabelsSubmit)}>
					<div
						className={styles.content_wrapper}
						data-spec="edit-custom-field-labels"
					>
						{fields.map((field, index) => {
							temp = { ...temp, [`test${index}`]: field.label };
							const labelIndex = index + 1;
							return (
								<div
									data-spec="label-item"
									className={styles.item}
									key={field.label}
								>
									<div className={styles.field_icon_row}>
										<FormattedMessage
											id="CUSTOM_LABEL"
											defaultMessage="Custom Label {labelIndex}"
											values={{ labelIndex }}
										>
											{(formattedValue) => (
												<Field
													type="text"
													component={FieldGroup}
													label={formattedValue}
													dataSpec={`customLabel${index + 1}`}
													name={`customLabel${index + 1}`}
													maxLength="50"
													disabled={disableEditing}
												/>
											)}
										</FormattedMessage>
									</div>
									<Field
										type="checkbox"
										component={Checkbox}
										label={
											<FormattedMessage
												id="SET_AS_REQUIRED"
												defaultMessage="Set as required"
											/>
										}
										// dataSpec={`isCustomField${index + 1}Required`}
										name={`isCustomField${index + 1}Required`}
										disabled={disableEditing}
									/>
								</div>
							);
						})}
					</div>
					<div className={styles.buttons}>
						<Button
							dataSpec="cancel-button"
							variant="outline-primary"
							className={styles.cancel_button}
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
							disabled={pristine || submiting || disableEditing}
							onClick={reset}
						/>

						<Button
							dataSpec="save-button"
							variant="primary"
							label={
								<FormattedMessage
									id="SAVE_CUSTOM_LABELS"
									defaultMessage="Save Custom Labels"
								/>
							}
							disabled={pristine || submiting || invalid || disableEditing}
							type="submit"
						/>
					</div>
				</form>
			</div>
		);
	}
}

const { object, func, bool } = PropTypes;

CustomFieldLabels.propTypes = {
	initialValues: object,
	handleSubmit: func,
	pristine: bool,
	submiting: bool,
	reset: func,
	invalid: bool,
	disableEditing: bool,
	styles: object,
	getCustomFieldLabels: func,
	editCustomFieldLabels: func,
	customFieldLabelsRequest: bool,
	customFieldLabelsIsNull: bool,
	editCustomFieldLabelsRequest: bool,
	editCustomFieldLabelsSuccess: bool
};

const CustomFieldLabelsForm = reduxForm({
	form: 'companyProfileCustomFieldLabels',
	validate,
	enableReinitialize: true
})(CustomFieldLabels);

export default CustomFieldLabelsForm;
