import React, { PureComponent } from 'react';
import {
	func,
	objectOf,
	string,
	bool,
	object,
	array,
	number,
	shape,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import FilterModal from '../../../Shared/components/FilterModal';
import ActionBar from '../../../Shared/views/ActionBarView';
import NoData from '../../../Shared/views/NoDataView';

import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Loading from '../../../../lib/DigitalComponents/Loader';
import Button from '../../../../lib/DigitalComponents/Button';

import AddUserGroupModal from '../AddUserGroupModal';
import ReactivateModal from './ReactivateUserGroupModal';
import SuspendUserGroupModal from './SuspendUserGroupsModal';

import {
	UserGroupsTableHeader as tableHeader,
	MobileUserGroupsTableHeader as mobileTableHeader,
	userGroupsSearchMenu,
} from '../../utils/constants';

import {
	isUserAllowedToAccess,
	checkIfUserIsSystem,
} from '../../../../utils/AuthSelector';

import {
	filterHelpers,
	onOrderClick,
	newOnPageChange
} from '../../../../utils/tableHelpers';

import styles from './UserGroups.scss';

import { getFilters as getUserGroupsFiltersService } from '../../services/UserGroupsService';

function UserGroups(WrappedComponent) {
	class UserGroupsComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				orderColumn: {},
				isReactivateModalOpen: false,
				selectedId: null,
				isSuspendModalOpen: false,
			};
		}

		componentDidMount() {
			this.getUserGroups();
		}

		componentDidUpdate(prevProps) {
			const {
				addUserGroupSuccess,
				suspendUserGroupSuccess,
				reactivateUserGroupSuccess,
				push,
			} = this.props;

			if (
				prevProps.suspendUserGroupSuccess !== suspendUserGroupSuccess ||
				prevProps.reactivateUserGroupSuccess !== reactivateUserGroupSuccess
			) {
				this.getUserGroups();
			}

			if (prevProps.addUserGroupSuccess !== addUserGroupSuccess) {
				const { system } = this.props;
				let url = `/user-groups/${addUserGroupSuccess.id}/permissions/edit`;
				if (system) {
					url = `/access-management/user-groups/${addUserGroupSuccess.id}/permissions/edit`;
				}
				push(url);
			}
		}

		getUserGroups = () => {
			const {
				getUserGroups,
				system,
				filterData,
				searchParameter,
				dataLimit,
				dataOffset
			} = this.props;

			const { orderColumn } = this.state;

			const realProps = {
				status: 'active',
			};

			let simpleSearchParams = [];

			if (searchParameter) {
				simpleSearchParams = [
					{
						operator: 'iLike',
						prop: searchParameter.prop,
						propValue: `${searchParameter.propValue}%`,
					},
				];
			}

			let filterParams = [];
			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}

			let tempApiString = '';
			if (orderColumn.name === 'status') {
				tempApiString = `active ${orderColumn.apiString.split(' ')[1]}`;
			} else {
				tempApiString = orderColumn.apiString;
			}

			const params = {
				system,
				params: {
					searchParams: [...simpleSearchParams, ...filterParams],
					additionalParams: {
						dataLimit: dataLimit || 50,
						dataOffset,
						dataSort: tempApiString || 'createdAt asc',
					},
				},
			};
			getUserGroups({ ...params });
		};

		goToUserGroupPermissions = (id) => {
			const { push, system } = this.props;
			let url = `/user-groups/${id}/permissions`;
			if (system) {
				url = `/access-management/user-groups/${id}/permissions`;
			}
			push(url);
		};

		goToEditUserGroupPermissions = (id) => {
			const { push, system } = this.props;
			let url = `/user-groups/${id}/permissions/edit`;
			if (system) {
				url = `/access-management/user-groups/${id}/permissions/edit`;
			}
			push(url);
		};

		goToUserGroupAssignedAccounts = (id) => {
			const { push } = this.props;
			const url = `/user-groups/${id}/assigned-accounts`;
			push(url);
		};

		goToEditUserGroupAssignedAccounts = (id) => {
			const { push } = this.props;
			const url = `/user-groups/${id}/assigned-accounts/edit`;
			push(url);
		};

		createFilterData = (data) => {
			const { messages } = this.props;
			const filterDataKeys = Object.keys(data);
			let filter = {};
			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					filter = {
						...filter,
						[`${item}`]: data[item].resultList.map((filterItem) => {
							const lbl = filterItem.code || filterItem.name;
							return {
								value: filterItem.id,
								label: messages[`FILTER_VALUE_${lbl.toUpperCase()}`] || lbl,
								checked: false,
							};
						}),
					};
				});
			return filter;
		};

		renderUserGroups = (data) => (
			<div
				className={styles.highlight}
				onClick={() => this.goToUserGroupPermissions(data.id)}
			>
				{data.name}
			</div>
		);

		renderAction = (data) => {
			const { system, user } = this.props;
			let options = [
				{
					value: 0,
					label: (
						<FormattedMessage
							id="ONBOARDING.EDIT_PERMISSIONS"
							defaultMessage="Edit Permissions"
						/>
					),
					data,
				},
			];

			if (data.active) {
				options = [
					...options,
					{
						value: 2,
						label: (
							<FormattedMessage
								id="ONBOARDING.SUSPEND"
								defaultMessage="Suspend"
							/>
						),
						data,
					},
				];
			} else {
				options = [
					...options,
					{
						value: 3,
						label: (
							<FormattedMessage
								id="ONBOARDING.REACTIVATE"
								defaultMessage="Reactivate"
							/>
						),
						data,
					},
				];
			}

			if (!system) {
				options = [
					...options,
					{
						value: 1,
						label: (
							<FormattedMessage
								id="ONBOARDING.EDIT_ASSIGNED_ACCOUNTS"
								defaultMessage="Edit Assigned Accounts"
							/>
						),
						data,
					},
				];
			}

			return (
				<div
					data-spec="order-status-wrapper"
					className={styles.order_status_select}
				>
					<Select
						menuPosition="fixed"
						name="status"
						placeholder={
							<FormattedMessage
								id="ONBOARDING.SELECT_ACTION"
								defaultMessage="Select Action"
							/>
						}
						options={options}
						onChange={this.handleChange}
						noBorder
						isDisabled={
							checkIfUserIsSystem(user)
								? !isUserAllowedToAccess(
										['system.accessmanagement.users_rw'],
										user
								  )
								: !isUserAllowedToAccess(
										['mnc.accessmanagement.users_rw'],
										user
								  )
						}
					/>
				</div>
			);
		};

		renderUserGroupStatus = (data) =>
			(data.active ? (
				<div className={cn(styles.status, styles.active)}>
					<FormattedMessage id="ONBOARDING.ACTIVE" defaultMessage="Active" />
				</div>
			) : (
				<div className={cn(styles.status, styles.paused)}>
					<FormattedMessage
						id="ONBOARDING.SUSPENDED"
						defaultMessage="Suspended"
					/>
				</div>
			));

		handleChange = (item) => {
			switch (item.value) {
				case 0:
					this.goToEditUserGroupPermissions(item.data.id);
					break;
				case 1:
					this.goToEditUserGroupAssignedAccounts(item.data.id);
					break;
				case 2:
					this.openSuspendModal(item.data.id);
					break;
				case 3:
					this.openReactivateModal(item.data.id);
				// no default
			}
		};

		totalCount = () => {
			const { userGroups, dataLimit } = this.props;
			return Math.ceil(userGroups.totalCount / dataLimit);
		};

		onPageChange = async (data) => {
      const { setDataOffset, setSelectedPage, dataLimit } = this.props;
			const { selected } = data;
			await setDataOffset(dataLimit * selected);
			await setSelectedPage(selected);
			this.getUserGroups();
    }

		getOptions = (target) => {
			const { orderColumn } = this.state;

			const {
				dataLimit,
				selectedPage,
				setDataOffset,
				setSelectedPage,
				userGroups,
			} = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			options.customComponents = {
				name: {
					type: 'custom',
					component: (data) => this.renderUserGroups(data),
				},
				status: {
					type: 'custom',
					component: (data) => this.renderUserGroupStatus(data),
				}
			};

			options.tableOptions = {
				orderByData: ['all'],
				excludeFromOrdering: ['actions'],
				showPagination: userGroups && userGroups.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				orderColumn,
				getOrderData: (data) => onOrderClick(data, this.getUserGroups, this),
				onPageChange: (data) =>
				newOnPageChange(
					data,
					this.getUserGroups,
					dataLimit,
					setDataOffset,
					setSelectedPage
				)
			};

			if (isMobile) {
				options.header = mobileTableHeader;
				options.customComponents = {
					...options.customComponents
				};
			}

			if (isDesktop) {
				options.header = tableHeader;
				options.customComponents = {
					...options.customComponents,
					actions: {
						type: 'custom',
						component: (data) => this.renderAction(data),
					},
				};
			}

			return options;
		};

		onSearchSubmitCallback = () => {
			this.getUserGroups();
		};

		clearFilters = () => {
			this.getUserGroups();
		};

		onApply = () => {
			this.getUserGroups();
		};

		onChange = () => {
			this.getUserGroups();
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		openUserGroupModal = () => {
			const { openAddUserGroupModal, simpleSearchClose } = this.props;
			simpleSearchClose();
			openAddUserGroupModal();
		};

		openReactivateModal = (data) => {
			this.setState({
				isReactivateModalOpen: true,
				selectedId: data,
			});
		};

		closeReactivateModal = () => {
			this.setState({
				isReactivateModalOpen: false,
				selectedId: null,
			});
		};

		openSuspendModal = (data) => {
			this.setState({
				isSuspendModalOpen: true,
				selectedId: data,
			});
		};

		closeSuspendModal = () => {
			this.setState({
				isSuspendModalOpen: false,
				selectedId: null,
			});
		};

		render() {
			const {
				messages,
				closeAddUserGroupModal,
				isAddUserGroupModalOpened,
				userGroups,
				userGroupsRequest,
				userGroupsFail,
				addUserGroupRequest,
				system,
				dataLimit,
				suspendUserGroup,
				reactivateUserGroup,
				user,
				selectedPage
			} = this.props;

			const {
				isReactivateModalOpen,
				selectedId,
				isSuspendModalOpen,
			} = this.state;

			const params = {
				service: getUserGroupsFiltersService,
				createFilterData: this.createFilterData,
			};

			return (
				<div data-spec="user-groups">
					<ActionBar
						menu={userGroupsSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						clearFilters={this.clearFilters}
						openFilterModal={this.openFilterModal}
						onChange={this.onChange}
						actions={
							<Button
								variant="primary"
								label={
									<FormattedMessage
										id="ONBOARDING.CREATE_NEW_GROUP"
										defaultMessage="Create New Group"
									/>
								}
								dataSpec="invite-users-button"
								onClick={() => this.openUserGroupModal()}
								disabled={
									checkIfUserIsSystem(user)
										? !isUserAllowedToAccess(
												['system.accessmanagement.usergroups_rw'],
												user
										  )
										: !isUserAllowedToAccess(
												['mnc.accessmanagement.usergroups_rw'],
												user
										  )
								}
							/>
						}
						isDisabled={userGroupsRequest}
						showPagination
						totalCount={userGroups && userGroups.totalCount}
						selectedPage={selectedPage}
						dataLimit={dataLimit}
					/>
					{(userGroupsRequest || addUserGroupRequest) && (
						<Loading data-spec="loading" />
					)}
					{userGroupsFail && null}
					{!userGroupsRequest && !addUserGroupRequest && userGroups && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}

					{userGroups &&
						userGroups.resultList &&
						userGroups.resultList.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.NO_USER_GROUPS_AVAILABLE"
										defaultMessage="No User Groups Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_USER_GROUPS_AVAILABLE"
										defaultMessage="No User Groups Available"
									/>
								}
							/>
						)}

					<FilterModal
						type="radio"
						params={params}
						messages={messages}
						onApply={this.onApply}
					/>

					{isAddUserGroupModalOpened && (
						<AddUserGroupModal
							show
							messages={messages}
							onClose={closeAddUserGroupModal}
							existingUserGroups={(userGroups && userGroups.resultList) || []}
							system={system}
						/>
					)}

					{isReactivateModalOpen && (
						<ReactivateModal
							show
							onClose={this.closeReactivateModal}
							data={selectedId}
							system={system}
							reactivateUserGroup={reactivateUserGroup}
						/>
					)}

					{isSuspendModalOpen && (
						<SuspendUserGroupModal
							show
							onClose={this.closeSuspendModal}
							data={selectedId}
							system={system}
							suspendUserGroup={suspendUserGroup}
						/>
					)}
				</div>
			);
		}
	}

	UserGroupsComponent.propTypes = {
		openFilterModal: func,
		openAddUserGroupModal: func,
		messages: objectOf(string),
		isAddUserGroupModalOpened: bool,
		closeAddUserGroupModal: func,
		push: func,
		getUserGroups: func,
		userGroups: object,
		userGroupsRequest: bool,
		userGroupsFail: bool,
		addUserGroupRequest: bool,
		addUserGroupSuccess: object,
		simpleSearchStart: func,
		simpleSearchClose: func,
		simpleSearchParameter: array,
		suspendUserGroupSuccess: bool,
		suspendUserGroup: func,
		reactivateUserGroup: func,
		reactivateUserGroupSuccess: bool,
		system: bool,
		dataLimit: number,
		filterData: shape(),
		user: object,
		searchParameter: object,
		dataOffset: number,
		setDataOffset: func,
    setSelectedPage: func,
		setPaginationInit: func,
		selectedPage: number,
	};

	const wrappedComponentName =
		WrappedComponent.displayName || WrappedComponent.name || 'Component';

	UserGroupsComponent.displayName = `UserGroups(${wrappedComponentName})`;
	return UserGroupsComponent;
}

export default UserGroups;
