/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { UserAllowedToAccess } from '../../../../utils/AuthSelector';

import Loader from '../../../../lib/DigitalComponents/Loader';
import Switch from '../../../../lib/DigitalComponents/Switch';

import PageTitle from '../../../Shared/views/PageTitleView';

import styles from './Failover.scss';

const FailoverRWComponent = UserAllowedToAccess(['system.failover_rw']);

class Failover extends Component {
	constructor(props) {
		super(props);

		this.state = {
			usePrimary: false,
			useSecondary: false
		};
	}

	componentDidMount() {
		const { getFailoverLinks } = this.props;
		getFailoverLinks();
	}

	componentDidUpdate(prevProps) {
		const { failoverLinks, changeEnvironmentFail } = this.props;

		if (failoverLinks && failoverLinks !== prevProps.failoverLinks) {
			this.updateUsePrimarySecondary();
		}

		if (prevProps.changeEnvironmentFail !== changeEnvironmentFail) {
			this.updateUsePrimarySecondary();
		}
  }

  updateUsePrimarySecondary = () => {
    const { failoverLinks } = this.props;
    this.setState({
			usePrimary: failoverLinks.usePrimary,
			useSecondary: !failoverLinks.usePrimary
		});
  }

	handleOnChange = (e, environment) => {
		const { changeEnvironment } = this.props;
		const value = e.currentTarget.checked;
		let usePrimary = null;
		if (environment === 'usePrimary') {
			this.setState({
				usePrimary: value
			});
			usePrimary = value;
		} else {
			this.setState({
				usePrimary: !value
			});
			usePrimary = !value;
		}

		changeEnvironment({ usePrimary });
	};

	render() {
		const {
			messages,
			failoverLinksRequest,
			failoverLinksFail,
			failoverLinks,
			changeEnvironmentRequest
    } = this.props;
    const { usePrimary } = this.state;

		return (
			<div data-spec="user-guide">
				<PageTitle
					title={<FormattedMessage id="FAILOVER" defaultMessage="Failover" />}
					messages={messages}
				/>
				{(failoverLinksRequest || changeEnvironmentRequest) && <Loader />}
				{failoverLinksFail && null}
				{!changeEnvironmentRequest && !failoverLinksRequest && failoverLinks && (
					<div className={styles.wrapper}>
						<div className={styles.container}>
							<div className={styles.failover_message}>
								<FormattedMessage
									id="FAILOVER.IN_CASE_OF_FAILOVER"
									defaultMessage="In case of failover you can switch the domain url."
								/>
							</div>
							<h4>
								<FormattedMessage
									id="FAILOVER.PRIMARY_ENVIRONMENT"
									defaultMessage="Primary environment"
								/>
							</h4>
							<div className={styles.block}>
								<div
									className={
										failoverLinks.usePrimary ? styles.active : styles.inactive
									}
								>
									<div className={styles.data}>
										{failoverLinks.failoverIntegrationEndpoints &&
											failoverLinks.failoverIntegrationEndpoints.primary}
									</div>
									<div className={styles.data}>
										{failoverLinks.failoverIntegrationEndpoints &&
											failoverLinks.failoverIntegrationEndpoints.primaryReport}
									</div>
								</div>
								<FailoverRWComponent>
									<Switch
										checked={usePrimary}
										data-spec="switch-active"
										className={styles.switch}
										label={
											failoverLinks.usePrimary === true ? (
												<FormattedMessage
													id="FAILOVER.ACTIVE_SWITCH"
													defaultMessage="Active"
												/>
											) : (
												<FormattedMessage
													id="FAILOVER.INACTIVE_SWITCH"
													defaultMessage="Inactive"
												/>
											)
										}
										onChange={(e) => this.handleOnChange(e, 'usePrimary')}
										disabled={changeEnvironmentRequest}
									/>
								</FailoverRWComponent>
							</div>
							<h4>
								<FormattedMessage
									id="FAILOVER.SECONDARY_ENVIRONMENT"
									defaultMessage="Secondary environment"
								/>
							</h4>
							<div className={styles.block}>
								<div
									className={
										failoverLinks.usePrimary !== true
											? styles.active
											: styles.inactive
									}
								>
									<div className={styles.data}>
										{failoverLinks.failoverIntegrationEndpoints &&
											failoverLinks.failoverIntegrationEndpoints.secondary}
									</div>
									<div className={styles.data}>
										{failoverLinks.failoverIntegrationEndpoints &&
											failoverLinks.failoverIntegrationEndpoints
												.secondaryReport}
									</div>
								</div>
								<FailoverRWComponent>
									<Switch
										checked={!usePrimary}
										data-spec="switch-inactive"
										className={styles.switch}
										label={
											failoverLinks.usePrimary === true ? (
												<FormattedMessage
													id="FAILOVER.INACTIVE_SWITCH"
													defaultMessage="Inactive"
												/>
											) : (
												<FormattedMessage
													id="FAILOVER.ACTIVE_SWITCH"
													defaultMessage="Active"
												/>
											)
										}
										onChange={(e) => this.handleOnChange(e, 'useSecondary')}
										disabled={changeEnvironmentRequest}
									/>
								</FailoverRWComponent>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const { object, func, bool } = PropTypes;

Failover.propTypes = {
	messages: object,
	getFailoverLinks: func,
	failoverLinksRequest: bool,
	failoverLinksFail: bool,
	failoverLinks: object,
	changeEnvironmentRequest: bool,
	changeEnvironment: func,
	changeEnvironmentFail: bool
};

export default Failover;
