export default {
	// COMMON
	'ONBOARDING.EDIT': '编辑',
	'ONBOARDING.DELETE': '删除',
	'ONBOARDING.NAME': '名称',
	'ONBOARDING.DESCRIPTION': '描述',
	'ONBOARDING.ID': 'ID',
	'ONBOARDING.ACCOUNT_NAME': '账户名称',
	'ONBOARDING.FRIENDLY_NAME': '昵称',
	'ONBOARDING.ACCOUNT_ID': '账户ID',
	'ONBOARDING.CREATE_NEW_COMPANY': '创建新公司',
	'ONBOARDING.IOTC_CUSTOMERS': 'IoT Console客户',
	'ONBOARDING.COMPANIES': '公司',
	'ONBOARDING.NO_COMPANIES_AVAILABLE': '无可用的公司',
	'ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE': '本表格目前无可显示的公司',
	'ONBOARDING.COMPANY_NAME': '公司名称',
	'ONBOARDING.COMPANY_NAME_PLACEHOLDER': '例如：AT&T Business',
	'ONBOARDING.MNC_ACCOUNT_NAME': 'MNC账户名称',
	'ONBOARDING.M2M_ACCOUNT_NAME': '机对机（M2M）账户名称',
	'ONBOARDING.MC_ACCOUNT_NUMBER': 'IoT Console账号',
	'ONBOARDING.SIM_ORDERS': 'SIM卡订单',
	// 'ONBOARDING.TECHNICAL_OWNER':'Technical Owner', - 已移除，针对账户创建者为
	'ONBOARDING.ACCOUNT_CREATED_BY': '账户创建者',
	'ONBOARDING.VIEW_MC': '查看MC',
	'ONBOARDING.DATE_CREATED': '创建日期',
	'ONBOARDING.ACTIONS': '行动',
	'ONBOARDING.PLATFORMS': '平台',
	'ONBOARDING.ACTIVATION_DENIED': '无法处理请求！',
	'ONBOARDING.ACTIVATION_SUCCESS': '已激活账户',
	'ONBOARDING.CONTACT_SYSADMIN': '请联系您的IoT Console管理员以获得更多协助',

	// NOTIFICATIONS
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_SUCCESS': '已成功地更新公司表格设置。',
	'ONBOARDING.TABLE_COMPANIES_COLUMNS_CHANGE_ERROR': '目前无法更新公司表格列。请稍后重试。',
	'ONBOARDING.RETRY': '重试',

	// SEARCH MENU
	'ONBOARDING.BY': '按照',
	'ONBOARDING.BUSINESS_NAME': '业务名称',
	'ONBOARDING.CONTACT_PERSON': '联系人',
	'ONBOARDING.PLATFORM_ID': '平台ID',

	// ADD/EDIT COMPANY
	'ONBOARDING.IOT_CONSOLE_ID': 'IoT Console ID：',
	'ONBOARDING.COMPANY_M2M_ACCOUNTS': '公司机对机（M2M）账户',
	'ONBOARDING.YOU_HAVE_SUCCESSFULLY_CREATED_A_MULTI_NETWORK_CONNECT_ACCOUNT_FOR': '您已经成功创建了IoT Console账户：',
	'ONBOARDING.THE_IOT_GATEWAY_NUMBER_ASSOCIATED_WITH_THIS_ACCOUNT': '与该账户关联的IoT网关号码为：',
	'ONBOARDING.CREATE_NEW_COMPANY_ACCOUNT': '创建新公司账户',
	'ONBOARDING.CANNOT_CONTAIN_SPACES_OR_NUMBERS': '不可包含空格或数字',
	'ONBOARDING.IDM_SERVCE_NAME': '身份管理（IDM）服务名称',
	'ONBOARDING.VISIBLE_TO_THE_CUSTOMER': '对客户可见',
	'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY': '企业或DBA法律实体',
	'ONBOARDING.COMPANY_CONTACTS': '公司联系人',
	'ONBOARDING.ATT_CONTACTS': 'AT&T联系人',
	'ONBOARDING.VTM_SUPPORT_TYPE': 'vTM支持类型',
	'ONBOARDING.FULL_TSM': '完全TSM（无论严重性高低，所有通知单一律发往TSM团队）',
	'ONBOARDING.PARTIAL_TSM': '部分TSM（严重性级别为1的通知单发往TSM团队，2级、3级通知单发往线下帮助团队）',
	'ONBOARDING.IOT_PRIORITY_CARE': 'IoT Priority Care（无论严重性高低，所有通知单一律发往IoT Priority Care团队）',
	'ONBOARDING.NO_SUPPORT': '无支持（无论严重性高低，所有通知单一律发往线下帮助团队）',
	'ONBOARDING.CONTACT': '联系人',

	// DETAILS
	'ONBOARDING.COMPANY_FRIENDLY_NAME': '公司昵称',
	'ONBOARDING.BUSINES_LEGAL_NAME': '业务法定名称',
	'ONBOARDING.COMPANY_CONTACT': '公司联系人',
	'ONBOARDING.ATT_CONTACT': 'AT&T联系人',
	'ONBOARDING.COMPANY': '公司',
	'ONBOARDING.COUNTRY': '国家',
	'ONBOARDING.STATE_PROVINCE': '州/省',
	'ONBOARDING.STREET_ADDRESS': '街道地址',
	'ONBOARDING.ADDRESS_LINE_1': '地址行1',
	'ONBOARDING.ADDRESS_LINE_2_OPTIONAL': '地址行2（选填）',
	'ONBOARDING.CITY_TOWN': '市/镇',
	'ONBOARDING.ZIP_POSTAL_CODE': '邮政编码',
	'ONBOARDING.COMPANY_DOMAIN': '公司域名',
	'ONBOARDING.COMPANY_EMAIL_DOMAIN': '公司电子邮件域名',
	'ONBOARDING.FULL_NAME': '全名',
	'ONBOARDING.PHONE_NUMBER': '电话号码',
	'ONBOARDING.EMAIL_ADDRESS': '电子邮件地址',
	'ONBOARDING.SIM_OPTIONS': 'SIM卡选项',
	'ONBOARDING.ORDER_MOVE_SIMS': '订购或移动SIM卡',
	'ONBOARDING.ORDERING': '订购',
	'ONBOARDING.TRANSFER': '转让',
	'ONBOARDING.SIM_TRANSFER': 'SIM卡转让',
	'ONBOARDING.TRANSFERING_SIM': '将SIM卡从一个机对机（M2M）账户转让至另一个机对机（M2M）账户',
	'ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST': '订购SIM卡、选择或上载SIM卡清单',
	'ONBOARDING.VIEW_OR_EDIT_BILLING': '查看或编辑账单信息',

	// PLATFORMS TABLE HEADER
	'ONBOARDING.PLATFORM': '平台',
	'ONBOARDING.PLATFORM_DESCRIPTION': '平台描述',
	'ONBOARDING.APNS': 'APN',
	'ONBOARDING.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'ONBOARDING.EDIT_ACTIONS': '编辑账户',
	'ONBOARDING.EDIT_M2M_PLATFORM': '编辑机对机（M2M）平台',
	'ONBOARDING.ADD_M2M_PLATFORM': '添加机对机（M2M）平台',
	'ONBOARDING.MC_ACCOUNT_ID': 'IoT Console账户ID',

	// M2M PLATFORM
	'ONBOARDING.PLATFORM_EOD': '按需服务企业',
	'ONBOARDING.PLATFORM_ACC': 'ATT控制中心',
	'ONBOARDING.PLATFORM_CCIP': '控制中心整合计划',
	'ONBOARDING.PLATFORM_VIVO': 'Vivo',
	'ONBOARDING.PLATFORM_GDSP': 'GDSP',
	'ONBOARDING.PLATFORM_WING': 'WING',
	'ONBOARDING.PLATFORM_DCP': '设备连接平台',
	'ONBOARDING.PLATFORM_GMNA': 'GM北美',
	'ONBOARDING.PLATFORM_POD3': 'ACC Pod3',
	'ONBOARDING.PLATFORM_POD19': '企业',
	'ONBOARDING.PLATFORM_GBCM': 'Speedcast',

	// PLATFORMS MENU
	'ONBOARDING.M2M_PLATFORM': '机对机（M2M）平台',

	// NETWORK ENTITLEMENTS
	'ONBOARDING.NETWORK_ENTITLEMENT': '网络权限',
	'ONBOARDING.NETWORK_ENTITLEMENTS': '网络权限',
	'ONBOARDING.COMMUNICATION_PROFILE_NAME': '通讯概况名称',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE': '无可用的网络权限',
	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE': '本表格目前无可显示的网络权限',
	'ONBOARDING.ADD_NEW_NETWORK_ENTITLEMENT': '添加新网络权限',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENT': '添加网络权限',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENT': '编辑网络权限',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS': '编辑网络权限',
	'ONBOARDING.CHOOSE_NETWORK_ENTITLEMENT': '选择网络权限',
	'ONBOARDING.SELECT_ENTITLEMENT': '选择权限',
	'ONBOARDING.PULL_DATA': '提取数据',
	'ONBOARDING.HLR_TEMPLATE': 'HLRHSS详情模板',
	'ONBOARDING.HOTLINE_NUMBER': '热线电话号码',
	'ONBOARDING.VOICE_MT': '移动端终止（MT）语音',
	'ONBOARDING.ROAMING_LTE': '漫游LTE',
	'ONBOARDING.DATA': '数据',
	'ONBOARDING.DATA_LTE': '数据LTE',
	'ONBOARDING.VOICE_MO': '移动端始发（MO）语音',
	'ONBOARDING.INTERNATIONAL_VOICE': '国际语音',
	'ONBOARDING.BLOCK_INTERNATIONAL_MO': '阻止国际移动端始发短信（SMS MO）（所在国除外）',
	'ONBOARDING.BLOCK_PREMIUM_NUMBERS': '阻止付费号码',
	'ONBOARDING.FACETIME': 'Facetime',
	'ONBOARDING.CAMEL': 'Camel',
	'ONBOARDING.CAMEL_SERVICE_TYPE': 'Camel服务类型',
	'ONBOARDING.APNS_PDPINDEX': 'PDP索引',
	'ONBOARDING.APNS_LTE': 'LTE',
	'ONBOARDING.APNS_FIXEDIP': '固定IP',
	'ONBOARDING.APNS_SECURITY': '安全',
	'ONBOARDING.APNS_SUBSCRIPTION': '订阅',
	'ONBOARDING.ADD_NEW_APN': '添加新APN',
	'ONBOARDING.PLATFORM_DETAILS': '平台详情',
	'ONBOARDING.EOD': 'EOD',
	'ONBOARDING.NETWORK_ENTITLEMENT_PLACEHOLDER': 'XYX GPRS/LTE/SMS/IMS RES 165',
	'ONBOARDING.ROAMING': '漫游',
	'ONBOARDING.SMS_MO': '移动端始发短信（SMS MO）',
	'ONBOARDING.SMS_MT': '移动端终止短信（SMS MT）',
	'ONBOARDING.ELIGIBLE_NETWORK_ENTITLEMENTS': '合格的网络权限',

	// RATE PLANS
	'ONBOARDING.PAYMENT_TYPE': '付款类型',
	'ONBOARDING.USAGE_LIMIT': '用量限制',
	'ONBOARDING.RATE_PLAN': '费率计划',
	'ONBOARDING.RATE_PLANS': '费率计划',
	'ONBOARDING.RATE_PLAN_NAME': '费率计划名称',
	'ONBOARDING.NO_RATE_PLANS_AVAILABLE': '无可用的费率计划',
	'ONBOARDING.ADD_NEW_RATE_PLAN': '添加新费率计划',
	'ONBOARDING.EDIT_RATE_PLAN': '编辑费率计划',
	'ONBOARDING.PLAN_ID': '计划ID',
	'ONBOARDING.RATE_PLAN_TYPE': '费率计划类型',
	'ONBOARDING.ACCOUNT_CHARGE': '账户收费',
	'ONBOARDING.TERM': '期限',
	'ONBOARDING.TIERING_FLAG': '层级标记',
	'ONBOARDING.PER_SUBSCRIBER_MRC': '每个订阅者每月重复收费（MRC）',
	'ONBOARDING.CURRENCY': '币种',
	'ONBOARDING.INCLUDED_DATA_USAGE': '包含的数据用量',
	'ONBOARDING.USAGE_LIMIT_MB': '用量限制（MB）',
	'ONBOARDING.PLAN_TYPE': '计划类型',
	'ONBOARDING.TYPE': '类型',
	'ONBOARDING.INVOICE_ACCOUNT_NAME': '账单账户名称',
	'ONBOARDING.M2M_RATE_PLAN_ID': '机对机（M2M）费率计划ID',
	'ONBOARDING.PRICE_PLAN_ID': '价格计划ID',
	'ONBOARDING.SUBSCRIPTION_ITEM_TYPE': '订阅项目类型',
	'ONBOARDING.M2M_SUBSCRIPTION_ITEM_ID': '机对机（M2M）订阅项目ID',
	'ONBOARDING.M2M_INTERNAL_ACCOUNT_ID': '机对机（M2M）内部账户ID',
	'ONBOARDING.M2M_ACCOUNT_NUMBER': '机对机（M2M）账号',
	'ONBOARDING.DEFAULT_PLAN': '默认计划',
	'ONBOARDING.SERVICE_PROVIDER_ID': '服务提供者ID',
	'ONBOARDING.NETWORK_ENTITLEMENT_ID': '网络权限ID',
	'ONBOARDING.NO_RATE_PLANS_SUBTITLE': '本表格目前无可显示的费率计划',
	'ONBOARDING.USD': '美元',
	'ONBOARDING.CAD': '加元',

	// SIM SKU
	'ONBOARDING.SIM_SKU': 'SIM卡储存单位（SKU）',
	'ONBOARDING.DELETE_SIM_SKU': '删除SIM卡储存单位（SKU）',
	'ONBOARDING.DELETE_SIM_SKU_MESSAGE': '删除此储存单位（SKU）的同时，您也将删除所有与此储存单位（SKU）关联的SIM卡订单。您确定想要继续吗？',
	'ONBOARDING.SIM_ORDERING': 'SIM卡订购',
	'ONBOARDING.CURRENT_SIM_SKUS': '当前的SIM卡储存单位（SKU）',
	'ONBOARDING.ADD_SIM_SKU': '添加SIM卡储存单位（SKU）',
	'ONBOARDING.EDIT_SIM_SKU': '编辑SIM卡储存单位（SKU）',
	'ONBOARDING.ENTER_SIM_SKU_HERE': '在此处输入SIM卡储存单位*（SKU）',
	'ONBOARDING.ENTER_SKU_DESCRIPTION': '输入储存单位（SKU）描述',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS': '合同限制',
	'ONBOARDING.SIM_ORDERING_CONTRACT_RESTRAINTS_SUBTITLE': '请为SIM卡订购流程设置限制。',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED': '允许的最低数量',
	'ONBOARDING.SIM_ORDERING_MIN_QUANTITY_ALLOWED_PLACEHOLDER': '最低数量',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED': '允许的最高数量',
	'ONBOARDING.SIM_ORDERING_MAX_QUANTITY_ALLOWED_PLACEHOLDER': '最高数量',
	'ONBOARDING.INCREMENT': '增量',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD': '每个期限',
	'ONBOARDING.SIM_ORDERING_PER_PERIOD_PLACEHOLDER': '选择时间期限',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MIN_QUANTITY': '最低数量',
	'ONBOARDING.SIM_ORDERING_VALIDATOR_MAX_QUANTITY': '最高数量',
	'ONBOARDING.UNIT_PRICE': '单价',
	'ONBOARDING.ALLOW_TO_ORDER': '允许订购',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_SUCCESS': '已成功编辑储存单位（SKU）',
	'ONBOARDING.EDIT_SIM_ORDERING_SKUS_ERROR': '无法编辑储存单位（SKU）',
	'ONBOARDING.RATE_PLAN_ID': '费率计划ID',
	'ONBOARDING.NO_SIM_SKUS_AVAILABLE': '没有可用的SIM卡SKU',

	// 平台
	'ONBOARDING.OPERATOR': '运营者',
	'ONBOARDING.LAUNCH_MC': '启动MC',
	'ONBOARDING.OPERATOR_OPTIONAL': '运营者（选填）',
	'ONBOARDING.MCC': '移动国家代码（MCC）',
	'ONBOARDING.MNC': '移动网络代码（MNC）',
	'ONBOARDING.POD': 'Pod',
	'ONBOARDING.MCC_TOOLTIP': '移动国家代码（MCC）是独特的3位数组合，每个代码代表一个国家。',
	'ONBOARDING.MNC_TOOLTIP': '移动网络代码（MCC）是独特的2到3位数组合，每个代码代表一个移动网络。',
	'ONBOARDING.PLATFORM_SETUP': '平台设置',
	'ONBOARDING.PLATFORM_SETUP_TOOLTIP': '成功添加平台后即可使用平台设置。 ',
	'ONBOARDING.SETTINGS': '设置',
	'ONBOARDING.EDIT_PLATFORM': '编辑平台',
	'ONBOARDING.ADD_PLATFORM': '添加平台',
	'ONBOARDING.NO_PLATFORMS_AVAILABLE': '无可用的平台',
	'ONBOARDING.REQUIRED_INFORMATION_MISSING': '缺少要求的信息',

	// 编辑平台
	'ONBOARDING.SIM_LOADING': '正在载入SIM卡',
	'ONBOARDING.GATEWAY': '网关',
	'ONBOARDING.ENTER_GATEWAY': '输入网关',
	'ONBOARDING.BILLING_INFORMATION': '账单信息',
	'ONBOARDING.BILLING_PLATFORM': '账单平台',
	'ONBOARDING.INVOICE_ACCOUNT_NUMBER': '账单账号',

	// 报告
	'ONBOARDING.REPORT': '报告',
	'ONBOARDING.REPORTS': '报告',
	'ONBOARDING.COMPANY_REPORTS': '报告',

	'ONBOARDING.IDM_INTEGRATION_ERROR': '身份管理（IDM）集成错误',
	'ONBOARDING.IDM_INTEGRATION_ERROR_MESSAGE': '身份管理（IDM）集成似乎遇到了问题。 ',
	'ONBOARDING.ONBOARDING_STATUS': '载入状态',
	'ONBOARDING.SUCCESS': '成功',
	'ONBOARDING.ERROR': '错误',

	'ONBOARDING.MUST_BE_BETWEEN': '长度必须在',
	'ONBOARDING.AND': '和',
	'ONBOARDING.CHARACTERS_LONG': ' 个字符之间',
	'ONBOARDING.DEFAULT': '默认',

	'ONBOARDING.ADD_RATE_PLANS_FOR': '为{companyName}添加费率计划',

	'ONBOARDING.COMPANY_REPORTS_SETTINGS_SUCCESSFULLY_UPDATED': '已成功更新公司报告设置',
	'ONBOARDING.ERROR_WHILE_UPDATING_COMPANY_REPORTS': '更新公司报告时出错',
	'ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR': '定义为{companyName}启用的报告',
	'ONBOARDING.REPORTS_SUBTITLE': '勾选的报告将在IoT Console门户网站中显示，可供用户下载',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS_FOR': '为{companyName}添加网络权限',
	'ONBOARDING.NETWORK_ENTITLEMENTS_SUBTITLE': '将为此账户启用的网络权限关联到AT&T控制中心',
	'ONBOARDING.MAIN_CORPORATE_ADDRESS': '主要公司地址',
	'ONBOARDING.COMPANY_DETAILS': '公司详情',
	'ONBOARDING.ACCOUNTS': '账户',
	'ONBOARDING.EDIT_ACCOUNT': '编辑账户',
	'ONBOARDING.VIEW_ACCOUNT': '查看账户',
	'ONBOARDING.SIM_LOADING_SUB': '为此账户载入SIM卡。通常IoT网关会在创建机对机（M2M）账户时自动完成该步骤。',
	'ONBOARDING.IMPORT_FROM_IOT_GATEWAY': '从IoT网关导入',
	'ONBOARDING.ACCOUNT_CONFIGURATION': '账户配置',
	'ONBOARDING.ACCOUNT_CONFIGURATION_SUB': '选择为该账户启用的相关服务。如果您没有找到所需的服务，可在上级账户层级添加服务，此项请求需要获得审批。',
	'ONBOARDING.ADD_NETWORK_ENTITLEMENTS': '添加网络权限',
	'ONBOARDING.ADD_RATE_PLANS': '添加费率计划',
	'ONBOARDING.VIEW_OR_EDIT_REPORTS_TO_BE_DISPLAYED': '查看或编辑需要显示的报告',
	'ONBOARDING.USERS': '用户',
	'ONBOARDING.USERS_SUB': '点击下面的按钮，您将被引导至公司身份屏幕。可在该屏幕邀请新用户加入此账户，或将现有用户添加至此公司。',
	'ONBOARDING.CREATE_USERS': '创建用户',
	'ONBOARDING.USER_ACCESS': '用户访问',
	'ONBOARDING.CREATE_ADD_USERS_IN_IOT_IDENTITY_APP': '创建/添加用户',
	'ONBOARDING.VERIFY_NEW_COMPANY_INFORMATION': '确认新公司信息',
	'ONBOARDING.SUBMIT': '提交',
	'ONBOARDING.CREATE_COMPANY': '创建公司',
	'ONBOARDING.NEW_ACCOUNT': '新账户',
	'ONBOARDING.NEW_ACCOUNT_SUBHEADLINE': '账户或子账户必须先被加入机对机（M2M）平台，然后才可添加至移动网络代码（MNC）。请按照下列步骤在IoT Console中创建机对机（M2M）平台。',
	'ONBOARDING.VALIDATE': '验证',
	'ONBOARDING.VALIDATE_ACCOUNT_NAME': '验证账户名称',
	'ONBOARDING.CREATE_ACCOUNT': '创建账户',
	'ONBOARDING.ADD_ACCOUNT': '添加账户',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE': '无可用的账户',
	'ONBOARDING.M2M_PLARFORM': '机对机（M2M）平台',

	'ONBOARDING.ADD_SIM_ORDERING_SKUS_SUCCESS': '已成功创建储存单位（SKU）',
	'ONBOARDING.ADD_SIM_ORDERING_SKUS_ERROR': '无法创建储存单位（SKU）',
	'ONBOARDING.EDIT_NETWORK_ENTITLEMENTS_FOR': '为{companyName}编辑网络权限',

	'ONBOARDING.EDIT_SUB_ACCOUNT': '编辑子账户',
	'ONBOARDING.VIEW_SUB_ACCOUNT': '查看子账户',
	'ONBOARDING.EDIT_RATE_PLANS_FOR': '为{companyName}编辑费率计划',
	'ONBOARDING.VIEW': '查看',
	'ONBOARDING.VIEW_NETWORK_ENTITLEMENTS': '查看网络权限',
	'ONBOARDING.VIEW_RATE_PLANS': '查看费率计划',
	'ONBOARDING.CREATE_SUB_ACCOUNT': '创建子账户',
	'ONBOARDING.NEW_SUB_ACCOUNT': '为{companyName}创建的新子账户',
	'ONBOARDING.GENERATE_M2M_ACCOUNT': '生成机对机（M2M）账户',
	'ONBOARDING.SUB_ACCOUNT_NAME': '子账户名称',
	'ONBOARDING.SELECT_RATE_PLANS_FOR_THIS_ACCOUNT': '为此账户选择费率计划',
	'ONBOARDING.SELECT_NETWORK_ENTITLEMENTS_FOR_THIS_ACCOUNT': '为此账户选择网络权限',

	'ONBOARDING.COUNTRY_NOT_SUPPORTED': '警告：目前尚不支持美国以外的其他国家',
	'ONBOARDING.ASSIGN_TO_SUB_ACCOUNT': '指派给子账户',
	'ONBOARDING.ENDPOINT_ID': '端点ID',
	'ONBOARDING.NETWORK_OPERATOR': '网络运营者',
	'ONBOARDING.STATUS': '状态',
	'ONBOARDING.ENDPOINTS_SELECTED_TO_MOVE': '端点将被移至某个账户',
	'ONBOARDING.CONFIRM_ENDPOINTS_MOVE': '确认端点移动',
	'ONBOARDING.ONCE_YOU_INITIATE_THIS_MOVE': '一旦开始此项移动，选择的端点将在几分钟内被移动。您确定想要继续吗？',
	'ONBOARDING.ENDPOINTS': '端点',
	'ONBOARDING.MOVE_TO_SUB_ACCOUNT': '移至子账户',
	'ONBOARDING.FROM': '来自',
	'ONBOARDING.TO': '去往',
	'ONBORDING.SIM_SKU': 'SIM卡储存单位（SKU）',
	'ONBORDING.UNIT_PRICE': '单价',
	'ONBORDING.DATE_SUBMITED': '提交日期',
	'ONBORDING.EDIT_DELETE_SKU': '编辑/删除储存单位（SKU）',
	'ONBOARDING.NO_ENDPOINTS_AVAILABLE': '无可用的端点',
	'ONBOARDING.NO_SIMS_AVAILABLE': '无可用的SIM卡',
	'ONBOARDING.SELECT_SIM_FOR_THIS_ACCOUNT': '为此账户选择SIM卡',
	'ONBOARDING.NO_ACCOUNTS_AVAILABLE_SUBTITLE': '本表格目前无可显示的账户',
	//  RATE PLANS PAYMENT TYPE DROPDOWNS
	'ONBOARDING.MONTHLY': '每月',
	'ONBOARDING.PREPAID': '预付',
	//  RATE PLANS PLAN TYPE DROPDOWNS
	'ONBOARDING.MONTHLY_INDIVIDUAL_SUBSCRIBER': '每月 — 个人订户',
	'ONBOARDING.MONTHLY_FIXED_POOL_SINGLE': '每月 — 固定汇总 — 单独',
	'ONBOARDING.ESSENTIAL_MONTHLY_FIXED_POOL': '重要 — 每月固定汇总',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL': '每月 — 灵活汇总',
	'ONBOARDING.ESSENTIAL_MONTHLY_FLEXIBLE_POOL': '重要l — 每月灵活汇总',
	'ONBOARDING.MONTHLY_FLEXIBLE_POOL_USAGE_TIER': '每月 — 灵活汇总用量分级',
	'ONBOARDING.MONTHLY_BOLT_ON': '每月 — 附加',
	'ONBOARDING.PREPAID_INDIVIDUAL_SUBSCRIBER': '预付 — 个人订户',
	'ONBOARDING.PREPAID_FLEXIBLE_POOL': '预付 — 灵活汇总',
	'ONBOARDING.PREPAID_FIXED_POOL': '预付 — 固定汇总',
	'ONBOARDING.PREPAID_ADD_ON': '预付 — 附加项',
	'ONBOARDING.PREPAID_EVENT': '预付 — 事件',
	'ONBOARDING.PREPAID_STACKED_EVENT': '预付 — 叠加事件',
	'ONBOARDING.MFPM': 'MFPM',
	'ONBOARDING.MFPS': 'MFPS',
	'ONBOARDING.MFP': 'MFP',
	'ONBOARDING.MIS': 'MIS',
	'ONBOARDING.DP': 'DP',
	'ONBOARDING.PE': 'PE',
	'ONBOARDING.PAO': 'PAO',
	'ONBOARDING.PFP': 'PFP',
	'ONBOARDING.PIM': 'PIM',
	'ONBOARDING.PIS': 'PIS',
	//  REPORTS CHECKBOXES
	'ONBOARDING.DATA_USAGE_DETAIL': '数据用量详情',
	'ONBOARDING.SMS_USAGE_DETAILS': '短信服务（SMS）用量详情',
	'ONBOARDING.IMSI_SNAPSHOT': 'IMSI快照',
	'ONBOARDING.SUBSCRIBER_DIRECTORY': '订户目录',
	'ONBOARDING.SUBSCRIBER_SNAPSHOT': '订户快照',
	'ONBOARDING.SUBSCRIBER_CHANGES': '订户变更',
	'ONBOARDING.ACCOUNT_DAILY_SNAPSHOT': '账户每日快照',
	'ONBOARDING.RATE_PLAN_SNAPSHOT': '费率计划快照',
	'ONBOARDING.COMMUNICATION_PLAN_SNAPSHOT': '通讯计划快照',
	'ONBOARDING.BILLING_CYCLE_REFERENCE': '账单周期参考',
	'ONBOARDING.CARRIER_REFERENCE': '服务运营商参考',
	'ONBOARDING.RATE_PLAN_TYPE_REFERENCE': '费率计划类型参考',
	'ONBOARDING.SIM_CHANGE_REFERENCE': 'SIM卡变更参考',
	'ONBOARDING.USAGE_RECORD_CLOSE_CAUSE_REFERENCE': '用量记录关闭原因参考',
	'ONBOARDING.RATE_PLAN_ZONE_SNAPSHOT': '费率计划区快照',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FIELDS': '服务提供者定制字段',
	'ONBOARDING.USER_SNAPSHOT': '用户快照',
	'ONBOARDING.NEW_SUBSCRIBERS': '新订户',
	'ONBOARDING.COMBINED_SUBSCRIBERS': '合并订户',
	'ONBOARDING.COMBINED_USAGE': '合并用量',
	'ONBOARDING.APN_SNAPSHOT': 'APN快照',
	'ONBOARDING.SERVICE_PROVIDER_CUSTOM_FILEDS': '服务提供者定制字段',
	'ONBOARDING.COMBINED_ACCOUNTS': '合并账户',
	'ONBOARDING.DAILY_USAGE_RAW': '每日用量原始数据',
	'ONBOARDING.ALL_ACTIVE_SIMS': '所有使用中的SIM卡',
	'ONBOARDING.DAILY_USAGE': '每日用量',
	'ONBOARDING.ALL_SMS_MESSAGES': '所有短信（SMS）讯息',
	'ONBOARDING.SMS_DETAIL': '短信服务（SMS）详情',
	'ONBOARDING.ICCID_CUMULATIVE_SUMMARY': '集成线路卡识别符（ICCID）累计总结',
	'ONBOARDING.SIM_STATE_REFERENCE': 'SIM卡状态参考',
	'ONBOARDING.VOICE_USAGE_DETAIL': '语音用量详情',
	'ONBOARDING.ALL_ASSIGNED_SIMS': '所有已指派的SIM卡',
	'ONBOARDING.INVOICE_DETAIL': '账单详情',
	'ONBOARDING.RATE_PLAN_SUMMARY': '费率计划总结',
	'ONBOARDING.OTHER_CHARGES': '其他收费',
	'ONBOARDING.USAGE_SUMMARY': '用量总结',
	'ONBOARDING.ACTIVATION_CHARGES': '激活收费',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAIL': '费率计划区用量详情',
	'ONBOARDING.INVOICE_CHARGES': '账单收费',
	'ONBOARDING.POOLED_USAGE_CHARGES': '汇总用量收费',
	'ONBOARDING.SUBSCRIBER_CHARGES': '订户收费',
	'ONBOARDING.NON_POOLED_USAGE_CHARGES': '非汇总用量收费',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_USAGE': '订户批发用量',
	'ONBOARDING.FUNDED_PACKAGES': '充值的套装',
	'ONBOARDING.TRIAL_COST_SHARE': '试用共享费用',
	'ONBOARDING.REVENUE_SHARE': '营收共享',
	'ONBOARDING.BOUNTY': '奖励金',
	'ONBOARDING.DATA_USAGE_DETAILS': '数据用量详情',
	'ONBOARDING.SMS_DETAILS': '短信服务（SMS）详情',
	'ONBOARDING.SUBSCRIPTIONS_DELTA': '订阅详情',
	'ONBOARDING.UNDELIVERED_SMS_MESSAGES': '未发出的所有短信',
	'ONBOARDING.SUBSCRIBER_WHOLESALE_ZERO_REVENUE': '订户批发零营收',
	'ONBOARDING.INVOICE_DETAILS': '账单详情',
	'ONBOARDING.RATE_PLAN_ZONE_USAGE_DETAILS': '费率计划区用量详情',
	'ONBOARDING.BILLING_INVOICE': '账单',
	//  SIM ORDERING DROPDOWN
	'ONBOARDING.1_MONTH': '1个月',
	'ONBOARDING.3_MONTHS': '3个月',
	'ONBOARDING.1_WEEK': '1周',
	'ONBOARDING.ACCOUNT_TRANSFER_SUCCESS': '已成功转让账户',
	'ONBOARDING.ACCOUNT_TRANSFER_ERROR': '转移账户失败',
	'ONBOARDING.MY_COMPANY': '我的公司',
	// USER MANAGEMENT
	'ONBOARDING.USER_GROUPS': '用户群组',
	'ONBOARDING.USER_COUNT': '用户数量',
	'ONBOARDING.CURRENTLY_ACTIVE_USERS': '当前活跃用户',
	'ONBOARDING.FIRST_NAME': '名',
	'ONBOARDING.LAST_NAME': '姓',
	'ONBOARDING.JOB_TITLE': '职务',
	'ONBOARDING.EMAIL': '电子邮件',
	'ONBOARDING.USER_GROUP': '用户群组',
	'ONBOARDING.ACCOUNT_ACCESS': '账户访问',
	'ONBOARDING.CREATE_NEW_GROUP': '创建新群组',
	'ONBOARDING.INVITE_USERS': '邀请用户',
	'ONBOARDING.ADD_USERS': '添加用户',
	'ONBOARDING.EDIT_USER_PROFILE': '编辑用户档案',
	'ONBOARDING.USER_INFORMATION': '用户信息',
	'ONBOARDING.ADDRESSES': '地址',
	'ONBOARDING.ASSIGNED_ACCOUNTS': '指派的账户',
	'ONBOARDING.LEGAL_AND_REGULATORY': '法务监管',
	'ONBOARDING.CREATE_NEW_USER_GROUP': '创建新用户群组',
	'ONBOARDING.CREATE_CUSTOMIZED_ENTITLEMENT_GROUP': '为您的用户群组创建定制化访问权限。',
	'ONBOARDING.USERNAME_ACCEPTED_END_USER_LICENCE': '{name}{lastName}已接受',
	'ONBOARDING.END_USER_LICENCE_AGREEMENT': '终端用户许可协议',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE': '如果您不同意终端用户许可协议中的条款，则可能需要删除此账户。',
	'ONBOARDING.ON_DATE': '于{date}。',
	'ONBOARDING.IF_YOU_DO_NOT_AGREE_WITH_THE_TERMS_OF_EULA_YOU_MAY_DELETE_THIS_ACCOUNT': '如果您不同意终端用户许可协议中的条款，则可能需要删除此账户。',
	'ONBOARDING.ENTER_THE_EMAIL_ADDRESSES_FOR_THE_USERS': '请输入您需要邀请用户的电子邮件地址。该用户点击通知邮件中所附带的链接并接受邀请后即可访问公司账户。',
	'ONBOARDING.ADD_NEW_USER': '添加新用户',
	'ONBOARDING.CAN_RECEIVE_SMS': '可接收短信',
	'ONBOARDING.STATE_PROVINCE_TERRITORY_REGION': '州/省/领地/地区',
	'ONBOARDING.COMPANY_EMAIL': '公司电子邮件',
	'ONBOARDING.POSTAL_CODE': '邮政编码',
	'ONBOARDING.BUSINESS': '业务',
	'ONBOARDING.USER_PRIVACY_IS_IMPORTANT_TO_US': '我们非常在意用户隐私，绝不会将您的用户信息售予任何第三方。',
	'ONBOARDING.EULA_CONTAINS_IN_DEPTH_INFORMATION_ABOUT_HOW_WE_PROTECT_YOUR_PRIVACY': '终端用户许可协议（EULA）中包含关于我们如何保护用户隐私权、删除权，以及其他合规行为的详细信息。',
	'ONBOARDING.STREET_ADDRESS_2': '街道地址2',
	'ONBOARDING.STREET_ADDRESS_1': '街道地址1',
	'ONBOARDING.CITY_OR_MUNICIPALITY': '市或直辖市',
	'ONBOARDING.SAME_ADDRESS_AS_PRIMARY': '与主要地址相同',
	'ONBOARDING.ADD_ANOTHER_ADDRESS': '添加另一个地址',
	'ONBOARDING.YES': '是',
	'ONBOARDING.NO': '否',
	'ONBOARDING.PRIMARY_ADDRESS': '主要地址',
	'ONBOARDING.ADDRESS_TYPE': '地址类型',
	'ONBOARDING.SECONDARY_NUMBER': '备用号码',
	'ONBOARDING.PREFERRED_LANGUAGE': '首选语言',
	'ONBOARDING.PRIMARY_NUMBER': '主要号码',
	'ONBOARDING.UPLOAD_IMAGE': '上载图片',
	'ONBOARDING.FILTER_USER_LIST': '过滤用户清单',
	'ONBOARDING.CANCEL': '取消',
	'ONBOARDING.ADD': '添加',
	'ONBOARDING.USER': '用户',
	'ONBOARDING.GROUP': '群组',
	'ONBOARDING.PASSWORD_RESET': '重设密码',
	'ONBOARDING.SELECT_ACTION': '选择操作',
	'ONBOARDING.ACTIVE_STATUS': '激活状态',
	'ONBOARDING.PENDING_STATUS': '待决状态',
	'ONBOARDING.ADD_MORE_USERS': '添加更多用户',
	'ONBOARDING.ENTITLEMENT_NAME': '权限名称',
	'ONBOARDING.NO_ACCESS': '禁止访问',
	'ONBOARDING.READ_ONLY': '只读',
	'ONBOARDING.READ_WRITE': '读写',

	'ONBORDING.ADMIN_GROUP_PERMISSIONS': '管理员群组权限',
	'ONBORDING.EDIT_GROUP_PERMISSIONS': '编辑群组权限',
	'ONBORDING.USER_GROUPS': '用户群组',
	'ONBOARDING.CREATE_GROUP': '创建群组',
	'ONBOARDING.SELECT_EXISTING_USER_GROUP': '创建现有的用户群组',
	'ONBOARDING.GROUP_NAME': '分组名称',
	'ONBOARDING.PAUSE': '暂停',
	'ONBOARDING.REACTIVATE': '重新激活',
	'ONBOARDING.ACTIVATE': '激活',
	'ONBOARDING.REMOVE': '移除',
	'ONBOARDING.RESEND_INVITE': '重新发送邀请',
	'ONBOARDING.CANCEL_INVITE': '取消邀请',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_USER': '您确定想要移除此用户吗？',
	'ONBOARDING.USER_PROFILE': '用户档案',
	'ONBOARDING.SUSPEND': '暂停',
	'ONBOARDING.USER_MANAGEMENT': '用户管理',
	'ONBOARDING.MAILING': '邮寄',
	'ONBOARDING.BILLING': '账单',
	'ONBOARDING.SECONDARY': '备用',
	'ONBOARDING.PRIMARY': '主要',
	'ONBOARDING.PHONE': '电话',
	'ONBOARDING.ENGLISH': '英语（美国）',
	'ONBOARDING.SAVE_USER': '保存用户',
	'ONBOARDING.BULK_UPLOAD': '成批上载',
	'ONBOARDING.FOR_BULK_UPLOADS': '用于批量上载',
	'ONBOARDING.DOWNLOAD_CSV_TEMPLATE': '下载CSV模板',
	'ONBOARDING.SEND_INVITE': '发送邀请',
	'ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS': '被暂停的用户将无法访问{companyName}账户。被暂停的用户将无法申请获得访问权，但管理员可重新激活用户账户并允许访问，此时被暂停的用户必须重新设置账户密码。',
	'ONBOARDING.PAUSE_USER': '暂停用户',
	'ONBOARDING.ASSIGNED_USER_GROUP': '指派的新用户群组',
	'ONBOARDING.ACCESS_LEVEL': '访问级别',
	'ONBOARDING.SEND_PASSWORD_RESET_LINK_TO_EMAIL': '向电子邮件地址发送重置密码链接',
	'ONBOARDING.EDIT_USER': '编辑用户',
	'ONBOARDING.RESET_PASSWORD': '重设密码',
	'ONBOARDING.CURRENT_STATUS': '当前状态',
	'ONBOARDING.VIEW_USER_ASSIGNED_PERMISSIONS': '查看已指派的用户权限',
	'ONBOARDING.MANAGE_INDIVIDUAL_PERMISSIONS': '管理单独权限',
	'ONBOARDING.INDIVIDUAL_PERMISSIONS': '单独权限',
	'ONBOARDING.EFFECTIVE_PERMISSIONS': '生效的权限',
	'ONBOARDING.ASSIGNED_USER_GROUPS': '指派的用户群组',
	'ONBOARDING.ACTION': '行动',
	'ONBOARDING.DELETE_PERMISSIONS': '删除权限',
	'ONBOARDING.VIEW_PERMISSIONS': '查看权限',
	'ONBOARDING.ADD_ANOTHER_USER_GROUP': '添加另一个用户群组',
	'ONBOARDING.PENDING_INVITATIONS': '待决的邀请',
	'ONBOARDING.PERMISSIONS': '权限',
	'ONBOARDING.CLOSE': '关闭',
	'ONBOARDING.SAVE_INDIVIDUAL_PERMISSIONS': '保存单独权限',
	'ONBOARDING.ACCESS_WILL_BE_GRANTED_TO_THE_FOLLOWING_M2M_ACCOUNTS': '将为下列M2M账户授予访问权',
	'ONBOARDING.ACCESS_MANAGEMENT': '访问管理',
	'ONBOARDING.SERVICE_PROVIDER_USERS': '服务提供者用户',
	'ONBOARDING.SERVICE_PROVIDER_USER_GROUPS': '服务提供者用户群组',
	'ONBOARDING.ADD_SERVICE_PROVIDER_USERS': '添加可以管理客户账户以及引入新账户的服务提供者用户',
	'ONBOARDING.NO_USER_GROUPS_AVAILABLE': '没有可用的用户群组',

	// USER GROUP PERMISSIONS
	'ONBOARDING.IF_YOU_NEED_TO_PERFORM_A_RESTRICTED_OPERTATION': '如果您需要进行受限制的操作，请向技术联系人寻求帮助。',
	'ONBOARDING.SOME_CONFIGURATIONS_ARE_LIMITED_TO_SUPERUSERS': '某些配置仅限于AT&T超级用户。',
	'ONBOARDING.USER_GROUPS_ARE_BUILT_USING_LEAST_PRIVILEGE-MODEL': '用户群组基于最低权限模式。',
	'ONBOARDING.SYSTEM_LEVEL_ENTITLEMENTS': '系统级权限',
	'ONBOARDING.DENY_ACCESS': '拒绝访问',
	'ONBOARDING.ALLOW_READ': '允许读取',
	'ONBOARDING.ALLOW_WRITE': '允许写入',
	'ONBOARDING.EDIT_GROUP_PERMISSIONS': '编辑群组权限',
	'ONBOARDING.NO_PERMISSIONS_AVAILABLE': '没有可用的权限',

	'ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE_SUBTITLE': '本表格目前无可显示的网络权限',
	'ONBOARDING.EDIT_M2M_ACCOUNTS': '编辑机对机（M2M）账户',
	'ONBOARDING.MOVE_TO_SUBACCOUNT': '移至子账户',
	'ONBOARDING.CAN_RECIVE_EMAIL': '可以接收电子邮件',
	'ONBOARDING.UNRESTRICTED_ACCESS': '不受限的访问',
	'ONBOARDING.ACTIVE': '活跃', // 稍后删除
	'ONBOARDING.PAUSED': '已暂停', // 稍后删除
	'ONBOARDING.VIEW_USER_GROUP': '查看用户群组',
	'ONBOARDING.DELETE_USER_GROUP': '删除用户群组',
	'ONBOARDING.SAVE': '保存',
	'ONBOARDING.SELECT_THE_GROUP_USER_SHOULD_BELONG_TO': '选择该用户应当归属的群组：',

	'ONBOARDING.ALLOW_ACCESS': '允许访问',

	// ACCOUNT ACTIVATION
	'ONBOARDING.CREATE_NEW_PASSWORD': '创建新密码',
	'ONBOARDING.NEW_PASSWORD': '新密码',
	'ONBOARDING.CONFIRM_PASSWORD': '确认密码',
	'ONBOARDING.ENTER_NEW_PASSWORD': '输入新密码',
	'ONBOARDING.NEW_PASSWORD_DESCRIPTION': '请输入您的新密码',
	'ONBOARDING.GO_TO_HOME_PAGE': '返回登录',
	'ONBOARDING.INFORMATION_NOT_PROVIDED': '未提供信息。',
	'ONBOARDING.TO_ADD_ADDRESS_DETAILS': '添加详细地址。',
	'ONBOARDING.EDIT_ASSIGNED_ACCOUNTS': '编辑指派的账户',
	'ONBOARDING.EDIT_PERMISSIONS': '编辑权限',
	'ONBOARDING.SUSPENDED': '已暂停',
	'ONBOARDING.PENDING_INVITATION_AVAILABLE': '有待决的邀请',
	'ONBOARDING.IN_NEXT_STEP_YOU_WILL_BE_ABLE_TUNE_NEW_GRUP_PERMISSIONS': '您可以在下一步调整新群组的权限',
	'ONBOARDING.GRANT_ACCESS': '允许访问',
	'ONBOARDING.SELECT_USER_GROUP': '请选择用户群组',
	'ONBOARDING.NO_SYSTEM_INVITATIONS_AVAILABLE': '系统邀请已用尽',
	'ONBOARDING.ADD_USER_GROUP': '添加用户群组',
	'ONBOARDING.ADD_ADDRESS': '添加地址',
	'ONBOARDING.CROP_IMAGE': '裁剪图片',
	'ONBOARDING.CROP': '裁剪',
	'ONBOARDING.THIS_ACTION_IS_IRREVERSIBLE_AND_HISTORY_OF_THE_USER_WILL_BE_FORGOTTEN': '本操作不可逆，我们的系统将抹消该用户的所有历史记录。',
	'ONBOARDING.DELETE_THE_PROFILE_WILL_REMOVE_USER_ACCESS': '删除档案将从您目前有权访问的所有账户中移除用户权限。',
	'ONBOARDING.DELETE_USERNAME_PROFILE': '删除{name}{lastName}的档案',
	'ONBOARDING.ENTER_CONFIRMATION_PHRASE': '输入确认短语',
	'ONBOARDING.DELETE_PROFILE': '删除档案',
	'ONBOARDING.DELETE_USER': '删除用户',
	'ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL': '暂停的理由（选填）',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED': '您确定想要继续吗？',
	'ONBOARDING.REACTIVATE_USER': '重新激活用户',
	'ONBOARDING.IF_YOU_CLICK_YES_YOU_WILL_RECEIVE_PASSWORD_RESTART_EMAIL_SHORTLY': '如果点击“是”，您将很快收到重设密码的电子邮件。',
	'ONBOARDING.RESET_USER_PASSWORD': '重设您的用户密码',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PROCEED_WITH_THE_PASSWORD_RESET': '您确定想要继续重设密码吗？',
	'ONBOARDING.ASSIGN_USER_GROUP': '指派用户群组',
	'ONBOARDING.ASSIGN': '指派',
	'ONBOARDING.AND_OTHERS': '以及其他{totalCount}个...',
	'ONBOARDING.MY_PROFILE': '我的档案',
	'ONBOARDING.EDIT_USER_GROUP': '编辑用户群组',
	'ONBOARDING.EDIT_INDIVIDUAL_PERMISSIONS': '编辑单独权限',
	'ONBOARDING.VIEW_INDIVIDUAL_PERMISSIONS': '查看单独权限',
	'ONBOARDING.NO_USERS_AVAILABLE': '没有可用的用户',
	'ONBOARDING.CONTACTS': '联系人',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_OPERATION': '您确定想要继续此操作吗？',
	'ONBOARDING.REMOVE_USER_GROUP': '移除用户群组',
	'ONBOARDING.CONTACT_TYPE': '联系人种类',
	'ONBOARDING.CANCEL_INVITATION': '取消邀请',
	'ONBOARDING.RESEND_INVITATION': '重新发送邀请',
	'ONBOARDING.REACTIVATE_USER_GROUP': '重新激活用户群组',
	'ONBOARDING.SUSPEND_USER_GROUP': '中止用户群组',
	'ONBOARDING.THERE_ARE_NO_USER_GROUPS_TO_ASSIGN': '没有可供指派的用户群组',
	'ONBOARDING.ADDRESS_TYPE_EXISTS': '地址类型存在',
	'ONBOARDING.ADD_NEW_ADDRESS': '添加新地址',
	'ONBOARDING.ADD_NEW_CONTACT': '添加新联系人',
	'ONBOARDING.ADD_ANOTHER_CONTACT': '添加另一个联系人',
	'ONBOARDING.COMPANY_FEATURES': '公司特性',
	'ONBOARDING.INFO_NOT_PROVIDED': '未提供信息',
	'ONBOARDING.COMPANY_SETTINGS': '公司设置',
	'ONBOARDING.CHANGES_TO_YOUR_ACCOUNT': '如果您已经拥有账户，请登录。',
	'ONBOARDING.ACCOUNT_ACTIVATED': '您很快会收到一封电子邮件，内有关于创建密码、访问账户的说明。',
	'ONBOARDING.PASSWORD_RESET_IS_MANAGED_BY_ATT_GLOBAL_SIGN_ON': '重设密码功能由AT&T Global Sign-On管理',

	//  账单平台
	'ONBOARDING.UB': 'UB',
	'ONBOARDING.MPOWER': 'mPower',

	//  地址类型
	'ONBOARDING.BILLING_ADDRESS': '账单地址',
	'ONBOARDING.HOME_ADDRESS': '居住地址',

	//  联系人种类
	'ONBOARDING.MAIN_CONTACT': '主要联系人',
	'ONBOARDING.BACKUP_CONTACT': '备用联系人',
	'ONBOARDING.TECHNICAL_OWNER': '技术拥有者',

	//  地址类型
	'ONBOARDING.SHIPPING': '发货',
	'ONBOARDING.HOME': '主页',
	'ONBOARDING.ANOTHER': '其他',
	'ONBOARDING.LAUNCH_IOT_CONSOLE': '运行IoT Console',
	'ONBOARDING.THIS_NUMBER_IS_REQUIRED_TO_DISPLAY_INVOICES_ON_IN_THE_IOT_CONSOLE': '如需在IoT Console中显示账单则必须填写此号码。',
	'ONBOARDING.BILLING_REPORTS': '账单报告',
	'ONBOARDING.THIS_BOX_MUST_BE_CHECKED_TO_VIEW_INOVICE_DETAILS': '如需查看账单详情则必须勾选此项。',
	'ONBOARDING.BILLING_DETAILS_ARE_NOT_SUPPORTED_FOR_THIS_PLATFORM': '不支持此平台的账单详情。',
	'ONBOARDING.VIEW_OR_EDIT_BILLING_CONFIGURATION': '查看或编辑账单设置',
	'ONBOARDING.ACCOUNT_ID_NOT_FOUND': '未找到账户ID。',
	'ONBOARDING.ARE_YOU_SURE': '您确定吗？',
	'ONBOARDING.REMOVE_CONTACT': '移除联系人',
	'ONBOARDING.POD_TENANT_INSTANCE_ID': 'Pod、租户或实例ID',
	'ONBOARDING.PLATFORM_SPEEDCAST': 'Speedcast',
	'ONBOARDING.PLATFORM_DEVICE_CONNECTION_PLATFORM': '设备连接平台',
	'ONBOARDING.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION': '您确定想要继续此操作吗？',

// Added after 7.4
	'ONBOARDING.ANONYMIZED': '已匿名化处理',
  'ONBOARDING.ACQUISITION_BOUNTY': '采办奖励金',
	'ONBOARDING.COMBINED_ACQUISITION_BOUNTY': '综合采办奖励金',
  'ONBOARDING.COMBINED_HARDWARE_SUBSIDY': '综合硬件补贴',
  'ONBOARDING.COMBINED_PARTNER_FUNDED_PACKAGES': '综合伙伴充值的套装',
	'ONBOARDING.COMBINED_POOLED_USAGE_CHARGES': '综合汇总用量收费',
  'ONBOARDING.COMBINED_SUBSCRIPTION_CHARGES': '综合订阅收费',
  'ONBOARDING.COMBINED_TRIAL_COST_SHARE': '综合试用共享费用',
  'ONBOARDING.COMBINED_USAGE_SUMMARY': '综合用量总结',
  'ONBOARDING.MONTHLY_COMBINED_INVOICE_CHARGES': '每月综合账单收费',
  'ONBOARDING.MONTHLY_COMBINED_INVOICE_DETAIL': '每月综合账单详情',
  'ONBOARDING.MONTHLY_COMBINED_NON_POOLED_USAGE_CHARGES': '每月综合非汇总用量收费',
  'ONBOARDING.MONTHLY_COMBINED_OTHER_CHARGES': '每月综合其他收费',
  'ONBOARDING.PARTNER_FUNDED_PACKAGES': '伙伴充值的套装',
  'ONBOARDING.ACCOUNT_DEVICE_STATUS_BY_MONTH': '按月显示账户设备状态',
  'ONBOARDING.ACCOUNT_USAGE_REPORT_BY_SATELLITE_NETWORK': '卫星网络账户用量报告',
};
