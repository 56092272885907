import globalAction from '../../../../redux/global/globalAction';

import { GET_BILLING_GROUPS, GET_BILLING_GROUPS_FILTERS } from './constants';

import {
	getBillingGroups as getBillingGroupsService,
	getBillingGroupsFilters as getBillingGroupsFiltersService
} from '../../services/BillingGroupsService';

/** ************ get billing groups ************* */
export const getBillingGroups = (id, params = {}) =>
	globalAction(GET_BILLING_GROUPS, getBillingGroupsService, {
		id,
		params
	});

/** ************ get billing groups filters ************* */
export const getBillingGroupsFilters = (id, params = {}) =>
	globalAction(GET_BILLING_GROUPS_FILTERS, getBillingGroupsFiltersService, {
		id,
		params
	});
