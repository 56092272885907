import React, { PureComponent } from 'react';
import { string, func, bool, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PermissionsView from '../../views/PermissionsView';
import PageTitle from '../../../Shared/views/PageTitleView';

import styles from './EffectivePermissions.scss';

export default class UserEffectivePermissions extends PureComponent {
	pushBack = () => {
		const {
			system,
			match: {
				params: { id }
			},
			myProfile,
			push
		} = this.props;
		let url;
		if (system) {
			url = `/access-management/users/${id}`;
		}
		if (myProfile) {
			url = '/my-profile';
		} else if (!system) {
			url = `/user/${id}`;
		}
		push(url);
	};

	render() {
		const {
			match: {
				params: { id }
			},
			myProfile
		} = this.props;

		return (
			<div data-spec="user-effective-permissions">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.EFFECTIVE_PERMISSIONS"
							defaultMessage="Effective Permissions"
						/>
					}
					pushBack={this.pushBack}
				/>
				<div className={styles.effective_permissions_wrapper}>
					<PermissionsView {...this.props} id={id} myProfile={myProfile} />
				</div>
			</div>
		);
	}
}

UserEffectivePermissions.propTypes = {
	match: object,
	id: string,
	getPermissions: func,
	permissionsRequest: bool,
	permissions: object,
	push: func,
	system: bool,
	myProfile: bool
};
