import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../BatchReports';
import styles from '../BatchReports.scss';

const Desktop = (props) => {
	const { messages, getOptions, batchReports } = props;
	return (
		<div data-spec="batch-reports-desktop" className={styles.table_wrapper}>
			<Table
				data={batchReports}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { object, func, array } = PropTypes;

Desktop.propTypes = {
	messages: object,
	getOptions: func,
	batchReports: array
};

export default hoc()(Desktop);
