import { Status } from '../../../../utils/constants';

/* ******************** ADD NETWORK ENTITLEMENT ******************** */
export const addNetworkEntitlementRequest = (state) =>
	state.onboarding.networkEntitlements.addNetworkEntitlement.status ===
	Status.PENDING;
export const addNetworkEntitlementFail = (state) =>
	state.onboarding.networkEntitlements.addNetworkEntitlement.status ===
	Status.FAIL;
export const addNetworkEntitlementSuccess = (state) =>
	state.onboarding.networkEntitlements.addNetworkEntitlement.data;

/* ******************** ADD NETWORK ENTITLEMENT MODAL ******************** */
export const isAddNetworkEntitlementsModalOpened = (state) =>
	state.onboarding.networkEntitlements.addNetworkEntitlementsModal.isOpen;

/* ******************** EDIT NETWORK ENTITLEMENT ******************** */
export const editNetworkEntitlementRequest = (state) =>
	state.onboarding.networkEntitlements.editNetworkEntitlement.status ===
	Status.PENDING;
export const editNetworkEntitlementFail = (state) =>
	state.onboarding.networkEntitlements.editNetworkEntitlement.status ===
	Status.FAIL;
export const editNetworkEntitlementSuccess = (state) =>
	state.onboarding.networkEntitlements.editNetworkEntitlement.status ===
	Status.DONE;

/* ******************** GET NETWORK ENTITLEMENT ******************** */
export const getNetworkEntitlementRequest = (state) =>
	state.onboarding.networkEntitlements.getNetworkEntitlement.status ===
	Status.PENDING;
export const getNetworkEntitlementFail = (state) =>
	state.onboarding.networkEntitlements.getNetworkEntitlement.status ===
	Status.FAIL;
export const getNetworkEntitlementSuccess = (state) =>
	state.onboarding.networkEntitlements.getNetworkEntitlement.data;

/* ******************** GET NETWORK ENTITLEMENTS ******************** */
export const getNetworkEntitlementsRequest = (state) =>
	state.onboarding.networkEntitlements.getNetworkEntitlements.status ===
	Status.PENDING;
export const getNetworkEntitlementsFail = (state) =>
	state.onboarding.networkEntitlements.getNetworkEntitlements.status ===
	Status.FAIL;
export const getNetworkEntitlementsSuccess = (state) =>
	state.onboarding.networkEntitlements.getNetworkEntitlements.data;

/* ******************** GET PARENT NETWORK ENTITLEMENTS ******************** */
export const getParentNetworkEntitlementsRequest = (state) =>
	state.onboarding.networkEntitlements.getParentNetworkEntitlements.status ===
	Status.PENDING;
export const getParentNetworkEntitlementsFail = (state) =>
	state.onboarding.networkEntitlements.getParentNetworkEntitlements.status ===
	Status.FAIL;
export const getParentNetworkEntitlementsSuccess = (state) =>
	state.onboarding.networkEntitlements.getParentNetworkEntitlements.data;

/* ******************** ADD SUB ACCOUNT NETWORK ENTITLEMENTS ******************** */
export const addSubAccountNetworkEntitlementsRequest = (state) =>
	state.onboarding.networkEntitlements.addSubAccountNetworkEntitlements
		.status === Status.PENDING;
export const addSubAccountNetworkEntitlementsFail = (state) =>
	state.onboarding.networkEntitlements.addSubAccountNetworkEntitlements
		.status === Status.FAIL;
export const addSubAccountNetworkEntitlementsSuccess = (state) =>
	state.onboarding.networkEntitlements.addSubAccountNetworkEntitlements.data;

/* ******************** SHOW ASSOCIATED APNS ******************** */
export const showAssociatedApnsRequest = (state) =>
	state.onboarding.networkEntitlements.showAssociatedApns.status ===
	Status.PENDING;
export const showAssociatedApnsFail = (state) =>
	state.onboarding.networkEntitlements.showAssociatedApns.status ===
	Status.FAIL;
export const showAssociatedApnsSuccess = (state) =>
	state.onboarding.networkEntitlements.showAssociatedApns.data;
