/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import cn from 'classnames';
import { isEqual } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { injectIntl, FormattedMessage } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import {
	updateBulkSingleEndpoint as updateBulkSingleEndpointAction,
	updateBulkSingleEndpointStatusStarted as updateBulkSingleEndpointStatusStartedAction
} from '../../../redux/updateBulkSingleEndpoints/actions';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import { getErrors } from '../../../../Shared/redux/getStatusCode/selectors';
import {
	getCurrentSim,
	isBulkSingleEndpointUpdating,
	singleBulkEndpointUpdateFail,
	singleBulkEndpointUpdateSuccess,
	getFailSims,
	getSuccessSims,
	getIsStarted
} from '../../../redux/updateBulkSingleEndpoints/selectors';
import { getDateFormat } from '../../../../../utils/constants';

import styles from './Steps.scss';

const Error = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const Success = ImageSelector(CurrentContext.theme, 'svgs/ok-full.svg');
const ErrorNotification = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-error.svg'
);
const Approval = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-approval.svg'
);

class StepThird extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSim: '',
			failSims: [],
			successSims: [],
			errors: null
		};
	}

	componentDidMount() {
		const {
			updateBulkSingleEndpointStatusStarted,
			updateBulkSingleEndpoint,
			values,
			context,
			isStarted,
			onClose,
			intl
		} = this.props;
		const { scheduledDate, user } = values.schedule;
		const formattedDate = format(scheduledDate, 'yyyy-MM-dd');
		const userNote = intl.formatMessage(
			{
				id: 'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE',
				defaultMessage: `User ${user.uid} made Change status on ${formattedDate}`
			},
			{ user: user.uid, date: formattedDate }
		);
		const data = { ...values.schedule, userNote };

		updateBulkSingleEndpointStatusStarted();
		updateBulkSingleEndpoint(data);

		if (context) {
			this.setFooter(context, isStarted, onClose);
		}
	}

	componentDidUpdate(prevProps) {
		const { context, isStarted, onClose } = this.props;

		if (context && !isEqual(isStarted, prevProps.isStarted)) {
			this.setFooter(context, isStarted, onClose);
		}
	}

	static getDerivedStateFromProps(nextProps) {
		const stateObject = {
			currentSim: nextProps.currentSim,
			failSims: nextProps.failSims || [],
			successSims: nextProps.successSims || []
		};

		if (nextProps.errors.length) {
			stateObject.errors = nextProps.errors;
		}

		return stateObject;
	}

	componentWillUnmount() {
		this.setState({
			currentSim: '',
			failSims: [],
			successSims: []
		});
	}

	setFooter = (context, isStarted, onClose) => {
		const footer = (
			<>
				<Button
					variant="success"
					onClick={onClose}
					disabled={isStarted}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
					dataSpec="done"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderNotification = () => {
		const { values } = this.props;
		const { successSims } = this.state;

		if (successSims && successSims.length) {
			return (
				<div data-spec="notification" className={styles.notification}>
					<div className={styles.image}>
						<Approval />
					</div>
					{values.schedule.scheduledDate !==
					format(new Date(), 'MM/dd/yyyy') ? (
						<div className={styles.schedule}>
							<div className={styles.text}>
								<FormattedMessage
									id="ENDPOINTS.SCHEDULE_SUCCESSFUL"
									defaultMessage="Multiple SIM state change is successfully scheduled!"
								/>
							</div>
							<div className={styles.schedule_text}>
								<FormattedMessage
									id="SIM_STATE_CHANGE_FOR"
									defaultMessage="SIM state change for"
								/>
								&nbsp;
								{successSims.length}
								<FormattedMessage id="OF" defaultMessage="of" />
								{values.schedule.originalSelectedSims.length}
								<FormattedMessage
									id="ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR"
									defaultMessage="SIMS is successfully scheduled for"
								/>
								{getDateFormat(values.schedule.scheduledDate)}
							</div>
						</div>
					) : (
						<div className={styles.text}>
							{successSims.length}
							<FormattedMessage id="OF" defaultMessage="of" />
							{values.schedule.originalSelectedSims.length}
							<FormattedMessage
								id="ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED"
								defaultMessage="SIMS status change successfully requested!"
							/>
						</div>
					)}
				</div>
			);
		}

		return (
			<div
				data-spec="notification"
				className={cn(styles.notification, styles.error)}
			>
				<div className={styles.image}>{<ErrorNotification />}</div>
				<div className={styles.schedule}>
					<div className={styles.text}>
						{values.schedule.scheduledDate !==
						format(new Date(), 'MM/dd/yyyy') ? (
							<FormattedMessage
								id="ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR"
								defaultMessage="Multiple SIM state change could not be scheduled successfully!"
							/>
						) : (
							<FormattedMessage
								id="ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR"
								defaultMessage="Multiple SIM state change could not be requested successfully!"
							/>
						)}
					</div>
					<div className={styles.schedule_text}>
						<FormattedMessage
							id="ENDPOINTS.PLEASE_TRY_AGAIN"
							defaultMessage="Please try again."
						/>
					</div>
				</div>
			</div>
		);
	};

	// get unique current state from all selected sims
	getCurrentSimStatus = (values) =>
		values && values.schedule.currentSelectedStates.map((item, index) => (
			<span data-spec="states" key={index}>
        <FormattedMessage id={item} defaultMessage={item} />
				{index + 1 < values.schedule.currentSelectedStates.length ? ', ' : ''}
			</span>
		));

	renderSims = (values) => (
		<ul data-spec="sims" className={styles.sims_result}>
			{values.schedule.originalSelectedSims.map((item, index) =>
				this.renderSimData(item.endpointName, index))}
		</ul>
	);

	getErrorMessage = () => {
		const { errors } = this.state;
		let errorMessage = '';

		if (errors) {
			errors.forEach((error) => {
				errorMessage += `${error.message} `;
			});
		}

		return errorMessage;
	};

	renderSimData = (sim, index) => {
		const { isUpdating } = this.props;
		const { currentSim, failSims, successSims } = this.state;

		if (sim === currentSim && isUpdating) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<div className={styles.loader}>
						<Loader size={15} data-spec="loader" />
					</div>
				</li>
			);
		}

		if (failSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<span
							className={styles.data}
							data-spec={`tooltip-${sim}`}
							data-tip={this.getErrorMessage()}
							data-for={sim}
						>
							<FormattedMessage id="ENDPOINTS.ERROR" defaultMessage="Error" />
						</span>
						<ReactTooltip type={'error'} id={sim} className={styles.tooltip} />
					</div>
				</li>
			);
		}

		if (successSims.indexOf(sim) !== -1) {
			return (
				<li data-spec="sim" key={index} className={styles.success}>
					<div className={styles.sim}>{sim}</div>
					<span>
						<Success />
					</span>
					<span>
						<FormattedMessage
							id="ENDPOINTS.SUCCESS_KEY"
							defaultMessage="Success"
						/>
					</span>
				</li>
			);
		}

		return (
			<li data-spec="sim" key={index} className={styles.start}>
				<div className={styles.sim}>{sim}</div>
			</li>
		);
	};

	render() {
		const { values, isStarted } = this.props;

		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className="confirm-content">
						<div className={`${styles.step_first} ${styles.step_third}`}>
							<h2 className={styles.touppercase}>
								<FormattedMessage
									id="ENDPOINTS.CONFIRM_SIM_STATE_CHANGE"
									defaultMessage="Confirm SIM state change"
								/>
							</h2>
							<p>
								{values.schedule.originalSelectedSims.length}
								&nbsp;
								<FormattedMessage
									id="ENDPOINTS.SIMS_SELECTED"
									defaultMessage="SIMs selected"
								/>
								(
								<FormattedMessage
									id="ENDPOINTS.CURRENT_STATES"
									defaultMessage="Current states"
								/>
								:
								{this.getCurrentSimStatus(values)}
								).
							</p>
						</div>
					</div>
					{!isStarted && this.renderNotification()}
					{this.renderSims(values)}
				</div>
			</div>
		);
	}
}

const { object, func, string, bool, array, any } = PropTypes;

StepThird.propTypes = {
	values: object,
	updateBulkSingleEndpoint: func,
	currentSim: string,
	isUpdating: bool,
	failSims: array,
	successSims: array,
	onClose: func,
	updateBulkSingleEndpointStatusStarted: func,
	isStarted: bool,
	errors: any,
	context: object,
	intl: object
};

const mapStateToProps = (state) => ({
	currentSim: getCurrentSim(state),
	isUpdating: isBulkSingleEndpointUpdating(state),
	isFail: singleBulkEndpointUpdateFail(state),
	isSucces: singleBulkEndpointUpdateSuccess(state),
	failSims: getFailSims(state),
	successSims: getSuccessSims(state),
	isStarted: getIsStarted(state),
	errors: getErrors(state)
});

const mapDispatchToProps = (dispatch) => ({
	updateBulkSingleEndpoint: (data) =>
		dispatch(updateBulkSingleEndpointAction(data)),
	updateBulkSingleEndpointStatusStarted: () =>
		dispatch(updateBulkSingleEndpointStatusStartedAction())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(injectIntl(StepThird));
