import { combineReducers } from 'redux';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	GET_CATEGORIES,
	GET_FIRST_SUBCATEGORIES,
	GET_SECOND_SUBCATEGORIES,
	GET_THIRD_SUBCATEGORIES,
	GET_SEVERITIES,
	GET_PRIORITIES,
	GET_COUNTRIES,
	GET_STATES,
	GET_CONFIRMATION,
	GET_HISTORY
} from './constants';

export default combineReducers({
	getCategories: globalReducer(GET_CATEGORIES),
	getFirstSubCategories: globalReducer(GET_FIRST_SUBCATEGORIES),
	getSecondSubCategories: globalReducer(GET_SECOND_SUBCATEGORIES),
	getThirdSubCategories: globalReducer(GET_THIRD_SUBCATEGORIES),
	getSeverities: globalReducer(GET_SEVERITIES),
	getPriorities: globalReducer(GET_PRIORITIES),
	getCountries: globalReducer(GET_COUNTRIES),
	getStates: globalReducer(GET_STATES),
  getConfirmation: globalReducer(GET_CONFIRMATION),
  getHistory: globalReducer(GET_HISTORY)
});
