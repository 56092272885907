/* eslint-disable max-classes-per-file */
/* eslint-disable require-spec-attribute-plugin/data-spec */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const ScheduleModalContext = React.createContext();

class ScheduleModalContextProvider extends React.Component {
	constructor(props) {
    super(props);
		this.state = {};
  }

	render() {
    const { children } = this.props;
		return (
			<ScheduleModalContext.Provider
				value={{
					state: this.state,
					updateContext: (newState) => this.setState(newState)
				}}
			>
        {children}
			</ScheduleModalContext.Provider>
		);
	}
}

ScheduleModalContextProvider.propTypes = {
	children: PropTypes.any
};

class ScheduleModalContextConsumer extends PureComponent {
	render() {
		const { children } = this.props;
		return (
			<ScheduleModalContext.Consumer>
				{(context) =>
					React.Children.map(children, (child) =>
						React.cloneElement(child, { ...this.props, context }))}
			</ScheduleModalContext.Consumer>
		);
	}
}

ScheduleModalContextConsumer.propTypes = {
	children: PropTypes.any
};

export {
	ScheduleModalContextProvider,
	ScheduleModalContextConsumer,
	ScheduleModalContext
};
