export default {
	// filtres
	CURRENCY: 'Devise',
	BILLABLE: 'Facturable',
	TAX_STATUS: 'État taxe',
	SUBSCRIBER_STATUS: 'État abonné',
	MONTHLY_RATE_PLAN: 'Forfait mensuel',
	STANDARD_RATE_PLAN: 'Forfait standard',
	PREPAID_TERMS_CHARGED: 'Prépaiement facturé',
	PLAN_TYPE: 'Type forfait',
	ZONE: 'Zone',
	ROAMING_ZONE: 'Zone d’itinérance',
	GROUP_NAME: 'Nom du groupe',
	THE_PLAN_TYPE: 'Type forfait',
	ACTIVATION_TYPE: 'Type activation',
	CHARGE_TYPE: 'Type frais',
	TECH_TYPE: 'Type tech',
	PRIMARY_PLACE: 'Endroit principal',
	DISTRICT_COUNTY: 'Comté district',
	DISTRICT_CITY: 'Ville district',
	PRIMARY_PLACE_OF_USE: 'Principal endroit d’utilisation',
	// notifications
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': 'Mise à jour paramètres utilisateur réussie',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'Mise à jour paramètres utilisateur impossible',
	// invoices
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'ID compte',
	'ANALYTICS_INVOICES.ADD_NOTE': 'Ajouter note',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Ajouter pièce jointe',
	'ANALYTICS_INVOICES.COUNTRY_NAME': 'Pays',
	'ANALYTICS_INVOICES.CUSTOMER_ID': 'ID client',
	'ANALYTICS_INVOICES.INVOICE_DATE': 'Date facture',
	'ANALYTICS_INVOICES.DOCUMENT_ID': 'ID document',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Sous-compte',
	'ANALYTICS_INVOICES.NOTES': 'Notes',
	'ANALYTICS_INVOICES.LINK': 'Factures',
	'ANALYTICS_INVOICES.BY': 'Par ',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Veuillez sélectionner stats à afficher ici',
	'ANALYTICS_INVOICES.NA': 'S/O',
	'ANALYTICS_INVOICES.YES': 'Oui',
	'ANALYTICS_INVOICES.NO': 'Non',
	'ANALYTICS_INVOICES.INVOICE_ID': 'ID facture',
	'ANALYTICS_INVOICES.BILLING_CYCLE': 'Cycle facturation',
	'ANALYTICS_INVOICES.ACCOUNT_ID': 'ID compte',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Nom du compte',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'ID compte opérateur',
	'ANALYTICS_INVOICES.DEVICES': 'Appareils',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Frais totaux',
	'ANALYTICS_INVOICES.CURRENCY': 'Devise',
	'ANALYTICS_INVOICES.DATA_VOLUME': 'Volume données (Mo)',
	'ANALYTICS_INVOICES.SMS_VOLUME': 'Volume SMS',
	'ANALYTICS_INVOICES.BILLABLE': 'Facturable (Oui ou Non)',
	'ANALYTICS_INVOICES.BILL': 'Facturable',
	'ANALYTICS_INVOICES.DATA_RATES': 'Tarifs données',
	'ANALYTICS_INVOICES.DATE': 'Date facture',
	'ANALYTICS_INVOICES.BILLING_DETAILS': 'Détails facturation',
	'ANALYTICS_INVOICES.DUE_DATE': 'Date échéance',
	'ANALYTICS_INVOICES.OPERATOR_ID': 'ID opérateur',
	'ANALYTICS_INVOICES.PUBLISHED': 'Publié',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Dépassement standard',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Itinérance standard',
	'ANALYTICS_INVOICES.TAXABLE': 'Compte taxable',
	'ANALYTICS_INVOICES.TAX_STATUS': 'État taxe',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Segment de compte',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'Afficher/Imprimer/Enregistrer',
	'ANALYTICS_INVOICES.INVOICE_TITLE': 'Détails facture',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Télécharger facture',
	'ANALYTICS_INVOICES.ENDPOINTS': 'Points terminaux',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Abonnements prépayés',
	'ANALYTICS_INVOICES.ADDONS': 'Suppléments',
	'ANALYTICS_INVOICES.RATE_PLANS': 'Forfaits',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Zones de forfait',
	'ANALYTICS_INVOICES.BILLING_GROUPS': 'Groupes facturation',
	'ANALYTICS_INVOICES.DOWNLOAD': 'Télécharger',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'Télécharger PDF',
	'ANALYTICS_INVOICES.UPLOAD': 'Téléverser',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Téléverser facture',
	'ANALYTICS_INVOICES.ACTIVATIONS': 'Activations',
	'ANALYTICS_INVOICES.DISCOUNTS': 'Rabais',
	'ANALYTICS_INVOICES.TAX': 'Taxe',
	'ANALYTICS_INVOICES.SURCHARGES': 'Surtaxe',
	'ANALYTICS_INVOICES.TECH_TYPES': 'Types tech',
	'ANALYTICS_INVOICES.OTHER_CHARGES': 'Autres frais',
	'ANALYTICS_INVOICES.NO_DATA': 'Aucune facture disponible',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': 'Aucune facture disponible à vous montrer actuellement',
	'ANALYTICS_INVOICES.TITLE': 'Factures',
	'ANALYTICS_INVOICES.DATA_DETAILS': 'Détails données',
	'ANALYTICS_INVOICES.SMS_DETAILS': 'Détails SMS',
	'ANALYTICS_INVOICES.TAXES': 'Taxes',
	// endpoints table
	'ANALYTICS_INVOICES.ICCID': 'Identifiant de carte SIM',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Indice de répartition mensuelle',
	'ANALYTICS_INVOICES.DEVICE_ID': 'ID appareil',
	'ANALYTICS_INVOICES.CUSTOMER': 'Client',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Forfait mensuel',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Prépaiement facturé',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Forfait standard',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'État abonné',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Frais abonnement',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Frais groupe fixe',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'Volume SMS départ (messages)',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'Volume SMS arrivée (messages)',
	// prepaid subscription table
	'ANALYTICS_INVOICES.MSISDN': 'Nº réseau numérique à intégration de services d’abonné mobile',
	'ANALYTICS_INVOICES.RATE_PLAN': 'Forfait',
	'ANALYTICS_INVOICES.PLAN_VERSION': 'Version forfait',
	'ANALYTICS_INVOICES.PLAN_TYPE': 'Type forfait',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Indice imputation utilisation',
	'ANALYTICS_INVOICES.TERM_START_DATE': 'Date début période',
	'ANALYTICS_INVOICES.TERM_END_DATE': 'Date fin période',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'Utilisation données incluses Mo',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'SMS départ inclus (messages)',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'SMS arrivée inclus (messages)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Voix incluses (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'Voix départ incluses (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'Voix arrivée incluses (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD': 'CSD inclus (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'CSD départ inclus (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'CSD arrivée inclus (m:ss)',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': 'Utilisation données à ce jour (zone principale)',
	'ANALYTICS_INVOICES.VOICE_VOLUME': 'Volume voix (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Volume voix départ (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Volume voix arrivée (m:ss)',
	// Addons Table
	'ANALYTICS_INVOICES.ADDON_SIZE': 'Taille supplément (Mo)',
	'ANALYTICS_INVOICES.ZONE': 'Zone',
	'ANALYTICS_INVOICES.ADDON_CHARGES': 'Frais supplément',
	'ANALYTICS_INVOICES.DATE_PURCHASED': 'Date d’achat',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Forfait base appliqué à',
	// Rate Plan Table
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': 'Activation période engagement abon.',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Abonnements actifs',
	'ANALYTICS_INVOICES.ACTIVE_TIER': 'Niveau actif',
	'ANALYTICS_INVOICES.DATA_CHARGE': 'Frais données',
	'ANALYTICS_INVOICES.INCLUDED_DATA': 'Données incluses',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Mode données incluses',
	'ANALYTICS_INVOICES.INCLUDED_SMS': 'SMS inclus',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Mode SMS départ inclus',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Mode SMS inclus',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Mode SMS arrivée inclus',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Mode voix départ incluses',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Mode voix incluses',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': 'Activation période minimum abon.',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Frais données non-itinérance',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Volume données non-itinérance',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Type paiement',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Activations prépayées',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Frais données itinérance',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Volume données itinérance',
	'ANALYTICS_INVOICES.SMS_CHARGE': 'Frais SMS',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Champ Abonnement',
	'ANALYTICS_INVOICES.VOICE_CHARGE': 'Frais voix',
	// Rate Plan Zone table
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Tarif dépassement actif',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Données plafonnées incluses',
	'ANALYTICS_INVOICES.INCLUDED_MODE': 'Mode inclus',
	'ANALYTICS_INVOICES.PRIORITY': 'Priorité',
	'ANALYTICS_INVOICES.ROAMING_ZONE': 'Zone d’itinérance',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Utilisation totale incluse (Mo)',
	//  Billing groups table
	'ANALYTICS_INVOICES.GROUP_NAME': 'Nom du groupe',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Total inclus',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'Frais SMS départ',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'SMS départ inclus (messages)',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': 'Utilisation totale SMS départ inclus',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': 'Total voix départ incluses',
	// Data details table
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Ajustement d’arrondi quotidien',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'Type forfait',
	// sms details table
	'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Type message',
	// activations table
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Frais activation',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Type activation',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Date activation initiale',
	// discount table
	'ANALYTICS_INVOICES.CHARGE_TYPE': 'Type frais',
	'ANALYTICS_INVOICES.DISCOUNT': 'Rabais',
	// taxes table
	'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Endroit principal',
	'ANALYTICS_INVOICES.MRC': 'Frais mensuels',
	'ANALYTICS_INVOICES.DATA': 'Données',
	'ANALYTICS_INVOICES.SMS': 'SMS',
	'ANALYTICS_INVOICES.VOICE': 'Voix',
	'ANALYTICS_INVOICES.ACTIVATION': 'Activation',
	'ANALYTICS_INVOICES.VPN': 'VPN',
	'ANALYTICS_INVOICES.SIM': 'SIM',
	'ANALYTICS_INVOICES.MRF': 'Frais mensuels',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': 'Type tech',
	// surcharges table
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Principal endroit d’utilisation',
	'ANALYTICS_INVOICES.FEDERAL': 'Fédéral',
	'ANALYTICS_INVOICES.STATE': 'État',
	'ANALYTICS_INVOICES.COUNTY': 'Comté',
	'ANALYTICS_INVOICES.CITY': 'Ville',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'Comté district',
	'ANALYTICS_INVOICES.DISTRICT_CITY': 'Ville district',
	// tech type table
	'ANALYTICS_INVOICES.PLAN_ID': 'ID forfait',
	'ANALYTICS_INVOICES.VERSION_ID': 'ID version',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'Nbre appareils 2G',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': 'Frais type tech 2G',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'Nbre appareils 3G',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': 'Frais type tech 3G',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'Nbre appareils 4G',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': 'Frais type tech 4G',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Fréquence frais type tech',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Total frais type tech',

	// Subscription charges:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Frais abonnement',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Frais abonnement',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Frais données',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'Frais SMS',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Frais activation',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Frais type tech',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Rabais fixe cible',
	'ANALYTICS_INVOICES_DETAILS.TAX': 'Taxe',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Frais totaux',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Surtaxe',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Exigible le ',

	// Endpoint stats:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Stats points terminaux',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'POINTS TERMINAUX',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'ABONNÉS ACTIFS',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'VOLUME DONNÉES',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'VOLUME SMS',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'REVENUS SERVICE',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'VARIABLE RABAIS',

	// other charges table
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Montant des frais',
	'ANALYTICS_INVOICES.DESCRIPTION': 'Description',
	'ANALYTICS_INVOICES.QUANTITY': 'Quantité',
	'ANALYTICS_INVOICES.ORDER_NUMBER': 'Numéro commande',
	'ANALYTICS_INVOICES.REFERENCE_ID': 'ID référence',
	//  placeholderS
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Saisissez votre note ici',
	'ANALYTICS_INVOICES.REMOVE': 'Supprimer',
	'ANALYTICS_INVOICES.EDIT_NOTE': 'Modifier note',
	'ANALYTICS_INVOICES.CANCEL': 'Annuler',
	'ANALYTICS_INVOICES.SUBMIT': 'Envoyer',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Pièces jointes ajoutées',
	//  edit table modal
	'ANALYTICS_INVOICES.APPLY_CHANGE': 'Appliquer changement',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Remettre à la valeur par défaut',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Modifier paramètres de la table',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': 'Sélectionner un minimum de 2 à appliquer à la table. ',
	'ANALYTICS_INVOICES.OF': ' de ',
	'ANALYTICS_INVOICES.SELECTED': ' Sélectionnés',
	// invoice details
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'Données non disponibles',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': 'Aucune donnée pour cette facture',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': 'La base de données des détails de la facture est vide pour cette facture',
	'ANALYTICS_INVOICES.ADD_FILES': 'Fichiers de facture',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'Afficher/Ajouter/Modifier',
	//  ATTACHMENTS MODAL
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Pièces jointes pour la facture',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Modifier pièce jointe',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Téléverser un fichier',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Supprimer pièce jointe',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Fermer',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Annuler',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Enregistrer',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Supprimer',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Téléverser fichier',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Téléverser pièce jointe',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Téléverser facture',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'Nom du fichier',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Description',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': 'Vous êtes sur le point de supprimer une pièce jointe de MC. Le fichier sera définitivement supprimé du système, voulez-vous vraiment continuer ?',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'Fichier à supprimer :',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'Fichier joint',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': 'Nom du fichier (si le champ est laissé vide, le nom de fichier par défaut sera utilisé)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': 'Description fichier visible pour le client (facultatif)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': 'Cette facture sera active dans quelques semaines.',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': 'Faire glisser le fichier ou cliquer pour téléverser',
	'INVOICES_NOTES_UPDATE.SUCCESS': 'Mise à jour réussie de la note sur la facture.',
	//  NOTES MODAL
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'Mise à jour réussie',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': 'Votre note a bien été ajoutée.',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Échec de mise à jour',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': 'Votre note a bien été ajoutée.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'Téléversement réussi',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Téléversement du fichier réussi',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Erreur téléversement',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Erreur lors du téléversement de ce fichier vers MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': 'Pièce jointe supprimée',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': 'Le fichier a été supprimé de MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': 'Erreur suppression',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': 'Erreur lors de la suppression de ce fichier de MC.',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Date facture',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': 'Le cycle de facturation n’est pas valide, les détails de la facture ne peuvent donc pas être affichés.',
	// ADD NEW INVOICE
	'INVOICES.ADD_NEW_INVOICE': 'Ajouter nouvelle facture',
	'INVOICES.CANCEL': 'Annuler',
	'INVOICES.ADD_INVOICE': 'Ajouter facture',
	'INVOICES.EDIT': 'Modifier',
	'INVOICES.CONFIRM': 'Confirmer',
	'INVOICES.CONFIRMATION': 'Confirmation',
	'INVOICES.INVOICE_DETAILS': 'Détails facture',
	'ADD_INVOICE.INVOICE_ID': 'ID facture',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': 'ID compte machine-machine',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'Sélectionner ID compte machine-machine',
	'ADD_INVOICE.INVOICE_DATE': 'Date facture',
	'ADD_INVOICE.BILLING_CYCLE': 'Cycle facturation',
	'ADD_INVOICE.DATE_PLACEHOLDER': 'Sélectionner date',
	'ADD_INVOICE.UPLOAD_FILE': 'Téléverser un fichier',
	'ADD_INVOICE.UPLOAD_FILE_NAME': 'Nom du fichier (facultatif : si le champ est laissé vide, le nom de fichier par défaut sera utilisé)',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': 'Nom du fichier (255 caractères maximum)',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': 'Nom du fichier',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Votre nouvelle facture a bien été ajoutée.',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Une erreur est survenue lors de l’ajout de la facture. Veuillez réessayer.',
	'ADD_INVOICE.TIME_STAMP': 'Joint le {date} à {time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION': 'Le nom du fichier n’est pas valide (veuillez utiliser _ à la place de l’espace, et les caractères suivants ne sont pas autorisés ! ? <> % $ () + =',
};
