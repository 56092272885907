export default {
	'ERRORS.ERROR': 'Fehler',
	'ERRORS.FORBIDDEN_ACCESS': 'Zugriff verboten',
	'ERRORS.RETURN_TO_HOME_PAGE': 'ZURÜCK ZUR STARTSEITE',
	'ERRORS.PAGE_NOT_FOUND': 'Wir können die gesuchte Seite nicht finden.',
	'ERRORS.INTERNAL_SERVER_ERROR': 'Interner Serverfehler',
	'ERRORS.REQUEST_TIMED_OUT': 'Zeitüberschreitung bei Anforderung',
	'ERRORS.LOGOUT': 'Abmelden',
	'ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION': 'Sie haben leider keine Zugriffsrechte für diese Anwendung.',
	'ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR': 'Wenden Sie sich bitte an Ihren Administrator, wenn Sie der Auffassung sind, dass Sie Zugriff haben sollten.'
};
