export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Diagrammkategorien',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Daten gruppieren nach',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filter',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Diagrammoptionen',
	'ANALYTICS.EDIT_CHART': 'Diagramm bearbeiten',
	'ANALYTICS.CREATE_NEW_CHART': 'Neues Diagramm erstellen',
	'ANALYTICS.DISCLAIMER': 'Ausschlusserklärung: ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': 'Die Datendiagrammdarstellung wird von mehreren Systemen in verschiedenen Zeitintervallen bereitgestellt. Die Daten in den Diagrammen geben die angemessene Gesamtsumme an. Daten sind in UTC-0-Zeit dargestellt. Die endgültigen Daten zu den berechneten Dienstleistungen sind auf der Rechnung für den Abrechnungszyklus verfügbar.',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Gesamtendpunktzahl',
	'ANALYTICS.NO_USAGE_SIMS': 'Keine Nutzungs-SIMs',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Gesamt-SMS-Nutzung',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Gesamtdatennutzung',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Gesamt-Voice-Nutzung',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Geschätzte durchschnittliche Nutzung',
	'ANALYTICS.NO_CATEGORIES': 'Für dieses Konto sind keine Kategorien verfügbar',
	'ANALYTICS.LINE_GRAPH': 'Liniendiagramm',
	'ANALYTICS.PIE_CHART': 'Kreisdiagramm',
	'ANALYTICS.BAR_GRAPH': 'Balkendiagramm',
	'ANALYTICS.WORLD_MAP_GRAPH': 'Weltkartendiagramm',
	'ANALYTICS.PLATFORM': 'Plattform',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Zugangstechnologie',
	'ANALYTICS.OPERATOR': 'Bediener',
	'ANALYTICS.RATEPLAN': 'Tarifplan',
	'ANALYTICS.COUNTRYCODE': 'Land',
	'ANALYTICS.SIM_STATE': 'SIM-Status',
	'ANALYTICS.DATE_RANGE': 'DATUMSBEREICH',
	'ANALYTICS.TODAY': 'Heute',
	'ANALYTICS.PREVIOUS_DAY': 'Vorheriger Tag',
	'ANALYTICS.LAST_10_DAYS': 'Letzte 10 Tage',
	'ANALYTICS.LAST_30_DAYS': 'Letzte 30 Tage',
	'ANALYTICS.CUSTOM_RANGE': 'Benutzerdefinierter Bereich',
	'ANALYTICS.CYCLE': 'ZYKLUS',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Aktueller Zyklus bis dato',
	'ANALYTICS.HISTORICAL': 'Historisch',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'M2M-PLATTFORM',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'ERHÖHEN UM',
	'ANALYTICS.DAY': 'Tag',
	'ANALYTICS.WEEK': 'Woche',
	'ANALYTICS.MONTH': 'Monat',
	'ANALYTICS.SHOW': 'ANZEIGEN',
	'ANALYTICS.SUM_TOTALS': 'Gesamtsumme',
	'ANALYTICS.AVERAGE_TOTALS': 'Summendurchschnitt',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': 'Bitte wählen Sie im ersten Schritt eine Kategorie aus',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': 'Keine Filter für diese Kategorie verfügbar',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Diagrammdaten spezifisch anpassen',
	'ANALYTICS.EXPORT_CHART_DATA': 'Diagrammdaten exportieren',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'PNG herunterladen',
	'ANALYTICS.REMOVE_CHART': 'Diagramm entfernen',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Diagramm hinzufügen',
	'ANALYTICS.NO_ATTRIBUTES': 'Für dieses Konto sind keine Gruppieren-nach-Attribute verfügbar',
	'ANALYTICS.MOVING_AVERAGE': 'Gleitender Durchschnitt',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Gesamtsummen zeigen',
	'ANALYTICS.DEFAULT_NAME': 'Diagrammname',
	'ANALYTICS.DEFAULT_DESCRIPTION': 'Dies ist eine Beschreibung des Diagramms.',
};
