export default {
	'TICKETING.YES': 'Sí',
	'TICKETING.NO': 'No',
	'TICKETING.VIEW_NOTE': 'Ver nota',
	'TICKETING.TICKET_ID': 'ID de ticket',
	'TICKETING.CATEGORY_NAME': 'Categoría',
	'TICKETING.DATE_CREATED': 'Fecha de creación',
	'TICKETING.CREATED_BY': 'Creado por',
	'TICKETING.TICKETING_STATUS': 'Estado del ticket',
	'TICKETING.LAST_UPDATE': 'Última actualización',
	'TICKETING.SEVERITY': 'Severidad',
	'TICKETING.SUBMISSION_LOCATION': 'Lugar de envío',
	'TICKETING.UPDATE_NOTE': 'Actualizar nota',
	'TICKETING.STATUS': 'Estado',
	'TICKETING.SUBMITED_BY': 'Enviado por',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': '¿Su ticket está relacionado con una terminal específica? Si es así, vaya a la pantalla de detalles de esa terminal y haga clic en el enlace para obtener asistencia con la terminal. De lo contrario, llene el formulario a continuación.',
	'TICKETING.ISSUE': 'Problema',
	'TICKETING.TICKET_DETAILS': 'Detalles del ticket',
	'TICKETING.CATEGORY': 'Categoría',
	'TICKETING.SELECT_CATEGORY': 'Seleccionar categoría',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Subcategoría (opcional)',
	'TICKETING.SUBCATEGORY': 'Subcategoría',
	'TICKETING.ROAMING_ISSUE': 'Problema de roaming',
	'TICKETING.PRIORITY': 'Prioridad',
	'TICKETING.ENDPOINT': 'Terminal',
	'TICKETING.ICCID': 'ICCID',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'MSISDN',
	'TICKETING.ENDPOINT_LOCATION': 'Ubicación de la terminal',
	'TICKETING.ENDPOINT_COUNTRY': 'País de la terminal',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Eliminar parámetros de terminal',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Buscar parámetros de terminal',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': 'Puede usar esta opción para acceder a los parámetros de la terminal al ingresar el valor ICCID, IMSI o MSISDN. ',
	'TICKETING.ENDPOINT_STREET': 'Calle de la terminal',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Dirección de la terminal',
	'TICKETING.ZIP_CODE': 'Código postal',
	'TICKETING.ENDPOINT_STATE': 'Estado de la terminal',
	'TICKETING.DETAILS': 'DETALLES',
	'TICKETING.BARS_SHOWN': 'Barras mostradas',
	'TICKETING.ENDPOINT_CITY': 'Ciudad de la terminal',
	'TICKETING.ENDPOINT_MODEL': 'Modelo de la terminal',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': '¿Se realizó solución de problemas?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Descripción del problema',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': 'Describa brevemente el problema en un máximo de 255 caracteres',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': 'Dé detalles adicionales, si es necesario en un máximo de 2000 caracteres',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Notas',
	'TICKETING.ISSUE_DETAILS': 'Detalles del problema',
	'TICKETING.CUSTOMER': 'Cliente',
	'TICKETING.ACCOUNT_NUMBER': 'Número de cuenta',
	'TICKETING.ACCOUNT_NAME': 'Nombre de cuenta',
	'TICKETING.PHONE': 'Teléfono',
	'TICKETING.CONTACT_PHONE': 'Teléfono de contacto',
	'TICKETING.CONTACT_EMAIL': 'Correo electrónico de contacto',
	'TICKETING.ATTACH_FILE': 'Adjuntar archivo',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': 'El nombre del archivo es demasiado largo para cargar, Debe tener un máx. de 25 caracteres.',
	'TICKETING.EMAIL': 'Correo electrónico',
	'TICKETING.TICKETING': 'Gestión de tickets',
	'TICKETING.ADD_TICKET': 'Agregar ticket',
	'TICKETING.SEARCH_RESULTS': 'Resultados de búsqueda',
	'TICKETING.NO_TICKETS_AVAILABLE': 'No hay tickets disponibles',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': 'iLos parámetros de la tabla de tickets se cambiaron con éxito!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': 'Los parámetros de la tabla de tickets se actualizaron con éxito.',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': 'iError al cambiar las columnas de tabla de tickets!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': 'Las columnas de la tabla de tickets no se pudieron actualizar esta vez. Inténtelo nuevamente.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'El ticket se editó con éxito.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': 'Error al editar el ticket. Inténtelo nuevamente.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'El nombre del archivo no debe incluir espacios.',
	'TICKETING.NEW_TICKET': 'Nuevo ticket',
	'TICKETING.OFF': 'Desactivado',
	'TICKETING.ON': 'El',
	'TICKETING.EMAIL_NOTIFICATIONS': 'Notificaciones por correo electrónico',
	'TICKETING.CONTACT_NAME': 'Nombre de contacto',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Última actualización por',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Fecha/Hora de última actualización',
	'TICKETING.SOMETHING_WENT_WRONG': 'Algo falló, inténtelo nuevamente.',
	'TICKETING.CANCEL_TICKET': 'Cancelar ticket',
	'TICKETING.ADD_NOTE': 'Agregar nota',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'iTicket enviado con éxito!',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': 'iEl ticket no se pudo enviar con éxito!',
	'TICKETING.CREATED_AT': 'Creado el',
	'TICKETING.GO_BACK_TO_TICKETING': 'Volver a gestión de tickets',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': 'Problema de facturación no técnico',
	'TICKETING.CATEGORY_PROVISIONING': 'Suministro',
	'TICKETING.CATEGORY_DATA': 'Datos',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Documentación',
	'TICKETING.CATEGORY_DUPLICATE': 'Duplicado',
	'TICKETING.CATEGORY_BILLING': 'Facturación',
	'TICKETING.CATEGORY_NOTIFICATION': 'Notificación',
	'TICKETING.CATEGORY_OTHER': 'Otro',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': 'Solicitud de mejora de producto',
	'TICKETING.CATEGORY_PROJECT': 'Proyecto',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Problema de ventas/comercialización',
	'TICKETING.CATEGORY_QUESTION': 'Pregunta',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Solicitud de servicio',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Problemas técnicos',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': 'Admin. de cuenta/usuario',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Problema de conectividad',
	'TICKETING.CATEGORY_EQUIPMENT': 'Equipo',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'Pregunta general',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Equipo perdido/robado',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Solicitud de puerto',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'Relacionado con el producto',
	'TICKETING.CATEGORY_REPORTING': 'Informando',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Sugerencia/Recomendación',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Problema del sitio Web',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': 'QUEUED',
	'TICKETING.STATUS_ACTIVE': 'Activo',
	'TICKETING.STATUS_DEFERRED': 'Aplazado',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'Pendiente de respuesta del cliente',
	'TICKETING.STATUS_CLOSED': 'Cerrado',
	'TICKETING.STATUS_CANCEL': 'Cancelar',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Disputado por el cliente',
	'TICKETING.STATUS_CLEARED': 'Resuelto',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Listo para cerrar',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'Pendiente de respuesta de ATT',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': 'Crítico',
	'TICKETING.SEVERITY_MAJOR': 'Grave',
	'TICKETING.SEVERITY_MINOR': 'Menor',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': 'Cargo de activación',
	'TICKETING.AIRTIME_USAGE': 'Uso de tiempo de conexión',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Preguntas básicas de la cuenta',
	'TICKETING.BILLING_DISPUTES': 'Disputas de facturación',
	'TICKETING.BILLING_FILES_ISSUES': 'Problema con archivos de facturación',
	'TICKETING.BILL_RECONCILIATION': 'Reconciliación de factura',
	'TICKETING.CUSTOMER_EDUCATION': 'Educación del cliente',
	'TICKETING.DATA_USAGE_CANADA': 'Uso de datos en Canadá',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Uso de datos internacional',
	'TICKETING.DATA_USAGE_OFF_NET': 'Uso de datos fuera de la red',
	'TICKETING.DATA_USAGE_ON_NET': 'Uso de datos en la red',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Facturación o factura demorada',
	'TICKETING.DISCOUNT': 'Descuento',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Problema o investigación de fraude',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Problemas para imprimir factura',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Cargo mensual recurrente',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'Cargo único - ajuste',
	'TICKETING.OTHER': 'Otro',
	'TICKETING.PAYMENT_ISSUES': 'Problemas de pago',
	'TICKETING.PRORATED_CHARGES': 'Cargos prorrateados',
	'TICKETING.REACTIVATION_FEE': 'Cargo de reactivación',
	'TICKETING.SMS_TEXT_CANADA': 'SMS/Texto en Canadá',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'SMS/Texto internacional',
	'TICKETING.SMS_TEXT_OFF_NET': 'SMS/Texto fuera de la red',
	'TICKETING.SMS_TEXT_ON_NET': 'SMS/Texto en la red',
	'TICKETING.STATUS_INVOICE': 'Estado de factura',
	'TICKETING.TAXES_AND_SURCHARGES': 'Impuestos y recargos',
	'TICKETING.DATA': 'Datos',
	'TICKETING.MESSAGING_SMS_MMS_': 'Mensajes (SMS/MMS)',
	'TICKETING.VOICE': 'VOZ',
	'TICKETING.CLARIFICATION': 'Aclaración',
	'TICKETING.PREPAID': 'Prepagado',
	'TICKETING.USAGE_INQUIRY': 'Consulta de uso',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': 'Pregunta de facturación',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Problema con la factura',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Problema con el plan de tarifas',
	'TICKETING.USAGE_RELATED_ISSUE': 'Problema con el uso',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': 'Mezcla de 2G y 3G',
	'TICKETING.2G_ONLY': 'SOLO 2G',
	'TICKETING.3G_ONLY': 'SOLO 3G',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Conectado pero sin transmitir datos',
	'TICKETING.FRAME_RELAY_PVC': 'Retransmisión de trama/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Problemas de latencia',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Roaming/Nacional',
	'TICKETING.ROAMING_INTERNATIONAL': 'Roaming/Internacional',
	'TICKETING.SIGNAL_ISSUES': 'Problemas de señal',
	'TICKETING.SMPP_BIND': 'Vinculación SMPP',
	'TICKETING.SMS': 'SMS',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': 'Comentarios',
	'TICKETING.NEW_REQUEST': 'Solicitud nueva',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': 'Compatibilidad',
	'TICKETING.DEVICE_UNLOCK': 'Desbloqueo de equipo',
	'TICKETING.ORDERING': 'Pedido',
	// TICKETING LOST/STOLEN DEVICE SUBCATEGORIES
	'TICKETING.BLOCK_DEVICE': 'Bloquear equipo',
	'TICKETING.FRAUD_REPORT': 'Informe de fraude',
	'TICKETING.UNBLOCK_DEVICE': 'Desbloquear equipo',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': 'Notificación de incidente',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Mantenimiento programado',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Interfaz de canal de red (NCI)',
	'TICKETING.WIRELESS': 'Inalámbrico',
	'TICKETING.WIRELINE': 'Línea',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': 'Pregunta',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': 'Lote de archivos',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Crear perfil de SIM nuevo',
	'TICKETING.EQUIPMENT_INQUIRY': 'Consulta de equipo',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Suministro de una sola SIM',
	'TICKETING.WEB_SERVICE': 'Servicio web',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'Suministro de SIM/funciones',
	'TICKETING.ACTIVATION': 'Activación',
	'TICKETING.BATCH_BULK_CHANGE': 'Cambio de lote/masivo',
	'TICKETING.CALL_FORWARDING': 'Reenvío de llamadas',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Cambiar plan de comunicación',
	'TICKETING.CHANGE_RATE_PLAN': 'Cambiar plan de tarifas',
	'TICKETING.CHANGE_SIM_STATUS': 'Cambiar estado de SIM',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'Transferir SIM entre cuentas',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Pregunta de regla de automatización',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Pregunta de centro de control',
	'TICKETING.COVERAGE_QUESTION': 'Pregunta de cobertura',
	'TICKETING.GENERAL_QUESTION': 'Pregunta general',
	'TICKETING.OUTAGE_QUESTION': 'Pregunta de interrupción de servicio',
	'TICKETING.ROAMING_QUESTION': 'Pregunta de roaming',
	'TICKETING.JPO_COVERAGE': 'Cobertura JPO',
	//  TICKETING SALES/MARKETING ISSUE SUBCATEGORIES
	'TICKETING.CUSTOMER_INQUIRY': 'Consulta de cliente',
	'TICKETING.DEV_KIT': 'Kit de des.',
	'TICKETING.MARKETING_INQUIRY': 'Consulta de comercialización',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': 'Cancelación de la cuenta',
	'TICKETING.API_SETUP': 'Configuración API',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': 'Creación/Modificación de zona de facturación',
	'TICKETING.CC_ACCESS_UPDATES': 'Acceso/actualización de CC',
	'TICKETING.COMMUNICATION_PLAN': 'Plan de comunicación',
	'TICKETING.CUSTOM_APN': 'APN personalizado',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Configuración/Asignación de SIM global',
	'TICKETING.GPRS_VPN': 'VPN GPRS',
	'TICKETING.LEGAL_SUBPOENA': 'Legal/Citación',
	'TICKETING.LTE_MIGRATION': 'Migración LTE',
	'TICKETING.MSISDN_UPDATES': 'Actualizaciones MSISDN',
	'TICKETING.NEW_ACCOUNT': 'Nueva cuenta',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Config. de radio',
	'TICKETING.REPORT': 'Informe',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES (Solicitud de zona de roaming)',
	'TICKETING.SECURESIM': 'SecureSIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'Suministro/Pedido de SIM',
	'TICKETING.TRACE': 'Seguir',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': 'Problemas de la aplicación',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Problema técnico de facturación',
	'TICKETING.CONNECTIVITY': 'Conectividad',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Problema de SIM global',
	'TICKETING.JPO_FILE_ISSUE': 'Problema con archivo JPO',
	'TICKETING.JPO_LINK_ISSUE': 'Problema de enlace JPO',
	'TICKETING.NOC_BRIDGE': 'Puente de NOC',
	'TICKETING.CDR_FILES': 'Archivos CDR',
	'TICKETING.JPO_REPORT': 'Informe JPO',
	//  TICKETING USER/ACCOUNT ADMINISTRATION SUBCATEGORIES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': 'Agregar opciones de funcionalidad de cuenta nueva',
	'TICKETING.DEACTIVATE_USER': 'Desactivar usuario',
	'TICKETING.ID_ISSUE': 'Problema de ID',
	'TICKETING.NEW_USER': 'Usuario nuevo',
	'TICKETING.PASSWORD_RESET': 'Restablecer contraseña',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'Centro de control de AT&T (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Apollo/Portal de coche conectado',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise on Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	// OTHER
	'TICKETING.CRITICAL': 'Crítico',
	'TICKETING.MAJOR': 'Grave',
	'TICKETING.MINOR': 'Menor',
	'TICKETING.LOW': 'Bajo',
	'TICKETING.MEDIUM': 'Medio',
	'TICKETING.HIGH': 'Alto',
	'TICKETING.OUTAGE': 'Interrupción de serv.',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': 'IP dinámico',
	'TICKETING.FIXED_IP': 'IP fijo',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Regla de automatización',
	'TICKETING.CC_ISSUE_OTHER': 'Problema de CC - Otro',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Actualización de exportación y lote',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Serv. de ubicación/terceros',
	'TICKETING.PROVISIONING': 'Suministro',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Demora de información de sesión',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': 'Problemas de WebUl',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': 'Problema de datos',
	'TICKETING.DEVICE_ISSUE': 'Problema de equipo',
	'TICKETING.SFTP_FILE_TRANSFERS': 'Transferencias de archivo/SFTP',
	'TICKETING.SMS_ISSUE': 'Problema de SMS',
	'TICKETING.VOICE_ISSUE': 'Problema de voz',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': 'RED',
	'TICKETING.RADIUS': 'Radio',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Agregar un adjunto',
	'TICKETING.NOTE': 'Nota',
	'TICKETING.LAST_UPDATED_DATE': 'Última fecha de actualización de ticket',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': 'No existe una terminal con ese parámetro.',
	// ticketing notifications
	'TICKETING.ATTACHMENT_FAIL_TITLE': 'No se pudo completar totalmente su solicitud.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': 'Se creó el ticket {ticketId}, pero no se cargaron los archivos adjuntos ({attachments})',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': 'Se editó el ticket {ticketId}, pero no se cargaron los archivos adjuntos ({attachments})',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Se editó el ticket {ticketId}, pero no se eliminaron los archivos adjuntos ({attachments})',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Se editó el ticket {ticketId}, pero no se borraron los archivos adjuntos ({deleteAttachments}) ni se cargaron los archivos adjuntos ({uploadAttachments}).'
};
