import { editPermissions } from '../../redux/userGroupPermissions/actions';

const submitForm = (values, dispatch, props) => {
	let userGroupPermissionInsertRequest = [];
	const initialPermissions = props.permissions;
	const { userGroupId } = props;

	let filteredValues = [];
	Object.keys(values).forEach((key) => {
		if (values[key] !== '') {
			filteredValues = [...filteredValues, { name: key, value: values[key] }];
		}
	});

	if (initialPermissions && initialPermissions.resultList.length > 0) {
		initialPermissions.resultList.forEach((_, index) => {
			const permissions = initialPermissions.resultList[index];
			const filteredItem = filteredValues.find(
				(item) => item.name === `${permissions.id}-${permissions.name}`
			);

			if (
				filteredItem &&
				`${permissions.id}-${permissions.name}` === filteredItem.name
			) {
				const key = filteredItem.value;
				userGroupPermissionInsertRequest = [
					...userGroupPermissionInsertRequest,
					{
						permissionId: permissions.id,
						[key]: true,
					},
				];
			}

			if (
				permissions.childPermission &&
				permissions.childPermission.length > 0
			) {
				permissions.childPermission.forEach((parent) => {
					const filteredItemParent = filteredValues.find(
						(item) => item.name === `${parent.id}-${parent.name}`
					);
					if (
						filteredItemParent &&
						`${parent.id}-${parent.name}` === filteredItemParent.name
					) {
						const key = filteredItemParent.value;
						userGroupPermissionInsertRequest = [
							...userGroupPermissionInsertRequest,
							{
								permissionId: parent.id,
								[key]: true,
							},
						];
					}

					if (parent.childPermission && parent.childPermission.length > 0) {
						parent.childPermission.forEach((child) => {
							const filteredItemChild = filteredValues.find(
								(item) => item.name === `${child.id}-${child.name}`
							);
							if (
								filteredItemChild &&
								`${child.id}-${child.name}` === filteredItemChild.name
							) {
								const key = filteredItemChild.value;
								userGroupPermissionInsertRequest = [
									...userGroupPermissionInsertRequest,
									{
										permissionId: child.id,
										[key]: true,
									},
								];
							}
						});
					}
				});
			}
		});
	}

	let redirectUrl = '';

	if (
		!props.system &&
		(props.userGroup.userGroupTypeId !== 4 ||
			props.userGroup.userGroupTypeId !== 3)
	) {
		redirectUrl = `/user-groups/${userGroupId}/permissions`;
	}

	if (props.userGroup.userGroupTypeId === 3) {
		redirectUrl = `/user/${props.userId}/user-groups/${userGroupId}/permissions`;
	}
	if (props.individual && props.userGroup.userGroupTypeId === 3) {
		redirectUrl = `/user/${props.userId}/user-groups/${userGroupId}/permissions`;
	}
	if (
		!props.individual &&
		props.system &&
		props.userGroup.userGroupTypeId === 4
	) {
		redirectUrl = `/access-management/users/${props.userId}/user-groups/${userGroupId}/permissions`;
	}
	if (
		props.system &&
		props.userGroup.userGroupTypeId !== 4 &&
		props.userGroup.userGroupTypeId !== 3
	) {
		redirectUrl = `/access-management/user-groups/${userGroupId}/permissions`;
	}
	if (
		props.individual &&
		props.system &&
		props.userGroup.userGroupTypeId === 4
	) {
		redirectUrl = `/access-management/users/${props.userId}/user-groups/${userGroupId}/individual-permissions`;
	}

	return dispatch(
		editPermissions({
			id: userGroupId,
			system: props.system,
			redirect: redirectUrl,
			data: { userGroupPermissionInsertRequest }
		})
	);
};

export default submitForm;
