export default {
	'SECURITY_FEATURES.TITLE': 'Éléments de sécurité',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'Liste blanche IMEI',
	'SECURITY_FEATURES.SMS_WHITELIST': 'Liste blanche SMS',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'Liste blanche VOIX',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'Liste noire IP, URL, Port',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'Liste blanche plage IP',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': 'Aucun élément de sécurité disponible',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': 'Aucun élément de sécurité à montrer actuellement pour cette table',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'NOM DE COMPTE MACHINE-MACHINE',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'DATE ENVOI',
	'SECURITY_FEATURES.STATUS': 'ÉTAT',
	'SECURITY_FEATURES.PENDING': 'En attente',
	'SECURITY_FEATURES.FAILED': 'Échec',
	'SECURITY_FEATURES.SUCCESSFUL': 'Réussi',
	'SECURITY_FEATURES.CCIP': 'Programme d’intégration du centre de contrôle (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Centre de contrôle Vivo',
	'SECURITY_FEATURES.DCP': 'Plateforme de connexion d’appareils',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'Centre de contrôle AT&T (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SEARCH MENU
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOIX',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, PORT',
	'SECURITY_FEATURES.MENU_5': 'PLAGE IP',
	'SECURITY_FEATURES.ADD_1': 'Ajouter nouveau IMEI à liste blanche',
	'SECURITY_FEATURES.ADD_2': 'Ajouter nouveau SMS à liste blanche',
	'SECURITY_FEATURES.ADD_3': 'Ajouter nouvelle VOIX à liste blanche',
	'SECURITY_FEATURES.ADD_4': 'Ajouter nouveaux IP, URL, PORT à liste noire',
	'SECURITY_FEATURES.ADD_5': 'Ajouter nouvelle plage IP à liste blanche',
	'SECURITY_FEATURES.EXPORT': 'Exporter',
	'SECURITY_FEATURES.EDIT': 'Modifier',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'Sélectionner plateforme machine-machine',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Téléversement groupé',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Téléverser fichier',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Confirmer changements',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. Confirmation système',
	'SECURITY_FEATURES.CANCEL': 'Annuler',
	'SECURITY_FEATURES.CONTINUE': 'Continuer',
	'SECURITY_FEATURES.DONE': 'Terminé',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Téléverser fichier',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': 'Téléverser fichiers ici pour action par lots sélectionnée :',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Faire glisser le fichier ou cliquer pour téléverser',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Confirmer téléversement du fichier séquentiel',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Action programmée sur',
	'SECURITY_FEATURES.LOADER_MESSAGE': 'Veuillez patienter. Cela peut prendre quelques minutes, en fonction de la taille du fichier.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': 'Réception réussie des fichiers séquentiels !',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': 'Changements en attente pendant que le système effectue vos mises à jour',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'Erreur système de fichier séquentiel !',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': 'Un problème est survenu au cours du traitement de vos mises à jour. Veuillez réessayer ultérieurement.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Télécharger modèle',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': 'Le fichier doit être envoyé avec une extension .csv.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': 'Le nombre maximal d’éléments de sécurité par téléversement est '
};
