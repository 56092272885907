import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { trimInput } from '../../../../utils/helperFunctions';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';

import { maxLength } from '../../../../utils/validators';
import { resetFields } from '../../../../utils/constants';

import styles from './StepTwo.scss';

const maxLength18 = maxLength(18);
const maxLength20 = maxLength(20);
const maxLength40 = maxLength(40);
const maxLength60 = maxLength(60);
const maxLength140 = maxLength(140);

const validate = (values) => {
	const errors = {
		endpoint: {}
	};
	if (!values.endpoint) {
		errors.endpoint.iccid = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
		errors.endpoint.msisdn = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
		errors.endpoint.imsi = (
			<FormattedMessage id="REQUIRED" defaultMessage="Required" />
		);
	}
	if (values.endpoint) {
		if (
			(!values.endpoint.iccid || !values.endpoint.iccid.trim().length) &&
			(!values.endpoint.msisdn || !values.endpoint.msisdn.trim().length) &&
			(!values.endpoint.imsi || !values.endpoint.imsi.trim().length)
		) {
			errors.endpoint.iccid = (
				<FormattedMessage id="REQUIRED" defaultMessage="Required" />
			);
			errors.endpoint.msisdn = (
				<FormattedMessage id="REQUIRED" defaultMessage="Required" />
			);
			errors.endpoint.imsi = (
				<FormattedMessage id="REQUIRED" defaultMessage="Required" />
			);
		}
	}

	return errors;
};

class StepTwo extends PureComponent {
	componentDidMount() {
		const { match, apns, formValues, initialize } = this.props;
		if (apns && apns.length > 0 && match.params.iccid) {
			const entitlement = apns.find(
				(item) => item.id === formValues.endpoint.networkEntitlementId
			);
			const apn =
				entitlement &&
				entitlement.onStateProfile &&
				entitlement.onStateProfile.apns &&
				entitlement.onStateProfile.apns.length > 0
					? entitlement.onStateProfile.apns[0].name
					: null;
			initialize({
				...formValues,
				endpoint: {
					...formValues.endpoint,
					apn
				}
			});
		}
	}

	componentDidUpdate(prevProps) {
		const { endpointSuccess, match, apns, formValues, initialize } = this.props;
		if (!endpointSuccess && match.params.iccid && !formValues.endpoint) {
			this.getEndpoint(match.params.iccid);
		}
		if (endpointSuccess && prevProps.endpointSuccess !== endpointSuccess) {
			this.getNetworkEntitlements(endpointSuccess.m2mAccountId);
		}
		if (apns && apns.length > 0 && prevProps.apns !== apns && endpointSuccess) {
			const entitlement = apns.find(
				(item) => item.id === endpointSuccess.networkEntitlementId
			);
			const apn =
				entitlement &&
				entitlement.onStateProfile &&
				entitlement.onStateProfile.apns &&
				entitlement.onStateProfile.apns.length > 0
					? entitlement.onStateProfile.apns[0].name
					: null;
			initialize({
				...formValues,
				endpoint: {
					...formValues.endpoint,
					apn
				}
			});
		}
	}

	getEndpoint = (url) => {
		const {
			formValues: { endpoint },
			getEndpointData,
			getNetworkEntitlementInit
		} = this.props;

		getNetworkEntitlementInit();

		let item = {};
		if (endpoint) {
			if (endpoint.iccid) {
				item = {
					value: endpoint.iccid || url,
					prop: 'iccid'
				};
			}
			if (endpoint.imsi) {
				item = {
					value: endpoint.imsi,
					prop: 'imsi'
				};
			}
			if (endpoint.msisdn) {
				item = {
					value: endpoint.msisdn,
					prop: 'msisdn'
				};
			}
		} else {
			item = {
				value: url,
				prop: 'iccid'
			};
		}
		getEndpointData({
			additionalParams: {
				dataLimit: 50,
				dataOffset: 0
			},
			searchParams: [
				{
					propValue: item.value,
					prop: item.prop,
					operator: '='
				}
			]
		});
	};

	getNetworkEntitlements = (m2mAccountId) => {
		const { getNetworkEntitlement } = this.props;
		getNetworkEntitlement(m2mAccountId);
	};

	clearEndpointParametars = () => {
		const { dispatch, setEndpointTicketingReset } = this.props;
		resetFields(dispatch, 'addTicket', {
			'endpoint.iccid': '',
			'endpoint.imsi': '',
			'endpoint.msisdn': '',
			'endpoint.apn': ''
		});
		setEndpointTicketingReset();
	};

	render() {
		const {
			handleSubmit,
			previousPage,
			endpointPending,
			blur,
			iccid,
			imsi,
			msisdn,
			valid,
			invalid,
			countries,
			states,
			match,
			endpointSuccess,
			pushBack,
			endpointRequest,
		} = this.props;

		const trimValue = trimInput(blur);

		return (
			<form onSubmit={handleSubmit} data-spec="step-two">
				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<h5 className={styles.column_title}>
							<FormattedMessage
								id="TICKETING.ENDPOINT"
								defaultMessage="Endpoint"
							/>
						</h5>
					</div>
				</div>

				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage id="TICKETING.ICCID" defaultMessage="ICCID">
								{(formattedValue) => (
									<Field
										component={Input}
										name="endpoint.iccid"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength20]}
										disabled={endpointPending}
										dataSpec="iccid"
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage id="TICKETING.IMSI" defaultMessage="IMSI">
								{(formattedValue) => (
									<Field
										component={Input}
										name="endpoint.imsi"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength20]}
										disabled={endpointPending}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
				</div>

				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage id="TICKETING.MSISDN" defaultMessage="MSISDN">
								{(formattedValue) => (
									<Field
										component={Input}
										name="endpoint.msisdn"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength40]}
										disabled={endpointPending}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage id="TICKETING.APN" defaultMessage="APN">
								{(formattedValue) => (
									<Field
										component={Input}
										name="endpoint.apn"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength60]}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
				</div>
				{(!iccid || !imsi || !msisdn) && (
					<div>
						<div className={styles.multiple_column_container}>
							<Button
								onClick={
									endpointSuccess
										? () => this.clearEndpointParametars()
										: () => this.getEndpoint()
								}
								variant="link"
								className={styles.button_padding}
								label={
									endpointSuccess ? (
										<FormattedMessage
											id="TICKETING.CLEAR_ENDPOINT_PARAMETARS"
											defaultMessage="Clear endpoint parameters"
										/>
									) : (
										<FormattedMessage
											id="TICKETING.FETCH_ENDPOINT_PARAMETERS"
											defaultMessage="Fetch endpoint parameters"
										/>
									)
								}
								type="button"
								disabled={!valid || endpointPending || match.params.iccid}
								isLoading={endpointRequest}
							/>
						</div>

						<div className={styles.multiple_column_container}>
							<FormattedMessage
								id="TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC"
								defaultMessage="You can use this option to fetch endpoint parameters by entering either ICCID or IMSI or MSISDN value. "
							/>
						</div>
					</div>
				)}

				<div className={styles.multiple_column_container}>
					<hr className={styles.divider} />
				</div>

				<div className={styles.multiple_column_container}>
					<div className={styles.full_column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="TICKETING.ENDPOINT_STREET_ADDRESS"
								defaultMessage="Endpoint street address"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										name="deviceLocationAddress"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength140]}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
				</div>

				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<div className={styles.field_label}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_COUNTRY"
									defaultMessage="Endpoint country"
								/>
							</div>

							<Field
								component={Select}
								name="issueCountryId"
								placeholder={
									<FormattedMessage
										id="TICKETING.ENDPOINT_COUNTRY"
										defaultMessage="Endpoint country"
									/>
								}
								options={
									countries &&
									countries.map((item) => ({
										value: item.id,
										label: item.name
									}))
								}
								cleanValue
								isSearchable
							/>
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<div className={styles.field_label}>
								<FormattedMessage
									id="TICKETING.ENDPOINT_STATE"
									defaultMessage="Endpoint state"
								/>
							</div>
							<Field
								component={Select}
								name="issueStateId"
								placeholder={
									<FormattedMessage
										id="TICKETING.ENDPOINT_STATE"
										defaultMessage="Endpoint state"
									/>
								}
								options={
									states &&
									states.map((item) => ({
										value: item.id,
										label: item.name,
										code: item.code
									}))
								}
								cleanValue
								isSearchable
							/>
						</div>
					</div>
				</div>
				<div className={styles.multiple_column_container}>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="TICKETING.ENDPOINT_CITY"
								defaultMessage="Endpoint city"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										name="deviceLocationCity"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength60]}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
					<div className={styles.column}>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="TICKETING.ZIP_CODE"
								defaultMessage="ZIP code"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										name="zipCode"
										placeholder={formattedValue}
										label={formattedValue}
										validate={[maxLength18]}
										onBlur={trimValue}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
				</div>

				<div className={styles.buttons_wrapper}>
					<div className={styles.left_button}>
						<Button
							label={
								<FormattedMessage
									id="TICKETING.CANCEL_TICKET"
									defaultMessage="Cancel ticket"
								/>
							}
							variant="link"
							type="button"
							dataSpec="back-to-ticketing"
							onClick={pushBack}
						/>
					</div>
					<div className={styles.buttons_right}>
						<Button
							label={<FormattedMessage id="BACK" defaultMessage="Back" />}
							variant="outline-primary"
							type="button"
							dataSpec="previuos"
							onClick={previousPage}
						/>
						<Button
							label={<FormattedMessage id="NEXT" defaultMessage="Next" />}
							disabled={invalid}
							variant="primary"
							type="submit"
							dataSpec="next"
						/>
					</div>
				</div>
			</form>
		);
	}
}

const { func, bool, string, arrayOf, object, shape } = PropTypes;

StepTwo.propTypes = {
	handleSubmit: func,
	previousPage: func,
	countries: arrayOf(object),
	states: arrayOf(object),
	getEndpointData: func,
	valid: bool,
	endpointPending: bool,
	iccid: string,
	msisdn: string,
	imsi: string,
	invalid: bool,
	blur: func,
	endpointSuccess: shape(),
	apns: arrayOf(object),
	initialize: func,
	formValues: shape(),
	getNetworkEntitlement: func,
	match: shape(),
	getNetworkEntitlementInit: func,
	setEndpointTicketingReset: func,
	dispatch: func,
	pushBack: func,
	endpointRequest: bool,
};

let StepTwoForm = reduxForm({
	form: 'addTicket',
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate
})(StepTwo);

const selector = formValueSelector('addTicket');
StepTwoForm = connect((state, props) => {
	const endpoint = selector(state, 'endpoint');
	if (state.ticketing.getEndpointData.data || state.ticketing.getEndpointData.isReset) {
		return {
			initialValues: {
				...props.formValues,
				endpoint: {
					...endpoint,
					iccid: state.ticketing.getEndpointData.data?.iccid,
					imsi: state.ticketing.getEndpointData.data?.imsi,
					msisdn: state.ticketing.getEndpointData.data?.msisdn,
					networkEntitlementId:
						state.ticketing.getEndpointData.data?.networkEntitlementId
				}
			}
		};
	}
	return { };
})(StepTwoForm);

export default StepTwoForm;
