import React from 'react';
import {
	string,
	objectOf,
	func,
	arrayOf,
	object,
	array,
	bool,
	number,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DSDateRangePicker
	from '../../../../../lib/DigitalComponents/DateRangePicker';
import DropdownSelectNew
	from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';
import NoData from '../../../../Shared/components/NoData/NoData';
import hoc from '../Usage';

import styles from './Mobile.scss';

function Mobile({
	messages,
	tableData,
	tableOptions,
	getEndpointUsageDataRequest,
	type,
	types,
	from,
	to,
	changeDataType,
	handleConfirm,
	limitByDays,
	dateRangePickerLabel,
	dateRangePickerInputLabel,
	dateRangePickerButtonConfirmLabel,
	dateRangePickerButtonCancelLabel,
}) {
	const renderTable = () => {
		if (getEndpointUsageDataRequest) {
			return (
				<FormattedMessage
					data-spec="usage-loader"
					id="FETCHING_DATA"
					defaultMessage="Please wait. Fetching data..."
				>
					{(formattedValue) => (
						<Loader msg={formattedValue} />
					)}
				</FormattedMessage>
			);
		}
		if (tableData.length === 0) {
			return <NoData data-spec="no-data-usage-table" table styles={styles} />;
		}

		return (
			<CardsTable
				data={tableData}
				options={tableOptions(true)}
				messages={messages}
				data-spec="usage-table"
			/>
		);
	};

	return (
		<div data-spec="usage-mobile" className={styles.mobile_wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>
					<span>
						<FormattedMessage
							id="USAGE_HISTORY"
							defaultMessage="Usage History"
						/>
					</span>
				</div>
				<div className={styles.dropdown_wrapper}>
					<span className={styles.usage_text}>
						<FormattedMessage id="USAGE_TYPE" defaultMessage="Usage Type" />
					</span>
					<div className={styles.dropdown}>
						<DropdownSelectNew
							value={type}
							options={!getEndpointUsageDataRequest
								? types
								: []}
							onChange={changeDataType}
						/>
					</div>
				</div>
				<div className={styles.date_icons}>
					<DSDateRangePicker
						handleConfirm={handleConfirm}
						startDate={from}
						endDate={to}
						minDate={new Date(0)}
						maxDate={new Date()}
						disabled={getEndpointUsageDataRequest}
						limitByDays={limitByDays}
						label={dateRangePickerLabel}
						inputLabel={dateRangePickerInputLabel}
						buttonConfirmLabel={dateRangePickerButtonConfirmLabel}
						buttonCancelLabel={dateRangePickerButtonCancelLabel}
					/>
				</div>
			</div>
			{renderTable()}
		</div>
	);
}

Mobile.propTypes = {
	messages: objectOf(string),
	tableData: arrayOf(object),
	tableOptions: func,
	getEndpointUsageDataRequest: bool,
	type: object,
	types: array,
	from: object,
	to: object,
	changeDataType: func,
	handleConfirm: func,
	limitByDays: number,
	dateRangePickerLabel: string,
	dateRangePickerInputLabel: string,
	dateRangePickerButtonConfirmLabel: string,
	dateRangePickerButtonCancelLabel: string,
};

Mobile.defaultProps = {
	messages: {},
	tableData: {},
};

export default hoc(Mobile);
