import globalAction from '../../../../redux/global/globalAction';
import { EDIT_USER_GROUP_ASSIGNED_ACCOUNTS } from './constants';

import { editUserGroupAssignedAccounts as editUserGroupAssignedAccountsService } from '../../services/UserGroupsService';

/* ******************** EDIT ASSIGNED ACCOUNTS ******************** */
// eslint-disable-next-line import/prefer-default-export
export const editUserGroupAssignedAccounts = (params) =>
	globalAction(
		EDIT_USER_GROUP_ASSIGNED_ACCOUNTS,
		editUserGroupAssignedAccountsService,
		params,
		{
			notify: 'edited-accounts',
			init: true
		}
	);
