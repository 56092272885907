import { difference } from 'lodash';

import { addAutomationRule, editAutomationRule } from '../../redux/actions';

const submitForm = (values, dispatch) => {
	const allFields = [
		{
			ruleName: 'name',
			valueName: 'name'
		},
		{
			ruleName: 'automationPlatformId',
			valueName: 'automationPlatformId'
		},
		{
			ruleName: 'automationCategoryId',
			valueName: 'category'
		},
		// {
		// 	ruleName: 'automationTriggerId',
		// 	valueName: 'trigger'
		// },
		/* 	{
				ruleName: "automationFrameId",
				valueName: "frame",
			},
			{
				ruleName: "automationActionId",
				valueName: "automationActionId",
			},
			{
				ruleName: "automationActionValue",
				valueName: "automationActionValue",
			}, */
		// {
		// 	ruleName: 'active',
		// 	valueName: 'active'
		// }
	];
	const rule = {};

	allFields.forEach(({ ruleName, valueName }) => {
		rule[ruleName] =
			(values[valueName] && values[valueName].value) || values[valueName];
  });

	const followUpActionFields = [
		{ name: 'automationActionId2' },
		{ name: 'automationActionValue2' },
		{ name: 'automationActionSchedulerId2' },
		{ name: 'automationActionSchedulerValue2' }
	];

	const resetValue = values.id ? null : undefined;

	followUpActionFields.forEach(({ name }) => {
		rule[name] =
			(values[name] && values[name].value) ||
			(values[name] === '' ? resetValue : values[name]);
	});

	const customFields = [...new Array(10)].map((...rest) => {
		const index = rest[1] + 1;
		return {
			name: `customField${index}`,
			value: values[`customField${index}`]
		};
	});

	const ruleFilters = [
		{
			name: 'simState',
			value:
				(values.simState && values.simState.value) || values.simState
		},
		{
			name: 'ratePlanId',
			value: `${(values.ratePlanId && values.ratePlanId.value) ||
				values.ratePlanId}`
		},
		{
			name: 'customerId',
			value:
				(values.customerId && values.customerId.value) ||
				values.customerId
		},
		{
			name: 'filterAccountId',
			value:
				(values.filterAccountId && values.filterAccountId.value) ||
				values.filterAccountId
    },
    {
			name: 'baseRatePlanId',
			value: (values.baseRatePlanId && values.baseRatePlanId.value) ||
      values.baseRatePlanId
    },
    {
			name: 'ratePlanType',
			value: (values.ratePlanType && values.ratePlanType.value) ||
      values.ratePlanType
		},
		...customFields
	];

	ruleFilters.forEach((item) => {
		const { name, value } = item;
		if (values[name] || values[name] === '') {
			rule[name] = value === '' ? resetValue : value;
		}
	});

	if (!values.id) {
    rule.m2mAccountId = values.m2mAccountId.value.toString();
    rule.automationTriggerId = values.trigger.value.toString();
	}

	if (values.id && !values.follow_up_with) {
		followUpActionFields.forEach(({ name }) => {
			rule[name] = null;
		});
	}

	// fields array
	rule.automationActionId =
		values.automationRuleFrameActionValues[0].automationActionId.value;
	rule.automationRuleFrameActionValues = values.automationRuleFrameActionValues.map((item) => ({
		automationFrameId: (item.frame && item.frame.value) || item.frame,
		automationTriggerSubCategoryId:
			(item.automationTriggerSubCategoryId &&
				item.automationTriggerSubCategoryId.value) ||
			item.automationTriggerSubCategoryId,
		frameValue:
			`${(item.frameValue && item.frameValue.value) || ''}` ||
			(`${item.frameValue || ''}` || undefined),
		frameValue2:
			`${(item.frameValue2 && item.frameValue2.value) || ''}` ||
			(`${item.frameValue2 || ''}` || undefined),
		automationActionValue: `${(item.automationActionValue &&
			item.automationActionValue.value) ||
			item.automationActionValue}`,
		id: item.id,
		zoneId:
			`${(item.zoneId && item.zoneId.value) || ''}` ||
			(`${item.zoneId || ''}` || undefined),
		simPoolId:
			(item.simPoolId && item.simPoolId.value) || (item.simPoolId || undefined),
		zoneModelId:
			(item.zoneModelId && item.zoneModelId.value) ||
			(item.zoneModelId || undefined),
		customMessage:
			(item.customMessage && item.customMessage.value) ||
			(item.customMessage || resetValue),
		automationFrameUnitId:
			(item.automationFrameUnitId && item.automationFrameUnitId.value) ||
			(item.automationFrameUnitId || undefined)
	}));

	rule.automationRuleFrameActionValuesToDelete = values.id
		? difference(
			values.automationRuleFrameActionOriginalIds,
			values.automationRuleFrameActionValues.map((item) => item.id)
		  )
		: undefined;

	if (values.id) {
		window.scrollTo(0, 0);
    let manualRuleSuccessMessageId = null;
		if (values.isManualRule) {
			manualRuleSuccessMessageId = 'AUTOMATION.MANUAL_RULE_NOTIF_MESSAGE';
    }
		return dispatch(
			editAutomationRule(rule, values.id, manualRuleSuccessMessageId)
		);
	}
  window.scrollTo(0, 0);
  return dispatch(addAutomationRule(rule));
};

export default submitForm;
