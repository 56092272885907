export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': '要删除自动化规则吗？',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': '选删除选定的自动化规则吗？',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': '您是否确定想要删除选定的自动化规则？',
	'AUTOMATION.CANCEL': '取消',
	'AUTOMATION.DELETE': '删除',
	'AUTOMATION.ARE_YOU_SURE': '您确定吗？',
	'AUTOMATION.ACTIVE': '已激活',
	'AUTOMATION.ACC_IF': '如果',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': '添加新阈值',
	'AUTOMATION.EDIT_RULE': '编辑规则',
	'AUTOMATION.CREATE_NEW_RULE': '创建新规则',
	'AUTOMATION.PLATFORM': '平台',
	'AUTOMATION.AUTOMATION_ACCOUNT': '账户',
	'AUTOMATION.CATEGORY': '类别',
	'AUTOMATION.RULE_STATE': '规则状态',
	'AUTOMATION.RULE_STATE_ACTIVE': '使用中',
	'AUTOMATION.RULE_STATE_INACTIVE': '未使用',
	'AUTOMATION.TRIGGER': '触发',
	'AUTOMATION.ACC_RULE_NAME': '规则名称',
	'AUTOMATION.SAVE': '保存',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': '清空已选择的过滤器',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': '选择过滤器',
	'AUTOMATION.AUTOMATION': '自动化',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': '创建新自动化规则',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': '您确实想要删除{ruleName}规则吗？',
	'AUTOMATION.ADD_RULE_SUCCESS': '已创建规则',
	'AUTOMATION.ADD_RULE_ERROR': '创建自动化规则失败，请重试。',
	'AUTOMATION.EDIT_RULE_SUCCESS': '已编辑规则',
	'AUTOMATION.EDIT_RULE_ERROR': '编辑自动化规则失败，请重试。',
	'AUTOMATION.THEN': '则',
	'AUTOMATION.NUMBER_OF_DAYS': '天数',
	'AUTOMATION.NUMBER_OF_HOURS': '小时数（最长24小时）',
	'AUTOMATION.AT': '目标',
	'AUTOMATION.SIM_STATE': 'SIM卡状态',
	'AUTOMATION.RATE_PLAN_ID_PLAN': '费率计划ID',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': '机对机（M2M）子账户ID',
	'AUTOMATION.CUSTOMER_ID': '客户ID',
	'AUTOMATION.CUSTOM': '定制',
	'AUTOMATION.FILTER': '过滤器',
	'AUTOMATION.NO_AVAILABLE_FILTERS': '无可用的过滤器',
	'AUTOMATION.THRESHOLD': '阈值',
	'AUTOMATION.ZONE_MODEL': '区型号',
	'AUTOMATION.ZONE_NAME': '区名称',
	'AUTOMATION.POOL_ID': '汇总ID',
	'AUTOMATION.COUNTRY': '国家',
	'AUTOMATION.FROM_KEY': '发件人',
	'AUTOMATION.TO_KEY': '收件人',
	'AUTOMATION.FOLLOW_UP_WITH': '跟进',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': '已成功删除自动化规则',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': '已成功删除选定的自动化警报',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': '删除自动化规则失败，请重试。',
	'AUTOMATION.PLATFORMS': '平台',
	'AUTOMATION.CATEGORIES': '类别',
	'AUTOMATION.TRIGGERS': '触发',
	'AUTOMATION.M2MACCOUNTS': '机对机（M2M）账户',
	'AUTOMATION.HOURS_USING': '过去24小时内使用',
	'AUTOMATION.LOCATIONUPDATE': '地点更新',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': '认证更新',
	'AUTOMATION.ZONE': '区',
	'AUTOMATION.ACCOUNT_ID': '账户ID',
	'AUTOMATION.ACTION': '行动',
	'AUTOMATION.DCP': '设备连接平台（DCP）',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': '短信服务（SMS）',
	'AUTOMATION.USAGE': '用量',
	'AUTOMATION.SIM_PROVISIONING': 'SIM卡配给',
	'AUTOMATION.SUBSCRIPTION_MONITORING': '订阅监控',
	'AUTOMATION.USAGE_MONITORING': '用量监控',
	'AUTOMATION.NETWORK_MONITORING': '网络监控',
	'AUTOMATION.PRICING_AUTOMATION': '定价自动化',
	'AUTOMATION.SECURITY_MONITORING': '安全监控',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'SIM卡费率计划更改',
	'AUTOMATION.SIM_STATE_CHANGE': 'SIM卡状态更改',
	'AUTOMATION.SMS_USAGE': '短信服务（SMS）用量',
	'AUTOMATION.SIM_DATA_LIMIT': 'SIM卡数据限制',
	'AUTOMATION.SIM_EXPIRATION': 'SIM卡过期',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': '周期至今数据用量',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': '周期至今语音用量',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': '过去24小时内数据使用超量',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': '过去24小时内会话连接数量',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': '每月汇总数据用量',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': '每月汇总短信服务（SMS）用量',
	'AUTOMATION.NO_CONNECTION': '无连接',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': '过去24小时内语音用量',
	'AUTOMATION.REGISTER_IN_A_ZONE': '在区中注册',
	'AUTOMATION.TOO_MANY_CONNECTIONS': '连接数量过多',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': '每个时段移动端始发短信（SMS MO）超限',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': '每个时段移动端始发短信（SMS MO）不足',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': '每个时段移动端终止短信（SMS MT）超限',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': '每个时段移动端终止短信（SMS MT）不足',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'GPRS长期超限',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'GPRS短期超限',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': '已建立首个数据会话',
	'AUTOMATION.IMEI_CHANGED': 'IMEI已更改',
	'AUTOMATION.NETWORK_REGISTRATION': '网络注册',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': '一个区中网络注册',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'SIM卡定制字段更改',
	'AUTOMATION.SIM_STATUS': 'SIM卡状态',
	'AUTOMATION.RATE_PLAN_CHANGED': '费率计划已更改',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'SIM卡国家已更改',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'SIM卡费率计划即将过期',
	'AUTOMATION.DATA_SESSION_STOPPED': '数据会话已停止',
	'AUTOMATION.DATA_SESSION_STARTED': '数据会话已开始',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': '一个国家中数据用量',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': '数据会话数量 — 账单周期',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': '一个区中数据用量',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': '无连接账单周期',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': '过去24小时内会话连接数量',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': '一个国家中短信服务（SMS）数量',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': '一个区中短信服务（SMS）用量',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': '过去24小时内短信服务（SMS）用量',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': '数据总用量 — 24小时',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': '数据总用量 — 账单周期',
	'AUTOMATION.SMS_MO_RECEIVED': '已收到移动端始发短信（SMS MO）',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': '一个区中周期至今语音用量',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'SIM卡计划中语音完成度',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'SIM卡计划中数据完成度',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'IMEI白名单操作',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'SIM卡账户更改',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': '过去（24小时内）短信服务用量',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': '一个区中的网络注册',
	'AUTOMATION.MSISDN_CHANGE': '移动台国际订户目录号（MSISDN）更改',
	'AUTOMATION.IMEI_CHANGE': 'IMEI更改',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': '一个区中周期至今数据用量',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': '一个端点由外部迁移而来，来源是',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': '当前周期内的端点',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': '已完成预付周期的预付费计划端点',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': '网络侦测到该端点的IMEI发生更改',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': '系统侦测到某端点的MSISDN发生更改',
	'AUTOMATION.INITIATE_OTA_SWAP': '初始化OTA互换',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': '端点收发数量超过',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': '定制字段发生过更改的SIM卡',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': '端点的IMEI不在白名单中',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': '端点在当前周期内已超过',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': '已收到包含段代码的移动端始发短信',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': '短信服务（SMS）总数 — 账单周期',
	'AUTOMATION.1_HOUR': '1小时',
	'AUTOMATION.2_HOURS': '2小时',
	'AUTOMATION.3_HOURS': '3小时',
	'AUTOMATION.4_HOURS': '4小时',
	'AUTOMATION.5_HOURS': '5小时',
	'AUTOMATION.6_HOURS': '6小时',
	'AUTOMATION.7_HOURS': '7小时',
	'AUTOMATION.8_HOURS': '8小时',
	'AUTOMATION.9_HOURS': '9小时',
	'AUTOMATION.10_HOURS': '10小时',
	'AUTOMATION.11_HOURS': '11小时',
	'AUTOMATION.12_HOURS': '12小时',
	'AUTOMATION.13_HOURS': '13小时',
	'AUTOMATION.14_HOURS': '14小时',
	'AUTOMATION.15_HOURS': '15小时',
	'AUTOMATION.16_HOURS': '16小时',
	'AUTOMATION.17_HOURS': '17小时',
	'AUTOMATION.18_HOURS': '18小时',
	'AUTOMATION.19_HOURS': '19小时',
	'AUTOMATION.20_HOURS': '20小时',
	'AUTOMATION.21_HOURS': '21小时',
	'AUTOMATION.22_HOURS': '22小时',
	'AUTOMATION.23_HOURS': '23小时',
	'AUTOMATION.24_HOURS': '24小时',
	'AUTOMATION.25_HOURS': '25小时',
	'AUTOMATION.26_HOURS': '26小时',
	'AUTOMATION.27_HOURS': '27小时',
	'AUTOMATION.28_HOURS': '28小时',
	'AUTOMATION.29_HOURS': '29小时',
	'AUTOMATION.30_HOURS': '30小时',
	'AUTOMATION.31_HOURS': '31小时',
	'AUTOMATION.32_HOURS': '32小时',
	'AUTOMATION.33_HOURS': '33小时',
	'AUTOMATION.34_HOURS': '34小时',
	'AUTOMATION.35_HOURS': '35小时',
	'AUTOMATION.36_HOURS': '36小时',
	'AUTOMATION.37_HOURS': '37小时',
	'AUTOMATION.38_HOURS': '38小时',
	'AUTOMATION.39_HOURS': '39小时',
	'AUTOMATION.40_HOURS': '40小时',
	'AUTOMATION.41_HOURS': '41小时',
	'AUTOMATION.42_HOURS': '42小时',
	'AUTOMATION.43_HOURS': '43小时',
	'AUTOMATION.44_HOURS': '44小时',
	'AUTOMATION.45_HOURS': '45小时',
	'AUTOMATION.46_HOURS': '46小时',
	'AUTOMATION.47_HOURS': '47小时',
	'AUTOMATION.48_HOURS': '48小时',
	'AUTOMATION.LAST_24_HOURS': '过去24小时',
	'AUTOMATION.CYCLE_TO_DATE': '周期至今',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': '超过SIM卡预付费费率计划限额',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': '数据用量超限',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': '数据用量接近限额',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'SIM卡预付费费率计划未超出',
	'AUTOMATION.MO_MT': '移动端始发/移动端终止（MO/MT）',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': '语音用量超限',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': '语音用量接近限额',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'SIM卡建立了超过',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'SIM卡建立了不足',
	'AUTOMATION.EXCEEDES': '已超出',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': '过去N小时内，SIM卡没有建立数据连接',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'SIM卡在区网络中注册',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'SIM卡在当前周期中建立了超额连接',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': '网络检测到一个新设备',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': '周期至今用量超出',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': '周期至今用量接近',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': '网络检测到更改，更改内容为......',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'SIM卡首次在新网络中注册',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': '端点在区中注册',
	'AUTOMATION.CUSTOM_FIELD_1': '定制字段1',
	'AUTOMATION.CUSTOM_FIELD_2': '定制字段2',
	'AUTOMATION.CUSTOM_FIELD_3': '定制字段3',
	'AUTOMATION.CUSTOM_FIELD_4': '定制字段4',
	'AUTOMATION.CUSTOM_FIELD_5': '定制字段5',
	'AUTOMATION.CUSTOM_FIELD_6': '定制字段6',
	'AUTOMATION.CUSTOM_FIELD_7': '定制字段7',
	'AUTOMATION.CUSTOM_FIELD_8': '定制字段8',
	'AUTOMATION.CUSTOM_FIELD_9': '定制字段9',
	'AUTOMATION.CUSTOM_FIELD_10': '定制字段10',
	'AUTOMATION.CHANGED': '已更改',
	'AUTOMATION.RATE_PLAN_CHANGE': '费率计划更改',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': '一张SIM卡的数据会话数量超出',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': '一张SIM卡的数据会话开始',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': '一张SIM卡的数据会话停止',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': '一个国家中的数据用量超出',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': '数据用量超出',
	'AUTOMATION.EXCEEDS': '已超出',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': '任意汇总费率计划的可计费短信服务（SMS）用量达到',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': '上一个账单周期中任何SIM卡未曾建立数据连接',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': '一张SIM卡有超过',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': '一个国家中的短信服务（SMS）数量超过',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': '短信服务（SMS）数量超限',
	'AUTOMATION.1_WEEK': '1周',
	'AUTOMATION.2_WEEKS': '2周',
	'AUTOMATION.3_WEEKS': '3周',
	'AUTOMATION.4_WEEKS': '4周',
	'AUTOMATION.SEND_EMAIL': '发送电子邮件',
	'AUTOMATION.SEND_SMS': '发送短信（SMS）',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': '更改设备网络权限',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': '更改设备SIM卡状态',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': '更改设备费率计划',
	'AUTOMATION.PUSH_API': 'Push API',
	'AUTOMATION.COMBINED': '合并',
	'AUTOMATION.MO': '移动端始发（MO）',
	'AUTOMATION.MT': '移动端终止（MT）',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': '24小时内连接',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': '更改网络权限',
	'AUTOMATION.CHANGE_SIM_STATUS': '更改SIM卡状态',
	'AUTOMATION.SMS_MESSAGES': '短信（SMS）讯息',
	'AUTOMATION.HOURS': '小时',
	'AUTOMATION.MIN': '分钟',
	'AUTOMATION.MINUTES': '分钟',
	'AUTOMATION.CHANGE_RATE_PLAN': '更改费率计划',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': '当前周期内的连接',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': '改回到以前的费率计划',
	'AUTOMATION.MODIFIED_DELETED': '已修改/已删除',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': '此SIM卡费率计划即将在一天后过期',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': '其包含的汇总短信服务（SMS）用量',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': '过去24小时内数据会话数量',
	'AUTOMATION.MSGS': '讯息',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': '区中的短信服务（SMS）数量超出',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': '过去24小时内数据会话数量',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': '消费小时数',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': '改回到以前的SIM卡状态',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': '触发事件后天数',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': '当前账单周期结束',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': '触发事件后UTC时间',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': '改回到以前的网络权限',
	'AUTOMATION.GMNA': 'GM北美',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': '企业',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM北美',
	'AUTOMATION.ENTERPRISE': '企业',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': '更改SIM卡状态',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': '过去24小时内短信服务（SMS）用量',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': '过去24小时内短信服务（SMS）用量超出',
	// Triggers
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': '移动端始发短信（SMS MO）用量 - 24小时',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': '移动端始发短信（SMS MO）用量 - 区内',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': '移动端始发短信（SMS MO）用量 - 汇总计划',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': '移动端终止短信（SMS MT）用量 - 24小时',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': '移动端终止短信（SMS MT）用量 - 账单周期',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': '移动端终止短信（SMS MT）用量 - 区内',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': '移动端终止短信（SMS MT）用量 - 汇总计划',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'SIM卡费率计划结束后的次日',
	'AUTOMATION.BYTES': '字节',
	'AUTOMATION.CUSTOM_SMS_LABEL': '向短信中添加定制文本（选填）',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': '定制文本内容（最长可输入140个字符）',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'SIM卡计划完成度',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': '预付费计划的SIM卡是否已完成预付期限',
	// new automation
	'AUTOMATION.TITLE': '自动化',
	'AUTOMATION.RULES_TAB_NAME': '自动化规则',
	'AUTOMATION.ALERTS_TAB_NAME': '自动化警报',
	'AUTOMATION_RULES.YES': '是',
	'AUTOMATION_RULES.NO': '否',
	'AUTOMATION_RULES.NO_DATA_TITLE': '没有可用的自动化规则',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': '目前没有可供显示的自动化规则',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': '没有可用的自动化警报',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': '目前没有可供显示的自动化警报',
	//  rules table headers
	'AUTOMATION_RULES.ID': 'ID',
	'AUTOMATION_RULES.RULE_NAME': '规则名称',
	'AUTOMATION_RULES.PLATFORM': '平台',
	'AUTOMATION_RULES.CATEGORY': '类别',
	'AUTOMATION_RULES.TRIGGER': '触发',
	'AUTOMATION_RULES.ACTIVE': '已激活',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'AUTOMATION_RULES.DATE_CREATED': '创建日期',
	'AUTOMATION_RULES.USER': '用户',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': '实现状态',
	// alerts table headers
	'AUTOMATION_ALERTS.ID': 'ID',
	'AUTOMATION_ALERTS.RULE_NAME': '规则名称',
	'AUTOMATION_ALERTS.PLATFORM': '平台',
	'AUTOMATION_ALERTS.CATEGORY': '类别',
	'AUTOMATION_ALERTS.TRIGGER': '触发',
	'AUTOMATION_ALERTS.ACTIVE': '已激活',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'AUTOMATION_ALERTS.DATE': '日期',
	'AUTOMATION_ALERTS.USER': '用户',
	'AUTOMATION_ALERTS.ACTION': '行动',
	// rules
	'AUTOMATION.ACTIVATE': '激活',
	'AUTOMATION.DEACTIVATE': '停用',
	'AUTOMATION.RULE_CREATED_OR_EDITED': '您选择的自动化规则需要额外处理，无法立即生效。',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': '已成功激活自动化规则',
	'AUTOMATION.ACTIVATED_RULE_ERROR': '未能成功激活自动化规则',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': '已成功已停用自动化规则',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': '未能成功停用自动化规则',
	'AUTOMATION.SHORT_CODE': '短代码',
	'AUTOMATION.BASE_RATE_PLAN': '基础费率计划',
	'AUTOMATION.RATE_PLAN_TYPE': '费率计划类型',
	'AUTOMATION.IMPLEMENTATION_STATUS': '实现状态',
	'AUTOMATION.YES': '是',
	'AUTOMATION.NO': '否',
	'AUTOMATION.RULE_STATUS_CHANGE': '规则状态更改',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': '您确定想要继续吗？',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': "您即将把规则状态从{currentState}更改为'{selectedState}'",
	'AUTOMATION.REQUEST_DELETION_TEXT': '您即将申请删除一条规则',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': '删除规则',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': '您即将申请删除规则{ruleName}',
	'AUTOMATION.REQUEST_DELETION_TITLE': '自动化规则删除申请',
	'AUTOMATION.REQUEST_DELETION': '申请删除',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': "您已成功将实现状态更改为'已收到'",
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': "您已成功将实现状态更改为'已实现'",
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': "您已成功将实现状态更改为'有冲突'",
	'AUTOMATION.SET_RULE_STATE_FAIL': '更改实现状态时发生错误。请稍后重试。',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': '您已成功发起删除规则的申请。',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': '申请删除规则时发生错误。',
	'AUTOMATION.RULE_CHANGE': '更改自动化规则',
	'AUTOMATION.RULE_CREATE': '创建自动化规则',
	'AUTOMATION.ACCEPT': '接受',
	'AUTOMATION.MANUAL_RULE_DELAY': '实现改规则时可能有延迟。',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': '执行此规则需要一定时间。',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': '我了解实现过程会有延迟。',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': '请选择操作：',
	// statuses
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': '等待删除',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': '已实现',
	'AUTOMATION.RULE_STATUS_PENDING': '待决',
	'AUTOMATION.RULE_STATUS_CONFLICTED': '有冲突',
	'AUTOMATION.RULE_STATUS_RECEIVED': '已收到',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': '不适用',
	//  Zone Model
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'ATT标准区1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'ATT标准区2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'ATT标准区3',
	//  Zone name
	'AUTOMATION.US_DOMESTIC': '美国国内',
	'AUTOMATION.EU': '欧盟'
};
