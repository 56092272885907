import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import SideBar from '../../../components/Shared/components/SideBar';
import { SuperUserTopBarDesktop } from '../../../components/Shared/components/SuperUserTopBar';
import UserFeedbackWrapper from '../../../components/UserFeedback/views/UserFeedbackWrapperView';
import { DesktopFooter } from '../../../lib/DigitalComponents/Footer';
import TopBar from '../../../lib/DigitalComponents/TopBar';
import CurrentContext from '../../../utils/currentContext';
import ImageSelector from '../../../utils/imageSelector';
import hoc from '../MainLayout';

import styles from './Desktop.scss';

const Logo = ImageSelector(CurrentContext.theme, 'svgs/logo.svg');
const UserPlaceholder = ImageSelector(CurrentContext.theme, 'svgs/user.svg');
const LinkIcon = ImageSelector(CurrentContext.theme, 'svgs/link.svg');

const Desktop = ({
	clientMenu,
	user,
	profileMenu,
	supportedLanguages,
	selectedLanguage,
	companyFriendlyName,
	companyAccountId,
	isCollapsed,
	location,
	children,
	withoutSidebar,
	showContentAboveTopBar,
	intl,
	links,
	resetFilterPaginationSearchData,
	userInitials,
}) => (
	<div data-spec="desktop" className={styles.wrapper}>
		<TopBar
			mainMenu={clientMenu}
			logo={
				<NavLink to="/">
					<Logo className={styles.logo} data-spec="homepage-logo" />
				</NavLink>
			}
			appName={<FormattedMessage id="APP_NAME" defaultMessage="IoT Console" />}
			userPlaceholder={
				<div className={styles.profile_avatar}>
					{user && user.profilePicture.length ? (
						<img
							src={user.profilePicture[0].picture}
							alt="user-profile"
							className={styles.image_holder}
						/>
					) : (
						<span className={styles.without_avatar}>
							<UserPlaceholder />
						</span>
					)}
				</div>
			}
			userName={userInitials}
			profileMenu={profileMenu}
			supportedLanguages={supportedLanguages}
			selectedLanguage={selectedLanguage}
			showContentInTopBar={showContentAboveTopBar}
			contentAboveTopBar={
				<SuperUserTopBarDesktop
					companyFriendlyName={companyFriendlyName}
					companyAccountId={companyAccountId}
					user={user}
				/>
			}
			resetFilterPaginationSearchData={resetFilterPaginationSearchData}
		/>
		<div
			className={cn({
				[styles.global_container]: true,
			})}
		>
			{!withoutSidebar && (
				<div
					className={cn(styles.main_left, isCollapsed ? styles.collapsed : '')}
				>
					<SideBar location={location} mainMenu={clientMenu} />
				</div>
			)}
			<div
				className={cn(
					styles.main_right,
					isCollapsed ? styles.collapsed : '',
					withoutSidebar ? styles.no_sidebar : ''
				)}
			>
				{children}
				<UserFeedbackWrapper location={location} menu={clientMenu} />
			</div>
		</div>
		<DesktopFooter
			logo={<Logo />}
			externalLink={<LinkIcon className={styles.link_icon} />}
			links={links}
			copyrightLink={intl.formatMessage(
				{
					id: 'COPYRIGHT_ATT_INTELECTUAL_PROPERTY',
					defaultMessage: '© {year} AT&T Intellectual Property. ',
				},
				{
					year: new Date().getFullYear(),
				}
			)}
			copyrightMessage={intl.formatMessage({
				id: 'COPYRIGHT_MESSAGE',
				defaultMessage:
					'All rights reserved. AT&T, the AT&T, Globe logo, Mobilizing Your World and DIRECTV are registered trademarks of AT&T intellectual Property and/or AT&T affiliated companies. All other marks are the property of their respective owners.',
			})}
		/>
	</div>
);

const {
	string,
	objectOf,
	object,
	bool,
	arrayOf,
	element,
	array,
	oneOfType,
	func
} = PropTypes;

Desktop.propTypes = {
	clientMenu: arrayOf(object),
	user: oneOfType([array, object, string]),
  profileMenu: arrayOf(object),
  supportedLanguages: arrayOf(object),
  selectedLanguage: object,
	companyFriendlyName: string,
	companyAccountId: string,
	isCollapsed: bool,
	location: objectOf(string),
	children: element,
	withoutSidebar: bool,
	showContentAboveTopBar: bool,
	intl: object,
	links: arrayOf(object),
	resetFilterPaginationSearchData: func,
	userInitials: string
};

Desktop.defaultProps = {
	clientMenu: [],
	user: {},
	profileMenu: [],
	companyFriendlyName: '',
	companyAccountId: '',
	isCollapsed: false,
	location: {},
	children: [],
	withoutSidebar: false,
	showContentAboveTopBar: false,
	intl: {},
	links: []
};

export default hoc()(injectIntl(Desktop));
