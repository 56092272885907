/* eslint-disable no-undef */
import { Log, UserManager } from 'oidc-client';
// import menu from '../utils/menu';

const oidcConfig = {
  authority: GLOBALS.IBM_CI_BASE_URL,
  client_id: GLOBALS.IBM_CI_CLIENT_ID,
  redirect_uri: `${GLOBALS.FRONT_BASE_PATH}/signin-callback`,
  post_logout_redirect_uri: GLOBALS.FRONT_BASE_PATH,
  response_type: 'code',
  revokeAccessTokenOnSignout: true,
  scope: 'openid',
  response_mode: 'query'
};

if (GLOBALS.FRONT_ENV !== 'dev' && GLOBALS.FRONT_ENV !== 'qa' && GLOBALS.FRONT_ENV !== 'development' && GLOBALS.FRONT_ENV !== 'idm' && GLOBALS.FRONT_ENV !== 'demo') {
	oidcConfig.metadata = {
    issuer: GLOBALS.IBM_CI_ISSUER,
    authorization_endpoint: `${GLOBALS.IBM_CI_BASE_URL}/authorize`,
    token_endpoint: `${GLOBALS.IBM_CI_BASE_URL}/token`,
    userinfo_endpoint: `${GLOBALS.IBM_CI_BASE_URL}/userinfo`,
    end_session_endpoint: GLOBALS.IBM_CI_END_SESSION_ENDPOINT
  };
}

export default class AuthService {
  constructor() {
    this.userManager = new UserManager(oidcConfig);
    if (GLOBALS.FRONT_ENV === 'dev' || GLOBALS.FRONT_ENV === 'qa' || GLOBALS.FRONT_ENV === 'idm') {
      Log.logger = console;
      Log.level = Log.INFO;
    } else {
      Log.logger = console;
      Log.level = Log.ERROR;
    }
  }

  async getAuthData() {
    return this.userManager.getUser();
  }

  async login() {
    /* if (window.location.pathname !== '/' && window.location.pathname
      .indexOf('/signin-callback') < 0) {
      const [lang, ...path] = window.location.pathname
        .split('/').filter((i) => i !== '');
      const redirectUri = lang && menu.supportedLanguages
        .find((x) => x.code === lang) ? `/${path.join('/')}` : window.location.pathname;
      sessionStorage.setItem('redirect_uri', redirectUri);
    } */
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('company-token');
    return this.userManager.signinRedirect();
  }

  async renewToken() {
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('company-token');
    return this.userManager.signinSilent();
  }

  async logout() {
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('language');
    sessionStorage.removeItem('company-token');
    return this.userManager.signoutRedirect();
  }

  async removeAuthData() {
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('company-token');
    return this.userManager.removeUser();
  }

  async exchangeCodeForToken() {
    await this.userManager.signinRedirectCallback();
  }
}
