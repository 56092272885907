import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import Loading from '../../../../lib/DigitalComponents/Loader';

import styles from './UploadFile.scss';

const TrashIcon = ImageSelector(CurrentContext.theme, 'svgs/trash.svg');
const Upload = ImageSelector(CurrentContext.theme, 'svgs/upload.svg');

const MAX_SIZE = 3145728;

class UploadFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
			dropZoneMessage: null,
			isStartedUpload: false,
			rejectedFiles: [],
			validationErrors: []
		};
	}

	componentDidUpdate(prevProps) {
		const { filePending, uploadFileNumber, resetFileNumbers } = this.props;

		const { isStartedUpload, files } = this.state;
		if (
			!filePending &&
			isStartedUpload &&
			(uploadFileNumber === 0 ||
				prevProps.uploadFileNumber !== uploadFileNumber) &&
			uploadFileNumber + 1 <= files.length
		) {
			this.startUpload(uploadFileNumber);
		}

		if (
			uploadFileNumber + 1 > files.length &&
			isStartedUpload &&
			!filePending &&
			uploadFileNumber !== 0
		) {
			resetFileNumbers();
			this.updateIsStartedUpload();
		}
	}

	componentWillUnmount() {
		const { resetFiles, hideNotification } = this.props;
		resetFiles && resetFiles();
		hideNotification();
	}

	updateIsStartedUpload = () => {
		this.setState({
			isStartedUpload: false
		});
	};

	onDrop = (files) => {
		const { resetErrors, validateUpload, hideNotification } = this.props;
		hideNotification();
		resetErrors();

		const acceptedFiles = [];
		const validationErrors = [];

		files.forEach((file) => {
			validateUpload && validateUpload(file)
				? validationErrors.push(file)
				: acceptedFiles.push(file);
		});

		this.setState({
			validationErrors,
			rejectedFiles: []
		});
		if (acceptedFiles.length > 0) {
			this.setState({
				files: acceptedFiles,
				isStartedUpload: true
			});
		}
	};

	onDropRejected = (files) => {
		const { accept, showNotification, maxSize, intl } = this.props;
		const fileMaxSize = maxSize || MAX_SIZE;
		files.forEach((file) => {
			const splitedFiles = file.name.split('.');
			const extension = `.${splitedFiles[splitedFiles.length - 1]}`;
			if (accept && !accept.includes(extension)) {
				const notificationTitle = intl.formatMessage({
					id: 'UPLOAD_FILE.EXTENSION_ERROR_TITLE',
					defaultMessage: 'Wrong file type'
				});
				const notificationMessage = intl.formatMessage({
					id: 'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE',
					defaultMessage: 'Only the following formats are allowed for upload:'
				});
				const notificationData = {
					id: 'upload-extension-error',
					message: `${notificationMessage} ${this.getAcceptedExtensions()}`,
					type: 'error',
					title: notificationTitle,
					notificationType: 'error'
				};
				showNotification(notificationData);
			}
			if (file.size > fileMaxSize) {
				const notificationTitle = intl.formatMessage({
					id: 'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE',
					defaultMessage: 'File is too large'
				});
				const notificationMessage = intl.formatMessage({
					id: 'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE',
					defaultMessage:
						'The file you tried to upload is too large, files must be less than'
				});
				const notificationData = {
					id: 'upload-file-size-error',
					message: `${notificationMessage} ${fileMaxSize / 1048576}MB`,
					type: 'error',
					title: notificationTitle
				};
				showNotification(notificationData);
			}
		});

		this.setState({
			rejectedFiles: files
		});
	};

	startUpload = (fileNumber) => {
		const { service, transactionType, fileRequest, validateFileContent } = this.props;
		const { files } = this.state;
		const uploadBody = {
			request: {
				fileName: files[fileNumber].name,
				service,
				transactionType,
				validateFileContent: validateFileContent || false
			},
			file: files[fileNumber]
		};
		fileRequest(uploadBody);
	};

	renderDropZoneMessage = () => {
		const { dropZoneMessage } = this.state;
		return (
			<div className={styles.title} data-spec="dropzone-message">
				{dropZoneMessage}
				<br />
				<FormattedMessage
					id="DRAG_FILE_OR_CLICK_TO_UPLOAD"
					defaultMessage="Drag file or click to upload"
				/>
			</div>
		);
	};

	getAcceptedExtensions = () => {
		const { accept } = this.props;
		let acceptedExtensions = '';
		accept &&
			accept.forEach((acceptExtension, index) => {
				if (index === 0) acceptedExtensions += `${acceptExtension}`;
				else {
					acceptedExtensions += `, ${acceptExtension}`;
				}
			});
		return acceptedExtensions;
	};

	renderRestrictions = () => {
		const { accept, maxSize, singleFileUpload } = this.props;

		return (
			<div className={styles.restrictions} data-spec="upload-file-restrictions">
				{accept && (
					<span>
						<FormattedMessage
							id="UPLOAD_FILE.ALLOWED_FORMATS"
							defaultMessage="Allowed formats: "
						/>
						{this.getAcceptedExtensions()}
					</span>
				)}
				<span>
					<FormattedMessage
						id="UPLOAD_FILE.MAX_FILE_SIZE"
						defaultMessage="File must be less than {size}MB."
						values={{
							size: maxSize / 1048576 || MAX_SIZE / 1048576
						}}
					/>
				</span>
				{singleFileUpload && (
					<span>
						<FormattedMessage
							id="UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED"
							defaultMessage="Only one file allowed"
						/>
					</span>
				)}
			</div>
		);
	};

	render() {
		const {
			fileSuccess,
			removeFileFromForm,
			removeFile,
			validationFailMessage,
			singleFileUpload,
			accept,
			maxSize
		} = this.props;

		const {
			dropZoneMessage,
			isStartedUpload,
			rejectedFiles,
			validationErrors
		} = this.state;

		return (
			<div data-spec="file-upload">
				<Dropzone
					className={cn(styles.dropzone, {
						[`${styles.error}`]: dropZoneMessage !== null
					})}
					onDrop={this.onDrop}
					maxSize={maxSize || MAX_SIZE}
					onDropRejected={this.onDropRejected}
					disabled={
						isStartedUpload || (singleFileUpload && fileSuccess.length > 0)
					}
					multiple={!singleFileUpload}
					accept={accept}
				>
					{isStartedUpload ? (
						<Loading />
					) : (
						<>
							<div>
								<Upload className={styles.upload_icon} />
							</div>
							<div className={styles.text}>{this.renderDropZoneMessage()}</div>
						</>
					)}
				</Dropzone>

				{this.renderRestrictions()}

				{fileSuccess.length > 0 && fileSuccess && (
					<div className={styles.field_wrapper}>
						<div className={styles.field_label}>
							<FormattedMessage
								id="FILE_UPLOADED"
								defaultMessage="File uploaded:"
							/>
						</div>
						<div className={styles.field_info}>
							{fileSuccess.map((file, i) => (
								<div className={styles.file_name_wrapper} key={file.name}>
									<p>{file.name}</p>
									<span
										className={styles.remove_icon_wrapper}
										onClick={() => {
											removeFileFromForm && removeFileFromForm(i);
											removeFile(file.name);
										}}
									>
										<TrashIcon />
										<FormattedMessage id="REMOVE" defaultMessage="Remove" />
									</span>
								</div>
							))}
						</div>
					</div>
				)}

				{rejectedFiles.map((file) => (
					<div key={file.preview}>
						<div className={styles.fail}>{file.name}</div>
					</div>
				))}
				{validationErrors.length > 0 && (
					<div className={styles.failed_info}>{validationFailMessage}</div>
				)}
				{validationErrors.map((file) => (
					<div key={file.name} className={styles.fail}>
						{file.name}
					</div>
				))}
			</div>
		);
	}
}

const {
	func,
	number,
	object,
	bool,
	string,
	shape,
	arrayOf,
	array,
	any
} = PropTypes;

UploadFile.propTypes = {
	fileRequest: func,
	uploadFileNumber: number,
	resetFileNumbers: func,
	removeFile: func,
	filePending: bool,
	fileSuccess: arrayOf(object),
	resetErrors: func,
	service: string,
	transactionType: string,
	removeFileFromForm: func,
	resetFiles: func,
	validateUpload: func,
	validationFailMessage: shape(),
	singleFileUpload: bool,
	accept: array,
	maxSize: number,
	hideNotification: func,
	showNotification: func,
	intl: any,
	validateFileContent: bool
};

export default injectIntl(UploadFile);
