export default {
	'SECURITY_FEATURES.TITLE': 'Funciones de seguridad',
	'SECURITY_FEATURES.IMEI_WHITELIST': 'Lista blanca de IMEI',
	'SECURITY_FEATURES.SMS_WHITELIST': 'Lista blanca de SMS',
	'SECURITY_FEATURES.VOICE_WHITELIST': 'Lista blanca de VOZ',
	'SECURITY_FEATURES.IP_URL_PORT_BLACKLIST': 'Lista negra de puerto, IP, URL',
	'SECURITY_FEATURES.IP_RANGE_WHITELIST': 'Rango de lista blanca de IP',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_TITLE': 'No hay funciones de seguridad disponibles',
	'SECURITY_FEATURES.NO_SECURITY_FEATURES_SUBTITLE': 'No hay funciones de seguridad para esta tabla para mostrarle ahora',
	'SECURITY_FEATURES.IMEI': 'IMEI',
	'SECURITY_FEATURES.M2M_ACCOUNT_NAME': 'NOMBRE DE CUENTA M2M',
	'SECURITY_FEATURES.DATE_SUBMITTED': 'FECHA DE ENVÍO',
	'SECURITY_FEATURES.STATUS': 'ESTADO',
	'SECURITY_FEATURES.PENDING': 'Pendiente',
	'SECURITY_FEATURES.FAILED': 'Falló',
	'SECURITY_FEATURES.SUCCESSFUL': 'Éxito',
	'SECURITY_FEATURES.CCIP': 'Programa de integración del centro de control (CCIP)',
	'SECURITY_FEATURES.VIVO': 'Centro de control Vivo',
	'SECURITY_FEATURES.DCP': 'Plataforma de conexión de equipo (DCP)',
	'SECURITY_FEATURES.POD19': 'POD19',
	'SECURITY_FEATURES.ACC': 'Centro de control de AT&T (ACC)',
	'SECURITY_FEATURES.GMNA': 'GMNA',
	'SECURITY_FEATURES.SXMACC': 'SXMACC',
	'SECURITY_FEATURES.WING': 'Nokia (WING)',
	'SECURITY_FEATURES.POD3': 'POD3',
	'SECURITY_FEATURES.GDSP': 'GDSP',
	'SECURITY_FEATURES.EOD': 'EOD',
	'SECURITY_FEATURES.GBCM': 'GBCM',
	'SECURITY_FEATURES.PP100017182': 'PP100017182',
	// SEARCH MENU
	'SECURITY_FEATURES.MENU_1': 'IMEI',
	'SECURITY_FEATURES.MENU_2': 'SMS',
	'SECURITY_FEATURES.MENU_3': 'VOZ',
	'SECURITY_FEATURES.MENU_4': 'IP, URL, PUERTO',
	'SECURITY_FEATURES.MENU_5': 'RANGO DE IP',
	'SECURITY_FEATURES.ADD_1': 'Agregar IMEI nuevo a la lista blanca',
	'SECURITY_FEATURES.ADD_2': 'Agregar SMS nuevo a la lista blanca',
	'SECURITY_FEATURES.ADD_3': 'Agregar servicio de VOZ nuevo a la lista blanca',
	'SECURITY_FEATURES.ADD_4': 'Agregar nuevo IP, URL, PUERTO a la lista negra',
	'SECURITY_FEATURES.ADD_5': 'Agregar rango de IP nuevo a la lista blanca',
	'SECURITY_FEATURES.EXPORT': 'Exportar',
	'SECURITY_FEATURES.EDIT': 'Editar',
	'SECURITY_FEATURES.SELECT_PLATFORM': 'Seleccionar plataforma M2M',
	'SECURITY_FEATURES.FORM_1': 'SMS',
	'SECURITY_FEATURES.FORM_2': 'SMS',
	'SECURITY_FEATURES.FORM_3': 'SMS',
	'SECURITY_FEATURES.FORM_4': 'SMS',
	'SECURITY_FEATURES.FORM_5': 'SMS',
	'SECURITY_FEATURES.BULK_MODAL_TITLE': 'Carga masiva',
	'SECURITY_FEATURES.STEP_UPLOAD_FILE': '1. Cargar archivo',
	'SECURITY_FEATURES.STEP_CONFIRM_CHANGES': '2. Confirmar cambios',
	'SECURITY_FEATURES.STEP_SYSTEM_CONFIRMATION': '3. Confirmación de sistema',
	'SECURITY_FEATURES.CANCEL': 'Cancelar',
	'SECURITY_FEATURES.CONTINUE': 'Continuar',
	'SECURITY_FEATURES.DONE': 'Listo',
	'SECURITY_FEATURES.UPLOAD_FILE': 'Cargar archivo',
	'SECURITY_FEATURES.UPLOAD_FILE_NOTE': 'Cargar aquí archivos para el lote de acción seleccionado:',
	'SECURITY_FEATURES.DRAG_FILE_OR_CLICK': 'Arrastre o haga clic en el archivo para cargarlo',
	'SECURITY_FEATURES.CONFIRM_UPLOAD': 'Confirmar carga de archivo para lote',
	'SECURITY_FEATURES.SCHEDULED_ACTION_ON': 'Acción fijada para el',
	'SECURITY_FEATURES.LOADER_MESSAGE': 'Espere, por favor, Esto puede llevar unos minutos, según el tamaño del archivo.',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_TITLE': 'iLote de archivos recibido con éxito!',
	'SECURITY_FEATURES.LAST_STEP_SUCCESS_MESSAGE': 'Los cambios están pendientes mientras el sistema completa las actualizaciones',
	'SECURITY_FEATURES.LAST_STEP_FAIL_TITLE': 'iError del sistema en lote de archivos!',
	'SECURITY_FEATURES.LAST_STEP_FAIL_MESSAGE': 'Hubo un problema al procesar sus actualizaciones. Inténtelo nuevamente.',
	'SECURITY_FEATURES.DOWNLOAD_TEMPLATE': 'Descargar plantilla',
	'SECURITY_FEATURES.FILES_MUST_BE_SUBMITTED': 'Los archivos deben enviarse con la extensión .csv.',
	'SECURITY_FEATURES.MAXIMUM_SF_PER_UPLOAD': 'El máximo de funciones de seguridad por carga es '
};
