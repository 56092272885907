import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import AddInvoice from './AddInvoice';

import {
	getM2mAccounts,
	postInvoiceAttachment,
} from '../../redux/invoices/actions';
import {
	getM2mAccountsRequest,
	getM2mAccountsFail,
  getM2mAccountsSuccess,
  postInvoiceRequest,
  postInvoiceSuccess
} from '../../redux/invoices/selector';

import { fileUploadSuccess } from '../../../Shared/redux/uploadFile/selectors';
import { resetFiles } from '../../../Shared/redux/uploadFile/actions';

const mapStateToProps = (state) => ({
	m2mAccountsRequest: getM2mAccountsRequest(state),
	m2mAccountsFail: getM2mAccountsFail(state),
	m2mAccounts: getM2mAccountsSuccess(state),
  fileSuccess: fileUploadSuccess(state),
  postInvoiceRequest: postInvoiceRequest(state),
  postInvoiceSuccess: postInvoiceSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getM2mAccounts: () => dispatch(getM2mAccounts()),
	postInvoiceAttachment: (id, data, notifOptions) =>
		dispatch(postInvoiceAttachment(id, data, notifOptions)),
	resetFiles: () => dispatch(resetFiles()),
	goToInvoices: () => dispatch(localizedRoute('/invoices'))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
