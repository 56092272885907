import { connect } from 'react-redux';

import DesktopView from './Desktop';
import MobileView from './Mobile';

import { getFailoverLinks, changeEnvironment } from '../../redux/actions';
import {
	getFailoverLinksSuccess,
	getFailoverLinksRequest,
	getFailoverLinksFail,
	changeEnvironmentRequest,
	changeEnvironmentFail,
	changeEnvironmentSuccess
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	failoverLinksRequest: getFailoverLinksRequest(state),
	failoverLinksFail: getFailoverLinksFail(state),
	failoverLinks: getFailoverLinksSuccess(state),
	changeEnvironmentRequest: changeEnvironmentRequest(state),
	changeEnvironmentFail: changeEnvironmentFail(state),
	changeEnvironmentSuccess: changeEnvironmentSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getFailoverLinks: () => dispatch(getFailoverLinks()),
	changeEnvironment: (data) => dispatch(changeEnvironment(data))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopView);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(MobileView);
