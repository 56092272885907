import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Error403.scss';

const Error403 = () => (
	<div data-spec="error-403">
		<div className={styles.title}>403</div>
		<div className={styles.sub_title}>
			<FormattedMessage id="ERRORS.ERROR" defaultMessage="Error" />
		</div>
		<div className={styles.message}>
			<FormattedMessage
				id="ERRORS.FORBIDDEN_ACCESS"
				defaultMessage="Forbidden Access"
			/>
		</div>
		<br />
		<div className={styles.message}>
			<FormattedMessage
				id="ERRORS.UH_OH_IT_SEEMS_THAT_YOU_DONT_HAVE_PERMISSION_TO_ACCESS_THIS_APPLICATION"
				defaultMessage="Uh oh, It seems that you don't have permission to access this application."
			/>
		</div>
		<br />
		<div className={styles.message}>
			<FormattedMessage
				id="ERRORS.IF_YOU_BELIEVE_YOU_SHOULD_HAVE_ACCESS_PLEASE_CONTACT_YOUR_COMPANY_ADMINISTRATOR"
				defaultMessage="If you believe you should have the access, please contact your company administrator."
			/>
		</div>
	</div>
);

export default Error403;
