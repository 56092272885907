import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import Button from '../../../../lib/DigitalComponents/Button';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';

import styles from './SimOrderConfirmationModal.scss';

const Icon = ImageSelector(CurrentContext.theme, 'svgs/info-empty.svg');

class SimOrderConfirmationModal extends PureComponent {
	onCancel = () => {
		const { onCancel } = this.props;
		onCancel();
	};

	onSubmit = () => {
		const { onSubmit, onCancel } = this.props;
		onCancel();
		onSubmit();
	};

	renderButtons = (isExisting) => (
		<div data-spec="buttons">
			<Button
				type="button"
				variant="link"
				label={
					isExisting ? (
						<FormattedMessage id="NO" defaultMessage="No" />
					) : (
						<FormattedMessage id="CANCEL" defaultMessage="Cancel" />
					)
				}
				dataSpec="cancel-button"
				onClick={this.onCancel}
			/>
			<Button
				type="button"
				variant="primary"
				label={
					isExisting ? (
						<FormattedMessage id="YES" defaultMessage="Yes" />
					) : (
						<FormattedMessage id="SUBMIT" defaultMessage="Submit" />
					)
				}
				dataSpec="submit-button"
				onClick={this.onSubmit}
			/>
		</div>
	);

	render() {
		const { title, message, show, isExisting } = this.props;

		return (
			<Modal data-spec="confirmation-modal" show={show} onClose={this.onCancel}>
				<ModalHeader title={title} />
				<ModalBody className={styles.modal__body}>
					<div data-spec="body-message" className={styles.body}>
						<div className={styles.modal_message}>{message}</div>
					</div>
					<div>
						<Icon />
					</div>
				</ModalBody>
				<ModalFooter data-spec="ds-modal-footer">
					{this.renderButtons(isExisting)}
				</ModalFooter>
			</Modal>
		);
	}
}

const { string, bool, func, object } = PropTypes;

SimOrderConfirmationModal.propTypes = {
	title: PropTypes.oneOfType([string, object]),
	message: PropTypes.oneOfType([string, object]),
	onCancel: func,
	onSubmit: func,
	show: bool,
	isExisting: bool
};

export default SimOrderConfirmationModal;
