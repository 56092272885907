import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Select from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';

import styles from './Mobile.scss';
import hoc from '../ReleaseVersion';

const ROComponent = UserAllowedToAccess(['system.releasenotes_ro']);

function Mobile(props) {
	const {
		releaseNotesListRequest,
		releaseNotesRequest,
		getReleaseNotes,
		getOptions,
		getValue,
		releaseNotesList
	} = props;

	return (
		<>
			<ROComponent>
				<div className={styles.subtitle}>
					<FormattedMessage
						id="RELEASE_NOTES.RELEASE_NOTES_SUBTITLE"
						defaultMessage="IoT Console is updated frequently to ensure most optimal operation and offer extended features and capabilities and user experience improvements. Below are the updates introduced in this release."
					/>
				</div>
			</ROComponent>
			<h3
				className={cn(styles.headline, styles.border)}
				data-spec="release-notes-version"
			>
				<div className={styles.release_version}>
					<FormattedMessage
						id="RELEASE_NOTES.RELEASE_VERSION"
						defaultMessage="Release Version"
					/>
					<Select
						placeholder={
							<FormattedMessage id="SELECT" defaultMessage="Select" />
						}
						onChange={(val) => getReleaseNotes(val.value)}
						data-spec="dropdown"
						options={getOptions()}
						value={getValue()}
						isDisabled={
							releaseNotesListRequest ||
							releaseNotesRequest ||
							(releaseNotesList &&
								releaseNotesList.resultList &&
								!releaseNotesList.resultList.length)
						}
						styles={{
							container: () => ({
								width: '100px'
							})
						}}
					/>
				</div>
			</h3>
		</>
	);
}

const { func, bool, object } = PropTypes;

Mobile.propTypes = {
	releaseNotesListRequest: bool,
	releaseNotesRequest: bool,
	getReleaseNotes: func,
	getOptions: func,
	getValue: func,
	releaseNotesList: object
};

export default hoc()(Mobile);
