import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import FieldGroup from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';
import UploadFile from '../UploadFile';
import { normalizeDoubleSpace } from '../../../../utils/constants';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import styles from './FeedbackWindow.scss';

const Heart = ImageSelector(CurrentContext.theme, 'heart.png');
const RemoveIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');
const validate = (formProps) => {
	const errors = {};

	if (!formProps.pageId) {
		errors.pageId = (
			<FormattedMessage
				id="feedbackSelect"
				defaultMessage="You must select related topic from the list above!"
			/>
		);
	}

	if (!formProps.feedback) {
		errors.feedback = (
			<FormattedMessage
				id="feedbackEnter"
				defaultMessage="You must enter feedback!"
			/>
		);
	}

	if (formProps.feedback && formProps.feedback.length < 10) {
		errors.feedback = (
			<FormattedMessage
				id="feedbackMinLength"
				defaultMessage="Please enter at least 10 characters!"
			/>
		);
	}

	return errors;
};

class FeedbackWindow extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			pageId: null,
			feedback: null
		};
	}

	componentDidMount() {
		const { name, initialize, user } = this.props;
		const pageId = this.getLocation(name, user);

		this.setState(
			{
				pageId
			},
			() => initialize(this.state)
		);
	}

	componentDidUpdate(prevProps) {
		const { name, opened, initialize, user } = this.props;

		if (prevProps.name !== name) {
			const pageId = this.getLocation(name, user);

			this.setStateAfterUpdate(pageId, initialize);
		}
		if (prevProps.opened !== opened && prevProps.opened) {
			this.handleClose();
		}
	}

	setStateAfterUpdate = (pageId, initialize) =>
		this.setState(
			{
				pageId
			},
			() => initialize(this.state)
		);

	getLocation = (name, user) => {
		let pageId = name.split('/')[1];

		if (!pageId) {
			if (user.systemUser && !sessionStorage.getItem('company')) {
				pageId = 'companies';
			} else {
				pageId = 'account-overview';
			}
		}

		return pageId;
	};

	get values() {
		const { getFormValues } = this.props;
		return getFormValues;
	}

	handleClose = () => {
		const {
			reset,
			handleClose,
			resetFeedback,
			feedbackFileUploadClear
		} = this.props;

		reset();
		handleClose({ keyCode: 27 });
		resetFeedback();
		feedbackFileUploadClear();
	};

	handleSubmit = (e) => {
		e.preventDefault();
	};

	handleSendButton = () => {
		const { pageId } = this.state;
		const { isFeedbackFileSuccess, feedbackFile, addFeedback } = this.props;
		const obj = {};
		const userOpt = [];
		let userFeedbackFile = [];

		obj.relatedTo =
			this.values.pageId && this.values.pageId.value
				? this.values.pageId.value
				: pageId;
		obj.description = this.values.feedback.replace(/\s{2,}/g, ' ');

		if (isFeedbackFileSuccess && feedbackFile) {
			userFeedbackFile = [feedbackFile.id];
			obj.userFeedbackFile = userFeedbackFile;
		}

		obj.userFeedbackContactInsertRequests = userOpt;
		addFeedback(obj);
	};

	renderFileName = () => {
		const {
			isFeedbackFileSuccess,
			feedbackFile,
			isFeedbackFileFail,
			isFeedbackFileSending,
			feedbackFileUploadClear,
			feedbackFileRequestURL
		} = this.props;

		if (isFeedbackFileSuccess && feedbackFile) {
			return (
				<div data-spec="file-uploaded" className={styles.field_wrapper}>
					<span className={styles.additional_file}>
						<FormattedMessage
							id="FILE_UPLOADED"
							defaultMessage="File uploaded"
						/>
					</span>
					<div className={styles.field_info}>
						{feedbackFile.fileName}
						<span>
							<RemoveIcon onClick={() => feedbackFileUploadClear()} />
						</span>
					</div>
				</div>
			);
		}

		return (
			<div data-spec="additional-file">
				<div className={styles.field_wrapper}>
					<span className={styles.additional_file}>
						<FormattedMessage
							id="USER_FEEDBACK.ATTACHMENTS"
							defaultMessage="Attachments"
						/>
						<span className={styles.optional}>
							<FormattedMessage
								id="USER_FEEDBACK.OPTIONAL"
								defaultMessage="(Optional)"
							/>
						</span>
					</span>
					{isFeedbackFileSending ? (
						<div className={styles.file_loading}>
							<Loading data-spec="loading" />
						</div>
					) : (
						<UploadFile
							error={isFeedbackFileFail}
							feedbackFileRequestURL={(data) => feedbackFileRequestURL(data)}
						/>
					)}
				</div>
			</div>
		);
	};

	goToTicketing = () => {
    const { goToTicketing } = this.props;

    this.handleClose();
    goToTicketing('/support/ticketing');
  }

	renderBody = () => {
		const {
			submitting,
			valid,
			pristine,
			handleSubmit,
			user,
			isFeedbackSent,
			isFeedbackFileSending,
			menu,
			getLocation
    } = this.props;
    const inStandardMode = sessionStorage.getItem('company');
		const options = [];

		menu
			.filter(
				({ sidebar, permissions, target }) =>
					!target &&
					sidebar &&
					(!permissions ||
						(permissions && isUserAllowedToAccess(permissions, user)))
			)
			.forEach(({ submenu, id, label, link }) => {
				if (submenu) {
					submenu.forEach(
						({
							permissions,
							id: subId,
							label: subLabel,
							target,
							link: subLink
						}) => {
							if (
								!target &&
								(!permissions ||
									(permissions && isUserAllowedToAccess(permissions, user)))
							) {
								options.push({ link: subLink, value: subId, label: subLabel });
							}
						}
					);
				}

				options.push({ link, value: id, label });
			});

		if (!isFeedbackSent) {
			const currentLocation = options
				.filter((option) => getLocation.pathname.toString() === option.link)
				.pop();

			return (
				<div data-spec="feedback-window-body">
					<p className={styles.subtitle}>
						<FormattedMessage
							id="USER_FEEDBACK.HELP_US"
							defaultMessage="Help us maximize your experience by sharing your suggestions."
						/>
					</p>
					<Form
						onSubmit={handleSubmit((e) => this.handleSubmit(e))}
						id="userFeedback"
					>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="USER_FEEDBACK.PAGE_LABEL"
								defaultMessage="Your suggestion is related to"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name="pageId"
										placeholder={
											currentLocation
												? currentLocation.label
												: (options[0] && options[0].label) || ''
										}
										options={options}
										clearable={false}
										searchable={false}
										label={formattedValue}
									/>
								)}
							</FormattedMessage>
						</div>
						<div className={styles.field_wrapper}>
							<FormattedMessage
								id="USER_FEEDBACK.FEED_LABEL"
								defaultMessage="Tell us about your suggestions"
							>
								{(feedbackFeedLabel) => (
									<FormattedMessage
										id="USER_FEEDBACK.FEED_PLACEHOLDER"
										defaultMessage="Example: I'd like to read helpful information about a feature when I hover it with my mouse."
									>
										{(feedbackFeedPlaceholder) => (
											<Field
												component={FieldGroup}
												componentClass="textarea"
												placeholder={feedbackFeedPlaceholder}
												name="feedback"
												label={feedbackFeedLabel}
												normalize={normalizeDoubleSpace}
											/>
										)}
									</FormattedMessage>
								)}
							</FormattedMessage>
						</div>
						{this.renderFileName()}
						<div className={styles.hr} />
						{inStandardMode && (
							<div className={styles.ticketing_link}>
								<FormattedMessage
									id="USER_FEEDBACK.LINK_TO_TICKETING"
									defaultMessage="Have an issue? To report go to"
								/>
								&nbsp;
								<span className={styles.link} onClick={this.goToTicketing}>
									<FormattedMessage
										id="USER_FEEDBACK.TICKETING"
										defaultMessage="Ticketing"
									/>
								</span>
								.
							</div>
						)}
						<div className={styles.footer}>
							<FormattedMessage id="CANCEL" defaultMessage="Cancel">
								{(formattedValue) => (
									<Button
										label={formattedValue}
										type="button"
										variant="outline-primary"
										onClick={this.handleClose}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id="SUBMIT" defaultMessage="Submit">
								{(formattedValue) => (
									<Button
										type="button"
										label={formattedValue}
										variant="primary"
										disabled={
											submitting || !valid || pristine || isFeedbackFileSending
										}
										onClick={this.handleSendButton}
									/>
								)}
							</FormattedMessage>
						</div>
					</Form>
				</div>
			);
		}

		return (
			<div data-spec="feedback-window-success" className={styles.success}>
				<div className={styles.icon}>
					<FormattedMessage
						id="USER_FEEDBACK.ICON"
						defaultMessage="Success Feedback Submit Icon"
					>
						{(formattedValue) => <img src={Heart} alt={formattedValue} />}
					</FormattedMessage>
				</div>
				<p className={styles.subtitle}>
					<FormattedMessage
						id="USER_FEEDBACK.SUCCESS_THANKS"
						defaultMessage="Thank you for the feedback."
					/>
				</p>
				<p className={styles.subtitle}>
					<FormattedMessage
						id="USER_FEEDBACK.SUCCESS_MESSAGE"
						defaultMessage="Your suggestion will make our product better. We will carefully evaluate your comments and act upon it."
					/>
				</p>
				<p className={styles.subtitle}>
					<FormattedMessage
						id="USER_FEEDBACK.SUCCESS_MESSAGE_SUB"
						defaultMessage="We may also contact you for more information regarding your suggestion."
					/>
				</p>
				<h5>
					--
					<FormattedMessage
						id="USER_FEEDBACK.SUCCESS_MESSAGE_SIGN"
						defaultMessage="IoT Console Team"
					/>
				</h5>
				<div className={styles.hr} />
				<div className={cn(styles.footer, styles.center)}>
					<FormattedMessage
						id="USER_FEEDBACK.SUCCESS_DONE_BUTTON"
						defaultMessage="Done"
					>
						{(formattedValue) => (
							<Button
								type="submit"
								label={formattedValue}
								variant="primary"
								onClick={this.handleClose}
							/>
						)}
					</FormattedMessage>
				</div>
			</div>
		);
	};

	renderLoader = () => (
		<div data-spec="feedback-window-body">
			<div className={styles.success}>
				<Loading data-spec="loading" />
			</div>
			<div className={styles.footer} />
		</div>
	);

	render() {
		const { isFeedbackSending, opened } = this.props;
		const feedbackContainer = cn({
			[styles.feedback_container]: true,
			[styles.opened]: opened
		});
		const feedbackWindow = cn({
			[styles.feedback_window]: true,
			[styles.opened_window]: opened
		});

		return (
			<div className={feedbackContainer} data-spec="user-feedback-window">
				<div className={feedbackWindow}>
					<div className={styles.corner_button}>
						<button
							type="button"
							className={styles.close}
							onClick={this.handleClose}
							aria-label="Corner Button"
						/>
					</div>
					<h2 className={styles.title}>
						<FormattedMessage
							id="USER_FEEDBACK.TITLE"
							defaultMessage="User Feedback"
						/>
					</h2>
					{isFeedbackSending && this.renderLoader()}
					{!isFeedbackSending && this.renderBody()}
				</div>
			</div>
		);
	}
}

const {
	func,
	object,
	bool,
	string,
	objectOf,
	arrayOf,
	oneOfType,
	array,
	number
} = PropTypes;

FeedbackWindow.propTypes = {
	handleClose: func,
	valid: bool,
	pristine: bool,
	submitting: bool,
	reset: func,
	isFeedbackSending: bool,
	isFeedbackSent: bool,
	addFeedback: func,
	initialize: func,
	name: string,
	resetFeedback: func,
	handleSubmit: func,
	user: oneOfType([string, array, object]),
	feedbackFileRequestURL: func,
	isFeedbackFileSending: bool,
	isFeedbackFileFail: bool,
	isFeedbackFileSuccess: bool,
	feedbackFile: objectOf(oneOfType([number, string])),
	feedbackFileUploadClear: func,
	opened: bool,
	getFormValues: objectOf(oneOfType([string, bool, object])),
	menu: arrayOf(object),
	getLocation: objectOf(oneOfType([string, object])),
	goToTicketing: func
};

FeedbackWindow.defaultProps = {
	handleClose: undefined,
	valid: false,
	pristine: false,
	submitting: false,
	reset: undefined,
	isFeedbackSending: false,
	isFeedbackSent: false,
	addFeedback: undefined,
	initialize: undefined,
	name: '',
	resetFeedback: undefined,
	handleSubmit: undefined,
	user: { services: [] },
	feedbackFileRequestURL: undefined,
	isFeedbackFileSending: false,
	isFeedbackFileFail: false,
	isFeedbackFileSuccess: false,
	feedbackFile: {},
	feedbackFileUploadClear: undefined,
	opened: false,
	getFormValues: {},
	menu: [],
	getLocation: {},
	goToTicketing: undefined
};

export default reduxForm({
	validate,
	form: 'userFeedback',
	fields: ['pageId', 'feedback'],
})(FeedbackWindow);
