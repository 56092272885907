import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { startOfDay, endOfDay, isValid, format } from 'date-fns';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import {
	UserAllowedToAccess,
	isUserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import {
	dateFormat,
	SimOrderingTableHeader as tableHeader,
	SimOrderingTableHeaderMobile as tableHeaderMobile,
	simOrderingSearchMenu,
	SystemSimOrderingTableHeader as systemTableHeader
} from '../../utils/constants';

import Button from '../../../../lib/DigitalComponents/Button';
import Select, {
	components,
} from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Loading from '../../../../lib/DigitalComponents/Loader';

import FilterModal from '../../../Shared/components/FilterModal';
import PageTitle from '../../../Shared/views/PageTitleView';
import ActionBar from '../../../Shared/views/ActionBarView';
import NoData from '../../../Shared/views/NoDataView';

import styles from './SimOrdering.scss';

import {
	getSimOrderingFilters as getSimOrderingFiltersService,
	getSystemSimOrderingFilters
} from '../../services/SimOrderingService';

import { filterHelpers, newOnPageChange } from '../../../../utils/tableHelpers';

const ExpeditedIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');

const SUComponent = UserAllowedToAccess(['system.managesimorders_rw']);
const RWComponent = UserAllowedToAccess(['mnc.simordering_rw']);
const ROComponent = UserAllowedToAccess([
	'mnc.simordering_rw',
	'mnc.simordering_ro',
]);

const SimOrdering = () => (WrappedComponent) => {
	class SimOrderingComponent extends PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				orderColumn: {},
				creatingData: false,
			};
		}

		componentDidMount() {
			const { orderStatuses, getOrderStatuses, getPlatformsInit } = this.props;
			if (!orderStatuses) {
				getOrderStatuses();
			}
			this.getSimOrders();
			getPlatformsInit();
		}

		componentDidUpdate(prevProps) {
			const { editSimOrderSuccess } = this.props;

			if (
				editSimOrderSuccess !== prevProps.editSimOrderSuccess
			) {
				this.getSimOrders();
			}
		}

		getSimOrders = () => {
			const {
				getSimOrders,
				filterData,
				dataOffset,
				dataLimit,
				system,
				searchParameter
			} = this.props;

			const { orderColumn } = this.state;

			const realProps = {
				filter_category_status: 'simOrderStatusId',
				filter_category_platform: 'platformTypeId',
				filter_category_sku: 'simSkuId',
				filter_category_expedited: 'expeditedShipping'
			};

			let simpleSearchParams = [];
			if (searchParameter) {
				if (
					['createdAt', 'targetImplementationDate'].includes(
						searchParameter.prop
					)
				) {
					if (isValid(new Date(searchParameter.propValue))) {
						simpleSearchParams = [
							{
								operator: '>=',
								prop: searchParameter.prop,
								propValue: `${startOfDay(
									new Date(searchParameter.propValue)
								)}`,
							},
							{
								operator: '<=',
								prop: searchParameter.prop,
								propValue: `${endOfDay(
									new Date(searchParameter.propValue)
								)}`,
							},
						];
					}
				} else if (searchParameter.prop === 'quantity') {
					simpleSearchParams = [
						{
							operator: '=',
							prop: searchParameter.prop,
							propValue: Number.isNaN(
								Number(searchParameter.propValue)
							)
								? 0
								: parseInt(searchParameter.propValue, 10),
						},
					];
				} else {
					simpleSearchParams = [
						{
							operator: 'iLike',
							prop: searchParameter.prop,
							propValue: `${searchParameter.propValue}%`,
						},
					];
				}
			}
			let filterParams = [];
			if (filterData) {
				filterParams = filterHelpers(filterData, realProps);
			}

			const columnsToSelect =
				'id,createdAt,simOrderId,platformTypeName,orderDate,m2mAccountId,po,quantity,simOrderStatusId,orderStatusName,simSkuName,targetImplementationDate,expeditedShipping,accountId,companyName,m2mAccountName';

			const params = {
				searchParams: [...simpleSearchParams, ...filterParams],
				additionalParams: {
					dataOffset,
					dataLimit,
					columnsToSelect,
				},
				system,
			};

			// Ordering
			if (!isEmpty(orderColumn)) {
				params.additionalParams.dataSort = orderColumn.apiString;
			} else {
				params.additionalParams.dataSort = 'createdAt desc';
			}

			getSimOrders(params);
		};

		Option = (optionProps) => {
			const { data, children } = optionProps;
			return (
				<components.Option {...optionProps} data-spec={`${data.value}`}>
					<div
						data-spec={`${data.value}`}
						className={`${styles[this.renderStatusClass(data.value)]} ${
							styles.custom_option
						}`}
					>
						{children}
					</div>
				</components.Option>
			);
		};

		SingleValue = (optionProps) => {
			const { data } = optionProps;
			return (
				<components.SingleValue
					{...optionProps}
					data-spec={`${data.value}`}
					className={`${styles[this.renderStatusClass(data.value)]} ${
						styles.custom_option
					} ${styles.select_single_value}`}
				/>
			);
		};

		totalCount = () => {
			const { totalCount, dataLimit } = this.props;
			return Math.ceil(totalCount / dataLimit);
		};

		onOrderClick = (data) => {
			const { name: column, apiString } = data;
			const { orderColumn } = this.state;
			this.setState(
				{
					orderColumn: {
						name: column,
						order: orderColumn.name === column ? !orderColumn.order : true,
						apiString,
					},
				},
				() => this.getSimOrders()
			);
		};

		getOptions = (target) => {
			const {
				totalCount,
				selectedPage,
				setSelectedPage,
				setDataOffset,
				dataLimit,
				system
			} = this.props;
			const { orderColumn } = this.state;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			options.tableOptions = {
				showPagination: totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				onPageChange: (data) =>
					newOnPageChange(
						data,
						this.getSimOrders,
						dataLimit,
						setDataOffset,
						setSelectedPage
					),
			};

			if (isDesktop) {
				options.header = system ? systemTableHeader : tableHeader;
			}

			if (isMobile) {
				options.header = tableHeaderMobile;
				options.tableOptions = {
					...options.tableOptions,
					mobileRowClick: (val) => this.goToSimOrder(val.id),
				};
				options.customComponents = {
					...options.customComponents,
					simOrderId: {
						type: 'custom',
						component: (val) => <span>{val.simOrderId}</span>,
					},
				};
			}

			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions,
					firstColumnMarked: true,
					fixedFirstColumn: true,
					orderByData: ['all'],
					excludeFromOrdering: ['onboardingStatus', 'actions'],
					getOrderData: (data) => this.onOrderClick(data),
					orderColumn,
				};

				options.customComponents = {
					...options.customComponents,
					simOrderId: {
						type: 'custom',
						component: (val) => (
							<span
								className={styles.clickable}
								onClick={() => this.goToSimOrder(val.id)}
							>
								{val.simOrderId}
							</span>
						),
					},
				};
			}

			options.customComponents = {
				...options.customComponents,
				platformTypeName: {
					type: 'custom',
					component: (val) => <span>{val.platformTypeName}</span>,
				},
				createdAt: {
					type: 'custom',
					component: (val) => format(new Date(val.createdAt), dateFormat),
				},
				m2mAccountId: {
					type: 'custom',
					component: (val) => val.m2mAccountId,
				},
				simSkuName: {
					type: 'custom',
					component: (val) => val.simSkuName,
				},
				orderStatusName: {
					type: 'custom',
					component: (val) => this.renderOrderStatus(val),
				},
				targetImplementationDate: {
					type: 'custom',
					component: (val) =>
						val.targetImplementationDate &&
						format(new Date(val.targetImplementationDate), dateFormat),
				},
				expeditedShipping: {
					type: 'custom',
					component: (val) =>
						val.expeditedShipping && (
							<div className={styles.icon_expedited}>
								<ExpeditedIcon />
							</div>
						),
				},
			};

			return options;
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		createFilterData = (data) => {
			const { messages } = this.props;
			const filterDataKeys = Object.keys(data);
			let filter = {};
			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					filter = {
						...filter,
						[`${item}`]: data[item].resultList.map((filterItem) => {
							const lbl = filterItem.code || filterItem.name;
							return {
								value: filterItem.id,
								label: messages[`FILTER_VALUE_${lbl.toUpperCase()}`] || lbl,
								checked: false,
							};
						}),
					};
				});
			return filter;
		};

		onChange = () => {
			this.getSimOrders();
		};

		onSearchSubmitCallback = () => {
			this.getSimOrders();
		};

		clearFilters = () => {
			this.getSimOrders();
		};

		onApply = () => {
			this.getSimOrders();
		};

		createSimOrder = () => {
			const { createSimOrder } = this.props;
			createSimOrder('/sim-ordering/order');
		};

		goToSimOrder = (id) => {
			const { goToSimOrder, system } = this.props;
			if (system) {
				goToSimOrder(`/company-sim-ordering/order/${id}`);
			} else {
				goToSimOrder(`/sim-ordering/order/${id}`);
			}
		};

		updateOrderStatus(id, newStatus) {
			const { simOrders, editSimOrder, system } = this.props;
			if (simOrders && simOrders.resultList.length) {
				const simOrder = simOrders.resultList.find((f) => f.id === id);
				if (simOrder && simOrder.simOrderStatusId !== newStatus) {
					editSimOrder({ id, data: { simOrderStatusId: newStatus }, system });
				}
			}
		}

		renderStatusClass = (value) => {
			let className = '';

			if (value === 1) className = 'pending';
			else if (value === 2) className = 'received';
			else if (value === 3) className = 'shipped';
			else if (value === 4) className = 'completed';

			return className;
		};

		renderOrderStatus(val) {
			const { orderStatuses, orderStatusesRequest, user, intl } = this.props;
			return (
				<div data-spec="order-status-wrapper">
					{isUserAllowedToAccess(['system.managesimorders_rw'], user) ? (
						<SUComponent>
							<div data-spec={`order-status-${val && val.simOrderId}`}>
								<Select
									name="orderStatusId"
									menuPosition="fixed"
									options={
										orderStatuses &&
										orderStatuses.resultList &&
										orderStatuses.resultList.map((item) => ({
											value: item.id,
											label: (
												<FormattedMessage
													id={`ORDER_STATUS_${item.name.toUpperCase()}`}
													defaultMessage={item.name}
												/>
											)
										}))
									}
									onChange={(data) =>
										this.updateOrderStatus(val.id, data.value)}
									value={{
										value: val.simOrderStatusId,
										label: intl.formatMessage({
											id: `ORDER_STATUS_${
												val.orderStatusName &&
												val.orderStatusName.toUpperCase()
											}`,
											defaultMessage: val.orderStatusName
										})
									}}
									isDisabled={orderStatusesRequest || !orderStatuses}
									components={{
										Option: this.Option,
										SingleValue: this.SingleValue
									}}
									styles={{
										valueContainer: (provided) => ({
											...provided,
											paddingLeft: '0px',
											paddingRight: '0px'
										})
									}}
								/>
							</div>
						</SUComponent>
					) : (
						<ROComponent>
							<div
								data-spec="order-status"
								className={cn(
									styles.order_status,
									val.simOrderStatusId === 1 && styles.pending,
									val.simOrderStatusId === 2 && styles.received,
									val.simOrderStatusId === 3 && styles.shipped,
									val.simOrderStatusId === 4 && styles.completed
								)}
							>
								<FormattedMessage
									id={`ORDER_STATUS_${
										val.orderStatusName && val.orderStatusName.toUpperCase()
									}`}
									defaultMessage={
										val.orderStatusName && val.orderStatusName.toUpperCase()
									}
								/>
							</div>
						</ROComponent>
					)}
				</div>
			);
		}

		render() {
			const {
				messages,
				simOrders,
				simOrdersRequest,
				simOrdersFail,
				editSimOrderRequest,
				system,
			} = this.props;

			const isDataEmpty =
				simOrders && simOrders.resultList && simOrders.resultList.length === 0;

			const params = {
				service: system ? getSystemSimOrderingFilters : getSimOrderingFiltersService,
				createFilterData: this.createFilterData,
			};

			return (
				<div data-spec="sim-ordering">
					<PageTitle
						title={
							<FormattedMessage
								id="SIM_ORDERING"
								defaultMessage="SIM Ordering"
							/>
						}
						actions={
							<RWComponent>
								{!system && (
									<Button
										label={
											<FormattedMessage
												id="CREATE_SIM_ORDER"
												defaultMessage="Create SIM Order"
											/>
										}
										variant="primary"
										dataSpec="create-sim-order-button"
										style={{ width: '250px' }}
										onClick={() => this.createSimOrder()}
										disabled={simOrdersRequest}
									/>
								)}
							</RWComponent>
						}
					/>
					<ActionBar
						menu={simOrderingSearchMenu}
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						onChange={this.onChange}
						clearFilters={this.clearFilters}
						openFilterModal={this.openFilterModal}
						isDisabled={simOrdersRequest}
						isDataEmpty={isDataEmpty}
					/>
					<FilterModal
						messages={messages}
						onApply={this.onApply}
						params={params}
						type="radio"
					/>
					{(simOrdersRequest ||
						editSimOrderRequest ||
						(!simOrders && !simOrdersFail)) && <Loading data-spec="loading" />}
					{!simOrdersRequest && !editSimOrderRequest && simOrders && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							getOptions={this.getOptions}
						/>
					)}
					{!simOrdersRequest && isDataEmpty && <NoData table />}
				</div>
			);
		}
	}

	const {
		object,
		func,
		bool,
		array,
		number,
		objectOf,
		string,
		shape,
	} = PropTypes;

	SimOrderingComponent.propTypes = {
		messages: objectOf(string),
		dataLimit: number,
		totalCount: number,

		getOrderStatuses: func,
		orderStatusesRequest: bool,
		orderStatuses: shape({
			resultList: array,
		}),

		getSimOrders: func,
		simOrders: shape({
			resultList: array,
		}),
		simOrdersRequest: bool,
		simOrdersFail: bool,

		createSimOrder: func,
		goToSimOrder: func,

		editSimOrder: func,
		editSimOrderSuccess: bool,
		editSimOrderRequest: bool,

		openFilterModal: func,
		filterModalOpened: bool,
		simpleSearchStart: func,
		simpleSearchClose: func,

		getPlatformsInit: func,
		user: shape(),
		filterData: shape(),
		dataOffset: number,
		selectedPage: number,
		setDataOffset: func,
		setSelectedPage: func,
		setPaginationInit: func,
		system: bool,
    searchParameter: object,
    intl: object
	};

	return injectIntl(SimOrderingComponent);
};

export default SimOrdering;
