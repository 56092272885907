import { Status } from '../../../../utils/constants';

//  GET RESTRAINTS
export const getRestraintsRequest = (state) =>
	state.simOrdering.restraints.getRestraints.status === Status.PENDING;
export const getRestraintsFail = (state) =>
	state.simOrdering.restraints.getRestraints.status === Status.FAIL;
export const getRestraintsSuccess = (state) =>
	state.simOrdering.restraints.getRestraints.data;

//  ADD RESTRAINTS
export const addRestraintsRequest = (state) =>
	state.simOrdering.restraints.addRestraints.status === Status.PENDING;
export const addRestraintsFail = (state) =>
	state.simOrdering.restraints.addRestraints.status === Status.FAIL;
export const addRestraintsSuccess = (state) =>
	state.simOrdering.restraints.addRestraints.status === Status.DONE;

//  UPDATE RESTRAINS
export const updateRestraintsRequest = (state) =>
	state.simOrdering.restraints.updateRestraints.status === Status.PENDING;
export const updateRestraintsFail = (state) =>
	state.simOrdering.restraints.updateRestraints.status === Status.FAIL;
export const updateRestraintsSuccess = (state) =>
	state.simOrdering.restraints.updateRestraints.status === Status.DONE;

//  GET RESTRAINT PERIODS
  export const getRestraintPeriodsRequest = (state) =>
	state.simOrdering.restraints.getRestraintPeriods.status === Status.PENDING;
export const getRestraintPeriodsFail = (state) =>
	state.simOrdering.restraints.getRestraintPeriods.status === Status.FAIL;
export const getRestraintPeriodsSuccess = (state) =>
	state.simOrdering.restraints.getRestraintPeriods.data;
