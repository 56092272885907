import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_VOICE_DETAILS, GET_VOICE_DETAILS_FILTERS } from './constants';

export default combineReducers({
	getVoiceDetails: globalReducer(GET_VOICE_DETAILS),
	getVoiceDetailsFilters: globalReducer(GET_VOICE_DETAILS_FILTERS)
});
