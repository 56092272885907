import { Status } from '../../../utils/constants';

/* GET RELEASE NOTES LIST */
export const getReleaseNotesListRequest = (state) =>
	state.releaseNotes.getReleaseNotesList.status === Status.PENDING;
export const getReleaseNotesListFail = (state) =>
	state.releaseNotes.getReleaseNotesList.status === Status.FAIL;
export const getReleaseNotesListSuccess = (state) =>
	state.releaseNotes.getReleaseNotesList.data;

/* GET RELEASE NOTES */
export const getReleaseNotesRequest = (state) =>
	state.releaseNotes.getReleaseNotes.status === Status.PENDING;
export const getReleaseNotesFail = (state) =>
	state.releaseNotes.getReleaseNotes.status === Status.FAIL;
export const getReleaseNotesSuccess = (state) =>
	state.releaseNotes.getReleaseNotes.data;

/* ADD RELEASE NOTES */
export const addReleaseNotesRequest = (state) =>
	state.releaseNotes.addReleaseNotes.status === Status.PENDING;
export const addReleaseNotesFail = (state) =>
	state.releaseNotes.addReleaseNotes.status === Status.FAIL;
export const addReleaseNotesSuccess = (state) =>
	state.releaseNotes.addReleaseNotes.data;

/* EDIT RELEASE NOTES */
export const editReleaseNotesRequest = (state) =>
	state.releaseNotes.editReleaseNotes.status === Status.PENDING;
export const editReleaseNotesFail = (state) =>
	state.releaseNotes.editReleaseNotes.status === Status.FAIL;
export const editReleaseNotesSuccess = (state) =>
	state.releaseNotes.editReleaseNotes.data;

/* ADDITIONAL DETAIL STATUSES */
export const getAdditionalDetailStatusesRequest = (state) =>
	state.releaseNotes.getAdditionalDetailStatuses.status ===
	Status.PENDING;
export const getAdditionalDetailStatusesFail = (state) =>
	state.releaseNotes.getAdditionalDetailStatuses.status ===
	Status.FAIL;
export const getAdditionalDetailStatusesSuccess = (state) =>
  state.releaseNotes.getAdditionalDetailStatuses.data;

/* PUBLISH RELEASE NOTES */
export const publishReleaseNotesRequest = (state) =>
	state.releaseNotes.publishReleaseNotes.status === Status.PENDING;
export const publishReleaseNotesFail = (state) =>
	state.releaseNotes.publishReleaseNotes.status === Status.FAIL;
export const publishReleaseNotesSuccess = (state) =>
	state.releaseNotes.publishReleaseNotes.status === Status.DONE;
