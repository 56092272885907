import React from 'react';
import {
	func,
	object,
	bool,
	array,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DSSteps from '../../../../lib/DigitalComponents/Steps';
import {
	StepFirst,
	StepSecond,
	StepThird,
} from './Steps';

export default function RatePlanChangeStepper({
	ModalContextConsumer,
	stepFirstProps,
	stepSecondProps,
	stepThirdProps,
	onNext,
	onBack,
	onFinish,
	isActionError,
	getRatePlans,
	updateRatePlanStatusInit,
	ratePlans,
	ratePlanUpdate,
	isRatePlanUpdating,
	ratePlanUpdateFail,
	ratePlanUpdateSuccess,
}) {
	const ratePlanSteps = [
		{
			label: <FormattedMessage
				id="SINGLE_ENDPOINT.SELECT_RATE_PLAN"
				defaultMessage="1. Select Rate Plan"
			/>,
			component: (
				<ModalContextConsumer>
					<StepFirst
						onClose={stepFirstProps.onClose}
						endpointId={stepFirstProps.endpointId}
						ratePlanId={stepFirstProps.ratePlanId}
						getRatePlans={getRatePlans}
						updateRatePlanStatusInit={updateRatePlanStatusInit}
						ratePlans={ratePlans}
					/>
				</ModalContextConsumer>
			)
		},
		{
			label: <FormattedMessage
				id="SINGLE_ENDPOINT.CONFIRM_CHANGES"
				defaultMessage="2. Confirm Changes"
			/>,
			component: (
				<ModalContextConsumer>
					<StepSecond
						onClose={stepSecondProps.onClose}
						ratePlans={stepSecondProps.ratePlans}
						endpointId={stepSecondProps.endpointId}
						ratePlanUpdate={ratePlanUpdate}
					/>
				</ModalContextConsumer>
			)
		},
		{
			label: <FormattedMessage
				id="SINGLE_ENDPOINT.SYSTEM_CONFIRMATION"
				defaultMessage="3. System Confirmation"
			/>,
			component: (
				<ModalContextConsumer>
					<StepThird
						onClose={stepThirdProps.onClose}
						ratePlans={stepThirdProps.ratePlans}
						getSingleEndpoint={stepThirdProps.getSingleEndpoint}
						type={stepThirdProps.type}
						endpointId={stepThirdProps.endpointId}
						isRatePlanUpdating={isRatePlanUpdating}
						ratePlanUpdateFail={ratePlanUpdateFail}
						ratePlanUpdateSuccess={ratePlanUpdateSuccess}
					/>
				</ModalContextConsumer>
			)
		}
	];

	return (
		<DSSteps
			data-spec="ratePlanSteps"
			steps={ratePlanSteps}
			onNext={onNext}
			noActions
			onBack={onBack}
			onFinish={onFinish}
			isActionError={isActionError}
		/>
	);
}

RatePlanChangeStepper.propTypes = {
	onNext: func,
	onBack: func,
	onFinish: func,
	stepThirdProps: object,
	stepFirstProps: object,
	stepSecondProps: object,
	ModalContextConsumer: func,
	isActionError: bool,
	getRatePlans: func,
	updateRatePlanStatusInit: func,
	ratePlans: array,
	ratePlanUpdate: func,
	isRatePlanUpdating: bool,
	ratePlanUpdateFail: bool,
	ratePlanUpdateSuccess: bool,
};
