export default {
	'ENDPOINTS.MULTI_ENDPOINT_ACTIONS': 'Multi-Endpunkt-Aktionen',
	'ENDPOINTS.PLEASE_SELECT_MORE_SIMS': 'Bitte wählen Sie zwei oder mehr Endpunkte aus',
	'ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE': 'Bitte wenden Sie einen oder mehrere Filter an, um die Exportfunktion zu aktivieren',
	'ENDPOINTS.CURRENTLY_ASSIGNED_TO_SELECTED_ENDPOINTS': 'Aktuell ausgewählten Endpunkten zugewiesen',
	'ENDPOINTS.ELIGIBLE_TO_USE_WITH_SELECTED_ENDPOINTS': 'Für die Verwendung mit ausgewählten Endpunkten qualifiziert',
	'ENDPOINTS.MULTIPLE_NETWORK_ENTITLEMENT_CHANGE_ERROR': 'Mehrfachänderung für SIM-Netzwerkanspruch konnte nicht mit Erfolg angefordert werden',
	'ENDPOINTS.SIMS_NETWORK_ENTITLEMENT_SUCCESSFULLY_UPDATED': 'SIM-Netzwerkanspruchsänderung mit Erfolg aktualisiert',
	'ENDPOINTS.VIEW_NOTE': 'Anmerkung anzeigen',
	'ENDPOINTS.NO_ENDPOINTS_AVAILABLE': 'Keine Endpunkte verfügbar',
	'ENDPOINTS.NO_ENDPOINTS_SUBTITLE': 'Für diese Tabelle sind derzeit keine Endpunkte zur Ansicht verfügbar',
	//  TABELLE BEARBEITEN MODAL
	'ENDPOINTS.TABLE_NAME': 'Tabellenname',
	'ENDPOINTS.EDIT_TABLE_VIEW_NAME': 'Tabellenansichtsname bearbeiten',
	'ENDPOINTS.PLEASE_ENTER_TABLE_NAME': 'Bitte Tabellennamen eingeben',
	'ENDPOINTS.EDIT_TABLE': 'Tabellenparameter bearbeiten',
	'ENDPOINTS.EDIT_CUSTOM_FIELD_LABELS': 'Benutzerdefinierte Feldbezeichnungen bearbeiten',
	'ENDPOINTS.EDIT_TABLE_PARAMETERS': 'Tabellenparameter bearbeiten',
	'ENDPOINTS.APPLY_CHANGE': 'Änderung übernehmen',
	'ENDPOINTS.DELETE_TAB': 'Register löschen',
	'ENDPOINTS.CUSTOM_LABEL': 'Benutzerdefinierte Bezeichnung {index}',
	'ENDPOINTS.CLEAR_ALL': 'Auf Voreinstellung zurücksetzen',
	'ENDPOINTS.DEVICE_TYPE': 'Gerätetyp',
	'ENDPOINTS.BULK_UPLOAD': 'Massen-Upload',

	//  TABELLENPARAMETER BEARBEITEN
	'ENDPOINTS.SELECT_A_MINIMUM_OF_4_TO_APPLY_TO_THE_TABLE': 'Mindestens 2 auswählen und auf Tabelle anwenden. ',
	'ENDPOINTS.CELLULAR_COLUMNS': 'Mobilfunkspezifische Parameter',
	'ENDPOINTS.SATELLITE_SPECIFIC_COLUMNS': 'Satellitenspezifische Parameter',

	//  MOBILFUNKSPEZIFISCHE PARAMETER
	'ENDPOINTS.M2MACCOUNTID': 'M2M-Konto-ID',
	'ENDPOINTS.IMEI': 'IMEI',
	'ENDPOINTS.STATUS': 'Status',
	'ENDPOINTS.IMSI': 'IMSI',
	'ENDPOINTS.NETWORK_OPERATOR': 'Netzbetreiber',
	'ENDPOINTS.NETWORK_TYPE': 'Netzwerktyp',
	'ENDPOINTS.IMSI_TRANSFER': 'IMSI-Übertragungsstatus',
	'ENDPOINTS.RATE_PLAN': 'Tarifplan',
	'ENDPOINTS.RECREDENTIAL_STATUS': 'Status erneute Zulassung',
	'ENDPOINTS.ICCID': 'ICCID',
	'ENDPOINTS.SERVICE_PROVIDER': 'M2M-Plattform',
	'ENDPOINTS.MSISDN': 'MSISDN',
	'ENDPOINTS.COUNTRY_OPERATOR': 'Netzland',
	'ENDPOINTS.NETWORK_ENTITLEMENT_ID': 'Netzwerkanspruchs-ID',
	'ENDPOINTS.OEM': 'Unterkonten',
	'ENDPOINTS.SERVICE_STATUS': '360L-Status',
	'ENDPOINTS.VIN': 'VIN',
	'ENDPOINTS.ROAMING_COUNTRY': 'Roaming-Land',
	'ENDPOINTS.ROAMING_CARRIER': 'Roaming-Betreiber',
	'ENDPOINTS.M2M_DATE_ADDED': 'M2M-Datum hinzugefügt',
	'ENDPOINTS.M2M_INITIAL_ACTIVATION_DATE': 'M2M-Aktivierungsdatum',
	'ENDPOINTS.NOTES': 'Anmerkungen',
	'ENDPOINTS.CTD_DATA_USAGE': 'CTD-Datennutzung',
	'ENDPOINTS.CTD_SMS_MO_MT_USAGE': 'CTD-SMS-Nutzung',
	'ENDPOINTS.CTD_VOICE_USAGE': 'CTD-Voice-Nutzung',
	'ENDPOINTS.INSESSION': 'In Sitzung',

	//  SATELLITENSPEZIFISCHE PARAMETER
	'ENDPOINTS.M2M_ACC_ID': 'M2M-Konto-ID',
	'ENDPOINTS.DEVICE_NAME': 'Gerätename',
	'ENDPOINTS.DEVICE_ID': 'Geräte-ID',
	'ENDPOINTS.DEVICE_MODEL_NUMBER': 'Gerätemodellnr.',

	//  TARIFPLAN PLANUNG MODAL
	'ENDPOINTS.RATE_PLAN_CHANGE_NOT_AVAILABLE': 'Die zugrunde liegende Plattform ist fehlerhaft und der Tarifplans kann derzeit nicht geändert werden',
	'ENDPOINTS.RATE_PLAN_CHANGE': 'Tarifplanänderung',
	'ENDPOINTS.RATE_PLAN_CHANGED_TO': 'Sie wechseln ihren Tarifplan zu',
	'ENDPOINTS.TAP_INITIATE_CHANGE': "Wählen Sie 'Änderung einleiten', um sofort eine Aktion zu ergreifen.",
	'ENDPOINTS.CHANGE_LATER': 'Sie können die Änderung auch für später einplanen.',
	'ENDPOINTS.SCHEDULED_CHANGE_WILL_HAPPEN': 'Geplante Änderung findet zu Beginn des Datums Ihrer Wahl statt.',
	'ENDPOINTS.SCHEDULE_CHANGE_BUTTON': 'Änderung planen',
	'ENDPOINTS.INITIATE_CHANGE': 'Änderung einleiten',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'Tarifplanänderung erfolgreich eingeplant',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'Tarifplanänderung erfolgreich eingeplant für{scheduleDate}',
	'ENDPOINTS.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'Tarifplanänderung konnte nicht erfolgreich eingeplant werden',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_CHANGE_STEP': '1. Tarifplanänderung einplanen',
	'ENDPOINTS.SYSTEM_CONFIRMATION_STEP': '2. Systembestätigung',
	'ENDPOINTS.NOTIFICATION_ERROR': 'Endpunkt-ID {endpointICCID} Konnte nach Tarifplanänderung nicht bereitgestellt werden.  Support erhalten',
	'ENDPOINTS.SCHEDULE_DATE': 'Datum',
	'ENDPOINTS.SIM_STATE_CHANGE': 'SIM-Statusänderung',
	'ENDPOINTS.SIM_STATE_CHANGED_TO': 'Sie ändern den SIM-Status auf',
	'ENDPOINTS.STATUS_CHANGE_ERROR': 'Benutzer {user} hat am {changeDate} eine Statusänderung vorgenommen',
	'ENDPOINTS.SCHEDULING': 'Planung ...',

	//  SIM-STATUS
	'ENDPOINTS.ACTIVE': 'Aktiviert',
	'ENDPOINTS.TERMINATE': 'Beendet',
	'ENDPOINTS.PROVISIONED': 'Bereitgestellt',
	'ENDPOINTS.DEACTIVE': 'Inaktiv',
	'ENDPOINTS.INACTIVE': 'Inaktiv',
	'ENDPOINTS.TEST': 'Test',
	'ENDPOINTS.SUSPENDED': 'Suspendiert',
	'ENDPOINTS.SUSPEND': 'Suspendieren',
	'ENDPOINTS.INVENTORY': 'Inventar',

	//  SIM-STATUS ÄNDERUNG PLANEN
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE_STEP': '1. SIM-Statusänderung einplanen',
	'ENDPOINTS.SCHEDULE_SIM_STATE_CHANGE': 'SIM-Statusänderung einplanen',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED': 'SIM-Statusänderung erfolgreich eingeplant!',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR': 'SIM-Statusänderung erfolgreich eingeplant für{scheduledDate}',
	'ENDPOINTS.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR': 'SIM-Statusänderung konnte nicht erfolgreich eingeplant werden!',
	'ENDPOINTS.PLEASE_TRY_AGAIN': 'Bitte versuchen Sie es noch einmal.',
	'ENDPOINTS.NOTIFICATION_STATUS_CHANGE_ERROR': 'Endpunkt-ID {endpointICCID} Bereitstellung nach IMSI-Statusänderung fehlgeschlagen.  Support erhalten',
	'ENDPOINTS.STATUS_CHANGE_USER_NOTE': 'Benutzer {user} hat am {changeDate} eine Statusänderung vorgenommen',
	'ENDPOINTS.UPDATE_ENDPOINT_STATUS': 'Benutzer {user} hat am {date} eine Statusänderung vorgenommen',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_SCHEDULE_ERROR': 'Mehrfach-SIM-Statusänderung konnte nicht erfolgreich eingeplant werden.',
	'ENDPOINTS.MULTIPLE_SIM_STATE_CHANGE_ERROR': 'Mehrfach-SIM-Statusänderung konnte nicht erfolgreich angefordert werden.',

	//  BERICHT ERSTELLEN MODAL
	'ENDPOINTS.CREATE_REPORT': 'Bericht erstellen',
	'ENDPOINTS.CREATE_CUSTOM_REPORT': 'Benutzerdefinierten Bericht erstellen',
	'ENDPOINTS.ENDPOINTS_ARE': 'Endpunkte sind für den Export ausgewählt',
	'ENDPOINTS.ENDPOINT_IS': 'Endpunkt ist für den Export ausgewählt.',
	'ENDPOINTS.CUSTOM_REPORT_WILL_BE_AVAILABLE': 'Dieser benutzerdefinierte Bericht wird im Berichteabschnitt zur Verfügung stehen.',
	'ENDPOINTS.YOU_CAN_APPEND_CUSTOM_NAME': 'Sie können hier einen benutzerdefinierten Namen an den Bericht anhängen',
	'ENDPOINTS.CUSTOM_REPORT_NAME_APPENDIX': 'Benutzerdefinierter Berichtsnamenanhang',
	'ENDPOINTS.ADD_CUSTOM_REPORT_NAME_APPENDIX': 'Benutzerdefinierten Berichtsnamenanhang hinzufügen (nur Ziffern und Buchstaben zulässig)',

	//  MULTI-AKTION MODAL
	'ENDPOINTS.SELECT_SIM_STATE_STEP_FIRST': '1. SIM-Status auswählen',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_SECOND': '2. Änderungen bestätigen',
	'ENDPOINTS.SELECT_SIM_STATE_STEP_CONFIRMATION_STEP': '3. Systembestätigung',
	'ENDPOINTS.SELECT_RATE_PLAN_STEP_FIRST': '1. Tarifplan auswählen',
	'ENDPOINTS.SELECT_NETWORK_ENTITLEMENT_STEP_FIRST': '1. Netzwerkanspruch auswählen',
	'ENDPOINTS.CHANGE_NOTES_STEP_FIRST': '1. Anmerkungen ändern',
	'ENDPOINTS.CONFIRM_STEP_SECOND': '2. Systembestätigung',
	'ENDPOINTS.CHANGE_SIM_STATE': 'SIM-Status ändern',
	'ENDPOINTS.CHANGE_RATE_PLAN': 'Tarifplan ändern',
	'ENDPOINTS.CHANGE_NETWORK_ENTITLEMENT': 'Netzwerkanspruch ändern',
	'ENDPOINTS.CHANGE_NOTES': 'Anmerkungen',

	//  MULTI-AKTIONSANMERKUNGEN MODAL
	'ENDPOINTS.SAVE_NOTE': 'Anmerkung speichern',
	'ENDPOINTS.ADD_NOTE_BELOW': 'Anmerkung nachstehend hinzufügen: ',
	'ENDPOINTS.ENTER_NOTE_HERE': 'Anmerkung hier eingeben (max. 2000 Zeichen)',
	'ENDPOINTS.MAX_CHARACTERS_EXCEEDED': 'Zeichenlimit überschritten',
	'ENDPOINTS.NO_ONLY_WHITE_SPACE': 'Es können nicht nur Whitespaces eingegeben werden',
	'ENDPOINTS.CHANGING_NOTES': 'Anmerkungen werden geändert',
	'ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED': 'Endpunkte erfolgreich geändert',
	'ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR': 'Änderung von Anmerkungen für mehrere Endpunkte konnte nicht erfolgreich eingeleitet werden',
	'ENDPOINTS.SUCCESS_KEY': 'Erfolg',
	'ENDPOINTS.ERROR': 'Fehler',
	'ENDPOINTS.REASON': 'Grund',
	'ENDPOINTS.ENDPOINTS_SELECTED': 'Endpunkte ausgewählt',
	'ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED': 'Änderung von Anmerkungen nicht zulässig',

	//  TARIFPLAN ÄNDERN
	'ENDPOINTS.PLEASE_SELECT_COUPLE_OF_SIMS': 'Bitte wählen Sie einige SIMS aus.',
	'ENDPOINTS.CHANGE_RATE_PLAN_NOT_ALLOWED': 'Tarifplanänderung nicht zulässig',
	'ENDPOINTS.NO_RATE_PLANS_AVAILABLE': 'Keine Tarifpläne verfügbar',
	'ENDPOINTS.SIMS_HAVE': 'SIMs haben',
	'ENDPOINTS.CHANGE_FOR_ALL_SELECTED_SIMS': 'Anderen Tarifplan auswählen, um Änderung auf alle ausgewählten SIMs anzuwenden',
	'ENDPOINTS.NO_RATE_PLAN_SELECTED': 'kein Tarifplan ausgewählt',
	'ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE': 'Tarifplanänderung bestätigen',
	'ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE': 'Sie ändern den Tarifplan der',
	'ENDPOINTS.SELECTED_SIMS_TO': 'ausgewählten SIMs auf',
	'ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW': 'Änderung jetzt einleiten?',
	'ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR': 'Sie können die Änderung auch planen für: ',
	'ENDPOINTS.SCHEDULE_RATE_PLAN_SUCCESSFUL': 'Tarifplan für mehrere SIMs erfolgreich eingeplant',
	'ENDPOINTS.SIM_RATE_PLAN_CHANGE_FOR': 'SIM-Tarifplanänderung für',
	'ENDPOINTS.SIMS_IS_SUCCESSFULLY_SCHEDULED_FOR': 'SIMS erfolgreich eingeplant für ',
	'ENDPOINTS.SIMS_RATE_PLAN_SUCCESSFULLY_REQUESTED': 'SIM-Tarifpläne erfolgreich aktualisiert',
	'ENDPOINTS.SIMS_SELECTED': 'SIMs ausgewählt',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_SCHEDULE_ERROR': 'Tarifplanänderung mehrerer SIMs konnte nicht erfolgreich eingeplant werden',
	'ENDPOINTS.MULTIPLE_RATE_PLAN_CHANGE_ERROR': 'Tarifplanänderung mehrerer SIMs konnte nicht erfolgreich angefordert werden',
	'ENDPOINTS.SAME_RATE_PLAN_SELECTED': 'Gleicher Tarifplan ausgewählt',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_SUCCESS': 'Endunkt-ID {endpointID} erfolgreich bereitgestellt. Tarifplan wurde geändert zu {ratePlan}',
	'ENDPOINTS.ENDPOINT_RATE_PLAN_CHANGED_ERROR': 'Endpunkt-ID {endpointID} Konnte nach Tarifplanänderung nicht bereitgestellt werden.  Support erhalten',

	//  SIM-STATUS ÄNDERN
	'ENDPOINTS.CURRENT': 'AKTUELL',
	'ENDPOINTS.NETWORK_TYPE_CHECK': 'Leider ist die Statusänderung mehrerer Endpunkte gleichzeitig für Endpunkte von verschiedenen Netzwerktypen oder Dienstanbietern nicht gestattet.',
	'ENDPOINTS.SELECT_SIM_STATE': 'SIM-Status auswählen',
	'ENDPOINTS.CURRENT_STATES': 'Aktuelle Status',
	'ENDPOINTS.SELECTED_TIP': 'Tipp: Falls Sie SIMs in verschiedenen Zuständen ausgewählt haben, können Sie nur auf einen Zustand ändern, der für alle SIMs verfügbar ist.',
	'ENDPOINTS.VIEW_RULES': 'Statusänderungsregeln für dieses Konto anzeigen',
	'ENDPOINTS.STATES_MESSAGE': 'Kein Status verfügbar.',
	'ENDPOINTS.CONFIRM_SIM_STATE_CHANGE': 'SIM-Statusänderung bestätigen',
	'ENDPOINTS.ONCE_YOU_INITIATE_CHANGE': 'Wenn Sie diese Änderung einleiten, lautet die ausgewählte SIMS',
	'ENDPOINTS.PROCEED_ACTION': 'Mit dieser Aktion fortfahren?',
	'ENDPOINTS.CAN_SCHEDULE': 'Sie können die Änderung auch planen für',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_SUCCESS': 'Endunkt-ID {endpointID} erfolgreich bereitgestellt. IMSI-Status geändert zu {status}',
	'ENDPOINTS.ENDPOINT_STATUS_CHANGED_ERROR': 'Endpunkt-ID {endpointID} Bereitstellung nach IMSI-Statusänderung fehlgeschlagen.  Support erhalten',
	'ENDPOINTS.CHANGE_SIM_STATE_USER_NOTE': 'Benutzer {user} hat am {date} eine Statusänderung vorgenommen',
	'ENDPOINTS.SCHEDULE_SUCCESSFUL': 'Mehrfach-SIM-Statusänderung erfolgreich eingeplant!',
	'ENDPOINTS.SIM_STATE_CHANGE_FOR': 'SIM-Statusänderung für',
	'ENDPOINTS.SIMS_SUCCESSFULLY_REQUESTED': 'SIM-Statusänderung erfolgreich angefordert!',

	//  BERICHT ERSTELLEN
	'ENDPOINTS.CUSTOM_REPORT_CREATED': 'Benutzerdefinierter Bericht erfolgreich erstellt',
	'ENDPOINTS.DOWNLOAD_REPORT_ERROR_MESSAGE': 'Fehler beim Versuch, Ihre Datei herunterzuladen',
	'ENDPOINTS.GO_TO_REPORTS': 'Zu Berichten wechseln',

	//  BENUTZERDEFINIERTE BEZEICHNUNGEN AKTUALISIEREN
	'ENDPOINTS.CUSTOM_FIELD_LABEL_SUCCESS_MESSAGE': 'Ein oder mehrere benutzerdefinierte Feldbezeichnungen wurden erfolgreich aktualisiert',
	'ENDPOINTS.CUSTOM_FIELD_LABEL_ERROR_MESSAGE': 'Ein oder mehrere benutzerdefinierte Feldbezeichnungen konnten derzeit nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',

	//  Aktivierungsplattform
	'ENDPOINTS.GMNA': 'GMNA',
	'ENDPOINTS.POD3': 'POD3',
	'ENDPOINTS.POD19': 'POD19',
	'ENDPOINTS.DCP': 'DCP',
	'ENDPOINTS.ACC': 'ACC',
	'ENDPOINTS.CCIP': 'CCIP',
	'ENDPOINTS.VIVO': 'VIVO',
	'ENDPOINTS.SXMACC': 'SXMACC',
	'ENDPOINTS.EOD': 'EOD',
	'ENDPOINTS.WING': 'WING',
	'ENDPOINTS.GBCM': 'GBCM',
	'ENDPOINTS.GDSP': 'GDSP',

	TECH_TYPE: 'Technischer Typ',
	TECH_TYPE_SUB_TYPE: 'Tech Type Unterkategorie',
	'ENDPOINTS.TECH_TYPE': 'Technischer Typ',
	'ENDPOINTS.TECH_TYPE_SUB_TYPE': 'Tech Type Unterkategorie',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Technischer Typ',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Tech Type Unterkategorie'

};
