import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../NotificationsList';

import styles from './Desktop.scss';

class Desktop extends PureComponent {
	render() {
		const {
			notifications,
			getOptions,
			messages,
			isAll,
			isGetAllNotificationRequestFailed,
			getAll,
			getAllNotifications
		} = this.props;

		return (
			<div data-spec="notifications-desktop">
				{!isAll || isGetAllNotificationRequestFailed ? (
					<>
						<Table
							data-spec="notifications-table-desktop"
							data={notifications.resultList.filter(
								(item) => item.notificationType.isVisible === true
							)}
							options={getOptions('desktop')}
						/>
						<div className={styles.view_all}>
							<span
								onClick={() => {
									getAll();
									getAllNotifications();
								}}
								data-spec="view-all-notifications"
							>
								<FormattedMessage
									id="SUPPORT.VIEW_ALL_NOTIFICATIONS"
									defaultMessage="View All Notifications"
								/>
							</span>
						</div>
					</>
				) : (
					<Table
						data-spec="notifications-table-desktop"
						data={notifications.resultList.filter(
							(item) => item.notificationType.isVisible === true
						)}
						options={getOptions('desktop')}
						messages={messages}
					/>
				)}
			</div>
		);
	}
}

const { shape, func, bool, objectOf, string, array } = PropTypes;

Desktop.propTypes = {
	notifications: shape({
		resultList: array
	}),
	getOptions: func,
	messages: objectOf(string),
	isAll: bool,
	getAllNotifications: func,
	isGetAllNotificationRequestFailed: bool,
	getAll: func
};

export default hoc()(Desktop);
