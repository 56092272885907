export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': 'Eliminare regola automazione?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': 'Eliminare regola automazione selezionata?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': 'Confermare l’eliminazione della regola automazione selezionata?',
	'AUTOMATION.CANCEL': 'Annulla',
	'AUTOMATION.DELETE': 'Elimina',
	'AUTOMATION.ARE_YOU_SURE': 'Confermare l’operazione?',
	'AUTOMATION.ACTIVE': 'Attivato',
	'AUTOMATION.ACC_IF': 'Se',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Aggiungere un’altra soglia',
	'AUTOMATION.EDIT_RULE': 'Modifica regola',
	'AUTOMATION.CREATE_NEW_RULE': 'Crea nuova regola',
	'AUTOMATION.PLATFORM': 'Piattaforma',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Account',
	'AUTOMATION.CATEGORY': 'Categoria',
	'AUTOMATION.RULE_STATE': 'Stato regola',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Attiva',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inattiva',
	'AUTOMATION.TRIGGER': 'Attivazione',
	'AUTOMATION.ACC_RULE_NAME': 'Nome regola',
	'AUTOMATION.SAVE': 'Salva',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Cancella filtri selezionati',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Seleziona filtri',
	'AUTOMATION.AUTOMATION': 'Automazione',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Crea nuova regola automazione',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': 'Confermare l’eliminazione della regola automazione {ruleName}?',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Regola creata',
	'AUTOMATION.ADD_RULE_ERROR': 'Creazione regola automazione non riuscita, riprovare.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Regola modificata',
	'AUTOMATION.EDIT_RULE_ERROR': 'Modifica regola automazione non riuscita, riprovare.',
	'AUTOMATION.THEN': 'Allora',
	'AUTOMATION.NUMBER_OF_DAYS': 'Numero di giorni',
	'AUTOMATION.NUMBER_OF_HOURS': 'Numero di ore (max 24h)',
	'AUTOMATION.AT': 'Alle',
	'AUTOMATION.SIM_STATE': 'Stato SIM',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'ID piano tariffario',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'ID sottoconto M2M',
	'AUTOMATION.CUSTOMER_ID': 'ID cliente',
	'AUTOMATION.CUSTOM': 'Personalizzato',
	'AUTOMATION.FILTER': 'Filtro',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'Nessun filtro disponibile',
	'AUTOMATION.THRESHOLD': 'Soglia',
	'AUTOMATION.ZONE_MODEL': 'Modello zona',
	'AUTOMATION.ZONE_NAME': 'Nome zona',
	'AUTOMATION.POOL_ID': 'ID pool',
	'AUTOMATION.COUNTRY': 'Paese',
	'AUTOMATION.FROM_KEY': 'Da',
	'AUTOMATION.TO_KEY': 'A',
	'AUTOMATION.FOLLOW_UP_WITH': 'Dare seguito a',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': 'Eliminazione regola automazione riuscita',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': 'Eliminazione avvisi automazione selezionati riuscita',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': 'Eliminazione regola automazione non riuscita, riprovare.',
	'AUTOMATION.PLATFORMS': 'Piattaforme',
	'AUTOMATION.CATEGORIES': 'Categorie',
	'AUTOMATION.TRIGGERS': 'Attivazioni',
	'AUTOMATION.M2MACCOUNTS': 'Account M2M',
	'AUTOMATION.HOURS_USING': 'Utilizzo ultime 24 ore',
	'AUTOMATION.LOCATIONUPDATE': 'Aggiornamento ubicazione',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Aggiornamento autenticazione',
	'AUTOMATION.ZONE': 'Zona',
	'AUTOMATION.ACCOUNT_ID': 'ID account',
	'AUTOMATION.ACTION': 'Azione',
	'AUTOMATION.DCP': 'DCP',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'SMS',
	'AUTOMATION.USAGE': 'Utilizzo',
	'AUTOMATION.SIM_PROVISIONING': 'Provisioning SIM',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Monitoraggio abbonamento',
	'AUTOMATION.USAGE_MONITORING': 'Monitoraggio utilizzo',
	'AUTOMATION.NETWORK_MONITORING': 'Monitoraggio rete',
	'AUTOMATION.PRICING_AUTOMATION': 'Automazione prezzi',
	'AUTOMATION.SECURITY_MONITORING': 'Monitoraggio sicurezza',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'Cambiamento piano tariffario SIM',
	'AUTOMATION.SIM_STATE_CHANGE': 'Cambiamento stato SIM',
	'AUTOMATION.SMS_USAGE': 'Utilizzo SMS',
	'AUTOMATION.SIM_DATA_LIMIT': 'Limite dati SIM',
	'AUTOMATION.SIM_EXPIRATION': 'Scadenza SIM',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Utilizzo dati dal ciclo fino alla data',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Utilizzo vocale dal ciclo fino alla data',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': 'Utilizzo dati superato nelle ultime 24 ore',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': 'Connessione numero sessioni ultime 24 ore',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Utilizzo mensile dati in pool',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Utilizzo mensile SMS in pool',
	'AUTOMATION.NO_CONNECTION': 'Nessuna connessione',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Utilizzo vocale ultime 24 ore',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'Registrazione in una zona',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Troppe connessioni',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'Sovraccarico SMS MO per periodo',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'Sottocarico SMS MO per periodo',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'Sovraccarico SMS MT per periodo',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'Sottocarico SMS MT per periodo',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'Sovraccarico a lungo termine GPRS',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'Sovraccarico GPRS a breve termine',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'Prima sessione dati stabilita',
	'AUTOMATION.IMEI_CHANGED': 'IMEI cambiato',
	'AUTOMATION.NETWORK_REGISTRATION': 'Registrazione rete',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Registrazione rete in una zona',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'Cambiamento campo personalizzato SIM',
	'AUTOMATION.SIM_STATUS': 'Stato SIM',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Piano tariffario cambiato',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'Paese SIM cambiato',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'Scadenza piano tariffario SIM',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Sessione dati interrotta',
	'AUTOMATION.DATA_SESSION_STARTED': 'Sessione dati avviata',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Utilizzo dati in un paese',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': 'Conteggio sessioni dati - Ciclo di fatturazione',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Utilizzo dati in una zona',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'Ciclo di fatturazione senza alcuna connessione',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': 'Connessioni numero di sessioni nelle ultime 24 ore',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'Conteggio SMS in un paese',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'Utilizzo SMS in una zona',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'Utilizzo SMS nelle ultime 24 ore',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Utilizzo totale dati - 24 ore',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': 'Utilizzo totale dati - Ciclo di fatturazione',
	'AUTOMATION.SMS_MO_RECEIVED': 'SMS MO ricevuti',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': 'Utilizzo vocale dal ciclo fino alla data in una zona',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'Completamento piano SIM vocale',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'Completamento piano SIM dati',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'Azione elenco IMEI consentiti',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'Cambiamento account SIM',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Utilizzo SMS recente (ultime 24 ore)',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': 'Registrazione rete in una zona',
	'AUTOMATION.MSISDN_CHANGE': 'Cambiamento MSISDN',
	'AUTOMATION.IMEI_CHANGE': 'Cambiamento IMEI',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': 'Utilizzo dati dal ciclo fino alla data in una zona',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': 'Un endpoint viene trasferito da',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': 'Qualsiasi endpoint nel ciclo corrente utilizza',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Qualsiasi endpoint in un piano tariffario prepagato completa il periodo prepagato',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': 'La rete rileva un cambiamento nell’IMEI dell’endpoint',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': 'Il sistema rileva un cambiamento all’MSISDN per un endpoint',
	'AUTOMATION.INITIATE_OTA_SWAP': 'Avvia scambio OTA',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': 'Qualsiasi endpoint invia o riceve più di',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': 'Qualsiasi SIM subisce una modifica in uno dei campi personalizzati',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': 'IMEI dell’endpoint non trovato nell’elenco IMEI consentiti',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': 'Qualsiasi endpoint nel ciclo corrente supera',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': 'Un SMS MO viene ricevuto per un codice breve',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'Conteggio totale SMS - Ciclo di fatturazione',
	'AUTOMATION.1_HOUR': '1 ora',
	'AUTOMATION.2_HOURS': '2 ore',
	'AUTOMATION.3_HOURS': '3 ore',
	'AUTOMATION.4_HOURS': '4 ore',
	'AUTOMATION.5_HOURS': '5 ore',
	'AUTOMATION.6_HOURS': '6 ore',
	'AUTOMATION.7_HOURS': '7 ore',
	'AUTOMATION.8_HOURS': '8 ore',
	'AUTOMATION.9_HOURS': '9 ore',
	'AUTOMATION.10_HOURS': '10 ore',
	'AUTOMATION.11_HOURS': '11 ore',
	'AUTOMATION.12_HOURS': '12 ore',
	'AUTOMATION.13_HOURS': '13 ore',
	'AUTOMATION.14_HOURS': '14 ore',
	'AUTOMATION.15_HOURS': '15 ore',
	'AUTOMATION.16_HOURS': '16 ore',
	'AUTOMATION.17_HOURS': '17 ore',
	'AUTOMATION.18_HOURS': '18 ore',
	'AUTOMATION.19_HOURS': '19 ore',
	'AUTOMATION.20_HOURS': '20 ore',
	'AUTOMATION.21_HOURS': '21 ore',
	'AUTOMATION.22_HOURS': '22 ore',
	'AUTOMATION.23_HOURS': '23 ore',
	'AUTOMATION.24_HOURS': '24 ore',
	'AUTOMATION.25_HOURS': '25 ore',
	'AUTOMATION.26_HOURS': '26 ore',
	'AUTOMATION.27_HOURS': '27 ore',
	'AUTOMATION.28_HOURS': '28 ore',
	'AUTOMATION.29_HOURS': '29 ore',
	'AUTOMATION.30_HOURS': '30 ore',
	'AUTOMATION.31_HOURS': '31 ore',
	'AUTOMATION.32_HOURS': '32 ore',
	'AUTOMATION.33_HOURS': '33 ore',
	'AUTOMATION.34_HOURS': '34 ore',
	'AUTOMATION.35_HOURS': '35 ore',
	'AUTOMATION.36_HOURS': '36 ore',
	'AUTOMATION.37_HOURS': '37 ore',
	'AUTOMATION.38_HOURS': '38 ore',
	'AUTOMATION.39_HOURS': '39 ore',
	'AUTOMATION.40_HOURS': '40 ore',
	'AUTOMATION.41_HOURS': '41 ore',
	'AUTOMATION.42_HOURS': '42 ore',
	'AUTOMATION.43_HOURS': '43 ore',
	'AUTOMATION.44_HOURS': '44 ore',
	'AUTOMATION.45_HOURS': '45 ore',
	'AUTOMATION.46_HOURS': '46 ore',
	'AUTOMATION.47_HOURS': '47 ore',
	'AUTOMATION.48_HOURS': '48 ore',
	'AUTOMATION.LAST_24_HOURS': 'Ultime 24 ore',
	'AUTOMATION.CYCLE_TO_DATE': 'Per il ciclo fino alla data',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'Il piano tariffario prepagato della SIM supera',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'L’utilizzo dati supera il limite',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'L’utilizzo dati è vicino al limite',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'Il piano tariffario prepagato SIM è entro',
	'AUTOMATION.MO_MT': 'MO/MT',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'L’utilizzo vocale supera il limite',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'L’utilizzo vocale è vicino al limite',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'La SIM ha stabilito oltre',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'La SIM ha stabilito meno',
	'AUTOMATION.EXCEEDES': 'Superato',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': 'La SIM non ha stabilito alcuna connessione dati nelle ultime N ore',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'La SIM si registra alla rete nella zona',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'La SIM ha stabilito più che connessioni nel ciclo corrente',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': 'La rete rileva un nuovo dispositivo',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'L’utilizzo per il ciclo fino alla data supera',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'L’utilizzo per il ciclo fino alla data è vicino a',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': 'La rete ha rilevato un cambiamento in ...',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'La SIM si è registrata per la prima volta in una nuova rete',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'Endpoint si registra nella zona',
	'AUTOMATION.CUSTOM_FIELD_1': 'Campo personalizzato 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Campo personalizzato 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Campo personalizzato 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Campo personalizzato 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Campo personalizzato 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Campo personalizzato 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Campo personalizzato 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Campo personalizzato 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Campo personalizzato 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Campo personalizzato 10',
	'AUTOMATION.CHANGED': 'Cambiato',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Cambiamento piano tariffario',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': 'Il numero di sessioni dati per una SIM supera',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'La sessione dati per una SIM si avvia',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'La sessione dati per una SIM si ferma',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': 'L’utilizzo dati in un paese supera',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'Utilizzo dati in un supera',
	'AUTOMATION.EXCEEDS': 'Superato',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': 'Utilizzo SMS fatturabile in qualsiasi piano tariffario in pool raggiunge',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': 'Qualsiasi SIM non ha stabilito alcuna connessione dati per il ciclo di fatturazione trascorso',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'Una SIM ha oltre',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'Il conteggio SMS in un paese supera',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'Conteggio SMS oltre al limite',
	'AUTOMATION.1_WEEK': '1 settimana',
	'AUTOMATION.2_WEEKS': '2 settimane',
	'AUTOMATION.3_WEEKS': '3 settimane',
	'AUTOMATION.4_WEEKS': '4 settimane',
	'AUTOMATION.SEND_EMAIL': 'Invia e-mail',
	'AUTOMATION.SEND_SMS': 'Invia SMS',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': 'Cambia diritti di rete del dispositivo',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': 'Cambia stato SIM del dispositivo',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': 'Cambia piano tariffario del dispositivo',
	'AUTOMATION.PUSH_API': 'API push',
	'AUTOMATION.COMBINED': 'Combinato',
	'AUTOMATION.MO': 'MO',
	'AUTOMATION.MT': 'MT',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Connessioni in 24 ore',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Cambia diritti di rete',
	'AUTOMATION.CHANGE_SIM_STATUS': 'Cambia stato SIM',
	'AUTOMATION.SMS_MESSAGES': 'Messaggi SMS',
	'AUTOMATION.HOURS': 'Ore',
	'AUTOMATION.MIN': 'Min',
	'AUTOMATION.MINUTES': 'Minuti',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Cambia piano tariffario',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': 'Connessioni nel ciclo corrente',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Ritorna a piano tariffario precedente',
	'AUTOMATION.MODIFIED_DELETED': 'Modificato/eliminato',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': 'Il piano tariffario SIM scadrà tra un giorno',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': 'Importo utilizzo SMS incluso in pool',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': 'Numero sessioni dati nelle ultime 24 ore',
	'AUTOMATION.MSGS': 'MSG',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'Il conteggio SMS nella zona supera',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': 'Numero di sessioni dati nelle ultime 24 ore',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Ore di consumo',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Ritorna a stato SIM precedente',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Giorni dopo l’evento di attivazione',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'Fine del ciclo di fatturazione corrente',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': 'L’ora UTC dopo l’evento di attivazione',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': 'Ritorna ai diritti di rete precedenti',
	'AUTOMATION.GMNA': 'GM America Settentrionale',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Enterprise',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM America Settentrionale',
	'AUTOMATION.ENTERPRISE': 'Enterprise',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'Cambia stato SIM',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'Utilizzo SMS ultime 24 ore',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': 'L’utilizzo SMS nelle ultime 24 ore supera',
	// Triggers
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': 'Utilizzo SMS MO (originati da mobile) - 24 ore',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': 'Utilizzo SMS MO (originati da mobile) - in una zona',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': 'Utilizzo SMS MO (originati da mobile) - piano in pool',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': 'Utilizzo SMS MT (terminati in mobile) - 24 ore',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': 'Utilizzo SMS MT (terminati in mobile) - ciclo di fatturazione',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': 'Utilizzo SMS MT (terminati in mobile) - in una zona',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': 'Utilizzo SMS MT (terminato in mobile) - piano in pool',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'Il giorno successivo alla scadenza del piano tariffario SIM',
	'AUTOMATION.BYTES': 'Byte',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Aggiungi testo personalizzato al messaggio SMS (opzionale)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': 'Testo messaggio personalizzato (massimo 140 caratteri)',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'Completamento piano SIM',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Se una SIM in un piano tariffario prepagato completa il periodo prepagato',
	// new automation
	'AUTOMATION.TITLE': 'Automazione',
	'AUTOMATION.RULES_TAB_NAME': 'Regole automazione',
	'AUTOMATION.ALERTS_TAB_NAME': 'Avvisi automazione',
	'AUTOMATION_RULES.YES': 'Sì',
	'AUTOMATION_RULES.NO': 'No',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'Nessuna regola automazione disponibile',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': 'Non ci sono attualmente regole automazione da mostrare',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'Nessun avviso automazione disponibile',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': 'Non ci sono attualmente avvisi automazione da mostrare',
	//  rules table headers
	'AUTOMATION_RULES.ID': 'ID',
	'AUTOMATION_RULES.RULE_NAME': 'Nome regola',
	'AUTOMATION_RULES.PLATFORM': 'Piattaforma',
	'AUTOMATION_RULES.CATEGORY': 'Categoria',
	'AUTOMATION_RULES.TRIGGER': 'Attivazione',
	'AUTOMATION_RULES.ACTIVE': 'Attivato',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'ID account M2M',
	'AUTOMATION_RULES.DATE_CREATED': 'Data creazione',
	'AUTOMATION_RULES.USER': 'Utente',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'Stato implementazione',
	// alerts table headers
	'AUTOMATION_ALERTS.ID': 'ID',
	'AUTOMATION_ALERTS.RULE_NAME': 'Nome regola',
	'AUTOMATION_ALERTS.PLATFORM': 'Piattaforma',
	'AUTOMATION_ALERTS.CATEGORY': 'Categoria',
	'AUTOMATION_ALERTS.TRIGGER': 'Attivazione',
	'AUTOMATION_ALERTS.ACTIVE': 'Attivato',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'ID account M2M',
	'AUTOMATION_ALERTS.DATE': 'Data',
	'AUTOMATION_ALERTS.USER': 'Utente',
	'AUTOMATION_ALERTS.ACTION': 'Azione',
	// rules
	'AUTOMATION.ACTIVATE': 'Attiva',
	'AUTOMATION.DEACTIVATE': 'Disattiva',
	'AUTOMATION.RULE_CREATED_OR_EDITED': 'È stata selezionata una regola automazione che richiede elaborazione prolungata e che non verrà impostata immediatamente.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': 'Attivazione regola automazione riuscita',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'Attivazione regola automazione non riuscita',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': 'Disattivazione regola automazione riuscita',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': 'Disattivazione regola automazione non riuscita',
	'AUTOMATION.SHORT_CODE': 'Codice breve',
	'AUTOMATION.BASE_RATE_PLAN': 'Piano tariffario base',
	'AUTOMATION.RATE_PLAN_TYPE': 'Tipo piano tariffario',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'Stato implementazione',
	'AUTOMATION.YES': 'Sì',
	'AUTOMATION.NO': 'No',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Cambiamento di stato regola',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': 'Confermare l’operazione?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': 'Si sta per cambiare lo stato {currentState} di una regola in “{selectedState}”',
	'AUTOMATION.REQUEST_DELETION_TEXT': 'Si sta per cambiare richiedere l’eliminazione di una regola',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Eliminazione regola',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': 'Si sta per eliminare la regola {ruleName}',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Richiesta eliminazione regola automazione',
	'AUTOMATION.REQUEST_DELETION': 'Richiedi eliminazione',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': 'Cambiamento stato implementazione in “Ricevuto” riuscito',
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': 'Cambiamento stato implementazione in “Implementato” riuscito',
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': 'Cambiamento stato implementazione in “In conflitto” riuscito',
	'AUTOMATION.SET_RULE_STATE_FAIL': 'Si è verificato un errore durante il cambiamento dello stato implementazione. Riprovare.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': 'Richiesta eliminazione regola riuscita.',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': 'Si è verificato un errore durante la richiesta di eliminazione di una regola.',
	'AUTOMATION.RULE_CHANGE': 'Cambiamento regola automazione',
	'AUTOMATION.RULE_CREATE': 'Creazione regola automazione',
	'AUTOMATION.ACCEPT': 'Accetta',
	'AUTOMATION.MANUAL_RULE_DELAY': 'Ci saranno ritardi nell’implementazione di questa regola.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': 'Per eseguire questa regola ci vorrà un certo tempo.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': 'Sono consapevole del ritardo nel processo di implementazione.',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Seleziona azione:',
	// statuses
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'In attesa di eliminazione',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Implementato',
	'AUTOMATION.RULE_STATUS_PENDING': 'In sospeso',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'In conflitto',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Ricevuto',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'Non applicabile',
	//  Zone Model
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'ATT zona standard 1',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'ATT zona standard 2',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'ATT zona standard 3',
	//  Zone name
	'AUTOMATION.US_DOMESTIC': 'Interna USA',
	'AUTOMATION.EU': 'UE'
};
