import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import Support from './Support';

const mapDispatchToProps = (dispatch) => ({
	goToFaq: () => dispatch(localizedRoute('/support/faq')),
	goToUserGuide: () => dispatch(localizedRoute('/support/user-guide')),
	goToTicketing: () => dispatch(localizedRoute('/support/ticketing')),
	goToReleaseNotes: (url) => dispatch(localizedRoute(url))
});

export default connect(
	null,
	mapDispatchToProps
)(Support);
