export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': 'Definierte Diagrammeinstellung löschen?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': 'Einstellung {settingName} wirklich löschen?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Aktuell verfügbare Diagramme',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': 'Dieses Diagramm ist in Ihren Einstellungen bereits vorhanden. Es ist nur ein Diagrammtyp zulässig.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'Keine Beschreibung für dieses Diagramm',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Diagrammtyp',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Diagrammdaten exportieren',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Diagramm entfernen',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Diagrammeinstellungen',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Diagramm hinzufügen',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'Neues Diagramm mit Erfolg hinzugefügt',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Neues Diagramm nicht hinzugefügt',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'Diagramm mit Erfolg aktualisiert',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Neues Diagramm nicht aktualisiert',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Diagrammeinstellung mit Erfolg gelöscht',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Diagrammeinstellung nicht gelöscht',
	'ACCOUNT_OVERVIEW.CHART_SETTING_NAME': 'Name der Diagrammeinstellung',
	'ACCOUNT_OVERVIEW.CHART_DESCRIPTION': 'Diagrammbeschreibung',
	'ACCOUNT_OVERVIEW.ACTIONS': 'Aktionen',
	'ACCOUNT_OVERVIEW.ADD_GRAPH': 'Neue Grafikeinstellung hinzufügen',
};
