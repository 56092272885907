import { Status } from '../../../../utils/constants';

// GET
export const getBlacklistedPermissionsRequest = (state) =>
	state.onboarding.blacklistedPermissions.getBlacklistedPermissions.status ===
	Status.PENDING;
export const getBlacklistedPermissionsFail = (state) =>
	state.onboarding.blacklistedPermissions.getBlacklistedPermissions.status ===
	Status.FAIL;
export const getBlacklistedPermissionsSuccess = (state) =>
	state.onboarding.blacklistedPermissions.getBlacklistedPermissions.data;

// EDIT
export const editBlacklistedPermissionRequest = (state) =>
	state.onboarding.blacklistedPermissions.editBlacklistedPermission.status ===
	Status.PENDING;
export const editBlacklistedPermissionFail = (state) =>
	state.onboarding.blacklistedPermissions.editBlacklistedPermission.status ===
	Status.FAIL;
export const editBlacklistedPermissionSuccess = (state) =>
	state.onboarding.blacklistedPermissions.editBlacklistedPermission.status ===
	Status.DONE;

// REQUEST MODAL
export const isRequestFeatureModalOpened = (state) =>
	state.onboarding.blacklistedPermissions.requestFeatureModal.isOpen;
export const requestFeatureData = (state) =>
	state.onboarding.blacklistedPermissions.requestFeatureModal.data;

// CANCEL MODAL
export const isCancelRequestFeatureModalOpened = (state) =>
	state.onboarding.blacklistedPermissions.cancelRequestFeatureModal.isOpen;

// REQUEST FEATURE
export const requestFeatureRequest = (state) =>
	state.onboarding.blacklistedPermissions.requestFeature.status ===
	Status.PENDING;
export const requestFeatureFail = (state) =>
	state.onboarding.blacklistedPermissions.requestFeature.status === Status.FAIL;
export const requestFeatureSuccess = (state) =>
	state.onboarding.blacklistedPermissions.requestFeature.status === Status.DONE;

// CANCEL REQUEST
export const cancelRequestedFeatureRequest = (state) =>
	state.onboarding.blacklistedPermissions.cancelRequestedFeature.status ===
	Status.PENDING;
export const cancelRequestedFeatureFail = (state) =>
	state.onboarding.blacklistedPermissions.cancelRequestedFeature.status ===
	Status.FAIL;
export const cancelRequestedFeatureSuccess = (state) =>
	state.onboarding.blacklistedPermissions.cancelRequestedFeature.status ===
	Status.DONE;

export const isCancelRequestedFeatureModalOpened = (state) =>
	state.onboarding.blacklistedPermissions.cancelRequestedFeatureModal.isOpen;
export const cancelRequestedFeatureData = (state) =>
	state.onboarding.blacklistedPermissions.cancelRequestedFeatureModal.data;

export const isRequestedFeatureModalOpened = (state) =>
	state.onboarding.blacklistedPermissions.requestedFeatureModal.isOpen;
export const requestedFeatureData = (state) =>
	state.onboarding.blacklistedPermissions.requestedFeatureModal.data;

export const approveRequestedFeatureRequest = (state) =>
	state.onboarding.blacklistedPermissions.approveRequestedFeature.status ===
	Status.PENDING;
export const approveRequestedFeatureFail = (state) =>
	state.onboarding.blacklistedPermissions.approveRequestedFeature.status ===
	Status.FAIL;
export const approveRequestedFeatureSuccess = (state) =>
	state.onboarding.blacklistedPermissions.approveRequestedFeature.data;

export const rejectRequestedFeatureRequest = (state) =>
	state.onboarding.blacklistedPermissions.rejectRequestedFeature.status ===
	Status.PENDING;
export const rejectRequestedFeatureFail = (state) =>
	state.onboarding.blacklistedPermissions.rejectRequestedFeature.status ===
	Status.FAIL;
export const rejectRequestedFeatureSuccess = (state) =>
	state.onboarding.blacklistedPermissions.rejectRequestedFeature.data;
