export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Endpoint {iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Edit Endpoint',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Network Entitlement',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'Endpoint ID {args1} failed to provision after IMSI status change. Get support',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE': '{step}. Select SIM State',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. Schedule SIM State Change',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. System Confirmation',
	'SINGLE_ENDPOINT.ICCID': 'ICCID',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': 'MSISDN',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'Status',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Refresh endpoint to check if the state has changed',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'M2M Platform',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'M2M Account ID',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Network Type',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Network Operator',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'Network Country',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'M2M Date Added',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': 'M2M Activation Date',
	'SINGLE_ENDPOINT.IN_SESSION': 'In Session',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Rate Plan',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Error on rate plan, please contact Customer Support.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Error on network entitlement, please contact Customer Support.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'IMSI Transfer Status',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'Roaming Country',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Account Name',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': 'Network Entitlement Name',
	'SINGLE_ENDPOINT.ROAMING': 'Roaming',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Create Date',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Last Update Date',
	'SINGLE_ENDPOINT.SMS_MO': 'SMS MO',
	'SINGLE_ENDPOINT.SMS_MT': 'SMS MT',
	'SINGLE_ENDPOINT.VOICE_MO': 'Voice MO',
	'SINGLE_ENDPOINT.VOICE_MT': 'Voice MT',
	'SINGLE_ENDPOINT.LTE_DATA': 'LTE Data',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'Roaming ID',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Camel Service Type',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Block Premium Numbers',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': 'Block International SMS MO',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'HLR Template',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'International Voice',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Hotline Number',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Description',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'Fixed IP',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'Not Applicable For This Endpoint',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Rate Plan Name',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Rate Plan Type',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Subscription Price',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Account Charge',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Payment Type',
	'SINGLE_ENDPOINT.TERM': 'Term',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Usage Limit',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Tiering Flag',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID: {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD': 'Elevated permissions required!',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Only administrators can modify custom field types as they have a system-wide configuration impact. Contact your administrator to update custom fields options.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID': 'Endpoint Actions for {endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. Select SIM State',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'Endpoint ID',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'There is a Scheduled Activity for this Endpoint.',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'Are you sure you want to cancel pending change?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT': 'This action cannot be undone.',
	'SINGLE_ENDPOINT.CARRIER': 'Carrier',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Recredentialed',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Note',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'User {uid} made change status on {startDate}',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'SIM state change is successfully scheduled for ',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'Current Sim State For Endpoint ID {endpointId} is ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS':
		'Eligible Network Entitlements',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'Fixed IP',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Currently assigned to this endpoint',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS': 'Network Entitlement Details',
	SELECT_MINIMUM_OF_2: 'Select minimum of 2 to apply to the table. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Start Diagnostics',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Carrier Information',
	'SINGLE_ENDPOINT.COUNTRY': 'Country',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'M2M Platform Status Description',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'Active Live',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Inactive New',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Active Sleep',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Inactive Stopped',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I':
		'Migrated from other GDSP Environment',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'Not provisioned',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O': 'Migrated to other GDSP Environment',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Active Ready',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Active Suspend',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Active Test',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'Active Standby',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Inactive Transformed',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Suspend Regulatory',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Terminated',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'Not Available',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Endpoint Provisioning Information',
	'SINGLE_ENDPOINT.PROVISIONED': 'Provisioned',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME': 'Provisioned Date & Time {date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Network Connections',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Voice Enabled',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Data Enabled',
	'SINGLE_ENDPOINT.SESSIONS': 'Sessions',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Inventory',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Activated',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Activated',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inactive',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inactive',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Deactivated',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': 'Retired',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Purged',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Test Ready',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Activation Ready',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Replaced',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Trial',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Start',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Transfer',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Global In Transit Source',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Global In Transit Destination',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Global Transferred',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Other',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Passing Traffic Permitted',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Passing Traffic Not Permitted',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Allowed TollSec Wireless Resources',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Barred From Network',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Traffic Not Allowed SIM State',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Suspended by Service Provider',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Suspended Due to Usage Limit',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Traffic Not Allowed (other)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Successfully Connected to Network (In Session)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'Not Connected to Network (In Session)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Valid Registration (Valid Network Activity)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Valid Registration (No Network Activity)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Valid GSM Registration Only',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'No Valid Registration (Has Activity in Last 24 Hours)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'No Valid Registration (Has Auth Request in Last 24 Hours)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'No Valid Registration (Has Local Updates More Than 24 Hours Ago)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'No Valid Registration (Has Auth Request More Than 24 Hours Ago)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'More Than 24 Hours Ago (No Network Activity)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'Device Not Connected Currently. Prior IP Sessions Successful.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Data Session Currently Not Successful',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Data Session Past Successful',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Connection Attempt Failed (Invalid Credentials)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'No Data Session Successful History',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Allowed TollSec Wireless Resources',
	'SINGLE_ENDPOINT.ACTIONS': 'Actions',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'Get HLR/HSS Details',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Recurring Fee',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Select Rate Plan',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Confirm Changes',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. System Confirmation',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Eligible to use with this endpoint',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		'Rate plan change is successfully updated',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Rate plan change is successfully scheduled for ',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Rate plan change is successfully scheduled',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Confirm Network Entitlement Change',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Once you initiate this change, the selected endpoint’s Network Entitlement will be ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION':
		' Would you like to proceed with this action?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Or you can schedule the change for: ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Network Entitlement change is successfully scheduled.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Network Entitlement change is successfully scheduled for ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'Network Entitlement change could not be carried out at this time.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'Network Entitlement change could not be scheduled at this time.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'Network Entitlement Change Status',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'Current Rate Plan for Endpoint ID {id} is ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Roaming Carrier',
	'SINGLE_ENDPOINT.OEM': 'Sub Accounts',
	'SINGLE_ENDPOINT.SERVICE_STATUS': '360L Status',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE: ',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Network Connection',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN: ',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'Registration Status',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Linked 360L Accounts',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Dual Mode',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Connected Device',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Rate Plan',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'Recorded Status',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Activation Date',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': 'Export Usage Data',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE': 'Exporting Data Failed',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'Cannot export usage data at the moment.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Please wait while we prepare the file for you. Depending on the number of records, this might take some time to complete.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'There is no data to show for the selected period',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'If avaliable, try changing the date range to refresh the list',
	'SINGLE_ENDPOINT.MANUFACTURER': 'Manufacturer',
	'SINGLE_ENDPOINT.MODEL_NUMBER': 'Model Number',
	'SINGLE_ENDPOINT.SUPPORTED_NETWORKS': 'Supported Networks',
	'SINGLE_ENDPOINT.SPECIFICATION': 'Specification',
	'SINGLE_ENDPOINT.PERMANENT_CUSTOM_FIELD_DELETION':
		'Permanent custom field deletion!',
	'SINGLE_ENDPOINT.DELETING_THIS_CUSTOM_FIELD':
		'Deleting this custom field will delete its all related data! This action cannot be undone!',
	'SINGLE_ENDPOINT.TECH_TYPE': 'Tech Type',
	'SINGLE_ENDPOINT.TECH_TYPE_SUB_TYPE': 'Tech Type Subcategory',
	'SINGLE_ENDPOINT.ROAMING_ID': 'Roaming ID',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Activated',
	'SINGLE_ENDPOINT.TERMINATE': 'Terminated',
	'SINGLE_ENDPOINT.DEACTIVE': 'Deactive',
	'SINGLE_ENDPOINT.INACTIVE': 'Inactive',
	'SINGLE_ENDPOINT.TEST': 'Test',
	'SINGLE_ENDPOINT.SUSPENDED': 'Suspended',
	'SINGLE_ENDPOINT.SUSPEND': 'Suspend',
	'SINGLE_ENDPOINT.INVENTORY': 'Inventory'
};
