import { connect } from 'react-redux';
import FunctionalitiesDesktop from './Desktop';

import {
	setSelectedItems,
	setSelectedItemsInit
} from '../../redux/selectedItems/actions';

import { selectedItems } from '../../redux/selectedItems/selectors';

import {
	anyModalOpened,
	anyModalClosed
} from '../../../Shared/redux/modals/actions';

import { exportToCsv } from '../../redux/exportToCsv/actions';

const mapStateToProps = (state) => ({
	selectedItems: selectedItems(state)
});

const mapDispatchToProps = (dispatch) => ({
	setSelectedItems: (data) => dispatch(setSelectedItems(data)),
	setSelectedItemsInit: () => dispatch(setSelectedItemsInit()),
	openBatchUploadModal: () => {
		dispatch(anyModalOpened());
	},
	closeBatchUploadModal: () => {
		dispatch(anyModalClosed());
  },
  exportToCsv: (params) => dispatch(exportToCsv(params)),
});

// eslint-disable-next-line import/prefer-default-export
export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(FunctionalitiesDesktop);
