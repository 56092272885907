import globalAction, {
	actionInit
} from '../../../../../redux/global/globalAction';
import { GET_TICKETING_SEVERITIES_AND_STATUSES } from './constants';
import { getSeveritiesAndStatuses as getSeveritiesAndStatusesService } from '../../../services/TicketingService';

export const getSeveritiesAndStatuses = () =>
	globalAction(
		GET_TICKETING_SEVERITIES_AND_STATUSES,
		getSeveritiesAndStatusesService
	);

export const getSeveritiesAndStatusesInit = () =>
	actionInit(GET_TICKETING_SEVERITIES_AND_STATUSES);
