import React from 'react';
import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery
} from '../../../../utils/CustomMediaQuery';
import Desktop from '../../components/CustomFieldLabels/Desktop';
import Tablet from '../../components/CustomFieldLabels/Tablet';
import Mobile from '../../components/CustomFieldLabels/Mobile';

const CustomFieldLabelsView = (props) => (
	<>
		<DesktopMediaQuery>
			<Desktop {...props} />
		</DesktopMediaQuery>
		<TabletMediaQuery>
			<Tablet {...props} />
		</TabletMediaQuery>
		<MobileMediaQuery>
			<Mobile {...props} />
		</MobileMediaQuery>
	</>
);

export default CustomFieldLabelsView;
