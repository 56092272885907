export const UPDATE_BULK_SINGLE_ENDPOINT_STATUS_REQUEST =
	'UPDATE_BULK_SINGLE_ENDPOINT_STATUS_REQUEST';
export const UPDATE_BULK_SINGLE_ENDPOINT_STATUS_SUCCESS =
	'UPDATE_BULK_SINGLE_ENDPOINT_STATUS_SUCCESS';
export const UPDATE_BULK_SINGLE_ENDPOINT_STATUS_FAIL =
	'UPDATE_BULK_SINGLE_ENDPOINT_STATUS_FAIL';
export const UPDATE_BULK_SINGLE_ENDPOINT_STATUS_STARTED =
	'UPDATE_BULK_SINGLE_ENDPOINT_STATUS_STARTED';
export const UPDATE_BULK_SINGLE_ENDPOINT_STATUS_ENDED =
	'UPDATE_BULK_SINGLE_ENDPOINT_STATUS_ENDED';
