import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import ReactTooltip from 'react-tooltip';

import Button from '../../../../../lib/DigitalComponents/Button';
import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import {
	updateBulkNotes as updateBulkNotesAction,
	updateBulkNotesStatusStarted as updateBulkNotesStatusStartedAction
} from '../../../redux/updateBulkNotes/actions';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import { getErrors } from '../../../../Shared/redux/getStatusCode/selectors';
import {
	getCurrentEndpoint,
	isBulkNotesUpdating,
	bulkNotesUpdateFail,
	bulkNotesUpdateSuccess,
	getFailEndpoints,
	getSuccessEndpoints,
	getIsStarted,
	getFilteredEndpoints
} from '../../../redux/updateBulkNotes/selectors';

import styles from './Steps.scss';

const Error = ImageSelector(CurrentContext.theme, 'svgs/info-full.svg');
const Success = ImageSelector(CurrentContext.theme, 'svgs/ok-full.svg');
const ErrorNotification = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-error.svg'
);
const Approval = ImageSelector(
	CurrentContext.theme,
	'svgs/controls-approval.svg'
);

class StepSecond extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentEndpoint: '',
			failEndpoints: [],
			successEndpoints: [],
			errors: null
		};
	}

	componentDidMount() {
		const {
			updateBulkNotesStatusStarted,
			updateBulkNotes,
			values,
			context,
			isStarted,
			onClose
		} = this.props;

		updateBulkNotesStatusStarted();
		updateBulkNotes({
			selectedEndpoints: values.notes.originalSelectedEndpoints,
			seNotes: values.notes.seNotes
		});

		if (context) {
			this.setFooter(context, isStarted, onClose);
		}
	}

	static getDerivedStateFromProps(nextProps) {
		const stateObject = {
			currentEndpoint: nextProps.currentEndpoint,
			failEndpoints: nextProps.failEndpoints || [],
			successEndpoints: nextProps.successEndpoints || []
		};

		if (nextProps.errors.length) {
			stateObject.errors = nextProps.errors;
		}

		return stateObject;
	}

	componentDidUpdate(prevProps) {
		const { context, isStarted, onClose } = this.props;

		if (context && !isEqual(isStarted, prevProps.isStarted)) {
			this.setFooter(context, isStarted, onClose);
		}
	}

	componentWillUnmount() {
		this.setState({
			currentEndpoint: '',
			failEndpoints: [],
			successEndpoints: []
		});
	}

	setFooter = (context, isStarted, onClose) => {
		const footer = (
			<>
				<Button
					variant="success"
					onClick={onClose}
					disabled={isStarted}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
					dataSpec="done"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderNotification = () => {
		const { values } = this.props;
		const { successEndpoints } = this.state;

		if (successEndpoints && successEndpoints.length) {
			return (
				<div data-spec="notification" className={styles.notification}>
					<div className={styles.image}>
						<Approval />
					</div>
					<div className={styles.text}>
						<FormattedMessage
							id="ENDPOINTS.CHANGING_NOTES"
							defaultMessage="Changing Notes"
						/>
						{', '}
						{successEndpoints.length}
						<FormattedMessage id="OF" defaultMessage=" of" />
						{`${values.notes.originalSelectedEndpoints.length} `}
						<FormattedMessage
							id="ENDPOINTS.ENDPOINTS_SUCCESSFULLY_CHANGED"
							defaultMessage="Endpoints successfully changed"
						/>
					</div>
				</div>
			);
		}

		return (
			<div
				data-spec="notification"
				className={cn(styles.notification, styles.error)}
			>
				<div className={styles.image}>
					<ErrorNotification />
				</div>
				<div className={styles.schedule}>
					<div className={styles.text}>
						<FormattedMessage
							id="ENDPOINTS.MULTIPLE_NOTES_CHANGE_ERROR"
							defaultMessage="Changing Notes for multiple Endpoints could not be initiated successfully"
						/>
					</div>
					<div className={styles.schedule_text}>
						<FormattedMessage
							id="ENDPOINTS.PLEASE_TRY_AGAIN"
							defaultMessage="Please try again."
						/>
					</div>
				</div>
			</div>
		);
	};

	renderEndpoints = () => {
    const { values } = this.props;
    return (
			<ul data-spec="endpoints" className={styles.endpoints_result}>
				{values.notes.originalSelectedEndpoints.map((item, index) =>
          this.renderEndpointData(item.endpointName, index))}
			</ul>
		);
  }

	getErrorMessage = () => {
		const { errors } = this.state;
		let errorMessage = '';

		if (errors) {
			errors.forEach((error) => { errorMessage += `${error.message} `; });
		}

		return errorMessage;
	};

	renderEndpointData = (endpoint, index) => {
		const { isUpdating, filteredEndpoints } = this.props;
		const { currentEndpoint, successEndpoints, failEndpoints } = this.state;

		if (endpoint === currentEndpoint && isUpdating) {
			return (
				<li data-spec="endpoint" key={index}>
					<div className={styles.endpoint}>{endpoint}</div>
					<div className={styles.loader}>
						<Loader size={15} data-spec="loader" />
					</div>
				</li>
			);
		}

		if (failEndpoints.indexOf(endpoint) !== -1) {
			return (
				<li data-spec="endpoint" key={index}>
					<div className={styles.endpoint}>{endpoint}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<span
							className={styles.data}
							data-spec={`tooltip-${endpoint}`}
							data-tip={this.getErrorMessage()}
							data-for={endpoint}
						>
							<FormattedMessage id="ENDPOINTS.ERROR" defaultMessage="Error" />
						</span>
						<ReactTooltip
							type={'error'}
							id={endpoint}
							className={styles.tooltip}
						/>
					</div>
				</li>
			);
		}

		if (successEndpoints.indexOf(endpoint) !== -1) {
			return (
				<li data-spec="endpoint" key={index} className={styles.success}>
					<div className={styles.endpoint}>{endpoint}</div>
					<span>
						<Success />
					</span>
					<span>
						<FormattedMessage
							id="ENDPOINTS.SUCCESS_KEY"
							defaultMessage="Success"
						/>
					</span>
				</li>
			);
		}

		if (filteredEndpoints.indexOf(endpoint) !== -1) {
			return (
				<li data-spec="endpoint" key={index}>
					<div className={styles.endpoint}>{endpoint}</div>
					<span>
						<Error />
					</span>
					<div className={styles.notification_wrap}>
						<FormattedMessage
							id="ENDPOINTS.CHANGING_NOTES_NOT_ALLOWED"
							defaultMessage="Changing Notes are not allowed"
						>
							{(formattedValue) => (
								<span
									className={styles.data}
									data-spec={`tooltip-${endpoint}`}
									data-tip={formattedValue}
									data-for={endpoint}
								>
									<FormattedMessage
										id="ENDPOINTS.ERROR"
										defaultMessage="Error"
									/>
								</span>
							)}
						</FormattedMessage>
						<ReactTooltip
							type={'error'}
							id={endpoint}
							className={styles.tooltip}
						/>
					</div>
				</li>
			);
		}

		return (
			<li data-spec="endpoint" key={index} className={styles.start}>
				<div className={styles.endpoint}>{endpoint}</div>
			</li>
		);
	};

	render() {
		const { values, isStarted } = this.props;

		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body" className="first-step-modal-body">
					<div className={styles.result_wrap}>
						<div className="confirm-content">
							<div className={`${styles.step_first} ${styles.step_third}`}>
								<h2 className={styles.touppercase}>
									<FormattedMessage
										id="ENDPOINTS.CHANGING_NOTES"
										defaultMessage="Changing Notes"
									/>
								</h2>
								<p>
									{`${values.notes.originalSelectedEndpoints.length} `}
									<FormattedMessage
										id="ENDPOINTS.ENDPOINTS_SELECTED"
										defaultMessage="Endpoints Selected"
									/>
								</p>
							</div>
						</div>
						{!isStarted && this.renderNotification()}
						{this.renderEndpoints()}
					</div>
				</div>
			</div>
		);
	}
}

const { object, func, string, bool, array, any } = PropTypes;

StepSecond.propTypes = {
	values: object,
	updateBulkNotes: func,
	currentEndpoint: string,
	isUpdating: bool,
	failEndpoints: array,
	successEndpoints: array,
	onClose: func,
	updateBulkNotesStatusStarted: func,
	isStarted: bool,
	errors: any,
	filteredEndpoints: array,
	context: object,
	seNotes: string
};

const mapStateToProps = (state) => ({
		currentEndpoint: getCurrentEndpoint(state),
		isUpdating: isBulkNotesUpdating(state),
		isFail: bulkNotesUpdateFail(state),
		isSucces: bulkNotesUpdateSuccess(state),
		failEndpoints: getFailEndpoints(state),
		successEndpoints: getSuccessEndpoints(state),
		isStarted: getIsStarted(state),
		errors: getErrors(state),
		filteredEndpoints: getFilteredEndpoints(state)
	});

const mapDispatchToProps = (dispatch) => ({
	updateBulkNotes: (data) => dispatch(updateBulkNotesAction(data)),
	updateBulkNotesStatusStarted: () =>
		dispatch(updateBulkNotesStatusStartedAction())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepSecond);
