import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, debounce, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';

const DownCarretIcon = ImageSelector(CurrentContext.theme, 'svgs/down.svg');
const SearchIcon = ImageSelector(CurrentContext.theme, 'svgs/search.svg');

class SearchBar extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			menuOpened: false,
			value: '',
			menuSearchParameter: null
		};
	}

	componentDidMount() {
		const { menu, setSearchParameterInit } = this.props;
		const showingMenu = menu.filter((item) => !item.hide);
		showingMenu.length > 0 && this.setMenuSearchParameter(menu[0]);
		setSearchParameterInit();
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentDidUpdate(prevProps) {
		const { searchParameter, menu } = this.props;
		const { value } = this.state;
		if (
			!isEmpty(prevProps.searchParameter) &&
			isEmpty(searchParameter) &&
			value !== ''
		) {
			this.setSearchValueInit();
		}
		if (!this.areMenusEqual(menu, prevProps.menu)) {
			const showingMenu = menu.filter((item) => !item.hide);
			showingMenu.length > 0 && this.setMenuSearchParameter(menu[0]);
		}
	}

	componentWillUnmount() {
		const { setSearchParameterInit, setSearchParameterPropInit } = this.props;
    setSearchParameterInit();
    setSearchParameterPropInit();
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	areMenusEqual = (menu1, menu2) => {
		if (menu1.length !== menu2.length) return false;
		const menu1Values = [];
		const menu2Values = [];
		menu1.forEach((element, index) => {
			menu1Values.push(element.value);
			menu2Values.push(menu2[index].value);
		});
		return isEqual(menu1Values, menu2Values);
	};

	handleClickOutside = (event) => {
		const parentelement = event.target.parentElement
			? 'parentElement'
			: 'parentNode';
		const domNode = this.menu;
		if (
			(!domNode || !domNode.contains(event.target)) &&
			event.target.id != 'search-menu' &&
			event.target.id != 'search-menu-item' &&
			event.target.id != 'carrot-icon' &&
			event.target[parentelement] &&
			event.target[parentelement][parentelement] &&
			event.target[parentelement][parentelement].id != 'search-menu'
		) {
			this.closeMenu();
		}
	};

	setSearchValueInit = () => {
		this.setState({
			value: ''
		});
	};

	setMenuSearchParameter = (menuSearchParameter) => {
		const { setSearchParameter, setSearchParameterProp } = this.props;
		const { value } = this.state;
		if (value !== '') {
			setSearchParameter({
				prop: menuSearchParameter.value,
				propValue: value
			});
		}
		this.setState({
			menuSearchParameter
    });
    setSearchParameterProp(menuSearchParameter.value);
	};

	onChange = (e) => {
    const { setSearchParameterProp } = this.props;

		this.setState({
			value: e.target.value
		});

		e.persist();
		if (!this.debouncedFn) {
			this.debouncedFn = debounce(() => {
				const { setSearchParameter, setSearchParameterInit } = this.props;
				const { menuSearchParameter } = this.state;
				if (e.target.value === '') {
					setSearchParameterInit();
				} else {
					setSearchParameter({
						prop: menuSearchParameter.value,
						propValue: e.target.value
          });
          setSearchParameterProp(menuSearchParameter.value);
				}
			}, 200);
		}
		this.debouncedFn();
	};

	handleOnKeyUp = (e) => {
		if (e.keyCode === 13) {
			e.persist();
			if (!this.debouncedFnEnter) {
				this.debouncedFnEnter = debounce(this.onSubmit, 200);
			}
			this.debouncedFnEnter();
		}
	};

	onSubmit = async () => {
		const { setPaginationInit, onSearchSubmitCallback } = this.props;
		await setPaginationInit();
		onSearchSubmitCallback();
	};

	toggleMenu = () => {
		const { menuOpened } = this.state;
		this.setState({
			menuOpened: !menuOpened
		});
	};

	closeMenu = () => {
		this.setState({
			menuOpened: false
		});
	};

	renderMenu = () => {
		const { menu, styles } = this.props;
		const { searchParameter } = this.state;
		return (
			<ul
				ref={(menuOptions) => {
					this.menu = menuOptions;
				}}
				className={styles.menu}
				data-spec="menu"
				id="menu"
			>
				{menu &&
					menu
						.filter((item) => !item.hide)
						.map((item) => (
							<li
								role="menuitem"
								data-spec={`search-bar-item-${
									item && item.value && item.value
								}`}
								className={
									item.label === (searchParameter && searchParameter.label)
										? styles.selected
										: ''
								}
								key={item.value}
								onClick={() => this.setMenuSearchParameter(item)}
							>
								{item.label}
							</li>
						))}
			</ul>
		);
	};

	renderMenuSearchParameter = () => {
    const { menuSearchParameter } = this.state;
		if (menuSearchParameter) {
			return (
				<div data-spec="search-menu-item" id="search-menu-item">
					{menuSearchParameter.label}
				</div>
			);
		}
	};

	render() {
    const { styles, showInputValueInTooltip } = this.props;
		const { value, menuOpened, menuSearchParameter } = this.state;
		return (
			<div data-spec="search-bar" className={styles.right}>
				<div
					id="search-menu"
					onClick={this.toggleMenu}
					className={cn(styles.search_left, menuOpened ? styles.opened : '')}
				>
					{this.renderMenuSearchParameter()}
					<DownCarretIcon className={styles.down_carret} id="carrot-icon" />
					{menuOpened && this.renderMenu()}
				</div>
				<div
					className={styles.search_right}
					data-for="quick-search"
					data-tip={value}
				>
					<FormattedMessage id="QUICK_SEARCH" defaultMessage="Quick search">
						{(formattedValue) => (
							<input
								type="text"
								maxLength={100}
								onKeyUp={this.handleOnKeyUp}
								onChange={this.onChange}
								value={value || ''}
								placeholder={
									(menuSearchParameter && menuSearchParameter.placeholder) ||
									formattedValue
								}
								data-spec="quick-search"
							/>
						)}
					</FormattedMessage>

					<div onClick={this.onSubmit} className={styles.search_icon}>
						<SearchIcon />
					</div>
				</div>
				{showInputValueInTooltip && value.length > 10 && (
					<ReactTooltip
						className={styles.tooltip}
						type="light"
						id="quick-search"
					/>
				)}
			</div>
		);
	}
}

const { func, array, object, bool } = PropTypes;

SearchBar.propTypes = {
	onSearchSubmitCallback: func,
	setSearchParameter: func,
	setSearchParameterInit: func,
	setSearchParameterProp: func,
	setSearchParameterPropInit: func,
	menu: array,
	setPaginationInit: func,
	searchParameter: object,
	styles: object,
	showInputValueInTooltip: bool
};

export default SearchBar;
