import { Status } from '../../../../utils/constants';

/* ******************** AUTOMATION ZONES MODAL ******************** */
export const isAutomationZonesModalOpen = (state) =>
	state.automationZones.zones.automationZonesModal.isOpen;

export const patchFileUploadRequest = (state) =>
	state.automationZones.zones.patchFileUpload.status === Status.PENDING;

export const patchFileUploadFail = (state) =>
	state.automationZones.zones.patchFileUpload.status === Status.FAIL;

export const patchFileUploadSuccess = (state) =>
	state.automationZones.zones.patchFileUpload.data;

/* ******************** GET AUTOMATION ZONES ******************** */
export const getAutomationZonesRequest = (state) =>
	state.automationZones.zones.getAutomationZones.status === Status.PENDING;
export const getAutomationZonesFail = (state) =>
	state.automationZones.zones.getAutomationZones.status === Status.FAIL;
export const getAutomationZonesSuccess = (state) =>
	state.automationZones.zones.getAutomationZones.data;

/* ******************** GET UPLOAD TEMPLATES ******************** */
export const downloadTemplateRequest = (state) =>
	state.automationZones.zones.getUploadTemplate.status === Status.PENDING;
export const downloadTemplateFail = (state) =>
	state.automationZones.zones.getUploadTemplate.status === Status.FAIL;
export const downloadTemplateSuccess = (state) =>
	state.automationZones.zones.getUploadTemplate.data;
