import qs from 'qs';
import * as ApiService from '../../../services/ApiService';
import ApiClient from '../../../utils/ApiClient';

export const getEndpoints = async (params) => {
	const response = await ApiService.post('/endpoints', params);

	return {
		data: {
			resultList: response.data.simDevices.SIMs,
			totalCount: response.data.simDevices.totalCount
		}
	};
};

export const getCustomLabels = () => ApiService.get('/customers/customLabels');

export const getAvailableStates = () => ApiService.get('endpoints/availableStates');

export const get = (iccid, type) =>
	Promise.all([
		ApiService.get(`/endpoints/${iccid}?type=${type}`),
		ApiService.get(
			`/batch/transaction-status?${qs.stringify({
				searchParams: [
					{ propValue: 'endpoint', prop: 'type', operator: '=' },
					{ propValue: iccid, prop: 'referenceId', operator: '=' }
				]
			})}`
		)
	])
		.then((response) => ({
			Endpoint: response[0].data || [],
			CustomLabels: response[1].data || [],
			transactionStatus: response[2].data || null
		}))
		.catch((error) => {
			throw { error: error.response.data };
		});

export const getEntitlement = async () => {
	const calls = [
		ApiService.get('/customers/networkEntitlement'),
		ApiService.get('/customers/customLabels')
	];

	const response = await Promise.all(calls);
	const data = {
		Entitlement: response[0].data,
		CustomLabels: response[1].data
	};

	return { data };
};

export const patchEndpoint = (props) =>
	ApiService.patch(`/endpoints/${props.id}/targetState`, props.data);

export const patchEntitlement = (props) =>
	ApiService.patch(`/endpoints/${props.id}/networkEntitlement`, props.data);

// export const postEndpointsAdvancedSearch = (data) => WE ARE NO USING THIS !!!
// 	ApiClient.post('/endpoints', data)
// 		.then((response) => ({ Endpoints: response.data.simDevices.SIMs }))
// 		.catch((error) => {
// 			throw { error: error.response.data };
// 		});

export const patchEndpoints = (data) =>
	ApiService.patch('/endpoints/updateMultipleTargetState', data);

export const patchEndpointCustomFields = (id, customFieldsValues) =>
	/*	TODO: Uncomment once custom Labels are required
return Promise.all([ApiClient.patch(`/endpoints/${id}/customFields`, {...customFields}),
		ApiClient.patch(`/customers/customLabels`, requiredColumns)]).then((response) => { */
	ApiClient.patch(`/endpoints/${id}/customFields`, {
		...customFieldsValues
	})
		.then((response) =>
			// return {CustomFields: response[0].data,RequiredColumns:response[1].data};
			({ CustomFields: response.data }))
		.catch((error) => {
			throw { error: error.response.data };
		});

export const patchBulkEndpointCustomFields = (data) =>
	ApiService.patch('/endpoints/changeMultipleEndpointsCustomFields', data);

export const getAllReports = () =>
	ApiService.get('/reports/allReports?fileType=report');

export const downloadReportRequestURL = (data) =>
	ApiService.post('/batch/downloadFile', data);

export const checkReportDownloadStatus = (data) =>
	ApiService.get(`/batch/downloadStatus?${qs.stringify(data)}`);

/* -------------------- START NETWORK ENTITLEMENT -------------------- */

export const getNetworkEntitlement = (networkEntitlementId) =>
	ApiService.get(
		`/customers/endpoint-network-entitlements/${networkEntitlementId}`
	);

export const changeNetworkEntitlement = (
	endpointId,
	entitlementId,
	startDate
) =>
	ApiService.patch(`/endpoints/${endpointId}/networkEntitlement`, {
		networkEntitlementId: entitlementId,
		startDate
	});

export const getNetworkEntitlementApn = (m2mAccountId, networkEntitlementId) =>
	ApiService.get(
		`/customers/networkEntitlement/${m2mAccountId}/apn/${networkEntitlementId}`
	);

/* -------------------- END NETWORK ENTITLEMENT -------------------- */

export const updateCustomLabels = (customLabels) =>
	ApiService.patch('/customers/customLabels', { ...customLabels });

/* -------------------- DOWNLOAD ENDPOINTS START -------------------- */
/* export const downloadEndpoints = (
		paginationData, searchData = [], Accept = "application/vnd.ms-excel") => {
  let url = '/endpoints/advancedSearchExport';
  let data = {
    exportFormat: "xlsx",
    additionalParams: {
      dataOffset: paginationData.dataOffset,
      dataLimit: paginationData.dataLimit,
      columnsToSelect: paginationData.columnsToSelect
    }
  };
  if (searchData.length) {
    data.searchParams = searchData;
  }
  if (paginationData.dataSort) {
    data.additionalParams.dataSort = paginationData.dataSort;
  }
  const options = {
    responseType:'blob',
		headers: {
      Accept
		}
	};
	return ApiClient.post(url, data, options).then((response) => {
		return response.data
	}).catch((error) => {
		throw {error: error.response.data}
	})
} */
/* -------------------- DOWNLOAD ENDPOINTS END -------------------- */

/* -------------------- GENERATE REPORT START -------------------- */
export const generateReportData = ({ paginationData, searchData }) => {
	const url = '/endpoints';
	const data = {
		additionalParams: {
			dataOffset: paginationData.dataOffset,
			dataLimit: paginationData.dataLimit,
			columnsToSelect: paginationData.columnsToSelect,
			generateReport: true,
			reportPrefix: paginationData.reportPrefix
		}
	};
	if (searchData && searchData.length) {
		data.searchParams = searchData.filter(
			(m) => m.propValue && m.prop && m.operator
		);
	}
	if (paginationData.dataSort) {
		data.additionalParams.dataSort = paginationData.dataSort;
	}
	return ApiService.post(url, data);
};
/* -------------------- DOWNLOAD ENDPOINTS END -------------------- */

/* -------------------- CHANGE RATE PLAN START -------------------- */

// get rate plans
export const getRatePlans = (m2mAccountId = '') => {
	const url = m2mAccountId
		? `/endpoints/platfrom/${m2mAccountId}/rate-plan`
		: '/endpoints/ratePlan';
	return ApiService.get(url);
};

// update rate plan
export const postRatePlan = (props) =>
	ApiService.patch(`/endpoints/${props.id}/ratePlan`, props.data);
/* -------------------- CHANGE RATE PLAN END -------------------- */

/* -------------------- CANCEL PENDING START -------------------- */
export const postCancelPending = (props) =>
	ApiService.post('/batch/cancelTransaction', props);
/* -------------------- CANCEL PENDING END -------------------- */

/* -------------------- FETCH M2M ACCOUNT START -------------------- */
export const getm2mAccount = () => ApiService.get('/customers/m2mAccount');
/* -------------------- FETCH M2M ACCOUNT END -------------------- */

/* -------------------- UPDATE M2M ACCOUNT START -------------------- */
export const postm2mAccount = (props) =>
	ApiService.patch(`/endpoints/${props.targetICCID}/swapProfile`, props);
/* -------------------- UPDATE M2M ACCOUNT END -------------------- */

/* -------------------- GET ENDPOINTS FILTERS DATA START -------------------- */
export const getEndpointsFilters = (data) => ApiClient.get(
		`endpoints/filters?${qs.stringify({
			searchParams: [{ propValue: data, prop: 'category', operator: 'in' }]
		})}`
	)
		.then((response) => {
			let temp = {};
			response.data.resultList.forEach((item) => {
				temp = {
					...temp,
					[`${Object.keys(item)}`]: Object.values(item)[0]
				};
			});
			return { data: temp };
		})
		.catch((error) => {
			throw { error: error.response.data };
		});

/* ------------------------- GET NOTE START -------------------------------- */
export const getNote = (params) =>
	ApiService.get(`/endpoints/${params.deviceId}/platform/${params.m2mAccountId}/se-note`);
/* ------------------------- GET NOTE END ---------------------------------- */

/* ------------------------- UPDATE NOTE START ---------------------------------- */
export const updateNote = (endpointName, m2mAccountId, data, flag) =>
	ApiClient[flag](
		`/endpoints/${endpointName}/platform/${m2mAccountId}/se-note`,
		flag === 'delete' ? { data } : data
	);
/* ------------------------- UPDATE NOTE END ---------------------------------- */
