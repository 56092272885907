import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import NoData from '../NoChartData';
import { renderValue } from '../Helpers/helpers';

import styles from './MUSTotal.scss';

const DataUsage = ImageSelector(CurrentContext.theme, 'svgs/endpoint.svg');

const MUSTotal = ({ chartData, unit }) => {
	if (!chartData || !chartData.length) {
		return (
			<div data-spec="no-data" className={styles.chart_wrapper}>
				<NoData />
			</div>
		);
	}

	let tempChartData = chartData.map((item) => item);

	if ('value_2' in chartData) {
		tempChartData = chartData.map((data) => ({
			...data,
			value: data.value !== null
				? parseFloat(data.value_2) / parseFloat(data.value)
				: 0
		}));
	}

	const total = tempChartData.reduce(
		(totalValue, current) =>
			totalValue + parseFloat(current.value === null ? 0 : current.value),
		0
	);

	return (
		<div data-spec="total-chart" className={styles.chart_wrapper}>
			<main className={styles.main}>
				<DataUsage className={styles.data_usage_icon} />
				<div className={styles.content}>
					<div>
						<FormattedMessage
							id={`CONSUMED_${unit}`}
							defaultMessage={`Consumed ${unit}`}
						/>
					</div>
					<div className={styles.content__total}>
						{renderValue(unit, total)}
					</div>
				</div>
			</main>
		</div>
	);
};

const {
	arrayOf,
	string,
	object,
} = PropTypes;

MUSTotal.propTypes = {
	chartData: arrayOf(object),
	unit: string,
};

MUSTotal.defaultProps = { chartData: [], unit: '' };

export default injectIntl(MUSTotal);
