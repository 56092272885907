export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_FIRST_SUBCATEGORIES = 'GET_FIRST_SUBCATEGORIES';
export const GET_SECOND_SUBCATEGORIES = 'GET_SECOND_SUBCATEGORIES';
export const GET_THIRD_SUBCATEGORIES = 'GET_THIRD_SUBCATEGORIES';
export const GET_SEVERITIES = 'GET_SEVERITIES';
export const GET_PRIORITIES = 'GET_PRIORITIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CONFIRMATION = 'GET_CONFIRMATION';
export const GET_HISTORY = 'GET_HISTORY';
