export default {
	'USER_FEEDBACK.TRIGGER_TITLE': 'Got feedback?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Tell us here',
	'USER_FEEDBACK.ATTACHMENTS': 'Attachments',
	'USER_FEEDBACK.HELP_US':
		'Help us maximize your experience by sharing your suggestions.',
	'USER_FEEDBACK.PAGE_LABEL': 'Your suggestion is related to',
	'USER_FEEDBACK.FEED_LABEL': 'Tell us about your suggestions',
	'USER_FEEDBACK.FEED_PLACEHOLDER':
		"Example: I'd like to read helpful information about a feature when I hover it with my mouse.",
	'USER_FEEDBACK.CONTACT_LABEL': 'If needed, can we contact you about this?',
	'USER_FEEDBACK.EMAIL_LABEL': 'Email',
	'USER_FEEDBACK.PHONE_LABEL': 'Phone',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Send Feedback',
	'USER_FEEDBACK.ICON': 'Success Feedback Submit Icon',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Thank you for the feedback.',
	'USER_FEEDBACK.SUCCESS_MESSAGE':
		'Your feedback will make our product better. We will carefully evaluate your comments and act upon it.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB':
		'We may also contact you for more information regarding your suggestion.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'IoT Console Team',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Done',
	'USER_FEEDBACK.TITLE': 'Have Suggestions?',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Fail Upload Failed',
	'USER_FEEDBACK.LINK_TO_TICKETING': 'Have an issue? To report go to',
	'USER_FEEDBACK.TICKETING': 'Ticketing',
	'USER_FEEDBACK.OPTIONAL': '(Optional)'
};
