import React from 'react';
import PropTypes from 'prop-types';
import Stickyfill from 'stickyfilljs';
import { FormattedMessage, injectIntl } from 'react-intl';

import Select from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import Loader from '../../../../../lib/DigitalComponents/Loader';

import styles from './Mobile.scss';

class Mobile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null
		};
	}

	componentDidMount() {
		this.showNotification();
	}

	componentDidUpdate(prevProps) {
		const { faqAvailable } = this.props;
		if (!prevProps.faqAvailable && faqAvailable) {
			Stickyfill.add(this.faqNavRef);
		}
	}

	showNotification = () => {
		const { intl, showNotification } = this.props;
		const message = intl.formatMessage({
			id: 'SUPPORT.TRANSLATION_NOT_AVAILABLE',
			defaultMessage:
				'Document below is shown in U.S. English. Translation into other supported languages will be available in upcoming product releases.'
		});
		showNotification(message);
	};

	getNodes = (str) =>
		new DOMParser().parseFromString(str, 'text/html').body.childNodes;

	render() {
		const { faqAvailable, contentId, fileMarkdown } = this.props;
		const { selected } = this.state;
		return (
			<div data-spec="faq-desktop" className={styles.wrapper}>
				{fileMarkdown && (
					<div
						className={styles.faq_menu}
						ref={(menu) => {
							this.faqNavRef = menu;
						}}
					>
						{faqAvailable &&
							this.faqRef &&
							(() => {
								const nodes = this.getNodes(fileMarkdown);
								const titles = Object.keys(nodes)
									.filter((key) => nodes[key].id && nodes[key].tagName === 'H2')
									.reduce((obj, key) => {
										obj[key] = nodes[key];
										return obj;
									}, {});
								const options = Object.keys(titles).map((key) => ({
									value: titles[key].id,
									label: titles[key].childNodes.item(0).data
								}));
								return (
									<Select
										data-spec="select"
										name="sim-state"
										options={options}
										onChange={(selectedItem) => {
											this.setState({ selected: selectedItem });
											const el = document.getElementById(selectedItem.value);
											window.scrollBy({
												top: el.getBoundingClientRect().top - 100,
												left: 0
											});
										}}
										value={selected}
										placeholder={
											<FormattedMessage
												id="SUPPORT.SELECT_SECTION"
												defaultMessage="Select Section"
											/>
										}
									/>
								);
							})()}
					</div>
				)}
				<div
					className={styles.main_content}
					ref={(faq) => {
						this.faqRef = faq;
					}}
					id={contentId}
					dangerouslySetInnerHTML={{ __html: fileMarkdown }}
				/>
				{!fileMarkdown && <Loader />}
			</div>
		);
	}
}

const { bool, string, oneOfType, object, func } = PropTypes;

Mobile.propTypes = {
	faqAvailable: bool,
	contentId: string,
	fileMarkdown: oneOfType([string, object]),
  showNotification: func,
  intl: object
};

export default injectIntl(Mobile);
