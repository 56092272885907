import { connect } from 'react-redux';
import ListDesktop from './Desktop';
import ListMobile from './Mobile';

import {
	getAnalyticsReports,
	getCustomerReports,
	getAnalyticsReportsInit,
	getCustomerReportsInit
} from '../../redux/reports/actions';

import {
	setDataOffset,
	setSelectedPage,
	setPaginationInit
} from '../../../Shared/redux/pagination/actions';

import {
	openFilterModal,
	setDataForFiltersInit,
	getFilters
} from '../../../Shared/redux/filterModal/actions';

import {
	getAnalyticsReportsRequest,
	getAnalyticsReportsFail,
	getAnalyticsReportsSuccess,
	getCustomerReportsRequest,
	getCustomerReportsFail,
	getCustomerReportsSuccess
} from '../../redux/reports/selectors';

import { getSimpleSearchParameter } from '../../../Shared/redux/simpleSearch/selectors';

import {
	getUser,
	getUserSettings
} from '../../../../redux/user/getUserData/selectors';

import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';

import {
	getDataOffset,
	getSelectedPage
} from '../../../Shared/redux/pagination/selectors';

import { downloadReportRequest } from '../../redux/downloadReport/selectors';

import { getFiltersRequest } from '../../../Shared/redux/filterModal/selectors';

import { getSearchParameter } from '../../../Shared/redux/searchBar/selectors';

import { anyModalOpened } from '../../../Shared/redux/modals/actions';

const mapStateToProps = (state) => ({
	reportsRequest:
		getAnalyticsReportsRequest(state) || getCustomerReportsRequest(state),
	reportsFail: getAnalyticsReportsFail(state) || getCustomerReportsFail(state),
	reports:
		getAnalyticsReportsSuccess(state) || getCustomerReportsSuccess(state),
	downloadReportRequest: downloadReportRequest(state),
	simpleSearchParameter: getSimpleSearchParameter(state),
	user: getUser(state),
	filterData: filterDataSuccess(state),
	userSettings: getUserSettings(state, 12),
	getFiltersRequest: getFiltersRequest(state),
	dataOffset: getDataOffset(state),
	selectedPage: getSelectedPage(state),
	searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAnalyticsReports: (params) => dispatch(getAnalyticsReports(params)),
	getAnalyticsReportsInit: () => dispatch(getAnalyticsReportsInit()),
	getCustomerReports: (params) => dispatch(getCustomerReports(params)),
	getCustomerReportsInit: () => dispatch(getCustomerReportsInit()),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
	getFilters: (params) => dispatch(getFilters(params)),
	setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
	setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(ListDesktop);

export const Mobile = connect(mapStateToProps, mapDispatchToProps)(ListMobile);
