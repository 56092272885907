/* global GLOBALS */
import React from 'react';
import { find } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import hash from 'object-hash';
import { untouch, change } from 'redux-form';
import { isUserAllowedToAccess } from './AuthSelector';

const SUBDOMAINS = ['gm'];
export const LOCALES = [
	{
		local: 'en-us',
		formattedLocal: 'en-US',
		label: 'EN-US'
	},
	{
		local: 'es-es',
		formattedLocal: 'es-ES',
		label: 'ES-ES'
	},
	{
		local: 'it-it',
		formattedLocal: 'it-IT',
		label: 'IT-IT'
	},
	{
		local: 'de-de',
		formattedLocal: 'de-DE',
		label: 'DE-DE'
	},
	{
		local: 'fr-fr',
		formattedLocal: 'fr-FR',
		label: 'FR-FR'
	},
	{
		local: 'fr-fr',
		formattedLocal: 'fr-FR',
		label: 'FR-FR'
	},
	{
		local: 'nl-nl',
		formattedLocal: 'nl-NL',
		label: 'NL-NL'
	},
	{
		local: 'pt-br',
		formattedLocal: 'pt-BR',
		label: 'PT-BR'
	},
	{
		local: 'zh-cn',
		formattedLocal: 'zh-CN',
		label: 'ZH-CN'
	}
];

export default {
	screen_sm_max: 640,
	screen_md_min: 641,
	screen_md_max: 900,
	screen_lg_min: 901,
	screen_lg_max: 1150,
	screen_xlg_min: 1151
};

export const getTheme = () => {
	const subdomain = window.location.hostname.split('.')[0];
	if (SUBDOMAINS.indexOf(subdomain) > -1) {
		return subdomain;
	}
	return 'default';
};

export const getLocale = () => {
	const lng = window.location.pathname.split('/')[1];
	const foundLocal = find(LOCALES, (item) => (
			item.label.toLowerCase() === lng || item.local === navigator.language
		));

	if (foundLocal) {
		return foundLocal.formattedLocal;
	}

	return 'en-US';
};

export const getThemeCss = (theme) => {
	const path =
		theme == 'default'
			? GLOBALS.DEFAULT_STYLES
			: `${GLOBALS.DEFAULT_STYLES}_${theme}`;
	return `${GLOBALS.ASSETS_BASE_PATH}${path}.css`;
};

export const getSwaggerUrl = () => (GLOBALS.SWAGGER_URL !== undefined ? GLOBALS.SWAGGER_URL : '/swagger/');

export const Status = {
	INIT: 0,
	PENDING: 1,
	DONE: 2,
	FAIL: 3
};

export const EndpointStatuses = [
	{ status: 'ACTIVE' },
	{ status: 'INACTIVE' },
	{ status: 'TERMINATE' }
];

export const notificationsTitle = [
	{ name: '' },
	{ name: 'Info' },
	{ name: 'Error' },
	{ name: 'Warning' },
  { name: 'Confirmation' },
  { name: 'Release Note' }
];

export const paginationData = {
	dataLimit: 50,
	dataOffset: 0,
	selectedPage: 0
};

export const batchFileStatuses = [
	{
		title: <FormattedMessage id="SUBMITTED_STATUS" />,
		color: '#959595'
	},
	{
		title: <FormattedMessage id="SCHEDULED_STATUS" />,
		color: '#ffb81c'
	},
	{
		title: <FormattedMessage id="IN_PROGRESS_STATUS" />,
		color: '#71c5e8'
	},
	{
		title: <FormattedMessage id="COMPLETED_STATUS" />,
		color: '#4ca90c'
	},
	{
		title: <FormattedMessage id="ERROR_STATUS" />,
		color: '#cf2a2a'
	}
];

export const serviceProviders = ['EOD', 'ACC', 'GBCM', 'DCP', 'CCIP', 'MC'];

export const HistoryHeader = [
	{
		title: <FormattedMessage id="BATCH_FILE_NAME" />,
		name: 'fileName'
	},
	{
		title: <FormattedMessage id="DATE_UPLOADED" />,
		name: 'scheduledDate'
	},
	{
		title: <FormattedMessage id="STATUS" />,
		name: 'status'
	}
];

export const TicketingTableHeader = [
	{
		title: 'TICKET_ID',
		name: 'externalTicketId'
	},
	{
		title: 'CATEGORY_NAME',
		name: 'categoryName'
	},
	{
		title: 'DATE_CREATED',
		name: 'createdAt'
	},
	{
		title: 'CREATED_BY',
		name: 'userId'
	},
	{
		title: 'TICKETING_STATUS',
		name: 'statusName'
	},
	{
		title: 'LAST_UPDATE',
		name: 'updatedAt'
	},
	{
		title: 'SEVERITY',
		name: 'severityName'
	},
	{
		title: 'SUBMISSION_LOCATION',
		name: 'deviceLocationCity'
	},
	{
		title: 'UPDATE_NOTE',
		name: 'notes'
	}
];

export const TicketingTableHeaderMobile = [
	{
		title: <FormattedMessage id="TICKET_ID" />,
		name: 'externalTicketId'
	},
	{
		title: <FormattedMessage id="CATEGORY_NAME" />,
		name: 'category'
	}
];

export const TicketHistoryTableHeader = [
	{
		title: <FormattedMessage id="LAST_UPDATE_DATE_TIME" />,
		name: 'externalCreatedAt'
	},
	{
		title: <FormattedMessage id="LAST_UPDATED_BY_USER" />,
		name: 'user'
	},
	{
		title: <FormattedMessage id="NOTE" />,
		name: 'notes'
	}
];

export const entitlementsHeader = [
	{
		title: <FormattedMessage id="COMMUNICATION_PROFILE_NAME" />,
		name: 'name'
	},
	{
		title: ' ',
		name: 'edit_entitlement'
	}
];

export const ratePlansHeader = [
	{
		title: <FormattedMessage id="RATE_PLAN_NAME" />,
		name: 'name'
	},
	{
		title: ' ',
		name: 'edit_rate_plan'
	}
];

/** *****************REPORT AND INVOICES TAB CONSTANTS START **************** */
/**
 * Array of object that contains
 * @id (string) that identifies tab
 * @name (string) used to get value from the localization
 * @fileCategory (string) filter name that needs to be passed to
 *  API in order to retrieve data for that category
 */

export const invoiceTabs = [
	{
		id: 'invoices',
		name: 'INVOICES',
		fileCategory: 'Invoice'
	}
];

export const ticketOverviewTabs = [
	{
		id: 'historyTicket',
		name: 'HISTORY',
		fileCategory: 'History'
	}
];
/** *************REPORT AND INVOICES TAB CONSTANTS END ************* */

/* LIST OF PAGES FOR USER FEEDBACK DROPDOWN SELECT (SHOULD BE USED FOR SIDEBAR) */
export const pages = [
	{ name: <FormattedMessage id="DASHBOARD_FEEDBACK" />, id: 'dashboard' },
	{ name: <FormattedMessage id="ENDPOINTS" />, id: 'endpoints' },
	{ name: <FormattedMessage id="REPORTS" />, id: 'reports' },
	{ name: <FormattedMessage id="INVOICES" />, id: 'invoices' },
	{ name: <FormattedMessage id="AUTOMATION" />, id: 'automation' },
	{ name: <FormattedMessage id="SUPPORT" />, id: 'support' },
	{ name: <FormattedMessage id="HISTORY" />, id: 'history' },
	{ name: <FormattedMessage id="COMPANIES" />, id: 'companies' },
	{ name: <FormattedMessage id="COMPANIES_STEPS" />, id: 'companies-steps' }
];

const endpointsTableHeaderPermissions = {
	m2mAccountId: ['mnc.endpoints.accountid_rw', 'mnc.endpoints.accountid_ro'],
	networkOperator: [
		'mnc.endpoints.networkoperator_rw',
		'mnc.endpoints.networkoperator_ro'
	],
	networkOperators: [
		'mnc.endpoints.networkoperator_rw',
		'mnc.endpoints.networkoperator_ro'
	],
	networkCountry: [
		'mnc.endpoints.networkcountry_rw',
		'mnc.endpoints.networkcountry_ro'
	],
	countries: [
		'mnc.endpoints.networkcountry_rw',
		'mnc.endpoints.networkcountry_ro'
	],
	serviceProvider: [
		'mnc.endpoints.m2mplatform_rw',
		'mnc.endpoints.m2mplatform_ro'
	],
	M2MPlatform: ['mnc.endpoints.m2mplatform_rw', 'mnc.endpoints.m2mplatform_ro'],
	m2mPlatform: ['mnc.endpoints.m2mplatform_rw', 'mnc.endpoints.m2mplatform_ro'],
	networkEntitlementId: [
		'mnc.endpoints.networkentitlements_rw',
		'mnc.endpoints.networkentitlements_ro'
	],
	networkEntitlement: [
		'mnc.endpoints.networkentitlements_rw',
		'mnc.endpoints.networkentitlements_ro'
	],
	ratePlanId: ['mnc.endpoints.rateplans_rw', 'mnc.endpoints.rateplans_ro'],
	rateplan: ['mnc.endpoints.rateplans_rw', 'mnc.endpoints.rateplans_ro'],
	ratePlan: ['mnc.endpoints.rateplans_rw', 'mnc.endpoints.rateplans_ro'],
	recredentialTransferStatus: [
		'svc_mc_endpointsrecredential_rw',
		'svc_mc_endpointsrecredential_ro'
	],
	networkType: ['mnc.endpoints.networktype_rw', 'mnc.endpoints.networktype_ro'],
	transferStatus: [
		'svc_mc_endpointreimsistatus_rw',
		'svc_mc_endpointreimsistatus_ro'
	],
	roamingCountry: [
		'mnc.endpoints.roamingcountry_rw',
		'mnc.endpoints.roamingcountry_ro'
	],
	roamingCarrier: [
		'mnc.endpoints.roamingcarrier_rw',
		'mnc.endpoints.roamingcarrier_ro'
	],
	seNotes: ['system.senotes_rw', 'system.senotes_ro'],
	m2mDateAdded: [
		'mnc.endpoints.m2mdateadded_rw',
		'mnc.endpoints.m2mdateadded_ro'
	],
	M2M_DATE_ADDED: [
		'mnc.endpoints.m2mdateadded_rw',
		'mnc.endpoints.m2mdateadded_ro'
	],
	m2mInitialActivationDate: [
		'mnc.endpoints.m2mactivationdate_rw',
		'mnc.endpoints.m2mactivationdate_ro'
	],
	M2M_INITIAL_ACTIVATION_DATE: [
		'mnc.endpoints.m2mactivationdate_rw',
		'mnc.endpoints.m2mactivationdate_ro'
	],
	deviceName: ['mnc.endpoints.devicename_rw', 'mnc.endpoints.devicename_ro'],
	dataBytesCtd: [
		'mnc.endpoints.ctddatausage_rw',
		'mnc.endpoints.ctddatausage_ro'
	],
	voiceSecondsCtd: [
		'mnc.endpoints.ctdvoiceusage_rw',
		'mnc.endpoints.ctdvoiceusage_ro'
	],
	smsCountCtd: ['mnc.endpoints.ctdsmsusage_rw', 'mnc.endpoints.ctdsmsusage_ro'],
	dataSession: ['mnc.endpoints.insession_rw', 'mnc.endpoints.insession_ro'],
	status: ['mnc.endpoints.simstatus_rw', 'mnc.endpoints.simstatus_ro']
};

const invoicesTableHeaderPermissions = {
	documentId: ['mnc.invoices.documentid_rw', 'mnc.invoices.documentid_ro']
};

const createCustomFieldPermisions = () => {
	const customFields = [...new Array(10)];
	let temp = {};
	customFields.forEach((...rest) => {
		const index = rest[1] + 1;
		temp = {
			...temp,
			[`customFieldValue${index}`]: [
				'mnc.endpoints.customfields_rw',
				'mnc.endpoints.customfields_ro',
			],
		};
	});
	return temp;
};

export const tableHeaderPermissionsCheck = (
	header,
	user,
	permissionsType = 'endpoints'
) => {
	let temp = [];
	let tableHeaderPermissions = {};
	switch (permissionsType) {
		case 'invoices':
			tableHeaderPermissions = {
				...invoicesTableHeaderPermissions
			};
			break;
		default:
			tableHeaderPermissions = {
				...endpointsTableHeaderPermissions,
				...createCustomFieldPermisions()
			};
	}

	header.forEach((item) => {
		const { name, value } = item;
		const headerNamePermission =
			tableHeaderPermissions[item] || tableHeaderPermissions[name || value];
		if (headerNamePermission) {
			if (isUserAllowedToAccess(headerNamePermission, user)) {
				temp = [...temp, item];
			}
		} else {
			temp = [...temp, item];
		}
	});

	return temp;
};

export const filtersPermissionsCheck = (
  params,
  realPropNames,
	user
) => {
	let temp = [];
	const tableHeaderPermissions = {
		...endpointsTableHeaderPermissions,
		...createCustomFieldPermisions(),
  };

  params.forEach((item) => {
    const permissionItem = tableHeaderPermissions[realPropNames[item]];
    if (permissionItem) {
      if (isUserAllowedToAccess(permissionItem, user)) {
        temp = [...temp, item];
      }
    } else {
      temp = [...temp, item];
    }
  });

	return temp;
};

export const InvoicesTableTabs = [
	{
		name: 'InvoiceNumber',
		refTypeId: 7,
		default: true,
		value: {
			data: [
				{ name: 'invoiceNumber', title: 'INVOICE_ID', checked: true },
				{
					title: 'INVOICE_COUNTRY',
					name: 'countryName',
					checked: true
				},
				{
					title: 'CUSTOMER_INVOICE_ID',
					name: 'customerId',
					checked: true
				},
				{
					title: 'INVOICE_DATE',
					name: 'invoiceDate',
					checked: true
				},
				{
					title: 'CUSTOMER_INVOICE_NAME',
					name: 'customerName',
					checked: true
				},
				{
					title: 'DOCUMENT_ID',
					name: 'documentId',
					checked: true
				},
				{
					title: 'BILLING_CYCLE',
					name: 'billingCycle',
					checked: true
				},
				{
					title: 'DOWNLOAD',
					name: 'download',
					checked: true
				},
				{
					title: 'NOTES',
					name: 'notes',
					checked: true
				}
			]
		}
	}
];
export const SecurityFeaturesHeaders = [
	{
		title: 'M2M_ACCOUNT_NAME',
		name: 'm2mAccountName',
		checked: true
	},
	{
		title: 'DATE_SUBMITTED',
		name: 'createdAt',
		checked: true
	},
	{
		title: 'STATUS',
		name: 'status',
		checked: true
	}
	// {
	// 	title: "CUSTOMER_NAME",
	// 	name: "customerName",
	// 	checked: true
	// },
	// {
	// 	title: "DOCUMENT_ID",
	// 	name: "documentId",
	// 	checked: true
	// },
	// {
	// 	title: "DOWNLOAD",
	// 	name: "download",
	// 	checked: true
	// }
];

export const ticketSignature = hash(JSON.stringify(TicketingTableHeader));

export const invoicesSignature = hash(
	JSON.stringify(InvoicesTableTabs[0].value.data)
);

export const getDateFormat = (date) => {
	date = new Date(date);
	const dateFormat = 'MM/dd/yyyy';
	return format(date, dateFormat);
};

export const getDateTimeFormat = (date) => {
	date = new Date(date);
	const dateTimeFormat = 'MM/dd/yyyy hh:mm:ss';
	return format(date, dateTimeFormat);
};

export const getFullDateTime = (date) => {
	date = new Date(date);
	const timeFormat = 'MM/dd/yyyy hh:mm:ss a';
	return format(date, timeFormat);
};

export const getTimeFormat = (date, A) => {
	date = new Date(date);
	const timeFormat = `hh:mm:ss ${A ? 'a' : ''}`;
	return format(date, timeFormat);
};

export const isValidDate = (date) => (typeof date === 'string'
		? new Date(date) instanceof Date && !Number.isNaN(new Date(date))
		: date instanceof Date && !Number.isNaN(date));

export const globalPaginationNumber = {
	desktop: 50,
	mobile: 20
};

export const allowedPlatformsPendingStatus = [
	'acc',
	'eod',
	'gbcm',
	'dcp',
	'wing',
	'ccip'
];

export const allowedServiceProviders = ['acc', 'ccip', 'gdsp', 'vivo', 'wing', 'gmna', 'pod3', 'pod19', 'eod'];

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
export const CurrencyFormatters = {
	usd: {
		locale: 'en-US',
		options: {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0
		}
	},
	usdDecimals: {
		locale: 'en-US',
		options: {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		}
	},
	usdQuantity: {
		locale: 'en-US',
		options: {
			style: 'decimal',
			currency: 'USD',
			minimumFractionDigits: 0
		}
	}
};

export const normalize = (value) =>
	value && value.replace(/^\s+/g, '').replace(/\s+/g, ' ');
export const normalizeDoubleSpace = (value) =>
	(value.trim().length === 0
		? value.replace(/^\s+/g, '').replace(/\s+/g, ' ')
		: value);

export const resetFields = (dispatch, formName, fieldsObj) => {
	Object.keys(fieldsObj).forEach((fieldKey) => {
		dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
		dispatch(untouch(formName, fieldKey));
	});
};

export const startOfDayFormatter = (value) =>
	new Date(value.setHours(0, 0, 0, 0));

export const sameDateFormatter = (value) =>
	format(new Date(), 'yyyy-MM-dd') === format(value, 'yyyy-MM-dd');

export const downloadReportHelper = (url, fileName, target) => {
		const tempLink = document.createElement('a');
		tempLink.href = url;
		tempLink.setAttribute('download', fileName);
		if (target) {
			tempLink.target = '_blank';
		}
		document.body.appendChild(tempLink);
		tempLink.click();
		document.body.removeChild(tempLink);
};

export const federatedDomains = ['att.com'];

export const filteredDomains = (userDomain) => federatedDomains.some(
	(x) => userDomain.endsWith(`.${x}`) || userDomain === x
);
