export default {
	'SINGLE_ENDPOINT.ENDPOINT_TITLE': 'Endpunkt{iccid}',
	'SINGLE_ENDPOINT.EDIT_ENDPOINT': 'Endpunkt bearbeiten',
	'SINGLE_ENDPOINT.APN': 'APN',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT': 'Netzwerkanspruch',
	'SINGLE_ENDPOINT.ID': 'ID',
	'SINGLE_ENDPOINT.PROVISIONING_ERROR_MESSAGE':
		'Endpunkt-ID {args1} Bereitstellung nach IMSI-Statusänderung fehlgeschlagen.  Support erhalten',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP_ONE': '{step}. SIM-Status auswählen',
	'SINGLE_ENDPOINT.SCHEDULE_SIM_STATE_CHANGE_STEP':
		'{step}. SIM-Statusänderung einplanen',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION_STEP': '{step}. Systembestätigung',
	'SINGLE_ENDPOINT.ICCID': 'ICCID',
	'SINGLE_ENDPOINT.EUICCID': 'EUICCID',
	'SINGLE_ENDPOINT.IMSI': 'IMSI',
	'SINGLE_ENDPOINT.MSISDN': 'MSISDN',
	'SINGLE_ENDPOINT.IMEI': 'IMEI',
	'SINGLE_ENDPOINT.STATUS': 'Status',
	'SINGLE_ENDPOINT.REFRESH_PENDING_STATUS':
		'Endpunkt aktualisieren, um zu prüfen, ob Status geändert wurde',
	'SINGLE_ENDPOINT.ENDPOINT_STATUS': '{status}',
	'SINGLE_ENDPOINT.SOURCE_PLATFORM': 'M2M-Plattform',
	'SINGLE_ENDPOINT.M2M_ACCOUNT_ID': 'M2M-Konto-ID',
	'SINGLE_ENDPOINT.NETWORK_TYPE': 'Netzwerktyp',
	'SINGLE_ENDPOINT.NETWORK_OPERATOR': 'Netzbetreiber',
	'SINGLE_ENDPOINT.NETWORK_COUNTRY': 'Netzland',
	'SINGLE_ENDPOINT.M2M_DATE_ADDED': 'M2M-Datum hinzugefügt',
	'SINGLE_ENDPOINT.M2M_INITIAL_ACTIVATION_DATE': 'M2M-Aktivierungsdatum',
	'SINGLE_ENDPOINT.IN_SESSION': 'In Sitzung',
	'SINGLE_ENDPOINT.RATE_PLAN': 'Tarifplan',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_SUPPORT':
		'Fehler bei Tarifplan, bitte Kundensupport kontaktieren.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_TOOLTIP_SUPPORT':
		'Fehler bei Netzwerkanspruch, bitte Kundensupport kontaktieren.',
	'SINGLE_ENDPOINT.IMSI_TRANSFER': 'IMSI-Übertragungsstatus',
	'SINGLE_ENDPOINT.TRANSFER_STATUSES': '{transferStatus}',
	'SINGLE_ENDPOINT.ROAMING_COUNTRY': 'Roaming-Land',
	'SINGLE_ENDPOINT.ACCOUNT_NAME': 'Kontoname',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_NAME': 'Netzwerkanspruchsname',
	'SINGLE_ENDPOINT.ROAMING': 'Roaming',
	'SINGLE_ENDPOINT.CREATE_DATE': 'Erstellungsdatum',
	'SINGLE_ENDPOINT.LAST_UPDATE_DATE': 'Datum der letzten Aktualisierung',
	'SINGLE_ENDPOINT.SMS_MO': 'SMS MO',
	'SINGLE_ENDPOINT.SMS_MT': 'SMS MT',
	'SINGLE_ENDPOINT.VOICE_MO': 'Voice MO',
	'SINGLE_ENDPOINT.VOICE_MT': 'Voice MT',
	'SINGLE_ENDPOINT.LTE_DATA': 'LTE Daten',
	'SINGLE_ENDPOINT.LTE_ROAMING_RESTRICT_SET_ID': 'Roaming ID',
	'SINGLE_ENDPOINT.CAMEL_SUBSCRIPTION_INFO': 'Camel',
	'SINGLE_ENDPOINT.CAMEL_SERVICE_TYPE': 'Camel-Servicetyp',
	'SINGLE_ENDPOINT.BLOCK_PREMIUM_NUMBERS': 'Premium-Nummern sperren',
	'SINGLE_ENDPOINT.BLOCK_INTERNATIONAL_MO': 'Internationale SMS MO sperren',
	'SINGLE_ENDPOINT.HLR_TEMPLATE': 'HLR-Vorlage',
	'SINGLE_ENDPOINT.INTERNATIONAL_VOICE': 'Voice international',
	'SINGLE_ENDPOINT.HOTLINE_NUMBER': 'Hotline-Nummer',
	'SINGLE_ENDPOINT.FACETIME': 'FACETIME',
	'SINGLE_ENDPOINT.GPRS': 'GPRS',
	'SINGLE_ENDPOINT.DESCRIPTION': 'Beschreibung',
	'SINGLE_ENDPOINT.APN_DETAILS_FIXED_IP': 'Feste IP',
	'SINGLE_ENDPOINT.NOT_APPLICABLE_FOR_THIS_ENDPOINT':
		'Für diesen Endpunkt nicht zutreffend',
	'SINGLE_ENDPOINT.RATE_PLAN_NAME': 'Tarifplanname',
	'SINGLE_ENDPOINT.RATE_PLAN_TYPE': 'Tarifplantyp',
	'SINGLE_ENDPOINT.SUBSCRIPTION_PRICE': 'Abonnementpreis',
	'SINGLE_ENDPOINT.ACCOUNT_CHARGE': 'Kontogebühr',
	'SINGLE_ENDPOINT.PAYMENT_TYPE': 'Zahlungstyp',
	'SINGLE_ENDPOINT.TERM': 'Laufzeit',
	'SINGLE_ENDPOINT.USAGE_LIMIT': 'Nutzungslimit',
	'SINGLE_ENDPOINT.TIERING_FLAG': 'Tiering-Flag',
	'SINGLE_ENDPOINT.DATA_OVERVIEW_DETAILS_ID': '{name} - ID: {id}',
	'SINGLE_ENDPOINT.REQUIRED_CUSTOM_FIELD':
		'Erhöhte Berechtigungen erforderlich!',
	'SINGLE_ENDPOINT.FIELD_IS_REQUIRED_ACTION':
		'Nur Administratoren können benutzerdefinierte Feldtypen ändern, weil sich diese systemweit auf die Konfiguration auswirken. Wenden Sie sich an Ihren Administrator, um benutzerdefinierte Feldoptionen zu aktualisieren.',
	'SINGLE_ENDPOINT.ENDPOINT_ACTIONS_ID': 'Endpunktaktionen für{endpointId}',
	'SINGLE_ENDPOINT.SELECT_SIM_STATE_STEP': '{step}. SIM-Status auswählen',
	'SINGLE_ENDPOINT.ENDPOINT_NAME': 'Endpunkt-ID',
	'SINGLE_ENDPOINT.STATUS_CHANGE_PENDING':
		'Für diesen Endpunkt ist eine geplante Aktivität vorhanden',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TITLE':
		'Die ausstehende Änderung wirklich stornieren?',
	'SINGLE_ENDPOINT.CANCEL_PROMPT_TEXT':
		'Diese Aktion kann nicht rückgängig gemacht werden.',
	'SINGLE_ENDPOINT.CARRIER': 'Betreiber',
	'SINGLE_ENDPOINT.RECREDENTIALED': 'Erneut zugelassen',
	'SINGLE_ENDPOINT.CHANGED_FROM_VALUE': '{changedFromValue}',
	'SINGLE_ENDPOINT.CHANGED_TO_VALUE': '{changedToValue}',
	'SINGLE_ENDPOINT.NOTE': 'Anmerkung',
	'SINGLE_ENDPOINT.SCHEDULE_CHANGE_USER_NOTES':
		'Benutzer {uid} hat am {startDate} eine Statusänderung vorgenommen',
	'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'SIM-Statusänderung erfolgreich eingeplant für',
	'SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID':
		'Aktueller SIM-Status für Endpunkt-ID{endpointId} ist ',
	'SINGLE_ENDPOINT.ELIGIBLE_NETWORK_ENTITLEMENTS':
		'Qualifizierte Netzwerkansprüche',
	'SINGLE_ENDPOINT.APNS_FIXED_IP': 'Feste IP',
	'SINGLE_ENDPOINT.CURRENTLY_ASSIGNED_TO_THIS_ENDPOINT':
		'Aktuell diesem Endpunkt zugewiesen',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_DETAILS': 'Netzwerkanspruchsdetails',
	SELECT_MINIMUM_OF_2: 'Mindestens 2 auswählen und auf Tabelle anwenden. ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_BUTTON': 'Diagnose starten',
	'SINGLE_ENDPOINT.CARRIER_INFORMATION': 'Betreiberinformationen',
	'SINGLE_ENDPOINT.COUNTRY': 'Land',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_DESCRIPTION':
		'M2M-Plattform-Statusbeschreibung',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_A': 'Aktiv live',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_B': 'Inaktiv neu',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_C': 'Aktiv Ruhe',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_D': 'Inaktiv angehalten',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_I': 'Aus anderer GDSP-Umgebung migriert',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_N': 'Nicht bereitgestellt',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_O': 'Zu anderer GDSP-Umgebung migriert',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_R': 'Aktiv bereit',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_S': 'Aktiv suspendieren',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_T': 'Aktiv Test',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_U': 'Aktiv Standby',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_V': 'Inaktiv umgewandelt',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_W': 'Regulatorische Suspendierung',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_Z': 'Beendet',
	'SINGLE_ENDPOINT.M2M_PLATFORM_STATUS_NOT_AVAILABLE': 'Nicht verfügbar',
	'SINGLE_ENDPOINT.ENDPOINT_PROVISIONING_INFORMATION':
		'Endpunktbereitstellungs-Informationen',
	'SINGLE_ENDPOINT.PROVISIONED': 'Bereitgestellt',
	'SINGLE_ENDPOINT.PROVISIONED_DATE_AND_TIME':
		'Datum und Uhrzeit der Bereitstellung{date}',
	'SINGLE_ENDPOINT.NETWORK_CONNECTIONS': 'Netzwerkverbindungen',
	'SINGLE_ENDPOINT.VOICE_ENABLED': 'Voice aktiviert',
	'SINGLE_ENDPOINT.DATA_ENABLED': 'Daten aktiviert',
	'SINGLE_ENDPOINT.SESSIONS': 'Sitzungen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inventory': 'Inventar',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activated': 'Aktiviert',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_active': 'Aktiviert',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactivated': 'Inaktiv',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_inactive': 'Inaktiv',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_deactivated': 'Deaktiviert',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_retired': 'Stillgelegt',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_purged': 'Bereinigt',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_testready': 'Test bereit',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_activationready':
		'Aktivierung bereit',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_replaced': 'Ersetzt',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trial': 'Versuch',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_start': 'Start',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_transfer': 'Übertragung',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitsource':
		'Globale In-Transit-Quelle',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globalintransitdestination':
		'Globales In-Transit-Ziel',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_globaltransferred':
		'Global übertragen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_other': 'Sonstiges',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_permitpassingtraffic':
		'Weiterleitung von Traffic zugelassen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notpermitpassingtraffic':
		'Weiterleitung von Traffic nicht zugelassen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedToUseWirelessNetworkResources':
		'Zulässige TollSec Wireless-Ressourcen',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_barredFromNetwork':
		'Aus Netzwerk ausgesperrt',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficnotallowed_simstate':
		'Traffic nicht zulässig SIM-Status',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedByServiceProvider':
		'Durch Dienstanbieter suspendiert',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_suspendedDueToUsageLimit':
		'Aufgrund Nutzungslimit suspendiert',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_trafficNotAllowed_others':
		'Traffic nicht zulässig (Sonstiges)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_successfullyconnectedtonetwork_insession':
		'Erfolgreich mit Netzwerk verbunden (in Sitzung)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notsuccessfullyconnectedtonetwork_insession':
		'Nicht mit Netzwerk verbunden (in Sitzung)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_validnetworkactivity':
		'Gültige Registrierung (gültige Netzwerkaktivität)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validregistration_nonetworkactivity':
		'Gültige Registrierung (keine Netzwerkaktivität)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_validgsmregistrationonly':
		'Nur gültige GSM-Registrierung',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasactivityinlast24h':
		'Keine gültige Registrierung (Aktivität in den letzten 24 Stunden)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestinlast24h_nolocupdate':
		'Keine gültige Registrierung (Auth.-Anforderung in den letzten 24 Stunden)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_haslocupdatesmorethan24hago':
		'Keine gültige Registrierung (lokale Aktualisierungen vor mehr als 24 Stunden)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_hasauthrequestmorethan24hago_noactivity':
		'Keine gültige Registrierung (Auth.-Anforderung vor mehr als 24 Stunden)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_novalidregistration_nonetworkactivity':
		'Vor mehr als 24 Stunden (keine Netzwerkaktivität)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessioncurrentlysuccessful':
		'Gerät aktuell nicht angebunden. Vorherige IP-Sitzungen erfolgreich.',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_notdatasessioncurrentlysuccessful':
		'Datensitzung derzeit nicht erfolgreich',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_datasessionpastsuccessful':
		'Datensitzung in der Vergangenheit erfolgreich',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_connectionattemptfailed_invalidcredentials':
		'Verbindungsversuch fehlgeschlagen (ungültige Anmeldedaten)',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_nodatasessionsuccessfulhistory':
		'Keine erfolgreiche Datensitzung in der Vergangenheit',
	'SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_allowedtousewirelessnetworkresources':
		'Zulässige TollSec Wireless-Ressourcen',
	'SINGLE_ENDPOINT.ACTIONS': 'Aktionen',
	'SINGLE_ENDPOINT.GET_HLR_HSS_DETAILS': 'HLR/HSS-Details abrufen',
	'SINGLE_ENDPOINT.SATELLITE_MODULE_DYNAMIC_STATUS': '{status}',
	'SINGLE_ENDPOINT.RECURRING_FEE': 'Laufende Gebühr',
	'SINGLE_ENDPOINT.SELECT_RATE_PLAN': '1. Tarifplan auswählen',
	'SINGLE_ENDPOINT.CONFIRM_CHANGES': '2. Änderungen bestätigen',
	'SINGLE_ENDPOINT.SYSTEM_CONFIRMATION': '3. Systembestätigung',
	'SINGLE_ENDPOINT.ELIGIBLE_TO_USE_WITH_THIS_ENDPOINT':
		'Für die Verwendung mit diesem Endpunkt qualifiziert',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED':
		' Tarifplanänderung erfolgreich aktualisiert',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Tarifplanänderung erfolgreich eingeplant für',
	'SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Tarifplanänderung erfolgreich eingeplant',
	'SINGLE_ENDPOINT.CONFIRM_NETWORK_ENTITLEMENT_CHANGE':
		'Netzwerkanspruchsänderung bestätigen',
	'SINGLE_ENDPOINT.ONCE_YOU_INITIATE_THIS_CHANGE':
		'Wenn Sie diese Änderung einleiten, lautet der Netzwerkanspruch des ausgewählten Endpunkts ',
	'SINGLE_ENDPOINT.WOULD_YOU_LIKE_TO_PROCEED_WITH_THIS_ACTION':
		'Mit dieser Aktion fortfahren?',
	'SINGLE_ENDPOINT.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR':
		'Sie können die Änderung auch planen für: ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED':
		'Netzwerkanspruchsänderung erfolgreich eingeplant.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR':
		'Netzwerkanspruchsänderung erfolgreich eingeplant für ',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY':
		'Netzwerkanspruchsänderung konnte derzeit nicht ausgeführt werden.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY':
		'Netzwerkanspruchsänderung konnte derzeit nicht eingeplant werden.',
	'SINGLE_ENDPOINT.NETWORK_ENTITLEMENT_CHANGE_STATUS':
		'Netzwerkanspruchsänderungs-Status',
	'SINGLE_ENDPOINT.CURRENT_RATE_PLAN_FOR_ENDPOINT_ID':
		'Aktueller Tarifplan für Endpunkt-ID{id} ist ',
	'SINGLE_ENDPOINT.ROAMING_CARRIER': 'Roaming-Betreiber',
	'SINGLE_ENDPOINT.OEM': 'Unterkonten',
	'SINGLE_ENDPOINT.SERVICE_STATUS': '360L-Status',
	'SINGLE_ENDPOINT.VIN': 'VIN',
	'SINGLE_ENDPOINT.DIAGNOSTICS_HSPA': 'HSPA: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_LTE': 'LTE: ',
	'SINGLE_ENDPOINT.NETWORK_CONNECTION': 'Netzwerkverbindung',
	'SINGLE_ENDPOINT.3G': '3G',
	'SINGLE_ENDPOINT.DIAGNOSTICS_VLR': 'VLR: ',
	'SINGLE_ENDPOINT.DIAGNOSTICS_SGSN': 'SGSN: ',
	'SINGLE_ENDPOINT.REGISTRATION_STATUS': 'Registrierungsstatus',
	'SINGLE_ENDPOINT.OEM_TOOLTIP': 'Verknüpfte 360L-Konten',
	'SINGLE_ENDPOINT.DUAL_MODE': 'Dualmodus',
	'SINGLE_ENDPOINT.CONNECTED_DEVICE': 'Verbundenes Gerät',
	'SINGLE_ENDPOINT.ENDPOINT_RATE_PLAN': 'Tarifplan',
	'SINGLE_ENDPOINT.RECORDED_STATUS': 'Aufgezeichneter Status',
	'SINGLE_ENDPOINT.ACTIVATION_DATE': 'Aktivierungsdatum',
	'SINGLE_ENDPOINT.EXPORT_USAGE_TOOLTIP_MESSAGE': 'Nutzungsdaten exportieren',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_TITLE':
		'Datenexport fehlgeschlagen',
	'SINGLE_ENDPOINT.EXPORT_USAGE_DATA_FAILED_MESSAGE':
		'Nutzungsdaten können derzeit nicht exportiert werden.',
	'SINGLE_ENDPOINT.USAGE_EXPORT_LOADER_MESSAGE':
		'Bitte warten, während die Datei für Sie vorbereitet wird. Je nach Anzahl der Datensätze nimmt dies Zeit in Anspruch.',
	'SINGLE_ENDPOINT.NO_DATA_FOR_DATE_RANGE':
		'Keine Daten für den ausgewählten Zeitraum vorhanden.',
	'SINGLE_ENDPOINT.CHANGE_DATE_TO_REFRESH_LIST':
		'Ändern Sie ggf. den Datumsbereich, um die Liste zu aktualisieren.',

	//  SIM STATUS
	'SINGLE_ENDPOINT.ACTIVE': 'Aktiviert',
	'SINGLE_ENDPOINT.TERMINATE': 'Beendet',
	'SINGLE_ENDPOINT.DEACTIVE': 'Inaktiv',
	'SINGLE_ENDPOINT.INACTIVE': 'Inaktiv',
	'SINGLE_ENDPOINT.TEST': 'Test',
	'SINGLE_ENDPOINT.SUSPENDED': 'Suspendiert',
	'SINGLE_ENDPOINT.SUSPEND': 'Suspendieren',
	'SINGLE_ENDPOINT.INVENTORY': 'Inventar'
};
