import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes, { object } from 'prop-types';

import { defaultSystemManagmentTab } from '../../utils/constants';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';

import SystemUsers from '../../views/UsersView';
import UserGroups from '../../views/UserGroupsView';
import SystemRegistrations from '../../views/PendingInvitationsView';

import styles from './AccessManagement.scss';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

const availableSystemUserTabs = [
	{
		services: [
			'system.accessmanagement.users_rw',
			'system.accessmanagement.users_ro',
		],
		tab: {
			id: 'systemUsersManagement',
			label: <FormattedMessage id="ONBOARDING.USERS" defaultMessage="Users" />,
		},
	},
	{
		services: [
			'system.accessmanagement.usergroups_rw',
			'system.accessmanagement.usergroups_ro',
		],
		tab: {
			id: 'systemUserGroups',
			label: (
				<FormattedMessage
					id="ONBOARDING.USER_GROUPS"
					defaultMessage="User Groups"
				/>
			),
		},
	},
	{
		services: [
			'system.accessmanagement.registrations_rw',
			'system.accessmanagement.registrations_ro',
		],
		tab: {
			id: 'systemUserPendingInvitations',
			label: (
				<FormattedMessage
					id="ONBOARDING.PENDING_INVITATIONS"
					defaultMessage="Pending Invitations"
				/>
			),
		},
	},
];

class AccessManagement extends React.Component {
	constructor(props) {
		super(props);
		this.SystemUsersTabs = availableSystemUserTabs
			.filter((item) => isUserAllowedToAccess(item.services, props.currentUser))
			.map((i) => i.tab);

		let activeTab =
			this.SystemUsersTabs.length > 0 ? this.SystemUsersTabs[0] : null;
		if (
			props.defaultSystemManagmentTab ===
			defaultSystemManagmentTab.systemUsersManagement
		) {
			activeTab = this.SystemUsersTabs.find(
				(tab) => tab.id === defaultSystemManagmentTab.systemUsersManagement
			);
		} else if (
			props.defaultSystemManagmentTab ===
			defaultSystemManagmentTab.systemUserGroups
		) {
			activeTab = this.SystemUsersTabs.find(
				(tab) => tab.id === defaultSystemManagmentTab.systemUserGroups
			);
		} else if (
			props.defaultSystemManagmentTab ===
			defaultSystemManagmentTab.systemUserPendingInvitations
		) {
			activeTab = this.SystemUsersTabs.find(
				(tab) =>
					tab.id === defaultSystemManagmentTab.systemUserPendingInvitations
			);
		}
		this.state = { activeTab };
	}

	onNewTabClick = async (id) => {
		const {
			push,
			setDataForFiltersInit,
		} = this.props;

		await setDataForFiltersInit();

		if (id === defaultSystemManagmentTab.systemUsersManagement) {
			push('/access-management');
		} else if (id === defaultSystemManagmentTab.systemUserGroups) {
			push('/access-management/user-groups');
		} else if (id === defaultSystemManagmentTab.systemUserPendingInvitations) {
			push('/access-management/pending-invitations');
		}
	};

	render() {
		const { messages } = this.props;

		const tabComponents = {
			systemUsersManagement: (
				<React.Fragment key="accessManagement">
					<SystemUsers
						key="service-provider-users"
						messages={messages}
						accessManagment
						system
					/>
				</React.Fragment>
			),
			systemUserGroups: (
				<React.Fragment key="userGroups">
					<UserGroups
						key="service-provider-user-groups"
						messages={messages}
						system
					/>
				</React.Fragment>
			),
			systemUserPendingInvitations: (
				<React.Fragment key="pendingInvitations">
					<SystemRegistrations
						key="pending-invitations"
						messages={messages}
						system
					/>
				</React.Fragment>
			),
		};

		const actionTabs = this.SystemUsersTabs.map((tab) => {
			const { activeTab } = this.state;
			const name = messages[tab.label] || tab.label;
			return {
				name,
				isActive: activeTab && tab.id === activeTab.id,
				onClick: () => this.onNewTabClick(tab.id),
				component: tabComponents[tab.id],
			};
		});

		return (
			<div data-spec="access-management">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.ACCESS_MANAGEMENT"
							defaultMessage="Access Management"
						/>
					}
				/>
				<div className={styles.notice}>
					<FormattedMessage
						id="ONBOARDING.ADD_SERVICE_PROVIDER_USERS"
						defaultMessage="Add service provider users to be able to manage customer accounts and onboard new ones."
					/>
				</div>
				<TabsBar tabs={actionTabs} />
				{(() => {
					const active = actionTabs.find((tab) => tab.isActive);
					return active.component;
				})()}
			</div>
		);
	}
}

const { objectOf, string, func } = PropTypes;

AccessManagement.propTypes = {
	messages: objectOf(string),
	defaultSystemManagmentTab: string,
	push: func,
	setDataForFiltersInit: func,
	currentUser: object,
};

export default AccessManagement;
