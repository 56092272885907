export default {
		'SHARED.NO_DATA_AVAILABLE': 'Nenhum dado disponível',
		'SHARED.NO_DATA_AVAILABLE_SUBTITLE': 'Não há dados disponíveis para mostrar no momento',
		'SHARED.EXIT_THIS_COMPANY': 'Sair',
		// ACTION BAR SELECTED FILTERS TOOLTIP
		// ** ENDPOINTS **
		'ACTION_BAR.ENDPOINTSTATUS': 'Status',
		'ACTION_BAR.NETWORKOPERATOR': 'Operador de rede',
		'ACTION_BAR.NETWORKCOUNTRY': 'País da rede',
		'ACTION_BAR.NETWORKENTITLEMENT': 'ID do direito de acesso à rede',
		'ACTION_BAR.NETWORKTYPE': 'Tipo de rede',
		'ACTION_BAR.TRANSFERSTATUS': 'Status da transferência de IMSI',
		'ACTION_BAR.RATEPLAN': 'Plano de tarifas',
		'ACTION_BAR.RECREDENTIALTRANSFERSTATUS': 'Status de recredenciamento',
		'ACTION_BAR.M2MPLATFORM': 'Plataforma M2M',
		'ACTION_BAR.ROAMINGCOUNTRY': 'País de roaming',
		'ACTION_BAR.ROAMINGCARRIER': 'Operadora de roaming',
		'ACTION_BAR.SERVICESTATUS': 'Status de 360L',
		'ACTION_BAR.OEM': 'Contas de assinatura',
		'ACTION_BAR.M2MDATEADDED': 'Data de M2M adicionada',
		'ACTION_BAR.M2MINITIALACTIVATIONDATE': 'Data de ativação de M2M',
		//  **  FATURAS  **
		'ACTION_BAR.M2MACCOUNTID': 'ID da conta do M2M',
		'ACTION_BAR.CUSTOMERNAME': 'Nome do cliente',
		'ACTION_BAR.INVOICENUMBER': 'Número da fatura',
		'ACTION_BAR.COUNTRYNAME': 'Nome do país',
		'ACTION_BAR.BILLINGCYCLE': 'Ciclo de cobrança',
		'ACTION_BAR.CUSTOMERID': 'ID do cliente',
		'ACTION_BAR.INVOICEDATE': 'Data da fatura',
		//  **  RELATÓRIOS **
		'ACTION_BAR.M2M_ACC_ID': 'ID da conta do M2M',
		'ACTION_BAR.M2M_PLATFORM': 'Plataforma M2M',
		'ACTION_BAR.PLATFORM': 'Plataforma',
		'ACTION_BAR.FREQUENCY': 'Frequência',
		'ACTION_BAR.REPORT_NAME': 'Nome do relatório',
		'ACTION_BAR.SERVICEPROVIDER': 'Plataforma',
		'ACTION_BAR.REPORTFREQUENCY': 'Frequência',
		//  **  PEDIDO DE SIM  **
		'ACTION_BAR.FILTER_CATEGORY_STATUS': 'Status',
		'ACTION_BAR.FILTER_CATEGORY_PLATFORM': 'Plataforma M2M',
		'ACTION_BAR.FILTER_CATEGORY_SKU': 'SKU',
		'ACTION_BAR.FILTER_CATEGORY_EXPEDITED': 'Emitido',
		//  **  RECURSOS DE SEGURANÇA **
		'ACTION_BAR.FILTER_CATEGORY_M2MACCOUNT': 'Conta do M2M',
		//  **  EMISSÃO DE TÍQUETES **
		'ACTION_BAR.CATEGORIES': 'Categorias',
		'ACTION_BAR.STATUS': 'Status',
		'ACTION_BAR.SEVERITY': 'Gravidade',
		//  ACTION BAR
		'ACTION_BAR.CLEAR_FILTERS': 'Limpar filtros',
		'ACTION_BAR.SELECT_FILTERS': 'Selecionar filtros',
		'ACTION_BAR.EDIT_TABLE_PARAMETERS': 'Revisar parâmetros da tabela',
		'ACTION_BAR.SHOWING': 'Mostrando ',
		'ACTION_BAR.OF': 'de',
		'ACTION_BAR.DOWNLOAD_FILE': 'Baixar arquivo',
		'ACTION_BAR.REFRESH_PAGE': 'Atualizar página',
		//  ******************* MODAL DE FILTROS *****************************
		//  **  ENDPOINTS **
		'FILTER_MODAL.ENDPOINTSTATUS': 'Status',
		'FILTER_MODAL.NETWORKOPERATOR': 'Operador de rede',
		'FILTER_MODAL.NETWORKCOUNTRY': 'País da rede',
		'FILTER_MODAL.NETWORKENTITLEMENT': 'ID do direito de acesso à rede',
		'FILTER_MODAL.NETWORKTYPE': 'Tipo de rede',
		'FILTER_MODAL.TRANSFERSTATUS': 'Status da transferência de IMSI',
		'FILTER_MODAL.RATEPLAN': 'Plano de tarifas',
		'FILTER_MODAL.RECREDENTIALTRANSFERSTATUS': 'Status de recredenciamento',
		'FILTER_MODAL.M2MPLATFORM': 'Plataforma M2M',
		'FILTER_MODAL.ROAMINGCOUNTRY': 'País de roaming',
		'FILTER_MODAL.ROAMINGCARRIER': 'Operadora de roaming',
		'FILTER_MODAL.SERVICESTATUS': 'Status de 360L',
		'FILTER_MODAL.OEM': 'Contas de assinatura',
		'FILTER_MODAL.M2MDATEADDED': 'Data de M2M adicionada',
		'FILTER_MODAL.M2MINITIALACTIVATIONDATE': 'Data de ativação de M2M',
		//  tabs
		'FILTER_MODAL.TABS_ENDPOINTSTATE': 'Status do endpoint',
		'FILTER_MODAL.TABS_NETWORKOPERATOR': 'Operador de rede',
		'FILTER_MODAL.TABS_NETWORKCOUNTRY': 'País da rede',
		'FILTER_MODAL.TABS_NETWORKENTITLEMENT': 'Direito de acesso à rede',
		'FILTER_MODAL.TABS_NETWORKTYPE': 'Tipo de rede',
		'FILTER_MODAL.TABS_TRANSFERSTATUS': 'Status da transferência',
		'FILTER_MODAL.TABS_RATEPLAN': 'Plano de tarifas',
		'FILTER_MODAL.TABS_RECREDENTIALTRANSFERSTATUS': 'Status da transferência de recredenciamento',
		'FILTER_MODAL.TABS_M2MPLATFORM': 'Plataforma M2M',
		'FILTER_MODAL.TABS_ROAMINGCOUNTRY': 'País de roaming',
		'FILTER_MODAL.TABS_ROAMINGCARRIER': 'Operadora de roaming',
		'FILTER_MODAL.TABS_SERVICESTATUS': 'Status de 360L',
		'FILTER_MODAL.TABS_OEM': 'Contas de assinatura',
		'FILTER_MODAL.TABS_M2MDATEADDED': 'Data de M2M adicionada',
		'FILTER_MODAL.TABS_M2MINITIALACTIVATIONDATE': 'Data de ativação de M2M',
		//  **  FATURAS  **
		'FILTER_MODAL.M2MACCOUNTID': 'ID da conta do M2M',
		'FILTER_MODAL.CUSTOMERNAME': 'NOME DO CLIENTE',
		'FILTER_MODAL.INVOICENUMBER': 'NÚMERO DA FATURA',
		'FILTER_MODAL.INVOICEDATE': 'DATA DA FATURA',
		'FILTER_MODAL.COUNTRYNAME': 'NOME DO PAÍS',
		'FILTER_MODAL.CUSTOMERID': 'ID DO CLIENTE',
		'FILTER_MODAL.BILLINGCYCLE': 'CICLO DE COBRANÇA',
		//  tabs
		'FILTER_MODAL.TABS_M2MACCOUNTID': 'ID da conta do M2M',
		'FILTER_MODAL.TABS_CUSTOMERNAME': 'Nome do cliente',
		'FILTER_MODAL.TABS_INVOICENUMBER': 'Número da fatura',
		'FILTER_MODAL.TABS_COUNTRYNAME': 'Nome do país',
		'FILTER_MODAL.TABS_CUSTOMERID': 'ID do cliente',
		'FILTER_MODAL.TABS_BILLINGCYCLE': 'Ciclo de cobrança',
		'FILTER_MODAL.TABS_INVOICEDATE': 'Data da fatura',

		//  **  RELATÓRIOS **
		'FILTER_MODAL.M2M_ACC_ID': 'ID da conta do M2M',
		'FILTER_MODAL.M2M_PLATFORM': 'PLATAFORMA M2M',
		'FILTER_MODAL.FREQUENCY': 'FREQUÊNCIA',
		'FILTER_MODAL.REPORT_NAME': 'NOME DO RELATÓRIO',
		'FILTER_MODAL.PLATFORM': 'PLATAFORMA',
		'FILTER_MODAL.SERVICEPROVIDER': 'PLATAFORMA',
		'FILTER_MODAL.REPORTFREQUENCY': 'FREQUÊNCIA',
		//  tabs
		'FILTER_MODAL.TABS_M2M_ACC_ID': 'ID da conta do M2M',
		'FILTER_MODAL.TABS_M2M_PLATFORM': 'Plataforma M2M',
		'FILTER_MODAL.TABS_FREQUENCY': 'Frequência',
		'FILTER_MODAL.TABS_REPORT_NAME': 'Nome do relatório',
		'FILTER_MODAL.TABS_PLATFORM': 'Plataforma',
		'FILTER_MODAL.TABS_SERVICEPROVIDER': 'Plataforma',
		'FILTER_MODAL.TABS_REPORTFREQUENCY': 'Frequência',
		//  **  PEDIDO DE SIM  **
		'FILTER_MODAL.FILTER_CATEGORY_STATUS': 'STATUS',
		'FILTER_MODAL.FILTER_CATEGORY_PLATFORM': 'PLATAFORMA M2M',
		'FILTER_MODAL.FILTER_CATEGORY_SKU': 'SKU',
		'FILTER_MODAL.FILTER_CATEGORY_EXPEDITED': 'EMITIDO',
		//  tabs
		'FILTER_MODAL.TABS_FILTER_CATEGORY_STATUS': 'Status',
		'FILTER_MODAL.TABS_FILTER_CATEGORY_PLATFORM': 'Plataforma M2M',
		'FILTER_MODAL.TABS_FILTER_CATEGORY_SKU': 'SKU',
		'FILTER_MODAL.TABS_FILTER_CATEGORY_EXPEDITED': 'Emitido',
		//  **  RECURSOS DE SEGURANÇA **
		'FILTER_MODAL.FILTER_CATEGORY_M2MACCOUNT': 'CONTA DO M2M',
		//  tabs
		'FILTER_MODAL.TABS_FILTER_CATEGORY_M2MACCOUNT': 'Conta do M2M',
		//  **  EMISSÃO DE TÍQUETES **
		'FILTER_MODAL.CATEGORIES': 'CATEGORIAS',
		'FILTER_MODAL.STATUS': 'STATUS',
		'FILTER_MODAL.SEVERITY': 'GRAVIDADE',
		//  tabs
		'FILTER_MODAL.TABS_CATEGORIES': 'Categorias',
		'FILTER_MODAL.TABS_STATUS': 'Status',
		'FILTER_MODAL.TABS_SEVERITY': 'Gravidade',
		//  **  DETALHES DAS FATURAS  **
		'FILTER_MODAL.CURRENCY': 'MOEDA',
		'FILTER_MODAL.CUSTOMER': 'CLIENTE',
		'FILTER_MODAL.SUBSCRIBER_STATUS': 'STATUS DO ASSINANTE',
		'FILTER_MODAL.MONTHLY_RATE_PLAN': 'PLANO DE TARIFAS MENSAL',
		'FILTER_MODAL.STANDARD_RATE_PLAN': 'PLANO DE TARIFAS PADRÃO',
		'FILTER_MODAL.PREPAID_TERMS_CHARGED': 'TERMOS PRÉ-PAGOS COBRADOS',
		'FILTER_MODAL.RATE_PLAN': 'PLANO DE TARIFAS',
		'FILTER_MODAL.PLAN_TYPE': 'TIPO DE PLANO',
		'FILTER_MODAL.ZONE': 'ZONA',
		'FILTER_MODAL.PRIORITY': 'PRIORIDADE',
		'FILTER_MODAL.ROAMING_ZONE': 'ZONA DE ROAMING',
		'FILTER_MODAL.GROUP_NAME': 'NOME DO GRUPO',
		'FILTER_MODAL.INVOICE_ID': 'ID DA FATURA',
		'FILTER_MODAL.THE_PLAN_TYPE': 'O TIPO DE PLANO',
		'FILTER_MODAL.ACTIVATION_TYPE': 'TIPO DE ATIVAÇÃO',
		'FILTER_MODAL.CHARGE_TYPE': 'TIPO DE COBRANÇA',
		'FILTER_MODAL.PRIMARY_PLACE': 'LOCAL PRINCIPAL',
		'FILTER_MODAL.TECH_TYPE': 'TIPO TEC',
		'FILTER_MODAL.PRIMARY_PLACE_OF_USE': 'LOCAL PRINCIPAL DE UTILIZAÇÃO',
		'FILTER_MODAL.STATE': 'ESTADO',
		'FILTER_MODAL.CITY': 'CIDADE',
		'FILTER_MODAL.DISTRICT_COUNTY': 'COMARCA DO DISTRITO',
		'FILTER_MODAL.DISTRICT_CITY': 'CIDADE DO DISTRITO',
		'FILTER_MODAL.DESCRIPTION': 'DESCRIÇÃO',
		//  tabs
		'FILTER_MODAL.TABS_CURRENCY': 'Moeda',
		'FILTER_MODAL.TABS_CUSTOMER': 'Cliente',
		'FILTER_MODAL.TABS_SUBSCRIBER_STATUS': 'Status do assinante',
		'FILTER_MODAL.TABS_MONTHLY_RATE_PLAN': 'Plano de tarifas mensal',
		'FILTER_MODAL.TABS_STANDARD_RATE_PLAN': 'Plano de tarifas padrão',
		'FILTER_MODAL.TABS_PREPAID_TERMS_CHARGED': 'Termos pré-pagos cobrados',
		'FILTER_MODAL.TABS_RATE_PLAN': 'Plano de tarifas',
		'FILTER_MODAL.TABS_PLAN_TYPE': 'Tipo de plano',
		'FILTER_MODAL.TABS_ZONE': 'Zona',
		'FILTER_MODAL.TABS_PRIORITY': 'Prioridade',
		'FILTER_MODAL.TABS_ROAMING_ZONE': 'Zona de roaming',
		'FILTER_MODAL.TABS_GROUP_NAME': 'Nome do grupo',
		'FILTER_MODAL.TABS_INVOICE_ID': 'ID da fatura',
		'FILTER_MODAL.TABS_THE_PLAN_TYPE': 'O tipo de plano',
		'FILTER_MODAL.TABS_ACTIVATION_TYPE': 'Tipo de ativação',
		'FILTER_MODAL.TABS_CHARGE_TYPE': 'Tipo de cobrança',
		'FILTER_MODAL.TABS_PRIMARY_PLACE': 'Local principal',
		'FILTER_MODAL.TABS_TECH_TYPE': 'Tipo Tec',
		'FILTER_MODAL.TABS_PRIMARY_PLACE_OF_USE': 'Local principal de utilização',
		'FILTER_MODAL.TABS_STATE': 'Estado',
		'FILTER_MODAL.TABS_CITY': 'Cidade',
		'FILTER_MODAL.TABS_DISTRICT_COUNTY': 'Comarca do distrito',
		'FILTER_MODAL.TABS_DISTRICT_CITY': 'Cidade do distrito',
	  'FILTER_MODAL.TABS_DESCRIPTION': 'Descrição',
	  //  **    AUTOMATIZAÇÃO    **  //
	  'FILTER_MODAL.PLATFORMS': 'PLATAFORMAS',
	  'FILTER_MODAL.TRIGGERS': 'ACIONAMENTOS',
	  'FILTER_MODAL.M2MACCOUNTS': 'CONTAS DE M2M',
	  'FILTER_MODAL.ACTIVE': 'ATIVADOS',
	  'FILTER_MODAL.STATUSES': 'STATUS',
	  //  tabs
	  'FILTER_MODAL.TABS_PLATFORMS': 'Plataformas',
	  'FILTER_MODAL.TABS_TRIGGERS': 'Acionamento',
	  'FILTER_MODAL.TABS_M2MACCOUNTS': 'Conta do M2M',
		'FILTER_MODAL.TABS_ACTIVE': 'Ativado',
		'FILTER_MODAL.TABS_USERGROUPS': 'Grupos de usuários',
	  'FILTER_MODAL.USERGROUPS': 'Grupos de usuários',
	  'FILTER_MODAL.TABS_STATUSES': 'Status',
	  // barra de ação
	  'ACTION_BAR.PLATFORMS': 'Plataformas',
	  'ACTION_BAR.TRIGGERS': 'Acionamento',
	  'ACTION_BAR.M2MACCOUNTS': 'Contas de M2M',
	  'ACTION_BAR.CURRENCY': 'Moeda',
	  'ACTION_BAR.ACTIVE': 'Ativado',
		'ACTION_BAR.CUSTOMER': 'Cliente',
		'ACTION_BAR.SUBSCRIBER_STATUS': 'Status do assinante',
		'ACTION_BAR.MONTHLY_RATE_PLAN': 'Plano de tarifas mensal',
		'ACTION_BAR.STANDARD_RATE_PLAN': 'Plano de tarifas padrão',
		'ACTION_BAR.PREPAID_TERMS_CHARGED': 'Termos pré-pagos cobrados',
		'ACTION_BAR.RATE_PLAN': 'Plano de tarifas',
		'ACTION_BAR.PLAN_TYPE': 'Tipo de plano',
		'ACTION_BAR.ZONE': 'Zona',
		'ACTION_BAR.PRIORITY': 'Prioridade',
		'ACTION_BAR.ROAMING_ZONE': 'Zona de roaming',
		'ACTION_BAR.GROUP_NAME': 'Nome do grupo',
		'ACTION_BAR.INVOICE_ID': 'ID da fatura',
		'ACTION_BAR.THE_PLAN_TYPE': 'O tipo de plano',
		'ACTION_BAR.ACTIVATION_TYPE': 'Tipo de ativação',
		'ACTION_BAR.CHARGE_TYPE': 'Tipo de cobrança',
		'ACTION_BAR.PRIMARY_PLACE': 'Local principal',
		'ACTION_BAR.TECH_TYPE': 'Tipo Tec',
		'ACTION_BAR.PRIMARY_PLACE_OF_USE': 'Local principal de utilização',
		'ACTION_BAR.STATE': 'Estado',
		'ACTION_BAR.CITY': 'Cidade',
		'ACTION_BAR.DISTRICT_COUNTY': 'Comarca do distrito',
		'ACTION_BAR.DISTRICT_CITY': 'Cidade do distrito',
		'ACTION_BAR.DESCRIPTION': 'Descrição',
	  'ACTION_BAR.USERGROUPS': 'Grupos de usuários',
	  'ACTION_BAR.STATUSES': 'Status',
	  // UPLOAD COMPONENT
		'UPLOAD_FILE.MAX_FILE_SIZE': 'Arquivo deve ser menor que {size}MB.',
		'UPLOAD_FILE.EXTENSION_ERROR_TITLE': 'Tipo incorreto do arquivo',
		'UPLOAD_FILE.EXTENSION_ERROR_MESSAGE': 'Somente os seguintes formatos podem ser carregados:',
		'UPLOAD_FILE.FILE_SIZE_ERROR_TITLE': 'O arquivo é muito grande',
		'UPLOAD_FILE.FILE_SIZE_ERROR_MESSAGE': 'Arquivo que você tentou carregar é muito grande: arquivos devem ser menores que.',
	  'UPLOAD_FILE.ALLOWED_FORMATS': 'Formatos permitidos: ',
    'UPLOAD_FILE.ONLY_ONE_FILE_ALLOWED': 'Somente um arquivo é permitido'
  };
