import React from 'react';

function Curl() {
	return (
		<div data-spec="curl-asset">
			<pre>
				{`
          curl -X POST
          https://cisiot.att.com/EAI/oauth/token
          -H 'Authorization: Basic YOUR_ENCODED_CREDENTIALS'
          -H 'Content-Type: application/x-www-form-urlencoded'
          -d 'username=USERNAME&password=PASSWORD&grant_type=password'
        `}
			</pre>
		</div>
	);
}

export default Curl;
