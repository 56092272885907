import { connect } from 'react-redux';
import BatchReportsDesktop from './Desktop';
import BatchReportsMobile from './Mobile';

import {
	getBatchReportsRequest,
	getBatchReportsFail,
	getBatchReportsSuccess,
	downloadReportRequest
} from '../../redux/batchReports/selectors';
import { getBatchReports } from '../../redux/batchReports/actions';

import {
	downloadReportRequestURL
} from '../../../Reports/redux/downloadReport/actions';

const mapStateToProps = (state) => ({
	batchReportsRequest: getBatchReportsRequest(state),
	batchReportsFail: getBatchReportsFail(state),
	batchReports: getBatchReportsSuccess(state),
	downloadReportRequest: downloadReportRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	getBatchReports: (params) => dispatch(getBatchReports(params)),
	downloadReport: (
		fullReportFileName,
		reportFrequency,
		serviceProvider,
		year,
		month,
		day,
		reportCategory,
		m2mAccountId
	) => dispatch(
			downloadReportRequestURL({
				reportFileName: fullReportFileName,
				reportFrequency,
				reportCategory,
				serviceProvider,
				yearOfGeneration: year,
				monthOfGeneration: month,
				dateOfGeneration: day,
				m2mAccountId
			})
		)
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(BatchReportsDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(BatchReportsMobile);
