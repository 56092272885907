export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': 'Gedefinieerde grafiekinstelling verwijderen?',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': 'Weet u zeker dat u instelling {settingName} wilt verwijderen?',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': 'Huidige beschikbare grafieken',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': 'Deze grafiek bestaat al in uw instellingen. Er is slechts één type grafiek toegestaan.',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': 'Geen beschrijving voor deze grafiek',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': 'Type grafiek',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': 'Grafiekdata exporteren',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': 'Grafiek verwijderen',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': 'Instellingen grafiek',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': 'Grafiek toevoegen',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': 'Nieuwe grafiek is toegevoegd',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': 'Toevoegen nieuwe grafiek mislukt',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': 'Grafiek is geüpdatet',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': 'Updaten grafiek is mislukt',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': 'Grafiekinstellingen zijn verwijderd',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': 'Verwijderen grafiekinstellingen is mislukt'
};
