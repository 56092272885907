import { connect } from 'react-redux';

import ActionBarDesktop from './Desktop';
import ActionBarMobile from './Mobile';

import filterDataSuccess from '../../redux/filterModal/filterData/selectors';
import { getFiltersSuccess } from '../../redux/filterModal/selectors';

import resetFilterSuccess from '../../redux/filterModal/resetFilter/selectors';
import { setFilterData } from '../../redux/filterModal/filterData/actions';
import { setOriginalFilter } from '../../redux/filterModal/originalFilter/actions';
import {
	getSimpleSearchParameter,
	getIsSimpleSearchActive
} from '../../redux/simpleSearch/selectors';

import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';

import { getAllUserSettings } from '../../../../redux/user/getUserData/selectors';
import { setPaginationInit } from '../../redux/pagination/actions';
import { getSearchParameter } from '../../redux/searchBar/selectors';

const mapStateToProps = (state) => ({
	filterData: filterDataSuccess(state),
	resetFilter: resetFilterSuccess(state),
	simpleSearchParameter: getSimpleSearchParameter(state),
	isSimpleSearchActive: getIsSimpleSearchActive(state),
	userSettings: getAllUserSettings(state),
  filters: getFiltersSuccess(state),
  searchParameter: getSearchParameter(state)
});

const mapDispatchToProps = (dispatch) => ({
	setFilterData: (filterData) => dispatch(setFilterData(filterData)),
	setOriginalFilter: (filterData) => dispatch(setOriginalFilter(filterData)),
	updateUserSettings: (data, id) => dispatch(updateUserSettings(data, id)),
  setPaginationInit: () => dispatch(setPaginationInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(ActionBarDesktop);
export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(ActionBarMobile);
