import React, { PureComponent } from 'react';

const ActionBar = () => (WrappedComponent) => {
	class ActionBarComponent extends PureComponent {
		render() {
			return (
				<WrappedComponent
					{...this.state}
					{...this.props}
					data-spec="action-bar"
				/>
			);
		}
	}
	return ActionBarComponent;
};

export default ActionBar;
