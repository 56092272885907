import { combineReducers } from 'redux';
import globalModalReducer from '../../../../redux/global/globalModalReducer';
import globalReducer from '../../../../redux/global/globalReducer';

import {
	ADD_USER_GROUP_MODAL,
	GET_USER_GROUPS,
	GET_USER_GROUP,
	ADD_USER_GROUP,
	SUSPEND_USER_GROUP,
	REACTIVATE_USER_GROUP
} from './constants';

export default combineReducers({
	addUserGroupModal: globalModalReducer(ADD_USER_GROUP_MODAL),
	getUserGroups: globalReducer(GET_USER_GROUPS),
	getUserGroup: globalReducer(GET_USER_GROUP),
	addUserGroup: globalReducer(ADD_USER_GROUP),
	getSuspendUserGroup: globalReducer(SUSPEND_USER_GROUP),
	getReactivateUserGroup: globalReducer(REACTIVATE_USER_GROUP),
});
