import React, { PureComponent } from 'react';
import PropTypes, { bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';
import ActionBar from '../../../Shared/views/ActionBarView';
import FilterModal from '../../../Shared/components/FilterModal';
import DeleteRuleModal from '../DeleteRuleModal';
import {
	localizationHelper,
	objectPropertiesValidation,
} from '../../../../utils/helperFunctions';
import { getDateFormat } from '../../../../utils/constants';
import { filterHelpers, newOnPageChange } from '../../../../utils/tableHelpers';

import { getAutomationAlertsFilters as getAutomationAlertsFiltersService } from '../../services/AutomationService';

import {
	alertsTableHeadersDesktop,
	alertsTableHeadersMobile,
} from '../../utils/tableHeaders';

import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

import styles from './Alerts.scss';

const Alerts = () => (WrappedComponent) => {
	class AlertsComponent extends PureComponent {
		componentDidMount() {
			this.getAlerts();
		}

		componentDidUpdate(prevProps) {
			const { removeAlertSuccess } = this.props;
			if (
				removeAlertSuccess &&
				prevProps.removeAlertSuccess !== removeAlertSuccess
			) {
				this.getAlerts();
			}
		}

		getAlerts = () => {
			const { dataLimit, dataOffset, filterData, getAlerts } = this.props;

			const realProps = {
				categories: 'automationCategoryId',
				platforms: 'automationPlatformId',
				triggers: 'automationTriggerId',
			};
			let searchParams = [];

			if (filterData) {
				searchParams = filterHelpers(filterData, realProps);
			}

			const params = {
				additionalParams: {
					include: [
						'AutomationRule',
						'AutomationTrigger',
						'AutomationPlatform',
						'AutomationCategory',
						'AutomationRule.AutomationAction',
						'AutomationRule.AutomationAction.AutomationActionType',
					],
					dataSort: 'createdAt desc',
					dataLimit,
					dataOffset,
				},
				searchParams,
			};
			getAlerts(params);
		};

		totalCount = () => {
			const { totalCount, dataLimit } = this.props;
			return Math.ceil(totalCount / dataLimit);
		};

		renderRule = (data) => (
			<div className={styles.rule_name}>
				{data && data.automationRule && data.automationRule.name}
			</div>
		);

		renderPlatform = (data) => {
			const localizationId = localizationHelper(data.automationPlatform.name);
			return (
				<span data-spec="platform-name">
					<FormattedMessage
						id={`AUTOMATION.${localizationId}`}
						defaultMessage={data.automationPlatform.name}
					/>
				</span>
			);
		};

		renderCategory = (data) => {
			const localizationId = localizationHelper(data.automationCategory.name);
			return (
				<span data-spec="platform-name">
					<FormattedMessage
						id={`AUTOMATION.${localizationId}`}
						defaultMessage={data.automationCategory.name}
					/>
				</span>
			);
		};

		renderTrigger = (data) => {
			const localizationId = localizationHelper(data.automationTrigger.name);
			return (
				<span data-spec="platform-name">
					<FormattedMessage
						id={`AUTOMATION.${localizationId}`}
						defaultMessage={data.automationTrigger.name}
					/>
				</span>
			);
		};

		renderDate = (data) => getDateFormat(data.alertDate);

		renderM2mAccountId = (data) => (
			<div className={styles.rule_name}>{data.automationRule.m2mAccountId}</div>
		);

		renderActionName = (data) => {
			const localizationId = localizationHelper(
				data.automationRule.automationAction.automationActionType.name
			);
			return (
				<div className={styles.rule_name} data-spec="action_name">
					{objectPropertiesValidation(
						data,
						'automationRule.automationAction.automationActionType.name'
					) ? (
						<FormattedMessage
							id={`AUTOMATION.${localizationId}`}
							defaultMessage={
								data.automationRule.automationAction.automationActionType.name
							}
						/>
					) : (
						''
					)}
				</div>
			);
		};

		onCheckBoxChange = (data) => {
			const { alerts, setSelectedItems } = this.props;
			let selectedIndexes = [];
			if (data && data.selected) {
				selectedIndexes = data.selected.map(
					(selectedItem) =>
						alerts && alerts[selectedItem] && alerts[selectedItem].id
				);
			}
			setSelectedItems([...selectedIndexes]);
		};

		isRWComponent = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(['mnc.automation_rw'], user);
		};

		getOptions = (target) => {
			const {
				totalCount,
				dataLimit,
				selectedPage,
				setDataOffset,
				setSelectedPage,
			} = this.props;
			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {
				tableOptions: {
					showPagination: totalCount > dataLimit,
					pageCount: this.totalCount(),
					forcePage: selectedPage,
					onPageChange: (data) =>
						newOnPageChange(
							data,
							this.getAlerts,
							dataLimit,
							setDataOffset,
							setSelectedPage
						),
				},
				customComponents: {
					platform: {
						type: 'custom',
						component: (val) => this.renderPlatform(val),
					},
					category: {
						type: 'custom',
						component: (val) => this.renderCategory(val),
					},
					trigger: {
						type: 'custom',
						component: (val) => this.renderTrigger(val),
					},
					alertDate: {
						type: 'custom',
						component: (val) => this.renderDate(val),
					},
				},
			};

			if (isMobile) {
				options.header = alertsTableHeadersMobile;
				options.tableOptions = {
					...options.tableOptions,
					headerNameComponent: (val) => this.renderRule(val),
				};
			}

			if (isDesktop) {
				options.header = alertsTableHeadersDesktop;
				options.tableOptions = {
					...options.tableOptions,
					selectRows: this.isRWComponent(),
					onCheckBoxChange: (data) => this.onCheckBoxChange(data),
				};
				options.customComponents = {
					...options.customComponents,
					name: {
						type: 'custom',
						component: (val) => this.renderRule(val),
					},
					m2mAccountId: {
						type: 'custom',
						component: (val) => this.renderM2mAccountId(val),
					},
					actionName: {
						type: 'custom',
						component: (val) => this.renderActionName(val),
					},
				};
			}

			return options;
		};

		createFilterData = (data) => {
			const filterDataKeys = Object.keys(data);
			let filter = {};

			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					if (item === 'm2mAccounts') {
						filter = {
							...filter,
							[`${item}`]: data[item].m2mAccounts
								.filter(
									(m2mAccount) =>
										m2mAccount.platformName &&
										['ACC', 'DCP', 'WING', 'GMNA', 'POD3', 'POD19'].includes(
											m2mAccount.platformName.toUpperCase()
										)
								)
								.map((m2mAccount) => ({
									value: m2mAccount.m2mAccountId,
									label: m2mAccount.m2mAccountId,
									checked: false,
								})),
						};
					} else {
						filter = {
							...filter,
							[`${item}`]: data[item].resultList.map((filterItem) => ({
								value: filterItem.id,
								label: filterItem.automationPlatform
									? `${filterItem.name} ${filterItem.automationPlatform.name}`
									: `${filterItem.name}`,
								checked: false,
							})),
						};
					}
				});
			return filter;
		};

		deleteSelectedAlerts = () => {
			const { selectedItems, setSelectedItemsInit, deleteAlert } = this.props;
			deleteAlert(selectedItems);
			setSelectedItemsInit();
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		onApply = () => {
			const { setSelectedItemsInit } = this.props;
			setSelectedItemsInit();
			this.getAlerts();
		};

		clearFilters = () => {
			const { setSelectedItemsInit } = this.props;
			setSelectedItemsInit();
			this.getAlerts();
		};

		onChange = () => {
			const { setSelectedItemsInit } = this.props;
			setSelectedItemsInit();
			this.getAlerts();
		};

		render() {
			const {
				alertsRequest,
				alerts,
				deleteAlertsModalOpened,
				closeDeleteAlertsModal,
				deleteAlertRequest,
				messages,
			} = this.props;
			const isAnyRequest = alertsRequest || deleteAlertRequest;
			const isDataEmpty = alerts && alerts.length === 0;

			const params = {
				service: getAutomationAlertsFiltersService,
				createFilterData: this.createFilterData,
			};

			return (
				<div data-spec="automation-alerts">
					<ActionBar
						openFilterModal={this.openFilterModal}
						clearFilters={this.clearFilters}
						onChange={this.onChange}
						isDisabled={isAnyRequest}
						isDataEmpty={isDataEmpty}
					/>
					<FilterModal
						type="checkbox"
						messages={messages}
						onApply={this.onApply}
						params={params}
					/>
					<DeleteRuleModal
						show={deleteAlertsModalOpened}
						onCancel={closeDeleteAlertsModal}
						onClick={this.deleteSelectedAlerts}
						modalHeader={
							<FormattedMessage
								id="AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION"
								defaultMessage="Delete selected automation rules?"
							/>
						}
						modalMessage={
							<FormattedMessage
								id="AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE"
								defaultMessage="Are you sure you would like to delete selected automation rules?"
							/>
						}
					/>
					{isAnyRequest && <Loader />}
					{!isAnyRequest && alerts && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							options={this.getOptions}
						/>
					)}
					{!isAnyRequest && isDataEmpty && (
						<NoData
							table
							title={
								<FormattedMessage
									id="AUTOMATION_ALERTS.NO_DATA_TITLE"
									defaultMessage="No Automation Alerts Available"
								/>
							}
							subtitle={
								<FormattedMessage
									id="AUTOMATION_ALERTS.NO_DATA_MESSAGE"
									defaultMessage="There are No Automation Alerts to show you right now"
								/>
							}
						/>
					)}
				</div>
			);
		}
	}

	const { number, func, object, array } = PropTypes;

	AlertsComponent.propTypes = {
		dataLimit: number,
		getAlerts: func,
		deleteAlert: func,
		setSelectedItems: func,
		setSelectedItemsInit: func,
		setDataOffset: func,
		setSelectedPage: func,
		alertsRequest: bool,
		alertsFail: bool,
		alerts: array,
		totalCount: number,
		deleteAlertRequest: bool,
		removeAlertSuccess: bool,
		filterData: object,
		dataOffset: number,
		selectedPage: number,
		user: object,
		deleteAlertsModalOpened: bool,
		closeDeleteAlertsModal: func,
		selectedItems: array,
		openFilterModal: func,
		messages: object,
	};

	return AlertsComponent;
};
export default Alerts;
