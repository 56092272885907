import { connect } from 'react-redux';

import UserGroupPermissions from './UserGroupPermissions';
import { getUserGroupPermissions } from '../../redux/userGroupPermissions/actions';
import {
	getUserGroupPermissionsRequest,
	getUserGroupPermissionsSuccess
} from '../../redux/userGroupPermissions/selectors';

const mapStateToProps = (state) => ({
	permissionsRequest: getUserGroupPermissionsRequest(state),
	permissions: getUserGroupPermissionsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getPermissions: (params) => dispatch(getUserGroupPermissions(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserGroupPermissions);
