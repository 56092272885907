export default {
	'TICKETING.YES': 'Yes',
	'TICKETING.NO': 'No',
	'TICKETING.VIEW_NOTE': 'View Note',
	'TICKETING.TICKET_ID': 'Ticket ID',
	'TICKETING.CATEGORY_NAME': 'Category',
	'TICKETING.DATE_CREATED': 'Date Created',
	'TICKETING.CREATED_BY': 'Created By',
	'TICKETING.TICKETING_STATUS': 'Ticketing Status',
	'TICKETING.LAST_UPDATE': 'Last Update',
	'TICKETING.SEVERITY': 'Severity',
	'TICKETING.SUBMISSION_LOCATION': 'Submission Location',
	'TICKETING.UPDATE_NOTE': 'Update Note',
	'TICKETING.STATUS': 'Status',
	'TICKETING.SUBMITED_BY': 'Submited By',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO':
		'Is your ticket related to a specific endpoint? If so, go to that endpoint details screen and click Get support for this endpoint link. Otherwise, fill out the form below.',
	'TICKETING.ISSUE': 'Issue',
	'TICKETING.TICKET_DETAILS': 'Ticket Details',
	'TICKETING.CATEGORY': 'Category',
	'TICKETING.SELECT_CATEGORY': 'Select category',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Subcategory (optional)',
	'TICKETING.SUBCATEGORY': 'Subcategory',
	'TICKETING.ROAMING_ISSUE': 'Roaming Issue',
	'TICKETING.PRIORITY': 'Priority',
	'TICKETING.ENDPOINT': 'Endpoint',
	'TICKETING.ICCID': 'ICCID',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'MSISDN',
	'TICKETING.ENDPOINT_LOCATION': 'Endpoint Location',
	'TICKETING.ENDPOINT_COUNTRY': 'Endpoint Country',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Clear endpoint parameters',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Fetch endpoint parameters',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC':
		'You can use this option to fetch endpoint parameters by entering either ICCID or IMSI or MSISDN value. ',
	'TICKETING.ENDPOINT_STREET': 'Endpoint Street',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Endpoint Street Address',
	'TICKETING.ZIP_CODE': 'ZIP Code',
	'TICKETING.ENDPOINT_STATE': 'Endpoint State',
	'TICKETING.DETAILS': 'Details',
	'TICKETING.BARS_SHOWN': 'Bars Shown',
	'TICKETING.ENDPOINT_CITY': 'Endpoint City',
	'TICKETING.ENDPOINT_MODEL': 'Endpoint model',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': 'Was troubleshooting done?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Problem Description',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX':
		'Provide a short description of the problem 255 characters maximum',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX':
		'Provide additional issue details, if needed 2000 characters maximum',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Notes',
	'TICKETING.ISSUE_DETAILS': 'Issue Details',
	'TICKETING.CUSTOMER': 'Customer',
	'TICKETING.ACCOUNT_NUMBER': 'Account Number',
	'TICKETING.ACCOUNT_NAME': 'Account Name',
	'TICKETING.PHONE': 'Phone',
	'TICKETING.CONTACT_PHONE': 'Contact phone',
	'TICKETING.CONTACT_EMAIL': 'Contact email',
	'TICKETING.ATTACH_FILE': 'Attach File',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG':
		'File name is too long for upload. Maximum file name length is 25 characters.',
	'TICKETING.EMAIL': 'Email',
	'TICKETING.TICKETING': 'Ticketing',
	'TICKETING.ADD_TICKET': 'Add Ticket',
	'TICKETING.SEARCH_RESULTS': 'Search Results',
	'TICKETING.NO_TICKETS_AVAILABLE': 'No Tickets Available',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE':
		'Tickets table settings change success!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE':
		'Tickets table settings have been successfully updated.',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE':
		'Tickets table columns change error!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE':
		'Tickets table columns could not be updated this time. Please try again.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'Ticket has been sucessfully edited.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET':
		'Error while editing ticket. Please try again later.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'File name should not contain empty spaces.',
	'TICKETING.NEW_TICKET': 'New Ticket',
	'TICKETING.OFF': 'Off',
	'TICKETING.ON': 'On',
	'TICKETING.EMAIL_NOTIFICATIONS': 'Email notifications',
	'TICKETING.CONTACT_NAME': 'Contact Name',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Last Updated By',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Last Updated Date/Time',
	'TICKETING.SOMETHING_WENT_WRONG': 'Something went wrong, please try again.',
	'TICKETING.CANCEL_TICKET': 'Cancel ticket',
	'TICKETING.ADD_NOTE': 'Add Note',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'Ticket submitted successfully!',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED':
		'Ticket could not be submitted successfully!',
	'TICKETING.CREATED_AT': 'Created at: ',
	'TICKETING.GO_BACK_TO_TICKETING': 'Go back to ticketing',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE':
		'Billing Non Technical Issue',
	'TICKETING.CATEGORY_PROVISIONING': 'Provisioning',
	'TICKETING.CATEGORY_DATA': 'Data',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Documentation',
	'TICKETING.CATEGORY_DUPLICATE': 'Duplicate',
	'TICKETING.CATEGORY_BILLING': 'Billing',
	'TICKETING.CATEGORY_NOTIFICATION': 'Notification',
	'TICKETING.CATEGORY_OTHER': 'Other',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST':
		'Product Enhancement Request',
	'TICKETING.CATEGORY_PROJECT': 'Project',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Sales/Marketing Issue',
	'TICKETING.CATEGORY_QUESTION': 'Question',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Service Request',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Technical Issues',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION':
		'User/Account Administration',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Connectivity Issue',
	'TICKETING.CATEGORY_EQUIPMENT': 'Equipment',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'General Question',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Lost/Stolen Device',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Port Request',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'Product Related',
	'TICKETING.CATEGORY_REPORTING': 'Reporting',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Suggestion/Recommendation',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Website Issue',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': 'Queued',
	'TICKETING.STATUS_ACTIVE': 'Active',
	'TICKETING.STATUS_DEFERRED': 'Deferred',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'Pending Customer Response',
	'TICKETING.STATUS_CLOSED': 'Closed',
	'TICKETING.STATUS_CANCEL': 'Cancel',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Customer Contested',
	'TICKETING.STATUS_CLEARED': 'Cleared',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Ready to Close',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'Pending ATT Response',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': 'Critical',
	'TICKETING.SEVERITY_MAJOR': 'Major',
	'TICKETING.SEVERITY_MINOR': 'Minor',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': 'Activation Fee',
	'TICKETING.AIRTIME_USAGE': 'Airtime Usage',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Basic Account Questions',
	'TICKETING.BILLING_DISPUTES': 'Billing Disputes',
	'TICKETING.BILLING_FILES_ISSUES': 'Billing Files Issues',
	'TICKETING.BILL_RECONCILIATION': 'Bill Reconciliation',
	'TICKETING.CUSTOMER_EDUCATION': 'Customer Education',
	'TICKETING.DATA_USAGE_CANADA': 'Data Usage Canada',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Data Usage International',
	'TICKETING.DATA_USAGE_OFF_NET': 'Data Usage Off Net',
	'TICKETING.DATA_USAGE_ON_NET': 'Data Usage On Net',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Delayed Billing or Invoice',
	'TICKETING.DISCOUNT': 'Discount',
	'TICKETING.DISPUTE': 'Dispute',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Fraud Inquiry or Problem',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Invoice Print Issues',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Monthly Recurring Charge',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'One Time Charge - adjustment',
	'TICKETING.OTHER': 'Other',
	'TICKETING.PAYMENT_ISSUES': 'Payment Issues',
	'TICKETING.PRORATED_CHARGES': 'Prorated Charges',
	'TICKETING.REACTIVATION_FEE': 'Reactivation Fee',
	'TICKETING.SMS_TEXT_CANADA': 'SMS/Text Canada',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'SMS/Text International',
	'TICKETING.SMS_TEXT_OFF_NET': 'SMS/Text Off Net',
	'TICKETING.SMS_TEXT_ON_NET': 'SMS/Text On Net',
	'TICKETING.STATUS_INVOICE': 'Status Invoice',
	'TICKETING.TAXES_AND_SURCHARGES': 'Taxes and Surcharges',
	'TICKETING.DATA': 'Data',
	'TICKETING.MESSAGING_SMS_MMS_': 'Messaging (SMS/MMS)',
	'TICKETING.VOICE': 'Voice',
	'TICKETING.CLARIFICATION': 'Clarification',
	'TICKETING.PREPAID': 'Prepaid',
	'TICKETING.USAGE_INQUIRY': 'Usage Inquiry',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': 'Billing Question',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Invoice-Related Issue',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Rate Plan-Related Issue',
	'TICKETING.USAGE_RELATED_ISSUE': 'Usage-Related Issue',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': '2G & 3G Mix',
	'TICKETING.2G_ONLY': '2G ONLY',
	'TICKETING.3G_ONLY': '3G ONLY',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Connected, But Not Passing Data',
	'TICKETING.FRAME_RELAY_PVC': 'Frame Relay/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Latency issue',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Roaming/Domestic',
	'TICKETING.ROAMING_INTERNATIONAL': 'Roaming/International',
	'TICKETING.SIGNAL_ISSUES': 'Signal Issues',
	'TICKETING.SMPP_BIND': 'SMPP Bind',
	'TICKETING.SMS': 'SMS',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': 'Feedback',
	'TICKETING.NEW_REQUEST': 'New Request',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': 'Compatibility',
	'TICKETING.DEVICE_UNLOCK': 'Device Unlock',
	'TICKETING.ORDERING': 'Ordering',
	// TICKETING LOST/STOLEN DEVICE SUBCATEGORIES
	'TICKETING.BLOCK_DEVICE': 'Block Device',
	'TICKETING.FRAUD_REPORT': 'Fraud Report',
	'TICKETING.UNBLOCK_DEVICE': 'Unblock Device',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': 'Incident Notification',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Scheduled Maintenance',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Network Channel Interface (NCI)',
	'TICKETING.WIRELESS': 'Wireless',
	'TICKETING.WIRELINE': 'Wireline',
	'TICKETING.PORT_IN': 'Port In',
	'TICKETING.PORT_OUT': 'Port Out',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': 'Question',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': 'Batch files',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Create new SIM profile',
	'TICKETING.EQUIPMENT_INQUIRY': 'Equipment Inquiry',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Single SIM provisioning',
	'TICKETING.WEB_SERVICE': 'Web service',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'Provisioning SIMs/features',
	'TICKETING.ACTIVATION': 'Activation',
	'TICKETING.BATCH_BULK_CHANGE': 'Batch/Bulk Change',
	'TICKETING.CALL_FORWARDING': 'Call Forwarding',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Change Communication Plan',
	'TICKETING.CHANGE_RATE_PLAN': 'Change Rate Plan',
	'TICKETING.CHANGE_SIM_STATUS': 'Change SIM Status',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'Transfer SIM between Accounts',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Automation Rule Question',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Control Center Question',
	'TICKETING.COVERAGE_QUESTION': 'Coverage Question',
	'TICKETING.GENERAL_QUESTION': 'General Question',
	'TICKETING.OUTAGE_QUESTION': 'Outage Question',
	'TICKETING.ROAMING_QUESTION': 'Roaming Question',
	'TICKETING.JPO_COVERAGE': 'JPO Coverage',
	//  TICKETING SALES/MARKETING ISSUE SUBCATEGORIES
	'TICKETING.CUSTOMER_INQUIRY': 'Customer Inquiry',
	'TICKETING.DEV_KIT': 'Dev Kit',
	'TICKETING.MARKETING_INQUIRY': 'Marketing Inquiry',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': 'Account Termination',
	'TICKETING.API_SETUP': 'API setup',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION':
		'Billing Zone Creation/Modification',
	'TICKETING.CC_ACCESS_UPDATES': 'CC access/updates',
	'TICKETING.COMMUNICATION_PLAN': 'Communication Plan',
	'TICKETING.CUSTOM_APN': 'Custom APN',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Global Sim Setup/Mapping',
	'TICKETING.GPRS_VPN': 'GPRS VPN',
	'TICKETING.LEGAL_SUBPOENA': 'Legal/subpoena',
	'TICKETING.LTE_MIGRATION': 'LTE Migration',
	'TICKETING.MSISDN_UPDATES': 'MSISDN Updates',
	'TICKETING.NEW_ACCOUNT': 'New Account',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Radius config setup',
	'TICKETING.REPORT': 'Report',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES (Roam Zone Request)',
	'TICKETING.SECURESIM': 'SecureSIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'Sim Order/Provisioning',
	'TICKETING.TRACE': 'Trace',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': 'Application Issues',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Billing Technical Issue',
	'TICKETING.CONNECTIVITY': 'Connectivity',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Global Sim Issue',
	'TICKETING.JPO_FILE_ISSUE': 'JPO File Issue',
	'TICKETING.JPO_LINK_ISSUE': 'JPO Link Issue',
	'TICKETING.NOC_BRIDGE': 'NOC Bridge',
	'TICKETING.CDR_FILES': 'CDR Files',
	'TICKETING.JPO_REPORT': 'JPO Report',
	//  TICKETING USER/ACCOUNT ADMINISTRATION SUBCATEGORIES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS':
		'Add new account functionality options',
	'TICKETING.DEACTIVATE_USER': 'Deactivate user',
	'TICKETING.ID_ISSUE': 'ID issue',
	'TICKETING.NEW_USER': 'New User',
	'TICKETING.PASSWORD_RESET': 'Password Reset',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'AT&T Control Center (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Connected Car Portal/Apollo',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise on Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	'TICKETING.IOT_CONSOLE_IOT_C_': 'IoT Console (IOT-C)',
	'TICKETING.NAVIGATION_QUESTION': 'Navigation Question',
  'TICKETING.UNABLE_TO_COMPLETE_TRANSACTION': 'Unable to Complete Transaction',
  'TICKETING.REGISTER_FOR_TRIAL': 'Register for Trial',
	// OTHER
	'TICKETING.CRITICAL': 'Critical',
	'TICKETING.MAJOR': 'Major',
	'TICKETING.MINOR': 'Minor',
	'TICKETING.LOW': 'Low',
	'TICKETING.MEDIUM': 'Medium',
	'TICKETING.HIGH': 'High',
	'TICKETING.OUTAGE': 'Outage',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': 'Dynamic IP',
	'TICKETING.FIXED_IP': 'Fixed IP',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Automation Rule',
	'TICKETING.CC_ISSUE_OTHER': 'CC Issue - Other',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Export and Batch Update',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Location Services/3rd Party',
	'TICKETING.PROVISIONING': 'Provisioning',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Session information Delay',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': 'WebUl Issues',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': 'Data issue',
	'TICKETING.DEVICE_ISSUE': 'Device Issue',
	'TICKETING.SFTP_FILE_TRANSFERS': 'sFTP/File Transfers',
	'TICKETING.SMS_ISSUE': 'SMS Issue',
	'TICKETING.VOICE_ISSUE': 'Voice Issue',
	'TICKETING.CARRIER_SWAP': 'Carrier Swap',
	'TICKETING.COVERAGE': 'Coverage',
	'TICKETING.INTERMITTENT_CONNECTION': 'Intermittent Connection',
	'TICKETING.LATENCY_DECREASED_SPEED': 'Latency/Decreased Speed',
	'TICKETING.UNABLE_TO_CONNECT': 'Unable to Connect',
	'TICKETING.CANNOT_RECEIVE_MESSAGES': 'Cannot Receive Messages',
	'TICKETING.CANNOT_SEND_MESSAGES': 'Cannot Send Messages',
	'TICKETING.CANNOT_SEND_OR_RECEIVE_MESSAGES':
		'Cannot Send or Receive Messages',
	'TICKETING.DROPPED_CALL(S_': 'Dropped Call(s)',
	'TICKETING.UNABLE_TO_MAKE_CALL(S_': 'Unable to Make Call(s)',
	'TICKETING.UNABLE_TO_MAKE_OR_RECEIVE_CALL(S_':
		'Unable to Make or Receive Call(s)',
	'TICKETING.UNABLE_TO_RECEIVE_CALL(S_': 'Unable to Receive Call(s)',
	'TICKETING.VOICEMAIL': 'Voicemail',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': 'Network',
	'TICKETING.RADIUS': 'Radius',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Add Attachment',
	'TICKETING.NOTE': 'Note',
	'TICKETING.LAST_UPDATED_DATE': 'Ticket last updated date',
	'TICKETING.ENDPOINT_WITH_PARAMETAR':
		'Endpoint with that parameter does not exist.',
	// ticketing notifications
	'TICKETING.ATTACHMENT_FAIL_TITLE':
		'Could not complete your request entirely.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE':
		'Ticket was created {ticketId}, but the attachments ({attachments}) were not uploaded',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE':
		'Ticket was edited {ticketId}, but the attachments ({attachments}) were not uploaded.',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE':
		'Ticket was edited {ticketId}, but the attachments ({attachments}) were not deleted.',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE':
		'Ticket was edited {ticketId}, but the attachments ({deleteAttachments}) were not deleted and attachments ({uploadAttachments}) were not uploaded.',
	'TICKETING.ERROR_MESSAGE': 'Error Message',
	'TICKETING.LOGINS': 'Logins',
};
