import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import BatchFileUpload from '../BatchFileUpload';

import Button from '../../../../../lib/DigitalComponents/Button';
import Loading from '../../../../../lib/DigitalComponents/Loader';

import { fetchAllTemplates as fetchAllTemplatesAction } from '../../../../../redux/batchFiles/templateDownload/actions';
import {
	batchFileRequestURL,
	clearUploadErrors as clearUploadErrorsAction,
	getBatchFiles
} from '../../../../../redux/batchFiles/actions';
import {
	getAllTemplates,
	getIsDownloadingTemplate,
	getIsFetchingTemplates
} from '../../../../../redux/batchFiles/templateDownload/selectors';
import {
	getBatchFileUploadFail,
	getBatchFileUploadSuccess
} from '../../../../../redux/batchFiles/selectors';

const dateFormat = 'yyyy-MM-dd';

class StepFirst extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
    const { fetchAllTemplates, clearUploadErrors, context } = this.props;
		fetchAllTemplates();
		clearUploadErrors();

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
    const { context, formValues } = this.props;
		if (
			context &&
			!isEqual(formValues, prevProps.formValues)
		) {
			this.setFooter(context);
		}
	}

	handleOnClick = (value) => {
    const { clearUploadErrors, onNext } = this.props;
		const date = value[2]
			? format(value[2].date, dateFormat)
			: format(new Date(), dateFormat);
		const file = value.files[0];
		const uploadBody = {
			request: {
				fileName: value.fileName,
				// todo - refactor: this to display real file type
				fileType: '.csv',
				scheduledDate: date
			},
			file
		};
		this.setState({
			uploadBody
		});
		if (!value.isActivationFile) {
			clearUploadErrors();
			onNext(uploadBody);
		}
	};

	onNext = () => {
    const { clearUploadErrors, onNext } = this.props;
    const { uploadBody } = this.state;
		const uploadFileBody = {
			...uploadBody
		};

		const { formValues } = this.props;

		if (formValues && Object.keys(formValues).length === 2) {
			uploadFileBody.activationFile = {
				m2mAccountId: formValues.m2mAccountId.value,
				m2mPlatform: formValues.m2mPlatform.value
			};
			clearUploadErrors();
			onNext(uploadFileBody);
		}
	};

	setFooter = (context) => {
		const { formValues, onClose } = this.props;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={() => onClose()}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
					dataSpec="cancel-button"
				/>
				<Button
					variant="primary"
					onClick={() => this.onNext()}
					disabled={
						!formValues || (formValues && Object.keys(formValues).length !== 2)
					}
					label={<FormattedMessage id="CONTINUE" defaultMessage="Continue" />}
					dataSpec="continue-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
    const {
			isTemplateDownloading,
			type,
			templates,
			downloadTemplate,
			bulkInstructionsCallback
		} = this.props;
		return (
			<div data-spec="batch-upload-first-step">
				<div data-spec="ds-modal-body">
					{isTemplateDownloading && (
						<Loading data-spec="template-body-loading" />
					)}

					{!isTemplateDownloading && (
						<BatchFileUpload
							type={type}
							onClick={(value) => this.handleOnClick(value)}
							templates={templates}
							downloadTemplate={downloadTemplate}
							bulkInstructionsCallback={bulkInstructionsCallback}
						/>
					)}
				</div>
			</div>
		);
	}
}

const { func, any, bool, object, string } = PropTypes;

StepFirst.propTypes = {
	onNext: any,
	onClose: func,
	templates: any,
	fetchAllTemplates: func,
	downloadTemplate: func,
	clearUploadErrors: func,
	isTemplateDownloading: bool,
	formValues: object,
	context: object,
	type: string,
	bulkInstructionsCallback: func
};

const mapStateToProps = (state) => ({
		batchFileUploadSuccess: getBatchFileUploadSuccess(state),
		batchFileUploadFail: getBatchFileUploadFail(state),
		templates: getAllTemplates(state),
		isFetchingTemplates: getIsFetchingTemplates(state),
		isTemplateDownloading: getIsDownloadingTemplate(state),
		formValues: getFormValues('activationFileForm')(state)
	});

const mapDispatchToProps = (dispatch) => ({
	clearUploadErrors: () => dispatch(clearUploadErrorsAction()),
	batchFileRequestURL: (data) => dispatch(batchFileRequestURL(data)),
	getBatchFiles: (paginationData) => dispatch(getBatchFiles(paginationData)),
	fetchAllTemplates: () => dispatch(fetchAllTemplatesAction())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepFirst);
