import ReportsView from '../views/ReportsView';
import {
	userIsAuthenticated,
	userInStandardMode
} from '../../../utils/AuthSelector';

// please be advised to take a caution when ordering routes

const reportsRoutes = [
	{
		path: '/reports',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(
				[
					'mnc.reports.endpoints_rw',
					'mnc.reports.endpoints_ro',
					'mnc.reports.usagesummaries_rw',
					'mnc.reports.usagesummaries_ro',
					'mnc.reports.transfer_rw',
					'mnc.reports.transfer_ro',
					'mnc.reports.accountsummary_rw',
					'mnc.reports.accountsummary_ro',
					'mnc.reports.usage_rw',
					'mnc.reports.usage_ro',
					'mnc.reports.reference_rw',
					'mnc.reports.reference_ro',
					'mnc.reports.billingdetails_rw',
					'mnc.reports.billingdetails_ro',
					'mnc.endpoints_rw',
					'mnc.endpoints_ro'
				],
				'/forbidden-access'
			)(ReportsView)
		)
	}
];

export default reportsRoutes;
