import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getTechTypes = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/tech-types?${qs.stringify(
			params.params
		)}`
	);

export const getTechTypesFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/tech-types/filters?${qs.stringify(
			params.params
		)}`
	);
