import React from 'react';
import { FormattedMessage, FormattedNumber, FormattedPlural } from 'react-intl';
import { schemeTableau10 } from 'd3-scale-chromatic';

export const formatUnits = (value = 0, isBytes) => {
	const unitsObject = {
		k: isBytes ? 1024 : 1000,
		sizes: ['', 'K', 'M', 'G'],
	};
	let i = value !== 0
		? Math.floor(Math.log(value) / Math.log(unitsObject.k))
		: 0;

	if (i > unitsObject.sizes.length) {
		i = unitsObject.sizes.length - 1;
	} else if (i < 0) {
		i = 0;
	}

	return {
		value: value !== 0 ? value / unitsObject.k ** i : 0,
		unit: unitsObject.sizes[i]
	};
};

export const renderValue = (unit, value = 0, isLeftAxis = false) => {
	const formattedUnitObject = formatUnits(value, unit === 'MB');

	if (isLeftAxis) {
		return `${parseFloat(formattedUnitObject.value.toFixed(2))}${formattedUnitObject.unit}`;
	}

	return (
		<>
			<FormattedNumber
				data-spec="formatted-number-charts"
				value={formattedUnitObject.value}
				maximumFractionDigits={2}
			/>
			{formattedUnitObject.unit}
		</>
	);
};

export const renderUnit = (unit, value, formatMessage) => {
	if (unit === 'COUNT') {
		return (
			<FormattedMessage
				data-spec="charts-unit-endpoints"
				id="ENDPOINTS"
				defaultMessage="Endpoints"
			/>
		);
	}

	if (unit === 'MINUTE') {
		return (
			<FormattedPlural
				data-spec="plural-unit-left-axis-minute"
				value={formatUnits(value, false).value}
				one={formatMessage({
					id: 'MINUTE',
					defaultMessage: 'Minute'
				})}
				other={formatMessage({
					id: 'MINUTES',
					defaultMessage: 'Minutes'
				})}
			/>
		);
	}

	if (unit === 'SECOND') {
		return (
			<FormattedPlural
				data-spec="plural-unit-left-axis-minute"
				value={formatUnits(value, false).value}
				one={formatMessage({
					id: 'SECOND',
					defaultMessage: 'Second'
				})}
				other={formatMessage({
					id: 'SECONDS',
					defaultMessage: 'Seconds'
				})}
			/>
		);
	}

	if (unit === 'MB') {
		return (
			<FormattedMessage
				data-spec="chart-unit-megabytes"
				id="MEGABYTES"
				defaultMessage="Megabytes"
			/>
		);
	}

	return <FormattedMessage
		data-spec="chart-unit-default"
		id={unit}
		defaultMessage="Unit"
	/>;
};

export const isHistoricalCycle = (groupByDate) =>
	!!groupByDate && groupByDate.toString() === '5';

export const isCycle = (groupByDate) =>
	!!groupByDate &&
	(groupByDate.toString() === '5' || groupByDate.toString() === '4');

export const topCountriesColors = [
	{ id: 'USA', color: '#0065B8' },
	{ id: 'MEX', color: '#87C947' },
	{ id: 'CAN', color: '#B80001' },
	{ id: 'THA', color: '#5856A0' },
	{ id: 'ITA', color: '#276635' },
	{ id: 'GRC', color: '#FFC536' },
	{ id: 'BRA', color: '#00256E' }
];

export const getDataColor = (id, index) => {
	const colorById = topCountriesColors.findIndex(
		(country) => id === country.id
	);
	const arrayMultiplier = Math.floor(index / schemeTableau10.length);
	const colorIndex = index - schemeTableau10.length * arrayMultiplier;

	return colorById !== -1
		? topCountriesColors[colorById].color
		: schemeTableau10[colorIndex];
};

export const checkEmptyPlace = (dataGridSize, settingsState) => {
	const emptySpace = {
		lg: { x: 0, y: 0 },
		md: { x: 0, y: 0 },
		sm: { x: 0, y: 0 }
	};

	if (!settingsState || !settingsState[dataGridSize]) {
		return emptySpace;
	}

	const keys = Object.keys(settingsState);

	for (let idxKeys = 0; idxKeys < keys.length; idxKeys += 1) {
		const sortedSettings = settingsState[keys[idxKeys]].sort(
			(item1, item2) => item1.y - item2.y || item1.x - item2.x
		);

		for (let index = 0; index < sortedSettings.length; index += 1) {
			if (
				sortedSettings[index].x === emptySpace[keys[idxKeys]].x &&
				sortedSettings[index].y === emptySpace[keys[idxKeys]].y
			) {
				emptySpace[keys[idxKeys]].x += sortedSettings[index].w;

				if (emptySpace[keys[idxKeys]].x >= sortedSettings[index].maxW) {
					emptySpace[keys[idxKeys]].x = 0;
					emptySpace[keys[idxKeys]].y += 1;
				}
			} else {
				return emptySpace;
			}
		}
	}

	return emptySpace;
};
