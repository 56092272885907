import { handleActions } from 'redux-actions';

import { Status } from '../../../../utils/constants';
import {
	GET_AUTOMATION_PLATFORMS_REQUEST,
	GET_AUTOMATION_PLATFORMS_SUCCESS,
	GET_AUTOMATION_PLATFORMS_FAIL,
	GET_AUTOMATION_CATEGORIES_REQUEST,
	GET_AUTOMATION_CATEGORIES_SUCCESS,
	GET_AUTOMATION_CATEGORIES_FAIL,
	GET_AUTOMATION_TRIGGERS_REQUEST,
	GET_AUTOMATION_TRIGGERS_SUCCESS,
	GET_AUTOMATION_TRIGGERS_FAIL,
	GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_REQUEST,
	GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_SUCCESS,
	GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_FAIL,
	GET_AUTOMATION_FRAMES_REQUEST,
	GET_AUTOMATION_FRAMES_SUCCESS,
	GET_AUTOMATION_FRAMES_FAIL,
	GET_AUTOMATION_OPTIONS_INIT
} from './constants';

export const initialState = {
	platformsStatus: Status.INIT,
	platforms: null,
	categoriesStatus: Status.INIT,
	categories: null,
	triggersStatus: Status.INIT,
	triggers: null,
	triggerSubcategoriesStatus: Status.INIT,
	triggerSubcategories: null,
	framesStatus: Status.INIT,
	frames: null,
	error: null,
	m2mAccounts: null
};

export default handleActions(
	{
		[GET_AUTOMATION_PLATFORMS_REQUEST]: (state) => ({
			...state,
			platformsStatus: Status.PENDING,
			platforms: null,
			categories: null,
			triggers: null,
			triggerSubcategories: null,
			frames: null,
			error: null
		}),
		[GET_AUTOMATION_PLATFORMS_SUCCESS]: (state, action) => ({
			...state,
			platformsStatus: Status.DONE,
			platforms: action.payload.platforms,
			m2mAccounts: action.payload.m2mAccounts,
			categories: null,
			triggers: null,
			triggerSubcategories: null,
			frames: null,
			error: null
		}),
		[GET_AUTOMATION_PLATFORMS_FAIL]: (state, action) => ({
			...state,
			platformsStatus: Status.FAIL,
			error: action.payload.error
		}),

		[GET_AUTOMATION_CATEGORIES_REQUEST]: (state) => ({
			...state,
			categoriesStatus: Status.PENDING,
			categories: null,
			triggers: null,
			triggerSubcategories: null,
			frames: null,
			error: null
		}),
		[GET_AUTOMATION_CATEGORIES_SUCCESS]: (state, action) => ({
			...state,
			categories: action.payload.data,
			triggers: null,
			triggerSubcategories: null,
			frames: null,
			error: null,
			categoriesStatus: Status.DONE
		}),
		[GET_AUTOMATION_CATEGORIES_FAIL]: (state, action) => ({
			...state,
			categoriesStatus: Status.FAIL,
			error: action.payload.error
		}),

		[GET_AUTOMATION_TRIGGERS_REQUEST]: (state) => ({
			...state,
			triggersStatus: Status.PENDING,
			triggers: null,
			triggerSubcategories: null,
			frames: null,
			error: null
		}),
		[GET_AUTOMATION_TRIGGERS_SUCCESS]: (state, action) => ({
			...state,
			triggers: action.payload.data,
			triggerSubcategories: null,
			frames: null,
			error: null,
			triggersStatus: Status.DONE
		}),
		[GET_AUTOMATION_TRIGGERS_FAIL]: (state, action) => ({
			...state,
			triggersStatus: Status.FAIL,
			error: action.payload.error
		}),

		[GET_AUTOMATION_FRAMES_REQUEST]: (state) => ({
			...state,
			frames: null,
			error: null,
			framesStatus: Status.PENDING
		}),
		[GET_AUTOMATION_FRAMES_SUCCESS]: (state, action) => ({
			...state,
			frames: action.payload,
			error: null,
			framesStatus: Status.DONE
		}),
		[GET_AUTOMATION_FRAMES_FAIL]: (state, action) => ({
			...state,
			framesStatus: Status.FAIL,
			error: action.payload.error
		}),

		[GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_REQUEST]: (state) => ({
			...state,
			triggerSubcategoriesStatus: Status.PENDING,
			triggerSubcategories: null,
			error: null
		}),
		[GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_SUCCESS]: (state, action) => ({
			...state,
			triggerSubcategories: action.payload,
			error: null,
			triggerSubcategoriesStatus: Status.DONE
		}),
		[GET_AUTOMATION_TRIGGERS_SUBCATEGORIES_FAIL]: (state, action) => ({
			...state,
			triggerSubcategoriesStatus: Status.FAIL,
			error: action.payload.error
		}),

		[GET_AUTOMATION_OPTIONS_INIT]: (state) => ({
			...state,
			platformsStatus: Status.INIT,
			platforms: null,
			categoriesStatus: Status.INIT,
			categories: null,
			triggersStatus: Status.INIT,
			triggers: null,
			triggerSubcategoriesStatus: Status.INIT,
			triggerSubcategories: null,
			framesStatus: Status.INIT,
			frames: null,
			error: null
		})
	},
	initialState
);
