import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../NetworkEntitlements';

import styles from './Desktop.scss';

const Desktop = (props) => {
	const { networkEntitlements, getOptions, messages } = props;
	return (
		<div
			data-spec="networkEntitlements-desktop"
			className={styles.network_entitlements_wrapper}
		>
			<Table
				data-spec="networkEntitlements-table-desktop"
				data={networkEntitlements.networkEntitlements}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { object, func } = PropTypes;

Desktop.propTypes = {
	networkEntitlements: object,
	getOptions: func,
	messages: object
};

export default hoc()(Desktop);
