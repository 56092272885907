import { Status } from '../../../../utils/constants';

export const getUserGroupPermissionsRequest = (state) =>
	state.onboarding.userGroupPermissions.getUserGroupPermissions.status ===
	Status.PENDING;
export const getUserGroupPermissionsFail = (state) =>
	state.onboarding.userGroupPermissions.getUserGroupPermissions.status ===
	Status.FAIL;
export const getUserGroupPermissionsSuccess = (state) =>
	state.onboarding.userGroupPermissions.getUserGroupPermissions.data;

export const editUserGroupPermissionsRequest = (state) =>
	state.onboarding.userGroupPermissions.editUserGroupPermissions.status ===
	Status.PENDING;
export const editUserGroupPermissionsFail = (state) =>
	state.onboarding.userGroupPermissions.editUserGroupPermissions.status ===
	Status.FAIL;
export const editUserGroupPermissionsSuccess = (state) =>
	state.onboarding.userGroupPermissions.editUserGroupPermissions.status ===
	Status.DONE;

export const getUserEffectivePermissionsRequest = (state) =>
	state.onboarding.userGroupPermissions.getUserEffectivePermissions
		.status === Status.PENDING;
export const getUserEffectivePermissionsFail = (state) =>
	state.onboarding.userGroupPermissions.getUserEffectivePermissions
		.status === Status.FAIL;
export const getUserEffectivePermissionsSuccess = (state) =>
	state.onboarding.userGroupPermissions.getUserEffectivePermissions.data;
