import { createAction } from 'redux-actions';
import {
	SET_ENDPOINTS_ORDER_COLUMN,
	SET_ENDPOINTS_PAGINATION,
	SET_ENDPOINTS_PAGINATION_INIT
} from './constants';

export const setEndpointsOrderColumn = createAction(SET_ENDPOINTS_ORDER_COLUMN);
export const setEndpointsPaginationInit = createAction(SET_ENDPOINTS_PAGINATION_INIT);
export const setEndpointsPagination = createAction(SET_ENDPOINTS_PAGINATION);
