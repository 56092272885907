import { connect } from 'react-redux';
import { getFormValues, isDirty } from 'redux-form';
import AddCompanyForm from './AddCompanyForm';

import {
	getCountries,
	getStates,
	getAddressTypes,
	getContactTypes,
	getCompanyLanguages,
} from '../../redux/companies/actions';

import {
	getCountriesRequest,
	getCountriesFail,
	getCountriesSuccess,
	getStatesRequest,
	getStatesFail,
	getStatesSuccess,
	getAddressTypesRequest,
	getAddressTypesSuccess,
	getContactTypesRequest,
	getContactTypesSuccess,
	getCompanyLanguagesRequest,
	getCompanyLanguagesSuccess,
} from '../../redux/companies/selectors';

const mapStateToProps = (state) => ({
	getCountriesRequest: getCountriesRequest(state),
	getCountriesFail: getCountriesFail(state),
	countries: getCountriesSuccess(state),
	getStatesRequest: getStatesRequest(state),
	getStatesFail: getStatesFail(state),
	states: getStatesSuccess(state),
	formData: getFormValues('AddCompanyForm')(state),
	getAddressTypesRequest: getAddressTypesRequest(state),
	addressTypes: getAddressTypesSuccess(state),
	getContactTypesRequest: getContactTypesRequest(state),
	contactTypes: getContactTypesSuccess(state),
	getCompanyLanguagesRequest: getCompanyLanguagesRequest(state),
	getCompanyLanguagesSuccess: getCompanyLanguagesSuccess(state),
	streetFieldDirty: isDirty('AddCompanyForm')(state, ['addresses[0].street1']),
	countryFieldDirty: isDirty('AddCompanyForm')(state, ['addresses[0].countryId']),
	stateFieldDirty: isDirty('AddCompanyForm')(state, ['addresses[0].stateId']),
	zipCodeFieldDirty: isDirty('AddCompanyForm')(state, ['addresses[0].zipCode']),
	cityFieldDirty: isDirty('AddCompanyForm')(state, ['addresses[0].city']),
});

const mapDispatchToProps = (dispatch) => ({
	getCountries: (params) => dispatch(getCountries(params)),
	getStates: (params) => dispatch(getStates(params)),
	getAddressTypes: () => dispatch(getAddressTypes()),
	getContactTypes: () => dispatch(getContactTypes()),
	getCompanyLanguages: () => dispatch(getCompanyLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyForm);
