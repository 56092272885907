import qs from 'qs';
import { get, patch, post, remove } from '../../../services/ApiService';

const urlString = '/analytics/account-overview/charts';

export const getDefinedSettings = (includeChartSettings = false) =>
	get(
		`${urlString}${
			includeChartSettings
				? `?${qs.stringify({
					additionalParams: { include: ['ChartSettings'] }
				  })}`
				: ''
		}`
	);

export const getDefinedSettingById = (settingId) =>
	get(
		`${urlString}/${settingId}?${qs.stringify({
			additionalParams: { include: ['ChartSettings'] }
		})}`
	);

export const updateDefinedSetting = ({ settingObject, settingId }) =>
	(settingId
		? patch(`${urlString}/${settingId}`, settingObject)
		: post(urlString, settingObject));

export const deleteDefinedSetting = (settingId) =>
	remove(`${urlString}/${settingId}`, { data: { settingId } });

export const getChartData = (settingId, additionalParams) =>
	(additionalParams
		? get(
			`${urlString}/${settingId}/chart-data?${qs.stringify({
				additionalParams
			})}`
		  )
		: get(`${urlString}/${settingId}/chart-data`));

export const exportChartDataCSV = (requestParams) =>
	get(
		`${urlString}/${requestParams.id}/chart-data/export${
			requestParams.additionalParams
				? `?${qs.stringify({
					additionalParams: requestParams.additionalParams
				  })}`
				: ''
		}`
	);
