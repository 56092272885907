import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bool, func, object, string } from 'prop-types';
import { isEqual } from 'lodash';

import Loading from '../../../../../lib/DigitalComponents/Loader';
import Button from '../../../../../lib/DigitalComponents/Button';
import DSLastStep from '../../../../Steps';

class StepSecond extends PureComponent {
	componentDidMount() {
    const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
    const { context, isUpdating } = this.props;

		if (context && !isEqual(isUpdating, prevProps.isUpdating)) {
			this.setFooter(context);
		}
	}

	onClick = () => {
    const { onCancel, getSingleEndpoint, endpointName, type } = this.props;

		onCancel();

		if (getSingleEndpoint) {
			getSingleEndpoint(endpointName, type);
		}
	};

	setFooter = (context) => {
    const { isUpdating } = this.props;
		const footer = (
			<>
				<Button
					variant="success"
					onClick={this.onClick}
					disabled={isUpdating}
					label={<FormattedMessage id="DONE" defaultMessage="Done" />}
					dataSpec="done-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	renderLastStepSuccess = () => {
    const { isSuccess, values, intl } = this.props;

		if (isSuccess) {
			return (
				<DSLastStep
					data-spec="last success step"
					title={<FormattedMessage
						id="SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED"
						defaultMessage="SIM state change is successfully scheduled!"
					/>}
					msg={`${intl.formatMessage({
						id: 'SINGLE_ENDPOINT.SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR',
						defaultMessage: 'SIM state change is successfully scheduled for '
					})} ${intl.formatDate(values.scheduleDate, {
						month: '2-digit',
						day: '2-digit',
						year: 'numeric',
					})}`}
				/>
			);
		}
	};

	renderLastStepError = () => {
    const { isFail } = this.props;

		if (isFail) {
			return (
				<DSLastStep
					data-spec="last step error"
					type="error"
					title={<FormattedMessage
						id="SIM_STATE_CHANGE_IS_SUCCESSFULLY_SCHEDULED_ERROR"
						defaultMessage="SIM state change could not be scheduled successfully!"
					/>}
					msg={<FormattedMessage
						id="PLEASE_TRY_AGAIN"
						defaultMessage="Please try again."
					/>}
				/>
			);
		}
	};

	render() {
		const { isUpdating, intl } = this.props;

		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body">
					{isUpdating && (
						<Loading
							data-spec="loading"
							msg={intl.formatMessage({
								id: 'SCHEDULING',
								defaultMessage: 'Scheduling...'
							})}
						/>
					)}

					{!isUpdating && this.renderLastStepSuccess()}
					{!isUpdating && this.renderLastStepError()}
				</div>
			</div>
		);
	}
}

StepSecond.propTypes = {
	isUpdating: bool,
	isSuccess: bool,
	isFail: bool,
	onCancel: func,
	values: object,
	getSingleEndpoint: func,
	type: string,
	endpointName: string,
	context: object,
	intl: object,
};

export default injectIntl(StepSecond);
