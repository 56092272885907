export default {
	'REPORTS.REPORT': 'Report',
	'REPORTS.M2M_ACCOUNT_ID': 'M2M Account ID',
	'REPORTS.M2M_PLATFORM': 'M2M Platform',
	'REPORTS.FREQUENCY': 'Frequency',
	'REPORTS.PROCESSED_ON': 'Processed On',
	'REPORTS.REPORT_DATE': 'Report Date',
	'REPORTS_TABS.ENDPOINTS': 'Endpoints',
	'REPORTS_TABS.USAGE_SUMMARY': 'Usage Summary',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Transfer Activities',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Account Summary',
	'REPORTS_TABS.BILLING_DETAILS': 'Billing Details',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Reference Reports',
	'REPORTS_TABS.MY_EXPORTS': 'My Exports',
	'REPORTS_TABS.USAGE_REPORTS': 'Usage Reports',
	'REPORTS_TABS.REPORT_SCHEMA': 'Report Schema',
	'REPORTS.REPORT_REPOST_SUCCESS': 'Report: {fileName} will be reposted to file gateway to download. \n Please allow some time for the report file to be uploaded.',
	'REPORTS.REPORT_REPOST_FAIL': 'Report: {fileName} could not be reposted',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Reposted Reports',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'No Reports Available',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE':
		'There are No Reports for this Table to show you right now',
	'REPORTS.REPORT_DATE_TOOLTIP':
		'This date represents when the report was created. The downloaded file name contains the date for the report data. The date selected and the report date will differ by up to 3 calendar days.',
	'REPORTS.FILE_NAME': 'File Name',
	'REPORTS.FILE_DATE': 'File Date',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Requested By'
};
