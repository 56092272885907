import React, { PureComponent } from 'react';
import {
	object,
	func,
	bool,
	string,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';

export default function Module(WrapperComponent) {
	class ModuleComponent extends PureComponent {
		componentDidMount() {
			const { availableStates, getAvailableStates } = this.props;

			if (!availableStates) {
				getAvailableStates();
			}
		}

		render() {
			const {
				availableStates,
				isPendingStatus,
				endpointData,
				modules,
				rateplan,
				endpoint
			} = this.props;

			if (!availableStates) {
				return <Loader data-spec="loader" />;
			}

			const currentEndpoint = endpoint || {};
			const statusMessage = modules.type === 'Satellite-Narrowband' ? (
				<FormattedMessage id="MODULE_STATUS" defaultMessage="Module Status" />
			) : (
				<FormattedMessage id="SIM_STATUS" defaultMessage="SIM status" />
			);
			const status = modules.type === 'Satellite-Narrowband'
				? modules.status
				: currentEndpoint.status;

			return (
				<div data-spec="module">
					<WrapperComponent
						endpoint={endpoint}
						modules={modules}
						rateplan={rateplan}
						endpointData={endpointData}
						currentEndpoint={currentEndpoint}
						statusMessage={statusMessage}
						status={status}
						isPendingStatus={isPendingStatus}
					/>
				</div>
			);
		}
	}

	ModuleComponent.propTypes = {
		endpoint: object,
		availableStates: object,
		rateplan: object,
		modules: object,
		getAvailableStates: func,
		isPendingStatus: bool,
		type: string,
		endpointData: object
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	ModuleComponent.displayName = `Module(${wrappedComponentName})`;

	return ModuleComponent;
}
