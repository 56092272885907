export default {
	'BATCH_HISTORY.LINK': 'Storico batch',
	'BATCH_HISTORY.TITLE': 'Storico batch',
	'BATCH_HISTORY.BATCH_ACTIONS': 'Azioni batch',
	'BATCH_HISTORY.BATCH_REPORTS': 'Report batch',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': 'Nessuna azione batch disponibile',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': 'Non ci sono attualmente azioni batch da mostrare per questa tabella',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': 'Nessun report batch disponibile',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': 'Non ci sono attualmente report batch da mostrare per questa tabella',
	'BATCH_HISTORY.BATCH_FILE_NAME': 'Nome file batch',
	'BATCH_HISTORY.DATE_UPLOADED': 'Data caricamento',
	'BATCH_HISTORY.STATUS': 'Stato',
	'BATCH_HISTORY.REPORT_NAME': 'Report',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': 'ID account M2M',
	'BATCH_HISTORY.DATE': 'Data',
	'BATCH_HISTORY.M2M_PLATFORM': 'Piattaforma M2M',
	'BATCH_HISTORY.FREQUENCY': 'Frequenza',
	'BATCH_HISTORY.SUBMITTED_STATUS': 'Inviato',
	'BATCH_HISTORY.SCHEDULED_STATUS': 'Frequenza',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': 'In corso',
	'BATCH_HISTORY.COMPLETED_STATUS': 'Completato',
  'BATCH_HISTORY.ERROR_STATUS': 'Errore',
  'BATCH_HISTORY.CANCELLED_STATUS': 'Annullato',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': 'Caricamento file per elaborazione riuscito',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': 'Caricamento file riuscito, pianificato per elaborazione',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': 'Elaborazione in corso',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': 'Elaborazione file riuscita',
  'BATCH_HISTORY.ERROR_TOOLTIP': 'Si è verificato un errore durante l’elaborazione',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': 'Descrizione comandi Annullato'
};
