import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../List';

const Desktop = (props) => {
	const { data, options, messages } = props;
	return (
		<div data-spec="list">
			<Table
				data-spec="list-desktop"
				data={data}
				options={options('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { func, objectOf, string, array } = PropTypes;

Desktop.propTypes = {
	data: array,
	options: func,
	messages: objectOf(string),
};

export default hoc()(Desktop);
