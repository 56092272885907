import { handleActions } from 'redux-actions';

import { Status } from 'utils/constants';

import {
	DOWNLOAD_INVOICE_INIT,
	DOWNLOAD_INVOICE_REQUEST,
	DOWNLOAD_INVOICE_SUCCESS,
	DOWNLOAD_INVOICE_FAIL
} from './constants';

const initialState = {
	downloadingReport: Status.INIT,
	downloadReportError: null
};

export default handleActions(
	{
		[DOWNLOAD_INVOICE_INIT]: (state) => ({
			...state,
			downloadingReport: Status.INIT,
			downloadReportError: null
		}),
		[DOWNLOAD_INVOICE_REQUEST]: (state) => ({
			...state,
			downloadingReport: Status.PENDING,
			downloadReportError: null
		}),
		[DOWNLOAD_INVOICE_FAIL]: (state, action) => ({
			...state,
			downloadingReport: Status.FAIL,
			downloadReportError: action.payload.error
		}),
		[DOWNLOAD_INVOICE_SUCCESS]: (state) => ({
			...state,
			downloadingReport: Status.DONE,
			downloadReportError: null
		})
	},
	initialState
);
