import React from 'react';
import { string, objectOf } from 'prop-types';

import {
	DesktopMediaQuery,
	TabletMediaQuery,
	MobileMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import { DesktopSMSTab, MobileSMSTab } from '../../components/SMSTab';

export default function SMSTabView({ messages, id, m2mAccountId }) {
	return (
		<>
			<DesktopMediaQuery>
				<DesktopSMSTab
					messages={messages}
					dataLimit={50}
					id={id}
					m2mAccountId={m2mAccountId}
				/>
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<DesktopSMSTab
					messages={messages}
					dataLimit={50}
					id={id}
					m2mAccountId={m2mAccountId}
				/>
			</TabletMediaQuery>
			<MobileMediaQuery>
				<MobileSMSTab
					messages={messages}
					dataLimit={20}
					id={id}
					m2mAccountId={m2mAccountId}
				/>
			</MobileMediaQuery>
		</>
	);
}

SMSTabView.propTypes = {
	id: string,
	messages: objectOf(string),
	m2mAccountId: string,
};

SMSTabView.defaultProps = {
	id: '',
	messages: {},
	m2mAccountId: '',
};
