import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// import SimTransferForm from '../SimOrderingTransfer';
import SimOrderingForm from '../SimOrderingForm';

import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import ActionBarWithTabs from '../../../Shared/views/TabsBarView';

import styles from './SimOrdering.scss';
import AccountInfoOverview from '../AccountInfoOverview';
// import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';

class SimOrdering extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'ordering'
		};
	}

	componentDidMount() {
		this.getAccount();
	}

	componentWillUnmount() {
		const { getAccountInit } = this.props;
		getAccountInit();
	}

	getAccount = () => {
		const {
			getAccount,
			match: {
				params: { accountId }
			}
		} = this.props;
		getAccount({
			id: accountId,
			additionalParams: {
				include: [
					'PlatformType',
					'PlatformMobileOperator',
					'PlatformSatelliteOperator',
					'Company'
				]
			}
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { parentAccountM2mId, companyId, accountId, m2mAccountId }
			}
		} = this.props;

		if (companyId) {
			if (parentAccountM2mId) {
				pushBack(
					`/companies/${companyId}/accounts/${accountId}/${m2mAccountId}/${parentAccountM2mId}`
				);
			} else {
				pushBack(`/companies/${companyId}/accounts/${accountId}`);
			}
		} else {
			pushBack(`/my-company/${accountId}`);
		}
	};

	renderTabs = (actionBarTabs) => {
		const { activeTab } = this.state;
		return actionBarTabs.find((tab) => tab.id === activeTab).component();
	};

	onTabClick = (activeTab) => this.setState({ activeTab });

	getTabs = () => {
		const { activeTab } = this.state;

		return [
			{
				id: 'ordering',
				isActive: activeTab === 'ordering',
				name: (
					<FormattedMessage
						id="ONBOARDING.ORDERING"
						defaultMessage="Ordering"
					/>
				),
				component: () => <SimOrderingForm {...this.props} pushBack={this.pushBack} />,
				onClick: () => this.onTabClick('ordering')
			},
			/* {
				id: 'transfer',
				name: (
					<FormattedMessage
						id="ONBOARDING.TRANSFER"
						defaultMessage="Transfer"
					/>
				),
				isActive: activeTab === 'transfer',
				component: () => (
					<SimTransferForm
						companyId={companyId || account.companyId}
						m2mAccountId={m2mAccountId}
					/>
				),
				onClick: () => this.onTabClick('transfer'),
				hide: !isUserAllowedToAccess(['system.simorderstatus_rw', 'system.simorderstatus_ro'], user)
			} */
		].filter((el) => !el.hide);
	};

	render() {
		const { messages, accountRequest, accountFail, account } = this.props;

		return (
			<div data-spec="sim-order-page">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.SIM_ORDERING"
							defaultMessage="SIM Ordering"
						/>
					}
					pushBack={this.pushBack}
					messages={messages}
				/>
				<div className={styles.sim_ordering_wrapper}>
					{accountFail && !accountRequest && null}
					{accountRequest && <Loading data-spec="loading" />}
					{account && !accountRequest && (
						<AccountInfoOverview
							account={account}
							title={account.company.companyFriendlyName}
							subtitle={
								<FormattedMessage
									id="ONBOARDING.ORDER_SIMS_SELECT_OR_UPLOAD_SIM_LIST"
									defaultMessage="Order SIMs, select or upload SIM list"
								/>
							}
						/>
					)}
				</div>

				<ActionBarWithTabs tabs={this.getTabs()} />
				{this.renderTabs(this.getTabs())}
			</div>
		);
	}
}

const { func, object, bool, objectOf, string, shape } = PropTypes;

SimOrdering.propTypes = {
	messages: objectOf(string),
	accountRequest: bool,
	account: object,
	accountFail: bool,
	match: object,
	pushBack: func,
	getAccount: func,
	getAccountInit: func,
	user: shape()
};

export default SimOrdering;
