import { handleActions } from 'redux-actions';
import { Status } from 'utils/constants';

import {
	UPDATE_BULK_NOTES_REQUEST,
	UPDATE_BULK_NOTES_SUCCESS,
	UPDATE_BULK_NOTES_FAIL,
	UPDATE_BULK_NOTES_STARTED,
	UPDATE_BULK_NOTES_ENDED,
	UPDATE_BULK_NOTES_ADD_FILTERED_ENDPOINTS,
	UPDATE_BULK_NOTES_ERROR_CLEAR
} from './constants';

export const initialState = {
	status: Status.INIT,
	error: [],
	currentEndpoint: '',
	successEndpoints: [],
	failEndpoints: [],
	actionError: false,
	started: false,
	filteredEndpoints: []
};

export default handleActions(
	{
		[UPDATE_BULK_NOTES_REQUEST]: (state, action) => ({
				...state,
				currentEndpoint: action.payload.targetICCID,
				status: Status.PENDING
			}),
		[UPDATE_BULK_NOTES_STARTED]: (state) => ({
				...state,
				currentEndpoint: '',
				successEndpoints: [],
				failEndpoints: [],
				actionError: false,
				started: true,
				filteredEndpoints: []
			}),
		[UPDATE_BULK_NOTES_ENDED]: (state) => ({
				...state,
				status: Status.INIT,
				actionError: !state.successEndpoints.length,
				started: false
			}),
		[UPDATE_BULK_NOTES_SUCCESS]: (state, action) => ({
				...state,
				successEndpoints: [
					...state.successEndpoints,
					action.payload.targetICCID
				],
				status: Status.DONE
			}),
		[UPDATE_BULK_NOTES_FAIL]: (state, action) => ({
				...state,
				status: Status.FAIL,
				failEndpoints: [...state.failEndpoints, action.payload.targetICCID],
				error: action.payload.error
			}),
		[UPDATE_BULK_NOTES_ADD_FILTERED_ENDPOINTS]: (state, action) => ({
				...state,
				filteredEndpoints: [
					...state.filteredEndpoints,
					action.payload.targetICCID
				]
			}),
		[UPDATE_BULK_NOTES_ERROR_CLEAR]: (state) => ({
				...state,
				actionError: false
			})
	},
	initialState
);
