/* eslint-disable prefer-template */
import { createAction } from 'redux-actions';

import {
	DOWNLOAD_TEMPLATE_FAIL,
	DOWNLOAD_TEMPLATE_REQUEST,
	DOWNLOAD_TEMPLATE_SUCCESS,
	FETCH_ALL_TEMPLATES_REQUEST,
	FETCH_ALL_TEMPLATES_SUCCESS,
	FETCH_ALL_TEMPLATES_FAIL
} from './constants';
import {
	getBatchFileTemplates
} from '../../../services/BatchFilesService';
import {
	download,
	reportPollForFile,
	downloadReportRequestURL as downloadReportRequestURLService,
} from '../../../services/DownloadService';
import { downloadReportHelper } from '../../../utils/constants';

// download custom template
const downloadTemplateRequest = createAction(DOWNLOAD_TEMPLATE_REQUEST);
const downloadTemplateSuccess = createAction(DOWNLOAD_TEMPLATE_SUCCESS);
export const downloadTemplateFail = createAction(DOWNLOAD_TEMPLATE_FAIL);

// fetch all templates
const fetchTemplatesRequest = createAction(FETCH_ALL_TEMPLATES_REQUEST);
const fetchTemplateSuccess = createAction(FETCH_ALL_TEMPLATES_SUCCESS);
const fetchTemplateFail = createAction(FETCH_ALL_TEMPLATES_FAIL);

// custom actions

const downloadHelper = (response) => {
	const blobURL = window.URL.createObjectURL(response);
	const tempLink = document.createElement('a');
	tempLink.href = blobURL;
	tempLink.setAttribute('download', 'results.xlsx');
	// tempLink.setAttribute("target", "_blank");
	document.body.appendChild(tempLink);
	tempLink.click();
	document.body.removeChild(tempLink);
};

export const downloadResults = (paginationData, searchData) => (dispatch) => {
	download(paginationData, searchData)
		.then((response) => {
			downloadHelper(response);
		})
		.catch((error) => {
			dispatch(downloadTemplateFail(error));
		});
};

export const downloadReportRequestURL = (data) => (dispatch) => {
	let downloadFileId = null;

	dispatch(downloadTemplateRequest());
	downloadReportRequestURLService(data)
		.then((response) => {
			downloadFileId = response.data.id;

			return reportPollForFile(
				{ id: downloadFileId },
				100,
				4000
			);
		})
		.then((response) => {
			dispatch(downloadTemplateSuccess());
			downloadReportHelper(response.data.downloadUrl, data.reportFileName);
		})
		.catch((error) => {
			dispatch(downloadTemplateFail(error));
		});
};

export const fetchAllTemplates = (data) => (dispatch) => {
	dispatch(fetchTemplatesRequest());
	getBatchFileTemplates(data)
		.then((response) => {
			dispatch(fetchTemplateSuccess(response));
		})
		.catch((error) => {
			dispatch(fetchTemplateFail(error));
		});
};
