import React from 'react';
import PropTypes from 'prop-types';

import hoc from '../PendingInvitations';
import Table from '../../../../../lib/DigitalComponents/Table';

const Desktop = (props) => {
	const { getOptions, messages, registrations } = props;
	return (
		<div data-spec="users-desktop">
			<Table
				data-spec="users-table-desktop"
				data={registrations.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
};

const { func, objectOf, string, shape } = PropTypes;

Desktop.propTypes = {
	getOptions: func,
	messages: objectOf(string),
	registrations: shape(),
};

export default hoc()(Desktop);
