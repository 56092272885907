import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { renderTabs, onNewTabClick } from '../../../../utils/tabHelpers';

import EditCompanyOverview from '../../views/EditCompanyOverviewView';
import Accounts from '../../views/AccountsView';

import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';
import Button from '../../../../lib/DigitalComponents/Button';
import BlacklistedPermissions from '../BlacklistedPermissions';
import {
	isUserAllowedToAccess,
	UserAllowedToAccess
} from '../../../../utils/AuthSelector';

const LaunchIotConsoleComponent = UserAllowedToAccess([
	'system.companies.launch_rw',
	'system.companies.launch_ro'
]);
class EditCompany extends Component {
	constructor(props) {
		super(props);
		const { initTab } = this.props;
		this.state = {
			activeTab: initTab,
		};
	}

	componentDidMount() {
		const {
			match: {
				params: { companyId }
			}
		} = this.props;
		if (companyId) {
			this.getCompany(companyId);
		}
	}

	componentDidUpdate(prevProps) {
		const { getCompanyTokenSuccess, user } = this.props;

		const { companyId } = this.state;

		if (
			prevProps.getCompanyTokenSuccess !== getCompanyTokenSuccess &&
			getCompanyTokenSuccess
		) {
			sessionStorage.setItem('company-token', getCompanyTokenSuccess.token);
			const sessionLanguage = sessionStorage.getItem('language');
			sessionStorage.setItem('company', companyId);
			window.location.href = `/${sessionLanguage || user.language || 'en-us'}`;
		}
	}

	componentWillUnmount() {
		const { getCompanyInit } = this.props;
		getCompanyInit();
	}

	getCompany = (companyId) => {
		const { getCompany } = this.props;
		getCompany({
			id: companyId,
			additionalParams: {
				include: [
					'CompanyContacts',
					'CompanyAddresses',
					'CompanyAddresses.AddressType',
					'CompanyAddresses.Country',
					'CompanyAddresses.State',
					'CompanyContacts.CompanyContactType',
					'CompanyLogo',
					'Language'
				]
			}
		});
	};

	pushBack = () => {
		const { pushBack } = this.props;
		pushBack();
	};

	launchMc = () => {
		const { getCompanyToken, company } = this.props;
		this.setState(
			{
				companyId: company.externalCompanyId
			},
			() => getCompanyToken({ companyId: company.id })
		);
	};

	render() {
		const {
			messages,
			getCompanyRequest,
			getCompanyFail,
			company,
			match: {
				params: { companyId }
			},
			user,
			getCompanyTokenRequest,
			getCompanyTokenSuccess,
			editCompany,
			editCompanySuccess,
			editCompanyRequest,
			editCompanyFail,
		} = this.props;

		const { activeTab } = this.state;

		const tabs = [
			{
				id: 'details',
				name: (
					<FormattedMessage
						id="ONBOARDING.COMPANY_DETAILS"
						defaultMessage="Company Details"
					/>
				),
				component: (
					<EditCompanyOverview
						key="1"
						company={company || null}
						onClose={this.pushBack}
						isMyCompanyOverview={false}
						editCompany={(params) => editCompany(params, companyId)}
						getCompany={() => this.getCompany(companyId)}
						getCompanyRequest={getCompanyRequest}
						editCompanyRequest={editCompanyRequest}
						editCompanySuccess={editCompanySuccess}
						editCompanyFail={editCompanyFail}
					/>
				),
				isActive: activeTab === 'details',
				onClick: () => onNewTabClick('details', this)()
			},
			{
				id: 'accounts',
				name: (
					<FormattedMessage
						id="ONBOARDING.ACCOUNTS"
						defaultMessage="Accounts"
					/>
				),
				component: (
					<Accounts key="2" companyId={companyId} messages={messages} />
				),
				isActive: activeTab === 'accounts',
				onClick: () => onNewTabClick('accounts', this)()
			},
			{
				id: 'features',
				name: (
					<FormattedMessage
						id="ONBOARDING.COMPANY_FEATURES"
						defaultMessage="Company Features"
					/>
				),
				component: <BlacklistedPermissions key="3" companyId={companyId} />,
				isActive: activeTab === 'features',
				onClick: () => onNewTabClick('features', this)(),
				hide: !isUserAllowedToAccess(
					[
						'system.accessmanagement.featureaccessmanagement_rw',
						'system.accessmanagement.featureaccessmanagement_ro'
					],
					user
				)
			}
		];

		return (
			<div data-spec="edit-company">
				<PageTitle
					title={company && company.companyFriendlyName}
					pushBack={!getCompanyRequest ? this.pushBack : undefined}
					messages={messages}
					actions={
						<LaunchIotConsoleComponent>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.LAUNCH_IOT_CONSOLE"
										defaultMessage="Launch IoT Console"
									/>
								}
								variant="primary"
								data-spec="launch-iot-console-button"
								onClick={this.launchMc}
							/>
						</LaunchIotConsoleComponent>
					}
				/>
				{(getCompanyRequest ||
					getCompanyTokenRequest ||
					getCompanyTokenSuccess) && <Loading data-spec="loading" />}
				{companyId && getCompanyFail && null}
				{!getCompanyRequest &&
					!getCompanyFail &&
					!getCompanyTokenSuccess &&
					!getCompanyTokenRequest && (
						<>
							<TabsBar tabs={tabs} />
							{renderTabs(tabs)}
						</>
					)}
			</div>
		);
	}
}

const { object, string, func, bool } = PropTypes;

EditCompany.propTypes = {
	initTab: string,
	messages: object,
	match: object,
	pushBack: func,
	getCompany: func,
	getCompanyRequest: bool,
	getCompanyFail: bool,
	company: object,
	getCompanyInit: func,
	user: object,
	getCompanyToken: func,
	getCompanyTokenRequest: bool,
	getCompanyTokenSuccess: object,
	editCompany: func,
	editCompanySuccess: bool,
	editCompanyRequest: bool,
	editCompanyFail: bool,
};

export default EditCompany;
