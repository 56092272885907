import globalAction, {
	actionInit
} from '../../../redux/global/globalAction';

import {
	getReleaseNotesList as getReleaseNotesListService,
	getReleaseNotes as getReleaseNotesService,
	addReleaseNotes as addReleaseNotesService,
	editReleaseNotes as editReleaseNotesService,
	getAdditionalDetailStatuses as getAdditionalDetailStatusesService,
  publishReleaseNotes as publishReleaseNotesService
} from '../services/ReleaseNotesService';

import {
	GET_RELEASE_NOTES_LIST,
	GET_RELEASE_NOTES,
	ADD_RELEASE_NOTES,
	EDIT_RELEASE_NOTES,
	GET_ADDITIONAL_DETAIL_STATUSES,
	PUBLISH_RELEASE_NOTES
} from './constants';

export const getReleaseNotesList = (params) =>
	globalAction(GET_RELEASE_NOTES_LIST, getReleaseNotesListService, params);

export const getReleaseNotes = (params) =>
	globalAction(GET_RELEASE_NOTES, getReleaseNotesService, params);

export const addReleaseNotes = (params) =>
	globalAction(ADD_RELEASE_NOTES, addReleaseNotesService, params, {
		notify: 'add-release-notes',
		redirect: '/release-notes'
	});

export const editReleaseNotes = (params) =>
	globalAction(EDIT_RELEASE_NOTES, editReleaseNotesService, params, {
		notify: 'edit-release-notes',
		redirect: '/release-notes'
	});

export const getAdditionalDetailStatuses = (params) =>
	globalAction(
		GET_ADDITIONAL_DETAIL_STATUSES,
		getAdditionalDetailStatusesService,
		params
  );

export const publishReleaseNotes = (params) =>
  globalAction(
    PUBLISH_RELEASE_NOTES,
    publishReleaseNotesService,
    params,
    {
      init: true
    }
  );

export const getReleaseNotesInit = () => actionInit(GET_RELEASE_NOTES);
