export default {
	'BATCH_HISTORY.LINK': '批处理历史记录',
	'BATCH_HISTORY.TITLE': '批处理历史记录',
	'BATCH_HISTORY.BATCH_ACTIONS': '批处理行动',
	'BATCH_HISTORY.BATCH_REPORTS': '批处理报告',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_TITLE': '无可用的批处理行动',
	'BATCH_HISTORY.NO_BATCH_ACTIONS_SUBTITLE': '本表格目前无可显示的批处理行动',
	'BATCH_HISTORY.NO_BATCH_REPORTS_TITLE': '无可用的批处理报告',
	'BATCH_HISTORY.NO_BATCH_REPORTS_SUBTITLE': '本表格目前无可显示的批处理报告',
	'BATCH_HISTORY.BATCH_FILE_NAME': '批处理文件名',
	'BATCH_HISTORY.DATE_UPLOADED': '上载日期',
	'BATCH_HISTORY.STATUS': '状态',
	'BATCH_HISTORY.REPORT_NAME': '报告',
	'BATCH_HISTORY.M2M_ACCOUNT_ID': '机对机（M2M）账户ID',
	'BATCH_HISTORY.DATE': '日期',
	'BATCH_HISTORY.M2M_PLATFORM': '机对机（M2M） 平台',
	'BATCH_HISTORY.FREQUENCY': '频率',
	'BATCH_HISTORY.SUBMITTED_STATUS': '已提交',
	'BATCH_HISTORY.SCHEDULED_STATUS': '频率',
	'BATCH_HISTORY.IN_PROGRESS_STATUS': '处理中',
	'BATCH_HISTORY.COMPLETED_STATUS': '已完成',
  'BATCH_HISTORY.ERROR_STATUS': '错误',
  'BATCH_HISTORY.CANCELLED_STATUS': '已取消',
	'BATCH_HISTORY.SUBMITTED_TOOLTIP': '文件上载成功，等待处理',
	'BATCH_HISTORY.SCHEDULED_TOOLTIP': '文件上载成功，已安排处理',
	'BATCH_HISTORY.IN_PROGRESS_TOOLTIP': '正在处理中',
	'BATCH_HISTORY.COMPLETED_TOOLTIP': '文件处理成功',
  'BATCH_HISTORY.ERROR_TOOLTIP': '处理过程中出错',
  'BATCH_HISTORY.CANCELLED_TOOLTIP': '已取消提示信息',
};
