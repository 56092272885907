import { Status } from '../../../../utils/constants';

/* ******************** ADD ACCOUNT ******************** */
export const addAccountRequest = (state) =>
	state.onboarding.accounts.addAccount.status === Status.PENDING;
export const addAccountFail = (state) =>
	state.onboarding.accounts.addAccount.status === Status.FAIL;
export const addAccountSuccess = (state) =>
	state.onboarding.accounts.addAccount.data;

/* ******************** GET ACCOUNT ******************** */
export const getAccountRequest = (state) =>
	state.onboarding.accounts.getAccount.status === Status.PENDING;
export const getAccountFail = (state) =>
	state.onboarding.accounts.getAccount.status === Status.FAIL;
export const getAccountSuccess = (state) =>
  state.onboarding.accounts.getAccount.data;

/* ******************** EDIT ACCOUNT ******************** */
export const editAccountRequest = (state) =>
	state.onboarding.accounts.editAccount.status === Status.PENDING;
export const editAccountFail = (state) =>
	state.onboarding.accounts.editAccount.status === Status.FAIL;
export const editAccountSuccess = (state) =>
	state.onboarding.accounts.editAccount.status === Status.DONE;

/* ******************** GET ACCOUNTS ******************** */
export const getAccountsRequest = (state) =>
	state.onboarding.accounts.getAccounts.status === Status.PENDING;
export const getAccountsFail = (state) =>
	state.onboarding.accounts.getAccounts.status === Status.FAIL;
export const getAccountsSuccess = (state) =>
	state.onboarding.accounts.getAccounts.data;

/* ******************** GET PLATFORMS ******************** */
export const getPlatformsRequest = (state) =>
	state.onboarding.accounts.getPlatforms.status === Status.PENDING;
export const getPlatformsFail = (state) =>
	state.onboarding.accounts.getPlatforms.status === Status.FAIL;
export const getPlatformsSuccess = (state) =>
	state.onboarding.accounts.getPlatforms.data;

/* ******************** GET PARENT ACCOUNT ******************** */
export const getParentAccountRequest = (state) =>
	state.onboarding.accounts.getParentAccount.status === Status.PENDING;
export const getParentAccountFail = (state) =>
	state.onboarding.accounts.getParentAccount.status === Status.FAIL;
export const getParentAccountSuccess = (state) =>
	state.onboarding.accounts.getParentAccount.data;

/* ******************** GET ACCOUNTS ******************** */
/* export const getAccountsRequest = (state) =>
	state.myCompany.accounts.getAccounts.status === Status.PENDING;
export const getAccountsFail = (state) =>
	state.myCompany.accounts.getAccounts.status === Status.FAIL;
export const getAccountsSuccess = (state) =>
	state.myCompany.accounts.getAccounts.data; */

/* ******************** GET ACCOUNTS ******************** */
/* export const getAccountRequest = (state) =>
	state.myCompany.accounts.getAccount.status === Status.PENDING;
export const getAccountFail = (state) =>
	state.myCompany.accounts.getAccount.status === Status.FAIL;
export const getAccountSuccess = (state) =>
	state.myCompany.accounts.getAccount.data; */

/* ******************** ADD SUB ACCOUNT ******************** */
export const addSubAccountRequest = (state) =>
	state.onboarding.accounts.addSubAccount.status === Status.PENDING;
export const addSubAccountFail = (state) =>
	state.onboarding.accounts.addSubAccount.status === Status.FAIL;
export const addSubAccountSuccess = (state) =>
	state.onboarding.accounts.addSubAccount.data;

/* ******************** ACCOUNT ACTIVATION ******************** */
export const accountActivationRequest = (state) =>
state.onboarding.accounts.activateAccount.status === Status.PENDING;
export const accountActivationFail = (state) =>
state.onboarding.accounts.activateAccount.status === Status.FAIL;
export const accountActivationData = (state) =>
state.onboarding.accounts.activateAccount.data;

/* ******************** REQUEST ACCESS ******************** */
export const requestAccessRequest = (state) =>
state.onboarding.accounts.requestAccess.status === Status.PENDING;
export const requestAccessFail = (state) =>
state.onboarding.accounts.requestAccess.status === Status.FAIL;
export const requestAccessData = (state) =>
state.onboarding.accounts.requestAccess.data;

/* ******************** GRANT ACCESS ******************** */
export const grantAccessRequest = (state) =>
state.onboarding.accounts.grantAccess.status === Status.PENDING;
export const grantAccessFail = (state) =>
state.onboarding.accounts.grantAccess.status === Status.FAIL;
export const grantAccessSuccess = (state) =>
state.onboarding.accounts.grantAccess.data &&
state.onboarding.accounts.grantAccess.data.status === 'Success';

/* ******************** GET PLATFORM TYPES ******************** */
export const getPlatformTypesRequest = (state) =>
	state.onboarding.accounts.getPlatformTypes.status === Status.PENDING;
export const getPlatformTypesFail = (state) =>
	state.onboarding.accounts.getPlatformTypes.status === Status.FAIL;
export const getPlatformTypesSuccess = (state) =>
	state.onboarding.accounts.getPlatformTypes.data;

/* ******************** VALIDATE ACCOUNT ******************** */
export const validateAccountRequest = (state) =>
	state.onboarding.accounts.validateAccount.status === Status.PENDING;
export const validateAccountFail = (state) =>
	state.onboarding.accounts.validateAccount.status === Status.FAIL;
export const validateAccountSuccess = (state) =>
	state.onboarding.accounts.validateAccount.data;
