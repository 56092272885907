import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';
import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';
import AccountInfoOverview from '../AccountInfoOverview';

import styles from './RatePlanDetails.scss';
import StaticField from '../../../../lib/DigitalComponents/StaticField';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import SectionHeadline from '../../../../lib/DigitalComponents/SectionHeadline';
import Divider from '../../../../lib/DigitalComponents/Divider';

class RatePlanDetails extends Component {
	componentDidMount() {
		const {
			getRatePlan,
			match: {
				params: { accountId, m2mAccountId, companyId, ratePlanId }
			}
		} = this.props;
		if (ratePlanId) {
			getRatePlan({
				companyId,
				m2mAccountId,
				id: ratePlanId
			});
		}
		if (accountId) {
			this.getAccount(accountId);
		}
	}

	componentWillUnmount() {
		const { ratePlanInit, getAccountInit } = this.props;
		ratePlanInit();
		getAccountInit();
	}

	getAccount = (id) => {
		const { getAccount } = this.props;
		getAccount({
			id,
			additionalParams: {
				include: [
					'Company',
					'PlatformType',
					'PlatformMobileOperator',
					'PlatformSatelliteOperator'
				]
			}
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId, accountId, m2mAccountId, platformType }
			}
		} = this.props;
		pushBack(
			`/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/rate-plans`
		);
	};

	render() {
		const {
			messages,
			ratePlanRequest,
			ratePlan,
			account,
			accountFail,
			accountRequest,
			intl
		} = this.props;

		if (ratePlanRequest || accountRequest) {
			return <Loading data-spec="loader" />;
		}
		return (
			<div data-spec="edit-rate-plan">
				<PageTitle
					title={intl.formatMessage({
						id: 'ONBOARDING.RATE_PLAN_DETAILS',
						defaultMessage: 'Rate Plan Details'
					})}
					pushBack={this.pushBack}
					messages={messages}
				/>

				<div className={styles.rate_plan_wrapper}>
					{accountFail && !accountRequest && null}

					{account && !accountRequest && (
						<AccountInfoOverview
							account={account}
							title={
								account &&
								account.company &&
								account.company.companyFriendlyName
							}
						/>
					)}
					{ratePlan && !ratePlanRequest && (
						<div className={styles.content}>
							<SectionHeadline
								title={intl.formatMessage({
									id: 'ONBOARDING.RATE_PLAN_CONFIGURATION',
									defaultMessage: 'Rate Plan Configuration'
								})}
							/>
							<section>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.RATE_PLAN_ID',
										defaultMessage: 'Rate Plan ID'
									})}
									data={ratePlan.id}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.PER_SUBSCRIBER_MRC',
										defaultMessage: 'Per Subscriber MRC'
									})}
									data={ratePlan.perSubscriberMRC}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.SUBSCRIPTION_CHARGE_UNIT',
										defaultMessage: 'Subscription Charge Unit'
									})}
									data={ratePlan.currency}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.PAYMENT_TYPE',
										defaultMessage: 'Payment Type'
									})}
									data={ratePlan.paymentType}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.INCLUDED_DATA_USAGE',
										defaultMessage: 'Included Data Usage'
									})}
									data={ratePlan.includedDataUsage}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.USAGE_LIMIT',
										defaultMessage: 'Usage Limit'
									})}
									data={ratePlan.usageLimit}
								/>
								<StaticField
									label={intl.formatMessage({
										id: 'ONBOARDING.PLAN_TYPE',
										defaultMessage: 'Plan Type'
									})}
									data={ratePlan.subscriptionItemType}
								/>
								<FieldWrapper>
									<Checkbox
										label={intl.formatMessage({
											id: 'ONBOARDING.DEFAULT_RATE_PLAN',
											defaultMessage: 'Default Rate Plan'
										})}
										disabled
										checked={ratePlan.default}
									/>
								</FieldWrapper>
							</section>
						</div>
					)}

					<>
						<Divider />
						<div className={styles.section_buttons}>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.BACK_TO_RATE_PLANS"
										defaultMessage="Back to Rate Plans"
									/>
								}
								variant="primary"
								type="button"
								dataSpec="back-button"
								onClick={this.pushBack}
							/>
						</div>
					</>
				</div>
			</div>
		);
	}
}

const { any, bool, func, object } = PropTypes;

RatePlanDetails.propTypes = {
	match: any,
	pushBack: func,
	messages: object,
	getRatePlan: func,
	ratePlanInit: func,
	ratePlanRequest: bool,
	ratePlan: object,
	getAccount: func,
	getAccountInit: func,
	account: object,
	accountFail: bool,
	accountRequest: bool,
	intl: object
};

export default injectIntl(RatePlanDetails);
