import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

const { shape } = PropTypes;

const customMediaQueryWrapper = (min, max) => {
	class CustomMediaQuery extends React.Component {
		returnComponent = () => {
      const { children } = this.props;
      return (
				<MediaQuery maxWidth={max} minWidth={min} data-spec="custom-media-query">
					{children}
				</MediaQuery>
			);
    }

		render() {
			return this.returnComponent();
		}
	}
	CustomMediaQuery.propTypes = {
		children: shape()
	};
	return CustomMediaQuery;
};

export default customMediaQueryWrapper;
