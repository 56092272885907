import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

import { isEqual } from 'lodash';
import DSDatePicker from '../../../../../lib/DigitalComponents/DatePicker';
import Button from '../../../../../lib/DigitalComponents/Button';
import { getDateFormat, startOfDayFormatter } from '../../../../../utils/constants';

import styles from './Steps.scss';

class StepSecond extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
    const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	shouldComponentUpdate(nextProps) {
		return Object.keys(nextProps.ratePlans).length;
	}

	componentDidUpdate(prevProps, prevState) {
    const { context } = this.props;
    const { selectedDay } = this.state;
		if (
			context &&
			!isEqual(selectedDay, prevState.selectedDay)
		) {
			this.setFooter(context);
		}
	}

	onNext = () => {
    const { onNext, ratePlans } = this.props;
    const { selectedDay } = this.state;
		const data = {
			ratePlanId: ratePlans.newRatePlan.ratePlan.id,
			startDate: startOfDayFormatter(selectedDay)
		};
		onNext({ data });
	};

	onDateChange = (date) => {
		this.setState({
			selectedDay: date
		});
	};

	setFooter = (context) => {
    const { onClose } = this.props;
    const { selectedDay } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onClose}
					label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
				/>
				<Button
					dataSpec="initiate-change"
					variant="primary"
					onClick={this.onNext}
					label={
						getDateFormat(new Date()) !== getDateFormat(selectedDay) ? (
							<FormattedMessage
								id="ENDPOINTS.SCHEDULE_CHANGE_BUTTON"
								defaultMessage="Schedule change"
							/>
						) : (
							<FormattedMessage
								id="ENDPOINTS.INITIATE_CHANGE"
								defaultMessage="Initiate change"
							/>
						)
					}
					disabled={
						selectedDay === undefined ||
						format(selectedDay, 'yyyy-MM-dd') < format(new Date(), 'yyyy-MM-dd')
					}
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
    const { ratePlans } = this.props;
    const { selectedDay } = this.state;
		const ratePlanName =
			ratePlans.newRatePlan &&
			ratePlans.newRatePlan.ratePlan.name;
		const simsCount =
			ratePlans.selectedSims &&
			ratePlans.selectedSims.length;

		return (
			<div data-spec="second-step">
				<div
					data-spec="ds-modal-body"
					className={cn('first-step-modal-body', styles.custom)}
				>
					<div
						className={cn(styles.select_field_label, styles.normal_font_weight)}
					>
						<FormattedMessage
							id="ENDPOINTS.CONFIRM_RATE_PLAN_CHANGE"
							defaultMessage="Confirm rate plan change"
						/>
					</div>
					<div className={styles.select_field_label_text}>
						<FormattedMessage
							id="ENDPOINTS.YOU_HAVE_CHOSEN_TO_CHANGE_THE_RATE_PLAN_OF_THE"
							defaultMessage="You have chosen to change the rate plan of the"
						/>
						{` ${simsCount} `}
						<FormattedMessage
							id="ENDPOINTS.SELECTED_SIMS_TO"
							defaultMessage="selected SIMs to"
						/>
						<span
							className={cn(styles.select_field_label_text, styles.rate_plan)}
						>
							&nbsp;
							{ratePlanName}
							.&nbsp;
						</span>
						<FormattedMessage
							id="ENDPOINTS.WOULD_YOU_LIKE_TO_INITIATE_CHANGE_NOW"
							defaultMessage="Would you like to initiate change now?"
						/>
					</div>
					<div
						className={cn(
							styles.select_field_wrapper,
							styles.rate_plan,
							styles.datepicker
						)}
					>
						<span>
							<FormattedMessage
								id="ENDPOINTS.OR_YOU_CAN_SCHEDULE_THE_CHANGE_FOR"
								defaultMessage="Or you can schedule the change for: "
							/>
						</span>
						<DSDatePicker value={selectedDay} onDayChange={this.onDateChange} />
					</div>
				</div>
			</div>
		);
	}
}

StepSecond.propTypes = {
	onClose: PropTypes.func,
	onNext: PropTypes.func,
	ratePlans: PropTypes.object,
	context: PropTypes.object
};

export default StepSecond;
