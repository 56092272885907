import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';

import { Status } from 'utils/constants';

import templateDownload from './templateDownload/reducer';

import {
	BATCH_FILE_UPLOAD_FAIL,
	BATCH_FILE_UPLOAD_REQUEST,
	BATCH_FILE_UPLOAD_SUCCESS,
	GET_BATCH_FILES_SUCCESS,
	GET_BATCH_FILES_REQUEST,
	GET_BATCH_FILES_FAIL,
	CLEAR_UPLOAD_ERRORS
} from './constants';

export const initialState = {
	uploadStatus: Status.INIT,
	getStatus: Status.INIT,
	batchFiles: null,
	errors: []
};

// this export we use for testingnpm
export const batchUploadMain = handleActions(
	{
		[BATCH_FILE_UPLOAD_FAIL]: (state, action) => ({
				...state,
				errors: action.payload.error,
				uploadStatus: Status.FAIL
			}),
		[BATCH_FILE_UPLOAD_REQUEST]: (state) => ({
				...state,
				uploadStatus: Status.PENDING
			}),
		[BATCH_FILE_UPLOAD_SUCCESS]: (state) => ({
				...state,
				uploadStatus: Status.DONE
			}),
		[GET_BATCH_FILES_FAIL]: (state) => ({
				...state,
				getStatus: Status.FAIL
			}),
		[GET_BATCH_FILES_REQUEST]: (state) => ({
				...state,
				getStatus: Status.PENDING,
				batchFiles: null
			}),
		[GET_BATCH_FILES_SUCCESS]: (state, action) => ({
				...state,
				getStatus: Status.DONE,
				batchFiles: action.payload
			}),
		[CLEAR_UPLOAD_ERRORS]: (state) => ({
				...state,
				errors: [],
				uploadStatus: Status.INIT
			})
	},
	initialState
);

export default combineReducers({
	batchUploadMain,
	templateDownload
});
