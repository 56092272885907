import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import DefaultIcon from '../../../../../brands/default/images/svgs/analytic.svg';

import styles from './NoChartData.scss';

const NoData = ({ endpointDetails }) => (
	<div data-spec="no-charts-message" className={styles.dashboard__default}>
		<div className={styles.default__icon}>
			<DefaultIcon />
		</div>
		<div className={styles.default__text}>
			{endpointDetails ? (
				<FormattedMessage
					id="ENDPOINT_DETAILS_SECOND_CARD_NO_DATA"
					defaultMessage="The data for this endpoint is temporary unavailable"
				/>
			) : (
				<FormattedMessage
					id="NO_CHART_DATA"
					defaultMessage="Missing Graph Data"
				/>
			)}
		</div>
	</div>
);

NoData.propTypes = { endpointDetails: PropTypes.bool };

NoData.defaultProps = { endpointDetails: false };

export default NoData;
