import React, { PureComponent } from 'react';
import { format } from 'date-fns';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import NoData from '../../../Shared/views/NoDataView';
import Loader from '../../../../lib/DigitalComponents/Loader';
import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalTabs from '../../../../lib/DigitalComponents/DSModal/ModalTabs';

import styles from './NotesModal.scss';

const tabFormat = 'M.d.yyyy';
const noteFormat = 'MMM dd, yyyy';

class NotesModal extends PureComponent {
	componentDidMount() {
		const { getRuleNotes, id } = this.props;
		const data = {
			id,
			params: {
				additionalParams: {
					dataSort: 'createdAt desc'
				}
			}
		};
		getRuleNotes(data);
	}

	getTabs = () => {
		const { ruleNotes } = this.props;
		const tabs = ruleNotes.resultList.map((note) => ({
			id: note.id,
			displayName: format(new Date(note.createdAt), tabFormat),
			content: () => <>{this.renderContent(note)}</>
		}));
		return tabs;
	};

	renderContent = (note) => (
		<div className={styles.note}>
			<div className={styles.details}>
				<div className={cn(styles.detail, styles.created_by)}>
					<span className={styles.detail_title}>
						<FormattedMessage
							id="AUTOMATION.CREATED_BY"
							defaultMessage="Created By"
						/>
					</span>
					<span>{note.creatorId}</span>
				</div>
				<div className={styles.detail}>
					<span className={styles.detail_title}>
						<FormattedMessage
							id="AUTOMATION.DATE_CREATED"
							defaultMessage="Date Created"
						/>
					</span>
					<span>{format(new Date(note.createdAt), noteFormat)}</span>
				</div>
			</div>
			<div className={styles.note_content}>{note.note}</div>
		</div>
	);

	renderButtons = () => {
		const { onClose } = this.props;
		return (
			<div className={styles.buttons} data-spec="notes-modal-buttons">
				<Button
					variant="primary"
					type="button"
					label={
						<FormattedMessage
							id={'AUTOMATION.CLOSE'}
							defaultMessage={'Close'}
						/>
					}
					onClick={onClose}
				/>
			</div>
		);
	};

	render() {
		const { show, onClose, ruleNotesRequest, ruleNotes } = this.props;
		return (
			<Modal
				data-spec="automation-request-deletion-modal"
				show={show}
				onClose={onClose}
			>
				<ModalHeader
					title={
						<FormattedMessage id="AUTOMATION.NOTES" defaultMessage="Notes" />
					}
				/>
				<ModalBody className={styles.modal__body}>
					{ruleNotesRequest && <Loader />}
					{!ruleNotesRequest && ruleNotes && ruleNotes.totalCount > 0 && (
						<ModalTabs tabs={this.getTabs()} data-spec="notes-tabs" />
					)}
					{!ruleNotesRequest && ruleNotes && ruleNotes.totalCount === 0 && (
						<div className={styles.no_data}>
							<NoData
								title={
									<FormattedMessage
										id="AUTOMATION_RULES.NO_RULE_NOTES_TITLE"
										defaultMessage="No Rule Notes Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="AUTOMATION_RULES.NO_RULE_NOTES_MESSAGE"
										defaultMessage="There are No Notes to show you right now"
									/>
								}
							/>
						</div>
					)}
				</ModalBody>
				<ModalFooter data-spec="ds-modal-footer">
					{this.renderButtons()}
				</ModalFooter>
			</Modal>
		);
	}
}

const { number, bool, func, object } = PropTypes;

NotesModal.propTypes = {
	id: number,
	show: bool,
	onClose: func,
	getRuleNotes: func,
	ruleNotesRequest: bool,
	ruleNotes: object
};

export default NotesModal;
