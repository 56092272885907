export default {
	'SWAGGER.DEVELOPER_API': 'Developer API',
	'SWAGGER.GETTING_STARTED': 'Getting started',
	'SWAGGER.API_SANDBOX': 'API Sandbox',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST':
		'The Swagger sandbox below specifies the list of resources that are available in the REST API and the operations that can be called on those resources. The document also specifies the list of parameters to an operation, including the name and type of the parameters, whether the parameters are required or optional, and information about acceptable values for those parameters.',
	'SWAGGER.JSON_SCHEMA_INCLUDES':
		'JSON Schema is included that describes the structure of the request body that is sent to an operation in a REST API, along with the JSON schema that describes the structure of any response bodies that are returned from an operation.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Getting started with API',
	'SWAGGER.GETTING_STARTED_WITH_API_P':
		'Before you can make your first API call, your source IP address (server) must be whitelisted. Source IP is typically configured during onboarding process when API access is created. If you receive any errors it is likely related to blocked access to AT&T IoT Gateway.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T':
		'Please ensure you have taken the folloing steps to get started with development:',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1':
		'- You have set up a publicly accessible server and provided us it’s IP address during onboarding.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2':
		'- Your Source IP is whitelisted to access AT&T IoT Gateway.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3':
		'- Your company Administrator has given you API username and password that was created when API service was enabled.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4':
		'- You have access to your resource server to execute curl commands, or use connection apps written in your preferred language below.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'Get OAuth access token',
	'SWAGGER.GET_OAUTH_TOKEN_P':
		'Get OAuth token from AT&T IoT Identity using username and password supplied by your administrator using your preferred method below. Token is valid for 7 days.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'API connectors',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P':
		'Select from the examples below how you wish to connect to our API.',
	'SWAGGER.TEST_CONNECTION_T': 'Test connection to the IoT Gateway',
	'SWAGGER.TEST_CONNECTION_P': 'Curl example:',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Desired Result',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P':
		'If you get HTTP Response Code 200 OK, you are all set to begin your development.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T':
		'Troubleshooting Connection Errors',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P':
		"If you get any errors, first check to see if your authetication is properly set in the HTTPHeader, and the Access Token has not expired and needs to be refreshed. Also, ping AT&T IoT Gateway to check if your source IP is white-listed. If you get unexpected results you may file a troubleshooting ticket in support section.'"
};
