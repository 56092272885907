import { Status } from '../../../../utils/constants';

/* GET CATEGORIES */
export const getCategoriesRequest = (state) =>
	state.ticketing.options.getCategories.status === Status.PENDING;
export const getCategoriesFail = (state) =>
	state.ticketing.options.getCategories.status === Status.FAIL;
export const getCategoriesSuccess = (state) =>
	state.ticketing.options.getCategories.data;

/* GET FIRST SUBCATEGORIES */
export const getFirstSubCategoriesRequest = (state) =>
	state.ticketing.options.getFirstSubCategories.status === Status.PENDING;
export const getFirstSubCategoriesFail = (state) =>
	state.ticketing.options.getFirstSubCategories.status === Status.FAIL;
export const getFirstSubCategoriesSuccess = (state) =>
	state.ticketing.options.getFirstSubCategories.data;

/* GET SECOND SUBCATEGORIES */
export const getSecondSubCategoriesRequest = (state) =>
	state.ticketing.options.getSecondSubCategories.status === Status.PENDING;
export const getSecondSubCategoriesFail = (state) =>
	state.ticketing.options.getSecondSubCategories.status === Status.FAIL;
export const getSecondSubCategoriesSuccess = (state) =>
	state.ticketing.options.getSecondSubCategories.data;

/* GET THIRD SUBCATEGORIES */
export const getThirdSubCategoriesRequest = (state) =>
	state.ticketing.options.getThirdSubCategories.status === Status.PENDING;
export const getThirdSubCategoriesFail = (state) =>
	state.ticketing.options.getThirdSubCategories.status === Status.FAIL;
export const getThirdSubCategoriesSuccess = (state) =>
	state.ticketing.options.getThirdSubCategories.data;

/* GET SEVERITIES */
export const getSeveritiesRequest = (state) =>
	state.ticketing.options.getSeverities.status === Status.PENDING;
export const getSeveritiesFail = (state) =>
	state.ticketing.options.getSeverities.status === Status.FAIL;
export const getSeveritiesSuccess = (state) =>
	state.ticketing.options.getSeverities.data;

/* GET PRIORITIES */
export const getPrioritiesRequest = (state) =>
	state.ticketing.options.getPriorities.status === Status.PENDING;
export const getPrioritiesFail = (state) =>
	state.ticketing.options.getPriorities.status === Status.FAIL;
export const getPrioritiesSuccess = (state) =>
	state.ticketing.options.getPriorities.data;

/* GET COUNTRIES */
export const getCountriesRequest = (state) =>
	state.ticketing.options.getCountries.status === Status.PENDING;
export const getCountriesFail = (state) =>
	state.ticketing.options.getCountries.status === Status.FAIL;
export const getCountriesSuccess = (state) =>
	state.ticketing.options.getCountries.data;

/* GET STATES */
export const getStatesRequest = (state) =>
	state.ticketing.options.getStates.status === Status.PENDING;
export const getStatesFail = (state) =>
	state.ticketing.options.getStates.status === Status.FAIL;
export const getStatesSuccess = (state) =>
	state.ticketing.options.getStates.data;

/* GET CONFIRMATION */
export const getConfirmationRequest = (state) =>
	state.ticketing.options.getConfirmation.status === Status.PENDING;
export const getConfirmationFail = (state) =>
	state.ticketing.options.getConfirmation.status === Status.FAIL;
export const getConfirmationSuccess = (state) =>
  state.ticketing.options.getConfirmation.data;

/* GET HISTORY */
export const getHistoryRequest = (state) =>
	state.ticketing.options.getHistory.status === Status.PENDING;
export const getHistoryFail = (state) =>
	state.ticketing.options.getHistory.status === Status.FAIL;
export const getHistorySuccess = (state) =>
	state.ticketing.options.getHistory.data;
