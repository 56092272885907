import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import DSSteps from 'lib/DigitalComponents/Steps';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';

import { getBatchFileUploadFail } from '../../../../redux/batchFiles/selectors';
import Notifications from '../../../../lib/DigitalComponents/Notifications';
import { clearUploadErrors as clearUploadErrorsAction } from '../../../../redux/batchFiles/actions';
import { updateBulkm2mAccountErrorClear as updateBulkm2mAccountErrorClearAction } from '../../redux/updateBulkm2mAccount/actions';
import styles from './BatchUpload.scss';
// batch upload steps
import StepFirst from './BatchSteps/StepFirst';
import StepSecond from './BatchSteps/StepSecond';
import StepThird from './BatchSteps/StepThird';
import {
	BulkUploadModalContextProvider,
	BulkUploadModalContextConsumer,
	BulkUploadModalContext
} from './BulkUploadModalContext';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';

class BulkUploadModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			batchUpload: {},
			disabledFirst: true,
			disabledSecond: false,
			disabledThird: true
		};
	}

	modalHide = () => {
    const {
			onHide,
			clearError,
			clearUploadErrors,
			updateBulkm2mAccountErrorClear,
			type,
			fetchAllEndpoints
		} = this.props;
		onHide();
	  clearError();
		clearUploadErrors();
		updateBulkm2mAccountErrorClear();
		if (type !== 'securityFeatures') {
			fetchAllEndpoints();
		}
	};

	mergeValues = (values) => {
		const { batchUpload } = this.state;
		this.setState({
			batchUpload: {
				...batchUpload,
				...values
			}
		});
	};

	onFinish = () => {
		this.modalHide();
	};

	renderNotifications = () => {
    const { intl } = this.props;
		const item = {
			id: 'batch-modal-error',
			type: 'error',
			title: intl.formatMessage({ id: 'ERROR', defaultMessage: 'Error' }),
			message: [
				intl.formatMessage({
					id: 'VALIDATION_FAILED',
					defaultMessage: 'Validation Failed'
				})
			],
			notificationType: 'general'
		};

		return <Notifications data-spec="notification" {...item} />;
	};

	render() {
    const { type, show, onHide, batchUploadFail, intl, bulkInstructionsCallback } = this.props;
    const {
			batchUpload,
			disabledFirst,
			disabledSecond,
			disabledThird
		} = this.state;
		const customSteps = [
			{
				label: `1. ${intl.formatMessage({
					id: 'ENDPOINTS.UPLOAD_FILE',
					defaultMessage: 'Upload file'
				})}`,
				component: (
					<BulkUploadModalContextConsumer>
						<StepFirst
							type={type}
							batchUpload={batchUpload}
							disabledFirst={disabledFirst}
							onClose={this.modalHide}
							bulkInstructionsCallback={bulkInstructionsCallback}
						/>
					</BulkUploadModalContextConsumer>
				)
			},
			{
				label: `2. ${intl.formatMessage({
					id: 'ENDPOINTS.CONFIRM_CHANGES',
					defaultMessage: 'Confirm changes'
				})}`,
				component: (
					<BulkUploadModalContextConsumer>
						<StepSecond
							type={type}
							batchUpload={batchUpload}
							disabledSecond={disabledSecond}
							onClose={this.modalHide}
						/>
					</BulkUploadModalContextConsumer>
				)
			},
			{
				label: `3. ${intl.formatMessage({
					id: 'ENDPOINTS.SYSTEM_CONFIRMATION',
					defaultMessage: 'System confirmation'
				})}`,
				component: (
					<BulkUploadModalContextConsumer>
						<StepThird
							type={type}
							batchUpload={batchUpload}
							disabledThird={disabledThird}
							onClose={this.modalHide}
						/>
					</BulkUploadModalContextConsumer>
				)
			}
		];

		return (
			<div data-spec="batch modal">
				<BulkUploadModalContextProvider>
					<Modal
						show={show}
						onClose={onHide}
						className={styles.modal}
					>
						<ModalHeader
							title={
								<FormattedMessage
									id="ENDPOINTS.BULK_UPLOAD"
									defaultMessage="Bulk upload"
								/>
							}
						/>
						<ModalBody className={styles.modal_body}>
							<div className={styles.modal_notification_wrapper}>
								{batchUploadFail && this.renderNotifications()}
							</div>
							<DSSteps
								steps={customSteps}
								onNext={this.mergeValues}
								noActions
								onBack={this.mergeValues}
								onFinish={this.onFinish}
							/>
						</ModalBody>
						<ModalFooter>
							<BulkUploadModalContext.Consumer>
								{(context) => context.state.footer}
							</BulkUploadModalContext.Consumer>
						</ModalFooter>
					</Modal>
				</BulkUploadModalContextProvider>
			</div>
		);
	}
}

const { bool, func, array, object, number, string } = PropTypes;

BulkUploadModal.propTypes = {
	type: string,
	isBatchUploadModalOpened: bool,
	onHide: func,
	clearError: func,
	clearUploadErrors: func,
	show: bool,
	notifications: array,
	updateBulkm2mAccountErrorClear: func,
	fetchAllEndpoints: func,
	dataLimit: number,
	batchUploadFail: bool,
	context: object,
	intl: object,
	bulkInstructionsCallback: func
};

const mapDispatchToProps = (dispatch) => ({
	clearUploadErrors: () => dispatch(clearUploadErrorsAction()),
	updateBulkm2mAccountErrorClear: () =>
		dispatch(updateBulkm2mAccountErrorClearAction())
});

const mapStateToProps = (state) => ({
		batchUploadFail: getBatchFileUploadFail(state)
	});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(injectIntl(BulkUploadModal));
