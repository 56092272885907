import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getNotifications = (params) =>
	ApiService.get(`/alerting/notifications/actives?${qs.stringify(params)}`);

export const getAllNotifications = (params) =>
	ApiService.get(`/alerting/notifications?${qs.stringify(params)}`);

export const getActiveNotifications = () =>
	ApiService.get('/alerting/notifications/actives/user');

export const addNotification = (params) => {
	let url = '/alerting/notifications';
	if (params.type === 'Global') {
		url = '/alerting/notifications/global';
	}
	return ApiService.post(url, params.data);
};

export const getNotificationsTypes = () =>
	ApiService.get('/alerting/notifications/types');

export const getNotificationsGroups = () =>
	ApiService.get('/alerting/notifications/group-types');

export const getNotification = (params) => {
	let url = `/alerting/notifications/actives/${params.id}?${qs.stringify(
		params.additionalParams
	)}`;
	if (params.type === 'Global') {
		url = `/alerting/notifications/actives/${params.id}/global?${qs.stringify(
			params.additionalParams
		)}`;
	}
	return ApiService.get(url);
};

export const editNotification = (params) => {
	let url = `/alerting/notifications/actives/${params.id}`;
	if (params.type === 'Global') {
		url = `/alerting/notifications/actives/${params.id}/global`;
	}
	return ApiService.patch(url, params.data);
};

export const removeNotification = (params) => {
	let url = `/alerting/notifications/actives/${params.id}`;
	if (params.type === 'Global') {
		url = `/alerting/notifications/actives/${params.id}/global`;
	}
	return ApiService.remove(url, { data: { id: params.id } });
};
