import { createAction } from 'redux-actions';

import { getChartData as service } from '../../services/AccountOverviewService';
import {
	GET_ACCOUNT_OVERVIEW_CHART_DATA_REQUEST,
	GET_ACCOUNT_OVERVIEW_CHART_DATA_SUCCESS,
	GET_ACCOUNT_OVERVIEW_CHART_DATA_ERROR,
	GET_ACCOUNT_OVERVIEW_CHART_DATA_INIT,
	CHECK_ACCOUNT_OVERVIEW_CHART_SETTINGS,
} from './constants';

const request = createAction(GET_ACCOUNT_OVERVIEW_CHART_DATA_REQUEST);
const success = createAction(GET_ACCOUNT_OVERVIEW_CHART_DATA_SUCCESS);
const fail = createAction(GET_ACCOUNT_OVERVIEW_CHART_DATA_ERROR);
export const initChartData = (id) =>
	createAction(GET_ACCOUNT_OVERVIEW_CHART_DATA_INIT)({ id });
export const checkChartSetting = (id) => createAction(
	CHECK_ACCOUNT_OVERVIEW_CHART_SETTINGS
)({ id });

const getChartData = (settingId, additionalParams) => async (dispatch) => {
	dispatch(request({ id: settingId }));
	return service(settingId.toString().split('_')[0], additionalParams)
		.then((response) => dispatch(success({ id: settingId, data: response })))
		.catch((error) => dispatch(fail({ id: settingId, error })));
};

export default getChartData;
