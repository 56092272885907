import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import {
	getUser,
	getPasswordResetUser,
	getUserInit
} from '../../redux/users/actions';

import {
	getUserRequest,
	getUserFail,
	getUserSuccess,
	getUserResetPasswordRequest,
	getUserResetPasswordFail,
	getUserResetPasswordSuccess,
} from '../../redux/users/selectors';

import {
	getUserSettings,
	getUser as currentUser
} from '../../../../redux/user/getUserData/selectors';

import UserProfileDesktop from './Desktop';
import UserProfileMobile from './Mobile';

const mapStateToProps = (state) => ({
	userRequest: getUserRequest(state),
	userFail: getUserFail(state),
	user: getUserSuccess(state),
	resetUserPasswordRequest: getUserResetPasswordRequest(state),
	resetUserPasswordFail: getUserResetPasswordFail(state),
	resetUserPassword: getUserResetPasswordSuccess(state),
	settings: getUserSettings(state, 3),
	currentUser: currentUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getUser: (id, params) => dispatch(getUser(id, params)),
	push: (url) => dispatch(localizedRoute(url)),
	goToIndividualPermissions: (url) => dispatch(localizedRoute(url)),
	goToEffectivePermissions: (url) => dispatch(localizedRoute(url)),
	goToEditProfile: (url) => dispatch(localizedRoute(url)),
	getPasswordResetUser: (params) => dispatch(getPasswordResetUser(params)),
	getUserInit: () => dispatch(getUserInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserProfileDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(UserProfileMobile);
