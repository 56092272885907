import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';

import AccessManagement from './AccessManagement';
import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';

import {
	getUser as currentUser
} from '../../../../redux/user/getUserData/selectors';

const mapStateToProps = (state) => ({
	currentUser: currentUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	push: (url) => dispatch(localizedRoute(url)),
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagement);
