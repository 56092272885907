import React, { PureComponent } from 'react';
import { func, object, bool } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AccountEndpointsTableHeader as tableHeader } from '../../utils/constants';

import Button from '../../../../lib/DigitalComponents/Button';

import Loading from '../../../../lib/DigitalComponents/Loader';
import NoData from '../../../Shared/views/NoDataView';
import ActionBar from '../../../Shared/views/ActionBarView';
import MoveToSubAccountModal from '../MoveToSubAccountModal';

const AccountEndpoints = () => (WrappedComponent) => {
	class AccountEndpointsComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				selectedEndpoints: []
			};
		}

		componentDidMount() {
			const { account } = this.props;
			this.getEndpoints(account.m2mAccountId);
		}

		componentDidUpdate(prevProps) {
			const { account, moveEndpointsSuccess } = this.props;
			if (prevProps.moveEndpointsSuccess !== moveEndpointsSuccess) {
				this.getEndpoints(account.m2mAccountId);
			}
		}

		getEndpoints = (m2mAccountId) => {
			const { getEndpoints, isMyCompany } = this.props;
			getEndpoints({
				m2mAccountId,
				searchParams: [
					{ propValue: 'ASSIGNED', prop: 'simStatus', operator: '=' }
				],
				isMyCompany
			});
		};

		onCheckboxChange = (data) => {
			const { endpoints } = this.props;
			let selectedIndexes = [];
			if (data && data.selected) {
				selectedIndexes = data.selected.map(
					(item) => endpoints.resultList[item].id
				);
			}
			this.setState({
				selectedEndpoints: [...selectedIndexes]
			});
		};

		getOptions = (/* target */) => {
			const options = {};

			options.header = tableHeader;

			options.tableOptions = {
				showPagination: false,
				selectRows: true,
				enableSelectAllRows: true,
				firstColumnMarked: true,
				onCheckBoxChange: (data) => this.onCheckboxChange(data)
			};

			options.customComponents = {};
			return options;
		};

		openMoveToSubAccountModal = () => {
			const { openMoveToSubAccountModal } = this.props;
			openMoveToSubAccountModal();
		};

		render() {
			const {
				endpointsRequest,
				endpointsFail,
				endpoints,
				isMoveToSubAccountModalOpen,
				account,
				closeMoveToSubAccountModal
			} = this.props;
			const { selectedEndpoints } = this.state;

			return (
				<div data-spec="endpoints">
					<ActionBar
						actions={
							<Button
								variant="primary"
								label={
									<FormattedMessage
										id="ONBOARDING.MOVE_TO_SUB_ACCOUNT"
										defaultMessage="Move to Sub-Account"
									/>
								}
								dataSpec="move-to-sub-account-button"
								onClick={this.openMoveToSubAccountModal}
								disabled={endpointsRequest || selectedEndpoints.length === 0}
							/>
						}
					/>
					{endpointsRequest && <Loading data-spec="loading" />}
					{endpointsFail && null}
					{!endpointsRequest &&
						endpoints &&
						endpoints.resultList &&
						endpoints.resultList.length > 0 && (
							<WrappedComponent
								{...this.state}
								{...this.props}
								getOptions={this.getOptions}
							/>
						)}
					{endpoints &&
						endpoints.resultList &&
						endpoints.resultList.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.NO_ENDPOINTS_AVAILABLE"
										defaultMessage="No Endpoints Available."
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_ENDPOINTS_AVAILABLE_SUBTITLE"
										defaultMessage="There are No Endpoints Available for this Table to show you right now"
									/>
								}
							/>
						)}
					{isMoveToSubAccountModalOpen && (
						<MoveToSubAccountModal
							account={account}
							onClose={closeMoveToSubAccountModal}
							endpoints={selectedEndpoints}
						/>
					)}
				</div>
			);
		}
	}

	AccountEndpointsComponent.propTypes = {
		getEndpoints: func,
		endpointsRequest: bool,
		endpointsFail: bool,
		endpoints: object,
		account: object,
		isMoveToSubAccountModalOpen: bool,
		openMoveToSubAccountModal: func,
		closeMoveToSubAccountModal: func,
		moveEndpointsSuccess: object,
		isMyCompany: bool
	};
	return AccountEndpointsComponent;
};

export default AccountEndpoints;
