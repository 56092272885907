export default {
	'USER_FEEDBACK.TRIGGER_TITLE': 'Hebt u feedback?',
	'USER_FEEDBACK.TRIGGER_SUBTITLE': 'Vertel het ons hier',
	'USER_FEEDBACK.ADDITIONAL_FILE': 'Aanvullend bestand',
	'USER_FEEDBACK.PAGE_LABEL': 'Uw feedback betreft',
	'USER_FEEDBACK.FEED_LABEL': 'Vertel in uw eigen woorden wat we kunnen verbeteren?',
	'USER_FEEDBACK.FEED_PLACEHOLDER': 'Noteer hier uw feedback ...',
	'USER_FEEDBACK.CONTACT_LABEL': 'Mogen we hierover zo nodig contact met u opnemen?',
	'USER_FEEDBACK.EMAIL_LABEL': 'E-mail',
	'USER_FEEDBACK.PHONE_LABEL': 'Telefoon',
	'USER_FEEDBACK.SEND_FEEDBACK': 'Feedback verzenden',
	'USER_FEEDBACK.ICON': 'Pictogram Feedback verzonden',
	'USER_FEEDBACK.SUCCESS_THANKS': 'Bedankt voor de feedback.',
	'USER_FEEDBACK.SUCCESS_MESSAGE': 'Met uw feedback kunnen we ons product verbeteren. We zullen uw opmerkingen zorgvuldig doornemen en navenant handelen.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SUB': 'Ook kunnen we contact met u opnemen voor meer informatie over uw suggestie.',
	'USER_FEEDBACK.SUCCESS_MESSAGE_SIGN': 'IoT Console-team',
	'USER_FEEDBACK.SUCCESS_DONE_BUTTON': 'Gereed',
	'USER_FEEDBACK.TITLE': 'Gebruikersfeedback',
	'USER_FEEDBACK.FILE_UPLOAD_FAIL': 'Bestand uploaden mislukt'
};
