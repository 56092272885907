/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import Loading from '@mc/ds-components-nt/ui/Loader';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './Date.scss';

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/downloadable-file-l.svg'
);

const ReportRepostIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/copy-icon.svg'
);

class Date extends Component {
	constructor(props) {
		super(props);

		this.state = {
			[`report${this.props.uniqueId}`]: props.data.date[0].fullDate,
			yearOfGeneration: props.data.date[0].yearOfGeneration,
			monthOfGeneration: props.data.date[0].monthOfGeneration,
			dateOfGeneration: props.data.date[0].dateOfGeneration,
			fullReportFileName: props.data.date[0].fullReportFileName,
			value: props.data.date[0].fullDate,
			label: this.getFullDate(props.data.date[0].fullDate),
			isRepostPending: false
		};
	}

	componentDidUpdate(prevProps) {
		const { sfgRepostRequest } = this.props;
		if (
			prevProps.sfgRepostRequest
			&& !sfgRepostRequest
			&& this.state.isRepostPending
		) {
			this.checkSfgRepost(prevProps);
		}
	}

	checkSfgRepost = (prevProps) => {
		const {
			sfgRepostSuccess,
			sfgRepostFail,
			intl,
			sfgRepostNotification
		} = this.props;
		this.resetLoader();
		if (!prevProps.sfgRepostSuccess && sfgRepostSuccess) {
			const notification = {
				message: intl.formatMessage(
					{
						id: 'REPORTS.REPORT_REPOST_SUCCESS'
					},
					{
						fileName: this.state.fullReportFileName
					}
				),
				type: 'success'
			};
			sfgRepostNotification(notification);
		} else if (!prevProps.sfgRepostFail && sfgRepostFail) {
			const notification = {
				message: intl.formatMessage(
					{
						id: 'REPORTS.REPORT_REPOST_FAIL'
					},
					{
						fileName: this.state.fullReportFileName
					}
				),
				type: 'error'
			};
			sfgRepostNotification(notification);
		}
	}

	resetLoader = () => this.setState((prevState) => ({ ...prevState, isRepostPending: false }));

	options = (data) => data && data.date && data.date.map((item) => {
			let dateString = 'Invalid date';
			if (item.fullDate) {
				dateString = this.getFullDate(item.fullDate);
			}
			return {
				value: item.fullDate,
				label: dateString,
				year: item.yearOfGeneration,
				month: item.monthOfGeneration,
				date: item.dateOfGeneration,
				fullReportFileName: item.fullReportFileName
			};
		});

	getFullDate = (date) => {
		const year = date && date.slice(0, 4);
		const month = date && date.slice(5, 7);
		const day = date && date.slice(8, 10);
		const dateString = `${month}/${day}/${year}`;
		return dateString;
	}

	renderRepostButton = (isDateEmpty) => {
		const { data, uniqueId, sfgRepost } = this.props;
		return (
			(this.state[`report${uniqueId}`] && !isDateEmpty)
			&& !this.state.isRepostPending ? (
				<div
					className={styles.color}
					onClick={() => {
						this.setState((prevState) => ({ ...prevState, isRepostPending: true }));
						sfgRepost(
							this.state.fullReportFileName,
							data.serviceProvider,
							data.m2mAccountId,
							this.state.yearOfGeneration,
							this.state.monthOfGeneration,
							this.state.monthOfGeneration
						);
					}}
				>
					<ReportRepostIcon
						data-spec="repost-icon"
						className={cn(styles.icon)}
					/>
					<FormattedMessage id="REPOST" />
				</div>
			) : (
				<div className={cn(styles.color, styles.disabled)}>
					<Loading
						size={15}
						spinnerWidth={2}
						className={cn(styles.spinner__wrapper, styles.icon)}
					/>
					<FormattedMessage id="REPOSTING" />
				</div>
			)
		);
	}

	render() {
		const { data, uniqueId, downloadReport, dataLength, allowRepost } = this.props;
		const isDateEmpty = data && data.date && data.date.length === 0;
		const menuPosition = (uniqueId + 1) > (dataLength - 3) ? 'fixed' : 'absolute';
		return (
			<div data-spec="reports-date" className={styles.reports_date}>
				<Select
					className={styles.reports_select}
					name={`report${uniqueId}`}
					value={this.state}
					options={this.options(data)}
					onChange={(e) =>
						this.setState({
							[`report${uniqueId}`]: e.value,
							yearOfGeneration: e.year,
							monthOfGeneration: e.month,
							dateOfGeneration: e.date,
							fullReportFileName: e.fullReportFileName,
							value: e.value,
							label: this.getFullDate(e.value)
						})}
					menuPosition={menuPosition}
				/>
				{this.state[`report${uniqueId}`] && !isDateEmpty ? (
					<div
						className={styles.color}
						onClick={() => {
							downloadReport(
								this.state.fullReportFileName,
								data.reportFrequency,
								this.state[`report${uniqueId}`],
								data.serviceProvider,
								this.state.yearOfGeneration,
								this.state.monthOfGeneration,
								this.state.dateOfGeneration,
								this.props.fileCategory,
								data.m2mAccountId
							);
						}}
					>
						<DocumentDownloadIcon
							data-spec="download-icon"
							className={styles.icon}
						/>
						<FormattedMessage id="DOWNLOAD" />
					</div>
				) : (
					<div className={cn(styles.color, styles.disabled)}>
						<DocumentDownloadIcon
							data-spec="download-icon"
							className={cn(styles.icon, styles.disabled)}
						/>
						<FormattedMessage id="DOWNLOAD" />
					</div>
				)}
				{allowRepost && this.renderRepostButton(isDateEmpty)}
			</div>
		);
	}
}

Date.propTypes = {
	data: PropTypes.shape(),
	uniqueId: PropTypes.number,
	downloadReport: PropTypes.func,
	fileCategory: PropTypes.string,
	dataLength: PropTypes.number,
	sfgRepost: PropTypes.func,
	sfgRepostRequest: PropTypes.bool,
	sfgRepostSuccess: PropTypes.bool,
	sfgRepostFail: PropTypes.bool,
	sfgRepostNotification: PropTypes.func,
	intl: PropTypes.object,
	allowRepost: PropTypes.bool
};

export default injectIntl(Date);
