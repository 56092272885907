import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';

import Selector from '../../../../../lib/DigitalComponents/Selector';
import DSDateRangePicker from '../../../../../lib/DigitalComponents/DateRangePicker';
import {
	isCycle,
	isHistoricalCycle
} from '../../../../Shared/components/Charts/Helpers/helpers';

import styles from './Step.scss';

const StepSecond = ({
	groupByDate,
	to,
	from,
	onNext,
	attributes,
	dateRange,
	cycleArray,
	incrementArray,
	aggregationArray,
	currentStep,
	shouldRefresh,
	filters,
	chartType,
	dispatch,
	change,
	isMovingAverage,
	intl,
	handleConfirm,
}) => {
	const minDate = new Date();

	return (
		<div data-spec="analytics-step-second" className={styles.steps_container}>
			{!isCycle(groupByDate) &&
				(attributes && attributes.length > 0 ? (
					<div className={styles.fetch_data_wrapper}>
						{attributes &&
							attributes.map((group) => (
								<div data-spec="radio-button" key={group.name}>
									<FormattedMessage
										id={`ANALYTICS.${group.name.toUpperCase()}`}
										defaultMessage={`${group.name.toUpperCase()}`}
									>
										{(formattedValue) => (
											<Field
												component={Selector}
												name="groupBySelector"
												label={formattedValue}
												value={`${group.name}`}
												type="radio"
												onChange={() => {
													onNext();
													if (currentStep > 3) shouldRefresh();
												}}
											/>
										)}
									</FormattedMessage>
								</div>
							))}
					</div>
				) : (
					<div>
						<FormattedMessage
							id="ANALYTICS.NO_ATTRIBUTES"
							defaultMessage="There are no Group By Attributes available for this account"
						/>
					</div>
				))}
			<div>
				<span className={styles.steps_header}>
					<FormattedMessage
						id="ANALYTICS.DATE_RANGE"
						defaultMessage="DATE RANGE"
					/>
				</span>
				<div className={styles.steps_wrapper}>
					{dateRange.map((group) => (
						<div data-spec="radio-button" key={group.id}>
							<FormattedMessage
								id={`ANALYTICS.${group.label}`}
								defaultMessage={`${group.label}`}
							>
								{(formattedValue) => (
									<Field
										component={Selector}
										name="groupByDate"
										label={formattedValue}
										value={`${group.value}`}
										type="radio"
										onChange={(event, newValue, oldValue) => {
											if (isCycle(oldValue)) {
												dispatch(change('groupBySelector', ''));
											}
											if (currentStep > 3) shouldRefresh();
										}}
									/>
								)}
							</FormattedMessage>
						</div>
					))}
				</div>
				{groupByDate === 3 ? (
					<div className={styles.date_range_picker}>
						<DSDateRangePicker
							startDate={from}
							endDate={to}
							minDate={
								new Date(
									minDate.getFullYear() - 1,
									minDate.getMonth() - 1,
									minDate.getDate()
								)
							}
							handleConfirm={handleConfirm}
							buttonCancelLabel={intl.formatMessage({
								id: 'CANCEL',
								defaultMessage: 'Cancel',
							})}
							buttonConfirmLabel={intl.formatMessage({
								id: 'APPLY',
								defaultMessage: 'Apply',
							})}
							inputLabel={intl.formatMessage(
								{
									id: 'DATE_RANGE_INPUT_LABEL',
									defaultMessage: 'From: {startDate} To: {endDate}',
								},
								{
									startDate: intl.formatDate(
										from,
										{ month: 'short', day: '2-digit' }
									),
									endDate: intl.formatDate(
										to,
										{ month: 'short', day: '2-digit' }
									),
								}
							)}
						/>
					</div>
				) : null}
			</div>
			<div>
				<span className={styles.steps_header}>
					<FormattedMessage id="ANALYTICS.CYCLE" defaultMessage="CYCLE" />
				</span>
				<div className={styles.steps_wrapper}>
					{cycleArray.map((cycle) => (
						<div data-spec="radio-button" key={cycle.value}>
							<FormattedMessage
								id={`ANALYTICS.${cycle.label}`}
								defaultMessage={`${cycle.label}`}
							>
								{(formattedValue) => (
									<Field
										component={Selector}
										name="groupByDate"
										label={formattedValue}
										value={`${cycle.value}`}
										type="radio"
										onChange={(event, newValue, oldValue) => {
											if (!isCycle(oldValue)) {
												dispatch(change('groupBySelector', ''));
											}
											if (
												currentStep > 3 &&
												(parseInt(oldValue, 10) > 3 || newValue !== '6')
											) {
												shouldRefresh();
											}
										}}
									/>
								)}
							</FormattedMessage>
						</div>
					))}
				</div>
			</div>
			{isCycle(groupByDate) &&
				(filters ? (
					<div>
						<span className={styles.steps_header}>
							<FormattedMessage
								id="ANALYTICS.M2M_PLATFORM_TITLE"
								defaultMessage="M2M PLATFORM"
							/>
						</span>
						<div className={styles.steps_wrapper}>
							{filters &&
								filters.platform &&
								filters.platform.map((platform) => (
									<div data-spec="radio-button" key={platform}>
										<FormattedMessage
											id={`${platform}`}
											defaultMessage={`${platform}`}
										>
											{(formattedValue) => (
												<Field
													component={Selector}
													name="groupBySelector"
													label={formattedValue}
													value={`${platform}`}
													type="radio"
													onChange={() => {
														onNext();
														if (currentStep > 3) shouldRefresh();
													}}
												/>
											)}
										</FormattedMessage>
									</div>
								))}
						</div>
					</div>
				) : (
					<div>
						<FormattedMessage
							id="ANALYTICS.NO_ATTRIBUTES"
							defaultMessage="There are no Group By Attributes available for this account"
						/>
					</div>
				))}
			{!isHistoricalCycle(groupByDate) && chartType !== 'pie' && (
				<div>
					<span className={styles.steps_header}>
						<FormattedMessage
							id="ANALYTICS.INCREMENT_BY"
							defaultMessage="INCREMENT BY"
						/>
					</span>
					<div className={styles.steps_wrapper}>
						{incrementArray.map((group) => (
							<div data-spec="radio-button" key={group.value}>
								<FormattedMessage
									id={`ANALYTICS.${group.label}`}
									defaultMessage={`${group.label}`}
								>
									{(formattedValue) => (
										<Field
											component={Selector}
											name="groupByIncrement"
											label={formattedValue}
											value={`${group.value}`}
											type="radio"
											onChange={() => {
												if (currentStep > 3) shouldRefresh();
											}}
										/>
									)}
								</FormattedMessage>
							</div>
						))}
					</div>
				</div>
			)}
			<div>
				<span className={styles.steps_header}>
					<FormattedMessage id="ANALYTICS.SHOW" defaultMessage="SHOW" />
				</span>
				<div className={styles.steps_wrapper}>
					{aggregationArray
						.slice(0, isMovingAverage ? 3 : 2)
						.map((group) => (
							<div data-spec="radio-button" key={group.value}>
								<FormattedMessage
									id={`ANALYTICS.${group.label}`}
									defaultMessage={`${group.label}`}
								>
									{(formattedValue) => (
										<Field
											component={Selector}
											name="groupByShow"
											label={formattedValue}
											value={`${group.value}`}
											type="radio"
											onChange={() => {
												if (currentStep > 3) shouldRefresh();
											}}
										/>
									)}
								</FormattedMessage>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

const {
	object,
	func,
	number,
	string,
	arrayOf,
	instanceOf,
	objectOf,
	array,
	bool,
} = PropTypes;

StepSecond.propTypes = {
	onNext: func,
	from: instanceOf(Date),
	to: instanceOf(Date),
	attributes: arrayOf(object),
	dateRange: arrayOf(object),
	cycleArray: arrayOf(object),
	incrementArray: arrayOf(object),
	aggregationArray: arrayOf(object),
	currentStep: number,
	shouldRefresh: func,
	filters: objectOf(array),
	groupByDate: number,
	chartType: string,
	dispatch: func,
	change: func,
	isMovingAverage: bool,
	intl: object,
	handleConfirm: func,
};

StepSecond.defaultProps = {
	attributes: [],
	dateRange: [],
	cycleArray: [],
	incrementArray: [],
	aggregationArray: [],
	currentStep: 0,
	filters: {},
	groupByDate: 0,
	chartType: '',
	onNext: undefined,
	from: {},
	to: {},
	shouldRefresh: undefined,
	dispatch: undefined,
	change: undefined,
	isMovingAverage: false,
	intl: {},
	handleConfirm: undefined,
};

export default StepSecond;
