/* eslint-disable import/prefer-default-export */
import { connect } from 'react-redux';
import { localizedRoute } from '../../../../utils/helperFunctions';
import DesktopComponent from './Desktop';

import { selectedItems } from '../../redux/alerts/selectedItems/selectors';
import {
	getAutomationRulesPending,
	getAutomationAlertsPending
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	selectedItems: selectedItems(state),
	rulesRequest: getAutomationRulesPending(state),
	alertsRequest: getAutomationAlertsPending(state)
});

const mapDispatchToProps = (dispatch) => ({
	createNewRule: () => dispatch(localizedRoute('/automation/rule'))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopComponent);
