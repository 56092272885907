export default {
	// filtros
	CURRENCY: 'Moeda',
	BILLABLE: 'Faturável',
	TAX_STATUS: 'Status do imposto',
	SUBSCRIBER_STATUS: 'Status do assinante',
	MONTHLY_RATE_PLAN: 'Plano de tarifas mensal',
	STANDARD_RATE_PLAN: 'Plano de tarifas padrão',
	PREPAID_TERMS_CHARGED: 'Termos pré-pagos cobrados',
	PLAN_TYPE: 'Tipo de plano',
	ZONE: 'Zona',
	ROAMING_ZONE: 'Zona de roaming',
	GROUP_NAME: 'Nome do grupo',
	THE_PLAN_TYPE: 'O tipo de plano',
	ACTIVATION_TYPE: 'Tipo de ativação',
	CHARGE_TYPE: 'Tipo de cobrança',
	TECH_TYPE: 'Tipo Tec',
	PRIMARY_PLACE: 'Local principal',
	DISTRICT_COUNTY: 'Comarca do distrito',
	DISTRICT_CITY: 'Cidade do distrito',
	PRIMARY_PLACE_OF_USE: 'Local principal de utilização',
	// notificações
	'ANALYTICS_INVOICES.NOTIFICATION_SUCCESS': 'Configurações do usuário atualizadas com êxito',
	'ANALYTICS_INVOICES.NOTIFICATION_FAIL': 'Não foi possível atualizar configurações do usuário',
	// faturas
	'ANALYTICS_INVOICES.M2M_ACCOUNT_ID': 'ID da conta',
	'ANALYTICS_INVOICES.ADD_NOTE': 'Adicionar nota',
	'ANALYTICS_INVOICES.ADD_ATTACHMENT': 'Adicionar anexo',
	'ANALYTICS_INVOICES.COUNTRY_NAME': 'País',
	'ANALYTICS_INVOICES.CUSTOMER_ID': 'ID do cliente',
	'ANALYTICS_INVOICES.INVOICE_DATE': 'Data da fatura',
	'ANALYTICS_INVOICES.DOCUMENT_ID': 'ID do documento',
	'ANALYTICS_INVOICES.INVOICE_CUSTOMER_NAME': 'Subconta',
	'ANALYTICS_INVOICES.NOTES': 'Notas',
	'ANALYTICS_INVOICES.LINK': 'Faturas',
	'ANALYTICS_INVOICES.BY': 'Por ',
	'ANALYTICS_INVOICES.SWITCH_MESSAGE': 'Selecione os dados estatísticos a serem exibidos aqui',
	'ANALYTICS_INVOICES.NA': 'N/A',
	'ANALYTICS_INVOICES.YES': 'Sim',
	'ANALYTICS_INVOICES.NO': 'Não',
	'ANALYTICS_INVOICES.INVOICE_ID': 'ID da fatura',
	'ANALYTICS_INVOICES.BILLING_CYCLE': 'Ciclo de cobrança',
	'ANALYTICS_INVOICES.ACCOUNT_ID': 'ID da conta',
	'ANALYTICS_INVOICES.ACCOUNT_NAME': 'Nome da conta',
	'ANALYTICS_INVOICES.OPERATOR_ACCOUNT_ID': 'ID da conta do operador',
	'ANALYTICS_INVOICES.DEVICES': 'Dispositivos',
	'ANALYTICS_INVOICES.TOTAL_CHARGE': 'Cobrança total',
	'ANALYTICS_INVOICES.CURRENCY': 'Moeda',
	'ANALYTICS_INVOICES.DATA_VOLUME': 'Volume de dados (MB)',
	'ANALYTICS_INVOICES.SMS_VOLUME': 'Volume de SMS',
	'ANALYTICS_INVOICES.BILLABLE': 'Faturável (Sim ou Não)',
	'ANALYTICS_INVOICES.BILL': 'Faturável',
	'ANALYTICS_INVOICES.DATA_RATES': 'Tarifas de dados',
	'ANALYTICS_INVOICES.DATE': 'Data da fatura',
	'ANALYTICS_INVOICES.BILLING_DETAILS': 'Detalhes da cobrança',
	'ANALYTICS_INVOICES.DUE_DATE': 'Data de vencimento',
	'ANALYTICS_INVOICES.OPERATOR_ID': 'ID do operador',
	'ANALYTICS_INVOICES.PUBLISHED': 'Publicado',
	'ANALYTICS_INVOICES.STANDARD_OVERAGE': 'Excedente padrão',
	'ANALYTICS_INVOICES.STANDARD_ROAMING': 'Roaming padrão',
	'ANALYTICS_INVOICES.TAXABLE': 'Conta tributável',
	'ANALYTICS_INVOICES.TAX_STATUS': 'Status do imposto',
	'ANALYTICS_INVOICES.ACCOUNT_SEGMENT': 'Segmento da conta',
	'ANALYTICS_INVOICES.VIEW_PRINT_SAVE': 'Visualizar/Imprimir/Salvar',
	'ANALYTICS_INVOICES.INVOICE_TITLE': 'Detalhes da fatura',
	'ANALYTICS_INVOICES.BUTTON_DOWNLOAD_INVOICE': 'Baixar fatura',
	'ANALYTICS_INVOICES.ENDPOINTS': 'Endpoints',
	'ANALYTICS_INVOICES.PREPAID_SUBSCRIPTIONS': 'Assinaturas pré-pagas',
	'ANALYTICS_INVOICES.ADDONS': 'Complementos',
	'ANALYTICS_INVOICES.RATE_PLANS': 'Planos de tarifas',
	'ANALYTICS_INVOICES.RATE_PLAN_ZONES': 'Zonas do plano de tarifas',
	'ANALYTICS_INVOICES.BILLING_GROUPS': 'Grupos de cobrança',
	'ANALYTICS_INVOICES.DOWNLOAD': 'Baixar',
	'ANALYTICS_INVOICES.DOWNLOAD_PDF': 'Baixar PDF',
	'ANALYTICS_INVOICES.UPLOAD': 'Carregar',
	'ANALYTICS_INVOICES.UPLOAD_INVOICE': 'Carregar fatura',
	'ANALYTICS_INVOICES.ACTIVATIONS': 'Ativações',
	'ANALYTICS_INVOICES.DISCOUNTS': 'Descontos',
	'ANALYTICS_INVOICES.TAX': 'Imposto',
	'ANALYTICS_INVOICES.SURCHARGES': 'Sobretaxas',
	'ANALYTICS_INVOICES.TECH_TYPES': 'Tipos Tec',
	'ANALYTICS_INVOICES.OTHER_CHARGES': 'Outras cobranças',
	'ANALYTICS_INVOICES.NO_DATA': 'Nenhuma fatura disponível',
	'ANALYTICS_INVOICES.NO_DATA_SUBTITILE': 'Não há nenhuma fatura a ser exibida neste momento',
	'ANALYTICS_INVOICES.TITLE': 'Faturas',
	'ANALYTICS_INVOICES.DATA_DETAILS': 'Detalhes dos dados',
	'ANALYTICS_INVOICES.SMS_DETAILS': 'Detalhes de SMS',
	'ANALYTICS_INVOICES.TAXES': 'Impostos',
	// tabela dos endpoints
	'ANALYTICS_INVOICES.ICCID': 'ICCID',
	'ANALYTICS_INVOICES.MONTHLY_PRORATION_INDEX': 'Índice de rateio mensal',
	'ANALYTICS_INVOICES.DEVICE_ID': 'ID do dispositivo',
	'ANALYTICS_INVOICES.CUSTOMER': 'Cliente',
	'ANALYTICS_INVOICES.MONTHLY_RATE_PLAN': 'Plano de tarifas mensal',
	'ANALYTICS_INVOICES.PREPAID_TERMS_CHARGED': 'Termos pré-pagos cobrados',
	'ANALYTICS_INVOICES.STANDARD_RATE_PLAN': 'Plano de tarifas padrão',
	'ANALYTICS_INVOICES.SUBSCRIBER_STATUS': 'Status do assinante',
	'ANALYTICS_INVOICES.SUBSCRIPTION_CHARGE': 'Cobrança de assinaturas',
	'ANALYTICS_INVOICES.FIXED_POOL_CHARGE': 'Taxa de grupo fixa',
	'ANALYTICS_INVOICES.SMS_MO_VOLUME': 'Volume de MO de SMS (msg)',
	'ANALYTICS_INVOICES.SMS_MT_VOLUME': 'Volume MT de SMS (msg)',
	// tabela das assinaturas pré-pagas
	'ANALYTICS_INVOICES.MSISDN': 'MSISDN',
	'ANALYTICS_INVOICES.RATE_PLAN': 'Plano de tarifas',
	'ANALYTICS_INVOICES.PLAN_VERSION': 'Versão do plano',
	'ANALYTICS_INVOICES.PLAN_TYPE': 'Tipo de plano',
	'ANALYTICS_INVOICES.USAGE_PRORATION_INDEX': 'Índice de rateio do uso',
	'ANALYTICS_INVOICES.TERM_START_DATE': 'Data inicial do contrato',
	'ANALYTICS_INVOICES.TERM_END_DATE': 'Data final do contrato',
	'ANALYTICS_INVOICES.INCLUDED_DATA_USAGE': 'MB do uso de dados incluído',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO': 'MO de SMS incluído (mensagem)',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT': 'MT de SMS incluído (mensagem)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE': 'Voz incluída (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO': 'MO de voz incluído (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MT': 'MT de voz incluído (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD': 'CSD incluído (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MO': 'CSD MO incluído (m:ss)',
	'ANALYTICS_INVOICES.INCLUDED_CSD_MT': 'CSD MT incluído (m:ss)',
	'ANALYTICS_INVOICES.TERM_TO_DATE_DATA_USAGE': 'Uso de dados do início do contrato até o momento (zona principal)',
	'ANALYTICS_INVOICES.VOICE_VOLUME': 'Volume de voz (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MO_VOLUME': 'Volume de MO de voz (m:ss)',
	'ANALYTICS_INVOICES.VOICE_MT_VOLUME': 'Volume de MT de voz (m:ss)',
	// Tabela dos complementos
	'ANALYTICS_INVOICES.ADDON_SIZE': 'Tamanho adicional (MB)',
	'ANALYTICS_INVOICES.ZONE': 'Zona',
	'ANALYTICS_INVOICES.ADDON_CHARGES': 'Cobranças adicionais',
	'ANALYTICS_INVOICES.DATE_PURCHASED': 'Data da compra',
	'ANALYTICS_INVOICES.BASE_PLAN_APPLIED_TO': 'Plano básico aplicado a',
	// Tabela do plano de tarifas
	'ANALYTICS_INVOICES.ACTIVATION_PERIOD_COMMITMENT_SUBS': 'Assinaturas de comprometimento pelo período de ativação',
	'ANALYTICS_INVOICES.ACTIVE_SUBSCRIPTIONS': 'Assinaturas ativas',
	'ANALYTICS_INVOICES.ACTIVE_TIER': 'Nível ativo',
	'ANALYTICS_INVOICES.DATA_CHARGE': 'Cobrança de dados',
	'ANALYTICS_INVOICES.INCLUDED_DATA': 'Dados incluídos',
	'ANALYTICS_INVOICES.INCLUDED_DATA_MODE': 'Modo de dados incluído',
	'ANALYTICS_INVOICES.INCLUDED_SMS': 'SMS incluído',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MODE': 'Incluído no modo MO de SMS',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MODE': 'Incluído no modo SMS',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MT_MODE': 'Incluído no modo MT de SMS',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MO_MODE': 'Modo MO de voz incluído',
	'ANALYTICS_INVOICES.INCLUDED_VOICE_MODE': 'Modo de voz incluído',
	'ANALYTICS_INVOICES.MINIMUM_ACTIVATION_PERIOD_SUBS': 'Mínimo de assinaturas no período de ativação',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_CHARGE': 'Cobrança de dados não relacionados a roaming',
	'ANALYTICS_INVOICES.NON_ROAMING_DATA_VOLUME': 'Volume de dados não relacionados a roaming',
	'ANALYTICS_INVOICES.PAYMENT_TYPE': 'Tipo de pagamento',
	'ANALYTICS_INVOICES.PREPAID_ACTIVATIONS': 'Ativações pré-pagas',
	'ANALYTICS_INVOICES.ROAMING_DATA_CHARGE': 'Cobrança de dados de roaming',
	'ANALYTICS_INVOICES.ROAMING_DATA_VOLUME': 'Volume de dados de roaming',
	'ANALYTICS_INVOICES.SMS_CHARGE': 'Cobrança de SMS',
	'ANALYTICS_INVOICES.SUBSCRIPTION_FIELD': 'Campo de assinaturas',
	'ANALYTICS_INVOICES.VOICE_CHARGE': 'Cobrança de voz',
	// Tabela da zona do plano de tarifas
	'ANALYTICS_INVOICES.ACTIVE_OVERAGE_RATE': 'Taxa de excedente ativo',
	'ANALYTICS_INVOICES.INCLUDED_DATA_CAPPED': 'Alcançou limite de dados incluídos',
	'ANALYTICS_INVOICES.INCLUDED_MODE': 'Modo incluído',
	'ANALYTICS_INVOICES.PRIORITY': 'Prioridade',
	'ANALYTICS_INVOICES.ROAMING_ZONE': 'Zona de roaming',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_USAGE': 'Uso total incluído (MB)',
	//  Tabela dos grupos de cobrança
	'ANALYTICS_INVOICES.GROUP_NAME': 'Nome do grupo',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED': 'Total incluído',
	'ANALYTICS_INVOICES.SMS_MO_CHARGE': 'Cobrança de MO de SMS',
	'ANALYTICS_INVOICES.INCLUDED_SMS_MO_MSGS': 'MO de SMS incluído (mensagens)',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_SMS_MO_USAGE': 'Uso de MO de SMS total incluído',
	'ANALYTICS_INVOICES.TOTAL_INCLUDED_VOICE_MO': 'MO de voz total incluído',
	// Tabela dos detalhes dos dados
	'ANALYTICS_INVOICES.DAILY_ROUNDING_ADJUSTMENT': 'Arredondamento diário',
	'ANALYTICS_INVOICES.THE_PLAN_TYPE': 'O tipo de plano',
	// tabela dos detalhes de sms
	'ANALYTICS_INVOICES.MESSAGE_TYPE': 'Tipo de mensagem',
	// tabela das ativações
	'ANALYTICS_INVOICES.ACTIVATION_CHARGE': 'Cobrança de ativação',
	'ANALYTICS_INVOICES.ACTIVATION_TYPE': 'Tipo de ativação',
	'ANALYTICS_INVOICES.INITIAL_ACTIVATION_DATE': 'Data inicial de ativação',
	// tabela dos descontos
	'ANALYTICS_INVOICES.CHARGE_TYPE': 'Tipo de cobrança',
	'ANALYTICS_INVOICES.DISCOUNT': 'Desconto',
	// tabela dos impostos
	'ANALYTICS_INVOICES.PRIMARY_PLACE': 'Local principal',
	'ANALYTICS_INVOICES.MRC': 'MRC',
	'ANALYTICS_INVOICES.DATA': 'Dados',
	'ANALYTICS_INVOICES.SMS': 'SMS',
	'ANALYTICS_INVOICES.VOICE': 'Voz',
	'ANALYTICS_INVOICES.ACTIVATION': 'Ativação',
	'ANALYTICS_INVOICES.VPN': 'VPN',
	'ANALYTICS_INVOICES.SIM': 'SIM',
	'ANALYTICS_INVOICES.MRF': 'MRF',
	'ANALYTICS_INVOICES.PDP': 'PDP',
	'ANALYTICS_INVOICES.TECH_TYPE': 'Tipo Tec',
	// tabela das sobretaxas
	'ANALYTICS_INVOICES.PRIMARY_PLACE_OF_USE': 'Local principal de utilização',
	'ANALYTICS_INVOICES.FEDERAL': 'Federal',
	'ANALYTICS_INVOICES.STATE': 'Estado',
	'ANALYTICS_INVOICES.COUNTY': 'Comarca',
	'ANALYTICS_INVOICES.CITY': 'Cidade',
	'ANALYTICS_INVOICES.DISTRICT_COUNTY': 'Comarca do distrito',
	'ANALYTICS_INVOICES.DISTRICT_CITY': 'Cidade do distrito',
	// tabela tipo tec
	'ANALYTICS_INVOICES.PLAN_ID': 'ID do plano',
	'ANALYTICS_INVOICES.VERSION_ID': 'ID da versão',
	'ANALYTICS_INVOICES.NUMBER_OF_2G_DEVICES': 'Número de dispositivos 2G',
	'ANALYTICS_INVOICES.2G_TECH_TYPE_CHARGE': 'Cobrança tipo Tec 2G',
	'ANALYTICS_INVOICES.NUMBER_OF_3G_DEVICES': 'Número de dispositivos 3G',
	'ANALYTICS_INVOICES.3G_TECH_TYPE_CHARGE': 'Cobrança tipo Tec 3G',
	'ANALYTICS_INVOICES.NUMBER_OF_4G_DEVICES': 'Número de dispositivos 4G',
	'ANALYTICS_INVOICES.4G_TECH_TYPE_CHARGE': 'Cobrança tipo Tec 4G',
	'ANALYTICS_INVOICES.TECH_TYPE_CHARGE_FREQUENCY': 'Frequência das cobranças tipo Tec',
	'ANALYTICS_INVOICES.TOTAL_TECH_TYPE_CHARGE': 'Cobrança total tipo Tec',

	// Cobranças de assinaturas:
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGES': 'Cobranças de assinaturas',
	'ANALYTICS_INVOICES_DETAILS.SUBSCRIPTION_CHARGE': 'Cobrança de assinaturas',
	'ANALYTICS_INVOICES_DETAILS.DATA_CHARGE': 'Cobrança de dados',
	'ANALYTICS_INVOICES_DETAILS.SMS_CHARGES': 'Cobranças de SMS',
	'ANALYTICS_INVOICES_DETAILS.ACTIVATION_CHARGE': 'Cobrança de ativação',
	'ANALYTICS_INVOICES_DETAILS.TECH_TYPE_CHARGE': 'Cobrança tipo Tec',
	'ANALYTICS_INVOICES_DETAILS.FIXED_DISCOUNT_TARGET': 'Meta de desconto fixo',
	'ANALYTICS_INVOICES_DETAILS.TAX': 'Imposto',
	'ANALYTICS_INVOICES_DETAILS.TOTAL_CHARGE': 'Cobrança total',
	'ANALYTICS_INVOICES_DETAILS.SURCHARGE': 'Sobretaxa',
	'ANALYTICS_INVOICES_DETAILS.DUE_BY': 'Vencimento em ',

	// Estatísticas de endpoint:
	'ANALYTICS_INVOICES_DETAILS.ENDPOINT_STATS': 'Estatísticas de endpoint',
	'ANALYTICS_INVOICES_DETAILS.ENDPOINTS': 'ENDPOINTS',
	'ANALYTICS_INVOICES_DETAILS.ACTIVE_SUBSCRIBERS': 'ASSINANTES ATIVOS',
	'ANALYTICS_INVOICES_DETAILS.DATA_VOLUME': 'VOLUME DE DADOS',
	'ANALYTICS_INVOICES_DETAILS.SMS_VOLUME': 'VOLUME DE SMS',
	'ANALYTICS_INVOICES_DETAILS.SERVICE_REVENUE': 'RECEITA DE SERVIÇOS',
	'ANALYTICS_INVOICES_DETAILS.VARIABLE_DISCOUNT': 'DESCONTO VARIÁVEL',

	// tabela das outras cobranças
	'ANALYTICS_INVOICES.CHARGE_AMOUNT': 'Valor da cobrança',
	'ANALYTICS_INVOICES.DESCRIPTION': 'Descrição',
	'ANALYTICS_INVOICES.QUANTITY': 'Quantidade',
	'ANALYTICS_INVOICES.ORDER_NUMBER': 'Número do pedido',
	'ANALYTICS_INVOICES.REFERENCE_ID': 'ID de referência',
	//  espaços reservados
	'ANALYTICS_INVOICES.ENTER_YOUR_NOTE_HERE': 'Digitar sua nota aqui',
	'ANALYTICS_INVOICES.REMOVE': 'Remover',
	'ANALYTICS_INVOICES.EDIT_NOTE': 'Revisar nota',
	'ANALYTICS_INVOICES.CANCEL': 'Cancelar',
	'ANALYTICS_INVOICES.SUBMIT': 'Enviar',
	'ANALYTICS_INVOICES.ATTACHMENTS_ADDED': 'Anexos adicionados',
	//  revisar modal da tabela
	'ANALYTICS_INVOICES.APPLY_CHANGE': 'Aplicar alteração',
	'ANALYTICS_INVOICES.RESET_TO_DEFAULT': 'Redefinir para o padrão',
	'ANALYTICS_INVOICES.EDIT_TABLE_PARAMETERS': 'Revisar parâmetros da tabela',
	'ANALYTICS_INVOICES.SELECT_MINIMUM_OF_2': 'Selecionar pelo menos dois para aplicar na tabela. ',
	'ANALYTICS_INVOICES.OF': ' de ',
	'ANALYTICS_INVOICES.SELECTED': ' Selecionado',
	// detalhes da fatura
	'ANALYTICS_INVOICES.DATA_NOT_AVAILABLE': 'Dados indisponíveis',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_TITLE': 'Não há dados para esta fatura.',
	'ANALYTICS_INVOICES.EMPTY_INVOICE_DETAILS_SUBTITLE': 'O banco de dados detalhes da fatura está vazio para esta fatura',
	'ANALYTICS_INVOICES.ADD_FILES': 'Arquivos da fatura',
	'ANALYTICS_INVOICES.VIEW_ADD_EDIT': 'Visualizar/Adicionar/Revisar',
	//  MODAL DOS ANEXOS
	'INVOICES_ATTACHMENTS_MODAL.VIEW_MODAL_TITLE': 'Anexos da fatura',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_MODAL_TITLE': 'Revisar anexo',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_MODAL_TITLE': 'Carregar um arquivo',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_MODAL_TITLE': 'Excluir anexo',
	'INVOICES_ATTACHMENTS_MODAL.CLOSE': 'Fechar',
	'INVOICES_ATTACHMENTS_MODAL.CANCEL': 'Cancelar',
	'INVOICES_ATTACHMENTS_MODAL.SAVE': 'Salvar',
	'INVOICES_ATTACHMENTS_MODAL.DELETE': 'Excluir',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_FILE': 'Carregar arquivo',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_ATTACHMENT': 'Carregar anexo',
	'INVOICES_ATTACHMENTS_MODAL.UPLOAD_INVOICE': 'Carregar fatura',
	'INVOICES_ATTACHMENTS_MODAL.FILE_NAME': 'Nome do arquivo',
	'INVOICES_ATTACHMENTS_MODAL.DESCRIPTION': 'Descrição',
	'INVOICES_ATTACHMENTS_MODAL.DELETE_CONFIRMATION': 'Você está prestes a remover um anexo do MC. Isso removerá permanentemente o arquivo do sistema: tem certeza que deseja realizar essa tarefa?',
	'INVOICES_ATTACHMENTS_MODAL.FILE_TO_DELETE': 'Arquivo para exclusão:',
	'INVOICES_ATTACHMENTS_MODAL.ATTACHED_FILE': 'Arquivo anexado',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_NAME_LABEL': 'Nome do arquivo (se deixado em branco o nome de arquivo padrão será usado)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_LABEL': 'Descrição do arquivo visível pelo cliente (opcional)',
	'INVOICES_ATTACHMENTS_MODAL.EDIT_DESCRIPTION_PLACEHOLDER': 'Esta fatura estará ativa em algumas semanas.',
	'INVOICES_ATTACHMENTS_MODAL.DRAG_FILE_OR_CLICK': 'Arrastar arquivo ou clicar para carregar',
	'INVOICES_NOTES_UPDATE.SUCCESS': 'Nota da fatura atualizada com êxito',
	//  MODAL DAS NOTAS
	'NOTES_MODAL.NOTIFICATION_SUCCESS_TITLE': 'Atualização com êxito',
	'NOTES_MODAL.NOTIFICATION_SUCCESS_MESSAGE': 'Sua nota foi adicionada com êxito.',
	'NOTES_MODAL.NOTIFICATION_ERROR_TITLE': 'Atualização falhou',
	'NOTES_MODAL.NOTIFICATION_ERROR_MESSAGE': 'Sua nota foi adicionada com êxito.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_TITLE': 'Carregamento bem-sucedido',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Arquivo carregado com êxito.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_TITLE': 'Erro ao carregar',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Erro ao carregar este arquivo do MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_TITLE': 'Anexo excluído',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_DELETE_ATTACHMENT_MESSAGE': 'Arquivo removido do MC.',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_TITLE': 'Erro ao excluir',
	'INVOICES_ATTACHMENTS_MODAL.NOTIFICATION_ERROR_DELETE_ATTACHMENT_MESSAGE': 'Erro ao remover este arquivo do MC.',
	'INVOICES_ATTACHMENTS_MODAL.INVOICE_DATE': 'Data da fatura',
	'INVOICE_DETAILS.BILLING_CYCLE_NOTIFICATION': 'O ciclo de cobrança não é válido, portanto os detalhes da fatura não podem ser exibidos.',
	// ADICIONAR NOVA FATURA
	'INVOICES.ADD_NEW_INVOICE': 'Adicionar nova fatura',
	'INVOICES.CANCEL': 'Cancelar',
	'INVOICES.ADD_INVOICE': 'Adicionar fatura',
	'INVOICES.EDIT': 'Revisar',
	'INVOICES.CONFIRM': 'Confirmar',
	'INVOICES.CONFIRMATION': 'Confirmação',
	'INVOICES.INVOICE_DETAILS': 'Detalhes da fatura',
	'ADD_INVOICE.INVOICE_ID': 'ID da fatura',
	'ADD_INVOICE.INVOICE_ID_PLACEHOLDER': '12345678910',
	'ADD_INVOICE.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'ADD_INVOICE.M2M_ACCOUNT_ID_PLACEHOLDER': 'Selecionar ID da conta do M2M',
	'ADD_INVOICE.INVOICE_DATE': 'Data da fatura',
	'ADD_INVOICE.BILLING_CYCLE': 'Ciclo de cobrança',
	'ADD_INVOICE.DATE_PLACEHOLDER': 'Selecionar data',
	'ADD_INVOICE.UPLOAD_FILE': 'Carregar um arquivo',
	'ADD_INVOICE.UPLOAD_FILE_NAME': 'Nome do arquivo (Opcional: se deixado em branco o nome de arquivo padrão será usado)',
	'ADD_INVOICE.UPLOAD_FILE_NAME_PLACEHOLDER': 'Nome do arquivo (máximo de 255 caracteres)',
	'ADD_INVOICE.OVERVIEW_FILE_NAME': 'Nome do arquivo',
	'ADD_INVOICE.NOTIFICATION_SUCCESS_UPLOAD_MESSAGE': 'Você adicionou a nova fatura com êxito.',
	'ADD_INVOICE.NOTIFICATION_ERROR_UPLOAD_MESSAGE': 'Erro ao adicionar a fatura. Tente novamente.',
	'ADD_INVOICE.TIME_STAMP': 'Anexado em {date} às {time}',
	'INVOICES.UPLOAD_FILE_NAME_VALIDATION': 'Nome do arquivo não é válido (use _ em vez de espaço em branco e os seguintes caracteres não são permitidos: ! ? <> % $ () + =',
};
