import React from 'react';
import PropTypes from 'prop-types';
import CurrentContext from 'utils/currentContext';
import ImageSelector from 'utils/imageSelector';
import { FormattedMessage } from 'react-intl';

const Alert = ImageSelector(CurrentContext.theme, 'svgs/alert.svg');
const NoTable = ImageSelector(CurrentContext.theme, 'svgs/no-table-data.svg');

const NoData = (props) => {
	const { icon, title, subtitle, styles, table } = props;
	return (
		<div className={styles.no_data_wrapper} data-spec="no-data">
			<div className={styles.icon_wrapper}>
				{icon && !table && icon}
				{table && <NoTable />}
				{!icon && !table && <Alert />}
			</div>
			<div className={styles.message}>
				<div className={styles.title}>
					{title || (
						<FormattedMessage
							id="SHARED.NO_DATA_AVAILABLE"
							defaultMessage="No Data Available"
						/>
					)}
				</div>
				<div className={styles.subtitle}>
					{subtitle || (
						<FormattedMessage
							id="SHARED.NO_DATA_AVAILABLE_SUBTITLE"
							defaultMessage="There is No Data available to show you right now"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const { bool, shape } = PropTypes;

NoData.propTypes = {
	icon: shape(),
	title: shape(),
	subtitle: shape(),
	styles: shape(),
	table: bool
};

export default NoData;
