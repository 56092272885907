import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import PageTitle from '../../../Shared/views/PageTitleView';
import Loading from '../../../../lib/DigitalComponents/Loader';

import AuthService from '../../../../services/AuthService';
import { setLanguageCode } from '../../../../utils/helperFunctions';

const authService = new AuthService();

const EditUserProfile = () => (WrappedComponent) => {
	class EditUserProfileComponent extends Component {
		componentDidMount() {
			const { getCountries } = this.props;

			this.getUserData();

			getCountries();

			this.getAddressTypes();

			this.getLanguages();
		}

		componentDidUpdate(prevProps) {
			const { user, editUserSuccess, deleteProfile, currentUser } = this.props;

			if (prevProps.user !== user) {
				user &&
					user.address &&
					user.address.length > 0 &&
					user.address.map((item, index) =>
						this.getStates(item.countryId, index));
      }

      if (
				prevProps.currentUser &&
				currentUser &&
				this.userUpdatedProfileLanguage(prevProps.currentUser, currentUser)
			) {
				setLanguageCode(currentUser.language);
			}

			if (editUserSuccess !== prevProps.editUserSuccess && editUserSuccess) {
				this.getUserData();
			}

			if (deleteProfile) {
				authService.logout();
			}
		}

		componentWillUnmount() {
			const { getUserInit } = this.props;
			getUserInit();
		}

		getUserData = () => {
			const {
				getUser,
				match: {
					params: { id },
				},
				system,
				myProfile,
			} = this.props;

			const params = {
				additionalParams: {
					include: ['Address', 'ProfilePicture'],
				},
				system,
				myProfile,
			};
			getUser(id, params);
		};

		getAddressTypes = () => {
			const { getAddressTypes } = this.props;
			getAddressTypes();
		};

		getLanguages = () => {
			const { getLanguages } = this.props;
			getLanguages();
		};

		getStates = (countryId, addressIdx) => {
			const {
				getFirstAddressStates,
				getSecondAddressStates,
				getThirdAddressStates,
			} = this.props;
			const searchParams = [
				{ propValue: countryId, prop: 'countryId', operator: '=' },
			];
			const additionalParams = {
				dataSort: 'name ASC',
			};

			switch (addressIdx) {
				case 0:
					getFirstAddressStates({ searchParams, additionalParams });
					break;
				case 1:
					getSecondAddressStates({ searchParams, additionalParams });
					break;
				case 2:
					getThirdAddressStates({ searchParams, additionalParams });
					break;
				// no default
			}
		};

		goBack = () => {
			const {
				match: {
					params: { id },
				},
				push,
				system,
				myProfile,
			} = this.props;
			if (system) {
				push(`/access-management/users/${id}`);
			} else if (myProfile) {
				push('/my-profile');
			} else {
				push(`/user/${id}`);
			}
		};

		deleteProfile = () => {
			const { deleteUserProfile, user } = this.props;
			const params = {
				data: user.id,
			};
			deleteUserProfile(params);
		};

		userUpdatedProfileLanguage = (prevUser, currentUser) => {
      const { myProfile } = this.props;

      return prevUser.language !== currentUser.language && myProfile;
    }

		render() {
			const {
				userRequest,
				editUserRequest,
				user,
				settings,
				currentUser,
			} = this.props;

			return (
				<div data-spec="edit-user-profile">
					<PageTitle
						title={
							<FormattedMessage
								id="ONBOARDING.EDIT_USER_PROFILE"
								defaultMessage="Edit User Profile"
							/>
						}
						pushBack={this.goBack}
					/>
					{(userRequest || editUserRequest) && <Loading data-spec="loading" />}
					{!userRequest && !editUserRequest && user && (
						<WrappedComponent
							{...this.state}
							{...this.props}
							user={user}
							getStates={this.getStates}
							pushBack={this.goBack}
							settings={settings}
							currentUser={currentUser}
						/>
					)}
				</div>
			);
		}
	}

	const { objectOf, string, func, shape, bool, object, arrayOf } = PropTypes;

	EditUserProfileComponent.propTypes = {
		messages: objectOf(string),
		getCountries: func,
		match: shape(),
		user: shape(),
		userRequest: bool,
		states: object,
		getAddressTypes: func,
		getUser: func,
		push: func,
		editUserRequest: bool,
		dispatch: func,
		editUserSuccess: bool,
		system: bool,
		settings: arrayOf(object),
		myProfile: bool,
		currentUser: object,
		deleteUserProfile: func,
		getUserInit: func,
		getFirstAddressStates: func,
		getSecondAddressStates: func,
		getThirdAddressStates: func,
		deleteProfile: bool,
		getLanguages: func,
	};
	return EditUserProfileComponent;
};

export default EditUserProfile;
