import { createAction } from 'redux-actions';
import {
	downloadTicketFile,
	ticketPollForFile
} from '../../services/TicketingService';

import {
	TICKET_FILE_DOWNLOAD_REQUEST,
	TICKET_FILE_DOWNLOAD_SUCCESS,
	TICKET_FILE_DOWNLOAD_FAIL
} from '../constants';
import { downloadReportHelper } from '../../../../utils/constants';

const ticketFileDownloadRequest = createAction(TICKET_FILE_DOWNLOAD_REQUEST);
const ticketFileDownloadSuccess = createAction(TICKET_FILE_DOWNLOAD_SUCCESS);
const ticketFileDownloadFail = createAction(TICKET_FILE_DOWNLOAD_FAIL);

// eslint-disable-next-line import/prefer-default-export
export const fileDownload = (data) => async (dispatch) => {
	dispatch(ticketFileDownloadRequest());
	const downloadURL = '';
	downloadTicketFile(data)
		.then((response) => {
			ticketPollForFile(response, 100, 4000)
				.then((ticketDownload) => {
					dispatch(ticketFileDownloadSuccess());
					return downloadReportHelper(ticketDownload.downloadUrl, downloadURL);
				})
				.catch((error) => {
					dispatch(ticketFileDownloadFail(error));
				});
		})
		.catch((error) => {
			dispatch(ticketFileDownloadFail(error));
		});
};
