import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';

import {
    Field,
    reduxForm
} from 'redux-form';

import styles from './ValidateAddressModal.scss';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Loader from '../../../../lib/DigitalComponents/Loader';
import Button from '../../../../lib/DigitalComponents/Button';
import Radio from '../../../../lib/DigitalComponents/Radio';

class ValidateAddressModal extends PureComponent {
    constructor(props) {
		super(props);
		this.state = {
			state: {},
			country: {}
		};
    }

    async componentDidUpdate(prevProps) {
        const { dataPresent, data, validateAddress, show } = this.props;
        if (dataPresent && prevProps.show !== show && show) {
            const params = {
                countryId: data.countryId.value || data.countryId,
                stateId: data.stateId.value || data.stateId,
                city: data.city,
                street1: data.street1,
                street2: data.street2,
                zipCode: data.zipCode
            };
            await validateAddress(params);
            this.setContryAndState();
        }
    }

    setContryAndState = () => {
        const { validatedAddress, states, countries } = this.props;
        if (validatedAddress && validatedAddress.isValid) {
        const validatedCountry = countries.resultList.find(
            (e) => e.id === validatedAddress.validatedAddress.countryId
        );
        const validatedState = states.resultList.find(
            (e) => e.id === validatedAddress.validatedAddress.stateId
        );
        this.setState({
            state: validatedState,
            country: validatedCountry
        });
        }
    }

    addressChosen = () => {
        const { useSuggestedAddress, onClose, formData, validatedAddress } = this.props;
        const { state, country } = this.state;
        onClose();
        if (formData.addressSuggestion === 'Use suggested') useSuggestedAddress(validatedAddress.validatedAddress, state, country);
    }

    render() {
        const {
            show,
            onClose,
            data,
            dataPresent,
            intl,
            dirty,
            validatedAddress,
            formData
        } = this.props;

        const { state, country } = this.state;

        return (
            <Modal
	            data-spec="dashboard-notes-modal"
	            show={show}
	            onClose={() => {
                    onClose();
                }}
            >
                <form>
                    <ModalHeader
	                    title={
                            <FormattedMessage
	                            id="ONBOARDING.VALIDATE_THE_ADDRESS"
	                            defaultMessage="Validate the address"
                            />
                        }
	                    showCloseButton={false}
                    />
                    <ModalBody className={styles.modal_body}>
                        <FormattedMessage
	                        id="ONBOARDING.WE_FOUND_FOLLOWING_MAILING_ADDRESS"
	                        defaultMessage="We found the following mailing address. Please choose the preferred address"
                        />
                        <div className={styles.cards}>
                            <div className={styles.address_card_wrapper}>
                                {dataPresent ?
                                    (
                                        <div className={formData && formData.addressSuggestion
                                            && formData.addressSuggestion === 'Use entered' ?
                                            cn(styles.address_card, styles.address_card_active)
                                            : styles.address_card}
                                        >
                                            <label className={styles.address_card_title}>
                                                <FormattedMessage
	                                                id="ONBOARDING.ENTERED_ADDRESS"
	                                                defaultMessage="Entered Address"
                                                />
                                            </label>
                                            <div className={styles.address_card_data}>
                                                <p>{data.street1}</p>
                                                <p>
                                                    {data.city}{' '}
                                                    {data.stateId.label || data.state.name}{' '}
                                                    {data.zipCode}
                                                </p>
                                                <p>{data.countryId.label || data.country.name}</p>
                                            </div>
                                            <Field
	                                            component={Radio}
	                                            name={'addressSuggestion'}
	                                            label={intl.formatMessage({
                                                    id: 'ONBOARDING.USE_ENTERED',
                                                    defaultMessage: 'Use Entered'
                                                })}
	                                            value={'Use entered'}
	                                            type="radio"
                                            />
                                        </div>
                                    ) : (<Loader className={styles.loader} />)}
                            </div>
                            <div className={styles.address_card_wrapper}>
                                {dataPresent && validatedAddress ?
                                    (
                                        <div className={formData && formData.addressSuggestion &&
                                            formData.addressSuggestion === 'Use suggested'
                                            && validatedAddress.isValid ?
                                            cn(
                                                styles.address_card,
                                                styles.address_card_active,
                                                styles.right_card
                                            ) :
                                            cn(styles.right_card, styles.address_card)}
                                        >
                                            <label className={cn(
                                                styles.address_card_title,
                                                styles.suggested_address_card_title
                                            )}
                                            >
                                                <FormattedMessage
	                                                id="ONBOARDING.SUGGESTED_ADDRESS"
	                                                defaultMessage="Suggested Address"
                                                />
                                            </label>
                                            {validatedAddress.isValid ?
                                                <>
                                                    <div className={styles.address_card_data}>
                                                        <p>
                                                        {validatedAddress.validatedAddress.street1}
                                                        </p>
                                                        <p>
                                                        {validatedAddress.validatedAddress.city}{' '}
                                                        {state.name}{' '}
                                                        {validatedAddress.validatedAddress.zipCode}
                                                        </p>
                                                        <p>{country.name}</p>
                                                    </div>
                                                    <Field
	                                                    component={Radio}
	                                                    name={'addressSuggestion'}
	                                                    label={intl.formatMessage({
                                                            id: 'ONBOARDING.USE_SUGGESTED',
                                                            defaultMessage: 'Use Suggested'
                                                        })}
	                                                    value={'Use suggested'}
	                                                    type="radio"
                                                    />
                                                </> : (
                                                    <label className={styles.invalid_address_label}>
                                                        <FormattedMessage
	                                                        id="ONBOARDING.CANNOT_SUGGEST_ADDRES"
	                                                        defaultMessage="We cannot recommend any address"
                                                        />
                                                    </label>
                                                )}
                                        </div>
                                    ) : (<Loader className={styles.loader} />)}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter data-spec="ds-modal-footer">
                        <Button
	                        variant="link"
	                        onClick={() => {
                                onClose();
                            }}
	                        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
	                        type="button"
                        />
                        <Button
	                        dataSpec="apply"
	                        variant="primary"
	                        disabled={!dirty}
	                        label={
                                <FormattedMessage
	                                id="APPLY"
	                                defaultMessage="Apply"
                                />
                            }
	                        onClick={this.addressChosen}
	                        type="button"
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

const {
    func,
    bool,
    object,
} = PropTypes;

ValidateAddressModal.propTypes = {
    onClose: func,
    validateAddress: func,
    useSuggestedAddress: func,
    show: bool,
    dataPresent: bool,
    dirty: bool,
    intl: object,
    data: object,
    formData: object,
    validatedAddress: object,
    states: object,
    countries: object
};

export default reduxForm({
    form: 'ValidateAddressModal',
})(injectIntl(ValidateAddressModal));
