import { createAction } from 'redux-actions';
import { showNotification } from '../../../Shared/redux/notifications/actions';

import { getLocale } from '../../../../utils/constants';

import { exportToCsv as exportToCsvService } from '../../services/SecurityFeaturesService';

import {
	SF_EXPORT_TO_CSV_INIT,
	SF_EXPORT_TO_CSV_REQUEST,
	SF_EXPORT_TO_CSV_FAIL,
	SF_EXPORT_TO_CSV_SUCCESS
} from './constants';
import { downloadHelper } from '../../../AnalyticsInvoices/redux/invoiceAttachment/actions';

const exportToCsvInitAction = createAction(SF_EXPORT_TO_CSV_INIT);
const exportToCsvRequest = createAction(SF_EXPORT_TO_CSV_REQUEST);
const exportToCsvFail = createAction(SF_EXPORT_TO_CSV_FAIL);
const exportToCsvSuccess = createAction(SF_EXPORT_TO_CSV_SUCCESS);

export const exportToCsv = (params) => async (dispatch) => {
  dispatch(exportToCsvInitAction());
  const locale = getLocale();
	const {
		default: {
			ACTION_COMPLETED_SUCCESSFULLY,
			ACTION_COULD_NOT_BE_COMPLETED,
			SECURITY_FEATURES_SUCCESSFULLY_EXPORT,
			SECURITY_FEATURES_FAIL_EXPORT
		}
	} = await import(`../../../../localizations/${locale}`);
	dispatch(exportToCsvRequest());
	try {
		const response = await exportToCsvService(params);
		downloadHelper(false, 'Export.csv', response.data, 'text/csv;encoding:utf-8');
		dispatch(
			showNotification({
				id: 'export-success',
				message: SECURITY_FEATURES_SUCCESSFULLY_EXPORT
					? [SECURITY_FEATURES_SUCCESSFULLY_EXPORT]
					: [ACTION_COMPLETED_SUCCESSFULLY],
				type: 'success',
				notificationType: 'toaster',
				close: true,
				duration: 5000
			})
		);
		dispatch(exportToCsvSuccess(response));
	} catch (error) {
		dispatch(exportToCsvFail(error));
		dispatch(
			showNotification({
				id: 'export-fail',
				message: SECURITY_FEATURES_FAIL_EXPORT
					? [SECURITY_FEATURES_FAIL_EXPORT]
					: [ACTION_COULD_NOT_BE_COMPLETED],
				type: 'error',
				notificationType: 'toaster',
				close: true,
				duration: 5000
			})
		);
	}
};

export const exportToCsvInit = () => async (dispatch) => {
	dispatch(exportToCsvInitAction());
};
