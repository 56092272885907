import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

import { analyticsInvoicesBaseURL } from '../utils/constants';

export const getOtherCharges = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${params.id}/other-charges?${qs.stringify(
			params.params
		)}`
	);

export const getOtherChargesFilters = (params) =>
	ApiService.get(
		`${analyticsInvoicesBaseURL}/${
			params.id
		}/other-charges/filters?${qs.stringify(params.params)}`
	);
