import { createAction } from 'redux-actions';

import { ORIGINAL_FILTER_INIT, ORIGINAL_FILTER_SUCCESS } from './constants';

export const originalFilterInit = createAction(ORIGINAL_FILTER_INIT);
export const originalFilterSuccess = createAction(ORIGINAL_FILTER_SUCCESS);

/** ************ set original filters ************* */
export const setOriginalFilter = (filterData) => async (dispatch) => {
	dispatch(originalFilterSuccess(filterData));
};
