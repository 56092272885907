import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';

import styles from './AccountInfoOverview.scss';
import StaticField from '../../../../lib/DigitalComponents/StaticField';

function AccountInfoOverview({ title, subtitle, account, intl }) {
	return (
		<div
			data-spec="account-info"
			className={styles.account_info_overview_wrapper}
		>
			<h2 className={cn(styles.title, subtitle && styles.has_subtitle)}>
				{title}
			</h2>
			{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
			<div>
				<StaticField
					label={intl.formatMessage({
						id: 'ONBOARDING.ACCOUNT_NAME',
						defaultMessage: 'Account Name'
					})}
					data={account.m2mAccountName}
				/>
				<StaticField
					label={intl.formatMessage({
						id: 'ONBOARDING.IOT_C_ACCOUNT_ID',
						defaultMessage: 'IOT-C Account ID'
					})}
					data={account.company && account.company.externalCompanyId}
				/>
				<StaticField
					label={intl.formatMessage({
						id: 'ONBOARDING.BILLING_PLATFORM',
						defaultMessage: 'Billing Platform'
					})}
					data={account.m2mBillingPlatform}
				/>
			</div>
			<div>
				<StaticField
					label={intl.formatMessage({
						id: 'ONBOARDING.M2M_PLATFORM',
						defaultMessage: 'M2M Platform'
					})}
					data={account && account.platformType && account.platformType.name}
				/>
				<StaticField
					label={intl.formatMessage({
						id: 'ONBOARDING.M2M_ACCOUNT_ID',
						defaultMessage: 'M2M Platform'
					})}
					data={account.m2mAccountId}
				/>
				<StaticField
					label={intl.formatMessage({
						id: 'ONBOARDING.INVOICE_ACCOUNT_NUMBER',
						defaultMessage: 'Invoice Account Number'
					})}
					data={account.m2mBillingAccountId}
				/>
			</div>
		</div>
	);
}

const { object, any } = PropTypes;

AccountInfoOverview.propTypes = {
	account: object,
	title: any,
	subtitle: any,
	intl: object
};

export default injectIntl(AccountInfoOverview);
