import {
	userIsAuthenticated,
	userInStandardMode
} from '../../../utils/AuthSelector';

import Swagger from '../components/Swagger';

const swaggerRoutes = [
	{
		path: '/developer-api',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated(['mnc.api_rw', 'mnc.api_ro'])(Swagger)
		)
	}
];

export default swaggerRoutes;
