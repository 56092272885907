import * as ApiService from '../../../services/ApiService';

const exportCsv = (data) => {
	const url = '/batch/file-download';

	return ApiService.post(url, data)
		.then((response) => response.data)
		.catch((error) => {
			throw { error: error.response.data };
		});
};

export default exportCsv;
