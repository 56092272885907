import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from './ErrorPage.scss';

import Error403 from '../Error403';
import Error404 from '../Error404';
import Error500 from '../Error500';
import Error504 from '../Error504';

import { sendErrorToServer } from '../../../Shared/components/ErrorBoundary/sendErrorToServer';
import AuthService from '../../../../services/AuthService';

class ErrorPage extends React.PureComponent {
	renderComponents = () => {
		const { location } = this.props;
    const { pathname } = location;

    if (pathname.includes('/page-not-found')) {
      return <Error404 data-spec="error-404" />;
    }

    if (pathname.includes('/forbidden-access')) {
      return <Error403 data-spec="error-403" />;
    }

    return this.renderOtherCases();
	};

	renderOtherCases = () => {
		const { statusCode, goToHomePage } = this.props;
		if (!statusCode) {
			goToHomePage();
		} else {
			sendErrorToServer({ type: 'custom', message: 'Custom notification' });
		}
		switch (statusCode) {
			case 403:
				return <Error403 data-spec="error-403" />;
			case 504:
				return <Error504 data-spec="error-504" />;
			default:
				return <Error500 data-spec="error-500" />;
		}
	};

	render() {
		const { statusCode } = this.props;
		const authService = new AuthService();
		return (
			<div data-spec="error-page" className={styles.main_wrapper}>
				{this.renderComponents()}
				<div className={styles.button_wrapper}>
					<a
						href={`/${sessionStorage.getItem('language') || 'en-us'}`}
						className={styles.homepage}
					>
						<span>
							<FormattedMessage
								id="ERRORS.RETURN_TO_HOME_PAGE"
								defaultMessage="RETURN TO HOME PAGE"
							/>
						</span>
					</a>
					{statusCode === 403 && (
						<span
							className={styles.homepage}
							onClick={() => {
								authService.logout();
							}}
						>
							<span>
								<FormattedMessage id="ERRORS.LOGOUT" defaultMessage="LOGOUT" />
							</span>
						</span>
					)}
				</div>
			</div>
		);
	}
}

const { object, any, func } = PropTypes;

ErrorPage.propTypes = {
	location: object,
	statusCode: any,
  goToHomePage: func,
};

export default ErrorPage;
