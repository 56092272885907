import { handleActions } from 'redux-actions';

import { OPEN_SEND_SMS_MODAL, CLOSE_SEND_SMS_MODAL } from './constants';

export const initialState = {
	sendSmsModalOpened: false
};

export default handleActions(
	{
		[OPEN_SEND_SMS_MODAL]: (state) => ({
				...state,
				sendSmsModalOpened: true
			}),
		[CLOSE_SEND_SMS_MODAL]: (state) => ({
				...state,
				sendSmsModalOpened: false
			})
	},
	initialState
);
