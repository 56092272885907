import { ratePlanTableHeaders } from './constants';

export default (endpoint) =>
	(
		endpoint &&
		(
			endpoint.pendingStatus &&
			endpoint.pendingStatus.toLowerCase() === 'pending'
		)
	) ||
	(
		endpoint &&
		endpoint.transferStatus &&
		endpoint.transferStatus.toLowerCase() !== 'reimsi success'
	) ||
	(
		endpoint &&
		endpoint.pendingStatus &&
		endpoint.pendingStatus.toLowerCase() === 'pending recredential'
	);

export const transpose = (ratePlans) => {
	const data = ratePlans
		.sort((plan1, plan2) => (
			plan1.ratePlan.id - plan2.ratePlan.id
		))
		.map(
			(ratePlan) =>
				ratePlan.ratePlan &&
				Object.keys(ratePlan.ratePlan).map((key) => ratePlan.ratePlan[key])
		);
	const result = {};

	ratePlanTableHeaders.forEach((head) => {
		result[head.name] = [];
		ratePlans.map(() => result[head.name].push('-'));
	});
	data.forEach((item, itemIndex) => {
		const header =
			ratePlans[itemIndex].ratePlan &&
			Object.keys(ratePlans[itemIndex].ratePlan);

		if (item) {
			item.forEach((value, valueIndex) => {
				if (result[header[valueIndex]] && header[valueIndex] && value) {
					result[header[valueIndex]][itemIndex] = value;
				}
			});
		}
	});

	return result;
};
