import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isEqual } from 'lodash';

import AutomationRuleDetail from '../AutomationRuleDetail';
import RuleHistory from '../RuleHistory';

import PageTitle from '../../../Shared/views/PageTitleView';
import TabsBar from '../../../Shared/views/TabsBarView';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';

import RuleStateDropdown from '../RuleStateDropdown';
import ChangeStateModal from '../ChangeStateModal';

import styles from './RuleDetailsParent.scss';

import ruleTypes from '../../utils/ruleTypes';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import {
	manualRuleStates,
	getPossibleStates
} from '../../utils/manualRuleStatesHelper';

import { renderTabs } from '../../../../utils/tabHelpers';

const tabNames = {
	DETAILS: 'details',
	HISTORY: 'history'
};

class RuleDetailsParent extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			ruleState: null,
			selectedState: null,
			openStateChangeModal: false,
			activeTab: tabNames.DETAILS
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		const { rule } = this.props;
		if (!isEmpty(rule) && !isEqual(prevProps.rule, rule)) {
			if (
				rule.automationRuleType &&
				rule.automationRuleType.code === ruleTypes.ManualRule
			) {
				const ruleState = {
					label: manualRuleStates[rule.automationRuleStatus.code],
					value: manualRuleStates[rule.automationRuleStatus.code]
				};
				this.setImplementationStatus(ruleState);
			}
		}
	}

	componentWillUnmount() {
		const { setRuleToInit } = this.props;
		setRuleToInit();
	}

	openStateChangeModal = (selectedState) => {
		this.setState({
			openStateChangeModal: true,
			selectedState
		});
	};

	closeOpenStateChangeModal = () => {
		this.setState({
			openStateChangeModal: false
		});
	};

	setImplementationStatus = (value) => {
		this.setState({
			ruleState: value
		});
	};

	isRWComponent = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['mnc.automation_rw'], user);
	};

	isProccessManager = () => {
		const { user } = this.props;
		return isUserAllowedToAccess(['system.managemanualrules_rw'], user);
	};

	renderPageTitleActions = () => {
		const { rule, ruleRequest } = this.props;
		if (ruleRequest) {
			return (
				<div data-spec="state-loader" className={styles.page_title_loader}>
					<Loader />
				</div>
			);
		}
		if (rule) {
			if (
				rule.automationRuleType &&
				rule.automationRuleType.code !== ruleTypes.ManualRule &&
				this.isRWComponent()
			) {
				return this.renderActivationButton();
			}

			if (
				rule.automationRuleType &&
				rule.automationRuleType.code === ruleTypes.ManualRule &&
				this.isProccessManager()
			) {
				return this.renderRuleStateDropdown();
			}
		}
	};

	getRuleStateDropdownOptions = () => {
		const { rule } = this.props;
		return getPossibleStates(manualRuleStates[rule.automationRuleStatus.code]);
	};

	renderRuleStateDropdown = () => {
		const { ruleState } = this.state;
		return ruleState ? (
			<div
				data-spec="automation-rule-state-action"
				className={styles.state_change}
			>
				<div className={styles.implementation_status}>
					<FormattedMessage
						id="AUTOMATION.IMPLEMENTATION_STATUS"
						defaultMessage="Implementation Status"
					/>
				</div>
				<div className={styles.state_dropdown}>
					<RuleStateDropdown
						value={{
							value: ruleState.value,
							label: (
								<FormattedMessage
									id={`AUTOMATION.RULE_STATUS_${
										ruleState.label && ruleState.label.toUpperCase()
									}`}
									defaultMessage={ruleState.label}
								/>
							)
						}}
						options={this.getRuleStateDropdownOptions()}
						onChange={(value) => this.openStateChangeModal(value)}
					/>
				</div>
			</div>
		) : (
			<span>test</span>
		);
	};

	// umjesto switcha ce ici activate ili deactivate rule
	// activate -> deactivate
	// rule == false -> activate ( true )
	renderActivationButton = () => {
		const {
			activateRule,
			rule,
			match: {
				params: { ruleId }
			}
		} = this.props;

		const successMessageId = rule.active
			? 'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY'
			: 'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY';

		const errorMessageId = rule.active
			? 'AUTOMATION.DEACTIVATED_RULE_ERROR'
			: 'AUTOMATION.ACTIVATED_RULE_ERROR';

		const notifOptions = {
			id: 'rule-activation',
			successMessageId,
			errorMessageId
		};

		return (
			<div data-spec="automation-activation-button">
				<Button
					type="button"
					variant="outline-primary"
					label={
						rule.active ? (
							<FormattedMessage
								id={'AUTOMATION.DEACTIVATE'}
								defaultMessage={'Deactivate'}
							/>
						) : (
							<FormattedMessage
								id={'AUTOMATION.ACTIVATE'}
								defaultMessage={'Activate'}
							/>
						)
					}
					onClick={() => {
						activateRule(ruleId, { active: !rule.active }, notifOptions);
					}}
				/>
			</div>
		);
	};

	onNewTabClick = (tabName) => {
		this.setState({
			activeTab: tabName
		});
	};

	getDetailsTabs = () => {
		const { activeTab } = this.state;
		const {
			ruleRequest,
			activateRuleRequest,
			match: {
				params: { ruleId }
			},
			messages
		} = this.props;
		const isAnyRequest = ruleRequest || activateRuleRequest;
		const automationRuleTabs = [
			{
				id: tabNames.DETAILS,
				name: (
					<FormattedMessage
						id="AUTOMATION.DETAILS_TAB_NAME"
						defaultMessage="Details"
					/>
				),
				component: (
					<AutomationRuleDetail {...this.props} key="automation-rule-details" />
				),
				isActive: tabNames.DETAILS === activeTab,
				onClick: () => this.onNewTabClick(tabNames.DETAILS),
				disabled: isAnyRequest && activeTab !== tabNames.DETAILS
			},
			{
				id: tabNames.HISTORY,
				name: (
					<FormattedMessage
						id="AUTOMATION.HISTORY_TAB_NAME"
						defaultMessage="History"
					/>
				),
				component: (
					<RuleHistory
						key="automation-rule-history"
						id={ruleId}
						messages={messages}
					/>
				),
				isActive: tabNames.HISTORY === activeTab,
				onClick: () => this.onNewTabClick(tabNames.HISTORY),
				disabled: isAnyRequest && activeTab !== tabNames.HISTORY
			}
		];
		return automationRuleTabs;
	};

	render() {
		const {
			match: {
				params: { ruleId }
			},
			pushBack,
			activateRuleRequest
		} = this.props;

		const { openStateChangeModal, selectedState, ruleState } = this.state;

		const isAnyRequest = activateRuleRequest;

		return (
			<div data-spec="rule-details-parent">
				<PageTitle
					pushBack={pushBack}
					key={'rule-detail-form-title'}
					actions={this.renderPageTitleActions()}
					title={
						ruleId ? (
							<FormattedMessage
								id={'AUTOMATION.EDIT_RULE'}
								defaultMessage={'Edit Rule'}
							/>
						) : (
							<FormattedMessage
								id={'AUTOMATION.CREATE_NEW_RULE'}
								defaultMessage={'Create New Rule'}
							/>
						)
					}
				/>
				{openStateChangeModal && (
					<ChangeStateModal
						show
						onClose={() => this.closeOpenStateChangeModal()}
						selectedState={selectedState}
						currentState={ruleState}
						id={ruleId}
					/>
				)}
				{isAnyRequest && <Loader data-spec="loader" />}
				{!isAnyRequest && (
					<div data-spec="rule-details-content">
						{ruleId ? (
							<div>
								<TabsBar tabs={this.getDetailsTabs()} />
								{renderTabs(this.getDetailsTabs())}
							</div>
						) : (
							<AutomationRuleDetail {...this.props} />
						)}
					</div>
				)}
			</div>
		);
	}
}

const { object, func, bool } = PropTypes;

RuleDetailsParent.propTypes = {
	rule: object,
	match: object,
	pushBack: func,
	activateRule: func,
	ruleRequest: bool,
	activateRuleRequest: bool,
	user: object,
	setRuleToInit: func,
	messages: object
};

export default RuleDetailsParent;
