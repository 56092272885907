export default {
	'SWAGGER.DEVELOPER_API': 'API voor ontwikkelaars',
	'SWAGGER.GETTING_STARTED': 'Aan de slag',
	'SWAGGER.API_SANDBOX': 'API-sandbox',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': 'De onderstaande Swagger-sandbox specificeert de lijst met bronnen die in de rest-API beschikbaar zijn en de bewerkingen die voor die bronnen aangeroepen kunnen worden. Ook specificeert het document de parameterlijst voor een bewerking met onder meer de naam en het type parameter, of de parameters verplicht of optioneel zijn en informatie over aanvaardbare waarden voor die parameters.',
	'SWAGGER.JSON_SCHEMA_INCLUDES': 'Het inbegrepen JSON-schema beschrijft de structuur van de aanvraagtekst die naar een bewerking wordt verzonden in een REST-API, samen met het JSON-schema dat de structuur van een responstekst beschrijft die vanaf een bewerking wordt geretourneerd.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Aan de slag met API',
	'SWAGGER.GETTING_STARTED_WITH_API_P': 'Voordat u uw eerste API-commando kunt maken, moet u het adres van de bron-IP (server) op de whitelist plaatsen. De bron-IP wordt gewoonlijk tijdens het onboardingproces geconfigureerd bij het maken van API-toegang. Als u een foutmelding ontvangt, heeft dat waarschijnlijk te maken met een geblokkeerde toegang tot AT&T IoT-gateway.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': 'Controleer of u de volgende stappen hebt genomen voordat u met ontwikkeling aan de slag gaat:',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '- U hebt een openbaar toegankelijke server ingesteld en het IP-adres tijdens het onboarden ervan opgegeven.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '- Uw bron-IP staat op de whitelist voor toegang tot AT&T IoT-gateway.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '- Uw bedrijfsbeheerder heeft u een API-gebruikersnaam en -wachtwoord gegeven die zijn gecreëerd toen de API-service werd ingeschakeld.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '- U hebt toegang tot uw bronserver om cURL-opdrachten uit te voeren of verbindingsapps te gebruiken in de onderstaande voorkeurstaal.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'OAuth-toegangstoken ophalen',
	'SWAGGER.GET_OAUTH_TOKEN_P': 'Kies hieronder uw voorkeursmethode en haal een OAuth-token op van AT&T IoT Identity met de gebruikersnaam en het wachtwoord die u van uw beheerder hebt gekregen. Het token is 7 dagen geldig.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'API-connectors',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': 'Selecteer een van de onderstaande voorbeelden om verbinding te maken met uw API.',
	'SWAGGER.TEST_CONNECTION_T': 'Test de verbinding met de IoT-gateway',
	'SWAGGER.TEST_CONNECTION_P': 'Voorbeeld cURL:',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Gewenst resultaat',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': 'Als u HTTP-statuscode 200 OK krijgt, is alles in orde en kunt u met ontwikkelen beginnen.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': 'Verbindingsfouten oplossen',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': "Als u een foutmelding ontvangt, controleer dan eerst of de verificatie goed is ingesteld in de HTTPHeader en of het toegangstoken niet verlopen is en vernieuwd moet worden. Ping ook AT&T IoT-gateway om te controleren of uw bron-IP op de whitelist staat. Als u onverwachte resultaten krijgt, kunt u een trouble ticket maken in het gedeelte Ondersteuning.'"
};
