import React from 'react';

function CSharp() {
	return (
		<div data-spec="csharp-asset">
			<pre>
				{`
          var client = new RestClient("https://cisiot.att.com/EAI/oauth/token");
          var request = new RestRequest(Method.POST);

          request.AddHeader("Authorization", "Basic YOUR_ENCODED_CREDENTIALS");
          request.AddHeader("Content-Type", "application/x-www-form-urlencoded");
          request.AddParameter("undefined",
            "username=USERNAME&password=PASSWORD&grant_type=password",
            ParameterType.RequestBody
          );
          IRestResponse response = client.Execute(request);
        `}
			</pre>
		</div>
	);
}

export default CSharp;
