export default {
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_QUESTION': '要删除默认的图表设置吗？',
	'ACCOUNT_OVERVIEW.DELETE_DEFINED_CHART_SETTING_MESSAGE': '您确实想要删除{settingName}设置吗？',
	'ACCOUNT_OVERVIEW.CURRENT_AVAILABLE_GRAPHS': '当前可用的图',
	'ACCOUNT_OVERVIEW.CHART_EXISTS_IN_SETTINGS': '您的设置中已有此图。只允许一种类型的图。',
	'ACCOUNT_OVERVIEW.NO_DESCRIPTION_FOR_GRAPH': '此图没有描述。',
	'ACCOUNT_OVERVIEW.CARD_DROPDOWN_TITLE': '图类型',
	'ACCOUNT_OVERVIEW.EXPORT_CHART_DATA': '输出图表数据',
	'ACCOUNT_OVERVIEW.REMOVE_CHART': '移除图',
	'ACCOUNT_OVERVIEW.OPEN_CHART_SETTINGS_PAGE': '图设置',
	'ACCOUNT_OVERVIEW.ADD_WIDGET_BUTTON': '添加图',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_SUCCESS': '已成功添加新图表',
	'ACCOUNT_OVERVIEW.ADD_CHART_SETTINGS_FAIL': '添加新图表失败',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_SUCCESS': '已成功更新图表',
	'ACCOUNT_OVERVIEW.UPDATE_CHART_SETTINGS_FAIL': '更新图表失败',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_SUCCESS': '已成功删除图表设置',
	'ACCOUNT_OVERVIEW.DELETE_CHART_SETTINGS_FAIL': '删除图表设置失败'
};
