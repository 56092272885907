import React from 'react';
import { arrayOf, object } from 'prop-types';
import cn from 'classnames';

import styles from './Tabs.scss';

function Tabs(props) {
	const { tabs } = props;
	return (
		<div data-spec="action-bar" className={styles.action_bar_wrapper}>
			<div className={styles.top}>
				<div className={styles.left}>
					{tabs.map((item) => (
						<div
							className={cn(item.isActive ? styles.tab_active : styles.tab)}
							key={item.id}
							data-spec={`${item.id}`.replace(/ /g, '_')}
							onClick={item.isActive ? undefined : item.onClick}
						>
							{item.name}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

Tabs.propTypes = {
	tabs: arrayOf(object)
};

export default Tabs;
