import React from 'react';
import { object, func } from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../Companies';

function Desktop(props) {
	const { companies, getOptions, messages } = props;
	return (
		<div data-spec="companies-desktop">
			<Table
				data-spec="companies-table-desktop"
				data={companies.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
}

Desktop.propTypes = {
	companies: object,
	getOptions: func,
	messages: object
};

export default hoc(Desktop);
