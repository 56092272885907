import { Status } from '../../../utils/constants';

// get all templates
export const getAllTemplates = (state) =>
	state.batchFiles.templateDownload.templates;

// templates fetching ?
export const getIsFetchingTemplates = (state) =>
	state.batchFiles.templateDownload.fetchingTemplates === Status.PENDING;

// single template downloading ?
export const getIsDownloadingTemplate = (state) =>
	state.batchFiles.templateDownload.downloadingTemplate === Status.PENDING;
