import { connect } from 'react-redux';

import MainLayoutDesktop from './Desktop';
import MainLayoutTablet from './Tablet';
import MainLayoutMobile from './Mobile';
import AuthWrapper from '../../utils/AuthWrapper';

import {
	getCompanyAccountId,
	getCompanyFriendlyName,
	getHasFetchedFail,
	getUser,
	getEulaUserSettings
} from '../../redux/user/getUserData/selectors';
import {
	setCompanyServiceInit,
	setCompanyFriendlyNameInit
} from '../../redux/user/getUserData/actions';
import getSidebarToggleStatus from '../../components/Shared/redux/sidebar/selectors';

import { setDataForFiltersInit } from '../../components/Shared/redux/filterModal/actions';

const mapStateToProps = (state) => ({
	isCollapsed: getSidebarToggleStatus(state),
	eula: getEulaUserSettings(state),
	isEulaFail: getHasFetchedFail(state),
	companyAccountId: getCompanyAccountId(state),
	companyFriendlyName: getCompanyFriendlyName(state),
  user: getUser(state)
});

const mapStateToDispatch = (dispatch) => ({
	setCompanyServiceInit: () => dispatch(setCompanyServiceInit()),
  setCompanyFriendlyNameInit: () => dispatch(setCompanyFriendlyNameInit()),
  setDataForFiltersInit: () => dispatch(setDataForFiltersInit())
});

export const Desktop = connect(
	mapStateToProps,
	mapStateToDispatch
)(AuthWrapper(MainLayoutDesktop));

export const Tablet = connect(
	mapStateToProps,
	mapStateToDispatch
)(AuthWrapper(MainLayoutTablet));

export const Mobile = connect(
	mapStateToProps,
	mapStateToDispatch
)(AuthWrapper(MainLayoutMobile));
