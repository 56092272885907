const validate = (values) => {
	const errors = {};

	Object.keys(values).forEach((key) => {
		if (key.startsWith('customLabel') && key !== 'messageId') {
			const number = key.substr('customLabel'.length);
			if (!values[key] && values[`isCustomField${number}Required`]) {
				errors[`customLabel${number}`] = 'Required';
			}
		}
	});

	return errors;
};

export default validate;
