import React, { PureComponent } from 'react';
import { array, object, string, number, bool, func } from 'prop-types';
import {
	FormattedDate,
	FormattedMessage,
	FormattedTime,
} from 'react-intl';
import Loader from '../../../../lib/DigitalComponents/Loader';
import {
	isValidDate,
	paginationData,
} from '../../../../utils/constants';
import NoDataView from '../../../Shared/views/NoDataView';
import { header } from '../../utils/constants';

import styles from './Changes.scss';

export default function Changes(WrapperComponent) {
	class ChangesComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				dataOffset: paginationData.dataOffset,
				dataLimit: props.dataLimit,
				selectedPage: paginationData.selectedPage
			};
		}

		componentDidMount() {
			const { getHistoryData, iccid } = this.props;
			const { dataOffset, dataLimit } = this.state;

			getHistoryData(this.getParams(iccid, dataOffset, dataLimit));
		}

		getParams = (iccid, dataOffset, dataLimit) => (
			{
				queryStringParameters: {
					dataOffset,
					dataLimit
				},
				iccid,
			}
		);

		handlePageClick = (data) => {
			const { getHistoryData } = this.props;
			const { dataLimit } = this.state;

			this.setState(
				{
					dataLimit,
					dataOffset: dataLimit * data.selected,
					selectedPage: data.selected
				},
				() => getHistoryData(this.getParams())
			);
		};

		renderDate = ({ transactionBase }) => (
			transactionBase && isValidDate(transactionBase.dateChanged)
				? (
					<FormattedDate
						value={transactionBase.dateChanged}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				)
				: '-'
		);

		renderTime = ({ transactionBase }) => (
			transactionBase && isValidDate(transactionBase.dateChanged)
				? (
					<FormattedTime
						value={transactionBase.dateChanged}
						hour="2-digit"
						minute="2-digit"
						second="2-digit"
					/>
				)
				: '-'
		);

		renderUserName = ({ transactionBase }) => (
			transactionBase && transactionBase.userId
				? transactionBase.userId
				: '-'
		);

		renderChange = ({ transactionBase }) => (
			transactionBase && transactionBase.description
				? transactionBase.description
				: '-'
		);

		renderM2MPlatform = ({ transactionBase }) => (
			transactionBase && transactionBase.m2mPlatform
				? transactionBase.m2mPlatform
				: '-'
		);

		renderAccountId = ({ transactionBase }) => (
			transactionBase && transactionBase.m2mAccountId
				? transactionBase.m2mAccountId
				: '-'
		);

		renderChangedBy = ({ transactionBase }) => (
			transactionBase && transactionBase.changedBy
				? transactionBase.changedBy
				: '-'
		);

		renderFromValue = ({ transactionBase }) => (
			<FormattedMessage
				id="SINGLE_ENDPOINT.CHANGED_FROM_VALUE"
				defaultMessage="{changedFromValue}"
				values={{
					changedFromValue: transactionBase && transactionBase.changedFromValue
						? transactionBase.changedFromValue
						: '-'
				}}
			/>
		);

		renderToValue = ({ transactionBase }) => (
			<FormattedMessage
				id="SINGLE_ENDPOINT.CHANGED_TO_VALUE"
				defaultMessage="{changedToValue}"
				values={{
					changedToValue: transactionBase && transactionBase.changedToValue
						? transactionBase.changedToValue
						: '-'
				}}
			/>
		);

		getOptions = (isMobile) => {
			const { totalCount } = this.props;
			const { selectedPage, dataLimit } = this.state;
			const customComponents = {
				date: {
					type: 'custom',
					component: this.renderDate,
				},
				time: {
					type: 'custom',
					component: this.renderTime,
				},
				changedBy: {
					type: 'custom',
					component: this.renderChangedBy,
				},
				username: {
					type: 'custom',
					component: this.renderUserName,
				},
				change: {
					type: 'custom',
					component: this.renderChange,
				},
				m2mplatform: {
					type: 'custom',
					component: this.renderM2MPlatform,
				},
				accountid: {
					type: 'custom',
					component: this.renderAccountId,
				},
				changedFromValue: {
					type: 'custom',
					component: this.renderFromValue,
				},
				changedToValue: {
					type: 'custom',
					component: this.renderToValue,
				}
			};
			const options = {
				header,
				tableOptions: {
					showPagination: false,
					pageCount: Math.ceil(totalCount / dataLimit),
					marginPagesDisplayed: 2,
					pageRangeDisplayed: 2,
					forcePage: selectedPage,
					onPageChange: (data) => this.handlePageClick(data)
				},
				customComponents
			};
			const optionsMobile = {
				...options,
				tableOptions: {
					...options.tableOptions,
					headerNameComponent: (val) => this.renderDate(val),
				}
			};

			return isMobile ? optionsMobile : options;
		};

		render() {
			const {
				messages,
				isFetching,
				historyData,
				isChangesRequestFailed
			} = this.props;

			if (isFetching && !isChangesRequestFailed) {
				return <Loader data-spec="loader" />;
			}

			if (historyData && historyData.length === 0) {
				return <NoDataView table data-spec="no-data-changes" />;
			}

			return (
				<div data-spec="history-changes" className={styles.table_body}>
					<WrapperComponent
						data={historyData}
						messages={messages}
						options={this.getOptions}
					/>
				</div>
			);
		}
	}

	ChangesComponent.propTypes = {
		historyData: array,
		getHistoryData: func,
		iccid: string.isRequired,
		messages: object,
		isFetching: bool,
		dataLimit: number,
		isChangesRequestFailed: bool,
		totalCount: number,
	};
	ChangesComponent.defaultProps = {
		historyData: [],
		getHistoryData: undefined,
		messages: {},
		isFetching: false,
		// dataLimit: 0,
		isChangesRequestFailed: false,
		totalCount: 0,
	};

	const wrappedComponentName = WrapperComponent.displayName
		|| WrapperComponent.name
		|| 'Component';

	ChangesComponent.displayName = `Changes(${wrappedComponentName})`;

	return ChangesComponent;
}
