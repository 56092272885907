import { connect } from 'react-redux';
import { getFormValues, isDirty, isInvalid } from 'redux-form';
import GrantAccessModal from './GrantAccessModal';
import {
	getUserGroups
} from '../../redux/userGroups/actions';
import {
	grantAccess
} from '../../redux/accounts/actions';
import {
	getUserGroupsFail,
	getUserGroupsSuccess,
	getUserGroupsRequest
} from '../../redux/userGroups/selectors';
import { getUserGroupPermissions } from '../../redux/userGroupPermissions/actions';
import {
	getUserGroupPermissionsRequest,
	getUserGroupPermissionsSuccess
} from '../../redux/userGroupPermissions/selectors';

const mapStateToProps = (state) => ({
  userGroupsRequest: getUserGroupsRequest(state),
	userGroupsFail: getUserGroupsFail(state),
	userGroups: getUserGroupsSuccess(state),
	formValues: getFormValues('GrantAccessModalForm')(state),
	isDirty: isDirty('GrantAccessModalForm')(state),
	isInvalid: isInvalid('GrantAccessModalForm')(state),
	permissionsRequest: getUserGroupPermissionsRequest(state),
	permissions: getUserGroupPermissionsSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getUserGroups: (data) => dispatch(getUserGroups(data)),
	grantAccess: (data) => dispatch(grantAccess(data)),
	getPermissions: (params) => dispatch(getUserGroupPermissions(params))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GrantAccessModal);
