import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../../utils/validators';

import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';

import PageTitle from '../../../Shared/views/PageTitleView';

import styles from './AddSubAccount.scss';

class AddSubAccount extends PureComponent {
	componentDidMount() {
		const {
			match: {
				params: { companyId }
			}
		} = this.props;

		if (companyId) {
			this.getCompany(companyId);
		}
	}

	componentWillUnmount() {
		const { getCompanyInit } = this.props;
		getCompanyInit();
	}

	getCompany = (companyId) => {
		const { getCompany } = this.props;
		getCompany({ id: companyId });
	};

	submitForm = (values) => {
		const {
			addSubAccount,
			match: {
				params: { companyId, platformTypeId, accountId }
			},
			isMyCompany
		} = this.props;

		if (isMyCompany) {
			const request = {
				isMyCompany,
				platformTypeId: parseInt(platformTypeId, 10),
				parentPlatformId: accountId,
				m2mAccountName: values.m2mAccountName
			};
			addSubAccount(request);
		} else {
			let request = {
				companyId
			};
			request = {
				...request,
				platformTypeId: parseInt(platformTypeId, 10),
				parentPlatformId: accountId,
				m2mAccountName: values.m2mAccountName
			};
			addSubAccount(request);
		}
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId, accountId }
			},
			isMyCompany
		} = this.props;

		if (isMyCompany) {
			pushBack(`/my-company/${accountId}`);
		} else {
			pushBack(`/companies/${companyId}`);
		}
	};

	render() {
		const {
			handleSubmit,
			invalid,
			messages,
			company,
			addSubAccountRequest,
			isMyCompany,
			match: {
				params: { platformType }
			}
		} = this.props;

		return (
			<div data-spec="add-account">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.CREATE_SUB_ACCOUNT"
							defaultMessage="Create Sub Account"
						/>
					}
					pushBack={this.pushBack}
					messages={messages}
				/>
				{(!isMyCompany && !company) || addSubAccountRequest ? (
					<Loading data-spec="loader" />
				) : (
					<form
						onSubmit={handleSubmit(this.submitForm)}
						className={styles.form_wrapper}
					>
						{!isMyCompany ? (
							<h2 className={styles.form_headline}>
								<FormattedMessage
									id="ONBOARDING.NEW_SUB_ACCOUNT"
									defaultMessage="New sub account for {companyName}"
									values={{
										companyName: company.companyFriendlyName
									}}
								/>
							</h2>
						) : null}

						<h5 className={styles.section_headline}>
							<FormattedMessage
								id="ONBOARDING.GENERATE_M2M_ACCOUNT"
								defaultMessage="Generate M2M Account"
							/>
						</h5>
						<div className={styles.fields_wrapper}>
							<div className={cn(styles.field_wrapper, styles.static)}>
								<div className={styles.label}>
									<FormattedMessage
										id="ONBOARDING.M2M_PLATFORM"
										defaultMessage="M2M Platform"
									/>
								</div>
								<div className={styles.info}>{platformType.toUpperCase()}</div>
							</div>
						</div>
						<div className={styles.fields_wrapper}>
							<div className={cn(styles.field_wrapper, styles.required_field)}>
								<FormattedMessage
									id="ONBOARDING.SUB_ACCOUNT_NAME"
									defaultMessage="Sub Account Name"
								>
									{(formattedValue) => (
										<Field
											component={Input}
											placeholder={formattedValue}
											label={formattedValue}
											name="m2mAccountName"
											dataSpec="m2mAccountName"
											validate={[required]}
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
						<div>
							<Button
								dataSpec="submit"
								variant="outline-primary"
								className={styles.button_width}
								label={
									<FormattedMessage
										id="ONBOARDING.GENERATE_M2M_ACCOUNT"
										defaultMessage="Generate M2M Account"
									/>
								}
								disabled={invalid}
								type="submit"
							/>
						</div>
					</form>
				)}
			</div>
		);
	}
}

const { func, bool, object } = PropTypes;

AddSubAccount.propTypes = {
	match: object,
	getCompany: func,
	getCompanyInit: func,
	pushBack: func,
	addSubAccount: func,
	handleSubmit: func,
	invalid: bool,
	messages: object,
	company: object,
	addSubAccountRequest: bool,
	isMyCompany: bool
};

const AddSubAccountForm = reduxForm({
	form: 'AddSubAccountForm'
})(AddSubAccount);

export default AddSubAccountForm;
