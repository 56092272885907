import { handleActions } from 'redux-actions';
import { Status } from 'utils/constants';

import {
	UPDATE_BULK_RATE_PLAN_REQUEST,
	UPDATE_BULK_RATE_PLAN_SUCCESS,
	UPDATE_BULK_RATE_PLAN_FAIL,
	UPDATE_BULK_RATE_PLAN_STARTED,
	UPDATE_BULK_RATE_PLAN_ENDED,
	UPDATE_BULK_RATE_PLAN_ADD_FILTERED_SIM,
	UPDATE_BULK_RATE_PLAN_ERROR_CLEAR
} from './constants';

export const initialState = {
	status: Status.INIT,
	error: [],
	currentSim: '',
	successSims: [],
	failSims: [],
	actionError: false,
	started: false,
	filteredSims: []
};

export default handleActions(
	{
		[UPDATE_BULK_RATE_PLAN_REQUEST]: (state, action) => ({
				...state,
				currentSim: action.payload.id,
				status: Status.PENDING
			}),
		[UPDATE_BULK_RATE_PLAN_STARTED]: (state) => ({
				...state,
				currentSim: '',
				successSims: [],
				failSims: [],
				actionError: false,
				started: true,
				filteredSims: []
			}),
		[UPDATE_BULK_RATE_PLAN_ENDED]: (state) => ({
				...state,
				status: Status.INIT,
				actionError: !state.successSims.length,
				started: false
			}),
		[UPDATE_BULK_RATE_PLAN_SUCCESS]: (state, action) => ({
				...state,
				successSims: [...state.successSims, action.payload.id],
				status: Status.DONE
			}),
		[UPDATE_BULK_RATE_PLAN_FAIL]: (state, action) => ({
				...state,
				status: Status.FAIL,
				failSims: [...state.failSims, action.payload.id],
				error: action.payload.error
			}),
		[UPDATE_BULK_RATE_PLAN_ADD_FILTERED_SIM]: (state, action) => ({
				...state,
				filteredSims: [...state.filteredSims, action.payload.id]
			}),
		[UPDATE_BULK_RATE_PLAN_ERROR_CLEAR]: (state) => ({
				...state,
				actionError: false
			})
	},
	initialState
);
