import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import PropTypes from 'prop-types';

import ImageSelector from '../../../../../../utils/imageSelector';
import CurrentContext from '../../../../../../utils/currentContext';
import styles from './DownloadReport.scss';

const DocumentDownloadIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg'
);

function DownloadReport({
	checkFilters,
	exportDataMessage,
	totalCount,
	openGenerateReportModal
}) {
	return (
		<div className={`${styles.icon}`} data-spec="export-feature">
			<FormattedMessage
				id="ENDPOINTS.PLEASE_APPLY_ONE_OR_MORE_FILTER_TO_ENABLE_EXPORT_FEATURE"
				defaultMessage="Please apply one or more filters to enable export feature"
			>
				{(formattedValue) => (
					<div
						data-spec="download-report"
						data-tip={checkFilters() ? formattedValue : exportDataMessage()}
						data-for="createCustomReportTooltip"
					>
						<DocumentDownloadIcon
							id="download-icon"
							data-spec="download-icon"
							onClick={() =>
								!checkFilters() && totalCount && openGenerateReportModal()}
							className={cn(
								styles.icon,
								checkFilters() || !totalCount ? styles.disabled : undefined
							)}
						/>
						<ReactTooltip
							className={styles.tooltip}
							type="light"
							id="createCustomReportTooltip"
						/>
					</div>
				)}
			</FormattedMessage>
		</div>
	);
}

const { number, func } = PropTypes;

DownloadReport.propTypes = {
	totalCount: number,
	openGenerateReportModal: func,
	exportDataMessage: func,
	checkFilters: func
};

export default DownloadReport;
