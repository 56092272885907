import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox';

import styles from './ManualRuleConfirmationModal.scss';

class ManualRuleConfirmationModal extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			checkboxClicked: false,
		};
	}

	setCheckboxState = (data) => {
		this.setState({
			checkboxClicked: data.target.checked,
		});
	};

	renderModalContent = () => (
		<div className={styles.content} data-spec="manual-rule-confirmation">
			<div className={styles.bolded_text}>
				<FormattedMessage
					id="AUTOMATION.MANUAL_RULE_DELAY"
					defaultMessage="There will be delay in the implementation of this rule."
				/>
			</div>
			<div className={styles.checkbox_part}>
				<Checkbox onChange={(data) => this.setCheckboxState(data)} />
				<span className={styles.checkbox_text}>
					<FormattedMessage
						id="AUTOMATION.MANUAL_RULE_CONFIRMATION"
						defaultMessage="This rule will take a certain amount of time to be executed."
					/>
				</span>
			</div>
		</div>
	);

	renderButtons = () => {
		const { onClose, onAccept } = this.props;
		const { checkboxClicked } = this.state;
		return (
			<div
				className={styles.buttons}
				data-spec="confirmation-manual-rule-buttons"
			>
				<Button
					onClick={onClose}
					variant="link"
					type="button"
					label={
						<FormattedMessage
							id={'AUTOMATION.CANCEL'}
							defaultMessage={'Cancel'}
						/>
					}
				/>
				<Button
					variant="primary"
					type="submit"
					label={
						<FormattedMessage
							id={'AUTOMATION.ACCEPT'}
							defaultMessage={'Accept'}
						/>
					}
					onClick={() => {
						onAccept();
						onClose();
					}}
					disabled={!checkboxClicked}
				/>
			</div>
		);
	};

	render() {
		const { show, onClose, ruleId } = this.props;
		return (
			<Modal
				data-spec="automation-change-state-modal"
				show={show}
				onClose={onClose}
			>
				<ModalHeader
					title={
						ruleId ? (
							<FormattedMessage
								id={'AUTOMATION.RULE_CHANGE'}
								defaultMessage={'Automation Rule Change'}
							/>
						) : (
							<FormattedMessage
								id={'AUTOMATION.RULE_CREATE'}
								defaultMessage={'Automation Rule Create'}
							/>
						)
					}
				/>
				<ModalBody className={styles.modal__body}>
					{this.renderModalContent()}
				</ModalBody>
				<ModalFooter data-spec="ds-modal-footer">
					{this.renderButtons()}
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, string } = PropTypes;

ManualRuleConfirmationModal.propTypes = {
	show: bool,
	onClose: func,
	onAccept: func,
	ruleId: string,
};

export default ManualRuleConfirmationModal;
