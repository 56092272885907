import React, { PureComponent } from 'react';
import { func, object, string } from 'prop-types';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '../../../../../lib/DigitalComponents/Button';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import Select, { components } from '../../../../../lib/DigitalComponents/DropdownSelectNew';

import styles from './Steps.scss';

const ChangeSIMStateRWComponent = UserAllowedToAccess([
	'mnc.endpoints_rw',
	'mnc.endpoints.simstatus_rw',
]);

class StepZero extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			newState: '',
		};
	}

	componentDidMount() {
    const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps, prevState) {
    const { context } = this.props;
    const { newState } = this.state;

		if (context && !isEqual(newState, prevState.newState)) {
			this.setFooter(context);
		}
	}

	onNext = () => {
    const { onNext } = this.props;
    const { newState } = this.state;

		onNext({ newState });
	};

	changeStatus = (val) => this.setState({	newState: val	});

	getOption = (availableStates, endpoint, intl) => {
		if (
			!availableStates ||
			!endpoint.serviceProvider ||
			Object.keys(availableStates).length === 0 ||
			!endpoint.status
		) {
			return [];
		}

		let tempAvailableStates = [];
		const filteredStates = availableStates[endpoint.serviceProvider]
			? availableStates[endpoint.serviceProvider]
				.filter((item) => item.currentState === endpoint.status)
			: [];

		if (filteredStates.length && filteredStates[0].targetStates) {
			tempAvailableStates = filteredStates[0].targetStates.map((item) => ({
				value: item.state,
				label: intl.formatMessage({
					id: item.state,
					defaultMessage: item.state,
				})
			}));
		}

		tempAvailableStates = [
			{
				value: endpoint.status,
				label: intl.formatMessage({
					id: endpoint.status,
					defaultMessage: endpoint.status,
				})
			},
			...tempAvailableStates
		];

		return tempAvailableStates;
	};

	setFooter = (context) => {
    const { onCancel, endpoint } = this.props;
    const { newState } = this.state;
		const footer = (
			<>
				<Button
					variant="link"
					onClick={onCancel}
					label={<FormattedMessage
						id="CANCEL"
						defaultMessage="Cancel"
					/>}
					dataSpec="cancel-button"
				/>
				<ChangeSIMStateRWComponent>
					<Button
						variant="primary"
						onClick={this.onNext}
						disabled={
							newState.value === endpoint.status ||
							!newState
						}
						label={<FormattedMessage
							id="CONTINUE"
							defaultMessage="Continue"
						/>}
						dataSpec="continue-button"
					/>
				</ChangeSIMStateRWComponent>
			</>
		);

		context.updateContext({ footer });
	};

	Option = (optionProps) => {
		const { data, children } = optionProps;
		return (
			<FormattedMessage
				data-spec={`${data.value}`}
				id={data.value || 'EMPTY_SPACE'}
				defaultMessage={data.value || ' '}
			>
				{() => (
					<components.Option
						{...optionProps}
						className={cn(
							styles[
							data && data.value && data.value.replace(/\s/g, '_').toLowerCase()
								],
							styles['custom-option']
						)}
					>
						<span data-spec={`${data.value}`}>{children}</span>
					</components.Option>
				)}
			</FormattedMessage>
		);
	};

	SingleValue = (optionProps) => {
		const { data } = optionProps;
		return (
			<FormattedMessage
				data-spec={`${data.value}`}
				id={data.value || 'EMPTY_SPACE'}
				defaultMessage={data.value || ' '}
			>
				{() => (
					<components.SingleValue
						{...optionProps}
						data-spec={`${data.value}`}
						className={cn(
							styles[
							data && data.value && data.value.replace(/\s/g, '_').toLowerCase()
								],
							styles['custom-option'],
							styles['select-single-value']
						)}
					/>
				)}
			</FormattedMessage>
		);
	};

	render() {
    const { endpoint, endpointId, availableStates, intl } = this.props;
    const { newState } = this.state;

		return (
			<div data-spec="first-step">
				<div data-spec="ds-modal-body">
					<div
						className={styles.select_field_label}
						data-spec="select-sim-state"
					>
						<FormattedMessage
							id="SELECT_SIM_STATE"
							defaultMessage="Select SIM State"
						/>
					</div>
					<div className={styles.select_field_label_text}>
						<FormattedMessage
							id="SINGLE_ENDPOINT.CURRENT_SIM_STATE_FOR_ENDPOINT_ID"
							defaultMessage="Current Sim State For Endpoint ID {endpointId} is "
							values={{ endpointId }}
						/>
						<span
							className={cn(styles.select_field_label_text, styles.rate_plan)}
						>
							<FormattedMessage
								id="SINGLE_ENDPOINT.ENDPOINT_STATUS"
								defaultMessage="{status}"
								values={{ status: endpoint.status || 'No Status' }}
							/>
						</span>
					</div>
					<div className={styles.status_col}>
						<Select
							data-spec="select"
							components={{ Option: this.Option, SingleValue: this.SingleValue }}
							dataSpec="sim-state"
							name="sim-state"
							options={this.getOption(availableStates, endpoint, intl)}
							onChange={(val) => this.changeStatus(val, endpoint)}
							value={!newState ? {
								value: endpoint.status,
								label: (
									<FormattedMessage
										id={endpoint.status || 'EMPTY_SPACE'}
										defaultMessage={endpoint.status || ' '}
									/>
								)
							} : {
								value: newState.value,
								label: (
									<FormattedMessage
										id={newState.value}
										defaultMessage={newState.value}
									/>
								)
							}}
							styles={{
								valueContainer: (provided) => (
									{
										...provided,
										paddingLeft: '0px',
										paddingRight: '0px'
									}
								)
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

StepZero.propTypes = {
	onNext: func,
	endpointId: string,
	endpoint: object,
	availableStates: object,
	onCancel: func,
	context: object,
	intl: object,
};
StepZero.defaultProps = {
	onNext: undefined,
	endpointId: '',
	endpoint: {},
	availableStates: {},
	onCancel: undefined,
	context: {},
	intl: {},
};

export default injectIntl(StepZero);
