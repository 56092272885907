import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SuperUserTopBar from '../SuperUserTopBar';
import Button from '../../../../../lib/DigitalComponents/Button';

import styles from './Desktop.scss';

const Desktop = ({ companyFriendlyName }) => (
	<div
		className={styles.super_user_mode}
		data-spec="above-top-bar-content-desktop"
	>
		<h2 className={styles.super_user_header}>
			<FormattedMessage
				id="SUPER_USER_MODE_MESSAGE"
				defaultMessage="{mode}: {companyFriendlyName}."
				values={{
					companyFriendlyName,
					mode: (
						<b>
							<FormattedMessage
								id="SERVICE_PROVIDER_MODE"
								defaultMessage="SERVICE PROVIDER MODE"
							/>
						</b>
					)
				}}
			/>
		</h2>
		<Button
			type="button"
			className={styles.super_user_button}
			label={
				<FormattedMessage
					id={'SHARED.EXIT_THIS_COMPANY'}
					defaultMessage={'Exit'}
				/>
			}
			onClick={() => {
				sessionStorage.removeItem('company');
				sessionStorage.removeItem('company-token');
				window.location.href = `/${sessionStorage.getItem('language') || 'en-us'}`;
			}}
		/>
	</div>
);

const { string } = PropTypes;

Desktop.propTypes = {
	companyFriendlyName: string,
};

Desktop.defaultProps = {
	companyFriendlyName: '',
};

export default SuperUserTopBar()(Desktop);
