import { createAction } from 'redux-actions';
import { postRatePlan } from '../../services/EndpointsService';

import {
	UPDATE_BULK_RATE_PLAN_REQUEST,
	UPDATE_BULK_RATE_PLAN_SUCCESS,
	UPDATE_BULK_RATE_PLAN_FAIL,
	UPDATE_BULK_RATE_PLAN_STARTED,
	UPDATE_BULK_RATE_PLAN_ENDED,
	UPDATE_BULK_RATE_PLAN_ADD_FILTERED_SIM,
	UPDATE_BULK_RATE_PLAN_ERROR_CLEAR
} from './constants';

const updateBulkRatePlanStatusRequest = createAction(
	UPDATE_BULK_RATE_PLAN_REQUEST
);
const updateBulkRatePlanStatusSuccess = createAction(
	UPDATE_BULK_RATE_PLAN_SUCCESS
);
const updateBulkRatePlanStatusFail = createAction(UPDATE_BULK_RATE_PLAN_FAIL);
const updateBulkRatePlanAddFilteredSims = createAction(
	UPDATE_BULK_RATE_PLAN_ADD_FILTERED_SIM
);
export const updateBulkRatePlanStatusStarted = createAction(
	UPDATE_BULK_RATE_PLAN_STARTED
);
export const updateBulkRatePlanStatusEnded = createAction(
	UPDATE_BULK_RATE_PLAN_ENDED
);
export const updateBulkRatePlanErrorClear = createAction(
	UPDATE_BULK_RATE_PLAN_ERROR_CLEAR
);

export const updateBulkRatePlan = (data, count = 0) => async (dispatch) => {
	if (count >= data.selectedSims.length) {
		dispatch(updateBulkRatePlanStatusEnded());
	} else {
		const scheduledDate = data.data.startDate;
		const updateData = {
			id: data.selectedSims[count].endpointName,
			data: {
				ratePlanId: `${data.newRatePlan.ratePlan.id}`,
				startDate: scheduledDate
			}
		};
		if (data.selectedSims[count].ratePlan === data.newRatePlan.ratePlan.name) {
			dispatch(
				updateBulkRatePlanAddFilteredSims({
					id: data.selectedSims[count].endpointName
				})
			);
			dispatch(updateBulkRatePlan(data, count + 1));
		} else {
			dispatch(updateBulkRatePlanStatusRequest(updateData));
			setTimeout(() => {
				postRatePlan(updateData)
					.then(() => {
						dispatch(updateBulkRatePlanStatusSuccess(updateData));
						dispatch(updateBulkRatePlan(data, count + 1));
					})
					.catch(() => {
						dispatch(updateBulkRatePlanStatusFail(updateData));
						dispatch(updateBulkRatePlan(data, count + 1));
					});
			}, 1000);
		}
	}
};
