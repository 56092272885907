import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageTitle from '../../../Shared/views/PageTitleView';
import Loader from '../../../../lib/DigitalComponents/Loader';
import TabsBar from '../../../Shared/views/TabsBarView';
import List from '../../views/List';
import { renderTabs } from '../../../../utils/tabHelpers';
import { localizationHelper } from '../../../../utils/helperFunctions';

class SecurityFeatures extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: 1
		};
	}

	componentDidMount() {
		const { getTabs } = this.props;
		getTabs();
	}

	onNewTabClick = async (id) => {
		const { setDataForFiltersInit } = this.props;
		await setDataForFiltersInit();
		this.setState({
			activeTab: id
		});
	};

	mapTabs = (tabs) => {
		const { activeTab } = this.state;
		const { messages } = this.props;
		let mappedTabs = [];
		if (tabs) {
			mappedTabs = tabs.resultList.map((tab) => ({
				id: tab.id,
				name: (
					<FormattedMessage
						id={`SECURITY_FEATURES.${localizationHelper(tab.name)}`}
						defaultMessage={tab.name}
					/>
				),
				component: <List id={tab.id} key={tab.id} messages={messages} />,
				isActive: tab.id === activeTab,
				onClick: () => this.onNewTabClick(tab.id)
			}));
		}
		return mappedTabs;
	};

	render() {
		const { tabsRequest, tabsFail, tabs } = this.props;
		return (
			<div data-spec="security-features">
				<PageTitle
					title={
						<FormattedMessage
							id="SECURITY_FEATURES.TITLE"
							defaultMessage="Security Features"
						/>
					}
				/>
				{tabsRequest && <Loader />}
				{tabsFail && null}
				{tabs && tabs.totalCount > 0 && <TabsBar tabs={this.mapTabs(tabs)} />}
				{tabs && tabs.totalCount > 0 && renderTabs(this.mapTabs(tabs))}
			</div>
		);
	}
}

const { func, bool, objectOf, string, shape } = PropTypes;

SecurityFeatures.propTypes = {
	getTabs: func,
	tabsRequest: bool,
	tabsFail: bool,
	tabs: shape(),
	messages: objectOf(string),
	setDataForFiltersInit: func
};

export default SecurityFeatures;
