import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, submit } from 'redux-form';
import PropTypes from 'prop-types';

import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import Button from '../../../../lib/DigitalComponents/Button';
import Input from '../../../../lib/DigitalComponents/FieldGroup';

import styles from './PauseUserModal.scss';

const submitForm = (values, _, props) => {
	const { getPauseUser, system, data, onClose } = props;

	const params = {
		data,
		justificationForSuspention: values.justificationReason || null,
		system
	};

	getPauseUser(params);
	onClose();
};

class PauseUserModal extends PureComponent {
	render() {
		const { show, onClose, handleSubmit, dispatch, companyName } = this.props;
		return (
			<Modal data-spec="pause-user-modal" show={show} onClose={onClose}>
				<ModalHeader
					title={
						<FormattedMessage
							id="ONBOARDING.PAUSE_USER"
							defaultMessage="Pause User"
						/>
					}
					closeModal={onClose}
				/>
				<form onSubmit={handleSubmit}>
					<ModalBody>
						<div className={styles.modal_body_content}>
							<p className={styles.notice}>
								<FormattedMessage
									id="ONBOARDING.PAUSING_USER_WILL_LOSE_ACCESS"
									defaultMessage="Pausing users will lose access to {companyName} accounts. Pausing users will not be allowed to request access, but administrator can reactivate user account, to regain access, paused users will need to reset their account password."
									values={{
										companyName,
									}}
								/>
							</p>
							<div className={styles.text_area}>
								<FormattedMessage
									id="ONBOARDING.JUSTIFICATION_FOR_PAUSING_OPTIONAL"
									defaultMessage="Justification For Pausing (Optional)"
								>
									{(formattedValue) => (
										<Field
											componentClass="textarea"
											component={Input}
											name="justificationReason"
											label={formattedValue}
										/>
									)}
								</FormattedMessage>
							</div>
						</div>
					</ModalBody>
				</form>
				<ModalFooter>
					<Button
						data-spec="close-button"
						variant="link"
						label={
							<FormattedMessage
								id="ONBOARDING.CANCEL"
								defaultMessage="Cancel"
							/>
						}
						onClick={onClose}
						type="button"
					/>
					<Button
						onClick={() => dispatch(submit('PauseUserModalForm'))}
						variant="primary"
						label={
							<FormattedMessage
								id="ONBOARDING.SUBMIT"
								defaultMessage="Submit"
							/>
						}
						data-spec="submit-button"
					/>
				</ModalFooter>
			</Modal>
		);
	}
}

const { bool, func, string } = PropTypes;

PauseUserModal.propTypes = {
	onClose: func,
	show: bool,
	handleSubmit: func,
	dispatch: func,
	companyName: string
};

PauseUserModal.defaultProps = {
	show: false
};

const PauseUserModalForm = reduxForm({
	form: 'PauseUserModalForm',
	enableReinitialize: true,
	onSubmit: submitForm
})(PauseUserModal);

export default PauseUserModalForm;
