import { handleActions } from 'redux-actions';

import { Status } from '../../../../utils/constants';

import {
	GET_INVOICE_ATTACHMENT_INIT,
	GET_INVOICE_ATTACHMENT_REQUEST,
	GET_INVOICE_ATTACHMENT_FAIL,
	GET_INVOICE_ATTACHMENT_SUCCESS
} from './constants';

const initialState = {
	status: Status.INIT,
	data: null,
	error: null
};

export default handleActions(
	{
		[GET_INVOICE_ATTACHMENT_INIT]: (state) => ({
			...state,
			error: null,
			status: Status.INIT,
			data: null
		}),
		[GET_INVOICE_ATTACHMENT_REQUEST]: (state) => ({
			...state,
			error: null,
			status: Status.PENDING,
			data: null
		}),
		[GET_INVOICE_ATTACHMENT_FAIL]: (state, action) => ({
			...state,
			error: action.payload,
			status: Status.FAIL,
			data: null
		}),
		[GET_INVOICE_ATTACHMENT_SUCCESS]: (state, action) => ({
			...state,
			error: null,
			status: Status.DONE,
			data: action.payload
		})
	},
	initialState
);
