export default {
	'SUPPORT.USER_GUIDE': 'Gebruikershandleiding',
	'SUPPORT.PRINT_PDF': 'Naar PDF printen',
	'SUPPORT.SUPPORT': 'Ondersteuning',
	'SUPPORT.FAQ': 'Veelgestelde vragen',
	'SUPPORT.FAQ_SUPPORT_INFO':
		'Vind snel antwoorden op vragen die onze gebruikers vaak stellen.',
	'SUPPORT.USER_GUIDE_SUPPORT_INFO':
		'Lees gedetailleerde stapsgewijze instructies voor portalfuncties. De handleiding is afgestemd op uw accounttype.',
	'SUPPORT.TABLE_OF_CONTENTS': 'Inhoud',
	'SUPPORT.TICKETING': 'Ticketsysteem',
	'SUPPORT.TICKETING_SUPPORT_INFO':
		'Als er systeemstoringen of serviceproblemen zijn met uw eindpunten en de diagnosetests bieden geen oplossing, dan kunt u een trouble ticket voor ons Ops-team maken. Ook kunt u vragen stellen over facturering en services.',
	'SUPPORT.RECENT_NOTIFICATIONS': 'Recente meldingen',
	'SUPPORT.CREATE_NEW_NOTIFICATION': 'Nieuwe melding maken',
	'SUPPORT.VIEW_ALL_NOTIFICATIONS': 'Alle meldingen weergeven',
	'SUPPORT.FREQUENTLY_ASKED_QUESTIONS': 'Veelgestelde vragen',
	'SUPPORT.TOPICS': 'Onderwerpen',
	'SUPPORT.EDIT_NOTIFICATION': 'Melding bewerken',
	'SUPPORT.GLOBAL_NOTIFICATION': 'Wereldwijde melding',
	'SUPPORT.COMPANY_RELATED_NOTIFICATION': 'Bedrijfsmelding',
	'SUPPORT.NOTIFICATION_TYPE': 'Type melding?',
	'SUPPORT.SELECT_NOTIFICATION_PLACEHOLDER': 'Selecteer het type melding',
	'SUPPORT.CHOOSE_WHICH_USER_GROUPS_TO_NOTIFY':
		'Kies welke gebruikersgroepen u wilt inlichten:',
	'SUPPORT.CONTENT': 'Inhoud',
	'SUPPORT.ADD_NOTIFICATION_CONTENT': 'Noteer hier uw melding',
	'SUPPORT.PUBLISH': 'Versturen',
	'SUPPORT.GLOBAL': 'Wereldwijd',
	'SUPPORT.COMPANY_RELATED': 'Bedrijf',
	'SUPPORT.NOTIFICATION_CONFIRMATION': 'Bevestiging',
	'SUPPORT.NOTIFICATION_INFO': 'Info',
	'SUPPORT.NOTIFICATION_WARNING': 'Waarschuwing',
	'SUPPORT.NOTIFICATION_ERROR': 'Fout',
	'SUPPORT.SELECT_SECTION': 'Selecteer gedeelte',
	'SUPPORT.ALL': 'Allemaal',
	'SUPPORT.ADMIN': 'Admin',
	'SUPPORT.USER': 'Gebruiker',
	'SUPPORT.FINANCE': 'Financiën',
	'SUPPORT.OPERATIONS': 'Operations',
	'SUPPORT.RESTRICTEDACCESS': 'Beperkte toegang',
	'SUPPORT.COMPANYVIEW': 'Bedrijfsweergave',
	'SUPPORT.COMPANYVIEWREADONLY': 'Bedrijfsweergave alleen-lezen',
	'SUPPORT.ADD_NEW_RELEASE_NOTES': 'Nieuwe releaseopmerkingen toevoegen',
	// TRANSLATION NOT AVAILABLE
	'SUPPORT.TRANSLATION_NOT_AVAILABLE':
		'Het onderstaande document is niet volledig vertaald in uw taal. De Engelse versie wordt weergegeven. De vertaling in uw taal zal in komende productreleases beschikbaar zijn.'
};
