import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import NoData from '../NoChartData';
import { renderUnit, renderValue } from '../Helpers/helpers';

import styles from './UsageTrend.scss';

const UsageTrend = ({
	data,
	isWide,
	isModal,
	directionIndex,
	isHistoricalCycle,
	unit,
	intl,
}) => {
	const item = cn({
		[styles.modal_usage_trend_item]: isModal,
		[styles.usage_trend_item]: true
	});
	const header = cn({
		[styles.usage_trend_header_modal]: isModal,
		[styles.usage_trend_header]: true
	});
	const wrapper = cn({
		[styles.wide_card_border_line]: isWide,
		[styles.usage_trend_container]: true
	});
	const directionIdx = Math.abs(directionIndex) * 100;
	const direction = cn({
		[styles.usage_trend_index_modal]: isModal,
		[styles.usage_trend_index]: true
	});
	const directionClass = cn({
		[styles.arrow_up]: directionIndex > 0,
		[styles.arrow_down]: directionIndex < 0
	});

	if (!data || data.length === 0) {
		return isWide ? null : (
			<div data-spec="no-data">
				<NoData />
			</div>
		);
	}

	return (
		<div data-spec="usage-trend" className={wrapper}>
			<div className={styles.usage_trend_wrapper}>
				<span className={header}>
					<FormattedMessage id="TOTAL" defaultMessage="Total" />
				</span>
				<div className={item}>
					<span>{renderValue(unit, data[0].total_value)}</span>
					<span>
						{' '}
						{renderUnit(unit, data[0].total_value, intl.formatMessage)}
					</span>
				</div>
			</div>
			<div className={styles.usage_trend_wrapper}>
				<span className={header}>
					<FormattedMessage id="AVERAGE_USAGE" defaultMessage="Average" />
				</span>
				<div className={item}>
					<span>{renderValue(unit, data[0].average_value)}</span>
					<span>
						{' '}
						{renderUnit(unit, data[0].average_value, intl.formatMessage)}
					</span>
				</div>
			</div>
			{!isHistoricalCycle ? (
				<div className={styles.usage_trend_wrapper}>
					<span className={header}>
						<FormattedMessage
							id="DIRECTION_INDEX"
							defaultMessage="Direction Index"
						/>
					</span>
					<div className={direction}>
						<span>
							{parseFloat(directionIdx).toFixed()}
%
						</span>
						{directionIdx !== 0 ? <div className={directionClass} /> : null}
					</div>
				</div>
			) : null}
		</div>
	);
};

const {
	bool,
	arrayOf,
	number,
	string,
	object,
	objectOf,
	oneOfType,
	func,
	symbol,
} = PropTypes;

UsageTrend.propTypes = {
	isWide: bool,
	data: arrayOf(object),
	isModal: bool,
	directionIndex: number,
	isHistoricalCycle: bool,
	unit: string,
	intl: objectOf(oneOfType([object, func, string, symbol])),
};

UsageTrend.defaultProps = {
	isWide: false,
	data: [],
	isModal: false,
	directionIndex: 0,
	isHistoricalCycle: false,
	unit: '',
	intl: {},
};

export default injectIntl(UsageTrend);
