import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Select from '../../../../../lib/DigitalComponents/DropdownSelectNew';
import { UserAllowedToAccess } from '../../../../../utils/AuthSelector';
import Button from '../../../../../lib/DigitalComponents/Button';

import { releaseStatus } from '../../../utils/constants';

import styles from './Desktop.scss';
import hoc from '../ReleaseVersion';

const ROComponent = UserAllowedToAccess(['system.releasenotes_ro']);
const RWComponent = UserAllowedToAccess(['system.releasenotes_rw']);

class Desktop extends PureComponent {
	isSelectedPublished = () => {
		const { releaseNotes } = this.props;

		return (
			releaseNotes && releaseNotes.releaseStatusId === releaseStatus.PUBLISHED
		);
	};

	render() {
		const {
			releaseNotesListRequest,
			releaseNotesRequest,
			releaseNotesList,
			releaseNotes,
			getReleaseNotes,
			getOptions,
			getValue,
			goToEditReleaseNotes,
			openPublishReleaseNoteModal,
			published,
		} = this.props;

		return (
			<>
				<ROComponent>
					<div className={styles.subtitle}>
						<FormattedMessage
							id="RELEASE_NOTES.RELEASE_NOTES_SUBTITLE"
							defaultMessage="IoT Console is updated frequently to ensure most optimal operation and offer extended features and capabilities and user experience improvements. Below are the updates introduced in this release."
						/>
					</div>
				</ROComponent>
				<h3
					className={cn(styles.headline, styles.border)}
					data-spec="release-notes-version"
				>
					<div className={styles.release_version}>
						<FormattedMessage
							id="RELEASE_NOTES.RELEASE_VERSION"
							defaultMessage="Release Version"
						/>
						<Select
							placeholder={
								<FormattedMessage id="SELECT" defaultMessage="Select" />
							}
							onChange={(val) => getReleaseNotes(val.value)}
							data-spec="dropdown"
							options={getOptions()}
							value={getValue()}
							isDisabled={
								releaseNotesListRequest ||
								releaseNotesRequest ||
								(releaseNotesList &&
									releaseNotesList.resultList &&
									!releaseNotesList.resultList.length)
							}
							styles={{
								container: () => ({
									width: '100px',
								}),
							}}
						/>
					</div>
					{!published && (
						<RWComponent>
							<div className={styles.headline_action}>
								<div className={styles.action_buttons}>
									<Button
										label={<FormattedMessage id="EDIT" defaultMessage="Edit" />}
										variant="outline-primary"
										dataSpec="edit-release-notes-button"
										onClick={() => goToEditReleaseNotes(releaseNotes.id)}
										disabled={
											releaseNotesListRequest ||
											releaseNotesRequest ||
											(releaseNotesList &&
												releaseNotesList.resultList &&
												!releaseNotesList.resultList.length) ||
											this.isSelectedPublished()
										}
									/>
									<Button
										label={
											<FormattedMessage
												id="RELEASE_NOTES.PUBLISH"
												defaultMessage="Publish"
											/>
										}
										variant="primary"
										dataSpec="publish-release-notes-button"
										onClick={() => openPublishReleaseNoteModal()}
										disabled={
											releaseNotesListRequest ||
											releaseNotesRequest ||
											this.isSelectedPublished() ||
											(releaseNotesList &&
												releaseNotesList.resultList &&
												!releaseNotesList.resultList.length) ||
											(releaseNotes && !releaseNotes.releaseDetails.length)
										}
									/>
								</div>
							</div>
						</RWComponent>
					)}
				</h3>
			</>
		);
	}
}

const { func, object, bool } = PropTypes;

Desktop.propTypes = {
	releaseNotesListRequest: bool,
	releaseNotesRequest: bool,
	releaseNotes: object,
	getReleaseNotes: func,
	getOptions: func,
	getValue: func,
	goToEditReleaseNotes: func,
	openPublishReleaseNoteModal: func,
	releaseNotesList: object,
	published: bool,
};

export default hoc()(Desktop);
