/* eslint-disable no-unused-vars */
import { addLocaleData } from 'react-intl';
import CurrentContext from 'utils/currentContext';
import { find } from 'lodash';
import { LOCALES as LOCALES_WHITELIST } from 'utils/constants';

const LOCALES = {
	en: () => require('react-intl/locale-data/en'),
	es: () => require('react-intl/locale-data/es'),
	ca: () => require('react-intl/locale-data/ca'),
	it: () => require('react-intl/locale-data/it'),
	de: () => require('react-intl/locale-data/de'),
	fr: () => require('react-intl/locale-data/fr'),
	nl: () => require('react-intl/locale-data/nl'),
	pt: () => require('react-intl/locale-data/pt'),
	zh: () => require('react-intl/locale-data/zh'),
};

const loadDefaultMessages = (locale, baseLng) => (resolve) => {
	require.ensure([], (require) => {
		const messages = require(`../brands/default/labels/${locale}.js`).default;
		const localeData = LOCALES[baseLng]();
		addLocaleData(localeData);
		resolve({ messages });
	});
};

const loadGmMessages = (locale, baseLng) => (resolve) => {
	require.ensure([], (require) => {
		const messages = require(`../brands/gm/labels/${locale}.js`).default;
		const localeData = LOCALES[baseLng]();
		addLocaleData(localeData);
		resolve({ messages });
	});
};

export const loadLanguages = (theme, locale) => new Promise(function (resolve) {
  const baseLng = locale.split('-')[0];
	const MESSAGES = {
		default: loadDefaultMessages(locale, baseLng),
		gm: loadGmMessages(locale, baseLng),
	};
	if (!window.Intl) {
		require([
			'intl',
			'intl/locale-data/jsonp/en.js',
			'intl/locale-data/jsonp/es.js',
			'intl/locale-data/jsonp/it.js',
			'intl/locale-data/jsonp/de.js',
			'intl/locale-data/jsonp/fr.js',
			'intl/locale-data/jsonp/nl.js',
			'intl/locale-data/jsonp/pt.js',
			'intl/locale-data/jsonp/zh.js',
		], function (Intl) {
			window.Intl = Intl;
			MESSAGES[theme](resolve);
		});
	} else {
		MESSAGES[theme](resolve);
	}
});

export const calculateLocationURL = (locale) => {
	// We should move this into env variables and make it more easy to calculate
	let url = `${window.location.protocol}//`;
	const urlParts = window.location.hostname.replace('.xip.io', '').split('.');
	let { theme } = CurrentContext;
	if (CurrentContext.theme === 'default') theme = 'att';
	url = `${url + theme}.`;
	if (urlParts[0] === theme) urlParts.shift();
	url = `${url + locale}.`;
	if (find(LOCALES_WHITELIST, (item) => item.local === urlParts[0])) {
		urlParts.shift();
	}
	return `${url + urlParts.join('.')}.xip.io:${window.location.port}`;
};
