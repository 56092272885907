export default {
	'SWAGGER.DEVELOPER_API': 'API de desenvolvedor',
	'SWAGGER.GETTING_STARTED': 'Introdução',
	'SWAGGER.API_SANDBOX': 'Área restrita de API',
	'SWAGGER.API': 'API',
	'SWAGGER.SANDBOX_SPECIFIES_THE_LIST': 'A área restrita do Swagger abaixo especifica a lista de recursos disponíveis no API REST e as operações que podem ser efetuadas nesses recursos. O documento também especifica a lista de parâmetros para uma operação, incluindo o nome e tipo de parâmetros, se são opcionais ou obrigatórios, e informações dos valores aceitáveis para tais parâmetros.',
	'SWAGGER.JSON_SCHEMA_INCLUDES': 'O esquema JSON é incluído, descrevendo a estrutura do corpo de solicitação enviado para uma operação no API REST, juntamente com o esquema JSON que descreve a estrutura de corpos de resposta de pesquisas a respeito de uma operação.',
	'SWAGGER.GETTING_STARTED_WITH_API_T': 'Introdução à API',
	'SWAGGER.GETTING_STARTED_WITH_API_P': 'Antes que se possa fazer a primeira chamada de API, o endereço da IP de origem (servidor) deve constar da lista de permissões. A API de origem é normalmente configurada durante o processo de integração quando se cria o acesso à API. Se você receber mensagens de erro, provavelmente estão relacionados ao acesso bloqueado para o Gateway IoT da AT&T.',
	'SWAGGER.GETTING_STARTED_WITH_API_UL_T': 'Assegure-se de já ter executado os passos a seguir para dar início ao desenvolvimento:',
	'SWAGGER.GETTING_STARTED_WITH_API_LI1': '- Você configurou um servidor acessível publicamente e nos forneceu seu endereço de IP durante a integração.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI2': '- Sua IP de origem consta na lista de permissões para acessar o Gateway IoT da AT&T.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI3': '- O administrador de sua empresa forneceu seu nome de usuário e senha de API criados ao habilitar o serviço de API.',
	'SWAGGER.GETTING_STARTED_WITH_API_LI4': '- Você tem acesso ao servidor de recursos para executar comandos curl, ou usar aplicativos de conexão redigidos no seu idioma preferido abaixo.',
	'SWAGGER.GET_OAUTH_TOKEN_T': 'Obter token OAuth de acesso',
	'SWAGGER.GET_OAUTH_TOKEN_P': 'Obter token OAuth da AT&T IoT Identity usando o nome do usuário e a senha fornecidos pelo seu administrador usando o método escolhido abaixo. O token é válido por 7 dias.',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_T': 'Conectores de API',
	'SWAGGER.GETTING_STARTED_WITH_API_CONNECTORS_P': 'Selecionar como você deseja se conectar à nossa API, entre os exemplos abaixo.',
	'SWAGGER.TEST_CONNECTION_T': 'Testar a conexão ao Gateway IoT',
	'SWAGGER.TEST_CONNECTION_P': 'Exemplo de Curl:',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_T': 'Resultado desejado',
	'SWAGGER.GETTING_STARTED_WITH_API_NEXT_STEP_P': 'Se você receber o código de resposta HTTP 200 OK, tudo está pronto para iniciar o desenvolvimento.',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_T': 'Como solucionar problemas de erros de conexão',
	'SWAGGER.GETTING_STARTED_WITH_API_ERRORS_P': "Se receber mensagens de erro, verifique primeiro se sua autenticação está definida corretamente no HTTPHeader e se o token de acesso não está vencido e precisa ser atualizado. Além disso, executar o ping do Gateway IoT da AT&T para verificar se o IP de origem consta da lista de permissões. Caso obtenha resultados inesperados, você pode registrar um tíquete de resolução de problemas na seção de suporte.'"
};
