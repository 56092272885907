import {
	componentAuthBasedSelector,
	userInStandardMode,
	userIsAuthenticated
} from '../../../utils/AuthSelector';
import CompaniesView from '../../Onboarding/views/CompaniesView';
import AccountOverviewView from '../views/AccountOverviewView';
import AccountOverviewAdminView from '../views/AccountOverviewAdminView';

export default [
	{
		path: '/',
		exact: true,
		Component: componentAuthBasedSelector(
			['system.companies_rw', 'system.companies_ro'],
			CompaniesView,
			AccountOverviewView
		)
	},
	{
		path: '/account-overview/admin-page',
		exact: true,
		Component: userInStandardMode(
			userIsAuthenticated('system.graphsettings_rw')(AccountOverviewAdminView)
		)
	}
];
