export default {
	'AUTOMATION.DELETE_AUTOMATION_RULE_QUESTION': 'Excluir regra de automação?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_QUESTION': 'Excluir regras de automação selecionadas?',
	'AUTOMATION.DELETE_SELECTED_AUTOMATION_RULE_MESSAGE': 'Tem certeza de que deseja excluir as regras de automação selecionadas?',
	'AUTOMATION.CANCEL': 'Cancelar',
	'AUTOMATION.DELETE': 'Excluir',
	'AUTOMATION.ARE_YOU_SURE': 'Tem certeza?',
	'AUTOMATION.ACTIVE': 'Ativado',
	'AUTOMATION.ACC_IF': 'Se',
	'AUTOMATION.ADD_ANOTHER_THRESHOLD': 'Adicionar outro limite',
	'AUTOMATION.EDIT_RULE': 'Revisar regra',
	'AUTOMATION.CREATE_NEW_RULE': 'Criar nova regra',
	'AUTOMATION.PLATFORM': 'Plataforma',
	'AUTOMATION.AUTOMATION_ACCOUNT': 'Conta',
	'AUTOMATION.CATEGORY': 'Categoria',
	'AUTOMATION.RULE_STATE': 'Estado da regra',
	'AUTOMATION.RULE_STATE_ACTIVE': 'Ativo',
	'AUTOMATION.RULE_STATE_INACTIVE': 'Inativo',
	'AUTOMATION.TRIGGER': 'Acionar',
	'AUTOMATION.ACC_RULE_NAME': 'Nome da regra',
	'AUTOMATION.SAVE': 'Salvar',
	'AUTOMATION.CLEAR_SELECTED_FILTERS_TOOLTIP': 'Limpar filtros selecionados',
	'AUTOMATION.SELECT_FILTERS_TOOLTIP': 'Selecionar filtros',
	'AUTOMATION.AUTOMATION': 'Automação',
	'AUTOMATION.CREATE_NEW_AUTOMATION_RULE': 'Criar nova regra de automação',
	'AUTOMATION.DELETE_AUTOMATION_RULE_MESSAGE': 'Tem certeza que deseja excluir a regra de automação {ruleName}?',
	'AUTOMATION.ADD_RULE_SUCCESS': 'Regra criada',
	'AUTOMATION.ADD_RULE_ERROR': 'Falha ao criar regra de automação, tente novamente.',
	'AUTOMATION.EDIT_RULE_SUCCESS': 'Regra revisada',
	'AUTOMATION.EDIT_RULE_ERROR': 'Falha ao revisar regra de automação, tente novamente.',
	'AUTOMATION.THEN': 'Em seguida',
	'AUTOMATION.NUMBER_OF_DAYS': 'Número de dias',
	'AUTOMATION.NUMBER_OF_HOURS': 'Número de horas (máx. 24 h)',
	'AUTOMATION.AT': 'Em',
	'AUTOMATION.SIM_STATE': 'Estado do SIM',
	'AUTOMATION.RATE_PLAN_ID_PLAN': 'ID do plano de tarifas',
	'AUTOMATION.M2M_SUB_ACCOUNT_ID': 'ID da conta de assinatura M2M',
	'AUTOMATION.CUSTOMER_ID': 'ID do cliente',
	'AUTOMATION.CUSTOM': 'Personalizar',
	'AUTOMATION.FILTER': 'Filtro',
	'AUTOMATION.NO_AVAILABLE_FILTERS': 'Sem filtro disponível',
	'AUTOMATION.THRESHOLD': 'Limite',
	'AUTOMATION.ZONE_MODEL': 'Modelo da zona',
	'AUTOMATION.ZONE_NAME': 'Nome da zona',
	'AUTOMATION.POOL_ID': 'ID do grupo',
	'AUTOMATION.COUNTRY': 'País',
	'AUTOMATION.FROM_KEY': 'De',
	'AUTOMATION.TO_KEY': 'Para',
	'AUTOMATION.FOLLOW_UP_WITH': 'Acompanhar com',
	'AUTOMATION.AUTOMATION_RULE_SUCCESSFULLY_DELETED': 'Regra de automação excluída com êxito',
	'AUTOMATION.AUTOMATION_ALERT_SUCCESSFULLY_DELETED': 'Alertas de automação’ selecionados excluídos com êxito',
	'AUTOMATION.AUTOMATION_DELETE_ERROR': 'Falha ao excluir regra de automação, tente novamente.',
	'AUTOMATION.PLATFORMS': 'Plataformas',
	'AUTOMATION.CATEGORIES': 'Categorias',
	'AUTOMATION.TRIGGERS': 'Acionamentos',
	'AUTOMATION.M2MACCOUNTS': 'Contas de M2M',
	'AUTOMATION.HOURS_USING': 'Nas últimas 24 horas usando',
	'AUTOMATION.LOCATIONUPDATE': 'Atualização da localização',
	'AUTOMATION.TRIPLE_LOCATION_UPDATE': 'Atualização da autenticação',
	'AUTOMATION.ZONE': 'Zona',
	'AUTOMATION.ACCOUNT_ID': 'ID da conta',
	'AUTOMATION.ACTION': 'Ação',
	'AUTOMATION.DCP': 'DCP',
	'AUTOMATION.ACC': 'ACC',
	'AUTOMATION.WING': 'WING',
	'AUTOMATION.SMS': 'SMS',
	'AUTOMATION.USAGE': 'Uso',
	'AUTOMATION.SIM_PROVISIONING': 'Provisionamento de SIM',
	'AUTOMATION.SUBSCRIPTION_MONITORING': 'Monitoramento de assinaturas',
	'AUTOMATION.USAGE_MONITORING': 'Monitoramento de uso',
	'AUTOMATION.NETWORK_MONITORING': 'Monitoramento da rede',
	'AUTOMATION.PRICING_AUTOMATION': 'Automação de preços',
	'AUTOMATION.SECURITY_MONITORING': 'Monitoramento da segurança',
	'AUTOMATION.SIM_RATE_PLAN_CHANGE': 'Mudança do plano de tarifas do SIM',
	'AUTOMATION.SIM_STATE_CHANGE': 'Mudança do estado do SIM',
	'AUTOMATION.SMS_USAGE': 'Uso de SMS',
	'AUTOMATION.SIM_DATA_LIMIT': 'Limite de dados do SIM',
	'AUTOMATION.SIM_EXPIRATION': 'Data de vencimento do SIM',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE': 'Uso de dados do ciclo até o momento',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE': 'Uso de voz do ciclo até o momento',
	'AUTOMATION.DATA_USAGE_EXCEEDED_IN_PAST_24_HOURS': 'Uso de dados excedido nas últimas 24 horas',
	'AUTOMATION.LAST_24_HOURS_NUMBER_OF_SESSIONS_CONNECTION': 'Número de conexões das sessões nas últimas 24 horas',
	'AUTOMATION.MONTHLY_POOLED_DATA_USAGE': 'Uso mensal de dados em conjunto',
	'AUTOMATION.MONTHLY_POOLED_SMS_USAGE': 'Uso mensal de SMS em conjunto',
	'AUTOMATION.NO_CONNECTION': 'Sem conexão',
	'AUTOMATION.PAST_24_HOURS_VOICE_USAGE': 'Uso de voz nas últimas 24 horas',
	'AUTOMATION.REGISTER_IN_A_ZONE': 'Registrar em uma zona',
	'AUTOMATION.TOO_MANY_CONNECTIONS': 'Número excessivo de conexões',
	'AUTOMATION.SMS_MO_OVER_RUN_PER_TIMEFRAME': 'Excesso de MO de SMS por período de tempo',
	'AUTOMATION.SMS_MO_UNDER_RUN_PER_TIMEFRAME': 'Insuficiência de MO de SMS por período de tempo',
	'AUTOMATION.SMS_MT_OVER_RUN_PER_TIMEFRAME': 'Excesso de MT de SMS por período de tempo',
	'AUTOMATION.SMS_MT_UNDER_RUN_PER_TIMEFRAME': 'Insuficiência de MT de SMS por período de tempo',
	'AUTOMATION.GPRS_LONG_TERM_OVER_RUN': 'Excesso de GPRS a longo prazo',
	'AUTOMATION.GPRS_SHORT_TERM_OVER_RUN': 'Excesso de GPRS a curto prazo',
	'AUTOMATION.FIRST_DATA_SESSION_ESTABLISHED': 'Primeira sessão de dados estabelecida',
	'AUTOMATION.IMEI_CHANGED': 'IMEI mudou',
	'AUTOMATION.NETWORK_REGISTRATION': 'Registro da rede',
	'AUTOMATION.NETWORK_REGISTRATION_IN_A_ZONE': 'Registro da rede em uma zona',
	'AUTOMATION.SIM_CUSTOM_FIELD_CHANGE': 'Alteração personalizada do campo SIM',
	'AUTOMATION.SIM_STATUS': 'Status de SIM',
	'AUTOMATION.RATE_PLAN_CHANGED': 'Plano de tarifas alterado',
	'AUTOMATION.SIM_COUNTRY_CHANGED': 'País do SIM alterado',
	'AUTOMATION.SIM_RATE_PLAN_EXPIRING': 'Plano de tarifas do SIM está vencendo',
	'AUTOMATION.DATA_SESSION_STOPPED': 'Sessão de dados interrompida',
	'AUTOMATION.DATA_SESSION_STARTED': 'Sessão de dados iniciada',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY': 'Uso de dados em um país',
	'AUTOMATION.DATA_SESSIONS_COUNT_BILL_CYCLE': 'Contagem das sessões de dados - Ciclo de cobrança',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE': 'Uso de dados em uma zona',
	'AUTOMATION.NO_CONNECTION_BILL_CYCLE': 'Ciclo de cobrança sem conexão',
	'AUTOMATION.NUMBER_OF_SESSION_CONNECTIONS_LAST_24_HOURS': 'Número de conexões da sessão nas últimas 24 horas',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY': 'Contagem de SMS em um país',
	'AUTOMATION.SMS_USAGE_IN_A_ZONE': 'Uso de SMS em uma zona',
	'AUTOMATION.SMS_USAGE_LAST_24_HOURS': 'Uso de SMS nas últimas 24 horas',
	'AUTOMATION.TOTAL_DATA_USAGE_24HRS': 'Uso total de dados - 24 horas',
	'AUTOMATION.TOTAL_DATA_USAGE_BILLING_CYCLE': 'Uso total de dados - Ciclo de cobrança',
	'AUTOMATION.SMS_MO_RECEIVED': 'MO de SMS recebidos',
	'AUTOMATION.CYCLE_TO_DATE_VOICE_USAGE_IN_A_ZONE': 'Uso de voz do ciclo em uma zona',
	'AUTOMATION.SIM_PLAN_COMPLETION_VOICE': 'Voz da conclusão do plano SIM',
	'AUTOMATION.SIM_PLAN_COMPLETION_DATA': 'Dados da conclusão do plano SIM',
	'AUTOMATION.IMEI_WHITELIST_ACTION': 'Ação da lista de permissão de IMEI',
	'AUTOMATION.SIM_ACCOUNT_CHANGE': 'Alterar conta de SIM',
	'AUTOMATION.RECENT_24_HOURS_SMS_USAGE': 'Uso de SMS recente (24 horas)',
	'AUTOMATION.DEVICE_REGISTERED_WITH_A_NETWORK_OPERATOR_IN_A_ZONE': 'Registro da rede em uma zona',
	'AUTOMATION.MSISDN_CHANGE': 'Alteração do MSISDN',
	'AUTOMATION.IMEI_CHANGE': 'Alteração do IMEI',
	'AUTOMATION.CYCLE_TO_DATE_DATA_USAGE_IN_A_ZONE': 'Uso de dados do ciclo até o momento em uma zona',
	'AUTOMATION.AN_ENDPOINT_IS_TRANSFERED_FROM': 'Um endpoint é transferido de',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_USES': 'Qualquer endpoint no ciclo atual usa',
	'AUTOMATION.ANY_ENDPOINT_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Qualquer endpoint em um plano de tarifas pré-pago conclui seu prazo pré-pago',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_THE_ENDPOINT_S_IMEI': 'A rede detecta uma alteração no IMEI do endpoint',
	'AUTOMATION.THE_SYSTEM_DETECTS_MSISDN_CHANGE_FOR_AN_ENDPOINT': 'O sistema detecta uma alteração do MSISDN para um endpoint',
	'AUTOMATION.INITIATE_OTA_SWAP': 'Iniciar troca de OTA',
	'AUTOMATION.ANY_ENDPOINT_SENDS_OR_RECEIVES': 'Qualquer endpoint envia ou recebe mais que',
	'AUTOMATION.ANY_SIM_EXPERIENCES_A_CHANGE_IN_ONE_OF_THE_CUSTOM_FIELDS': 'Qualquer SIM passa por uma alteração em um dos campos personalizados',
	'AUTOMATION.ENDPOINT_IMEI_WAS_NOT_FOUND_IN_THE_WHITELIST': 'IMEI do endpoint não foi encontrado na lista de permissão',
	'AUTOMATION.ANY_ENDPOINT_IN_THE_CURRENT_CYCLE_EXCEEDS': 'Qualquer endpoint no ciclo atual usa',
	'AUTOMATION.A_MO_SMS_IS_RECEIVED_FOR_A_SHORT_CODE': 'MO de SMS é recebido para um código curto',
	'AUTOMATION.TOTAL_SMS_COUNT_BILLING_CYCLE': 'Contagem total de SMS - Ciclo de cobrança',
	'AUTOMATION.1_HOUR': '1 hora',
	'AUTOMATION.2_HOURS': '2 horas',
	'AUTOMATION.3_HOURS': '3 horas',
	'AUTOMATION.4_HOURS': '4 horas',
	'AUTOMATION.5_HOURS': '5 horas',
	'AUTOMATION.6_HOURS': '6 horas',
	'AUTOMATION.7_HOURS': '7 horas',
	'AUTOMATION.8_HOURS': '8 horas',
	'AUTOMATION.9_HOURS': '9 horas',
	'AUTOMATION.10_HOURS': '10 horas',
	'AUTOMATION.11_HOURS': '11 horas',
	'AUTOMATION.12_HOURS': '12 horas',
	'AUTOMATION.13_HOURS': '13 horas',
	'AUTOMATION.14_HOURS': '14 horas',
	'AUTOMATION.15_HOURS': '15 horas',
	'AUTOMATION.16_HOURS': '16 horas',
	'AUTOMATION.17_HOURS': '17 horas',
	'AUTOMATION.18_HOURS': '18 horas',
	'AUTOMATION.19_HOURS': '19 horas',
	'AUTOMATION.20_HOURS': '20 horas',
	'AUTOMATION.21_HOURS': '21 horas',
	'AUTOMATION.22_HOURS': '22 horas',
	'AUTOMATION.23_HOURS': '23 horas',
	'AUTOMATION.24_HOURS': '24 horas',
	'AUTOMATION.25_HOURS': '25 horas',
	'AUTOMATION.26_HOURS': '26 horas',
	'AUTOMATION.27_HOURS': '27 horas',
	'AUTOMATION.28_HOURS': '28 horas',
	'AUTOMATION.29_HOURS': '29 horas',
	'AUTOMATION.30_HOURS': '30 horas',
	'AUTOMATION.31_HOURS': '31 horas',
	'AUTOMATION.32_HOURS': '32 horas',
	'AUTOMATION.33_HOURS': '33 horas',
	'AUTOMATION.34_HOURS': '34 horas',
	'AUTOMATION.35_HOURS': '35 horas',
	'AUTOMATION.36_HOURS': '36 horas',
	'AUTOMATION.37_HOURS': '37 horas',
	'AUTOMATION.38_HOURS': '38 horas',
	'AUTOMATION.39_HOURS': '39 horas',
	'AUTOMATION.40_HOURS': '40 horas',
	'AUTOMATION.41_HOURS': '41 horas',
	'AUTOMATION.42_HOURS': '42 horas',
	'AUTOMATION.43_HOURS': '43 horas',
	'AUTOMATION.44_HOURS': '44 horas',
	'AUTOMATION.45_HOURS': '45 horas',
	'AUTOMATION.46_HOURS': '46 horas',
	'AUTOMATION.47_HOURS': '47 horas',
	'AUTOMATION.48_HOURS': '48 horas',
	'AUTOMATION.LAST_24_HOURS': 'Últimas 24 horas',
	'AUTOMATION.CYCLE_TO_DATE': 'Ciclo até o momento',
	'AUTOMATION.SIM_PREPAID_RATE_PLAN_EXCEEDS': 'Excede o plano de tarifas pré-pago do SIM',
	'AUTOMATION.DATA_USAGE_EXCEEDS_LIMIT': 'Uso de dados excede o limite',
	'AUTOMATION.DATA_USAGE_APPROACHES_LIMIT': 'Uso de dados próximo do limite',
	'AUTOMATION.SIM_S_PREPAID_RATE_PLAN_COMES_WITHIN': 'Plano pré-pago de tarifas do SIM se enquadra em',
	'AUTOMATION.MO_MT': 'MO/MT',
	'AUTOMATION.VOICE_USAGE_EXCEEDS_LIMIT': 'Uso de voz excede o limite',
	'AUTOMATION.VOICE_USAGE_APPROACHES_LIMIT': 'Uso de voz próximo do limite',
	'AUTOMATION.SIM_HAS_ESTABLISHED_MORE_THAN': 'SIM estabeleceu mais de',
	'AUTOMATION.SIM_HAS_ESTABLISHED_LESS_THAN': 'SIM estabeleceu menos de',
	'AUTOMATION.EXCEEDES': 'Excedido',
	'AUTOMATION.SIM_ESTABLISHED_NO_DATA_CONNECTION_FOR_PAST_N_HOURS': 'SIM não estabeleceu nenhuma conexão de dados nas últimas N horas',
	'AUTOMATION.SIM_REGISTERS_TO_NETWORK_IN_ZONE': 'Registros de SIM à rede na zona',
	'AUTOMATION.SIM_ESTABLISHED_MORE_THAN_CONNECTIONS_IN_THE_CURRENT_CYCLE': 'SIM estabeleceu mais do que conexões no ciclo atual',
	'AUTOMATION.THE_NETWORK_DETECTS_A_NEW_DEVICE': 'Rede detectou um novo dispositivo',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_EXCEEDS': 'Uso até o momento excede',
	'AUTOMATION.CYCLE_TO_DATE_USAGE_APPROACHES': 'Uso até o momento aproxima-se de',
	'AUTOMATION.THE_NETWORK_DETECTS_A_CHANGE_IN_': 'Rede detectou alteração em ...',
	'AUTOMATION.SIM_HAS_REGISTERED_FOR_THE_FIRST_TIME_IN_A_NEW_NETWORK': 'SIM registrado pela primeira vez em uma nova rede',
	'AUTOMATION.ENDPOINT_REGISTERS_IN_ZONE': 'Registros de endpoint na zona',
	'AUTOMATION.CUSTOM_FIELD_1': 'Campo personalizado 1',
	'AUTOMATION.CUSTOM_FIELD_2': 'Campo personalizado 2',
	'AUTOMATION.CUSTOM_FIELD_3': 'Campo personalizado 3',
	'AUTOMATION.CUSTOM_FIELD_4': 'Campo personalizado 4',
	'AUTOMATION.CUSTOM_FIELD_5': 'Campo personalizado 5',
	'AUTOMATION.CUSTOM_FIELD_6': 'Campo personalizado 6',
	'AUTOMATION.CUSTOM_FIELD_7': 'Campo personalizado 7',
	'AUTOMATION.CUSTOM_FIELD_8': 'Campo personalizado 8',
	'AUTOMATION.CUSTOM_FIELD_9': 'Campo personalizado 9',
	'AUTOMATION.CUSTOM_FIELD_10': 'Campo personalizado 10',
	'AUTOMATION.CHANGED': 'Alterado',
	'AUTOMATION.RATE_PLAN_CHANGE': 'Alteração no plano de tarifas',
	'AUTOMATION.THE_NUMBER_OF_DATA_SESSIONS_FOR_A_SIM_EXCEEDES': 'O número de sessões de dados para um SIM excede',
	'AUTOMATION.A_SIMS_DATA_SESSION_STARTS': 'Sessão de dados de SIMS inicia',
	'AUTOMATION.A_SIMS_DATA_SESSION_STOPS': 'Sessão de dados de SIMS para',
	'AUTOMATION.DATA_USAGE_IN_A_COUNTRY_EXCEEDS': 'Uso de dados em um país excede',
	'AUTOMATION.DATA_USAGE_IN_A_ZONE_EXCEEDS': 'Uso de dados excede',
	'AUTOMATION.EXCEEDS': 'Excedido',
	'AUTOMATION.BILLABLE_SMS_USAGE_ON_ANY_POOLED_RATE_PLAN_REACHES': 'Uso de SMS faturável em um plano de tarifa em conjunto alcança',
	'AUTOMATION.ANY_SIM_HAS_ESTABLISHED_NO_DATA_CONNECTION_FOR_THE_PAST_BILLING_CYCLE': 'Nenhum SIM estabeleceu conexão de dados no último ciclo de cobrança',
	'AUTOMATION.A_SIM_HAS_MORE_THAN': 'SIM tem mais de',
	'AUTOMATION.SMS_COUNT_IN_A_COUNTRY_EXCEEDS': 'Contagem de SMS em um país excede',
	'AUTOMATION.SMS_COUNT_EXCEEDS_LIMIT': 'Contagem de SMS excede o limite',
	'AUTOMATION.1_WEEK': '1 semana',
	'AUTOMATION.2_WEEKS': '2 semanas',
	'AUTOMATION.3_WEEKS': '3 semanas',
	'AUTOMATION.4_WEEKS': '4 semanas',
	'AUTOMATION.SEND_EMAIL': 'Enviar e-mail',
	'AUTOMATION.SEND_SMS': 'Enviar SMS',
	'AUTOMATION.CHANGE_DEVICES_S_NETWORK_ENTITLEMENT': 'Alterar direito de acesso à rede do dispositivo',
	'AUTOMATION.CHANGE_DEVICE_S_SIM_STATE': 'Alterar estado de SIM do dispositivo',
	'AUTOMATION.CHANGE_DEVICE_S_RATE_PLAN': 'Alterar plano de tarifas do dispositivo',
	'AUTOMATION.PUSH_API': 'API de envio',
	'AUTOMATION.COMBINED': 'Combinado',
	'AUTOMATION.MO': 'MO',
	'AUTOMATION.MT': 'MT',
	'AUTOMATION.CONNECTIONS_IN_A_24_HOUR_PERIOD': 'Conexões em 24 horas',
	'AUTOMATION.CHANGE_NETWORK_ENTITLEMENT': 'Alterar direito da rede',
	'AUTOMATION.CHANGE_SIM_STATUS': 'Alterar Status de SIM',
	'AUTOMATION.SMS_MESSAGES': 'Mensagens de SMS',
	'AUTOMATION.HOURS': 'Horas',
	'AUTOMATION.MIN': 'Min',
	'AUTOMATION.MINUTES': 'Minutos',
	'AUTOMATION.CHANGE_RATE_PLAN': 'Alterar plano de tarifas',
	'AUTOMATION.CONNECTIONS_IN_THE_CURRENT_CYCLE': 'Conexões no ciclo atual',
	'AUTOMATION.%': '%',
	'AUTOMATION.KB': 'KB',
	'AUTOMATION.REVERT_TO_PREVIOUS_RATE_PLAN': 'Voltar ao plano de tarifas anterior',
	'AUTOMATION.MODIFIED_DELETED': 'Modificado/Excluído',
	'AUTOMATION.THE_SIM_S_RATE_PLAN_IS_ONE_DAY_FROM_EXPIRING': 'O plano de tarifas de SIM expira dentro de um dia',
	'AUTOMATION.AMOUNT_OF_ITS_POOLED_INCLUDED_SMS_USAGE': 'Quantidade de uso de SMS incluído em conjunto',
	'AUTOMATION.NUMBER_OF_DATA_SESSON_IN_THE_LAST_24_HOURS': 'Número de sessão de dados nas últimas 24 horas',
	'AUTOMATION.MSGS': 'MSGs',
	'AUTOMATION.SMS_COUNT_IN_A_ZONE_EXCEEDS': 'Contagem de SMS na zona excede',
	'AUTOMATION.NUMBER_OF_DATA_SESSIONS_IN_THE_LST_24_HOURS': 'Número de sessões de dados nas últimas 24 horas',
	'AUTOMATION.MB': 'MB',
	'AUTOMATION.HOURS_OF_CONSUMPTION': 'Horas de consumo',
	'AUTOMATION.REVERT_TO_PREVIOUS_SIM_STATE': 'Reverter ao estado de SIM anterior',
	'AUTOMATION.DAYS_AFTER_THE_TRIGGER_EVENT': 'Dias após um evento de acionamento',
	'AUTOMATION.END_OF_CURRENT_BILLING_CYCLE': 'Encerramento do ciclo de cobrança atual',
	'AUTOMATION.THE_UTC_TIME_AFTER_TRIGGER_EVENT': 'Horário UTC após evento de acionamento',
	'AUTOMATION.REVERT_TO_PREVIOUS_NETWORK_ENTITLEMENT': 'Reverter ao direito de rede anterior',
	'AUTOMATION.GMNA': 'GM América do Norte',
	'AUTOMATION.POD3': 'ACC Pod3',
	'AUTOMATION.POD19': 'Enterprise',
	'AUTOMATION.GM_NORTH_AMERICA': 'GM América do Norte',
	'AUTOMATION.ENTERPRISE': 'Enterprise',
	'AUTOMATION.ACC_POD3': 'ACC Pod3',
	'AUTOMATION.CHANGE_SIM_STATE': 'Alterar estado de SIM',
	'AUTOMATION.PAST_24_HOURS_SMS_USAGE': 'Uso de SMS nas últimas 24 horas',
	'AUTOMATION.SMS_USAGE_IN_PAST_24_HOURS_EXCEEDS': 'Uso de SMS nas últimas 24 horas excede',
	// Acionamentos
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_24_HOURS': 'Uso de MO (Mobile Originated) de SMS - 24 horas',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_IN_A_ZONE': 'Uso de MO (Mobile Originated) de SMS - em uma zona',
	'AUTOMATION.SMS_MO_MOBILE_ORIGINATED_USAGE_POOLED_PLAN': 'Uso de MO (Mobile Originated) de SMS - plano em pool',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_24_HOURS': 'Uso de MT (Mobile Terminated) de SMS - 24 Hours',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_BILL_CYCLE': 'Uso de MT (Mobile Terminated) de SMS - ciclo de cobrança',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_IN_A_ZONE': 'Uso de MT (Mobile Terminated) de SMS - em uma zona',
	'AUTOMATION.SMS_MT_MOBILE_TERMINATED_USAGE_POOLED_PLAN': 'Uso de MT (Mobile Terminated) de SMS - plano em pool',
	'AUTOMATION.THE_DAY_AFTER_THE_SIM_RATE_PLAN_EXPIRED': 'O dia depois que o plano de tarifas SIM venceu',
	'AUTOMATION.BYTES': 'Bytes',
	'AUTOMATION.CUSTOM_SMS_LABEL': 'Adicionar texto personalizado à mensagem SMS (opcional)',
	'AUTOMATION.CUSTOM_SMS_PLACEHOLDER': 'Texto personalizado da mensagem (limite de 140 caracteres)',
	'AUTOMATION.TB': 'TB',
	'AUTOMATION.B': 'B',
	'AUTOMATION.GB': 'GB',
	'AUTOMATION.SIM_PLAN_COMPLETION': 'Conclusão do plano SIM',
	'AUTOMATION.IF_ANY_SIM_ON_A_PREPAID_RATE_PLAN_COMPLETES_ITS_PREPAID_TERM': 'Se qualquer SIM em um plano de tarifas pré-pago conclui seu prazo pré-pago',
	// nova automação
	'AUTOMATION.TITLE': 'Automação',
	'AUTOMATION.RULES_TAB_NAME': 'Regras de automação',
	'AUTOMATION.ALERTS_TAB_NAME': 'Alertas de automação',
	'AUTOMATION_RULES.YES': 'Sim',
	'AUTOMATION_RULES.NO': 'Não',
	'AUTOMATION_RULES.NO_DATA_TITLE': 'Nenhuma regra de automação disponível',
	'AUTOMATION_RULES.NO_DATA_MESSAGE': 'Não há nenhuma regra de automatização para mostrar no momento',
	'AUTOMATION_ALERTS.NO_DATA_TITLE': 'Nenhum alerta de automação disponível',
	'AUTOMATION_ALERTS.NO_DATA_MESSAGE': 'Não há nenhum alerta de automatização para mostrar no momento',
	//  cabeçalhos da tabela das regras
	'AUTOMATION_RULES.ID': 'ID',
	'AUTOMATION_RULES.RULE_NAME': 'Nome da regra',
	'AUTOMATION_RULES.PLATFORM': 'Plataforma',
	'AUTOMATION_RULES.CATEGORY': 'Categoria',
	'AUTOMATION_RULES.TRIGGER': 'Acionamento',
	'AUTOMATION_RULES.ACTIVE': 'Ativado',
	'AUTOMATION_RULES.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'AUTOMATION_RULES.DATE_CREATED': 'Data criado',
	'AUTOMATION_RULES.USER': 'Usuário',
	'AUTOMATION_RULES.ACTIONS': ' ',
	'AUTOMATION_RULES.STATUS': 'Status da implementação',
	// cabeçalhos da tabela dos alertas
	'AUTOMATION_ALERTS.ID': 'ID',
	'AUTOMATION_ALERTS.RULE_NAME': 'Nome da regra',
	'AUTOMATION_ALERTS.PLATFORM': 'Plataforma',
	'AUTOMATION_ALERTS.CATEGORY': 'Categoria',
	'AUTOMATION_ALERTS.TRIGGER': 'Acionamento',
	'AUTOMATION_ALERTS.ACTIVE': 'Ativado',
	'AUTOMATION_ALERTS.M2M_ACCOUNT_ID': 'ID da conta do M2M',
	'AUTOMATION_ALERTS.DATE': 'Data',
	'AUTOMATION_ALERTS.USER': 'Usuário',
	'AUTOMATION_ALERTS.ACTION': 'Ação',
	// regras
	'AUTOMATION.ACTIVATE': 'Ativar',
	'AUTOMATION.DEACTIVATE': 'Desativar',
	'AUTOMATION.RULE_CREATED_OR_EDITED': 'Você selecionou uma regra de automação que requer processamento estendido e não será configurada imediatamente.',
	'AUTOMATION.ACTIVATED_RULE_SUCCESSFULLY': 'Regra de automação ativada com êxito',
	'AUTOMATION.ACTIVATED_RULE_ERROR': 'Regra de automação não foi ativada com êxito',
	'AUTOMATION.DEACTIVATED_RULE_SUCCESSFULLY': 'Regra de automação desativada com êxito',
	'AUTOMATION.DEACTIVATED_RULE_ERROR': 'Regra de automação não foi desativada com êxito',
	'AUTOMATION.SHORT_CODE': 'Código curto',
	'AUTOMATION.BASE_RATE_PLAN': 'Plano básico de tarifas',
	'AUTOMATION.RATE_PLAN_TYPE': 'Tipo do plano de tarifas',
	'AUTOMATION.IMPLEMENTATION_STATUS': 'Status da implementação',
	'AUTOMATION.YES': 'Sim',
	'AUTOMATION.NO': 'Não',
	'AUTOMATION.RULE_STATUS_CHANGE': 'Alteração do status da regra',
	'AUTOMATION.ARE_YOU_SURE_YOU_WANT_TO_DO_THAT': 'Tem certeza que deseja fazer isso?',
	'AUTOMATION.STATE_CHANGE_CONFIRMATION_TEXT': 'Você está prestes a alterar o {currentState} status de uma regra para {selectedState}',
	'AUTOMATION.REQUEST_DELETION_TEXT': 'Você está prestes a solicitar a remoção de uma regra',
	'AUTOMATION.DELETE_AUTOMATION_RULE_HEADER': 'Remoção da regra',
	'AUTOMATION.DELETE_AUTOMATION_RULE_TITLE': 'Você está prestes a excluir a regra{ruleName}',
	'AUTOMATION.REQUEST_DELETION_TITLE': 'Solicitação de exclusão da regra de automação',
	'AUTOMATION.REQUEST_DELETION': 'Solicitar remoção',
	'AUTOMATION.SET_RULE_RECEIVED_SUCCESS': 'Você alterou com êxito o status de implementação para Recebido',
	'AUTOMATION.SET_RULE_IMPLEMENTED_SUCCESS': 'Você alterou com êxito o status de implementação para Implementado',
	'AUTOMATION.SET_RULE_CONFLICTED_SUCCESS': 'Você alterou com êxito o status de implementação para Conflitante',
	'AUTOMATION.SET_RULE_STATE_FAIL': 'Erro ao alterar o status da implementação. Tente novamente.',
	'AUTOMATION.REQUEST_FOR_DELETION_SUCCESS': 'Você solicitou com êxito a remoção de uma regra',
	'AUTOMATION.REQUEST_FOR_DELETION_FAIL': 'Erro ao solicitar a remoção de uma regra.',
	'AUTOMATION.RULE_CHANGE': 'Alteração da regra de automação',
	'AUTOMATION.RULE_CREATE': 'Criação da regra de automação',
	'AUTOMATION.ACCEPT': 'Aceitar',
	'AUTOMATION.MANUAL_RULE_DELAY': 'Haverá atraso na implementação desta regra.',
	'AUTOMATION.MANUAL_RULE_CONFIRMATION': 'Será necessário um determinado tempo para a execução desta regra.',
	'AUTOMATION.REQUEST_DELETION_CONFIRMATION': 'Estou ciente do atraso do processo de implementação.',
    'AUTOMATION.RULE_STATUS_SELECT_ACTION': 'Selecionar ação:',
	// status
	'AUTOMATION.RULE_STATUS_PENDING_FOR_DELETION': 'Remoção pendente',
	'AUTOMATION.RULE_STATUS_IMPLEMENTED': 'Implementada',
	'AUTOMATION.RULE_STATUS_PENDING': 'Pendente',
	'AUTOMATION.RULE_STATUS_CONFLICTED': 'Conflitante',
	'AUTOMATION.RULE_STATUS_RECEIVED': 'Recebida',
	'AUTOMATION.RULE_STATUS_NOT_APPLICABLE': 'Não aplicável',
	//  Modelo da zona
	'AUTOMATION.ATT_STANDARD_ZONE_1': 'Zona padrão 1 da ATT',
	'AUTOMATION.ATT_STANDARD_ZONE_2': 'Zona padrão 2 da ATT',
	'AUTOMATION.ATT_STANDARD_ZONE_3': 'Zona padrão 3 da ATT',
	//  Nome da zona
	'AUTOMATION.US_DOMESTIC': 'Local USA',
	'AUTOMATION.EU': 'UE'
};
