import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FieldGroup from '../../../../../lib/DigitalComponents/FieldGroup';
import Checkbox from '../../../../../lib/DigitalComponents/Checkbox';
import { normalize } from '../../../../../utils/constants';
import { validate } from '../../../utils/constants';

import styles from '../EditTableModal.scss';

class EditCustomFieldLabels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: []
		};
	}

	componentDidMount() {
		const { items } = this.props;
		let fields = [];

		Object.keys(items).forEach((key) => {
			if (key.startsWith('customLabel') && key !== 'messageId') {
				const number = key.substr('customLabel'.length);

				fields = [
					...fields,
					{
						label: items[key],
						isRequired: items[`isCustomField${number}Required`]
							? items[`isCustomField${number}Required`]
							: false
					}
				];
			}
		});

		this.setState({ fields });
	}

	componentDidUpdate(prevProps) {
    const { invalid, pristine, setButtonLabels } = this.props;

		if (invalid || (!prevProps.pristine && pristine)) {
			setButtonLabels(true);
		} else if (!pristine) {
			setButtonLabels(false);
		}
	}

	render() {
		const { fields } = this.state;
    let temp = {};

		return (
			<div
				className={styles.content_wrapper}
				data-spec="edit-custom-field-labels"
			>
				{fields.map((field, index) => {
					temp = { ...temp, [`test${index}`]: field.label };
					return (
						<div
							data-spec="label-item"
							className={styles.item}
							key={field.label}
						>
							<Field
								type="text"
								component={FieldGroup}
								label={
									<FormattedMessage
										id="ENDPOINTS.CUSTOM_LABEL"
										defaultMessage="Custom Label {index}"
										values={{ index: index + 1 }}
									/>
								}
								name={`customLabel${index + 1}`}
								maxLength="50"
								normalize={normalize}
							/>
							<Field
								type="checkbox"
								component={Checkbox}
								label={
									<FormattedMessage
										id="SET_AS_REQUIRED"
										defaultMessage="Set As Required"
									/>
								}
								data-spec="is-required"
								name={`isCustomField${index + 1}Required`}
							/>
						</div>
					);
				})}
			</div>
		);
	}
}

const { object, bool, func } = PropTypes;

EditCustomFieldLabels.propTypes = {
  items: object,
  invalid: bool,
  pristine: bool,
  setButtonLabels: func
};

EditCustomFieldLabels.defaultProps = {
  items: {},
  invalid: false,
  pristine: false
};

let EditCustomFieldLabelsForm = reduxForm({
	form: 'EditCustomFieldLabels',
	validate
})(EditCustomFieldLabels);

EditCustomFieldLabelsForm = connect((state, props) => {
	let fields = {};

	Object.keys(props.items).forEach((key) => {
		if (key.startsWith('customLabel') && key !== 'messageId') {
			const number = key.substr('customLabel'.length);

			fields = {
				...fields,
				[`customLabel${number}`]: props.items[key],
				[`isCustomField${number}Required`]: props.items[
					`isCustomField${number}Required`
				]
					? props.items[`isCustomField${number}Required`]
					: false
			};
		}
	});

	return { initialValues: fields };
})(EditCustomFieldLabelsForm);

export default EditCustomFieldLabelsForm;
