import React from 'react';

function Ruby() {
	return (
		<div data-spec="ruby-asset">
			<pre>
				{`
          imrequire 'uri'
          require 'net/http'

          url = URI("https://cisiot.att.com/EAI/oauth/token")

          http = Net::HTTP.new(url.host, url.port)

          request = Net::HTTP::Post.new(url)
          request["Content-Type"] = 'application/x-www-form-urlencoded'
          request["Authorization"] = 'Basic YOUR_ENCODED_CREDENTIALS'
          request.body = "username=USERNAME&password=PASSWORD&grant_type=password"

          response = http.request(request)
          puts response.read_body`}
			</pre>
		</div>
	);
}

export default Ruby;
