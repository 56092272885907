import React from 'react';
import {
	arrayOf,
	object,
	bool,
	oneOfType,
} from 'prop-types';

import styles from './GroupButton.scss';

export default function GroupButton({ children }) {
	return (
		<div
			data-spec="group-button-wrapper"
			className={children.filter((child) => child !== null).length > 1
				? styles.wrapper : ''}
		>
			{children.map((child) => (
				child
			))}
		</div>
	);
}

GroupButton.propTypes = {
	children: arrayOf(oneOfType([object, bool])),
};

GroupButton.defaultProps = {
	children: [],
};
