import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getRatePlans = (params) => {
	const { companyId, m2mAccountId, additionalParams } = params;
	let url = `/onboarding/my-company/m2m-accounts/${m2mAccountId}/rate-plans?${qs.stringify(
		{ searchParams: additionalParams.searchParams }
	)}`;
	if (companyId) {
		url = `/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/rate-plans?${qs.stringify(
			{ searchParams: additionalParams.searchParams }
		)}`;
	}
	return ApiService.get(url);
};

export const getRatePlan = (params) => {
	const { companyId, m2mAccountId, id } = params;
	return ApiService.get(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/rate-plans/${id}`
	);
};

export const addRatePlan = (params) => {
	const { companyId, m2mAccountId, data } = params;
	return ApiService.post(
		`/onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/rate-plans`,
		data
	);
};

export const editRatePlan = (params) => {
	const { companyId, m2mAccountId, id, data } = params;
	return ApiService.patch(
		`onboarding/companies/${companyId}/m2m-accounts/${m2mAccountId}/rate-plans/${id}`,
		data
	);
};

export const getAllowedTypes = () =>
	ApiService.get('onboarding/rate-plans/allowed-types');
