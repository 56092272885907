import React from 'react';
import { string, object, bool, oneOfType, number } from 'prop-types';
import cn from 'classnames';

import styles from './StaticField.scss';

const StaticFieldPropTypes = {
	label: string.isRequired,
	data: oneOfType([string, object, number]),
	single: bool,
	customStyle: object
};

const StaticFieldDefaultProps = {
	single: false,
	customStyle: {}
};

export default function StaticField({ label, data, single, customStyle }) {
	const StaticFieldClass = cn({
		[styles.field_wrapper]: true,
		[styles.single_field]: single,
		customStyle
	});

	return (
		<div data-spec="field-wrapper" className={StaticFieldClass}>
			<label className={styles.label}>{label}</label>
			<div className={styles.info}>{data}</div>
		</div>
	);
}

StaticField.propTypes = StaticFieldPropTypes;
StaticField.defaultProps = StaticFieldDefaultProps;
