import React, { PureComponent } from 'react';
import { func, bool, object, number, array } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {
	OnboardingTableHeader as tableHeader,
	OnboardingTableHeaderMobile as tableHeaderMobile,
	onboardingSignature,
	onboardingSearchMenu
} from '../../utils/constants';

import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';

import {
	onOrderClick,
	newOnPageChange
} from '../../../../utils/tableHelpers';

import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';

import Button from '../../../../lib/DigitalComponents/Button';
import Loading from '../../../../lib/DigitalComponents/Loader';

import PageTitle from '../../../Shared/views/PageTitleView';
import ActionBar from '../../../Shared/views/ActionBarView';
import NoData from '../../../Shared/views/NoDataView';
import FilterModal from '../../../Shared/components/FilterModal';

import { getCompaniesFilters } from '../../services/CompaniesService';

import styles from './Companies.scss';

const FilterIcon = ImageSelector(CurrentContext.theme, 'svgs/filter.svg');

const CompaniesRWComponent = UserAllowedToAccess(['system.companies_rw']);

export default function Companies(WrappedComponent) {
	class CompaniesComponent extends PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				dataOffset: 0,
				dataLimit: props.dataLimit,
				selectedPage: 0,
				orderColumn: {},
				settings: props.userSettings[0]
					? JSON.parse(props.userSettings[0].value)
					: {}
			};
		}

		componentDidMount() {
			const { createUserSettings, userSettings } = this.props;
			this.getCompanies();

			if (!userSettings[0]) {
				createUserSettings({
					refTypeId: 8,
					name: 'TableHeader',
					value: JSON.stringify({
						signature: onboardingSignature,
						header: tableHeader.map((item, index) => {
							item.order = index + 1;
							return item;
						})
					})
				});
				this.setState({
					settings: {
						signature: onboardingSignature,
						header: tableHeader.map((item, index) => {
							item.order = index + 1;
							return item;
						})
					}
				});
			}
		}

		componentDidUpdate(prevProps) {
			const {
				getCompanyTokenSuccess,
				user
			} = this.props;
			const { settings, companyId } = this.state;

			if (settings.signature !== onboardingSignature) {
				this.updateUserSettings();
			}
			if (
				prevProps.getCompanyTokenSuccess !== getCompanyTokenSuccess &&
				getCompanyTokenSuccess
			) {
				sessionStorage.setItem('company-token', getCompanyTokenSuccess.token);
				const sessionLanguage = sessionStorage.getItem('language');
				sessionStorage.setItem('company', companyId);
				window.location.href = `/${
					sessionLanguage || user.language || 'en-us'
					}`;
			}
		}

		componentWillUnmount() {
			const { simpleSearchClose } = this.props;
			simpleSearchClose();
		}

		updateUserSettings = () => {
			const { editUserSetting, userSettings } = this.props;
			const newValue = {
				value: JSON.stringify({
					signature: onboardingSignature,
					header: tableHeader.map((item, index) => {
						item.order = index + 1;
						return item;
					})
				})
			};

			editUserSetting(newValue, userSettings[0].id);
			this.setState({ settings: JSON.parse(newValue.value) });
		};

		addNewCompany = () => {
			const { addNewCompany } = this.props;
			addNewCompany('/companies/add-company');
		};

		getCompanies = () => {
			const { orderColumn } = this.state;
			const { getCompanies, searchParameter, filterData, dataLimit, dataOffset } = this.props;
			let simpleSearchParams = [];
			let searchParams = [];
			const realProps = {
				platforms: 'platformTypeCode',
			};

			if (searchParameter) {
				simpleSearchParams = [
					{
						operator: 'iLike',
						prop: searchParameter.prop,
						propValue: `${searchParameter.propValue}%`,
					},
				];
			}
			if (filterData) {
				Object.keys(filterData).forEach((key) => {
					const item = filterData[key].filter((filterItem) => filterItem.checked);
					if (item.length) {
						const temp = {
							propValue: item.map((m) => m.code),
							prop: realProps[key],
							operator: 'in'
						};
						searchParams = [...searchParams, temp];
					}
				});
				simpleSearchParams = simpleSearchParams.concat(searchParams);
			}
			const params = {
				searchParams: simpleSearchParams,
				additionalParams: {
					dataLimit: dataLimit || 50,
					dataOffset: dataOffset || 0,
					dataSort: orderColumn.apiString || 'createdAt desc',
					include: ['PlatformTypes']
				}
			};

			getCompanies(params);
		};

		handlePageClick = (data) => {
			const { dataLimit } = this.state;
			this.setState(
				{
					dataLimit,
					dataOffset: dataLimit * data.selected,
					selectedPage: data.selected
				},
				() => this.getCompanies()
			);
			window.scrollTo(0, 0);
		};

		onOrderClick = (data) => {
			const { name: column, apiString } = data;
			const { orderColumn } = this.state;
			this.setState(
				{
					orderColumn: {
						name: column,
						order: orderColumn.name === column ? !orderColumn.order : true,
						apiString
					}
				},
				() => this.getCompanies()
			);
		};

		totalCount = () => {
			const { companies } = this.props;
			const { dataLimit } = this.state;
			return Math.ceil(companies.totalCount / dataLimit);
		};

		allowToLaunch = () => {
			const { user } = this.props;
			return isUserAllowedToAccess(
				['system.companies.launch_rw', 'system.companies.launch_ro'],
				user
			);
		};

		onPageChange = async (data) => {
			const { setDataOffset, setSelectedPage, dataLimit } = this.props;
			const { selected } = data;
			await setDataOffset(dataLimit * selected);
			await setSelectedPage(selected);
			this.getCompanies();
		}

		getOptions = (target) => {
			const { dataLimit, orderColumn } = this.state;
			const { companies, setDataOffset, selectedPage, setSelectedPage } = this.props;

			const isMobile = target === 'mobile';
			const isDesktop = target === 'desktop';

			const options = {};

			if (isDesktop) {
				options.header = this.getTableHeader();
			}

			if (isMobile) {
				options.header = tableHeaderMobile;
			}
			options.tableOptions = {
				showPagination: companies.totalCount > dataLimit,
				pageCount: this.totalCount(),
				forcePage: selectedPage,
				onPageChange: (data) => newOnPageChange(
					data,
					this.getCompanies,
					dataLimit,
					setDataOffset,
					setSelectedPage
				)
			};

			options.customComponents = {
				companyFriendlyName: {
					type: 'custom',
					component: (val) => (
						<span
							className={
								this.allowToLaunch() ? styles.clickable : styles.disabled
							}
							onClick={() =>
								(this.allowToLaunch() ? this.launchMc(val) : undefined)}
						>
							{val.companyFriendlyName}
						</span>
					)
				},
				platforms: {
					type: 'custom',
					component: (val) => this.renderPlatforms(val)
				},
				creatorId: {
					type: 'custom',
					component: (val) => (
						<div
							className={styles.wide_clickable_column}
							data-spec="creator-email"
						>
							<a className={styles.clickable} href={`mailto:${val.creatorId}`}>
								{val.creatorId}
							</a>
						</div>
					)
				},
				actions: {
					type: 'custom',
					component: (val) => this.renderActions(val)
				}
			};
			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions,
					firstColumnMarked: true,
					fixedFirstColumn: true,
					orderByData: ['all'],
					excludeFromOrdering: ['actions', 'platforms'],
					getOrderData: (data) => onOrderClick(data, this.getCompanies, this),
					orderColumn,
					draggable: true,
					onDragEnd: (fromIndex, toIndex) => this.onDragEnd(fromIndex, toIndex),
					excludeDraggableColumns: ['companyFriendlyName'],
					fixedHeader: true
				};
			}

			if (isMobile) {
				options.customComponents = {
					...options.customComponents,
					launchIoTConsole: {
						type: 'custom',
						component: (val) => (
							<span
								className={styles.clickable}
								onClick={() => this.launchMc(val)}
							>
								{val.companyFriendlyName}
							</span>
						)
					},
					actions: {
						type: 'custom',
						component: (val) => this.renderActions(val)
					},
					platforms: {
						type: 'custom',
						component: (val) =>
						(val.platformTypes.length > 0 ?
							this.renderPlatforms(val) :
							<FormattedMessage
								id="ONBOARDING.NO_PLATFORMS"
								defaultMessage="No platforms"
							/>
						)
					}
				};
			}

			return options;
		};

		launchMc = async (item) => {
			const { getCompanyToken } = this.props;
			this.setState(
				{
					companyId: item.externalCompanyId
				},
				() => getCompanyToken({ companyId: item.id })
			);
		};

		editCompany = (companyId, tab) => {
			const { editCompany, changeCompanyInitalTab } = this.props;
			editCompany(`/companies/${companyId}`);
			changeCompanyInitalTab(tab);
		}

		renderPlatforms = (data) => {
			const { goToAddAccount } = this.props;
			return data.platformTypes.length > 0 ? (
				<div className={styles.wide_clickable_column} onClick={() => this.editCompany(data.id, 'accounts')}>
					<span>
						{data.platformTypes.slice(0, 2).map((el) => el.description).join(', ')}
						{data.platformTypes[3] && '...'}
					</span>
					{data.platformTypes[3] && <span>more</span>}
				</div>
			) : (
					<div className={styles.wide_clickable_column}>
						<span onClick={() => goToAddAccount(`/companies/${data.id}/accounts/new-account`)}>
							+ Add platform
						</span>
					</div>
				);
		}

		renderActions = (data) => (
			<div data-spec="actions" className={styles.actions}>
				<div onClick={() => this.editCompany(data.id, 'details')}>
					<FormattedMessage
						id="ONBOARDING.COMPANY_SETTINGS"
						defaultMessage="Company Settings"
					/>
				</div>
			</div>
		);

		renderBottomActionBar = () => {
			const { openGlobalFilterModal } = this.props;
			return (
				<div data-spec="filter-icon" className={`${styles.icon}`}>
					<FilterIcon
						onClick={() => {
							openGlobalFilterModal();
							// fetchEndpointsFilters(
							// 	tableHeaderPermissionsCheck(endpointsFiltersData, user)
							// );
						}}
						data-tip={'Select Filters'}
						data-for="selectFiltersTooltip"
						data-spec="filter-icon"
					/>
					<ReactTooltip
						className={styles.tooltip}
						type="light"
						id="selectFiltersTooltip"
					/>
				</div>
			);
		}

		onDragEnd = async (fromIndex, toIndex) => {
			const { editUserSetting, userSettings } = this.props;
			if (toIndex < 0) return;

			const data = await this.getTableHeader();
			[data[fromIndex], data[toIndex]] = [data[toIndex], data[fromIndex]];
			const temp = {
				value: JSON.stringify({
					signature: onboardingSignature,
					header: data.map((item, index) => {
						item.order = index + 1;
						return item;
					})
				})
			};

			editUserSetting(temp, userSettings[0].id);
			this.setState({ settings: JSON.parse(temp.value) });
		};

		getTableHeader = () => {
			const { settings } = this.state;
			return !settings ? tableHeader : settings.header;
		};

		onSearchSubmitCallback = () => {
			this.getCompanies();
		};

		openFilterModal = () => {
			const { openFilterModal } = this.props;
			openFilterModal();
		};

		clearFilters = () => {
			this.getCompanies();
		};

		onChange = () => {
			this.getCompanies();
		};

		onApply = () => {
			this.getCompanies();
		};

		createFilterData = (data) => {
			const filterDataKeys = Object.keys(data);
			let filter = {};

			filterDataKeys.length &&
				filterDataKeys.forEach((item) => {
					filter = {
						...filter,
						[`${item}`]: data[item].map((filterItem) => ({
							value: filterItem.id,
							label: filterItem.description || filterItem.name,
							code: filterItem.code,
							checked: false,
						})),
					};
				});
			return filter;
		};

		render() {
			const {
				companiesRequest,
				companiesFail,
				companies,
				isSimpleSearchActive,
				editCompaniesUserSettingsRequest,
				messages,
				getCompanyTokenRequest,
				getCompanyTokenSuccess,
				selectedPage
			} = this.props;

			const params = {
				service: getCompaniesFilters,
				createFilterData: this.createFilterData,
			};

			return (
				<div data-spec="entities">
					<PageTitle
						title={
							<FormattedMessage
								id="ONBOARDING.IOTC_CUSTOMERS"
								defaultMessage="IoT Console Customers"
							/>
						}
						actions={
							<CompaniesRWComponent>
								<Button
									label={
										<FormattedMessage
											id="ONBOARDING.CREATE_NEW_COMPANY"
											defaultMessage="Create New Company"
										/>
									}
									variant="primary"
									className={styles.button_width}
									dataSpec="create-new-company-button"
									onClick={this.addNewCompany}
									disabled={companiesRequest}
								/>
							</CompaniesRWComponent>
						}
						messages={messages}
					/>
					<ActionBar
						menu={onboardingSearchMenu}
						hasSearch
						onSearchSubmitCallback={this.onSearchSubmitCallback}
						isSimpleSearchActive={isSimpleSearchActive}
						openFilterModal={this.openFilterModal}
						clearFilters={this.clearFilters}
						onChange={this.onChange}
						selectedPage={selectedPage}
					/>
					<FilterModal type="radio" params={params} onApply={this.onApply} />
					{(companiesRequest ||
						getCompanyTokenRequest ||
						getCompanyTokenSuccess ||
						editCompaniesUserSettingsRequest ||
						getCompanyTokenRequest) && <Loading data-spec="loading" />}
					{companiesFail && null}
					{!companiesRequest &&
						!getCompanyTokenSuccess &&
						!getCompanyTokenRequest &&
						!editCompaniesUserSettingsRequest &&
						companies && (
							<WrappedComponent
								{...this.state}
								{...this.props}
								getOptions={this.getOptions}
							/>
						)}
					{companies &&
						companies.resultList &&
						companies.resultList.length === 0 && (
							<NoData
								title={
									<FormattedMessage
										id="ONBOARDING.NO_COMPANIES_AVAILABLE"
										defaultMessage="No Companies Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_COMPANIES_AVAILABLE_SUBTITLE"
										defaultMessage="There are No Companies for this Table to show you right now"
									/>
								}
							/>
						)}
				</div>
			);
		}
	}

	CompaniesComponent.propTypes = {
		dataLimit: number,
		getCompanies: func,
		companiesRequest: bool,
		companiesFail: bool,
		companies: object,
		simpleSearchStart: func,
		simpleSearchClose: func,
		isSimpleSearchActive: bool,
		addNewCompany: func,
		editCompany: func,
		goToAddAccount: func,
		changeCompanyInitalTab: func,
		openGlobalFilterModal: func,
		openFilterModal: func,
		userSettings: array,
		editUserSetting: func,
		editCompaniesUserSettingsInit: bool,
		editCompaniesUserSettingsRequest: bool,
		messages: object,
		user: object,
		createUserSettings: func,
		getCompanyToken: func,
		getCompanyTokenRequest: bool,
		getCompanyTokenSuccess: object,
		searchParameter: object,
		filterData: object,
		dataOffset: number,
		setDataOffset: func,
		setSelectedPage: func,
		selectedPage: number
	};
	return CompaniesComponent;
}
