import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import Tabs from '../../lib/DigitalComponents/Tabs';
import { DesktopAndTabletMediaQuery } from '../../utils/CustomMediaQuery';

import styles from './GlobalActionBar.scss';

class ActionBar extends PureComponent {
	render() {
		const {
			messages,
			customComponentsTop,
			customComponentsBottom,
			classname,
			tabs,
			actions
		} = this.props;

		return (
			<div data-spec="global-action-bar" className={styles.action_bar_wrapper}>
				{(!isEmpty(customComponentsTop) ||
					(tabs.length !== 0 || !isEmpty(actions))) && (
					<div className={cn(styles.top, classname)}>
						<Tabs tabs={tabs} actions={actions} messages={messages} />
						<DesktopAndTabletMediaQuery>
							{customComponentsTop || null}
						</DesktopAndTabletMediaQuery>
					</div>
				)}
				{!isEmpty(customComponentsBottom) && (
					<div className={styles.bottom}>
						<DesktopAndTabletMediaQuery>
							{customComponentsBottom || null}
						</DesktopAndTabletMediaQuery>
					</div>
				)}
			</div>
		);
	}
}

const { object, array, string } = PropTypes;

ActionBar.propTypes = {
	actions: object,
	tabs: array,
	messages: object,
	customComponentsTop: object,
	customComponentsBottom: object,
	classname: string
};

ActionBar.defaultProps = {
	actions: {},
	tabs: [],
	messages: {}
};

export default ActionBar;
