import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getReleaseNotesList = (params) =>
    ApiService.get(
      `/releases${params.published ? '/published' : ''}?${qs.stringify({
        additionalParams: params.additionalParams
      })}`
    );

export const getReleaseNotes = (params) => {
  let url = '/releases';

  if (params.published) {
    url += `/published/${params.id}`;
  } else {
    url += `/${params.id}`;
  }

  return ApiService.get(
		`${url}?${qs.stringify({
			additionalParams: params.additionalParams
		})}`
	);
};

export const addReleaseNotes = (params) =>
	ApiService.post('/releases', params.data);

export const editReleaseNotes = (params) =>
	ApiService.patch(`/releases/${params.id}`, params.data);

export const getAdditionalDetailStatuses = () =>
  ApiService.get('/releases/additional-detail-statuses');

export const publishReleaseNotes = (params) =>
	ApiService.patch(`/releases/${params.id}/publish`);
