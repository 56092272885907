import globalAction from '../../../../redux/global/globalAction';
import { getUploadTemplate as getUploadTemplateService } from '../../services/SecurityFeaturesService';
import { GET_SECURITY_FEATURES_UPLOAD_TEMPLATE } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const getUploadTemplate = (params) =>
	globalAction(
		GET_SECURITY_FEATURES_UPLOAD_TEMPLATE,
		getUploadTemplateService,
		params
	);
