import { Status } from '../../../../utils/constants';

/* ******************** ADD ACCOUNT ******************** */
export const editUserGroupAssignedAccountsRequest = (state) =>
	state.onboarding.userGroupAssignedAccounts.editUserGroupAssignedAccounts
		.status === Status.PENDING;
export const editUserGroupAssignedAccountsFail = (state) =>
	state.onboarding.userGroupAssignedAccounts.editUserGroupAssignedAccounts
		.status === Status.FAIL;
export const editUserGroupAssignedAccountsSuccess = (state) =>
	state.onboarding.userGroupAssignedAccounts.editUserGroupAssignedAccounts
		.status === Status.DONE;
