import { handleActions } from 'redux-actions';

import { TOGGLE_SIDEBAR } from './constants';

export const initialState = {
  isCollapsed: localStorage.getItem('mnc-sidebar') === 'true'
};

export default handleActions(
	{
		[TOGGLE_SIDEBAR]: (state) => ({
				...state,
				isCollapsed: !state.isCollapsed
			})
	},
	initialState
);
