import { connect } from 'react-redux';
import BatchActionsDesktop from './Desktop';
import BatchActionsMobile from './Mobile';

import { getBatchActions } from '../../redux/batchActions/actions';
import {
	downloadReportRequestURL
} from '../../../Reports/redux/downloadReport/actions';

import {
	getBatchActionsRequest,
	getBatchActionsFail,
	getBatchActionsSuccess
} from '../../redux/batchActions/selectors';

import { setDataOffset, setSelectedPage } from '../../../Shared/redux/pagination/actions';
import { getDataOffset, getSelectedPage } from '../../../Shared/redux/pagination/selectors';
import {
	downloadReportRequest
} from '../../redux/batchReports/selectors';

const mapStateToProps = (state) => ({
	batchActionsRequest: getBatchActionsRequest(state),
	batchActionsFail: getBatchActionsFail(state),
  batchActions: getBatchActionsSuccess(state),
  dataOffset: getDataOffset(state),
  selectedPage: getSelectedPage(state),
	downloadReportRequest: downloadReportRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
  getBatchActions: (params) => dispatch(getBatchActions(params)),
  setDataOffset: (dataOffset) => dispatch(setDataOffset(dataOffset)),
  setSelectedPage: (selectedPage) => dispatch(setSelectedPage(selectedPage)),
	downloadReport: (
		fullReportFileName,
		reportFrequency,
		serviceProvider,
		year,
		month,
		day,
		reportCategory,
		m2mAccountId
	) => dispatch(
			downloadReportRequestURL({
				reportFileName: fullReportFileName,
				reportFrequency,
				reportCategory,
				serviceProvider,
				yearOfGeneration: year,
				monthOfGeneration: month,
				dateOfGeneration: day,
				m2mAccountId
			})
		)
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(BatchActionsDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(BatchActionsMobile);
