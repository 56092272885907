import React, { PureComponent } from 'react';
import { object, func, bool, string } from 'prop-types';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { FormattedDate, FormattedMessage } from 'react-intl';

import DSLastStep from '../../../../Steps';
import Loading from '../../../../../lib/DigitalComponents/Loader';
import Button from '../../../../../lib/DigitalComponents/Button';

export default class StepThird extends PureComponent {
	dateFormat = 'MM/dd/yyyy';

	componentDidMount() {
    const { context } = this.props;

		if (context) {
			this.setFooter(context);
		}
	}

	componentDidUpdate(prevProps) {
    const { context, isRatePlanUpdating } = this.props;

		if (context && !isEqual(isRatePlanUpdating, prevProps.isRatePlanUpdating)) {
			this.setFooter(context);
		}
	}

	isScheduled = () => {
    const { ratePlans } = this.props;

    return (
			format(new Date(), this.dateFormat) !==
			format(ratePlans.startDate, this.dateFormat)
		);
  };

	renderLastStepSuccess = () => {
    const { ratePlanUpdateSuccess, ratePlans } = this.props;

		if (ratePlanUpdateSuccess) {
			return (
				<DSLastStep
					data-spec="last success step"
					title={
						this.isScheduled() ? (
							<FormattedMessage
								id="SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED"
								defaultMessage="Rate plan change is successfully scheduled"
							/>
						) : (
							<FormattedMessage
								id="SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_UPDATED"
								defaultMessage="Rate plan change is successfully updated"
							/>
						)
					}
					msg={
						this.isScheduled() ? (
							<>
								<FormattedMessage
									id="SINGLE_ENDPOINT.RATE_PLAN_CHANGE_IS_SUCCESSFULLY_SCHEDULED_FOR"
									defaultMessage="Rate plan change is successfully scheduled for "
								/>
								<FormattedDate value={ratePlans.startDate} />
							</>
						) : (
							''
						)
					}
				/>
			);
		}
	};

	renderLastStepError = () => {
    const { ratePlanUpdateFail } = this.props;

		if (ratePlanUpdateFail) {
			return (
				<DSLastStep
					data-spec="last step error"
					type="error"
					title={
						this.isScheduled() ? (
							<FormattedMessage
								id="SINGLE_ENDPOINT.RATE_PLAN_CHANGE_COULD_NOT_BE_SCHEDULED_SUCCESSFULLY"
								defaultMessage="Rate plan change could not be scheduled successfully"
							/>
						) : (
							<FormattedMessage
								id="SINGLE_ENDPOINT.RATE_PLAN_CHANGE_COULD_NOT_BE_CARRIED_OUT_SUCCESSFULLY"
								defaultMessage="Rate plan change could not be carried out successfully"
							/>
						)
					}
					msg={
						<FormattedMessage
							id="PLEASE_TRY_AGAIN"
							defaultMessage="Please try again."
						/>
					}
				/>
			);
		}
	};

	setFooter = (context) => {
    const {
			onClose,
			getSingleEndpoint,
			endpointId,
			type,
      isRatePlanUpdating,
		} = this.props;
		const footer = (
			<>
				<Button
					variant="success"
					onClick={() => {
						onClose();
						getSingleEndpoint(
							endpointId,
							type
						);
					}}
					disabled={isRatePlanUpdating}
					label={<FormattedMessage
						id="DONE"
						defaultMessage="Done"
					/>}
					dataSpec="done-button"
				/>
			</>
		);

		context.updateContext({ footer });
	};

	render() {
    const { isRatePlanUpdating } = this.props;
		return (
			<div data-spec="third-step-modal-body">
				<div data-spec="ds-modal-body">
					{isRatePlanUpdating && (
						<FormattedMessage
							id="UPDATING"
							defaultMessage="Updating..."
						>
							{(formattedValues) => (
								<Loading
									data-spec="loading"
									msg={formattedValues}
								/>
							)}
						</FormattedMessage>
					)}

					{!isRatePlanUpdating && this.renderLastStepSuccess()}
					{!isRatePlanUpdating && this.renderLastStepError()}
				</div>
			</div>
		);
	}
}

StepThird.propTypes = {
	onClose: func,
	ratePlans: object,
	isRatePlanUpdating: bool,
	ratePlanUpdateFail: bool,
	ratePlanUpdateSuccess: bool,
	getSingleEndpoint: func,
	endpointId: string,
	type: string,
	context: object,
};
StepThird.defaultProps = {
	onClose: undefined,
	ratePlans: {},
	isRatePlanUpdating: false,
	ratePlanUpdateFail: false,
	ratePlanUpdateSuccess: false,
	getSingleEndpoint: undefined,
	endpointId: '',
	type: '',
	context: {},
};
