import React from 'react';
import hash from 'object-hash';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

export const EndpointsTableTabs = [
	{
		name: 'tab',
		refTypeId: 1,
		default: true,
		value: {
			data: [
				{
					tabName: 'Endpoints',
					value: 0
				},
				{
					title: 'ENDPOINT_NAME',
					name: 'endpointName',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'M2MACCOUNTID',
					name: 'm2mAccountId',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'IMEI',
					name: 'imei',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'STATUS',
					name: 'status',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'IMSI',
					name: 'imsi',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'NETWORK_OPERATOR',
					name: 'networkOperator',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'COUNTRY_OPERATOR',
					name: 'networkCountry',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'NETWORK_TYPE',
					name: 'networkType',
					checked: true,
					type: 'cellular'
				},
				{
					title: 'DEVICE_NAME',
					name: 'deviceName',
					checked: true,
					type: 'satellite'
				},
				// {
				// 	title: 'IMSI_TRANSFER',
				// 	name: 'transferStatus',
				// 	checked: true,
				// 	type: 'cellular'
				// },
				{
					title: 'RATE_PLAN',
					name: 'ratePlanId',
					checked: true,
					type: 'cellular'
				},
				// {
				// 	title: 'RECREDENTIAL_STATUS',
				// 	name: 'recredentialTransferStatus',
				// 	checked: true,
				// 	type: 'cellular'
				// },
				{
					title: 'ICCID',
					name: 'iccid',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'SERVICE_PROVIDER',
					name: 'serviceProvider',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'MSISDN',
					name: 'msisdn',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'NETWORK_ENTITLEMENT_ID',
					name: 'networkEntitlementId',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'DEVICE_MODEL_NUMBER',
					name: 'deviceModelNumber',
					checked: false,
					type: 'satellite'
				},
				{
					title: 'DEVICE_TYPE',
					name: 'deviceType',
					checked: false,
					type: 'satellite'
				},
				/*  {
        title: "DEVICE_STATUS",
        name: 'deviceStatus',
        checked: false,
        type: 'satellite'
      }, */
				{
					title: 'DEVICE_ID',
					name: 'deviceId',
					checked: false,
					type: 'satellite'
				},
				{
					title: 'TECH_TYPE',
					name: 'techType',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'TECH_TYPE_SUB_TYPE',
					name: 'techtypeSubtype',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'OEM',
					name: 'oem',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'SERVICE_STATUS',
					name: 'serviceStatus',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'VIN',
					name: 'vin',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'ROAMING_COUNTRY',
					name: 'roamingCountry',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'ROAMING_CARRIER',
					name: 'roamingCarrier',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'M2M_DATE_ADDED',
					name: 'm2mDateAdded',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'M2M_INITIAL_ACTIVATION_DATE',
					name: 'm2mInitialActivationDate',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'NOTES',
					name: 'seNotes',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'CTD_DATA_USAGE',
					name: 'dataBytesCtd',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'CTD_SMS_MO_MT_USAGE',
					name: 'smsCountCtd',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'CTD_VOICE_USAGE',
					name: 'voiceSecondsCtd',
					checked: false,
					type: 'cellular'
				},
				{
					title: 'INSESSION',
					name: 'dataSession',
					checked: false,
					type: 'cellular'
				}
			]
		}
	}
];
export const endpointsFiltersData = [
	'EndpointState',
	'NetworkOperator',
	'NetworkCountry',
	'NetworkEntitlement',
	'NetworkType',
	'TransferStatus',
	'Rateplan',
	'RecredentialTransferStatus',
	'M2MPlatform',
	'RoamingCountry',
	'RoamingCarrier',
	'ServiceStatus',
	'OEM',
	'M2MDateAdded',
	'm2mAccountId'
];

export const notificationMessages = {
	success: 'TABLE_COLUMNS_CHANGE_SUCCESS_MESSAGE',
	error: 'TABLE_COLUMNS_CHANGE_ERROR_MESSAGE'
};

export const endpointSignature = hash(
	JSON.stringify(EndpointsTableTabs[0].value.data)
);

const customFieldsSearchMenu = () => {
	let fields = [];
	for (let i = 1; i <= 10; i += 1) {
		fields = [
			...fields,
			{
				label: (
					<div>
						<FormattedMessage id="BY" defaultMessage="By " />
						<FormattedMessage
							id={`CUSTOM_FIELD_${i}`}
							defaultMessage={`Custom Field ${i}`}
						/>
					</div>
				),
				value: `customFieldValue${i}`
			}
		];
	}
	return fields;
};

export const searchMenu = [
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="ENDPOINT_NAME" defaultMessage="Endpoint ID" />
			</div>
		),
		value: 'endpointName'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="IMEI" />
			</div>
		),
		value: 'imei'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="IMSI" />
			</div>
		),
		value: 'imsi'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="MSISDN" />
			</div>
		),
		value: 'msisdn'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
			</div>
		),
		value: 'deviceId'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage
					id="DEVICE_MODEL_NUMBER"
					defaultMessage="Device Model #"
				/>
			</div>
		),
		value: 'deviceModelNumber'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="OEM" defaultMessage="Sub Accounts" />
			</div>
		),
		value: 'oem'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="SERVICE_STATUS" defaultMessage="360L Status" />
			</div>
		),
		value: 'serviceStatus'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="VIN" />
			</div>
		),
		value: 'vin'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage
					id="ROAMING_COUNTRY"
					defaultMessage="Roaming Country"
				/>
			</div>
		),
		value: 'roamingCountry'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage
					id="ROAMING_CARRIER"
					defaultMessage="Roaming Carrier"
				/>
			</div>
		),
		value: 'roamingCarrier'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="M2M_DATE_ADDED" defaultMessage="M2M Date Added" />
			</div>
		),
		value: 'm2mDateAdded'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage
					id="M2M_INITIAL_ACTIVATION_DATE"
					defaultMessage="M2M Activation Date"
				/>
			</div>
		),
		value: 'm2mInitialActivationDate'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage id="ENDPOINTS.TECH_TYPE" defaultMessage="Tech Type" />
			</div>
		),
		value: 'techType'
	},
	{
		label: (
			<div>
				<FormattedMessage id="BY" />
				<FormattedMessage
					id="ENDPOINTS.TECH_TYPE_SUB_TYPE"
					defaultMessage="Tech Type Subcategory"
				/>
			</div>
		),
		value: 'techtypeSubtype'
	},
	...customFieldsSearchMenu()
];

export const supportAdvancedSearch = ['endpointName', 'imsi', 'msisdn'];

export const validate = (values) => {
	const errors = {};

	Object.keys(values).forEach((key) => {
		if (key.startsWith('customLabel') && key !== 'messageId') {
			const number = key.substr('customLabel'.length);
			if (!values[key] && values[`isCustomField${number}Required`]) {
				errors[`customLabel${number}`] = 'Required';
			}
		}
	});

	return errors;
};

export const realPropName = {
	EndpointState: 'status',
	NetworkOperator: 'networkOperator',
	NetworkCountry: 'networkCountry',
	NetworkEntitlement: 'networkEntitlement',
	NetworkType: 'networkType',
	TransferStatus: 'transferStatus',
	Rateplan: 'ratePlan',
	RecredentialTransferStatus: 'recredentialTransferStatus',
	M2MPlatform: 'm2mPlatform',
	RoamingCountry: 'roamingCountry',
	RoamingCarrier: 'roamingCarrier',
	ServiceStatus: 'serviceStatus',
	OEM: 'oem',
	VIN: 'vin',
	M2MDateAdded: 'm2mDateAdded',
	m2mAccountId: 'm2mAccountId'
};

export const isValidDate = (date) =>
	(typeof date === 'string'
		? new Date(date) instanceof Date && !Number.isNaN(new Date(date))
		: date instanceof Date && !Number.isNaN(date));

export const getDateFormat = (date) => {
	date = new Date(date);
	const dateFormat = 'MM/dd/yyyy';
	return format(date, dateFormat);
};

export const batchFileUploadActions = {
	ACTIVE: (
		<FormattedMessage id="ACTIVATE_ACTION" defaultMessage="Activate SIMS" />
	),
	TERMINATE: (
		<FormattedMessage id="TERMINATE_ACTION" defaultMessage="Terminate SIMS" />
	),
	SIMSWAP: (
		<FormattedMessage id="SWAP_SIMS_ACTION" defaultMessage="Swap SIMS" />
	),
	NETWORK_ENTITLEMENT_CHANGE: (
		<FormattedMessage
			id="NETWORK_ENTITLEMENT_CHANGE_ACTION"
			defaultMessage="SIM Network Entitlement Change"
		/>
	),
	CUSTOM_FIELDS: (
		<FormattedMessage
			id="CUSTOM_FIELDS_ACTION"
			defaultMessage="Add or update custom fields"
		/>
	)
};

export const endpointsAdvancedSearchMenu = ['endpointName', 'imsi', 'msisdn'];
