/* eslint-disable no-unused-expressions */
import globalAction from '../../../../redux/global/globalAction';

import { GET_ADDONS, GET_ADDONS_FILTERS } from './constants';

import {
	getAddons as getAddonsService,
	getAddonsFilters as getAddonsFiltersService
} from '../../services/AddonsService';

/** ************ get addons ************* */
export const getAddons = (id, params = {}) =>
	globalAction(GET_ADDONS, getAddonsService, { id, params });

/** ************ get addons filters ************* */
export const getAddonsFilters = (id, params = {}) => {
	GET_ADDONS_FILTERS;
	return globalAction(GET_ADDONS_FILTERS, getAddonsFiltersService, {
		id,
		params
	});
};
