import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Table from '../../../../lib/DigitalComponents/Table';
import ImageSelector from '../../../../utils/imageSelector';
import CurrentContext from '../../../../utils/currentContext';
import AnalyticsModal from '../../../Analytics/components/AnalyticsModal';
import Loader from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import Button from '../../../../lib/DigitalComponents/Button';
import DeleteSettingModal from '../DeleteSettingModal';
import {
	isCycle,
	isHistoricalCycle
} from '../../../Shared/components/Charts/Helpers/helpers';
import NoDataView from '../../../Shared/views/NoDataView';

import styles from './AccountOverviewAdmin.scss';

const EditIcon = ImageSelector(CurrentContext.theme, 'svgs/pen.svg');
const DeleteIcon = ImageSelector(CurrentContext.theme, 'svgs/delete.svg');
const AddIcon = ImageSelector(CurrentContext.theme, 'svgs/add-bold.svg');
const definedSettingsKeyCheck = [
	'radioCategory',
	'groupBySelector',
	'groupByDate',
	'groupByIncrement',
	'groupByShow',
	'filterForm',
	'chartForm',
	'unit'
];

class AccountOverviewAdmin extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			openModal: false,
			openDeleteModal: false,
			editSetting: {},
			settingId: -1,
			settingName: ''
		};
	}

	componentDidMount() {
		const { getDefinedSettings, getDefinedSettingsByIdInit } = this.props;

		getDefinedSettings(true);
		getDefinedSettingsByIdInit();
	}

	componentDidUpdate(prevProps) {
		const {
			definedSettingById,
			definedSettings,
			deleteDefinedSetting,
			isDeleteDefinedSettingSuccess,
			isUpdateDefinedSettingsSuccess,
			getDefinedSettings
		} = this.props;

		if (
			!prevProps.definedSettings &&
			definedSettings &&
			definedSettings.length
		) {
			definedSettings.forEach((defined) => {
				definedSettingsKeyCheck.forEach((key) => {
					if (
						Object.keys(
							JSON.parse(defined.chartSettings.value).chartModalSettings
						).indexOf(key) === -1
					) {
						deleteDefinedSetting(defined.id);
					}
				});
			});
		}

		if (!prevProps.definedSettingById && definedSettingById) {
			const tempSettingObject = JSON.parse(
				definedSettingById.chartSettings.value
			);
			const editSetting = {
				...tempSettingObject.chartModalSettings,
				name: definedSettingById.name,
				description: definedSettingById.description
			};
			const tempSearchParam1 = tempSettingObject.chartQuery.searchParams.find(
				(item) => item.prop === 'full_date' && item.operator === '>='
			);
			const tempSearchParam2 = tempSettingObject.chartQuery.searchParams.find(
				(item) => item.prop === 'full_date' && item.operator === '<='
			);

			if (tempSearchParam1) {
				editSetting.dateFrom = tempSearchParam1.propValue;
			}

			if (tempSearchParam2) {
				editSetting.dateTo = tempSearchParam2.propValue;
			}

			this.setEditItemAndOpenModal(editSetting);
		}

		if (
			(!prevProps.isDeleteDefinedSettingSuccess &&
				isDeleteDefinedSettingSuccess) ||
			(!prevProps.isUpdateDefinedSettingsSuccess &&
				isUpdateDefinedSettingsSuccess)
		) {
			getDefinedSettings();
		}
	}

	setEditItemAndOpenModal = (editSetting) =>
		this.setState({
			editSetting,
			openModal: true
		});

	openModal = (settingId, getDefinedSettingsById) =>
		(settingId
			? getDefinedSettingsById(settingId)
			: this.setState({ openModal: true }));

	openDeleteModal = (setting) =>
		this.setState({
			openDeleteModal: true,
			settingId: setting.id,
			settingName: setting.name
		});

	closeModal = () => {
		const { getDefinedSettingsByIdInit } = this.props;

		getDefinedSettingsByIdInit();
		this.setState({ openModal: false, editSetting: {} });
	};

	closeDeleteModal = () =>
		this.setState({
			openDeleteModal: false,
			settingId: -1,
			settingName: ''
		});

	createObjectSettings = (gridItem) => {
		const TODAY = 0;
		const TEN_DAYS = 864000;
		const THIRTY_DAYS = 2592000;
		const dateRanges = [
			{ propValue: TODAY, prop: 'full_date', operator: 'date_gte_today' },
			{ propValue: TEN_DAYS, prop: 'full_date', operator: 'date_gte_sec' },
			{ propValue: THIRTY_DAYS, prop: 'full_date', operator: 'date_gte_sec' }
		];
		let additionalParams = {
			columnsToSelect: `${gridItem.groupByColumn},${gridItem.incrementBy}`,
			dataSort: gridItem.incrementBy
				.split(',')
				.map((item) => `${item} asc`)
				.join(',')
		};
		const searchParams = [];

		if (gridItem.filters) {
			Object.keys(gridItem.filters).forEach((column) => {
				const tempPropValueIn = [];

				Object.keys(gridItem.filters[column]).forEach((filter) => {
					if (gridItem.filters[column][filter]) {
						tempPropValueIn.push(
							typeof filter === 'boolean'
								? gridItem.filters[column][filter]
								: filter
						);
					}
				});

				if (
					tempPropValueIn.length !==
					Object.keys(gridItem.filters[column]).length
				) {
					searchParams.push({
						propValue: tempPropValueIn,
						prop: column,
						operator: 'in'
					});
				}
			});
		}

		if (isHistoricalCycle(gridItem.groupByDateId)) {
			additionalParams = {
				columnsToSelect: `${gridItem.groupByColumn},billcycle`,
				dataSort: 'billcycle asc'
			};
		}

		const aggregationParams = gridItem.aggregationFunction !== 'MVA'
			? [
				{
					function: `${gridItem.aggregationFunction}`,
					column: 'value',
					as: 'value'
				}
			]
			: [
				{
					function: 'SUM',
					column: 'value',
					as: 'value'
				},
				{
					function: 'SUM',
					column: 'value_2',
					as: 'value_2'
				}
			];
		let dateFrom = '';
		let dateTo = '';

		searchParams.push({
			propValue: gridItem.categoryId,
			prop: 'categoryid',
			operator: '='
		});

		if (parseInt(gridItem.groupByDateId, 10) < 3) {
			searchParams.push(dateRanges[gridItem.groupByDateId]);
		} else if (gridItem.groupByDateId === '3') {
			const itemFrom = new Date(gridItem.dateFrom);
			const itemTo = new Date(gridItem.dateTo);

			dateFrom = new Date(
				Date.UTC(
					itemFrom.getFullYear(),
					itemFrom.getMonth(),
					itemFrom.getDate(),
					0,
					0,
					0,
					0
				)
			).toISOString();
			dateTo = new Date(
				Date.UTC(
					itemTo.getFullYear(),
					itemTo.getMonth(),
					itemTo.getDate(),
					23,
					59,
					59,
					999
				)
			).toISOString();

			searchParams.push({
				propValue: dateFrom,
				prop: 'full_date',
				operator: '>='
			});
			searchParams.push({
				propValue: dateTo,
				prop: 'full_date',
				operator: '<='
			});
		} else if (gridItem.groupByDateId === '4') {
			additionalParams.currentCycle = true;
			searchParams.push({
				propValue: [Object.keys(gridItem.filters.platform)[0]],
				prop: 'platform',
				operator: 'in'
			});
		} else if (isHistoricalCycle(gridItem.groupByDateId)) {
			searchParams.push({
				propValue: [Object.keys(gridItem.filters.platform)[0]],
				prop: 'platform',
				operator: 'in'
			});
		} else if (gridItem.groupByDateId === '6') {
			searchParams.push({
				propValue: -1,
				prop: 'full_date',
				operator: 'date_gte_today'
			});
		}

		return {
			chartQuery: { additionalParams, aggregationParams, searchParams },
			chartModalSettings: {
				radioCategory: gridItem.radioCategory,
				groupBySelector: gridItem.groupByColumn,
				groupByDate: gridItem.groupByDateId,
				groupByIncrement: gridItem.incrementById,
				groupByShow: gridItem.aggregationFunctionId,
				filterForm: gridItem.editFilters,
				chartForm: gridItem.type,
				unit: gridItem.units,
				showSummary: gridItem.showSummary,
			}
		};
	};

	getAnalyticsChartData = (gridItem, getChartData) => {
		const tempQuery = this.createObjectSettings(gridItem).chartQuery;

		if (!isCycle(gridItem.groupByDateId)) {
			tempQuery.additionalParams.timeZoneOffset = new Date().getTimezoneOffset();
		}

		getChartData(gridItem.i, tempQuery);
	};

	updateDefinedSettings = (
		gridItem,
		updateDefinedSettings,
		definedSettingById,
		getDefinedSettings,
		getDefinedSettingsByIdInit
	) => {
		updateDefinedSettings(
			{
				name: gridItem.name,
				description: gridItem.description,
				chartSettings: {
					...this.createObjectSettings(gridItem)
				}
			},
			definedSettingById ? definedSettingById.id : ''
		);
		getDefinedSettingsByIdInit();
		this.setState({ editSetting: {} });
	};

	renderActions = (setting, getDefinedSettingsById) => (
		<div data-spec="account-overview-admin-actions">
			<EditIcon
				data-spec={`edit-setting-${setting.name}`}
				onClick={() => {
					this.openModal(setting.id, getDefinedSettingsById);
				}}
				className={styles.addIconPlus}
			/>
			<DeleteIcon
				data-spec={`delete-setting-${setting.name}`}
				onClick={() => this.openDeleteModal(setting)}
				className={styles.deleteIcon}
			/>
		</div>
	);

	renderTable = () => {
		const {
			getDefinedSettingsById,
			messages,
			definedSettings,
			pendingData,
			isDeleteDefinedSettingPending,
			isUpdateDefinedSettingsPending,
		} = this.props;
		const options = {
			header: [
				{
					title: <FormattedMessage
						id="ACCOUNT_OVERVIEW.CHART_SETTING_NAME"
						defaultMessage="Chart Setting Name"
					/>,
					name: 'name',
					checked: true
				},
				{
					title: <FormattedMessage
						id="ACCOUNT_OVERVIEW.CHART_DESCRIPTION"
						defaultMessage="Chart Descripion"
					/>,
					name: 'description',
					checked: true
				},
				{
					title: <FormattedMessage
						id="ACCOUNT_OVERVIEW.ACTIONS"
						defaultMessage="Actions"
					/>,
					name: 'actions',
					checked: true
				}
			],
			tableOptions: {},
			customComponents: {
				actions: {
					type: 'custom',
					component: (setting) =>
						this.renderActions(setting, getDefinedSettingsById)
				}
			}
		};

		if (
			pendingData ||
			isDeleteDefinedSettingPending ||
			isUpdateDefinedSettingsPending
		) {
			return <Loader data-spec="account-overview-admin-loader" />;
		}

		if (!definedSettings || (definedSettings && definedSettings.length === 0)) {
			return <NoDataView data-spec="account-overview-no-data-table" table />;
		}

		return (
			<Table
				data-spec="account-overview-admin-table"
				data={definedSettings}
				options={options}
				messages={messages}
			/>
		);
	}

	render() {
		const {
			messages,
			pushBack,
			chartData,
			isGetChartDataPending,
			isDefinedSettingByIdPending,
			deleteDefinedSetting,
			getDefinedSettingsById,
			getChartData,
			updateDefinedSettings,
			definedSettingById,
			getDefinedSettings,
			getDefinedSettingsByIdInit
		} = this.props;
		const {
			openModal,
			editSetting,
			openDeleteModal,
			settingId,
			settingName
		} = this.state;

		return (
			<div data-spec="account-overview-admin">
				<PageTitle
					title={
						<FormattedMessage
							id="ACCOUNT_OVERVIEW_ADMIN_DASHBOARD"
							defaultMessage="Account Overview Admin Dashboard"
						/>
					}
					actions={
						<Button
							dataSpec="add-graph-setting-button"
							onClick={() => this.openModal(null, getDefinedSettingsById)}
							variant="primary"
							label={<FormattedMessage
								id="ACCOUNT_OVERVIEW.ADD_GRAPH"
								defaultMessage="Add New Graph Setting"
							/>}
							labelIcon={<AddIcon className={styles.addIconPlus} />}
						/>
					}
					pushBack={() => pushBack()}
				/>
				<div className={styles.table_wrapper}>
					{this.renderTable()}
				</div>
				<DeleteSettingModal
					show={openDeleteModal}
					onClick={(id) => {
						deleteDefinedSetting(id);
						getDefinedSettingsByIdInit();
					}}
					onCancel={this.closeDeleteModal}
					settingId={settingId}
					settingName={settingName}
				/>
				<AnalyticsModal
					show={openModal}
					messages={messages}
					onClose={this.closeModal}
					addChart={(gridItem) =>
						this.updateDefinedSettings(
							gridItem,
							updateDefinedSettings,
							definedSettingById,
							getDefinedSettings,
							getDefinedSettingsByIdInit
						)}
					editItem={editSetting}
					getModalChartData={(gridItem) =>
						this.getAnalyticsChartData(gridItem, getChartData)}
					chartData={chartData}
					isGetChartDataPending={(id) =>
						isGetChartDataPending(id) || isDefinedSettingByIdPending}
					isAccountOverviewSuperAdminModal
				/>
			</div>
		);
	}
}

const {
	object,
	func,
	arrayOf,
	objectOf,
	bool,
	string,
	oneOfType,
	number
} = PropTypes;

AccountOverviewAdmin.propTypes = {
	messages: objectOf(string),
	definedSettings: arrayOf(object),
	pendingData: bool,
	getDefinedSettings: func,
	pushBack: func,
	chartData: func,
	isGetChartDataPending: func,
	updateDefinedSettings: func,
	getChartData: func,
	isDefinedSettingByIdPending: bool,
	getDefinedSettingsById: func,
	definedSettingById: objectOf(oneOfType([string, number, object])),
	deleteDefinedSetting: func,
	isDeleteDefinedSettingPending: bool,
	isUpdateDefinedSettingsPending: bool,
	getDefinedSettingsByIdInit: func,
	isDeleteDefinedSettingSuccess: bool,
	isUpdateDefinedSettingsSuccess: bool
};

AccountOverviewAdmin.defaultProps = {
	messages: {},
	definedSettings: [],
	pendingData: false,
	isDeleteDefinedSettingPending: false,
	isUpdateDefinedSettingsPending: false,
	definedSettingById: {},
	isDefinedSettingByIdPending: false,
	isDeleteDefinedSettingSuccess: false,
	isUpdateDefinedSettingsSuccess: false,
	getDefinedSettingsByIdInit: undefined,
	getDefinedSettingsById: undefined,
	deleteDefinedSetting: undefined,
	getDefinedSettings: undefined,
	pushBack: undefined,
	chartData: undefined,
	isGetChartDataPending: undefined,
	updateDefinedSettings: undefined,
	getChartData: undefined
};

export default AccountOverviewAdmin;
