export const ACTIVATE_COMPANY = 'ACTIVATE_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_ADDRESS_TYPES = 'GET_ADDRESS_TYPES';
export const GET_CONTACT_TYPES = 'GET_CONTACT_TYPES';
export const GET_MY_COMPANY = 'GET_MY_COMPANY';
export const EDIT_MY_COMPANY = 'EDIT_MY_COMPANY';
export const GET_COMPANY_LANGUAGES = 'GET_COMPANY_LANGUAGES';
export const GET_COMPANY_TOKEN = 'GET_COMPANY_TOKEN';
export const CHANGE_INITAL_COMPANY_TAB = 'CHANGE_INITAL_COMPANY_TAB';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';
