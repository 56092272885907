import React from 'react';
import {
	shape,
	string,
	arrayOf,
	bool,
	object
} from 'prop-types';
import {
	FormattedMessage,
	injectIntl,
} from 'react-intl';

import Loader from '../../../../lib/DigitalComponents/Loader';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import { objectPropertiesValidation } from '../../../../utils/helperFunctions';

import styles from './DiagnosticDetailsWing.scss';

function DiagnosticsDetailsWing({
	data,
	isFetching,
	carrier,
	fields,
	carrierName,
	intl,
}) {
	const getWingData = () => ({
		simState: objectPropertiesValidation(data, 'attributes.simState')
			? data.attributes.simState
			: '',
		permitPassingTraffic: objectPropertiesValidation(
			data,
			'attributes.provisioningInfo.data.attributes.permitPassingTraffic'
		)
			? `${
				data.attributes.provisioningInfo.data.attributes
					.permitPassingTraffic
					? 'permitpassingtraffic'
					: 'notpermitpassingtraffic'
			}`
			: '',
		successfullyConnectedToNetwork_inSession: objectPropertiesValidation(
			data,
			'attributes.networkInfo.data.attributes.successfullyConnectedToNetwork_inSession'
		)
			? `${
				data.attributes.networkInfo.data.attributes
					.successfullyConnectedToNetwork_inSession
					? 'successfullyconnectedtonetwork_insession'
					: 'notsuccessfullyconnectedtonetwork_insession'
			}`
			: '',
		dataSessionCurrentlySuccessful: objectPropertiesValidation(
			data,
			'attributes.ipInternetInfo.data.attributes.dataSessionCurrentlySuccessful'
		)
			? `${
				data.attributes.ipInternetInfo.data.attributes
					.dataSessionCurrentlySuccessful
					? 'datasessioncurrentlysuccessful'
					: 'notdatasessioncurrentlysuccessful'
			}`
			: ''
	});
	const getValue = (value) => intl.formatMessage({
		id: `SINGLE_ENDPOINT.WING_DIAGNOSTIC_DETAIL_VALUE_${value.toLowerCase()}`,
		defaultMessage: value,
	});
	const getSubFieldStatus = (field) => {
		const wingData = getWingData(data);
		const { id, successMessages, errorMessages } = field;

		if (!wingData[id]) {
			return 'disabled';
		}
		if (successMessages.indexOf(wingData[id].toLowerCase()) !== -1) {
			return 'success';
		}
		if (errorMessages.indexOf(wingData[id].toLowerCase()) !== -1) {
			return 'error';
		}
		return 'disabled';
	};
	const getFieldStatus = (statusArray) => {
		let iconStatus = 'success';
		let fieldStatus = 'success';

		if (statusArray.indexOf('error') !== -1) {
			iconStatus = 'error';
			fieldStatus = 'error';
		} else if (statusArray.filter((item) => item !== 'disabled').length === 0) {
			iconStatus = 'disabled';
			fieldStatus = 'disabled';
		} else if (statusArray.indexOf('disabled') !== -1) {
			iconStatus = 'disabled';
		}
		return [iconStatus, fieldStatus];
	};
	const parseFields = () => {
		const wingData = getWingData(data);
		return fields.reduce((modFields, field) => {
			const fieldsStatuses = [];
			const tempFieldData = field.data.map((item) => {
				const itemStatus = getSubFieldStatus(item);
				fieldsStatuses.push(itemStatus);
				return {
					id: item.id,
					defaultLabel: item.defaultLabel,
					value: wingData[item.id] ? getValue(wingData[item.id]) : '',
					status: itemStatus
				};
			});
			const [iconStatus, fieldStatus] = getFieldStatus(fieldsStatuses);
			modFields.push({
				...field,
				data: tempFieldData,
				iconStatus,
				fieldStatus
			});
			return modFields;
		}, []);
	};

	if (isFetching) {
		return (
			<div
				data-spec="registration-details-is-fetching"
				className={styles.diagnostics_details}
			>
				<Loader data-spec="diagnostics-details-loader" />
			</div>
		);
	}

	const parsedFields = parseFields(fields);
	const fieldStatus = carrierName ? 'success' : 'disabled';
	const Icon = ImageSelector(
		CurrentContext.theme,
		`svgs/${carrier.icon}.svg`
	);
	const renderIconStatus = (icon, iconStatus) => {
		const IconStatus = ImageSelector(
			CurrentContext.theme,
			`svgs/${icon}${iconStatus === 'error' ? `_${iconStatus}` : ''}.svg`
		);

		return <IconStatus data-spec="diagnostic-details-wing-icon-status" />;
	};

	return (
		<div
			data-spec="diagnostics-details"
			className={styles.diagnostics_details}
		>
			{Object.keys(getWingData(data)).length > 0 && (
				<>
					<div className={styles.dd_title}>
						<div
							data-spec={`dd-field-${carrier.id}`}
							className={`${styles.dd_field} ${styles[fieldStatus]}`}
						>
							<div className={styles.dd_title}>
								<div className={styles.dd_title_left} />
								<div
									className={`${styles.dd_title_right} ${styles[fieldStatus]}`}
								>
									<FormattedMessage
										id={carrier.id}
										defaultMessage={carrier.defaultLabel}
									/>
								</div>
							</div>
							<div className={styles.dd_content}>
								<div
									className={`${styles.dd_content_left} ${styles[fieldStatus]}`}
								>
									<Icon />
								</div>
								<div className={styles.dd_content_right}>
									{carrier.data.map((item) => (
										<div
											data-spec={`dd-field-item-${item.id}`}
											className={`${styles.dd_field_item} ${styles[fieldStatus]}`}
											key={item.id}
										>
											<div
												className={`${styles.dd_item_title} ${styles[fieldStatus]}`}
											>
												{item.id && <FormattedMessage
													id={item.id}
													defaultMessage={item.defaultLabel}
												/>}
											</div>
											<div
												className={`${styles.dd_item_content} ${styles[fieldStatus]}`}
											>
												{carrierName || '-'}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.dd_fields}>
						{parsedFields.map((item) => (
							<div
								key={item.id}
								data-spec={`dd-field-${item.id}`}
								className={`${styles.dd_field} ${styles[fieldStatus]}`}
							>
								<div className={styles.dd_title}>
									<div
										className={`${styles.dd_title_left} ${styles[item.iconStatus]}`}
									>
										<div className={styles[item.iconStatus]} />
										<div className={styles[item.iconStatus]} />
										<div className={styles[item.iconStatus]} />
									</div>
									<div
										className={`${styles.dd_title_right} ${styles[fieldStatus]}`}
									>
										<FormattedMessage
											id={item.id}
											defaultMessage={item.defaultLabel}
										/>
									</div>
								</div>
								<div className={styles.dd_content}>
									<div
										className={`${styles.dd_content_left} ${styles[item.iconStatus]}`}
									>
										{renderIconStatus(item.icon, item.iconStatus)}
									</div>
									<div className={styles.dd_content_right}>
										{item.data.map((field) => (
											<div
												data-spec={`dd-field-item-${field.id}`}
												className={`${styles.dd_field_item} ${styles[field.status]}`}
												key={field.id}
											>
												<div
													className={`${styles.dd_item_title} ${styles[field.status]}`}
												>
													{field.id && <FormattedMessage
														id={field.id}
														defaultMessage={field.defaultLabel}
													/>}
												</div>
												<div
													className={`${styles.dd_item_content} ${styles[field.status]}`}
												>
													{field.value || '-'}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}

DiagnosticsDetailsWing.propTypes = {
	data: shape({
		id: string
	}).isRequired,
	fields: arrayOf(object).isRequired,
	isFetching: bool,
	carrier: object,
	carrierName: string,
	intl: object,
};
DiagnosticsDetailsWing.defaultProps = {
	isFetching: true,
	carrier: {},
	carrierName: '',
	intl: {},
};

export default injectIntl(DiagnosticsDetailsWing);
