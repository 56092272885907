import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { SuperUserTopBarMobile } from '../../../components/Shared/components/SuperUserTopBar';
import UserFeedbackWrapper from '../../../components/UserFeedback/views/UserFeedbackWrapperView';
import { DesktopFooter } from '../../../lib/DigitalComponents/Footer';
import TopBar from '../../../lib/DigitalComponents/TopBar';
import CurrentContext from '../../../utils/currentContext';
import ImageSelector from '../../../utils/imageSelector';
import hoc from '../MainLayout';

import styles from './Tablet.scss';

const Logo = ImageSelector(CurrentContext.theme, 'svgs/logo.svg');
const UserPlaceholder = ImageSelector(CurrentContext.theme, 'svgs/user.svg');
const LinkIcon = ImageSelector(CurrentContext.theme, 'svgs/link.svg');

const Tablet = ({
	clientMenu,
	user,
	profileMenu,
	supportedLanguages,
	selectedLanguage,
	companyFriendlyName,
	companyAccountId,
	location,
	children,
	showContentAboveTopBar,
	links,
	intl,
	resetFilterPaginationSearchData,
	userInitials,
}) => (
	<div data-spec="desktop">
		<TopBar
			mainMenu={clientMenu
				.filter((item) => !item.notOnTablet)
				.map((item) => {
					if (item.submenu) {
						item.submenu = item.submenu.filter(
							(subItem) => !subItem.notOnTablet
						);
					}

					return item;
				})}
			logo={
				<NavLink to="/">
					<Logo className={styles.logo} data-spec="homepage-logo" />
				</NavLink>
			}
			appName={<FormattedMessage id="APP_NAME" defaultMessage="IoT Console" />}
			userPlaceholder={
				<div className={styles.profile_avatar}>
					<UserPlaceholder />
				</div>
			}
			userName={userInitials}
			profileMenu={profileMenu}
			supportedLanguages={supportedLanguages}
			selectedLanguage={selectedLanguage}
			showContentAboveTopBar={showContentAboveTopBar}
			contentAboveTopBar={
				<SuperUserTopBarMobile
					companyFriendlyName={companyFriendlyName}
					companyAccountId={companyAccountId}
					user={user}
				/>
			}
			resetFilterPaginationSearchData={resetFilterPaginationSearchData}
		/>
		<div
			className={cn({
				[styles.global_container]: true,
				[styles.additional_offset]: showContentAboveTopBar,
			})}
		>
			<div className={cn(styles.main_right)}>
				{children}
				<UserFeedbackWrapper location={location} menu={clientMenu} />
			</div>
		</div>
		<DesktopFooter
			logo={<Logo />}
			externalLink={<LinkIcon className={styles.link_icon} />}
			links={links}
			copyrightLink={intl.formatMessage(
				{
					id: 'COPYRIGHT_ATT_INTELECTUAL_PROPERTY',
					defaultMessage: '© {year} AT&T Intellectual Property. ',
				},
				{
					year: new Date().getFullYear(),
				}
			)}
			copyrightMessage={intl.formatMessage({
				id: 'COPYRIGHT_MESSAGE',
				defaultMessage:
					'All rights reserved. AT&T, the AT&T, Globe logo, Mobilizing Your World and DIRECTV are registered trademarks of AT&T intellectual Property and/or AT&T affiliated companies. All other marks are the property of their respective owners.',
			})}
		/>
	</div>
);

const {
	string,
	objectOf,
	object,
	bool,
	arrayOf,
	element,
	array,
  oneOfType,
  func
} = PropTypes;

Tablet.propTypes = {
	clientMenu: arrayOf(object),
	user: oneOfType([array, object, string]),
	profileMenu: arrayOf(object),
	supportedLanguages: arrayOf(object),
	selectedLanguage: object,
	companyFriendlyName: string,
	companyAccountId: string,
	location: objectOf(string),
	children: element,
	showContentAboveTopBar: bool,
	intl: object,
	links: arrayOf(object),
	resetFilterPaginationSearchData: func,
	userInitials: string,
};

Tablet.defaultProps = {
	clientMenu: [],
	user: {},
	profileMenu: [],
	companyFriendlyName: '',
	companyAccountId: '',
	location: {},
	children: null,
	showContentAboveTopBar: false,
	intl: {},
	links: [],
};

export default hoc()(Tablet);
