import { connect } from 'react-redux';

import DesktopView from './Desktop';
import MobileView from './Mobile';

import {
	getAnalyticsReportsRequest,
	getCustomerReportsRequest
} from '../../redux/reports/selectors';
import { downloadReportRequest } from '../../redux/downloadReport/selectors';
import { getUser } from '../../../../redux/user/getUserData/selectors';

import {
	getAnalyticsReportsInit,
	getCustomerReportsInit
} from '../../redux/reports/actions';
import { setDataForFiltersInit } from '../../../Shared/redux/filterModal/actions';

import { hideNotification } from '../../../Shared/redux/notifications/actions';

const mapStateToProps = (state) => ({
	reportsRequest:
		getAnalyticsReportsRequest(state) || getCustomerReportsRequest(state),
	downloadReportRequest: downloadReportRequest(state),
	user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
	getAnalyticsReportsInit: () => dispatch(getAnalyticsReportsInit()),
	getCustomerReportsInit: () => dispatch(getCustomerReportsInit()),
  setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
  hideNotification: (id) => dispatch(hideNotification(id))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(DesktopView);
export const Mobile = connect(mapStateToProps, mapDispatchToProps)(MobileView);
