import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Carousel from 're-carousel';
import cn from 'classnames';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
// import { find } from 'lodash';
// import { push } from 'react-router-redux';

import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Loader from '../../../../lib/DigitalComponents/Loader';
import {
	BarChart,
	LineChart,
	MUSTotal,
	PieChart,
	WorldMapChart
} from '../Charts';
// import { settingFiltersDone, settingFiltersRequest } from '../../redux/dashboard/actions';
// import { getUserSettings, patchEndpointsListView } from '../../services/EndpointService';
// import { EndpointsTableTabs as tableHeader } from '../../utils/constants';
// import store from "redux/store";

import styles from './WrapperGridCard.scss';

const Dots = ImageSelector(CurrentContext.theme, 'svgs/more-applications.svg');
const Reset = ImageSelector(CurrentContext.theme, 'svgs/reset.svg');
const DragIcon = ImageSelector(CurrentContext.theme, 'svgs/move-control.svg');

class WrapperGridCard extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { active: false };
	}

	componentDidMount() {
		const { getContent, gridItem, pendingData } = this.props;

		!pendingData && getContent(gridItem);
		document.addEventListener('click', this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClick, false);
	}

	handleClick = (e) => {
		if (this.node && !this.node.contains(e.target)) {
			this.setState({ active: false });
		}
	};

	handleDotsClick = (active) => this.setState({ active: !active });

	getContentData = (itemSetting, contentData, isAnalytics) =>
		(isAnalytics ? contentData(itemSetting.i) : contentData(itemSetting.id));

	getAsOfDate = (itemSetting, asOfDate) => {
		if (asOfDate && asOfDate(itemSetting.id)) {
			const formattedDate = asOfDate(itemSetting.id)
				.split('T')[0]
				.split('-');

			return new Date(
				formattedDate[0],
				parseInt(formattedDate[1], 10) - 1,
				formattedDate[2]
			);
		}

		return null;
	};

	// This needs to be determined if its going to be used, if this was or is a requirement
	// onChartClick = async data => {
	//   const {
	//     data: { chartName, id }
	//   } = data;
	//   const filterByCountry = [
	//     'DASHBOARD_CHART_countByCountry_CELLULAR',
	//     'DASHBOARD_CHART_countByCountry_CELLULAR',
	//     'world_map_charts',
	//   ];
	//   if (filterByCountry.indexOf(chartName) !== -1) {
	//     store.dispatch(settingFiltersRequest())
	//     const userSettings = await getUserSettings(1);
	//     if (userSettings && (
	//       typeof userSettings !== 'string'
	//     )) {
	//       const tab = find(userSettings, { name: "tab" });
	//       const value = await JSON.parse(tab.value);
	//       const filters = value.data[0].filters;
	//       if (filters) {
	//         if (filters.countries && filters.countries.indexOf(id) !== -1) {
	//           await store.dispatch(settingFiltersDone())
	//           store.dispatch(localizedRoute("/endpoints"));
	//         } else {
	//           this.saveNewFilter({ userSettings, id, type: 'HAS_FILTERS' });
	//         }
	//       } else {
	//         this.saveNewFilter({ userSettings, id, type: 'HAS_FILTERS' });
	//       }
	//     } else {
	//       this.saveNewFilter({ userSettings, id, type: 'NO_USER_SETTNGS' });
	//     }
	//   }
	// };
	//
	// saveNewFilter = async data => {
	//   const { userSettings, id, type } = data;
	//   switch (type) {
	//     case 'NO_USER_SETTNGS': {
	//       const oldValues = [];
	//       const newValues = [
	//         {
	//           name: 'tab',
	//           refTypeId: 1,
	//           value: JSON.stringify({
	//             data: [
	//               {
	//                 tabName: tableHeader[0].value.data[0].tabName,
	//                 value: 0,
	//                 filters: { countries: [id] },
	//               },
	//               ...tableHeader[0].value.data.filter(item => item.checked)
	//                 .map((item, index) => (
	//                   {
	//                     title: item.title,
	//                     name: item.name,
	//                     value: index + 1
	//                   }
	//                 ))
	//             ]
	//           })
	//         }
	//       ];
	//       await patchEndpointsListView(newValues, oldValues);
	//       await store.dispatch(settingFiltersDone());
	//       store.dispatch(localizedRoute('/endpoints'));
	//     }
	//       break;
	//     case 'HAS_FILTERS': {
	//       const tab = userSettings[0];
	//       tab.value = await JSON.parse(tab.value);
	//       tab.value.data[0].filters = {
	//         ...tab.value.data[0].filters,
	//         countries: [...(
	//           tab.value.data[0].filters ? (
	//             tab.value.data[0].filters.countries || []
	//           ) : []
	//         ), id
	//         ],
	//       };
	//       tab.value = await JSON.stringify(tab.value);
	//       const oldValues = [
	//         {
	//           name: tab.name,
	//           refTypeId: 1,
	//         }
	//       ];
	//       const newValues = [
	//         {
	//           name: tab.name,
	//           refTypeId: 1,
	//           value: tab.value,
	//         },
	//       ];
	//       await patchEndpointsListView(newValues, oldValues);
	//       await store.dispatch(settingFiltersDone());
	//       store.dispatch(localizedRoute('/endpoints'));
	//     }
	//   }
	// };

	renderIndicatorDots = (index) => (
		<div
			data-spec="multiple-dot-wrapper"
			className={styles.indicator_dots_wrapper}
			data-html2canvas-ignore
		>
			<span
				className={
					index === 0
						? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
						: styles.indicator_dot
				}
			/>
			<span
				className={
					index === 1
						? `${styles.indicator_dot} ${styles.indicator_dot_selected}`
						: styles.indicator_dot
				}
			/>
		</div>
	);

	renderChart = (
		itemSetting,
		modal,
		isAnalytics,
		isTicketingCharts,
		contentData,
		dataGridSize,
	) => {
		switch (itemSetting.type) {
			case 'line':
				return (
					<LineChart
						data-spec={`line-chart-${itemSetting.name}`}
						chartData={
							this.getContentData(itemSetting, contentData, isAnalytics) || []
						}
						isAnalytics={isAnalytics}
						gridItem={itemSetting}
						isWide={itemSetting.w > 1}
						dataGridSize={dataGridSize}
						// onChartClick={this.onChartClick}
					/>
				);
			case 'bar':
				return (
					<BarChart
						data-spec={`bar-chart-${itemSetting.name}`}
						chartData={
							this.getContentData(itemSetting, contentData, isAnalytics) || []
						}
						gridItem={itemSetting}
						isAnalytics={isAnalytics}
						isWide={itemSetting.w > 1}
						isTicketingCharts={isTicketingCharts}
						dataGridSize={dataGridSize}
						// onChartClick={this.onChartClick}
					/>
				);
			case 'pie':
				return (
					<PieChart
						data-spec={`pie-chart-${itemSetting.name}`}
						chartData={
							this.getContentData(itemSetting, contentData, isAnalytics) || []
						}
						isAnalytics={isAnalytics}
						isModal={modal}
						gridItem={itemSetting}
						isWide={itemSetting.w > 1}
						isTicketingCharts={isTicketingCharts}
						// onChartClick={this.onChartClick}
					/>
				);
			case 'map':
				return (
					<WorldMapChart
						data-spec={`map-chart-${itemSetting.name}`}
						chartData={
							this.getContentData(itemSetting, contentData, isAnalytics) || []
						}
						isAnalytics={isAnalytics}
						gridItem={itemSetting}
						isWide={itemSetting.w > 1}
						hasLegend
						unit={itemSetting.units}
						// onChartClick={this.onChartClick}
					/>
				);
			case 'count':
				return (
					<MUSTotal
						data-spec={`count-chart-${itemSetting.name}`}
						chartData={
							this.getContentData(itemSetting, contentData, isAnalytics) || []
						}
						unit={itemSetting.units}
					/>
				);
			default:
				return null;
		}
	};

	render() {
		const {
			widthSize,
			gridItem,
			hasDropdown,
			disabled,
			dropdownItem,
			hasCarousel,
			children,
			pendingData,
			getContent,
			hasRefresh,
			isModal,
			isAnalytics,
			isTicketingCharts,
			contentData,
			asOfDate,
			dataGridSize,
		} = this.props;
		const { active } = this.state;
		const navigationClass = cn({
			[styles.navigation]: true,
			[styles.disabled]: disabled
		});
		const dropmenuWrapperClass = cn({
			[styles.form__active]: active,
			[styles.form__wrapper]: true
		});
		const modalClass = cn({
			[styles.wrapper_modal_card]: isModal && isAnalytics,
			[styles.wrapper_modal_card_account_overview]: isModal && !isAnalytics,
			[styles.wrapper_grid_card]: true
		});
		const asOfDateCheck = !isAnalytics
			? this.getAsOfDate(gridItem, asOfDate)
			: null;

		if (pendingData) {
			return (
				<Loader
					data-spec="wrapper-grid-card-loader"
					className={isModal ? styles.modal_loader : styles.loader}
				/>
			);
		}

		return (
			<div
				data-spec="wrapper-grid-card"
				className={modalClass}
				ref={(c) => {
					this[gridItem.i] = c;
				}}
			>
				{!isModal && (
					<>
						<header className={styles.header}>
							<div
								className={styles.title}
								data-spec={`title-card-${gridItem.name}`}
							>
								{gridItem.name}
							</div>
							<div className={styles.icons_wrapper} data-html2canvas-ignore>
								{hasRefresh ? (
									<div className={styles.navigation}>
										<Reset
											data-spec={`refresh-${gridItem.name}`}
											onClick={() => {
												getContent(gridItem);
											}}
										/>
									</div>
								) : null}
								{hasDropdown ? (
									<div
										className={navigationClass}
										ref={(node) => {
											this.node = node;
										}}
										data-html2canvas-ignore
									>
										<Dots
											data-spec={`open-menu-${gridItem.name}`}
											onClick={() => this.handleDotsClick(active)}
										/>
									</div>
								) : null}
								{active ? (
									<div
										className={dropmenuWrapperClass}
										data-spec={`card-dropdown-${gridItem.name}`}
										data-html2canvas-ignore
									>
										{dropdownItem(gridItem, this[gridItem.i])}
									</div>
								) : null}
							</div>
						</header>
						<strong
							className={`${styles.iconWrapper} draggable`}
							data-html2canvas-ignore
						>
							<div className={styles.dragIcon}>
								<DragIcon />
							</div>
						</strong>
					</>
				)}
				{widthSize === 1 && hasCarousel && children ? (
					<div className={styles.carousel_frames}>
						<Carousel widgets={[this.renderIndicatorDots]}>
							{this.renderChart(
								gridItem,
								isModal,
								isAnalytics,
								isTicketingCharts,
								contentData,
								dataGridSize,
							)}
							{children}
						</Carousel>
					</div>
				) : (
					<div
						data-spec={`wider-grid-card-${gridItem.name}`}
						className={
							isAnalytics
								? styles.wider_card
								: `${styles.wider_card} ${styles.account_overview}`
						}
					>
						{this.renderChart(
							gridItem,
							isModal,
							isAnalytics,
							isTicketingCharts,
							contentData,
							dataGridSize,
						)}
						{children}
					</div>
				)}
				{!isAnalytics && asOfDateCheck ? (
					<footer className={isModal ? styles.footer_margin : ''}>
						<div className={styles.as_of}>
							<span className={styles.uppercase}>
								<FormattedMessage id="AS_OF" defaultMessage="As Of" />
							</span>
							<span>{format(asOfDateCheck, 'MM/dd/y')}</span>
						</div>
					</footer>
				) : null}
			</div>
		);
	}
}

const {
	number,
	objectOf,
	object,
	bool,
	func,
	oneOfType,
	array,
	string
} = PropTypes;

WrapperGridCard.propTypes = {
	widthSize: number,
	gridItem: objectOf(oneOfType([string, number, bool, object])),
	hasDropdown: bool,
	disabled: bool,
	hasCarousel: bool,
	children: oneOfType([object, array]),
	getContent: func,
	dropdownItem: func,
	pendingData: oneOfType([bool, number]),
	hasRefresh: bool,
	isModal: bool,
	contentData: func,
	isAnalytics: bool,
	asOfDate: func,
	isTicketingCharts: bool,
	dataGridSize: string,
};

WrapperGridCard.defaultProps = {
	widthSize: 0,
	gridItem: {},
	hasDropdown: false,
	disabled: false,
	hasCarousel: false,
	children: [],
	pendingData: false,
	hasRefresh: false,
	isModal: false,
	isAnalytics: false,
	isTicketingCharts: false,
	getContent: undefined,
	dropdownItem: undefined,
	contentData: undefined,
	asOfDate: undefined,
	dataGridSize: 'lg',
};

export default WrapperGridCard;
