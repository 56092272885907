export default {
	'REPORTS.REPORT': 'Rapport',
	'REPORTS.M2M_ACCOUNT_ID': 'ID compte machine-machine',
	'REPORTS.M2M_PLATFORM': 'Plateforme machine-machine',
	'REPORTS.FREQUENCY': 'Fréquence',
	'REPORTS.REPORT_DATE': 'Date du rapport',
	'REPORTS_TABS.ENDPOINTS': 'Points terminaux',
	'REPORTS_TABS.USAGE_SUMMARY': 'Synthèse d’utilisation',
	'REPORTS_TABS.TRANSFER_ACTIVITIES': 'Activités de transfert',
	'REPORTS_TABS.ACCOUNT_SUMMARY': 'Synthèse de compte',
	'REPORTS_TABS.BILLING_DETAILS': 'Détails facturation',
	'REPORTS_TABS.REFERENCE_REPORTS': 'Rapports de référence',
	'REPORTS_TABS.MY_EXPORTS': 'Mes exportations',
	'REPORTS_TABS.USAGE_REPORTS': 'Rapports d’utilisation',
	'REPORTS_TABS.REPOSTED_REPORTS': 'Rapports repostés',
	'SECURITY_FEATURES.NO_REPORTS_TITLE': 'Aucun rapport disponible',
	'SECURITY_FEATURES.NO_REPORTS_SUBTITLE': 'Aucun rapport à montrer actuellement pour cette table',
	'REPORTS.FILE_NAME': 'Nom de fichier',
	'REPORTS.FILE_DATE': 'Date du dossier',
	'REPORTS.REPOST_DATE': 'Repost Date',
	'REPORTS.REQUESTED_BY': 'Demandé par'
};
