import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { localizedRoute } from '../../../../utils/helperFunctions';
import AddAccount from './AddAccount';

import {
	getPlatforms,
	addAccount,
	getPlatformTypes,
	validateAccount,
	validateAccountInit
} from '../../redux/accounts/actions';
import {
	getPlatformsRequest,
	getPlatformsFail,
	getPlatformsSuccess,
	addAccountRequest,
	getPlatformTypesRequest,
	getPlatformTypesSuccess,
	getPlatformTypesFail,
	validateAccountRequest,
	validateAccountSuccess,
	validateAccountFail
} from '../../redux/accounts/selectors';

import { getCompanySuccess } from '../../redux/companies/selectors';
import { getCompany, getCompanyInit } from '../../redux/companies/actions';

const mapStateToProps = (state) => ({
	getPlatformsRequest: getPlatformsRequest(state),
	getPlatformsFail: getPlatformsFail(state),
	platforms: getPlatformsSuccess(state),
	formData: getFormValues('AddAccountForm')(state),
	company: getCompanySuccess(state),
	addAccountRequest: addAccountRequest(state),
	getPlatformTypesRequest: getPlatformTypesRequest(state),
	getPlatformTypesFail: getPlatformTypesFail(state),
	platformTypes: getPlatformTypesSuccess(state),
	validateAccountRequest: validateAccountRequest(state),
	validateAccountFail: validateAccountFail(state),
	validateAccountSuccess: validateAccountSuccess(state)
});

const mapDispatchToProps = (dispatch) => ({
	getPlatforms: (params) => dispatch(getPlatforms(params)),
	getCompany: (params) => dispatch(getCompany(params)),
	getCompanyInit: () => dispatch(getCompanyInit()),
	pushBack: (url) => dispatch(localizedRoute(url)),
	addAccount: (data) => dispatch(addAccount(data)),
	getPlatformTypes: () => dispatch(getPlatformTypes()),
	validateAccount: (params) => dispatch(validateAccount(params)),
	validateAccountInit: () => dispatch(validateAccountInit())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAccount);
