export default {
	'TICKETING.YES': 'Sim',
	'TICKETING.NO': 'Não',
	'TICKETING.VIEW_NOTE': 'Visualizar nota',
	'TICKETING.TICKET_ID': 'ID do tíquete',
	'TICKETING.CATEGORY_NAME': 'Categoria',
	'TICKETING.DATE_CREATED': 'Data criado',
	'TICKETING.CREATED_BY': 'Criado por',
	'TICKETING.TICKETING_STATUS': 'Status da emissão de tíquetes',
	'TICKETING.LAST_UPDATE': 'Última atualização',
	'TICKETING.SEVERITY': 'Gravidade',
	'TICKETING.SUBMISSION_LOCATION': 'Local de envio',
	'TICKETING.UPDATE_NOTE': 'Atualizar nota',
	'TICKETING.STATUS': 'Status',
	'TICKETING.SUBMITED_BY': 'Enviado por',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': 'O seu tíquete está relacionado com um endpoint específico? Se for o caso, vá para a tela de detalhes do endpoint em questão e clique em Obter suporte para o link deste endpoint. Caso contrário, preencha o formulário abaixo.',
	'TICKETING.ISSUE': 'Problema',
	'TICKETING.TICKET_DETAILS': 'Detalhes do tíquete',
	'TICKETING.CATEGORY': 'Categoria',
	'TICKETING.SELECT_CATEGORY': 'Selecionar categoria',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Subcategoria (opcional)',
	'TICKETING.SUBCATEGORY': 'Subcategoria',
	'TICKETING.ROAMING_ISSUE': 'Problema de roaming',
	'TICKETING.PRIORITY': 'Prioridade',
	'TICKETING.ENDPOINT': 'Endpoint',
	'TICKETING.ICCID': 'ICCID',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'MSISDN',
	'TICKETING.ENDPOINT_LOCATION': 'Localização do endpoint',
	'TICKETING.ENDPOINT_COUNTRY': 'País do endpoint',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Limpar parâmetros de endpoint',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Buscar parâmetros de endpoints',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': 'Você pode usar esta opção para buscar os parâmetros de endpoints digitando o valor de MSISDN, ICCID ou IMSI.',
	'TICKETING.ENDPOINT_STREET': 'Logradouro do endpoint',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Endereço do logradouro do endpoint',
	'TICKETING.ZIP_CODE': 'CEP',
	'TICKETING.ENDPOINT_STATE': 'Estado do endpoint',
	'TICKETING.DETAILS': 'Detalhes',
	'TICKETING.BARS_SHOWN': 'Barras indicadas',
	'TICKETING.ENDPOINT_CITY': 'Cidade do endpoint',
	'TICKETING.ENDPOINT_MODEL': 'Modelo de endpoint',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': 'A resolução de problemas foi concluída?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Descrição do problema',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': 'Forneça uma descrição resumida do problema usando um máximo de 255 caracteres',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': 'Forneça detalhes adicionais do problema. Se necessário use até 2000 caracteres',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Notas',
	'TICKETING.ISSUE_DETAILS': 'Detalhes do problema',
	'TICKETING.CUSTOMER': 'Cliente',
	'TICKETING.ACCOUNT_NUMBER': 'Número da conta',
	'TICKETING.ACCOUNT_NAME': 'Nome da conta',
	'TICKETING.PHONE': 'Telefone',
	'TICKETING.CONTACT_PHONE': 'Telefone de contato',
	'TICKETING.CONTACT_EMAIL': 'E-mail de contato',
	'TICKETING.ATTACH_FILE': 'Anexar arquivo',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': 'O nome do arquivo é muito longo para carregar. O nome do arquivo deve ter no máximo 25 caracteres.',
	'TICKETING.EMAIL': 'E-mail',
	'TICKETING.TICKETING': 'Emissão de tíquetes',
	'TICKETING.ADD_TICKET': 'Adicionar tíquete',
	'TICKETING.SEARCH_RESULTS': 'Resultados da pesquisa',
	'TICKETING.NO_TICKETS_AVAILABLE': 'Nenhum tíquete disponível',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': 'Êxito na alteração das configurações da tabela de tíquetes!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': 'Configurações da tabela de tíquetes foram atualizadas com êxito.',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': 'Erro na alteração das colunas da tabela de tíquetes!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': 'As colunas da tabela de tíquetes não puderam ser atualizadas neste momento. Tentar novamente.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'O tíquete foi revisado com êxito.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': 'Erro ao revisar tíquete. Tente de novo mais tarde.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'O nome do arquivo não deve conter espaços vazios.',
	'TICKETING.NEW_TICKET': 'Novo tíquete',
	'TICKETING.OFF': 'Desligado',
	'TICKETING.ON': 'Ligado',
	'TICKETING.EMAIL_NOTIFICATIONS': 'Notificações de e-mail',
	'TICKETING.CONTACT_NAME': 'Nome de contato',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Última atualização por',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Data/hora da última atualização',
	'TICKETING.SOMETHING_WENT_WRONG': 'Ocorreu um problema, tentar novamente.',
	'TICKETING.CANCEL_TICKET': 'Cancelar tíquete',
	'TICKETING.ADD_NOTE': 'Adicionar nota',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'Tíquete enviado com êxito!',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': 'Não foi possível enviar o tíquete com êxito!',
	'TICKETING.CREATED_AT': 'Criado em',
	'TICKETING.GO_BACK_TO_TICKETING': 'Voltar à emissão de tíquetes',
	//  TICKETING CATEGORIES
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': 'Cobrança de problema não técnico',
	'TICKETING.CATEGORY_PROVISIONING': 'Provisionamento',
	'TICKETING.CATEGORY_DATA': 'Dados',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Documentação',
	'TICKETING.CATEGORY_DUPLICATE': 'Duplicar',
	'TICKETING.CATEGORY_BILLING': 'Cobrança',
	'TICKETING.CATEGORY_NOTIFICATION': 'Notificação',
	'TICKETING.CATEGORY_OTHER': 'Outro',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': 'Solicitação de melhoria do produto',
	'TICKETING.CATEGORY_PROJECT': 'Projeto',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Problema de vendas/marketing',
	'TICKETING.CATEGORY_QUESTION': 'Pergunta',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Solicitação de serviço',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Problemas técnicos',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': 'Usuário/administração da conta',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Problema de conectividade',
	'TICKETING.CATEGORY_EQUIPMENT': 'Equipamentos',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'Pergunta geral',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Dispositivo extraviado/roubado',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Solicitação de porta',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'Relacionada ao produto',
	'TICKETING.CATEGORY_REPORTING': 'Relatório',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Sugestão/recomendação',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Problema do site na web',
	//  TICKETING STATUS
	'TICKETING.STATUS_QUEUED': 'Em fila',
	'TICKETING.STATUS_ACTIVE': 'Ativo',
	'TICKETING.STATUS_DEFERRED': 'Adiado',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'Resposta do cliente pendente',
	'TICKETING.STATUS_CLOSED': 'Fechado',
	'TICKETING.STATUS_CANCEL': 'Cancelar',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Disputado pelo cliente',
	'TICKETING.STATUS_CLEARED': 'Removido',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Pronto para fechar',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'Resposta da ATT pendente',
	//  TICKETING SEVERITY
	'TICKETING.SEVERITY_CRITICAL': 'Crítica',
	'TICKETING.SEVERITY_MAJOR': 'Principal',
	'TICKETING.SEVERITY_MINOR': 'Secundária',
	//  TICKETING BILLING SUBCATEGORIES
	'TICKETING.ACTIVATION_FEE': 'Taxa de ativação',
	'TICKETING.AIRTIME_USAGE': 'Uso do horário de transmissão',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Perguntas básicas sobre a conta',
	'TICKETING.BILLING_DISPUTES': 'Disputas sobre a cobrança',
	'TICKETING.BILLING_FILES_ISSUES': 'Problemas com arquivos de cobrança',
	'TICKETING.BILL_RECONCILIATION': 'Reconciliação da cobrança',
	'TICKETING.CUSTOMER_EDUCATION': 'Educação do cliente',
	'TICKETING.DATA_USAGE_CANADA': 'Uso de dados no Canadá',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Uso de dados fora do país',
	'TICKETING.DATA_USAGE_OFF_NET': 'Uso de dados fora da rede',
	'TICKETING.DATA_USAGE_ON_NET': 'Uso de dados na rede',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Fatura ou cobrança atrasadas',
	'TICKETING.DISCOUNT': 'Desconto',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Consulta sobre fraude ou problema',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Problemas de impressão da fatura',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Cobrança recorrente mensal',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'Ajuste avulso da cobrança',
	'TICKETING.OTHER': 'Outro',
	'TICKETING.PAYMENT_ISSUES': 'Problemas de pagamento',
	'TICKETING.PRORATED_CHARGES': 'Cobranças rateadas',
	'TICKETING.REACTIVATION_FEE': 'Taxa de ativação',
	'TICKETING.SMS_TEXT_CANADA': 'SMS/Texto Canadá',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'SMS/Texto Internacional',
	'TICKETING.SMS_TEXT_OFF_NET': 'SMS/Texto fora da rede',
	'TICKETING.SMS_TEXT_ON_NET': 'SMS/Texto na rede',
	'TICKETING.STATUS_INVOICE': 'Status da fatura',
	'TICKETING.TAXES_AND_SURCHARGES': 'Taxas e cobranças adicionais',
	'TICKETING.DATA': 'Dados',
	'TICKETING.MESSAGING_SMS_MMS_': 'Mensagens (SMS/MMS)',
	'TICKETING.VOICE': 'Voz',
	'TICKETING.CLARIFICATION': 'Esclarecimento',
	'TICKETING.PREPAID': 'Pré-pago',
	'TICKETING.USAGE_INQUIRY': 'Consulta do uso',
	//  TICKETING NON TECHNICAL ISSUE SUBCATEGORIES
	'TICKETING.BILLING_QUESTION': 'Pergunta sobre a cobrança',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Problema relacionado com a fatura',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Problema relacionado com o plano de tarifas',
	'TICKETING.USAGE_RELATED_ISSUE': 'Problema relacionado com o uso',
	//  TICKETING DATA SUBCATEGORIES
	'TICKETING.2G_3G_MIX': 'Mistura de 2G e 3G',
	'TICKETING.2G_ONLY': '2G SOMENTE',
	'TICKETING.3G_ONLY': '3G SOMENTE',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Conectado, mas sem passar dados',
	'TICKETING.FRAME_RELAY_PVC': 'Retransmissão de quadros/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Problema de latência',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Roaming/Doméstico',
	'TICKETING.ROAMING_INTERNATIONAL': 'Roaming/Internacional',
	'TICKETING.SIGNAL_ISSUES': 'Problemas de sinais',
	'TICKETING.SMPP_BIND': 'Conexão SMPP',
	'TICKETING.SMS': 'SMS',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKETING DOCUMENTATION SUBCATEGORIES
	'TICKETING.FEEDBACK': 'Feedback',
	'TICKETING.NEW_REQUEST': 'Nova solicitação',
	// TICKETING EQUIPMENT SUBCATEGORIES
	'TICKETING.COMPATIBILITY': 'Compatibilidade',
	'TICKETING.DEVICE_UNLOCK': 'Desbloqueio de dispositivo',
	'TICKETING.ORDERING': 'Pedindo',
	// SUBCATEGORIAS DE DISPOSITIVO DE EMISSÃO DE TÍQUETES EXTRAVIADO/ROUBADO
	'TICKETING.BLOCK_DEVICE': 'Bloquear dispositivo',
	'TICKETING.FRAUD_REPORT': 'Relatório de fraude',
	'TICKETING.UNBLOCK_DEVICE': 'Desbloquear dispositivo',
	//  TICKETING NOTIFICATION SUBCATEGORIES
	'TICKETING.INCIDENT_NOTIFICATION': 'Notificação de incidente',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Manutenção agendada',
	//  TICKETING PORT REQUEST SUBCATEGORIES
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Network Channel Interface (NCI)',
	'TICKETING.WIRELESS': 'Sem fio',
	'TICKETING.WIRELINE': 'Conexão com fio',
	//  TICKETING PRODUCT RELATED SUBCATEGORIES
	'TICKETING.QUESTION': 'Pergunta',
	//  TICKETING PROVISIONING SUBCATEGORIES
	'TICKETING.BATCH_FILES': 'Arquivos em lotes',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Criar novo perfil de SIM',
	'TICKETING.EQUIPMENT_INQUIRY': 'Consulta de equipamento',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Provisionamento único de SIM',
	'TICKETING.WEB_SERVICE': 'Serviço Web',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'SIMs/recursos de provisionamento',
	'TICKETING.ACTIVATION': 'Ativação',
	'TICKETING.BATCH_BULK_CHANGE': 'Alteração em lote/em massa',
	'TICKETING.CALL_FORWARDING': 'Encaminhamento de chamadas',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Alterar plano de comunicação',
	'TICKETING.CHANGE_RATE_PLAN': 'Alterar plano de tarifas',
	'TICKETING.CHANGE_SIM_STATUS': 'Alterar Status de SIM',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'Transferir SIM entre contas',
	//  TICKETING QUESTION SUBCATEGORIES
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Pergunta sobre regra de automação',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Pergunta sobre Central de controle',
	'TICKETING.COVERAGE_QUESTION': 'Pergunta sobre cobertura',
	'TICKETING.GENERAL_QUESTION': 'Pergunta geral',
	'TICKETING.OUTAGE_QUESTION': 'Pergunta sobre interrupção',
	'TICKETING.ROAMING_QUESTION': 'Pergunta sobre roaming',
	'TICKETING.JPO_COVERAGE': 'Cobertura de JPO',
	//  SUBCATEGORIAS DE PROBLEMA DE VENDAS/MARKETING DE EMISSÃO DE TÍQUETES
	'TICKETING.CUSTOMER_INQUIRY': 'Consulta do cliente',
	'TICKETING.DEV_KIT': 'Kit de desenv',
	'TICKETING.MARKETING_INQUIRY': 'Consulta de marketing',
	//  TICKETING SERVICE REQUEST SUBCATEGORIES
	'TICKETING.ACCOUNT_TERMINATION': 'Rescisão da conta',
	'TICKETING.API_SETUP': 'Configuração de API',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': 'Criação/Modificação da zona de cobrança',
	'TICKETING.CC_ACCESS_UPDATES': 'Acesso/atualizações de CC',
	'TICKETING.COMMUNICATION_PLAN': 'Plano de comunicação',
	'TICKETING.CUSTOM_APN': 'APN personalizado ',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Configuração global de Sim /Mapeamento',
	'TICKETING.GPRS_VPN': 'GPRS VPN',
	'TICKETING.LEGAL_SUBPOENA': 'Legal/intimação',
	'TICKETING.LTE_MIGRATION': 'Migração de LTE',
	'TICKETING.MSISDN_UPDATES': 'Atualizações de MSISDN',
	'TICKETING.NEW_ACCOUNT': 'Nova conta',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Definição config. do raio',
	'TICKETING.REPORT': 'Relatório',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES (Solicitação da zona de roaming)',
	'TICKETING.SECURESIM': 'SecureSIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'Pedido/Provisionamento de SIM',
	'TICKETING.TRACE': 'Rastrear',
	//  TICKETING TECHNICAL ISSUES SUBCATEGORIES
	'TICKETING.APPLICATION_ISSUES': 'Problemas com aplicativo',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Problema técnico com cobrança',
	'TICKETING.CONNECTIVITY': 'Conectividade',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Problema global com SIM',
	'TICKETING.JPO_FILE_ISSUE': 'Problemas com arquivo JPO',
	'TICKETING.JPO_LINK_ISSUE': 'Problema com link JPO',
	'TICKETING.NOC_BRIDGE': 'Ponte NOC',
	'TICKETING.CDR_FILES': 'Arquivos CDR',
	'TICKETING.JPO_REPORT': 'Relatório de JPO',
	//  SUBCATEGORIAS DE ADMINISTRAÇÃO DO USUÁRIO/CONTA DE EMISSÃO DE TÍQUETES
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': 'Adicionar novas opções de funcionalidade da conta',
	'TICKETING.DEACTIVATE_USER': 'Desativar usuário',
	'TICKETING.ID_ISSUE': 'Problema com ID',
	'TICKETING.NEW_USER': 'Novo usuário',
	'TICKETING.PASSWORD_RESET': 'Redefinição da senha',
	//  TICKETING WEBSITE ISSUE SUBCATEGORIES
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'AT&T Control Center (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Portal/Apollo de carro conectado',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise on Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	// OTHER
	'TICKETING.CRITICAL': 'Crítico',
	'TICKETING.MAJOR': 'Principal',
	'TICKETING.MINOR': 'Secundário',
	'TICKETING.LOW': 'Baixo',
	'TICKETING.MEDIUM': 'Médio',
	'TICKETING.HIGH': 'Alto',
	'TICKETING.OUTAGE': 'Interrupção',
	// SERVICE REQUEST CUSTOM APN SUBCATEGORIES
	'TICKETING.DYNAMIC_IP': 'IP dinâmico',
	'TICKETING.FIXED_IP': 'IP fixo',
	//  TECHNICAL ISSUES APPLICATION ISSUES SUBCATEGORIES
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Regra de automação',
	'TICKETING.CC_ISSUE_OTHER': 'Problema com CC - Outro',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Exportar e atualizar lotes',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Serviços de localização/terceiros',
	'TICKETING.PROVISIONING': 'Provisionamento',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Atraso de informações da sessão',
	'TICKETING.SPOTLIGHT': 'Destaque',
	'TICKETING.WEBUL_ISSUES': 'Problemas com WebUI',
	//  TECHNICAL ISSUES CONNECTIVITY SUBCATEGORIES
	'TICKETING.DATA_ISSUE': 'Problema com dados',
	'TICKETING.DEVICE_ISSUE': 'Problema com dispositivo',
	'TICKETING.SFTP_FILE_TRANSFERS': 'SFTP/Transferências de arquivos',
	'TICKETING.SMS_ISSUE': 'Problema de SMS',
	'TICKETING.VOICE_ISSUE': 'Problema de voz',
	//  TECHNICAL ISSUES JPO LINK ISSUE SUBCATEGORIES
	'TICKETING.NETWORK': 'Rede',
	'TICKETING.RADIUS': 'Raio',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Adicionar anexo',
	'TICKETING.NOTE': 'Nota',
	'TICKETING.LAST_UPDATED_DATE': 'Data da última atualização do tíquete',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': 'Endpoint com esse parâmetro não existe.',
	// notificações de emissão de tíquetes
	'TICKETING.ATTACHMENT_FAIL_TITLE': 'Não foi possível concluir totalmente sua solicitação.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': 'O tíquete foi criado {ticketId}, mas os anexos ({attachments}) não foram carregados',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': 'O tíquete foi revisado {ticketId}, mas os anexos ({attachments}) não foram carregados',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': 'O tíquete foi revisado {ticketId}, mas os anexos ({attachments}) não foram excluídos.',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': 'O tíquete foi revisado {ticketId}, mas os anexos ({deleteAttachments}) não foram excluídos e os anexos ({uploadAttachments}) não foram carregados.',
};
