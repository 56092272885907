import React, { PureComponent } from 'react';
import { bool, object, string, func, any } from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { isArray } from 'lodash';
import { isValid } from 'date-fns';

import Loader from '../../../../lib/DigitalComponents/Loader';
import {
	isUserAllowedToAccess,
} from '../../../../utils/AuthSelector';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';
import Button from '../../../../lib/DigitalComponents/Button';
import GroupButton from '../GroupButton';
import SMSTab from '../../views/SMSTabView';

import styles from './DiagnosticDcp.scss';

const Check = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');
const NCheck = ImageSelector(CurrentContext.theme, 'svgs/close.svg');
const Reset = ImageSelector(CurrentContext.theme, 'svgs/reset.svg');
const GSucces = ImageSelector(
	CurrentContext.theme,
	'svgs/diagnostic-success.svg',
);
const GMobileTest = ImageSelector(
	CurrentContext.theme,
	'svgs/diagnostic-dcp-illustration.svg',
);
const GError = ImageSelector(CurrentContext.theme, 'svgs/diagnostic-error.svg');
const GDots = ImageSelector(CurrentContext.theme, 'svgs/diagnostic-dots.svg');
const BadgeAlert = ImageSelector(CurrentContext.theme, 'svgs/badge-alert.svg');
const TabletIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/endpoint-registration-details.svg',
);

export default class DiagnosticDcp extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedButton: 0,
			refresh: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const { refresh } = this.state;

		if (prevState.refresh !== refresh) {
			this.refresh();
		}
	}

	refresh = () => this.setState({
		refresh: false,
	});

	renderEDField = (id, defaultLabel, value = '-', type, dateFormat) => (
		<div data-spec={`ed-field-${id}`} className={styles.rd_field}>
			<FormattedMessage id={id} defaultMessage={defaultLabel} tagName="div" />
			{type === 'date' && isValid(new Date(value)) ? (
				<div className={styles.rd_field_value}>
					{dateFormat === 'full'
						? (
							<FormattedDate
								value={new Date(value)}
								year="numeric"
								month="2-digit"
								day="2-digit"
								hour="2-digit"
								minute="2-digit"
								second="2-digit"
								hour12={false}
							/>
						)
						: (
							<FormattedDate
								value={new Date(value)}
								year="numeric"
								month="2-digit"
								day="2-digit"
							/>
						)}
				</div>
			) : (
				<div className={styles.rd_field_value}>{value}</div>
			)}
		</div>
	);

	renderDiagnosticDetails = () => {
		const {
			blockedSubscriptionDcpData,
			troubleshootDcpData,
			isFetchingBlockedSubscription,
			isGetTroubleshootDcpFetching,
			getBlockedSubscriptionDcp,
			getTroubleshootDcp,
			iccid,
			endpoint,
		} = this.props;
		const blocked = blockedSubscriptionDcpData
			? blockedSubscriptionDcpData.blocked : false;
		const getBlockedSubscription = () => {
			getBlockedSubscriptionDcp(
				iccid,
				endpoint.m2mAccountId,
			);
		};
		const getTroubleshootDcpFunc = () => getTroubleshootDcp(iccid);
		const keyMessages = {
			locationUpdate: 'Location Update',
			pdpStatus: 'PDP Status',
			cancelLocation: 'Cancel Location',
		};

		if (isFetchingBlockedSubscription || isGetTroubleshootDcpFetching) {
			return (
				<div
					data-spec="diagnostic-dcp-details"
					className={styles.diagnostics_details}
				>
					<Loader data-spec="ckecked-blocked-status" />
				</div>
			);
		}

		return (
			<>
				<div className={styles.test_wrapper}>
					<div className={styles.graphics}>
						{blockedSubscriptionDcpData
						&& Object.keys(blockedSubscriptionDcpData).length > 0 && (
							<>
								{blocked ? (
									<GError className={styles.error} />
								) : (
									<GSucces className={styles.succes} />
								)}
								<GDots
									className={cn(
										styles.dots,
										blocked
										&& styles.dots_error,
									)}
								/>
							</>
						)}
					</div>
					<div className={styles.data}>
						<div
							className={cn(
								styles.title,
								blocked && styles.error,
							)}
						>
							<FormattedMessage
								id="CHECK_BLOCKED_STATUS"
								defaultMessage="Check blocked status"
							/>
						</div>
						{blockedSubscriptionDcpData
						&& Object.keys(blockedSubscriptionDcpData).length > 0 && (
							<div className={styles.data_name}>
								<span>
									<FormattedMessage
										id="BLOCKED_STATUS"
										defaultMessage="Blocked status"
									/>
								</span>
								{blocked ? (
									<>
										<NCheck
											className={styles.ncheck}
											data-tip={'test'}
											data-for="blockedStatusCheck"
										/>
										<ReactTooltip
											className={styles.tooltip}
											place={'right'}
											type="light"
											id="blockedStatusCheck"
										>
											<div className={styles.tooltip_data}>
												<span className={styles.data_title}>
													<FormattedMessage
														id="TEST_DETAILS"
														defaultMessage="Test details"
													/>
												</span>
												<div>
													<code>
														<pre>
															{JSON.stringify(
																blockedSubscriptionDcpData,
																null,
																1,
															)}
														</pre>
													</code>
												</div>
											</div>
										</ReactTooltip>
									</>
								) : (
									<Check />
								)}
							</div>
						)}
						{blockedSubscriptionDcpData
						&& Object.keys(blockedSubscriptionDcpData).length > 0 && (
							<div
								className={styles.retry_test}
								onClick={getBlockedSubscription}
							>
								<Reset />
								<span>
									<FormattedMessage
										id="RETRY_TEST"
										defaultMessage="Retry test"
									/>
								</span>
							</div>
						)}
						{!blockedSubscriptionDcpData && (
							<Button
								dataSpec="diagnostic-button"
								variant="outline-primary"
								className={cn(
									styles.align_self,
									styles.button_margin
								)}
								onClick={getBlockedSubscription}
								label={<FormattedMessage
									id="CHECK_BLOCKED_SUB"
									defaultMessage="Check Blocked Sub"
								/>}
							/>
						)}
					</div>
				</div>
				<div className={styles.test_wrapper}>
					<div className={styles.graphics}>
						{
							troubleshootDcpData &&
							isArray(troubleshootDcpData) &&
							Object.keys(troubleshootDcpData[0]).length > 0
								? (
									<GMobileTest
										className={styles.orange}
										data-spec="info"
									/>
								)
								: troubleshootDcpData && (
								<GSucces
									data-spec="success"
									className={styles.succes}
								/>
							)
						}
					</div>
					<div className={styles.data}>
						<div
							className={cn(
								styles.title,
								troubleshootDcpData &&
								isArray(troubleshootDcpData) &&
								Object.keys(troubleshootDcpData[0]).length > 0
									? styles.orange
									: '',
							)}
						>
							<FormattedMessage
								id="MOBILE_NETWORK_TEST"
								defaultMessage="Mobile network test"
							/>
						</div>
						{troubleshootDcpData && Object.keys(keyMessages)
							.map((key) => (
								<div
									key={key}
									className={styles.data_name}
									data-spec="mobile-network-test-data"
								>
									<span>
										<FormattedMessage
											id={key}
											defaultMessage={keyMessages[key]}
										/>
									</span>
									<>
										{isArray(troubleshootDcpData) &&
										Object.keys(troubleshootDcpData[0]).length > 0 ? (
											<>
												<BadgeAlert
													className={styles.badge_alert}
													data-tip={'test'}
													data-for={key}
												/>
												<ReactTooltip
													className={styles.tooltip}
													place={'right'}
													type="light"
													id={key}
												>
													<div className={styles.tooltip_data}>
														<span className={styles.data_title}>
															<FormattedMessage
																id="TEST_DETAILS"
																defaultMessage="Test details"
															/>
														</span>
														<div>
															<code>
																<pre>
																	{JSON.stringify(
																		troubleshootDcpData,
																		null,
																		1,
																	)}
																</pre>
															</code>
														</div>
													</div>
												</ReactTooltip>
											</>
										) : (
											<>
												<Check />
											</>
										)}
									</>
								</div>
							))}
						{troubleshootDcpData
							? (
								<div
									className={styles.retry_test}
									onClick={getTroubleshootDcpFunc}
								>
									<Reset />
									<span>
										<FormattedMessage
											id="RETRY_TEST"
											defaultMessage="Retry test"
										/>
									</span>
								</div>
							)
							: (
								<Button
									dataSpec="diagnostic-button"
									variant="outline-primary"
									onClick={getTroubleshootDcpFunc}
									className={styles.align_self}
									label={<FormattedMessage
										id="TROUBLESHOOT_DCP"
										defaultMessage="Troubleshoot DCP"
									/>}
								/>
							)}
					</div>
				</div>
			</>
		);
	};

	renderDiagnosticData = () => {
		const { diagnosticDcpData, isSuccess, isFetching } = this.props;

		if (isFetching) {
			return (
				<div
					data-spec="diagnostic-dcp-details"
					className={styles.registration_details}
				>
					<Loader data-spec="ckecked-blocked-status" />
				</div>
			);
		}

		if (isSuccess && Object.keys(diagnosticDcpData).length > 0) {
			return (
				<div
					data-spec="endpoint-data"
					className={styles.registration_details}
				>
					<div className={styles.rd_title}>
						<FormattedMessage
							id="ENDPOINT_DATA_INFORMATION"
							defaultMessage="Endpoint data information"
						/>
					</div>
					<div className={styles.rd_fields}>
						{this.renderEDField(
							'LAST_SESSION',
							'Last Session',
							diagnosticDcpData.sessionStartDate,
							'date',
						)}
						{this.renderEDField(
							'CARRIER_ATTACHED',
							'Carrier Attached',
							diagnosticDcpData.lastLU &&
							diagnosticDcpData.lastLU.length > 0 &&
							diagnosticDcpData.lastLU[0].networkOperator,
						)}
						{this.renderEDField(
							'APN_DCP',
							'APN',
							diagnosticDcpData.gprs
							&& diagnosticDcpData.gprs.length > 0
							&& diagnosticDcpData.gprs[0].apn,
						)}
						{this.renderEDField(
							'IP_ADDRESS',
							'IP Address',
							diagnosticDcpData.gprs
							&& diagnosticDcpData.gprs.length > 0
							&& diagnosticDcpData.gprs[0].ip,
						)}
						{this.renderEDField(
							'LAST_SENT_SMS_MO',
							'LAST_SENT_SMS_MO',
							diagnosticDcpData.smsMO &&
							diagnosticDcpData.smsMO.length > 0 &&
							diagnosticDcpData.smsMO[0].sms &&
							diagnosticDcpData.smsMO[0].sms.lastSMS,
							'date',
							'full',
						)}
						{this.renderEDField(
							'LAST_SENT_SMS_MT',
							'LAST_SENT_SMS_MT',
							diagnosticDcpData.smsMT &&
							diagnosticDcpData.smsMT.length > 0 &&
							diagnosticDcpData.smsMT[0].lastSMS,
							'date',
							'full',
						)}
					</div>
					<TabletIcon className={styles.tablet_icon} />
				</div>
			);
		}
		return (
			<div
				data-spec="endpoint-data-no-data"
				className={styles.registration_details}
			>
				<FormattedMessage
					id="NO_DATA_TO_DISPLAY_AT_THE_MOMENT"
					defaultMessage="No data to display at the moment"
				/>
			</div>
		);
	};

	render() {
		const {
			messages,
			iccid,
			endpoint,
			getDiagnosticDcp,
			user,
		} = this.props;
		const { selectedButton, refresh } = this.state;

		const getDiagnosticDcpFunc = () => {
			this.setState({ selectedButton: 1 });
			getDiagnosticDcp(iccid);
		};

		return (
			<div
				data-spec="diagnostic-dcp-wrapper"
				className={styles.diagnostics_wrapper}
			>
				<header className={styles.header}>
					<GroupButton>
						<Button
							dataSpec="diagnostic-button"
							variant={selectedButton === 1 ? 'primary' : 'outline-primary'}
							onClick={getDiagnosticDcpFunc}
							label={<FormattedMessage
								id="DIAGNOSTIC_BUTTON"
								defaultMessage="Start Diagnostics"
							/>}
						/>
						{isUserAllowedToAccess([
							'mnc.endpoints.smstab_rw',
							'mnc.endpoints.smstab_ro',
						], user) ? (
							<Button
								dataSpec="sms-button"
								variant={selectedButton === 2 ? 'primary' : 'outline-primary'}
								onClick={() => this.setState({
									selectedButton: 2,
									refresh: true,
								})}
								label={<FormattedMessage
									id="SEND_SMS_TO_ENDPOINT"
									defaultMessage="Send SMS to Endpoint"
								/>}
								disabled={false}
							/>
						) : null}
					</GroupButton>
				</header>
				{selectedButton === 1 && (
					<main className={styles.main}>
						<div
							data-spec="diagnostic-dcp-details"
							className={styles.diagnostics_details}
						>
							{this.renderDiagnosticDetails()}
						</div>
						{this.renderDiagnosticData()}
					</main>
				)}
				{!refresh && selectedButton === 2 && (
					<SMSTab
						messages={messages}
						id={iccid}
						m2mAccountId={endpoint.m2mAccountId}
					/>
				)}
			</div>
		);
	}
}

DiagnosticDcp.propTypes = {
	iccid: string,
	getBlockedSubscriptionDcp: func,
	getDiagnosticDcp: func,
	isFetching: bool,
	endpoint: object,
	blockedSubscriptionDcpData: object,
	getTroubleshootDcp: func,
	isGetTroubleshootDcpFetching: bool,
	troubleshootDcpData: any,
	diagnosticDcpData: object,
	isFetchingBlockedSubscription: bool,
	isSuccess: bool,
	messages: object,
	user: object,
};
DiagnosticDcp.defaultProps = {
	endpoint: {},
	messages: {},
	user: {},
};
