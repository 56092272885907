import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getPendingRegistrations = (params) => {
	const query = {
		additionalParams: params.additionalParams,
		searchParams: params.searchParams,
	};
	if (params.system) {
		return ApiService.get(`/users/system-registrations?${qs.stringify(query)}`);
	}
	return ApiService.get(`/users/registrations?${qs.stringify(query)}`);
};

export const cancelRegistration = (params) => {
	if (params.system) {
		return ApiService.patch(
			`/users/system-registrations/${params.data.id}/cancel`,
			{
				id: params.data.id,
			}
		);
	}
	return ApiService.patch(`/users/registrations/${params.data.id}/cancel`, {
		id: params.data.id,
	});
};

export const resendRegistration = (params) => {
	if (params.system) {
		return ApiService.patch(
			`/users/system-registrations/${params.data.id}/resend-invite`,
			{
				id: params.data.id,
			}
		);
	}
	return ApiService.patch(
		`/users/registrations/${params.data.id}/resend-invite`,
		{
			id: params.data.id,
		}
	);
};

export const getFilters = async () => {
	const calls = [ApiService.get('/users/system-user-group-overviews')];

	const response = await Promise.all(calls);

	const data = {
		status: {
			resultList: [
				{
					id: 1,
					name: 'Access Requested',
				},
				{
					id: 2,
					name: 'Invite Sent',
				},
			],
			totalCount: 2,
		},
		userGroups: response[0].data,
	};

	return {
		data
	};
};

export const getFiltersCompanyUser = async () => {
	const calls = [ApiService.get('/users/user-group-overviews')];

	const response = await Promise.all(calls);

	const data = {
		status: {
			resultList: [
				{
					id: 1,
					name: 'Access Requested',
				},
				{
					id: 2,
					name: 'Invite Sent',
				},
			],
			totalCount: 2,
		},
		userGroups: response[0].data,
	};

	return {
		data
	};
};
