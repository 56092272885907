import React from 'react';
import { object, func } from 'prop-types';

import Table from '../../../../../lib/DigitalComponents/Table';
import hoc from '../AutomationZones';

function Desktop(props) {
	const { automationZones, getOptions, messages } = props;
	return (
		<div data-spec="automation-zones-desktop">
			<Table
				data-spec="automation-zones-desktop"
				data={automationZones.resultList}
				options={getOptions('desktop')}
				messages={messages}
			/>
		</div>
	);
}

Desktop.propTypes = {
	automationZones: object,
	getOptions: func,
	messages: object
};

export default hoc(Desktop);
