import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_SMS_DETAILS, GET_SMS_DETAILS_FILTERS } from './constants';

export default combineReducers({
	getSmsDetails: globalReducer(GET_SMS_DETAILS),
	getSmsDetailsFilters: globalReducer(GET_SMS_DETAILS_FILTERS)
});
