import React, { PureComponent } from 'react';
import {
	object,
	string,
	func,
	bool,
	shape
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../lib/DigitalComponents/Button';
import Checkbox from '../../../../lib/DigitalComponents/Checkbox/Checkbox';
import InputField from '../../../../lib/DigitalComponents/FieldGroup';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import AccountInfoOverview from '../AccountInfoOverview';
import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import { companyReportCategories } from '../../utils/constants';
import {
	UserAllowedToAccess,
	isUserAllowedToAccess
} from '../../../../utils/AuthSelector';
import { required } from '../../../../utils/validators';
import { localizationHelper } from '../../../../utils/helperFunctions';

import styles from './Reports.scss';

const CompaniesRWComponent = UserAllowedToAccess([
	'system.companies_rw',
	'mnc.reports.configuration_rw'
]);

class ReportsForm extends PureComponent {
	constructor(props) {
		super(props);

		const tempState = {};

		companyReportCategories.forEach((category) => {
			tempState[`selectAll${category}Iotc`] = false;
			tempState[`selectAll${category}Sfg`] = false;
		});

		this.state = {
			...tempState
		};
	}

	componentDidMount() {
		this.getAccount();
	}

	componentDidUpdate(prevProps) {
		const {
			companyReports,
			companyReportsEditRequest,
			account,
			match: {
				params: { companyId }
			}
		} = this.props;

		if (
			(prevProps.companyReportsEditRequest && !companyReportsEditRequest) ||
			(account && prevProps.account !== account)
		) {
			this.getCompanyReports({
				companyId,
				accountId: account.company.externalCompanyId,
				m2mAccountId: account.m2mAccountId,
				platformType:
					(account.platformType &&
						account.platformType.platformTypeCategory &&
						account.platformType.platformTypeCategory.code) ||
					account.platformType.code
			});
		}

		if (!prevProps.companyReports && companyReports && companyReports.resultList) {
			companyReportCategories.forEach((category) => {
				const filteredCompanyReports = companyReports.resultList
					.filter((report) => (
						report.sort_frequency.toLowerCase() === category.toLowerCase()
					));
				const tempIotcSelectAll = filteredCompanyReports
						.filter((report) => report.iot_c === true).length ===
					filteredCompanyReports.length;
				const tempSfgSelectAll = filteredCompanyReports
						.filter((report) => report.sfg === true).length ===
					filteredCompanyReports.length;

				this.setSelectAllFields(category, tempIotcSelectAll, tempSfgSelectAll);
			});
		}
	}

	componentWillUnmount() {
		const { getAccountInit } = this.props;
		getAccountInit();
	}

	setSelectAllFields = (category, iotc, sfg) => {
		this.setState({
			[`selectAll${category}Iotc`]: iotc,
			[`selectAll${category}Sfg`]: sfg
		});
	}

	getCompanyReports = () => {
		const {
			getCompanyReports,
			account,
			match: {
				params: { companyId }
			}
		} = this.props;
		if (account) {
			getCompanyReports({
				companyId,
				accountId: account.company.externalCompanyId,
				m2mAccountId: account.m2mAccountId,
				platformType:
					(account.platformType &&
						account.platformType.platformTypeCategory &&
						account.platformType.platformTypeCategory.code) ||
					account.platformType.code,
				searchParams: [
					{
						operator: '!=',
						prop: 'report_frequency',
						propValue: 'Billing'
					}
				],
				additionalParams: {
					dataSort: 'report_friendly_name asc'
				}
			});
		}
	};

	getAccount = () => {
		const {
			getAccount,
			match: {
				params: { accountId }
			}
		} = this.props;
		getAccount({
			id: accountId,
			additionalParams: {
				include: [
					'PlatformType',
					'PlatformMobileOperator',
					'PlatformSatelliteOperator',
					'Company',
					'PlatformType.PlatformTypeCategory'
				]
			}
		});
	};

	submit = (values, dispatch, { initialValues }) => {
		const {
			editCompanyReports,
			account,
			match: { params: { companyId } },
		} = this.props;
		const temp = {};

		Object.keys(values)
			.filter((key) => !key.includes('field') && values[key] !== initialValues[key])
			.forEach((key) => {
				const splitKey = key.split('_');

				if (temp[splitKey[0]]) {
					temp[splitKey[0]][splitKey[1]] = values[key];
				} else {
					temp[splitKey[0]] = { [splitKey[1]]: values[key] };
				}
			});

		const reportConfiguration = Object.keys(temp).map((key) => {
			const obj = {
				available_report_id: parseInt(key, 10),
			};

			if (temp[key].iotc || temp[key].iotc === false) {
				obj.iot_c = temp[key].iotc;
			}
			if (temp[key].sfg || temp[key].sfg === false) {
				obj.sfg = temp[key].sfg;
			}
			if (temp[key].sfgMailbox) {
				obj.sfg = true;
				obj.sfg_mailbox = temp[key].sfgMailbox;
			}

			return obj;
		});

		editCompanyReports({
			companyId,
			accountId: account.company.externalCompanyId,
			m2mAccountId: account.m2mAccountId,
			platformType:
				(account.platformType &&
					account.platformType.platformTypeCategory &&
					account.platformType.platformTypeCategory.code) ||
				account.platformType.code,
			data: { reportConfiguration }
		});
	};

	pushBack = () => {
		const {
			pushBack,
			match: {
				params: { companyId, accountId }
			}
		} = this.props;

		if (companyId) {
			pushBack(`/companies/${companyId}/accounts/${accountId}`);
		} else {
			pushBack(`/my-company/${accountId}`);
		}
	};

	render() {
		const {
			handleSubmit,
			companyReports,
			dirty,
			submitting,
			disableEditing,
			companyReportsGetRequest,
			companyReportsEditRequest,
			accountRequest,
			accountFail,
			account,
			user,
			change,
			formValues,
			invalid,
		} = this.props;
		const { state } = this;
		const reportColumns = [];

		if (
			companyReports &&
			companyReports.resultList &&
			companyReports.resultList.length > 0
		) {
			companyReportCategories
				.forEach((categorie) => {
					reportColumns.push({
						categorie,
						availableReports:
							companyReports &&
							companyReports.resultList &&
							companyReports.resultList.length > 0 &&
							companyReports.resultList.filter(
								(item) => item.sort_frequency === categorie
							)
					});
				});
		}

		return (
			<div data-spec="platform-details">
				<PageTitle
					title={
						<FormattedMessage
							id="ONBOARDING.COMPANY_REPORTS"
							defaultMessage="Reports"
						/>
					}
					pushBack={this.pushBack}
				/>
				{!companyReports ||
				companyReportsGetRequest ||
				companyReportsEditRequest ||
				!account ||
				accountRequest ? (
					<Loading data-spec="loader" />
				) : (
					<>
						<div className={styles.reports_wrapper}>
							{accountFail && !accountRequest && null}
							{account && !accountRequest && (
								<AccountInfoOverview
									account={account}
									title={
										<FormattedMessage
											id="ONBOARDING.DEFINE_REPORTS_TO_BE_ENABLED_FOR"
											defaultMessage="Define reports to be enabled for {companyName}"
											values={{
												companyName: account.company.companyFriendlyName
											}}
										/>
									}
									subtitle={
										<FormattedMessage
											id="ONBOARDING.REPORTS_SUBTITLE"
											defaultMessage="Reports checked will be displayed in IoT Console portal for users to download"
										/>
									}
								/>
							)}
						</div>
						<form
							onSubmit={handleSubmit(this.submit)}
							className={styles.reports_wrapper}
							data-spec="company-reports"
						>
							<div className={styles.reports_container}>
								{reportColumns
									.filter((report) => (
										report && report.availableReports.length > 0
									))
									.map((reportCategorie) => (
										<div
											key={reportCategorie.categorie}
											className={styles.reports_column}
										>
											<h5 className={styles.headline}>
												<FormattedMessage
													id={`ONBOARDING.${reportCategorie.categorie.toUpperCase()}_REPORTS`}
													defaultMessage={`${reportCategorie.categorie} Reports`}
												/>
											</h5>
											<div className={styles.underline}>
												{(
													reportCategorie.categorie === 'Daily'
													|| reportCategorie.categorie === 'Monthly'
												) && (
													<FormattedMessage
														id="ONBOARDING.SENDING_REPORTS_BY_STERLING"
														defaultMessage="If sending reports by Sterling File Gateway, please fill out the {sfgConnectionsFormLink} before proceeding."
														values={{
															sfgConnectionsFormLink: (
																<a
																	className={styles.link}
																	href="https://workspace.web.att.com/sites/ascspi/stakeholder/Lists/SFG%20Connection%20Requests/AllItems.aspx"
																>
																	<FormattedMessage
																		id="ONBOARDING.SFG_CONNECTIONS_FORM"
																		defaultMessage="SFG Connections Form"
																	/>
																</a>
															)
														}}
													/>
												)}
												{reportCategorie.categorie === 'DailyCombined' && (
													<FormattedMessage
														id="ONBOARDING.DAILY_COMBINED_REPORTS_UNDERLINE"
														defaultMessage="A task must be opened in Jira to have analytics create these reports."
													/>
												)}
											</div>
											<div className={styles.first_row}>
												<span>
													<FormattedMessage
														id="ONBOARDING.SELECT_DESELECT_ALL"
														defaultMessage="Select/Deselect All"
													/>
												</span>
												<div className={styles.sfg_iotc}>
													<span>
														<FormattedMessage
															id="ONBOARDING.IOT_C"
															defaultMessage="IoT-C"
														/>
													</span>
													<Checkbox
														checked={state[
															`selectAll${reportCategorie.categorie}Iotc`
														]}
														data-spec="select-all-iotc-checkbox"
														disabled={
															disableEditing ||
															!isUserAllowedToAccess(
																[
																	'system.companies_rw',
																	'mnc.reports.configuration_rw'
																],
																user
															)
														}
														onChange={() => {
															reportCategorie.availableReports
																.forEach((report) => (
																	change(
																		`${report.id}_iotc`,
																		!state[`selectAll${reportCategorie.categorie}Iotc`]
																	)
																));
															this.setState({
																[`selectAll${reportCategorie.categorie}Iotc`]: !state[`selectAll${reportCategorie.categorie}Iotc`]
															});
														}}
													/>
												</div>
												<div className={styles.sfg_iotc}>
													<span>
														<FormattedMessage
															id="ONBOARDING.SFG"
															defaultMessage="SFG"
														/>
													</span>
													<Checkbox
														checked={state[
															`selectAll${reportCategorie.categorie}Sfg`
														]}
														data-spec="select-all-sfg-checkbox"
														disabled={
															disableEditing ||
															!isUserAllowedToAccess(
																[
																	'system.companies_rw',
																	'mnc.reports.configuration_rw'
																],
																user
															)
														}
														onChange={() => {
															reportCategorie.availableReports
																.forEach((report) => {
																	change(
																		`${report.id}_sfg`,
																		!state[`selectAll${reportCategorie.categorie}Sfg`]
																	);

																	if (!state[`selectAll${reportCategorie.categorie}Sfg`]) {
																		change(`${report.id}_sfgMailbox`, null);
																	}
																});
															this.setState({
																[`selectAll${reportCategorie.categorie}Sfg`]: !state[`selectAll${reportCategorie.categorie}Sfg`]
															});
														}}
													/>
												</div>
												<span>
													{reportCategorie.categorie === 'Daily' ? (
														<FormattedMessage
															id="ONBOARDING.SFG_MAILBOX"
															defaultMessage="SFG Mailbox"
														/>
													) : (
														<FormattedMessage
															id="ONBOARDING.SFG_ROUTING"
															defaultMessage="SFG Routing"
														/>
													)}
												</span>
											</div>
											{reportCategorie.availableReports.map((report) => (
												<div key={report.id} className={styles.row}>
													<span>
														<FormattedMessage
															id={`ONBOARDING.${localizationHelper(
																report.report_friendly_name
															).toUpperCase()}`}
															defaultMessage={report.report_friendly_name}
														/>
													</span>
													<Field
														name={`${report.id}_iotc`}
														type="checkbox"
														value={report.iot_c}
														component={Checkbox}
														disabled={
															disableEditing ||
															!isUserAllowedToAccess(
																[
																	'system.companies_rw',
																	'mnc.reports.configuration_rw'
																],
																user
															)
														}
														onChange={() => {
															if (
																state[`selectAll${reportCategorie.categorie}Iotc`]
															) {
																this.setState({
																	[`selectAll${reportCategorie.categorie}Iotc`]: false
																});
															}
														}}
													/>
													<Field
														name={`${report.id}_sfg`}
														type="checkbox"
														component={Checkbox}
														value={report.sfg}
														disabled={
															disableEditing ||
															!isUserAllowedToAccess(
																[
																	'system.companies_rw',
																	'mnc.reports.configuration_rw'
																],
																user
															)
														}
														onChange={(event, newValue) => {
															if (!newValue) {
																change(
																	`${report.id}_sfgMailbox`,
																	null
																);
															}
															if (
																state[`selectAll${reportCategorie.categorie}Sfg`]
															) {
																this.setState({
																	[`selectAll${reportCategorie.categorie}Sfg`]: false
																});
															}
														}}
													/>
													<FieldWrapper required>
														<Field
															name={`${report.id}_sfgMailbox`}
															component={InputField}
															value={report.sfg_mailbox}
															disabled={
																disableEditing ||
																!isUserAllowedToAccess(
																	[
																		'system.companies_rw',
																		'mnc.reports.configuration_rw'
																	],
																	user
																) || (formValues && !formValues[`${report.id}_sfg`])
															}
															validate={(formValues && formValues[`${report.id}_sfg`])
																? [required]
																: []}
														/>
														{formValues
														&& formValues[`${report.id}_sfg`]
														&& <label />}
													</FieldWrapper>
												</div>
											))}
										</div>
									))}
							</div>
							<div className={styles.section_buttons}>
								<Button
									variant="outline-primary"
									label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
									onClick={this.pushBack}
									type="button"
									dataSpec="cancel-button"
								/>
								<CompaniesRWComponent>
									<Button
										variant="primary"
										label={<FormattedMessage id="SUBMIT" defaultMessage="Submit" />}
										dataSpec="save-button"
										disabled={!dirty || submitting || disableEditing || invalid}
									/>
								</CompaniesRWComponent>
							</div>
						</form>
					</>
				)}
			</div>
		);
	}
}

ReportsForm.propTypes = {
	handleSubmit: func,
	getCompanyReports: func,
	companyId: string,
	companyReportsGetRequest: bool,
	companyReportsEditRequest: bool,
	companyReports: object,
	editCompanyReports: func,
	dirty: bool,
	submitting: bool,
	disableEditing: bool,
	match: object,
	pushBack: func,
	getAccount: func,
	getAccountInit: func,
	account: object,
	accountRequest: bool,
	accountFail: bool,
	user: shape(),
	change: func,
	formValues: object,
	invalid: bool,
};

ReportsForm.defaultProps = {
	disableEditing: false
};

export default reduxForm({
	form: 'companyReportsForm',
	enableReinitialize: true
})(ReportsForm);
