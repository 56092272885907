import React from 'react';
import { object } from 'prop-types';

import {
	DesktopMediaQuery,
	MobileMediaQuery,
	TabletMediaQuery,
} from '../../../../utils/CustomMediaQuery';
import {
	HardwareDesktop,
	HardwareMobile,
} from '../../components/Hardware';

export default function HardwareView({ endpoint }) {
	return (
		<>
			<DesktopMediaQuery>
				<HardwareDesktop endpoint={endpoint} />
			</DesktopMediaQuery>
			<TabletMediaQuery>
				<HardwareDesktop endpoint={endpoint} />
			</TabletMediaQuery>
			<MobileMediaQuery>
				<HardwareMobile endpoint={endpoint} />
			</MobileMediaQuery>
		</>
	);
}

HardwareView.propTypes = {
	endpoint: object,
};
HardwareView.defaultProps = {
	endpoint: {},
};
