export default {
	'ANALYTICS.FIRST_STEP_CHART_CATEGORIES': '1. Grafiekcategorieën',
	'ANALYTICS.SECOND_STEP_GROUP_DATA_BY': '2. Data groeperen op',
	'ANALYTICS.THIRD_STEP_FILTERS': '3. Filters',
	'ANALYTICS.FOURTH_STEP_CHART_OPTIONS': '4. Grafiekopties',
	'ANALYTICS.EDIT_CHART': 'Grafiek bewerken',
	'ANALYTICS.CREATE_NEW_CHART': 'Nieuwe grafiek maken',
	'ANALYTICS.DISCLAIMER': 'Disclaimer: ',
	'ANALYTICS.CREATE_NEW_CHART_DISCLAIMER': 'Grafiekdata worden door meerdere systemen in verschillende tijdsintervallen verstrekt. De data in de grafieken geven de totalen bij benadering weer. Data worden weergegeven in UTC-0. Definitieve data over gefactureerde services zijn beschikbaar op de factuur voor de factureringscyclus.',
	'ANALYTICS.ENDPOINT_COUNT_TOTALS': 'Totalen voor het aantal eindpunten',
	'ANALYTICS.NO_USAGE_SIMS': 'SIM’s zonder verbruik',
	'ANALYTICS.TOTAL_SMS_USAGE': 'Totaal sms-verbruik',
	'ANALYTICS.TOTAL_DATA_USAGE': 'Totaal dataverbruik',
	'ANALYTICS.TOTAL_VOICE_USAGE': 'Total Voice Usage',
	'ANALYTICS.ESTIMATED_AVERAGE_USAGE': 'Geschat gemiddeld verbruik',
	'ANALYTICS.NO_CATEGORIES': 'Er zijn geen categorieën beschikbaar voor deze account',
	'ANALYTICS.LINE_GRAPH': 'Lijngrafiek',
	'ANALYTICS.PIE_CHART': 'Cirkeldiagram',
	'ANALYTICS.BAR_GRAPH': 'Staafdiagram',
	'ANALYTICS.WORLD_MAP_GRAPH': 'Wereldkaartgrafiek',
	'ANALYTICS.PLATFORM': 'Platform',
	'ANALYTICS.ACCESSTECHNOLOGY': 'Toegangstechnologie',
	'ANALYTICS.OPERATOR': 'Operator',
	'ANALYTICS.RATEPLAN': 'Tariefplan',
	'ANALYTICS.COUNTRYCODE': 'Land',
	'ANALYTICS.SIM_STATE': 'SIM-status',
	'ANALYTICS.DATE_RANGE': 'DATUMBEREIK',
	'ANALYTICS.TODAY': 'Vandaag',
	'ANALYTICS.PREVIOUS_DAY': 'Vorige dag',
	'ANALYTICS.LAST_10_DAYS': 'Afgelopen 10 dagen',
	'ANALYTICS.LAST_30_DAYS': 'Afgelopen 30 dagen',
	'ANALYTICS.CUSTOM_RANGE': 'Aangepast bereik',
	'ANALYTICS.CYCLE': 'CYCLUS',
	'ANALYTICS.CURRENT_CYCLE_TO_DATE': 'Huidige cyclus tot op heden',
	'ANALYTICS.HISTORICAL': 'Historisch',
	'ANALYTICS.M2M_PLATFORM_TITLE': 'M2M-PLATFORM',
	'ANALYTICS.M2M_PLATFORM': '{platform}',
	'ANALYTICS.INCREMENT_BY': 'VERHOGEN MET',
	'ANALYTICS.DAY': 'Dag',
	'ANALYTICS.WEEK': 'Week',
	'ANALYTICS.MONTH': 'Maand',
	'ANALYTICS.SHOW': 'WEERGEVEN',
	'ANALYTICS.SUM_TOTALS': 'Totalen',
	'ANALYTICS.AVERAGE_TOTALS': 'Gemiddelde totalen',
	'ANALYTICS.CHOOSE_FILTERS_ON_ANALYTICS_MODAL': 'Selecteer een categorie in de eerste stap',
	'ANALYTICS.FILTERS': '{filter}',
	'ANALYTICS.FILTER_LABEL': '{filter}',
	'ANALYTICS.NO_FILTERS_LABEL': '{filtersLabel}',
	'ANALYTICS.NO_FILTERS_ON_ANALYTICS_MODAL': 'Er zijn geen filters beschikbaar voor deze categorie',
	'ANALYTICS.CUSTOMIZE_CHART_DATA': 'Grafiekdata aanpassen',
	'ANALYTICS.EXPORT_CHART_DATA': 'Grafiekdata exporteren',
	'ANALYTICS.DOWNLOAD_CHART_AS_PNG': 'PNG downloaden',
	'ANALYTICS.REMOVE_CHART': 'Grafiek verwijderen',
	'ANALYTICS.ADD_WIDGET_BUTTON': 'Grafiek toevoegen',
	'ANALYTICS.NO_ATTRIBUTES': 'Er zijn voor deze account geen attributen beschikbaar om op te groeperen',
	'ANALYTICS.MOVING_AVERAGE': 'Voortschrijdend gemiddelde',
	'ANALYTICS.SERIES_ID': '{id}',
	'ANALYTICS.SHOW_SUMMARY': 'Overzicht totalen weergeven',
};
