import { Status } from '../../../../utils/constants';

/*  GET SECURITY FEATURES  */
export const getSecurityFeaturesRequest = (state) =>
	state.securityFeatures.securityFeatures.getSecurityFeatures.status ===
	Status.PENDING;
export const getSecurityFeaturesFail = (state) =>
	state.securityFeatures.securityFeatures.getSecurityFeatures.status ===
	Status.FAIL;
export const getSecurityFeaturesSuccess = (state) =>
	state.securityFeatures.securityFeatures.getSecurityFeatures.data;

/*  EDIT SECURITY FEATURES  */
export const editSecurityFeaturesRequest = (state) =>
	state.securityFeatures.securityFeatures.editSecurityFeatures.status ===
	Status.PENDING;
export const editSecurityFeaturesFail = (state) =>
	state.securityFeatures.securityFeatures.editSecurityFeatures.status ===
	Status.FAIL;
export const editSecurityFeaturesSuccess = (state) =>
	state.securityFeatures.securityFeatures.editSecurityFeatures.status ===
	Status.DONE;

/*  EDIT SECURITY FEATURE  */
export const editSecurityFeatureRequest = (state) =>
	state.securityFeatures.securityFeatures.editSecurityFeature.status ===
	Status.PENDING;
export const editSecurityFeatureFail = (state) =>
	state.securityFeatures.securityFeatures.editSecurityFeature.status ===
	Status.FAIL;
export const editSecurityFeatureSuccess = (state) =>
	state.securityFeatures.securityFeatures.editSecurityFeature.status ===
	Status.DONE;

/*  GET SECURITY FEATURES STATUSES */
export const getSecurityFeaturesStatusRequest = (state) =>
	state.securityFeatures.securityFeatures.getStatuses.status === Status.PENDING;
export const getSecurityFeaturesStatusFail = (state) =>
	state.securityFeatures.securityFeatures.getStatuses.status === Status.FAIL;
export const getSecurityFeaturesStatuseSuccess = (state) =>
	state.securityFeatures.securityFeatures.getStatuses.data;

/*  ADD SECURITY FEATURES  */
export const addSecurityFeatureRequest = (state) =>
	state.securityFeatures.securityFeatures.addSecurityFeature.status ===
	Status.PENDING;
export const addSecurityFeatureFail = (state) =>
	state.securityFeatures.securityFeatures.addSecurityFeature.status ===
	Status.FAIL;
export const addSecurityFeatureSuccess = (state) =>
	state.securityFeatures.securityFeatures.addSecurityFeature.status ===
	Status.DONE;
export const selectedItems = (state) =>
	state.securityFeatures.securityFeatures.addSecurityFeature.selectedItems;
