import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../lib/DigitalComponents/Button';
import Modal from '../../../../lib/DigitalComponents/DSModal/Modal';
import ModalBody from '../../../../lib/DigitalComponents/DSModal/ModalBody';
import ModalHeader from '../../../../lib/DigitalComponents/DSModal/ModalHeader';
import ModalFooter from '../../../../lib/DigitalComponents/DSModal/ModalFooter';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import styles from './ReleaseNotesPublishModal.scss';

const Prompt = ImageSelector(CurrentContext.theme, 'svgs/alert.svg');

function DeleteRuleModal({ onClick, releaseVersion, onCancel, show }) {
	return (
		<Modal data-spec="dashboard-prompt-modal" show={show} onClose={onCancel}>
			<ModalHeader
				title={
					<FormattedMessage
						id="RELEASE_NOTES.PUBLISH_RELEASE_NOTES"
						defaultMessage="Publish Release Notes"
					/>
				}
			/>
			<ModalBody className={styles.modal__body}>
				<div data-spec="body-message" className={styles.body}>
					<div className={styles.modal_message}>
						<FormattedMessage
							id="RELEASE_NOTES.RELEASE_NOTES_MODAL_MESSAGE"
							defaultMessage="You are about to publish Release Notes {releaseVersion}. Are you sure you wish to do this?"
							values={{ releaseVersion }}
						/>
					</div>
				</div>
				<div>
					<Prompt />
				</div>
			</ModalBody>
			<ModalFooter data-spec="ds-modal-footer">
				<div data-spec="buttons">
					<Button
						onClick={onCancel}
						variant="link"
						label={
							<FormattedMessage
								id="RELEASE_NOTES.CANCEL"
								defaultMessage="Cancel"
							/>
						}
					/>
					<Button
						dataSpec="go-to-reports"
						variant="primary"
						onClick={() => {
							onClick();
							onCancel();
						}}
						label={
							<FormattedMessage
								id="RELEASE_NOTES.PUBLISH"
								defaultMessage="Publish"
							/>
						}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
}

const { func, bool, string } = PropTypes;

DeleteRuleModal.propTypes = {
	onCancel: func,
	show: bool,
	onClick: func,
	releaseVersion: string
};

export default DeleteRuleModal;
