import { connect } from 'react-redux';
import DesktopView from './Desktop';
import TabletView from './Tablet';
import MobileView from './Mobile';

import {
	closeFilterModal,
	getFilters,
	setDataForFiltersInit
} from '../../redux/filterModal/actions';

import { setFilterData } from '../../redux/filterModal/filterData/actions';
import { setOriginalFilter } from '../../redux/filterModal/originalFilter/actions';

import { anyModalClosed } from '../../redux/modals/actions';

import {
	isFilterModalOpen,
	getFiltersRequest,
	getFiltersFail,
	getFiltersSuccess
} from '../../redux/filterModal/selectors';

import filterDataSuccess from '../../redux/filterModal/filterData/selectors';

import originalFilterSuccess from '../../redux/filterModal/originalFilter/selectors';

import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import resetUserSettings from '../../../../redux/user/resetUserSettings/actions';

import { getAllUserSettings } from '../../../../redux/user/getUserData/selectors';
import { setPaginationInit } from '../../redux/pagination/actions';

const mapStateToProps = (state) => ({
	filterModalOpened: isFilterModalOpen(state),
	filtersRequest: getFiltersRequest(state),
	filtersFail: getFiltersFail(state),
	filters: getFiltersSuccess(state),
	filterData: filterDataSuccess(state),
	originalFilter: originalFilterSuccess(state),
	userSettings: getAllUserSettings(state)
});
const mapDispatchToProps = (dispatch) => ({
	dismissModal: () => {
		dispatch(closeFilterModal());
		dispatch(anyModalClosed());
	},
	getFilters: (params) => dispatch(getFilters(params)),
	setFilterData: (filterData) => dispatch(setFilterData(filterData)),
	setOriginalFilter: (filterData) => dispatch(setOriginalFilter(filterData)),
	setDataForFiltersInit: () => dispatch(setDataForFiltersInit()),
	createUserSettings: (data) => dispatch(createUserSettings(data)),
	resetUserSettings: (id) => dispatch(resetUserSettings(id)),
	updateUserSettings: (data, id) => dispatch(updateUserSettings(data, id)),
  setPaginationInit: () => dispatch(setPaginationInit())
});

export const Tablet = connect(mapStateToProps, mapDispatchToProps)(TabletView);
export const Mobile = connect(mapStateToProps, mapDispatchToProps)(MobileView);
export default connect(mapStateToProps, mapDispatchToProps)(DesktopView);
