import React, { PureComponent } from 'react';
import {
	objectOf,
	string,
	func,
	object,
	bool,
} from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import FieldGroup from '../../../../lib/DigitalComponents/FieldGroup';
import { noWhiteSpaceOnly } from '../../../../utils/validators';
import Button from '../../../../lib/DigitalComponents/Button';
import Loader from '../../../../lib/DigitalComponents/Loader';
import { UserAllowedToAccess } from '../../../../utils/AuthSelector';

import styles from './Notes.scss';

const NotesRWComponent = UserAllowedToAccess(['system.senotes_rw']);

const validate = (values, { intl }) => {
	const errors = {};

	if (intl === undefined) {
		return errors;
	}
	if (!values.seNote) {
		errors.seNote = intl.formatMessage({
			id: 'REQUIRED',
			defaultMessage: 'Required',
		});
	} else if (values.seNote.length > 2000) {
		errors.seNote = intl.formatMessage({
			id: 'MAX_CHARACTERS_EXCEEDED',
			defaultMessage: 'Character limit exceeded',
		});
	} else if (!values.seNote.replace(/\s/g, '').length) {
		errors.seNote = intl.formatMessage({
			id: 'NO_ONLY_WHITE_SPACE',
			defaultMessage: 'Cannot be only white spaces',
		});
	}

	return errors;
};

class Notes extends PureComponent {
	componentDidMount() {
		const {
			endpoint,
			getNote,
			match: {
				params: { iccid }
			}
		} = this.props;

		if (endpoint) {
			getNote(iccid, endpoint.m2mAccountId);
		}
	}

	deleteNote = () => {
		const { endpoint, note, updateNote, getSingleEndpoint } = this.props;
		const tempNote = note;

		delete tempNote.messageId;
		updateNote(
			endpoint.endpointName,
			endpoint.m2mAccountId,
			tempNote,
			'delete'
		);
		getSingleEndpoint(endpoint.iccid, endpoint.networkType);
		window.scrollTo(0, 0);
	};

	submitNote = (values) => {
		const { note, updateNote, endpoint, getSingleEndpoint } = this.props;
		const flag = note.seNote ? 'patch' : 'post';

		updateNote(endpoint.endpointName, endpoint.m2mAccountId, values, flag);
		getSingleEndpoint(endpoint.iccid, endpoint.networkType);
		window.scrollTo(0, 0);
	};

	render() {
		const {
			intl,
			handleSubmit,
			getNotePending,
			valid,
			dirty,
			note
		} = this.props;

		if (getNotePending) {
			return (
				<Loader data-spec="notes-loader" />
			);
		}

		return (
			<div data-spec="notes" className={styles.notesContainer}>
				<div>
					<span>
						<FormattedMessage
							id="SINGLE_ENDPOINT.NOTE"
							defaultMessage="Note"
						/>
					</span>
				</div>
				<form onSubmit={handleSubmit(this.submitNote)}>
					<Field
						name="seNote"
						component={FieldGroup}
						props={{
							componentClass: 'textarea',
							dataSpec: 'notes-textarea',
							placeholder: intl.formatMessage({
								id: 'NOTE_TEXTAREA_PLACEHOLDER',
								defaultMessage: 'Enter your note here (2000 characters max)',
							}),
						}}
						validate={[noWhiteSpaceOnly]}
					/>
					<NotesRWComponent>
						<div className={styles.notesButton}>
							<Button
								type="submit"
								label={intl.formatMessage({
									id: 'SAVE_NOTE',
									defaultMessage: 'Save Note',
								})}
								variant="primary"
								disabled={!dirty || !valid}
							/>
							<Button
								onClick={this.deleteNote}
								label={intl.formatMessage({
									id: 'DELETE_NOTE',
									defaultMessage: 'Delete Note',
								})}
								variant="primary"
								className={
									(!note || (note && !note.seNote))
									&& styles.hidden_button
								}
								disabled={!note || (note && !note.seNote)}
							/>
						</div>
					</NotesRWComponent>
				</form>
			</div>
		);
	}
}

Notes.propTypes = {
	intl: object,
	handleSubmit: func,
	endpoint: object,
	getNote: func,
	getNotePending: bool,
	note: objectOf(string),
	updateNote: func,
	getSingleEndpoint: func,
	valid: bool,
	dirty: bool,
	match: object,
};
Notes.defaultProps = {
	intl: {},
	handleSubmit: undefined,
	endpoint: {},
	getNote: undefined,
	getNotePending: false,
	note: {},
	updateNote: undefined,
	getSingleEndpoint: undefined,
	dirty: false,
	valid: false,
	match: {},
};

export default reduxForm({
	form: 'notes',
	validate,
	enableReinitialize: true,
})(injectIntl(Notes));
