import { connect } from 'react-redux';
import RuleHistory from './RuleHistory';

import { getRuleHistory } from '../../redux/actions';

import {
	anyModalClosed,
	anyModalOpened
} from '../../../Shared/redux/modals/actions';

import {
	getRuleHistoryRequest,
	getRuleHistoryFail,
	getRuleHistorySuccess
} from '../../redux/selectors';

const mapStateToProps = (state) => ({
	ruleHistoryRequest: getRuleHistoryRequest(state),
	ruleHistoryFail: getRuleHistoryFail(state),
	ruleHistory: getRuleHistorySuccess(state)
});
const mapDispatchToProps = (dispatch) => ({
	getRuleHistory: (data) => dispatch(getRuleHistory(data)),
	anyModalOpened: () => dispatch(anyModalOpened()),
	anyModalClosed: () => dispatch(anyModalClosed())
});

export default connect(mapStateToProps, mapDispatchToProps)(RuleHistory);
