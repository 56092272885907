import { combineReducers } from 'redux';

import globalReducer from '../../../../redux/global/globalReducer';

import { GET_COMPANY_REPORTS, EDIT_COMPANY_REPORTS } from './constants';

export default combineReducers({
	getCompanyReports: globalReducer(GET_COMPANY_REPORTS),
	editCompanyReports: globalReducer(EDIT_COMPANY_REPORTS)
});
