export default {
	'TICKETING.YES': 'Ja',
	'TICKETING.NO': 'Nein',
	'TICKETING.VIEW_NOTE': 'Anmerkung anzeigen',
	'TICKETING.TICKET_ID': 'Ticket-ID',
	'TICKETING.CATEGORY_NAME': 'Kategorie',
	'TICKETING.DATE_CREATED': 'Erstellungsdatum',
	'TICKETING.CREATED_BY': 'Erstellt von',
	'TICKETING.TICKETING_STATUS': 'Ticketing-Status',
	'TICKETING.LAST_UPDATE': 'Letzte Aktualisierung',
	'TICKETING.SEVERITY': 'Schweregrad',
	'TICKETING.SUBMISSION_LOCATION': 'Ort der Einreichung',
	'TICKETING.UPDATE_NOTE': 'Anmerkung aktualisieren',
	'TICKETING.STATUS': 'Status',
	'TICKETING.SUBMITED_BY': 'Abgesendet von',
	'TICKETING.ADD_TICKET_ADDITIONAL_INFO': 'Bezieht sich Ihr Ticket auf einen spezifischen Endpunkt? Falls ja, wechseln Sie zum Detailbildschirm dieses Endpunkts und klicken Sie für diesen Endpunkt-Link auf „Support erhalten“. Ansonsten füllen Sie das nachstehende Formular aus.',
	'TICKETING.ISSUE': 'Problem',
	'TICKETING.TICKET_DETAILS': 'Ticket-Details',
	'TICKETING.CATEGORY': 'Kategorie',
	'TICKETING.SELECT_CATEGORY': 'Kategorie auswählen',
	'TICKETING.SUBCATEGORY_OPTIONAL': 'Unterkategorie (optional)',
	'TICKETING.SUBCATEGORY': 'Unterkategorie',
	'TICKETING.ROAMING_ISSUE': 'Roaming-Problem',
	'TICKETING.PRIORITY': 'Priorität',
	'TICKETING.ENDPOINT': 'Endpunkt',
	'TICKETING.ICCID': 'ICCID',
	'TICKETING.IMSI': 'IMSI',
	'TICKETING.MSISDN': 'MSISDN',
	'TICKETING.ENDPOINT_LOCATION': 'Endpunkt Standort',
	'TICKETING.ENDPOINT_COUNTRY': 'Endpunkt Land',
	'TICKETING.CLEAR_ENDPOINT_PARAMETARS': 'Endpunktparameter löschen',
	'TICKETING.APN': 'APN',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS': 'Endpunktparameter abrufen',
	'TICKETING.FETCH_ENDPOINT_PARAMETERS_DESC': 'Sie können mithilfe dieser Option Endpunktparameter abrufen, indem Sie den ICCID-, IMSI- oder MSISDN-Wert eingeben.',
	'TICKETING.ENDPOINT_STREET': 'Endpunkt Straße',
	'TICKETING.ENDPOINT_STREET_ADDRESS': 'Straße/Hausnr.',
	'TICKETING.ZIP_CODE': 'Postleitzahl',
	'TICKETING.ENDPOINT_STATE': 'Endpunkt Status',
	'TICKETING.DETAILS': 'Details',
	'TICKETING.BARS_SHOWN': 'Angezeigte Balken',
	'TICKETING.ENDPOINT_CITY': 'Endpunkt Ort',
	'TICKETING.ENDPOINT_MODEL': 'Endpunktmodell',
	'TICKETING.PLACEHOLDER_MODEL': 'AT&T Z431',
	'TICKETING.WAS_TROUBLESHOOTING_DONE': 'Wurde eine Fehlersuche durchgeführt?',
	'TICKETING.PROBLEM_DESCRIPTION': 'Problembeschreibung',
	'TICKETING.PROVIDE_A_SHORT_DESCRIPTION_OF_THE_PROBLEM_255_CHARACTERS_MAX': 'Geben Sie eine kurze Beschreibung des Problems an, max. 255 Zeichen',
	'TICKETING.PROVIDE_ADDITIONAL_ISSUE_DETAILS_IF_NEEDED_2000_CHARACTERS_MAX': 'Geben Sie ggf. weitere Problemdetails an, max. 2000 Zeichen',
	'TICKETING.GPRS': 'GPRS',
	'TICKETING.NOTES': 'Anmerkungen',
	'TICKETING.ISSUE_DETAILS': 'Problemdetails',
	'TICKETING.CUSTOMER': 'Kunde',
	'TICKETING.ACCOUNT_NUMBER': 'Kontonummer',
	'TICKETING.ACCOUNT_NAME': 'Kontoname',
	'TICKETING.PHONE': 'Telefon',
	'TICKETING.CONTACT_PHONE': 'Telefon Kontakt',
	'TICKETING.CONTACT_EMAIL': 'E-Mail Kontakt',
	'TICKETING.ATTACH_FILE': 'Datei anhängen',
	'TICKETING.ATTACHMENT_FILE_NAME_TOO_LONG': 'Dateiname ist zum Hochladen zu lang. Maximale Dateinamenlänge beträgt 25 Zeichen.',
	'TICKETING.EMAIL': 'E-Mail',
	'TICKETING.TICKETING': 'Ticketing',
	'TICKETING.ADD_TICKET': 'Ticket hinzufügen',
	'TICKETING.SEARCH_RESULTS': 'Suchergebnisse',
	'TICKETING.NO_TICKETS_AVAILABLE': 'Keine Tickets verfügbar',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_TITLE': 'Einstellungen der Ticket-Tabelle erfolgreich geändert!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_SUCCESS_MESSAGE': 'Einstellungen der Ticket-Tabelle wurden erfolgreich aktualisiert.',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_TITLE': 'Fehler beim Ändern von Ticket-Tabellenspalten!',
	'TICKETING.TABLE_TICKETS_COLUMNS_CHANGE_ERROR_MESSAGE': 'Die Ticket-Tabellenspalten konnten zu diesem Zeitpunkt nicht aktualisiert werden. Bitte versuchen Sie es noch einmal.',
	'TICKETING.TICKET_SUCCESSFULLY_EDITED': 'Ticket wurde erfolgreich bearbeitet.',
	'TICKETING.ERROR_WHILE_EDITING_TICKET': 'Fehler beim Bearbeiten des Tickets. Bitte versuchen Sie es später noch einmal.',
	'TICKETING.ATTACH_FILE_TOOLTIP': 'Dateiname sollte keine Leerzeichen enthalten.',
	'TICKETING.NEW_TICKET': 'Neues Ticket',
	'TICKETING.OFF': 'Aus',
	'TICKETING.ON': 'Ein',
	'TICKETING.EMAIL_NOTIFICATIONS': 'E-Mail-Benachrichtigungen',
	'TICKETING.CONTACT_NAME': 'Kontaktname',
	'TICKETING.EXTERNAL_LAST_UPDATED_BY': 'Letzte Aktualisierung durch',
	'TICKETING.EXTERNAL_LAST_UPDATED_DATE': 'Datum/Uhrzeit letzte Aktualisierung',
	'TICKETING.SOMETHING_WENT_WRONG': 'Etwas ist schief gelaufen, bitte versuchen Sie es noch einmal.',
	'TICKETING.CANCEL_TICKET': 'Ticket abbrechen',
	'TICKETING.ADD_NOTE': 'Anmerkung hinzufügen',
	'TICKETING.TICKET_SUBMITTED_SUCCESSFULLY': 'Ticket erfolgreich eingereicht!',
	'TICKETING.TICKET_COULD_NOT_BE_SUBMITTED': 'Ticket konnte nicht erfolgreich eingereicht werden!',
	'TICKETING.CREATED_AT': 'Erstellt am/um',
	'TICKETING.GO_BACK_TO_TICKETING': 'Zurück zu Ticketing',
	//  TICKET-KATEGORIEN
	'TICKETING.CATEGORY_BILLING_NON_TECHNICAL_ISSUE': 'Abrechnung, nichttechnisches Problem',
	'TICKETING.CATEGORY_PROVISIONING': 'Bereitstellung',
	'TICKETING.CATEGORY_DATA': 'Daten',
	'TICKETING.CATEGORY_DOCUMENTATION': 'Dokumentation',
	'TICKETING.CATEGORY_DUPLICATE': 'Duplikat',
	'TICKETING.CATEGORY_BILLING': 'Abrechnung',
	'TICKETING.CATEGORY_NOTIFICATION': 'Benachrichtigung',
	'TICKETING.CATEGORY_OTHER': 'Sonstiges',
	'TICKETING.CATEGORY_PRODUCT_ENHANCEMENT_REQUEST': 'Produktverbesserungsanforderung',
	'TICKETING.CATEGORY_PROJECT': 'Projekt',
	'TICKETING.CATEGORY_SALES_MARKETING_ISSUE': 'Vertriebs-/Marketingproblem',
	'TICKETING.CATEGORY_QUESTION': 'Frage',
	'TICKETING.CATEGORY_SERVICE_REQUEST': 'Serviceanforderung',
	'TICKETING.CATEGORY_TECHNICAL_ISSUES': 'Technische Probleme',
	'TICKETING.CATEGORY_USER_ACCOUNT_ADMINISTRATION': 'Benutzer-/Kontoadministration',
	'TICKETING.CATEGORY_CONNECTIVITY_ISSUE': 'Verbindungsproblem',
	'TICKETING.CATEGORY_EQUIPMENT': 'Geräte',
	'TICKETING.CATEGORY_GENERAL_QUESTION': 'Allgemeine Frage',
	'TICKETING.CATEGORY_LOST_STOLEN_DEVICE': 'Verlorenes/Gestohlenes Gerät',
	'TICKETING.CATEGORY_PORT_REQUEST': 'Port-Anforderung',
	'TICKETING.CATEGORY_PRODUCT_RELATED': 'Produktbezogen',
	'TICKETING.CATEGORY_REPORTING': 'Reporting',
	'TICKETING.CATEGORY_SUGGESTION_RECOMMENDATION': 'Vorschlag/Empfehlung',
	'TICKETING.CATEGORY_WEBSITE_ISSUE': 'Website-Problem',
	//  TICKETSTATUS
	'TICKETING.STATUS_QUEUED': 'In Warteschlange',
	'TICKETING.STATUS_ACTIVE': 'Aktiv',
	'TICKETING.STATUS_DEFERRED': 'Zurückgestellt',
	'TICKETING.STATUS_PENDING_CUSTOMER_RESPONSE': 'Kundenantwort ausstehend',
	'TICKETING.STATUS_CLOSED': 'Geschlossen',
	'TICKETING.STATUS_CANCEL': 'Abbrechen',
	'TICKETING.STATUS_CUSTOMER_CONTESTED': 'Vom Kunden angefochten',
	'TICKETING.STATUS_CLEARED': 'Gelöscht',
	'TICKETING.STATUS_READY_TO_CLOSE': 'Bereit zum Schließen',
	'TICKETING.STATUS_PENDING_ATT_RESPONSE': 'ATT-Antwort ausstehend',
	//  TICKET-SCHWEREGRAD
	'TICKETING.SEVERITY_CRITICAL': 'Kritisch',
	'TICKETING.SEVERITY_MAJOR': 'Schwerwiegend',
	'TICKETING.SEVERITY_MINOR': 'Geringfügig',
	//  TICKET - RECHNUNGS-UNTERKATEGORIEN
	'TICKETING.ACTIVATION_FEE': 'Aktivierungsgebühr',
	'TICKETING.AIRTIME_USAGE': 'Sprechzeitnutzung',
	'TICKETING.BASIC_ACCOUNT_QUESTIONS': 'Grundlegende Kontofragen',
	'TICKETING.BILLING_DISPUTES': 'Abrechnungsdispute',
	'TICKETING.BILLING_FILES_ISSUES': 'Abrechnungsarchivierungsprobleme',
	'TICKETING.BILL_RECONCILIATION': 'Abrechnungsabstimmung',
	'TICKETING.CUSTOMER_EDUCATION': 'Kundenaufklärung',
	'TICKETING.DATA_USAGE_CANADA': 'Datennutzung Kanada',
	'TICKETING.DATA_USAGE_INTERNATIONAL': 'Datennutzung international',
	'TICKETING.DATA_USAGE_OFF_NET': 'Datennutzung netzextern',
	'TICKETING.DATA_USAGE_ON_NET': 'Datennutzung netzintern',
	'TICKETING.DELAYED_BILLING_OR_INVOICE': 'Verzögerte Abrechnung oder Rechnung',
	'TICKETING.DISCOUNT': 'Rabatt',
	'TICKETING.FRAUD_INQUIRY_OR_PROBLEM': 'Betrugsuntersuchung oder -problem',
	'TICKETING.GSMS': 'GSMS',
	'TICKETING.INVOICE_PRINT_ISSUES': 'Rechnungsdruckprobleme',
	'TICKETING.MONTHLY_RECURRING_CHARGE': 'Monatlich wiederkehrende Kosten',
	'TICKETING.ONE_TIME_CHARGE_ADJUSTMENT': 'Einmalige Kosten – Anpassung',
	'TICKETING.OTHER': 'Sonstiges',
	'TICKETING.PAYMENT_ISSUES': 'Zahlungsprobleme',
	'TICKETING.PRORATED_CHARGES': 'Anteilige Kosten',
	'TICKETING.REACTIVATION_FEE': 'Reaktivierungsgebühr',
	'TICKETING.SMS_TEXT_CANADA': 'SMS/Textnachrichten Kanada',
	'TICKETING.SMS_TEXT_INTERNATIONAL': 'SMS/Textnachrichten international',
	'TICKETING.SMS_TEXT_OFF_NET': 'SMS/Textnachrichten netzextern',
	'TICKETING.SMS_TEXT_ON_NET': 'SMS/Textnachrichten netzintern',
	'TICKETING.STATUS_INVOICE': 'Status Rechnung',
	'TICKETING.TAXES_AND_SURCHARGES': 'Steuern und Zuschläge',
	'TICKETING.DATA': 'Daten',
	'TICKETING.MESSAGING_SMS_MMS_': 'Messaging (SMS/MMS)',
	'TICKETING.VOICE': 'Voice',
	'TICKETING.CLARIFICATION': 'Klärung',
	'TICKETING.PREPAID': 'Prepaid',
	'TICKETING.USAGE_INQUIRY': 'Nutzungsanfrage',
	//  TICKET - NICHTTECHNISCHES PROBLEM, UNTERKATEGORIEN
	'TICKETING.BILLING_QUESTION': 'Abrechnungsfrage',
	'TICKETING.INVOICE_RELATED_ISSUE': 'Rechnungsbezogenes Problem',
	'TICKETING.RATE_PLAN_RELATED_ISSUE': 'Tarifplanbezogenes Problem',
	'TICKETING.USAGE_RELATED_ISSUE': 'Nutzungsbezogenes Problem',
	//  TICKET - DATEN, UNTERKATEGORIEN
	'TICKETING.2G_3G_MIX': '2G und 3G Mix',
	'TICKETING.2G_ONLY': 'NUR 2G',
	'TICKETING.3G_ONLY': 'NUR 3G',
	'TICKETING.CONNECTED_BUT_NOT_PASSING_DATA': 'Verbunden, aber keine Datenweiterleitung',
	'TICKETING.FRAME_RELAY_PVC': 'Frame Relay/PVC',
	'TICKETING.GPRS_EDGE': 'GPRS/EDGE',
	'TICKETING.LATENCY_ISSUE': 'Latenzproblem',
	'TICKETING.MMS': 'MMS',
	'TICKETING.ROAMING_DOMESTIC': 'Roaming/Inland',
	'TICKETING.ROAMING_INTERNATIONAL': 'Roaming/international',
	'TICKETING.SIGNAL_ISSUES': 'Signalprobleme',
	'TICKETING.SMPP_BIND': 'SMPP-Bind',
	'TICKETING.SMS': 'SMS',
	'TICKETING.UMTS_3G': 'UMTS/3G',
	'TICKETING.VPN': 'VPN',
	//  TICKET - DOKUMENTATION, UNTERKATEGORIEN
	'TICKETING.FEEDBACK': 'Feedback',
	'TICKETING.NEW_REQUEST': 'Neue Anforderung',
	// TICKET - RECHNUNGS-UNTERKATEGORIEN
	'TICKETING.COMPATIBILITY': 'Kompatibilität',
	'TICKETING.DEVICE_UNLOCK': 'Gerät entsperren',
	'TICKETING.ORDERING': 'Bestellung',
	// TICKET - VERLORENES/GESTOHLENES GERÄT, UNTERKATEGORIEN
	'TICKETING.BLOCK_DEVICE': 'Gerät blockieren',
	'TICKETING.FRAUD_REPORT': 'Betrugsbericht',
	'TICKETING.UNBLOCK_DEVICE': 'Geräteblockierung aufheben',
	//  TICKET - BENACHRICHTIGUNG, UNTERKATEGORIEN
	'TICKETING.INCIDENT_NOTIFICATION': 'Vorfallbenachrichtigung',
	'TICKETING.SCHEDULED_MAINTENANCE': 'Planmäßige Wartung',
	//  TICKET - PORT-ANFRAGE, UNTERKATEGORIEN
	'TICKETING.NETWORK_CHANNEL_INTERFACE_NCI_': 'Network Channel Interface (NCI)',
	'TICKETING.WIRELESS': 'Kabellos',
	'TICKETING.WIRELINE': 'Festnetz',
	//  TICKET - PRODUKTBEZOGEN, UNTERKATEGORIEN
	'TICKETING.QUESTION': 'Frage',
	//  TICKET - BEREITSTELLUNG, UNTERKATEGORIEN
	'TICKETING.BATCH_FILES': 'Batch-Dateien',
	'TICKETING.CREATE_NEW_SIM_PROFILE': 'Neues SIM-Profil erstellen',
	'TICKETING.EQUIPMENT_INQUIRY': 'Ausrüstungsanfrage',
	'TICKETING.SINGLE_SIM_PROVISIONING': 'Einzel-SIM-Bereitstellung',
	'TICKETING.WEB_SERVICE': 'Webdienst',
	'TICKETING.PROVISIONING_SIMS_FEATURES': 'SIM-/Funktionsbereitstellung',
	'TICKETING.ACTIVATION': 'Aktivierung',
	'TICKETING.BATCH_BULK_CHANGE': 'Batch/Bulk-Änderung',
	'TICKETING.CALL_FORWARDING': 'Anrufweiterleitung',
	'TICKETING.CHANGE_COMMUNICATION_PLAN': 'Kommunikationsplan ändern',
	'TICKETING.CHANGE_RATE_PLAN': 'Tarifplan ändern',
	'TICKETING.CHANGE_SIM_STATUS': 'SIM-Status ändern',
	'TICKETING.TRANSFER_SIM_BETWEEN_ACCOUNTS': 'SIM zwischen Konten übertragen',
	//  TICKET - FRAGE, UNTERKATEGORIEN
	'TICKETING.AUTOMATION_RULE_QUESTION': 'Automatisierungsregelfrage',
	'TICKETING.CONTROL_CENTER_QUESTION': 'Control Center-Frage',
	'TICKETING.COVERAGE_QUESTION': 'Abdeckungsfrage',
	'TICKETING.GENERAL_QUESTION': 'Allgemeine Frage',
	'TICKETING.OUTAGE_QUESTION': 'Ausfallfrage',
	'TICKETING.ROAMING_QUESTION': 'Roaming-Frage',
	'TICKETING.JPO_COVERAGE': 'JPO-Abdeckung',
	//  TICKET - VERTRIES-/MARKETINGPROBLEM, UNTERKATEGORIEN
	'TICKETING.CUSTOMER_INQUIRY': 'Kundenanfrage',
	'TICKETING.DEV_KIT': 'Dev Kit',
	'TICKETING.MARKETING_INQUIRY': 'Marketinganfrage',
	//  TICKET - SERVICEANFRAGE, UNTERKATEGORIEN
	'TICKETING.ACCOUNT_TERMINATION': 'Kontostornierung',
	'TICKETING.API_SETUP': 'API-Einrichtung',
	'TICKETING.BILLING_ZONE_CREATION_MODIFICATION': 'Erstellung/Änderung von Abrechnungszonen',
	'TICKETING.CC_ACCESS_UPDATES': 'CC-Zugriff/Aktualisierungen',
	'TICKETING.COMMUNICATION_PLAN': 'Kommunikationsplan',
	'TICKETING.CUSTOM_APN': 'Benutzerdefinierter APN',
	'TICKETING.GLOBAL_SIM_SETUP_MAPPING': 'Globale SIM-Einrichtung/Zuordnung',
	'TICKETING.GPRS_VPN': 'GPRS VPN',
	'TICKETING.LEGAL_SUBPOENA': 'Juristisch/Vorladung',
	'TICKETING.LTE_MIGRATION': 'LTE-Migration',
	'TICKETING.MSISDN_UPDATES': 'MSISDN-Aktualisierungen',
	'TICKETING.NEW_ACCOUNT': 'Neues Konto',
	'TICKETING.RADIUS_CONFIG_SETUP': 'Radius-Konfig.-Einrichtung',
	'TICKETING.REPORT': 'Bericht',
	'TICKETING.RES_ROAM_ZONE_REQUEST_': 'RES (Roaming-Zonenanforderung)',
	'TICKETING.SECURESIM': 'SecureSIM',
	'TICKETING.SIM_ORDER_PROVISIONING': 'SIM-Bestellung/Bereitstellung',
	'TICKETING.TRACE': 'Verfolgen',
	//  TICKET - NICHTTECHNISCHES PROBLEM, UNTERKATEGORIEN
	'TICKETING.APPLICATION_ISSUES': 'Anwendungsprobleme',
	'TICKETING.BILLING_TECHNICAL_ISSUE': 'Abrechnung, technisches Problem',
	'TICKETING.CONNECTIVITY': 'Konnektivität',
	'TICKETING.GLOBAL_SIM_ISSUE': 'Globales SIM-Problem',
	'TICKETING.JPO_FILE_ISSUE': 'JPO-Dateiproblem',
	'TICKETING.JPO_LINK_ISSUE': 'JPO-Verknüpfungsproblem',
	'TICKETING.NOC_BRIDGE': 'NOC-Überbrückung',
	'TICKETING.CDR_FILES': 'CDR-Dateien',
	'TICKETING.JPO_REPORT': 'JPO-Bericht',
	//  TICKET - BENUTZER-/KONTOADMINISTRATION, UNTERKATEGORIEN
	'TICKETING.ADD_NEW_ACCOUNT_FUNCTIONALITY_OPTIONS': 'Neue Kontofunktionalitätsoptionen hinzufügen',
	'TICKETING.DEACTIVATE_USER': 'Benutzer deaktivieren',
	'TICKETING.ID_ISSUE': 'ID-Problem',
	'TICKETING.NEW_USER': 'Neuer Benutzer',
	'TICKETING.PASSWORD_RESET': 'Passwortzurücksetzung',
	//  TICKET - WEBSITE-PROBLEM, UNTERKATEGORIEN
	'TICKETING.AT&T_CONTROL_CENTER_ACC_': 'AT&T Control Center (ACC)',
	'TICKETING.CONNECTED_CAR_PORTAL_APOLLO': 'Angeschlossenes Kfz-Portal/Apollo',
	'TICKETING.ENTERPRISE_ON_DEMAND_EOD_': 'Enterprise On Demand (EOD)',
	'TICKETING.MULTI_NETWORK_CONNECT_MNC_': 'IoT Console (MNC)',
	// SONSTIGE
	'TICKETING.CRITICAL': 'Kritisch',
	'TICKETING.MAJOR': 'Schwerwiegend',
	'TICKETING.MINOR': 'Geringfügig',
	'TICKETING.LOW': 'Niedrig',
	'TICKETING.MEDIUM': 'Mittel',
	'TICKETING.HIGH': 'Hoch',
	'TICKETING.OUTAGE': 'Ausfall',
	// SERVICEANFRAGE, BENUTZERDEFINIERTES APN, UNTERKATEGORIEN
	'TICKETING.DYNAMIC_IP': 'Dynamische IP',
	'TICKETING.FIXED_IP': 'Feste IP',
	//  TICKET - ANWENDUNGSPROBLEM, UNTERKATEGORIEN
	'TICKETING.API': 'API',
	'TICKETING.AUTOMATION_RULE': 'Automatisierungsregel',
	'TICKETING.CC_ISSUE_OTHER': 'CC-Problem – Sonstiges',
	'TICKETING.EXPORT_AND_BATCH_UPDATE': 'Export und Batch-Aktualisierung',
	'TICKETING.LOCATION_SERVICES_3RD_PARTY': 'Standortdienste/Drittanbieter',
	'TICKETING.PROVISIONING': 'Bereitstellung',
	'TICKETING.SESSION_INFORMATION_DELAY': 'Sitzungsinformations-Verzögerung',
	'TICKETING.SPOTLIGHT': 'Spotlight',
	'TICKETING.WEBUL_ISSUES': 'WebUl-Probleme',
	//  TECHNISCHE PROBLEME VERBINDUNG, UNTERKATEGORIEN
	'TICKETING.DATA_ISSUE': 'Datenproblem',
	'TICKETING.DEVICE_ISSUE': 'Geräteproblem',
	'TICKETING.SFTP_FILE_TRANSFERS': 'sFTP/Dateiübertragungen',
	'TICKETING.SMS_ISSUE': 'SMS-Problem',
	'TICKETING.VOICE_ISSUE': 'Voice-Problem',
	//  TECHNISCHE PROBLEME, JPO-LINK-PROBLEM, UNTERKATEGORIEN
	'TICKETING.NETWORK': 'Netzwerk',
	'TICKETING.RADIUS': 'Radius',
	'TICKETING.SMPP': 'SMPP',
	'TICKETING.SS7': 'SS7',
	'TICKETING.ADD_ATTACHMENT': 'Anlage hinzufügen',
	'TICKETING.NOTE': 'Anmerkung',
	'TICKETING.LAST_UPDATED_DATE': 'Datum der letzten Ticket-Aktualisierung',
	'TICKETING.ENDPOINT_WITH_PARAMETAR': 'Endpunkt mit diesem Parameter nicht vorhanden',
	// Ticket-Benachrichtigungen
	'TICKETING.ATTACHMENT_FAIL_TITLE': 'Ihre Anfrage konnte nicht vollständig ausgeführt werden.',
	'TICKETING.ADD_TICKET_ATTACHMENT_FAIL_MESSAGE': 'Das Ticket wurde erstellt {ticketId}, die Anhänge ({attachments}) wurden jedoch nicht hochgeladen.',
	'TICKETING.EDIT_TICKET_ADD_ATTACHMENT_FAIL_MESSAGE': 'Das Ticket wurde bearbeitet {ticketId}, die Anhänge ({attachments}) wurden jedoch nicht hochgeladen.',
	'TICKETING.EDIT_TICKET_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Das Ticket wurde bearbeitet {ticketId}, die Anhänge ({attachments}) wurden jedoch nicht gelöscht.',
	'TICKETING.EDIT_TICKET_ADD_DELETE_ATTACHMENT_FAIL_MESSAGE': 'Das Ticket wurde bearbeitet {ticketId}, die Anhänge ({deleteAttachments}) wurden jedoch nicht gelöscht, und Anhänge ({uploadAttachments}) wurden nicht hochgeladen.'
};
