import { connect } from 'react-redux';
import DeleteSimSkuModal from './DeleteSimSkuModal';

import { deleteSimSku } from '../../redux/simOrdering/actions';
import { deleteSimSkuRequest } from '../../redux/simOrdering/selectors';

const mapStateToProps = (state) => ({
	deleteSimSkuRequest: deleteSimSkuRequest(state)
});

const mapDispatchToProps = (dispatch) => ({
	deleteSimSku: (id) => dispatch(deleteSimSku(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeleteSimSkuModal);
