import { createAction } from 'redux-actions';
import { get } from 'lodash';

import { localizedRoute } from '../../utils/helperFunctions';
import { showNotification } from '../../components/Shared/redux/notifications/actions';
import { getLocale } from '../../utils/constants';

const request = (prefix) => createAction(`${prefix}_REQUEST`);
const success = (prefix) => createAction(`${prefix}_SUCCESS`);
const fail = (prefix) => createAction(`${prefix}_FAIL`);
const init = (prefix) => createAction(`${prefix}_INIT`);

const globalAction = (prefix, service, params, actions) => async (dispatch) => {
  const locale = getLocale();
	const { default: messages } = await import(
		`../../localizations/${locale}`
	);

	try {
		dispatch(request(prefix)());
		const data = await service(params);
		if (actions && actions.redirect) {
			const url = actions.redirect.replace(/({.*?})/g, (match) => {
				const prop = match.replace(/{|}/g, '');
				return get(data, prop);
			});
			dispatch(localizedRoute(url));
		}
		dispatch(success(prefix)(data));
		actions && actions.init && dispatch(init(prefix)());
		actions && actions.modals && dispatch(actions.modals);
		actions &&
			(actions.notify || actions.notifySuccess) &&
			dispatch(
				showNotification({
					id: `${actions.notify || actions.notifySuccess}-success`,
					message: (actions.notifyMessageSuccess && [
						messages[actions.notifyMessageSuccess]
					]) || [messages.ACTION_COMPLETED_SUCCESSFULLY],
					type: 'success',
					notificationType: 'toaster',
					close: true,
					duration: 5000,
					actionText: actions.actionText && messages[actions.actionText],
					action: actions.action
				})
			);
	} catch (error) {
		dispatch(fail(prefix)(error));
		actions && actions.modals && dispatch(actions.modals);
		actions &&
			(actions.notify || actions.notifyFail) &&
			dispatch(
				showNotification({
					id: `${actions.notify || actions.notifyFail}-fail`,
					message: (actions.notifyMessageError && [
						messages[actions.notifyMessageError]
					]) ||
						(actions.serverError &&
							error.error &&
							error.error.resultList && [
								`${messages.ACTION_COULD_NOT_BE_COMPLETED}. ${error.error.resultList[0].message}`
							]) || [messages.ACTION_COULD_NOT_BE_COMPLETED],
					type: 'error',
					notificationType: 'toaster',
					close: false,
					actionText: actions.actionText && messages[actions.actionText],
					action: actions.action
				})
			);
		actions && actions.init && dispatch(init(prefix)());
	}
	actions && actions.final && dispatch(actions.final);
};

export const actionInit = (prefix) => async (dispatch) => {
	dispatch(init(prefix)());
};

export default globalAction;
