/* eslint-disable arrow-body-style */
import globalAction, {
	actionInit
} from '../../../../redux/global/globalAction';
import { GET_PLATFORMS } from '../constants';
import { getPlatforms as service } from '../../services/SimOrderingService';
import { getSkusInit } from '../getSKUs/actions';
import { getRestraintsInit } from '../restraints/actions';

export const getPlatforms = (params, me) => {
	return ((
		getSkusInit(),
		getRestraintsInit(),
		globalAction(GET_PLATFORMS, service, { params, me })
	));
};

export const getPlatformsInit = () => actionInit(GET_PLATFORMS);
