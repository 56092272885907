import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import hoc from '../EndpointsData';

import Table from '../../../../../lib/DigitalComponents/Table';

function Mobile(props) {
	const {
		data,
		tableHeader,
		messages,
		onCellClick,
		onOrderClick,
		orderColumn,
		handlePageClick,
		totalCount,
		forcePage,
		selectedPage,
		showPagination
	} = props;

	const renderTransferStatus = (val) => {
		const status = val.transferStatus
			? val.transferStatus.replace(/\s/g, '').toLowerCase()
			: '';
		return val.transferStatus ? (
			<FormattedMessage
				id={status}
				defaultMessage={String(val.transferStatus)
					.replace(/_/g, ' ')
					.toLowerCase()}
			/>
		) : (
			''
		);
	};

	const renderStatus = (val) => (
		<div
			className={`${val.status
				.replace(/\s/g, '_')
				.toLowerCase()} custom-option`}
		>
			<FormattedMessage id={val.status} defaultMessage={val.status} />
		</div>
	);

	const options = {
		header: tableHeader,
		tableOptions: {
			orderByData: ['all'],
			getOrderData: (val) => onOrderClick(val),
			orderColumn,
			showPagination,
			showPaginationTop: true,
			pageCount: totalCount,
			marginPagesDisplayed: 2,
			pageRangeDisplayed: 7,
			forcePage,
			onPageChange: (val) => handlePageClick(val),
			selectedPage,
			mobileRowClick: (val) => onCellClick(val)
		},
		customComponents: {
			status: {
				type: 'custom',
				component: (val) => renderStatus(val)
			},
			transferStatus: {
				type: 'custom',
				component: (val) => renderTransferStatus(val)
			}
		}
	};

	return (
		<Table
			data-spec="endpoints-table"
			data={data.resultList}
			options={options}
			messages={messages}
			mobile
		/>
	);
}

Mobile.propTypes = {
	data: PropTypes.object,
	tableHeader: PropTypes.array,
	messages: PropTypes.object,
	orderColumn: PropTypes.object,
	onCellClick: PropTypes.func,
	onOrderClick: PropTypes.func,
	handlePageClick: PropTypes.func,
	totalCount: PropTypes.number,
	forcePage: PropTypes.number,
	selectedPage: PropTypes.number,
	showPagination: PropTypes.bool
};

export default hoc(Mobile);
