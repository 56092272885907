import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isUserAllowedToAccess } from '../../../../utils/AuthSelector';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import { NetworkEntitlementsTableHeader as tableHeader } from '../../utils/constants';

import Loading from '../../../../lib/DigitalComponents/Loader';
import PageTitle from '../../../Shared/views/PageTitleView';
import NoData from '../../../Shared/views/NoDataView';

import styles from './NetworkEntitlements.scss';

const CheckIcon = ImageSelector(CurrentContext.theme, 'svgs/check-new.svg');

const NetworkEntitlements = () => (WrappedComponent) => {
	class NetworkEntitlementsComponent extends PureComponent {
		componentDidMount() {
			const {
				match: {
					params: { companyId, m2mAccountId, platformType, accountId }
				}
			} = this.props;
			this.getNetworkEntitlements(companyId, m2mAccountId, platformType);
			if (accountId) {
				this.getAccount(accountId);
			}
		}

		getAccount = (id) => {
			const { getAccount } = this.props;
			getAccount({
				id,
				additionalParams: {
					include: [
						'Company',
						'PlatformType',
						'PlatformMobileOperator',
						'PlatformSatelliteOperator'
					]
				}
			});
		};

		getNetworkEntitlements = (companyId, m2mAccountId, platformType) => {
			const { getNetworkEntitlements } = this.props;
			getNetworkEntitlements({
				companyId,
				m2mAccountId,
				platformType
			});
		};

		pushBack = () => {
			const {
				pushBack,
				match: {
					params: { parentAccountM2mId, companyId, accountId, m2mAccountId }
				}
			} = this.props;

			if (companyId) {
				if (parentAccountM2mId) {
					pushBack(
						`/companies/${companyId}/accounts/${accountId}/${m2mAccountId}/${parentAccountM2mId}`
					);
				} else {
					pushBack(`/companies/${companyId}/accounts/${accountId}`);
				}
			} else if (parentAccountM2mId) {
				pushBack(
					`/my-company/${accountId}/${m2mAccountId}/${parentAccountM2mId}`
				);
			} else {
				pushBack(`/my-company/${accountId}`);
			}
		};

		goToNetworkEntitlementDetails = (id) => {
			const {
				goToNetworkEntitlementDetails,
				match: {
					params: { companyId, accountId, platformType, m2mAccountId }
				}
			} = this.props;
			goToNetworkEntitlementDetails(
				`/companies/${companyId}/accounts/${accountId}/${platformType}/${m2mAccountId}/network-entitlements/${id}`
			);
		};

		getOptions = (target) => {
			const {
				user,
				match: {
					params: { companyId }
				}
			} = this.props;

			const isDesktop = target === 'desktop';

			const options = {
				header: tableHeader,
				tableOptions: {
					fixedFirstColumn: true,
					firstColumnMarked: true
				}
			};

			if (isDesktop) {
				options.tableOptions = {
					...options.tableOptions
				};
			}

			options.customComponents = {
				name: {
					type: 'custom',
					component: (val) => (
						<span
							className={cn(
								isUserAllowedToAccess(['system.companies_rw'], user) &&
									companyId
									? styles.clickable
									: '',
								styles.communication_profile_name
							)}
							onClick={() =>
								isUserAllowedToAccess(['system.companies_rw'], user) &&
								companyId &&
								this.goToNetworkEntitlementDetails(val.id)}
						>
							{val.name}
						</span>
					)
				},
				iotAccountName: {
					type: 'custom',
					component: (val) => val.accountInfo && val.accountInfo.platfrom
				},
				m2mAccountId: {
					type: 'custom',
					component: (val) => val.accountInfo && val.accountInfo.m2mAccountId
				},
				default: {
					type: 'custom',
					component: (val) => (
						<span>
							{!!(val.default === 'true' || val.default === true) && (
								<CheckIcon className={styles.check_icon} />
							)}
						</span>
					)
				}
			};
			return options;
		};

		render() {
			const {
				networkEntitlementsRequest,
				networkEntitlementsFail,
				networkEntitlements,
				accountRequest,
				accountFail,
				account,
      } = this.props;

			return (
				<div data-spec="network-entitlements">
					<PageTitle
						title={
							<FormattedMessage
								id="ONBOARDING.NETWORK_ENTITLEMENTS"
								defaultMessage="Network Entitlements"
							/>
						}
						pushBack={this.pushBack}
					/>
					{(networkEntitlementsRequest || accountRequest) && (
						<Loading data-spec="loading" />
					)}
					{(networkEntitlementsFail || accountFail) && null}
					{!networkEntitlementsRequest &&
						!accountRequest &&
						networkEntitlements &&
						account && (
							<WrappedComponent
								{...this.state}
								{...this.props}
								getOptions={this.getOptions}
							/>
						)}
					{networkEntitlements &&
						networkEntitlements.networkEntitlements &&
						networkEntitlements.networkEntitlements.length === 0 && (
							<NoData
								table
								title={
									<FormattedMessage
										id="ONBOARDING.NO_NETWORK_ENTITLEMENTS_AVAILABLE"
										defaultMessage="No Network Entitlements Available"
									/>
								}
								subtitle={
									<FormattedMessage
										id="ONBOARDING.NO_NETWORK_ENTITLEMENTS_SUBTITLE"
										defaultMessage="There are No Network Entitlements for this Table to show you right now"
									/>
								}
							/>
						)}
				</div>
			);
		}
	}
	const { func, bool, object, string } = PropTypes;

	NetworkEntitlementsComponent.propTypes = {
		getNetworkEntitlements: func,
		networkEntitlementsRequest: bool,
		networkEntitlementsFail: bool,
		networkEntitlements: object,
		match: object,
		platformId: string,
		goToNetworkEntitlementDetails: func,
		pushBack: func,
		accountRequest: bool,
		accountFail: bool,
		account: object,
		getAccount: func,
		parentAccountM2mId: string,
		user: object
	};
	return NetworkEntitlementsComponent;
};

export default NetworkEntitlements;
