import React from 'react';
import PropTypes from 'prop-types';
import hoc from '../Alerts';
import CardsTable from '../../../../../lib/DigitalComponents/CardsTable';

const Mobile = (props) => {
	const { options, messages, alerts } = props;
	return (
		<div data-spec="automation-alerts-mobile">
			<CardsTable
				data-spec="automation-rules-desktop-table"
				options={options('mobile')}
				messages={messages}
				data={alerts}
				mobile
			/>
		</div>
	);
};

const { object, func, array } = PropTypes;

Mobile.propTypes = {
	messages: object,
  options: func,
  alerts: array
};

export default hoc()(Mobile);
