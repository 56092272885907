import React from 'react';
import {
	string,
	objectOf,
	func,
	arrayOf,
	object,
	bool,
	oneOfType,
	number,
} from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import CurrentContext from '../../../../../utils/currentContext';
import ImageSelector from '../../../../../utils/imageSelector';
import Card from '../../../../Shared/components/Card';
import Table from '../../../../../lib/DigitalComponents/Table';
import {
	renderValue,
} from '../../../../Shared/components/Charts/Helpers/helpers';
import NoData from '../../../../Shared/components/NoData/NoData';
import Loader from '../../../../../lib/DigitalComponents/Loader';
import EditTableModal from '../../EditTableModal';
import hoc from '../Sessions';

import styles from './Desktop.scss';

const RefreshIcon = ImageSelector(CurrentContext.theme, 'svgs/reset.svg');
const ExportCSVIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg',
);
const EditTableIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/guide-setup.svg',
);

function Desktop({
	refresh,
	currentActiveSession,
	tableData,
	options,
	messages,
	exportSessionsData,
	isExportSessionsDataPending,
	sessionsSignature,
	header,
	settings,
	submitColumns,
	openEditTableModal,
	closeEditTableModal,
	isEditTableModalOpen,
}) {
	return (
		<div data-spec="sessions-desktop" className={styles.desktop_wrapper}>
			{currentActiveSession ? (
				<Card customStyle={styles.custom_card}>
					<div className={styles.card_header}>
						<span>
							<FormattedMessage
								id="CURRENTLY_ACTIVE_SESSION"
								defaultMessage="Currently Active Session"
							/>
						</span>
						<div className={styles.refresh_icon}>
							<RefreshIcon onClick={refresh} />
						</div>
					</div>
					<div className={styles.card_body}>
						<div>
							<div className={styles.in_session}>
								{/*	 <span> */}
								{/*		<FormattedMessage */}
								{/*			id={'IN_SESSION'} */}
								{/*			defaultMessage={'IN SESSION'} */}
								{/*		/> */}
								{/*	</span> */}
								{/*	<div> */}
								{/*		<RefreshIcon /> */}
								{/*	</div> */}
								<div>
									<FormattedMessage
										id={'ACTIVE_SESSION_CARRIER'}
										defaultMessage={'CARRIER'}
									/>
								</div>
								<div
									data-tip={currentActiveSession.carrier}
									data-for="carrier-tooltip"
								>
									{currentActiveSession.carrier}
								</div>
							</div>
						</div>
						<div>
							<div>
								<span>
									<FormattedMessage
										id={'ACTIVE_SESSION_RECEIVING'}
										defaultMessage={'RECEIVING'}
									/>
								</span>
								<div className={styles.receiving_transmitting}>
									<span className={styles.receiving_arrow} />
									<span>
										{renderValue(
											'MB',
											currentActiveSession.downlinkDataVolume,
										)}
									</span>
									<span>
										<FormattedMessage
											id="BYTE_ABBREVIATION"
											defaultMessage="B"
										/>
									</span>
								</div>
							</div>
							<span>
								<FormattedMessage
									id={'ACTIVE_SESSION_IP_ADDRESS'}
									defaultMessage={'IP ADDRESS'}
								/>
							</span>
							<div className={styles.bottom_items}>
								{currentActiveSession.ipAddress}
							</div>
						</div>
						<div>
							<div>
								<span>
									<FormattedMessage
										id={'ACTIVE_SESSION_TRANSMITTING'}
										defaultMessage={'TRANSMITTING'}
									/>
								</span>
								<div className={styles.receiving_transmitting}>
									<span className={styles.transmitting_arrow} />
									<span>
										{renderValue('MB', currentActiveSession.uplinkDataVolume)}
									</span>
									<span>
										<FormattedMessage
											id="BYTE_ABBREVIATION"
											defaultMessage="B"
										/>
									</span>
								</div>
							</div>
							<span>
								<FormattedMessage
									id={'APN'}
									defaultMessage={'APN'}
								/>
							</span>
							<div className={styles.bottom_items}>
								{currentActiveSession.apn}
							</div>
						</div>
						<div>
							<div className={styles.total_volume_wrapper}>
								<span>
									<FormattedMessage
										id={'ACTIVE_SESSION_TOTAL_VOLUME'}
										defaultMessage={'TOTAL VOLUME'}
									/>
								</span>
								<div className={styles.total_volume}>
									<span>
										{renderValue(
											'MB',
											currentActiveSession.downlinkDataVolume
											+ currentActiveSession.uplinkDataVolume,
										)}
									</span>
									<span>
										<FormattedMessage
											id="BYTE_ABBREVIATION"
											defaultMessage="B"
										/>
									</span>
								</div>
							</div>
						</div>
						<div>
							<div className={styles.session_start_wrapper}>
								<span>
									<FormattedMessage
										id={'ACTIVE_SESSION_SESSION_START_TIME'}
										defaultMessage={'SESSION START TIME'}
									/>
								</span>
								<div className={styles.session_start}>
									<span>
										<FormattedTime
											value={currentActiveSession.start || new Date()}
											hour="numeric"
											minute="numeric"
											hour12={false}
											timeZone="UTC"
										/>
									</span>
									<span>
										<FormattedDate
											value={currentActiveSession.start || new Date()}
											month="2-digit"
											day="2-digit"
											year="numeric"
											hour="numeric"
											minute="numeric"
											timeZone="UTC"
											hour12={false}
										>
											{(formattedDate) => formattedDate.replace(',', '')}
										</FormattedDate>
										<FormattedMessage
											id="UTC_TIME_ZONE"
											defaultMessage=" UTC "
										/>
									</span>
								</div>
							</div>
						</div>
					</div>
				</Card>
			) : null}
			<ReactTooltip
				className={styles.tooltip}
				type="light"
				id="carrier-tooltip"
			/>
			<div className={styles.table_wrapper}>
				<header className={styles.table_header}>
					<span>
						<FormattedMessage
							id="RECENT_SESSIONS"
							defaultMessage="Recent Sessions"
						/>
					</span>
					{tableData.length > 0 ? (
						<div className={styles.table_header_buttons}>
							<FormattedMessage
								id="EDIT_TABLE_PARAMETERS"
								defaultMessage="Edit Table Parameters"
							>
								{(formattedValue) => (
									<EditTableIcon
										onClick={openEditTableModal}
										data-tip={formattedValue}
										data-for="edit-table-icon-tooltip"
									/>
								)}
							</FormattedMessage>
							<ReactTooltip
								type="light"
								id="edit-table-icon-tooltip"
							/>
							{!isExportSessionsDataPending
								? (
									<>
										<FormattedMessage
											id="EXPORT_DATA"
											defaultMessage="Export Data"
										>
											{(formattedValue) => (
												<ExportCSVIcon
													onClick={exportSessionsData}
													data-tip={formattedValue}
													data-for="export-data-icon-tooltip"
												/>
											)}
										</FormattedMessage>
										<ReactTooltip
											type="light"
											id="export-data-icon-tooltip"
										/>
									</>
								)
								: <Loader />}
						</div>
					) : null}
				</header>
				{tableData.length > 0 ? (
					<Table
						data={tableData}
						options={options()}
						messages={messages}
						dataspec="sessions-table"
					/>
				) : <NoData styles={styles} table />}
				<EditTableModal
					show={isEditTableModalOpen}
					onClose={closeEditTableModal}
					customFieldLabels={[]}
					messages={messages}
					submitColumns={submitColumns}
					columns={settings}
					signature={sessionsSignature}
					tableTabs={header}
					excludeColumns={[]}
				/>
			</div>
		</div>
	);
}

Desktop.propTypes = {
	refresh: func,
	currentActiveSession: objectOf(oneOfType([string, bool, number])),
	messages: objectOf(string),
	tableData: arrayOf(object),
	options: func,
	exportSessionsData: func,
	isExportSessionsDataPending: bool,
	sessionsSignature: string,
	header: arrayOf(object),
	settings: object,
	submitColumns: func,
	openEditTableModal: func,
	closeEditTableModal: func,
	isEditTableModalOpen: bool,
};
Desktop.defaultProps = {
	refresh: undefined,
	messages: {},
	currentActiveSession: null,
	tableData: {},
	options: undefined,
};

export default hoc(Desktop);
