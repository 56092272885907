import React from 'react';
import cn from 'classnames';
import { string } from 'prop-types';

import styles from './Desktop.scss';
import TabsBar from '../TabsBar';

const TabsBarDesktop = (props) => {
  const { className } = props;

  return (
		<TabsBar
			data-spec="tabs-bar-desktop"
			{...props}
			styles={cn(styles.tabs_bar, className)}
		/>
	);
};

TabsBarDesktop.propTypes = {
  className: string
};

export default TabsBarDesktop;
