import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import cn from 'classnames';
import { format } from 'date-fns';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from '../../../../../lib/DigitalComponents/Table';
import isPendingChange from '../../../../SingleEndpoint/utils/helpers';
import { isUserAllowedToAccess } from '../../../../../utils/AuthSelector';
import {
	tableHeaderPermissionsCheck,
	allowedServiceProviders
} from '../../../../../utils/constants';
import { objectPropertiesValidation } from '../../../../../utils/helperFunctions';

import StatusSelect from '../Assets/StatusSelect';
import RatePlanSelect from '../Assets/RatePlanSelect';
import styles from './Desktop.scss';

import hoc from '../EndpointsData';

function Desktop(props) {
	const {
		data,
		tableHeader,
		availableStates,
		ratePlans,
		messages,
		changeRatePlan,
		changeStatus,
		user,
		onCheckboxChange,
		onCellClick,
		onOrderClick,
		onNoteClick,
		orderColumn,
		onDragEnd,
		handlePageClick,
		totalCount,
		showPagination,
    pagination,
		intl
	} = props;

	/* --- get available endpoints states start --- */
	let selectOptions = [];

  const StatusRW = isUserAllowedToAccess(['mnc.endpoints.simstatus_rw'], user);
	const StatusRO = isUserAllowedToAccess(['mnc.endpoints.simstatus_ro'], user);
	const RatePlansRW = isUserAllowedToAccess(
		['mnc.endpoints.rateplans_rw'],
		user
	);

	const getSelectOptions = (newAvailableStates, value) => {
		if (value) {
			selectOptions = [
				{
					value: value.status,
					label: intl.formatMessage({
						id: `ENDPOINTS.${value.status}`,
						defaultMessage: value.status
					})
				}
			];
		}

		if (newAvailableStates && newAvailableStates.length > 0) {
			selectOptions = newAvailableStates.map((item) => ({
				value: item.state,
				label: intl.formatMessage({
					id: `ENDPOINTS.${item.state}`,
					defaultMessage: `${item.state}`
				})
			}));
			selectOptions = [
				{
					value: value.status,
					label: intl.formatMessage({
						id: `ENDPOINTS.${value.status}`,
						defaultMessage: `${value.status}`
					})
				},
				...selectOptions
			];
		}

		if (StatusRW) {
			return selectOptions;
		}
		if (StatusRO) {
			return [selectOptions[0]];
		}
	};

	const getAvailableStates = (endpoint) => {
		let statesObject = {};
		if (availableStates && availableStates[endpoint.serviceProvider]) {
			// eslint-disable-next-line array-callback-return
			availableStates[endpoint.serviceProvider].map((item) => {
				statesObject = {
					...statesObject,
					[item.currentState]: item.targetStates
				};
			});
		}
		return endpoint.status
			? getSelectOptions(
					statesObject[endpoint.status] ? statesObject[endpoint.status] : [],
					endpoint
			  )
			: [];
	};
	/* --- get available endpoints states end --- */

	const getRatePlans = (endpoint) => {
		let exist = false;

		const matchedRatePlans = ratePlans
			? ratePlans
					.filter((plan) => {
						if (objectPropertiesValidation(plan, 'ratePlan.m2mAccountId')) {
							if (
								plan.ratePlan.id === endpoint.ratePlanId &&
								plan.ratePlan.m2mAccountId === endpoint.m2mAccountId
							) {
								exist = true;
							}

							return plan.ratePlan.m2mAccountId === endpoint.m2mAccountId;
						}

						return endpoint.m2mAccountId === '';
					})
					.map((plan) => ({
						value: plan.ratePlan.id,
						label: plan.ratePlan.name
					}))
			: [];

		return { matchedRatePlans, ratePlanMatch: exist };
	};

	/* --- update endpoint status start --- */
	const updateEndpointStatus = (val, item) => {
		const value = item;
		if (val.value === value.status) return;
		const dateFormat = 'yyyy-MM-dd';
		const updateData = {
			id: value.endpointName,
			data: {
				targetState: val.value,
				userNotes: intl.formatMessage(
					{
						id: 'ENDPOINTS.UPDATE_ENDPOINT_STATUS',
						defaultMessage: `User ${user.uid} made Change status on ${format(
							new Date(),
							dateFormat
						)}`
					},
					{ user: user.uid, date: format(new Date(), dateFormat) }
				)
			}
		};
		changeStatus(updateData, value);
	};
	/* --- update endpoint status end --- */

	const updateEndpointRatePlan = (val, item) => {
		if (val.value === item.ratePlanId) return;
		const updateData = {
			data: {
				ratePlanId: val.value
			},
			label: val.label,
			id: item.endpointName
		};
		changeRatePlan(updateData, item);
	};

	const statusRenderer = (val) =>
		(val.status ? (
			<div
				className={cn(
					styles.status_col,
					styles[val.status.replace(/\s/g, '_').toLowerCase()]
				)}
			>
				<FormattedMessage id={val.status} defaultMessage={val.status} />
			</div>
		) : (
			''
		));

	const ratePlanRenderer = (val) => val.ratePlanName || '';

	const renderTransferStatus = (val) => {
		const status = val.transferStatus
			? val.transferStatus.replace(/\s/g, '').toLowerCase()
			: '';
		if (val.transferStatus) {
			return (
				<FormattedMessage
					data-spec="transfer-status"
					id={status}
					defaultMessage={String(val.transferStatus)
						.replace(/_/g, ' ')
						.toLowerCase()}
				/>
			);
		}
		return '';
	};

	const renderEndpointName = (val) => (
		<div
			data-spec="endpoint-name"
			className={styles.endpoint_name}
			onClick={() => onCellClick(val)}
		>
			{val.endpointName}
		</div>
	);

	const renderViewNotes = (val) =>
		(val.seNotes ? (
			<div
				className={styles.endpoint_name}
				onClick={() => onNoteClick(val.seNotes)}
			>
				<FormattedMessage id="ENDPOINTS.VIEW_NOTE" defaultMessage="View Note" />
			</div>
		) : (
			''
		));

	const renderDataSession = ({ dataSession }) => {
		if (dataSession === '1') {
			return (
				<FormattedMessage
					data-spec="in-session"
					id="YES"
					defaultMessage="Yes"
				/>
			);
		}
		if (dataSession === '0') {
			return (
				<FormattedMessage data-spec="in-session" id="NO" defaultMessage="No" />
			);
		}
		return '';
	};

	const ratePlanComponent = (val, index) => {
    const { matchedRatePlans, ratePlanMatch } = getRatePlans(val);
    const menuPosition = index + 1 > data.totalCount - 3 ? 'fixed' : 'absolute';

		return ratePlanMatch ? (
			<RatePlanSelect
				className={'select'}
				onChange={(values) => updateEndpointRatePlan(values, val)}
				value={{ value: val.ratePlanId, label: val.ratePlanName }}
				options={matchedRatePlans}
				isDisabled={
					isPendingChange(val) ||
					(val &&
						val.serviceProvider &&
						!includes(
							allowedServiceProviders,
							val.serviceProvider.toLowerCase()
						))
				}
				menuPosition={menuPosition}
			/>
		) : (
			ratePlanRenderer(val)
		);
  };

  const statusSelectRenderer = (val, index) => (
		<StatusSelect
			onChange={(value) => updateEndpointStatus(value, val)}
			value={{
				value: val.status,
				label: intl.formatMessage({
					id: val.status ? `ENDPOINTS.${val.status}` : 'EMPTY_SPACE',
					defaultMessage: val.status || ' '
				})
			}}
			options={getAvailableStates(val)}
			isDisabled={isPendingChange(val)}
			index={index}
			dataLenght={data.totalCount}
		/>
	);

	const options = {
		header: tableHeaderPermissionsCheck(tableHeader, user),
		tableOptions: {
			selectRows: true,
			enableSelectAllRows: true,
			onCheckBoxChange: (val) => onCheckboxChange(val),
			isCheckBoxDisabled: (val) => isPendingChange(val),
			firstColumnMarked: true,
			draggable: true,
			onDragEnd: (fromIndex, toIndex) => onDragEnd(fromIndex, toIndex),
			excludeDraggableColumns: ['endpointName'],
			orderByData: ['all'],
			getOrderData: (val) => onOrderClick(val),
			orderColumn,
			showPagination,
			pageCount: totalCount,
			marginPagesDisplayed: 2,
			pageRangeDisplayed: 7,
			forcePage: pagination,
			onPageChange: (val) => handlePageClick(val),
			selectedPage: pagination,
			fixedFirstColumn: true,
			containerClassName: `pagination ${styles.endpoints_table}`,
			enableTableGrabbing: true,
			fixedHeader: true
		},
		customComponents: {
			status: StatusRW
				? {
						type: 'custom',
						component: (val, index) => statusSelectRenderer(val, index)
				  }
				: {
						type: 'custom',
						component: (val) => statusRenderer(val)
				  },
			ratePlanId: RatePlansRW
				? {
						type: 'custom',
						component: (val, index) => ratePlanComponent(val, index)
				  }
				: {
						type: 'custom',
						component: (val) => ratePlanRenderer(val)
				  },
			transferStatus: {
				type: 'custom',
				component: (val) => renderTransferStatus(val)
			},
			endpointName: {
				type: 'custom',
				component: (val) => renderEndpointName(val)
			},
			seNotes: {
				type: 'custom',
				component: (val) => renderViewNotes(val)
			},
			dataSession: {
				type: 'custom',
				component: (val) => renderDataSession(val)
			}
		}
	};
	return (
		<Table
			data-spec="endpoints-table"
			data={data.resultList}
			options={options}
			messages={messages}
		/>
	);
}

const { array, object, func, number, bool } = PropTypes;
Desktop.propTypes = {
  data: object,
	tableHeader: array,
	availableStates: object,
	messages: object,
	user: object,
	orderColumn: object,
	changeStatus: func,
	onCheckboxChange: func,
	onCellClick: func,
	onNoteClick: func,
	onOrderClick: func,
	onDragEnd: func,
	handlePageClick: func,
	totalCount: number,
	pagination: number,
	showPagination: bool,
	ratePlans: array,
  changeRatePlan: func,
  intl: object
};

export default hoc(injectIntl(Desktop));
