import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { FormattedMessage } from 'react-intl';

import ImageSelector from 'utils/imageSelector';
import CurrentContext from 'utils/currentContext';

import { ConditionIf } from '../../../../utils/helperFunctions';

import styles from './AttachmentsTable.scss';

const DownloadDocument = ImageSelector(
	CurrentContext.theme,
	'svgs/download-document.svg'
);
const TrashIcon = ImageSelector(CurrentContext.theme, 'svgs/trash.svg');

class AttachmentsTable extends PureComponent {
	downloadInvoice = () => {
		const { invoiceDocumentName, m2mAccountId, downloadInvoice } = this.props;
		downloadInvoice(invoiceDocumentName, m2mAccountId);
	};

	downloadAttachment = (attachmentName, attachmentId) => {
		const { invoiceAttachmentDownload, invoiceId } = this.props;
		const fileRequest = {
			service: 'invoice',
			fileName: attachmentName,
			transactionType: 'attachmentInvoice',
			invoiceId,
			attachmentId
		};
		invoiceAttachmentDownload(fileRequest);
	};

	render() {
		const {
			attachments,
			onDeleteAttachment,
			invoiceDocumentName,
			isRWOComponent
		} = this.props;
		return (
			<div
				data-spec="attachments-table"
				className={styles.attachments_table_wrapper}
			>
				<table className={styles.attachments_table}>
					<thead className={styles.header_row}>
						<tr>
							<th>
								<FormattedMessage
									id="INVOICES_ATTACHMENTS_MODAL.FILE_NAME"
									defaultMessage="File Name"
								/>
							</th>
							<th>
								<FormattedMessage
									id="INVOICES_ATTACHMENTS_MODAL.DESCRIPTION"
									defaultMessage="Description"
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{invoiceDocumentName && (
							<tr>
								<td className={styles.invoice_name}>
									<span
										className={styles.clickable}
										onClick={() => this.downloadInvoice()}
										data-tip={`${invoiceDocumentName}.pdf`}
										data-for="invoice-name"
									>
										<DownloadDocument />
										<span className={styles.name}>
											{`${invoiceDocumentName}.pdf`}
										</span>
									</span>
									{invoiceDocumentName.length > 25 && (
										<ReactTooltip
											className={styles.tooltip}
											type="light"
											id={'invoice-name'}
										/>
									)}
								</td>
							</tr>
						)}
						{attachments &&
							attachments.map((attachment) => (
								<tr key={attachment.inovoiceAttachmentId}>
									<td>
										<span className={styles.file_name}>
											<span
												className={styles.clickable}
												onClick={() =>
													this.downloadAttachment(
														attachment.invoiceAttachmentName,
														attachment.inovoiceAttachmentId
													)}
												data-tip={attachment.invoiceAttachmentName}
												data-for="invoice-attachment"
											>
												<DownloadDocument />
												<span className={styles.attachment_name}>
													{attachment.invoiceAttachmentName}
												</span>
											</span>
											{attachment.invoiceAttachmentName.length > 25 && (
												<ReactTooltip
													className={styles.tooltip}
													type="light"
													id={'invoice-attachment'}
												/>
											)}
										</span>
									</td>

									<td className={styles.description_td}>
										<span className={styles.description}>
											{attachment.invoiceAttachmentContents}
										</span>
										<ConditionIf condition={isRWOComponent}>
											<span className={styles.description_icons}>
												<TrashIcon
													onClick={() =>
														onDeleteAttachment(attachment.inovoiceAttachmentId)}
												/>
											</span>
										</ConditionIf>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		);
	}
}

const { array, func, string, bool } = PropTypes;

AttachmentsTable.propTypes = {
	attachments: array,
	onDeleteAttachment: func,
	invoiceDocumentName: string,
	invoiceId: string,
	m2mAccountId: string,
	downloadInvoice: func,
	invoiceAttachmentDownload: func,
	isRWOComponent: bool
};

export default AttachmentsTable;
