import React from 'react';
import { FormattedMessage } from 'react-intl';

export const batchActionsHeaderDesktop = [
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.BATCH_FILE_NAME"
				defaultMessage="Batch File Name"
			/>
		),
		name: 'displayFileName'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.M2M_PLATFORM"
				defaultMessage="M2M Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.CREATED_BY"
				defaultMessage="Created By"
			/>
		),
		name: 'creatorId'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.DATE_UPLOADED"
				defaultMessage="Date Uploaded"
			/>
		),
		name: 'scheduledDate'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.STATUS" defaultMessage="Status" />
		),
		name: 'status'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.SUCCESS_RATE" defaultMessage="Success Rate" />
		),
		name: 'successRate'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.DETAILS" defaultMessage="Details" />
		),
		name: 'details'
	}
];

export const batchActionsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.BATCH_FILE_NAME"
				defaultMessage="Batch File Name"
			/>
		),
		name: 'displayFileName'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.M2M_PLATFORM"
				defaultMessage="M2M Platform"
			/>
		),
		name: 'platform'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.CREATED_BY"
				defaultMessage="Created By"
			/>
		),
		name: 'creatorId'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.DATE_UPLOADED"
				defaultMessage="Date Uploaded"
			/>
		),
		name: 'scheduledDate'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.STATUS" defaultMessage="Status" />
		),
		name: 'status'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.SUCCESS_RATE" defaultMessage="Success Rate" />
		),
		name: 'successRate'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.DETAILS" defaultMessage="Details" />
		),
		name: 'details'
	}
];

export const batchReportsHeaderDesktop = [
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.REPORT_NAME"
				defaultMessage="Report"
			/>
		),
		name: 'reportName'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.M2M_PLATFORM"
				defaultMessage="M2M Platform"
			/>
		),
		name: 'serviceProvider'
	},
	{
		title: <FormattedMessage id="BATCH_HISTORY.DATE" defaultMessage="Date Created" />,
		name: 'date'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.DETAILS" defaultMessage="Details" />
		),
		name: 'details'
	},
];

export const batchReportsHeaderMobile = [
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.REPORT_NAME"
				defaultMessage="Report"
			/>
		),
		name: 'reportName'
	},
	{
		title: (
			<FormattedMessage
				id="BATCH_HISTORY.M2M_PLATFORM"
				defaultMessage="M2M Platform"
			/>
		),
		name: 'serviceProvider'
	},
	{
		title: <FormattedMessage id="BATCH_HISTORY.DATE" defaultMessage="Date Created" />,
		name: 'date'
	},
	{
		title: (
			<FormattedMessage id="BATCH_HISTORY.DETAILS" defaultMessage="Details" />
		),
		name: 'details'
	},
];
