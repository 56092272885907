import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../../redux/modals/batchFileUploadModal/actions';
import { anyModalOpened, anyModalClosed } from '../../../../redux/modals/globalModal/actions';
import { getUser } from '../../../../redux/user/getUserData/selectors';
import createUserSettings from '../../../../redux/user/createUserSettings/actions';
import setEndpointsActiveTab from '../../redux/endpointsTabs/actions';
import getActiveTab from '../../redux/endpointsTabs/selectors';
import { openFilterModal } from '../../../Shared/redux/filterModal/actions';
import { getSearchParameterProp } from '../../../Shared/redux/searchBar/selectors';
import {
	isBatchUploadModalOpened,
	batchModalType
} from '../../../../redux/modals/batchFileUploadModal/selectors';
import {
	getSelectedPage,
	getEndpointsOrderColumn
} from '../../redux/endpointsPagination/selectors';
import {
	setEndpointsPagination,
	setEndpointsPaginationInit
} from '../../redux/endpointsPagination/actions';
import {
	getCustomLabelsRequest,
	getCustomLabelsSuccess,
	customLabelsUpdateSuccess,
	getEndpointsRequest,
	getEndpointsFail
} from '../../redux/selectors';
import updateUserSettings from '../../../../redux/user/updateUserSettings/actions';
import { updateUserSettingsPending } from '../../../../redux/user/updateUserSettings/selectors';
import deleteUserSettings from '../../../../redux/user/deleteUserSettings/actions';
import { getFiltersRequest } from '../../../Shared/redux/filterModal/selectors';
import { clearError } from '../../../Shared/redux/getStatusCode/actions';
import { getActionError } from '../../redux/updateBulkSingleEndpoints/selectors';
import {
	updateSingleEndpoint,
	updateSingleEndpointInit,
	updateRatePlan,
	updateRatePlanInit
} from '../../../SingleEndpoint/redux/actions';
import {
	isSingleEndpointUpdating,
	singleEndpointUpdateSuccess,
  singleEndpointUpdateFail,
  isRatePlanUpdating,
  ratePlanUpdateSuccess,
  ratePlanUpdateFail
} from '../../../SingleEndpoint/redux/selectors';
import { getIsBatchFilesUploading } from '../../../../redux/batchFiles/selectors';

import EndpointsDataDesktop from './Desktop';
import EndpointsDataMobile from './Mobile';
import filterDataSuccess from '../../../Shared/redux/filterModal/filterData/selectors';
import {
  getEndpoints,
  getCustomLabels,
	generateReport,
	generateReportInit,
	statusChangeSuccess,
	statusChangeError,
	ratePlanChangeSuccess,
	ratePlanChangeError,
	updateCustomLabelsInit
} from '../../redux/actions';
import { getIsDownloadingTemplate } from '../../../../redux/batchFiles/templateDownload/selectors';

const mapStateToProps = (state) => ({
  user: getUser(state),
  getCustomLabelsRequest: getCustomLabelsRequest(state),
  customFieldLabels: getCustomLabelsSuccess(state),
	pagination: getSelectedPage(state),
  areEndpointsFetching: getEndpointsRequest(state),
  endpointsRequestFail: getEndpointsFail(state),
  filterData: filterDataSuccess(state),
  searchParameterProp: getSearchParameterProp(state),
	activeTab: getActiveTab(state),
	getFiltersRequest: getFiltersRequest(state),
	updateUserSettingsPending: updateUserSettingsPending(state),
	isBatchUploadModalOpened: isBatchUploadModalOpened(state),
	isBatchFilesUploading: getIsBatchFilesUploading(state),
	batchModalType: batchModalType(state),
	actionError: getActionError(state),
	isTemplateDownloading: getIsDownloadingTemplate(state),
	orderColumn: getEndpointsOrderColumn(state),
	customLabelsUpdateSuccess: customLabelsUpdateSuccess(state),
	isSingleEndpointUpdating: isSingleEndpointUpdating(state),
	singleEndpointUpdateSuccess: singleEndpointUpdateSuccess(state),
	singleEndpointUpdateFail: singleEndpointUpdateFail(state),
	isRatePlanUpdating: isRatePlanUpdating(state),
	ratePlanUpdateSuccess: ratePlanUpdateSuccess(state),
  ratePlanUpdateFail: ratePlanUpdateFail(state)
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllEndpoints: (paginationData, searchData) =>
		dispatch(getEndpoints(paginationData, searchData)),
	getCustomLabels: () => dispatch(getCustomLabels()),
	openBatchUploadModal: (type) => dispatch(openModal(type)),
	openFilterModal: () => {
		dispatch(openFilterModal());
		dispatch(anyModalOpened());
	},
	modalOpened: () => dispatch(anyModalOpened()),
	createUserSettings: (setting, notificationMessages) =>
		dispatch(createUserSettings(setting, notificationMessages)),
	updateUserSettings: (setting, id, notificationMessages) =>
		dispatch(updateUserSettings(setting, id, notificationMessages)),
	deleteUserSettings: (id, notificationMessages) =>
		dispatch(deleteUserSettings(id, notificationMessages)),
	setEndpointsActiveTab: (tab) => dispatch(setEndpointsActiveTab(tab)),
	modalClosed: () => dispatch(anyModalClosed()),
	updateStatus: (data, paginationData, searchData) =>
		dispatch(updateSingleEndpoint(data, paginationData, searchData)),
	updateSingleEndpointStatusInit: () => {
		dispatch(updateCustomLabelsInit());
		dispatch(generateReportInit());
		return dispatch(updateSingleEndpointInit());
	},
	bulkBatchUploadModalClose: (disabled) => {
		if (!disabled) {
			dispatch(closeModal());
		}
	},
	clearError: () => dispatch(clearError()),
	updateRatePlan: (data) => dispatch(updateRatePlan(data)),
	updateRatePlanInit: () => dispatch(updateRatePlanInit()),
	setEndpointsPaginationInit: () => dispatch(setEndpointsPaginationInit()),
	setEndpointsPagination: (pagination) =>
		dispatch(setEndpointsPagination(pagination)),
	generateReport: (paginationData, searchData) =>
		dispatch(generateReport(paginationData, searchData, dispatch)),
	showNotificationSuccess: (data, formatMessage) =>
		dispatch(statusChangeSuccess(data, formatMessage)),
	showNotificationError: (data, formatMessage) =>
		dispatch(statusChangeError(data, formatMessage)),
	ratePlanNotificationSuccess: (data, formatMessage) =>
		dispatch(ratePlanChangeSuccess(data, formatMessage)),
	ratePlanNotificationError: (data, formatMessage) =>
		dispatch(ratePlanChangeError(data, formatMessage))
});

export const Desktop = connect(
	mapStateToProps,
	mapDispatchToProps
)(EndpointsDataDesktop);

export const Mobile = connect(
	mapStateToProps,
	mapDispatchToProps
)(EndpointsDataMobile);
