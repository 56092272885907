import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import { Field, reduxForm, FieldArray, arrayRemove } from 'redux-form';
import { resetFields } from '../../../../utils/constants';
import { localizationHelper } from '../../../../utils/helperFunctions';
import {
	required,
	email,
	maxLength,
	onlyLettersAndSpaces,
	zipCodeValidation
} from '../../../../utils/validators';
import CurrentContext from '../../../../utils/currentContext';
import ImageSelector from '../../../../utils/imageSelector';

import Select from '../../../../lib/DigitalComponents/DropdownSelectNew';
import Input from '../../../../lib/DigitalComponents/FieldGroup';
import Button from '../../../../lib/DigitalComponents/Button';
import Radio from '../../../../lib/DigitalComponents/Radio';
import Loading from '../../../../lib/DigitalComponents/Loader';
import SectionHeadline from '../../../../lib/DigitalComponents/SectionHeadline';
import FieldWrapper from '../../../../lib/DigitalComponents/FieldWrapper';
import LanguageSelector from '../../../../lib/DigitalComponents/LanguageSelector/LanguageSelector';
import PhoneSelector from '../../../../lib/DigitalComponents/PhoneSelector';
import Divider from '../../../../lib/DigitalComponents/Divider';
import UploadImage from '../UploadImage';
import phonesList from '../../../Ticketing/utils/countryAndPhoneCodes';
import RemoveCompanyContact from '../EditCompanyOverview/RemoveCompanyContact';
import ValidateAddressModal from '../ValidateAddressModal';

import styles from './AddCompanyForm.scss';

const PlusIcon = ImageSelector(
	CurrentContext.theme,
	'svgs/pluss-add-collapse.svg'
);

const CompanyLogo = ImageSelector(
	CurrentContext.theme,
	'company-image-placeholder.png'
);

const RemoveIcon = ImageSelector(CurrentContext.theme, 'svgs/remove.svg');

const maxLength50 = maxLength(50);
const maxLength40 = maxLength(40);
const maxLength20 = maxLength(20);

const countryCallingCodes = sortBy(phonesList, 'value');

class AddCompanyForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openValidateAddressModal: false,
			isRemoveCompanyContact: false
		};
	}

	// Temp comment
	componentDidMount() {
		const { getCountries, initialize, formData } = this.props;
		getCountries({
			additionalParams: {
				dataSort: 'priority asc, name asc'
			}
		});
		this.getCompanyLanguages();
		if (!formData) {
			initialize({
				addresses: [{}],
				companyContacts: [{}],
				attContacts: [{}],
				supportType: ''
			});
		}
	}

	componentDidUpdate() {
		const { formData, initialize } = this.props;

		if (!formData) {
			initialize({
				addresses: [{}],
				companyContacts: [{}],
				attContacts: [{}],
				supportType: ''
			});
		}
	}

	getStates = (item) => {
		const { getStates } = this.props;
		getStates({
			searchParams: [{ propValue: item, prop: 'countryId', operator: '=' }],
			additionalParams: {
				dataSort: 'name ASC'
			}
		});
	};

	useSuggestedAddress = (data, state, country) => {
		const { formData, initialize } = this.props;
		initialize({
			...formData,
			addresses: [
				{
					city: data.city,
					street1: data.street1,
					street2: formData.street2,
					zipCode: data.zipCode,
					countryId: {
						value: country.id,
						label: country.name
					},
					stateId: {
						value: state.id,
						label: state.name
					}
				}
			]
		});
	};

	getCompanyLanguages = () => {
		const { getCompanyLanguages } = this.props;
		getCompanyLanguages();
	};

	updateLogo = (logoUrl) => {
		const { formData, initialize } = this.props;
		initialize({
			...formData,
			logo: logoUrl
		});
	};

	renderContacts = ({ fields, contactType }) => {
		const { formData, intl } = this.props;
		return (
			<div data-spec="contacts">
				{fields.map((contact, index) => (
					<div key={contact}>
						<div className={styles.counter}>
							{index !== 0 && <Divider />}
							<FormattedMessage
								id="ONBOARDING.CONTACT"
								defaultMessage="Contact"
							/>
							{` ${index + 1}`}
							<div className={styles.remove_icon_wrapper}>
								{formData &&
									formData[contactType] &&
									formData[contactType][index] &&
									index !== 0 && (
										<div
											className={styles.icon_wrapper}
											onClick={() => {
												this.openRemoveCompanyContactModal(index, contactType);
											}}
										>
											<RemoveIcon />
										</div>
									)}
							</div>
						</div>

						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.FIRST_NAME"
								defaultMessage="First Name"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										name={`${contact}.firstName`}
										label={formattedValue}
										dataSpec="first-name"
										validate={[required, onlyLettersAndSpaces, maxLength20]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.LAST_NAME"
								defaultMessage="Last Name"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										name={`${contact}.lastName`}
										label={formattedValue}
										dataSpec="last-name"
										validate={[required, onlyLettersAndSpaces, maxLength20]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper single>
							<FormattedMessage
								id="ONBOARDING.JOB_TITLE"
								defaultMessage="Job Title"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										name={`${contact}.jobTitle`}
										label={formattedValue}
										dataSpec="job-title"
										validate={[onlyLettersAndSpaces]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper required>
							<PhoneSelector
								label={intl.formatMessage({
									id: 'ONBOARDING.PHONE_NUMBER',
									defaultMessage: 'Phone Number'
								})}
								isRequired
								options={countryCallingCodes}
								name={`${contact}`}
							/>
						</FieldWrapper>
						<FieldWrapper required>
							<Field
								component={Input}
								placeholder="contact@company.com"
								name={`${contact}.email`}
								label={
									<FormattedMessage
										id="ONBOARDING.EMAIL_ADDRESS"
										defaultMessage="Email Address"
									/>
								}
								dataSpec="contact-email-address"
								validate={[required, email]}
							/>
						</FieldWrapper>
					</div>
				))}
				<span
					className={cn({
						[styles.icon_wrapper]: true
					})}
					onClick={() => fields.push({})}
				>
					<PlusIcon />
					{
						<FormattedMessage
							id="ONBOARDING.ADD_ANOTHER_CONTACT"
							defaultMessage="Add Another Contact"
						/>
					}
				</span>
			</div>
		);
	};

	openRemoveCompanyContactModal = (indexItem, contactType) => {
		this.setState({
			indexItem,
			contactType,
			isRemoveCompanyContact: true
		});
	};

	closeRemoveCompanyContactModal = () => {
		this.setState({
			isRemoveCompanyContact: false
		});
	};

	confirmRemoveCompanyContact = () => {
		const { indexItem, contactType } = this.state;

		const { dispatch } = this.props;
		dispatch(arrayRemove('AddCompanyForm', contactType, indexItem));
		this.setState({
			isRemoveCompanyContact: false
		});
	};

	renderAddresses = ({ fields }) => {
		const {
			formData,
			countries,
			getStatesRequest,
			states,
			streetFieldDirty,
			countryFieldDirty,
			stateFieldDirty,
			zipCodeFieldDirty,
			cityFieldDirty,
			intl
		} = this.props;
		const dataPresentInAddressFields =
			formData && formData.addresses && formData.addresses[0]
				? !formData.addresses[0].countryId ||
				  !formData.addresses[0].stateId ||
				  !formData.addresses[0].street1 ||
				  !formData.addresses[0].zipCode ||
				  !formData.addresses[0].city ||
				  formData.addresses[0].countryId.value !== 231
				: true;
		const addressFieldsTouched =
			streetFieldDirty ||
			countryFieldDirty ||
			stateFieldDirty ||
			zipCodeFieldDirty ||
			cityFieldDirty;

		return (
			<div data-spec="addresses">
				{fields.map((address) => (
					<div key={address}>
						<FieldWrapper required single>
							<FormattedMessage
								id="ONBOARDING.COUNTRY"
								defaultMessage="Country"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name={`${address}.countryId`}
										placeholder={formattedValue}
										options={
											countries &&
											countries.resultList &&
											countries.resultList.length > 0 &&
											countries.resultList.map((item) => ({
												value: item.id,
												label: intl.formatMessage({
													id: `COUNTRY_${localizationHelper(
														item.name
													).toUpperCase()}`,
													defaultMessage: item.name
												})
											}))
										}
										label={formattedValue}
										onChange={(item) => {
											const { dispatch } = this.props;
											this.getStates(item.value || item);
											resetFields(dispatch, 'AddCompanyForm', {
												'addresses[0].stateId': ''
											});
										}}
										validate={[required]}
										cleanValue
										isSearchable
									/>
								)}
							</FormattedMessage>
							{formData &&
								formData.addresses &&
								formData.addresses[0].countryId &&
								formData.addresses[0].countryId.value !== 231 && (
									<div className="help-block">
										<i className="icon-badgealert" aria-hidden="true" />
										<FormattedMessage
											id="ONBOARDING.COUNTRY_NOT_SUPPORTED"
											defaultMessage="Warning: Country other than USA is currently not supported"
										/>
									</div>
								)}
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.ADDRESS_LINE_1"
								defaultMessage="Address Line 1"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.street1`}
										dataSpec="street1"
										validate={[required]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper>
							<FormattedMessage
								id="ONBOARDING.ADDRESS_LINE_2_OPTIONAL"
								defaultMessage="Address Line 2 (Optional)"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.street2`}
										dataSpec="street2"
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.CITY_TOWN"
								defaultMessage="City/Town"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.city`}
										dataSpec="city"
										validate={[required]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.STATE_PROVINCE"
								defaultMessage="State/Province"
							>
								{(formattedValue) => (
									<Field
										component={Select}
										name={`${address}.stateId`}
										placeholder={formattedValue}
										options={
											states &&
											states.resultList &&
											states.resultList.length > 0 &&
											states.resultList.map((item) => ({
												value: item.id,
												label: intl.formatMessage({
													id: `STATE_${localizationHelper(
														item.name
													).toUpperCase()}`,
													defaultMessage: item.name
												})
											}))
										}
										label={formattedValue}
										loading={getStatesRequest}
										validate={[required]}
										isDisabled={getStatesRequest}
										isSearchable
										cleanValue
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper required>
							<FormattedMessage
								id="ONBOARDING.ZIP_POSTAL_CODE"
								defaultMessage="ZIP/Postal code"
							>
								{(formattedValue) => (
									<Field
										component={Input}
										placeholder={formattedValue}
										label={formattedValue}
										name={`${address}.zipCode`}
										dataSpec="zip-code"
										validate={[required, zipCodeValidation]}
									/>
								)}
							</FormattedMessage>
						</FieldWrapper>
						<FieldWrapper customStyle={styles.validate_button}>
							<Button
								label={
									<FormattedMessage
										id="ONBOARDING.VALIDATE_ADDRESS"
										defaultMessage="Validate Address"
									/>
								}
								variant="outline-primary"
								type="button"
								dataSpec="validate-address-button"
								disabled={dataPresentInAddressFields || !addressFieldsTouched}
								onClick={() => this.openModal()}
							/>
						</FieldWrapper>
					</div>
				))}
				{/* <span
					className={cn({
						[styles.icon_wrapper]: true,
						[styles.disabled]: invalid
					})}
					onClick={() => !invalid && fields.push({})}
				>
					<PlusIcon />
					{
						<FormattedMessage
							id="ONBOARDING.ADD_ANOTHER_ADDRESS"
							defaultMessage="Add Another Address"
						/>
					}
				</span> */}
			</div>
		);
	};

	openModal = () =>
		this.setState({
			openValidateAddressModal: true
		});

	closeModal = () => this.setState({ openValidateAddressModal: false });

	render() {
		const {
			countries,
			handleSubmit,
			onClose,
			invalid,
			getCompanyLanguagesRequest,
			getCompanyLanguagesSuccess,
			intl,
			formData,
			states
		} = this.props;

		const { isRemoveCompanyContact, openValidateAddressModal } = this.state;

		const dataPresent =
			!!formData &&
			formData.addresses &&
			formData.addresses[0] &&
			formData.addresses[0].countryId &&
			formData.addresses[0].countryId.value === 231 &&
			formData.addresses[0].stateId &&
			formData.addresses[0].street1 &&
			formData.addresses[0].zipCode &&
			formData.addresses[0].city !== undefined;

		if (!countries) {
			return <Loading data-spec="loader" />;
		}
		return (
			<form
				onSubmit={handleSubmit}
				data-spec="company-details"
				className={styles.page_wrapper}
			>
				<div className={styles.form_wrapper}>
					<h2 className={styles.form_headline}>
						<FormattedMessage
							id="ONBOARDING.CREATE_NEW_COMPANY"
							defaultMessage="Create New Company"
						/>
					</h2>
					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.ACCOUNT_NAME',
							defaultMessage: 'Account Name'
						})}
					/>
					<section className={styles.form_logo}>
						<div>
							<FieldWrapper required single>
								<Field
									component={Input}
									placeholder={intl.formatMessage({
										id: 'ONBOARDING.COMPANY_NAME_PLACEHOLDER',
										defaultMessage: 'e.g: AT&T Business'
									})}
									name="companyFriendlyName"
									label={intl.formatMessage({
										id: 'ONBOARDING.COMPANY_NAME',
										defaultMessage: 'Company Name'
									})}
									dataSpec="company-friendly-name"
									validate={[required, maxLength40]}
								/>
							</FieldWrapper>

							<FieldWrapper required single>
								<Field
									component={Input}
									placeholder={intl.formatMessage({
										id: 'ONBOARDING.BUSINESS_OR_DBA_LEGAL_ENTITY',
										defaultMessage: 'Business or DBA legal entity'
									})}
									name="companyLegalName"
									label={intl.formatMessage({
										id: 'ONBOARDING.BUSINES_LEGAL_NAME',
										defaultMessage: 'Business Legal Name'
									})}
									dataSpec="company-legal-name"
									validate={[required, maxLength50]}
								/>
							</FieldWrapper>
							<FieldWrapper single required>
								<LanguageSelector
									options={
										(getCompanyLanguagesSuccess &&
											getCompanyLanguagesSuccess.resultList) ||
										[]
									}
									isLoading={getCompanyLanguagesRequest}
									isDisabled={getCompanyLanguagesRequest}
									isRequired
								/>
							</FieldWrapper>
						</div>

						<div className={styles.logo}>
							<UploadImage
								updateLogo={this.updateLogo}
								customDefaultLogo={CompanyLogo}
								logo={formData && formData.logo}
							/>
						</div>
					</section>

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.MAIN_CORPORATE_ADDRESS',
							defaultMessage: 'Main Corporate Address'
						})}
					/>
					<FieldArray name="addresses" component={this.renderAddresses} />

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.COMPANY_CONTACTS',
							defaultMessage: 'Company Contacts'
						})}
					/>
					<FieldArray
						name="companyContacts"
						component={this.renderContacts}
						contactType="companyContacts"
					/>

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.ATT_CONTACTS',
							defaultMessage: 'AT&T Contacts'
						})}
					/>
					<FieldArray
						name="attContacts"
						component={this.renderContacts}
						contactType="attContacts"
					/>

					<SectionHeadline
						title={intl.formatMessage({
							id: 'ONBOARDING.VTM_SUPPORT_TYPE',
							defaultMessage: 'vTM Support Type'
						})}
					/>
					<section>
						<Field
							component={Radio}
							name="supportType"
							label={
								<span>
									<span className={styles.bold}>
										<FormattedMessage
											id="ONBOARDING.IOT_PRIORITY_CARE"
											defaultMessage="IoT Priority Care"
										/>
									</span>
									&nbsp;-&nbsp;
									<FormattedMessage
										id="ONBOARDING.IOT_PRIORITY_CARE_DESCRIPTION"
										defaultMessage="All tickets will be supported by the IoT Priority Care team. The account’s contract must specify that they qualify for IoT Priority Care support."
									/>
								</span>
							}
							value={'IoT Priority Care'}
							type="radio"
							props={{ multiline: 'true' }}
						/>
						<Field
							component={Radio}
							name="supportType"
							label={
								<span>
									<span className={styles.bold}>
										<FormattedMessage
											id="ONBOARDING.FULL_TSM"
											defaultMessage="Full TSM"
										/>
									</span>
									&nbsp;-&nbsp;
									<FormattedMessage
										id="ONBOARDING.FULL_TSM_DESCRIPTION"
										defaultMessage="All tickets, regardless of severity, will be supported by the Technical Solutions Manager team. The account’s contract must specify that they qualify for TSM support or the Advanced Solutions Design and Implementation (ASDI) team has specified that the account qualifies for TSM support."
									/>
								</span>
							}
							value={'Full TSM'}
							type="radio"
							props={{ multiline: 'true' }}
						/>
						<Field
							component={Radio}
							name="supportType"
							label={
								<span>
									<span className={styles.bold}>
										<FormattedMessage
											id="ONBOARDING.PARTIAL_TSM"
											defaultMessage="Partial TSM"
										/>
									</span>
									&nbsp;-&nbsp;
									<FormattedMessage
										id="ONBOARDING.PARTIAL_TSM_DESCRIPTION"
										defaultMessage="Critical tickets will be supported by the Technical Solutions Manager team. All other severities will be supported by the ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team. The account’s contract must specify that they qualify for TSM support for critical issues."
									/>
								</span>
							}
							value={'Partial TSM'}
							type="radio"
							props={{ multiline: 'true' }}
						/>
						<Field
							component={Radio}
							name="supportType"
							label={
								<span>
									<span className={styles.bold}>
										<FormattedMessage
											id="ONBOARDING.IOT_GATEWAY"
											defaultMessage="IoT Gateway"
										/>
									</span>
									&nbsp;-&nbsp;
									<FormattedMessage
										id="ONBOARDING.IOT_GATEWAY_DESCRIPTION"
										defaultMessage="Only for use when Equipment Inquiries will be submitted for manually processed TCU (Transceiver Control Unit) replacement requests."
									/>
								</span>
							}
							value={'IoT Gateway'}
							type="radio"
							props={{ multiline: 'true' }}
						/>
						<Field
							component={Radio}
							name="supportType"
							label={
								<span>
									<span className={styles.bold}>
										<FormattedMessage
											id="ONBOARDING.STANDARD"
											defaultMessage="Standard (Default)"
										/>
									</span>
									&nbsp;-&nbsp;
									<FormattedMessage
										id="ONBOARDING.STANDARD_DESCRIPTION"
										defaultMessage="This is the default Support Type for all accounts that do not have a specialized Support Type outlined in their contract. The ASCS HD Offline (Advanced Solutions Customer Service Helpdesk) team will provide the first tier of support."
									/>
								</span>
							}
							value={''}
							type="radio"
							props={{ multiline: 'true' }}
						/>
					</section>
					<br />
				</div>
				<>
					<Divider />
					<div className={styles.section_buttons}>
						<Button
							label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
							variant="outline-primary"
							type="button"
							dataSpec="cancel-button"
							onClick={onClose}
						/>
						<Button
							label={
								<FormattedMessage
									id="ONBOARDING.CREATE_COMPANY"
									defaultMessage="Create Company"
								/>
							}
							variant="primary"
							type="submit"
							dataSpec="submit-button"
							disabled={invalid}
						/>
					</div>
				</>
				{isRemoveCompanyContact && (
					<RemoveCompanyContact
						confirm={this.confirmRemoveCompanyContact}
						show
						onClose={this.closeRemoveCompanyContactModal}
					/>
				)}
				{formData && formData.addresses && formData.addresses[0] && (
					<ValidateAddressModal
						show={openValidateAddressModal}
						onClose={this.closeModal}
						data={formData.addresses[0]}
						dataPresent={dataPresent}
						useSuggestedAddress={this.useSuggestedAddress}
						countries={countries}
						states={states}
					/>
				)}
			</form>
		);
	}
}

const { func, bool, object } = PropTypes;

AddCompanyForm.propTypes = {
	handleSubmit: func,
	invalid: bool,
	getCountries: func,
	countries: object,
	getStates: func,
	getStatesRequest: bool,
	states: object,
	onClose: func,
	dispatch: func,
	formData: object,
	initialize: func,
	getCompanyLanguages: func,
	getCompanyLanguagesRequest: bool,
	getCompanyLanguagesSuccess: object,
	intl: object,
	streetFieldDirty: bool,
	countryFieldDirty: bool,
	stateFieldDirty: bool,
	zipCodeFieldDirty: bool,
	cityFieldDirty: bool
};

export default reduxForm({
	form: 'AddCompanyForm',
	destroyOnUnmount: false
})(injectIntl(AddCompanyForm));
