import qs from 'qs';
import * as ApiService from '../../../services/ApiService';

export const getCompanies = (params) =>
	ApiService.get(`/onboarding/companies/overview?${qs.stringify(params)}`);

export const getCompaniesFilters = async () => {
	const platforms = await ApiService.get('/onboarding/platform-types');
	return {
		data: {
			platforms: platforms.data.resultList,
		}
	};
};

export const getCompany = (params) => {
	const { id, additionalParams } = params;
	return ApiService.get(
		`/onboarding/companies/${id}?${qs.stringify({
			additionalParams
		})}`
	);
};

export const addCompany = (data) =>
	ApiService.post('/onboarding/companies', data);

export const validateAddress = async (data) => {
	const res = await ApiService.post('/onboarding/addresses/validate', data);
	return res;
};

export const getMyCompany = (params) =>
	ApiService.get(`/onboarding/my-company?${qs.stringify(params)}`,);

export const activateCompany = (id) =>
	ApiService.patch(`/onboarding/companies/${id}/activate`, { data: { id } });

export const getCountries = (params) =>
	ApiService.get(`/onboarding/countries?${qs.stringify(params)}`);

export const getStates = (params) =>
	ApiService.get(`/onboarding/states?${qs.stringify(params)}`);

export const getAddressTypes = () =>
	ApiService.get('/onboarding/companies/address-types');

export const getContactTypes = () =>
	ApiService.get('/onboarding/companies/contact-types');

export const editMyCompany = (params) =>
	ApiService.patch('/onboarding/my-company', params);

export const editCompany = ({ params, companyId }) =>
	ApiService.patch(`/onboarding/companies/${companyId}`, params);

export const getCompanyLanguages = () =>
	ApiService.get('/onboarding/languages');

export const getCompanyToken = (params) =>
	ApiService.get(`/users/system-users/me/companies/${params.companyId}/token`);
