import React, { PureComponent } from 'react';
import { format } from 'date-fns';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../lib/DigitalComponents/Button';
import DSDatePicker from '../../../../lib/DigitalComponents/DatePicker';

import styles from './StepTwo.scss';

const dateFormat = 'yyyy-MM-dd';

// eslint-disable-next-line no-unused-vars
const validate = (values) => {
	const errors = {};

	return errors;
};

class StepTwo extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedDay: new Date()
		};
	}

	componentDidMount() {
		const { context } = this.props;
		if (context) {
			this.setFooter(context);
		}
	}

	onClose = () => {
		const { onClose } = this.props;
		onClose();
	};

	// eslint-disable-next-line no-unused-vars
	onNext = (values) => {
		const { onNext, batchUpload, batchFileRequestURL, id } = this.props;
		const { selectedDay } = this.state;
		const formatedDate = format(selectedDay, dateFormat);
		const object = {
			...batchUpload,
			scheduleDate: formatedDate,
			request: {
				...batchUpload.request,
				scheduleDate: formatedDate,
				customerSecurityFeatureTypeId: id
			},
			selectedDay
			// here goes
		};
		const type = 'securityFeatures';
		batchFileRequestURL(object, type);
		onNext(object);
	};

	setFooter = (context) => {
		const footer = (
			<>
				<Button
					onClick={this.onClose}
					variant="link"
					label={
						<FormattedMessage
							id="SECURITY_FEATURES.CANCEL"
							defaultMessage="Cancel"
						/>
					}
					dataSpec="cancel-button"
				/>
				<Button
					dataSpec="upload-file"
					onClick={this.onNext}
					variant="primary"
					label={
						<FormattedMessage
							id="SECURITY_FEATURES.UPLOAD_FILE"
							defaultMessage="Upload file"
						/>
					}
				/>
			</>
		);
		context.updateContext({ footer });
	};

	onDefaultDateChange = (date) => {
		this.setState({
			selectedDay: date
		});
	};

	render() {
		const { selectedDay } = this.state;
		return (
			<div data-spec="step-two">
				<h4 className={styles.confirm_upload_title}>
					<FormattedMessage
						id="SECURITY_FEATURES.CONFIRM_UPLOAD"
						defaultMessage="Confirm batch file upload"
					/>
				</h4>
				<div className={styles.confirm_content}>
					<DSDatePicker
						label={
							<FormattedMessage
								id="SECURITY_FEATURES.SCHEDULED_ACTION_ON"
								defaultMessage="Scheduled Action on"
							/>
						}
						value={selectedDay}
						onDayChange={this.onDefaultDateChange}
					/>
				</div>
			</div>
		);
	}
}

const { func, shape, number } = PropTypes;

StepTwo.propTypes = {
	context: shape(),
	onClose: func,
	onNext: func,
	batchUpload: shape(),
	batchFileRequestURL: func,
	id: number
};

export default reduxForm({
	form: 'DSBatchUploadForm',
	validate,
	initialValues: {
		scheduledDate: Date.now()
	}
})(StepTwo);
